import React from "react";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";
import Button from "devextreme-react/button";
import { getTrad } from "../../../../helpers";
import DataGrid, { Grouping, Paging } from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
//Css
import "./Css.scss";

export const PopupHistorico = ({
  visible,
  data,
  colExtraConfig,
  title,
  subTitle,
  isSubtitleOneLine,
  isLoading,
  onClickAceptar,
  onCellPrepared,
  onRowPrepared,
  onHiding,
  onShown,
}) => {
  let columns = data.map((rows) => {
    return Object.keys(rows).map((col) => {
      let colConfig = colExtraConfig?.filter(
        (conf) => conf.dataField == col,
      )?.[0];
      return {
        dataField: col,
        caption: getTrad(col),
        ...colConfig,
      };
    });
  });

  const dxPopup_onContentReady = (e) => {
    e.component.content().css("white-space", "pre-line"); // salto de linea en string con \n
  };

  let recambioIncoherente = data.length
    ? data[0].refIntRec + " - " + data[0].refRec + " - " + data[0].denoRec
    : "";

  return (
    <Popup
      title={title}
      visible={visible}
      height="60%"
      width="86%"
      onContentReady={dxPopup_onContentReady}
      onHiding={onHiding}
      onShown={onShown}
    >
      <div className="d-flex flex-column he-100">
        {subTitle && (
          <div
            className={
              isSubtitleOneLine
                ? "PopupGrid_subtitle_oneLine"
                : "PopupGrid_subtitle"
            }
          >
            {
              <div
                dangerouslySetInnerHTML={{
                  __html: subTitle.replace("{0}", recambioIncoherente),
                }}
              ></div>
            }
          </div>
        )}
        <div
          className="he-100 flex-1 dxDataGridContainer_popupGrid"
          style={{ paddingBottom: 50 }}
        >
          <DataGrid
            dataSource={data}
            columnsAutoWidth={true}
            height={"100%"}
            width={"100%"}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            columns={columns[0]}
            onCellPrepared={onCellPrepared}
            onRowPrepared={onRowPrepared}
          >
            <Paging enabled={false} />
            <Grouping autoExpandAll={false} />
          </DataGrid>
          <LoadPanel
            shadingColor="transparent"
            delay={0}
            animation={null}
            visible={isLoading}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        </div>
      </div>

      <ToolbarItemPopup widget="dxButton" location="after" toolbar="bottom">
        <Button
          text={getTrad("aceptar")}
          type="success"
          onClick={onClickAceptar}
        />
      </ToolbarItemPopup>
    </Popup>
  );
};
