import React, { forwardRef, useEffect, useState } from "react";

import Form, { ButtonItem, Item } from "devextreme-react/form";

import BuscadorRecambio from "./components/BuscadorRecambio";
import { datasource_tblCierreRecambioNAlmacen } from "../../api";
import { getTrad } from "helpers";

const IDLabel = {
  text: "ID",
  location: "top",
  showColon: false,
};

const SharedForm = forwardRef(
  (
    {
      formData,
      origen = null,
      origenOptions,
      destino = null,
      destinoOptions,
      disableCoreFields,
      disableBuscador,
      enableIsInventario = false,
      isSending,
      setIsLoading,
      onAddRecambio,
      onChangeFecha,
      setHasChangesForm,
      onSubmit,
      children,
    },
    ref
  ) => {
    const [minFecha, setMinFecha] = useState(null);

    const {
      fecha,
      idAlmacenDestino,
      idAlmacenOrigen,
      idMovimientoRecambio,
      isEditable,
      tblRecambioNMovimientoRecambio,
    } = formData;

    useEffect(() => {
      if (isEditable) {
        const fechaO = idAlmacenOrigen
          ? datasource_tblCierreRecambioNAlmacen
              .items()
              .find((item) => item.idAlmacen === idAlmacenOrigen)?.fecha
          : null;

        const fechaD = idAlmacenDestino
          ? datasource_tblCierreRecambioNAlmacen
              .items()
              .find((item) => item.idAlmacen === idAlmacenDestino)?.fecha
          : null;

        const fechaOD =
          fechaO && fechaD ? Math.max(fechaO, fechaD) : fechaO ?? fechaD;

        if (fechaOD) {
          const date = new Date(fechaOD);
          const fechaFinal = new Date(date.setMonth(date.getMonth() + 1));
          setMinFecha(fechaFinal);
          if (fecha < fechaFinal) {
            ref.current.instance.updateData("fecha", null);
          }
        } else {
          setMinFecha(null);
        }
      }
    }, [idAlmacenOrigen, idAlmacenDestino]);

    const this_onSubmit = (e) => {
      e.preventDefault();
      onSubmit(formData);
    };

    const getText = (label) => ({ text: getTrad(label) });

    const editorOptions = {
      readOnly: {
        readOnly: true,
      },
      fecha: {
        type: "datetime",
        displayFormat: "dd/MM/yyyy HH:mm",
        min: minFecha,
        readOnly: disableCoreFields,
        onValueChanged: onChangeFecha,
      },
      isInventario: {
        switchedOnText: getTrad("inventario"),
        switchedOffText: getTrad("regularizacion"),
        className: "no-disabled",
        readOnly: disableCoreFields,
      },
      buttonOptions: {
        text: getTrad("guardar"),
        type: "success",
        useSubmitBehavior: true,
        disabled: isSending || !isEditable,
      },
      observaciones: {
        placeholder: getTrad("observaciones"),
      },
    };

    const this_onAddRecambio = (r) => {
      const newRecambio = {
        idMovimientoRecambio: idMovimientoRecambio,
        idRecambio: r.idRecambio,
        min: r.min,
        max: r.max,
        cantidad: r.min ?? 1,
        precio: r.precio,
        ubicacion: r.ubicacion,
        referenciaInterna: r.referenciaInterna,
        referencia: r.referencia,
        denominacion: r.denoRecambio,
        referenciaProveedor: r.referenciaProveedor,
      };

      onAddRecambio(newRecambio);
    };

    const onFieldDataChanged = () => setHasChangesForm(true);

    return (
      <form className={"he-100 w-100"} onSubmit={this_onSubmit}>
        <Form
          ref={ref}
          formData={formData}
          width={"100%"}
          height={"100%"}
          labelLocation={"top"}
          colCount={10}
          showColonAfterLabel={false}
          readOnly={!isEditable}
          onFieldDataChanged={onFieldDataChanged}
        >
          <Item
            dataField={"idMovimientoRecambio"}
            label={IDLabel}
            colSpan={1}
            editorOptions={editorOptions.readOnly}
          />
          {origen || origenOptions ? (
            <Item {...origenOptions}>{origen}</Item>
          ) : null}
          {destino || destinoOptions ? (
            <Item {...destinoOptions}>{destino}</Item>
          ) : null}
          <Item
            dataField={"fecha"}
            label={getText("fecha")}
            editorOptions={editorOptions.fecha}
            editorType={"dxDateBox"}
            colSpan={enableIsInventario ? 3 : 2}
            isRequired
          />
          {enableIsInventario ? (
            <Item
              dataField={"isInventario"}
              label={getText("inventario")}
              editorOptions={editorOptions.isInventario}
              editorType={"dxSwitch"}
              colSpan={2}
            />
          ) : null}
          <ButtonItem
            buttonOptions={editorOptions.buttonOptions}
            horizontalAlignment={"right"}
            verticalAlignment={"bottom"}
          />
          <Item
            dataField={"observaciones"}
            label={getText("observaciones")}
            editorOptions={editorOptions.observaciones}
            editorType={"dxTextArea"}
            colSpan={4}
          />
          {children}
          <Item
            label={getText("busquedaNuevoRecambio")}
            editorType={"dxSelectBox"}
            colSpan={10}
          >
            <BuscadorRecambio
              formData={formData}
              readOnly={disableBuscador || !isEditable}
              setIsLoading={setIsLoading}
              onAddRecambio={this_onAddRecambio}
            />
          </Item>
        </Form>
      </form>
    );
  }
);

export default SharedForm;
