import React from "react";

import DataGrid, {
    Column,
    Export,
    FilterRow,
    Pager,
    Paging,
    Sorting,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import $ from "jquery";

import { errorHandler, authHeader, getTrad, formatNumber } from "helpers";
import { connectionConstants } from "../../../../../../../constants";

const allowedPageSizes = [20, 50, 100];

const formats = { int: { maximumFractionDigits: 0 }, decimal: { maximumFractionDigits: 2 } };

const DataGridPrendaNMovimiento = ({ movimientosSel }) => {
    const datasource_spSelectPrendaNMovimiento_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        request.params.idsMovimiento = movimientosSel.join("|");
    };

    const datasource_spSelectPrendaNMovimiento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url:
                connectionConstants.WEB_API_CORE_ODATA_URL +
                "Inventarios/Movimientos/infMovimientos_spSelectPrendaNMovimiento",
            key: "idPrenda",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: datasource_spSelectPrendaNMovimiento_beforeSend,
            version: 4,
        }),
    });

    const onToolbarPreparing = ({ toolbarOptions, component }) => {
        toolbarOptions.items.unshift(
            {
                location: "before",
                template: () =>
                    $('<div id="prendas_title" class="font-size" style="margin-top:10px" >').text(getTrad("prendas")),
            },
            {
                location: "affter",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    width: "100%",
                    icon: "exportxlsx",
                    text: getTrad("exportar"),
                    type: "normal",
                    disabled: movimientosSel.length === 0,
                    onClick: () => component.exportToExcel(),
                },
            }
        );
    };

    return (
        <DataGrid
            dataSource={datasource_spSelectPrendaNMovimiento}
            width={"100%"}
            height={"100%"}
            showRowLines
            columnsAutoWidth
            hoverStateEnabled
            rowAlternationEnabled
            allowColumnReordering
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
        >
            <FilterRow visible />
            <Sorting mode={"multiple"} />
            <Paging defaultPageSize={50} />
            <Pager showInfo showPageSizeSelector allowedPageSizes={allowedPageSizes} />
            <Export fileName={getTrad("prendas")} />
            <Column dataField={"denominacion"} caption={getTrad("denominacion")} sortIndex={0} sortOrder={"asc"} />
            <Column
                dataField={"pesoUdKg"}
                caption={getTrad("pesoUdKg")}
                dataType={"number"}
                width={100}
                format={formats.decimal}
            />
            <Column
                dataField={"entradas"}
                caption={getTrad("entradas")}
                dataType={"number"}
                width={95}
                alignment={"right"}
                allowFiltering={false}
                format={formats.int}
            />
            <Column
                dataField={"salidas"}
                caption={getTrad("salidas")}
                dataType={"number"}
                width={95}
                alignment={"right"}
                allowFiltering={false}
                format={formats.int}
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                width={95}
                alignment={"right"}
                allowFiltering={false}
                format={formats.int}
            />
            <Column
                dataField={"totalKg"}
                caption={getTrad("kgTotal")}
                dataType={"number"}
                width={95}
                alignment={"right"}
                allowFiltering={false}
                format={formats.decimal}
            />
            <Summary>
                <TotalItem column={"denominacion"} displayFormat={getTrad("total").toUpperCase()} />
                <TotalItem
                    name={"entradas"}
                    column={"entradas"}
                    summaryType={"sum"}
                    displayFormat={"{0}"}
                    alignment={"right"}
                    allowFiltering={false}
                    valueFormat={formats.int}
                />
                <TotalItem
                    name={"salidas"}
                    column={"salidas"}
                    summaryType={"sum"}
                    displayFormat={"{0}"}
                    alignment={"right"}
                    allowFiltering={false}
                    valueFormat={formats.int}
                />
                <TotalItem
                    name={"total"}
                    column={"total"}
                    summaryType={"sum"}
                    displayFormat={"{0}"}
                    alignment={"right"}
                    allowFiltering={false}
                    valueFormat={formats.int}
                />
                <TotalItem
                    name={"totalKg"}
                    column={"totalKg"}
                    summaryType={"sum"}
                    displayFormat={"{0}"}
                    alignment={"right"}
                    allowFiltering={false}
                    valueFormat={formats.decimal}
                />
            </Summary>
        </DataGrid>
    );
};

export default DataGridPrendaNMovimiento;
