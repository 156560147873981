import React from "react";

import { SelectBox, Switch } from "devextreme-react";

import { ControlPresupuestarioCxt } from "../../../contexts/context";
import { getTrad } from "helpers";

class SelectorTasaDivisa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ tasaDivisa, setTasaDivisa }) => {
                    return (
                        <Switch
                            value={tasaDivisa}
                            className="dx-switch-header font-size-sm text-uppercase"
                            switchedOnText={getTrad("divisaActual")}
                            switchedOffText={getTrad("divisaPresupuesto")}
                            width={250}
                            onValueChanged={(e) => setTasaDivisa(e.value)}
                            {...this.props}
                        />
                    );
                }}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

export default SelectorTasaDivisa;
