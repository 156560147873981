import React, { forwardRef, useState } from "react";

import Box, { Item } from "devextreme-react/box";

const FormMovimientoRecambioWrapper = forwardRef(
  (
    {
      Form,
      DataGrid,
      movimientoRecambio,
      isSending,
      setHasChangesForm,
      onSubmit,
    },
    ref
  ) => {
    const [codigoMoneda, setCodigoMoneda] = useState(null);
    const [disableCoreFields, setDisableCoreFields] = useState(false);

    const onAddRecambio = (newRecambio) => {
      ref?.current?.onAddRecambio(newRecambio);
      setDisableCoreFields(true);
    };

    const setIsLoading = ref?.current?.setIsLoading;

    const onChange = (tblRecambioNMovimientoRecambio) =>
      setDisableCoreFields(tblRecambioNMovimientoRecambio.length > 0);

    return (
      <Box direction={"col"} width={"100%"} height={"100%"}>
        <Item ratio={3}>
          <Form
            formData={movimientoRecambio}
            codigoMoneda={codigoMoneda}
            setCodigoMoneda={setCodigoMoneda}
            isSending={isSending}
            disableCoreFields={disableCoreFields}
            setIsLoading={setIsLoading}
            setHasChangesForm={setHasChangesForm}
            onAddRecambio={onAddRecambio}
            onSubmit={onSubmit}
          />
        </Item>
        <Item ratio={7}>
          <DataGrid
            ref={ref}
            movimientoRecambio={movimientoRecambio}
            isSending={isSending}
            codigoMoneda={codigoMoneda}
            setHasChangesForm={setHasChangesForm}
            onChange={onChange}
          />
        </Item>
      </Box>
    );
  }
);

export default FormMovimientoRecambioWrapper;
