import React from "react";

import Box, { Item } from "devextreme-react/box";
import List from "devextreme-react/list";

import { formatNumber } from "helpers";
import { useRecambiosProvider } from "pages/AssistantNew/Recambios/components/RecambiosProvider";

const ListTblRecambioNProveedor = ({ dataSource }) => {
  const { setRecambioSel } = useRecambiosProvider();

  const itemRender = (data) => {
    let fields = [
      { colSpan: 3, value: data.idProveedorNavigation.nombreComercial },
      { colSpan: 2, value: data.idPaisNavigation.denominacion },
      { colSpan: 2, value: data.referencia },
      { colSpan: 2, value: data.refFabricante },
      { colSpan: 2, value: data.codigoFabricante },
      {
        colSpan: 2,
        className: "text-right",
        value: formatNumber(
          data.ultimoPrecio,
          2,
          "currency",
          data.idPaisNavigation.idMonedaNavigation.codigo
        ),
      },
    ];

    return (
      <div>
        <Box height={"100%"} width={"100%"}>
          {fields.map((field, index) => {
            return (
              <Item key={index} ratio={field.colSpan}>
                <div
                  className={
                    "px-2 text-truncate " +
                    (field.className ? field.className : "")
                  }
                >
                  {field.value}
                </div>
              </Item>
            );
          })}
        </Box>
      </div>
    );
  };

  const onItemHold = () => {};

  const onItemReordered = ({ component, toIndex, fromIndex, itemData }) => {
    component.option("disabled", true);

    let items = component.getDataSource().items();
    let isAscendente = fromIndex < toIndex;
    let desde = isAscendente ? fromIndex : toIndex;
    let hasta = isAscendente ? toIndex : fromIndex;

    for (var i = desde; i <= hasta; i++) {
      var cont = i + 1;
      var item = items[i];
      item.orden = isAscendente ? cont - 1 : cont + 1;

      if (itemData.idRecambioNProveedor === item.idRecambioNProveedor) {
        item.orden = toIndex + 1;
      }
    }

    items.sort((a, b) => a.orden - b.orden);

    setRecambioSel((prevState) => ({
      ...prevState,
      tblRecambioNProveedor: items,
    }));

    component.option("disabled", false);
  };

  return (
    <List
      dataSource={dataSource}
      allowItemReordering={true}
      selectionMode={"none"}
      activeStateEnabled={false}
      focusStateEnabled={false}
      pageLoadMode={"scrollBottom"}
      itemRender={itemRender}
      onItemHold={onItemHold}
      onItemReordered={onItemReordered}
    />
  );
};

export default ListTblRecambioNProveedor;
