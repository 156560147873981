import React from "react";
import { connect } from "react-redux";

import "./style.scss";

import { personaActions } from "actions/fotos";

import LottieIcon from "components/LottieIcon";

class FotoPersona extends React.Component {
    constructor(props) {
        super(props);
        this.state = { foto: null, loaded: false };
    }

    img_develop = "data:image/png;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

    // #region Lifecycle

    componentDidMount() {
        const { idPersona, fotosPerfil, loadFotoPerfil } = this.props;
        if (fotosPerfil[idPersona] !== undefined) {
            this.setState({
                foto: fotosPerfil[idPersona] != null ? "data:image/png;base64," + fotosPerfil[idPersona] : null,
                loaded: true,
            });
        } else {
            // loadFotoPerfil(idPersona);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { idPersona, fotosPerfil } = this.props;
        const { foto, loaded } = this.state;
        return (
            (foto == null && fotosPerfil[idPersona] !== null) ||
            nextProps.fotosPerfil[idPersona] !== fotosPerfil[idPersona] ||
            nextProps.idPersona !== idPersona ||
            nextState.foto !== foto ||
            nextState.loaded !== loaded
        );
    }

    componentDidUpdate(prevProps) {
        const { idPersona, fotosPerfil, loadFotoPerfil } = this.props;
        if (idPersona !== prevProps.idPersona) {
            if (fotosPerfil[idPersona] !== undefined) {
                this.setState({
                    foto: fotosPerfil[idPersona] != null ? "data:image/png;base64," + fotosPerfil[idPersona] : null,
                    loaded: true,
                });
            } else {
                // loadFotoPerfil(idPersona);
                this.setState({ foto: null, loaded: false });
            }
        }
        const { foto } = this.state;
        if (foto == null && fotosPerfil[idPersona] !== undefined) {
            this.setState({
                foto: fotosPerfil[idPersona] != null ? "data:image/png;base64," + fotosPerfil[idPersona] : null,
                loaded: true,
            });
        }
    }

    // #endregion

    render() {
        const { foto, loaded } = this.state;
        const { style, placeholder } = this.props;
        return (
            <div style={style} className="fotoPersona">
                {foto && foto !== this.img_develop ? (
                    <img src={foto} alt="imagen" />
                ) : loaded ? (
                    placeholder ? (
                        placeholder
                    ) : (
                        <LottieIcon icon="defaultUserPhoto" isLoop={false} />
                    )
                ) : (
                    <LottieIcon icon="loading_circular" isLoop={true} />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fotosPerfil: state.fotos.fotosPerfil,
});

const mapDispatchToProps = (dispatch) => ({
    loadFotoPerfil: (idPersona) => dispatch(personaActions.loadFotoPerfil(idPersona)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FotoPersona);
