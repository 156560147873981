import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDateTime_parameter,
  convertClienteUtcToLavanderiaUtc,
  dxMensajePregunta,
} from "../../../helpers";
import { svg_remove, svg_edit, svg_warning } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import query from "devextreme/data/query";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var parteTransporteSel = {
    tblRutaNParteTransporte: [],
    tblRevisionVehiculoNParteTransporte: [],
    tblTransportistaNParteTransporte: [],
  };
  var parteTransporteSel_bak = null;

  //#region DATASOURCES

  var datasource_tblParteTransporte = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblParteTransporte",
      key: "idParteTransporte",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    expand: [
      "tblVehiculo($select = denominacion,matricula)",
      "tblTransportistaNParteTransporte($select = idPersona, nombre,apellidos)",
      "tblRevisionVehiculoNParteTransporte($select =  idRevisionVehiculo,incidencia,estado)",
      "tblRutaNParteTransporte($select = idParteTransporte,idReparto,horaLlegada,horaSalida,idEntidad,idRutaNParteTransporte; $expand=tblReparto($select=idReparto,codigo, fecha), tblEntidad($select= idEntidad, denominacion, idCompañia))",
    ],
    postProcess: function (data) {
      $.each(data, function (index, parteTransporte) {
        var vehiculo = parteTransporte.tblVehiculo;
        parteTransporte.tblVehiculo.denominacion =
          vehiculo.matricula + " - " + vehiculo.denominacion;

        $.each(
          parteTransporte.tblTransportistaNParteTransporte,
          function (index, transportistaNParteTransporte) {
            transportistaNParteTransporte.nombreCompuesto =
              transportistaNParteTransporte.nombre +
              " " +
              transportistaNParteTransporte.apellidos;
          },
        );
      });

      return data;
    },
  });

  var datasource_tblVehiculo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblVehiculo",
      key: "idVehiculo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idVehiculo", "denominacion", "matricula"],
    map: function (dataItem) {
      return {
        idVehiculo: dataItem.idVehiculo,
        denominacion: dataItem.matricula + " - " + dataItem.denominacion,
      };
    },
  });

  var datasource_tblNivelCombustible = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblNivelCombustible",
      key: "idNivelCombustible",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_tblPersona_responsable = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "nombre",
    select: ["idPersona", "nombre", "apellidos"],
    filter: [
      ["idCategoria", "=", 1],
      "OR",
      ["idCategoria", "=", 3],
      "OR",
      ["idCategoria", "=", 5],
      "OR",
      ["idCategoria", "=", 12],
      "OR",
      ["idCategoria", "=", 15],
      "OR",
      ["idCategoriaInterna", "=", 8],
      "OR",
      ["idCategoriaInterna", "=", 1],
      "OR",
      ["idCategoriaInterna", "=", 7],
      "OR",
      ["idCategoriaInterna", "=", 10],
      "OR",
    ],
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
      };
    },
  });

  var datasource_tblPersona_transportistas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
    filter: [["idCategoria", "=", 3], "or", ["idCategoriaInterna", "=", 1]],
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
      };
    },
  });

  var datasource_tblRevisionVehiculo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRevisionVehiculo",
      key: "idRevisionVehiculo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.estado = true;
        item.incidencia = "";
      });
      return data;
    },
  });

  var datasource_datos_compaEnti_compañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idCompañia", "denominacion", "activo"],
    sort: "denominacion",
  });

  var datasource_datos_compaEnti_entidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idEntidad", "denominacion", "idCompañia"],
    sort: "denominacion",
  });

  var datasource_datos_compaEnti = new DataSource({
    store: new CustomStore({
      load: function (loadOptions) {
        var _deferred = $.Deferred();

        $.when(
          datasource_datos_compaEnti_compañia.load(),
          datasource_datos_compaEnti_entidad.load(),
        ).done(function () {
          var compañias = query(datasource_datos_compaEnti_compañia.items())
            .filter(["activo", "=", true])
            .toArray();

          var entidades = datasource_datos_compaEnti_entidad.items();

          //Se filtran las entidades con las que NO están ya subscritas a la lavanderia y se obtiene una lista de todas las entidades
          var compaEnti = [];
          var todasEntidades = [];

          $.each(compañias, function (index, compañia) {
            var compa = $.extend(true, {}, compañia);
            compa.tblEntidad = $.grep(entidades, function (entidad) {
              return entidad.idCompañia === compa.idCompañia;
            });

            compaEnti.push(compa);
            $.merge(todasEntidades, compa.tblEntidad);
          });

          compaEnti = $.grep(compaEnti, function (compañia) {
            return compañia.tblEntidad.length > 0;
          });

          todasEntidades = query(todasEntidades)
            .sortBy("denominacion")
            .toArray();

          compaEnti.splice(0, 0, {
            idCompañia: -1,
            denominacion: getTrad("todas").toUpperCase(),
            tblEntidad: todasEntidades,
          });

          _deferred.resolve(compaEnti);
        });

        return _deferred.promise();
      },
    }),
  });

  var datasource_tblReparto = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblReparto",
      key: "idReparto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "codigo",
    select: ["idReparto", "codigo", "fecha"],
  });

  var enum_estadoVehiculo = [
    {
      value: true,
      estado: getTrad("correcto").toUpperCase(),
    },
    {
      value: false,
      estado: getTrad("incorrecto").toUpperCase(),
    },
  ];

  //#endregion

  $.when(
    datasource_tblVehiculo.load(),
    datasource_tblNivelCombustible.load(),
    datasource_tblPersona_responsable.load(),
    datasource_tblPersona_transportistas.load(),
    datasource_tblRevisionVehiculo.load(),
    datasource_datos_compaEnti.load(),
  )
    .done(function () {
      function nuevoParteTransporte() {
        parteTransporteSel.idParteTransporte = null;
        parteTransporteSel.fecha = convertClienteUtcToLavanderiaUtc(
          LAVANDERIA.GMT,
          new Date(),
        );
        parteTransporteSel.idVehiculo = null;
        parteTransporteSel.idPersonaResponsable = null;
        parteTransporteSel.idNivelCombustible = null;
        parteTransporteSel.kmsInicialesVehiculo = 0;
        parteTransporteSel.kmsFinalesVehiculo = 0;
        parteTransporteSel.horaSalidaLavanderia = new Date(
          convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()).setHours(
            0,
            0,
            0,
            0,
          ),
        );
        parteTransporteSel.horaLlegadaLavanderia = new Date(
          convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()).setHours(
            0,
            0,
            0,
            0,
          ),
        );
        parteTransporteSel.observaciones = "";

        parteTransporteSel.tblRutaNParteTransporte.length = 0;
        parteTransporteSel.tblRevisionVehiculoNParteTransporte.length = 0;

        datasource_tblRevisionVehiculo.load().done(function () {
          $.merge(
            parteTransporteSel.tblRevisionVehiculoNParteTransporte,
            datasource_tblRevisionVehiculo.items().slice(),
          );

          parteTransporteSel.tblTransportistaNParteTransporte.length = 0;

          parteTransporteSel_bak = $.extend(true, {}, parteTransporteSel);

          if ($("#dxForm_parteTransporte").length > 0) {
            $("#dxForm_parteTransporte").dxForm("instance").repaint();
          }
        });
      }

      function cargaParteTransporte(objParte) {
        parteTransporteSel.idParteTransporte = objParte.idParteTransporte;
        parteTransporteSel.fecha = objParte.fecha;
        parteTransporteSel.idVehiculo = objParte.idVehiculo;
        parteTransporteSel.idPersonaResponsable = objParte.idPersonaResponsable;
        parteTransporteSel.idNivelCombustible = objParte.idNivelCombustible;
        parteTransporteSel.kmsInicialesVehiculo = objParte.kmsInicialesVehiculo;
        parteTransporteSel.kmsFinalesVehiculo = objParte.kmsFinalesVehiculo;
        parteTransporteSel.horaSalidaLavanderia = objParte.horaSalidaLavanderia;
        parteTransporteSel.horaLlegadaLavanderia =
          objParte.horaLlegadaLavanderia;
        parteTransporteSel.observaciones = objParte.observaciones;

        parteTransporteSel.tblRutaNParteTransporte.length = 0;
        $.merge(
          parteTransporteSel.tblRutaNParteTransporte,
          objParte.tblRutaNParteTransporte,
        );

        parteTransporteSel.tblRevisionVehiculoNParteTransporte.length = 0;
        $.merge(
          parteTransporteSel.tblRevisionVehiculoNParteTransporte,
          objParte.tblRevisionVehiculoNParteTransporte,
        );

        parteTransporteSel.tblTransportistaNParteTransporte.length = 0;
        $.merge(
          parteTransporteSel.tblTransportistaNParteTransporte,
          objParte.tblTransportistaNParteTransporte,
        );

        parteTransporteSel_bak = $.extend(true, {}, parteTransporteSel);

        $("#dxForm_parteTransporte")
          .dxForm("instance")
          .updateData(parteTransporteSel);
        $("#dxDataGrid_transportistas").dxDataGrid("instance").refresh();
        $("#dxDataGrid_revisionVehiculo").dxDataGrid("instance").refresh();
        $("#dxDataGrid_rutaNParteTransporte").dxDataGrid("instance").refresh();

        dxForm_customCheck();
      }

      nuevoParteTransporte();

      $("#PartesTransporte #dxContainer").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            ratio: 3,
            template: function (e, index, item) {
              item.css("padding-right", "30px");
              item.append(
                $("<div />").dxBox({
                  direction: "col",
                  align: "space-around",
                  crossAlign: "stretch",
                  height: "100%",
                  items: [
                    {
                      ratio: 1,
                      template: function (e, index, item) {
                        item.append(
                          $("<div />").dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              {
                                baseSize: 45,
                                template: function (e, index, item) {
                                  item.append(
                                    $("<div />").dxToolbar({
                                      height: "100%",
                                      items: [
                                        {
                                          location: "before",
                                          widget: "dxButton",
                                          options: {
                                            text: getTrad(
                                              "nuevoParteTransporte",
                                            ),
                                            icon: "add",
                                            onClick: function (e) {
                                              nuevoParteTransporte();

                                              var dxDataGrid_PartesTransporte =
                                                $(
                                                  "#dxDataGrid_PartesTransporte",
                                                ).dxDataGrid("instance");
                                              dxDataGrid_PartesTransporte.option(
                                                "selectedRowKeys",
                                                [],
                                              );
                                              dxDataGrid_PartesTransporte.repaint();
                                            },
                                          },
                                        },
                                        {
                                          location: "after",
                                          widget: "dxButton",
                                          options: {
                                            text: getTrad(
                                              "guardarParteTransporte",
                                            ),
                                            icon: "check",
                                            type: "success",
                                            onClick: function (e) {
                                              if (dxForm_hasChanges()) {
                                                var form = $(
                                                  "#dxForm_parteTransporte",
                                                ).dxForm("instance");
                                                var formData =
                                                  form.option("formData");
                                                var isValid_customCheck =
                                                  dxForm_customCheck();

                                                if (
                                                  formData &&
                                                  form.validate().isValid &&
                                                  isValid_customCheck
                                                ) {
                                                  var data = tratarDatos(
                                                    $.extend(
                                                      true,
                                                      {},
                                                      formData,
                                                    ),
                                                  );

                                                  if (!data.idParteTransporte) {
                                                    //POST
                                                    delete data.idParteTransporte;
                                                    datasource_tblParteTransporte
                                                      .store()
                                                      .insert(data)
                                                      .done(
                                                        function (
                                                          parteTransporte,
                                                        ) {
                                                          notify({
                                                            message: getTrad(
                                                              "aviso_C_RegistroInsertado",
                                                            ),
                                                            type: "success",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                          });

                                                          datasource_tblParteTransporte
                                                            .reload()
                                                            .done(function () {
                                                              parteTransporteSel_bak =
                                                                $.extend(
                                                                  true,
                                                                  {},
                                                                  parteTransporteSel,
                                                                );
                                                              $(
                                                                "#dxDataGrid_PartesTransporte",
                                                              )
                                                                .dxDataGrid(
                                                                  "instance",
                                                                )
                                                                .selectRows(
                                                                  [
                                                                    parteTransporte.idParteTransporte,
                                                                  ],
                                                                  false,
                                                                );
                                                            });
                                                        },
                                                      );
                                                  } else {
                                                    //PATCH
                                                    datasource_tblParteTransporte
                                                      .store()
                                                      .update(
                                                        data.idParteTransporte,
                                                        data,
                                                      )
                                                      .done(
                                                        function (
                                                          parteTransporte,
                                                        ) {
                                                          notify({
                                                            message: getTrad(
                                                              "aviso_C_RegistroActualizado",
                                                            ),
                                                            type: "success",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                          });

                                                          parteTransporteSel_bak =
                                                            $.extend(
                                                              true,
                                                              {},
                                                              parteTransporteSel,
                                                            );
                                                          datasource_tblParteTransporte.reload();
                                                        },
                                                      );
                                                  }
                                                }
                                              }
                                            },
                                          },
                                        },
                                      ],
                                    }),
                                  );
                                },
                              },
                              {
                                ratio: 1,
                                template: function (e, index, item) {
                                  item.append(
                                    $(
                                      "<div id='dxForm_parteTransporte' >",
                                    ).dxForm({
                                      labelLocation: "top",
                                      width: "100%",
                                      height: "100%",
                                      colCount: 1,
                                      formData: parteTransporteSel,
                                      scrollingEnabled: true,
                                      items: [
                                        {
                                          itemType: "group",
                                          cssClass: "groups1",
                                          height: "100%",
                                          width: "100%",
                                          colCountByScreen: {
                                            xl: 5,
                                            lg: 5,
                                            md: 3,
                                            sm: 2,
                                            xs: 1,
                                          },
                                          screenByWidth: function (width) {
                                            return width >= 1200
                                              ? "xl"
                                              : width >= 992
                                                ? "lg"
                                                : width >= 768
                                                  ? "md"
                                                  : width >= 576
                                                    ? "sm"
                                                    : "xs";
                                          },
                                          items: [
                                            {
                                              dataField: "fecha",
                                              label: { text: getTrad("fecha") },
                                              colSpan: 1,
                                              editorType: "dxDateBox",
                                              editorOptions: {
                                                width: "100%",
                                                displayFormat: "dd/MM/yyyy",
                                                min: "01/01/1900",
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_seleccioneFecha",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "idVehiculo",
                                              label: {
                                                text: getTrad("vehiculo"),
                                              },
                                              colSpan: 2,
                                              editorType: "dxLookup",
                                              editorOptions: {
                                                dataSource:
                                                  datasource_tblVehiculo.items(),
                                                valueExpr: "idVehiculo",
                                                displayExpr: "denominacion",
                                                title:
                                                  getTrad("seleccionMatricula"),
                                                closeOnOutsideClick: true,
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaMatricula",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "kmsInicialesVehiculo",
                                              label: {
                                                text: getTrad("kmIniciales"),
                                              },
                                              colSpan: 1,
                                              editorType: "dxNumberBox",
                                              editorOptions: {
                                                step: 0,
                                                min: 0,
                                                max: 999999999,
                                                format: {
                                                  style: "decimal",
                                                  maximumFractionDigits: 0,
                                                },
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaKmIniciales",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "kmsFinalesVehiculo",
                                              label: {
                                                text: getTrad("kmFinales"),
                                              },
                                              colSpan: 1,
                                              editorType: "dxNumberBox",
                                              editorOptions: {
                                                step: 0,
                                                min: 0,
                                                max: 999999999,
                                                format: {
                                                  style: "decimal",
                                                  maximumFractionDigits: 0,
                                                },
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaKmFinales",
                                                  ),
                                                },
                                                {
                                                  type: "compare",
                                                  comparisonTarget:
                                                    function () {
                                                      return parteTransporteSel.kmsInicialesVehiculo;
                                                    },
                                                  comparisonType: ">",
                                                  message: getTrad(
                                                    "alerta_KmFinalesMayorIniciales",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "idNivelCombustible",
                                              label: {
                                                text: getTrad(
                                                  "nivelCombustible",
                                                ),
                                              },
                                              colSpan: 1,
                                              editorType: "dxSelectBox",
                                              editorOptions: {
                                                dataSource:
                                                  datasource_tblNivelCombustible.items(),
                                                valueExpr: "idNivelCombustible",
                                                displayExpr:
                                                  "estadoCombustible",
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaCombustible",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "idPersonaResponsable",
                                              label: {
                                                text: getTrad("responsable"),
                                              },
                                              colSpan: 2,
                                              editorType: "dxLookup",
                                              editorOptions: {
                                                title: getTrad(
                                                  "seleccionResponsable",
                                                ),
                                                dataSource:
                                                  datasource_tblPersona_responsable.items(),
                                                valueExpr: "idPersona",
                                                displayExpr: "nombreCompuesto",
                                                closeOnOutsideClick: true,
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaResponsable",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "horaSalidaLavanderia",
                                              label: {
                                                text: getTrad(
                                                  "horaSalidaLavanderia",
                                                ),
                                              },
                                              colSpan: 1,
                                              editorType: "dxDateBox",
                                              editorOptions: {
                                                width: "100%",
                                                type: "time",
                                                showDropDownButton: false,
                                                displayFormat: "HH:mm",
                                                useMaskBehavior: true,
                                                min: "01/01/1900",
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaHoraSalida",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField:
                                                "horaLlegadaLavanderia",
                                              label: {
                                                text: getTrad(
                                                  "horaLLegadaLavanderia",
                                                ),
                                              },
                                              colSpan: 1,
                                              editorType: "dxDateBox",
                                              editorOptions: {
                                                width: "100%",
                                                type: "time",
                                                showDropDownButton: false,
                                                displayFormat: "HH:mm",
                                                useMaskBehavior: true,
                                                min: "01/01/1900",
                                              },
                                              validationRules: [
                                                {
                                                  type: "required",
                                                  message: getTrad(
                                                    "alerta_SeleccionaHoraLLegada",
                                                  ),
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "observaciones",
                                              label: {
                                                text: getTrad("observaciones"),
                                              },
                                              colSpan: 5,
                                              editorType: "dxTextArea",
                                              editorOptions: {
                                                height: 70,
                                                maxLength: 1000,
                                              },
                                            },
                                          ],
                                        },
                                        {
                                          itemType: "tabbed",
                                          height: "100%",
                                          width: "100%",
                                          tabPanelOptions: {
                                            elementAttr: {
                                              id: "dxTabPanel_parteTransporte",
                                              class: "h5",
                                            },
                                            deferRendering: false,
                                            animationEnabled: true,
                                          },
                                          tabs: [
                                            {
                                              title: getTrad("transportistas"),
                                              items: [
                                                {
                                                  dataField:
                                                    "tblTransportistaNParteTransporte",
                                                  label: { visible: false },
                                                  template: function (
                                                    data,
                                                    itemElement,
                                                  ) {
                                                    itemElement.append(
                                                      $(
                                                        "<div id='dxDataGrid_transportistas' />",
                                                      ).dxDataGrid({
                                                        dataSource:
                                                          data.component.option(
                                                            "formData",
                                                          )[data.dataField],
                                                        //Propiedades
                                                        columnsAutoWidth: true,
                                                        elementAttr: {
                                                          class:
                                                            "dxDataGrid_groups1",
                                                        },
                                                        columns: [
                                                          {
                                                            dataField:
                                                              "idPersona",
                                                            visible: false,
                                                          },
                                                          {
                                                            dataField:
                                                              "nombreCompuesto",
                                                            caption:
                                                              getTrad(
                                                                "transportista",
                                                              ),
                                                            minWidth: 250,
                                                            allowEditing: false,
                                                          },
                                                          {
                                                            caption: " ",
                                                            width: "50px",
                                                            alignment: "center",
                                                            cssClass: "p-0",
                                                            allowEditing: false,
                                                            allowHeaderFiltering: false,
                                                            allowUpdating: false,
                                                            cellTemplate:
                                                              function (
                                                                cellElement,
                                                                cellInfo,
                                                              ) {
                                                                cellElement.on(
                                                                  "click",
                                                                  function () {
                                                                    cellInfo.component.deleteRow(
                                                                      cellInfo.rowIndex,
                                                                    );
                                                                  },
                                                                );

                                                                cellElement.append(
                                                                  $("<div/>")
                                                                    .addClass(
                                                                      "container_spanCentrado",
                                                                    )
                                                                    .append(
                                                                      svg_remove
                                                                        .clone()

                                                                        .css(
                                                                          "height",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "width",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "cursor",
                                                                          "pointer",
                                                                        ),
                                                                    ),
                                                                );
                                                              },
                                                          },
                                                        ],
                                                        onRowRemoved: function (
                                                          e,
                                                        ) {
                                                          dxForm_customCheck(
                                                            data.dataField,
                                                          );
                                                        },
                                                        onToolbarPreparing:
                                                          function (e) {
                                                            e.toolbarOptions.items.unshift(
                                                              {
                                                                location:
                                                                  "before",
                                                                widget:
                                                                  "dxButton",
                                                                options: {
                                                                  icon: "plus",
                                                                  text: getTrad(
                                                                    "añadir",
                                                                  ),
                                                                  onClick:
                                                                    function () {
                                                                      $(
                                                                        "#dxPopup_addTransportista",
                                                                      )
                                                                        .dxPopup(
                                                                          "instance",
                                                                        )
                                                                        .show();
                                                                    },
                                                                },
                                                              },
                                                            );
                                                          },
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                        rowAlternationEnabled: true,
                                                      }),
                                                    );

                                                    itemElement.append(
                                                      $(
                                                        "<div id='dxPopup_addTransportista'/>",
                                                      ).dxPopup({
                                                        width: 400,
                                                        height: 500,
                                                        title: getTrad(
                                                          "seleccionTransportista",
                                                        ),
                                                        contentTemplate:
                                                          function () {
                                                            return $(
                                                              "<div id='dxList_seleccionTransportista' />",
                                                            ).dxList({
                                                              height: "100%",
                                                              keyExpr:
                                                                "idPersona",
                                                              searchExpr:
                                                                "nombreCompuesto",
                                                              displayExpr:
                                                                "nombreCompuesto",
                                                              searchEnabled: true,
                                                              focusStateEnabled: false,
                                                              selectionMode:
                                                                "all",
                                                              showSelectionControls: true,
                                                              pageLoadMode:
                                                                "scrollBottom",
                                                            });
                                                          },
                                                        toolbarItems: [
                                                          {
                                                            toolbar: "bottom",
                                                            location: "after",
                                                            widget: "dxButton",
                                                            options: {
                                                              text: getTrad(
                                                                "guardar",
                                                              ),
                                                              onClick:
                                                                function (e) {
                                                                  var selectedItems =
                                                                    $(
                                                                      "#dxList_seleccionTransportista",
                                                                    )
                                                                      .dxList(
                                                                        "instance",
                                                                      )
                                                                      .option(
                                                                        "selectedItems",
                                                                      );

                                                                  parteTransporteSel.tblTransportistaNParteTransporte.length = 0;
                                                                  $.merge(
                                                                    parteTransporteSel.tblTransportistaNParteTransporte,
                                                                    selectedItems,
                                                                  );
                                                                  dxForm_customCheck(
                                                                    "tblTransportistaNParteTransporte",
                                                                  );

                                                                  $(
                                                                    "#dxPopup_addTransportista",
                                                                  )
                                                                    .dxPopup(
                                                                      "instance",
                                                                    )
                                                                    .hide();
                                                                  $(
                                                                    "#dxDataGrid_transportistas",
                                                                  )
                                                                    .dxDataGrid(
                                                                      "instance",
                                                                    )
                                                                    .refresh();
                                                                },
                                                            },
                                                          },
                                                          {
                                                            toolbar: "bottom",
                                                            location: "after",
                                                            widget: "dxButton",
                                                            options: {
                                                              text: getTrad(
                                                                "cancelar",
                                                              ),
                                                              onClick:
                                                                function (e) {
                                                                  $(
                                                                    "#dxPopup_addTransportista",
                                                                  )
                                                                    .dxPopup(
                                                                      "instance",
                                                                    )
                                                                    .hide();
                                                                },
                                                            },
                                                          },
                                                        ],
                                                        onShowing: function () {
                                                          var idsPersonasSel =
                                                            $.map(
                                                              parteTransporteSel.tblTransportistaNParteTransporte,
                                                              function (
                                                                transportistaSel,
                                                                index,
                                                              ) {
                                                                return transportistaSel.idPersona;
                                                              },
                                                            );
                                                          var dxList_seleccionTransportista =
                                                            $(
                                                              "#dxList_seleccionTransportista",
                                                            ).dxList(
                                                              "instance",
                                                            );

                                                          dxList_seleccionTransportista.beginUpdate();
                                                          dxList_seleccionTransportista.option(
                                                            "dataSource",
                                                            datasource_tblPersona_transportistas.items(),
                                                          );
                                                          dxList_seleccionTransportista.option(
                                                            "selectedItemKeys",
                                                            idsPersonasSel,
                                                          );

                                                          dxList_seleccionTransportista.endUpdate();
                                                        },
                                                      }),
                                                    );
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              title:
                                                getTrad("revisionVehiculo"),
                                              items: [
                                                {
                                                  dataField:
                                                    "tblRevisionVehiculoNParteTransporte",
                                                  label: { visible: false },
                                                  template: function (data) {
                                                    return $(
                                                      "<div id='dxDataGrid_revisionVehiculo'/>",
                                                    ).dxDataGrid({
                                                      dataSource:
                                                        data.component.option(
                                                          "formData",
                                                        )[data.dataField],
                                                      //Propiedades
                                                      columnsAutoWidth: true,
                                                      elementAttr: {
                                                        class:
                                                          "dxDataGrid_groups1",
                                                      },
                                                      editing: {
                                                        allowUpdating: true,
                                                        mode: "cell",
                                                      },
                                                      keyboardNavigation: {
                                                        enterKeyAction:
                                                          "moveFocus",
                                                        enterKeyDirection:
                                                          "row",
                                                        editOnKeyPress: true,
                                                      },
                                                      columns: [
                                                        {
                                                          dataField:
                                                            "idRevisionVehiculo",
                                                          caption:
                                                            getTrad(
                                                              "puntoRevisar",
                                                            ),
                                                          width: "20%",
                                                          sortOrder: "asc",
                                                          lookup: {
                                                            dataSource:
                                                              datasource_tblRevisionVehiculo.items(),
                                                            valueExpr:
                                                              "idRevisionVehiculo",
                                                            displayExpr:
                                                              "denominacion",
                                                            closeOnOutsideClick: true,
                                                          },
                                                          allowEditing: false,
                                                        },
                                                        {
                                                          dataField: "estado",
                                                          caption:
                                                            getTrad("estado"),
                                                          width: "20%",
                                                          allowEditing: false,
                                                          lookup: {
                                                            dataSource:
                                                              enum_estadoVehiculo,
                                                            valueExpr: "value",
                                                            displayExpr:
                                                              "estado",
                                                            closeOnOutsideClick: true,
                                                          },
                                                        },
                                                        {
                                                          dataField:
                                                            "incidencia",
                                                          caption:
                                                            getTrad(
                                                              "incidencia",
                                                            ),
                                                          cssClass:
                                                            "dx-Cell_Editable",
                                                          editorOptions: {
                                                            showClearButton: true,
                                                          },
                                                          setCellValue:
                                                            function (
                                                              newData,
                                                              value,
                                                              currentRowData,
                                                            ) {
                                                              newData.estado =
                                                                !(
                                                                  value.length >
                                                                  0
                                                                );
                                                              newData.incidencia =
                                                                value;
                                                            },
                                                        },
                                                      ],
                                                      showColumnLines: false,
                                                      showRowLines: true,
                                                      rowAlternationEnabled: true,
                                                    });
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              title: getTrad("rutaTransporte"),
                                              items: [
                                                {
                                                  dataField:
                                                    "tblRutaNParteTransporte",
                                                  label: { visible: false },
                                                  template: function (
                                                    data,
                                                    itemElement,
                                                  ) {
                                                    var rutaNParteSel = {};
                                                    var entidadVisible = true;

                                                    function nuevaRutaNParte() {
                                                      rutaNParteSel.idRutaNParteTransporte =
                                                        null;
                                                      rutaNParteSel.horaLlegada =
                                                        new Date(
                                                          convertClienteUtcToLavanderiaUtc(
                                                            LAVANDERIA.GMT,
                                                            new Date(),
                                                          ).setHours(
                                                            0,
                                                            0,
                                                            0,
                                                            0,
                                                          ),
                                                        );
                                                      rutaNParteSel.horaSalida =
                                                        new Date(
                                                          convertClienteUtcToLavanderiaUtc(
                                                            LAVANDERIA.GMT,
                                                            new Date(),
                                                          ).setHours(
                                                            0,
                                                            0,
                                                            0,
                                                            0,
                                                          ),
                                                        );
                                                      rutaNParteSel.idEntidad =
                                                        null;
                                                      rutaNParteSel.idReparto =
                                                        null;
                                                      rutaNParteSel.tblEntidad =
                                                        null;
                                                      rutaNParteSel.tblReparto =
                                                        null;
                                                    }

                                                    function cargaRutaNParte(
                                                      objParte,
                                                    ) {
                                                      rutaNParteSel.idRutaNParteTransporte =
                                                        objParte.idRutaNParteTransporte;
                                                      rutaNParteSel.horaLlegada =
                                                        objParte.horaLlegada;
                                                      rutaNParteSel.horaSalida =
                                                        objParte.horaSalida;
                                                      rutaNParteSel.idEntidad =
                                                        objParte.idEntidad;
                                                      rutaNParteSel.idReparto =
                                                        objParte.idReparto;
                                                      rutaNParteSel.tblEntidad =
                                                        objParte.tblEntidad;
                                                      rutaNParteSel.tblReparto =
                                                        objParte.tblReparto;
                                                    }

                                                    nuevaRutaNParte();

                                                    itemElement.append(
                                                      $(
                                                        "<div id='dxDataGrid_rutaNParteTransporte'/>",
                                                      ).dxDataGrid({
                                                        dataSource:
                                                          data.component.option(
                                                            "formData",
                                                          )[data.dataField],
                                                        //Propiedades
                                                        columnsAutoWidth: true,
                                                        elementAttr: {
                                                          class:
                                                            "dxDataGrid_groups1",
                                                        },
                                                        editing: {
                                                          mode: "cell",
                                                          allowAdding: true,
                                                        },
                                                        keyboardNavigation: {
                                                          enterKeyAction:
                                                            "moveFocus",
                                                          enterKeyDirection:
                                                            "row",
                                                          editOnKeyPress: true,
                                                        },
                                                        columns: [
                                                          {
                                                            dataField:
                                                              "idRutaNParteTransporte",
                                                            visible: false,
                                                          },
                                                          {
                                                            caption: " ",
                                                            width: "30px",
                                                            alignment: "center",
                                                            cssClass: "p-0",
                                                            cellTemplate:
                                                              function (
                                                                cellElement,
                                                                cellInfo,
                                                              ) {
                                                                cellElement.on(
                                                                  "click",
                                                                  function () {
                                                                    cargaRutaNParte(
                                                                      cellInfo.data,
                                                                    );

                                                                    entidadVisible =
                                                                      $.grep(
                                                                        datasource_datos_compaEnti_entidad.items(),
                                                                        function (
                                                                          entidad,
                                                                        ) {
                                                                          return (
                                                                            entidad.idEntidad ===
                                                                            rutaNParteSel.idEntidad
                                                                          );
                                                                        },
                                                                      ).length >
                                                                      0;

                                                                    $(
                                                                      "#dxPopup_addRutaNParteTransporte",
                                                                    )
                                                                      .dxPopup(
                                                                        "instance",
                                                                      )
                                                                      .show();
                                                                  },
                                                                );

                                                                cellElement.append(
                                                                  $("<div/>")
                                                                    .addClass(
                                                                      "container_spanCentrado",
                                                                    )
                                                                    .append(
                                                                      svg_edit
                                                                        .clone()

                                                                        .css(
                                                                          "height",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "width",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "cursor",
                                                                          "pointer",
                                                                        ),
                                                                    ),
                                                                );
                                                              },
                                                            allowSearch: false,
                                                          },
                                                          {
                                                            dataField:
                                                              "horaLlegada",
                                                            caption:
                                                              getTrad(
                                                                "horaLLegada",
                                                              ),
                                                            alignment: "center",
                                                            width: 125,
                                                            dataType: "date",
                                                            format: "HH:mm",
                                                          },
                                                          {
                                                            dataField:
                                                              "horaSalida",
                                                            caption:
                                                              getTrad(
                                                                "horaSalida",
                                                              ),
                                                            alignment: "center",
                                                            width: 125,
                                                            dataType: "date",
                                                            format: "HH:mm",
                                                          },
                                                          {
                                                            dataField:
                                                              "tblEntidad",
                                                            caption:
                                                              getTrad(
                                                                "entidad",
                                                              ),
                                                            dataType: "object",
                                                            minWidth: 250,
                                                            alignment: "left",
                                                            customizeText:
                                                              function (row) {
                                                                return row.value
                                                                  ? row.value
                                                                      .denominacion
                                                                  : "";
                                                              },
                                                          },
                                                          {
                                                            dataField:
                                                              "tblReparto",
                                                            caption:
                                                              getTrad(
                                                                "albaran",
                                                              ),
                                                            dataType: "object",
                                                            width: 150,
                                                            alignment: "left",
                                                            customizeText:
                                                              function (row) {
                                                                return row.value
                                                                  ? row.value
                                                                      .codigo
                                                                  : "";
                                                              },
                                                          },
                                                          {
                                                            caption: " ",
                                                            width: "50px",
                                                            alignment: "center",
                                                            cssClass: "p-0",
                                                            allowEditing: false,
                                                            allowHeaderFiltering: false,
                                                            allowUpdating: false,
                                                            cellTemplate:
                                                              function (
                                                                cellElement,
                                                                cellInfo,
                                                              ) {
                                                                cellElement.on(
                                                                  "click",
                                                                  function () {
                                                                    cellInfo.component.deleteRow(
                                                                      cellInfo.rowIndex,
                                                                    );
                                                                  },
                                                                );

                                                                cellElement.append(
                                                                  $("<div/>")
                                                                    .addClass(
                                                                      "container_spanCentrado",
                                                                    )
                                                                    .append(
                                                                      svg_remove
                                                                        .clone()

                                                                        .css(
                                                                          "height",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "width",
                                                                          "25px",
                                                                        )
                                                                        .css(
                                                                          "cursor",
                                                                          "pointer",
                                                                        ),
                                                                    ),
                                                                );
                                                              },
                                                          },
                                                        ],
                                                        onRowRemoved: function (
                                                          e,
                                                        ) {
                                                          dxForm_customCheck(
                                                            data.dataField,
                                                          );
                                                        },
                                                        onToolbarPreparing:
                                                          function (e) {
                                                            $.each(
                                                              e.toolbarOptions
                                                                .items,
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                switch (
                                                                  item.name
                                                                ) {
                                                                  case "addRowButton":
                                                                    item.location =
                                                                      "before";
                                                                    item.showText =
                                                                      "always";
                                                                    item.options.text =
                                                                      getTrad(
                                                                        "añadir",
                                                                      );
                                                                    item.options.icon =
                                                                      "plus";
                                                                    item.options.onClick =
                                                                      function () {
                                                                        entidadVisible = true;
                                                                        $(
                                                                          "#dxPopup_addRutaNParteTransporte",
                                                                        )
                                                                          .dxPopup(
                                                                            "instance",
                                                                          )
                                                                          .show();
                                                                      };
                                                                    break;
                                                                  default:
                                                                    break;
                                                                }
                                                              },
                                                            );
                                                          },
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                        rowAlternationEnabled: true,
                                                      }),
                                                    );

                                                    itemElement.append(
                                                      $(
                                                        "<div id='dxPopup_addRutaNParteTransporte' />",
                                                      ).dxPopup({
                                                        width: "600px",
                                                        height: "300px",
                                                        title:
                                                          getTrad(
                                                            "establecerRuta",
                                                          ),
                                                        contentTemplate:
                                                          function (
                                                            contentElement,
                                                          ) {
                                                            //FILTROS REPARTOS
                                                            function filtrarRepartos(
                                                              fecha,
                                                            ) {
                                                              if (!fecha) {
                                                                fecha =
                                                                  rutaNParteSel.tblReparto
                                                                    ? rutaNParteSel
                                                                        .tblReparto
                                                                        .fecha
                                                                    : parteTransporteSel.fecha;
                                                              }

                                                              var start =
                                                                new Date(
                                                                  fecha.getTime(),
                                                                );
                                                              start.setHours(
                                                                0,
                                                                0,
                                                                0,
                                                                0,
                                                              );
                                                              start =
                                                                formatDateTime_parameter(
                                                                  start,
                                                                );

                                                              var end =
                                                                new Date(
                                                                  fecha.getTime(),
                                                                );
                                                              end.setHours(
                                                                23,
                                                                59,
                                                                59,
                                                                999,
                                                              );
                                                              end =
                                                                formatDateTime_parameter(
                                                                  end,
                                                                );

                                                              var filter = [
                                                                [
                                                                  "fecha gt " +
                                                                    start +
                                                                    " and fecha lt " +
                                                                    end,
                                                                ],
                                                                "and",
                                                                [
                                                                  "idEntidad",
                                                                  "=",
                                                                  rutaNParteSel.idEntidad,
                                                                ],
                                                              ];

                                                              var filtroActual =
                                                                datasource_tblReparto.filter();

                                                              if (
                                                                !(
                                                                  JSON.stringify(
                                                                    filter,
                                                                  ) ===
                                                                  JSON.stringify(
                                                                    filtroActual,
                                                                  )
                                                                )
                                                              ) {
                                                                datasource_tblReparto.filter(
                                                                  filter,
                                                                );
                                                                datasource_tblReparto.load();
                                                              }
                                                            }

                                                            filtrarRepartos();

                                                            contentElement.append(
                                                              $(
                                                                "<div id='dxForm_rutaNParte'/>",
                                                              ).dxForm({
                                                                formData:
                                                                  rutaNParteSel,
                                                                validationGroup:
                                                                  "dxForm_rutaNParte_validationGroup",
                                                                labelLocation:
                                                                  "top",
                                                                height: "100%",
                                                                scrollingEnabled: true,
                                                                colCountByScreen:
                                                                  {
                                                                    xl: 2,
                                                                    lg: 2,
                                                                    md: 2,
                                                                    sm: 1,
                                                                    xs: 1,
                                                                  },
                                                                screenByWidth:
                                                                  function (
                                                                    width,
                                                                  ) {
                                                                    return width >=
                                                                      1200
                                                                      ? "xl"
                                                                      : width >=
                                                                          992
                                                                        ? "lg"
                                                                        : width >=
                                                                            768
                                                                          ? "md"
                                                                          : width >=
                                                                              576
                                                                            ? "sm"
                                                                            : "xs";
                                                                  },
                                                                items: [
                                                                  {
                                                                    dataField:
                                                                      "horaLlegada",
                                                                    label: {
                                                                      text: getTrad(
                                                                        "horaLLegada",
                                                                      ),
                                                                    },
                                                                    editorType:
                                                                      "dxDateBox",
                                                                    editorOptions:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                        type: "time",
                                                                        showDropDownButton: false,
                                                                        displayFormat:
                                                                          "HH:mm",
                                                                        useMaskBehavior: true,
                                                                        min: "01/01/1900",
                                                                      },
                                                                    validationRules:
                                                                      [
                                                                        {
                                                                          type: "required",
                                                                          message:
                                                                            getTrad(
                                                                              "alerta_SeleccionaHoraLLegada",
                                                                            ) +
                                                                            ": " +
                                                                            getTrad(
                                                                              "campoNecesario",
                                                                            ),
                                                                        },
                                                                      ],
                                                                  },
                                                                  {
                                                                    dataField:
                                                                      "horaSalida",
                                                                    label: {
                                                                      text: getTrad(
                                                                        "horaSalida",
                                                                      ),
                                                                    },
                                                                    editorType:
                                                                      "dxDateBox",
                                                                    editorOptions:
                                                                      {
                                                                        width:
                                                                          "100%",
                                                                        type: "time",
                                                                        showDropDownButton: false,
                                                                        displayFormat:
                                                                          "HH:mm",
                                                                        useMaskBehavior: true,
                                                                        min: "01/01/1900",
                                                                      },
                                                                    validationRules:
                                                                      [
                                                                        {
                                                                          type: "required",
                                                                          message:
                                                                            getTrad(
                                                                              "alerta_SeleccionaHoraSalida",
                                                                            ) +
                                                                            ": " +
                                                                            getTrad(
                                                                              "campoNecesario",
                                                                            ),
                                                                        },
                                                                      ],
                                                                  },
                                                                  {
                                                                    dataField:
                                                                      "idEntidad",
                                                                    label: {
                                                                      text: getTrad(
                                                                        "entidad",
                                                                      ),
                                                                    },
                                                                    editorType:
                                                                      "dxDropDownBox",
                                                                    editorOptions:
                                                                      {
                                                                        displayExpr:
                                                                          "denominacion",
                                                                        valueExpr:
                                                                          "idEntidad",
                                                                        dataSource:
                                                                          datasource_datos_compaEnti_entidad.items(),
                                                                        dropDownOptions:
                                                                          {
                                                                            minHeight:
                                                                              "500px",
                                                                            minWidth:
                                                                              "600px",
                                                                            position:
                                                                              {
                                                                                my: "center",
                                                                                at: "center",
                                                                                of: window,
                                                                              },
                                                                            closeOnOutsideClick: true,
                                                                            showTitle: true,
                                                                            title:
                                                                              getTrad(
                                                                                "seleccioneEntidad",
                                                                              ),
                                                                            showCloseButton: true,
                                                                          },
                                                                        contentTemplate:
                                                                          function (
                                                                            templateData,
                                                                            contentElement,
                                                                          ) {
                                                                            contentElement.append(
                                                                              $(
                                                                                "<div />",
                                                                              ).dxBox(
                                                                                {
                                                                                  direction:
                                                                                    "row",
                                                                                  align:
                                                                                    "space-around",
                                                                                  crossAlign:
                                                                                    "stretch",
                                                                                  height:
                                                                                    "100%",
                                                                                  width:
                                                                                    "100%",
                                                                                  items:
                                                                                    [
                                                                                      {
                                                                                        ratio: 1,
                                                                                        template:
                                                                                          function (
                                                                                            itemData,
                                                                                            itemIndex,
                                                                                            itemElement,
                                                                                          ) {
                                                                                            itemElement.css(
                                                                                              "padding-right",
                                                                                              "15px",
                                                                                            );

                                                                                            // COMPAÑÍAS
                                                                                            itemElement.append(
                                                                                              $(
                                                                                                "<div/>",
                                                                                              ).dxBox(
                                                                                                {
                                                                                                  direction:
                                                                                                    "col",
                                                                                                  align:
                                                                                                    "space-around",
                                                                                                  crossAlign:
                                                                                                    "stretch",
                                                                                                  height:
                                                                                                    "100%",
                                                                                                  width:
                                                                                                    "100%",
                                                                                                  items:
                                                                                                    [
                                                                                                      {
                                                                                                        baseSize: 40,
                                                                                                        shrink: 0,
                                                                                                        ratio: 0,
                                                                                                        template:
                                                                                                          function () {
                                                                                                            return $(
                                                                                                              "<div class='font-size'>" +
                                                                                                                getTrad(
                                                                                                                  "compañia",
                                                                                                                ) +
                                                                                                                "</div>",
                                                                                                            );
                                                                                                          },
                                                                                                      },
                                                                                                      {
                                                                                                        ratio: 1,
                                                                                                        template:
                                                                                                          function () {
                                                                                                            return $(
                                                                                                              "<div>",
                                                                                                            ).dxList(
                                                                                                              {
                                                                                                                dataSource:
                                                                                                                  datasource_datos_compaEnti.items(),
                                                                                                                focusStateEnabled: false,
                                                                                                                selectionMode:
                                                                                                                  "single",
                                                                                                                height:
                                                                                                                  "100%",
                                                                                                                keyExpr:
                                                                                                                  "idCompañia",
                                                                                                                displayExpr:
                                                                                                                  "denominacion",
                                                                                                                pageLoadMode:
                                                                                                                  "scrollBottom",
                                                                                                                selectedItemKeys:
                                                                                                                  rutaNParteSel.tblEntidad
                                                                                                                    ? [
                                                                                                                        rutaNParteSel
                                                                                                                          .tblEntidad
                                                                                                                          .idCompañia,
                                                                                                                      ]
                                                                                                                    : -1,
                                                                                                                // EVENTOS
                                                                                                                onSelectionChanged:
                                                                                                                  function (
                                                                                                                    e,
                                                                                                                  ) {
                                                                                                                    var dataSource =
                                                                                                                      null;
                                                                                                                    if (
                                                                                                                      e
                                                                                                                        .addedItems
                                                                                                                        .length >
                                                                                                                      0
                                                                                                                    ) {
                                                                                                                      var compañia =
                                                                                                                        e
                                                                                                                          .addedItems[0];

                                                                                                                      dataSource =
                                                                                                                        compañia.tblEntidad;
                                                                                                                    }

                                                                                                                    var dxList_entidad =
                                                                                                                      $(
                                                                                                                        "#dxList_entidad",
                                                                                                                      ).dxList(
                                                                                                                        "instance",
                                                                                                                      );
                                                                                                                    dxList_entidad.option(
                                                                                                                      "selectedItemKeys",
                                                                                                                      [],
                                                                                                                    );
                                                                                                                    dxList_entidad.option(
                                                                                                                      "dataSource",
                                                                                                                      dataSource,
                                                                                                                    );
                                                                                                                  },
                                                                                                              },
                                                                                                            );
                                                                                                          },
                                                                                                      },
                                                                                                    ],
                                                                                                },
                                                                                              ),
                                                                                            );
                                                                                          },
                                                                                      },
                                                                                      {
                                                                                        ratio: 1,
                                                                                        template:
                                                                                          function (
                                                                                            itemData,
                                                                                            itemIndex,
                                                                                            itemElement,
                                                                                          ) {
                                                                                            //ENTIDADES
                                                                                            itemElement.append(
                                                                                              $(
                                                                                                "<div/>",
                                                                                              ).dxBox(
                                                                                                {
                                                                                                  direction:
                                                                                                    "col",
                                                                                                  align:
                                                                                                    "space-around",
                                                                                                  crossAlign:
                                                                                                    "stretch",
                                                                                                  height:
                                                                                                    "100%",
                                                                                                  width:
                                                                                                    "100%",
                                                                                                  items:
                                                                                                    [
                                                                                                      {
                                                                                                        baseSize: 40,
                                                                                                        shrink: 0,
                                                                                                        ratio: 0,
                                                                                                        template:
                                                                                                          function () {
                                                                                                            return $(
                                                                                                              "<div class='font-size'>" +
                                                                                                                getTrad(
                                                                                                                  "entidad",
                                                                                                                ) +
                                                                                                                "</div>",
                                                                                                            );
                                                                                                          },
                                                                                                      },
                                                                                                      {
                                                                                                        ratio: 1,
                                                                                                        template:
                                                                                                          function () {
                                                                                                            var dataSource =
                                                                                                              null;

                                                                                                            var compa =
                                                                                                              $.grep(
                                                                                                                datasource_datos_compaEnti.items(),
                                                                                                                function (
                                                                                                                  compa,
                                                                                                                ) {
                                                                                                                  var idCompañia =
                                                                                                                    rutaNParteSel.tblEntidad
                                                                                                                      ? [
                                                                                                                          rutaNParteSel
                                                                                                                            .tblEntidad
                                                                                                                            .idCompañia,
                                                                                                                        ]
                                                                                                                      : -1;
                                                                                                                  return (
                                                                                                                    compa.idCompañia ===
                                                                                                                    idCompañia
                                                                                                                  );
                                                                                                                },
                                                                                                              );

                                                                                                            if (
                                                                                                              compa.length >
                                                                                                              0
                                                                                                            ) {
                                                                                                              dataSource =
                                                                                                                compa[0]
                                                                                                                  .tblEntidad;
                                                                                                            }

                                                                                                            return $(
                                                                                                              "<div id='dxList_entidad'>",
                                                                                                            ).dxList(
                                                                                                              {
                                                                                                                focusStateEnabled: false,
                                                                                                                height:
                                                                                                                  "100%",
                                                                                                                dataSource:
                                                                                                                  dataSource,
                                                                                                                keyExpr:
                                                                                                                  "idEntidad",
                                                                                                                displayExpr:
                                                                                                                  "denominacion",
                                                                                                                pageLoadMode:
                                                                                                                  "scrollBottom",
                                                                                                                searchEnabled: true,
                                                                                                                searchExpr:
                                                                                                                  "denominacion",
                                                                                                                selectionMode:
                                                                                                                  "single",
                                                                                                                selectedItemKeys:
                                                                                                                  templateData.component.option(
                                                                                                                    "value",
                                                                                                                  ),
                                                                                                                // EVENTOS
                                                                                                                onSelectionChanged:
                                                                                                                  function (
                                                                                                                    e,
                                                                                                                  ) {
                                                                                                                    if (
                                                                                                                      e
                                                                                                                        .addedItems
                                                                                                                        .length >
                                                                                                                      0
                                                                                                                    ) {
                                                                                                                      var entidad =
                                                                                                                        e
                                                                                                                          .addedItems[0];
                                                                                                                      rutaNParteSel.tblEntidad =
                                                                                                                        entidad;

                                                                                                                      ////Limpiamos el selector de  repartos y lo habilitamos
                                                                                                                      rutaNParteSel.idReparto =
                                                                                                                        null;
                                                                                                                      rutaNParteSel.tblReparto =
                                                                                                                        null;
                                                                                                                      var dxForm_rutaNParte =
                                                                                                                        $(
                                                                                                                          "#dxForm_rutaNParte",
                                                                                                                        ).dxForm(
                                                                                                                          "instance",
                                                                                                                        );
                                                                                                                      dxForm_rutaNParte
                                                                                                                        .getEditor(
                                                                                                                          "idReparto",
                                                                                                                        )
                                                                                                                        .option(
                                                                                                                          "disabled",
                                                                                                                          false,
                                                                                                                        );

                                                                                                                      templateData.component.option(
                                                                                                                        "value",
                                                                                                                        entidad.idEntidad,
                                                                                                                      );
                                                                                                                      templateData.component.close();
                                                                                                                    }
                                                                                                                  },
                                                                                                              },
                                                                                                            );
                                                                                                          },
                                                                                                      },
                                                                                                    ],
                                                                                                },
                                                                                              ),
                                                                                            );
                                                                                          },
                                                                                      },
                                                                                    ],
                                                                                },
                                                                              ),
                                                                            );
                                                                          },
                                                                        onValueChanged:
                                                                          function (
                                                                            e,
                                                                          ) {
                                                                            $(
                                                                              "#dxForm_rutaNParte",
                                                                            )
                                                                              .dxForm(
                                                                                "instance",
                                                                              )
                                                                              .repaint();
                                                                            filtrarRepartos();
                                                                          },
                                                                      },
                                                                    validationRules:
                                                                      [
                                                                        {
                                                                          type: "required",
                                                                          message:
                                                                            getTrad(
                                                                              "entidad",
                                                                            ) +
                                                                            ": " +
                                                                            getTrad(
                                                                              "campoNecesario",
                                                                            ),
                                                                        },
                                                                      ],
                                                                  },
                                                                  {
                                                                    dataField:
                                                                      "idReparto",
                                                                    label: {
                                                                      text: getTrad(
                                                                        "albaran",
                                                                      ),
                                                                    },
                                                                    editorType:
                                                                      "dxLookup",
                                                                    editorOptions:
                                                                      {
                                                                        dataSource:
                                                                          datasource_tblReparto,
                                                                        displayExpr:
                                                                          "codigo",
                                                                        valueExpr:
                                                                          "idReparto",
                                                                        usePopover: true,
                                                                        popupHeight:
                                                                          "450px",
                                                                        popupWidth:
                                                                          "300px",
                                                                        position:
                                                                          {
                                                                            my: "center",
                                                                            at: "center",
                                                                            of: window,
                                                                          },
                                                                        closeOnOutsideClick: true,
                                                                        titleTemplate:
                                                                          function (
                                                                            titleElement,
                                                                          ) {
                                                                            titleElement.css(
                                                                              "padding",
                                                                              "0px",
                                                                            );

                                                                            titleElement.append(
                                                                              $(
                                                                                "<div />",
                                                                              )
                                                                                .css(
                                                                                  "padding",
                                                                                  "10px 15px",
                                                                                )
                                                                                .css(
                                                                                  "font-size",
                                                                                  "20px",
                                                                                )
                                                                                .css(
                                                                                  "border-bottom",
                                                                                  "1px solid gainsboro",
                                                                                )
                                                                                .text(
                                                                                  getTrad(
                                                                                    "albaran",
                                                                                  ),
                                                                                ),
                                                                            );

                                                                            titleElement.append(
                                                                              $(
                                                                                "<div>",
                                                                              )
                                                                                .css(
                                                                                  "padding",
                                                                                  "6px 10px",
                                                                                )
                                                                                .append(
                                                                                  $(
                                                                                    "<div>",
                                                                                  )
                                                                                    .css(
                                                                                      "font-weight",
                                                                                      700,
                                                                                    )
                                                                                    .text(
                                                                                      getTrad(
                                                                                        "fecha",
                                                                                      ) +
                                                                                        ":",
                                                                                    ),
                                                                                )
                                                                                .append(
                                                                                  $(
                                                                                    "<div />",
                                                                                  ).dxDateBox(
                                                                                    {
                                                                                      width:
                                                                                        "100%",
                                                                                      displayFormat:
                                                                                        "dd/MM/yyyy",
                                                                                      value:
                                                                                        rutaNParteSel.tblReparto
                                                                                          ? rutaNParteSel
                                                                                              .tblReparto
                                                                                              .fecha
                                                                                          : parteTransporteSel.fecha,
                                                                                      showClearButton: true,
                                                                                      onValueChanged:
                                                                                        function (
                                                                                          e,
                                                                                        ) {
                                                                                          filtrarRepartos(
                                                                                            e.value,
                                                                                          );
                                                                                        },
                                                                                    },
                                                                                  ),
                                                                                ),
                                                                            );
                                                                          },
                                                                        onOpened:
                                                                          function (
                                                                            e,
                                                                          ) {
                                                                            filtrarRepartos();
                                                                          },
                                                                        onSelectionChanged:
                                                                          function (
                                                                            e,
                                                                          ) {
                                                                            rutaNParteSel.tblReparto =
                                                                              e.selectedItem;
                                                                          },
                                                                      },
                                                                  },
                                                                ],
                                                                onContentReady:
                                                                  function (e) {
                                                                    var form =
                                                                      e.component;

                                                                    if (
                                                                      entidadVisible
                                                                    ) {
                                                                      form
                                                                        .getEditor(
                                                                          "idReparto",
                                                                        )
                                                                        .option(
                                                                          "disabled",
                                                                          !rutaNParteSel.idEntidad,
                                                                        );
                                                                    }

                                                                    form
                                                                      .getEditor(
                                                                        "horaSalida",
                                                                      )
                                                                      .element()
                                                                      .find(
                                                                        ".dx-texteditor-input",
                                                                      )
                                                                      .off(
                                                                        "dxmousewheel",
                                                                      );
                                                                    form
                                                                      .getEditor(
                                                                        "horaLlegada",
                                                                      )
                                                                      .element()
                                                                      .find(
                                                                        ".dx-texteditor-input",
                                                                      )
                                                                      .off(
                                                                        "dxmousewheel",
                                                                      );
                                                                  },
                                                              }),
                                                            );
                                                          },
                                                        toolbarItems: [
                                                          {
                                                            toolbar: "top",
                                                            location: "center",
                                                            html: "<span id='dxPopup_rutaNParte_title'></span>",
                                                          },
                                                          {
                                                            toolbar: "bottom",
                                                            location: "after",
                                                            widget: "dxButton",
                                                            options: {
                                                              text: getTrad(
                                                                "guardar",
                                                              ),
                                                              onClick:
                                                                function () {
                                                                  var dxForm_rutaNParte =
                                                                    $(
                                                                      "#dxForm_rutaNParte",
                                                                    ).dxForm(
                                                                      "instance",
                                                                    );
                                                                  var formData =
                                                                    dxForm_rutaNParte.option(
                                                                      "formData",
                                                                    );

                                                                  if (
                                                                    formData &&
                                                                    dxForm_rutaNParte.validate()
                                                                      .isValid
                                                                  ) {
                                                                    if (
                                                                      rutaNParteSel.idRutaNParteTransporte
                                                                    ) {
                                                                      //Update
                                                                      var rutaNParteMod =
                                                                        $.grep(
                                                                          parteTransporteSel.tblRutaNParteTransporte,
                                                                          function (
                                                                            rutaNParte,
                                                                          ) {
                                                                            return (
                                                                              rutaNParte.idRutaNParteTransporte ===
                                                                              rutaNParteSel.idRutaNParteTransporte
                                                                            );
                                                                          },
                                                                        )[0];

                                                                      rutaNParteMod.horaLlegada =
                                                                        rutaNParteSel.horaLlegada;
                                                                      rutaNParteMod.horaSalida =
                                                                        rutaNParteSel.horaSalida;

                                                                      if (
                                                                        entidadVisible
                                                                      ) {
                                                                        rutaNParteMod.idEntidad =
                                                                          rutaNParteSel.idEntidad;
                                                                        rutaNParteMod.idReparto =
                                                                          rutaNParteSel.idReparto;
                                                                        rutaNParteMod.tblEntidad =
                                                                          rutaNParteSel.tblEntidad;
                                                                        rutaNParteMod.tblReparto =
                                                                          rutaNParteSel.tblReparto;
                                                                      }

                                                                      $(
                                                                        "#dxDataGrid_rutaNParteTransporte",
                                                                      )
                                                                        .dxDataGrid(
                                                                          "instance",
                                                                        )
                                                                        .refresh();
                                                                      $(
                                                                        "#dxPopup_addRutaNParteTransporte",
                                                                      )
                                                                        .dxPopup(
                                                                          "instance",
                                                                        )
                                                                        .hide();
                                                                    } else {
                                                                      //Insert
                                                                      query(
                                                                        parteTransporteSel.tblRutaNParteTransporte,
                                                                      )
                                                                        .min(
                                                                          "idRutaNParteTransporte",
                                                                        )
                                                                        .done(
                                                                          function (
                                                                            result,
                                                                          ) {
                                                                            //Generamos id único negativo.
                                                                            result =
                                                                              result
                                                                                ? result
                                                                                : 0;

                                                                            var id =
                                                                              -1;
                                                                            if (
                                                                              result <
                                                                              0
                                                                            ) {
                                                                              id =
                                                                                result -
                                                                                1;
                                                                            }

                                                                            var rutaAdd =
                                                                              $.extend(
                                                                                true,
                                                                                {},
                                                                                rutaNParteSel,
                                                                              );
                                                                            rutaAdd.idRutaNParteTransporte =
                                                                              id;

                                                                            parteTransporteSel.tblRutaNParteTransporte.push(
                                                                              rutaAdd,
                                                                            );
                                                                            dxForm_customCheck(
                                                                              data.dataField,
                                                                            );

                                                                            $(
                                                                              "#dxDataGrid_rutaNParteTransporte",
                                                                            )
                                                                              .dxDataGrid(
                                                                                "instance",
                                                                              )
                                                                              .refresh();
                                                                            $(
                                                                              "#dxPopup_addRutaNParteTransporte",
                                                                            )
                                                                              .dxPopup(
                                                                                "instance",
                                                                              )
                                                                              .hide();
                                                                          },
                                                                        );
                                                                    }
                                                                  }
                                                                },
                                                            },
                                                          },
                                                          {
                                                            toolbar: "bottom",
                                                            location: "after",
                                                            widget: "dxButton",
                                                            options: {
                                                              text: getTrad(
                                                                "cancelar",
                                                              ),
                                                              onClick:
                                                                function () {
                                                                  $(
                                                                    "#dxPopup_addRutaNParteTransporte",
                                                                  )
                                                                    .dxPopup(
                                                                      "instance",
                                                                    )
                                                                    .hide();
                                                                },
                                                            },
                                                          },
                                                        ],
                                                        onShowing: function (
                                                          e,
                                                        ) {
                                                          e.component.option(
                                                            "height",
                                                            entidadVisible
                                                              ? "325px"
                                                              : "230px",
                                                          );

                                                          var form =
                                                            $(
                                                              "#dxForm_rutaNParte",
                                                            ).dxForm(
                                                              "instance",
                                                            );
                                                          form.beginUpdate();

                                                          form.itemOption(
                                                            "idEntidad",
                                                            "visible",
                                                            entidadVisible,
                                                          );
                                                          form.itemOption(
                                                            "idReparto",
                                                            "visible",
                                                            entidadVisible,
                                                          );

                                                          form.endUpdate();
                                                        },
                                                        onHidden: function (e) {
                                                          var dxForm_rutaNParte =
                                                            $(
                                                              "#dxForm_rutaNParte",
                                                            ).dxForm(
                                                              "instance",
                                                            );

                                                          dxForm_rutaNParte.beginUpdate();

                                                          nuevaRutaNParte();
                                                          dxForm_rutaNParte.updateData(
                                                            rutaNParteSel,
                                                          );
                                                          dxForm_rutaNParte.repaint();

                                                          dxForm_rutaNParte.endUpdate();
                                                        },
                                                      }),
                                                    );
                                                  },
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                      onContentReady: function (e) {
                                        var form = e.component;
                                        form
                                          .getEditor("horaSalidaLavanderia")
                                          .element()
                                          .find(".dx-texteditor-input")
                                          .off("dxmousewheel");
                                        form
                                          .getEditor("horaLlegadaLavanderia")
                                          .element()
                                          .find(".dx-texteditor-input")
                                          .off("dxmousewheel");
                                      },
                                    }),
                                  );
                                },
                              },
                            ],
                          }),
                        );
                      },
                    },
                  ],
                }),
              );
            },
          },
          {
            ratio: 2,
            template: function (e, index, item) {
              item.append(
                $("<div />").dxBox({
                  direction: "col",
                  align: "space-around",
                  crossAlign: "stretch",
                  height: "100%",
                  items: [
                    {
                      ratio: 1,
                      template: function (e, index, item) {
                        item.css("padding-top", "23px");
                        item.append(
                          $(
                            "<div id='dxDataGrid_PartesTransporte' />",
                          ).dxDataGrid({
                            //Datos
                            dataSource: datasource_tblParteTransporte,
                            remoteOperations: true,
                            //Propiedades
                            columnsAutoWidth: true,
                            height: "100%",
                            filterRow: {
                              visible: true,
                              applyFilter: "auto",
                            },
                            headerFilter: {
                              visible: true,
                            },
                            searchPanel: {
                              visible: true,
                              width: 240,
                              placeholder: getTrad("busqueda"),
                            },
                            selection: {
                              mode: "single",
                            },
                            paging: {
                              pageSize: 50,
                            },
                            pager: {
                              showPageSizeSelector: true,
                              allowedPageSizes: [20, 50, 100],
                              showInfo: true,
                            },
                            hoverStateEnabled: true,
                            columns: [
                              {
                                dataField: "fecha",
                                caption: getTrad("fecha"),
                                width: 150,
                                alignment: "center",
                                dataType: "date",
                                format: "dd/MM/yyyy",
                                sortOrder: "desc",
                                allowHeaderFiltering: false,
                              },
                              {
                                dataField: "tblVehiculo.denominacion",
                                caption: getTrad("vehiculo"),
                                minWidth: 150,
                                calculateFilterExpression: function (
                                  filterValue,
                                  selectedFilterOperation,
                                ) {
                                  return [
                                    "concat(concat(tblVehiculo.matricula, ' - '), tblVehiculo.denominacion)",
                                    "contains",
                                    filterValue,
                                  ];
                                },
                              },
                              {
                                dataField: "horaSalidaLavanderia",
                                caption: getTrad("horaSalida"),
                                width: 100,
                                alignment: "center",
                                dataType: "date",
                                format: "HH:mm",
                                allowHeaderFiltering: false,
                                allowFiltering: false,
                              },
                              {
                                dataField: "horaLlegadaLavanderia",
                                caption: getTrad("horaLLegada"),
                                width: 100,
                                alignment: "center",
                                dataType: "date",
                                format: "HH:mm",
                                allowHeaderFiltering: false,
                                allowFiltering: false,
                              },
                              {
                                visible: false,
                                calculateFilterExpression: function (
                                  filterValue,
                                  selectedFilterOperation,
                                ) {
                                  return [
                                    [
                                      "tblTransportistaNParteTransporte/any(f: contains(concat(concat(f/nombre, ' '), f/apellidos), '" +
                                        filterValue +
                                        "'))",
                                    ],
                                    "or",
                                    [
                                      "tblRutaNParteTransporte/any(f: contains(f/tblEntidad/denominacion, '" +
                                        filterValue +
                                        "') or contains(f/tblReparto/codigo, '" +
                                        filterValue +
                                        "'))",
                                    ],
                                  ];
                                },
                              },
                            ],
                            //Eventos
                            onContentReady: function (e) {
                              //Se asigna el dxTooltip a el busador
                              if ($("#dxTooltip_searchPanel").length > 0) {
                                $("#dxTooltip_searchPanel")
                                  .dxTooltip("instance")
                                  .option(
                                    "target",
                                    $(
                                      "#dxDataGrid_PartesTransporte .dx-searchbox",
                                    ),
                                  );
                              }
                            },
                            onSelectionChanged: function (e) {
                              var data = e.selectedRowsData[0];
                              if (data) {
                                if (dxForm_hasChanges()) {
                                  dxMensajePregunta(
                                    getTrad("preg_PerderCambios"),
                                    [
                                      [
                                        getTrad("aceptar"),
                                        function () {
                                          cargaParteTransporte(data);
                                        },
                                        "danger",
                                      ],
                                      [
                                        getTrad("cancelar"),
                                        function () {
                                          e.component.option(
                                            "selectedRowKeys",
                                            e.currentDeselectedRowKeys,
                                          );
                                        },
                                      ],
                                    ],
                                  );
                                } else {
                                  cargaParteTransporte(data);
                                }
                              } else {
                                if (parteTransporteSel.idParteTransporte) {
                                  nuevoParteTransporte();
                                }
                              }
                            },
                            onToolbarPreparing: function (e) {
                              e.toolbarOptions.items.unshift(
                                {
                                  location: "before",
                                  template: function () {
                                    return $(
                                      "<div class ='font-size' style='margin-top : 10px;'>",
                                    ).text(getTrad("partesAnteriores"));
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  showText: "inMenu",
                                  locateInMenu: "auto",
                                  options: {
                                    text: getTrad("limpiarFiltro"),
                                    icon: " icon_EliminarFiltros",
                                    onClick: function () {
                                      e.component.clearFilter();
                                      notify({
                                        message: getTrad(
                                          "aviso_C_FiltroRestaurado",
                                        ),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                      });
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  showText: "inMenu",
                                  options: {
                                    icon: "refresh",
                                    text: getTrad("actualizar"),
                                    onClick: function () {
                                      e.component.refresh(true);
                                    },
                                  },
                                },
                              );
                            },
                            onRowPrepared: function (info) {
                              if (info.rowType === "data") {
                                info.rowElement.css("cursor", "pointer");
                              }
                            },
                            //Estilos
                            showColumnLines: false,
                            showRowLines: true,
                            rowAlternationEnabled: true,
                          }),
                        );

                        item.append(
                          $("<div id='dxTooltip_searchPanel' />").dxTooltip({
                            height: "42px",
                            showEvent: "dxhoverstart",
                            hideEvent: "dxhoverend",
                            position: "top",
                            contentTemplate: function (contentElement) {
                              contentElement.html(
                                getTrad("busq_parteTransporte"),
                              );
                            },
                            animation: {
                              show: {
                                type: "pop",
                                from: {
                                  scale: 0.1,
                                  opacity: 0,
                                },
                                to: {
                                  opacity: 1,
                                  scale: 1,
                                },
                              },
                              hide: {
                                type: "pop",
                                from: {
                                  scale: 1,
                                  opacity: 1,
                                },
                                to: {
                                  opacity: 0,
                                  scale: 0.1,
                                },
                              },
                            },
                          }),
                        );
                      },
                    },
                  ],
                }),
              );
            },
          },
        ],
      });
    })
    .always(function () {
      deferred.resolve();
    });

  function dxForm_hasChanges(e) {
    var obj = tratarDatos($.extend(true, {}, parteTransporteSel));
    var obj_bak = tratarDatos($.extend(true, {}, parteTransporteSel_bak));

    return !(JSON.stringify(obj) === JSON.stringify(obj_bak));
  }

  function dxForm_customCheck(dataField) {
    var isValid_Transportistas = true;
    var isValid_RutaNParteTransporte = true;

    if (!dataField || dataField === "tblTransportistaNParteTransporte") {
      isValid_Transportistas =
        parteTransporteSel.tblTransportistaNParteTransporte.length > 0;
    }

    if (!dataField || dataField === "tblRutaNParteTransporte") {
      isValid_RutaNParteTransporte =
        parteTransporteSel.tblRutaNParteTransporte.length > 0;
    }

    var warning = svg_warning
      .clone()
      .css("height", "25px")
      .css("width", "25px")
      .css("cursor", "pointer");

    var serializer = new XMLSerializer();
    var warningHtml = serializer.serializeToString(warning[0]);

    var dxTabPanel_parteTransporte = $(
      "#dxTabPanel_parteTransporte",
    ).dxTabPanel("instance");
    dxTabPanel_parteTransporte.beginUpdate();

    if (!dataField || dataField === "tblTransportistaNParteTransporte") {
      dxTabPanel_parteTransporte.option(
        "items[0].icon",
        isValid_Transportistas ? null : warningHtml,
      );
    }

    if (!dataField || dataField === "tblRutaNParteTransporte") {
      dxTabPanel_parteTransporte.option(
        "items[2].icon",
        isValid_RutaNParteTransporte ? null : warningHtml,
      );
    }

    dxTabPanel_parteTransporte.endUpdate();

    return isValid_Transportistas && isValid_RutaNParteTransporte;
  }

  function tratarDatos(obj) {
    delete obj.tblVehiculo;

    $.each(
      obj.tblTransportistaNParteTransporte,
      function (index, transportistaNParteTransporte) {
        delete transportistaNParteTransporte.nombreCompuesto;
        delete transportistaNParteTransporte.nombre;
        delete transportistaNParteTransporte.apellidos;
      },
    );

    $.each(obj.tblRutaNParteTransporte, function (index, rutaNParteTransporte) {
      delete rutaNParteTransporte.tblReparto;
      delete rutaNParteTransporte.tblEntidad;
    });

    $.each(
      obj.tblRevisionVehiculoNParteTransporte,
      function (index, revisionVehiculoNParteTransporte) {
        delete revisionVehiculoNParteTransporte.denominacion;
      },
    );

    obj.idLavanderia = LAVANDERIA.idLavanderia;

    return obj;
  }

  return deferred.promise();
}
