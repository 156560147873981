import { userConstants } from "../constants";
import { history, getAplicaciones, getUrlFormularioInicio } from "../helpers";

import {
    userService,
    lavanderiasService,
    languageService,
    estructuraMenuService,
    logUsoService,
    signalRCoreService,
    signalRService,
} from "../services";
import { globalActions } from "./";
import { setEnableFullScreen } from "../reducers/ThemeOptions";

import $ from "jquery";
import { empresasService } from "services/empresas";

export const userActions = {
    login,
    logout,
    refreshToken,
    logout_SesionCaducada,
    change_username,
    loginChangeUser,
};

function login(username, password) {
    return (dispatch) => {
        dispatch(request({ username }));

        userService.login(username, password).then(
            (user) => {
                $.when(
                    lavanderiasService.load(user),
                    empresasService.load(user),
                    languageService.load(),
                    estructuraMenuService.load()
                ).then(function () {
                    localStorage.setItem("idUsuarioOriginal", null);
                    let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));
                    let empresas = JSON.parse(localStorage.getItem("empresas"));
                    let idiomas = JSON.parse(localStorage.getItem("idiomas"));

                    //LogAcciones
                    logUsoService.setLog(1, username);

                    dispatch(success(user));
                    dispatch(globalActions.setIdioma(idiomas.find((x) => x.idIdioma === user.idioma)));
                    dispatch(globalActions.setResolucion(window.screen));

                    let lavInicio;
                    if (lavanderias.length > 0) {
                        if (user.idLavanderiaInicio) {
                            lavInicio = lavanderias.find((x) => x.idLavanderia === user.idLavanderiaInicio);
                            dispatch(globalActions.setLavanderia(lavInicio));
                        } else {
                            lavInicio = lavanderias[0];
                            dispatch(globalActions.setLavanderia(lavInicio));
                        }
                    }

                    if (empresas.length /* === 1 */ > 0) {
                        dispatch(globalActions.setEmpresa(empresas[0]));
                    }

                    if (user.enableFullScreen === true) {
                        dispatch(setEnableFullScreen(true));
                    }

                    let aplicaciones = getAplicaciones(null);
                    let appInicio;

                    if (aplicaciones.length > 0) {
                        if (user.tblFormularioInicio && user.tblFormularioInicio.tblAplicacion) {
                            appInicio = user.tblFormularioInicio.tblAplicacion;
                        } else if (user.idAplicacionInicial) {
                            appInicio = aplicaciones.find((x) => x.idAplicacion === user.idAplicacionInicial);
                        } else {
                            appInicio = aplicaciones[0];
                        }
                        dispatch(globalActions.setAplicacionActiva(appInicio, lavInicio));
                    }

                    let formularioInicio = getUrlFormularioInicio(lavInicio, appInicio, user.tblFormularioInicio);
                    if (formularioInicio) {
                        history.push(formularioInicio);
                    } else {
                        history.push("/Informes");
                    }
                });
            },
            (error) => {
                setTimeout(() => {
                    dispatch(failure(error));
                    history.push("/Login");
                }, 500);
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function loginChangeUser(idUsuarioSel, idUsuarioOriginal, isLogout) {
    return (dispatch) => {
        userService.loginChangeUser(idUsuarioSel, idUsuarioOriginal).then((user) => {
            $.when(
                lavanderiasService.load(user),
                empresasService.load(user),
                languageService.load(),
                estructuraMenuService.load()
            ).then(function () {
                localStorage.setItem("idUsuarioOriginal", isLogout ? null : JSON.stringify(idUsuarioOriginal));
                dispatch(globalActions.setIdUsuarioOriginal(isLogout ? null : idUsuarioOriginal));

                window.location.reload();
            });
        });
    };
}

function logout() {
    signalRCoreService.leaveAllGroups();
    signalRService.closeAllConnections();
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function logout_SesionCaducada() {
    //LogAcciones
    return (dispatch) => {
        dispatch(failure("error"));
        setTimeout(() => {
            $(".dx-overlay-wrapper").remove();
            signalRCoreService.leaveAllGroups();
            signalRService.closeAllConnections();
            history.push("/Login/SesionCaducada");

            logUsoService.setLog(4);
        }, 1000);
    };
    function failure(refresh_state) {
        return { type: userConstants.REFRESH_TOKEN_FAILURE, refresh_state };
    }
}

function refreshToken(mostrarMensaje) {
    return (dispatch) => {
        let action_request = mostrarMensaje ? "loading" : null;
        let action_success = mostrarMensaje ? "done" : null;
        let action_failure = mostrarMensaje ? "error" : null;

        dispatch(request(action_request));

        setTimeout(
            () => {
                userService.refreshToken().then(
                    (user) => {
                        dispatch(success(action_success));
                        setTimeout(
                            () => {
                                dispatch(reset());
                            },
                            mostrarMensaje ? 1500 : 0
                        );
                    },
                    (error) => {
                        dispatch(failure(action_failure));
                        setTimeout(
                            () => {
                                const USER = JSON.parse(localStorage.getItem("user"));
                                if (!USER || USER.enableFullScreen == false) {
                                    $(".dx-overlay-wrapper").remove();
                                    signalRCoreService.leaveAllGroups();
                                    signalRService.closeAllConnections();
                                    history.push("/Login/SesionCaducada");
                                }
                            },
                            mostrarMensaje ? 500 : 0
                        );
                    }
                );
            },
            mostrarMensaje ? 1000 : 0
        );
    };

    function request(refresh_state) {
        return { type: userConstants.REFRESH_TOKEN_REQUEST, refresh_state };
    }
    function success(refresh_state) {
        return { type: userConstants.REFRESH_TOKEN_SUCCESS, refresh_state };
    }
    function failure(refresh_state) {
        return { type: userConstants.REFRESH_TOKEN_FAILURE, refresh_state };
    }
    function reset(empty = {}) {
        return { type: userConstants.REFRESH_TOKEN_RESET, empty };
    }
}

function change_username(user) {
    if (user.nombre.length > 0) {
        const USER = JSON.parse(localStorage.getItem("user"));
        USER.nombre = user.nombre;
        localStorage.setItem("user", JSON.stringify(USER));

        return (dispatch) => {
            dispatch(request(USER));
        };
    }
    function request(user) {
        return { type: userConstants.UPDATE_USER_REQUEST, user };
    }
}
