import React from "react";
import { Button } from "devextreme-react";
import MapDeckGL from "./mapDeckGl";

import { useVisorRutasContext } from "./visorRutasContext";

export default function Map({ onSetIsDrawerOpened }) {
  const {
    idParteTransporteSel,
    setIdParteTransporteSel,
    lavanderias,
    entidades,
    pausas,
    vehiculos,
    flags,
    date,
    rutas,
  } = useVisorRutasContext();

  const renderButton_menu = () => {
    return (
      <div className="hamburger hamburger--elastic">
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </div>
    );
  };

  return (
    <>
      <Button
        width={50}
        className="shadow"
        render={renderButton_menu}
        stylingMode="contained"
        onClick={() => {
          onSetIsDrawerOpened(true);
        }}
        style={{
          zIndex: 1,
          backgroundColor: "white",
          position: "absolute",
          top: 20,
          left: 20,
        }}
      />
      <MapDeckGL
        date={date}
        rutas={rutas}
        lavanderias={lavanderias}
        entidades={entidades}
        pausas={pausas}
        vehiculos={vehiculos}
        flags={flags}
        idParteTransporteSel={idParteTransporteSel}
        setIdParteTransporteSel={setIdParteTransporteSel}
      />
    </>
  );
}
