import { getArticulos, getDefaultIvaId, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearPedido = () => {
    return {
        codigo: null,
        fechaCreacion: null,
        idAdmPedido_Estado: 1,
        idTipoPedido: null,
        idAdmCliente: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1,
        tasaCambio: 1,
        descuento: 0,
        idAdmTipoDescuento: 1,
        idIvaNPais: getDefaultIvaId(false),
        idAdmFormaPago: null,
        idIncoterm: null,
        ordenCompraCliente: null,
        numPresupuesto_id: null,
        numPresupuesto_codigo: null,
        observaciones: null,
        articulosDraft: [],
        tblArticuloNAdmPedidoCliente: [],
    };
};

export const payloadBuilder = (data, queryReady = true) => {
    if (queryReady) {
        let payload = {
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idAdmPedido_Estado: data.idAdmPedido_Estado,
            idMoneda: data.idMoneda,
            idAdmCliente: data.idAdmCliente,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            numPedidoCliente: data.ordenCompraCliente,
            idAdmFormaPago: data.idAdmFormaPago,
            idTipoPedido: data.idTipoPedido,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            idIncoterm: data.idIncoterm,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idAdmPresupuestoVenta: data.numPresupuesto_id,
            idIvaNPais: data.idIvaNPais,
            observaciones: data.observaciones,
            idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
            tblArticuloNAdmPedidoCliente: getArticulos(data.tblArticuloNAdmPedidoCliente, queryReady),
        };
        if (data.idAdmPedidoCliente != null) {
            payload.idAdmPedidoCliente = data.idAdmPedidoCliente;
        }
        return payload;
    } else {
        return {
            idAdmPedidoCliente: data.idAdmPedidoCliente,
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idAdmPedido_Estado: data.idAdmPedido_Estado,
            idMoneda: data.idMoneda,
            idAdmCliente: data.idAdmCliente,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            ordenCompraCliente: data.numPedidoCliente,
            idAdmFormaPago: data.idAdmFormaPago,
            idTipoPedido: data.idTipoPedido,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            idIncoterm: data.idIncoterm,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            numPresupuesto_id: data.idAdmPresupuestoVenta,
            numPresupuesto_codigo: data?.idAdmPresupuestoVentaNavigation?.codigo,
            // numPresupuesto: data.numPresupuesto,
            idIvaNPais: data.idIvaNPais,
            observaciones: data.observaciones,
            // numPresupuestoNavigation: data.numPresupuestoNavigation,
            tblArticuloNAdmPedidoCliente: getArticulos(data.tblArticuloNAdmPedidoCliente),
        };
    }
};
