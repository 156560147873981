import React from "react";

import "./style.scss";
import { Tooltip } from "devextreme-react";

const ObservacionesCell = ({ data }) => {
    const containerRef = React.useRef(null);
    const spanRef = React.useRef(null);
    const [isOverflowing, setIsOverflowing] = React.useState(false);

    React.useEffect(() => {
        const containerWidth = containerRef.current.offsetWidth;
        const spanWidth = spanRef.current.offsetWidth;

        setIsOverflowing(spanWidth > containerWidth);
    }, [data.value]);

    return (
        <div ref={containerRef} className="contenedor-observaciones position-relative">
            <span ref={spanRef} className="span-observaciones">
                {data.value}
            </span>
            {isOverflowing && (
                <>
                    <i className="bg-white icon_Comment position-absolute-center-right p-2 font-size-lg" />
                    <Tooltip target={containerRef.current} showEvent="dxhoverstart" hideEvent="dxhoverend">
                        <div className="tooltip-observaciones">{data.value}</div>
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default ObservacionesCell;
