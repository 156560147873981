import React, { Component, createContext } from "react";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { authHeader, errorHandler } from "helpers";
import { connectionConstants } from "constants";

let recambiosPromise = null;
let infoActualPromise = null;

export const GestionAlmacenesContext = createContext();

class GestionAlmacenesProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoActual: {},
      dataSource: [],
      idsRecambio: undefined,
      isLoading: {
        getRecambios: false,
        getInfoActual: false,
      },
    };
  }

  componentDidMount() {
    this.load_dataSource_getRecambios();
  }

  componentDidUpdate(prevProps) {
    const { idAlmacenSel } = this.props;

    if (prevProps.idAlmacenSel !== idAlmacenSel) {
      if (idAlmacenSel) {
        this.load_dataSource_getRecambios();
      } else {
        this.setState({
          infoActual: {},
          dataSource: [],
          isLoading: {
            getRecambios: false,
            getInfoActual: false,
          },
        });
      }
    }
  }

  datasource_getRecambios_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.idAlmacen = this.props.idAlmacenSel;
  };

  datasource_getRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/GestionAlmacenes/GetRecambios",
      key: "idRecambio",
      errorHandler,
      beforeSend: this.datasource_getRecambios_beforeSend,
      version: 4,
    }),
  });

  datasource_getInfoActual_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.timeout = 90000;
    request.params.idAlmacen = this.props.idAlmacenSel;
  };

  datasource_getInfoActual = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/GetInfoActual",
      key: "idRecambio",
      errorHandler,
      beforeSend: this.datasource_getInfoActual_beforeSend,
      version: 4,
    }),
  });

  getIsLoading = (prevState, field, value) => ({
    isLoading: { ...prevState.isLoading, [field]: value },
  });

  load_dataSource_getRecambios = () => {
    let newState = {
      infoActual: {},
      dataSource: [],
    };

    if (this.props.idAlmacenSel) {
      if (recambiosPromise) {
        this.datasource_getRecambios.cancel(recambiosPromise.operationId);
      }
      if (infoActualPromise) {
        this.datasource_getInfoActual.cancel(infoActualPromise.operationId);
      }

      newState.isLoading = {
        getRecambios: true,
        getInfoActual: true,
      };
      this.setState(newState);

      recambiosPromise = this.datasource_getRecambios.load();
      recambiosPromise
        .done((data) => {
          this.setState({ dataSource: data });

          infoActualPromise = this.datasource_getInfoActual.load();
          infoActualPromise
            .done((data) => {
              const mapaDeDatos = {};
              data.forEach((item) => (mapaDeDatos[item.idRecambio] = item));

              this.setState({ infoActual: mapaDeDatos });
            })
            .then(() => {
              this.setState((prevState) => ({
                isLoading: {
                  ...prevState.isLoading,
                  getInfoActual: false,
                },
              }));
            });
        })
        .then(() => {
          this.setState((prevState) => ({
            isLoading: {
              ...prevState.isLoading,
              getRecambios: false,
            },
          }));
        });
    }
  };

  setIdsRecambio = (idsRecambio) => this.setState({ idsRecambio });

  render() {
    const { children } = this.props;
    const { isLoading, dataSource, infoActual, idsRecambio } = this.state;

    const dataSourceFinal = idsRecambio
      ? dataSource.filter((r) => idsRecambio.includes(r.idRecambio))
      : dataSource;

    const value = {
      load_dataSource_getRecambios: this.load_dataSource_getRecambios,
      isLoading,
      dataSource: dataSourceFinal,
      infoActual,
      setIdsRecambio: this.setIdsRecambio,
    };

    return (
      <GestionAlmacenesContext.Provider value={value}>
        {children}
      </GestionAlmacenesContext.Provider>
    );
  }
}

export default GestionAlmacenesProvider;
