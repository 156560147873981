import React from "react";

import Loader from "react-loaders";

import { getTrad } from "helpers";

const loaderStyle = { paddingRight: 20, zoom: 0.5 };

const CustomLoader = ({ isLoading }) => {
  const isLoadingCalculatedData =
    isLoading.getRecambios || isLoading.getInfoActual;

  return isLoadingCalculatedData ? (
    <div
      className={
        "p-2 he-100 d-flex flex-row align-items-center text-info border border-info rounded rounded-3"
      }
    >
      <div style={loaderStyle}>
        <Loader type={"line-scale"} />
      </div>
      {getTrad("cargandoCamposCalculados")}...
    </div>
  ) : null;
};

export default CustomLoader;
