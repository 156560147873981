import React, { Fragment, useEffect, useState } from "react";
import Box, { Item as ItemBox } from "devextreme-react/box";
import {
  Button,
  DateBox,
  LoadPanel,
  ScrollView,
  SelectBox,
  Tooltip,
} from "devextreme-react";
import { useVisorRutasContext } from "./visorRutasContext";
import { dateTime_hourMinute } from "../../../../../../../helpers";

const currentDate = new Date();

export default function componentDrawer({
  isDrawerOpened,
  onSetIsDrawerOpened,
}) {
  const {
    idParteTransporteSel,
    setIdParteTransporteSel,
    dataParteTrabajo,
    setDataParteTrabajo,
    dataParadaNParteTrabajo,
    date,
    setDate,
    setLastFechaLocalizacion,
    stopInterval,
  } = useVisorRutasContext();

  const [filterPT, setFilterPT] = useState({});
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      stopInterval();
    };
  }, []);

  useEffect(() => {
    const filtered = dataParteTrabajo
      ? dataParteTrabajo.filter((x) =>
          Object.keys(filterPT).every(
            (key) => x[key] && x[key] === filterPT[key],
          ),
        )
      : null;
    setFilteredData(filtered);
  }, [dataParteTrabajo, filterPT]);

  const renderButton_menu = () => {
    return <div className="icon_Cerrar" />;
  };

  const itemRender_parteTransporte = (item, index) => {
    const {
      idEstado,
      denoRutaExpedicion,
      vehiculo_denominacion,
      vehiculo_matricula,
      nombreUsuario,
    } = item;

    let iconColor = {
      1: "primary",
      2: "success",
      3: "danger",
    };

    return (
      <div
        id={"idParteTransporte" + index}
        className={idParteTransporteSel ? "border-bottom" : undefined}
        style={{
          padding: 10,
          height: 100,
          height: 95,
          overflow: "hidden",
          textAlign: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            height: 25,
            alignItems: "center",
          }}
        >
          <div
            className={`icon_Vehiculos4 ${iconColor[idEstado] || ""}`}
            style={{ fontSize: 20, marginRight: 10 }}
          />
          <p className="font-weight-bold" style={{ margin: 0 }}>
            {denoRutaExpedicion}
          </p>
        </div>
        <p
          className="text-primary font-weight-bold"
          style={{
            display: "flex",
            margin: 0,
            height: 25,
            alignItems: "center",
          }}
        >
          {nombreUsuario.toUpperCase()}
        </p>
        <p
          style={{
            display: "flex",
            margin: 0,
            height: 25,
            alignItems: "center",
          }}
        >
          {vehiculo_denominacion} - {vehiculo_matricula}
        </p>
      </div>
    );
  };

  const lista_filtredData = () => {
    const tooltipText = {
      1: "En curso",
      2: "Finalizado",
      3: "Cancelado",
    };

    return filteredData ? (
      filteredData.length > 0 ? (
        filteredData.map((item, index) => {
          const { idEstado } = item;
          return (
            <Fragment key={index}>
              <li>
                <Button
                  stylingMode="text"
                  className="border-bottom"
                  onClick={() =>
                    idParteTransporteSel !== item.idParteTransporte &&
                    setIdParteTransporteSel(item.idParteTransporte)
                  }
                  style={{ width: "100%" }}
                >
                  {itemRender_parteTransporte(item, index)}
                </Button>
              </li>
              <Tooltip
                target={"#idParteTransporte" + index}
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="font-weight-bold">{tooltipText[idEstado]}</div>
              </Tooltip>
            </Fragment>
          );
        })
      ) : filterPT.idEstado ? (
        dataParteTrabajo && dataParteTrabajo.length > 0 ? (
          <p className="text-center pt-3">
            {" "}
            No hay registros en estado (
            {tooltipText[filterPT.idEstado].toLowerCase()})
          </p>
        ) : (
          <p className="text-center pt-3">No hay registros para esta fecha.</p>
        )
      ) : (
        <p className="text-center pt-3">No hay registros para esta fecha.</p>
      )
    ) : null;
  };

  const lista_paradaParteTransporte = () => {
    return dataParadaNParteTrabajo
      .find((x) => x.idParteTransporte === idParteTransporteSel)
      ?.tblParadaNParteTransporte.map((item, index) => {
        function getColorEstadoParada(activo, done, actual) {
          let color = "primary";

          if (!actual) {
            if (activo) {
              if (done) {
                color = "success";
              } else {
                color = "gray";
              }
            } else {
              if (done) {
                color = "success";
              } else {
                color = "lightGray";
              }
            }
          }

          return color;
        }

        const paradas = dataParadaNParteTrabajo.find(
          (x) => x.idParteTransporte === idParteTransporteSel,
        )?.tblParadaNParteTransporte;

        const done = item.fechaSalida !== null;
        const omitido = item.fechaOmitido !== null;

        let paradaAnterior = null;
        if (index > 0) {
          paradaAnterior = {
            done: paradas[index - 1].fechaSalida !== null,
            omitido: paradas[index - 1].fechaOmitido !== null,
          };
        }

        const actual =
          (paradaAnterior === null ||
            paradaAnterior.done ||
            paradaAnterior.omitido) &&
          !done &&
          !omitido;

        const activo =
          item.idEntidad === null ||
          (item.idEntidad !== null &&
            paradas.some((r) => r.idEntidad === item.idEntidad));

        const colorEstado =
          item.idEstado !== 3 ||
          getColorEstadoParada(activo, done, actual) !== "primary"
            ? getColorEstadoParada(activo, done, actual)
            : "danger";

        const textEstado = {
          primary: "En curso",
          gray: item.idEstado === 3 ? "Cancelado" : "Pendiente",
          danger: "Cancelado",
          success: "Finalizada",
          lightGray: "Pendiente",
        };

        const contenedor_horasStyle = {
          display: "flex",
          flexDirection: "column",
          height: "100%",
          alignItems: "center",
          justifyContent:
            !item.fechaLlegada ||
            (colorEstado !== "primary" && index === paradas.length - 1)
              ? "center"
              : "space-between",
        };
        const icon_Vehiculos = { fontSize: 20 };
        const horaLlegadaStyle =
          item.fechaLlegada && index === paradas.length - 1
            ? { marginTop: 5, marginBottom: 5 }
            : { alignSelf: "start", marginTop: 5, marginBottom: 5 };
        const horaSalidaStyle =
          item.fechaSalida && index === paradas.length - 1
            ? { marginTop: 5, marginBottom: 5 }
            : { alignSelf: "end", marginTop: 5, marginBottom: 5 };

        const denominacionPausa = item.idLavanderia
          ? item.idLavanderiaNavigation?.denominacion
          : item.idEntidad
            ? item.idEntidadNavigation?.denominacion
            : "PAUSA - " +
              item.idMotivoPausaNavigation?.denominacion.toUpperCase();

        const horaLlegada = item.fechaLlegada ? (
          <div id={`horaLlegada-${index}`}>
            <p style={horaLlegadaStyle}>
              {dateTime_hourMinute(new Date(item.fechaLlegada))}
            </p>
          </div>
        ) : null;

        const fechaSalida =
          item.fechaSalida && index !== paradas.length - 1 ? (
            <div id={`horaSalida-${index}`}>
              <p style={horaSalidaStyle}>
                {dateTime_hourMinute(new Date(item.fechaSalida))}
              </p>
            </div>
          ) : null;

        return (
          <Fragment key={index}>
            <li>
              <Button
                stylingMode="text"
                className="border-bottom"
                style={{ width: "100%" }}
                onClick={() => {
                  /*//TODO: Al clicar en parada que te lleve en el mapa */
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    height: 60,
                    padding: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    {colorEstado === "primary" || colorEstado === "danger" ? (
                      <div style={contenedor_horasStyle}>
                        {horaLlegada}
                        <div
                          className={`icon_Vehiculos4 ${colorEstado}`}
                          style={icon_Vehiculos}
                        />
                      </div>
                    ) : (
                      <div style={contenedor_horasStyle}>
                        {horaLlegada}
                        {fechaSalida}
                      </div>
                    )}
                  </div>
                  <div
                    id={`idParada-${index}`}
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className={`text-${colorEstado}`}
                      style={{ display: "flex", margin: 0 }}
                    >
                      {denominacionPausa}
                    </p>
                  </div>
                </div>
              </Button>
            </li>
            <Tooltip
              target={`#tiempoTotal-${index}`}
              position="bottom"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
            >
              <div className="font-weight-bold">Tiempo total</div>
            </Tooltip>
            <Tooltip
              target={`#horaLlegada-${index}`}
              position="bottom"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
            >
              <div className="font-weight-bold">
                {index === 0 ? "Hora inicio carga" : "Hora llegada"}
              </div>
            </Tooltip>
            <Tooltip
              target={`#horaSalida-${index}`}
              position="bottom"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
            >
              <div className="font-weight-bold">Hora salida</div>
            </Tooltip>
            <Tooltip
              target={`#idParada-${index}`}
              position="bottom"
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
            >
              <div className="font-weight-bold">{textEstado[colorEstado]}</div>
            </Tooltip>
          </Fragment>
        );
      });
  };

  const data_PTSel = idParteTransporteSel
    ? dataParteTrabajo.find((x) => x.idParteTransporte === idParteTransporteSel)
    : {};

  function formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  return (
    <div
      id="componentDrawer"
      className="list"
      style={{
        height: "100%",
        width: "400px",
        backgroundColor: "white",
        paddingRight: 20,
      }}
    >
      <LoadPanel
        shadingColor="transparent"
        deferRendering={false}
        animation={null}
        position={{ of: "#dataDrawer" }}
        visible={isDrawerOpened && dataParteTrabajo === null}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <Box direction="col" width="100%" height="100%">
        <ItemBox baseSize={70}>
          <Box direction="row" width="100%" height="100%">
            <ItemBox ratio={1}>
              {idParteTransporteSel ? (
                <div
                  className="container_spanCentrado"
                  style={{ alignSelf: "start" }}
                >
                  <Button
                    text="Atrás"
                    width={80}
                    stylingMode="contained"
                    onClick={() => {
                      idParteTransporteSel && setIdParteTransporteSel(null);
                      setLastFechaLocalizacion(null);
                    }}
                  />
                </div>
              ) : null}
            </ItemBox>
            <ItemBox ratio={1}>
              <div
                className="container_spanCentrado"
                style={{ alignSelf: "end", marginRight: 10 }}
              >
                <Button
                  width={50}
                  render={renderButton_menu}
                  stylingMode="contained"
                  onClick={() => onSetIsDrawerOpened(false)}
                />
              </div>
            </ItemBox>
          </Box>
        </ItemBox>
        {idParteTransporteSel ? null : (
          <ItemBox baseSize={125}>
            <div className="border-bottom">
              <div
                style={{
                  width: "100%",
                  alignSelf: "start",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div
                  className="container_spanCentrado"
                  style={{ marginRight: 10 }}
                >
                  Fecha:
                </div>
                <DateBox
                  displayFormat="dd/MM/yyyy"
                  stylingMode="underlined"
                  value={date}
                  width="200"
                  max={currentDate}
                  onValueChanged={(e) => {
                    setDate(e.value);
                    setDataParteTrabajo(null);
                    setFilterPT({
                      ...filterPT,
                      fecha: formatDate(new Date(e.value)),
                    });
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  alignSelf: "start",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                <div
                  className="container_spanCentrado"
                  style={{ marginRight: 10 }}
                >
                  Estado:
                </div>
                <SelectBox
                  value={filterPT?.idEstado}
                  showClearButton
                  width={"200"}
                  displayExpr="denominacion"
                  valueExpr="idEstado"
                  onValueChanged={(e) => {
                    if (e.value === null) {
                      let filter_ = { ...filterPT };
                      delete filter_.idEstado;
                      setFilterPT(filter_);
                    } else {
                      setFilterPT({ ...filterPT, idEstado: e.value });
                    }
                  }}
                  items={[
                    { idEstado: 1, denominacion: "En curso" },
                    { idEstado: 2, denominacion: "Finalizado" },
                    { idEstado: 3, denominacion: "Cancelado" },
                  ]}
                />
              </div>
            </div>
          </ItemBox>
        )}
        <ItemBox ratio={1}>
          <div
            id="dataDrawer"
            style={{
              display: "flex",
              flexDirection: "column",
              minHeight: "50%",
            }}
          >
            {idParteTransporteSel ? (
              <>
                <div>{itemRender_parteTransporte(data_PTSel, 0)}</div>
                <ScrollView>
                  <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                    {lista_paradaParteTransporte()}
                  </ul>
                </ScrollView>
              </>
            ) : (
              <ScrollView>
                <ul style={{ listStyleType: "none", margin: 0, padding: 0 }}>
                  {lista_filtredData()}
                </ul>
              </ScrollView>
            )}
          </div>
        </ItemBox>
      </Box>
    </div>
  );
}
