import React, { Fragment } from "react";
import { connect } from "react-redux";

//Constants
import { connectionConstants } from "../../../constants";

//Actions
import { loadPanelActions } from "../../../actions";

//Helpers
import {
  endOfMonth,
  getNombreFormulario,
  getTrad,
  startOfMonth,
  authHeader,
  formatDate_parameter,
} from "../../../helpers";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Button } from "devextreme-react";
import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import { Form, Item } from "devextreme-react/form";
import ODataContext from "devextreme/data/odata/context";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

//Js
import cargaJs from "./Js.js";

class Estancias extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible_dxPopup_importarEstancias: false,
      formData_importarEstancias: {
        fechaDesde: startOfMonth(new Date()),
        fechaHasta: endOfMonth(new Date()),
      },
    };

    this.context_importarEstancias_beforeSend =
      this.context_importarEstancias_beforeSend.bind(this);

    this.importarEstancias = this.importarEstancias.bind(this);
    this.onClick_importarEstancias = this.onClick_importarEstancias.bind(this);
    this.onHiding_importarEstancias =
      this.onHiding_importarEstancias.bind(this);
    this.onClick_ejecutarImportarEstancias =
      this.onClick_ejecutarImportarEstancias.bind(this);

    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  context_importarEstancias_HM = new ODataContext({
    url: connectionConstants.WEB_API_CORE_URL + "timonHotel",
    entities: {
      ImportarEstancias: {},
    },
    beforeSend: (request) => {
      this.context_importarEstancias_beforeSend(request);
    },
  });

  context_importarEstancias_AllSun = new ODataContext({
    url: connectionConstants.WEB_API_CORE_URL + "allSun",
    entities: {
      ImportarEstancias: {},
    },
    beforeSend: (request) => {
      this.context_importarEstancias_beforeSend(request);
    },
  });

  context_importarEstancias_beforeSend(request) {
    const { fechaDesde, fechaHasta } = this.state.formData_importarEstancias;

    request.headers = { ...authHeader() };

    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  //ImportarEstancias HM
  importarEstancias() {
    let { user } = this.props;
    let visible = user.idCargo === 1; //DESARROLLADOR
    return (
      <Button
        visible={visible}
        text={"Importar estancias"}
        width="100%"
        icon="download"
        className="mr-2"
        onClick={this.onClick_importarEstancias}
      />
    );
  }

  onClick_importarEstancias = () => {
    this.setState({ isVisible_dxPopup_importarEstancias: true });
  };

  render() {
    const { isVisible_dxPopup_importarEstancias, formData_importarEstancias } =
      this.state;
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={[this.importarEstancias()]}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="Estancias" className={"formContainer"}>
            <div id="dxContainer" />
          </div>
          <Popup
            visible={isVisible_dxPopup_importarEstancias}
            showTitle={true}
            title={"Importar estancias"}
            closeOnOutsideClick={false}
            width={400}
            height={400}
            onHiding={this.onHiding_importarEstancias}
          >
            <Form
              formData={formData_importarEstancias}
              labelLocation="top"
              colCount={2}
              height="100%"
              width="100%"
            >
              <Item
                dataField="compañia"
                label={this.formLabel_compañia}
                colSpan={2}
                editorType="dxSelectBox"
                editorOptions={this.editorOptions_compañia}
              />
              <Item
                dataField="fechaDesde"
                label={this.formLabel_fechaDesde}
                colSpan={2}
                editorType="dxDateBox"
                editorOptions={this.editorOptions_date}
              />
              <Item
                dataField="fechaHasta"
                label={this.formLabel_fechaHasta}
                colSpan={2}
                editorType="dxDateBox"
                editorOptions={this.editorOptions_date}
              />
            </Form>
            <ToolbarItem_popup
              toolbar="bottom"
              location="after"
              widget="dxButton"
            >
              <Button
                text={getTrad("guardar")}
                type="success"
                onClick={this.onClick_ejecutarImportarEstancias}
              />
            </ToolbarItem_popup>
            <ToolbarItem_popup
              toolbar="bottom"
              location="after"
              widget="dxButton"
            >
              <Button
                text={getTrad("cancelar")}
                type="normal"
                onClick={this.onHiding_importarEstancias}
              />
            </ToolbarItem_popup>
          </Popup>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //FORM
  formLabel_compañia = { text: getTrad("compañia") };
  formLabel_fechaDesde = { text: getTrad("fechaDesde") };
  formLabel_fechaHasta = { text: getTrad("fechaHasta") };

  editorOptions_date = {
    displayFormat: "dd/MM/yyyy",
    stylingMode: "underlined",
  };

  editorOptions_compañia = {
    items: ["HM", "AllSun"],
  };

  onHiding_importarEstancias = () => {
    this.setState({
      isVisible_dxPopup_importarEstancias: false,
      formData_importarEstancias: {
        fechaDesde: startOfMonth(new Date()),
        fechaHasta: endOfMonth(new Date()),
      },
    });
  };

  onClick_ejecutarImportarEstancias = () => {
    const { formData_importarEstancias } = this.state;
    let _this = this;
    this.loadPanel_show();

    if (formData_importarEstancias.compañia == "HM") {
      this.context_importarEstancias_HM
        .invoke("ImportarEstancias", {}, "POST")
        .done(function () {
          _this._cargaJs();
          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        })
        .always(function () {
          _this.onHiding_importarEstancias();
          _this.loadPanel_hide();
        });
    } else {
      this.context_importarEstancias_AllSun
        .invoke("ImportarEstancias", {}, "POST")
        .done(function () {
          _this._cargaJs();
          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        })
        .always(function () {
          _this.onHiding_importarEstancias();
          _this.loadPanel_hide();
        });
    }
  };

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      this._cargaJs();
    }
  }

  componentDidMount() {
    this._cargaJs();
  }

  _cargaJs = () => {
    let { lavanderia, idioma } = this.props;
    cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
  };
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Estancias);
