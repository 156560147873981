import React from "react";

class CellCheck extends React.PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { value, data, column } = this.props.data;
        let isDisabled =
            (data.fecha instanceof Date ? data.fecha.getMonth() : data.fecha?.split("-")[1] * 1 - 1) ===
            column.dataField.split("-")[1] * 1;
        return (
            <i
                onClick={this.onClick}
                className={`position-absolute-0 he-100 font-size-xl d-flex align-items-center justify-content-center pointer ${
                    isDisabled ? "dx-state-disabled opacity-6" : ""
                } ${value?.activo ? "icon_Circle_check month-selected" : "icon_Circle_uncheck"}`}
            />
        );
    }

    onClick = () => {
        const { component, rowIndex, column, value } = this.props.data;
        let newValue = { ...value, activo: !value.activo };
        component.cellValue(rowIndex, column.dataField, newValue);
    };
}

export default CellCheck;
