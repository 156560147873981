import React, { forwardRef } from "react";

import DataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Selection,
  Sorting,
} from "devextreme-react/data-grid";

import { getTrad } from "helpers";

const allowedPageSizes = [20, 50, 100];

const SharedDataGridMovimientos = forwardRef(
  (
    {
      dataSource,
      firstColumn,
      newMovimientoTrad,
      onRemove,
      onSelectionChanged,
      children,
    },
    ref
  ) => {
    const onToolbarPreparing = ({ toolbarOptions, component }) =>
      toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          options: {
            icon: "plus",
            text: getTrad(newMovimientoTrad),
            onClick: () => onSelectionChanged(null),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          options: {
            icon: "refresh",
            text: getTrad("actualizar"),
            onClick: () => component.refresh(),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          locateInMenu: "auto",
          options: {
            text: getTrad("limpiarFiltro"),
            icon: " icon_EliminarFiltros",
            onClick: () => component.clearFilter(),
          },
        }
      );

    const onRowPrepared = (e) => {
      if (e.rowType === "data") {
        e.rowElement.addClass("pointer");
      }
    };

    const cellRender = (e) => {
      if (!e.value) return null;
      return (
        <div
          className={"container_spanCentrado pointer"}
          onMouseDown={() => onRemove(e.data.idMovimientoRecambio)}
        >
          <i className={"icon_Cerrar font-size-lg"} />
        </div>
      );
    };

    return (
      <DataGrid
        ref={ref}
        dataSource={dataSource}
        keyExpr={"idMovimientoRecambio"}
        height={"100%"}
        width={"100%"}
        allowColumnReordering
        hoverStateEnabled
        rowAlternationEnabled
        showRowLines
        showColumnLines={false}
        onToolbarPreparing={onToolbarPreparing}
        onRowPrepared={onRowPrepared}
        onSelectionChanged={onSelectionChanged}
      >
        <ColumnChooser enabled />
        <FilterRow visible />
        <HeaderFilter visible />
        <Pager showPageSizeSelector allowedPageSizes={allowedPageSizes} />
        <Paging defaultPageSize={50} />
        <Selection mode={"single"} />
        <Sorting mode={"multiple"} />
        {firstColumn ? <Column {...firstColumn} /> : null}
        <Column
          dataField={"idMovimientoRecambio"}
          caption={"ID"}
          width={75}
          alignment={"center"}
          allowResizing
          allowHeaderFiltering={false}
        />
        <Column
          dataField={"fecha"}
          caption={getTrad("fecha")}
          dataType={"datetime"}
          format={"dd/MM/yyyy HH:mm"}
          defaultSelectedFilterOperation={">="}
          width={130}
          sortOrder={"desc"}
          alignment={"center"}
          allowResizing
          allowHeaderFiltering={false}
        />
        {children}
        <Column
          dataField={"observaciones"}
          caption={getTrad("observaciones")}
          minWidth={150}
          alignment={"left"}
          allowResizing
          allowHeaderFiltering={false}
          visible={false}
        />
        <Column
          dataField={"isEditable"}
          caption={" "}
          width={30}
          alignment={"center"}
          cssClass={"p-0"}
          fixedPosition={"right"}
          fixed
          allowFiltering={false}
          allowReordering={false}
          allowResizing={false}
          allowSorting={false}
          showInColumnChooser={false}
          cellRender={cellRender}
        />
      </DataGrid>
    );
  }
);

export default SharedDataGridMovimientos;
