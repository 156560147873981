import React, { useState } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../constants";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Helpers
import {
  getNombreFormulario,
  authHeader,
  errorHandler,
  dxMensajePregunta,
  patchRequestHandler,
  getTrad,
} from "../../../helpers";

import List from "devextreme-react/list";
import { Button } from "devextreme-react";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";

import ListsCompaEnti from "../../../components/ListsCompaEnti";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import notify from "devextreme/ui/notify";

import DataGrid, {
  Column,
  Selection,
  Pager,
  Paging,
  Editing,
  Popup as Popup_dataGrid,
  Form as Popup_Form,
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";

import { RequiredRule } from "devextreme-react/form";
import "./Css.scss";

class RutasExpedicion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idRutaExpedicion: null,
      idsAlmacenesSel: [],
      isVisible_dxPopup_compaEnti: false,
      omitir_idsEntidad: [],
      resetListasCompaEnti: false,
      recargarListas: false,
    };

    this.dxDataGrid_rutasExp_REF = React.createRef();
    this.dxList_paradaNRuta_REF = React.createRef();

    //Datasource
    this.datasource_rutasExpedicion_beforeSend =
      this.datasource_rutasExpedicion_beforeSend.bind(this);
    this.datasource_rutasExpedicion_onLoading =
      this.datasource_rutasExpedicion_onLoading.bind(this);
    this.datasource_rutasExpedicion_onRemoved =
      this.datasource_rutasExpedicion_onRemoved.bind(this);
    this.datasource_paradaNRutaExpedicion_onLoading =
      this.datasource_paradaNRutaExpedicion_onLoading.bind(this);
    this.datasource_paradaNRutaExpedicion_todosAlmacenes_onLoading =
      this.datasource_paradaNRutaExpedicion_todosAlmacenes_onLoading.bind(this);

    //Eventos
    this.dxDataGrid_rutasExp_editCellRender =
      this.dxDataGrid_rutasExp_editCellRender.bind(this);
    this.dxDataGrid_rutasExp_removeCellRender =
      this.dxDataGrid_rutasExp_removeCellRender.bind(this);
    this.dxDataGrid_rutasExp_onSelectionChanged =
      this.dxDataGrid_rutasExp_onSelectionChanged.bind(this);
    this.dxDataGrid_rutasExp_onToolbarPreparing =
      this.dxDataGrid_rutasExp_onToolbarPreparing.bind(this);
    this.dxList_paradaNRutaExpedicion_itemRender =
      this.dxList_paradaNRutaExpedicion_itemRender.bind(this);
    this.dxList_onClick_añadirEntidades =
      this.dxList_onClick_añadirEntidades.bind(this);
    this.dxList_onItemReordered = this.dxList_onItemReordered.bind(this);
    this.dxList_onItemDeleted = this.dxList_onItemDeleted.bind(this);
    this.onShowing_dxPopup_compaEnti =
      this.onShowing_dxPopup_compaEnti.bind(this);
    this.onHiding_dxPopup_compaEnti =
      this.onHiding_dxPopup_compaEnti.bind(this);
    this.onHidden_dxPopup_compaEnti =
      this.onHidden_dxPopup_compaEnti.bind(this);
    this.onClick_PopupEntidad_guardar =
      this.onClick_PopupEntidad_guardar.bind(this);
    this.dxList_entidades_onSelectionChanged =
      this.dxList_entidades_onSelectionChanged.bind(this);

    this.getTrad = this.getTrad.bind(this);
  }

  get dxDataGrid_rutasExp() {
    return this.dxDataGrid_rutasExp_REF.current.instance;
  }

  get dxList_paradaNRuta() {
    return this.dxList_paradaNRuta_REF.current.instance;
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //#region DATASOURCE
  datasource_rutasExpedicion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRutaExpedicion",
      key: "idRutaExpedicion",
      errorHandler: function (error) {
        errorHandler(error, -1);
      },
      beforeSend: (data) => {
        this.datasource_rutasExpedicion_beforeSend(data);
      },
      onLoading: (data) => {
        this.datasource_rutasExpedicion_onLoading(data);
      },
      onRemoved: (data) => {
        this.datasource_rutasExpedicion_onRemoved(data);
      },
      version: 4,
    }),
    select: ["idRutaExpedicion", "denominacion"],
  });

  datasource_rutasExpedicion_beforeSend(request) {
    let { lavanderia } = this.props;
    request.headers = { ...authHeader() };

    if (request.method === "POST") {
      request.payload = {
        ...request.payload,
        idLavanderia: lavanderia.idLavanderia,
      };
    } else if (request.method === "PATCH") {
      request.payload = patchRequestHandler(request.payload);
    }
  }

  datasource_rutasExpedicion_onLoading(loadOptions) {
    let { lavanderia } = this.props;
    loadOptions.filter =
      "eliminado eq false and idLavanderia eq " + lavanderia.idLavanderia;
  }

  datasource_rutasExpedicion_onRemoved() {
    notify({
      message: getTrad("aviso_C_RegistroEliminado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  }

  datasource_paradaNRutaExpedicion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "tblParadaNRutaExpedicion",
      key: "idParadaNRutaExpedicion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      onLoading: (data) => {
        this.datasource_paradaNRutaExpedicion_onLoading(data);
      },
      version: 4,
    }),
    select: "idParadaNRutaExpedicion, idEntidad, orden",
    expand: ["idEntidadNavigation($select=denominacion)"],
    sort: "orden",
    map: (item) => {
      return {
        idParadaNRutaExpedicion: item.idParadaNRutaExpedicion,
        idEntidad: item.idEntidad,
        denominacion: item.idEntidadNavigation
          ? item.idEntidadNavigation.denominacion
          : "",
        orden: item.orden,
      };
    },
  });

  datasource_paradaNRutaExpedicion_onLoading(loadOptions) {
    let { idRutaExpedicion } = this.state;
    loadOptions.filter =
      "idEntidad ne null and idRutaExpedicion eq " + idRutaExpedicion;
  }

  datasource_paradaNRutaExpedicion_todosAlmacenes = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "tblParadaNRutaExpedicion",
      key: "idParadaNRutaExpedicion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      onLoading: (data) => {
        this.datasource_paradaNRutaExpedicion_todosAlmacenes_onLoading(data);
      },
      version: 4,
    }),
    select: "idRutaExpedicion",
  });

  datasource_paradaNRutaExpedicion_todosAlmacenes_onLoading(loadOptions) {
    let { idRutaExpedicion } = this.state;
    loadOptions.filter = "idRutaExpedicion eq " + idRutaExpedicion;
  }
  //#endregion

  render() {
    let { lavanderia } = this.props;
    let {
      isVisible_dxPopup_compaEnti,
      idsAlmacenesSel,
      omitir_idsEntidad,
      resetListasCompaEnti,
      recargarListas,
    } = this.state;

    return (
      <>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div
            id="RutasExpedicion"
            className="formContainer scrollbar-container"
          >
            <div className="d-flex he-100 flexContainer">
              <div className="pr-4 mr-1">
                <DataGrid
                  ref={this.dxDataGrid_rutasExp_REF}
                  dataSource={this.datasource_rutasExpedicion}
                  columnsAutoWidth={true}
                  height={"100%"}
                  width={"100%"}
                  keyExpr="idRutaExpedicion"
                  hoverStateEnabled={true}
                  showColumnLines={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  onRowPrepared={this.dxDataGrid_rutasExp_onRowPrepared}
                  onCellClick={this.onRowClick_dxDataGrid}
                  onSelectionChanged={
                    this.dxDataGrid_rutasExp_onSelectionChanged
                  }
                  onRowInserted={this.dxDataGrid_rutasExp_onRowInserted}
                  onToolbarPreparing={
                    this.dxDataGrid_rutasExp_onToolbarPreparing
                  }
                >
                  <Selection mode="single" />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={this.grid_allowedPageSizes}
                    showInfo={true}
                  />
                  <Paging defaultPageSize={50} />
                  <Editing
                    mode="popup"
                    allowAdding={true}
                    allowUpdating={false}
                    allowDeleting={false}
                  >
                    <Popup_dataGrid
                      title={getTrad("rutasRevision")}
                      showTitle={true}
                      height={235}
                      width={350}
                    />
                    <Popup_Form
                      labelLocation="top"
                      deferRendering={false}
                      height="100%"
                      scrollingEnabled={true}
                      colCount={1}
                    >
                      <Item
                        dataField="denominacion"
                        label={this.formLabel_denominacion}
                      />
                    </Popup_Form>
                  </Editing>
                  <Column dataField="idEntidad" visible={false} />
                  <Column dataField="idRutaExpedicion" visible={false} />
                  <Column
                    caption=" "
                    width={30}
                    alignment="center"
                    cssClass="p-0"
                    visibleIndex={0}
                    cellRender={this.dxDataGrid_rutasExp_editCellRender}
                  />
                  <Column
                    dataField="denominacion"
                    minWidth={100}
                    caption={getTrad("denominacion")}
                    alignment="left"
                    sortOrder={"asc"}
                    sortIndex={0}
                    allowHeaderFiltering={false}
                  >
                    <RequiredRule />
                  </Column>
                  <Column
                    caption=" "
                    width={60}
                    alignment="center"
                    cssClass="p-0"
                    visibleIndex={9}
                    cellRender={this.dxDataGrid_rutasExp_removeCellRender}
                  />
                </DataGrid>
              </div>
              <div className="he-100">
                <div
                  className="d-flex justify-content-start"
                  style={{ marginBottom: 10 }}
                >
                  <Button
                    icon="plus"
                    text={getTrad("añadir")}
                    height={36}
                    onClick={this.dxList_onClick_añadirEntidades}
                  />
                </div>
                <List
                  ref={this.dxList_paradaNRuta_REF}
                  elementAttr={this.dxList_paradaNRuta_elementAttr}
                  dataSource={this.datasource_paradaNRutaExpedicion}
                  allowItemReordering={true}
                  selectionMode="single"
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  pageLoadMode="scrollBottom"
                  itemRender={(item, index) =>
                    this.dxList_paradaNRutaExpedicion_itemRender(item, index)
                  }
                  onItemDeleted={this.dxList_onItemDeleted}
                  onItemHold={this.dxList_onItemHold}
                  onItemReordered={this.dxList_onItemReordered}
                />
                <Popup
                  showTitle={true}
                  width={650}
                  maxHeight={550}
                  title={"Guardar ruta de expedición"}
                  visible={isVisible_dxPopup_compaEnti}
                  closeOnOutsideClick={false}
                  onShowing={this.onShowing_dxPopup_compaEnti}
                  onHiding={this.onHiding_dxPopup_compaEnti}
                  onHidden={this.onHidden_dxPopup_compaEnti}
                >
                  <ListsCompaEnti
                    todasCompaEntiSeleccionable={false}
                    compañiaSeleccionable={false}
                    isMultiSelection={true}
                    filtroLavanderia={lavanderia.idLavanderia}
                    omitir_idsEntidad={omitir_idsEntidad}
                    idsEntidad_multiSeleccion={idsAlmacenesSel}
                    resetListas={resetListasCompaEnti}
                    recargarListas={recargarListas}
                    dxList_entidades_onSelectionChanged={
                      this.dxList_entidades_onSelectionChanged
                    }
                  />
                  <ToolbarItemPopup
                    widget="dxButton"
                    location="after"
                    toolbar="bottom"
                  >
                    <Button
                      text={this.getTrad("guardar")}
                      type="success"
                      onClick={this.onClick_PopupEntidad_guardar}
                    />
                  </ToolbarItemPopup>
                </Popup>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  dxList_paradaNRuta_elementAttr = { id: "dxList_paradaNRuta" };
  formLabel_denominacion = { text: this.getTrad("denominacion") };
  grid_allowedPageSizes = [20, 50, 100];

  dxDataGrid_rutasExp_onSelectionChanged = ({ selectedRowsData }) => {
    let _this = this;
    let idRutaExpedicion =
      selectedRowsData.length > 0 ? selectedRowsData[0].idRutaExpedicion : -1;
    this.setState({ idRutaExpedicion: idRutaExpedicion });

    this.datasource_paradaNRutaExpedicion.reload().done(function (items) {
      _this.setState({ omitir_idsEntidad: items.map((x) => x.idEntidad) });
    });
  };

  dxDataGrid_rutasExp_onRowInserted(e) {
    e.component.selectRows(e.key, false);
  }

  dxDataGrid_rutasExp_editCellRender(args) {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={(e) => this.dxDataGrid_rutasExp_onClick_editCell(e, args)}
      >
        <div className="icon_Edit font-size-lg" />
      </div>
    );
  }

  dxDataGrid_rutasExp_onClick_editCell(e, args) {
    args.component.editRow(args.rowIndex);
    args.component.selectRowsByIndexes(args.rowIndex);
  }

  dxDataGrid_rutasExp_removeCellRender(args) {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={(e) =>
          this.dxDataGrid_rutasExp_onClick_removeCell(e, args)
        }
      >
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  }

  dxDataGrid_rutasExp_onClick_removeCell(e, args) {
    args.component.deleteRow(args.rowIndex);
    args.component.selectRowsByIndexes(args.rowIndex);
  }

  dxDataGrid_rutasExp_onRowPrepared = (e) => {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  };

  dxDataGrid_rutasExp_onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      showText: "inMenu",
      options: {
        icon: "refresh",
        text: getTrad("actualizar"),
        onClick: () => e.component.refresh(),
      },
    });

    e.toolbarOptions.items.forEach((item) => {
      switch (item.name) {
        case "addRowButton":
          item.location = "before";
          item.showText = "always";
          item.options.text = getTrad("añadir");
          item.options.icon = "plus";
          item.options.onClick = () => {
            e.component.addRow();
          };
          break;
        default:
          break;
      }
    });
  };

  dxList_entidades_onSelectionChanged(value, dropDownBoxComponent, action) {
    let { idsAlmacenesSel } = this.state;
    if (action == "add") {
      let almacenes = new Set([...idsAlmacenesSel, value.idEntidad]); // Quitar duplicados
      this.setState({ idsAlmacenesSel: [...almacenes] });
    } else if (action == "remove") {
      let index = idsAlmacenesSel.indexOf(value.idEntidad);
      if (index > -1) {
        idsAlmacenesSel.splice(index, 1);
      }
      this.setState({ idsAlmacenesSel: idsAlmacenesSel });
    }
  }

  dxList_onClick_añadirEntidades(e) {
    let { idRutaExpedicion } = this.state;
    if (idRutaExpedicion == -1 || idRutaExpedicion == null) {
      notify({
        message: "Debe seleccionar una ruta de expedición",
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
    } else {
      this.setState({ isVisible_dxPopup_compaEnti: true });
    }
  }

  dxList_paradaNRutaExpedicion_itemRender(item, index) {
    let _this = this;
    return (
      <div className="d-flex">
        <div style={{ flexBasis: 30 }}>{item.orden - 1 + "."}</div>
        <div className="flex-fill justify-content-start">
          {item.denominacion}
        </div>
        <div style={{ flexBasis: 30 }}>
          <div
            className="font-size-lg center-svg icon_Cerrar"
            onClick={(e) => {
              dxMensajePregunta(_this.getTrad("alert_eliminarRegistro"), [
                [
                  this.getTrad("aceptar"),
                  function (e) {
                    _this.dxList_paradaNRuta
                      .deleteItem(index)
                      .done(function () {
                        _this.datasource_paradaNRutaExpedicion
                          .reload()
                          .done(function (result) {
                            _this.setState({
                              omitir_idsEntidad: result.map((x) => x.idEntidad),
                              resetListasCompaEnti: true,
                            });
                          });
                      });
                  },
                  "normal",
                ],
                [_this.getTrad("cancelar"), function () {}],
              ]);
            }}
          ></div>
        </div>
      </div>
    );
  }

  dxList_onItemHold = (e) => {
    e.component.selectItem(e.itemIndex);
  };

  dxList_onItemDeleted(e) {
    let _this = this;
    e.component.option("disabled", true);
    var cont = e.itemData.orden - 1;
    var items = e.component.getDataSource().items();
    for (var i = cont; i < items.length; i++) {
      var updateEntidad = {};
      var isUltimoItem = i + 1 === items.length;
      updateEntidad.orden = items[i].orden - 1;

      if (isUltimoItem) {
        _this.datasource_paradaNRutaExpedicion
          .store()
          .update(
            items[i].idParadaNRutaExpedicion,
            patchRequestHandler(updateEntidad),
          )
          .done(function () {
            _this.datasource_paradaNRutaExpedicion.reload().done(function () {
              e.component.option("disabled", false);
            });
          });
      } else {
        _this.datasource_paradaNRutaExpedicion
          .store()
          .update(
            items[i].idParadaNRutaExpedicion,
            patchRequestHandler(updateEntidad),
          );
      }
    }
    if (cont === items.length) {
      _this.datasource_paradaNRutaExpedicion.reload().done(function () {
        e.component.option("disabled", false);
      });
    }
  }

  dxList_onItemReordered = (e) => {
    let _this = this;
    e.component.option("disabled", true);
    let items = e.component.getDataSource().items();
    let isAscendente = e.fromIndex < e.toIndex;
    let desde = isAscendente ? e.fromIndex : e.toIndex;
    let hasta = isAscendente ? e.toIndex : e.fromIndex;

    var entidadNRuta = {};
    for (var i = desde; i <= hasta; i++) {
      var cont = i + 2;
      var itemEntidadNRuta = items[i];
      entidadNRuta.orden = isAscendente ? cont - 1 : cont + 1;

      //
      if (e.itemData.idEntidad === itemEntidadNRuta.idEntidad) {
        //Almacen que se mueve
        entidadNRuta.orden = e.toIndex + 2;
      }
      if (i === hasta) {
        _this.datasource_paradaNRutaExpedicion
          .store()
          .update(
            itemEntidadNRuta.idParadaNRutaExpedicion,
            patchRequestHandler(entidadNRuta),
          )
          .done(function () {
            _this.datasource_paradaNRutaExpedicion.reload().done(function () {
              e.component.selectItem(e.toIndex);
              e.component.scrollToItem(e.toIndex);
              e.component.option("disabled", false);
            });
          });
      } else {
        _this.datasource_paradaNRutaExpedicion
          .store()
          .update(
            itemEntidadNRuta.idParadaNRutaExpedicion,
            patchRequestHandler(entidadNRuta),
          );
      }
    }
  };

  onShowing_dxPopup_compaEnti(e) {
    this.setState({ resetListasCompaEnti: false });
  }

  onHiding_dxPopup_compaEnti(e) {
    this.setState({
      isVisible_dxPopup_compaEnti: false,
      idsAlmacenesSel: [],
      resetListasCompaEnti: true,
    });
  }

  onHidden_dxPopup_compaEnti(e) {
    this.setState({ resetListasCompaEnti: false });
  }

  onClick_PopupEntidad_guardar(e) {
    let _this = this;
    let { idsAlmacenesSel, idRutaExpedicion } = this.state;
    let { lavanderia } = this.props;

    if (idsAlmacenesSel.length == 0) {
      notify({
        message: getTrad("alerta_seleccionarEntidad"),
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
    } else {
      e.component.option("disabled", true);
      this.datasource_paradaNRutaExpedicion_todosAlmacenes
        .load()
        .done(async function (result) {
          var dxListEntidadesNRuta_CountItem = result.length;
          let hasLavanderia = result.length > 0;

          if (!hasLavanderia) {
            idsAlmacenesSel.unshift(0);
            idsAlmacenesSel.push(-1);
          }

          for (
            let rowIndex = 0;
            rowIndex < idsAlmacenesSel.length;
            rowIndex++
          ) {
            await new Promise((resolve) => {
              let item = idsAlmacenesSel[rowIndex];
              dxListEntidadesNRuta_CountItem++;
              var isLastElement = rowIndex == idsAlmacenesSel.length - 1;
              var entidadNRutaExp = {};
              if (!hasLavanderia && (rowIndex == 0 || isLastElement)) {
                entidadNRutaExp.isCarga = rowIndex == 0;
                entidadNRutaExp.idLavanderia = lavanderia.idLavanderia;
                entidadNRutaExp.idRutaExpedicion = idRutaExpedicion;
                entidadNRutaExp.orden = dxListEntidadesNRuta_CountItem;
              } else {
                entidadNRutaExp.idEntidad = item;
                entidadNRutaExp.isCarga = false;
                entidadNRutaExp.idRutaExpedicion = idRutaExpedicion;
                entidadNRutaExp.orden =
                  dxListEntidadesNRuta_CountItem + (hasLavanderia ? -1 : 0);
              }

              _this.datasource_paradaNRutaExpedicion
                .store()
                .insert(entidadNRutaExp)
                .done(function () {
                  if (isLastElement) {
                    setTimeout(() => {
                      _this.datasource_paradaNRutaExpedicion
                        .reload()
                        .done(function (result) {
                          var itemsEntidadesNRuta = result.length;
                          _this.dxList_paradaNRuta.selectItem(
                            itemsEntidadesNRuta - 1,
                          );
                          _this.dxList_paradaNRuta.scrollToItem(
                            itemsEntidadesNRuta - 1,
                          );

                          _this.setState({
                            omitir_idsEntidad: result.map((x) => x.idEntidad),
                            idsAlmacenesSel: [],
                            resetListasCompaEnti: true,
                          });
                        });
                      notify({
                        message: getTrad("aviso_C_RegistroInsertado"),
                        type: "success",
                        displayTime: "2500",
                        closeOnClick: true,
                      });
                      e.component.option("disabled", false);
                      _this.setState({ isVisible_dxPopup_compaEnti: false });
                      resolve();
                    }, 0);
                  } else {
                    resolve();
                  }
                });
            });
          }
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia } = this.props;
    if (lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) {
      this.setState(
        {
          idRutaExpedicion: null,
          idsAlmacenesSel: [],
          isVisible_dxPopup_compaEnti: false,
          omitir_idsEntidad: [],
          resetListasCompaEnti: false,
          recargarListas: true,
        },
        () => {
          this.datasource_rutasExpedicion.reload();
          this.datasource_paradaNRutaExpedicion.reload();
          this.dxDataGrid_rutasExp.deselectAll();

          this.setState({ recargarListas: false });
        },
      );
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RutasExpedicion);
