import React, { useState } from "react";
import { connect } from "react-redux";

import Box, { Item } from "devextreme-react/box";

import DataGrid from "./components/DataGrid";
import GestionAlmacenesProvider, { GestionAlmacenesContext } from "./components/GestionAlmacenesProvider";
import Layout from "./components/Layout";
import PageTitle from "layout/AppMain/PageTitle";
import TreeView from "./components/TreeView";
import { getNombreFormulario } from "helpers";

const GestionAlmacenes = ({ idioma, user }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [codigoMoneda, setCodigoMoneda] = useState("EUR");
  const [idAlmacenPadreSel, setIdAlmacenPadreSel] = useState(null);
  const [idAlmacenSel, setIdAlmacenSel] = useState(null);

  const heading = getNombreFormulario({ props: { idioma, user } });

  return (
    <Layout selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex}>
      <>
        <PageTitle heading={heading} />
        <div className={"media-body"}>
          <div className={"formContainer scrollbar-container"}>
            <Box width={"100%"} height={"100%"}>
              <Item ratio={1}>
                <TreeView
                  setIdAlmacenSel={setIdAlmacenSel}
                  setIdAlmacenPadreSel={setIdAlmacenPadreSel}
                  setCodigoMoneda={setCodigoMoneda}
                />
              </Item>
              <Item baseSize={10} />
              <Item ratio={4}>
                <GestionAlmacenesProvider idAlmacenSel={idAlmacenSel}>
                  <GestionAlmacenesContext.Consumer>
                    {({
                      load_dataSource_getRecambios,
                      isLoading,
                      dataSource,
                      infoActual,
                    }) => (
                      <DataGrid
                        idAlmacenSel={idAlmacenSel}
                        idAlmacenPadreSel={idAlmacenPadreSel}
                        codigoMoneda={codigoMoneda}
                        setSelectedIndex={setSelectedIndex}
                        load_dataSource_getRecambios={
                          load_dataSource_getRecambios
                        }
                        isLoading={isLoading}
                        dataSource={dataSource}
                        infoActual={infoActual}
                      />
                    )}
                  </GestionAlmacenesContext.Consumer>
                </GestionAlmacenesProvider>
              </Item>
            </Box>
          </div>
        </div>
      </>
    </Layout>
  );
};

const mapStateToProps = ({ Global, Authentication }) => ({
  idioma: Global.idioma,
  user: Authentication.user,
});

export default connect(mapStateToProps)(GestionAlmacenes);
