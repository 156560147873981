import React, { Fragment } from "react";
import { connect } from "react-redux";

import PageTitle from "../../../layout/AppMain/PageTitle";

import {
  getNombreFormulario,
  authHeader,
  errorHandler,
} from "../../../helpers";
import { connectionConstants } from "../../../constants";
import GridRecursosEnergeticos from "./components/GridRecursosEnergeticos";
import PopupRecursosEnergeticos from "./components/PopupRecursosEnergeticos";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

class RecursosEnergeticos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tblGrupoEnergetico: [],
      tblCategoriaRecurso: [],
      tblUnidadMedida: [],
      recursoEnergeticoSel: null,
    };

    this.GridRecursosEnergeticos_REF = React.createRef();
  }

  datasource_tblGrupoEnergetico = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblGrupoEnergetico",
      key: "idGrupoEnergetico",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idGrupoEnergetico", "denominacion"],
  });

  datasource_tblCategoriaRecurso = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaRecurso",
      key: "idCategoriaRecurso",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idCategoriaRecurso", "denominacion"],
  });

  datasource_tblUnidadMedida = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblUnidadMedida",
      key: "idUnidadMedida",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idUnidadMedida", "denominacion"],
  });

  render() {
    const {
      tblGrupoEnergetico,
      tblCategoriaRecurso,
      tblUnidadMedida,
      recursoEnergeticoSel,
    } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="RecursosEnergeticos" className={"formContainer"}>
            <GridRecursosEnergeticos
              ref={(ref) => (this.GridRecursosEnergeticos_REF = ref)}
              tblCategoriaRecurso={tblCategoriaRecurso}
              tblUnidadMedida={tblUnidadMedida}
              startEdit_recursoContador={this.startEdit_recursoContador}
            />

            <PopupRecursosEnergeticos
              visible={recursoEnergeticoSel != null}
              tblGrupoEnergetico={tblGrupoEnergetico}
              tblCategoriaRecurso={tblCategoriaRecurso}
              tblUnidadMedida={tblUnidadMedida}
              recursoEnergeticoSel={recursoEnergeticoSel}
              endEdit_recursoContador={this.endEdit_recursoContador}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  startEdit_recursoContador = (recursoEnergeticoSel) => {
    this.setState({ recursoEnergeticoSel });
  };

  endEdit_recursoContador = (reload) => {
    if (reload) {
      this.GridRecursosEnergeticos_REF.datasource.reload();
    }
    this.setState({ recursoEnergeticoSel: null });
  };

  async componentDidMount() {
    const [tblGrupoEnergetico, tblCategoriaRecurso, tblUnidadMedida] =
      await Promise.all([
        this.datasource_tblGrupoEnergetico.load(),
        this.datasource_tblCategoriaRecurso.load(),
        this.datasource_tblUnidadMedida.load(),
      ]);
    this.setState({ tblGrupoEnergetico, tblCategoriaRecurso, tblUnidadMedida });
  }
}

const mapStateToProps = (state) => ({
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(RecursosEnergeticos);
