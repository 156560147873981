import { getPorcentajeDesviacion } from "pages/Finanzas/ControlPresupuestarioV2/shared/functions";
import React from "react";
import CurrencyFormatter from "../../CurrencyFormatter";

class DesviacionCell extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    style = { flexBasis: 100 };

    render() {
        const { data } = this.props;
        const { valorReal, valorPresupuestado, valorAjuste } = data.data;

        const totalReal = valorReal + (valorAjuste ?? 0);

        let valorPorcentaje = ((data.value / valorPresupuestado) * 100) / 100;
        let porcentaje = getPorcentajeDesviacion(Number.isNaN(valorPorcentaje) ? 0 : valorPorcentaje);

        let color =
            totalReal < valorPresupuestado
                ? "var(--danger)"
                : totalReal > valorPresupuestado
                ? "var(--success)"
                : "inherit";

        let icon =
            totalReal < valorPresupuestado
                ? "icon_ArrowDown"
                : totalReal > valorPresupuestado
                ? "icon_ArrowUp"
                : "dx-icon-equal font-weight-bold";

        return (
            <div className="d-flex mr-3 position-relative" style={{ color }}>
                <i className={`${icon} font-size-lg position-absolute-left`} />
                <div className="flex-1 text-right">
                    <CurrencyFormatter value={data.value} idMoneda={data.data.idMoneda} />
                </div>
                <div className="text-right" style={this.style}>
                    {porcentaje}
                </div>
            </div>
        );
    }
}

export default DesviacionCell;
