import { Cookies } from "react-cookie-banner";
import notify from "devextreme/ui/notify";
import { userActions } from "../actions";
import { userService } from "../services";
import { flatObject } from "./utils";

export let fechaUltimaPeticion = null;

export function authHeader() {
    fechaUltimaPeticion = new Date();
    // return authorization header with jwt token
    const cookies = new Cookies();
    const user = cookies.get("user");
    if (user && user.token) {
        return { Authorization: "Bearer " + user.token };
    } else {
        return {};
    }
}

export function errorHandler(error, COMPONENT) {
    if (error.httpStatus === 401) {
        const USER = JSON.parse(localStorage.getItem("user"));
        if (USER && USER.enableFullScreen == true) {
            userService.refreshToken();
        } else {
            //DASHBOARD
            userActions.logout();
            window.location.reload();
        }
    } else {
        if (process.env.NODE_ENV !== "production") {
            notify({
                message: error.message,
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }
}

export function patchRequestHandler(obj) {
    let result = [];
    Object.keys(obj).forEach((key) => {
        result.push({
            op: obj[key] && obj[key].isRemove ? "remove" : "replace",
            path: "/" + key,
            value: obj[key],
        });
    });
    return result;
}

export function patchRequestHandlerWithNavigations(obj) {
    let result = [];
    let flatObj = flatObject(obj);

    Object.entries(flatObj).forEach(([key, value]) => {
        result.push({
            op: obj[key] && obj[key].isRemove ? "remove" : "replace",
            path: "/" + key,
            value,
        });
    });
    return result;
}
