export const getInitialState = () => ({
  idRecambio: 0,
  denominacion: "",
  activo: true,
  eliminado: false,
  referencia: "",
  idProveedor: null,
  referenciaInterna: "",
  descripcionArticulo: "",
  tblRecambioNAlmacenRecambios: [],
  tblRecambioNProveedor: [],
});
