import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "devextreme-react";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DateRangePicker from "../../../../../../components/DateRangePicker/DateRangePicker";
import List from "devextreme-react/list";
import { connectionConstants } from "../../../../../../constants";
import {
  authHeader,
  errorHandler,
  formatDate_parameter,
  getTrad,
} from "../../../../../../helpers";
import LoadPanel_ProgressBar from "components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import notify from "devextreme/ui/notify";

const hoy = new Date();
const startDate = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
const endDate = hoy;

class SelectorContadores extends Component {
  constructor(props) {
    super(props);

    this.dx_List_recursosContador_REF = React.createRef();

    this.state = {
      dataSource_recursosContador: [],
      idRecursoContadorSel: [],
      maxDate: new Date(),
      fechaSel: {
        key: "selection",
        idTipoCalendario: 2,
        fechaSel: hoy,
        startDate: startDate,
        endDate: endDate,
      },
      isVisible_LoadPanel_ProgressBar: false,
      progress: 0,
    };
  }

  get dx_List_recursosContador() {
    return this.dx_List_recursosContador_REF.current.instance;
  }

  render() {
    const {
      fechaSel,
      maxDate,
      idRecursoContadorSel,
      dataSource_recursosContador,
      isVisible_LoadPanel_ProgressBar,
      progress,
    } = this.state;
    const { visible } = this.props;

    return (
      <>
        <Box direction="col" width="100%" height="100%">
          <ItemBox ratio={1}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="font-size-lg">Seleccionar contadores</div>
              <div className="d-flex align-items-center he-100 mr-1">
                {visible && (
                  <DateRangePicker
                    align={"right"}
                    month
                    year
                    day
                    defaultType="personalizado"
                    fechaSel={fechaSel}
                    maxDate={maxDate}
                    onDateSelected={this.dateRangePicker_onDateSelected}
                  />
                )}
              </div>
            </div>
          </ItemBox>
          <ItemBox ratio={10}>
            <List
              width={500}
              ref={this.dx_List_recursosContador_REF}
              dataSource={dataSource_recursosContador}
              displayExpr="denominacion"
              keyExpr="idRecursoContador"
              searchEnabled={true}
              selectionMode="all"
              showSelectionControls={true}
              onSelectionChanged={this.onSelection}
              selectedItemKeys={idRecursoContadorSel}
              grouped={true}
              collapsibleGroups={true}
              groupRender={this.cellRender_groupExpand}
            />
          </ItemBox>
          <ItemBox ratio={1}>
            <div className=".bg-danger d-flex w-100 justify-content-end pt-4 ">
              <Button
                height={45}
                width="35%"
                text={getTrad("exportar")}
                type="success"
                onClick={this.exportaContadores}
              />
            </div>
          </ItemBox>
        </Box>
        <LoadPanel_ProgressBar
          title={"Generando archivo"}
          progress={progress}
          visible={isVisible_LoadPanel_ProgressBar}
        />
      </>
    );
  }

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      this.setState({ fechaSel: fecha });
    }
  };

  GroupTemplate = (item) => {
    return <div>{item.key}</div>;
  };

  onSelection = (e) => {
    const { addedItems, removedItems } = e;
    const { idRecursoContadorSel } = this.state;
    let recursosSel_ = [...idRecursoContadorSel];

    if (addedItems.length > 0) {
      addedItems.forEach((x) => {
        recursosSel_.push(x.idRecursoContador);
      });
    }
    if (removedItems.length > 0) {
      recursosSel_ = idRecursoContadorSel.filter(
        (x) => !removedItems.some((y) => x === y.idRecursoContador),
      );
    }
    this.setState({ idRecursoContadorSel: recursosSel_ });
  };

  formatFecha = (date, tipo) => {
    const fechaHora = new Date(date);

    const horas = fechaHora.getHours();
    const minutos = fechaHora.getMinutes();
    const dia = fechaHora.getDate();
    const mes = fechaHora.getMonth() + 1;
    const anio = fechaHora.getFullYear();

    const horaFormateada = `${horas.toString().padStart(2, "0")}:${minutos.toString().padStart(2, "0")}`;
    const fechaFormateada = `${dia.toString().padStart(2, "0")}-${mes.toString().padStart(2, "0")}-${anio}`;

    if (tipo === 1) {
      return fechaFormateada;
    } else if (tipo === 2) {
      return horaFormateada;
    }
  };

  GroupTemplate = (item) => {
    return <div>{item.key}</div>;
  };

  onSelection = (e) => {
    const { addedItems, removedItems } = e;
    const { idRecursoContadorSel } = this.state;
    let recursosSel_ = [...idRecursoContadorSel];

    if (addedItems.length > 0) {
      addedItems.forEach((x) => {
        recursosSel_.push(x.idRecursoContador);
      });
    }
    if (removedItems.length > 0) {
      recursosSel_ = idRecursoContadorSel.filter(
        (x) => !removedItems.some((y) => x === y.idRecursoContador),
      );
    }
    this.setState({ idRecursoContadorSel: recursosSel_ });
  };

  exportaContadores = () => {
    const { onHiding } = this.props;
    const { idRecursoContadorSel, progress } = this.state;
    const fechaSelInicial = {
      key: "selection",
      idTipoCalendario: 2,
      fechaSel: hoy,
      startDate: startDate,
      endDate: endDate,
    };
    const currentDate = this.formatFecha(new Date(), 1);
    const workbook = new Workbook();
    const _this = this;

    if (idRecursoContadorSel.length == 0) {
      notify({
        message: getTrad("alerta_SeleccionarParaImprimir"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }

    this.setState({ isVisible_LoadPanel_ProgressBar: true, progress: 0 }); //Comienza loading

    this.datasource_lecturaContadores.load().done((datos) => {
      if (datos.length == 0) {
        this.setState(
          { isVisible_LoadPanel_ProgressBar: false, progress: 100 },
          () => {
            // Finaliza loading // error
            notify({
              message: getTrad("sinRegistrosRango_lecturaContador"),
              type: "error",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        );
        return;
      }

      let contadoresAgrupados = [];
      datos.forEach((element) => {
        // Agrupa contadores
        const {
          idRecursoContador,
          denominacion,
          idGrupoEnergetico,
          denoGrupoEnergetico,
        } = element;
        const grupoExistente = contadoresAgrupados.find(
          (grupo) => grupo.idRecursoContador === idRecursoContador,
        );
        if (grupoExistente) {
          grupoExistente.items.push(element);
        } else {
          contadoresAgrupados.push({
            idRecursoContador,
            idGrupoEnergetico,
            denominacion,
            denoGrupoEnergetico,
            items: [element],
          });
        }
      });

      const sheet = workbook.addWorksheet("Lectura contadores");
      let numRow = 1;

      sheet.columns = [
        // Columnas
        { header: "ID Recurso", key: "idRecurso", width: 20 }, //A
        { header: "Denominación", key: "denominacion", width: 60 }, //B
        { header: "Grupo energético", key: "grupoEnergetico", width: 30 }, //C
        { header: "Fecha", key: "fecha", width: 20 }, //D
        { header: "Hora", key: "hora", width: 20 }, //E
        { header: "Valor contador", key: "valor", width: 20 }, //F
        { header: "Consumo", key: "consumo", width: 20 }, //G
      ];

      const denominacionColumn = sheet.getColumn("denominacion");
      const consumoColumn = sheet.getColumn("consumo");
      const fechaColumn = sheet.getColumn("fecha");

      denominacionColumn.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      consumoColumn.alignment = { vertical: "middle", horizontal: "center" };
      fechaColumn.alignment = { vertical: "middle", horizontal: "center" };

      contadoresAgrupados.forEach((grupo) => {
        const { denominacion, items, denoGrupoEnergetico, idRecursoContador } =
          grupo;

        numRow += 1;
        items.forEach((item) => {
          sheet.getCell("A" + numRow).value = idRecursoContador;
          sheet.getCell("B" + numRow).value = denominacion;
          sheet.getCell("C" + numRow).value = denoGrupoEnergetico;
          sheet.getCell("D" + numRow).value = this.formatFecha(item.fecha, 1);
          sheet.getCell("E" + numRow).value = this.formatFecha(item.fecha, 2);
          sheet.getCell("F" + numRow).value = item.valor;
          sheet.getCell("G" + numRow).value = item.consumo;
          numRow += 1;
        });
      });

      workbook.xlsx
        .writeBuffer() // Exporta
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            currentDate + " - LECTURA CONTADORES.xlsx",
          );
          _this.setState({
            isVisible_LoadPanel_ProgressBar: false,
            progress: 100,
            idRecursoContadorSel: [],
            fechaSel: fechaSelInicial,
          });
          onHiding();
        })
        .then(function () {});
    });
  };

  cargaProgresa = () => {
    const { progress } = this.state;

    if (progress < 100) {
      const nuevoProgreso = progress + 20;
      this.setState({ progress: nuevoProgreso });
    } else {
      this.detenerIntervalo();
    }
  };

  iniciarIntervalo = () => {
    this.intervalo = setInterval(this.cargaProgresa, 1500);
  };

  detenerIntervalo = () => {
    clearInterval(this.intervalo);
  };

  //#region DATOS

  datasource_tblRecursoContador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecursoContador",
      key: "idRecursoContador",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      onLoading: (loadOptions) =>
        this.datasource_tblRecursoContador_onLoading(loadOptions),
      version: 4,
    }),
    select: ["idRecursoContador", "denominacion", "idGrupoEnergetico"],
    expand: ["idGrupoEnergeticoNavigation", "idCategoriaRecursoNavigation"],
  });

  datasource_tblRecursoContador_onLoading = (loadOptions) => {
    const { lavanderia } = this.props;
    loadOptions.filter = ["idLavanderia", "=", lavanderia.idLavanderia];
  };

  datasource_lecturaContadores = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/ControlCalidad/selectLecturaContadores",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_lecturaContadores_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_lecturaContadores_beforeSend = (request) => {
    const { fechaSel, idRecursoContadorSel } = this.state;
    var string_IdsRecursoContador = idRecursoContadorSel.join("|");
    this.iniciarIntervalo();

    request.headers = { ...authHeader() };
    request.params.string_IdsRecursoContador = string_IdsRecursoContador;
    request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
    request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
  };

  //#endregion

  componentDidMount() {
    this.datasource_tblRecursoContador.load().done((dataSource) => {
      const grupoAgua = {
        key: "AGUA",
        items: dataSource.filter((x) => x.idGrupoEnergetico === 1),
      };
      const grupoElect = {
        key: "ELECTRICIDAD",
        items: dataSource.filter((x) => x.idGrupoEnergetico === 2),
      };
      const grupoCalef = {
        key: "CALEFACCIÓN",
        items: dataSource.filter((x) => x.idGrupoEnergetico === 3),
      };
      const datosFormateados = [grupoAgua, grupoElect, grupoCalef];
      this.setState({ dataSource_recursosContador: datosFormateados });
    });
  }

  componentDidUpdate(prevProps) {
    const { lavanderia } = this.props;

    if (prevProps.lavanderia.idLavanderia != lavanderia.idLavanderia) {
      this.datasource_tblRecursoContador.reload().done((dataSource) => {
        const grupoAgua = {
          key: "AGUA",
          items: dataSource.filter((x) => x.idGrupoEnergetico === 1),
        };
        const grupoElect = {
          key: "ELECTRICIDAD",
          items: dataSource.filter((x) => x.idGrupoEnergetico === 2),
        };
        const grupoCalef = {
          key: "CALEFACCIÓN",
          items: dataSource.filter((x) => x.idGrupoEnergetico === 3),
        };
        const datosFormateados = [grupoAgua, grupoElect, grupoCalef];
        this.setState({ dataSource_recursosContador: datosFormateados });
      });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(SelectorContadores);
