import { formatDate_noTime_parameter } from "helpers";
import { getDefaultIvaId, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearFactura = () => {
    return {
        idAdmFactura_Estado: 1,
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1,
        idAdmTipoDescuento: 1,
        idTipoFactura: null,
        idIncoterm: null,
        codigo: null,
        fecha: null,
        fechaVencimiento: null,
        idAdmTipoNCF: null,
        NCF: null,
        comentario: null,
        idAdmCliente: null,
        idCuentaBancaria: null,
        idAdmFormaCobro: null,
        tasaCambio: 0,
        descuento: 0,
        idIvaNPais: getDefaultIvaId(false),
        idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
        observaciones: null,
        numPedido: null,
        idTipoAlbaran: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        idAdmAlbaranVenta: [],
        idAdmCondicionPago: null,
        tblAdmConceptoVenta: [],
        tipoRetencion: null,
        codigoRetencion: null,
        idReferenciaFacturaVenta: null,
        idReferenciaFacturaVentaNavigation: {
            idAdmFacturaVenta: null,
            NCF: null,
            codigoFactura: null,
        },
    };
};

export const payloadBuilder = (formData) => {
    if (typeof formData.fecha == "object") {
        formData.fecha = formatDate_noTime_parameter(formData.fecha);
    }
    if (typeof formData.fechaVencimiento == "object") {
        formData.fechaVencimiento = formatDate_noTime_parameter(formData.fechaVencimiento);
    }
    formData.tblAdmConceptoVenta = formData.tblAdmConceptoVenta.map((concepto) => {
        delete concepto.tblAdmConceptoVenta;
        return concepto;
    });
    formData.idAdmAlbaranVenta = formData.idAdmAlbaranVenta.map((x) => x.idAdmAlbaranVenta);
    formData.idEmpresaPolarier = empresaPolarierSel.idEmpresaPolarier;
    return formData;
};
