import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate,
  formatNumber,
  tooltipControl_creacion,
  formatDate_parameter,
  addDays,
  dxMensajePregunta,
} from "../../../../helpers";
import { svg_info, svg_warning } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import query from "devextreme/data/query";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";

// Export excel
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_inventarios;
  var datasource_compañias;
  var datasource_entidades;
  var datasource_analisiInventario;
  var datasource_reposicionesParStock;
  var datasource_analisiInventario_entidades;
  var datasource_analisiInventario_entidades_gridPrendas;

  var informeSel;
  var inventariosSel = [];
  var isTeorico = false,
    teorico_idPrendaSel = -1,
    teorico_idEntiSel = -1;
  var analisisInv_idPrendaSel = -1;

  var finalTeorico_items;
  var prendasAgrupadas = [];

  //#region Datasource

  var enum_informes = [
    {
      idInforme: 1,
      denominacion: getTrad("analisiEntreInventarios"),
    },
    {
      idInforme: 2,
      denominacion: getTrad("reposicionesContraParStock"),
    },
    {
      idInforme: 3,
      denominacion: getTrad("valoracionInventario"),
    },
    {
      idInforme: 4,
      denominacion: getTrad("inventarioFinalTeorico"),
    },
  ];

  datasource_inventarios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblInventario",
      key: "idInventario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;

        request.params.isSubInventario = false;

        var compaSel = [];
        if (typeof $("#dxList_compañia")[0] !== "undefined") {
          compaSel = $("#dxList_compañia")
            .dxList("instance")
            .option("selectedItems");
        }

        var entidadSel = [];
        if (typeof $("#dxList_entidad")[0] !== "undefined") {
          entidadSel = $("#dxList_entidad")
            .dxList("instance")
            .option("selectedItems");
        }

        request.params.idCompañia =
          compaSel.length > 0 ? compaSel[0].idCompañia : null;
        request.params.idEntidad =
          entidadSel.length > 0 ? entidadSel[0].idEntidad : null;
      },
      version: 4,
    }),
    select: ["idInventario", "denominacion", "fecha", "fechaCierre"],
    sort: [{ selector: "fecha", desc: true }],
  });

  datasource_compañias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    expand: ["tblMoneda"],
    sort: "denominacion",
    select: ["idCompañia", "idEntidad", "denominacion"],
  });

  datasource_analisiInventario = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAnalisisInventario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 2) {
          request.params.idInventarioActual = inventariosSel[0].idInventario;
          request.params.idInventarioAnterior = inventariosSel[1].idInventario;
        }
      },
      version: 4,
    }),
  });

  datasource_analisiInventario_entidades_gridPrendas = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAnalisisInventario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 2) {
          request.params.idInventarioActual = inventariosSel[0].idInventario;
          request.params.idInventarioAnterior = inventariosSel[1].idInventario;
        }
      },
      version: 4,
    }),
  });

  datasource_analisiInventario_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAnalisisInventario",
      key: ["idEntidad", "idLavanderia"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 2) {
          request.params.idInventarioActual = inventariosSel[0].idInventario;
          request.params.idInventarioAnterior = inventariosSel[1].idInventario;
          request.params.idPrenda = analisisInv_idPrendaSel;
        }
      },
      version: 4,
    }),
  });

  datasource_reposicionesParStock = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectReposicionesParStock",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 1) {
          request.params.idInventario = inventariosSel[0].idInventario;
          request.params.isTeorico = isTeorico;
        }
      },
      version: 4,
    }),
  });

  var datasource_valoracionInventarios = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectValoracionInventarios",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 1) {
          let formatAgrupacion = "";
          $.each($.extend(true, [], prendasAgrupadas), function (index, item) {
            formatAgrupacion += "|" + item.join(",");
          });

          request.params.idInventario = inventariosSel[0].idInventario;
          request.params.agrupacionesId = "'" + formatAgrupacion + "'";
        }
      },
      version: 4,
    }),
  });

  var datasource_finalTeorico = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectInventarioFinalTeorico",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (inventariosSel.length === 1) {
          request.params.idInventario = inventariosSel[0].idInventario;

          var dxDateBox_fechaHasta = $("#dxDateBox_fechaHasta").dxDateBox(
            "instance",
          );
          var fechaDesde = null;
          if (dxDateBox_fechaHasta) {
            fechaDesde = formatDate_parameter(
              dxDateBox_fechaHasta.option("value"),
            );
          }
          request.params.fechaDesde = fechaDesde;
        }
      },
      version: 4,
    }),
  });

  var enum_finalTeorico_tipoTabla = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("prendas").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("entidades").toUpperCase(),
    },
  ];

  var dataSource_lavanderias_todas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      version: 4,
    }),
    sort: ["denominacion"],
    select: ["idLavanderia", "denominacion"],
  });

  var datasource_entidades_todas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = null;
        request.params.todas = true;
      },
      version: 4,
    }),
    expand: ["tblMoneda"],
    sort: "denominacion",
    select: ["idCompañia", "idEntidad", "denominacion"],
  });

  var context_InfInventarios = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "Informes/InformesEjecutivos/InfInventarios/",
    entities: {
      fn_getFechaAnterior_AnalisisInventario: {
        key: "idInventario",
        keyType: {
          idInventario: "Int32",
        },
      },
    },
    beforeSend: (request) => (request.headers = { ...authHeader() }),
  });

  //#endregion

  $("#InfInventarios #dxContainer").dxBox({
    direction: "row",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    width: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          item.append(
            $("<div id='tabpanel-container' />")
              .addClass("no-header")
              .dxTabPanel({
                height: "100%",
                width: "100%",
                scrollByContent: true,
                scrollingEnabled: true,
                animationEnabled: true,
                swipeEnabled: false,
                items: [
                  {
                    title: getTrad("filtros"),
                    template: function () {
                      return $("<div id='dxBox_Filtros' />").dxBox({
                        direction: "row",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            //INVENTARIOS
                            ratio: 1,
                            template: function () {
                              return $("<div id='dxBox_Filtros'  />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                items: [
                                  {
                                    baseSize: "50px",
                                    template: function () {
                                      return $(
                                        "<div class='font-size' style='margin-left:9px;'>1. " +
                                          getTrad("seleccioneInforme") +
                                          "</div>",
                                      );
                                    },
                                  },
                                  {
                                    ratio: 1,
                                    template: function () {
                                      return $("<div />").dxList({
                                        dataSource: enum_informes,
                                        selectionMode: "single",
                                        keyExpr: "idInforme",
                                        height: "100%",
                                        scrolling: { mode: "infinite" },
                                        pageLoadMode: "scrollBottom",
                                        onSelectionChanged: function (i) {
                                          informeSel = i.addedItems[0];

                                          $("#dxBox_Filtros")
                                            .dxBox("instance")
                                            .option("items[1].visible", true);
                                          $("#dxBox_compaEnti")
                                            .dxBox("instance")
                                            .repaint();

                                          reiniciar_filtros();

                                          if (informeSel.idInforme === 3) {
                                            //Mostramos sólo los inventarios con fecha de cierre
                                            datasource_inventarios.filter([
                                              "!",
                                              ["fechaCierre", "=", null],
                                            ]);
                                          }
                                          datasource_inventarios.load();

                                          $("#dxBox_inventarios")
                                            .dxBox("instance")
                                            .option(
                                              "items[0].visible",
                                              informeSel.idInforme === 2,
                                            );
                                          $("#dxBox_inventarios")
                                            .dxBox("instance")
                                            .option(
                                              "items[1].visible",
                                              informeSel.idInforme === 2,
                                            );

                                          var texto =
                                            informeSel.idInforme === 1
                                              ? "3. " +
                                                getTrad(
                                                  "seleccioneDosInventarios",
                                                )
                                              : informeSel.idInforme === 4
                                                ? "3. " +
                                                  getTrad(
                                                    "seleccioneUnInventario",
                                                  )
                                                : "4. " +
                                                  getTrad(
                                                    "seleccioneUnInventario",
                                                  );
                                          $("#title_seleccioneInventario").text(
                                            texto,
                                          );

                                          isTeorico = false;
                                        },
                                        itemTemplate: function (data, index) {
                                          var result = $(
                                            "<div class='container_spanCentrado' style='height:160px;'>",
                                          );
                                          $("<div  class='font-size'>")
                                            .text(
                                              data.denominacion.toUpperCase(),
                                            )
                                            .appendTo(result);
                                          return result;
                                        },
                                      });
                                    },
                                  },
                                ],
                              });
                            },
                          },
                          {
                            //COMPAÑIAS Y ENTIDADES
                            ratio: 1,
                            visible: false,
                            template: function () {
                              return $(
                                "<div id='dxBox_compaEnti' style='padding: 0px 15px;' />",
                              ).dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                items: [
                                  {
                                    ratio: 0,
                                    shrink: 0,
                                    baseSize: 45,
                                    template: function () {
                                      return $(
                                        "<div class='font-size' />",
                                      ).text(
                                        "2. " + getTrad("seleccionaCompaEnti"),
                                      );
                                    },
                                  },
                                  {
                                    ratio: 1,
                                    template: function () {
                                      return $("<div/>").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        height: "100%",
                                        items: [
                                          {
                                            ratio: 0,
                                            shrink: 0,
                                            baseSize: 45,
                                            template: function () {
                                              return $(
                                                "<div class='font-size' />",
                                              ).text(getTrad("compañias"));
                                            },
                                          },
                                          {
                                            ratio: 1,
                                            template: function () {
                                              return $(
                                                "<div id='dxList_compañia'>",
                                              ).dxList({
                                                dataSource:
                                                  datasource_compañias,
                                                selectionMode: "single",
                                                keyExpr: "idCompañia",
                                                scrolling: { mode: "infinite" },
                                                height: "100%",
                                                pageLoadMode: "scrollBottom",
                                                onSelectionChanged: function (
                                                  e,
                                                ) {
                                                  if (e.addedItems.length > 0) {
                                                    $("#dxList_entidad")
                                                      .dxList("instance")
                                                      .unselectAll();

                                                    reiniciar_filtros();

                                                    if (
                                                      informeSel.idInforme === 3
                                                    ) {
                                                      //Mostramos sólo los inventarios con fecha de cierre
                                                      datasource_inventarios.filter(
                                                        [
                                                          "!",
                                                          [
                                                            "fechaCierre",
                                                            "=",
                                                            null,
                                                          ],
                                                        ],
                                                      );
                                                    }
                                                    datasource_inventarios
                                                      .load()
                                                      .done(function () {
                                                        $("#dxBox_Filtros")
                                                          .dxBox("instance")
                                                          .option(
                                                            "items[2].visible",
                                                            true,
                                                          );
                                                      });
                                                  }
                                                },
                                                itemTemplate: function (
                                                  data,
                                                  index,
                                                ) {
                                                  var result = $("<div>");
                                                  $("<div>")
                                                    .text(data.denominacion)
                                                    .appendTo(result);
                                                  return result;
                                                },
                                              });
                                            },
                                          },
                                        ],
                                      });
                                    },
                                  },
                                  {
                                    ratio: 1,
                                    template: function () {
                                      return $("<div/>").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        height: "100%",
                                        items: [
                                          {
                                            ratio: 0,
                                            shrink: 0,
                                            baseSize: 45,
                                            template: function () {
                                              return $(
                                                "<div class='font-size' />",
                                              ).text(getTrad("entidades"));
                                            },
                                          },
                                          {
                                            ratio: 1,
                                            template: function () {
                                              return $(
                                                "<div id='dxList_entidad'>",
                                              ).dxList({
                                                dataSource:
                                                  datasource_entidades,
                                                selectionMode: "single",
                                                keyExpr: "idEntidad",
                                                scrolling: { mode: "infinite" },
                                                pageLoadMode: "scrollBottom",
                                                height: "100%",
                                                searchEnabled: true,
                                                searchExpr: ["denominacion"],
                                                onSelectionChanged: function (
                                                  e,
                                                ) {
                                                  if (e.addedItems.length > 0) {
                                                    $("#dxList_compañia")
                                                      .dxList("instance")
                                                      .unselectAll();

                                                    reiniciar_filtros();

                                                    if (
                                                      informeSel.idInforme === 3
                                                    ) {
                                                      //Mostramos sólo los inventarios con fecha de cierre
                                                      datasource_inventarios.filter(
                                                        [
                                                          "!",
                                                          [
                                                            "fechaCierre",
                                                            "=",
                                                            null,
                                                          ],
                                                        ],
                                                      );
                                                    }
                                                    datasource_inventarios
                                                      .load()
                                                      .done(function () {
                                                        $("#dxBox_Filtros")
                                                          .dxBox("instance")
                                                          .option(
                                                            "items[2].visible",
                                                            true,
                                                          );
                                                      });
                                                  }
                                                },
                                                itemTemplate: function (
                                                  data,
                                                  index,
                                                ) {
                                                  var result = $("<div>");
                                                  $("<div>")
                                                    .text(data.denominacion)
                                                    .appendTo(result);

                                                  return result;
                                                },
                                              });
                                            },
                                          },
                                        ],
                                      });
                                    },
                                  },
                                ],
                              });
                            },
                          },
                          {
                            //INVENTARIOS
                            ratio: 1,
                            visible: false,
                            template: function () {
                              return $("<div id='dxBox_inventarios' />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                items: [
                                  {
                                    ratio: 0,
                                    shrink: 0,
                                    baseSize: 45,
                                    template: function () {
                                      return $(
                                        "<div id='title_inventarioTeorico' class='font-size' style='margin-left:9px;'>",
                                      ).text(
                                        "3. " +
                                          getTrad("preg_inventarioTeorico"),
                                      );
                                    },
                                  },
                                  {
                                    ratio: 0,
                                    shrink: 0,
                                    baseSize: 80,
                                    template: function () {
                                      return $(
                                        "<div id='dxList_inventarioTeorico'>",
                                      ).dxList({
                                        dataSource: [
                                          {
                                            denominacion:
                                              "Inventario teórico".toUpperCase(),
                                          },
                                        ],
                                        height: 60,
                                        selectionMode: "multiple",
                                        focusStateEnabled: false,
                                        onSelectionChanged: function (e) {
                                          if (e.addedItems.length > 0) {
                                            isTeorico = true;

                                            $(
                                              "#title_seleccioneInventario",
                                            ).text(
                                              "4. " +
                                                getTrad(
                                                  "seleccioneInventarioReferencia",
                                                ),
                                            );
                                          } else {
                                            isTeorico = false;

                                            var texto =
                                              informeSel.idInforme === 1
                                                ? "3. " +
                                                  getTrad(
                                                    "seleccioneDosInventarios",
                                                  )
                                                : "4. " +
                                                  getTrad(
                                                    "seleccioneUnInventario",
                                                  );
                                            $(
                                              "#title_seleccioneInventario",
                                            ).text(texto);
                                          }
                                        },
                                        itemTemplate: function (data, index) {
                                          var result = $("<div>");
                                          $("<div>")
                                            .text(data.denominacion)
                                            .appendTo(result);
                                          return result;
                                        },
                                      });
                                    },
                                  },
                                  {
                                    ratio: 0,
                                    shrink: 0,
                                    baseSize: 45,
                                    template: function () {
                                      return $(
                                        "<div id='title_seleccioneInventario' class='font-size' style='margin-left:9px;'>",
                                      );
                                    },
                                  },
                                  {
                                    ratio: 1,
                                    template: function () {
                                      return $(
                                        "<div id='dxList_inventarios'>",
                                      ).dxList({
                                        dataSource: datasource_inventarios,
                                        selectionMode: "multiple",
                                        keyExpr: "idInventario",
                                        scrolling: { mode: "infinite" },
                                        pageLoadMode: "scrollBottom",
                                        onSelectionChanged: function (e) {
                                          var selectedItems =
                                            e.component.option("selectedItems");

                                          if (informeSel.idInforme === 1) {
                                            //Analisis 2 inventarios
                                            const mostrar_analisiEntreInventarios =
                                              () => {
                                                let grids = [
                                                  "dxDataGrid_analisisInventarios_prendas",
                                                  "dxDataGrid_analisisInventarios_entidades_gridEntidades",
                                                  "dxDataGrid_analisisInventarios_entidades_gridPrendas",
                                                ];

                                                $.each(
                                                  grids,
                                                  function (index, item) {
                                                    if (
                                                      typeof $(
                                                        "#" + item,
                                                      )[0] !== "undefined"
                                                    ) {
                                                      var dxDataGrid = $(
                                                        "#" + item,
                                                      ).dxDataGrid("instance");
                                                      dxDataGrid.refresh(true);

                                                      dxDataGrid.columnOption(
                                                        "totalInvAnterior",
                                                        "caption",
                                                        formatDate(
                                                          inventariosSel[1]
                                                            .fecha,
                                                        ),
                                                      );
                                                      dxDataGrid.columnOption(
                                                        "totalInvActual",
                                                        "caption",
                                                        formatDate(
                                                          inventariosSel[0]
                                                            .fecha,
                                                        ),
                                                      );
                                                    }
                                                  },
                                                );

                                                $("#tabpanel-container")
                                                  .dxTabPanel("instance")
                                                  .option("selectedIndex", 1);
                                              };

                                            if (selectedItems.length === 1) {
                                              const firstInventario =
                                                e.component
                                                  .getDataSource()
                                                  .items()
                                                  .reduce(
                                                    (anterior, actual) => {
                                                      const fechaAnterior =
                                                        new Date(
                                                          anterior.fecha,
                                                        );
                                                      const fechaActual =
                                                        new Date(actual.fecha);
                                                      return fechaAnterior <
                                                        fechaActual
                                                        ? anterior
                                                        : actual;
                                                    },
                                                  );

                                              if (
                                                firstInventario.idInventario ===
                                                selectedItems[0].idInventario
                                              ) {
                                                context_InfInventarios
                                                  .invoke(
                                                    "fn_getFechaAnterior_AnalisisInventario",
                                                    {
                                                      idInventario:
                                                        selectedItems[0]
                                                          .idInventario,
                                                    },
                                                  )
                                                  .done((fechaAnterior) => {
                                                    inventariosSel = [
                                                      { ...selectedItems[0] },
                                                      { ...selectedItems[0] },
                                                    ];
                                                    inventariosSel[1].fecha =
                                                      new Date(fechaAnterior);
                                                    mostrar_analisiEntreInventarios();
                                                  });
                                              }
                                            } else if (
                                              selectedItems.length === 2
                                            ) {
                                              inventariosSel = selectedItems;
                                              mostrar_analisiEntreInventarios();
                                            }
                                          } else if (
                                            informeSel.idInforme === 2 &&
                                            selectedItems.length === 1
                                          ) {
                                            //Reposiciones stock 1 inventario
                                            inventariosSel = selectedItems;
                                            if (
                                              typeof $(
                                                "#dxDataGrid_reposicionesParStock",
                                              )[0] !== "undefined"
                                            ) {
                                              var dxDataGrid_reposicionesParStock =
                                                $(
                                                  "#dxDataGrid_reposicionesParStock",
                                                ).dxDataGrid("instance");
                                              dxDataGrid_reposicionesParStock.refresh(
                                                true,
                                              );

                                              dxDataGrid_reposicionesParStock.columnOption(
                                                "totalTeorico",
                                                "visible",
                                                isTeorico,
                                              );
                                              dxDataGrid_reposicionesParStock.columnOption(
                                                "entradas",
                                                "visible",
                                                isTeorico,
                                              );
                                              dxDataGrid_reposicionesParStock.columnOption(
                                                "salidas",
                                                "visible",
                                                isTeorico,
                                              );

                                              dxDataGrid_reposicionesParStock.columnOption(
                                                "precio",
                                                "format",
                                                currency_format(),
                                              );
                                              dxDataGrid_reposicionesParStock.columnOption(
                                                "precioTotal",
                                                "format",
                                                currency_format(),
                                              );
                                              dxDataGrid_reposicionesParStock.option(
                                                "summary.totalItems[0].valueFormat",
                                                currency_format(),
                                              );
                                            }
                                            $("#tabpanel-container")
                                              .dxTabPanel("instance")
                                              .option("selectedIndex", 2);
                                          } else if (
                                            informeSel.idInforme === 3 &&
                                            selectedItems.length === 1
                                          ) {
                                            //Reposiciones stock 1 inventario
                                            inventariosSel = selectedItems;

                                            datasource_valoracionInventarios.reload();

                                            if (
                                              $("#denoInv_valoracionInventario")
                                                .length > 0
                                            ) {
                                              $(
                                                "#denoInv_valoracionInventario",
                                              ).text(
                                                inventariosSel[0].denominacion +
                                                  " - " +
                                                  formatDate(
                                                    inventariosSel[0].fecha,
                                                  ),
                                              );
                                            }

                                            if (
                                              $(
                                                "#dxDataGrid_valoracionInventario",
                                              ).length > 0
                                            ) {
                                              var dxDataGrid_valoracionInventario =
                                                $(
                                                  "#dxDataGrid_valoracionInventario",
                                                ).dxDataGrid("instance");
                                              dxDataGrid_valoracionInventario.collapseAll(
                                                -1,
                                              );

                                              dxDataGrid_valoracionInventario.columnOption(
                                                "precioMedio",
                                                "format",
                                                currency_format(),
                                              );
                                              dxDataGrid_valoracionInventario.columnOption(
                                                "precioTotal",
                                                "format",
                                                currency_format(),
                                              );
                                              dxDataGrid_valoracionInventario.option(
                                                "summary.totalItems[0].valueFormat",
                                                currency_format(),
                                              );
                                            }

                                            $("#tabpanel-container")
                                              .dxTabPanel("instance")
                                              .option("selectedIndex", 3);
                                          } else if (
                                            informeSel.idInforme === 4 &&
                                            selectedItems.length === 1
                                          ) {
                                            // Inventario final teórico
                                            inventariosSel = selectedItems;
                                            $.when(
                                              datasource_finalTeorico.load(),
                                              dataSource_lavanderias_todas.load(),
                                              datasource_entidades_todas.load(),
                                            ).then(
                                              function (itemsFinal, itemsLav) {
                                                finalTeorico_items =
                                                  itemsFinal[0];

                                                //#region Refresh de los grids de ambas pestañas
                                                if (
                                                  typeof $(
                                                    "#dxDataGrid_finalTeorico_prendas_gridPrendas",
                                                  )[0] !== "undefined"
                                                ) {
                                                  let dxDataGrid_finalTeorico_prendas_gridPrendas =
                                                    $(
                                                      "#dxDataGrid_finalTeorico_prendas_gridPrendas",
                                                    ).dxDataGrid("instance");
                                                  dxDataGrid_finalTeorico_prendas_gridPrendas.option(
                                                    "dataSource",
                                                    set_dataSource_prendas_gridPrendas(),
                                                  );
                                                  dxDataGrid_finalTeorico_prendas_gridPrendas.deselectAll();
                                                }
                                                if (
                                                  typeof $(
                                                    "#dxDataGrid_finalTeorico_entidades_gridEntidades",
                                                  )[0] !== "undefined"
                                                ) {
                                                  let dxDataGrid_finalTeorico_entidades_gridEntidades =
                                                    $(
                                                      "#dxDataGrid_finalTeorico_entidades_gridEntidades",
                                                    ).dxDataGrid("instance");
                                                  dxDataGrid_finalTeorico_entidades_gridEntidades.option(
                                                    "dataSource",
                                                    set_dataSource_entidades_gridEntidades(),
                                                  );
                                                  dxDataGrid_finalTeorico_entidades_gridEntidades.deselectAll();
                                                }

                                                setTimeout(() => {
                                                  if (
                                                    typeof $(
                                                      "#dxDateBox_fechaHasta",
                                                    )[0] !== "undefined"
                                                  ) {
                                                    let dxDateBox_fechaHasta =
                                                      $(
                                                        "#dxDateBox_fechaHasta",
                                                      ).dxDateBox("instance");
                                                    dxDateBox_fechaHasta.option(
                                                      "value",
                                                      new Date(),
                                                    );
                                                    dxDateBox_fechaHasta.option(
                                                      "min",
                                                      addDays(
                                                        inventariosSel[0].fecha,
                                                        1,
                                                      ),
                                                    );
                                                  }
                                                }, 0);

                                                //#endregion

                                                //#region Título Grid en función de si hay 1 o 2 inventarios
                                                let lavs = query(
                                                  finalTeorico_items,
                                                )
                                                  .filter([
                                                    "idLavanderia",
                                                    "<>",
                                                    null,
                                                  ])
                                                  .groupBy(function (dataItem) {
                                                    return [
                                                      dataItem.idLavanderia,
                                                    ];
                                                  })
                                                  .toArray();

                                                if (lavs.length > 1) {
                                                  let lavanderias_Text =
                                                    "Lavanderías: ".toUpperCase();
                                                  $.each(
                                                    lavs,
                                                    function (index, item) {
                                                      if (index !== 0)
                                                        lavanderias_Text +=
                                                          ", ";
                                                      lavanderias_Text +=
                                                        getLavName(item.key[0]);
                                                    },
                                                  );

                                                  let icon = $(
                                                    "<div />",
                                                  ).addClass(
                                                    "icon_Comment pointer pl-3 d-inline",
                                                  );
                                                  tooltipControl_creacion(
                                                    icon,
                                                    lavanderias_Text,
                                                  );

                                                  setTimeout(() => {
                                                    $("#denoInv_finalTeorico")
                                                      .text(
                                                        inventariosSel[0]
                                                          .denominacion +
                                                          " - " +
                                                          formatDate(
                                                            inventariosSel[0]
                                                              .fecha,
                                                          ),
                                                      )
                                                      .append(icon);
                                                  }, 0);
                                                } else {
                                                  $(
                                                    "#denoInv_finalTeorico",
                                                  ).text(
                                                    inventariosSel[0]
                                                      .denominacion +
                                                      " - " +
                                                      formatDate(
                                                        inventariosSel[0].fecha,
                                                      ),
                                                  );
                                                }
                                                //#endregion
                                                $("#tabpanel-container")
                                                  .dxTabPanel("instance")
                                                  .option("selectedIndex", 4);
                                              },
                                            );
                                          }
                                        },
                                        itemTemplate: function (data, index) {
                                          var result = $("<div>");
                                          $("<div>")
                                            .text(
                                              formatDate(data.fecha) +
                                                " - " +
                                                data.denominacion,
                                            )
                                            .appendTo(result);

                                          return result;
                                        },
                                      });
                                    },
                                  },
                                ],
                              });
                            },
                          },
                        ],
                      });
                    },
                  },
                  {
                    title: getTrad("analisiEntreInventarios"),
                    template: function () {
                      return $("<div />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            baseSize: "47px",
                            template: function (data, index, element) {
                              element.css("border-bottom", "1px solid #DDD"); // Para emular separador toolbar grid
                              return $("<div />").dxToolbar({
                                items: [
                                  {
                                    location: "before",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      text: getTrad("atras"),
                                      icon: "back",
                                      onClick: function () {
                                        reiniciar_filtros();
                                        analisisInv_idPrendaSel = -1;
                                        $(
                                          "#dxRadioGroup_tipoTabla_analisisEntreInventarios",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value", 1);
                                        $("#tabpanel-container")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", 0);
                                      },
                                    },
                                  },
                                  {
                                    location: "before",
                                    widget: "dxRadioGroup",
                                    showText: "always",
                                    options: {
                                      elementAttr: {
                                        id: "dxRadioGroup_tipoTabla_analisisEntreInventarios",
                                        class: "dxRadioGroup_opciones",
                                      },
                                      dataSource: enum_finalTeorico_tipoTabla,
                                      valueExpr: "idTipoTabla",
                                      displayExpr: "denominacion",
                                      value: 1,
                                      layout: "horizontal",
                                      height: "36px",
                                      width: "290px",
                                      onContentReady: function (e) {
                                        $(e.element).css("margin-left", 10);
                                        let buttons = $(
                                          e.element.children()[1],
                                        ).children();
                                        $.each(
                                          buttons,
                                          function (index, button) {
                                            $(button).css({
                                              "min-width": 80,
                                              "text-align": "center",
                                              padding: "6px 20px 6px 20px",
                                              "margin-top": 0,
                                              "margin-bottom": 0,
                                              "font-size": "0.9rem",
                                              border: "1px solid #ddd",
                                            });
                                          },
                                        );
                                      },
                                      onValueChanged: function (e) {
                                        let tabIndex = e.value === 1 ? 0 : 1;
                                        $(
                                          "#dxTabPanel_analisisEntreInventarios",
                                        )
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", tabIndex);
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "always",
                                    locateInMenu: "auto",
                                    options: {
                                      width: "100%",
                                      icon: "exportxlsx",
                                      text: getTrad("exportar"),
                                      type: "normal",
                                      onClick: function () {
                                        let buttonIndex = $(
                                          "#dxRadioGroup_tipoTabla_analisisEntreInventarios",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value");
                                        let workbook = new Workbook();
                                        let worksheet =
                                          workbook.addWorksheet("Hoja1");

                                        let dataGrid, nombreArchivo;
                                        if (buttonIndex === 1) {
                                          // Prendas
                                          dataGrid = $(
                                            "#dxDataGrid_analisisInventarios_prendas",
                                          ).dxDataGrid("instance");
                                          nombreArchivo =
                                            "Análisis_inventarios_prendas.xlsx";

                                          exportDataGrid({
                                            worksheet,
                                            component: dataGrid,
                                            topLeftCell: { row: 4, column: 2 },
                                          }).then(function () {
                                            workbook.xlsx
                                              .writeBuffer()
                                              .then(function (buffer) {
                                                saveAs(
                                                  new Blob([buffer], {
                                                    type: "application/octet-stream",
                                                  }),
                                                  nombreArchivo,
                                                );
                                              });
                                          });
                                        }
                                        if (buttonIndex === 2) {
                                          // Entidades
                                          dataGrid = $(
                                            "#dxDataGrid_analisisInventarios_entidades_gridPrendas",
                                          ).dxDataGrid("instance");
                                          nombreArchivo =
                                            "Análisis_inventarios_entidades.xlsx";

                                          let dataGrid2 = $(
                                            "#dxDataGrid_analisisInventarios_entidades_gridEntidades",
                                          ).dxDataGrid("instance");

                                          worksheet.getRow(2).getCell(2).value =
                                            getTrad("prendas");
                                          worksheet.getRow(2).getCell(2).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          let denoPrendaSel =
                                            dataGrid.option("selectedRowKeys")
                                              .length > 0
                                              ? " - " +
                                                dataGrid.option(
                                                  "selectedRowKeys",
                                                )[0].denominacion
                                              : "";

                                          worksheet.getRow(2).getCell(8).value =
                                            getTrad("entidades") +
                                            denoPrendaSel;
                                          worksheet.getRow(2).getCell(8).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          exportDataGrid({
                                            worksheet,
                                            component: dataGrid,
                                            topLeftCell: { row: 4, column: 2 },
                                          })
                                            .then(function (cellRange) {
                                              return exportDataGrid({
                                                worksheet,
                                                component: dataGrid2,
                                                topLeftCell: {
                                                  row: 4,
                                                  column:
                                                    cellRange.to.column + 3,
                                                },
                                              });
                                            })
                                            .then(function () {
                                              workbook.xlsx
                                                .writeBuffer()
                                                .then(function (buffer) {
                                                  saveAs(
                                                    new Blob([buffer], {
                                                      type: "application/octet-stream",
                                                    }),
                                                    nombreArchivo,
                                                  );
                                                });
                                            });
                                        }
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    locateInMenu: "auto",
                                    options: {
                                      text: getTrad("limpiarFiltro"),
                                      icon: " icon_EliminarFiltros",
                                      onInitialized: function (e) {
                                        tooltipControl_creacion(
                                          e.element,
                                          e.component.option("text"),
                                        );
                                      },
                                      onClick: function () {
                                        let buttonIndex = $(
                                          "#dxRadioGroup_tipoTabla_analisisEntreInventarios",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value");

                                        if (buttonIndex === 1)
                                          // Prendas
                                          $(
                                            "#dxDataGrid_analisisInventarios_prendas",
                                          )
                                            .dxDataGrid("instance")
                                            .clearFilter();
                                        if (buttonIndex === 2)
                                          // Entidades
                                          $(
                                            "#dxDataGrid_analisisInventarios_entidades_gridPrendas",
                                          )
                                            .dxDataGrid("instance")
                                            .clearFilter();

                                        notify({
                                          message: getTrad(
                                            "aviso_C_FiltroRestaurado",
                                          ),
                                          type: "success",
                                          displayTime: "1500",
                                          closeOnClick: true,
                                        });
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onInitialized: function (e) {
                                        tooltipControl_creacion(
                                          e.element,
                                          e.component.option("text"),
                                        );
                                      },
                                      onClick: function () {
                                        let buttonIndex = $(
                                          "#dxRadioGroup_tipoTabla_analisisEntreInventarios",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value");

                                        if (buttonIndex === 1)
                                          // Prendas
                                          $(
                                            "#dxDataGrid_analisisInventarios_prendas",
                                          )
                                            .dxDataGrid("instance")
                                            .refresh();
                                        if (buttonIndex === 2)
                                          // Entidades
                                          $(
                                            "#dxDataGrid_analisisInventarios_entidades_gridPrendas",
                                          )
                                            .dxDataGrid("instance")
                                            .refresh();
                                      },
                                    },
                                  },
                                ],
                              });
                            },
                          },
                          {
                            baseSize: "10px",
                          },
                          {
                            ratio: 1,
                            template: function () {
                              return $(
                                "<div id='dxTabPanel_analisisEntreInventarios' />",
                              )
                                .addClass("no-header")
                                .dxTabPanel({
                                  height: "100%",
                                  width: "100%",
                                  scrollByContent: true,
                                  scrollingEnabled: true,
                                  animationEnabled: false,
                                  swipeEnabled: false,
                                  items: [
                                    {
                                      title: getTrad("prendas"),
                                      template: function () {
                                        return $(
                                          "<div id='dxDataGrid_analisisInventarios_prendas'>",
                                        ).dxDataGrid({
                                          //Datos
                                          dataSource:
                                            datasource_analisiInventario,
                                          remoteOperations: true,
                                          //Propiedades
                                          columnsAutoWidth: true,
                                          height: "100%",
                                          scrolling: { mode: "infinite" },
                                          headerFilter: {
                                            visible: true,
                                          },
                                          filterRow: {
                                            visible: true,
                                            applyFilter: "auto",
                                          },
                                          columns: [
                                            {
                                              dataField: "codigoPrenda",
                                              caption: getTrad("codigo"),
                                              sortOrder: "asc",
                                              width: "8%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowEditing: false,
                                              editorOptions: {
                                                showClearButton: true,
                                              },
                                            },
                                            {
                                              dataField: "denominacion",
                                              caption: getTrad("denominacion"),
                                              allowEditing: false,
                                              allowHeaderFiltering: false,
                                              editorOptions: {
                                                showClearButton: true,
                                              },
                                            },
                                            {
                                              caption: getTrad("totalAnterior"),
                                              alignment: "center",
                                              columns: [
                                                {
                                                  caption: formatDate(
                                                    inventariosSel[1].fecha,
                                                  ),
                                                  dataField: "totalInvAnterior",
                                                  width: "10%",
                                                  alignment: "center",
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  customizeText: function (
                                                    data,
                                                  ) {
                                                    return formatNumber(
                                                      data.value,
                                                    );
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "entradas",
                                              caption: getTrad("entradas"),
                                              width: "10%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              customizeText: function (data) {
                                                return formatNumber(data.value);
                                              },
                                            },
                                            {
                                              dataField: "salidas",
                                              caption: getTrad("salidas"),
                                              width: "10%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              customizeText: function (data) {
                                                return formatNumber(data.value);
                                              },
                                            },
                                            {
                                              dataField: "totalInvTeorico",
                                              caption: getTrad("totalTeorico"),
                                              width: "10%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              customizeText: function (data) {
                                                return formatNumber(data.value);
                                              },
                                            },
                                            {
                                              caption: getTrad("totalActual"),
                                              alignment: "center",
                                              columns: [
                                                {
                                                  caption: formatDate(
                                                    inventariosSel[0].fecha,
                                                  ),
                                                  dataField: "totalInvActual",
                                                  width: "10%",
                                                  alignment: "center",
                                                  allowHeaderFiltering: false,
                                                  allowFiltering: false,
                                                  customizeText: function (
                                                    data,
                                                  ) {
                                                    return formatNumber(
                                                      data.value,
                                                    );
                                                  },
                                                },
                                              ],
                                            },
                                            {
                                              dataField: "perdidas",
                                              caption: getTrad("perdidas"),
                                              width: "8%",
                                              alignment: "center",
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                              customizeText: function (data) {
                                                return formatNumber(data.value);
                                              },
                                            },
                                            {
                                              dataField: "porcentajePerdidas",
                                              caption:
                                                getTrad("perdidas") + "(%)",
                                              width: "10%",
                                              alignment: "center",
                                              format: {
                                                type: "fixedPoint",
                                                precision: 2,
                                              },
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value / 100,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              allowHeaderFiltering: false,
                                              allowFiltering: false,
                                            },
                                          ],

                                          //Estilos
                                          showColumnLines: false,
                                          showRowLines: true,
                                          rowAlternationEnabled: true,
                                        });
                                      },
                                    },
                                    {
                                      title: getTrad("entidades"),
                                      template: function () {
                                        return $("<div />").dxBox({
                                          direction: "row",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              ratio: 3,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_analisisInventarios_entidades_gridPrendas'>",
                                                ).dxDataGrid({
                                                  //Datos
                                                  dataSource:
                                                    datasource_analisiInventario_entidades_gridPrendas,
                                                  remoteOperations: false,
                                                  //Propiedades
                                                  columnsAutoWidth: true,
                                                  height: "100%",
                                                  scrolling: {
                                                    mode: "infinite",
                                                  },
                                                  pageLoadMode: "scrollBottom",
                                                  keyExpr: "idPrenda",
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  selection: {
                                                    mode: "single",
                                                  },
                                                  hoverStateEnabled: true,
                                                  filterRow: {
                                                    visible: true,
                                                    applyFilter: "auto",
                                                  },
                                                  columns: [
                                                    {
                                                      dataField: "codigoPrenda",
                                                      caption:
                                                        getTrad("codigo"),
                                                      sortOrder: "asc",
                                                      width: "12%",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                      allowEditing: false,
                                                      editorOptions: {
                                                        showClearButton: true,
                                                      },
                                                    },
                                                    {
                                                      dataField: "denominacion",
                                                      caption:
                                                        getTrad("denominacion"),
                                                      allowEditing: false,
                                                      allowHeaderFiltering: false,
                                                      editorOptions: {
                                                        showClearButton: true,
                                                      },
                                                    },
                                                    {
                                                      caption:
                                                        getTrad(
                                                          "totalAnterior",
                                                        ),
                                                      alignment: "center",
                                                      columns: [
                                                        {
                                                          caption: formatDate(
                                                            inventariosSel[1]
                                                              .fecha,
                                                          ),
                                                          dataField:
                                                            "totalInvAnterior",
                                                          width: "11%",
                                                          alignment: "center",
                                                          allowHeaderFiltering: false,
                                                          allowFiltering: false,
                                                          customizeText:
                                                            function (data) {
                                                              return formatNumber(
                                                                data.value,
                                                              );
                                                            },
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("totalActual"),
                                                      alignment: "center",
                                                      columns: [
                                                        {
                                                          caption: formatDate(
                                                            inventariosSel[0]
                                                              .fecha,
                                                          ),
                                                          dataField:
                                                            "totalInvActual",
                                                          width: "11%",
                                                          alignment: "center",
                                                          allowHeaderFiltering: false,
                                                          allowFiltering: false,
                                                          customizeText:
                                                            function (data) {
                                                              return formatNumber(
                                                                data.value,
                                                              );
                                                            },
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("diferencia"),
                                                      width: 85,
                                                      alignment: "center",
                                                      calculateCellValue(
                                                        rowData,
                                                      ) {
                                                        return formatNumber(
                                                          rowData.totalInvActual -
                                                            rowData.totalInvAnterior,
                                                        );
                                                      },
                                                    },
                                                    {
                                                      caption: getTrad(
                                                        "porcentaje_diferencia",
                                                      ),
                                                      width: 100,
                                                      alignment: "center",
                                                      calculateCellValue(
                                                        rowData,
                                                      ) {
                                                        if (
                                                          rowData.totalInvAnterior ===
                                                          0
                                                        )
                                                          return "-";
                                                        let diferencia =
                                                          rowData.totalInvActual -
                                                          rowData.totalInvAnterior;
                                                        return formatNumber(
                                                          diferencia /
                                                            rowData.totalInvAnterior,
                                                          2,
                                                          "percent",
                                                        );
                                                      },
                                                    },
                                                  ],
                                                  //Eventos
                                                  onSelectionChanged: function (
                                                    e,
                                                  ) {
                                                    analisisInv_idPrendaSel =
                                                      e.selectedRowKeys.length >
                                                      0
                                                        ? e.selectedRowKeys[0]
                                                            .idPrenda
                                                        : -1;
                                                    datasource_analisiInventario_entidades.reload();
                                                  },
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                            {
                                              ratio: 0,
                                              baseSize: 20,
                                            },
                                            {
                                              ratio: 2,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_analisisInventarios_entidades_gridEntidades'>",
                                                ).dxDataGrid({
                                                  //Datos
                                                  dataSource:
                                                    datasource_analisiInventario_entidades,
                                                  remoteOperations: true,
                                                  //Propiedades
                                                  columnsAutoWidth: true,
                                                  height: "100%",
                                                  scrolling: {
                                                    mode: "infinite",
                                                  },
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  filterRow: {
                                                    visible: true,
                                                    applyFilter: "auto",
                                                  },
                                                  columns: [
                                                    {
                                                      dataField: "idLavanderia",
                                                      visible: false,
                                                      sortOrder: "desc",
                                                      sortIndex: 0,
                                                    },
                                                    {
                                                      dataField: "denominacion",
                                                      caption:
                                                        getTrad("denominacion"),
                                                      sortIndex: 0,
                                                      sortOrder: "asc",
                                                      allowEditing: false,
                                                      allowHeaderFiltering: false,
                                                      editorOptions: {
                                                        showClearButton: true,
                                                      },
                                                    },
                                                    {
                                                      caption:
                                                        getTrad(
                                                          "totalAnterior",
                                                        ),
                                                      alignment: "center",
                                                      columns: [
                                                        {
                                                          caption: formatDate(
                                                            inventariosSel[1]
                                                              .fecha,
                                                          ),
                                                          dataField:
                                                            "totalInvAnterior",
                                                          width: "20%",
                                                          alignment: "center",
                                                          allowHeaderFiltering: false,
                                                          allowFiltering: false,
                                                          customizeText:
                                                            function (data) {
                                                              return formatNumber(
                                                                data.value,
                                                              );
                                                            },
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("totalActual"),
                                                      alignment: "center",
                                                      columns: [
                                                        {
                                                          caption: formatDate(
                                                            inventariosSel[0]
                                                              .fecha,
                                                          ),
                                                          dataField:
                                                            "totalInvActual",
                                                          width: "20%",
                                                          alignment: "center",
                                                          allowHeaderFiltering: false,
                                                          allowFiltering: false,
                                                          customizeText:
                                                            function (data) {
                                                              return formatNumber(
                                                                data.value,
                                                              );
                                                            },
                                                        },
                                                      ],
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("diferencia"),
                                                      width: 85,
                                                      alignment: "center",
                                                      calculateCellValue(
                                                        rowData,
                                                      ) {
                                                        return formatNumber(
                                                          rowData.totalInvActual -
                                                            rowData.totalInvAnterior,
                                                        );
                                                      },
                                                    },
                                                    {
                                                      caption: getTrad(
                                                        "porcentaje_diferencia",
                                                      ),
                                                      width: 100,
                                                      alignment: "center",
                                                      calculateCellValue(
                                                        rowData,
                                                      ) {
                                                        if (
                                                          rowData.totalInvAnterior ===
                                                          0
                                                        )
                                                          return "-";
                                                        let diferencia =
                                                          rowData.totalInvActual -
                                                          rowData.totalInvAnterior;
                                                        return formatNumber(
                                                          diferencia /
                                                            rowData.totalInvAnterior,
                                                          2,
                                                          "percent",
                                                        );
                                                      },
                                                    },
                                                  ],
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                  ],
                                });
                            },
                          },
                        ],
                      });
                    },
                  },
                  {
                    title: getTrad("reposicionesContraParStock"),
                    template: function () {
                      return $("<div />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            ratio: 1,
                            template: function () {
                              return $(
                                "<div id='dxDataGrid_reposicionesParStock'>",
                              ).dxDataGrid({
                                //Datos
                                dataSource: datasource_reposicionesParStock,
                                remoteOperations: {
                                  groupPaging: true,
                                  paging: true,
                                  filtering: true,
                                  sorting: true,
                                  grouping: true,
                                  summary: false,
                                },
                                //Propiedades
                                columnsAutoWidth: true,
                                height: "100%",
                                headerFilter: {
                                  visible: true,
                                },
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                },
                                columns: [
                                  {
                                    dataField: "codigoPrenda",
                                    caption: getTrad("codigo"),
                                    sortOrder: "asc",
                                    width: "8%",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowEditing: false,
                                    editorOptions: { showClearButton: true },
                                  },
                                  {
                                    dataField: "denominacion",
                                    caption: getTrad("denominacion"),
                                    allowEditing: false,
                                    allowHeaderFiltering: false,
                                    editorOptions: { showClearButton: true },
                                  },
                                  {
                                    dataField: "totalInv",
                                    caption: getTrad("totalInventario"),
                                    width: "10%",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "entradas",
                                    caption: getTrad("entradas"),
                                    width: "8%",
                                    alignment: "center",
                                    visible: isTeorico,
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "salidas",
                                    caption: getTrad("salidas"),
                                    width: "8%",
                                    alignment: "center",
                                    visible: isTeorico,
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "totalTeorico",
                                    caption: getTrad("totalTeorico"),
                                    width: "10%",
                                    alignment: "center",
                                    visible: isTeorico,
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "parStock",
                                    caption: getTrad("parStock"),
                                    width: "10%",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "reposicion",
                                    caption: getTrad("reposicion"),
                                    width: "10%",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    customizeText: function (data) {
                                      return formatNumber(data.value);
                                    },
                                  },
                                  {
                                    dataField: "precio",
                                    caption: getTrad("precio"),
                                    width: "8%",
                                    alignment: "center",
                                    format: currency_format(),
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                  },
                                  {
                                    dataField: "precioTotal",
                                    caption: getTrad("importeTotal"),
                                    width: "10%",
                                    alignment: "center",
                                    format: currency_format(),
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                  },
                                ],
                                summary: {
                                  totalItems: [
                                    {
                                      name: "precioTotal",
                                      column: getTrad("importeTotal"),
                                      summaryType: "sum",
                                      valueFormat: currency_format(),
                                      displayFormat: "Total: {0}",
                                    },
                                  ],
                                },
                                //Eventos
                                onToolbarPreparing: function (e) {
                                  e.toolbarOptions.items.unshift(
                                    {
                                      location: "before",
                                      widget: "dxButton",
                                      toolbar: "bottom",
                                      options: {
                                        text: getTrad("atras"),
                                        type: "back",
                                        onClick: function () {
                                          reiniciar_filtros();
                                          $("#tabpanel-container")
                                            .dxTabPanel("instance")
                                            .option("selectedIndex", 0);
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "always",
                                      locateInMenu: "auto",
                                      options: {
                                        width: "100%",
                                        icon: "exportxlsx",
                                        text: getTrad("exportar"),
                                        type: "normal",
                                        onClick: function () {
                                          let workbook = new Workbook();
                                          let worksheet =
                                            workbook.addWorksheet("Prendas");

                                          let dataGrid1 = $(
                                            "#dxDataGrid_reposicionesParStock",
                                          ).dxDataGrid("instance");

                                          exportDataGrid({
                                            worksheet,
                                            component: dataGrid1,
                                            topLeftCell: { row: 4, column: 2 },
                                          }).then(function () {
                                            workbook.xlsx
                                              .writeBuffer()
                                              .then(function (buffer) {
                                                saveAs(
                                                  new Blob([buffer], {
                                                    type: "application/octet-stream",
                                                  }),
                                                  "Reposiciones_parStock.xlsx",
                                                );
                                              });
                                          });
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "inMenu",
                                      locateInMenu: "auto",
                                      options: {
                                        text: getTrad("limpiarFiltro"),
                                        icon: " icon_EliminarFiltros",
                                        onInitialized: function (e) {
                                          tooltipControl_creacion(
                                            e.element,
                                            e.component.option("text"),
                                          );
                                        },
                                        onClick: function () {
                                          e.component.clearFilter();
                                          notify({
                                            message: getTrad(
                                              "aviso_C_FiltroRestaurado",
                                            ),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                          });
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "inMenu",
                                      options: {
                                        icon: "refresh",
                                        text: getTrad("actualizar"),
                                        onInitialized: function (e) {
                                          tooltipControl_creacion(
                                            e.element,
                                            e.component.option("text"),
                                          );
                                        },
                                        onClick: function () {
                                          e.component.refresh(true);
                                        },
                                      },
                                    },
                                  );
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              });
                            },
                          },
                        ],
                      });
                    },
                  },
                  {
                    title: getTrad("valoracionInventario"),
                    template: function () {
                      return $("<div />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            ratio: 1,
                            template: function () {
                              return $(
                                "<div id='dxDataGrid_valoracionInventario'>",
                              ).dxDataGrid({
                                //Datos
                                dataSource: datasource_valoracionInventarios,
                                remoteOperations: {
                                  groupPaging: true,
                                  paging: true,
                                  filtering: true,
                                  sorting: true,
                                  grouping: true,
                                  summary: false,
                                },
                                //Propiedades
                                columnsAutoWidth: true,
                                height: "100%",
                                paging: { enabled: false },
                                headerFilter: {
                                  visible: true,
                                },
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                },
                                export: { fileName: "Valoración_Inventario" },
                                columnChooser: {
                                  enabled: true,
                                },
                                selection: {
                                  mode: "multiple",
                                },
                                columns: [
                                  {
                                    name: "selection",
                                    caption: " ",
                                    width: "30px",
                                    alignment: "center",
                                    cssClass: "p-0",
                                    allowHiding: false,
                                    allowReordering: false,
                                    cellTemplate: function (
                                      cellElement,
                                      cellInfo,
                                    ) {
                                      let isAgrupado =
                                        cellInfo.key.includes(",");
                                      if (isAgrupado) {
                                        cellElement.append(
                                          $("<div />").addClass(
                                            "container_spanCentrado dx-icon-undo font-size-md pointer",
                                          ),
                                        );
                                        cellElement.append(
                                          $("<div />").dxPopover({
                                            target: cellElement,
                                            showEvent: "mouseenter",
                                            hideEvent: "mouseleave",
                                            position: "top",
                                            height: "auto",
                                            width: "auto",
                                            contentTemplate: function (
                                              contentElement,
                                            ) {
                                              contentElement.append(
                                                $("<div />").text(
                                                  "Deshacer agrupación",
                                                ),
                                              );
                                            },
                                          }),
                                        );

                                        cellElement.on("click", function () {
                                          dxMensajePregunta(
                                            "Se va a deshacer la agrupación para las prendas de esta fila, ¿Desea continuar?",
                                            [
                                              [
                                                getTrad("aceptar"),
                                                function () {
                                                  let selRowKeys =
                                                    cellInfo.key.split(",");
                                                  let agrupacionesTotales = [];
                                                  $.each(
                                                    prendasAgrupadas,
                                                    function (index, item) {
                                                      let Agrupacion = [];
                                                      $.each(
                                                        item,
                                                        function (
                                                          subIndex,
                                                          subItem,
                                                        ) {
                                                          let isInArray =
                                                            $.grep(
                                                              selRowKeys,
                                                              function (item) {
                                                                return (
                                                                  item ==
                                                                  subItem
                                                                );
                                                              },
                                                            ).length > 0;

                                                          if (!isInArray)
                                                            Agrupacion.push(
                                                              subItem,
                                                            );
                                                        },
                                                      );
                                                      agrupacionesTotales.push(
                                                        Agrupacion,
                                                      );
                                                    },
                                                  );

                                                  $(
                                                    "#dxDataGrid_valoracionInventario",
                                                  )
                                                    .dxDataGrid("instance")
                                                    .option(
                                                      "selectedRowKeys",
                                                      agrupacionesTotales.flat(
                                                        1,
                                                      ),
                                                    );
                                                  prendasAgrupadas =
                                                    agrupacionesTotales;
                                                  datasource_valoracionInventarios.reload();
                                                },
                                                "danger",
                                              ],
                                              [
                                                getTrad("cancelar"),
                                                function () {},
                                              ],
                                            ],
                                          );
                                        });
                                      }
                                    },
                                  },
                                  {
                                    dataField: "codigoPrenda",
                                    caption: getTrad("codigo"),
                                    width: 120,
                                    sortOrder: "asc",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowEditing: false,
                                    editorOptions: { showClearButton: true },
                                  },
                                  {
                                    dataField: "denominacion",
                                    caption: getTrad("denominacion"),
                                    minWidth: 350,
                                    allowEditing: false,
                                    allowHeaderFiltering: false,
                                    editorOptions: { showClearButton: true },
                                  },
                                  {
                                    caption: " ",
                                    width: "40px",
                                    alignment: "center",
                                    cssClass: "p-0",
                                    allowHiding: false,
                                    allowReordering: false,
                                    cellTemplate: function (
                                      cellElement,
                                      cellInfo,
                                    ) {
                                      cellElement.append(
                                        $("<div />")
                                          .addClass("container_spanCentrado")
                                          .append(
                                            svg_info
                                              .clone()
                                              .css("height", "25px")
                                              .css("width", "25px")
                                              .css("cursor", "pointer"),
                                          ),
                                      );

                                      cellElement.append(
                                        $("<div />").dxPopover({
                                          target: cellElement,
                                          showEvent: "mouseenter",
                                          hideEvent: "mouseleave",
                                          position: "top",
                                          width: 1000,
                                          contentTemplate: function (
                                            contentElement,
                                          ) {
                                            contentElement.append(
                                              $("<div />").dxDataGrid({
                                                dataSource: [cellInfo.data],
                                                columns: [
                                                  {
                                                    dataField:
                                                      "inventarioAnterior",
                                                    caption: "Inv. anterior",
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField:
                                                      "inventarioActual",
                                                    caption: "Inv. actual",
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "avg_inv",
                                                    caption: "Media inv.",
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "prodAño1",
                                                    caption:
                                                      inventariosSel[0].fechaCierre.getFullYear() -
                                                      3,
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "prodAño2",
                                                    caption:
                                                      inventariosSel[0].fechaCierre.getFullYear() -
                                                      2,
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "prodAño3",
                                                    caption:
                                                      inventariosSel[0].fechaCierre.getFullYear() -
                                                      1,
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "prodAño4",
                                                    caption:
                                                      inventariosSel[0].fechaCierre.getFullYear(),
                                                    alignment: "center",
                                                    width: 90,
                                                  },
                                                  {
                                                    dataField: "avg_prod",
                                                    caption: "Media prod.",
                                                    alignment: "center",
                                                    width: 100,
                                                  },
                                                  {
                                                    caption: "Lavados año",
                                                    format: {
                                                      style: "decimal",
                                                      maximumFractionDigits: 2,
                                                    },
                                                    alignment: "center",
                                                    width: 100,
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.avg_prod /
                                                          rowData.avg_inv
                                                        );
                                                      },
                                                  },
                                                  {
                                                    caption: "Lavados mes",
                                                    format: {
                                                      style: "decimal",
                                                      maximumFractionDigits: 2,
                                                    },
                                                    alignment: "center",
                                                    width: 100,
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.avg_prod /
                                                          rowData.avg_inv /
                                                          12
                                                        );
                                                      },
                                                  },
                                                  {
                                                    caption: "Amort. meses",
                                                    dataField: "mAmort",
                                                    width: 110,
                                                    format: {
                                                      style: "decimal",
                                                      maximumFractionDigits: 2,
                                                    },
                                                    alignment: "center",
                                                  },
                                                  {
                                                    caption: "Amort. días",
                                                    name: "diasAmort",
                                                    format: {
                                                      style: "decimal",
                                                      maximumFractionDigits: 2,
                                                    },
                                                    alignment: "center",
                                                    width: 100,
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.mAmort * 30
                                                        );
                                                      },
                                                  },
                                                ],
                                                onCellPrepared: function (e) {
                                                  if (
                                                    e.column.dataField ===
                                                      "avg_inv" ||
                                                    e.column.dataField ===
                                                      "avg_prod" ||
                                                    e.column.dataField ===
                                                      "mAmort" ||
                                                    e.column.name ===
                                                      "diasAmort"
                                                  ) {
                                                    e.cellElement.css(
                                                      "font-weight",
                                                      "bold",
                                                    );
                                                  }
                                                },
                                                showColumnLines: false,
                                              }),
                                            );
                                          },
                                        }),
                                      );
                                    },
                                  },
                                  {
                                    dataField: "total_unidades",
                                    caption: "Total unidades",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                  },
                                  {
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    width: 40,
                                    cellTemplate:
                                      cellTemplate_avisoCantidadMovInv,
                                  },
                                  {
                                    dataField: "ponderacion",
                                    caption: "Media días circulación",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 1,
                                    },
                                  },
                                  {
                                    dataField: "mProd",
                                    caption: "Meses productivos",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 2,
                                    },
                                    visible: false,
                                  },
                                  {
                                    dataField: "mAmort",
                                    caption: "Días amortización",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 2,
                                    },
                                    calculateCellValue: function (rowData) {
                                      return Math.ceil(rowData.mAmort * 30);
                                    },
                                  },
                                  {
                                    dataField: "percVidaUtil",
                                    caption: "Vida útil restante (%)",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: {
                                      style: "percent",
                                      maximumFractionDigits: 2,
                                    },
                                  },
                                  {
                                    dataField: "precioMedio",
                                    caption: "Precio medio",
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: currency_format(),
                                  },
                                  {
                                    dataField: "precioTotal",
                                    caption: getTrad("valorTotal"),
                                    alignment: "center",
                                    allowHeaderFiltering: false,
                                    allowFiltering: false,
                                    format: currency_format(),
                                  },
                                ],
                                summary: {
                                  totalItems: [
                                    {
                                      name: "codigoPrenda",
                                      column: "codigoPrenda",
                                      displayFormat: "TOTAL",
                                    },
                                    {
                                      name: "precioTotal",
                                      column: getTrad("valorTotal"),
                                      summaryType: "sum",
                                      valueFormat: currency_format(),
                                      displayFormat: "{0}",
                                    },
                                  ],
                                },
                                masterDetail: {
                                  enabled: true,
                                  template: function (container, options) {
                                    container.css("padding", "10px 40px");
                                    container.css("background-color", "white");

                                    container.append(
                                      $("<div>").dxDataGrid({
                                        //Datos
                                        dataSource: options.data.desglose,
                                        //Propiedades
                                        elementAttr: {
                                          class: "dxDatagrid_MasterDetail",
                                        },
                                        columnsAutoWidth: true,
                                        height: "250px",
                                        width: "900px",
                                        paging: { enabled: false },
                                        headerFilter: {
                                          visible: true,
                                        },
                                        filterRow: {
                                          visible: false,
                                          applyFilter: "auto",
                                        },
                                        columns: [
                                          {
                                            dataField: "fecha",
                                            caption: getTrad("fecha"),
                                            dataType: "date",
                                            format: "dd/MM/yyyy",
                                            width: "150px",
                                            alignment: "center",
                                            sortOrder: "asc",
                                            allowSearch: false,
                                            allowHeaderFiltering: false,
                                          },
                                          {
                                            dataField: "totalMov",
                                            caption: "Cantidad Mov.",
                                            alignment: "center",
                                            dataType: "number",
                                            format: {
                                              style: "decimal",
                                              maximumFractionDigits: 0,
                                            },
                                            allowHeaderFiltering: false,
                                          },
                                          {
                                            dataField: "unidades",
                                            caption: "Unidades",
                                            alignment: "center",
                                            dataType: "number",
                                            format: {
                                              style: "decimal",
                                              maximumFractionDigits: 0,
                                            },
                                            allowHeaderFiltering: true,
                                            filterValues: [
                                              ["unidades", ">", 0],
                                            ],
                                            headerFilter: {
                                              dataSource: [
                                                {
                                                  text: getTrad(
                                                    "todas",
                                                  ).toUpperCase(),
                                                  value: ["unidades", ">=", 0],
                                                },
                                                {
                                                  text: "CON UNIDADES",
                                                  value: ["unidades", ">", 0],
                                                },
                                              ],
                                            },
                                          },
                                          {
                                            dataField: "numDays",
                                            caption: "Días circulación",
                                            alignment: "center",
                                            dataType: "number",
                                            format: {
                                              style: "decimal",
                                              maximumFractionDigits: 0,
                                            },
                                            allowHeaderFiltering: false,
                                          },
                                          {
                                            dataField: "precio",
                                            caption: "Precio",
                                            alignment: "center",
                                            allowHeaderFiltering: false,
                                            allowFiltering: false,
                                            dataType: "number",
                                            format: currency_format(),
                                          },
                                          {
                                            dataField: "precioTotal",
                                            caption: getTrad("valorTotal"),
                                            alignment: "center",
                                            allowHeaderFiltering: false,
                                            allowFiltering: false,
                                            dataType: "number",
                                            format: currency_format(),
                                          },
                                          {
                                            dataField: "isAmortizada",
                                            dataType: "boolean",
                                            caption: "Amortizada",
                                            alignment: "center",
                                            allowHeaderFiltering: false,
                                            allowFiltering: false,
                                          },
                                        ],
                                        onCellPrepared: function (e) {
                                          if (e.rowType === "data") {
                                            if (e.data.isAmortizada) {
                                              e.cellElement.css(
                                                "background-color",
                                                "#e5fde5",
                                              );
                                              e.cellElement.css(
                                                "color",
                                                "green !important",
                                              );
                                            }
                                          }
                                        },
                                        //Estilos
                                        showColumnLines: false,
                                        showRowLines: true,
                                        rowAlternationEnabled: true,
                                      }),
                                    );
                                  },
                                },
                                //Eventos
                                onSelectionChanged: function (e) {
                                  $("#dxButton_agruparRows")
                                    .dxButton("instance")
                                    .option(
                                      "disabled",
                                      e.selectedRowsData.length < 2,
                                    );
                                },
                                onToolbarPreparing: function (e) {
                                  e.toolbarOptions.items.unshift(
                                    {
                                      location: "before",
                                      widget: "dxButton",
                                      showText: "always",
                                      options: {
                                        text: getTrad("atras"),
                                        icon: "back",
                                        onClick: function () {
                                          reiniciar_filtros();
                                          $("#tabpanel-container")
                                            .dxTabPanel("instance")
                                            .option("selectedIndex", 0);
                                        },
                                      },
                                    },
                                    {
                                      location: "before",
                                      template: function () {
                                        var inv = inventariosSel[0];
                                        return $(
                                          "<div id='denoInv_valoracionInventario'/>",
                                        )
                                          .text(
                                            inv.denominacion +
                                              " - " +
                                              formatDate(inv.fecha),
                                          )
                                          .css("font-size", "25px")
                                          .css("font-weight", "lighter")
                                          .css("padding-left", "10px");
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "always",
                                      locateInMenu: "auto",
                                      options: {
                                        width: "100%",
                                        text: "Agrupar prendas",
                                        type: "normal",
                                        elementAttr: {
                                          id: "dxButton_agruparRows",
                                        },
                                        disabled: true,
                                        onClick: function () {
                                          let selRowKeys = $.map(
                                            e.component.option(
                                              "selectedRowKeys",
                                            ),
                                            function (item) {
                                              if (item.includes(","))
                                                return item.split(",");
                                              else return item;
                                            },
                                          );

                                          let agrupacionesTotales = [];
                                          $.each(
                                            prendasAgrupadas,
                                            function (index, item) {
                                              let Agrupacion = [];
                                              $.each(
                                                item,
                                                function (subIndex, subItem) {
                                                  let isInArray =
                                                    $.grep(
                                                      selRowKeys,
                                                      function (item) {
                                                        return item == subItem;
                                                      },
                                                    ).length > 0;

                                                  if (!isInArray)
                                                    Agrupacion.push(subItem);
                                                },
                                              );
                                              agrupacionesTotales.push(
                                                Agrupacion,
                                              );
                                            },
                                          );

                                          prendasAgrupadas =
                                            agrupacionesTotales;
                                          prendasAgrupadas.push(selRowKeys);

                                          datasource_valoracionInventarios
                                            .reload()
                                            .done(function () {
                                              e.component.option(
                                                "selectedRowKeys",
                                                [],
                                              );
                                            });
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "always",
                                      locateInMenu: "auto",
                                      options: {
                                        width: "100%",
                                        icon: "exportxlsx",
                                        text: getTrad("exportar"),
                                        type: "normal",
                                        onClick: function () {
                                          e.component.exportToExcel();
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "inMenu",
                                      locateInMenu: "auto",
                                      options: {
                                        text: getTrad("limpiarFiltro"),
                                        icon: " icon_EliminarFiltros",
                                        onInitialized: function (e) {
                                          tooltipControl_creacion(
                                            e.element,
                                            e.component.option("text"),
                                          );
                                        },
                                        onClick: function () {
                                          e.component.clearFilter();
                                          notify({
                                            message: getTrad(
                                              "aviso_C_FiltroRestaurado",
                                            ),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                          });
                                        },
                                      },
                                    },
                                    {
                                      location: "after",
                                      widget: "dxButton",
                                      showText: "inMenu",
                                      options: {
                                        icon: "refresh",
                                        text: getTrad("actualizar"),
                                        onInitialized: function (e) {
                                          tooltipControl_creacion(
                                            e.element,
                                            e.component.option("text"),
                                          );
                                        },
                                        onClick: function () {
                                          e.component.refresh(true);
                                        },
                                      },
                                    },
                                  );
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              });
                            },
                          },
                        ],
                      });
                    },
                  },
                  {
                    title: getTrad("inventarioFinalTeorico"),
                    template: function () {
                      return $("<div />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            baseSize: "55px",
                            template: function (data, index, element) {
                              element.css("border-bottom", "1px solid #DDD"); // Para emular separador toolbar grid
                              return $("<div />").dxToolbar({
                                items: [
                                  {
                                    location: "before",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      text: getTrad("atras"),
                                      icon: "back",
                                      onClick: function () {
                                        reiniciar_filtros();
                                        $(
                                          "#dxDataGrid_finalTeorico_prendas_gridEntidades",
                                        )
                                          .dxDataGrid("instance")
                                          .option("dataSource", []);
                                        $("#dxRadioGroup_tipoTabla")
                                          .dxRadioGroup("instance")
                                          .option("value", 1);
                                        $("#tabpanel-container")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", 0);
                                      },
                                    },
                                  },
                                  {
                                    location: "before",
                                    widget: "dxRadioGroup",
                                    showText: "always",
                                    options: {
                                      elementAttr: {
                                        id: "dxRadioGroup_tipoTabla",
                                        class: "dxRadioGroup_opciones",
                                      },
                                      dataSource: enum_finalTeorico_tipoTabla,
                                      valueExpr: "idTipoTabla",
                                      displayExpr: "denominacion",
                                      value: 1,
                                      layout: "horizontal",
                                      height: "36px",
                                      width: "290px",
                                      onContentReady: function (e) {
                                        $(e.element).css("margin-left", 10);
                                        let buttons = $(
                                          e.element.children()[1],
                                        ).children();
                                        $.each(
                                          buttons,
                                          function (index, button) {
                                            $(button).css({
                                              "min-width": 80,
                                              "text-align": "center",
                                              padding: "6px 20px 6px 20px",
                                              "margin-top": 0,
                                              "margin-bottom": 0,
                                              "font-size": "0.9rem",
                                              border: "1px solid #ddd",
                                            });
                                          },
                                        );
                                      },
                                      onValueChanged: function (e) {
                                        let tabIndex = e.value === 1 ? 0 : 1;
                                        $("#dxTabPanel_invFinalTeorico")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", tabIndex);
                                      },
                                    },
                                  },
                                  {
                                    location: "before",
                                    template: function () {
                                      return $("<div />")
                                        .text(getTrad("fechaLimite"))
                                        .addClass("font-size-xxxs")
                                        .css("font-weight", "bold")
                                        .css("padding-left", "10px");
                                    },
                                  },
                                  {
                                    location: "before",
                                    widget: "dxDateBox",
                                    options: {
                                      elementAttr: {
                                        id: "dxDateBox_fechaHasta",
                                      },
                                      type: "date",
                                      pickerType: "calendar",
                                      width: "150px",
                                      displayFormat: "dd/MM/yyyy",
                                      value: new Date(),
                                      onValueChanged: function (e) {
                                        actualizarDatos_FinalTeorico();
                                      },
                                    },
                                  },
                                  {
                                    location: "center",
                                    template: function () {
                                      var invSel = inventariosSel[0];
                                      return $(
                                        "<div id='denoInv_finalTeorico'/>",
                                      )
                                        .text(
                                          invSel.denominacion +
                                            " - " +
                                            formatDate(invSel.fecha),
                                        )
                                        .css("font-size", "25px")
                                        .css("font-weight", "lighter")
                                        .css("padding-left", "10px");
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "always",
                                    locateInMenu: "auto",
                                    options: {
                                      width: "100%",
                                      icon: "exportxlsx",
                                      text: getTrad("exportar"),
                                      type: "normal",
                                      onClick: function () {
                                        let buttonIndex = $(
                                          "#dxRadioGroup_tipoTabla",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value");

                                        let workbook = new Workbook();
                                        let worksheet =
                                          workbook.addWorksheet("Prendas");

                                        let dataGrid1, dataGrid2, nombreArchivo;
                                        if (buttonIndex === 1) {
                                          // Prendas
                                          dataGrid1 = $(
                                            "#dxDataGrid_finalTeorico_prendas_gridPrendas",
                                          ).dxDataGrid("instance");
                                          dataGrid2 = $(
                                            "#dxDataGrid_finalTeorico_prendas_gridEntidades",
                                          ).dxDataGrid("instance");

                                          worksheet.getRow(2).getCell(2).value =
                                            getTrad("prendas");
                                          worksheet.getRow(2).getCell(2).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          let denoPrendaSel =
                                            dataGrid1.option("selectedRowKeys")
                                              .length > 0
                                              ? " - " +
                                                dataGrid1.option(
                                                  "selectedRowKeys",
                                                )[0].denominacion
                                              : "";

                                          worksheet
                                            .getRow(2)
                                            .getCell(11).value =
                                            getTrad("entidades") +
                                            denoPrendaSel;
                                          worksheet.getRow(2).getCell(11).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          nombreArchivo =
                                            "Inventario_teórico_prendas.xlsx";
                                        }
                                        if (buttonIndex === 2) {
                                          // Entidades
                                          dataGrid1 = $(
                                            "#dxDataGrid_finalTeorico_entidades_gridEntidades",
                                          ).dxDataGrid("instance");
                                          dataGrid2 = $(
                                            "#dxDataGrid_finalTeorico_entidades_gridPrendas",
                                          ).dxDataGrid("instance");

                                          let denoEntiSel;

                                          if (
                                            dataGrid1.option("selectedRowKeys")
                                              .length > 0
                                          ) {
                                            // Row seleccionada
                                            if (
                                              dataGrid1.option(
                                                "selectedRowKeys",
                                              )[0].idEntidad != null
                                            ) {
                                              // Entidad seleccionada
                                              let entidad = $.grep(
                                                datasource_entidades_todas.items(),
                                                function (item) {
                                                  return (
                                                    item.idEntidad ===
                                                    dataGrid1.option(
                                                      "selectedRowKeys",
                                                    )[0].idEntidad
                                                  );
                                                },
                                              );
                                              denoEntiSel =
                                                " - " + entidad[0].denominacion;
                                            } else if (
                                              dataGrid1.option(
                                                "selectedRowKeys",
                                              )[0].denoLavs.length > 1
                                            )
                                              // Lavandería seleccioanda (+ de 1 lav)
                                              denoEntiSel =
                                                " - " +
                                                dataGrid1
                                                  .option("selectedRowKeys")[0]
                                                  .denoLavs.join(", ");
                                            // Lavandería seleccionada (sólo 1 lav)
                                            else
                                              denoEntiSel =
                                                " - " + getTrad("lavanderia");
                                          } else denoEntiSel = "";

                                          worksheet.getRow(2).getCell(2).value =
                                            getTrad("entidades");
                                          worksheet.getRow(2).getCell(2).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          worksheet.getRow(2).getCell(9).value =
                                            getTrad("prendas") + denoEntiSel;
                                          worksheet.getRow(2).getCell(9).font =
                                            {
                                              bold: true,
                                              size: 16,
                                              underline: "double",
                                            };

                                          nombreArchivo =
                                            "Inventario_teórico_entidades.xlsx";
                                        }

                                        exportDataGrid({
                                          worksheet,
                                          component: dataGrid1,
                                          topLeftCell: { row: 4, column: 2 },
                                        })
                                          .then(function (cellRange) {
                                            return exportDataGrid({
                                              worksheet,
                                              component: dataGrid2,
                                              topLeftCell: {
                                                row: 4,
                                                column: cellRange.to.column + 3,
                                              },
                                            });
                                          })
                                          .then(function () {
                                            workbook.xlsx
                                              .writeBuffer()
                                              .then(function (buffer) {
                                                saveAs(
                                                  new Blob([buffer], {
                                                    type: "application/octet-stream",
                                                  }),
                                                  nombreArchivo,
                                                );
                                              });
                                          });
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    locateInMenu: "auto",
                                    options: {
                                      text: getTrad("limpiarFiltro"),
                                      icon: " icon_EliminarFiltros",
                                      onInitialized: function (e) {
                                        tooltipControl_creacion(
                                          e.element,
                                          e.component.option("text"),
                                        );
                                      },
                                      onClick: function () {
                                        let buttonIndex = $(
                                          "#dxRadioGroup_tipoTabla",
                                        )
                                          .dxRadioGroup("instance")
                                          .option("value");

                                        if (buttonIndex === 1)
                                          // Prendas
                                          $(
                                            "#dxDataGrid_finalTeorico_prendas_gridPrendas",
                                          )
                                            .dxDataGrid("instance")
                                            .clearFilter();
                                        if (buttonIndex === 2)
                                          // Entidades
                                          $(
                                            "#dxDataGrid_finalTeorico_entidades_gridEntidades",
                                          )
                                            .dxDataGrid("instance")
                                            .clearFilter();

                                        notify({
                                          message: getTrad(
                                            "aviso_C_FiltroRestaurado",
                                          ),
                                          type: "success",
                                          displayTime: "1500",
                                          closeOnClick: true,
                                        });
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onInitialized: function (e) {
                                        tooltipControl_creacion(
                                          e.element,
                                          e.component.option("text"),
                                        );
                                      },
                                      onClick: function () {
                                        actualizarDatos_FinalTeorico();
                                      },
                                    },
                                  },
                                ],
                              });
                            },
                          },
                          {
                            baseSize: "10px",
                          },
                          {
                            ratio: 1,
                            template: function () {
                              return $(
                                "<div id='dxTabPanel_invFinalTeorico' />",
                              )
                                .addClass("no-header")
                                .dxTabPanel({
                                  height: "100%",
                                  width: "100%",
                                  scrollByContent: true,
                                  scrollingEnabled: true,
                                  animationEnabled: false,
                                  swipeEnabled: false,
                                  items: [
                                    {
                                      title: getTrad("prendas"),
                                      template: function () {
                                        return $("<div />").dxBox({
                                          direction: "row",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              ratio: 2,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_finalTeorico_prendas_gridPrendas'>",
                                                ).dxDataGrid({
                                                  dataSource:
                                                    set_dataSource_prendas_gridPrendas(),
                                                  //Propiedades
                                                  columnsAutoWidth: true,
                                                  remoteOperations: false,
                                                  height: "100%",
                                                  width: "100%",
                                                  paging: {
                                                    pageSize: 50,
                                                  },
                                                  pager: {
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [
                                                      20, 50, 100,
                                                    ],
                                                    showInfo: true,
                                                  },
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  selection: {
                                                    mode: "single",
                                                  },
                                                  filterRow: {
                                                    visible: false,
                                                  },
                                                  hoverStateEnabled: true,
                                                  columns: [
                                                    {
                                                      dataField: "codigoPrenda",
                                                      caption:
                                                        getTrad("codigo"),
                                                      width: 100,
                                                      allowHeaderFiltering: false,
                                                      sortIndex: 0,
                                                      sortOrder: "asc",
                                                    },
                                                    {
                                                      dataField: "denominacion",
                                                      caption:
                                                        getTrad("denominacion"),
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      dataField: "totalInv",
                                                      caption:
                                                        getTrad(
                                                          "invInicialTotal",
                                                        ),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 125,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      dataField: "totalInvLav",
                                                      caption:
                                                        getTrad(
                                                          "invInicialLav",
                                                        ),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      area: "data",
                                                      dataType: "number",
                                                      alignment: "center",
                                                      width: 125,
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      dataField: "pedidos",
                                                      caption:
                                                        getTrad("pedidos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 125,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      dataField: "repartos",
                                                      caption:
                                                        getTrad("repartos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 125,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      name: "invFinal",
                                                      caption:
                                                        getTrad("invFinalLav"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 125,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          return (
                                                            e.totalInvLav +
                                                            e.pedidos -
                                                            e.repartos
                                                          );
                                                        },
                                                    },
                                                  ],
                                                  summary: {
                                                    totalItems: [
                                                      {
                                                        column: "codigoPrenda",
                                                        displayFormat:
                                                          getTrad(
                                                            "kgTotales",
                                                          ).toUpperCase(),
                                                      },
                                                      {
                                                        name: "totalInv",
                                                        showInColumn:
                                                          "totalInv",
                                                        summaryType: "custom",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        name: "totalInvLav",
                                                        showInColumn:
                                                          "totalInvLav",
                                                        summaryType: "custom",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        name: "pedidos",
                                                        showInColumn: "pedidos",
                                                        summaryType: "custom",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        name: "repartos",
                                                        showInColumn:
                                                          "repartos",
                                                        summaryType: "custom",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        name: "invFinal",
                                                        showInColumn:
                                                          "invFinal",
                                                        summaryType: "custom",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 2,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                    ],
                                                    calculateCustomSummary:
                                                      function (options) {
                                                        if (
                                                          options.summaryProcess ===
                                                          "start"
                                                        ) {
                                                          options.totalValue = 0;
                                                        }
                                                        if (
                                                          options.summaryProcess ===
                                                          "calculate"
                                                        ) {
                                                          if (options.value) {
                                                            if (
                                                              options.name !==
                                                              "invFinal"
                                                            )
                                                              options.totalValue +=
                                                                options.value[
                                                                  options.name
                                                                ] *
                                                                (options.value
                                                                  .peso *
                                                                  0.001);
                                                            else
                                                              options.totalValue +=
                                                                (options.value
                                                                  .totalInvLav +
                                                                  options.value
                                                                    .pedidos -
                                                                  options.value
                                                                    .repartos) *
                                                                (options.value
                                                                  .peso *
                                                                  0.001);
                                                          }
                                                        }
                                                      },
                                                  },
                                                  onSelectionChanged: function (
                                                    e,
                                                  ) {
                                                    teorico_idPrendaSel =
                                                      e.selectedRowKeys.length >
                                                      0
                                                        ? e.selectedRowKeys[0]
                                                            .idPrenda
                                                        : -1;
                                                    set_dataSource_prendas_gridEntidades();
                                                  },
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                            {
                                              ratio: 0,
                                              baseSize: 20,
                                            },
                                            {
                                              ratio: 1,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_finalTeorico_prendas_gridEntidades'>",
                                                ).dxDataGrid({
                                                  //Propiedades
                                                  columnsAutoWidth: true,
                                                  remoteOperations: false,
                                                  height: "100%",
                                                  width: "100%",
                                                  paging: {
                                                    pageSize: 50,
                                                  },
                                                  pager: {
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [
                                                      20, 50, 100,
                                                    ],
                                                    showInfo: true,
                                                  },
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  columns: [
                                                    {
                                                      dataField: "idEntidad",
                                                      caption:
                                                        getTrad("entidad"),
                                                      width: 150,
                                                      sortOrder: "asc",
                                                      alignment: "left",
                                                      lookup: {
                                                        dataSource:
                                                          datasource_entidades_todas.items(),
                                                        displayExpr:
                                                          "denominacion",
                                                        valueExpr: "idEntidad",
                                                      },
                                                      calculateSortValue:
                                                        function (data) {
                                                          var value =
                                                            this.calculateCellValue(
                                                              data,
                                                            );
                                                          return this.lookup.calculateCellValue(
                                                            value,
                                                          );
                                                        },
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("invInicial"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 100,
                                                      dataField: "totalInv",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("pedidos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 100,
                                                      dataField: "pedidos",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("repartos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 100,
                                                      dataField: "repartos",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("invFinal"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      name: "invFinal",
                                                      width: 50,
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          return (
                                                            e.totalInv +
                                                            e.repartos -
                                                            e.pedidos
                                                          );
                                                        },
                                                    },
                                                  ],
                                                  summary: {
                                                    totalItems: [
                                                      {
                                                        column: "idEntidad",
                                                        displayFormat:
                                                          getTrad(
                                                            "total",
                                                          ).toUpperCase(),
                                                      },
                                                      {
                                                        column: "totalInv",
                                                        summaryType: "sum",
                                                        displayFormat: "{0}",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                        },
                                                      },
                                                      {
                                                        column: "pedidos",
                                                        summaryType: "sum",
                                                        displayFormat: "{0}",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                        },
                                                      },
                                                      {
                                                        column: "repartos",
                                                        summaryType: "sum",
                                                        displayFormat: "{0}",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                        },
                                                      },
                                                      {
                                                        column: "invFinal",
                                                        summaryType: "sum",
                                                        displayFormat: "{0}",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                    {
                                      title: getTrad("entidades"),
                                      template: function () {
                                        return $("<div />").dxBox({
                                          direction: "row",
                                          align: "space-around",
                                          crossAlign: "stretch",
                                          height: "100%",
                                          items: [
                                            {
                                              ratio: 2,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_finalTeorico_entidades_gridEntidades'>",
                                                ).dxDataGrid({
                                                  //Propiedades
                                                  dataSource:
                                                    set_dataSource_entidades_gridEntidades(),
                                                  columnsAutoWidth: true,
                                                  remoteOperations: false,
                                                  height: "100%",
                                                  width: "100%",
                                                  paging: {
                                                    pageSize: 50,
                                                  },
                                                  pager: {
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [
                                                      20, 50, 100,
                                                    ],
                                                    showInfo: true,
                                                  },
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  selection: {
                                                    mode: "single",
                                                  },
                                                  hoverStateEnabled: true,
                                                  columns: [
                                                    {
                                                      dataField: "isEntidad",
                                                      visible: false,
                                                      sortIndex: 0,
                                                      sortOrder: "asc",
                                                    },
                                                    {
                                                      dataField: "denominacion",
                                                      sortIndex: 1,
                                                      sortOrder: "asc",
                                                      alignment: "left",
                                                      allowHeaderFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          if (
                                                            e.idEntidad === null
                                                          ) {
                                                            return getTrad(
                                                              "lavanderia",
                                                            ).toUpperCase();
                                                          } else if (
                                                            e.idEntidad !== null
                                                          ) {
                                                            let item = $.grep(
                                                              datasource_entidades_todas.items(),
                                                              function (item) {
                                                                return (
                                                                  item.idEntidad ===
                                                                  e.idEntidad
                                                                );
                                                              },
                                                            );
                                                            return item.length >
                                                              0
                                                              ? item[0]
                                                                  .denominacion
                                                              : "";
                                                          }
                                                        },
                                                      cellTemplate: (
                                                        cellElement,
                                                        cellInfo,
                                                      ) => {
                                                        let container =
                                                          $("<div/>");
                                                        container.append(
                                                          $("<div/>").text(
                                                            cellInfo.text,
                                                          ),
                                                        );

                                                        if (
                                                          cellInfo.data
                                                            .idEntidad ==
                                                            null &&
                                                          cellInfo.data.denoLavs
                                                            .length > 1
                                                        ) {
                                                          let icon = $(
                                                            "<div />",
                                                          )
                                                            .addClass(
                                                              "icon_Comment pointer pl-3 d-inline",
                                                            )
                                                            .css(
                                                              "padding-top",
                                                              "2px",
                                                            );
                                                          tooltipControl_creacion(
                                                            icon,
                                                            cellInfo.data.denoLavs.join(
                                                              ", ",
                                                            ),
                                                          );
                                                          container
                                                            .append(icon)
                                                            .addClass("d-flex");
                                                        }
                                                        cellElement.append(
                                                          container,
                                                        );
                                                      },
                                                    },
                                                    {
                                                      caption: getTrad(
                                                        "kgTotales_inventario",
                                                      ),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      },
                                                      width: 140,
                                                      dataField: "totalInv",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          return e.isEntidad
                                                            ? e.totalInv
                                                            : e.totalInvLav;
                                                        },
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("kgPedido"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      },
                                                      width: 140,
                                                      dataField: "pedidos",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad("kgRepartos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      },
                                                      width: 140,
                                                      dataField: "repartos",
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      caption:
                                                        getTrad(
                                                          "kgFinalesTeoricos",
                                                        ),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                      },
                                                      name: "invFinal",
                                                      width: 140,
                                                      alignment: "center",
                                                      allowHeaderFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          if (e.isEntidad)
                                                            return (
                                                              e.totalInv +
                                                              e.repartos -
                                                              e.pedidos
                                                            );
                                                          else
                                                            return (
                                                              e.totalInvLav +
                                                              e.pedidos -
                                                              e.repartos
                                                            );
                                                        },
                                                    },
                                                  ],
                                                  summary: {
                                                    totalItems: [
                                                      {
                                                        column: "denominacion",
                                                        displayFormat:
                                                          getTrad(
                                                            "kgTotales",
                                                          ).toUpperCase(),
                                                      },
                                                      {
                                                        column: "totalInv",
                                                        summaryType: "sum",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        column: "pedidos",
                                                        summaryType: "sum",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        column: "repartos",
                                                        summaryType: "sum",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                      {
                                                        column: "invFinal",
                                                        summaryType: "sum",
                                                        displayFormat:
                                                          "{0} Kg.",
                                                        valueFormat: {
                                                          style: "decimal",
                                                          minimumFractionDigits: 0,
                                                          maximumFractionDigits: 2,
                                                        },
                                                      },
                                                    ],
                                                  },
                                                  onSelectionChanged: function (
                                                    e,
                                                  ) {
                                                    teorico_idEntiSel =
                                                      e.selectedRowKeys.length >
                                                      0
                                                        ? e.selectedRowKeys[0]
                                                            .idEntidad
                                                        : -1;
                                                    set_dataSource_entidades_gridPrendas();
                                                  },
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                            {
                                              ratio: 0,
                                              baseSize: 20,
                                            },
                                            {
                                              ratio: 2,
                                              template: function () {
                                                return $(
                                                  "<div id='dxDataGrid_finalTeorico_entidades_gridPrendas'>",
                                                ).dxDataGrid({
                                                  //Propiedades
                                                  columnsAutoWidth: true,
                                                  remoteOperations: false,
                                                  height: "100%",
                                                  width: "100%",
                                                  paging: {
                                                    pageSize: 50,
                                                  },
                                                  pager: {
                                                    showPageSizeSelector: true,
                                                    allowedPageSizes: [
                                                      20, 50, 100,
                                                    ],
                                                    showInfo: true,
                                                  },
                                                  headerFilter: {
                                                    visible: true,
                                                  },
                                                  columns: [
                                                    {
                                                      dataField: "codigoPrenda",
                                                      caption:
                                                        getTrad("codigo"),
                                                      width: 80,
                                                      allowHeaderFiltering: false,
                                                      sortIndex: 0,
                                                      sortOrder: "asc",
                                                    },
                                                    {
                                                      dataField: "denominacion",
                                                      caption:
                                                        getTrad("denominacion"),
                                                      allowHeaderFiltering: false,
                                                    },
                                                    {
                                                      dataField: "totalInv",
                                                      caption:
                                                        getTrad("invInicial"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 85,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          return e.idEntidad !==
                                                            null
                                                            ? e.totalInv
                                                            : e.totalInvLav;
                                                        },
                                                    },
                                                    {
                                                      dataField: "pedidos",
                                                      caption:
                                                        getTrad("pedidos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 85,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      dataField: "repartos",
                                                      caption:
                                                        getTrad("repartos"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 85,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                    },
                                                    {
                                                      name: "invFinal",
                                                      caption:
                                                        getTrad("invFinalEnti"),
                                                      format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 0,
                                                      },
                                                      width: 120,
                                                      alignment: "center",
                                                      allowFiltering: false,
                                                      calculateCellValue:
                                                        function (e) {
                                                          return (
                                                            (e.idEntidad !==
                                                            null
                                                              ? e.totalInv
                                                              : e.totalInvLav) +
                                                            e.repartos -
                                                            e.pedidos
                                                          );
                                                        },
                                                    },
                                                  ],
                                                  //Estilos
                                                  showColumnLines: false,
                                                  showRowLines: true,
                                                  rowAlternationEnabled: true,
                                                });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                  ],
                                });
                            },
                          },
                        ],
                      });
                    },
                  },
                ],
                onSelectionChanged: function (e) {
                  $("#font-size").text(e.addedItems[0].title);

                  var compaSel = [];
                  if (typeof $("#dxList_compañia")[0] !== "undefined") {
                    compaSel = $("#dxList_compañia")
                      .dxList("instance")
                      .option("selectedItems");
                  }

                  var entidadSel = [];
                  if (typeof $("#dxList_entidad")[0] !== "undefined") {
                    entidadSel = $("#dxList_entidad")
                      .dxList("instance")
                      .option("selectedItems");
                  }

                  let selectedIndex = e.component.option("selectedIndex");
                  let denominacion =
                    compaSel.length > 0
                      ? compaSel[0].denominacion
                      : entidadSel.length > 0
                        ? entidadSel[0].denominacion
                        : "";
                  let compaEntiTitle = selectedIndex > 0 ? denominacion : "";

                  if ($("#dxButton_agruparRows").data("dxButton"))
                    $("#dxButton_agruparRows")
                      .dxButton("instance")
                      .option("disabled", true);

                  if (
                    typeof $("#dxDataGrid_valoracionInventario")[0] !==
                    "undefined"
                  )
                    $("#dxDataGrid_valoracionInventario")
                      .dxDataGrid("instance")
                      .deselectAll();

                  if (
                    e.addedItems.length > 0 &&
                    e.addedItems["0"].title == "Filtros"
                  ) {
                    $(".page-title-actions").css({ marginLeft: 30 });
                    $("#infoCompaEnti_container").css({ display: "none" });
                    prendasAgrupadas = [];
                  } else {
                    $(".page-title-actions").css({ marginLeft: 15 });
                    $("#infoCompaEnti_container").css({ display: "flex" });
                    $("#infoTipoInfInv")
                      .addClass("font-size-lg")
                      .text(informeSel.denominacion);
                    $("#infoCompaEnti")
                      .addClass("font-size-lg")
                      .text(compaEntiTitle);
                  }
                },
              }),
          );
        },
      },
    ],
  });

  function actualizarDatos_FinalTeorico() {
    COMPONENT.loadPanel_show();
    datasource_finalTeorico.reload().done(function (items) {
      finalTeorico_items = items;
      let buttonIndex = $("#dxRadioGroup_tipoTabla")
        .dxRadioGroup("instance")
        .option("value");

      if (buttonIndex === 1) {
        // Prendas
        let dxDataGrid_finalTeorico_prendas_gridPrendas = $(
          "#dxDataGrid_finalTeorico_prendas_gridPrendas",
        ).dxDataGrid("instance");
        dxDataGrid_finalTeorico_prendas_gridPrendas.option(
          "dataSource",
          set_dataSource_prendas_gridPrendas(),
        );
        dxDataGrid_finalTeorico_prendas_gridPrendas.deselectAll();
      }
      if (buttonIndex === 2) {
        // Entidades
        let dxDataGrid_finalTeorico_entidades_gridEntidades = $(
          "#dxDataGrid_finalTeorico_entidades_gridEntidades",
        ).dxDataGrid("instance");
        dxDataGrid_finalTeorico_entidades_gridEntidades.option(
          "dataSource",
          set_dataSource_entidades_gridEntidades(),
        );
        dxDataGrid_finalTeorico_entidades_gridEntidades.deselectAll();
      }
      COMPONENT.loadPanel_hide();
    });
  }

  //#region Cálculo DataSources Grids Inventario teórico
  function set_dataSource_prendas_gridPrendas() {
    let dataSource_teorico = get_dataSource_teorico_format();
    let dataSource_pedidos_repartos = query(dataSource_teorico)
      .filter([
        ["totalInv", ">", 0],
        "or",
        ["totalInvLav", ">", 0],
        "or",
        ["pedidos", ">", 0],
        "or",
        ["repartos", ">", 0],
      ]) // Si uno de estos campos es mayor a 0, ha habido movimiento en el inventario, ha de salir
      .groupBy(function (dataItem) {
        return [
          dataItem.codigoPrenda,
          dataItem.denominacion,
          dataItem.idPrenda,
          dataItem.peso,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("totalInvLav")
          .done(function (result) {
            resultItem = {
              codigoPrenda: dataItem.key[0],
              denominacion: dataItem.key[1],
              idPrenda: dataItem.key[2],
              peso: dataItem.key[3],
              totalInvLav: result,
            };
          });
        query(dataItem.items)
          .sum("totalInv")
          .done(function (result) {
            resultItem = {
              codigoPrenda: dataItem.key[0],
              denominacion: dataItem.key[1],
              idPrenda: dataItem.key[2],
              peso: dataItem.key[3],
              totalInvLav: resultItem.totalInvLav,
              totalInv: result + resultItem.totalInvLav,
            };
          });
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              codigoPrenda: dataItem.key[0],
              denominacion: dataItem.key[1],
              idPrenda: dataItem.key[2],
              peso: dataItem.key[3],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: result,
            };
          });
        query(dataItem.items)
          .sum("repartos")
          .done(function (result) {
            resultItem = {
              codigoPrenda: dataItem.key[0],
              denominacion: dataItem.key[1],
              idPrenda: dataItem.key[2],
              peso: dataItem.key[3],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: resultItem.pedidos,
              repartos: result,
            };
          });
        return resultItem;
      })
      .toArray();

    return dataSource_pedidos_repartos;
  }

  function set_dataSource_prendas_gridEntidades() {
    let dataSource_entidades = query(finalTeorico_items)
      .filter([
        ["idPrenda", "=", teorico_idPrendaSel],
        "and",
        ["idEntidad", "<>", null],
      ])
      .groupBy(function (dataItem) {
        return [dataItem.idEntidad];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("totalInv")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: result,
            };
          });
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: resultItem.totalInv,
              pedidos: result,
            };
          });
        query(dataItem.items)
          .sum("repartos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: resultItem.totalInv,
              pedidos: resultItem.pedidos,
              repartos: result,
            };
          });
        return resultItem;
      })
      .filter([
        ["totalInv", ">", 0],
        "or",
        ["totalInvLav", ">", 0],
        "or",
        ["pedidos", ">", 0],
        "or",
        ["repartos", ">", 0],
      ])
      .toArray();

    $("#dxDataGrid_finalTeorico_prendas_gridEntidades")
      .dxDataGrid("instance")
      .option("dataSource", dataSource_entidades);
  }

  function set_dataSource_entidades_gridEntidades() {
    let dataSource_grouped_peso = query(finalTeorico_items)
      .filter([
        ["totalInv", ">", 0],
        "or",
        ["totalInvLav", ">", 0],
        "or",
        ["pedidos", ">", 0],
        "or",
        ["repartos", ">", 0],
      ])
      .groupBy(function (dataItem) {
        return [dataItem.idEntidad, dataItem.peso];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("totalInv")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              peso: dataItem.key[1],
              totalInv: result * (dataItem.key[1] * 0.001),
            };
          });
        query(dataItem.items)
          .sum("totalInvLav")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              peso: dataItem.key[1],
              totalInv: resultItem.totalInv,
              totalInvLav: result * (dataItem.key[1] * 0.001),
            };
          });
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              peso: dataItem.key[1],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: result * (dataItem.key[1] * 0.001),
            };
          });
        query(dataItem.items)
          .sum("repartos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              peso: dataItem.key[1],
              isEntidad: dataItem.key[0] !== null,
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: resultItem.pedidos,
              repartos: result * (dataItem.key[1] * 0.001),
            };
          });
        return resultItem;
      })
      .toArray();

    let dataSource_grouped_entidades = query(dataSource_grouped_peso)
      .groupBy(function (dataItem) {
        return [dataItem.idEntidad];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("totalInv")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: result,
            };
          });
        query(dataItem.items)
          .sum("totalInvLav")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: resultItem.totalInv,
              totalInvLav: result,
            };
          });
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: result,
            };
          });
        query(dataItem.items)
          .sum("repartos")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              isEntidad: dataItem.key[0] !== null,
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: resultItem.pedidos,
              repartos: result,
            };
          });
        return resultItem;
      })
      .toArray();

    let lavs = query(finalTeorico_items)
      .filter(["idLavanderia", "<>", null])
      .groupBy(function (dataItem) {
        return [dataItem.idLavanderia];
      })
      .toArray();

    let denoLavs = [];
    $.each(lavs, function (index, item) {
      denoLavs.push(getLavName(item.key[0]));
    });

    $.each(dataSource_grouped_entidades, function (index, item) {
      item.denoLavs = denoLavs;
    });

    //#region Añadir sumatorio de pedidos de entidades a la lavanderia
    let dataSource_teorico = get_dataSource_teorico_format();
    let dataSource_pedidos_repartos = query(dataSource_teorico)
      .filter([
        ["totalInv", ">", 0],
        "or",
        ["totalInvLav", ">", 0],
        "or",
        ["pedidos", ">", 0],
        "or",
        ["repartos", ">", 0],
      ])
      .groupBy(function (dataItem) {
        return [dataItem.peso];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              pedidos: result * (dataItem.key[0] * 0.001),
            };
          });
        return resultItem;
      })
      .toArray();

    // Formatear obj con pedidos a integer pedidos totales.
    let pedidosLav = 0;
    $.each(dataSource_pedidos_repartos, function (index, item) {
      pedidosLav += item.pedidos;
    });

    //Asignar los pedidos a la lavanderia
    $.each(dataSource_grouped_entidades, function (index, item) {
      if (item.idEntidad == null) item.pedidos = pedidosLav;
    });
    //#endregion

    return dataSource_grouped_entidades;
  }

  function set_dataSource_entidades_gridPrendas() {
    let dataSource_filtrado = [];
    $.each(finalTeorico_items, function (index, item) {
      if (
        (teorico_idEntiSel != null && item.idEntidad === teorico_idEntiSel) ||
        (teorico_idEntiSel == null && item.idEntidad == null)
      )
        dataSource_filtrado.push(item);
    });

    let dataSource_entidades = query(dataSource_filtrado)
      .groupBy(function (dataItem) {
        return [
          dataItem.idPrenda,
          dataItem.codigoPrenda,
          dataItem.denominacion,
          dataItem.idEntidad,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("totalInv")
          .done(function (result) {
            resultItem = {
              idPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              idEntidad: dataItem.key[3],
              totalInv: result,
            };
          });
        query(dataItem.items)
          .sum("totalInvLav")
          .done(function (result) {
            resultItem = {
              idPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              idEntidad: dataItem.key[3],
              totalInv: resultItem.totalInv,
              totalInvLav: result,
            };
          });
        query(dataItem.items)
          .sum("pedidos")
          .done(function (result) {
            resultItem = {
              idPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              idEntidad: dataItem.key[3],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: result,
            };
          });
        query(dataItem.items)
          .sum("repartos")
          .done(function (result) {
            resultItem = {
              idPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              idEntidad: dataItem.key[3],
              totalInv: resultItem.totalInv,
              totalInvLav: resultItem.totalInvLav,
              pedidos: resultItem.pedidos,
              repartos: result,
            };
          });
        return resultItem;
      })
      .toArray();

    //#region Añadir pedidos de lavandería a partir de la tabla de prendas
    let items_prendas_gridPrendas = $(
      "#dxDataGrid_finalTeorico_prendas_gridPrendas",
    )
      .dxDataGrid("instance")
      .getDataSource()
      .items();
    $.each(dataSource_entidades, function (index, item) {
      if (item.idEntidad == null) {
        // Solo para las lavanderias
        let prenda_coincide = $.grep(
          items_prendas_gridPrendas,
          function (prenda) {
            return prenda.idPrenda === item.idPrenda;
          },
        );
        if (prenda_coincide.length === 1)
          item.pedidos = prenda_coincide[0].pedidos;
      }
    });
    //#endregion

    //Para filtrar una vez se ha modificado las prendas de lavandería
    let dataSource_entidades_ = query(dataSource_entidades)
      .filter([
        ["totalInv", ">", 0],
        "or",
        ["totalInvLav", ">", 0],
        "or",
        ["pedidos", ">", 0],
        "or",
        ["repartos", ">", 0],
      ]) // Si uno de estos campos es mayor a 0, ha habido movimiento en el inventario, ha de salir
      .toArray();

    $("#dxDataGrid_finalTeorico_entidades_gridPrendas")
      .dxDataGrid("instance")
      .option("dataSource", dataSource_entidades_);
  }
  //#endregion

  function currency_format() {
    var moneda = "EUR";

    var compaSel = [];
    if (typeof $("#dxList_compañia")[0] !== "undefined") {
      compaSel = $("#dxList_compañia")
        .dxList("instance")
        .option("selectedItems");
    }

    var entidadSel = [];
    if (typeof $("#dxList_entidad")[0] !== "undefined") {
      entidadSel = $("#dxList_entidad")
        .dxList("instance")
        .option("selectedItems");
    }

    if (entidadSel.length > 0) {
      var entidad = entidadSel[0];
      if (entidad) {
        moneda = entidad.tblMoneda.codigo;
      }
    } else if (LAVANDERIA) {
      moneda = LAVANDERIA.monedaLocal;
    } else if (compaSel.lenght > 0) {
      var entidades = $.grep(datasource_entidades.items(), function (entidad) {
        return entidad.idCompañia === compaSel[0].idCompañia;
      });

      moneda = entidades[0].tblMoneda.codigo;
    }
    return { style: "currency", currency: moneda, minimumFractionDigits: 2 };
  }

  function reiniciar_filtros() {
    var listaInventarioTeorico = $("#dxList_inventarioTeorico").dxList(
      "instance",
    );
    if (listaInventarioTeorico) {
      listaInventarioTeorico.option("selectedItemKeys", []);
      listaInventarioTeorico.repaint();
    }

    var listaInventarios = $("#dxList_inventarios").dxList("instance");
    if (listaInventarios) {
      listaInventarios.option("selectedItemKeys", []);
      listaInventarios.repaint();
    }

    datasource_inventarios.filter(null);
  }

  function getLavName(idLavanderia) {
    var lavanderiasFiltradas = $.grep(
      dataSource_lavanderias_todas.items(),
      function (element, index) {
        return element.idLavanderia === idLavanderia;
      },
    );

    if (lavanderiasFiltradas.length > 0) {
      return lavanderiasFiltradas[0].denominacion;
    } else {
      return "";
    }
  }

  function get_dataSource_teorico_format() {
    let dataSource_teorico = $.extend(true, [], finalTeorico_items);
    $.each(dataSource_teorico, function (index, item) {
      if (item.idLavanderia !== null) {
        // los almacenes de lavanderia
        item.pedidos = 0; // Los pedidos agrupados por lav se omiten para no duplicar datos. (Siempre tiene idEntidad)

        let count = finalTeorico_items.filter(
          (obj) => obj.idPrenda === item.idPrenda,
        ).length;
        if (count > 1) item.repartos = 0; // Los repartos agrupados por lav se omiten para no duplicar datos. (Siempre tiene idEntidad e idLavanderia)
      }
    });

    return dataSource_teorico;
  }

  function cellTemplate_avisoCantidadMovInv(cellElement, { data }) {
    if (data.total_entradas < data.total_inventario) {
      cellElement.append(
        $("<div />")
          .addClass("font-size-xl icon_Warning primary")
          .css({ cursor: "pointer" }),
      );

      cellElement.append(
        $("<div />").dxPopover({
          target: cellElement,
          showEvent: "mouseenter",
          hideEvent: "mouseleave",
          position: "top",
          width: 350,
          contentTemplate: (contentElement) => {
            const container = $("<div />").addClass(
              "d-flex flex-column align-items-center",
            );
            const mensaje = $("<div />")
              .addClass("mb-2")
              .text(getTrad("aviso_I_CantidadInvetarioMayorQueEntradas"));
            const dataGrid = $("<div />").dxDataGrid({
              dataSource: [data],
              width: 300,
              showColumnLines: false,
              columns: [
                {
                  dataField: "total_inventario",
                  caption: getTrad("totalInventario"),
                  alignment: "center",
                  width: 150,
                  format: { style: "decimal", maximumFractionDigits: 0 },
                },
                {
                  dataField: "total_entradas",
                  caption: getTrad("totalEntradas"),
                  alignment: "center",
                  width: 150,
                  format: { style: "decimal", maximumFractionDigits: 0 },
                },
              ],
            });

            mensaje.appendTo(container);
            dataGrid.appendTo(container);

            contentElement.append(container);
          },
        }),
      );
    }
  }

  deferred.resolve();
  return deferred.promise();
}
