import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { Router } from "react-router-dom";
import "./styles/base.scss";
import "./assets/base.scss";
import Main from "./pages/Main";

import { store } from "./config";
import { Provider } from "react-redux";
import { history } from "./helpers";
import dxComponentDefaultOptions from "./config/dxComponentDefaultOptions";

dxComponentDefaultOptions();
const rootElement = document.getElementById("root");
window.onerror = function () {
  if (process.env.NODE_ENV === "production") {
    return true;
  }
};

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>,
    rootElement,
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./pages/Main", () => {
    const NextApp = require("./pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
