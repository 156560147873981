import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";
import { globalActions } from "../../actions";

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import { SelectBox } from "devextreme-react/select-box";
import { DropDownOptions } from "devextreme-react/lookup";
import HeaderDots from "./Components/HeaderDots";
import { history } from "../../helpers";

import "../../styles/layout/UserBox/UserBox.scss";
import "../../styles/layout/HeaderDots/HeaderDots.scss";

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    onSelectionChanged_lavanderia = (e) => {
        this.props.setLavanderia(e.selectedItem);
    };

    onSelectionChanged_empresas = (e) => {
        this.props.setEmpresa(e.selectedItem);
    };

    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow,
            lavanderia,
            empresa,
            resolucion,
            aplicacionActiva,
            user,
        } = this.props;

        let isMobileVersion = resolucion == "xs" || resolucion == "sm";
        let lavanderias =
            aplicacionActiva && aplicacionActiva.idAplicacion === 1
                ? JSON.parse(localStorage.getItem("lavanderias"))
                : null;

        let headerDots_usuario = (
            <div
                className={
                    "app-header-right" +
                    (enableMobileMenuSmall && isMobileVersion ? " m-0 pt-3 justify-content-around" : "")
                }
            >
                <HeaderDots />
                <UserBox />
            </div>
        );

        let formulario = history.location.pathname.split("/").reverse()[0];
        let form = user.visiblePages.find((x) => x.formulario === formulario);

        let isVisibleSelectorLavsRRHH =
            (form &&
                (form.idApartado != 5 || // No es RRHH
                    (form.idApartado == 5 && // Es RRHH
                        (form.idFormulario == 41 || // Personal
                            form.idFormulario == 63 || // Calendario laboral
                            form.idFormulario == 136 || // Cuadrante personal
                            form.idFormulario == 151 || // Informe balance horas
                            (form.idFormulario == 26 && !lavanderia.enableControlHorario))))) || // Entrada / Salida personal + lavanderia no enableControlHorario
            form == null;

        let isVisibleFinanzas =
            (form && (form.idApartado !== 19 || (form.idApartado === 19 && form.idFormulario !== 148))) || // No es Control presupuestario V2
            form == null;

        let isVisibleSelectorLavs =
            lavanderias &&
            lavanderias.length > 0 &&
            aplicacionActiva.idAplicacion === 1 && // Tiene + de 1 lavandería y la aplicación es Lavanderías
            ((form && isVisibleSelectorLavsRRHH && isVisibleFinanzas) || form == null);

        let empresas = JSON.parse(localStorage.getItem("empresas"));
        let selectorLavanderias = isVisibleSelectorLavs ? (
            <div className={"app-header-left" + (enableMobileMenuSmall && isMobileVersion ? " pb-3" : "")}>
                <SelectBox
                    elementAttr={{ class: "font-size-sm" }}
                    inputAttr={{ class: "font-size-sm" }}
                    deferRendering={false}
                    items={lavanderias}
                    displayExpr="denominacion"
                    valueExpr="idLavanderia"
                    value={lavanderia ? lavanderia.idLavanderia : lavanderias[0].idLavanderia}
                    defaultValue={lavanderia ? lavanderia.idLavanderia : lavanderias[0].idLavanderia}
                    width={"100%"}
                    stylingMode="underlined"
                    onSelectionChanged={this.onSelectionChanged_lavanderia}
                />
            </div>
        ) : (
            ""
        );

        let isVisibleSelectorEmpresas =
            aplicacionActiva.idAplicacion === 5 && form.idApartado != 23 && form.idApartado != 24;
        let selectorEmpresas = isVisibleSelectorEmpresas ? (
            <div className={"app-header-left" + (enableMobileMenuSmall && isMobileVersion ? " pb-3" : "")}>
                <SelectBox
                    elementAttr={{ class: "font-size-sm" }}
                    inputAttr={{ class: "font-size-sm" }}
                    deferRendering={false}
                    items={empresas}
                    displayExpr="denominacion"
                    valueExpr="idEmpresaPolarier"
                    // value={empresa ? empresa.idEmpresaPolarier : empresas[0].idEmpresaPolarier}
                    defaultValue={empresa ? empresa.idEmpresaPolarier : empresas[0].idEmpresaPolarier}
                    width={"100%"}
                    stylingMode="underlined"
                    onSelectionChanged={this.onSelectionChanged_empresas}
                    defaultOpened={empresas.length > 1}
                    disabled={empresas.length <= 1}
                >
                    <DropDownOptions
                        showTitle={true}
                        title={"Seleccione la empresa"}
                        minWidth={300}
                        shading={true}
                        animation={{
                            show: { type: "fade", duration: 400, from: 0, to: 1 },
                            hide: { type: "fade", duration: 400, from: 1, to: 0 },
                        }}
                        position={{
                            my: "center",
                            at: "center",
                            of: window,
                        }}
                    />
                </SelectBox>
            </div>
        ) : (
            ""
        );

        let header;
        if (enableMobileMenuSmall && isMobileVersion)
            header = [headerDots_usuario, selectorLavanderias, selectorEmpresas];
        else header = [selectorLavanderias, selectorEmpresas, headerDots_usuario];
        return (
            <Fragment>
                <div
                    className={cx("app-header", headerBackgroundColor, {
                        "header-shadow": enableHeaderShadow,
                    })}
                >
                    <HeaderLogo />

                    <div
                        className={cx("app-header__content", {
                            "header-mobile-open flex-flow-wrap justify-content-center":
                                enableMobileMenuSmall && isMobileVersion,
                        })}
                    >
                        {header}
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    resolucion: state.Global.resolucion,
    lavanderia: state.Global.lavanderia,
    aplicacionActiva: state.Global.aplicacionActiva,
    formularioActivo: state.Global.formularioActivo,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    setLavanderia: (lavanderia) => dispatch(globalActions.setLavanderia(lavanderia)),
    setEmpresa: (empresa) => dispatch(globalActions.setEmpresa(empresa)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
