import React, { Fragment } from "react";
import { connect } from "react-redux";

import { history } from "../helpers";

import Loader from "react-loaders";

class LoadingPanel extends React.Component {
  render() {
    let { user, show, shading } = this.props;
    let formulario = history.location.pathname.split("/").reverse()[0];

    let form = user.visiblePages.find((x) => x.formulario === formulario);
    let denominacion = form ? form.denominacion : formulario;

    return (
      <Fragment>
        {show ? (
          <div
            className={
              "loader-container absolute " + (shading ? "shading" : "")
            }
          >
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="line-scale" />
              </div>
              {!shading ? (
                <h6 className="mt-3">
                  Por favor, espera mientras se carga {denominacion}
                </h6>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show: state.LoadPanel.show,
  shading: state.LoadPanel.shading,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(LoadingPanel);
