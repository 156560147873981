import { Button } from "devextreme-react";
import React, { Component } from "react";

class GroupCell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{this.props.text}</span>
        <Button
          onClick={() => {
            this.props.popupMaquinas.current.toggle();
            this.props.popupMaquinas.current.selectMaquina(
              this.props.data.items[0].idMaquina,
            );
          }}
          type="default"
          text="Editar máquina"
        />
      </div>
    );
  }
}

export default GroupCell;
