import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
import { authHeader, errorHandler, getTrad } from "../../../../helpers";
//Componentes

import { connectionConstants } from "constants";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import ScrollView from "devextreme-react/scroll-view";
import LottieIcon from "components/LottieIcon";
import StarRatings from "react-star-ratings";

import { EmojiRating } from "../../../../components/EmojiRaging/EmojiRating";

//styles
import "./css.scss";

class EncuestaRealizada extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datosEncuesta: [],
      denoUsuario: "",
    };
  }

  render() {
    const { datosEncuesta, denoUsuario } = this.state;
    const { visible } = this.props;

    return (
      <div className="he-100">
        {visible &&
          (datosEncuesta.length == 0 ? (
            <div className="he-100">
              <div className="h2 p-4 text-center ">
                {getTrad("seleccionaUsuario")}{" "}
              </div>
              <LottieIcon height="70%" width="70%" icon={"buscarEncuesta"} />
            </div>
          ) : (
            <div className="he-100 pb-5">
              <div className="h4 text-center">
                {getTrad("encuesta")} - {denoUsuario}
              </div>
              <ScrollView className="he-100 p-2">
                <div className="he-100">
                  {datosEncuesta.map((item) => {
                    return this.render_datosEncuesta(item);
                  })}
                </div>
              </ScrollView>
            </div>
          ))}
      </div>
    );
  }

  render_datosEncuesta = (item) => {
    const {
      pregunta,
      texto,
      valor,
      idTipoPregunta,
      maxRango,
      minRango,
      idRespuesta,
      descripcionOpcion,
      descripcionPregunta,
    } = item;

    return (
      <div key={idRespuesta} className="container_pregunta ">
        <div className="font-weight-bold">{pregunta}</div>
        {idTipoPregunta == 1 && (
          <div className="container_respuesta">{descripcionOpcion}</div>
        )}
        {idTipoPregunta == 4 && (
          <div className="container_respuesta">
            <EmojiRating value={valor} />
          </div>
        )}
        {idTipoPregunta == 2 && (
          <div className="container_respuesta d-flex justify-content-center ">
            <StarRatings
              rating={valor}
              starRatedColor={"var(--primary)"}
              starEmptyColor={"#F3EFEF"}
              numberOfStars={maxRango}
              name="rating"
            />
          </div>
        )}
        {idTipoPregunta == 3 && (
          <div className="container_respuesta">{texto}</div>
        )}
      </div>
    );
  };

  dataSource_datosEncuestaNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas_usuario",
      key: "idPregunta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_datosEncuestaNUsuario_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_datosEncuestaNUsuario_beforeSend = (request) => {
    const { idUsuarioSel, idEncuestaSel } = this.props;

    request.headers = { ...authHeader() };
    if (idEncuestaSel && idUsuarioSel) {
      request.params.idEncuesta = idEncuestaSel;
      request.params.idUsuario = idUsuarioSel;
    } else {
      request.onload = () => {
        request.response = [];
      };
    }
  };

  componentDidUpdate(prevProps) {
    const { idEncuestaSel, idUsuarioSel, visible } = this.props;
    const { datosEncuesta } = this.state;

    if (
      idUsuarioSel &&
      idUsuarioSel !== prevProps.idUsuarioSel &&
      idEncuestaSel
    ) {
      this.dataSource_datosEncuestaNUsuario.reload().done((data) => {
        this.setState({
          datosEncuesta: data[0].datosRespuesta,
          denoUsuario: data[0].denoUsuario,
        });
      });
    }

    if (
      prevProps.visible == true &&
      visible == false &&
      datosEncuesta.length > 0
    ) {
      this.setState({ datosEncuesta: [], denoUsuario: "" });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(EncuestaRealizada);
