import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
import Box, { Item as ItemBox } from "devextreme-react/box";

//Componentes
import StarRatings from "react-star-ratings";
import { getTrad } from "helpers";

//Css
import "../css.scss";

class PreguntaRango extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valorRango: 0,
    };
  }

  setRango = (valor) => {
    this.setState({ valorRango: valor }, () => {
      let { onValueChange, respuestas, dataSource } = this.props;
      const resp = { idPregunta: dataSource.idPregunta, valor };
      const hayRespuesta = respuestas.find(
        ({ idPregunta }) => idPregunta == dataSource.idPregunta,
      );

      if (hayRespuesta) {
        respuestas.find(
          ({ idPregunta }) => idPregunta == dataSource.idPregunta,
        ).valor = valor;
      } else {
        respuestas.push(resp);
      }

      onValueChange({ respuestasUsuario: respuestas });
    });
  };

  render() {
    const { dataSource } = this.props;
    const { valorRango } = this.state;

    return (
      <div className="contentPosition_spaceAround">
        {dataSource.minRangoText && (
          <div className="font-size-md  font-weight-semiBold">
            {dataSource.minRangoText}
          </div>
        )}
        <StarRatings
          rating={valorRango}
          starRatedColor={"var(--primary)"}
          starHoverColor={"var(--primary_light)"}
          starEmptyColor={"#F3EFEF"}
          numberOfStars={dataSource.maxRango}
          name={getTrad("rating")}
          starDimension={"5rem"}
          starSpacing={"0.2rem"}
          changeRating={(num) => this.setRango(num)}
        />
        <div>
          {dataSource.maxRangoText && (
            <div className="font-size-md  font-weight-semiBold">
              {dataSource.maxRangoText}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(PreguntaRango);
