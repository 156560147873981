import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
  formatDate,
  tooltipControl_creacion,
  formatDate_noTime_parameter,
  getDaysInRange,
  patchRequestHandler,
} from "../../../helpers";
import {
  svg_edit,
  svg_remove,
  svg_elemTrans_ud,
  svg_elemTrans_bac,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA, USER) {
  var deferred = $.Deferred();

  var datasource_repartos;
  var datasource_prendasReparto;
  var repartoSel, tipoReportSel;
  var datasource_prendasReparto_peticion;
  var datasource_prendasPedido;
  let fechasEntidadesVisibles = [];

  var datasource_tblCierreFactEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
      key: ["idEntidad", "fechaDesde", "fechaHasta"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        let idsEntidad = [
          ...new Set(fechasEntidadesVisibles.map((x) => x.idEntidad)),
        ];
        let fechas = [...new Set(fechasEntidadesVisibles.map((x) => x.fecha))];

        let filtroEntidades =
          idsEntidad.length > 0
            ? ["idEntidad in (" + idsEntidad + ")"]
            : ["true"];
        let filtroFechas = "";

        fechas.forEach((fecha, index) => {
          if (index != 0) filtroFechas += " or ";
          filtroFechas +=
            "(fechaDesde le " + fecha + " and fechaHasta ge " + fecha + ")";
        });
        if (filtroFechas == "") filtroFechas = "true";

        if (loadOptions.filter) {
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            [filtroEntidades],
            "and",
            [filtroFechas],
          ];
        } else {
          loadOptions.filter = [[filtroEntidades], "and", [filtroFechas]];
        }
      },
      version: 4,
    }),
  });

  var datasource_repartos_Cierres = new DataSource({
    store: new CustomStore({
      key: "idReparto",
      load: function (loadOptions) {
        var deferred = $.Deferred();

        let pageIndex =
          loadOptions.skip == 0 ? 0 : loadOptions.skip / loadOptions.take;

        datasource_repartos.paginate(true);
        datasource_repartos.pageSize(loadOptions.take);
        datasource_repartos.sort(loadOptions.sort);
        datasource_repartos.requireTotalCount(true);
        datasource_repartos.searchValue(loadOptions.searchValue);
        datasource_repartos.searchOperation(loadOptions.searchOperation);
        datasource_repartos.filter(
          loadOptions.filter ? loadOptions.filter : null,
        );
        datasource_repartos.pageIndex(pageIndex);

        datasource_repartos.load().done(function (data) {
          fechasEntidadesVisibles = [];
          let repartosMapeados = data.map((x) => {
            return {
              fecha: formatDate_noTime_parameter(x.fecha),
              idEntidad: x.idEntidad,
            };
          });
          fechasEntidadesVisibles = [...new Set(repartosMapeados)];

          datasource_tblCierreFactEntidad.load().done((fechasEntidadCierre) => {
            data.forEach((reparto) => {
              fechasEntidadCierre.forEach((item) => {
                let { fechaDesde, fechaHasta } = item;
                let rangoFechas = getDaysInRange(fechaDesde, fechaHasta).map(
                  (x) => formatDate_noTime_parameter(x),
                );

                if (!reparto.isCerradoFactEntidad)
                  reparto.isCerradoFactEntidad =
                    reparto.idEntidad == item.idEntidad &&
                    rangoFechas.filter(
                      (fecha) =>
                        fecha == formatDate_noTime_parameter(reparto.fecha),
                    ).length > 0;
              });
            });
            deferred.resolve(data, {
              totalCount: datasource_repartos.totalCount(),
            });
          });
        });

        return deferred.promise();
      },
      update: (key, values) => {
        var deferred = $.Deferred();
        datasource_repartos
          .store()
          .update(key, values)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
      remove: (key) => {
        var deferred = $.Deferred();
        datasource_repartos
          .store()
          .remove(key)
          .done(() => {
            deferred.resolve();
          });
        return deferred.promise();
      },
    }),
  });

  datasource_repartos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblReparto",
      key: "idReparto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    select: [
      "idReparto",
      "codigo",
      "fecha",
      "idEntidad",
      "idPedido",
      "idPedidoCliente",
      "idRepartoEstado",
      "isApp",
    ],
    expand: [
      "idUsuarioPreparaNavigation($expand=idPersonaNavigation($select=nombre,apellidos))",
      "idEntidadNavigation($select = denominacion, reparteRechazoRetiro;$expand=idModeloImpresion_repartoNavigation($select=report))",
      "idPedidoNavigation($select = idTipoProduccion; $expand = idTipoProduccionNavigation($select = idTipoProduccion,denominacion), idTipoPedidoNavigation($select = idTipoPedido,denominacion))",
      "idRepartoEstadoNavigation($select = denominacion)",
      "tblRutaNParteTransporte($count=true;$top=1)",
      "tblRepartoNParteTransporte($count=true;$top=1)",
    ],

    postProcess: function (data) {
      let dataFormat = data.map((x) => {
        const repartoFinalizado = x.idRepartoEstado == 3;
        const repartoApp = x.tblRepartoNParteTransporte.length > 0;

        const personaNav =
          x.idUsuarioPreparaNavigation?.idPersonaNavigation ?? null;
        const nombreUsuarioPrepara = personaNav
          ? personaNav?.nombre + " " + personaNav?.apellidos
          : null;

        let idTipoReparto, denoSubTipoReparto;

        if (x.idPedido === null) {
          idTipoReparto = 0;
          denoSubTipoReparto = getTrad("desgome").toUpperCase();
        } else if (x.idPedidoNavigation?.idTipoProduccion) {
          idTipoReparto = 1;
          denoSubTipoReparto =
            x.idPedidoNavigation.idTipoProduccionNavigation.denominacion;
        } else {
          idTipoReparto = 2;
          denoSubTipoReparto =
            x.idPedidoNavigation.idTipoPedidoNavigation.denominacion;
        }

        return {
          idReparto: x.idReparto,
          codigo: x.codigo,
          fecha: x.fecha,
          idEntidad: x.idEntidad,
          idPedido: x.idPedido,
          idPedidoCliente: x.idPedidoCliente,
          idRepartoEstado: x.idRepartoEstado,
          preparacionApp: x.isApp,
          nombreUsuarioPrepara,
          idEntidadNavigation: x.idEntidadNavigation,
          idPedidoNavigation: x.idPedidoNavigation,
          idRepartoEstadoNavigation: x.idRepartoEstadoNavigation,
          "tblRutaNParteTransporte@odata.count": x.tblRutaNParteTransporte,
          idTipoReparto: x.idTipoReparto,
          denoSubTipoReparto: x.denoSubTipoReparto,
          validacionApp: repartoFinalizado && repartoApp,
          idTipoReparto,
          denoSubTipoReparto,
        };
      });
      return dataFormat;
    },
  });

  var enum_tipoReparto = [
    {
      idTipoReparto: 0,
      denominacion: getTrad("desgome").toUpperCase(),
    },
    {
      idTipoReparto: 1,
      denominacion: getTrad("produccion").toUpperCase(),
    },
    {
      idTipoReparto: 2,
      denominacion: getTrad("pedido").toUpperCase(),
    },
  ];

  var enum_estadoReparto = [
    {
      idRepartoEstado: 1,
      denominacion: getTrad("enReparto").toUpperCase(),
    },
    {
      idRepartoEstado: 2,
      denominacion: getTrad("noValidado").toUpperCase(),
    },
    {
      idRepartoEstado: 3,
      denominacion: getTrad("validado").toUpperCase(),
    },
  ];

  var enum_preparacion = [
    {
      preparacionApp: true,
      denominacion: getTrad("prepApp").toUpperCase(),
    },
    {
      preparacionApp: false,
      denominacion: getTrad("prepMypolarier").toUpperCase(),
    },
  ];

  var enum_validacion = [
    {
      validacionApp: true,
      denominacion: getTrad("valApp").toUpperCase(),
    },
    {
      validacionApp: false,
      denominacion: getTrad("valMypolarier").toUpperCase(),
    },
  ];

  datasource_prendasReparto = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaNReparto",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idReparto = repartoSel.idReparto;
      },
      version: 4,
    }),
    expand: [
      "tblPrenda($select = codigoPrenda, denominacion, udsXBacReparto,udsXBacPedido; $expand = tblElemTransReparto,tblElemTransPedido)",
    ],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        if (
          parseInt(item.tblPrenda.tblElemTransReparto.codigo) === 2 &&
          repartoSel.pedidoProd === false
        )
          item.cantidad = item.cantidad / item.tblPrenda.udsXBacReparto;
      });
      return data;
    },
  });

  var context_tblPrendaNReparto = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblPrendaNReparto",
    entities: {
      OverrideMasivo: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
      request.params.idReparto = repartoSel.idReparto;
      request.payload = tratarPrendasReparto();
    },
  });

  datasource_prendasPedido = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "repartos_spSelectPrendasNPedidoPendientes",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idPedido = repartoSel.idPedido;
        request.params.idReparto = repartoSel.idReparto;
      },
      version: 4,
    }),
  });

  datasource_prendasReparto_peticion = new DataSource({
    store: new CustomStore({
      load: function (loadOptions) {
        var deferred = $.Deferred();

        $.when(
          datasource_prendasPedido.reload(),
          datasource_prendasReparto.reload(),
        ).done(function () {
          var prendasPedido = datasource_prendasPedido.items();
          var prendasReparto = datasource_prendasReparto.items();

          var datos = [];
          $.each(prendasReparto, function (index, prendaRep) {
            var prenda = $.extend(true, {}, prendaRep);
            var prendaPedido = $.grep(prendasPedido, function (prendaPed) {
              return prendaPed.idPrenda === prenda.idPrenda;
            });
            prenda.peticion =
              prendaPedido.length > 0 ? prendaPedido[0].peticion : 0;
            datos.push(prenda);
          });
          deferred.resolve(datos);
        });
        return deferred.promise();
      },
    }),
  });

  if (typeof $("#ValidarRepartos #dxContainer").data("dxBox") !== "undefined") {
    $("#ValidarRepartos #dxContainer").dxBox("instance").dispose();
  }

  $("#dxTooltip_ValidarRepartos").dxTooltip({
    showEvent: "mouseenter",
    hideEvent: "mouseleave",
  });

  $("#ValidarRepartos #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    width: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          item.append(
            $("<div id='dgvRepartos' />").dxDataGrid({
              //Datos
              dataSource: datasource_repartos_Cierres,
              remoteOperations: true,
              //Propiedades
              columnsAutoWidth: true,
              height: "100%",
              headerFilter: {
                visible: true,
              },
              filterRow: {
                visible: true,
                applyFilter: "auto",
              },
              searchPanel: {
                visible: true,
                width: 240,
                placeholder: getTrad("busqueda"),
              },
              paging: {
                pageSize: 50,
              },
              pager: {
                showPageSizeSelector: true,
                allowedPageSizes: [20, 50, 100],
                showInfo: true,
              },
              columns: [
                {
                  caption: " ",
                  width: "30px",
                  alignment: "center",
                  cssClass: "p-0",
                  cellTemplate: function (cellElement, cellInfo) {
                    cellElement.on("click", function () {
                      if (cellInfo.data.isCerradoFactEntidad) return;
                      repartoSel = cellInfo.data;
                      repartoSel.pedidoProd =
                        repartoSel.idPedido === null ||
                        repartoSel.idPedidoNavigation.idTipoProduccion !== null;

                      datasource_prendasReparto_peticion
                        .reload()
                        .done(function (prendas) {
                          $("#popup_prendas").dxPopup("instance").show();

                          var grid = $("#dgvPrendas").dxDataGrid("instance");
                          grid.beginUpdate();
                          grid.cancelEditData();
                          grid.option("dataSource", prendas);

                          if (
                            typeof $(
                              "#dxDropDownBox_dgvPrendasReparto_pedidoCliente",
                            )[0] !== "undefined"
                          ) {
                            var dxDropDownBox_dgvPrendasReparto_pedidoCliente =
                              $(
                                "#dxDropDownBox_dgvPrendasReparto_pedidoCliente",
                              ).dxDropDownBox("instance");
                            dxDropDownBox_dgvPrendasReparto_pedidoCliente
                              .getDataSource()
                              .reload()
                              .done(function () {
                                dxDropDownBox_dgvPrendasReparto_pedidoCliente.option(
                                  "value",
                                  repartoSel.idPedidoCliente,
                                );
                              });
                          }

                          var editingOptions = grid.option("editing");
                          if (
                            cellInfo.data.idRepartoEstado === 1 ||
                            cellInfo.data.idRepartoEstado === 3
                          ) {
                            editingOptions.allowUpdating = false;
                            editingOptions.allowDeleting = false;
                            $.each(
                              ["cantidad", "rechazo", "retiro"],
                              function (index, item) {
                                grid.columnOption(item, "cssClass", "");
                              },
                            );
                          } else {
                            editingOptions.allowUpdating = true;
                            editingOptions.allowDeleting = true;
                            $.each(
                              ["cantidad", "rechazo", "retiro"],
                              function (index, item) {
                                grid.columnOption(
                                  item,
                                  "cssClass",
                                  "dx-Cell_Editable",
                                );
                              },
                            );
                          }

                          grid.columnOption(
                            "rechazo",
                            "visible",
                            repartoSel.pedidoProd &&
                              (repartoSel.idEntidadNavigation
                                .reparteRechazoRetiro === 0 ||
                                repartoSel.idEntidadNavigation
                                  .reparteRechazoRetiro === 1),
                          );
                          grid.columnOption(
                            "retiro",
                            "visible",
                            repartoSel.pedidoProd &&
                              (repartoSel.idEntidadNavigation
                                .reparteRechazoRetiro === 0 ||
                                repartoSel.idEntidadNavigation
                                  .reparteRechazoRetiro === 2),
                          );

                          grid.endUpdate();
                        });
                    });

                    let tooltip = $("#dxTooltip_ValidarRepartos").dxTooltip(
                      "instance",
                    );

                    let editElement = $("<div/>")
                      .addClass(
                        "container_spanCentrado " +
                          (cellInfo.data.isCerradoFactEntidad && "pe-none"),
                      )
                      .append(
                        svg_edit
                          .clone()
                          .css(
                            "opacity",
                            cellInfo.data.isCerradoFactEntidad ? 0.5 : 1,
                          )
                          .css("height", "25px")
                          .css("width", "25px")
                          .css("cursor", "pointer"),
                      );

                    cellElement.addClass(
                      "pe-auto id_modify_" + cellInfo.data.idReparto,
                    );
                    cellElement
                      .on("mouseover", function () {
                        if (cellInfo.data.isCerradoFactEntidad) {
                          tooltip.option({
                            target: ".id_modify_" + cellInfo.data.idReparto,
                            contentTemplate: function (contentElement) {
                              contentElement.html(
                                getTrad("factBloqueadaEntiFecha"),
                              );
                            },
                          });
                          tooltip.show();
                        }
                      })
                      .on("mouseout", function () {
                        tooltip.hide();
                      });

                    if (!cellInfo.data.isCerradoFactEntidad) {
                      tooltip.option({
                        showEvent: null,
                        hideEvent: null,
                      });
                    }

                    cellElement.append(editElement);
                  },
                  allowSearch: false,
                },
                {
                  caption: " ",
                  width: "30px",
                  alignment: "center",
                  cssClass: "p-0",
                  cellTemplate: function (container, options) {
                    $("<div />")
                      .attr("class", "icon_Impresora")
                      .css("font-size", "25px")
                      .css("cursor", "pointer")
                      .on("click", function () {
                        //REPORT
                        tipoReportSel =
                          options.data.idEntidadNavigation
                            .idModeloImpresion_repartoNavigation.report;
                        var report = {
                          denominacion: tipoReportSel,
                          parameterEvent: CustomizeParameterEditors,
                        };
                        var params = {
                          idReparto: options.data.idReparto,
                        };

                        COMPONENT.setReport(report);
                        setTimeout(() => {
                          COMPONENT.setParams(params);
                        }, 1000);
                      })
                      .appendTo(container);
                  },
                  allowFiltering: false,
                },
                {
                  caption: " ",
                  width: "30px",
                  alignment: "center",
                  cssClass: "p-0",
                  cellTemplate: function (cellElement, cellInfo) {
                    if (
                      typeof cellInfo.data.idRepartoEstado !== "undefined" &&
                      cellInfo.data.idRepartoEstado !== 1
                    ) {
                      var codigoEstadoNuevo;
                      var codigoEstado = cellInfo.data.idRepartoEstado;
                      var img;

                      let tooltip = $("#dxTooltip_ValidarRepartos").dxTooltip(
                        "instance",
                      );

                      if (codigoEstado === 2) {
                        codigoEstadoNuevo = 3;
                        img = "icon_Circle_uncheck";
                      } else if (codigoEstado === 3) {
                        codigoEstadoNuevo = 2;
                        img = "icon_Circle_check";
                      }

                      let innerElement = $("<div />")
                        .attr("id", "img_val")
                        .attr("class", img)
                        .css("font-size", "25px")
                        .css("cursor", "pointer")
                        .css(
                          "opacity",
                          cellInfo.data.isCerradoFactEntidad ? 0.5 : 1,
                        )
                        .on("click", function () {
                          if (cellInfo.data.isCerradoFactEntidad) return;
                          datasource_repartos
                            .store()
                            .update(
                              cellInfo.data.idReparto,
                              patchRequestHandler({
                                idRepartoEstado: codigoEstadoNuevo,
                              }),
                            )
                            .done(function () {
                              notify({
                                message: getTrad("alerta_estadoReparto"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                              });

                              cellInfo.component.refresh();
                            });
                        });

                      let editElement = $("<div/>")
                        .addClass(
                          "container_spanCentrado " +
                            (cellInfo.data.isCerradoFactEntidad && "pe-none"),
                        )
                        .append(innerElement);

                      cellElement.addClass(
                        "pe-auto id_remove_" + cellInfo.data.idReparto,
                      );
                      cellElement
                        .on("mouseover", function () {
                          if (cellInfo.data.isCerradoFactEntidad) {
                            tooltip.option({
                              target: ".id_remove_" + cellInfo.data.idReparto,
                              contentTemplate: function (contentElement) {
                                contentElement.html(
                                  getTrad("factBloqueadaEntiFecha"),
                                );
                              },
                            });
                            tooltip.show();
                          }
                        })
                        .on("mouseout", function () {
                          tooltip.hide();
                        });

                      if (!cellInfo.data.isCerradoFactEntidad) {
                        tooltip.option({
                          showEvent: null,
                          hideEvent: null,
                        });
                      }

                      cellElement.append(editElement);
                    }
                  },
                  allowFiltering: false,
                },
                {
                  dataField: "codigo",
                  caption: getTrad("codigo"),
                  width: "10%",
                  allowHeaderFiltering: false,
                  editorOptions: {
                    showClearButton: true,
                  },
                },
                {
                  dataField: "idEntidadNavigation.denominacion",
                  caption: getTrad("cliente"),
                  cssClass: "p-0",
                  allowHeaderFiltering: false,
                  editorOptions: {
                    showClearButton: true,
                  },
                },
                {
                  dataField: "fecha",
                  caption: getTrad("fecha"),
                  dataType: "date",
                  format: "dd/MM/yyyy",
                  width: "15%",
                  sortOrder: "desc",
                  allowHeaderFiltering: false,
                  editorOptions: {
                    showClearButton: true,
                  },
                },
                {
                  dataField: "idRepartoEstado",
                  caption: getTrad("estado"),
                  width: "10%",
                  cssClass: "p-0",
                  lookup: {
                    dataSource: enum_estadoReparto,
                    valueExpr: "idRepartoEstado",
                    displayExpr: "denominacion",
                  },
                  allowEditing: false,
                  allowUpdating: false,
                  allowFiltering: false,
                  allowSorting: false,
                  allowHeaderFiltering: true,
                  calculateFilterExpression: function (
                    filterValue,
                    selectedFilterOperation,
                  ) {
                    switch (filterValue) {
                      case 1:
                        return ["idRepartoEstado", "=", 1];
                      case 2:
                        return ["idRepartoEstado", "=", 2];
                      case 3:
                        return ["idRepartoEstado", "=", 3];
                      default:
                        break;
                    }
                  },
                },
                {
                  dataField: "idTipoReparto",
                  caption: getTrad("tipoPedido"),
                  alignment: "center",
                  width: "9%",
                  cssClass: "p-0",
                  lookup: {
                    dataSource: enum_tipoReparto,
                    valueExpr: "idTipoReparto",
                    displayExpr: "denominacion",
                  },
                  allowEditing: false,
                  allowUpdating: false,
                  allowFiltering: false,
                  allowSorting: false,
                  allowHeaderFiltering: true,
                  cellTemplate: function (container, options) {
                    var img;
                    var htmlTooltip;
                    if (options.data.idTipoReparto === 0) {
                      // DESGOME
                      img = "icon_Box";
                      htmlTooltip = "<p>" + getTrad("desgome") + "</p>";
                    } else {
                      if (options.data.idTipoReparto === 2) {
                        // PEDIDO
                        img = "icon_Carro";
                        htmlTooltip = "<p>" + getTrad("pedido") + "</p>";
                      } else if (options.data.idTipoReparto === 1) {
                        // PRODUCCIÓN
                        img = "icon_Lavadora";
                        htmlTooltip = "<p>" + getTrad("produccion") + "</p>";
                      }
                    }
                    container.append(
                      $("<div />")
                        .attr("class", img)
                        .css("font-size", "25px")
                        .appendTo(container),
                    );

                    tooltipControl_creacion(container, htmlTooltip);
                  },
                  calculateFilterExpression: function (
                    filterValue,
                    selectedFilterOperation,
                  ) {
                    switch (filterValue) {
                      case 0:
                        return ["idPedido", "=", null];
                      case 1:
                        return [
                          ["idPedido", "<>", null],
                          "and",
                          ["idPedidoNavigation.idTipoProduccion", "<>", null],
                        ];
                      case 2:
                        return [
                          ["idPedido", "<>", null],
                          "and",
                          ["idPedidoNavigation.idTipoProduccion", "=", null],
                          "and",
                          ["idPedidoNavigation.idTipoPedido", "<>", null],
                        ];
                      default:
                        break;
                    }
                  },
                },
                {
                  dataField: "denoSubTipoReparto",
                  caption: getTrad("subtipoPedido"),
                  width: "10%",
                  editorOptions: {
                    showClearButton: true,
                  },
                  allowSorting: false,
                  allowHeaderFiltering: false,
                  calculateFilterExpression: function (
                    filterValue,
                    selectedFilterOperation,
                  ) {
                    var value = filterValue.toUpperCase(),
                      desgome = getTrad("desgome").toUpperCase();

                    if (desgome.indexOf(value) > -1) {
                      return [
                        ["idPedido", "=", null],
                        "or",
                        [
                          "idPedidoNavigation.idTipoProduccionNavigation.denominacion",
                          "contains",
                          filterValue,
                        ],
                        "or",
                        [
                          "idPedidoNavigation.idTipoPedidoNavigation.denominacion",
                          "contains",
                          filterValue,
                        ],
                      ];
                    } else
                      return [
                        [
                          "idPedidoNavigation.idTipoProduccionNavigation.denominacion",
                          "contains",
                          filterValue,
                        ],
                        "or",
                        [
                          "idPedidoNavigation.idTipoPedidoNavigation.denominacion",
                          "contains",
                          filterValue,
                        ],
                      ];
                  },
                },
                {
                  dataField: "preparacionApp",
                  caption: getTrad("preparacion"),
                  alignment: "center",
                  width: "9%",
                  cssClass: "p-0",
                  lookup: {
                    dataSource: enum_preparacion,
                    valueExpr: "preparacionApp",
                    displayExpr: "denominacion",
                  },
                  allowEditing: false,
                  allowUpdating: false,
                  allowFiltering: false,
                  allowSorting: false,
                  allowHeaderFiltering: true,

                  cellTemplate: function (container, options) {
                    var img;
                    var htmlTooltip;
                    if (options.data.preparacionApp == true) {
                      img = "icon_mobile";
                      htmlTooltip =
                        "<p>" + options.data.nombreUsuarioPrepara + "</p > ";
                    }
                    container.append(
                      $("<div />")
                        .attr("class", img)
                        .css("font-size", "25px")
                        .appendTo(container),
                    );
                    tooltipControl_creacion(container, htmlTooltip);
                  },
                  calculateFilterExpression: function (
                    filterValue,
                    selectedFilterOperation,
                  ) {
                    switch (filterValue) {
                      case true:
                        return ["isApp", "=", true];
                      case false:
                        return ["isApp", "=", false];
                      default:
                        break;
                    }
                  },
                },

                {
                  dataField: "validacionApp",
                  caption: getTrad("validacion"),
                  width: "9%",
                  cssClass: "p-0",
                  alignment: "center",
                  lookup: {
                    dataSource: enum_validacion,
                    valueExpr: "validacionApp",
                    displayExpr: "denominacion",
                  },
                  allowEditing: false,
                  allowUpdating: false,
                  allowFiltering: false,
                  allowSorting: false,
                  allowHeaderFiltering: true,

                  cellTemplate: function (container, options) {
                    var img;
                    if (options.data.validacionApp == true) {
                      img = "icon_mobile";
                    }
                    container.append(
                      $("<div />")
                        .attr("class", img)
                        .css("font-size", "25px")
                        .appendTo(container),
                    );
                    tooltipControl_creacion(container);
                  },
                  calculateFilterExpression: function (filterValue) {
                    switch (filterValue) {
                      case true: //APP
                        return [
                          ["idRepartoEstado", "=", 3],
                          "and",
                          ["tblRepartoNParteTransporte/$count", ">", 0],
                        ];
                      case false: //MY POLARIER
                        return [
                          ["idRepartoEstado", "=", 3],
                          "and",
                          ["tblRepartoNParteTransporte/$count", "=", 0],
                        ];
                      default:
                        break;
                    }
                  },
                },
                {
                  caption: " ",
                  width: "50px",
                  alignment: "center",
                  cssClass: "p-0",
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  allowUpdating: false,
                  cellTemplate: function (cellElement, cellInfo) {
                    cellElement.on("click", function () {
                      if (USER.idUsuario == 281) {
                        fetch(
                          connectionConstants.WEB_API_CORE_ODATA_URL +
                            "AppLogisticaExterna/setLogError?text=BORRAR_REPARTO=" +
                            cellInfo.data.idReparto,
                          { method: "POST" },
                        );
                        return;
                      }
                      if (cellInfo.data.isCerradoFactEntidad) return;
                      if (
                        cellInfo.data["tblRutaNParteTransporte@odata.count"] > 0
                      ) {
                        dxMensajePregunta(
                          getTrad("alert_repartoConParteTrans"),
                          [
                            // ACEPTAR
                            [
                              getTrad("aceptar"),
                              function () {},
                              "normal",
                              "btnAceptar",
                            ],
                          ],
                        );
                      } else cellInfo.component.deleteRow(cellInfo.rowIndex);
                    });

                    let tooltip = $("#dxTooltip_ValidarRepartos").dxTooltip(
                      "instance",
                    );

                    let editElement = $("<div/>")
                      .addClass(
                        "container_spanCentrado " +
                          (cellInfo.data.isCerradoFactEntidad && "pe-none"),
                      )
                      .append(
                        svg_remove
                          .clone()
                          .css(
                            "opacity",
                            cellInfo.data.isCerradoFactEntidad ? 0.5 : 1,
                          )
                          .css("height", "25px")
                          .css("width", "25px")
                          .css("cursor", "pointer"),
                      );

                    cellElement.addClass(
                      "pe-auto id_remove_" + cellInfo.data.idReparto,
                    );
                    cellElement
                      .on("mouseover", function () {
                        if (cellInfo.data.isCerradoFactEntidad) {
                          tooltip.option({
                            target: ".id_remove_" + cellInfo.data.idReparto,
                            contentTemplate: function (contentElement) {
                              contentElement.html(
                                getTrad("factBloqueadaEntiFecha"),
                              );
                            },
                          });
                          tooltip.show();
                        }
                      })
                      .on("mouseout", function () {
                        tooltip.hide();
                      });

                    if (!cellInfo.data.isCerradoFactEntidad) {
                      tooltip.option({
                        showEvent: null,
                        hideEvent: null,
                      });
                    }

                    cellElement.append(editElement);
                  },
                  allowSearch: false,
                },
                {
                  dataField: "idPedidoCliente",
                  visible: false,
                  allowFiltering: false,
                },
              ],
              //Eventos
              onRowRemoved: function () {
                notify({
                  message: getTrad("alerta_eliminarReparto"),
                  type: "success",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              },
              onRowUpdating: function (option) {
                option.newData = patchRequestHandler(option.newData);
              },
              onToolbarPreparing: function (e) {
                e.toolbarOptions.items.unshift(
                  {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                      text: getTrad("limpiarFiltro"),
                      icon: " icon_EliminarFiltros",
                      onClick: function (e) {
                        $("#dgvRepartos").dxDataGrid("instance").clearFilter();
                      },
                    },
                  },
                  {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                      icon: "refresh",
                      text: getTrad("actualizar"),
                      onClick: function () {
                        e.component.refresh(true);
                      },
                    },
                  },
                );
              },
              //Estilos
              showColumnLines: false,
              showRowLines: true,
              rowAlternationEnabled: true,
            }),
          );
        },
      },
    ],
  });

  if (
    typeof $("#ValidarRepartos #popup_prendas").data("dxPopup") !== "undefined"
  ) {
    $("#ValidarRepartos #popup_prendas").dxPopup("instance").dispose();
  }

  $("#ValidarRepartos #popup_prendas").dxPopup({
    showTitle: true,
    title: getTrad("prendasReparto"),
    height: "80%",
    contentTemplate: function () {
      return $("<div id='dgvPrendas' />").dxDataGrid({
        //Datos
        dataSource: datasource_prendasReparto_peticion.items(),
        remoteOperations: true,
        //Propiedades
        height: "100%",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          allowUpdating: true,
          allowDeleting: true,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        paging: {
          pageSize: 100,
        },
        //Columnas
        columns: [
          {
            dataField: "tblPrenda.codigoPrenda",
            caption: getTrad("codigo"),
            sortOrder: "asc",
            width: "10%",
            alignment: "center",
            allowHeaderFiltering: false,
            allowEditing: false,
            editorOptions: { showClearButton: true },
          },
          {
            dataField: "tblPrenda.denominacion",
            caption: getTrad("denominacion"),
            allowEditing: false,
            editorOptions: { showClearButton: true },
          },
          {
            dataField: "peticion",
            caption: getTrad("peticion"),
            width: "10%",
            alignment: "center",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            calculateCellValue: function (e) {
              var cantidad = typeof (e.cantidad !== "undefined")
                ? e.cantidad
                : 0;

              if (typeof e.tblPrenda !== "undefined") {
                var udsPeticion,
                  resultado = 0;

                //#region Paso la petición y cantidad a uds y al final lo transformo a bacs si es necesario
                if (repartoSel.pedidoProd) {
                  // POR UDS
                  udsPeticion = e.peticion;
                  resultado = udsPeticion - cantidad;
                } else {
                  if (parseInt(e.tblPrenda.tblElemTransPedido.codigo) === 1)
                    // POR UDS
                    udsPeticion = e.peticion;
                  else if (
                    parseInt(e.tblPrenda.tblElemTransPedido.codigo) === 2
                  )
                    // BAC/SACA
                    udsPeticion = e.peticion * e.tblPrenda.udsXBacPedido;

                  if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 1)
                    // POR UDS
                    resultado = udsPeticion - cantidad;
                  else if (
                    parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 2
                  )
                    // BAC/SACA
                    resultado = Math.ceil(
                      (udsPeticion - cantidad * e.tblPrenda.udsXBacReparto) /
                        e.tblPrenda.udsXBacPedido,
                    );
                }
                return resultado >= 0 ? resultado : 0;
                //#endregion
              }
            },
          },
          {
            dataField: "tblPrenda.tblElemTransPedido.codigo",
            caption: getTrad("elementoTransporte_abr"),
            width: "70",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (container, options) {
              var contenedor = $("<div />")
                .addClass("container_spanCentrado")
                .css("padding-left", "10px");

              var svg;
              var htmlTooltip = "";
              if (
                parseInt(options.data.tblPrenda.tblElemTransPedido.codigo) ===
                  1 ||
                repartoSel.pedidoProd
              ) {
                // POR UDS
                svg = svg_elemTrans_ud.clone();
                htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
              } else if (
                parseInt(options.data.tblPrenda.tblElemTransPedido.codigo) === 2
              ) {
                // BAC/SACA
                svg = svg_elemTrans_bac.clone();
                htmlTooltip =
                  "<p>" +
                  getTrad("bacSaca") +
                  ": " +
                  options.data.tblPrenda.udsXBacPedido +
                  "</p>";
              }

              svg
                .css("height", "20px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              tooltipControl_creacion(contenedor, htmlTooltip);

              return contenedor;
            },
          },
          {
            dataField: "cantidad",
            caption: getTrad("cantidad"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "rechazo",
            caption: getTrad("rechazo"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "retiro",
            caption: getTrad("retiro"),
            width: "10%",
            alignment: "center",
            dataType: "number",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
            },
          },
          {
            dataField: "tblPrenda.tblElemTransReparto.codigo",
            caption: getTrad("elementoTransporte_abr"),
            width: "70",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowFiltering: false,
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (container, options) {
              var contenedor = $("<div />")
                .addClass("container_spanCentrado")
                .css("padding-left", "10px");

              var svg;
              var htmlTooltip = "";
              if (
                parseInt(options.data.tblPrenda.tblElemTransReparto.codigo) ===
                  1 ||
                repartoSel.pedidoProd
              ) {
                // POR UDS
                svg = svg_elemTrans_ud.clone();
                htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
              } else if (
                parseInt(options.data.tblPrenda.tblElemTransReparto.codigo) ===
                2
              ) {
                // BAC/SACA
                svg = svg_elemTrans_bac.clone();
                htmlTooltip =
                  "<p>" +
                  getTrad("bacSaca") +
                  ": " +
                  options.data.tblPrenda.udsXBacReparto +
                  "</p>";
              }

              svg
                .css("height", "20px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              tooltipControl_creacion(contenedor, htmlTooltip);

              return contenedor;
            },
          },
          {
            dataField: "total",
            caption: getTrad("total"),
            width: "10%",
            alignment: "center",
            allowEditing: false,
            allowHeaderFiltering: false,
            allowSorting: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            calculateCellValue: function (e) {
              var cantidad = typeof e.cantidad ? e.cantidad : 0,
                rechazo = typeof e.rechazo ? e.rechazo : 0,
                retiro = typeof e.retiro ? e.retiro : 0;

              if (e.tblPrenda) {
                if (repartoSel.pedidoProd)
                  // POR UDS
                  return cantidad + rechazo + retiro;
                else if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 1)
                  // POR UDS
                  return cantidad + rechazo + retiro;
                else if (parseInt(e.tblPrenda.tblElemTransReparto.codigo) === 2)
                  // BAC/SACA
                  return (
                    (cantidad + rechazo + retiro) * e.tblPrenda.udsXBacReparto
                  );
              }
            },
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift({
            location: "after",
            widget: "dxButton",
            showText: "inMenu",
            locateInMenu: "auto",
            options: {
              text: getTrad("limpiarFiltro"),
              icon: " icon_EliminarFiltros",
              onClick: function (e) {
                $("#dgvPrendas").dxDataGrid("instance").clearFilter();
              },
            },
          });

          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.options.onClick = function () {
                var grid = e.component;
                grid.saveEditData().done(function () {
                  notify({
                    message: getTrad("aviso_C_RegistroActualizado"),
                    type: "success",
                    displayTime: "600",
                    closeOnClick: true,
                  });

                  context_tblPrendaNReparto.invoke(
                    "OverrideMasivo",
                    {},
                    "POST",
                  );
                });
              };
            }
          });
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        template: function () {
          var container = $("<div>");
          var title = $(
            "<div style='padding-top: 8px; padding-right:8px; float:left'>" +
              getTrad("pedidoCliente") +
              ":</div>",
          );
          var dxDropDownBox = $(
            "<div id='dxDropDownBox_dgvPrendasReparto_pedidoCliente' style='float:left'>",
          ).dxDropDownBox({
            valueExpr: "idPedido",
            value: repartoSel.idPedidoCliente,
            width: 350,
            deferRendering: false,
            remoteOperations: true,
            placeholder: "Asocia un pedido",
            displayExpr: function (data) {
              return (
                data &&
                data.codigoPedido +
                  " - " +
                  data.denoEntidad +
                  " - " +
                  formatDate(data.fecha)
              );
            },
            showClearButton: true,
            dropDownOptions: {
              title: "Selección de pedido",
              width: 800,
              height: 500,
              position: { my: "center", at: "center", of: window },
              showTitle: true,
              showCloseButton: true,
            },
            dataSource: new DataSource({
              store: new ODataStore({
                url:
                  connectionConstants.ODATA_URL +
                  "repartos_spSelectPedidosCliente",
                key: "idPedido",
                errorHandler: function (error) {
                  errorHandler(error, COMPONENT);
                },
                beforeSend: function (request) {
                  request.headers = { ...authHeader() };

                  request.params.idEntidad = repartoSel.idEntidad;
                },
                version: 4,
              }),
            }),
            contentTemplate: function (dxDropDownBox) {
              var value = dxDropDownBox.component.option("value");
              var dxDataGrid_PedidosSel = $(
                "<div id='dxDataGrid_dgvPrendasReparto_pedidoCliente'>",
              ).dxDataGrid({
                //Datos
                dataSource: dxDropDownBox.component.option("dataSource"),
                height: "100%",
                remoteOperations: true,
                //Propiedades
                columnsAutoWidth: true,
                headerFilter: {
                  visible: true,
                },
                filterRow: {
                  visible: true,
                  applyFilter: "auto",
                },
                filterValue: ["asignadoPedCliente", "=", 0],
                searchPanel: {
                  visible: true,
                  width: 240,
                  placeholder: getTrad("busqueda"),
                },
                paging: {
                  pageSize: 10,
                },
                scrolling: { mode: "infinite" },
                selection: { mode: "single" },
                columns: [
                  {
                    dataField: "codigoPedido",
                    caption: getTrad("codigo"),
                    width: "80px",
                    allowEditing: false,
                    allowHeaderFiltering: false,
                  },
                  {
                    dataField: "fecha",
                    caption: getTrad("fecha"),
                    dataType: "date",
                    format: "dd/MM/yyyy",
                    width: "125px",
                    sortOrder: "desc",
                    editorOptions: {
                      showClearButton: true,
                    },
                    allowHeaderFiltering: false,
                  },

                  {
                    dataField: "denoEntidad",
                    caption: getTrad("cliente"),
                    minWidth: 150,
                    allowEditing: false,
                    allowHeaderFiltering: false,
                  },

                  "observacionesPedido",
                  {
                    dataField: "asignadoPedCliente",
                    visible: false,
                  },
                ],
                selectedRowKeys: [value],
                onSelectionChanged: function (selectedItems) {
                  var keys = selectedItems.selectedRowKeys,
                    hasSelection = keys.length;

                  if (hasSelection && keys[0] !== repartoSel.idPedidoCliente) {
                    dxDropDownBox.component.option("value", keys[0]);
                  }
                },
                onToolbarPreparing: function (option) {
                  option.toolbarOptions.items.unshift(
                    {
                      location: "before",
                      widget: "dxCheckBox",
                      value: false,
                      options: {
                        text: getTrad("mostrarPedidosAsignados"),
                        onValueChanged: function (e) {
                          if (e.value === 0) {
                            option.component.option("filterValue", [
                              "asignadoPedCliente",
                              "=",
                              0,
                            ]);
                          } else {
                            option.component.option("filterValue", []);
                          }
                        },
                      },
                    },
                    {
                      location: "after",
                      widget: "dxButton",
                      showText: "inMenu",
                      options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: function () {
                          option.component.refresh();
                        },
                      },
                    },
                    {
                      location: "after",
                      widget: "dxButton",
                      showText: "inMenu",
                      options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: function () {
                          option.component.refresh(true);
                        },
                      },
                    },
                  );
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              });

              dxDropDownBox.component.on("valueChanged", function (args) {
                if (args.value === repartoSel.idPedidoCliente) {
                  return;
                }

                var dgvRepartos = $("#dgvRepartos").dxDataGrid("instance");
                dgvRepartos.cellValue(
                  dgvRepartos.getRowIndexByKey(repartoSel.idReparto),
                  "idPedidoCliente",
                  args.value,
                );
                dgvRepartos.option("loadPanel.enabled", false);

                dgvRepartos.saveEditData().done(function () {
                  dgvRepartos.option("loadPanel.enabled", true);

                  args.component.close();
                  notify({
                    message: "Se ha modificado el reparto",
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                });
              });

              return dxDataGrid_PedidosSel;
            },
            onOpened: function () {
              var dxDataGrid_dgvPrendasReparto_pedidoCliente = $(
                "#dxDataGrid_dgvPrendasReparto_pedidoCliente",
              ).dxDataGrid("instance");
              dxDataGrid_dgvPrendasReparto_pedidoCliente.option("filterValue", [
                "asignadoPedCliente",
                "=",
                0,
              ]);
              dxDataGrid_dgvPrendasReparto_pedidoCliente.repaint();
            },
          });

          if (repartoSel.pedidoProd) {
            title.appendTo(container);
            dxDropDownBox.appendTo(container);
          }

          return container;
        },
      },
    ],
  });

  function tratarPrendasReparto() {
    var prendas = $.extend(
      true,
      [],
      datasource_prendasReparto_peticion.items(),
    );

    $.each(prendas, function (index, item) {
      if (
        parseInt(item.tblPrenda.tblElemTransReparto.codigo) === 2 &&
        repartoSel.pedidoProd === false
      )
        item.cantidad = item.cantidad * item.tblPrenda.udsXBacReparto;

      delete item.peticion;
      delete item.tblPrenda;
    });

    return prendas;
  }

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === tipoReportSel) {
      if (e.parameter.name === "idReparto") {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  setTimeout(() => {
    var dgvRepartos = $("#dgvRepartos").dxDataGrid("instance");
    if (dgvRepartos) {
      dgvRepartos.repaint();
    }
  }, 50);
  deferred.resolve();
  return deferred.promise();
}
