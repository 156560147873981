import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { loadPanelActions } from "../../../actions";
import notify from "devextreme/ui/notify";

import DataGridPedidosProveedor from "./components/DataGridPedidosProveedor";
import FormPedidoProveedor from "./components/FormPedidoProveedor";
import PageTitle from "layout/AppMain/PageTitle";
import { dxMensajePregunta, getNombreFormulario, getTrad } from "helpers";
import { Button, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem as ToolbarItem_Popup } from "devextreme-react/popup";
import { getClearPedido, payloadBuilder } from "./model";
import { refreshStaticDatasources, datasource_tblAdmPedidoProveedor, empresaPolarierSel } from "../shared/model";
import SelectorEmpresa from "../shared/components/SelectorEmpresa";

const PedidoProveedor = ({ idioma, user, loadPanel_show, loadPanel_hide }) => {
    const heading = getNombreFormulario({ props: { idioma, user } });
    const [pedidoSel, setPedidoSel] = useState({ tblArticuloNAdmPedidoProveedor: [] });
    const [adminItemPopup_visibility, setAdminItemPopup_visibility] = useState(false);
    const [empresaSel, setEmpresaSel] = useState(empresaPolarierSel);
    const formPedidoProveedor_REF = useRef();
    const savedData = useRef();
    const validations = useRef([]);

    useEffect(() => {
        if (empresaSel.idEmpresaPolarier == null) {
            const empresaAux = {
                idEmpresaPolarier: user.tblEmpresaPolarierNUsuario[0]?.idEmpresaPolarier,
                idPais: user.tblEmpresaPolarierNUsuario[0]?.idPais,
            };
            setEmpresaSel(empresaAux);
        }
        return () => {
            validations.current = [];
        };
    }, []);

    useEffect(() => {
        savedData.current = { ...pedidoSel };
    }, [pedidoSel]);

    const openPopup = () => {
        setAdminItemPopup_visibility(true);
    };

    const popup_onHiding = (e) => {
        if (formPedidoProveedor_REF.current.hasEditData(savedData.current)) {
            e.cancel = true;
            dxMensajePregunta("Hay cambios sin guardar que se van a perder. ¿Desea salir sin guardar? ", [
                [
                    getTrad("aceptar"),
                    () => {
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        } else {
            closePopup();
        }
    };

    const closePopup = () => {
        datasource_tblAdmPedidoProveedor.reload();
        loadPanel_hide();
        setAdminItemPopup_visibility(false);
    };

    const getTitle = () => {
        if (pedidoSel.idAdmPedidoProveedor) {
            const string = getTrad("modificandoPedidoProveedor") + " - " + pedidoSel.codigo;
            return string;
        } else {
            return getTrad("nuevoPedidoProveedor");
        }
    };

    const getButtonText = () => {
        if (pedidoSel.idAdmPedidoProveedor) {
            return getTrad("actualizar");
        } else {
            return getTrad("guardar");
        }
    };

    const onContentReady = (e) => {
        e.component.content().addClass("admScrollPopup");
    };

    const selectorEmpresa_onSelectionChanged = (e) => {
        loadPanel_show();
        refreshStaticDatasources(e.selectedItem);
        setEmpresaSel(e.selectedItem);
    };

    return (
        <>
            <>
                <PageTitle
                    heading={heading}
                    postHeading={
                        <SelectorEmpresa value={empresaSel} onSelectionChanged={selectorEmpresa_onSelectionChanged} />
                    }
                />
                <div className="media-body">
                    <Popup
                        showTitle={true}
                        title={getTitle()}
                        visible={adminItemPopup_visibility}
                        showCloseButton={true}
                        closeOnOutsideClick={false}
                        height="90%"
                        onHiding={popup_onHiding}
                        onContentReady={onContentReady}
                    >
                        <ScrollView>
                            <FormPedidoProveedor
                                ref={formPedidoProveedor_REF}
                                pedidoSel={pedidoSel}
                                validations={validations.current}
                                reloadData={() => datasource_tblAdmPedidoProveedor.reload()}
                                closePopup={closePopup}
                            />
                        </ScrollView>
                        <ToolbarItem_Popup toolbar="bottom" location="after">
                            <Button
                                text={getButtonText()}
                                type="success"
                                onClick={formPedidoProveedor_REF.current?.onSubmit_FormPedidoProv}
                                useSubmitBehavior={false}
                                disabled={pedidoSel.isCerrado}
                            />
                        </ToolbarItem_Popup>
                    </Popup>
                    <div className={"formContainer scrollbar-container"}>
                        <DataGridPedidosProveedor
                            datasource={datasource_tblAdmPedidoProveedor}
                            setPedidoSel={setPedidoSel}
                            openPopup={openPopup}
                            reloadData={() => datasource_tblAdmPedidoProveedor.reload()}
                            idEmpresaPolarier={empresaSel.idEmpresaPolarier}
                            loadPanel_hide={loadPanel_hide}
                        />
                    </div>
                </div>
            </>
        </>
    );
};

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PedidoProveedor);
