import React, { Component } from "react";
import { connect } from "react-redux";

import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "../../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  patchRequestHandler,
} from "../../../../../helpers";

import DataGrid, {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";

import {
  editCellComponent,
  removeCellComponent,
} from "../../../../../components/DataGrid/Cells";

import { Switch } from "devextreme-react";

class GridRecursosEnergeticos extends Component {
  constructor(props) {
    super(props);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.editCellRender_activo = this.editCellRender_activo.bind(this);
  }

  datasource = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecursoContador",
      key: "idRecursoContador",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      onLoading: (loadOptions) => this.datasource_onLoading(loadOptions),
      version: 4,
    }),
    expand: [
      "tblRecursoVirtual_CalculoidRecursoVirtualNavigation($select=idRecursoContador, operando)",
      "idUnidadMedida_ContadorNavigation",
      "idUnidadMedida_KpiNavigation",
      "idGrupoEnergeticoNavigation",
      "idCategoriaRecursoNavigation",
    ],
  });

  datasource_onLoading = (loadOptions) => {
    const { lavanderia } = this.props;
    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          ["idLavanderia", "=", lavanderia.idLavanderia],
          "and",
          ["eliminado", "=", false],
        ],
      ];
    else
      loadOptions.filter = [
        ["idLavanderia", "=", lavanderia.idLavanderia],
        "and",
        ["eliminado", "=", false],
      ];
  };

  render() {
    const { tblCategoriaRecurso, tblUnidadMedida } = this.props;

    return (
      <DataGrid
        dataSource={this.datasource}
        columnsAutoWidth={true}
        hoverStateEnabled={true}
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        height={"100%"}
        width={"100%"}
        className={"pt-2"}
        grouping={this.grouping_options}
        onEditingStart={this.onEditingStart}
        onRowPrepared={this.onRowPrepared}
        onRowUpdating={this.onRowUpdating}
        onRowRemoved={this.onRowRemoved}
        onToolbarPreparing={this.onToolbarPreparing}
      >
        <SearchPanel visible={true} width={240} />
        <HeaderFilter visible={false} />
        <FilterRow
          visible={true}
          showAllText={getTrad("todos").toUpperCase()}
        />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={this.dataGrid_allowedPageSizes}
          showInfo={true}
        />
        <Paging defaultPageSize={50} />
        <Editing mode="cell" allowUpdating={true} />
        <Column
          type="groupExpand"
          cssClass="p-0"
          width={35}
          cellRender={this.cellRender_groupExpand}
        />
        <Column
          dataField={"idGrupoEnergeticoNavigation.denominacion"}
          caption={""}
          width={0}
          groupIndex={0}
          groupCellRender={this.groupCellRender_idGrupoEnergetico}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={editCellComponent}
        />
        <Column
          dataField={"sumaInforme"}
          sortOrder={"desc"}
          sortIndex={0}
          visible={false}
        />
        <Column
          dataField={"denominacion"}
          caption={getTrad("denominacion")}
          sortOrder={"asc"}
          sortIndex={1}
          allowEditing={false}
        />
        <Column
          dataField={"idCategoriaRecursoNavigation.denominacion"}
          caption={getTrad("categoria")}
          allowHeaderFiltering={false}
          alignment="center"
          width={110}
          dataType={"string"}
          allowEditing={false}
        >
          <Lookup
            dataSource={tblCategoriaRecurso}
            valueExpr={"denominacion"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          dataField={"factorNormalizacion"}
          caption={"Factor norm."}
          alignment="center"
          width={110}
          dataType={"number"}
          allowEditing={false}
        />
        <Column
          dataField={"poderCalorifico"}
          caption={"Poder calorífico"}
          alignment="center"
          width={110}
          dataType={"number"}
          allowEditing={false}
        />
        <Column
          dataField={"margen"}
          caption={getTrad("margen")}
          alignment="center"
          width={110}
          dataType={"number"}
          allowEditing={false}
        />
        <Column
          dataField={"densidad"}
          caption={"Densidad"}
          alignment="center"
          width={110}
          dataType={"number"}
          allowEditing={false}
        />
        <Column
          dataField={"idUnidadMedida_ContadorNavigation.denominacion"}
          caption={"U. contador"}
          alignment="center"
          width={100}
          dataType={"string"}
          allowEditing={false}
        >
          <Lookup
            dataSource={tblUnidadMedida}
            valueExpr={"denominacion"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          dataField={"idUnidadMedida_KpiNavigation.denominacion"}
          caption={"U. KPI"}
          alignment="center"
          width={100}
          dataType={"string"}
          allowEditing={false}
        >
          <Lookup
            dataSource={tblUnidadMedida}
            valueExpr={"denominacion"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          dataField={"isVirtual"}
          caption={getTrad("tipo")}
          alignment="center"
          width={95}
          dataType={"boolean"}
          allowEditing={false}
        >
          <Lookup
            dataSource={this.lookup_options_isVirtual}
            valueExpr={"isVirtual"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          dataField={"isAutomatico"}
          caption={"Automático / Manual"}
          alignment="center"
          width={160}
          dataType={"boolean"}
          allowEditing={false}
        >
          <Lookup
            dataSource={this.lookup_options_isAutomatico}
            valueExpr={"isAutomatico"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          dataField={"activo"}
          caption={getTrad("activo")}
          cssClass="p-0"
          alignment="center"
          width={135}
          dataType={"boolean"}
          editCellRender={this.editCellRender_activo}
          showEditorAlways={true}
        >
          <Lookup
            dataSource={this.lookup_options_activo}
            valueExpr={"activo"}
            displayExpr={"denominacion"}
          />
        </Column>
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
        <Column dataField={"valorPulsoEnergyHub"} visible={false} />
      </DataGrid>
    );
  }

  dataGrid_allowedPageSizes = [20, 50, 100];
  grouping_options = { allowCollapsing: true };

  lookup_options_sumaInforme = [
    { sumaInforme: true, denominacion: "Suma en informe".toUpperCase() },
    { sumaInforme: false, denominacion: "No suma en informe".toUpperCase() },
  ];

  lookup_options_activo = [
    { activo: true, denominacion: getTrad("activo").toUpperCase() },
    { activo: false, denominacion: getTrad("noActivo").toUpperCase() },
  ];

  lookup_options_isVirtual = [
    { isVirtual: true, denominacion: "Virtual".toUpperCase() },
    { isVirtual: false, denominacion: "Físico".toUpperCase() },
  ];

  lookup_options_isAutomatico = [
    { isAutomatico: true, denominacion: "Automático".toUpperCase() },
    { isAutomatico: false, denominacion: "Manual".toUpperCase() },
  ];

  cellRender_groupExpand = (e) => {
    if (e.rowType == "group") {
      const idGrupoEnergetico = e.data.items[0]?.idGrupoEnergetico;

      return (
        <div
          className={
            "pl-2 font-size-xl white " +
            (idGrupoEnergetico === 1
              ? "icon_Agua"
              : idGrupoEnergetico === 2
                ? "icon_Electricidad"
                : "icon_Calefaccion")
          }
        />
      );
    } else if (e.rowType == "data" && e.data.sumaInforme === true) {
      return (
        <div className="pl-3 font-size-lg dx-icon dx-icon-favorites primary"></div>
      );
    }
  };

  groupCellRender_idGrupoEnergetico = (data) => <div>{data.value}</div>;

  editCellRender_activo = (cell) => {
    const onValueChanged = this.editCellRender_activo_onValueChanged.bind(
      this,
      cell,
    );
    return (
      <Switch
        switchedOnText={getTrad("activo")}
        switchedOffText={getTrad("noActivo")}
        height={"100%"}
        width={120}
        defaultValue={cell.value}
        onValueChanged={onValueChanged}
      />
    );
  };

  editCellRender_activo_onValueChanged(cell, e) {
    cell.setValue(e.value);
  }

  onEditingStart = (e) => {
    if (e.column?.dataField === "activo") {
      return;
    }

    e.cancel = true;
    this.props.startEdit_recursoContador({ ...e.data });
  };

  onRowPrepared = (e) => {
    if (e.rowType === "group") {
      const idGrupoEnergetico = e.data.items[0]?.idGrupoEnergetico;
      const bgColor =
        idGrupoEnergetico === 1
          ? "var(--blue)"
          : idGrupoEnergetico === 2
            ? "var(--primary)"
            : "var(--orange)";

      e.rowElement.css("backgroundColor", bgColor);
      e.rowElement.css("color", "white");
    }

    if (e.rowType === "data") {
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  };

  onRowUpdating = (option) => {
    option.newData = patchRequestHandler(option.newData);
  };

  onRowRemoved = () => {
    notify({
      message: getTrad("aviso_C_RegistroEliminado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          text: getTrad("añadir"),
          icon: "plus",
          onClick: () => this.props.startEdit_recursoContador({}),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: (args) => this.onClick_limpiarFiltros(args, e),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          text: getTrad("actualizar"),
          icon: "refresh",
          onClick: (args) => this.onClick_refresh(args, e),
        },
      },
    );
  };

  onClick_limpiarFiltros = (args, e) => {
    e.component.clearFilter();

    notify({
      message: getTrad("aviso_C_FiltroRestaurado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  onClick_refresh = (args, e) => {
    this.datasource.reload();
  };

  componentDidUpdate(prevProps) {
    const { lavanderia } = this.props;

    if (prevProps.lavanderia.idLavanderia !== lavanderia.idLavanderia) {
      this.datasource.reload();
    }
  }
}
const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  GridRecursosEnergeticos,
);
