export function get_resolucion(screen) {
  return screen.width >= 1200
    ? "xl"
    : screen.width >= 992
      ? "lg"
      : screen.width >= 768
        ? "md"
        : screen.width >= 576
          ? "sm"
          : "xs";
}
