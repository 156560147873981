import React, { useContext, useEffect, useState } from "react";
import { ControlPresupuestarioCxt } from "../../contexts/context";
import { formatNumber } from "helpers";
import Loader from "react-loaders";
import LottieIcon from "components/LottieIcon";

function ResumenCard({ title, icon, value, placeholder, color, isLoading }) {
    const context = useContext(ControlPresupuestarioCxt);
    const [valor, setValor] = useState(value);
    const [moneda, setMoneda] = useState(context.moneda.codigo);
    const [lottieKey, setLottieKey] = useState(`lottieKey-${Date.now()}-${icon}`);

    useEffect(() => {
        setValor(value);
        if (context.moneda.codigo !== moneda && (value != valor || value === 0)) {
            setMoneda(context.moneda.codigo);
        }
    }, [value, context.moneda.codigo]);

    useEffect(() => {
        if (!isLoading) {
            setLottieKey(`lottieKey-${Date.now()}-${icon}`);
        }
    }, [isLoading]);

    return (
        <div className="w-100 he-100 card border-radius resumenCard">
            <div className="d-flex flex-row he-100 position-relative">
                <div key={lottieKey}>
                    <LottieIcon icon={icon} isLoop={false} />
                </div>
                <div className="flex-1 d-flex flex-column my-2">
                    <span className="resumenCard-title">{title}</span>
                    <div className="resumenCard-body flex-1">
                        {isLoading ? (
                            <Loader type="line-scale" />
                        ) : (
                            <span style={{ color: color }}>
                                {(valor != null && moneda != null
                                    ? formatNumber(valor, 2, "currency", moneda)
                                    : null) ?? placeholder}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResumenCard;
