import { Popup } from "devextreme-react";
import React, { Component } from "react";

import SelectorContadores from "./SelectorContadores";

class PopupExportar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { visible, onHiding } = this.props;

    return (
      <Popup
        visible={visible}
        onHiding={onHiding}
        title={"Exportar contadores"}
        width={550}
        height={"90%"}
      >
        <SelectorContadores onHiding={onHiding} visible={visible} />
      </Popup>
    );
  }
}

export default PopupExportar;
