import React from "react";
import { connect } from "react-redux";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import { connectionConstants } from "../../../../constants";
import { errorHandler, authHeader } from "../../../../helpers";
import query from "devextreme/data/query";
import { loadPanelActions } from "actions";
import LottieIcon from "components/LottieIcon";
import { personaActions } from "actions/fotos";
import { signalRCoreService } from "services";

export const SVContext = React.createContext();

const minsEsperaInicioCliente = 1;
const minsProduccionActual = 5;

const minRefreshTime = 60 * 1000;

class SmartViewContext extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tblPosicion: [],
            tblClienteNMaquina: [],
            datosProduccion: [],
            tblPersonas: [],
            datosMaquinas: [],
            tblEstadoSmartHubNMaquina: [],
            tblLavanderiaPrendasHora: [],
            layoutPages: [],
            tooltipData: {},
            paginado: props.paginado ? props.paginado : false,
            setMaquinaSel: props.setMaquinaSel,
            setTooltipData: this.setTooltipData,
        };

        this.refresh_interval = setInterval(this.recargarSources, minRefreshTime);
        this.produccion_interval = setInterval(this.calcularDatosMaquinas, 1000);
    }

    // #region Componente

    componentDidMount() {
        this.props.loadPanel_show();

        this.createSignalRConnection();
        this.recargarSources();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lavanderia.idLavanderia !== this.props.lavanderia.idLavanderia) {
            this.props.loadPanel_show();

            this.createSignalRConnection();

            this.recargarSources();
        }
    }

    componentWillUnmount() {
        clearInterval(this.refresh_interval);
        clearInterval(this.produccion_interval);
        signalRCoreService.leaveGroup("SmartHUB_" + this.props.lavanderia.idLavanderia);
    }

    // #endregion

    // #region Context

    setTooltipData = (target, content) => {
        this.setState({
            tooltipData: {
                target: target,
                content: content,
            },
        });
    };

    // #endregion

    // #region Datasources y contextos

    beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblLayout_SmartView = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLayout_SmartView",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblLayout_SmartView_onLoading(loadOptions),
            version: 4,
        }),
        select: ["idLavanderia", "idMaquina", "idAreaLavanderia", "x", "y", "width", "height"],
        sort: ["idAreaLavanderia", "idMaquinaNavigation/denominacion"],
    });

    datasource_tblLayout_SmartView_onLoading = (loadOptions) => {
        let { lavanderia } = this.props;

        loadOptions.filter = ["idLavanderia", "=", lavanderia.idLavanderia];
    };

    datasource_tblPosicion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPosicionNAreaLavanderiaNLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.beforeSend(request),
            onLoading: (request) => this.datasource_tblPosicion_onLoading(request),
            version: 4,
        }),
        select: ["idPosicionNAreaLavanderiaNLavanderia", "denominacion", "idLavanderia", "idAreaLavanderia", "numPos"],
        expand: [
            "idMaquinaNavigation($select=idMaquina,denominacion, posicionVia1Izquierda;$expand=idTipoMaquinaNCategoriaMaquinaNavigation($select=idTipoMaquina))",
            "idAreaLavanderiaNavigation($select=denominacion)",
        ],
        map: (item) => this.datasource_tblPosicion_map(item),
        sort: [
            "idMaquinaNavigation.idTipoMaquinaNCategoriaMaquinaNavigation.idTipoMaquina",
            "idMaquinaNavigation.denominacion",
        ],
    });

    datasource_tblPosicion_onLoading = (loadOptions) => {
        let { lavanderia } = this.props;

        loadOptions.filter = [["activo", "=", true], "and", ["idLavanderia", "=", lavanderia.idLavanderia]];
    };

    datasource_tblPosicion_map = (item) => {
        let newItem = {
            idPosicion: item.idPosicionNAreaLavanderiaNLavanderia,
            idAreaLavanderia: item.idAreaLavanderia,
            areaLabel: item.idAreaLavanderiaNavigation.denominacion,
            idLavanderia: item.idLavanderia,
            numPos: item.numPos,
            denominacion: item.denominacion,
        };

        const tiposMaquina = [1, 34];
        if (
            item.idMaquinaNavigation &&
            tiposMaquina.includes(item.idMaquinaNavigation.idTipoMaquinaNCategoriaMaquinaNavigation?.idTipoMaquina)
        ) {
            newItem.maquina = {
                idMaquina: item.idMaquinaNavigation.idMaquina,
                denominacion: item.idMaquinaNavigation.denominacion,
                idTipoMaquina: item.idMaquinaNavigation.idTipoMaquinaNCategoriaMaquinaNavigation?.idTipoMaquina,
                posicionVia1Izquierda: item.idMaquinaNavigation.posicionVia1Izquierda,
            };
        }

        return newItem;
    };

    context_spSelectPersonas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/spSelectPersonas",
        entities: {
            spSelectPersonas: {},
        },
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            this.beforeSend(request);
        },
    });

    context_spSelectPersonas_load = () => {
        let { lavanderia } = this.props;
        const params = { idLavanderia: lavanderia.idLavanderia };

        return new Promise((cb) => {
            this.context_spSelectPersonas.invoke("", params, "GET").then((responsePersonas) => {
                const { fotosPerfil, loadFotoPerfilMasivo } = this.props;
                const idsPersona = responsePersonas.map((x) => x.idPersona).filter((id) => fotosPerfil[id] == null);
                loadFotoPerfilMasivo(idsPersona);
                cb(responsePersonas);
            });
        });
    };

    datasource_tblClienteNMaquina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblClienteNMaquina",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.beforeSend(request),
            onLoading: (request) => this.datasource_tblClienteNMaquina_onLoading(request),
            version: 4,
            deserializeDates: false,
        }),
        select: ["idMaquina", "idCompañia", "idEntidad", "idFamilia", "idTipoPrenda", "fechaIni"],
        expand: [
            "idEntidadNavigation($select=denominacion)",
            "idCompañiaNavigation($select=denominacion)",
            "idFamiliaNavigation($select=denominacion)",
            "idTipoPrendaNavigation($select=denominacion)",
        ],
        map: (item) => this.datasource_tblClienteNMaquina_map(item),
    });

    datasource_tblClienteNMaquina_onLoading = (loadOptions) => {
        let { lavanderia } = this.props;

        loadOptions.filter = [
            ["fechaFin", "=", null],
            "and",
            ["idMaquinaNavigation/idLavanderia", "=", lavanderia.idLavanderia],
        ];
    };

    datasource_tblClienteNMaquina_map = (item) => {
        item.fechaIni = new Date(item.fechaIni);
        if (item.idCompañia) {
            item.denominacion = item.idCompañiaNavigation.denominacion;
        } else {
            item.denominacion = item.idEntidadNavigation.denominacion;
        }
        if (item.idTipoPrenda) {
            item.programa = item.idTipoPrendaNavigation.denominacion;
        } else {
            item.programa = item.idFamiliaNavigation.denominacion;
        }
        delete item.idCompañiaNavigation;
        delete item.idEntidadNavigation;
        delete item.idTipoPrendaNavigation;
        delete item.idFamiliaNavigation;
        return item;
    };

    datasource_tblPrendasHora = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendasHora",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_tblPrendasHora_beforeSend(request),
            version: 4,
        }),
        select: ["idMaquina", "idFamilia", "idTipoPrenda", "prendasHora"],
    });

    datasource_tblPrendasHora_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        request.params = {
            idLavanderia: this.props.lavanderia.idLavanderia,
        };
    };

    datasource_tblEstadoSmartHubNMaquina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoSmartHubNMaquina",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_tblEstadoSmartHubNMaquina_beforeSend(request),
            version: 4,
        }),
        select: ["idMaquina", "fechaInicio", "fechaUltimaActualizacion", "idEstadoSmartHub"],
    });

    datasource_tblEstadoSmartHubNMaquina_beforeSend = (request) => {
        let { lavanderia } = this.props;

        request.params = {
            idLavanderia: lavanderia.idLavanderia,
        };
    };

    datasource_DatosProduccion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/DatosProduccion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_DatosProduccion_beforeSend(request),
            deserializeDates: false,
            version: 4,
        }),
        map: (item) => this.datasource_DatosProduccion_map(item),
    });

    datasource_DatosProduccion_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        let { lavanderia } = this.props;
        request.params = {
            idLavanderia: lavanderia.idLavanderia,
            minsEsperaInicio: minsEsperaInicioCliente,
            minsProduccionActual: minsProduccionActual,
        };
    };

    datasource_DatosProduccion_map = (item) => {
        item.fechaLavanderia = new Date(item.fechaLavanderia);
        return item;
    };

    // #endregion

    render() {
        const { tblPosicion, layoutPages, tooltipData } = this.state;
        const { children } = this.props;

        return (
            <div className="he-100">
                {tblPosicion.length > 0 && layoutPages.at(0)?.length > 0 ? (
                    <SVContext.Provider value={this.state}>{children}</SVContext.Provider>
                ) : (
                    <div className="container_spanCentrado h-100 w-75">
                        <LottieIcon height="250px" width="250px" icon={"warning"} />
                        <div>
                            <span className="display-4 p-5">Esta lavandería no ha sido configurada</span>
                            <hr />
                            <ul>
                                {tblPosicion.length === 0 && <li>No se ha encontrado estructura operativa</li>}
                                {layoutPages.at(0)?.length === 0 && <li>No se ha encontrado el layout</li>}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    createSignalRConnection = () => {
        let { lavanderia } = this.props;

        let _this = this;
        signalRCoreService.joinGroup("SmartHUB_" + lavanderia.idLavanderia, [
            {
                type: "SmartView/signalR_refresh",
                listener: function (srcs) {
                    _this.onSignalR_refresh(srcs);
                },
            },
        ]);
    };

    onSignalR_refresh = (srcs) => {
        srcs?.forEach((src) => {
            if (src === "PersonalActivo") {
                this.context_spSelectPersonas_load().then((tblPersonas) => {
                    if (this.props.onRefreshPersonalActivo != null) {
                        this.props.onRefreshPersonalActivo(tblPersonas);
                    }
                    this.setState({ tblPersonas: tblPersonas });
                });
            } else if (src === "tblClienteNMaquina") {
                this.datasource_tblClienteNMaquina.reload().then((tblClienteNMaquina) => {
                    this.setState({ tblClienteNMaquina: tblClienteNMaquina });
                });
            } else if (src === "tblEstadoSmartHubNMaquina") {
                this.datasource_tblEstadoSmartHubNMaquina.reload().then((tblEstadoSmartHubNMaquina) => {
                    this.setState({
                        tblEstadoSmartHubNMaquina: tblEstadoSmartHubNMaquina,
                    });
                });
            }
        });
    };

    recargarSources = () => {
        Promise.all([
            this.datasource_tblPosicion.reload(),
            this.datasource_tblClienteNMaquina.reload(),
            this.datasource_tblPrendasHora.reload(),
            this.datasource_tblEstadoSmartHubNMaquina.reload(),
            this.context_spSelectPersonas_load(),
            this.datasource_DatosProduccion.load(),
            this.datasource_tblLayout_SmartView.reload(),
        ]).then(
            ([
                tblPosicion,
                tblClienteNMaquina,
                tblLavanderiaPrendasHora,
                tblEstadoSmartHubNMaquina,
                tblPersonas,
                datosProduccion,
                tblLayout_SmartView,
            ]) => {
                let layoutSmartView = tblLayout_SmartView.map((x) => {
                    return {
                        i: `layout-key-${x.idMaquina ? x.idMaquina : x.idAreaLavanderia}`,
                        x: x.x,
                        y: x.y,
                        w: x.width,
                        h: x.height,
                    };
                });

                let layoutPages;
                if (this.props.paginado) {
                    layoutPages = [];
                    let layoutGrouped = query(layoutSmartView)
                        .groupBy("y")
                        .toArray()
                        .sort((a, b) => a.key - b.key);
                    for (let i = 0; i < layoutGrouped.length; i = i + 2) {
                        let page = layoutGrouped[i + 1]
                            ? layoutGrouped[i].items.concat(layoutGrouped[i + 1]?.items)
                            : layoutGrouped[i].items;
                        page.forEach((item) => {});
                        layoutPages.push(page);
                    }
                } else {
                    layoutPages = [layoutSmartView];
                }

                this.setState({
                    tblPosicion: tblPosicion,
                    tblClienteNMaquina: tblClienteNMaquina,
                    tblLavanderiaPrendasHora: tblLavanderiaPrendasHora,
                    datosProduccion: datosProduccion,
                    tblPersonas: tblPersonas,
                    tblEstadoSmartHubNMaquina: tblEstadoSmartHubNMaquina,
                    layoutPages: layoutPages,
                });

                if (this.props.onRefreshPersonalActivo != null) {
                    this.props.onRefreshPersonalActivo(tblPersonas);
                }
                this.props.loadPanel_hide();
            }
        );
    };

    calcularDatosMaquinas = () => {
        const { tblPosicion, tblClienteNMaquina, tblLavanderiaPrendasHora } = this.state;

        this.datasource_DatosProduccion.reload().then(([result]) => {
            let datosMaquinas = [];

            let { fechaLavanderia, datosProduccion } = result;

            const maquinas = query(tblPosicion.filter((x) => x.maquina != null))
                .groupBy((x) => x.maquina.idMaquina)
                .toArray();
            maquinas.forEach((maquina) => {
                let cliente = tblClienteNMaquina.find((x) => x.idMaquina === maquina.key);
                let prendasHora = tblLavanderiaPrendasHora.find(
                    (x) =>
                        x.idMaquina === maquina.key &&
                        x.idFamilia === cliente?.idFamilia &&
                        x.idTipoPrenda === cliente?.idTipoPrenda
                )?.prendasHora;
                let produccion = datosProduccion.find((x) => x.idMaquina === maquina.key);

                let prendasHoraActual = null;
                let ptgActual = null;
                let ptgCliente = null;

                if (cliente && produccion) {
                    const segsClienteActivo = (fechaLavanderia.getTime() - cliente.fechaIni.getTime()) / 1000;

                    if (segsClienteActivo > minsEsperaInicioCliente * 60) {
                        const segsProduccionActual =
                            segsClienteActivo < minsProduccionActual * 60
                                ? segsClienteActivo
                                : minsProduccionActual * 60;
                        prendasHoraActual = ((produccion.prendasActuales / segsProduccionActual) * 3600).toFixed(0);
                        ptgActual = produccion.prendasActuales / segsProduccionActual / (prendasHora / 3600);
                        ptgCliente = produccion.prendasCliente / segsClienteActivo / (prendasHora / 3600);
                    } else {
                        ptgActual = -1;
                        ptgCliente = -1;
                    }

                    datosMaquinas.push({
                        idMaquina: maquina.key,
                        prendasHoraActual: prendasHoraActual,
                        ptgActual: ptgActual,
                        ptgCliente: ptgCliente,
                        prendasHora: prendasHora,
                        prendasActuales: produccion.prendasActuales,
                        prendasCliente: produccion.prendasCliente,
                    });
                }
            });

            this.setState({
                fechaLavanderia: fechaLavanderia,
                datosMaquinas: datosMaquinas,
                atosProduccion: datosProduccion,
            });
        });
    };
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    fotosPerfil: state.fotos.fotosPerfil,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
    loadFotoPerfilMasivo: (idsPersona) => dispatch(personaActions.loadFotoPerfilMasivo(idsPersona)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SmartViewContext);
