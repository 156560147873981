import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  formatNumber,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import ODataContext from "devextreme/data/odata/context";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ArrayStore from "devextreme/data/array_store";
import { Button } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
  Column,
  MasterDetail,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  KeyboardNavigation,
  Pager,
  Paging,
  Editing,
  Lookup,
  Grouping,
  ColumnChooser,
} from "devextreme-react/data-grid";
import {
  Form,
  Item,
  EmptyItem,
  RequiredRule,
  AsyncRule,
  TabbedItem,
  CustomRule,
  Tab,
  ButtonItem,
} from "devextreme-react/form";
import { Row, Col } from "reactstrap";

//CUSTOM COMPONENTS
import { removeCellComponent } from "../../../components/DataGrid/Cells";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
//Css
import "./Css.scss";

class Recambios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recambioSel: null,
      recambioSel_bak: null,
      is_switchStock: true,
      showPopup_Add_tblRecambioNProveedor: false,
      tblProveedor_items: [],
      tblPais_items: [],
    };

    // REFERENCIAS
    this.dxDataGrid_tblRecambio_REF = React.createRef();
    this.dxDataGrid_tblRecambioNProveedor_REF = React.createRef();
    this.dxForm_tblRecambio_REF = React.createRef();
    this.dxForm_addProveedor_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblRecambio_onInserted =
      this.datasource_tblRecambio_onInserted.bind(this);
    this.datasource_tblRecambio_onUpdated =
      this.datasource_tblRecambio_onUpdated.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //EVENTOS
    this.onSelectionChanged_tblRecambio =
      this.onSelectionChanged_tblRecambio.bind(this);
    this.onToolbarPreparing_tblRecambio =
      this.onToolbarPreparing_tblRecambio.bind(this);
    this.onRowRemoving_tblRecambio = this.onRowRemoving_tblRecambio.bind(this);
    this.onRowRemoved_tblRecambio = this.onRowRemoved_tblRecambio.bind(this);
    this.render_tblRecambioNProveedor =
      this.render_tblRecambioNProveedor.bind(this);
    this.dxButtonItem_addProveedor_onClick =
      this.dxButtonItem_addProveedor_onClick.bind(this);
    this.hiding_popup_Add_tblRecambioNProveedor =
      this.hiding_popup_Add_tblRecambioNProveedor.bind(this);
    this.onInitNewRow_tblRecambioNProveedor =
      this.onInitNewRow_tblRecambioNProveedor.bind(this);
    this.datasource_tblRecambio_postProcess =
      this.datasource_tblRecambio_postProcess.bind(this);
    this.onToolbarPreparing_tblRecambioNProveedor =
      this.onToolbarPreparing_tblRecambioNProveedor.bind(this);
    this.dxDataGrid_tblRecambioNProveedor_añadir_onClick =
      this.dxDataGrid_tblRecambioNProveedor_añadir_onClick.bind(this);
    this.validateAsync_referencia = this.validateAsync_referencia.bind(this);
    this.validateAsync_referenciaInterna =
      this.validateAsync_referenciaInterna.bind(this);
    this.context_CheckReferencia_beforeSend =
      this.context_CheckReferencia_beforeSend.bind(this);
    this.context_CheckReferenciaInterna_beforeSend =
      this.context_CheckReferenciaInterna_beforeSend.bind(this);
    this.onValueChanged_Switch_conStock =
      this.onValueChanged_Switch_conStock.bind(this);
    this.dxLookupPais_onSelectionChanged =
      this.dxLookupPais_onSelectionChanged.bind(this);

    //METODOS
    this.crearRecambio = this.crearRecambio.bind(this);
    this.submit_formRecambio = this.submit_formRecambio.bind(this);
    this.initReset_addProveedor_formData =
      this.initReset_addProveedor_formData.bind(this);
  }

  //REFERENCIAS
  get dxDataGrid_tblRecambio() {
    return this.dxDataGrid_tblRecambio_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNProveedor() {
    return this.dxDataGrid_tblRecambioNProveedor_REF.current.instance;
  }

  get dxForm_tblRecambio() {
    return this.dxForm_tblRecambio_REF.current.instance;
  }

  get dxForm_addProveedor() {
    return this.dxForm_addProveedor_REF.current.instance;
  }

  //#region DATA SOURCE
  datasource_tblProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblProveedor",
      key: "idProveedor",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    sort: ["nombreComercial"],
  });

  datasource_tblAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
    }),
    filter: ["activo eq true and eliminado eq false"],
    select: ["idAlmacen,denominacion,idAlmacenPadre"],
    sort: ["denominacion"],
    expand: ["tblAlmacenHijo($select=idAlmacen,denominacion)"],
  });

  datasource_tblRecambio = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onInserted: (values, key) => {
        this.datasource_tblRecambio_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblRecambio_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_tblRecambio_postProcess(data);
    },
    expand: [
      "tblProveedor($select=nombreComercial),tblRecambioNProveedor($expand=tblProveedor($select=nombreComercial),tblPais($select=idPais,denominacion;$expand=tblMoneda($select=codigo,denominacion))) \
        ,tblRecambioNAlmacenRecambios( \
            $select=idRecambio,cantidad,idAlmacen,ubicacion; \
            $expand=tblAlmacenRecambios( \
                $select=idAlmacen,denominacion,idAlmacenPadre; \
                $expand=tblAlmacenHijo($top=0) \
                ))",
    ],
  });

  datasource_tblRecambio_postProcess(data) {
    let ids_almacenesConsulta = $.grep(
      this.datasource_tblAlmacenRecambiosNPersona.items(),
      function (item) {
        return item.isConsulta;
      },
    );
    let ids_almacenesNPersona = $.map(ids_almacenesConsulta, function (item) {
      return item.idAlmacen;
    });

    let recambios = data;
    let _this = this;

    let { user } = this.props;
    let is_userMasterDev = user.idCargo === 1 || user.idCargo === 2;

    $.each(recambios, function (index, item) {
      //generar padres a partir de hijos

      $.each(item.tblRecambioNAlmacenRecambios, function (indexRec, itemRec) {
        if (itemRec.tblAlmacenRecambios.idAlmacenPadre !== null) {
          // es un hijo
          let idAlmacenPadre = itemRec.tblAlmacenRecambios.idAlmacenPadre;
          let almPadre = $.grep(
            _this.datasource_tblAlmacenRecambios.items(),
            function (item) {
              return item.idAlmacen === idAlmacenPadre;
            },
          );
          let is_almPadre =
            $.grep(item.tblRecambioNAlmacenRecambios, function (item) {
              return item.idAlmacen === idAlmacenPadre;
            }).length > 0;

          let tblAlmacenHijo = {
            visible: true,
            denominacion: itemRec.tblAlmacenRecambios.denominacion,
            idAlmacen: itemRec.tblAlmacenRecambios.idAlmacen,
            tblRecambioNAlmacenRecambios: [
              {
                cantidad: itemRec.cantidad,
                idAlmacen: itemRec.idAlmacen,
              },
            ],
          };

          //Si el padre no existe
          if (!is_almPadre) {
            item.tblRecambioNAlmacenRecambios.push({
              cantidad: 0,
              idAlmacen: idAlmacenPadre,
              idRecambio: item.idRecambio,
              visible: true,
              tblAlmacenRecambios: {
                denominacion: almPadre[0].denominacion,
                idAlmacen: idAlmacenPadre,
                idAlmacenPadre: null,
                visible: true,
                tblAlmacenHijo: [tblAlmacenHijo],
              },
            });
          } else {
            //Si el padre existe añade el sub almacen
            let index_almacen = -1;
            $.each(item.tblRecambioNAlmacenRecambios, function (index, item) {
              if (item.idAlmacen === idAlmacenPadre) index_almacen = index;
            });
            item.tblRecambioNAlmacenRecambios[
              index_almacen
            ].tblAlmacenRecambios.tblAlmacenHijo.push(tblAlmacenHijo);
          }
        }
      });

      $.each(item.tblRecambioNAlmacenRecambios, function (indexRec, itemRec) {
        let sumSubAlm = [],
          cantidadTotal = itemRec.cantidad,
          cantidadSecundarios = 0;

        if (
          itemRec.tblAlmacenRecambios.idAlmacenPadre === null &&
          itemRec.tblAlmacenRecambios.tblAlmacenHijo.length > 0
        ) {
          $.each(
            itemRec.tblAlmacenRecambios.tblAlmacenHijo,
            function (indexAlmHijo, itemAlmHijo) {
              itemAlmHijo.visible = true;

              $.each(
                itemAlmHijo.tblRecambioNAlmacenRecambios,
                function (iRecambioHijo, itemRecambioHijo) {
                  let subAlm = {
                    idAlmacenPadre: itemRec.tblAlmacenRecambios.idAlmacen,
                    idRecambio: itemRec.idRecambio,
                    cantidad: itemRecambioHijo.cantidad,
                  };

                  if (itemRecambioHijo.cantidad > 0) sumSubAlm.push(subAlm);
                },
              );
            },
          );

          $.each(sumSubAlm, function (subIndex, subItem) {
            // Para Sumar a la cantidad del almacen Principal, las cantidades de los sub almacenes.
            if (
              itemRec.idAlmacen === subItem.idAlmacenPadre &&
              itemRec.idRecambio === subItem.idRecambio
            ) {
              cantidadSecundarios += subItem.cantidad;
              cantidadTotal += subItem.cantidad;
            }
          });
        }

        itemRec.cantidadTotal = cantidadTotal;
        itemRec.cantidadSecundarios =
          itemRec.tblAlmacenRecambios.idAlmacenPadre === null
            ? cantidadSecundarios
            : "-";

        itemRec.visible = true;
      });

      // Si solo tengo subalmacen guardado, no se verá el almacen principal y pondré el alm secundario como principal
      item.tblRecambioNAlmacenRecambios = $.grep(
        item.tblRecambioNAlmacenRecambios,
        function (item) {
          return (
            item.tblAlmacenRecambios.idAlmacenPadre === null && item.visible
          );
        },
      );
    });
    return data;
  }

  datasource_tblRecambio_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { recambioSel } = this.state;
    recambioSel.idRecambio = key;
    let recambioSel_ = $.extend(true, {}, recambioSel);

    this.dxDataGrid_tblRecambio.refresh().done(function () {
      _this.setState({
        recambioSel: recambioSel,
        recambioSel_bak: recambioSel_,
      });

      _this.dxDataGrid_tblRecambio.navigateToRow(recambioSel.idRecambio);
      _this.dxDataGrid_tblRecambio.selectRows([recambioSel.idRecambio], false);
    });
  }

  datasource_tblRecambio_onUpdated(values, key) {
    let { recambioSel } = this.state;
    let _this = this;
    this.dxDataGrid_tblRecambio.refresh().done(function () {
      if (recambioSel.idRecambio) {
        _this.dxDataGrid_tblRecambio.navigateToRow(recambioSel.idRecambio);
        _this.dxDataGrid_tblRecambio.selectRows(
          [recambioSel.idRecambio],
          false,
        );
      }
    });
  }

  datasource_tblAlmacenRecambiosNPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambiosNPersona",
      key: ["idAlmacen", "idPersona"],
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblAlmacenRecambiosNPersona_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblAlmacenRecambiosNPersona_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { user } = this.props;
    request.params.idPersona = user.idPersona !== null ? user.idPersona : -1;
  }

  objCheckReferencia = {};
  context_CheckReferencia = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblRecambio",
    entities: {
      CheckReferencia: {},
    },
    beforeSend: (request) => {
      this.context_CheckReferencia_beforeSend(request);
    },
  });
  context_CheckReferencia_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.payload = this.objCheckReferencia;
  }

  objCheckReferenciaInterna = {};
  context_CheckReferenciaInterna = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblRecambio",
    entities: {
      CheckReferenciaInterna: {},
    },
    beforeSend: (request) => {
      this.context_CheckReferenciaInterna_beforeSend(request);
    },
  });
  context_CheckReferenciaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.payload = this.objCheckReferenciaInterna;
  }

  datasource_tblPais = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    filter: ["idPais in (1,4,5)"],
    expand: ["tblMoneda($select=idMoneda, codigo)"],
    sort: ["denominacion"],
  });

  datasource_tblRecambioNProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecambioNProveedor",
      key: "idRecambioNProveedor",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
    }),
  });

  initReset_addProveedor_formData(updateObj) {
    this.addProveedor_formData = {
      idPais: null,
      activo: true,
      codigoBarras: null,
      fabricante: null,
      idProveedor: null,
      refFabricante: null,
      referencia: null,
      ultimoPrecio: null,
    };

    if (updateObj != null) {
      this.addProveedor_formData = {
        ...this.addProveedor_formData,
        ...updateObj,
      };
    }
  }

  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    this.initReset_addProveedor_formData();
    _this.loadPanel_show();
    _this.datasource_tblPais.load();
    _this.datasource_tblAlmacenRecambiosNPersona.load().done(function () {
      $.when(
        _this.datasource_tblAlmacenRecambios.load(),
        _this.datasource_tblRecambio.load(),
        _this.datasource_tblProveedor.load(),
      ).then(function () {
        _this.crearRecambio();
        _this.setState({
          tblProveedor_items: _this.datasource_tblProveedor.items(),
          tblPais_items: _this.datasource_tblPais.items(),
        });
        _this.loadPanel_hide();
      });
    });
  }

  render() {
    let { recambioSel } = this.state;
    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="Recambios" className="formContainer scrollbar-container">
            <Row className="pt-4 he-100">
              <Col xs="12" md="7" className="he-100">
                <form
                  onSubmit={this.submit_formRecambio}
                  className="d-flex flex-column he-100 w-100"
                >
                  <div className="text-left pb-3">
                    <Toolbar>
                      <ToolbarItem location="before">
                        <Button
                          text={getTrad("nuevoRecambio")}
                          icon="add"
                          onClick={this.crearRecambio}
                        />
                      </ToolbarItem>
                      <ToolbarItem location="after">
                        <Button
                          text={getTrad("guardar")}
                          type="success"
                          icon="check"
                          useSubmitBehavior={true}
                          validationGroup="validationGroup_form_recambio"
                        />
                      </ToolbarItem>
                    </Toolbar>
                  </div>
                  <div className="flex-grow-1">
                    <Form
                      height="72vh"
                      formData={recambioSel}
                      ref={this.dxForm_tblRecambio_REF}
                      labelLocation="top"
                      screenByWidth={this.screenByWidth}
                      colCountByScreen={this.colCountByScreen}
                      validationGroup="validationGroup_form_recambio"
                    >
                      <Item
                        dataField="denominacion"
                        label={this.formLabel_denominacion}
                        colSpan={7}
                      >
                        <RequiredRule message={getTrad("campoNecesario")} />
                      </Item>
                      <Item
                        dataField="idProveedor"
                        label={this.formLabel_fabricante}
                        editorType="dxSelectBox"
                        editorOptions={this.fabricante_editorOptions}
                        colSpan={5}
                      />
                      <Item
                        dataField="referencia"
                        label={this.formLabel_referenciaFabricante}
                        colSpan={4}
                      >
                        <AsyncRule
                          ignoreEmptyValue={true}
                          reevaluate={true}
                          message="La referencia introducida ya existe"
                          validationCallback={this.validateAsync_referencia}
                        />
                      </Item>
                      <Item
                        dataField="referenciaInterna"
                        label={this.formLabel_referenciaInterna}
                        colSpan={4}
                      >
                        <AsyncRule
                          ignoreEmptyValue={true}
                          reevaluate={true}
                          message="La referencia interna introducida ya existe"
                          validationCallback={
                            this.validateAsync_referenciaInterna
                          }
                        />
                      </Item>
                      <Item
                        dataField="activo"
                        label={this.formLabel_activo}
                        colSpan={4}
                        editorType="dxSwitch"
                        editorOptions={this.activo_editorOptions}
                      />

                      <Item
                        dataField="descripcionArticulo"
                        label={this.formLabel_descripcionArticulo}
                        colSpan={12}
                        editorType="dxTextArea"
                        editorOptions={this.descripcionArticulo_editorOptions}
                      />
                      <TabbedItem
                        colSpan={12}
                        tabPanelOptions={this.tabPanelOptions_recambiosNAlmProv}
                      >
                        <Tab title={getTrad("almacenes")}>
                          <Item
                            dataField="tblRecambioNAlmacen"
                            render={this.render_tblRecambioNAlmacen}
                            label={this.formLabel_invisible}
                          />
                        </Tab>
                        <Tab title={getTrad("proveedores")}>
                          <Item
                            dataField="tblRecambioNProveedor"
                            render={this.render_tblRecambioNProveedor}
                            label={this.formLabel_invisible}
                          />
                        </Tab>
                      </TabbedItem>
                    </Form>
                  </div>
                </form>
              </Col>
              <Col xs="12" md="5" className="he-100">
                <DataGrid
                  //Datos
                  ref={this.dxDataGrid_tblRecambio_REF}
                  dataSource={this.datasource_tblRecambio}
                  //Propiedades
                  columnsAutoWidth={true}
                  height={"100%"}
                  width={"100%"}
                  hoverStateEnabled={true}
                  selection={this.tblRecambio_selection}
                  selectedRowKeys={
                    recambioSel && recambioSel.idRecambio
                      ? [recambioSel.idRecambio]
                      : []
                  }
                  //Estilos
                  loadPanel={{ enabled: false }}
                  showColumnLines={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  onToolbarPreparing={this.onToolbarPreparing_tblRecambio}
                  onRowRemoving={this.onRowRemoving_tblRecambio}
                  onRowRemoved={this.onRowRemoved_tblRecambio}
                  onSelectionChanged={this.onSelectionChanged_tblRecambio}
                  onRowPrepared={this.onRowPrepared_tblRecambio}
                >
                  <SearchPanel visible={true} width={240} />
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={this.tblRecambio_allowedPageSizes}
                    showInfo={true}
                  />
                  <ColumnChooser enabled={true} height={200} />
                  <Paging defaultPageSize={50} />
                  <Column
                    dataField="activo"
                    visible={false}
                    sortIndex={1}
                    sortOrder="desc"
                  />
                  <Column
                    dataField="referencia"
                    caption={getTrad("refFabricante")}
                    width="25%"
                    alignment="center"
                    sortIndex={2}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="referenciaInterna"
                    caption={getTrad("referenciaInterna")}
                    width="25%"
                    alignment="center"
                    sortIndex={2}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                    showInColumnChooser={true}
                    visible={false}
                  />
                  <Column
                    dataField="denominacion"
                    caption={getTrad("denominacion")}
                    alignment="left"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="tblRecambioNProveedor.referencia"
                    calculateFilterExpression={
                      this
                        .tblRecambioNProveedor_referencia_calculateFilterExpression
                    }
                    visible={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                  />
                  <Column
                    dataField="tblRecambioNProveedor.codigoBarras"
                    calculateFilterExpression={
                      this
                        .tblRecambioNProveedor_codigoBarras_calculateFilterExpression
                    }
                    visible={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                  />
                  <Column
                    dataField="tblRecambioNProveedor.fabricante"
                    calculateFilterExpression={
                      this
                        .tblRecambioNProveedor_fabricante_calculateFilterExpression
                    }
                    visible={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                  />
                  <Column
                    dataField="tblRecambioNProveedor.refFabricante"
                    calculateFilterExpression={
                      this
                        .tblRecambioNProveedor_refFabricante_calculateFilterExpression
                    }
                    visible={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                  />
                  <Column
                    dataField="tblProveedor.nombreComercial"
                    calculateFilterExpression={
                      this
                        .tblProveedor_nombreComercial_calculateFilterExpression
                    }
                    visible={false}
                    allowHeaderFiltering={false}
                    showInColumnChooser={false}
                  />
                  <Column
                    caption=" "
                    width={50}
                    alignment="center"
                    cssClass="p-0"
                    cellComponent={removeCellComponent}
                  />
                </DataGrid>
              </Col>
            </Row>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  tblRecambioNProveedor_referencia_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [
      "tblRecambioNProveedor/any(x: (contains(tolower(x/referencia),'" +
        filterValue +
        "')))",
    ];
  }
  tblRecambioNProveedor_codigoBarras_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [
      "tblRecambioNProveedor/any(x: (contains(tolower(x/codigoBarras),'" +
        filterValue +
        "')))",
    ];
  }
  tblRecambioNProveedor_fabricante_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [
      "tblRecambioNProveedor/any(x: (contains(tolower(x/fabricante),'" +
        filterValue +
        "')))",
    ];
  }
  tblRecambioNProveedor_refFabricante_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [
      "tblRecambioNProveedor/any(x: (contains(tolower(x/refFabricante),'" +
        filterValue +
        "')))",
    ];
  }
  tblProveedor_nombreComercial_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return ["tblProveedor/nombreComercial", "contains", filterValue];
  }

  number_editorOptions = {
    dataType: "number",
    format: "#0.## %",
    min: 0,
    step: 0,
    max: 1,
  };

  formLabel_invisible = { visible: false };
  formLabel_denominacion = { text: getTrad("denominacion") };
  formLabel_referenciaFabricante = { text: getTrad("referenciaFabricante") };
  formLabel_activo = { text: getTrad("activo") };
  formLabel_fabricante = { text: getTrad("fabricante") };
  formLabel_referenciaInterna = { text: getTrad("referenciaInterna") };
  formLabel_descripcionArticulo = { text: getTrad("descripcionArticulo") };

  formLabel_codigoBarras = { text: getTrad("codigoBarras") };
  formLabel_refFabricante = { text: getTrad("refFabricante") };
  formLabel_referencia = { text: getTrad("refProveedor") };
  formLabel_proveedor = { text: getTrad("proveedor") };
  formLabel_precioCompra = { text: getTrad("precioCompra") };
  formLabel_pais = { text: getTrad("pais") };

  tabPanelOptions_recambiosNAlmProv = {
    elementAttr: { class: "h4" },
    deferRendering: false,
    onContentReady: (e) => {
      e.element.find(".dx-tabs").addClass("fill");
    },
  };

  activo_editorOptions = {
    switchedOnText: getTrad("activo"),
    switchedOffText: getTrad("noActivo"),
  };

  descripcionArticulo_editorOptions = {
    height: "75px",
  };

  fabricante_editorOptions = {
    searchEnabled: true,
    dataSource: this.datasource_tblProveedor,
    displayExpr: "nombreComercial",
    valueExpr: "idProveedor",
    showClearButton: true,
  };

  dxLookupPais_onSelectionChanged(e, _this) {
    let codigoMoneda = "EUR";
    if (e.selectedItem) codigoMoneda = e.selectedItem.tblMoneda.codigo;

    let format = {
      style: "currency",
      currency: codigoMoneda,
      minimumFractionDigits: 2,
    };
    if (_this.dxForm_addProveedor)
      _this.dxForm_addProveedor
        .getEditor("ultimoPrecio")
        .option("format", format);
  }

  colCountByScreen = { xl: 12, lg: 12, md: 12, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //CONFIG GRID
  tblRecambio_selection = { mode: "single" };
  tblRecambio_allowedPageSizes = [20, 50, 100];

  //#region EVENTOS

  onToolbarPreparing_tblRecambio(e) {
    let _this = this;
    let { is_switchStock } = this.state;

    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("recambios"));
        },
      },
      {
        location: "after",
        widget: "dxSwitch",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          width: 105,
          switchedOnText: getTrad("todos"),
          switchedOffText: "Con stock",
          value: is_switchStock,
          elementAttr: this.elementAttr_dxSwitch_toolbar,
          onValueChanged: (value) =>
            this.onValueChanged_Switch_conStock(value, e),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            //LIMPIA TODOS LOS FILTROS EXCEPTO EL DEL DATASOURCE
            e.component.beginUpdate();
            e.component.clearFilter("search");
            e.component.clearFilter("header");
            e.component.clearFilter("filterValue");
            e.component.clearFilter("row");
            e.component.endUpdate();

            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }

  onValueChanged_Switch_conStock(value, e) {
    if (!value.value)
      this.datasource_tblRecambio.filter([
        "tblRecambioNAlmacenRecambios/any(o: o.cantidad gt 0)",
      ]);
    else this.datasource_tblRecambio.filter("");

    this.datasource_tblRecambio.load();
    this.setState({ is_switchStock: value.value });
  }

  onRowRemoving_tblRecambio(e) {
    this.datasource_tblRecambio.store().remove(e.key);
  }

  onRowRemoved_tblRecambio(e) {
    let { recambioSel } = this.state;
    if (e.key === recambioSel.idRecambio) {
      this.crearRecambio();
    }
  }

  onSelectionChanged_tblRecambio(e) {
    let _this = this;
    const data = e.selectedRowsData[0];
    if (data) {
      _this.dxDataGrid_tblRecambioNProveedor.saveEditData();
      let recambioSel = $.extend(true, {}, data),
        recambioSel_bak = $.extend(true, {}, recambioSel);
      _this.setState(
        { recambioSel: recambioSel, recambioSel_bak: recambioSel_bak },
        () => {
          _this.dxForm_tblRecambio.validate();
        },
      );
    }
  }

  onRowPrepared_tblRecambio(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  render_tblRecambioNProveedor = (data) => {
    let _this = this;
    let { showPopup_Add_tblRecambioNProveedor } = this.state;
    let recambioSel = this.dxForm_tblRecambio.option("formData");

    let decimal_ultimoPrecio_editorOptions = {
      placeholder: "0",
      step: 0,
      format: {
        style: "currency",
        currency:
          this.addProveedor_formData && this.addProveedor_formData.moneda
            ? this.addProveedor_formData.moneda
            : "EUR",
        minimumFractionDigits: 2,
      },
      min: 0,
      max: 999999.99,
    };

    let fabricante_lookup_editorOptions = {
      searchEnabled: true,
      dataSource: this.state.tblProveedor_items,
      displayExpr: "nombreComercial",
      valueExpr: "idProveedor",
      showPopupTitle: false,
      showCancelButton: false,
      showClearButton: false,
      closeOnOutsideClick: true,
    };

    let pais_editorOptions = {
      searchEnabled: false,
      dataSource: this.state.tblPais_items,
      displayExpr: "denominacion",
      valueExpr: "idPais",
      showPopupTitle: false,
      showCancelButton: false,
      showClearButton: false,
      closeOnOutsideClick: true,
      onSelectionChanged: (e) => this.dxLookupPais_onSelectionChanged(e, this),
    };

    return (
      <>
        <DataGrid
          //Datos
          ref={this.dxDataGrid_tblRecambioNProveedor_REF}
          dataSource={
            new DataSource({
              store: new ArrayStore({
                data: recambioSel.tblRecambioNProveedor,
                onModified: function () {
                  _this.dxForm_tblRecambio.updateData(
                    "tblRecambioNProveedor",
                    recambioSel.tblRecambioNProveedor,
                  );
                },
              }),
            })
          }
          //Propiedades
          columnsAutoWidth={true}
          height={390}
          hoverStateEnabled={true}
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          onInitNewRow={this.onInitNewRow_tblRecambioNProveedor}
          onRowRemoving={this.onRowRemoving_tblRecambioNProveedor}
          onToolbarPreparing={this.onToolbarPreparing_tblRecambioNProveedor}
        >
          <Grouping autoExpandAll={true} />
          <SearchPanel visible={false} />
          <HeaderFilter visible={false} />
          <FilterRow visible={false} />
          <Pager visible={false} />
          <KeyboardNavigation
            enterKeyAction="moveFocus"
            enterKeyDirection="row"
            editOnKeyPress={true}
          />
          <Editing
            mode="cell"
            allowUpdating={true}
            selectTextOnEditStart={true}
          />
          <Column dataField="idRecambio" visible={false} allowEditing={false} />
          <Column dataField="activo" visible={false} allowEditing={false} />
          <Column
            dataField="idProveedor"
            caption={getTrad("denominacion")}
            alignment="left"
            allowEditing={false}
          >
            <RequiredRule />
            <Lookup
              dataSource={this.datasource_tblProveedor.items()}
              valueExpr="idProveedor"
              displayExpr="nombreComercial"
            />
          </Column>
          <Column
            dataField="idPais"
            groupIndex={0}
            visible={false}
            allowEditing={false}
            groupCellRender={(e) => {
              if (e.data.items !== null)
                return e.data.items[0].tblPais.denominacion;
              else return e.data.collapsedItems[0].tblPais.denominacion;
            }}
          />
          <Column
            dataField="ultimoPrecio"
            caption={getTrad("precioCompra")}
            alignment="center"
            dataType="number"
            width={180}
            calculateDisplayValue={function (e) {
              return formatNumber(
                e.ultimoPrecio,
                2,
                "currency",
                e.tblPais.tblMoneda.codigo,
              );
            }}
          ></Column>
          <Column
            dataField="referencia"
            caption={getTrad("refProveedor")}
            alignment="center"
            width={110}
          ></Column>
          <Column
            dataField="codigoBarras"
            caption={getTrad("codigoBarras")}
            alignment="left"
            width={110}
          ></Column>
          <Column
            dataField="fabricante"
            caption={getTrad("fabricante")}
            alignment="left"
            width={110}
          ></Column>
          <Column
            dataField="refFabricante"
            caption={getTrad("refFabricante")}
            alignment="left"
            width={110}
          ></Column>
          <Column
            caption=" "
            width={50}
            alignment="center"
            cssClass="p-0"
            cellComponent={removeCellComponent}
          />
        </DataGrid>
        <Popup
          title={"Añadir proveedor"}
          showTitle={true}
          height={450}
          width={440}
          closeOnOutsideClick={false}
          visible={showPopup_Add_tblRecambioNProveedor}
          onHiding={this.hiding_popup_Add_tblRecambioNProveedor}
        >
          <form id="dxForm_addProveedor">
            <Form
              ref={this.dxForm_addProveedor_REF}
              formData={this.addProveedor_formData}
              labelLocation="top"
              colCount={2}
              showColonAfterLabel={true}
              showValidationSummary={false}
              validationGroup="proveedorData"
            >
              <Item dataField="idRecambio" visible={false} />
              <Item
                dataField="idProveedor"
                label={this.formLabel_proveedor}
                editorType="dxLookup"
                editorOptions={fabricante_lookup_editorOptions}
                colSpan={2}
              >
                <RequiredRule />
              </Item>
              <Item
                dataField="idPais"
                editorType="dxLookup"
                label={this.formLabel_pais}
                editorOptions={pais_editorOptions}
                colSpan={1}
              >
                <RequiredRule />
              </Item>

              <Item
                dataField="ultimoPrecio"
                editorType="dxNumberBox"
                label={this.formLabel_precioCompra}
                editorOptions={decimal_ultimoPrecio_editorOptions}
                colSpan={1}
              />
              <Item
                dataField="referencia"
                editorType="dxTextBox"
                label={this.formLabel_referencia}
                colSpan={1}
              />
              <Item
                dataField="codigoBarras"
                editorType="dxTextBox"
                label={this.formLabel_codigoBarras}
                colSpan={1}
              />
              <Item
                dataField="fabricante"
                editorType="dxTextBox"
                label={this.formLabel_fabricante}
                colSpan={1}
              />
              <Item
                dataField="refFabricante"
                editorType="dxTextBox"
                label={this.formLabel_referenciaFabricante}
                colSpan={1}
              />

              <EmptyItem />
              <ButtonItem
                horizontalAlignment="right"
                verticalAlignment="bottom"
                buttonOptions={this.buttonOptions}
              />
            </Form>
          </form>
        </Popup>
      </>
    );
  };

  validationCallback_CustomRule_MinVal(e) {
    return e.value > 0;
  }

  buttonOptions = {
    text: "Aceptar",
    type: "success",
    useSubmitBehavior: false,
    onClick: (e) => this.dxButtonItem_addProveedor_onClick(e, this),
  };

  dxButtonItem_addProveedor_onClick(e, _this) {
    let { recambioSel } = _this.state;
    if (_this.dxForm_addProveedor.validate().isValid) {
      let {
        ultimoPrecio,
        referencia,
        codigoBarras,
        fabricante,
        refFabricante,
      } = this.addProveedor_formData;
      if (
        (ultimoPrecio != null && ultimoPrecio > 0) ||
        (referencia != null && referencia.length > 0) ||
        (codigoBarras != null && codigoBarras.length > 0) ||
        (fabricante != null && fabricante.length > 0) ||
        (refFabricante != null && refFabricante.length > 0)
      ) {
        let formatPais = [..._this.datasource_tblPais.items()].filter(
          (x) => x.idPais == this.addProveedor_formData.idPais,
        );
        let formatProveedor = [..._this.datasource_tblProveedor.items()]
          .map((x) => {
            return {
              idProveedor: x.idProveedor,
              nombreComercial: x.nombreComercial,
            };
          })
          .filter(
            (x) => x.idProveedor == this.addProveedor_formData.idProveedor,
          );

        let _addProveedor = { ..._this.addProveedor_formData };
        delete _addProveedor.idRecambio;
        _addProveedor.tblPais = formatPais.length > 0 ? formatPais[0] : null;
        _addProveedor.tblProveedor =
          formatProveedor.length > 0 ? formatProveedor[0] : null;
        _addProveedor.orden = recambioSel?.tblRecambioNProveedor?.length + 1;

        recambioSel.tblRecambioNProveedor.push(_addProveedor);

        recambioSel.tblRecambioNProveedor.sort((a, b) => a.orden - b.orden).map((rnp, index) => ({ ...rnp, orden: index + 1 }));
        _this.setState({ showPopup_Add_tblRecambioNProveedor: false });
      } else {
        notify({
          message: getTrad("alerta_RellenaAlMenosUnCampo"),
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
      }
    }
  }

  onInitNewRow_tblRecambioNProveedor(e) {
    e.data.ultimoPrecio = 0;
    e.data.idRecambio =
      this.state.recambioSel !== null ? this.state.recambioSel.idRecambio : -1;
    e.data.activo = true;
  }

  onRowRemoving_tblRecambioNProveedor = ({ data }) => {
    let { recambioSel } = this.state;

    const tblRecambioNProveedor = recambioSel.tblRecambioNProveedor
      .filter(rnp => rnp.idRecambioNProveedor !== data.idRecambioNProveedor)
      .sort((a, b) => a.orden - b.orden)
      .map((rnp, index) => ({ ...rnp, orden: index + 1 }));
      
    this.setState({ recambioSel: { ...recambioSel, tblRecambioNProveedor } });
  }

  render_tblRecambioNAlmacen = (data) => {
    let recambioSel = this.dxForm_tblRecambio.option("formData");
    return (
      <DataGrid
        //Datos
        elementAttr={{ id: "dxDataGrid_almacenes" }}
        dataSource={recambioSel.tblRecambioNAlmacenRecambios}
        //Propiedades
        columnsAutoWidth={true}
        height={400}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onContentReady={(e) => {
          e.component.filter(["visible", "=", true]);
        }}
        onCellPrepared={(e) => {
          if (e.rowType === "data" && e.column.command === "expand") {
            //Comprobar si almacen tiene visible algún hijo
            let is_subAlm_visible =
              $.grep(
                e.data.tblAlmacenRecambios.tblAlmacenHijo,
                function (item) {
                  return item.visible;
                },
              ).length > 0;
            if (e.data.cantidadSecundarios <= 0 || !is_subAlm_visible) {
              e.cellElement.removeClass("dx-datagrid-expand");
              e.cellElement.empty();
            }
          }
        }}
      >
        <KeyboardNavigation
          enterKeyAction="moveFocus"
          enterKeyDirection="row"
          editOnKeyPress={true}
        />
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Column
          dataField="tblAlmacenRecambios.denominacion"
          caption={getTrad("denominacion")}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="cantidad"
          caption={getTrad("almPrinc_abr")}
          alignment="center"
          dataType="number"
          width={100}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="ubicacion"
          caption={getTrad("ubicacion")}
          width="10%"
          alignment="left"
          allowFiltering={false}
        />
        <Column
          dataField="cantidadSecundarios"
          caption={getTrad("almSec_abr")}
          width={160}
          alignment="center"
          allowFiltering={false}
        />
        <Column
          dataField="cantidadTotal"
          caption={getTrad("total")}
          width={120}
          alignment="center"
          allowFiltering={false}
        />
        <MasterDetail enabled={true} render={this.render_subAlmacenes} />
      </DataGrid>
    );
  };

  render_subAlmacenes = (data) => {
    let recambiosNAlmacenHijo = $.grep(
      data.data.tblAlmacenRecambios.tblAlmacenHijo,
      function (item) {
        return item.visible && item.tblRecambioNAlmacenRecambios.length > 0;
      },
    );
    let formated_dataSource = $.map(recambiosNAlmacenHijo, function (item) {
      return {
        idAlmacen: item.idAlmacen,
        denominacion: item.denominacion,
        cantidad: item.tblRecambioNAlmacenRecambios[0].cantidad,
      };
    });
    if (formated_dataSource.length > 0) {
      return (
        <DataGrid
          //Datos
          dataSource={formated_dataSource}
          //Propiedades
          columnsAutoWidth={true}
          height={"100%"}
          hoverStateEnabled={true}
          //Estilos
          showColumnLines={false}
          showColumnHeaders={false}
          showRowLines={true}
          rowAlternationEnabled={true}
        >
          <SearchPanel visible={false} />
          <HeaderFilter visible={false} />
          <FilterRow visible={false} />
          <Pager visible={false} />
          <Column
            dataField="denominacion"
            caption={getTrad("denominacion")}
            alignment="left"
          >
            <RequiredRule />
          </Column>
          <Column
            alignment="center"
            width={100}
            calculateDisplayValue={(e) => {
              return "-";
            }}
          />
          <Column
            dataField="cantidad"
            caption={getTrad("cantidad")}
            alignment="center"
            dataType="number"
            width={160}
            format={this.decimal_format}
            editorOptions={this.decimal_editorOptions}
          />
          <Column
            alignment="center"
            width={120}
            calculateDisplayValue={(e) => {
              return "-";
            }}
          />
        </DataGrid>
      );
    } else return null;
  };

  elementAttr_dxSwitch_toolbar = {
    class: "dxSwitch_toolbar no-disabled",
  };

  onToolbarPreparing_tblRecambioNProveedor(e) {
    let toolbarItems = e.toolbarOptions.items;
    e.toolbarOptions.height = 35;

    $.each(toolbarItems, function (_, item) {
      if (item.name == "saveButton" || item.name == "revertButton") {
        item.visible = false;
      }
    });

    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      options: {
        text: getTrad("añadir"),
        icon: "plus",
        onClick: this.dxDataGrid_tblRecambioNProveedor_añadir_onClick,
      },
    });
  }

  dxDataGrid_tblRecambioNProveedor_añadir_onClick(e) {
    this.dxForm_addProveedor.resetValues();
    let { user } = this.props;
    let { recambioSel } = this.state;
    let obj = {
      idPais: user.idPais ? user.idPais : null,
      idRecambio: recambioSel.idRecambio ? recambioSel.idRecambio : null,
    };

    let formatPais = [...this.datasource_tblPais.items()].filter(
      (x) => x.idPais == obj.idPais,
    );
    obj.tblPais = formatPais.length > 0 ? formatPais[0] : null;
    this.initReset_addProveedor_formData(obj);

    this.setState({ showPopup_Add_tblRecambioNProveedor: true });
  }

  hiding_popup_Add_tblRecambioNProveedor(e) {
    this.setState({ showPopup_Add_tblRecambioNProveedor: false });
  }

  //RECAMBIO
  crearRecambio() {
    let recambioSel = {};
    recambioSel.denominacion = undefined;
    recambioSel.tblRecambioNProveedor = [];
    recambioSel.referencia = "";
    recambioSel.activo = true;

    let recambioSel_ = $.extend(true, {}, recambioSel);
    this.setState({
      recambioSel: recambioSel,
      recambioSel_bak: $.extend(true, {}, recambioSel_),
    });
  }

  submit_formRecambio(e) {
    e.preventDefault();
    let _this = this;

    this.dxDataGrid_tblRecambioNProveedor
      .getController("validating")
      .validate(true)
      .done(function (tblRecambioNProveedor_isValid) {
        if (tblRecambioNProveedor_isValid) {
          if (!_this.state.recambioSel.referencia) {
            dxMensajePregunta(getTrad("alert_guardarReferenciaVacía"), [
              [
                getTrad("aceptar"),
                function () {
                  guardado();
                },
                "danger",
              ],
              [getTrad("cancelar"), function () {}],
            ]);
          } else {
            guardado();
          }

          function guardado() {
            _this.dxDataGrid_tblRecambioNProveedor.saveEditData();
            let { recambioSel } = _this.state;
            let recambioSel_ = $.extend(true, {}, recambioSel);

            delete recambioSel_.tblRecambioNAlmacenRecambios;

            $.each(recambioSel_.tblRecambioNProveedor, function (index, prov) {
              delete prov.__KEY__;
              delete prov.tblProveedor;
              delete prov.tblPais;
              delete prov.updateObj;
            });

            //#region Para quitar dobles espacios y espacios antes y despues de la palabra
            recambioSel_.denominacion = $.trim(
              recambioSel_.denominacion,
            ).replace(/ +(?= )/g, "");
            recambioSel_.referencia = $.trim(recambioSel_.referencia).replace(
              / +(?= )/g,
              "",
            );
            //#endregion

            if (recambioSel_.idRecambio) {
              //UPDATE
              _this.datasource_tblRecambio
                .store()
                .update(recambioSel_.idRecambio, recambioSel_)
                .done(function () {
                  notify({
                    message: getTrad("aviso_C_RegistroActualizado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });

                  _this.setState({
                    recambioSel: recambioSel,
                    recambioSel_bak: recambioSel,
                  });

                  _this.dxDataGrid_tblRecambio.navigateToRow(
                    recambioSel.idRecambio,
                  );
                  _this.dxDataGrid_tblRecambio.selectRows(
                    [recambioSel.idRecambio],
                    false,
                  );
                });
            } else {
              //INSERT
              _this.datasource_tblRecambio
                .store()
                .insert(recambioSel_)
                .done(function () {
                  notify({
                    message: getTrad("aviso_C_RegistroInsertado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                });
            }
          }
        } else {
          notify({
            message: getTrad("errorIntroduccionDatos"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        }
      });
  }

  validateAsync_referencia(params) {
    let _this = this;
    let { recambioSel } = this.state;

    let value = params.value.replace(/\s+/g, " ").trim();
    return new Promise((resolve) => {
      this.objCheckReferencia = {
        idRecambio: recambioSel.idRecambio,
        referencia: value,
      };
      this.context_CheckReferencia
        .invoke("CheckReferencia", {}, "POST")
        .done(function (result) {
          if (result !== -1) {
            notify({
              message: getTrad("errorIntroduccionDatos"),
              type: "error",
              displayTime: "1500",
              closeOnClick: true,
            });

            if (recambioSel.idRecambio == null) {
              dxMensajePregunta(getTrad("alert_refYaExiste"), [
                [
                  getTrad("aceptar"),
                  function () {
                    $.when(
                      _this.dxDataGrid_tblRecambio.navigateToRow(result),
                    ).then(function () {
                      $.when(
                        _this.dxDataGrid_tblRecambio.selectRows(
                          [result],
                          false,
                        ),
                      ).then(function () {
                        _this.dxForm_tblRecambio.validate();
                      });
                    });
                  },
                  "danger",
                ],
                [
                  getTrad("cancelar"),
                  function () {
                    _this.dxForm_tblRecambio
                      .getEditor("referencia")
                      .option("value", _this.state.recambioSel_bak.referencia);
                  },
                ],
              ]);
            }
          }

          return resolve(result === -1);
        });
    });
  }

  validateAsync_referenciaInterna(params) {
    let _this = this;
    let { recambioSel } = this.state;
    let value = params.value.replace(/\s+/g, " ").trim();
    return new Promise((resolve) => {
      this.objCheckReferenciaInterna = {
        idRecambio: recambioSel.idRecambio,
        referencia: value,
      };
      this.context_CheckReferenciaInterna
        .invoke("CheckReferenciaInterna", {}, "POST")
        .done(function (result) {
          if (result !== -1) {
            notify({
              message: getTrad("errorIntroduccionDatos"),
              type: "error",
              displayTime: "1500",
              closeOnClick: true,
            });

            if (recambioSel.idRecambio == null) {
              dxMensajePregunta(getTrad("alert_refYaExiste"), [
                [
                  getTrad("aceptar"),
                  function () {
                    $.when(
                      _this.dxDataGrid_tblRecambio.navigateToRow(result),
                    ).then(function () {
                      $.when(
                        _this.dxDataGrid_tblRecambio.selectRows(
                          [result],
                          false,
                        ),
                      ).then(function () {
                        _this.dxForm_tblRecambio.validate();
                      });
                    });
                  },
                  "danger",
                ],
                [
                  getTrad("cancelar"),
                  function () {
                    _this.dxForm_tblRecambio
                      .getEditor("referenciaInterna")
                      .option(
                        "value",
                        _this.state.recambioSel_bak.referenciaInterna,
                      );
                  },
                ],
              ]);
            }
          }

          return resolve(result === -1);
        });
    });
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps) {}
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Recambios);
