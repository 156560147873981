import React, { Component } from "react";
import { getTrad } from "helpers";

const InfoItem = ({ icon, label, value }) => (
  <div className="d-flex w-100 m-2">
    <div className="d-flex w-100 m-2">
      <i className={icon + " " + "mr-4"} style={{ fontSize: 20 }} />
      <div>{getTrad(label)}</div>
    </div>
    <div className="d-flex w-100 m-2 justify-content-end">{value}</div>
  </div>
);

// Componente de clase refactorizado
class DatosPersonales extends Component {
  render() {
    const { datosPersonaSel, formIcons } = this.props;
    return (
      <div className="d-flex justify-content-center shadow flex-column align-items-center border-radius p-4 mt-3 ">
        <InfoItem icon={formIcons.nombre} label="nombre" value={datosPersonaSel?.nombre} />
        <InfoItem icon={formIcons.apellidos} label="apellidos" value={datosPersonaSel?.apellidos} />
        <InfoItem icon={formIcons.email} label="email" value={datosPersonaSel?.email} />
        <InfoItem icon={formIcons.telefono} label="telefono" value={datosPersonaSel?.telefono} />
      </div>
    );
  }
}

export default DatosPersonales;
