import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate,
  formatDate_parameter,
  formatNumber,
  startOfMonth,
  endOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  dxRangeSelector_PosicionValor_nuevoMarker,
  filtroTiempo_Resize,
  tooltipControl_creacion,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

import notify from "devextreme/ui/notify";

import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var idLavanderia = LAVANDERIA.idLavanderia,
    fechaDesde = null,
    fechaHasta = null,
    is_unidades = true,
    idCompaSel = null,
    idEntidadSel = null,
    is_todasCompa = null,
    tipoImpresion = null,
    rowSel = null;
  //#endregion

  //#region DATASOURCE
  var datasource_filtroTiempo_spSelectProduccion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProduccion_filtroTiempo_spSelectProduccion",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
      },
      version: 4,
    }),
    sort: "fecha",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
        producido: itemData.producido,
      };
    },
  });

  var datasource_spSelectProduccion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infProduccion_spSelectProduccion",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idioma = IDIOMA.codigo;
        request.params.idTipoPrenda = null;
        request.params.idPrenda = null;
      },
      version: 4,
    }),
    sort: "idPrenda",
  });

  var datasource_spSelectProduccion_fecha = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infProduccion_spSelectProduccion",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idioma = IDIOMA.codigo;
        request.params.idTipoPrenda =
          rowSel != null ? rowSel.idTipoPrenda : null;
        request.params.idPrenda = rowSel != null ? rowSel.idPrenda : null;
      },
      version: 4,
    }),
    sort: "idPrenda",
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion", "idCompañia"],
    postProcess: function (data) {
      if (datasource_entidades.items().length === 0) {
        var filtro_dxList_compañia = $("#filtro_dxList_compañia").dxList(
          "instance",
        );
        data.splice(0, 0, {
          idEntidad: 0,
          denominacion: getTrad("todas").toUpperCase(),
          idCompañia: 0,
        }); // Todas

        if (
          typeof filtro_dxList_compañia !== "undefined" &&
          filtro_dxList_compañia.option("selectedItems[0].idCompañia") !== 0
        )
          data.splice(1, 0, {
            idEntidad: -1,
            denominacion: getTrad("prendasCompañia").toUpperCase(),
            idCompañia: 0,
          }); // Prendas de compañía
      }
      return data;
    },
  });

  var datasource_tipoProduccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "idTipoProduccion",
  });

  var datasource_turnos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTurno",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "idTurno",
    select: ["idTurno", "denominacion"],
    filter: ["idTurnoPadre eq null"],
  });

  var datasource_familias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFamilia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idFamilia", "denominacion", "abreviatura"],
    postProcess: function (data) {
      if (datasource_familias.items().length === 0) {
        data.splice(0, 0, {
          idFamilia: 0,
          denominacion: getTrad("todas").toUpperCase(),
          abreviatura: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
    sort: "idFamilia",
  });

  var enum_tipoTabla = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("unidades").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("peso").toUpperCase(),
    },
  ];

  var datasource_tipoRechazo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoRechazo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_spSelectTipoRechazoNPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProduccion_spSelectTipoRechazoNPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
  });

  var enum_coloresExcel = {
    fondo: {
      rojo: "FFC7CE",
      naranja: "FFCC99",
      amarillo: "FFEB9C",
      verde: "C6EFCE",
    },
    letra: {
      rojo: "9C0006",
      naranja: "3F3F76",
      amarillo: "9C6500",
      verde: "006100",
    },
  };

  //#endregion

  $.when(
    datasource_tipoProduccion.load(),
    datasource_turnos.load(),
    datasource_familias.load(),
    datasource_tipoRechazo.load(),
    datasource_filtroTiempo_spSelectProduccion.load(),
  )
    .then(function () {
      if (datasource_filtroTiempo_spSelectProduccion.items().length > 0) {
        $("#dxForm_opciones").dxForm({
          labelLocation: "top",
          height: "100%",
          scrollingEnabled: true,
          colCount: 1,
          items: [
            {
              label: { text: getTrad("seleccioneEntidad") },
              editorType: "dxDropDownBox",
              editorOptions: {
                width: "100%",
                deferRendering: false,
                placeholder: getTrad("todas").toUpperCase(),
                // EVENTS
                elementAttr: {
                  id: "dxDropDownBox_selCompaEnti",
                },
                dropDownOptions: {
                  width: 800,
                  height: 500,
                },
                contentTemplate: function (dxDropDownBox, contentElement) {
                  return $("<div>").dxBox({
                    direction: "row",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        ratio: 1,
                        template: function () {
                          return $(
                            "<div id='dxBox_Filtros_compañias' />",
                          ).dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              {
                                baseSize: 40,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='font-size' style='margin-left:9px;'>",
                                  ).text(getTrad("compañia"));
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='filtro_dxList_compañia'>",
                                  ).dxList({
                                    dataSource: datasource_compañias,
                                    selectionMode: "single",
                                    keyExpr: "idCompañia",
                                    scrolling: { mode: "infinite" },
                                    pageLoadMode: "scrollBottom",
                                    height: "100%",
                                    searchEnabled: true,
                                    searchExpr: ["denominacion"],
                                    onContentReady: function (e) {
                                      e.component.selectItem(0); // SELECCIONA EL ITEM 0 POR DEFECTO
                                    },
                                    onSelectionChanged: function (e) {
                                      var lista_entidades = $(
                                        "#filtro_dxList_entidad",
                                      ).dxList("instance");
                                      if (
                                        typeof lista_entidades !== "undefined"
                                      ) {
                                        if (
                                          e.addedItems.length > 0 &&
                                          e.addedItems[0].idCompañia !== 0
                                        ) {
                                          lista_entidades
                                            .getDataSource()
                                            .filter([
                                              [
                                                "idCompañia",
                                                "=",
                                                e.addedItems[0].idCompañia,
                                              ],
                                            ]);
                                        } else {
                                          lista_entidades
                                            .getDataSource()
                                            .filter("");
                                        }
                                        lista_entidades.reload();
                                      }
                                    },
                                    itemTemplate: function (data, index) {
                                      return $("<div>").text(data.denominacion);
                                    },
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $(
                            "<div id='dxBox_Filtros_entidades' style='padding-left:20px;' />",
                          ).dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              {
                                baseSize: 40,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='font-size' style='margin-left:9px;'>",
                                  ).text(getTrad("entidades"));
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='filtro_dxList_entidad' />",
                                  ).dxList({
                                    dataSource: datasource_entidades,
                                    height: "100%",
                                    selectionMode: "single",
                                    keyExpr: "idEntidad",
                                    scrolling: { mode: "infinite" },
                                    pageLoadMode: "scrollBottom",
                                    searchEnabled: true,
                                    searchExpr: ["denominacion"],
                                    onItemClick: function (e) {
                                      $("#dxDropDownBox_selCompaEnti")
                                        .dxDropDownBox("instance")
                                        .close();
                                    },
                                    onSelectionChanged: function (e) {
                                      if (
                                        e.component.option().selectedItems
                                          .length > 0
                                      ) {
                                        // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                        if (
                                          e.component.option().selectedItems[0]
                                            .idEntidad !== 0 &&
                                          e.component.option().selectedItems[0]
                                            .idEntidad !== -1
                                        ) {
                                          // SI NO SE SELECCIONA TODAS O DE COMPAÑÍA
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            "idEntidad",
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            "denominacion",
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            datasource_entidades,
                                          );
                                          dxDropDownBox.component.option(
                                            "value",
                                            e.component.option()
                                              .selectedItems[0].idEntidad,
                                          );
                                        } else if (
                                          $("#filtro_dxList_compañia")
                                            .dxList("instance")
                                            .option().selectedItems[0]
                                            ?.idCompañia === 0
                                        ) {
                                          // SI ESTÁS EN TODAS LAS COMPAÑÍAS O EN PRENDAS DE COMPAÑIA
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          ); // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            null,
                                          );
                                        } else {
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          ); // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            "idCompañia",
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            "denominacion",
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            datasource_compañias,
                                          );
                                          dxDropDownBox.component.option(
                                            "value",
                                            $("#filtro_dxList_compañia")
                                              .dxList("instance")
                                              .option().selectedItems[0]
                                              ?.idCompañia,
                                          );
                                        }
                                        setDataSource_prodRech();
                                      }
                                    },
                                    itemTemplate: function (data, index) {
                                      return $("<div>").text(data.denominacion);
                                    },
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                    ],
                  });
                },
                onValueChanged: function (e) {
                  cambioTitulo();
                },
              },
            },
            {
              label: { text: getTrad("familias") },
              editorType: "dxRadioGroup",
              editorOptions: {
                elementAttr: {
                  id: "dxRadioGroup_familias",
                  class: "dxRadioGroup_opciones",
                },
                dataSource: datasource_familias,
                valueExpr: "idFamilia",
                displayExpr: "denominacion",
                width: "100%",
                value: 0,
                onValueChanged: function (e) {
                  setDataSource_prodRech();
                },
              },
            },
            {
              label: { text: getTrad("tipoProduccion") },
              editorType: "dxDropDownBox",
              editorOptions: {
                elementAttr: { id: "dxDropDownBox_tipoProduccion" },
                width: "100%",
                displayExpr: "siglas",
                deferRendering: false,
                valueExpr: "idTipoProduccion",
                placeholder: getTrad("todos").toUpperCase(),
                showClearButton: true,
                dataSource: datasource_tipoProduccion,
                contentTemplate: function (e) {
                  return $("<div id='dxList_TipoProduccion' />").dxList({
                    dataSource: e.component.option("dataSource"),
                    selectionMode: "multiple",
                    showSelectionControls: true,
                    displayExpr: "denominacion",
                    keyExpr: "idTipoProduccion",
                    onSelectionChanged: function (arg) {
                      setDataSource_prodRech();
                      e.component.option(
                        "value",
                        arg.component.option("selectedItemKeys"),
                      );
                    },
                    itemTemplate: function (data, index) {
                      return $("<div>").text(data.denominacion);
                    },
                  });
                },
                onValueChanged: function (e) {
                  // CLEAR BUTTON
                  if (!e.value)
                    $("#dxList_TipoProduccion")
                      .dxList("instance")
                      .unselectAll();
                },
              },
            },
            {
              label: { text: getTrad("turno") },
              editorType: "dxDropDownBox",
              editorOptions: {
                elementAttr: { id: "dxDropDownBox_turnos" },
                width: "100%",
                displayExpr: "denominacion",
                deferRendering: false,
                valueExpr: "idTurno",
                placeholder: getTrad("todos").toUpperCase(),
                showClearButton: true,
                dataSource: datasource_turnos,
                contentTemplate: function (e) {
                  return $("<div id='dxList_Turnos' />").dxList({
                    dataSource: e.component.option("dataSource"),
                    selectionMode: "multiple",
                    showSelectionControls: true,
                    displayExpr: "denominacion",
                    keyExpr: "idTurno",
                    onSelectionChanged: function (arg) {
                      setDataSource_prodRech();
                      e.component.option(
                        "value",
                        arg.component.option("selectedItemKeys"),
                      );
                    },
                    itemTemplate: function (data, index) {
                      return $("<div>").text(data.denominacion);
                    },
                  });
                },
                onValueChanged: function (e) {
                  // CLEAR BUTTON
                  if (!e.value)
                    $("#dxList_Turnos").dxList("instance").unselectAll();
                },
              },
            },
            {
              label: { text: getTrad("tipoTabla") },
              editorType: "dxRadioGroup",
              editorOptions: {
                elementAttr: {
                  id: "dxRadioGroup_unidadesPeso",
                  class: "dxRadioGroup_opciones",
                },
                dataSource: enum_tipoTabla,
                valueExpr: "idTipoTabla",
                displayExpr: "denominacion",
                layout: "horizontal",
                value: 1,
                height: "100%",
                onValueChanged: function (e) {
                  setDataSource_prodRech();
                  is_unidades = e.value === 1 ? true : false;
                },
              },
            },
          ],
        });

        $("#InfProduccion #dxContainer").dxBox({
          direction: "col",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          items: [
            {
              ratio: 1,
              template: function (itemData, itemIndex, itemElement) {
                itemElement.append(
                  $("<div />")
                    .addClass("h3")
                    .dxTabPanel({
                      height: "100%",
                      width: "100%",
                      deferRendering: false,
                      animationEnabled: true,
                      swipeEnabled: false,
                      items: [
                        {
                          title: getTrad("produccion"),
                          name: "produccion",
                          template: function (
                            itemData,
                            itemIndex,
                            itemElement,
                          ) {
                            itemElement.append(
                              $("<div />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                items: [
                                  {
                                    baseSize: 40,
                                    template: function (e, index, item) {
                                      item.append(
                                        $(
                                          "<div id='dxToolbar_title' />",
                                        ).dxToolbar({
                                          items: [
                                            {
                                              location: "before",
                                              template: function (
                                                e,
                                                index,
                                                item,
                                              ) {
                                                item.append(
                                                  $("<div/>")
                                                    .addClass("font-size")
                                                    .text(
                                                      getTrad("lavanderia") +
                                                        " - " +
                                                        LAVANDERIA.denominacion,
                                                    ),
                                                );
                                              },
                                            },
                                            {
                                              location: "after",
                                              widget: "dxButton",
                                              options: {
                                                text: getTrad("exportar"),
                                                icon: "exportxlsx",
                                                onClick: function () {
                                                  $(
                                                    "#dxPopup_seleccion_impresion",
                                                  )
                                                    .dxPopup("instance")
                                                    .show();
                                                },
                                              },
                                            },
                                          ],
                                        }),
                                      );
                                    },
                                  },
                                  {
                                    ratio: 1,
                                    template: function (e, index, item) {
                                      //#region La primera vez que se inicia coge estas fechas por defecto
                                      var ultimaFecha = new Date(
                                        datasource_filtroTiempo_spSelectProduccion.items()[
                                          datasource_filtroTiempo_spSelectProduccion.items()
                                            .length - 1
                                        ].arg,
                                      );
                                      fechaDesde = startOfMonth(ultimaFecha);
                                      fechaHasta = new Date(ultimaFecha);
                                      //#endregion

                                      is_unidades =
                                        $("#dxRadioGroup_unidadesPeso")
                                          .dxRadioGroup("instance")
                                          .option("value") === 1
                                          ? true
                                          : false;
                                      item.append(
                                        $(
                                          "<div id='dxDataGrid_produccionesPrenda' />",
                                        ).dxDataGrid({
                                          //Propiedades
                                          headerFilter: {
                                            visible: true,
                                          },
                                          hoverStateEnabled: true,
                                          export: {
                                            fileName: "infProduccion_Reducido",
                                          },
                                          filterRow: {
                                            visible: true,
                                            applyFilter: "auto",
                                            showAllText:
                                              getTrad("todos").toUpperCase(),
                                          },
                                          loadPanel: {
                                            enabled: false,
                                          },
                                          height: "100%",
                                          columnAutoWidth: true,
                                          paging: {
                                            enabled: false,
                                          },
                                          selection: {
                                            mode: "single",
                                          },
                                          columns: [
                                            {
                                              dataField: "codigoPrenda",
                                              caption: getTrad("codigo"),
                                              width: 100,
                                              sortIndex: 1,
                                              sortOrder: "asc",
                                            },
                                            {
                                              dataField: "denominacion",
                                              caption: getTrad("denominacion"),
                                              minWidth: 250,
                                            },
                                            {
                                              dataField: "producidas",
                                              caption: getTrad("producidas"),
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? formatNumber(data.value)
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                            {
                                              dataField: "rechazadas",
                                              caption: getTrad("rechazadas"),
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? formatNumber(data.value)
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                            {
                                              dataField: "retiradas",
                                              caption: getTrad("retiradas"),
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? formatNumber(data.value)
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                            {
                                              dataField: "procesadas",
                                              caption: getTrad("procesadas"),
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? formatNumber(data.value)
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                            {
                                              dataField: "porcentaje_rechazo",
                                              caption:
                                                "% " + getTrad("rechazo"),
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                            {
                                              dataField: "porcentaje_retiro",
                                              caption: "% " + getTrad("retiro"),
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              dataType: "string",
                                              minWidth: 150,
                                              allowFiltering: false,
                                              alignment: "center",
                                            },
                                          ],
                                          summary: {
                                            recalculateWhileEditing: true,
                                            totalItems: [
                                              {
                                                column: "denominacion",
                                                displayFormat:
                                                  getTrad(
                                                    "total",
                                                  ).toUpperCase(),
                                              },
                                              {
                                                column: "producidas",
                                                summaryType: "sum",
                                                customizeText: function (data) {
                                                  return is_unidades
                                                    ? ""
                                                    : formatNumber(
                                                        data.value,
                                                        2,
                                                        "decimal",
                                                      ) + " Kg";
                                                },
                                              },
                                              {
                                                column: "rechazadas",
                                                summaryType: "sum",
                                                customizeText: function (data) {
                                                  return is_unidades
                                                    ? ""
                                                    : formatNumber(
                                                        data.value,
                                                        2,
                                                        "decimal",
                                                      ) + " Kg";
                                                },
                                                displayFormat: "{0}",
                                              },
                                              {
                                                column: "retiradas",
                                                summaryType: "sum",
                                                customizeText: function (data) {
                                                  return is_unidades
                                                    ? ""
                                                    : formatNumber(
                                                        data.value,
                                                        2,
                                                        "decimal",
                                                      ) + " Kg";
                                                },
                                                displayFormat: "{0}",
                                              },
                                              {
                                                column: "procesadas",
                                                summaryType: "sum",
                                                customizeText: function (data) {
                                                  return is_unidades
                                                    ? ""
                                                    : formatNumber(
                                                        data.value,
                                                        2,
                                                        "decimal",
                                                      ) + " Kg";
                                                },
                                                displayFormat: "{0}",
                                              },
                                              {
                                                name: "porcentaje_rechazo",
                                                showInColumn:
                                                  "porcentaje_rechazo",
                                                summaryType: "custom",
                                                customizeText: function (data) {
                                                  return formatNumber(
                                                    data.value,
                                                    2,
                                                    "percent",
                                                  );
                                                },
                                                displayFormat: "{0}",
                                              },
                                              {
                                                name: "porcentaje_retiro",
                                                showInColumn:
                                                  "porcentaje_retiro",
                                                summaryType: "custom",
                                                customizeText: function (data) {
                                                  return formatNumber(
                                                    data.value,
                                                    2,
                                                    "percent",
                                                  );
                                                },
                                                displayFormat: "{0}",
                                              },
                                            ],
                                            calculateCustomSummary: function (
                                              options,
                                            ) {
                                              if (
                                                options.name ===
                                                "porcentaje_rechazo"
                                              ) {
                                                if (
                                                  options.summaryProcess ===
                                                  "start"
                                                ) {
                                                  options.totalValue = [0, 0];
                                                }
                                                if (
                                                  options.summaryProcess ===
                                                  "calculate"
                                                ) {
                                                  options.totalValue = [
                                                    options.totalValue[0] +
                                                      options.value.rechazadas,
                                                    options.totalValue[1] +
                                                      options.value.procesadas,
                                                  ];
                                                }
                                                if (
                                                  options.summaryProcess ===
                                                  "finalize"
                                                ) {
                                                  options.totalValue =
                                                    options.totalValue[0] /
                                                    options.totalValue[1];
                                                }
                                              }
                                              if (
                                                options.name ===
                                                "porcentaje_retiro"
                                              ) {
                                                if (
                                                  options.summaryProcess ===
                                                  "start"
                                                ) {
                                                  options.totalValue = [0, 0];
                                                }
                                                if (
                                                  options.summaryProcess ===
                                                  "calculate"
                                                ) {
                                                  options.totalValue = [
                                                    options.totalValue[0] +
                                                      options.value.retiradas,
                                                    options.totalValue[1] +
                                                      options.value.procesadas,
                                                  ];
                                                }
                                                if (
                                                  options.summaryProcess ===
                                                  "finalize"
                                                ) {
                                                  options.totalValue =
                                                    options.totalValue[0] /
                                                    options.totalValue[1];
                                                }
                                              }
                                            },
                                          },
                                          //Eventos
                                          onSelectionChanged: function (e) {
                                            if (e.selectedRowKeys.length > 0) {
                                              rowSel = e.selectedRowsData[0];

                                              let dxDataGrid_produccionFecha =
                                                $(
                                                  "#dxDataGrid_produccionFecha",
                                                ).dxDataGrid("instance");
                                              dxDataGrid_produccionFecha.beginCustomLoading();
                                              datasource_spSelectProduccion_fecha
                                                .load()
                                                .done(function (items) {
                                                  var dataSource = query(
                                                    filtroDatos_prodRech(items),
                                                  )
                                                    .groupBy(
                                                      function (dataItem) {
                                                        return [dataItem.fecha];
                                                      },
                                                    )
                                                    .select(
                                                      function (dataItem) {
                                                        var resultItem = null;
                                                        query(dataItem.items)
                                                          .sum("producidas")
                                                          .done(
                                                            function (result) {
                                                              resultItem = {
                                                                fecha:
                                                                  dataItem
                                                                    .key[0],
                                                                producidas:
                                                                  result,
                                                              };
                                                            },
                                                          );
                                                        query(dataItem.items)
                                                          .sum("rechazadas")
                                                          .done(
                                                            function (result) {
                                                              resultItem = {
                                                                fecha:
                                                                  dataItem
                                                                    .key[0],
                                                                producidas:
                                                                  resultItem.producidas,
                                                                rechazadas:
                                                                  result,
                                                              };
                                                            },
                                                          );
                                                        query(dataItem.items)
                                                          .sum("retiradas")
                                                          .done(
                                                            function (result) {
                                                              resultItem = {
                                                                fecha:
                                                                  dataItem
                                                                    .key[0],
                                                                producidas:
                                                                  resultItem.producidas,
                                                                rechazadas:
                                                                  resultItem.rechazadas,
                                                                retiradas:
                                                                  result,
                                                              };
                                                            },
                                                          );
                                                        query(dataItem.items)
                                                          .sum("procesadas")
                                                          .done(
                                                            function (result) {
                                                              resultItem = {
                                                                porcentaje_rechazo:
                                                                  result !== 0
                                                                    ? resultItem.rechazadas /
                                                                      result
                                                                    : 0,
                                                                porcentaje_retiro:
                                                                  result !== 0
                                                                    ? resultItem.retiradas /
                                                                      result
                                                                    : 0,
                                                                fecha:
                                                                  dataItem
                                                                    .key[0],
                                                                producidas:
                                                                  resultItem.producidas,
                                                                rechazadas:
                                                                  resultItem.rechazadas,
                                                                retiradas:
                                                                  resultItem.retiradas,
                                                                procesadas:
                                                                  result,
                                                              };
                                                            },
                                                          );
                                                        return resultItem;
                                                      },
                                                    )
                                                    .toArray();

                                                  dxDataGrid_produccionFecha.option(
                                                    {
                                                      dataSource: dataSource,
                                                    },
                                                  );
                                                  dxDataGrid_produccionFecha.endCustomLoading();
                                                });
                                              $("#titPopupProd_container").text(
                                                rowSel != null
                                                  ? rowSel.denominacion
                                                  : null,
                                              );
                                              $(
                                                "#dxPopup_seleccion_prendaProduccion",
                                              )
                                                .dxPopup("instance")
                                                .show();
                                            } else rowSel = null;
                                          },
                                          onExporting: function (e) {
                                            if (
                                              tipoImpresion ===
                                              "informeDetallado"
                                            ) {
                                              var workbook =
                                                new ExcelJS.Workbook();
                                              var worksheet =
                                                workbook.addWorksheet(
                                                  getTrad("kgLavados"),
                                                );

                                              function colorearCeldas_prenda(
                                                initBodyExcel_row,
                                                percProd,
                                                percRech,
                                                precRet,
                                              ) {
                                                var fontColor = "",
                                                  bgColor = "";

                                                //#region % Prod
                                                if (percProd < 0.8) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (percProd < 0.9) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (percProd < 0.95) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }

                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(12).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(12).font = {
                                                  color: { argb: fontColor },
                                                  bold: false,
                                                };
                                                //#endregion

                                                //#region % Rech
                                                if (percRech > 0.2) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (percRech > 0.1) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (percRech > 0.5) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(13).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(13).font = {
                                                  color: { argb: fontColor },
                                                };
                                                //#endregion

                                                //#region % Ret
                                                if (precRet > 0.2) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (precRet > 0.1) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (precRet > 0.5) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(14).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(14).font = {
                                                  color: { argb: fontColor },
                                                };
                                                //#endregion
                                              }

                                              function agruparDatos(items) {
                                                var dataSource = query(items)
                                                  .groupBy(function (dataItem) {
                                                    return [
                                                      dataItem.idPrenda,
                                                      dataItem.codigoPrenda,
                                                      dataItem.denoPrenda,
                                                      dataItem.peso,
                                                    ];
                                                  })
                                                  .select(function (dataItem) {
                                                    var resultItem = null;
                                                    query(dataItem.items)
                                                      .sum("producidas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("rechazadas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("retiradas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas:
                                                            resultItem.rechazadas,
                                                          retiradas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("procesadas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas:
                                                            resultItem.rechazadas,
                                                          retiradas:
                                                            resultItem.retiradas,
                                                          procesadas: result,
                                                        };
                                                      });
                                                    return resultItem;
                                                  })
                                                  .sortBy("codigoPrenda")
                                                  .toArray();
                                                return dataSource;
                                              }

                                              // Width de las columnas
                                              worksheet.columns = [
                                                { width: 11 },
                                                { width: 16 },
                                                { width: 56 },
                                                { width: 11 },
                                                { width: 11 },
                                                { width: 11 },
                                                { width: 10 },
                                                { width: 11 },
                                                { width: 10 },
                                                { width: 12 },
                                                { width: 16.5 },
                                                { width: 12 },
                                                { width: 12 },
                                                { width: 12 },
                                              ];

                                              //#region Título
                                              Object.assign(
                                                worksheet.getRow(2).getCell(2),
                                                {
                                                  value:
                                                    getTrad(
                                                      "kgProcesadosCliente",
                                                    ).toUpperCase() +
                                                    " - " +
                                                    LAVANDERIA.denominacion,
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "center",
                                                  },
                                                },
                                              );
                                              // merge by start row, start column, end row, end column
                                              worksheet.mergeCells(2, 2, 2, 7);

                                              Object.assign(
                                                worksheet.getRow(3).getCell(2),
                                                {
                                                  value:
                                                    formatDate(fechaDesde) +
                                                    " - " +
                                                    formatDate(fechaHasta),
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "center",
                                                  },
                                                },
                                              );
                                              // merge by start row, start column, end row, end column
                                              worksheet.mergeCells(3, 2, 3, 7);
                                              //#endregion

                                              var filtro_dxList_entidad = $(
                                                  "#filtro_dxList_entidad",
                                                ).dxList("instance"),
                                                filtro_dxList_compañia = $(
                                                  "#filtro_dxList_compañia",
                                                ).dxList("instance"),
                                                idCompaSel =
                                                  filtro_dxList_compañia.option(
                                                    "selectedItemKeys",
                                                  ).length > 0
                                                    ? filtro_dxList_compañia.option(
                                                        "selectedItemKeys",
                                                      )[0]
                                                    : 0,
                                                idEntidadSel =
                                                  filtro_dxList_entidad.option(
                                                    "selectedItemKeys",
                                                  ).length > 0
                                                    ? filtro_dxList_entidad.option(
                                                        "selectedItemKeys",
                                                      )[0]
                                                    : 0,
                                                is_prendasCompañia =
                                                  idCompaSel !== 0 &&
                                                  idEntidadSel === -1; // Afecta a la impresión

                                              var compañias = $.grep(
                                                datasource_compañias.items(),
                                                function (item) {
                                                  return (
                                                    item.idCompañia !== 0 &&
                                                    ((idCompaSel !== 0 &&
                                                      item.idCompañia ===
                                                        idCompaSel) ||
                                                      idCompaSel === 0) &&
                                                    ((idEntidadSel !== 0 &&
                                                      idEntidadSel !== 1 &&
                                                      filtro_dxList_entidad.option(
                                                        "selectedItem",
                                                      ).idCompañia ===
                                                        item.idCompañia) ||
                                                      idEntidadSel === 0 ||
                                                      idEntidadSel === -1)
                                                  );
                                                },
                                              );
                                              var entidades = $.grep(
                                                datasource_entidades.items(),
                                                function (item) {
                                                  return (
                                                    item.idEntidad !== 0 &&
                                                    is_prendasCompañia ===
                                                      false &&
                                                    ((idEntidadSel !== 0 &&
                                                      item.idEntidad ===
                                                        idEntidadSel) ||
                                                      idEntidadSel === 0)
                                                  );
                                                },
                                              );
                                              var initBodyExcel_row = 5;

                                              $.each(
                                                compañias,
                                                function (
                                                  compaIndex,
                                                  compaItem,
                                                ) {
                                                  var objSumatorio_compañias = {
                                                    kgProd: 0,
                                                    kgRech: 0,
                                                    kgRet: 0,
                                                    ttKgUdsProc: 0,
                                                  };

                                                  //#region Headers prendas
                                                  var gridPrincipal_tableHeaders =
                                                    [
                                                      getTrad("codigo"),
                                                      getTrad("denominacion"),
                                                      getTrad("udsProd_abr"),
                                                      getTrad("kgProd_abr"),
                                                      getTrad("udsRech_abr"),
                                                      getTrad("kgRech_abr"),
                                                      getTrad("udsRet_abr"),
                                                      getTrad("kgRet_abr"),
                                                      getTrad("ttUdsProc_abr"),
                                                      getTrad(
                                                        "ttKgUdsProc_abr",
                                                      ),
                                                      getTrad("percKgProd_abr"),
                                                      getTrad("percKgRech_abr"),
                                                      getTrad("percKgRet_abr"),
                                                    ];

                                                  $.each(
                                                    gridPrincipal_tableHeaders,
                                                    function (
                                                      headerIndex,
                                                      headerValue,
                                                    ) {
                                                      Object.assign(
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(
                                                            headerIndex + 2,
                                                          ),
                                                        {
                                                          value: headerValue,
                                                          font: { bold: true },
                                                          fill: {
                                                            type: "pattern",
                                                            pattern: "solid",
                                                            fgColor: {
                                                              argb: "FFCC00",
                                                            },
                                                          },
                                                          border: {
                                                            top: {
                                                              style: "medium",
                                                            },
                                                            left: {
                                                              style:
                                                                headerIndex ===
                                                                0
                                                                  ? "medium"
                                                                  : "",
                                                            },
                                                            bottom: {
                                                              style: "medium",
                                                            },
                                                            right: {
                                                              style:
                                                                headerIndex ===
                                                                12
                                                                  ? "medium"
                                                                  : "",
                                                            },
                                                          },
                                                          alignment: {
                                                            vertical: "middle",
                                                            horizontal:
                                                              headerIndex === 1
                                                                ? "left"
                                                                : "center",
                                                          },
                                                        },
                                                      );
                                                    },
                                                  );
                                                  initBodyExcel_row++;
                                                  //#endregion

                                                  //#region Header Compañía
                                                  Object.assign(
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(2),
                                                    {
                                                      value:
                                                        getTrad(
                                                          "compañia",
                                                        ).toUpperCase() +
                                                        ": " +
                                                        compaItem.denominacion.toUpperCase(),
                                                      fill: {
                                                        type: "pattern",
                                                        pattern: "solid",
                                                        fgColor: {
                                                          argb: "D9D9D9",
                                                        },
                                                      },
                                                      border: {
                                                        top: {
                                                          style: "medium",
                                                        },
                                                        left: {
                                                          style: "medium",
                                                        },
                                                        bottom: {
                                                          style: "medium",
                                                        },
                                                        right: {
                                                          style: "medium",
                                                        },
                                                      },
                                                    },
                                                  );
                                                  // merge by start row, start column, end row, end column
                                                  worksheet.mergeCells(
                                                    initBodyExcel_row,
                                                    2,
                                                    initBodyExcel_row,
                                                    14,
                                                  );
                                                  initBodyExcel_row++;
                                                  //#endregion

                                                  //#region Prendas de compañía
                                                  var prendasNComp =
                                                    agruparDatos(
                                                      filtroDatos_prodRech(
                                                        $.grep(
                                                          datasource_spSelectProduccion.items(),
                                                          function (prodItem) {
                                                            return (
                                                              compaItem.idCompañia ===
                                                              prodItem.idCompañia
                                                            );
                                                          },
                                                        ),
                                                        true,
                                                      ),
                                                    );

                                                  if (
                                                    prendasNComp.length === 0
                                                  ) {
                                                    Object.assign(
                                                      worksheet
                                                        .getRow(
                                                          initBodyExcel_row,
                                                        )
                                                        .getCell(2),
                                                      {
                                                        value:
                                                          getTrad(
                                                            "noLavPrendasCompa",
                                                          ),
                                                        border: {
                                                          top: {
                                                            style: "medium",
                                                          },
                                                          left: {
                                                            style: "medium",
                                                          },
                                                          bottom: {
                                                            style: "medium",
                                                          },
                                                          right: {
                                                            style: "medium",
                                                          },
                                                        },
                                                      },
                                                    );
                                                    worksheet.mergeCells(
                                                      initBodyExcel_row,
                                                      2,
                                                      initBodyExcel_row,
                                                      14,
                                                    );
                                                    initBodyExcel_row++;
                                                  } else {
                                                    $.each(
                                                      prendasNComp,
                                                      function (
                                                        prendaIndex,
                                                        prendaItem,
                                                      ) {
                                                        var peso =
                                                          prendaItem.peso *
                                                          0.001;

                                                        // Sumatorio subtotal y total
                                                        objSumatorio_compañias.kgProd +=
                                                          prendaItem.producidas *
                                                          peso;
                                                        objSumatorio_compañias.kgRech +=
                                                          prendaItem.rechazadas *
                                                          peso;
                                                        objSumatorio_compañias.kgRet +=
                                                          prendaItem.retiradas *
                                                          peso;
                                                        objSumatorio_compañias.ttKgUdsProc +=
                                                          prendaItem.procesadas *
                                                          peso;

                                                        //#region Columnas de prendas
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(2).value =
                                                          prendaItem.codigoPrenda;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(2).numFmt =
                                                          "#";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(3).value =
                                                          prendaItem.denoPrenda;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(4).value =
                                                          prendaItem.producidas;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(5).value =
                                                          prendaItem.producidas *
                                                          peso;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(5).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(6).value =
                                                          prendaItem.rechazadas;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(7).value =
                                                          prendaItem.rechazadas *
                                                          peso;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(7).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(8).value =
                                                          prendaItem.retiradas;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(9).value =
                                                          prendaItem.retiradas *
                                                          peso;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(9).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(10).value =
                                                          prendaItem.procesadas;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(11).value =
                                                          prendaItem.procesadas *
                                                          peso;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(11).numFmt =
                                                          "0.00";

                                                        let percProd =
                                                            prendaItem.producidas *
                                                              peso !==
                                                              0 &&
                                                            prendaItem.procesadas *
                                                              peso !==
                                                              0
                                                              ? (prendaItem.producidas *
                                                                  peso) /
                                                                (prendaItem.procesadas *
                                                                  peso)
                                                              : 0,
                                                          percRech =
                                                            prendaItem.rechazadas *
                                                              peso !==
                                                              0 &&
                                                            prendaItem.procesadas *
                                                              peso !==
                                                              0
                                                              ? (prendaItem.rechazadas *
                                                                  peso) /
                                                                (prendaItem.procesadas *
                                                                  peso)
                                                              : 0,
                                                          precRet =
                                                            prendaItem.retiradas *
                                                              peso !==
                                                              0 &&
                                                            prendaItem.procesadas *
                                                              peso !==
                                                              0
                                                              ? (prendaItem.retiradas *
                                                                  peso) /
                                                                (prendaItem.procesadas *
                                                                  peso)
                                                              : 0;

                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(12).value =
                                                          formatNumber(
                                                            percProd,
                                                            2,
                                                            "percent",
                                                          );
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(13).value =
                                                          formatNumber(
                                                            percRech,
                                                            2,
                                                            "percent",
                                                          );
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(14).value =
                                                          formatNumber(
                                                            precRet,
                                                            2,
                                                            "percent",
                                                          );

                                                        colorearCeldas_prenda(
                                                          initBodyExcel_row,
                                                          percProd,
                                                          percRech,
                                                          precRet,
                                                        );

                                                        $.each(
                                                          gridPrincipal_tableHeaders,
                                                          function (
                                                            headerIndex,
                                                            headerValue,
                                                          ) {
                                                            Object.assign(
                                                              worksheet
                                                                .getRow(
                                                                  initBodyExcel_row,
                                                                )
                                                                .getCell(
                                                                  headerIndex +
                                                                    2,
                                                                ),
                                                              {
                                                                alignment: {
                                                                  vertical:
                                                                    "middle",
                                                                  horizontal:
                                                                    headerIndex ===
                                                                    1
                                                                      ? "left"
                                                                      : "center",
                                                                },
                                                                border: {
                                                                  left: {
                                                                    style:
                                                                      headerIndex ===
                                                                      0
                                                                        ? "medium"
                                                                        : "thin",
                                                                  },
                                                                  right: {
                                                                    style:
                                                                      headerIndex ===
                                                                      12
                                                                        ? "medium"
                                                                        : "thin",
                                                                  },
                                                                },
                                                              },
                                                            );
                                                          },
                                                        );
                                                        initBodyExcel_row++;
                                                        //#endregion
                                                      },
                                                    );

                                                    //#region Cálculo Subtotal
                                                    var percProd =
                                                        objSumatorio_compañias.kgProd !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgProd /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0,
                                                      percRech =
                                                        objSumatorio_compañias.kgRech !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgRech /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0,
                                                      precRet =
                                                        objSumatorio_compañias.kgRet !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgRet /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0;

                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(2).value =
                                                      getTrad(
                                                        "subtotal",
                                                      ).toUpperCase();
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(5).value =
                                                      objSumatorio_compañias.kgProd;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(5).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(7).value =
                                                      objSumatorio_compañias.kgRech;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(7).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(9).value =
                                                      objSumatorio_compañias.kgRet;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(9).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(11).value =
                                                      objSumatorio_compañias.ttKgUdsProc;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(11).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(12).value =
                                                      formatNumber(
                                                        percProd,
                                                        2,
                                                        "percent",
                                                      );
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(13).value =
                                                      formatNumber(
                                                        percRech,
                                                        2,
                                                        "percent",
                                                      );
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(14).value =
                                                      formatNumber(
                                                        precRet,
                                                        2,
                                                        "percent",
                                                      );

                                                    colorearCeldas_prenda(
                                                      initBodyExcel_row,
                                                      percProd,
                                                      percRech,
                                                      precRet,
                                                    );

                                                    $.each(
                                                      gridPrincipal_tableHeaders,
                                                      function (
                                                        headerIndex,
                                                        headerValue,
                                                      ) {
                                                        //#region Si asignamos font en las celdas en las que ya esta asignada se sobreescribe. Si añadimos bold a font, en los casos que no exista previemente font, peta.
                                                        var cellFont = worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(
                                                            headerIndex + 2,
                                                          ).font;
                                                        if (
                                                          typeof cellFont !==
                                                          "undefined"
                                                        )
                                                          cellFont.bold = true;
                                                        else
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(
                                                              headerIndex + 2,
                                                            ).font = {
                                                            bold: true,
                                                          };
                                                        //#endregion

                                                        Object.assign(
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(
                                                              headerIndex + 2,
                                                            ),
                                                          {
                                                            fill: {
                                                              type: "pattern",
                                                              pattern: "solid",
                                                              fgColor: {
                                                                argb: "FFCC00",
                                                              },
                                                            },
                                                            border: {
                                                              top: {
                                                                style: "medium",
                                                              },
                                                              left: {
                                                                style:
                                                                  headerIndex ===
                                                                  0
                                                                    ? "medium"
                                                                    : "thin",
                                                              },
                                                              bottom: {
                                                                style: "medium",
                                                              },
                                                              right: {
                                                                style:
                                                                  headerIndex ===
                                                                  12
                                                                    ? "medium"
                                                                    : "thin",
                                                              },
                                                            },
                                                            alignment: {
                                                              vertical:
                                                                "middle",
                                                              horizontal:
                                                                headerIndex ===
                                                                0
                                                                  ? "left"
                                                                  : "center",
                                                            },
                                                          },
                                                        );
                                                      },
                                                    );
                                                    initBodyExcel_row++;
                                                    //#endregion
                                                  }
                                                  //#endregion

                                                  //#region Prendas de entidad
                                                  var entiFiltradas = $.grep(
                                                    entidades,
                                                    function (entidad) {
                                                      return (
                                                        compaItem.idCompañia ===
                                                        entidad.idCompañia
                                                      );
                                                    },
                                                  );
                                                  $.each(
                                                    entiFiltradas,
                                                    function (
                                                      indexEntidad,
                                                      itemEntidad,
                                                    ) {
                                                      //#region Header Compañía
                                                      Object.assign(
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(2),
                                                        {
                                                          value:
                                                            getTrad(
                                                              "entidad",
                                                            ).toUpperCase() +
                                                            ": " +
                                                            itemEntidad.denominacion.toUpperCase(),
                                                          fill: {
                                                            type: "pattern",
                                                            pattern: "solid",
                                                            fgColor: {
                                                              argb: "D9D9D9",
                                                            },
                                                          },
                                                          border: {
                                                            top: {
                                                              style: "medium",
                                                            },
                                                            left: {
                                                              style: "medium",
                                                            },
                                                            bottom: {
                                                              style: "medium",
                                                            },
                                                            right: {
                                                              style: "medium",
                                                            },
                                                          },
                                                        },
                                                      );
                                                      // merge by start row, start column, end row, end column
                                                      worksheet.mergeCells(
                                                        initBodyExcel_row,
                                                        2,
                                                        initBodyExcel_row,
                                                        14,
                                                      );
                                                      initBodyExcel_row++;
                                                      //#endregion

                                                      var objSumatorio_entidades =
                                                        {
                                                          kgProd: 0,
                                                          kgRech: 0,
                                                          kgRet: 0,
                                                          ttKgUdsProc: 0,
                                                        };

                                                      var prendasNEnti =
                                                        agruparDatos(
                                                          filtroDatos_prodRech(
                                                            $.grep(
                                                              datasource_spSelectProduccion.items(),
                                                              function (
                                                                prodItem,
                                                              ) {
                                                                return (
                                                                  itemEntidad.idEntidad ===
                                                                  prodItem.idEntidad
                                                                );
                                                              },
                                                            ),
                                                            true,
                                                          ),
                                                        );

                                                      if (
                                                        prendasNEnti.length ===
                                                        0
                                                      ) {
                                                        Object.assign(
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(2),
                                                          {
                                                            value:
                                                              getTrad(
                                                                "noLavPrendasEnti",
                                                              ),
                                                            border: {
                                                              top: {
                                                                style: "medium",
                                                              },
                                                              left: {
                                                                style: "medium",
                                                              },
                                                              bottom: {
                                                                style: "medium",
                                                              },
                                                              right: {
                                                                style: "medium",
                                                              },
                                                            },
                                                          },
                                                        );
                                                        worksheet.mergeCells(
                                                          initBodyExcel_row,
                                                          2,
                                                          initBodyExcel_row,
                                                          14,
                                                        );
                                                        initBodyExcel_row++;
                                                      } else {
                                                        $.each(
                                                          prendasNEnti,
                                                          function (
                                                            prendaIndex,
                                                            prendaItem,
                                                          ) {
                                                            var peso =
                                                              prendaItem.peso *
                                                              0.001;

                                                            //Sumatorio subtotal y total
                                                            objSumatorio_entidades.kgProd +=
                                                              prendaItem.producidas *
                                                              peso;
                                                            objSumatorio_entidades.kgRech +=
                                                              prendaItem.rechazadas *
                                                              peso;
                                                            objSumatorio_entidades.kgRet +=
                                                              prendaItem.retiradas *
                                                              peso;
                                                            objSumatorio_entidades.ttKgUdsProc +=
                                                              prendaItem.procesadas *
                                                              peso;

                                                            //#region Columnas de prendas
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                2,
                                                              ).value =
                                                              prendaItem.codigoPrenda;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                2,
                                                              ).numFmt = "#";
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                3,
                                                              ).value =
                                                              prendaItem.denoPrenda;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                4,
                                                              ).value =
                                                              prendaItem.producidas;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                5,
                                                              ).value =
                                                              prendaItem.producidas *
                                                              peso;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                5,
                                                              ).numFmt = "0.00";
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                6,
                                                              ).value =
                                                              prendaItem.rechazadas;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                7,
                                                              ).value =
                                                              prendaItem.rechazadas *
                                                              peso;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                7,
                                                              ).numFmt = "0.00";
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                8,
                                                              ).value =
                                                              prendaItem.retiradas;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                9,
                                                              ).value =
                                                              prendaItem.retiradas *
                                                              peso;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                9,
                                                              ).numFmt = "0.00";
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                10,
                                                              ).value =
                                                              prendaItem.procesadas;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                11,
                                                              ).value =
                                                              prendaItem.procesadas *
                                                              peso;
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                11,
                                                              ).numFmt = "0.00";

                                                            let percProd =
                                                                prendaItem.producidas *
                                                                  peso !==
                                                                  0 &&
                                                                (prendaItem.procesadas *
                                                                  peso !==
                                                                  0) !==
                                                                  0
                                                                  ? (prendaItem.producidas *
                                                                      peso) /
                                                                    (prendaItem.procesadas *
                                                                      peso)
                                                                  : 0,
                                                              percRech =
                                                                prendaItem.rechazadas *
                                                                  peso !==
                                                                  0 &&
                                                                (prendaItem.procesadas *
                                                                  peso !==
                                                                  0) !==
                                                                  0
                                                                  ? (prendaItem.rechazadas *
                                                                      peso) /
                                                                    (prendaItem.procesadas *
                                                                      peso)
                                                                  : 0,
                                                              precRet =
                                                                prendaItem.retiradas *
                                                                  peso !==
                                                                  0 &&
                                                                (prendaItem.procesadas *
                                                                  peso !==
                                                                  0) !==
                                                                  0
                                                                  ? (prendaItem.retiradas *
                                                                      peso) /
                                                                    (prendaItem.procesadas *
                                                                      peso)
                                                                  : 0;

                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                12,
                                                              ).value =
                                                              formatNumber(
                                                                percProd,
                                                                2,
                                                                "percent",
                                                              );
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                13,
                                                              ).value =
                                                              formatNumber(
                                                                percRech,
                                                                2,
                                                                "percent",
                                                              );
                                                            worksheet
                                                              .getRow(
                                                                initBodyExcel_row,
                                                              )
                                                              .getCell(
                                                                14,
                                                              ).value =
                                                              formatNumber(
                                                                precRet,
                                                                2,
                                                                "percent",
                                                              );

                                                            colorearCeldas_prenda(
                                                              initBodyExcel_row,
                                                              percProd,
                                                              percRech,
                                                              precRet,
                                                            );

                                                            $.each(
                                                              gridPrincipal_tableHeaders,
                                                              function (
                                                                headerIndex,
                                                                headerValue,
                                                              ) {
                                                                Object.assign(
                                                                  worksheet
                                                                    .getRow(
                                                                      initBodyExcel_row,
                                                                    )
                                                                    .getCell(
                                                                      headerIndex +
                                                                        2,
                                                                    ),
                                                                  {
                                                                    alignment: {
                                                                      vertical:
                                                                        "middle",
                                                                      horizontal:
                                                                        headerIndex ===
                                                                        1
                                                                          ? "left"
                                                                          : "center",
                                                                    },
                                                                    border: {
                                                                      left: {
                                                                        style:
                                                                          headerIndex ===
                                                                          0
                                                                            ? "medium"
                                                                            : "thin",
                                                                      },
                                                                      right: {
                                                                        style:
                                                                          headerIndex ===
                                                                          12
                                                                            ? "medium"
                                                                            : "thin",
                                                                      },
                                                                    },
                                                                  },
                                                                );
                                                              },
                                                            );
                                                            initBodyExcel_row++;
                                                            //#endregion
                                                          },
                                                        );

                                                        //#region Cálculo Subtotal
                                                        objSumatorio_compañias.kgProd +=
                                                          objSumatorio_entidades.kgProd;
                                                        objSumatorio_compañias.kgRech +=
                                                          objSumatorio_entidades.kgRech;
                                                        objSumatorio_compañias.kgRet +=
                                                          objSumatorio_entidades.kgRet;
                                                        objSumatorio_compañias.ttKgUdsProc +=
                                                          objSumatorio_entidades.ttKgUdsProc;

                                                        let percProd =
                                                            objSumatorio_entidades.kgProd !==
                                                              0 &&
                                                            objSumatorio_entidades.ttKgUdsProc !==
                                                              0
                                                              ? objSumatorio_entidades.kgProd /
                                                                objSumatorio_entidades.ttKgUdsProc
                                                              : 0,
                                                          percRech =
                                                            objSumatorio_entidades.kgRech !==
                                                              0 &&
                                                            objSumatorio_entidades.ttKgUdsProc !==
                                                              0
                                                              ? objSumatorio_entidades.kgRech /
                                                                objSumatorio_entidades.ttKgUdsProc
                                                              : 0,
                                                          precRet =
                                                            objSumatorio_entidades.kgRet !==
                                                              0 &&
                                                            objSumatorio_entidades.ttKgUdsProc !==
                                                              0
                                                              ? objSumatorio_entidades.kgRet /
                                                                objSumatorio_entidades.ttKgUdsProc
                                                              : 0;

                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(2).value =
                                                          getTrad(
                                                            "subtotal",
                                                          ).toUpperCase();
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(5).value =
                                                          objSumatorio_entidades.kgProd;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(5).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(7).value =
                                                          objSumatorio_entidades.kgRech;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(7).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(9).value =
                                                          objSumatorio_entidades.kgRet;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(9).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(11).value =
                                                          objSumatorio_entidades.ttKgUdsProc;
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(11).numFmt =
                                                          "0.00";
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(12).value =
                                                          formatNumber(
                                                            percProd,
                                                            2,
                                                            "percent",
                                                          );
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(13).value =
                                                          formatNumber(
                                                            percRech,
                                                            2,
                                                            "percent",
                                                          );
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(14).value =
                                                          formatNumber(
                                                            precRet,
                                                            2,
                                                            "percent",
                                                          );

                                                        colorearCeldas_prenda(
                                                          initBodyExcel_row,
                                                          percProd,
                                                          percRech,
                                                          precRet,
                                                        );

                                                        $.each(
                                                          gridPrincipal_tableHeaders,
                                                          function (
                                                            headerIndex,
                                                            headerValue,
                                                          ) {
                                                            var cellFont =
                                                              worksheet
                                                                .getRow(
                                                                  initBodyExcel_row,
                                                                )
                                                                .getCell(
                                                                  headerIndex +
                                                                    2,
                                                                ).font;
                                                            if (
                                                              typeof cellFont !==
                                                              "undefined"
                                                            )
                                                              cellFont.bold = true;
                                                            else
                                                              worksheet
                                                                .getRow(
                                                                  initBodyExcel_row,
                                                                )
                                                                .getCell(
                                                                  headerIndex +
                                                                    2,
                                                                ).font = {
                                                                bold: true,
                                                              };

                                                            Object.assign(
                                                              worksheet
                                                                .getRow(
                                                                  initBodyExcel_row,
                                                                )
                                                                .getCell(
                                                                  headerIndex +
                                                                    2,
                                                                ),
                                                              {
                                                                fill: {
                                                                  type: "pattern",
                                                                  pattern:
                                                                    "solid",
                                                                  fgColor: {
                                                                    argb: "FFCC00",
                                                                  },
                                                                },
                                                                border: {
                                                                  top: {
                                                                    style:
                                                                      "medium",
                                                                  },
                                                                  left: {
                                                                    style:
                                                                      headerIndex ===
                                                                      0
                                                                        ? "medium"
                                                                        : "thin",
                                                                  },
                                                                  bottom: {
                                                                    style:
                                                                      "medium",
                                                                  },
                                                                  right: {
                                                                    style:
                                                                      headerIndex ===
                                                                      12
                                                                        ? "medium"
                                                                        : "thin",
                                                                  },
                                                                },
                                                                alignment: {
                                                                  vertical:
                                                                    "middle",
                                                                  horizontal:
                                                                    headerIndex ===
                                                                    0
                                                                      ? "left"
                                                                      : "center",
                                                                },
                                                              },
                                                            );
                                                          },
                                                        );
                                                        initBodyExcel_row++;
                                                        //#endregion
                                                      }
                                                    },
                                                  );
                                                  //#endregion

                                                  //#region Total compañía
                                                  if (
                                                    objSumatorio_compañias.ttKgUdsProc >
                                                    0
                                                  ) {
                                                    let percProd =
                                                        objSumatorio_compañias.kgProd !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgProd /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0,
                                                      percRech =
                                                        objSumatorio_compañias.kgRech !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgRech /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0,
                                                      precRet =
                                                        objSumatorio_compañias.kgRet !==
                                                          0 &&
                                                        objSumatorio_compañias.ttKgUdsProc !==
                                                          0
                                                          ? objSumatorio_compañias.kgRet /
                                                            objSumatorio_compañias.ttKgUdsProc
                                                          : 0;

                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(2).value =
                                                      getTrad(
                                                        "totalCompa",
                                                      ).toUpperCase();
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(5).value =
                                                      objSumatorio_compañias.kgProd;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(5).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(7).value =
                                                      objSumatorio_compañias.kgRech;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(7).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(9).value =
                                                      objSumatorio_compañias.kgRet;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(9).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(11).value =
                                                      objSumatorio_compañias.ttKgUdsProc;
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(11).numFmt =
                                                      "0.00";
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(12).value =
                                                      formatNumber(
                                                        percProd,
                                                        2,
                                                        "percent",
                                                      );
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(13).value =
                                                      formatNumber(
                                                        percRech,
                                                        2,
                                                        "percent",
                                                      );
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(14).value =
                                                      formatNumber(
                                                        precRet,
                                                        2,
                                                        "percent",
                                                      );

                                                    colorearCeldas_prenda(
                                                      initBodyExcel_row,
                                                      percProd,
                                                      percRech,
                                                      precRet,
                                                    );

                                                    $.each(
                                                      gridPrincipal_tableHeaders,
                                                      function (
                                                        headerIndex,
                                                        headerValue,
                                                      ) {
                                                        var cellFont = worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(
                                                            headerIndex + 2,
                                                          ).font;
                                                        if (
                                                          typeof cellFont !==
                                                          "undefined"
                                                        )
                                                          cellFont.bold = true;
                                                        else
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(
                                                              headerIndex + 2,
                                                            ).font = {
                                                            bold: true,
                                                          };

                                                        Object.assign(
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(
                                                              headerIndex + 2,
                                                            ),
                                                          {
                                                            fill: {
                                                              type: "pattern",
                                                              pattern: "solid",
                                                              fgColor: {
                                                                argb: "F39D03",
                                                              },
                                                            },
                                                            border: {
                                                              top: {
                                                                style: "medium",
                                                              },
                                                              left: {
                                                                style:
                                                                  headerIndex ===
                                                                  0
                                                                    ? "medium"
                                                                    : "thin",
                                                              },
                                                              bottom: {
                                                                style: "medium",
                                                              },
                                                              right: {
                                                                style:
                                                                  headerIndex ===
                                                                  12
                                                                    ? "medium"
                                                                    : "thin",
                                                              },
                                                            },
                                                          },
                                                        );
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(
                                                            headerIndex + 3,
                                                          ).alignment = {
                                                          vertical: "middle",
                                                          horizontal: "center",
                                                        };
                                                      },
                                                    );
                                                    initBodyExcel_row++;
                                                  }
                                                  //#endregion

                                                  initBodyExcel_row++;
                                                  initBodyExcel_row++;
                                                },
                                              );

                                              workbook.xlsx
                                                .writeBuffer()
                                                .then(function (buffer) {
                                                  var formatoFecha =
                                                    new Date().getDate() +
                                                    "_" +
                                                    new Date().getMonth() +
                                                    "_" +
                                                    new Date().getFullYear();
                                                  saveAs(
                                                    new Blob([buffer], {
                                                      type: "application/octet-stream",
                                                    }),
                                                    "InfProduccion_" +
                                                      formatoFecha +
                                                      ".xlsx",
                                                  );
                                                });

                                              e.cancel = true;
                                            } else if (
                                              tipoImpresion ==
                                              "informeDetallado_analisis"
                                            ) {
                                              var workbook =
                                                new ExcelJS.Workbook();
                                              var worksheet =
                                                workbook.addWorksheet(
                                                  "Informe_detallado_análisis",
                                                );

                                              function colorearCeldas_prenda(
                                                initBodyExcel_row,
                                                percProd,
                                                percRech,
                                                precRet,
                                              ) {
                                                var fontColor = "",
                                                  bgColor = "";

                                                //#region % Prod
                                                if (percProd < 0.8) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (percProd < 0.9) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (percProd < 0.95) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }

                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(14).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(14).font = {
                                                  color: { argb: fontColor },
                                                  bold: false,
                                                };
                                                //#endregion

                                                //#region % Rech
                                                if (percRech > 0.2) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (percRech > 0.1) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (percRech > 0.5) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(15).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(15).font = {
                                                  color: { argb: fontColor },
                                                };
                                                //#endregion

                                                //#region % Ret
                                                if (precRet > 0.2) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .rojo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .rojo;
                                                } else if (precRet > 0.1) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .naranja;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .naranja;
                                                } else if (precRet > 0.5) {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .amarillo;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .amarillo;
                                                } else {
                                                  fontColor =
                                                    enum_coloresExcel.letra
                                                      .verde;
                                                  bgColor =
                                                    enum_coloresExcel.fondo
                                                      .verde;
                                                }
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(16).fill = {
                                                  type: "pattern",
                                                  pattern: "solid",
                                                  fgColor: {
                                                    argb: bgColor,
                                                  },
                                                };
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(16).font = {
                                                  color: { argb: fontColor },
                                                };
                                                //#endregion
                                              }

                                              function agruparDatos(items) {
                                                var dataSource = query(items)
                                                  .groupBy(function (dataItem) {
                                                    return [
                                                      dataItem.idPrenda,
                                                      dataItem.codigoPrenda,
                                                      dataItem.denoPrenda,
                                                      dataItem.peso,
                                                    ];
                                                  })
                                                  .select(function (dataItem) {
                                                    var resultItem = null;
                                                    query(dataItem.items)
                                                      .sum("producidas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("rechazadas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("retiradas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas:
                                                            resultItem.rechazadas,
                                                          retiradas: result,
                                                        };
                                                      });
                                                    query(dataItem.items)
                                                      .sum("procesadas")
                                                      .done(function (result) {
                                                        resultItem = {
                                                          idPrenda:
                                                            dataItem.key[0],
                                                          codigoPrenda:
                                                            dataItem.key[1],
                                                          denoPrenda:
                                                            dataItem.key[2],
                                                          peso: dataItem.key[3],
                                                          producidas:
                                                            resultItem.producidas,
                                                          rechazadas:
                                                            resultItem.rechazadas,
                                                          retiradas:
                                                            resultItem.retiradas,
                                                          procesadas: result,
                                                        };
                                                      });
                                                    return resultItem;
                                                  })
                                                  .sortBy("codigoPrenda")
                                                  .toArray();
                                                return dataSource;
                                              }

                                              // Width de las columnas
                                              worksheet.columns = [
                                                { width: 11 },
                                                { width: 35 },
                                                { width: 35 },
                                                { width: 10 },
                                                { width: 56 },
                                                { width: 11 },
                                                { width: 11 },
                                                { width: 10 },
                                                { width: 11 },
                                                { width: 10 },
                                                { width: 12 },
                                                { width: 16.5 },
                                                { width: 12 },
                                                { width: 12 },
                                                { width: 12 },
                                              ];

                                              //#region Título
                                              Object.assign(
                                                worksheet.getRow(2).getCell(2),
                                                {
                                                  value: getTrad("lavanderia"),
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "left",
                                                  },
                                                  fill: {
                                                    type: "pattern",
                                                    pattern: "solid",
                                                    fgColor: {
                                                      argb: "FFCC00",
                                                    },
                                                  },
                                                  border: {
                                                    top: { style: "medium" },
                                                    left: { style: "medium" },
                                                    bottom: { style: "medium" },
                                                    right: { style: "medium" },
                                                  },
                                                },
                                              );
                                              Object.assign(
                                                worksheet.getRow(2).getCell(3),
                                                {
                                                  value:
                                                    LAVANDERIA.denominacion,
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "left",
                                                  },
                                                  border: {
                                                    top: { style: "medium" },
                                                    bottom: { style: "medium" },
                                                    right: { style: "medium" },
                                                  },
                                                },
                                              );
                                              // merge by start row, start column, end row, end column
                                              worksheet.mergeCells(1, 1, 1, 6);

                                              Object.assign(
                                                worksheet.getRow(3).getCell(2),
                                                {
                                                  value: "Período",
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "left",
                                                  },
                                                  fill: {
                                                    type: "pattern",
                                                    pattern: "solid",
                                                    fgColor: {
                                                      argb: "FFCC00",
                                                    },
                                                  },
                                                  border: {
                                                    top: { style: "medium" },
                                                    left: { style: "medium" },
                                                    bottom: { style: "medium" },
                                                    right: { style: "medium" },
                                                  },
                                                },
                                              );
                                              Object.assign(
                                                worksheet.getRow(3).getCell(3),
                                                {
                                                  value:
                                                    formatDate(fechaDesde) +
                                                    " - " +
                                                    formatDate(fechaHasta),
                                                  font: { bold: true },
                                                  alignment: {
                                                    vertical: "middle",
                                                    horizontal: "left",
                                                  },
                                                  border: {
                                                    top: { style: "medium" },
                                                    bottom: { style: "medium" },
                                                    right: { style: "medium" },
                                                  },
                                                },
                                              );
                                              //#endregion

                                              var filtro_dxList_entidad = $(
                                                  "#filtro_dxList_entidad",
                                                ).dxList("instance"),
                                                filtro_dxList_compañia = $(
                                                  "#filtro_dxList_compañia",
                                                ).dxList("instance"),
                                                idCompaSel =
                                                  filtro_dxList_compañia.option(
                                                    "selectedItemKeys",
                                                  ).length > 0
                                                    ? filtro_dxList_compañia.option(
                                                        "selectedItemKeys",
                                                      )[0]
                                                    : 0,
                                                idEntidadSel =
                                                  filtro_dxList_entidad.option(
                                                    "selectedItemKeys",
                                                  ).length > 0
                                                    ? filtro_dxList_entidad.option(
                                                        "selectedItemKeys",
                                                      )[0]
                                                    : 0,
                                                is_prendasCompañia =
                                                  idCompaSel !== 0 &&
                                                  idEntidadSel === -1; // Afecta a la impresión

                                              var compañias = $.grep(
                                                datasource_compañias.items(),
                                                function (item) {
                                                  return (
                                                    item.idCompañia !== 0 &&
                                                    ((idCompaSel !== 0 &&
                                                      item.idCompañia ===
                                                        idCompaSel) ||
                                                      idCompaSel === 0) &&
                                                    ((idEntidadSel !== 0 &&
                                                      idEntidadSel !== 1 &&
                                                      filtro_dxList_entidad.option(
                                                        "selectedItem",
                                                      ).idCompañia ===
                                                        item.idCompañia) ||
                                                      idEntidadSel === 0 ||
                                                      idEntidadSel === -1)
                                                  );
                                                },
                                              );
                                              var entidades = $.grep(
                                                datasource_entidades.items(),
                                                function (item) {
                                                  return (
                                                    item.idEntidad !== 0 &&
                                                    is_prendasCompañia ===
                                                      false &&
                                                    ((idEntidadSel !== 0 &&
                                                      item.idEntidad ===
                                                        idEntidadSel) ||
                                                      idEntidadSel === 0)
                                                  );
                                                },
                                              );
                                              var initBodyExcel_row = 5;
                                              var objSumatorio_compañias = {
                                                kgProd: 0,
                                                kgRech: 0,
                                                kgRet: 0,
                                                ttKgUdsProc: 0,
                                              };

                                              //#region Headers prendas
                                              var gridPrincipal_tableHeaders = [
                                                getTrad("compañia"),
                                                getTrad("entidad"),
                                                getTrad("codigo"),
                                                getTrad("denominacion"),
                                                getTrad("udsProd_abr"),
                                                getTrad("kgProd_abr"),
                                                getTrad("udsRech_abr"),
                                                getTrad("kgRech_abr"),
                                                getTrad("udsRet_abr"),
                                                getTrad("kgRet_abr"),
                                                getTrad("ttUdsProc_abr"),
                                                getTrad("ttKgUdsProc_abr"),
                                                getTrad("percKgProd_abr"),
                                                getTrad("percKgRech_abr"),
                                                getTrad("percKgRet_abr"),
                                              ];

                                              $.each(
                                                gridPrincipal_tableHeaders,
                                                function (
                                                  headerIndex,
                                                  headerValue,
                                                ) {
                                                  Object.assign(
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(headerIndex + 2),
                                                    {
                                                      value: headerValue,
                                                      font: { bold: true },
                                                      fill: {
                                                        type: "pattern",
                                                        pattern: "solid",
                                                        fgColor: {
                                                          argb: "FFCC00",
                                                        },
                                                      },
                                                      border: {
                                                        top: {
                                                          style: "medium",
                                                        },
                                                        left: {
                                                          style:
                                                            headerIndex === 0
                                                              ? "medium"
                                                              : "",
                                                        },
                                                        bottom: {
                                                          style: "medium",
                                                        },
                                                        right: {
                                                          style:
                                                            headerIndex === 14
                                                              ? "medium"
                                                              : "",
                                                        },
                                                      },
                                                      alignment: {
                                                        vertical: "middle",
                                                        horizontal:
                                                          headerIndex === 0 ||
                                                          headerIndex === 1 ||
                                                          headerIndex === 3
                                                            ? "left"
                                                            : "center",
                                                      },
                                                    },
                                                  );
                                                },
                                              );
                                              initBodyExcel_row++;
                                              //#endregion

                                              let totalPrendas = $.extend(
                                                true,
                                                [],
                                                datasource_spSelectProduccion.items(),
                                              );
                                              $.each(
                                                totalPrendas,
                                                function (index, item) {
                                                  let item_compañia =
                                                    datasource_compañias
                                                      .items()
                                                      .find((x) => {
                                                        return (
                                                          x.idCompañia ===
                                                          item.idCompañia
                                                        );
                                                      });
                                                  let item_entidad =
                                                    datasource_entidades
                                                      .items()
                                                      .find((x) => {
                                                        return (
                                                          x.idEntidad ===
                                                          item.idEntidad
                                                        );
                                                      });

                                                  item.denoCompa =
                                                    item_compañia != null
                                                      ? item_compañia.denominacion
                                                      : "";
                                                  item.denoEnti =
                                                    item_entidad != null
                                                      ? item_entidad.denominacion
                                                      : "";

                                                  if (item_entidad != null) {
                                                    let item_compaEnti =
                                                      datasource_compañias
                                                        .items()
                                                        .find((x) => {
                                                          return (
                                                            x.idCompañia ===
                                                            item_entidad.idCompañia
                                                          );
                                                        });
                                                    item.denoCompa =
                                                      item_compaEnti != null
                                                        ? item_compaEnti.denominacion
                                                        : "";
                                                  }
                                                },
                                              );

                                              $.each(
                                                compañias,
                                                function (
                                                  compaIndex,
                                                  compaItem,
                                                ) {
                                                  var entiFiltradas = $.grep(
                                                    entidades,
                                                    function (entidad) {
                                                      return (
                                                        compaItem.idCompañia ===
                                                        entidad.idCompañia
                                                      );
                                                    },
                                                  );
                                                  $.each(
                                                    entiFiltradas,
                                                    function (
                                                      indexEntidad,
                                                      itemEntidad,
                                                    ) {
                                                      var prendasNEnti =
                                                        agruparDatos(
                                                          filtroDatos_prodRech(
                                                            $.grep(
                                                              datasource_spSelectProduccion.items(),
                                                              function (
                                                                prodItem,
                                                              ) {
                                                                return (
                                                                  itemEntidad.idEntidad ===
                                                                  prodItem.idEntidad
                                                                );
                                                              },
                                                            ),
                                                            true,
                                                          ),
                                                        );

                                                      if (
                                                        prendasNEnti.length ===
                                                        0
                                                      ) {
                                                        totalPrendas.push({
                                                          idCompañia: null,
                                                          codigoPrenda: null,
                                                          idEntidad: null,
                                                          idPrenda: null,
                                                          idTipoPrenda: null,
                                                          denoTipoPrenda: null,
                                                          idFamilia: null,
                                                          idTipoProduccion:
                                                            null,
                                                          peso: null,
                                                          producidas: null,
                                                          rechazadas: null,
                                                          retiradas: null,
                                                          procesadas: null,
                                                          fecha: null,
                                                          denoCompa:
                                                            compaItem.denominacion,
                                                          denoEnti:
                                                            itemEntidad.denominacion,
                                                          denoPrenda:
                                                            getTrad(
                                                              "noLavPrendasEnti",
                                                            ),
                                                        });
                                                      }
                                                    },
                                                  );
                                                },
                                              );

                                              totalPrendas = totalPrendas.sort(
                                                function (a, b) {
                                                  if (a.denoCompa < b.denoCompa)
                                                    return -1;

                                                  if (a.denoCompa > b.denoCompa)
                                                    return 1;

                                                  if (a.denoEnti < b.denoEnti)
                                                    return -1;

                                                  if (a.denoEnti > b.denoEnti)
                                                    return 1;

                                                  if (
                                                    a.codigoPrenda <
                                                    b.codigoPrenda
                                                  )
                                                    return -1;

                                                  if (
                                                    a.codigoPrenda >
                                                    b.codigoPrenda
                                                  )
                                                    return 1;

                                                  return 0;
                                                },
                                              );

                                              $.each(
                                                totalPrendas,
                                                function (
                                                  prendaIndex,
                                                  prendaItem,
                                                ) {
                                                  var peso =
                                                    prendaItem.peso * 0.001;

                                                  // Sumatorio subtotal y total
                                                  objSumatorio_compañias.kgProd +=
                                                    prendaItem.producidas *
                                                    peso;
                                                  objSumatorio_compañias.kgRech +=
                                                    prendaItem.rechazadas *
                                                    peso;
                                                  objSumatorio_compañias.kgRet +=
                                                    prendaItem.retiradas * peso;
                                                  objSumatorio_compañias.ttKgUdsProc +=
                                                    prendaItem.procesadas *
                                                    peso;

                                                  //#region Columnas de prendas
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(2).value =
                                                    prendaItem.denoCompa;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(3).value =
                                                    prendaItem.denoEnti;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(4).value =
                                                    prendaItem.codigoPrenda;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(4).numFmt = "#";
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(5).value =
                                                    prendaItem.denoPrenda;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(6).value =
                                                    prendaItem.producidas;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(7).value =
                                                    prendaItem.producidas *
                                                    peso;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(7).numFmt = "0.00";
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(8).value =
                                                    prendaItem.rechazadas;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(9).value =
                                                    prendaItem.rechazadas *
                                                    peso;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(9).numFmt = "0.00";
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(10).value =
                                                    prendaItem.retiradas;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(11).value =
                                                    prendaItem.retiradas * peso;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(11).numFmt =
                                                    "0.00";
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(12).value =
                                                    prendaItem.procesadas;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(13).value =
                                                    prendaItem.procesadas *
                                                    peso;
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(13).numFmt =
                                                    "0.00";

                                                  let percProd =
                                                      prendaItem.producidas *
                                                        peso !==
                                                        0 &&
                                                      prendaItem.procesadas *
                                                        peso !==
                                                        0
                                                        ? (prendaItem.producidas *
                                                            peso) /
                                                          (prendaItem.procesadas *
                                                            peso)
                                                        : 0,
                                                    percRech =
                                                      prendaItem.rechazadas *
                                                        peso !==
                                                        0 &&
                                                      prendaItem.procesadas *
                                                        peso !==
                                                        0
                                                        ? (prendaItem.rechazadas *
                                                            peso) /
                                                          (prendaItem.procesadas *
                                                            peso)
                                                        : 0,
                                                    precRet =
                                                      prendaItem.retiradas *
                                                        peso !==
                                                        0 &&
                                                      prendaItem.procesadas *
                                                        peso !==
                                                        0
                                                        ? (prendaItem.retiradas *
                                                            peso) /
                                                          (prendaItem.procesadas *
                                                            peso)
                                                        : 0;

                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(14).value =
                                                    formatNumber(
                                                      percProd,
                                                      2,
                                                      "percent",
                                                    );
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(15).value =
                                                    formatNumber(
                                                      percRech,
                                                      2,
                                                      "percent",
                                                    );
                                                  worksheet
                                                    .getRow(initBodyExcel_row)
                                                    .getCell(16).value =
                                                    formatNumber(
                                                      precRet,
                                                      2,
                                                      "percent",
                                                    );

                                                  colorearCeldas_prenda(
                                                    initBodyExcel_row,
                                                    percProd,
                                                    percRech,
                                                    precRet,
                                                  );

                                                  $.each(
                                                    gridPrincipal_tableHeaders,
                                                    function (
                                                      headerIndex,
                                                      headerValue,
                                                    ) {
                                                      Object.assign(
                                                        worksheet
                                                          .getRow(
                                                            initBodyExcel_row,
                                                          )
                                                          .getCell(
                                                            headerIndex + 2,
                                                          ),
                                                        {
                                                          alignment: {
                                                            vertical: "middle",
                                                            horizontal:
                                                              headerIndex ===
                                                                0 ||
                                                              headerIndex ===
                                                                1 ||
                                                              headerIndex === 3
                                                                ? "left"
                                                                : "center",
                                                          },
                                                          border: {
                                                            left: {
                                                              style:
                                                                headerIndex ===
                                                                0
                                                                  ? "medium"
                                                                  : "thin",
                                                            },
                                                            right: {
                                                              style:
                                                                headerIndex ===
                                                                  1 ||
                                                                headerIndex ===
                                                                  14
                                                                  ? "medium"
                                                                  : "thin",
                                                            },
                                                          },
                                                        },
                                                      );
                                                    },
                                                  );

                                                  if (
                                                    prendaItem.idPrenda == null
                                                  ) {
                                                    $.each(
                                                      gridPrincipal_tableHeaders,
                                                      function (
                                                        headerIndex,
                                                        headerValue,
                                                      ) {
                                                        Object.assign(
                                                          worksheet
                                                            .getRow(
                                                              initBodyExcel_row,
                                                            )
                                                            .getCell(
                                                              headerIndex + 2,
                                                            ),
                                                          {
                                                            alignment: {
                                                              vertical:
                                                                "middle",
                                                              horizontal:
                                                                "left",
                                                            },
                                                          },
                                                        );
                                                      },
                                                    );

                                                    Object.assign(
                                                      worksheet
                                                        .getRow(
                                                          initBodyExcel_row,
                                                        )
                                                        .getCell(4),
                                                      {
                                                        value:
                                                          getTrad(
                                                            "noLavPrendasEnti",
                                                          ),
                                                        border: {
                                                          left: {
                                                            style: "thin",
                                                          },
                                                          right: {
                                                            style: "medium",
                                                          },
                                                        },
                                                      },
                                                    );
                                                    worksheet.mergeCells(
                                                      initBodyExcel_row,
                                                      4,
                                                      initBodyExcel_row,
                                                      16,
                                                    );
                                                  }

                                                  initBodyExcel_row++;
                                                  //#endregion
                                                },
                                              );

                                              //#region Total
                                              if (
                                                objSumatorio_compañias.ttKgUdsProc >
                                                0
                                              ) {
                                                let percProd =
                                                    objSumatorio_compañias.kgProd !==
                                                      0 &&
                                                    objSumatorio_compañias.ttKgUdsProc !==
                                                      0
                                                      ? objSumatorio_compañias.kgProd /
                                                        objSumatorio_compañias.ttKgUdsProc
                                                      : 0,
                                                  percRech =
                                                    objSumatorio_compañias.kgRech !==
                                                      0 &&
                                                    objSumatorio_compañias.ttKgUdsProc !==
                                                      0
                                                      ? objSumatorio_compañias.kgRech /
                                                        objSumatorio_compañias.ttKgUdsProc
                                                      : 0,
                                                  precRet =
                                                    objSumatorio_compañias.kgRet !==
                                                      0 &&
                                                    objSumatorio_compañias.ttKgUdsProc !==
                                                      0
                                                      ? objSumatorio_compañias.kgRet /
                                                        objSumatorio_compañias.ttKgUdsProc
                                                      : 0;

                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(2).value =
                                                  getTrad(
                                                    "total",
                                                  ).toUpperCase();
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(7).value =
                                                  objSumatorio_compañias.kgProd;
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(7).numFmt = "0.00";
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(9).value =
                                                  objSumatorio_compañias.kgRech;
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(9).numFmt = "0.00";
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(11).value =
                                                  objSumatorio_compañias.kgRet;
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(11).numFmt = "0.00";
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(13).value =
                                                  objSumatorio_compañias.ttKgUdsProc;
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(13).numFmt = "0.00";
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(14).value =
                                                  formatNumber(
                                                    percProd,
                                                    2,
                                                    "percent",
                                                  );
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(15).value =
                                                  formatNumber(
                                                    percRech,
                                                    2,
                                                    "percent",
                                                  );
                                                worksheet
                                                  .getRow(initBodyExcel_row)
                                                  .getCell(16).value =
                                                  formatNumber(
                                                    precRet,
                                                    2,
                                                    "percent",
                                                  );

                                                colorearCeldas_prenda(
                                                  initBodyExcel_row,
                                                  percProd,
                                                  percRech,
                                                  precRet,
                                                );

                                                $.each(
                                                  gridPrincipal_tableHeaders,
                                                  function (
                                                    headerIndex,
                                                    headerValue,
                                                  ) {
                                                    var cellFont = worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(
                                                        headerIndex + 2,
                                                      ).font;
                                                    if (
                                                      typeof cellFont !==
                                                      "undefined"
                                                    )
                                                      cellFont.bold = true;
                                                    else
                                                      worksheet
                                                        .getRow(
                                                          initBodyExcel_row,
                                                        )
                                                        .getCell(
                                                          headerIndex + 2,
                                                        ).font = { bold: true };

                                                    Object.assign(
                                                      worksheet
                                                        .getRow(
                                                          initBodyExcel_row,
                                                        )
                                                        .getCell(
                                                          headerIndex + 2,
                                                        ),
                                                      {
                                                        fill: {
                                                          type: "pattern",
                                                          pattern: "solid",
                                                          fgColor: {
                                                            argb: "F39D03",
                                                          },
                                                        },
                                                        border: {
                                                          top: {
                                                            style: "medium",
                                                          },
                                                          left: {
                                                            style:
                                                              headerIndex === 0
                                                                ? "medium"
                                                                : "thin",
                                                          },
                                                          bottom: {
                                                            style: "medium",
                                                          },
                                                          right: {
                                                            style:
                                                              headerIndex === 14
                                                                ? "medium"
                                                                : "thin",
                                                          },
                                                        },
                                                      },
                                                    );
                                                    worksheet
                                                      .getRow(initBodyExcel_row)
                                                      .getCell(
                                                        headerIndex + 6,
                                                      ).alignment = {
                                                      vertical: "middle",
                                                      horizontal: "center",
                                                    };
                                                  },
                                                );
                                                initBodyExcel_row++;
                                              }
                                              //#endregion

                                              workbook.xlsx
                                                .writeBuffer()
                                                .then(function (buffer) {
                                                  var formatoFecha =
                                                    new Date().getDate() +
                                                    "_" +
                                                    new Date().getMonth() +
                                                    "_" +
                                                    new Date().getFullYear();
                                                  saveAs(
                                                    new Blob([buffer], {
                                                      type: "application/octet-stream",
                                                    }),
                                                    "InfProduccion_" +
                                                      formatoFecha +
                                                      ".xlsx",
                                                  );
                                                });

                                              e.cancel = true;
                                            }
                                            $("#dxPopup_seleccion_impresion")
                                              .dxPopup("instance")
                                              .hide();
                                          },
                                          onRowPrepared: function (e) {
                                            if (e.rowType === "data") {
                                              e.rowElement.css(
                                                "cursor",
                                                "pointer",
                                              );
                                            }
                                          },
                                          //Estilos
                                          showColumnLines: false,
                                          showRowLines: true,
                                          rowAlternationEnabled: true,
                                        }),
                                      );
                                    },
                                  },
                                ],
                              }),
                            );
                          },
                        },
                        {
                          title: getTrad("rechazo"),
                          name: "rechazo",
                          template: function (
                            itemData,
                            itemIndex,
                            itemElement,
                          ) {
                            itemElement.append(
                              $("<div />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                items: [
                                  {
                                    ratio: 1,
                                    template: function (e, index, item) {
                                      //#region La primera vez que se inicia coge estas fechas por defecto
                                      var ultimaFecha = new Date(
                                        datasource_filtroTiempo_spSelectProduccion.items()[
                                          datasource_filtroTiempo_spSelectProduccion.items()
                                            .length - 1
                                        ].arg,
                                      );
                                      fechaDesde = startOfMonth(ultimaFecha);
                                      fechaHasta = new Date(ultimaFecha);
                                      //#endregion

                                      datasource_spSelectProduccion
                                        .load()
                                        .done(function () {
                                          is_unidades =
                                            $("#dxRadioGroup_unidadesPeso")
                                              .dxRadioGroup("instance")
                                              .option("value") === 1
                                              ? true
                                              : false;
                                          item.append(
                                            $("<div />").dxBox({
                                              direction: "row",
                                              align: "space-around",
                                              crossAlign: "stretch",
                                              height: "100%",
                                              items: [
                                                {
                                                  ratio: 5,
                                                  template: function (
                                                    e,
                                                    index,
                                                    item,
                                                  ) {
                                                    item.append(
                                                      $(
                                                        "<div id='dxDataGrid_rechazoPrenda' />",
                                                      ).dxDataGrid({
                                                        //Propiedades
                                                        headerFilter: {
                                                          visible: true,
                                                        },
                                                        export: {
                                                          enabled: false,
                                                          fileName:
                                                            "infRechazo",
                                                        },
                                                        hoverStateEnabled: true,
                                                        filterRow: {
                                                          visible: true,
                                                          applyFilter: "auto",
                                                          showAllText:
                                                            getTrad(
                                                              "todos",
                                                            ).toUpperCase(),
                                                        },
                                                        loadPanel: {
                                                          enabled: false,
                                                        },
                                                        selection: {
                                                          mode: "single",
                                                        },
                                                        height: "100%",
                                                        columnAutoWidth: true,
                                                        paging: {
                                                          enabled: false,
                                                        },
                                                        columns: [
                                                          {
                                                            dataField:
                                                              "codigoPrenda",
                                                            caption:
                                                              getTrad("codigo"),
                                                            width: 100,
                                                            sortIndex: 1,
                                                            sortOrder: "asc",
                                                          },
                                                          {
                                                            dataField:
                                                              "denominacion",
                                                            caption:
                                                              getTrad(
                                                                "denominacion",
                                                              ),
                                                            minWidth: 250,
                                                          },
                                                          {
                                                            dataField:
                                                              "procesadas",
                                                            caption:
                                                              getTrad(
                                                                "procesadas",
                                                              ),
                                                            customizeText:
                                                              function (data) {
                                                                return is_unidades
                                                                  ? formatNumber(
                                                                      data.value,
                                                                    )
                                                                  : formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "decimal",
                                                                    );
                                                              },
                                                            dataType: "string",
                                                            minWidth: 150,
                                                            allowFiltering: false,
                                                            alignment: "center",
                                                          },
                                                          {
                                                            dataField:
                                                              "rechazadas",
                                                            caption:
                                                              getTrad(
                                                                "rechazadas",
                                                              ),
                                                            customizeText:
                                                              function (data) {
                                                                return is_unidades
                                                                  ? formatNumber(
                                                                      data.value,
                                                                    )
                                                                  : formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "decimal",
                                                                    );
                                                              },
                                                            dataType: "string",
                                                            minWidth: 150,
                                                            allowFiltering: false,
                                                            alignment: "center",
                                                          },
                                                          {
                                                            dataField:
                                                              "porcentaje_rechazo",
                                                            caption:
                                                              "% " +
                                                              getTrad(
                                                                "rechazo",
                                                              ),
                                                            customizeText:
                                                              function (data) {
                                                                return formatNumber(
                                                                  data.value,
                                                                  2,
                                                                  "percent",
                                                                );
                                                              },
                                                            dataType: "string",
                                                            minWidth: 150,
                                                            allowFiltering: false,
                                                            alignment: "center",
                                                          },
                                                        ],
                                                        summary: {
                                                          recalculateWhileEditing: true,
                                                          totalItems: [
                                                            {
                                                              column:
                                                                "denominacion",
                                                              displayFormat:
                                                                getTrad(
                                                                  "total",
                                                                ).toUpperCase(),
                                                            },
                                                            {
                                                              column:
                                                                "procesadas",
                                                              summaryType:
                                                                "sum",
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return is_unidades
                                                                    ? ""
                                                                    : formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      ) + " Kg";
                                                                },
                                                              displayFormat:
                                                                "{0}",
                                                            },
                                                            {
                                                              column:
                                                                "rechazadas",
                                                              summaryType:
                                                                "sum",
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return is_unidades
                                                                    ? ""
                                                                    : formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      ) + " Kg";
                                                                },
                                                              displayFormat:
                                                                "{0}",
                                                            },
                                                            {
                                                              name: "porcentaje_rechazo",
                                                              showInColumn:
                                                                "porcentaje_rechazo",
                                                              summaryType:
                                                                "custom",
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return formatNumber(
                                                                    data.value,
                                                                    2,
                                                                    "percent",
                                                                  );
                                                                },
                                                              displayFormat:
                                                                "{0}",
                                                            },
                                                          ],
                                                          calculateCustomSummary:
                                                            function (options) {
                                                              if (
                                                                options.name ===
                                                                "porcentaje_rechazo"
                                                              ) {
                                                                if (
                                                                  options.summaryProcess ===
                                                                  "start"
                                                                ) {
                                                                  options.totalValue =
                                                                    [0, 0];
                                                                }
                                                                if (
                                                                  options.summaryProcess ===
                                                                  "calculate"
                                                                ) {
                                                                  options.totalValue =
                                                                    [
                                                                      options
                                                                        .totalValue[0] +
                                                                        options
                                                                          .value
                                                                          .rechazadas,
                                                                      options
                                                                        .totalValue[1] +
                                                                        options
                                                                          .value
                                                                          .procesadas,
                                                                    ];
                                                                }
                                                                if (
                                                                  options.summaryProcess ===
                                                                  "finalize"
                                                                ) {
                                                                  options.totalValue =
                                                                    options
                                                                      .totalValue[0] /
                                                                    options
                                                                      .totalValue[1];
                                                                }
                                                              }
                                                            },
                                                        },
                                                        onContentReady:
                                                          function (e) {
                                                            if (
                                                              $(
                                                                "#dxDataGrid_tiposRechazoNPrenda",
                                                              ).length > 0
                                                            )
                                                              setDataSource_tipoRech();
                                                          },
                                                        onToolbarPreparing:
                                                          function (e) {
                                                            e.toolbarOptions.items.unshift(
                                                              {
                                                                location:
                                                                  "before",
                                                                template:
                                                                  function (
                                                                    e,
                                                                    index,
                                                                    item,
                                                                  ) {
                                                                    item.append(
                                                                      $(
                                                                        "<div/>",
                                                                      )
                                                                        .addClass(
                                                                          "font-size",
                                                                        )
                                                                        .text(
                                                                          getTrad(
                                                                            "lavanderia",
                                                                          ) +
                                                                            " - " +
                                                                            LAVANDERIA.denominacion,
                                                                        ),
                                                                    );
                                                                  },
                                                              },
                                                              {
                                                                location:
                                                                  "after",
                                                                widget:
                                                                  "dxButton",
                                                                options: {
                                                                  text: getTrad(
                                                                    "eliminarSeleccion",
                                                                  ),
                                                                  icon: " icon_EliminarFiltros",
                                                                  disabled: true,
                                                                  elementAttr: {
                                                                    id: "dxButton_limpiarFiltros",
                                                                  },
                                                                  onClick:
                                                                    function () {
                                                                      $(
                                                                        "#dxDataGrid_rechazoPrenda",
                                                                      )
                                                                        .dxDataGrid(
                                                                          "instance",
                                                                        )
                                                                        .deselectAll();
                                                                    },
                                                                },
                                                              },
                                                              {
                                                                location:
                                                                  "after",
                                                                widget:
                                                                  "dxButton",
                                                                options: {
                                                                  text: getTrad(
                                                                    "exportar",
                                                                  ),
                                                                  icon: "exportxlsx",
                                                                  onClick:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      $(
                                                                        "#dxDataGrid_rechazoPrenda",
                                                                      )
                                                                        .dxDataGrid(
                                                                          "instance",
                                                                        )
                                                                        .exportToExcel();
                                                                    },
                                                                },
                                                              },
                                                            );
                                                          },
                                                        onSelectionChanged:
                                                          function (e) {
                                                            setDataSource_tipoRech();

                                                            var dxButton_limpiarFiltros =
                                                              $(
                                                                "#dxButton_limpiarFiltros",
                                                              ).dxButton(
                                                                "instance",
                                                              );
                                                            if (
                                                              e.selectedRowKeys
                                                                .length > 0
                                                            )
                                                              dxButton_limpiarFiltros.option(
                                                                "disabled",
                                                                false,
                                                              );
                                                            else
                                                              dxButton_limpiarFiltros.option(
                                                                "disabled",
                                                                true,
                                                              );
                                                          },
                                                        onRowPrepared:
                                                          function (e) {
                                                            if (
                                                              e.rowType ===
                                                              "data"
                                                            ) {
                                                              e.rowElement.css(
                                                                "cursor",
                                                                "pointer",
                                                              );
                                                            }
                                                          },
                                                        //Estilos
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                        rowAlternationEnabled: true,
                                                      }),
                                                    );
                                                  },
                                                },
                                                {
                                                  ratio: 2,
                                                  template: function (
                                                    e,
                                                    index,
                                                    item,
                                                  ) {
                                                    datasource_spSelectTipoRechazoNPrenda
                                                      .load()
                                                      .done(function (items) {
                                                        item.css(
                                                          "padding-left",
                                                          15,
                                                        );
                                                        item.append(
                                                          $(
                                                            "<div id='dxDataGrid_tiposRechazoNPrenda' />",
                                                          ).dxDataGrid({
                                                            //Propiedades
                                                            headerFilter: {
                                                              visible: true,
                                                            },
                                                            loadPanel: {
                                                              enabled: false,
                                                            },
                                                            export: {
                                                              enabled: false,
                                                              fileName:
                                                                "infRechazo_tiposRechazo",
                                                            },
                                                            filterRow: {
                                                              visible: true,
                                                              applyFilter:
                                                                "auto",
                                                              showAllText:
                                                                getTrad(
                                                                  "todos",
                                                                ).toUpperCase(),
                                                            },
                                                            height: "100%",
                                                            columnAutoWidth: true,
                                                            paging: {
                                                              enabled: false,
                                                            },
                                                            columns: [
                                                              {
                                                                dataField:
                                                                  "idTipoRechazo",
                                                                caption:
                                                                  getTrad(
                                                                    "tipoRechazo",
                                                                  ),
                                                                sortIndex: 1,
                                                                sortOrder:
                                                                  "asc",
                                                                lookup: {
                                                                  dataSource:
                                                                    datasource_tipoRechazo.items(),
                                                                  valueExpr:
                                                                    "idTipoRechazo",
                                                                  displayExpr:
                                                                    "denominacion",
                                                                },
                                                              },
                                                              {
                                                                dataField:
                                                                  "rechazadas",
                                                                caption:
                                                                  getTrad(
                                                                    "rechazadas",
                                                                  ),
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return is_unidades
                                                                      ? formatNumber(
                                                                          data.value,
                                                                        )
                                                                      : formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        );
                                                                  },
                                                                dataType:
                                                                  "string",
                                                                width: 120,
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                              {
                                                                dataField:
                                                                  "porcentaje_rechazo",
                                                                caption:
                                                                  "% " +
                                                                  getTrad(
                                                                    "rechazo",
                                                                  ),
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "percent",
                                                                    );
                                                                  },
                                                                dataType:
                                                                  "string",
                                                                width: 120,
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                            ],
                                                            summary: {
                                                              recalculateWhileEditing: true,
                                                              totalItems: [
                                                                {
                                                                  column:
                                                                    "idTipoRechazo",
                                                                  displayFormat:
                                                                    getTrad(
                                                                      "total",
                                                                    ).toUpperCase(),
                                                                },
                                                                {
                                                                  column:
                                                                    "porcentaje_rechazo",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "percent",
                                                                      );
                                                                    },
                                                                  displayFormat:
                                                                    "{0} %",
                                                                },
                                                              ],
                                                            },
                                                            onToolbarPreparing:
                                                              function (e) {
                                                                e.toolbarOptions.items.unshift(
                                                                  {
                                                                    location:
                                                                      "after",
                                                                    widget:
                                                                      "dxButton",
                                                                    options: {
                                                                      text: getTrad(
                                                                        "exportar",
                                                                      ),
                                                                      icon: "exportxlsx",
                                                                      onClick:
                                                                        function (
                                                                          e,
                                                                        ) {
                                                                          $(
                                                                            "#dxDataGrid_tiposRechazoNPrenda",
                                                                          )
                                                                            .dxDataGrid(
                                                                              "instance",
                                                                            )
                                                                            .exportToExcel();
                                                                        },
                                                                    },
                                                                  },
                                                                );
                                                              },
                                                            //Estilos
                                                            showColumnLines: false,
                                                            showRowLines: true,
                                                            rowAlternationEnabled: true,
                                                          }),
                                                        );
                                                        setDataSource_tipoRech();
                                                      });
                                                  },
                                                },
                                              ],
                                            }),
                                          );
                                          setDataSource_prodRech();
                                        });
                                    },
                                  },
                                ],
                              }),
                            );
                          },
                        },
                      ],
                    }),
                );
              },
            },
            {
              // Range selector
              baseSize: 130,
              template: function (e, index, itemElement) {
                itemElement.append(
                  create_rangeSelector(
                    datasource_filtroTiempo_spSelectProduccion,
                    false,
                    false,
                    function (e) {
                      COMPONENT.loadPanel_show(true);

                      fechaDesde = e.value[0];
                      fechaHasta = e.component.option("valueHasta");

                      $.when(
                        datasource_spSelectProduccion.load(),
                        datasource_spSelectTipoRechazoNPrenda.load(),
                      ).then(function () {
                        COMPONENT.loadPanel_hide();
                        setDataSource_prodRech();
                      });
                      dxRangeSelector_PosicionValor_nuevoMarker(
                        $("#dxRangeSelector_filtroTiempo"),
                        fechaDesde,
                        fechaHasta,
                      );
                    },
                    function () {
                      var ultimaFecha = new Date(
                        datasource_filtroTiempo_spSelectProduccion.items()[
                          datasource_filtroTiempo_spSelectProduccion.items()
                            .length - 1
                        ].arg,
                      );
                      $("#dxRangeSelector_filtroTiempo")
                        .dxRangeSelector("instance")
                        .option({
                          "scale.minRange": { days: null, months: 1 },
                          value: [
                            startOfMonth(ultimaFecha),
                            endOfMonth(new Date(ultimaFecha)),
                          ],
                        });
                    },
                  ),
                );
              },
            },
          ],
        });

        $("#InfProduccion #dxPopup_seleccion_prendaProduccion").dxPopup({
          showTitle: true,
          title: "Producciones por fecha",
          deferRendering: false,
          height: 600,
          width: 800,
          contentTemplate: function (e) {
            return $("<div id='dxBox_popup' />").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  baseSize: 60,
                  template: function (e, index, item) {
                    item.append(
                      $("<div />").dxToolbar({
                        items: [
                          {
                            //todo poner titulo tipo prenda
                            location: "before",
                            template: function (e, index, item) {
                              item.css("width", 285);
                              item.append(
                                $(
                                  "<div id='titPopupProd_container' class='font-size-xs' />",
                                ).css({
                                  display: "flex",
                                  "flex-direction": "column",
                                }),
                              );
                            },
                          },
                          {
                            location: "after",
                            widget: "dxButton",
                            showText: "inMenu",
                            options: {
                              text: getTrad("limpiarFiltro"),
                              icon: " icon_EliminarFiltros",
                              onInitialized: function (e) {
                                tooltipControl_creacion(
                                  e.element,
                                  e.component.option("text"),
                                );
                              },
                              onClick: function (e) {
                                $("#dxDataGrid_produccionFecha")
                                  .dxDataGrid("instance")
                                  .clearFilter();
                                notify({
                                  message: getTrad("aviso_C_FiltroRestaurado"),
                                  type: "success",
                                  displayTime: "1500",
                                  closeOnClick: true,
                                });
                              },
                            },
                          },
                          {
                            location: "after",
                            widget: "dxButton",
                            options: {
                              text: getTrad("exportar"),
                              icon: "exportxlsx",
                              onClick: function (e) {
                                $("#dxDataGrid_produccionFecha")
                                  .dxDataGrid("instance")
                                  .exportToExcel();
                              },
                            },
                          },
                        ],
                      }),
                    );
                  },
                },
                {
                  ratio: 1,
                  template: function (e, index, item) {
                    item.css("margin", "0px 5px");
                    item.append(
                      $("<div id='dxDataGrid_produccionFecha' />").dxDataGrid({
                        //Propiedades
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                          showAllText: getTrad("todos"),
                        },
                        export: {
                          enabled: false,
                          fileName: "InfProducción_fechas",
                          allowExportSelectedData: true,
                        },
                        height: "100%",
                        hint: null,
                        columnAutoWidth: true,
                        paging: {
                          pageSize: 50,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        columns: [
                          {
                            dataField: "fecha",
                            caption: getTrad("fecha"),
                            sortIndex: 1,
                            sortOrder: "asc",
                            format: "dd/MM/yyyy",
                            allowHeaderFiltering: false,
                          },
                          {
                            dataField: "producidas",
                            caption: getTrad("producidas"),
                            alignment: "center",
                            dataType: "number",
                            width: 100,
                            allowFiltering: false,
                          },
                          {
                            dataField: "rechazadas",
                            caption: getTrad("rechazadas"),
                            alignment: "center",
                            dataType: "number",
                            width: 100,
                            allowFiltering: false,
                          },
                          {
                            dataField: "retiradas",
                            caption: getTrad("retiradas"),
                            alignment: "center",
                            dataType: "number",
                            width: 100,
                            allowFiltering: false,
                          },
                          {
                            dataField: "procesadas",
                            caption: getTrad("procesadas"),
                            alignment: "center",
                            dataType: "number",
                            width: 100,
                            allowFiltering: false,
                          },
                          {
                            dataField: "porcentaje_rechazo",
                            caption: "% " + getTrad("rechazo"),
                            customizeText: function (data) {
                              return formatNumber(data.value, 2, "percent");
                            },
                            dataType: "string",
                            width: 100,
                            allowFiltering: false,
                            alignment: "center",
                          },
                          {
                            dataField: "porcentaje_retiro",
                            caption: "% " + getTrad("retiro"),
                            customizeText: function (data) {
                              return formatNumber(data.value, 2, "percent");
                            },
                            dataType: "string",
                            width: 100,
                            allowFiltering: false,
                            alignment: "center",
                          },
                        ],
                        summary: {
                          totalItems: [
                            {
                              column: "fecha",
                              displayFormat: getTrad("total").toUpperCase(),
                            },
                            {
                              column: "producidas",
                              summaryType: "sum",
                              valueFormat: "#,##0",
                              displayFormat: "{0}",
                            },
                            {
                              column: "rechazadas",
                              summaryType: "sum",
                              valueFormat: "#,##0",
                              displayFormat: "{0}",
                            },
                            {
                              column: "retiradas",
                              summaryType: "sum",
                              valueFormat: "#,##0",
                              displayFormat: "{0}",
                            },
                            {
                              column: "procesadas",
                              summaryType: "sum",
                              valueFormat: "#,##0",
                              displayFormat: "{0}",
                            },
                            {
                              name: "porcentaje_rechazo",
                              showInColumn: "porcentaje_rechazo",
                              summaryType: "custom",
                              customizeText: function (data) {
                                return formatNumber(data.value, 2, "percent");
                              },
                              displayFormat: "{0}",
                            },
                            {
                              name: "porcentaje_retiro",
                              showInColumn: "porcentaje_retiro",
                              summaryType: "custom",
                              customizeText: function (data) {
                                return formatNumber(data.value, 2, "percent");
                              },
                              displayFormat: "{0}",
                            },
                          ],
                          calculateCustomSummary: function (options) {
                            if (options.name === "porcentaje_rechazo") {
                              if (options.summaryProcess === "start") {
                                options.totalValue = [0, 0];
                              }
                              if (options.summaryProcess === "calculate") {
                                options.totalValue = [
                                  options.totalValue[0] +
                                    options.value.rechazadas,
                                  options.totalValue[1] +
                                    options.value.procesadas,
                                ];
                              }
                              if (options.summaryProcess === "finalize") {
                                options.totalValue =
                                  options.totalValue[0] / options.totalValue[1];
                              }
                            }
                            if (options.name === "porcentaje_retiro") {
                              if (options.summaryProcess === "start") {
                                options.totalValue = [0, 0];
                              }
                              if (options.summaryProcess === "calculate") {
                                options.totalValue = [
                                  options.totalValue[0] +
                                    options.value.retiradas,
                                  options.totalValue[1] +
                                    options.value.procesadas,
                                ];
                              }
                              if (options.summaryProcess === "finalize") {
                                options.totalValue =
                                  options.totalValue[0] / options.totalValue[1];
                              }
                            }
                          },
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      }),
                    );
                  },
                },
              ],
            });
          },
          onContentReady: function (e) {
            var html = e.component.content();
            $(html).css("padding-top", 15);
          },
          onHiding: function (e) {
            let dxDataGrid_produccionFecha = $(
              "#dxDataGrid_produccionFecha",
            ).dxDataGrid("instance");
            dxDataGrid_produccionFecha.getScrollable().scrollTo(0);

            $("#dxDataGrid_produccionesPrenda")
              .dxDataGrid("instance")
              .clearSelection();
          },
          onHidden: function () {
            $("#dxDataGrid_produccionFecha").dxDataGrid("instance").option({
              dataSource: [],
            });
          },
        });

        $("#InfProduccion #dxPopup_seleccion_impresion").dxPopup({
          showTitle: true,
          title: getTrad("exportar"),
          height: 230,
          width: 300,
          contentTemplate: function (e) {
            return $("<div>").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  ratio: 1,
                  template: function () {
                    return $("<div />").dxButton({
                      text: getTrad("informeReducido"),
                      type: "normal",
                      onClick: function (options) {
                        tipoImpresion = "informeReducido";
                        $("#dxDataGrid_produccionesPrenda")
                          .dxDataGrid("instance")
                          .exportToExcel();
                        $("#dxPopup_seleccion_impresion")
                          .dxPopup("instance")
                          .hide();
                      },
                    });
                  },
                },
                {
                  ratio: 1,
                  template: function () {
                    return $("<div />").dxButton({
                      text: getTrad("informeDetallado"),
                      type: "normal",
                      onClick: function (options) {
                        tipoImpresion = "informeDetallado";
                        $("#dxDataGrid_produccionesPrenda")
                          .dxDataGrid("instance")
                          .exportToExcel();
                      },
                    });
                  },
                },
                {
                  ratio: 1,
                  template: function () {
                    return $("<div />").dxButton({
                      text:
                        getTrad("informeDetallado") +
                        " " +
                        getTrad("analisis").toLowerCase(),
                      type: "normal",
                      onClick: function (options) {
                        tipoImpresion = "informeDetallado_analisis";
                        $("#dxDataGrid_produccionesPrenda")
                          .dxDataGrid("instance")
                          .exportToExcel();
                      },
                    });
                  },
                },
              ],
            });
          },
        });
      } else {
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "btnErrorSinDatos",
          ],
        ]);
      }
    })
    .always(function () {
      setTimeout(() => {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  //#region FUNCIONES INTERNAS
  function cambioTitulo() {
    var titulo = "",
      compaSel = $("#filtro_dxList_compañia")
        .dxList("instance")
        .option("selectedItem"),
      entiSel = $("#filtro_dxList_entidad")
        .dxList("instance")
        .option("selectedItem");

    if (compaSel.idCompañia === 0 && entiSel.idEntidad === 0)
      titulo += getTrad("lavanderia") + " - " + LAVANDERIA.denominacion;
    else if (compaSel.idCompañia !== 0 && entiSel.idEntidad === -1)
      titulo += compaSel.denominacion + " - " + getTrad("prendasCompañia");
    else if (compaSel.idCompañia !== 0 && entiSel.idEntidad === 0)
      titulo += compaSel.denominacion + " - " + getTrad("todas");
    else titulo += entiSel.denominacion;

    $("#toolbarTitle").text(titulo);
    $("#toolbarTitle_rechazo").text(titulo);
  }

  function filtroDatos_prodRech(items, isExport) {
    isExport = isExport || false;
    if (!fechaDesde || !fechaHasta) {
      var dxRangeSelector_filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo",
      ).dxRangeSelector("instance");
      fechaDesde = dxRangeSelector_filtroTiempo.option("value")[0];
      fechaHasta = dxRangeSelector_filtroTiempo.option("valueHasta");
    }
    // COGER LOS FILTROS DE LA PÁGINA
    var filtro_dxList_entidad = $("#filtro_dxList_entidad").dxList("instance"),
      filtro_dxList_compañia = $("#filtro_dxList_compañia").dxList("instance");
    var items_local = $.extend(true, [], items),
      idFamiliaSel =
        typeof $("#dxRadioGroup_familias").data("dxRadioGroup") !== "undefined"
          ? $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value")
          : 0,
      idsTipoProduccionSel =
        typeof $("#dxList_TipoProduccion").data("dxList") !== "undefined"
          ? $("#dxList_TipoProduccion")
              .dxList("instance")
              .option("selectedItemKeys")
          : [],
      idsTurnosSel =
        typeof $("#dxList_Turnos").data("dxList") !== "undefined"
          ? $("#dxList_Turnos").dxList("instance").option("selectedItemKeys")
          : [];

    idCompaSel =
      filtro_dxList_compañia.option("selectedItemKeys").length > 0
        ? filtro_dxList_compañia.option("selectedItemKeys")[0]
        : 0;
    idEntidadSel =
      filtro_dxList_entidad.option("selectedItemKeys").length > 0
        ? filtro_dxList_entidad.option("selectedItemKeys")[0]
        : 0;
    is_todasCompa = idEntidadSel === 0;

    // CAMBIAR A UNIDADES O A PESO SEGÚN SELECTOR SWITCH
    if (isExport === false) {
      is_unidades =
        $("#dxRadioGroup_unidadesPeso")
          .dxRadioGroup("instance")
          .option("value") === 1
          ? true
          : false;
      if (!is_unidades) {
        $.each(items_local, function (index, element) {
          element.producidas = element.peso * 0.001 * element.producidas;
          element.rechazadas = element.peso * 0.001 * element.rechazadas;
          element.retiradas = element.peso * 0.001 * element.retiradas;
          element.procesadas = element.peso * 0.001 * element.procesadas;
        });
      }
    }

    var listaEntidadesNCompa = [];
    $.each(
      $("#filtro_dxList_entidad").dxList("instance").getDataSource().items(),
      function (index, item) {
        if (item.idEntidad !== -1 && item.idEntidad !== 0)
          listaEntidadesNCompa.push(parseInt(item.idEntidad));
      },
    );

    return items_local.filter(function (dataItem) {
      var is_tipoProduccion =
          idsTipoProduccionSel.length !== 0
            ? idsTipoProduccionSel.indexOf(dataItem.idTipoProduccion) > -1
            : true,
        is_turnos =
          idsTurnosSel.length !== 0
            ? idsTurnosSel.indexOf(dataItem.idTurno) > -1
            : true,
        is_familia =
          idFamiliaSel !== 0 ? idFamiliaSel === dataItem.idFamilia : true,
        is_idCompaEnti = (function () {
          if (idCompaSel === 0 && idEntidadSel === 0) return true; // TODA LA LAVANDERIA
          if (idCompaSel !== 0 && idEntidadSel === 0)
            return (
              dataItem.idCompañia === idCompaSel ||
              $.inArray(dataItem.idEntidad, listaEntidadesNCompa) >= 0
            ); // Prendas compa + prenda entis de la compa
          if (idCompaSel !== 0 && idEntidadSel === -1)
            return dataItem.idCompañia === idCompaSel; // Prendas de la compañía
          if (idEntidadSel !== 0 || idEntidadSel !== -1)
            return dataItem.idEntidad === idEntidadSel; // Prendas de la entidad
        })();
      return is_tipoProduccion && is_turnos && is_idCompaEnti && is_familia;
    });
  }

  function setDataSource_prodRech() {
    var items_prodRech = filtroDatos_prodRech(
      datasource_spSelectProduccion.items(),
    );
    // FILTRADO, AGRUPACIÓN Y SUMATORIO DE DATOS
    var dataSource = query(items_prodRech)
      .groupBy(function (dataItem) {
        if (!is_todasCompa)
          // SI ESTÁ SELECCIONADO PRENDAS DE COMPAÑÍA
          return [
            dataItem.idPrenda,
            dataItem.codigoPrenda,
            dataItem.denoPrenda,
          ];
        return [
          dataItem.idTipoPrenda,
          dataItem.codigoPrenda.substr(0, 2),
          dataItem.denoTipoPrenda,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("producidas")
          .done(function (result) {
            resultItem = {
              idTipoPrenda: dataItem.key[0],
              producidas: result,
            };
          });
        query(dataItem.items)
          .sum("rechazadas")
          .done(function (result) {
            resultItem = {
              idTipoPrenda: dataItem.key[0],
              producidas: resultItem.producidas,
              rechazadas: result,
            };
          });
        query(dataItem.items)
          .sum("retiradas")
          .done(function (result) {
            resultItem = {
              idTipoPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              producidas: resultItem.producidas,
              rechazadas: resultItem.rechazadas,
              retiradas: result,
            };
          });
        query(dataItem.items)
          .sum("procesadas")
          .done(function (result) {
            resultItem = {
              porcentaje_rechazo:
                result !== 0 ? resultItem.rechazadas / result : 0,
              porcentaje_retiro:
                result !== 0 ? resultItem.retiradas / result : 0,
              idTipoPrenda: is_todasCompa ? dataItem.key[0] : null,
              idPrenda: !is_todasCompa ? dataItem.key[0] : null,
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              producidas: resultItem.producidas,
              rechazadas: resultItem.rechazadas,
              retiradas: resultItem.retiradas,
              procesadas: result,
            };
          });
        return resultItem;
      })
      .toArray();

    $("#dxDataGrid_produccionesPrenda")
      .dxDataGrid("instance")
      .option("dataSource", dataSource);
    var dxDataGrid_rechazoPrenda = $("#dxDataGrid_rechazoPrenda").dxDataGrid(
      "instance",
    );
    dxDataGrid_rechazoPrenda.option("dataSource", dataSource);
    dxDataGrid_rechazoPrenda.deselectAll();
  }

  function setDataSource_tipoRech(items) {
    var items_tipoRech = filtroDatos_prodRech(
      datasource_spSelectTipoRechazoNPrenda.items(),
    );

    var dxDataGrid_rechazoPrenda = $("#dxDataGrid_rechazoPrenda").dxDataGrid(
        "instance",
      ),
      prendaSel =
        dxDataGrid_rechazoPrenda.getSelectedRowsData().length === 1
          ? dxDataGrid_rechazoPrenda.getSelectedRowsData()[0]
          : null;

    var totalProcesadas = 0;
    query(dxDataGrid_rechazoPrenda.option("dataSource"))
      .sum("procesadas")
      .done(function (result) {
        totalProcesadas = result;
      });

    // FILTRADO, AGRUPACIÓN Y SUMATORIO DE DATOS
    var dataSource = query(items_tipoRech)
      .groupBy(function (dataItem) {
        if (prendaSel) {
          if (!is_todasCompa)
            // SI ESTÁ SELECCIONADO PRENDAS DE COMPAÑÍA
            return [dataItem.idTipoRechazo, dataItem.idPrenda];
          return [dataItem.idTipoRechazo, dataItem.codigoPrenda.substr(0, 2)];
        } else return [dataItem.idTipoRechazo];
      })
      .filter(function (dataItem) {
        if (prendaSel) {
          if (!is_todasCompa)
            // SI ESTÁ SELECCIONADO PRENDAS DE COMPAÑÍA
            return dataItem.key[1] === prendaSel.idPrenda;
          return dataItem.key[1] === prendaSel.codigoPrenda;
        } else return true; // Queremos todos los visibles
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("rechazadas")
          .done(function (result) {
            resultItem = {
              idTipoRechazo: dataItem.key[0],
              rechazadas: result,
            };
          });
        query(dataItem.items)
          .sum("porcentaje_rechazo")
          .done(function (result) {
            resultItem = {
              idTipoRechazo: dataItem.key[0],
              rechazadas: resultItem.rechazadas,
              porcentaje_rechazo:
                resultItem.rechazadas /
                (prendaSel ? prendaSel.procesadas : totalProcesadas),
            };
          });
        return resultItem;
      })
      .filter(function (dataItem) {
        return dataItem.rechazadas > 0;
      })
      .toArray();
    $("#dxDataGrid_tiposRechazoNPrenda")
      .dxDataGrid("instance")
      .option("dataSource", dataSource);
  }
  //#endregion

  return deferred.promise();
}
