import dxTextBox from "devextreme/ui/text_box";

function dxComponentDefaultOptions() {
  dxTextBox.defaultOptions({
    options: {
      onInitialized: function (e) {
        e.component.on("change", function (ee) {
          var onChange_original = e.component.option("onChange");
          let value =
            e.component.option("value") != null
              ? e.component.option("value").replace(/\s+/g, " ").trim()
              : null; // Sin doble espacio ni espacio al principio/final.

          e.component.option("value", value);
          if (onChange_original) onChange_original.apply(this, arguments);
        });
      },
    },
  });
}
export default dxComponentDefaultOptions;
