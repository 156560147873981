import React from "react";
import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import { connectionConstants } from "./../../../../constants/connection";
import { errorHandler, authHeader } from "./../../../../helpers/connection";
import {
    addDays,
    datetimeToDuration,
    formatDate_noTime_parameter,
    formatDate_parameter,
    timeToDatetime,
} from "helpers";
import { createContext } from "react";
import query from "devextreme/data/query";
import { connect } from "react-redux";
import { personaActions } from "actions/fotos";
import idsCalendarioPersonal_Estado from "constants/enums/RRHH/tblCalendarioPersonal_Estado";

export function ds_errorHandler(error) {
    errorHandler(error, null);
}

export function ds_authHeader(request) {
    request.headers = { ...authHeader() };
}

export const getLavanderiasAccesibles = () => {
    const dataSource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
            errorHandler: ds_errorHandler,
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idLavanderia", "denominacion"],
    });

    return dataSource_tblLavanderia;
};

export const getTblTurno = (idLavanderia) => {
    const dataSource_tblTurno = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
            errorHandler: ds_errorHandler,
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: [
            "idTurno",
            "idLavanderia",
            "denominacion",
            "abreviatura",
            "idTurnoPadre",
            "horaEntrada",
            "horaSalida",
            "activo",
            "eliminado",
        ],
        filter: [["eliminado", "=", false], "and", ["activo", "=", true], "and", ["idLavanderia", "=", idLavanderia]],
    });

    return dataSource_tblTurno;
};

export const getTblCalendarioPersonal_estado = () => {
    const dataSource_tblCalendarioPersonal_estado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal_estado",
            // key: "idAlmacen",
            errorHandler: ds_errorHandler,
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["*"],
        sort: { selector: "denominacion", desc: false },
        filter: "idEstado ne 14",
    });
    return dataSource_tblCalendarioPersonal_estado;
};

let postCuadrantePromise;
const formatPayloadCuadrante = (payload) => {
    return payload.map((item) => {
        return {
            idCuadrantePersonal: item.idCuadrantePersonal > 0 ? item.idCuadrantePersonal : null,
            idPersona: item.idPersona,
            fecha: item.fecha,
            idEstado: item.idEstado,
            horaEntrada: item.horaEntrada,
            horaSalida: item.horaSalida,
            idTurno: item.idTurno,
            idPosicionNAreaLavanderiaNLavanderia: item.idPosicionNAreaLavanderiaNLavanderia,
            idLavanderia: item.idLavanderia,
        };
    });
};

export async function postChangesIntoCuadrantePersonal(payload) {
    payload = formatPayloadCuadrante(payload.filter((x) => x.idPersona && x.fecha && x.idLavanderia && x.idTurno));

    const context_tblCuadrantePersonal = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal",
        entities: { fn_IU_CuadrantePersonal: {} },
        errorHandler: ds_errorHandler,
        beforeSend: function (request) {
            request.headers = { ...authHeader() };
            request.payload = payload; //currentChangesList;
        },
    });

    if (postCuadrantePromise == null || postCuadrantePromise?.state != "pending") {
        postCuadrantePromise = context_tblCuadrantePersonal.invoke("fn_IU_CuadrantePersonal", {}, "POST");
    }

    return postCuadrantePromise;
}

export function getPostCuadrantePromise() {
    return postCuadrantePromise;
}

export async function patchCuadrantePersonalRecalcular(payload) {
    const context_Cuadrantes = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal",
        entities: {
            PrevisionCuadrantePersonal_Persona: {},
            RecalcularPrevisiones: {},
        },
        errorHandler: ds_errorHandler,
        beforeSend: (request) => {
            request.headers = { ...authHeader() };
            request.payload = formatPayloadCuadrante(payload);
        },
    });

    return context_Cuadrantes.invoke("RecalcularPrevisiones", {}, "PATCH");
}

export const CuadranteContext = createContext(null);

class Cuandrante_ContextProvider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tblPersona: [],
            tblCuadrantePersonal: [],
            tblJornada: [],
            tblPersonaNTipoContrato: [],
            tblCalendarioPersonal: [],
            tblDiasLibresPersonal: [],

            data: [],
            tblCalendarioLavanderia: [],

            tblFotosPersona: new Map(),

            tblTurno: [],
            tblTipoTrabajo: [],
            tblCalendarioPersonal_Estado: [],
            tblMotivoIncumplimientoJornada: [],

            idTipoTrabajoSel:
                !props.user.enableDatosRRHH &&
                props.user.tblTipoTrabajoNUsuario.filter((x) => x.idLavanderia === props.lavanderia.idLavanderia)
                    .length === 1
                    ? props.user.tblTipoTrabajoNUsuario[0].idTipoTrabajo
                    : null,
            selectTipoTrabajo: this.selectTipoTrabajo,

            reload: this.reload,
            loadAll: this.loadAll,
            getDS_tblLlamamiento: this.getDS_tblLlamamiento,
        };

        this.requestFotosFrom = [];
    }

    // #region DataSources DATOS

    getFiltroPersona = () => {
        const { lavanderia, selectedRange } = this.props;

        return [
            [
                ["idLavanderia", "=", lavanderia.idLavanderia],
                "or",
                [
                    `tblCuadrantePersonal/any(x: x/idLavanderia eq ${
                        lavanderia.idLavanderia
                    } and x/fecha ge ${formatDate_noTime_parameter(
                        selectedRange.firstDay
                    )} and x/fecha le ${formatDate_noTime_parameter(selectedRange.lastDay)})`,
                ],
            ],
            [
                `tblPersonaNTipoContrato/any(x: x/fechaAltaContrato le ${formatDate_noTime_parameter(
                    selectedRange.lastDay
                )} and (x/fechaBajaContrato eq null or x/fechaBajaContrato ge ${formatDate_noTime_parameter(
                    selectedRange.firstDay
                )}))`,
            ],
        ];
    };

    dataSource_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { lavanderia, selectedRange } = this.props;

        request.params = {
            idLavanderia: lavanderia.idLavanderia,
            fechaDesde: formatDate_noTime_parameter(selectedRange.firstDay),
            fechaHasta: formatDate_noTime_parameter(selectedRange.lastDay),
            idTipoTrabajo: this.state.idTipoTrabajoSel,
        };
    };

    dataSource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal/GetPersonas",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        map: (item) => this.dataSource_tblPersona_map(item),
    });

    dataSource_tblPersona_map = (item) => {
        item.listId = `${item.nombre} ${item.apellidos}-${item.idPersona}`;
        delete item.nombre;
        delete item.apellidos;
        return item;
    };

    dataSource_tblCuadrantePersonal = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal/GetCuadrantes",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        map: (item) => this.dataSource_tblCuadrantePersonal_map(item),
    });

    dataSource_tblCuadrantePersonal_map = (item) => {
        item.fecha = item.fecha.split("T")[0];
        if (item.horaEntrada) item.horaEntrada = datetimeToDuration(timeToDatetime(item.horaEntrada));
        if (item.horaSalida) item.horaSalida = datetimeToDuration(timeToDatetime(item.horaSalida));
        return item;
    };

    dataSource_tblJornada = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal/GetJornadas",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        map: (item) => this.dataSource_tblJornada_map(item),
    });

    dataSource_tblJornada_map = (item) => {
        item.fecha = item.fecha.split("T")[0];
        if (item.horaIni) item.horaIni = datetimeToDuration(timeToDatetime(item.horaIni));
        if (item.horaFin) item.horaFin = datetimeToDuration(timeToDatetime(item.horaFin));
        if (item.tiempoDescanso) item.tiempoDescanso = datetimeToDuration(timeToDatetime(item.tiempoDescanso));
        return item;
    };

    dataSource_tblPersonaNTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url:
                connectionConstants.WEB_API_CORE_ODATA_URL +
                "MyPolarier/RRHH/CuadrantePersonal/GetPersonaNTipoContrato",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
    });

    dataSource_tblCalendarioPersonal = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal/GetCalendarioPersonal",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
        map: (item) => this.dataSource_tblCalendarioPersonal_map(item),
    });

    dataSource_tblCalendarioPersonal_map = (item) => {
        item.fecha = item.fecha.split("T")[0];
        return item;
    };

    dataSource_tblDiasLibresPersonal = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal/getDiasLibres",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_beforeSend(request),
            version: 4,
        }),
    });

    dataSource_tblCalendarioLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioLavanderia",
            errorHandler: ds_errorHandler,
            beforeSend: (request) => this.dataSource_tblCalendarioLavanderia_beforeSend(request),
            version: 4,
        }),
    });

    dataSource_tblCalendarioLavanderia_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { lavanderia, selectedRange } = this.props;

        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.fechaDesde = formatDate_parameter(selectedRange.firstDay);
        request.params.fechaHasta = formatDate_parameter(selectedRange.lastDay);
    };

    dataSource_tblLlamamiento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLlamamiento",
            key: "idLlamamiento",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            onLoading: (loadOptions) => this.dataSource_tblLlamamiento_onLoading(loadOptions),
            version: 4,
        }),
        expand: [
            "tblDiasLibresPersonal_Llamamiento($select=idDiaSemana,idDiaMes,numDia)",
            "idCategoriaInternaNavigation($select=denominacion)",
        ],
        map: (item) => this.dataSource_tblLlamamiento_map(item),
    });

    dataSource_tblLlamamiento_onLoading = (loadOptions) => {
        const { lavanderia, selectedRange } = this.props;
        const { idTipoTrabajoSel } = this.state;

        loadOptions.filter = [
            ["activo", "=", true],
            ["idPersona", "=", null],
            ["idLavanderia", "=", lavanderia.idLavanderia],
            [`fechaIni le ${formatDate_noTime_parameter(selectedRange.lastDay)}`],
        ];

        if (idTipoTrabajoSel != null) {
            loadOptions.filter.push(["idTipoTrabajo", "=", idTipoTrabajoSel]);
        }
    };

    dataSource_tblLlamamiento_map = (item) => {
        item.fechaIni = new Date(item.fechaIni);
        item.categoriaInterna = item.idCategoriaInternaNavigation?.denominacion;
        return item;
    };

    // #endregion

    // #region DataSources estáticas

    dataSource_tblTurno = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            onLoading: (loadOptions) => this.dataSource_tblTurno_onLoading(loadOptions),
            version: 4,
        }),
        select: [
            "idTurno",
            "idLavanderia",
            "denominacion",
            "abreviatura",
            "idTurnoPadre",
            "horaEntrada",
            "horaSalida",
            "descanso",
            "activo",
            "eliminado",
        ],
    });

    dataSource_tblTurno_onLoading = (loadOptions) => {
        const { lavanderia } = this.props;

        loadOptions.filter = [
            ["eliminado", "=", false],
            ["activo", "=", true],
            ["idLavanderia", "=", lavanderia.idLavanderia],
        ];
    };

    dataSource_tblCalendarioPersonal_Estado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal_estado",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            version: 4,
        }),
        sort: { selector: "denominacion", desc: false },
        filter: "idEstado ne 14",
    });

    dataSource_tblMotivoIncumplimientoJornada = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMotivoIncumplimientoJornada",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            version: 4,
        }),
        select: ["idMotivoIncumplimientoJornada", "denominacion"],
    });

    datasource_tblFormatoDiasLibres = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFormatoDiasLibres",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            version: 4,
        }),
    });

    datasource_tblTipoTrabajo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoTrabajo",
            errorHandler: ds_errorHandler,
            beforeSend: ds_authHeader,
            version: 4,
        }),
        select: ["idTipoTrabajo", "denominacion"],
    });

    // #endregion

    componentDidMount = () => {
        Promise.all([
            this.dataSource_tblTurno.reload(),
            this.dataSource_tblCalendarioPersonal_Estado.reload(),
            this.dataSource_tblMotivoIncumplimientoJornada.reload(),
            this.datasource_tblFormatoDiasLibres.reload(),
            this.datasource_tblTipoTrabajo.reload(),
        ]).then(
            ([
                tblTurno,
                tblCalendarioPersonal_Estado,
                tblMotivoIncumplimientoJornada,
                tblFormatoDiasLibres,
                tblTipoTrabajo,
            ]) => {
                this.setState({
                    tblTurno,
                    tblCalendarioPersonal_Estado,
                    tblMotivoIncumplimientoJornada,
                    tblFormatoDiasLibres,
                    tblTipoTrabajo,
                });
                this.loadAll();
            }
        );
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.lavanderia.idLavanderia !== this.props.lavanderia.idLavanderia) {
            this.dataSource_tblTurno.reload().then((tblTurno) => {
                this.setState({ tblTurno });
                this.loadAll();
            });
        }
        if (prevProps.selectedRange !== this.props.selectedRange) {
            this.loadAll();
        }
        if (prevState.idTipoTrabajoSel !== this.state.idTipoTrabajoSel) {
            this.loadAll();
        }
    };

    loadAll = () => {
        let deferred = $.Deferred();

        Promise.all([
            this.dataSource_tblJornada.load(),
            this.dataSource_tblCalendarioPersonal.load(),
            this.dataSource_tblPersona.load(),
            this.dataSource_tblCuadrantePersonal.load(),
            this.dataSource_tblPersonaNTipoContrato.load(),
            this.dataSource_tblDiasLibresPersonal.load(),
            this.dataSource_tblCalendarioLavanderia.load(),
            this.dataSource_tblLlamamiento.load(),
        ]).then(
            ([
                tblJornada,
                tblCalendarioPersonal,
                tblPersona,
                tblCuadrantePersonal,
                tblPersonaNTipoContrato,
                tblDiasLibresPersonal,
                tblCalendarioLavanderia,
                tblLlamamiento,
            ]) => {
                tblCuadrantePersonal = query(tblCuadrantePersonal).groupBy("idPersona").toArray();
                tblJornada = query(tblJornada).groupBy("idPersona").toArray();
                tblCalendarioPersonal = query(tblCalendarioPersonal).groupBy("idPersona").toArray();
                tblDiasLibresPersonal = query(tblDiasLibresPersonal).groupBy("idPersona").toArray();
                tblPersonaNTipoContrato = query(tblPersonaNTipoContrato).groupBy("idPersona").toArray();

                this.calcData(
                    [
                        tblJornada,
                        tblCalendarioPersonal,
                        tblPersona,
                        tblCuadrantePersonal,
                        tblPersonaNTipoContrato,
                        tblDiasLibresPersonal,
                        tblCalendarioLavanderia,
                        tblLlamamiento,
                    ],
                    deferred
                );

                const { tblPersona: newtblPersona, tblFotosPersona } = this.state;

                this.requestFotosFrom = newtblPersona.map((x) => x.idPersona).filter((id) => !tblFotosPersona.has(id));
                this.props.loadFotoPerfilMasivo(this.requestFotosFrom);
            }
        );

        return deferred.promise();
    };

    reload = () => {
        let deferred = $.Deferred();

        Promise.all([
            this.dataSource_tblJornada.load(),
            this.dataSource_tblCuadrantePersonal.load(),
            this.dataSource_tblCalendarioPersonal.load(),
            this.dataSource_tblLlamamiento.load(),
        ]).then(([tblJornada, tblCuadrantePersonal, tblCalendarioPersonal, tblLlamamiento]) => {
            const { tblPersona, tblPersonaNTipoContrato, tblDiasLibresPersonal, tblCalendarioLavanderia } = this.state;

            tblCuadrantePersonal = query(tblCuadrantePersonal).groupBy("idPersona").toArray();
            tblJornada = query(tblJornada).groupBy("idPersona").toArray();
            tblCalendarioPersonal = query(tblCalendarioPersonal).groupBy("idPersona").toArray();

            this.calcData(
                [
                    tblJornada,
                    tblCalendarioPersonal,
                    tblPersona,
                    tblCuadrantePersonal,
                    tblPersonaNTipoContrato,
                    tblDiasLibresPersonal,
                    tblCalendarioLavanderia,
                    tblLlamamiento,
                ],
                deferred
            );
        });

        return deferred;
    };

    calcData = (
        [
            tblJornada,
            tblCalendarioPersonal,
            tblPersona,
            tblCuadrantePersonal,
            tblPersonaNTipoContrato,
            tblDiasLibresPersonal,
            tblCalendarioLavanderia,
            tblLlamamiento,
        ],
        deferred
    ) => {
        const { lavanderia } = this.props;
        const { tblTurno } = this.state;

        const fechas = this.generarFechas();

        let data = [fechas, tblPersona].reduce((a, b) =>
            a.flatMap((fecha) =>
                b.map((persona) => {
                    const cuadrante = tblCuadrantePersonal
                        .find((x) => x.key === persona.idPersona)
                        ?.items.find((x) => x.fecha === formatDate_noTime_parameter(fecha));
                    const jornadas =
                        tblJornada
                            .find((x) => x.key === persona.idPersona)
                            ?.items.filter((x) => x.fecha === formatDate_noTime_parameter(fecha)) ?? [];
                    const calendarioPersonal = tblCalendarioPersonal
                        .find((x) => x.key === persona.idPersona)
                        ?.items.find((x) => x.fecha === formatDate_noTime_parameter(fecha));

                    let eventoLavanderia = tblCalendarioLavanderia.find(
                        (x) =>
                            x.fecha === formatDate_noTime_parameter(fecha) &&
                            x.idEstado !== idsCalendarioPersonal_Estado.CierreNomina
                    );
                    let isDiaLibre =
                        tblDiasLibresPersonal
                            .find((x) => x.key === persona.idPersona)
                            ?.items.find(
                                (x) =>
                                    x.idDiaSemana === fecha.getDay() ||
                                    (x.idDiaSemana === 7 && fecha.getDay() === 0) ||
                                    x.idDiaMes === fecha.getDate()
                            ) != null;
                    let templateCalendario = {
                        idEstado:
                            isDiaLibre &&
                            !cuadrante?.idEstado &&
                            !eventoLavanderia &&
                            !calendarioPersonal &&
                            jornadas.length === 0
                                ? 9
                                : cuadrante?.idEstado === 3 && eventoLavanderia?.idEstado === 8
                                ? 4
                                : eventoLavanderia?.idEstado === 10
                                ? 10
                                : calendarioPersonal?.idEstado ??
                                  (jornadas.length > 0 && eventoLavanderia?.idEstado === 8
                                      ? 4
                                      : eventoLavanderia?.idEstado) ??
                                  3,
                        idPersona: persona.idPersona,
                        fecha,
                    };

                    let item = {
                        data: {
                            fecha,
                            listId: persona.listId,
                            idPersona: persona.idPersona,
                            idTipoTrabajo: persona.idTipoTrabajo,
                            categoriaInterna: persona.categoriaInterna,
                            cuadrante: cuadrante,
                            jornadas: jornadas,
                            calendarioPersonal:
                                cuadrante?.idLavanderia === lavanderia.idLavanderia ||
                                persona.idLavanderia === lavanderia.idLavanderia
                                    ? (!cuadrante && eventoLavanderia ? templateCalendario : calendarioPersonal) ??
                                      (jornadas?.length > 0 || cuadrante ? templateCalendario : eventoLavanderia) ??
                                      templateCalendario
                                    : null,
                        },
                    };

                    for (const jornada of item.data.jornadas) {
                        jornada.tblBalanceHorasExtra = setBalances(jornada, "tblBalanceHorasExtra");
                        jornada.tblBalanceHoras = setBalances(jornada, "tblBalanceHoras");
                    }

                    item.data.isContratado = false;

                    let contratos = tblPersonaNTipoContrato.find((x) => x.key === persona.idPersona)?.items;
                    for (const contrato of contratos) {
                        let fechaAltaContrato = new Date(contrato.fechaAltaContrato);
                        let fechaBajaContrato = new Date(contrato.fechaBajaContrato);
                        if (
                            (fecha >= fechaAltaContrato && fecha <= fechaBajaContrato) ||
                            (fecha >= fechaAltaContrato && !contrato.fechaBajaContrato)
                        ) {
                            item.data.isContratado = true;
                            break;
                        }
                    }

                    if (
                        (!item.data.cuadrante || !item.data.cuadrante.idEstado) &&
                        (cuadrante?.idLavanderia === lavanderia.idLavanderia ||
                            persona.idLavanderia === lavanderia.idLavanderia)
                    ) {
                        if (
                            item.data.isContratado &&
                            (cuadrante?.idLavanderia === lavanderia.idLavanderia ||
                                persona.idLavanderia === lavanderia.idLavanderia)
                        ) {
                            let turno = tblTurno.find(
                                (x) =>
                                    (cuadrante?.idTurno && x.idTurno === cuadrante?.idTurno) ||
                                    (!cuadrante?.idTurno && x.idTurno === persona.idTurno)
                            );

                            let idEstado = templateCalendario.idEstado; //eventoPersonal ? eventoPersonal.idEstado : eventoLavanderia ? eventoLavanderia.idEstado : isDiaLibre ? 9 : 3

                            item.data.estimacion = {
                                idPersona: persona.idPersona,
                                idCuadrantePersonal: cuadrante?.idCuadrantePersonal,
                                idLavanderia: cuadrante?.idLavanderia ?? persona.idLavanderia,
                                idTurno: cuadrante?.idTurno ?? persona.idTurno,
                                fecha: formatDate_noTime_parameter(fecha),
                                idEstado: idEstado,
                                horaEntrada: idEstado == 3 || idEstado == 4 ? turno?.horaEntrada : null,
                                horaSalida: idEstado == 3 || idEstado == 4 ? turno?.horaSalida : null,
                            };
                        } else {
                            item.data.calendarioPersonal = null;
                            item.data.contratos = contratos;
                        }
                    }

                    return item;
                })
            )
        );

        // TODO: tradurcir
        data = data.concat(
            [fechas.filter((x) => x > Date.now()), tblLlamamiento].reduce((a, b) =>
                a.flatMap((fecha) =>
                    b.map((llamamiento) => {
                        const turno = tblTurno.find((x) => llamamiento.idTurno === x.idTurno);
                        const listId = `INCORPORACIÓN #${llamamiento.codigoLlamamiento}-${llamamiento.idLlamamiento}`;

                        let eventoLavanderia = tblCalendarioLavanderia.find(
                            (x) =>
                                x.fecha === formatDate_noTime_parameter(fecha) &&
                                x.idEstado !== idsCalendarioPersonal_Estado.CierreNomina
                        );
                        const isDiaLibre =
                            llamamiento.tblDiasLibresPersonal_Llamamiento.find(
                                (x) =>
                                    x.idDiaSemana === fecha.getDay() ||
                                    (x.idDiaSemana === 7 && fecha.getDay() === 0) ||
                                    x.idDiaMes === fecha.getDate()
                            ) != null;
                        let idEstado = eventoLavanderia?.idEstado ?? (isDiaLibre ? 9 : 3);

                        let item = {
                            data: {
                                fecha,
                                listId: listId,
                                idLlamamiento: llamamiento.idLlamamiento,
                                fechaIni: llamamiento.fechaIni,
                                categoriaInterna: llamamiento.categoriaInterna,
                                estimacion: {
                                    idLlamamiento: llamamiento.idLlamamiento,
                                    idLavanderia: llamamiento.idLavanderia,
                                    idTurno: llamamiento.idTurno,
                                    fecha: formatDate_noTime_parameter(fecha),
                                    idEstado: idEstado,
                                    horaEntrada: !isDiaLibre ? turno?.horaEntrada : null,
                                    horaSalida: !isDiaLibre ? turno?.horaSalida : null,
                                },
                            },
                        };

                        return item;
                    })
                )
            )
        );

        this.setState({
            tblPersona,
            tblCuadrantePersonal,
            tblJornada,
            tblPersonaNTipoContrato,
            tblCalendarioPersonal,
            tblDiasLibresPersonal,
            data,
            tblCalendarioLavanderia,
            tblLlamamiento,
        });
        deferred.resolve();
    };

    render() {
        const { children, refs } = this.props;

        return <CuadranteContext.Provider value={{ ...this.state, ...refs }}>{children}</CuadranteContext.Provider>;
    }

    generarFechas = () => {
        const { selectedRange } = this.props;

        const fechas = [selectedRange.firstDay];

        while (fechas.length < (selectedRange.weekAmount + 1) * 7) {
            fechas.push(addDays(fechas[fechas.length - 1], 1));
        }

        return fechas;
    };

    getDS_tblLlamamiento = () => {
        return this.dataSource_tblLlamamiento;
    };

    selectTipoTrabajo = (idTipoTrabajo) => this.setState({ idTipoTrabajoSel: idTipoTrabajo });
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadFotoPerfilMasivo: (idPersona) => dispatch(personaActions.loadFotoPerfilMasivo(idPersona)),
});

export const CuandranteContextProvider = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    Cuandrante_ContextProvider
);

export function setBalances(jornada, key) {
    let tbl = jornada[key];
    let id = key.replace("tbl", "id");

    if (!tbl.find((x) => x.isInicio)) {
        tbl.push({
            [id]: null,
            idPersona: jornada.idPersona,
            idJornada: jornada.idJornada,
            fecha: jornada.fecha,
            minutos: 0,
            isInicio: true,
        });
    }

    if (!tbl.find((x) => !x.isInicio)) {
        tbl.push({
            [id]: null,
            idPersona: jornada.idPersona,
            idJornada: jornada.idJornada,
            fecha: jornada.fecha,
            minutos: 0,
            isInicio: false,
        });
    }

    return tbl;
}

export class CuadranteContextConsumer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { children } = this.props;

        return (
            <CuadranteContext.Consumer>
                {(context) => {
                    const newProps = {
                        CuadranteContext: context,
                    };

                    if (children.length > 1) {
                        return children.map((ch) => React.cloneElement(ch, newProps));
                    } else {
                        return React.cloneElement(children, newProps);
                    }
                }}
            </CuadranteContext.Consumer>
        );
    }
}
