import { personaConstants } from "../constants";
import { fotosService } from "services/fotos";

export const personaActions = {
    loadFotoPerfil,
    loadFotoPerfilMasivo,
};

function loadFotoPerfil(idPersona) {
    return (dispatch) => {
        fotosService.loadFotoPerfil(idPersona).then(
            (data) => {
                dispatch(success(data));
            },
            (error) => {
                dispatch(failure(error));
            }
        );
    };

    function success(data) {
        return { type: personaConstants.LOAD_FOTOPERFIL_SUCCESS, data };
    }
    function failure(error) {
        return { type: personaConstants.LOAD_FOTOPERFIL_FAILURE, error };
    }
}

function loadFotoPerfilMasivo(idsPersona) {
    return (dispatch) => {
        while (idsPersona.length > 0) {
            let ids = idsPersona.splice(0, 20);
            fotosService.loadFotoPerfilMasivo(ids).then(
                (data) => {
                    dispatch(success(data));
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
        }
    };

    function success(data) {
        return { type: personaConstants.LOAD_FOTOPERFILMASIVO_SUCCESS, data };
    }
    function failure(error) {
        return { type: personaConstants.LOAD_FOTOPERFILMASIVO_FAILURE, error };
    }
}
