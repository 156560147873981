import React, { useEffect, useState } from "react";

//Css
import "./Css.scss";

const ItemRender = ({ data }) => {
  return <div className={data.icon + " " + data.estado + " tipoTrabajoIcon"} />;
};

export default ItemRender;
