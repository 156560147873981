import { useEffect } from "react";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { authHeader, errorHandler, formatDateTime_parameter } from "helpers";
import { connectionConstants } from "constants";

const BusquedaRecambioProvider = ({
  formData,
  idRecambioSel,
  idRecambioNProveedorSel,
  onGetRecambio,
  children,
}) => {
  useEffect(() => {
    if (idRecambioSel || idRecambioNProveedorSel) {
      datasource_buscarRecambio.reload().done((data) => onGetRecambio(data[0]));
    }
  }, [idRecambioSel, idRecambioNProveedorSel]);

  const {
    fecha,
    idAlmacenDestino,
    idAlmacenOrigen,
    idMovimientoRecambio,
    idProveedor,
    idTipoMovimientoRecambio,
  } = formData;

  const datasource_buscarRecambio_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    request.params.idAlmacenOrigen = idAlmacenOrigen;
    request.params.idAlmacenDestino = idAlmacenDestino;
    request.params.fecha = formatDateTime_parameter(fecha);
    request.params.idTipoMovimientoRecambio = idTipoMovimientoRecambio;
    request.params.idProveedor = idProveedor;
    request.params.idMovimientoRecambio = idMovimientoRecambio;
    request.params.idRecambio = idRecambioSel;
    request.params.idRecambioNProveedor = idRecambioNProveedorSel;
  };

  const datasource_buscarRecambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/MovimientoRecambio/GetRecambio_MovimientoRecambio",
      errorHandler,
      beforeSend: datasource_buscarRecambio_beforeSend,
      version: 4,
    }),
  });

  return children;
};

export default BusquedaRecambioProvider;
