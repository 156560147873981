import React from "react";
import { Popup, ScrollView } from "devextreme-react";
import { datasource_tblPersona } from "../datasources";
import { getTrad } from "helpers";
import DatosPersonales from "./DatosPersonales";
import DatosLocalizacion from "./DatosLocalizacion";
import DatosFotos from "./DatosFotos";
import LottieIcon from "components/LottieIcon";
import FotoPerfil from "./FotoPerfil";
import DatosSalariales from "./DatosSalariales";

class index extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      datosPersonaSel: null,
    };
  }

  hidePopup = () => {
    const { onValueChange } = this.props;
    onValueChange({ showPopupDatosPersona: false, idPersonaSel: null }, () => {
      setTimeout(() => {
        this.setState({ datosPersonaSel: null });
      }, 100);
    });
  };

  componentDidUpdate(prevProps) {
    const { idPersonaSel, visible } = this.props;

    if (visible && idPersonaSel && idPersonaSel !== prevProps.idPersonaSel) {
      datasource_tblPersona(idPersonaSel)
        .load()
        .done((data) => {
          this.setState({ datosPersonaSel: data[0] });
        });
    }
  }

  render() {
    const { visible } = this.props;
    const { datosPersonaSel } = this.state;

    return (
      <Popup
        showTitle={true}
        title={getTrad("datosPersonales")}
        visible={visible}
        showCloseButton={true}
        closeOnOutsideClick={true}
        width={"40%"}
        minWidth={400}
        height={"85%"}
        onHiding={this.hidePopup}
        className="px-1"
      >
        <div
          style={datosPersonaSel != null ? { height: "0px" } : {}}
          className="he-100 d-flex flex-column justify-content-center align-items-center "
        >
          <LottieIcon icon="loading_circular" height={"50%"} width={"50%"} />
          <h4 style={datosPersonaSel != null ? { display: "none" } : {}}>Cargando datos de la persona...</h4>
        </div>
        <ScrollView>
          <div style={datosPersonaSel == null ? { display: "none" } : {}} className="p-3">
            <FotoPerfil datosPersonaSel={datosPersonaSel} />
            <DatosPersonales datosPersonaSel={datosPersonaSel} formIcons={this.formIcons} />
            <DatosSalariales datosPersonaSel={datosPersonaSel} formIcons={this.formIcons} />
            <DatosLocalizacion datosPersonaSel={datosPersonaSel} formIcons={this.formIcons} />
            <DatosFotos datosPersonaSel={datosPersonaSel} formIcons={this.formIcons} />
          </div>
        </ScrollView>
      </Popup>
    );
  }

  get formIcons() {
    return {
      fotoPerfil: "icon_face_recognition",
      nombre: "icon_Rendimiento",
      apellidos: "icon_account_details_outline",
      email: "icon_email_outline",
      telefono: "icon_Telefono",
      calle: "inf_icons_RutasExpedicion",
      numDomicilio: "icon_numeric",
      piso: "icon_format_list_number_outline",
      puerta: "icon_door_outline",
      codigoPostal: "icon_mailbox_open_outline",
      localidad: "icon_city_variant_outline",
      nacionalidad: "icon_flag_variant_outline",
      comunidadAutonoma: "icon_map_outline",
      pais: "icon_earth",
      tipoDocumento: "icon_card_account_details_outline",
      numDocumento: "icon_numeric",
      caducidad: "icon_CalendarioMes",
      fotoCaraDelantera: "icon_badge_account_horizontal_outline",
      fotoCaraTrasera: "icon_badge_account_horizontal_outline",
      fotoNAF: "icon_id_card",
      fotoIBAN: "icon_credit_card_outline",
      NAF: "icon_id_card",
      IBAN: "icon_credit_card_outline",
      genero: "icon_gender_male_female_variant",
      fechaNacimiento: "icon_cake",
      nombreTutor: "icon_Rendimiento",
      apellidosTutor: "icon_account_details_outline",
      tipoDocumentoTutor: "icon_card_account_details_outline",
      numDocumentoTutor: "icon_numeric",
      estadoCivil: "inf_icons_RRHH",
      numHijos: "icon_account_child_outline",
      nivelEstudios: "icon_book_account_outline",
      discapacidad: "icon_book_account_outline",
      fotoDemandanteEmpleo: "icon_card_account_details_outline",
      certificadoDiscapacidad: "icon_book_account_outline",
      tallaCamiseta: "menu_icons_Valet",
      tallaPantalon: "icon_numeric",
    };
  }
}

export default index;
