import React, { Fragment } from "react";
import { connect } from "react-redux";

import { history, getEstructura } from "../../../helpers";

//Actions
import { loadPanelActions } from "../../../actions";

import ScrollView from "devextreme-react/scroll-view";
import { Container, Col, Row, Button } from "reactstrap";
import { globalActions } from "../../../actions";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.redirectPage = this.redirectPage.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  redirectPage = (path) => (e) => {
    history.push(path);
  };

  render() {
    let { lavanderia, idioma } = this.props;
    let estructuraMenu = getEstructura(lavanderia);

    return (
      <Fragment>
        <div className={"media-body"}>
          {estructuraMenu &&
          estructuraMenu.Informes &&
          estructuraMenu.Informes.content.length > 0 ? (
            <div className="formContainer scrollbar-container">
              <ScrollView>
                <Container style={{ maxWidth: "none" }} className="m-0 p-0">
                  {estructuraMenu.Informes.content
                    .filter((x) => x.content.length > 0)
                    .map((apart, i) => (
                      <Row>
                        <Col className="mb-4">
                          <div className="titForm">
                            <h3>
                              {apart.tblTraduccion !== null &&
                              typeof idioma !== "undefined"
                                ? apart.tblTraduccion[idioma.codigo]
                                : apart.label}
                            </h3>
                          </div>
                          <Row>
                            {apart.content
                              .filter((x) => !x.content || x.content.length > 0)
                              .map((child, i) =>
                                !child.content ? (
                                  <Col xs="6" sm="3" md="">
                                    <Button
                                      className="btn-icon-vertical btn-square btn-transition w-100"
                                      outline
                                      color="link"
                                      onClick={this.redirectPage(child.to)}
                                    >
                                      <i
                                        className={
                                          "icon-gradient bg-polarier btn-icon-wrapper btn-icon-lg mb-3 " +
                                          child.icon
                                        }
                                      >
                                        {" "}
                                      </i>
                                      {child.tblTraduccion !== null &&
                                      typeof idioma !== "undefined"
                                        ? child.tblTraduccion[idioma.codigo]
                                        : child.label}
                                    </Button>
                                  </Col>
                                ) : (
                                  <Col xs="12" sm="6" md="4" lg="4" xl="">
                                    <div className="font-size-lg text-center mt-2 ml-3 mr-3 p-2 underline_bottom_dotted">
                                      {child.tblTraduccion !== null &&
                                      typeof idioma !== "undefined"
                                        ? child.tblTraduccion[idioma.codigo]
                                        : child.label}
                                    </div>
                                    <Row>
                                      {child.content.map((child, i) => (
                                        <Col xs="6">
                                          <Button
                                            className="btn-icon-vertical btn-square btn-transition w-100"
                                            outline
                                            color="link"
                                            onClick={this.redirectPage(
                                              child.to,
                                            )}
                                          >
                                            <i
                                              className={
                                                "icon-gradient bg-polarier btn-icon-wrapper btn-icon-lg mb-3 " +
                                                child.icon
                                              }
                                            >
                                              {" "}
                                            </i>
                                            {child.tblTraduccion !== null &&
                                            typeof idioma !== "undefined"
                                              ? child.tblTraduccion[
                                                  idioma.codigo
                                                ]
                                              : child.label}
                                          </Button>
                                        </Col>
                                      ))}
                                    </Row>
                                  </Col>
                                ),
                              )}
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </Container>
              </ScrollView>
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    this.loadPanel_hide();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  aplicacionActiva: state.Global.aplicacionActiva,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
