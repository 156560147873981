import React, { Fragment, createRef } from "react";
import { ScrollView, Tooltip } from "devextreme-react";
import query from "devextreme/data/query";
import { SVContext } from "../components/SmartViewContext";
import EstructuraItem from "./components/Item";

import RGL, { WidthProvider } from "react-grid-layout";
import { connect } from "react-redux";
const ReactGridLayout = WidthProvider(RGL);
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

class SmartViewLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowHeight: 185,
    };

    this.dxScrollView_REF = createRef();
  }

  get dxScrollView() {
    return this.dxScrollView_REF.current.instance;
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateRowHeight);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.tblPosicion) !==
      JSON.stringify(this.props.tblPosicion)
    ) {
      const data = this.props.tblPosicion;

      const maquinas = query(data.filter((x) => x.maquina != null))
        .groupBy((x) => x.maquina.idMaquina)
        .toArray();
      const areas = query(data.filter((x) => x.maquina == null))
        .groupBy("idAreaLavanderia")
        .toArray();

      const tblPosicion = maquinas.concat(areas);

      this.setState({ tblPosicion: tblPosicion });
    }
  }

  updateRowHeight = () => {
    this.setState({
      rowHeight: (this.dxScrollView.element().height() - 50) / 4,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateRowHeight);
  }

  /**
   * ESTRUCTURA DE DATOS
   */
  render() {
    const { rowHeight } = this.state;
    const { resolucion, pagina = 0 } = this.props;

    return (
      <SVContext.Consumer>
        {({ tblPosicion, tooltipData, layoutPages, paginado }) => {
          const maquinas = query(tblPosicion.filter((x) => x.maquina != null))
            .groupBy((x) => x.maquina.idMaquina)
            .toArray();
          const areas = query(tblPosicion.filter((x) => x.maquina == null))
            .groupBy("idAreaLavanderia")
            .toArray();

          const items = maquinas.concat(areas);

          const layout = layoutPages[pagina];

          return (
            <Fragment>
              <ScrollView
                ref={this.dxScrollView_REF}
                className={"cardContent w-100 he-100"}
                onInitialized={(e) => {
                  this.setState({ rowHeight: (e.element.height() - 50) / 4 });
                }}
              >
                <ReactGridLayout
                  style={{
                    zoom:
                      resolucion === "xl"
                        ? 1
                        : resolucion === "lg"
                          ? 0.8
                          : resolucion === "md"
                            ? 0.6
                            : resolucion === "sm"
                              ? 0.4
                              : 0.2,
                  }}
                  layout={layout}
                  cols={60}
                  rowHeight={paginado ? rowHeight : 185}
                  verticalCompact={true}
                  isDraggable={false}
                  isResizable={false}
                >
                  {items
                    ?.filter(
                      (x) =>
                        layout.filter((y) => y.i == `layout-key-${x.key}`)
                          .length > 0,
                    )
                    .map((x) => (
                      <div
                        key={`layout-key-${x.key}`}
                        style={{ height: "fit-content" }}
                      >
                        <EstructuraItem data={x.items} />
                      </div>
                    ))}
                </ReactGridLayout>
                <Tooltip
                  target={tooltipData.target}
                  visible={tooltipData.target != null}
                  onContentReady={({ component }) => {
                    component.content().css("padding", "0");
                    component.content().css("border-radius", "1.125rem");
                    component
                      .content()
                      .parent()
                      .css("border-radius", "1.125rem");
                  }}
                >
                  <div>{tooltipData?.content}</div>
                </Tooltip>
              </ScrollView>
            </Fragment>
          );
        }}
      </SVContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
});

export default connect(mapStateToProps)(SmartViewLayout);
