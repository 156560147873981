import { getArticulos, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearPedido = () => {
    return {
        idTipoPedido: null,
        codigo: null,
        fechaCreacion: null,
        idAdmPedido_Estado: 1,
        idMoneda: getDefaultMonedaId(),
        idAdmProveedor: null,
        idAdmTipoCambio: 1,
        tasaCambio: 1,
        idAdmFormaPago: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        descuento: 0,
        idAdmTipoDescuento: 1,
        idIncoterm: null,
        fechaEstimadaRecepcion: null,
        numPresupuestoProveedor: null,
        observaciones: null,
        articulosDraft: [],
        tblArticuloNAdmPedidoProveedor: [],
    };
};

export const payloadBuilder = (data, queryReady = true) => {
    if (queryReady) {
        let payload = {
            idTipoPedido: data.idTipoPedido,
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idAdmPedido_Estado: data.idAdmPedido_Estado,
            idMoneda: data.idMoneda,
            idAdmProveedor: data.idAdmProveedor,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            idAdmFormaPago: data.idAdmFormaPago,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idIncoterm: data.idIncoterm,
            fechaEstimadaRecepcion: data.fechaEstimadaRecepcion,
            numPresupuestoProveedor: data.numPresupuestoProveedor,
            observaciones: data.observaciones,
            idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
            tblArticuloNAdmPedidoProveedor: getArticulos(data.tblArticuloNAdmPedidoProveedor, queryReady),
        };
        if (data.idAdmPedidoProveedor != null) {
            payload.idAdmPedidoProveedor = data.idAdmPedidoProveedor;
        }
        return payload;
    } else {
        return {
            idAdmPedidoProveedor: data.idAdmPedidoProveedor,
            idTipoPedido: data.idTipoPedido,
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idAdmPedido_Estado: data.idAdmPedido_Estado,
            idMoneda: data.idMoneda,
            idAdmProveedor: data.idAdmProveedor,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            idAdmFormaPago: data.idAdmFormaPago,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idIncoterm: data.idIncoterm,
            fechaEstimadaRecepcion: data.fechaEstimadaRecepcion,
            numPresupuestoProveedor: data.numPresupuestoProveedor,
            observaciones: data.observaciones,
            tblArticuloNAdmPedidoProveedor: getArticulos(data.tblArticuloNAdmPedidoProveedor),
        };
    }
};
