import React from "react";
import { getTrad } from "helpers";

const InfoItem = ({ icon, label, value }) => (
  <div className="d-flex w-100 m-2">
    <div className="d-flex w-100 m-2">
      <i className={icon + " " + "mr-4"} style={{ fontSize: 20 }} />
      <div>{getTrad(label)}</div>
    </div>
    <div className="d-flex w-100 m-2 justify-content-end">{value}</div>
  </div>
);

class DatosLocalizacion extends React.PureComponent {
  render = () => {
    const { datosPersonaSel, formIcons } = this.props;
    return (
      <div className="d-flex justify-content-center shadow flex-column align-items-center border-radius p-4 mt-4">
        <InfoItem icon={formIcons.calle} label="calle" value={datosPersonaSel?.calle} />
        <InfoItem icon={formIcons.numDomicilio} label="numDomicilio" value={datosPersonaSel?.numDomicilio} />
        <InfoItem icon={formIcons.piso} label="piso" value={datosPersonaSel?.piso} />
        <InfoItem icon={formIcons.puerta} label="puerta" value={datosPersonaSel?.puerta} />
        <InfoItem icon={formIcons.codigoPostal} label="codigoPostal" value={datosPersonaSel?.codigoPostal} />
        <InfoItem icon={formIcons.localidad} label="localidad" value={datosPersonaSel?.localidad} />
        <InfoItem
          icon={formIcons.comunidadAutonoma}
          label="comunidadAutonoma"
          value={datosPersonaSel?.idComunidadAutonomaNavigation?.denominacion}
        />
        <InfoItem icon={formIcons.pais} label="pais" value={datosPersonaSel?.idPaisNavigation?.denominacion} />
      </div>
    );
  };
}

export default DatosLocalizacion;
