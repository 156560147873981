import { connectionConstants } from "../constants";
import { authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const languageService = {
  load,
};

function load() {
  var deferred = $.Deferred();
  $.when(dataSource_tblIdioma.load(), dataSource_tblTraduccion.load()).then(
    function () {
      localStorage.setItem(
        "idiomas",
        JSON.stringify(dataSource_tblIdioma.items()),
      );
      localStorage.setItem(
        "traducciones",
        JSON.stringify(dataSource_tblTraduccion.items()),
      );

      deferred.resolve();
    },
  );
  return deferred.promise();
}

const dataSource_tblIdioma = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.ODATA_URL + "tblIdioma",
    key: "idIdioma",
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
});

const dataSource_tblTraduccion = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.ODATA_URL + `tblTraduccion`,
    version: 4,
  }),
  select: ["clave", "es", "en", "pt"],
  postProcess: function (data) {
    var result = {
      es: {},
      en: {},
      pt: {},
    };

    $.each(data, function (index, trad) {
      result.es[trad.clave] = trad.es;
      result.en[trad.clave] = trad.en;
      result.pt[trad.clave] = trad.pt;
    });
    return result;
  },
});
