import React from "react";

import { connectionConstants } from "constants";
import {
  endOfMonth,
  errorHandler,
  authHeader,
  getTrad,
  startOfMonth,
  formatDate_noTime_parameter,
  dxMensajePregunta,
} from "helpers";
import { connect } from "react-redux";

import PageTitle from "layout/AppMain/PageTitle";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import LoadPanel_ProgressBar from "components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";

import DataGrid_ContabilidadNominas from "./components/DataGrid_ContabilidadNominas";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";

import { Button, Switch } from "devextreme-react";

import notify from "devextreme/ui/notify";
import FilterNominas from "./components/FilterNominas";

class AsientosNominas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaSel: {
        startDate: startOfMonth(new Date()),
        endDate: new Date(endOfMonth(new Date()).setHours(23, 59, 59, 999)),
        idTipoCalendario: 0,
      },
      vista: false,
      progress: 0,
      isLoading: false,
      isVisible_LoadPanel_ProgressBar: false,
      filtroEstadoNomina: {
        pagado: true,
        pendientePago: true,
        anticipo: true,
        pendienteAnticipo: true,
        contabilizado: true,
        retenido: true,
      },
      dataSourceAgrupado: {},
      dataSource: [],
      tblHistoricoAsientoNomina: [],
    };

    this.DataGrid_REF = React.createRef();
  }

  get DataGrid() {
    return this.DataGrid_REF.current;
  }

  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
  };
  headerGap = { gap: 10 };

  // #region Lifecycle

  componentDidMount() {
    this.datasource_asientosNomina.reload().done((dataSource) => {
      const dataSourceAgrupado = Object.groupBy(
        dataSource,
        ({ idCentroElem }) => idCentroElem
      );

      this.setState({ dataSource, dataSourceAgrupado }, () => {
        this.DataGrid.dxDataGrid.refresh(false);
      });
    });
    this.load_tblHistoricoAsientoNomina();
  }

  componentDidUpdate(prevProps, prevState) {
    const { fechaSel } = this.state;
    const { empresa } = this.props;
    if (
      fechaSel !== prevState.fechaSel ||
      empresa.idEmpresaPolarier !== prevProps.empresa.idEmpresaPolarier
    ) {
      this.DataGrid.dxDataGrid.collapseAll(0);
      this.load_dataSource();
    }
  }

  // #endregion

  // #region DataSource

  datasource_asientosNomina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Contabilidad/AsientosNominas/GetHistorialNominas",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => this.datasource_tblNomina_beforeSend(request),
      version: 4,
    }),
  });

  datasource_tblNomina_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    const { fechaSel } = this.state;
    const { empresa } = this.props;

    request.params = {
      idEmpresaPolarier: empresa.idEmpresaPolarier,
      fechaDesde: formatDate_noTime_parameter(fechaSel.startDate),
      fechaHasta: formatDate_noTime_parameter(fechaSel.endDate),
    };
  };

  // #endregion

  context_gestionNominas = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "MyPolarier/RRHH/GestionNominas/",
    entities: {
      ImportarMesCompleto_NominasGestoria: {},
    },
    errorHandler: (error) => errorHandler(error, null),
    beforeSend: (request) => this.context_gestionNominas_beforeSend(request),
  });

  context_gestionNominas_beforeSend = (request) => {
    const { fechaSel } = this.state;

    request.headers = { ...authHeader() };
    request.timeout = 90000;

    request.params.fechaDesde = formatDate_noTime_parameter(fechaSel.startDate);
    request.params.fechaHasta = formatDate_noTime_parameter(fechaSel.endDate);
  };

  context_historicoAsientoNomina = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL,
    entities: {
      tblHistoricoAsientoNomina: {
        key: ["idAdmElementoPEP", "idAdmCentroCoste"],
        keyType: {
          idAdmElementoPEP: "Int32",
          idAdmCentroCoste: "Int32",
        },
      },
    },
    errorHandler: (error) => errorHandler(error),
    beforeSend: (request) =>
      this.context_historicoAsientoNomina_beforeSend(request),
  });

  context_historicoAsientoNomina_beforeSend = (request) => {
    const { fechaSel } = this.state;

    request.headers = { ...authHeader() };

    request.params.fechaDesde = formatDate_noTime_parameter(fechaSel.startDate);
    request.params.fechaHasta = formatDate_noTime_parameter(fechaSel.endDate);
  };

  load_tblHistoricoAsientoNomina = () =>
    new Promise((resolve) => {
      this.context_historicoAsientoNomina
        .invoke("tblHistoricoAsientoNomina", {}, "GET")
        .done((data) =>
          this.setState({ tblHistoricoAsientoNomina: data }, resolve)
        );
    });

  load_dataSource = () => {
    this.setState({ isLoading: true }, () => {
      this.load_tblHistoricoAsientoNomina();
      this.datasource_asientosNomina
        .reload()
        .done((dataSource) => {
          const dataSourceAgrupado = Object.groupBy(
            dataSource,
            ({ idCentroElem }) => idCentroElem
          );

          this.setState({ dataSource, dataSourceAgrupado }, () => {
            this.DataGrid.dxDataGrid.refresh(false);
            this.setState({ isLoading: false });
          });
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    });
  };

  render() {
    const {
      fechaSel,
      vista,
      progress,
      isVisible_LoadPanel_ProgressBar,
      tblHistoricoAsientoNomina,
      filtroEstadoNomina,
      dataSource,
      dataSourceAgrupado,
      isLoading,
    } = this.state;
    return (
      <>
        <PageTitle
          heading={getTrad("asientosNominas")}
          postHeadingEnd={
            <div
              className="d-flex flex-row he-100 align-items-center"
              style={this.headerGap}
            >
              <Button
                icon="refresh"
                text={getTrad("actualizar")}
                className="bg-white"
                disabled={isLoading}
                onClick={this.load_dataSource}
              />
              <Button
                text={getTrad("importarNominas")}
                className={"bg-white"}
                disabled={isLoading}
                onClick={this.onClick_importarNominas}
              />
              <Button
                icon="exportxlsx"
                text={getTrad("exportar")}
                className="bg-white"
                disabled={isLoading}
                onClick={this.dxButton_onClick_exportar}
              />
              <Switch
                width={200}
                height={36}
                className="no-disabled bg-white"
                switchedOnText={getTrad("vistaHistorialNominas")}
                switchedOffText={getTrad("vistaAsientosNominas")}
                value={vista}
                disabled={isLoading}
                onValueChanged={this.dxSwitch_vista_onValueChanged}
              />
              <FilterNominas
                value={filtroEstadoNomina}
                disabled={isLoading}
                onValueChanged={this.onValueChanged_FilterNominas}
              />
              <div className="font-weight-bold">Fecha:</div>
              <DateRangePicker
                month
                fechaSel={{ ...fechaSel }}
                align={"right"}
                disabled={isLoading}
                onDateSelected={this.dateRangePicker_onDateSelected}
              />
              {/* <SelectorEmpresa value={this.state.idEmpresaPolarier} onSelectionChanged={this.selectorEmpresa_onSelectionChanged}/> */}
            </div>
          }
        />
        <div className={"media-body"}>
          <div id="AsientosNominas" className="formContainer">
            <DataGrid_ContabilidadNominas
              ref={this.DataGrid_REF}
              dataSource={dataSource}
              tblHistoricoAsientoNomina={tblHistoricoAsientoNomina}
              fechaSel={fechaSel}
              isLoading={isLoading}
              dataSourceAgrupado={dataSourceAgrupado}
              filtroEstadoNomina={filtroEstadoNomina}
              vista={vista}
              load_dataSource={this.load_dataSource}
              load_tblHistoricoAsientoNomina={
                this.load_tblHistoricoAsientoNomina
              }
            />
          </div>
        </div>
        <LoadPanel_ProgressBar
          title={getTrad("importandoNominas")}
          progress={progress}
          visible={isVisible_LoadPanel_ProgressBar}
        />
      </>
    );
  }

  onClick_importarNominas = async () => {
    const { empresa } = this.props;

    let conflictoNominaContrato = false;

    await this.setStateAsync({
      progress: 0,
      isVisible_LoadPanel_ProgressBar: true,
    });

    let currentPage = 0;
    let totalPages = 1;

    while (currentPage < totalPages) {
      currentPage++;
      await this.context_gestionNominas
        .invoke("ImportarMesCompleto_NominasGestoria", {
          idEmpresaPolarier: empresa?.idEmpresaPolarier,
          pageNumber: currentPage,
        })
        .done((data) => {
          conflictoNominaContrato =
            data.conflictoNominaContrato || conflictoNominaContrato;

          totalPages = data.totalPages;

          if (totalPages > 0) {
            const progress = Math.ceil((currentPage / totalPages) * 100);

            if (progress === 100) {
              if (conflictoNominaContrato) {
                dxMensajePregunta(getTrad("aviso_I_nominasSinContrato"), [
                  [getTrad("aceptar"), () => {}, "danger"],
                ]);
              }

              notify({
                message: getTrad("importarNominasCorrecto"),
                type: "success",
                displayTime: 1500,
                closeOnClick: true,
              });

              this.load_dataSource();
            }

            this.setState({ progress });
          } else {
            notify({
              message: getTrad("sinNominasImportar"),
              type: "success",
              displayTime: 1500,
              closeOnClick: true,
            });

            this.setState({ progress: 100 });
          }
        })
        .catch(() => {
          notify({
            message: getTrad("errorImportarNominas"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
          currentPage = totalPages;

          this.setState({ progress: 100 });
        });
    }

    this.setState({ isVisible_LoadPanel_ProgressBar: false });
  };

  setStateAsync = (state) =>
    new Promise((resolve) => this.setState(state, resolve));

  dxButton_onClick_exportar = () => {
    this.DataGrid.dxDataGrid.exportToExcel();
  };

  dxSwitch_vista_onValueChanged = (e) => {
    this.setState({ vista: e.value });
  };

  onValueChanged_FilterNominas = (filtroEstadoNomina) =>
    this.setState({ filtroEstadoNomina });

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      fecha.endDate = new Date(
        endOfMonth(fecha.endDate).setHours(23, 59, 59, 999)
      );

      if (fecha !== this.state.fechaSel) {
        this.setState({ isLoading: true });
      }

      this.setState({ fechaSel: fecha });
    }
  };

  // selectorEmpresa_onSelectionChanged = (e) => {
  //     this.setState({ idEmpresaPolarier: e.selectedItem });
  // };
}

const mapStateToProps = (state) => ({
  empresa: state.Global.empresa,
});

export default connect(mapStateToProps)(AsientosNominas);
