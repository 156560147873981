import { Draggable } from "devextreme-react";
import "./Draggable.css";
import FotoPersona from "components/FotoPersona";
const colorHover = "rgba(237, 182, 55, 0.68)";

const { Component } = require("react");

// Crea un elemento de la lista de empleados que se puede arrastrar
class DraggableListItem extends Component {
    constructor(props) {
        super(props);
        this.svg = props.svg;
    }

    get layout() {
        return this.props.layout_REF;
    }

    render() {
        const { data, group, enableDrag } = this.props;
        const id = `lista_${data.idCuadrantePersonal}`;

        return (
            <Draggable
                clone={true}
                group={enableDrag ? group : undefined}
                data={data}
                onDragStart={enableDrag ? this.onItemDragStart : this.cancelDrag}
                onDragEnd={enableDrag ? this.onItemDragEnd : undefined}
                onDragMove={enableDrag ? this.onItemDragMove : undefined}
                className={enableDrag ? undefined : "draggable-disabled"}
            >
                <div
                    id={id}
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        background: "#fff",
                        padding: "5px 10px",
                        border: "1px solid rgba(151, 109, 14, 0.125)",
                        marginBottom: 5,
                        borderRadius: 20,
                    }}
                    onMouseEnter={() => this.layout.setTooltipData(null, data, id)}
                    onMouseLeave={() => this.layout.resetTooltipData()}
                >
                    {/* Imagen de la persona */}
                    <FotoPersona
                        idPersona={data.idPersona}
                        style={{
                            alignSelf: "center",
                            width: 30,
                            height: 30,
                            marginRight: 10,
                            borderRadius: 90,
                            objectFit: "cover",
                        }}
                    />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        <span
                            style={{
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: 700,
                                color: data.idPosicionNAreaLavanderiaNLavanderia !== null && "#30b1ff",
                            }}
                        >
                            {data.nombre}
                        </span>
                        <span
                            style={{
                                fontSize: 13,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontWeight: 300,
                                color: "#8c8c8c",
                            }}
                        >
                            {data.categoria}{" "}
                            {data.posicion ? (
                                <span style={{ fontWeight: 300, color: "#8c8c8c" }}>
                                    {" "}
                                    -{" "}
                                    {data.posicion.idMaquinaNavigation
                                        ? data.posicion.idMaquinaNavigation.etiqueta
                                        : data.posicion.idAreaLavanderiaNavigation.denominacion}
                                </span>
                            ) : null}
                        </span>
                    </div>
                </div>
            </Draggable>
        );
    }

    cancelDrag = (e) => {
        e.cancel = true;
    };

    // Aplica estilo al elemento que arrastras
    onItemDragStart = ({ component }) => {
        component.element()[0].style.opacity = 0.3;
        component.element()[0].firstChild.style.border = "1px dashed black";
    };

    // Reinicia el estilo de los elementos afectados previamente
    onItemDragEnd = ({ component, toComponent }) => {
        component.element()[0].style.opacity = 1;
        component.element()[0].firstChild.style.border = "1px solid rgba(151, 109, 14, 0.125)";
        toComponent.element()[0].firstChild.style.opacity = 1;
        toComponent.element()[0].style.outline = "0px";
    };

    // Aplica cambios de estilos a los elementos de sobre los que pasas el elemento arrastable
    onItemDragMove = ({ component, toComponent, ...e }) => {
        const componentElement = component.element()[0];
        const toComponentElement = toComponent.element()[0];
        if (!toComponentElement.firstChild.id.includes("lista")) {
            if (this.componentBehind != toComponentElement && componentElement != toComponentElement) {
                this.resetDragStyle();
                this.componentBehind = toComponentElement;
                this.setDragStyle();
            }
        } else if (this.componentBehind != toComponentElement && toComponentElement == componentElement) {
            this.resetDragStyle();
            this.componentBehind = toComponentElement;
        }
    };

    setDragStyle = () => {
        if (this.componentBehind.firstChild?.src === this.svg.persona) {
            this.componentBehind.firstChild.src = this.svg.personaHover;
        } else {
            this.componentBehind.firstChild.style.opacity = 0.25;
        }
        this.componentBehind.style.outline = "dashed 3px " + colorHover;
    };

    resetDragStyle = () => {
        if (this.componentBehind) {
            if (this.componentBehind.firstChild?.src === this.svg.personaHover) {
                this.componentBehind.firstChild.src = this.svg.persona;
            } else {
                this.componentBehind.firstChild.style.opacity = 1;
            }
            this.componentBehind.style.outline = "0px";
        }
    };
}

export default DraggableListItem;
