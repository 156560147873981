import { connectionConstants } from "../../../../constants";

import {
  authHeader,
  errorHandler,
  tooltipControl_creacion,
} from "../../../../helpers";

import { allIcons as svg_iconos } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import { locale } from "devextreme/localization";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

var COMPONENT;

var deferred;
var isRedirect;

var datasource_resumen_Incidencias;
export function cargaDatos(_component, _lavanderia, isPrimeraCarga) {
  COMPONENT = _component;
  isRedirect = false;

  deferred = $.Deferred();

  datasource_resumen_Incidencias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spResumen_Incidencias",
      key: "idTipoIncidencia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idioma = locale();
      },
      version: 4,
    }),
    sort: "denominacion",
  });

  datasource_resumen_Incidencias.load().done(function () {
    deferred.resolve();
  });

  return deferred.promise();
}

export function cardClick(idTipoIncidencia) {
  if (!isRedirect) {
    isRedirect = true;

    var params = {};

    if (idTipoIncidencia) {
      params = { idTipoIncidencia: idTipoIncidencia };
    }

    COMPONENT.props.hideSelector(params);
  }
}

export function getMinSize() {
  var minSize = {
    height: 165,
    width: 350,
  };
  return minSize;
}

export function getContent_header(itemData) {
  return $("<span />").text(itemData.denominacion);
}

export function getContent_front(idLavanderia) {
  var tiposIncidencia = $.grep(
    datasource_resumen_Incidencias.items(),
    function (tipoIncidencia) {
      return tipoIncidencia.idLavanderia === idLavanderia;
    },
  );

  function content_TipoIncidencia(tipoIncidencia) {
    var svgContainer = $("<div/>")
      .addClass("container_spanCentrado")
      .css("cursor", "pointer")
      .on("click", function () {
        cardClick(tipoIncidencia.idTipoIncidencia);
      })
      .append(svg_iconos[tipoIncidencia.icon].clone())
      .append($("<span/>").text(tipoIncidencia.numIncidencias));

    tooltipControl_creacion(svgContainer, tipoIncidencia.denominacion);

    return $("<div/>").css("width", "33%").append(svgContainer);
  }
  var result = $("<div />").addClass("container_iconos");

  $.each(tiposIncidencia, function (index, tipoIncidencia) {
    result.append(
      content_TipoIncidencia(tipoIncidencia).css(
        "border-right",
        (index + 1) % 3 ? "1px solid gainsboro" : "none",
      ),
    );
  });

  return result;
}
