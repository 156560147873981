import { Component } from "react";
import { Box, Button, List, Popup } from "devextreme-react";
import { Item as ItemBox } from "devextreme-react/box";

class PopupAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedItemKeys: [],
      tblAreaLavanderia: [],
    };
  }

  componentDidMount() {}

  toggle = () => {
    if (!this.state.visible) {
      this.props.dataSource_tblAreaLavanderiaNLavanderia
        .load()
        .then((tblAreaLavanderiaNLavanderia) => {
          this.props.dataSource_tblAreaLavanderia
            .load()
            .then((tblAreaLavanderia) => {
              const data = tblAreaLavanderia.filter((x) => {
                const i = tblAreaLavanderiaNLavanderia.find(
                  (y) => x.idAreaLavanderia === y.idAreaLavanderia,
                );
                return i === undefined;
              });
              // TODO HACER FILTRO DE AREAS NO SELECCIONADAS
              this.setState({ visible: true, tblAreaLavanderia: data });
            });
        });
    } else {
      this.setState({ visible: false });
    }
  };

  agregarAreas = () => {
    const data = [];
    this.state.selectedItemKeys.map((x) => {
      data.push({ idLavanderia: this.props.idLavanderia, idAreaLavanderia: x });
    });
    this.props.dataSource_tblAreaLavanderiaNLavanderia
      .store()
      .insert({ lista: data })
      .done((data) => {
        this.setState({ visible: false, selectedItemKeys: [] });
        this.props.dataSource_tblAreaLavanderiaNLavanderia.load();
        this.props.dataSource_tblAreaLavanderia.load().then((data) => {
          this.setState({ tblAreaLavanderia: data });
        });
      });
  };

  render() {
    return (
      <Popup
        visible={this.state.visible}
        onHiding={() => this.setState({ visible: false })}
        dragEnabled={false}
        width={"fit-content"}
        height={"fit-content"}
        title="Añadir áreas"
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <List
            showSelectionControls={true}
            style={{ width: 300 }}
            dataSource={this.state.tblAreaLavanderia}
            displayExpr={"denominacion"}
            keyExpr={"idAreaLavanderia"}
            selectionMode="multiple"
            selectedItemKeys={this.state.selectedItemKeys}
            onItemClick={({ itemData }) => {
              if (
                !this.state.selectedItemKeys.includes(itemData.idAreaLavanderia)
              ) {
                this.setState({
                  selectedItemKeys: [
                    ...this.state.selectedItemKeys,
                    itemData.idAreaLavanderia,
                  ],
                });
              } else {
                this.setState({
                  selectedItemKeys: this.state.selectedItemKeys.filter(
                    (x) => x !== itemData.idAreaLavanderia,
                  ),
                });
              }
            }}
          />
          <Box style={{ marginTop: 15 }}>
            <ItemBox ratio={1}>
              <Button
                type="danger"
                text="Cancelar"
                style={{ margin: 5 }}
                onClick={() => {
                  this.setState({ visible: false });
                }}
              />
            </ItemBox>
            <ItemBox ratio={1}>
              <Button
                disabled={this.state.selectedItemKeys.length === 0}
                type="success"
                text="Añadir"
                style={{ margin: 5 }}
                onClick={() => {
                  this.agregarAreas();
                }}
              />
            </ItemBox>
          </Box>
        </div>
      </Popup>
    );
  }
}

export default PopupAreas;
