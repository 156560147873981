import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions, globalActions } from "../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";

//Actions
import { userActions } from "../../../actions";

class Usuarios extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="Usuarios" className={"formContainer"}>
            <div id="dxContainer"></div>
            <div id="dxPopup_importarPermisos"></div>
            <div id="dxPopover_cargo"></div>
            <div id="dxLoadPanel_formularios"></div>
            <div id="dxPopup_asociarPersona"></div>
            <div id="dxPopup_asociarPersona_datosPersona"></div>
            <div id="dxPopover_logUsuario"></div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { user, idioma } = this.props;
    if (idioma.idIdioma !== prevProps.idioma.idIdioma) {
      this.loadPanel_show();
      cargaJs(this, user, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { user, idioma } = this.props;
    cargaJs(this, user, idioma).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  loginChangeUser: (idUsuarioSel, idUsuarioOriginal) =>
    dispatch(userActions.loginChangeUser(idUsuarioSel, idUsuarioOriginal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);
