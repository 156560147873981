export const noNotification = {
  v: "5.4.2",
  fr: 15,
  ip: 0,
  op: 49,
  w: 216,
  h: 216,
  nm: "No-Notification",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "star-2 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 0,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 11,
                  s: [90],
                  e: [0],
                },
                { t: 24 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [111, 161, 0], ix: 2 },
            a: { a: 0, k: [28.5, 28.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-11.598, 0],
                        [0, 11.598],
                        [11.598, 0],
                        [0, -11.598],
                      ],
                      o: [
                        [-11.598, 0],
                        [0, 11.598],
                        [11.598, 0],
                        [0, -11.598],
                      ],
                      v: [
                        [21, 0],
                        [0, -21],
                        [-21, 0],
                        [0, 21],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [28.5, 28.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "star-1 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 14,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 25,
                  s: [90],
                  e: [0],
                },
                { t: 38 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [408, 305, 0], ix: 2 },
            a: { a: 0, k: [22.5, 22.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-8.284, 0],
                        [0, 8.284],
                        [8.284, 0],
                        [0, -8.284],
                      ],
                      o: [
                        [-8.284, 0],
                        [0, 8.284],
                        [8.284, 0],
                        [0, -8.284],
                      ],
                      v: [
                        [15, 0],
                        [0, -15],
                        [-15, 0],
                        [0, 15],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [22.5, 22.5], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "buble-2 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 19,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 30,
                  s: [90],
                  e: [0],
                },
                { t: 43 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [151.5, 151, 0], ix: 2 },
            a: { a: 0, k: [25.5, 70, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.59],
                        [3.59, 0],
                        [0, 3.59],
                        [-3.59, 0],
                      ],
                      o: [
                        [0, 3.59],
                        [-3.59, 0],
                        [0, -3.59],
                        [3.59, 0],
                      ],
                      v: [
                        [6.5, 0],
                        [0, 6.5],
                        [-6.5, 0],
                        [0, -6.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [14, 126], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -2.485],
                        [2.485, 0],
                        [0, 2.485],
                        [-2.485, 0],
                      ],
                      o: [
                        [0, 2.485],
                        [-2.485, 0],
                        [0, -2.485],
                        [2.485, 0],
                      ],
                      v: [
                        [4.5, 0],
                        [0, 4.5],
                        [-4.5, 0],
                        [0, -4.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [39, 12], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "buble1 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 9,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 20,
                  s: [90],
                  e: [0],
                },
                { t: 33 },
              ],
              ix: 11,
            },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ["0p833_0p833_0p167_0p167"],
                  t: 10,
                  s: [0],
                  e: [-10],
                },
                { t: 43 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [236.5, 286.5, 0], ix: 2 },
            a: { a: 0, k: [172.5, 78.5, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.59],
                        [3.59, 0],
                        [0, 3.59],
                        [-3.59, 0],
                      ],
                      o: [
                        [0, 3.59],
                        [-3.59, 0],
                        [0, -3.59],
                        [3.59, 0],
                      ],
                      v: [
                        [6.5, 0],
                        [0, 6.5],
                        [-6.5, 0],
                        [0, -6.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [331, 14], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -5.247],
                        [5.247, 0],
                        [0, 5.247],
                        [-5.247, 0],
                      ],
                      o: [
                        [0, 5.247],
                        [-5.247, 0],
                        [0, -5.247],
                        [5.247, 0],
                      ],
                      v: [
                        [9.5, 0],
                        [0, 9.5],
                        [-9.5, 0],
                        [0, -9.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [17, 23], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -3.59],
                        [3.59, 0],
                        [0, 3.59],
                        [-3.59, 0],
                      ],
                      o: [
                        [0, 3.59],
                        [-3.59, 0],
                        [0, -3.59],
                        [3.59, 0],
                      ],
                      v: [
                        [6.5, 0],
                        [0, 6.5],
                        [-6.5, 0],
                        [0, -6.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 3, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [33, 143], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "bell-sleep3 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 25,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 36,
                  s: [90],
                  e: [0],
                },
                { t: 49 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [366.718, 146.156, 0], ix: 2 },
            a: { a: 0, k: [7.796, 9.499, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-7.268, 5.147],
                        [1.544, -5.042],
                        [-6.01, -5.822],
                        [-5.657, -9.249],
                        [7.546, -7.887],
                        [7.268, -5.19],
                        [-1.509, 4.97],
                        [6.425, 5.789],
                        [6.069, 9.249],
                        [-7.546, 7.844],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [7.796, 9.499], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "bell-sleep2 Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 11,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 22,
                  s: [90],
                  e: [0],
                },
                { t: 35 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [350.624, 155.339, 0], ix: 2 },
            a: { a: 0, k: [6.382, 7.665, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-4.988, 5.257],
                        [0.205, -4.187],
                        [-5.836, -3.535],
                        [-6.132, -6.277],
                        [4.427, -7.415],
                        [4.66, -5.259],
                        [-0.512, 4.158],
                        [5.832, 3.473],
                        [6.132, 6.24],
                        [-4.757, 7.415],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [6.382, 7.666], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "bell-sleep Outlines",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 1,
                  s: [0],
                  e: [90],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 12,
                  s: [90],
                  e: [0],
                },
                { t: 25 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [337.52, 164.172, 0], ix: 2 },
            a: { a: 0, k: [4.079, 5.062, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-3.829, 3.293],
                        [0.49, -2.883],
                        [-3.759, -2.883],
                        [-3.759, -4.811],
                        [3.668, -4.811],
                        [3.668, -3.293],
                        [-0.635, 2.866],
                        [3.829, 2.866],
                        [3.829, 4.811],
                        [-3.829, 4.811],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [4.079, 5.062], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "bell-dialog Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 0,
                  s: [349.434, 160.643, 0],
                  e: [349.434, 150.643, 0],
                  to: [0, -1.66666662693024, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 25,
                  s: [349.434, 150.643, 0],
                  e: [349.434, 160.643, 0],
                  to: [0, 0, 0],
                  ti: [0, -1.66666662693024, 0],
                },
                { t: 49 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [63.387, 66.228, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [26.723, 0],
                        [0, -26.723],
                        [-11.796, -8.824],
                        [0, 0],
                        [-5.529, -2.765],
                        [-7.054, 3.867],
                        [-5.03, 0],
                        [0, 26.723],
                      ],
                      o: [
                        [-26.724, 0],
                        [0, 15.864],
                        [0, 0],
                        [0, 0],
                        [3.072, 1.536],
                        [4.56, 1.424],
                        [26.723, 0],
                        [0, -26.723],
                      ],
                      v: [
                        [0.001, -51.228],
                        [-48.387, -2.842],
                        [-28.956, 35.928],
                        [-34.562, 42.78],
                        [-33.18, 49.692],
                        [-14.44, 43.352],
                        [0.001, 45.544],
                        [48.387, -2.842],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [63.386, 66.228], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "bell-eyes Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 0,
                  s: [251.972, 224.539, 0],
                  e: [251.972, 234.539, 0],
                  to: [0, 1.66666662693024, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 25,
                  s: [251.972, 234.539, 0],
                  e: [251.972, 224.539, 0],
                  to: [0, 0, 0],
                  ti: [0, 1.66666662693024, 0],
                },
                { t: 49 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [64.047, 16.461, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.435, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [6.434, 0],
                        [0, 0],
                      ],
                      v: [
                        [-12.547, -1.461],
                        [-0.322, 1.461],
                        [12.547, -1.461],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [100.547, 16.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-6.434, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [6.435, 0],
                        [0, 0],
                      ],
                      v: [
                        [-12.547, -1.461],
                        [-0.322, 1.461],
                        [12.547, -1.461],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [27.547, 16.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "bell-mouth Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 0,
                  s: [255.883, 264.107, 0],
                  e: [255.883, 262.107, 0],
                  to: [0, -0.33333334326744, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 25,
                  s: [255.883, 262.107, 0],
                  e: [255.883, 264.107, 0],
                  to: [0, 0, 0],
                  ti: [0, -0.33333334326744, 0],
                },
                { t: 49 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [41.958, 17.374, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-13.825, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [13.824, 0],
                        [0, 0],
                      ],
                      v: [
                        [-26.958, -2.374],
                        [-0.691, 2.374],
                        [26.958, -2.374],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [41.958, 17.374], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "bell-home Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: {
              a: 1,
              k: [
                {
                  i: { x: [0], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0_1_0p333_0"],
                  t: 0,
                  s: [0],
                  e: [3],
                },
                {
                  i: { x: [0.667], y: [1] },
                  o: { x: [0.333], y: [0] },
                  n: ["0p667_1_0p333_0"],
                  t: 25,
                  s: [3],
                  e: [0],
                },
                { t: 49 },
              ],
              ix: 10,
            },
            p: { a: 0, k: [255.135, 135.059, 0], ix: 2 },
            a: { a: 0, k: [129.689, 37.644, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.437, 26.631],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.435, 0.342],
                      ],
                      o: [
                        [0, 0],
                        [-10.099, 41.78],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.743, -2.944],
                      ],
                      v: [
                        [86.96, -22.811],
                        [-86.96, -22.811],
                        [-116.128, 22.811],
                        [0, 22.811],
                        [116.127, 22.811],
                        [115.454, 22.297],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [131.128, 224.443], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.783, 32.946],
                        [0, 2.475],
                        [0, 0],
                        [40.768, 0.39],
                        [0, 0.002],
                        [0.023, 0],
                        [0.023, 0],
                        [0, -0.001],
                        [3.897, -39.877],
                        [0, -1.615],
                        [5.84, -28.364],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.616, 0.429],
                      ],
                      o: [
                        [-5.84, -28.364],
                        [0, -1.615],
                        [-3.898, -39.877],
                        [0, -0.001],
                        [-0.023, 0],
                        [-0.024, 0],
                        [0, 0.002],
                        [-40.768, 0.39],
                        [0, 0],
                        [0, 2.475],
                        [-9.677, 47.004],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.393, -3.069],
                      ],
                      v: [
                        [85.714, 51.144],
                        [78.8, -22.818],
                        [78.416, -30.553],
                        [0.07, -102.291],
                        [0.07, -102.295],
                        [0.001, -102.293],
                        [-0.07, -102.295],
                        [-0.07, -102.291],
                        [-78.415, -30.553],
                        [-78.8, -22.818],
                        [-85.713, 51.144],
                        [-116.127, 102.295],
                        [0.001, 102.295],
                        [116.127, 102.295],
                        [115.169, 101.661],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [131.128, 144.959], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 3,
              cix: 2,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -15.652],
                        [15.652, 0],
                        [0, 15.652],
                        [-15.652, 0],
                      ],
                      o: [
                        [0, 15.652],
                        [-15.652, 0],
                        [0, -15.652],
                        [15.652, 0],
                      ],
                      v: [
                        [28.34, -0.001],
                        [0, 28.34],
                        [-28.34, -0.001],
                        [0, -28.34],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [130.436, 43.341], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "bell Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0_1_0p333_0",
                  t: 0,
                  s: [257.265, 343.978, 0],
                  e: [221, 343.978, 0],
                  to: [-6.04419946670532, 0, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.333, y: 0 },
                  n: "0p667_1_0p333_0",
                  t: 25,
                  s: [221, 343.978, 0],
                  e: [257.265, 343.978, 0],
                  to: [0, 0, 0],
                  ti: [-6.04419946670532, 0, 0],
                },
                { t: 49 },
              ],
              ix: 2,
            },
            a: { a: 0, k: [48.87, 48.87, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -18.707],
                        [18.706, 0],
                        [0, 18.706],
                        [-18.706, 0],
                      ],
                      o: [
                        [0, 18.706],
                        [-18.706, 0],
                        [0, -18.707],
                        [18.706, 0],
                      ],
                      v: [
                        [33.87, 0],
                        [-0.001, 33.87],
                        [-33.87, 0],
                        [-0.001, -33.87],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  ml2: { a: 0, k: 10, ix: 8 },
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [48.871, 48.871], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 3,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "bcg Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [254.5, 255.5, 0], ix: 2 },
            a: { a: 0, k: [173.75, 173.75, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -95.821],
                        [95.821, 0],
                        [0, 95.821],
                        [-95.821, 0],
                      ],
                      o: [
                        [0, 95.821],
                        [-95.821, 0],
                        [0, -95.821],
                        [95.821, 0],
                      ],
                      v: [
                        [173.5, 0],
                        [0, 173.5],
                        [-173.5, 0],
                        [0, -173.5],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9764705882352941, 0.9098039215686274,
                      0.7490196078431373, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [173.75, 173.75], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 52,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "No-Notification 2",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [108, 108, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [44, 44, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 0,
      op: 50,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
