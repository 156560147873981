import React, { Fragment } from "react";
import { connect } from "react-redux";

import { formatDateTime, getTrad } from "../helpers";

class LastUpdateClock extends React.Component {
  render() {
    let { fecha } = this.props;
    return (
      <Fragment>
        <span className="font-size-md m-auto" style={this.spanDateTime_style}>
          {getTrad("ultimaConexion") + ": " + formatDateTime(fecha)}
        </span>
      </Fragment>
    );
  }
  spanDateTime_style = {
    color: "#817f80",
  };
}
export default connect(null)(LastUpdateClock);
