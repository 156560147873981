import { connectionConstants } from "../constants";
import { Cookies } from "react-cookie-banner";
import { cookies_options, authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const userService = {
  login,
  loginChangeUser,
  logout,
  refreshToken,
  validateEmailUsuario,
  validatePassword,
  recoveryPassword,
  checkRecoveryPassword_Token,
  changePassword,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  const cookies = new Cookies();

  return fetch(connectionConstants.WEB_API_CORE_URL + `login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      //Tokens
      user.token_Date = new Date();
      cookies.set("user", JSON.stringify(user), cookies_options);
      return user;
    })
    .then((user) => {
      //Primera carga
      let deferred = $.Deferred();
      $.when(dataSource_tblUsuario.load()).then(function () {
        let datosUser = dataSource_tblUsuario.items()[0];
        localStorage.setItem("user", JSON.stringify(datosUser));
        deferred.resolve(datosUser);
      });
      return deferred.promise();
    });
}

function loginChangeUser(idUsuarioSel, idUsuarioOriginal) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ idUsuarioSel, idUsuarioOriginal }),
  };

  const cookies = new Cookies();

  return fetch(
    connectionConstants.WEB_API_CORE_URL + `loginChangeUser`,
    requestOptions,
  )
    .then(handleResponse)
    .then((user) => {
      //Tokens
      user.token_Date = new Date();
      cookies.set("user", JSON.stringify(user), cookies_options);
      return user;
    })
    .then((user) => {
      //Primera carga
      let deferred = $.Deferred();
      $.when(dataSource_tblUsuario.load()).then(function () {
        let datosUser = dataSource_tblUsuario.items()[0];
        localStorage.setItem("user", JSON.stringify(datosUser));
        deferred.resolve(datosUser);
      });
      return deferred.promise();
    });
}

function logout() {
  //Remove user from coockies to log user out
  const cookies = new Cookies();
  cookies.remove("user", cookies_options);
  localStorage.clear();
}

function refreshToken() {
  const cookies = new Cookies();
  const currentUser = cookies.get("user");

  if (currentUser) {
    var refreshToken = currentUser.refreshToken;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ refreshToken }),
    };

    return fetch(
      connectionConstants.WEB_API_CORE_URL + `refreshToken`,
      requestOptions,
    )
      .then(handleResponse)
      .then((user) => {
        currentUser.token = user.token;
        currentUser.token_expireDate = user.token_expireDate;
        currentUser.token_Date = new Date();

        cookies.set("user", JSON.stringify(currentUser), cookies_options);

        return currentUser;
      });
  }
}

function validateEmailUsuario(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return new Promise((resolve, reject) => {
    fetch(
      connectionConstants.WEB_API_CORE_URL + `validateEmailUsuario`,
      requestOptions,
    )
      .then(async (response) => {
        let userCorp = await response.text(); // PolarierUser / SasUser
        resolve({ isValid: response.ok, message: "", userCorp });
      })
      .catch(function (error) {
        reject({
          isValid: false,
          message: "No se puede establecer conexión con el servidor.",
        });
      });
  });
}

function validatePassword(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ Email: email, Password: password }),
  };

  return new Promise((resolve, reject) => {
    fetch(
      connectionConstants.WEB_API_CORE_URL + `validatePassword`,
      requestOptions,
    )
      .then(handleResponse)
      .then((response) => {
        resolve(response);
      });
  });
}

function recoveryPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(
    connectionConstants.WEB_API_CORE_URL + `recoveryPassword`,
    requestOptions,
  ).then(handleResponse);
}

function checkRecoveryPassword_Token(token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token }),
  };

  return new Promise((resolve, reject) => {
    fetch(
      connectionConstants.WEB_API_CORE_URL + `checkRecoveryPassword_Token`,
      requestOptions,
    )
      .then(handleResponse)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        reject();
      });
  });
}

function changePassword(token, newPassword) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token, newPassword }),
  };

  return new Promise((resolve, reject) => {
    fetch(
      connectionConstants.WEB_API_CORE_URL + `changePassword`,
      requestOptions,
    )
      .then(handleResponse)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject();
      });
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(response.status);
    }

    return data;
  });
}

const dataSource_tblUsuario = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + `GestionInterna/Usuario/GetUsuarioActual`,
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
  map: function (data) {
    return {
        idUsuario: data.idUsuario,
        nombre: data.nombre,
        idCargo: data.idCargo,
        idioma: data.idIdioma,
        idPais: data.idLocalizacionNavigation.idPais,
        idLavanderiaInicio: data.idLavanderiaInicio,
        refactura: data.refactura,
        importaEntidades: data.importaEntidades,
        enableFullScreen: data.enableFullScreen,
        enableDatosRRHH: data.enableDatosRRHH,
        isDepartamentoControl: data.isDepartamentoControl,
        idAplicacionInicial: data.idAplicacionInicial,
        tblFormularioInicio: !data.idFormularioInicioNavigation
          ? null
          : {
              idFormulario: data.idFormularioInicioNavigation.idFormulario,
              tblAplicacion: data.idFormularioInicioNavigation.tblAplicacion,
              to:
                "/" +
                (data.idFormularioInicioNavigation.informe === true
                  ? "Informes"
                  : data.idFormularioInicioNavigation.idApartadoNavigation.apartado) +
                "/" +
                data.idFormularioInicioNavigation.formulario,
            },
        visiblePages: $.map(data.tblFormularioNUsuario, function (frm, i) {
          return {
            idFormulario: frm.idFormulario,
            idApartado: frm.idFormularioNavigation.idApartado,
            formulario: frm.idFormularioNavigation.formulario,
            denominacion: frm.idFormularioNavigation.denominacion,
            tblTraduccion: frm.idFormularioNavigation.idTraduccionNavigation,
            isEscritura: frm.isEscritura,
            isBorrado: frm.isBorrado,
          };
        }),
        idPersona: data.idPersona,
        idCategoriaInterna: data.tblPersona?.idCategoriaInterna ?? null,
        tblTipoTrabajoNUsuario: data.tblTipoTrabajoNUsuario,
        tblEmpresaPolarierNUsuario: data.tblEmpresaPolarierNUsuario,
    }
  },
});
