import React, { Fragment } from "react";
import { connect } from "react-redux";

import css from "./css.scss";

//Componentes devExtreme
import { DataGrid } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  Paging,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

//Componentes
import { formatNumber, getTrad } from "../../../../helpers";
import StarRatings from "react-star-ratings";

//Odata

class GridPreguntasPuntuacion extends React.Component {
  constructor(props) {
    super(props);

    this.dxDataGrid_REF = React.createRef();
  }

  render() {
    const { dataSource, dataShown } = this.props;
    return (
      <Fragment>
        <DataGrid
          ref={this.dxDataGrid_REF}
          dataSource={dataSource}
          className="he-100"
          remoteOperations={false}
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          onRowClick={this.seleccionaPregunta}
          noDataText={getTrad("sinDatos")}
          keyExpr="idPregunta"
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            selectTextOnEditStart={true}
          />
          <Paging enabled={false} />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          {dataShown === "preguntas" && <Selection mode={"single"} />}
          <KeyboardNavigation
            enterKeyAction="moveFocus"
            enterKeyDirection="row"
            editOnKeyPress={true}
          />
          <Column
            visible={dataShown === "preguntas"}
            dataField="pregunta"
            caption={getTrad("pregunta")}
            dataType="string"
            allowHeaderFiltering={true}
            allowSearch={true}
            minWidth={225}
            allowEditing={false}
            sortIndex={1}
          />
          <Column
            visible={dataShown === "preguntas"}
            dataField="valorMedio"
            caption={getTrad("valorMedio")}
            alignment="center"
            dataType="number"
            allowHeaderFiltering={false}
            allowSearch={false}
            width={100}
            allowEditing={false}
            sortIndex={1}
            calculateCellValue={this.dx_column_redondeaValorMedio}
          />
          <Column
            visible={dataShown === "preguntas"}
            dataField="valorMedio"
            caption={getTrad("rating")}
            defaultSelectedFilterOperation=">="
            alignment="center"
            allowHeaderFiltering={false}
            allowSearch={false}
            width={170}
            allowEditing={false}
            cellRender={this.render_starRating}
          />
          <Column
            visible={dataShown === "respuestas"}
            dataField="nombre"
            caption={getTrad("usuario")}
            dataType="string"
            allowHeaderFiltering={true}
            allowSearch={true}
            minWidth={225}
            allowEditing={false}
            sortIndex={1}
          />
          <Column
            visible={dataShown === "respuestas"}
            dataField="valor"
            caption={getTrad("valor")}
            alignment="center"
            dataType="number"
            allowHeaderFiltering={false}
            allowSearch={false}
            width={100}
            allowEditing={false}
            sortIndex={1}
          />
          <Column
            visible={dataShown === "respuestas"}
            dataField="valor"
            caption={getTrad("rating")}
            defaultSelectedFilterOperation=">="
            alignment="center"
            allowHeaderFiltering={false}
            allowSearch={false}
            width={170}
            allowEditing={false}
            cellRender={this.render_starRating}
          />
          <Summary>
            <TotalItem
              column={"pregunta"}
              displayFormat={getTrad("total").toUpperCase()}
            />
            <TotalItem
              column={"nombre"}
              displayFormat={getTrad("total").toUpperCase()}
            />
            <TotalItem
              column={dataShown === "respuestas" ? "valor" : "valorMedio"}
              summaryType="avg"
              displayFormat={"{0}"}
              customizeText={this.customizeText_decimal}
            />
          </Summary>
        </DataGrid>
      </Fragment>
    );
  }

  render_starRating = (e) => {
    const maxRango = e.row.data.maxRango;
    return (
      <div className="container_starRating">
        <StarRatings
          rating={e.value}
          starRatedColor={"var(--primary)"}
          starEmptyColor={"#F3EFEF"}
          numberOfStars={maxRango}
          name={getTrad("rating")}
          starDimension="22px"
          starSpacing="2px"
        />
      </div>
    );
  };

  seleccionaPregunta = (e) => {
    const { onValueChange, dataShown } = this.props;

    if (onValueChange && dataShown === "preguntas")
      onValueChange({ idPreguntaSel: e.key });
  };

  dx_column_redondeaValorMedio = (e) => {
    if (e.valorMedio) return e.valorMedio.toFixed(2);
  };

  get dxDataGrid() {
    return this.dxDataGrid_REF.current.instance;
  }

  customizeText_decimal = (rowData) => {
    return formatNumber(rowData.value, 2, "decimal");
  };

  componentDidUpdate(prevProps) {
    const {
      idPreguntaSel,
      dataShown,
      idEncuestaSel,
      dataSource,
      idCompañiaSel,
      idLavanderiaSel,
    } = this.props;

    if (
      (prevProps.idPreguntaSel !== idPreguntaSel &&
        dataShown === "respuestas") || //Si cambia la pregunta borra la selección.
      dataSource !== prevProps.dataSource ||
      prevProps.idCompañiaSel !== idCompañiaSel ||
      prevProps.idLavanderiaSel !== idLavanderiaSel
    ) {
      this.dxDataGrid.deselectAll();
    }

    if (
      (prevProps.idEncuestaSel !== idEncuestaSel &&
        dataShown === "preguntas") ||
      prevProps.idCompañiaSel !== idCompañiaSel ||
      prevProps.idLavanderiaSel !== idLavanderiaSel
    ) {
      //Si cambia la pregunta borra la selección.
      this.dxDataGrid.deselectAll();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(GridPreguntasPuntuacion);
