import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  errorHandler,
  authHeader,
  getTrad,
  getNombreFormulario,
  password_Pattern,
} from "../../../helpers";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { PatternRule, CustomRule } from "devextreme-react/validator";

//Actions
import { loadPanelActions, userActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import Form, { ButtonItem, Item } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { userService } from "../../../services";

//Css
import "./Css.scss";

class MiCuenta extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datosUser_formData: null,
      passwordStyle_0: "passwordStyle",
      passwordStyle_1: "passwordStyle",
      passwordStyle_2: "passwordStyle",
    };

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //EVENTOS
    this.disablePaste = this.disablePaste.bind(this);
    this.disableCopy = this.disableCopy.bind(this);
    this.passwordComparison = this.passwordComparison.bind(this);

    //Referencias
    this.form_userData_REF = React.createRef();

    //Variables globales
    this.data = { isGuardando: false };
  }

  //DATASOURCES
  dataSource_tblUsuario = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblUsuario",
      key: "idUsuario",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "PATCH") request.params.isDelta = true;
      },
      version: 4,
    }),
    select: ["nombre", "usuario"],
  });

  change_username(user) {
    this.props.change_username(user);
  }

  get form_userData() {
    return this.form_userData_REF.current.instance;
  }

  //#region Guardado datos
  onClick_btnGuardarForm(e, _this) {
    _this.data.isGuardando = true;
    let validation = this.form_userData.validate();
    if (validation.isValid) {
      let idUsuario = _this.props.user.idUsuario,
        formData = _this.state.datosUser_formData;

      function _saveData() {
        _this.dataSource_tblUsuario
          .store()
          .update(idUsuario, _this.formatearDatos_formulario(formData))
          .done(function (result) {
            _this.loadPanel_hide();
            result.password = null;
            result.newPassword = null;
            result.confirmPassword = null;
            _this.data.isGuardando = false;

            _this.change_username(result);

            _this.setState({ datosUser_formData: result });
            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
      }

      this.loadPanel_show(true);
      if (formData.password !== null && formData.password !== "") {
        // UPDATE
        userService
          .validatePassword(formData.usuario, formData.password)
          .then(function (resultValid) {
            if (resultValid !== -1) {
              _saveData();
            } else {
              _this.data.isGuardando = false;
              _this.loadPanel_hide();
              notify({
                message: getTrad("contraseñaIncorrecta"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
              });
            }
          });
      } else _saveData();
    } else _this.data.isGuardando = false;
  }

  formatearDatos_formulario(formData) {
    formData.password = formData.newPassword;
    delete formData.newPassword;

    if (formData.password === null || formData.password === "")
      delete formData.password;
    delete formData.confirmPassword;
    return formData;
  }
  //#endregion

  //#region onClick buttons
  onClick_passBtn(_this) {
    _this.setState({
      passwordStyle_0:
        _this.state.passwordStyle_0 === "textStyle"
          ? "passwordStyle"
          : "textStyle",
    });

    this.form_userData.validate();
  }

  onClick_newPassBtn(_this) {
    _this.setState({
      passwordStyle_1:
        _this.state.passwordStyle_1 === "textStyle"
          ? "passwordStyle"
          : "textStyle",
    });

    this.form_userData.validate();
  }

  onClick_confirmPassBtn(_this) {
    _this.setState({
      passwordStyle_2:
        _this.state.passwordStyle_2 === "textStyle"
          ? "passwordStyle"
          : "textStyle",
    });

    this.form_userData.validate();
  }
  //#endregion

  password_requiredRule(e, _this) {
    let newPassword =
      _this.form_userData.option("formData.newPassword") === ""
        ? null
        : _this.form_userData.option("formData.newPassword");

    return (
      (newPassword !== null && e.value !== "" && e.value !== null) || //Nueva pass tiene datos y pass también
      newPassword === null
    );
  }

  newPassword_requiredRule(e, _this) {
    let newPassword = e.value === "" ? null : e.value,
      password =
        _this.form_userData.option("formData.password") === ""
          ? null
          : _this.form_userData.option("formData.password");

    return (
      (this.data.isGuardando &&
        ((newPassword !== null && password !== null) || password === null)) ||
      !this.data.isGuardando
    );
  }

  passwordComparison(e) {
    let confirmPassword = e.value === "" ? null : e.value,
      newPassword =
        this.form_userData.option("formData.newPassword") === ""
          ? null
          : this.form_userData.option("formData.newPassword");
    return newPassword === confirmPassword;
  }

  //#region buttonOptions & editorOptions
  disablePaste(e) {
    e.event.preventDefault();
  }

  disableCopy(e) {
    e.event.preventDefault();
  }

  setConfigLocal() {
    this.buttonOptions = {
      userData_guardar: {
        text: getTrad("guardar"),
        type: "success",
        onClick: (e) => this.onClick_btnGuardarForm(e, this),
      },
      confirmPasswordBtn: {
        stylingMode: "text",
        tabIndex: -1,
        icon:
          this.state.passwordStyle_2 === "textStyle"
            ? " icon_Eye_Blocked"
            : " icon_Eye",
        onClick: (e) => this.onClick_confirmPassBtn(this),
      },
      newPasswordBtn: {
        stylingMode: "text",
        tabIndex: -1,
        icon:
          this.state.passwordStyle_1 === "textStyle"
            ? " icon_Eye_Blocked"
            : " icon_Eye",
        onClick: (e) => this.onClick_newPassBtn(this),
      },
      passwordBtn: {
        stylingMode: "text",
        tabIndex: -1,
        icon:
          this.state.passwordStyle_0 === "textStyle"
            ? " icon_Eye_Blocked"
            : " icon_Eye",
        onClick: (e) => this.onClick_passBtn(this),
      },
    };

    this.editorOptions = {
      passwordInput: {
        onPaste: this.disablePaste,
        onCopy: this.disableCopy,
        onValueChanged: (e) => {
          let validator_newPassword = this.form_userData
            .getEditor("newPassword")
            .element()
            .dxValidator("instance");
          if (validator_newPassword.option("isValid") === false)
            validator_newPassword.validate();
        },
        elementAttr: { class: this.state.passwordStyle_0 },
        buttons: [
          {
            name: "password",
            location: "after",
            options: this.buttonOptions.passwordBtn,
          },
        ],
      },
      newPasswordInput: {
        name: "newPassword",
        elementAttr: { class: this.state.passwordStyle_1 },
        onPaste: this.disablePaste,
        onCopy: this.disableCopy,
        onValueChanged: (e) => {
          let validator_password = this.form_userData
              .getEditor("password")
              .element()
              .dxValidator("instance"),
            validator_newPassword = this.form_userData
              .getEditor("newPassword")
              .element()
              .dxValidator("instance"),
            validator_confirmPassword = this.form_userData
              .getEditor("confirmPassword")
              .element()
              .dxValidator("instance");

          if (validator_password.option("isValid") === false)
            validator_password.validate();
          if (validator_newPassword.option("isValid") === false)
            validator_newPassword.validate();
          if (validator_confirmPassword.option("isValid") === false)
            validator_confirmPassword.validate();
        },
        buttons: [
          {
            name: "newPassword",
            location: "after",
            options: this.buttonOptions.newPasswordBtn,
          },
        ],
      },
      confirmPasswordInput: {
        name: "confirmPassword",
        elementAttr: { class: this.state.passwordStyle_2 },
        onPaste: this.disablePaste,
        onCopy: this.disableCopy,
        buttons: [
          {
            name: "confirmPassword",
            location: "after",
            options: this.buttonOptions.confirmPasswordBtn,
          },
        ],
      },
    };
  }
  //#endregion

  render() {
    if (!this.state.datosUser_formData) return false;
    this.setConfigLocal();

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div
            id="MiCuenta"
            className={"formContainer d-flex justify-content-center"}
          >
            <Box direction="col" width="50vh" height={"100%"}>
              <ItemBox baseSize={25} />
              <ItemBox ratio={1}>
                <Form
                  ref={this.form_userData_REF}
                  formData={this.state.datosUser_formData}
                >
                  <Item dataField="nombre" editorType="dxTextBox" />
                  <Item
                    dataField="password"
                    name="password"
                    editorOptions={this.editorOptions.passwordInput}
                    label={{ text: getTrad("contraseña") }}
                  >
                    <PatternRule
                      pattern={password_Pattern}
                      message={getTrad("alert_restriccionPasswordUser")}
                    />
                    <CustomRule
                      message={getTrad("campoNecesario")}
                      reevaluate={true}
                      validationCallback={(e) =>
                        this.password_requiredRule(e, this)
                      }
                    />
                  </Item>
                  <Item
                    dataField="newPassword"
                    editorOptions={this.editorOptions.newPasswordInput}
                    label={{ text: getTrad("nuevaContraseña") }}
                  >
                    <PatternRule
                      reevaluate={true}
                      pattern={password_Pattern}
                      message={getTrad("alert_restriccionPasswordUser")}
                    />
                    <CustomRule
                      message={getTrad("campoNecesario")}
                      reevaluate={true}
                      validationCallback={(e) =>
                        this.newPassword_requiredRule(e, this)
                      }
                    />
                  </Item>
                  <Item
                    dataField="confirmPassword"
                    editorOptions={this.editorOptions.confirmPasswordInput}
                    label={{ text: getTrad("confirmarContraseña") }}
                  >
                    <CustomRule
                      message={getTrad("contraseñasNoCoinciden")}
                      reevaluate={true}
                      validationCallback={this.passwordComparison}
                    />
                  </Item>

                  <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={this.buttonOptions.userData_guardar}
                  />
                </Form>
              </ItemBox>
            </Box>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    let _this = this;
    this.dataSource_tblUsuario.load().then(function (items) {
      items[0].password = null;
      items[0].newPassword = null;
      items[0].confirmPassword = null;

      _this.setState({
        datosUser_formData: items[0],
      });
    });
    this.loadPanel_hide();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  change_username: (user) => dispatch(userActions.change_username(user)),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiCuenta);
