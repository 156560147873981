import React, { Fragment } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../constants";

import {
  errorHandler,
  authHeader,
  getNombreFormulario,
  get_isMovil,
  getTrad,
  durationToDatetime,
  dateTime_hourMinute,
} from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

//Custom components
import ActionToast from "../../../components/ActionToast/ActionToast";
import { DropDownOptions, TagBox } from "devextreme-react/tag-box";

import { Switch } from "devextreme-react";
import { Button } from "devextreme-react";

import ScrollView from "devextreme-react/scroll-view";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//Custom components
import PopupTurnoEdit from "./components/PopupTurnoEdit/index";

//Css
import "./Css.scss";

class Turno extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      turnos_items: [],
      subTurnos_items: [],
      idSel: null,
      isVisible_popup_showTurno: false,
      editTurno_itemSel: null,
      isSubTurno_addTurno: false,
      categoriaSalarial_items: [],
    };

    this.onClick_SelectionToast = this.onClick_SelectionToast.bind(this);
    this.onClick_Button_editToast = this.onClick_Button_editToast.bind(this);
    this.dxPopup_tblTurno_onHiding = this.dxPopup_tblTurno_onHiding.bind(this);
    this.dxPopup_tblTurno_onHidden = this.dxPopup_tblTurno_onHidden.bind(this);
    this.onClick_Button_addTurno = this.onClick_Button_addTurno.bind(this);

    //DATASOURCE
    this.datasource_tblTurno_map = this.datasource_tblTurno_map.bind(this);

    this.loadPanel_show();
    this.getTrad = this.getTrad.bind(this);
    this.reload_data = this.reload_data.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //Datasource turno
  datasource_tblTurno = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblTurno_beforeSend(request);
      },
      version: 4,
    }),
    filter: ["eliminado eq false and idTurnoPadre eq null"],
    expand: [
      "InverseidTurnoPadreNavigation($filter=eliminado eq false;$expand=tblCategoriaInternaNTurno($select=idCategoriaInterna),tblPersona($top=1;$select=idPersona)),tblPersona($top=1;$select=idPersona)",
    ],
    map: (data) => {
      return this.datasource_tblTurno_map(data);
    },
  });

  datasource_tblTurno_map(dataItem) {
    let { categoriaSalarial_items } = this.state;

    function getSwitchActivo(item, _this) {
      return (
        <Switch
          value={item.activo}
          height={25}
          width={90}
          switchedOnText={_this.getTrad("activo")}
          switchedOffText={_this.getTrad("noActivo")}
          elementAttr={_this.dxSwitch_activo_elementAttr}
          readOnly={true}
        />
      );
    }

    function getDescripcion(item, _this) {
      return (
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-row">
            <div style={{ minWidth: 90 }}>
              {_this.getTrad("horaEntrada") + ": "}
            </div>{" "}
            <div style={{ flexBasis: 15 }} />{" "}
            <div>
              {dateTime_hourMinute(durationToDatetime(item.horaEntrada))}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div style={{ minWidth: 90 }}>
              {_this.getTrad("horaSalida") + ": "}
            </div>{" "}
            <div style={{ flexBasis: 15 }} />{" "}
            <div>
              {dateTime_hourMinute(durationToDatetime(item.horaSalida))}
            </div>
          </div>
          <div className="d-flex flex-row">
            <div style={{ minWidth: 90 }}>
              {_this.getTrad("descanso") + ": "}
            </div>{" "}
            <div style={{ flexBasis: 15 }} />{" "}
            <div>{dateTime_hourMinute(durationToDatetime(item.descanso))}</div>
          </div>
        </div>
      );
    }

    let _this = this;
    let tblSubTurno_map = $.map(
      dataItem.InverseidTurnoPadreNavigation,
      function (item) {
        let tblCategoriaInternaNTurno = $.map(
          item.tblCategoriaInternaNTurno,
          function (item_) {
            return item_.idCategoriaInterna;
          },
        );

        let tagBox_categorias =
          tblCategoriaInternaNTurno.length == 0 ? null : (
            <TagBox
              value={tblCategoriaInternaNTurno}
              items={categoriaSalarial_items}
              elementAttr={_this.dxTagBox_tblCategoriaInternaNTurno_elementAttr}
              width="100%"
              readOnly={true}
              stylingMode="underlined"
              displayExpr="denominacion"
              valueExpr="idCategoriaInterna"
              applyValueMode="useButtons"
              tagRender={_this.dxTagBox_tagRender}
            >
              <DropDownOptions minWidth={300} />
            </TagBox>
          );

        return {
          id: item.idTurno,
          denominacion: item.denominacion,
          descripcion: getDescripcion(item, _this),
          abreviatura: item.abreviatura,
          activo: item.activo,
          horaEntrada: durationToDatetime(item.horaEntrada),
          horaSalida: durationToDatetime(item.horaSalida),
          descanso: durationToDatetime(item.descanso),
          idTurnoPadre: item.idTurnoPadre,
          tagBox_categorias: tagBox_categorias,
          dxSwitch_Activo: getSwitchActivo(item, _this),
          tblCategoriaInternaNTurno: tblCategoriaInternaNTurno,
          hasPersonaAsignada: item.tblPersona.length > 0,
        };
      },
    );

    return {
      id: dataItem.idTurno,
      denominacion: dataItem.denominacion,
      descripcion: getDescripcion(dataItem, this),
      tblSubTurno: tblSubTurno_map,
      abreviatura: dataItem.abreviatura,
      activo: dataItem.activo,
      dxSwitch_Activo: getSwitchActivo(dataItem, this),
      horaEntrada: durationToDatetime(dataItem.horaEntrada),
      horaSalida: durationToDatetime(dataItem.horaSalida),
      descanso: durationToDatetime(dataItem.descanso),
      hasPersonaAsignada: dataItem.tblPersona.length > 0,
    };
  }

  datasource_tblTurno_beforeSend(request) {
    let { lavanderia } = this.props;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }
  //#endregion

  dxTagBox_tagRender(itemData) {
    return (
      <div
        style={{ paddingRight: 6, marginRight: 4 }}
        className="dx-tag-content"
      >
        {itemData.denominacion}
      </div>
    );
  }

  render() {
    let {
      turnos_items,
      subTurnos_items,
      idSel,
      isVisible_popup_showTurno,
      editTurno_itemSel,
      isSubTurno_addTurno,
      categoriaSalarial_items,
    } = this.state;
    let { idioma, resolucion, lavanderia } = this.props;

    let height_scrollViewPrincipal = $("#dxScrollView_principal").height() - 10;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="Turno" className="formContainer he-100 scrollbar-container">
            <ScrollView
              id="dxScrollView_principal"
              className={"cardContent he-100"}
            >
              <Row className="row-1 he-100 flex-fill">
                <Col
                  xs="12"
                  md="12"
                  lg="4"
                  xl="4"
                  className={"d-flex flex-column col-xl-5ths "}
                  style={{
                    maxHeight: height_scrollViewPrincipal
                      ? height_scrollViewPrincipal
                      : "100%",
                  }}
                >
                  <Card className="d-flex he-100 card-notificaciones">
                    <CardHeader className="card-header-tab">
                      <Row className="container_spanCentrado w-100">
                        <Col className="w-100 pl-2 pr-3 text-truncate text-left">
                          {this.getTrad("turnos") + " principales"}
                        </Col>
                        <Col xs="3" className="d-flex justify-content-end p-0">
                          <Button
                            elementAttr={this.dxButton_comunicado}
                            icon="plus"
                            stylingMode="text"
                            hoverStateEnabled={false}
                            focusStateEnabled={false}
                            activeStateEnabled={false}
                            width={28}
                            onClick={this.onClick_Button_addTurno}
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="body-actionToast he-100 align-items-stretch">
                      <ScrollView>
                        <div className="pr-2 pl-2 pb-1 pt-2 he-100">
                          {turnos_items.length == 0 && (
                            <div className="he-100 font-size-lg noData_disabled d-flex justify-content-center align-items-center">
                              {"Sin turnos"}
                            </div>
                          )}
                          <ActionToast
                            tipo="notificaciones"
                            data={turnos_items}
                            idSel={idSel}
                            enableSelection={true}
                            direction="column"
                            onClick_SelectionToast={this.onClick_SelectionToast}
                            onClick_Button_editToast={
                              this.onClick_Button_editToast
                            }
                          />
                        </div>
                      </ScrollView>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg="8"
                  xl="8"
                  className={
                    "d-flex flex-column col-xl-5ths " +
                    (get_isMovil(resolucion) ? "pt-3" : "")
                  }
                  style={{
                    maxHeight: height_scrollViewPrincipal
                      ? height_scrollViewPrincipal
                      : "100%",
                  }}
                >
                  <Card className="d-flex he-100 card-notificaciones">
                    <CardHeader
                      className={
                        "card-header-tab " +
                        (get_isMovil(resolucion) ? "minHeight-50" : "")
                      }
                    >
                      <Row className="container_spanCentrado w-100 he-100-">
                        <Col
                          className={
                            "w-100 pl-2 pr-3 text-truncate text-left he-100- " +
                            (get_isMovil(resolucion)
                              ? "d-flex align-items-center"
                              : "")
                          }
                        >
                          {"Subturnos"}
                        </Col>
                        <Col
                          xs="3"
                          className="d-flex justify-content-end p-0 he-100-"
                        >
                          <Button
                            elementAttr={this.dxButton_comunicado}
                            icon="plus"
                            stylingMode="text"
                            hoverStateEnabled={false}
                            focusStateEnabled={false}
                            activeStateEnabled={false}
                            width={28}
                            onClick={(e) => {
                              this.onClick_Button_addTurno(e, true);
                            }}
                          />
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="body-actionToast he-100 align-items-stretch">
                      <ScrollView>
                        <div className="pr-2 pl-2 pb-1 pt-2 he-100">
                          {subTurnos_items.length == 0 && (
                            <div className="he-100 font-size-lg noData_disabled d-flex justify-content-center align-items-center">
                              {"Sin subturnos"}
                            </div>
                          )}
                          <ActionToast
                            tipo="notificaciones"
                            data={subTurnos_items}
                            enableSelection={false}
                            onClick_Button_editToast={
                              this.onClick_Button_editToast
                            }
                            direction="row"
                          />
                        </div>
                      </ScrollView>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </ScrollView>
            <PopupTurnoEdit
              isVisible_popup_showTurno={isVisible_popup_showTurno}
              resolucion={resolucion}
              isSubTurno={isSubTurno_addTurno}
              idTurnoPadreSel={idSel}
              idioma={idioma}
              idPais={lavanderia.idPais}
              idLavanderia={lavanderia.idLavanderia}
              editTurno_itemSel={editTurno_itemSel}
              categoriaSalarial_items={categoriaSalarial_items}
              onHiding={this.dxPopup_tblTurno_onHiding}
              onHidden={this.dxPopup_tblTurno_onHidden}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  dxSwitch_activo_elementAttr = { class: "no-disabled font-size-xxxs" };
  dxButton_comunicado = {
    class: "iconHeader_dxButton_comunicado",
  };

  dxTagBox_tblCategoriaInternaNTurno_elementAttr = {
    class: "dxTagBox_tblCategoriaInternaNTurno",
  };

  onClick_SelectionToast(e, item) {
    let turnos_items = [...this.state.turnos_items];
    let turnoSelected = $.grep(turnos_items, function (turno) {
      return turno.id == item.id;
    });

    this.setState({
      idSel: item.id,
      subTurnos_items:
        turnoSelected.length > 0 ? turnoSelected[0].tblSubTurno : [],
    });
  }

  onClick_Button_editToast(e, item) {
    this.setState({
      editTurno_itemSel: item,
      isVisible_popup_showTurno: true,
    });
  }

  onClick_Button_addTurno(e, isSubTurno) {
    this.setState({
      isVisible_popup_showTurno: true,
      isSubTurno_addTurno: isSubTurno,
    });
  }

  dxPopup_tblTurno_onHiding(isSaved, idSel) {
    if (isSaved) {
      this.reload_data(this, idSel);
    }

    this.setState({
      isVisible_popup_showTurno: false,
    });
  }

  dxPopup_tblTurno_onHidden() {
    this.setState({
      editTurno_itemSel: null,
      isSubTurno_addTurno: false,
    });
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  reload_data(_this, _idSel) {
    this.loadPanel_show(true);
    let { idSel } = this.state;

    this.datasource_tblTurno.reload().done(function (items) {
      let turnoSel_guardado = null;
      if (_idSel != null && _idSel != -1) {
        turnoSel_guardado = $.grep(items, function (item) {
          return item.id == _idSel;
        });
        turnoSel_guardado =
          turnoSel_guardado.length > 0 ? turnoSel_guardado[0] : null;
      } else if (_idSel == -1) {
        // Seleccionar primer registro
        _idSel = items.length > 0 ? items[0].id : null;
      }

      let tblTurnoSel =
        turnoSel_guardado == null
          ? items.length > 0
            ? items[0]
            : null
          : turnoSel_guardado;
      let subTurno_primerTurno =
        tblTurnoSel != null ? tblTurnoSel.tblSubTurno : [];

      _this.setState({
        turnos_items: items,
        subTurnos_items: subTurno_primerTurno,
        idSel:
          _idSel != null
            ? _idSel
            : idSel == null
              ? tblTurnoSel
                ? tblTurnoSel.id
                : null
              : idSel,
      });
      _this.loadPanel_hide();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      this.reload_data(this, -1);
    }
  }

  componentDidMount() {
    let _this = this;
    this.datasource_tblCategoriaInterna.load().done(function (items) {
      _this.setState(
        {
          categoriaSalarial_items: items,
        },
        () => {
          _this.reload_data(_this);
        },
      );
    });
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Turno);
