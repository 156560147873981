import React, { Fragment } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import { connectionConstants } from "../../constants";
import {
  getTrad,
  dxMensajePregunta,
  compressBase64Img_scaleWidth,
  formatDate,
  convertClienteUtcToLavanderiaUtc,
  errorHandler,
  authHeader,
  startOfYear,
} from "../../helpers";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Tooltip } from "devextreme-react/tooltip";
import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import {
  Autocomplete,
  DateBox,
  FileUploader,
  Gallery,
  SelectBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import { Form, Item, RequiredRule } from "devextreme-react/form";
import { TextBox, Switch } from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";
import { LoadPanel } from "devextreme-react/load-panel";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Guid from "devextreme/core/guid";

import { Validator } from "devextreme-react/validator";

//Css
import "./Css.scss";

const dxPopup_adjuntarArchivos_galeriaDocumentos = {
  of: ".dxPopup_adjuntarArchivos_galeriaDocumentos",
};
class AdjuntarArchivos extends React.PureComponent {
  constructor(props) {
    super(props);

    let { tipoArchivos } = this.props;

    this.tileItem_size = {
      fotos: {
        height: 125,
        width: 130,
        padding: 40,
      },
      fotosPequeñas: {
        height: 95,
        width: 95,
        padding: 0,
      },
      documentos: {
        height: 75,
        width: "48%",
        padding: 20,
      },
    };

    this.tileItem_size[tipoArchivos].height =
      this.tileItem_size[tipoArchivos].height +
      this.tileItem_size[tipoArchivos].padding; // Para dejar especio entre tiles como son absolute hay que sumarle al height

    this.state = {
      data: [],
      accionesModificarDatos: [],
      popup_galeriaDocumentos_visible: false,
      popup_modificarArchivo_visible: false,
      dxGallery_items: [],
      dxGallery_selectedIndex: 0,
      formData_modificarArchivo: this.formData_modificarArchivo_reset,
      requerirDoc: null,
      dxAccordion_grandParentTileView_selectedItems: [],
      idPadreSel: null,
      isVisible_LoadPanel_ProgressBar: false,
      numArchivos_LoadPanel_ProgressBar: 0,
      start: 0,
      end: null,
      showLoading_adjuntarArchivos: false,
      isUploadAllowed: true,
      archivoModificando: null,
    };

    this.scrollView = React.createRef();

    //TEMPLATE
    this.template_tile_documento = this.template_tile_documento.bind(this);
    this.dxGallery_itemRender = this.dxGallery_itemRender.bind(this);
    this.dxGallery_onContentReady = this.dxGallery_onContentReady.bind(this);

    //EVENTOS
    this.onContentReady_popup_galeriaDocumentos =
      this.onContentReady_popup_galeriaDocumentos.bind(this);
    this.onHiding_popup_galeriaDocumentos =
      this.onHiding_popup_galeriaDocumentos.bind(this);
    this.documentos_onValueChanged = this.documentos_onValueChanged.bind(this);
    this.documentos_onOptionChanged =
      this.documentos_onOptionChanged.bind(this);
    this.documentos_onContentReady = this.documentos_onContentReady.bind(this);
    this.dxButton_subirArchivo_onClick =
      this.dxButton_subirArchivo_onClick.bind(this);
    this.dxButton_requerirDocumento_onClick =
      this.dxButton_requerirDocumento_onClick.bind(this);
    this.onHiding_popup_modificarArchivo =
      this.onHiding_popup_modificarArchivo.bind(this);
    this.onHidden_popup_modificarArchivo =
      this.onHidden_popup_modificarArchivo.bind(this);
    this.onShown_popup_modificarArchivo =
      this.onShown_popup_modificarArchivo.bind(this);
    this.dxButton_modificarArchivo_guardar_onClick =
      this.dxButton_modificarArchivo_guardar_onClick.bind(this);
    this.dxTextBox_requerirDocumento_onValueChanged =
      this.dxTextBox_requerirDocumento_onValueChanged.bind(this);
    this.dxAutocomplete_requerirDocumento_onValueChanged =
      this.dxAutocomplete_requerirDocumento_onValueChanged.bind(this);
    this.dxSwitch_firma_onValueChanged =
      this.dxSwitch_firma_onValueChanged.bind(this);

    this.itemRender = this.itemRender.bind(this);
    this.scollPos = this.scollPos.bind(this);

    // REFERENCIAS
    this.dxFileUploader_documentos_REF = React.createRef();
    this.dxForm_requerirDocumento_REF = React.createRef();
    this.dxTextBox_nombre_modificarArchivo_REF = React.createRef();

    this.getTrad = this.getTrad.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //REFERENCIAS
  get dxFileUploader_documentos() {
    return this.dxFileUploader_documentos_REF.current.instance;
  }
  get dxForm_requerirDocumento() {
    return this.dxForm_requerirDocumento_REF.current.instance;
  }
  get dxTextBox_nombre_modificarArchivo() {
    return this.dxTextBox_nombre_modificarArchivo_REF.current.instance;
  }

  //#region DATASOURCE Para cargar documentos en galeria
  datasource_tblDocumento = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
      key: "idDocumento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblDocumento_beforeSend(request);
      },
      version: 4,
    }),
    select: [
      "documento,extension,fecha,fechaModificacion,idCarpetaDocumentos,denominacion",
    ],
  });

  datasource_tblDocumento_beforeSend(request) {
    request.headers = { ...authHeader() };

    if (request.method === "get") request.params.todos = true;
  }

  datasource_tblDocumentoPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumentoPrenda",
      key: "idDocumento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblDocumentoPrenda_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblDocumentoPrenda_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  //#endregion

  //PROPS ACEPTADAS
  // - isEditAllowed -> FALSE SI NO SE PERMITE EDICIÓN | TRUE O UNDEFINED SI SE PERMITE
  // - isRemoveAllowed -> FALSE SI NO SE PERMITE EL BORRADO | TRUE O UNDEFINED SI SE PERMITE
  // - isUploadAllowed -> FALSE si no se permite subir nuevos archivos | TRUE o UNDEFINED si se permite subir nuevos archivos
  // - data -> ARRAY CON LOS ARCHIVOS A MOSTRAR. DEBEN TENER UN CAMPO id
  // - updateArchivos -> METODO DEFINIDO EN ORIGEN DONDE SE ACTUALIZA EL STATE DE ORIGEN CON LOS NUEVOS ARCHIVOS
  // - idPadreSel -> ID DEL PADRE QUE FILTRA LOS ARCHIVOS VISIBLES
  // - tipoArchivos -> PUEDE SER "documentos" o "fotos" -> Mostrará los archivos en el dxTileView de una manera u otra por defecto se muestran fotos
  // - isVisibleRequerirDocumento -> SE MUESTRA BOTÓN Requerir documento
  // - isMultipleLoadFile -> Para permitir cargar solo uno o varios archivos cada vez
  // - acceptedTypes -> Tipos de archivos aceptados(string separado por comas) (solo se filtra a nivel visual cuando se busca el archivo a subir)
  // - allowedFileExtensions -> extensiones permitidas de los documentos para poder guardarlos
  // - documentosNGrandParent -> Arrays con objs, si hay más de uno se generará una vista de tileviews por cada id
  // - isDropZone
  // - tablaCargaDocumentos -> tblDocumentoPrenda
  // - archivoUnico -> Solo se sube un archivo para el registro, si se sube otro sobreescribe al que hay
  // - tieneCabecera -> Cabecera con titulo archivos por ejemplo (para calcular el height teniendolo en cuenta)

  render() {
    let {
      data,
      archivoModificando,
      popup_galeriaDocumentos_visible,
      dxGallery_items,
      popup_modificarArchivo_visible,
      formData_modificarArchivo,
      requerirDoc,
      isMultipleLoadFile,
      acceptedTypes,
      allowedFileExtensions,
      showLoading_adjuntarArchivos,
      isUploadAllowed,
    } = this.state;
    let {
      tipoArchivos,
      isDropZone,
      numItemPerRow,
      tieneCabecera,
      archivoUnico,
      subcarpetas,
    } = this.props;
    let containerStyle = {
      height:
        Math.round(data.length / numItemPerRow) *
        this.tileItem_size[tipoArchivos].height,
    };

    return (
      <Fragment>
        <LoadPanel
          shadingColor="transparent"
          deferRendering={false}
          animation={null}
          visible={showLoading_adjuntarArchivos}
          showIndicator={true}
          shading={true}
          showPane={true}
        />
        <div
          id={archivoUnico ? "" : "adjuntarArchivos_container_personal"}
          className={
            "d-flex flex-column he-100 w-100 " +
            (tieneCabecera ? "cabecera " : " ")
          }
        >
          <div
            className="d-flex flex-column tileContainer_archivos"
            style={{
              flex: tipoArchivos === "fotosPequeñas" ? 0 : 1,
              minHeight: 0,
            }}
          >
            {data.length > 0 ? (
              <ScrollView
                ref={this.scrollView}
                className="he-100 scroll_adjuntarArchivos"
                showScrollbar="always"
                onScroll={this.scollPos}
              >
                <div
                  className={
                    "itemContainer tipoArchivos_documentos " + tipoArchivos
                  }
                  id="tileView_adjuntarArchivos"
                  style={containerStyle}
                >
                  {this.itemRender()}
                </div>
              </ScrollView>
            ) : (
              <div
                className="d-flex justify-content-center position-relative"
                style={{
                  top: tipoArchivos === "fotosPequeñas" ? 0 : "50%",
                  width:
                    tipoArchivos === "fotosPequeñas"
                      ? this.tileItem_size[tipoArchivos].width
                      : "100%",
                }}
              >
                No hay archivos subidos
              </div>
            )}
          </div>
          {isUploadAllowed && (
            <div
              className="d-flex flex-column"
              style={{ height: tipoArchivos === "fotosPequeñas" ? 35 : 90 }}
            >
              <FileUploader
                ref={this.dxFileUploader_documentos_REF}
                width="100%"
                accept={acceptedTypes ? acceptedTypes : "*"}
                allowedFileExtensions={
                  allowedFileExtensions ? allowedFileExtensions : []
                }
                minFileSize={1}
                multiple={isMultipleLoadFile}
                uploadMode="useForm"
                showFileList={true}
                labelText={
                  isDropZone ? this.getTrad("msg_arrastraArchivo5mb") : ""
                }
                uploadFailedMessage={this.getTrad("subidaFallida")}
                selectButtonText={this.getTrad("subirArchivo")}
                onContentReady={this.documentos_onContentReady}
                onValueChanged={this.documentos_onValueChanged}
                onOptionChanged={this.documentos_onOptionChanged}
              />
            </div>
          )}
        </div>
        <Popup
          showTitle={true}
          title={this.getTrad("galeria")}
          visible={popup_galeriaDocumentos_visible}
          showCloseButton={true}
          closeOnOutsideClick={true}
          width="900px"
          height="90%"
          onContentReady={this.onContentReady_popup_galeriaDocumentos}
          onHiding={this.onHiding_popup_galeriaDocumentos}
        >
          <LoadPanel
            shadingColor="transparent"
            delay={150}
            position={dxPopup_adjuntarArchivos_galeriaDocumentos}
            animation={null}
            visible={
              dxGallery_items.length == 0 && popup_galeriaDocumentos_visible
            }
            showIndicator={true}
            shading={true}
            showPane={true}
          />
          <Gallery
            items={dxGallery_items}
            showIndicator={dxGallery_items.length > 1}
            showNavButtons={dxGallery_items.length > 1}
            swipeEnabled={false}
            loop={true}
            height="100%"
            width="100%"
            noDataText=""
            itemRender={this.dxGallery_itemRender}
            onContentReady={this.dxGallery_onContentReady}
          />
        </Popup>
        <Popup
          showTitle={true}
          title={
            requerirDoc
              ? "Requerir documento"
              : archivoModificando
                ? "Editar documento"
                : "Subir documento"
          }
          visible={popup_modificarArchivo_visible}
          showCloseButton={requerirDoc || archivoModificando != null}
          closeOnOutsideClick={false}
          width={350}
          height={340}
          onShown={this.onShown_popup_modificarArchivo}
          onHiding={this.onHiding_popup_modificarArchivo}
          onHidden={this.onHidden_popup_modificarArchivo}
        >
          <Form
            ref={this.dxForm_requerirDocumento_REF}
            formData={formData_modificarArchivo}
            labelLocation="top"
            colCount={1}
            height="100%"
            width="100%"
            validationGroup="dxForm_modificarArchivo_validationGroup"
          >
            <Item dataField="nombre" label={this.formLabel_nombre}>
              <TextBox
                ref={this.dxTextBox_nombre_modificarArchivo_REF}
                value={formData_modificarArchivo.nombre}
                onValueChanged={this.dxTextBox_requerirDocumento_onValueChanged}
              >
                <Validator validationGroup="dxForm_modificarArchivo_validationGroup">
                  <RequiredRule />
                </Validator>
              </TextBox>
            </Item>
            <Item dataField="subCarpeta" label={this.formLabel_subCarpeta}>
              <Autocomplete
                value={formData_modificarArchivo.subCarpeta}
                onValueChanged={
                  this.dxAutocomplete_requerirDocumento_onValueChanged
                }
                showClearButton={true}
                dataSource={subcarpetas?.filter(
                  (x) =>
                    x.subCarpeta !== "null" && x.subCarpeta !== "undefined",
                )}
                valueExpr={"subCarpeta"}
                displayExpr={"denominacion"}
                minSearchLength={0}
                openOnFieldClick={true}
              />
            </Item>
            <Item
              visible={!(archivoModificando != null)}
              disabled={archivoModificando != null}
              dataField="firmado"
              label={this.formLabel_firma}
              cssClass="pr-2"
            >
              <Switch
                value={formData_modificarArchivo.firmado}
                width={200}
                switchedOffText="Sin firma"
                switchedOnText="Con firma"
                elementAtt={this.dxSwitch_firma_elementAttr}
                onValueChanged={this.dxSwitch_firma_onValueChanged}
              />
            </Item>
          </Form>
          <ToolbarItem_popup
            toolbar="bottom"
            location="after"
            widget="dxButton"
          >
            <Button
              text={this.getTrad("guardar")}
              type="success"
              onClick={this.dxButton_modificarArchivo_guardar_onClick}
            />
          </ToolbarItem_popup>
        </Popup>
      </Fragment>
    );
  }

  //#region FORMAT
  elemetnAttr_class__tipoArchivos_documentos = {
    class: "tipoArchivos_documentos",
  };
  template_tile_documento_elemAttr = {
    class: "dxToolbar_opciones dxToolbar_position",
  };
  formLabel_nombre = { text: this.getTrad("nombre") };
  formLabel_subCarpeta = { text: "Subcarpeta" };
  formLabel_firma = { text: " ", visible: true, showColon: false };
  dxSwitch_firma_elementAttr = { class: "pt-switchFirma no-disabled" };
  formData_modificarArchivo_reset = {
    nombre: "",
    subCarpeta: this.props.subCarpetaSel ? this.props.subCarpetaSel : null,
    firmado: null,
  };

  //#endregion

  //#region EVENTOS

  dxTextBox_requerirDocumento_onValueChanged(e) {
    let formData_modificarArchivo = { ...this.state.formData_modificarArchivo };
    formData_modificarArchivo.nombre = e.value;

    this.setState({ formData_modificarArchivo });
  }

  dxAutocomplete_requerirDocumento_onValueChanged(e) {
    let formData_modificarArchivo = { ...this.state.formData_modificarArchivo };
    formData_modificarArchivo.subCarpeta = e.value;

    this.setState({ formData_modificarArchivo });
  }

  dxSwitch_firma_onValueChanged(e) {
    let formData_modificarArchivo = { ...this.state.formData_modificarArchivo };
    formData_modificarArchivo.firmado = e.value;
    this.setState({ formData_modificarArchivo });
  }

  dxGallery_onContentReady(e) {
    let { dxGallery_selectedIndex } = this.state;
    e.component.option("selectedIndex", dxGallery_selectedIndex);
  }

  onContentReady_popup_galeriaDocumentos(e) {
    e.component
      .content()
      .attr("id", "dxPopup_adjuntarArchivos_galeriaDocumentos");
  }

  onHiding_popup_galeriaDocumentos(e) {
    this.setState({
      popup_galeriaDocumentos_visible: false,
      dxGallery_items: [],
    });
  }

  //#region btnDownload documentos
  btnDownloadDocument_onClick(e, itemData) {
    let downloadLink = document.createElement("a");
    let _this = this;

    let { archivoUnico } = this.props;

    if (itemData.documento) {
      downloadLink.href =
        "data:" + itemData.extension + ";base64," + itemData.documento;
      downloadLink.download = itemData.nombre;
      downloadLink.click();
    } else {
      let dataSource =
        _this.props.tablaCargaDocumentos === "tblDocumentoPrenda"
          ? "datasource_tblDocumentoPrenda"
          : "datasource_tblDocumento";
      _this[dataSource]
        .store()
        .byKey(itemData.idDocumento)
        .done(function (imagen) {
          let img = archivoUnico ? imagen[0] : imagen;

          downloadLink.href =
            "data:" + itemData.extension + ";base64," + img.documento;
          downloadLink.download = itemData.nombre;
          downloadLink.click();
        });
    }
  }

  btnDownloadDocument_template(e, itemData) {
    return (
      <div
        className="container_spanCentrado dxToolbar_opcion mr-1"
        style={this.btnDownloadDocument_template_style}
      >
        <div
          className="icon_Arrow_Down icon_color_hover iconos_svg"
          style={this.btnDownloadDocument_template_style_icon}
          onClick={(e) => this.btnDownloadDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  }
  //#endregion

  btnEditDocument_onClick(e, itemData) {
    let subCarpeta =
      !itemData.subCarpeta ||
      itemData.subCarpeta == "undefined" ||
      itemData.subCarpeta == "null"
        ? null
        : itemData.subCarpeta;

    this.setState({
      popup_modificarArchivo_visible: true,
      archivoModificando: itemData,
      formData_modificarArchivo: {
        nombre: itemData.nombre,
        subCarpeta: subCarpeta,
      },
    });
  }

  btnEditDocument_template(e, itemData) {
    return (
      <div
        className="container_spanCentrado dxToolbar_opcion mr-1"
        style={this.btnRemoveDocument_template_style}
      >
        <div
          className="icon_Edit icon_color_hover iconos_svg"
          style={this.btnRemoveDocument_template_style_icon}
          onClick={(e) => this.btnEditDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  }

  btnRemoveDocument_onClick(e, itemData) {
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    let _this = this;
    let { data } = this.state;
    let { archivoUnico, accionesModificarDatos } = this.props;
    dxMensajePregunta(this.getTrad("preg_RegistroEliminarCorto"), [
      [
        this.getTrad("aceptar"),
        function () {
          let doc_eliminar_index = null;
          let accion_eliminar_index = null;

          $.each(data, function (index, item) {
            if (item.id === itemData.id) {
              doc_eliminar_index = index;
            }
          });
          $.each(accionesModificarDatos, function (index, item) {
            if (item.action === "insert" && item.data.id === itemData.id)
              accion_eliminar_index = index;
          });

          if (doc_eliminar_index !== null) data.splice(doc_eliminar_index, 1);

          if (accion_eliminar_index !== null)
            accionesModificarDatos.splice(accion_eliminar_index, 1);

          if (isNumeric(itemData.id) || archivoUnico) {
            accionesModificarDatos = accionesModificarDatos.filter(
              (x) => x.data?.id !== itemData.id,
            );

            accionesModificarDatos.push({
              action: "delete",
              id: itemData.id,
            });
          }

          _this.props.updateArchivos(data, accionesModificarDatos);
          _this.setState({
            data: data,
            accionesModificarDatos: accionesModificarDatos,
          });
        },
        "danger",
      ],
      [this.getTrad("cancelar"), function () {}],
    ]);
  }

  btnRemoveDocument_template(e, itemData) {
    return (
      <div
        className="container_spanCentrado dxToolbar_opcion"
        style={this.btnRemoveDocument_template_style}
      >
        <div
          className="icon_Cerrar icon_color_hover iconos_svg"
          style={this.btnRemoveDocument_template_style_icon}
          onClick={(e) => this.btnRemoveDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  }

  documentos_onContentReady(e) {
    let { isVisibleRequerirDocumento } = this.state;
    if (isVisibleRequerirDocumento) {
      e.element.find(".dx-fileuploader-button").hide();

      e.element.find(".dx-fileuploader-input-wrapper").prepend(
        $("<div />").addClass("dx-fileuploader-button").dxButton({
          text: "Subir archivo",
          onClick: this.dxButton_subirArchivo_onClick,
        }),
      );
      e.element.find(".dx-fileuploader-input-wrapper").prepend(
        $("<div />").addClass("dx-fileuploader-button").dxButton({
          text: "Requerir documento",
          onClick: this.dxButton_requerirDocumento_onClick,
        }),
      );

      let container = $("<div/>");
      container.append();
      $(e.element.find(".dx-fileuploader-input-wrapper"))
        .children()
        .appendTo(container);
      e.element.find(".dx-fileuploader-input-wrapper").prepend(container);
    }
  }

  dxButton_requerirDocumento_onClick(e) {
    this.setState(
      {
        popup_modificarArchivo_visible: true,
        requerirDoc: true,
        formData_modificarArchivo: this.formData_modificarArchivo_reset,
      },
      () => {
        this.dxForm_requerirDocumento.resetValues();
      },
    );
  }

  dxButton_subirArchivo_onClick(e) {
    this.dxFileUploader_documentos._selectButton.element().trigger("click");
  }

  onShown_popup_modificarArchivo(e) {
    this.dxTextBox_nombre_modificarArchivo.focus();
  }

  onHiding_popup_modificarArchivo(e) {
    this.setState({ popup_modificarArchivo_visible: false });
  }

  onHidden_popup_modificarArchivo(e) {
    this.setState({
      requerirDoc: null,
      archivoModificando: null,
    });
  }

  dxButton_modificarArchivo_guardar_onClick(e) {
    let formData_modificarArchivo = $.extend(
      true,
      {},
      this.state.formData_modificarArchivo,
    );
    let isValid = this.dxForm_requerirDocumento.validate().isValid;

    if (
      isValid ||
      (formData_modificarArchivo.nombre != null &&
        formData_modificarArchivo.nombre != "")
    ) {
      let { requerirDoc, documentosNGrandParent } = this.state;
      let { idPadreSel, lavanderia } = this.props;
      let formData_modificarArchivo = $.extend(
        true,
        {},
        this.state.formData_modificarArchivo,
      );
      let data =
        documentosNGrandParent && documentosNGrandParent.length > 0
          ? [...this.state.documentosNGrandParent[0].items]
          : [...this.state.data]; // documentosNGrandParent -> personas y dentro archivos || data -> archivos
      let accionesModificarDatos = [...this.props.accionesModificarDatos];

      let documentoEditando = this.state.archivoModificando;
      let item = documentoEditando ? documentoEditando : data[data.length - 1];
      let accionModificar =
        accionesModificarDatos[accionesModificarDatos.length - 1];
      let fecha = lavanderia
        ? convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date())
        : new Date();
      if (requerirDoc)
        item = {
          id: new Guid(),
          documento: null,
          idPadre: idPadreSel,
          fecha: fecha,
          fechaModificacion: fecha,
        };

      let items_añadidos = requerirDoc ? $.merge(data, [item]) : [data];
      if (requerirDoc) {
        item.nombre = formData_modificarArchivo.nombre;
        item.subCarpeta = formData_modificarArchivo.subCarpeta;
        item.firmado = formData_modificarArchivo.firmado ? false : null;
        item.requerido = requerirDoc ? requerirDoc : item.requerido;

        item.icon = "icon_Warning";
        item.subTitle = this.getTrad("documentoRequerido");
        item.colorIcon = item.firmado == null ? "color-green" : "color-red";

        accionesModificarDatos.push({
          action: "insert",
          data: item,
        });
      } else if (documentoEditando) {
        documentoEditando.nombre = formData_modificarArchivo.nombre;
        documentoEditando.subCarpeta = formData_modificarArchivo.subCarpeta;

        let accion = accionesModificarDatos.find(
          (x) => x.data?.id === documentoEditando.id,
        );
        if (accion && accion.action === "insert") {
          accion.data = documentoEditando;
        } else {
          accionesModificarDatos.push({
            action: "update",
            data: documentoEditando,
          });
        }
      } else {
        item.nombre = formData_modificarArchivo.nombre;
        item.subCarpeta = formData_modificarArchivo.subCarpeta;
        item.firmado = formData_modificarArchivo.firmado ? false : null;
        item.requerido = requerirDoc ? requerirDoc : item.requerido;

        accionModificar.data.nombre = formData_modificarArchivo.nombre;
        accionModificar.data.subCarpeta = formData_modificarArchivo.subCarpeta;
        accionModificar.data.firmado = item.firmado;
        accionModificar.requerido = requerirDoc;

        item.subTitle = accionModificar.data.subTitle =
          item.firmado === false ? this.getTrad("firmaRequerida") : null;
        item.icon = accionModificar.data.icon =
          item.firmado == false ? "icon_Warning" : item.icon;
        item.colorIcon = accionModificar.data.colorIcon =
          item.firmado == null ? "color-primary" : "color-green";
      }

      this.setState({
        popup_modificarArchivo_visible: false,
        requerirDoc: true,
        accionesModificarDatos: accionesModificarDatos,
      });

      if (this.scrollView.current != null)
        this.scrollView.current.instance.scrollTo(0);

      this.props.updateArchivos(items_añadidos, accionesModificarDatos);
    }
  }

  documentos_onOptionChanged(e) {
    if (e.name === "value" && e.value.length > 0) {
      this.setState({ showLoading_adjuntarArchivos: true });
    }
  }

  documentos_onValueChanged(e) {
    let data = [...this.state.data];
    let { isVisibleRequerirDocumento, maxSize, documentosNGrandParent } =
      this.state;
    let formData_modificarArchivo = { ...this.formData_modificarArchivo };
    let { idPadreSel, lavanderia, accionesModificarDatos, subCarpetaSel } =
      this.props;

    let sibling = data[0];

    let _this = this;
    let items_añadidos;
    let totalSize = 0;

    function updateDocumentos() {
      var deferred = $.Deferred();
      $.each(e.value, function (index, item) {
        var reader = new FileReader();
        reader.onload = function () {
          var fileSplit = reader.result.split(",");
          var extension = item.type;
          var base64 = fileSplit[1];
          var fileName = item.name;

          let icon = _this.getIcon(extension);

          function insertarDocumento() {
            let fecha = lavanderia
              ? convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date())
              : new Date();
            if (!data) data = [];
            let addedItem = {
              id: new Guid(),
              nombre: fileName,
              documento: base64,
              extension: extension,
              idPadre: idPadreSel,
              subCarpeta: _this.props.subCarpetaSel
                ? _this.props.subCarpetaSel
                : sibling?.subCarpeta,
              fecha: fecha,
              fechaModificacion: fecha,
              icon: icon,
              size: item.size,
              maxSize: maxSize,
            };

            items_añadidos = $.merge(data, [addedItem]);

            accionesModificarDatos.push({
              action: "insert",
              data: addedItem,
            });
            formData_modificarArchivo.nombre = addedItem.nombre;
            formData_modificarArchivo.subCarpeta = subCarpetaSel
              ? subCarpetaSel
              : null;

            var isLastElement = index == e.value.length - 1;
            if (isLastElement) deferred.resolve();
          }

          //Compress
          if (extension.split("/")[0] === "image") {
            compressBase64Img_scaleWidth(reader.result, 800).then(
              function (compressedBase64) {
                base64 = compressedBase64;
                insertarDocumento();
              },
            );
          } else {
            insertarDocumento();
          }
        };
        reader.readAsDataURL(item);
      });
      return deferred.promise();
    }

    updateDocumentos().then(function () {
      _this.setState({
        formData_modificarArchivo: formData_modificarArchivo,
        popup_modificarArchivo_visible: isVisibleRequerirDocumento,
        requerirDoc: null,
        start: 0,
      });
      e.component.option({
        value: [],
      });

      if (_this.scrollView.current != null)
        _this.scrollView.current.instance.scrollTo(0);

      _this.props.updateArchivos(items_añadidos, accionesModificarDatos);
    });
  }

  getIcon(extension) {
    let icon;
    if (extension?.split("/")[0] === "image") {
      icon = "dx-icon-photo";
    } else if (extension?.split("/")[1] === "pdf") {
      icon = "dx-icon-exportpdf";
    } else {
      if (extension?.split("/")[0] === "video") {
        icon = "dx-icon-video";
      } else if (
        extension?.includes("excel") ||
        extension?.includes("spreadsheetml")
      ) {
        icon = "dx-icon-exportxlsx";
      } else {
        icon = "dx-icon-doc";
      }
    }
    return icon;
  }

  tile_documento_onMouseEnter(e, itemData) {
    $("#tooltip_" + itemData.id)
      .dxTooltip("instance")
      .show();
  }

  tile_documento_onMouseLeave(e, itemData) {
    $("#tooltip_" + itemData.id)
      .dxTooltip("instance")
      .hide();
  }
  //#endregion

  //#region TEMPLATE

  scollPos(e) {
    let { data } = this.state;
    let { tipoArchivos, numItemPerRow } = this.props;
    let currentIndx = Math.trunc(
      e.scrollOffset.top / this.tileItem_size[tipoArchivos].height,
    );
    let scrollHeight = $(
      "#adjuntarArchivos_container_personal .tileContainer_archivos",
    )
      .last()
      .height();
    this.numVisibleItems = Math.ceil(
      (scrollHeight / this.tileItem_size[tipoArchivos].height) * numItemPerRow,
    );

    currentIndx =
      currentIndx - this.numVisibleItems >= data.length
        ? currentIndx - this.numVisibleItems
        : currentIndx;

    if (currentIndx * numItemPerRow !== this.state.start) {
      currentIndx = currentIndx * numItemPerRow;
      this.setState({
        start: currentIndx,
        end:
          currentIndx + this.numVisibleItems >= data.length
            ? data.length - 1
            : currentIndx + this.numVisibleItems,
      });
    }
  }

  itemRender() {
    let { data, start, end } = this.state;
    let { tipoArchivos, numItemPerRow, archivoUnico } = this.props;

    if (archivoUnico) {
      return (
        <div
          className="tileItem"
          style={{
            height:
              this.tileItem_size[tipoArchivos].height -
              this.tileItem_size[tipoArchivos].padding,
          }}
        >
          {this.template_tile_documento(data[0])}
        </div>
      );
    }

    if (data.length === 0) {
      return null;
    }

    let scrollHeight = $(
      "#adjuntarArchivos_container_personal .tileContainer_archivos",
    )
      .last()
      .height();
    this.numVisibleItems = Math.ceil(
      (scrollHeight / this.tileItem_size[tipoArchivos].height) * numItemPerRow,
    );

    if (end == null) {
      end = this.numVisibleItems;
    }

    let result = [];
    data.map((itemData, i) => {
      let rowActual = Math.floor(i / numItemPerRow);
      let indexItemRow = Math.abs(
        (rowActual + 1) * numItemPerRow - i - numItemPerRow,
      );

      if (itemData != null) {
        result.push(
          <div
            key={i}
            style={{
              height: this.tileItem_size[tipoArchivos].height,
              width: this.tileItem_size[tipoArchivos].width,
              paddingLeft:
                indexItemRow == 0 && tipoArchivos !== "fotosPequeñas" ? 15 : 0,
              position: "absolute",
              top: rowActual * this.tileItem_size[tipoArchivos].height + "px",
              left: (100 / numItemPerRow) * indexItemRow + "%",
            }}
          >
            <div
              className="tileItem"
              style={{
                height:
                  this.tileItem_size[tipoArchivos].height -
                  this.tileItem_size[tipoArchivos].padding,
              }}
            >
              {this.template_tile_documento(itemData)}
            </div>
          </div>,
        );
      }
    });
    return result;
  }

  template_tile_documento(itemData) {
    let { isRemoveAllowed, isEditAllowed, tipoArchivos } = this.props;
    let _this = this,
      toolbar = (
        <Toolbar elementAttr={this.template_tile_documento_elemAttr}>
          <ToolbarItem
            location="after"
            visible={!itemData.requerido}
            render={(e) => _this.btnDownloadDocument_template(e, itemData)}
          />
          <ToolbarItem
            location="after"
            visible={isEditAllowed != null ? isEditAllowed : false}
            render={(e) => _this.btnEditDocument_template(e, itemData)}
          />
          <ToolbarItem
            location="after"
            visible={isRemoveAllowed != null ? isRemoveAllowed : true}
            render={(e) => _this.btnRemoveDocument_template(e, itemData)}
          />
        </Toolbar>
      ),
      fileName = (
        <div
          id={"filename_" + itemData.id}
          className="archivoAdjunto_contenedorNombre"
          style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          onMouseEnter={(e) => this.tile_documento_onMouseEnter(e, itemData)}
          onMouseLeave={(e) => this.tile_documento_onMouseLeave(e, itemData)}
        >
          {itemData.nombre}
        </div>
      ),
      tooltip = (
        <Tooltip
          deferRendering={false}
          elementAttr={{ id: "tooltip_" + itemData.id }}
          target={"#filename_" + itemData.id}
          position="bottom"
          closeOnOutsideClick={false}
        >
          {itemData.nombre}
        </Tooltip>
      );

    function _onClick(idDocumento, _this) {
      let { archivoUnico } = _this.props;
      if (
        itemData.extension &&
        (itemData.extension.split("/")[0] === "image" ||
          itemData.extension.split("/")[1] === "pdf")
      ) {
        if (idDocumento != null && itemData.documento == null) {
          _this.setState({
            popup_galeriaDocumentos_visible: true,
          });

          let dataSource =
            _this.props.tablaCargaDocumentos === "tblDocumentoPrenda"
              ? "datasource_tblDocumentoPrenda"
              : "datasource_tblDocumento";
          _this[dataSource]
            .store()
            .byKey(itemData.idDocumento)
            .done(function (imagen) {
              let img = archivoUnico ? imagen[0] : imagen;

              _this.setState({
                dxGallery_items: [img],
              });
            });
        } else {
          _this.setState({
            popup_galeriaDocumentos_visible: true,
          });

          setTimeout(() => {
            _this.setState({
              dxGallery_items: [itemData],
            });
          }, 50);
        }
      }
    }

    if (tipoArchivos === "documentos") {
      return (
        <Fragment>
          <div
            style={{ overflow: "hidden" }}
            className="d-flex flex-row he-100 listTipoDocumento_item align-items-center ml-3 pl-2 mr-3 pr-2"
            onClick={() => {
              _onClick(itemData.idDocumento, _this);
            }}
          >
            <div className="d-flex flex-65px itemIcon">
              {" "}
              <div
                className={
                  "font-size-max  he-100 " +
                  itemData.icon +
                  " " +
                  itemData.colorIcon
                }
              />
            </div>
            <div className="d-flex flex-5px" />
            <div
              className="d-flex flex-1 folderTitle_documentos"
              style={{ overflow: "hidden" }}
            >
              <div
                style={{ fontWeight: 500 }}
                className="d-flex font-size-md w-100 align-items-center text-dark"
              >
                {fileName}
              </div>
              <div className="d-flex text-secondary font-size-xxxs align-items-center">
                {itemData.subTitle != null
                  ? itemData.subTitle
                  : itemData.fecha
                    ? formatDate(itemData.fecha)
                    : ""}
              </div>
              {itemData.subTitle2 && (
                <div className="d-flex text-secondary font-size-xxxs align-items-center">
                  {itemData.subTitle2}
                </div>
              )}
            </div>
          </div>
          {tooltip}
          {toolbar}
        </Fragment>
      );
    } else if (
      tipoArchivos === "fotos" ||
      tipoArchivos === "fotosPequeñas" ||
      tipoArchivos == null
    ) {
      if (itemData.extension.split("/")[0] === "image") {
        return (
          <Fragment>
            <img
              className="image"
              style={{
                backgroundImage:
                  "url(data:" +
                  itemData.extension +
                  ";base64," +
                  itemData.documento +
                  ")",
              }}
              onClick={() => {
                _onClick(itemData.idDocumento, _this);
              }}
            />
            {tooltip}
            {toolbar}
            {fileName}
          </Fragment>
        );
      } else if (itemData.extension.split("/")[1] === "pdf") {
        return (
          <Fragment>
            <div
              className={
                "container_spanCentrado  dx-icon-exportpdf font-size-max"
              }
              style={this.template_tile_documento_pdf}
              onClick={() => {
                _onClick(itemData.idDocumento, _this);
              }}
            />
            {tooltip}
            {toolbar}
            {tipoArchivos === "fotosPequeñas" ? "" : fileName}
          </Fragment>
        );
      } else {
        let icon;
        if (itemData.extension.split("/")[0] === "video") {
          icon = "dx-icon-video font-size-max";
        } else if (
          itemData.extension.includes("excel") ||
          itemData.extension.includes("spreadsheetml")
        ) {
          icon = "dx-icon-exportxlsx font-size-max";
        } else {
          icon = "dx-icon-doc font-size-max";
        }

        return (
          <Fragment>
            <div
              style={this.template_tile_documento_other}
              className={"container_spanCentrado " + icon}
              onClick={this.tile_documento_alertTipoDoc}
            ></div>
            {tooltip}
            {toolbar}
            {fileName}
          </Fragment>
        );
      }
    }
  }

  dxGallery_itemRender(itemData) {
    if (itemData.extension.split("/")[0] === "image") {
      return (
        <img
          className="image"
          style={{
            backgroundImage:
              "url(data:" +
              itemData.extension +
              ";base64," +
              itemData.documento,
          }}
        />
      );
    } else if (itemData.extension.split("/")[1] === "pdf") {
      return (
        <object
          toolbar="0"
          width="100%"
          height="100%"
          data={"data:" + itemData.extension + ";base64," + itemData.documento}
        />
      );
    }
  }
  //#endregion

  componentDidUpdate(prevProps) {
    let {
      data,
      isMultipleLoadFile,
      isVisibleRequerirDocumento,
      allowedFileExtensions,
      acceptedTypes,
      documentosNGrandParent,
      idPadreSel,
      accionesModificarDatos_fileUploader,
      showLoading_adjuntarArchivos,
      maxSize,
      isUploadAllowed,
    } = this.props;

    if (idPadreSel !== prevProps.idPadreSel) {
      if (this.scrollView.current != null)
        this.scrollView.current.instance.scrollTo(0);
    }

    this.setState({
      maxSize: maxSize,
      isMultipleLoadFile: isMultipleLoadFile,
      isVisibleRequerirDocumento: isVisibleRequerirDocumento,
      allowedFileExtensions: allowedFileExtensions,
      acceptedTypes: acceptedTypes,
      documentosNGrandParent: documentosNGrandParent,
      idPadreSel: idPadreSel,
      data: data,
      accionesModificarDatos: accionesModificarDatos_fileUploader,
      showLoading_adjuntarArchivos:
        showLoading_adjuntarArchivos != null
          ? showLoading_adjuntarArchivos
          : this.state.showLoading_adjuntarArchivos,
      isUploadAllowed: isUploadAllowed == undefined ? true : isUploadAllowed,
    });
  }

  componentDidMount() {
    let {
      data,
      isMultipleLoadFile,
      isVisibleRequerirDocumento,
      allowedFileExtensions,
      acceptedTypes,
      documentosNGrandParent,
      idPadreSel,
      accionesModificarDatos_fileUploader,
      showLoading_adjuntarArchivos,
      maxSize,
      isUploadAllowed,
    } = this.props;
    this.setState({
      maxSize: maxSize,
      isMultipleLoadFile,
      isMultipleLoadFile,
      isVisibleRequerirDocumento: isVisibleRequerirDocumento,
      allowedFileExtensions: allowedFileExtensions,
      acceptedTypes: acceptedTypes,
      documentosNGrandParent: documentosNGrandParent,
      idPadreSel: idPadreSel,
      data: data,
      accionesModificarDatos: accionesModificarDatos_fileUploader,
      showLoading_adjuntarArchivos: showLoading_adjuntarArchivos,
      isUploadAllowed: isUploadAllowed == undefined ? true : isUploadAllowed,
    });
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(AdjuntarArchivos);
