import { connectionConstants } from "../constants";
import { authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const lavanderiasService = {
  load,
};

function load(user) {
  var deferred = $.Deferred();
  dataSource_tblLavanderia.load().done(function (lavs) {
    $.each(lavs, function (index, lav) {
      lav.visiblePages =
        user && user.visiblePages
          ? user.visiblePages
              .filter((x) => lav.visiblePages.includes(x.idFormulario))
              .map((item) => item.idFormulario)
          : [];
    });

    localStorage.setItem("lavanderias", JSON.stringify(lavs));
    deferred.resolve();
  });
  return deferred.promise();
}

const dataSource_tblLavanderia = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.ODATA_URL + `tblLavanderia`,
    key: "idLavanderia",
    paginate: false,
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
  sort: ["denominacion"],
  expand: [
    "tblZonaHoraria($select=GMT), tblMoneda($select=codigo), tblMonedaLocal($select=codigo), tblIdioma($select=idIdioma), tblFormulario($select=idFormulario), tblModulo($select=idModulo)",
  ],
  select: [
    "idLavanderia",
    "denominacion",
    "horarioVerano",
    "gestionaRechazo",
    "direccion",
    "telefono",
    "tipoPedidoHuesped",
    "idCorporacion",
    "idPais",
    "isNum_codPersona_MyUniform",
    "isMyUniformCantidad",
    "enableControlHorario",
    "enableRFID_Personal",
  ],
  postProcess: function (data) {
    return $.map(data, function (lav, i) {
      return {
        idLavanderia: lav.idLavanderia,
        denominacion: lav.denominacion,
        gestionaRechazo: lav.gestionaRechazo,
        direccion: lav.direccion,
        telefono: lav.telefono,
        visibleModulos: $.map(lav.tblModulo, function (mod, i) {
          return mod.idModulo;
        }),
        visiblePages: $.map(lav.tblFormulario, function (frm, i) {
          return frm.idFormulario;
        }),
        GMT: lav.horarioVerano
          ? parseInt(lav.tblZonaHoraria.GMT) + 1
          : lav.tblZonaHoraria.GMT,
        moneda: lav.tblMoneda.codigo,
        monedaLocal: lav.tblMonedaLocal
          ? lav.tblMonedaLocal.codigo
          : lav.tblMoneda.codigo,
        tipoPedidoHuesped: lav.tipoPedidoHuesped,
        idCorporacion: lav.idCorporacion,
        idPais: lav.idPais,
        isNum_codPersona_MyUniform: lav.isNum_codPersona_MyUniform,
        isMyUniformCantidad: lav.isMyUniformCantidad,
        enableControlHorario: lav.enableControlHorario,
        enableRFID_Personal: lav.enableRFID_Personal,
      };
    });
  },
});
