import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../constants";
import { errorHandler, authHeader, getTrad } from "../../helpers";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import $ from "jquery";

import Box, { Item as ItemBox } from "devextreme-react/box";
import List from "devextreme-react/list";
import SelectBox from "devextreme-react/select-box";
import { TagBox } from "devextreme-react/tag-box";
import {
  DropDownBox,
  Button as DropDownBoxButton,
  DropDownOptions,
} from "devextreme-react/drop-down-box";
import { Button } from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import query from "devextreme/data/query";

//Css
import "./Css.scss";

class DropDownBox_filtroCategoriasInternas extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categoriasInternas_items: [],
      categoriasInternas_items_filtered: [],
      idCategoriaConvenioSel: null,
      dxList_idsCategoriasInternasSel: [],
      dxTagBox_idsCategoriasInternasSel: [],
      opened: false,
      dxList_noItems: false,
      dxCheckBox_seleccionarTodasCategoriasConvenio_value: false,
    };

    this.dxList_categoriasInternas_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblCategoriaConvenio_onLoading =
      this.datasource_tblCategoriaConvenio_onLoading.bind(this);
    this.datasource_tblCategoriaIntern_onLoading =
      this.datasource_tblCategoriaIntern_onLoading.bind(this);

    this.dxDropDownBox_contentRender =
      this.dxDropDownBox_contentRender.bind(this);
    this.dxDropDownBox_onOpened = this.dxDropDownBox_onOpened.bind(this);
    this.dxSelectBox_categoriaConvenio_onValueChanged =
      this.dxSelectBox_categoriaConvenio_onValueChanged.bind(this);
    this.dxList_categoriasInternas_onOptionChanged =
      this.dxList_categoriasInternas_onOptionChanged.bind(this);

    this.dxDropDownBox_contentRender_fieldRender =
      this.dxDropDownBox_contentRender_fieldRender.bind(this);
    this.dxTagBox_onValueChanged = this.dxTagBox_onValueChanged.bind(this);

    this.dxButton_cancelar_onClick = this.dxButton_cancelar_onClick.bind(this);
    this.dxButton_aceptar_onClick = this.dxButton_aceptar_onClick.bind(this);

    this.getTrad = this.getTrad.bind(this);
    this.reload_data = this.reload_data.bind(this);
  }

  get dxList_categoriasInternas() {
    return this.dxList_categoriasInternas_REF.current.instance;
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //#region DATASOURCES
  datasource_tblCategoriaConvenio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaConvenio",
      key: "idCategoriaConvenio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaConvenio_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCategoriaConvenio_onLoading(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    expand: ["tblCategoriaInterna($top=1;$select=idCategoriaInterna)"],
    postProcess: (data) => {
      return this.atasource_tblCategoriaConvenio_postProcess(data);
    },
  });

  datasource_tblCategoriaConvenio_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCategoriaConvenio_onLoading(loadOptions) {
    let { idPaisSel, isOficina_checked } = this.props;
    let idPaisSel_filter =
      idPaisSel != null ? ["idPais", "=", idPaisSel] : ["1 eq 1"];
    let isOficina_checked_filter =
      isOficina_checked != null ? isOficina_checked : false;

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        idPaisSel_filter,
        "and",
        ["isOficina", "=", isOficina_checked_filter],
      ];
    else
      loadOptions.filter = [
        idPaisSel_filter,
        "and",
        ["isOficina", "=", isOficina_checked_filter],
      ];
  }

  atasource_tblCategoriaConvenio_postProcess(data) {
    $.each(data, function (index, item) {
      item.denominacion = item.denominacion.toUpperCase();
    });
    return data;
  }

  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCategoriaIntern_onLoading(request);
      },
      version: 4,
    }),
    expand: ["idCategoriaConvenioNavigation($select=denominacion)"],
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCategoriaIntern_onLoading(loadOptions) {
    let { idPaisSel, isOficina_checked } = this.props;

    let idPaisSel_filter =
      idPaisSel != null
        ? ["idCategoriaConvenioNavigation/idPais", "=", idPaisSel]
        : ["1 eq 1"];
    let isOficina_checked_filter =
      isOficina_checked != null
        ? ["idCategoriaConvenioNavigation/isOficina", "=", isOficina_checked]
        : ["1 eq 1"];

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        idPaisSel_filter,
        "and",
        isOficina_checked_filter,
      ];
    else
      loadOptions.filter = [idPaisSel_filter, "and", isOficina_checked_filter];
  }

  //#endregion

  render() {
    let { width, stylingMode } = this.props;
    let { opened } = this.state;

    return (
      <Fragment>
        <DropDownBox
          opened={opened}
          deferRendering={false}
          elementAttr={this.dxDropDownBoxButton_elementAttr}
          width={width ? width : "100%"}
          contentRender={this.dxDropDownBox_contentRender}
          fieldRender={this.dxDropDownBox_contentRender_fieldRender}
          onOptionChanged={this.dxDropDownBox_onOpened}
          stylingMode={stylingMode}
        >
          <DropDownBoxButton
            name="DropDownBoxButton_idsCategorias"
            location="after"
            options={this.dxDropDownBoxButton_options}
          />
          <DropDownOptions height={500} closeOnOutsideClick={true} />
        </DropDownBox>
      </Fragment>
    );
  }

  dxDropDownBox_contentRender(e) {
    let {
      idCategoriaConvenioSel,
      dxList_idsCategoriasInternasSel,
      dxList_noItems,
      categoriasInternas_items_filtered,
    } = this.state;
    let {
      idPaisSel,
      noDataText_sinPais,
      hasFiltroConvenio,
      multiSeleccion = true,
      value,
    } = this.props;

    return (
      <Box direction="col" width="100%" height="100%">
        {hasFiltroConvenio && (
          <ItemBox baseSize={45}>
            <SelectBox
              elementAttr={this.dxSelectBox_tblCategoriaConvenio_elementAttr}
              dataSource={this.datasource_tblCategoriaConvenio}
              displayExpr="denominacion"
              valueExpr="idCategoriaConvenio"
              value={idCategoriaConvenioSel}
              placeholder={this.getTrad("todos").toUpperCase()}
              showClearButton={true}
              onValueChanged={this.dxSelectBox_categoriaConvenio_onValueChanged}
            />
          </ItemBox>
        )}
        <ItemBox ratio={1}>
          <List
            ref={this.dxList_categoriasInternas_REF}
            items={categoriasInternas_items_filtered}
            displayExpr="denominacion"
            keyExpr={"idCategoriaInterna"}
            focusStateEnabled={false}
            pageLoadMode="scrollBottom"
            selectionMode={multiSeleccion ? "all" : "single"}
            selectAllMode="page"
            showSelectionControls={multiSeleccion}
            noDataText=" "
            collapsibleGroups={true}
            grouped={idCategoriaConvenioSel == null}
            groupRender={this.dxList_categoriasInternas_groupRender}
            selectedItemKeys={
              value === undefined ? dxList_idsCategoriasInternasSel : value
            }
            onOptionChanged={this.dxList_categoriasInternas_onOptionChanged}
          />
          {dxList_noItems && (
            <div className="dxList_categoriasInternas_noDataText">
              {idPaisSel == -1
                ? noDataText_sinPais
                  ? noDataText_sinPais
                  : "Seleccione un país"
                : this.getTrad("sinDatos")}
            </div>
          )}
        </ItemBox>
        <ItemBox baseSize={15} />
        <ItemBox baseSize={35}>
          <Toolbar>
            <ToolbarItem location="center">
              <Button
                width={100}
                text={this.getTrad("aceptar")}
                onClick={this.dxButton_aceptar_onClick}
              />
            </ToolbarItem>
            <ToolbarItem location="center" cssClass={"pr-2"} />
          </Toolbar>
        </ItemBox>
      </Box>
    );
  }

  dxSelectBox_tblCategoriaConvenio_elementAttr = {
    id: "dxSelectBox_tblCategoriaConvenio",
  };

  dxList_categoriasInternas_groupRender(e) {
    return e.key;
  }

  dxDropDownBox_contentRender_fieldRender(e) {
    let { value, customValidation } = this.props;
    let { dxTagBox_idsCategoriasInternasSel } = this.state;
    const { placeholder, multiSeleccion = true } = this.props;

    return multiSeleccion ? (
      <TagBox
        width="100%"
        openOnFieldClick={false}
        value={value === undefined ? dxTagBox_idsCategoriasInternasSel : value}
        dataSource={this.datasource_tblCategoriaInterna}
        displayExpr="denominacion"
        valueExpr="idCategoriaInterna"
        applyValueMode="useButtons"
        showSelectionControls={true}
        showClearButton={true}
        placeholder={placeholder ?? this.getTrad("todasCategorias")}
        onValueChanged={this.dxTagBox_onValueChanged}
      >
        {customValidation != null && customValidation}
      </TagBox>
    ) : (
      <SelectBox
        value={
          value === undefined
            ? dxTagBox_idsCategoriasInternasSel?.at(0)
            : value?.at(0)
        }
        readOnly
        dataSource={this.datasource_tblCategoriaInterna}
        displayExpr="denominacion"
        valueExpr="idCategoriaInterna"
      >
        {customValidation != null && customValidation}
      </SelectBox>
    );
  }

  dxDropDownBoxButton_elementAttr = {
    id: "dxDropDownBox_filtroCategoriasInternas",
  };

  dxDropDownBoxButton_options = {
    height: "100%",
    icon: "spindown",
    type: "default",
    onClick: () => {
      let { opened } = this.state;
      this.setState({ opened: !opened });
    },
  };

  dxDropDownBox_onOpened(e) {
    if (e.name === "opened") {
      this.setState({ opened: e.value });
    }
  }

  dxTagBox_onValueChanged(e) {
    let items_catInterna_totales =
      this.datasource_tblCategoriaInterna.items().length;

    const { multiSeleccion = true } = this.props;
    let value = multiSeleccion ? e.value : [e.value.at(-1)];

    this.setState(
      {
        dxTagBox_idsCategoriasInternasSel: value,
        dxList_idsCategoriasInternasSel: value,
        dxCheckBox_seleccionarTodasCategoriasConvenio_value:
          items_catInterna_totales == e.value.length
            ? true
            : 0 == e.value.length
              ? false
              : null,
      },
      () => {
        this.props.onValueChanged(value);
      },
    );
  }

  dxSelectBox_categoriaConvenio_onValueChanged(e) {
    let categoriasInternas_items = [...this.state.categoriasInternas_items];
    let { idPaisSel, isOficina_checked } = this.props;

    let dxList_noItems;
    let categoriasInternas_items_filtered;

    let dxList_idsCategoriasInternasSel =
      this.state.dxList_idsCategoriasInternasSel == null
        ? []
        : [...this.state.dxList_idsCategoriasInternasSel];

    if (e.value != null) {
      categoriasInternas_items_filtered = $.grep(
        categoriasInternas_items,
        function (item) {
          return (
            idPaisSel > 0 &&
            isOficina_checked != null &&
            item.idCategoriaConvenio == e.value
          );
        },
      );

      dxList_idsCategoriasInternasSel = $.grep(
        dxList_idsCategoriasInternasSel,
        function (item) {
          return (
            $.inArray(
              item,
              categoriasInternas_items_filtered.map(
                (item) => item.idCategoriaInterna,
              ),
            ) > -1
          );
        },
      );

      dxList_noItems = categoriasInternas_items_filtered.length == 0;
    } else {
      categoriasInternas_items_filtered = $.grep(
        categoriasInternas_items,
        function (item) {
          return idPaisSel > 0 && isOficina_checked != null;
        },
      );

      categoriasInternas_items_filtered = query(
        categoriasInternas_items_filtered,
      )
        .groupBy(function (dataItem) {
          return dataItem.idCategoriaConvenioNavigation.denominacion;
        })
        .toArray();
      dxList_noItems = categoriasInternas_items_filtered.length == 0;
    }

    dxList_idsCategoriasInternasSel = [];

    const { multiSeleccion = true } = this.props;
    let value = multiSeleccion
      ? dxList_idsCategoriasInternasSel
      : [dxList_idsCategoriasInternasSel.at(-1)];

    this.setState(
      {
        idCategoriaConvenioSel: e.value,
        categoriasInternas_items_filtered: categoriasInternas_items_filtered,
        dxList_noItems: dxList_noItems,
        dxList_idsCategoriasInternasSel: value,
        dxTagBox_idsCategoriasInternasSel: value,
      },
      () => {
        this.props.onValueChanged(value);
      },
    );
  }

  dxList_categoriasInternas_onOptionChanged(e) {
    if (e.name === "selectedItemKeys") {
      let idsCategorias;

      idsCategorias = e.value;

      const { multiSeleccion = true } = this.props;
      let value = multiSeleccion ? idsCategorias : [idsCategorias.at(-1)];

      this.setState(
        {
          dxTagBox_idsCategoriasInternasSel: value,
          dxList_idsCategoriasInternasSel: value,
        },
        () => {
          this.props.onValueChanged(value);
        },
      );
    }
  }

  dxButton_aceptar_onClick(e) {
    this.setState({
      opened: false,
    });
  }

  dxButton_cancelar_onClick(e) {
    let { dxTagBox_idsCategoriasInternasSel } = this.state;
    let items_catInterna_totales =
      this.datasource_tblCategoriaInterna.items().length;

    this.setState({
      opened: false,
      dxList_idsCategoriasInternasSel: dxTagBox_idsCategoriasInternasSel,
      dxCheckBox_seleccionarTodasCategoriasConvenio_value:
        items_catInterna_totales == dxTagBox_idsCategoriasInternasSel.length
          ? true
          : 0 == dxTagBox_idsCategoriasInternasSel.length
            ? false
            : null,
    });
  }

  reload_data(_this) {
    $.when(
      this.datasource_tblCategoriaConvenio.reload(),
      this.datasource_tblCategoriaInterna.reload(),
    ).then(function (categoriasConvenio_items, categoriasInternas_items) {
      let idCategoriaConvenioSel =
        categoriasConvenio_items[0].length > 0 ? null : -1;
      let { idPaisSel, isOficina_checked, value } = _this.props;
      let { opened } = _this.state;
      let dxList_noItems;
      let categoriasInternas_items_filtered;

      if (idCategoriaConvenioSel != null) {
        categoriasInternas_items_filtered = $.grep(
          categoriasInternas_items[0],
          function (item) {
            return (
              idPaisSel > 0 &&
              isOficina_checked != null &&
              (idCategoriaConvenioSel == null ||
                item.idCategoriaConvenio == idCategoriaConvenioSel)
            );
          },
        );

        dxList_noItems = categoriasInternas_items_filtered.length == 0;
      } else {
        categoriasInternas_items_filtered = $.grep(
          categoriasInternas_items[0],
          function (item) {
            return (
              idPaisSel > 0 &&
              isOficina_checked != null &&
              (idCategoriaConvenioSel == null ||
                item.idCategoriaConvenio == idCategoriaConvenioSel)
            );
          },
        );

        categoriasInternas_items_filtered = query(
          categoriasInternas_items_filtered,
        )
          .groupBy(function (dataItem) {
            return dataItem.idCategoriaConvenioNavigation.denominacion;
          })
          .toArray();
        dxList_noItems = categoriasInternas_items_filtered.length == 0;
      }

      let idsCategoriaInterna = $.map(
        $.extend(true, [], categoriasInternas_items[0]),
        function (item) {
          return item.idCategoriaInterna;
        },
      );
      _this.setState({
        idCategoriaConvenioSel: idCategoriaConvenioSel,
        categoriasInternas_items: categoriasInternas_items[0],
        categoriasInternas_items_filtered: categoriasInternas_items_filtered,
        dxList_noItems: dxList_noItems,
        opened: false,
        dxList_idsCategoriasInternasSel: value ? value : [],
        dxTagBox_idsCategoriasInternasSel: value ? value : [],
      });
    });
  }

  componentDidMount() {
    this.reload_data(this);
  }

  componentDidUpdate(prevProps) {
    let { idPaisSel, isOficina_checked, opened } = this.props;

    if (
      prevProps.idPaisSel !== idPaisSel ||
      prevProps.isOficina_checked !== isOficina_checked
    ) {
      this.reload_data(this);
    }
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(DropDownBox_filtroCategoriasInternas);
