import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  tooltipControl_creacion,
  convertClienteUtcToLavanderiaUtc,
  formatDateTime_parameter,
  get_isMovil,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import ScrollView from "devextreme-react/scroll-view";
import DataGrid, {
  Column,
  FilterRow,
  KeyboardNavigation,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import { Row, Col } from "reactstrap";

import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";

//CUSTOM COMPONENTS
import {
  editCellComponent,
  progressBarCellComponent,
  elemTransCellComponent,
} from "../../../components/DataGrid/Cells";

//Css
import "./Css.scss";

class Revisiones extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revisionSel: null,
      popup_repartosOffice_visible: false,
      repartoOfficeSel: null,
    };

    this.data = {
      porcentajeFilter: 0.9,
    };

    // REFERENCIAS
    this.dxDataGrid_gridRepartosOffice_REF = React.createRef();
    this.dxDataGrid_gridPrendaNRepartoOffice_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblRevision_beforeSend =
      this.datasource_tblRevision_beforeSend.bind(this);
    this.datasource_tblPrendaNRevision_beforeSend =
      this.datasource_tblPrendaNRevision_beforeSend.bind(this);
    this.datasource_tblPrendaNRevision_postProcess =
      this.datasource_tblPrendaNRevision_postProcess.bind(this);
    this.datasource_tblRepartoOffice_beforeSend =
      this.datasource_tblRepartoOffice_beforeSend.bind(this);
    this.datasource_tblRepartoOffice_postProcess =
      this.datasource_tblRepartoOffice_postProcess.bind(this);

    //GRID PRENDAS REVISIÓN
    this.onToolbarPreparing_gridPrendasNRevision =
      this.onToolbarPreparing_gridPrendasNRevision.bind(this);

    //GRID REVISIONES
    this.onSelectionChanged_gridRevisiones =
      this.onSelectionChanged_gridRevisiones.bind(this);
    this.onEditingStart_gridRevisiones =
      this.onEditingStart_gridRevisiones.bind(this);
    this.onToolbarPreparing_gridRevisiones =
      this.onToolbarPreparing_gridRevisiones.bind(this);

    //POPUP REPARTOS OFFICE
    this.onShowing_popup_repartosOffice =
      this.onShowing_popup_repartosOffice.bind(this);
    this.onHiding_popup_repartosOffice =
      this.onHiding_popup_repartosOffice.bind(this);

    //POPUP PRENDAS REPARTOS MÓVIL
    this.dxTabPanel_onContentReady_tabPanelPrincipal =
      this.dxTabPanel_onContentReady_tabPanelPrincipal.bind(this);
    this.dxTabPanel_onSelectionChanged_tabPanelPrincipal =
      this.dxTabPanel_onSelectionChanged_tabPanelPrincipal.bind(this);
    this.onContentReady_dxTabPanel_repartosOffice_revisiones =
      this.onContentReady_dxTabPanel_repartosOffice_revisiones.bind(this);
    this.onSelectionChanged_dxTabPanel_repartosOffice_revisiones =
      this.onSelectionChanged_dxTabPanel_repartosOffice_revisiones.bind(this);

    //GRID REPARTOS OFFICE
    this.onContentReady_gridRepartosOffice =
      this.onContentReady_gridRepartosOffice.bind(this);
    this.onSelectionChanged_gridRepartosOffice =
      this.onSelectionChanged_gridRepartosOffice.bind(this);
    this.onToolbarPreparing_gridRepartosOffice =
      this.onToolbarPreparing_gridRepartosOffice.bind(this);
    this.nuevoReparto = this.nuevoReparto.bind(this);
    this.render_gridRepartosOffice = this.render_gridRepartosOffice.bind(this);

    //GRID PRENDAS REPARTO OFFICE

    this.onEditorPreparing_gridPrendaNRepartoOffice =
      this.onEditorPreparing_gridPrendaNRepartoOffice.bind(this);
    this.onToolbarPreparing_gridPrendaNRepartoOffice =
      this.onToolbarPreparing_gridPrendaNRepartoOffice.bind(this);
    this.onClick_dxButton_guardar_repartosOffice =
      this.onClick_dxButton_guardar_repartosOffice.bind(this);
    this.guardarRepartoOffice = this.guardarRepartoOffice.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  //REFERENCIAS
  get dxDataGrid_tblRepartoOffice() {
    return this.dxDataGrid_gridRepartosOffice_REF.current.instance;
  }

  get dxDataGrid_tblPrendaNRepartoOffice() {
    return this.dxDataGrid_gridPrendaNRepartoOffice_REF.current.instance;
  }

  //#region DataSources
  datasource_tblRevision = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRevision",
      key: "idRevision",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRevision_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_tblRevision_onLoaded(data);
      },
      version: 4,
    }),
    select: [
      "idRevision",
      "fecha",
      "idEstadoOffice",
      "idAlmacen",
      "porcentaje",
    ],
    expand: [
      "tblAlmacen($select=denominacion, idEntidad)",
      "tblEstadoOffice($select=denominacion)",
    ],
  });
  datasource_tblRevision_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }
  datasource_tblRevision_onLoaded(data) {
    this.loadPanel_hide();
  }

  datasource_tblPrendaNRevision = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaNRevision",
      key: ["idRevision", "idPrenda"],
      keyType: {
        idRevision: "Int32",
        idPrenda: "Int32",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPrendaNRevision_beforeSend(request);
      },
      version: 4,
    }),
    expand: [
      "tblPrenda($select=codigoPrenda,denominacion,udsXBacOffice;$expand=tblColorTapa($select=codigoHexadecimal),tblElemTransOffice($select=codigo,denominacion))",
    ],
    filter: ["pedido", ">", 0],
    postProcess: (data) => {
      return this.datasource_tblPrendaNRevision_postProcess(data);
    },
  });
  datasource_tblPrendaNRevision_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { revisionSel } = this.state;
    request.params.idRevision = revisionSel ? revisionSel.idRevision : -1;
  }
  datasource_tblPrendaNRevision_postProcess(data) {
    $.each(data, function (index, item) {
      item.pedido = item.pedido / item.tblPrenda.udsXBacOffice;
      item.stock = item.stock / item.tblPrenda.udsXBacOffice;
      item.repartido = item.repartido / item.tblPrenda.udsXBacOffice;
    });
    return data;
  }

  datasource_tblRepartoOffice = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRepartoOffice",
      key: "idRepartoOffice",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRepartoOffice_beforeSend(request);
      },
      version: 4,
      onInserted: (values, key) => {
        this.datasource_tblRepartoOffice_onInserted(values, key);
      },
    }),
    select: ["idRepartoOffice", "fecha", "idEntidad"],
    expand: [
      "tblPrendaNRepartoOffice($expand=tblPrenda($select=codigoPrenda,denominacion,udsXBacOffice;$expand=tblColorTapa($select=codigoHexadecimal),tblElemTransOffice($select=codigo,denominacion)))",
    ],
    postProcess: (data) => {
      return this.datasource_tblRepartoOffice_postProcess(data);
    },
  });
  datasource_tblRepartoOffice_beforeSend(request) {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      let { revisionSel } = this.state;
      request.params.idRevision = revisionSel ? revisionSel.idRevision : -1;
    }
  }
  datasource_tblRepartoOffice_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  }
  datasource_tblRepartoOffice_postProcess(data) {
    $.each(data, function (index, rep) {
      $.each(rep.tblPrendaNRepartoOffice, function (index, prend) {
        prend.cantidad = prend.cantidad / prend.tblPrenda.udsXBacOffice;
        prend.pendientes = prend.pendiente / prend.tblPrenda.udsXBacOffice;
      });
    });
    return data;
  }
  //#endregion

  cargaDatos() {
    let _this = this;
    _this.loadPanel_show();

    this.datasource_tblRevision.reload();
  }

  cargaDatos_revision() {
    this.datasource_tblRepartoOffice.reload();
    this.datasource_tblPrendaNRevision.reload();
  }

  //#region meter widgets en funciones
  render_gridRepartosOffice = () => {
    return (
      <DataGrid
        //Datos
        ref={this.dxDataGrid_gridRepartosOffice_REF}
        dataSource={this.datasource_tblRepartoOffice}
        //Propiedades
        columnsAutoWidth={true}
        height={"100%"}
        hoverStateEnabled={true}
        selection={this.grid_Singleselection}
        selectedRowKeys={
          this.state.repartoOfficeSel &&
          this.state.repartoOfficeSel.idRepartoOffice
            ? [this.state.repartoOfficeSel.idRepartoOffice]
            : []
        }
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onContentReady={this.onContentReady_gridRepartosOffice}
        onSelectionChanged={this.onSelectionChanged_gridRepartosOffice}
        onRowPrepared={this.onRowPrepared_pointer}
        onToolbarPreparing={this.onToolbarPreparing_gridRepartosOffice}
      >
        <HeaderFilter visible={true} />
        <FilterRow visible={false} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={this.grid_allowedPageSizes}
          showInfo={true}
        />
        <Paging defaultPageSize={50} />
        <Column
          dataField="fecha"
          caption={getTrad("fecha")}
          alignment="center"
          format={this.datetime_format}
          sortOrder={"desc"}
        />
      </DataGrid>
    );
  };

  render_dxDataGrid_revision = () => {
    let { resolucion } = this.props;
    return (
      <Fragment>
        <DataGrid
          //Datos
          elementAttr={{ id: "dxDataGrid_revision" }}
          dataSource={this.datasource_tblRevision}
          //Propiedades
          columnsAutoWidth={true}
          height={"100%"}
          width={"100%"}
          hoverStateEnabled={true}
          selection={this.grid_Singleselection}
          selectedRowKeys={
            this.state.revisionSel && this.state.revisionSel.idRevision
              ? [this.state.revisionSel.idRevision]
              : []
          }
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          onSelectionChanged={this.onSelectionChanged_gridRevisiones}
          onRowPrepared={this.onRowPrepared_pointer}
          onEditingStart={this.onEditingStart_gridRevisiones}
          onEditorPreparing={this.onEditorPreparing_gridRevisiones}
          onToolbarPreparing={this.onToolbarPreparing_gridRevisiones}
        >
          <SearchPanel
            visible={true}
            width={!get_isMovil(resolucion) ? 200 : 120}
          />
          <Scrolling mode={get_isMovil(resolucion) ? "infinite" : "standard"} />
          <HeaderFilter visible={true} />
          <FilterRow visible={!get_isMovil(resolucion)} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={this.grid_allowedPageSizes}
            showInfo={true}
          />
          <Paging defaultPageSize={50} enabled={!get_isMovil(resolucion)} />
          <Column
            caption={" "}
            width={get_isMovil(resolucion) ? 20 : 25}
            visible={resolucion !== "xs"}
            alignment="center"
            cssClass="p-0"
            cellComponent={editCellComponent}
          />
          <Column
            dataField="fecha"
            caption={getTrad("fecha")}
            dataType="datetime"
            width={get_isMovil(resolucion) ? 110 : 135}
            alignment="center"
            allowFiltering={false}
            allowHeaderFiltering={true}
            format={this.datetime_format}
            sortOrder={"desc"}
          />
          <Column
            dataField="tblAlmacen.denominacion"
            dataType="string"
            allowHeaderFiltering={false}
            caption={getTrad("almacen")}
            cssClass="white-space-normal"
          />
          <Column
            dataField="porcentaje"
            dataType="number"
            caption={getTrad("porcentaje")}
            width={get_isMovil(resolucion) ? 70 : 150}
            alignment="center"
            cssClass="p-0"
            allowSearch={false}
            allowHeaderFiltering={false}
            cellComponent={progressBarCellComponent}
            filterOperations={["=", "<>", "<", ">", "<=", ">="]}
            defaultSelectedFilterOperation="<="
            defaultFilterValue={this.data.porcentajeFilter}
          />
        </DataGrid>
      </Fragment>
    );
  };

  render_dxDataGrid_gridPrendaNRepartoOffice = () => {
    let { resolucion } = this.props;
    let { repartoOfficeSel } = this.state;
    return (
      <DataGrid
        ref={this.dxDataGrid_gridPrendaNRepartoOffice_REF}
        //Datos
        dataSource={
          repartoOfficeSel ? repartoOfficeSel.tblPrendaNRepartoOffice : []
        }
        //Propiedades
        columnsAutoWidth={true}
        height={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onEditorPreparing={this.onEditorPreparing_gridPrendaNRepartoOffice}
        onToolbarPreparing={this.onToolbarPreparing_gridPrendaNRepartoOffice}
      >
        <KeyboardNavigation
          enterKeyAction="moveFocus"
          enterKeyDirection="row"
          editOnKeyPress={true}
        />
        <Editing
          mode="cell"
          allowUpdating={repartoOfficeSel && !repartoOfficeSel.idRepartoOffice}
          allowDeleting={false}
          useIcons={true}
        />
        <HeaderFilter visible={true} />
        <FilterRow visible={false} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={this.grid_allowedPageSizes}
          showInfo={true}
        />
        <Paging defaultPageSize={50} />
        <Column
          dataField="tblPrenda.codigoPrenda"
          caption={
            get_isMovil(resolucion) ? getTrad("codigo_abr") : getTrad("codigo")
          }
          width={get_isMovil(resolucion) ? "15%" : "12%"}
          alignment="center"
          sortOrder="asc"
          allowHeaderFiltering={false}
          allowEditing={false}
          visible={resolucion !== "xs"}
        />
        <Column
          dataField="tblPrenda.denominacion"
          caption={getTrad("prenda")}
          allowEditing={false}
          cssClass="white-space-normal"
        />
        <Column
          caption={getTrad("elementoTransporte_abr")}
          width={
            resolucion === "xs"
              ? "10%"
              : get_isMovil(resolucion)
                ? "6.5%"
                : "6%"
          }
          alignment="center"
          cssClass="p-0"
          cellComponent={elemTransCellComponent}
        />
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          width={
            resolucion === "xs"
              ? "19%"
              : get_isMovil(resolucion)
                ? "12%"
                : "10%"
          }
          alignment="center"
          allowEditing={true}
          allowFiltering={false}
          cssClass={
            repartoOfficeSel && !repartoOfficeSel.idRepartoOffice
              ? "dx-Cell_Editable"
              : ""
          }
        />
        <Column
          dataField="pendientes"
          caption={resolucion === "xs" ? "Pend." : getTrad("pendientes")}
          width={get_isMovil(resolucion) ? "14%" : "10%"}
          alignment="center"
          allowEditing={false}
          calculateCellValue={this.calculateCellValue_pendientes}
          editorOptions={this.decimal_editorOptions}
          allowFiltering={false}
          visible={repartoOfficeSel && !repartoOfficeSel.idRepartoOffice}
        />
        <Column
          caption={getTrad("total")}
          width={
            resolucion === "xs"
              ? "17%"
              : get_isMovil(resolucion)
                ? "12%"
                : "10%"
          }
          alignment="center"
          allowEditing={false}
          calculateDisplayValue={this.calculateDisplayValue_total}
          editorOptions={this.decimal_editorOptions}
        />
      </DataGrid>
    );
  };

  render_dxDataGrid_gridPrendaNRevision = () => {
    let { resolucion } = this.props;
    let { revisionSel } = this.state;
    return (
      <DataGrid
        //Datos
        elementAttr={{ id: "dxDataGrid_gridPrendaNRevision" }}
        dataSource={this.datasource_tblPrendaNRevision}
        //Propiedades
        columnsAutoWidth={true}
        height={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        noDataText={revisionSel ? getTrad("msg_sinPrendasNRevision") : null}
        onToolbarPreparing={this.onToolbarPreparing_gridPrendasNRevision}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={this.grid_allowedPageSizes}
          showInfo={true}
        />
        <Paging defaultPageSize={50} />
        <Column
          dataField="tblPrenda.codigoPrenda"
          caption={
            get_isMovil(resolucion) ? getTrad("codigo_abr") : getTrad("codigo")
          }
          width={get_isMovil(resolucion) ? "17.5%" : "15%"}
          visible={resolucion !== "xs"}
          alignment="center"
          sortOrder="asc"
        />
        <Column
          dataField="tblPrenda.denominacion"
          caption={getTrad("prenda")}
          cssClass="white-space-normal"
        />
        <Column
          caption={getTrad("elementoTransporte_abr")}
          width={
            resolucion === "xs"
              ? "9%"
              : get_isMovil(resolucion)
                ? "6.5%"
                : "5.5%"
          }
          alignment="center"
          cssClass="p-0"
          cellComponent={elemTransCellComponent}
        />
        <Column
          dataField="stock"
          caption={getTrad("stock")}
          width={get_isMovil(resolucion) ? "10%" : "8%"}
          alignment="center"
          visible={resolucion !== "xs"}
        />
        <Column
          dataField="pedido"
          caption={getTrad("pedido")}
          width={
            resolucion === "xs" ? "16%" : get_isMovil(resolucion) ? "12%" : "9%"
          }
          alignment="center"
        />
        <Column
          dataField="repartido"
          caption={get_isMovil(resolucion) ? "Repart." : getTrad("repartido")}
          width={resolucion === "xs" ? "16%" : "12%"}
          alignment="center"
        />
      </DataGrid>
    );
  };

  render_dxPopup_repartosOffice = () => {
    let { resolucion } = this.props;
    return (
      <Popup
        title={getTrad("repartosOffice")}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        width="90%"
        maxWidth={1200}
        height={650}
        deferRendering={false}
        visible={this.state.popup_repartosOffice_visible}
        closeOnOutsideClick={false}
        onShowing={this.onShowing_popup_repartosOffice}
        onHiding={this.onHiding_popup_repartosOffice}
      >
        {resolucion === "xs" ? (
          <div>
            <TabPanel
              elementAttr={{ id: "dxTabPanel_revisiones", class: "h3" }}
              height="100%"
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              swipeEnabled={false}
              onContentReady={this.dxTabPanel_onContentReady_tabPanelPrincipal}
              onSelectionChanged={
                this.dxTabPanel_onSelectionChanged_tabPanelPrincipal
              }
            >
              <TabPanelItem title="Prendas revisión" tabIndex={0}>
                {this.render_dxDataGrid_gridPrendaNRevision()}
              </TabPanelItem>
              <TabPanelItem title="Repartos" tabIndex={1}>
                <TabPanel
                  elementAttr={{ id: "dxTabPanel_repartosOffice_revisiones" }}
                  height="100%"
                  width="100%"
                  deferRendering={false}
                  animationEnabled={true}
                  swipeEnabled={false}
                  onContentReady={
                    this.onContentReady_dxTabPanel_repartosOffice_revisiones
                  }
                  onSelectionChanged={
                    this.onSelectionChanged_dxTabPanel_repartosOffice_revisiones
                  }
                >
                  <TabPanelItem tabIndex={0}>
                    {this.render_gridRepartosOffice()}
                  </TabPanelItem>
                  <TabPanelItem tabIndex={1}>
                    {this.render_dxDataGrid_gridPrendaNRepartoOffice()}
                  </TabPanelItem>
                </TabPanel>
              </TabPanelItem>
            </TabPanel>
          </div>
        ) : (
          <div>
            <ScrollView id="dxScrollView" className="formContent">
              <Row className="he-100">
                <Col xs="12" md="3" className="he-100">
                  {this.render_gridRepartosOffice()}
                </Col>
                <Col xs="12" md="9" className="he-100">
                  {this.render_dxDataGrid_gridPrendaNRepartoOffice()}
                </Col>
              </Row>
            </ScrollView>
          </div>
        )}
        <ToolbarItem
          widget="dxButton"
          location="after"
          toolbar="bottom"
          options={{
            visible:
              this.state.repartoOfficeSel !== null &&
              this.state.repartoOfficeSel.idRepartoOffice == null &&
              resolucion === "xs",
            elementAttr: {
              id: "dxButton_guardar_repartosOffice",
            },
            text: getTrad("guardar"),
            type: "success",
            onClick: this.onClick_dxButton_guardar_repartosOffice,
          }}
        />
      </Popup>
    );
  };
  //#endregion

  render() {
    let { resolucion } = this.props;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup className="media-body"
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div
            id="Revisiones"
            className="formContainer he-100 scrollbar-container"
          >
            <ScrollView id="dxScrollView" className="formContent">
              <Row className="he-100 w-100 ml-0">
                {resolucion === "xs" ? (
                  this.render_dxDataGrid_revision()
                ) : (
                  <Fragment>
                    <Col xs="12" md="5" className="he-100 pl-0">
                      {this.render_dxDataGrid_revision()}
                    </Col>
                    <Col xs="12" md="7" className="he-100 pr-0">
                      {this.render_dxDataGrid_gridPrendaNRevision()}
                    </Col>
                  </Fragment>
                )}
              </Row>
            </ScrollView>
            {this.render_dxPopup_repartosOffice()}
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  onClick_dxButton_guardar_repartosOffice(e) {
    this.guardarRepartoOffice();
  }

  //FORMATS
  date_format = "dd/MM/yyyy";
  datetime_format = "dd/MM/yyyy - HH:mm";
  decimal_format = { style: "decimal", maximumFractionDigits: 2 };
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: this.props.lavanderia.moneda,
  };

  //CONFIG GRID
  grid_Singleselection = { mode: "single" };
  grid_allowedPageSizes = [20, 50, 100];
  onRowPrepared_pointer(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  //GRID REVISIONES
  onSelectionChanged_gridRevisiones(e) {
    const data = e.selectedRowsData[0];
    if (data) {
      let revision = $.extend(true, {}, data);
      if (this.props.resolucion === "xs")
        this.setState({
          popup_repartosOffice_visible: true,
          revisionSel: revision,
        });
      else this.setState({ revisionSel: revision });
    } else {
      this.setState({ revisionSel: null });
    }
  }

  onEditingStart_gridRevisiones(e) {
    e.cancel = true;
    let revision = $.extend(true, {}, e.data);
    this.setState({
      popup_repartosOffice_visible: true,
      revisionSel: revision,
    });
  }
  onEditorPreparing_gridRevisiones(e) {
    if (e.parentType === "filterRow") {
      if (e.dataField === "porcentaje") {
        e.editorName = "dxSlider";
        e.editorOptions = {
          min: 0,
          max: 1,
          value: e.value,
          step: 0.05,
          width: "100%",
          onValueChanged: function (data) {
            e.setValue(data.value);

            let textValue = formatNumber(data.value, 0, "percent");
            $(".slider-textValue").text(textValue);
          },
          onInitialized: function (e) {
            let value = e.component.option("value");
            let textValue = formatNumber(value, 0, "percent");
            e.element.append(
              $("<div />").addClass("slider-textValue").text(textValue),
            );
          },
        };
      }
    }
  }

  onToolbarPreparing_gridRevisiones(e) {
    let { resolucion } = this.props;
    let _this = this;
    if (get_isMovil(resolucion)) {
      let filtroValue = this.data.porcentajeFilter;
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxSlider",
          options: {
            elementAttr: { id: "dxSlider_porcentaje" },
            min: 0,
            max: 1,
            value: filtroValue,
            step: 0.05,
            width: resolucion === "xs" ? 125 : 110,
            onValueChanged: function (data) {
              let textValue = formatNumber(data.value, 0, "percent");
              $(".slider-textValue-mobile").text(textValue);
              let dxDataGrid_revision = $("#dxDataGrid_revision").dxDataGrid(
                "instance",
              );

              dxDataGrid_revision.beginUpdate();
              dxDataGrid_revision.clearFilter();
              dxDataGrid_revision.filter(["porcentaje", "<=", data.value]);
              _this.data.porcentajeFilter = data.value;
              dxDataGrid_revision.endUpdate();
            },
          },
        },
        {
          location: "before",
          template: (e) => {
            return $("<div />")
              .addClass("slider-textValue-mobile font-size-lg")
              .text(formatNumber(filtroValue, 0, "percent"));
          },
        },
      );
    }
  }

  gridRevisiones_notas_cellTemplate = (e, itemData) => {
    let text = itemData.text;
    let text_ = text;
    let textLargo = text.length > 20;
    if (textLargo) {
      text_ = text.substr(0, 20) + "...";
    }

    let result = $("<div />").text(text_);

    if (textLargo) {
      result.append($("<div/>").addClass("pointer dx-icon dx-icon-help pl-2"));
      tooltipControl_creacion(result, text);
    }

    return result;
  };

  //GRID PRENDAS REVISION
  onToolbarPreparing_gridPrendasNRevision(e) {
    if (this.props.resolucion !== "xs") {
      let toolbarItems = e.toolbarOptions.items;

      toolbarItems.push({
        location: "before",
        template: function () {
          return $("<div class ='font-size-xs' />").text(
            getTrad("prendasRevision"),
          );
        },
      });
    }
  }

  //POPUP REPARTOS OFFICE
  onShowing_popup_repartosOffice(e) {
    if (this.props.resolucion === "xs") {
      this.dxDataGrid_tblPrendaNRepartoOffice.repaint();
      this.dxDataGrid_tblRepartoOffice.repaint();
      $("#dxDataGrid_gridPrendaNRevision").dxDataGrid("instance").repaint();
    }
  }

  onHiding_popup_repartosOffice(e) {
    this.setState({
      popup_repartosOffice_visible: false,
      repartoOfficeSel: null,
    });

    if (this.props.resolucion === "xs") {
      this.setState({ revisionSel: null });

      $("#dxTabPanel_revisiones")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);
      if (
        $("#dxTabPanel_repartosOffice_revisiones").dxTabPanel("instance") !=
        null
      )
        $("#dxTabPanel_repartosOffice_revisiones")
          .dxTabPanel("instance")
          .option("selectedIndex", 0);
    }
  }

  set_guardarVisible_repartosOffice(e) {
    let is_tabReparto = e.component.option("selectedIndex") === 1,
      is_nuevoReparto =
        this.state.repartoOfficeSel !== null &&
        this.state.repartoOfficeSel.idRepartoOffice == null;

    if ($("#dxButton_guardar_repartosOffice").dxButton("instance") != null)
      $("#dxButton_guardar_repartosOffice")
        .dxButton("instance")
        .option(
          "visible",
          is_tabReparto && is_nuevoReparto && this.props.resolucion === "xs",
        );

    if (!is_tabReparto) {
      $("#dxTabPanel_repartosOffice_revisiones")
        .dxTabPanel("instance")
        .option("selectedIndex", 0);
    }
  }

  dxTabPanel_onContentReady_tabPanelPrincipal(e) {
    let { popup_repartosOffice_visible } = this.state;
    let is_repartosOpen = popup_repartosOffice_visible;

    e.component.option("animationEnabled", false);
    e.component.option("selectedIndex", is_repartosOpen ? 1 : 0);
    setTimeout(() => {
      e.component.option("animationEnabled", true);
    }, 0);
  }

  dxTabPanel_onSelectionChanged_tabPanelPrincipal(e) {
    this.set_guardarVisible_repartosOffice(e);
  }

  onContentReady_dxTabPanel_repartosOffice_revisiones(e) {
    let { repartoOfficeSel, popup_repartosOffice_visible } = this.state;
    let is_repartosOpen = popup_repartosOffice_visible;
    e.component.option("animationEnabled", false);
    e.component.option(
      "selectedIndex",
      is_repartosOpen && repartoOfficeSel !== null ? 1 : 0,
    );
    setTimeout(() => {
      e.component.option("animationEnabled", true);
    }, 0);
  }

  onSelectionChanged_dxTabPanel_repartosOffice_revisiones(e) {
    let item = e.addedItems;
    if (item.length > 0) {
      let dxButton_guardar_repartosOffice = $(
        "#dxButton_guardar_repartosOffice",
      ).dxButton("instance");
      if (item[0].tabIndex === 0) {
        if (dxButton_guardar_repartosOffice != null)
          dxButton_guardar_repartosOffice.option("visible", false);
        this.setState({ repartoOfficeSel: null });
      } else this.set_guardarVisible_repartosOffice(e);
    }
  }

  //GRID REPARTOS OFFICE
  onContentReady_gridRepartosOffice(e) {
    let { revisionSel, popup_repartosOffice_visible } = this.state;
    let { resolucion } = this.props;

    if (resolucion === "xs") {
      if (revisionSel !== null && popup_repartosOffice_visible === false)
        this.setState({ popup_repartosOffice_visible: true });
    }
  }
  onSelectionChanged_gridRepartosOffice(e) {
    const data = e.selectedRowsData[0];
    if (data) {
      let reparto = $.extend(true, {}, data);
      this.setState({ repartoOfficeSel: reparto });
      if (this.props.resolucion === "xs") {
        $("#dxTabPanel_repartosOffice_revisiones")
          .dxTabPanel("instance")
          .option("selectedIndex", 1);
      }
    } else {
      this.setState({ repartoOfficeSel: null });
    }
  }
  onToolbarPreparing_gridRepartosOffice(e) {
    let _this = this;
    let { revisionSel } = this.state;
    let is_incompleto = revisionSel && revisionSel.porcentaje < 1;

    if (this.props.resolucion === "xs")
      e.toolbarOptions.visible = is_incompleto;
    else e.toolbarOptions.visible = true;

    let toolbarItems = e.toolbarOptions.items;
    toolbarItems.push({
      widget: "dxButton",
      options: {
        elementAttr: { id: "dxButton_nuevoReparto_gridRepartosOffice" },
        text: getTrad("nuevoReparto"),
        icon: "add",
        visible: is_incompleto,
        onClick: function (e) {
          _this.dxDataGrid_tblPrendaNRepartoOffice.beginUpdate();
          _this.setState({
            repartoOfficeSel: _this.nuevoReparto(),
          });
          _this.dxDataGrid_tblPrendaNRepartoOffice.refresh();
          _this.dxDataGrid_tblPrendaNRepartoOffice.endUpdate();
          if (
            $("#dxTabPanel_repartosOffice_revisiones").dxTabPanel("instance") !=
            null
          )
            $("#dxTabPanel_repartosOffice_revisiones")
              .dxTabPanel("instance")
              .option("selectedIndex", 1);
        },
      },
      location: "after",
    });
  }

  nuevoReparto() {
    let { revisionSel } = this.state;
    let { lavanderia } = this.props;
    let nuevoReparto = {
      idRevision: revisionSel.idRevision,
      fecha: convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date()),
      repartoExtra: false,
      idEntidad: revisionSel.tblAlmacen.idEntidad,
      idLavanderia: lavanderia.idLavanderia,
    };
    nuevoReparto.tblPrendaNRepartoOffice = $.map(
      this.datasource_tblPrendaNRevision.items(),
      function (item) {
        if (item.pedido - item.repartido > 0) {
          return {
            idPrenda: item.idPrenda,
            cantidad: item.pedido - item.repartido,
            pendientes: 0,
            total: item.pedido - item.repartido,
            tblPrenda: item.tblPrenda,
          };
        }
      },
    );
    return nuevoReparto;
  }

  //GRID PRENDAS DEL REPARTOS OFFICE
  onEditorPreparing_gridPrendaNRepartoOffice(e) {
    if (e.parentType == "dataRow" && e.dataField == "cantidad") {
      e.editorOptions.onFocusOut = function (args) {
        e.component.saveEditData();
      };
      e.editorOptions.valueChangeEvent = "keyup";
      e.editorOptions.onValueChanged = (args) => {
        e.setValue(args.value);
      };
    }
  }
  onToolbarPreparing_gridPrendaNRepartoOffice(e) {
    let _this = this;
    let { repartoOfficeSel } = this.state;
    let toolbarItems = e.toolbarOptions.items;
    let { resolucion } = this.props;

    if (resolucion === "xs") {
      toolbarItems.push({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "chevronleft",
          width: 35,
          type: "normal",
          onClick: function (e) {
            $("#dxTabPanel_repartosOffice_revisiones")
              .dxTabPanel("instance")
              .option("selectedIndex", 0);
          },
        },
      });
    }

    //Nuevo reparto office
    if (repartoOfficeSel && !repartoOfficeSel.idRepartoOffice) {
      $.each(e.toolbarOptions.items, function (_, item) {
        if (item.name === "saveButton") {
          item.visible = false;
        }
      });

      if (resolucion !== "xs") {
        toolbarItems.push({
          location: "before",
          template: function () {
            return $("<div class ='font-size-xs' />").text(
              getTrad("nuevoReparto"),
            );
          },
        });
      }

      toolbarItems.push(
        {
          location: "after",
          widget: "dxDateBox",
          options: {
            type: "datetime",
            pickerType: resolucion === "xs" ? "rollers" : "calendar",
            width: "100%",
            displayFormat: "dd/MM/yyyy - HH:mm",
            value: repartoOfficeSel.fecha,
            onValueChanged: function (e) {
              repartoOfficeSel.fecha = e.value;
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          visible: resolucion !== "xs",
          options: {
            icon: "save",
            text: getTrad("guardar"),
            type: "success",
            showText: "always",
            onClick: function (e) {
              _this.guardarRepartoOffice();
            },
          },
        },
      );
    } else {
      if (resolucion !== "xs") {
        toolbarItems.push({
          location: "before",
          template: function () {
            return $("<div class ='font-size-xs' />").text(
              getTrad("prendasReparto"),
            );
          },
        });
      } else {
        toolbarItems.push({
          location: "after",
          widget: "dxButton",
          options: {
            text: getTrad("nuevoReparto"),
            icon: "add",
            visible:
              (_this.state.revisionSel &&
                _this.state.revisionSel.porcentaje < 1) === true,
            onClick: function (e) {
              _this.dxDataGrid_tblPrendaNRepartoOffice.beginUpdate();
              _this.setState({
                repartoOfficeSel: _this.nuevoReparto(),
              });
              _this.dxDataGrid_tblPrendaNRepartoOffice.refresh();
              _this.dxDataGrid_tblPrendaNRepartoOffice.endUpdate();
              if (
                $("#dxTabPanel_repartosOffice_revisiones").dxTabPanel(
                  "instance",
                ) != null
              )
                $("#dxTabPanel_repartosOffice_revisiones")
                  .dxTabPanel("instance")
                  .option("selectedIndex", 1);
            },
          },
        });
      }
    }
  }
  calculateCellValue_pendientes(rowData) {
    let value = rowData.total - rowData.cantidad;
    return value < 0 ? 0 : value;
  }
  calculateDisplayValue_total(rowData) {
    return rowData.cantidad * rowData.tblPrenda.udsXBacOffice;
  }

  guardarRepartoOffice() {
    let { revisionSel, repartoOfficeSel } = this.state;
    let _this = this;
    let repartoOffice = $.extend(true, {}, repartoOfficeSel);

    this.dxDataGrid_tblPrendaNRepartoOffice.saveEditData().done(function () {
      repartoOffice.fecha = formatDateTime_parameter(repartoOffice.fecha);

      $.each(repartoOffice.tblPrendaNRepartoOffice, function (_, item) {
        let value = item.total - item.cantidad;
        item.pendientes = value < 0 ? 0 : value * item.tblPrenda.udsXBacOffice;
        item.cantidad = item.cantidad * item.tblPrenda.udsXBacOffice;

        delete item.tblPrenda;
        delete item.total;
      });

      _this.datasource_tblRepartoOffice
        .store()
        .insert(repartoOffice)
        .done(function (inserted) {
          _this.cargaDatos_revision();
          _this.datasource_tblRevision.reload();
          _this.datasource_tblRevision
            .store()
            .byKey(revisionSel.idRevision)
            .done(function (revision) {
              revisionSel.porcentaje = revision.porcentaje;
              _this.dxDataGrid_tblRepartoOffice.repaint();
              _this.dxDataGrid_tblRepartoOffice.refresh().done(function () {
                _this.dxDataGrid_tblRepartoOffice.selectRows(
                  [inserted.idRepartoOffice],
                  true,
                );
              });
            });
        });
    });
  }

  //FORMS
  frmLabel_fecha = { text: getTrad("fecha") };

  //EDITORES
  decimal_editorOptions = {
    format: this.decimal_format,
    step: 0,
    min: -999999999,
    max: 999999999,
  };

  currency_editorOptions = {
    format: this.currency_format,
    step: 0,
    min: 0,
    max: 9999.99,
  };

  disabled_editorOptions = {
    disabled: true,
  };

  columnEditorOptions = {
    showClearButton: true,
  };

  dateTime_editorOptions = {
    type: "datetime",
    width: "100%",
    displayFormat: "dd/MM/yyyy - HH:mm",
  };

  time_editorOptions = {
    displayFormat: "HH:mm",
    showAnalogClock: false,
    showDropDownButton: false,
    spellcheck: true,
    type: "time",
    useMaskBehavior: true,
  };

  idMaquina_editorOptions = {
    dataSource: this.datasource_tblMaquina,
    displayExpr: "denominacion",
    valueExpr: "idMaquina",
    searchExpr: ["denominacion", "etiqueta", "numSerie"],
    cleanSearchOnOpening: true,
    searchEnabled: true,
    dropDownOptions: {
      title: getTrad("maquina"),
      closeOnOutsideClick: true,
    },
  };

  estadoMaquina_editorOptions = {
    dataSource: this.enum_estadosMaquina,
    displayExpr: "denominacion",
    valueExpr: "estadoMaquina",
  };

  textArea_editorOptions = {
    height: "75px",
  };

  btnSubmit_buttonOptions = {
    text: getTrad("aceptar"),
    type: "success",
    useSubmitBehavior: true,
  };

  formLabel_invisible = { visible: false };

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos();
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, resolucion } = this.props;

    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.setState({ revisionSel: null });
      this.cargaDatos();
    }

    if (resolucion !== prevProps.resolucion) {
      let dxDataGrid_revision = $("#dxDataGrid_revision").dxDataGrid(
        "instance",
      );
      if (dxDataGrid_revision != null) {
        let combinedFilter = dxDataGrid_revision.getCombinedFilter();
        let filterValue = 0.9;
        if (combinedFilter != null) {
          if (combinedFilter[0] === "porcentaje")
            filterValue = combinedFilter[2];
          else filterValue = combinedFilter[0][2];

          if (this.data.porcentajeFilter !== filterValue) {
            dxDataGrid_revision.clearFilter();

            this.data.porcentajeFilter = filterValue;
          }
        }
      }
    }

    let { revisionSel, repartoOfficeSel } = this.state;
    //La revisión seleccionada es diferente a la anteiror.
    if (
      !revisionSel ||
      !prevState.revisionSel ||
      revisionSel.idRevision !== prevState.revisionSel.idRevision
    ) {
      this.cargaDatos_revision();
      if (resolucion !== "xs") this.dxDataGrid_tblRepartoOffice.repaint();
    }

    if (
      resolucion !== "xs" &&
      (!repartoOfficeSel ||
        !prevState.repartoOfficeSel ||
        repartoOfficeSel.idRepartoOffice !==
          prevState.repartoOfficeSel.idRepartoOffice)
    ) {
      this.dxDataGrid_tblPrendaNRepartoOffice.repaint();
      this.dxDataGrid_tblPrendaNRepartoOffice.endUpdate();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Revisiones);
