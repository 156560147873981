import { connectionConstants } from "../constants";
import {
  authHeader,
  errorHandler,
  history,
  getDatosNavegador,
  formatDateTime_parameter,
  formatTimeOffset,
} from "../helpers";
import { store } from "../config";

import ODataStore from "devextreme/data/odata/store";

export const logUsoService = {
  setLog,
};

function setLog(accion, loginUsername) {
  let idUsuarioOriginal = store.getState().Global.idUsuarioOriginal;
  if (idUsuarioOriginal != null) return;

  if (typeof window.jqBrowser === "undefined") {
    getDatosNavegador();
  }

  var state = store.getState();
  var LAVANDERIA = state.Global.lavanderia;
  var idFormulario = null;

  if (accion === 3) {
    var USER = state.Authentication.user;
    if (!USER) {
      return false;
    }

    var formulario = history.location.pathname.split("/").reverse()[0];
    var frm = USER.visiblePages
      ? USER.visiblePages.filter((x) => x.formulario === formulario)[0]
      : null;

    if (!frm) {
      return false;
    }

    idFormulario = frm.idFormulario;
  }
  let actualDate = formatDateTime_parameter(new Date());
  actualDate = actualDate.replace("Z", ""); // Elimina la Z (último caracter date)
  actualDate += formatTimeOffset(new Date());

  var datos = {
    fecha: actualDate,
    idLavanderia:
      accion === 3 ? (LAVANDERIA ? LAVANDERIA.idLavanderia : null) : null,
    idFormulario: idFormulario,
    idAccion: accion,
    navegador: window.jQBrowser.name,
    navegador_version: window.jQBrowser.version,
    loginUsername: accion === 1 ? loginUsername : null,
  };
  datasource_logAcciones.insert(datos);
}

const datasource_logAcciones = new ODataStore({
  url: connectionConstants.ODATA_URL + "tblLogAcciones",
  errorHandler: function (error) {
    errorHandler(error, null);
  },
  key: "fecha",
  beforeSend: function (request) {
    request.headers = { ...authHeader() };
  },
  version: 4,
});
