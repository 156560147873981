import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  convertClienteUtcToLavanderiaUtc,
  dxMensajePregunta,
} from "../../../helpers";
import { svg_tick } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

export default function cargaJs(COMPONENT, LAVANDERIA, USER, IDIOMA) {
  var deferred = $.Deferred();

  var fechaSel = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date());
  var array_tecnico = [];

  //#region Datasource

  var datasource_InsertarPreventivo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "monitorSAT_spSelectPreventivoDiario",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.fecha = formatDate_parameter(fechaSel);
        request.params.personas = array_tecnico.toString().replace(/,/g, "|");

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.lng = IDIOMA.codigo;
      },
      version: 4,
      onLoading: function () {
        if ($("#loadPanel").length > 0) {
          $("#loadPanel").dxLoadPanel("instance").show();
        }
      },
      onLoaded: function () {
        if ($("#loadPanel").length > 0) {
          $("#loadPanel").dxLoadPanel("instance").hide();
        }
      },
    }),
    paginate: false,
  });

  var datasource_selectTareasMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTareaMaquina",
      key: "idTareaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
      onLoading: function (loadOptions) {
        loadOptions.filter = [
          "tblMaquina/idLavanderia eq " + LAVANDERIA.idLavanderia,
        ];
      },
    }),
    paginate: false,
  });

  var datasource_insertarTareasPersonas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTareaPersonaDia",
      key: "idTareaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "DELETE") {
          request.params.fecha = formatDate_parameter(fechaSel);
        }
      },
      version: 4,
    }),
  });

  var datasource_tblPersona_Tecnicos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
      onLoading: function (loadOptions) {
        if (typeof loadOptions.filter === "undefined") {
          loadOptions.filter = [
            [
              ["idCategoria", "=", 4],
              "OR",
              ["idCategoria", "=", 13],
              "OR",
              ["idCategoria", "=", 19],
              "OR",
              ["idCategoriaInterna", "=", 34],
              "OR",
              ["idCategoriaInterna", "=", 35],
              "OR",
              ["idCategoriaInterna", "=", 38],
              "OR",
              ["idCategoriaInterna", "=", 39],
            ],
            "AND",
            ["activo", "=", true],
            "AND",
            ["eliminado", "=", false],
          ];
        }
      },
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
  });

  //#endregion

  COMPONENT.setReport({
    denominacion: "preventivoDiario",
    parameterEvent: CustomizeParameterEditors,
  });

  $.when(
    datasource_tblPersona_Tecnicos.load(),
    datasource_selectTareasMaquina.load(),
  )
    .done(function () {
      $("#MonitorSat #popup_imprimirPreventivo").dxPopup({
        title: getTrad("preventivoDiario"),
        width: "325px",
        height: "450px",
        contentTemplate: function () {
          return $("<div />").dxCalendar({
            firstDayOfWeek: 1,
            value: fechaSel,
            onValueChanged: function (e) {
              fechaSel = e.value;
            },
          });
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("imprimir"),
              onClick: function (e) {
                $("#MonitorSat #popup_imprimirPreventivo")
                  .dxPopup("instance")
                  .hide();
                var params = {
                  idLavanderia: LAVANDERIA.idLavanderia,
                  fecha: formatDate_parameter(fechaSel),
                };

                COMPONENT.setParams(params);
              },
            },
          },
        ],
      });

      $("#MonitorSat #popup_insertarPreventivo_config").dxPopup({
        title: getTrad("preventivoDiario"),
        width: "700px",
        height: "450px",
        contentTemplate: function () {
          var contenedor = $("<div />").addClass("he-100");
          contenedor.append(
            $("<div id='loadPanel' />").dxLoadPanel({
              shadingColor: "rgba(255,255,255,0.8)",
              position: { of: "#dxBox_insertarPreventivo_config" },
              visible: false,
              showIndicator: true,
              showPane: true,
              shading: true,
              closeOnOutsideClick: false,
            }),
          );

          contenedor.append(
            $("<div id='dxBox_insertarPreventivo_config'/>").dxBox({
              direction: "row",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  ratio: 1,
                  template: function () {
                    return $("<div />").dxCalendar({
                      firstDayOfWeek: 1,
                      value: fechaSel,
                      onValueChanged: function (e) {
                        fechaSel = e.value;
                      },
                    });
                  },
                },
                {
                  ratio: 1,
                  template: function () {
                    return $("<div id='dxList_selectTecnicos'/>").dxList({
                      dataSource: datasource_tblPersona_Tecnicos,
                      selectionMode: "multiple",
                      height: "100%",
                      keyExpr: "idPersona",
                      activeStateEnabled: false,
                      focusStateEnabled: false,
                      itemTemplate: function (
                        itemData,
                        itemIndex,
                        itemElement,
                      ) {
                        return itemData.nombre + " " + itemData.apellidos;
                      },
                      onSelectionChanged: function (e) {
                        array_tecnico = e.component.option("selectedItemKeys");
                      },
                    });
                  },
                },
              ],
            }),
          );
          return contenedor;
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("insertar"),
              onClick: function (e) {
                if (array_tecnico.length !== 0) {
                  e.component.option("disabled", true);
                  datasource_InsertarPreventivo.reload().done(function () {
                    e.component.option("disabled", false);
                    $("#popup_insertarPreventivo").dxPopup("instance").show();

                    if (
                      $("#dxAccordion_insertarPreventivo").data("dxAccordion")
                    )
                      $("#dxAccordion_insertarPreventivo")
                        .dxAccordion("instance")
                        .repaint();
                  });
                } else {
                  dxMensajePregunta(getTrad("alerta_seleccionTecnicos"), [
                    [getTrad("aceptar"), function (e) {}, "danger"],
                  ]);
                }
              },
            },
          },
        ],
        onHidden: function (e) {
          $("#dxList_selectTecnicos").dxList("instance").unselectAll();
        },
      });

      $("#MonitorSat #popup_insertarPreventivo").dxPopup({
        title: getTrad("insertarPartePreventivo"),
        width: "80%",
        height: "80%",
        deferRendering: false,
        animation: null,
        contentTemplate: function () {
          var container = $("<div id='popup_insertarPreventivo_container'/>")
            .css("height", "100%")
            .css("width", "100%");
          var scrollView = $("<div />");
          var dxAccordion = $("<div id='dxAccordion_insertarPreventivo' />");
          var dxBtn = $("<div style='float:right; margin-top:10px;'/>");

          scrollView.appendTo(container);
          dxAccordion.appendTo(scrollView);
          dxBtn.appendTo(scrollView);

          scrollView.dxScrollView({
            height: "100%",
            width: "100%",
            direction: "vertical",
            showScrollbar: "always",
          });
          dxBtn.dxButton({
            text: getTrad("finalizar"),
            elementAttr: { id: "dxButton_finalizar" },
            visible: false,
            onClick: function (e) {
              $("#popup_insertarPreventivo").dxPopup("instance").hide();
            },
          });

          dxAccordion.addClass("no-icons").dxAccordion({
            dataSource: datasource_InsertarPreventivo,
            noDataText: "",
            collapsible: true,
            focusStateEnabled: false,
            multiple: true,
            onContentReady: function (e) {
              e.component.option(
                "selectedItems",
                e.component.getDataSource().items(),
              );
            },
            itemTitleTemplate: function (itemData, itemIndex, itemElement) {
              itemElement.append(
                $("<div />")
                  .addClass("font-size")
                  .addClass("p-0")
                  .text(itemData.denoMaquina),
              );
            },
            itemTemplate: function (itemData, itemIndex, itemElement) {
              return $("<div />").dxPivotGrid({
                //Datos
                showColumnGrandTotals: false,
                showColumnTotals: false,
                showRowGrandTotals: false,
                showRowTotals: false,
                showTotalsPrior: false,
                allowExpandAll: false,
                showBorders: true,
                fieldChooser: {
                  enabled: false,
                },
                dataSource: {
                  fields: [
                    {
                      dataField: "idTareaMaquina",
                      area: "row",
                      width: 350,
                      expanded: true,
                      customizeText: function (cellInfo) {
                        if (datasource_selectTareasMaquina.items().length > 0) {
                          var tareas = $.grep(
                            datasource_selectTareasMaquina.items(),
                            function (element, index) {
                              return element.idTareaMaquina === cellInfo.value;
                            },
                          );
                          return tareas[0].denominacion;
                        }
                      },
                    },
                    {
                      dataField: "realizado",
                      summaryType: "sum",
                      dataType: "number",
                      area: "data",
                    },
                    {
                      dataField: "denoFrec",
                      width: 80,
                      area: "row",
                      expanded: true,
                    },
                    {
                      dataField: "idPersona",
                      alignment: "center",
                      area: "column",
                      expanded: true,
                      customizeText: function (cellInfo) {
                        if (datasource_tblPersona_Tecnicos.items().length > 0) {
                          var personas = $.grep(
                            datasource_tblPersona_Tecnicos.items(),
                            function (element, index) {
                              return element.idPersona === cellInfo.value;
                            },
                          );
                          return (
                            personas[0].nombre + " " + personas[0].apellidos
                          );
                        }
                      },
                    },
                  ],
                  store: itemData.listaTareasNPreventivo,
                },
                loadPanel: {
                  enabled: false,
                },
                //Eventos dxPivotGrid
                onContentReady: function (e) {
                  //Terminamos el endUpdate para que guarde la tarea realizada en el tecnico seleccionado y asi evitamos un parpadeo del icono que nos indica que la tarea esta realizada.
                  e.component.endUpdate();
                },
                onCellPrepared: function (e) {
                  if (e.area === "data") {
                    var container = e.cellElement;
                    container.empty();
                    e.cellElement.css("text-align", "center");
                    e.cellElement.css("cursor", "pointer");
                    //Cuando la celda tiene valor 1 le asignamos el svg para indicar que la tarea esta realizada.
                    if (e.cell.text === "1") {
                      var imgTick = svg_tick.clone();
                      imgTick
                        .css("height", "14px")
                        .css("width", "14px")
                        .appendTo(container);
                    }
                  }
                  if (e.area !== "data") {
                    e.cellElement.css("cursor", "default");
                  }
                },
                onCellClick: function (e) {
                  if (e.area !== "data") {
                    e.cancel = true;
                  }

                  if (e.area === "data") {
                    var idPersona = e.cell.columnPath[0];
                    var idTareaMaquina = e.cell.rowPath[0];
                    var fecha = formatDate_parameter(fechaSel);
                    if (e.cell.value === 0) {
                      //Solo entramos si la celda no tiene ninguna tarea realizada a ninguna persona asociada.
                      var tareaPersonaDiaria = {};
                      tareaPersonaDiaria.fecha = fecha;
                      tareaPersonaDiaria.idTareaMaquina = idTareaMaquina;
                      tareaPersonaDiaria.idPersona = idPersona;

                      datasource_insertarTareasPersonas
                        .store()
                        .insert(tareaPersonaDiaria)
                        .done(function () {
                          var store = e.component.option("dataSource.store");
                          var tareas = $.grep(store, function (element, index) {
                            return element.idTareaMaquina === idTareaMaquina;
                          });
                          //Introducimos la tarea al técnico al cual se le asocia la tarea realizada solo si idPersona coincide con la columna donde el usuario hace click.
                          $.each(tareas, function (index, item) {
                            item.realizado =
                              item.idPersona === idPersona ? 1 : 0;
                          });

                          //Realizamos el beginUpdate para evitar un parpadeo del icono cuando el usuario hacia click encima de una tarea.
                          e.component.beginUpdate();
                          e.component.option("dataSource.store", store);
                        });
                    } else {
                      datasource_insertarTareasPersonas
                        .store()
                        .remove(idTareaMaquina)
                        .done(function () {
                          var store = e.component.option("dataSource.store");
                          var tareas = $.grep(store, function (element, index) {
                            return element.idTareaMaquina === idTareaMaquina;
                          });
                          //Eliminamos la tarea asociada al técnico que previamente tiene una tarea asociada.
                          $.each(tareas, function (index, item) {
                            item.realizado = 0;
                          });

                          e.component.beginUpdate();
                          e.component.option("dataSource.store", store);
                        });
                    }
                  }
                },
              });
            },
            //Eventos dxAccordion
            onItemTitleClick: function (e) {
              if (e.itemIndex >= 0) e.jQueryEvent.stopPropagation();
            },
          });
          return container;
        },
        onShowing: function (e) {
          $("#dxButton_finalizar").dxButton("instance").option("visible", true);
        },
        onHidden: function (e) {
          $("#dxList_selectTecnicos").dxList("instance").unselectAll();

          $("#dxButton_finalizar")
            .dxButton("instance")
            .option("visible", false);
        },
      });
    })
    .always(function () {
      deferred.resolve();
    });

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === "preventivoDiario") {
      if (e.parameter.name === "idLavanderia" || e.parameter.name === "fecha") {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  return deferred.promise();
}
