import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Lookup } from "devextreme-react/lookup";

//Css
import "./Css.scss";

class CountryPicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
      className: "",
    };

    //EVENTOS
    this.dxLookup_selectorPaises_itemRender =
      this.dxLookup_selectorPaises_itemRender.bind(this);
    this.dxLookup_selectorPaises_onValueChanged =
      this.dxLookup_selectorPaises_onValueChanged.bind(this);
    this.dxLookup_selectorPaises_onContentReady =
      this.dxLookup_selectorPaises_onContentReady.bind(this);
  }

  enum_paises = [
    {
      name: "Afganistán",
      code: "AF",
    },
    {
      name: "Alandia",
      code: "AX",
    },
    {
      name: "Albania",
      code: "AL",
    },
    {
      name: "Argelia",
      code: "DZ",
    },
    {
      name: "Samoa Americana",
      code: "AS",
    },
    {
      name: "Andorra",
      code: "AD",
    },
    {
      name: "Angola",
      code: "AO",
    },
    {
      name: "Anguilla",
      code: "AI",
    },
    {
      name: "Antártida",
      code: "AQ",
    },
    {
      name: "Antigua y Barbuda",
      code: "AG",
    },
    {
      name: "Argentina",
      code: "AR",
    },
    {
      name: "Armenia",
      code: "AM",
    },
    {
      name: "Aruba",
      code: "AW",
    },
    {
      name: "Australia",
      code: "AU",
    },
    {
      name: "Austria",
      code: "AT",
    },
    {
      name: "Azerbaiyán",
      code: "AZ",
    },
    {
      name: "Bahamas",
      code: "BS",
    },
    {
      name: "Bahrein",
      code: "BH",
    },
    {
      name: "Bangladesh",
      code: "BD",
    },
    {
      name: "Barbados",
      code: "BB",
    },
    {
      name: "Bielorrusia",
      code: "BY",
    },
    {
      name: "Bélgica",
      code: "BE",
    },
    {
      name: "Belice",
      code: "BZ",
    },
    {
      name: "Benín",
      code: "BJ",
    },
    {
      name: "Bermudas",
      code: "BM",
    },
    {
      name: "Bután",
      code: "BT",
    },
    {
      name: "Bolivia",
      code: "BO",
    },
    {
      name: "Bosnia y Herzegovina",
      code: "BA",
    },
    {
      name: "Botswana",
      code: "BW",
    },
    {
      name: "Isla Bouvet",
      code: "BV",
    },
    {
      name: "Brasil",
      code: "BR",
    },
    {
      name: "Territorio Británico del Oéano Índico",
      code: "IO",
    },
    {
      name: "Brunei",
      code: "BN",
    },
    {
      name: "Bulgaria",
      code: "BG",
    },
    {
      name: "Burkina Faso",
      code: "BF",
    },
    {
      name: "Burundi",
      code: "BI",
    },
    {
      name: "Camboya",
      code: "KH",
    },
    {
      name: "Camerún",
      code: "CM",
    },
    {
      name: "Canadá",
      code: "CA",
    },
    {
      name: "Cabo Verde",
      code: "CV",
    },
    {
      name: "Islas Caimán",
      code: "KY",
    },
    {
      name: "República Centroafricana",
      code: "CF",
    },
    {
      name: "Chad",
      code: "TD",
    },
    {
      name: "Chile",
      code: "CL",
    },
    {
      name: "China",
      code: "CN",
    },
    {
      name: "Isla de Navidad",
      code: "CX",
    },
    {
      name: "Islas Cocos o Islas Keeling",
      code: "CC",
    },
    {
      name: "Colombia",
      code: "CO",
    },
    {
      name: "Comoras",
      code: "KM",
    },
    {
      name: "Congo",
      code: "CG",
    },
    {
      name: "Congo (Rep. Dem.)",
      code: "CD",
    },
    {
      name: "Islas Cook",
      code: "CK",
    },
    {
      name: "Costa Rica",
      code: "CR",
    },
    {
      name: "Costa de Marfil",
      code: "CI",
    },
    {
      name: "Croacia",
      code: "HR",
    },
    {
      name: "Cuba",
      code: "CU",
    },
    {
      name: "Chipre",
      code: "CY",
    },
    {
      name: "República Checa",
      code: "CZ",
    },
    {
      name: "Dinamarca",
      code: "DK",
    },
    {
      name: "Djibouti",
      code: "DJ",
    },
    {
      name: "Dominica",
      code: "DM",
    },
    {
      name: "República Dominicana",
      code: "DO",
    },
    {
      name: "Ecuador",
      code: "EC",
    },
    {
      name: "Egipto",
      code: "EG",
    },
    {
      name: "El Salvador",
      code: "SV",
    },
    {
      name: "Guinea Ecuatorial",
      code: "GQ",
    },
    {
      name: "Eritrea",
      code: "ER",
    },
    {
      name: "Estonia",
      code: "EE",
    },
    {
      name: "Etiopía",
      code: "ET",
    },
    {
      name: "Islas Malvinas",
      code: "FK",
    },
    {
      name: "Islas Faroe",
      code: "FO",
    },
    {
      name: "Fiyi",
      code: "FJ",
    },
    {
      name: "Finlandia",
      code: "FI",
    },
    {
      name: "Francia",
      code: "FR",
    },
    {
      name: "Guinea Francesa",
      code: "GF",
    },
    {
      name: "Polinesia Francesa",
      code: "PF",
    },
    {
      name: "Tierras Australes y Antárticas Francesas",
      code: "TF",
    },
    {
      name: "Gabón",
      code: "GA",
    },
    {
      name: "Gambia",
      code: "GM",
    },
    {
      name: "Georgia",
      code: "GE",
    },
    {
      name: "Alemania",
      code: "DE",
    },
    {
      name: "Ghana",
      code: "GH",
    },
    {
      name: "Gibraltar",
      code: "GI",
    },
    {
      name: "Grecia",
      code: "GR",
    },
    {
      name: "Groenlandia",
      code: "GL",
    },
    {
      name: "Granada",
      code: "GD",
    },
    {
      name: "Guadalupe",
      code: "GP",
    },
    {
      name: "Guam",
      code: "GU",
    },
    {
      name: "Guatemala",
      code: "GT",
    },
    {
      name: "Guernsey",
      code: "GG",
    },
    {
      name: "Guinea",
      code: "GN",
    },
    {
      name: "Guinea-Bisáu",
      code: "GW",
    },
    {
      name: "Guyana",
      code: "GY",
    },
    {
      name: "Haití",
      code: "HT",
    },
    {
      name: "Islas Heard y McDonald",
      code: "HM",
    },
    {
      name: "Ciudad del Vaticano",
      code: "VA",
    },
    {
      name: "Honduras",
      code: "HN",
    },
    {
      name: "Hong Kong",
      code: "HK",
    },
    {
      name: "Hungría",
      code: "HU",
    },
    {
      name: "Islandia",
      code: "IS",
    },
    {
      name: "India",
      code: "IN",
    },
    {
      name: "Indonesia",
      code: "ID",
    },
    {
      name: "Iran",
      code: "IR",
    },
    {
      name: "Iraq",
      code: "IQ",
    },
    {
      name: "Irlanda",
      code: "IE",
    },
    {
      name: "Isla de Man",
      code: "IM",
    },
    {
      name: "Israel",
      code: "IL",
    },
    {
      name: "Italia",
      code: "IT",
    },
    {
      name: "Jamaica",
      code: "JM",
    },
    {
      name: "Japón",
      code: "JP",
    },
    {
      name: "Jersey",
      code: "JE",
    },
    {
      name: "Jordania",
      code: "JO",
    },
    {
      name: "Kazajistán",
      code: "KZ",
    },
    {
      name: "Kenia",
      code: "KE",
    },
    {
      name: "Kiribati",
      code: "KI",
    },
    {
      name: "Corea del Norte",
      code: "KP",
    },
    {
      name: "Corea del Sur",
      code: "KR",
    },
    {
      name: "Kuwait",
      code: "KW",
    },
    {
      name: "Kirguistán",
      code: "KG",
    },
    {
      name: "Laos",
      code: "LA",
    },
    {
      name: "Letonia",
      code: "LV",
    },
    {
      name: "Líbano",
      code: "LB",
    },
    {
      name: "Lesoto",
      code: "LS",
    },
    {
      name: "Liberia",
      code: "LR",
    },
    {
      name: "Libia",
      code: "LY",
    },
    {
      name: "Liechtenstein",
      code: "LI",
    },
    {
      name: "Lituania",
      code: "LT",
    },
    {
      name: "Luxemburgo",
      code: "LU",
    },
    {
      name: "Macao",
      code: "MO",
    },
    {
      name: "Macedonia",
      code: "MK",
    },
    {
      name: "Madagascar",
      code: "MG",
    },
    {
      name: "Malawi",
      code: "MW",
    },
    {
      name: "Malasia",
      code: "MY",
    },
    {
      name: "Maldivas",
      code: "MV",
    },
    {
      name: "Mali",
      code: "ML",
    },
    {
      name: "Malta",
      code: "MT",
    },
    {
      name: "Islas Marshall",
      code: "MH",
    },
    {
      name: "Martinica",
      code: "MQ",
    },
    {
      name: "Mauritania",
      code: "MR",
    },
    {
      name: "Mauricio",
      code: "MU",
    },
    {
      name: "Mayotte",
      code: "YT",
    },
    {
      name: "México",
      code: "MX",
    },
    {
      name: "Micronesia",
      code: "FM",
    },
    {
      name: "Moldavia",
      code: "MD",
    },
    {
      name: "Mónaco",
      code: "MC",
    },
    {
      name: "Mongolia",
      code: "MN",
    },
    {
      name: "Montserrat",
      code: "MS",
    },
    {
      name: "Marruecos",
      code: "MA",
    },
    {
      name: "Mozambique",
      code: "MZ",
    },
    {
      name: "Birmania",
      code: "MM",
    },
    {
      name: "Namibia",
      code: "NA",
    },
    {
      name: "Nauru",
      code: "NR",
    },
    {
      name: "Nepal",
      code: "NP",
    },
    {
      name: "Países Bajos",
      code: "NL",
    },
    {
      name: "Antillas Neerlandesas",
      code: "AN",
    },
    {
      name: "Nnueva Caledonia",
      code: "NC",
    },
    {
      name: "Nueva Zelanda",
      code: "NZ",
    },
    {
      name: "Nicaragua",
      code: "NI",
    },
    {
      name: "Níger",
      code: "NE",
    },
    {
      name: "Nigeria",
      code: "NG",
    },
    {
      name: "Niue",
      code: "NU",
    },
    {
      name: "Isla Norfolk",
      code: "NF",
    },
    {
      name: "Islas Marianas del Norte",
      code: "MP",
    },
    {
      name: "Noruega",
      code: "NO",
    },
    {
      name: "Omán",
      code: "OM",
    },
    {
      name: "Pakistán",
      code: "PK",
    },
    {
      name: "Palaos",
      code: "PW",
    },
    {
      name: "Palestina",
      code: "PS",
    },
    {
      name: "Panamá",
      code: "PA",
    },
    {
      name: "Papúa Nueva Guinea",
      code: "PG",
    },
    {
      name: "Paraguay",
      code: "PY",
    },
    {
      name: "Perú",
      code: "PE",
    },
    {
      name: "Filipinas",
      code: "PH",
    },
    {
      name: "Islas Pitcairn",
      code: "PN",
    },
    {
      name: "Polonia",
      code: "PL",
    },
    {
      name: "Portugal",
      code: "PT",
    },
    {
      name: "Puerto Rico",
      code: "PR",
    },
    {
      name: "Catar",
      code: "QA",
    },
    {
      name: "Reunión",
      code: "RE",
    },
    {
      name: "Rumanía",
      code: "RO",
    },
    {
      name: "Rusia",
      code: "RU",
    },
    {
      name: "Ruanda",
      code: "RW",
    },
    {
      name: "Santa Helena",
      code: "SH",
    },
    {
      name: "San Cristóbal y Nieves",
      code: "KN",
    },
    {
      name: "Santa Lucía",
      code: "LC",
    },
    {
      name: "San Pedro y Miquelón",
      code: "PM",
    },
    {
      name: "San Vicente y las Granadinas",
      code: "VC",
    },
    {
      name: "Samoa",
      code: "WS",
    },
    {
      name: "San Marino",
      code: "SM",
    },
    {
      name: "Santo Tomé y Príncipe",
      code: "ST",
    },
    {
      name: "Arabia Saudí",
      code: "SA",
    },
    {
      name: "Senegal",
      code: "SN",
    },
    {
      name: "Serbia",
      code: "RS",
    },
    {
      name: "Montenegro",
      code: "ME",
    },
    {
      name: "Seychelles",
      code: "SC",
    },
    {
      name: "Sierra Leona",
      code: "SL",
    },
    {
      name: "Singapur",
      code: "SG",
    },
    {
      name: "Eslovaquia",
      code: "SK",
    },
    {
      name: "Eslovenia",
      code: "SI",
    },
    {
      name: "Islas Salomón",
      code: "SB",
    },
    {
      name: "Somalia",
      code: "SO",
    },
    {
      name: "Sudáfrica",
      code: "ZA",
    },
    {
      name: "Islas Georgias del Sur y Sandwich del Sur",
      code: "GS",
    },
    {
      name: "España",
      code: "ES",
    },
    {
      name: "Sri Lanka",
      code: "LK",
    },
    {
      name: "Sudán",
      code: "SD",
    },
    {
      name: "Surinam",
      code: "SR",
    },
    {
      name: "Svalbard y Jan Mayen",
      code: "SJ",
    },
    {
      name: "Esuatini",
      code: "SZ",
    },
    {
      name: "Suecia",
      code: "SE",
    },
    {
      name: "Suiza",
      code: "CH",
    },
    {
      name: "Siria",
      code: "SY",
    },
    {
      name: "Taiwán",
      code: "TW",
    },
    {
      name: "Tayikistán",
      code: "TJ",
    },
    {
      name: "Tanzania",
      code: "TZ",
    },
    {
      name: "Tailandia",
      code: "TH",
    },
    {
      name: "Timor Oriental",
      code: "TL",
    },
    {
      name: "Togo",
      code: "TG",
    },
    {
      name: "Tokelau",
      code: "TK",
    },
    {
      name: "Tonga",
      code: "TO",
    },
    {
      name: "Trinidad y Tobago",
      code: "TT",
    },
    {
      name: "Túnez",
      code: "TN",
    },
    {
      name: "Turquía",
      code: "TR",
    },
    {
      name: "Turkmenistán",
      code: "TM",
    },
    {
      name: "Islas Turcas y Caicos",
      code: "TC",
    },
    {
      name: "Tuvalu",
      code: "TV",
    },
    {
      name: "Uganda",
      code: "UG",
    },
    {
      name: "Ucrania",
      code: "UA",
    },
    {
      name: "Emiratos Árabes Unidos",
      code: "AE",
    },
    {
      name: "Reino Unido",
      code: "GB",
    },
    {
      name: "Estados Unidos",
      code: "US",
    },
    {
      name: "Islas Ultramarinas Menores de Estados Unidos",
      code: "UM",
    },
    {
      name: "Uruguay",
      code: "UY",
    },
    {
      name: "Uzbekistán",
      code: "UZ",
    },
    {
      name: "Vanuatu",
      code: "VU",
    },
    {
      name: "Venezuela",
      code: "VE",
    },
    {
      name: "Vietnam",
      code: "VN",
    },
    {
      name: "slas Vírgenes Británicas",
      code: "VG",
    },
    {
      name: "Islas Vírgenes de los Estados Unidos",
      code: "VI",
    },
    {
      name: "Wallis y Futuna",
      code: "WF",
    },
    {
      name: "Sahara Occidental",
      code: "EH",
    },
    {
      name: "Yemen",
      code: "YE",
    },
    {
      name: "Zambia",
      code: "ZM",
    },
    {
      name: "Zimbabue",
      code: "ZW",
    },
  ];

  //PROPS ACEPTADAS
  render() {
    let { value } = this.props;
    let { className } = this.state;
    return (
      <Fragment>
        <Lookup
          searchEnabled={true}
          searchExpr="name"
          dataSource={this.enum_paises}
          itemRender={this.dxLookup_selectorPaises_itemRender}
          fieldRender={this.dxLookup_selectorPaises_fieldRender}
          closeOnOutsideClick={true}
          className={className}
          displayExpr="name"
          valueExpr="code"
          value={value}
          usePopopver={false}
          popupWidth={300}
          showCancelButton={false}
          onContentReady={this.dxLookup_selectorPaises_onContentReady}
          onValueChanged={this.dxLookup_selectorPaises_onValueChanged}
        ></Lookup>
      </Fragment>
    );
  }

  dxLookup_selectorPaises_onContentReady(e) {
    let popup = e.component._popup;
    popup.option("showTitle", false);
    popup.content().css("top", 0);
    popup.content().addClass("dxLookupPopup_countrySelector");

    if (this.props.onContentRead) this.props.onContentReady(e);
  }

  dxLookup_selectorPaises_onValueChanged(e) {
    this.props.updateValor_paises(e.value);
  }

  dxLookup_selectorPaises_itemRender(itemData) {
    if (!itemData) return <div></div>;
    return (
      <div style={{ width: 350 }} className="d-flex align-items-center">
        <div
          className="flex-30"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/flags/" +
              itemData.code.toLowerCase() +
              ".svg)",
            backgroundRepeat: "no-repeat",
            height: 15,
            width: 20,
          }}
        ></div>
        <div className="flex-1 pl-3">{itemData.name}</div>
      </div>
    );
  }

  dxLookup_selectorPaises_fieldRender(itemData) {
    if (!itemData) return <div style={{ width: 150 }}></div>;
    return (
      <div
        style={{ width: 150 }}
        className="d-flex align-items-center countryContainer"
      >
        <div className="flex-1 pl-3 countryText">{itemData.name}</div>
        <div
          className="flex-30px countryFlag"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/flags/" +
              itemData.code.toLowerCase() +
              ".svg)",
            backgroundRepeat: "no-repeat",
            height: 15,
            width: 20,
          }}
        ></div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    let { value, className } = this.props;
    this.setState({
      data: value,
      className: className,
    });
  }

  componentDidMount() {
    let { value, className } = this.props;
    this.setState({
      data: value,
      className: className,
    });
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(CountryPicker);
