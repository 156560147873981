import React from "react";

import { Box, Button, Popup } from "devextreme-react";
import { Item as ItemBox } from "devextreme-react/box";
import DataGrid_Centro from "./DataGrid_Centro";
import { ToolbarItem } from "devextreme-react/popup";
import SelectorEmpresa from "../SelectorEmpresa";
import { ControlPresupuestarioCxt } from "../../../contexts/context";
import { getTrad } from "helpers";

class SelectorCentro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            selectedRows: [],
        };
    }

    render() {
        const { isVisible, selectedRows } = this.state;
        const { datasource_tblEmpresasPolarier, tblAdmCentroCoste, tblAdmElementoPEP } = this.context;

        return (
            <div>
                <Button text="Seleccionar centro" onClick={this.dxButton_onClick} className="he-100" type="default" />

                <Popup
                    visible={isVisible}
                    onShowing={this.onShowing}
                    onHiding={this.onHiding}
                    onHidden={this.onHidden}
                    width={900}
                    deferRendering={false}
                    dragEnabled={false}
                    closeOnOutsideClick={false}
                >
                    <ToolbarItem toolbar={"top"} location={"before"}>
                        <SelectorEmpresa dataSource={datasource_tblEmpresasPolarier} stylingMode="filled" />
                    </ToolbarItem>
                    <Box className="he-100">
                        <ItemBox ratio={1}>
                            <DataGrid_Centro
                                value={selectedRows}
                                onSelectionChanged={this.DataGrid_Centro_onSelectionChanged}
                                dataSource={tblAdmCentroCoste}
                                keyExpr={"idAdmCentroCoste"}
                                caption={"centroCoste"}
                            />
                        </ItemBox>
                        <ItemBox baseSize={10} />
                        <ItemBox ratio={1}>
                            <DataGrid_Centro
                                value={selectedRows}
                                onSelectionChanged={this.DataGrid_Centro_onSelectionChanged}
                                dataSource={tblAdmElementoPEP}
                                keyExpr={"idAdmElementoPEP"}
                                caption={"elementoPep"}
                            />
                        </ItemBox>
                    </Box>
                    <ToolbarItem toolbar={"bottom"} location={"after"}>
                        <Button text={getTrad("cancelar")} onClick={this.onHiding} />
                    </ToolbarItem>
                    <ToolbarItem toolbar={"bottom"} location={"after"}>
                        <Button text={getTrad("seleccionar")} onClick={this.onClick_seleccionar} type="default" />
                    </ToolbarItem>
                </Popup>
            </div>
        );
    }

    // #region dxButton

    dxButton_onClick = () => {
        this.setState({ isVisible: true });
    };

    // #endregion

    // #region Popup

    onShowing = () => {
        const { centros, tblAdmCentroCoste, tblAdmElementoPEP } = this.context;
        let selectedRows =
            centros.length > 0
                ? centros.some((x) => x.idAdmCentroCoste)
                    ? tblAdmCentroCoste.filter((x) =>
                          centros.map((x) => x.idAdmCentroCoste).includes(x.idAdmCentroCoste)
                      )
                    : tblAdmElementoPEP.filter((x) =>
                          centros.map((x) => x.idAdmElementoPEP).includes(x.idAdmElementoPEP)
                      )
                : [];

        this.setState({ selectedRows });
    };

    onHiding = () => {
        this.setState({ isVisible: false });
    };

    onHidden = () => {
        this.setState({ selectedRows: [] });
    };

    onClick_seleccionar = () => {
        const { setCentros } = this.context;
        setCentros(this.state.selectedRows);
        this.onHiding();
    };

    DataGrid_Centro_onSelectionChanged = (e, key) => {
        this.setState({ selectedRows: e.selectedRowsData ?? [] });
    };

    // #endregion
}
SelectorCentro.contextType = ControlPresupuestarioCxt;

export default SelectorCentro;
