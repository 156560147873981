import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  datetimeToDuration,
  durationToDatetime,
  formatDateTime_parameter,
  patchRequestHandler,
  dateTime_hourMinute,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions, impresionActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import ScrollView from "devextreme-react/scroll-view";
import { Button } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Lookup,
  Editing,
} from "devextreme-react/data-grid";
import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import {
  Form,
  Item,
  RequiredRule,
  TabbedItem,
  Tab,
  TabPanelOptions,
} from "devextreme-react/form";
import { Row, Col } from "reactstrap";

//CUSTOM COMPONENTS
import { removeCellComponent } from "../../../components/DataGrid/Cells";

//DEVEXTREME JQUERY
import cargaJs from "./Js.js";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import PopupAñadirRecambio from "components/Recambios/PopupAñadirRecambio";

class OrdenesTrabajo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parteTrabajoSel: null,
      showPopup_Add_tblPersonasNParte: false,
      showPopup_Add_tblRecambioNParteTrabajo: false,
    };

    // REFERENCIAS
    this.dxDataGrid_tblParteTrabajo_REF = React.createRef();
    this.dxDataGrid_tblPersonasNParte_REF = React.createRef();
    this.dxDataGrid_tblRecambioNParteTrabajo_REF = React.createRef();
    this.dxDataGrid_tblServicioExternoNParteTrabajo_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblPersona_Tecnicos_beforeSend =
      this.datasource_tblPersona_Tecnicos_beforeSend.bind(this);

    this.datasource_tblParteTrabajo_onLoading =
      this.datasource_tblParteTrabajo_onLoading.bind(this);
    this.datasource_tblParteTrabajo_postProcess =
      this.datasource_tblParteTrabajo_postProcess.bind(this);
    this.datasource_tblParteTrabajo_onInserted =
      this.datasource_tblParteTrabajo_onInserted.bind(this);
    this.datasource_tblParteTrabajo_onUpdated =
      this.datasource_tblParteTrabajo_onUpdated.bind(this);

    this.datasource_tblMaquina_beforeSend =
      this.datasource_tblMaquina_beforeSend.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    this.onSelectionChanged_tblParteTrabajo =
      this.onSelectionChanged_tblParteTrabajo.bind(this);
    this.onToolbarPreparing_tblParteTrabajo =
      this.onToolbarPreparing_tblParteTrabajo.bind(this);
    this.onRowRemoving_tblParteTrabajo =
      this.onRowRemoving_tblParteTrabajo.bind(this);
    this.onRowRemoved_tblParteTrabajo =
      this.onRowRemoved_tblParteTrabajo.bind(this);

    //PARTES DE TRABAJO
    this.crearParteTrabajo = this.crearParteTrabajo.bind(this);
    this.render_tblPersonasNParte = this.render_tblPersonasNParte.bind(this);
    this.onToolbarPreparing_tblPersonasNParte =
      this.onToolbarPreparing_tblPersonasNParte.bind(this);
    this.submit_formParteTrabajo = this.submit_formParteTrabajo.bind(this);

    //Add Técnicos
    this.hiding_popup_Add_tblPersonasNParte =
      this.hiding_popup_Add_tblPersonasNParte.bind(this);
    this.onShown_popup_Add_tblPersonasNParte =
      this.onShown_popup_Add_tblPersonasNParte.bind(this);
    this.getDataSource_Add_tblPersonasNParte =
      this.getDataSource_Add_tblPersonasNParte.bind(this);
    this.onClick_btnAddPersonasNParte =
      this.onClick_btnAddPersonasNParte.bind(this);

    //Add partes trabajo
    this.hiding_popup_Add_tblRecambioNParteTrabajo =
      this.hiding_popup_Add_tblRecambioNParteTrabajo.bind(this);
    this.onToolbarPreparing_tblRecambioNParteTrabajo =
      this.onToolbarPreparing_tblRecambioNParteTrabajo.bind(this);
    this.onToolbarPreparing_tblRecambioNParteTrabajoIBS =
      this.onToolbarPreparing_tblRecambioNParteTrabajoIBS.bind(this);
    this.onToolbarPreparing_tblServicioExternoNParteTrabajo =
      this.onToolbarPreparing_tblServicioExternoNParteTrabajo.bind(this);

    this.imprimirPreventivo = this.imprimirPreventivo.bind(this);
    this.insertarPreventivo = this.insertarPreventivo.bind(this);

    //REPORT
    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
  }

  //REFERENCIAS
  get dxDataGrid_tblParteTrabajo() {
    return this.dxDataGrid_tblParteTrabajo_REF.current.instance;
  }

  get dxDataGrid_tblPersonasNParte() {
    return this.dxDataGrid_tblPersonasNParte_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNParteTrabajo() {
    return this.dxDataGrid_tblRecambioNParteTrabajo_REF.current.instance;
  }

  get dxDataGrid_tblServicioExternoNParteTrabajo() {
    return this.dxDataGrid_tblServicioExternoNParteTrabajo_REF.current.instance;
  }

  datasource_tblParteTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblParteTrabajo",
      key: "idParteTrabajo",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: (loadOptions) => {
        this.datasource_tblParteTrabajo_onLoading(loadOptions);
      },
      onInserting: (values) => {
        this.datasource_tblParteTrabajo_onInserting(values);
      },
      onInserted: (values, key) => {
        this.datasource_tblParteTrabajo_onInserted(values, key);
      },
      onUpdating: (key, values) => {
        this.datasource_tblParteTrabajo_onUpdating(key, values);
      },
      onUpdated: (values, key) => {
        this.datasource_tblParteTrabajo_onUpdated(values, key);
      },
      onRemoved: () => {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    expand: [
      "tblPersonasNParte",
      "tblRecambioNParteTrabajoIBS",
      "tblServicioExternoNParteTrabajo",
      "tblRecambioNParteTrabajo($expand=idRecambioNavigation($select=denominacion,referencia),idAlmacenNavigation($select=idAlmacen,idAlmacenPadre,denominacion;$expand=idMonedaNavigation($select=codigo)))",
      "tblIncidenciaNParte($expand=idIncidenciaNavigation($select=codigo))",
    ],
    postProcess: (data) => {
      return this.datasource_tblParteTrabajo_postProcess(data);
    },
  });
  datasource_tblParteTrabajo_onLoading(loadOptions) {
    let { lavanderia } = this.props;
    if (loadOptions.filter) {
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        ["idLavanderia", "=", lavanderia.idLavanderia],
      ];
    } else {
      loadOptions.filter = ["idLavanderia", "=", lavanderia.idLavanderia];
    }
  }
  datasource_tblParteTrabajo_postProcess(data) {
    $.each(data, function (index, item) {
      $.each(item.tblPersonasNParte, function (index, pers) {
        if (!(pers.horas instanceof Date)) {
          pers.horas = durationToDatetime(pers.horas);
        }
      });
    });
    return data;
  }

  datasource_tblParteTrabajo_onInserting = (values) => {
    $.each(values.tblPersonasNParte, (index, pers) => {
      pers.horas = datetimeToDuration(pers.horas);
    });
  };

  datasource_tblParteTrabajo_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  }

  datasource_tblParteTrabajo_onUpdating = (key, values) => {
    $.each(
      values.find((item) => item.path === "/tblPersonasNParte").value,
      (index, pers) => {
        pers.horas = dateTime_hourMinute(new Date(pers.horas));
      },
    );
  };

  datasource_tblParteTrabajo_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.dxDataGrid_tblParteTrabajo.refresh(true);
  }

  datasource_tblPersona_Tecnicos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_Tecnicos_beforeSend(request);
      },
      version: 4,
      onLoading: (loadOptions) => {
        this.datasource_tblPersona_Tecnicos_onLoading(loadOptions);
      },
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
    map: (itemData) => {
      return this.datasource_tblPersona_Tecnicos_map(itemData);
    },
  });

  datasource_tblPersona_Tecnicos_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }
  datasource_tblPersona_Tecnicos_onLoading(loadOptions) {
    let { lavanderia } = this.props;
    let filtroLavanderia =
      "idLavanderia eq " +
      lavanderia.idLavanderia +
      " or tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
      lavanderia.idLavanderia +
      "))";
    if (typeof loadOptions.filter === "undefined") {
      loadOptions.filter = [
        [
          ["idCategoria", "=", 4],
          "OR",
          ["idCategoria", "=", 13],
          "OR",
          ["idCategoria", "=", 19],
          "OR",
          ["idCategoriaInterna", "=", 34],
          "OR",
          ["idCategoriaInterna", "=", 35],
          "OR",
          ["idCategoriaInterna", "=", 38],
          "OR",
          ["idCategoriaInterna", "=", 39],
        ],
        "AND",
        ["activo", "=", true],
        "AND",
        ["eliminado", "=", false],
        "AND",
        [filtroLavanderia],
      ];
    }
  }
  datasource_tblPersona_Tecnicos_map(itemData) {
    return {
      idPersona: itemData.idPersona,
      nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
    };
  }

  datasource_tblPersona_Tecnicos_all = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_Tecnicos_all_beforeSend(request);
      },
      version: 4,
      onLoading: (loadOptions) => {
        this.datasource_tblPersona_Tecnicos_all_onLoading(loadOptions);
      },
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
    map: (itemData) => {
      return this.datasource_tblPersona_Tecnicos_map(itemData);
    },
  });

  datasource_tblPersona_Tecnicos_all_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = true;
  }

  datasource_tblPersona_Tecnicos_all_onLoading(loadOptions) {
    let { lavanderia } = this.props;
    let filtroLavanderia =
      "idLavanderia eq " +
      lavanderia.idLavanderia +
      " or tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
      lavanderia.idLavanderia +
      "))";
    if (typeof loadOptions.filter === "undefined") {
      loadOptions.filter = [
        [
          ["idCategoria", "=", 4],
          "OR",
          ["idCategoria", "=", 13],
          "OR",
          ["idCategoria", "=", 19],
          "OR",
          ["idCategoriaInterna", "=", 34],
          "OR",
          ["idCategoriaInterna", "=", 35],
          "OR",
          ["idCategoriaInterna", "=", 38],
          "OR",
          ["idCategoriaInterna", "=", 39],
        ],
        "AND",
        [filtroLavanderia],
      ];
    }
  }

  datasource_tblMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblMaquina_beforeSend(request);
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idMaquina", "denominacion", "etiqueta", "numSerie", "activo"],
  });

  datasource_tblMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  enum_EstadoParteTrabajo = [
    {
      estado: getTrad("ok").toUpperCase(),
      valor: 0,
      color: "#1BD319",
    },
    {
      estado: getTrad("noParada").toUpperCase(),
      valor: 1,
      color: "#FEF161",
    },
    {
      estado: getTrad("parada").toUpperCase(),
      valor: 2,
      color: "#FE3814",
    },
  ];

  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    _this.loadPanel_show();
    $.when(
      _this.datasource_tblParteTrabajo.reload(),
      _this.datasource_tblMaquina.load(),
      _this.datasource_tblPersona_Tecnicos.load(),
      _this.datasource_tblPersona_Tecnicos_all.load(),
    ).then(function () {
      _this.crearParteTrabajo();
      _this.loadPanel_hide();
    });
  }

  //REPORTS
  setParams(params) {
    this.props.setParams(params);
  }

  setReport(report) {
    this.props.setReport(report);
  }

  closeReport() {
    this.props.close();
  }

  render() {
    let {
      parteTrabajoSel,
      showPopup_Add_tblPersonasNParte,
      showPopup_Add_tblRecambioNParteTrabajo,
    } = this.state;
    const { lavanderia } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={[this.imprimirPreventivo(), this.insertarPreventivo()]}
        />
        <div className={"media-body"}>
          <div
            id="OrdenesTrabajo"
            className="formContainer scrollbar-container"
          >
            <ScrollView id="dxScrollView" className="formContent">
              <Row className="pt-4 he-100">
                <Col xs="12" md="6">
                  <form
                    onSubmit={this.submit_formParteTrabajo}
                    className="d-flex flex-column he-100 w-100"
                  >
                    <div className="text-left pb-3">
                      <Toolbar>
                        <ToolbarItem location="before">
                          <Button
                            text={getTrad("nuevoParte")}
                            icon="add"
                            onClick={this.crearParteTrabajo}
                          />
                        </ToolbarItem>
                        <ToolbarItem location="after">
                          <Button
                            text={getTrad("actualizar")}
                            type="success"
                            icon="check"
                            useSubmitBehavior={true}
                            validationGroup="validationGroup_form_parteTrabajo"
                            visible={
                              parteTrabajoSel && parteTrabajoSel.idParteTrabajo
                                ? true
                                : false
                            }
                          />
                          <Button
                            text={getTrad("crearParte")}
                            type="success"
                            icon="check"
                            useSubmitBehavior={true}
                            validationGroup="validationGroup_form_parteTrabajo"
                            visible={
                              !(
                                parteTrabajoSel &&
                                parteTrabajoSel.idParteTrabajo
                              )
                            }
                          />
                        </ToolbarItem>
                      </Toolbar>
                    </div>
                    <div className="flex-grow-1">
                      <Form
                        height="100%"
                        formData={parteTrabajoSel}
                        labelLocation="top"
                        scrollingEnabled={true}
                        screenByWidth={this.screenByWidth}
                        colCountByScreen={this.colCountByScreen}
                        validationGroup="validationGroup_form_parteTrabajo"
                        onContentReady={this.onContentReady_form_parteTrabajo}
                      >
                        <Item dataField="idUsuarioCrea" visible={false} />
                        <Item dataField="estadoMaquina" visible={false} />
                        <Item
                          dataField="fecha"
                          label={this.formLabel_fecha}
                          editorType="dxDateBox"
                          editorOptions={this.dateTime_editorOptions}
                        >
                          <RequiredRule message={getTrad("campoNecesario")} />
                        </Item>
                        <Item
                          dataField="idMaquina"
                          label={this.formLabel_maquina}
                          editorType="dxLookup"
                          editorOptions={this.idMaquina_editorOptions}
                          colSpan={2}
                        >
                          <RequiredRule message={getTrad("campoNecesario")} />
                        </Item>
                        <Item
                          dataField="tblIncidenciaNParte[0].idIncidenciaNavigation.codigo"
                          label={this.formLabel_incidencia}
                          colSpan={1}
                          disabled={true}
                          editorOptions={this.disabled_editorOptions}
                        />
                        <Item
                          dataField="resolucion"
                          label={this.formLabel_descripcionResolucion}
                          editorType="dxTextArea"
                          editorOptions={this.textArea_editorOptions}
                          colSpan={4}
                        >
                          <RequiredRule message={getTrad("campoNecesario")} />
                        </Item>
                        <TabbedItem cssClass="fill" colSpan={4}>
                          <TabPanelOptions
                            elementAttr={this.dxTabPanelParte_elementAttr}
                            deferRendering={false}
                            selectedIndex={0}
                            onContentReady={this.dxTabPanelParte_onContentReady}
                          />
                          <Tab title={getTrad("tecnicos")}>
                            <Item
                              dataField="tblPersonasNParte"
                              render={this.render_tblPersonasNParte}
                              label={this.formLabel_invisible}
                            />
                          </Tab>
                          {lavanderia.idCorporacion !== 1 ? (
                            <Tab title={getTrad("recambios")}>
                              <Item
                                dataField="tblRecambioNParteTrabajo"
                                render={this.render_tblRecambioNParteTrabajo}
                                label={this.formLabel_invisible}
                              />
                            </Tab>
                          ) : (
                            <Tab title={getTrad("recambios")}>
                              <Item
                                dataField="tblRecambioNParteTrabajoIBS"
                                render={this.render_tblRecambioNParteTrabajoIBS}
                                label={this.formLabel_invisible}
                              />
                            </Tab>
                          )}
                          <Tab title={getTrad("serviciosExternos")}>
                            <Item
                              dataField="tblServicioExternoNParteTrabajo"
                              render={
                                this.render_tblServicioExternoNParteTrabajo
                              }
                              label={this.formLabel_invisible}
                            />
                          </Tab>
                        </TabbedItem>
                      </Form>
                    </div>
                  </form>
                </Col>
                <Col xs="12" md="6" className="he-100">
                  <DataGrid
                    //Datos
                    ref={this.dxDataGrid_tblParteTrabajo_REF}
                    dataSource={this.datasource_tblParteTrabajo}
                    //Propiedades
                    columnsAutoWidth={true}
                    height={"100%"}
                    hoverStateEnabled={true}
                    selection={this.tblParteTrabajo_selection}
                    selectedRowKeys={
                      parteTrabajoSel && parteTrabajoSel.idParteTrabajo
                        ? [parteTrabajoSel.idParteTrabajo]
                        : []
                    }
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onToolbarPreparing={this.onToolbarPreparing_tblParteTrabajo}
                    onRowRemoving={this.onRowRemoving_tblParteTrabajo}
                    onRowRemoved={this.onRowRemoved_tblParteTrabajo}
                    onSelectionChanged={this.onSelectionChanged_tblParteTrabajo}
                    onRowPrepared={this.onRowPrepared_tblParteTrabajo}
                  >
                    <SearchPanel visible={true} width={240} />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={this.tblParteTrabajo_allowedPageSizes}
                      showInfo={true}
                    />
                    <Paging defaultPageSize={50} />
                    <Column
                      dataField="codigo"
                      caption={getTrad("codigo")}
                      width={100}
                      alignment="center"
                    />
                    <Column
                      dataField="fecha"
                      caption={getTrad("fecha")}
                      width={120}
                      alignment="center"
                      format={this.date_format}
                      sortOrder={"desc"}
                    />
                    <Column
                      dataField="idMaquina"
                      caption={getTrad("maquina")}
                      allowHeaderFiltering={true}
                      editorOptions={this.columnEditorOptions}
                    >
                      <Lookup
                        dataSource={this.datasource_tblMaquina.items()}
                        valueExpr={"idMaquina"}
                        displayExpr={"denominacion"}
                      />
                    </Column>
                    <Column
                      dataField="tblIncidenciaNParte[0].idIncidenciaNavigation.codigo"
                      caption={getTrad("incidencia")}
                      width={120}
                      alignment="center"
                      editorOptions={this.columnEditorOptions}
                      calculateFilterExpression={function (
                        filterValue,
                        filterOperation,
                      ) {
                        return [
                          "tblIncidenciaNParte/any(l: contains(tolower(l/idIncidenciaNavigation/codigo),'" +
                            filterValue +
                            "'))",
                        ];
                      }}
                    />
                  </DataGrid>
                </Col>
              </Row>
            </ScrollView>
            <Popup
              title={"Añadir técnicos"}
              showTitle={true}
              width={600}
              height={500}
              visible={showPopup_Add_tblPersonasNParte}
              closeOnOutsideClick={false}
              onShown={this.onShown_popup_Add_tblPersonasNParte}
              onHiding={this.hiding_popup_Add_tblPersonasNParte}
            >
              <DataGrid
                //Datos
                ref={this.dxDataGrid_tblPersonasNParte_REF}
                //Propiedades
                height="100%"
                columnsAutoWidth={true}
                hoverStateEnabled={true}
                //Estilos
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
              >
                <SearchPanel visible={false} />
                <HeaderFilter visible={false} />
                <FilterRow visible={false} />
                <Pager visible={false} />
                <Editing mode="cell" allowUpdating={true} />
                <Column dataField="idPersona" visible={false} />
                <Column
                  dataField="nombreCompuesto"
                  caption={getTrad("nombre")}
                  allowEditing={false}
                />
                <Column
                  dataField="horas"
                  caption={getTrad("horas")}
                  alignment="center"
                  dataType="datetime"
                  width={80}
                  format="HH:mm"
                  mask="00:00"
                  cssClass="dx-Cell_Editable"
                  editorOptions={this.time_editorOptions}
                />
              </DataGrid>
              <ToolbarItem_popup toolbar="bottom" location="after">
                <Button
                  text={getTrad("aceptar")}
                  type="success"
                  onClick={this.onClick_btnAddPersonasNParte}
                />
              </ToolbarItem_popup>
            </Popup>

            <PopupAñadirRecambio
              visible={showPopup_Add_tblRecambioNParteTrabajo}
              parteTrabajoSel={parteTrabajoSel}
              onHiding={this.hiding_popup_Add_tblRecambioNParteTrabajo}
              onSaveRecambiosNParteTrabajo={
                this.onClick_btnAddRecambioNParteTrabajo
              }
            />
            <div id="popup_insertarPreventivo_config"></div>
            <div id="popup_imprimirPreventivo"></div>
            <div id="popup_insertarPreventivo"></div>
          </div>
        </div>
      </Fragment>
    );
  }

  //FORMATS
  date_format = "dd/MM/yyyy";
  decimal_format = { style: "decimal", maximumFractionDigits: 2 };
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: this.props.lavanderia.moneda,
  };

  //EDITORES
  decimal_editorOptions = {
    format: this.decimal_format,
    step: 0,
    min: -999999999,
    max: 999999999,
  };

  currency_editorOptions = {
    format: this.currency_format,
    step: 0,
    min: 0,
    max: 999999.99,
  };

  disabled_editorOptions = {
    disabled: true,
  };

  columnEditorOptions = {
    showClearButton: true,
  };

  dateTime_editorOptions = {
    elementAttr: {
      class: "dxDateBoxForm",
    },
    type: "datetime",
    width: "100%",
    displayFormat: "dd/MM/yyyy - HH:mm",
  };

  time_editorOptions = {
    displayFormat: "HH:mm",
    showAnalogClock: false,
    showDropDownButton: false,
    spellcheck: true,
    type: "time",
    useMaskBehavior: true,
  };

  idMaquina_editorOptions = {
    dataSource: this.datasource_tblMaquina,
    displayExpr: "denominacion",
    valueExpr: "idMaquina",
    searchExpr: ["denominacion", "etiqueta", "numSerie"],
    cleanSearchOnOpening: true,
    searchEnabled: true,
    dropDownOptions: {
      title: getTrad("maquina"),
      closeOnOutsideClick: true,
    },
  };

  estadoMaquina_editorOptions = {
    dataSource: this.enum_estadosMaquina,
    displayExpr: "denominacion",
    valueExpr: "estadoMaquina",
  };

  textArea_editorOptions = {
    height: "75px",
  };

  btnSubmit_buttonOptions = {
    text: getTrad("aceptar"),
    type: "success",
    useSubmitBehavior: true,
  };

  estadoActual_editorOptions = {
    dataSource: this.enum_EstadoParteTrabajo,
    displayExpr: "estado",
    valueExpr: "valor",
  };

  formLabel_invisible = { visible: false };
  formLabel_fecha = { text: getTrad("fecha") };
  formLabel_maquina = { text: getTrad("maquina") };
  formLabel_incidencia = { text: getTrad("incidencia") };
  formLabel_descripcionResolucion = { text: getTrad("descripcionResolucion") };

  renderEditCell_estadoMaquina = (itemData) => {
    let estadoMaquina = this.enum_estadosMaquina.find(
      (x) => x.estadoMaquina === itemData.data.estadoMaquina,
    );
    if (estadoMaquina) {
      return (
        <div className="container_spanCentrado">
          <div
            className="pr-2 pl-2"
            style={{
              backgroundColor: estadoMaquina.color,
              borderRadius: "5px",
              height: "20px",
              width: "15px",
            }}
          ></div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  colCountByScreen = { xl: 4, lg: 4, md: 4, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //CONFIG GRID
  tblParteTrabajo_selection = { mode: "single" };
  tblParteTrabajo_allowedPageSizes = [20, 50, 100];

  onToolbarPreparing_tblParteTrabajo(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("partesAnteriores"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }
  onRowRemoving_tblParteTrabajo(e) {
    this.datasource_tblParteTrabajo.store().remove(e.key);
  }

  onRowRemoved_tblParteTrabajo(e) {
    let { parteTrabajoSel } = this.state;
    if (e.key === parteTrabajoSel.idParteTrabajo) {
      this.crearParteTrabajo();
    }
  }

  hiding_popup_Add_tblRecambioNParteTrabajo() {
    this.setState({ showPopup_Add_tblRecambioNParteTrabajo: false });
  }

  onRowRemoved_tblRecambioNAlmacenRecambios(e) {
    $(".dxDateBoxForm")
      .dxDateBox("instance")
      .option("disabled", e.component.getDataSource().items().length > 0);
  }

  onClick_btnAddRecambioNParteTrabajo = (tblRecambioNParteTrabajo) => {
    const { parteTrabajoSel } = this.state;

    $(".dxDateBoxForm")
      .dxDateBox("instance")
      .option("disabled", tblRecambioNParteTrabajo.length > 0);

    this.setState(
      { parteTrabajoSel: { ...parteTrabajoSel, tblRecambioNParteTrabajo } },
      () => {
        this.dxDataGrid_tblRecambioNParteTrabajo.refresh();
      },
    );
  };

  onSelectionChanged_tblParteTrabajo(e) {
    const data = e.selectedRowsData[0];
    if (data) {
      let parteSel = $.extend(true, {}, data);
      this.setState({ parteTrabajoSel: parteSel });
    } else {
      this.crearParteTrabajo();
    }
    this.dxDataGrid_tblRecambioNParteTrabajo.repaint();

    setTimeout(() => {
      $(".dxDateBoxForm")
        .dxDateBox("instance")
        .option(
          "disabled",
          this.state.parteTrabajoSel.tblRecambioNParteTrabajo.length > 0,
        );
    }, 0);
  }

  onRowPrepared_tblParteTrabajo(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  //PARTES DE TRABAJO
  crearParteTrabajo() {
    let { lavanderia } = this.props;
    let parteTrabajoSel = {};

    parteTrabajoSel.fecha = new Date();
    parteTrabajoSel.idLavanderia = lavanderia.idLavanderia;
    parteTrabajoSel.tblPersonasNParte = [];
    parteTrabajoSel.tblRecambioNParteTrabajo = [];
    parteTrabajoSel.tblRecambioNParteTrabajoIBS = [];
    parteTrabajoSel.tblServicioExternoNParteTrabajo = [];
    parteTrabajoSel.tblIncidenciaNParte = [];

    this.setState({ parteTrabajoSel: parteTrabajoSel });
    this.dxDataGrid_tblRecambioNParteTrabajo.repaint();
    $(".dxTabPanelParte").dxTabPanel("instance").resetOption("selectedIndex");
    $(".dxDateBoxForm").dxDateBox("instance").option("disabled", false);
  }

  onContentReady_form_parteTrabajo(e) {
    var form = e.component;
    let parteTrabajoSel = form.option("formData");
    form
      .getEditor("idMaquina")
      .option(
        "disabled",
        parteTrabajoSel &&
          parteTrabajoSel.tblIncidenciaNParte &&
          parteTrabajoSel.tblIncidenciaNParte.length > 0,
      );
  }

  render_tblPersonasNParte = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        //Datos
        dataSource={parteTrabajoSel.tblPersonasNParte}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onToolbarPreparing={this.onToolbarPreparing_tblPersonasNParte}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} />
        <Column
          dataField="idPersona"
          caption={getTrad("nombre")}
          allowEditing={false}
        >
          <Lookup
            dataSource={this.datasource_tblPersona_Tecnicos_all.items()}
            valueExpr={"idPersona"}
            displayExpr={"nombreCompuesto"}
          />
        </Column>
        <Column
          dataField="horas"
          caption={getTrad("horas")}
          alignment="center"
          dataType="datetime"
          width={80}
          format="HH:mm"
          mask="00:00"
          cssClass="dx-Cell_Editable"
          editorOptions={this.time_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onToolbarPreparing_tblPersonasNParte(e) {
    let _this = this;
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirTecnico"),
        type: "normal",
        onClick: function () {
          _this.dxDataGrid_tblPersonasNParte.option(
            "dataSource",
            _this.getDataSource_Add_tblPersonasNParte(),
          );
          _this.setState({ showPopup_Add_tblPersonasNParte: true });
        },
      },
    });
  }

  onShown_popup_Add_tblPersonasNParte(e) {
    this.dxDataGrid_tblPersonasNParte.beginUpdate();
    this.dxDataGrid_tblPersonasNParte.getScrollable().scrollTo(0);
    this.dxDataGrid_tblPersonasNParte.updateDimensions();
    this.dxDataGrid_tblPersonasNParte.endUpdate();
  }

  hiding_popup_Add_tblPersonasNParte() {
    this.setState({ showPopup_Add_tblPersonasNParte: false });
  }

  //Se obtienen los técnicos totales de la Lavanderia, con las horas del parte seleccionado
  getDataSource_Add_tblPersonasNParte() {
    let { parteTrabajoSel } = this.state;
    let tecnicos_all = [...this.datasource_tblPersona_Tecnicos.items()];

    if (parteTrabajoSel) {
      let datasource = $.grep(tecnicos_all, function (item) {
        var personaEncontrada = $.grep(
          parteTrabajoSel.tblPersonasNParte,
          function (i) {
            return item.idPersona === i.idPersona;
          },
        );
        return personaEncontrada.length === 0;
      });

      return datasource.map(function (tec) {
        return {
          idPersona: tec.idPersona,
          nombreCompuesto: tec.nombreCompuesto,
          horas: new Date(2000, 1, 0, 0, 0, 0),
        };
      });
    } else {
      return [];
    }
  }

  onClick_btnAddPersonasNParte() {
    let _this = this;
    let { parteTrabajoSel } = this.state;
    let tecnicosAdd = this.dxDataGrid_tblPersonasNParte.option("dataSource");

    this.dxDataGrid_tblPersonasNParte.saveEditData().done(function () {
      let tecnicosMod = tecnicosAdd.filter(function (tecnico) {
        var horaTecnico = tecnico.horas.getHours();
        var minutoTecnico = tecnico.horas.getMinutes();

        if (minutoTecnico !== 0 || horaTecnico !== 0) {
          return true;
        }

        return false;
      });

      tecnicosMod = tecnicosMod.map(function (tecnico) {
        return {
          idPersona: tecnico.idPersona,
          horas: tecnico.horas,
        };
      });
      parteTrabajoSel.tblPersonasNParte =
        parteTrabajoSel.tblPersonasNParte.concat(tecnicosMod);
      _this.setState({
        showPopup_Add_tblPersonasNParte: false,
        parteTrabajoSel: parteTrabajoSel,
      });
    });
  }

  dxTabPanelParte_elementAttr = { class: "h4 dxTabPanelParte" };

  dxTabPanelParte_onContentReady(e) {
    e.element.find(".dx-tabs").addClass("fill");
    e.element
      .find(".dx-item-content.dx-multiview-item-content")
      .css("padding", 5);
  }

  render_tblRecambioNParteTrabajo = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        //Datos
        ref={this.dxDataGrid_tblRecambioNParteTrabajo_REF}
        dataSource={parteTrabajoSel.tblRecambioNParteTrabajo}
        //Propiedades
        columnsAutoWidth={true}
        height="100%"
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        loadPanel={{ enabled: false }}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblRecambioNParteTrabajo}
        onCellPrepared={(e) => {
          if (e.rowType === "data" && e.column.dataField === "precio") {
            let codigo =
              e.data.idAlmacenNavigation.idMonedaNavigation !== null &&
              e.data.idAlmacenNavigation.idMonedaNavigation !== undefined
                ? e.data.idAlmacenNavigation.idMonedaNavigation.codigo
                : "EUR";
            return formatNumber(e.data.precio, 2, "currency", codigo);
          }
        }}
        onRowRemoved={this.onRowRemoved_tblRecambioNAlmacenRecambios}
        onToolbarPreparing={this.onToolbarPreparing_tblRecambioNParteTrabajo}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Column
          dataField="idRecambioNavigation.referencia"
          caption={getTrad("refFabricante")}
          alignment="center"
          width={110}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="idRecambioNavigation.denominacion"
          caption={getTrad("denominacion")}
          sortOrder="asc"
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="idAlmacenNavigation.denominacion"
          caption={getTrad("almacen")}
          alignment="left"
        />
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={100}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          width={120}
          calculateDisplayValue={this.calculateDisplayValue_precio}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={120}
          allowEditing={false}
          calculateDisplayValue={this.calculateDisplayValue_importeRecambio}
        />
        <Column
          caption=" "
          width={30}
          alignment="center"
          cssClass="p-0"
          visible={
            parteTrabajoSel !== null &&
            parteTrabajoSel.idParteTrabajo === undefined
          }
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onInitNewRow_tblRecambioNParteTrabajo(e) {
    e.data.cantidad = 0;
  }
  calculateDisplayValue_precio(rowData) {
    let codigo =
      rowData.idAlmacenNavigation.idMonedaNavigation !== null &&
      rowData.idAlmacenNavigation.idMonedaNavigation !== undefined
        ? rowData.idAlmacenNavigation.idMonedaNavigation.codigo
        : "EUR";
    return formatNumber(rowData.precio, 2, "currency", codigo);
  }
  calculateDisplayValue_importeRecambio(rowData) {
    let codigo =
      rowData.idAlmacenNavigation.idMonedaNavigation !== null &&
      rowData.idAlmacenNavigation.idMonedaNavigation !== undefined
        ? rowData.idAlmacenNavigation.idMonedaNavigation.codigo
        : "EUR";
    let importe = rowData.precio * rowData.cantidad;

    return formatNumber(importe, 2, "currency", codigo);
  }
  onToolbarPreparing_tblRecambioNParteTrabajo(e) {
    let _this = this;
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.height = 36;

    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirRecambio"),
        type: "normal",
        onClick: function () {
          _this.setState({ showPopup_Add_tblRecambioNParteTrabajo: true });
        },
      },
    });
  }

  render_tblRecambioNParteTrabajoIBS = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        ref={this.dxDataGrid_tblRecambioNParteTrabajo_REF}
        //Datos
        dataSource={parteTrabajoSel.tblRecambioNParteTrabajoIBS}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblRecambioNParteTrabajoIBS}
        onToolbarPreparing={this.onToolbarPreparing_tblRecambioNParteTrabajoIBS}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} allowAdding={true} />
        <Column
          dataField="referenciaRecambio"
          caption={getTrad("referencia")}
          alignment="center"
          width={100}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="denominacion"
          caption={getTrad("denominacion")}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={90}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          width={120}
          format={this.currency_format}
          editorOptions={this.currency_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={145}
          allowEditing={false}
          format={this.currency_format}
          calculateDisplayValue={this.calculateDisplayValue_importeRecambioIBS}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onInitNewRow_tblRecambioNParteTrabajoIBS(e) {
    e.data.cantidad = 0;
    e.data.precio = 0;
  }
  calculateDisplayValue_importeRecambioIBS(rowData) {
    return rowData.precio * rowData.cantidad;
  }
  onToolbarPreparing_tblRecambioNParteTrabajoIBS(e) {
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirRecambio"),
        type: "normal",
        onClick: function () {
          e.component.addRow();
        },
      },
    });
  }

  render_tblServicioExternoNParteTrabajo = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        ref={this.dxDataGrid_tblServicioExternoNParteTrabajo_REF}
        //Datos
        dataSource={parteTrabajoSel.tblServicioExternoNParteTrabajo}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblServicioExternoNParteTrabajo}
        onToolbarPreparing={
          this.onToolbarPreparing_tblServicioExternoNParteTrabajo
        }
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} allowAdding={true} />
        <Column
          dataField="denominacion"
          caption={getTrad("denominacion")}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={90}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          width={120}
          format={this.currency_format}
          editorOptions={this.currency_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={145}
          allowEditing={false}
          format={this.currency_format}
          calculateDisplayValue={this.calculateDisplayValue_importeServicio}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onInitNewRow_tblServicioExternoNParteTrabajo(e) {
    e.data.cantidad = 0;
    e.data.precio = 0;
  }
  calculateDisplayValue_importeServicio(rowData) {
    return rowData.precio * rowData.cantidad;
  }
  onToolbarPreparing_tblServicioExternoNParteTrabajo(e) {
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirServicio"),
        type: "normal",
        onClick: function () {
          e.component.addRow();
        },
      },
    });
  }

  enviando = false;
  submit_formParteTrabajo(e) {
    e.preventDefault();
    if (!this.enviando) {
      this.enviando = true;
      let _this = this;
      let { parteTrabajoSel } = this.state;
      let parteSel = $.extend(true, {}, parteTrabajoSel);

      $.each(parteSel.tblRecambioNParteTrabajoIBS, function (index, recambio) {
        delete recambio.__KEY__;
      });

      $.each(
        parteSel.tblServicioExternoNParteTrabajo,
        function (index, servicio) {
          delete servicio.__KEY__;
        },
      );

      $.each(parteSel.tblIncidenciaNParte, function (index, item) {
        delete item.idIncidenciaNavigation;
      });

      $.each(parteSel.tblRecambioNParteTrabajo, function (index, item) {
        delete item.__KEY__;
        delete item.referencia;
        delete item.importe;
        delete item.idRecambioNavigation;
        delete item.idAlmacenNavigation;
      });

      if (parteSel.idParteTrabajo) {
        //UPDATE, eliminamos el parte trabajo (Original) que se acaba de modificar
        const idParteTrabajo = parteSel.idParteTrabajo;
        delete parteSel.idParteTrabajo;

        this.datasource_tblParteTrabajo
          .store()
          .update(idParteTrabajo, patchRequestHandler(parteSel))
          .then(
            () => {
              _this.datasource_tblParteTrabajo.reload();
              _this.enviando = false;
            },
            (e) => {
              _this.enviando = false;
            },
          );
      } else {
        //INSERT
        parteSel.fecha = formatDateTime_parameter(
          new Date(
            new Date(parteSel.fecha).setHours(parteSel.fecha.getUTCHours()),
          ),
        );

        this.datasource_tblParteTrabajo
          .store()
          .insert(parteSel)
          .then(
            (e) => {
              _this.datasource_tblParteTrabajo.reload().done(() => {
                _this.setState({
                  parteTrabajoSel: {
                    ..._this.state.parteTrabajoSel,
                    idParteTrabajo: e.idParteTrabajo,
                  },
                });
                _this.enviando = false;
              });
            },
            (e) => {
              _this.enviando = false;
            },
          );
      }
    }
  }

  //PREVENTIVO
  imprimirPreventivo() {
    return (
      <Button
        text={getTrad("imprimirPrev")}
        width="100%"
        icon=" icon_Impresora"
        className="mr-2"
        onClick={this.onClick_imprimirPrev}
      />
    );
  }
  insertarPreventivo() {
    return (
      <Button
        text={getTrad("insertarPrev")}
        width="100%"
        icon="add"
        onClick={this.onClick_insertarPrev}
      />
    );
  }
  onClick_imprimirPrev() {
    $("#popup_imprimirPreventivo").dxPopup("instance").show();
  }
  onClick_insertarPrev() {
    $("#popup_insertarPreventivo_config").dxPopup("instance").show();
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    let { lavanderia, user, idioma } = this.props;
    cargaJs(this, lavanderia, user, idioma);
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, user, idioma } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.currency_format = {
        style: "currency",
        maximumFractionDigits: 2,
        currency: lavanderia.moneda,
      };

      this.currency_editorOptions = {
        format: this.currency_format,
        step: 0,
        min: 0,
        max: 999999.99,
      };

      this.dxDataGrid_tblServicioExternoNParteTrabajo.cancelEditData();
      this.dxDataGrid_tblRecambioNParteTrabajo.cancelEditData();

      cargaJs(this, lavanderia, user, idioma);
      this.cargaDatos_lavanderia();
    }
  }

  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdenesTrabajo);
