import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "constants";
import {
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  capitalize,
  formatDate_parameter,
  getLastMonth,
  getLastNMonth,
  formatDateTime_parameter,
} from "helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import Calendar from "devextreme-react/calendar";
import { Button } from "devextreme-react";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";

import notify from "devextreme/ui/notify";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { Popover } from "devextreme-react/popover";
import { LoadPanel } from "devextreme-react/load-panel";

//Css
import "./Css.scss";

const dxPopup_cierrePrecios = {
  of: "#dxPopup_cierrePrecios .dx-popup-content",
};
class PopupCierrePrecios extends React.PureComponent {
  state = {
    dxCalendar_cierrePrecios_value: null,
    isVisible_cierrePrecios: false,
    fechasCierreNAlmacen: null,
    isDisabled_btnCerrarPrecios: false,
    isVisible_dxLoadPanel: false,
    minFechaMovimiento: null,
  };
  constructor(props) {
    super(props);

    this.dxPopover_REF = React.createRef();
    this.dxCalendar_REF = React.createRef();

    this.datasource_GetFechasNAlmacen_beforeSend =
      this.datasource_GetFechasNAlmacen_beforeSend.bind(this);
    this.datasource_GetFechasNAlmacen_onLoading =
      this.datasource_GetFechasNAlmacen_onLoading.bind(this);
    this.datasource_SetPrecioCierreRecambioNAlmacen_beforeSend =
      this.datasource_SetPrecioCierreRecambioNAlmacen_beforeSend.bind(this);
    this.datasource_tblMovimientoRecambio_beforeSend =
      this.datasource_tblMovimientoRecambio_beforeSend.bind(this);
    this.datasource_tblMovimientoRecambio_onLoading =
      this.datasource_tblMovimientoRecambio_onLoading.bind(this);

    this.onHiding = this.onHiding.bind(this);
    this.btnCerrarPrecios_cierrePrecios_onClick =
      this.btnCerrarPrecios_cierrePrecios_onClick.bind(this);
    this.dxCalendar_monthSelector_cellRender =
      this.dxCalendar_monthSelector_cellRender.bind(this);
    this.dxCalendar_eventosLavanderia_onValueChanged =
      this.dxCalendar_eventosLavanderia_onValueChanged.bind(this);
    this.onShowing = this.onShowing.bind(this);
    this.onHidden = this.onHidden.bind(this);

    this.onHoverEnterDate = this.onHoverEnterDate.bind(this);
    this.onHoverLeaveDate = this.onHoverLeaveDate.bind(this);

    this.reload_mesesCierre = this.reload_mesesCierre.bind(this);
    this.get_isMesCerrado = this.get_isMesCerrado.bind(this);
    this.onHoverBtnCerrarPrecios = this.onHoverBtnCerrarPrecios.bind(this);
    this.onHoverLeaveBtnCerrarPrecios =
      this.onHoverLeaveBtnCerrarPrecios.bind(this);
  }

  get dxPopover() {
    return this.dxPopover_REF.current.instance;
  }

  get dxCalendar() {
    return this.dxCalendar_REF.current.instance;
  }

  datasource_GetFechasNAlmacen = new DataSource({
    paginate: false,
    store: new ODataStore({
      key: "id",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreRecambioNAlmacen/GetFechasNAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_GetFechasNAlmacen_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_GetFechasNAlmacen_onLoading(loadOptions);
      },
      version: 4,
    }),
  });

  datasource_GetFechasNAlmacen_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_GetFechasNAlmacen_onLoading(loadOptions) {
    let { almacenSel } = this.props;
    let idAlmacen = almacenSel != null ? almacenSel.idAlmacen : -1;
    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [["idAlmacen", "=", idAlmacen]],
      ];
    else loadOptions.filter = [["idAlmacen", "=", idAlmacen]];
  }

  datasource_SetPrecioCierreRecambioNAlmacen = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreRecambioNAlmacen/SetPrecioCierreRecambioNAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_SetPrecioCierreRecambioNAlmacen_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_SetPrecioCierreRecambioNAlmacen_beforeSend(request) {
    let { almacenSel } = this.props;
    let { dxCalendar_cierrePrecios_value } = this.state;

    request.headers = { ...authHeader() };
    request.params.idAlmacen = almacenSel != null ? almacenSel.idAlmacen : -1;
    request.params.fecha = formatDate_parameter(dxCalendar_cierrePrecios_value);
  }

  datasource_tblMovimientoRecambio = new DataSource({
    paginate: true,
    pageSize: 1,
    store: new ODataStore({
      key: "id",
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimientoRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblMovimientoRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblMovimientoRecambio_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: ["fecha"],
    sort: ["fecha asc"],
  });

  datasource_tblMovimientoRecambio_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblMovimientoRecambio_onLoading(loadOptions) {
    let { almacenSel } = this.props;
    let idAlmacen = almacenSel != null ? almacenSel.idAlmacen : -1;

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          ["idAlmacenOrigen", "=", idAlmacen],
          "or",
          ["idAlmacenDestino", "=", idAlmacen],
        ],
      ];
    else
      loadOptions.filter = [
        ["idAlmacenOrigen", "=", idAlmacen],
        "or",
        ["idAlmacenDestino", "=", idAlmacen],
      ];
  }

  render() {
    let {
      dxCalendar_cierrePrecios_value,
      isVisible_cierrePrecios,
      fechasCierreNAlmacen,
      isDisabled_btnCerrarPrecios,
      isVisible_dxLoadPanel,
      minFechaMovimiento,
    } = this.state;

    let isMesCerrado = this.get_isMesCerrado(dxCalendar_cierrePrecios_value);
    let mesAnterior =
      dxCalendar_cierrePrecios_value == null
        ? null
        : getLastMonth(new Date(dxCalendar_cierrePrecios_value))[0];

    return (
      <Fragment>
        <LoadPanel
          deferRendering={false}
          animation={null}
          position={dxPopup_cierrePrecios}
          visible={isVisible_dxLoadPanel}
          shadingColor="transparent"
          showIndicator={true}
          shading={true}
          showPane={true}
        />
        <Popup
          title={getTrad("cierrePrecios")}
          width={400}
          height={450}
          visible={isVisible_cierrePrecios}
          onContentReady={this.onContentReady}
          onShowing={this.onShowing}
          onHiding={this.props.onHiding ? this.onHiding : undefined}
          onHidden={this.onHidden}
        >
          <Box direction="col" width="100%" height="100%">
            <ItemBox ratio={1}>
              <Calendar
                ref={this.dxCalendar_REF}
                firstDayOfWeek={1}
                disabledDates={fechasCierreNAlmacen}
                value={dxCalendar_cierrePrecios_value}
                height={"100%"}
                width={"100%"}
                maxZoomLevel="year"
                minZoomLevel="decade"
                min={minFechaMovimiento}
                max={new Date()}
                cellRender={this.dxCalendar_monthSelector_cellRender}
                onValueChanged={
                  this.dxCalendar_eventosLavanderia_onValueChanged
                }
              />
            </ItemBox>
            <ItemBox baseSize={15} />
            <ItemBox baseSize={40}>
              <div
                onMouseEnter={this.onHoverBtnCerrarPrecios}
                onMouseLeave={this.onHoverLeaveBtnCerrarPrecios}
              >
                <Button
                  text={getTrad("cerrarPrecios")}
                  width={"100%"}
                  disabled={
                    isDisabled_btnCerrarPrecios ||
                    dxCalendar_cierrePrecios_value == null ||
                    (isMesCerrado == false && mesAnterior >= minFechaMovimiento)
                  }
                  type="normal"
                  onClick={this.btnCerrarPrecios_cierrePrecios_onClick}
                />
              </div>
            </ItemBox>
          </Box>
        </Popup>
        <Popover
          ref={this.dxPopover_REF}
          width={"auto"}
          height={"auto"}
        ></Popover>
      </Fragment>
    );
  }

  onHoverBtnCerrarPrecios(e) {
    let { minFechaMovimiento, dxCalendar_cierrePrecios_value } = this.state;
    let date = formatDate_parameter(new Date(dxCalendar_cierrePrecios_value));
    let isMesCerrado = this.get_isMesCerrado(new Date(date));

    let mesAnterior = date == null ? null : getLastMonth(new Date(date))[0];

    let is_disabledDay =
      date > new Date() ||
      date < new Date(minFechaMovimiento) ||
      mesAnterior < minFechaMovimiento;
    if (!is_disabledDay) {
      if (isMesCerrado == false && dxCalendar_cierrePrecios_value != null) {
        this.dxPopover.option("contentTemplate", function (contentElement) {
          $("<div/>")
            .append("El mes anterior debe estar cerrado.")
            .appendTo(contentElement);
        });
        this.dxPopover.option("target", e.target);
        this.dxPopover.show();
      }
    }
  }

  onHoverLeaveBtnCerrarPrecios() {
    this.dxPopover.hide();
  }

  onShowing(e) {
    this.reload_mesesCierre();
  }

  get_isMesCerrado(date) {
    let { fechasCierreNAlmacen } = this.state;
    let numMesesAbiertos = 1; //2
    let fechaSel_mesAnterior =
      date == null
        ? null
        : formatDate_parameter(getLastMonth(new Date(date))[0]);
    let fechaSel_dosMesesAnterior =
      date == null
        ? null
        : formatDate_parameter(
            getLastNMonth(new Date(date), numMesesAbiertos)[0]
          );

    let isMesCerrado =
      fechasCierreNAlmacen == null
        ? null
        : $.grep(fechasCierreNAlmacen, function (item) {
            return (
              item == fechaSel_mesAnterior || item == fechaSel_dosMesesAnterior
            );
          }).length > 0;

    return isMesCerrado;
  }

  reload_mesesCierre() {
    let _this = this;
    let { dxCalendar_cierrePrecios_value } = this.state;

    setTimeout(() => {
      this.setState({ isVisible_dxLoadPanel: true });
    }, 0);

    $.when(
      this.datasource_tblMovimientoRecambio.reload(),
      this.datasource_GetFechasNAlmacen.reload()
    ).then(function (minFechaMovimiento, maxFechaCierre) {
      let fechasCierre =
        maxFechaCierre[0].length > 0
          ? _this.getMonthDates(
              formatDateTime_parameter(minFechaMovimiento[0][0].fecha),
              maxFechaCierre[0][0].fecha
            )
          : [];

      let fechas = $.map(fechasCierre, function (item) {
        return item.split("T")[0] + "T00:00:00Z";
      });

      let isFechaConCierre =
        dxCalendar_cierrePrecios_value == null
          ? false
          : $.grep(fechas, function (item) {
              return (
                item == formatDate_parameter(dxCalendar_cierrePrecios_value)
              );
            }).length > 0;

      let primerDiaMes_minFechaMovimiento = null;
      if (minFechaMovimiento[0].length > 0) {
        let fecha = new Date(minFechaMovimiento[0][0].fecha);
        primerDiaMes_minFechaMovimiento = new Date(
          fecha.getFullYear(),
          fecha.getMonth(),
          1
        );
      }

      _this.setState(
        {
          minFechaMovimiento: primerDiaMes_minFechaMovimiento,
          fechasCierreNAlmacen: fechas,
          isDisabled_btnCerrarPrecios:
            dxCalendar_cierrePrecios_value == null ||
            (dxCalendar_cierrePrecios_value != null && isFechaConCierre),
          isVisible_dxLoadPanel: false,
        },
        () => {
          if (dxCalendar_cierrePrecios_value != null && !isFechaConCierre) {
            notify({
              message: "No hay ningún movimiento este mes.",
              type: "error",
              displayTime: "1500",
              closeOnClick: true,
            });
          }
        }
      );
    });
  }

  getMonthDates(startDate, endDate) {
    var start = startDate.split("-");
    var end = endDate.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }
    return dates;
  }

  onContentReady(e) {
    e.component.content().attr("id", "dxPopup_cierrePrecios");
  }

  onHiding(e) {
    this.setState({
      isVisible_cierrePrecios: false,
    });
    this.props.onHiding(e);
  }

  onHidden(e) {
    this.setState({
      dxCalendar_cierrePrecios_value: undefined, // Para resetear año del calendario
      isDisabled_btnCerrarPrecios: false,
      minFechaMovimiento: null,
    });
  }

  dxCalendar_eventosLavanderia_onValueChanged(e) {
    let { fechasCierreNAlmacen } = this.state;

    let isDisabled_btnCerrarPrecios = false;
    if ($.inArray(formatDate_parameter(e.value), fechasCierreNAlmacen) >= 0) {
      isDisabled_btnCerrarPrecios = true;
    }

    this.setState({
      dxCalendar_cierrePrecios_value: e.value,
      isDisabled_btnCerrarPrecios: isDisabled_btnCerrarPrecios,
    });
  }

  dxCalendar_monthSelector_cellRender(e) {
    let { idioma, resolucion } = this.props;
    let { fechasCierreNAlmacen, minFechaMovimiento } = this.state;

    let className = "";
    let date = formatDate_parameter(e.date);
    let mesAnterior = e.date == null ? null : getLastMonth(new Date(e.date))[0];
    let is_disabledDay =
      e.date > new Date() || e.date < new Date(minFechaMovimiento);
    let is_fechaCerrada = $.inArray(date, fechasCierreNAlmacen) >= 0;

    let isMesCerrado = this.get_isMesCerrado(e.date);

    let icono;
    if (!is_disabledDay) {
      if (is_fechaCerrada) icono = "icon_lockOutline";
    }

    if (e.view === "decade") return e.date.getFullYear();
    else if (e.view === "year")
      return (
        <div
          className={
            "d-flex flex-column he-100 justify-content-center " +
            (is_disabledDay && "disabledDay")
          }
        >
          <div
            id={date}
            className={
              "he-100 w-100 d-flex align-items-center justify-content-center position-relative " +
              className
            }
            onMouseEnter={(e) => this.onHoverEnterDate(e, date)}
            onMouseLeave={this.onHoverLeaveDate}
          >
            {capitalize(
              e.date.toLocaleString(idioma.codigo, {
                month: resolucion === "xs" ? "short" : "long",
              })
            )}
            <div className={icono ? icono + " fechaCerrada" : ""} />
          </div>
        </div>
      );
  }

  onHoverEnterDate(e, date) {
    let { fechasCierreNAlmacen, minFechaMovimiento } = this.state;
    let isMesCerrado = this.get_isMesCerrado(new Date(date));

    let mesAnterior = date == null ? null : getLastMonth(new Date(date))[0];

    let is_disabledDay =
      date > new Date() ||
      date < new Date(minFechaMovimiento) ||
      mesAnterior < minFechaMovimiento;
    if (!is_disabledDay) {
      let isDateCerrada = $.inArray(date, fechasCierreNAlmacen) >= 0;
      if (isDateCerrada) {
        this.dxPopover.option("contentTemplate", function (contentElement) {
          $("<div/>").append("Mes cerrado").appendTo(contentElement);
        });
        this.dxPopover.option("target", e.target);
        this.dxPopover.show();
      }
    }
  }

  onHoverLeaveDate() {
    this.dxPopover.hide();
  }

  btnCerrarPrecios_cierrePrecios_onClick(e) {
    let _this = this;
    dxMensajePregunta(getTrad("aviso_cierrePrecios"), [
      [
        getTrad("aceptar"),
        function () {
          _this.setState({ isVisible_dxLoadPanel: true });
          _this.datasource_SetPrecioCierreRecambioNAlmacen
            .load()
            .done(function () {
              _this.reload_mesesCierre();
              _this.dxCalendar.repaint();
              notify({
                message: getTrad("aviso_C_RegistroActualizado"),
                type: "success",
                displayTime: "1500",
                closeOnClick: true,
              });
            });
        },
        "danger",
      ],
      [getTrad("cancelar"), function () {}],
    ]);
  }

  componentDidUpdate(prevProps) {
    let { isVisible_cierrePrecios } = this.props;
    if (prevProps.isVisible_cierrePrecios !== isVisible_cierrePrecios) {
      this.setState({
        isVisible_cierrePrecios: isVisible_cierrePrecios,
      });
    }
  }
}

const mapStateToProps = (state) => ({
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(PopupCierrePrecios);
