import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

const { errorHandler, authHeader } = require("helpers");

export function ds_errorHandler(error) {
  errorHandler(error, null);
}

const dataSource_beforeSend = (request) => {
  request.headers = { ...authHeader() };
};

export const dataSource_tblSolicitudGestoria = (idEstadoSolicitudGestoria_sel) => {
  const expandPersona =
    "idPersonaNavigation($select=idPersona,apellidos,nombre,codigoGestoria, idEmpresaPolarier, idLavanderia, idCategoriaInterna;";
  const expandsDePersona =
    "$expand=idLavanderiaNavigation($select=denominacion),idCentroTrabajoNavigation($select=denominacion),idCategoriaInternaNavigation($select=idCategoriaConvenio),tblPersonaNTipoContrato($select=idTipoContrato;$filter=fechabajacontrato eq null;$orderby=fechabajacontrato desc;$top=1))";

  const allExpands = [expandPersona + expandsDePersona];

  return new DataSource({
    store: new ODataStore({
      key: "idSolicitudGestoria",
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblSolicitudGestoria",
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      onLoading: (loadOptions) => dataSource_onLoading_tblSolicitudGestoria(loadOptions, idEstadoSolicitudGestoria_sel),
      version: 4,
    }),
    paginate: false,
    select: ["idSolicitudGestoria", "idEstadoSolicitudGestoria", "fecha_alta", "fecha_reg", "isUrgente"],
    expand: allExpands,
    sort: ["idPersonaNavigation.nombre"],
    postProcess: (data) => {
      return postProcess_dataSource_tblSolicitudGestoria(data);
    },
  });
};

function postProcess_dataSource_tblSolicitudGestoria(data) {
  const result = data.map((item) => {
    const { idPersonaNavigation } = item;
    const { idCategoriaInternaNavigation } = idPersonaNavigation || {};
    const { idLavanderiaNavigation } = idPersonaNavigation || {};
    const { tblPersonaNTipoContrato } = idPersonaNavigation || {};
    const { idCentroTrabajoNavigation } = idPersonaNavigation || {};

    return {
      idEstadoSolicitudGestoria: item.idEstadoSolicitudGestoria,
      fecha_alta: item.fecha_alta,
      fecha_reg: item.fecha_reg,
      idPersonaNavigation: {
        apellidos: idPersonaNavigation.apellidos,
        codigoGestoria: idPersonaNavigation.codigoGestoria,
        idCategoriaInternaNavigation: {
          idCategoriaConvenio: idCategoriaInternaNavigation.idCategoriaConvenio,
        },
        idCentroLav: idPersonaNavigation.idLavanderia
          ? idLavanderiaNavigation.denominacion
          : idCentroTrabajoNavigation.denominacion,
        idEmpresaPolarier: idPersonaNavigation.idEmpresaPolarier,
        idPersona: idPersonaNavigation.idPersona,
        nombre: idPersonaNavigation.nombre,
        tblPersonaNTipoContrato: tblPersonaNTipoContrato,
        idCategoriaInterna: idPersonaNavigation.idCategoriaInterna,
        idTipoContrato: tblPersonaNTipoContrato[0].idTipoContrato,
      },
      idSolicitudGestoria: item.idSolicitudGestoria,
      isUrgente: item.isUrgente,
    };
  });
  return result;
}

function dataSource_onLoading_tblSolicitudGestoria(loadOptions, idEstadoSolicitudGestoria_sel) {
  let filter = [];
  if (idEstadoSolicitudGestoria_sel === true) {
    filter = ["idEstadoSolicitudGestoria", "=", 3]; // Aceptado
  } else {
    filter = ["idEstadoSolicitudGestoria", "<>", 3]; // Pendiente codigo gestoria o pendiente documentacion
  }

  if (loadOptions.filter) loadOptions.filter = [loadOptions.filter, "and", filter];
  else loadOptions.filter = filter;
}

export const datasource_tblPersona = (idPersona, select = null, expandSel = null) => {
  const defaultExpand = [
    "idTipoDocumentoIdentidadNavigation($select=denominacion)",
    "idComunidadAutonomaNavigation($select=denominacion)",
    "idPaisNavigation($select=denominacion)",
    "idFotoDocumentoIdentidad_ANavigation ($select=documento)",
    "idFotoDocumentoIdentidad_BNavigation ($select=documento)",
    "idFotoNAFNavigation ($select=documento)",
    "idFotoIBANNavigation ($select=documento)",
    "idFotoPerfilNavigation ($select=documento)",
    "idTurnoNavigation($select=denominacion)",
    "tblDatosSalariales($select=salarioBase,plusAsistencia,numPagas)",
  ];

  const expand =
    select == null && expandSel == null // Si no se especifica select ni expand
      ? defaultExpand // Se expanden todos los campos por defecto
      : select != null && expandSel == null // Si se especifica select pero no expand
      ? [] // No se expande nada
      : expandSel != null // Si se especifica expand
      ? expandSel // Se expanden los campos especificados
      : defaultExpand;

  return new DataSource({
    store: new ODataStore({
      key: "idPersona",
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      onLoading: (loadOptions) => dataSource_onLoading_tblPersona(loadOptions, idPersona),
      version: 4,
    }),
    paginate: false,
    select: select,
    expand: expand,
  });
};

function dataSource_onLoading_tblPersona(loadOptions, idPersona) {
  if (!loadOptions.filter) loadOptions.filter = [];
  if (idPersona) {
    loadOptions.filter = ["idPersona", "=", idPersona];
  }
}

export const datasource_persona_codigoGestoria = (codigoGestoria) =>
  new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        datasource_persona_codigoGestoria_beforeSend(request);
      },
      onLoading: (data) => {
        datasource_persona_codigoGestoria_onLoading(data, codigoGestoria);
      },
      version: 4,
    }),
    select: ["codigoGestoria", "isCodigoGestoriaValidado", "idPersona"],
  });

function datasource_persona_codigoGestoria_beforeSend(request) {
  request.headers = { ...authHeader() };
  request.params.filtroTipoTrabajoRRHH = false;
}

function datasource_persona_codigoGestoria_onLoading(loadOptions, codigoGestoria) {
  loadOptions.filter = "eliminado eq false and codigoGestoria eq '" + codigoGestoria + "'";
}

export const datasource_empresaPolarier_solicitudGestoria = (estadoSel_aceptado) => {
  return new DataSource({
    store: new ODataStore({
      key: "idEmpresaPolarier",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Gestoria/empresaPolarier_solicitudGestoria?estadoAceptado=" +
        estadoSel_aceptado,
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      version: 4,
    }),
    paginate: false,
  });
};

export const dataSource_categoriasConvenio_solicitudGestoria = (estadoSel_aceptado) => {
  return new DataSource({
    store: new ODataStore({
      key: "idCategoriaConvenio",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Gestoria/categoriasConvenio_solicitudGestoria?estadoAceptado=" +
        estadoSel_aceptado,
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      version: 4,
    }),
    paginate: false,
  });
};

export const dataSource_categoriaInterna_solicitudGestoria = (estadoSel_aceptado) => {
  return new DataSource({
    store: new ODataStore({
      key: "idCategoriaInterna",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Gestoria/categoriaInterna_solicitudGestoria?estadoAceptado=" +
        estadoSel_aceptado,
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      version: 4,
    }),
    paginate: false,
  });
};

export const dataSource_tipoContrato_solicitudGestoria = (estadoSel_aceptado) => {
  return new DataSource({
    store: new ODataStore({
      key: "idTipoContrato",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Gestoria/tipoContrato_solicitudGestoria?idEstadoSolicitudGestoria=" +
        estadoSel_aceptado,
      errorHandler: ds_errorHandler,
      beforeSend: (request) => dataSource_beforeSend(request),
      version: 4,
    }),
    paginate: false,
  });
};

export const datasource_tblDocumento = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
    key: "idDocumento",
    errorHandler: function (error) {
      errorHandler(error, null);
    },
    beforeSend: (request) => {
      datasource_tblDocumento_beforeSend(request);
    },
    version: 4,
  }),
});

function datasource_tblDocumento_beforeSend(request) {
  request.headers = { ...authHeader() };
  request.params.todos = true;
}
