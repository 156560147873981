import DataSource from "devextreme/data/data_source";
import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";

import idsTipoMovimientoRecambio from "constants/enums/Assistant/tblTipoMovimientoRecambio";
import { addAuthHeader } from "../utils";
import { authHeader, errorHandler, getNextNMonth } from "helpers";
import { connectionConstants } from "constants";

let this_idTipoMovimientoRecambio;

export const setIdTipoMovimientoRecambio = (idTipoMovimientoRecambio) =>
  (this_idTipoMovimientoRecambio = idTipoMovimientoRecambio);

// Función para determinar si un almacen es parte de los cierres
const esAlmacenEnCierres = (almacen, crna) => {
  return (
    almacen && crna.idAlmacen === (almacen.idAlmacenPadre ?? almacen.idAlmacen)
  );
};

// Función para calcular la fecha de inicio y fin para comparar
const calcularFechasComparacion = (fecha) => {
  const fechaDesde = new Date(getNextNMonth(new Date(fecha), 1)[0]);
  const fechaHasta = new Date(getNextNMonth(new Date(fecha), 3)[0]).getTime();
  return { fechaDesde, fechaHasta };
};

const datasource_tblMovimientoRecambio_beforeSend = (request) => {
  request.headers = { ...authHeader() };

  if (request.method === "get") {
    request.params.idTipoMovimientoRecambio = this_idTipoMovimientoRecambio;
  }
};

const datasource_tblMovimientoRecambio_postProcess = (data) => {
  return data.map((mr) => {
    const cierres = mr.idAlmacenDestino
      ? datasource_tblCierreRecambioNAlmacen
          .items()
          .filter(
            (crna) =>
              esAlmacenEnCierres(mr.idAlmacenOrigenNavigation, crna) ||
              esAlmacenEnCierres(mr.idAlmacenDestinoNavigation, crna)
          )
      : [];

    const isRegularizacion =
      mr.idTipoMovimientoRecambio === idsTipoMovimientoRecambio.regularizacion;

    const isEditable =
      isRegularizacion ||
      cierres.length === 0 ||
      cierres.some((crna) => {
        const { fechaDesde, fechaHasta } = calcularFechasComparacion(
          crna.fecha
        );
        const fechaMr = new Date(mr.fecha);

        return fechaMr >= fechaDesde && fechaMr.getTime() < fechaHasta;
      });

    return {
      ...mr,
      isEditable,
    };
  });
};

export const datasource_tblMovimientoRecambio = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimientoRecambio",
    key: "idMovimientoRecambio",
    errorHandler,
    beforeSend: datasource_tblMovimientoRecambio_beforeSend,
    version: 4,
  }),
  expand: [
    "idAlmacenOrigenNavigation($select=idAlmacen, idAlmacenPadre, denominacion;$expand=idMonedaNavigation($select=codigo))",
    "idAlmacenDestinoNavigation($select=idAlmacen, idAlmacenPadre, denominacion;$expand=idMonedaNavigation($select=codigo))",
    "idProveedorNavigation($select=idProveedor, nombreComercial)",
    "tblRecambioNMovimientoRecambio($select=idRecambio)",
  ],
  postProcess: datasource_tblMovimientoRecambio_postProcess,
});

export const datasource_tblCierreRecambioNAlmacen = new DataSource({
  paginate: false,
  store: new ODataStore({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "tblCierreRecambioNAlmacen/GetFechasNAlmacen",
    key: ["idAlmacen", "fecha"],
    errorHandler,
    beforeSend: addAuthHeader,
    version: 4,
  }),
});

export const datasource_tblAlmacenRecambios = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAlmacenRecambios",
    key: "idAlmacen",
    errorHandler,
    beforeSend: addAuthHeader,
    version: 4,
  }),
  select: ["idAlmacen", "idAlmacenPadre", "denominacion"],
  expand: ["idMonedaNavigation($select=codigo)"],
  sort: "denominacion",
});

export const datasource_tblProveedor = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblProveedor",
    key: "idProveedor",
    errorHandler,
    beforeSend: addAuthHeader,
    version: 4,
  }),
  sort: "nombreComercial",
  select: ["idProveedor", "nombreComercial"],
});

export const context_tblMovimientoRecambio = new ODataContext({
  url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimientoRecambio",
  entities: {
    fn_isNumPedidoAsociadoExists: {},
  },
  beforeSend: addAuthHeader,
});

export const context_traspasos = new ODataContext({
  url:
    connectionConstants.WEB_API_CORE_ODATA_URL +
    "MyPolarier/Assistant/Traspasos/",
  entities: {
    SetIsValidado: {
      key: "idMovimientoRecambio",
      keyType: "Int32",
    },
  },
  beforeSend: addAuthHeader,
});
