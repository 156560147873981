import React, { useState } from "react";

import Box, { Item } from "devextreme-react/box";
import Button from "devextreme-react/button";
import Switch from "devextreme-react/switch";

import DataGridTblRecambioNProveedor from "./components/DataGridTblRecambioNProveedor";
import ListTblRecambioNProveedor from "./components/ListTblRecambioNProveedor";
import Popup from "./components/Popup";
import { getTrad } from "helpers";

const style = { height: 290 };

const gap = { gap: 10 };

const LayoutTblRecambioNProveedor = ({ dataSource }) => {
  const [isEditing, setIsEditing] = useState(true);
  const [isVisible_Popup, setIsVisible_Popup] = useState(false);

  const onClick_añadir = () => setIsVisible_Popup(true);

  const onValueChanged = ({ value }) => setIsEditing(value);

  const onHiding = () => setIsVisible_Popup(false);

  return (
    <div style={style}>
      <Box direction={"col"} className={"he-100"}>
        <Item baseSize={50}>
          <Box
            direction={"row"}
            className={"p-2 justify-content-between he-100"}
            style={gap}
          >
            <Item baseSize={"auto"}>
              <Box className={"he-100"} style={gap}>
                <Item baseSize={"auto"}>
                  <Button
                    icon={"add"}
                    text={getTrad("añadir")}
                    disabled={!isEditing}
                    onClick={onClick_añadir}
                  />
                </Item>
              </Box>
            </Item>
            <Item baseSize={"auto"}>
              <Box className={"he-100"} style={gap}>
                <Item baseSize={"auto"}>
                  <Switch
                    value={isEditing}
                    switchedOnText={getTrad("edicion")}
                    switchedOffText={getTrad("ordenacion")}
                    className={"no-disabled"}
                    onValueChanged={onValueChanged}
                  />
                </Item>
              </Box>
            </Item>
          </Box>
        </Item>
        <Item ratio={1}>
          <div>
            {isEditing ? (
              <>
                <Popup visible={isVisible_Popup} onHiding={onHiding} />
                <DataGridTblRecambioNProveedor dataSource={dataSource} />
              </>
            ) : (
              <ListTblRecambioNProveedor dataSource={dataSource} />
            )}
          </div>
        </Item>
      </Box>
    </div>
  );
};

export default LayoutTblRecambioNProveedor;
