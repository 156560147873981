import React, { useEffect, useRef, useState } from "react";

import DataSource from "devextreme/data/data_source";
import DxDataGrid, {
  Column,
  ColumnChooser,
  Editing,
  FilterRow,
  Pager,
  Paging,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import LoadPanel from "devextreme-react/load-panel";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

import Layout from "./components/Layout";
import PopupCierrePrecios from "./components/PopupCierrePrecios";
import PopupHistorico from "./components/PopupHistorico";
import { connectionConstants } from "constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  patchRequestHandler,
} from "helpers";

const elementAttr = { id: "dxDataGrid_GestionAlmacenes" };

const position = {
  my: "center",
  at: "center",
  of: `#${elementAttr.id}`,
};

const allowedPageSizes = [20, 50, 100];

const defaultNotify = (isError, trad) => {
  notify({
    message: getTrad(trad),
    type: isError ? "error" : "success",
    displayTime: 1500,
    closeOnClick: true,
  });
};

const DataGrid = ({
  idAlmacenSel,
  idAlmacenPadreSel,
  codigoMoneda,
  setSelectedIndex,
  load_dataSource_getRecambios,
  isLoading,
  dataSource,
  infoActual,
}) => {
  const dataGridRef = useRef(null);

  const [isVisible_cierrePrecios, setIsVisible_cierrePrecios] = useState(false);
  const [isVisible_PopupHistorico, setIsVisible_PopupHistorico] =
    useState(false);
  const [recambioSel, setRecambioSel] = useState(null);

  useEffect(() => {
    dataGridRef?.current?.instance?.clearFilter();
  }, [idAlmacenSel]);

  const datasource_tblRecambioNAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblRecambioNAlmacenRecambios",
      errorHandler,
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
  });

  const format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: codigoMoneda ?? "EUR",
  };

  const noDataText = idAlmacenSel
    ? getTrad("sinDatos")
    : getTrad("seleccionaAlmacen");

  const onToolbarPreparing = (e) => (e.toolbarOptions.visible = false);

  const onRowUpdated = ({ data }) => {
    datasource_tblRecambioNAlmacenRecambios
      .store()
      .update(
        { idAlmacen: idAlmacenSel, idRecambio: data.idRecambio },
        patchRequestHandler({ ubicacion: data.ubicacion })
      )
      .then(
        () => defaultNotify(false, "aviso_C_RegistroActualizado"),
        () => defaultNotify(true, "aviso_RegistroNoActualizado")
      );
  };

  const cellRender = ({ data }) => {
    const onMouseDown = () => {
      setIsVisible_PopupHistorico(true);
      setRecambioSel(data);
    };

    return (
      <div
        className={"container_spanCentrado pointer"}
        onMouseDown={onMouseDown}
      >
        <div
          className={"icon_Comment font-size-lg"}
          title={getTrad("historialRecambio")}
        />
      </div>
    );
  };

  const calculateCellValue = (idRecambio, field) =>
    infoActual[idRecambio]?.[field];

  const calculateCellValue_total = ({ idRecambio }) => {
    const cantidad = infoActual[idRecambio]?.cantidad;
    const precio = infoActual[idRecambio]?.precio;

    const total = cantidad * precio;

    return isNaN(total) ? null : total;
  };

  const onHiding_PopupHistorico = () => setIsVisible_PopupHistorico(false);

  const onHiding_cierrePrecios = () => setIsVisible_cierrePrecios(false);

  const almacenSel = !idAlmacenPadreSel
    ? {
        idAlmacen: idAlmacenSel,
      }
    : null;

  const isLoadingCalculatedData =
    isLoading.getRecambios || isLoading.getInfoActual;

  const idsRecambioHistorico = recambioSel?.idRecambio
    ? [recambioSel.idRecambio]
    : [];

  return (
    <Layout
      dataGridRef={dataGridRef}
      idAlmacenSel={idAlmacenSel}
      idAlmacenPadreSel={idAlmacenPadreSel}
      setIsVisible_PopupCierrePrecios={setIsVisible_cierrePrecios}
      setSelectedIndex={setSelectedIndex}
      load_dataSource_getRecambios={load_dataSource_getRecambios}
      isLoading={isLoading}
    >
      <>
        <LoadPanel visible={isLoading.getRecambios} position={position} />
        <PopupCierrePrecios
          isVisible_cierrePrecios={isVisible_cierrePrecios}
          almacenSel={almacenSel}
          onHiding={onHiding_cierrePrecios}
        />
        <PopupHistorico
          visible={isVisible_PopupHistorico}
          tipoHistorico={2}
          idsRecambioHistorico={idsRecambioHistorico}
          idAlmacen={idAlmacenSel}
          recambioSel={recambioSel}
          codigoMoneda={codigoMoneda}
          onHiding={onHiding_PopupHistorico}
        />
        <DxDataGrid
          ref={dataGridRef}
          elementAttr={elementAttr}
          dataSource={dataSource}
          keyExpr={"idRecambio"}
          height={"100%"}
          width={"100%"}
          allowColumnReordering
          allowColumnResizing
          hoverStateEnabled
          rowAlternationEnabled
          showRowLines
          remoteOperations={false}
          showColumnLines={false}
          noDataText={noDataText}
          onToolbarPreparing={onToolbarPreparing}
          onRowUpdated={onRowUpdated}
        >
          <ColumnChooser enabled />
          <Editing
            mode={"cell"}
            allowUpdating
            selectTextOnEditStart
            changes={[]}
          />
          <FilterRow visible />
          <Pager showPageSizeSelector allowedPageSizes={allowedPageSizes} />
          <Paging defaultPageSize={50} />
          <Sorting mode={"multiple"} />
          <Column
            name={"historialRecambio"}
            fixedPosition={"left"}
            fixed
            width={30}
            cellRender={cellRender}
            showInColumnChooser={false}
          />
          <Column
            dataField={"referenciaInterna"}
            caption={getTrad("refInterna")}
            alignment={"left"}
            sortOrder={"asc"}
            sortIndex={0}
            width={110}
            allowEditing={false}
          />
          <Column
            dataField={"referencia"}
            caption={getTrad("refFabricante")}
            alignment={"left"}
            width={110}
            allowEditing={false}
          />
          <Column
            dataField={"denominacion"}
            caption={getTrad("denominacion")}
            alignment={"left"}
            sortOrder={"asc"}
            sortIndex={1}
            minWidth={200}
            allowEditing={false}
          />
          <Column
            dataField={"ubicacion"}
            caption={getTrad("ubicacion")}
            alignment={"left"}
            cssClass={"dx-Cell_Editable"}
            width={100}
          />
          <Column
            dataField={"cantidad"}
            caption={getTrad("cantidad")}
            width={100}
            dataType={"number"}
            alignment={"center"}
            allowEditing={false}
            allowFiltering={!isLoadingCalculatedData}
            calculateCellValue={({ idRecambio }) =>
              calculateCellValue(idRecambio, "cantidad")
            }
          />
          <Column
            dataField={"precio"}
            caption={getTrad("precio")}
            alignment={"center"}
            dataType={"number"}
            width={110}
            format={format}
            allowEditing={false}
            allowFiltering={!isLoadingCalculatedData}
            calculateCellValue={({ idRecambio }) =>
              calculateCellValue(idRecambio, "precio")
            }
          />
          <Column
            dataField={"descripcionArticulo"}
            caption={getTrad("descripcionArticulo")}
            alignment={"left"}
            allowEditing={false}
            visible={false}
          />
          <Column
            caption={getTrad("total")}
            name={"total"}
            alignment={"center"}
            dataType={"number"}
            width={140}
            format={format}
            allowEditing={false}
            calculateCellValue={calculateCellValue_total}
          />
          {!isLoadingCalculatedData ? (
            <Summary recalculateWhileEditing>
              <TotalItem
                column={"referenciaInterna"}
                displayFormat={getTrad("total").toUpperCase()}
              />
              <TotalItem
                summaryType={"sum"}
                valueFormat={format}
                displayFormat={"{0}"}
                column={"total"}
              />
            </Summary>
          ) : null}
        </DxDataGrid>
      </>
    </Layout>
  );
};

export default DataGrid;
