import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";
import { authHeader, errorHandler, formatDate, formatNumber, getNombreFormulario, getTrad } from "../../../helpers";
import css from "./css.scss";
//Componentes

import { ButtonGroup } from "devextreme-react/button-group";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";

import Puntuacion from "./Puntuacion/index";
import Seleccion from "./Seleccion/index";
import Opinion from "./Opinion";
import PopUpDatosGenerales from "./PopUpDatosGenerales";

import { Button, ScrollView, SelectBox } from "devextreme-react";
import { connectionConstants } from "constants";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
//Js

class InfEncuestas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      group: this.enum_filtro[0],
      idEncuestaSel: null,
      idLavanderiaSel: null,
      idCompañiaSel: null,
      dxMultiView_index: 0,
      datosEncuestaGenerales: [],
      showPopUpDatosGenerales: false,

      datasource_tblEncuesta: [],
      datasource_tblLavanderia: [],
      datasource_tblCompañia: [],
    };

    this.dx_selectBox_tblComp_REF = React.createRef();
    this.dx_selectBox_tblLav_REF = React.createRef();
  }

  //DATSOURCES
  enum_filtro = [
    {
      key: "puntuacion",
      denominacion: getTrad("puntuacion"),
      icon: "icon_starFull",
      color: "--red",
    },
    {
      key: "seleccion",
      denominacion: getTrad("seleccion"),
      icon: "icon_pieChart",
      color: "--blue",
    },
    {
      key: "opinion",
      denominacion: getTrad("opinion"),
      icon: "icon_Comment",
      color: "--yellow",
    },
  ];

  enum_datosGenerales = [
    {
      key: "encuestasCompletadas",
      denominacion: getTrad("encuestasCompletadas"),
      icon: "menu_icons_Informes",
      color: "var(--success)",
    },
    {
      key: "encuestasPendientes",
      denominacion: getTrad("encuestasPendientes"),
      icon: "menu_icons_Inventarios",
      color: "var(--danger)",
    },
    {
      key: "porcentajeEncuestasCompletadas",
      denominacion: getTrad("porcentajeCompletadas"),
      icon: "icon_Comment",
      color: "var(--info)",
    },
  ];

  render() {
    const {
      group,
      dxMultiView_index,
      idEncuestaSel,
      datosEncuestaGenerales,
      showPopUpDatosGenerales,
      idLavanderiaSel,
      idCompañiaSel,
    } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} postHeading={this.render_SelectorEncuesta()} />
        <Box direction="col" width="auto" height="100%" className="pt-4">
          <ItemBox baseSize={60}>
            <Box direction="row" width="auto" height="100%">
              <ItemBox ratio={1}>
                <Box direction="row" width="auto" height="100%">
                  <ItemBox ratio={1}>
                    <div className="w-100 d-flex justify-content-between">
                      <ButtonGroup
                        height="100%"
                        width="600"
                        items={this.enum_filtro}
                        keyExpr="key"
                        selectedItemKeys={[group.key]}
                        buttonRender={this.dxButtonGroup_buttonRender}
                        onItemClick={this.dxButtonGroup_onItemClick}
                      />
                    </div>
                  </ItemBox>
                  <ItemBox ratio={1}>
                    <div className="d-flex he-100 w-100 justify-content-end">
                      {this.enum_datosGenerales.map((x, i) => {
                        const valorDatosGenerales =
                          datosEncuestaGenerales.length > 0 ? datosEncuestaGenerales[0][x.key] : 0;
                        const colorBoton = idEncuestaSel == null ? "var(--secondary)" : x.color;
                        const formatValue =
                          x.key === "porcentajeEncuestasCompletadas"
                            ? formatNumber(valorDatosGenerales * 100, 1, "decimal") + "%"
                            : valorDatosGenerales;

                        return (
                          <Button
                            key={i}
                            disabled={idEncuestaSel == null}
                            onClick={this.showPopUpDatosGenerales}
                            className="container_boton_datosGenerales"
                            style={{ backgroundColor: colorBoton }}
                          >
                            <Box direction="row" width="auto" height="100%" className="justify-content-center">
                              <ItemBox ratio={1}>
                                <div className="font-weight-bold text-light">{x.denominacion}</div>
                              </ItemBox>
                              <ItemBox ratio={2}>
                                <div
                                  style={{
                                    alignSelf: "center",
                                    alignSelf: "flex-end",
                                  }}
                                  className="h3 text-light"
                                >
                                  {formatValue}
                                </div>
                              </ItemBox>
                            </Box>
                          </Button>
                        );
                      })}
                    </div>
                  </ItemBox>
                </Box>
              </ItemBox>
            </Box>
          </ItemBox>
          <ItemBox baseSize={20} />
          <ItemBox ratio={1}>
            <MultiView
              height="100%"
              width="100%"
              selectedIndex={dxMultiView_index}
              swipeEnabled={false}
              deferRendering={true}
              animationEnabled={true}
            >
              <MultiViewItem>
                <Puntuacion
                  group={group.key}
                  idEncuestaSel={idEncuestaSel}
                  idLavanderiaSel={idLavanderiaSel}
                  idCompañiaSel={idCompañiaSel}
                />
              </MultiViewItem>
              <MultiViewItem>
                <ScrollView>
                  <Seleccion
                    group={group.key}
                    idEncuestaSel={idEncuestaSel}
                    idLavanderiaSel={idLavanderiaSel}
                    idCompañiaSel={idCompañiaSel}
                  />
                </ScrollView>
              </MultiViewItem>
              <MultiViewItem>
                <Opinion
                  group={group.key}
                  idEncuestaSel={idEncuestaSel}
                  idLavanderiaSel={idLavanderiaSel}
                  idCompañiaSel={idCompañiaSel}
                />
              </MultiViewItem>
            </MultiView>
          </ItemBox>
        </Box>
        <PopUpDatosGenerales
          visible={showPopUpDatosGenerales}
          onValueChange={this.onValueChange}
          idEncuestaSel={idEncuestaSel}
          idLavanderiaSel={idLavanderiaSel}
          idCompañiaSel={idCompañiaSel}
        />
      </Fragment>
    );
  }

  showPopUpDatosGenerales = () => {
    this.setState({ showPopUpDatosGenerales: true });
  };

  onValueChange = (newState) => {
    this.setState(newState);
  };

  render_SelectorEncuesta = () => {
    const {
      idEncuestaSel,
      idCompañiaSel,
      idLavanderiaSel,
      datasource_tblCompañia,
      datasource_tblEncuesta,
      datasource_tblLavanderia,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <div className="font-weight-bold pr-3">{getTrad("encuesta")}:</div>
          <SelectBox
            dataSource={datasource_tblEncuesta}
            value={idEncuestaSel}
            valueExpr={"idEncuesta"}
            displayExpr="denominacion"
            placeholder={getTrad("seleccionaEncuesta")}
            searchEnabled={true}
            searchExpr="denominacion"
            showClearButton={true}
            width="400px"
            onSelectionChanged={this.dx_selectBox_seleccionaEncuesta}
          />
        </div>
        <div className="d-flex align-items-center mx-3">
          <div className="font-weight-bold pr-3">{getTrad("lavanderia")}:</div>
          <SelectBox
            ref={this.dx_selectBox_tblLav_REF}
            disabled={idEncuestaSel == null}
            dataSource={datasource_tblLavanderia}
            displayExpr="denominacion"
            searchEnabled={true}
            searchExpr="denominacion"
            placeholder={getTrad("todos").toUpperCase()}
            showClearButton={true}
            width="270px"
            onSelectionChanged={this.dx_selectBox_seleccionaLavanderia}
            value={idLavanderiaSel}
            valueExpr={"idLavanderia"}
          />
        </div>
        <div className="d-flex align-items-center mx-3">
          <div className="font-weight-bold pr-3">{getTrad("compañia")}:</div>
          <SelectBox
            ref={this.dx_selectBox_tblComp_REF}
            disabled={idEncuestaSel == null}
            dataSource={datasource_tblCompañia}
            displayExpr="denominacion"
            searchEnabled={true}
            searchExpr="denominacion"
            placeholder={getTrad("todos").toUpperCase()}
            showClearButton={true}
            width="270px"
            onSelectionChanged={this.dx_selectBox_seleccionaCompañia}
            value={idCompañiaSel}
            valueExpr={"idCompañia"}
          />
        </div>
      </>
    );
  };

  dx_selectBox_seleccionaLavanderia = (e) => {
    this.setState({ idLavanderiaSel: e.selectedItem?.idLavanderia ?? null }, () =>
      this.dataSource_datosEncuestaResumen.reload().done((data) => {
        this.setState({ datosEncuestaGenerales: data });
      })
    );
  };

  dx_selectBox_seleccionaCompañia = (e) => {
    this.setState({ idCompañiaSel: e.selectedItem?.idCompañia ?? null }, () =>
      this.dataSource_datosEncuestaResumen.reload().done((data) => {
        this.setState({ datosEncuestaGenerales: data });
      })
    );
  };

  dx_selectBox_seleccionaEncuesta = (e) => {
    this.setState({ idEncuestaSel: e.selectedItem?.idEncuesta ?? null }, () =>
      this.dataSource_datosEncuestaResumen.reload().done((data) => {
        this.setState({ datosEncuestaGenerales: data });
      })
    );
  };

  dxButtonGroup_buttonRender = (data) => {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        <i style={{ flexBasis: 30, color: "red" }} className={"font-size-xxl w-100 " + data.icon}></i>
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  };

  dxButtonGroup_onItemClick = (e) => {
    this.setState({ group: e.itemData, dxMultiView_index: e.itemIndex });
  };

  //#region  DATASOURCE

  datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "Encuestas/getLavanderiaEncuesta",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblLavanderia_beforeSend(request) {
    const { idEncuestaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  }

  datasource_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "Encuestas/getCompañiasEncuesta",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCompañia_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCompañia_beforeSend(request) {
    const { idEncuestaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  }

  datasource_tblEncuesta = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEncuesta",
      key: "idEncuesta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblEncuesta_beforeSend(request);
      },
      version: 4,
    }),
    select: "",
    sort: ["fechaIni desc"],
    expand: ["idEncuestaPlantillaNavigation($select=denominacion)"],
    map: (itemData) => {
      return this.datasource_tblEntidad_map(itemData);
    },
  });

  datasource_tblEncuesta_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblEntidad_map(itemData) {
    return {
      idEncuesta: itemData?.idEncuesta,
      denominacion: itemData?.idEncuestaPlantillaNavigation?.denominacion + " - " + formatDate(itemData?.fechaIni),
    };
  }

  dataSource_datosEncuestaResumen = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "Encuestas/getDatosEncuestas_resumen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datosEncuestaResumen_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_datosEncuestaResumen_beforeSend(request) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.state;

    request.headers = { ...authHeader() }; // Si no hay idEncuesta devuelve []

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
      request.params.idLavanderia = idLavanderiaSel;
      request.params.idCompañia = idCompañiaSel;
    } else {
      request.onload = () => {
        request.response = [];
      };
    }
  }
  //#endregion

  componentDidMount() {
    this.datasource_tblEncuesta.load().done((data) => {
      this.setState({ datasource_tblEncuesta: data });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { idEncuestaSel, datosEncuestaGenerales } = this.state;

    if (idEncuestaSel && idEncuestaSel !== prevState.idEncuestaSel) {
      this.datasource_tblCompañia.reload().done((data) => {
        this.setState({ datasource_tblCompañia: data });
      });

      this.datasource_tblLavanderia.reload().done((data) => {
        this.setState({ datasource_tblLavanderia: data });
      });
    }

    if (idEncuestaSel !== prevState.idEncuestaSel) {
      this.setState({ idCompañiaSel: null, idLavanderiaSel: null });
    }

    if (idEncuestaSel == null && datosEncuestaGenerales.length > 0) {
      this.setState({ datosEncuestaGenerales: [] });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(InfEncuestas);
