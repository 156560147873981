import React from "react";

//Services
import { userService } from "../../services";

import { Col, Row, Form, FormGroup, Alert } from "reactstrap";
import { TextBox, Button } from "devextreme-react";
import { Popup } from "devextreme-react/popup";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { Validator, AsyncRule, RequiredRule } from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/validation-group";

export class PopupIdentificacion extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      loggingError: false,
    };

    //Referencias
    this.textBox_username_REF = React.createRef();
    this.validationGroup_REF = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.disablePaste = this.disablePaste.bind(this);
    this.asyncValidation = this.asyncValidation.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  get textBox_username() {
    return this.textBox_username_REF.current.instance;
  }

  get validationGroup() {
    return this.validationGroup_REF.current.instance;
  }

  handleChange(e) {
    const { name, value } = e.event.target;
    this.setState({ [name]: value });
  }

  disablePaste(e) {
    e.event.preventDefault();
  }

  asyncValidation(params) {
    let value = params.value.replace(/\s+/g, " ").trim();
    return userService.validateEmailUsuario(value);
  }

  onSubmit() {
    const _this = this;
    const res = this.validationGroup.validate();
    res.status === "pending" &&
      res.complete.then((r) => {
        if (r.isValid) {
          const { username, password } = this.state;
          userService.validatePassword(username, password).then((result) => {
            if (result === 1) {
              _this.props.onIdentifySuccess();
            } else {
              _this.setState({ password: "", loggingError: true });
            }
          });
        }
      });
  }

  render() {
    const { username, password, loggingError } = this.state;
    const { identifyNeeded, onCancelIdentify } = this.props;

    return (
      <Popup
        height="auto"
        width="auto"
        showTitle={true}
        title={"Identificación"}
        deferRendering={false}
        visible={identifyNeeded}
        onHiding={onCancelIdentify}
      >
        <ValidationGroup ref={this.validationGroup_REF}>
          {loggingError ? (
            <Alert className="w-100" color="danger">
              <div className="text-center">
                <span>Las credenciales introducidas no son correctas.</span>
              </div>
            </Alert>
          ) : (
            ""
          )}
          <Box direction="col" width="auto" height="220px">
            <ItemBox ratio={1}>
              <Form>
                <Row form>
                  <Col md={12} className="mt-3">
                    <FormGroup>
                      <span>Usuario</span>
                      <TextBox
                        ref={this.textBox_username_REF}
                        name="username"
                        stylingMode="underlined"
                        placeholder="Introduzca usuario o email..."
                        value={username}
                        onChange={this.handleChange}
                        onEnterKey={this.onSubmit}
                      >
                        <Validator>
                          <RequiredRule message="Campo necesario" />
                          <AsyncRule
                            message="El nombre de usuario indicado no se encuentra registrado."
                            validationCallback={this.asyncValidation}
                          />
                        </Validator>
                      </TextBox>
                    </FormGroup>
                  </Col>
                  <Col md={12} className="mt-3">
                    <FormGroup>
                      <span>Contraseña</span>
                      <TextBox
                        name="password"
                        stylingMode="underlined"
                        placeholder="Introduzca contraseña..."
                        mode="password"
                        value={password}
                        onPaste={this.disablePaste}
                        onChange={this.handleChange}
                        onEnterKey={this.onSubmit}
                      >
                        <Validator>
                          <RequiredRule message="Campo necesario" />
                        </Validator>
                      </TextBox>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ItemBox>
            <ItemBox baseSize={50}>
              <div className="he-100 d-flex flex-column justify-content-end align-self-end">
                <Button
                  width={100}
                  text="Entrar"
                  type="default"
                  stylingMode="contained"
                  onClick={this.onSubmit}
                />
              </div>
            </ItemBox>
          </Box>
        </ValidationGroup>
      </Popup>
    );
  }
}
