import $ from "jquery";
import React, { Fragment } from "react";

const { ScrollView } = require("devextreme-react");
import "./YearCalendar.scss";

import Calendar from "../../libraries/rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.es";
import "rc-year-calendar/locales/rc-year-calendar.pt";
import { connect } from "react-redux";
import { addDays, dayDiff, endOfYear, isSameDay } from "helpers";

class NewYearCalendar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            seleccion: null,
        };

        this.oldEventos = [];
        this.disabledDates = this.loadDisabledDays();
    }

    componentDidUpdate() {
        const { seleccion } = this.state;
        const { eventos } = this.props;

        if (seleccion != null && JSON.stringify(eventos) !== JSON.stringify(this.oldEventos)) {
            if (seleccion.date) {
                this.onDayClick({
                    date: seleccion.date,
                    events: this.formatData(eventos.filter((x) => isSameDay(x.fecha, seleccion.date))),
                });
            } else {
                this.onRangeSelected({
                    startDate: seleccion.startDate,
                    endDate: seleccion.endDate,
                    events: this.formatData(
                        eventos.filter((x) => x.fecha >= seleccion.startDate && x.fecha <= seleccion.endDate)
                    ),
                });
            }
        }
        if (JSON.stringify(eventos) !== JSON.stringify(this.oldEventos)) {
            this.oldEventos = eventos;
        }
    }

    isDayEnabled(day) {
        return this.disabledDates.filter((x) => isSameDay(x, day)).length === 0;
    }

    setRangeDate(startDate, endDate) {
        while (!this.isDayEnabled(startDate) && startDate <= endDate) {
            startDate = addDays(startDate, 1);
        }
        while (!this.isDayEnabled(endDate) && startDate <= endDate) {
            endDate = addDays(endDate, -1);
        }
        return [startDate, endDate];
    }

    sendFormatEvents(events) {
        events = $.extend(true, [], events).map((event) => {
            delete event.color;
            delete event.startDate;
            delete event.endDate;
            delete event.isDisabled;
            delete event.isSelected;
            delete event.tipoCelda;
            return event;
        });
        return events;
    }

    // #region FORMATO DATASOURCE

    loadDisabledDays() {
        let disabledDates = [];
        const { disabledDays, minDate, maxDate, year, minDateRestrictive, maxDateRestrictive } = this.props;
        if ((disabledDays && typeof disabledDays[0] === "object") || minDate || maxDate) {
            if (minDate) {
                let date = new Date(`${year}-01-01 00:00:00:00`);
                if (date.getTime() < minDateRestrictive?.getTime()) {
                    date = minDateRestrictive;
                }
                while (
                    date.getTime() < minDate.getTime() &&
                    date.getTime() <= new Date(`${year}-12-31 00:00:00:00`) &&
                    (!maxDateRestrictive || date.getTime() <= maxDateRestrictive.getTime())
                ) {
                    disabledDates.push(date);
                    date = addDays(date, 1);
                }
            }
            if (maxDate) {
                let date = new Date(`${year}-12-31 00:00:00:00`);
                if (date.getTime() > maxDateRestrictive?.getTime()) {
                    date = maxDateRestrictive;
                }
                while (
                    date.getTime() > maxDate.getTime() &&
                    date.getTime() >= new Date(`${year}-01-01 00:00:00:00`) &&
                    (!minDateRestrictive || date.getTime() >= minDateRestrictive.getTime())
                ) {
                    disabledDates.push(date);
                    date = addDays(date, -1);
                }
            }

            if (disabledDays && typeof disabledDays[0] === "object") {
                disabledDates = disabledDates.concat(
                    disabledDays
                        .filter((x) => disabledDates.filter((y) => isSameDay(x, y)).length === 0)
                        .map((x) => {
                            x.setHours(0, 0, 0, 0);
                            return x;
                        })
                );
            }
        }

        return disabledDates;
    }

    formatData(data) {
        const { seleccion } = this.state;
        const { eventoRangeExpr, dateExpr, colorExpr } = this.props;

        data.sort((a, b) => a[dateExpr].getTime() - b[dateExpr].getTime());

        let _this = this;

        return $.map(data, function (item, inx) {
            let clases = [];
            let prev = data[inx - 1];
            let next = data[inx + 1];

            // Comprueba si el anterior evento o siguiente existe, son correlativos, y si el evento es el mismo
            if (!prev || dayDiff(prev[dateExpr], item[dateExpr]) > 1 || prev[eventoRangeExpr] !== item[eventoRangeExpr])
                clases.push("evento-start");
            if (!next || dayDiff(next[dateExpr], item[dateExpr]) > 1 || next[eventoRangeExpr] !== item[eventoRangeExpr])
                clases.push("evento-end");

            const date = new Date(item[dateExpr]);
            const isDaySelected = date?.setHours(0, 0, 0, 0) == seleccion?.date?.setHours(0, 0, 0, 0);
            const isInRangeSelected =
                date?.setHours(0, 0, 0, 0) >= seleccion?.startDate?.setHours(0, 0, 0, 0) &&
                date <= seleccion?.endDate?.setHours(0, 0, 0, 0);

            return {
                startDate: item[dateExpr],
                endDate: item[dateExpr],
                color: item[colorExpr] != null ? item[colorExpr] : "transparent",
                tipoCelda: clases.join(" "),
                isDisabled: _this.isDayEnabled(date),
                isSelected: isDaySelected || isInRangeSelected,
                ...item,
            };
        });
    }

    // #endregion

    render() {
        const { idioma, eventos, year, displayDisabledDataSource, enableRangeSelection, height } = this.props;
        const {
            minDateRestrictive,
            maxDateRestrictive,
            disabledWeekDays,
            displayHeader,
            displayWeekNumber,
            hiddenWeekDays,
        } = this.props;
        const {
            dayRender,
            onDayClick,
            onDayContextMenu,
            onDayEnter,
            onDayLeave,
            onRangeSelected,
            onRenderEnd,
            onYearChanged,
        } = this.props;
        this.disabledDates = this.loadDisabledDays();

        return (
            <Fragment>
                <div id="YearCalendarPolarier" style={{ height: height }}>
                    <ScrollView style={{ maxHeight: "100%" }}>
                        <Calendar
                            language={idioma.codigo}
                            dataSource={this.formatData(eventos)}
                            displayDisabledDataSource={displayDisabledDataSource}
                            enableRangeSelection={enableRangeSelection}
                            year={year}
                            minDate={minDateRestrictive}
                            maxDate={maxDateRestrictive}
                            disabledWeekDays={disabledWeekDays}
                            displayHeader={displayHeader}
                            displayWeekNumber={displayWeekNumber}
                            hiddenWeekDays={hiddenWeekDays}
                            customDayRenderer={this.customDayRenderer}
                            customDataSourceRenderer={dayRender}
                            onDayClick={onDayClick ? this.onDayClick : undefined}
                            onDayContextMenu={onDayContextMenu ? this.onDayContextMenu : undefined}
                            onDayEnter={onDayEnter ? this.onDayEnter : undefined}
                            onDayLeave={onDayLeave ? this.onDayLeave : undefined}
                            onRangeSelected={onRangeSelected ? this.onRangeSelected : undefined}
                            onRenderEnd={onRenderEnd}
                            onYearChanged={onYearChanged}
                            style={"custom"}
                            roundRangeLimits={true}
                        />
                    </ScrollView>
                </div>
            </Fragment>
        );
    }

    customDayRenderer = (element, day) => {
        const { seleccion } = this.state;
        const { hideToday, minDateRestrictive, maxDateRestrictive } = this.props;

        let selSpan = $('<span class="position-absolute"></span>');
        $(element).prepend(selSpan);
        $(element).contents().appendTo(selSpan);

        $(element)
            .parent()
            .attr("id", `calendar-${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`);

        if (!hideToday && day.setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
            selSpan.addClass("today-mark");
        }

        if (
            !this.isDayEnabled(day) &&
            (!minDateRestrictive || day.setHours(0, 0, 0, 0) >= minDateRestrictive.getTime()) &&
            (!maxDateRestrictive || day.setHours(0, 0, 0, 0) <= maxDateRestrictive.getTime())
        ) {
            $(element).parent().addClass("day-disabled");
        }

        if (seleccion != null && !$(element).parent().hasClass("day-disabled")) {
            let selDiv = $("<div></div>");
            $(element).append(selDiv);

            const date = new Date(day).setHours(0, 0, 0, 0);
            const isDaySelected = date == seleccion.date?.setHours(0, 0, 0, 0);
            const isInRangeSelected =
                date >= seleccion.startDate?.setHours(0, 0, 0, 0) && date <= seleccion.endDate?.setHours(0, 0, 0, 0);
            const isStartRange = date == seleccion.startDate?.setHours(0, 0, 0, 0);
            const isEndRange = date == seleccion.endDate?.setHours(0, 0, 0, 0);

            const prevDate = new Date(addDays(day, -1));
            const nextDate = new Date(addDays(day, 1));

            if (isDaySelected || isInRangeSelected) {
                $(selDiv).addClass("selected");
            }
            if (isDaySelected || isStartRange || (isInRangeSelected && !this.isDayEnabled(prevDate))) {
                $(selDiv).addClass("selected-start");
            }
            if (isDaySelected || isEndRange || (isInRangeSelected && !this.isDayEnabled(nextDate))) {
                $(selDiv).addClass("selected-end");
            }
        }
    };

    onDayClick = (e) => {
        const { date, events } = e;
        const { onDayClick, autoClearSeleccion } = this.props;

        if (this.isDayEnabled(date)) {
            if (!autoClearSeleccion) {
                this.setState({ seleccion: e });
            }

            const eventos = this.sendFormatEvents(events);
            onDayClick({ date, eventos });
        }
    };

    onDayContextMenu = (e) => {
        const { onDayContextMenu } = this.props;
        const { startDate, endDate, events } = e;
        const eventos = this.sendFormatEvents(events);

        onDayContextMenu({ startDate, endDate, eventos });
    };

    onDayEnter = (e) => {
        const { onDayEnter } = this.props;
        const { element, date, events } = e;
        const eventos = this.sendFormatEvents(events);

        onDayEnter({ element, date, eventos });
    };

    onDayLeave = (e) => {
        const { onDayLeave } = this.props;
        const { element, date, events } = e;
        const eventos = this.sendFormatEvents(events);

        onDayLeave({ element, date, eventos });
    };

    onRangeSelected = (e) => {
        let { startDate, endDate, events } = e;
        const { onRangeSelected, autoClearSeleccion } = this.props;
        let isValid = true;

        if (!this.isDayEnabled(startDate) || !this.isDayEnabled(endDate)) {
            [startDate, endDate] = this.setRangeDate(startDate, endDate);
            isValid = startDate <= endDate;
            e.startDate = startDate;
            e.endDate = endDate;
        }

        if (!autoClearSeleccion) {
            this.setState({ seleccion: e });
        }

        const eventos = this.sendFormatEvents(events.filter((x) => x.isDisabled));

        onRangeSelected({ startDate, endDate, eventos: isValid ? eventos : [] });
    };
}

const mapStateToProps = (state) => ({
    resolucion: state.Global.resolucion,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

export default connect(mapStateToProps)(NewYearCalendar);
