import { personaConstants } from "../constants";

const initialState = { fotosPerfil: {} };

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case personaConstants.LOAD_FOTOPERFIL_SUCCESS: {
            let fotosPerfil = { ...state.fotosPerfil };
            fotosPerfil[action.data.idPersona] = action.data.documento;
            return {
                ...state,
                fotosPerfil,
            };
        }
        case personaConstants.LOAD_FOTOPERFILMASIVO_SUCCESS: {
            let fotosPerfil = { ...state.fotosPerfil };
            action.data.forEach((foto) => {
                fotosPerfil[foto.idPersona] = foto.documento;
            });
            return {
                ...state,
                fotosPerfil,
            };
        }
        default:
            return state;
    }
}
