import React, { useRef } from "react";

import Box, { Item } from "devextreme-react/box";
import Button from "devextreme-react/button";

import Buscador from "./components/Buscador";
import CustomLoader from "./components/CustomLoader";
import { GestionAlmacenesContext } from "../../../GestionAlmacenesProvider";
import { getTrad } from "helpers";

const gap = { gap: 10 };

const Layout = ({
  dataGridRef,
  idAlmacenSel,
  idAlmacenPadreSel,
  setIsVisible_PopupCierrePrecios,
  setSelectedIndex,
  load_dataSource_getRecambios,
  isLoading,
  children,
}) => {
  const buscadorRef = useRef(null);

  const onClick_infRecambios = () => setSelectedIndex(1);

  const onClick_cierrePrecios = () => setIsVisible_PopupCierrePrecios(true);

  const onClick_eliminarFiltros = () => {
    buscadorRef?.current?.clear();
    dataGridRef?.current?.instance?.clearFilter();
  };

  const onClick_columnchooser = () =>
    dataGridRef?.current?.instance?.showColumnChooser();

  const isLoadingCalculatedData =
    isLoading.getRecambios || isLoading.getInfoActual;

  return (
    <Box direction={"col"} className={"he-100"}>
      <Item baseSize={50}>
        <Box
          direction={"row"}
          className={"p-2 justify-content-between he-100"}
          style={gap}
        >
          <Item baseSize={"auto"}>
            <Box className={"he-100"} style={gap}>
              <Item baseSize={"auto"}>
                <Button
                  width={95}
                  text={getTrad("informes")}
                  onClick={onClick_infRecambios}
                />
              </Item>
              <Item baseSize={"auto"} visible={!idAlmacenPadreSel}>
                <Button
                  text={getTrad("cierrePrecios")}
                  disabled={!idAlmacenSel}
                  onClick={onClick_cierrePrecios}
                />
              </Item>
              <Item baseSize={"auto"}>
                <CustomLoader isLoading={isLoading} />
              </Item>
            </Box>
          </Item>
          <Item baseSize={"auto"}>
            <Box className={"he-100"} style={gap}>
              <Item baseSize={"auto"}>
                <Button
                  icon={"refresh"}
                  disabled={isLoadingCalculatedData}
                  onClick={load_dataSource_getRecambios}
                />
              </Item>
              <Item baseSize={"auto"}>
                <Button
                  icon={" icon_EliminarFiltros"}
                  onClick={onClick_eliminarFiltros}
                />
              </Item>
              <Item baseSize={"auto"}>
                <Button
                  icon={"columnchooser"}
                  onClick={onClick_columnchooser}
                />
              </Item>
              <Item baseSize={"auto"}>
                <GestionAlmacenesContext.Consumer>
                  {({ setIdsRecambio }) => (
                    <Buscador
                      ref={buscadorRef}
                      idAlmacenSel={idAlmacenSel}
                      setIdsRecambio={setIdsRecambio}
                    />
                  )}
                </GestionAlmacenesContext.Consumer>
              </Item>
            </Box>
          </Item>
        </Box>
      </Item>
      <Item ratio={1}>{children}</Item>
    </Box>
  );
};

export default Layout;
