import React, { Fragment } from "react";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Button } from "devextreme-react";

class CustomCookieBanner extends React.Component {
  constructor(props) {
    super(props);
    this.accept = this.accept.bind(this);
    this.state = { showBanner: true };
  }

  accept() {
    this.setState({ showBanner: false });
    setTimeout(() => {
      this.props.onAccept();
    }, 500);
  }

  render() {
    let { showBanner } = this.state;
    return (
      <Fragment>
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                    component="div"
                    className={"media-body"}
                    transitionName="CookieBannerAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={500}
                    transitionLeave={true}> */}
          {showBanner ? (
            <div className="CustomCookieBanner">
              <div className="container">
                <div className="title">Consentimiento de cookies</div>
                <div className="message">
                  Este sitio web utiliza cookies propias y de terceros para
                  poderte ofrecer una mejor experiencia de navegación y una
                  atención personalizada gracias a la información estadística
                  que obtenemos tras analizar hábitos de navegación. Si sigues
                  navegando sin cambiar la configuración, entenderemos que
                  aceptas el uso de cookies en nuestro sitio. Si deseas más
                  información o modificar el uso de las cookies, lee nuestra{" "}
                  <a href="https://www.polarier.com/aviso-legal/">
                    política legal
                  </a>
                  .
                </div>
                <div className="footer">
                  <Button
                    className="acceptButton"
                    icon=" icon_Circle_check"
                    text="Aceptar"
                    type="default"
                    onClick={this.accept}
                  ></Button>
                </div>
                <div
                  className="closeButton icon_Cerrar container_spanCentrado"
                  onClick={this.accept}
                ></div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }
}

export default CustomCookieBanner;
