import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useContext, useMemo } from "react";

import DxForm, { Item } from "devextreme-react/form";
import { getTrad } from "helpers";

import DataGridArticulos from "components/Administracion/DataGridArticulos";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { Card, CardBody } from "reactstrap";

import "pages/Administracion/AlbaranVenta/styles/css.css";

import {
    datasource_tblAdmTipoElemento,
    datasource_tblCliente,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import CargarPedidos from "./CargarPedido";
import { generateCode, handleDefaultFieldsCliente } from "pages/Administracion/shared/functions";

import { Button, TextBox } from "devextreme-react";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import { MainScreenContext } from "pages/Administracion/AlbaranVenta";
import CentroCosteElementoPepComponent from "pages/Administracion/shared/components/CentroCosteElementoPepComponent";
import { getClearAlbaran } from "pages/Administracion/AlbaranVenta/model";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda }, ref) => {
    const ScreenCxt = useContext(MainScreenContext);
    const [cargarPedidoPopup_visibility, setCargarPedidoPopup_visibility] = useState(false);
    const [codigoElemento, setCodigoElemento] = useState();
    const [pedidoAsociado, setPedidoAsociado] = useState(formData?.numPedidoCliente_codigo);

    /* formSuperiorRef y formInferiorRef solo sirven para que las funciones de validación funcionen. 
  Se puede usar cualquiera para sacar informacion sobre el formData del componente entero, pues aunque las referencias sean de 
  dos formularios de devextreme diferentes ambos se nutren del mismo objeto pasado por props.
  summaryFormRef es la diferente, se nutre solo de una parte del formData pero sus cambios se vuelcan manual y directamente sobre el formData de los otros formularios 
  para evitar cualquier incoherencia y para facilitar la función de guardado. */
    const previousCode = useRef();
    const formSuperiorRef = useRef();
    const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const CCEPFormRef = useRef();
    const TCTCFormRef = useRef();
    const cargarPedidoCliente_REF = useRef();
    const colCount = 5;
    const multiplier = 3;
    useImperativeHandle(ref, () => ({
        getFormData,
        hasEditData,
    }));

    const getFormData = () => formSuperiorRef?.current?.instance?.option("formData");

    useEffect(() => {
        validations.push(checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setPedidoAsociado(formData?.numPedidoCliente_codigo);
        setCodigoElemento(formData.codigo);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if (prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const getText = (clave) => ({ text: getTrad(clave) });

    const checkIsValid = () => {
        const isCCEPFormValidated = CCEPFormRef?.current?.checkIsValid();
        const TCTCFormValidated = TCTCFormRef?.current?.checkIsValid();
        const isSuperiorFormValidated = formSuperiorRef.current.instance.validate();
        const isInferiorFormValidated = formInferiorRef.current.instance.validate();
        const isFormValidated =
            isSuperiorFormValidated.isValid &&
            isInferiorFormValidated.isValid &&
            isCCEPFormValidated.isValid &&
            TCTCFormValidated.isValid;
        return new Promise((resolve, reject) => {
            if (isFormValidated) {
                resolve();
            }
            reject();
        });
    };

    const cargarPedidos = () => {
        setCargarPedidoPopup_visibility(true);
    };

    const setDefaultConfiguration = (idAdmCliente) => {
        const cliente = datasource_tblCliente.items().find((x) => x.idAdmCliente === idAdmCliente);

        if (cliente) {
            const fieldsCentroCosteElementoPep = ["idAdmCentroCoste", "idAdmElementoPEP"];

            handleDefaultFieldsCliente(fieldsCentroCosteElementoPep, CCEPFormRef?.current, cliente);

            const formSuperior = formSuperiorRef.current.instance;

            const fieldsFormSuperior = [
                "idMoneda",
                "idIvaNPais",
                {
                    form: "idAdmFormaPago",
                    cliente: "idAdmFormaCobro",
                },
            ];

            handleDefaultFieldsCliente(fieldsFormSuperior, formSuperior, cliente);
        }
    };

    const labels = {
        codigo: getText("codigo"),
        idTipoAlbaran: getText("tipoAlbaran"),
        fechaCreacion: getText("fecha"),
        numPedidoCliente: getText("numPedidoCliente"),
        idAdmCliente: getText("cliente"),
        idTipoFactura: getText("tipoFactura"),
        idIncoterm: getText("incoterms"),
        idAdmFormaPago: getText("formaPago"),
        idMoneda: getText("moneda"),
        observaciones: getText("observaciones"),
        descuento: getText("descuento"),
        bruto: getText("totalBruto"),
        neto: getText("neto"),
        idIvaNPais: getText("iva"),
    };

    const editorOptions = {
        idTipoAlbaran: {
            dataSource: datasource_tblAdmTipoElemento,
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) =>
                e.value != null &&
                generateCode(
                    formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date(),
                    setCodigoElemento_Wrapper,
                    e.value,
                    "albaranVenta"
                ),
            placeholder: getTrad("tipoAlbaran"),
            readOnly: pedidoAsociado != null ? true : false,
        },
        idAdmCliente: {
            ...sharedEditorOptions.idAdmCliente,
            readOnly: pedidoAsociado != null ? true : false,
        },
        idMoneda: {
            ...sharedEditorOptions.idMoneda,
            readOnly: pedidoAsociado != null ? true : false,
        },
        idAdmFormaPago: {
            ...sharedEditorOptions.idAdmFormaPago,
            readOnly: pedidoAsociado != null ? true : false,
        },
        numPedidoCliente: {
            readOnly: true,
        },
        cargarPedido: {
            text: getTrad("cargarPedido"),
            disabled: formData.isCerrado,
            onClick: cargarPedidos,
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
        }
        if (e.dataField === "fechaCreacion" && formData.idTipoAlbaran != null) {
            generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoAlbaran, "albaranCompra");
            TCTCFormRef.current?.recalcularTasaCambio();
        }
        if (e.dataField === "idAdmTipoCambio") {
            TCTCFormRef.current?.recalcularTasaCambio();
        }
        if (e.dataField === "idAdmCliente") {
            setDefaultConfiguration(e.value);
        }
    };

    const setPedido = (pedido) => {
        formSuperiorRef.current.instance.option("formData").numPedidoCliente_id =
            pedido.idAdmPedidoCliente == null ? null : pedido.idAdmPedidoCliente * 1; //pedido.numPedidoCliente;
        formSuperiorRef.current.instance.option("formData").numPedidoCliente_codigo = pedido.codigo;
        formSuperiorRef.current.instance.option("formData").idAdmCliente = pedido.idAdmCliente;
        formSuperiorRef.current.instance.option("formData").idAdmCentroCoste = pedido.idAdmCentroCoste;
        formSuperiorRef.current.instance.option("formData").idAdmElementoPEP = pedido.idAdmElementoPEP;
        formSuperiorRef.current.instance.option("formData").idAdmFormaPago = pedido.idAdmFormaPago;
        formSuperiorRef.current.instance.option("formData").idMoneda = pedido.idMoneda;
        formSuperiorRef.current.instance.option("formData").tasaCambio = pedido.tasaCambio;
        formSuperiorRef.current.instance.option("formData").descuento = pedido.descuento;
        formSuperiorRef.current.instance.option("formData").idAdmTipoDescuento = pedido.idAdmTipoDescuento;
        formSuperiorRef.current.instance.option("formData").idIvaNPais = pedido.idIvaNPais;
        formSuperiorRef.current.instance.option("formData").idIncoterm = pedido.idIncoterm;
        formSuperiorRef.current.instance.option("formData").idAdmTipoCambio = pedido.idAdmTipoCambio;
        formData.articulosDraft = pedido.tblArticuloNAdmPedidoCliente;
        if (
            pedido.idTipoPedido != null &&
            formSuperiorRef.current.instance.option("formData").idTipoAlbaran != pedido.idTipoPedido
        ) {
            formSuperiorRef.current.instance.option("formData").idTipoAlbaran = pedido.idTipoPedido;
            const fecha = formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date();
            generateCode(fecha, setCodigoElemento_Wrapper, pedido.idTipoPedido, "albaranVenta");
        }
        setIdMoneda(pedido.idMoneda);
        setPedidoAsociado(pedido.codigo);
    };

    const hasEditData = (referenceFormData = getClearAlbaran()) => {
        let editData = true;
        const {
            numPedidoCliente_codigo,
            idAdmCliente,
            idAdmCentroCoste,
            idAdmElementoPEP,
            idAdmFormaPago,
            idMoneda,
            tasaCambio,
            descuento,
            idAdmTipoDescuento,
            idIvaNPais,
            idIncoterm,
            idAdmTipoCambio,
        } = formSuperiorRef.current.instance.option("formData");
        if (
            numPedidoCliente_codigo == referenceFormData.numPedidoCliente_codigo &&
            idAdmCliente == referenceFormData.idAdmCliente &&
            idAdmCentroCoste == referenceFormData.idAdmCentroCoste &&
            idAdmElementoPEP == referenceFormData.idAdmElementoPEP &&
            idAdmFormaPago == referenceFormData.idAdmFormaPago &&
            idMoneda == referenceFormData.idMoneda &&
            tasaCambio == referenceFormData.tasaCambio &&
            descuento == referenceFormData.descuento &&
            idAdmTipoDescuento == referenceFormData.idAdmTipoDescuento &&
            idIvaNPais == referenceFormData.idIvaNPais &&
            idIncoterm == referenceFormData.idIncoterm &&
            idAdmTipoCambio == referenceFormData.idAdmTipoCambio //&&
            // formData.articulosDraft.length == 0
        ) {
            editData = false;
        }
        return editData;
    };

    return (
        <>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex"} style={{ justifyContent: "space-between" }}>
                        {ScreenCxt.mainScreen && (
                            <div className={"d-flex"}>
                                <Button {...editorOptions.cargarPedido} />
                                {pedidoAsociado && (
                                    <div className="ml-3 position-relative">
                                        <TextBox
                                            value={`${getTrad("pedido")} ${pedidoAsociado}`}
                                            stylingMode="outlined"
                                            readOnly
                                        />
                                        <Button
                                            className="clearButtonLlamamiento"
                                            stylingMode="text"
                                            icon="clear"
                                            disabled={formData.isCerrado}
                                            onClick={cargarPedidoCliente_REF.current.quitarPedidoSeleccionado}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className={"d-flex"} style={{ marginTop: "auto", marginBottom: "auto" }}>
                            {ChipEstado(formData.idAdmAlbaran_Estado, "", "albaran")}
                        </div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={(!ScreenCxt.mainScreen ? true : false) || formData.isCerrado}
                        >
                            <Item
                                dataField={"idTipoAlbaran"}
                                label={labels.idTipoAlbaran}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idTipoAlbaran}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                editorOptions={sharedEditorOptions.codigo}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fechaCreacion"}
                                label={labels.fechaCreacion}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"numPedidoCliente_codigo"}
                                label={labels.numPedidoCliente}
                                colSpan={1 * multiplier}
                                editorOptions={editorOptions.numPedidoCliente}
                            />
                            <Item
                                dataField={"idAdmCliente"}
                                label={labels.idAdmCliente}
                                colSpan={5}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmCliente}
                                // isRequired
                            />
                            <Item
                                dataField={"idTipoFactura"}
                                label={labels.idTipoFactura}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idTipoFactura}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item colSpan={4 * multiplier}>
                                <CentroCosteElementoPepComponent
                                    ref={CCEPFormRef}
                                    formData={formData}
                                    readOnly={pedidoAsociado != null ? true : false}
                                />
                                {/* <CustomRule validationCallback={CCEPFormRef?.current?.checkIsValid}/> */}
                            </Item>
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idMoneda}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmFormaPago"}
                                label={labels.idAdmFormaPago}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmFormaPago}
                                colSpan={1 * multiplier + 2}
                                // isRequired
                            />
                            <Item
                                dataField={"idIncoterm"}
                                label={labels.idIncoterm}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idIncoterm}
                                colSpan={1 * multiplier + 2}
                                // isRequired
                            />
                        </DxForm>
                    </div>
                </CardBody>
            </Card>
            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "visible",
                    }}
                >
                    <CardBody>
                        <DataGridArticulos
                            idMoneda={idMoneda}
                            dataSource={formData.articulosDraft}
                            validations={validations}
                            updateSummary={summaryFormRef?.current?.updateSummary}
                            showIva={false}
                            tipoArticulos={formData.idTipoAlbaran}
                            isCompra={false}
                            readOnly={(!ScreenCxt.mainScreen ? true : false) || formData.isCerrado}
                        />
                    </CardBody>
                </Card>
            </div>
            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <DxForm
                                ref={formInferiorRef}
                                formData={formData}
                                onFieldDataChanged={onFieldDataChanged}
                                colCount={4 * multiplier}
                                height={"100%"}
                                width={"100%"}
                                labelLocation={"top"}
                                readOnly={(!ScreenCxt.mainScreen ? true : false) || formData.isCerrado}
                            >
                                <Item colSpan={1 * multiplier + 1}>
                                    <TasaCambioTipoCambioComponent
                                        keyExpr={"idAdmAlbaranVenta"}
                                        ref={TCTCFormRef}
                                        formData={formData}
                                    />
                                </Item>
                                <Item
                                    dataField={"observaciones"}
                                    label={labels.observaciones}
                                    editorType={"dxTextArea"}
                                    colSpan={2 * multiplier + 2}
                                />
                            </DxForm>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginLeft: "auto",
                            marginRight: 0,
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <TablaResumen
                                ref={summaryFormRef}
                                formData={formData}
                                idMoneda={idMoneda}
                                showIva={true}
                                readOnly={!ScreenCxt.mainScreen ? true : false}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <CargarPedidos
                ref={cargarPedidoCliente_REF}
                cargarPedidoPopup_visibility={cargarPedidoPopup_visibility}
                setCargarPedidoPopup_visibility={setCargarPedidoPopup_visibility}
                setPedido={setPedido}
                hasEditData={hasEditData}
            />
        </>
    );
});

export default Form;
