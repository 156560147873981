import React, { Component, createRef } from "react";
import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "../../../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../../../helpers";
import { Tooltip } from "devextreme-react/tooltip";
import { connect } from "react-redux";
import { DataGrid } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import { Column, FilterRow, HeaderFilter, Paging, SearchPanel, Selection } from "devextreme-react/data-grid";

class SelectorLavanderias extends Component {
    constructor(props) {
        super(props);

        this.state = { dataSource: new ArrayStore(), selectedRowKeys: [], tblLavanderia: [] };
    }

    datasource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GetAccesoLavanderias",
            key: "idLavanderia",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblLavanderia_beforeSend(request),
            version: 4,
        }),
    });

    datasource_tblLavanderia_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { personaSel } = this.props;

        request.params = {
            idPersona: personaSel.idPersona,
            idPais: personaSel.idPais,
        };

        this.dataGridREF = createRef(null);
    };

    get dataGrid() {
        return this.dataGridREF.current.instance;
    }

    render() {
        const { dataSource, selectedRowKeys } = this.state;

        return (
            <>
                <DataGrid
                    ref={this.dataGridREF}
                    dataSource={dataSource}
                    height={"100%"}
                    style={{ maxHeight: 425 }}
                    accessKey={"idLavanderia"}
                    columnsAutoWidth={true}
                    remoteOperations={false}
                    selectedRowKeys={selectedRowKeys}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onSelectionChanged={this.onSelectionChanged}
                    onToolbarPreparing={this.onToolbarPreparing}
                >
                    <SearchPanel visible={true} width={240} />
                    <HeaderFilter visible={false} />
                    <FilterRow visible={false} />
                    <Paging enabled={false} />
                    <Selection mode={"multiple"} showCheckBoxesMode={"always"} />
                    <Column
                        dataField={"denominacion"}
                        caption={getTrad("lavanderia")}
                        sortOrder={"asc"}
                        minWidth={250}
                        allowEditing={false}
                        cellTemplate={this.cellTemplate}
                    />
                </DataGrid>
            </>
        );
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: "before",
            text: "Acceso a lavanderías",
        });
    };

    onSelectionChanged = ({ selectedRowKeys }) => {
        const { tblLavanderia } = this.state;
        const { personaSel } = this.props;
        tblLavanderia.forEach((x) => {
            if (x.disabled && !selectedRowKeys.includes(x.idLavanderia)) {
                selectedRowKeys.push(x.idLavanderia);
            }
        });
        this.setState({ selectedRowKeys });
    };

    cellTemplate = (element, item) => {
        $(element).text(item.data.denominacion);
        if (item.data.disabled) {
            $(element).prev().children().addClass("dx-state-disabled");
            $(element).append(
                $("<div />").dxTooltip({
                    height: "42px",
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend",
                    position: "top",
                    contentTemplate: function (contentElement) {
                        contentElement.html("No tienes acceso a esta lavandería o es el centro de la persona.");
                    },
                    target: $(element).parent().children()[0],
                })
            );
        }
    };

    componentDidMount() {
        const { personaSel } = this.props;

        this.datasource_tblLavanderia.load().done((tblLavanderia) => {
            const newCentroValue = JSON.parse(personaSel.idCentroLav);
            const newIdLavanderia = newCentroValue.length == 0 || newCentroValue[1] <= 0 ? null : newCentroValue[1];

            let reg_lav = tblLavanderia.find((x) => x.idLavanderia === newIdLavanderia);
            if (reg_lav) {
                reg_lav.disabled = true;
            }
            this.setState({
                tblLavanderia,
                dataSource: new ArrayStore({
                    key: "idLavanderia",
                    data: tblLavanderia.filter((x) => x.idPais === personaSel.idPais),
                }),
                selectedRowKeys: personaSel.tblLavanderiaNUsuario
                    ? personaSel.tblLavanderiaNUsuario.map((lav) => lav.idLavanderia)
                    : [newIdLavanderia],
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { personaSel, onChange } = this.props;
        const { dataSource, selectedRowKeys } = this.state;

        const oldCentroValue = JSON.parse(prevProps.personaSel.idCentroLav);
        const oldIdLavanderia = oldCentroValue.length == 0 || oldCentroValue[1] <= 0 ? null : oldCentroValue[1];

        const newCentroValue = JSON.parse(personaSel.idCentroLav);
        const newIdLavanderia = newCentroValue.length == 0 || newCentroValue[1] <= 0 ? null : newCentroValue[1];
        if (oldIdLavanderia !== newIdLavanderia) {
            selectedRowKeys.push(newIdLavanderia);

            let reg_old = dataSource.byKey(oldIdLavanderia);
            if (reg_old) {
                reg_old.disabled = false;
            }

            let reg_new = dataSource.byKey(newIdLavanderia);
            if (reg_new) {
                reg_new.disabled = true;
            }
        }

        if (prevState.selectedRowKeys.length !== selectedRowKeys.length) {
            onChange(selectedRowKeys);
        }
    }
}

export default SelectorLavanderias;
