import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

//Actions
import { loadPanelActions } from "../../actions";
import $ from "jquery";
import { dxMensajePregunta, getTrad, compressBase64Img_scaleWidth, rotateBase64Image } from "../../helpers";

import { Button, FileUploader, Gallery } from "devextreme-react";

import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { NumberBox, Button as NumberBoxButton } from "devextreme-react/number-box";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import { SelectBox, Button as SelectBoxButton } from "devextreme-react/select-box";

import Box, { Item as ItemBox } from "devextreme-react/box";

import { Form, GroupItem, SimpleItem, NumericRule, EmailRule } from "devextreme-react/form";
import { Popup, ToolbarItem as ToolbarItem_Popup } from "devextreme-react/popup";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { ScrollView } from "devextreme-react";

import { TileView } from "devextreme-react/tile-view";
import notify from "devextreme/ui/notify";

import { Validator } from "devextreme-react/validator";

import validationEngine from "devextreme/ui/validation_engine";
//Custom components
import LottieIcon from "../LottieIcon";
import CountryPicker from "../CountryPicker/CountryPicker";

import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import es from "react-phone-input-2/lang/es.json";

//Css
import "./Css.scss";
import { RequiredRule } from "devextreme-react/data-grid";

class ActionToast extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      direction: "column",
      notificacionSel: null,
      notificacionSel_data: null,
      archivoSel: null,
      archivoSel_antiguo: null,
      dataField_archivoSel: null,
      dataSources_aux: null,
      removeFotos: [],
      isVisible_editActionToast: false,
      isVisible_GaleriaArchivos: false,
      idFiltro: 0,
      isValidating: false,
    };

    // REFERENCIAS
    this.dxForm_cambiosPersonal_REF = React.createRef();
    this.dxForm_cambiosPersonal_original_REF = React.createRef();
    this.dxScrollView_cambiosPersonal_REF = React.createRef();

    //RENDER
    this.dxFormItem_readOnly_render = this.dxFormItem_readOnly_render.bind(this);
    this.dxFormItem_render = this.dxFormItem_render.bind(this);
    this.fotos_itemRender = this.fotos_itemRender.bind(this);
    this.dxTile_onClick = this.dxTile_onClick.bind(this);
    this.documentos_itemRender = this.documentos_itemRender.bind(this);

    //EVENTOS
    //NOTIFICACIONES
    this.actionToast_onClick = this.actionToast_onClick.bind(this);
    this.get_descripcion_notificacion = this.get_descripcion_notificacion.bind(this);

    //POPUP
    this.dxPopup_editActionToast_onContentReady = this.dxPopup_editActionToast_onContentReady.bind(this);
    this.dxPopup_editActionToast_contentRender = this.dxPopup_editActionToast_contentRender.bind(this);
    this.dxPopup_editActionToast_onShowing = this.dxPopup_editActionToast_onShowing.bind(this);
    this.dxPopup_editActionToast_onShown = this.dxPopup_editActionToast_onShown.bind(this);
    this.dxPopup_editActionToast_onHiding = this.dxPopup_editActionToast_onHiding.bind(this);
    this.dxPopup_editActionToast_validar_onClick = this.dxPopup_editActionToast_validar_onClick.bind(this);
    this.dxPopup_editActionToast_denegar_onClick = this.dxPopup_editActionToast_denegar_onClick.bind(this);
    this.dxPopup_editActionToast_cancelar_onClick = this.dxPopup_editActionToast_cancelar_onClick.bind(this);
    this.dxPopup_galeriaFotos_aceptar_onClick = this.dxPopup_galeriaFotos_aceptar_onClick.bind(this);
    this.onContentReady_popup_galeriaArchivos = this.onContentReady_popup_galeriaArchivos.bind(this);
    this.onHiding_popup_galeriaArchivos = this.onHiding_popup_galeriaArchivos.bind(this);
    this.dxButton_rotarImagen_onClick = this.dxButton_rotarImagen_onClick.bind(this);

    //FORM
    this.dxFormEditor_onValueChanged = this.dxFormEditor_onValueChanged.bind(this);
    this.dxButton_revertirBtn_onClick = this.dxButton_revertirBtn_onClick.bind(this);
    this.dxFormEditor_telefono_onValueChanged = this.dxFormEditor_telefono_onValueChanged.bind(this);
    this.fotos_onUploadStarted = this.fotos_onUploadStarted.bind(this);
    this.dxSelectBox_displayExpr = this.dxSelectBox_displayExpr.bind(this);
    this.updateValor_paises = this.updateValor_paises.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);

    this.getTrad = this.getTrad.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxForm_cambiosPersonal() {
    return this.dxForm_cambiosPersonal_REF.current.instance;
  }

  get dxForm_cambiosPersonal_original() {
    return this.dxForm_cambiosPersonal_original_REF.current.instance;
  }

  get dxScrollView_cambiosPersonal() {
    return this.dxScrollView_cambiosPersonal_REF.current.instance;
  }

  // PROPS ACEPTADAS
  // data -> array con campos: id, denominacion, descripcion, idTipoNotificacion, color
  // dataSources_aux -> Datasources para cargar listas de datos
  // direction -> row o column
  // validarNotificacion -> accion al validar notificacion
  // denegarNotificacion -> accion al denegar notificacion
  // cargarImagenes_tblPeticionCambioDatos -> accion para cargar imagenes al selecciona peticion datos
  // idFiltro -> filtra el data por un id
  // tipo -> notificaciones o comunicado -> para generar o no el popup editable
  // notificacionSel_imagenes -> imagenes de la notificacion seleccionada

  dxGallery_itemRender = (itemData) => {
    const prefix = itemData.substring(0, itemData.indexOf(","));

    if (prefix.startsWith("data:image/")) {
      return (
        <img
          className="image"
          style={{
            backgroundImage: "url(" + itemData + ")",
          }}
        />
      );
    } else if (prefix.startsWith("data:application/pdf")) {
      return <object toolbar="0" width="100%" height="100%" data={itemData} />;
    }
  };

  render() {
    let {
      data,
      direction,
      isVisible_editActionToast,
      isVisible_GaleriaArchivos,
      archivoSel,
      archivoSel_antiguo,
      notificacionSel,
      tipo,
      dataField_archivoSel,
    } = this.state;
    let { idioma, idSel, enableSelection } = this.props;

    const {
      idFotoDocumentoIdentidad_A,
      idFotoDocumentoIdentidad_ANavigation,
      idFotoDocumentoIdentidad_B,
      idFotoDocumentoIdentidad_BNavigation,
      idFotoIBAN,
      idFotoIBANNavigation,
      idFotoNAF,
      idFotoNAFNavigation,
      idFotoPerfil,
      idFotoPerfilNavigation,
      idFotoDemandanteEmpleo,
      idFotoDemandanteEmpleoNavigation,
      idDocCertificadoDiscapacidad,
      idDocCertificadoDiscapacidadNavigation,
    } = notificacionSel?.data || {};

    const allDatosCargados =
      !!notificacionSel?.data &&
      (idFotoDocumentoIdentidad_A == null || !!idFotoDocumentoIdentidad_ANavigation) &&
      (idFotoDocumentoIdentidad_B == null || !!idFotoDocumentoIdentidad_BNavigation) &&
      (idFotoPerfil == null || !!idFotoPerfilNavigation) &&
      (idFotoNAF == null || !!idFotoNAFNavigation) &&
      (idFotoIBAN == null || !!idFotoIBANNavigation) &&
      (idFotoDemandanteEmpleo == null || !!idFotoDemandanteEmpleoNavigation) &&
      (idDocCertificadoDiscapacidad == null || !!idDocCertificadoDiscapacidadNavigation);

    return (
      <div id="ActionToast" className="d-inline">
        {/* Comunicado cards */}
        <Row>
          {data == null || data.length == 0
            ? null
            : data.map((item, i) =>
                item.tblTipoNotificacion == null ? (
                  <Col
                    key={i}
                    xs="12"
                    md="12"
                    lg={direction === "column" ? "12" : "6"}
                    xl={direction === "column" ? "12" : "6"}
                  >
                    <div
                      style={{
                        "--notificacionColor": enableSelection
                          ? idSel && idSel == item.id
                            ? "#EDB637"
                            : "#9e9e9e"
                          : null,
                      }}
                      className={"d-flex flex-row actionToast-container mb-3 pb-3"}
                    >
                      <div
                        className="d-flex w-100 overflow-hidden"
                        onClick={
                          this.props.onClick_SelectionToast
                            ? (e) => {
                                this.props.onClick_SelectionToast(e, item);
                              }
                            : null
                        }
                      >
                        <div className="d-flex flex-column w-100">
                          <div className="d-flex flex-row w-100">
                            <div
                              className="actionToastTitle font-size-xl textOverflowEllipsis"
                              style={{ color: "#817f80", flex: 1 }}
                            >
                              {item.denominacion + (item.abreviatura != null ? " (" + item.abreviatura + ")" : "")}
                            </div>
                            <div className="d-flex comunicado_editable">
                              <i
                                className="font-size-lg dx-icon-edit"
                                onClick={(e) => {
                                  this.props.onClick_Button_editToast(e, item);
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-column w-100 justify-content-start align-items-start pt-1">
                            {item.dxSwitch_Activo}
                          </div>
                          <div className="actionToastDescripcion" style={{ minHeight: 21, wordBreak: "break-word" }}>
                            <div className="d-flex w-100 pb-2 pt-3">{item.descripcion}</div>
                          </div>
                          {item.tagBox_categorias && (
                            <div className="d-flex comunicado_editable">{item.tagBox_categorias}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col
                    key={i}
                    xs="12"
                    md="12"
                    lg={direction === "column" ? "12" : "6"}
                    xl={direction === "column" ? "12" : "6"}
                  >
                    <div
                      style={{
                        "--notificacionColor": item.tblTipoNotificacion.color,
                      }}
                      className={
                        "d-flex flex-row actionToast-container mb-3" +
                        (item.tblTipoNotificacion.clave !== "comunicado" ? " pb-2" : " pr-3")
                      }
                      onClick={(e) => {
                        this.actionToast_onClick(i, item);
                      }}
                    >
                      <div
                        className="d-flex w-100"
                        onClick={
                          this.props.onClick_Button_editComunicado
                            ? (e) => {
                                this.props.onClick_Button_editComunicado(e, item);
                              }
                            : null
                        }
                      >
                        {item.tblTipoNotificacion.clave === "comunicado" ? (
                          <div
                            style={{
                              flexBasis: item.tblTipoNotificacion.icon ? 60 : 0,
                            }}
                            className="actionToastIcon d-flex align-items-center mx-auto pr-3 "
                          >
                            {item.tblTipoNotificacion.icon && (
                              <LottieIcon icon={item.tblTipoNotificacion.icon} isLoop={false} height={45} width={45} />
                            )}
                          </div>
                        ) : item.tblTipoNotificacion.icon ? (
                          <div
                            style={{
                              flexBasis: item.tblTipoNotificacion.icon ? 60 : 0,
                            }}
                            className={
                              "actionToastIcon d-flex align-items-center font-size-xxxxl mx-auto pr-3 " +
                              "icon_" +
                              item.tblTipoNotificacion.icon
                            }
                          />
                        ) : (
                          <div style={{ paddingLeft: 11 }} />
                        )}
                        <div className="d-flex flex-column w-100">
                          <div className="actionToastTitle">
                            {item.tblTipoNotificacion.clave === "comunicado"
                              ? item.data.titulo
                              : item.tblTipoNotificacion.idTraduccionDenominacionNavigation
                              ? item.tblTipoNotificacion.idTraduccionDenominacionNavigation[idioma.codigo]
                              : item.tblTipoNotificacion.clave === "tblDocumento_nuevo" ||
                                item.tblTipoNotificacion.clave === "tblDocumento_requerido" ||
                                item.tblTipoNotificacion.clave === "tblDocumento_firmado"
                              ? item.tblTipoNotificacion.idTraduccionDescripcionNavigation[idioma.codigo]
                              : null}
                          </div>
                          <div className="actionToastDescripcion" style={{ minHeight: 21, wordBreak: "break-word" }}>
                            {item.tblTipoNotificacion.clave === "comunicado"
                              ? item.data.contenido
                              : this.get_descripcion_notificacion(item)}
                          </div>
                          {item.tblTipoNotificacion.clave !== "comunicado" ? (
                            <div className="font-size-xxxxs timeSpan_notificacion">
                              <span className="float-right">{this.get_format_fechaEnvio(item)}</span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {item.tblTipoNotificacion.clave === "comunicado" ? (
                        <div
                          className="d-flex comunicado_editable pl-3"
                          style={{
                            flexBasis: 20,
                          }}
                        >
                          <i
                            className="font-size-lg dx-icon-remove"
                            onClick={(e) => {
                              this.props.onClick_Button_removeComunicado(e, item);
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Col>
                )
              )}
        </Row>
        {/* POPUP DE DATOS Y CAMBIO DE DATOS */}
        {tipo === "notificaciones" && (
          <Popup
            visible={isVisible_editActionToast}
            dragEnabled={false}
            deferRendering={false}
            width={"65%"}
            minWidth={950}
            height={"85%"}
            title={
              !notificacionSel
                ? ""
                : notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente"
                ? this.getTrad("solicitudCambioDatos")
                : notificacionSel.tblTipoNotificacion.clave === "tblDocumento"
                ? this.getTrad("firmarDocumento")
                : ""
            }
            onContentReady={this.dxPopup_editActionToast_onContentReady}
            contentRender={this.dxPopup_editActionToast_contentRender}
            onShowing={this.dxPopup_editActionToast_onShowing}
            onShown={this.dxPopup_editActionToast_onShown}
            onHiding={this.dxPopup_editActionToast_onHiding}
          >
            <ToolbarItem_Popup
              toolbar="bottom"
              location="after"
              visible={
                notificacionSel &&
                notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente"
              }
            >
              <Button
                text={this.getTrad("cancelar")}
                type="normal"
                onClick={this.dxPopup_editActionToast_cancelar_onClick}
              />
            </ToolbarItem_Popup>
            <ToolbarItem_Popup
              toolbar="bottom"
              location="after"
              visible={
                notificacionSel &&
                notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente"
              }
            >
              <Button
                text={this.getTrad("denegar")}
                type="danger"
                disabled={!allDatosCargados}
                icon="clear"
                onClick={this.dxPopup_editActionToast_denegar_onClick}
              />
            </ToolbarItem_Popup>
            <ToolbarItem_Popup
              toolbar="bottom"
              location="after"
              visible={
                notificacionSel &&
                notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente"
              }
            >
              <Button
                text={this.getTrad("validar")}
                type={"success"}
                disabled={!allDatosCargados}
                onClick={this.dxPopup_editActionToast_validar_onClick}
              />
            </ToolbarItem_Popup>
          </Popup>
        )}
        {/* //#region GALERIA ARCHIVOS */}
        <Popup
          showTitle={true}
          title={this.getTrad("añadirFoto")}
          visible={isVisible_GaleriaArchivos}
          showCloseButton={true}
          closeOnOutsideClick={true}
          width={"60%"}
          minWidth={850}
          height={"85%"}
          onContentReady={this.onContentReady_popup_galeriaArchivos}
          onHiding={this.onHiding_popup_galeriaArchivos}
        >
          <Box direction="col" width="100%" height="100%">
            <ItemBox ratio={1}>
              <Box direction="row" width="100%" height="100%">
                <ItemBox ratio={1}>
                  <Box direction="col" width="100%" height="100%">
                    <ItemBox baseSize="auto">
                      {" "}
                      <div className="font-size">{this.getTrad("antigua")}</div>
                    </ItemBox>
                    <ItemBox ratio={1}>
                      <Gallery
                        items={
                          archivoSel_antiguo
                            ? ["data:" + archivoSel_antiguo.extension + ";base64," + archivoSel_antiguo.documento]
                            : []
                        }
                        showIndicator={false}
                        showNavButtons={false}
                        loop={false}
                        height="100%"
                        width="100%"
                        noDataText={this.getTrad("sinFoto")}
                        itemRender={this.dxGallery_itemRender}
                      />
                    </ItemBox>
                    <ItemBox
                      baseSize="auto"
                      visible={
                        dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                        dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation" ||
                        dataField_archivoSel == "idFotoNAFNavigation" ||
                        dataField_archivoSel == "idFotoIBANNavigation" ||
                        dataField_archivoSel == "idFotoDemandanteEmpleoNavigation" ||
                        dataField_archivoSel == "idDocCertificadoDiscapacidadNavigation"
                      }
                    >
                      <Form labelLocation="left" readOnly={true}>
                        <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                          <SimpleItem
                            dataField="numDocumentoIdentidad"
                            visible={
                              dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                              dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation"
                            }
                            render={this.dxFormItem_readOnly_render}
                            label={this.formLabel_numDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="caducidadDocumentoIdentidad"
                            visible={
                              dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                              dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation"
                            }
                            render={this.dxFormItem_readOnly_render}
                            label={this.formLabel_caducidadDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="NAF"
                            visible={dataField_archivoSel == "idFotoNAFNavigation"}
                            render={this.dxFormItem_readOnly_render}
                            label={this.formLabel_NAF}
                          />
                          <SimpleItem
                            dataField="IBAN"
                            visible={dataField_archivoSel == "idFotoIBANNavigation"}
                            render={this.dxFormItem_readOnly_render}
                            label={this.formLabel_IBAN}
                          />
                        </GroupItem>
                      </Form>
                    </ItemBox>
                  </Box>
                </ItemBox>
                <ItemBox baseSize={25} />
                <ItemBox ratio={1}>
                  <Box direction="col" width="100%" height="100%">
                    <ItemBox baseSize="auto">
                      {" "}
                      <div className="font-size">{this.getTrad("nueva")}</div>
                    </ItemBox>
                    <ItemBox ratio={1}>
                      <Gallery
                        items={archivoSel ? ["data:" + archivoSel.extension + ";base64," + archivoSel.documento] : []}
                        showIndicator={false}
                        showNavButtons={false}
                        loop={false}
                        height="100%"
                        width="100%"
                        noDataText={this.getTrad("sinFoto")}
                        itemRender={this.dxGallery_itemRender}
                      />
                    </ItemBox>
                    <ItemBox baseSize={50}>
                      <div className="align-items-center he-100 d-flex pl-2">
                        <Button width={60} hint="Rotar" icon="pulldown" onClick={this.dxButton_rotarImagen_onClick} />
                      </div>
                    </ItemBox>
                    <ItemBox
                      baseSize="auto"
                      visible={
                        dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                        dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation" ||
                        dataField_archivoSel == "idFotoNAFNavigation" ||
                        dataField_archivoSel == "idFotoIBANNavigation" ||
                        dataField_archivoSel == "idFotoDemandanteEmpleoNavigation" ||
                        dataField_archivoSel == "idDocCertificadoDiscapacidadNavigation"
                      }
                    >
                      <Form labelLocation="left">
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem
                            dataField="numDocumentoIdentidad"
                            visible={
                              dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                              dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation"
                            }
                            render={this.dxFormItem_render}
                            label={this.formLabel_numDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="caducidadDocumentoIdentidad"
                            visible={
                              dataField_archivoSel == "idFotoDocumentoIdentidad_ANavigation" ||
                              dataField_archivoSel == "idFotoDocumentoIdentidad_BNavigation"
                            }
                            render={this.dxFormItem_render}
                            label={this.formLabel_caducidadDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="NAF"
                            visible={dataField_archivoSel == "idFotoNAFNavigation"}
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoNAFNavigation}
                          />
                          <SimpleItem
                            dataField="IBAN"
                            visible={dataField_archivoSel == "idFotoIBANNavigation"}
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoIBANNavigation}
                          />
                        </GroupItem>
                      </Form>
                    </ItemBox>
                  </Box>
                </ItemBox>
              </Box>
            </ItemBox>
            <ItemBox baseSize={40} />
            <ItemBox baseSize={60}>
              <Box direction="row" width="100%" height="100%">
                <ItemBox ratio={1}>
                  <FileUploader
                    width="100%"
                    labelText={this.getTrad("msg_arrastraArchivo5mb")}
                    accept={"image/*,application/pdf"}
                    minFileSize={1}
                    uploadMode="instantly"
                    uploadFailedMessage={this.getTrad("subidaFallida")}
                    selectButtonText={this.getTrad("subirArchivo")}
                    onUploadStarted={this.fotos_onUploadStarted}
                  />
                </ItemBox>
                <ItemBox baseSize={120}>
                  <Button
                    text={this.getTrad("aceptar")}
                    type="success"
                    onClick={this.dxPopup_galeriaFotos_aceptar_onClick}
                  />
                </ItemBox>
              </Box>
            </ItemBox>
          </Box>
        </Popup>
      </div>
    );
  }

  //#region PROPIEDADES
  formLabel_idFotoPerfilNavigation = {
    text: this.getTrad("fotoPerfil"),
    visible: false,
    icon: "icon_face_recognition",
  };
  formLabel_nombre = {
    text: this.getTrad("nombre"),
    visible: false,
    icon: "icon_Rendimiento",
  };
  formLabel_apellidos = {
    text: this.getTrad("apellidos"),
    visible: false,
    icon: "icon_account_details_outline",
  };
  formLabel_email = {
    text: this.getTrad("email"),
    visible: false,
    icon: "icon_email_outline",
  };
  formLabel_telefono = {
    text: this.getTrad("telefono"),
    visible: false,
    icon: "icon_Telefono",
  };
  formLabel_calle = {
    text: this.getTrad("calle"),
    visible: false,
    icon: "inf_icons_RutasExpedicion",
  };
  formLabel_numDomicilio = {
    text: this.getTrad("numDomicilio"),
    visible: false,
    icon: "icon_numeric",
  };
  formLabel_piso = {
    text: this.getTrad("piso"),
    visible: false,
    icon: "icon_format_list_number_outline",
  };
  formLabel_puerta = {
    text: this.getTrad("puerta"),
    visible: false,
    icon: "icon_door_outline",
  };
  formLabel_codigoPostal = {
    text: this.getTrad("codigoPostal"),
    visible: false,
    icon: "icon_mailbox_open_outline",
  };
  formLabel_localidad = {
    text: this.getTrad("localidad"),
    visible: false,
    icon: "icon_city_variant_outline",
  };
  formLabel_nacionalidad = {
    text: this.getTrad("nacionalidad"),
    visible: false,
    icon: "icon_flag_variant_outline",
  };
  formLabel_idComunidadAutonoma = {
    text: this.getTrad("comunidadAutonoma"),
    visible: false,
    icon: "icon_map_outline",
  };
  formLabel_idPais = {
    text: this.getTrad("pais"),
    visible: false,
    icon: "icon_earth",
  };
  formLabel_idTipoDocumentoIdentidad = {
    text: this.getTrad("tipoDocumento"),
    visible: false,
    icon: "icon_card_account_details_outline",
  };
  formLabel_numDocumentoIdentidad = {
    text: this.getTrad("numDocumento"),
    visible: false,
    icon: "icon_numeric",
  };
  formLabel_caducidadDocumentoIdentidad = {
    text: this.getTrad("caducidad"),
    visible: false,
    icon: "icon_CalendarioMes",
  };
  formLabel_idFotoDocumentoIdentidad_ANavigation = {
    text: this.getTrad("fotoCaraDelantera"),
    visible: false,
    icon: "icon_badge_account_horizontal_outline",
  };
  formLabel_idFotoDocumentoIdentidad_BNavigation = {
    text: this.getTrad("fotoCaraTrasera"),
    visible: false,
    icon: "icon_badge_account_horizontal_outline",
  };
  formLabel_idFotoNAFNavigation = {
    text: this.getTrad("fotoNAF"),
    visible: false,
    icon: "icon_id_card",
  };
  formLabel_idFotoIBANNavigation = {
    text: this.getTrad("fotoIBAN"),
    visible: false,
    icon: "icon_credit_card_outline",
  };
  formLabel_NAF = {
    text: this.getTrad("NAF"),
    visible: false,
    icon: "icon_id_card",
  };
  formLabel_IBAN = {
    text: this.getTrad("IBAN"),
    visible: false,
    icon: "icon_credit_card_outline",
  };
  formLabel_idGenero = {
    text: this.getTrad("genero"),
    visible: false,
    icon: "icon_gender_male_female_variant",
  };
  formLabel_fechaNacimiento = {
    text: this.getTrad("fechaNacimiento"),
    visible: false,
    icon: "icon_cake",
  };
  formLabel_nombre_tutor = {
    text: this.getTrad("nombreTutor"),
    visible: false,
    icon: "icon_Rendimiento",
  };
  formLabel_apellidos_tutor = {
    text: this.getTrad("apellidosTutor"),
    visible: false,
    icon: "icon_account_details_outline",
  };
  formLabel_idTipoDocumentoIdentidad_tutor = {
    text: this.getTrad("tipoDocumentoTutor"),
    visible: false,
    icon: "icon_card_account_details_outline",
  };
  formLabel_numDocumentoIdentidad_tutor = {
    text: this.getTrad("numDocumentoTutor"),
    visible: false,
    icon: "icon_numeric",
  };
  formLabel_idEstadoCivil = {
    text: this.getTrad("estadoCivil"),
    visible: false,
    icon: "inf_icons_RRHH",
  };
  formLabel_numHijos = {
    text: this.getTrad("numHijos"),
    visible: false,
    icon: "icon_account_child_outline",
  };
  formLabel_idNivelEstudios = {
    text: this.getTrad("nivelEstudios"),
    visible: false,
    icon: "icon_book_account_outline",
  };
  formLabel_idDiscapacidad = {
    text: this.getTrad("discapacidad"),
    visible: false,
    icon: "icon_book_account_outline",
  };
  formLabel_idFotoDemandanteEmpleoNavigation = {
    text: this.getTrad("fotoDemandanteEmpleo"),
    visible: false,
    icon: "icon_card_account_details_outline",
  };
  formLabel_idDocCertificadoDiscapacidadNavigation = {
    text: this.getTrad("certificadoDiscapacidad"),
    visible: false,
    icon: "icon_book_account_outline",
  };
  formLabel_idTallaAlfa_Camiseta = {
    text: this.getTrad("tallaCamiseta"),
    visible: false,
    icon: "menu_icons_Valet",
  };
  formLabel_tallaPantalon = {
    text: this.getTrad("tallaPantalon"),
    visible: false,
    icon: "icon_numeric",
  };

  dxScrollView_elementAttr = {
    id: "dxScrollView_noficacionPersona",
  };
  circular_tileView = {
    class: "circular_tileView",
  };
  dxTileViewItems_size = {
    fotoPerfil: {
      width: 100,
      itemMargin: 0,
    },
    fotos: {
      width: 200,
      itemMargin: 0,
    },
  };
  template_tile_documento_elemAttr = {
    class: "dxToolbar_opciones dxToolbar_position",
  };
  //#endregion

  //#region MÉTODOS
  get_format_fechaEnvio(item) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = new Date() - item.fecha;

    if (elapsed < msPerMinute) {
      return "hace " + Math.round(elapsed / 1000) + " seg";
    } else if (elapsed < msPerHour) {
      return "hace " + Math.round(elapsed / msPerMinute) + " min";
    } else if (elapsed < msPerDay) {
      return "hace " + Math.round(elapsed / msPerHour) + " horas";
    } else if (elapsed < msPerMonth) {
      return "hace " + Math.round(elapsed / msPerDay) + " días";
    } else if (elapsed < msPerYear) {
      return "hace " + Math.round(elapsed / msPerMonth) + " meses";
    } else {
      return "hace " + Math.round(elapsed / msPerYear) + " años";
    }
  }

  //#endregion

  //#region EVENTOS

  //#region POPUP

  //#region FORM
  fotos_onUploadStarted(e) {
    let notificacionSel = { ...this.state.notificacionSel };
    let { dataField_archivoSel } = this.state;

    let reader = new FileReader(),
      _this = this;

    if (e.file.size > 5242880) {
      notify({
        message: _this.getTrad("subirArchivo"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    } else {
      reader.onloadend = function () {
        if (reader.result !== "") {
          let fileSplit = reader.result.split(",");
          let extension = e.file.type;
          let base64 = fileSplit[1];
          let fileName = dataField_archivoSel.replace("Navigation", "");

          let idDocumento = notificacionSel.data.idPersonaNavigation[dataField_archivoSel.replace("Navigation", "")];
          function insertarDocumento() {
            let addedItem = {
              idDocumento: idDocumento,
              denominacion: fileName,
              documento: base64,
              extension: extension,
              fecha: new Date(),
              fechaModificacion: new Date(),
              idPersona: notificacionSel.data.idPersona,
            };

            e.component.option({
              value: [],
            });

            if (!idDocumento) delete addedItem.idDocumento;

            _this.setState({ archivoSel: addedItem });
          }

          //Compress
          if (extension.split("/")[0] === "image") {
            compressBase64Img_scaleWidth(reader.result, 800).then(function (compressedBase64) {
              base64 = compressedBase64;
              insertarDocumento();
            });
          } else {
            insertarDocumento();
          }
        }
      };
      reader.readAsDataURL(e.file);
    }
    e.request.abort();
  }

  dxFormEditor_readOnly_onContentReady(e) {
    setTimeout(() => {
      $(e.element).css("border", "none");
      $(e.element).find("input").css({
        "text-align": "end",
        "font-weight": 500,
      });
      $(e.element).find(".dx-lookup-arrow").css("display", "none");

      $(e.element).find(".dx-texteditor-input").css({
        "text-align": "end",
        "font-weight": 500,
      });

      $(e.element).find(".countryText").css({
        "justify-content": "flex-end",
        display: "flex",
      });
    }, 0);
  }

  isImage(dataField) {
    return (
      dataField == "idFotoDocumentoIdentidad_ANavigation" ||
      dataField == "idFotoDocumentoIdentidad_BNavigation" ||
      dataField == "idFotoNAFNavigation" ||
      dataField == "idFotoIBANNavigation" ||
      dataField == "idFotoPerfilNavigation" ||
      dataField == "idFotoDemandanteEmpleoNavigation" ||
      dataField == "idDocCertificadoDiscapacidadNavigation"
    );
  }

  dxFormItem_render(data) {
    let { notificacionSel, dataSources_aux, isValidating } = this.state;
    const isIBANSolicitado = notificacionSel?.data?.idPersonaNavigation?.isIBANSolicitado;

    let originalValue = !notificacionSel ? null : notificacionSel.data.idPersonaNavigation[data.dataField];
    originalValue = !originalValue ? null : originalValue;

    let isImage = this.isImage(data.dataField);

    let newData = !notificacionSel ? null : notificacionSel.data[data.editorOptions.name];
    newData = !newData ? null : newData;
    let value = newData;

    let formLabel = this["formLabel_" + data.dataField];
    let date = new Date();

    let color = JSON.stringify(value) != JSON.stringify(originalValue) ? "inputColor_blue" : "";
    if (notificacionSel && data.dataField == "telefono") {
      let prefijoOriginal = notificacionSel.data.idPersonaNavigation["prefijoTelefonico"];
      let prefijoNuevo = notificacionSel.data["prefijoTelefonico"];

      if (prefijoOriginal != prefijoNuevo) color = "inputColor_blue";
    }
    let is_imageError = isValidating && isImage && !newData;
    let isRequired =
      data.dataField != "piso" &&
      data.dataField != "puerta" &&
      data.dataField != "numHijos" &&
      data.dataField != "idEstadoCivil";

    const dataFieldNumberBox = ["numHijos", "tallaPantalon", "numDomicilio"];
    const dataFieldDateBox = ["caducidadDocumentoIdentidad", "fechaNacimiento"];
    const dataFieldSelectBox = [
      "idTallaAlfa_Camiseta",
      "idDiscapacidad",
      "idNivelEstudios",
      "idPais",
      "idGenero",
      "idTipoDocumentoIdentidad",
      "idTipoDocumentoIdentidad_tutor",
      "idEstadoCivil",
      "idComunidadAutonoma",
    ];
    const dataFieldCountryPicker = ["nacionalidad"];
    const dataFieldPhone = ["telefono"];

    let itemsSelectBox = {
      idTallaAlfa_Camiseta: dataSources_aux?.talla ?? [],
      idDiscapacidad: dataSources_aux?.discapacidad ?? [],
      idNivelEstudios: dataSources_aux?.nivelEstudios ?? [],
      idPais: dataSources_aux?.pais ?? [],
      idGenero: dataSources_aux?.genero ?? [],
      idTipoDocumentoIdentidad: dataSources_aux?.tipoDocumentoId ?? [],
      idTipoDocumentoIdentidad_tutor: dataSources_aux?.tipoDocumentoId ?? [],
      idEstadoCivil: dataSources_aux?.estadoCivil ?? [],
      idComunidadAutonoma: dataSources_aux?.comunidadAutonoma ?? [],
    };

    return (
      <div className="d-flex flex-row align-items-center">
        <i className={"font-size-lg pe-none align-middle pr-3 " + formLabel.icon + " " + color}></i>
        <div style={{ color: "var(--secondary)", flex: 2 }} className="pr-3 pe-none">
          {formLabel.text}
          <span className="dx-field-item-required-mark">{isRequired && " *"}</span>
        </div>
        <div
          style={{ flex: 3, height: isImage ? 120 : 35 }}
          className={"ml-auto d-flex justify-content-end " + color + " " + (is_imageError && "errorValidacion_img")}
        >
          {dataFieldNumberBox.includes(data.dataField) ? (
            <NumberBox
              name={data.dataField}
              stylingMode="underlined"
              value={!value ? null : parseInt(value)}
              step={0}
              min={0}
              max={9999}
              width="100%"
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              <Validator validationGroup="dxForm_cambiosPersonal_validationGroup">
                <NumericRule />
                {(data.dataField == "numDomicilio" || data.dataField == "tallaPantalon") && <RequiredRule />}
              </Validator>
              {color === "inputColor_blue" && (
                <NumberBoxButton
                  name="revertirBtn"
                  location="before"
                  options={{
                    elementAttr: this.revertirBtn_elementAttr,
                    hint: "Revertir",
                    icon: "undo",
                    stylingMode: "contained",
                    onClick: (e) => {
                      this.dxButton_revertirBtn_onClick(e, data);
                    },
                  }}
                />
              )}
            </NumberBox>
          ) : dataFieldDateBox.includes(data.dataField) ? (
            <DateBox
              displayFormat="dd/MM/yyyy"
              name={data.dataField}
              stylingMode="underlined"
              value={value}
              min={data.dataField == "caducidadDocumentoIdentidad" ? date.setFullYear(date.getFullYear() - 2) : null}
              max={data.dataField == "fechaNacimiento" ? date.setFullYear(date.getFullYear() - 15) : null}
              width="100%"
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              {color === "inputColor_blue" && (
                <DateBoxButton
                  name="revertirBtn"
                  location="before"
                  options={{
                    elementAttr: this.revertirBtn_elementAttr,
                    hint: "Revertir",
                    icon: "undo",
                    stylingMode: "contained",
                    onClick: (e) => {
                      this.dxButton_revertirBtn_onClick(e, data);
                    },
                  }}
                />
              )}
              <Validator validationGroup="dxForm_cambiosPersonal_validationGroup">
                <RequiredRule />
              </Validator>
            </DateBox>
          ) : dataFieldSelectBox.includes(data.dataField) ? (
            <SelectBox
              name={data.dataField}
              items={itemsSelectBox[data.dataField]}
              displayExpr={this.dxSelectBox_displayExpr}
              valueExpr={
                data.dataField == "idTallaAlfa_Camiseta"
                  ? "idTallaAlfa"
                  : data.dataField == "idTipoDocumentoIdentidad_tutor"
                  ? "idTipoDocumentoIdentidad"
                  : data.dataField
              }
              value={value}
              width="100%"
              stylingMode="underlined"
              onInitialized={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              {color === "inputColor_blue" && (
                <DateBoxButton
                  name="revertirBtn"
                  location="before"
                  options={{
                    elementAttr: this.revertirBtn_elementAttr,
                    hint: "Revertir",
                    icon: "undo",
                    stylingMode: "contained",
                    onClick: (e) => {
                      this.dxButton_revertirBtn_onClick(e, data);
                    },
                  }}
                />
              )}
              <Validator validationGroup="dxForm_cambiosPersonal_validationGroup">
                {isRequired && <RequiredRule />}
              </Validator>
            </SelectBox>
          ) : isImage ? (
            <Fragment>
              {color === "inputColor_blue" && (
                <Button
                  elementAttr={this.revertirBtn_elementAttr}
                  hint="Revertir"
                  icon="undo"
                  stylingMode="contained"
                  onClick={(e) => {
                    this.dxButton_revertirBtn_onClick(e, data);
                  }}
                />
              )}
              {is_imageError && (
                <div
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                  className="validationError_icon position-relative"
                >
                  !
                </div>
              )}
              <TileView
                elementAttr={data.dataField === "idFotoPerfilNavigation" ? this.circular_tileView : null}
                itemMargin={this.dxTileViewItems_size.fotos.itemMargin}
                baseItemWidth={
                  data.dataField === "idFotoPerfilNavigation"
                    ? this.dxTileViewItems_size.fotoPerfil.width
                    : this.dxTileViewItems_size.fotos.width
                }
                activeStateEnabled={false}
                items={[data]}
                itemRender={this.fotos_itemRender}
              ></TileView>
            </Fragment>
          ) : dataFieldCountryPicker.includes(data.dataField) ? (
            <Fragment>
              {color === "inputColor_blue" && (
                <Button
                  elementAttr={this.revertirBtn_elementAttr}
                  hint="Revertir"
                  icon="undo"
                  stylingMode="contained"
                  onClick={(e) => {
                    this.dxButton_revertirBtn_onClick(e, data);
                  }}
                />
              )}
              <CountryPicker
                onContentReady={this.dxFormEditor_onContentReady}
                className={color}
                value={value}
                updateValor_paises={this.updateValor_paises}
              />
            </Fragment>
          ) : dataFieldPhone.includes(data.dataField) ? (
            <Fragment>
              {color === "inputColor_blue" && (
                <Button
                  elementAttr={this.revertirBtn_elementAttr}
                  hint="Revertir"
                  icon="undo"
                  stylingMode="contained"
                  onClick={(e) => {
                    this.dxButton_revertirBtn_onClick(e, data);
                  }}
                />
              )}
              <ReactPhoneInput
                country="es"
                localization={es}
                preferredCountries={["es"]}
                enableSearch={true}
                placeholder=""
                searchPlaceholder={"Buscar..."}
                searchClass="search-class"
                searchStyle={{ margin: "0", width: "97%", height: "30px" }}
                disableSearchIcon={true}
                inputProps={{
                  required: true,
                  bordercolor: "red",
                }}
                value={
                  notificacionSel && notificacionSel.data
                    ? "+" +
                      (notificacionSel.data.prefijoTelefonico ? notificacionSel.data.prefijoTelefonico : "34") +
                      " " +
                      notificacionSel.data.telefono
                    : ""
                }
                onChange={this.dxFormEditor_telefono_onValueChanged}
              />
            </Fragment>
          ) : (
            <TextBox
              name={data.dataField}
              width="100%"
              stylingMode="underlined"
              value={value}
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              {color === "inputColor_blue" && (
                <TextBoxButton
                  name="revertirBtn"
                  location="before"
                  options={{
                    elementAttr: this.revertirBtn_elementAttr,
                    hint: "Revertir",
                    icon: "undo",
                    stylingMode: "contained",
                    onClick: (e) => {
                      this.dxButton_revertirBtn_onClick(e, data);
                    },
                  }}
                />
              )}
              <Validator validationGroup="dxForm_cambiosPersonal_validationGroup">
                {data.dataField != "puerta" &&
                  data.dataField != "piso" &&
                  data.dataField != "idEstadoCivil" &&
                  (isIBANSolicitado === true || (isIBANSolicitado === false && data.dataField != "IBAN")) && (
                    <RequiredRule />
                  )}
                {data.dataField == "email" && <EmailRule />}
              </Validator>
            </TextBox>
          )}
        </div>
      </div>
    );
  }
  revertirBtn_elementAttr = { class: "revertirBtn" };

  dxButton_revertirBtn_onClick(e, data) {
    let notificacionSel = { ...this.state.notificacionSel };
    let originalValue = !notificacionSel ? null : notificacionSel.data.idPersonaNavigation[data.dataField];
    originalValue = !originalValue ? null : originalValue;

    if (data.editorOptions.name === "telefono") {
      notificacionSel.data["telefono"] = notificacionSel.data.idPersonaNavigation["telefono"];
      notificacionSel.data["prefijoTelefonico"] = notificacionSel.data.idPersonaNavigation["prefijoTelefonico"];
    } else if (this.isImage(data.dataField)) {
      let name = data.editorOptions.name.replace("Navigation", "");
      if (notificacionSel.data[name]) notificacionSel.data[name] = notificacionSel.data[name].idDocumento;

      notificacionSel.data[data.editorOptions.name] = originalValue;
    } else {
      notificacionSel.data[data.editorOptions.name] = originalValue;
    }

    this.setState({ notificacionSel: notificacionSel });
  }

  dxFormEditor_onContentReady(e) {
    $(e.element).find("input").css({
      "text-align": "end",
      "font-weight": 500,
      "margin-right": 10,
    });

    setTimeout(() => {
      $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
        "font-weight": 500,
        "text-align": "start",
      });
    }, 0);
  }

  dxFormEditor_telefono_onValueChanged(value, data) {
    let prefijoTelefonico = data.dialCode;
    let telefono = value.slice(data.dialCode.length).replace(/\s/g, "");

    let notificacionSel = { ...this.state.notificacionSel };
    if (notificacionSel.data) {
      notificacionSel.data["telefono"] = telefono;
      notificacionSel.data["prefijoTelefonico"] = prefijoTelefonico;
      this.setState({ notificacionSel: notificacionSel });
    }
  }

  dxFormEditor_onValueChanged(e) {
    let dataField = e.component.option("name");
    let notificacionSel = { ...this.state.notificacionSel };

    if (notificacionSel.data) {
      notificacionSel.data[dataField] = e.value;
      this.setState({ notificacionSel: notificacionSel });
    }
  }

  dxSelectBox_displayExpr = function (item) {
    let { idioma } = this.props;
    if (!item) return null;

    if (item.idGenero || item.idNivelEstudios || item.idDiscapacidad || item.idEstadoCivil)
      return item.idTraduccionNavigation[idioma.codigo];

    return item.denominacion;
  };

  dxFormItem_readOnly_render(data) {
    let { notificacionSel, dataSources_aux } = this.state;
    let originalValue = !notificacionSel ? null : notificacionSel.data.idPersonaNavigation[data.dataField];
    originalValue = !originalValue ? null : originalValue;

    let newData = !notificacionSel ? null : notificacionSel.data[data.editorOptions.name];
    newData = !newData ? null : newData;
    let isImage = this.isImage(data.dataField);

    let formLabel = this["formLabel_" + data.dataField];
    let date = new Date();

    let itemSelectBox = [];

    switch (data.dataField) {
      case "idTallaAlfa_Camiseta":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.talla;
      case "idDiscapacidad":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.discapacidad;
      case "idNivelEstudios":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.nivelEstudios;
      case "idPais":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.pais;
      case "idGenero":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.genero;
      case "idTipoDocumentoIdentidad":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.tipoDocumentoId;
      case "idTipoDocumentoIdentidad_tutor":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.tipoDocumentoId;
      case "idEstadoCivil":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.estadoCivil;
      case "idComunidadAutonoma":
        itemSelectBox = !dataSources_aux ? [] : dataSources_aux.comunidadAutonoma;
      default:
        itemSelectBox = [];
    }

    const dataFieldSelectBox =
      data.dataField == "idTallaAlfa_Camiseta" ||
      data.dataField == "idDiscapacidad" ||
      data.dataField == "idNivelEstudios" ||
      data.dataField == "idPais" ||
      data.dataField == "idGenero" ||
      data.dataField == "idTipoDocumentoIdentidad" ||
      data.dataField == "idTipoDocumentoIdentidad_tutor" ||
      data.dataField == "idEstadoCivil" ||
      data.dataField == "idComunidadAutonoma";

    const dataFieldDateBox = data.dataField == "caducidadDocumentoIdentidad" || data.dataField == "fechaNacimiento";
    const dataFieldCountryPicker = data.dataField == "nacionalidad";
    const dataFieldPhone = data.dataField == "telefono";

    return (
      <div className="d-flex flex-row align-items-center readOnlyContainer">
        <i className={"font-size-lg align-middle pr-3 " + formLabel.icon}></i>
        <span style={{ color: "var(--secondary)", flex: 2 }} className="pr-3">
          {formLabel.text}
        </span>
        <div className="ml-auto d-flex justify-content-end" style={{ flex: 3, height: isImage ? 120 : 35 }}>
          {dataFieldSelectBox ? (
            <SelectBox
              name={data.dataField}
              readOnly={true}
              placeholder=""
              items={itemSelectBox}
              displayExpr={this.dxSelectBox_displayExpr}
              valueExpr={
                data.dataField == "idTallaAlfa_Camiseta"
                  ? "idTallaAlfa"
                  : data.dataField == "idTipoDocumentoIdentidad_tutor"
                  ? "idTipoDocumentoIdentidad"
                  : data.dataField
              }
              value={originalValue}
              stylingMode="underlined"
              showDropDownButton={false}
              onInitialized={this.dxFormEditor_readOnly_onContentReady}
            />
          ) : dataFieldDateBox ? (
            <DateBox
              displayFormat="dd/MM/yyyy"
              name={data.dataField}
              stylingMode="underlined"
              showDropDownButton={false}
              value={originalValue}
              min={data.dataField == "caducidadDocumentoIdentidad" ? date.setFullYear(date.getFullYear() - 2) : null}
              max={data.dataField == "fechaNacimiento" ? date.setFullYear(date.getFullYear() - 15) : null}
              width="100%"
              onContentReady={this.dxFormEditor_readOnly_onContentReady}
            />
          ) : isImage ? (
            <TileView
              elementAttr={data.dataField === "idFotoPerfilNavigation" ? this.circular_tileView : null}
              items={[data]}
              itemMargin={this.dxTileViewItems_size.fotos.itemMargin}
              baseItemWidth={
                data.dataField === "idFotoPerfilNavigation"
                  ? this.dxTileViewItems_size.fotoPerfil.width
                  : this.dxTileViewItems_size.fotos.width
              }
              hoverStateEnabled={true}
              itemRender={this.fotos_itemRender}
            />
          ) : dataFieldCountryPicker ? (
            <CountryPicker
              onContentReady={this.dxFormEditor_readOnly_onContentReady}
              value={originalValue}
              updateValor_paises={this.updateValor_paises}
            />
          ) : dataFieldPhone ? (
            <ReactPhoneInput
              country="es"
              localization={es}
              preferredCountries={["es"]}
              enableSearch={true}
              placeholder=""
              searchPlaceholder={"Buscar..."}
              searchClass="search-class"
              searchStyle={{ margin: "0", width: "97%", height: "30px" }}
              disableSearchIcon={true}
              inputProps={{
                required: true,
                bordercolor: "red",
              }}
              value={
                notificacionSel && notificacionSel.data.idPersonaNavigation
                  ? "+" +
                    (notificacionSel.data.idPersonaNavigation["prefijoTelefonico"]
                      ? notificacionSel.data.idPersonaNavigation["prefijoTelefonico"]
                      : "34") +
                    " " +
                    originalValue
                  : ""
              }
            />
          ) : (
            <TextBox
              readOnly={true}
              width="100%"
              stylingMode="underlined"
              value={originalValue ? originalValue.toString() : null}
              onContentReady={this.dxFormEditor_readOnly_onContentReady}
            />
          )}
        </div>
      </div>
    );
  }

  dxTile_onClick(dataField, archivo) {
    if (dataField) {
      let { notificacionSel } = this.state;
      this.setState({
        isVisible_GaleriaArchivos: true,
        dataField_archivoSel: dataField,
        archivoSel: archivo,
        archivoSel_antiguo: !notificacionSel ? null : notificacionSel.data.idPersonaNavigation[dataField],
      });
    }
  }

  fotos_itemRender(itemData) {
    let { notificacionSel } = this.state;
    let isReadOnly = itemData.component.option("readOnly");
    let value = !notificacionSel
      ? null
      : isReadOnly
      ? notificacionSel.data.idPersonaNavigation[itemData.dataField]
      : notificacionSel.data[itemData.dataField];

    let archivo = value == null ? null : value;
    let _this = this;

    if (!archivo || !archivo.documento) {
      let emptyIcon;

      switch (itemData.dataField) {
        case "idFotoPerfilNavigation":
          emptyIcon = null;
          break;
        case "idFotoDocumentoIdentidad_ANavigation":
          emptyIcon = "icon_badge_account_horizontal_outline";
          break;
        case "idFotoDocumentoIdentidad_BNavigation":
          emptyIcon = "icon_badge_account_horizontal_outline";
          break;
        case "idFotoNAFNavigation":
          emptyIcon = "icon_id_card";
          break;
        case "idFotoIBANNavigation":
          emptyIcon = "icon_credit_card_outline";
          break;
        case "idFotoDemandanteEmpleoNavigation":
          emptyIcon = "icon_card_account_details_outline";
          break;
        case "idDocCertificadoDiscapacidadNavigation":
          emptyIcon = "icon_book_account_outline";
          break;
      }

      return (
        <div
          className={emptyIcon + " he-100 align-items-center justify-content-center font-size-max d-flex pointer"}
          onClick={() => {
            this.dxTile_onClick(itemData.dataField, archivo);
          }}
        >
          {!emptyIcon && <LottieIcon height="70%" width="70%" icon={"defaultUserPhoto"} />}
        </div>
      );
    }

    const extension = archivo.extension;
    const isImage = extension.split("/")[0] === "image";
    const isPdf = extension === "application/pdf";

    return (
      <Fragment>
        {isImage && (
          <img
            className="image pointer"
            style={{
              backgroundImage: "url(data:" + archivo.extension + ";base64," + archivo.documento + ")",
            }}
            onClick={() => {
              this.dxTile_onClick(itemData.dataField, archivo);
            }}
          />
        )}
        {isPdf && (
          <div
            className="pointer align-items-center justify-content-center"
            onClick={() => {
              this.dxTile_onClick(itemData.dataField, archivo);
            }}
          >
            <LottieIcon height="45%" width="45%" icon={"pdffile"} />
          </div>
        )}
        <Toolbar elementAttr={this.template_tile_documento_elemAttr}>
          <ToolbarItem location="after" render={(e) => this.btnDownloadDocument_template(e, itemData)} />
          <ToolbarItem location="after" render={(e) => _this.btnRemoveDocument_template(e, itemData)} />
        </Toolbar>
      </Fragment>
    );
  }

  btnDownloadDocument_template(e, itemData) {
    return (
      <div className="container_spanCentrado dxToolbar_opcion mr-1">
        <div
          className="icon_Arrow_Down icon_color_hover iconos_svg"
          onClick={(e) => this.btnDownloadDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  }

  btnDownloadDocument_onClick(e, itemData) {
    let downloadLink = document.createElement("a");
    if (itemData.documento) {
      downloadLink.href = "data:" + itemData.extension + ";base64," + itemData.documento;
      downloadLink.download = itemData.nombre;
      downloadLink.click();
    } else {
      let { notificacionSel } = this.state;
      let objImagen = notificacionSel.data[itemData.dataField];
      if (objImagen) {
        downloadLink.href = "data:" + objImagen.extension + ";base64," + objImagen.documento;
        downloadLink.download = this["formLabel_" + itemData.dataField].text;
        downloadLink.click();
      }
    }
  }

  documentos_itemRender(itemData) {
    let { notificacionSel } = this.state;
    let isReadOnly = itemData.component.option("readOnly");
    let value = !notificacionSel
      ? null
      : isReadOnly
      ? notificacionSel.data.idPersonaNavigation[itemData.dataField]
      : notificacionSel.data[itemData.dataField];

    let archivo = value == null ? null : value;
    let _this = this;

    function dxTile_onClick(dataField) {
      _this.setState({
        isVisible_GaleriaArchivos: true,
        dataField_archivoSel: dataField,
        archivoSel: archivo,
        archivoSel_antiguo: !notificacionSel ? null : notificacionSel.data.idPersonaNavigation[dataField],
      });
    }

    if (!archivo || !archivo.documento)
      return (
        <div
          className="dx-icon-doc he-100 align-items-center justify-content-center font-size-max d-flex"
          onClick={() => {
            dxTile_onClick(itemData.dataField);
          }}
        />
      );

    return (
      <Fragment>
        <div
          className={"container_spanCentrado dx-icon-exportpdf font-size-max"}
          onClick={() => {
            dxTile_onClick(itemData.dataField);
          }}
        />
        <Toolbar elementAttr={this.template_tile_documento_elemAttr}>
          <ToolbarItem location="after" render={(e) => _this.btnRemoveDocument_template(e, itemData)} />
        </Toolbar>
      </Fragment>
    );
  }

  btnRemoveDocument_template(e, itemData) {
    return (
      <div className="container_spanCentrado dxToolbar_opcion" style={this.btnRemoveDocument_template_style}>
        <div
          className="icon_Cerrar icon_color_hover iconos_svg"
          style={this.btnRemoveDocument_template_style_icon}
          onClick={(e) => this.btnRemoveDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  }

  btnRemoveDocument_onClick(e, itemData) {
    let _this = this;
    let { removeFotos, notificacionSel } = this.state;
    let value = notificacionSel.data[itemData.dataField];
    dxMensajePregunta(_this.getTrad("preg_RegistroEliminarCorto"), [
      [
        _this.getTrad("aceptar"),
        function () {
          let removeItem = {
            idDocumento: value ? value.idDocumento : null,
            extension: "image/png",
            isRemove: true,
          };

          let notificacionSel = { ..._this.state.notificacionSel };

          notificacionSel.data[itemData.dataField.replace("Navigation", "")] = removeItem;
          notificacionSel.data[itemData.dataField] = null;

          let isFoto_inArray =
            removeFotos.filter(function (foto) {
              return foto.idDocumento === removeItem.idDocumento;
            }).length > 0;
          if (!isFoto_inArray) {
            removeFotos.push(removeItem);
          }
          _this.setState({
            notificacionSel: notificacionSel,
            removeFotos: removeFotos,
          });
        },
        "danger",
      ],
      [_this.getTrad("cancelar"), function () {}],
    ]);
  }

  dxPopup_galeriaFotos_aceptar_onClick(e) {
    let notificacionSel = { ...this.state.notificacionSel };
    notificacionSel.data[this.state.dataField_archivoSel] = this.state.archivoSel;

    if (this.isImage(this.state.dataField_archivoSel)) {
      let name = this.state.dataField_archivoSel.replace("Navigation", "");
      if (notificacionSel.data[name]) notificacionSel.data[name] = notificacionSel.data[name].idDocumento;
    }

    this.setState({
      notificacionSel: notificacionSel,
      isVisible_GaleriaArchivos: false,
    });
  }

  onContentReady_popup_galeriaArchivos(e) {
    e.component.content().attr("id", "dxPopup_galeriaArchivos");
  }

  onHiding_popup_galeriaArchivos(e) {
    this.setState({ isVisible_GaleriaArchivos: false });
  }
  //#endregion

  dxPopup_editActionToast_onContentReady(e) {
    e.component.content().attr("id", "dxPopup_editActionToast");
  }

  calcularEdad = (fechaNacimiento) => {
    let fecha = new Date(fechaNacimiento);
    let hoy = new Date();
    let edad = hoy.getFullYear() - fecha.getFullYear();
    let mes = hoy.getMonth() - fecha.getMonth();

    if (mes < 0 || (mes === 0 && hoy.getDate() < fecha.getDate())) {
      edad--;
    }
    return edad;
  };

  dxPopup_editActionToast_contentRender() {
    let { notificacionSel } = this.state;

    const personaNavigation = notificacionSel?.data?.idPersonaNavigation;

    const wasDiscapacidad = !!personaNavigation?.idDiscapacidad && personaNavigation?.idDiscapacidad !== 1;
    const isDiscapacidad = !!notificacionSel?.data?.idDiscapacidad && notificacionSel?.data?.idDiscapacidad !== 1;

    const wasMenorEdad =
      !!personaNavigation?.fechaNacimiento && this.calcularEdad(personaNavigation?.fechaNacimiento) < 18;
    const isMenorEdad =
      !!notificacionSel?.data?.fechaNacimiento && this.calcularEdad(notificacionSel?.data?.fechaNacimiento) < 18;

    if (notificacionSel && notificacionSel.tblTipoNotificacion.clave !== "tblDocumento") {
      return (
        <ScrollView ref={this.dxScrollView_cambiosPersonal_REF} elementAttr={this.dxScrollView_elementAttr}>
          <Row>
            <Col>
              <div className="font-size pl-2">{"Datos actuales"}</div>
              <Form
                ref={this.dxForm_cambiosPersonal_original_REF}
                formData={notificacionSel ? notificacionSel.data.idPersonaNavigation : {}}
                labelLocation="left"
                readOnly={true}
              >
                {/* NOTIFICACION / DATOS PERSONALES / FOTO,NOMBRE,APELLIDOS,TELEFONO */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem
                    dataField="idFotoPerfilNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoPerfilNavigation}
                  />
                  <SimpleItem
                    dataField="nombre"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_nombre}
                  />
                  <SimpleItem
                    dataField="apellidos"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_apellidos}
                  />
                  <SimpleItem dataField="email" render={this.dxFormItem_readOnly_render} label={this.formLabel_email} />
                  <SimpleItem
                    dataField="telefono"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_telefono}
                  />
                </GroupItem>
                {/* NOTIFICACION / DATOS LOCALIZACION / DOMICILIO,PUERTA,CP,LOCALIDAD,COMUNIDAD,PAIS */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem dataField="calle" render={this.dxFormItem_readOnly_render} label={this.formLabel_calle} />
                  <SimpleItem
                    dataField="numDomicilio"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_numDomicilio}
                  />
                  <SimpleItem dataField="piso" render={this.dxFormItem_readOnly_render} label={this.formLabel_piso} />
                  <SimpleItem
                    dataField="puerta"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_puerta}
                  />
                  <SimpleItem
                    dataField="codigoPostal"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_codigoPostal}
                  />
                  <SimpleItem
                    dataField="localidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_localidad}
                  />
                  <SimpleItem
                    dataField="idComunidadAutonoma"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idComunidadAutonoma}
                  />
                  <SimpleItem
                    dataField="idPais"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idPais}
                  />
                </GroupItem>
                {/* NOTIFICACION / DATOS NACIONALIDAD / NACIONALIDAD, TIPO ID, ID, CADUCIDAD, FOTO ID, FOTO ID B */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem
                    dataField="nacionalidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_nacionalidad}
                  />
                  <SimpleItem
                    dataField="idTipoDocumentoIdentidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idTipoDocumentoIdentidad}
                  />
                  <SimpleItem
                    dataField="numDocumentoIdentidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_numDocumentoIdentidad}
                  />
                  <SimpleItem
                    dataField="caducidadDocumentoIdentidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_caducidadDocumentoIdentidad}
                  />
                  <SimpleItem
                    dataField="idFotoDocumentoIdentidad_ANavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoDocumentoIdentidad_ANavigation}
                  />
                  <SimpleItem
                    dataField="idFotoDocumentoIdentidad_BNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoDocumentoIdentidad_BNavigation}
                  />
                </GroupItem>
                {/* NOTIFICACION / FOTO NAF */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem dataField="NAF" render={this.dxFormItem_readOnly_render} label={this.formLabel_NAF} />
                  <SimpleItem
                    dataField="idFotoNAFNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoNAFNavigation}
                  />
                </GroupItem>
                {/* NOTIFICACION / DATOS IBAN */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem dataField="IBAN" render={this.dxFormItem_readOnly_render} label={this.formLabel_IBAN} />
                  <SimpleItem
                    dataField="idFotoIBANNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoIBANNavigation}
                  />
                </GroupItem>
                {/* NOTIFICACION / DATOS COMPLEMENTARIOS / GENERO,NACIMIENTO,E.CIVIL, HIJOS, ESTUDIOS, DISCAPACIDAD, CAMISETA, PANTALON */}
                <GroupItem cssClass="ActionToast_dxForm_group pe-none">
                  <SimpleItem
                    dataField="idGenero"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idGenero}
                  />
                  <SimpleItem
                    dataField="fechaNacimiento"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_fechaNacimiento}
                  />
                  <SimpleItem
                    dataField="nombre_tutor"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_nombre_tutor}
                    visible={wasMenorEdad}
                  />
                  <SimpleItem
                    dataField="apellidos_tutor"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_apellidos_tutor}
                    visible={wasMenorEdad}
                  />
                  <SimpleItem
                    dataField="idTipoDocumentoIdentidad_tutor"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idTipoDocumentoIdentidad_tutor}
                    visible={wasMenorEdad}
                  />
                  <SimpleItem
                    dataField="numDocumentoIdentidad_tutor"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_numDocumentoIdentidad_tutor}
                    visible={wasMenorEdad}
                  />
                  <SimpleItem
                    dataField="idEstadoCivil"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idEstadoCivil}
                  />
                  <SimpleItem
                    dataField="numHijos"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_numHijos}
                  />
                  <SimpleItem
                    dataField="idNivelEstudios"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idNivelEstudios}
                  />
                  <SimpleItem
                    dataField="idDiscapacidad"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idDiscapacidad}
                  />
                  <SimpleItem
                    dataField="idFotoDemandanteEmpleoNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idFotoDemandanteEmpleoNavigation}
                    visible={wasDiscapacidad}
                  />
                  <SimpleItem
                    dataField="idDocCertificadoDiscapacidadNavigation"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idDocCertificadoDiscapacidadNavigation}
                    visible={wasDiscapacidad}
                  />
                  <SimpleItem
                    dataField="idTallaAlfa_Camiseta"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_idTallaAlfa_Camiseta}
                  />
                  <SimpleItem
                    dataField="tallaPantalon"
                    render={this.dxFormItem_readOnly_render}
                    label={this.formLabel_tallaPantalon}
                  />
                </GroupItem>
              </Form>
            </Col>
            {notificacionSel &&
              notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente" && (
                <Col>
                  <div className="font-size pl-2">{"Cambios solicitados"}</div>
                  {/* PETICION CAMBIO DATOS NUEVOS */}
                  <Form
                    ref={this.dxForm_cambiosPersonal_REF}
                    formData={notificacionSel ? notificacionSel.data : {}}
                    labelLocation="left"
                  >
                    <GroupItem>
                      {/* CAMBIODATOS / DATOS PERSONALES / FOTO,NOMBRE,APELLIDOS,TELEFONO */}
                      <GroupItem>
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem
                            dataField="idFotoPerfilNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoPerfilNavigation}
                          />
                          <SimpleItem
                            dataField="nombre"
                            render={this.dxFormItem_render}
                            label={this.formLabel_nombre}
                          />
                          <SimpleItem
                            dataField="apellidos"
                            render={this.dxFormItem_render}
                            label={this.formLabel_apellidos}
                          />
                          <SimpleItem dataField="email" render={this.dxFormItem_render} label={this.formLabel_email} />
                          <SimpleItem
                            dataField="telefono"
                            render={this.dxFormItem_render}
                            label={this.formLabel_telefono}
                          />
                        </GroupItem>
                      </GroupItem>
                      {/* CAMBIODATOS / DATOS LOCALIZACION / DOMICILIO,PUERTA,CP,LOCALIDAD,COMUNIDAD,PAIS */}
                      <GroupItem>
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem dataField="calle" render={this.dxFormItem_render} label={this.formLabel_calle} />
                          <SimpleItem
                            dataField="numDomicilio"
                            render={this.dxFormItem_render}
                            label={this.formLabel_numDomicilio}
                          ></SimpleItem>
                          <SimpleItem
                            dataField="piso"
                            render={this.dxFormItem_render}
                            label={this.formLabel_piso}
                          ></SimpleItem>
                          <SimpleItem
                            dataField="puerta"
                            render={this.dxFormItem_render}
                            label={this.formLabel_puerta}
                          />
                          <SimpleItem
                            dataField="codigoPostal"
                            render={this.dxFormItem_render}
                            label={this.formLabel_codigoPostal}
                          />
                          <SimpleItem
                            dataField="localidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_localidad}
                          />
                          <SimpleItem
                            dataField="idComunidadAutonoma"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idComunidadAutonoma}
                          />
                          <SimpleItem
                            dataField="idPais"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idPais}
                          />
                        </GroupItem>
                      </GroupItem>
                      {/* CAMBIODATOS / DATOS NACIONALIDAD / NACIONALIDAD, TIPO ID, ID, CADUCIDAD, FOTO ID, FOTO ID B */}
                      <GroupItem>
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem
                            dataField="nacionalidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_nacionalidad}
                          />
                          <SimpleItem
                            dataField="idTipoDocumentoIdentidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idTipoDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="numDocumentoIdentidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_numDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="caducidadDocumentoIdentidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_caducidadDocumentoIdentidad}
                          />
                          <SimpleItem
                            dataField="idFotoDocumentoIdentidad_ANavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoDocumentoIdentidad_ANavigation}
                          />
                          <SimpleItem
                            dataField="idFotoDocumentoIdentidad_BNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoDocumentoIdentidad_BNavigation}
                          />
                        </GroupItem>
                      </GroupItem>
                      {/* CAMBIODATOS / FOTO NAF */}
                      <GroupItem>
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem dataField="NAF" render={this.dxFormItem_render} label={this.formLabel_NAF} />
                          <SimpleItem
                            dataField="idFotoNAFNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoNAFNavigation}
                          />
                        </GroupItem>
                        {/* CAMBIODATOS / DATOS IBAN */}
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem dataField="IBAN" render={this.dxFormItem_render} label={this.formLabel_IBAN} />
                          <SimpleItem
                            dataField="idFotoIBANNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoIBANNavigation}
                          />
                        </GroupItem>
                      </GroupItem>
                      {/* CAMBIODATOS / DATOS COMPLEMENTARIOS / GENERO,NACIMIENTO,E.CIVIL, HIJOS, ESTUDIOS, DISCAPACIDAD, CAMISETA, PANTALON */}
                      <GroupItem>
                        <GroupItem cssClass="ActionToast_dxForm_group">
                          <SimpleItem
                            dataField="idGenero"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idGenero}
                          />
                          <SimpleItem
                            dataField="fechaNacimiento"
                            render={this.dxFormItem_render}
                            label={this.formLabel_fechaNacimiento}
                          />
                          <SimpleItem
                            dataField="nombre_tutor"
                            render={this.dxFormItem_render}
                            label={this.formLabel_nombre_tutor}
                            visible={isMenorEdad}
                          />
                          <SimpleItem
                            dataField="apellidos_tutor"
                            render={this.dxFormItem_render}
                            label={this.formLabel_apellidos_tutor}
                            visible={isMenorEdad}
                          />
                          <SimpleItem
                            dataField="idTipoDocumentoIdentidad_tutor"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idTipoDocumentoIdentidad_tutor}
                            visible={isMenorEdad}
                          />
                          <SimpleItem
                            dataField="numDocumentoIdentidad_tutor"
                            render={this.dxFormItem_render}
                            label={this.formLabel_numDocumentoIdentidad_tutor}
                            visible={isMenorEdad}
                          />
                          <SimpleItem
                            dataField="idEstadoCivil"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idEstadoCivil}
                          />
                          <SimpleItem
                            dataField="numHijos"
                            render={this.dxFormItem_render}
                            label={this.formLabel_numHijos}
                          />
                          <SimpleItem
                            dataField="idNivelEstudios"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idNivelEstudios}
                          />
                          <SimpleItem
                            dataField="idDiscapacidad"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idDiscapacidad}
                          />
                          <SimpleItem
                            dataField="idFotoDemandanteEmpleoNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idFotoDemandanteEmpleoNavigation}
                            visible={isDiscapacidad}
                          />
                          <SimpleItem
                            dataField="idDocCertificadoDiscapacidadNavigation"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idDocCertificadoDiscapacidadNavigation}
                            visible={isDiscapacidad}
                          />
                          <SimpleItem
                            dataField="idTallaAlfa_Camiseta"
                            render={this.dxFormItem_render}
                            label={this.formLabel_idTallaAlfa_Camiseta}
                          />
                          <SimpleItem
                            dataField="tallaPantalon"
                            render={this.dxFormItem_render}
                            label={this.formLabel_tallaPantalon}
                          />
                        </GroupItem>
                      </GroupItem>
                    </GroupItem>
                  </Form>
                </Col>
              )}
          </Row>
        </ScrollView>
      );
    } else if (notificacionSel && notificacionSel.tblTipoNotificacion.clave === "tblDocumento") {
      return (
        <Gallery
          items={
            notificacionSel
              ? ["data:" + notificacionSel.data.extension + ";base64," + notificacionSel.data.documento]
              : []
          }
          showIndicator={false}
          showNavButtons={false}
          loop={false}
          height="100%"
          width="100%"
        />
      );
    }
  }

  dxPopup_editActionToast_denegar_onClick(e) {
    if (this.dxForm_cambiosPersonal.validate().isValid) {
      let obj_idPeticionCambioDatos_estado = {
        idPeticionCambioDatos_estado: 3,
      };

      this.setState({
        isVisible_editActionToast: false,
        removeFotos: [],
        isValidating: false,
      });

      this.loadPanel_show(true);
      this.props.denegarNotificacion({
        notificacionSel: this.state.notificacionSel,
        obj_idPeticionCambioDatos_estado: obj_idPeticionCambioDatos_estado,
      });
    }
  }

  dxPopup_editActionToast_validar_onClick(e) {
    let isValid = validationEngine.validateGroup("dxForm_cambiosPersonal_validationGroup").isValid;

    let {
      idFotoDocumentoIdentidad_ANavigation,
      idFotoDocumentoIdentidad_BNavigation,
      idFotoNAFNavigation,
      idFotoPerfilNavigation,
      idFotoIBANNavigation,
      idFotoDemandanteEmpleoNavigation,
      idDocCertificadoDiscapacidadNavigation,
      idPersonaNavigation,
    } = this.state.notificacionSel.data;
    const idFotoIBAN_isValid = idPersonaNavigation.isIBANSolicitado === true ? idFotoIBANNavigation != null : true;

    const isDiscapacidad = this.state.notificacionSel.data.idDiscapacidad !== 1;
    const idFotoDemandanteEmpleo_isValid = isDiscapacidad ? !!idFotoDemandanteEmpleoNavigation : true;
    const idDocCertificadoDiscapacidad_isValid = isDiscapacidad ? !!idDocCertificadoDiscapacidadNavigation : true;

    /* Check si fotos está relleno. FotoIBAn se pide solo si la variable solicitado es true*/
    const formValid =
      isValid &&
      !!idFotoDocumentoIdentidad_ANavigation &&
      !!idFotoDocumentoIdentidad_BNavigation &&
      !!idFotoNAFNavigation &&
      !!idFotoPerfilNavigation &&
      idFotoIBAN_isValid &&
      idFotoDemandanteEmpleo_isValid &&
      idDocCertificadoDiscapacidad_isValid;

    if (formValid) {
      let notificacionSel = $.extend(true, {}, { ...this.state.notificacionSel });
      let notificacionSel_bd = $.extend(true, {}, { ...this.state.notificacionSel.data.idPersonaNavigation });
      let removeFotos = $.extend(true, [], this.state.removeFotos);
      let idPersona = notificacionSel_bd.idPersona;

      $.each([notificacionSel.data, notificacionSel_bd], function (index, item) {
        delete item.fechaPeticion;
        delete item.fechaRespuesta;
        delete item.idPeticionCambioDatos_estado;
        delete item.idPersonaNavigation;
        delete item.idPersona;
      });

      let result = {};
      $.each(notificacionSel.data, function (index, item) {
        $.each(notificacionSel_bd, function (index2, item2) {
          if (index === index2 && item != item2) {
            //misma propiedad y diferente valor
            if (item && item.extension && item.extension === "image/png") {
              if (item.documento != ((item2 != null && item2.documento) || item2 == null)) result[index] = item;
            } else {
              result[index] = item;
            }
          }
        });
      });

      result.isIBANSolicitado = true;

      let obj_idPeticionCambioDatos_estado = {
        idPeticionCambioDatos_estado: 2,
      };

      let _this = this;

      _this.setState({
        isVisible_editActionToast: false,
        removeFotos: [],
        isValidating: false,
      });
      this.loadPanel_show(true);

      this.props.validarNotificacion({
        notificacionSel: notificacionSel,
        idPersona: idPersona,
        obj_idPeticionCambioDatos_estado: obj_idPeticionCambioDatos_estado,
        result: result,
        removeFotos: removeFotos,
      });
    } else {
      this.setState({ isValidating: true });
      notify({
        message: this.getTrad("alerta_rellenarCamposObligatorios"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }
  }

  updateValor_paises(value) {
    let dataField = "nacionalidad";
    let notificacionSel = { ...this.state.notificacionSel };
    if (notificacionSel.data) {
      notificacionSel.data[dataField] = value;
      this.setState({ notificacionSel: notificacionSel });
    }
  }

  dxPopup_editActionToast_cancelar_onClick(e) {
    this.setState({
      isVisible_editActionToast: false,
      removeFotos: [],
      isValidating: false,
    });
  }

  dxButton_rotarImagen_onClick(e) {
    let _this = this;
    let archivoSel = { ...this.state.archivoSel };

    rotateBase64Image("data:image/jpeg;base64," + archivoSel.documento, 90, function (resultBase64) {
      archivoSel.documento = resultBase64.replace("data:image/png;base64,", "");
      _this.setState({ archivoSel });
    });
  }
  //#endregion

  //#region NOTIFICACIÓN
  get_descripcion_notificacion(item) {
    let { idioma } = this.props;
    let textoFijo_subtipoNotificacion = !item.tblTipoNotificacion.idTraduccionDescripcionNavigation
      ? ""
      : item.tblTipoNotificacion.idTraduccionDescripcionNavigation[idioma.codigo];

    let textoPersonalizado_notificacion;
    let nombre, apellidos;
    switch (item.tblTipoNotificacion.clave) {
      case "tblPersona_PeticionCambioDatos_pendiente":
        nombre = item.data.idPersonaNavigation.nombre ? item.data.idPersonaNavigation.nombre : "";
        apellidos = item.data.idPersonaNavigation.apellidos ? item.data.idPersonaNavigation.apellidos : "";

        textoPersonalizado_notificacion = " " + nombre + " " + apellidos;
        break;
      case "tblDocumento_requerido":
        nombre = item.data.idPersonaNavigation.nombre ? item.data.idPersonaNavigation.nombre : "";
        apellidos = item.data.idPersonaNavigation.apellidos ? item.data.idPersonaNavigation.apellidos : "";

        textoFijo_subtipoNotificacion =
          item.data.denominacion + (item.data.extension ? item.data.extension : "") + " - ";
        textoPersonalizado_notificacion = nombre + " " + apellidos;
        break;
      case "tblDocumento_firmado":
        nombre = item.data.idPersonaNavigation.nombre ? item.data.idPersonaNavigation.nombre : "";
        apellidos = item.data.idPersonaNavigation.apellidos ? item.data.idPersonaNavigation.apellidos : "";

        textoFijo_subtipoNotificacion = item.data.denominacion + " - ";
        textoPersonalizado_notificacion = nombre + " " + apellidos;
        break;
      default:
        textoPersonalizado_notificacion = "";
        break;
    }

    return (
      <Fragment>
        <span>{textoFijo_subtipoNotificacion}</span>
        <span className="font-weight-bold">{textoPersonalizado_notificacion}</span>
      </Fragment>
    );
  }

  actionToast_onClick(index, item) {
    this.setState({
      isVisible_editActionToast: true,
      notificacionSel: $.extend(true, {}, item),
      notificacionSel_data: $.extend(true, {}, item.data),
    });
  }
  //#endregion
  //#endregion

  dxPopup_editActionToast_onShowing(e) {
    let { notificacionSel } = this.state;
    if (this.dxScrollView_cambiosPersonal_REF.current !== null) {
      setTimeout(() => {
        this.dxScrollView_cambiosPersonal.scrollTo(0);
      }, 0);
    }
  }

  dxPopup_editActionToast_onShown(e) {
    let { notificacionSel } = this.state;
    if (notificacionSel.tblTipoNotificacion.clave === "tblPersona_PeticionCambioDatos_pendiente") {
      this.props.cargarImagenes_tblPeticionCambioDatos(notificacionSel.data.idPeticionCambioDatos);
    }
  }

  dxPopup_editActionToast_onHiding(e) {
    this.setState({
      isVisible_editActionToast: false,
      removeFotos: [],
      isValidating: false,
    });
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidUpdate(prevProps, prevState) {
    let { notificacionSel } = this.state;
    let { data, direction, dataSources_aux, idFiltro, tipo, notificacionSel_imagenes } = this.props;
    let dataFiltrada = null;
    if (idFiltro != null) {
      dataFiltrada = $.grep(data, function (item) {
        return idFiltro === item.tblTipoNotificacion.idTipoNotificacion;
      });
    }

    if (notificacionSel_imagenes !== prevProps.notificacionSel_imagenes && notificacionSel != null) {
      let dataField_updatear = [
        "idFotoDocumentoIdentidad_ANavigation",
        "idFotoDocumentoIdentidad_BNavigation",
        "idFotoIBANNavigation",
        "idFotoNAFNavigation",
        "idFotoPerfilNavigation",
        "idFotoDocumentoIdentidad_A",
        "idFotoDocumentoIdentidad_B",
        "idFotoIBAN",
        "idFotoNAF",
        "idFotoPerfil",
        "idFotoDemandanteEmpleoNavigation",
        "idDocCertificadoDiscapacidadNavigation",
      ];

      $.each(dataField_updatear, function (index, item) {
        notificacionSel.data[item] = notificacionSel_imagenes[item];
        notificacionSel.data.idPersonaNavigation[item] = notificacionSel_imagenes.idPersonaNavigation[item];
      });
    }

    if (
      data !== prevProps.data ||
      idFiltro !== prevProps.idFiltro ||
      notificacionSel_imagenes !== prevProps.notificacionSel_imagenes
    ) {
      this.setState({
        data: dataFiltrada != null ? dataFiltrada : data,
        direction: direction,
        tipo: tipo,
        dataSources_aux: dataSources_aux,
        notificacionSel: notificacionSel,
      });
    }
  }

  componentDidMount() {
    let { data, direction, dataSources_aux, tipo } = this.props;
    this.setState({
      data: data,
      direction: direction,
      tipo: tipo,
      dataSources_aux: dataSources_aux,
    });
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
  lavanderia: state.Global.lavanderia,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionToast);
