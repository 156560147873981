import React from "react";

import { getTrad } from "helpers";
import { ControlPresupuestarioCxt } from "../../../contexts/context";

import ButtonGroupCustom from "components/ButtonGroupCustom";

import "./style.scss";

class SelectorVista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    vistas = [
        {
            id: 1,
            denominacion: getTrad("mensual"),
            icon: "icon_CalendarioMes",
            color: "--secondary",
        },
        {
            id: 2,
            denominacion: getTrad("acumulado"),
            icon: "icon_CalendarioAyer",
            color: "--secondary",
        },
    ];

    render() {
        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ vista, setVista }) => {
                    return (
                        <div className="buttonGroupWrapper">
                            <ButtonGroupCustom
                                idSel={vista}
                                items={this.vistas}
                                dxButtonGroup_onValueChanged={setVista}
                            />
                        </div>
                    );
                }}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

export default SelectorVista;
