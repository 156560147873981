import React from "react";

import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
    authHeader,
    capitalize,
    endOfMonth,
    errorHandler,
    formatDate_noTime_parameter,
    formatNumber,
    getTrad,
    patchRequestHandler,
} from "helpers";
import { displayExpr_CodigoDenominacion } from "pages/Finanzas/ControlPresupuestarioV2/shared/functions";

import CellCheck from "./CellCheck";
import ObservacionesCell from "../../../../../../../components/DataGrid/Cells/ObservacionesCell";
import CurrencyFormatterCell from "../../../CurrencyFormatterCell";
import { removeCellComponent } from "components/DataGrid/Cells";

import { Button, DateBox, SelectBox, TextBox } from "devextreme-react";
import DataGrid, { Column, Editing, LoadPanel, Paging, Sorting, Summary, TotalItem } from "devextreme-react/data-grid";
import Box, { Item } from "devextreme-react/box";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import Validator, { RequiredRule } from "devextreme-react/validator";
import validationEngine from "devextreme/ui/validation_engine";

class PopupAjustes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            centroSel: null,
            tblAdmCuentaContable: [],
            idAdmCuentaContableSel: null,
        };

        this.dxDataGrid_REF = React.createRef();
        this.dxButton_guardar_REF = React.createRef();
        this.dxValidator_REF = React.createRef();
    }

    get DataGrid() {
        return this.dxDataGrid_REF.current?.instance;
    }

    get ButtonGuardar() {
        return this.dxButton_guardar_REF.current?.instance;
    }

    get Validator() {
        return this.dxValidator_REF.current?.instance;
    }

    boxGap = { gap: 10 };

    inputAttributes = { class: "text-uppercase" };

    // #region Lifecycle

    meses = [];
    componentDidMount() {
        const { fecha } = this.props;
        for (let monthIndex = 0; monthIndex < 11; monthIndex++) {
            this.meses.push(endOfMonth(new Date(fecha.getFullYear(), monthIndex, 1)));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { centroSel, idAdmCuentaContableSel } = this.state;
        const { centroSel: prevCentroSel, idAdmCuentaContableSel: prevIdAdmCuentaContableSel } = prevState;
        if (
            this.props.visible &&
            (centroSel !== prevCentroSel || idAdmCuentaContableSel !== prevIdAdmCuentaContableSel)
        ) {
            if (centroSel != null) {
                this.datasource_tblAjustePresupuestario.reload();
            }
        }

        if (
            idAdmCuentaContableSel &&
            (this.props.moneda.idMoneda !== prevProps.moneda.idMoneda || this.props.tasaDivisa !== prevProps.tasaDivisa)
        ) {
            this.datasource_tblAjustePresupuestario.reload();
        }

        if (this.props.visible && !prevProps.visible) {
            if (this.ButtonGuardar?.option("disabled")) {
                this.ButtonGuardar?.option("disabled", false);
            }
        }

        if (
            JSON.stringify(this.props.tblAdmCuentaContable) !== JSON.stringify(prevProps.tblAdmCuentaContable) ||
            JSON.stringify(this.props.dataSource) !== JSON.stringify(prevProps.dataSource) ||
            (this.state.tblAdmCuentaContable.length === 0 && this.props.dataSource.length > 0)
        ) {
            let tblAdmCuentaContable = this.props.tblAdmCuentaContable.map((item) => {
                item.visible = !this.props.dataSource.some((x) => x.idAdmCuentaContable === item.idAdmCuentaContable);
                item.disabled = !item.visible;
                return item;
            });
            this.setState({ tblAdmCuentaContable: tblAdmCuentaContable });
        }
    }

    // #endregion

    // #region DataSource

    datasource_tblAjustePresupuestario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAjustePresupuestario",
            key: "idAjustePresupuestario",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblAjustePresupuestario_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblAjustePresupuestario_onLoading(loadOptions),
            onLoaded: (items) => this.datasource_tblAjustePresupuestario_onLoaded(items),
            onInserting: (item) => this.datasource_tblAjustePresupuestario_onInserting(item),
            version: 4,
        }),
        expand: ["tblMesNAjustePresupuestario"],
    });

    datasource_tblAjustePresupuestario_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblAjustePresupuestario_onLoading = (loadOptions) => {
        const { centroSel, idAdmCuentaContableSel } = this.state;

        loadOptions.filter = [
            ["idAdmCentroCoste", "=", centroSel?.idAdmCentroCoste ?? null],
            ["idAdmElementoPEP", "=", centroSel?.idAdmElementoPEP ?? null],
            ["idAdmCuentaContable", "=", idAdmCuentaContableSel ?? null],
        ];
    };

    datasource_tblAjustePresupuestario_onLoaded = (items) => {
        const { moneda, intercambiarDivisa } = this.props;
        items.forEach((item) => {
            item.valor = intercambiarDivisa(item);
            item.idMoneda = moneda.idMoneda;

            item.tblMesNAjustePresupuestario = this.meses.reduce((obj, mes, index) => {
                const mesItem = item.tblMesNAjustePresupuestario.find(
                    (x) => x.fecha === formatDate_noTime_parameter(mes)
                );
                obj[`m-${index}`] = {
                    fecha: mes,
                    idAjustePresupuestario: mesItem?.idAjustePresupuestario || null,
                    activo: mesItem?.idAjustePresupuestario != null,
                };
                return obj;
            }, {});
        });
    };

    datasource_tblAjustePresupuestario_onInserting = (item) => {
        if (item.fecha) {
            item.fecha = formatDate_noTime_parameter(item.fecha);
        }

        if (item.tblMesNAjustePresupuestario) {
            item.tblMesNAjustePresupuestario = Object.values(item.tblMesNAjustePresupuestario).reduce((arr, item) => {
                if (item.activo) {
                    let x = { fecha: formatDate_noTime_parameter(item.fecha) };
                    if (item.idAjustePresupuestario != null) x.idAjustePresupuestario = item.idAjustePresupuestario;
                    arr.push(x);
                }
                return arr;
            }, []);
        }
    };

    // #endregion

    render() {
        const { visible, onHiding, rowData } = this.props;
        const { centros, moneda, fecha } = this.props;
        const { centroSel, tblAdmCuentaContable, idAdmCuentaContableSel } = this.state;
        return (
            <Popup
                title={getTrad("ajustes")}
                visible={visible}
                onHiding={onHiding}
                onHidden={this.onHidden}
                onShowing={this.onShowing}
            >
                <div className="position-relative he-100">
                    <Box className="he-100" direction="col" style={this.boxGap}>
                        <Item baseSize={"auto"}>
                            <Box style={this.boxGap}>
                                <Item ratio={1}>
                                    <span className="mb-1 font-weight-semiBold text-secondary">
                                        {getTrad("centroCoste")} / {getTrad("elementoPep")}
                                    </span>
                                    {centros.length > 1 ? (
                                        <SelectBox
                                            value={centroSel}
                                            items={centros}
                                            displayExpr={displayExpr_CodigoDenominacion}
                                            inputAttr={this.inputAttributes}
                                            onValueChanged={this.dxSelectBox_centroSel_onValueChanged}
                                        >
                                            <Validator validationGroup="ajustePresupuestario">
                                                <RequiredRule />
                                            </Validator>
                                        </SelectBox>
                                    ) : (
                                        <TextBox
                                            value={centros.at(0)?.denominacion}
                                            readOnly
                                            inputAttr={this.inputAttributes}
                                        >
                                            <Validator validationGroup="ajustePresupuestario">
                                                <RequiredRule />
                                            </Validator>
                                        </TextBox>
                                    )}
                                </Item>
                                <Item ratio={1}>
                                    <span className="mb-1 font-weight-semiBold text-secondary">
                                        {getTrad("cuentaContable")}
                                    </span>
                                    <SelectBox
                                        value={idAdmCuentaContableSel}
                                        dataSource={tblAdmCuentaContable}
                                        valueExpr={"idAdmCuentaContable"}
                                        displayExpr={displayExpr_CodigoDenominacion}
                                        readOnly={rowData != null}
                                        inputAttr={this.inputAttributes}
                                        searchEnabled={true}
                                        onValueChanged={this.dxSelectBox_idAdmCuentaContable_onValueChanged}
                                    >
                                        <Validator ref={this.dxValidator_REF} validationGroup="ajustePresupuestario">
                                            <RequiredRule />
                                        </Validator>
                                    </SelectBox>
                                </Item>
                                <Item ratio={1}>
                                    <span className="mb-1 font-weight-semiBold text-secondary">
                                        {getTrad("moneda")}
                                    </span>
                                    <TextBox value={moneda?.denominacion} readOnly inputAttr={this.inputAttributes}>
                                        <Validator validationGroup="ajustePresupuestario">
                                            <RequiredRule />
                                        </Validator>
                                    </TextBox>
                                </Item>
                                <Item ratio={1}>
                                    <span className="mb-1 font-weight-semiBold text-secondary">{getTrad("fecha")}</span>
                                    <DateBox
                                        value={fecha}
                                        displayFormat={"MMMM yyyy"}
                                        readOnly
                                        inputAttr={this.inputAttributes}
                                    >
                                        <Validator validationGroup="ajustePresupuestario">
                                            <RequiredRule />
                                        </Validator>
                                    </DateBox>
                                </Item>
                            </Box>
                        </Item>
                        <Item baseSize={"auto"}>
                            <div className="d-flex flex-row justify-content-between">
                                <div>
                                    <Button
                                        icon="plus"
                                        text={getTrad("crearAjuste")}
                                        type="success"
                                        disabled={centroSel == null}
                                        onClick={this.crearAjuste}
                                    />
                                </div>
                                <div>
                                    <Button icon="revert" onClick={this.reiniciarCambios} />
                                </div>
                            </div>
                        </Item>
                        <Item ratio={1}>
                            <DataGrid
                                ref={this.dxDataGrid_REF}
                                dataSource={this.datasource_tblAjustePresupuestario}
                                className="he-100"
                                showColumnLines={false}
                                showRowLines={true}
                                repaintChangesOnly={true}
                                onToolbarPreparing={this.onToolbarPreparing}
                                onInitNewRow={this.onInitNewRow}
                                onRowUpdating={this.onRowUpdating}
                            >
                                <LoadPanel enabled={false} />
                                <Paging enabled={false} />
                                <Editing mode="batch" allowUpdating={true} allowAdding={true} allowDeleting={false} />
                                <Sorting mode={"none"} />

                                <Column
                                    dataField="fecha"
                                    caption={getTrad("fecha")}
                                    cssClass={"text-capitalize"}
                                    dataType={"date"}
                                    format={"MMMM yyyy"}
                                    fixed={true}
                                    width={125}
                                    allowEditing={false}
                                    alignment={"center"}
                                    sortIndex={0}
                                    sortOrder={"desc"}
                                >
                                    <RequiredRule />
                                </Column>
                                <Column
                                    dataField="valor"
                                    caption={getTrad("valor")}
                                    cssClass={"border-right border-left"}
                                    dataType={"number"}
                                    fixed={true}
                                    width={150}
                                    allowUpdating={false}
                                    cellComponent={CurrencyFormatterCell}
                                >
                                    <RequiredRule />
                                </Column>
                                <Column
                                    dataField={"idMoneda"}
                                    dataType={"number"}
                                    caption={getTrad("moneda")}
                                    visible={false}
                                    allowUpdating={false}
                                />
                                <Column
                                    dataField="observaciones"
                                    caption={getTrad("observaciones")}
                                    fixed={true}
                                    minWidth={200}
                                    cellComponent={ObservacionesCell}
                                />
                                {this.meses.map((mes, index) => (
                                    <Column
                                        key={`m-${index + 1}`}
                                        dataField={`tblMesNAjustePresupuestario.m-${index}`}
                                        caption={`${capitalize(
                                            mes.toLocaleDateString(undefined, { month: "short" }),
                                            true
                                        )}.`}
                                        cssClass={"p-0 position-relative month-selector"}
                                        dataType={"boolean"}
                                        width={75}
                                        cellComponent={CellCheck}
                                        allowEditing={false}
                                        allowSorting={false}
                                    />
                                ))}
                                <Column
                                    caption={" "}
                                    width={30}
                                    alignment={"center"}
                                    cssClass={"p-0"}
                                    cellComponent={removeCellComponent}
                                />
                                <Summary>
                                    <TotalItem column={"valor"} summaryType={"sum"} displayFormat={this.valor_format} />
                                </Summary>
                            </DataGrid>
                        </Item>
                    </Box>
                </div>
                <ToolbarItem location={"after"} toolbar={"bottom"}>
                    <Button text={getTrad("cancelar")} onClick={this.cancelarCambios} />
                </ToolbarItem>
                <ToolbarItem location={"after"} toolbar={"bottom"}>
                    <Button
                        ref={this.dxButton_guardar_REF}
                        text={getTrad("guardar")}
                        type="success"
                        onClick={this.guardarCambios}
                    />
                </ToolbarItem>
            </Popup>
        );
    }

    // #region Popup

    crearAjuste = () => {
        let validation = validationEngine.validateGroup("ajustePresupuestario");

        if (validation.isValid) {
            this.DataGrid.addRow();
        }
    };

    reiniciarCambios = () => {
        this.DataGrid.cancelEditData();
    };

    cancelarCambios = () => {
        this.DataGrid.cancelEditData();
        this.props.onHiding();
    };

    guardarCambios = (e) => {
        let validation = validationEngine.validateGroup("ajustePresupuestario");

        if (validation.isValid) {
            e.component.option("disabled", true);
            this.DataGrid.saveEditData()
                .then(() => {
                    if (!this.DataGrid.hasEditData()) {
                        this.props.onSaving();
                    } else {
                        e.component.option("disabled", false);
                    }
                })
                .catch(() => e.component.option("disabled", false));
        }
    };

    onShowing = () => {
        let { centroSel } = this.state;
        let idAdmCuentaContableSel;
        if (this.props.centros.length === 1) {
            centroSel = this.props.centros.at(0);
        }
        if (this.props.rowData != null) {
            idAdmCuentaContableSel = this.props.rowData.idAdmCuentaContable;
        } else {
            this.Validator.reset();
        }
        this.setState({ centroSel, idAdmCuentaContableSel });
    };

    onHidden = () => {
        if (this.props.rowData == null) {
            this.Validator.reset();
        }
    };

    // #endregion

    // #region DataGrid

    onToolbarPreparing = (e) => {
        e.toolbarOptions.visible = false;
        e.toolbarOptions.items = e.toolbarOptions.items.map((item) => {
            if (item.name === "addRowButton") {
                item.location = "before";
                item.showText = "always";
                item.options.text = getTrad("crearAjuste");
                item.options.type = "success";
            }
            if (item.name === "saveButton") {
                item.options.visible = false;
            }
            return item;
        });
    };

    onInitNewRow = (e) => {
        const { fecha, moneda } = this.props;
        const { centroSel, idAdmCuentaContableSel } = this.state;
        e.data.idMoneda = moneda.idMoneda;
        e.data.idAdmCentroCoste = centroSel.idAdmCentroCoste;
        e.data.idAdmElementoPEP = centroSel.idAdmElementoPEP;
        e.data.idAdmCuentaContable = idAdmCuentaContableSel;

        e.data.fecha = endOfMonth(fecha);
        e.data.tblMesNAjustePresupuestario = {};

        this.meses.forEach((mes, index) => {
            e.data.tblMesNAjustePresupuestario[`m-${index}`] = {
                fecha: mes,
                idAjustePresupuestario: null,
                activo: mes.getDate() === e.data.fecha.getDate() && mes.getMonth() === e.data.fecha.getMonth(),
            };
        });
    };

    onRowUpdating = (e) => {
        e.newData.idMoneda = this.props.moneda.idMoneda;

        if (e.newData.tblMesNAjustePresupuestario) {
            e.newData.tblMesNAjustePresupuestario = Object.values({
                ...e.oldData.tblMesNAjustePresupuestario,
                ...e.newData.tblMesNAjustePresupuestario,
            }).reduce((arr, item) => {
                if (item.activo) {
                    let x = { fecha: formatDate_noTime_parameter(item.fecha) };
                    if (item.idAjustePresupuestario != null) x.idAjustePresupuestario = item.idAjustePresupuestario;
                    arr.push(x);
                }
                return arr;
            }, []);
        }
        e.newData = patchRequestHandler(e.newData);
    };

    valor_format = (e) => {
        const { moneda } = this.props;
        return formatNumber(e, 2, "currency", moneda.codigo ?? "EUR");
    };

    // #endregion

    // #region SelectBox

    dxSelectBox_centroSel_onValueChanged = (e) => {
        this.setState({ centroSel: e.value });
    };

    dxSelectBox_idAdmCuentaContable_onValueChanged = (e) => {
        this.setState({ idAdmCuentaContableSel: e.value });
    };

    // #endregion
}

export default PopupAjustes;
