import LottieIcon from "components/LottieIcon";
import React from "react";

class Imagen extends React.Component {
    constructor(props) {
        super(props);
    }

    objectFit = { objectFit: "contain" };

    render() {
        const { value, loading = false } = this.props;
        return value ? (
            <img src={"data:image/jpeg;base64," + value} className="w-100 he-100" style={this.objectFit} />
        ) : (
            <div className="w-100 he-100 p-4">
                <LottieIcon icon={loading ? "loading_circular" : "noImage"} isLoop={loading} />
            </div>
        );
    }
}

export default Imagen;
