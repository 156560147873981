import React from "react";

import { connectionConstants } from "../../../../constants";
import { errorHandler, authHeader, getTrad, formatNumber, formatDate_parameter } from "../../../../helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  FilterRow,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import { progressBarCellComponent } from "../../../../components/DataGrid/Cells";
import { Tooltip } from "devextreme-react";

const GET_COLOR_CUMPLIMIENTO = (cumplimiento) => {
  if (cumplimiento >= 0.9) return "success";
  else if (cumplimiento > 0.5) return "orange";
  else return "danger";
};

export default class GridClasificacionSucio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data_clasificacionSucio: [],
    };

    this.grid_REF = React.createRef();

    this.datasource_clasificacionSucio_postProcess = this.datasource_clasificacionSucio_postProcess.bind(this);

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.cellRender_sacas = this.cellRender_sacas.bind(this);
  }

  get grid() {
    return this.grid_REF.current.instance;
  }

  datasource_clasificacionSucio = new DataSource({
    paginate: false,
    store: new ODataStore({
      key: "id",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/ControlCalidad/ClasificacionSucio/" + this.props.group,
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => this.datasource_clasificacionSucio_postProcess(data),
  });

  datasource_clasificacionSucio_postProcess(data) {
    data.forEach((item) => {
      item.progressBarCellOptions_cumplimiento = {
        color: GET_COLOR_CUMPLIMIENTO(item.cumplimiento),
      };
    });

    this.setState({ data_clasificacionSucio: data });
    return data;
  }

  datasource_beforeSend(request) {
    let { filtros } = this.props;
    let { idLavanderia, fechaIni, fechaFin, idCompañia, idEntidad } = filtros;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderia;
    request.params.idCompañia = idCompañia;
    request.params.idEntidad = idEntidad;
    request.params.fechaIni = formatDate_parameter(fechaIni);
    request.params.fechaFin = formatDate_parameter(fechaFin);
  }

  render() {
    let { selectedRowKey, group } = this.props;

    return (
      <>
        <DataGrid
          ref={this.grid_REF}
          dataSource={this.datasource_clasificacionSucio}
          elementAttr={
            group === "compañia"
              ? this.elementAttr_compa
              : group === "entidad"
              ? this.elementAttr_enti
              : this.elementAttr_detallado
          }
          remoteOperations={false}
          height="100%"
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={group !== "detallado"}
          selectedRowKeys={selectedRowKey != null ? [selectedRowKey] : undefined}
          onContentReady={this.onContentReady}
          onCellPrepared={this.onCellPrepared}
          onRowPrepared={this.onRowPrepared}
          onToolbarPreparing={this.props.title ? this.onToolbarPreparing : undefined}
          onEditorPreparing={this.onEditorPreparing}
          onSelectionChanged={this.props.onSelectionChanged ? this.onSelectionChanged : undefined}
        >
          <Paging enabled={false} />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <Selection mode={this.props.onSelectionChanged ? "single" : undefined} />
          <Column
            dataField="fecha"
            dataType="date"
            format={"dd/MM/yyyy"}
            caption={getTrad("fecha")}
            width={145}
            visible={group === "detallado"}
            allowHeaderFiltering={false}
            sortOrder={group === "detallado" ? "asc" : null}
          />
          <Column
            dataField="denominacion"
            dataType="string"
            caption={getTrad("denominacion")}
            minWidth={250}
            allowHeaderFiltering={group !== "detallado"}
            visible={group !== "detallado"}
            sortOrder={group === "detallado" ? null : "asc"}
          />
          <Column
            dataField="carrosContados"
            dataType="number"
            caption={getTrad("carros")}
            alignment={"center"}
            defaultSelectedFilterOperation=">="
            allowSearch={false}
            allowHeaderFiltering={false}
            cellRender={this.cellRender_carros}
          />
          <Column
            dataField="cumplimientoCarros"
            caption={getTrad("cumplimientoCarros")}
            dataType="number"
            alignment="center"
            filterOperations={["=", "<>", "<", ">", "<=", ">="]}
            defaultSelectedFilterOperation=">="
            defaultFilterValue={0}
            cssClass="p-0"
            allowSearch={false}
            allowHeaderFiltering={false}
            allowFiltering={true}
            allowSorting={true}
            minWidth={150}
            cellComponent={progressBarCellComponent}
          />
          <Column
            dataField="sacasContadas"
            dataType="number"
            caption={getTrad("sacas")}
            alignment={"center"}
            defaultSelectedFilterOperation=">="
            allowSearch={false}
            allowHeaderFiltering={false}
            cellRender={this.cellRender_sacas}
          />
          <Column
            dataField="cumplimientoSacas"
            caption={getTrad("cumplimientoSacas")}
            dataType="number"
            alignment="center"
            filterOperations={["=", "<>", "<", ">", "<=", ">="]}
            defaultSelectedFilterOperation=">="
            defaultFilterValue={0}
            cssClass="p-0"
            allowSearch={false}
            allowHeaderFiltering={false}
            allowFiltering={true}
            allowSorting={true}
            minWidth={150}
            cellComponent={progressBarCellComponent}
          />
          <Summary calculateCustomSummary={this.calculateCustomSummary}>
            <TotalItem
              column={group === "detallado" ? "fecha" : "denominacion"}
              displayFormat={getTrad("total").toUpperCase()}
            />
            <TotalItem column="carrosContados" name="carrosContados" summaryType="sum" displayFormat="{0}" />
            <TotalItem
              name="cumplimientoCarros"
              showInColumn={"cumplimientoCarros"}
              summaryType="custom"
              displayFormat="{0}"
              customizeText={this.customizeText_sum_cumplimiento}
            />
            <TotalItem column="sacasContadas" name="sacasContadas" summaryType="sum" displayFormat="{0}" />
            <TotalItem
              name="cumplimientoSacas"
              showInColumn={"cumplimientoSacas"}
              summaryType="custom"
              displayFormat="{0}"
              customizeText={this.customizeText_sum_cumplimiento}
            />
          </Summary>
        </DataGrid>
      </>
    );
  }

  elementAttr_compa = {
    id: "dxDataGrid_compa",
  };

  elementAttr_enti = {
    id: "dxDataGrid_enti",
  };

  elementAttr_detallado = {
    id: "dxDataGrid_detallado",
  };

  cellRender_sacas(e) {
    let rowData = e.data;
    let { group } = this.props;
    let fecha = rowData.fecha ? rowData.fecha.slice(0, 10) : "";

    const datoTooltip = {
      sacasCorrectas: rowData.sacasCorrectas,
      sacasErroneas: rowData.sacasErroneas,
    };

    return (
      <>
        <div id={"id_" + group + "_" + rowData.id + "_" + fecha + "sacas"}>{rowData.sacasContadas}</div>
        {rowData.sacasCorrectas > 0 || rowData.sacasErroneas > 0 ? (
          <Tooltip
            target={"#id_" + group + "_" + rowData.id + "_" + fecha + "sacas"}
            position="bottom"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            {this.renderTooltip(datoTooltip)}
          </Tooltip>
        ) : null}
      </>
    );
  }

  cellRender_carros = (e) => {
    let rowData = e.data;
    let { group } = this.props;
    let fecha = rowData.fecha ? rowData.fecha.slice(0, 10) : "";

    const datoTooltip = {
      carrosCorrectos: rowData.carrosCorrectos,
      carrosErroneos: rowData.carrosErroneos,
    };

    return (
      <>
        <div id={"id_" + group + "_" + rowData.id + "_" + fecha + "carros"}>{rowData.carrosContados}</div>
        {rowData.carrosCorrectos > 0 || rowData.carrosErroneos > 0 ? (
          <Tooltip
            target={"#id_" + group + "_" + rowData.id + "_" + fecha + "carros"}
            position="bottom"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            {this.renderTooltip(datoTooltip)}
          </Tooltip>
        ) : null}
      </>
    );
  };

  renderTooltip(data) {
    return (
      <div
        className="d-flex"
        style={{
          minWidth: 160,
          fontWeight: 500,
          fontSize: 12,
          color: "rgba(94, 94, 94, 0.7)",
        }}
      >
        <div className="d-flex flex-column w-100">
          {(data.carrosCorrectos != undefined || data.carrosErroneos != undefined) && (
            <>
              <div className="p-2" style={{ fontWeight: 800 }}>
                Datos de carros
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: 800 }}>Correctos:</div>
                <div className="ml-auto"> {data?.carrosCorrectos || 0}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: 800 }}>Mezclados:</div>
                <div className="ml-auto"> {data?.carrosErroneos || 0}</div>
              </div>
            </>
          )}
          {(data.sacasCorrectas != undefined || data.sacasErroneas != undefined) && (
            <>
              <div className="p-2" style={{ fontWeight: 800 }}>
                Datos de sacas
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: 800 }}>Correctas:</div>
                <div className="ml-auto"> {data?.sacasCorrectas || 0}</div>
              </div>
              <div className="d-flex flex-row">
                <div style={{ fontWeight: 800 }}>Mezcladas:</div>
                <div className="ml-auto"> {data?.sacasErroneas || 0}</div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  onContentReady(e) {
    e.element.find(".dx-freespace-row").css("height", 0);
  }

  onCellPrepared(e) {
    if (e.rowType === "totalFooter" && e.summaryItems[0]) {
      if (e.summaryItems[0].column === "sacasErroneas") {
        e.cellElement.addClass("sacasErroneasTotal");
      }
    }
  }

  onRowPrepared(e) {
    let { group } = this.props;
    if (e.rowType === "data" && group !== "detallado") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      text: this.props.title,
    });
  }

  sliderText = null;
  onEditorPreparing(e) {
    let _this = this;
    if (e.parentType === "filterRow") {
      if (e.dataField === "cumplimiento") {
        e.editorName = "dxSlider";
        e.editorOptions = {
          min: 0,
          max: 1,
          value: e.value,
          step: 0.05,
          width: "100%",
          onValueChanged: function (data) {
            e.setValue(data.value);
            let textValue = formatNumber(data.value, 0, "percent");
            _this.sliderText.text(textValue);
          },
          onInitialized: function (e) {
            let value = e.component.option("value");
            let textValue = formatNumber(value, 0, "percent");
            _this.sliderText = $("<div />").addClass("slider-textValue").text(textValue);
            e.element.append(_this.sliderText);
          },
        };
      }
    }
  }

  calculateCustomSummary(options) {
    if (options.name === "cumplimientoCarros") {
      switch (options.summaryProcess) {
        case "start":
          options.carrosContados = 0;
          options.carrosCorrectos = 0;
          break;
        case "calculate":
          options.carrosContados += options.value.carrosContados;
          options.carrosCorrectos += options.value.carrosCorrectos;
          break;
        case "finalize":
          options.totalValue = options.carrosCorrectos / options.carrosContados;
          options.totalValue = options.totalValue ? options.totalValue : 0;
          break;
      }
    } else if (options.name === "cumplimientoSacas") {
      switch (options.summaryProcess) {
        case "start":
          options.sacasContadas = 0;
          options.sacasCorrectas = 0;
          break;
        case "calculate":
          options.sacasContadas += options.value.sacasContadas;
          options.sacasCorrectas += options.value.sacasCorrectas;
          break;
        case "finalize":
          options.totalValue = options.sacasCorrectas / options.sacasContadas;
          options.totalValue = options.totalValue ? options.totalValue : 0;
          break;
      }
    }
  }

  customizeText_sum_cumplimiento(itemInfo) {
    return formatNumber(itemInfo.value, 2, "percent");
  }

  onSelectionChanged(e) {
    if (e.selectedRowKeys.length > 0) {
      let key = e.selectedRowKeys[0].split("_");
      let { denominacion } = e.selectedRowsData[0];
      this.props.onSelectionChanged({
        [key[0]]: parseInt(key[1]),
        denominacion: denominacion,
      });
    } else {
      this.props.onSelectionChanged({});
    }
  }

  componentDidUpdate(prevProps) {
    let { title, filtros } = this.props;
    if (JSON.stringify(prevProps.filtros) !== JSON.stringify(filtros)) {
      this.datasource_clasificacionSucio.reload();
      this.grid.clearFilter();
    }

    if (prevProps.title !== title) {
      this.grid.repaint();
    }
  }
}
