import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getTrad } from "../../../helpers";

import $ from "jquery";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { Popup } from "devextreme-react/popup";

import GridRechazoCliente from "./components/GridRechazoCliente";
import DateRangePicker from "components/DateRangePicker/DateRangePicker";
import PageTitle from "../../../layout/AppMain/PageTitle";
import { Button, ButtonGroup } from "devextreme-react";
import Toolbar, { Item } from "devextreme-react/toolbar";

let default_filter = {
  idFamilia: null,
  idTipoPrenda: null,
  idCompañia: null,
  idEntidad: null,
  idMaquina: null,
};

class InfRechazoCliente extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fechaIni: new Date(),
      fechaFin: new Date(),
      filtro_1: {},
      filtro_2: {},
      group: "prenda",
    };

    this.get_dateRangePicker = this.get_dateRangePicker.bind(this);
    this.dateRangePicker_onDateSelected =
      this.dateRangePicker_onDateSelected.bind(this);
    this.onSelectionChanged_1 = this.onSelectionChanged_1.bind(this);
    this.onSelectionChanged_2 = this.onSelectionChanged_2.bind(this);

    this.dxCloseButtonPopup_onClick =
      this.dxCloseButtonPopup_onClick.bind(this);
    this.dxPopup_titleRender = this.dxPopup_titleRender.bind(this);
    this.hiding_popup = this.hiding_popup.bind(this);

    this.dxButtonGroup_buttonRender =
      this.dxButtonGroup_buttonRender.bind(this);
    this.dxButtonGroup_onItemClick = this.dxButtonGroup_onItemClick.bind(this);

    this.onClick_compañia = this.onClick_compañia.bind(this);
  }

  group = {
    key: "compañia",
    denominacion: getTrad("compañia"),
    subGroup: { key: "entidad", denominacion: getTrad("entidad") },
  };

  detalladoButtonGroup = [
    {
      key: "prenda",
      denominacion: getTrad("prenda"),
      icon: "icon_Camisa",
      color: "--red",
    },
    {
      key: "fecha",
      denominacion: getTrad("fecha"),
      icon: "icon_CalendarioMes",
      color: "--blue",
    },
  ];

  get_dateRangePicker = () => {
    let { fechaIni, fechaFin } = this.state;
    let fechaSel = {
      startDate: fechaIni,
      endDate: fechaFin,
      idTipoCalendario: 2,
    };

    return (
      <DateRangePicker
        month
        year
        day
        defaultType="personalizado"
        fechaSel={fechaSel}
        align={"right"}
        maxDate={new Date()}
        onDateSelected={this.dateRangePicker_onDateSelected}
      />
    );
  };

  render() {
    let { lavanderia } = this.props;
    let { fechaIni, fechaFin, filtro_1, filtro_2, group } = this.state;

    let globalFilter = {
      ...default_filter,
      idLavanderia: lavanderia.idLavanderia,
      fechaIni,
      fechaFin,
    };
    let filtros_grid2 = { ...globalFilter },
      filtros_grid3 = { ...globalFilter };
    let key_1 = null,
      key_2 = null;

    if (!$.isEmptyObject(filtro_1)) {
      filtros_grid2 = { ...filtros_grid2, ...filtro_1 };
      let objKeys = Object.keys(filtro_1)[0];
      key_1 = objKeys + "_" + filtro_1[objKeys];
    } else {
      filtros_grid2.idLavanderia = -1;
    }

    if (!$.isEmptyObject(filtro_2)) {
      filtros_grid3 = { ...filtros_grid3, ...filtro_1, ...filtro_2 };
      let objKeys = Object.keys(filtro_2)[0];
      key_2 = objKeys + "_" + filtro_2[objKeys];
    } else {
      filtros_grid3.idLavanderia = -1;
    }

    return (
      <Fragment>
        <Fragment>
          <PageTitle
            heading={getTrad("infRechazoCliente")}
            postHeadingEnd={
              <Box direction="row" width="auto" height="100%">
                <ItemBox ratio={1} />
                <ItemBox baseSize={"auto"}>
                  <div className="d-flex flex-row he-100 align-items-center">
                    <div className="font-weight-bold pr-3">Fecha:</div>
                    {this.get_dateRangePicker()}
                  </div>
                </ItemBox>
              </Box>
            }
          />
          <div className={"media-body"}>
            <div
              id="InfRechazoCliente"
              className="formContainer scrollbar-container"
            >
              <Box direction="row" width="auto" height="100%">
                <ItemBox ratio={1}>
                  <GridRechazoCliente
                    title={this.group.denominacion}
                    group={this.group.key}
                    filtros={globalFilter}
                    selectedRowKey={key_1}
                    onSelectionChanged={this.onSelectionChanged_1}
                    onClick_compañia={this.onClick_compañia}
                  />
                </ItemBox>
                <ItemBox baseSize={20} />
                <ItemBox ratio={1}>
                  <GridRechazoCliente
                    title={this.group.subGroup.denominacion}
                    group={this.group.subGroup.key}
                    filtros={filtros_grid2}
                    selectedRowKey={key_2}
                    onSelectionChanged={this.onSelectionChanged_2}
                  />
                </ItemBox>
              </Box>
              <Popup
                titleRender={this.dxPopup_titleRender}
                showTitle={true}
                width={"1200"}
                height={"700"}
                visible={!$.isEmptyObject(filtro_2)}
                closeOnOutsideClick={false}
                onHiding={this.hiding_popup}
              >
                <Box height={"10%"} className="mb-5">
                  <ItemBox ratio={1}>
                    <ButtonGroup
                      height="100%"
                      width="300"
                      items={this.detalladoButtonGroup}
                      keyExpr="key"
                      selectedItemKeys={[group]}
                      buttonRender={this.dxButtonGroup_buttonRender}
                      onItemClick={this.dxButtonGroup_onItemClick}
                    />
                  </ItemBox>
                </Box>
                <Box height={"85%"}>
                  <ItemBox ratio={1}>
                    <GridRechazoCliente group={group} filtros={filtros_grid3} />
                  </ItemBox>
                </Box>
              </Popup>
            </div>
          </div>
        </Fragment>
      </Fragment>
    );
  }

  dxButtonGroup_buttonRender(data) {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        <i
          style={{ flexBasis: 30, color: "red" }}
          className={"font-size-xxl w-100 " + data.icon}
        ></i>
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  }

  dxButtonGroup_onItemClick(e) {
    this.setState({ group: e.itemData.key });
  }

  dxPopup_titleRender() {
    let { filtro_2 } = this.state;

    return (
      <Toolbar>
        <Item location="before">
          <div className="font-size-lg">{filtro_2?.denominacion}</div>
        </Item>
        <Item location="after">
          <Button
            icon="close"
            stylingMode="text"
            hoverStateEnabled={false}
            activeStateEnabled={false}
            focusStateEnabled={false}
            onClick={(arg) => this.dxCloseButtonPopup_onClick(arg)}
          />
        </Item>
      </Toolbar>
    );
  }

  dxCloseButtonPopup_onClick(e, popup) {
    this.setState({ filtro_2: {}, group: "prenda" });
  }

  dateRangePicker_onDateSelected(fecha) {
    if (fecha) {
      this.setState({ fechaIni: fecha.startDate, fechaFin: fecha.endDate });
    }
  }

  onSelectionChanged_1(changes) {
    let { filtro_1 } = this.state;
    if (JSON.stringify(changes) !== JSON.stringify(filtro_1)) {
      this.setState({ filtro_1: changes, filtro_2: {} });
    }
  }

  onSelectionChanged_2(changes) {
    let { filtro_2 } = this.state;
    if (JSON.stringify(changes) !== JSON.stringify(filtro_2)) {
      this.setState({ filtro_2: changes });
    }
  }

  hiding_popup() {
    this.setState({ filtro_2: {}, group: "prenda" });
  }

  onClick_compañia(changes) {
    this.setState({ filtro_2: changes });
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(InfRechazoCliente);
