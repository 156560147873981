import React from "react";
import { SVContext } from "../../components/SmartViewContext";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Box } from "devextreme-react";
import { Item as ItemBox } from "devextreme-react/box";
import { getColor_porcentaje } from "./helpers";
import Estadisticas from "./Estadisticas";
import Maquina from "./Maquina";
import Area from "./Area";

import "./Components.scss";
import { connect } from "react-redux";
import { convertClienteUtcToLavanderiaUtc } from "helpers";

class EstructuraItem extends React.Component {
    constructor(props) {
        super(props);
    }

    timeFormatHour = { hour: "numeric", minute: "2-digit", second: "2-digit" };
    timeFormat = { minute: "2-digit", second: "2-digit" };
    cardTitleStyle = {
        fontSize: "14px",
        margin: 0,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
    };

    render() {
        const { data, lavanderia } = this.props;
        const maquina = data[0].maquina;
        const isMaquina = maquina != null;

        return (
            <SVContext.Consumer>
                {(context) => {
                    const {
                        fechaLavanderia,
                        datosMaquinas,
                        tblPersonas,
                        tblEstadoSmartHubNMaquina,
                        tblClienteNMaquina,
                    } = context;

                    const item = data[0];
                    const datos = datosMaquinas.find((x) => x.idMaquina === maquina?.idMaquina);
                    const idEstadoSmartHub = tblEstadoSmartHubNMaquina.find(
                        (x) => x.idMaquina === maquina?.idMaquina
                    )?.idEstadoSmartHub;
                    const cliente = tblClienteNMaquina.find((x) => x.idMaquina === maquina?.idMaquina);

                    const isApagada = idEstadoSmartHub === 2;
                    const isAlertaActiva = idEstadoSmartHub === 3;

                    const tiempoActivo = new Date(fechaLavanderia - cliente?.fechaIni);

                    return (
                        <Card className={`he-100 ${isAlertaActiva ? "warningColor" : isApagada ? "opacity-3" : ""}`}>
                            <CardHeader className="px-3 justify-content-between d-flex">
                                <CardTitle style={this.cardTitleStyle}>
                                    {item.maquina ? item.maquina.denominacion : item.areaLabel}
                                </CardTitle>
                            </CardHeader>
                            <CardBody
                                className={`he-100 card-btm-border position-relative p-0 px-3`}
                                style={{
                                    borderColor: getColor_porcentaje(
                                        datos?.ptgActual,
                                        isApagada || !data[0].maquina ? "white" : "var(--secondary)",
                                        { item: data[0], tblPersonas }
                                    ),
                                }}
                            >
                                {isMaquina && (
                                    <Box className="he-100">
                                        <ItemBox baseSize={100}>
                                            <Estadisticas
                                                ptgActual={datos?.ptgActual}
                                                ptgCliente={datos?.ptgCliente}
                                                tiempoActivo={tiempoActivo}
                                            />
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <Maquina id={maquina.idMaquina} idEstadoSmartHub={idEstadoSmartHub} />
                                        </ItemBox>
                                    </Box>
                                )}
                                {data.filter((x) => x.idAreaLavanderia !== 3 && x.idAreaLavanderia !== 4).length >
                                    0 && <Area id={data[0].idAreaLavanderia} />}
                            </CardBody>
                        </Card>
                    );
                }}
            </SVContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(EstructuraItem);
