import React from "react";

import MultiView, { Item } from "devextreme-react/multi-view";

import InfRecambios from "pages/Assistant/InfRecambios";

const Layout = ({ selectedIndex, setSelectedIndex, children }) => {
  const onBack = () => setSelectedIndex(0);

  const getBackButton_paginaSecundaria = () => (
    <div
      id={"backButton"}
      className={"backButton dx-icon-chevronleft container_spanCentrado"}
      onClick={onBack}
    />
  );

  return (
    <MultiView
      selectedIndex={selectedIndex}
      height={"100%"}
      width={"100%"}
      className={"MainTitle"}
      deferRendering={false}
      focusStateEnabled={false}
      loop={false}
      swipeEnabled={false}
      animationEnabled
    >
      <Item>{children}</Item>
      <Item>
        {selectedIndex === 1 ? (
          <InfRecambios
            getBackButton_paginaSecundaria={getBackButton_paginaSecundaria}
          />
        ) : null}
      </Item>
    </MultiView>
  );
};

export default Layout;
