import React, { Fragment } from "react";
import { connect } from "react-redux";

//Componentes devExtreme
import Box, { Item as ItemBox } from "devextreme-react/box";

//Componentes
import { connectionConstants } from "constants";
import GridPreguntasPuntuacion from "./GridPreguntasPuntuacion";

//helpers
import { errorHandler, authHeader, getTrad } from "../../../../helpers";

//Odata
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

class Puntuacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idPreguntaSel: null,
    };
  }

  render() {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;
    const { idPreguntaSel } = this.state;

    return (
      <Fragment>
        <div className={"he-100"}>
          <div className="formContainer scrollbar-container">
            <Box direction="row" width="auto" height="100%">
              <ItemBox ratio={1}>
                <GridPreguntasPuntuacion
                  dataSource={this.dataSource_getDatosEncuestas}
                  onValueChange={this.onValueChange}
                  dataShown={"preguntas"}
                  idEncuestaSel={idEncuestaSel}
                  idLavanderiaSel={idLavanderiaSel}
                  idCompañiaSel={idCompañiaSel}
                />
              </ItemBox>
              <ItemBox baseSize={20} />
              <ItemBox ratio={1}>
                <GridPreguntasPuntuacion
                  dataSource={this.dataSource_infRespuestas}
                  onValueChange={this.onValueChange}
                  idPreguntaSel={idPreguntaSel}
                  dataShown={"respuestas"}
                  idLavanderiaSel={idLavanderiaSel}
                  idCompañiaSel={idCompañiaSel}
                />
              </ItemBox>
            </Box>
          </div>
        </div>
      </Fragment>
    );
  }

  onValueChange = (newState) => {
    this.setState(newState);
  };

  dataSource_infRespuestas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas_infRespuestas",
      key: "idRespuesta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_infRespuestas_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_infRespuestas_beforeSend = (request) => {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;
    const { idPreguntaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;
    request.params.idPregunta = idPreguntaSel;
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idCompañia = idCompañiaSel;

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  };

  dataSource_getDatosEncuestas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas",
      key: "idPregunta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_getDatosEncuestas_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_getDatosEncuestas_beforeSend(request) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idCompañia = idCompañiaSel;
    request.params.group = "puntuacion";

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;
    const { idPreguntaSel } = this.state;

    if (
      prevProps.idEncuestaSel !== idEncuestaSel ||
      prevProps.idLavanderiaSel !== idLavanderiaSel ||
      prevProps.idCompañiaSel !== idCompañiaSel
    ) {
      this.dataSource_getDatosEncuestas.reload();
      this.setState({ idPreguntaSel: null });
    }

    if (prevState.idPreguntaSel !== idPreguntaSel) {
      this.dataSource_infRespuestas.reload();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(Puntuacion);
