import $ from "jquery";
import {
  svg_ask_circle,
  svg_calandra_jensen,
  svg_plegadora,
  svg_user,
} from "styles/svg_iconos";

function getCssColor(color) {
  return getComputedStyle(document.documentElement).getPropertyValue(color);
}

const color_enCurso = getCssColor("--turquoise");
const color_success = getCssColor("--teal");
const color_warning = getCssColor("--warning");
const color_danger = getCssColor("--red");

const color_normal = getCssColor("--secondary");

export const svgPersona = encodeURI(
  "data:image/svg+xml;utf-8," +
    svg_color(svg_user.clone(), getCssColor("--primary"))[0].outerHTML,
);

export function getColor_porcentaje(porcentaje, defaultValue, obj) {
  if (
    obj &&
    !obj.item.maquina &&
    obj.tblPersonas.filter(
      (x) => x.idPosicionNAreaLavanderiaNLavanderia === obj.item.idPosicion,
    ).length > 0
  ) {
    return "var(--teal)";
  }

  if (porcentaje != null) {
    return porcentaje < 0
      ? color_enCurso
      : porcentaje < 0.7
        ? color_danger
        : porcentaje < 0.9
          ? color_warning
          : color_success;
  } else if (defaultValue) {
    return defaultValue;
  } else {
    return "white";
  }
}

function svg_color(svg, color) {
  $.each(svg[0].childNodes, function (i, node) {
    if (svg[0].id !== "question") node.style["stroke"] = color;
    node.style.fill = color;
  });

  return svg;
}

export function getMaquinaSVG(maquina, ptg, idEstadoSmartHub) {
  let svg;
  let color = getColor_porcentaje(ptg);

  if (maquina.idTipoMaquina === 1) {
    // CALANDRA
    svg = svg_calandra_jensen;
  } else if (maquina.idTipoMaquina === 34) {
    // CALANDRA
    svg = svg_plegadora;
  } else {
    svg = svg_ask_circle;
  }

  if (idEstadoSmartHub === 2 || idEstadoSmartHub === 3 || ptg == null) {
    return encodeURI(
      "data:image/svg+xml;utf-8," +
        svg_color(svg.clone(), color_normal)[0].outerHTML,
    );
  } else {
    return encodeURI(
      "data:image/svg+xml;utf-8," + svg_color(svg.clone(), color)[0].outerHTML,
    );
  }
}

export function acronym(text) {
  return text.split(/\s/).reduce(function (accumulator, word) {
    return accumulator + word.charAt(0);
  }, "");
}
