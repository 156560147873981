import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../../constants";
import {
  capitalize,
  errorHandler,
  getTrad,
  history,
  monthToName,
  authHeader,
  formatDate_noTime_parameter,
  endOfMonth,
  startOfMonth,
} from "../../../../../helpers";

//Layout
import PageTitle from "../../../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Button, List, SelectBox, Tooltip } from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Form, {
  Item,
  RequiredRule,
  SimpleItem,
  Tab,
  TabPanelOptions,
  TabbedItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";

class CierreDatos_Uniformidad_Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idLavanderia: props.idLavanderia,
      formData: {
        fechaDesde: startOfMonth(new Date()),
        fechaHasta: endOfMonth(new Date()),
        periodo: new Date(),
      },
      tblCierreDatos: [],
    };

    //Referencias
    this.form_REF = React.createRef();
  }

  get form() {
    return this.form_REF.current.instance;
  }

  //#region tblLavanderia

  datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      paginate: false,
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    sort: ["denominacion asc"],
  });

  datasource_tblLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  //#endregion

  //#region tblCierreDatos_Uniformidad
  datasource_tblCierreDatos_Uniformidad = new DataSource({
    store: new ODataStore({
      key: ["idLavanderia", "año", "mes"],
      keyType: {
        idLavanderia: "Int32",
        año: "Int32",
        mes: "Int32",
      },
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreDatos_Uniformidad",
      errorHandler: errorHandler,
      beforeSend: (request) => {
        this.datasource_tblCierreDatos_Uniformidad_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblCierreDatos_Uniformidad_onLoading(loadOptions);
      },
      onLoaded: (data) => {
        this.datasource_tblCierreDatos_Uniformidad_onLoaded(data);
      },
      version: 4,
    }),
    select: ["año", "mes", "fechaHasta"],
    sort: "año desc, mes desc",
  });

  datasource_tblCierreDatos_Uniformidad_beforeSend = (request) => {
    request.headers = { ...authHeader() };
  };

  datasource_tblCierreDatos_Uniformidad_onLoading = (loadOptions) => {
    loadOptions.filter = ["idLavanderia eq " + this.state.idLavanderia];
  };

  datasource_tblCierreDatos_Uniformidad_onLoaded = (data) => {
    let { formData } = this.state;
    let _this = this;

    let _formData = {};
    if (data.length > 0) {
      let ultimoCierre = { ...data[0] };
      if (ultimoCierre.mes == 12) {
        ultimoCierre.año++;
        ultimoCierre.mes = 1;
      } else {
        ultimoCierre.mes++;
      }

      const periodo = new Date(ultimoCierre.año, ultimoCierre.mes - 1, 1);
      const fechaHasta_ultimoCierre = new Date(ultimoCierre.fechaHasta);
      const fechaDesde = new Date(
        fechaHasta_ultimoCierre.setDate(fechaHasta_ultimoCierre.getDate() + 1),
      );
      const fechaHasta = endOfMonth(periodo);

      _formData = {
        fechaHasta,
        fechaDesde,
        periodo,
      };
    } else {
      //Sin registros
      _formData = {
        fechaDesde: startOfMonth(new Date()),
        fechaHasta: endOfMonth(new Date()),
        periodo: new Date(),
      };
    }

    this.setState({ tblCierreDatos: data, formData: _formData }, () => {
      _this.form.repaint();
    });
  };
  //#region

  render() {
    const { formData, idLavanderia } = this.state;

    return (
      <form className="he-100 w-100" onSubmit={this.submit}>
        <MultiView
          height="100%"
          width="100%"
          deferRendering={false}
          animationEnabled={true}
          swipeEnabled={false}
          selectedIndex={idLavanderia != null ? 1 : 0}
        >
          <MultiViewItem>
            <Box direction="col" width="100%" height="100%">
              <ItemBox baseSize={45}>
                <div className="font-size">{getTrad("lavanderías")}</div>
              </ItemBox>
              <ItemBox ratio={1}>
                <List
                  dataSource={this.datasource_tblLavanderia}
                  height="100%"
                  keyExpr={"idLavanderia"}
                  displayExpr="denominacion"
                  searchEnabled={false}
                  selectionMode="single"
                  pageLoadMode="scrollBottom"
                  selectedItemKeys={[idLavanderia]}
                  onOptionChanged={this.lavanderia_onOptionChanged}
                />
              </ItemBox>
            </Box>
          </MultiViewItem>
          <MultiViewItem>
            <Box direction="col" width="100%" height="100%">
              <ItemBox ratio={1}>
                <Form
                  ref={this.form_REF}
                  formData={formData}
                  showColonAfterLabel={true}
                  validationGroup="validationGroup"
                  className="he-100 w-100"
                  labelLocation="top"
                  colCount={2}
                >
                  <Item
                    colSpan={2}
                    dataField="periodo"
                    label={this.formLabel_periodo}
                    editorType="dxCalendar"
                    cssClass={"mb-4"}
                    editorOptions={this.editorOptions_calendar}
                  />
                  <Item
                    dataField="fechaDesde"
                    label={this.formLabel_fechaDesde}
                    editorType="dxDateBox"
                    editorOptions={this.editorOptions_date}
                  />
                  <Item
                    dataField="fechaHasta"
                    label={this.formLabel_fechaHasta}
                    editorType="dxDateBox"
                    editorOptions={this.editorOptions_date}
                  />
                </Form>
              </ItemBox>
              <ItemBox baseSize={45}>
                <Toolbar>
                  <ToolbarItem
                    location={"before"}
                    visible={this.props.idLavanderia == null}
                  >
                    <Button
                      text={getTrad("lavanderia")}
                      icon="back"
                      onClick={this.clear_idLavanderia}
                    />
                  </ToolbarItem>
                  <ToolbarItem location={"after"}>
                    <Button
                      text={getTrad("guardar")}
                      type="success"
                      useSubmitBehavior={true}
                    />
                  </ToolbarItem>
                </Toolbar>
              </ItemBox>
            </Box>
          </MultiViewItem>
        </MultiView>
      </form>
    );
  }
  formLabel_periodo = { text: getTrad("periodo") };
  formLabel_fechaDesde = { text: getTrad("fechaDesde") };
  formLabel_fechaHasta = { text: getTrad("fechaHasta") };

  editorOptions_date = {
    displayFormat: "dd/MM/yyyy",
    openOnFieldClick: true,
    stylingMode: "underlined",
    acceptCustomValue: false,
  };

  editorOptions_calendar = {
    elementAttr: {
      class: "w-100",
    },
    height: 300,
    maxZoomLevel: "year",
    minZoomLevel: "year",
    disabledDates: (args) => {
      return this.checkPeriodoDisabled(args.date);
    },
    cellTemplate: (cell, index, container) => {
      const isDisabled = this.checkPeriodoDisabled(cell.date);
      if (!isDisabled) {
        return capitalize(cell.date.toLocaleString("es-ES", { month: "long" }));
      } else {
        container[0].className =
          "dx-calendar-cell icon_lockOutline font-size-xxl";
        container[0].style.pointerEvents = "none";
        return "";
      }
    },
  };

  lavanderia_onOptionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys" && value[0]) {
      let _this = this;
      this.setState({ idLavanderia: value[0] }, () => {
        _this.datasource_tblCierreDatos_Uniformidad.load();
      });
    }
  };

  customizeText_mes = (rowData) => {
    return monthToName(rowData.value, "es");
  };

  checkPeriodoDisabled = (date) => {
    const { tblCierreDatos } = this.state;
    const año = date.getFullYear();
    const mes = date.getMonth() + 1;

    const exists =
      tblCierreDatos.filter((x) => {
        return x.año === año && x.mes === mes;
      }).length > 0;
    return exists; //Disabled if exists
  };

  clear_idLavanderia = () => {
    this.setState({ idLavanderia: null });
  };

  submit = async (e) => {
    e.preventDefault();
    const { datasource_tblCierreDatos_Uniformidad, onSave } = this.props;
    const { idLavanderia, formData } = this.state;

    const año = formData.periodo.getFullYear();
    const mes = formData.periodo.getMonth() + 1;
    const objInsert = {
      idLavanderia,
      año,
      mes,
      fechaDesde: formatDate_noTime_parameter(formData.fechaDesde),
      fechaHasta: formatDate_noTime_parameter(formData.fechaHasta),
    };

    await datasource_tblCierreDatos_Uniformidad.store().insert(objInsert);
    await datasource_tblCierreDatos_Uniformidad.reload();

    //Manda la señal al parent y expande el año y mes dónde se han insertado los datos.
    onSave(año, mes);
  };

  //#endregion

  componentDidMount() {
    const { user } = this.props;
    if (!user.isDepartamentoControl) {
      history.push("/");
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(CierreDatos_Uniformidad_Add);
