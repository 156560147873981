import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  phone_Pattern,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Box, { Item as ItemBox } from "devextreme-react/box";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Lookup as Lookup_grid,
  Editing,
  KeyboardNavigation,
} from "devextreme-react/data-grid";
import {
  Form,
  Item,
  EmptyItem,
  RequiredRule,
  ValidationRule,
} from "devextreme-react/form";
import { Row, Col } from "reactstrap";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { removeCellComponent } from "../../../components/DataGrid/Cells";
import { Button, List } from "devextreme-react";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";

class Almacenes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      almacenSel: null,
      almacenSel_bak: null,
      show_popup_add_recambio: false,
    };

    this.data = {
      almacenSel_validation_row: null, //row pendiente de validación para que si das a cancelar vuelva atrás
    };

    // REFERENCIAS
    this.dxDataGrid_tblAlmacenRecambios_REF = React.createRef();
    this.dxForm_tblAlmacenRecambios_REF = React.createRef();
    this.dxList_add_recambio_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblAlmacenRecambios_onInserted =
      this.datasource_tblAlmacenRecambios_onInserted.bind(this);
    this.datasource_tblAlmacenRecambios_onUpdated =
      this.datasource_tblAlmacenRecambios_onUpdated.bind(this);
    this.datasource_tblAlmacenRecambios_postProcess =
      this.datasource_tblAlmacenRecambios_postProcess.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //EVENTOS
    this.onSelectionChanged_tblAlmacenRecambios =
      this.onSelectionChanged_tblAlmacenRecambios.bind(this);
    this.onToolbarPreparing_tblAlmacenRecambios =
      this.onToolbarPreparing_tblAlmacenRecambios.bind(this);
    this.onRowRemoving_tblAlmacenRecambios =
      this.onRowRemoving_tblAlmacenRecambios.bind(this);
    this.onRowRemoved_tblAlmacenRecambios =
      this.onRowRemoved_tblAlmacenRecambios.bind(this);
    this.onItemClick_almacenPadre = this.onItemClick_almacenPadre.bind(this);
    this.onSelectionChanged_almacenPadre =
      this.onSelectionChanged_almacenPadre.bind(this);

    this.onToolbarPreparing_tblStockMinimoNAlmacenRecambios =
      this.onToolbarPreparing_tblStockMinimoNAlmacenRecambios.bind(this);
    this.hiding_popup_add_recambio = this.hiding_popup_add_recambio.bind(this);
    this.onClick_btnAdd_popup_add_recambio =
      this.onClick_btnAdd_popup_add_recambio.bind(this);

    //MÉTODOS
    this.crearAlmacen = this.crearAlmacen.bind(this);
    this.submit_formAlmacen = this.submit_formAlmacen.bind(this);
  }

  //REFERENCIAS
  get dxDataGrid_tblAlmacenRecambios() {
    return this.dxDataGrid_tblAlmacenRecambios_REF.current.instance;
  }

  get dxForm_tblAlmacenRecambios() {
    return this.dxForm_tblAlmacenRecambios_REF.current.instance;
  }

  get dxList_add_recambio() {
    return this.dxList_add_recambio_REF.current.instance;
  }

  //#region DATA SOURCES
  datasource_tblAlmacenRecambios = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onInserted: (values, key) => {
        this.datasource_tblAlmacenRecambios_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblAlmacenRecambios_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    expand: [
      "tblStockMinimoNAlmacenRecambios($expand=tblRecambio($select=referencia,referenciaInterna,denominacion))",
    ],
    postProcess: (data) => {
      return this.datasource_tblAlmacenRecambios_postProcess(data);
    },
  });

  datasource_tblAlmacenRecambios_postProcess(data) {
    let almacenes = $.extend(true, [], data);

    data = $.each(data, function (index, item) {
      item.tblAlmacenPadre = [];
      if (item.idAlmacenPadre !== null) {
        let almacenPadre = $.grep(almacenes, function (alm) {
          return alm.idAlmacen === item.idAlmacenPadre;
        });
        item.tblAlmacenPadre.push(almacenPadre[0]);
      }
    });
    return data;
  }

  datasource_tblAlmacenRecambios_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { almacenSel } = this.state;
    almacenSel.idAlmacen = key;
    let almacenSel_ = $.extend(true, {}, almacenSel);

    $.when(
      this.dxDataGrid_tblAlmacenRecambios.refresh(),
      this.datasource_tblAlmacenPadre.reload(),
    ).then(function () {
      _this.setState({ almacenSel: almacenSel, almacenSel_bak: almacenSel_ });
    });
  }

  datasource_tblAlmacenRecambios_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.dxDataGrid_tblAlmacenRecambios.refresh();
  }

  datasource_tblAlmacenPadre = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    sort: ["denominacion"],
    filter: [
      "idAlmacenPadre eq null and activo eq true and eliminado eq false",
    ],
  });

  datasource_tblPais = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  datasource_datos_tblMoneda = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMoneda",
      key: "idMoneda",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  datasource_tblTipoSalidaRecambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoSalidaRecambio",
      key: "idTipoSalidaRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idTipoSalidaRecambio"],
    expand: ["idTraduccionNavigation($select=clave)"],
  });

  datasource_tblRecambio = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambio_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: ["idRecambio, referencia, referenciaInterna, denominacion"],
    sort: ["referencia asc", "denominacion asc"],
  });

  datasource_tblRecambio_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblRecambio_onLoading(loadOptions) {
    let { almacenSel } = this.state;
    let idsRecambios_existentes = $.map(
      almacenSel.tblStockMinimoNAlmacenRecambios,
      function (item) {
        if (item) return item.idRecambio;
      },
    );

    if (loadOptions.filter) {
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          "not(idRecambio in (" +
            $.map(idsRecambios_existentes, function (item) {
              return item;
            }) +
            "))",
        ],
      ];
    } else {
      loadOptions.filter = [
        "not(idRecambio in (" +
          $.map(idsRecambios_existentes, function (item) {
            return item;
          }) +
          "))",
      ];
    }
  }
  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    _this.loadPanel_show();
    $.when(
      _this.datasource_tblAlmacenRecambios.load(),
      _this.datasource_tblAlmacenPadre.load(),
      _this.datasource_tblPais.load(),
      _this.datasource_tblTipoSalidaRecambio.load(),
      _this.datasource_datos_tblMoneda.load(),
    ).then(function () {
      _this.crearAlmacen();
      _this.loadPanel_hide();
    });
  }

  render() {
    let { almacenSel, show_popup_add_recambio } = this.state;

    const tipoSalidaRecambio_editorOptions = {
      dataSource: this.datasource_tblTipoSalidaRecambio.items(),
      displayExpr: (e) => (e ? getTrad(e.idTraduccionNavigation.clave) : null),
      valueExpr: "idTipoSalidaRecambio",
      value: almacenSel !== null ? almacenSel.idTipoSalidaRecambio : null,
      disabled: almacenSel !== null && almacenSel.idAlmacen ? true : false,
    };

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                    component="div"
                    className={"media-body"}
                    transitionName="PageAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1000}
                    transitionEnter={false}
                    transitionLeave={false}> */}
          <div id="Almacenes" className="formContainer scrollbar-container">
            <Row className="pt-4 he-100">
              <Col xs="12" md="6">
                <Box
                  direction="col"
                  width="100%"
                  height="100%"
                  elementAttr={this.elementAttr_BoxPrincipal}
                  align="space-around"
                  crossAlign="stretch"
                >
                  <ItemBox baseSize="auto">
                    <form
                      onSubmit={this.submit_formAlmacen}
                      className="d-flex flex-column he-100 w-100"
                    >
                      <div className="text-left pb-3">
                        <Toolbar>
                          <ToolbarItem location="before">
                            <Button
                              text={getTrad("nuevoAlmacen")}
                              icon="add"
                              onClick={this.crearAlmacen}
                            />
                          </ToolbarItem>
                          <ToolbarItem location="after">
                            <Button
                              text={getTrad("actualizar")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_Almacen"
                              visible={
                                almacenSel && almacenSel.idAlmacen
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              text={getTrad("crearAlmacen")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_Almacen"
                              visible={!(almacenSel && almacenSel.idAlmacen)}
                            />
                          </ToolbarItem>
                        </Toolbar>
                      </div>
                      <div className="flex-grow-1">
                        <Form
                          height="100%"
                          ref={this.dxForm_tblAlmacenRecambios_REF}
                          formData={almacenSel}
                          labelLocation="top"
                          scrollingEnabled={true}
                          screenByWidth={this.screenByWidth}
                          colCountByScreen={this.colCountByScreen}
                          validationGroup="validationGroup_form_Almacen"
                        >
                          <Item
                            dataField="denominacion"
                            label={this.formLabel_denominacion}
                            colSpan={2}
                          >
                            <RequiredRule />
                          </Item>
                          <Item
                            dataField="direccion"
                            label={this.formLabel_direccion}
                            colSpan={3}
                          />
                          <Item
                            dataField="poblacion"
                            label={this.formLabel_poblacion}
                            colSpan={2}
                          />
                          <Item
                            dataField="codigoPostal"
                            label={this.formLabel_codigoPostal}
                            colSpan={1}
                          />
                          <Item
                            dataField="telefono"
                            label={this.formLabel_telefono}
                            colSpan={1}
                          >
                            <ValidationRule
                              type="pattern"
                              pattern={phone_Pattern}
                              message={getTrad("validation_Formato")}
                            />
                          </Item>
                          <Item
                            dataField="centroCoste"
                            label={this.formLabel_centroCoste}
                            editorType={"dxTextBox"}
                            editorOptions={this.centroCoste_editorOptions}
                            colSpan={1}
                          />
                          <Item
                            dataField="idAlmacenPadre"
                            label={this.formLabel_almacenPadre}
                            editorType="dxSelectBox"
                            editorOptions={{
                              dataSource: $.grep(
                                this.datasource_tblAlmacenPadre.items(),
                                function (item) {
                                  if (almacenSel !== null)
                                    return (
                                      item.idAlmacen !== almacenSel.idAlmacen
                                    );
                                  return item;
                                },
                              ),
                              displayExpr: "denominacion",
                              valueExpr: "idAlmacen",
                              value:
                                almacenSel !== null
                                  ? almacenSel.idAlmacenPadre
                                  : undefined,
                              showClearButton: true,
                              onItemClick: this.onItemClick_almacenPadre,
                              onSelectionChanged:
                                this.onSelectionChanged_almacenPadre,
                            }}
                            colSpan={2}
                          ></Item>
                          <Item
                            dataField="idPais"
                            label={this.formLabel_idPais}
                            editorType="dxSelectBox"
                            editorOptions={{
                              dataSource: this.datasource_tblPais,
                              displayExpr: "denominacion",
                              valueExpr: "idPais",
                              value:
                                almacenSel !== null ? almacenSel.idPais : null,
                              disabled:
                                almacenSel !== null &&
                                almacenSel.idAlmacenPadre !== undefined &&
                                almacenSel.idAlmacenPadre !== null,
                            }}
                            colSpan={2}
                          ></Item>
                          <Item
                            dataField="idMoneda"
                            label={this.formLabel_idMoneda}
                            editorType="dxSelectBox"
                            editorOptions={{
                              dataSource: this.datasource_datos_tblMoneda,
                              displayExpr: "denominacion",
                              valueExpr: "idMoneda",
                              value:
                                almacenSel !== null
                                  ? almacenSel.idMoneda
                                  : null,
                              disabled:
                                almacenSel !== null &&
                                almacenSel.idAlmacenPadre !== undefined &&
                                almacenSel.idAlmacenPadre !== null,
                            }}
                            colSpan={2}
                          >
                            <RequiredRule />
                          </Item>
                          <Item
                            dataField="idTipoSalidaRecambio"
                            label={this.formLabel_tipoSalida}
                            colSpan={2}
                            editorType="dxSelectBox"
                            editorOptions={tipoSalidaRecambio_editorOptions}
                            visible={
                              almacenSel && almacenSel.idAlmacenPadre === null
                            }
                          >
                            <RequiredRule />
                          </Item>
                          <Item
                            dataField="activo"
                            label={this.formLabel_activo}
                            colSpan={1}
                            editorType="dxSwitch"
                            editorOptions={this.activo_editorOptions}
                          />
                        </Form>
                      </div>
                    </form>
                  </ItemBox>
                  <ItemBox ratio={1}>
                    <DataGrid
                      //Datos
                      dataSource={
                        almacenSel !== null
                          ? almacenSel.tblStockMinimoNAlmacenRecambios
                          : []
                      }
                      //Propiedades
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      className={"pt-2"}
                      hoverStateEnabled={true}
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onToolbarPreparing={
                        this.onToolbarPreparing_tblStockMinimoNAlmacenRecambios
                      }
                    >
                      <KeyboardNavigation
                        enterKeyAction="moveFocus"
                        enterKeyDirection="column"
                        editOnKeyPress={true}
                      />
                      <Editing
                        mode="cell"
                        allowUpdating={true}
                        selectTextOnEditStart={true}
                      />
                      <SearchPanel visible={true} width={240} />
                      <HeaderFilter visible={false} />
                      <FilterRow visible={true} />
                      <Paging enabled={false} />
                      <Column
                        dataField="tblRecambio.referenciaInterna"
                        caption={"Ref. Interna"}
                        width={85}
                        visible={false}
                        alignment="center"
                        allowHeaderFiltering={false}
                      />
                      <Column
                        dataField="tblRecambio.referencia"
                        caption={getTrad("refFabricante")}
                        width="25%"
                        alignment="center"
                        sortOrder="asc"
                        allowEditing={false}
                      />
                      <Column
                        dataField="tblRecambio.denominacion"
                        caption={getTrad("denominacion")}
                        alignment="left"
                        allowEditing={false}
                      />
                      <Column
                        dataField="cantidad"
                        caption={getTrad("cantidad")}
                        width={120}
                        alignment="center"
                        allowHeaderFiltering={false}
                        allowEditing={true}
                      />
                      <Column
                        caption=" "
                        width={50}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={removeCellComponent}
                      />
                    </DataGrid>
                  </ItemBox>
                </Box>
              </Col>
              <Col xs="12" md="6" className="he-100">
                <DataGrid
                  //Datos
                  ref={this.dxDataGrid_tblAlmacenRecambios_REF}
                  dataSource={this.datasource_tblAlmacenRecambios}
                  //Propiedades
                  columnsAutoWidth={true}
                  height={"100%"}
                  width={"100%"}
                  hoverStateEnabled={true}
                  selection={this.tblAlmacenRecambios_selection}
                  selectedRowKeys={
                    almacenSel && almacenSel.idAlmacen
                      ? [almacenSel.idAlmacen]
                      : []
                  }
                  //Estilos
                  showColumnLines={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  onToolbarPreparing={
                    this.onToolbarPreparing_tblAlmacenRecambios
                  }
                  onRowRemoving={this.onRowRemoving_tblAlmacenRecambios}
                  onRowRemoved={this.onRowRemoved_tblAlmacenRecambios}
                  onSelectionChanged={
                    this.onSelectionChanged_tblAlmacenRecambios
                  }
                  onRowPrepared={this.onRowPrepared_tblAlmacenRecambios}
                >
                  <SearchPanel visible={true} width={240} />
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={this.tblAlmacenRecambios_allowedPageSizes}
                    showInfo={true}
                  />
                  <Paging defaultPageSize={50} />

                  <Column
                    dataField="activo"
                    visible={false}
                    sortIndex={1}
                    sortOrder="desc"
                  />
                  <Column
                    dataField="denominacion"
                    width={"50%"}
                    caption={getTrad("denominacion")}
                    alignment="left"
                    sortIndex={3}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="centroCoste"
                    caption={getTrad("centroCoste")}
                    width={200}
                    alignment="center"
                    sortIndex={2}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="idAlmacenPadre"
                    caption={getTrad("almacenPadre")}
                    width={180}
                    alignment="left"
                    allowHeaderFiltering={false}
                  >
                    <Lookup_grid
                      dataSource={this.datasource_tblAlmacenPadre.items()}
                      valueExpr={"idAlmacen"}
                      displayExpr={"denominacion"}
                    />
                  </Column>
                </DataGrid>
              </Col>
            </Row>
            {almacenSel !== null ? (
              <Popup
                title={getTrad("añadirRecambio")}
                showTitle={true}
                width={"600"}
                height={"700"}
                visible={show_popup_add_recambio}
                closeOnOutsideClick={false}
                onHiding={this.hiding_popup_add_recambio}
              >
                <List
                  dataSource={this.datasource_tblRecambio}
                  ref={this.dxList_add_recambio_REF}
                  searchEnabled={true}
                  searchExpr={[
                    "denominacion",
                    "referencia",
                    "referenciaInterna",
                  ]}
                  selectionMode="multiple"
                  showSelectionControls={true}
                  height="100%"
                  keyExpr="idRecambio"
                  activeStateEnabled={false}
                  focusStateEnabled={false}
                  pageLoadMode="scrollBottom"
                  itemRender={this.itemRender_list_add_recambio}
                />
                <ToolbarItemPopup
                  visible={true}
                  location="after"
                  toolbar="bottom"
                >
                  <Button
                    text={getTrad("aceptar")}
                    type="success"
                    onClick={this.onClick_btnAdd_popup_add_recambio}
                  />
                </ToolbarItemPopup>
              </Popup>
            ) : (
              ""
            )}
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //EDITORES
  centroCoste_editorOptions = {
    mask: "000",
  };

  columnEditorOptions = {
    showClearButton: true,
  };

  activo_editorOptions = {
    switchedOnText: getTrad("activo"),
    switchedOffText: getTrad("noActivo"),
  };

  formLabel_invisible = { visible: false };
  formLabel_denominacion = { text: getTrad("denominacion") };
  formLabel_direccion = { text: getTrad("direccion") };
  formLabel_poblacion = { text: getTrad("poblacion") };
  formLabel_codigoPostal = { text: getTrad("codigoPostal") };
  formLabel_telefono = { text: getTrad("telefono") };
  formLabel_almacenPadre = { text: getTrad("almacenPadre") };
  formLabel_centroCoste = { text: getTrad("centroCoste") };
  formLabel_tipoSalida = { text: getTrad("tipoSalidaRecambios") };
  formLabel_activo = { text: getTrad("activo") };
  formLabel_idPais = { text: getTrad("pais") };
  formLabel_idMoneda = { text: getTrad("moneda") };

  colCountByScreen = { xl: 5, lg: 5, md: 5, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //CONFIG GRID
  tblAlmacenRecambios_selection = { mode: "single" };
  tblAlmacenRecambios_allowedPageSizes = [20, 50, 100];

  //#region EVENTOS
  onToolbarPreparing_tblAlmacenRecambios(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("almacenes"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
    );
  }
  onRowRemoving_tblAlmacenRecambios(e) {
    this.datasource_tblAlmacenRecambios.store().remove(e.key);
  }

  onRowRemoved_tblAlmacenRecambios(e) {
    let { almacenSel } = this.state;
    if (e.key === almacenSel.idAlmacen) {
      this.crearAlmacen();
    }
  }

  onSelectionChanged_tblAlmacenRecambios(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let dxForm_hasChanges = !(
        JSON.stringify(_this.state.almacenSel) ===
        JSON.stringify(_this.state.almacenSel_bak)
      );

      if (
        _this.dxDataGrid_tblAlmacenRecambios.hasEditData() ||
        dxForm_hasChanges
      ) {
        if (_this.data.almacenSel_validation_row === null)
          _this.data.almacenSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              _this.dxDataGrid_tblAlmacenRecambios.cancelEditData();

              let almacenSel = $.extend(
                true,
                {},
                _this.data.almacenSel_validation_row,
              );
              let almacenSel_ = $.extend(true, {}, almacenSel);

              if (almacenSel.tblAlmacenPadre.length > 0) {
                almacenSel.idPais = almacenSel.tblAlmacenPadre[0].idPais;
                almacenSel_.idPais = almacenSel.tblAlmacenPadre[0].idPais;

                almacenSel.idMoneda = almacenSel.tblAlmacenPadre[0].idMoneda;
                almacenSel_.idMoneda = almacenSel.tblAlmacenPadre[0].idMoneda;
              }

              _this.setState(
                { almacenSel: almacenSel, almacenSel_bak: almacenSel_ },
                async () => {
                  _this.datasource_tblAlmacenRecambios.load();
                },
              );
              _this.data.almacenSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.almacenSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let almacenSel = $.extend(true, {}, data);
        let almacenSel_bak = $.extend(true, {}, almacenSel);

        if (almacenSel != null && almacenSel.tblAlmacenPadre.length > 0) {
          almacenSel.idPais = almacenSel.tblAlmacenPadre[0].idPais;
          almacenSel_bak.idPais = almacenSel.tblAlmacenPadre[0].idPais;

          almacenSel.idMoneda = almacenSel.tblAlmacenPadre[0].idMoneda;
          almacenSel_bak.idMoneda = almacenSel.tblAlmacenPadre[0].idMoneda;
        }

        _this.setState(
          { almacenSel: almacenSel, almacenSel_bak: almacenSel_bak },
          async () => {
            _this.datasource_tblAlmacenRecambios.load();
          },
        );
      }
    }
  }

  onRowPrepared_tblAlmacenRecambios(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  onSelectionChanged_almacenPadre(e) {
    if (e.selectedItem === null) {
      // En caso de quitar almacén padre
      let almacenSel = { ...this.state.almacenSel };
      almacenSel.idAlmacenPadre = null;
      this.setState({ almacenSel });
    }
  }

  onItemClick_almacenPadre(e) {
    const data = e.itemData;
    let _this = this;
    let { almacenSel } = this.state;

    if (data) {
      almacenSel.idAlmacenPadre = data.idAlmacen;
      almacenSel.idPais = data.idPais;
      almacenSel.idMoneda = data.idMoneda;
    } else almacenSel.idAlmacenPadre = null; // todo ver consecuencias de poner null y no undefined
    _this.setState({ almacenSel: almacenSel });
  }

  onToolbarPreparing_tblStockMinimoNAlmacenRecambios(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("stockMinimo"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          text: getTrad("añadirRecambio"),
          icon: "plus",
          onClick: function (e) {
            e.component.option("disabled", true);
            _this.datasource_tblRecambio.reload().done(function () {
              e.component.option("disabled", false);
              _this.setState({ show_popup_add_recambio: true });
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh();
          },
        },
      },
    );
  }
  hiding_popup_add_recambio(e) {
    this.dxList_add_recambio.unselectAll();
    this.setState({ show_popup_add_recambio: false });
  }
  itemRender_list_add_recambio(itemData) {
    if (itemData.referencia != null && itemData.referencia.length > 0) {
      return itemData.referencia + " - " + itemData.denominacion;
    } else {
      return itemData.denominacion;
    }
  }
  onClick_btnAdd_popup_add_recambio() {
    let { almacenSel } = this.state;

    let recambiosAdd = this.dxList_add_recambio
      .option("selectedItems")
      .map(function (item) {
        return {
          tblRecambio: {
            referencia: item.referencia,
            denominacion: item.denominacion,
          },
          cantidad: 0,
          idRecambio: item.idRecambio,
        };
      });

    almacenSel.tblStockMinimoNAlmacenRecambios = [
      ...almacenSel.tblStockMinimoNAlmacenRecambios,
      ...recambiosAdd,
    ];

    let seen = new Set();
    var hasDuplicates = almacenSel.tblStockMinimoNAlmacenRecambios.some(
      function (obj) {
        return seen.size === seen.add(obj.idRecambio).size;
      },
    );

    if (hasDuplicates) {
      notify({
        message: "Hay recambios duplicados",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }

    this.dxList_add_recambio.unselectAll();
    this.setState({ show_popup_add_recambio: false, almacenSel: almacenSel });
  }

  //#endregion

  crearAlmacen() {
    let almacenSel = {};

    almacenSel.denominacion = "";
    almacenSel.direccion = "";
    almacenSel.poblacion = "";
    almacenSel.codigoPostal = "";
    almacenSel.telefono = "";
    almacenSel.centroCoste = "";
    almacenSel.idAlmacen = undefined;
    almacenSel.idAlmacenPadre = null;
    almacenSel.idPais = null;
    almacenSel.idMoneda = null;
    almacenSel.activo = true;
    almacenSel.idTipoSalidaRecambio = null;
    almacenSel.tblStockMinimoNAlmacenRecambios = [];

    let almacenSel_bak = $.extend(true, {}, almacenSel);
    this.setState({ almacenSel: almacenSel, almacenSel_bak: almacenSel_bak });
    this.datasource_tblAlmacenPadre.load();
  }

  submit_formAlmacen(e) {
    e.preventDefault();

    let _this = this;
    let { almacenSel } = this.state;
    let almacenSel_ = $.extend(true, {}, almacenSel);

    delete almacenSel_.tblAlmacenPadre;

    if (almacenSel_.idAlmacenPadre !== null) {
      almacenSel_.idTipoSalidaRecambio = null;
    }

    $.each(almacenSel_.tblStockMinimoNAlmacenRecambios, function (index, item) {
      delete item.tblRecambio;
    });

    let seen = new Set();
    var hasDuplicates = almacenSel_.tblStockMinimoNAlmacenRecambios.some(
      function (obj) {
        return seen.size === seen.add(obj.idRecambio).size;
      },
    );

    if (hasDuplicates) {
      notify({
        message: "Hay recambios duplicados",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }

    if (almacenSel_.idAlmacen) {
      //UPDATE, eliminamos Almacen (Original) que se acaba de modificar
      this.datasource_tblAlmacenRecambios
        .store()
        .update(almacenSel_.idAlmacen, almacenSel_)
        .done(function () {
          _this.datasource_tblAlmacenRecambios.reload();
          _this.setState({ almacenSel_bak: almacenSel });
        });
    } else {
      //INSERT
      this.datasource_tblAlmacenRecambios.store().insert(almacenSel_);
    }
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps, prevState) {}
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Almacenes);
