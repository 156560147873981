import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  history,
  monthToName,
  patchRequestHandler,
  capitalize,
  endOfMonth,
  formatDate_noTime_parameter,
} from "../../../../../helpers";

//Layout
import PageTitle from "../../../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

//Components
import notify from "devextreme/ui/notify";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Calendar from "devextreme-react/calendar";
import { Button, List, SelectBox, Tooltip } from "devextreme-react";

import EditCellComponent_isCerrado from "../EditCellComponent_isCerrado";
import CierreDatos_Lavanderia_Add from "./CierreDatos_Lavanderia_Add";

class CierreDatos_Lavanderia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedItemKeys_lavanderia: [],
      tblCierreDatos: [],
    };

    //Referencias
    this.dxCalendar_REF = React.createRef();
  }

  get calendar() {
    return this.dxCalendar_REF.current.instance;
  }

  //#region tblLavanderia

  datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      paginate: false,
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    sort: ["denominacion asc"],
  });

  datasource_tblLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  //#endregion

  //#region tblCierreDatos_Lavanderia
  datasource_tblCierreDatos_Lavanderia = new DataSource({
    store: new ODataStore({
      key: ["idLavanderia", "año", "mes"],
      keyType: {
        idLavanderia: "Int32",
        año: "Int32",
        mes: "Int32",
      },
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreDatos_Lavanderia",
      errorHandler: errorHandler,
      beforeSend: (request) => {
        this.datasource_tblCierreDatos_Lavanderia_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblCierreDatos_Lavanderia_onLoading(loadOptions);
      },
      onLoaded: (data) => {
        this.datasource_tblCierreDatos_Lavanderia_onLoaded(data);
      },
      onUpdated: () => {
        this.datasource_tblCierreDatos_Lavanderia_onUpdated();
      },
      onInserted: () => {
        this.datasource_tblCierreDatos_Lavanderia_onInserted();
      },
      version: 4,
    }),
    expand: ["idLavanderiaNavigation($select=denominacion)"],
  });

  datasource_tblCierreDatos_Lavanderia_beforeSend = (request) => {
    request.headers = { ...authHeader() };
  };

  datasource_tblCierreDatos_Lavanderia_onLoading = (loadOptions) => {
    const { selectedItemKeys_lavanderia } = this.state;
    let idLavanderia =
      selectedItemKeys_lavanderia.length > 0
        ? selectedItemKeys_lavanderia[0]
        : -1;
    loadOptions.filter = ["idLavanderia eq " + idLavanderia];
  };

  datasource_tblCierreDatos_Lavanderia_onLoaded = (data) => {
    this.setState({ tblCierreDatos: data }, () => {
      this.calendar.repaint();
    });
  };

  datasource_tblCierreDatos_Lavanderia_onInserted = () => {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  datasource_tblCierreDatos_Lavanderia_onUpdated = () => {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };
  //#endregion

  render() {
    const { selectedItemKeys_lavanderia, tblCierreDatos } = this.state;
    return (
      <div className="scrollbar-container">
        <Box direction="row" width="100%" height="100%">
          <ItemBox ratio={1}>
            <Box direction="col" width="100%" height="100%">
              <ItemBox baseSize={45}>
                <div className="font-size">{getTrad("lavanderías")}</div>
              </ItemBox>
              <ItemBox ratio={1}>
                <List
                  dataSource={this.datasource_tblLavanderia}
                  height="100%"
                  keyExpr={"idLavanderia"}
                  displayExpr="denominacion"
                  searchEnabled={true}
                  searchExpr={"denominacion"}
                  selectionMode="single"
                  pageLoadMode="scrollBottom"
                  selectedItemKeys={selectedItemKeys_lavanderia}
                  onOptionChanged={this.lavanderia_onOptionChanged}
                />
              </ItemBox>
            </Box>
          </ItemBox>
          <ItemBox ratio={2}>
            <Box
              direction="col"
              width="100%"
              height="100%"
              visible={tblCierreDatos.length > 0}
            >
              <ItemBox baseSize={45}>
                <div className="font-size">{getTrad("calendario")}</div>
              </ItemBox>
              <ItemBox ratio={1}>
                <Calendar
                  ref={this.dxCalendar_REF}
                  height={"100%"}
                  width={"100%"}
                  maxZoomLevel="year"
                  minZoomLevel="year"
                  max={endOfMonth(new Date())}
                  cellRender={this.dxCalendar_cellRender}

                  // onValueChanged={this.dxCalendar_eventosLavanderia_onValueChanged}
                />
              </ItemBox>
            </Box>
            <div
              className="he-100 position-relative"
              hidden={tblCierreDatos.length > 0}
            >
              <div className="dx-datagrid-nodata">
                {getTrad("seleccioneLavanderia")}
              </div>
            </div>
          </ItemBox>
        </Box>
      </div>
    );
  }

  //#region Lavandería Events
  lavanderia_onOptionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys") {
      let _this = this;
      this.setState({ selectedItemKeys_lavanderia: value }, () => {
        _this.datasource_tblCierreDatos_Lavanderia.load();
      });
    }
  };

  //#endregion

  //#region Calendar Events
  dxCalendar_cellRender = ({ date }) => {
    let _this = this;
    const { selectedItemKeys_lavanderia, tblCierreDatos } = this.state;

    const año = date.getFullYear();
    const mes = date.getMonth() + 1;

    const reg_cierre = tblCierreDatos.filter((x) => {
      return x.año === año && x.mes === mes;
    });

    const isDisabled = reg_cierre[0]?.isCerrado ?? false;
    const showEdit = date <= endOfMonth(new Date());

    let editCellComponent_data = {
      value: isDisabled,
      setValue: (isCerrado) => {
        const idLavanderia = selectedItemKeys_lavanderia[0];
        const isInsert = reg_cierre.length == 0;

        _this.IU_tblCierreDatos_Lavanderia(
          idLavanderia,
          año,
          mes,
          isCerrado,
          isInsert,
        );
      },
    };

    return (
      <div className={"container_spanCentrado flex-column font-size-xl"}>
        {capitalize(date.toLocaleString("es-ES", { month: "long" }))}
        <div className="mt-4" style={{ height: "5rem", width: "5rem" }}>
          {showEdit && (
            <EditCellComponent_isCerrado
              data={editCellComponent_data}
              className={"position-relative w-100 he-100 font-size-xxl shadow"}
            />
          )}
        </div>
      </div>
    );
  };

  customizeText_mes = (rowData) => {
    return monthToName(rowData.value, "es");
  };

  IU_tblCierreDatos_Lavanderia = (
    idLavanderia,
    año,
    mes,
    isCerrado,
    isInsert,
  ) => {
    let _this = this;
    if (isInsert) {
      let fechaDesde = new Date(año, mes - 1, 1);
      const fechaHasta = formatDate_noTime_parameter(endOfMonth(fechaDesde));
      fechaDesde = formatDate_noTime_parameter(fechaDesde);

      this.datasource_tblCierreDatos_Lavanderia
        .store()
        .insert({ idLavanderia, año, mes, fechaDesde, fechaHasta, isCerrado })
        .done(() => {
          _this.datasource_tblCierreDatos_Lavanderia.load();
        });
    } else {
      this.datasource_tblCierreDatos_Lavanderia
        .store()
        .update({ idLavanderia, año, mes }, patchRequestHandler({ isCerrado }))
        .done(() => {
          _this.datasource_tblCierreDatos_Lavanderia.load();
        });
    }
  };

  //#endregion

  componentDidMount() {
    const { user } = this.props;
    if (!user.isDepartamentoControl) {
      history.push("/");
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CierreDatos_Lavanderia);
