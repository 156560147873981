import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  phone_Pattern,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { Button } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  SearchPanel,
  Pager,
  Paging,
  Editing,
} from "devextreme-react/data-grid";
import {
  Form,
  Item,
  EmptyItem,
  RequiredRule,
  EmailRule,
  ValidationRule,
} from "devextreme-react/form";
import { Row, Col } from "reactstrap";

//CUSTOM COMPONENTS
import { removeCellComponent } from "../../../components/DataGrid/Cells";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

class Proveedores extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      proveedorSel: null,
      showPopup_Add_tblPersonaContactoNProveedor: false,
      proveedorSel_bak: null,
    };

    this.data = {
      proveedorSel_validation_row: null, //row pendiente de validación para que si das a cancelar vuelva atrás
    };

    // REFERENCIAS
    this.dxDataGrid_tblProveedor_REF = React.createRef();
    this.dxDataGrid_tblPersonaContactoNProveedor_REF = React.createRef();
    this.dxForm_tblProveedor_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblProveedor_onInserted =
      this.datasource_tblProveedor_onInserted.bind(this);
    this.datasource_tblProveedor_onUpdated =
      this.datasource_tblProveedor_onUpdated.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    // EVENTOS
    this.onSelectionChanged_tblProveedor =
      this.onSelectionChanged_tblProveedor.bind(this);
    this.onToolbarPreparing_tblProveedores =
      this.onToolbarPreparing_tblProveedores.bind(this);
    this.onRowRemoving_tblProveedor =
      this.onRowRemoving_tblProveedor.bind(this);
    this.onRowRemoved_tblProveedor = this.onRowRemoved_tblProveedor.bind(this);
    this.render_tblPersonaContactoNProveedor =
      this.render_tblPersonaContactoNProveedor.bind(this);
    this.onToolbarPreparing_tblPersonaContactoNProveedor =
      this.onToolbarPreparing_tblPersonaContactoNProveedor.bind(this);

    //MÉTODOS
    this.crearProveedor = this.crearProveedor.bind(this);
    this.submit_formProveedores = this.submit_formProveedores.bind(this);
  }

  //REFERENCIAS
  get dxDataGrid_tblProveedor() {
    return this.dxDataGrid_tblProveedor_REF.current.instance;
  }

  get dxDataGrid_tblPersonaContactoNProveedor() {
    return this.dxDataGrid_tblPersonaContactoNProveedor_REF.current.instance;
  }

  get dxForm_tblProveedor() {
    return this.dxForm_tblProveedor_REF.current.instance;
  }

  //#region DATA SOURCE
  datasource_tblProveedor = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblProveedor",
      key: "idProveedor",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onInserted: (values, key) => {
        this.datasource_tblProveedor_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblProveedor_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    expand: [
      "tblPersonaContactoNProveedor,tblPais($select=idPais,denominacion)",
    ],
  });

  datasource_tblProveedor_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { proveedorSel } = this.state;
    proveedorSel.idProveedor = key;
    let proveedorSel_ = $.extend(true, {}, proveedorSel);

    this.dxDataGrid_tblProveedor.refresh().done(function () {
      _this.setState({
        proveedorSel: proveedorSel,
        proveedorSel_bak: proveedorSel_,
      });
    });
  }

  datasource_tblProveedor_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.dxDataGrid_tblProveedor.refresh();
  }

  datasource_tblPais = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });
  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    _this.loadPanel_show();
    $.when(
      _this.datasource_tblProveedor.load(),
      _this.datasource_tblPais.load(),
    ).then(function () {
      _this.crearProveedor();
      _this.loadPanel_hide();
    });
  }

  render() {
    let { proveedorSel } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="Proveedores" className="formContainer scrollbar-container">
            <Row className="pt-4 he-100">
              <Col xs="12" md="6">
                <form
                  onSubmit={this.submit_formProveedores}
                  className="d-flex flex-column he-100 w-100"
                >
                  <div className="text-left pb-3">
                    <Toolbar>
                      <ToolbarItem location="before">
                        <Button
                          text={getTrad("nuevoProveedor")}
                          icon="add"
                          onClick={this.crearProveedor}
                        />
                      </ToolbarItem>
                      <ToolbarItem location="after">
                        <Button
                          text={getTrad("actualizar")}
                          type="success"
                          icon="check"
                          useSubmitBehavior={true}
                          validationGroup="validationGroup_form_proveedor"
                          visible={
                            proveedorSel && proveedorSel.idProveedor
                              ? true
                              : false
                          }
                        />
                        <Button
                          text={getTrad("crearProveedor")}
                          type="success"
                          icon="check"
                          useSubmitBehavior={true}
                          validationGroup="validationGroup_form_proveedor"
                          visible={!(proveedorSel && proveedorSel.idProveedor)}
                        />
                      </ToolbarItem>
                    </Toolbar>
                  </div>
                  <div className="flex-grow-1">
                    <Form
                      height="100%"
                      formData={proveedorSel}
                      ref={this.dxForm_tblProveedor_REF}
                      labelLocation="top"
                      scrollingEnabled={true}
                      screenByWidth={this.screenByWidth}
                      colCountByScreen={this.colCountByScreen}
                      validationGroup="validationGroup_form_proveedor"
                    >
                      <Item
                        dataField="nombreComercial"
                        label={this.formLabel_nombreComercial}
                        colSpan={3}
                      >
                        <RequiredRule message={getTrad("campoNecesario")} />
                      </Item>
                      <Item
                        dataField="nombreFiscal"
                        label={this.formLabel_nombreFiscal}
                        colSpan={3}
                      />
                      <Item
                        dataField="CIF"
                        label={this.formLabel_CIF}
                        colSpan={1}
                        editorOptions={this.cif_editorOptions}
                      />
                      <Item
                        dataField="direccion"
                        label={this.formLabel_direccion}
                        colSpan={3}
                      />
                      <Item
                        dataField="poblacion"
                        label={this.formLabel_poblacion}
                        colSpan={3}
                      />
                      <Item
                        dataField="codigoPostal"
                        label={this.formLabel_codigoPostal}
                        colSpan={1}
                      />
                      <Item
                        dataField="idPais"
                        label={this.formLabel_idPais}
                        editorType="dxSelectBox"
                        editorOptions={this.idPais_editorOptions}
                        colSpan={2}
                      >
                        <RequiredRule message={getTrad("campoNecesario")} />
                      </Item>
                      <Item
                        dataField="telefono"
                        label={this.formLabel_telefono}
                        colSpan={2}
                      >
                        <ValidationRule
                          type="pattern"
                          pattern={phone_Pattern}
                          message={getTrad("validation_Formato")}
                        />
                      </Item>
                      <Item
                        dataField="telefono2"
                        label={this.formLabel_telefono2}
                        colSpan={2}
                      >
                        <ValidationRule
                          type="pattern"
                          pattern={phone_Pattern}
                          message={getTrad("validation_Formato")}
                        />
                      </Item>
                      <EmptyItem colSpan={1} />
                      <Item
                        dataField="email"
                        label={this.formLabel_email}
                        colSpan={2}
                      >
                        <EmailRule />
                      </Item>
                      <Item
                        dataField="paginaWeb"
                        label={this.formLabel_paginaWeb}
                        colSpan={2}
                      />
                      <Item
                        dataField="activo"
                        label={this.formLabel_activo}
                        colSpan={2}
                        editorType="dxSwitch"
                        editorOptions={this.activo_editorOptions}
                      />
                      <EmptyItem colSpan={2} />

                      <Item
                        dataField="tblPersonaContactoNProveedor"
                        colSpan={7}
                        render={this.render_tblPersonaContactoNProveedor}
                        label={this.formLabel_invisible}
                      />
                    </Form>
                  </div>
                </form>
              </Col>
              <Col xs="12" md="6" className="he-100">
                <DataGrid
                  //Datos
                  ref={this.dxDataGrid_tblProveedor_REF}
                  dataSource={this.datasource_tblProveedor}
                  //Propiedades
                  columnsAutoWidth={true}
                  height={"100%"}
                  width={"100%"}
                  hoverStateEnabled={true}
                  selection={this.tblProveedor_selection}
                  selectedRowKeys={
                    proveedorSel && proveedorSel.idProveedor
                      ? [proveedorSel.idProveedor]
                      : []
                  }
                  //Estilos
                  showColumnLines={false}
                  showRowLines={true}
                  rowAlternationEnabled={true}
                  onToolbarPreparing={this.onToolbarPreparing_tblProveedores}
                  onRowRemoving={this.onRowRemoving_tblProveedor}
                  onRowRemoved={this.onRowRemoved_tblProveedor}
                  onSelectionChanged={this.onSelectionChanged_tblProveedor}
                  onRowPrepared={this.onRowPrepared_tblProveedor}
                >
                  <SearchPanel visible={true} width={240} />
                  <HeaderFilter visible={true} />
                  <FilterRow visible={true} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={this.tblProveedor_allowedPageSizes}
                    showInfo={true}
                  />
                  <Paging defaultPageSize={50} />
                  <Column
                    dataField="activo"
                    visible={false}
                    sortIndex={1}
                    sortOrder="desc"
                  />
                  <Column
                    dataField="CIF"
                    caption={getTrad("cif").toUpperCase()}
                    width={100}
                    alignment="center"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="nombreComercial"
                    caption={getTrad("nombreComercial")}
                    alignment="left"
                    sortIndex={2}
                    sortOrder="asc"
                    allowHeaderFiltering={false}
                  />
                  <Column
                    dataField="tblPais.denominacion"
                    caption={getTrad("pais")}
                    width={160}
                    alignment="left"
                  />
                  <Column
                    caption=" "
                    width={50}
                    alignment="center"
                    cssClass="p-0"
                    cellComponent={removeCellComponent}
                  />
                </DataGrid>
              </Col>
            </Row>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  formLabel_invisible = { visible: false };
  formLabel_nombreComercial = { text: getTrad("nombreComercial") };
  formLabel_nombreFiscal = { text: getTrad("nombreFiscal") };
  formLabel_CIF = { text: getTrad("cif").toUpperCase() };
  formLabel_direccion = { text: getTrad("direccion") };
  formLabel_codigoPostal = { text: getTrad("codigoPostal") };
  formLabel_poblacion = { text: getTrad("poblacion") };
  formLabel_telefono = { text: getTrad("telefono") };
  formLabel_telefono2 = { text: getTrad("telefono") + " 2" };
  formLabel_email = { text: getTrad("email") };
  formLabel_idPais = { text: getTrad("pais") };
  formLabel_activo = { text: getTrad("activo") };
  formLabel_paginaWeb = { text: getTrad("paginaWeb") };

  idPais_editorOptions = {
    dataSource: this.datasource_tblPais,
    displayExpr: "denominacion",
    valueExpr: "idPais",
  };

  activo_editorOptions = {
    switchedOnText: getTrad("activo"),
    switchedOffText: getTrad("noActivo"),
  };

  cif_editorOptions = {
    inputAttr: { style: "text-transform: uppercase" },
  };

  colCountByScreen = { xl: 7, lg: 7, md: 7, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //CONFIG GRID
  tblProveedor_selection = { mode: "single" };
  tblProveedor_allowedPageSizes = [20, 50, 100];

  //#region EVENTOS
  onToolbarPreparing_tblProveedores(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("proveedores"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }
  onRowRemoving_tblProveedor(e) {
    this.datasource_tblProveedor.store().remove(e.key);
  }

  onRowRemoved_tblProveedor(e) {
    let { proveedorSel } = this.state;
    if (e.key === proveedorSel.idProveedor) {
      this.crearProveedor();
    }
  }

  onSelectionChanged_tblProveedor(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let dxForm_hasChanges = !(
        JSON.stringify(_this.state.proveedorSel) ===
        JSON.stringify(_this.state.proveedorSel_bak)
      );

      if (
        _this.dxDataGrid_tblPersonaContactoNProveedor.hasEditData() ||
        dxForm_hasChanges
      ) {
        if (_this.data.proveedorSel_validation_row === null)
          _this.data.proveedorSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              _this.dxDataGrid_tblPersonaContactoNProveedor.cancelEditData();

              let proveedorSel = $.extend(
                true,
                {},
                _this.data.proveedorSel_validation_row,
              );
              let proveedorSel_ = $.extend(true, {}, proveedorSel);

              _this.setState({
                proveedorSel: proveedorSel,
                proveedorSel_bak: proveedorSel_,
              });
              _this.data.proveedorSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.proveedorSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let proveedorSel = $.extend(true, {}, data),
          proveedorSel_bak = $.extend(true, {}, proveedorSel);
        _this.setState({
          proveedorSel: proveedorSel,
          proveedorSel_bak: proveedorSel_bak,
        });
      }
    }
  }

  onRowPrepared_tblProveedor(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  onToolbarPreparing_tblPersonaContactoNProveedor(e) {
    let _this = this;
    e.toolbarOptions.items.unshift({
      location: "before",
      toolbar: "bottom",
      text: getTrad("personasContacto"),
    });

    $.each(e.toolbarOptions.items, function (index, item) {
      switch (item.name) {
        case "addRowButton":
          item.location = "after";
          item.showText = "always";
          item.options.text = getTrad("añadirPersona");
          item.options.icon = "plus";
          item.options.onClick = function () {
            _this.dxDataGrid_tblPersonaContactoNProveedor
              .addRow()
              .done(function () {
                setTimeout(() => {
                  _this.dxDataGrid_tblPersonaContactoNProveedor.editCell(
                    0,
                    "nombre",
                  );
                }, 100);
              });
          };
          break;
        default:
          break;
      }
    });
  }

  render_tblPersonaContactoNProveedor = (data) => {
    let proveedorSel = data.component.option("formData");

    return (
      <DataGrid
        //Datos
        dataSource={proveedorSel.tblPersonaContactoNProveedor}
        ref={this.dxDataGrid_tblPersonaContactoNProveedor_REF}
        //Propiedades
        columnsAutoWidth={true}
        height={300}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onToolbarPreparing={
          this.onToolbarPreparing_tblPersonaContactoNProveedor
        }
      >
        <KeyboardNavigation
          enterKeyAction="moveFocus"
          enterKeyDirection="row"
          editOnKeyPress={true}
        />
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing
          mode="cell"
          allowUpdating={true}
          allowAdding={true}
          selectTextOnEditStart={true}
        />
        <Column dataField="nombre" caption={getTrad("nombre")} alignment="left">
          <RequiredRule />
        </Column>
        <Column
          dataField="apellidos"
          caption={getTrad("apellidos")}
          alignment="left"
        ></Column>
        <Column
          dataField="telefono"
          caption={getTrad("telefono")}
          width={125}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="telefono2"
          caption={getTrad("telefono") + " 2"}
          width={125}
          alignment="left"
        ></Column>
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  //#endregion

  crearProveedor() {
    let proveedorSel = {};

    proveedorSel.nombreComercial = undefined;
    proveedorSel.nombreFiscal = "";
    proveedorSel.CIF = "";
    proveedorSel.direccion = "";
    proveedorSel.poblacion = "";
    proveedorSel.codigoPostal = "";
    proveedorSel.telefono = "";
    proveedorSel.telefono2 = "";
    proveedorSel.email = "";
    proveedorSel.paginaWeb = "";
    proveedorSel.idPais = undefined;
    proveedorSel.tblPersonaContactoNProveedor = [];
    proveedorSel.activo = true;

    let proveedorSel_ = $.extend(true, {}, proveedorSel);
    this.setState({
      proveedorSel: proveedorSel,
      proveedorSel_bak: $.extend(true, {}, proveedorSel_),
    });
  }

  submit_formProveedores(e) {
    e.preventDefault();

    let _this = this;
    this.dxDataGrid_tblPersonaContactoNProveedor
      .getController("validating")
      .validate(true)
      .done(function (tblPersonaContactoNProveedor_isValid) {
        if (tblPersonaContactoNProveedor_isValid) {
          let { proveedorSel } = _this.state;
          let proveedorSel_ = $.extend(true, {}, proveedorSel);

          $.each(
            proveedorSel_.tblPersonaContactoNProveedor,
            function (index, proveedor) {
              delete proveedor.__KEY__;
            },
          );
          delete proveedorSel_.tblPais;

          if (proveedorSel_.idProveedor) {
            //UPDATE, eliminamos el parte trabajo (Original) que se acaba de modificar
            _this.datasource_tblProveedor
              .store()
              .update(proveedorSel_.idProveedor, proveedorSel_)
              .done(function () {
                _this.setState({
                  proveedorSel: proveedorSel_,
                  proveedorSel_bak: proveedorSel_,
                });
              });
          } else {
            //INSERT
            _this.datasource_tblProveedor.store().insert(proveedorSel_);
          }
        }
      });
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps) {}
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Proveedores);
