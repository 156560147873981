import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

import DataGrid from "./components/DataGrid";
import Popup from "./components/Popup";
import { authHeader, errorHandler, getNombreFormulario, getTrad, patchRequestHandler } from "helpers";
import { connectionConstants } from "constants";
import PageTitle from "layout/AppMain/PageTitle";

const addAuthHeader = (request) => (request.headers = { ...authHeader() });

const Clientes = ({ empresa, idioma, user }) => {
    const [cliente, setCliente] = useState(null);
    const [visible, setVisible] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [idMonedaFilter, setIdMonedaFilter] = useState(null);

    const [tblPais, setTblPais] = useState([]);
    const [tblMoneda, setTblMoneda] = useState([]);
    const [tblAdmCentroCoste, setTblAdmCentroCoste] = useState([]);
    const [tblAdmElementoPEP, setTblAdmElementoPEP] = useState([]);
    const [tblAdmFormaPago, setTblAdmFormaPago] = useState([]);
    const [tblAdmCondicionPago, setTblAdmCondicionPago] = useState([]);
    const [tblAdmCuentaBancaria, setTblAdmCuentaBancaria] = useState([]);
    const [tblIvaNPais, setTblIvaNPais] = useState([]);

    useEffect(() => {
        datasource_tblPais.load().done(setTblPais);
        datasource_tblMoneda.load().done(setTblMoneda);
        datasource_tblAdmCondicionPago.load().done(setTblAdmCondicionPago);
    }, []);

    useEffect(() => {
        datasource_tblAdmCliente.reload();
        datasource_tblAdmCentroCoste.reload().done(setTblAdmCentroCoste);
        datasource_tblAdmElementoPEP.reload().done(setTblAdmElementoPEP);
        datasource_tblAdmFormaPago.reload().done(setTblAdmFormaPago);
        datasource_tblAdmCuentaBancaria.reload().done(setTblAdmCuentaBancaria);
        datasource_tblIvaNPais.reload().done(setTblIvaNPais);
    }, [empresa]);

    useEffect(() => {
        datasource_tblAdmCuentaBancaria.reload().done(setTblAdmCuentaBancaria);
    }, [idMonedaFilter]);

    const heading = getNombreFormulario({ props: { idioma, user } });

    const datasource_tblAdmCliente = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCliente",
            key: "idAdmCliente",
            errorHandler,
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            version: 4,
        }),
        filter: [["isEliminado", "=", false], "and", ["idEmpresaPolarier", "=", empresa.idEmpresaPolarier]],
    });

    const datasource_tblPais = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
            key: "idPais",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idPais", "denominacion"],
        sort: ["denominacion"],
    });

    const datasource_tblMoneda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMoneda",
            key: "idMoneda",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idMoneda", "codigo"],
    });

    const datasource_tblAdmCentroCoste = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCentroCoste",
            key: "idAdmCentroCoste",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idAdmCentroCoste", "denominacion", "codigo"],
        filter: [["isEliminado", "=", false], "and", ["idEmpresaPolarier", "=", empresa.idEmpresaPolarier]],
        sort: ["denominacion", "codigo"],
        postProcess: (data) => {
            return data.map((item) => ({
                ...item,
                denominacion: `${item.codigo} - ${item.denominacion}`,
            }));
        },
    });

    const datasource_tblAdmElementoPEP = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
            key: "idAdmElementoPEP",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idAdmElementoPEP", "denominacion", "codigo"],
        filter: [["isEliminado", "=", false], "and", ["idEmpresaPolarier", "=", empresa.idEmpresaPolarier]],
        sort: ["denominacion", "codigo"],
        postProcess: (data) => {
            return data.map((item) => ({
                ...item,
                denominacion: `${item.codigo} - ${item.denominacion}`,
            }));
        },
    });

    const datasource_tblAdmFormaPago = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFormaPago",
            key: "idAdmFormaPago",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idAdmFormaPago", "denominacion"],
        filter: ["idPais", "=", empresa.idPais],
        sort: ["denominacion"],
    });

    const datasource_tblAdmCondicionPago = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCondicionPago",
            key: "idAdmCondicionPago",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idAdmCondicionPago", "denominacionCondicion", "denominacionTermino"],
        sort: ["denominacionCondicion", "denominacionTermino"],
        postProcess: (data) => {
            return data.map((item) => ({
                ...item,
                denominacion: `${item.denominacionCondicion} - ${item.denominacionTermino}`,
            }));
        },
    });

    const onLoading = (loadOptions) => {
        if (!idMonedaFilter) return;

        const filter = ["idMoneda", "=", idMonedaFilter];

        if (loadOptions.filter.length > 0) {
            loadOptions.filter = [loadOptions.filter, "and", filter];
        } else {
            loadOptions.filter = filter;
        }
    };

    const datasource_tblAdmCuentaBancaria = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaBancaria",
            key: "idCuentaBancaria",
            errorHandler,
            beforeSend: addAuthHeader,
            onLoading,
            version: 4,
        }),
        select: ["idCuentaBancaria", "denominacion", "idMoneda"],
        filter: ["idEmpresaPolarier", "=", empresa.idEmpresaPolarier],
        sort: ["denominacion"],
    });

    const datasource_tblIvaNPais = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblIvaNPais",
            key: "idIvaNPais",
            errorHandler,
            beforeSend: addAuthHeader,
            version: 4,
        }),
        select: ["idIvaNPais", "indicadorIVA_Venta", "descripcionIVA_Venta"],
        expand: ["idAdmIvaNavigation($select=denominacion)"],
        filter: [["indicadorIVA_Venta", "<>", null], "and", ["idPais", "=", empresa.idPais]],
        sort: ["idAdmIva"],
        postProcess: (data) => {
            return data.map((item) => ({
                ...item,
                denominacion: `${item.idAdmIvaNavigation.denominacion} ${
                    item.descripcionIVA_Venta ? `${item.descripcionIVA_Venta} ` : ""
                }(${item.indicadorIVA_Venta})`,
            }));
        },
    });

    const onSetCliente = (cliente) => {
        cliente.codigoRetencion = cliente.codigoRetencion * 100;
        setIdMonedaFilter(cliente.idMoneda);
        setCliente(cliente);
        setVisible(true);
    };

    const onSave = (data) => {
        setIsSaving(true);

        data.codigoRetencion = data.codigoRetencion / 100;

        datasource_tblAdmCliente
            .store()
            .update(data.idAdmCliente, patchRequestHandler(data))
            .then(() => {
                notify({
                    message: getTrad("aviso_C_RegistroActualizado"),
                    type: "success",
                    displayTime: 1500,
                    closeOnClick: true,
                });
                datasource_tblAdmCliente.reload();
                setIsSaving(false);
                setIdMonedaFilter(null);
                setVisible(false);
            })
            .catch(() => {
                setIsSaving(false);
                notify({
                    message: getTrad("aviso_RegistroNoActualizado"),
                    type: "error",
                    displayTime: 1500,
                    closeOnClick: true,
                });
            });
    };

    const onHiding = () => setVisible(false);

    const dataSources = {
        tblPais,
        tblMoneda,
        tblAdmCentroCoste,
        tblAdmElementoPEP,
        tblAdmFormaPago,
        tblAdmCondicionPago,
        tblAdmCuentaBancaria,
        tblIvaNPais,
    };

    return (
        <>
            <PageTitle heading={heading} />
            <div className="media-body">
                <div className="formContainer">
                    <DataGrid
                        dataSource={datasource_tblAdmCliente}
                        dataSources={dataSources}
                        setCliente={onSetCliente}
                        user={user}
                    />
                </div>
                <Popup
                    visible={visible}
                    formData={cliente}
                    dataSources={dataSources}
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    setIdMonedaFilter={setIdMonedaFilter}
                    onSave={onSave}
                    onHiding={onHiding}
                    empresa={empresa}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    empresa: state.Global.empresa,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

export default connect(mapStateToProps)(Clientes);
