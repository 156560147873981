import React from "react";
import { Redirect } from "react-router-dom";

import { connectionConstants } from "../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    dxMensajePregunta,
    formatDate,
    tooltipControl_creacion,
    getCierresFacturacion,
    getDaysInRange,
    formatDate_noTime_parameter,
} from "../../../helpers";
import { svg_elemTrans_ud, svg_lock_outline } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
    var deferred = $.Deferred();

    var abonoSel = null;
    var guardando = false; //Variable que sirve para pasar la validación de HasChanges y no tener que hacer un cancelEdit y verse todo a 0 cuando se guarda
    var idEntidadSel = null;
    var fechaSel = new Date();
    var isCerradoFactEntidad = false;
    var dxDateBox_disabledDates = [];
    var idsEntidad_disabled = [];

    //#region DATASOURCES

    var datasource_compañias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idCompañia", "denominacion"],
        postProcess: function (data) {
            if (datasource_compañias.items().length === 0) {
                data.splice(0, 0, { idCompañia: 0, denominacion: "TODAS" });
            }
            return data;
        },
    });

    var datasource_entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idEntidad", "denominacion", "idCompañia"],
        postProcess: function (data) {
            data.forEach((dataItem) => {
                dataItem.disabled =
                    idsEntidad_disabled.filter((idEntidad) => idEntidad == dataItem.idEntidad).length > 0;
            });
            return data;
        },
    });

    var datasource_prendaAbono = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "abonos_spSelectPrendasNuevoAbono",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                if (abonoSel) {
                    request.params.idAbono = abonoSel.idAbono;

                    if (request.method !== "get") {
                        request.url = request.url.replace("abonos_spSelectPrendasNuevoAbono", "tblPrendaNAbono");
                    } else {
                        request.url = request.url.replace("abonos_spSelectPrendasNuevoAbono", "spSelectPrendaNAbono");
                    }
                } else {
                    request.params.idEntidad = null;

                    if (typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined") {
                        request.params.idEntidad = $("#dxDropDownBox_selCompaEnti")
                            .dxDropDownBox("instance")
                            .option("value");
                    }
                }
            },
            version: 4,
        }),
        select: [
            "idPrenda",
            "codigoPrenda",
            "denoPrenda",
            "codigoElemTransPedido",
            "denoElemTransPedido",
            "cantidad",
            "codigoHexadecimal",
            "denoMarcaTapa",
            "isEntidad",
        ],
    });

    var datasource_añadirPrenda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectPrendasNuevoPedido",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idEntidad = null;
                request.params.idPedido = null;
                if (typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined") {
                    request.params.idEntidad = $("#dxDropDownBox_selCompaEnti")
                        .dxDropDownBox("instance")
                        .option("value");
                }
            },
            version: 4,
        }),
        select: [
            "idPrenda",
            "codigoPrenda",
            "denoPrenda",
            "codigoElemTransPedido",
            "denoElemTransPedido",
            "udsXBacPedido",
            "cantidad",
            "codigoHexadecimal",
            "denoMarcaTapa",
        ],
        postProcess: function (data) {
            var grid = $("#dgvNuevoAbono").dxDataGrid("instance");

            return $.grep(data, function (v) {
                return grid.getRowIndexByKey(v.idPrenda) === -1;
            });
        },
    });

    var datasource_abonosAnteriores = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectAbonos",
            key: "idAbono",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                if (request.method !== "get") {
                    request.url = request.url.replace("spSelectAbonos", "tblAbono");
                }

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        // select: [
        //     "idAbono",
        //     "codigo",
        //     "fecha",
        //     "idEntidad",
        //     "denoEntidad",
        //     "observaciones",
        //     "idTipoAbono",
        //     "isCerradoFactEntidad"
        // ],
    });

    var datasource_tipoAbono = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoAbono",
            key: "idTipoAbono",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: ["idTipoAbono", "denominacion"],
    });

    var datasource_categoriaAbono = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaAbono",
            key: "idCategoriaAbono",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idCategoriaAbono", "denominacion"],
    });

    //#endregion

    //#region Cierre Facturación Entidad
    function deshabilitarEdicionCierreFact(idEntidad, fechaDesde, fechaHasta) {
        return new Promise((resolve, reject) => {
            getCierresFacturacion(idEntidad, fechaDesde, fechaHasta)
                .then((data) => {
                    // Comprobación si entidad actual con la fecha seleccionada es editable
                    isCerradoFactEntidad = data.length > 0;

                    let dgvNuevoAbono = $("#dgvNuevoAbono").dxDataGrid("instance");
                    dgvNuevoAbono.option("editing.allowUpdating", !isCerradoFactEntidad);
                    dgvNuevoAbono.columnOption("cantidad", "cssClass", !isCerradoFactEntidad && "dx-Cell_Editable");

                    deshabilitarFechasFacturacion(idEntidad, fechaDesde);
                    resolve(data);
                })
                .catch(reject);
        });
    }

    function deshabilitarFechasFacturacion(idEntidad, fechaDesde) {
        dxDateBox_disabledDates = [];
        return new Promise((resolve, reject) => {
            if (idEntidad) {
                getCierresFacturacion(idEntidad, null, null)
                    .then((data) => {
                        //region deshabilitar días
                        let disabledDates = [];
                        data.forEach((item) => {
                            disabledDates.push(...getDaysInRange(new Date(item.fechaDesde), new Date(item.fechaHasta)));
                        });
                        dxDateBox_disabledDates = [...disabledDates];

                        //Aplicar fechaFiltrada
                        $("#dxDateBox_dbFechaAbono").dxDateBox("instance").option({
                            value: fechaDesde,
                            disabledDates: dxDateBox_disabledDates,
                        });
                        //#endregion
                        resolve(data);
                    })
                    .catch(reject);
            } else {
                $("#dxDateBox_dbFechaAbono").dxDateBox("instance").option({
                    value: fechaDesde,
                    disabledDates: [],
                });
                resolve();
            }
        });
    }

    function deshabilitarEntidadesFacturacion(fechaDesde, fechaHasta) {
        idsEntidad_disabled = [];
        getCierresFacturacion(null, fechaDesde, fechaHasta).then(function (entidadesDisabled) {
            idsEntidad_disabled = [...entidadesDisabled.map((x) => x.idEntidad)];
            datasource_entidades.reload().done(function (items) {
                if ($("#dxDropDownBox_lista_entidades").dxList("instance"))
                    $("#dxDropDownBox_lista_entidades").dxList("instance").option("dataSource", items);
            });
        });
    }
    deshabilitarEntidadesFacturacion(new Date(), new Date());
    //#endregion

    $("#dxTooltip_Abonos").dxTooltip({
        showEvent: "mouseenter",
        hideEvent: "mouseleave",
    });

    COMPONENT.setReport({
        denominacion: "albaran_abonos",
        parameterEvent: CustomizeParameterEditors,
    });

    $.when(
        datasource_entidades.load(),
        datasource_compañias.load(),
        datasource_tipoAbono.load(),
        datasource_categoriaAbono.load()
    )
        .then(function () {
            datasource_entidades.load().done(function (e) {
                datasource_tipoAbono.load().done(function (e) {
                    if (datasource_tipoAbono.items().length === 0) {
                        dxMensajePregunta(getTrad("alerta_error_noTipoAbono"), [
                            [
                                getTrad("aceptar"),
                                function (e) {
                                    return <Redirect to="/" />;
                                },
                                "danger",
                                "btnErrorTipoAbono",
                            ],
                        ]);
                        return;
                    }

                    if (datasource_categoriaAbono.items().length === 0) {
                        dxMensajePregunta(getTrad("alerta_error_noCategoriaAbono"), [
                            [
                                getTrad("aceptar"),
                                function (e) {
                                    return <Redirect to="/" />;
                                },
                                "danger",
                                "btnErrorTipoAbono",
                            ],
                        ]);
                        return;
                    }

                    $("#Abonos #dxContainer").dxBox({
                        direction: "row",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        width: "100%",
                        items: [
                            {
                                ratio: 5,
                                template: function (e, index, item) {
                                    item.css("padding-right", "30px");
                                    item.append(
                                        $("<div/>").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            width: "100%",
                                            items: [
                                                {
                                                    baseSize: 45,
                                                    template: function (e, index, item) {
                                                        item.append(
                                                            $("<div id='dxToolbar_dgvNuevoAbono' />").dxToolbar({
                                                                items: [
                                                                    {
                                                                        location: "before",
                                                                        template: function () {
                                                                            var container = $("<div>");
                                                                            var dxDropDownBox = $(
                                                                                "<div id='dxDropDownBox_selCompaEnti' style='float:left'>"
                                                                            ).dxDropDownBox({
                                                                                dataSource: datasource_entidades,
                                                                                valueExpr: "idEntidad",
                                                                                displayExpr: "denominacion",
                                                                                width: 280,
                                                                                value: !abonoSel
                                                                                    ? null
                                                                                    : abonoSel.idEntidad,
                                                                                disabled: !abonoSel ? false : true,
                                                                                deferRendering: false,
                                                                                remoteOperations: true,
                                                                                placeholder: getTrad("entidades"),
                                                                                // EVENTS
                                                                                onOpened: function (e) {
                                                                                    if (nuevoAbono_HasChanges()) {
                                                                                        e.component.close();

                                                                                        $(
                                                                                            "#popup_alert_AceptarCancelar"
                                                                                        )
                                                                                            .dxPopup("instance")
                                                                                            .show();
                                                                                        $("#btnAceptar")
                                                                                            .dxButton("instance")
                                                                                            .option(
                                                                                                "onClick",
                                                                                                function () {
                                                                                                    var grid =
                                                                                                        $(
                                                                                                            "#dgvNuevoAbono"
                                                                                                        ).dxDataGrid(
                                                                                                            "instance"
                                                                                                        );
                                                                                                    grid.option(
                                                                                                        "editing.allowDeleting",
                                                                                                        abonoSel
                                                                                                    );
                                                                                                    grid.cancelEditData();
                                                                                                    grid.refresh(true);
                                                                                                    grid.repaint();
                                                                                                    e.component.open();
                                                                                                    $(
                                                                                                        "#popup_alert_AceptarCancelar"
                                                                                                    )
                                                                                                        .dxPopup(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .hide();
                                                                                                }
                                                                                            );
                                                                                    }
                                                                                },
                                                                                onClosed: function (e) {
                                                                                    if (
                                                                                        $(
                                                                                            "#dxDropDownBox_lista_entidades"
                                                                                        ).data("dxList")
                                                                                    )
                                                                                        $(
                                                                                            "#dxDropDownBox_lista_entidades"
                                                                                        )
                                                                                            .dxList("instance")
                                                                                            .option("searchValue", "");
                                                                                    if (
                                                                                        $(
                                                                                            "#dxDropDownBox_lista_compañias"
                                                                                        ).data("dxList")
                                                                                    )
                                                                                        $(
                                                                                            "#dxDropDownBox_lista_compañias"
                                                                                        )
                                                                                            .dxList("instance")
                                                                                            .option("searchValue", "");
                                                                                },
                                                                                dropDownOptions: {
                                                                                    width: 800,
                                                                                    height: 500,
                                                                                    position: {
                                                                                        my: "center",
                                                                                        at: "center",
                                                                                        of: window,
                                                                                    },
                                                                                    closeOnOutsideClick: true,
                                                                                },
                                                                                contentTemplate: function (
                                                                                    dxDropDownBox
                                                                                ) {
                                                                                    return $("<div>").dxBox({
                                                                                        direction: "row",
                                                                                        align: "space-around",
                                                                                        crossAlign: "stretch",
                                                                                        items: [
                                                                                            {
                                                                                                ratio: 1,
                                                                                                template: function () {
                                                                                                    // COMPAÑÍAS
                                                                                                    var container =
                                                                                                        $("<div>");
                                                                                                    var title = $(
                                                                                                        "<div class='font-size' style='margin-left:9px;'>" +
                                                                                                            getTrad(
                                                                                                                "compañia"
                                                                                                            ) +
                                                                                                            "</div>"
                                                                                                    );
                                                                                                    var lista_compañias =
                                                                                                        $(
                                                                                                            "<div id='dxDropDownBox_lista_compañias'>"
                                                                                                        ).dxList({
                                                                                                            dataSource:
                                                                                                                datasource_compañias.items(),
                                                                                                            selectionMode:
                                                                                                                "single",
                                                                                                            height: "375px",
                                                                                                            width: "95%",
                                                                                                            keyExpr:
                                                                                                                "idCompañia",
                                                                                                            scrolling: {
                                                                                                                mode: "infinite",
                                                                                                            },
                                                                                                            pageLoadMode:
                                                                                                                "scrollBottom",
                                                                                                            searchEnabled: true,
                                                                                                            searchExpr:
                                                                                                                [
                                                                                                                    "denominacion",
                                                                                                                ],
                                                                                                            // EVENTOS
                                                                                                            onContentReady:
                                                                                                                function (
                                                                                                                    e
                                                                                                                ) {
                                                                                                                    // SELECCIONA EL ITEM 0 POR DEFECTO
                                                                                                                    e.component.selectItem(
                                                                                                                        0
                                                                                                                    );
                                                                                                                },
                                                                                                            onSelectionChanged:
                                                                                                                function (
                                                                                                                    e
                                                                                                                ) {
                                                                                                                    var lista_entidades =
                                                                                                                        $(
                                                                                                                            "#dxDropDownBox_lista_entidades"
                                                                                                                        ).dxList(
                                                                                                                            "instance"
                                                                                                                        );

                                                                                                                    var entidadesFiltradas =
                                                                                                                        datasource_entidades.items();
                                                                                                                    if (
                                                                                                                        e
                                                                                                                            .addedItems
                                                                                                                            .length >
                                                                                                                            0 &&
                                                                                                                        e
                                                                                                                            .addedItems[0]
                                                                                                                            .idCompañia !==
                                                                                                                            0
                                                                                                                    ) {
                                                                                                                        // SI EL ITEM SELECCIONADO NO ES TODOS
                                                                                                                        entidadesFiltradas =
                                                                                                                            datasource_entidades
                                                                                                                                .items()
                                                                                                                                .filter(
                                                                                                                                    (
                                                                                                                                        x
                                                                                                                                    ) =>
                                                                                                                                        x.idCompañia ===
                                                                                                                                        e
                                                                                                                                            .addedItems[0]
                                                                                                                                            .idCompañia
                                                                                                                                );
                                                                                                                    }

                                                                                                                    if (
                                                                                                                        lista_entidades !==
                                                                                                                        undefined
                                                                                                                    ) {
                                                                                                                        lista_entidades.option(
                                                                                                                            "dataSource",
                                                                                                                            entidadesFiltradas
                                                                                                                        );
                                                                                                                    }
                                                                                                                },
                                                                                                            itemTemplate:
                                                                                                                function (
                                                                                                                    data,
                                                                                                                    index
                                                                                                                ) {
                                                                                                                    var result =
                                                                                                                        $(
                                                                                                                            "<div>"
                                                                                                                        );
                                                                                                                    $(
                                                                                                                        "<div>"
                                                                                                                    )
                                                                                                                        .text(
                                                                                                                            data.denominacion
                                                                                                                        )
                                                                                                                        .appendTo(
                                                                                                                            result
                                                                                                                        );

                                                                                                                    return result;
                                                                                                                },
                                                                                                        });

                                                                                                    title.appendTo(
                                                                                                        container
                                                                                                    );
                                                                                                    lista_compañias.appendTo(
                                                                                                        container
                                                                                                    );

                                                                                                    return container;
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                // ENTIDADES
                                                                                                ratio: 1,
                                                                                                template: function () {
                                                                                                    var container =
                                                                                                        $("<div>");
                                                                                                    var title = $(
                                                                                                        "<div class='font-size' style='margin-left:9px;'>" +
                                                                                                            getTrad(
                                                                                                                "entidad"
                                                                                                            ) +
                                                                                                            "</div>"
                                                                                                    );
                                                                                                    var lista_entidades =
                                                                                                        $(
                                                                                                            "<div id='dxDropDownBox_lista_entidades' class='dxList-pe-auto'>"
                                                                                                        ).dxList({
                                                                                                            //Datos
                                                                                                            dataSource:
                                                                                                                datasource_entidades.items(),
                                                                                                            selectionMode:
                                                                                                                "single",
                                                                                                            keyExpr:
                                                                                                                "idEntidad",
                                                                                                            height: "375px",
                                                                                                            scrolling: {
                                                                                                                mode: "infinite",
                                                                                                            },
                                                                                                            pageLoadMode:
                                                                                                                "scrollBottom",
                                                                                                            searchEnabled: true,
                                                                                                            searchExpr:
                                                                                                                [
                                                                                                                    "denominacion",
                                                                                                                ],
                                                                                                            //EVENTOS
                                                                                                            onSelectionChanged:
                                                                                                                function (
                                                                                                                    e
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        typeof e
                                                                                                                            .addedItems[0] !==
                                                                                                                        "undefined"
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            dxDropDownBox.component.option(
                                                                                                                                "value"
                                                                                                                            ) !==
                                                                                                                            e
                                                                                                                                .addedItems[0]
                                                                                                                                .idEntidad
                                                                                                                        ) {
                                                                                                                            idEntidadSel =
                                                                                                                                e
                                                                                                                                    .addedItems[0]
                                                                                                                                    .idEntidad;
                                                                                                                            dxDropDownBox.component.option(
                                                                                                                                "value",
                                                                                                                                idEntidadSel
                                                                                                                            );
                                                                                                                        }
                                                                                                                        deshabilitarEdicionCierreFact(
                                                                                                                            idEntidadSel,
                                                                                                                            fechaSel,
                                                                                                                            fechaSel
                                                                                                                        );
                                                                                                                    }
                                                                                                                },
                                                                                                            itemTemplate:
                                                                                                                function (
                                                                                                                    data,
                                                                                                                    index,
                                                                                                                    itemElement
                                                                                                                ) {
                                                                                                                    let isDisabled =
                                                                                                                        data.disabled ==
                                                                                                                        true;
                                                                                                                    let tooltip =
                                                                                                                        $(
                                                                                                                            "#dxTooltip_Abonos"
                                                                                                                        ).dxTooltip(
                                                                                                                            "instance"
                                                                                                                        );

                                                                                                                    let div =
                                                                                                                        $(
                                                                                                                            "<div>"
                                                                                                                        )
                                                                                                                            .addClass(
                                                                                                                                "id_" +
                                                                                                                                    data.idEntidad
                                                                                                                            )
                                                                                                                            .text(
                                                                                                                                data.denominacion
                                                                                                                            )
                                                                                                                            .on(
                                                                                                                                "mouseout",
                                                                                                                                function () {
                                                                                                                                    tooltip.hide();
                                                                                                                                }
                                                                                                                            );

                                                                                                                    if (
                                                                                                                        isDisabled
                                                                                                                    ) {
                                                                                                                        $(
                                                                                                                            itemElement
                                                                                                                        ).on(
                                                                                                                            "mouseover",
                                                                                                                            function () {
                                                                                                                                tooltip.option(
                                                                                                                                    {
                                                                                                                                        visible:
                                                                                                                                            isDisabled,
                                                                                                                                        target:
                                                                                                                                            ".id_" +
                                                                                                                                            data.idEntidad,
                                                                                                                                        contentTemplate:
                                                                                                                                            function (
                                                                                                                                                contentElement
                                                                                                                                            ) {
                                                                                                                                                contentElement.html(
                                                                                                                                                    getTrad(
                                                                                                                                                        "factBloqueadaEntiFecha"
                                                                                                                                                    )
                                                                                                                                                );
                                                                                                                                            },
                                                                                                                                    }
                                                                                                                                );
                                                                                                                                tooltip.show();
                                                                                                                            }
                                                                                                                        );
                                                                                                                    } else {
                                                                                                                        tooltip.option(
                                                                                                                            {
                                                                                                                                showEvent:
                                                                                                                                    null,
                                                                                                                                hideEvent:
                                                                                                                                    null,
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                    $(
                                                                                                                        itemElement
                                                                                                                    ).on(
                                                                                                                        "mouseout",
                                                                                                                        function () {
                                                                                                                            tooltip.hide();
                                                                                                                        }
                                                                                                                    );
                                                                                                                    div.appendTo(
                                                                                                                        itemElement
                                                                                                                    ).text(
                                                                                                                        data.denominacion
                                                                                                                    );
                                                                                                                },
                                                                                                        });

                                                                                                    dxDropDownBox.component.on(
                                                                                                        "valueChanged",
                                                                                                        function (
                                                                                                            args
                                                                                                        ) {
                                                                                                            if (
                                                                                                                $(
                                                                                                                    "#dxDropDownBox_lista_entidades"
                                                                                                                )
                                                                                                                    .dxList(
                                                                                                                        "instance"
                                                                                                                    )
                                                                                                                    .option(
                                                                                                                        "selectedItemKeys"
                                                                                                                    )[0] !==
                                                                                                                args.value
                                                                                                            ) {
                                                                                                                $(
                                                                                                                    "#dxDropDownBox_lista_entidades"
                                                                                                                )
                                                                                                                    .dxList(
                                                                                                                        "instance"
                                                                                                                    )
                                                                                                                    .option(
                                                                                                                        "selectedItemKeys",
                                                                                                                        [
                                                                                                                            args.value,
                                                                                                                        ]
                                                                                                                    );
                                                                                                            }
                                                                                                            var grid =
                                                                                                                $(
                                                                                                                    "#dgvNuevoAbono"
                                                                                                                ).dxDataGrid(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            grid.option(
                                                                                                                "editing.allowDeleting",
                                                                                                                abonoSel
                                                                                                            );
                                                                                                            grid.cancelEditData();
                                                                                                            grid.refresh(
                                                                                                                true
                                                                                                            );

                                                                                                            args.component.close();
                                                                                                        }
                                                                                                    );

                                                                                                    title.appendTo(
                                                                                                        container
                                                                                                    );
                                                                                                    lista_entidades.appendTo(
                                                                                                        container
                                                                                                    );

                                                                                                    return container;
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            });
                                                                            dxDropDownBox.appendTo(container);
                                                                            return container;
                                                                        },
                                                                    },
                                                                    {
                                                                        location: "before",
                                                                        widget: "dxSelectBox",
                                                                        options: {
                                                                            elementAttr: {
                                                                                id: "dxSelectBox_tipoAbono",
                                                                            },
                                                                            dataSource: datasource_tipoAbono,
                                                                            valueExpr: "idTipoAbono",
                                                                            displayExpr: "denominacion",
                                                                            value: datasource_tipoAbono.items()[0]
                                                                                .idTipoAbono,
                                                                            width: "180px",
                                                                        },
                                                                    },
                                                                    {
                                                                        location: "before",
                                                                        widget: "dxSelectBox",
                                                                        options: {
                                                                            placeholder: "Sel. Categoría del abono",
                                                                            elementAttr: {
                                                                                id: "dxSelectBox_categoriaAbono",
                                                                            },
                                                                            dataSource: datasource_categoriaAbono,
                                                                            valueExpr: "idCategoriaAbono",
                                                                            value: datasource_categoriaAbono.items()[0]
                                                                                .idCategoriaAbono,
                                                                            displayExpr: "denominacion",
                                                                            width: "210",
                                                                        },
                                                                    },
                                                                    {
                                                                        location: "after",
                                                                        widget: "dxDateBox",
                                                                        options: {
                                                                            elementAttr: {
                                                                                id: "dxDateBox_dbFechaAbono",
                                                                            },
                                                                            displayFormat: "dd/MM/yyyy",
                                                                            value: new Date(),
                                                                            width: 120,
                                                                            disabledDates: dxDateBox_disabledDates,
                                                                            calendarOptions: {
                                                                                cellTemplate: function (e) {
                                                                                    let tooltip =
                                                                                        $(
                                                                                            "#dxTooltip_Abonos"
                                                                                        ).dxTooltip("instance");
                                                                                    let result = $("<div>")
                                                                                        .addClass(
                                                                                            "id_" +
                                                                                                formatDate_noTime_parameter(
                                                                                                    e.date
                                                                                                )
                                                                                        )
                                                                                        .text(e.text)
                                                                                        .on("mouseenter", function () {
                                                                                            let fechasDeshabilitadas =
                                                                                                dxDateBox_disabledDates.filter(
                                                                                                    (fecha) =>
                                                                                                        formatDate_noTime_parameter(
                                                                                                            fecha
                                                                                                        ) ==
                                                                                                        formatDate_noTime_parameter(
                                                                                                            e.date
                                                                                                        )
                                                                                                );
                                                                                            if (
                                                                                                fechasDeshabilitadas.length >
                                                                                                0
                                                                                            ) {
                                                                                                tooltip.option({
                                                                                                    target:
                                                                                                        ".id_" +
                                                                                                        formatDate_noTime_parameter(
                                                                                                            e.date
                                                                                                        ),
                                                                                                    contentTemplate:
                                                                                                        function (
                                                                                                            contentElement
                                                                                                        ) {
                                                                                                            contentElement.html(
                                                                                                                getTrad(
                                                                                                                    "factBloqueadaEntiFecha"
                                                                                                                )
                                                                                                            );
                                                                                                        },
                                                                                                });
                                                                                                tooltip.show();
                                                                                            }
                                                                                        })
                                                                                        .on("mouseleave", function () {
                                                                                            tooltip.hide();
                                                                                        });

                                                                                    return result;
                                                                                },
                                                                            },
                                                                            onValueChanged: function (e) {
                                                                                fechaSel = e.value;
                                                                                deshabilitarEntidadesFacturacion(
                                                                                    fechaSel,
                                                                                    fechaSel
                                                                                );
                                                                            },
                                                                        },
                                                                    },
                                                                ],
                                                            })
                                                        );
                                                    },
                                                },
                                                {
                                                    ratio: 1,
                                                    template: function (e, index, item) {
                                                        item.append(
                                                            $("<div id='dgvNuevoAbono' />").dxDataGrid({
                                                                dataSource: datasource_prendaAbono,
                                                                remoteOperations: true,
                                                                //Propiedades
                                                                columnsAutoWidth: true,
                                                                editing: {
                                                                    mode: "batch",
                                                                    allowUpdating: true,
                                                                    useIcons: true,
                                                                },
                                                                height: "100%",
                                                                paging: {
                                                                    enabled: false,
                                                                },
                                                                grouping: {
                                                                    autoExpandAll: true,
                                                                    allowCollapsing: false,
                                                                },
                                                                groupPanel: {
                                                                    visible: false,
                                                                },
                                                                keyboardNavigation: {
                                                                    enterKeyAction: "moveFocus",
                                                                    enterKeyDirection: "row",
                                                                    editOnKeyPress: true,
                                                                },
                                                                columns: [
                                                                    {
                                                                        dataField: "isEntidad",
                                                                        groupCellTemplate: function (
                                                                            cellElement,
                                                                            cellInfo
                                                                        ) {
                                                                            switch (cellInfo.data.items[0].isEntidad) {
                                                                                case 0:
                                                                                    cellElement.text(
                                                                                        getTrad("prendasCompañia")
                                                                                    );
                                                                                    break;
                                                                                case 1:
                                                                                    cellElement.text(
                                                                                        getTrad("prendasEnti")
                                                                                    );
                                                                                    break;
                                                                                default:
                                                                                    break;
                                                                            }
                                                                        },
                                                                        visible: false,
                                                                        groupIndex: 0,
                                                                    },
                                                                    {
                                                                        dataField: "idPrenda",
                                                                        visible: false,
                                                                    },
                                                                    {
                                                                        dataField: "codigoPrenda",
                                                                        caption: getTrad("codigo"),
                                                                        width: "100px",
                                                                        alignment: "center",
                                                                        allowEditing: false,
                                                                        sortOrder: "asc",
                                                                    },
                                                                    {
                                                                        dataField: "denoPrenda",
                                                                        caption: getTrad("denominacion"),
                                                                        minWidth: "250px",
                                                                        allowEditing: false,
                                                                    },
                                                                    {
                                                                        dataField: "cantidad",
                                                                        caption: getTrad("cantidad"),
                                                                        width: "10%",
                                                                        alignment: "center",
                                                                        dataType: "number",
                                                                        cssClass: "dx-Cell_Editable",
                                                                        allowHeaderFiltering: false,
                                                                        allowFiltering: false,
                                                                        allowSorting: false,
                                                                        format: {
                                                                            style: "decimal",
                                                                            maximumFractionDigits: 0,
                                                                        },
                                                                        editorOptions: {
                                                                            step: 0,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            min: 0,
                                                                            max: 99999999,
                                                                        },
                                                                    },
                                                                    {
                                                                        dataField: "codigoElemTransPedido",
                                                                        caption: getTrad("elementoTransporte_abr"),
                                                                        width: "70px",
                                                                        allowEditing: false,
                                                                        allowHeaderFiltering: false,
                                                                        allowFiltering: false,
                                                                        alignment: "center",
                                                                        cssClass: "p-0",
                                                                        cellTemplate: function (container, options) {
                                                                            var contenedor = $("<div />")
                                                                                .addClass("container_spanCentrado")
                                                                                .css("padding-left", "10px");

                                                                            var svg;
                                                                            var htmlTooltip = "";
                                                                            svg = svg_elemTrans_ud.clone();
                                                                            htmlTooltip =
                                                                                "<p>" + getTrad("porUds") + "</p>";

                                                                            svg.css("height", "20px")
                                                                                .css("width", "25px")
                                                                                .css("cursor", "pointer")
                                                                                .appendTo(contenedor);

                                                                            tooltipControl_creacion(
                                                                                contenedor,
                                                                                htmlTooltip
                                                                            );

                                                                            return contenedor;
                                                                        },
                                                                    },
                                                                ],
                                                                //Eventos
                                                                onToolbarPreparing: function (e) {
                                                                    $.each(
                                                                        e.toolbarOptions.items,
                                                                        function (index, item) {
                                                                            if (item.name === "saveButton") {
                                                                                item.visible = false;
                                                                            }
                                                                        }
                                                                    );
                                                                    e.toolbarOptions.items.unshift(
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            showText: "inMenu",
                                                                            locateInMenu: "auto",
                                                                            options: {
                                                                                text: getTrad("imprimir"),
                                                                                icon: " icon_Impresora",
                                                                                onClick: function (e) {
                                                                                    if (abonoSel) {
                                                                                        if (!nuevoAbono_HasChanges()) {
                                                                                            var params = {
                                                                                                idAbono:
                                                                                                    abonoSel.idAbono,
                                                                                            };

                                                                                            COMPONENT.setParams(params);
                                                                                        } else {
                                                                                            notify({
                                                                                                message: getTrad(
                                                                                                    "alerta_GuardarParaImprimir_Abono"
                                                                                                ),
                                                                                                type: "error",
                                                                                                displayTime: "1500",
                                                                                                closeOnClick: true,
                                                                                            });
                                                                                        }
                                                                                    } else {
                                                                                        notify({
                                                                                            message: getTrad(
                                                                                                "alerta_SeleccionarParaImprimir"
                                                                                            ),
                                                                                            type: "error",
                                                                                            displayTime: "1500",
                                                                                            closeOnClick: true,
                                                                                        });
                                                                                    }
                                                                                },
                                                                            },
                                                                        },
                                                                        {
                                                                            location: "before",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                width: "170px",
                                                                                icon: "plus",
                                                                                text: getTrad("nuevoAbono"),
                                                                                type: "normal",
                                                                                onClick: function (e) {
                                                                                    if (nuevoAbono_HasChanges()) {
                                                                                        $(
                                                                                            "#popup_alert_AceptarCancelar"
                                                                                        )
                                                                                            .dxPopup("instance")
                                                                                            .show();
                                                                                        $("#btnAceptar")
                                                                                            .dxButton("instance")
                                                                                            .option(
                                                                                                "onClick",
                                                                                                function (e) {
                                                                                                    $(
                                                                                                        "#popup_alert_AceptarCancelar"
                                                                                                    )
                                                                                                        .dxPopup(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .hide();
                                                                                                    nuevoAbono();
                                                                                                }
                                                                                            );
                                                                                    } else {
                                                                                        nuevoAbono();
                                                                                    }
                                                                                },
                                                                            },
                                                                        },
                                                                        {
                                                                            location: "before",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                width: "100%",
                                                                                icon: "plus",
                                                                                text: getTrad("añadirPrenda"),
                                                                                type: "success",
                                                                                disabled: isCerradoFactEntidad,
                                                                                visible: !abonoSel ? false : true,
                                                                                elementAttr: {
                                                                                    id: "btnAddPrenda",
                                                                                },
                                                                                onClick: function (e) {
                                                                                    if (
                                                                                        typeof $(
                                                                                            "#dxList_añadirPrendas"
                                                                                        )[0] !== "undefined"
                                                                                    ) {
                                                                                        $("#dxList_añadirPrendas")
                                                                                            .dxList("instance")
                                                                                            .unselectAll();
                                                                                        datasource_añadirPrenda.load();
                                                                                    }

                                                                                    $("#popup_añadirPrendas")
                                                                                        .dxPopup("instance")
                                                                                        .show();
                                                                                },
                                                                            },
                                                                        },
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            options: {
                                                                                width: !abonoSel ? "170px" : "36px",
                                                                                icon: !abonoSel ? "check" : "save",
                                                                                type: !abonoSel ? "success" : "normal",
                                                                                text: !abonoSel
                                                                                    ? getTrad("realizarAbono")
                                                                                    : null,
                                                                                disabled: isCerradoFactEntidad,
                                                                                elementAttr: {
                                                                                    id: "btnGuardarAbono",
                                                                                },
                                                                                onClick: function ({ component }) {
                                                                                    if (!abonoSel) {
                                                                                        guardarAbono(e, component);
                                                                                    } else if (
                                                                                        nuevoAbono_HasChanges()
                                                                                    ) {
                                                                                        var abono = {};
                                                                                        abono.idTipoAbono = $(
                                                                                            "#dxSelectBox_tipoAbono"
                                                                                        )
                                                                                            .dxSelectBox("instance")
                                                                                            .option("value");
                                                                                        abono.idCategoriaAbono = $(
                                                                                            "#dxSelectBox_categoriaAbono"
                                                                                        )
                                                                                            .dxSelectBox("instance")
                                                                                            .option("value");
                                                                                        abono.fecha = $(
                                                                                            "#dxDateBox_dbFechaAbono"
                                                                                        )
                                                                                            .dxDateBox("instance")
                                                                                            .option("value");
                                                                                        abono.observaciones = $(
                                                                                            "#taObservaciones"
                                                                                        )
                                                                                            .dxTextArea("instance")
                                                                                            .option("value");

                                                                                        e.component.saveEditData();
                                                                                        datasource_abonosAnteriores
                                                                                            .store()
                                                                                            .update(
                                                                                                abonoSel.idAbono,
                                                                                                abono
                                                                                            )
                                                                                            .done(function () {
                                                                                                abonoSel.idTipoAbono =
                                                                                                    abono.idTipoAbono;
                                                                                                abonoSel.idCategoriaAbono =
                                                                                                    abono.idCategoriaAbono;
                                                                                                abonoSel.fecha =
                                                                                                    abono.fecha;
                                                                                                abonoSel.observaciones =
                                                                                                    abono.observaciones;
                                                                                                fechaSel = abono.fecha;

                                                                                                notify({
                                                                                                    message:
                                                                                                        getTrad(
                                                                                                            "aviso_C_RegistroActualizado"
                                                                                                        ),
                                                                                                    type: "success",
                                                                                                    displayTime: "2500",
                                                                                                    closeOnClick: true,
                                                                                                });
                                                                                                $(
                                                                                                    "#dgvAbonosAnteriores"
                                                                                                )
                                                                                                    .dxDataGrid(
                                                                                                        "instance"
                                                                                                    )
                                                                                                    .refresh(true);
                                                                                            });
                                                                                    } else {
                                                                                        notify({
                                                                                            message:
                                                                                                getTrad(
                                                                                                    "alerta_SinCambios"
                                                                                                ),
                                                                                            type: "error",
                                                                                            displayTime: "2500",
                                                                                            closeOnClick: true,
                                                                                        });
                                                                                    }
                                                                                },
                                                                            },
                                                                        }
                                                                    );
                                                                },
                                                                //Estilos
                                                                showColumnLines: false,
                                                                showRowLines: true,
                                                                rowAlternationEnabled: true,
                                                            })
                                                        );
                                                    },
                                                },
                                                {
                                                    baseSize: 128,
                                                    template: function (e, index, item) {
                                                        item.append(
                                                            $("<div id='tit_observaciones' />")
                                                                .css("marginBottom", "10px")
                                                                .css("marginTop", "10px")
                                                                .addClass("font-size")
                                                                .text(getTrad("observaciones"))
                                                        );

                                                        item.append(
                                                            $("<div id='taObservaciones' />").dxTextArea({
                                                                height: "75px",
                                                                width: "100%",
                                                            })
                                                        );
                                                    },
                                                },
                                            ],
                                        })
                                    );
                                },
                            },
                            {
                                ratio: 4,
                                template: function (e, index, item) {
                                    item.css("padding-top", "45px");

                                    item.append(
                                        $("<div/>").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            width: "100%",
                                            items: [
                                                {
                                                    ratio: 1,
                                                    template: function (e, index, item) {
                                                        item.append(
                                                            $("<div id='dgvAbonosAnteriores'/>").dxDataGrid({
                                                                //Datos
                                                                dataSource: datasource_abonosAnteriores,
                                                                remoteOperations: true,
                                                                //Propiedades
                                                                columnsAutoWidth: true,
                                                                height: "100%",
                                                                filterRow: {
                                                                    visible: true,
                                                                    applyFilter: "auto",
                                                                },
                                                                headerFilter: {
                                                                    visible: true,
                                                                },
                                                                searchPanel: {
                                                                    visible: true,
                                                                    width: 240,
                                                                    placeholder: getTrad("busqueda"),
                                                                },
                                                                selection: {
                                                                    mode: "single",
                                                                },
                                                                paging: {
                                                                    pageSize: 50,
                                                                },
                                                                pager: {
                                                                    showPageSizeSelector: true,
                                                                    allowedPageSizes: [20, 50, 100],
                                                                    showInfo: true,
                                                                },
                                                                hoverStateEnabled: true,
                                                                columns: [
                                                                    {
                                                                        dataField: "codigo",
                                                                        caption: getTrad("codigo"),
                                                                        alignment: "center",
                                                                        width: 100,
                                                                        sortOrder: "desc",
                                                                        sortIndex: 1,
                                                                        allowHeaderFiltering: false,
                                                                    },
                                                                    {
                                                                        dataField: "fecha",
                                                                        caption: getTrad("fecha"),
                                                                        dataType: "date",
                                                                        format: "dd/MM/yyyy",
                                                                        width: 140,
                                                                        alignment: "center",
                                                                        sortOrder: "desc",
                                                                    },
                                                                    {
                                                                        dataField: "denoEntidad",
                                                                        caption: getTrad("entidad"),
                                                                        alignment: "left",
                                                                        cellTemplate: function (cellElement, cellInfo) {
                                                                            var container = $("<div />")
                                                                                .text(cellInfo.text)
                                                                                .css("position", "relative");
                                                                            let text;

                                                                            if (cellInfo.data.isCerradoFactEntidad) {
                                                                                text =
                                                                                    getTrad("factBloqueadaEntiFecha");
                                                                                container.append(
                                                                                    svg_lock_outline
                                                                                        .clone()
                                                                                        .css("height", "25px")
                                                                                        .css("width", "25px")
                                                                                        .css("cursor", "pointer")
                                                                                        .css("position", "absolute")
                                                                                        .css("right", "0px")
                                                                                        .css("top", "-3px")
                                                                                );
                                                                                tooltipControl_creacion(
                                                                                    container,
                                                                                    text
                                                                                );
                                                                            }
                                                                            cellElement.append(container);
                                                                        },
                                                                    },
                                                                    {
                                                                        dataField: "idTipoAbono",
                                                                        width: 150,
                                                                        caption: getTrad("tipoAbono"),
                                                                        alignment: "left",
                                                                        lookup: {
                                                                            dataSource: datasource_tipoAbono.store(),
                                                                            valueExpr: "idTipoAbono",
                                                                            displayExpr: "denominacion",
                                                                        },
                                                                    },
                                                                    {
                                                                        dataField: "idCategoriaAbono",
                                                                        width: 150,
                                                                        caption: getTrad("catAbono"),
                                                                        alignment: "left",
                                                                        lookup: {
                                                                            dataSource:
                                                                                datasource_categoriaAbono.store(),
                                                                            valueExpr: "idCategoriaAbono",
                                                                            displayExpr: "denominacion",
                                                                        },
                                                                    },
                                                                ],
                                                                //Eventos
                                                                onRowPrepared: function (info) {
                                                                    if (info.rowType === "data") {
                                                                        info.rowElement.css("cursor", "pointer");
                                                                    }
                                                                },
                                                                onSelectionChanged: function (e) {
                                                                    if (e.selectedRowsData.length > 0) {
                                                                        var grid =
                                                                            $("#dgvNuevoAbono").dxDataGrid("instance");
                                                                        if (
                                                                            nuevoAbono_HasChanges(
                                                                                e.currentDeselectedRowKeys.length
                                                                            )
                                                                        ) {
                                                                            var popup =
                                                                                $(
                                                                                    "#popup_alert_AceptarCancelar"
                                                                                ).dxPopup("instance");
                                                                            if (!popup.option("visible")) {
                                                                                $("#popup_alert_AceptarCancelar")
                                                                                    .dxPopup("instance")
                                                                                    .show();
                                                                                $("#btnAceptar")
                                                                                    .dxButton("instance")
                                                                                    .option("onClick", function () {
                                                                                        //Al limpiar el abonoSel la próxima vez que entre en el evento onSelectionChanged no detectará cambios.
                                                                                        abonoSel = null;
                                                                                        grid.cancelEditData();

                                                                                        e.component.option(
                                                                                            "selectedRowKeys",
                                                                                            e.currentSelectedRowKeys
                                                                                        );

                                                                                        $(
                                                                                            "#popup_alert_AceptarCancelar"
                                                                                        )
                                                                                            .dxPopup("instance")
                                                                                            .hide();
                                                                                    });

                                                                                e.component.option(
                                                                                    "selectedRowKeys",
                                                                                    e.currentDeselectedRowKeys
                                                                                );
                                                                            }
                                                                        } else {
                                                                            abonoSel = $.extend(
                                                                                true,
                                                                                {},
                                                                                e.selectedRowsData[0]
                                                                            );
                                                                            idEntidadSel = abonoSel.idEntidad;
                                                                            fechaSel = abonoSel.fecha;
                                                                            $.when(
                                                                                deshabilitarEdicionCierreFact(
                                                                                    idEntidadSel,
                                                                                    fechaSel,
                                                                                    fechaSel
                                                                                )
                                                                            ).then(function () {
                                                                                grid.refresh().done(function () {
                                                                                    grid.option(
                                                                                        "editing.allowDeleting",
                                                                                        !isCerradoFactEntidad &&
                                                                                            abonoSel
                                                                                    );

                                                                                    $("#dgvNuevoAbono").dxDataGrid(
                                                                                        "columnOption",
                                                                                        "cantidad",
                                                                                        "cssClass",
                                                                                        !isCerradoFactEntidad
                                                                                            ? "dx-Cell_Editable"
                                                                                            : ""
                                                                                    );

                                                                                    if (guardando) {
                                                                                        guardando = false;
                                                                                        grid.cancelEditData();
                                                                                    }

                                                                                    grid.repaint();
                                                                                    grid.option(
                                                                                        "editing.allowUpdating",
                                                                                        !isCerradoFactEntidad
                                                                                    );

                                                                                    $("#dxToolbar_dgvNuevoAbono")
                                                                                        .dxToolbar("instance")
                                                                                        .repaint();
                                                                                    deshabilitarEdicionCierreFact(
                                                                                        idEntidadSel,
                                                                                        fechaSel,
                                                                                        fechaSel
                                                                                    ); // Si no lo cargo, repaint de dxToolbar_dgvNuevoAbono quita disabledDates de calendario

                                                                                    $("#btnAddPrenda")
                                                                                        .dxButton("instance")
                                                                                        .option({
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });
                                                                                    $("#btnGuardarAbono")
                                                                                        .dxButton("instance")
                                                                                        .option({
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });

                                                                                    $("#taObservaciones")
                                                                                        .dxTextArea("instance")
                                                                                        .option({
                                                                                            value: abonoSel.observaciones,
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });
                                                                                    $("#dxSelectBox_tipoAbono")
                                                                                        .dxSelectBox("instance")
                                                                                        .option({
                                                                                            value: abonoSel.idTipoAbono,
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });
                                                                                    $("#dxSelectBox_categoriaAbono")
                                                                                        .dxSelectBox("instance")
                                                                                        .option({
                                                                                            value: abonoSel.idCategoriaAbono,
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });
                                                                                    $("#dxDateBox_dbFechaAbono")
                                                                                        .dxDateBox("instance")
                                                                                        .option({
                                                                                            value: abonoSel
                                                                                                ? abonoSel.fecha
                                                                                                : fechaSel,
                                                                                            disabled:
                                                                                                isCerradoFactEntidad,
                                                                                        });
                                                                                });
                                                                            });
                                                                        }
                                                                    } else {
                                                                        abonoSel = null;
                                                                    }
                                                                },
                                                                onToolbarPreparing: function (e) {
                                                                    e.toolbarOptions.items.unshift(
                                                                        {
                                                                            location: "before",
                                                                            template: function () {
                                                                                return $(
                                                                                    "<div class ='font-size' style='margin-top : 10px;'>"
                                                                                ).text(getTrad("abonosAnteriores"));
                                                                            },
                                                                        },
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            showText: "inMenu",
                                                                            locateInMenu: "auto",
                                                                            options: {
                                                                                text: getTrad("limpiarFiltro"),
                                                                                icon: " icon_EliminarFiltros",
                                                                                onClick: function () {
                                                                                    e.component.clearFilter();
                                                                                    notify({
                                                                                        message:
                                                                                            getTrad(
                                                                                                "aviso_C_FiltroRestaurado"
                                                                                            ),
                                                                                        type: "success",
                                                                                        displayTime: "1500",
                                                                                        closeOnClick: true,
                                                                                    });
                                                                                },
                                                                            },
                                                                        },
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            showText: "inMenu",
                                                                            options: {
                                                                                icon: "refresh",
                                                                                text: getTrad("actualizar"),
                                                                                onClick: function () {
                                                                                    e.component.refresh(true);
                                                                                },
                                                                            },
                                                                        }
                                                                    );
                                                                },
                                                                //Estilos
                                                                showColumnLines: false,
                                                                showRowLines: true,
                                                                rowAlternationEnabled: true,
                                                            })
                                                        );
                                                    },
                                                },
                                            ],
                                        })
                                    );
                                },
                            },
                        ],
                    });
                });
            });

            $("#Abonos #popup_alert_AceptarCancelar").dxPopup({
                showTitle: false,
                width: 350,
                height: "auto",
                minHeight: 150,
                onContentReady: function (container) {
                    var html = container.component.content();
                    $(html).css("padding-top", "5");
                },
                contentTemplate: function () {
                    return $(
                        "<h4>" +
                            getTrad("alerta") +
                            "</h4><div style='font-size: 14px;'><p>" +
                            getTrad("preg_PerderCambios") +
                            "</p></div>"
                    );
                },
                toolbarItems: [
                    {
                        toolbar: "bottom",
                        location: "after",
                        template: function (item) {
                            var container = $("<div></div>");

                            $("<div id='popup_dxButton_aceptar'>")
                                .dxButton({
                                    text: getTrad("aceptar"),
                                    type: "danger",
                                    elementAttr: {
                                        id: "btnAceptar",
                                    },
                                })
                                .appendTo(container);

                            $("<div>")
                                .dxTooltip({
                                    target: "#popup_dxButton_aceptar",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                        contentElement.html("<p>Se descartarán los cambios.</p>");
                                    },
                                    animation: {
                                        show: {
                                            type: "pop",
                                            from: {
                                                scale: 0.1,
                                                opacity: 0,
                                            },
                                            to: {
                                                opacity: 1,
                                                scale: 1,
                                            },
                                        },
                                        hide: {
                                            type: "pop",
                                            from: {
                                                scale: 1,
                                                opacity: 1,
                                            },
                                            to: {
                                                opacity: 0,
                                                scale: 0.1,
                                            },
                                        },
                                    },
                                })
                                .appendTo(container);

                            return container;
                        },
                    },
                    {
                        toolbar: "bottom",
                        location: "after",
                        template: function (item) {
                            var container = $("<div></div>");

                            $("<div id='popup_dxButton_cancelar'>")
                                .dxButton({
                                    text: getTrad("cancelar"),
                                    elementAttr: {
                                        id: "btnCancelar_alert",
                                    },
                                    onClick: function (e) {
                                        $("#popup_alert_AceptarCancelar").dxPopup("instance").hide();
                                    },
                                })
                                .appendTo(container);

                            return container;
                        },
                    },
                ],
            });

            //POPUP AÑADIR PRENDAS
            $("#Abonos #popup_añadirPrendas").dxPopup({
                showTitle: true,
                title: getTrad("añadirPrenda"),
                height: "80%",
                width: "35%",
                minWidth: "500px",
                contentTemplate: function () {
                    return $("<div id='dxList_añadirPrendas' />").dxList({
                        dataSource: datasource_añadirPrenda,
                        selectionMode: "multiple",
                        showSelectionControls: false,
                        scrolling: { mode: "infinite" },
                        pageLoadMode: "scrollBottom",
                        itemTemplate: function (data, index, item) {
                            var content = $("<div>").css("display", "flex").css("margin", "0px 15px");
                            content.append($("<div>").text(data.codigoPrenda).css("flex-basis", 80));
                            content.append($("<div>").text(data.denoPrenda).css("flex", 1));

                            var container = $("<div />")
                                .addClass("container_spanCentrado")
                                .css("padding-left", "10px")
                                .css("flex-basis", 100);
                            var svg;
                            var htmlTooltip = "";

                            svg = svg_elemTrans_ud.clone();
                            htmlTooltip = "<p>" + getTrad("porUds") + "</p>";

                            svg.css("height", "20px").css("width", "25px").css("cursor", "pointer").appendTo(container);

                            tooltipControl_creacion(container, htmlTooltip);

                            content.append(container);

                            return content;
                        },
                    });
                },
                toolbarItems: [
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: getTrad("añadir"),
                            type: "success",
                            onClick: function (e) {
                                //Añadir prenda a las prendas N reparto
                                var prendasSeleccionadas = $("#dxList_añadirPrendas")
                                    .dxList("instance")
                                    .option("selectedItems");

                                if (prendasSeleccionadas.length > 0) {
                                    $.each(prendasSeleccionadas, function (index, item) {
                                        var isLastElement = index === prendasSeleccionadas.length - 1;

                                        var prendaNAbono = {};
                                        prendaNAbono.idAbono = abonoSel.idAbono;
                                        prendaNAbono.idPrenda = item.idPrenda;
                                        prendaNAbono.cantidad = 0;

                                        datasource_prendaAbono
                                            .store()
                                            .insert(prendaNAbono)
                                            .done(function () {
                                                if (isLastElement) {
                                                    $("#dgvNuevoAbono").dxDataGrid("instance").refresh();
                                                }
                                            });
                                    });

                                    $("#popup_añadirPrendas").dxPopup("instance").hide();

                                    notify({
                                        message: getTrad("aviso_C_RegistroInsertado"),
                                        type: "success",
                                        displayTime: "600",
                                        closeOnClick: true,
                                    });
                                }
                            },
                        },
                    },
                ],
            });
        })
        .always(function () {
            deferred.resolve();
        });

    function guardarAbono(e, component) {
        component.option("disabled", true);
        var dgvNuevoAbono = e.component;
        var tblPrendaNAbono = [];

        $.each(dgvNuevoAbono.getVisibleRows(), function (index, item) {
            if (item.rowType === "data") {
                var prenda = {};
                prenda.idPrenda = dgvNuevoAbono.cellValue(item.rowIndex, "idPrenda");
                prenda.cantidad = dgvNuevoAbono.cellValue(item.rowIndex, "cantidad");

                if (prenda.cantidad > 0) {
                    tblPrendaNAbono.push(prenda);
                }
            }
        });

        if (tblPrendaNAbono.length > 0) {
            var abono = {};
            abono.idEntidad = $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").option("value");
            abono.idTipoAbono = $("#dxSelectBox_tipoAbono").dxSelectBox("instance").option("value");
            abono.idCategoriaAbono = $("#dxSelectBox_categoriaAbono").dxSelectBox("instance").option("value");
            abono.fecha = $("#dxDateBox_dbFechaAbono").dxDateBox("instance").option("value");
            abono.observaciones = $("#taObservaciones").dxTextArea("instance").option("value");
            abono.idLavanderia = LAVANDERIA.idLavanderia;
            abono.tblPrendaNAbono = tblPrendaNAbono;

            datasource_abonosAnteriores
                .store()
                .insert(abono)
                .done(function (data, idAbono) {
                    guardando = true;

                    var dgvAbonosAnteriores = $("#dgvAbonosAnteriores").dxDataGrid("instance");
                    dgvAbonosAnteriores.refresh(true).done(function () {
                        notify({
                            message: getTrad("aviso_C_RegistroInsertado") + " " + data.codigo + ".",
                            type: "success",
                            displayTime: "2500",
                            closeOnClick: true,
                        });
                        dgvAbonosAnteriores.option("selectedRowKeys", [idAbono]);
                    });
                });
            return;
        } else {
            component.option("disabled", false);
            notify({
                message: getTrad("alerta_cantidadRepartir"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    function nuevoAbono() {
        $("#dxDropDownBox_lista_compañias").dxList("instance").selectItem(0);
        $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").open();

        if (abonoSel) {
            var gridAnteriores = $("#dgvAbonosAnteriores").dxDataGrid("instance");
            gridAnteriores.option("selectedRowKeys", []);
            gridAnteriores.repaint();
        }

        var gridAbonos = $("#dgvNuevoAbono").dxDataGrid("instance");
        gridAbonos.cancelEditData();

        $("#dxToolbar_dgvNuevoAbono").dxToolbar("instance").repaint();
        $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").open();

        $("#dxDateBox_dbFechaAbono").dxDateBox("instance").option("value", new Date());
        $("#taObservaciones").dxTextArea("instance").option("value", "");
        $("#dxSelectBox_tipoAbono")
            .dxSelectBox("instance")
            .option("value", datasource_tipoAbono.items()[0].idTipoAbono);
        $("#dxSelectBox_categoriaAbono")
            .dxSelectBox("instance")
            .option("value", datasource_categoriaAbono.items()[0].idCategoriaAbono);

        gridAbonos.refresh(true);
        gridAbonos.repaint();
        deshabilitarEdicionCierreFact(null, fechaSel, fechaSel);
    }

    function nuevoAbono_HasChanges() {
        if (guardando) {
            return false;
        }
        if (!abonoSel) {
            // Para que al cambiar de abono estando creando uno nuevo, te salte la ventana de descarte/cancelar.
            if ($("#dgvNuevoAbono").dxDataGrid("instance").getController("editing").hasChanges()) {
                return true;
            }
            return false;
        }

        var hasChanges = false;

        hasChanges = $("#dgvNuevoAbono").dxDataGrid("instance").getController("editing").hasChanges();
        hasChanges =
            hasChanges ||
            formatDate($("#dxDateBox_dbFechaAbono").dxDateBox("instance").option("value")) !==
                formatDate(abonoSel.fecha);
        hasChanges =
            hasChanges || $("#taObservaciones").dxTextArea("instance").option("value") !== abonoSel.observaciones;
        hasChanges =
            hasChanges || $("#dxSelectBox_tipoAbono").dxSelectBox("instance").option("value") !== abonoSel.idTipoAbono;
        hasChanges =
            hasChanges ||
            $("#dxSelectBox_categoriaAbono").dxSelectBox("instance").option("value") !== abonoSel.idCategoriaAbono;

        return hasChanges;
    }

    function CustomizeParameterEditors(report, s, e) {
        if (report === "albaran_abonos") {
            if (e.parameter.name === "idAbono") {
                //INVISIBLES
                e.info.editor = $.extend({}, e.info.editor);
                e.info.visible = false;
            }
        }
    }

    return deferred.promise();
}
