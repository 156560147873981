import React, { useContext, useRef } from "react";

import PrevGrid from "./PrevGrid";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { CuadranteContextConsumer } from "../../services/apiCalls";

const Cuadrante = ({
    lavanderia,
    selectedRange,
    refSettingsPopup,
    refCalendarioCuadrante,
    refPersonalGeneralPopup,
    refEstructuraOperativa,
    tblCalendarioPersonal_estado,
    tblTurno,
    addNewChange,
    viewType,
    selectedIndex,
    loadPanel_hide,
    enableMigracion,
}) => {
    const refMultiView = useRef(null);

    const getTabData = () => {
        let tabData = [];

        const turnos = tblTurno.filter((x) => x.idLavanderia === lavanderia.idLavanderia && x.idTurnoPadre === null);
        let subturnos = [];
        tblTurno.map((turno) => {
            let sts = tblTurno.filter((x) => turno.idTurno === x.idTurnoPadre).map((x) => x.idTurno);
            if (sts.length > 0) subturnos = { ...subturnos, [turno.idTurno]: sts };
        });

        turnos.map((x) =>
            tabData.push({
                turno: x,
                subturnos: subturnos[x.idTurno],
            })
        );
        return tabData;
    };

    return (
        <>
            <MultiView
                ref={refMultiView}
                deferRendering={false}
                height={"95%"}
                className="py-2"
                animationEnabled={true}
                selectedIndex={selectedIndex}
                swipeEnabled={false}
            >
                {getTabData().map((tab) => (
                    <MultiViewItem key={tab.turno.idTurno}>
                        <CuadranteContextConsumer>
                            <PrevGrid
                                turno={tab.turno}
                                subturnos={tab.subturnos}
                                lavanderia={lavanderia}
                                selectedRange={selectedRange}
                                refSettingsPopup={refSettingsPopup}
                                refCalendarioCuadrante={refCalendarioCuadrante}
                                refPersonalGeneralPopup={refPersonalGeneralPopup}
                                tblCalendarioPersonal_estado={tblCalendarioPersonal_estado}
                                addNewChange={addNewChange}
                                refEstructuraOperativa={refEstructuraOperativa}
                                viewType={viewType}
                                loadPanel_hide={loadPanel_hide}
                                enableMigracion={enableMigracion}
                            />
                        </CuadranteContextConsumer>
                    </MultiViewItem>
                ))}
            </MultiView>
        </>
    );
};

export default Cuadrante;
