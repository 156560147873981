import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { impresionActions, loadPanelActions } from "../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs, { onSave_InventarioMod } from "./Js.js";

//Css
import "./Css.scss";
import PopupEdit_Inventario from "./components/PopupEdit_Inventario";
import PopupDocumentacion from "./components/PopupDocumentacion";

class Inventarios extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inventarioSel: null,
      isVisible_PopupEdit_Inventario: false,
      isVisible_PopupDocumentacion: false,
    };

    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  setParams(params) {
    this.props.setParams(params);
  }

  setReport(report) {
    this.props.setReport(report);
  }

  closeReport() {
    this.props.close();
  }

  render() {
    const {
      inventarioSel,
      isVisible_PopupEdit_Inventario,
      isVisible_PopupDocumentacion,
    } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="Inventarios" className={"formContainer"}>
            <div id="dxContainer">
              <div data-options="dxItem: {ratio: 1}">
                <div id="dxTabPanel_container"></div>
              </div>
            </div>
            <div id="dxPopup_nuevoInventario"></div>
            <div id="dxPopup_movimientos"></div>
            <div id="dxPopup_estadoInventarios"></div>
            <div id="dxPopup_prendasNAlmacen"></div>
            <div id="dxPopup_informePrendas"></div>
            <div id="dxPopup_impresion"></div>
            <div id="dxPopup_nuevoSubInventario"></div>
            <div id="dxPopup_tblPrendasSubInv_validar"></div>
            <div id="dxPopup_fechaCierre"></div>
            <div id="dxPopup_visualizarPrendas"></div>
            <div id="dxPopup_addPrenda"></div>
            <div id="dxPopup_firmas"></div>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
        <PopupEdit_Inventario
          visible={isVisible_PopupEdit_Inventario}
          inventarioSel={inventarioSel}
          onSave_InventarioMod={this.onSave_InventarioMod}
          onHiding={this.onHiding_PopupEdit_Inventario}
        />
        <PopupDocumentacion
          visible={isVisible_PopupDocumentacion}
          idInventarioSel={inventarioSel?.idInventario}
          onHiding={this.onHiding_PopupDocumentacion}
        />
      </Fragment>
    );
  }

  onChange_inventarioSel = (inventarioSel) => this.setState({ inventarioSel });

  onSave_InventarioMod = (objUpdate) => {
    const { lavanderia } = this.props;

    onSave_InventarioMod(objUpdate, this, lavanderia);

    let inventarioSel = { ...this.state.inventarioSel };
    inventarioSel.fecha = objUpdate?.fecha ?? inventarioSel.fecha;
    inventarioSel.denominacion =
      objUpdate?.denominacion ?? inventarioSel.denominacion;

    this.setState({ inventarioSel });
  };

  onShow_PopupEdit_Inventario = () =>
    this.setState({ isVisible_PopupEdit_Inventario: true });

  onHiding_PopupEdit_Inventario = () =>
    this.setState({ isVisible_PopupEdit_Inventario: false });

  onShow_PopupDocumentacion = () =>
    this.setState({ isVisible_PopupDocumentacion: true });

  onHiding_PopupDocumentacion = () =>
    this.setState({ isVisible_PopupDocumentacion: false });

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(
        this,
        lavanderia,
        idioma,
        this.onChange_inventarioSel,
        this.onShow_PopupEdit_Inventario,
        this.onShow_PopupDocumentacion,
      ).then(() => this.loadPanel_hide());
      this.props.close();
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;
    cargaJs(
      this,
      lavanderia,
      idioma,
      this.onChange_inventarioSel,
      this.onShow_PopupEdit_Inventario,
      this.onShow_PopupDocumentacion,
    ).then(() => this.loadPanel_hide());
  }

  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Inventarios);
