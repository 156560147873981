import React, { forwardRef, useImperativeHandle, useMemo, useRef } from "react";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblAdmFactura_Estado,
    datasource_tblCliente,
    datasource_tblCondicionPagoCobro,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblViasPagoCobro,
    displayDeno_CondicionTermino,
    empresaPolarierSel,
    formats,
    sharedEditorOptions,
} from "../../../../../shared/model";
import { calcularValorNeto, calculateDisplayValue, custom_displayExpr } from "../../../../../shared/functions";
import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    Lookup,
} from "devextreme-react/data-grid";
import { authHeader, errorHandler, getTrad } from "helpers";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import { idsAdmTipoFactura } from "constants/enums/Administracion/tblAdmTipoFactura";

const DataGridCargaFacturasVenta = forwardRef(({ formData, seleccionarFactura }, ref) => {
    // Component logic and state

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        clearSelection: () => {
            dataGridRef.current.instance.clearSelection();
        },
        selectRow: (id) => {
            dataGridRef.current.instance.deselectAll();
            dataGridRef.current.instance.refresh().then(() => {
                dataGridRef.current.instance.selectRows([id], false);
            });
        },
    }));

    const calculateCellValue = (e) => {
        let total = 0;

        if (e.tblAdmConceptoVenta.length > 0) {
            e.tblAdmConceptoVenta.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += cantidad * precio * (1 - descuento);
            });
        }

        if (e.idAdmAlbaranVenta.length > 0) {
            e.idAdmAlbaranVenta.forEach((albaran) => {
                albaran.tblArticuloNAdmAlbaranVenta.forEach((articulo) => {
                    const { cantidad = 0, descuento = 0, precio = 0 } = articulo;
                    total += cantidad * precio * (1 - descuento);
                });
                const { descuento = 0, idAdmTipoDescuento } = albaran;
                total = calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "venta");
            });
        }
        const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
        total = calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "venta");
        return total;
    };

    const calculateTotalValueForDisplay = (e) => {
        let total = calculateCellValue(e);
        return calculateDisplayValue(isNaN(total) ? 0 : total, e.idMoneda);
    };
    const datasource_tblAdmFacturaVenta_onLoading = (loadOptions) => {
        loadOptions.filter = [
            ["idEmpresaPolarier", "=", empresaPolarierSel.idEmpresaPolarier],
            ["idAdmCliente", "=", formData.idAdmCliente],
            ["!", ["idTipoFactura", "=", idsAdmTipoFactura.Rectificativa]],
        ];
    };

    const datasource_tblAdmFacturaVenta = useMemo(() => {
        return new DataSource({
            paginate: true,
            store: new ODataStore({
                url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmFacturaVenta",
                // key: "customer",
                key: "idAdmFacturaVenta",
                errorHandler: function (error) {
                    errorHandler(error, null);
                },
                beforeSend: (request) => {
                    request.headers = { ...authHeader() };
                },
                onLoading: (loadOptions) => datasource_tblAdmFacturaVenta_onLoading(loadOptions),
                version: 4,
            }),
            expand: [
                "tblAdmConceptoVenta",
                "idAdmAlbaranVenta($expand=tblArticuloNAdmAlbaranVenta($expand=idArticuloLenceriaNavigation, idArticuloMaquinariaNavigation, idGrupoArticulosNavigation, idRecambioNavigation))",
            ],
        });
    }, [formData.idAdmCliente]);

    const onSelectionChanged_tblFacturas = (e) => {
        if (e.selectedRowsData.length > 0) {
            seleccionarFactura(e.selectedRowsData[0]);
        }
    };

    return (
        // JSX markup for the component
        // <div className={"formContainer scrollbar-container"}>
        <DataGrid
            ref={dataGridRef}
            dataSource={formData.idAdmCliente != undefined ? datasource_tblAdmFacturaVenta : []}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            noDataText="Selecciona un cliente para cargar las facturas."
            selection={{ mode: "single" }}
            // onToolbarPreparing={onToolbarPreparing}
            onSelectionChanged={onSelectionChanged_tblFacturas}
            hoverStateEnabled={true}
            // onRowPrepared={onRowPrepared_tblFacturas}
            // onCellPrepared={onCellPrepared_tblFacturas}
            // onCellClick={onCellClick_tblFacturas}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />
            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fecha"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmCliente"}
                caption={getTrad("cliente")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <Lookup
                    dataSource={datasource_tblCliente.store()}
                    valueExpr="idAdmCliente"
                    displayExpr="nombreFiscal"
                />
            </Column>
            <Column
                dataField={"idAdmFactura_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmFactura_Estado, "", "factura")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmFactura_Estado.store()}
                    valueExpr="idAdmFactura_Estado"
                    displayExpr="denominacion"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                />
            </Column>
            <Column
                dataField={"idMoneda"}
                caption={getTrad("moneda")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPedido"}
                caption={getTrad("numPedido")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                format={formats.percent}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaCobro"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCondicionPago"}
                caption={getTrad("condicionesPagoCobro")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblCondicionPagoCobro.store()}
                    valueExpr="idAdmCondicionPago"
                    displayExpr={displayDeno_CondicionTermino}
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmCentroCoste.store()}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmElementoPEP.store()}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"comentario"}
                caption={getTrad("comentario")}
                dataType={"string"}
                visible={true}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                alignment={"center"}
                dataType={"number"}
                visible={true}
                width={200}
                calculateCellValue={calculateCellValue}
                calculateDisplayValue={calculateTotalValueForDisplay}
            />
            {/* <Column
                    dataField={"isCerrado"}
                    caption={" "}
                    width={40}
                    alignment={"center"}
                    allowReordering={false}
                    allowResizing={false}
                    allowFiltering={false}
                    cellRender={cellRender_isCerrado}
                /> */}
            {/* <Column
                    dataField={"idAdmAlbaranVenta"}
                    dataType={"number"}
                    visible={false}
                    showInColumnChooser={false}
                    allowFiltering={true}
                    calculateFilterExpression={filterArticulo}
                /> */}
            {/* <Column
                    dataField={"tblAdmConceptoVenta"}
                    dataType={"object"}
                    visible={false}
                    showInColumnChooser={false}
                    allowFiltering={true}
                    calculateFilterExpression={filterArticulo}
                /> */}
            {/* <Column
                    caption={" "}
                    width={30}
                    alignment={"center"}
                    cssClass={"p-0"}
                    cellComponent={removeCellComponent}
                /> */}
        </DataGrid>
        // </div>
    );
});

export default DataGridCargaFacturasVenta;
