import React, { useEffect, useState } from "react";

import $ from "jquery";

import NewYearCalendar from "components/NewYearCalendar";
import PopupEditarEstado from "./components/PopupEditarEstado";
import TooltipDayEvents from "./components/TooltipDayEvents";
import { authHeader, formatDate_parameter, getTrad } from "helpers";
import { connectionConstants } from "constants";
import notify from "devextreme/ui/notify";
import ODataContext from "devextreme/data/odata/context";
import OverlayCalendarioLavanderia from "./components/OverlayCalendarioLavanderia";
import { Tooltip } from "devextreme-react";

const today = new Date();
let tooltipDelay;
const idsEstado = {
  cierreNomina: 14,
};

const idsEstado_unoPorMes = [
  //* Estados que solo pueden tener un evento por mes
  idsEstado.cierreNomina,
];

let payload_tblCalendarioLavanderia = [];

const startOfYear = (year) => new Date(year, 0, 1);
const endOfYear = (year) => new Date(year, 11, 31);

const CalendarioLavanderia = ({ idsLavanderia }) => {
  const [eventos, setEventos] = useState([]);
  const [year, setYear] = useState(today.getFullYear());
  const [tooltipTarget, setTooltipTarget] = useState(null);
  const [targetEvents, setTargetEvents] = useState(null);
  const [seleccion, setSeleccion] = useState(null);
  const [warningsEventosMes, setWarningsEventosMes] = useState([]);
  const [
    isVisible_PopupInformeMovimientos,
    setIsVisible_PopupInformeMovimientos,
  ] = useState(false);

  const motivosWarning = {
    variosEventosMismoMes: {
      idMotivo: 1,
      descripcion: getTrad("noCoincidenEventosLavanderia"),
    },
    lavanderiasSinEvento: {
      idMotivo: 2,
      descripcion: getTrad("lavanderiasSinEvento"),
    },
  };

  const datasource_tblCalendarioLavanderia_load = () => {
    context_CalendarioLavanderia
      .invoke("GetEventosNCalendarioLavanderia", {}, "GET")
      .then((data) => {
        data = datasource_tblCalendarioLavanderia_postProcess(data);

        const warningsEventosMes = getWarningEventosMes(data);

        setEventos(data);
        setWarningsEventosMes(warningsEventosMes);
        renderWarnings(warningsEventosMes);
      });
  };

  useEffect(() => {
    if (idsLavanderia.length > 0) datasource_tblCalendarioLavanderia_load();
    else setEventos([]);
  }, [idsLavanderia, year]);

  useEffect(() => {
    clearTimeout(tooltipDelay);
  }, []);

  const getWarningEventosMes = (data) => {
    let contadorWarningsEventosMes = [];

    data.forEach((evento) => {
      const fecha = new Date(evento.fecha);
      const mes = fecha.getMonth();
      const año = fecha.getFullYear();
      const key = año + "-" + mes;

      let contadorWarningEvento = contadorWarningsEventosMes.find(
        (cem) => cem.idEstado === evento.idEstado && cem.añoMes === key,
      );

      if (!contadorWarningEvento)
        contadorWarningsEventosMes.push({
          cantidad: 1,
          idEstado: evento.idEstado,
          añoMes: key,
          motivosWarning: [],
        });
      else {
        contadorWarningEvento.cantidad++;
        if (
          !contadorWarningEvento.motivosWarning.some(
            (mw) =>
              mw.idMotivo === motivosWarning.variosEventosMismoMes.idMotivo,
          )
        )
          contadorWarningEvento.motivosWarning.push(
            motivosWarning.variosEventosMismoMes,
          );
      }

      if (
        idsEstado_unoPorMes.includes(evento.idEstado) &&
        evento.tblLavanderia.length !== idsLavanderia.length
      ) {
        //* Si el evento no se repite paara todas las lavanderías
        let actual_contadorWarningEvento = contadorWarningEvento
          ? contadorWarningEvento
          : contadorWarningsEventosMes.find(
              (cem) => cem.idEstado === evento.idEstado && cem.añoMes === key,
            );

        actual_contadorWarningEvento.cantidad++;
        if (
          !actual_contadorWarningEvento.motivosWarning.some(
            (mw) =>
              mw.idMotivo === motivosWarning.lavanderiasSinEvento.idMotivo,
          )
        )
          actual_contadorWarningEvento.motivosWarning.push(
            motivosWarning.lavanderiasSinEvento,
          );
      }

      let actual_contadorWarningEvento = contadorWarningEvento
        ? contadorWarningEvento
        : contadorWarningsEventosMes.find(
            (cem) => cem.idEstado === evento.idEstado && cem.añoMes === key,
          );

      if (
        actual_contadorWarningEvento.motivosWarning.some(
          (mw) => mw.idMotivo === motivosWarning.variosEventosMismoMes.idMotivo,
        )
      )
        actual_contadorWarningEvento.motivosWarning =
          actual_contadorWarningEvento.motivosWarning.filter(
            (mw) =>
              mw.idMotivo === motivosWarning.variosEventosMismoMes.idMotivo,
          );
    });

    return contadorWarningsEventosMes.filter(
      (cem) => cem.cantidad > 1 && idsEstado_unoPorMes.includes(cem.idEstado),
    );
  };

  const renderWarnings = (warningsEventosMes) => {
    warningsEventosMes.forEach((cem) => {
      $(() => {
        //* Obtener el data-month-id
        var monthId = cem.añoMes.split("-")[1];

        //* Crear la nueva celda
        var newCell = $(
          `<th id="warning-month-${monthId}" class="icon_Warning" style="font-size: 1.4rem" />`,
        );

        //* Reducir el colspan de la celda month-title en 1 unidad
        var monthTitle = $(
          '.month-container[data-month-id="' + monthId + '"]',
        ).find(".month-title");
        var currentColspan = parseInt(monthTitle.attr("colspan"));
        monthTitle.attr("colspan", currentColspan - 1);
        monthTitle.css("color", "var(--danger)");

        //* Insertar la nueva celda después de la celda month-title
        monthTitle.after(newCell);
      });
    });
  };

  const context_CalendarioLavanderia = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL + "RRHH/CalendarioLavanderia/",
    entities: {
      GetEventosNCalendarioLavanderia: {},
      AddEventosNCalendarioLavanderia: {},
      DeleteEventosNCalendarioLavanderia: {
        año: "Int32",
        mes: "Int32",
      },
    },
    beforeSend: (request) => context_CalendarioLavanderia_beforeSend(request),
  });

  const context_CalendarioLavanderia_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      request.params.fechaDesde = formatDate_parameter(startOfYear(year));
      request.params.fechaHasta = formatDate_parameter(endOfYear(year));
      request.params.idsLavanderia = idsLavanderia.join("|");
    }

    if (request.method === "post") {
      request.payload = payload_tblCalendarioLavanderia;
      payload_tblCalendarioLavanderia = [];
    }

    if (request.method === "delete") {
      request.params.idsLavanderia = idsLavanderia.join("|");
    }
  };

  const datasource_tblCalendarioLavanderia_postProcess = (data) => {
    data = data.map((cl) => {
      cl.fecha = new Date(cl.fecha.split("T")[0]);
      cl.fecha.setHours(0, 0, 0, 0);
      cl.idsLavanderia = idsLavanderia;
      return cl;
    });

    return data;
  };

  const dayRender = (element, date, eventos) => {
    const evento = eventos[0];
    const parentElement = $(element).parent();

    parentElement.css("background-color", evento.color);

    if (evento.tipoCelda.length > 0) parentElement.addClass(evento.tipoCelda);
  };

  const onDayEnter = (e) => {
    tooltipDelay = setTimeout(() => {
      const evento = e.eventos.length > 0 ? e.eventos : null;
      const element = e.element;
      setTooltipTarget(`#${element.id}`);
      setTargetEvents(evento);
    }, 100);
  };

  const onDayLeave = () => {
    clearTimeout(tooltipDelay);
    setTooltipTarget(null);
    setTargetEvents(null);
  };

  const onDayClick = (e) => {
    e.startDate = e.date;
    e.endDate = e.date;
    onRangeSelected(e);
  };

  const onRangeSelected = ({ startDate, endDate, eventos }) => {
    if (idsLavanderia.length > 0) {
      setSeleccion({ startDate, endDate, eventos, idsLavanderia });
      setIsVisible_PopupInformeMovimientos(true);
    } else
      notify({
        message: getTrad("seleccioneLavanderia"),
        type: "error",
        displayTime: 1000,
        closeOnClick: true,
      });
  };

  const onYearChanged = (e) => setYear(e.currentYear);

  const onSave_PopupEditarEstado = (month, tblCalendarioLavanderia) => {
    Promise.all([
      (payload_tblCalendarioLavanderia = tblCalendarioLavanderia),
      context_CalendarioLavanderia.invoke(
        "DeleteEventosNCalendarioLavanderia",
        { año: year, mes: ++month },
        "DELETE",
      ),
    ]).then(() => {
      context_CalendarioLavanderia
        .invoke("AddEventosNCalendarioLavanderia", {}, "POST")
        .then(() => datasource_tblCalendarioLavanderia_load());
    });
  };

  const onHiding_PopupEditarEstado = () =>
    setIsVisible_PopupInformeMovimientos(false);

  return (
    <OverlayCalendarioLavanderia idsLavanderia={idsLavanderia}>
      <NewYearCalendar
        height={"100%"}
        eventos={eventos}
        eventoRangeExpr={"idEstado"}
        dateExpr={"fecha"}
        colorExpr={"colorHexa"}
        autoClearSeleccion
        dayRender={dayRender}
        onDayEnter={onDayEnter}
        onDayLeave={onDayLeave}
        onDayClick={onDayClick}
        onRangeSelected={onRangeSelected}
        onYearChanged={onYearChanged}
      />
      <TooltipDayEvents
        tooltipTarget={tooltipTarget}
        targetEvents={targetEvents}
      />
      <PopupEditarEstado
        visible={isVisible_PopupInformeMovimientos}
        seleccion={seleccion}
        onSave={onSave_PopupEditarEstado}
        onHiding={onHiding_PopupEditarEstado}
      />
      {warningsEventosMes.map((wem) => (
        <Tooltip
          key={wem.idEstado + "-" + wem.añoMes}
          target={`#warning-month-${wem.añoMes.split("-")[1]}`}
          showEvent={"mouseenter"}
          hideEvent={"mouseleave"}
        >
          <div>
            {wem.motivosWarning.map((mw) => (
              <div
                key={`${wem.idEstado}-${wem.añoMes}-${mw.idMotivo}`}
                className={"font-size-md font-weight-700"}
              >
                {mw.descripcion}
              </div>
            ))}
          </div>
        </Tooltip>
      ))}
    </OverlayCalendarioLavanderia>
  );
};

export default CalendarioLavanderia;
