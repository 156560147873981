import React from "react";

import { Box, Item as ItemBox } from "devextreme-react/box";
import { getColor_porcentaje } from "./helpers";
import LottieIcon from "components/LottieIcon";
import RadialBar from "components/RadialBar/RadialBar";

class Estadisticas extends React.Component {
    constructor(props) {
        super(props);
    }

    labelSyle = {
        whiteSpace: "pre",
        fontSize: "1.4rem",
        fontFamily: [
            "Segoe UI Light",
            "Helvetica Neue Light",
            "Segoe UI",
            "Helvetica Neue",
            "Trebuchet MS",
            "Verdana, sans-serif",
        ],
        fontWeight: 200,
        color: "gray",
    };

    timeFormatHour = { hour: "numeric", minute: "2-digit", second: "2-digit" };
    timeFormat = { minute: "2-digit", second: "2-digit" };

    posicionWidget = { position: "absolute", top: 0, bottom: 0 };

    render() {
        const { ptgActual, ptgCliente, tiempoActivo } = this.props;

        return (
            <Box direction="col" className={"he-100 position-relative"}>
                <ItemBox ratio={1}>
                    <div className="container_spanCentrado d-flex flex-column position-relative">
                        <RadialBar
                            size={170}
                            height={100}
                            label={"Actual"}
                            value={ptgActual && ptgActual !== -1 ? Math.round(ptgActual * 100) : 0}
                            color={getColor_porcentaje(ptgActual)}
                            valueStyle={{ top: 32, display: ptgCliente === -1 ? "none" : "" }}
                            disabled={ptgActual == null}
                        />
                        {ptgActual === -1 && (
                            <div style={this.posicionWidget}>
                                <LottieIcon height="100%" width="100%" icon={"loading_input"} />
                            </div>
                        )}
                    </div>
                </ItemBox>
                <ItemBox ratio={1}>
                    <div className="container_spanCentrado d-flex flex-column position-relative">
                        <RadialBar
                            size={170}
                            height={100}
                            label={"Cliente"}
                            value={ptgCliente && ptgCliente !== -1 ? Math.round(ptgCliente * 100) : 0}
                            color={getColor_porcentaje(ptgCliente)}
                            valueStyle={{ top: 32, display: ptgCliente === -1 ? "none" : "" }}
                            disabled={ptgCliente == null}
                        />
                        {ptgCliente === -1 && (
                            <div style={this.posicionWidget}>
                                <LottieIcon height="100%" width="100%" icon={"loading_input"} />
                            </div>
                        )}
                    </div>
                </ItemBox>
                <ItemBox baseSize={50}>
                    {!Number.isNaN(tiempoActivo.getTime()) && (
                        <div className={`card timer font-size-xs px-2 position-absolute`}>
                            <i className="icon_timer mr-2 my-2 d-flex align-items-center" />
                            <span>
                                {tiempoActivo.getUTCHours() > 0 && tiempoActivo.getUTCHours() + ":"}
                                {tiempoActivo.getUTCMinutes() > 9
                                    ? tiempoActivo.getUTCMinutes() + ":"
                                    : "0" + tiempoActivo.getUTCMinutes() + ":"}
                                {tiempoActivo.getUTCSeconds() > 9
                                    ? tiempoActivo.getUTCSeconds()
                                    : "0" + tiempoActivo.getUTCSeconds()}
                            </span>
                        </div>
                    )}
                </ItemBox>
            </Box>
        );
    }
}

export default Estadisticas;
