import React, { Component } from "react";
import { TileView } from "devextreme-react/tile-view";

class FotoPerfil extends Component {
  constructor(props) {
    super(props);
  }

  itemRender = () => {
    const { datosPersonaSel } = this.props;
    const idFotoPerfil = datosPersonaSel?.idFotoPerfilNavigation?.documento;
    const imageBase64 = "data:image/png;base64," + idFotoPerfil;

    return (
      <div
        className="d-flex justify-content-center align-items-center "
        style={{ width: "100%", height: "100%", borderRadius: "50%" }}
      >
        {!idFotoPerfil ? (
          <i className="icon_Persona" style={{ fontSize: 120 }} />
        ) : (
          <img
            className="image "
            style={{
              backgroundImage: `url(${imageBase64})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              backgroundRepeat: "no-repeat",
              borderRadius: "50%",
            }}
          />
        )}
      </div>
    );
  };

  render() {
    const { datosPersonaSel, size = 180 } = this.props;

    const idFotoPerfil = datosPersonaSel?.idFotoPerfilNavigation?.documento;
    const item = [{ idFotoPerfil }];

    return (
      <div className="d-flex justify-content-center mb-2">
        <TileView
          items={item}
          itemComponent={this.itemRender}
          height={size}
          width={size}
          baseItemWidth={size}
          baseItemHeight={size}
          itemMargin={0}
          activeStateEnabled={false}
          hoverStateEnabled={false}
          focusStateEnabled={false}
          rtlEnabled={false}
          elementAttr={{ class: "circular_tileView" }}
        />
      </div>
    );
  }
}

export default FotoPerfil;
