import React from "react";
import { Redirect } from "react-router-dom";

import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  dxMensajePregunta,
  convertClienteUtcToLavanderiaUtc,
  formatDate,
  tooltipControl_creacion,
  getCierresFacturacion,
  getDaysInRange,
  formatDate_noTime_parameter,
} from "../../../helpers";
import {
  svg_elemTrans_ud,
  svg_elemTrans_bac,
  svg_lock_outline,
} from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var idArchivoFirmaSel = null;
  var repartoSel = null; // REPARTO SELECCIONADO EN GRI NUEVO REPARTO
  var guardando = false; //Variable que sirve para pasar la validación de HasChanges y no tener que hacer un cancelEdit y verse todo a 0 cuando se guarda
  var dxDataGrid_impresionAgrupada_filtro = null;
  var dxDataGrid_RepartosAnteriores; // Instancia de grid repartos anteriores
  var isCerradoFactEntidad = false;
  var dxDateBox_disabledDates = [];
  var idsEntidad_disabled = [];
  var idEntidadSel = null;
  var fechaSel = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date());

  //#region COGER EL DÍA DE HACE UN MES PARA EL FILTRO DE REPARTOS ANTERIORES
  var filtro_fechasAnteriores = new Date();
  filtro_fechasAnteriores.setDate(filtro_fechasAnteriores.getDate() - 30);
  //#endregion

  //#region DATASOURCES

  var datasource_compañias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "idCompañia", "denominacion"],
    map: function (dataItem) {
      return {
        idEntidad: dataItem.idEntidad,
        denominacion: dataItem.denominacion,
        idCompañia: dataItem.idCompañia,
        disabled:
          idsEntidad_disabled.filter(
            (idEntidad) => idEntidad == dataItem.idEntidad,
          ).length > 0,
      };
    },
  });

  var datasource_almacenes = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAlmacen",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.idTipoAlmacen = 2; //TIPO OFFICE
      },
      version: 4,
      onLoading: function (loadOptions) {
        var dxDropDownBox_lista_entidades = $(
          "#dxDropDownBox_lista_entidades",
        ).dxList("instance");
        var idEntidad = -1;

        if (
          typeof dxDropDownBox_lista_entidades !== "undefined" &&
          dxDropDownBox_lista_entidades.option("selectedItemKeys").length > 0
        ) {
          idEntidad =
            dxDropDownBox_lista_entidades.option("selectedItemKeys")[0];
        }

        if (!loadOptions.filter) {
          loadOptions.filter = [["idEntidad", "=", idEntidad]];
        } else {
          loadOptions.filter = [
            ["idEntidad", "=", idEntidad],
            "AND",
            [loadOptions.filter],
          ];
        }
      },
    }),
    sort: "denominacion",
    select: ["idAlmacen", "denominacion"],
    postProcess: function (data) {
      data.forEach((x) => {
        x.disabled =
          idsEntidad_disabled.filter((idEntidad) => idEntidad == idEntidadSel)
            .length > 0;
      });
      return data;
    },
  });

  var enum_tipoReparto = [
    {
      idTipoReparto: 0,
      denominacion: getTrad("normal").toUpperCase(),
    },
    {
      idTipoReparto: 1,
      denominacion: getTrad("extra").toUpperCase(),
    },
  ];

  var datasource_tblPrendaNRevision = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaNRevision",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idRevision = repartoSel.idRevision ?? -1;
      },
      version: 4,
    }),
  });

  var datasource_tblPrendaNPedidoExtra = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaNPedidoExtra",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idPedidoExtra = repartoSel.idPedidoExtra ?? -1;
      },
      version: 4,
    }),
  });

  var datasource_prendaRepartoOffice = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "repartosOffice_spSelectPrendaNAlmacen",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method === "get") {
          // SELECT
          if (repartoSel) {
            // SELECCIONA PRENDAS DE UN REPARTO
            request.params.idRepartoOffice = repartoSel.idRepartoOffice;
            request.url = request.url.replace(
              "repartosOffice_spSelectPrendaNAlmacen",
              "repartosOffice_spSelectPrendasNRepartoOffice",
            );
          } // SELECCIONA PRENDAS DE UN ALMACEN (NO HAY REPARTO SELECCIONADO)
          else {
            request.params.idAlmacen = -1;

            if (typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined") {
              request.params.idAlmacen = $("#dxDropDownBox_selCompaEnti")
                .dxDropDownBox("instance")
                .option("value");
            }
          }
        } // INSERT / UPDATE
        else {
          request.params.idAlmacen = repartoSel
            ? repartoSel.idAlmacen
            : $("#dxDropDownBox_lista_almacenes")
                .dxList("instance")
                .option("selectedItemKeys")[0];
          request.params.denoEnti = repartoSel
            ? ""
            : $("#dxDropDownBox_lista_entidades")
                .dxList("instance")
                .option("selectedItems")[0].denominacion;
          request.url = request.url.replace(
            "repartosOffice_spSelectPrendaNAlmacen",
            "tblRepartoOffice",
          );
        }
      },
      version: 4,
    }),
  });

  var datasource_añadirPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "repartosOffice_spSelectPrendaNAlmacen",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idAlmacen = -1;

        if (typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined") {
          request.params.idAlmacen = $("#dxDropDownBox_selCompaEnti")
            .dxDropDownBox("instance")
            .option("value");
        }
      },
      version: 4,
    }),
    sort: "codigoPrenda",
    postProcess: function (data) {
      var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");

      if (repartoSel.isApp) {
        if (repartoSel.idRevision)
          data = data.filter((p) =>
            datasource_tblPrendaNRevision
              .items()
              .some((pnr) => pnr.idPrenda === p.idPrenda),
          );

        if (repartoSel.idPedidoExtra)
          data = data.filter((p) =>
            datasource_tblPrendaNPedidoExtra
              .items()
              .some((pnpe) => pnpe.idPrenda === p.idPrenda),
          );
      }

      return $.grep(data, function (v) {
        return grid.getRowIndexByKey(v.idPrenda) === -1;
      });
    },
  });

  var datasource_repartosOfficeAnteriores = new DataSource({
    paginate: true,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "repartosOffice_spSelectRepartosOffice",
      key: "idRepartoOffice",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (
          typeof dxDataGrid_RepartosAnteriores.columnOption(
            "fecha",
            "filterValue",
          ) !== "undefined"
        ) {
          filtro_fechasAnteriores = dxDataGrid_RepartosAnteriores.columnOption(
            "fecha",
            "filterValue",
          );
        }

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fecha = filtro_fechasAnteriores
          ? formatDate_parameter(filtro_fechasAnteriores)
          : null;
      },
      version: 4,
    }),
  });

  var datasource_repartosAnteriores_impresionAgrupada = new DataSource({
    paginate: true,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "repartosOffice_spSelectRepartosOffice",
      key: "idRepartoOffice",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDataGrid_repartosOfficeAnteriores_impresionAgrupada = $(
          "#dxDataGrid_repartosOfficeAnteriores_impresionAgrupada",
        ).dxDataGrid("instance");
        if (
          typeof dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
            "fecha",
            "filterValue",
          ) !== "undefined"
        ) {
          filtro_fechasAnteriores =
            dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
              "fecha",
              "filterValue",
            );
        }

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fecha = filtro_fechasAnteriores
          ? formatDate_parameter(filtro_fechasAnteriores)
          : null;
      },
      version: 4,
    }),
  });

  var datasource_tblArchivo_firma = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblArchivo",
      key: "idArchivo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        loadOptions.filter = ["idArchivo", "=", idArchivoFirmaSel];
      },
      version: 4,
    }),
    select: ["archivo"],
    expand: ["tblRepartoOffice($select=firmante)"],
    map: function (item) {
      return {
        firmante: item.tblRepartoOffice[0]?.firmante,
        archivo: item.archivo,
      };
    },
  });

  //#endregion

  //#region

  //#region Cierre Facturación Entidad
  function deshabilitarEdicionCierreFact(idEntidad, fechaDesde, fechaHasta) {
    return new Promise((resolve, reject) => {
      getCierresFacturacion(idEntidad, fechaDesde, fechaHasta)
        .then((data) => {
          // Comprobación si entidad actual con la fecha seleccionada es editable
          isCerradoFactEntidad = data.length > 0;

          let dgvNuevoAbono = $(
            "#RepartosOffice #dxDataGrid_NuevoReparto",
          ).dxDataGrid("instance");
          dgvNuevoAbono.option("editing.allowUpdating", !isCerradoFactEntidad);
          dgvNuevoAbono.columnOption(
            "cantidad",
            "cssClass",
            !isCerradoFactEntidad && "dx-Cell_Editable",
          );

          deshabilitarFechasFacturacion(idEntidad, fechaDesde);
          resolve(data);
        })
        .catch(reject);
    });
  }

  //idEntidad y fechadsde globla o coger de reparto ???

  function deshabilitarFechasFacturacion(idEntidad, fechaDesde) {
    dxDateBox_disabledDates = [];
    return new Promise((resolve, reject) => {
      if (idEntidad) {
        getCierresFacturacion(idEntidad, null, null)
          .then((data) => {
            //region deshabilitar días
            let disabledDates = [];
            data.forEach((item) => {
              disabledDates.push(
                ...getDaysInRange(
                  new Date(item.fechaDesde),
                  new Date(item.fechaHasta),
                ),
              );
            });
            dxDateBox_disabledDates = [...disabledDates];

            //Aplicar fechaFiltrada
            $("#dxDateBox_dbFechaReparto").dxDateBox("instance").option({
              value: fechaDesde,
              disabledDates: dxDateBox_disabledDates,
            });
            //#endregion
            resolve(data);
          })
          .catch(reject);
      } else {
        $("#dxDateBox_dbFechaReparto").dxDateBox("instance").option({
          value: fechaDesde,
          disabledDates: [],
        });
        resolve();
      }
    });
  }

  function deshabilitarEntidadesFacturacion(fechaDesde, fechaHasta) {
    idsEntidad_disabled = [];
    getCierresFacturacion(null, fechaDesde, fechaHasta).then(
      function (entidadesDisabled) {
        idsEntidad_disabled = [...entidadesDisabled.map((x) => x.idEntidad)];
        datasource_almacenes.reload();
        datasource_entidades.reload().done(function (items) {
          $("#dxDropDownBox_lista_entidades")
            .dxList("instance")
            .option("dataSource", items);
        });
      },
    );
  }
  deshabilitarEntidadesFacturacion(fechaSel, fechaSel);
  //#endregion

  $("#dxTooltip_RepartoOffice").dxTooltip({
    showEvent: "mouseenter",
    hideEvent: "mouseleave",
  });

  $("#tit_NuevoReparto").text(getTrad("nuevoReparto"));
  $("#tit_RepartosAnteriores").text(getTrad("repartosAnteriores"));

  //#endregion

  //#region LAYOUT

  $("#RepartosOffice #dxContainer").dxBox({
    direction: "row",
    height: "100%",
  });

  $("#RepartosOffice #dxBox_NuevoReparto").dxBox({
    direction: "col",
    height: "100%",
  });

  $("#RepartosOffice #dxBox_RepartosAnteriores").dxBox({
    direction: "col",
    height: "100%",
  });

  //#endregion

  COMPONENT.setReport({
    denominacion: "albaran_repartosOffice",
    parameterEvent: CustomizeParameterEditors,
  });

  datasource_entidades.load().done(function (e) {
    if (e.length > 0) {
      $("#RepartosOffice #dxToolbar_NuevoReparto").dxToolbar({
        items: [
          {
            location: "before",
            template: function (data, itemElement) {
              var container = $("<div>");
              var dxDropDownBox = $(
                "<div id='dxDropDownBox_selCompaEnti' style='float:left'>",
              ).dxDropDownBox({
                dataSource: datasource_almacenes,
                valueExpr: "idAlmacen",
                displayExpr: function (item) {
                  var denoEntidad = "";
                  var listaEnti_selectedItems = $(
                    "#dxDropDownBox_lista_entidades",
                  )
                    .dxList("instance")
                    .option("selectedItems");
                  if (listaEnti_selectedItems.length > 0) {
                    denoEntidad = listaEnti_selectedItems[0].denominacion;
                  } else if (repartoSel) {
                    denoEntidad = repartoSel.denoEnti;
                  }
                  return denoEntidad + (item ? ": " + item.denominacion : "");
                },
                width: 350,
                value: repartoSel ? repartoSel.idAlmacen : null,
                disabled: repartoSel ? true : false,
                deferRendering: false,
                remoteOperations: true,
                placeholder: getTrad("almacenes"),
                // EVENTS
                onOpened: function (e) {
                  if (nuevoReparto_HasChanges()) {
                    e.component.close();
                    dxMensajePregunta(
                      "<div style='width:310px;text-align:left;'><h4>" +
                        getTrad("alerta") +
                        "</h4><div style='font-size: 14px;'><p>" +
                        getTrad("preg_PerderCambios") +
                        "</p></div></div>",
                      [
                        [
                          getTrad("aceptar"),
                          function () {
                            var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid(
                              "instance",
                            );
                            grid.option("editing.allowDeleting", repartoSel);
                            grid.cancelEditData();
                            grid.repaint();
                          },
                          "danger",
                          "btnAceptar",
                        ],
                        [getTrad("cancelar"), function () {}],
                      ],
                    );
                  }
                },
                dropDownOptions: {
                  width: 1000,
                  height: 500,
                  position: { my: "center", at: "center", of: window },
                  closeOnOutsideClick: true,
                },
                contentTemplate: function (dxDropDownBox) {
                  return $("<div>").dxBox({
                    direction: "row",
                    align: "space-around",
                    crossAlign: "stretch",
                    items: [
                      {
                        ratio: 1,
                        template: function () {
                          // COMPAÑÍAS
                          var container = $("<div>");
                          var title = $(
                            "<div class='font-size' style='margin-left:9px;'>" +
                              getTrad("compañia") +
                              "</div>",
                          );
                          var lista_compañias = $(
                            "<div id='dxDropDownBox_lista_compañias'>",
                          ).dxList({
                            dataSource: datasource_compañias,
                            selectionMode: "single",
                            height: "375px",
                            width: "95%",
                            keyExpr: "idCompañia",
                            scrolling: { mode: "infinite" },
                            pageLoadMode: "scrollBottom",
                            // EVENTOS
                            onContentReady: function (e) {
                              // SELECCIONA EL ITEM 0 POR DEFECTO
                              e.component.selectItem(0);
                            },
                            onSelectionChanged: function (e) {
                              var lista_entidades = $(
                                "#dxDropDownBox_lista_entidades",
                              ).dxList("instance");

                              if (
                                e.addedItems.length > 0 &&
                                e.addedItems[0].idCompañia !== 0
                              ) {
                                // SI EL ITEM SELECCIONADO NO ES TODOS
                                lista_entidades
                                  .getDataSource()
                                  .filter([
                                    [
                                      "idCompañia",
                                      "=",
                                      e.addedItems[0].idCompañia,
                                    ],
                                  ]);
                              } else if (
                                typeof lista_entidades !== "undefined"
                              ) {
                                //AL ABRIR POR PRIMERA VEZ lista_entidades es undefined
                                lista_entidades.getDataSource().filter("");
                              }
                              if (typeof lista_entidades !== "undefined")
                                lista_entidades.reload();
                            },
                            itemTemplate: function (data, index) {
                              return $("<div>").text(data.denominacion);
                            },
                          });

                          title.appendTo(container);
                          lista_compañias.appendTo(container);

                          return container;
                        },
                      },
                      {
                        // ENTIDADES
                        ratio: 1,
                        template: function () {
                          var container = $("<div>");
                          var title = $(
                            "<div class='font-size' style='margin-left:9px;'>" +
                              getTrad("entidad") +
                              "</div>",
                          );
                          var lista_entidades = $(
                            "<div id='dxDropDownBox_lista_entidades' class='dxList-pe-auto'>",
                          ).dxList({
                            //Datos
                            dataSource: datasource_entidades,
                            focusStateEnabled: false,
                            activeStateEnabled: false,
                            selectionMode: "single",
                            height: "375px",
                            width: "95%",
                            keyExpr: "idEntidad",
                            scrolling: { mode: "infinite" },
                            pageLoadMode: "scrollBottom",
                            //EVENTOS
                            onSelectionChanged: function (e) {
                              var lista_almacenes = $(
                                "#dxDropDownBox_lista_almacenes",
                              ).dxList("instance");

                              if (typeof lista_almacenes !== "undefined") {
                                var filtros = [];

                                if (e.addedItems.length > 0) {
                                  filtros.push([
                                    "idEntidad",
                                    "=",
                                    e.addedItems[0].idEntidad,
                                  ]);
                                  lista_almacenes
                                    .getDataSource()
                                    .filter(filtros);
                                  idEntidadSel = e.addedItems[0].idEntidad;
                                }
                                datasource_almacenes.reload();
                              }
                            },
                            itemTemplate: function (data, index, itemElement) {
                              let isDisabled = data.disabled == true;
                              let tooltip = $(
                                "#dxTooltip_RepartoOffice",
                              ).dxTooltip("instance");

                              let div = $("<div>")
                                .addClass("id_entidades" + data.idEntidad)
                                .text(data.denominacion)
                                .on("mouseout", function () {
                                  tooltip.hide();
                                });

                              if (isDisabled) {
                                $(itemElement)
                                  .parent()
                                  .on("mouseover", function () {
                                    tooltip.option({
                                      visible: isDisabled,
                                      target: ".id_entidades" + data.idEntidad,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  });
                              } else {
                                tooltip.option({
                                  showEvent: null,
                                  hideEvent: null,
                                });
                              }

                              $(e.element).on("mouseout", function () {
                                tooltip.hide();
                              });

                              div.appendTo(itemElement).text(data.denominacion);
                            },
                          });

                          title.appendTo(container);
                          lista_entidades.appendTo(container);

                          return container;
                        },
                      },
                      {
                        // ALMACENES
                        ratio: 1,
                        template: function (data, itemElement) {
                          var container = $("<div>");
                          var title = $(
                            "<div class='font-size' style='margin-left:9px;'>" +
                              getTrad("almacenes") +
                              "</div>",
                          );
                          var lista_almacenes = $(
                            "<div id='dxDropDownBox_lista_almacenes' class='dxList-pe-auto'>",
                          ).dxList({
                            //Datos
                            dataSource: datasource_almacenes,
                            focusStateEnabled: false,
                            activeStateEnabled: false,
                            selectionMode: "single",
                            keyExpr: "idAlmacen",
                            height: "375px",
                            scrolling: { mode: "infinite" },
                            pageLoadMode: "scrollBottom",
                            searchEnabled: true,
                            searchExpr: "denominacion",
                            //EVENTOS
                            onSelectionChanged: function (e) {
                              if (typeof e.addedItems[0] !== "undefined") {
                                if (
                                  dxDropDownBox.component.option("value") !==
                                  e.addedItems[0].idAlmacen
                                ) {
                                  dxDropDownBox.component.option(
                                    "value",
                                    e.addedItems[0].idAlmacen,
                                  );
                                  $("#dxDropDownBox_selCompaEnti")
                                    .dxDropDownBox("instance")
                                    .option("isValid", true);
                                }
                                deshabilitarEdicionCierreFact(
                                  idEntidadSel,
                                  fechaSel,
                                  fechaSel,
                                );
                              }
                            },
                            itemTemplate: function (data, index, itemElement) {
                              let isDisabled = data.disabled == true;
                              let tooltip = $(
                                "#dxTooltip_RepartoOffice",
                              ).dxTooltip("instance");

                              let div = $("<div>")
                                .addClass("id_almacenes" + data.idAlmacen)
                                .text(data.denominacion)
                                .on("mouseout", function () {
                                  tooltip.hide();
                                });

                              if (isDisabled) {
                                $(itemElement)
                                  .parent()
                                  .on("mouseover", function () {
                                    tooltip.option({
                                      visible: isDisabled,
                                      target: ".id_almacenes" + data.idAlmacen,
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("factBloqueadaEntiFecha"),
                                        );
                                      },
                                    });
                                    tooltip.show();
                                  });
                              } else {
                                tooltip.option({
                                  showEvent: null,
                                  hideEvent: null,
                                });
                              }

                              $(e.element).on("mouseout", function () {
                                tooltip.hide();
                              });

                              div.appendTo(itemElement).text(data.denominacion);
                            },
                          });

                          dxDropDownBox.component.on(
                            "valueChanged",
                            function (args) {
                              var grid = $(
                                "#dxDataGrid_NuevoReparto",
                              ).dxDataGrid("instance");
                              grid.option("editing.allowDeleting", repartoSel);
                              grid.cancelEditData();
                              grid.refresh();

                              args.component.close();
                            },
                          );

                          title.appendTo(container);
                          lista_almacenes.appendTo(container);

                          return container;
                        },
                      },
                    ],
                  });
                },
              });
              dxDropDownBox.appendTo(container);
              return container;
            },
          },
          {
            location: "after",
            widget: "dxSelectBox",
            options: {
              elementAttr: { id: "dxSelectBox_tipoReparto" },
              dataSource: enum_tipoReparto,
              valueExpr: "idTipoReparto",
              displayExpr: "denominacion",
              value: enum_tipoReparto[0].idTipoReparto,
              width: 150,
            },
          },
          {
            location: "after",
            widget: "dxDateBox",
            options: {
              elementAttr: { id: "dxDateBox_dbFechaReparto" },
              type: "datetime",
              displayFormat: "dd/MM/yyyy - HH:mm",
              value: convertClienteUtcToLavanderiaUtc(
                LAVANDERIA.GMT,
                new Date(),
              ),
              disabledDates: dxDateBox_disabledDates,
              width: 190,
              calendarOptions: {
                cellTemplate: function (e) {
                  let tooltip = $("#dxTooltip_RepartoOffice").dxTooltip(
                    "instance",
                  );
                  let result = $("<div>")
                    .addClass("id_" + formatDate_noTime_parameter(e.date))
                    .text(e.text)
                    .on("mouseenter", function () {
                      let fechasDeshabilitadas = dxDateBox_disabledDates.filter(
                        (fecha) =>
                          formatDate_noTime_parameter(fecha) ==
                          formatDate_noTime_parameter(e.date),
                      );
                      if (fechasDeshabilitadas.length > 0) {
                        tooltip.option({
                          target: ".id_" + formatDate_noTime_parameter(e.date),
                          contentTemplate: function (contentElement) {
                            contentElement.html(
                              getTrad("factBloqueadaEntiFecha"),
                            );
                          },
                        });
                        tooltip.show();
                      }
                    })
                    .on("mouseleave", function () {
                      tooltip.hide();
                    });
                  return result;
                },
              },
              onValueChanged: function (e) {
                fechaSel = e.value;
                deshabilitarEntidadesFacturacion(fechaSel, fechaSel);
              },
            },
          },
        ],
      });
    } else {
      dxMensajePregunta(getTrad("sinEntidadesConfiguradas"), [
        [
          getTrad("aceptar"),
          function (e) {
            return <Redirect to="/" />;
          },
          "danger",
          "btnErrorTipoReparto",
        ],
      ]);
    }
    //#endregion

    //#region DATAGRID NUEVO REPARTO (izquierda)

    $("#RepartosOffice #dxDataGrid_NuevoReparto").dxDataGrid({
      dataSource: datasource_prendaRepartoOffice,
      remoteOperations: true,
      //Propiedades
      columnsAutoWidth: true,
      editing: {
        mode: "batch",
        allowUpdating: true,
        allowDeleting: repartoSel,
        useIcons: true,
      },
      height: "100%",
      paging: {
        enabled: false,
      },
      keyboardNavigation: {
        enterKeyAction: "moveFocus",
        enterKeyDirection: "row",
        editOnKeyPress: true,
      },
      columns: [
        {
          dataField: "idPrenda",
          visible: false,
        },
        {
          dataField: "codigoPrenda",
          caption: getTrad("codigo"),
          width: "100px",
          alignment: "center",
          allowEditing: false,
          sortOrder: "asc",
        },
        {
          dataField: "denominacion",
          caption: getTrad("denominacion"),
          minWidth: "250px",
          allowEditing: false,
        },
        {
          dataField: "cantidad",
          caption: getTrad("cantidad"),
          width: "10%",
          alignment: "center",
          dataType: "number",
          cssClass: "dx-Cell_Editable",
          allowSorting: false,
          format: { style: "decimal", maximumFractionDigits: 0 },
          editorOptions: {
            step: 0,
            format: { style: "decimal", maximumFractionDigits: 0 },
            min: 0,
            max: 99999,
          },
          validationRules: [
            {
              type: "custom",
              message: getTrad("alerta_cantidadExcedida"),
              validationCallback: function (e) {
                if (e.data.cantidad * e.data.udsXBacOffice < 30000) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          ],
        },
        {
          dataField: "codigoElemRepOffice",
          caption: getTrad("elementoTransporte_abr"),
          width: "50px",
          allowEditing: false,
          allowHeaderFiltering: false,
          allowFiltering: false,
          alignment: "center",
          cssClass: "p-0",
          cellTemplate: function (container, options) {
            var contenedor = $("<div />")
              .addClass("container_spanCentrado")
              .css("padding-left", "10px");

            var svg;
            var htmlTooltip = "";
            if (options.data.codigoElemRepOffice === 1) {
              // POR UDS
              svg = svg_elemTrans_ud.clone();
              htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
            } else if (options.data.codigoElemRepOffice === 2) {
              // BAC/SACA
              svg = svg_elemTrans_bac.clone();
              htmlTooltip =
                "<p>" +
                getTrad("bacSaca") +
                ": " +
                options.data.udsXBacOffice +
                "</p>";
            }

            svg
              .css("height", "20px")
              .css("width", "25px")
              .css("cursor", "pointer")
              .appendTo(contenedor);

            tooltipControl_creacion(contenedor, htmlTooltip);

            return contenedor;
          },
        },
        {
          dataField: "peticion",
          caption: getTrad("total"),
          width: "8%",
          alignment: "center",
          allowEditing: false,
          allowHeaderFiltering: false,
          allowSorting: false,
          allowFiltering: false,
          format: { style: "decimal", maximumFractionDigits: 0 },
          calculateCellValue: function (e) {
            var cantidad = typeof (e.cantidad !== "undefined") ? e.cantidad : 0;
            return cantidad * e.udsXBacOffice;
          },
          // DEBIDO A LOS REGISTROS DE LA TABLA DE PRENDA N REVISIÓN QUE SON SMALLINT
          validationRules: [
            {
              type: "custom",
              message: getTrad("alerta_cantidadExcedida"),
              validationCallback: function (e) {
                if (e.data.cantidad * e.data.udsXBacOffice < 32767) {
                  return true;
                } else {
                  return false;
                }
              },
            },
          ],
        },
      ],
      //Eventos
      onRowUpdating: function (cellInfo) {
        cellInfo.newData.peticion =
          cellInfo.newData.cantidad * cellInfo.oldData.udsXBacOffice;
        delete cellInfo.newData.cantidad;
      },
      onToolbarPreparing: function (e) {
        //Modificar los iconos por defecto de guardar y cancelar del batchEdti
        $.each(e.toolbarOptions.items, function (index, item) {
          if (item.name === "saveButton") {
            item.visible = false;
          }
        });
        e.toolbarOptions.items.unshift(
          {
            location: "before",
            widget: "dxButton",
            toolbar: "bottom",
            options: {
              width: "100%",
              icon: "plus",
              text: getTrad("nuevoReparto"),
              type: "normal",
              onClick: function (e) {
                if (nuevoReparto_HasChanges()) {
                  dxMensajePregunta(
                    "<div style='width:310px;text-align:left;'><h4>" +
                      getTrad("alerta") +
                      "</h4><div style='font-size: 14px;'><p>" +
                      getTrad("preg_PerderCambios") +
                      "</p></div></div>",
                    [
                      [
                        getTrad("aceptar"),
                        function () {
                          nuevoReparto();
                        },
                        "danger",
                        "btnAceptar",
                      ],
                      [getTrad("cancelar"), function () {}],
                    ],
                  );
                } else {
                  nuevoReparto();
                }
              },
            },
          },
          {
            location: "after",
            widget: "dxButton",
            showText: "inMenu",
            locateInMenu: "auto",
            options: {
              text: getTrad("imprimir"),
              icon: " icon_Impresora",
              onClick: function (e) {
                if (repartoSel) {
                  if (!nuevoReparto_HasChanges()) {
                    var params = {
                      idsRepartosOffice: repartoSel.idRepartoOffice,
                      idLavanderia: LAVANDERIA.idLavanderia,
                    };
                    COMPONENT.setParams(params);
                  } else {
                    notify({
                      message: getTrad("alerta_GuardarParaImprimir"),
                      type: "error",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                  }
                } else {
                  notify({
                    message: getTrad("alerta_SeleccionarParaImprimir"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                }
              },
            },
          },
          {
            location: "before",
            widget: "dxButton",
            toolbar: "bottom",
            options: {
              width: "100%",
              icon: "plus",
              text: getTrad("añadirPrenda"),
              type: "success",
              disabled: isCerradoFactEntidad,
              elementAttr: {
                id: "btnAddPrenda",
              },
              visible: !repartoSel ? false : true,
              onClick: function (e) {
                var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");

                grid
                  .getController("validating")
                  .validate(true)
                  .done(function (grid_isValid) {
                    if (
                      grid_isValid &&
                      $("#dxDateBox_dbFechaReparto")
                        .dxDateBox("instance")
                        .option("isValid") === true
                    ) {
                      if (
                        typeof $("#dxList_añadirPrendas")[0] !== "undefined"
                      ) {
                        $("#dxList_añadirPrendas")
                          .dxList("instance")
                          .unselectAll();
                        datasource_añadirPrenda.load();
                      }

                      $("#popup_añadirPrendas").dxPopup("instance").show();
                    } else {
                      if (
                        $("#dxDateBox_dbFechaReparto")
                          .dxDateBox("instance")
                          .option("isValid") !== true
                      ) {
                        notify({
                          message: getTrad("alerta_fechaIncorrecta"),
                          type: "error",
                          displayTime: "2000",
                          closeOnClick: true,
                        });
                      } else {
                        notify({
                          message: getTrad("alerta_cantidadErronea"),
                          type: "error",
                          displayTime: "2000",
                          closeOnClick: true,
                        });
                      }
                    }
                  });
              },
            },
          },
          {
            location: "after",
            widget: "dxButton",
            options: {
              width: !repartoSel ? "100%" : "36px",
              icon: !repartoSel ? "check" : "save",
              type: !repartoSel ? "success" : "normal",
              text: !repartoSel ? getTrad("realizarReparto") : null,
              disabled: isCerradoFactEntidad,
              elementAttr: {
                id: "btnguardarReparto",
              },
              onClick: function (button) {
                var dxDataGrid_NuevoReparto = e.component;

                dxDataGrid_NuevoReparto
                  .getController("validating")
                  .validate(true)
                  .done(function (dxDataGrid_NuevoReparto_isValid) {
                    if (
                      dxDataGrid_NuevoReparto_isValid &&
                      $("#dxDateBox_dbFechaReparto")
                        .dxDateBox("instance")
                        .option("isValid") === true
                    ) {
                      if (
                        $("#dxDropDownBox_selCompaEnti")
                          .dxDropDownBox("instance")
                          .option("value")
                      ) {
                        button.component.option("disabled", true);
                        var prendaNReparto = [];

                        $.each(
                          dxDataGrid_NuevoReparto.getVisibleRows(),
                          function (index, item) {
                            if (item.rowType === "data") {
                              if (item.removed) {
                                return true;
                              }
                              var prenda = {};
                              prenda.idPrenda =
                                dxDataGrid_NuevoReparto.cellValue(
                                  item.rowIndex,
                                  "idPrenda",
                                );
                              prenda.cantidad =
                                dxDataGrid_NuevoReparto.cellValue(
                                  item.rowIndex,
                                  "peticion",
                                );

                              if (!repartoSel && prenda.cantidad > 0) {
                                prendaNReparto.push(prenda);
                              } else if (repartoSel) {
                                prendaNReparto.push(prenda);
                              }
                            }
                          },
                        );

                        if (nuevoReparto_HasChanges()) {
                          guardarReparto(prendaNReparto);
                        } else {
                          notify({
                            message: getTrad("alerta_SinCambios"),
                            type: "error",
                            displayTime: "2500",
                            closeOnClick: true,
                          });
                          $("#btnguardarReparto")
                            .dxButton("instance")
                            .option("disabled", false);
                        }
                      } else {
                        notify({
                          message: getTrad("alerta_SeleccionaAlmacen"),
                          type: "error",
                          displayTime: "2000",
                          closeOnClick: true,
                        });
                      }
                    } else {
                      if (
                        $("#dxDateBox_dbFechaReparto")
                          .dxDateBox("instance")
                          .option("isValid") !== true
                      ) {
                        notify({
                          message: getTrad("alerta_fechaIncorrecta"),
                          type: "error",
                          displayTime: "2000",
                          closeOnClick: true,
                        });
                      } else {
                        notify({
                          message: getTrad("alerta_cantidadErronea"),
                          type: "error",
                          displayTime: "2000",
                          closeOnClick: true,
                        });
                      }
                    }
                  });
              },
            },
          },
        );
      },
      //Estilos
      showColumnLines: false,
      showRowLines: true,
      rowAlternationEnabled: true,
    });

    //    #endregion

    //#region DATAGRID REPARTOS ANTERIORES (derecha)

    dxDataGrid_RepartosAnteriores = $(
      "#RepartosOffice #dxDataGrid_RepartosAnteriores",
    )
      .dxDataGrid({
        //Datos
        dataSource: datasource_repartosOfficeAnteriores,
        remoteOperations: true,
        //Propiedades
        columnsAutoWidth: true,
        height: "100%",
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        headerFilter: {
          visible: true,
        },
        searchPanel: {
          visible: true,
          width: 240,
          placeholder: getTrad("busqueda"),
        },
        selection: {
          mode: "single",
        },
        paging: {
          pageSize: 50,
        },
        pager: {
          showPageSizeSelector: true,
          allowedPageSizes: [20, 50, 100],
          showInfo: true,
        },
        hoverStateEnabled: true,
        columns: [
          {
            caption: " ",
            cssClasd: "p-0",
            width: 40,
            alignment: "center",
            allowHeaderFiltering: false,
            cellTemplate: function (cellElement, cellInfo) {
              if (cellInfo.data && cellInfo.data.idArchivo_firma !== null) {
                function abrirFirmas(e) {
                  if (idArchivoFirmaSel === cellInfo.data.idArchivo_firma) {
                    abrirPopup();
                  } else {
                    idArchivoFirmaSel = cellInfo.data.idArchivo_firma;
                    datasource_tblArchivo_firma.reload().done(function () {
                      abrirPopup();
                    });
                  }
                }

                function abrirPopup() {
                  let popup = $("#dxPopup_firmas").dxPopup("instance");
                  popup.option({
                    contentTemplate: (e) => dxPopup_firmas_ContentTemplate(e),
                  });
                  popup.show();
                }

                cellElement.append(
                  $("<div />")
                    .css("font-size", "18px")
                    .addClass("icon_Sign")
                    .on("click", abrirFirmas),
                );
              }
            },
          },
          {
            dataField: "fecha",
            caption: getTrad("fecha"),
            dataType: "date",
            format: "dd/MM/yyyy - HH:mm",
            width: 175,
            alignment: "center",
            sortOrder: "desc",
            allowHeaderFiltering: false,
            filterValue: filtro_fechasAnteriores
              ? new Date(formatDate_parameter(filtro_fechasAnteriores))
              : null,
            filterOperations: [">=", "="],
            selectedFilterOperation: ">=",
            editorOptions: {
              type: "date",
              displayFormat: "dd/MM/yyyy",
            },
          },
          {
            dataField: "idEntidad",
            visible: false,
            allowFiltering: false,
            allowSorting: false,
          },
          {
            dataField: "denoEnti",
            caption: getTrad("entidad"),
            minWidth: 130,
            alignment: "left",
            cellTemplate: function (cellElement, cellInfo) {
              var container = $("<div />")
                .text(cellInfo.text)
                .css("position", "relative");
              let text;
              if (cellInfo.data.isCerradoFactEntidad) {
                text = getTrad("factBloqueadaEntiFecha");
                container.append(
                  svg_lock_outline
                    .clone()
                    .css("height", "25px")
                    .css("width", "25px")
                    .css("cursor", "pointer")
                    .css("position", "absolute")
                    .css("right", "0px")
                    .css("top", "-3px"),
                );
                tooltipControl_creacion(container, text);
              }
              cellElement.append(container);
            },
          },
          {
            dataField: "denoAlm",
            caption: getTrad("almacen"),
            minWidth: 170,
            alignment: "left",
          },
          {
            dataField: "tipoReparto",
            caption: getTrad("tipoReparto"),
            width: 90,
            alignment: "left",
            allowHeaderFiltering: true,
            allowFiltering: false,
            calculateDisplayValue: function (e) {
              if (e.tipoReparto === true) {
                return getTrad("extra").toUpperCase();
              } else {
                return getTrad("normal").toUpperCase();
              }
            },
            calculateCellValue: function (e) {
              if (e.tipoReparto === true) {
                return 1;
              } else {
                return 0;
              }
            },
            headerFilter: {
              allowSearch: true,
              dataSource: [
                {
                  text: getTrad("normal").toUpperCase(),
                  value: false,
                },
                {
                  text: getTrad("extra").toUpperCase(),
                  value: true,
                },
              ],
            },
          },
          {
            dataField: "isApp",
            caption: "",
            width: 100,
            alignment: "center",
            allowHeaderFiltering: false,
            allowFiltering: false,
            cellTemplate: function (container, options) {
              var img;
              img = options.data.isApp ? "icon_mobile" : "menu_icons_Dashboard";
              container.append(
                $("<div />")
                  .attr("class", img)
                  .css("font-size", "25px")
                  .appendTo(container),
              );
            },
          },
        ],
        //Eventos
        onRowPrepared: function (info) {
          if (info.rowType === "data") {
            info.rowElement.css("cursor", "pointer");
          }
        },
        onSelectionChanged: function (e) {
          if (e.selectedRowsData.length > 0) {
            var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");

            if (nuevoReparto_HasChanges()) {
              var popup =
                typeof $("#dxPopup_AlertPregunta").data("dxPopup") !==
                "undefined"
                  ? $("#dxPopup_AlertPregunta").dxPopup("instance")
                  : null;
              if ((popup && !popup.option("visible")) || !popup) {
                dxMensajePregunta(
                  "<div style='width:310px;text-align:left;'><h4>" +
                    getTrad("alerta") +
                    "</h4><div style='font-size: 14px;'><p>" +
                    getTrad("preg_PerderCambios") +
                    "</p></div></div>",
                  [
                    [
                      getTrad("aceptar"),
                      function () {
                        //Al limpiar el repartoSel la próxima vez que entre en el evento onSelectionChanged no detectará cambios.
                        repartoSel = null;
                        grid.cancelEditData();

                        e.component.option(
                          "selectedRowKeys",
                          e.currentSelectedRowKeys,
                        );
                      },
                      "danger",
                      "btnAceptar",
                    ],
                    [getTrad("cancelar"), function () {}],
                  ],
                );
                e.component.option(
                  "selectedRowKeys",
                  e.currentDeselectedRowKeys,
                );
              }
            } else {
              var data = e.selectedRowsData[0];
              dxDataGrid_NuevoReparto_reloadData(data);
            }
          } else {
            repartoSel = null;
          }
        },
        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift(
            {
              location: "after",
              widget: "dxButton",
              locateInMenu: "auto",
              options: {
                text: getTrad("impresionAgrupada"),
                icon: " icon_Impresora",
                onClick: function () {
                  var dxDataGrid_repartosOfficeAnteriores_impresionAgrupada = $(
                    "#dxDataGrid_repartosOfficeAnteriores_impresionAgrupada",
                  ).dxDataGrid("instance");
                  var filtro_tipoReparto = e.component.columnOption(
                    "tipoReparto",
                    "filterValues",
                  )
                    ? e.component.columnOption("tipoReparto", "filterValues")[0]
                    : null;
                  var fecha = filtro_fechasAnteriores
                    ? new Date(formatDate_parameter(filtro_fechasAnteriores))
                    : null;
                  if (
                    typeof dxDataGrid_repartosOfficeAnteriores_impresionAgrupada !==
                    "undefined"
                  ) {
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.refresh();

                    //#region GRID POPUP IMPRESIÓN COGE FILTROS Y ORDENACIÓN DEL GRID DE REPARTOS ANTERIORES
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "fecha",
                      "selectedFilterOperation",
                      e.component.columnOption(
                        "fecha",
                        "selectedFilterOperation",
                      ),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "fecha",
                      "filterValue",
                      fecha,
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "fecha",
                      "sortOrder",
                      e.component.columnOption("fecha", "sortOrder"),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "denoEnti",
                      "filterValue",
                      e.component.columnOption("denoEnti", "filterValue"),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "denoEnti",
                      "sortOrder",
                      e.component.columnOption("denoEnti", "sortOrder"),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "denoAlm",
                      "filterValue",
                      e.component.columnOption("denoAlm", "filterValue"),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "denoAlm",
                      "sortOrder",
                      e.component.columnOption("denoAlm", "sortOrder"),
                    );
                    dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.columnOption(
                      "tipoReparto",
                      "filterValues",
                      filtro_tipoReparto,
                    );

                    $(
                      "#dxDataGrid_repartosOfficeAnteriores_impresionAgrupada",
                    ).dxDataGrid(
                      "searchByText",
                      e.component.option("searchPanel.text"),
                    );
                    //#endregion
                  }
                  $("#popup_seleccionImpresionMultiple")
                    .dxPopup("instance")
                    .show();
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              locateInMenu: "auto",
              options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onClick: function () {
                  e.component.clearFilter();
                  e.component.columnOption("fecha", "filterValue", null);
                  notify({
                    message: getTrad("aviso_C_FiltroRestaurado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              options: {
                icon: "refresh",
                text: getTrad("actualizar"),
                onClick: function () {
                  e.component.refresh(true);
                },
              },
            },
          );
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      })
      .dxDataGrid("instance");

    //#endregion

    //POPUP AÑADIR PRENDAS
    $("#RepartosOffice #popup_añadirPrendas").dxPopup({
      showTitle: true,
      title: getTrad("añadirPrenda"),
      height: "80%",
      width: "35%",
      minWidth: "500px",
      contentTemplate: function () {
        return $("<div id='dxList_añadirPrendas' />").dxList({
          dataSource: datasource_añadirPrenda,
          selectionMode: "multiple",
          showSelectionControls: false,
          itemTemplate: function (data, index, item) {
            var content = $("<div>")
              .css("display", "flex")
              .css("margin", "0px 15px");
            content.append(
              $("<div>").text(data.codigoPrenda).css("flex-basis", 80),
            );
            content.append($("<div>").text(data.denominacion).css("flex", 1));

            var container = $("<div />")
              .addClass("container_spanCentrado")
              .css("padding-left", "10px")
              .css("flex-basis", 100);
            var svg;
            var htmlTooltip = "";
            if (data.codigoElemRepOffice === 1) {
              // POR UDS
              svg = svg_elemTrans_ud.clone();
              htmlTooltip = "<p>" + data.denoElemRepOffice + "</p>";
            } else if (data.codigoElemRepOffice === 2) {
              // BAC/SACA
              svg = svg_elemTrans_bac.clone();
              htmlTooltip =
                "<p>" +
                data.denoElemRepOffice +
                ": " +
                data.udsXBacOffice +
                "</p>";
            }

            svg
              .css("height", "20px")
              .css("width", "25px")
              .css("cursor", "pointer")
              .appendTo(container);

            tooltipControl_creacion(container, htmlTooltip);

            content.append(container);

            return content;
          },
        });
      },
      toolbarItems: [
        {
          toolbar: "bottom",
          location: "after",
          widget: "dxButton",
          options: {
            text: getTrad("añadir"),
            type: "success",
            onClick: function (e) {
              //Añadir prenda a las prendas N reparto
              guardando = true;
              var dxDataGrid_NuevoReparto = $(
                "#dxDataGrid_NuevoReparto",
              ).dxDataGrid("instance");
              var prendasSeleccionadas = $("#dxList_añadirPrendas")
                .dxList("instance")
                .option("selectedItems");

              if (prendasSeleccionadas.length > 0) {
                var prendaNRepartoGrid = [];

                $.each(
                  dxDataGrid_NuevoReparto.getVisibleRows(),
                  function (index, item) {
                    if (item.rowType === "data") {
                      var prenda = {};
                      prenda.idPrenda = dxDataGrid_NuevoReparto.cellValue(
                        item.rowIndex,
                        "idPrenda",
                      );
                      prenda.cantidad = dxDataGrid_NuevoReparto.cellValue(
                        item.rowIndex,
                        "peticion",
                      );
                      if (item.removed) {
                        return true;
                      }
                      prendaNRepartoGrid.push(prenda);
                    }
                  },
                );

                $.each(prendasSeleccionadas, function (index, item) {
                  var prendaNReparto = {};
                  prendaNReparto.idPrenda = item.idPrenda;
                  prendaNReparto.cantidad = 0;
                  prendaNRepartoGrid.push(prendaNReparto);
                });

                guardarReparto(prendaNRepartoGrid);

                $("#popup_añadirPrendas").dxPopup("instance").hide();
              }
            },
          },
        },
      ],
    });

    $("#RepartosOffice #popup_seleccionImpresionMultiple").dxPopup({
      showTitle: true,
      title: getTrad("impresionAgrupada"),
      height: "80%",
      width: "50%",
      minWidth: "650px",
      contentTemplate: function () {
        return $(
          "<div id='dxDataGrid_repartosOfficeAnteriores_impresionAgrupada'>",
        ).dxDataGrid({
          //Datos
          dataSource: datasource_repartosAnteriores_impresionAgrupada,
          remoteOperations: true,
          //Propiedades
          columnsAutoWidth: true,
          height: "100%",
          filterRow: {
            visible: true,
            applyFilter: "auto",
          },
          headerFilter: {
            visible: true,
          },
          searchPanel: {
            visible: true,
            width: 240,
            placeholder: getTrad("busqueda"),
          },
          selection: {
            mode: "multiple",
            selectAllMode: "allPages",
            showCheckBoxesMode: "always",
          },
          paging: {
            pageSize: 50,
          },
          pager: {
            showPageSizeSelector: true,
            allowedPageSizes: [20, 50, 100],
            showInfo: true,
          },
          hoverStateEnabled: true,
          columns: [
            {
              dataField: "fecha",
              caption: getTrad("fecha"),
              dataType: "date",
              format: "dd/MM/yyyy - HH:mm",
              width: 175,
              alignment: "center",
              sortOrder: dxDataGrid_RepartosAnteriores.columnOption(
                "fecha",
                "sortOrder",
              ),
              allowHeaderFiltering: false,
              filterValue: filtro_fechasAnteriores
                ? new Date(formatDate_parameter(filtro_fechasAnteriores))
                : null,
              filterOperations: [">=", "="],
              selectedFilterOperation:
                dxDataGrid_RepartosAnteriores.columnOption(
                  "fecha",
                  "selectedFilterOperation",
                ),
              editorOptions: {
                type: "date",
                displayFormat: "dd/MM/yyyy",
              },
            },
            {
              dataField: "denoEnti",
              caption: getTrad("entidad"),
              minWidth: 130,
              alignment: "left",
              sortOrder: dxDataGrid_RepartosAnteriores.columnOption(
                "denoEnti",
                "sortOrder",
              ),
              filterValue: dxDataGrid_RepartosAnteriores.columnOption(
                "denoEnti",
                "filterValue",
              ),
            },
            {
              dataField: "denoAlm",
              caption: getTrad("almacen"),
              minWidth: 170,
              alignment: "left",
              sortOrder: dxDataGrid_RepartosAnteriores.columnOption(
                "denoAlm",
                "sortOrder",
              ),
              filterValue: dxDataGrid_RepartosAnteriores.columnOption(
                "denoAlm",
                "filterValue",
              ),
            },
            {
              dataField: "tipoReparto",
              caption: getTrad("tipoReparto"),
              width: 110,
              alignment: "left",
              allowHeaderFiltering: true,
              allowFiltering: false,
              calculateDisplayValue: function (e) {
                if (e.tipoReparto === true) {
                  return getTrad("extra").toUpperCase();
                } else {
                  return getTrad("normal").toUpperCase();
                }
              },
              calculateCellValue: function (e) {
                if (e.tipoReparto === true) {
                  return 1;
                } else {
                  return 0;
                }
              },
              headerFilter: {
                allowSearch: true,
                dataSource: [
                  {
                    text: getTrad("normal").toUpperCase(),
                    value: false,
                  },
                  {
                    text: getTrad("extra").toUpperCase(),
                    value: true,
                  },
                ],
              },
              sortOrder: dxDataGrid_RepartosAnteriores.columnOption(
                "tipoReparto",
                "sortOrder",
              ),
              filterValue: dxDataGrid_RepartosAnteriores.columnOption(
                "tipoReparto",
                "filterValues",
              )
                ? dxDataGrid_RepartosAnteriores.columnOption(
                    "tipoReparto",
                    "filterValues",
                  )[0]
                : null,
            },
          ],
          //Eventos
          onContentReady: function (e) {
            if (
              String(dxDataGrid_impresionAgrupada_filtro) !==
              String(e.component.getCombinedFilter())
            ) {
              var grid = e.component;
              var selectedKeys = grid.getSelectedRowKeys();
              for (var i = 0; i < selectedKeys.length; i++) {
                grid.deselectRows([selectedKeys[i]]);
              }
              dxDataGrid_impresionAgrupada_filtro =
                e.component.getCombinedFilter();
            }
          },
          onToolbarPreparing: function (e) {
            e.toolbarOptions.items.unshift(
              {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                  text: getTrad("limpiarFiltro"),
                  icon: " icon_EliminarFiltros",
                  onClick: function () {
                    e.component.clearFilter();
                    e.component.columnOption("fecha", "filterValue", null);
                    notify({
                      message: getTrad("aviso_C_FiltroRestaurado"),
                      type: "success",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                  },
                },
              },
              {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                  icon: "refresh",
                  text: getTrad("actualizar"),
                  onClick: function () {
                    e.component.refresh(true);
                  },
                },
              },
            );
          },
          //Estilos
          showColumnLines: false,
          showRowLines: true,
          rowAlternationEnabled: true,
        });
      },
      toolbarItems: [
        {
          toolbar: "bottom",
          location: "after",
          widget: "dxButton",
          options: {
            text: getTrad("imprimir"),
            type: "success",
            onClick: function (e) {
              var grid = $(
                "#dxDataGrid_repartosOfficeAnteriores_impresionAgrupada",
              ).dxDataGrid("instance");
              var rowsSeleccionadas = grid.getSelectedRowKeys().length;

              if (rowsSeleccionadas > 0) {
                var idsRepartoOffice = [];
                var denoAlmacenes = [];
                var denoEntis = [];
                var fechas = [];
                $.each(grid.getSelectedRowsData(), function (i, data) {
                  // ID REPARTO OFFICE
                  idsRepartoOffice.push(data.idRepartoOffice);
                  // ALMACENES DIFERENTES
                  if ($.inArray(data.denoAlm, denoAlmacenes) === -1) {
                    denoAlmacenes.push(data.denoAlm);
                  }
                  // ENTIDADES DIFERENTES
                  if ($.inArray(data.denoEnti, denoEntis) === -1) {
                    denoEntis.push(data.denoEnti);
                  }
                  // FECHAS DIFERENTES
                  if ($.inArray(formatDate(data.fecha), fechas) === -1) {
                    fechas.push(formatDate(data.fecha));
                  }
                });
                if (denoAlmacenes.length <= 100 && fechas.length <= 30) {
                  var params = {
                    idsRepartosOffice: idsRepartoOffice.join("|"),
                    idLavanderia: LAVANDERIA.idLavanderia,
                  };
                  COMPONENT.setParams(params);
                  $("#popup_seleccionImpresionMultiple")
                    .dxPopup("instance")
                    .hide();
                } else {
                  notify({
                    message: getTrad("aviso_superadoLimite_seleccionRepartos"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                }
              } else {
                notify({
                  message: getTrad("aviso_I_SeleccionaReparto"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              }
            },
          },
        },
      ],
      onHiding: function (e) {
        var dxDataGrid_repartosOfficeAnteriores_impresionAgrupada = $(
          "#dxDataGrid_repartosOfficeAnteriores_impresionAgrupada",
        ).dxDataGrid("instance");
        dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.deselectAll();
        dxDataGrid_repartosOfficeAnteriores_impresionAgrupada.clearFilter();
      },
    });

    // POPUP FIRMA
    function dxPopup_firmas_ContentTemplate(contentElement) {
      let repartoFirma = datasource_tblArchivo_firma.items()[0];
      contentElement.append(
        $("<div />").dxBox({
          direction: "col",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          width: "100%",
          items: [
            {
              ratio: 1,
              template: function () {
                return $(
                  "<img src='data:image/png;base64," +
                    repartoFirma.archivo +
                    "'/>",
                ) // Imagen firma
                  .css("border", "2px solid")
                  .css("max-width", "600px")
                  .css("max-height", "100%")
                  .css("margin", "auto");
              },
            },
            {
              baseSize: 75,
              template: function () {
                return $("<div />")
                  .addClass("pt-3")
                  .dxForm({
                    labelLocation: "top",
                    height: "100%",
                    scrollingEnabled: false,
                    colCount: 1,
                    formData: repartoFirma,
                    items: [
                      {
                        dataField: "firmante",
                        label: { text: getTrad("firmante") },
                        editorOptions: {
                          disabled: true,
                        },
                      },
                    ],
                  });
              },
            },
          ],
        }),
      );
    }

    $("#RepartosOffice #dxPopup_firmas").dxPopup({
      showTitle: true,
      showCloseButton: true,
      title: getTrad("validacionInventario"),
      height: 400,
      width: 700,
      deferRendering: true,
      contentTemplate: dxPopup_firmas_ContentTemplate,
    });

    deferred.resolve();
  });

  function nuevoReparto() {
    var lista_entidades = $("#dxDropDownBox_lista_entidades").dxList(
      "instance",
    );
    var lista_almacenes = $("#dxDropDownBox_lista_almacenes").dxList(
      "instance",
    );
    $("#dxDropDownBox_lista_compañias").dxList("instance").selectItem(0);
    lista_entidades.selectItem(0);

    if (repartoSel) {
      if (
        dxDataGrid_RepartosAnteriores.option("selectedRowKeys").length === 0
      ) {
        repartoSel = null;
      } else {
        dxDataGrid_RepartosAnteriores.option("selectedRowKeys", []);
      }
      dxDataGrid_RepartosAnteriores.repaint();
    }

    var gridRepartos = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");
    gridRepartos.cancelEditData();

    lista_entidades.getDataSource().filter(null);
    lista_entidades.reload();
    lista_almacenes.getDataSource().filter(null); // para limpiar el filtro aplicado por la lista de entidad
    lista_almacenes.getDataSource().searchValue(null); // para limpiar el campo de búsqueda de la lista
    lista_almacenes.reload();

    $("#dxToolbar_NuevoReparto").dxToolbar("instance").repaint();
    $("#dxSelectBox_tipoReparto")
      .dxSelectBox("instance")
      .option("disabled", false);

    $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").open();

    $("#dxSelectBox_tipoReparto")
      .dxSelectBox("instance")
      .option("value", enum_tipoReparto[0].idTipoReparto);
    $("#dxDateBox_dbFechaReparto")
      .dxDateBox("instance")
      .option("value", new Date());

    gridRepartos.option("editing.allowDeleting", repartoSel);
    gridRepartos.refresh(true);
    gridRepartos.repaint();

    deshabilitarEdicionCierreFact(null, fechaSel, fechaSel);
  }

  function nuevoReparto_HasChanges() {
    if (guardando) {
      return false;
    }

    if (!repartoSel) {
      // Para que al cambiar de reparto estando creando uno nuevo, te salte la ventana de descarte/cancelar.
      if (
        $("#dxDataGrid_NuevoReparto")
          .dxDataGrid("instance")
          .getController("editing")
          .hasChanges()
      ) {
        return true;
      }
      return false;
    }

    var hasChanges = false;

    hasChanges = $("#dxDataGrid_NuevoReparto")
      .dxDataGrid("instance")
      .getController("editing")
      .hasChanges();
    hasChanges =
      hasChanges ||
      formatDate(
        $("#dxDateBox_dbFechaReparto").dxDateBox("instance").option("value"),
      ) !== formatDate(repartoSel.fecha);

    return hasChanges;
  }

  function guardarReparto(prendaNReparto) {
    guardando = true;
    var reparto = {
      isApp: false,
      idLavanderia: LAVANDERIA.idLavanderia,
      idEntidad: repartoSel
        ? null
        : $("#dxDropDownBox_lista_entidades")
            .dxList("instance")
            .option("selectedItemKeys")[0],
      fecha: $("#dxDateBox_dbFechaReparto")
        .dxDateBox("instance")
        .option("value"),
      repartoExtra:
        $("#dxSelectBox_tipoReparto")
          .dxSelectBox("instance")
          .option("value") === 0
          ? false
          : true,
      idRevision: repartoSel ? repartoSel.idRevision : null,
      idPedidoExtra: repartoSel ? repartoSel.idPedidoExtra : null,
      tblPrendaNRepartoOffice: prendaNReparto,
    };

    if (!repartoSel) {
      datasource_prendaRepartoOffice
        .store()
        .insert(reparto)
        .done(function (data) {
          dxDataGrid_RepartosAnteriores.refresh().done(function () {
            notify({
              message: getTrad("aviso_C_RegistroInsertado") + ".",
              type: "success",
              displayTime: "2500",
              closeOnClick: true,
            });
            if (
              dxDataGrid_RepartosAnteriores.getRowIndexByKey(
                data.idRepartoOffice,
              ) > -1
            ) {
              dxDataGrid_RepartosAnteriores.option("selectedRowKeys", [
                data.idRepartoOffice,
              ]);
            } else {
              dxDataGrid_NuevoReparto_reloadData(data);
            }
          });
          $("#btnguardarReparto")
            .dxButton("instance")
            .option("disabled", false);
        });
    } else {
      datasource_prendaRepartoOffice
        .store()
        .update(repartoSel.idRepartoOffice, reparto)
        .done(function (data) {
          //Seteamos los cambios en la variable global repartoSel para que no detecte que siga habiendo cambios.
          repartoSel.fecha = reparto.fecha;

          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "2500",
            closeOnClick: true,
          });
          dxDataGrid_RepartosAnteriores.refresh(true);
          var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");
          grid.refresh(true).done(function () {
            grid.option("editing.allowDeleting", repartoSel);
            if (guardando) {
              guardando = false;
              grid.cancelEditData();
            }
          });
          grid.repaint();
          $("#btnguardarReparto")
            .dxButton("instance")
            .option("disabled", false);
          if (
            dxDataGrid_RepartosAnteriores.getRowIndexByKey(
              data.idRepartoOffice,
            ) > -1
          ) {
            dxDataGrid_RepartosAnteriores.option("selectedRowKeys", [
              data.idRepartoOffice,
            ]);
          }
        });
    }
    return;
  }

  function dxDataGrid_NuevoReparto_reloadData(data) {
    repartoSel = data;
    idEntidadSel = repartoSel.idEntidad;
    fechaSel = repartoSel.fecha;

    $.when(
      deshabilitarEdicionCierreFact(idEntidadSel, fechaSel, fechaSel),
    ).then(function () {
      var tipoReparto = repartoSel.tipoReparto === false ? 0 : 1;
      var grid = $("#dxDataGrid_NuevoReparto").dxDataGrid("instance");

      if (repartoSel.idRevision) datasource_tblPrendaNRevision.load();

      if (repartoSel.idPedidoExtra) datasource_tblPrendaNPedidoExtra.load();

      grid.refresh().done(function () {
        grid.option(
          "editing.allowDeleting",
          !isCerradoFactEntidad && repartoSel,
        );
        $("#dxDataGrid_NuevoReparto").dxDataGrid(
          "columnOption",
          "cantidad",
          "cssClass",
          !isCerradoFactEntidad ? "dx-Cell_Editable" : "",
        );
        if (guardando) {
          guardando = false;
          grid.cancelEditData();
        }
      });
      grid.repaint();
      grid.option("editing.allowUpdating", !isCerradoFactEntidad);

      $("#dxToolbar_NuevoReparto").dxToolbar("instance").repaint();
      deshabilitarEdicionCierreFact(idEntidadSel, fechaSel, fechaSel); // Si no lo cargo, repaint de dxToolbar_dgvNuevoAbono quita disabledDates de calendario

      $("#btnAddPrenda").dxButton("instance").option({
        disabled: isCerradoFactEntidad,
      });
      $("#btnguardarReparto").dxButton("instance").option({
        disabled: isCerradoFactEntidad,
      });

      $("#dxSelectBox_tipoReparto").dxSelectBox("instance").option({
        value: tipoReparto,
        disabled: true,
      });
      $("#dxDateBox_dbFechaReparto").dxDateBox("instance").option({
        value: repartoSel.fecha,
        disabled: isCerradoFactEntidad,
      });
    });
  }

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (report === "albaran_repartosOffice") {
      if (
        e.parameter.name === "idsRepartosOffice" ||
        e.parameter.name === "idLavanderia"
      ) {
        //INVISIBLES
        e.info.editor = $.extend({}, e.info.editor);
        e.info.visible = false;
      }
    }
  }

  return deferred.promise();
}
