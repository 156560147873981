import React from "react";

import DataGrid, { Column, Paging, Sorting } from "devextreme-react/data-grid";

import { getTrad } from "helpers";

const typesByField = {
  fechaAntiguedad: "date",
  codigoGestoria: "string",
  numDocumentoIdentidad: "string",
  NAF: "string",
  IBAN: "string",
};

const formatNumber = {
  style: "currency",
  maximumFractionDigits: 2,
  currency: "EUR",
};

const formatByField = {
  fechaAntiguedad: "dd/MM/yyyy",
};

const DataGridConflictos = ({ resumenConflictos }) => {
  const onCellPrepared = ({ column, cellElement }) => {
    if (column?.dataField === "valor") {
      cellElement.css("color", "var(--primary)");
      cellElement.css("font-weight", "bold");
    }
    if (column?.dataField === "valorA3") {
      cellElement.css("color", "var(--blue)");
      cellElement.css("font-weight", "bold");
    }
  };

  const calculateCellValue_valor = ({ valor }) => {
    if (valor !== null) {
      return typesByField[resumenConflictos.field] === "date"
        ? new Date(valor)
        : valor;
    } else {
      return "Sin registrar";
    }
  };

  const calculateCellValue_valorA3 = ({ valorA3 }) => {
    if (valorA3 !== null) {
      return typesByField[resumenConflictos.field] === "date"
        ? new Date(valorA3)
        : valorA3;
    } else {
      return "No encontrado";
    }
  };

  const calculateCellValue_diferencia = (row) => {
    if (typesByField[resumenConflictos.field] === "date") {
      const diffTime = new Date(row.valor) - new Date(row.valorA3);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Diferencia en días
      return diffDays;
    } else if (typesByField[resumenConflictos.field] === undefined) {
      return row.valor - row.valorA3;
    }
  };

  const dataType = typesByField[resumenConflictos.field] || "number";
  const format = formatByField[resumenConflictos.field] || formatNumber;

  return (
    <DataGrid
      dataSource={resumenConflictos.conflictos}
      height={640}
      width={"100%"}
      columnAutoWidth
      hoverStateEnabled
      rowAlternationEnabled
      showRowLines
      allowColumnReordering
      showColumnLines={false}
      onCellPrepared={onCellPrepared}
    >
      <Sorting mode={"multiple"} />
      <Paging enabled={false} />
      <Column
        dataField={"nombre"}
        caption={getTrad("nombre")}
        dataType={"string"}
        sortIndex={0}
        sortOrder={"asc"}
        width={"25%"}
      />
      <Column
        dataField={"apellidos"}
        caption={getTrad("apellidos")}
        dataType={"string"}
        sortIndex={1}
        sortOrder={"asc"}
        width={"25%"}
      />
      <Column
        dataField={"valor"}
        caption={getTrad("polarier")}
        alignment={"right"}
        dataType={dataType}
        format={format}
        calculateCellValue={calculateCellValue_valor}
      />
      <Column
        dataField={"valorA3"}
        caption={getTrad("gestoria")}
        alignment={"right"}
        dataType={dataType}
        format={format}
        calculateCellValue={calculateCellValue_valorA3}
      />
      <Column
        name={"diferencia"}
        caption={
          typesByField[resumenConflictos.field] === "date"
            ? getTrad("diferencia") + " (días)"
            : getTrad("diferencia")
        }
        alignment={"right"}
        dataType={"number"}
        format={
          typesByField[resumenConflictos.field] === undefined
            ? formatNumber
            : undefined
        }
        visible={typesByField[resumenConflictos.field] !== "string"}
        calculateCellValue={calculateCellValue_diferencia}
      />
    </DataGrid>
  );
};

export default DataGridConflictos;
