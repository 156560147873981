import React, { useMemo, useCallback } from "react";

import SharedLookupAlmacen from "../SharedLookupAlmacen";
import idsTipoMovimientoRecambio from "constants/enums/Assistant/tblTipoMovimientoRecambio";
import { datasource_tblAlmacenRecambios } from "../../../api";
import { getTrad } from "helpers";

export const destinoOptions = {
  dataField: "idAlmacenDestino",
  editorType: "dxLookup",
  colSpan: 3,
  isRequired: true,
};

const AlmacenDestinoItem = ({
  formRef,
  formData: { idTipoMovimientoRecambio, idAlmacenOrigen, idAlmacenDestino },
  onChange,
  ...props
}) => {
  const isTraspaso = useMemo(
    () =>
      idTipoMovimientoRecambio ===
      idsTipoMovimientoRecambio.traspasoEntreAlmacenes,
    [idTipoMovimientoRecambio]
  );

  const almacenOrigen = useMemo(
    () =>
      datasource_tblAlmacenRecambios
        .items()
        .find((ar) => ar.idAlmacen === idAlmacenOrigen),
    [idAlmacenOrigen]
  );

  const noDataText = useMemo(
    () =>
      !almacenOrigen
        ? getTrad("debeSeleccionarAlmacenOrigen")
        : getTrad("sinDatosParaMostrar"),
    [almacenOrigen]
  );

  const filter = useCallback(
    (ar) => {
      if (!isTraspaso) return true;
      if (!almacenOrigen) return false;

      const isAlmacenDiferente = ar.idAlmacen !== almacenOrigen.idAlmacen;
      const ifPadre =
        almacenOrigen.idAlmacenPadre === null &&
        (ar.idAlmacenPadre === null ||
          ar.idAlmacenPadre === almacenOrigen.idAlmacen);
      const ifHijo =
        almacenOrigen.idAlmacenPadre !== null &&
        (ar.idAlmacenPadre === almacenOrigen.idAlmacenPadre ||
          ar.idAlmacen === almacenOrigen.idAlmacenPadre);

      return isAlmacenDiferente && (ifPadre || ifHijo);
    },
    [isTraspaso, almacenOrigen]
  );

  const this_onChange = (value) => {
    formRef.current.instance.updateData("idAlmacenDestino", value);
    onChange(value);
  };

  return (
    <SharedLookupAlmacen
      value={idAlmacenDestino}
      noDataText={noDataText}
      filter={filter}
      onChange={this_onChange}
      {...props}
    />
  );
};

export default AlmacenDestinoItem;
