export const upload = {
  v: "5.5.5",
  fr: 29.9700012207031,
  ip: 0,
  op: 38.0000015477717,
  w: 1024,
  h: 768,
  nm: "Comp 1",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 10",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 27,
                  s: [100],
                },
                { t: 37.0000015070409, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 23,
                  s: [323, 357, 0],
                  to: [-15.333, -11, 0],
                  ti: [15.333, 11, 0],
                },
                { t: 35.0000014255792, s: [231, 291, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-233, -159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [38, 38], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-233, -159], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 23.0000009368092,
          op: 38.0000015477717,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 9",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 26,
                  s: [100],
                },
                { t: 36.0000014663101, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 22,
                  s: [685, 325, 0],
                  to: [17, -13.333, 0],
                  ti: [-17, 13.333, 0],
                },
                { t: 34.0000013848484, s: [787, 245, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-233, -159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [38, 38], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-233, -159], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 22.0000008960784,
          op: 37.0000015070409,
          st: 9.00000036657752,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Shape Layer 8",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 19,
                  s: [100],
                },
                { t: 29.0000011811942, s: [0] },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.167, y: 0.167 },
                  t: 15,
                  s: [489, 213, 0],
                  to: [-0.333, -23.667, 0],
                  ti: [0.333, 23.667, 0],
                },
                { t: 27.0000010997325, s: [487, 71, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-233, -159, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [38, 38], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-233, -159], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 15.0000006109625,
          op: 30.0000012219251,
          st: 2.00000008146167,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "Shape Layer 7",
          td: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [512, 384, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [74, 74, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [1024, 768], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 25.0000010182709,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "Shape Layer 6",
          tt: 1,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 1 },
                  o: { x: 0.167, y: 0 },
                  t: 25,
                  s: [507.984, 852.584, 0],
                  to: [0, -33.333, 0],
                  ti: [0, 33.333, 0],
                },
                { t: 30.0000012219251, s: [507.984, 652.584, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-8, 336.584, 0], ix: 1 },
            s: { a: 0, k: [116, 116, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.882, 4.882],
                        [0, 0],
                        [4.882, -4.882],
                        [0, 0],
                        [-4.881, -4.881],
                        [-4.882, 4.882],
                        [0, 0],
                        [0, 0],
                        [-6.903, 0],
                        [0, 6.903],
                        [0, 0],
                        [0, 0],
                        [-3.199, 0],
                        [-2.44, 2.441],
                      ],
                      o: [
                        [0, 0],
                        [-4.882, -4.882],
                        [0, 0],
                        [-4.881, 4.882],
                        [4.882, 4.882],
                        [0, 0],
                        [0, 0],
                        [0, 6.903],
                        [6.903, 0],
                        [0, 0],
                        [0, 0],
                        [2.44, 2.441],
                        [3.199, 0],
                        [4.882, -4.881],
                      ],
                      v: [
                        [55.286, 254.745],
                        [0.839, 200.298],
                        [-16.839, 200.298],
                        [-71.286, 254.745],
                        [-71.286, 272.423],
                        [-53.609, 272.423],
                        [-20.5, 239.314],
                        [-20.5, 324.084],
                        [-8, 336.584],
                        [4.5, 324.084],
                        [4.5, 239.314],
                        [37.608, 272.423],
                        [46.447, 276.084],
                        [55.286, 272.423],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 25.0000010182709,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "Shape Layer 5",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.667, y: 1 },
                  o: { x: 0.842, y: 0 },
                  t: 5,
                  s: [507.984, 652.584, 0],
                  to: [0, -40, 0],
                  ti: [0, 40, 0],
                },
                { t: 15.0000006109625, s: [507.984, 412.584, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-8, 336.584, 0], ix: 1 },
            s: { a: 0, k: [116, 116, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [4.882, 4.882],
                        [0, 0],
                        [4.882, -4.882],
                        [0, 0],
                        [-4.881, -4.881],
                        [-4.882, 4.882],
                        [0, 0],
                        [0, 0],
                        [-6.903, 0],
                        [0, 6.903],
                        [0, 0],
                        [0, 0],
                        [-3.199, 0],
                        [-2.44, 2.441],
                      ],
                      o: [
                        [0, 0],
                        [-4.882, -4.882],
                        [0, 0],
                        [-4.881, 4.882],
                        [4.882, 4.882],
                        [0, 0],
                        [0, 0],
                        [0, 6.903],
                        [6.903, 0],
                        [0, 0],
                        [0, 0],
                        [2.44, 2.441],
                        [3.199, 0],
                        [4.882, -4.881],
                      ],
                      v: [
                        [55.286, 254.745],
                        [0.839, 200.298],
                        [-16.839, 200.298],
                        [-71.286, 254.745],
                        [-71.286, 272.423],
                        [-53.609, 272.423],
                        [-20.5, 239.314],
                        [-20.5, 324.084],
                        [-8, 336.584],
                        [4.5, 324.084],
                        [4.5, 239.314],
                        [37.608, 272.423],
                        [46.447, 276.084],
                        [55.286, 272.423],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 25.0000010182709,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "Shape Layer 3",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 10,
                  s: [-19.032, 8, 0],
                  to: [0, -5, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 20,
                  s: [-19.032, -22, 0],
                  to: [0, 0, 0],
                  ti: [0, -5, 0],
                },
                { t: 30.0000012219251, s: [-19.032, 8, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-4.016, -1, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 10,
                  s: [89.666, 89.666, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 20,
                  s: [120.666, 120.666, 100],
                },
                { t: 30.0000012219251, s: [89.666, 89.666, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [238, 238], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-3, -1], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 10.0000004073083,
          op: 910.00003706506,
          st: 10.0000004073083,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "Shape Layer 4",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 13,
                  s: [76.968, 72, 0],
                  to: [14.667, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 23,
                  s: [164.968, 32, 0],
                  to: [0, 0, 0],
                  ti: [14.667, -6.667, 0],
                },
                { t: 33.0000013441176, s: [76.968, 72, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-4.016, -1, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 13,
                  s: [37.666, 37.666, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 23,
                  s: [76.666, 76.666, 100],
                },
                { t: 33.0000013441176, s: [37.666, 37.666, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [238, 238], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-3, -1], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 13.0000005295009,
          op: 913.000037187252,
          st: 13.0000005295009,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "Shape Layer 2",
          parent: 10,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 16,
                  s: [-72.032, 75, 0],
                  to: [-15, -6.667, 0],
                  ti: [0, 0, 0],
                },
                {
                  i: { x: 0.833, y: 0.833 },
                  o: { x: 0.167, y: 0.167 },
                  t: 26,
                  s: [-162.032, 35, 0],
                  to: [0, 0, 0],
                  ti: [-15, -6.667, 0],
                },
                { t: 36.0000014663101, s: [-72.032, 75, 0] },
              ],
              ix: 2,
            },
            a: { a: 0, k: [-4.016, -1, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 16,
                  s: [37.666, 37.666, 100],
                },
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 26,
                  s: [73.666, 73.666, 100],
                },
                { t: 36.0000014663101, s: [37.666, 37.666, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [238, 238], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-3, -1], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 16.0000006516934,
          op: 916.000037309445,
          st: 16.0000006516934,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [508.016, 318, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-35.648, 0],
                        [0, 0],
                        [-5.385, 31.695],
                        [0.07, 5.979],
                        [51.78, 1.447],
                        [4.907, -0.62],
                        [58.803, 0],
                        [16.362, -61.554],
                        [0, -40.599],
                        [-0.021, -0.752],
                      ],
                      o: [
                        [0, 0],
                        [32.149, 0],
                        [0.966, -5.688],
                        [-0.608, -51.796],
                        [-5.108, -0.143],
                        [-22.365, -50.519],
                        [-66.704, 0],
                        [-38.961, 5.871],
                        [0, 0.757],
                        [0.978, 35.634],
                      ],
                      v: [
                        [-158.085, 126.5],
                        [157.68, 126.5],
                        [223.094, 71.857],
                        [224.461, 54.335],
                        [130.235, -41.461],
                        [115.199, -40.73],
                        [-16.532, -126.5],
                        [-155.701, -19.588],
                        [-224.532, 60.5],
                        [-224.501, 62.762],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 900.000036657751,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "Shape Layer 1 Comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 30, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 16,
              s: [512, 424, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 18,
              s: [512, 404, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 23.0000009368092, s: [512, 424, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [512, 384, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1024,
      h: 768,
      ip: 3.00000012219251,
      op: 903.000036779944,
      st: 3.00000012219251,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Shape Layer 1 Comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 30, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [512, 424, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 17,
              s: [512, 404, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 22.0000008960784, s: [512, 424, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [512, 384, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1024,
      h: 768,
      ip: 2.00000008146167,
      op: 902.000036739213,
      st: 2.00000008146167,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Shape Layer 1 Comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 30, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 14,
              s: [512, 424, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 16,
              s: [512, 404, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 21.0000008553475, s: [512, 424, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [512, 384, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1024,
      h: 768,
      ip: 1.00000004073083,
      op: 901.000036698482,
      st: 1.00000004073083,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "Shape Layer 1 Comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 13,
              s: [512, 424, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 15,
              s: [512, 404, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 20.0000008146167, s: [512, 424, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [512, 384, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1024,
      h: 768,
      ip: 0,
      op: 900.000036657751,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
