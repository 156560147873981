import React, { Component, createRef } from "react";
import "./Styles.scss";

import {
    errorHandler,
    authHeader,
    getTrad,
    isSameMonth,
    formatDate_noTime_parameter,
    startOfMonth,
    endOfMonth,
    capitalize,
    addMonths,
    dxMensajePregunta,
} from "helpers";
import { connectionConstants } from "constants";

import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import validationEngine from "devextreme/ui/validation_engine";

import { Button, Popup, Tooltip } from "devextreme-react";
import DataGrid, { Column, Paging, ToolbarItem as PopupToolbarItem } from "devextreme-react/data-grid";
import Form, { Item as FormItem } from "devextreme-react/form";
import Validator, { RequiredRule } from "devextreme-react/validator";
import DateBox, { CalendarOptions, Button as DateBoxButton } from "devextreme-react/date-box";
import SelectorPersonas from "components/SelectorPersonas";
import Box, { Item as ItemBox } from "devextreme-react/box";
import ButtonGroup from "components/ButtonGroup";
import notify from "devextreme/ui/notify";

export const tipoConcepto = {
    // { "salarioBase", 1 },
    plusActividad: { name: "plusActividad", id: 2 },
    plusAntiguedad: { name: "plusAntiguedad", id: 3 },
    plusAsistencia: { name: "plusAsistencia", id: 4 },
    plusFestivoTrab: { name: "gr", id: 5 },
    incentivo: { name: "incentivo", id: 6 },
    // ppext: { name: "ppext", id: 7 },
    plusAbsorbible: { name: "plusAbsorbible", id: 8 },
    plusProductividad: { name: "plusProductividad", id: 9 },
    plusNocturnidad: { name: "plusNocturnidad", id: 10 },
    // segAccidenteConvenio: { name: "segAccidenteConvenio", id: 11 },
    plusPeligrosidad: { name: "plusPeligrosidad", id: 12 },
    plusResponsabilidad: { name: "plusResponsabilidad", id: 13 },
    //horasExtra: { name: "horasExtra", id: 14 },
    //embargo: { name: "embargo", id: 15 },
    anticipos: { name: "anticipos", id: 16 },
};

class GestorConceptoNNomina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dxPopup_visible: false,
            formData: {},
            isEditing: false,

            fechaPeriodo: startOfMonth(new Date()),
            tooltipData: {},
            dxDataGrid_filters: {
                nombre: null,
                idLavanderia: null,
                idTipoTrabajo: null,
            },

            tblConceptoNNomina: [],
            tblLavanderia: [],
            tblTipoTrabajo: [],
        };

        this.SelectorPersonas_REF = createRef();
    }

    get SelectorPersonas() {
        return this.SelectorPersonas_REF.current;
    }

    dateFilterOptions = {
        format: { month: "long", year: "numeric" },
        prevOptions: { stylingMode: "text", icon: "chevronleft", onClick: () => this.dxDateBoxButton_onClick(-1) },
        nextOptions: { stylingMode: "text", icon: "chevronright", onClick: () => this.dxDateBoxButton_onClick(+1) },
    };

    // #region Componente

    componentDidMount = () => {
        this.datasource_tblConceptoNominaNNomina
            .load()
            .then((tblConceptoNNomina) => this.setState({ tblConceptoNNomina }));
    };

    // #endregion

    // #region Datasources

    datasource_tblConceptoNominaNNomina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblConceptoNominaNNomina",
            key: ["idNomina", "idConceptoNomina"],
            keyType: {
                idNomina: "Int32",
                idConceptoNomina: "Int32",
            },
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_tblConceptoNominaNNomina_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblConceptoNNomina_onLoading(loadOptions),
            version: 4,
        }),
        select: ["idNomina", "idConceptoNomina", "cantidad", "importe", "importe", "observaciones", "fecha"],
        expand: [
            "idNominaNavigation($select=idPersona;$expand=idPersonaNavigation($select=nombre,apellidos;$expand=tblPersonaNTipoContrato($top=1;$select=fechaAltaContrato,fechaBajaContrato;$orderby=fechaAltaContrato desc)))",
        ],
        map: (item) => {
            item.persona = item.idNominaNavigation.idPersonaNavigation;
            return item;
        },
    });

    datasource_tblConceptoNominaNNomina_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        switch (request.method) {
            case "POST":
                request.url += "/IU_tblConceptoNominaNNomina";
                break;
            case "DELETE":
                let newUrl = request.url.split("tblConceptoNominaNNomina");
                request.url =
                    newUrl[0] +
                    "tblConceptoNominaNNomina" +
                    "/" +
                    this.deleteKey.idNomina +
                    "/" +
                    this.deleteKey.idConceptoNomina;
                break;
            case "get":
                request.params.filtroTipoTrabajoRRHH = false;
                break;
        }
    };

    datasource_tblConceptoNNomina_onLoading = (loadOptions) => {
        const { tipoConcepto } = this.props;
        const { fechaPeriodo } = this.state;

        loadOptions.filter = [
            ["idConceptoNomina", "=", tipoConcepto.id],
            ["fecha", ">=", fechaPeriodo],
            ["fecha", "<=", endOfMonth(fechaPeriodo)],
            ["importe", ">", 0],
        ];
    };

    // #endregion

    render() {
        const { tipoConcepto } = this.props;
        const { tooltipData, fechaPeriodo } = this.state;

        return (
            <Card className="d-flex he-100 cardPlus">
                <CardHeader className="card-header-tab">
                    <Row className="container_spanCentrado w-100">
                        <Col className="w-100 pl-2 pr-3 text-truncate text-left">{getTrad(tipoConcepto.name)}</Col>
                        <Col xs="3" className="d-flex justify-content-end p-0">
                            <DateBox
                                stylingMode="underlined"
                                className="dateFilterPlus"
                                value={fechaPeriodo}
                                displayFormat={this.dxDateBox_displayFormat}
                                acceptCustomValue={false}
                                openOnFieldClick={true}
                                onValueChanged={this.dxDateBox_onValueChanged}
                            >
                                <CalendarOptions maxZoomLevel={"year"} />
                                <DateBoxButton
                                    name={"prev"}
                                    location={"before"}
                                    options={this.dateFilterOptions.prevOptions}
                                />
                                <DateBoxButton
                                    name={"next"}
                                    location={"after"}
                                    options={this.dateFilterOptions.nextOptions}
                                />
                            </DateBox>
                            <Button
                                className="iconHeader_dxButton_comunicado"
                                icon="plus"
                                stylingMode="text"
                                hoverStateEnabled={false}
                                focusStateEnabled={false}
                                activeStateEnabled={false}
                                width={28}
                                onClick={() => this.dxPopup_show()}
                            />
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="body-actionToast he-100 align-items-stretch p-0">
                    <this.renderConceptoNNominaGrid />
                    <this.renderPopupCrearConceptoNNomina />
                </CardBody>
                <Tooltip
                    visible={tooltipData.target != null}
                    target={tooltipData.target}
                    position={"left"}
                    closeOnOutsideClick
                    onHidden={this.dxTooltip_hide}
                >
                    <div>{tooltipData.content}</div>
                </Tooltip>
            </Card>
        );
    }

    dxDateBox_displayFormat = (value) => {
        return capitalize(value.toLocaleDateString(undefined, this.dateFilterOptions.format));
    };

    dxDateBox_onValueChanged = (e) => {
        this.setState({ fechaPeriodo: e.value });
        this.SelectorPersonas.refresh();
        this.datasource_tblConceptoNominaNNomina
            .reload()
            .then((tblConceptoNNomina) => this.setState({ tblConceptoNNomina }));
    };

    dxDateBoxButton_onClick = (value) =>
        this.dxDateBox_onValueChanged({ value: addMonths(this.state.fechaPeriodo, value) });

    // #region dxDataGrid

    currency_format = { style: "currency", maximumFractionDigits: 2, currency: "EUR" };

    renderConceptoNNominaGrid = () => {
        const { tblConceptoNNomina, tooltipData } = this.state;
        return (
            <DataGrid
                className="he-100 w-100"
                dataSource={tblConceptoNNomina}
                showColumnLines={false}
                showRowLines={true}
                onCellClick={this.dxDataGrid_onCellClick}
            >
                <Paging enabled={false} />
                <Column
                    dataField={"edit"}
                    caption={""}
                    width={"auto"}
                    alignment={"center"}
                    cellComponent={this.dxDataGrid_cellComponent_edit}
                />
                <Column
                    dataField={"fecha"}
                    caption={getTrad("fecha")}
                    dataType={"date"}
                    format={"dd/MM/yyyy"}
                    width={150}
                    alignment={"center"}
                />
                <Column
                    dataField={"persona.nombre"}
                    caption={getTrad("nombre")}
                    dataType={"string"}
                    sortOrder={"asc"}
                />
                <Column
                    dataField={"persona.apellidos"}
                    caption={getTrad("apellidos")}
                    dataType={"string"}
                    sortOrder={"asc"}
                />
                <Column
                    dataField={"importe"}
                    caption={getTrad("importe")}
                    dataType={"number"}
                    format={this.currency_format}
                    width={150}
                    alignment={"center"}
                />
                <Column
                    dataField={"observaciones"}
                    caption={getTrad("observaciones")}
                    dataType={"string"}
                    width={150}
                    alignment={"center"}
                    cellComponent={this.dxDataGrid_cellComponent_observaciones}
                    allowSorting={false}
                />
                <Column
                    dataField={"delete"}
                    caption={""}
                    width={"auto"}
                    alignment={"center"}
                    cellComponent={this.dxDataGrid_cellComponent_delete}
                />
            </DataGrid>
        );
    };

    dxDataGrid_onCellClick = (e) => {
        if (e.column && e.rowType == "data") {
            if (e.column.dataField === "edit" && e.event.target.classList.contains("icon_Edit")) {
                this.dxPopup_show(e.data);
            }
            if (e.column.dataField === "delete" && e.event.target.classList.contains("dx-icon-close")) {
                dxMensajePregunta(getTrad("preg_RegistroEliminar"), [
                    [
                        getTrad("aceptar"),
                        () =>
                            this.dxDataGrid_cellComponent_delete_onClick({
                                idNomina: e.data.idNomina,
                                idConceptoNomina: e.data.idConceptoNomina,
                            }),
                        "danger",
                    ],
                    [getTrad("cancelar"), () => {}],
                ]);
            }
        }
    };

    dxDataGrid_cellComponent_edit = () => {
        return (
            <div className="iconCentered">
                <i className="dx-icon icon_Edit text-center" />
            </div>
        );
    };

    dxDataGrid_cellComponent_observaciones = ({ data }) => {
        return data.value ? (
            <div className="iconCentered">
                <i
                    className="dx-icon dx-icon-info text-center"
                    onMouseEnter={(e) => this.dxDataGrid_cellComponent_observaciones_onHover(e, data.value)}
                    onMouseLeave={this.dxTooltip_hide}
                />
            </div>
        ) : null;
    };

    dxDataGrid_cellComponent_observaciones_onHover = (e, text) =>
        this.setState({
            tooltipData: {
                target: e.target,
                content: <span>{text}</span>,
            },
        });

    dxDataGrid_cellComponent_delete = () => {
        return (
            <div className="iconCentered">
                <i className="dx-icon dx-icon-close text-center" />
            </div>
        );
    };

    dxDataGrid_cellComponent_delete_onClick = (key) => {
        this.deleteKey = key;
        this.dxTooltip_hide();
        this.datasource_tblConceptoNominaNNomina
            .store()
            .remove({})
            .then(() => {
                this.SelectorPersonas.refresh();
                this.datasource_tblConceptoNominaNNomina
                    .reload()
                    .then((tblConceptoNNomina) => this.setState({ tblConceptoNNomina }));
            });
    };

    dxTooltip_hide = () => this.setState({ tooltipData: {} });

    // #endregion

    // #region dxPopup

    formLabels = {
        persona: { text: getTrad("persona") },
        fecha: { text: getTrad("fecha") },
        importe: { text: getTrad("importe") },
        observaciones: { text: getTrad("observaciones") },
    };

    formEditorOptions = {
        fecha: {
            displayFormat: "dd/MM/yyyy",
            stylingMode: "underlined",
            openOnFieldClick: true,
            onValueChanged: (e) => this.dxFormItem_onValueChanged(e),
        },
        importe: {
            stylingMode: "underlined",
            format: this.currency_format,
            min: 1,
            max: 9999999999.99,
            onValueChanged: (e) => this.dxFormItem_onValueChanged(e),
        },
        observaciones: {
            stylingMode: "contained",
            height: 100,
            onValueChanged: (e) => this.dxFormItem_onValueChanged(e),
        },
    };

    renderPopupCrearConceptoNNomina = () => {
        const { dxPopup_visible, formData, isEditing } = this.state;
        return (
            <Popup
                visible={dxPopup_visible}
                onHiding={this.dxPopup_hide}
                onHidden={this.dxPopup_onHidden}
                title={capitalize(
                    `${getTrad(isEditing ? "modificar" : "crear")} ${getTrad(this.props.tipoConcepto.name)}`,
                    true
                )}
                showTitle
                width={"45rem"}
                height={"30rem"}
            >
                <Form
                    formData={formData}
                    labelLocation="top"
                    colCount={2}
                    className="he-100"
                    validationGroup="ConceptoNNominaValidator"
                >
                    <FormItem
                        dataField={"persona"}
                        colSpan={2}
                        label={this.formLabels.persona}
                        render={this.dxFormItem_render_persona}
                        isRequired={true}
                    />
                    <FormItem
                        dataField={"fecha"}
                        colSpan={1}
                        label={this.formLabels.fecha}
                        editorType={"dxDateBox"}
                        editorOptions={this.formEditorOptions.fecha}
                        isRequired={true}
                    />
                    <FormItem
                        dataField={"importe"}
                        colSpan={1}
                        label={this.formLabels.importe}
                        editorType={"dxNumberBox"}
                        editorOptions={this.formEditorOptions.importe}
                        isRequired={true}
                    />
                    <FormItem
                        dataField={"observaciones"}
                        colSpan={2}
                        label={this.formLabels.observaciones}
                        editorType={"dxTextArea"}
                        editorOptions={this.formEditorOptions.observaciones}
                    />
                </Form>

                <PopupToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button
                        text={getTrad(isEditing ? "modificar" : "crear")}
                        className="mr-3"
                        type="success"
                        onClick={this.dxForm_submit_onClick}
                    />
                </PopupToolbarItem>
                <PopupToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} type="normal" onClick={this.dxPopup_hide} />
                </PopupToolbarItem>
            </Popup>
        );
    };

    dxPopup_show = (formData) =>
        this.setState({
            dxPopup_visible: true,
            formData: formData ?? {},
            isEditing: formData != null,
        });

    dxPopup_hide = () => this.setState({ dxPopup_visible: false });

    dxPopup_onHidden = () => this.setState({ formData: {} });

    dxForm_submit_onClick = (e) => {
        const validator = validationEngine.validateGroup("ConceptoNNominaValidator");
        if (validator.isValid) {
            e.component.option("disabled", true);

            const { tipoConcepto } = this.props;
            let { formData } = this.state;

            let fechaAltaContrato = formData.persona.tblPersonaNTipoContrato[0].fechaAltaContrato;
            let fechaBajaContrato = formData.persona.tblPersonaNTipoContrato[0].fechaBajaContrato;

            let fechaInicioNomina = isSameMonth(new Date(fechaAltaContrato), new Date(formData.fecha))
                ? fechaAltaContrato
                : formatDate_noTime_parameter(
                      startOfMonth(typeof formData.fecha === "string" ? new Date(formData.fecha) : formData.fecha)
                  );
            let fechaFinNomina =
                fechaBajaContrato && isSameMonth(new Date(fechaBajaContrato), new Date(formData.fecha))
                    ? fechaBajaContrato
                    : formatDate_noTime_parameter(
                          endOfMonth(typeof formData.fecha === "string" ? new Date(formData.fecha) : formData.fecha)
                      );

            let objConceptoNominaNNomina = {
                idConceptoNomina: tipoConcepto.id,
                cantidad: null,
                precioUnitario: null,
                observaciones:
                    formData.observaciones && formData.observaciones.length > 0 ? formData.observaciones : null,
                fecha: formatDate_noTime_parameter(
                    typeof formData.fecha === "string" ? new Date(formData.fecha) : formData.fecha ?? new Date()
                ),
                idPersona: formData.persona.idPersona ?? formData.idNominaNavigation.idPersona,
                fechaInicioNomina: fechaInicioNomina,
                fechaFinNomina: fechaFinNomina,
                importe: formData.importe,
            };

            this.datasource_tblConceptoNominaNNomina
                .store()
                .insert(objConceptoNominaNNomina)
                .catch((error) => {
                    error.errorDetails().catch((e) => {
                        notify({
                            message: e.responseText,
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                    });
                })
                .always(() => e.component.option("disabled", false))
                .then(() => {
                    this.SelectorPersonas.refresh();
                    this.datasource_tblConceptoNominaNNomina
                        .reload()
                        .then((tblConceptoNNomina) => this.setState({ tblConceptoNNomina }));
                    this.dxPopup_hide();
                });
        }
    };

    dxFormItem_onValueChanged = (e) => {
        const { formData } = this.state;
        let dataField = e.component.option("name");

        formData[dataField] = e.value;

        this.setState({ formData });
    };

    selectorPersonas = {
        filter: [
            ["activo", "=", true],
            ["eliminado", "=", false],
            [
                ["idLavanderiaNavigation/idPais", "=", 1], // Lavandería de españa
                "or",
                ["idCentroTrabajoNavigation/idPais", "=", 1], // Centro de españa
            ],
        ],
        expand: [
            "tblPersonaNTipoContrato($top=1;$select=fechaAltaContrato,fechaBajaContrato;$orderby=fechaAltaContrato desc)",
        ],
        map: (item) => {
            let fechaAltaContrato = item.tblPersonaNTipoContrato[0].fechaAltaContrato;
            let fechaBajaContrato = item.tblPersonaNTipoContrato[0].fechaBajaContrato;

            item.fechaInicioNomina = isSameMonth(new Date(fechaAltaContrato), new Date())
                ? fechaAltaContrato
                : formatDate_noTime_parameter(startOfMonth(new Date()));
            item.fechaFinNomina =
                fechaBajaContrato && isSameMonth(new Date(fechaBajaContrato), new Date())
                    ? fechaBajaContrato
                    : formatDate_noTime_parameter(endOfMonth(new Date()));

            const { tblConceptoNNomina } = this.state;

            item.disabled =
                tblConceptoNNomina.filter((x) => x.idNominaNavigation.idPersona === item.idPersona).length > 0;

            return item;
        },
        beforeSend: (request) => {
            request.params.filtroTipoTrabajoRRHH = false;
        },
    };

    dxFormItem_render_persona = () => {
        const { formData, isEditing } = this.state;
        return (
            <SelectorPersonas
                ref={this.SelectorPersonas_REF}
                disabled={isEditing}
                selectorCompacto={true}
                selectionMode={"single"}
                allowFiltering={true}
                stylingMode="underlined"
                onSelectionChanged={this.dxDataGridPersonas_onSelectionChanged}
                selectedRowKeys={[formData.persona?.idPersona ?? formData.idNominaNavigation?.idPersona]}
                reloadDataSourceAfterSelection={true}
                beforeSend={this.selectorPersonas.beforeSend}
                filter={this.selectorPersonas.filter}
                expand={this.selectorPersonas.expand}
                map={this.selectorPersonas.map}
            >
                <Validator validationGroup="ConceptoNNominaValidator">
                    <RequiredRule />
                </Validator>
            </SelectorPersonas>
        );
    };

    dxDataGridPersonas_onSelectionChanged = (e) => {
        const { formData } = this.state;
        formData.persona = e.selectedRowsData?.at(0);

        this.setState({ formData });
    };

    // #endregion
}

export default GestorConceptoNNomina;
