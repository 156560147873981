import React, { Fragment } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import { getTrad } from "../helpers";

import Query from "devextreme/data/query";

import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import ScrollView from "devextreme-react/scroll-view";

class CardSelector extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  //Estructura de data
  // [{
  //       id
  //       denominacion
  //       color = null
  //       headerContent = null
  //       bodyContent
  //       item.cardClass = null
  // }]

  render() {
    let _this = this;
    let { data, groupField } = this.props;

    let groupedData = null;
    if (groupField) {
      groupedData = Query(data).groupBy(groupField).toArray();
    }

    return (
      <div id="CardSelector" className="scrollbar-container">
        <ScrollView
          id="dxScrollView"
          className={"cardContent" + (this.get_isMovil() ? " vMovil" : "")}
          onScroll={this.props.onScroll}
        >
          <div>
            {groupedData ? (
              $.map(groupedData, function (group) {
                return (
                  <Fragment key={"grupo" + group.key}>
                    <div className="groupTitle font-size-xl p-3 mb-3">
                      {getTrad(group.key).toUpperCase()}
                    </div>
                    <div className="row">
                      {$.map(group.items, function (item) {
                        return _this.card_render(item);
                      })}
                    </div>
                  </Fragment>
                );
              })
            ) : (
              <div className="row">
                {$.map(data, function (item) {
                  return _this.card_render(item);
                })}
              </div>
            )}
          </div>
        </ScrollView>
      </div>
    );
  }

  card_render = (item) => {
    let _this = this;
    let { onClick, bodyClick, zoom, numCol, readOnly, idSelected } = this.props;

    let is_selected = item.id != null ? item.id === idSelected : false;
    numCol = numCol != null ? numCol : 4;

    return (
      <div
        key={item.id}
        className={"col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-" + numCol}
      >
        <div
          className={
            "he-100 mb-3 " + (item.cardClass ? item.cardClass : "row-1")
          }
        >
          <Card
            className={
              "card-btm-border d-flex he-100 " +
              (is_selected ? "is_selected " : " ") +
              (readOnly ? "" : "hover ") +
              (!_this.get_isMovil() && zoom ? "zoom " : "") +
              (item.color ? item.color : "")
            }
          >
            <CardHeader
              className={"card-header-tab " + (readOnly ? "" : "pointer ")}
              onClick={() => {
                onClick(item);
              }}
            >
              <Row className="container_spanCentrado w-100">
                <Col className="w-100 pl-2 pr-3 text-truncate text-left">
                  {(item.getDenominacion
                    ? item.getDenominacion()
                    : item.denominacion
                  ).toUpperCase()}
                </Col>
                {item.headerContent ? (
                  <Col xs="3" className="d-flex justify-content-end p-0">
                    {item.headerContent(item)}
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </CardHeader>
            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
              <div className="widget-content pl-0 pr-0 he-100">
                <div className="widget-content-outer he-100">
                  <Row className="flex-fill he-100">
                    <div
                      className="widget-content-wrapper w-100 he-100"
                      onClick={
                        bodyClick
                          ? () => {
                              onClick(item);
                            }
                          : null
                      }
                    >
                      {item.bodyContent(item)}
                    </div>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  get_isMovil() {
    return (
      this.props.resolucion === "xs" ||
      this.props.resolucion === "sm" ||
      this.props.resolucion === "md"
    );
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(CardSelector);
