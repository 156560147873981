import React, { useEffect, useRef, useState } from "react";

import { EmptyItem, Item } from "devextreme-react/form";

import AlmacenDestinoItem, {
  destinoOptions,
} from "pages/AssistantNew/Movimientos/shared/components/FormItems/AlmacenDestinoItem";
import AlmacenOrigenItem, {
  origenOptions,
} from "pages/AssistantNew/Movimientos/shared/components/FormItems/AlmacenOrigenItem";
import NumRegistroItem from "pages/AssistantNew/Movimientos/shared/components/FormItems/NumRegistroItem";
import SharedForm from "pages/AssistantNew/Movimientos/shared/components/SharedFormMovimientoRecambio";
import { getTrad } from "helpers";

const FormMovimientoRecambio = ({
  formData,
  setCodigoMoneda,
  disableCoreFields,
  ...props
}) => {
  const formRef = useRef(null);

  const { fecha, idAlmacenDestino, idAlmacenOrigen, idMovimientoRecambio } =
    formData;

  const [fieldsDisableBuscador, setFieldsDisableBuscador] = useState({
    fecha,
    idAlmacenOrigen,
    idAlmacenDestino,
  });

  useEffect(() => {
    setFieldsDisableBuscador({
      fecha,
      idAlmacenOrigen,
      idAlmacenDestino,
    });
  }, [idMovimientoRecambio]);

  const getText = (label) => ({ text: getTrad(label) });

  const origen = (
    <AlmacenOrigenItem
      formRef={formRef}
      value={idAlmacenOrigen}
      readOnly={disableCoreFields}
      onChange={(value) =>
        setFieldsDisableBuscador({
          ...fieldsDisableBuscador,
          ...value,
        })
      }
    />
  );

  const origenOptionsFinal = {
    label: getText("almacenOrigen"),
    ...origenOptions,
  };

  const destino = (
    <AlmacenDestinoItem
      formRef={formRef}
      formData={formData}
      setCodigoMoneda={setCodigoMoneda}
      readOnly={disableCoreFields}
      onChange={(value) =>
        setFieldsDisableBuscador({
          ...fieldsDisableBuscador,
          idAlmacenDestino: value,
        })
      }
    />
  );

  const destinoOptionsFinal = {
    label: getText("almacenDestino"),
    ...destinoOptions,
  };

  const disableBuscador = Object.keys(fieldsDisableBuscador).some(
    (key) => fieldsDisableBuscador[key] === null
  );

  const onChangeFecha = ({ value }) =>
    setFieldsDisableBuscador({ ...fieldsDisableBuscador, fecha: value });

  return (
    <SharedForm
      ref={formRef}
      formData={formData}
      origen={origen}
      origenOptions={origenOptionsFinal}
      destino={destino}
      destinoOptions={destinoOptionsFinal}
      disableCoreFields={disableCoreFields}
      disableBuscador={disableBuscador}
      onChangeFecha={onChangeFecha}
      {...props}
    >
      <Item {...NumRegistroItem(getText)} />
      <EmptyItem colSpan={3} />
    </SharedForm>
  );
};

export default FormMovimientoRecambio;
