import React from "react";

import DxDataGrid, {
  Column,
  ColumnChooser,
  FilterRow,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
  Sorting,
} from "devextreme-react/data-grid";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";

import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";
import { authHeader, errorHandler, getTrad } from "helpers";
import { connectionConstants } from "constants";

const allowedPageSizes = [20, 50, 100];

const DataGrid = ({ dataSource, dataSources, setCliente, user }) => {
  const context_clientes = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "MyPolarier/Administracion/Clientes/",
    entities: {
      UpdateTablasSAP: {},
    },
    errorHandler,
    beforeSend: (request) => (request.headers = { ...authHeader() }),
  });

  const onToolbarPreparing = ({ toolbarOptions, component }) => {
    toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        visible:
          [idsUsuario.silverioLaraudogoitia, idsUsuario.jorgeGarcia, idsUsuario.maribelMoranta].includes(
            user.idUsuario
          ) || [idsCargo.desarrollador].includes(user.idCargo),
        options: {
          text: getTrad("actualizarDatosSAP"),
          onClick: () => {
            component.beginCustomLoading();
            context_clientes
              .invoke("UpdateTablasSAP")
              .done(() => {
                component.refresh(false).done(() => {
                  component.endCustomLoading();
                  notify({
                    message: getTrad("registrosActualizadosCorrectamente"),
                    type: "success",
                    displayTime: 1500,
                    closeOnClick: true,
                  });
                });
              })
              .catch(() => {
                component.endCustomLoading();
                notify({
                  message: getTrad("errorActualizarRegistros"),
                  type: "error",
                  displayTime: 1500,
                  closeOnClick: true,
                });
              });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: () => {
            component.refresh();
          },
        },
      }
    );
  };

  const cellRender = ({ data }) => {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={() => setCliente(data)}
      >
        <i className="icon_Edit font-size-lg" />
      </div>
    );
  };

  const displayExpr_idIvaNPais = (e) =>
    e &&
    `${e.idAdmIvaNavigation.denominacion} ${
      e.descripcionIVA_Venta ? `${e.descripcionIVA_Venta} ` : ""
    }(${e.indicadorIVA_Venta})`;

  return (
    <DxDataGrid
      dataSource={dataSource}
      height={"100%"}
      width={"100%"}
      showColumnLines={false}
      allowColumnReordering
      allowColumnResizing
      columnAutoWidth
      hoverStateEnabled
      repaintChangesOnly
      rowAlternationEnabled
      showRowLines
      onToolbarPreparing={onToolbarPreparing}
    >
      <ColumnChooser enabled />
      <FilterRow visible />
      <Pager
        showPageSizeSelector
        allowedPageSizes={allowedPageSizes}
        showInfo
      />
      <Paging defaultPageSize={50} />
      <SearchPanel visible width={240} />
      <Sorting mode={"multiple"} />
      <Column
        caption=" "
        name={"editCell"}
        width={30}
        alignment="center"
        cssClass="p-0"
        fixed={true}
        allowExporting={false}
        cellRender={cellRender}
      />
      <Column
        dataField={"codigo"}
        caption={getTrad("codigo")}
        dataType={"string"}
        sortIndex={1}
        sortOrder={"asc"}
      />
      <Column dataField={"CIF"} caption={"CIF"} dataType={"string"} />
      <Column
        dataField={"nombreFiscal"}
        caption={getTrad("nombreFiscal")}
        dataType={"string"}
        sortIndex={0}
        sortOrder={"asc"}
      />
      <Column dataField={"idPais"} caption={getTrad("pais")} minWidth={150}>
        <Lookup
          dataSource={dataSources.tblPais}
          valueExpr={"idPais"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"provincia"}
        caption={getTrad("provincia")}
        dataType={"string"}
      />
      <Column
        dataField={"poblacion"}
        caption={getTrad("poblacion")}
        dataType={"string"}
      />
      <Column
        dataField={"codigoPostal"}
        caption={getTrad("codigoPostal")}
        dataType={"string"}
      />
      <Column
        dataField={"direccion"}
        caption={getTrad("direccion")}
        dataType={"string"}
      />
      <Column
        dataField={"diaPago"}
        caption={getTrad("diaPago")}
        visible={false}
      />
      <Column
        dataField={"idMoneda"}
        caption={getTrad("moneda")}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblMoneda}
          valueExpr={"idMoneda"}
          displayExpr={"codigo"}
        />
      </Column>
      <Column
        dataField={"tipoRetencion"}
        caption={getTrad("tipoRetencion")}
        visible={false}
      />
      <Column
        dataField={"codigoRetencion"}
        caption={getTrad("indicadorRetencion")}
        visible={false}
      />
      <Column
        dataField={"idAdmCentroCoste"}
        caption={getTrad("centroCoste")}
        minWidth={300}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblAdmCentroCoste}
          valueExpr={"idAdmCentroCoste"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idAdmElementoPEP"}
        caption={getTrad("elementoPep")}
        minWidth={300}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblAdmElementoPEP}
          valueExpr={"idAdmElementoPEP"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idAdmFormaCobro"}
        caption={getTrad("formaPago")}
        minWidth={200}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblAdmFormaPago}
          valueExpr={"idAdmFormaPago"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idAdmCondicionPago"}
        caption={getTrad("condicionPago")}
        minWidth={210}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblAdmCondicionPago}
          valueExpr={"idAdmCondicionPago"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idCuentaBancaria"}
        caption={getTrad("cuentaBancaria")}
        minWidth={200}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblAdmCuentaBancaria}
          valueExpr={"idCuentaBancaria"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idIvaNPais"}
        caption={getTrad("iva")}
        minWidth={80}
        visible={false}
      >
        <Lookup
          dataSource={dataSources.tblIvaNPais}
          valueExpr={"idIvaNPais"}
          displayExpr={displayExpr_idIvaNPais}
        />
      </Column>
      <Column
        dataField={"isModificableNFactura"}
        caption={getTrad("isModificableNFactura")}
        visible={false}
      />
    </DxDataGrid>
  );
};

export default DataGrid;
