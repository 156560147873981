import React, { useEffect, useState } from "react";

import Button from "devextreme-react/button";
import ODataContext from "devextreme/data/odata/context";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import TextArea from "devextreme-react/text-area";

import { authHeader, errorHandler, getTrad } from "helpers";
import { connectionConstants } from "constants";
import notify from "devextreme/ui/notify";

const PopupComentarioCambioEstadoNomina = ({ title, visible, idNomina, idEstadoNomina, onConfirm, onCancel, onHiding }) => {
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [idEstadoNominaActual, setIdEstadoNominaActual] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (visible) {
      context_tblEstadoNominaNNomina.invoke("tblEstadoNominaNNomina", {}, "GET")
        .done(data => {
          setValue(data?.[0]?.observaciones ?? "");
          setIdEstadoNominaActual(data?.[0]?.idEstadoNomina ?? null);
        });
    }
  }, [idNomina, idEstadoNomina, visible]);

  const context_tblEstadoNominaNNomina = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL,
    entities: {
      tblEstadoNominaNNomina: {},
    },
    errorHandler: error => errorHandler(error, null),
    beforeSend: request => context_tblEstadoNominaNNomina_beforeSend(request),
  });

  const context_tblEstadoNominaNNomina_beforeSend = request => {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      request.params.idNomina = idNomina;
      request.params.idEstadoNomina = idEstadoNomina;
    } else {
      request.payload = {
        idNomina,
        idEstadoNomina,
        observaciones: value.trim(),
      }
    }
  };

  const onHidden = () => {
    setValue("");
    setIsValid(true);
    setIdEstadoNominaActual(null);
  };

  const onValueChanged = ({ value }) => setValue(value);

  const onClick_confirmar = () => {
    if (disabled) return;

    setDisabled(true);

    const valueTrimed = value.trim();

    setIsValid(valueTrimed.length > 0);

    if (valueTrimed.length > 0) {
      if (idEstadoNomina !== idEstadoNominaActual) {
        context_tblEstadoNominaNNomina.invoke("tblEstadoNominaNNomina", {})
          .done(data => {
            onHiding();
            onConfirm && onConfirm(data);
            notify({
              message: getTrad("aviso_C_solicitarCambio"),
              type: "success",
              displayTime: 1500,
              closeOnClick: true,
            });
          })
          .catch(() => {
            notify({
              message: getTrad("aviso_I_modificarSolicitudCambio"),
              type: "error",
              displayTime: 1500,
              closeOnClick: true
            })
          })
          .finally(() => setDisabled(false));
      } else {
        context_tblEstadoNominaNNomina.invoke("tblEstadoNominaNNomina", {}, "PATCH")
          .done(data => {
            onHiding();
            onConfirm && onConfirm(data);
            notify({
              message: getTrad("aviso_C_modificarSolicitudCambio"),
              type: "success",
              displayTime: 1500,
              closeOnClick: true,
            });
          })
          .catch(() => {
            notify({
              message: getTrad("aviso_I_solicitarCambio"),
              type: "error",
              displayTime: 1500,
              closeOnClick: true
            })
          })
          .finally(() => setDisabled(false));
      }
    }
  };

  const onClick_cancelar = () => {
    onHiding();
    onCancel && onCancel();
  };

  return (
    <Popup
      title={title}
      width={"30%"}
      height={"45%"}
      visible={visible}
      onHiding={onHiding}
      onHidden={onHidden}
    >
      <div className={"d-flex flex-column he-100"}>
        <p className={"font-weight-bold text-secondary mb-2"}>
          {getTrad("observaciones")}
          <span className={"text-danger font-weight-normal"}>{" *"}</span>
        </p>
        <TextArea
          height={"100%"}
          value={value}
          isValid={isValid}
          onValueChanged={onValueChanged}
        />
      </div>
      <ToolbarItem toolbar={"bottom"} location={"after"}>
        <Button
          type={"success"}
          text={getTrad("confirmar")}
          onClick={onClick_confirmar}
        />
      </ToolbarItem>
      <ToolbarItem toolbar={"bottom"} location={"after"}>
        <Button
          type={"danger"}
          text={getTrad("cancelar")}
          onClick={onClick_cancelar}
        />
      </ToolbarItem>
    </Popup>
  );
}

export default PopupComentarioCambioEstadoNomina;