import React, { forwardRef } from "react";

import { Column } from "devextreme-react/data-grid";

import SharedDataGridRecambios from "pages/AssistantNew/Movimientos/shared/components/SharedDataGridRecambios";
import StockColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/StockColumn";
import UbicacionColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/UbicacionColumn";
import { getTrad } from "helpers";

const DataGridRecambios = forwardRef(
  ({ movimientoRecambio, ...props }, ref) => {
    const { isEditable } = movimientoRecambio;

    return (
      <SharedDataGridRecambios
        ref={ref}
        movimientoRecambio={movimientoRecambio}
        {...props}
      >
        <Column {...UbicacionColumn(isEditable, getTrad)} />
        <Column {...StockColumn(getTrad)} />
      </SharedDataGridRecambios>
    );
  }
);

export default DataGridRecambios;
