import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, FormGroup, Alert } from "reactstrap";

import { userService } from "../../../services";
import { email_Pattern } from "../../../helpers";

import { Button } from "devextreme-react";
import Loader from "react-loaders";
import { TextBox } from "devextreme-react/text-box";

import {
  Validator,
  RequiredRule,
  PatternRule,
} from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/validation-group";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      loading: false,
      success: false,
      error: false,
    };

    //Referencias
    this.textBox_email_REF = React.createRef();
    this.button_submit_REF = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.enterKey = this.enterKey.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  get textBox_email() {
    return this.textBox_email_REF.current.instance;
  }

  get button_submit() {
    return this.button_submit_REF.current.instance;
  }

  handleChange(e) {
    const { name, value } = e.event.target;
    this.setState({ [name]: value });
  }

  enterKey(e) {
    this.button_submit.element().click();
  }

  onSubmit(e) {
    const res = e.validationGroup.validate();
    if (res.isValid) {
      const { email } = this.state;
      this.setState({ loading: true });
      userService
        .recoveryPassword(email)
        .then((response) =>
          this.setState({ loading: false, success: true, error: false }),
        )
        .catch((error) =>
          setTimeout(() => {
            this.setState({ loading: false, success: false, error: true });
          }, 500),
        );
    }
  }

  render() {
    const { email, loading, success, error } = this.state;
    return (
      <Fragment>
        <div className="app-container">
          <div className="he-100 bg-animation bg-white">
            <div className="d-flex he-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="logo-LoveYourLinen mx-auto mb-3" />
                {!loading ? (
                  <div className="w-100 mx-auto modal-dialog">
                    <div
                      className={
                        "modal-content " +
                        (!success ? "" : "success") +
                        (!error ? "" : "warning")
                      }
                    >
                      <ValidationGroup>
                        <div className="modal-body">
                          <div className="modal-title text-center mb-4">
                            <h4 className="mt-2">
                              <div>Recupera tu contraseña</div>
                            </h4>
                            <h6 className="mt-1">
                              <span>
                                Por favor, indique su correo electrónico.
                              </span>
                            </h6>
                          </div>
                          {success ? (
                            <Alert className="w-100" color="success">
                              <div className="text-center">
                                <span>
                                  Se ha enviado el correo de recuperación.
                                </span>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          {error ? (
                            <Alert className="w-100" color="danger">
                              <div className="text-center">
                                <span>
                                  El email indicado no se encuentra asociado a
                                  un usuario
                                </span>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          {/* <Form> */}
                          <Row form>
                            <Col md={12} className="mt-3">
                              <FormGroup>
                                <span>Correo electrónico</span>
                                <TextBox
                                  ref={this.textBox_email_REF}
                                  name="email"
                                  stylingMode="underlined"
                                  placeholder="Introduzca correo electrónico..."
                                  value={email}
                                  onChange={this.handleChange}
                                  onEnterKey={this.enterKey}
                                >
                                  <Validator>
                                    <RequiredRule message="Campo necesario" />
                                    <PatternRule
                                      pattern={email_Pattern}
                                      message="Se debe indicar un email válido."
                                    />
                                  </Validator>
                                </TextBox>
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* </Form> */}
                        </div>
                        <div className="modal-footer clearfix">
                          <div className="float-left w-100">
                            <h6 className="mb-0">
                              <Link to={`/Login`} className="text-primary">
                                Utilizar una cuenta ya existente.
                              </Link>
                            </h6>
                          </div>
                          <div className="float-right ">
                            <Button
                              ref={this.button_submit_REF}
                              width={120}
                              text="Recuperar"
                              type="default"
                              stylingMode="contained"
                              onClick={this.onSubmit}
                            />
                          </div>
                        </div>
                      </ValidationGroup>
                    </div>
                  </div>
                ) : (
                  <div className="loader-container">
                    <div className="loader-container-inner m-5">
                      <div className="text-center">
                        <Loader type="line-scale" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center opacity-8 mt-3">
                  <a href="https://www.polarier.com/aviso-legal/ ">
                    Copyright &copy; Polarier {new Date().getFullYear()}
                  </a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    this.textBox_email.focus();
  }
}

export default ForgotPassword;
