import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  startOfMonth,
  endOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  dxRangeSelector_PosicionValor_nuevoMarker,
  filtroTiempo_Resize,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var idLavanderia = LAVANDERIA.idLavanderia,
    fechaDesde = null,
    fechaHasta = null,
    is_unidades = true,
    compaSel = null,
    kgLavados = [];
  //#endregion

  //#region DATASOURCE
  var datasource_filtroTiempo_spSelectComparativa = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infKgLavados_filtroTiempo_spSelectComparativa",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
      },
      version: 4,
    }),
    sort: "fecha",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
        producido: itemData.producido,
      };
    },
  });

  var datasource_infKgLavados_spSelectComparativa = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infKgLavados_spSelectComparativa",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idioma = IDIOMA.codigo;
      },
      version: 4,
      onLoading: function (loadOptions) {
        COMPONENT.loadPanel_hide();
        setTimeout(() => {
          $("#dxDataGrid_comparativa_compañias")
            .dxDataGrid("instance")
            .beginCustomLoading();
        }, 0);
      },
    }),
    sort: "idPrenda",
  });

  var infProcesadosEntregados_kgLavadosProcesados = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProcesadosEntregados_kgLavadosProcesados",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion", "idCompañia"],
  });

  var datasource_tipoProduccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "idTipoProduccion",
  });

  var enum_tipoTabla = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("unidades").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("peso").toUpperCase(),
    },
  ];

  //#endregion

  $.when(
    datasource_tipoProduccion.load(),
    datasource_filtroTiempo_spSelectComparativa.load(),
    datasource_compañias.load(),
    datasource_entidades.load(),
  )
    .then(function () {
      if (datasource_filtroTiempo_spSelectComparativa.items().length > 0) {
        $("#dxForm_opciones").dxForm({
          labelLocation: "top",
          height: "100%",
          scrollingEnabled: true,
          colCount: 1,
          items: [
            {
              label: { text: getTrad("tipoTabla") },
              editorType: "dxRadioGroup",
              editorOptions: {
                elementAttr: {
                  id: "dxRadioGroup_unidadesPeso",
                  class: "dxRadioGroup_opciones",
                },
                dataSource: enum_tipoTabla,
                valueExpr: "idTipoTabla",
                displayExpr: "denominacion",
                layout: "horizontal",
                value: 2,
                height: "100%",
                onValueChanged: function (e) {
                  setDataSource_comparativa(true);
                  is_unidades = e.value === 1 ? true : false;
                },
              },
            },
          ],
        });

        $("#InfKgLavados #dxContainer").dxBox({
          direction: "col",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          items: [
            {
              ratio: 1,
              template: function (itemData, itemIndex, itemElement) {
                itemElement.append(
                  $("<div />").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          item.append(
                            $("<div />").dxBox({
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  ratio: 1,
                                  template: function (e, index, item) {
                                    //#region La primera vez que se inicia coge estas fechas por defecto
                                    var ultimaFecha = new Date(
                                      datasource_filtroTiempo_spSelectComparativa.items()[
                                        datasource_filtroTiempo_spSelectComparativa.items()
                                          .length - 1
                                      ].arg,
                                    );
                                    fechaDesde = startOfMonth(ultimaFecha);
                                    fechaHasta = new Date(ultimaFecha);
                                    //#endregion

                                    is_unidades =
                                      $("#dxRadioGroup_unidadesPeso")
                                        .dxRadioGroup("instance")
                                        .option("value") === 1
                                        ? true
                                        : false;
                                    item.append(
                                      $(
                                        "<div id='dxDataGrid_comparativa_compañias' />",
                                      ).dxDataGrid({
                                        //Propiedades
                                        headerFilter: {
                                          visible: true,
                                        },
                                        export: {
                                          fileName: "InfKgLavados_compañias",
                                        },
                                        filterRow: {
                                          visible: true,
                                          applyFilter: "auto",
                                          showAllText:
                                            getTrad("todos").toUpperCase(),
                                        },
                                        height: "100%",
                                        columnAutoWidth: true,
                                        paging: {
                                          enabled: false,
                                        },
                                        selection: {
                                          mode: "single",
                                        },
                                        columns: [
                                          {
                                            dataField: "orden",
                                            visible: false,
                                            sortIndex: 1,
                                            sortOrder: "asc",
                                          },
                                          {
                                            dataField: "idCompañia",
                                            caption: getTrad("compañia"),
                                            sortIndex: 2,
                                            sortOrder: "asc",
                                            lookup: {
                                              dataSource: {
                                                store:
                                                  datasource_compañias.store(),
                                                sort: "denominacion",
                                              },
                                              valueExpr: "idCompañia",
                                              displayExpr: "denominacion",
                                            },
                                            calculateSortValue: function (
                                              data,
                                            ) {
                                              var value =
                                                this.calculateCellValue(data);
                                              return this.lookup.calculateCellValue(
                                                value,
                                              );
                                            },
                                          },
                                          {
                                            dataField: "entregadas",
                                            caption: getTrad("entregadas"),
                                            customizeText: function (data) {
                                              return is_unidades
                                                ? formatNumber(data.value)
                                                : formatNumber(
                                                    data.value,
                                                    2,
                                                    "decimal",
                                                  );
                                            },
                                            dataType: "string",
                                            width: 110,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                          {
                                            dataField: "porcentaje_entrega",
                                            caption: "% " + getTrad("entrega"),
                                            customizeText: function (data) {
                                              return formatNumber(
                                                data.value,
                                                2,
                                                "percent",
                                              );
                                            },
                                            dataType: "string",
                                            width: 100,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                          {
                                            dataField: "kgLavados",
                                            caption: getTrad("kgLavados"),
                                            customizeText: function (data) {
                                              let value =
                                                data.value !== undefined
                                                  ? data.value
                                                  : 0;
                                              return (
                                                formatNumber(
                                                  value,
                                                  2,
                                                  "decimal",
                                                ) + " Kg."
                                              );
                                            },
                                            dataType: "string",
                                            width: 110,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                        ],
                                        summary: {
                                          recalculateWhileEditing: true,
                                          totalItems: [
                                            {
                                              column: "denominacion",
                                              displayFormat:
                                                getTrad("total").toUpperCase(),
                                            },
                                            {
                                              column: "rechazadas",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              name: "porcentaje_rechazo",
                                              showInColumn:
                                                "porcentaje_rechazo",
                                              summaryType: "custom",
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              column: "entregadas",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                            },
                                            {
                                              column: "diferencia",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                            },
                                            {
                                              name: "porcentaje_entrega",
                                              showInColumn:
                                                "porcentaje_entrega",
                                              summaryType: "custom",
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              column: "kgLavados",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return (
                                                  formatNumber(
                                                    data.value,
                                                    2,
                                                    "decimal",
                                                  ) + " Kg"
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                          ],
                                          calculateCustomSummary: function (
                                            options,
                                          ) {
                                            if (
                                              options.name ===
                                              "porcentaje_rechazo"
                                            ) {
                                              if (
                                                options.summaryProcess ===
                                                "start"
                                              ) {
                                                options.totalValue = [0, 0];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "calculate"
                                              ) {
                                                options.totalValue = [
                                                  options.totalValue[0] +
                                                    options.value.rechazadas,
                                                  options.totalValue[1] +
                                                    options.value.procesadas,
                                                ];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "finalize"
                                              ) {
                                                options.totalValue =
                                                  options.totalValue[0] /
                                                  options.totalValue[1];
                                              }
                                            }

                                            if (
                                              options.name ===
                                              "porcentaje_entrega"
                                            ) {
                                              if (
                                                options.summaryProcess ===
                                                "start"
                                              ) {
                                                options.totalValue = [0, 0];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "calculate"
                                              ) {
                                                options.totalValue = [
                                                  options.totalValue[0] +
                                                    options.value.entregadas,
                                                  options.totalValue[1] +
                                                    options.value.procesadas,
                                                ];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "finalize"
                                              ) {
                                                options.totalValue =
                                                  options.totalValue[1] !== 0
                                                    ? options.totalValue[0] /
                                                      options.totalValue[1]
                                                    : 0;
                                              }
                                            }
                                          },
                                        },
                                        onSelectionChanged: function (
                                          selectedItems,
                                        ) {
                                          var data =
                                            selectedItems.selectedRowsData[0];
                                          compaSel = data
                                            ? data
                                            : { idCompañia: -1 };

                                          setDataSource_comparativa(false);
                                        },
                                        onCellPrepared: function (e) {
                                          if (e.rowType === "data")
                                            e.cellElement.css(
                                              "cursor",
                                              "pointer",
                                            );
                                        },
                                        onToolbarPreparing: function (e) {
                                          e.toolbarOptions.height = 55;
                                          e.toolbarOptions.items.unshift(
                                            {
                                              location: "before",
                                              template: function (
                                                e,
                                                index,
                                                item,
                                              ) {
                                                return $("<div />")
                                                  .addClass(
                                                    "dxChart_contenedorIndividual",
                                                  )
                                                  .append(
                                                    $(
                                                      "<div id='toolbarTitle' />",
                                                    )
                                                      .text(
                                                        getTrad(
                                                          "totalKgLavados",
                                                        ).toUpperCase(),
                                                      )
                                                      .append(
                                                        $(
                                                          "<div id='kgLavados_text'  />",
                                                        ).addClass(
                                                          "lblKgLavados",
                                                        ),
                                                      ),
                                                  );
                                              },
                                            },
                                            {
                                              location: "before",
                                              template: function (
                                                e,
                                                index,
                                                item,
                                              ) {
                                                return $("<div />")
                                                  .addClass(
                                                    "dxChart_contenedorIndividual",
                                                  )
                                                  .append(
                                                    $(
                                                      "<div id='toolbarPorcentaje' />",
                                                    )
                                                      .text(
                                                        getTrad(
                                                          "porcentaje_kgProcesados_kgLavados",
                                                        ).toUpperCase(),
                                                      )
                                                      .append(
                                                        $(
                                                          "<div id='kgLavadosProcesados_text'  />",
                                                        ).addClass(
                                                          "kgLavadosProcesados",
                                                        ),
                                                      ),
                                                  );
                                              },
                                            },
                                            {
                                              location: "after",
                                              widget: "dxButton",
                                              options: {
                                                elementAttr: {
                                                  class: "dxButton_toolbar",
                                                },
                                                text: getTrad("exportar"),
                                                icon: "exportxlsx",
                                                onClick: function () {
                                                  $(
                                                    "#dxDataGrid_comparativa_compañias",
                                                  )
                                                    .dxDataGrid("instance")
                                                    .exportToExcel();
                                                },
                                              },
                                            },
                                          );
                                        },
                                        //Estilos
                                        showColumnLines: false,
                                        showRowLines: true,
                                        rowAlternationEnabled: true,
                                      }),
                                    );
                                  },
                                },
                                {
                                  ratio: 0,
                                  baseSize: 20,
                                },
                                {
                                  ratio: 1,
                                  template: function (e, index, item) {
                                    //#region La primera vez que se inicia coge estas fechas por defecto
                                    var ultimaFecha = new Date(
                                      datasource_filtroTiempo_spSelectComparativa.items()[
                                        datasource_filtroTiempo_spSelectComparativa.items()
                                          .length - 1
                                      ].arg,
                                    );
                                    fechaDesde = startOfMonth(ultimaFecha);
                                    fechaHasta = new Date(ultimaFecha);
                                    //#endregion

                                    is_unidades =
                                      $("#dxRadioGroup_unidadesPeso")
                                        .dxRadioGroup("instance")
                                        .option("value") === 1
                                        ? true
                                        : false;
                                    item.append(
                                      $(
                                        "<div id='dxDataGrid_comparativa_entidades' />",
                                      ).dxDataGrid({
                                        //Propiedades
                                        headerFilter: {
                                          visible: true,
                                        },
                                        export: {
                                          fileName: "InfKgLavados_entidades",
                                        },
                                        filterRow: {
                                          visible: true,
                                          applyFilter: "auto",
                                          showAllText:
                                            getTrad("todos").toUpperCase(),
                                        },
                                        loadPanel: {
                                          enabled: false,
                                        },
                                        height: "100%",
                                        columnAutoWidth: true,
                                        paging: {
                                          enabled: false,
                                        },
                                        columns: [
                                          {
                                            dataField: "orden",
                                            visible: false,
                                            sortIndex: 1,
                                            sortOrder: "asc",
                                          },
                                          {
                                            dataField: "idEntidad",
                                            caption: getTrad("entidades"),
                                            sortIndex: 2,
                                            sortOrder: "asc",
                                            lookup: {
                                              dataSource: {
                                                store:
                                                  datasource_entidades.store(),
                                                sort: "denominacion",
                                              },
                                              valueExpr: "idEntidad",
                                              displayExpr: "denominacion",
                                            },
                                            calculateSortValue: function (
                                              data,
                                            ) {
                                              var value =
                                                this.calculateCellValue(data);
                                              return this.lookup.calculateCellValue(
                                                value,
                                              );
                                            },
                                          },
                                          ,
                                          {
                                            dataField: "entregadas",
                                            caption: getTrad("entregadas"),
                                            customizeText: function (data) {
                                              return is_unidades
                                                ? formatNumber(data.value)
                                                : formatNumber(
                                                    data.value,
                                                    2,
                                                    "decimal",
                                                  );
                                            },
                                            dataType: "string",
                                            width: 110,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                          {
                                            dataField: "porcentaje_entrega",
                                            caption: "% " + getTrad("entrega"),
                                            customizeText: function (data) {
                                              return formatNumber(
                                                data.value,
                                                2,
                                                "percent",
                                              );
                                            },
                                            dataType: "string",
                                            width: 100,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                          {
                                            dataField: "kgLavados",
                                            caption: getTrad("kgLavados"),
                                            customizeText: function (data) {
                                              let value =
                                                data.value !== undefined
                                                  ? data.value
                                                  : 0;
                                              return (
                                                formatNumber(
                                                  value,
                                                  2,
                                                  "decimal",
                                                ) + " Kg."
                                              );
                                            },
                                            dataType: "string",
                                            width: 110,
                                            allowFiltering: false,
                                            alignment: "center",
                                          },
                                        ],
                                        summary: {
                                          recalculateWhileEditing: true,
                                          totalItems: [
                                            {
                                              column: "denominacion",
                                              displayFormat:
                                                getTrad("total").toUpperCase(),
                                            },

                                            {
                                              column: "rechazadas",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              name: "porcentaje_rechazo",
                                              showInColumn:
                                                "porcentaje_rechazo",
                                              summaryType: "custom",
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              column: "entregadas",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                            },
                                            {
                                              column: "diferencia",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return is_unidades
                                                  ? ""
                                                  : formatNumber(
                                                      data.value,
                                                      2,
                                                      "decimal",
                                                    ) + " Kg";
                                              },
                                            },
                                            {
                                              name: "porcentaje_entrega",
                                              showInColumn:
                                                "porcentaje_entrega",
                                              summaryType: "custom",
                                              customizeText: function (data) {
                                                return formatNumber(
                                                  data.value,
                                                  2,
                                                  "percent",
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                            {
                                              column: "kgLavados",
                                              summaryType: "sum",
                                              customizeText: function (data) {
                                                return (
                                                  formatNumber(
                                                    data.value,
                                                    2,
                                                    "decimal",
                                                  ) + " Kg"
                                                );
                                              },
                                              displayFormat: "{0}",
                                            },
                                          ],
                                          calculateCustomSummary: function (
                                            options,
                                          ) {
                                            if (
                                              options.name ===
                                              "porcentaje_rechazo"
                                            ) {
                                              if (
                                                options.summaryProcess ===
                                                "start"
                                              ) {
                                                options.totalValue = [0, 0];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "calculate"
                                              ) {
                                                options.totalValue = [
                                                  options.totalValue[0] +
                                                    options.value.rechazadas,
                                                  options.totalValue[1] +
                                                    options.value.procesadas,
                                                ];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "finalize"
                                              ) {
                                                options.totalValue =
                                                  options.totalValue[0] /
                                                  options.totalValue[1];
                                              }
                                            }

                                            if (
                                              options.name ===
                                              "porcentaje_entrega"
                                            ) {
                                              if (
                                                options.summaryProcess ===
                                                "start"
                                              ) {
                                                options.totalValue = [0, 0];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "calculate"
                                              ) {
                                                options.totalValue = [
                                                  options.totalValue[0] +
                                                    options.value.entregadas,
                                                  options.totalValue[1] +
                                                    options.value.procesadas,
                                                ];
                                              }
                                              if (
                                                options.summaryProcess ===
                                                "finalize"
                                              ) {
                                                options.totalValue =
                                                  options.totalValue[1] !== 0
                                                    ? options.totalValue[0] /
                                                      options.totalValue[1]
                                                    : 0;
                                              }
                                            }
                                          },
                                        },
                                        onToolbarPreparing: function (e) {
                                          e.toolbarOptions.height = 55;
                                          e.toolbarOptions.items.unshift({
                                            location: "after",
                                            widget: "dxButton",
                                            options: {
                                              elementAttr: {
                                                class: "dxButton_toolbar",
                                              },
                                              text: getTrad("exportar"),
                                              icon: "exportxlsx",
                                              onClick: function () {
                                                $(
                                                  "#dxDataGrid_comparativa_entidades",
                                                )
                                                  .dxDataGrid("instance")
                                                  .exportToExcel();
                                              },
                                            },
                                          });
                                        },
                                        //Estilos
                                        showColumnLines: false,
                                        showRowLines: true,
                                        rowAlternationEnabled: true,
                                      }),
                                    );
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                    ],
                  }),
                );
              },
            },
            {
              // Range selector
              baseSize: 130,
              template: function (e, index, itemElement) {
                itemElement.append(
                  create_rangeSelector(
                    datasource_filtroTiempo_spSelectComparativa,
                    false,
                    false,
                    function (e) {
                      fechaDesde = e.value[0];
                      fechaHasta = e.component.option("valueHasta");

                      $.when(
                        datasource_infKgLavados_spSelectComparativa.load(),
                        infProcesadosEntregados_kgLavadosProcesados.load(),
                      ).then(function (itemsComparativa, itemsKgLavados) {
                        kgLavados =
                          itemsKgLavados.length > 0 ? itemsKgLavados[0] : [];
                        setDataSource_comparativa(true);

                        calculo_lavadosProcesados_lavanderia(itemsKgLavados);

                        COMPONENT.loadPanel_hide();
                        $("#dxDataGrid_comparativa_compañias")
                          .dxDataGrid("instance")
                          .endCustomLoading();
                      });
                      dxRangeSelector_PosicionValor_nuevoMarker(
                        $("#dxRangeSelector_filtroTiempo"),
                        fechaDesde,
                        fechaHasta,
                      );
                    },
                    function () {
                      var ultimaFecha = new Date(
                        datasource_filtroTiempo_spSelectComparativa.items()[
                          datasource_filtroTiempo_spSelectComparativa.items()
                            .length - 1
                        ].arg,
                      );
                      $("#dxRangeSelector_filtroTiempo")
                        .dxRangeSelector("instance")
                        .option({
                          "scale.minRange": { days: null, months: 1 },
                          value: [
                            startOfMonth(ultimaFecha),
                            endOfMonth(new Date(ultimaFecha)),
                          ],
                        });
                    },
                  ),
                );
                $.when(
                  datasource_infKgLavados_spSelectComparativa.load(),
                  infProcesadosEntregados_kgLavadosProcesados.load(),
                ).then(function (itemsComparativa, itemsKgLavados) {
                  kgLavados =
                    itemsKgLavados.length > 0 ? itemsKgLavados[0] : [];
                  setDataSource_comparativa(true);
                  calculo_lavadosProcesados_lavanderia(itemsKgLavados);

                  COMPONENT.loadPanel_hide();
                  $("#dxDataGrid_comparativa_compañias")
                    .dxDataGrid("instance")
                    .endCustomLoading();
                });
              },
            },
          ],
        });
      } else {
        COMPONENT.loadPanel_hide();
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "btnErrorSinDatos",
          ],
        ]);
      }
    })
    .always(function () {
      setTimeout(() => {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  //#region FUNCIONES INTERNAS

  function calculo_lavadosProcesados_lavanderia(itemsKgLavados) {
    let kgLavados = 0;
    $.each(itemsKgLavados[0], function (index, item) {
      kgLavados += item.kgLavados;
    });

    let kgProcesados =
      itemsKgLavados.length > 0 && itemsKgLavados[0].length > 0
        ? itemsKgLavados[0][0].kgProcesados
        : 0;
    let porc_lavadosProcesados =
      kgProcesados > 0 ? kgProcesados / kgLavados - 1 : 0;

    $("#kgLavados_text").text(formatNumber(kgLavados, 2, "decimal") + " Kg.");
    $("#kgLavadosProcesados_text").text(
      formatNumber(porc_lavadosProcesados, 2, "percent") + " Kg.",
    );
  }

  function filtroDatos_comparativa(items, isExport) {
    isExport = isExport || false;
    if (!fechaDesde || !fechaHasta) {
      var dxRangeSelector_filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo",
      ).dxRangeSelector("instance");
      fechaDesde = dxRangeSelector_filtroTiempo.option("value")[0];
      fechaHasta = dxRangeSelector_filtroTiempo.option("valueHasta");
    }

    // COGER LOS FILTROS DE LA PÁGINA
    var items_local = $.extend(true, [], items),
      idFamiliaSel =
        typeof $("#dxRadioGroup_familias").data("dxRadioGroup") !== "undefined"
          ? $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value")
          : 0,
      idsTipoProduccionSel =
        typeof $("#dxList_TipoProduccion").data("dxList") !== "undefined"
          ? $("#dxList_TipoProduccion")
              .dxList("instance")
              .option("selectedItemKeys")
          : [];

    // CAMBIAR A UNIDADES O A PESO SEGÚN SELECTOR SWITCH
    if (isExport === false) {
      is_unidades =
        $("#dxRadioGroup_unidadesPeso")
          .dxRadioGroup("instance")
          .option("value") === 1
          ? true
          : false;
      if (!is_unidades) {
        $.each(items_local, function (index, element) {
          element.procesadas = element.peso * 0.001 * element.procesadas;
          element.rechazadas = element.peso * 0.001 * element.rechazadas;
          element.entregadas = element.peso * 0.001 * element.entregadas;
        });
      }
    }

    return items_local.filter(function (dataItem) {
      var is_tipoProduccion =
          idsTipoProduccionSel.length !== 0
            ? idsTipoProduccionSel.indexOf(dataItem.idTipoProduccion) > -1
            : true,
        is_familia =
          idFamiliaSel !== 0 ? idFamiliaSel === dataItem.idFamilia : true;
      return is_tipoProduccion && is_familia;
    });
  }

  function setDataSource_comparativa(isCompañia) {
    //#region Asignar a cada entidad, su compañía correspondiente
    $.each(
      datasource_infKgLavados_spSelectComparativa.items(),
      function (index, item) {
        if (item.idCompañia == null) {
          let enti = $.grep(datasource_entidades.items(), function (entidad) {
            return item.idEntidad === entidad.idEntidad;
          });
          if (enti.length > 0) item.idCompañia = enti[0].idCompañia;
        }
      },
    );
    //#endregion

    var items_comparativa = filtroDatos_comparativa(
      datasource_infKgLavados_spSelectComparativa.items(),
    );

    // FILTRADO, AGRUPACIÓN Y SUMATORIO DE DATOS
    if (isCompañia) {
      var dataSource = query(items_comparativa)
        .groupBy(function (dataItem) {
          return [dataItem.idCompañia];
        })
        .select(function (dataItem) {
          var resultItem = null;
          query(dataItem.items)
            .sum("entregadas")
            .done(function (result) {
              resultItem = {
                idCompañia: dataItem.key[0],
                entregadas: result,
              };
            });
          return resultItem;
        })
        .toArray();

      let listaCompañias = [];
      $.each(datasource_compañias.items(), function (index, item) {
        if (item.idCompañia !== -1 && item.idCompañia !== 0) {
          item.entregadas = 0;
          item.porcentaje_entrega = 0;
          item.kgLavados = 0;
          item.orden = 2;
          listaCompañias.push(item);
        }
      });

      $.each(listaCompañias, function (indx, compa) {
        $.each(dataSource, function (index, item) {
          if (item.idCompañia === compa.idCompañia) {
            compa.entregadas = item.entregadas;
            compa.porcentaje_entrega = 0;
            compa.kgLavados = 0;
            compa.orden = 1;
          }
        });
      });

      //TOTAL ENTREGAS
      let totalEntregadas = 0;
      $.each(listaCompañias, function (index, item) {
        totalEntregadas += item.entregadas;
      });

      //Porcentaje entrega
      $.each(listaCompañias, function (index, item) {
        item.porcentaje_entrega =
          totalEntregadas !== 0 && item.entregadas !== 0
            ? item.entregadas / totalEntregadas
            : 0;
      });

      //#region Añadir kgLavados
      if (kgLavados.length > 0) {
        $.each(kgLavados, function (index_kgLavados, item_kgLavados) {
          if (item_kgLavados.idEntidad != null) {
            $.each(datasource_entidades.items(), function (index, item) {
              if (item.idEntidad === item_kgLavados.idEntidad)
                item_kgLavados.idCompañia = item.idCompañia;
            });
          }
          if (item_kgLavados.idCompañia != null) {
            $.each(listaCompañias, function (index, item) {
              if (item.idCompañia === item_kgLavados.idCompañia)
                item.kgLavados += item_kgLavados.kgLavados;
            });
          }
        });
      } else {
        $.each(listaCompañias, function (index, item) {
          item.kgLavados = 0;
        });
      }
      //#endregion

      $("#dxDataGrid_comparativa_compañias")
        .dxDataGrid("instance")
        .option("dataSource", listaCompañias);
    } else {
      var dataSource = query(items_comparativa)
        .groupBy(function (dataItem) {
          return [dataItem.idCompañia, dataItem.idEntidad];
        })
        .select(function (dataItem) {
          var resultItem = null;
          query(dataItem.items)
            .sum("entregadas")
            .done(function (result) {
              resultItem = {
                idCompañia: dataItem.key[0],
                idEntidad: dataItem.key[1],
                entregadas: result,
              };
            });
          return resultItem;
        })
        .toArray();

      let listaEntidadesNCompa = [];
      $.each(datasource_entidades.items(), function (index, item) {
        if (
          item.idEntidad !== -1 &&
          item.idEntidad !== 0 &&
          item.idCompañia === compaSel.idCompañia
        ) {
          item.entregadas = 0;
          item.porcentaje_entrega = 0;
          item.kgLavados = 0;
          item.orden = 2;
          listaEntidadesNCompa.push(item);
        }
      });

      $.each(listaEntidadesNCompa, function (indx, enti) {
        $.each(dataSource, function (index, item) {
          if (item.idEntidad === enti.idEntidad) {
            enti.entregadas = item.entregadas;
            enti.porcentaje_entrega = 0;
            enti.kgLavados = 0;
            enti.orden = 1;
          }
        });
      });

      let totalEntregadas = 0;
      $.each(listaEntidadesNCompa, function (index, item) {
        totalEntregadas += item.entregadas;
      });

      let total_kgLavados = 0;
      let total_kgLavados_entidad = 0; // Para saber si hay que dividir los kg de compa entre las entis (si ninguna tiene kg)

      $.each(kgLavados, function (index, item) {
        $.each(listaEntidadesNCompa, function (iEnti, entidad) {
          if (item.idEntidad === entidad.idEntidad) {
            total_kgLavados_entidad += item.kgLavados;
            entidad.kgLavados = item.kgLavados;
          }
        });
        if (compaSel.idCompañia === item.idCompañia)
          total_kgLavados += item.kgLavados;
      });

      $.each(listaEntidadesNCompa, function (index, item) {
        item.porcentaje_entrega =
          item.entregadas !== 0 && totalEntregadas !== 0
            ? item.entregadas / totalEntregadas
            : 0;
        if (
          (compaSel.kgLavados != null) & (compaSel.kgLavados > 0) &&
          item.kgLavados === 0 &&
          total_kgLavados_entidad === 0
        )
          item.kgLavados =
            item.porcentaje_entrega !== 0 && total_kgLavados !== 0
              ? item.porcentaje_entrega * total_kgLavados
              : 0;
      });
      $("#dxDataGrid_comparativa_entidades")
        .dxDataGrid("instance")
        .option("dataSource", listaEntidadesNCompa);
      //#endregion
    }
  }
  //#endregion

  return deferred.promise();
}
