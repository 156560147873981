import React, { Fragment } from "react";
import { connect } from "react-redux";
import notify from "devextreme/ui/notify";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  formatDate_noTime_parameter,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  capitalize,
  durationToDatetime,
  leadingZero,
  isSameDay,
  startOfYear,
  endOfYear,
  datetimeToDuration,
} from "../../../helpers";

import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import List from "devextreme-react/list";
import Box, { Item as ItemBox } from "devextreme-react/box";
import SelectBox from "devextreme-react/select-box";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Lookup } from "devextreme-react/lookup";
import { LoadPanel } from "devextreme-react/load-panel";

//CUSTOM COMPONENTS
import "react-phone-input-2/lib/style.css";
import YearCalendar from "../../../components/YearCalendar/YearCalendar";
import DropDownBox_filtroCategoriasInternas from "../../../components/DropDownBox_filtroCategoriasInternas/index";

//Css
import "./Css.scss";
import ResumenEstados from "./components";

const calendarContainer = { of: ".calendar" };
class CalendarioLaboral_RRHH extends React.Component {
  constructor(props) {
    super(props);

    let { user } = this.props;

    this.state = {
      idPersonaSel: [],
      datasource_tblCategoria: null,
      isPersonaActivo: true,
      idCategoriasSel: null,
      idCentroLavSel: null,
      yearCalendar_currentYear: new Date().getFullYear(),
      calendarData: [],
      yearCalendar_newData: [],
      disabledDays: [],
      tblPersonaNTipoContratoItems: [],
      isVisible_calendarContainer_loadPanel: false,
      isFirstLoad: true,
      fechaSel: null,
      idPaisSel: user.idPais,
      isOficina_checked: false,
      isOpened_dropDownBox_filtroCategoriasInternas: null,
      resumenEstados_data: [],
      is_dxList_persona_disabled: false,
      dxList_seachValue: "",
    };

    // REFERENCIAS
    this.dxList_personas_REF = React.createRef();
    this.dxLoadPanel_calendario_REF = React.createRef();

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);

    //EVENTOS
    this.dxSelectBox_activo_onValueChanged =
      this.dxSelectBox_activo_onValueChanged.bind(this);
    this.dxLookup_idCentroLav_onValueChanged =
      this.dxLookup_idCentroLav_onValueChanged.bind(this);
    this.dxList_personas_onOptionChanged =
      this.dxList_personas_onOptionChanged.bind(this);
    this.yearCalendar_onDateChanged =
      this.yearCalendar_onDateChanged.bind(this);
    this.yearCalendar_onYearChanged =
      this.yearCalendar_onYearChanged.bind(this);
    this.yearCalendar_onEstadoDeleting =
      this.yearCalendar_onEstadoDeleting.bind(this);
    this.dxLookup_onOpened = this.dxLookup_onOpened.bind(this);
    this.dxLookup_onClosed = this.dxLookup_onClosed.bind(this);

    this.DropDownBox_filtroCategoriasInternas_onValueChanged =
      this.DropDownBox_filtroCategoriasInternas_onValueChanged.bind(this);
    this.setResumenEstadosData = this.setResumenEstadosData.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  //REFERENCIAS
  get dxList_personas() {
    return this.dxList_personas_REF.current.instance;
  }

  get dxLoadPanel_calendario() {
    return this.dxLoadPanel_calendario_REF.current.instance;
  }

  //#region DATA SOURCE
  datasource_persona = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_persona_beforeSend(request);
      },
      onLoading: (data) => {
        this.datasource_persona_onLoading(data);
      },
      onLoaded: (data) => {
        this.datasource_persona_onLoaded(data);
      },
      version: 4,
    }),
    sort: ["nombre", "apellidos"],
    select: ["idPersona", "nombre", "apellidos"],
    expand: ["tblCalendarioPersonal($top=0)"],
  });

  datasource_persona_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.filtroTipoTrabajoRRHH = false;
  }

  datasource_persona_onLoading(loadOptions) {
    let {
      isPersonaActivo,
      idCategoriasSel,
      idCentroLavSel,
      dxList_seachValue,
    } = this.state;
    let parsed = JSON.parse(idCentroLavSel);
    let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;
    let idCentroTrabajo = !parsed ? null : parsed[0] > 0 ? parsed[0] : null;

    let filtroActivo =
      isPersonaActivo != null ? "activo eq " + isPersonaActivo : null;
    let filtroCategoria =
      idCategoriasSel != null && idCategoriasSel.length > 0
        ? "idCategoriaInterna in (" +
          $.map(idCategoriasSel, function (item) {
            return item;
          }) +
          ")"
        : null;
    let filtroLavanderia =
      idLavanderia != null ? "idLavanderia eq " + idLavanderia : null;
    let filtroCentroTrabajo =
      idCentroTrabajo != null ? "idCentroTrabajo eq " + idCentroTrabajo : null;

    if (!loadOptions.filter || loadOptions.filter.length === 0) {
      loadOptions.filter = [
        filtroActivo != null ? [filtroActivo] : ["true"],
        "and",
        filtroCategoria != null ? [filtroCategoria] : ["true"],
        "and",
        filtroLavanderia != null ? [filtroLavanderia] : ["true"],
        "and",
        filtroCentroTrabajo != null ? [filtroCentroTrabajo] : ["true"],
        "and",
        ["eliminado eq false"],
        "and"["idCategoria eq null"],
      ];
    } else {
      loadOptions.filter = [
        [
          "concat(concat(nombre, ' '), apellidos)",
          "contains",
          dxList_seachValue,
        ],
        "and",
        filtroActivo != null ? [filtroActivo] : ["true"],
        "and",
        filtroCategoria != null ? [filtroCategoria] : ["true"],
        "and",
        filtroLavanderia != null ? [filtroLavanderia] : ["true"],
        "and",
        filtroCentroTrabajo != null ? [filtroCentroTrabajo] : ["true"],
        "and",
        ["eliminado eq false"],
        "and"["idCategoria eq null"],
      ];
    }
  }

  datasource_persona_onLoaded(data) {
    $.each(data, function (index, item) {
      if (item.horasDiarias) {
        let horasDiarias_dateTime = durationToDatetime(item.horasDiarias);
        item.horasDiarias =
          leadingZero(horasDiarias_dateTime.getHours()) +
          ":" +
          leadingZero(horasDiarias_dateTime.getMinutes());
      }

      item.idCentroLav = JSON.stringify([
        item.idCentroTrabajo ? item.idCentroTrabajo : -1,
        item.idLavanderia ? item.idLavanderia : -1,
      ]);

      item.isUsuario = item["tblUsuario@odata.count"] > 0;
      item.nombreCompuesto = item.nombre + " " + item.apellidos;
    });
  }

  enum_activo_noActivo = [
    {
      text: capitalize(this.getTrad("activo")),
      value: true,
    },
    {
      text: capitalize(this.getTrad("noActivo")),
      value: false,
    },
  ];

  datasource_cuadrantePersonal = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCuadrantePersonal",
      key: "idCuadrantePersonal",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_cuadrantePersonal_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["fecha desc"],
    select: ["fecha"],
  });

  datasource_cuadrantePersonal_beforeSend(request) {
    let { idPersonaSel } = this.state;

    request.headers = { ...authHeader() };

    request.params.$filter = `(idPersona eq ${idPersonaSel[0]}) and (idEstado ne null)`;
    request.params.$top = 1;
  }

  datasource_tblCategoriaInterna = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      group: "idCategoriaConvenio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    select: ["idCategoriaConvenio", "idCategoriaInterna", "denominacion"],
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCentroTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
      key: "idCentroTrabajo",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCentroTrabajo_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
  });

  datasource_tblCentroTrabajo_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }

  datasource_tblLavanderiaNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderiaNUsuario_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion, idPais"],
    sort: ["denominacion"],
  });

  datasource_tblLavanderiaNUsuario_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }

  datasource_datos_tblCalendarioPersonal_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCalendarioPersonal_Estado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_datos_tblCalendarioPersonal_Estado_postProcess(
        data,
      );
    },
    filter: "idEstado ne 14",
  });

  datasource_datos_tblCalendarioPersonal_Estado_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_datos_tblCalendarioPersonal_Estado_postProcess(data) {
    data.sort((a, b) =>
      getTrad(a.traduccion) > getTrad(b.traduccion)
        ? 1
        : getTrad(b.traduccion) > getTrad(a.traduccion)
          ? -1
          : 0,
    );
    if (
      this.datasource_datos_tblCalendarioPersonal_Estado.items().length === 0
    ) {
      data.splice(0, 0, {
        idEstado: 0,
        denominacion: "Sin estado",
        traduccion: "sinEstado",
        colorHexa: "#FFFFFF",
        isLavanderia: false,
      });
    }
    return data;
  }

  context_calendarioLaboral = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblCalendarioPersonal",
    entities: {
      fn_spSelectCalendarioLaboral: {
        key: ["idPersona", "fecha"],
      },
      fn_iudCalendarioPersonal: {},
    },
    beforeSend: (request) => {
      this.context_calendarioLaboral_beforeSend(request);
    },
  });

  context_calendarioLaboral_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  context_calendarioPersonal = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal",
    entities: {
      fn_IU_tblCalendarioPersonal: {},
    },
    beforeSend: (request) => {
      this.context_calendarioPersonal_beforeSend(request);
    },
  });

  context_calendarioPersonal_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.timeout = 300000;

    let { idPersonaSel } = this.state;
    let yearCalendar_newData = [...this.state.yearCalendar_newData];
    request.params.idPersona = idPersonaSel[0];

    yearCalendar_newData = $.map(yearCalendar_newData, function (item) {
      return {
        idPersona: idPersonaSel[0],
        idEstado: item.idEstado,
        fecha: item.fecha,
      };
    });
    yearCalendar_newData = yearCalendar_newData.sort(function (a, b) {
      return new Date(a.fecha) - new Date(b.fecha);
    });

    let rangos = [];
    $.each(yearCalendar_newData, function (index, date) {
      let date_ = new Date(date.fecha);
      let diaAnterior = new Date(
        new Date(date_).setDate(new Date(date_).getDate() - 1),
      );
      let diaSiguiente = new Date(
        new Date(date_).setDate(new Date(date_).getDate() + 1),
      );

      let obj_diaActual = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == date_.toDateString();
      });
      let obj_diaAnterior = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaAnterior.toDateString();
      });
      let obj_diaSiguiente = $.grep(yearCalendar_newData, function (item) {
        return item.fecha.toDateString() == diaSiguiente.toDateString();
      });

      if (obj_diaAnterior.length > 0) {
        if (obj_diaAnterior[0].idEstado != obj_diaActual[0].idEstado) {
          rangos.push([date]);
        }
      } else {
        rangos.push([date]);
      }

      if (obj_diaSiguiente.length > 0) {
        if (obj_diaSiguiente[0].idEstado != obj_diaActual[0].idEstado) {
          rangos[rangos.length - 1].push(date);
        }
      } else {
        rangos[rangos.length - 1].push(date);
      }
    });

    let formatRangos = [];
    $.each(rangos, function (index, items) {
      formatRangos.push({
        idEstado: items[0].idEstado,
        fechaDesde: items[0].fecha,
        fechaHasta: items[1].fecha,
        idPersona: items[0].idPersona,
      });
    });
    request.payload = { fechasCalendario: formatRangos };
  }

  datasource_tblPersonaNTipoContrato = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersonaNTipoContrato",
      key: "idDocumento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersonaNTipoContrato_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblPersonaNTipoContrato_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { idPersonaSel } = this.state;
    request.params.idPersona = idPersonaSel.length > 0 ? idPersonaSel[0] : -1;
  }

  //#endregion

  cargaDatos_inicio() {
    let _this = this;
    _this.loadPanel_show();
    $.when(
      this.datasource_tblCategoriaInterna.reload(),
      this.datasource_tblCentroTrabajo.reload(),
      this.datasource_tblLavanderiaNUsuario.reload(),
      this.datasource_datos_tblCalendarioPersonal_Estado.reload(),
      this.datasource_persona.reload(),
    ).then(
      function (
        tblCategoria,
        tblCentroTrabajo,
        tblLavanderiaNUsuario,
        tblCalendarioEstado,
      ) {
        let datasource_tblCentroTrabajo_Lavanderia = $.map(
          $.merge(tblCentroTrabajo[0], tblLavanderiaNUsuario[0]),
          function (item, index) {
            return {
              groupTitle: item.idCentroTrabajo
                ? getTrad("centrosTrabajo")
                : getTrad("lavanderías"),
              idCentroLav: JSON.stringify([
                item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                item.idLavanderia ? item.idLavanderia : -1,
              ]),
              denominacion: item.denominacion,
              idPais: item.idPais,
            };
          },
        );

        _this.setState(
          {
            datasource_tblCategoriaInterna: tblCategoria[0],
            datasource_tblCentroTrabajo_Lavanderia:
              datasource_tblCentroTrabajo_Lavanderia,
            datasource_tblCalendarioEstado: tblCalendarioEstado[0],
          },
          () => {
            _this.dxList_personas.selectItem(0);
          },
        );
      },
    );
  }

  datasource_tblJornadaPersona = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblJornadaPersona",
      key: ["idPersona", "fecha"],
      keyType: {
        idPersona: "Int32",
        fecha: "String",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblJornadaPersona_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblJornadaPersona_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { fechaSel, idPersonaSel } = this.state;
    let fecha = formatDate_noTime_parameter(fechaSel);

    if (request.method === "DELETE") {
      let newUrl = request.url.split("tblJornadaPersona");
      request.url =
        newUrl[0] + "tblJornadaPersona" + "/" + idPersonaSel[0] + "/" + fecha;
    }
  }

  datasource_tblCalendarioPersonal = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCalendarioPersonal",
      key: ["idPersona", "fecha"],
      keyType: {
        idPersona: "Int32",
        fecha: "String",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCalendarioPersonal_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCalendarioPersonal_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { fechaSel, idPersonaSel } = this.state;
    let fecha = formatDate_noTime_parameter(fechaSel);

    if (request.method === "DELETE") {
      let newUrl = request.url.split("tblCalendarioPersonal");
      request.url =
        newUrl[0] +
        "tblCalendarioPersonal" +
        "/" +
        idPersonaSel[0] +
        "/" +
        fecha;
    }
  }

  render() {
    let {
      idPersonaSel,
      is_dxList_persona_disabled,
      idPaisSel,
      isOficina_checked,
      isOpened_dropDownBox_filtroCategoriasInternas,
      isPersonaActivo,
      idCategoriasSel,
      datasource_tblCentroTrabajo_Lavanderia,
      idCentroLavSel,
      datasource_tblCalendarioEstado,
      yearCalendar_currentYear,
      calendarData,
      yearCalendar_newData,
      disabledDays,
      resumenEstados_data,
    } = this.state;
    let { idioma } = this.props;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div
            id="CalendarioLaboral_RRHH"
            className="formContainer scrollbar-container"
          >
            <LoadPanel
              ref={this.dxLoadPanel_calendario_REF}
              shadingColor="#FFF"
              deferRendering={false}
              animation={null}
              position={calendarContainer}
              showIndicator={true}
              shading={true}
              showPane={true}
            />
            <Box direction="row" width="100%" height="100%">
              <ItemBox ratio={1}>
                <Box direction="col" width="100%" height="100%">
                  <ItemBox baseSize={45}>
                    <Lookup
                      dataSource={
                        new DataSource({
                          group: "groupTitle",
                          store: datasource_tblCentroTrabajo_Lavanderia,
                        })
                      }
                      displayExpr={"denominacion"}
                      valueExpr={"idCentroLav"}
                      value={idCentroLavSel}
                      width="100%"
                      stylingMode="underlined"
                      closeOnOutsideClick={true}
                      grouped={true}
                      searchEnabled={false}
                      showCancelButton={false}
                      showClearButton={true}
                      placeholder={"Centro / lavandería"}
                      onContentReady={this.dxLookup_onContenReady}
                      onOpened={this.dxLookup_onOpened}
                      onClosed={this.dxLookup_onClosed}
                      onValueChanged={this.dxLookup_idCentroLav_onValueChanged}
                    />
                  </ItemBox>
                  <ItemBox ratio={1}>
                    <DropDownBox_filtroCategoriasInternas
                      idPaisSel={idPaisSel}
                      isOficina_checked={isOficina_checked}
                      hasFiltroConvenio={true}
                      idioma={idioma}
                      noDataText_sinPais="Seleccione una lavandería"
                      opened={isOpened_dropDownBox_filtroCategoriasInternas}
                      multiSeleccion={true}
                      onValueChanged={
                        this.DropDownBox_filtroCategoriasInternas_onValueChanged
                      }
                    />

                    <div style={{ height: 10 }} />
                    <Toolbar>
                      <ToolbarItem location="before">
                        <SelectBox
                          dataSource={this.enum_activo_noActivo}
                          displayExpr="text"
                          valueExpr="value"
                          value={isPersonaActivo}
                          placeholder={getTrad("todos")}
                          showClearButton={true}
                          onValueChanged={
                            this.dxSelectBox_activo_onValueChanged
                          }
                        />
                      </ToolbarItem>
                    </Toolbar>
                    <div style={{ height: 10 }} />
                    <List
                      ref={this.dxList_personas_REF}
                      dataSource={this.datasource_persona}
                      disabled={is_dxList_persona_disabled}
                      activeStateEnabled={false}
                      selectionMode="single"
                      selectedItemKeys={idPersonaSel}
                      displayExpr="nombreCompuesto"
                      keyExpr="idPersona"
                      noDataText=""
                      searchEnabled={true}
                      focusStateEnabled={false}
                      pageLoadMode="scrollBottom"
                      onOptionChanged={this.dxList_personas_onOptionChanged}
                    />
                  </ItemBox>
                </Box>
              </ItemBox>
              <ItemBox baseSize={30} />
              <ItemBox ratio={3}>
                <div className="d-flex flex-column containerResumenCalendar he-100">
                  <div className="mb-1">
                    <ResumenEstados data={resumenEstados_data} />
                  </div>
                  <div className="calendarContainer he-100">
                    <YearCalendar
                      data={calendarData}
                      yearCalendar_newData={yearCalendar_newData}
                      tipoCalendario="calendarioLaboral"
                      enableRangeSelection={true}
                      listEstados_selectMode="single"
                      estados={datasource_tblCalendarioEstado}
                      soloMostrar_idEstados_seleccion={[
                        0, 2, 5, 6, 7, 9, 11, 12, 13,
                      ]}
                      readOnly_idEstados_seleccion={[3, 4]}
                      currentYear={yearCalendar_currentYear}
                      yearCalendar_onDateChanged={
                        this.yearCalendar_onDateChanged
                      }
                      yearCalendar_onYearChanged={
                        this.yearCalendar_onYearChanged
                      }
                      yearCalendar_onEstadoDeleting={
                        this.yearCalendar_onEstadoDeleting
                      }
                      isDisabled={idPersonaSel.length === 0}
                      disabledDays={disabledDays}
                      allowDelete={true}
                    />
                  </div>
                </div>
              </ItemBox>
            </Box>
          </div>
        </div>
      </Fragment>
    );
  }

  dxTabBoxButton_options = {
    height: "100%",
    icon: "spindown",
    type: "default",
    onClick: () => {
      this.dxTagBox_idsCategoria.option("opened")
        ? this.dxTagBox_idsCategoria.close()
        : this.dxTagBox_idsCategoria.open();
    },
  };

  dxSelectBox_activo_onValueChanged(e) {
    this.setState({ isPersonaActivo: e.value });
    this.datasource_persona.pageIndex(0);
    this.datasource_persona.reload();
  }

  dxLookup_idCentroLav_onValueChanged(e) {
    let { user } = this.props;
    let selectedItem = e.component.option("selectedItem");
    let idPaisSel = selectedItem ? selectedItem.idPais : -1; //idLavnaderia siempre tiene idPais (o tiene pais o es oficina)

    let isOficina_checked = false;
    if (selectedItem) {
      let parsed = JSON.parse(selectedItem.idCentroLav);
      let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;
      isOficina_checked = idLavanderia == null;
    }

    this.setState({
      idCentroLavSel: e.value,
      idPaisSel: selectedItem == null ? user.idPais : idPaisSel,
      isOficina_checked: isOficina_checked,
    });
    this.datasource_persona.pageIndex(0);
    this.datasource_persona.reload();
  }

  getDatos = (idPersona) => {
    let { yearCalendar_currentYear } = this.state;
    let _this = this;

    const promises = [];

    promises.push(this.datasource_tblPersonaNTipoContrato.load());
    promises.push(
      this.context_calendarioLaboral.invoke(
        "fn_spSelectCalendarioLaboral",
        {
          idPersona: idPersona,
          año: yearCalendar_currentYear,
        },
        "GET",
      ),
    );
    promises.push(this.datasource_cuadrantePersonal.load());

    Promise.all(promises).then(([tipoContrato, result, diasFijados]) => {
      if (idPersona.length > 0) {
        let enabledRange = $.map(tipoContrato, function (item) {
          return {
            fechaDesde: item.fechaAltaContrato,
            fechaHasta: item.fechaBajaContrato,
          };
        });

        var enabledDays = [];
        $.each(enabledRange, function (index, item) {
          var startDate = new Date(new Date(item.fechaDesde).setHours(0));
          var endDate =
            item.fechaHasta == null
              ? new Date(yearCalendar_currentYear, 11, 31, 23, 59)
              : new Date(new Date(item.fechaHasta).setHours(0));

          for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
            enabledDays.push(
              new Date(
                new Date(
                  i.getFullYear() +
                    "-" +
                    (i.getMonth() + 1) +
                    "-" +
                    i.getDate(),
                ).setHours(0),
              ),
            );
          }
        });

        var date = startOfYear(new Date(yearCalendar_currentYear, 0, 1));
        var end = endOfYear(new Date(yearCalendar_currentYear, 0, 1));
        var diasDisabled = [];

        while (date <= end) {
          let is_SameDay =
            $.grep(enabledDays, function (item) {
              return isSameDay(item, date);
            }).length > 0;
          if (!is_SameDay) {
            diasDisabled.push(new Date(new Date(date).setHours(0)));
          }
          date.setDate(date.getDate() + 1);
        }

        result = result.filter(
          (x) =>
            (x.idEstado != 8 && x.idEstado != 10) ||
            ((x.idEstado == 8 || x.idEstado == 10) &&
              enabledDays.filter((d) => isSameDay(d, x.fecha)).length > 0),
        );

        let ultDiaFijado;
        if (diasFijados.length > 0) {
          ultDiaFijado = new Date(diasFijados[0].fecha.split("T")[0]).setHours(
            0,
            0,
            0,
            0,
          );
        }

        _this.setState(
          {
            disabledDays: diasDisabled,
            calendarData: result,
            tblPersonaNTipoContratoItems: tipoContrato,
            isFirstLoad: false,
            ultDiaFijado: ultDiaFijado,
          },
          () => {
            _this.setState({ is_dxList_persona_disabled: false });
          },
        );

        _this.dxLoadPanel_calendario.option("visible", false);
        _this.loadPanel_hide();
      }
    });
  };

  dxList_personas_onOptionChanged(e) {
    if (e.name === "selectedItemKeys") {
      if (e.value.length > 0) {
        let { yearCalendar_currentYear, isFirstLoad } = this.state;
        let _this = this;

        this.setState(
          { idPersonaSel: e.value, is_dxList_persona_disabled: false },
          () => {
            if (!isFirstLoad)
              this.dxLoadPanel_calendario.option("visible", true);
            _this.getDatos(e.value);
            _this.setState({
              idPersonaSel: e.value,
              yearCalendar_newData: [],
            });
          },
        );
      } else {
        this.setState({ is_dxList_persona_disabled: false });
        let { yearCalendar_currentYear } = this.state;
        var date = startOfYear(new Date(yearCalendar_currentYear, 0, 1));
        var end = endOfYear(new Date(yearCalendar_currentYear, 0, 1));
        var diasDisabled = [];

        while (date <= end) {
          diasDisabled.push(new Date(new Date(date).setHours(0)));
          date.setDate(date.getDate() + 1);
        }

        this.setState(
          {
            calendarData: [],
            idPersonaSel: [],
            yearCalendar_newData: [],
            disabledDays: diasDisabled,
            tblPersonaNTipoContratoItems: [],
          },
          () => {
            this.setState({ is_dxList_persona_disabled: false });
          },
        );
      }
    } else if (e.name === "searchValue") {
      this.setState({ dxList_seachValue: e.value });
    }
  }

  dxLookup_onContenReady(e) {
    let popup = e.component._popup;
    popup.option("showTitle", false);
    popup.content().css("top", 0);
  }

  dxLookup_onOpened(e) {
    this.setState({ isOpened_dropDownBox_filtroCategoriasInternas: false });
  }

  dxLookup_onClosed() {
    this.setState({ isOpened_dropDownBox_filtroCategoriasInternas: null });
  }

  yearCalendar_onDateChanged(data, newData) {
    let { ultDiaFijado, calendarData, datasource_tblCalendarioEstado } =
      this.state;
    const { user } = this.props;

    let yearCalendar_newData = [...this.state.yearCalendar_newData];
    let _this = this;

    let hasAbsentismo = false;
    let isSinEstado = newData[0].idEstado === 0;

    let diasAfectados = newData.filter((x) => {
      if (
        user.enableDatosRRHH &&
        calendarData.filter(
          (c) =>
            c.fecha.setHours(0, 0, 0, 0) === x.fecha.setHours(0, 0, 0, 0) &&
            c.idEstado === 5,
        ).length > 0
      ) {
        hasAbsentismo = ultDiaFijado >= x.fecha.getTime();
        return x.idEstado === 0 && ultDiaFijado >= x.fecha.getTime();
      }
      return ultDiaFijado >= x.fecha.getTime();
    });

    if (diasAfectados.length === 0) {
      if (hasAbsentismo) {
        dxMensajePregunta(
          getTrad("aviso_cambiarEstadoCalendario").replace(
            "{0}",
            getTrad(
              datasource_tblCalendarioEstado.find(
                (x) => x.idEstado === newData[0].idEstado,
              ).traduccion,
            ),
          ),
          [
            [
              getTrad("guardar"),
              () => {
                $.each(newData, function (index, item) {
                  let exist = $.grep(yearCalendar_newData, function (item2) {
                    return isSameDay(item.fecha, item2.fecha);
                  });
                  if (exist.length > 0) {
                    let objIndex = yearCalendar_newData.findIndex((obj) =>
                      isSameDay(obj.fecha, item.fecha),
                    );
                    yearCalendar_newData[objIndex].idEstado = item.idEstado;
                  } else {
                    yearCalendar_newData.push(item);
                  }
                });

                _this.setState({
                  calendarData: data,
                  yearCalendar_newData: yearCalendar_newData,
                });

                this.context_calendarioPersonal
                  .invoke("fn_IU_tblCalendarioPersonal", {}, "POST")
                  .done(function () {
                    _this.getDatos(_this.state.idPersonaSel);
                    _this.loadPanel_hide();
                  });
              },
              "default",
            ],
            [getTrad("cancelar"), () => {}],
          ],
        );
      } else {
        $.each(newData, function (index, item) {
          let exist = $.grep(yearCalendar_newData, function (item2) {
            return isSameDay(item.fecha, item2.fecha);
          });
          if (exist.length > 0) {
            let objIndex = yearCalendar_newData.findIndex((obj) =>
              isSameDay(obj.fecha, item.fecha),
            );
            yearCalendar_newData[objIndex].idEstado = item.idEstado;
          } else {
            yearCalendar_newData.push(item);
          }
        });

        _this.setState({
          calendarData: data,
          yearCalendar_newData: yearCalendar_newData,
        });

        this.context_calendarioPersonal
          .invoke("fn_IU_tblCalendarioPersonal", {}, "POST")
          .done(function () {
            _this.getDatos(_this.state.idPersonaSel);
            _this.loadPanel_hide();
          });
      }
    } else {
      if (isSinEstado) {
        notify({
          message: `No se puede asignar "Sin estado" a un evento con cuadrante fijado`,
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
      } else {
        const format = { day: "2-digit", month: "2-digit", year: "numeric" };

        notify({
          message: `Se ha encontrado un día fijado posterior: ${new Date(ultDiaFijado).toLocaleString(undefined, format)}`,
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
      }
    }
  }

  yearCalendar_onYearChanged(data) {
    let _this = this;
    let {
      idPersonaSel,
      yearCalendar_currentYear,
      tblPersonaNTipoContratoItems,
    } = this.state;
    if (data.currentYear !== yearCalendar_currentYear) {
      if (idPersonaSel.length > 0) {
        _this.dxLoadPanel_calendario.option("visible", true);

        const promises = [];
        promises.push(
          this.context_calendarioLaboral.invoke(
            "fn_spSelectCalendarioLaboral",
            {
              idPersona: idPersonaSel[0],
              año: data.currentYear,
            },
            "GET",
          ),
        );

        Promise.all(promises).then(([result]) => {
          let enabledRange = $.map(
            tblPersonaNTipoContratoItems,
            function (item) {
              return {
                fechaDesde: item.fechaAltaContrato,
                fechaHasta: item.fechaBajaContrato,
              };
            },
          );

          var enabledDays = [];
          $.each(enabledRange, function (index, item) {
            var startDate = new Date(new Date(item.fechaDesde).setHours(0));
            var endDate =
              item.fechaHasta == null
                ? new Date(data.currentYear, 11, 31, 23, 59)
                : new Date(new Date(item.fechaHasta).setHours(0));

            for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
              enabledDays.push(
                new Date(
                  new Date(
                    i.getFullYear() +
                      "-" +
                      (i.getMonth() + 1) +
                      "-" +
                      i.getDate(),
                  ).setHours(0),
                ),
              );
            }
          });

          var date = startOfYear(new Date(data.currentYear, 0, 1));
          var end = endOfYear(new Date(data.currentYear, 0, 1));
          var diasDisabled = [];

          while (date <= end) {
            let is_SameDay =
              $.grep(enabledDays, function (item) {
                return isSameDay(item, date);
              }).length > 0;
            if (!is_SameDay) {
              diasDisabled.push(new Date(new Date(date).setHours(0)));
            }
            date.setDate(date.getDate() + 1);
          }

          result = result.filter(
            (x) =>
              (x.idEstado != 8 && x.idEstado != 10) ||
              ((x.idEstado == 8 || x.idEstado == 10) &&
                enabledDays.filter((d) => isSameDay(d, x.fecha)).length > 0),
          );

          _this.setState(
            {
              calendarData: result,
              yearCalendar_currentYear: data.currentYear,
              disabledDays: diasDisabled,
            },
            () => {
              setTimeout(() => {
                _this.setState({ is_dxList_persona_disabled: false });
              }, 0);
            },
          );
          _this.dxLoadPanel_calendario.option("visible", false);
        });
      }
    }
  }

  yearCalendar_onEstadoDeleting(data, fechaSel) {
    let _this = this;
    let {
      idPersonaSel,
      yearCalendar_currentYear,
      tblPersonaNTipoContratoItems,
    } = this.state;
    this.state.fechaSel = fechaSel;

    let dataSource =
      data.idEstado == 3 || data.idEstado == 4
        ? this.datasource_tblJornadaPersona
        : this.datasource_tblCalendarioPersonal;
    dataSource
      .store()
      .remove({})
      .done(function () {
        _this.context_calendarioLaboral
          .invoke(
            "fn_spSelectCalendarioLaboral",
            {
              idPersona: idPersonaSel[0],
              año: yearCalendar_currentYear,
            },
            "GET",
          )
          .done(function (result) {
            let enabledRange = $.map(
              tblPersonaNTipoContratoItems,
              function (item) {
                return {
                  fechaDesde: item.fechaAltaContrato,
                  fechaHasta: item.fechaBajaContrato,
                };
              },
            );

            var enabledDays = [];
            $.each(enabledRange, function (index, item) {
              var startDate = new Date(new Date(item.fechaDesde).setHours(0));
              var endDate =
                item.fechaHasta == null
                  ? new Date(data.currentYear, 11, 31, 23, 59)
                  : new Date(new Date(item.fechaHasta).setHours(0));

              for (
                var i = startDate;
                i <= endDate;
                i.setDate(i.getDate() + 1)
              ) {
                enabledDays.push(
                  new Date(
                    new Date(
                      i.getFullYear() +
                        "-" +
                        (i.getMonth() + 1) +
                        "-" +
                        i.getDate(),
                    ).setHours(0),
                  ),
                );
              }
            });

            var date = startOfYear(new Date(data.currentYear, 0, 1));
            var end = endOfYear(new Date(data.currentYear, 0, 1));
            var diasDisabled = [];

            while (date <= end) {
              let is_SameDay =
                $.grep(enabledDays, function (item) {
                  return isSameDay(item, date);
                }).length > 0;
              if (!is_SameDay) {
                diasDisabled.push(new Date(new Date(date).setHours(0)));
              }
              date.setDate(date.getDate() + 1);
            }

            _this.setState(
              {
                calendarData: result,
              },
              () => {
                setTimeout(() => {
                  _this.setState({ is_dxList_persona_disabled: false });
                }, 0);
              },
            );
          });
      });
  }

  DropDownBox_filtroCategoriasInternas_onValueChanged(e) {
    this.setState({ idCategoriasSel: e }, () => {
      this.datasource_persona.pageIndex(0);
      this.datasource_persona.reload();
    });
  }

  setResumenEstadosData() {
    let _this = this;
    let { calendarData, datasource_tblCalendarioEstado } = this.state;
    let data = [];

    calendarData.reduce(function (res, value) {
      if (!res[value.idEstado]) {
        res[value.idEstado] = { idEstado: value.idEstado, cantidad: 0 };
        data.push(res[value.idEstado]);
      }
      res[value.idEstado].cantidad += 1;
      return res;
    }, {});

    datasource_tblCalendarioEstado.forEach(function (estado) {
      let exists =
        data.findIndex((element) => element.idEstado === estado.idEstado) > -1;
      if (!exists) {
        data.push({
          idEstado: estado.idEstado,
          denominacion: _this.getTrad(estado.traduccion),
          cantidad: 0,
          colorHexa: estado.colorHexa,
        });
      }
    });

    data = data
      .filter((x) => x.idEstado != 0)
      .map(function (element) {
        let findEstado = datasource_tblCalendarioEstado.find(
          (el) => el.idEstado == element.idEstado,
        );

        return {
          idEstado: element.idEstado,
          denominacion:
            findEstado != null ? _this.getTrad(findEstado.traduccion) : "",
          cantidad: element.cantidad,
          colorHexa: findEstado != null ? findEstado.colorHexa : null,
        };
      })
      .sort((a, b) =>
        a.denominacion > b.denominacion
          ? 1
          : b.denominacion > a.denominacion
            ? -1
            : 0,
      );

    this.setState({ resumenEstados_data: data });
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    this.cargaDatos_inicio();
  }

  componentDidUpdate(prevProps, prevState) {
    let { idioma } = this.props;
    let { calendarData } = this.state;

    let _this = this;
    if (idioma.idIdioma !== prevProps.idioma.idIdioma) {
      this.datasource_datos_tblCalendarioPersonal_Estado
        .reload()
        .done(function (estados) {
          _this.setState({ datasource_tblCalendarioEstado: estados });
          _this.setResumenEstadosData();
        });
    }

    // Formatear data para componente resumen estados calendario
    if (calendarData != prevState.calendarData) {
      this.setResumenEstadosData();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CalendarioLaboral_RRHH);
