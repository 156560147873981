import React from "react";

import { connectionConstants } from "constants";
import { authHeader, errorHandler, formatDate_noTime_parameter, getTrad } from "helpers";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Box, Button, DataGrid, DateBox, Popup } from "devextreme-react";
import { Item } from "devextreme-react/box";
import { Column, Lookup, Selection, Sorting } from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/popup";

class FechaAntiguedadPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fechaAntiguedad: null,
            tblPersonaNTipoContrato: [],
            tblMotivoBaja: [],
            tblTipoContrato: [],
            selectedRowKeys: [],
        };
    }

    // #region Lifecycle

    componentDidMount() {
        const { visible, idPersona, tblPersonaNTipoContrato, fechaAntiguedad } = this.props;
        if (visible && idPersona) {
            if (this.props.tblPersonaNTipoContrato) {
                let selectedRowKeys = this.getSelectedRowKeys(tblPersonaNTipoContrato, fechaAntiguedad);

                this.setState({
                    tblPersonaNTipoContrato,
                    fechaAntiguedad,
                    selectedRowKeys,
                });
            } else {
                this.datasource_tblPersona.reload();
            }
        }

        Promise.all([this.datasource_tblMotivoBaja.load(), this.datasource_tblTipoContrato.load()]).then(
            ([tblMotivoBaja, tblTipoContrato]) => {
                this.setState({ tblMotivoBaja, tblTipoContrato });
            }
        );
    }

    componentDidUpdate(prevProps) {
        const { visible, tblPersonaNTipoContrato, fechaAntiguedad } = this.props;
        if (visible && !prevProps.visible) {
            if (tblPersonaNTipoContrato) {
                let selectedRowKeys = this.getSelectedRowKeys(tblPersonaNTipoContrato, fechaAntiguedad);

                this.setState({
                    tblPersonaNTipoContrato,
                    fechaAntiguedad,
                    selectedRowKeys,
                });
            } else {
                this.datasource_tblPersona.reload();
            }
        }
    }

    getSelectedRowKeys = (tblPersonaNTipoContrato, fechaAntiguedad) => {
        return tblPersonaNTipoContrato.filter(
            (x) =>
                x.fechaAltaContrato ===
                (fechaAntiguedad instanceof Date
                    ? formatDate_noTime_parameter(fechaAntiguedad)
                    : fechaAntiguedad?.split("T")[0])
        );
    };

    // #endregion

    // #region DataSource

    datasource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblPersona_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblPersona_onLoading(loadOptions),
            onLoaded: (result) => this.datasource_tblPersona_onLoaded(result),
            version: 4,
        }),
        select: ["idPersona"],
        expand: ["tblDatosSalariales($select=fechaAntiguedad)", "tblPersonaNTipoContrato"],
    });

    datasource_tblPersona_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblPersona_onLoading = (loadOptions) => {
        const { idPersona } = this.props;
        loadOptions.filter = ["idPersona", "=", idPersona ?? null];
    };

    datasource_tblPersona_onLoaded = (result) => {
        const { tblDatosSalariales, tblPersonaNTipoContrato } = result[0];
        const { fechaAntiguedad } = tblDatosSalariales;

        let selectedRowKeys = this.getSelectedRowKeys(tblPersonaNTipoContrato, fechaAntiguedad);

        this.setState({ fechaAntiguedad, tblPersonaNTipoContrato, selectedRowKeys });
    };

    datasource_tblMotivoBaja = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMotivoBaja",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblMotivoBaja_beforeSend(request),
            version: 4,
        }),
        select: ["idMotivoBaja", "denominacion"],
    });

    datasource_tblMotivoBaja_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoContrato",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblTipoContrato_beforeSend(request),
            version: 4,
        }),
        select: ["idTipoContrato", "denominacion"],
    });

    datasource_tblTipoContrato_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    // #endregion

    render() {
        const { visible, onHiding, nombreCompleto } = this.props;
        const { fechaAntiguedad, tblPersonaNTipoContrato, tblMotivoBaja, tblTipoContrato, selectedRowKeys } =
            this.state;
        return (
            <Popup
                title={`${getTrad("seleccionarFechaAntiguedad")} ${
                    nombreCompleto != null ? " - " + nombreCompleto : ""
                }`}
                visible={visible}
                onHiding={onHiding}
                onHidden={this.onHidden}
                width={600}
                height={400}
                deferRendering={false}
                dragEnabled={false}
            >
                <div className="he-100">
                    <Box direction="col" className="he-100">
                        <Item baseSize={"auto"}>
                            <div className="d-flex flex-row justify-content-center align-items-center">
                                <span className="mr-2 font-weight-semiBold text-secondary">
                                    {getTrad("fechaAntiguedadActual")}
                                </span>
                                <DateBox
                                    className="ml-2"
                                    value={fechaAntiguedad}
                                    readOnly={true}
                                    stylingMode="filled"
                                    displayFormat={"dd/MM/yyyy"}
                                />
                            </div>
                        </Item>
                        <Item baseSize={20} />
                        <Item ratio={1}>
                            <DataGrid
                                dataSource={tblPersonaNTipoContrato}
                                className="he-100 shadow-sm"
                                showColumnLines={false}
                                showRowLines
                                hoverStateEnabled
                                selectedRowKeys={selectedRowKeys}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                <Sorting mode={"none"} />
                                <Selection mode={"single"} />
                                <Column
                                    dataField="fechaAltaContrato"
                                    caption={getTrad("fechaAlta")}
                                    dataType="date"
                                    alignment="center"
                                    format="dd/MM/yyyy"
                                    width={142}
                                    sortOrder="desc"
                                    sortIndex={1}
                                />
                                <Column
                                    dataField="fechaBajaContrato"
                                    caption={getTrad("fechaBaja")}
                                    dataType="date"
                                    alignment="center"
                                    format="dd/MM/yyyy"
                                    width={150}
                                />
                                <Column
                                    dataField="idMotivoBaja"
                                    caption={getTrad("motivoBaja")}
                                    alignment="center"
                                    allowSearch={false}
                                    visible={true}
                                    width={142}
                                >
                                    <Lookup
                                        dataSource={tblMotivoBaja}
                                        valueExpr="idMotivoBaja"
                                        displayExpr="denominacion"
                                    />
                                </Column>
                                <Column
                                    dataField="idTipoContrato"
                                    caption={getTrad("tipoContrato")}
                                    alignment="center"
                                    allowSearch={false}
                                    visible={true}
                                >
                                    <Lookup
                                        dataSource={tblTipoContrato}
                                        valueExpr="idTipoContrato"
                                        displayExpr="denominacion"
                                    />
                                </Column>
                            </DataGrid>
                        </Item>
                    </Box>
                </div>
                <ToolbarItem toolbar={"bottom"} location={"after"}>
                    <Button
                        text={getTrad("guardar")}
                        type={"success"}
                        onClick={this.onGuardar}
                        disabled={selectedRowKeys.length === 0 ? true : undefined}
                    />
                </ToolbarItem>
            </Popup>
        );
    }

    onHidden = () => {
        this.setState({ fechaAntiguedad: null, tblPersonaNTipoContrato: [] });
    };

    onSelectionChanged = (e) => {
        if (e.selectedRowKeys.length > 0) {
            this.setState({ selectedRowKeys: e.selectedRowKeys });
        }
    };

    onGuardar = (e) => {
        const { selectedRowKeys } = this.state;
        const { onGuardar } = this.props;
        if (selectedRowKeys.length > 0) {
            onGuardar({ ...e, value: selectedRowKeys[0]?.fechaAltaContrato });
        }
    };
}

export default FechaAntiguedadPopup;
