import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { globalActions, loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import {
  authHeader,
  errorHandler,
  getNombreFormulario,
  getTrad,
} from "../../../helpers";

//Js
import cargaJs, { onSaveDireccion } from "./Js.js";

//Css
import "./Css.scss";

// Mapa
import LocationMapMarker from "../../../components/LocationMapMarker";

import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "../../../constants";
import notify from "devextreme/ui/notify";

class Lavanderia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible_dxPopup_mapa: false,
      addressNoMod: null,
      address: undefined,
      coordinates: undefined,
      address_components: undefined,
    };

    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  setLavanderia(lav) {
    this.props.setLavanderia(lav);
  }

  render() {
    const { isVisible_dxPopup_mapa, address, coordinates, addressNoMod } =
      this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="Lavanderia" className={"formContainer"}>
            <div id="dxContainer">
              <div data-options="dxItem: {ratio: 1}">
                <div id="dxForm_lavanderia"></div>
              </div>
            </div>
          </div>
          <Popup
            width={"80%"}
            height={"85%"}
            title={getTrad("seleccionarUbicacion")}
            visible={isVisible_dxPopup_mapa}
            onHiding={this.onHiding_dxPopup_mapa}
          >
            <div className="w-100 h-100">
              <LocationMapMarker
                isVisible={isVisible_dxPopup_mapa}
                addressNoMod={addressNoMod}
                address={address}
                coordinates={coordinates}
                onChange={this.onChange_map}
              />
            </div>
            <Button
              type="success"
              style={this.buttonAceptarStyle_map}
              text={getTrad("aceptar")}
              onClick={this.onClick_saveDireccion}
            />
          </Popup>
        </div>
      </Fragment>
    );
  }

  // Ubicación | Mapa

  buttonAceptarStyle_map = {
    position: "absolute",
    right: 30,
    bottom: 50,
    zIndex: 1,
  };

  datasource_tblPais = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  onClick_useMap = (itemsLavanderiaSel) => {
    let state = {};
    let coordinates = undefined;

    if (itemsLavanderiaSel.coordenadas) {
      coordinates = itemsLavanderiaSel.coordenadas.split(",");
      coordinates = {
        lat: parseFloat(coordinates[0]),
        lng: parseFloat(coordinates[1]),
      };
      state = { coordinates };
    }

    const address = itemsLavanderiaSel.direccion;

    if (address && address.length > 0) {
      state = { ...state, address, addressNoMod: address };
    }

    this.setState({ isVisible_dxPopup_mapa: true, ...state }, () => {
      const autoCompletes = document.getElementsByClassName(
        "pac-container pac-logo",
      );

      for (let i = 0; i < autoCompletes.length - 1; i++) {
        autoCompletes[i].remove();
      }

      const autoComplete = autoCompletes[autoCompletes.length - 1];

      if (autoComplete && autoComplete.style.zIndex != 2000) {
        autoComplete.style.zIndex = 2000;
      }
    });
  };

  onHiding_dxPopup_mapa = () => {
    this.setState({ isVisible_dxPopup_mapa: false });
  };

  onChange_map = (onChangeObj) => {
    this.setState({ ...onChangeObj });
  };

  onClick_saveDireccion = () => {
    const { address_components, address, coordinates, addressNoMod } =
      this.state;

    if (address === addressNoMod) {
      notify({
        message: getTrad("ubicacionSinModificacion"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }
    if (!coordinates || !coordinates.lat || !coordinates.lng) {
      notify({
        message: getTrad("sinUbicacionSel"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }
    if (!address_components && address && address.length > 0) {
      notify({
        message: getTrad("error_sinConfirmarUbicacion"),
        type: "error",
        displayTime: "4500",
        closeOnClick: true,
      });
      return;
    }

    const update = {
      direccion: "",
      pais: "",
      codigoPostal: "",
      poblacion: "",
      coordenadas: `${coordinates.lat}, ${coordinates.lng}`,
    };

    let numeroCalle = "";
    let hasRoute = false;

    address_components?.forEach(({ types, long_name }) => {
      const key = {
        street_number: "numeroCalle",
        locality: "poblacion",
        route: "direccion",
        country: "pais",
        postal_code: "codigoPostal",
      }[types?.[0]];

      if (key) {
        if (key === "numeroCalle") {
          numeroCalle = long_name;
        } else {
          update[key] = long_name;
          if (key === "direccion") {
            hasRoute = true;
          }
        }
      }
    });

    if (hasRoute && numeroCalle.length > 0) {
      update.direccion += `, ${numeroCalle}`;
    } else if (!hasRoute) {
      update.direccion = address || update.direccion;
    }

    onSaveDireccion(update);
    this.onHiding_dxPopup_mapa();
  };

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, user, idioma } = this.props;

    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, user, idioma, this.onClick_useMap).then(() =>
        this.loadPanel_hide(),
      );
    }
  }

  componentDidMount() {
    let { lavanderia, user, idioma } = this.props;
    this.datasource_tblPais.load();
    cargaJs(this, lavanderia, user, idioma, this.onClick_useMap).then(() =>
      this.loadPanel_hide(),
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  setLavanderia: (lavanderia) =>
    dispatch(globalActions.setLavanderia(lavanderia)),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lavanderia);
