import React, { Fragment } from "react";
import { connect } from "react-redux";

import $ from "jquery";

import Box, { Item as ItemBox } from "devextreme-react/box";

import GridPersonaNArea from "./GridPersonaNArea";

const default_filter = { idPersona: null };

class ViewPersonaNArea extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filtro_1: {},
      filtro_2: {},
      nombreCompleto: "",
    };
  }

  render() {
    const { fechaIni, fechaFin, group, lavanderia } = this.props;
    const { filtro_1, filtro_2, nombreCompleto } = this.state;

    let globalFilter = {
      ...default_filter,
      idLavanderia: lavanderia.idLavanderia,
      fechaIni,
      fechaFin,
    };
    let filtros_grid2 = { ...globalFilter },
      filtros_grid3 = { ...globalFilter };
    let key_1 = null,
      key_2 = null;

    if (!$.isEmptyObject(filtro_1)) {
      filtros_grid2 = { ...filtros_grid2, ...filtro_1 };
      let objKeys = Object.keys(filtro_1)[0];
      key_1 = objKeys + "_" + filtro_1[objKeys];
    } else {
      filtros_grid2.idLavanderia = -1;
    }

    if (!$.isEmptyObject(filtro_2)) {
      filtros_grid3 = { ...filtros_grid3, ...filtro_1, ...filtro_2 };
      let objKeys = Object.keys(filtro_2)[0];
      key_2 = objKeys + "_" + filtro_2[objKeys];
    } else {
      filtros_grid3.idLavanderia = -1;
    }

    return (
      <Fragment>
        <Box direction="row" width="auto" height="100%">
          <ItemBox ratio={6}>
            <GridPersonaNArea
              title={group.denominacion}
              group={group.key}
              filtros={globalFilter}
              selectedRowKey={key_1}
              onSelectionChanged={this.onSelectionChanged_1}
            />
          </ItemBox>
          <ItemBox baseSize={20} />
          <ItemBox ratio={4}>
            <GridPersonaNArea
              title={
                nombreCompleto
                  ? group.subGroup.denominacion + " - " + nombreCompleto
                  : group.subGroup.denominacion
              }
              group={group.subGroup.key}
              filtros={filtros_grid2}
              selectedRowKey={key_2}
              onSelectionChanged={this.onSelectionChanged_2}
            />
          </ItemBox>
        </Box>
      </Fragment>
    );
  }

  dxButtonGroup_buttonRender = (data) => {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        <i
          style={{ flexBasis: 30, color: "red" }}
          className={"font-size-xxl w-100 " + data.icon}
        ></i>
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  };

  dxButtonGroup_onItemClick = (e) => {
    this.setState({ group: e.itemData, filtro_1: {}, filtro_2: {} });
  };

  dateRangePicker_onDateSelected = (fecha) => {
    if (fecha) {
      this.setState({ fechaIni: fecha.startDate, fechaFin: fecha.endDate });
    }
  };

  onSelectionChanged_1 = (changes, nombreCompleto) => {
    const { filtro_1 } = this.state;
    if (JSON.stringify(changes) !== JSON.stringify(filtro_1)) {
      this.setState({ filtro_1: changes, filtro_2: {}, nombreCompleto });
    }
  };

  onSelectionChanged_2 = (changes) => {
    const { filtro_2 } = this.state;
    if (JSON.stringify(changes) !== JSON.stringify(filtro_2)) {
      this.setState({ filtro_2: changes });
    }
  };

  hiding_popup = () => {
    this.setState({ filtro_2: {} });
  };

  componentDidUpdate(prevProps) {
    const { filtro_1 } = this.props;

    let newState = {};

    if (JSON.stringify(prevProps.filtro_1) !== JSON.stringify(filtro_1)) {
      if (filtro_1) {
        newState.filtro_1 = filtro_1;
      } else {
        newState.filtro_1 = {};
      }
    }

    if (!$.isEmptyObject(newState)) {
      this.setState(newState);
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(ViewPersonaNArea);
