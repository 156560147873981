import React, { PureComponent } from "react";
import $ from "jquery";
import Toolbar, { Item as ItemToolbar } from "devextreme-react/toolbar";
import { DateBox, DropDownOptions } from "devextreme-react/date-box";
import { capitalize, formatDate } from "../helpers";
import classnames from "classnames";
import { format, parse, isValid, isEqual } from "date-fns";
import { Calendar, DateRange } from "react-date-range";

class DateInput extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      invalid: false,
      changed: false,
      value: this.formatDate(props),
    };

    this.changeInput = this.changeInput.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { value, inputValue, tipo } = prevProps;
    if (value && !isEqual(value, this.props.value)) {
      this.setState({ value: this.formatDate(this.props) });
    }

    if (inputValue && !isEqual(inputValue, this.props.inputValue)) {
      let value = inputValue
        ? formatDate(
            tipo === "fechaInicio" ? inputValue.startDate : inputValue.endDate,
          )
        : "";
      this.changeInput(value);
    }
  }

  formatDate({ value, dateDisplayFormat, dateOptions }) {
    if (value && isValid(value)) {
      return format(value, dateDisplayFormat, dateOptions);
    }
    return "";
  }

  update(value) {
    const { invalid, changed } = this.state;

    if (invalid || !changed || !value) {
      return;
    }

    const { onChange, dateDisplayFormat, dateOptions } = this.props;
    const parsed = parse(value, dateDisplayFormat, new Date(), dateOptions);
    if (isValid(parsed)) {
      this.setState({ changed: false }, () => onChange(parsed));
    } else {
      this.setState({ invalid: true });
    }
  }

  onKeyDown = (e) => {
    const { value } = this.state;

    if (e.key === "Enter") {
      this.update(value);
    }
  };

  changeInput(value) {
    this.setState({ value: value, changed: true, invalid: false });
  }

  onChange = (e) => {
    this.changeInput(e.target.value);
  };

  onBlur = () => {
    const { value } = this.state;
    this.update(value);
  };

  render() {
    const {
      className,
      readOnly,
      placeholder,
      ariaLabel,
      disabled,
      onFocus,
      tipo,
      resolucion,
    } = this.props;
    const { value, invalid } = this.state;

    return (
      <span className={classnames("rdrDateInput", className)}>
        <div className="input-group">
          <label htmlFor={"input_" + tipo} className="labelImg">
            <i className="dx-icon-event" />
          </label>
          {resolucion === "xs" || resolucion === "sm" ? (
            <div />
          ) : (
            <label htmlFor={"input_" + tipo} className="labelText">
              {tipo === "fechaInicio" ? "Fecha Inicio:." : "Fecha Fin:."}{" "}
            </label>
          )}
          <input
            id={"input_" + tipo}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            aria-label={ariaLabel}
            maxLength="10"
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            onBlur={this.onBlur}
            onFocus={onFocus}
          />
        </div>
        {invalid && <span className="rdrWarning icon_Warning"></span>}
      </span>
    );
  }
}

class Calendar_ extends Calendar {
  constructor(props) {
    super(props);
  }

  renderMonthAndYear = (focusedDate, changeShownDate, props) => {
    const { showMonthArrow, resolucion } = props;

    return (
      <Toolbar elementAttr={this.dxToolbar_elementAttr}>
        {showMonthArrow ? (
          <ItemToolbar
            location="before"
            widget="dxButton"
            options={this.backButtonOptions}
          />
        ) : null}
        <ItemToolbar location="center">
          <DateBox
            pickerType="calendar"
            type={"date"}
            elementAttr={this.dxDateBox_dateRangePicker_elementAttr}
            min={this.props.minDate}
            max={this.props.maxDate}
            calendarOptions={this.calendarOptions}
            width={170}
            displayFormat={"MMMM yyyy"}
            value={this.state.focusedDate}
            onContentReady={this.dxDateBox_onContentReady}
            onValueChanged={(e) => {
              this.changeShownDate(e.value.getMonth(), "setMonth");
              this.changeShownDate(e.value.getFullYear(), "setYear");
            }}
          >
            <DropDownOptions
              width={350}
              position={
                resolucion === "xs"
                  ? { my: "center", at: "center", of: window }
                  : {
                      my: "top left",
                      at: "bottom left",
                      of: "#dxDateBox_dateRangePicker",
                    }
              }
            />
          </DateBox>
        </ItemToolbar>

        {showMonthArrow ? (
          <ItemToolbar
            location="after"
            widget="dxButton"
            options={this.nextButtonOptions}
          />
        ) : null}
      </Toolbar>
    );
  };

  renderDateDisplay = () => {
    const {
      focusedRange,
      color,
      ranges,
      rangeColors,
      dateDisplayFormat,
      editableDateInputs,
      startDatePlaceholder,
      endDatePlaceholder,
      ariaLabels,
      inputValue,
      resolucion,
    } = this.props;

    const defaultColor = rangeColors[focusedRange[0]] || color;
    const styles = this.styles;

    return (
      <div className={styles.dateDisplayWrapper}>
        {ranges.map((range, i) => {
          if (
            range.showDateDisplay === false ||
            (range.disabled && !range.showDateDisplay)
          )
            return null;
          return (
            <div
              className={styles.dateDisplay}
              key={i}
              style={{ color: range.color || defaultColor }}
            >
              <DateInput
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]:
                    focusedRange[0] === i && focusedRange[1] === 0,
                })}
                readOnly={!editableDateInputs}
                disabled={range.disabled}
                value={range.startDate}
                placeholder={startDatePlaceholder}
                dateOptions={this.dateOptions}
                dateDisplayFormat={dateDisplayFormat}
                ariaLabel={
                  ariaLabels.dateInput &&
                  ariaLabels.dateInput[range.key] &&
                  ariaLabels.dateInput[range.key].startDate
                }
                tipo="fechaInicio"
                inputValue={inputValue}
                resolucion={resolucion}
                onChange={this.onDragSelectionEnd}
                onFocus={(e) => this.handleRangeFocusChange(i, 0, e)}
              />
              <DateInput
                className={classnames(styles.dateDisplayItem, {
                  [styles.dateDisplayItemActive]:
                    focusedRange[0] === i && focusedRange[1] === 1,
                })}
                readOnly={!editableDateInputs}
                disabled={range.disabled}
                value={range.endDate}
                placeholder={endDatePlaceholder}
                dateOptions={this.dateOptions}
                dateDisplayFormat={dateDisplayFormat}
                ariaLabel={
                  ariaLabels.dateInput &&
                  ariaLabels.dateInput[range.key] &&
                  ariaLabels.dateInput[range.key].endDate
                }
                tipo="fechaFin"
                inputValue={inputValue}
                resolucion={resolucion}
                onChange={this.onDragSelectionEnd}
                onFocus={(e) => this.handleRangeFocusChange(i, 1, e)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  backButtonOptions = {
    icon: "back",
    onClick: () => this.changeShownDate(-1, "monthOffset"),
  };

  calendarOptions = {
    maxZoomLevel: "year",
    minZoomLevel: "decade",
    cellRender: this.dxCalendar_monthSelector_cellRender,
    onContentReady: this.dxCalendar_monthSelector_onContentReady,
  };

  nextButtonOptions = {
    icon: "chevronnext",
    onClick: () => this.changeShownDate(+1, "monthOffset"),
  };

  dxToolbar_elementAttr = {
    id: "dxToolbar_calendarioPersonalizado",
  };

  dxDateBox_dateRangePicker_elementAttr = {
    id: "dxDateBox_dateRangePicker",
  };

  dxCalendar_monthSelector_onContentReady(e) {
    $(e.element).find(".dx-calendar-cell").css({
      "text-transform": "capitalize",
    });
  }

  dxCalendar_monthSelector_cellRender(e) {
    let { locale } = this.props;
    if (e.view === "decade") return e.date.getFullYear();
    else if (e.view === "year")
      return (
        <span>
          {capitalize(e.date.toLocaleString(locale, { month: "long" }))}
        </span>
      );
  }

  dxDateBox_onContentReady(e) {
    $(e.element).find("input").css({
      cursor: "pointer",
      "caret-color": "transparent",
      "text-transform": "capitalize",
    });

    $(e.element)
      .find("input")
      .on("click", function () {
        e.component.open();
      });

    $(e.element)
      .find(".dx-dropdowneditor-icon")
      .addClass("dxDateBox_calPersonalizado_selectorMeses_icono");
    $(e.element).find(".dx-dropdowneditor-icon:before").css("content", "\f001");
    $(e.element)
      .find(".dx-texteditor-buttons-container")
      .css("cursor", "pointer");
    $(e.element)
      .find(".dx-button.dx-button-normal")
      .css("pointer-events", "none");
    $(e.element)
      .find(".dx-button.dx-button-normal > .dx-button-content")
      .css("padding-top", 6);
  }

  handleRangeFocusChange = (rangesIndex, rangeItemIndex, e) => {
    e.target.select();
    this.props.onRangeFocusChange &&
      this.props.onRangeFocusChange([rangesIndex, rangeItemIndex]);
  };

  componentDidUpdate(prevProps) {
    const propMapper = {
      dateRange: "ranges",
      date: "date",
    };

    let { ranges } = this.props;
    let fecha = ranges[0];
    let startDate_yearMonth =
      !fecha || !fecha.startDate
        ? null
        : new Date(fecha.startDate.getFullYear(), fecha.startDate.getMonth());
    let endDate_yearMonth =
      !fecha || !fecha.startDate
        ? null
        : new Date(fecha.endDate.getFullYear(), fecha.endDate.getMonth());
    let focusedDate_yearMonth = new Date(
      this.state.focusedDate.getFullYear(),
      this.state.focusedDate.getMonth(),
    );

    //Focused date siempre será el 1er calendario,por lo que aunque selecciones un mes, si está en el 2o calendario cogerá la fecha del 1ero, comprobar fecha de ambos calendarios.
    let focusedDate_next_yearMonth = new Date(
      this.state.focusedDate.getFullYear(),
      this.state.focusedDate.getMonth() + 1,
    );

    const targetProp = propMapper[this.props.displayMode];
    if (
      this.props[targetProp] !== prevProps[targetProp] &&
      (!fecha.startDate ||
        (fecha.startDate &&
          focusedDate_yearMonth.getTime() !== startDate_yearMonth.getTime() &&
          focusedDate_next_yearMonth.getTime() !==
            startDate_yearMonth.getTime() &&
          focusedDate_yearMonth.getTime() !== endDate_yearMonth.getTime() &&
          focusedDate_next_yearMonth.getTime() !== endDate_yearMonth.getTime()))
    ) {
      this.updateShownDate(this.props);
    }
  }
}

class DateRange_ extends DateRange {
  render() {
    return (
      <Calendar_
        focusedRange={this.state.focusedRange}
        onRangeFocusChange={this.handleRangeFocusChange}
        preview={this.state.preview}
        onPreviewChange={(value) => {
          this.updatePreview(value ? this.calcNewSelection(value) : null);
        }}
        {...this.props}
        displayMode="dateRange"
        className={classnames(
          this.styles.dateRangeWrapper,
          this.props.className,
        )}
        onChange={this.setSelection}
        updateRange={(val) => this.setSelection(val, false)}
        inputValue={this.props.inputValue}
        resolucion={this.props.resolucion}
        ref={(target) => {
          this.calendar = target;
        }}
      />
    );
  }
}
export { DateRange_, Calendar_ };
