import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import $ from "jquery";

import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    Pager,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import { getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { getClearPedido } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    filterArticulo,
    onCellPrepared_Total,
    retrieveIvaValue,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblProveedor,
    datasource_tblViasPagoCobro,
    datasource_tblAdmPedido_Estado,
    sharedEditorOptions,
    formats,
    datasource_tblAdmTipoElemento,
} from "pages/Administracion/shared/model";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import config_isCerrado from "pages/Administracion/shared/configs/config_isCerrado";
import { impresionActions } from "actions";

const DataGridPedidosProveedor = ({
    datasource,
    setPedidoSel,
    openPopup,
    reloadData,
    idEmpresaPolarier,
    loadPanel_hide,
    setParams,
    setReport,
    close,
}) => {
    const [proveedores, setProveedores] = useState([]);
    const [centrosCoste, setCentroCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);

    useEffect(() => {
        Promise.all([
            datasource_tblProveedor.reload(),
            datasource_tblAdmCentroCoste.reload(),
            datasource_tblAdmElementoPEP.reload(),
        ]).then((data) => {
            setProveedores(datasource_tblProveedor.items());
            setCentroCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    useEffect(() => {
        setReport({
            denominacion: "administracion_pedido_proveedor",
            parameterEvent: customizeParameterEditors,
        });

        return () => {
            close();
        };
    }, []);

    const customizeParameterEditors = (report, s, e) => {
        if (e.parameter.name === "idsAdmPedidoProveedor") {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
    };

    const calculateCellValue = (e) => {
        if (e.tblArticuloNAdmPedidoProveedor.length > 0) {
            let total = 0;
            e.tblArticuloNAdmPedidoProveedor.forEach((articulo) => {
                const { cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 } = articulo;
                let iva = retrieveIvaValue(idIvaNPais, "compra");
                total += cantidad * precio * (1 + iva) * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento } = e;
            return {
                valor: calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "compra"),
                idMoneda: e.idMoneda,
            };
        }
    };

    const cellRender = (e) => {
        return calculateDisplayValue(isNaN(e.value?.valor) ? 0 : e.value?.valor, e.value?.idMoneda);
    };

    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    widget: "dxButton",
                    options: {
                        icon: "plus",
                        text: getTrad("nuevoPedido"),
                        onClick: () => onNewPedido(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            reloadData();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewPedido = () => {
        const pedido = getClearPedido();
        setPedidoSel({ ...pedido });
        openPopup();
    };

    const onRowPrepared_tblPedidos = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onCellClick_tblPedidos = (e) => {
        if (e.column?.name === "impresion") {
            setParams({ idsAdmPedidoProveedor: [e.data.idAdmPedidoProveedor] });
        } else if (e.data) {
            setPedidoSel({ ...e.data, articulosDraft: [...e.data.tblArticuloNAdmPedidoProveedor] });
            openPopup();
        }
    };

    const cellRender_impresion = () => {
        const style = { color: "var(--secondary))" };

        return <i className={"dx-icon dx-icon-print container_spanCentrado font-size-lg"} style={style} />;
    };

    return (
        <DataGrid
            dataSource={datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblPedidos}
            onCellPrepared={onCellPrepared_Total}
            onCellClick={onCellClick_tblPedidos}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                dataField={"idTipoPedido"}
                caption={getTrad("tipoPedido")}
                dataType={"number"}
                alignment={"left"}
                visible={false}
                minWidth={150}
                allowReordering
                allowResizing
                cellComponent={(e) => TipoElemento(e.data.data.idTipoPedido)}
                isRequired
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmTipoElemento.store()}
                    valueExpr="idAdmTipoElemento"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                name={"impresion"}
                caption={" "}
                width={30}
                allowReordering={false}
                allowResizing={false}
                cellRender={cellRender_impresion}
            />
            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fechaCreacion"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmProveedor"}
                caption={getTrad("proveedor")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={proveedores} valueExpr="idAdmProveedor" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmPedido_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmPedido_Estado, "", "pedido")}
                allowHeaderFiltering={true}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmPedido_Estado.store()}
                    valueExpr="idAdmPedido_Estado"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"fechaEstimadaRecepcion"}
                caption={getTrad("fechaEstimadaRecepcion")}
                dataType={"date"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column dataField={"idMoneda"} caption={getTrad("moneda")} visible={false} allowReordering allowResizing>
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                format={formats.percent}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"numPresupuestoProveedor"}
                caption={getTrad("numPresupuestoProveedor")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                visible={true}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue}
                cellRender={cellRender}
            />
            <Column {...config_isCerrado} />
            <Column
                dataField={"tblArticuloNAdmPedidoProveedor"}
                visible={false}
                dataType={"object"}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            {/* <Column
        caption={' '}
        width={30}
        alignment={'center'}
        cssClass={'p-0'}
        cellComponent={removeCellComponent}
      /> */}

            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setReport: (report) => dispatch(impresionActions.setReport(report)),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    close: () => dispatch(impresionActions.close()),
});

export default connect(null, mapDispatchToProps)(DataGridPedidosProveedor);
