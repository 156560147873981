import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  startOfMonth,
  endOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  durationToDatetime,
  dxRangeSelector_PosicionValor_nuevoMarker,
  filtroTiempo_Resize,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import { elemTransCellComponent } from "components/DataGrid/Cells";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var fechaDesde = null,
    fechaHasta = null,
    parteSel = null;
  //#endregion

  //#region Datasource

  var datasource_filtroTiempo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infParteTrabajo_filtroTiempo",
      errorHandler: errorHandler,
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var item = data[0];
      var dataSource = [];
      dataSource.push({ arg: item.fechaDesde, value: item.fechaDesde });
      dataSource.push({ arg: item.fechaHasta, value: item.fechaHasta });
      return dataSource;
    },
  });

  var datasource_partesTrabajo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "parteTrabajo",
      key: "idParteTrabajo",
      errorHandler: errorHandler,
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
    select: ["idParteTrabajo,fecha,resolucion,idMaquina,codigo"],
    expand: [
      "tblIncidenciaNParte($select=estadoInicial,estadoActual,fecha;$expand=tblIncidencia($select=idIncidencia,fechaIncidencia,denominacion,descripcionIncidencia,descripcionResolucion)),tblMaquina($select=denominacion,etiqueta,numSerie;$expand=tblTipoMaquinaNCategoriaMaquina($expand=tblCategoriaMaquina($select=denominacion))),tblPersonasNParte($select=idPersona,horas;$expand=tblPersona($select=nombre,apellidos)),tblRecambioNParteTrabajoIBS,tblRecambioNParteTrabajo($expand=tblRecambio($select=denominacion,referencia)),tblServicioExternoNParteTrabajo($select=idServicioExterno,denominacion,cantidad,precio)",
    ],
  });

  var datasource_categoriaMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoriaMaquina",
      key: "idCategoriaMaquina",
      errorHandler: errorHandler,
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idCategoriaMaquina,denominacion"],
  });

  var enum_EstadoParteTrabajo = [
    {
      estado: getTrad("ok").toUpperCase(),
      valor: 0,
      color: "#1BD319",
    },
    {
      estado: getTrad("noParada").toUpperCase(),
      valor: 1,
      color: "#FEF161",
    },
    {
      estado: getTrad("parada").toUpperCase(),
      valor: 2,
      color: "#FE3814",
    },
  ];

  $.when(datasource_filtroTiempo.load())
    .then(function (items) {
      if (items.length > 0) {
        fechaDesde = startOfMonth(new Date(items[items.length - 1].arg));
        fechaHasta = new Date(items[items.length - 1].arg);

        $.when(datasource_partesTrabajo.load())
          .then(function () {
            $("#InfPartesTrabajo #dxContainer").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  // GRID
                  ratio: 1,
                  template: function (e, index, item) {
                    item.append(
                      $("<div />").dxBox({
                        direction: "row",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            ratio: 4,
                            template: function (e, index, item) {
                              item.append(
                                $(
                                  "<div id='dxDataGrid_partesTrabajo' />",
                                ).dxDataGrid({
                                  dataSource: datasource_partesTrabajo,
                                  //Propiedades
                                  headerFilter: {
                                    visible: true,
                                  },
                                  filterRow: {
                                    visible: true,
                                    applyFilter: "auto",
                                    showAllText: getTrad("todos"),
                                  },
                                  height: "100%",
                                  columnAutoWidth: true,
                                  selection: {
                                    mode: "single",
                                  },
                                  export: {
                                    enabled: false,
                                    fileName:
                                      "InfPartesTrabajo_" +
                                      fechaDesde +
                                      "_fechaHasta",
                                    allowExportSelectedData: true,
                                    texts: {
                                      exportAll: getTrad("exportar"),
                                    },
                                  },
                                  hoverStateEnabled: true,
                                  searchPanel: {
                                    visible: true,
                                    width: 200,
                                    placeholder: getTrad("busqueda"),
                                    searchVisibleColumnsOnly: true,
                                  },
                                  paging: {
                                    pageSize: 50,
                                  },
                                  pager: {
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [50, 100],
                                    showInfo: true,
                                  },
                                  columns: [
                                    {
                                      dataField: "codigo",
                                      caption: getTrad("numParte"),
                                      sortIndex: 1,
                                      sortOrder: "asc",
                                      width: 100,
                                      allowFiltering: false,
                                      calculateCellValue: function (rowData) {
                                        return !rowData.codigo
                                          ? "-"
                                          : rowData.codigo;
                                      },
                                    },
                                    {
                                      dataField: "fecha",
                                      caption: getTrad("fecha"),
                                      dataType: "date",
                                      format: "dd/MM/yyyy",
                                      width: 150,
                                      allowHeaderFiltering: false,
                                    },
                                    {
                                      dataField:
                                        "tblMaquina.tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina",
                                      caption: getTrad("categoriaMaquina"),
                                      allowSorting: false,
                                      allowHeaderFiltering: false,
                                      alignment: "left",
                                      minWidth: 125,
                                      lookup: {
                                        dataSource:
                                          datasource_categoriaMaquina.store(),
                                        valueExpr: "idCategoriaMaquina",
                                        displayExpr: "denominacion",
                                      },
                                    },
                                    {
                                      dataField: "tblMaquina.denominacion",
                                      caption: getTrad("maquina"),
                                      allowSorting: false,
                                      allowHeaderFiltering: false,
                                      alignment: "left",
                                      minWidth: 125,
                                    },
                                    {
                                      dataField: "resolucion",
                                      caption: getTrad("descripcion"),
                                      allowSorting: false,
                                      allowFiltering: false,
                                      alignment: "left",
                                      width: "40%",
                                    },
                                  ],
                                  //Eventos
                                  onToolbarPreparing: function (e) {
                                    e.toolbarOptions.items.unshift(
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        showText: "inMenu",
                                        locateInMenu: "auto",
                                        options: {
                                          text: getTrad("limpiarFiltro"),
                                          icon: " icon_EliminarFiltros",
                                          onClick: function (e) {
                                            $("#dxDataGrid_partesTrabajo")
                                              .dxDataGrid("instance")
                                              .clearFilter();
                                            notify({
                                              message: getTrad(
                                                "aviso_C_FiltroRestaurado",
                                              ),
                                              type: "success",
                                              displayTime: "1500",
                                              closeOnClick: true,
                                            });
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("exportar"),
                                          icon: "exportxlsx",
                                          onClick: function (e) {
                                            $("#dxDataGrid_partesTrabajo")
                                              .dxDataGrid("instance")
                                              .exportToExcel();
                                          },
                                        },
                                      },
                                    );
                                  },
                                  onRowPrepared: function (e) {
                                    if (e.rowType === "data") {
                                      e.rowElement.css("cursor", "pointer");
                                    }
                                  },
                                  onSelectionChanged: function (e) {
                                    if (e.selectedRowsData.length > 0) {
                                      parteSel = e.selectedRowsData[0];
                                      $("#dxPopup_parteTrabajo")
                                        .dxPopup("instance")
                                        .show();
                                    } else parteSel = null;
                                  },
                                  //Estilos
                                  showColumnLines: false,
                                  showRowLines: true,
                                  rowAlternationEnabled: true,
                                }),
                              );
                            },
                          },
                        ],
                      }),
                    );
                  },
                },
                {
                  // FILTRO TIEMPO
                  baseSize: 130,
                  template: function (e, index, itemElement) {
                    itemElement.append(
                      create_rangeSelector(
                        datasource_filtroTiempo,
                        false,
                        false,
                        function (e) {
                          fechaDesde = e.value[0];
                          fechaHasta = e.component.option("valueHasta");

                          $("#dxDataGrid_partesTrabajo")
                            .dxDataGrid("instance")
                            .refresh();
                          dxRangeSelector_PosicionValor_nuevoMarker(
                            $("#dxRangeSelector_filtroTiempo"),
                            fechaDesde,
                            fechaHasta,
                          );
                        },
                        function () {
                          var ultimaFecha = new Date(
                            datasource_filtroTiempo.items()[
                              datasource_filtroTiempo.items().length - 1
                            ].arg,
                          );

                          $("#dxRangeSelector_filtroTiempo")
                            .dxRangeSelector("instance")
                            .option({
                              "scale.minRange": { days: null, months: 1 },
                              value: [
                                startOfMonth(ultimaFecha),
                                endOfMonth(new Date(ultimaFecha)),
                              ],
                            });
                        },
                      ),
                    );
                  },
                },
              ],
            });
          })
          .always(function () {
            setTimeout(function () {
              filtroTiempo_Resize();
            }, 0);
          });

        $("#InfPartesTrabajo #dxPopup_parteTrabajo").dxPopup({
          width: "1200px",
          height: "675px",
          deferRendering: false,
          title: getTrad("parteTrabajo"),
          contentTemplate: function () {
            return $("<div />").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  //Info General Parte
                  baseSize: 40,
                  template: function () {
                    return $("<div id='dxForm_infoParteTrabajo' /> ").dxForm({
                      colCount: 5,
                      formData: parteSel,
                      items: [
                        {
                          dataField: "fecha",
                          editorType: "dxDateBox",
                          colSpan: 1,
                          editorOptions: {
                            type: "datetime",
                            dateOutOfRangeMessage: getTrad(
                              "alerta_fechaIncidenciaNParte",
                            ),
                            displayFormat: "dd/MM/yyyy - HH:mm",
                            disabled: true,
                            min: "01/01/1900",
                          },
                        },
                        {
                          dataField: "tblMaquina.denominacion",
                          editorType: "dxTextBox",
                          colSpan: 2,
                          editorOptions: {
                            disabled: true,
                          },
                          label: {
                            text: getTrad("denominacion"),
                          },
                        },
                        {
                          dataField: "tblMaquina.etiqueta",
                          editorType: "dxTextBox",
                          colSpan: 1,
                          editorOptions: {
                            disabled: true,
                          },
                          label: {
                            text: getTrad("etiqueta"),
                          },
                        },
                        {
                          dataField: "tblMaquina.numSerie",
                          editorType: "dxTextBox",
                          colSpan: 1,
                          editorOptions: {
                            disabled: true,
                          },
                          label: {
                            text: getTrad("numSerie"),
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  //Grid Incidencias NParte
                  ratio: 2,
                  template: function () {
                    return $(
                      "<div id = 'dxDataGrid_IncidenciasNParteTrabajo' style ='margin-bottom: 10px; ' />",
                    ).dxDataGrid({
                      //Datos
                      remoteOperations: true,
                      keyExpr: "tblIncidencia.idIncidencia",
                      //Propiedades
                      height: "100%",
                      loadPanel: { enabled: false },
                      columnsAutoWidth: true,
                      selection: {
                        mode: "single",
                      },
                      columns: [
                        {
                          dataField: "tblIncidencia.idIncidencia",
                          visible: false,
                        },
                        {
                          dataField: "tblIncidencia.fechaIncidencia",
                          caption: getTrad("fecha"),
                          width: 150,
                          format: "dd/MM/yyyy - HH:mm",
                          allowEditing: false,
                        },
                        {
                          dataField: "tblIncidencia.denominacion",
                          caption: getTrad("denominacion"),
                          minWidth: 400,
                          allowEditing: false,
                        },
                        {
                          dataField: "estadoInicial",
                          caption: getTrad("estadoInicial"),
                          width: "120px",
                          alignment: "center",
                          lookup: {
                            dataSource: enum_EstadoParteTrabajo,
                            displayExpr: "estado",
                            valueExpr: "valor",
                          },
                        },
                        {
                          dataField: "fecha",
                          caption: getTrad("horaResolucion"),
                          alignment: "center",
                          width: 70,
                          format: "HH:mm",
                        },
                        {
                          dataField: "estadoActual",
                          caption: getTrad("estadoFinal"),
                          width: "120px",
                          alignment: "center",
                          lookup: {
                            dataSource: enum_EstadoParteTrabajo,
                            displayExpr: "estado",
                            valueExpr: "valor",
                          },
                        },
                      ],
                      //Eventos
                      onRowPrepared: function (e) {
                        if (e.rowType === "data") {
                          e.rowElement.css("cursor", "pointer");
                        }
                      },
                      onSelectionChanged: function (e) {
                        var descripcionIncidencia =
                          e.selectedRowsData.length > 0
                            ? e.selectedRowsData[0].tblIncidencia
                                .descripcionIncidencia
                            : "";
                        $("#dxTextArea_descripcionIncidencia")
                          .dxTextArea("instance")
                          .option("value", descripcionIncidencia);
                      },
                      //Estilos
                      showColumnLines: false,
                      showRowLines: true,
                      rowAlternationEnabled: true,
                    });
                  },
                },
                {
                  //Descripciones Parte y Grid tecnicos y recambios
                  ratio: 3,
                  template: function () {
                    return $("<div />").dxBox({
                      direction: "row",
                      align: "space-around",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          baseSize: 475,
                          template: function () {
                            return $(
                              "<div style= 'margin-right:15px;' />",
                            ).dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  baseSize: 20,
                                  template: function () {
                                    return $(
                                      "<div class ='font_family font_size_titulo' >",
                                    ).text(getTrad("descripcionIncidencia"));
                                  },
                                },
                                {
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<div id = 'dxTextArea_descripcionIncidencia'/>",
                                    ).dxTextArea({
                                      height: "100%",
                                      readOnly: true,
                                    });
                                  },
                                },
                                {
                                  baseSize: 20,
                                  template: function () {
                                    return $(
                                      "<div  class ='font_family font_size_titulo' style='margin-top:10px;' >",
                                    ).text(getTrad("descripcionResolucion"));
                                  },
                                },
                                {
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<div id = 'dxTextArea_ResolucionIncidencia' style='height:100%; margin-top:10px;' />",
                                    ).dxTextArea({
                                      readOnly: true,
                                    });
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $("<div />").dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  ratio: 0,
                                  baseSize: 40,
                                  template: function (e, index, item) {
                                    return $(
                                      "<div id='dxRadioGroup_tabPanel' />",
                                    ).dxRadioGroup({
                                      height: "100px",
                                      width: "160px",
                                      dataSource: [
                                        { text: getTrad("tecnicos"), val: 1 },
                                        { text: getTrad("recambios"), val: 2 },
                                        {
                                          text: getTrad("serviciosExternos"),
                                          val: 3,
                                        },
                                      ],
                                      value: 1,
                                      valueExpr: "val",
                                      layout: "horizontal",
                                      itemTemplate: function (
                                        itemData,
                                        itemIndex,
                                        itemElement,
                                      ) {
                                        itemElement
                                          .parent()
                                          .css("padding", "6px 0px");
                                        itemElement.addClass(
                                          "container_spanCentrado",
                                        );
                                        itemElement.append(
                                          $("<span>").text(itemData.text),
                                        );
                                      },
                                      onValueChanged: function (e) {
                                        var index = (index = e.value);
                                        if (e.value === 2)
                                          index =
                                            LAVANDERIA.idCorporacion !== 1
                                              ? 1
                                              : 2;

                                        $("#dxTabPanel_tecnicosRecambios_inf")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", index);
                                      },
                                    });
                                  },
                                },
                                {
                                  ratio: 1,
                                  template: function (e, index, item) {
                                    return $(
                                      "<div id = 'dxTabPanel_tecnicosRecambios_inf'/>",
                                    )
                                      .addClass("no-header")
                                      .dxTabPanel({
                                        height: "100%",
                                        width: "100%",
                                        focusStateEnabled: false,
                                        deferRendering: false,
                                        items: [
                                          {
                                            title: getTrad("tecnicos"),
                                            template: function () {
                                              return $(
                                                "<div id = 'dxDataGrid_TecnicosParte' />",
                                              ).dxDataGrid({
                                                //Datos
                                                keyExpr: "idPersona",
                                                height: "100%",
                                                remoteOperations: true,
                                                loadPanel: { enabled: false },
                                                //Configuración
                                                columnAutoWidth: true,
                                                columns: [
                                                  {
                                                    dataField: "nombreCompleto",
                                                    caption: getTrad("nombre"),
                                                    minWidth: 100,
                                                    alignment: "left",
                                                    allowEditing: false,
                                                    allowSorting: false,
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.tblPersona
                                                            .nombre +
                                                          " " +
                                                          rowData.tblPersona
                                                            .apellidos
                                                        );
                                                      },
                                                  },
                                                  {
                                                    dataField: "horas",
                                                    caption: getTrad("horas"),
                                                    alignment: "center",
                                                    dataType: "datetime",
                                                    format: "HH:mm",
                                                    width: "100px",
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return durationToDatetime(
                                                          rowData.horas,
                                                        );
                                                      },
                                                  },
                                                ],
                                                //Estilos
                                                showColumnLines: false,
                                                showRowLines: true,
                                                rowAlternationEnabled: true,
                                              });
                                            },
                                          },
                                          {
                                            title: getTrad("recambios"),
                                            template: function () {
                                              return $(
                                                "<div id = 'dxDataGrid_Recambios'/>",
                                              ).dxDataGrid({
                                                //Datos
                                                remoteOperations: false,
                                                keyExpr: "idRecambio",
                                                //Configuración
                                                columnsAutoWidth: true,
                                                loadPanel: { enabled: false },
                                                height: "100%",
                                                columns: [
                                                  {
                                                    dataField:
                                                      "tblRecambio.referencia",
                                                    caption:
                                                      getTrad("refFabricante"),
                                                    width: 110,
                                                    alignment: "center",
                                                  },
                                                  {
                                                    dataField:
                                                      "tblRecambio.referencia",
                                                    minWidth: 150,
                                                    alignment: "left",
                                                    caption:
                                                      getTrad("denominacion"),
                                                  },
                                                  {
                                                    dataField: "cantidad",
                                                    caption:
                                                      getTrad("cantidad"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                  },
                                                  {
                                                    dataField: "precio",
                                                    caption: getTrad("precio"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                  },
                                                  {
                                                    dataField: "importe",
                                                    width: 100,
                                                    alignment: "center",
                                                    caption: getTrad("importe"),
                                                    dataType: "number",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.cantidad *
                                                          rowData.precio
                                                        );
                                                      },
                                                  },
                                                ],
                                                //Estilos
                                                showColumnLines: false,
                                                showRowLines: true,
                                                rowAlternationEnabled: true,
                                              });
                                            },
                                          },
                                          {
                                            title: getTrad("recambios2"),
                                            template: function () {
                                              return $(
                                                "<div id = 'dxDataGrid_RecambiosIBS'/>",
                                              ).dxDataGrid({
                                                //Datos
                                                remoteOperations: false,
                                                keyExpr:
                                                  "idRecambioNParteTrabajo",
                                                //Configuración
                                                columnsAutoWidth: true,
                                                loadPanel: { enabled: false },
                                                height: "100%",
                                                columns: [
                                                  {
                                                    dataField:
                                                      "referenciaRecambio",
                                                    caption:
                                                      getTrad("referencia"),
                                                    width: 100,
                                                    alignment: "center",
                                                  },
                                                  {
                                                    dataField: "denominacion",
                                                    minWidth: 150,
                                                    alignment: "left",
                                                    caption:
                                                      getTrad("denominacion"),
                                                  },
                                                  {
                                                    dataField: "cantidad",
                                                    caption:
                                                      getTrad("cantidad"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                  },
                                                  {
                                                    dataField: "precio",
                                                    caption: getTrad("precio"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                  },
                                                  {
                                                    dataField: "importe",
                                                    width: 100,
                                                    alignment: "center",
                                                    caption: getTrad("importe"),
                                                    dataType: "number",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.cantidad *
                                                          rowData.precio
                                                        );
                                                      },
                                                  },
                                                ],
                                                //Estilos
                                                showColumnLines: false,
                                                showRowLines: true,
                                                rowAlternationEnabled: true,
                                              });
                                            },
                                          },
                                          {
                                            title: getTrad("serviciosExternos"),
                                            template: function () {
                                              return $(
                                                "<div id = 'dxDataGrid_tblServicioExternoNParteTrabajo'/>",
                                              ).dxDataGrid({
                                                //Datos
                                                remoteOperations: false,
                                                keyExpr: "idServicioExterno",
                                                //Configuración
                                                columnsAutoWidth: true,
                                                loadPanel: { enabled: false },
                                                height: "100%",
                                                columns: [
                                                  {
                                                    dataField: "denominacion",
                                                    caption:
                                                      getTrad("denominacion"),
                                                    alignment: "left",
                                                  },
                                                  {
                                                    dataField: "cantidad",
                                                    caption:
                                                      getTrad("cantidad"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                  },
                                                  {
                                                    dataField: "precio",
                                                    caption: getTrad("precio"),
                                                    dataType: "number",
                                                    width: 100,
                                                    alignment: "center",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                  },
                                                  {
                                                    dataField: "importe",
                                                    width: 100,
                                                    alignment: "center",
                                                    caption: getTrad("importe"),
                                                    dataType: "number",
                                                    format: {
                                                      style: "currency",
                                                      currency:
                                                        LAVANDERIA.moneda,
                                                      minimumFractionDigits: 2,
                                                    },
                                                    calculateCellValue:
                                                      function (rowData) {
                                                        return (
                                                          rowData.cantidad *
                                                          rowData.precio
                                                        );
                                                      },
                                                  },
                                                ],
                                                //Estilos
                                                showColumnLines: false,
                                                showRowLines: true,
                                                rowAlternationEnabled: true,
                                              });
                                            },
                                          },
                                        ],
                                      });
                                  },
                                },
                              ],
                            });
                          },
                        },
                      ],
                    });
                  },
                },
              ],
            });
          },
          toolbarItems: [
            {
              location: "after",
              widget: "dxButton",
              toolbar: "bottom",
              options: {
                text: getTrad("cerrar"),
                onClick: function (e) {
                  $("#dxPopup_parteTrabajo").dxPopup("instance").hide();
                },
              },
            },
          ],
          //Eventos
          onHiding: function (e) {
            $("#dxRadioGroup_tabPanel")
              .dxRadioGroup("instance")
              .option("value", 1);
            $("#dxDataGrid_partesTrabajo").dxDataGrid("instance").deselectAll();
          },
          onHidden: function (e) {
            $("#dxDataGrid_IncidenciasNParteTrabajo")
              .dxDataGrid("instance")
              .deselectAll();
          },
          onShowing: function (e) {
            var dxDataGrid_IncidenciasNParteTrabajo = $(
              "#dxDataGrid_IncidenciasNParteTrabajo",
            ).dxDataGrid("instance");
            dxDataGrid_IncidenciasNParteTrabajo.option(
              "dataSource",
              parteSel.tblIncidenciaNParte,
            );
            $("#dxTextArea_ResolucionIncidencia")
              .dxTextArea("instance")
              .option("value", parteSel.resolucion);
            $("#dxDataGrid_TecnicosParte")
              .dxDataGrid("instance")
              .option("dataSource", parteSel.tblPersonasNParte);
            $("#dxDataGrid_Recambios")
              .dxDataGrid("instance")
              .option("dataSource", parteSel.tblRecambioNParteTrabajo);
            $("#dxDataGrid_RecambiosIBS")
              .dxDataGrid("instance")
              .option("dataSource", parteSel.tblRecambioNParteTrabajoIBS);
            $("#dxDataGrid_tblServicioExternoNParteTrabajo")
              .dxDataGrid("instance")
              .option("dataSource", parteSel.tblServicioExternoNParteTrabajo);
            $("#dxTabPanel_tecnicosRecambios_inf")
              .dxTabPanel("instance")
              .option("selectedIndex", 0);
            $("#dxForm_infoParteTrabajo")
              .dxForm("instance")
              .option("formData", parteSel);

            if (parteSel.tblIncidenciaNParte.length > 0)
              dxDataGrid_IncidenciasNParteTrabajo.selectRows(
                parteSel.tblIncidenciaNParte[0].tblIncidencia.idIncidencia,
              );
          },
          onShown: function () {
            $("#dxDataGrid_IncidenciasNParteTrabajo")
              .dxDataGrid("instance")
              .repaint();
          },
        });
      } else {
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "dxButton_sinLavanderia_error",
          ],
        ]);
      }
    })
    .always(function () {
      deferred.resolve();
    });

  return deferred.promise();
}
