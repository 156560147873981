import React, { Fragment } from "react";
import { connect } from "react-redux";

import { history } from "../../../helpers";

import { userActions } from "../../../actions";
import { Col } from "reactstrap";

import Loader from "react-loaders";

class RedirectPage extends React.Component {
  constructor(props) {
    super(props);

    //Reset login status
    this.props.dispatch(userActions.logout());
  }

  render() {
    return (
      <Fragment>
        <div className="app-container">
          <div className="he-100 bg-animation bg-white">
            <div className="d-flex he-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="logo-LoveYourLinen mx-auto mb-3" />
                <div className="loader-container">
                  <div className="loader-container-inner m-5">
                    <div className="text-center">
                      <Loader type="line-scale" />
                    </div>
                  </div>
                </div>
                <div className="text-center opacity-8 mt-3">
                  <a href="https://www.polarier.com/aviso-legal/ ">
                    Copyright &copy; Polarier {new Date().getFullYear()}{" "}
                  </a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { credentials } = this.props.match.params;
    if (credentials) {
      try {
        let cred = JSON.parse(atob(atob(credentials)));
        dispatch(userActions.login(cred.email, cred.password));
      } catch (error) {
        history.push("/");
      }
    } else {
      history.push("/");
    }
  }
}

function mapStateToProps(state) {
  return {};
}

const connectedRedirectPage = connect(mapStateToProps)(RedirectPage);
export default connectedRedirectPage;
