import { combineReducers } from "redux";

import Global from "./Global";
import ThemeOptions from "./ThemeOptions";
import Authentication from "./Authentication";
import Impresion from "./Impresion";
import LoadPanel from "./LoadPanel";
import fotos from "./fotos";
import tasaCambio from "./tasaCambio";

const reducers = combineReducers({
    Global,
    Authentication,
    ThemeOptions,
    Impresion,
    LoadPanel,
    fotos,
    tasaCambio,
});

export default reducers;
