import React, { Component } from "react";

import { connect } from "react-redux";

import $ from "jquery";

import Button from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";
import DataGrid, {
  Column,
  Export,
  GroupItem,
  Lookup,
  Paging,
  Scrolling,
  Sorting,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import DateBox from "devextreme-react/date-box";
import LoadPanel from "devextreme-react/load-panel";
import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";

import "./DataGrid_Nominas.scss";
import LastCell from "./LastCell";
import PersonalGeneral from "pages/RRHH/PersonalGeneral";
import idsEstadoHistoricoAsientoNomina from "constants/enums/RRHH/tblEstadoHistoricoAsientoNomina";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import { connectionConstants } from "constants";
import { editCellComponent } from "components/DataGrid/Cells";
import {
  authHeader,
  errorHandler,
  formatDate_noTime_parameter,
  formatNumber,
  getTrad,
} from "helpers";

const elementAttr = { id: "dxDataGrid_AsientosNominas" };

const position = { my: "center", at: "center", of: `#${elementAttr.id}` };

const columnNames_historicoAsientoNomina = [
  "salarioBruto",
  "tributacionIRPF",
  "segSocialTrabajador",
  "liquidoPercibir",
  "embargo",
  "conceptoNEspecie",
  "segSocialEmpresa",
  "descuentosSalariales",
  "descuentoPreaviso",
];

class DataGrid_ContabilidadNominas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: false,
      idElementoPEPSel: null,
      idCentroCosteSel: null,
      isSending: false,
      context_asinetosNominas_then: null,
      idAccionARealizar: null,
      tblCentroCoste_ElementoPEP: [],
      tblTipoTrabajo: [],
    };

    this.dxDataGrid_REF = React.createRef();
    this.PersonalGeneral_REF = React.createRef();
  }

  get dxDataGrid() {
    return this.dxDataGrid_REF.current.instance;
  }

  // get PersonalGeneral() {
  //     return this.refPersonalGeneralPopup.current;
  // }

  // #region Properties

  acciones = {
    [idsEstadoHistoricoAsientoNomina.pagado]: getTrad("pagado"),
    [idsEstadoHistoricoAsientoNomina.anticipo]: getTrad("anticipo"),
    [idsEstadoHistoricoAsientoNomina.contabilizado]: getTrad("contabilizado"),
  };

  fieldsFecha = {
    [idsEstadoHistoricoAsientoNomina.pagado]: getTrad("establecerFechaPago"),
    [idsEstadoHistoricoAsientoNomina.anticipo]: getTrad("fecha"),
    [idsEstadoHistoricoAsientoNomina.contabilizado]: getTrad(
      "establecerFechaContable"
    ),
  };

  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
  };
  summaryItem = (column) => ({
    column,
    showInColumn: column,
    summaryType: "sum",
    displayFormat: "{0}",
    valueFormat: this.currency_format,
    alignByColumn: true,
  });
  currencyColumn = {
    format: this.currency_format,
    dataType: "number",
    alignment: "center",
  };

  // #endregion

  // #region Lifecycle

  personalGeneral_onContentReady = (e) => {
    if (e.component.pageSize() > 1) {
      e.component.pageSize(1);
    }
    if (this.isEditing) {
      this.isEditing = false;
      e.component.editRow(0);
    }
  };

  // #endregion

  // #region Context

  context_asinetosNominas = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "MyPolarier/Contabilidad/AsientosNominas",
    entities: {
      GenerarAsientosNominasSAP: {},
      GenerarHistoricoAsientoNomina: {},
    },
    beforeSend: (request) => this.context_asientosNominas_beforeSend(request),
  });

  context_asientosNominas_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    const { idElementoPEPSel, idCentroCosteSel, fechaAsiento, idNominaSel } =
      this.state;
    const { fechaSel, empresa } = this.props;

    request.params.fechaAsiento = formatDate_noTime_parameter(fechaAsiento);

    if (
      request.url.includes("GenerarHistoricoAsientoNomina") ||
      request.url.includes("GenerarAnticipo")
    ) {
      request.params.idNomina = idNominaSel;
    } else {
      request.params.idEmpresaPolarier = empresa.idEmpresaPolarier;
      request.params.idElementoPEP = idElementoPEPSel;
      request.params.idCentroCoste = idCentroCosteSel;
      request.params.fechaDesde = formatDate_noTime_parameter(
        fechaSel.startDate
      );
      request.params.fechaHasta = formatDate_noTime_parameter(fechaSel.endDate);
    }
  };

  store_tblTipoTrabajo = new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoTrabajo",
    key: "idTipoTrabajo",
    errorHandler: function (error) {
      errorHandler(error, null);
    },
    beforeSend: (request) => {
      request.headers = { ...authHeader() };
    },
    onLoaded: (result) => this.store_tblTipoTrabajo_onLoaded(result),
    version: 4,
  });

  store_tblTipoTrabajo_onLoaded = (result) => {
    result.map((x) => {
      delete x.icon;
      return x;
    });
  };

  store_tblAdmCentroCoste = new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCentroCoste",
    key: "idAdmCentroCoste",
    errorHandler: (error) => errorHandler(error, null),
    beforeSend: (request) => {
      request.headers = { ...authHeader() };
    },
    version: 4,
  });

  store_tblAdmElementoPEP = new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
    key: "idAdmElementoPEP",
    errorHandler: (error) => errorHandler(error, null),
    beforeSend: (request) => {
      request.headers = { ...authHeader() };
    },
    version: 4,
  });

  store_tblCentroCoste_ElementoPEP = new CustomStore({
    key: "idCentroElem",
    load: (loadOptions) =>
      this.store_tblCentroCoste_ElementoPEP_load(loadOptions),
    byKey: (key) => this.store_tblCentroCoste_ElementoPEP_byKey(key),
  });

  store_tblCentroCoste_ElementoPEP_load = (loadOptions) => {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.store_tblAdmCentroCoste.load(),
        this.store_tblAdmElementoPEP.load(),
      ]).then(([tblCentroCoste, tblElementoPEP]) => {
        let datasource_tblCentroCoste_tblElementoPEP = $.map(
          $.merge(tblCentroCoste, tblElementoPEP),
          function (item, index) {
            return {
              groupTitle: item.idAdmCentroCoste
                ? getTrad("centroCoste")
                : getTrad("elementoPep"),
              idCentroElem: JSON.stringify([
                item.idAdmCentroCoste ? item.idAdmCentroCoste : -1,
                item.idAdmElementoPEP ? item.idAdmElementoPEP : -1,
              ]),
              denominacion: item.codigo + " - " + item.denominacion,
              codigo: item.codigo,
            };
          }
        );

        resolve(datasource_tblCentroCoste_tblElementoPEP);
      });
    });
  };

  store_tblCentroCoste_ElementoPEP_byKey = (key) => {
    return new Promise((resolve, reject) => {
      this.store_tblCentroCoste_ElementoPEP_load().then((data) => {
        let result = data.filter((x) => x.idCentroElem === key)[0];
        resolve(result);
      });
    });
  };

  // #endregion

  defaultNotify = (isError, trad) => {
    notify({
      message: getTrad(trad),
      type: isError ? "error" : "success",
      displayTime: 1500,
      closeOnClick: true,
    });
  };

  render() {
    const { dataSource, fechaSel, vista, filtroEstadoNomina, isLoading } =
      this.props;
    const {
      popupVisible,
      fechaAsiento,
      idAccionARealizar,
      isSending,
      tblTipoTrabajo,
      tblCentroCoste_ElementoPEP,
    } = this.state;

    const dataSourceFinal = query(dataSource)
      .filter((n) => {
        const {
          pagado,
          pendientePago,
          anticipo,
          pendienteAnticipo,
          contabilizado,
          retenido,
        } = filtroEstadoNomina;
        const {
          idEstadoHistoricoAsientoNomina,
          isRetenida,
          isPendientePagar,
          isPendienteAnticipo,
        } = n;

        return (
          (pagado &&
            idEstadoHistoricoAsientoNomina ===
              idsEstadoHistoricoAsientoNomina.pagado &&
            !isPendientePagar &&
            !isPendienteAnticipo) ||
          (pendientePago &&
            !isRetenida &&
            (idEstadoHistoricoAsientoNomina === null || isPendientePagar)) ||
          (anticipo &&
            idEstadoHistoricoAsientoNomina ===
              idsEstadoHistoricoAsientoNomina.anticipo &&
            !isPendientePagar &&
            !isPendienteAnticipo) ||
          (pendienteAnticipo && isPendienteAnticipo) ||
          (contabilizado &&
            idEstadoHistoricoAsientoNomina ===
              idsEstadoHistoricoAsientoNomina.contabilizado &&
            !isPendientePagar &&
            !isPendienteAnticipo) ||
          (retenido && isRetenida)
        );
      })
      .toArray();

    return (
      <>
        <LoadPanel visible={isLoading} position={position} />
        <DataGrid
          ref={this.dxDataGrid_REF}
          elementAttr={elementAttr}
          dataSource={dataSourceFinal}
          className={"DataGrid_Nominas"}
          height={"100%"}
          width={"100%"}
          remoteOperations={false}
          showColumnLines={false}
          showRowLines
          hoverStateEnabled
          onEditingStart={this.onEditingStart}
          onCellPrepared={this.dxDataGrid_onCellPrepared}
          onCellClick={this.dxDataGrid_onCellClick}
        >
          <Scrolling mode="virtual" />
          <Sorting mode={"multiple"} />
          <Paging enabled={false} />
          <Export
            enabled={false}
            fileName={vista ? "HistorialNominas" : "AsientoNominas"}
          />
          <Column
            dataField="idCentroElem"
            groupIndex={0}
            autoExpandGroup={false}
            caption={""}
            sortOrder={"desc"}
            sortIndex={0}
            width={300}
            groupCellTemplate={this.dxDataGrid_groupCellTemplate}
          >
            <Lookup
              dataSource={tblCentroCoste_ElementoPEP}
              valueExpr={"idCentroElem"}
              displayExpr={"denominacion"}
            />
          </Column>

          <Column
            caption=" "
            name={"editCell"}
            width={30}
            alignment="center"
            cssClass="p-0"
            fixed
            cellComponent={editCellComponent}
            allowExporting={false}
          />

          <Column
            dataField="nombreCompleto"
            caption={getTrad("persona")}
            dataType={"string"}
            sortOrder={"asc"}
            fixed
            sortIndex={1}
            width={300}
          />

          <Column
            dataField="idTipoTrabajo"
            caption={getTrad("tipoTrabajo")}
            dataType={"string"}
            width={125}
          >
            <Lookup
              dataSource={tblTipoTrabajo}
              valueExpr={"idTipoTrabajo"}
              displayExpr={"denominacion"}
            />
          </Column>

          <Column
            width={125}
            caption={getTrad("fechaCobro")}
            dataField={"fechaCobro"}
            dataType={"datetime"}
            format={"dd/MM/yyyy"}
            sortOrder={"asc"}
            sortIndex={1}
            alignment={"center"}
            visible={vista}
          />
          <Column
            width={125}
            caption={getTrad("tipoPaga")}
            dataField={"tipoPaga"}
            alignment={"center"}
            visible={vista}
          />
          <Column
            width={100}
            caption={getTrad("salarioBruto")}
            dataField={"salarioBruto"}
            {...this.currencyColumn}
          />
          <Column
            width={100}
            caption={getTrad("pagaExtra1")}
            dataField={"pagaExtra1"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={100}
            caption={getTrad("pagaExtra2")}
            dataField={"pagaExtra2"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={125}
            caption={getTrad("tributacionIRPF")}
            dataField={"tributacionIRPF"}
            {...this.currencyColumn}
          />
          <Column
            width={125}
            caption={getTrad("segSocialTrabajador")}
            dataField={"segSocialTrabajador"}
            {...this.currencyColumn}
          />
          <Column
            width={125}
            caption={getTrad("liquidoPercibir")}
            dataField={"liquidoPercibir"}
            {...this.currencyColumn}
          />
          <Column
            width={100}
            caption={getTrad("anticipo")}
            dataField={"anticipo"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={100}
            caption={getTrad("embargo")}
            dataField={"embargo"}
            {...this.currencyColumn}
          />
          <Column
            width={100}
            caption={getTrad("absentismo")}
            dataField={"absentismo"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={125}
            caption={getTrad("indemnizacion")}
            dataField={"indemnizacion"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={150}
            caption={getTrad("conceptoNEspecie")}
            dataField={"conceptoNEspecie"}
            {...this.currencyColumn}
          />
          <Column
            width={100}
            caption={getTrad("segSocialEmpresa")}
            dataField={"segSocialEmpresa"}
            {...this.currencyColumn}
          />
          <Column
            width={100}
            caption={getTrad("totalTC1")}
            dataField={"totalTC1"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={100}
            caption={getTrad("baseCC")}
            dataField={"baseCC"}
            {...this.currencyColumn}
            visible={vista}
          />
          <Column
            width={125}
            caption={getTrad("costeEmpresa")}
            dataField={"costeEmpresa"}
            {...this.currencyColumn}
            visible={vista}
          />

          <Column
            width={125}
            dataField="descuentosSalariales"
            caption={getTrad("descuentosSalariales")}
            {...this.currencyColumn}
          />

          <Column
            width={125}
            dataField="descuentoPreaviso"
            caption={getTrad("descuentoPreaviso")}
            {...this.currencyColumn}
          />

          <Column
            dataField={"advertencias"}
            caption={""}
            width={30}
            allowSorting={false}
            allowFiltering={false}
            allowSearch={false}
            allowHeaderFiltering={false}
            fixed
            fixedPosition={"right"}
            visible={!vista}
          />

          <Column
            dataField="errores"
            caption={""}
            width={120}
            allowSorting={false}
            allowFiltering={false}
            allowSearch={false}
            allowHeaderFiltering={false}
            visible={!vista}
            cssClass={"p-0"}
            fixed
            fixedPosition={"right"}
            cellComponent={LastCell}
            calculateCellValue={this.dxDataGrid_calculateCellValue_errores}
          />

          <Column
            dataField="idPersona"
            caption={" "}
            width={0}
            visible={false}
          />

          <Summary>
            <TotalItem
              column={"nombreCompleto"}
              displayFormat={getTrad("total").toUpperCase()}
            />
            <GroupItem {...this.summaryItem("salarioBruto")} />
            <TotalItem {...this.summaryItem("salarioBruto")} />
            <GroupItem {...this.summaryItem("pagaExtra1")} />
            <TotalItem {...this.summaryItem("pagaExtra1")} />
            <GroupItem {...this.summaryItem("pagaExtra2")} />
            <TotalItem {...this.summaryItem("pagaExtra2")} />
            <GroupItem {...this.summaryItem("tributacionIRPF")} />
            <TotalItem {...this.summaryItem("tributacionIRPF")} />
            <GroupItem {...this.summaryItem("segSocialTrabajador")} />
            <TotalItem {...this.summaryItem("segSocialTrabajador")} />
            <GroupItem {...this.summaryItem("liquidoPercibir")} />
            <TotalItem {...this.summaryItem("liquidoPercibir")} />
            <GroupItem {...this.summaryItem("anticipo")} />
            <TotalItem {...this.summaryItem("anticipo")} />
            <GroupItem {...this.summaryItem("embargo")} />
            <TotalItem {...this.summaryItem("embargo")} />
            <GroupItem {...this.summaryItem("absentismo")} />
            <TotalItem {...this.summaryItem("absentismo")} />
            <GroupItem {...this.summaryItem("indemnizacion")} />
            <TotalItem {...this.summaryItem("indemnizacion")} />
            <GroupItem {...this.summaryItem("conceptoNEspecie")} />
            <TotalItem {...this.summaryItem("conceptoNEspecie")} />
            <GroupItem {...this.summaryItem("segSocialEmpresa")} />
            <TotalItem {...this.summaryItem("segSocialEmpresa")} />
            <GroupItem {...this.summaryItem("totalTC1")} />
            <TotalItem {...this.summaryItem("totalTC1")} />
            <GroupItem {...this.summaryItem("baseCC")} />
            <TotalItem {...this.summaryItem("baseCC")} />
            <GroupItem {...this.summaryItem("costeEmpresa")} />
            <TotalItem {...this.summaryItem("costeEmpresa")} />
            <GroupItem {...this.summaryItem("descuentosSalariales")} />
            <TotalItem {...this.summaryItem("descuentosSalariales")} />
            <GroupItem {...this.summaryItem("descuentoPreaviso")} />
            <TotalItem {...this.summaryItem("descuentoPreaviso")} />
            <GroupItem
              column={"errores"}
              summaryType={"sum"}
              displayFormat={"{0}"}
              valueFormat={this.currency_format}
              alignByColumn
            />
          </Summary>
        </DataGrid>
        <Popup
          visible={popupVisible}
          title={this.acciones[idAccionARealizar]}
          onHiding={this.dxPopup_onHiding}
          onHidden={this.dxPopup_onHidden}
          dragEnabled={false}
          width={300}
          height={225}
        >
          <div className="dx-form">
            <span className="dx-field-item-label-text">
              {this.fieldsFecha[idAccionARealizar]}:
            </span>
            <DateBox
              displayFormat={"dd/MM/yyyy"}
              stylingMode="underlined"
              type={"date"}
              openOnFieldClick
              acceptCustomValue={false}
              value={fechaAsiento}
              onValueChanged={this.dxDateBox_onValueChanged}
              min={
                idAccionARealizar === idsEstadoHistoricoAsientoNomina.anticipo
                  ? new Date(
                      fechaSel.startDate.getFullYear(),
                      fechaSel.startDate.getMonth() + 1,
                      fechaSel.startDate.getDate()
                    )
                  : fechaSel.startDate
              }
            />
          </div>
          <ToolbarItem location={"after"} toolbar={"bottom"}>
            <Button
              disabled={fechaAsiento == null || isSending}
              text={getTrad("crear")}
              onClick={this.dxToolbarItem_onClick}
              type="success"
            />
          </ToolbarItem>
          <ToolbarItem location={"after"} toolbar={"bottom"}>
            <Button
              text={getTrad("cancelar")}
              onClick={this.dxPopup_onHiding}
            />
          </ToolbarItem>
        </Popup>
        <div style={this.personalGeneralStyle}>
          <PersonalGeneral ref={(ref) => (this.PersonalGeneral_REF = ref)} />
        </div>
      </>
    );
  }

  personalGeneralStyle = { overflow: "hidden", height: 0 };

  // #region dxDataGrid

  onEditingStart(e) {
    e.cancel = true;
  }

  dxDataGrid_onCellPrepared = (e) => {
    const { dataSourceAgrupado } = this.props;

    const { column, rowType, value, cellElement, data, key } = e;

    if (
      rowType === "group" &&
      column.dataField !== "errores" &&
      column.dataField !== "advertencias"
    ) {
      cellElement.addClass("pointer");
    }

    if (column.dataField === "advertencias" && rowType === "group") {
      const items = dataSourceAgrupado[key[0]];

      const sigueIgual = this.getSigueIgual(items, data, true);

      const nominasRetenidas = items.filter((n) => n.isRetenida);

      let element = $(cellElement[0]);
      element.empty();
      element.css("padding", "0");
      element.css("border-left", "2px solid #ddd");

      if (!sigueIgual || nominasRetenidas.length > 0) {
        let icon = $(
          `<div class="container_spanCentrado">
            <i class="icon_Warning font-size-xs"></i>
          </div>
          `
        );

        element.append(icon.clone(false));

        cellElement.append(
          $("<div />").dxTooltip({
            target: cellElement,
            position: "top",
            showEvent: {
              name: "dxhoverstart",
              delay: 200,
            },
            hideEvent: "dxhoverend",
            contentTemplate: (contentElement) => {
              if (!sigueIgual) {
                contentElement.append(
                  $("<div />").text(getTrad("hayDiferenciasSinPagarAnticipar"))
                );
              }
              if (nominasRetenidas.length > 0) {
                contentElement.append(
                  $("<div />").text(
                    `${getTrad("hayNominasRetenidas")} (${
                      nominasRetenidas.length
                    })`
                  )
                );
              }
            },
          })
        );
      }
    }

    if (column.dataField === "errores" && rowType === "group") {
      const items = dataSourceAgrupado[key[0]];

      const nominasSinRetencion = items.filter((n) => !n.isRetenida);

      const idEstadoNomina = Math.min(
        ...nominasSinRetencion.map((x) => x.idEstadoNomina)
      );

      const sigueIgual = this.getSigueIgual(items, data);

      const estadoHAN = this.getEstadoHistoricoAsientoNomina(items, sigueIgual);

      let button = $(`<div class="chipContainer">
                <div class="chip secondary">
                    ${getTrad("pagar")}
                </div>
            </div>`);

      if (idEstadoNomina < idsEstadoNomina.validadoGestoria) {
        button = $(`<div class="chipContainer dx-state-disabled">
                    <div class="chip disabled">
                        ${getTrad("bloqueado")}
                    </div>
                </div>`);
      } else if (
        data.aggregates.at(17) ||
        nominasSinRetencion.some((x) => x.hasConceptosGestoria)
      ) {
        button = $(`<div class="chipContainer dx-state-disabled">
                    <div class="chip danger">
                        ${getTrad("error")}
                    </div>
                </div>`);
      } else if (estadoHAN.isPagado) {
        button = $(`<div class="chipContainer dx-state-disabled">
                    <div class="chip warning">
                        ${getTrad("pagado")}
                    </div>
                </div>`);
      } else if (estadoHAN.isContabilizar) {
        button = $(`<div class="chipContainer">
                    <div class="chip info">
                        ${getTrad("contabilizar")}
                    </div>
                </div>`);
      } else if (estadoHAN.isContabilizado) {
        button = $(`<div class="chipContainer">
                    <div class="chip success">
                        ${getTrad("contabilizado")}
                    </div>
                </div>`);
      }

      let element = $(cellElement[0]);
      element.empty();
      element.css("padding", "0");
      element.append(button.clone(false));
    }

    if (
      column.dataField === "idCentroElem" &&
      rowType === "group" &&
      typeof value === "string"
    ) {
      cellElement.first().addClass("sticky-grid-row-header p-0");
      let div = $("<div>");
      div.addClass("p-2");
      div.append(cellElement.text());
      cellElement.empty().append(div);
    }

    if (
      ["group", "data"].includes(rowType) &&
      columnNames_historicoAsientoNomina.includes(
        column?.dataField ?? column?.name
      )
    ) {
      this.toolTip(e);
    }
  };

  getSigueIgual = (items, data) => {
    const columnName = "liquidoPercibir";

    const idElementoPEP = items?.[0]?.idElementoPEP;
    const idCentroCoste = items?.[0]?.idCentroCoste;

    const nominasSinRetencionSinPtePago = items.filter(
      (n) => !n.isRetenida && n.idEstadoHistoricoAsientoNomina
    );

    const valueActual =
      Math.round(
        (nominasSinRetencionSinPtePago.reduce(
          (acc, n) => acc + (n[columnName] ?? 0),
          0
        ) ?? 0) * 100
      ) / 100;

    const dataSource = this.getDataSource(
      idElementoPEP,
      idCentroCoste,
      true,
      columnName,
      data
    );

    const ultimoRegistro = dataSource?.[dataSource.length - 1];

    return (
      ultimoRegistro?.actual === undefined ||
      Math.round(ultimoRegistro.actual * 100) / 100 === valueActual
    );
  };

  getDataSource = (idElementoPEP, idCentroCoste, isGroup, columnName, data) => {
    const { tblHistoricoAsientoNomina } = this.props;

    const formatedData = query(tblHistoricoAsientoNomina)
      .filter((han) =>
        isGroup
          ? han.idAdmElementoPEP === idElementoPEP &&
            han.idAdmCentroCoste === idCentroCoste
          : han.idNomina === data.idNomina
      )
      .sortBy((han) => han.fechaContabilizado)
      .toArray();

    let dataSource = isGroup
      ? query(formatedData)
          .groupBy((han) => {
            const idEstadoHistoricoAsientoNominaOriginal =
              han.idEstadoHistoricoAsientoNomina;

            const idEstadoHistoricoAsientoNomina = [
              idsEstadoHistoricoAsientoNomina.pagado,
              idsEstadoHistoricoAsientoNomina.anticipo,
            ].includes(idEstadoHistoricoAsientoNominaOriginal)
              ? idsEstadoHistoricoAsientoNomina.pagado
              : idEstadoHistoricoAsientoNominaOriginal;

            return [
              han.fechaContabilizado,
              idEstadoHistoricoAsientoNominaOriginal,
              idEstadoHistoricoAsientoNomina,
              han.idAdmElementoPEP,
              han.idAdmCentroCoste,
            ];
          })
          .select((han) => {
            const fechaContabilizado = han.key[0];
            const idEstadoHistoricoAsientoNominaOriginal = han.key[1];
            const idEstadoHistoricoAsientoNomina = han.key[2];

            const columnSUM = Object.values(
              formatedData
                .filter((fd) => {
                  const calculatedIdEstadoHistoricoAsientoNomina = [
                    idsEstadoHistoricoAsientoNomina.pagado,
                    idsEstadoHistoricoAsientoNomina.anticipo,
                  ].includes(fd.idEstadoHistoricoAsientoNomina)
                    ? idsEstadoHistoricoAsientoNomina.pagado
                    : fd.idEstadoHistoricoAsientoNomina;

                  if (fd.fechaContabilizado < fechaContabilizado) {
                    return true;
                  }

                  if (
                    fd.fechaContabilizado === fechaContabilizado &&
                    idEstadoHistoricoAsientoNomina ===
                      calculatedIdEstadoHistoricoAsientoNomina
                  ) {
                    return true;
                  }

                  return false;
                })
                .reduce((acc, item) => {
                  const idEstadoHistoricoAsientoNomina = [
                    idsEstadoHistoricoAsientoNomina.pagado,
                    idsEstadoHistoricoAsientoNomina.anticipo,
                  ].includes(item.idEstadoHistoricoAsientoNomina)
                    ? idsEstadoHistoricoAsientoNomina.pagado
                    : item.idEstadoHistoricoAsientoNomina;

                  const key = `${item.idNomina}-${idEstadoHistoricoAsientoNomina}`;

                  if (
                    !acc[key] ||
                    acc[key].fechaContabilizado < item.fechaContabilizado ||
                    (acc[key].fechaContabilizado === item.fechaContabilizado &&
                      acc[key].idEstadoHistoricoAsientoNomina <=
                        item.idEstadoHistoricoAsientoNomina)
                  ) {
                    acc[key] = item;
                  }

                  return acc;
                }, {})
            ).reduce((acc, item) => acc + (item[columnName] ?? 0), 0);

            const actual = Math.round(columnSUM * 100) / 100;

            const ultimoAcumulado = isGroup ? columnSUM : null;

            return {
              actual,
              ultimoAcumulado,
              fechaContabilizado: fechaContabilizado,
              estado: this.acciones[idEstadoHistoricoAsientoNominaOriginal],
              idEstadoHistoricoAsientoNomina,
            };
          })
          .toArray()
      : formatedData.map((han) => ({
          actual: han[columnName],
          ultimoAcumulado: null,
          fechaContabilizado: han.fechaContabilizado,
          estado: this.acciones[han.idEstadoHistoricoAsientoNomina],
          idEstadoHistoricoAsientoNomina: han.idEstadoHistoricoAsientoNomina,
        }));

    dataSource = dataSource.map((han, index) => {
      han.anterior = index === 0 ? "-" : dataSource[index - 1].actual;
      han.diferencia =
        typeof han.anterior === "number" ? han.actual - han.anterior : 0;

      if (
        isGroup &&
        dataSource?.[index - 1] &&
        han.idEstadoHistoricoAsientoNomina ===
          idsEstadoHistoricoAsientoNomina.pagado &&
        han.idEstadoHistoricoAsientoNomina ===
          dataSource[index - 1].idEstadoHistoricoAsientoNomina
      ) {
        han.actual = han.ultimoAcumulado;
        han.diferencia =
          typeof han.anterior === "number" ? han.actual - han.anterior : 0;
      }

      return han;
    });

    return dataSource;
  };

  toolTip = ({
    column,
    summaryItems,
    rowType,
    value,
    cellElement,
    data,
    key,
  }) => {
    const items = query(this.props.dataSource)
      .filter((n) => n.idCentroElem === key[0])
      .toArray();

    const isGroup = rowType === "group";
    const columnName = column.dataField ?? column.name;
    const idElementoPEP = items?.[0]?.idElementoPEP;
    const idCentroCoste = items?.[0]?.idCentroCoste;
    const valueActual =
      Math.round(
        (isGroup
          ? summaryItems[0].value ?? 0
          : data[columnName] ?? value ?? 0) * 100
      ) / 100;

    const dataSource = this.getDataSource(
      idElementoPEP,
      idCentroCoste,
      isGroup,
      columnName,
      data
    );

    if (dataSource.length > 0) {
      const lastValueSended =
        Math.round(dataSource[dataSource.length - 1].actual * 100) / 100;

      if (lastValueSended < valueActual) {
        cellElement.addClass("text-success font-weight-bold");
        cellElement.children().addClass("text-success font-weight-bold");
      } else if (lastValueSended > valueActual) {
        cellElement.addClass("text-danger font-weight-bold");
        cellElement.children().addClass("text-danger font-weight-bold");
      }
    }

    if (
      dataSource.length > 0 ||
      (columnName === "descuentosSalariales" && !isGroup)
    ) {
      cellElement.append(
        $("<div />").dxTooltip({
          target: cellElement,
          position: "top",
          showEvent: {
            name: "dxhoverstart",
            delay: 200,
          },
          hideEvent: "dxhoverend",
          contentTemplate: (contentElement) => {
            const tooltipContainer = $('<div style="max-width: 550px;"></div>');
            contentElement.append(tooltipContainer);

            if (columnName === "descuentosSalariales" && !isGroup) {
              tooltipContainer.append(
                $("<div />")
                  .addClass("container_spanCentrado")
                  .append(
                    $("<div />")
                      .addClass(
                        "flex-1 text-left color-secondary font-weight-bold"
                      )
                      .text(getTrad("faltas")),
                    $("<div />")
                      .addClass("p-1 pl-2 pr-2 ml-2")
                      .text(
                        formatNumber(
                          data?.absentismo ?? 0,
                          this.currency_format.maximumFractionDigits,
                          this.currency_format.style,
                          this.currency_format.currency
                        )
                      )
                  ),
                $("<div />")
                  .addClass("container_spanCentrado")
                  .append(
                    $("<div />")
                      .addClass(
                        "flex-1 text-left color-secondary font-weight-bold"
                      )
                      .text(getTrad("anticipos")),
                    $("<div />")
                      .addClass("p-1 pl-2 pr-2 ml-2")
                      .text(
                        formatNumber(
                          data?.anticipo ?? 0,
                          this.currency_format.maximumFractionDigits,
                          this.currency_format.style,
                          this.currency_format.currency
                        )
                      )
                  )
              );
            }

            if (dataSource.length > 0) {
              const ultimoAsiento = dataSource[dataSource.length - 1];

              const dataGridProps = () => ({
                showRowLines: true,
                columnAutoWidth: true,
                hoverStateEnabled: true,
                rowAlternationEnabled: true,
                showColumnLines: false,
                sorting: { mode: "none" },
              });

              const numberColumn = (field) => ({
                dataField: field,
                caption: getTrad(field),
                dataType: "number",
                alignment: "center",
                format: this.currency_format,
              });

              const columns = {
                fechaContabilizado: {
                  dataField: "fechaContabilizado",
                  caption: getTrad("fecha"),
                  dataType: "date",
                  format: "dd/MM/yyyy",
                  sortOrder: "asc",
                },
              };

              const dataSource_ultimoAsiento = [
                {
                  guardado: ultimoAsiento.actual,
                  actual: valueActual,
                  diferencia: valueActual - ultimoAsiento.actual,
                },
              ];

              tooltipContainer.append(
                $(
                  "<div className='container_spanCentrado w-100' style='margin-bottom: 10px' />"
                ).dxDataGrid({
                  dataSource: dataSource_ultimoAsiento,
                  width: "100%",
                  ...dataGridProps(),
                  columns: [
                    { ...numberColumn("guardado"), width: "33%" },
                    { ...numberColumn("actual"), width: "33%" },
                    { ...numberColumn("diferencia"), width: "33%" },
                  ],
                  onCellPrepared: (e) => {
                    if (
                      e.rowType === "data" &&
                      e.column.dataField === "diferencia"
                    ) {
                      if (e.value > 0) e.cellElement.addClass("text-success");
                      else if (e.value < 0)
                        e.cellElement.addClass("text-danger");
                    }
                  },
                })
              );

              tooltipContainer.append(
                $("<div />").dxDataGrid({
                  dataSource,
                  ...dataGridProps(),
                  width: "100%",
                  onToolbarPreparing: (e) => {
                    e.toolbarOptions.items.unshift({
                      location: "before",
                      text: getTrad("historicoAsientos"),
                    });
                  },
                  onCellPrepared: (e) => {
                    if (
                      e.rowType === "data" &&
                      e.column.dataField === "diferencia"
                    ) {
                      if (e.value > 0) e.cellElement.addClass("text-success");
                      else if (e.value < 0)
                        e.cellElement.addClass("text-danger");
                    }
                  },
                  columns: [
                    columns.fechaContabilizado,
                    {
                      dataField: "estado",
                      caption: getTrad("estado"),
                      width: 100,
                    },
                    { ...numberColumn("anterior"), width: 120 },
                    { ...numberColumn("actual"), width: 120 },
                    { ...numberColumn("diferencia"), width: 120 },
                  ],
                })
              );
            }
          },
        })
      );
    }
  };

  isEditing = false;
  dxDataGrid_onCellClick = (e) => {
    const { isLoading } = this.props;

    const {
      component,
      key,
      row,
      column,
      columnIndex,
      text,
      event,
      data,
      rowType,
    } = e;

    if (isLoading) return;

    if (column && column.dataField === "advertencias") {
      return;
    } else if (
      column &&
      column.dataField === "errores" &&
      rowType === "group"
    ) {
      const items = data.items ?? data.collapsedItems;

      const nominasSinRetencion = items.filter((n) => !n.isRetenida);

      const idEstadoNomina = Math.min(
        ...nominasSinRetencion.map((x) => x.idEstadoNomina)
      );

      const sigueIgual = this.getSigueIgual(items, data);

      const estadoHAN = this.getEstadoHistoricoAsientoNomina(items, sigueIgual);

      if (
        idEstadoNomina < idsEstadoNomina.validadoGestoria ||
        data.aggregates.at(17) ||
        nominasSinRetencion.some((x) => x.hasConceptosGestoria) ||
        estadoHAN.isPagado
      ) {
        return;
      }

      const { fechaSel, load_dataSource } = this.props;
      let element = $(event.target);

      this.setState({
        popupVisible: true,
        idAccionARealizar:
          !estadoHAN.isContabilizar && !estadoHAN.isContabilizado
            ? idsEstadoHistoricoAsientoNomina.pagado
            : idsEstadoHistoricoAsientoNomina.contabilizado,
        idElementoPEPSel: (data.items ?? data.collapsedItems).at(0)
          .idElementoPEP,
        idCentroCosteSel: (data.items ?? data.collapsedItems).at(0)
          .idCentroCoste,
        fechaAsiento:
          new Date() > fechaSel.endDate ? fechaSel.endDate : new Date(),
        context_asinetosNominas_then: (response) => {
          element.addClass("dx-state-disabled");
          if (response) {
            this.defaultNotify(false, "aviso_C_RegistroInsertado");

            this.dxPopup_onHiding();
            load_dataSource();
          } else {
            this.defaultNotify(true, "aviso_I_RegistroNoInsertado");
          }
          this.setState({ isSending: false });
        },
      });
    } else if (
      column &&
      column.dataField === "errores" &&
      rowType === "data" &&
      data.isPendientePagar &&
      !data.isRetenida
    ) {
      const { fechaSel, load_dataSource } = this.props;

      this.setState({
        popupVisible: true,
        idAccionARealizar: idsEstadoHistoricoAsientoNomina.pagado,
        idNominaSel: data.idNomina,
        fechaAsiento:
          new Date() > fechaSel.endDate ? fechaSel.endDate : new Date(),
        context_asinetosNominas_then: (response) => {
          if (response) {
            this.defaultNotify(false, "aviso_C_RegistroInsertado");

            this.dxPopup_onHiding();
            load_dataSource();
          } else {
            this.defaultNotify(true, "aviso_I_RegistroNoInsertado");
          }
          this.setState({ isSending: false });
        },
      });
    } else if (
      column &&
      column.dataField === "errores" &&
      rowType === "data" &&
      data.isPendienteAnticipo &&
      !data.isRetenida
    ) {
      const { fechaSel, load_dataSource } = this.props;

      this.setState({
        popupVisible: true,
        idAccionARealizar: idsEstadoHistoricoAsientoNomina.anticipo,
        idNominaSel: data.idNomina,
        fechaAsiento: new Date(
          fechaSel.startDate.getFullYear(),
          fechaSel.startDate.getMonth() + 1,
          fechaSel.startDate.getDate()
        ),
        context_asinetosNominas_then: (response) => {
          if (response) {
            this.defaultNotify(false, "aviso_C_RegistroInsertado");

            this.dxPopup_onHiding();
            load_dataSource();
          } else {
            this.defaultNotify(true, "aviso_I_RegistroNoInsertado");
          }
          this.setState({ isSending: false });
        },
      });
    } else if (
      column &&
      column.name == "editCell" &&
      data &&
      data.idPersona != undefined
    ) {
      let idPersona = data.idPersona;
      let grid = this.PersonalGeneral_REF.dxDataGrid_personas;

      if (grid.getRowIndexByKey(idPersona) === 0) {
        grid.editRow(0);
      } else {
        this.isEditing = true;
        grid.navigateToRow(idPersona);
      }
    } else if (rowType === "group" && (columnIndex !== 0 || text !== "")) {
      if (row.isExpanded) {
        component.collapseRow(key);
      } else {
        component.expandRow(key);
      }
    }
  };

  getEstadoHistoricoAsientoNomina = (items, sigueIgual) => {
    if (!sigueIgual) {
      return {
        isPagado: true,
        isContabilizar: false,
        isContabilizado: false,
      };
    }

    const isPagado =
      items.some((item) => !item.idEstadoHistoricoAsientoNomina) &&
      items.some(
        (item) =>
          item.idEstadoHistoricoAsientoNomina &&
          item.idEstadoHistoricoAsientoNomina <=
            idsEstadoHistoricoAsientoNomina.anticipo
      );

    const isContabilizar =
      !isPagado &&
      items.every(
        (item) =>
          item.idEstadoHistoricoAsientoNomina &&
          item.idEstadoHistoricoAsientoNomina <=
            idsEstadoHistoricoAsientoNomina.anticipo
      );

    const isContabilizado =
      !isPagado &&
      !isContabilizar &&
      items.every(
        (item) =>
          item.idEstadoHistoricoAsientoNomina &&
          item.idEstadoHistoricoAsientoNomina >=
            idsEstadoHistoricoAsientoNomina.contabilizado
      );

    return {
      isPagado,
      isContabilizar,
      isContabilizado,
    };
  };

  dxDataGrid_groupCellTemplate = (cellElement, cellInfo) => {
    cellElement.text(cellInfo.text);
  };

  dxDataGrid_calculateCellValue_errores = (data) => {
    return data.isRetenida
      ? 0
      : (
          data.salarioBruto -
          (data.conceptoNEspecie +
            data.absentismo +
            data.tributacionIRPF +
            data.segSocialTrabajador +
            data.liquidoPercibir +
            data.embargo +
            data.anticipo +
            data.descuentoPreaviso)
        ).toFixed(2) * 1;
  };

  // #endregion

  // #region Popup

  dxPopup_onHiding = () => this.setState({ popupVisible: false });

  dxPopup_onHidden = () =>
    this.setState({
      idNominaSel: null,
      idElementoPEPSel: null,
      idCentroCosteSel: null,
      fechaAsiento: null,
      context_asinetosNominas_then: null,
    });

  dxDateBox_onValueChanged = (e) => this.setState({ fechaAsiento: e.value });

  dxToolbarItem_onClick = (e) => {
    const { idAccionARealizar, idNominaSel } = this.state;

    this.setState({ isSending: true });

    const onCatch = () => {
      this.defaultNotify(true, "aviso_I_RegistroNoInsertado");

      this.setState({ isSending: false });
    };

    if (idAccionARealizar === idsEstadoHistoricoAsientoNomina.pagado) {
      this.context_asinetosNominas
        .invoke(
          idNominaSel
            ? "GenerarHistoricoAsientoNomina"
            : "GenerarAsientosNominasSAP",
          {},
          "POST"
        )
        .then(this.state.context_asinetosNominas_then)
        .catch(onCatch);
    } else if (idAccionARealizar === idsEstadoHistoricoAsientoNomina.anticipo) {
      this.context_asinetosNominas
        .invoke("GenerarAnticipo", {}, "POST")
        .then(this.state.context_asinetosNominas_then)
        .catch(onCatch);
    } else if (
      idAccionARealizar === idsEstadoHistoricoAsientoNomina.contabilizado
    ) {
      this.context_asinetosNominas
        .invoke("GenerarAsientosNominasSAP", {}, "POST")
        .then(this.state.context_asinetosNominas_then)
        .catch(onCatch);
    } else {
      onCatch();
    }
  };

  // #endregion

  personalGeneral_onContentReady = (e) => {
    if (e.component.pageSize() > 1) {
      e.component.pageSize(1);
    }

    if (this.isEditing) {
      this.isEditing = false;
      e.component.editRow(0);
    }
  };

  componentDidMount() {
    const { load_dataSource } = this.props;

    this.PersonalGeneral_REF.dxDataGrid_personas.option(
      "onContentReady",
      this.personalGeneral_onContentReady
    );
    this.PersonalGeneral_REF.datasource_persona
      .store()
      .on("modified", load_dataSource);
    this.store_tblTipoTrabajo
      .load()
      .done((tblTipoTrabajo) => this.setState({ tblTipoTrabajo }));
    this.store_tblCentroCoste_ElementoPEP
      .load()
      .done((tblCentroCoste_ElementoPEP) =>
        this.setState({ tblCentroCoste_ElementoPEP })
      );
  }

  componentDidUpdate(prevProps) {
    const { empresa, fechaSel } = this.props;

    if (
      empresa?.idEmpresaPolarier !== prevProps.empresa?.idEmpresaPolarier ||
      fechaSel !== prevProps.fechaSel
    ) {
      this.store_tblTipoTrabajo
        .load()
        .done((tblTipoTrabajo) => this.setState({ tblTipoTrabajo }));
      this.store_tblCentroCoste_ElementoPEP
        .load()
        .done((tblCentroCoste_ElementoPEP) =>
          this.setState({ tblCentroCoste_ElementoPEP })
        );
    }
  }
}

const mapStateToProps = (state) => ({
  empresa: state.Global.empresa,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  DataGrid_ContabilidadNominas
);
