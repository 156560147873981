import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatDate,
  dxMensajePregunta,
  formatNumber,
  convertClienteUtcToLavanderiaUtc,
  startOfMonth,
  tooltipControl_creacion,
  patchRequestHandler,
} from "../../../helpers";
import { svg_warning, svg_lock_reset } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(
  COMPONENT,
  LAVANDERIA,
  IDIOMA,
  onShow_dxPopup_grafica,
  onShow_dxPopup_exportar,
) {
  var deferred = $.Deferred();

  var datasource_selectFechaLectura;
  var datasource_tblCalendarioLavanderia;
  var fechaSel;
  var recursoContaSel = null;

  //#region Datasource
  var datasource_controlContador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "lecturaRecursos_spSelectControlContador",
      key: "idRecursoContador",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          //PATCH
          var idRecursoContador = request.url.substring(
            request.url.indexOf("(") + 1,
            request.url.lastIndexOf(")"),
          );
          request.url =
            connectionConstants.WEB_API_CORE_ODATA_URL +
            "tblControlContador/" +
            idRecursoContador;
        } else {
          //SELECT
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
        }
        request.params.fecha = formatDate_parameter(fechaSel);
      },
      version: 4,
    }),
    postProcess: function (data) {
      $.each(data, function (index1, grupo1) {
        $.each(grupo1.items, function (index2, grupo2) {
          $.each(grupo2.items, function (index, item) {
            item.anterior = !item.anterior ? 0 : item.anterior;
          });
        });
      });
      return data;
    },
  });

  datasource_selectFechaLectura = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "lecturaRecursos_spSelectUltimaFechaLectura",
      key: "primerRegistro",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  datasource_tblCalendarioLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var fechasArray = [];
      $.each(data, function (index, item) {
        fechasArray.push(item.fecha);
      });
      return fechasArray;
    },
    filter: "idEstado eq 10", // Cierre lavandería
    select: ["fecha"],
  });

  //#endregion

  //#region Controles

  $("#LecturaRecursos #dxContainer").dxBox({
    direction: "col",
    height: "100%",
  });
  $("#LecturaRecursos #dxBox_Tits").dxBox({
    direction: "row",
    height: "100%",
  });
  $("#LecturaRecursos #dxBox_Grids").dxBox({
    direction: "row",
    height: "100%",
  });

  if (typeof $("#dxDateBox_fechaSel").data("dxDateBox") !== "undefined") {
    $("#dxDateBox_fechaSel").dxDateBox("instance").dispose();
  }

  $("#dxDateBox_fechaSel").dxDateBox({
    width: 250,
    height: 36,
    displayFormat: "dd/MM/yyyy",
    onValueChanged: function (e) {
      if (
        typeof fechaSel === "undefined" ||
        formatDate(fechaSel) !== formatDate(e.value)
      ) {
        var dxDataGrid_RecursosContador = $(
          "#dxDataGrid_RecursosContador",
        ).dxDataGrid("instance");

        var hasChangesContador = dxDataGrid_RecursosContador
          .getController("editing")
          .hasChanges();

        if (hasChangesContador === true) {
          dxMensajePregunta(getTrad("preg_PerderCambios"), [
            [
              getTrad("aceptar"),
              function () {
                fechaSel = e.value;
                dxDataGrid_RecursosContador.cancelEditData();
                dxDataGrid_RecursosContador.refresh();
              },
              "normal",
              "btnAceptar_alert",
            ],
            [
              getTrad("cancelar"),
              function () {
                e.component.option("value", e.previousValue);
              },
              "normal",
              "btnCancelar_alert",
            ],
          ]);
        } else {
          fechaSel = e.value;
          if (!dxDataGrid_RecursosContador.getDataSource()) {
            dxDataGrid_RecursosContador.option(
              "dataSource",
              datasource_controlContador,
            );
          } else {
            dxDataGrid_RecursosContador.refresh();
          }
        }
      }
    },
  });

  setRestriccionFecha();

  if (
    typeof $("#LecturaRecursos #dxDataGrid_RecursosContador").data(
      "dxDataGrid",
    ) !== "undefined"
  ) {
    $("#LecturaRecursos #dxDataGrid_RecursosContador")
      .dxDataGrid("instance")
      .dispose();
  }

  let ultimaActualizacion = new Date();

  $("#LecturaRecursos #dxDataGrid_RecursosContador").dxDataGrid({
    paging: false,
    remoteOperations: true,
    //Propiedades
    height: "95%",
    columnAutoWidth: true,
    editing: {
      mode: "batch",
      allowUpdating: true,
      selectTextOnEditStart: true,
    },
    keyboardNavigation: {
      enterKeyAction: "moveFocus",
      enterKeyDirection: "row",
      editOnKeyPress: true,
    },
    grouping: {
      autoExpandAll: true,
      allowCollapsing: false,
    },
    groupPanel: {
      visible: false,
    },
    onRowPrepared: function (e) {
      if (e.rowType === "group") {
        if (e.groupIndex == 0) {
          e.rowElement.css("backgroundColor", "var(--secondary)");
          e.rowElement.css("color", "white");
        }
        if (e.groupIndex == 1) {
          const idGrupoEnergetico = e.data.items[0]?.idGrupoEnergetico;
          const bgColor =
            idGrupoEnergetico === 1
              ? "var(--blue)"
              : idGrupoEnergetico === 2
                ? "var(--primary)"
                : "var(--orange)";

          e.rowElement.css("backgroundColor", bgColor);
          e.rowElement.css("color", "white");
        }
      }
    },
    onRowUpdating: function (e) {
      e.newData.diferencia = e.component.cellValue(
        e.component.getRowIndexByKey(e.key),
        "diferencia",
      );
      e.newData = patchRequestHandler(e.newData);
    },
    columns: [
      {
        type: "groupExpand",
        width: 5,
      },
      {
        dataField: "idRecursoContador",
        visible: false,
      },
      {
        dataField: "isAutomatico",
        caption: "",
        width: 0,
        groupIndex: 0,
        groupCellTemplate: function (cellElement, cellInfo) {
          switch (cellInfo.data.key) {
            case true:
              cellElement.text(getTrad("automatico"));
              break;
            case false:
              cellElement.text(getTrad("manual"));
              break;
            default:
              break;
          }
        },
      },
      {
        dataField: "denoTipoRecurso",
        caption: "",
        groupIndex: 0,
        groupCellTemplate: function (cellElement, cellInfo) {
          const idGrupoEnergetico = cellInfo.data.items[0].idGrupoEnergetico;
          cellElement.append(
            $("<div />")
              .addClass("container_spanCentrado justify-content-start")
              .append(
                $("<div/>").addClass(
                  "font-size-xl white " +
                    (idGrupoEnergetico === 1
                      ? "icon_Agua"
                      : idGrupoEnergetico === 2
                        ? "icon_Electricidad"
                        : "icon_Calefaccion"),
                ),
              )
              .append($("<span/>").text(cellInfo.value)),
          );
        },
      },
      {
        dataField: "sumaInforme",
        caption: "",
        width: 35,
        cssClass: "p-0",
        sortOrder: "desc",
        cellTemplate: function (cellElement, cellInfo) {
          if (cellInfo.value == true) {
            cellElement.append(
              $("<div />").addClass(
                "pl-3 font-size-lg dx-icon dx-icon-favorites primary",
              ),
            );
          }
        },
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        minWidth: 80,
        sortOrder: "asc",
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
      },
      {
        dataField: "idCategoriaRecurso",
        caption: getTrad("categoria"),
        alignment: "center",
        cellTemplate: function (cellElement, cellInfo) {
          switch (cellInfo.data.idCategoriaRecurso) {
            case 1:
              cellElement.text(getTrad("lavanderia"));
              break;
            case 2:
              cellElement.text(getTrad("valet"));
              break;
            case 3:
              cellElement.text(getTrad("general"));
              break;
            default:
              break;
          }
        },
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
      },
      {
        dataField: "anterior",
        caption: getTrad("anterior"),
        width: 200,
        alignment: "center",
        format: { style: "decimal", minimumFractionDigits: 2 },
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
      },
      {
        dataField: "actual",
        width: 200,
        caption: getTrad("actual"),
        alignment: "center",
        format: { style: "decimal", minimumFractionDigits: 2 },
        cssClass: "dx-Cell_Editable",
        editorType: "dxNumberBox",
        cellTemplate: function (container, options) {
          var contenedorImg = $(
            "<div id =iconoWarningContador" +
              options.data.idRecursoContador +
              " />",
          )
            .css("float", "right")
            .css("width", "16px")
            .css("display", "none")
            .css("height", "20px")
            .appendTo(container);

          var iconoWarningContador = svg_warning.clone();

          iconoWarningContador.css("height", "20px").css("width", "20px");
          iconoWarningContador.appendTo(contenedorImg);

          var texto = $("<div/>").text(
            formatNumber(options.data.actual, 2, "decimal"),
          );

          texto.appendTo(container);

          return container;
        },
        editCellTemplate: function (container, options) {
          $("<div/>")
            .dxNumberBox({
              format: { style: "decimal", minimumFractionDigits: 2 },
              placeholder: "0,00",
              step: 0,
              min: 0,
              max: 999999999,
              value: options.data.actual,
              disabled: options.data.isAutomatico,
              onValueChanged: function (e) {
                options.setValue(e.value);
              },
            })
            .appendTo(container);

          if (options.data.isAutomatico) {
            var htmlTooltip =
              "<p>" +
              getTrad("alerta_contadoresAutomaticosNoEditables") +
              "</p>";
            tooltipControl_creacion(container, htmlTooltip);
          }
        },
        validationRules: [
          {
            type: "custom",
            reevaluate: true,
            validationCallback: function (e) {
              var rowOptions = e.validator.element().parent().data("options");

              if (e.data.isAutomatico) {
                return true;
              }
              var isValid = validation_margenLectura(rowOptions.data);

              if (isValid === true) {
                $("#iconoWarningContador" + e.data.idRecursoContador).hide();
              } else {
                $("#iconoWarningContador" + e.data.idRecursoContador).show();
              }
              return isValid;
            },
            message: getTrad("alerta_margenMaximoPermitido"),
          },
        ],
      },
      {
        caption: getTrad("diferencia"),
        name: "diferencia",
        width: 200,
        format: { style: "decimal", minimumFractionDigits: 2 },
        alignment: "center",
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
        calculateCellValue: function (rowData) {
          if (!rowData.actual || rowData.actual === 0) {
            return 0;
          } else {
            var anterior = 0;
            var actual = 0;

            anterior = typeof (rowData.anterior !== "undefined")
              ? rowData.anterior
              : 0;
            actual = typeof (rowData.actual !== "undefined")
              ? rowData.actual
              : 0;

            if (rowData.finalContador) {
              return (
                rowData.finalContador -
                anterior +
                (actual - rowData.inicioNuevoContador)
              );
            }
            return actual - anterior;
          }
        },
      },
      {
        caption: getTrad("finContador"),
        width: 90,
        alignment: "center",
        cssClass: "p-0",
        cellTemplate: function (container, options) {
          let isAutomatico = options.row.data.isAutomatico;
          if (!isAutomatico) {
            container.append(
              svg_lock_reset
                .clone()
                .css({
                  height: 24,
                  width: 24,
                  cursor: "pointer",
                  "vertical-align": "middle",
                })
                .on("click", function () {
                  recursoContaSel = options.row.data;
                  var dxPopup_finContador = $("#dxPopup_finContador").dxPopup(
                      "instance",
                    ),
                    datasource_formFinalContador = {
                      idRecursoContador: recursoContaSel.idRecursoContador,
                      finalContador: recursoContaSel.finalContador,
                      inicioNuevoContador: recursoContaSel.inicioNuevoContador,
                      actual: recursoContaSel.actual,
                      anterior: recursoContaSel.anterior,
                      margenLectura: recursoContaSel.margenLectura,
                    };

                  $("#dxForm_finalContador")
                    .dxForm("instance")
                    .option("formData", datasource_formFinalContador);

                  dxPopup_finContador.option(
                    "title",
                    recursoContaSel.denominacion,
                  );
                  dxPopup_finContador.show();
                }),
            );
          }
        },
      },
      {
        dataField: "ultimoRegistroEnergyHub",
        caption: getTrad("lecturaActual"),
        width: 200,
        alignment: "center",
        format: { style: "decimal", minimumFractionDigits: 2 },
        allowEditing: false,
        allowHeaderFiltering: false,
        allowUpdating: false,
      },
      {
        caption: "",
        width: 40,
        alignment: "center",
        cssClass: "p-0",
        cellTemplate: function (container, options) {
          if (options.row.data.ultimoRegistroEnergyHub) {
            $("<div>")
              .addClass("menu_icons_Informes font-size-xl")
              .css({
                height: 25,
                width: 25,
                cursor: "pointer",
                "vertical-align": "middle",
              })
              .on("click", function () {
                recursoContaSel = options.row.data;
                onShow_dxPopup_grafica(recursoContaSel);
              })
              .appendTo(container);
          }
        },
      },
      {
        datafield: "margenlectura",
        visible: false,
      },
      {
        dataField: "finalContador",
        visible: false,
      },
      {
        dataField: "inicioNuevoContador",
        visible: false,
      },
    ],
    //Eventos
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          template: function () {
            return $(
              "<div class ='font-size' style='margin-top : 10px;'>",
            ).text(getTrad("recursosContador"));
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "always",
          options: {
            icon: "exportxlsx",
            text: getTrad("exportar"),
            onClick: () => onShow_dxPopup_exportar(),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          options: {
            text: getTrad("actualizar"),
            icon: "refresh",
            onClick: () => {
              ultimaActualizacion = new Date();
              $("#LecturaRecursos #dxToolbar_guardarDatos")
                .dxToolbar("instance")
                .repaint();
              datasource_controlContador.reload();
            },
          },
        },
      );
      $.each(e.toolbarOptions.items, function (index, item) {
        var toolbarItems = e.toolbarOptions.items;
        $.each(toolbarItems, function (_, item) {
          if (item.name === "saveButton") {
            item.visible = false;
          }
        });
      });
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });

  $("#LecturaRecursos #dxToolbar_guardarDatos").dxToolbar({
    items: [
      {
        location: "before",
        template: function () {
          return $("<div>")
            .text(
              getTrad("ultimaActualizacion") +
                " " +
                ultimaActualizacion.toLocaleDateString("es-ES", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                }) +
                " - " +
                ultimaActualizacion.toLocaleTimeString("es-ES", {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
            )
            .addClass("dx-toolbar-custom-item");
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("guardar"),
          onClick: function () {
            var dxDataGrid_RecursosContador = $(
              "#dxDataGrid_RecursosContador",
            ).dxDataGrid("instance");
            var arrayRecursos = [];
            var isValid = true;

            //Recursos Contador
            let recursoContadorItems = $.grep(
              dxDataGrid_RecursosContador.getVisibleRows(),
              function (item) {
                return item.rowType == "data";
              },
            );
            $.each(recursoContadorItems, function (index, item) {
              let { rowIndex, data } = item;
              if (data.isAutomatico == false) {
                var dataSource_RecursosContador = $.map(
                  dxDataGrid_RecursosContador.getDataSource().items(),
                  function (item) {
                    return item.items.flatMap((x) => [...x.items]);
                  },
                );

                var actual = dxDataGrid_RecursosContador.cellValue(
                    rowIndex,
                    "actual",
                  )
                    ? dxDataGrid_RecursosContador.cellValue(rowIndex, "actual")
                    : 0,
                  inicioNuevoContador = dxDataGrid_RecursosContador.cellValue(
                    rowIndex,
                    "inicioNuevoContador",
                  ),
                  finalContador = dxDataGrid_RecursosContador.cellValue(
                    rowIndex,
                    "finalContador",
                  );

                var dxDataGridContador_actual = inicioNuevoContador
                  ? actual - inicioNuevoContador
                  : actual;

                var dataSourceContador_anterior = inicioNuevoContador
                  ? dataSource_RecursosContador[index].anterior - finalContador
                  : dataSource_RecursosContador[index].anterior;

                if (
                  dxDataGrid_RecursosContador.cellValue(
                    rowIndex,
                    "denominacion",
                  ) == "AGUA DE RED"
                ) {
                }
                if (inicioNuevoContador != null && finalContador != null) {
                  //CAMBIO DE CONTADOR
                  if (actual - inicioNuevoContador < 0) {
                    isValid = false;
                    arrayRecursos.push(
                      dxDataGrid_RecursosContador.cellValue(
                        item.rowIndex,
                        "denominacion",
                      ),
                    );
                  }
                } else if (
                  dataSourceContador_anterior != null &&
                  (dxDataGridContador_actual - dataSourceContador_anterior <
                    0 ||
                    (dxDataGridContador_actual - dataSourceContador_anterior >
                      dataSource_RecursosContador[index].margenLectura &&
                      dxDataGridContador_actual !== 0) ||
                    (dxDataGridContador_actual === 0 &&
                      dataSourceContador_anterior !== 0))
                ) {
                  isValid = false;
                  arrayRecursos.push(
                    dxDataGrid_RecursosContador.cellValue(
                      item.rowIndex,
                      "denominacion",
                    ),
                  );
                }
              }
            });

            if (isValid === true) {
              $.when(dxDataGrid_RecursosContador.saveEditData()).done(
                function () {
                  setRestriccionFecha();
                  notify({
                    message: getTrad("aviso_C_RegistroInsertado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              );
            } else {
              var texto = "<div id = 'textoMensajeError'><div/>";
              mensajeAlerta_campos(texto, arrayRecursos);
            }
          },
        },
      },
    ],
  });

  $("#dxPopup_finContador").dxPopup({
    showTitle: true,
    height: 425,
    width: 375,
    deferRendering: false,
    contentTemplate: function (e) {
      return $("<div id='dxForm_finalContador'/>").dxForm({
        labelLocation: "top",
        height: "100%",
        isModified: false,
        isValidationEnabled: false, // para validar solo al pulsar botón añadir
        colCount: 1,
        items: [
          {
            dataField: "anterior",
            label: {
              text: getTrad("anterior"),
            },
            editorType: "dxNumberBox",
            editorOptions: {
              format: { style: "decimal", minimumFractionDigits: 2 },
              placeholder: "0,00",
              readOnly: true,
              value: null,
              step: 0,
              min: 0,
              max: 999999999,
            },
          },
          {
            dataField: "finalContador",
            name: "finalContador",
            label: {
              text: getTrad("valorFinalContador"),
            },
            editorType: "dxNumberBox",
            editorOptions: {
              format: { style: "decimal", minimumFractionDigits: 2 },
              showClearButton: true,
              placeholder: "0,00",
              value: null,
              step: 0,
              min: 0,
              max: 999999999,
            },
            validationRules: [
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                    "instance",
                  );
                  var inicioContaHasValue =
                    dxForm_finalContador.option(
                      "formData.inicioNuevoContador",
                    ) !== null
                      ? true
                      : false;

                  if (!dxForm_finalContador.option("isValidationEnabled"))
                    return true;
                  return (
                    e.value !== null ||
                    (e.value === null && !inicioContaHasValue)
                  );
                },
                message: getTrad("valoresInicioFinRellenar"),
              },
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                    "instance",
                  );
                  if (
                    e.value === null ||
                    !dxForm_finalContador.option("isValidationEnabled")
                  )
                    return true;
                  return (
                    e.value >=
                      dxForm_finalContador.option("formData.anterior") &&
                    e.value <=
                      recursoContaSel.margenLectura +
                        dxForm_finalContador.option("formData.anterior")
                  );
                },
                message: getTrad("alerta_margenMaximoPermitido"),
              },
            ],
          },
          {
            dataField: "inicioNuevoContador",
            name: "inicioNuevoContador",
            label: {
              text: getTrad("valorInicioNuevoContador"),
            },
            editorType: "dxNumberBox",
            editorOptions: {
              format: { style: "decimal", minimumFractionDigits: 2 },
              showClearButton: true,
              placeholder: "0,00",
              value: null,
              step: 0,
              min: 0,
              max: 999999999,
            },
            validationRules: [
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                    "instance",
                  );
                  var finalContaHasValue =
                    dxForm_finalContador.option("formData.finalContador") !==
                    null
                      ? true
                      : false;

                  if (!dxForm_finalContador.option("isValidationEnabled"))
                    return true;

                  return (
                    e.value !== null ||
                    (e.value === null && !finalContaHasValue)
                  );
                },
                message: getTrad("valoresInicioFinRellenar"),
              },
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                      "instance",
                    ),
                    anteriorValue =
                      dxForm_finalContador.option("formData.anterior") !== null
                        ? dxForm_finalContador.option("formData.anterior")
                        : 0,
                    finalContValue =
                      dxForm_finalContador.option("formData.finalContador") !==
                      null
                        ? dxForm_finalContador.option("formData.finalContador")
                        : 0,
                    actualValue =
                      dxForm_finalContador.option("formData.actual") !== null
                        ? dxForm_finalContador.option("formData.actual")
                        : 0;

                  if (
                    e.value === null ||
                    !dxForm_finalContador.option("isValidationEnabled")
                  )
                    return true;

                  if (
                    finalContValue - anteriorValue + (actualValue - e.value) <=
                    recursoContaSel.margenLectura
                  )
                    return true;
                  return false;
                },
                message: getTrad("alerta_margenMaximoPermitido"),
              },
            ],
          },
          {
            dataField: "actual",
            name: getTrad("valorActual"),
            label: {
              text: getTrad("valorActual"),
            },
            editorType: "dxNumberBox",
            editorOptions: {
              format: { style: "decimal", minimumFractionDigits: 2 },
              placeholder: "0,00",
              value: null,
              step: 0,
              min: 0,
              max: 999999999,
            },
            validationRules: [
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                    "instance",
                  );
                  var inicioNuevoContador =
                    dxForm_finalContador.option(
                      "formData.inicioNuevoContador",
                    ) !== null
                      ? true
                      : false;

                  if (!dxForm_finalContador.option("isValidationEnabled"))
                    return true;

                  return (
                    e.value !== null ||
                    (e.value === null && !inicioNuevoContador)
                  );
                },
                message: getTrad("valoresInicioFinRellenar"),
              },
              {
                type: "custom",
                reevaluate: true,
                validationCallback: function (e) {
                  var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                    "instance",
                  );
                  //var rowOptions = e.validator.element().parent().data('options');

                  //var formData = $.extend(true, {}, { data: recursoContaSel });

                  if (!dxForm_finalContador.option("isValidationEnabled"))
                    return true;

                  // $.each(["actual", "inicioNuevoContador", "finalContador"], function (index, item) {
                  //     formData.data[item] = dxForm_finalContador.option("formData")[item];
                  // });

                  return validation_margenLectura(
                    dxForm_finalContador.option("formData"),
                  );
                },
                message: getTrad("alerta_margenMaximoPermitido"),
              },
            ],
          },
        ],
        onFieldDataChanged: function (e) {
          if (
            e.dataField === "finalContador" ||
            e.dataField === "inicioNuevoContador"
          )
            e.component.validate();
          if (recursoContaSel && e.value !== recursoContaSel[e.dataField])
            e.component.option("isModified", true);
        },
      });
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("añadir"),
          type: "success",
          onClick: function (e) {
            var dxForm_finalContador = $("#dxForm_finalContador").dxForm(
                "instance",
              ),
              dxDataGrid_RecursosContador = $(
                "#dxDataGrid_RecursosContador",
              ).dxDataGrid("instance");
            dxForm_finalContador.option("isValidationEnabled", true);

            if (dxForm_finalContador.validate().isValid) {
              var rowIndex = dxDataGrid_RecursosContador.getRowIndexByKey(
                recursoContaSel.idRecursoContador,
              );

              $.each(
                ["actual", "finalContador", "inicioNuevoContador"],
                function (index, item) {
                  if (
                    $("#dxForm_finalContador")
                      .dxForm("instance")
                      .option("isModified")
                  )
                    dxDataGrid_RecursosContador.cellValue(
                      rowIndex,
                      item,
                      dxForm_finalContador.option("formData")[item],
                    );
                },
              );

              $("#dxPopup_finContador").dxPopup("instance").hide();
            }
            dxForm_finalContador.option("isValidationEnabled", false);
          },
        },
      },
    ],
    onShowing: function (e) {
      $("#dxForm_finalContador").dxForm("instance").option("isModified", false);
    },
  });

  //#endregion

  //Migrar a utils
  function mensajeAlerta_campos(text, arrayCampos) {
    for (var i = 0; i < arrayCampos.length; i++) {
      text += "- " + arrayCampos[i] + " <br />";
    }
    dxMensajePregunta(
      getTrad("alerta_margenMaximoPermitidoNRecursos") + " <br />" + text,
      [[getTrad("aceptar"), function (e) {}, "danger"]],
    );
  }

  function validation_margenLectura(rowData) {
    if (rowData.actual === null) {
      return true;
    }
    // if (rowData.actual === 0) {
    //     return false;
    // }

    if (
      typeof rowData.inicioNuevoContador !== "undefined" &&
      rowData.inicioNuevoContador != null &&
      rowData.actual < rowData.inicioNuevoContador
    ) {
      return false;
    }

    if (
      typeof rowData.inicioNuevoContador !== "undefined" &&
      rowData.inicioNuevoContador != null &&
      rowData.anterior > rowData.finalContador
    ) {
      return false;
    }

    var actual =
        typeof rowData.inicioNuevoContador !== "undefined"
          ? rowData.actual - rowData.inicioNuevoContador
          : rowData.actual,
      anterior =
        typeof rowData.inicioNuevoContador !== "undefined"
          ? rowData.anterior - rowData.finalContador
          : rowData.anterior;

    if (actual - anterior >= 0 && actual - anterior <= rowData.margenLectura) {
      return true;
    }
    return false;
  }

  function setRestriccionFecha() {
    $.when(
      datasource_selectFechaLectura.load(),
      datasource_tblCalendarioLavanderia.load(),
    ).then(function () {
      var dxDateBox_fechaSel = $("#dxDateBox_fechaSel").dxDateBox("instance"),
        hoy = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()),
        fechaMax = new Date(hoy),
        fechaMin = startOfMonth(
          new Date(new Date(hoy).setMonth(new Date(hoy).getMonth() - 2)),
        ),
        fechaLectura_items = datasource_selectFechaLectura.items();

      if (fechaLectura_items.length > 0) {
        fechaMax = fechaLectura_items[0].ultimoRegistro;
        fechaMin = fechaLectura_items[0].primerRegistro;

        if (fechaMax.getTime() > hoy.getTime()) fechaMax = hoy;
      }
      dxDateBox_fechaSel.option({
        min: fechaMin,
        max: fechaMax,
        value: fechaMax,
        disabledDates: datasource_tblCalendarioLavanderia.items(),
      });
    });
  }

  deferred.resolve();
  return deferred.promise();
}
