import React from "react";
import { SVContext } from "../../components/SmartViewContext";
import { acronym, svgPersona } from "./helpers";
import { connect } from "react-redux";
import { convertClienteUtcToLavanderiaUtc } from "helpers";
import FotoPersona from "components/FotoPersona";

class Persona extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tiempoActivo: null,
        };
    }

    timeFormatHour = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

    render() {
        const { data } = this.props;
        return (
            <SVContext.Consumer>
                {({ setTooltipData }) => {
                    const element = (
                        <div
                            id={`pos-${data.idPersona}`}
                            className="foto"
                            // style={{ background: `url(${fotoPersona})` }}
                        >
                            <FotoPersona
                                idPersona={data.idPersona}
                                placeholder={
                                    <div className="foto sin-foto d-flex flex-column justify-content-center">
                                        <span>{acronym(data.nombre).substring(0, 3)}</span>
                                    </div>
                                }
                            />
                        </div>
                    );
                    return (
                        <div
                            className="posicion p-2"
                            onMouseEnter={(e) => setTooltipData(e.target, <this.tooltipContent src={element} />)}
                            onMouseLeave={(e) => setTooltipData()}
                        >
                            {element}
                        </div>
                    );
                }}
            </SVContext.Consumer>
        );
    }

    tooltipContent = ({ src }) => {
        const { data } = this.props;
        let fechaIni = new Date(data.fechaIni);
        return (
            <div className="p-3 tooltipPersona font-size-xs">
                <span className="nombrePersona">{data.nombre}</span>
                {src}
                <SVContext.Consumer>
                    {({ fechaLavanderia }) => {
                        const tiempoActivo = new Date(fechaLavanderia - fechaIni);
                        return (
                            !Number.isNaN(tiempoActivo.getTime()) && (
                                <div
                                    className={`d-flex align-content-center timer p-2`} /* style={{backgroundColor: getColor_porcentaje(ptgActual)}} */
                                >
                                    <i className="icon_timer mr-2 d-flex align-items-center" />
                                    <span>
                                        {tiempoActivo.getUTCHours() > 0 && tiempoActivo.getUTCHours() + ":"}
                                        {tiempoActivo.getUTCMinutes() > 9
                                            ? tiempoActivo.getUTCMinutes() + ":"
                                            : "0" + tiempoActivo.getUTCMinutes() + ":"}
                                        {tiempoActivo.getUTCSeconds() > 9
                                            ? tiempoActivo.getUTCSeconds()
                                            : "0" + tiempoActivo.getUTCSeconds()}
                                    </span>
                                </div>
                            )
                        );
                    }}
                </SVContext.Consumer>
            </div>
        );
    };
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(Persona);
