import React from "react";

//Componentes
import { EmojiRating } from "../../EmojiRaging/EmojiRating";

//Css
import "../css.scss";

class PreguntaCaritas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valorRango: 0,
    };
  }

  setCarita = (valor) => {
    this.setState({ valorRango: valor }, () => {
      let { onValueChange, respuestas, dataSource } = this.props;
      const resp = { idPregunta: dataSource.idPregunta, valor };
      const hayRespuesta = respuestas.find(
        ({ idPregunta }) => idPregunta == dataSource.idPregunta,
      );

      if (hayRespuesta) {
        respuestas.find(
          ({ idPregunta }) => idPregunta == dataSource.idPregunta,
        ).valor = valor;
      } else {
        respuestas.push(resp);
      }

      onValueChange({ respuestasUsuario: respuestas });
    });
  };

  render() {
    const { valorRango } = this.state;

    return (
      <div className="contentPosition_spaceAround ">
        <EmojiRating
          value={valorRango}
          onClick_selection={this.setCarita}
          size={"5rem"}
          spaceIcons={3}
        />
      </div>
    );
  }
}

export default PreguntaCaritas;
