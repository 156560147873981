import React from "react";

import DataGrid, {
  Column,
  Editing,
  KeyboardNavigation,
  Sorting,
} from "devextreme-react/data-grid";

import { formatNumber, getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { useRecambiosProvider } from "pages/AssistantNew/Recambios/components/RecambiosProvider";

const editorOptions = {
  step: 0,
  min: 0,
  max: 9999999.99,
};

const calculateDisplayValue = ({ ultimoPrecio, idPaisNavigation }) => {
  return formatNumber(
    ultimoPrecio,
    2,
    "currency",
    idPaisNavigation.idMonedaNavigation.codigo
  );
};

const DataGridTblRecambioNProveedor = ({ dataSource }) => {
  const { setRecambioSel } = useRecambiosProvider();

  const onRowRemoving = ({ data }) => {
    const tblRecambioNProveedor = dataSource
      .filter((item) => item.idRecambioNProveedor !== data.idRecambioNProveedor)
      .map((item, index) => ({
        ...item,
        orden: index + 1,
      }));

    setRecambioSel((prevState) => ({
      ...prevState,
      tblRecambioNProveedor,
    }));
  };

  const groupCellRender = ({ value }) => value;

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr={"idRecambioNProveedor"}
      height={240}
      width={"100%"}
      allowColumnReordering
      columnsAutoWidth
      hoverStateEnabled
      rowAlternationEnabled
      showRowLines
      showColumnLines={false}
      onRowRemoving={onRowRemoving}
    >
      <Editing mode={"cell"} allowUpdating selectTextOnEditStart changes={[]} />
      <KeyboardNavigation
        enterKeyAction={"moveFocus"}
        enterKeyDirection={"row"}
        editOnKeyPress
      />
      <Sorting mode={"none"} />
      <Column
        dataField={"idProveedorNavigation.nombreComercial"}
        caption={getTrad("proveedor")}
        width={250}
        allowEditing={false}
      />
      <Column
        dataField={"idPaisNavigation.denominacion"}
        caption={getTrad("pais")}
        width={160}
        allowEditing={false}
        groupCellRender={groupCellRender}
      />
      <Column
        dataField={"ultimoPrecio"}
        caption={getTrad("precioCompra")}
        dataType={"number"}
        width={130}
        editorOptions={editorOptions}
        calculateDisplayValue={calculateDisplayValue}
      />
      <Column
        dataField={"referencia"}
        caption={getTrad("refProveedor")}
        alignment={"center"}
        width={130}
      />
      <Column
        dataField={"refFabricante"}
        caption={getTrad("refFabricante")}
        alignment={"left"}
        width={130}
      />
      <Column
        dataField={"codigoFabricante"}
        caption={getTrad("codigoFabricante")}
        alignment={"left"}
        width={130}
      />
      <Column
        dataField={"fabricante"}
        caption={getTrad("fabricante")}
        alignment={"left"}
        width={130}
      />
      <Column
        dataField={"codigoBarras"}
        caption={getTrad("codigoBarras")}
        alignment={"left"}
        width={130}
      />
      <Column
        dataField={"codigoBarrasFabricante"}
        caption={getTrad("codigoBarrasFabricante")}
        alignment={"left"}
        width={170}
      />
      <Column
        dataField={"observaciones"}
        caption={getTrad("observaciones")}
        alignment={"left"}
        width={130}
      />
      <Column
        dataField={"orden"}
        sortIndex={0}
        sortOrder={"asc"}
        visible={false}
      />
      <Column
        width={30}
        cssClass={"p-0"}
        alignment={"left"}
        fixed
        fixedPosition={"right"}
        cellComponent={removeCellComponent}
      />
    </DataGrid>
  );
};

export default DataGridTblRecambioNProveedor;
