export const exclamation_circle = {
  v: "5.5.1",
  fr: 60,
  ip: 0,
  op: 90,
  w: 180,
  h: 180,
  nm: "error",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 10,
              s: [0],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 20,
              s: [-16],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 30,
              s: [16],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 40,
              s: [-8],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 50,
              s: [4],
            },
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.333], y: [0] },
              t: 60,
              s: [-2],
            },
            { t: 66, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0 },
              t: 10,
              s: [89.661, 126.788, 0],
              to: [-0.277, 0, 0],
              ti: [0.11, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0 },
              t: 20,
              s: [88, 126.788, 0],
              to: [-0.11, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0 },
              t: 30,
              s: [89, 126.788, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0 },
              t: 40,
              s: [88, 126.788, 0],
              to: [0, 0, 0],
              ti: [-0.277, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.333, y: 0 },
              t: 50,
              s: [89, 126.788, 0],
              to: [0.277, 0, 0],
              ti: [-0.11, 0, 0],
            },
            { t: 60, s: [89.661, 126.788, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [0, 37, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 10,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [105, 105, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0.11],
                    [0, 0],
                    [1.88, 0],
                    [0, -2.36],
                    [0, 0],
                    [-0.11, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, -2.33],
                    [-1.93, 0],
                    [0, 0],
                    [0.01, 0.11],
                    [0, 0],
                    [0.11, 0],
                  ],
                  v: [
                    [3.205, 5.691],
                    [3.705, -12.329],
                    [0.005, -15.929],
                    [-3.705, -12.329],
                    [-3.215, 5.691],
                    [-3.015, 5.881],
                    [3.005, 5.881],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "패스 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 2.25],
                    [-2.44, 0],
                    [0, -2.22],
                    [2.43, 0],
                  ],
                  o: [
                    [0, -2.25],
                    [2.43, 0],
                    [0, 2.21],
                    [-2.44, 0],
                  ],
                  v: [
                    [-3.887, 12.261],
                    [0.003, 8.591],
                    [3.883, 12.261],
                    [0.003, 15.931],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "패스 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "패스 병합 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "칠 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [300, 300], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "변형",
            },
          ],
          nm: "Shape",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Oval",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [90, 90, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [52, 52], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "타원 패스 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.8509803921568627, 0.1450980392156863, 0.3137254901960784, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "칠 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [300, 300], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "변형",
            },
          ],
          nm: "Oval",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
