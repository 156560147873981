import React, { forwardRef } from "react";

import notify from "devextreme/ui/notify";
import { Column, Lookup } from "devextreme-react/data-grid";

import IdAlmacenDestinoColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/IdAlmacenDestinoColumn";
import IdAlmacenOrigenColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/idAlmacenOrigenColumn";
import NumRegistroColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/NumRegistroColumn";
import SharedDataGridMovimientos from "pages/AssistantNew/Movimientos/shared/components/SharedDataGridMovimientos";
import { context_traspasos } from "pages/AssistantNew/Movimientos/shared/api";
import { getTrad } from "helpers";

const icons = {
  true: "icon_Circle_check",
  false: "icon_Circle_uncheck",
};

const calculateSortValue = ({ isInventario }) => (isInventario ? 1 : 0);

const DataGridMovimientos = forwardRef(({ ...props }, ref) => {
  const title = {
    true: getTrad("validado"),
    false: getTrad("noValidado"),
  };

  const onClick_Validar = (idMovimientoRecambio, component) =>
    context_traspasos
      .invoke("SetIsValidado", { idMovimientoRecambio })
      .then((response) => {
        if (response) {
          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: 500,
            closeOnClick: true,
          });
          component.refresh();
        }
      });

  const cellRender = ({ value, data, component }) => {
    if (value === null) return null;

    return (
      <div
        title={title[value]}
        className={"container_spanCentrado pointer"}
        onMouseDown={() =>
          onClick_Validar(data.idMovimientoRecambio, component)
        }
      >
        <i className={`${icons[value] || ""} font-size-xs`} />
      </div>
    );
  };

  const datasource_isValidado = [
    { value: true, denominacion: getTrad("validado") },
    { value: false, denominacion: getTrad("noValidado") },
  ];

  const firstColumn = {
    dataField: "isValidado",
    minWidth: 85,
    caption: getTrad("validado"),
    allowHeaderFiltering: true,
    allowFiltering: false,
    allowReordering: false,
    allowResizing: false,
    alignment: "left",
    calculateSortValue: calculateSortValue,
    cellRender: cellRender,
    children: (
      <Lookup
        dataSource={datasource_isValidado}
        valueExpr={"value"}
        displayExpr={"denominacion"}
      />
    ),
  };

  return (
    <SharedDataGridMovimientos
      ref={ref}
      firstColumn={firstColumn}
      newMovimientoTrad={"nuevoTraspaso"}
      {...props}
    >
      <Column {...IdAlmacenDestinoColumn(getTrad)} />
      <Column {...IdAlmacenOrigenColumn(getTrad)} />
      <Column {...NumRegistroColumn(getTrad)} />
    </SharedDataGridMovimientos>
  );
});

export default DataGridMovimientos;
