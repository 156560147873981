import React, { forwardRef } from "react";

import { Column, Summary, TotalItem } from "devextreme-react/data-grid";

import SharedDataGridRecambios from "pages/AssistantNew/Movimientos/shared/components/SharedDataGridRecambios";
import UbicacionColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/UbicacionColumn";
import { getTrad } from "helpers";
import { sharedFormatCurrency } from "pages/AssistantNew/Movimientos/shared/constants";

const DataGridRecambios = forwardRef(
  ({ movimientoRecambio, codigoMoneda, ...props }, ref) => {
    const { isEditable } = movimientoRecambio;

    const valueFormat = sharedFormatCurrency(codigoMoneda);

    return (
      <SharedDataGridRecambios
        ref={ref}
        movimientoRecambio={movimientoRecambio}
        codigoMoneda={codigoMoneda}
        allowEditingPrecio
        {...props}
      >
        <Column
          dataField={"referenciaProveedor"}
          caption={getTrad("refProveedor")}
          alignment={"left"}
          width={110}
          cssClass={isEditable ? "dx-Cell_Editable" : ""}
          allowEditing={isEditable}
        />
        <Column {...UbicacionColumn(isEditable, getTrad)} />
        <Summary recalculateWhileEditing>
          <TotalItem
            column={"referenciaInterna"}
            displayFormat={getTrad("total").toUpperCase()}
          />
          <TotalItem
            summaryType={"sum"}
            valueFormat={valueFormat}
            displayFormat={"{0}"}
            column={"total"}
          />
        </Summary>
      </SharedDataGridRecambios>
    );
  }
);

export default DataGridRecambios;
