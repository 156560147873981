import React from "react";

export class removeCellComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.data.component.deleteRow(this.props.data.rowIndex);
  }

  render() {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={this.onClick}
      >
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  }
}
