import React, { useEffect, useState } from "react";

import MultiView, { Item } from "devextreme-react/multi-view";
import ODataContext from "devextreme/data/odata/context";
import Popup, { ToolbarItem } from "devextreme-react/popup";

import DataGridConceptos from "./components/DataGridConceptos";
import DisplayNominas from "./components/DisplayNominas";
import { authHeader, errorHandler, formatDate_parameter, formatDate, getTrad, dxMensajePregunta } from "helpers";
import { connectionConstants } from "constants";
import notify from "devextreme/ui/notify";

const views = {
  displayNominas: 0,
  dataGridConceptos: 1,
}

const nominaSel_initialState = {
  idNomina: null,
  idEstadoNomina: null,
  fechaDesde: null,
  fechaHasta: null,
  conceptosNominaNNomina: [],
};

const PopupComparativaConceptos = ({ title, visible, comparacionNominaGestoria, nominaCompara, fechaIni, fechaFin, onChange_comparacionNominaGestoria, onHiding }) => {
  const [titleState, setTitleState] = useState("");
  const [nominaSel, setNominaSel] = useState(nominaSel_initialState);
  const [selectedIndex, setSelectedIndex] = useState(views.dataGridConceptos);
  const [disabled, setDisabled] = useState(false);

  const conflictoNominaContrato = comparacionNominaGestoria.some(n => !n.isContratoAsociado) && comparacionNominaGestoria.some(n => n.isContratoAsociado);

  useEffect(() => {
    if (visible) {
      setTitleState(title);
      if (comparacionNominaGestoria.length === 1) {
        onSetNominaSel(comparacionNominaGestoria[0]);
      } else if (!conflictoNominaContrato) {
        const nominaSel = comparacionNominaGestoria.find(n => n.idNomina === nominaCompara?.idNomina);

        nominaSel ? onSetNominaSel(nominaSel) : goBack();
      } else {
        goBack();
      }
    }
  }, [visible]);

  const context_gestionNominas = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/",
    entities: {
      AceptarNominasGestoria: {
        key: "idPersona",
        keyType: "Int32",
      },
      AceptarNominaGestoria: {
        key: "idNomina",
        keyType: "Int32",
      },
    },
    errorHandler: error => errorHandler(error, null),
    beforeSend: request => {
      request.headers = { ...authHeader() };

      request.params.fechaDesde = formatDate_parameter(new Date(fechaIni));
      request.params.fechaHasta = formatDate_parameter(new Date(fechaFin));
    },
  });

  const onClick_aceptarNominasGestoria = () => {
    const aceptarNominasGestoria = () => {
      if (disabled) return;

      setDisabled(true);

      context_gestionNominas.invoke("AceptarNominasGestoria", { idPersona: nominaCompara?.idPersona })
        .done(data => {
          onChange_comparacionNominaGestoria(data);
          notify({
            message: getTrad("aviso_C_aceptarDatosGestoria"),
            type: "success",
            displayTime: 1500,
            closeOnClick: true,
          });
        })
        .always(() => setDisabled(false));
    };

    dxMensajePregunta(
      getTrad("aviso_aceptarVariasNominasGestoria"),
      [
        [
          getTrad("aceptar"),
          aceptarNominasGestoria,
          "danger",
        ],
        [getTrad("cancelar"), () => { }],
      ]
    );
  };

  const onClick_aceptarNominaGestoria = () => {
    if (disabled) return;

    setDisabled(true);

    context_gestionNominas.invoke("AceptarNominaGestoria", { idNomina: nominaSel.idNomina })
      .done(data => {
        onChange_comparacionNominaGestoria(data);
        setNominaSel(data.find(n => n.idNomina === nominaSel.idNomina));
        notify({
          message: getTrad("aviso_C_aceptarDatosGestoria"),
          type: "success",
          displayTime: 1500,
          closeOnClick: true,
        });
      })
      .always(() => setDisabled(false));
  };

  const goBack = () => {
    setSelectedIndex(views.displayNominas);
    setNominaSel(nominaSel_initialState);
  };

  const onSetNominaSel = nominaSel => {
    setNominaSel(nominaSel);
    setSelectedIndex(views.dataGridConceptos);
  };

  const onHidden = () => setSelectedIndex(views.dataGridConceptos);

  const optionsAtras = {
    icon: "chevronleft",
    width: 45,
    type: "normal",
    onClick: goBack
  };

  return (
    <Popup
      visible={visible}
      width={"50%"}
      onHiding={onHiding}
      onHidden={onHidden}
    >
      <MultiView
        selectedIndex={selectedIndex}
        swipeEnabled={false}
        deferRendering={false}
      >
        <Item>
          <DisplayNominas
            conflictoNominaContrato={conflictoNominaContrato}
            dataSource={comparacionNominaGestoria}
            setNominaSel={onSetNominaSel}
            onClick_aceptarNominasGestoria={onClick_aceptarNominasGestoria}
          />
        </Item>
        <Item>
          <div className={selectedIndex == views.displayNominas ? "d-none" : ""}>
            <DataGridConceptos
              nominaSel={nominaSel}
              conflictoNominaContrato={conflictoNominaContrato}
              onChange_comparacionNominaGestoria={onChange_comparacionNominaGestoria}
              onClick_aceptarNominaGestoria={onClick_aceptarNominaGestoria}
            />
          </div>
        </Item>
      </MultiView>
      {selectedIndex === views.dataGridConceptos && comparacionNominaGestoria.length > 1
        ? <ToolbarItem
          widget={"dxButton"}
          location={"before"}
          toolbar={"top"}
          options={optionsAtras}
        />
        : null
      }
      <ToolbarItem
        text={selectedIndex === views.dataGridConceptos ? `${titleState} | ${formatDate(new Date(nominaSel?.fechaDesde))} - ${formatDate(new Date(nominaSel?.fechaHasta))}` : titleState}
        location={"before"}
        toolbar={"top"}
      />
    </Popup>
  );
};

export default PopupComparativaConceptos;
