import React, { useEffect, useRef, useState } from "react";

import TagBox from "devextreme-react/tag-box";

import { getTrad } from "helpers";

const ids = {
  pagado: 1,
  pendientePago: 2,
  anticipo: 3,
  pendienteAnticipo: 4,
  contabilizado: 5,
  retenido: 6,
};

const FilterNominas = ({ value, disabled, onValueChanged }) => {
  const tagBoxRef = useRef(null);
  const [valueFinal, setValueFinal] = useState([]);

  useEffect(() => {
    let initialValue = [];
    if (value.pagado) initialValue.push(ids.pagado);
    if (value.pendientePago) initialValue.push(ids.pendientePago);
    if (value.anticipo) initialValue.push(ids.anticipo);
    if (value.pendienteAnticipo) initialValue.push(ids.pendienteAnticipo);
    if (value.contabilizado) initialValue.push(ids.contabilizado);
    if (value.retenido) initialValue.push(ids.retenido);
    setValueFinal(initialValue);
  }, [value]);

  const items = [
    { value: ids.pagado, denominacion: getTrad("pagado") },
    { value: ids.pendientePago, denominacion: getTrad("ptePago") },
    { value: ids.anticipo, denominacion: getTrad("anticipo") },
    { value: ids.pendienteAnticipo, denominacion: getTrad("pteAnticipo") },
    { value: ids.contabilizado, denominacion: getTrad("contabilizado") },
    { value: ids.retenido, denominacion: getTrad("retenido") },
  ];

  const buttons = [
    {
      name: "addTipoPedido",
      location: "after",
      options: {
        height: "100%",
        icon: "spindown",
        type: "default",
        onClick: () => {
          const tagBoxInstance = tagBoxRef?.current?.instance;
          tagBoxInstance?.option("opened")
            ? tagBoxInstance?.close()
            : tagBoxInstance?.open();
        },
      },
    },
  ];

  const this_onValueChanged = ({ value }) => {
    const valueFinal = {
      pagado: value.includes(ids.pagado),
      pendientePago: value.includes(ids.pendientePago),
      anticipo: value.includes(ids.anticipo),
      pendienteAnticipo: value.includes(ids.pendienteAnticipo),
      contabilizado: value.includes(ids.contabilizado),
      retenido: value.includes(ids.retenido),
    };

    onValueChanged(valueFinal);
  };

  return (
    <TagBox
      ref={tagBoxRef}
      value={valueFinal}
      items={items}
      valueExpr={"value"}
      displayExpr={"denominacion"}
      width={310}
      showSelectionControls
      multiline={false}
      applyValueMode={"useButtons"}
      buttons={buttons}
      disabled={disabled}
      onValueChanged={this_onValueChanged}
    />
  );
};

export default FilterNominas;
