import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
//Actions
import { loadPanelActions } from "../../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import $ from "jquery";
import {
  errorHandler,
  convertClienteUtcToLavanderiaUtc,
  authHeader,
  getNombreFormulario,
  formatNumber,
  getTrad,
  capitalize,
  endOfMonth,
  formatDate,
  formatDate_parameter,
  startOfMonth,
} from "../../../../helpers";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";

import { Popup, ToolbarItem } from "devextreme-react/popup";
import query from "devextreme/data/query";
import { ButtonGroup } from "devextreme-react/button-group";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Tooltip as Chart_Tooltip,
  Label,
  Grid,
  LoadingIndicator,
  AdaptiveLayout,
  Aggregation,
} from "devextreme-react/chart";

import { Tooltip } from "devextreme-react/tooltip";
import SelectBox from "devextreme-react/select-box";
import TextBox from "devextreme-react/text-box";

import "./css.scss";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";

class InfOperativo extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    this.state = {
      fechaSel: null,
      minDate: null,
      maxDate: null,
      idTipoChartSel: 1,
      totalLabels: null,
      datasource_dxChart: [],
      param_paginaSecundaria: null,
      dxPopup_noDatos_isVisible: false,
      isVisible_rendimientoProd: false,
      isVisible_aguaProd: false,
      isVisible_electricidadProd: false,
      isVisible_calefaccionProd: false,
      isVisible_kgProcesados: true,
      isVisible_kgLavados: true,

      isVisible_rendimientoProc: true,
      isVisible_rendimientoObjProc: true,
      isVisible_kgRechazo: true,
      isVisible_objRechazo: true,
      isVisible_aguaProc: true,
      isVisible_aguaObj: true,
      isVisible_elecProc: true,
      isVisible_elecObj: true,
      isVisible_calProc: true,
      isVisible_calOjb: true,
    };

    this.dxChart_REF = React.createRef();

    this.dateRangePicker_onDateSelected =
      this.dateRangePicker_onDateSelected.bind(this);

    this.dxButtonGroup_buttonRender =
      this.dxButtonGroup_buttonRender.bind(this);
    this.dxButtonGroup_onItemClick = this.dxButtonGroup_onItemClick.bind(this);
    this.dxSelectBox_tipoChart_onSelectionChanged =
      this.dxSelectBox_tipoChart_onSelectionChanged.bind(this);

    this.dxChart_onInitialized = this.dxChart_onInitialized.bind(this);
    this.dxChart_onLegendClick = this.dxChart_onLegendClick.bind(this);
    this.dxChart_onPointClick = this.dxChart_onPointClick.bind(this);
    this.dxChart_customizeTooltip = this.dxChart_customizeTooltip.bind(this);
    this.dxChart_legend_customizeItems =
      this.dxChart_legend_customizeItems.bind(this);
    this.dxChart_argumentAxis_label_customizeText =
      this.dxChart_argumentAxis_label_customizeText.bind(this);

    this.dxPopup_noDatos_contentRender =
      this.dxPopup_noDatos_contentRender.bind(this);
    this.dxPopup_noDatos_onContentReady =
      this.dxPopup_noDatos_onContentReady.bind(this);
    this.dxPopup_noDatos_buttonOnClick =
      this.dxPopup_noDatos_buttonOnClick.bind(this);

    this.datasource_selectCharts_onLoaded =
      this.datasource_selectCharts_onLoaded.bind(this);
    this.datasource_infOperativo_filtroTiempo_beforeSend =
      this.datasource_infOperativo_filtroTiempo_beforeSend.bind(this);

    this.set_totales_dxChart = this.set_totales_dxChart.bind(this);
    this.getKgProcLav = this.getKgProcLav.bind(this);
    this.cargaDatos_lavanderia = this.cargaDatos_lavanderia.bind(this);

    this.aceptar_dxButton = {
      text: getTrad("aceptar"),
      type: "danger",
      onClick: this.dxPopup_noDatos_buttonOnClick,
    };
  }

  get dxChart() {
    return this.dxChart_REF.current.instance;
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  //#region DATASOURCE
  datasource_selectCharts = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infOperativo_spSelectCharts",
      key: "arg",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_selectCharts_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_selectCharts_onLoaded(data);
      },
      version: 4,
    }),
    sort: "arg",
  });
  datasource_selectCharts_beforeSend(request) {
    const { lavanderia } = this.props;
    let { fechaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
    request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
  }

  datasource_selectCharts_onLoaded(data) {
    $.each(data, function (index, item) {
      item.consumosKgProcesados_agua = item.consumos_agua / item.kgProcesados;
      item.consumosKgLavados_agua = item.consumos_agua / item.kgLavados;

      item.consumosKgProcesados_electricidad =
        item.consumos_electricidad / item.kgProcesados;
      item.consumosKgLavados_electricidad =
        item.consumos_electricidad / item.kgLavados;

      item.consumosKgProcesados_calefaccion =
        item.consumos_calefaccion / item.kgProcesados;
      item.consumosKgLavados_calefaccion =
        item.consumos_calefaccion / item.kgLavados;

      item.val_procesados =
        item.kgLavados === 0 ? 0 : item.kgProcesados / item.kgLavados - 1;
      item.horasKgProcesados =
        item.horasProductivas && item.kgProcesados === 0
          ? 0
          : item.kgProcesados / item.horasProductivas;
      item.horasKgLavados =
        item.horasProductivas && item.kgLavados === 0
          ? 0
          : item.kgLavados / item.horasProductivas;
    });

    let totalLabels = this.set_totales_dxChart(data, this.state.idTipoChartSel);

    this.setState({ datasource_dxChart: data, totalLabels: totalLabels });
    this.loadPanel_hide();
  }

  datasource_infOperativo_filtroTiempo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infOperativo_filtroTiempo",
      key: "arg",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_infOperativo_filtroTiempo_beforeSend(request);
      },
      version: 4,
    }),
  });
  datasource_infOperativo_filtroTiempo_beforeSend(request) {
    const { lavanderia } = this.props;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  enum_tipoChart = [
    {
      idTipoChart: 1,
      denominacion: getTrad("kgProcesados"),
      icon: "icon_Kg",
      color: "--secondary",
    },
    {
      idTipoChart: 2,
      denominacion: getTrad("rendimiento"),
      icon: "icon_timer",
      color: "--teal",
    },
    {
      idTipoChart: 3,
      denominacion: getTrad("rechazo"),
      icon: "icon_Rechazo",
      color: "--red",
    },
    {
      idTipoChart: 4,
      denominacion: getTrad("agua"),
      icon: "icon_Agua",
      color: "--blue",
    },
    {
      idTipoChart: 5,
      denominacion: getTrad("electricidad"),
      icon: "icon_Electricidad",
      color: "--yellow",
    },
    {
      idTipoChart: 6,
      denominacion: getTrad("calefaccion"),
      icon: "icon_Calefaccion",
      color: "--orange",
    },
  ];

  enum_palette_dxChart = {
    series: ["#F1BE48", "#A9A9A9"],
    kpi: ["#C48D0F", "#767676"],
  };
  //#endregion

  render() {
    let { getBackButton_paginaSecundaria, param_paginaSecundaria, resolucion } =
      this.props;
    let {
      fechaSel,
      minDate,
      maxDate,
      idTipoChartSel,
      totalLabels,
      datasource_dxChart,
      isVisible_rendimientoProd,
      isVisible_aguaProd,
      isVisible_electricidadProd,
      isVisible_calefaccionProd,
      isVisible_kgProcesados,
      isVisible_kgLavados,
      isVisible_rendimientoProc,
      isVisible_rendimientoObjProc,
      isVisible_kgRechazo,
      isVisible_objRechazo,
      isVisible_aguaProc,
      isVisible_aguaObj,
      isVisible_elecProc,
      isVisible_elecObj,
      isVisible_calProc,
      isVisible_calOjb,
    } = this.state;

    let isAnual = this.get_differenceInDays(fechaSel) > 60;

    return (
      <Fragment>
        <PageTitle
          heading={
            param_paginaSecundaria != null
              ? param_paginaSecundaria.nombre_paginaSecundaria
              : getNombreFormulario(this)
          }
          preHeading={
            getBackButton_paginaSecundaria
              ? getBackButton_paginaSecundaria()
              : ""
          }
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className="media-body"
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="InfOperativo" className="formContainer">
            <Box direction="col" height="100%" width="100%">
              <ItemBox ratio={0} baseSize={50}>
                <ResponsiveBox>
                  <Row ratio={1} screen="sm md lg xl" />
                  <Col ratio={0} baseSize={220} screen="md lg xl" />

                  <Col ratio={1} screen="lg xl" />
                  <Col ratio={3} screen="lg xl" />

                  <Col ratio={0} screen="md" />
                  <Col ratio={1} screen="md" />

                  <Col ratio={1} screen="xs sm" />
                  <Col ratio={1} screen="xs sm" />
                  <Row ratio={1} screen="xs sm" />
                  <Row ratio={1} screen="xs sm" />

                  <Item>
                    <Location row={0} col={0}></Location>
                    <div className="d-flex align-items-center he-100">
                      <DateRangePicker
                        month
                        year
                        day
                        defaultType="month"
                        fechaSel={fechaSel}
                        minDate={minDate}
                        maxDate={maxDate}
                        onDateSelected={this.dateRangePicker_onDateSelected}
                      />
                    </div>
                  </Item>
                  <Item>
                    <Location row={0} col={2} screen="md lg xl"></Location>
                    <Location row={0} col={1} screen="xs sm"></Location>

                    <ButtonGroup
                      height="100%"
                      width="100%"
                      items={this.enum_tipoChart}
                      keyExpr="idTipoChart"
                      selectedItemKeys={[idTipoChartSel]}
                      visible={resolucion != "xs" && resolucion != "sm"}
                      buttonRender={this.dxButtonGroup_buttonRender}
                      onItemClick={this.dxButtonGroup_onItemClick}
                    />
                    <div className="d-flex he-100 justify-content-center align-items-center">
                      <SelectBox
                        visible={resolucion == "xs" || resolucion == "sm"}
                        height={36}
                        width="100%"
                        items={this.enum_tipoChart}
                        valueExpr={"idTipoChart"}
                        displayExpr={"denominacion"}
                        value={idTipoChartSel}
                        fieldRender={this.dxSelectBox_tipoChart_fieldRender}
                        itemRender={this.dxSelectBox_tipoChart_itemRender}
                        onSelectionChanged={
                          this.dxSelectBox_tipoChart_onSelectionChanged
                        }
                      />
                    </div>
                  </Item>
                </ResponsiveBox>
              </ItemBox>
              <ItemBox baseSize={20} />
              <ItemBox ratio={0} baseSize={70}>
                <div
                  className={
                    "d-flex he-100 totalLabelContainer " +
                    (resolucion == "xs" ? "justify-content-between" : "")
                  }
                >
                  {totalLabels
                    ? totalLabels.map((child, i) => (
                        <div
                          style={{
                            minWidth: resolucion != "xs" ? 150 : "",
                            display:
                              (i === 2 &&
                                (idTipoChartSel === 1 ||
                                  (idTipoChartSel === 2 &&
                                    isVisible_rendimientoProd) ||
                                  idTipoChartSel === 3 ||
                                  (idTipoChartSel === 4 &&
                                    isVisible_aguaProd) ||
                                  (idTipoChartSel === 5 &&
                                    isVisible_electricidadProd) ||
                                  (idTipoChartSel === 6 &&
                                    isVisible_calefaccionProd))) ||
                              i !== 2
                                ? "flex"
                                : "none",
                          }}
                          key={i}
                          className="flex flex-column font-size-xxs justify-content-center pr-4"
                        >
                          <div className="d-flex flex-row">
                            <div>{child.denominacion}</div>
                            {i == 1 && idTipoChartSel == 3 ? (
                              <Fragment>
                                <i
                                  id={i + "_icon_Comment"}
                                  className="icon_Comment pointer pl-3"
                                />
                                <Tooltip
                                  height="42px"
                                  showEvent="dxhoverstart click"
                                  hideEvent="dxhoverend"
                                  position="top"
                                  target={"#" + i + "_icon_Comment"}
                                  animation={this.dxTooltipRechazo_animation}
                                  contentRender={
                                    this.dxTooltipRechazo_contentRender
                                  }
                                />
                              </Fragment>
                            ) : (
                              <div />
                            )}
                          </div>
                          <div
                            style={{ fontWeight: 600 }}
                            className="font-size-xs"
                          >
                            {child.total + " " + child.unidad}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </ItemBox>
              <ItemBox ratio={1}>
                <Chart
                  ref={this.dxChart_REF}
                  dataSource={datasource_dxChart}
                  size={{ height: "100%", width: "100%" }} //Si lo saco fuera del render no me hace bien la 1era carga
                  palette={this.enum_palette_dxChart.series}
                  onInitialized={this.dxChart_onInitialized}
                  onPointClick={this.dxChart_onPointClick}
                  onLegendClick={this.dxChart_onLegendClick}
                >
                  <CommonSeriesSettings
                    type="bar"
                    argumentField="arg"
                    ignoreEmptyPoints={true}
                    hoverMode="allArgumentPoints"
                  />

                  <Series
                    valueField="kgProcesados"
                    aggregation={{}}
                    name={getTrad("kgProcesados_label")}
                    visible={idTipoChartSel === 1 && isVisible_kgProcesados}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={1}
                  >
                    <Aggregation enabled={isAnual} method="sum" />
                  </Series>
                  <Series
                    valueField="kgLavados"
                    name={getTrad("kgLavados")}
                    visible={idTipoChartSel === 1 && isVisible_kgLavados}
                    color={this.enum_palette_dxChart.series[1]}
                    idTipoChart={1}
                  >
                    <Aggregation enabled={isAnual} method="sum" />
                  </Series>
                  <Series
                    valueField="horasKgProcesados"
                    type="bar"
                    name={getTrad("kgProc_HorasProd")}
                    visible={idTipoChartSel === 2 && isVisible_rendimientoProc}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={2}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rendimiento}
                    />
                  </Series>
                  <Series
                    valueField="horasKgLavados"
                    visible={idTipoChartSel === 2 && isVisible_rendimientoProd}
                    type="bar"
                    name={getTrad("kgLav_HorasProd")}
                    color={this.enum_palette_dxChart.series[1]}
                    idTipoChart={2}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rendimiento}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiProc_rendimiento"
                    type="spline"
                    name={getTrad("objKgProc")}
                    color={this.enum_palette_dxChart.kpi[0]}
                    visible={
                      idTipoChartSel === 2 && isVisible_rendimientoObjProc
                    }
                    idTipoChart={2}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rendimiento}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiLav_rendimiento"
                    type="spline"
                    name={getTrad("objKgLav")}
                    color={this.enum_palette_dxChart.kpi[1]}
                    visible={idTipoChartSel === 2 && isVisible_rendimientoProd}
                    idTipoChart={2}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rendimiento}
                    />
                  </Series>
                  <Series
                    valueField="val_rechazados"
                    type="bar"
                    name={getTrad("kgRechazados")}
                    visible={idTipoChartSel === 3 && isVisible_kgRechazo}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={3}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rechazo}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpi_rechazo"
                    type="spline"
                    name={getTrad("objetivoRechazo")}
                    visible={idTipoChartSel === 3 && isVisible_objRechazo}
                    color={this.enum_palette_dxChart.kpi[0]}
                    idTipoChart={3}
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_rechazo}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgProcesados_agua"
                    name={getTrad("lAgua_kgProcesados")}
                    visible={idTipoChartSel === 4 && isVisible_aguaProc}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={4}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_agua}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgLavados_agua"
                    name={getTrad("lAgua_kgLavados")}
                    visible={idTipoChartSel === 4 && isVisible_aguaProd}
                    color={this.enum_palette_dxChart.series[1]}
                    idTipoChart={4}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_agua}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiProc_agua"
                    name={getTrad("objKgProc")}
                    visible={idTipoChartSel === 4 && isVisible_aguaObj}
                    color={this.enum_palette_dxChart.kpi[0]}
                    idTipoChart={4}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_agua}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiLav_agua"
                    name={getTrad("objKgLav")}
                    visible={idTipoChartSel === 4 && isVisible_aguaProd}
                    color={this.enum_palette_dxChart.kpi[1]}
                    idTipoChart={4}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_agua}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgProcesados_electricidad"
                    name={getTrad("kwhElectricidad_kgProc")}
                    visible={idTipoChartSel === 5 && isVisible_elecProc}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={5}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_electricidad}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgLavados_electricidad"
                    name={getTrad("kwhElectricidad_kgLav")}
                    visible={idTipoChartSel === 5 && isVisible_electricidadProd}
                    color={this.enum_palette_dxChart.series[1]}
                    idTipoChart={5}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_electricidad}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiProc_electricidad"
                    name={getTrad("objKgProc")}
                    visible={idTipoChartSel === 5 && isVisible_elecObj}
                    color={this.enum_palette_dxChart.kpi[0]}
                    idTipoChart={5}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_electricidad}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiLav_electricidad"
                    name={getTrad("objKgLav")}
                    visible={idTipoChartSel === 5 && isVisible_electricidadProd}
                    color={this.enum_palette_dxChart.kpi[1]}
                    idTipoChart={5}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_electricidad}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgProcesados_calefaccion"
                    name={getTrad("kwhCalefaccion_kgProc")}
                    visible={idTipoChartSel === 6 && isVisible_calProc}
                    color={this.enum_palette_dxChart.series[0]}
                    idTipoChart={6}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_calefaccion}
                    />
                  </Series>
                  <Series
                    valueField="consumosKgLavados_calefaccion"
                    name={getTrad("kwhCalefaccion_kgLav")}
                    visible={idTipoChartSel === 6 && isVisible_calefaccionProd}
                    color={this.enum_palette_dxChart.series[1]}
                    idTipoChart={6}
                    type="bar"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_calefaccion}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiProc_calefaccion"
                    name={getTrad("objKgProc")}
                    visible={idTipoChartSel === 6 && isVisible_calOjb}
                    color={this.enum_palette_dxChart.kpi[0]}
                    idTipoChart={6}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_calefaccion}
                    />
                  </Series>
                  <Series
                    valueField="objetivoKpiLav_calefaccion"
                    name={getTrad("objKgLav")}
                    visible={idTipoChartSel === 6 && isVisible_calefaccionProd}
                    color={this.enum_palette_dxChart.kpi[1]}
                    idTipoChart={6}
                    type="spline"
                  >
                    <Aggregation
                      enabled={isAnual}
                      method="custom"
                      calculate={this.calculate_aggregation_calefaccion}
                    />
                  </Series>
                  <ValueAxis
                    position="left"
                    maxValueMargin={0.3}
                    minValueMargin={0.3}
                  >
                    <Grid visible={true} />
                  </ValueAxis>
                  <ArgumentAxis
                    argumentType="datetime"
                    tickInterval={isAnual ? { months: 1 } : "day"}
                    format="monthDay"
                    aggregationInterval={isAnual ? "month" : "day"}
                  >
                    <Label
                      overlappingBehavior="none"
                      rotationAngle={-45}
                      displayMode="rotate"
                      format={isAnual ? "month" : "monthAndDay"}
                      customizeText={
                        this.dxChart_argumentAxis_label_customizeText
                      }
                    />
                  </ArgumentAxis>
                  <Legend
                    verticalAlignment="top"
                    horizontalAlignment="right"
                    orientation="horizontal"
                    customizeItems={this.dxChart_legend_customizeItems}
                  />
                  <Chart_Tooltip
                    enabled={true}
                    location="edge"
                    customizeTooltip={this.dxChart_customizeTooltip}
                  />
                  <LoadingIndicator
                    backgroundColor="none"
                    font={this.loadingIndicator_font}
                  />
                  <AdaptiveLayout width={"100%"} />
                </Chart>
              </ItemBox>
            </Box>
            <Popup
              height="auto"
              width="auto"
              minWidth={300}
              maxWidth={500}
              visible={this.state.dxPopup_noDatos_isVisible}
              showTitle={false}
              deferRendering={false}
              contentRender={this.dxPopup_noDatos_contentRender}
              onContentReady={this.dxPopup_noDatos_onContentReady}
            >
              <ToolbarItem
                toolbar="bottom"
                location="center"
                widget="dxButton"
                options={this.aceptar_dxButton}
              ></ToolbarItem>
            </Popup>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //#region MÉTODOS
  cargaDatos_lavanderia() {
    let _this = this;
    $.when(this.datasource_infOperativo_filtroTiempo.reload()).then(
      function () {
        let datasource_infOperativo_filtroTiempo_items =
          _this.datasource_infOperativo_filtroTiempo.items();
        _this.loadPanel_hide();
        _this.setState({
          fechaSel: null,
          idTipoChartSel: 1,
          minDate: datasource_infOperativo_filtroTiempo_items[0].fechaMin,
          maxDate: datasource_infOperativo_filtroTiempo_items[0].fechaMax,
        });
      },
    );
  }

  set_totales_dxChart(items, idTipoChartSel) {
    let tot1 = 0;
    let tot2 = 0;
    let tot3 = 0;
    let deno1;
    let deno2;
    let deno3;
    let unidad1;
    let unidad2;
    let unidad3;
    let total1;
    let total2;
    let total3;

    $.each(items, function (index, item) {
      switch (idTipoChartSel) {
        case 1:
          tot1 += item.kgProcesados;
          tot2 += item.kgLavados;
          break;
        case 2:
          tot1 += item.horasProductivas;
          tot2 += item.kgProcesados;
          tot3 += item.kgLavados;
          break;
        case 3:
          tot1 += item.kgRechazados;
          tot2 += item.kgProcesados_rechazo;
          tot3 += item.kgLavados;
          break;

        case 4:
          tot1 += item.consumos_agua;
          tot2 += item.kgProcesados;
          tot3 += item.kgLavados;
          break;

        case 5:
          tot1 += item.consumos_electricidad;
          tot2 += item.kgProcesados;
          tot3 += item.kgLavados;
          break;

        case 6:
          tot1 += item.consumos_calefaccion;
          tot2 += item.kgProcesados;
          tot3 += item.kgLavados;
          break;
      }
    });

    switch (idTipoChartSel) {
      case 1:
        deno1 = getTrad("totalKgProcesados");
        deno2 = getTrad("totalKgLavados");
        deno3 = getTrad("porcentaje_kgProcesados_kgLavados");
        total1 = formatNumber(tot1, 2);
        total2 = formatNumber(tot2, 2);
        total3 = formatNumber(
          tot1 > 0 && tot2 > 0 ? tot1 / tot2 - 1 : 0,
          2,
          "percent",
        );
        unidad1 = "KG";
        unidad2 = "KG";
        unidad3 = "";
        break;
      case 2:
        deno1 = getTrad("totalHorasProd");
        deno2 = getTrad("kgProc_HorasProd");
        deno3 = getTrad("kgLav_HorasProd");
        total1 = formatNumber(Math.trunc(tot1), 0);
        total2 = formatNumber(tot1 > 0 && tot2 > 0 ? tot2 / tot1 : 0, 2);
        total3 = formatNumber(tot1 > 0 && tot3 > 0 ? tot3 / tot1 : 0, 2);
        unidad1 = getTrad("horas").toUpperCase();
        unidad2 = "KG/" + getTrad("horas").toUpperCase();
        unidad3 = "KG/" + getTrad("horas").toUpperCase();
        break;
      case 3:
        deno1 = getTrad("totalKgRechazados");
        deno2 = getTrad("totalKgProcesados");
        deno3 = getTrad("porcentajeRechazo");
        total1 = formatNumber(tot1, 2);
        total2 = formatNumber(tot2, 2);
        total3 = formatNumber(
          tot1 > 0 && tot2 > 0 ? tot1 / tot2 : 0,
          2,
          "percent",
        );
        unidad1 = "KG";
        unidad2 = "KG";
        unidad3 = "";
        break;
      case 4:
        deno1 = getTrad("totalLAgua");
        deno2 = getTrad("lAgua_kgProcesados");
        deno3 = getTrad("lAgua_kgLavados");
        total1 = formatNumber(tot1, 0);
        total2 = formatNumber(tot1 > 0 && tot2 > 0 ? tot1 / tot2 : 0, 2);
        total3 = formatNumber(tot1 > 0 && tot3 > 0 ? tot1 / tot3 : 0, 2);
        unidad1 = "L";
        unidad2 = "L/KG";
        unidad3 = "L/KG";
        break;
      case 5:
        deno1 = getTrad("totalKwhElectricidad");
        deno2 = getTrad("kwhElectricidad_kgProc");
        deno3 = getTrad("kwhElectricidad_kgLav");
        total1 = formatNumber(tot1, 0);
        total2 = formatNumber(tot1 > 0 && tot2 > 0 ? tot1 / tot2 : 0, 3);
        total3 = formatNumber(tot1 > 0 && tot3 > 0 ? tot1 / tot3 : 0, 3);
        unidad1 = "KWH";
        unidad2 = "KWH/KG";
        unidad3 = "KWH/KG";
        break;
      case 6:
        deno1 = getTrad("totalKwhCalefaccion");
        deno2 = getTrad("kwhCalefaccion_kgProc");
        deno3 = getTrad("kwhCalefaccion_kgLav");
        total1 = formatNumber(tot1, 0);
        total2 = formatNumber(tot1 > 0 && tot2 > 0 ? tot1 / tot2 : 0, 2);
        total3 = formatNumber(tot1 > 0 && tot3 > 0 ? tot1 / tot3 : 0, 2);
        unidad1 = "KWH";
        unidad2 = "KWH/KG";
        unidad3 = "KWH/KG";
        break;
    }

    let totalLabels = [
      {
        denominacion: deno1,
        total: total1,
        unidad: unidad1,
      },
      {
        denominacion: deno2,
        total: total2,
        unidad: unidad2,
      },
      {
        denominacion: deno3,
        total: total3,
        unidad: unidad3,
      },
    ];

    return totalLabels;
  }

  calculate_aggregation_rendimiento(aggregationInfo) {
    let dataObjects = aggregationInfo.data;
    let result = {
      arg: dataObjects[0].arg,
      kgProcesados: 0,
      kgLavados: 0,
      horasProductivas: 0,
      horasKgProcesados: 0,
      horasKgLavados: 0,
      objetivoKpiProc_rendimiento: dataObjects[0].objetivoKpiLav_rendimiento,
      objetivoKpiLav_rendimiento: dataObjects[0].objetivoKpiProc,
    };

    for (var i = 0; i < dataObjects.length; i++) {
      result.kgProcesados += dataObjects[i].kgProcesados;
      result.kgLavados += dataObjects[i].kgLavados;
      result.horasProductivas += dataObjects[i].horasProductivas;
    }

    result.horasKgProcesados =
      result.horasProductivas === 0
        ? 0
        : result.kgProcesados / result.horasProductivas;
    result.horasKgLavados =
      result.horasProductivas === 0
        ? 0
        : result.kgLavados / result.horasProductivas;
    return result;
  }

  calculate_aggregation_rechazo(aggregationInfo) {
    let dataObjects = aggregationInfo.data;
    let result = {
      arg: dataObjects[0].arg,
      kgProcesados_rechazo: 0,
      kgRechazados: 0,
      val_rechazados: 0,
      objetivoKpi_rechazo: dataObjects[0].objetivoKpi,
    };

    for (var i = 0; i < dataObjects.length; i++) {
      result.kgProcesados_rechazo += dataObjects[i].kgProcesados_rechazo;
      result.kgRechazados += dataObjects[i].kgRechazados;
    }

    result.val_rechazados =
      result.kgProcesados_rechazo === 0
        ? 0
        : (result.kgRechazados / result.kgProcesados_rechazo) * 100;

    return result;
  }

  calculate_aggregation_agua(aggregationInfo) {
    let dataObjects = aggregationInfo.data;
    let result = {
      arg: dataObjects[0].arg,
      kgProcesados: 0,
      kgLavados: 0,
      consumos_agua: 0,
      consumosKgProcesados_agua: 0,
      consumosKgLavados_agua: 0,
      objetivoKpiProc_agua: dataObjects[0].objetivoKpiProc_agua,
      objetivoKpiLav_agua: dataObjects[0].objetivoKpiLav_agua,
    };

    for (let i = 0; i < dataObjects.length; i++) {
      result.kgProcesados += dataObjects[i].kgProcesados;
      result.kgLavados += dataObjects[i].kgLavados;
      result.consumos_agua += dataObjects[i].consumos_agua;
    }

    result.consumosKgProcesados_agua =
      result.kgProcesados === 0
        ? 0
        : result.consumos_agua / result.kgProcesados;
    result.consumosKgLavados_agua =
      result.kgLavados === 0 ? 0 : result.consumos_agua / result.kgLavados;

    return result;
  }

  calculate_aggregation_electricidad(aggregationInfo) {
    let dataObjects = aggregationInfo.data;
    let result = {
      arg: dataObjects[0].arg,
      kgProcesados: 0,
      kgLavados: 0,
      consumos_electricidad: 0,
      consumosKgProcesados_electricidad: 0,
      consumosKgLavados_electricidad: 0,
      objetivoKpiProc_electricidad: dataObjects[0].objetivoKpiProc_electricidad,
      objetivoKpiLav_electricidad: dataObjects[0].objetivoKpiLav_electricidad,
    };

    for (let i = 0; i < dataObjects.length; i++) {
      result.kgProcesados += dataObjects[i].kgProcesados;
      result.kgLavados += dataObjects[i].kgLavados;
      result.consumos_electricidad += dataObjects[i].consumos_electricidad;
    }

    result.consumosKgProcesados_electricidad =
      result.kgProcesados === 0
        ? 0
        : result.consumos_electricidad / result.kgProcesados;
    result.consumosKgLavados_electricidad =
      result.kgLavados === 0
        ? 0
        : result.consumos_electricidad / result.kgLavados;

    return result;
  }

  calculate_aggregation_calefaccion(aggregationInfo) {
    let dataObjects = aggregationInfo.data;
    let result = {
      arg: dataObjects[0].arg,
      kgProcesados: 0,
      kgLavados: 0,
      consumos_calefaccion: 0,
      consumosKgProcesados_calefaccion: 0,
      consumosKgLavados_calefaccion: 0,
      objetivoKpiProc_calefaccion: dataObjects[0].objetivoKpiProc_calefaccion,
      objetivoKpiLav_calefaccion: dataObjects[0].objetivoKpiLav_calefaccion,
    };

    for (let i = 0; i < dataObjects.length; i++) {
      result.kgProcesados += dataObjects[i].kgProcesados;
      result.kgLavados += dataObjects[i].kgLavados;
      result.consumos_calefaccion += dataObjects[i].consumos_calefaccion;
    }

    result.consumosKgProcesados_calefaccion =
      result.kgProcesados === 0
        ? 0
        : result.consumos_calefaccion / result.kgProcesados;
    result.consumosKgLavados_calefaccion =
      result.kgLavados === 0
        ? 0
        : result.consumos_calefaccion / result.kgLavados;

    return result;
  }

  get_differenceInDays(fecha) {
    if (fecha && fecha.startDate && fecha.endDate) {
      let Difference_In_Time =
        fecha.endDate.getTime() - fecha.startDate.getTime();
      return Difference_In_Time / (1000 * 3600 * 24);
    }
    return 0;
  }

  getKgProcLav(data, oneDay) {
    let fechaIniTooltip = startOfMonth(data.arg),
      fechaFinTooltip = endOfMonth(data.arg),
      kgProcesados = 0,
      kgLavados = 0;

    let filteredData = query(this.state.datasource_dxChart)
      .filter(function (dataItem) {
        if (oneDay) {
          return dataItem.arg.getTime() === data.arg.getTime();
        } else {
          if (
            dataItem.arg >= fechaIniTooltip &&
            dataItem.arg <= fechaFinTooltip
          ) {
            return true;
          }
          return false;
        }
      })
      .toArray();
    $.each(filteredData, function (index, item) {
      kgProcesados += item.kgProcesados;
      kgLavados += item.kgLavados;
    });

    return [kgProcesados, kgLavados];
  }

  tooltipCreation(tooltipData) {
    var html = "<div>";
    html += "<div class='tooltip-header'>";
    html += tooltipData.header;
    html += "</div>";

    html += "<div class='tooltip-body'>";

    html += "<div class='tooltip-row'>";
    html += "<div class='series-name'>";
    html += tooltipData.seriesName[0];
    html += "</div>";
    html += "<div class='value-text'>";
    html += tooltipData.seriesData[0];
    html += "</div>";
    html += "</div>";

    if (tooltipData.seriesName[1]) {
      html += "<div class='tooltip-row'>";
      html += "<div class='series-name'>";
      html += tooltipData.seriesName[1];
      html += "</div>";
      html += "<div class='value-text'>";
      html += tooltipData.seriesData[1];
      html += "</div>";
      html += "</div>";
    }

    if (tooltipData.seriesName[2]) {
      html += "<div class='tooltip-row'>";
      html += "<div class='series-name'>";
      html += tooltipData.seriesName[2];
      html += "</div>";
      html += "<div class='value-text'>";
      html += tooltipData.seriesData[2];
      html += "</div>";
      html += "</div>";
    }

    if (tooltipData.kpiData) {
      html += "<div class='tooltip-row'>";
      html += "<div class='series-name'>";
      html += tooltipData.kpiName[0];
      html += "</div>";
      html += "<div class='value-text'>";
      html += tooltipData.kpiData[0];
      html += "</div>";
      html += "</div>";

      if (tooltipData.kpiData.length > 1) {
        html += "<div class='tooltip-row'>";
        html += "<div class='series-name'>";
        html += tooltipData.kpiName[1];
        html += "</div>";
        html += "<div class='value-text'>";
        html += tooltipData.kpiData[1];
        html += "</div>";
        html += "</div>";
      }
    }

    html += "</div>";
    html += "</div>";
    return html;
  }
  //#endregion

  //#region ELEMENTS
  dxButtonGroup_elementAttr = {
    id: "dxButtonGroup_tipoChart",
  };

  dxTooltipRechazo_animation = {
    show: {
      type: "pop",
      from: {
        scale: 0.1,
        opacity: 0,
      },
      to: {
        opacity: 1,
        scale: 1,
      },
    },
    hide: {
      type: "pop",
      from: {
        scale: 1,
        opacity: 1,
      },
      to: {
        opacity: 0,
        scale: 0.1,
      },
    },
  };

  loadingIndicator_font = { size: 14 };
  //#endregion

  //#region EVENTOS

  //#region DATERANGEPICKER
  dateRangePicker_onDateSelected(fecha) {
    if (fecha) {
      let { param_paginaSecundaria, minDate, maxDate } = this.state;

      let fecha_param;
      let outOfDate = false;
      if (param_paginaSecundaria != null) {
        let fechaDesde = new Date(param_paginaSecundaria.fechaDesde),
          fechaHasta = new Date(param_paginaSecundaria.fechaHasta);

        if (fechaHasta > maxDate || fechaDesde < minDate) {
          fechaDesde = startOfMonth(maxDate);
          fechaHasta = maxDate;
          outOfDate = true;
        }

        fecha_param = {
          key: "selection",
          idTipoCalendario: 0,
          fechaSel: fechaDesde,
          startDate: fechaDesde,
          endDate: fechaHasta,
        };
      }

      this.loadPanel_show();
      this.setState(
        {
          fechaSel: param_paginaSecundaria != null ? fecha_param : fecha,
          param_paginaSecundaria: null,
          dxPopup_noDatos_isVisible: outOfDate,
        },
        () => {
          let _this = this;
          this.datasource_selectCharts.reload().done(function () {
            _this.loadPanel_hide();
          });
        },
      );
    } else {
      this.loadPanel_hide();
      let totalLabels = this.set_totales_dxChart(
        null,
        this.state.idTipoChartSel,
      );
      this.setState({
        fechaSel: null,
        minDate: null,
        maxDate: null,
        datasource_dxChart: [],
        totalLabels: totalLabels,
      });
    }
  }
  //#endregion

  //#region CHART
  dxChart_onInitialized(e) {
    e.element.css("height", "100%");
  }

  dxChart_onPointClick(e) {
    let point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  }

  dxChart_onLegendClick(e) {
    let series = e.target;

    let {
      isVisible_rendimientoProd,
      isVisible_aguaProd,
      isVisible_electricidadProd,
      isVisible_calefaccionProd,
      isVisible_kgProcesados,
      isVisible_kgLavados,
      isVisible_rendimientoProc,
      isVisible_rendimientoObjProc,
      isVisible_kgRechazo,
      isVisible_objRechazo,
      isVisible_aguaProc,
      isVisible_aguaObj,
      isVisible_elecProc,
      isVisible_elecObj,
      isVisible_calProc,
      isVisible_calOjb,
    } = this.state;

    if (series.name == getTrad("kgLav_HorasProd") || series.index === 5) {
      this.setState({ isVisible_rendimientoProd: !isVisible_rendimientoProd });
    } else if (
      series.name == getTrad("lAgua_kgLavados") ||
      series.index === 11
    ) {
      this.setState({ isVisible_aguaProd: !isVisible_aguaProd });
    } else if (
      series.name == getTrad("kwhElectricidad_kgLav") ||
      series.index === 15
    ) {
      this.setState({
        isVisible_electricidadProd: !isVisible_electricidadProd,
      });
    } else if (
      series.name == getTrad("kwhCalefaccion_kgLav") ||
      series.index === 19
    ) {
      this.setState({ isVisible_calefaccionProd: !isVisible_calefaccionProd });
    } else {
      switch (series.index) {
        case 0:
          this.setState({ isVisible_kgProcesados: !isVisible_kgProcesados });
          break;
        case 1:
          this.setState({ isVisible_kgLavados: !isVisible_kgLavados });
          break;
        case 2:
          this.setState({
            isVisible_rendimientoProc: !isVisible_rendimientoProc,
          });
          break;
        case 4:
          this.setState({
            isVisible_rendimientoObjProc: !isVisible_rendimientoObjProc,
          });
          break;
        case 6:
          this.setState({ isVisible_kgRechazo: !isVisible_kgRechazo });
          break;
        case 7:
          this.setState({ isVisible_objRechazo: !isVisible_objRechazo });
          break;
        case 8:
          this.setState({ isVisible_aguaProc: !isVisible_aguaProc });
          break;
        case 10:
          this.setState({ isVisible_aguaObj: !isVisible_aguaObj });
          break;
        case 12:
          this.setState({ isVisible_elecProc: !isVisible_elecProc });
          break;
        case 14:
          this.setState({ isVisible_elecObj: !isVisible_elecObj });
          break;
        case 16:
          this.setState({ isVisible_calProc: !isVisible_calProc });
          break;
        case 18:
          this.setState({ isVisible_calOjb: !isVisible_calOjb });
          break;
      }
    }
  }

  dxChart_legend_customizeItems(items) {
    let { idTipoChartSel } = this.state;

    $.each(items, function (index, item) {
      item.visible = item.series._options.idTipoChart === idTipoChartSel;
    });
  }

  dxChart_argumentAxis_label_customizeText = function (e) {
    let { lavanderia, idioma } = this.props;
    let { fechaSel } = this.state;
    let isAnual = this.get_differenceInDays(fechaSel) > 60;

    if (isAnual) {
      var displayYear = "";
      if (
        e.value.getFullYear() !==
        convertClienteUtcToLavanderiaUtc(
          lavanderia.GMT,
          new Date(),
        ).getFullYear()
      )
        displayYear = " '" + e.value.getFullYear().toString().substr(-2);
      return (
        capitalize(
          new Intl.DateTimeFormat(idioma.codigo, { month: "short" }).format(
            e.value,
          ),
        ) + displayYear
      );
    } else {
      return (
        e.value.getDate() +
        " " +
        capitalize(
          new Intl.DateTimeFormat(idioma.codigo, { month: "short" }).format(
            e.value,
          ),
        )
      );
    }
  };

  dxChart_customizeTooltip(e) {
    let { idTipoChartSel, fechaSel } = this.state;
    let { idioma } = this.props;
    let isAnual = this.get_differenceInDays(fechaSel) > 60;

    let data = e.point.data;
    let tooltipData;

    let serieVisible,
      seriesName,
      seriesData,
      kpiName,
      kpiData,
      kgProcesados,
      kgLavados;

    switch (idTipoChartSel) {
      case 1:
        if (isAnual) {
          let kgProcesados = 0,
            kgLavados = 0;
          $.each(e.point.aggregationInfo.data, function (index, element) {
            kgProcesados += element.kgProcesados;
            kgLavados += element.kgLavados;
          });

          let val_procesados =
            kgLavados === 0 ? 0 : kgProcesados / kgLavados - 1;
          data = {
            arg: e.point.data.arg,
            kgProcesados: kgProcesados,
            kgLavados: kgLavados,
            val_procesados: val_procesados,
          };
        }
        seriesName = [getTrad("kgProcesados").toUpperCase() + ":"];
        seriesData = [formatNumber(data.kgProcesados, 2)];

        serieVisible = this.dxChart
          .getSeriesByName(getTrad("kgLavados"))
          .isVisible();

        if (serieVisible) {
          seriesName.push(getTrad("kgLavados").toUpperCase() + ":");
          seriesName.push(
            getTrad("porcentaje_kgProcesados_kgLavados").toUpperCase() + ":",
          );
          seriesData.push(formatNumber(data.kgLavados, 2));
          seriesData.push(
            data.val_procesados === 0
              ? 0
              : formatNumber(data.val_procesados, 2, "percent"),
          );
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? e.argument.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(e.argument),
          ),
          seriesName: seriesName,
          seriesData: seriesData,
          kpi: null,
        };
        break;
      case 2:
        if (isAnual) {
          let horasProductivas = 0,
            objetivoKpiProc_rendimiento = 0,
            objetivoKpiLav_rendimiento = 0;

          $.each(e.point.aggregationInfo.data, function (index, element) {
            horasProductivas += element.horasProductivas;
            objetivoKpiProc_rendimiento +=
              typeof element.objetivoKpiProc_rendimiento !== "undefined"
                ? element.objetivoKpiProc_rendimiento
                : 0;
            objetivoKpiLav_rendimiento +=
              typeof element.objetivoKpiLav_rendimiento !== "undefined"
                ? element.objetivoKpiLav_rendimiento
                : 0;
          });

          var diasLength = e.point.aggregationInfo.data.length;
          data = {
            arg: e.point.data.arg,
            horasProductivas: horasProductivas,
            horasKgProcesados:
              horasProductivas === 0
                ? 0
                : this.getKgProcLav(data)[0] / horasProductivas,
            horasKgLavados:
              horasProductivas === 0
                ? 0
                : this.getKgProcLav(data)[1] / horasProductivas,
            objetivoKpiProc_rendimiento:
              objetivoKpiProc_rendimiento / diasLength,
            objetivoKpiLav_rendimiento: objetivoKpiLav_rendimiento / diasLength,
          };
        }
        serieVisible =
          this.dxChart
            .getSeriesByName(getTrad("kgLav_HorasProd"))
            .isVisible() ||
          this.dxChart.getSeriesByName(getTrad("objKgLav")).isVisible();

        seriesName = [
          getTrad("totalHorasProd").toUpperCase() + ":",
          getTrad("kgProc_HorasProd").toUpperCase() + ":",
        ];
        seriesData = [
          formatNumber(data.horasProductivas),
          formatNumber(data.horasKgProcesados ? data.horasKgProcesados : 0, 2),
        ];

        kpiName = [getTrad("objKgProc").toUpperCase() + ":"];
        kpiData = [
          data.objetivoKpiProc_rendimiento
            ? formatNumber(data.objetivoKpiProc_rendimiento, 2)
            : "-",
        ];

        if (serieVisible) {
          seriesName.push(getTrad("kgLav_HorasProd").toUpperCase() + ":");
          seriesData.push(
            formatNumber(data.horasKgLavados ? data.horasKgLavados : 0, 2),
          );
          kpiName.push(getTrad("objKgLav").toUpperCase() + ":");
          kpiData.push(
            data.objetivoKpiLav_rendimiento
              ? formatNumber(data.objetivoKpiLav_rendimiento, 2)
              : "-",
          );
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? data.arg.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(data.arg),
          ),
          seriesName: seriesName,
          seriesData: seriesData,
          kpiName: kpiName,
          kpiData: kpiData,
        };
        break;
      case 3:
        if (isAnual) {
          let kgRechazados = 0,
            kgProcesados_rechazo = 0,
            objetivoKpi_rechazo = 0,
            diasLength = e.point.aggregationInfo.data.length;

          $.each(e.point.aggregationInfo.data, function (index, element) {
            kgRechazados += element.kgRechazados;
            kgProcesados_rechazo += element.kgProcesados_rechazo;
            objetivoKpi_rechazo += element.objetivoKpi_rechazo;
          });

          let diferenciaKg =
            kgProcesados_rechazo === 0
              ? 0
              : (kgRechazados / kgProcesados_rechazo) * 100;
          data = {
            arg: e.point.data.arg,
            kgRechazados: kgRechazados,
            kgProcesados_rechazo: kgProcesados_rechazo,
            val_rechazados: diferenciaKg,
            objetivoKpi_rechazo: objetivoKpi_rechazo / diasLength,
          };
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? data.arg.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(data.arg),
          ),
          seriesName: [
            getTrad("kgRechazados").toUpperCase() + ":",
            getTrad("kgProcesados").toUpperCase() + ":",
            getTrad("porcentajeRechazo").toUpperCase() + ":",
          ],
          seriesData: [
            formatNumber(data.kgRechazados, 2),
            formatNumber(data.kgProcesados_rechazo, 2),
            data.val === 0 ? 0 : formatNumber(data.val_rechazados, 2) + " %",
          ],
          kpiName: [getTrad("objetivoRechazo").toUpperCase() + ":"],
          kpiData: ["<= " + formatNumber(data.objetivoKpi_rechazo, 2) + " %"],
        };
        break;
      case 4:
        if (isAnual) {
          kgProcesados = this.getKgProcLav(data)[0];
          kgLavados = this.getKgProcLav(data)[1];
          let consumos_agua = 0,
            objetivoKpiProc_agua = 0,
            objetivoKpiLav_agua = 0;

          $.each(e.point.aggregationInfo.data, function (index, element) {
            consumos_agua += element.consumos_agua;
            objetivoKpiProc_agua +=
              typeof element.objetivoKpiProc_agua !== "undefined"
                ? element.objetivoKpiProc_agua
                : 0;
            objetivoKpiLav_agua +=
              typeof element.objetivoKpiLav_agua !== "undefined"
                ? element.objetivoKpiLav_agua
                : 0;
          });

          let diasLength = e.point.aggregationInfo.data.length;
          data = {
            arg: e.point.data.arg,
            consumos_agua: consumos_agua,
            consumosKgProcesados_agua:
              kgProcesados === 0 ? 0 : consumos_agua / kgProcesados,
            consumosKgLavados_agua:
              kgLavados === 0 ? 0 : consumos_agua / kgLavados,
            objetivoKpiProc_agua: objetivoKpiProc_agua / diasLength,
            objetivoKpiLav_agua: objetivoKpiLav_agua / diasLength,
          };
        } else {
          kgProcesados = this.getKgProcLav(data, true)[0];
          kgLavados = this.getKgProcLav(data, true)[1];
        }
        serieVisible = this.dxChart
          .getSeriesByName(getTrad("lAgua_kgLavados"))
          .isVisible();

        seriesName = [
          getTrad("totalLAgua").toUpperCase() + ":",
          getTrad("lAgua_kgProcesados").toUpperCase() + ":",
        ];
        seriesData = [
          formatNumber(data.consumos_agua),
          formatNumber(
            kgProcesados > 0 ? data.consumos_agua / kgProcesados : 0,
            2,
          ),
        ];
        kpiName = [getTrad("objKgProc").toUpperCase() + ":"];
        kpiData = [
          data.objetivoKpiProc_agua
            ? formatNumber(data.objetivoKpiProc_agua, 2)
            : "-",
        ];

        if (serieVisible) {
          seriesName.push(getTrad("lAgua_kgLavados").toUpperCase() + ":");
          seriesData.push(
            formatNumber(kgLavados > 0 ? data.consumos_agua / kgLavados : 0, 2),
          );
          kpiName.push(getTrad("objKgLav").toUpperCase() + ":");
          kpiData.push(
            data.objetivoKpiLav_agua
              ? formatNumber(data.objetivoKpiLav_agua, 2)
              : "-",
          );
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? data.arg.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(data.arg),
          ),
          seriesName: seriesName,
          seriesData: seriesData,
          kpiName: kpiName,
          kpiData: kpiData,
        };
        break;
      case 5:
        if (isAnual) {
          kgProcesados = this.getKgProcLav(data)[0];
          kgLavados = this.getKgProcLav(data)[1];
          let consumos_electricidad = 0,
            objetivoKpiProc_electricidad = 0,
            objetivoKpiLav_electricidad = 0;

          $.each(e.point.aggregationInfo.data, function (index, element) {
            consumos_electricidad += element.consumos_electricidad;
            objetivoKpiProc_electricidad +=
              typeof element.objetivoKpiProc_electricidad !== "undefined"
                ? element.objetivoKpiProc_electricidad
                : 0;
            objetivoKpiLav_electricidad +=
              typeof element.objetivoKpiLav_electricidad !== "undefined"
                ? element.objetivoKpiLav_electricidad
                : 0;
          });

          let diasLength = e.point.aggregationInfo.data.length;

          data = {
            arg: e.point.data.arg,
            consumos_electricidad: consumos_electricidad,
            consumosKgProcesados_electricidad:
              kgProcesados === 0 ? 0 : consumos_electricidad / kgProcesados,
            consumosKgLavados_electricidad:
              kgLavados === 0 ? 0 : consumos_electricidad / kgLavados,
            objetivoKpiProc_electricidad:
              objetivoKpiProc_electricidad / diasLength,
            objetivoKpiLav_electricidad:
              objetivoKpiLav_electricidad / diasLength,
          };
        } else {
          kgProcesados = this.getKgProcLav(data, true)[0];
          kgLavados = this.getKgProcLav(data, true)[1];
        }

        serieVisible = this.dxChart
          .getSeriesByName(getTrad("kwhElectricidad_kgLav"))
          .isVisible();
        seriesName = [
          getTrad("totalKwhElectricidad").toUpperCase() + ":",
          getTrad("kwhElectricidad_kgProc").toUpperCase() + ":",
        ];
        seriesData = [
          formatNumber(data.consumos_electricidad),
          formatNumber(
            kgProcesados > 0 ? data.consumos_electricidad / kgProcesados : 0,
            2,
          ),
        ];
        kpiName = [getTrad("objKgProc").toUpperCase() + ":"];
        kpiData = [
          data.objetivoKpiProc_electricidad
            ? formatNumber(data.objetivoKpiProc_electricidad, 2)
            : "-",
        ];

        if (serieVisible) {
          seriesName.push(getTrad("kwhElectricidad_kgLav").toUpperCase() + ":");
          seriesData.push(
            formatNumber(
              kgLavados > 0 ? data.consumos_electricidad / kgLavados : 0,
              2,
            ),
          );
          kpiName.push(getTrad("objKgLav").toUpperCase() + ":");
          kpiData.push(
            data.objetivoKpiLav_electricidad
              ? formatNumber(data.objetivoKpiLav_electricidad, 2)
              : "-",
          );
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? data.arg.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(data.arg),
          ),
          seriesName: seriesName,
          seriesData: seriesData,
          kpiName: kpiName,
          kpiData: kpiData,
        };
        break;
      case 6:
        if (isAnual) {
          kgProcesados = this.getKgProcLav(data)[0];
          kgLavados = this.getKgProcLav(data)[1];
          let consumos_calefaccion = 0,
            objetivoKpiProc_calefaccion = 0,
            objetivoKpiLav_calefaccion = 0;

          $.each(e.point.aggregationInfo.data, function (index, element) {
            consumos_calefaccion += element.consumos_calefaccion;
            objetivoKpiProc_calefaccion +=
              typeof element.objetivoKpiProc_calefaccion !== "undefined"
                ? element.objetivoKpiProc_calefaccion
                : 0;
            objetivoKpiLav_calefaccion +=
              typeof element.objetivoKpiLav_calefaccion !== "undefined"
                ? element.objetivoKpiLav_calefaccion
                : 0;
          });

          let diasLength = e.point.aggregationInfo.data.length;

          data = {
            arg: e.point.data.arg,
            consumos_calefaccion: consumos_calefaccion,
            consumosKgProcesados_calefaccion:
              kgProcesados === 0 ? 0 : consumos_calefaccion / kgProcesados,
            consumosKgLavados_calefaccion:
              kgLavados === 0 ? 0 : consumos_calefaccion / kgLavados,
            objetivoKpiProc_calefaccion:
              objetivoKpiProc_calefaccion / diasLength,
            objetivoKpiLav_calefaccion: objetivoKpiLav_calefaccion / diasLength,
          };
        } else {
          kgProcesados = this.getKgProcLav(data, true)[0];
          kgLavados = this.getKgProcLav(data, true)[1];
        }

        serieVisible = this.dxChart
          .getSeriesByName(getTrad("kwhCalefaccion_kgLav"))
          .isVisible();
        seriesName = [
          getTrad("totalKwhCalefaccion").toUpperCase() + ":",
          getTrad("kwhCalefaccion_kgProc").toUpperCase() + ":",
        ];
        seriesData = [
          formatNumber(data.consumos_calefaccion),
          formatNumber(
            kgProcesados > 0 ? data.consumos_calefaccion / kgProcesados : 0,
            2,
          ),
        ];
        kpiName = [getTrad("objKgProc").toUpperCase() + ":"];
        kpiData = [
          data.objetivoKpiProc_calefaccion
            ? formatNumber(data.objetivoKpiProc_calefaccion, 2)
            : "-",
        ];

        if (serieVisible) {
          seriesName.push(getTrad("kwhCalefaccion_kgLav").toUpperCase() + ":");
          seriesData.push(
            formatNumber(
              kgLavados > 0 ? data.consumos_calefaccion / kgLavados : 0,
              2,
            ),
          );
          kpiName.push(getTrad("objKgLav").toUpperCase() + ":");
          kpiData.push(
            data.objetivoKpiLav_calefaccion
              ? formatNumber(data.objetivoKpiLav_calefaccion, 2)
              : "-",
          );
        }

        tooltipData = {
          header: capitalize(
            isAnual
              ? data.arg.toLocaleString(idioma.codigo, { month: "long" })
              : formatDate(data.arg),
          ),
          seriesName: seriesName,
          seriesData: seriesData,
          kpiName: kpiName,
          kpiData: kpiData,
        };

        break;
    }
    return {
      html: this.tooltipCreation(tooltipData),
    };
  }

  //#endregion

  //#region POPUP
  dxPopup_noDatos_buttonOnClick(e) {
    this.setState({ dxPopup_noDatos_isVisible: false });
  }

  dxPopup_noDatos_contentRender(e) {
    return (
      <div style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>
        <p>{getTrad("sinDatos_filtro").replace("<br />", "\n")}</p>
      </div>
    );
  }

  dxPopup_noDatos_onContentReady(e) {
    var html = e.component.content();
    $(html).css("padding-top", "20px");
    $(html).css("padding-bottom", "10px");
    $(html).css("text-align", "center");
  }
  //#endregion

  //#region OTROS WIDGETS
  dxTooltipRechazo_contentRender(e) {
    return <div>{getTrad("kgProcTipoProd").toUpperCase()}</div>;
  }

  dxButtonGroup_buttonRender(data, $buttonContent) {
    return (
      <div
        className="d-flex he-100 justify-content-center align-items-center"
        style={{ "--buttonGroup_color": "var(" + data.color + ")" }}
      >
        <i
          style={{ flexBasis: 30, color: "red" }}
          className={"font-size-xxl w-100 " + data.icon}
        ></i>
        <span
          style={{ lineHeight: "12px" }}
          className="d-flex flex-column w-100 he-100 align-items-center justify-content-center"
        >
          {data.denominacion}
        </span>
      </div>
    );
  }

  dxButtonGroup_onItemClick(e) {
    this.setState({
      idTipoChartSel: e.itemData.idTipoChart,
      totalLabels: this.set_totales_dxChart(
        this.state.datasource_dxChart,
        e.itemData.idTipoChart,
      ),
    });
    this.dxChart.refresh();
  }

  dxSelectBox_tipoChart_fieldRender(e) {
    return (
      <div className="d-flex flex-row he-100 align-items-center justify-content-center">
        <i
          style={{ flexBasis: 30, paddingTop: 8, paddingLeft: 8 }}
          className={" font-size-xl he-100 " + e.icon}
        ></i>
        <TextBox className="flex" defaultValue={e.denominacion} />
      </div>
    );
  }

  dxSelectBox_tipoChart_itemRender(e) {
    return (
      <div className="d-flex flex-row align-items-center">
        <i style={{ flexBasis: 30 }} className={"font-size-lg " + e.icon}></i>
        <div className="flex">{e.denominacion}</div>
      </div>
    );
  }

  dxSelectBox_tipoChart_onSelectionChanged(e) {
    let idTipoChart = e.selectedItem.idTipoChart;
    this.setState({
      idTipoChartSel: idTipoChart,
      totalLabels: this.set_totales_dxChart(
        this.state.datasource_dxChart,
        idTipoChart,
      ),
    });
    this.dxChart.refresh();
  }

  //#endregion

  //#endregion

  componentDidUpdate(prevProps) {
    let { lavanderia, idioma, param_paginaSecundaria } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma ||
      (param_paginaSecundaria !== prevProps.param_paginaSecundaria &&
        param_paginaSecundaria != null)
    ) {
      if (
        lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
      ) {
        this.loadPanel_show();
        this.cargaDatos_lavanderia();
      }
    }
  }

  componentDidMount() {
    let { param_paginaSecundaria } = this.props;

    this.setState({ param_paginaSecundaria: param_paginaSecundaria });
    this.cargaDatos_lavanderia();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfOperativo);
