import React from "react";

class CierreCell extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const { value } = this.props.data;
        return value.length > 0 ? (
            <i className="position-absolute-0 d-flex align-items-center justify-content-center font-size-xl icon_lockOutline" />
        ) : null;
    }
}

export default CierreCell;
