import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { store } from "../../config";

import ResizeDetector from "react-resize-detector";

import { globalActions } from "actions";
// Layout
import AppMain from "../../layout/AppMain";

//Component
import CheckToken from "../../components/CheckToken";
import CookieBanner from "react-cookie-banner";
import CustomCookieBanner from "../../layout/CustomCookieBanner";

import esMessages from "devextreme/localization/messages/es.json";
import ptMessages from "devextreme/localization/messages/pt.json";
import { locale, loadMessages } from "devextreme/localization";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closedSmallerSidebar: false,
    };

    loadMessages(esMessages);
    loadMessages(ptMessages);
    locale(navigator.language.substring(0, 2));
  }

  onResize = (width, height) => {
    this.props.setResolucion({ width: width, height: height });
  };

  render() {
    let {
      colorScheme,
      enableFixedHeader,
      enableFixedSidebar,
      enableFixedFooter,
      enableClosedSidebar,
      closedSmallerSidebar,
      enableMobileMenu,
      enablePageTabsAlt,
      idUsuarioOriginal,
    } = this.props;

    return (
      <ResizeDetector
        handleWidth
        skipOnMount={true}
        onResize={this.onResize}
        render={({ width }) => (
          <Fragment>
            {idUsuarioOriginal != null && <div className="redFrame" />}
            <div
              className={cx(
                "he-100",
                "app-container app-theme-" + colorScheme,
                { "fixed-header": enableFixedHeader },
                {
                  "fixed-sidebar":
                    enableFixedSidebar ||
                    (width == null ? window.screen.width < 1800 : width < 1800),
                },
                { "fixed-footer": enableFixedFooter },
                {
                  "closed-sidebar":
                    enableClosedSidebar ||
                    (width == null ? window.screen.width < 1800 : width < 1800),
                },
                {
                  "closed-sidebar-mobile":
                    closedSmallerSidebar ||
                    (width == null ? window.screen.width < 1800 : width < 1800),
                },
                { "sidebar-mobile-open": enableMobileMenu },
                { "body-tabs-shadow-btn": enablePageTabsAlt },
              )}
            >
              <CookieBanner
                cookie="user-has-accepted-cookies"
                disableStyle={true}
              >
                {(onAccept) => <CustomCookieBanner onAccept={onAccept} />}
              </CookieBanner>

              <AppMain />
              <CheckToken />

              <div id="dxPopup_AlertPregunta" />
            </div>
          </Fragment>
        )}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setResolucion: (resolucion) =>
    dispatch(globalActions.setResolucion(resolucion)),
});

const mapStateToProp = (state) => ({
  colorScheme: state.ThemeOptions.colorScheme,
  enableFixedHeader: state.ThemeOptions.enableFixedHeader,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableFixedFooter: state.ThemeOptions.enableFixedFooter,
  enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
  idUsuarioOriginal: state.Global.idUsuarioOriginal,
});

export default connect(mapStateToProp, mapDispatchToProps)(Main);
