import React, { useEffect, useMemo, useState } from "react";

import DataGrid, {
    Column,
    ColumnChooser,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    FilterRow,
    Pager,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import { getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { getClearPedido } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    filterArticulo,
    onCellPrepared_Total,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblCliente,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblViasPagoCobro,
    datasource_tblAdmPedido_Estado,
    formats,
    sharedEditorOptions,
    datasource_tblAdmTipoElemento,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import config_isCerrado from "pages/Administracion/shared/configs/config_isCerrado";

const DataGridPedidosCliente = ({
    datasource,
    setPedidoSel,
    openPopup,
    reloadData,
    idEmpresaPolarier,
    loadPanel_hide,
}) => {
    const [clientes, setClientes] = useState([]);
    const [centrosCoste, setCentrosCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);

    useEffect(() => {
        Promise.all([
            datasource_tblCliente.reload(),
            datasource_tblAdmCentroCoste.reload(),
            datasource_tblAdmElementoPEP.reload(),
        ]).then((data) => {
            setClientes(datasource_tblCliente.items());
            setCentrosCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    const calculateCellValue = (e) => {
        if (e.tblArticuloNAdmPedidoCliente.length > 0) {
            let total = 0;
            e.tblArticuloNAdmPedidoCliente.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += cantidad * precio * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
            return {
                valor: calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "venta"),
                idMoneda: e.idMoneda,
            };
        }
    };

    const cellRender = (e) => {
        return calculateDisplayValue(isNaN(e.value?.valor) ? 0 : e.value?.valor, e.value.idMoneda);
    };

    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    widget: "dxButton",
                    options: {
                        icon: "plus",
                        text: getTrad("nuevoPedido"),
                        onClick: () => onNewPedido(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            reloadData();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewPedido = () => {
        const pedido = getClearPedido();
        setPedidoSel({ ...pedido });
        openPopup();
    };

    const onRowPrepared_tblPedidos = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onRowClick_tblPedidos = useMemo(() => {
        return (e) => {
            if (e.data) {
                setPedidoSel({ ...e.data, articulosDraft: [...e.data.tblArticuloNAdmPedidoCliente] });
                openPopup();
            }
        };
    }, []);

    return (
        <DataGrid
            dataSource={datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblPedidos}
            onCellPrepared={onCellPrepared_Total}
            onRowClick={onRowClick_tblPedidos}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                dataField={"idTipoPedido"}
                caption={getTrad("tipoPedido")}
                dataType={"string"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
                cellComponent={(e) => TipoElemento(e.data.data.idTipoPedido)}
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmTipoElemento.store()}
                    valueExpr="idAdmTipoElemento"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fechaCreacion"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmCliente"}
                caption={getTrad("cliente")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={clientes} valueExpr="idAdmCliente" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmPedido_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmPedido_Estado, "", "pedido")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmPedido_Estado.store()}
                    valueExpr="idAdmPedido_Estado"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            {/* <Column
                dataField={"fechaEstimadaRecepcion"}
                caption={getTrad("fechaEstimadaRecepcion")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column dataField={"idMoneda"} caption={getTrad("moneda")} visible={false} allowReordering allowResizing>
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"ordenCompraCliente"}
                caption={getTrad("ordenCompraCliente")}
                dataType={"string"}
                alignment={"center"}
                visible={false}
                allowReordering
                allowResizing
            />
            {/* <Column
                dataField={"idPedidoCliente"}
                caption={getTrad("pedidoCliente")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                allowReordering
                format={formats.percent}
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            {/* <Column
                dataField={"numPresupuestoProveedor"}
                caption={getTrad("numPresupuestoProveedor")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                visible={true}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue}
                cellRender={cellRender}
            />
            <Column {...config_isCerrado} />
            <Column
                dataField={"tblArticuloNAdmPedidoCliente"}
                dataType={"object"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            {/* <Column
        caption={' '}
        width={30}
        alignment={'center'}
        cssClass={'p-0'}
        cellComponent={removeCellComponent}
      /> */}
            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

export default DataGridPedidosCliente;
