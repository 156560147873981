import React from "react";

import ScrollView from "devextreme-react/scroll-view";

import { Card, CardBody } from "reactstrap";

const DisplayConfiltos = ({ dataSource, setConflictosSel }) => {
  return (
    <ScrollView height={590}>
      <div className={"d-flex flex-row flex-wrap justify-content-between he-100 w-100 px-3"}>
        {dataSource.sort((a, b) => b.conflictos.length - a.conflictos.length).map((c, index) => {
          const hayConflictos = c.conflictos.length > 0;

          return <div
            key={c.field + index}
            className={"p-3 w-50"}
          >
            <Card
              className={`rounded-3 hover zoom card-btm-border ${hayConflictos ? "pointer border-danger" : "border-success"}`}
              onClick={hayConflictos ? () => setConflictosSel(c) : undefined}
            >
              <CardBody className={"px-5"}>
                <div className={"d-flex justify-content-between align-items-center"}>
                  <p className={`font-size-xxxl mr-4 mb-0`}>{c.denominacion}</p>
                  {hayConflictos
                    ? <i className={"icon_alert-circle danger font-size-xxxl"} />
                    : <i className={"icon_Success font-size-xxxl"} />
                  }
                </div>
                {hayConflictos
                  ? <p className={"text-danger mb-0"}>Se han detectado <strong>{c.conflictos.length}</strong> conflictos</p>
                  : <p className={"mb-0"}>No se han detectado conflictos</p>
                }
              </CardBody>
            </Card>
          </div>
        })}
      </div>
    </ScrollView>
  );
}

export default DisplayConfiltos;