import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";

import DxForm, { EmptyItem, GroupItem, Item } from "devextreme-react/form";
import { getTrad } from "helpers";

import { Card, CardBody } from "reactstrap";

import {
    datasource_tblAdmTipoFactura,
    datasource_tblAdmTipoElemento,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import { generateCode } from "pages/Administracion/shared/functions";
import { Button } from "devextreme-react";
import AlbaranCompra, { MainScreenContext } from "pages/Administracion/AlbaranCompra";
import DataGridConceptos from "components/Administracion/DataGridConceptos";
import { payloadBuilder } from "pages/Administracion/AlbaranCompra/model";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import CargarAlbaran from "pages/Administracion/shared/components/CargarAlbaran";
import CentroCosteElementoPepComponent from "pages/Administracion/shared/components/CentroCosteElementoPepComponent";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";
import CancelarFacturaComponent from "pages/Administracion/shared/components/CancelarFacturaComponent";
import { getClearFactura } from "pages/Administracion/FacturaCompra/model";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda }, ref) => {
    const [cargarAlbaranPopup_visibility, setCargarAlbaranPopup_visibility] = useState(false);
    const [codigoElemento, setCodigoElemento] = useState();
    const getText = (clave) => ({ text: getTrad(clave) });
    const previousCode = useRef();
    const formSuperiorRef = useRef();
    const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const CCEPFormRef = useRef();
    const TCTCFormRef = useRef();
    const colCount = 5;
    const multiplier = 3;
    
    useImperativeHandle(ref, () => ({
        getFormData: () => formSuperiorRef.current.instance.option("formData"),
        hasEditData,
    }));

    useEffect(() => {
        validations.set("forms", checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setCodigoElemento(formData.codigo);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if(prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const checkIsValid = () => {
        const isCCEPFormValidated = CCEPFormRef?.current?.checkIsValid();
        const TCTCFormValidated = TCTCFormRef?.current?.checkIsValid();
        const isSuperiorFormValidated = formSuperiorRef.current.instance.validate();
        const isInferiorFormValidated = formInferiorRef.current.instance.validate();
        const isFormValidated = isSuperiorFormValidated.isValid && isInferiorFormValidated.isValid && isCCEPFormValidated.isValid && TCTCFormValidated.isValid;
        return new Promise((resolve, reject) => {
            if (isFormValidated) {
                resolve();
            }
            reject();
        });
    };

    const cargarAlbaranes = () => {
        setCargarAlbaranPopup_visibility(true);
    };

    const labels = {
        codigo: getText("codigo"),
        idAdmProveedor: getText("proveedor"),
        fecha: getText("fecha"),
        idTipoFactura: getText("tipoFactura"),
        idTipoAlbaran: getText("tipoAlbaran"),
        idMoneda: getText("moneda"),
        idAdmFormaPago: getText("formaPago"),
        idAdmCondicionPago: getText("condicionesPagoCobro"),
        descuento: getText("descuento"),
        idIncoterm: getText("incoterms"),
        numFacturaProveedor: getText("numFacturaProveedor"),
        observaciones: getText("observaciones"),
        bruto: { text: "Total Bruto" }, //getText("totalBruto"),
        neto: getText("neto"),
    };

    const editorOptions = {
        idTipoFactura: {
            dataSource: datasource_tblAdmTipoFactura, //el tipo de Factura se determina por el tipo de factura
            valueExpr: "idTipoFactura",
            displayExpr: "denominacion",
            placeholder: getTrad("tipoFactura"),
        },
        idTipoAlbaran: {
            dataSource: datasource_tblAdmTipoElemento, //el tipo de Factura se determina por el tipo de factura
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) => e.value != null && 
                generateCode(formSuperiorRef.current.instance.option("formData").fecha ?? new Date() , setCodigoElemento_Wrapper, e.value, "facturaCompra"),
            placeholder: getTrad("tipoAlbaran"),
            readOnly: formData.idAdmAlbaranCompra?.length > 0,
        },
        idMoneda: {
            ...sharedEditorOptions.idMoneda,
            readOnly: formData.idAdmAlbaranCompra?.length > 0,
        },
        cargarAlbaranes: {
            text: getTrad("asociarAlbaranes"),
            disabled: formData.isCerrado,
            onClick: cargarAlbaranes,
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
        }
        if (e.dataField === "fecha" && formData.idTipoAlbaran != null) {
            generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoAlbaran, "facturaCompra");
        }
    };

    const [contextValue, setContextValue] = useState({
        mainScreen: false,
        validations,
        updateSummary: summaryFormRef?.current?.updateSummary,
    });

    const setAlbaran = (data) => {
        const form = formSuperiorRef.current.instance;

        form.option("formData").idAdmProveedor = data.idAdmProveedor;
        form.option("formData").idAdmCentroCoste = data.idAdmCentroCoste;
        form.option("formData").idAdmElementoPEP = data.idAdmElementoPEP;
        form.option("formData").idMoneda = data.idMoneda;
        form.option("formData").tasaCambio = data.tasaCambio;
        form.option("formData").idAdmTipoCambio = data.idAdmTipoCambio;
        form.option("formData").idAdmAlbaranCompra = data.idAdmAlbaranCompra;
        form.option("formData").idTipoAlbaran = data.idTipoAlbaran;
        form.option("formData").idAdmFormaPago = data.idAdmFormaPago;
        form.option("formData").idIncoterm = data.idIncoterm;

        if (data.idTipoAlbaran !== undefined) {
            const fecha = formSuperiorRef.current.instance.option("formData").fecha ?? new Date();
            generateCode(fecha, setCodigoElemento_Wrapper, data.idTipoAlbaran, "facturaCompra");
        }

        if (data.idMoneda !== contextValue.idMoneda) {
            let obj = { ...contextValue };
            obj.idMoneda = data.idMoneda;
            setContextValue(obj);
        }
        setIdMoneda(data.idMoneda);
    };

    const updateState = (state) => {
        formSuperiorRef.current.instance.option("formData").idAdmFactura_Estado = state;
    };

    const hasEditData = (referenceFormData = getClearFactura()) => {
        let editData = true;
        const {
            idAdmProveedor,
            idAdmCentroCoste,
            idAdmElementoPEP,
            idMoneda,
            tasaCambio,
            idAdmTipoCambio,
            idAdmAlbaranCompra,
            // idTipoAlbaran,
            idAdmFormaPago,
            idIncoterm
        } = formSuperiorRef.current.instance.option("formData");
        if (
            idAdmProveedor == referenceFormData.idAdmProveedor &&
            idAdmCentroCoste == referenceFormData.idAdmCentroCoste &&
            idAdmElementoPEP == referenceFormData.idAdmElementoPEP &&
            idMoneda == referenceFormData.idMoneda &&
            tasaCambio == referenceFormData.tasaCambio &&
            idAdmTipoCambio == referenceFormData.idAdmTipoCambio &&
            idAdmAlbaranCompra.length == referenceFormData.idAdmAlbaranCompra.length &&
            // idTipoAlbaran == referenceFormData.idTipoAlbaran &&
            idAdmFormaPago == referenceFormData.idAdmFormaPago &&
            idIncoterm == referenceFormData.idIncoterm
        ) {
            editData = false;
        }
        return editData;
    }

    return (
        <>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex"}>
                        <Button {...editorOptions.cargarAlbaranes} />
                        <div className="ml-auto ">
                            <CancelarFacturaComponent formData={formData} updateState={updateState} />
                        </div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={formData.isCerrado}
                        >
                            <Item
                                dataField={"idTipoFactura"}
                                label={labels.idTipoFactura}
                                editorOptions={editorOptions.idTipoFactura}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier - 1}
                                isRequired
                            />
                            <Item
                                dataField={"idTipoAlbaran"}
                                label={labels.idTipoAlbaran}
                                editorOptions={editorOptions.idTipoAlbaran}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier - 1}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                editorOptions={sharedEditorOptions.codigo}
                                editorType={"dxTextBox"}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fecha"}
                                label={labels.fecha}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idMoneda}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                colSpan={2 * multiplier - 2}
                            >
                                <TasaCambioTipoCambioComponent
                                    ref={TCTCFormRef}
                                    formData={formData}
                                />
                            </Item>
                            <Item
                                dataField={"numFacturaProveedor"}
                                label={labels.numFacturaProveedor}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmProveedor"}
                                label={labels.idAdmProveedor}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idAdmProveedor}
                                colSpan={2 * multiplier}
                                isRequired
                            />
                            <Item
                                colSpan={2 * multiplier}
                            >
                                <CentroCosteElementoPepComponent ref={CCEPFormRef} formData={formData} readOnly={formData?.idAdmAlbaranCompra?.length > 0 ? true : false}/>
                                {/* <CustomRule validationCallback={CCEPFormRef?.current?.checkIsValid}/> */}
                            </Item>
                        </DxForm>
                    </div>
                </CardBody>
            </Card>

            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "302px",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "visible",
                    }}
                >
                    <CardBody>
                        <MainScreenContext.Provider
                            value={{
                                ...contextValue,
                                idMoneda,
                                datasource:
                                    formData.idAdmAlbaranCompra != null
                                        ? formData.idAdmAlbaranCompra.map((x) => payloadBuilder(x, false))
                                        : null,
                            }}
                        >
                            {formData.idAdmAlbaranCompra?.length > 0 ? (
                                <AlbaranCompra updateSummary={summaryFormRef?.current?.updateSummary} />
                            ) : (
                                <DataGridConceptos
                                    idMoneda={idMoneda}
                                    dataSource={formData.tblAdmConceptoCompra}
                                    validations={validations}
                                    isCompra={true}
                                    updateSummary={summaryFormRef?.current?.updateSummary}
                                />
                            )}
                        </MainScreenContext.Provider>
                    </CardBody>
                </Card>
            </div>
            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <DxForm
                                ref={formInferiorRef}
                                formData={formData}
                                onFieldDataChanged={onFieldDataChanged}
                                colCount={4 * multiplier}
                                height={"100%"}
                                width={"100%"}
                                labelLocation={"top"}
                                readOnly={formData.isCerrado}
                            >
                                <Item
                                    dataField={"idIncoterm"}
                                    label={labels.idIncoterm}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idIncoterm}
                                    colSpan={1 * multiplier}
                                />
                                <Item
                                    dataField={"idAdmFormaPago"}
                                    label={labels.idAdmFormaPago}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idAdmFormaPago}
                                    colSpan={1 * multiplier}
                                    isRequired
                                />
                                <Item
                                    dataField={"observaciones"}
                                    label={labels.observaciones}
                                    editorType={"dxTextArea"}
                                    colSpan={2 * multiplier}
                                />
                                <EmptyItem colSpan={1 * multiplier} />
                                <Item
                                    dataField={"idAdmCondicionPago"}
                                    label={labels.idAdmCondicionPago}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idAdmCondicionPago}
                                    colSpan={1 * multiplier}
                                    isRequired
                                />
                            </DxForm>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginRight: 0,
                            marginLeft: "auto",
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <TablaResumen
                                ref={summaryFormRef}
                                formData={formData}
                                idMoneda={idMoneda}
                                showIva={false}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <CargarAlbaran
                apartado={"Compra"}
                idAdmFactura={formData.idAdmFacturaCompra}
                albaranesSeleccionados={formData.idAdmAlbaranCompra}
                cargarAlbaranPopup_visibility={cargarAlbaranPopup_visibility}
                setCargarAlbaranPopup_visibility={setCargarAlbaranPopup_visibility}
                setAlbaran={setAlbaran}
                hasEditData={hasEditData}
            />
        </>
    );
});

export default Form;
