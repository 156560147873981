const IdAlmacenOrigenColumn = (getTrad) => ({
  dataField: "idAlmacenOrigenNavigation.denominacion",
  caption: getTrad("almacenOrigen"),
  minWidth: 250,
  alignment: "left",
  allowResizing: true,
  showInColumnChooser: true,
  visible: false,
});

export default IdAlmacenOrigenColumn;
