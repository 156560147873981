import React, { Fragment } from "react";

export default () => {
  return (
    <Fragment>
      <div id="opciones_container">
        <div id="dxForm_opciones"></div>
      </div>
    </Fragment>
  );
};
