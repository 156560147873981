import React from "react";
import { connect } from "react-redux";

import ko from "knockout";
import "devexpress-reporting/dx-webdocumentviewer";

import { connectionConstants } from "../../constants";
import { authHeader, getTrad, dxMensajePregunta } from "../../helpers";

import analitics_ES from "./localization/dx-analytics-core.es.json";
import analitics_EN from "./localization/dx-analytics-core.en.json";
import analitics_PT from "./localization/dx-analytics-core.pt.json";

import reporting_ES from "./localization/dx-reporting.es.json";
import reporting_EN from "./localization/dx-reporting.en.json";
import reporting_PT from "./localization/dx-reporting.pt.json";

import "./Css.scss";

import ODataContext from "devextreme/data/odata/context";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import notify from "devextreme/ui/notify";

import $ from "jquery";

import { loadPanelActions, impresionActions } from "../../actions";
import Guid from "devextreme/core/guid";

class ReportViewer extends React.Component {
  constructor(props) {
    super(props);

    let { report } = this.props;

    this.JSReportViewer = null;

    this.reportUrl = ko.observable(report.denominacion);
    this.requestOptions = {
      host: connectionConstants.REPORT_URL,
      invokeAction: "/WebDocumentViewer/Invoke",
    };
    let _this = this;

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);

    // CERRAR DOCUMENT VIEWER
    this.close = this.close.bind(this);

    //DATASOURCE
    this.context_enviarReportCorreo_beforeSend =
      this.context_enviarReportCorreo_beforeSend.bind(this);

    this.callbacks = {
      DocumentReady: function (s, e) {
        let button_mail = $(".sendMail_report");
        if (_this.props.report.enviarMail) button_mail.parent().show();
        else button_mail.parent().hide();

        $(".dxrdp-parameters-reset").hide();
      },
      Init: function (s) {
        _this.JSReportViewer = s;

        var reportPreview = s.GetPreviewModel().reportPreview;
        var currentExportOptions = reportPreview.exportOptionsModel;
        var optionsUpdating = false;
        var fixExportOptions = function (options) {
          try {
            optionsUpdating = true;
            if (!options) {
              currentExportOptions(null);
            } else {
              delete options["rtf"];
              delete options["mht"];
              delete options["html"];
              delete options["textExportOptions"];
              delete options["csv"];
              delete options["image"];
              currentExportOptions(options);
            }
          } finally {
            optionsUpdating = false;
          }
        };
        currentExportOptions.subscribe(function (newValue) {
          !optionsUpdating && fixExportOptions(newValue);
        });
        fixExportOptions(currentExportOptions());

        const { hideFilters, hideToolbar } = _this.props;
        const container = $(`#${_this.id}`);

        if (hideFilters) {
          container.find(".dxrd-tab-panel-right").hide();
          container.find(".dxrd-preview-wrapper").css("right", 0);
        }

        if (hideToolbar) {
          container.find(".dxrd-toolbar-wrapper").hide();
          container.find(".dxrd-preview-wrapper").css("top", 34);
        }
      },
      CustomizeMenuActions: function (s, e) {
        var search = e.GetById("dxxrp-search");
        if (search) search.visible = false;

        var editing = e.GetById("dxxrp-highlight-editing-fields");
        if (editing) editing.visible = false;

        var editing = e.GetById("dxxrp-highlight-editing-fields");
        if (editing) editing.visible = false;

        e.Actions.push({
          clickAction: function () {
            _this.loadPanel_show(true);
            _this.close(); // Cerrar Document Viewer
            $.when(
              _this.datasource_tblCorreosNLav.load(),
              _this.datasource_tblCorreosNEntidad.load(),
              _this.context_enviarReportCorreo.invoke(
                "enviarReportCorreo",
                {},
                "POST",
              ),
            )
              .then(function (correosLavanderia, correosEntidad) {
                _this.loadPanel_hide();
                let items_correos = $.extend(
                  correosEntidad[0],
                  correosLavanderia[0],
                );

                let text = "<div />";
                for (var i = 0; i < items_correos.length; i++) {
                  text += items_correos[i].denominacion + " <br />";
                }

                dxMensajePregunta(
                  getTrad("correoEnviadoEmails") + " <br />" + text,
                  [[getTrad("aceptar"), function (e) {}, "danger"]],
                );
                if (
                  _this.props.report.denominacion === "albaran_repartosValet"
                ) {
                  $("#dxDataGrid_repartosAnteriores")
                    .dxDataGrid("instance")
                    .refresh();
                }
              })
              .fail(function () {
                _this.loadPanel_hide();

                let items_correos = $.extend(
                  _this.datasource_tblCorreosNEntidad.items(),
                  _this.datasource_tblCorreosNLav.items(),
                );

                if (items_correos.length === 0) {
                  notify({
                    message: "No hay correos dados de alta",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                } else {
                  notify({
                    message: getTrad("error_envioMail"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                }
              });
          },
          disabled: false,
          id: "dxrd-email",
          imageClassName: "dx-icon-email custom-icon sendMail_report",
          text: getTrad("enviarMail"),
          visible: _this.props.report.enviarMail,
        });
      },
      CustomizeParameterEditors: function (s, e) {},
      CustomizeExportOptions: function (s, e) {
        e.HideExportOptionsPanel();

        e.HideFormat("xls");
        e.HideFormat("rtf");
        e.HideFormat("mht");
        e.HideFormat("html");
      },
      CustomizeLocalization: function (s, e) {
        e.LoadMessages(analitics_ES);
        e.LoadMessages(reporting_ES);
      },
      BeforeRender: function (s, e) {
        props.defaultZoom &&
          s.GetPreviewModel().reportPreview.zoom(props.defaultZoom); //Set 100% zooming
      },
    };

    this.id = new Guid().toString();
  }

  //#region DATASOURCES
  context_enviarReportCorreo = new ODataContext({
    url: connectionConstants.WEB_API_URL,
    entities: {
      enviarReportCorreo: {},
    },
    beforeSend: (request) => {
      this.context_enviarReportCorreo_beforeSend(request);
    },
  });

  context_enviarReportCorreo_beforeSend(request) {
    let denominacionReport = this.props.report.denominacion;

    let payload = [];
    $.each(this.props.params, function (index, item) {
      let param = {};
      param.Key = index;
      param.Value = item;

      if (param.Key != "idEntidad") payload.push(param);
    });

    request.headers = { ...authHeader() };
    request.params.idLavanderia = this.props.lavanderia.idLavanderia;
    request.params.idEntidad = this.props.params.idEntidad;
    request.params.denominacionReport = denominacionReport;

    request.payload = payload;
  }

  datasource_tblCorreosNEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCorreosNEntidad",
      key: "idCorreo",
      beforeSend: (request) => {
        this.datasource_tblCorreosNEntidad_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCorreosNEntidad_onLoading(request);
      },
      version: 4,
    }),
    select: ["denominacion"],
  });

  datasource_tblCorreosNEntidad_beforeSend(request) {
    let idEntidad = this.props.params.idEntidad;
    request.headers = { ...authHeader() };
    request.params.idEntidad = idEntidad;
  }

  datasource_tblCorreosNEntidad_onLoading(loadOptions) {
    let denoReport = this.props.report.denominacion;
    loadOptions.filter =
      "(tblReports/any(x: x/denominacion eq '" + denoReport + "'))";
  }

  datasource_tblCorreosNLav = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCorreosNLav",
      key: "idCorreo",
      beforeSend: (request) => {
        this.datasource_tblCorreosNLav_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCorreosNLav_onLoading(request);
      },
      version: 4,
    }),
    select: ["denominacion"],
  });

  datasource_tblCorreosNLav_beforeSend(request) {
    let idLavanderia = this.props.lavanderia.idLavanderia;
    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderia;
  }

  datasource_tblCorreosNLav_onLoading(loadOptions) {
    let denoReport = this.props.report.denominacion;
    loadOptions.filter =
      "(tblReports/any(x: x/denominacion eq '" + denoReport + "'))";
  }

  dataSource_tblRepartosValet = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRepartosValet",
      key: "idRepartoValet",
      keyType: "Int32",
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "PATCH") request.params.isDelta = true;
      },
      version: 4,
    }),
  });

  //#endregion

  render() {
    return (
      <div
        id={this.id}
        className="reportViewer"
        ref="viewer"
        data-bind="dxReportViewer: $data"
      ></div>
    );
  }

  componentDidMount() {
    ko.applyBindings(
      {
        reportUrl: this.reportUrl,
        requestOptions: this.requestOptions,
        callbacks: this.callbacks,
      },
      document.getElementById(this.id),
    );
  }

  componentWillUnmount() {
    let element = document.getElementById(this.id);
    element && ko.cleanNode(element);
  }

  componentDidUpdate(prevProps, prevState) {
    let { idioma } = this.props;

    this.reload();

    if (idioma.codigo !== prevProps.idioma.codigo) {
      this.callbacks.CustomizeLocalization = function (s, e) {
        if (idioma.codigo === "es") {
          e.LoadMessages(analitics_ES);
          e.LoadMessages(reporting_ES);
        } else if (idioma.codigo === "en") {
          e.LoadMessages(analitics_EN);
          e.LoadMessages(reporting_EN);
        } else if (idioma.codigo === "pt") {
          e.LoadMessages(analitics_PT);
          e.LoadMessages(reporting_PT);
        }
      };

      //  this.JSReportViewer.previewModel.dx.Analytics.Localization.loadMessages(analitics_PT)
      //  this.JSReportViewer.previewModel.dx.Analytics.Localization.loadMessages(reporting_PT)
    }
  }

  reload = () => {
    let { report, params } = this.props;

    this.reportUrl(report.denominacion);
    this.callbacks.CustomizeParameterEditors = function (s, e) {
      var rprt = s.GetReportPreview().reportUrl;
      report.parameterEvent(rprt, s, e);
    };

    var parameterModel = this.JSReportViewer.GetParametersModel();

    parameterModel.restore();
    for (var param in params) {
      if (parameterModel[param]) {
        parameterModel[param](params[param]);
      }
    }

    if (Object.keys(params).length > 0) {
      let close = Object.values(params).reduce((previousValue, param) => {
        if (!previousValue) return false;
        return param == null || (Array.isArray(param) && param.length === 0);
      }, true);
      if (close) {
        this.JSReportViewer.OpenReport(report.denominacion);
      } else {
        parameterModel.submit();
      }
    }
  };

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  close() {
    this.props.close();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ReportViewer);
