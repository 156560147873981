import React from "react";

import { connectionConstants } from "../../../../../constants";

import { errorHandler, authHeader, getTrad } from "../../../../../helpers";

import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import Form, { Item } from "devextreme-react/form";
import { TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";

import Validator, { RequiredRule } from "devextreme-react/validator";

export default class PopupInsUpdCategoriaConvenio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dxForm_formDataEditCategoriaConvenio: {},
    };

    this.dxForm_nuevaCategoriaConvenio_REF = React.createRef();

    this.dxFormItem_render = this.dxFormItem_render.bind(this);
    this.onShown_popup_categoriaConvenio =
      this.onShown_popup_categoriaConvenio.bind(this);
    this.onHidden_popup_categoriaConvenio =
      this.onHidden_popup_categoriaConvenio.bind(this);
    this.onHiding_popup_categoriaConvenio =
      this.onHiding_popup_categoriaConvenio.bind(this);
    this.dxButton_popupCategoriaConvenio_cancelar_onClick =
      this.dxButton_popupCategoriaConvenio_cancelar_onClick.bind(this);
    this.dxFormEditor_onValueChanged =
      this.dxFormEditor_onValueChanged.bind(this);
    this.dxButton_popupCategoriaConvenio_guardar_onClick =
      this.dxButton_popupCategoriaConvenio_guardar_onClick.bind(this);

    this.getTrad = this.getTrad.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxForm_nuevaCategoriaConvenio() {
    return this.dxForm_nuevaCategoriaConvenio_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  //#endregion

  render() {
    let { dxForm_formDataEditCategoriaConvenio } = this.state;
    let { isVisible_popup_showEditarCategoriaConvenio } = this.props;

    return (
      <Popup
        title={"Editar categoría convenio"}
        showTitle={true}
        width="40%"
        maxWidth={400}
        height={190}
        deferRendering={false}
        visible={isVisible_popup_showEditarCategoriaConvenio}
        closeOnOutsideClick={false}
        onShown={this.onShown_popup_categoriaConvenio}
        onHiding={this.onHiding_popup_categoriaConvenio}
        onHidden={this.onHidden_popup_categoriaConvenio}
      >
        <Form
          ref={this.dxForm_nuevaCategoriaConvenio_REF}
          formData={dxForm_formDataEditCategoriaConvenio}
          deferRendering={false}
          labelLocation="top"
          colCount={1}
          height="100%"
          width="100%"
        >
          <Item
            dataField="denominacion"
            label={this.formLabel_denominacion}
            render={this.dxFormItem_render}
          />
        </Form>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("guardar")}
            type="success"
            onClick={this.dxButton_popupCategoriaConvenio_guardar_onClick}
          />
        </ToolbarItem_popup>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("cancelar")}
            type="normal"
            onClick={this.dxButton_popupCategoriaConvenio_cancelar_onClick}
          />
        </ToolbarItem_popup>
      </Popup>
    );
  }

  formLabel_denominacion = {
    text: this.getTrad("denominacion"),
    visible: false,
  };

  dxFormItem_render(data) {
    if (data == null) return null;
    let { dxForm_formDataEditCategoriaConvenio } = this.state;
    let formLabel = this["formLabel_" + data.dataField.replace(".", "_")];
    let isRequired = data.dataField == "denominacion";

    return (
      <div className="d-flex flex-row align-items-center">
        <div className="flex-row d-flex" style={{ flex: 4, paddingRight: 0 }}>
          <div className="pr-3 pe-none ">
            {formLabel.text}
            <span className="dx-field-item-required-mark">
              {isRequired && " *"}
            </span>
          </div>
        </div>
        <div
          style={{ flex: 7, height: 38 }}
          className="ml-auto d-flex justify-content-end "
        >
          <TextBox
            name={data.dataField}
            value={dxForm_formDataEditCategoriaConvenio.denominacion}
            width="100%"
            stylingMode="underlined"
            className={
              "textUpperCase dxForm_denominacion_categoriaConvenio_editor pr-1"
            }
            onContentReady={this.dxFormEditor_onContentReady}
            onValueChanged={this.dxFormEditor_onValueChanged}
          >
            <Validator>{isRequired && <RequiredRule />}</Validator>
          </TextBox>
        </div>
      </div>
    );
  }

  dxFormEditor_onContentReady(e) {
    $(e.element).find("input").css({
      "text-align": "end",
      "font-weight": 500,
    });

    setTimeout(() => {
      $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
        "font-weight": 500,
        "text-align": "start",
      });
    }, 0);
  }

  dxFormEditor_onValueChanged(e) {
    let dxForm_formDataEditCategoriaConvenio = {
      ...this.state.dxForm_formDataEditCategoriaConvenio,
    };
    let dataField = e.component.option("name");

    dxForm_formDataEditCategoriaConvenio[dataField] = e.value.toUpperCase();

    this.setState({
      dxForm_formDataEditCategoriaConvenio:
        dxForm_formDataEditCategoriaConvenio,
    });
  }

  dxButton_popupCategoriaConvenio_guardar_onClick(e) {
    let { selectedCategoriaConvenio_item } = this.props;
    let { dxForm_formDataEditCategoriaConvenio } = this.state;
    let id = selectedCategoriaConvenio_item
      ? selectedCategoriaConvenio_item.idCategoriaConvenio
      : null;
    this.props.onSaveCategoriaConvenio(
      dxForm_formDataEditCategoriaConvenio,
      id,
    );
  }

  dxButton_popupCategoriaConvenio_cancelar_onClick(e) {
    this.props.onHiding();
  }

  onShown_popup_categoriaConvenio(e) {
    $(".dxForm_denominacion_categoriaConvenio_editor")
      .dxTextBox("instance")
      .focus();
  }

  onHiding_popup_categoriaConvenio(e) {
    this.props.onHiding();
  }

  onHidden_popup_categoriaConvenio(e) {
    this.setState({ dxForm_formDataEditCategoriaConvenio: {} });
    this.dxForm_nuevaCategoriaConvenio.repaint();
  }

  componentDidUpdate(prevProps) {
    let _this = this;
    let { selectedCategoriaConvenio_item } = this.props;
    if (
      prevProps.selectedCategoriaConvenio_item !==
      selectedCategoriaConvenio_item
    ) {
      let {
        dxForm_formDataEditCategoriaConvenio,
        selectedCategoriaConvenio_item,
      } = this.props;
      if (selectedCategoriaConvenio_item) {
        this.setState(() => {
          return {
            dxForm_formDataEditCategoriaConvenio: {
              ...dxForm_formDataEditCategoriaConvenio,
              denominacion: selectedCategoriaConvenio_item.denominacion,
            },
          };
        });
      }
    }
  }
}
