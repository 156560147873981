import { Tooltip as DevTooltip } from "devextreme-react/tooltip";
import Posicion from "./Posicion";
import FotoPersona from "components/FotoPersona";

const { Component } = require("react");

// CREA LAS TARJETAS PARA IDENTIFICAR MEJOR A LOS EMPLEADOS TANTO EN
// LA LISTA COMO EN EL PROPIO MAPA
class Tooltip extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let { target, posicion, prevision } = this.props;

        if (prevision && prevision.posicion && !posicion) posicion = prevision.posicion;

        const visible = (target !== undefined || target !== null) && target !== "" && target !== "add_area";
        if (target === "") target = "null";

        const ttPosicion = target.includes("lista")
            ? "left"
            : { my: "top center", at: "bottom center", collision: "fit flip" };

        return (
            <DevTooltip target={"#" + target} visible={visible} position={ttPosicion}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {posicion && posicion !== null ? (
                        <div>
                            {posicion.idMaquinaNavigation ? (
                                <span style={{ fontWeight: 700, fontSize: 20, marginBottom: 10 }}>
                                    {posicion.idMaquinaNavigation.etiqueta} - {posicion.denominacion}
                                </span>
                            ) : null}
                            <span style={{ fontWeight: 400, fontSize: 20, marginBottom: 10 }}>
                                {posicion.idMaquinaNavigation ? " - " : null}
                                {posicion.idAreaLavanderiaNavigation.denominacion}
                            </span>
                            {prevision && prevision !== null ? (
                                <div
                                    style={{
                                        borderBottom: "1px solid rgba(151, 109, 14, 0.125)",
                                        margin: "5px 2px",
                                    }}
                                />
                            ) : null}
                        </div>
                    ) : null}
                    {prevision && prevision !== null ? (
                        <>
                            <span style={{ fontWeight: 400, fontSize: 15, marginBottom: 10 }}>{prevision.nombre}</span>
                            <div className="container_spanCentrado">
                                <FotoPersona
                                    idPersona={prevision.idPersona}
                                    style={{
                                        width: 150,
                                        height: 150,
                                        borderRadius: 25,
                                        overflow: "hidden",
                                    }}
                                />
                            </div>
                        </>
                    ) : null}
                </div>
            </DevTooltip>
        );
    }
}

export default Tooltip;
