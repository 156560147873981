import React, { useState, useEffect } from "react";

import Box, { Item } from "devextreme-react/box";
import Popup from "devextreme-react/popup";

import DataGridPrendaNMovimientoInventario from "./components/DataGridPrendaNMovimiento";
import DataGridMovimientoInventario from "./components/DataGridMovimiento";
import { getTrad } from "helpers";

const PopupInformeMovimientosInventario = ({ visible = false, onHiding }) => {
    const [movimientosSel, setMovimientosSel] = useState([]);

    useEffect(() => {
        if (!visible) setMovimientosSel([]);
    }, [visible]);

    return (
        <Popup visible={visible} title={getTrad("infMovimientos")} onHiding={onHiding} height={"90%"} width={"90%"}>
            <Box direction={"row"} height={"100%"}>
                <Item ratio={10}>
                    <DataGridMovimientoInventario visible={visible} setSelectedRowKeys={setMovimientosSel} />
                </Item>
                <Item baseSize={20} />
                <Item ratio={9}>
                    <DataGridPrendaNMovimientoInventario movimientosSel={movimientosSel} />
                </Item>
            </Box>
        </Popup>
    );
};

export default PopupInformeMovimientosInventario;
