import React from "react";
import $ from "jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  FilterRow,
  Selection,
  Summary,
  TotalItem,
  Editing,
  KeyboardNavigation,
  RangeRule,
  RequiredRule,
  CustomRule,
} from "devextreme-react/data-grid";

import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatNumber,
  formatDate_parameter,
  patchRequestHandler,
} from "../../../../helpers";
import { connect } from "react-redux";
import {
  elemTransCellComponent,
  progressBarCellComponent,
} from "components/DataGrid/Cells";

const GET_COLOR_REPOSICION = (reposicion) => {
  if (reposicion >= 0.7) return "danger";
  else if (reposicion > 0.65) return "warning";
  else if (reposicion >= 0.45) return "success";
  else if (reposicion >= 0.4) return "warning";
  else return "danger";
};

class GridPrendasControlAlmacen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.dxDataGrid_REF = React.createRef();
  }

  get dxDataGrid() {
    return this.dxDataGrid_REF.current.instance;
  }

  //#region DATASOURCES

  datasource_infControlAlmacenCliente = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/ControlCalidad/infControlAlmacenCliente",
      key: "id",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_infControlAlmacenCliente_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_infControlAlmacenCliente_postProcess(data);
    },
  });

  datasource_infControlAlmacenCliente_beforeSend(request) {
    let { fechaIni, fechaFin, idCompañiaSel, idPrendaSel, group } = this.props;

    request.headers = { ...authHeader() };
    request.params.idCompañia = idCompañiaSel;
    request.params.fechaIni = formatDate_parameter(fechaIni);
    request.params.fechaFin = formatDate_parameter(fechaFin);
    request.params.group = group;

    if (group == "entidad") {
      if (idPrendaSel && idPrendaSel !== null) {
        request.params.idPrenda = idPrendaSel;
      } else {
        request.params.idPrenda = -1;
      }
    }
  }

  datasource_infControlAlmacenCliente_postProcess = (data) => {
    data.forEach((item) => {
      item.progressBarCellOptions_reposicion = {
        color: GET_COLOR_REPOSICION(item.reposicion),
      };
    });
    return data;
  };

  datasource_tblPrendaNEntidad_NuevoPedido = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblPrendaNEntidad_NuevoPedido",
      key: "id",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPrendaNEntidad_NuevoPedido_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblPrendaNEntidad_NuevoPedido_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  //#endregion

  render() {
    const { idCompañiaSel, group, idPrendaSel, title } = this.props;

    const noDataMessage =
      group === "prenda" && idCompañiaSel === null
        ? "Selecciona una compañía"
        : group === "entidad" && idPrendaSel === null
          ? "Selecciona una prenda"
          : "Sin datos";

    return (
      <DataGrid
        ref={this.dxDataGrid_REF}
        dataSource={this.datasource_infControlAlmacenCliente}
        className="he-100"
        remoteOperations={false}
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        hoverStateEnabled={true}
        onRowClick={group === "prenda" ? this.dxDataGrid_onRowClick : undefined}
        noDataText={noDataMessage}
        selection={group === "entidad" ? null : undefined}
        onToolbarPreparing={title ? this.onToolbarPreparing : undefined}
        onContentReady={this.onContentReady}
        onRowPrepared={this.onRowPrepared}
        onEditorPreparing={this.onEditorPreparing}
        onRowUpdating={this.onRowUpdating}
      >
        <Editing
          mode="cell"
          allowUpdating={true}
          selectTextOnEditStart={true}
        />
        <Paging enabled={false} />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <Selection mode={"single"} />
        <KeyboardNavigation
          enterKeyAction="moveFocus"
          enterKeyDirection="row"
          editOnKeyPress={true}
        />
        <Column
          dataField="denominacion"
          caption={getTrad("denominacion")}
          dataType="string"
          allowHeaderFiltering={false}
          allowSearch={false}
          minWidth={225}
          allowEditing={false}
          sortIndex={1}
        />
        <Column
          dataField="stockDefinido"
          caption={getTrad("stockDefinido")}
          width={130}
          alignment="center"
          dataType="number"
          calculateDisplayValue={this.calculateDisplayValue_stockDefinido}
          cssClass={group === "entidad" && "dx-Cell_Editable"}
          allowEditing={group === "entidad"}
          allowSorting={false}
          allowFiltering={false}
          editorOptions={this.editorOptions_noDecimals}
        >
          <CustomRule
            reevaluate={true}
            ignoreEmptyValue={true}
            validationCallback={
              this.validationCallback_CustomRule_stockDefinido
            }
          />
        </Column>
        <Column
          dataField="codigoElemTransPedido"
          caption={getTrad("elementoTransporte_abr")}
          width={50}
          allowHeaderFiltering={false}
          allowEditing={false}
          allowFiltering={false}
          alignment="center"
          cssClass="p-0"
          cellComponent={elemTransCellComponent}
        />
        <Column
          dataField="udsStock"
          caption={getTrad("udsStock")}
          width={100}
          dataType="number"
          alignment="center"
          allowHeaderFiltering={false}
          allowSearch={false}
          defaultSelectedFilterOperation=">="
          allowEditing={false}
          calculateDisplayValue={this.calculateDisplayValue_udsStock}
        />
        <Column
          dataField="reposicion"
          caption={getTrad("reposicion")}
          dataType="number"
          alignment="center"
          filterOperations={["=", "<>", "<", ">", "<=", ">="]}
          defaultSelectedFilterOperation=">="
          defaultFilterValue={0}
          cssClass="p-0"
          allowSearch={false}
          allowHeaderFiltering={false}
          allowFiltering={true}
          allowSorting={true}
          width={140}
          allowEditing={false}
          cellComponent={progressBarCellComponent}
        />
        <Column
          dataField="udsDiarias"
          caption={getTrad("udsDiarias")}
          width={100}
          dataType="number"
          alignment="center"
          allowHeaderFiltering={false}
          allowSearch={false}
          defaultSelectedFilterOperation=">="
          calculateDisplayValue={this.calculateDisplayValue_udsDiarias}
          allowEditing={false}
        />

        <Summary calculateCustomSummary={this.calculateCustomSummary}>
          <TotalItem
            column={"denominacion"}
            displayFormat={getTrad("total").toUpperCase()}
          />
          <TotalItem
            column="stockDefinido"
            name="stockDefinido"
            summaryType="sum"
            displayFormat={this.displayFormat_number}
          />
          <TotalItem
            column="udsStock"
            name="udsStock"
            summaryType="sum"
            displayFormat={this.displayFormat_number}
          />
          <TotalItem
            name="reposicion"
            showInColumn={"reposicion"}
            summaryType="custom"
            displayFormat="{0}"
            customizeText={this.displayFormat_percent}
          />
          <TotalItem
            column="udsDiarias"
            name="udsDiarias"
            summaryType="sum"
            displayFormat={this.displayFormat_number}
          />
        </Summary>
      </DataGrid>
    );
  }

  //FORMATS
  decimal_format = { style: "decimal", maximumFractionDigits: 2 };

  editorOptions_noDecimals = {
    min: 0,
    step: 0,
    placeholder: "0",
    format: { style: "decimal", maximumFractionDigits: 0 },
  };

  onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
      location: "before",
      text: this.props.title,
    });
  };

  onContentReady = (e) => {
    e.element.find(".dx-freespace-row").css("height", 0);
  };

  onRowPrepared = (e) => {
    let { group } = this.props;
    if (e.rowType === "data" && group == "prenda") {
      e.rowElement.css("cursor", "pointer");
    }
  };

  cellRender_detallado_comañia = (e) => {
    return (
      <div
        id={"compañiaButton_" + e.data.id}
        className="container_spanCentrado pointer"
      >
        <div className="icon_Camisa font-size-xl" />
      </div>
    );
  };

  onEditorPreparing = (e) => {
    let _this = this;
    if (e.parentType === "filterRow") {
      if (e.dataField === "reposicion") {
        e.editorName = "dxSlider";
        e.editorOptions = {
          min: 0,
          max: 1,
          value: e.value,
          step: 0.05,
          width: "100%",
          onValueChanged: function (data) {
            e.setValue(data.value);
            let textValue = formatNumber(data.value, 0, "percent");
            _this.sliderText.text(textValue);
          },
          onInitialized: function (e) {
            let value = e.component.option("value");
            let textValue = formatNumber(value, 0, "percent");
            _this.sliderText = $("<div />")
              .addClass("slider-textValue")
              .text(textValue);
            e.element.append(_this.sliderText);
          },
        };
      }
    }
  };

  dxDataGrid_onRowClick = (item) => {
    const { data } = item;
    if (!data) return;

    const { onValueChange, group } = this.props;
    const prenda = data.id.split("_");
    const idPrenda = prenda
      .filter((item) => !isNaN(item))
      .map((item) => parseInt(item))[0];

    if (group == "prenda")
      onValueChange({
        prendaSel: { idPrenda, denominacion: data.denominacion },
      });
  };

  onRowUpdating = (cellInfo) => {
    const { onDataRefresh } = this.props;
    cellInfo.cancel = true;

    if (cellInfo.newData.stockDefinido > 0) {
      cellInfo.component.beginUpdate();

      const { idPrendaSel } = this.props;
      const idEntidad = parseInt(cellInfo.key.split("_")[1]);
      const prendaNEntidad = {
        stockDefinido:
          cellInfo.newData.stockDefinido * cellInfo.oldData.udsXBacPedido,
      };

      this.datasource_tblPrendaNEntidad_NuevoPedido
        .store()
        .update(
          { idEntidad, idPrenda: idPrendaSel },
          patchRequestHandler(prendaNEntidad),
        )
        .done(() => {
          cellInfo.component.cancelEditData();
          cellInfo.component.refresh(true).done(function () {});
          onDataRefresh(true);
          setTimeout(() => {
            cellInfo.component.endUpdate();
          }, 100);
        });
    }
  };

  calculateCustomSummary(options) {
    if (options.name == "reposicion") {
      switch (options.summaryProcess) {
        case "start":
          options.udsDiarias = 0;
          options.udsStock = 0;
          break;
        case "calculate":
          options.udsDiarias += options.value.udsDiarias;
          options.udsStock += options.value.udsStock;
          break;
        case "finalize":
          options.totalValue = options.udsDiarias / options.udsStock;
          options.totalValue = !isNaN(options.totalValue)
            ? options.totalValue
            : 0;
          break;
      }
    }
  }

  calculateDisplayValue_reposicion = (rowData) => {
    return formatNumber(rowData?.reposicion, 2, "percent");
  };
  calculateDisplayValue_stockDefinido = (rowData) => {
    return formatNumber(rowData?.stockDefinido, 0, "decimal");
  };
  calculateDisplayValue_udsStock = (rowData) => {
    return formatNumber(rowData?.udsStock, 0, "decimal");
  };
  calculateDisplayValue_udsDiarias = (rowData) => {
    return formatNumber(rowData?.udsDiarias, 0, "decimal");
  };

  validationCallback_CustomRule_stockDefinido(e) {
    e.rule.message = "El valor debe ser mayor a 0";
    return e.data.stockDefinido > 0;
  }

  displayFormat_number = (e) => {
    return formatNumber(e, 0, "decimal");
  };
  displayFormat_percent = (e) => {
    return formatNumber(e.value, 2, "percent");
  };

  componentDidUpdate = (prevProps) => {
    const {
      idCompañiaSel,
      idPrendaSel = null,
      fechaIni,
      fechaFin,
      onValueChange,
      group,
      refresh,
      onDataRefresh,
    } = this.props;

    const hasIdCompañiaChanged = idCompañiaSel !== prevProps.idCompañiaSel;
    const hasFechaChanged =
      fechaIni !== prevProps.fechaIni || fechaFin !== prevProps.fechaFin;
    const hasIdPrendaChanged = idPrendaSel !== prevProps.idPrendaSel;

    if (group === "prenda" && (hasIdCompañiaChanged || hasFechaChanged)) {
      if (hasIdCompañiaChanged) {
        this.dxDataGrid.clearSelection();
      }
      this.datasource_infControlAlmacenCliente.reload();
    }

    if (group === "entidad" && (hasIdPrendaChanged || hasFechaChanged)) {
      this.dxDataGrid.repaint();
      this.datasource_infControlAlmacenCliente.reload();
    }

    if (refresh) {
      this.datasource_infControlAlmacenCliente.reload();
      onDataRefresh(false);
    }
  };
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(GridPrendasControlAlmacen);
