import React, { useEffect, useState } from 'react';
import { Button } from "devextreme-react";
import ChipEstado from 'pages/Administracion/shared/components/ChipEstado';
import { dxMensajePregunta, getTrad } from 'helpers';

const ToggleEstado = ({formData, updateState}) => {
    // Component logic goes here
    const [estado, setEstado] = useState(formData.idAdmPresupuestoVenta_Estado);

    useEffect(() => {
        setEstado(formData.idAdmPresupuestoVenta_Estado);
    }, [formData.idAdmPresupuestoVenta_Estado]);

    const togglePresupuesto = (e) => {
        dxMensajePregunta("¿Estás seguro que quieres rechazar el presupuesto? ", [
            [
                getTrad("aceptar"),
                () => {
                    toggleEstadoPresupuesto();
                },
                "danger",
            ],
            [getTrad("cancelar"), () => {}],
        ]);
    };

    const getFormStatus = () => {
        if(formData?.idAdmPresupuestoVenta != null && formData?.idAdmPresupuestoVenta != undefined){
            if(formData.idAdmPresupuestoVenta_Estado === 1 || formData.idAdmPresupuestoVenta_Estado === 0){
                return true
            }
        }
        return false
    }

    const getToggleButtonText = () => {
        if (formData.idAdmPresupuestoVenta_Estado === 0){
            return getTrad("activar");
        } else if(formData.idAdmPresupuestoVenta_Estado === 1){
            return getTrad("rechazar");
        }
    }

    const toggleEstadoPresupuesto = () => {
        if (estado === 0){
            updateState(1);
            setEstado(1);
        } else if(estado === 1){
            updateState(0);
            setEstado(0);
        }
    }

    return (
        // JSX markup goes here
        <div style={{ display: "flex" }}>
            <div style={{ marginRight: "10px" }}>
                <Button
                    text={getToggleButtonText()}
                    visible={getFormStatus()}
                    onClick={togglePresupuesto}
                />
            </div>
            <div style={{ marginTop: "auto", marginBottom: "auto" }}>
                {ChipEstado(formData.idAdmPresupuestoVenta_Estado, "", "presupuesto")}
            </div>
        </div>
    );
};

export default ToggleEstado;