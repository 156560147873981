import { connectionConstants } from "../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    formatDate,
    tooltipControl_creacion,
    dxMensajePregunta,
    convertClienteUtcToLavanderiaUtc,
    compressBase64Img_scaleWidth,
    formatNumber,
    hourDiff,
} from "../../../helpers";
import { allIcons as svg_iconos } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";
import Guid from "devextreme/core/guid";

export default function cargaJs(COMPONENT, LAVANDERIA, USER, IDIOMA) {
    const LAVANDERIAS = JSON.parse(localStorage.getItem("lavanderias"));

    var deferred = $.Deferred();

    var tipoIncidenciaSel = null,
        incidenciaSel = {
            tblAccionesCorrectivas: [],
            tblUsuarioCrea: {},
            tblUsuarioRevisor: {},
            tblIncidencia_Documento: [],
        },
        incidenciaSel_bak = null,
        isSat = false,
        isRRHH = false,
        isCliente = false,
        isTransporte = false,
        isCreacion = false,
        isRevisada = false,
        isUserRevisor = false,
        isCerrada = false;
    var datasource_tblTipoSubIncidencia;

    //Obtenemos los parametros que provienen del selector de lavandería
    var idTipoIncidencia_ = COMPONENT.state.idTipoIncidencia;
    idTipoIncidencia_ = idTipoIncidencia_ ? parseInt(idTipoIncidencia_) : -1;

    //#region FUENTES DE DATOS

    var datasource_tblIncidencia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblIncidencia",
            key: "idIncidencia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            updated: function () {
                notify({
                    message: getTrad("aviso_C_RegistroInsertado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            },
            onLoading: function (loadOptions) {
                if (loadOptions.filter) {
                    loadOptions.filter = [
                        loadOptions.filter,
                        "and",
                        ["tblTipoSubIncidencia/idTipoIncidencia", "=", tipoIncidenciaSel.idTipoIncidencia],
                    ];
                } else {
                    loadOptions.filter = [
                        "tblTipoSubIncidencia/idTipoIncidencia",
                        "=",
                        tipoIncidenciaSel.idTipoIncidencia,
                    ];
                }
            },
            version: 4,
        }),
        select: [
            "idIncidencia",
            "codigo",
            "idLavanderia",
            "estado",
            "fechaCierre",
            "fechaIncidencia",
            "descripcionIncidencia",
            "descripcionResolucion",
            "idSubTipoIncidencia",
            "idUsuarioCrea",
            "idUsuarioResponsable",
            "idUsuarioRevisor",
            "isInformativa",
            "isRevisada",
            "idEntidad",
            "idCompañia",
            "idMaquina",
            "estadoMaquina",
            "idUsuarioAfecta",
            "idVehiculo",
            "idPoliticaDisciplinaria",
            "asunto",
        ],
        expand: [
            "tblUsuarioCrea($select = nombre)",
            "tblUsuarioRevisor($select = nombre)",
            "tblAccionesCorrectivas",
            "tblPersona_responsable($select=idPersona,nombre,apellidos)",
            "tblPersona_usuarioAfecta($select=idPersona,nombre,apellidos)",
        ],
    });

    var datasource_spResumen_Incidencias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spResumen_Incidencias",
            key: "idTipoIncidencia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;

                request.params.idioma = IDIOMA.codigo;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idTipoIncidencia", "denominacion", "numIncidencias", "icon"],
        postProcess: function (data) {
            $.each(data, function (index, tipoIncidencias) {
                var icono = svg_iconos[tipoIncidencias.icon].clone().css("height", "40px").css("width", "35px");

                var serializer = new XMLSerializer();
                tipoIncidencias.icon = serializer.serializeToString(icono[0]);
            });
            return data;
        },
        map: function (dataItem) {
            return {
                idTipoIncidencia: dataItem.idTipoIncidencia,
                text: dataItem.denominacion,
                badge: dataItem.numIncidencias,
                icon: dataItem.icon,
            };
        },
    });

    datasource_tblTipoSubIncidencia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoSubIncidencia",
            key: "idSubTipoIncidencia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        sort: "denominacion",
        expand: "tblTraduccion($select=" + IDIOMA.codigo + ")",
        select: ["idSubTipoIncidencia", "idTipoIncidencia"],
        postProcess: function (data) {
            $.each(data, function (index, tipoSubIncidencia) {
                tipoSubIncidencia.denominacion = tipoSubIncidencia.tblTraduccion[IDIOMA.codigo].toUpperCase();
            });
            return data;
        },
    });

    var datasource_tblIncidencia_Documento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblIncidencia_Documento",
            key: "idSubTipoIncidencia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idIncidencia = incidenciaSel.idIncidencia;
            },
            version: 4,
        }),
    });

    var datasource_tblPersona_responsables = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.todas = true;
            },
            onLoading: function (loadOptions) {
                var filter =
                    "(tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
                    LAVANDERIA.idLavanderia +
                    ")))  \
                or (idLavanderia eq " +
                    LAVANDERIA.idLavanderia +
                    ")";

                if (loadOptions.filter) loadOptions.filter = [loadOptions.filter, "and", filter];
                else loadOptions.filter = filter;
            },
            version: 4,
        }),
        select: ["idPersona", "nombre", "apellidos", "email"],
        sort: ["nombre", "apellidos"],
        map: function (itemData) {
            return {
                idPersona: itemData.idPersona,
                nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
                email: itemData.email,
            };
        },
    });

    var datasource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: ["nombre", "apellidos"],
        select: ["idPersona", "nombre", "apellidos", "email"],
        map: function (itemData) {
            return {
                idPersona: itemData.idPersona,
                nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
                email: itemData.email,
            };
        },
    });

    var datasource_tblPoliticaDisciplinaria = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPoliticaDisciplinaria",
            key: "idPoliticaDisciplinaria",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        expand: "tblTraduccion($select=" + IDIOMA.codigo + ")",
        select: "idPoliticaDisciplinaria",
        map: function (itemData) {
            return {
                idPoliticaDisciplinaria: itemData.idPoliticaDisciplinaria,
                denominacion: itemData.tblTraduccion[IDIOMA.codigo],
            };
        },
    });

    var datasource_tblMaquina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMaquina",
            key: "idMaquina",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idMaquina", "denominacion", "etiqueta", "numSerie"],
    });

    var datasource_tblMaquina_ALL = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMaquina",
            key: "idMaquina",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.todas = true;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idMaquina", "denominacion", "etiqueta", "numSerie"],
    });

    var datasource_tblPesoIncidencia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPesoIncidencia",
            key: "idPeso",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        sort: "valor",
        select: ["idPeso", "valor"],
    });

    var datasource_tblCompañia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idCompañia", "denominacion"],
    });

    var datasource_tblEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idEntidad", "denominacion", "idCompañia"],
    });

    var datasource_tblVehiculo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblVehiculo",
            key: "idVehiculo",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idVehiculo", "denominacion", "matricula"],
        map: function (dataItem) {
            return {
                idVehiculo: dataItem.idVehiculo,
                denominacion: dataItem.matricula + " - " + dataItem.denominacion,
            };
        },
    });

    var datasource_tblVehiculo_ALL = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblVehiculo",
            key: "idVehiculo",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.todas = true;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idVehiculo", "denominacion", "matricula"],
    });

    var enum_estadosMaquina = [
        {
            estadoMaquina: 1,
            denominacion: getTrad("noParada").toUpperCase(),
        },
        {
            estadoMaquina: 2,
            denominacion: getTrad("parada").toUpperCase(),
        },
    ];

    var enum_estadoIncidencia = [
        {
            estado: true,
            denominacion: getTrad("cerrada").toUpperCase(),
        },
        {
            estado: false,
            denominacion: getTrad("abierta").toUpperCase(),
        },
    ];

    var ODataContext_tblIncidencia = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblIncidencia",
        entities: {
            Incidencias: {
                key: "idInventario",
                keyType: "Int32",
            },
        },
        beforeSend: function (request) {
            request.headers = { ...authHeader() };
        },
    });

    //#endregion

    $.when(
        datasource_spResumen_Incidencias.load(),
        datasource_tblTipoSubIncidencia.load(),
        datasource_tblPersona_responsables.load(),
        datasource_tblPersona.load(),
        datasource_tblPoliticaDisciplinaria.load(),
        datasource_tblMaquina.load(),
        datasource_tblEntidad.load(),
        datasource_tblCompañia.load(),
        datasource_tblVehiculo.load(),
        datasource_tblPesoIncidencia.load()
    )
        .done(function () {
            tipoIncidenciaSel = $.grep(datasource_spResumen_Incidencias.items(), function (tipoIncidencia) {
                return tipoIncidencia.idTipoIncidencia === idTipoIncidencia_;
            })[0];

            if (!tipoIncidenciaSel) {
                tipoIncidenciaSel = datasource_spResumen_Incidencias.items()[0];
            }

            crearIncidencia();

            $("#Incidencias #dxContainer").dxBox({
                direction: "col",
                align: "space-around",
                crossAlign: "stretch",
                height: "100%",
                width: "100%",
                items: [
                    {
                        baseSize: 80,
                        shrink: 0,
                        ratio: 0,
                        template: function (e, index, item) {
                            item.append(
                                $("<div id='dxNavBar_tipoIncidencia'/>")
                                    .addClass("fill")
                                    .addClass("h4")
                                    .dxNavBar({
                                        dataSource: datasource_spResumen_Incidencias,
                                        keyExpr: "idTipoIncidencia",
                                        selectedItem: tipoIncidenciaSel,
                                        onSelectionChanged: function (e) {
                                            if (e.addedItems.length > 0) {
                                                tipoIncidenciaSel = e.addedItems[0];

                                                datasource_tblIncidencia.reload().done(function () {
                                                    config_dxDataGrid_tipoIncidencia();
                                                });
                                            }
                                        },
                                    })
                            );
                        },
                    },
                    {
                        ratio: 1,
                        template: function (e, index, item) {
                            item.append(
                                $("<div id='dxDataGrid_tblIncidencia'/>").dxDataGrid({
                                    //Datos
                                    dataSource: datasource_tblIncidencia,
                                    remoteOperations: true,
                                    //Propiedades
                                    columnsAutoWidth: true,
                                    headerFilter: {
                                        visible: true,
                                    },
                                    height: "100%",
                                    filterRow: {
                                        visible: true,
                                        showAllText: getTrad("todos").toUpperCase(),
                                        applyFilter: "auto",
                                    },
                                    searchPanel: {
                                        visible: true,
                                        width: 200,
                                        placeholder: getTrad("busqueda"),
                                        searchVisibleColumnsOnly: true,
                                    },
                                    paging: {
                                        pageSize: 50,
                                    },
                                    pager: {
                                        showPageSizeSelector: true,
                                        allowedPageSizes: [20, 50, 100],
                                        showInfo: true,
                                    },
                                    sorting: {
                                        mode: "multiple",
                                    },
                                    hoverStateEnabled: true,
                                    columns: [
                                        {
                                            dataField: "idUsuarioRevisor",
                                            visible: false,
                                        },
                                        {
                                            caption: " ",
                                            width: "40px",
                                            alignment: "center",
                                            cssClass: "p-0",
                                            fixed: true,
                                            allowFixing: false,
                                            allowHiding: false,
                                            allowReordering: false,
                                            cellTemplate: function (cellElement, cellInfo) {
                                                var contenedor = $("<div/>").addClass("container_spanCentrado");
                                                svg_iconos.svg_edit
                                                    .clone()
                                                    .css("height", "25px")
                                                    .css("width", "25px")
                                                    .css("cursor", "pointer")
                                                    .appendTo(contenedor);

                                                cellElement.on("click", function () {
                                                    cargaIncidencia($.extend(true, {}, cellInfo.data));

                                                    isCreacion = false;
                                                    isRevisada = incidenciaSel.isRevisada;
                                                    isCerrada = incidenciaSel.estado; //True cerrada
                                                    isUserRevisor = incidenciaSel.idUsuarioRevisor === USER.idUsuario; //|| parent.usuario.idCargo === 1;

                                                    datasource_tblIncidencia_Documento
                                                        .reload()
                                                        .done(function (documentos) {
                                                            $.merge(incidenciaSel.tblIncidencia_Documento, documentos);

                                                            $("#dxTileView_documentos")
                                                                .dxTileView("instance")
                                                                .option(
                                                                    "dataSource",
                                                                    incidenciaSel.tblIncidencia_Documento
                                                                );
                                                        });

                                                    $("#dxPopup_incidencia").dxPopup("instance").show();
                                                });

                                                contenedor.appendTo(cellElement);
                                            },
                                        },
                                        {
                                            dataField: "codigo",
                                            caption: getTrad("codigo"),
                                            width: 100,
                                            alignment: "center",
                                            allowHeaderFiltering: false,
                                        },
                                        {
                                            dataField: "fechaIncidencia",
                                            caption: getTrad("fechaIncidencia"),
                                            dataType: "date",
                                            format: "dd/MM/yyyy",
                                            width: "150px",
                                            alignment: "center",
                                            sortIndex: 1,
                                            sortOrder: "desc",
                                            allowSearch: false,
                                            allowHeaderFiltering: false,
                                        },
                                        {
                                            dataField: "idSubTipoIncidencia",
                                            caption: getTrad("tipo"),
                                            width: "240px",
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: $.grep(
                                                    datasource_tblTipoSubIncidencia.items(),
                                                    function (tipoSub) {
                                                        return (
                                                            tipoSub.idTipoIncidencia ===
                                                            tipoIncidenciaSel.idTipoIncidencia
                                                        );
                                                    }
                                                ),
                                                valueExpr: "idSubTipoIncidencia",
                                                displayExpr: "denominacion",
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                                searchEnabled: false,
                                            },
                                        },
                                        {
                                            dataField: "estado",
                                            caption: getTrad("estado"),
                                            dataType: "boolean",
                                            minWidth: 80,
                                            width: 100,
                                            alignment: "left",
                                            sortIndex: 0,
                                            sortOrder: "asc",
                                            falseText: enum_estadoIncidencia[0].denominacion,
                                            trueText: enum_estadoIncidencia[1].denominacion,
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: enum_estadoIncidencia,
                                                displayExpr: "denominacion",
                                                valueExpr: "estado",
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                                searchEnabled: false,
                                            },
                                            headerFilter: {
                                                allowSearch: true,
                                            },
                                        },
                                        {
                                            dataField: "tblPersona_responsable",
                                            caption: getTrad("responsable"),
                                            width: 170,
                                            allowHeaderFiltering: false,
                                            allowSorting: false,
                                            calculateDisplayValue: function (rowData) {
                                                if (rowData.tblPersona_responsable) {
                                                    return (
                                                        rowData.tblPersona_responsable.nombre +
                                                        " " +
                                                        rowData.tblPersona_responsable.apellidos
                                                    );
                                                } else {
                                                    return "";
                                                }
                                            },
                                            calculateFilterExpression: function (filterValue) {
                                                return [
                                                    "concat(concat(tblPersona_responsable.nombre, ' '), tblPersona_responsable.apellidos)",
                                                    "contains",
                                                    filterValue,
                                                ];
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                            },
                                        },
                                        {
                                            dataField: "idMaquina",
                                            caption: getTrad("maquina"),
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: datasource_tblMaquina_ALL.store(),
                                                valueExpr: "idMaquina",
                                                displayExpr: "denominacion",
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                            },
                                        },
                                        {
                                            dataField: "tblPersona_usuarioAfecta",
                                            caption: getTrad("nombrePersona"),
                                            allowHeaderFiltering: false,
                                            allowSorting: false,
                                            calculateDisplayValue: function (rowData) {
                                                if (rowData.tblPersona_usuarioAfecta) {
                                                    return (
                                                        rowData.tblPersona_usuarioAfecta.nombre +
                                                        " " +
                                                        rowData.tblPersona_usuarioAfecta.apellidos
                                                    );
                                                } else {
                                                    return "";
                                                }
                                            },
                                            calculateFilterExpression: function (filterValue) {
                                                return [
                                                    "concat(concat(tblPersona_usuarioAfecta.nombre, ' '), tblPersona_usuarioAfecta.apellidos)",
                                                    "contains",
                                                    filterValue,
                                                ];
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                            },
                                        },
                                        {
                                            dataField: "asunto",
                                            caption: getTrad("asunto"),
                                            allowHeaderFiltering: false,
                                        },
                                        {
                                            dataField: "idCompañia",
                                            caption: getTrad("compañia"),
                                            width: 120,
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: datasource_tblCompañia.items(),
                                                valueExpr: "idCompañia",
                                                displayExpr: "denominacion",
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                                searchEnabled: false,
                                            },
                                        },
                                        {
                                            dataField: "idEntidad",
                                            caption: getTrad("entidad"),
                                            width: 170,
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: datasource_tblEntidad.items(),
                                                valueExpr: "idEntidad",
                                                displayExpr: "denominacion",
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                                searchEnabled: false,
                                            },
                                        },
                                        {
                                            dataField: "idVehiculo",
                                            caption: getTrad("vehiculo"),
                                            allowHeaderFiltering: true,
                                            lookup: {
                                                dataSource: datasource_tblVehiculo_ALL.store(),
                                                valueExpr: "idVehiculo",
                                                displayExpr: function (item) {
                                                    return item && item.matricula + " - " + item.denominacion;
                                                },
                                            },
                                            editorOptions: {
                                                showClearButton: true,
                                                searchEnabled: false,
                                            },
                                        },
                                        {
                                            dataField: "fechaCierre",
                                            caption: getTrad("fechaResolucion"),
                                            dataType: "date",
                                            format: "dd/MM/yyyy",
                                            width: "150px",
                                            alignment: "center",
                                            allowSearch: false,
                                            allowHeaderFiltering: false,
                                        },
                                        {
                                            dataField: "tblUsuarioCrea.nombre",
                                            caption: getTrad("registradoPor"),
                                            width: 130,
                                            allowHeaderFiltering: false,
                                            visible: false,
                                        },
                                        {
                                            caption: getTrad("duracion"),
                                            name: "duracion",
                                            width: 200,
                                            calculateCellValue(e) {
                                                if (e.fechaCierre !== null) {
                                                    return hourDiff(e.fechaIncidencia, e.fechaCierre);
                                                }
                                            },
                                            allowHeaderFiltering: false,
                                            visible: false,
                                        },
                                        {
                                            dataField: "descripcionIncidencia",
                                            caption: getTrad("descripcion"),
                                            width: 300,
                                            allowHeaderFiltering: false,
                                            visible: false,
                                        },
                                    ],
                                    //Eventos
                                    onRowPrepared: function (e) {
                                        if (e.rowType === "data") {
                                            if (e.data.estado === true) {
                                                e.rowElement.addClass("disabledRow");
                                            }
                                        }
                                    },
                                    onToolbarPreparing: function (e) {
                                        e.toolbarOptions.items.unshift(
                                            {
                                                location: "before",
                                                widget: "dxButton",
                                                toolbar: "bottom",
                                                options: {
                                                    width: "100%",
                                                    icon: "plus",
                                                    text: getTrad("incidencia"),
                                                    type: "normal",
                                                    onClick: function () {
                                                        isCreacion = true;
                                                        isRevisada = false;
                                                        isCerrada = false;

                                                        crearIncidencia();

                                                        $("#dxPopup_incidencia").dxPopup("instance").show();
                                                    },
                                                },
                                            },
                                            {
                                                location: "before",
                                                widget: "dxButton",
                                                showText: "always",
                                                locateInMenu: "auto",
                                                options: {
                                                    width: "100%",
                                                    icon: "exportxlsx",
                                                    text: getTrad("exportar"),
                                                    type: "normal",
                                                    onClick: function () {
                                                        e.component.exportToExcel();
                                                    },
                                                },
                                            },
                                            {
                                                location: "after",
                                                widget: "dxButton",
                                                showText: "inMenu",
                                                locateInMenu: "auto",
                                                options: {
                                                    text: getTrad("limpiarFiltro"),
                                                    icon: " icon_EliminarFiltros",
                                                    onInitialized: function (e) {
                                                        tooltipControl_creacion(e.element, e.component.option("text"));
                                                    },
                                                    onClick: function () {
                                                        e.component.clearFilter();
                                                        notify({
                                                            message: getTrad("aviso_C_FiltroRestaurado"),
                                                            type: "success",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                        });
                                                    },
                                                },
                                            },
                                            {
                                                location: "after",
                                                widget: "dxButton",
                                                showText: "inMenu",
                                                options: {
                                                    icon: "refresh",
                                                    text: getTrad("actualizar"),
                                                    onInitialized: function (e) {
                                                        tooltipControl_creacion(e.element, e.component.option("text"));
                                                    },
                                                    onClick: function () {
                                                        e.component.refresh(true);
                                                    },
                                                },
                                            }
                                        );
                                    },
                                    onExporting: function (e) {
                                        e.component.beginUpdate();
                                        e.component.columnOption("duracion", "visible", true);
                                        e.component.columnOption("descripcionIncidencia", "visible", true);
                                    },
                                    onExported: function (e) {
                                        e.component.columnOption("duracion", "visible", false);
                                        e.component.columnOption("descripcionIncidencia", "visible", false);
                                        e.component.endUpdate();
                                    },
                                    //Estilos
                                    showColumnLines: false,
                                    showRowLines: true,
                                    rowAlternationEnabled: true,
                                })
                            );
                        },
                    },
                ],
            });

            config_dxDataGrid_tipoIncidencia();

            $("#Incidencias #dxPopup_incidencia").dxPopup({
                width: "80%",
                height: "85%",
                deferRendering: false,
                contentTemplate: function (contentElement) {
                    contentElement.css("padding-bottom", "0px");
                    contentElement.append(
                        $("<div id='dxForm_incidencia'/>").dxForm({
                            formData: incidenciaSel,
                            validationGroup: "dxForm_incidencia_validationGroup",
                            labelLocation: "top",
                            height: "100%",
                            scrollingEnabled: true,
                            colCount: 2,
                            items: [
                                {
                                    dataField: "idUsuarioCrea",
                                    visible: false,
                                },
                                {
                                    dataField: "estadoMaquina",
                                    visible: false,
                                },
                                {
                                    itemType: "group",
                                    caption: getTrad("general"),
                                    name: "General",
                                    colCountByScreen: {
                                        xl: 2,
                                        lg: 2,
                                        md: 2,
                                        sm: 1,
                                        xs: 1,
                                    },
                                    screenByWidth: function (width) {
                                        return width >= 1200
                                            ? "xl"
                                            : width >= 992
                                            ? "lg"
                                            : width >= 768
                                            ? "md"
                                            : width >= 576
                                            ? "sm"
                                            : "xs";
                                    },
                                    items: [
                                        {
                                            dataField: "tblUsuarioCrea.nombre",
                                            name: "tblUsuarioCrea_nombre",
                                            label: { text: getTrad("registradoPor") },
                                            editorType: "dxTextBox",
                                            editorOptions: {
                                                disabled: true,
                                            },
                                            colSpan: 1,
                                        },
                                        {
                                            dataField: "tblUsuarioRevisor.nombre",
                                            name: "tblUsuarioRevisor_nombre",
                                            label: { text: getTrad("validadoPor") },
                                            editorType: "dxTextBox",
                                            editorOptions: {
                                                disabled: true,
                                            },
                                            colSpan: 1,
                                        },
                                        {
                                            name: "tblUsuarioRevisor_nombre_empty",
                                            itemType: "empty",
                                            colSpan: 1,
                                        },
                                        {
                                            dataField: "fechaIncidencia",
                                            name: "fechaIncidencia",
                                            label: { text: getTrad("fechaHora_Inc") },
                                            editorType: "dxDateBox",
                                            colSpan: 1,
                                            editorOptions: {
                                                type: "datetime",
                                                width: "100%",
                                                displayFormat: "dd/MM/yyyy - HH:mm",
                                                showClearButton: true,
                                                min: "01/01/1900",
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message:
                                                        getTrad("fechaHora_Inc") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "idSubTipoIncidencia",
                                            name: "idSubTipoIncidencia",
                                            label: { text: getTrad("subTipo") },
                                            editorType: "dxSelectBox",
                                            colSpan: 1,
                                            editorOptions: {
                                                dataSource: $.grep(
                                                    datasource_tblTipoSubIncidencia.items(),
                                                    function (tipoSub) {
                                                        return (
                                                            tipoSub.idTipoIncidencia ===
                                                            tipoIncidenciaSel.idTipoIncidencia
                                                        );
                                                    }
                                                ),
                                                displayExpr: "denominacion",
                                                valueExpr: "idSubTipoIncidencia",
                                                showClearButton: true,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message: getTrad("subTipo") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },

                                        //#region VARIABLE DE TIPO INCIDENCIA EN MODIFICACION
                                        {
                                            dataField: "idMaquina",
                                            label: { text: getTrad("maquina") },
                                            editorType: "dxLookup",
                                            colSpan: 2,
                                            name: "idMaquina_modificacion",
                                            editorOptions: {
                                                dataSource: datasource_tblMaquina.items(),
                                                displayExpr: "denominacion",
                                                valueExpr: "idMaquina",
                                                searchExpr: ["denominacion", "etiqueta", "numSerie"],
                                                title: getTrad("maquina"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message: getTrad("maquina") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "idCompañia",
                                            label: { text: getTrad("compañia") },
                                            editorType: "dxLookup",
                                            colSpan: 1,
                                            name: "idCompañia_modificacion",
                                            editorOptions: {
                                                dataSource: datasource_tblCompañia.items(),
                                                displayExpr: "denominacion",
                                                valueExpr: "idCompañia",
                                                searchExpr: ["denominacion"],
                                                title: getTrad("compañia"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                                onItemClick: function (e) {
                                                    $("#dxForm_incidencia")
                                                        .dxForm("instance")
                                                        .updateData("idEntidad", null);
                                                },
                                            },
                                        },
                                        {
                                            dataField: "idEntidad",
                                            label: { text: getTrad("entidad") },
                                            editorType: "dxLookup",
                                            colSpan: 1,
                                            name: "idEntidad_modificacion",
                                            editorOptions: {
                                                dataSource: datasource_tblEntidad.items(),
                                                displayExpr: "denominacion",
                                                valueExpr: "idEntidad",
                                                searchExpr: ["denominacion"],
                                                title: getTrad("entidad"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                                showClearButton: true,
                                                onSelectionChanged: function (e) {
                                                    if (e.selectedItem) {
                                                        $("#dxForm_incidencia")
                                                            .dxForm("instance")
                                                            .updateData("idCompañia", e.selectedItem.idCompañia);
                                                    }
                                                },
                                            },
                                        },
                                        {
                                            dataField: "idUsuarioAfecta",
                                            label: { text: getTrad("nombrePersona") },
                                            editorType: "dxLookup",
                                            colSpan: 2,
                                            name: "idUsuarioAfecta_modificacion",
                                            editorOptions: {
                                                dataSource: datasource_tblPersona,
                                                displayExpr: "nombreCompuesto",
                                                valueExpr: "idPersona",
                                                searchExpr: ["nombre", "apellidos"],
                                                title: getTrad("nombrePersona"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message:
                                                        getTrad("nombrePersona") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "idVehiculo",
                                            label: { text: getTrad("vehiculo") },
                                            editorType: "dxLookup",
                                            colSpan: 2,
                                            name: "idVehiculo_modificacion",
                                            editorOptions: {
                                                dataSource: datasource_tblVehiculo.items(),
                                                displayExpr: "denominacion",
                                                valueExpr: "idVehiculo",
                                                searchExpr: ["denominacion", "matricula"],
                                                title: getTrad("vehiculo"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message: getTrad("vehiculo") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        //#endregion
                                        {
                                            dataField: "asunto",
                                            name: "asunto",
                                            label: { text: getTrad("asunto") },
                                            editorType: "dxTextArea",
                                            colSpan: 2,
                                            editorOptions: {
                                                height: 75,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message: getTrad("asunto") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "descripcionIncidencia",
                                            name: "descripcionIncidencia",
                                            label: { text: getTrad("descripcionIncidencia") },
                                            editorType: "dxTextArea",
                                            colSpan: 2,
                                            editorOptions: {
                                                height: 75,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message:
                                                        getTrad("descripcionIncidencia") +
                                                        ": " +
                                                        getTrad("campoNecesario"),
                                                },
                                            ],
                                        },

                                        {
                                            dataField: "tblIncidencia_Documento",
                                            name: "tblIncidencia_Documento",
                                            label: { text: getTrad("archivosAdjuntos") },
                                            colSpan: 2,
                                            template: function (data, itemElement) {
                                                itemElement.append(
                                                    $("<div id='dxTileView_documentos'>")
                                                        .css("max-width", "600px")
                                                        .dxTileView({
                                                            dataSource: incidenciaSel.tblIncidencia_Documento,
                                                            noDataText: getTrad("alert_NoArchivosAdjuntos"),
                                                            hoverStateEnabled: true,
                                                            baseItemHeight: 75,
                                                            baseItemWidth: 75,
                                                            direction: "horizontal",
                                                            height: "100",
                                                            width: "100%",
                                                            itemMargin: 10,
                                                            showScrollbar: true,
                                                            itemTemplate: function (itemData, itemIndex, itemElement) {
                                                                itemElement.addClass("contenedorImg");

                                                                if (itemData.extension.split("/")[0] === "image") {
                                                                    itemElement.css("cursor", "pointer");
                                                                    itemElement.append(
                                                                        $("<img />")
                                                                            .addClass("image")
                                                                            .attr(
                                                                                "style",
                                                                                "background-image: url(data:" +
                                                                                    itemData.extension +
                                                                                    ";base64," +
                                                                                    itemData.documento
                                                                            )
                                                                            .on("click", function () {
                                                                                var imagenes = $.grep(
                                                                                    incidenciaSel.tblIncidencia_Documento,
                                                                                    function (doc) {
                                                                                        return (
                                                                                            doc.extension.split(
                                                                                                "/"
                                                                                            )[0] === "image"
                                                                                        );
                                                                                    }
                                                                                );

                                                                                var dxGallery_documento =
                                                                                    $("#dxGallery_documento").dxGallery(
                                                                                        "instance"
                                                                                    );
                                                                                dxGallery_documento.beginUpdate();
                                                                                dxGallery_documento.option(
                                                                                    "dataSource",
                                                                                    imagenes
                                                                                );
                                                                                dxGallery_documento.option(
                                                                                    "selectedItem",
                                                                                    itemData
                                                                                );
                                                                                dxGallery_documento.endUpdate();

                                                                                $("#dxPopup_galeria_documento")
                                                                                    .dxPopup("instance")
                                                                                    .show();
                                                                            })
                                                                    );
                                                                } else {
                                                                    var ico_container = $("<div />")
                                                                        .addClass("container_spanCentrado")
                                                                        .on("click", function () {
                                                                            notify({
                                                                                message:
                                                                                    getTrad("alert_SoloAbrirImagenes"),
                                                                                type: "error",
                                                                                displayTime: "1500",
                                                                                closeOnClick: true,
                                                                            });
                                                                        });
                                                                    var ico = $("<i />").css("font-size", "45px");

                                                                    if (itemData.extension.split("/")[1] === "pdf") {
                                                                        ico.addClass("dx-icon-exportpdf");
                                                                    } else if (
                                                                        itemData.extension.split("/")[0] === "video"
                                                                    ) {
                                                                        ico.addClass("dx-icon-video");
                                                                    } else if (
                                                                        itemData.extension.includes("excel") ||
                                                                        itemData.extension.includes("spreadsheetml")
                                                                    ) {
                                                                        ico.addClass("dx-icon-exportxlsx");
                                                                    } else {
                                                                        ico.addClass("dx-icon-doc");
                                                                    }

                                                                    ico_container.append(ico);
                                                                    itemElement.append(ico_container);
                                                                }

                                                                itemElement.append(
                                                                    $("<div class='dxToolbar_opciones'/>")
                                                                        .css("position", "absolute")
                                                                        .css("bottom", "-5px")
                                                                        .css("right", "0px")
                                                                        .dxToolbar({
                                                                            items: [
                                                                                {
                                                                                    location: "after",
                                                                                    template: function (
                                                                                        dxToolbar_itemData,
                                                                                        dxToolbar_itemIndex,
                                                                                        dxToolbar_itemElement
                                                                                    ) {
                                                                                        dxToolbar_itemElement.append(
                                                                                            $("<div/>")
                                                                                                .addClass(
                                                                                                    "container_spanCentrado"
                                                                                                )
                                                                                                .addClass(
                                                                                                    "dxToolbar_opcion"
                                                                                                )

                                                                                                .css("height", "25px")
                                                                                                .css("width", "25px")
                                                                                                .append(
                                                                                                    svg_iconos.svg_flecha
                                                                                                        .clone()
                                                                                                        .css(
                                                                                                            "height",
                                                                                                            "15px"
                                                                                                        )
                                                                                                        .css(
                                                                                                            "width",
                                                                                                            "15px"
                                                                                                        )
                                                                                                        .on(
                                                                                                            "click",
                                                                                                            function () {
                                                                                                                var downloadLink =
                                                                                                                    document.createElement(
                                                                                                                        "a"
                                                                                                                    );
                                                                                                                downloadLink.href =
                                                                                                                    "data:" +
                                                                                                                    itemData.extension +
                                                                                                                    ";base64," +
                                                                                                                    itemData.documento;
                                                                                                                downloadLink.download =
                                                                                                                    itemData.nombre;
                                                                                                                downloadLink.click();
                                                                                                            }
                                                                                                        )
                                                                                                )
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    location: "after",
                                                                                    template: function (
                                                                                        dxToolbar_itemData,
                                                                                        dxToolbar_itemIndex,
                                                                                        dxToolbar_itemElement
                                                                                    ) {
                                                                                        if (
                                                                                            !(
                                                                                                isCerrada &&
                                                                                                !isUserRevisor
                                                                                            )
                                                                                        ) {
                                                                                            dxToolbar_itemElement.append(
                                                                                                $("<div/>")
                                                                                                    .addClass(
                                                                                                        "container_spanCentrado"
                                                                                                    )
                                                                                                    .addClass(
                                                                                                        "dxToolbar_opcion"
                                                                                                    )
                                                                                                    .append(
                                                                                                        svg_iconos.svg_remove
                                                                                                            .clone()
                                                                                                            .css(
                                                                                                                "height",
                                                                                                                "25px"
                                                                                                            )
                                                                                                            .css(
                                                                                                                "width",
                                                                                                                "25px"
                                                                                                            )
                                                                                                            .on(
                                                                                                                "click",
                                                                                                                function () {
                                                                                                                    dxMensajePregunta(
                                                                                                                        getTrad(
                                                                                                                            "preg_RegistroEliminarCorto"
                                                                                                                        ),
                                                                                                                        [
                                                                                                                            [
                                                                                                                                getTrad(
                                                                                                                                    "aceptar"
                                                                                                                                ),
                                                                                                                                function () {
                                                                                                                                    $.each(
                                                                                                                                        incidenciaSel.tblIncidencia_Documento,
                                                                                                                                        function (
                                                                                                                                            i,
                                                                                                                                            el
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                this
                                                                                                                                                    .idDocumento ===
                                                                                                                                                itemData.idDocumento
                                                                                                                                            ) {
                                                                                                                                                incidenciaSel.tblIncidencia_Documento.splice(
                                                                                                                                                    i,
                                                                                                                                                    1
                                                                                                                                                );
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    );

                                                                                                                                    $(
                                                                                                                                        "#dxTileView_documentos"
                                                                                                                                    )
                                                                                                                                        .dxTileView(
                                                                                                                                            "instance"
                                                                                                                                        )
                                                                                                                                        .option(
                                                                                                                                            "dataSource",
                                                                                                                                            incidenciaSel.tblIncidencia_Documento
                                                                                                                                        );
                                                                                                                                },
                                                                                                                                "danger",
                                                                                                                            ],
                                                                                                                            [
                                                                                                                                getTrad(
                                                                                                                                    "cancelar"
                                                                                                                                ),
                                                                                                                                function () {},
                                                                                                                            ],
                                                                                                                        ]
                                                                                                                    );
                                                                                                                }
                                                                                                            )
                                                                                                    )
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                },
                                                                            ],
                                                                        })
                                                                );
                                                            },
                                                        })
                                                );

                                                itemElement.append(
                                                    $("<div id='dxPopup_galeria_documento'>").dxPopup({
                                                        deferRendering: false,
                                                        showTitle: true,
                                                        title: getTrad("galeriaImagenes"),
                                                        showCloseButton: true,
                                                        closeOnOutsideClick: true,
                                                        width: "900px",
                                                        height: "700px",
                                                        contentTemplate: function (contentElement) {
                                                            contentElement.append(
                                                                $("<div id='dxGallery_documento'>").dxGallery({
                                                                    dataSource: incidenciaSel.tblIncidencia_Documento,
                                                                    showIndicator: true,
                                                                    showNavButtons: true,
                                                                    loop: true,
                                                                    height: "100%",
                                                                    width: "100%",
                                                                    itemTemplate: function (
                                                                        itemData,
                                                                        itemIndex,
                                                                        itemElement
                                                                    ) {
                                                                        itemElement.append(
                                                                            $("<img />")
                                                                                .addClass("image")
                                                                                .attr(
                                                                                    "style",
                                                                                    "background-image: url(data:" +
                                                                                        itemData.extension +
                                                                                        ";base64," +
                                                                                        itemData.documento
                                                                                )
                                                                        );
                                                                    },
                                                                })
                                                            );
                                                        },
                                                    })
                                                );

                                                itemElement.append(
                                                    $("<div id='dxFileUploader_documento'/>").dxFileUploader({
                                                        width: "100%",
                                                        labelText: getTrad("msg_arrastraArchivo5mb"),
                                                        accept: "*",
                                                        minFileSize: 1,
                                                        multiple: true,
                                                        uploadMode: "instantly",
                                                        showFileList: false,
                                                        uploadFailedMessage: getTrad("subidaFallida"),
                                                        selectButtonText: getTrad("subirArchivo"),
                                                        disabled: isCerrada && !isUserRevisor,
                                                        onUploadStarted: function (e) {
                                                            var reader = new FileReader();

                                                            if (e.file.size > 5242880) {
                                                                notify({
                                                                    message: getTrad("subirArchivo"),
                                                                    type: "error",
                                                                    displayTime: "1500",
                                                                    closeOnClick: true,
                                                                });
                                                            } else {
                                                                reader.onloadend = function () {
                                                                    if (reader.result !== "") {
                                                                        var fileSplit = reader.result.split(",");
                                                                        var extension = e.file.type;
                                                                        var base64 = fileSplit[1];
                                                                        var fileName = e.file.name;

                                                                        function insertarDocumento() {
                                                                            incidenciaSel.tblIncidencia_Documento.push({
                                                                                idDocumento: new Guid(),
                                                                                nombre: fileName,
                                                                                documento: base64,
                                                                                extension: extension,
                                                                            });

                                                                            e.component.option({
                                                                                value: [],
                                                                            });

                                                                            $("#dxTileView_documentos")
                                                                                .dxTileView("instance")
                                                                                .option(
                                                                                    "dataSource",
                                                                                    incidenciaSel.tblIncidencia_Documento
                                                                                );
                                                                        }

                                                                        //Compress
                                                                        if (extension.split("/")[0] === "image") {
                                                                            compressBase64Img_scaleWidth(
                                                                                reader.result,
                                                                                800
                                                                            ).then(function (compressedBase64) {
                                                                                base64 = compressedBase64;
                                                                                insertarDocumento();
                                                                            });
                                                                        } else {
                                                                            insertarDocumento();
                                                                        }
                                                                    }
                                                                };
                                                                reader.readAsDataURL(e.file);
                                                            }

                                                            e.request.abort();
                                                        },
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                },

                                //#region VARIABLE DE TIPO INCIDENCIA EN CREACIÓN

                                {
                                    label: { visible: false },
                                    dataField: "idMaquina",
                                    name: "idMaquina_creacion",
                                    template: function (data, itemElement) {
                                        var contenedorImg = $("<div id='warning_idMaquina'/>")
                                            .addClass("container_spanCentrado")
                                            .css("display", "none")
                                            .css("width", "40px")
                                            .css("float", "left");

                                        svg_iconos.svg_warning
                                            .clone()
                                            .css("height", "25px")
                                            .css("width", "25px")
                                            .css("cursor", "pointer")
                                            .appendTo(contenedorImg);

                                        itemElement.append(contenedorImg);

                                        itemElement.append(
                                            $("<div />")
                                                .addClass("dx-form-group-caption")
                                                .css("border-bottom", "1px solid #ddd")
                                                .css("padding-bottom", "6px")
                                                .css("margin-bottom", "20px")
                                                .text(getTrad("maquina"))
                                        );

                                        //DATA GRID
                                        itemElement.append(
                                            $("<div />").dxList({
                                                dataSource: datasource_tblMaquina.items(),
                                                selectionMode: "single",
                                                height: "500px",
                                                keyExpr: "idMaquina",
                                                pageLoadMode: "scrollBottom",
                                                selectedItemKeys: [incidenciaSel.idMaquina],
                                                searchEnabled: true,
                                                searchExpr: "denominacion",
                                                itemTemplate: function (itemData, index, itemElement) {
                                                    var isSelected = itemData.idMaquina === incidenciaSel.idMaquina;

                                                    if (!isSelected && incidenciaSel.idMaquina) {
                                                        itemElement.addClass("disabledRow");
                                                    }

                                                    itemElement.addClass("container_spanCentrado");
                                                    itemElement.css("display", "flex");
                                                    itemElement.css("height", "45px");
                                                    itemElement.append(
                                                        $("<div />")
                                                            .css("flex", "1")
                                                            .css("font-size", "16px")
                                                            .css("padding-left", "10px")
                                                            .text(itemData.denominacion)
                                                    );

                                                    if (isSelected) {
                                                        itemElement.append(
                                                            $("<div />")
                                                                .dxSelectBox({
                                                                    dataSource: enum_estadosMaquina,
                                                                    displayExpr: "denominacion",
                                                                    valueExpr: "estadoMaquina",
                                                                    value: incidenciaSel.estadoMaquina,
                                                                    onValueChanged: function (e) {
                                                                        let dxSelectBox_peso =
                                                                            $("#dxSelectBox_peso").dxSelectBox(
                                                                                "instance"
                                                                            );
                                                                        incidenciaSel.estadoMaquina = e.value;

                                                                        if (e.value === 1) {
                                                                            dxSelectBox_peso.option("visible", true);
                                                                            dxSelectBox_peso.option("valor", 3);
                                                                        } else {
                                                                            dxSelectBox_peso.option("visible", false);
                                                                            dxSelectBox_peso.option("valor", null);
                                                                            incidenciaSel.peso = null;
                                                                        }
                                                                    },
                                                                })
                                                                .dxValidator({
                                                                    validationGroup:
                                                                        data.component.option("validationGroup"),
                                                                    validationRules: [
                                                                        {
                                                                            type: "required",
                                                                            message:
                                                                                getTrad("estado") +
                                                                                ": " +
                                                                                getTrad("alert_asignarEstado"),
                                                                        },
                                                                    ],
                                                                }),
                                                            $("<div />").width(10),
                                                            $("<div />").dxSelectBox({
                                                                width: 135,
                                                                visible: false,
                                                                dataSource: datasource_tblPesoIncidencia.items(),
                                                                displayExpr: "valor",
                                                                valueExpr: "idPeso",
                                                                elementAttr: { id: "dxSelectBox_peso" },
                                                                placeholder: getTrad("peso"),
                                                                displayExpr: function (item) {
                                                                    return (
                                                                        item && formatNumber(item.valor, 0, "percent")
                                                                    );
                                                                },
                                                                onValueChanged: function (e) {
                                                                    incidenciaSel.peso = e.value;
                                                                },
                                                            })
                                                        );
                                                    }
                                                },
                                                onSelectionChanged: function (e) {
                                                    if (e.addedItems.length > 0) {
                                                        var maquinaSel = e.addedItems[0];
                                                        incidenciaSel.idMaquina = maquinaSel.idMaquina;
                                                        incidenciaSel.estadoMaquina = null;

                                                        var dxList = e.component;
                                                        var scroll_position = dxList.scrollTop();
                                                        dxList.repaint();
                                                        dxList.scrollTo(scroll_position);
                                                    } else {
                                                        incidenciaSel.idMaquina = null;
                                                        incidenciaSel.estadoMaquina = null;
                                                    }

                                                    dxForm_customCheck(data.dataField);
                                                },
                                                onOptionChanged: function (e) {
                                                    if (e.name === "searchValue") {
                                                        e.component.unselectAll();
                                                    }
                                                },
                                            })
                                        );
                                    },
                                },
                                {
                                    itemType: "group",
                                    // caption: getTrad("compaEnti"),
                                    name: "idCompañia_idEntidad_creacion",
                                    colCount: 1,
                                    items: [
                                        {
                                            label: { visible: false },
                                            dataField: "idCompañia",
                                            template: function (data, itemElement) {
                                                var contenedorImg = $("<div id='warning_idCompañia'/>")
                                                    .addClass("container_spanCentrado")
                                                    .css("display", "none")
                                                    .css("width", "40px")
                                                    .css("float", "left");

                                                svg_iconos.svg_warning
                                                    .clone()
                                                    .css("height", "25px")
                                                    .css("width", "25px")
                                                    .css("cursor", "pointer")
                                                    .appendTo(contenedorImg);

                                                itemElement.append(contenedorImg);

                                                itemElement.append(
                                                    $("<div />")
                                                        .addClass("dx-form-group-caption")
                                                        .css("border-bottom", "1px solid #ddd")
                                                        .css("padding-bottom", "6px")
                                                        .css("margin-bottom", "20px")
                                                        .text(getTrad("compañia"))
                                                );

                                                //DATA GRID
                                                itemElement.append(
                                                    $("<div id='dxList_compañia_creacion'/>").dxList({
                                                        dataSource: datasource_tblCompañia.items(),
                                                        selectionMode: "single",
                                                        keyExpr: "idCompañia",
                                                        pageLoadMode: "scrollBottom",
                                                        selectedItemKeys: [incidenciaSel.idCompañia],
                                                        searchEnabled: true,
                                                        height: "250px",
                                                        searchExpr: "denominacion",
                                                        itemTemplate: function (itemData, index, itemElement) {
                                                            var isSelected =
                                                                itemData.idCompañia === incidenciaSel.idCompañia;

                                                            if (!isSelected && incidenciaSel.idCompañia) {
                                                                itemElement.addClass("disabledRow");
                                                            }

                                                            itemElement.addClass("container_spanCentrado");
                                                            itemElement.css("display", "flex");
                                                            itemElement.css("height", "45px");
                                                            itemElement.append(
                                                                $("<div />")
                                                                    .css("flex", "1")
                                                                    .css("font-size", "16px")
                                                                    .css("padding-left", "10px")
                                                                    .text(itemData.denominacion)
                                                            );
                                                        },
                                                        onSelectionChanged: function (e) {
                                                            if (e.addedItems.length > 0) {
                                                                var compañiaSel = e.addedItems[0];
                                                                incidenciaSel.idCompañia = compañiaSel.idCompañia;
                                                                incidenciaSel.idEntidad = null;

                                                                // var dxList = e.component;
                                                                // var scroll_position = dxList.scrollTop();
                                                                // dxList.repaint();
                                                                // dxList.scrollTo(scroll_position);
                                                            } else {
                                                                incidenciaSel.idEntidad = null;
                                                                incidenciaSel.idCompañia = null;
                                                            }

                                                            $("#dxForm_incidencia").dxForm("instance").repaint();

                                                            dxForm_customCheck(data.dataField);
                                                        },
                                                        onOptionChanged: function (e) {
                                                            if (e.name === "searchValue") {
                                                                e.component.unselectAll();
                                                            }
                                                        },
                                                    })
                                                );
                                            },
                                        },
                                        {
                                            label: { visible: false },
                                            dataField: "idEntidad",
                                            template: function (data, itemElement) {
                                                var contenedorImg = $("<div id='warning_idEntidad'/>")
                                                    .addClass("container_spanCentrado")
                                                    .css("display", "none")
                                                    .css("width", "40px")
                                                    .css("float", "left");

                                                svg_iconos.svg_warning
                                                    .clone()
                                                    .css("height", "25px")
                                                    .css("width", "25px")
                                                    .css("cursor", "pointer")
                                                    .appendTo(contenedorImg);

                                                itemElement.append(contenedorImg);

                                                itemElement.append(
                                                    $("<div />")
                                                        .addClass("dx-form-group-caption")
                                                        .css("border-bottom", "1px solid #ddd")
                                                        .css("padding-bottom", "6px")
                                                        .css("margin-bottom", "20px")
                                                        .text(getTrad("entidad"))
                                                );

                                                //DATA GRID
                                                itemElement.append(
                                                    $("<div id='dxList_entidad_creacion'/>").dxList({
                                                        dataSource: datasource_tblEntidad.items(),
                                                        selectionMode: "single",
                                                        keyExpr: "idEntidad",
                                                        pageLoadMode: "scrollBottom",
                                                        height: "250px",
                                                        selectedItemKeys: [incidenciaSel.idEntidad],
                                                        searchEnabled: true,
                                                        searchExpr: "denominacion",
                                                        itemTemplate: function (itemData, index, itemElement) {
                                                            var isSelected =
                                                                itemData.idEntidad === incidenciaSel.idEntidad;

                                                            if (!isSelected && incidenciaSel.idEntidad) {
                                                                itemElement.addClass("disabledRow");
                                                            }

                                                            itemElement.addClass("container_spanCentrado");
                                                            itemElement.css("display", "flex");
                                                            itemElement.css("height", "45px");
                                                            itemElement.append(
                                                                $("<div />")
                                                                    .css("flex", "1")
                                                                    .css("font-size", "16px")
                                                                    .css("padding-left", "10px")
                                                                    .text(itemData.denominacion)
                                                            );
                                                        },
                                                        onSelectionChanged: function (e) {
                                                            if (e.addedItems.length > 0) {
                                                                var entidadSel = e.addedItems[0];
                                                                incidenciaSel.idEntidad = entidadSel.idEntidad;
                                                                incidenciaSel.idCompañia = entidadSel.idCompañia;

                                                                // var dxList = e.component;
                                                                // var scroll_position = dxList.scrollTop();
                                                                // dxList.repaint();
                                                                // dxList.scrollTo(scroll_position);
                                                            } else {
                                                                incidenciaSel.idEntidad = null;
                                                                incidenciaSel.idCompañia = null;
                                                            }
                                                            $("#dxForm_incidencia").dxForm("instance").repaint();
                                                            dxForm_customCheck(data.dataField);
                                                        },
                                                        onOptionChanged: function (e) {
                                                            if (e.name === "searchValue") {
                                                                e.component.unselectAll();
                                                            }
                                                        },
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                },
                                {
                                    label: { visible: false },
                                    dataField: "idUsuarioAfecta",
                                    name: "idUsuarioAfecta_creacion",
                                    template: function (data, itemElement) {
                                        var contenedorImg = $("<div id='warning_idUsuarioAfecta'/>")
                                            .addClass("container_spanCentrado")
                                            .css("display", "none")
                                            .css("width", "40px")
                                            .css("float", "left");

                                        svg_iconos.svg_warning
                                            .clone()
                                            .css("height", "25px")
                                            .css("width", "25px")
                                            .css("cursor", "pointer")
                                            .appendTo(contenedorImg);

                                        itemElement.append(contenedorImg);

                                        itemElement.append(
                                            $("<div />")
                                                .addClass("dx-form-group-caption")
                                                .css("border-bottom", "1px solid #ddd")
                                                .css("padding-bottom", "6px")
                                                .css("margin-bottom", "20px")
                                                .text(getTrad("nombrePersona"))
                                        );

                                        //LISTA RESPONSBLES
                                        itemElement.append(
                                            $("<div />").dxList({
                                                dataSource: datasource_tblPersona_responsables,
                                                selectionMode: "single",
                                                height: "500px",
                                                keyExpr: "idPersona",
                                                pageLoadMode: "scrollBottom",
                                                selectedItemKeys: [incidenciaSel.idUsuarioAfecta],
                                                searchEnabled: true,
                                                searchExpr: "nombreCompuesto",
                                                itemTemplate: function (itemData, index, itemElement) {
                                                    var isSelected =
                                                        itemData.idPersona === incidenciaSel.idUsuarioAfecta;

                                                    if (!isSelected && incidenciaSel.idUsuarioAfecta) {
                                                        itemElement.addClass("disabledRow");
                                                    }

                                                    itemElement.addClass("container_spanCentrado");
                                                    itemElement.css("display", "flex");
                                                    itemElement.css("height", "45px");
                                                    itemElement.append(
                                                        $("<div />")
                                                            .css("flex", "1")
                                                            .css("font-size", "16px")
                                                            .css("padding-left", "10px")
                                                            .text(itemData.nombreCompuesto)
                                                    );
                                                },
                                                onSelectionChanged: function (e) {
                                                    if (e.addedItems.length > 0) {
                                                        var usuarioAfectaSel = e.addedItems[0];
                                                        incidenciaSel.idUsuarioAfecta = usuarioAfectaSel.idPersona;

                                                        var dxList = e.component;
                                                        var scroll_position = dxList.scrollTop();
                                                        dxList.repaint();
                                                        dxList.scrollTo(scroll_position);
                                                    } else {
                                                        incidenciaSel.idUsuarioAfecta = null;
                                                    }

                                                    dxForm_customCheck(data.dataField);
                                                },
                                                onOptionChanged: function (e) {
                                                    if (e.name === "searchValue") {
                                                        e.component.unselectAll();
                                                    }
                                                },
                                            })
                                        );
                                    },
                                },
                                {
                                    label: { visible: false },
                                    dataField: "idVehiculo",
                                    name: "idVehiculo_creacion",
                                    template: function (data, itemElement) {
                                        var contenedorImg = $("<div id='warning_idVehiculo'/>")
                                            .addClass("container_spanCentrado")
                                            .css("display", "none")
                                            .css("width", "40px")
                                            .css("float", "left");

                                        svg_iconos.svg_warning
                                            .clone()
                                            .css("height", "25px")
                                            .css("width", "25px")
                                            .css("cursor", "pointer")
                                            .appendTo(contenedorImg);

                                        itemElement.append(contenedorImg);

                                        itemElement.append(
                                            $("<div />")
                                                .addClass("dx-form-group-caption")
                                                .css("border-bottom", "1px solid #ddd")
                                                .css("padding-bottom", "6px")
                                                .css("margin-bottom", "20px")
                                                .text(getTrad("vehiculo"))
                                        );

                                        //LISTA
                                        itemElement.append(
                                            $("<div />").dxList({
                                                dataSource: datasource_tblVehiculo.items(),
                                                selectionMode: "single",
                                                height: "500px",
                                                keyExpr: "idVehiculo",
                                                pageLoadMode: "scrollBottom",
                                                selectedItemKeys: [incidenciaSel.idVehiculo],
                                                searchEnabled: true,
                                                searchExpr: "denominacion",
                                                itemTemplate: function (itemData, index, itemElement) {
                                                    var isSelected = itemData.idVehiculo === incidenciaSel.idVehiculo;

                                                    if (!isSelected && incidenciaSel.idVehiculo) {
                                                        itemElement.addClass("disabledRow");
                                                    }

                                                    itemElement.addClass("container_spanCentrado");
                                                    itemElement.css("display", "flex");
                                                    itemElement.css("height", "45px");
                                                    itemElement.append(
                                                        $("<div />")
                                                            .css("flex", "1")
                                                            .css("font-size", "16px")
                                                            .css("padding-left", "10px")
                                                            .text(itemData.denominacion)
                                                    );
                                                },
                                                onSelectionChanged: function (e) {
                                                    if (e.addedItems.length > 0) {
                                                        var vehiculoSel = e.addedItems[0];
                                                        incidenciaSel.idVehiculo = vehiculoSel.idVehiculo;

                                                        var dxList = e.component;
                                                        var scroll_position = dxList.scrollTop();
                                                        dxList.repaint();
                                                        dxList.scrollTo(scroll_position);
                                                    } else {
                                                        incidenciaSel.idVehiculo = null;
                                                    }

                                                    dxForm_customCheck(data.dataField);
                                                },
                                                onOptionChanged: function (e) {
                                                    if (e.name === "searchValue") {
                                                        e.component.unselectAll();
                                                    }
                                                },
                                            })
                                        );
                                    },
                                },

                                //#endregion

                                //#region VARIABLES MODIFICAR

                                {
                                    itemType: "group",
                                    caption: getTrad("revisionIncidencia"),
                                    name: "Revision",
                                    colCount: 1,
                                    items: [
                                        {
                                            dataField: "estado",
                                            label: { text: getTrad("estado") },
                                            editorType: "dxLookup",
                                            colSpan: 2,
                                            name: "estado",
                                            editorOptions: {
                                                dataSource: enum_estadoIncidencia,
                                                displayExpr: "denominacion",
                                                valueExpr: "estado",
                                                title: getTrad("estado"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: false,
                                            },
                                        },
                                        {
                                            dataField: "isInformativa",
                                            label: { text: getTrad("preg_grupoIncidencia") },
                                            editorType: "dxRadioGroup",
                                            name: "isInformativa",
                                            colSpan: 1,
                                            editorOptions: {
                                                height: "100px",
                                                width: "100%",
                                                dataSource: [
                                                    {
                                                        text: getTrad("msg_incidenciaInformativa"),
                                                        isInformativa: true,
                                                    },
                                                    {
                                                        text: getTrad("msg_incidenciaCorrectiva"),
                                                        isInformativa: false,
                                                    },
                                                ],
                                                valueExpr: "isInformativa",
                                                layout: "horizontal",
                                                itemTemplate: function (itemData, itemIndex, itemElement) {
                                                    itemElement.addClass("container_spanCentrado");
                                                    itemElement.append($("<span>").text(itemData.text));
                                                },
                                                onValueChanged: function (e) {
                                                    if (!isCreacion) {
                                                        var form = $("#dxForm_incidencia").dxForm("instance");
                                                        var hayCambios =
                                                            incidenciaSel.idUsuarioResponsable ||
                                                            incidenciaSel.tblAccionesCorrectivas.length > 0;

                                                        function cambioCorrecto() {
                                                            //Se limpia el responsable
                                                            if (e.value) {
                                                                incidenciaSel.idUsuarioResponsable = null;
                                                                incidenciaSel.tblAccionesCorrectivas.length = 0;
                                                            }

                                                            form.getEditor("idUsuarioResponsable").option({
                                                                disabled: e.value,
                                                                value: incidenciaSel.idUsuarioResponsable,
                                                            });

                                                            //Se limpian las acciones correctivas
                                                            var dxDataGrid_tblAccionesCorrectivas = $(
                                                                "#dxDataGrid_tblAccionesCorrectivas"
                                                            ).dxDataGrid("instance");
                                                            dxDataGrid_tblAccionesCorrectivas.option({
                                                                disabled: e.value || isSat,
                                                                dataSource: incidenciaSel.tblAccionesCorrectivas,
                                                            });

                                                            dxDataGrid_tblAccionesCorrectivas.repaint();
                                                        }

                                                        if (e.value === true && hayCambios) {
                                                            //De Acciones a Informativa
                                                            dxMensajePregunta(
                                                                getTrad("preg_incidenciaCambioInformativa"),
                                                                [
                                                                    [
                                                                        getTrad("aceptar"),
                                                                        function () {
                                                                            cambioCorrecto();
                                                                        },
                                                                        "danger",
                                                                    ],
                                                                    [
                                                                        getTrad("cancelar"),
                                                                        function () {
                                                                            e.component.option("value", false);
                                                                        },
                                                                    ],
                                                                ]
                                                            );
                                                        } else {
                                                            cambioCorrecto();
                                                        }
                                                    }
                                                },
                                            },
                                        },
                                        {
                                            dataField: "idPoliticaDisciplinaria",
                                            label: { text: getTrad("politicaDisciplinaria") },
                                            editorType: "dxSelectBox",
                                            name: "idPoliticaDisciplinaria",
                                            colSpan: 1,
                                            editorOptions: {
                                                dataSource: datasource_tblPoliticaDisciplinaria.items(),
                                                displayExpr: "denominacion",
                                                valueExpr: "idPoliticaDisciplinaria",
                                                showClearButton: true,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message:
                                                        getTrad("politicaDisciplinaria") +
                                                        ": " +
                                                        getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "idUsuarioResponsable",
                                            label: { text: getTrad("responsable") },
                                            editorType: "dxLookup",
                                            name: "idUsuarioResponsable",
                                            colSpan: 1,
                                            editorOptions: {
                                                dataSource: datasource_tblPersona_responsables,
                                                displayExpr: "nombreCompuesto",
                                                valueExpr: "idPersona",
                                                searchExpr: ["nombre", "apellidos"],
                                                title: getTrad("responsable"),
                                                cleanSearchOnOpening: true,
                                                closeOnOutsideClick: true,
                                                searchEnabled: true,
                                            },
                                            validationRules: [
                                                {
                                                    type: "required",
                                                    message: getTrad("responsable") + ": " + getTrad("campoNecesario"),
                                                },
                                            ],
                                        },
                                        {
                                            dataField: "tblAccionesCorrectivas",
                                            name: "tblAccionesCorrectivas",
                                            label: { visible: false },
                                            template: function (data, itemElement) {
                                                itemElement.append(
                                                    $("<div id='dxDataGrid_tblAccionesCorrectivas'/>").dxDataGrid({
                                                        dataSource: data.component.option("formData")[data.dataField],
                                                        //Propiedades
                                                        keyExpr: "idAccionCorrectiva",
                                                        height: "100%",
                                                        columnsAutoWidth: true,
                                                        refreshMode: "rehsape",
                                                        repaintChangesOnly: true,
                                                        disabled: false,
                                                        disabled:
                                                            incidenciaSel.isInformativa ||
                                                            !(!isRevisada || isUserRevisor) ||
                                                            isSat,
                                                        sorting: {
                                                            mode: "multiple",
                                                        },
                                                        editing: {
                                                            refreshMode: "repaint",
                                                            mode: "cell",
                                                            allowAdding: true,
                                                            allowUpdating: true,
                                                            allowDelete: true,
                                                            useIcons: true,
                                                            selectTextOnEditStart: true,
                                                        },
                                                        keyboardNavigation: {
                                                            enterKeyAction: "moveFocus",
                                                            enterKeyDirection: "row",
                                                            editOnKeyPress: true,
                                                        },
                                                        hoverStateEnabled: true,
                                                        paging: {
                                                            enabled: false,
                                                        },
                                                        columns: [
                                                            {
                                                                dataField: "idAccionCorrectiva",
                                                                visible: false,
                                                            },
                                                            {
                                                                dataField: "estado",
                                                                caption: getTrad("estado"),
                                                                dataType: "boolean",
                                                                width: "100px",
                                                            },
                                                            {
                                                                dataField: "denominacion",
                                                                caption: getTrad("accionesCorrectivas"),
                                                                validationRules: [
                                                                    {
                                                                        type: "required",
                                                                        message: getTrad("campoNecesario"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                dataField: "fecha",
                                                                dataType: "date",
                                                                caption: getTrad("fechaLimite"),
                                                                sortOrder: "asc",
                                                                width: "130px",
                                                                sortIndex: 1,
                                                                alignment: "center",
                                                                format: "dd/MM/yyyy",
                                                                editorOptions: {
                                                                    useMaskBehavior: true,
                                                                    displayFormat: "dd/MM/yyyy",
                                                                    calendarOptions: {
                                                                        min: incidenciaSel.fechaIncidencia,
                                                                    },
                                                                },
                                                                validationRules: [
                                                                    {
                                                                        type: "required",
                                                                        message: getTrad("campoNecesario"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                caption: " ",
                                                                width: "40px",
                                                                alignment: "center",
                                                                cssClass: "p-0",
                                                                cellTemplate: function (cellElement, cellInfo) {
                                                                    var contenedor =
                                                                        $("<div/>").addClass("container_spanCentrado");
                                                                    svg_iconos.svg_remove
                                                                        .clone()

                                                                        .css("height", "25px")
                                                                        .css("width", "25px")
                                                                        .css("cursor", "pointer")
                                                                        .appendTo(contenedor);

                                                                    cellElement.on("click", function () {
                                                                        cellInfo.component.deleteRow(cellInfo.rowIndex);
                                                                    });

                                                                    contenedor.appendTo(cellElement);
                                                                },
                                                            },
                                                        ],
                                                        onInitNewRow: function (e) {
                                                            e.data.fecha = convertClienteUtcToLavanderiaUtc(
                                                                LAVANDERIA.GMT,
                                                                new Date()
                                                            );
                                                            e.data.denominacion = "";
                                                            e.data.estado = false;
                                                        },
                                                        onRowPrepared: function (e) {
                                                            if (e.rowType === "data") {
                                                                e.isSelected = e.data.estado;
                                                                if (e.data.estado) {
                                                                    e.rowElement.addClass("successRow");
                                                                }
                                                            }
                                                        },
                                                        onEditorPreparing: function (e) {
                                                            if (
                                                                e.parentType === "dataRow" &&
                                                                e.dataField === "estado"
                                                            ) {
                                                                e.editorOptions.disabled = e.row.inserted;
                                                            }
                                                        },
                                                        onRowInserted: function (e) {
                                                            e.component.refresh(true);
                                                            e.component.repaint();
                                                        },
                                                        onRowRemoved: function (e) {
                                                            e.component.refresh(true);
                                                            e.component.repaint();
                                                        },
                                                        onRowUpdated: function (e) {
                                                            e.component.refresh(true);
                                                            e.component.repaint();
                                                        },
                                                        onToolbarPreparing: function (e) {
                                                            $.each(e.toolbarOptions.items, function (index, item) {
                                                                switch (item.name) {
                                                                    case "addRowButton":
                                                                        item.options.text = getTrad("añadir");
                                                                        item.options.icon = "plus";
                                                                        item.options.type = "success";
                                                                        item.showText = "always";
                                                                        break;
                                                                    default:
                                                                        break;
                                                                }
                                                            });

                                                            e.toolbarOptions.items.unshift(
                                                                {
                                                                    location: "before",
                                                                    template: function () {
                                                                        return $("<span>")
                                                                            .addClass("dx-field-item-label-content")
                                                                            .append(
                                                                                $("<span>")
                                                                                    .text(
                                                                                        getTrad("accionesCorrectivas") +
                                                                                            ": "
                                                                                    )
                                                                                    .css("font-weight", "700")
                                                                            )
                                                                            .append(
                                                                                $("<span>")
                                                                                    .addClass(
                                                                                        "dx-field-item-required-mark"
                                                                                    )
                                                                                    .text("*")
                                                                            );
                                                                    },
                                                                },
                                                                {
                                                                    location: "before",
                                                                    template: function () {
                                                                        var contenedorImg = $(
                                                                            "<div id='warning_accionesCorrectivas'/>"
                                                                        )
                                                                            .addClass("container_spanCentrado")
                                                                            .css("display", "none")
                                                                            .css("width", "40px")
                                                                            .css("float", "left");

                                                                        svg_iconos.svg_warning
                                                                            .clone()
                                                                            .css("height", "25px")
                                                                            .css("width", "25px")
                                                                            .css("cursor", "pointer")
                                                                            .appendTo(contenedorImg);

                                                                        return contenedorImg;
                                                                    },
                                                                },
                                                                {
                                                                    location: "center",
                                                                    template: function () {
                                                                        return $(
                                                                            "<div id='dxProgressBar_tblAccionesCorrectivas'/>"
                                                                        ).dxProgressBar({
                                                                            min: 0,
                                                                            max: incidenciaSel.tblAccionesCorrectivas
                                                                                .length,
                                                                            width: "250px",
                                                                            value: $.grep(
                                                                                incidenciaSel.tblAccionesCorrectivas,
                                                                                function (accion) {
                                                                                    return accion.estado;
                                                                                }
                                                                            ).length,
                                                                            statusFormat: function (ratio, value) {
                                                                                return (
                                                                                    value +
                                                                                    " / " +
                                                                                    incidenciaSel.tblAccionesCorrectivas
                                                                                        .length +
                                                                                    " Tareas realizadas"
                                                                                );
                                                                            },
                                                                        });
                                                                    },
                                                                }
                                                            );
                                                        },
                                                        //Estilos
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                },

                                //#endregion
                            ],
                            onContentReady: function (e) {
                                var form = e.component;
                                form.getEditor("idSubTipoIncidencia").option(
                                    "dataSource",
                                    $.grep(datasource_tblTipoSubIncidencia.items(), function (tipoSub) {
                                        return tipoSub.idTipoIncidencia === tipoIncidenciaSel.idTipoIncidencia;
                                    })
                                );

                                //MODIFICAR
                                if (!isCreacion) {
                                    //SOLO HABILITADA SI ERES EL REVISOR O NO ESTÁ REVISADA
                                    form.getEditor("isInformativa").option("disabled", !(!isRevisada || isUserRevisor));
                                    form.getEditor("idUsuarioResponsable").option(
                                        "disabled",
                                        incidenciaSel.isInformativa || !(!isRevisada || isUserRevisor)
                                    );
                                    if (isRRHH) {
                                        form.getEditor("idPoliticaDisciplinaria").option(
                                            "disabled",
                                            !(!isRevisada || isUserRevisor)
                                        );
                                    }
                                }
                            },
                        })
                    );
                },
                toolbarItems: [
                    {
                        toolbar: "top",
                        location: "center",
                        html: "<span id='dxPopup_incidencia_title'></span>",
                    },
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: getTrad("guardar"),
                            elementAttr: { id: "dxButton_GuardarInc" },
                            onClick: function () {
                                if (isCerrada && !isUserRevisor) {
                                    $("#dxPopup_incidencia").dxPopup("instance").hide();
                                    return;
                                }

                                var form = $("#dxForm_incidencia").dxForm("instance");
                                var formData = form.option("formData");
                                var isValid_customCheck = dxForm_customCheck();

                                if (formData && form.validate().isValid && isValid_customCheck) {
                                    function envioDatos() {
                                        var data = tratarDatos($.extend(true, {}, formData));

                                        if (!data.idIncidencia) {
                                            //POST
                                            delete data.idIncidencia;

                                            let dxDataGrid_tblIncidencia =
                                                $("#dxDataGrid_tblIncidencia").dxDataGrid("instance");

                                            dxDataGrid_tblIncidencia.option("loadPanel.enabled", false);
                                            $("#dxPopup_incidencia").dxPopup("instance").hide();
                                            COMPONENT.loadPanel_show(true);
                                            datasource_tblIncidencia
                                                .store()
                                                .insert(data)
                                                .done(function (incidencia) {
                                                    $.when(
                                                        datasource_spResumen_Incidencias.load(),
                                                        datasource_tblIncidencia.reload()
                                                    ).then(function () {
                                                        COMPONENT.loadPanel_hide();

                                                        notify({
                                                            message: getTrad("aviso_C_RegistroInsertado"),
                                                            type: "success",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                        });

                                                        dxDataGrid_tblIncidencia.option("loadPanel.enabled", true);
                                                    });
                                                });
                                        } else {
                                            //PATCH
                                            let dxButton_GuardarInc = $("#dxButton_GuardarInc").dxButton("instance");
                                            dxButton_GuardarInc.option("disabled", true);
                                            datasource_tblIncidencia
                                                .store()
                                                .update(data.idIncidencia, data)
                                                .done(function (incidencia) {
                                                    datasource_spResumen_Incidencias.load();
                                                    datasource_tblIncidencia.reload();

                                                    isUserRevisor = incidencia.idUsuarioRevisor === USER.idUsuario; //|| parent.usuario.idCargo === 1;
                                                    var hayCambio_responsable =
                                                        incidenciaSel_bak.idUsuarioResponsable !==
                                                        incidencia.idUsuarioResponsable;

                                                    if (isUserRevisor && hayCambio_responsable) {
                                                        var dataSource_correos = [];

                                                        if (!data.isInformativa) {
                                                            var tblPersona_responsable = form
                                                                .getEditor("idUsuarioResponsable")
                                                                .option("selectedItem");
                                                            if (tblPersona_responsable.email) {
                                                                dataSource_correos.push({
                                                                    denominacion: tblPersona_responsable.email,
                                                                    orden: 1,
                                                                });
                                                            }
                                                        }

                                                        $("#dxDataGrid_tblCorreosNLav")
                                                            .dxDataGrid("instance")
                                                            .option(
                                                                "dataSource",
                                                                $.extend(true, [], dataSource_correos)
                                                            );

                                                        $("#dxPopup_notificar").dxPopup("instance").show();
                                                    }

                                                    $("#dxPopup_incidencia").dxPopup("instance").hide();

                                                    dxButton_GuardarInc.option("disabled", false);
                                                    notify({
                                                        message: getTrad("aviso_C_RegistroActualizado"),
                                                        type: "success",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                    });
                                                });
                                        }
                                    }

                                    var seCierra = false;
                                    //Marcamos como revisada la incidencia cuando no lo esté
                                    if (!isCreacion) {
                                        if (!isRevisada) {
                                            incidenciaSel.idUsuarioRevisor = USER.idUsuario;
                                            incidenciaSel.isRevisada = true;

                                            isUserRevisor = true;
                                        }

                                        var hayAccionesPendientes =
                                            $.grep(incidenciaSel.tblAccionesCorrectivas, function (accion) {
                                                return accion.estado === false;
                                            }).length > 0;

                                        if (isUserRevisor && !isCliente) {
                                            if (
                                                incidenciaSel.isInformativa ||
                                                (!incidenciaSel.isInformativa && !hayAccionesPendientes && !isSat)
                                            ) {
                                                if (!incidenciaSel.estado) {
                                                    incidenciaSel.estado = true; //Cerrada
                                                    incidenciaSel.fechaCierre = convertClienteUtcToLavanderiaUtc(
                                                        LAVANDERIA.GMT,
                                                        new Date()
                                                    );
                                                    seCierra = true;
                                                }
                                            } else {
                                                incidenciaSel.estado = false; //Abierta
                                                incidenciaSel.fechaCierre = null;
                                            }
                                        } else if (isCliente) {
                                            if (incidenciaSel.estado == true && incidenciaSel.fechaCierre == null) {
                                                incidenciaSel.fechaCierre = convertClienteUtcToLavanderiaUtc(
                                                    LAVANDERIA.GMT,
                                                    new Date()
                                                );
                                                seCierra = true;
                                            } else {
                                                incidenciaSel.fechaCierre = null;
                                            }
                                        }
                                    }

                                    if (seCierra) {
                                        dxMensajePregunta(
                                            incidenciaSel.isInformativa
                                                ? getTrad("preg_CerrarIncidencia_Informativa")
                                                : getTrad("preg_CerrarIncidencia_Correctiva"),
                                            [
                                                [
                                                    getTrad("aceptar"),
                                                    function () {
                                                        envioDatos();
                                                    },
                                                    "danger",
                                                ],
                                                [
                                                    getTrad("cancelar"),
                                                    function () {
                                                        incidenciaSel.estado = false;
                                                        incidenciaSel.fechaCierre = null;
                                                        seCierra = false;
                                                    },
                                                ],
                                            ]
                                        );
                                    } else {
                                        envioDatos();
                                    }
                                }
                            },
                        },
                    },
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: getTrad("cancelar"),
                            onClick: function () {
                                $("#dxPopup_incidencia").dxPopup("instance").hide();
                            },
                        },
                    },
                ],
                onShowing: function (e) {
                    var title = (isCreacion ? getTrad("nuevaIncidencia: ") : "") + tipoIncidenciaSel.text.toUpperCase();
                    $("#dxPopup_incidencia_title").text(title);

                    var form = $("#dxForm_incidencia").dxForm("instance");
                    form.beginUpdate();

                    //Adaptamos Popup y Form al contenido
                    var twoColumns = !isCreacion || (isCreacion && (isSat || isCliente || isRRHH || isTransporte));
                    e.component.option("width", twoColumns ? "80%" : "600px");

                    //form.option("disabled", isCerrada && !isUserRevisor ? true : false);
                    form.option("colCount", twoColumns ? 2 : 1);

                    //Deshabilitar cuando está cerrada
                    form.itemOption("General.idSubTipoIncidencia", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.descripcionIncidencia", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.fechaIncidencia", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.tblUsuarioRevisor_nombre", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.tblUsuarioCrea_nombre", "disabled", isCerrada && !isUserRevisor);

                    form.itemOption("General.idVehiculo_modificacion", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.idMaquina_modificacion", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.idEntidad_modificacion", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.idCompañia_modificacion", "disabled", isCerrada && !isUserRevisor);
                    form.itemOption("General.idUsuarioAfecta_modificacion", "disabled", isCerrada && !isUserRevisor);

                    form.itemOption("Revision", "disabled", isCerrada && !isUserRevisor);

                    //CREAR
                    form.itemOption("idMaquina_creacion", "visible", isSat && isCreacion);
                    form.itemOption("idCompañia_idEntidad_creacion", "visible", isCliente && isCreacion);

                    form.itemOption("General.tblUsuarioRevisor_nombre", "visible", !isCliente);
                    form.itemOption("General.tblUsuarioRevisor_nombre_empty", "visible", isCliente);

                    form.itemOption("idUsuarioAfecta_creacion", "visible", isRRHH && isCreacion);
                    form.itemOption("idVehiculo_creacion", "visible", isTransporte && isCreacion);

                    //MODIFICAR
                    form.itemOption("Revision", "visible", !isCreacion);
                    form.itemOption("Revision.estado", "visible", !isCreacion && isCliente);
                    form.itemOption("Revision.isInformativa", "visible", !isCreacion);
                    form.itemOption("Revision.idUsuarioResponsable", "visible", !isCreacion);
                    form.itemOption("Revision.tblAccionesCorrectivas", "visible", !isCreacion);
                    form.itemOption("Revision.idPoliticaDisciplinaria", "visible", isRRHH && !isCreacion);
                    form.endUpdate();

                    //MODIFICAR
                    form.itemOption("General.idVehiculo_modificacion", "visible", isTransporte && !isCreacion);
                    form.itemOption("General.idMaquina_modificacion", "visible", isSat && !isCreacion);
                    form.itemOption("General.idEntidad_modificacion", "visible", isCliente && !isCreacion);
                    form.itemOption("General.idCompañia_modificacion", "visible", isCliente && !isCreacion);
                    form.itemOption("General.idUsuarioAfecta_modificacion", "visible", isRRHH && !isCreacion);
                    form.repaint();
                },
                onHidden: function (e) {
                    var dxForm_incidencia = $("#dxForm_incidencia").dxForm("instance");

                    dxForm_incidencia.beginUpdate();

                    crearIncidencia();
                    dxForm_incidencia.updateData(incidenciaSel);

                    dxForm_incidencia.endUpdate();
                },
            });

            $("#Incidencias #dxPopup_notificar").dxPopup({
                width: "800px",
                height: "450px",
                deferRendering: false,
                title: getTrad("notificacionIncidencias"),
                contentTemplate: function (contentElement) {
                    contentElement.append(
                        $("<div id='dxDataGrid_tblCorreosNLav' />").dxDataGrid({
                            //Propiedades
                            width: "100%",
                            height: "100%",
                            editing: {
                                mode: "cell",
                                allowUpdating: true,
                                allowAdding: true,
                                allowDeleting: false,
                            },
                            scrolling: {
                                showScrollbar: "always",
                            },
                            columns: [
                                {
                                    dataField: "orden",
                                    visible: false,
                                    sortOrder: "desc",
                                },
                                {
                                    dataField: "denominacion",
                                    caption: getTrad("email"),
                                    showEditorAlways: true,
                                    validationRules: [
                                        {
                                            type: "pattern",
                                            pattern:
                                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: getTrad("validation_Formato"),
                                        },
                                        { type: "required" },
                                        {
                                            type: "custom",
                                            reevaluate: true,
                                            validationCallback: function validationCallback(options) {
                                                var correo = options.value;

                                                var rows = $("#dxDataGrid_tblCorreosNLav")
                                                    .dxDataGrid("instance")
                                                    .getVisibleRows();

                                                var existe =
                                                    $.grep(rows, function (row) {
                                                        return (
                                                            row.data.denominacion.toLowerCase() === correo.toLowerCase()
                                                        );
                                                    }).length > 1;

                                                return !existe;
                                            },
                                            message: getTrad("alert_correoDuplicado"),
                                        },
                                    ],
                                },
                                {
                                    caption: " ",
                                    width: "50px",
                                    alignment: "center",
                                    cssClass: "p-0",
                                    allowEditing: false,
                                    allowHeaderFiltering: false,
                                    allowUpdating: false,
                                    cellTemplate: function (cellElement, cellInfo) {
                                        cellElement.on("click", function () {
                                            cellInfo.component.deleteRow(cellInfo.rowIndex);
                                        });

                                        cellElement.append(
                                            $("<div/>").addClass("container_spanCentrado").append(
                                                svg_iconos.svg_remove
                                                    .clone()

                                                    .css("height", "25px")
                                                    .css("width", "25px")
                                                    .css("cursor", "pointer")
                                            )
                                        );
                                    },
                                },
                            ],
                            //EVENTOS
                            onToolbarPreparing: function (e) {
                                $.each(e.toolbarOptions.items, function (index, item) {
                                    switch (item.name) {
                                        case "saveButton":
                                            item.visible = false;
                                            break;
                                        case "addRowButton":
                                            item.location = "after";
                                            item.showText = "always";
                                            item.options.text = getTrad("añadirCorreo");
                                            item.options.icon = "plus";
                                            break;
                                        default:
                                            break;
                                    }
                                });

                                e.toolbarOptions.items.unshift({
                                    location: "before",
                                    template: function () {
                                        return $("<div />")
                                            .text(getTrad("msg_notificarIncidencia"))
                                            .css("font-size", "15px");
                                    },
                                });
                            },
                            onInitNewRow: function (e) {
                                e.data.orden = e.component.option("dataSource").length + 1;
                            },
                            //Estilos
                            showColumnLines: true,
                            showRowLines: true,
                        })
                    );
                },
                toolbarItems: [
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            type: "success",
                            icon: "check",
                            text: getTrad("notificar"),
                            onClick: function () {
                                var correosGrid = $("#dxDataGrid_tblCorreosNLav")
                                    .dxDataGrid("instance")
                                    .option("dataSource");
                                if (correosGrid.length > 0) {
                                    var arrayCorreos = [];
                                    $.each(correosGrid, function (index, correo) {
                                        arrayCorreos.push(correo.denominacion);
                                    });

                                    var correos = arrayCorreos.toString();
                                    ODataContext_tblIncidencia.invoke(
                                        "Notificar",
                                        {
                                            idIncidencia: incidenciaSel.idIncidencia,
                                            correos: correos,
                                            isCreacion: true,
                                        },
                                        "GET"
                                    );
                                    $("#dxPopup_notificar").dxPopup("instance").hide();
                                    notify({
                                        message: getTrad("aviso_C_NotificacionEnviada"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                } else {
                                    notify({
                                        message: getTrad("aviso_E_SeleccioneCorreo"),
                                        type: "error",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                }
                            },
                        },
                    },
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: getTrad("noNotificar"),
                            onClick: function () {
                                $("#dxPopup_notificar").dxPopup("instance").hide();
                            },
                        },
                    },
                ],
                onHidden: function () {
                    $("#dxDataGrid_tblCorreosNLav").dxDataGrid("instance").cancelEditData();
                },
            });

            if (LAVANDERIAS.length > 1) {
                $(".backButton").on("click", function () {
                    var dxForm_incidencia = $("#dxForm_incidencia").dxForm("instance");
                    if (dxForm_incidencia) {
                        dxForm_incidencia.dispose();
                    }

                    COMPONENT.setState({ showSelector: true });
                });

                tooltipControl_creacion($(".backButton"), getTrad("volverLavanderia"));
            }
        })
        .then(function () {
            deferred.resolve();
        });

    function crearIncidencia() {
        incidenciaSel.idIncidencia = null;
        incidenciaSel.fechaIncidencia = null;
        incidenciaSel.descripcionIncidencia = "";
        incidenciaSel.idUsuarioCrea = USER.idUsuario;
        incidenciaSel.idUsuarioRevisor = null;
        incidenciaSel.idLavanderia = LAVANDERIA.idLavanderia;
        incidenciaSel.tblUsuarioCrea.nombre = USER.nombre;
        incidenciaSel.tblUsuarioRevisor.nombre = null;
        incidenciaSel.idSubTipoIncidencia = null;
        incidenciaSel.isRevisada = false;
        incidenciaSel.isInformativa = true;
        incidenciaSel.tblIncidencia_Documento.length = 0;
        incidenciaSel.estado = false;
        incidenciaSel.fechaCierre = null;
        //Modificación
        incidenciaSel.idUsuarioResponsable = null;
        incidenciaSel.tblAccionesCorrectivas.length = 0;
        //SAT
        incidenciaSel.idMaquina = null;
        incidenciaSel.estadoMaquina = null;
        //RRHH
        incidenciaSel.idUsuarioAfecta = null;
        incidenciaSel.idPoliticaDisciplinaria = null;
        //CLIENTE
        incidenciaSel.idEntidad = null;
        incidenciaSel.idCompañia = null;
        incidenciaSel.asunto = "";

        //TRANSPORTE
        incidenciaSel.idVehiculo = null;

        incidenciaSel_bak = $.extend(true, {}, incidenciaSel);
    }

    function cargaIncidencia(objInci) {
        incidenciaSel.idIncidencia = objInci.idIncidencia;
        incidenciaSel.fechaIncidencia = objInci.fechaIncidencia;
        incidenciaSel.descripcionIncidencia = objInci.descripcionIncidencia;
        incidenciaSel.idUsuarioCrea = objInci.idUsuarioCrea;
        incidenciaSel.idUsuarioRevisor = objInci.idUsuarioRevisor;
        incidenciaSel.idLavanderia = objInci.idLavanderia;
        incidenciaSel.idSubTipoIncidencia = objInci.idSubTipoIncidencia;
        incidenciaSel.isRevisada = objInci.isRevisada;
        incidenciaSel.isInformativa = objInci.isInformativa;
        incidenciaSel.tblUsuarioCrea.nombre = objInci.tblUsuarioCrea ? objInci.tblUsuarioCrea.nombre : "";
        incidenciaSel.tblUsuarioRevisor.nombre = objInci.tblUsuarioRevisor ? objInci.tblUsuarioRevisor.nombre : "";
        incidenciaSel.estado = objInci.estado;
        incidenciaSel.fechaCierre = objInci.fechaCierre;

        incidenciaSel.tblIncidencia_Documento.length = 0;

        incidenciaSel.tblAccionesCorrectivas.length = 0;
        $.merge(incidenciaSel.tblAccionesCorrectivas, objInci.tblAccionesCorrectivas);
        //Modificación
        incidenciaSel.idUsuarioResponsable = objInci.idUsuarioResponsable;
        //SAT
        incidenciaSel.idMaquina = objInci.idMaquina;
        incidenciaSel.estadoMaquina = objInci.estadoMaquina;
        //RRHH
        incidenciaSel.idUsuarioAfecta = objInci.idUsuarioAfecta;
        incidenciaSel.idPoliticaDisciplinaria = objInci.idPoliticaDisciplinaria;
        //CLIENTE
        incidenciaSel.idEntidad = objInci.idEntidad;
        incidenciaSel.idCompañia = objInci.idCompañia;
        incidenciaSel.asunto = objInci.asunto;

        //TRANSPORTE
        incidenciaSel.idVehiculo = objInci.idVehiculo;

        incidenciaSel_bak = $.extend(true, {}, incidenciaSel);
    }

    function config_dxDataGrid_tipoIncidencia() {
        var dxDataGrid_tblIncidencia = $("#Incidencias #dxDataGrid_tblIncidencia").dxDataGrid("instance");
        isSat = tipoIncidenciaSel.idTipoIncidencia === 3;
        isRRHH = tipoIncidenciaSel.idTipoIncidencia === 4;
        isCliente = tipoIncidenciaSel.idTipoIncidencia === 5;
        isTransporte = tipoIncidenciaSel.idTipoIncidencia === 2;

        dxDataGrid_tblIncidencia.beginUpdate();

        dxDataGrid_tblIncidencia.option(
            "export.fileName",
            tipoIncidenciaSel.text +
                " (" +
                formatDate(convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date())) +
                ")"
        );

        dxDataGrid_tblIncidencia.columnOption("idMaquina", "visible", isSat);
        dxDataGrid_tblIncidencia.columnOption("idUsuarioAfecta", "visible", isRRHH);
        dxDataGrid_tblIncidencia.columnOption("idEntidad", "visible", isCliente);
        dxDataGrid_tblIncidencia.columnOption("idCompañia", "visible", isCliente);
        dxDataGrid_tblIncidencia.columnOption("asunto", "visible", isCliente);
        dxDataGrid_tblIncidencia.columnOption("tblPersona_usuarioAfecta", "visible", !isCliente);

        dxDataGrid_tblIncidencia.columnOption("idVehiculo", "visible", isTransporte);

        var subTiposFiltrados = $.grep(datasource_tblTipoSubIncidencia.items(), function (tipoSub) {
            return tipoSub.idTipoIncidencia === tipoIncidenciaSel.idTipoIncidencia;
        });
        dxDataGrid_tblIncidencia.columnOption("idSubTipoIncidencia", "lookup.dataSource", subTiposFiltrados);

        dxDataGrid_tblIncidencia.clearFilter();

        dxDataGrid_tblIncidencia.endUpdate();
    }

    function dxForm_customCheck(dataField) {
        var isValid_idMaquina = true;
        var isValid_idEntidad_idCompañia = true;
        var isValid_idUsuarioAfecta = true;
        var isValid_idVehiculo = true;
        var isValid_accionesCorrectivas = true;

        if (isSat && isCreacion && (!dataField || dataField === "idMaquina")) {
            isValid_idMaquina = incidenciaSel.idMaquina;
        }

        if (isCliente && isCreacion && (!dataField || dataField === "idEntidad" || dataField === "idCompañia")) {
            isValid_idEntidad_idCompañia = incidenciaSel.idEntidad || incidenciaSel.idCompañia;
        }

        if (isRRHH && isCreacion && (!dataField || dataField === "idUsuarioAfecta")) {
            isValid_idUsuarioAfecta = incidenciaSel.idUsuarioAfecta;
        }

        if (isTransporte && isCreacion && (!dataField || dataField === "idVehiculo")) {
            isValid_idVehiculo = incidenciaSel.idVehiculo;
        }

        if (
            !isCreacion &&
            !incidenciaSel.isInformativa &&
            !isSat &&
            (!dataField || dataField === "tblAccionesCorrectivas")
        ) {
            isValid_accionesCorrectivas = incidenciaSel.tblAccionesCorrectivas.length > 0;
        }

        $("#warning_idMaquina").css("display", !isValid_idMaquina ? "block" : "none");
        $("#warning_idEntidad").css("display", !isValid_idEntidad_idCompañia ? "block" : "none");
        $("#warning_idCompañia").css("display", !isValid_idEntidad_idCompañia ? "block" : "none");
        $("#warning_idUsuarioAfecta").css("display", !isValid_idUsuarioAfecta ? "block" : "none");
        $("#warning_idVehiculo").css("display", !isValid_idVehiculo ? "block" : "none");
        $("#warning_accionesCorrectivas").css("display", !isValid_accionesCorrectivas ? "block" : "none");

        return (
            isValid_idMaquina &&
            isValid_idEntidad_idCompañia &&
            isValid_idUsuarioAfecta &&
            isValid_idVehiculo &&
            isValid_accionesCorrectivas
        );
    }

    function tratarDatos(obj) {
        delete obj.tblEntidad;
        delete obj.tblUsuarioCrea;
        delete obj.tblUsuarioRevisor;
        delete obj.tblMaquina;
        delete obj.tblPersona_usuarioAfecta;
        delete obj.tblPersona_responsable;
        delete obj.tblTipoSubIncidencia;
        delete obj.tblVehiculo;

        $.each(obj.tblAccionesCorrectivas, function (index, accionCorrectiva) {
            delete accionCorrectiva.idAccionCorrectiva;
            accionCorrectiva.denominacion = accionCorrectiva.denominacion.toUpperCase();
        });

        $.each(obj.tblIncidencia_Documento, function (index, doc) {
            delete doc.idDocumento;
        });

        obj.descripcionIncidencia = obj.descripcionIncidencia.toUpperCase();

        return obj;
    }
    return deferred.promise();
}
