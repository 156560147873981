import React from "react";

import { addDays, authHeader, errorHandler, formatDate_noTime_parameter, getTrad, patchRequestHandler } from "helpers";

import DropDownBox_filtroCategoriasInternas from "components/DropDownBox_filtroCategoriasInternas";
import SelectorDiasLibres from "components/SelectorDiasLibres/SelectorDiasLibres";

import validationEngine from "devextreme/ui/validation_engine";
import { Button, DateBox, ScrollView, SelectBox, TextBox } from "devextreme-react";
import Form, { Item as ItemForm } from "devextreme-react/form";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import Validator, { CustomRule, RequiredRule } from "devextreme-react/validator";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";

class LlamamientoPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dxPopup_isVisible: false,
            llamamientoSel: {},
            llamamientoData: {},
            isModificacion: false,
        };
    }

    formLabels = {
        turno: { text: getTrad("turno") },
        lavanderia: { text: getTrad("lavanderia") },
        tipoTrabajo: { text: getTrad("tipoTrabajo") },
        categoriaInterna: { text: getTrad("categoriaInterna") },
        fechaIni: { text: getTrad("fechaInicio") },
        formatoDiasLibres: { text: "Formato días libres" },
        diasLibres: { text: getTrad("diasLibres") },
        idTipoContrato: { text: getTrad("tipoContrato") },
    };
    formValidation = "formLlamamiento";
    formValidator = (
        <Validator validationGroup={this.formValidation}>
            <RequiredRule />
        </Validator>
    );

    // #region TRADUCCIONES

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    // #region DataSources

    datasource_tblTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoContrato",
            key: "idTipoContrato",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            version: 4,
        }),
        sort: ["denominacion"],
        filter: ["idTipoContrato in (1,2,4)"],
    });

    // #endregion

    open = (idLlamamiento) => {
        const { lavanderia, CuadranteContext } = this.props;

        let llamamiento;
        if (idLlamamiento) {
            llamamiento = CuadranteContext.tblLlamamiento.find((x) => x.idLlamamiento === idLlamamiento);
        }

        this.setState({
            dxPopup_isVisible: true,
            isModificacion: llamamiento != null,
            llamamientoSel: { ...llamamiento },
            llamamientoData: llamamiento ? {} : { idLavanderia: lavanderia.idLavanderia },
        });
    };

    render() {
        const { dxPopup_isVisible, isModificacion } = this.state;

        return (
            <>
                <Popup
                    visible={dxPopup_isVisible}
                    title={getTrad("incorporaciones")}
                    width={"60rem"}
                    height={"35rem"}
                    dragEnabled={false}
                    onShowing={this.dxPopup_onShowing}
                    onHiding={this.dxPopup_onHiding}
                    onHidden={this.dxPopup_onHidden}
                >
                    <Form colCount={6} labelLocation="top" validationGroup={this.formValidation}>
                        <ItemForm
                            dataField={"idLavanderia"}
                            label={this.formLabels.lavanderia}
                            colSpan={2}
                            render={this.dxItemForm_render_lavanderia}
                            isRequired
                        />
                        <ItemForm
                            dataField={"idTurno"}
                            label={this.formLabels.turno}
                            colSpan={2}
                            render={this.dxItemForm_render_turno}
                            isRequired
                        />
                        <ItemForm
                            dataField={"idTipoTrabajo"}
                            label={this.formLabels.tipoTrabajo}
                            colSpan={2}
                            render={this.dxItemForm_render_tipoTrabajo}
                            isRequired
                        />
                        <ItemForm
                            dataField={"fechaIni"}
                            label={this.formLabels.fechaIni}
                            colSpan={2}
                            render={this.dxItemForm_render_fechaIni}
                            isRequired
                        />
                        <ItemForm
                            dataField={"idTipoContrato"}
                            label={this.formLabels.idTipoContrato}
                            colSpan={2}
                            render={this.dxItemForm_render_tipoContrato}
                            isRequired
                        />
                        <ItemForm
                            dataField={"idFormatoDiasLibres"}
                            label={this.formLabels.formatoDiasLibres}
                            colSpan={2}
                            render={this.dxItemForm_render_formatoDiasLibres}
                            isRequired
                        />
                        <ItemForm
                            dataField={"idCategoriaInterna"}
                            label={this.formLabels.categoriaInterna}
                            colSpan={4}
                            render={this.dxItemForm_render_categoriaInterna}
                            isRequired
                        />
                        <ItemForm
                            dataField={"tblDiasLibresPersonal_Llamamiento"}
                            label={this.formLabels.diasLibres}
                            colSpan={2}
                            isRequired
                            render={this.dxItemForm_render_diasLibres}
                        />
                    </Form>

                    <ToolbarItem toolbar="bottom" location="after">
                        <Button
                            text={isModificacion ? getTrad("editarIncorporacion") : getTrad("crearIncorporacion")}
                            type="success"
                            onClick={this.dxButton_onClick}
                        />
                    </ToolbarItem>
                </Popup>
            </>
        );
    }

    // #region Popup

    dxPopup_onShowing = () => {
        if (this.state.isModificacion) validationEngine.validateGroup(this.formValidation);
    };

    dxPopup_onHiding = () => {
        this.setState({ dxPopup_isVisible: false });
    };

    dxPopup_onHidden = () => {
        if (!this.state.isModificacion) validationEngine.resetGroup(this.formValidation);
        this.setState({ llamamientoData: {}, llamamientoSel: {} });
    };

    dxButton_onClick = () => {
        const { isModificacion } = this.state;
        const validation = validationEngine.validateGroup(this.formValidation);

        if (validation.isValid) {
            const { llamamientoData, llamamientoSel } = this.state;
            const { CuadranteContext } = this.props;

            if (!isModificacion) {
                // Crear nuevo llamamiento
                CuadranteContext.getDS_tblLlamamiento()
                    .store()
                    .insert(llamamientoData)
                    .then(() => {
                        CuadranteContext.reload();
                        this.dxPopup_onHiding();
                    });
            }
            if (isModificacion) {
                // Editar llamamiento existente
                CuadranteContext.getDS_tblLlamamiento()
                    .store()
                    .update(llamamientoSel.idLlamamiento, patchRequestHandler(llamamientoData))
                    .then(() => {
                        CuadranteContext.reload();
                        this.dxPopup_onHiding();
                    });
            }
        }
    };

    // #endregion

    // #region Lavanderia

    dxItemForm_render_lavanderia = () => {
        const { lavanderia } = this.props;
        return <TextBox value={lavanderia.denominacion} readOnly />;
    };

    // #endregion

    // #region Turno

    dxItemForm_render_turno = () => {
        const { CuadranteContext } = this.props;
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <SelectBox
                value={llamamientoData.idTurno ?? llamamientoSel.idTurno}
                defaultValue={llamamientoSel.idTurno}
                valueExpr={"idTurno"}
                stylingMode={"underlined"}
                displayExpr={"denominacion"}
                items={CuadranteContext.tblTurno}
                onSelectionChanged={this.dxSelectBox_turno_onSelectionChanged}
            >
                {this.formValidator}
            </SelectBox>
        );
    };

    dxSelectBox_turno_onSelectionChanged = (e) => {
        let { llamamientoData } = this.state;

        llamamientoData.idTurno = e.selectedItem?.idTurno;

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Tipo trabajo

    dxItemForm_render_tipoTrabajo = () => {
        const { CuadranteContext } = this.props;
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <SelectBox
                value={llamamientoData.idTipoTrabajo ?? llamamientoSel.idTipoTrabajo}
                valueExpr={"idTipoTrabajo"}
                stylingMode={"underlined"}
                displayExpr={"denominacion"}
                items={CuadranteContext.tblTipoTrabajo}
                onSelectionChanged={this.dxSelectBox_tipoTrabajo_onSelectionChanged}
            >
                {this.formValidator}
            </SelectBox>
        );
    };

    dxSelectBox_tipoTrabajo_onSelectionChanged = (e) => {
        let { llamamientoData } = this.state;

        llamamientoData.idTipoTrabajo = e.selectedItem?.idTipoTrabajo;

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Categoria interna

    dxItemForm_render_categoriaInterna = () => {
        const { idioma, lavanderia } = this.props;
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <DropDownBox_filtroCategoriasInternas
                value={
                    llamamientoData.idCategoriaInterna || llamamientoSel.idCategoriaInterna
                        ? [llamamientoData.idCategoriaInterna ?? llamamientoSel.idCategoriaInterna]
                        : null
                }
                idPaisSel={lavanderia.idPais}
                isOficina_checked={false}
                hasFiltroConvenio={true}
                idioma={idioma}
                multiSeleccion={false}
                stylingMode={"filled"}
                placeholder={this.getTrad("seleccionar")}
                onValueChanged={this.DropDownBox_filtroCategoriasInternas_onValueChanged}
                customValidation={this.formValidator}
            />
        );
    };

    DropDownBox_filtroCategoriasInternas_onValueChanged = (value) => {
        let { llamamientoData } = this.state;

        llamamientoData.idCategoriaInterna = value[0];

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Fecha inicio

    dxItemForm_render_fechaIni = () => {
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <DateBox
                value={llamamientoData.fechaIni ?? llamamientoSel.fechaIni}
                stylingMode={"underlined"}
                displayFormat={"dd/MM/yyyy"}
                openOnFieldClick={true}
                acceptCustomValue={false}
                min={addDays(new Date(), 1)}
                onValueChanged={this.dxDateBox_fechaIni_onValueChanged}
            >
                {this.formValidator}
            </DateBox>
        );
    };

    dxDateBox_fechaIni_onValueChanged = (e) => {
        let { llamamientoData } = this.state;

        llamamientoData.fechaIni = e.value
            ? typeof e.value === "string"
                ? e.value
                : formatDate_noTime_parameter(e.value)
            : null;

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Tipo contrato

    dxItemForm_render_tipoContrato = () => {
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <SelectBox
                value={llamamientoData.idTipoContrato ?? llamamientoSel.idTipoContrato}
                valueExpr={"idTipoContrato"}
                stylingMode={"underlined"}
                displayExpr={"denominacion"}
                dataSource={this.datasource_tblTipoContrato}
                onSelectionChanged={this.dxSelectBox_tipoContrato_onSelectionChanged}
            >
                {this.formValidator}
            </SelectBox>
        );
    };

    dxSelectBox_tipoContrato_onSelectionChanged = (e) => {
        let { llamamientoData } = this.state;

        llamamientoData.idTipoContrato = e.selectedItem?.idTipoContrato;

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Formato dias libres

    dxItemForm_render_formatoDiasLibres = () => {
        const { CuadranteContext } = this.props;
        const { llamamientoData, llamamientoSel } = this.state;
        return (
            <SelectBox
                value={llamamientoData.idFormatoDiasLibres ?? llamamientoSel.idFormatoDiasLibres}
                valueExpr={"idFormatoDiasLibres"}
                stylingMode={"underlined"}
                displayExpr={"denominacion"}
                items={CuadranteContext.tblFormatoDiasLibres}
                onSelectionChanged={this.dxSelectBox_formatoDiasLibres_onSelectionChanged}
            >
                {this.formValidator}
            </SelectBox>
        );
    };

    dxSelectBox_formatoDiasLibres_onSelectionChanged = (e) => {
        let { llamamientoData } = this.state;

        llamamientoData.idFormatoDiasLibres = e.selectedItem?.idFormatoDiasLibres;

        this.setState({ llamamientoData });
    };

    // #endregion

    // #region Dias libres

    dxItemForm_render_diasLibres = () => {
        const { CuadranteContext } = this.props;
        const { llamamientoData, llamamientoSel } = this.state;
        const formato = CuadranteContext.tblFormatoDiasLibres?.find(
            (x) =>
                x.idFormatoDiasLibres ===
                (llamamientoData.idFormatoDiasLibres
                    ? llamamientoData.idFormatoDiasLibres
                    : llamamientoSel.idFormatoDiasLibres)
        );
        return (
            <div className="position-relative w-100">
                <div className="position-absolute w-100" style={{ height: 150 }}>
                    <ScrollView className="he-100">
                        <SelectorDiasLibres
                            stylingMode="underlined"
                            isLlamamiento={true}
                            formato={formato ?? {}}
                            persona={
                                llamamientoData.tblDiasLibresPersonal_Llamamiento ? llamamientoData : llamamientoSel
                            }
                            isLabelsVisible={true}
                            onValueChanged={this.SelectorDiasLibres_onValueChanged}
                            customValidation={
                                <Validator validationGroup={this.formValidation}>
                                    <RequiredRule />
                                    <CustomRule
                                        validationCallback={this.SelectorDiasLibres_customValidation_CustomRule}
                                        reevaluate={true}
                                        message={"Valor repetido"}
                                    />
                                </Validator>
                            }
                        />
                    </ScrollView>
                </div>
            </div>
        );
    };

    SelectorDiasLibres_onValueChanged = (tblDiasLibresPersonal) => {
        let { llamamientoData } = this.state;

        let tblDiasLibresPersonal_Llamamiento = tblDiasLibresPersonal.map((x) => {
            return {
                idDiaSemana: x.idDiaSemana,
                idDiaMes: x.idDiaMes,
                numDia: x.numDia,
            };
        });

        llamamientoData.tblDiasLibresPersonal_Llamamiento = tblDiasLibresPersonal_Llamamiento;

        this.setState({ llamamientoData });
    };

    // Todo: realizar validación al cambiar valor
    SelectorDiasLibres_customValidation_CustomRule = ({ value, ...a }) => {
        const { llamamientoData, llamamientoSel } = this.state;
        let data = llamamientoData.tblDiasLibresPersonal_Llamamiento ? llamamientoData : llamamientoSel;
        return (
            !data.tblDiasLibresPersonal_Llamamiento ||
            data.idFormatoDiasLibres === 2 ||
            data.tblDiasLibresPersonal_Llamamiento?.filter((x) => x.idDiaSemana === value || x.idDiaMes === value)
                .length <= 1
        );
    };

    // #endregion
}

export default LlamamientoPopup;
