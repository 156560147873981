import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  startOfMonth,
  endOfMonth,
  filtroTiempo_Resize,
  create_rangeSelector,
  tooltipControl_creacion,
  dxRangeSelector_PosicionValor_nuevoMarker,
  dxMensajePregunta,
} from "../../../../helpers";
import { svg_warning } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var fechaDesde = startOfMonth(new Date()),
    fechaHasta = endOfMonth(new Date()),
    is_unidades = true,
    dataSourceItems_enMemoria = [],
    entidadSel = null;

  //#endregion

  //#region DATASOURCE
  var datasource_Pedidos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPedido",
      key: "idPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      onLoading: function (loadOptions) {
        loadOptions.filter = [
          "(idEntidad eq " +
          entidadSel.idEntidad +
          ") and idTipoProduccion eq null and (date(fecha) ge " +
          formatDate_parameter(fechaDesde).split("T")[0] +
          " and date(fecha) le " +
          formatDate_parameter(fechaHasta).split("T")[0] +
          ")",
        ];
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: [
      "idPedido,codigo,fecha,idEstadoPedido,idTipoPedido,idEntidad, isCerrado",
    ],
  });

  var datasource_PrendasNPedido = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infBalancePedidosRepartos_spSelectPrendasNPedido",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDataGrid_pedidos = $("#dxDataGrid_pedidos").dxDataGrid(
          "instance",
        ),
          idPedidoSel =
            dxDataGrid_pedidos.getSelectedRowKeys().length > 0
              ? dxDataGrid_pedidos.getSelectedRowKeys()[0].idPedido
              : -1;

        request.params.idPedido = idPedidoSel;
      },
      onLoaded: function (data) {
        dataSourceItems_enMemoria["prendas"] = data;
      },
      version: 4,
    }),
  });

  var datasource_PrendasNPedido_agrupado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infBalancePedidosRepartos_spSelectPrendasNPedido_agrupado",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idEntidad = entidadSel.idEntidad;
      },
      version: 4,
    }),
  });

  var datasource_Pedidos_agrupado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infBalancePedidosRepartos_spSelectPedidos_agrupado",
      key: "idPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDataGrid_prendaNPedido_agrupados = $(
          "#dxDataGrid_prendaNPedido_agrupados",
        ).dxDataGrid("instance"),
          prendaSel =
            dxDataGrid_prendaNPedido_agrupados.getSelectedRowKeys().length > 0
              ? dxDataGrid_prendaNPedido_agrupados.getSelectedRowKeys()[0]
              : null;

        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idEntidad = entidadSel.idEntidad;
        request.params.idPrenda = prendaSel?.idPrenda ?? -1;
        request.params.peso = prendaSel?.peso;
      },
      onLoaded: function (data) {
        dataSourceItems_enMemoria["pedidos_agrupado"] = data;
      },
      version: 4,
    }),
  });

  var datasource_filtroTiempo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infBalancePedidosRepartos_filtroTiempo",
      key: "idFiltroTiempo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = entidadSel.idEntidad;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var item = data[0];
      var dataSource = [];
      dataSource.push({ arg: item.fechaMin, value: item.fechaMin });
      dataSource.push({ arg: item.fechaMax, value: item.fechaMax });
      return dataSource;
    },
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion", "idCompañia"],
  });

  var datasource_estadoPedido = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEstadoPedido",
      key: "idEstadoPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idEstadoPedido", "denominacion"],
  });

  var datasource_tipoPedido = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoPedido",
      key: "idTipoPedido",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = entidadSel ? entidadSel.idEntidad : null;
      },
      version: 4,
    }),
  });

  var datasource_familias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFamilia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idFamilia", "denominacion", "abreviatura"],
    postProcess: function (data) {
      if (datasource_familias.items().length === 0) {
        data.splice(0, 0, {
          idFamilia: 0,
          denominacion: getTrad("todas").toUpperCase(),
          abreviatura: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
    sort: "idFamilia",
  });

  var enum_tipoTabla_agrupacion = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("pedidos").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("agrupado").toUpperCase(),
    },
  ];

  var enum_tipoTabla_unidadesPeso = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("unidades").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("peso").toUpperCase(),
    },
  ];

  //#endregion

  $("#dxForm_opciones").dxForm({
    labelLocation: "top",
    height: "100%",
    scrollingEnabled: true,
    colCount: 1,
    disabled: true,
    items: [
      {
        label: { text: getTrad("tipoPedido") },
        editorType: "dxDropDownBox",
        editorOptions: {
          width: "100%",
          deferRendering: false,
          displayExpr: "denominacion",
          valueExpr: "idTipoPedido",
          placeholder: getTrad("todos").toUpperCase(),
          showClearButton: true,
          dataSource: datasource_tipoPedido,
          contentTemplate: function (e) {
            return $("<div id='dxList_tipoPedido' />").dxList({
              height: "100%",
              dataSource: e.component.option("dataSource"),
              selectionMode: "multiple",
              showSelectionControls: true,
              displayExpr: "denominacion",
              keyExpr: "idTipoPedido",
              onSelectionChanged: function (arg) {
                e.component.option(
                  "value",
                  arg.component.option("selectedItemKeys"),
                );
                asignacion_dataSources();
              },
              itemTemplate: function (data, index) {
                return $("<div>").text(data.denominacion);
              },
            });
          },
          onValueChanged: function (e) {
            // CLEAR BUTTON
            if (!e.value) {
              $("#dxList_tipoPedido").dxList("instance").unselectAll();
              asignacion_dataSources();
            }
          },
        },
      },
      {
        label: { text: getTrad("familias") },
        editorType: "dxRadioGroup",
        editorOptions: {
          elementAttr: {
            id: "dxRadioGroup_familias",
            class: "dxRadioGroup_opciones",
          },
          dataSource: datasource_familias,
          valueExpr: "idFamilia",
          displayExpr: "denominacion",
          width: "100%",
          value: 0,
          disabled: true,
          onValueChanged: function (e) {
            asignacion_dataSources();
          },
        },
      },
      {
        label: { text: getTrad("mostrar") },
        editorType: "dxRadioGroup",
        editorOptions: {
          elementAttr: {
            id: "dxRadioGroup_tipoTabla_agrupado",
            class: "dxRadioGroup_opciones",
          },
          dataSource: enum_tipoTabla_agrupacion,
          valueExpr: "idTipoTabla",
          displayExpr: "denominacion",
          layout: "horizontal",
          value: 1,
          height: "100%",
          onValueChanged: function (e) {
            var dxTabPanel_informe = $("#dxTabPanel_informe").dxTabPanel(
              "instance",
            ),
              dxRadioGroup_familias = $("#dxRadioGroup_familias").dxRadioGroup(
                "instance",
              );

            switch (e.value) {
              case 1:
                dxTabPanel_informe.option("selectedIndex", 0);
                dxRadioGroup_familias.option("disabled", true);
                break;
              case 2:
                dxTabPanel_informe.option("selectedIndex", 1);
                dxRadioGroup_familias.option("disabled", false);
                break;
              default:
                break;
            }
          },
        },
      },
      {
        label: { text: getTrad("tipoTabla") },
        editorType: "dxRadioGroup",
        editorOptions: {
          elementAttr: {
            id: "dxRadioGroup_tipoTabla_unidadesPeso",
            class: "dxRadioGroup_opciones",
          },
          dataSource: enum_tipoTabla_unidadesPeso,
          valueExpr: "idTipoTabla",
          displayExpr: "denominacion",
          layout: "horizontal",
          value: 1,
          height: "100%",
          onValueChanged: function (e) {
            is_unidades = e.value === 1;
            asignacion_dataSources();
            $("#dxDataGrid_prendaNPedido")
              .dxDataGrid("instance")
              .option("dataSource", dataSourceItems_enMemoria["prendas"]);
            $("#dxDataGrid_Pedidos_agrupado")
              .dxDataGrid("instance")
              .option(
                "dataSource",
                dataSourceItems_enMemoria["pedidos_agrupado"],
              );
          },
        },
      },
    ],
  });

  $.when(datasource_entidades.load(), datasource_estadoPedido.load())
    .done(function () {
      var is_entidadUnica = false;
      if (datasource_entidades.items().length === 1) {
        entidadSel = datasource_entidades.items()[0];
        is_entidadUnica = true;
      }

      $("#InfBalancePedidosRepartos #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            ratio: 1,
            template: function (itemData, itemIndex, itemElement) {
              itemElement.append(
                $("<div id='dxTabPanel_filtro' />")
                  .addClass("no-header")
                  .dxTabPanel({
                    height: "100%",
                    width: "100%",
                    deferRendering: false,
                    animationEnabled: true,
                    swipeEnabled: false,
                    selectedIndex: is_entidadUnica ? 1 : 0,
                    focusStateEnabled: false,
                    items: [
                      {
                        template: function (e, index, item) {
                          item.append(
                            $("<div id='dxBox_Filtros' />").dxBox({
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  //COMPAÑIAS
                                  ratio: 1,
                                  template: function () {
                                    return $(
                                      "<div style='margin-right:15px;'/>",
                                    ).dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      items: [
                                        {
                                          baseSize: 45,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size'>",
                                            ).text(
                                              "1. " +
                                              getTrad("seleccioneCompañia"),
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='filtro_dxList_compañia'>",
                                            ).dxList({
                                              dataSource: datasource_compañias,
                                              selectionMode: "single",
                                              keyExpr: "idCompañia",
                                              height: "100%",
                                              focusStateEnabled: false,
                                              activeStateEnabled: false,
                                              pageLoadMode: "scrollBottom",
                                              onSelectionChanged: function (e) {
                                                var filtro_dxList_entidad = $(
                                                  "#filtro_dxList_entidad",
                                                ).dxList("instance"),
                                                  dxBox_Filtros =
                                                    $("#dxBox_Filtros").dxBox(
                                                      "instance",
                                                    ),
                                                  idCompañiaSel =
                                                    e.addedItems[0].idCompañia;

                                                if (idCompañiaSel !== 0)
                                                  filtro_dxList_entidad
                                                    .getDataSource()
                                                    .filter(
                                                      "idCompañia",
                                                      "=",
                                                      idCompañiaSel,
                                                    );
                                                //SI ES TODOS
                                                else
                                                  filtro_dxList_entidad
                                                    .getDataSource()
                                                    .filter("");

                                                filtro_dxList_entidad
                                                  .getDataSource()
                                                  .load()
                                                  .done(function () {
                                                    dxBox_Filtros.option(
                                                      "items[1].visible",
                                                      true,
                                                    );
                                                    dxBox_Filtros.repaint();
                                                  });
                                              },
                                              itemTemplate: function (
                                                data,
                                                index,
                                              ) {
                                                return $("<div/>").dxBox({
                                                  direction: "row",
                                                  align: "space-around",
                                                  crossAlign: "stretch",
                                                  height: "100%",
                                                  items: [
                                                    {
                                                      ratio: 1,
                                                      template: function () {
                                                        return $("<div/>").text(
                                                          data.denominacion,
                                                        );
                                                      },
                                                    },
                                                  ],
                                                });
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                                {
                                  //ENTIDADES
                                  ratio: 1,
                                  visible: false,
                                  template: function () {
                                    return $(
                                      "<div id='dxBox_Filtros_entidades' />",
                                    ).dxBox({
                                      direction: "col",
                                      align: "space-around",
                                      crossAlign: "stretch",
                                      height: "100%",
                                      items: [
                                        {
                                          baseSize: 45,
                                          shrink: 0,
                                          ratio: 0,
                                          template: function () {
                                            return $(
                                              "<div class='font-size' style='margin-left:9px;'>",
                                            ).text(
                                              "2. " +
                                              getTrad("seleccioneEntidad"),
                                            );
                                          },
                                        },
                                        {
                                          ratio: 1,
                                          template: function () {
                                            return $(
                                              "<div id='filtro_dxList_entidad'>",
                                            ).dxList({
                                              dataSource:
                                                datasource_entidades.items(),
                                              height: "100%",
                                              keyExpr: "idEntidad",
                                              selectionMode: "single",
                                              focusStateEnabled: false,
                                              activeStateEnabled: false,
                                              pageLoadMode: "scrollBottom",
                                              onSelectionChanged: function (e) {
                                                if (e.addedItems.length > 0) {
                                                  entidadSel = e.addedItems[0];
                                                  datasource_tipoPedido.load();
                                                  $("#dxTabPanel_filtro")
                                                    .dxTabPanel("instance")
                                                    .option("selectedIndex", 1);
                                                }
                                              },
                                              itemTemplate: function (
                                                data,
                                                index,
                                              ) {
                                                return $("<div>").text(
                                                  data.denominacion,
                                                );
                                              },
                                            });
                                          },
                                        },
                                      ],
                                    });
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        template: function (e, index, item) {
                          item.append(
                            $("<div />").dxBox({
                              direction: "col",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  ratio: 1,
                                  template: function (
                                    itemData,
                                    itemIndex,
                                    itemElement,
                                  ) {
                                    itemElement.append(
                                      $("<div id='dxTabPanel_informe' />")
                                        .addClass("no-header")
                                        .dxTabPanel({
                                          height: "100%",
                                          width: "100%",
                                          deferRendering: false,
                                          animationEnabled: false,
                                          swipeEnabled: false,
                                          focusStateEnabled: false,
                                          items: [
                                            {
                                              name: "pedidos",
                                              template: function () {
                                                return $("<div />").dxBox({
                                                  direction: "row",
                                                  crossAlign: "stretch",
                                                  height: "100%",
                                                  items: [
                                                    {
                                                      ratio: 4,
                                                      template: function () {
                                                        return $(
                                                          "<div id='dxDataGrid_pedidos' />",
                                                        ).dxDataGrid({
                                                          //Propiedades
                                                          headerFilter: {
                                                            visible: true,
                                                          },
                                                          export: {
                                                            enabled: true,
                                                            texts: {
                                                              exportAll:
                                                                getTrad(
                                                                  "exportar",
                                                                ),
                                                            },
                                                            fileName:
                                                              "infBalancePedidosRepartos_Pedidos",
                                                          },
                                                          filterRow: {
                                                            visible: true,
                                                            applyFilter: "auto",
                                                            showAllText:
                                                              getTrad("todos"),
                                                          },
                                                          height: "100%",
                                                          columnAutoWidth: true,
                                                          hoverStateEnabled: true,
                                                          selection: {
                                                            mode: "single",
                                                          },
                                                          paging: {
                                                            pageSize: 50,
                                                          },
                                                          pager: {
                                                            showPageSizeSelector: true,
                                                            allowedPageSizes: [
                                                              20, 50, 100,
                                                            ],
                                                            showInfo: true,
                                                          },
                                                          columns: [
                                                            {
                                                              dataField:
                                                                "codigo",
                                                              caption:
                                                                getTrad(
                                                                  "codigo",
                                                                ),
                                                              width: 120,
                                                              allowHeaderFiltering: false,
                                                            },
                                                            {
                                                              dataField:
                                                                "fecha",
                                                              caption:
                                                                getTrad(
                                                                  "fecha",
                                                                ),
                                                              minwidth: 150,
                                                              allowFiltering: false,
                                                              alignment:
                                                                "center",
                                                              dataType: "date",
                                                              format:
                                                                "dd/MM/yyyy",
                                                              sortIndex: 1,
                                                              sortOrder: "asc",
                                                            },
                                                            {
                                                              dataField:
                                                                "idEstadoPedido",
                                                              caption:
                                                                getTrad(
                                                                  "estado",
                                                                ),
                                                              lookup: {
                                                                dataSource:
                                                                  datasource_estadoPedido.items(),
                                                                valueExpr:
                                                                  "idEstadoPedido",
                                                                displayExpr:
                                                                  "denominacion",
                                                              },
                                                              allowHeaderFiltering: true,
                                                              allowFiltering: false,
                                                              width: 120,
                                                              cellTemplate:
                                                                function (
                                                                  cellElement,
                                                                  cellInfo,
                                                                ) {
                                                                  var container =
                                                                    $("<div />")
                                                                      .text(
                                                                        cellInfo.text,
                                                                      )
                                                                      .css(
                                                                        "position",
                                                                        "relative",
                                                                      );
                                                                  if (
                                                                    cellInfo
                                                                      .data
                                                                      .isCerrado
                                                                  ) {
                                                                    container.append(
                                                                      svg_warning
                                                                        .clone()
                                                                        .css(
                                                                          "height",
                                                                          "20px",
                                                                        )
                                                                        .css(
                                                                          "width",
                                                                          "20px",
                                                                        )
                                                                        .css(
                                                                          "cursor",
                                                                          "pointer",
                                                                        )
                                                                        .css(
                                                                          "position",
                                                                          "absolute",
                                                                        )
                                                                        .css(
                                                                          "right",
                                                                          "0px",
                                                                        ),
                                                                    );

                                                                    var msg =
                                                                      getTrad(
                                                                        "msg_pedidoCerrado",
                                                                      );

                                                                    tooltipControl_creacion(
                                                                      container,
                                                                      msg,
                                                                    );
                                                                  }

                                                                  cellElement.append(
                                                                    container,
                                                                  );
                                                                },
                                                            },
                                                          ],
                                                          //Eventos
                                                          onContentReady:
                                                            function (e) {
                                                              e.element
                                                                .find(
                                                                  ".dx-datagrid-export-button",
                                                                )
                                                                .dxButton(
                                                                  "instance",
                                                                )
                                                                .option(
                                                                  "text",
                                                                  getTrad(
                                                                    "exportar",
                                                                  ),
                                                                );
                                                            },
                                                          onSelectionChanged:
                                                            function (e) {
                                                              $(
                                                                "#dxDataGrid_prendaNPedido",
                                                              )
                                                                .dxDataGrid(
                                                                  "instance",
                                                                )
                                                                .option(
                                                                  "dataSource",
                                                                  datasource_PrendasNPedido,
                                                                );
                                                            },
                                                          onRowPrepared:
                                                            function (e) {
                                                              if (
                                                                e.rowType ===
                                                                "data"
                                                              )
                                                                e.rowElement.css(
                                                                  "cursor",
                                                                  "pointer",
                                                                );
                                                            },
                                                          onToolbarPreparing:
                                                            function (e) {
                                                              e.toolbarOptions.items.unshift(
                                                                {
                                                                  location:
                                                                    "before",
                                                                  widget:
                                                                    "dxButton",
                                                                  options: {
                                                                    visible:
                                                                      datasource_entidades.items()
                                                                        .length >
                                                                      1,
                                                                    text: getTrad(
                                                                      "atras",
                                                                    ),
                                                                    type: "back",
                                                                    onClick:
                                                                      function () {
                                                                        $(
                                                                          "#dxTabPanel_filtro",
                                                                        )
                                                                          .dxTabPanel(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "selectedIndex",
                                                                            0,
                                                                          );
                                                                      },
                                                                  },
                                                                },
                                                                {
                                                                  location:
                                                                    "before",
                                                                  template:
                                                                    function (
                                                                      e,
                                                                      index,
                                                                      item,
                                                                    ) {
                                                                      item.css(
                                                                        "margin-left",
                                                                        "15px",
                                                                      );
                                                                      var denoEnti =
                                                                        entidadSel
                                                                          ? entidadSel.denominacion
                                                                          : "";
                                                                      item.append(
                                                                        $(
                                                                          "<div/>",
                                                                        )
                                                                          .addClass(
                                                                            "font-size",
                                                                          )
                                                                          .addClass(
                                                                            "p-0",
                                                                          )
                                                                          .addClass(
                                                                            "toolbarTitle",
                                                                          )
                                                                          .text(
                                                                            denoEnti,
                                                                          ),
                                                                      );
                                                                    },
                                                                },
                                                              );
                                                            },
                                                          //Estilos
                                                          showColumnLines: false,
                                                          showRowLines: true,
                                                          rowAlternationEnabled: true,
                                                        });
                                                      },
                                                    },
                                                    {
                                                      ratio: 5,
                                                      template: function () {
                                                        return $(
                                                          "<div id='dxDataGrid_prendaNPedido' />",
                                                        )
                                                          .css({
                                                            "padding-left":
                                                              "20px",
                                                          })
                                                          .dxDataGrid({
                                                            //Propiedades
                                                            filterRow: {
                                                              visible: true,
                                                              applyFilter:
                                                                "auto",
                                                              showAllText:
                                                                getTrad(
                                                                  "todos",
                                                                ),
                                                            },
                                                            export: {
                                                              enabled: true,
                                                              texts: {
                                                                exportAll:
                                                                  getTrad(
                                                                    "exportar",
                                                                  ),
                                                              },
                                                              fileName:
                                                                "infBalancePedidosRepartos_Prendas",
                                                            },
                                                            height: "100%",
                                                            columnAutoWidth: true,
                                                            paging: {
                                                              enabled: false,
                                                            },
                                                            loadPanel: {
                                                              enabled: false,
                                                            },
                                                            columns: [
                                                              {
                                                                dataField:
                                                                  "codigoPrenda",
                                                                caption:
                                                                  getTrad(
                                                                    "codigo",
                                                                  ),
                                                                width: 120,
                                                                sortIndex: 1,
                                                                sortOrder:
                                                                  "asc",
                                                                allowFiltering: true,
                                                              },
                                                              {
                                                                dataField:
                                                                  "denoPrenda",
                                                                caption:
                                                                  getTrad(
                                                                    "denominacion",
                                                                  ),
                                                                width: "50%",
                                                                allowFiltering: true,
                                                                allowHeaderFiltering: false,
                                                                alignment:
                                                                  "left",
                                                              },
                                                              {
                                                                dataField:
                                                                  "pedido",
                                                                caption:
                                                                  getTrad(
                                                                    "pedidas",
                                                                  ),
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.pedido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.pedido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                minWidth: "10%",
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                              {
                                                                name: "repartido",
                                                                caption:
                                                                  getTrad(
                                                                    "entregadas",
                                                                  ),
                                                                minWidth: "10%",
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.repartido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.repartido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                              {
                                                                caption:
                                                                  getTrad(
                                                                    "diferencia",
                                                                  ),
                                                                name: "diferencia",
                                                                minWidth: "10%",
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.repartido *
                                                                      (rowData.peso *
                                                                        0.001) -
                                                                      rowData.pedido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.repartido -
                                                                      rowData.pedido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                            ],
                                                            //EVENTOS
                                                            summary: {
                                                              recalculateWhileEditing: true,
                                                              totalItems: [
                                                                {
                                                                  column:
                                                                    "codigoPrenda",
                                                                  displayFormat:
                                                                    getTrad(
                                                                      "total",
                                                                    ).toUpperCase(),
                                                                },
                                                                {
                                                                  column:
                                                                    "pedido",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                                {
                                                                  column:
                                                                    "repartido",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                                {
                                                                  column:
                                                                    "diferencia",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                              ],
                                                            },
                                                            onContentReady:
                                                              function (e) {
                                                                e.element
                                                                  .find(
                                                                    ".dx-datagrid-export-button",
                                                                  )
                                                                  .dxButton(
                                                                    "instance",
                                                                  )
                                                                  .option(
                                                                    "text",
                                                                    getTrad(
                                                                      "exportar",
                                                                    ),
                                                                  );
                                                              },
                                                            //Estilos
                                                            showColumnLines: false,
                                                            showRowLines: true,
                                                            rowAlternationEnabled: true,
                                                          });
                                                      },
                                                    },
                                                  ],
                                                });
                                              },
                                            },
                                            {
                                              name: "agrupado",
                                              template: function () {
                                                return $("<div />").dxBox({
                                                  direction: "row",
                                                  crossAlign: "stretch",
                                                  height: "100%",
                                                  items: [
                                                    {
                                                      ratio: 5,
                                                      template: function () {
                                                        return $(
                                                          "<div id='dxDataGrid_prendaNPedido_agrupados' />",
                                                        ).dxDataGrid({
                                                          //Propiedades
                                                          headerFilter: {
                                                            visible: true,
                                                          },
                                                          export: {
                                                            enabled: true,
                                                            texts: {
                                                              exportAll:
                                                                getTrad(
                                                                  "exportar",
                                                                ),
                                                            },
                                                            fileName:
                                                              "infBalancePedidosRepartos_Prendas_Agrupado",
                                                          },
                                                          filterRow: {
                                                            visible: true,
                                                            applyFilter: "auto",
                                                            showAllText:
                                                              getTrad("todos"),
                                                          },
                                                          height: "100%",
                                                          columnAutoWidth: true,
                                                          hoverStateEnabled: true,
                                                          selection: {
                                                            mode: "single",
                                                          },
                                                          paging: {
                                                            pageSize: 50,
                                                          },
                                                          pager: {
                                                            showPageSizeSelector: true,
                                                            allowedPageSizes: [
                                                              20, 50, 100,
                                                            ],
                                                            showInfo: true,
                                                          },
                                                          loadPanel: {
                                                            enabled: false,
                                                          },
                                                          columns: [
                                                            {
                                                              dataField:
                                                                "codigoPrenda",
                                                              caption:
                                                                getTrad(
                                                                  "codigo",
                                                                ),
                                                              width: 120,
                                                              sortIndex: 1,
                                                              sortOrder: "asc",
                                                              allowFiltering: true,
                                                              allowHeaderFiltering: false,
                                                            },
                                                            {
                                                              dataField:
                                                                "denoPrenda",
                                                              caption:
                                                                getTrad(
                                                                  "denominacion",
                                                                ),
                                                              width: "50%",
                                                              allowFiltering: true,
                                                              allowHeaderFiltering: false,
                                                              alignment: "left",
                                                            },
                                                            {
                                                              dataField: "peso",
                                                              caption: getTrad("peso") + " (g)",
                                                              allowFiltering: false,
                                                              allowHeaderFiltering: false,
                                                              alignment: "center",
                                                              width: 70
                                                            },
                                                            {
                                                              dataField:
                                                                "pedido",
                                                              caption:
                                                                getTrad(
                                                                  "pedidas",
                                                                ),
                                                              calculateCellValue:
                                                                function (
                                                                  rowData,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? rowData.pedido *
                                                                    (rowData.peso *
                                                                      0.001)
                                                                    : rowData.pedido;
                                                                },
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "decimal",
                                                                    )
                                                                    : formatNumber(
                                                                      data.value,
                                                                    );
                                                                },
                                                              minWidth: "10%",
                                                              allowFiltering: false,
                                                              alignment:
                                                                "center",
                                                            },
                                                            {
                                                              name: "repartido",
                                                              caption:
                                                                getTrad(
                                                                  "entregadas",
                                                                ),
                                                              minWidth: "10%",
                                                              calculateCellValue:
                                                                function (
                                                                  rowData,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? rowData.repartido *
                                                                    (rowData.peso *
                                                                      0.001)
                                                                    : rowData.repartido;
                                                                },
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "decimal",
                                                                    )
                                                                    : formatNumber(
                                                                      data.value,
                                                                    );
                                                                },
                                                              allowFiltering: false,
                                                              alignment:
                                                                "center",
                                                            },
                                                            {
                                                              caption:
                                                                getTrad(
                                                                  "diferencia",
                                                                ),
                                                              name: "diferencia",
                                                              minWidth: "10%",
                                                              calculateCellValue:
                                                                function (
                                                                  rowData,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? rowData.repartido *
                                                                    (rowData.peso *
                                                                      0.001) -
                                                                    rowData.pedido *
                                                                    (rowData.peso *
                                                                      0.001)
                                                                    : rowData.repartido -
                                                                    rowData.pedido;
                                                                },
                                                              customizeText:
                                                                function (
                                                                  data,
                                                                ) {
                                                                  return !is_unidades
                                                                    ? formatNumber(
                                                                      data.value,
                                                                      2,
                                                                      "decimal",
                                                                    )
                                                                    : formatNumber(
                                                                      data.value,
                                                                    );
                                                                },
                                                              allowFiltering: false,
                                                              alignment:
                                                                "center",
                                                            },
                                                          ],
                                                          //EVENTOS
                                                          summary: {
                                                            recalculateWhileEditing: true,
                                                            totalItems: [
                                                              {
                                                                column:
                                                                  "codigoPrenda",
                                                                displayFormat:
                                                                  getTrad(
                                                                    "total",
                                                                  ).toUpperCase(),
                                                              },
                                                              {
                                                                column:
                                                                  "pedido",
                                                                summaryType:
                                                                  "sum",
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                displayFormat:
                                                                  "{0}",
                                                              },
                                                              {
                                                                column:
                                                                  "repartido",
                                                                summaryType:
                                                                  "sum",
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                displayFormat:
                                                                  "{0}",
                                                              },
                                                              {
                                                                column:
                                                                  "diferencia",
                                                                summaryType:
                                                                  "sum",
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                displayFormat:
                                                                  "{0}",
                                                              },
                                                            ],
                                                          },
                                                          //Eventos
                                                          onContentReady:
                                                            function (e) {
                                                              e.element
                                                                .find(
                                                                  ".dx-datagrid-export-button",
                                                                )
                                                                .dxButton(
                                                                  "instance",
                                                                )
                                                                .option(
                                                                  "text",
                                                                  getTrad(
                                                                    "exportar",
                                                                  ),
                                                                );
                                                            },
                                                          onSelectionChanged:
                                                            function (e) {
                                                              $(
                                                                "#dxDataGrid_Pedidos_agrupado",
                                                              )
                                                                .dxDataGrid(
                                                                  "instance",
                                                                )
                                                                .option(
                                                                  "dataSource",
                                                                  datasource_Pedidos_agrupado,
                                                                );
                                                            },
                                                          onRowPrepared:
                                                            function (e) {
                                                              if (
                                                                e.rowType ===
                                                                "data"
                                                              )
                                                                e.rowElement.css(
                                                                  "cursor",
                                                                  "pointer",
                                                                );
                                                            },
                                                          onToolbarPreparing:
                                                            function (e) {
                                                              e.toolbarOptions.items.unshift(
                                                                {
                                                                  location:
                                                                    "before",
                                                                  widget:
                                                                    "dxButton",
                                                                  options: {
                                                                    visible:
                                                                      datasource_entidades.items()
                                                                        .length >
                                                                      1,
                                                                    text: getTrad(
                                                                      "atras",
                                                                    ),
                                                                    type: "back",
                                                                    onClick:
                                                                      function () {
                                                                        $(
                                                                          "#dxTabPanel_filtro",
                                                                        )
                                                                          .dxTabPanel(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "selectedIndex",
                                                                            0,
                                                                          );
                                                                      },
                                                                  },
                                                                },
                                                                {
                                                                  location:
                                                                    "before",
                                                                  template:
                                                                    function (
                                                                      e,
                                                                      index,
                                                                      item,
                                                                    ) {
                                                                      item.css(
                                                                        "margin-left",
                                                                        "15px",
                                                                      );
                                                                      var denoEnti =
                                                                        entidadSel
                                                                          ? entidadSel.denominacion
                                                                          : "";
                                                                      item.append(
                                                                        $(
                                                                          "<div/>",
                                                                        )
                                                                          .addClass(
                                                                            "font-size",
                                                                          )
                                                                          .addClass(
                                                                            "p-0",
                                                                          )
                                                                          .addClass(
                                                                            "toolbarTitle",
                                                                          )
                                                                          .text(
                                                                            denoEnti,
                                                                          ),
                                                                      );
                                                                    },
                                                                },
                                                              );
                                                            },
                                                          //Estilos
                                                          showColumnLines: false,
                                                          showRowLines: true,
                                                          rowAlternationEnabled: true,
                                                        });
                                                      },
                                                    },
                                                    {
                                                      ratio: 3,
                                                      template: function () {
                                                        return $(
                                                          "<div id='dxDataGrid_Pedidos_agrupado' />",
                                                        )
                                                          .css({
                                                            "padding-left":
                                                              "20px",
                                                          })
                                                          .dxDataGrid({
                                                            //Propiedades
                                                            headerFilter: {
                                                              visible: true,
                                                            },
                                                            export: {
                                                              enabled: true,
                                                              texts: {
                                                                exportAll:
                                                                  getTrad(
                                                                    "exportar",
                                                                  ),
                                                              },
                                                              fileName:
                                                                "infBalancePedidosRepartos_Pedidos_Agrupado",
                                                            },
                                                            filterRow: {
                                                              visible: true,
                                                              applyFilter:
                                                                "auto",
                                                              showAllText:
                                                                getTrad(
                                                                  "todos",
                                                                ),
                                                            },
                                                            height: "100%",
                                                            columnAutoWidth: true,
                                                            paging: {
                                                              pageSize: 50,
                                                            },
                                                            pager: {
                                                              showPageSizeSelector: true,
                                                              allowedPageSizes:
                                                                [20, 50, 100],
                                                              showInfo: true,
                                                            },
                                                            loadPanel: {
                                                              enabled: false,
                                                            },
                                                            columns: [
                                                              {
                                                                dataField:
                                                                  "fecha",
                                                                caption:
                                                                  getTrad(
                                                                    "fecha",
                                                                  ),
                                                                width: 100,
                                                                sortIndex: 1,
                                                                sortOrder:
                                                                  "asc",
                                                                allowFiltering: false,
                                                                dataType:
                                                                  "date",
                                                                format:
                                                                  "dd/MM/yyyy",
                                                              },
                                                              {
                                                                dataField:
                                                                  "idEstadoPedido",
                                                                caption:
                                                                  getTrad(
                                                                    "estado",
                                                                  ),
                                                                lookup: {
                                                                  dataSource:
                                                                    datasource_estadoPedido.items(),
                                                                  valueExpr:
                                                                    "idEstadoPedido",
                                                                  displayExpr:
                                                                    "denominacion",
                                                                },
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    //Cálculo de los estados del pedido para X prenda
                                                                    if (
                                                                      rowData.repartido ===
                                                                      0
                                                                    )
                                                                      return 1; //pendiente

                                                                    if (
                                                                      rowData.pedido <=
                                                                      rowData.repartido
                                                                    )
                                                                      return 3; // Servido
                                                                    else
                                                                      return 2; // Parc. servido
                                                                  },
                                                                cellTemplate:
                                                                  function (
                                                                    cellElement,
                                                                    cellInfo,
                                                                  ) {
                                                                    var container =
                                                                      $(
                                                                        "<div />",
                                                                      )
                                                                        .text(
                                                                          cellInfo.text,
                                                                        )
                                                                        .css(
                                                                          "position",
                                                                          "relative",
                                                                        );
                                                                    if (
                                                                      cellInfo
                                                                        .data
                                                                        .isCerrado
                                                                    ) {
                                                                      container.append(
                                                                        svg_warning
                                                                          .clone()
                                                                          .css(
                                                                            "height",
                                                                            "20px",
                                                                          )
                                                                          .css(
                                                                            "width",
                                                                            "20px",
                                                                          )
                                                                          .css(
                                                                            "cursor",
                                                                            "pointer",
                                                                          )
                                                                          .css(
                                                                            "position",
                                                                            "absolute",
                                                                          )
                                                                          .css(
                                                                            "right",
                                                                            "0px",
                                                                          ),
                                                                      );

                                                                      var msg =
                                                                        getTrad(
                                                                          "msg_pedidoCerrado",
                                                                        );

                                                                      tooltipControl_creacion(
                                                                        container,
                                                                        msg,
                                                                      );
                                                                    }

                                                                    cellElement.append(
                                                                      container,
                                                                    );
                                                                  },
                                                                allowHeaderFiltering: true,
                                                                allowFiltering: false,
                                                                width: 120,
                                                              },
                                                              {
                                                                dataField:
                                                                  "pedido",
                                                                caption:
                                                                  getTrad(
                                                                    "pedidas",
                                                                  ),
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.pedido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.pedido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                width: "40%",
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                              {
                                                                dataField:
                                                                  "repartido",
                                                                caption:
                                                                  getTrad(
                                                                    "entregadas",
                                                                  ),
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.repartido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.repartido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                width: "40%",
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                              {
                                                                caption:
                                                                  getTrad(
                                                                    "diferencia",
                                                                  ),
                                                                name: "diferencia",
                                                                minWidth: "10%",
                                                                calculateCellValue:
                                                                  function (
                                                                    rowData,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? rowData.repartido *
                                                                      (rowData.peso *
                                                                        0.001) -
                                                                      rowData.pedido *
                                                                      (rowData.peso *
                                                                        0.001)
                                                                      : rowData.repartido -
                                                                      rowData.pedido;
                                                                  },
                                                                customizeText:
                                                                  function (
                                                                    data,
                                                                  ) {
                                                                    return !is_unidades
                                                                      ? formatNumber(
                                                                        data.value,
                                                                        2,
                                                                        "decimal",
                                                                      )
                                                                      : formatNumber(
                                                                        data.value,
                                                                      );
                                                                  },
                                                                allowFiltering: false,
                                                                alignment:
                                                                  "center",
                                                              },
                                                            ],
                                                            //EVENTOS
                                                            summary: {
                                                              recalculateWhileEditing: true,
                                                              totalItems: [
                                                                {
                                                                  column:
                                                                    "fecha",
                                                                  displayFormat:
                                                                    getTrad(
                                                                      "total",
                                                                    ).toUpperCase(),
                                                                },
                                                                {
                                                                  column:
                                                                    "pedido",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                                {
                                                                  column:
                                                                    "repartido",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                                {
                                                                  column:
                                                                    "diferencia",
                                                                  summaryType:
                                                                    "sum",
                                                                  customizeText:
                                                                    function (
                                                                      data,
                                                                    ) {
                                                                      return !is_unidades
                                                                        ? formatNumber(
                                                                          data.value,
                                                                          2,
                                                                          "decimal",
                                                                        )
                                                                        : formatNumber(
                                                                          data.value,
                                                                        );
                                                                    },
                                                                  displayFormat:
                                                                    "{0}",
                                                                },
                                                              ],
                                                            },
                                                            onContentReady:
                                                              function (e) {
                                                                e.element
                                                                  .find(
                                                                    ".dx-datagrid-export-button",
                                                                  )
                                                                  .dxButton(
                                                                    "instance",
                                                                  )
                                                                  .option(
                                                                    "text",
                                                                    getTrad(
                                                                      "exportar",
                                                                    ),
                                                                  );
                                                              },
                                                            //Estilos
                                                            showColumnLines: false,
                                                            showRowLines: true,
                                                            rowAlternationEnabled: true,
                                                          });
                                                      },
                                                    },
                                                  ],
                                                });
                                              },
                                            },
                                          ],
                                          //EVENTOS
                                          onSelectionChanged: function (e) {
                                            var tabSelected = $(
                                              "#dxTabPanel_informe",
                                            )
                                              .dxTabPanel("instance")
                                              .option("selectedItem.name"),
                                              dxDataGrid_pedidos = $(
                                                "#dxDataGrid_pedidos",
                                              ).dxDataGrid("instance"),
                                              dxDataGrid_prendaNPedido_agrupados =
                                                $(
                                                  "#dxDataGrid_prendaNPedido_agrupados",
                                                ).dxDataGrid("instance");

                                            dataSourceItems_enMemoria[
                                              "prendas"
                                            ] = [];
                                            dataSourceItems_enMemoria[
                                              "pedidos_agrupado"
                                            ] = [];

                                            if (tabSelected === "pedidos") {
                                              datasource_Pedidos
                                                .load()
                                                .done(function (items) {
                                                  dataSourceItems_enMemoria[
                                                    tabSelected
                                                  ] = items;

                                                  $("#dxDataGrid_prendaNPedido")
                                                    .dxDataGrid("instance")
                                                    .option("dataSource", null);
                                                  dxDataGrid_pedidos.option(
                                                    "dataSource",
                                                    dxDataGrid_filtros(items),
                                                  );
                                                  dxDataGrid_pedidos.clearSelection();
                                                });
                                            } else if (
                                              tabSelected === "agrupado"
                                            ) {
                                              datasource_PrendasNPedido_agrupado
                                                .load()
                                                .done(function (items) {
                                                  dataSourceItems_enMemoria[
                                                    tabSelected
                                                  ] = items;

                                                  dxDataGrid_prendaNPedido_agrupados.option(
                                                    "dataSource",
                                                    dxDataGrid_filtros(items),
                                                  );
                                                  dxDataGrid_prendaNPedido_agrupados.clearSelection();
                                                });
                                            }
                                          },
                                        }),
                                    );
                                  },
                                },
                                {
                                  // Range selector
                                  baseSize: 130,
                                  template: function (e, index, itemElement) {
                                    itemElement.append(
                                      create_rangeSelector(
                                        datasource_filtroTiempo,
                                        false,
                                        false,
                                        function (e) {
                                          if (fechaDesde) {
                                            // para que no entre cuando se limpia el valor
                                            var tabSelected = $(
                                              "#dxTabPanel_informe",
                                            )
                                              .dxTabPanel("instance")
                                              .option("selectedItem.name"),
                                              dxDataGrid_pedidos = $(
                                                "#dxDataGrid_pedidos",
                                              ).dxDataGrid("instance"),
                                              dxDataGrid_prendaNPedido_agrupados =
                                                $(
                                                  "#dxDataGrid_prendaNPedido_agrupados",
                                                ).dxDataGrid("instance");

                                            fechaDesde = e.value[0];
                                            fechaHasta =
                                              e.component.option("valueHasta");
                                            if (tabSelected === "pedidos") {
                                              datasource_Pedidos
                                                .load()
                                                .done(function (items) {
                                                  dataSourceItems_enMemoria[
                                                    tabSelected
                                                  ] = items;

                                                  $("#dxDataGrid_prendaNPedido")
                                                    .dxDataGrid("instance")
                                                    .option("dataSource", null);
                                                  dxDataGrid_pedidos.option(
                                                    "dataSource",
                                                    dxDataGrid_filtros(items),
                                                  );
                                                  dxDataGrid_pedidos.clearSelection();
                                                });
                                            } else if (
                                              tabSelected === "agrupado"
                                            ) {
                                              datasource_PrendasNPedido_agrupado
                                                .load()
                                                .done(function (items) {
                                                  dataSourceItems_enMemoria[
                                                    tabSelected
                                                  ] = items;

                                                  dxDataGrid_prendaNPedido_agrupados.option(
                                                    "dataSource",
                                                    dxDataGrid_filtros(items),
                                                  );
                                                  dxDataGrid_prendaNPedido_agrupados.clearSelection();
                                                });
                                            }
                                            dxRangeSelector_PosicionValor_nuevoMarker(
                                              $(
                                                "#dxRangeSelector_filtroTiempo",
                                              ),
                                              fechaDesde,
                                              fechaHasta,
                                            );
                                          }
                                        },
                                        function () {
                                          $("#dxRangeSelector_filtroTiempo")
                                            .dxRangeSelector("instance")
                                            .option({
                                              "scale.minRange": {
                                                days: null,
                                                months: 1,
                                              },
                                            });
                                        },
                                      ),
                                    );
                                    setTimeout(function () {
                                      filtroTiempo_Resize();
                                    }, 0);
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                    ],
                    onSelectionChanged: function (e) {
                      var tabpanel = e.component;
                      var index = tabpanel.option("selectedIndex");
                      if (index === 0) {
                        $("#opciones_container").removeClass("opened");
                        $("#dxForm_opciones")
                          .dxForm("instance")
                          .option("disabled", true);

                        //#region Limpiar opciones
                        $("#filtro_dxList_entidad")
                          .dxList("instance")
                          .unselectAll();

                        var dxList_tipoPedido =
                          $("#dxList_tipoPedido").dxList("instance");
                        if (dxList_tipoPedido) {
                          dxList_tipoPedido.unselectAll();
                        }

                        $("#dxRadioGroup_familias")
                          .dxRadioGroup("instance")
                          .option("value", 0);
                        $("#dxRadioGroup_tipoTabla_agrupado")
                          .dxRadioGroup("instance")
                          .option("value", 1);
                        $("#dxRadioGroup_tipoTabla_unidadesPeso")
                          .dxRadioGroup("instance")
                          .option("value", 1);
                        //#endregion

                        $("#dxDataGrid_pedidos")
                          .dxDataGrid("instance")
                          .option("dataSource", null);
                        $("#dxDataGrid_prendaNPedido")
                          .dxDataGrid("instance")
                          .option("dataSource", null);
                      } else {
                        $("#dxForm_opciones")
                          .dxForm("instance")
                          .option("disabled", false);

                        fechaDesde = null; // para filtrar la carga al vaciar de valor el range selector
                        fechaHasta = null;
                        $("#dxRangeSelector_filtroTiempo")
                          .dxRangeSelector("instance")
                          .option("value", []);

                        cargaInforme_pedidos(entidadSel);
                      }
                    },
                  }),
              );
            },
          },
        ],
      });

      if (is_entidadUnica) {
        $("#dxForm_opciones").dxForm("instance").option("disabled", false);
        cargaInforme_pedidos(entidadSel);
        filtroTiempo_Resize();
      }
    })
    .always(function () {
      deferred.resolve();
    });

  function cargaInforme_pedidos(entidadSel) {
    datasource_filtroTiempo.load().done(function (filtroTiempo_items) {
      if (filtroTiempo_items[0].arg) {
        //#region Carga de range selector
        if (filtroTiempo_items.length > 0) {
          var ultimaFecha = new Date(filtroTiempo_items[1].arg);
          fechaDesde = startOfMonth(ultimaFecha);
          fechaHasta = new Date(
            new Date(ultimaFecha).setDate(ultimaFecha.getDate() + 1),
          );
        }

        $("#dxRangeSelector_filtroTiempo")
          .dxRangeSelector("instance")
          .option({
            dataSource: datasource_filtroTiempo.items(),
            value: [fechaDesde, fechaHasta],
            valueHasta: new Date(fechaHasta),
            scale: {
              startValue: new Date(filtroTiempo_items[0].arg),
              endValue: new Date(fechaHasta),
            },
          });
        //#endregion

        $("#dxTabPanel_filtro")
          .dxTabPanel("instance")
          .option("selectedIndex", 1);
        $(".toolbarTitle").text(entidadSel.denominacion);
      } else {
        dxMensajePregunta(getTrad("alert_sinDatos_entidad"), [
          [
            getTrad("aceptar"),
            function (e) { },
            "danger",
            "dxButton_sinEntidad_error",
          ],
        ]);
        $("#filtro_dxList_entidad").dxList("instance").unselectAll();
      }
    });
  }

  function asignacion_dataSources() {
    var tabSelected = $("#dxTabPanel_informe")
      .dxTabPanel("instance")
      .option("selectedItem.name");

    switch (tabSelected) {
      case "pedidos":
        $("#dxDataGrid_pedidos")
          .dxDataGrid("instance")
          .option(
            "dataSource",
            dxDataGrid_filtros(dataSourceItems_enMemoria[tabSelected]),
          );
        break;
      case "agrupado":
        $("#dxDataGrid_prendaNPedido_agrupados")
          .dxDataGrid("instance")
          .option(
            "dataSource",
            dxDataGrid_filtros(dataSourceItems_enMemoria[tabSelected]),
          );
        break;
      default:
        break;
    }
  }

  //region FUNCIONES DE TRATAMIENTO DE DATOS
  function dxDataGrid_filtros(items) {
    var tabSelected = $("#dxTabPanel_informe")
      .dxTabPanel("instance")
      .option("selectedItem.name");
    // COGER LOS FILTROS DE LA PÁGINA
    var items_local = $.extend(true, [], items),
      idsTipoPedidoSel =
        typeof $("#dxList_tipoPedido").dxList("instance") !== "undefined"
          ? $("#dxList_tipoPedido")
            .dxList("instance")
            .option("selectedItemKeys")
          : [],
      idFamiliaSel =
        $("#dxRadioGroup_familias").data("dxRadioGroup") &&
          tabSelected === "agrupado"
          ? $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value")
          : 0;

    // FILTRADO, AGRUPACIÓN Y SUMATORIO DE DATOS
    var datosFiltrados = query(items_local)
      .filter(function (dataItem) {
        var is_tipoPedido =
          idsTipoPedidoSel.length !== 0
            ? idsTipoPedidoSel.indexOf(dataItem.idTipoPedido) > -1
            : true,
          is_idFamilia =
            idFamiliaSel !== 0 ? dataItem.idFamilia === idFamiliaSel : true;
        return (
          is_tipoPedido &&
          is_idFamilia &&
          dataItem.idEntidad === entidadSel.idEntidad
        );
      })
      .toArray();

    if (tabSelected === "pedidos") {
      return datosFiltrados;
    } else if (tabSelected === "agrupado") {
      return agrupar_prendasNPedido(datosFiltrados);
    }
  }
  function agrupar_prendasNPedido(items) {
    // FILTRA LOS DATOS POR FECHA, LOS AGRUPA POR ENTIDAD Y SUMA EL RESTO DE DATOS
    return query(items)
      .select([
        "idPrenda",
        "codigoPrenda",
        "denoPrenda",
        "peso",
        "pedido",
        "repartido",
        "idFamilia",
      ])
      .groupBy(function (dataItem) {
        return [
          dataItem.idPrenda,
          dataItem.codigoPrenda,
          dataItem.denoPrenda,
          dataItem.peso,
          dataItem.idFamilia,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("pedido")
          .done(function (result) {
            resultItem = {
              pedido: result,
            };
          });
        query(dataItem.items)
          .sum("repartido")
          .done(function (result) {
            resultItem = {
              idPrenda: dataItem.key[0],
              codigoPrenda: dataItem.key[1],
              denoPrenda: dataItem.key[2],
              peso: dataItem.key[3],
              pedido: resultItem.pedido,
              repartido: result,
            };
          });
        return resultItem;
      })
      .toArray();
  }
  //#endregion

  return deferred.promise();
}
