import { Button, Popup, TextBox } from "devextreme-react";
import { getTrad } from "helpers";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import DataGridCargaFacturasVenta from "./DataGridCargaFacturasVenta";
import { ToolbarItem } from "devextreme-react/autocomplete";
import notify from "devextreme/ui/notify";

const SelectorFacturaVentaComponent = forwardRef(({ visible, formData, asociarFactura }, ref) => {
    const [isVisible, setIsVisible] = useState(visible);
    const [popupVisible, setPopupVisible] = useState(false);
    const [facturaAsociada, setFacturaAsociada] = useState({
        idReferenciaFacturaVenta: formData.idReferenciaFacturaVenta,
        NCF: formData?.idEmpresaPolarier === 4 ? formData.idReferenciaFacturaVentaNavigation?.NCF : null,
        codigo: formData?.idEmpresaPolarier !== 4 ? formData.idReferenciaFacturaVentaNavigation?.codigo : null,
    });
    const dataGridCargaFacturasVenta_REF = useRef();

    useImperativeHandle(ref, () => ({
        setVisibility: (visibility) => {
            setIsVisible(visibility);
        },
        clearFacturaAsociada: () => {
            setFacturaAsociada({
                idReferenciaFacturaVenta: null,
                NCF: null,
                codigo: null,
            });
            dataGridCargaFacturasVenta_REF.current.clearSelection();
        },
    }));

    useEffect(() => {
        setFacturaAsociada({
            idReferenciaFacturaVenta: formData.idReferenciaFacturaVenta,
            NCF: formData?.idEmpresaPolarier === 4 ? formData.idReferenciaFacturaVentaNavigation?.NCF : null,
            codigo: formData?.idEmpresaPolarier !== 4 ? formData.idReferenciaFacturaVentaNavigation?.codigo : null,
            tasaCambio: formData.tasaCambio,
        });
    }, [formData]);

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    const openPopup = () => {
        if (formData.idAdmCliente != undefined) {
            dataGridCargaFacturasVenta_REF.current.selectRow(facturaAsociada.idReferenciaFacturaVenta);
            setPopupVisible(true);
        } else {
            notify({
                message: "Selecciona un cliente",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    };

    const closePopup = () => {
        setPopupVisible(false);
    };

    const seleccionarFactura = (factura) => {
        setFacturaAsociada({
            idReferenciaFacturaVenta: factura.idAdmFacturaVenta,
            NCF: factura.idEmpresaPolarier === 4 ? factura.NCF : null,
            codigo: factura.idEmpresaPolarier !== 4 ? factura.codigo : null,
            tasaCambio: factura.tasaCambio,
            idMoneda: factura.idMoneda,
        });
    };

    const asociarFactura_onClick = () => {
        asociarFactura(facturaAsociada);
        closePopup();
    };

    const desasociarFactura = () => {
        asociarFactura({
            idReferenciaFacturaVenta: null,
            NCF: null,
            codigo: null,
        });
        dataGridCargaFacturasVenta_REF.current.clearSelection();
        setFacturaAsociada({
            idReferenciaFacturaVenta: null,
            NCF: null,
            codigo: null,
        });
        closePopup();
    };

    return (
        // JSX markup goes here
        <>
            {/* {isVisible &&  */}
            <div style={{ display: "flex" }}>
                <Button text="Asociar Factura" onClick={openPopup} visible={isVisible} />
                {facturaAsociada.idReferenciaFacturaVenta && (
                    <div className="ml-3 position-relative">
                        <TextBox
                            value={`${getTrad("Factura")} ${
                                formData.idEmpresaPolarier === 4 ? facturaAsociada.NCF : facturaAsociada.codigo
                            }`}
                            stylingMode="outlined"
                            visible={isVisible}
                            readOnly
                        />
                        <Button
                            className="clearButtonLlamamiento"
                            stylingMode="text"
                            icon="clear"
                            disabled={formData.idAdmFactura_Estado === 2}
                            onClick={desasociarFactura}
                            visible={isVisible}
                        />
                    </div>
                )}
            </div>
            {/* } */}
            {/* {datasource_tblAdmFacturaVenta.items().length > 0 && */}
            <div className="media-body">
                <Popup
                    visible={popupVisible}
                    title="Seleccionar Factura"
                    onHiding={closePopup}
                    width={1000}
                    height={600}
                >
                    <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                        <Button text={getTrad("seleccionar")} type="success" onClick={asociarFactura_onClick} />
                    </ToolbarItem>
                    <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                        <Button text={getTrad("quitarSeleccion")} type="default" onClick={desasociarFactura} />
                    </ToolbarItem>
                    <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                        <Button text={getTrad("cancelar")} onClick={closePopup} />
                    </ToolbarItem>
                    <DataGridCargaFacturasVenta
                        ref={dataGridCargaFacturasVenta_REF}
                        formData={formData}
                        seleccionarFactura={seleccionarFactura}
                    />
                </Popup>
            </div>
            {/* } */}
        </>
    );
});

export default SelectorFacturaVentaComponent;
