import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import {
    getTblCalendarioPersonal_estado,
    getTblTurno,
    getLavanderiasAccesibles,
    CuandranteContextProvider,
    CuadranteContextConsumer,
} from "./services/apiCalls";

import SettingsPopup from "./components/Cuadrante/SettingsPopup";
import PersonalGeneral from "../PersonalGeneral";
import Cuadrante from "./components/Cuadrante/Cuadrante";
import Filters from "./components/Filtros/Filters";
import EstructuraOperativa from "./Layout";
import { loadPanelActions } from "actions";
import CalendarioCuadrante from "./components/CalendarioCuadrante/CalendarioCuadrante";
import JornadaPopup from "./components/Jornada/JornadaPopup";
import LlamamientoPopup from "./components/Llamamientos/LlamamientoPopup";
import LottieIcon from "components/LottieIcon";
import { getTrad } from "helpers";

function CuadrantePersonal({ user, lavanderia, loadPanel_show, loadPanel_hide, idioma }) {
    //Api data storage
    const [turnoSeleccionado, setTurnoSeleccionado] = useState({});
    const [tblTurno, setTblTurno] = useState([]);
    const [tblCalendarioPersonal_estado, setTblCalendarioPersonal_estado] = useState([]);
    const [viewType, setViewType] = useState("compacto");
    const [enableMigracion, setEnableMigracion] = useState(false);
    /* ************ */

    const prueba = (a) => {
        setViewType(a);
    };
    //Pivot Grid constants
    const refSettingsPopup = useRef(null);
    const refCalendarioCuadrante = useRef(null);
    const refPersonalGeneralPopup = useRef(null);
    const refEstructuraOperativa = useRef(null);
    const refContextProvider = useRef(null);
    const refJornadaPopup = useRef(null);
    const refLlamamientoPopup = useRef(null);
    /* ************ */
    const rangeInitializer = (selectedWeekAmount) => {
        const date = Date.now();
        let weekAmount;
        if (selectedWeekAmount != null) {
            weekAmount = selectedWeekAmount;
        } else {
            weekAmount = 2;
        }
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - 7 - startOfWeek.getDay() + 1);
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6 + 7 * weekAmount);
        endOfWeek.setHours(23, 59, 59);
        return {
            firstDay: startOfWeek,
            lastDay: endOfWeek,
            weekAmount: weekAmount,
        };
    };
    const [selectedRange, setSelectedRange] = useState(() => rangeInitializer());
    /* ************ */

    useEffect(() => {
        getLavanderiasAccesibles()
            .load()
            .then((data) => {
                setEnableMigracion(data.length > 1);
            });

        const estados = getTblCalendarioPersonal_estado()
            .load()
            .then((data) => {
                setTblCalendarioPersonal_estado(data);
            });
    }, []);

    useEffect(() => {
        if (
            (user.enableDatosRRHH ||
                user.tblTipoTrabajoNUsuario.some((x) => x.idLavanderia === lavanderia.idLavanderia)) &&
            lavanderia.idLavanderia != 0 &&
            selectedRange.firstDay &&
            selectedRange.lastDay
        ) {
            loadPanel_show(false);

            getTblTurno(lavanderia.idLavanderia)
                .load()
                .then((tblTurno) => {
                    setTurnoSeleccionado({ ...tblTurno[0], index: 0 });
                    setTblTurno(tblTurno);
                });
        }
    }, [lavanderia.idLavanderia]);

    useEffect(() => {
        refPersonalGeneralPopup_unsubscribeToStore_modified();
        refPersonalGeneralPopup_subscribeToStore_modified(fetchCuadrantePersonal_named);
    }, [selectedRange, lavanderia.idLavanderia]);

    const refPersonalGeneralPopup_subscribeToStore_modified = (eventHandler) => {
        refPersonalGeneralPopup.current?.datasource_persona.store().on("modified", eventHandler);
    };

    const refPersonalGeneralPopup_unsubscribeToStore_modified = () => {
        refPersonalGeneralPopup.current?.datasource_persona.store().off("modified");
    };

    const fetchCuadrantePersonal = () => {
        refContextProvider.current.loadAll();
    };
    const fetchCuadrantePersonal_named = fetchCuadrantePersonal;

    return !user.enableDatosRRHH &&
        !user.tblTipoTrabajoNUsuario.some((x) => x.idLavanderia === lavanderia.idLavanderia) ? (
        <div className="container_spanCentrado">
            <div className="container_spanCentrado h-100 w-75">
                <LottieIcon height="250px" width="250px" icon={"warning"} />
                <div>
                    <p className="display-4 p-5 m-0">{getTrad("sinAccesoTipoTrabajo")}</p>
                </div>
            </div>
        </div>
    ) : (
        <>
            <CuandranteContextProvider
                ref={refContextProvider}
                selectedRange={selectedRange}
                refs={{ refJornadaPopup, refLlamamientoPopup, refSettingsPopup }}
            >
                <Filters
                    lavanderia={lavanderia}
                    tblTurno={tblTurno}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    viewType={viewType}
                    setViewType={prueba}
                    selectedIndex={turnoSeleccionado.index}
                    setTurnoSeleccionado={setTurnoSeleccionado}
                    tblCalendarioPersonal_estado={tblCalendarioPersonal_estado}
                />

                <Cuadrante
                    lavanderia={lavanderia}
                    selectedRange={selectedRange}
                    refSettingsPopup={refSettingsPopup}
                    refCalendarioCuadrante={refCalendarioCuadrante}
                    refPersonalGeneralPopup={refPersonalGeneralPopup}
                    refEstructuraOperativa={refEstructuraOperativa}
                    tblCalendarioPersonal_estado={tblCalendarioPersonal_estado}
                    tblTurno={tblTurno}
                    viewType={viewType}
                    selectedIndex={turnoSeleccionado.index}
                    enableMigracion={enableMigracion}
                    loadPanel_hide={loadPanel_hide}
                />

                <CuadranteContextConsumer>
                    <SettingsPopup
                        ref={refSettingsPopup}
                        tblCalendarioPersonal_estado={tblCalendarioPersonal_estado.filter(
                            (x) => x.idEstado !== 10 && x.idEstado !== 4
                        )} //se filtra el cierre y el festivo trabajado
                        tblTurno={tblTurno}
                        idioma={idioma}
                    />

                    <CalendarioCuadrante
                        ref={refCalendarioCuadrante}
                        tblCalendarioPersonal_estado={tblCalendarioPersonal_estado} //se filtra el cierre y el festivo trabajado
                        idioma={idioma}
                        lavanderia={lavanderia}
                        turno={turnoSeleccionado}
                        enableMigracion={enableMigracion}
                        refSettingsPopup={refSettingsPopup}
                    />

                    <EstructuraOperativa ref={refEstructuraOperativa} idLavanderia={lavanderia.idLavanderia} />

                    <JornadaPopup lavanderia={lavanderia} ref={refJornadaPopup} />

                    <LlamamientoPopup ref={refLlamamientoPopup} lavanderia={lavanderia} idioma={idioma} />
                </CuadranteContextConsumer>

                <div style={{ overflow: "hidden", height: 0 }}>
                    <PersonalGeneral ref={refPersonalGeneralPopup} />
                </div>
            </CuandranteContextProvider>
        </>
    );
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    // resolucion: state.Global.resolucion
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CuadrantePersonal);
