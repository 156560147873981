import $ from "jquery";
import { tasaCambioConstants } from "constants/tasaCambio";

const initialState = { tblTasaCambio: [] };

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case tasaCambioConstants.LOAD_TASACAMBIO_SUCCESS: {
            let tblTasaCambio = { ...state.tblTasaCambio };
            return {
                ...state,
                tblTasaCambio: action.data,
            };
        }
        case tasaCambioConstants.CLEAR_TASACAMBIO_SUCCESS: {
            return {
                ...state,
                tblTasaCambio: [],
            };
        }
        default:
            return state;
    }
}
