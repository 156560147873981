import React, { useState } from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import TextBox from "devextreme-react/text-box";
import { List } from "devextreme-react";

const options = {
  types: [],
  fields: ["formatted_address", "geometry.location", "address_components"],
};

export default ({
  apiKey,
  debounce = 1000,
  language = "es-ES",
  value,
  onPlaceSelected,
  onChange,
  placeholder,
}) => {
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey,
    debounce,
    language,
    options,
  });

  const [isListVisible, setIsListVisible] = useState(true);

  const containerStyle = {
    zIndex: 1,
    position: "absolute",
    top: 30,
    padding: 10,
    borderRadius: 10,
    backgroundColor: "white",
    width: "95%",
    marginLeft: 20,
  };
  const predictionsStyle = { paddingTop: placePredictions.length > 0 ? 10 : 0 };

  return (
    <div style={containerStyle}>
      <TextBox
        value={value}
        placeholder={placeholder}
        showClearButton={true}
        onValueChanged={(e) => onChange(e.value)}
        onInput={(e) => {
          setIsListVisible(true);
          getPlacePredictions({ input: e.event.currentTarget.value });
        }}
      />
      {placePredictions.length > 0 &&
      !isPlacePredictionsLoading &&
      isListVisible ? (
        <div style={predictionsStyle}>
          <List
            dataSource={placePredictions}
            displayExpr={"description"}
            onItemClick={(e) => {
              setIsListVisible(false);
              placesService?.getDetails(
                {
                  placeId: e.itemData.place_id,
                },
                (placeDetails) => {
                  onPlaceSelected(placeDetails);
                },
              );
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
