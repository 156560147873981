import React, { useRef } from "react";

import DxDataGrid, {
  Column,
  FilterRow,
  Lookup,
  Pager,
  Paging,
  Selection,
  Sorting,
} from "devextreme-react/data-grid";
import LoadPanel from "devextreme-react/load-panel";

import Layout from "./components/Layout";
import { getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { useRecambiosProvider } from "../RecambiosProvider";

const allowedPageSizes = [20, 50, 100];

const elementAttr = { id: "dxDataGrid_Recambios" };

const position = {
  my: "center",
  at: "center",
  of: `#${elementAttr.id}`,
};

const DataGrid = ({ buscadorRef, onSelectionChanged }) => {
  const { tblRecambio, tblProveedor, refreshData, isLoading } =
    useRecambiosProvider();

  const dataGridRef = useRef(null);

  const editorOptions = {
    idProveedor: {
      searchTimeout: 0,
    },
  };

  const onClick_nuevoRecambio = () => onSelectionChanged(null);

  const onRowPrepared = (e) => {
    if (e.rowType === "data") {
      e.rowElement.addClass("pointer");
    }
  };

  const this_onSelectionChanged = ({ component, selectedRowsData }) => {
    component.clearSelection();

    const recambio = selectedRowsData?.[0];

    if (recambio) {
      onSelectionChanged(recambio);
    }
  };

  return (
    <Layout
      buscadorRef={buscadorRef}
      dataGridRef={dataGridRef}
      refreshData={refreshData}
      onClick_nuevoRecambio={onClick_nuevoRecambio}
    >
      <LoadPanel visible={isLoading} position={position} />
      <DxDataGrid
        ref={dataGridRef}
        elementAttr={elementAttr}
        dataSource={tblRecambio}
        keyExpr={"idRecambio"}
        height={"100%"}
        width={"100%"}
        allowColumnReordering
        columnsAutoWidth
        hoverStateEnabled
        rowAlternationEnabled
        showRowLines
        showColumnLines={false}
        onRowPrepared={onRowPrepared}
        onSelectionChanged={this_onSelectionChanged}
      >
        <FilterRow visible />
        <Pager showPageSizeSelector allowedPageSizes={allowedPageSizes} />
        <Paging defaultPageSize={50} />
        <Selection mode={"single"} />
        <Sorting mode={"multiple"} />
        <Column
          dataField={"referenciaInterna"}
          caption={getTrad("referenciaInterna")}
          width={160}
          sortOrder={"asc"}
          allowHeaderFiltering={false}
        />
        <Column
          dataField={"referencia"}
          caption={getTrad("referencia")}
          width={150}
          allowHeaderFiltering={false}
        />
        <Column
          dataField={"denominacion"}
          caption={getTrad("denominacion")}
          allowHeaderFiltering={false}
        />
        <Column
          dataField={"descripcionArticulo"}
          caption={getTrad("descripcionArticulo")}
          allowHeaderFiltering={false}
        />
        <Column
          dataField={"idProveedor"}
          caption={getTrad("fabricante")}
          width={150}
          editorOptions={editorOptions.idProveedor}
        >
          <Lookup
            dataSource={tblProveedor}
            valueExpr={"idProveedor"}
            displayExpr={"nombreComercial"}
          />
        </Column>
        <Column
          width={30}
          cssClass={"p-0"}
          fixed
          fixedPosition={"right"}
          cellComponent={removeCellComponent}
        />
      </DxDataGrid>
    </Layout>
  );
};

export default DataGrid;
