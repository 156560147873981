import { Component } from "react";
import { connect } from "react-redux";

import { errorHandler, authHeader, getTrad, capitalize, dxMensajePregunta, patchRequestHandler } from "helpers";
import "./style.scss";

import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";

import SelectorDenoPrenda from "components/SelectorDenoPrenda";
import SelectorCategoriaMaquina from "components/SelectorCategoriaMaquina";

import { custom_displayExpr } from "pages/Administracion/shared/functions";

import validationEngine from "devextreme/ui/validation_engine";
import Validator, { RequiredRule } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";

import { Autocomplete, Button, List, SelectBox } from "devextreme-react";
import DataGrid, {
    Column,
    CustomRule,
    Editing,
    FilterRow,
    Lookup,
    Pager,
    SearchPanel,
} from "devextreme-react/data-grid";
import Box, { Item as ItemBox } from "devextreme-react/box";
import TextBox, { Button as TextButton } from "devextreme-react/text-box";
import Popup, { ToolbarItem as PopupToolbarItem } from "devextreme-react/popup";
import MultiView, { Item as MultiViewItem } from "devextreme-react/multi-view";
import { DropDownOptions } from "devextreme-react/select-box";

import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";

let prefix_options_default = {
    elementAttr: { class: "prefix" },
    text: "[Deno prenda]",
};

let validations = [];

class GestorArticulos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditorVisible: false,
            tipoArticulo: null,
            selectorVal: null,
            articulo: {},
            prefijo: { ...prefix_options_default },
            selectedIndex: 0,
            tblArticulo: [],
            tblArticulo_filtered: [],
            tblAdmTipoArticulo: [],
            tblAdmTipoArticulo_dxList: [],

            tblCuentaContableCompra: [],
            tblCuentaContableVenta: [],
        };

        this.isMainScreen = props.isMainScreen ?? true;
    }

    // #region Lifecycle

    componentDidMount() {
        this.datasource_Articulo.reload();
        this.datasource_tblAdmTipoArticulo.load().then((data) => {
            this.setState({
                tblAdmTipoArticulo: data,
                tblAdmTipoArticulo_dxList: data.filter((x) => x.idAdmTipoArticulo === 2 || x.idAdmTipoArticulo === 3),
            });
        });
        this.datasource_tblAdmCuentaContable.load().done((data) => {
            this.setState({
                tblCuentaContableCompra: data.filter((x) => x.codigo[0] === "6"),
                tblCuentaContableVenta: data.filter((x) => x.codigo[0] === "7"),
            });
        });
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.tipoArticulos !== this.props.tipoArticulos) {
    //         const { tblArticulo } = this.state;
    //         let filter = [this.props.tipoArticulos, 4]; // Filtrar tipo articulo seleccionado y libre
    //         this.setState({ tblArticulo_filtered: tblArticulo.filter((x) => filter.includes(x.tipoArticulo)) });
    //     }
    // }

    // #endregion

    // #region Datasource

    store_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblAdmTipoArticulo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmTipoArticulo",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.store_beforeSend(request),
            version: 4,
        }),
        select: ["idAdmTipoArticulo", "denominacion", "idAdmCuentaContableCompra", "idAdmCuentaContableVenta"],
    });

    datasource_tblAdmCuentaContable = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaContable",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.store_beforeSend(request),
            version: 4,
        }),
        select: ["idAdmCuentaContable", "codigo", "denominacion"],
        filter: [["codigo", "startsWith", "6"], "or", ["codigo", "startsWith", "7"]],
    });

    datasource_Articulo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Administracion/Articulo",
            key: ["tipoArticulo", "idArticulo"],
            keyType: {
                tipoArticulo: "Int32",
                idArticulo: "Int32",
            },
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_Articulo_beforeSend(request),
            // onLoading: (loadOptions) => this.datasource_Articulo_onLoading(loadOptions),
            version: 4,
        }),
        postProcess: (item) => this.datasource_Articulo_postProcess(item),
    });

    datasource_Articulo_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        if (request.method === "get") {
            if (this.props.isCompra || this.props.isCompra == null) request.params.enableArticulosLibres = true;
        }
    };

    datasource_Articulo_postProcess = (data) => {
        // let tblArticulo_filtered = [];

        // if (this.props.tipoArticulos != null) {
        //     let filter = [this.props.tipoArticulos, 4]; // Filtrar tipo articulo seleccionado y libre
        //     tblArticulo_filtered = data.filter((x) => filter.includes(x.tipoArticulo));
        // }

        this.setState({ tblArticulo: data /* tblArticulo_filtered */ });
        return data;
    };

    // #endregion

    
    context_articulos = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/Administracion/Articulos/",
        entities: {
            UpdateTablasSAP: {},
        },
        errorHandler,
        beforeSend: (request) => (request.headers = { ...authHeader() }),
    });

    render() {
        const {
            isEditorVisible,
            tipoArticulo,
            selectorVal,
            articulo,
            selectedIndex,
            tblArticulo,
            tblCuentaContableCompra,
            tblCuentaContableVenta,
        } = this.state;
        return (
            <>
                <DataGrid
                    dataSource={tblArticulo}
                    remoteOperations={false}
                    height={this.isMainScreen ? "100%" : 520}
                    width={"100%"}
                    showColumnLines={false}
                    showRowLines={true}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowClick={this.props.onRowClick}
                    onCellClick={this.onCellClick}
                    onRowPrepared={this.onRowPrepared}
                    hoverStateEnabled={true}
                >
                    <SearchPanel visible={true} />
                    <FilterRow visible={true} />
                    {this.isMainScreen && <Pager showPageSizeSelector={true} showInfo={true} />}
                    {this.isMainScreen && (
                        <Column
                            name={"edit"}
                            caption={" "}
                            width={30}
                            alignment={"center"}
                            cssClass={"p-0"}
                            cellComponent={this.dxDataGrid_cellComponent_edit}
                        />
                    )}
                    <Column dataField={"idArticulo"} allowEditing={false} visible={false} />
                    <Column dataField={"idDenoPrenda"} allowEditing={false} visible={false} />
                    <Column dataField={"idCategoriaMaquina"} allowEditing={false} visible={false} />
                    <Column
                        dataField={"tipoArticulo"}
                        caption={getTrad("tipoArticulo")}
                        alignment={"center"}
                        dataType={"string"}
                        width={150}
                        cellComponent={this.dxDataGrid_cellComponent_tipoArticulo}
                        allowEditing={false}
                    >
                        <Lookup
                            dataSource={this.datasource_tblAdmTipoArticulo.items()}
                            valueExpr={"idAdmTipoArticulo"}
                            displayExpr={"denominacion"}
                        />
                    </Column>
                    <Column
                        dataField={"codigo"}
                        caption={getTrad("codigo")}
                        alignment={"center"}
                        dataType={"string"}
                        width={125}
                        allowEditing={false}
                    />
                    <Column
                        dataField={"denominacion"}
                        caption={getTrad("denominacion")}
                        dataType={"string"}
                        allowEditing={false}
                    />
                    {(this.props.isCompra == null || this.props.isCompra === true) && (
                        <Column
                            dataField={"idAdmCuentaContableCompra"}
                            caption={getTrad("cuentaContableCompras")}
                            alignment={"left"}
                            dataType={"string"}
                        >
                            <Lookup
                                dataSource={tblCuentaContableCompra}
                                valueExpr={"idAdmCuentaContable"}
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                    )}
                    {(this.props.isCompra == null || this.props.isCompra === false) && (
                        <Column
                            dataField={"idAdmCuentaContableVenta"}
                            caption={getTrad("cuentaContableVentas")}
                            alignment={"left"}
                            dataType={"string"}
                        >
                            <Lookup
                                dataSource={tblCuentaContableVenta}
                                valueExpr={"idAdmCuentaContable"}
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                    )}
                    {this.isMainScreen && (
                        <Column
                            name={"delete"}
                            caption={" "}
                            width={30}
                            alignment={"center"}
                            cssClass={"p-0"}
                            cellComponent={this.dxDataGrid_cellComponent_delete}
                        />
                    )}
                </DataGrid>
                <Popup
                    height={selectedIndex === 0 ? 312 : 500}
                    width={selectedIndex !== 1 ? 500 : 940}
                    minWidth={300}
                    visible={isEditorVisible}
                    dragEnabled={false}
                    onHiding={this.dxPopup_onHiding}
                    onHidden={this.dxPopup_onHidden}
                    title={getTrad("añadirArticulo")}
                    deferRendering={false}
                >
                    <div>
                        <MultiView
                            selectedIndex={selectedIndex}
                            animationEnabled={false}
                            swipeEnabled={false}
                            deferRendering={false}
                        >
                            <MultiViewItem>
                                <Box direction="col">
                                    <ItemBox baseSize={39}>
                                        <div className="font-size">{getTrad("tipoArticulo")}</div>
                                    </ItemBox>
                                    <ItemBox baseSize={181}>
                                        <List
                                            visible={selectedIndex === 0}
                                            selectedItems={[]}
                                            items={this.state.tblAdmTipoArticulo_dxList}
                                            valueExpr={"idAdmTipoArticulo"}
                                            displayExpr={"denominacion"}
                                            itemRender={this.dxList_itemRender}
                                            onItemClick={this.dxList_onItemClick}
                                        />
                                    </ItemBox>
                                </Box>
                            </MultiViewItem>
                            <MultiViewItem>
                                <Box direction="col" height={343}>
                                    <ItemBox ratio={1}>
                                        <div className="he-100">
                                            {tipoArticulo === 2 ? (
                                                <SelectorDenoPrenda
                                                    isRequired={true}
                                                    validateGroup={"articulo"}
                                                    denoPrendaSel={selectorVal}
                                                    onSelectionChanged={this.SelectorDenoPrenda_onSelectionChanged}
                                                />
                                            ) : (
                                                <SelectorCategoriaMaquina
                                                    isRequired={true}
                                                    validateGroup={"articulo"}
                                                    tipoPrendaSel={selectorVal}
                                                    onSelectionChanged={
                                                        this.SelectorCategoriaMaquina_onSelectionChanged
                                                    }
                                                />
                                            )}
                                        </div>
                                    </ItemBox>
                                </Box>
                            </MultiViewItem>
                            <MultiViewItem>
                                <Box direction="col" className="justify-content-center">
                                    <ItemBox baseSize={25}>
                                        <div className="font-size-md font-weight-bold text-secondary">
                                            {getTrad("denominacion")}
                                        </div>
                                    </ItemBox>
                                    <ItemBox baseSize={45}>
                                        <TextBox
                                            dataSource={tblArticulo}
                                            valueExpr={"denominacion"}
                                            className="w-100"
                                            value={articulo.denominacion}
                                            stylingMode="underlined"
                                            onValueChanged={this.dxTextBox_onValueChanged}
                                            readOnly={this.isMainScreen && articulo.idArticulo != null}
                                        >
                                            <Validator validationGroup="articulo">
                                                {tipoArticulo === 3 && <RequiredRule />}
                                                <CustomRule
                                                    ignoreEmptyValue={false}
                                                    message={getTrad("denoArticuloRepetido")}
                                                    validationCallback={this.dxTextBox_CustomRule_validationCallback}
                                                />
                                            </Validator>
                                            {tipoArticulo === 2 && (
                                                <TextButton
                                                    name={"prefix"}
                                                    location={"before"}
                                                    options={this.state.prefijo}
                                                />
                                            )}
                                        </TextBox>
                                    </ItemBox>
                                    <ItemBox baseSize={20}></ItemBox>
                                    <ItemBox baseSize={25}>
                                        <div className="font-size-md font-weight-bold text-secondary">
                                            {getTrad("cuentaContableCompras")}
                                        </div>
                                    </ItemBox>
                                    <ItemBox baseSize={45}>
                                        <SelectBox
                                            name="idAdmCuentaContableCompra"
                                            dataSource={tblCuentaContableCompra}
                                            value={articulo.idAdmCuentaContableCompra}
                                            valueExpr={"idAdmCuentaContable"}
                                            displayExpr={custom_displayExpr}
                                            searchEnabled={true}
                                            onValueChanged={this.dxSelectBox_onValueChanged}
                                            stylingMode="underlined"
                                        >
                                            <Validator validationGroup="articulo">
                                                <RequiredRule message={getTrad("campoObligatorio")} />
                                            </Validator>
                                            <DropDownOptions minWidth={450} resizeEnabled={true} />
                                        </SelectBox>
                                    </ItemBox>
                                    <ItemBox baseSize={20}></ItemBox>
                                    <ItemBox baseSize={25}>
                                        <div className="font-size-md font-weight-bold text-secondary">
                                            {getTrad("cuentaContableVentas")}
                                        </div>
                                    </ItemBox>
                                    <ItemBox baseSize={45}>
                                        <SelectBox
                                            name="idAdmCuentaContableVenta"
                                            dataSource={tblCuentaContableVenta}
                                            value={articulo.idAdmCuentaContableVenta}
                                            valueExpr={"idAdmCuentaContable"}
                                            displayExpr={custom_displayExpr}
                                            searchEnabled={true}
                                            onValueChanged={this.dxSelectBox_onValueChanged}
                                            stylingMode="underlined"
                                        >
                                            <Validator validationGroup="articulo">
                                                <RequiredRule message={getTrad("campoObligatorio")} />
                                            </Validator>
                                            <DropDownOptions minWidth={450} resizeEnabled={true} />
                                        </SelectBox>
                                    </ItemBox>
                                </Box>
                            </MultiViewItem>
                        </MultiView>
                    </div>
                    {!this.isMainScreen && selectedIndex !== 0 && (
                        <PopupToolbarItem location={"before"} toolbar={"bottom"}>
                            <Button
                                text={getTrad("atras")}
                                stylingMode="contained"
                                icon="chevronleft"
                                onClick={this.irAtras}
                            />
                        </PopupToolbarItem>
                    )}
                    {selectedIndex === 2 &&
                        (articulo.idArticulo == null ? (
                            <PopupToolbarItem location={"after"} toolbar={"bottom"}>
                                <Button
                                    text={getTrad("añadirArticulo")}
                                    type="success"
                                    stylingMode="contained"
                                    onClick={this.crearArticulo}
                                />
                            </PopupToolbarItem>
                        ) : (
                            <PopupToolbarItem location={"after"} toolbar={"bottom"}>
                                <Button
                                    text={getTrad("modificarArticulo")}
                                    type="success"
                                    stylingMode="contained"
                                    onClick={this.editarArticulo}
                                />
                            </PopupToolbarItem>
                        ))}
                    {selectedIndex === 2 && (
                        <PopupToolbarItem location={"after"} toolbar={"bottom"}>
                            <Button text={getTrad("cancelar")} onClick={this.dxPopup_onHiding} />
                        </PopupToolbarItem>
                    )}
                </Popup>
            </>
        );
    }

    // #region dxDataGrid

    onToolbarPreparing = ({ toolbarOptions, component }) => {
        const { user } = this.props;

        toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                visible:
                    [idsUsuario.silverioLaraudogoitia, idsUsuario.jorgeGarcia, idsUsuario.maribelMoranta].includes(user.idUsuario)
                    || [idsCargo.desarrollador].includes(user.idCargo),
                options: {
                    text: getTrad("actualizarDatosSAP"),
                    onClick: () => {
                        component.beginCustomLoading()
                        this.context_articulos
                            .invoke("UpdateTablasSAP")
                            .done(() => {
                                this.datasource_Articulo.reload().done(() => {
                                    component.endCustomLoading()
                                    notify({
                                        message: getTrad("registrosActualizadosCorrectamente"),
                                        type: "success",
                                        displayTime: 1500,
                                        closeOnClick: true,
                                    })
                                })
                            })
                            .catch(() => {
                                component.endCustomLoading()
                                notify({
                                    message: getTrad("errorActualizarRegistros"),
                                    type: "error",
                                    displayTime: 1500,
                                    closeOnClick: true,
                                })
                            })
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "plus",
                    text: getTrad("añadirArticulo"),
                    onClick: () => this.setState({ isEditorVisible: true }),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                locateInMenu: "auto",
                options: {
                    text: getTrad("exportar"),
                    icon: "exportxlsx",
                    onClick: ()=> component.exportToExcel(),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onClick: () => {
                        this.datasource_Articulo.reload();
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: () => {
                        component.clearFilter();
                    },
                },
            }
        );
    };

    onCellClick = (e) => {
        if (e.rowType === "data" && e.column.name === "edit") {
            let articulo = e.data;

            this.setState({
                isEditorVisible: true,
                selectedIndex: 2,
                selectorVal:
                    e.data.tipoArticulo === 2
                        ? e.data.idDenoPrenda
                        : e.data.tipoArticulo === 3
                        ? e.data.idCategoriaMaquina
                        : null,
                articulo: articulo,
                tipoArticulo: e.data.tipoArticulo,
            });
        }
        if (e.rowType === "data" && e.column.name === "delete") {
            dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
                [
                    getTrad("aceptar"),
                    () =>
                        this.datasource_Articulo
                            .store()
                            .remove({ tipoArticulo: e.data.tipoArticulo, idArticulo: e.data.idArticulo })
                            .done(() => this.datasource_Articulo.reload()),
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        }
    };

    onRowPrepared = (e) => {
        // Deshabilitar recambios
        if (this.isMainScreen && e.rowType === "data" && e.data.tipoArticulo === 1) {
            e.rowElement.addClass("dx-state-disabled");
        }
    };

    dxDataGrid_cellComponent_tipoArticulo = ({ data }) => {
        let color =
            data.value === 1 // Recambios
                ? "default"
                : data.value === 2 // Lenceria
                ? "success"
                : data.value === 3 // Maquinaria
                ? "warning"
                : "bg-info";
        return <span className={`chip ${color} text-white`}>{capitalize(data.text, true)}</span>;
    };

    dxDataGrid_cellComponent_edit = ({ data }) => {
        return data.data.tipoArticulo === 2 || data.data.tipoArticulo === 3 ? (
            <div id="edit" className="container_spanCentrado pointer">
                <div className="icon_Edit font-size-lg" />
            </div>
        ) : null;
    };

    dxDataGrid_cellComponent_delete = ({ data }) => {
        return data.data.tipoArticulo === 2 || data.data.tipoArticulo === 3 ? (
            <div id="delete" className="container_spanCentrado pointer">
                <div className="icon_Cerrar font-size-lg" />
            </div>
        ) : null;
    };

    // #endregion

    // #region Popup

    irAtras = () => {
        let articulo = { ...this.state.articulo };
        articulo.idDenoPrenda = null;
        articulo.idCategoriaMaquina = null;
        articulo.denominacion = null;
        this.setState({ selectedIndex: this.state.selectedIndex - 1, selectorVal: null, articulo });
    };

    crearArticulo = (e) => {
        let validation = validationEngine.validateGroup("articulo");

        if (validation.isValid) {
            e.component.option("disabled", true);

            let articulo = { ...this.state.articulo };
            if (this.state.tipoArticulo === 2) {
                articulo.denominacion = this.state.prefijo.text + " " + (articulo.denominacion ?? "");
            }

            this.datasource_Articulo
                .store()
                .insert(articulo)
                .then(() => {
                    e.component.option("disabled", false);
                    this.datasource_Articulo.reload();
                    this.dxPopup_onHiding();
                    notify(
                        {
                            message: getTrad("aviso_C_RegistroInsertado"),
                            width: 300,
                        },
                        "success",
                        3000
                    );
                });
        }
    };

    editarArticulo = (e) => {
        let validation = validationEngine.validateGroup("articulo");

        if (validation.isValid) {
            e.component.option("disabled", true);
            let articulo = { ...this.state.articulo };
            if (this.state.tipoArticulo === 2) {
                articulo.denominacion = this.state.prefijo.text + " " + articulo.denominacion;
            }

            let patch = {
                idAdmCuentaContableCompra: articulo.idAdmCuentaContableCompra,
                idAdmCuentaContableVenta: articulo.idAdmCuentaContableVenta,
            };

            this.datasource_Articulo
                .store()
                .update(
                    { tipoArticulo: articulo.tipoArticulo, idArticulo: articulo.idArticulo },
                    patchRequestHandler(patch)
                )
                .then(() => {
                    e.component.option("disabled", false);
                    this.datasource_Articulo.reload();
                    this.dxPopup_onHiding();
                    notify(
                        {
                            message: getTrad("aviso_C_RegistroActualizado"),
                            width: 300,
                        },
                        "success",
                        3000
                    );
                });
        }
    };

    dxPopup_onHiding = () => {
        this.setState({ isEditorVisible: false });
    };

    dxPopup_onHidden = () => {
        this.setState({ selectorVal: null, articulo: {}, prefijo: { ...prefix_options_default }, selectedIndex: 0 });
        validationEngine.resetGroup("articulo");
    };

    // #endregion

    // #region dxList

    dxList_itemRender = (data, index, element) => {
        return (
            <div className="container_spanCentrado" style={{ height: 70 }}>
                <div className="font-size p-0">{data.denominacion.toUpperCase()}</div>
            </div>
        );
    };

    dxList_onItemClick = ({ itemData }) => {
        let articulo = { ...this.state.articulo };

        articulo.tipoArticulo = itemData.idAdmTipoArticulo;
        articulo.idAdmCuentaContableCompra = itemData.idAdmCuentaContableCompra;
        articulo.idAdmCuentaContableVenta = itemData.idAdmCuentaContableVenta;

        this.setState({ articulo, selectedIndex: 1, tipoArticulo: itemData.idAdmTipoArticulo });
    };

    // #endregion

    // #region TextBox

    dxTextBox_onValueChanged = (e) => {
        let articulo = { ...this.state.articulo };
        articulo.denominacion = e.value?.trim().toUpperCase();
        this.setState({ articulo });
    };

    dxTextBox_CustomRule_validationCallback = (e) => {
        const { articulo, tblArticulo, tipoArticulo, prefijo } = this.state;
        // Lenceria
        if (tipoArticulo === 2)
            return (
                tblArticulo.find(
                    (x) =>
                        capitalize(x.denominacion, true) === capitalize(prefijo.text + " " + e.value, true) &&
                        x.idArticulo !== articulo.idArticulo
                ) == null
            );
        else
            return (
                tblArticulo.find(
                    (x) =>
                        capitalize(x.denominacion, true) === capitalize(e.value, true) &&
                        x.idArticulo !== articulo.idArticulo
                ) == null
            );
    };

    // #endregion

    // #region SelectorDenoPrenda

    SelectorDenoPrenda_onSelectionChanged = (data) => {
        let articulo = { ...this.state.articulo };

        const { idioma } = this.props;

        articulo.idDenoPrenda = data.denoPrendaSel.idDenoPrenda;

        let prefijo = { ...this.state.prefijo };
        prefijo.text = data.tipoPrendaSel.tblTraduccion[idioma.codigo];

        if (data.denoPrendaSel.codigo !== "00") {
            prefijo.text += " " + data.denoPrendaSel.tblTraduccion[idioma.codigo];
        }

        articulo.denominacion = articulo.denominacion?.replace(prefijo.text + " ", "");

        this.setState({ articulo, prefijo, selectorVal: data.denoPrendaSel, selectedIndex: 2 });
    };

    SelectorCategoriaMaquina_onSelectionChanged = (data) => {
        let articulo = { ...this.state.articulo };

        articulo.idCategoriaMaquina = data.tipoPrendaSel.idCategoriaMaquina;

        this.setState({ articulo, selectorVal: data.tipoPrendaSel, selectedIndex: 2 });
    };

    // #endregion

    // #region SelectBox

    dxSelectBox_onValueChanged = (e) => {
        const key = e.component.option("name");
        let articulo = { ...this.state.articulo };
        articulo[key] = e.value;
        this.setState({ articulo });
    };

    // #endregion
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

export default connect(mapStateToProps)(GestorArticulos);
