import React, { Fragment } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import { connectionConstants } from "../../constants";
import { errorHandler, authHeader } from "../../helpers";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { TileView } from "devextreme-react/tile-view";
import Box, { Item as ItemBox } from "devextreme-react/box";

import "devextreme/integration/jquery";

//Css
import "./Css.scss";

class AppSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fechaDesde: null,
      fechaHasta: null,
      fechaDesde_redireccion: null,
      fechaHasta_redireccion: null,
      fechaSel: null,
      minDate: null,
      maxDate: null,
      datasource_datos_tblLavanderia: [],
      datasource_datos_tblCompañia: [],
      activeCardItem: null,
      dxTileViewItems: [],
    };

    // REFERENCIAS
    this.dxTileView_REF = React.createRef();

    //DATASOURCE
    this.datasource_datos_tblLavanderia_postProcess =
      this.datasource_datos_tblLavanderia_postProcess.bind(this);
    this.datasource_datos_tblCompañia_beforeSend =
      this.datasource_datos_tblCompañia_beforeSend.bind(this);
    this.datasource_datos_tblCompañia_postProcess =
      this.datasource_datos_tblCompañia_postProcess.bind(this);

    //EVENTOS
    this.dxTileView_itemRender = this.dxTileView_itemRender.bind(this);
    this.dxTileView_onContentReady = this.dxTileView_onContentReady.bind(this);
    this.dxTileView_onItemClick = this.dxTileView_onItemClick.bind(this);
    this.reloadDataSource = this.reloadDataSource.bind(this);
  }

  get dxTileView() {
    return this.dxTileView_REF.current.instance;
  }

  datasource_datos_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    expand: [
      "tblZonaHoraria($select=GMT), tblMoneda($select=codigo), tblMonedaLocal($select=codigo), tblIdioma($select=codigo)",
    ],
    select: [
      "idLavanderia",
      "denominacion",
      "horarioVerano",
      "gestionaRechazo",
      "direccion",
      "telefono",
    ],
    sort: ["denominacion"],
    postProcess: (data) => {
      return this.datasource_datos_tblLavanderia_postProcess(data);
    },
  });

  datasource_datos_tblLavanderia_postProcess(data) {
    $.each(data, function (index, lav) {
      lav.id = lav.idLavanderia;
      lav.tblZonaHoraria.GMT =
        lav.horarioVerano === 1
          ? parseInt(lav.tblZonaHoraria.GMT) + 1
          : lav.tblZonaHoraria.GMT;
    });

    this.setState({ datasource_datos_tblLavanderia: data });

    return data;
  }

  datasource_datos_tblLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_datos_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_tblCompañia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idCompañia", "denominacion"],
    sort: ["denominacion"],
    postProcess: (data) => {
      return this.datasource_datos_tblCompañia_postProcess(data);
    },
  });

  datasource_datos_tblCompañia_postProcess(data) {
    $.each(data, function (index, comp) {
      comp.id = comp.idCompañia;
    });

    this.setState({ datasource_datos_tblCompañia: data });
    return data;
  }

  datasource_datos_tblCompañia_beforeSend(request) {
    let { lavanderia } = this.props;
    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
  }

  //PROPS ACEPTADAS

  render() {
    let { selector, dxTileView_data } = this.props;
    let {
      datasource_datos_tblCompañia,
      datasource_datos_tblLavanderia,
      activeCardItem,
    } = this.state;
    let idSel = activeCardItem ? activeCardItem.id : null;

    //Todas las compañías excepto el idSeleccionado
    let dxTileViewItems =
      selector === "compañia"
        ? $.grep(datasource_datos_tblCompañia, function (item) {
            return item.idCompañia !== idSel;
          })
        : selector === "lavanderia"
          ? datasource_datos_tblLavanderia
          : [];

    return (
      <Fragment>
        <div id="Selector" className="he-100">
          <Box
            direction="row"
            align="space-around"
            crossAlign="stretch"
            width="100%"
            height="100%"
            dxTileViewItems={dxTileViewItems}
            elementAttr={this.dxBoxCardContainer_elementAttr}
          >
            <ItemBox ratio={2} visible={activeCardItem != null}>
              <div style={{ paddingBottom: "5px" }} className="activeCard">
                <div
                  className={
                    "border-tile cardShadow he-100 dxTileView_item_" +
                    (idSel ? idSel : "")
                  }
                  style={{ padding: "0px 2px 2px" }}
                >
                  <Box
                    direction="col"
                    align="space-around"
                    crossAlign="stretch"
                    width="100%"
                    height="100%"
                    elementAttr={this.dxTileView_Box_elementAttr}
                  >
                    <ItemBox baseSize={50}>
                      <div className="cardHeader he-100 d-flex">
                        {activeCardItem != null
                          ? this.props.getContent_header(activeCardItem)
                          : null}
                      </div>
                    </ItemBox>
                    <ItemBox ratio={1}>
                      <div className="dxCard_back">
                        {activeCardItem != null && dxTileView_data
                          ? this.props.getContent_back(idSel)
                          : null}
                      </div>
                    </ItemBox>
                  </Box>
                </div>
              </div>
            </ItemBox>
            <ItemBox ratio={1} visible={dxTileViewItems.length > 0}>
              <TileView
                ref={this.dxTileView_REF}
                elementAttr={this.dxTileView_elementAttr}
                items={dxTileViewItems}
                data={dxTileView_data} // Datos tileView
                height="100%"
                width="100%"
                direction="vertical"
                itemMargin={15}
                showScrollbar={true}
                itemRender={this.dxTileView_itemRender}
                onContentReady={this.dxTileView_onContentReady}
                onItemClick={this.dxTileView_onItemClick}
              />
            </ItemBox>
          </Box>
        </div>
      </Fragment>
    );
  }

  dxTileView_onContentReady(e) {
    if (e.element.height() > 0) {
      var height = e.element.height();
      var width = e.element.width();

      var minSize = this.props.getMinSize();

      var itemMinWidth = minSize.width;
      var itemMinHeight = minSize.height;

      var itemMargin = e.component.option("itemMargin");

      var numCols = Math.floor(width / itemMinWidth);
      var numRows = Math.floor(height / itemMinHeight);

      numCols = numCols < 1 ? 1 : numCols;
      numRows = numRows < 1 ? 1 : numRows;

      var itemWidth =
        Math.floor(width / numCols) - itemMargin - itemMargin / numCols;
      var itemHeight =
        Math.floor(height / numRows) - itemMargin - 5 / (numRows * 2); //El 5 es para que se vea la sombra del último item

      e.component.beginUpdate();
      e.component.option("baseItemWidth", itemWidth);
      e.component.option("baseItemHeight", itemHeight);
      e.component.endUpdate();
    }
  }

  dxTileView_itemRender(itemData) {
    return (
      <div
        className={"cardShadow he-100 dxTileView_item_" + itemData.id}
        style={{ padding: "0px 2px 2px 2px" }}
      >
        <Box
          direction="col"
          align="space-around"
          crossAlign="stretch"
          width="100%"
          height="100%"
          elementAttr={this.dxTileView_Box_elementAttr}
        >
          <ItemBox baseSize={50}>
            <div className="cardHeader he-100 d-flex">
              {this.props.getContent_header(itemData)}
            </div>
          </ItemBox>
          <ItemBox ratio={1}>
            <div className="dxCard_front">
              {this.props.getContent_front(itemData.idCompañia)}
            </div>
          </ItemBox>
        </Box>
      </div>
    );
  }

  dxTileView_onItemClick(e) {
    let _this = this;
    let { activeCardItem } = this.state;

    if (activeCardItem == null) {
      // Primera carga
      setTimeout(function () {
        _this.props.refreshContent_back();
      }, 0);
    }

    this.setState({ activeCardItem: e.itemData });
  }

  cardClick(idEntidad) {
    let { fechaSel } = this.state;
    var params = {
      idEntidad: idEntidad,
      fechaHasta: fechaSel.startDate,
      fechaDesde: fechaSel.endDate,
    };

    this.props.hideSelector(params);
  }

  dxContainer_selector_elementAttr = {
    id: "dxContainer_selector",
  };

  dxBoxCardContainer_elementAttr = {
    id: "dxBox_tileViews",
    class: "cardContainer",
  };

  dxTileView_Box_elementAttr = {
    id: "dxTileView_Box",
  };

  dxTileView_elementAttr = {
    id: "dxTileView",
  };

  reloadDataSource() {
    let _this = this;
    let {
      fechaDesde,
      fechaHasta,
      fechaDesde_redireccion,
      fechaHasta_redireccion,
      selector,
      fechaSel,
    } = this.props;
    let { activeCardItem } = this.state;

    if (selector === "compañia") {
      this.datasource_datos_tblCompañia.reload().done(function (items) {
        let activeCardItemSel = $.grep(items, function (item) {
          return item.idCompañia === (activeCardItem && activeCardItem.id);
        });
        if (
          (items.length <= 4 && items.length > 0) ||
          (activeCardItem != null && activeCardItemSel.length > 0)
        ) {
          let itemSel = items[0];

          let idSel = itemSel ? itemSel.id : null;
          let dxTileViewItems = $.grep(items, function (item) {
            return item.idCompañia !== idSel;
          });

          _this.setState({
            dxTileViewItems: dxTileViewItems,
            activeCardItem:
              activeCardItem && activeCardItemSel.length > 0
                ? activeCardItem
                : itemSel
                  ? itemSel
                  : null,
            fechaDesde: fechaDesde,
            fechaHasta: fechaHasta,
            fechaDesde_redireccion: fechaDesde_redireccion,
            fechaHasta_redireccion: fechaHasta_redireccion,
            fechaSel: fechaSel,
          });

          setTimeout(function () {
            _this.props.refreshContent_back();
          }, 0);
        } else {
          _this.setState({ activeCardItem: null, dxTileViewItems: items });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    let { fechaSel, minDate, maxDate, lavanderia } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.reloadDataSource();
    } else if (fechaSel !== prevProps.fechaSel) {
      let { fechaDesde, fechaHasta, fechaSel } = this.props;

      this.setState({
        fechaDesde: fechaDesde,
        fechaHasta: fechaHasta,
        fechaSel: fechaSel,
      });
    } else {
      this.setState({
        fechaSel: fechaSel,
        minDate: minDate,
        maxDate: maxDate,
      });
    }
  }

  componentDidMount() {
    this.reloadDataSource();
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(AppSelector);
