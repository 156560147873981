import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { history, getAplicaciones, getUrlFormularioInicio } from "../helpers";

import $ from "jquery";
import AppMaster from "../layout/AppMaster";
import { globalActions } from "../actions";

class PrivateRoute extends React.Component {
  render() {
    const {
      component: Component,
      user,
      loggedIn,
      lavanderia,
      aplicacionActiva,
      ...rest
    } = this.props;

    const visiblePages =
      user && user.visiblePages
        ? lavanderia
          ? user.visiblePages.filter((x) =>
              lavanderia.visiblePages.includes(x.idFormulario),
            ) //|| x.idApartado == 21
          : user.visiblePages
        : [];

    let formulario = history.location.pathname.split("/").reverse()[0];
    let apartado = history.location.pathname.split("/").reverse()[1];

    const auth =
      Component &&
      ((apartado === "Inicio" &&
        ("RecursosHumanos" === formulario || "Lavanderia" === formulario)) ||
        formulario === "Notificaciones" ||
        "Informes" === formulario ||
        visiblePages.some((page) => page.formulario === formulario) ||
        formulario === "MiCuenta");

    //#region CHECK FORMULARIO ACTUAL COINCIDE CON SU APP ASOCIADA
    let aplicaciones = getAplicaciones(null);
    let aplicacionInicial =
      user &&
      aplicaciones &&
      user.tblFormularioInicio &&
      user.tblFormularioInicio.tblAplicacion
        ? user.tblFormularioInicio.tblAplicacion
        : user && aplicaciones && user.idAplicacionInicial
          ? aplicaciones.find(
              (x) => x.idAplicacion === user.idAplicacionInicial,
            )
          : user && aplicaciones
            ? aplicaciones[0]
            : null;

    // OBTENER EL FORMULARIO ACTUAL A TRAVÉS DE SU CAMPO TO
    let estructuraMenu = JSON.parse(localStorage.getItem("estructuraMenu"));
    let formulario_obj = $.map(estructuraMenu, function mapper(obj) {
      return obj["idFormulario"] && obj.to === rest.path
        ? obj
        : $.map(obj.content, mapper);
    });

    //FORM SIN idAplicacion -> SE MOSTRARÁ EN TODAS
    let isAplicacionCorrecta =
      aplicacionActiva && formulario_obj.length > 0
        ? aplicacionActiva.idAplicacion == formulario_obj[0].idAplicacion ||
          formulario_obj[0].idAplicacion == null
        : true;

    // FORM SIN APLICACIÓN Y SI LA APP SELECCIONADA NO ESTÁ EN LA LAVANDERIA ACTUAL -> SETEAR APLICACION INICIAL
    if (
      formulario_obj.length > 0 &&
      formulario_obj[0].idAplicacion == null &&
      $.inArray(
        aplicacionActiva.idAplicacion,
        aplicaciones.map((item) => item.idAplicacion),
      ) === -1
    ) {
      let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));
      let lavInicio;
      if (lavanderias.length > 0) {
        if (user.idLavanderiaInicio) {
          lavInicio = lavanderias.find(
            (x) => x.idLavanderia === user.idLavanderiaInicio,
          );
        } else {
          lavInicio = lavanderias[0];
        }
      }
      this.props.setAplicacionActiva(aplicacionInicial, lavInicio);
    }
    //#endregion

    //CHECK INFORMES VISIBLES PARA APP ACTUAL
    let informesVisibles = [];
    $.each(estructuraMenu, function (index, item) {
      if (item.apartado === "Informes") {
        $.each(item.content, function (index2, item2) {
          if (item2.apartado == "InformesOperacionales") {
            $.each(item2.content, function (index3, item3) {
              $.each(item3.content, function (iForm, form) {
                if (form.idAplicacion === aplicacionActiva.idAplicacion)
                  informesVisibles.push(form);
              });
            });
          } else {
            $.each(item2.content, function (index3, item3) {
              if (item3.idAplicacion === aplicacionActiva.idAplicacion)
                informesVisibles.push(item3);
            });
          }
        });
      }
    });

    let redirection = getUrlFormularioInicio(
      lavanderia,
      aplicacionInicial,
      user ? user.tblFormularioInicio : null,
    );
    let informesVacios =
      rest.path == "/Informes" && informesVisibles.length === 0;
    return (
      <Route
        {...rest}
        render={
          (routeProps) =>
            loggedIn ? (
              auth ? (
                isAplicacionCorrecta && !informesVacios ? (
                  <AppMaster>
                    <Component {...routeProps} />
                  </AppMaster>
                ) : (
                  <Redirect
                    to={{
                      pathname: redirection != rest.path ? redirection : "/",
                      state: { from: routeProps.location },
                    }}
                  />
                ) //FORMULARIO EN APLICACIÓN INCORRECTA
              ) : (
                <Redirect
                  to={{ pathname: "/", state: { from: routeProps.location } }}
                />
              ) //SIN PERMISO
            ) : (
              <Redirect
                to={{
                  pathname: "/Login",
                  state: { from: routeProps.location },
                }}
              />
            ) //SIN USUARIO
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
  loggedIn: state.Authentication.loggedIn,
  lavanderia: state.Global.lavanderia,
  aplicacionActiva: state.Global.aplicacionActiva,
});

const mapDispatchToProps = (dispatch) => ({
  setAplicacionActiva: (aplicacion, lavInicio) =>
    dispatch(globalActions.setAplicacionActiva(aplicacion, lavInicio)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
