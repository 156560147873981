import React from "react";
import { getBrightnessColor } from "../../../../helpers";

import { Row, Col } from "reactstrap";

import "./Css.scss";

function getTextColor(color) {
    if (color == null) return "text-muted";
    return getBrightnessColor(color) >= 200 ? "text-muted" : "text-white";
}

function getTextStroke(color) {
    if (color == null) return "text-muted";
    return getBrightnessColor(color) >= 200 ? "text-stroke" : "";
}

const ResumenEstados = (props) => {
    let { data } = props;
    return (
        <div className="gridContainer pl-1 pr-1">
            {data.map((estado) => (
                <Row>
                    <Col key={estado.idEstado} xs="12" className="pr-0">
                        <div
                            style={{
                                "--notificacionColor": estado.colorHexa,
                            }}
                            className="d-flex flex-row mb-3 mr-3 actionToast-container"
                        >
                            <div className="d-flex w-100 ">
                                <div className="toastIconContainer">
                                    <div
                                        className={
                                            "actionToastIcon d-flex align-items-center font-size-xxl mx-auto " +
                                            getTextColor(estado.colorHexa)
                                        }
                                    >
                                        {estado.cantidad}
                                    </div>
                                </div>
                                <div className="d-flex flex-column w-100 justify-content-center font-weight-bold">
                                    <div
                                        className={
                                            "actionToastDescripcion font-size-xxs text-center " +
                                            getTextStroke(estado.colorHexa)
                                        }
                                        style={{ wordBreak: "break-word" }}
                                    >
                                        {estado.denominacion}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ))}
        </div>
    );
};

export default ResumenEstados;
