import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    getTrad,
    formatDateTime,
    getDaysInRange,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ArrayStore from "devextreme/data/array_store";
import ODataContext from "devextreme/data/odata/context";

import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Button, DropDownBox, DateBox, SelectBox, Tooltip } from "devextreme-react";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Pager,
    Paging,
    Scrolling,
    Export,
    Lookup as Lookup_grid,
} from "devextreme-react/data-grid";
import { NumberBox } from "devextreme-react";

import { Popup } from "devextreme-react/popup";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Form, { Item } from "devextreme-react/form";
import { RequiredRule, Validator } from "devextreme-react/validator";

class MovimientosUniformidad extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datos_Prendas: [],
            datos_Estados: [],
            datos_Personas: [],
            mov_fecha: null,
            mov_prendaSel: null,
            mov_estadoSel: null,
            mov_personaSel: null,
            mov_cantidad: null,
            movimientosPendientes: [],
            popupMovimientos_isVisible: false,
            popupMovimientosEdit_isVisible: false,
            mostrarPrendasExtra: false,
            movimientoSel: {},
        };

        // REFERENCIAS
        this.dxDataGrid_selectorPersona_REF = React.createRef();
        this.dxDataGrid_selectorPrenda_REF = React.createRef();
        this.dxDataGrid_movimientos_REF = React.createRef();
        this.dxDataGrid_selectorPersonaEdit_REF = React.createRef();

        //DATASOURCE
        this.datasource_tblMovimiento_beforeSend = this.datasource_tblMovimiento_beforeSend.bind(this);
        this.datasource_tblMovimiento_onLoaded = this.datasource_tblMovimiento_onLoaded.bind(this);
        this.datasource_tblPrenda_beforeSend = this.datasource_tblPrenda_beforeSend.bind(this);
        this.datasource_tblPrenda_onLoaded = this.datasource_tblPrenda_onLoaded.bind(this);
        this.datasource_tblPersona_beforeSend = this.datasource_tblPersona_beforeSend.bind(this);
        this.datasource_tblPersona_onLoaded = this.datasource_tblPersona_onLoaded.bind(this);
        this.datasource_tblEstado_onLoaded = this.datasource_tblEstado_onLoaded.bind(this);
        this.context_tblMovimientos_beforeSend = this.context_tblMovimientos_beforeSend.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();

        //EVENTOS
        this.onToolbarPreparing_tblMovimientos = this.onToolbarPreparing_tblMovimientos.bind(this);
        this.popupMovimientos_onHiding = this.popupMovimientos_onHiding.bind(this);
        this.onValueChanged_dxDateBox_fechaMovimiento = this.onValueChanged_dxDateBox_fechaMovimiento.bind(this);

        this.onValueChanged_cantidad = this.onValueChanged_cantidad.bind(this);

        //Selector prenda
        this.render_selectorPrenda = this.render_selectorPrenda.bind(this);
        this.onValueChanged_dropDownBox_selectorPrenda = this.onValueChanged_dropDownBox_selectorPrenda.bind(this);
        this.onOpened_dropDownBox_selectorPrenda = this.onOpened_dropDownBox_selectorPrenda.bind(this);
        this.onSelectionChanged_dropDownBox_selectorPrenda =
            this.onSelectionChanged_dropDownBox_selectorPrenda.bind(this);
        this.onToolbarPreparing_selectorPrenda = this.onToolbarPreparing_selectorPrenda.bind(this);

        //Selector persona
        this.render_selectorPersona = this.render_selectorPersona.bind(this);
        this.onValueChanged_dropDownBox_selectorPersona = this.onValueChanged_dropDownBox_selectorPersona.bind(this);
        this.onOpened_dropDownBox_selectorPersona = this.onOpened_dropDownBox_selectorPersona.bind(this);
        this.onSelectionChanged_dropDownBox_selectorPersona =
            this.onSelectionChanged_dropDownBox_selectorPersona.bind(this);

        this.onSelectionChanged_selectBox_estado = this.onSelectionChanged_selectBox_estado.bind(this);

        this.onClick_btnAñadirMovimiento = this.onClick_btnAñadirMovimiento.bind(this);
        this.onClick_btnInsertarMovimientos = this.onClick_btnInsertarMovimientos.bind(this);

        //MÉTODOS
        this.context_tblMovimientos_getPayload = this.context_tblMovimientos_getPayload.bind(this);

        //Editar movimientos

        this.getDimensions_popupEdit = this.getDimensions_popupEdit.bind(this);

        //  Render edit

        this.cellRender_tblMovimiento_edit = this.cellRender_tblMovimiento_edit.bind(this);
        this.tblPrenda_edit_onClick = this.tblPrenda_edit_onClick.bind(this);
        this.popupMovimientosEdit_onHiding = this.popupMovimientosEdit_onHiding.bind(this);
        this.onClick_btnEditarMovimientos = this.onClick_btnEditarMovimientos.bind(this);

        this.onOpened_dropDownBox_selectorPersonaEdit = this.onOpened_dropDownBox_selectorPersonaEdit.bind(this);
    }

    // #region REFERENCIAS

    get dxDataGrid_selectorPersona() {
        return this.dxDataGrid_selectorPersona_REF.current.instance;
    }

    get dxDataGrid_selectorPrenda() {
        return this.dxDataGrid_selectorPrenda_REF.current.instance;
    }

    get dxDataGrid_movimientos() {
        return this.dxDataGrid_movimientos_REF.current.instance;
    }

    get dxDataGrid_selectorPersonaEdit() {
        return this.dxDataGrid_selectorPersonaEdit_REF.current.instance;
    }

    // #endregion

    // #region DATASOURCE
    datasource_tblCierreDatos_Uniformidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreDatos_Uniformidad",
            key: "idMovimiento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_tblCierreDatos_Uniformidad_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblCierreDatos_Uniformidad_onLoading(loadOptions),
            onLoaded: (request) => this.datasource_tblCierreDatos_Uniformidad_onLoaded(request),
            version: 4,
        }),
        select: ["idLavanderia", "fechaDesde", "fechaHasta"],
    });
    datasource_tblCierreDatos_Uniformidad_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idPersona = null;
    }
    datasource_tblCierreDatos_Uniformidad_onLoading = (loadOptions) => {
        let { lavanderia } = this.props;
        loadOptions.filter = [
            ["idLavanderia", "=", lavanderia.idLavanderia],
            ["isCerrado", "=", true],
        ];
    };
    datasource_tblCierreDatos_Uniformidad_onLoaded(result) {
        let disabledDates = result.map((cierre) => getDaysInRange(cierre.fechaDesde, cierre.fechaHasta)).flat();
        result = result.map((cierre) => {
            cierre.fechaDesde = new Date(cierre.fechaDesde);
            cierre.fechaDesde.setHours(0, 0, 0, 0);
            cierre.fechaHasta = new Date(cierre.fechaHasta);
            cierre.fechaHasta.setHours(23, 59, 59, 999);
            return cierre;
        });
        this.setState({ tblCierreDatos_Uniformidad: result, disabledDates });
    }

    datasource_tblMovimiento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMovimiento_MyUnif",
            key: "idMovimiento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblMovimiento_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_tblMovimiento_onLoaded(request);
            },
            version: 4,
        }),
        select: ["idMovimiento", "fecha", "cantidad"],
        expand: [
            "tblPrenda_MyUnif($select = idPrenda, barcode, isExtra;$expand=tblTipoPrenda_MyUnif($select=denominacion))",
            "tblpersona_MyUnif($select = idPersona, codigo, nombre, apellidos; $expand = tblDepartamento_MyUnif($select = idDepartamento,denominacion)), tblEstado_MyUnif",
        ],
    });
    datasource_tblMovimiento_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.idPersona = null;
    }
    datasource_tblMovimiento_onLoaded(result) {
        const { tblCierreDatos_Uniformidad } = this.state;

        if (tblCierreDatos_Uniformidad.length > 0) {
            result = result.map((movimiento) => {
                movimiento.isCerrado = tblCierreDatos_Uniformidad.some(
                    (cierre) => cierre.fechaDesde <= movimiento.fecha && movimiento.fecha <= cierre.fechaHasta
                );
                return movimiento;
            });
        }
        this.loadPanel_hide();
    }

    datasource_tblPrenda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrenda_MyUnif",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPrenda_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_tblPrenda_onLoaded(request);
            },
            version: 4,
        }),
        select: ["idPrenda", "barcode", "isExtra"],
        expand: [
            "tblTipoPrenda_MyUnif($select=denominacion)",
            "tblpersona_MyUnif($select = idPersona, codigo, nombre, apellidos; $expand = tblDepartamento_MyUnif($select = idDepartamento,denominacion)), tblEstado_MyUnif",
        ],
    });
    datasource_tblPrenda_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
    }
    datasource_tblPrenda_onLoaded(result) {
        this.setState({ datos_Prendas: result });
    }

    datasource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPersona_MyUnif",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPersona_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_tblPersona_onLoaded(request);
            },
            version: 4,
        }),
        sort: ["codigo"],
        filter: ["idEstadoPersona", "<>", 0],
        expand: ["tblDepartamento_MyUnif($select = idDepartamento,denominacion)"],
    });
    datasource_tblPersona_beforeSend(request) {
        let { lavanderia } = this.props;
        request.headers = { ...authHeader() };
        request.params.idLavanderia = lavanderia.idLavanderia;
    }
    datasource_tblPersona_onLoaded(result) {
        this.setState({ datos_Personas: result });
    }

    datasource_tblEstado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEstado_MyUnif",
            key: "idEstado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstado_beforeSend(request);
            },
            onLoaded: (request) => {
                this.datasource_tblEstado_onLoaded(request);
            },
            version: 4,
        }),
        filter: [
            ["idEstado", "=", 1],
            "or",
            ["idEstado", "=", 2],
            "or",
            ["idEstado", "=", 3],
            "or",
            ["idEstado", "=", 6],
        ],
    });
    datasource_tblEstado_beforeSend(request) {
        request.headers = { ...authHeader() };
    }
    datasource_tblEstado_onLoaded(result) {
        this.setState({ datos_Estados: result });
    }

    datasource_tblEstado_dropDownFilter = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEstado_MyUnif",
            key: "idEstado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstado_dropDownFilter_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_tblEstado_dropDownFilter_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDepartamento_dropDownFilter = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblDepartamento_MyUnif",
            key: "idDepartamento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDepartamento_dropDownFilter_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_tblDepartamento_dropDownFilter_beforeSend(request) {
        let { lavanderia } = this.props;
        request.headers = { ...authHeader() };
        request.params.idLavanderia = lavanderia.idLavanderia;
    }

    context_tblMovimientos = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblMovimiento_MyUnif",
        entities: {
            PostMasivo: {},
        },
        beforeSend: (request) => {
            this.context_tblMovimientos_beforeSend(request);
        },
    });
    context_tblMovimientos_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.payload = this.context_tblMovimientos_getPayload();
    }

    // #endregion

    cargaDatos_lavanderia() {
        this.loadPanel_show();
        this.datasource_tblCierreDatos_Uniformidad.reload().then((result) => {
            this.datasource_tblMovimiento.reload();
            this.datasource_tblPrenda.reload();
            this.datasource_tblPersona.reload();
            this.datasource_tblDepartamento_dropDownFilter.reload();
        });
    }

    render() {
        const {
            datos_Prendas,
            datos_Estados,
            datos_Personas,
            popupMovimientos_isVisible,
            popupMovimientosEdit_isVisible,
            mov_fecha,
            mov_prendaSel,
            mov_estadoSel,
            mov_personaSel,
            mov_cantidad,
            movimientosPendientes,
            mostrarPrendasExtra,
            movimientoSel,
            disabledDates,
        } = this.state;
        const { isMyUniformCantidad } = this.props.lavanderia;

        return (
            <Fragment>
                <PageTitle heading={getNombreFormulario(this)} />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
                    <div id="MovimientosUniformidad" className="formContainer scrollbar-container">
                        <DataGrid
                            ref={this.dxDataGrid_movimientos_REF}
                            //Datos
                            dataSource={this.datasource_tblMovimiento}
                            //Propiedades
                            columnsAutoWidth={true}
                            height={"100%"}
                            width={"100%"}
                            hoverStateEnabled={true}
                            //Estilos
                            showColumnLines={false}
                            showRowLines={true}
                            rowAlternationEnabled={true}
                            onToolbarPreparing={this.onToolbarPreparing_tblMovimientos}
                        >
                            <Export fileName="movimientos_uniformidad" />
                            <SearchPanel visible={true} width={240} searchVisibleColumnsOnly={true} />
                            <HeaderFilter visible={true} />
                            <FilterRow visible={true} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={this.tblMovimientos_allowedPageSizes}
                                showInfo={true}
                            />
                            <Paging defaultPageSize={50} />
                            <Column
                                caption=" "
                                width={40}
                                alignment="center"
                                cssClass="p-0"
                                fixed={true}
                                allowFixing={false}
                                allowHiding={false}
                                allowReordering={false}
                                cellRender={this.cellRender_tblMovimiento_edit}
                            />
                            <Column
                                dataField="fecha"
                                caption={getTrad("fecha")}
                                dataType="date"
                                sortOrder="desc"
                                alignment="center"
                                width={150}
                                format="dd/MM/yyyy"
                                allowHeaderFiltering={false}
                                customizeText={this.fecha_datetime_CustomizeText}
                            />
                            <Column
                                dataField="tblPrenda_MyUnif.barcode"
                                caption={getTrad("codigoPrenda")}
                                alignment="center"
                                width={130}
                                customizeText={function (e) {
                                    if (!e.value) return "-";
                                    return e.valueText;
                                }}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField="tblPrenda_MyUnif.tblTipoPrenda_MyUnif.denominacion"
                                caption={getTrad("prenda")}
                                alignment="left"
                                customizeText={function (e) {
                                    if (!e.value) return "-";
                                    return e.valueText;
                                }}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                dataField="tblPersona_MyUnif.codigo"
                                caption={getTrad("codigoPersona")}
                                alignment="center"
                                width={130}
                                allowHeaderFiltering={false}
                            />
                            <Column
                                caption={getTrad("persona")}
                                calculateCellValue={this.calculateCellValue_nombreCompuesto}
                                calculateFilterExpression={this.calculateFilterExpression_remote_persona}
                                allowHeaderFiltering={false}
                            />
                            <Column dataField="tblPersona_MyUnif.nombre" caption={getTrad("nombre")} visible={false} />
                            <Column
                                dataField="tblPersona_MyUnif.apellidos"
                                caption={getTrad("apellidos")}
                                visible={false}
                            />
                            <Column
                                dataField="tblPersona_MyUnif.tblDepartamento_MyUnif.idDepartamento"
                                caption={getTrad("departamento")}
                                alignment="left"
                                allowHeaderFiltering={false}
                            >
                                <Lookup_grid
                                    dataSource={this.datasource_tblDepartamento_dropDownFilter.items()}
                                    valueExpr={"idDepartamento"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                            <Column
                                dataField="cantidad"
                                visible={isMyUniformCantidad}
                                caption={getTrad("cantidad")}
                                alignment="center"
                                allowFiltering={false}
                                customizeText={function (e) {
                                    if (!e.value) return "-";
                                    return e.valueText;
                                }}
                            />
                            <Column
                                dataField="tblEstado_MyUnif.idEstado"
                                caption={getTrad("estado")}
                                alignment="left"
                                width={150}
                                allowHeaderFiltering={false}
                            >
                                <Lookup_grid
                                    dataSource={this.datasource_tblEstado_dropDownFilter.items()}
                                    valueExpr={"idEstado"}
                                    displayExpr={"denominacion"}
                                />
                            </Column>
                        </DataGrid>

                        <Popup
                            visible={popupMovimientos_isVisible}
                            height="80%"
                            width="85%"
                            showTitle={true}
                            title={getTrad("añadirMovimiento")}
                            deferRendering={false}
                            onHiding={this.popupMovimientos_onHiding}
                        >
                            <Box direction="col" width="100%" height="100%">
                                <ItemBox baseSize={"auto"}>
                                    <Box direction="row" width="100%" height="100%">
                                        <ItemBox baseSize={190}>
                                            <label className="dx-form pl-1">
                                                <span className="dx-field-item-label-text">{getTrad("fecha")}</span>
                                            </label>
                                            <DateBox
                                                className="mr-3"
                                                type="datetime"
                                                useMaskBehavior={true}
                                                value={mov_fecha}
                                                onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                                                displayFormat={this.format_dateTime}
                                                disabledDates={disabledDates}
                                            />
                                        </ItemBox>
                                        <ItemBox baseSize={150}>
                                            <label className="dx-form pl-1">
                                                <span className="dx-field-item-label-text">{getTrad("estado")}</span>
                                            </label>
                                            <SelectBox
                                                className="mr-3"
                                                valueExpr="idEstado"
                                                displayExpr="denominacion"
                                                value={mov_estadoSel ? mov_estadoSel.idEstado : null}
                                                showClearButton={false}
                                                items={datos_Estados}
                                                onSelectionChanged={this.onSelectionChanged_selectBox_estado}
                                            />
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <label className="dx-form">
                                                <span className="dx-field-item-label-text">{getTrad("prenda")}</span>
                                            </label>
                                            <DropDownBox
                                                dataSource={datos_Prendas}
                                                value={mov_prendaSel ? mov_prendaSel.idPrenda : null}
                                                disabled={
                                                    isMyUniformCantidad
                                                        ? mov_estadoSel
                                                            ? this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                            : true
                                                        : false
                                                }
                                                valueExpr="idPrenda"
                                                deferRendering={false}
                                                displayExpr={this.displayExpr_dropDownBox_prenda}
                                                onValueChanged={this.onValueChanged_dropDownBox_selectorPrenda}
                                                onOpened={this.onOpened_dropDownBox_selectorPrenda}
                                                contentRender={this.render_selectorPrenda}
                                                mostrarPrendasExtra={mostrarPrendasExtra}
                                                dropDownOptions={this.dropDownOptions_prenda}
                                            />
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <label className="dx-form pl-1">
                                                <span className="dx-field-item-label-text">{getTrad("persona")}</span>
                                            </label>
                                            <DropDownBox
                                                className="mr-3"
                                                dataSource={datos_Personas}
                                                value={mov_personaSel ? mov_personaSel.idPersona : null}
                                                disabled={
                                                    isMyUniformCantidad
                                                        ? mov_estadoSel
                                                            ? !this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                                                              (mov_prendaSel ? !mov_prendaSel.isExtra : true)
                                                            : true
                                                        : mov_prendaSel
                                                        ? !mov_prendaSel.isExtra
                                                        : true
                                                }
                                                valueExpr="idPersona"
                                                deferRendering={false}
                                                displayExpr={this.displayExpr_dropDownBox_persona}
                                                onValueChanged={this.onValueChanged_dropDownBox_selectorPersona}
                                                onOpened={this.onOpened_dropDownBox_selectorPersona}
                                                contentRender={this.render_selectorPersona}
                                                dropDownOptions={this.dropDownOptions_persona}
                                            />
                                        </ItemBox>
                                        <ItemBox baseSize={80} visible={isMyUniformCantidad}>
                                            <label className="dx-form pl-1">
                                                <span className="dx-field-item-label-text">{getTrad("cantidad")}</span>
                                            </label>
                                            <NumberBox
                                                value={mov_cantidad ? mov_cantidad : 0}
                                                disabled={
                                                    mov_estadoSel
                                                        ? !this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                        : true
                                                }
                                                className="mr-3"
                                                step={0}
                                                min={0}
                                                max={999}
                                                onValueChanged={this.onValueChanged_cantidad}
                                            ></NumberBox>
                                        </ItemBox>
                                        <ItemBox baseSize={120}>
                                            <div className="he-100 d-flex flex-column justify-content-end">
                                                <Button
                                                    text={getTrad("añadir")}
                                                    type="success"
                                                    icon="add"
                                                    onClick={this.onClick_btnAñadirMovimiento}
                                                />
                                            </div>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                                <ItemBox ratio={1}>
                                    <DataGrid
                                        //Datos
                                        dataSource={movimientosPendientes}
                                        //Propiedades
                                        columnsAutoWidth={true}
                                        height={"100%"}
                                        width={"100%"}
                                        hoverStateEnabled={true}
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                    >
                                        <Pager visible={false} />
                                        <Paging enabled={false} />
                                        <Column
                                            dataField="fecha"
                                            caption={getTrad("fecha")}
                                            dataType="datetime"
                                            sortOrder="desc"
                                            alignment="center"
                                            width={150}
                                            format={this.format_dateTime}
                                        />
                                        <Column
                                            dataField="tblPrenda_MyUnif.barcode"
                                            caption={getTrad("codigoPrenda")}
                                            alignment="center"
                                            width={100}
                                            customizeText={function (e) {
                                                if (!e.value) return "-";
                                                return e.valueText;
                                            }}
                                        />
                                        <Column
                                            dataField="tblPrenda_MyUnif.tblTipoPrenda_MyUnif.denominacion"
                                            caption={getTrad("prenda")}
                                            alignment="left"
                                            customizeText={function (e) {
                                                if (!e.value) return "-";
                                                return e.valueText;
                                            }}
                                        />
                                        <Column
                                            dataField="tblPersona_MyUnif.codigo"
                                            caption={getTrad("codigoPersona")}
                                            alignment="center"
                                            width={100}
                                        />
                                        <Column
                                            caption={getTrad("persona")}
                                            calculateCellValue={this.calculateCellValue_nombreCompuesto}
                                        />
                                        <Column
                                            dataField="tblPersona_MyUnif.nombre"
                                            caption={getTrad("nombre")}
                                            visible={false}
                                        />
                                        <Column
                                            dataField="tblPersona_MyUnif.apellidos"
                                            caption={getTrad("apellidos")}
                                            visible={false}
                                        />
                                        <Column
                                            dataField="cantidad"
                                            caption={getTrad("cantidad")}
                                            visible={isMyUniformCantidad}
                                            alignment="center"
                                            customizeText={function (e) {
                                                if (!e.value) return "-";
                                                return e.valueText;
                                            }}
                                        />
                                        <Column
                                            dataField="tblPersona_MyUnif.tblDepartamento_MyUnif.denominacion"
                                            caption={getTrad("departamento")}
                                            alignment="left"
                                        />
                                        <Column
                                            dataField="tblEstado_MyUnif.denominacion"
                                            caption={getTrad("estado")}
                                            alignment="left"
                                            width={150}
                                        />
                                    </DataGrid>
                                </ItemBox>
                                <ItemBox baseSize={50}>
                                    <div className="he-100 d-flex flex-column justify-content-end align-self-end">
                                        <Button
                                            text={getTrad("aceptar")}
                                            type="success"
                                            onClick={this.onClick_btnInsertarMovimientos}
                                        />
                                    </div>
                                </ItemBox>
                            </Box>
                        </Popup>

                        <Popup
                            visible={popupMovimientosEdit_isVisible}
                            height={this.getDimensions_popupEdit}
                            width={this.getDimensions_popupEdit}
                            showTitle={true}
                            title={getTrad("modificarMovimiento")}
                            deferRendering={false}
                            onHiding={this.popupMovimientosEdit_onHiding}
                            screenByWidth={getSizeQualifier}
                        >
                            <form className="he-100" onSubmit={this.onClick_btnEditarMovimientos}>
                                <Box direction="col" width="100%" height="100%">
                                    <ItemBox ratio={1}>
                                        <Form
                                            colCount={2}
                                            formData={movimientoSel}
                                            labelLocation="top"
                                            scrollingEnabled={true}
                                            screenByWidth={this.screenByWidth}
                                            validationGroup="validationGroup_form_movimiento"
                                        >
                                            <Item cssClass={"ml-3 mr-3"}>
                                                <label className="dx-form pl-1">
                                                    <span className="dx-field-item-label-text">{getTrad("fecha")}</span>
                                                </label>
                                                <DateBox
                                                    type="datetime"
                                                    useMaskBehavior={true}
                                                    value={mov_fecha}
                                                    onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                                                    displayFormat={this.format_dateTime}
                                                >
                                                    <Validator validationGroup="validationGroup_form_movimiento">
                                                        <RequiredRule />
                                                    </Validator>
                                                </DateBox>
                                            </Item>
                                            <Item cssClass={"ml-3 mr-3"}>
                                                <label className="dx-form pl-1">
                                                    <span className="dx-field-item-label-text">
                                                        {getTrad("estado")}
                                                    </span>
                                                </label>
                                                <SelectBox
                                                    valueExpr="idEstado"
                                                    displayExpr="denominacion"
                                                    value={mov_estadoSel ? mov_estadoSel.idEstado : null}
                                                    showClearButton={false}
                                                    items={datos_Estados}
                                                    onSelectionChanged={this.onSelectionChanged_selectBox_estado}
                                                >
                                                    <Validator validationGroup="validationGroup_form_movimiento">
                                                        <RequiredRule />
                                                    </Validator>
                                                </SelectBox>
                                            </Item>
                                            <Item cssClass={"ml-3 mr-3"}>
                                                <label className="dx-form">
                                                    <span className="dx-field-item-label-text">
                                                        {getTrad("prenda")}
                                                    </span>
                                                </label>
                                                <DropDownBox
                                                    dataSource={datos_Prendas}
                                                    value={mov_prendaSel ? mov_prendaSel.idPrenda : null}
                                                    disabled={
                                                        isMyUniformCantidad
                                                            ? mov_estadoSel
                                                                ? this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                                : true
                                                            : false
                                                    }
                                                    valueExpr="idPrenda"
                                                    deferRendering={false}
                                                    displayExpr={this.displayExpr_dropDownBox_prenda}
                                                    onValueChanged={this.onValueChanged_dropDownBox_selectorPrenda}
                                                    contentRender={this.render_selectorPrenda}
                                                    mostrarPrendasExtra={mostrarPrendasExtra}
                                                    dropDownOptions={this.dropDownOptions_prenda}
                                                >
                                                    <Validator validationGroup="validationGroup_form_movimiento">
                                                        {(
                                                            isMyUniformCantidad
                                                                ? mov_estadoSel
                                                                    ? this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                                    : true
                                                                : false
                                                        ) ? null : (
                                                            <RequiredRule />
                                                        )}
                                                    </Validator>
                                                </DropDownBox>
                                            </Item>
                                            <Item cssClass={"ml-3 mr-3"}>
                                                <label className="dx-form pl-1">
                                                    <span className="dx-field-item-label-text">
                                                        {getTrad("persona")}
                                                    </span>
                                                </label>
                                                <DropDownBox
                                                    dataSource={datos_Personas}
                                                    value={mov_personaSel ? mov_personaSel.idPersona : null}
                                                    disabled={
                                                        isMyUniformCantidad
                                                            ? mov_estadoSel
                                                                ? !this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                                                                  (mov_prendaSel ? !mov_prendaSel.isExtra : true)
                                                                : true
                                                            : mov_prendaSel
                                                            ? !mov_prendaSel.isExtra
                                                            : true
                                                    }
                                                    valueExpr="idPersona"
                                                    deferRendering={false}
                                                    displayExpr={this.displayExpr_dropDownBox_persona}
                                                    onValueChanged={this.onValueChanged_dropDownBox_selectorPersona}
                                                    onOpened={this.onOpened_dropDownBox_selectorPersonaEdit}
                                                    contentRender={this.render_selectorPersona}
                                                    dropDownOptions={this.dropDownOptions_persona}
                                                >
                                                    <Validator validationGroup="validationGroup_form_movimiento">
                                                        {(
                                                            isMyUniformCantidad
                                                                ? mov_estadoSel
                                                                    ? !this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                                                                      (mov_prendaSel ? !mov_prendaSel.isExtra : true)
                                                                    : true
                                                                : mov_prendaSel
                                                                ? !mov_prendaSel.isExtra
                                                                : true
                                                        ) ? null : (
                                                            <RequiredRule />
                                                        )}
                                                    </Validator>
                                                </DropDownBox>
                                            </Item>
                                            <Item cssClass={"ml-3 mr-3"}>
                                                <label className="dx-form pl-1">
                                                    <span className="dx-field-item-label-text">
                                                        {getTrad("cantidad")}
                                                    </span>
                                                </label>
                                                <NumberBox
                                                    value={mov_cantidad}
                                                    disabled={
                                                        mov_estadoSel
                                                            ? !this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                            : true
                                                    }
                                                    step={0}
                                                    min={1}
                                                    max={999}
                                                    onValueChanged={this.onValueChanged_cantidad}
                                                    placeholder={"0"}
                                                >
                                                    <Validator validationGroup="validationGroup_form_movimiento">
                                                        {(
                                                            mov_estadoSel
                                                                ? !this.isEstadoCantidad(mov_estadoSel.idEstado)
                                                                : true
                                                        ) ? null : (
                                                            <RequiredRule />
                                                        )}
                                                    </Validator>
                                                </NumberBox>
                                            </Item>
                                        </Form>
                                    </ItemBox>
                                    <ItemBox baseSize={50}>
                                        <Toolbar>
                                            <ToolbarItem location="after" toolbar="bottom">
                                                <div className="he-100 d-flex flex-column justify-content-end align-self-end">
                                                    <Button
                                                        width={200}
                                                        text={getTrad("guardar")}
                                                        type="success"
                                                        validationGroup="validationGroup_form_movimiento"
                                                        useSubmitBehavior={true}
                                                    />
                                                </div>
                                            </ToolbarItem>
                                        </Toolbar>
                                    </ItemBox>
                                </Box>
                            </form>
                        </Popup>
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    getDimensions_popupEdit() {
        const width = window.innerWidth;

        if (width <= 600) {
            return "75%";
        } else if (width > 992) {
            return "50%";
        } else if (width > 768) {
            return "60%";
        } else if (width > 600) {
            return "65%";
        }
        return "50%";
    }

    screenByWidth(width) {
        return width >= 1200 ? "xl" : width >= 992 ? "lg" : width >= 768 ? "md" : width >= 576 ? "sm" : "xs";
    }

    cellRender_tblMovimiento_edit(e) {
        const { data } = e;
        const isMovimientoEditable = !(
            data.tblEstado_MyUnif.idEstado !== 1 &&
            data.tblEstado_MyUnif.idEstado !== 2 &&
            data.tblEstado_MyUnif.idEstado !== 3 &&
            data.tblEstado_MyUnif.idEstado !== 6
        );

        return data.isCerrado ? (
            <div className="container_spanCentrado pointer">
                <div className="icon_lockOutline font-size-xl iconoEditMovimiento" />
            </div>
        ) : (
            <div
                id={"editButton_" + data.idMovimiento}
                className="container_spanCentrado pointer"
                onMouseDown={isMovimientoEditable ? () => this.tblPrenda_edit_onClick(data) : undefined}
            >
                <div
                    style={{ opacity: isMovimientoEditable ? 1 : 0.5 }}
                    className="icon_Edit font-size-xl iconoEditMovimiento"
                />
                {isMovimientoEditable ? null : (
                    <Tooltip
                        target={"#editButton_" + data.idMovimiento}
                        showEvent="dxhoverstart"
                        hideEvent="dxhoverend"
                    >
                        <div>{getTrad("movimientoNoModificable")}</div>
                    </Tooltip>
                )}
            </div>
        );
    }

    tblPrenda_edit_onClick(rowData) {
        const movimientoSel = rowData;

        const state = {
            movimientoSel,
            popupMovimientosEdit_isVisible: true,
            mov_fecha: movimientoSel.fecha,
            mov_prendaSel: movimientoSel.tblPrenda_MyUnif,
            mov_estadoSel: movimientoSel.tblEstado_MyUnif,
            mov_cantidad: movimientoSel.cantidad,
            mov_personaSel: movimientoSel.tblPersona_MyUnif,
        };

        this.setState(state);
    }

    popupMovimientosEdit_onHiding() {
        this.setState({ popupMovimientosEdit_isVisible: false });
    }

    onOpened_dropDownBox_selectorPersonaEdit(e) {
        this.dxDataGrid_selectorPersonaEdit.option("searchPanel.text", "");
    }

    onClick_btnEditarMovimientos(e) {
        const { movimientoSel, mov_fecha, mov_prendaSel, mov_estadoSel, mov_personaSel, mov_cantidad } = this.state;
        const { isMyUniformCantidad } = this.props.lavanderia;

        let _this = this;

        e.preventDefault();

        if (
            mov_fecha != null &&
            mov_estadoSel != null &&
            ((!isMyUniformCantidad && mov_prendaSel != null && mov_personaSel != null) ||
                (isMyUniformCantidad &&
                    ((this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                        mov_personaSel != null &&
                        mov_cantidad != null) ||
                        (!this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                            mov_prendaSel != null &&
                            mov_personaSel != null))))
        ) {
            const movimientoMod = {
                ...movimientoSel,
                cantidad: mov_cantidad,
                fecha: mov_fecha,
                tblEstado_MyUnif: mov_estadoSel,
                tblPersona_MyUnif: mov_personaSel,
                tblPrenda_MyUnif: mov_prendaSel,
            };

            let update = {};

            if (JSON.stringify(movimientoSel) !== JSON.stringify(movimientoMod)) {
                if (JSON.stringify(movimientoSel.tblPrenda_MyUnif) !== JSON.stringify(movimientoMod.tblPrenda_MyUnif)) {
                    if (movimientoMod.tblPrenda_MyUnif) {
                        update = {
                            ...update,
                            idPrenda: movimientoMod.tblPrenda_MyUnif.idPrenda,
                        };
                    } else {
                        update = { ...update, idPrenda: null };
                    }
                }
                if (JSON.stringify(movimientoSel.tblEstado_MyUnif) !== JSON.stringify(movimientoMod.tblEstado_MyUnif)) {
                    if (movimientoMod.tblEstado_MyUnif) {
                        update = {
                            ...update,
                            idEstado: movimientoMod.tblEstado_MyUnif.idEstado,
                        };
                    } else {
                        update = { ...update, idEstado: null };
                    }
                }
                if (
                    JSON.stringify(movimientoSel.tblPersona_MyUnif) !== JSON.stringify(movimientoMod.tblPersona_MyUnif)
                ) {
                    if (movimientoMod.tblPersona_MyUnif) {
                        update = {
                            ...update,
                            idPersona: movimientoMod.tblPersona_MyUnif.idPersona,
                        };
                    } else {
                        update = { ...update, idPersona: null };
                    }
                }
                if (movimientoSel.fecha !== movimientoMod.fecha) {
                    update = { ...update, fecha: movimientoMod.fecha };
                }
                if (movimientoSel.cantidad !== movimientoMod.cantidad) {
                    update = { ...update, cantidad: movimientoMod.cantidad };
                }
            } else {
                notify({
                    message: getTrad("modificarParaEnviar"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }

            if (Object.keys(update).length > 0) {
                this.datasource_tblMovimiento
                    .store()
                    .update(movimientoSel.idMovimiento, update)
                    .done(function () {
                        _this.datasource_tblMovimiento.reload();
                        _this.setState({ popupMovimientosEdit_isVisible: false });
                    });
            }
        } else {
            notify({
                message: getTrad("alerta_rellenarTodosCampos"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    // #region FORMATOS
    format_dateTime = "dd/MM/yyyy - HH:mm";
    fecha_datetime_CustomizeText = function (args) {
        if (args.value != null) return formatDateTime(args.value);
    };

    // #endregion

    // #region EDITORES
    formLabel_idMoneda = { text: getTrad("moneda") };

    // #endregion

    // #region CONFIG GRID
    grid_single_selection = { mode: "single" };
    tblMovimientos_allowedPageSizes = [20, 50, 100];
    calculateCellValue_nombreCompuesto = function (rowData) {
        if (rowData.tblPersona_MyUnif) {
            return rowData.tblPersona_MyUnif.nombre + " " + rowData.tblPersona_MyUnif.apellidos;
        } else {
            return getTrad("sinAsignar");
        }
    };

    // #endregion

    // #region CONFIG DROPDOWNBOX
    dropDownOptions_prenda = {
        width: "80%",
        height: 500,
        position: { my: "center", at: "center", of: window },
        closeOnOutsideClick: true,
    };

    dropDownOptions_persona = {
        width: 500,
        height: 500,
        position: { my: "center", at: "center", of: window },
        closeOnOutsideClick: true,
    };

    calculateFilterExpression_remote_persona(filterValue, selectedFilterOperation) {
        return ["concat(concat(tblPersona_MyUnif.nombre, ' '), tblPersona_MyUnif.apellidos)", "contains", filterValue];
    }

    calculateFilterExpression_local_persona(filterValue, selectedFilterOperation) {
        return [this.calculateCellValue, "contains", filterValue];
    }

    isEstadoCantidad(idEstado) {
        if (idEstado === 1 || idEstado === 3) return true;
        return false;
    }

    // #endregion

    // #region EVENTOS
    onToolbarPreparing_tblMovimientos(e) {
        let _this = this;
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    text: getTrad("añadirMovimiento"),
                    icon: "add",
                    onClick: function () {
                        _this.setState({
                            mov_fecha: null,
                            mov_prendaSel: null,
                            mov_personaSel: null,
                            mov_estadoSel: null,
                            mov_cantidad: null,
                            mostrarPrendasExtra: false,
                            movimientosPendientes: [],
                            popupMovimientos_isVisible: true,
                        });
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    width: "100%",
                    icon: "exportxlsx",
                    text: getTrad("exportar"),
                    type: "normal",
                    onClick: function () {
                        if (e.component.totalCount() <= 2500) e.component.exportToExcel();
                        else {
                            notify({
                                message: getTrad("alert_maxRegistrosExport_MovUniform"),
                                type: "error",
                                displayTime: "1500",
                                closeOnClick: true,
                            });
                        }
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: function () {
                        e.component.clearFilter();
                        notify({
                            message: getTrad("aviso_C_FiltroRestaurado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onClick: function () {
                        e.component.refresh();
                    },
                },
            }
        );
    }

    // #endregion

    // #region POPUP

    //EVENTOS
    onValueChanged_dxDateBox_fechaMovimiento(e) {
        e.value.setMilliseconds(new Date().getMilliseconds());
        this.setState({ mov_fecha: e.value });
    }

    displayExpr_dropDownBox_prenda(item) {
        return item && item.barcode + " - " + item.tblTipoPrenda_MyUnif.denominacion;
    }
    onValueChanged_dropDownBox_selectorPrenda(e) {
        this.setState({ mov_prendaSel: null });
    }
    onOpened_dropDownBox_selectorPrenda(e) {
        this.dxDataGrid_selectorPrenda.option("searchPanel.text", "");
    }
    render_selectorPrenda = (data) => {
        let { datos_Prendas, mov_prendaSel, mostrarPrendasExtra } = this.state;

        let filterValue = [];
        filterValue.push(["isExtra", "=", mostrarPrendasExtra]);
        if (mov_prendaSel && mov_prendaSel.idPrenda) {
            filterValue.push("and");
            filterValue.push(["idPrenda", "<>", mov_prendaSel.idPrenda]);
        }

        return (
            <DataGrid
                //Datos
                ref={this.dxDataGrid_selectorPrenda_REF}
                dataSource={
                    new DataSource({
                        paginate: false,
                        store: new ArrayStore({
                            data: datos_Prendas,
                        }),
                        filter: [["isExtra", "=", true], "or", ["tblPersona_MyUnif", "<>", null]],
                    })
                }
                //Propiedades
                keyExpr="idPrenda"
                columnsAutoWidth={true}
                height={"100%"}
                width={"100%"}
                hoverStateEnabled={true}
                selection={this.grid_single_selection}
                filterValue={filterValue}
                selectedRowKeys={[]}
                //Eventos
                onSelectionChanged={(e) => {
                    this.onSelectionChanged_dropDownBox_selectorPrenda(e, data.component);
                }}
                onToolbarPreparing={(e) => {
                    this.onToolbarPreparing_selectorPrenda(e);
                }}
                //Estilos
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
            >
                <Scrolling mode="infinite" />
                <SearchPanel visible={true} width={240} searchVisibleColumnsOnly={true} />
                <Pager visible={false} />
                <Column dataField="isExtra" visible={false} />
                <Column dataField="idPrenda" visible={false} />
                <Column dataField="barcode" caption={getTrad("codigoPrenda")} alignment="center" width={100} />
                <Column dataField="tblTipoPrenda_MyUnif.denominacion" caption={getTrad("prenda")} alignment="left" />
                <Column
                    dataField="tblPersona_MyUnif.codigo"
                    caption={getTrad("codigoPersona")}
                    alignment="center"
                    width={100}
                />
                <Column
                    caption={getTrad("persona")}
                    calculateCellValue={this.calculateCellValue_nombreCompuesto}
                    calculateFilterExpression={this.calculateFilterExpression_local_persona}
                />
                <Column dataField="tblPersona_MyUnif.nombre" caption={getTrad("nombre")} visible={false} />
                <Column dataField="tblPersona_MyUnif.apellidos" caption={getTrad("apellidos")} visible={false} />
                <Column
                    dataField="tblPersona_MyUnif.tblDepartamento_MyUnif.denominacion"
                    caption={getTrad("departamento")}
                    alignment="left"
                />
                <Column
                    dataField="tblEstado_MyUnif.denominacion"
                    caption={getTrad("estado")}
                    alignment="left"
                    width={150}
                />
            </DataGrid>
        );
    };
    onSelectionChanged_dropDownBox_selectorPrenda(e, dropdown) {
        let prendaSel = e.selectedRowsData[0];
        let personaSel = null;
        if (prendaSel.tblPersona_MyUnif) {
            personaSel = prendaSel.tblPersona_MyUnif;
        }
        dropdown.close();
        this.setState({ mov_prendaSel: prendaSel, mov_personaSel: personaSel });
    }
    onToolbarPreparing_selectorPrenda(e) {
        let _this = this;
        let { mostrarPrendasExtra } = this.state;

        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxSwitch",
            showText: "always",
            locateInMenu: "auto",
            options: {
                height: "34px",
                switchedOnText: getTrad("extra"),
                switchedOffText: getTrad("normal"),
                elementAttr: {
                    class: "comunSwitch",
                },
                value: mostrarPrendasExtra,
                onValueChanged: function (e) {
                    _this.setState({ mostrarPrendasExtra: e.value });
                },
            },
        });
    }

    // #endregion

    // #region PERSONA
    displayExpr_dropDownBox_persona(item) {
        return item && item.codigo + " - " + item.nombre + " " + item.apellidos;
    }

    onOpened_dropDownBox_selectorPersona(e) {
        this.dxDataGrid_selectorPersona.option("searchPanel.text", "");
    }
    onValueChanged_dropDownBox_selectorPersona(e) {
        this.setState({ mov_personaSel: null });
    }
    onValueChanged_cantidad(e) {
        this.setState({ mov_cantidad: e.value });
    }
    render_selectorPersona = (data) => {
        let { datos_Personas, mov_personaSel } = this.state;
        return (
            <DataGrid
                ref={this.dxDataGrid_selectorPersona_REF}
                //Datos
                dataSource={
                    new DataSource({
                        paginate: false,
                        store: new ArrayStore({
                            data: datos_Personas,
                        }),
                        filter: [["codigo", "<>", ""], "and", ["codigo", "<>", null]],
                    })
                }
                //Propiedades
                keyExpr="idPersona"
                columnsAutoWidth={true}
                height={"100%"}
                width={"100%"}
                hoverStateEnabled={true}
                selectedRowKeys={[]}
                rowAlternationEnabled={true}
                selection={this.tblPersona_selection}
                //Eventos
                filterValue={
                    mov_personaSel && mov_personaSel.idPersona ? ["idPersona", "<>", mov_personaSel.idPersona] : null
                }
                onSelectionChanged={(e) => {
                    this.onSelectionChanged_dropDownBox_selectorPersona(e, data.component);
                }}
                //Estilos
                showColumnLines={false}
                showRowLines={true}
            >
                <Scrolling mode="infinite" />
                <SearchPanel visible={true} width={240} />
                <Pager visible={false} />
                <Column dataField="idPersona" visible={false} />
                <Column width={100} dataField="codigo" caption={getTrad("codigo")} alignment="left" sortOrder="asc" />
                <Column
                    caption={getTrad("persona")}
                    calculateCellValue={this.calculateCellValue_nombreCompuesto_selector}
                    calculateFilterExpression={this.calculateFilterExpression_local_persona}
                />
                <Column dataField="nombre" caption={getTrad("nombre")} visible={false} />
                <Column dataField="apellidos" caption={getTrad("apellidos")} visible={false} />
            </DataGrid>
        );
    };
    calculateCellValue_nombreCompuesto_selector = function (rowData) {
        return rowData.nombre + " " + rowData.apellidos;
    };
    onSelectionChanged_dropDownBox_selectorPersona(e, dropdown) {
        dropdown.close();
        this.setState({ mov_personaSel: e.selectedRowsData[0] });
    }

    // #endregion

    // #region ESTADO
    onSelectionChanged_selectBox_estado(e) {
        let estadoAnterior = this.state.mov_estadoSel;
        if (
            estadoAnterior != null &&
            this.isEstadoCantidad(estadoAnterior.idEstado) != this.isEstadoCantidad(e.selectedItem.idEstado)
        ) {
            this.setState({
                mov_estadoSel: e.selectedItem,
                mov_cantidad: null,
                mov_prendaSel: null,
                mov_personaSel: null,
            });
        } else {
            this.setState({ mov_estadoSel: e.selectedItem });
        }
    }

    onClick_btnAñadirMovimiento(e) {
        let { mov_fecha, mov_prendaSel, mov_estadoSel, mov_personaSel, movimientosPendientes, mov_cantidad } =
            this.state;
        let { isMyUniformCantidad } = this.props.lavanderia;

        if (
            mov_fecha != null &&
            mov_estadoSel != null &&
            ((!isMyUniformCantidad && mov_prendaSel != null && mov_personaSel != null) ||
                (isMyUniformCantidad &&
                    ((this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                        mov_personaSel != null &&
                        mov_cantidad != null) ||
                        (!this.isEstadoCantidad(mov_estadoSel.idEstado) &&
                            mov_prendaSel != null &&
                            mov_personaSel != null))))
        ) {
            let movimientos = $.extend(true, [], movimientosPendientes);
            let tblPrenda_MyUnif = $.extend(true, {}, mov_prendaSel);
            delete tblPrenda_MyUnif.tblPersona_MyUnif;

            let nuevoMov = {
                fecha: mov_fecha,
                tblPersona_MyUnif: mov_personaSel,
                tblPrenda_MyUnif: tblPrenda_MyUnif,
                tblEstado_MyUnif: mov_estadoSel,
                cantidad: mov_cantidad,
            };

            movimientos.push(nuevoMov);

            this.setState({
                mov_fecha: null,
                mov_prendaSel: null,
                mov_estadoSel: null,
                mov_personaSel: null,
                mov_cantidad: null,
                mostrarPrendasExtra: false,
                movimientosPendientes: movimientos,
            });
        } else {
            notify({
                message: getTrad("alerta_rellenarTodosCampos"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    onClick_btnInsertarMovimientos() {
        let { movimientosPendientes } = this.state;
        if (movimientosPendientes.length > 0) {
            let _this = this;

            _this.setState({
                popupMovimientos_isVisible: false,
            });

            this.context_tblMovimientos.invoke("PostMasivo", {}, "POST").done(function () {
                notify({
                    message: getTrad("aviso_C_RegistroInsertado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });

                _this.datasource_tblMovimiento.reload();
                _this.setState({
                    mov_fecha: null,
                    mov_prendaSel: null,
                    mov_personaSel: null,
                    mov_estadoSel: null,
                    mostrarPrendasExtra: false,
                    mov_cantidad: null,
                    movimientosPendientes: [],
                    popupMovimientos_isVisible: false,
                });
            });
        } else {
            notify({
                message: getTrad("alerta_NoDatosValidar"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
            });
        }
    }

    context_tblMovimientos_getPayload() {
        let { movimientosPendientes } = this.state;
        var movimientosInsertar = [];

        $.each(movimientosPendientes, function (index, mov) {
            movimientosInsertar.push({
                fecha: mov.fecha,
                idPrenda: mov.tblPrenda_MyUnif.idPrenda,
                idPersona: mov.tblPersona_MyUnif ? mov.tblPersona_MyUnif.idPersona : null,
                idEstado: mov.tblEstado_MyUnif.idEstado,
                cantidad: mov.cantidad,
            });
        });
        return movimientosInsertar;
    }

    popupMovimientos_onHiding(e) {
        this.setState({
            popupMovimientos_isVisible: false,
            mov_fecha: null,
            mov_prendaSel: null,
            mov_personaSel: null,
            mov_estadoSel: null,
            mov_cantidad: null,
            mostrarPrendasExtra: false,
        });
    }

    // #endregion

    // #region LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    // #endregion

    componentDidMount() {
        //DEVEXREME JQUERY
        this.cargaDatos_lavanderia();
        this.datasource_tblEstado.load();
        this.datasource_tblEstado_dropDownFilter.load();
    }

    componentDidUpdate(prevProps, prevState) {
        let { lavanderia, idioma } = this.props;
        if (lavanderia && lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) {
            this.cargaDatos_lavanderia();
        } else if (idioma.idIdioma !== prevProps.idioma.idIdioma) {
            this.dxDataGrid_movimientos.repaint();
        }
    }
}

const getSizeQualifier = (width) => {
    if (width < 768) return "xs";
    if (width < 992) return "sm";
    if (width < 1200) return "md";
    return "lg";
};

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MovimientosUniformidad);
