import { getTrad } from "../helpers";
import { svg_month, svg_day } from "../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import { locale } from "devextreme/localization";
import query from "devextreme/data/query";
import { history, getEstructura, authHeader, errorHandler } from "../helpers";

import { connectionConstants } from "../constants";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
// import { errorHandler } from './errorHandler';
// import {  } from './authHeader';

export function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export var filtroActivo = [
    {
        text: "activo", // getTrad("activo"),
        value: true,
    },
    {
        text: "noActivo", //getTrad("noActivo"),
        value: false,
    },
];

//#endregion

//#region Enums

export var enum_NumOrdinales = [
    {
        semana: 1,
        denominacion: "primera", //,getTrad('primera'),
    },
    {
        semana: 2,
        denominacion: "segunda", //getTrad('segunda'),
    },
    {
        semana: 3,
        denominacion: "tercera", //getTrad('tercera'),
    },
    {
        semana: 4,
        denominacion: "cuarta", //getTrad('cuarta'),
    },
];

export var enum_colores_dashboard = [
    "rgb(108,202,201)", //0-Turquesa
    "rgb(253,122,111)", //1-Rojo
    "rgb(255,190,88)", //2-Amarillo
    "rgb(87,200,242)", //3-Azul
    "rgb(129,199,132)", //4-Verde
    "rgb(194,150,229)",
]; //5-Morado

//#endregion

//#region Patterns

export var pattern_denominacion = "^[wA-z wÀ-ú 0-9 _+çÇñÑ.:'-,]*$";

//#endregion

export function compressBase64Img_scaleWidth(src, width) {
    const img = new Image();
    img.src = src;

    var deferred = $.Deferred();
    img.onload = function () {
        var scaleFactor = width / img.width;

        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = img.height * scaleFactor;

        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, width, elem.height);

        deferred.resolve(ctx.canvas.toDataURL().split(",")[1]);
    };
    return deferred.promise();
}

//#region FUNCIONES PROPIAS

export function convertClienteUtcToLavanderiaUtc(GMT, date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000 + GMT * 60 * 60 * 1000);
}

export function convertClienteUtcToSinUtc(date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
}

export function formatTimeOffset(date) {
    let actualOffset = date.getTimezoneOffset(), //getTimezoneOffset diferencia minutos respecto a tiempo UTC, negativo si es superiro al UTC, positivo si es menor
        horas = Math.abs(actualOffset / 60),
        stringHoras = "." + date.getMilliseconds() + (actualOffset / 60 >= 0 ? "-" : "+");

    return (stringHoras += leadingZero(horas) + ":00");
}

// SI EL NUMERO ES MENOR QUE 10 PONER 0 A LA IZQUIERDA
export function leadingZero(n) {
    return n < 10 ? "0" + n : n;
}

export function formatNumber(num, numDecimales, style, moneda) {
    var options = {};

    if (style === "largeNumber") {
        options.notation = "compact";
        options.compactDisplay = "short";
    } else {
        options.style = style;
    }
    options.currency = moneda;
    options.minimumFractionDigits = numDecimales;
    options.maximumFractionDigits = numDecimales;

    return new Intl.NumberFormat(locale(), options).format(num);
}

export function currencyFormat_lavanderiaSel(number, codigoMoneda, codigoIdioma) {
    var moneda = "EUR";
    moneda = codigoMoneda;

    return new Intl.NumberFormat(codigoIdioma, {
        style: "currency",
        currency: moneda,
        minimumFractionDigits: 0,
    }).format(number);
}

export function durationToDatetime(duration) {
    //PT23H15M12S
    if (duration == null) return null;
    var horas = 0;
    var minutos = 0;
    var segundos = 0;

    duration = duration.split("PT")[1]; //23H1M1S

    if (duration.includes("H")) {
        var arr_hora = duration.split("H");
        horas = arr_hora[0]; //23
        duration = arr_hora[1]; //15M12S
    }

    var arr_min = [];
    if (duration.includes("M")) {
        arr_min = duration.split("M");
        minutos = arr_min[0]; //15
        duration = arr_min[1]; //12S
    }

    if (duration.includes("S")) {
        arr_min = duration.split("S");
        segundos = arr_min[0]; //12
    }

    return new Date(1970, 0, 1, horas, minutos, segundos);
}

export function datetimeToDuration(datetime) {
    return "PT" + datetime.getHours() + "H" + datetime.getMinutes() + "M" + datetime.getSeconds() + "S";
}

export function dateTime_hourMinute(date) {
    return date.toLocaleTimeString(navigator.language, {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });
}

export function timeToDatetime(time) {
    time = time.split(":");
    return new Date(1970, 0, 1, time[0], time[1], time[2] ?? 0, 0);
}

// PONER FECHA EN FORMATO CORRECTO PARA SQL
export function formatDate(date) {
    return leadingZero(date.getDate()) + "/" + leadingZero(date.getMonth() + 1) + "/" + date.getFullYear();
}

// PARA ENVIAR SOLAMENTE FECHA COMO PARÁMETRO PARA WEB API
export function formatDate_parameter(date) {
    return (
        date.getFullYear() + "-" + leadingZero(date.getMonth() + 1) + "-" + leadingZero(date.getDate()) + "T00:00:00Z"
    );
}

export function formatDate_noTime_parameter(date) {
    return date.getFullYear() + "-" + leadingZero(date.getMonth() + 1) + "-" + leadingZero(date.getDate());
}

export function formatDateTime_parameter(date) {
    return (
        date.getFullYear() +
        "-" +
        leadingZero(date.getMonth() + 1) +
        "-" +
        leadingZero(date.getDate()) +
        "T" +
        leadingZero(date.getHours()) +
        ":" +
        leadingZero(date.getMinutes()) +
        ":" +
        leadingZero(date.getSeconds()) +
        "Z"
    );
}

export function formatTime_parameter(date) {
    if (date == null) return null;
    date = new Date(date);
    return leadingZero(date.getHours()) + ":" + leadingZero(date.getMinutes()) + ":" + leadingZero(date.getSeconds());
}

export function formatDateTime(date) {
    return (
        leadingZero(date.getDate()) +
        "/" +
        leadingZero(date.getMonth() + 1) +
        "/" +
        date.getFullYear() +
        " - " +
        leadingZero(date.getHours()) +
        ":" +
        leadingZero(date.getMinutes())
    );
}

export function formatDateTimeOffset_lavanderia(GMT, date) {
    return (
        date.getFullYear() +
        "-" +
        leadingZero(date.getMonth() + 1) +
        "-" +
        leadingZero(date.getDate()) +
        "T" +
        leadingZero(date.getHours()) +
        ":" +
        leadingZero(date.getMinutes()) +
        ":" +
        leadingZero(date.getSeconds()) +
        "+" +
        "01:00"
    );
}

export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addMonths(date, months) {
    var result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
}

export function addMinutes(date, minutes) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() + minutes);
    return result;
}

//#region CÁLCULO DE FECHASexport
export function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth() + 1;
    return months <= 0 ? 0 : months;
}

export function dayDiff(d1, d2) {
    var unDia = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((d1.getTime() - d2.getTime()) / unDia));
}

export function minuteDiff(d1, d2) {
    var unMinuto = 60 * 1000;
    return Math.round(Math.abs((d1.getTime() - d2.getTime()) / unMinuto));
}

export function timeDiff(d1, d2) {
    let date1 = new Date(1970, 0, 1, d1.getHours(), d1.getMinutes(), 0);
    let date2 = new Date(1970, 0, 1, d2.getHours(), d2.getMinutes(), 0);
    const diff = date2.getTime() - date1.getTime();

    let msec = diff;
    const hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    const mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    const ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return new Date(1970, 0, 1, hh, mm, ss);
}

export function hourDiff(d1, d2) {
    var seconds = Math.floor((d2 - d1) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = minutes / 60;
    return hours.toFixed(2);
}

export function dateDiff(d1, d2) {
    var seconds = Math.floor((d2 - d1) / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    var stringFinal = "";
    if (days > 0) {
        stringFinal += days + " " + getTrad("dias") + " ";
    }
    if (hours > 0) {
        stringFinal += hours + " " + getTrad("horas") + " ";
    }
    if (minutes > 0) {
        stringFinal += minutes + " " + getTrad("minutos") + " ";
    }

    return stringFinal;
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
}

export function createPalette(hexaColor, num) {
    var color = hexToRgb(hexaColor);
    var targ_R = color.r,
        targ_G = color.g,
        targ_B = color.b,
        inc_R = (255 - targ_R) / (num / 2),
        inc_G = (255 - targ_G) / (num / 2),
        inc_B = (255 - targ_B) / (num / 2);

    var palette = [];
    for (var i = 2; i <= num + 1; i++) {
        var red = 255 - i * inc_R;
        var green = 255 - i * inc_G;
        var blue = 255 - i * inc_B;

        palette.push("rgb(" + red + "," + green + "," + blue + ")");
    }

    return palette;
}
//#endregion

export function dxMensajePregunta() {
    //  dxMensajePregunta("PREGUNTA" , [["nombre_btn1", function_btn1, tipo_btn1 , btn_id],[btn2,etc...]]);
    var mensaje = arguments[0];
    var array_toolbarItems = [];
    var args = arguments[1];
    var icon = arguments[2];

    for (var i = 0; i < arguments[1].length; i++) {
        var textBtn = arguments[1][i][0];
        var typeBtn = arguments[1][i][2];
        var btn_id = arguments[1][i][3];

        array_toolbarItems.push({
            toolbar: "bottom",
            location: "center",
            widget: "dxButton",

            options: {
                text: textBtn,
                elemAttributes: {
                    buttonIndex: i,
                    id: btn_id,
                },
                type: typeBtn,
                onClick: function (e) {
                    var j = e.component.option("elemAttributes").buttonIndex;
                    args[j][1]();

                    $("#dxPopup_AlertPregunta").dxPopup("instance").hide();
                },
            },
        });
    }

    $("#dxPopup_AlertPregunta").dxPopup({
        visible: true,
        height: "auto",
        width: "auto",
        maxWidth: 500,
        minWidth: 300,
        showTitle: false,
        deferRendering: false,
        onContentReady: function (args) {
            var html = args.component.content();
            $(html).css("padding-top", "20px");
            $(html).css("padding-bottom", "10px");
            $(html).css("text-align", "center");
        },
        onInitialized: function (e) {
            e.component.registerKeyHandler("escape", function (arg) {
                arg.stopPropagation();
            });
        },
        contentTemplate: function (e) {
            var container = $("<div />");

            if (icon != undefined) {
                container.append($("<div />").addClass("dx-icon font-size-xxxxl pb-2").addClass(icon));
            }
            container.append($("<div style='font-size: 14px;'><p>" + mensaje + "</p></div>"));

            return container;
        },
        toolbarItems: array_toolbarItems,
    });
}

//#region DATOS NAVEGADOR

export function getDatosNavegador() {
    function uaMatch(ua) {
        // If an UA is not provided, default to the current browser UA.
        if (ua === undefined) {
            ua = window.navigator.userAgent;
        }
        ua = ua.toLowerCase();

        var match =
            /(edge)\/([\w.]+)/.exec(ua) ||
            /(opr)[/]([\w.]+)/.exec(ua) ||
            /(chrome)[ /]([\w.]+)/.exec(ua) ||
            /(version)(applewebkit)[ /]([\w.]+).*(safari)[ /]([\w.]+)/.exec(ua) ||
            /(webkit)[ /]([\w.]+).*(version)[ /]([\w.]+).*(safari)[ /]([\w.]+)/.exec(ua) ||
            /(webkit)[ /]([\w.]+)/.exec(ua) ||
            /(opera)(?:.*version|)[ /]([\w.]+)/.exec(ua) ||
            /(msie) ([\w.]+)/.exec(ua) ||
            (ua.indexOf("trident") >= 0 && /(rv)(?::| )([\w.]+)/.exec(ua)) ||
            (ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua)) ||
            [];

        var platform_match =
            /(ipad)/.exec(ua) ||
            /(ipod)/.exec(ua) ||
            /(iphone)/.exec(ua) ||
            /(kindle)/.exec(ua) ||
            /(silk)/.exec(ua) ||
            /(android)/.exec(ua) ||
            /(windows phone)/.exec(ua) ||
            /(win)/.exec(ua) ||
            /(mac)/.exec(ua) ||
            /(linux)/.exec(ua) ||
            /(cros)/.exec(ua) ||
            /(playbook)/.exec(ua) ||
            /(bb)/.exec(ua) ||
            /(blackberry)/.exec(ua) ||
            [];

        var browser = {},
            matched = {
                browser: match[5] || match[3] || match[1] || "",
                version: match[2] || match[4] || "0",
                versionNumber: match[4] || match[2] || "0",
                platform: platform_match[0] || "",
            };

        if (matched.browser) {
            browser[matched.browser] = true;
            browser.version = matched.version;
            browser.versionNumber = parseInt(matched.versionNumber, 10);
        }

        if (matched.platform) {
            browser[matched.platform] = true;
        }

        // These are all considered mobile platforms, meaning they run a mobile browser
        if (
            browser.android ||
            browser.bb ||
            browser.blackberry ||
            browser.ipad ||
            browser.iphone ||
            browser.ipod ||
            browser.kindle ||
            browser.playbook ||
            browser.silk ||
            browser["windows phone"]
        ) {
            browser.mobile = true;
        }

        // These are all considered desktop platforms, meaning they run a desktop browser
        if (browser.cros || browser.mac || browser.linux || browser.win) {
            browser.desktop = true;
        }

        // Chrome, Opera 15+ and Safari are webkit based browsers
        if (browser.chrome || browser.opr || browser.safari) {
            browser.webkit = true;
        }

        // IE11 has a new token so we will assign it msie to avoid breaking changes
        // IE12 disguises itself as Chrome, but adds a new Edge token.
        if (browser.rv || browser.edge) {
            var ie = "msie";

            matched.browser = ie;
            browser[ie] = true;
        }

        // Blackberry browsers are marked as Safari on BlackBerry
        if (browser.safari && browser.blackberry) {
            var blackberry = "blackberry";

            matched.browser = blackberry;
            browser[blackberry] = true;
        }

        // Playbook browsers are marked as Safari on Playbook
        if (browser.safari && browser.playbook) {
            var playbook = "playbook";

            matched.browser = playbook;
            browser[playbook] = true;
        }

        // BB10 is a newer OS version of BlackBerry
        if (browser.bb) {
            var bb = "blackberry";

            matched.browser = bb;
            browser[bb] = true;
        }

        // Opera 15+ are identified as opr
        if (browser.opr) {
            var opera = "opera";

            matched.browser = opera;
            browser[opera] = true;
        }

        // Stock Android browsers are marked as Safari on Android.
        if (browser.safari && browser.android) {
            var android = "android";

            matched.browser = android;
            browser[android] = true;
        }

        // Kindle browsers are marked as Safari on Kindle
        if (browser.safari && browser.kindle) {
            var kindle = "kindle";

            matched.browser = kindle;
            browser[kindle] = true;
        }

        // Kindle Silk browsers are marked as Safari on Kindle
        if (browser.safari && browser.silk) {
            var silk = "silk";

            matched.browser = silk;
            browser[silk] = true;
        }

        // Assign the name and platform variable
        browser.name = matched.browser;
        browser.platform = matched.platform;
        return browser;
    }

    // Run the matching process, also assign the function to the returned object
    // for manual, jQuery-free use if desired
    window.jQBrowser = uaMatch(window.navigator.userAgent);
    window.jQBrowser.uaMatch = uaMatch;

    // Only assign to jQuery.browser if jQuery is loaded
    $.browser = window.jQBrowser;

    return window.jQBrowser;
}

//#endregion

export function getYesterday() {
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday;
}

export function getCurrentWeek() {
    const today = new Date();
    let numDia = today.getDay();

    let firstDay = today.getDate() - (numDia == 0 ? 7 : numDia) + 1;
    let lastDay = firstDay + 6;

    firstDay = new Date(today.setDate(firstDay));
    lastDay = new Date(today.setDate(lastDay));

    return [firstDay, lastDay];
}

export function getLastMonth(date) {
    var date = date == null ? new Date() : date;
    var firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    return [firstDay, lastDay];
}

export function getLastNMonth(date, numberMonth) {
    // numerMonth -> 1 mes anterior, 2 dos meses atras, ect...
    numberMonth = numberMonth ? numberMonth : 1;
    var date = date == null ? new Date() : date;
    var firstDay = new Date(date.getFullYear(), date.getMonth() - numberMonth, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    return [firstDay, lastDay];
}

export function getNextNMonth(date, numberMonth) {
    // numerMonth -> 1 mes anterior, 2 dos meses atras, ect...
    numberMonth = numberMonth ? numberMonth : 1;
    var date = date == null ? new Date() : date;
    var firstDay = new Date(date.getFullYear(), date.getMonth() + numberMonth, 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth(), 0);

    return [firstDay, lastDay];
}

export function isSameDay(date1, date2) {
    if (date1 == null || date2 == null) return false;
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
    );
}

export function isSameMonth(date1, date2) {
    if (date1 == null || date2 == null) return false;
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function endOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function startOfYear(date) {
    return new Date(date.getFullYear(), 0, 1);
}

export function endOfYear(date) {
    return new Date(date.getFullYear(), 11, 31);
}

var timeout_RangeSelector_valueChanged = null;
export function create_rangeSelector(
    dataSource,
    isChartDiario,
    isChartMensual,
    dxRangeSelector_onValueChanged,
    dxButtonMensual_onClick,
    dxButtonDiario_onClick,
    values
) {
    var date = new Date(),
        is_empty = dataSource.items().length === 0;
    date.setFullYear(date.getFullYear() - 1);

    var ultimaFecha_dataSource = null,
        fechaSel_desde = null,
        fechaSel_hasta = null,
        fechaHasta = null;

    dataSource = is_empty ? [{ arg: date }, { arg: new Date() }] : dataSource.items();

    if (!is_empty) {
        ultimaFecha_dataSource = new Date(dataSource[dataSource.length - 1].arg);
        fechaSel_desde = values ? values[0] : startOfMonth(ultimaFecha_dataSource);
        fechaSel_hasta = values ? values[1] : new Date(ultimaFecha_dataSource);
        fechaHasta = new Date(ultimaFecha_dataSource);
    }

    return $("<div />")
        .css({ "background-color": "white", padding: "5px 0px 0px 0px" })
        .dxBox({
            elementAttr: { id: "dxBox_filtroTiempo" },
            direction: "row",
            height: "100%",
            width: "100%",
            items: [
                {
                    baseSize: 50,
                    template: function (itemData, itemIndex, itemElement) {
                        itemElement.append(
                            $("<div />")
                                .css({
                                    "background-color": "white",
                                    padding: "0px 15px 0px 0px",
                                })
                                .dxBox({
                                    direction: "col",
                                    height: "100%",
                                    width: "100%",
                                    items: [
                                        {
                                            ratio: 1,
                                            template: function (itemData, itemIndex, itemElement) {
                                                itemElement.css("padding-top", 5);
                                                itemElement.append(
                                                    $("<div />").dxButton({
                                                        tooltipText: getTrad("mensual"),
                                                        height: "100%",
                                                        width: "100%",
                                                        icon: svg_month.clone().css({
                                                            height: "20px",
                                                            width: "20px",
                                                        })[0].outerHTML,
                                                        elementAttr: { id: "dxButton_mensual" },
                                                        onInitialized: function (e) {
                                                            tooltipControl_creacion(
                                                                e.element,
                                                                e.component.option("tooltipText")
                                                            );
                                                        },
                                                        onClick: function (e) {
                                                            if (!e.element.hasClass("dxButton_activo")) {
                                                                $(".dxButton_activo").removeClass("dxButton_activo");
                                                                e.element.addClass("dxButton_activo");

                                                                $("#dxRangeSelector_filtroTiempo")
                                                                    .dxRangeSelector("instance")
                                                                    .option("scale.tickInterval", "month");
                                                                init_CustomMarker_RangeSelector(
                                                                    $("#dxRangeSelector_filtroTiempo")
                                                                );
                                                                dxRangeSelector_PosicionValor_nuevoMarker(
                                                                    $("#dxRangeSelector_filtroTiempo"),
                                                                    true,
                                                                    isChartMensual
                                                                );
                                                            }
                                                        },
                                                    })
                                                );
                                            },
                                        },
                                        {
                                            ratio: 1,
                                            template: function (itemData, itemIndex, itemElement) {
                                                itemElement.css("padding-top", 5);
                                                itemElement.append(
                                                    $("<div />").dxButton({
                                                        tooltipText: getTrad("diario"),
                                                        height: "100%",
                                                        width: "100%",
                                                        icon: svg_day.clone().css({
                                                            height: "20px",
                                                            width: "20px",
                                                        })[0].outerHTML,
                                                        elementAttr: {
                                                            id: "dxButton_diario",
                                                            class: "dxButton_activo",
                                                        },
                                                        onInitialized: function (e) {
                                                            tooltipControl_creacion(
                                                                e.element,
                                                                e.component.option("tooltipText")
                                                            );
                                                        },
                                                        onClick: function (e) {
                                                            if (!e.element.hasClass("dxButton_activo")) {
                                                                $(".dxButton_activo").removeClass("dxButton_activo");
                                                                e.element.addClass("dxButton_activo");

                                                                $("#dxRangeSelector_filtroTiempo")
                                                                    .dxRangeSelector("instance")
                                                                    .option("scale.tickInterval", "day");

                                                                remove_CustomMarker_RangeSelector(
                                                                    $("#dxRangeSelector_filtroTiempo")
                                                                );
                                                            }
                                                        },
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                })
                        );
                    },
                },
                {
                    ratio: 1,
                    template: function (itemData, itemIndex, itemElement) {
                        var scrollView_dxRangeSelector = $("<div id='scrollView_dxRangeSelector' />");
                        var dxRangeSelector_filtroTiempo = $("<div id='dxRangeSelector_filtroTiempo'/>");

                        dxRangeSelector_filtroTiempo.appendTo(scrollView_dxRangeSelector);

                        scrollView_dxRangeSelector.dxScrollView({
                            height: "100%",
                            width: "100%",
                            direction: "horizontal",
                            showScrollbar: "always",
                        });

                        dxRangeSelector_filtroTiempo.dxRangeSelector({
                            dataSource: dataSource,
                            mesesVisibles: { diario: 4, mensual: 12, anual: 72 },
                            is_anual: false,
                            is_mensual: false,
                            is_daytoDaySelection: false, // si es true solo deja escojer de dia en dia en diario.
                            value: [fechaSel_desde, fechaSel_hasta],
                            valueHasta: new Date(fechaSel_hasta), // value[1] , sirve para por ejemplo mensual restar al value 1 dia y asignar la fecha a este, asi no desvirtua la fecha visible.
                            size: {
                                height: 150,
                            },
                            behavior: {
                                animationEnabled: false,
                                callValueChanged: "onMovingComplete",
                            },
                            scale: {
                                tickInterval: "day",
                                minorTickInterval: "day",
                                valueType: "datetime",
                                minorTick: {
                                    visible: false,
                                },
                                marker: {
                                    label: {
                                        customizeText: function (e) {
                                            if (
                                                dxRangeSelector_filtroTiempo
                                                    .dxRangeSelector("instance")
                                                    .option("is_mensual")
                                            ) {
                                                return e.valueText;
                                            } else {
                                                return (
                                                    capitalize(
                                                        new Intl.DateTimeFormat(locale(), {
                                                            month: "long",
                                                        }).format(e.value)
                                                    ) +
                                                    " (" +
                                                    e.value.getFullYear() +
                                                    ")"
                                                );
                                            }
                                        },
                                    },
                                },
                                label: {
                                    customizeText: function (e) {
                                        if (
                                            dxRangeSelector_filtroTiempo
                                                .dxRangeSelector("instance")
                                                .option("is_mensual")
                                        ) {
                                            var fecha = new Date(e.value);
                                            var mes = fecha.toLocaleString("es-ES", {
                                                month: "short",
                                            });
                                            var año = fecha.getFullYear();
                                            var text = mes;

                                            if (año !== new Date().getFullYear()) {
                                                var displayYear = " '" + e.value.getFullYear().toString().substr(-2);
                                                text += " " + displayYear;
                                            }
                                            return text.toUpperCase();
                                        }
                                        return e.valueText;
                                    },
                                },
                                endValue: fechaHasta,
                            },
                            sliderMarker: {
                                format: "monthAndDay",
                                paddingLeftRight: 30,
                                customizeText: function (e) {
                                    if (
                                        typeof $("#dxRangeSelector_filtroTiempo").data("dxRangeSelector") !==
                                            "undefined" &&
                                        $("#dxRangeSelector_filtroTiempo").data("dxRangeSelector").option("value") &&
                                        ($("#dxRangeSelector_filtroTiempo")
                                            .dxRangeSelector("instance")
                                            .option("scale.tickInterval") === "month" ||
                                            $("#dxRangeSelector_filtroTiempo")
                                                .dxRangeSelector("instance")
                                                .option("is_daytoDaySelection") ||
                                            $("#dxRangeSelector_filtroTiempo")
                                                .dxRangeSelector("instance")
                                                .option("scale.tickInterval") === "year") // si es mensual, o es day to day o es anual
                                    ) {
                                        dxRangeSelector_PosicionValor_nuevoMarker($("#dxRangeSelector_filtroTiempo"));
                                    } else return e.valueText;
                                },
                            },
                            onDrawn: function (e) {
                                if (
                                    e.component.option("scale.tickInterval") === "month" ||
                                    e.component.option("scale.tickInterval") === "year"
                                ) {
                                    customMonthViewer_rangeSelector();
                                } else {
                                    selectorMeses_dxRangeSelector(e.component);
                                }
                                filtroTiempo_Resize();
                            },
                            onOptionChanged: function (e) {
                                if (e.name === "dataSource") {
                                    ultimaFecha_dataSource = new Date(e.value[e.value.length - 1].arg);
                                    dataSource = e.value;
                                }
                                if (e.name === "scale") {
                                    if (e.value === "month") {
                                        //#region Configuración range selector
                                        var d = new Date(ultimaFecha_dataSource);
                                        // si selecciona último mes, si este tiene pocos dias, al seleccionar otro no llega a seleccionar un mes entero.
                                        e.component.option({
                                            is_mensual: true,
                                            is_anual: false,
                                            "scale.minRange": { days: null, months: 2 },
                                            "scale.maxRange": null,
                                            "scale.startValue": startOfMonth(new Date(dataSource[0].arg)),
                                            "scale.endValue": endOfMonth(new Date(ultimaFecha_dataSource)),
                                            "scale.minorTickInterval": { months: 2 },
                                            "scale.aggregationInterval": "month",
                                            "sliderMarker.format": "month",
                                            "sliderMarker.paddingLeftRight": 15,
                                            valueHasta: endOfMonth(new Date(ultimaFecha_dataSource)),
                                            value: [
                                                startOfMonth(new Date(d.setMonth(d.getMonth() - 1))),
                                                endOfMonth(new Date(ultimaFecha_dataSource)),
                                            ],
                                            "behavior.callValueChanged": "onMoving",
                                        });
                                        dxRangeSelector_PosicionValor_nuevoMarker($("#dxRangeSelector_filtroTiempo"));

                                        if (typeof dxButtonMensual_onClick === "function") dxButtonMensual_onClick();

                                        //#endregion
                                    } else if (e.value === "day") {
                                        //#region Configuración range selector
                                        e.component.option({
                                            is_mensual: false,
                                            is_anual: false,
                                            "scale.minRange": { days: 1, months: null },
                                            "scale.maxRange": e.component.option("is_daytoDaySelection") ? "day" : null,
                                            "scale.minorTickInterval": { days: 1, months: null },
                                            "scale.startValue": new Date(dataSource[0].arg),
                                            "scale.endValue": ultimaFecha_dataSource,
                                            "sliderMarker.format": "monthAndDay",
                                            "sliderMarker.paddingLeftRight": 30,
                                            valueHasta: new Date(ultimaFecha_dataSource),
                                            value: [
                                                startOfMonth(new Date(ultimaFecha_dataSource)),
                                                new Date(ultimaFecha_dataSource),
                                            ],
                                            "behavior.callValueChanged": "onMovingComplete",
                                        });
                                        //#endregion
                                        selectorMeses_dxRangeSelector(e.component);

                                        if (typeof dxButtonDiario_onClick === "function") dxButtonDiario_onClick();
                                    } else if (e.value === "year") {
                                        //#region Configuración range selector
                                        e.component.option({
                                            is_mensual: false,
                                            is_anual: true,
                                            "scale.tickInterval": "year",
                                            "scale.minRange": "year",
                                            "scale.maxRange": "year",
                                            "scale.minorTickInterval": "year",
                                            "scale.startValue": new Date(startOfYear(dataSource[0].arg)),
                                            "scale.endValue": endOfYear(ultimaFecha_dataSource),
                                            "sliderMarker.format": "year",
                                            "sliderMarker.paddingLeftRight": 30,
                                            valueHasta: new Date(endOfYear(ultimaFecha_dataSource)),
                                            value: [
                                                startOfYear(new Date(ultimaFecha_dataSource)),
                                                endOfYear(new Date(ultimaFecha_dataSource)),
                                            ],
                                            "behavior.callValueChanged": "onMovingComplete",
                                        });
                                        //#endregion

                                        dxRangeSelector_PosicionValor_nuevoMarker($("#dxRangeSelector_filtroTiempo"));

                                        if (typeof dxButtonDiario_onClick === "function") {
                                            setTimeout(function () {
                                                dxButtonDiario_onClick();
                                            }, 100);
                                        }
                                    }
                                    filtroTiempo_Resize();
                                }
                            },
                            onValueChanged: function (e) {
                                if (timeout_RangeSelector_valueChanged)
                                    clearTimeout(timeout_RangeSelector_valueChanged);

                                e.component.option("valueHasta", new Date(e.value[1]));
                                // Para que mensual funcione bien, quito un dia a fecha hasta para que muestre el mes anterior.
                                if (
                                    e.component.option("scale.tickInterval") === "month" ||
                                    e.component.option("scale.tickInterval") === "year"
                                ) {
                                    //MENSUAL
                                    if (e.value[1].getDate() === 1) {
                                        var d = new Date(e.value[1]);
                                        e.component.option("valueHasta", new Date(d.setDate(d.getDate() - 1)));
                                    }
                                    dxRangeSelector_PosicionValor_nuevoMarker(e.element);

                                    timeout_RangeSelector_valueChanged = setTimeout(function () {
                                        dxRangeSelector_onValueChanged(e);
                                    }, 450);
                                } else {
                                    //DIARIO
                                    dxRangeSelector_onValueChanged(e);
                                }
                            },
                        });
                        return scrollView_dxRangeSelector;
                    },
                },
            ],
        });
}

export function remove_CustomMarker_RangeSelector(rangeSelector) {
    var sliderMarkers = rangeSelector.find(".slider .slider-marker");

    if ($(sliderMarkers[0]).find("text").length < 2) return;

    // AÑADE LOS NUEVOS MARKERS CLONADOS
    $(sliderMarkers[0]).find("text")[0].remove();
    $(sliderMarkers[1]).find("text")[0].remove();
    $($(sliderMarkers[0]).find("text")[0]).attr("visibility", "");
    $($(sliderMarkers[1]).find("text")[0]).attr("visibility", "");
}

export function init_CustomMarker_RangeSelector(rangeSelector) {
    // PERSONALIZA LOS MARCADORES DEL RANGE SELECTOR PARA QUE SALGA UN DÍA MENOS EN EL DE FECHA HASTA.
    // *EJECUTAR DESPUÉS DE LA CREACIÓN DEL RANGE SELECTOR

    //CLONAR MARKER DEL RANGE SELECTOR PARA CUSTOMIZARLO.
    var textoMarcador_Desde = rangeSelector.find(".slider:eq(0) text"),
        textoMarcador_Hasta = rangeSelector.find(".slider:eq(1) text"),
        sliderMarkers = rangeSelector.find(".slider .slider-marker"),
        markerPresionado = false;

    if (textoMarcador_Desde.length > 1) return;

    $(rangeSelector[0]).dxRangeSelector("instance").option("sliderMarker.paddingLeftRight", 30);

    // AÑADE LOS NUEVOS MARKERS CLONADOS
    sliderMarkers[0].insertBefore(textoMarcador_Desde[0].cloneNode(true), sliderMarkers[0].childNodes[1]);
    sliderMarkers[1].insertBefore(textoMarcador_Hasta[0].cloneNode(true), sliderMarkers[1].childNodes[1]);

    // RECARGA LA SELECCION DE ELEMENTOS CON LOS NUEVOS
    textoMarcador_Desde = rangeSelector.find(".slider:eq(0) text");
    textoMarcador_Hasta = rangeSelector.find(".slider:eq(1) text");

    // INVISIBILIZA LOS MARKERS QUE NO SE QUIEREN MOSTRAR
    textoMarcador_Desde[1].setAttributeNS(null, "visibility", "hidden");
    textoMarcador_Hasta[1].setAttributeNS(null, "visibility", "hidden");
    //#endregion

    //#region CORREGIR BUG AL SELECCIONAR UNA FECHA SUPERIOR AL A PERMITIDA, LABEL DESAPARECE.
    // LIMPIAR EVENTOS PARA QUE NO SE ACUMULEN
    rangeSelector.find(".slider:eq(1)").off("mousedown.dxRangeSelectorMarker");
    $(".dxrs-trackers path.selected-area-tracker").off("mousedown.dxRangeSelectorMarker");
    $(document).off("mouseup.dxRangeSelectorMarker mouseenter.dxRangeSelectorMarker"); // se pone .dxRangeSelectorMarker para crear namespace y al eliminar los eventos no elimine lo que no so nde la pantalla.

    rangeSelector.find(".slider").on("mousedown.dxRangeSelectorMarker", function (e) {
        markerPresionado = true;
    });

    // PARA EL ANUAL
    $("#dxRangeSelector_filtroTiempo > svg").on("mousedown.dxRangeSelectorMarker", function (e) {
        markerPresionado = true;
    });

    $(document).on(
        "mouseup.dxRangeSelectorMarker mouseenter.dxRangeSelectorMarker mouseleave.dxRangeSelectorMarker",
        function (e) {
            switch (e.type) {
                case "mouseup":
                    if (markerPresionado)
                        setTimeout(function () {
                            dxRangeSelector_PosicionValor_nuevoMarker(rangeSelector);
                        }, 0);

                    markerPresionado = false;
                    break;

                case "mouseenter":
                    setTimeout(function () {
                        dxRangeSelector_PosicionValor_nuevoMarker(rangeSelector);
                    }, 0);
                    break;
                default:
                    break;
            }
        }
    );
    dxRangeSelector_PosicionValor_nuevoMarker(rangeSelector);
}

export function dxRangeSelector_PosicionValor_nuevoMarker(rangeSelector, cambioMensual, chartMensual) {
    if (typeof $(rangeSelector[0]).data("dxRangeSelector") !== "undefined") {
        var rangeSelector_instance = $(rangeSelector[0]).dxRangeSelector("instance"),
            fechaDesde_ = rangeSelector_instance.option("value")[0],
            fechaHasta_ = rangeSelector_instance.option("value")[1];

        cambioMensual = typeof cambioMensual !== "undefined" ? cambioMensual : false;
        chartMensual = typeof chartMensual !== "undefined" ? chartMensual : false;
        // REASIGNA LA POSICIÓN DE LOS NUEVOS MARCADORES CONSTANTEMENTE.
        // EJECUTAR EN 'sliderMarker.customizeText', EN EL 'onValueChanged' DEL RANGE SELECTOR Y EN 'onScroll' de SCROLLVIEW
        if (typeof rangeSelector.find(".slider")[0] !== "undefined") {
            var rangeSelector_sliders = rangeSelector.find(".slider");

            var position1 = rangeSelector_sliders[0].transform.baseVal.consolidate("matrix.e").matrix.e;
            var position2 = rangeSelector_sliders[1].transform.baseVal.consolidate("matrix.e").matrix.e;

            var etiqueta_Desde = position1 <= position2 ? rangeSelector_sliders[0] : rangeSelector_sliders[1];
            var etiqueta_Hasta = position1 > position2 ? rangeSelector_sliders[0] : rangeSelector_sliders[1];

            var etiqueta_Desde_Texto = etiqueta_Desde.getElementsByTagName("text");
            var etiqueta_Hasta_Texto = etiqueta_Hasta.getElementsByTagName("text");

            function asignacionMarker() {
                if (etiqueta_Desde_Texto.length < 2) return;
                //#region ASIGNAR VALOR SEGÚN DIARIO O MENSUAL
                var fechaHasta__extend = new Date(fechaHasta_.getTime());
                var diaMenos = new Date(fechaHasta__extend.setDate(fechaHasta__extend.getDate() - 1));
                var posicionDesde = etiqueta_Desde_Texto[1].transform.baseVal.consolidate().matrix.e;
                var posicionHasta = etiqueta_Hasta_Texto[1].transform.baseVal.consolidate().matrix.e;

                if (rangeSelector_instance.option("scale.tickInterval") === "month") {
                    etiqueta_Desde_Texto[0].textContent = capitalize(
                        new Intl.DateTimeFormat(locale(), { month: "long" }).format(fechaDesde_)
                    );
                    etiqueta_Hasta_Texto[0].textContent = capitalize(
                        new Intl.DateTimeFormat(locale(), { month: "long" }).format(diaMenos)
                    );
                } else if (rangeSelector_instance.option("scale.tickInterval") === "year") {
                    etiqueta_Desde_Texto[0].textContent = fechaDesde_.getFullYear();
                    etiqueta_Hasta_Texto[0].textContent = diaMenos.getFullYear();
                } else {
                    etiqueta_Desde_Texto[0].textContent =
                        fechaDesde_.getDate() +
                        " " +
                        getTrad("de") +
                        " " +
                        capitalize(new Intl.DateTimeFormat(locale(), { month: "long" }).format(fechaDesde_));
                    etiqueta_Hasta_Texto[0].textContent =
                        diaMenos.getDate() +
                        " " +
                        getTrad("de") +
                        " " +
                        capitalize(new Intl.DateTimeFormat(locale(), { month: "long" }).format(diaMenos));
                }
                //#endregion

                // ASIGNAR POSICIÓN MARCADOR

                var longitudTextoDesde = etiqueta_Desde_Texto[0].getBBox().width;

                posicionDesde = posicionDesde - longitudTextoDesde * 0.1;
                etiqueta_Desde_Texto[0].setAttributeNS(null, "transform", "translate(" + posicionDesde + ",14)");
                etiqueta_Hasta_Texto[0].setAttributeNS(null, "transform", "translate(" + posicionHasta + ",14)");
            }

            if (cambioMensual) {
                var timeOut = chartMensual ? 500 : 100;
                setTimeout(function () {
                    asignacionMarker();
                }, timeOut);
                cambioMensual = false;
            } else asignacionMarker();
        }
    }
}

export function capitalize(palabra, remainingToLowerCase) {
    if (remainingToLowerCase) {
        palabra = palabra?.toLowerCase();
    }
    return palabra?.charAt(0).toUpperCase() + palabra?.slice(1);
}

export function element_absolutePosition(element) {
    var iframeHeight = window.innerHeight,
        windowHeight = window.innerHeight;
    var navbarHeaderHeight = windowHeight - iframeHeight;

    return element.offset().top + navbarHeaderHeight;
}

export function getMesesVisibles() {
    var dxRangeSelector_filtroTiempo = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance");
    if (dxRangeSelector_filtroTiempo.option("is_anual"))
        return dxRangeSelector_filtroTiempo.option("mesesVisibles.anual");
    return dxRangeSelector_filtroTiempo.option("is_mensual")
        ? dxRangeSelector_filtroTiempo.option("mesesVisibles.mensual")
        : dxRangeSelector_filtroTiempo.option("mesesVisibles.diario");
}

export function filtroTiempo_Resize(scrollLeft) {
    if (typeof $("#dxRangeSelector_filtroTiempo")[0] !== "undefined") {
        //#region ADAPTA RANGE SELECTOR AL Nº MESES DISPONIBLES
        var dxRangeSelector_filtroTiempo = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance"),
            scrollView_dxRangeSelector = $("#scrollView_dxRangeSelector").dxScrollView("instance"),
            mesesVisibles = getMesesVisibles(),
            fechas = query(dxRangeSelector_filtroTiempo.getDataSource().items()).toArray();
        if (fechas.length > 0) {
            var itemsCount = fechas.length,
                mesesCount = monthDiff(fechas[0].arg, fechas[fechas.length - 1].arg),
                diasCount = dayDiff(fechas[0].arg, fechas[fechas.length - 1].arg);

            if (itemsCount && itemsCount > 0) {
                var meses = mesesCount > mesesVisibles ? mesesVisibles : mesesCount,
                    contenedorWidth = scrollView_dxRangeSelector.clientWidth(),
                    widthDia = contenedorWidth / (diasCount > meses * 30 ? meses * 30 : diasCount),
                    widthVisible = diasCount * widthDia;

                dxRangeSelector_filtroTiempo.option("size.width", widthVisible);
            } else {
                dxRangeSelector_filtroTiempo.option("size.width", "100%");
            }
        }
        //#endregion
        scrollView_dxRangeSelector.scrollTo({
            left: scrollLeft ? scrollLeft : $("#dxRangeSelector_filtroTiempo svg").width(),
            top: 0,
        });
    }
}

export function customMonthViewer_rangeSelector() {
    //#region Sacar el width de cada mes
    var dxRangeSelector_filtroTiempo = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance"),
        items = query(dxRangeSelector_filtroTiempo.getDataSource().items()).toArray();

    if (items.length > 0) {
        var fechas = dxRangeSelector_filtroTiempo.option("dataSource"),
            numMeses = monthDiff(fechas[0].arg, fechas[fechas.length - 1].arg);
        var width_rangeSelector = $(".dxrs-scale")[0].getBBox().width,
            widthDias = width_rangeSelector / (numMeses * 30);
    }
    //#endregion

    // Títulos de los meses en el range selector.
    var primeraFecha = new Date(items[0].arg);
    $.each($(".dxrs-range-selector-elements text"), function (index, item) {
        // Si no es un int, no es un año. Es un mes
        if (isNaN(parseInt(item.textContent, 10))) {
            // Saco la posición inicial de los markers y el numero de días del mes que se va a mostrar
            var numDias = new Date(primeraFecha.getFullYear(), primeraFecha.getMonth() + 1, 0).getDate() - 1;

            var centeredX = (parseInt(item.getAttribute("x")) + (numDias * widthDias) / 2).toString();
            item.setAttribute("x", centeredX);
            primeraFecha = new Date(primeraFecha.setMonth(primeraFecha.getMonth() + 1));
        }
    });

    // Posicionamiento de markers en range selector mensual.
    primeraFecha = new Date(items[0].arg);
    $.each($(".dxrs-series-group .dxc-labels g > g"), function (index, item) {
        // Saco la posición inicial de los markers y el numero de días del mes que se va a mostrar
        var positionX = index === 0 ? 3 : $(item).css("transform").split(",")[4],
            numDias = new Date(primeraFecha.getFullYear(), primeraFecha.getMonth() + 1, 0).getDate() - 1;

        //cambio de posición el marker según los días que tenga el mes para centrarlo bien.
        $(item).css("transform", "translate(" + (parseInt(positionX) + (numDias * widthDias) / 2) + "px,40px)");
        primeraFecha = new Date(primeraFecha.setMonth(primeraFecha.getMonth() + 1));
    });
}

export function selectorMeses_dxRangeSelector(dxRangeSelector_instance) {
    if (
        dxRangeSelector_instance.option("scale.tickInterval") !== "day" ||
        dxRangeSelector_instance.option("scale.maxRange") === "day"
    )
        return;

    var dataSource = dxRangeSelector_instance.option("dataSource");
    var firstDate = new Date(dataSource[0]["arg"]);
    var lastDate = new Date(dataSource[dataSource.length - 1]["arg"]);
    var index_ = parseInt(firstDate.getMonth());

    // coge todos los path(tanto los btn en si como el layout de los btn)
    $.each($("#dxRangeSelector_filtroTiempo svg .dxrs-range-selector-elements path"), function (index, item) {
        var positionX;
        try {
            positionX = $(item)[0].getBBox().width;
        } catch (error) {
            positionX = 0;
        }

        if (positionX !== 0) {
            // quitar espacios en blanco a positionX y comparar con 0
            //En función del 1er mes con datos se sacan los siguientes, en los btn no hay ninguna referencia a la fecha.
            firstDate.setMonth(index_, 1);

            index_++;
            if (index_ > 12) index_ = 1;

            var fechaDesde_ = new Date(firstDate.getFullYear(), firstDate.getMonth(), 1),
                fechaHasta_ = new Date(firstDate.getFullYear(), firstDate.getMonth() + 1, 0);

            if (fechaHasta_ > lastDate) {
                fechaHasta_ = new Date(lastDate.getTime());
            }

            //Se clona el btn y se envuelve en su clon para desactivar el click
            //Se añade evento click al clon
            var clon = item.cloneNode(true);

            $(clon).click(function () {
                dxRangeSelector_instance.option({
                    valueHasta: fechaHasta_,
                    value: [fechaDesde_, fechaHasta_],
                });
            });
            $(item).wrap(clon);
        }
    });
}

//#endregion

// INSERTAR TOOLTIP EN CONTROL
export function tooltipControl_creacion(element, tooltipText, is_toolbarDefaultButton) {
    function _tooltipCreation(element) {
        if (tooltipText) {
            $(element).append(
                $("<div />").dxTooltip({
                    height: "42px",
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend  dxclick",
                    position: "top",
                    contentTemplate: function (contentElement) {
                        contentElement.html(tooltipText);
                    },
                    target: element,
                    animation: {
                        show: {
                            type: "pop",
                            from: {
                                scale: 0.1,
                                opacity: 0,
                            },
                            to: {
                                opacity: 1,
                                scale: 1,
                            },
                        },
                        hide: {
                            type: "pop",
                            from: {
                                scale: 1,
                                opacity: 1,
                            },
                            to: {
                                opacity: 0,
                                scale: 0.1,
                            },
                        },
                    },
                })
            );
        }
    }

    if (is_toolbarDefaultButton) {
        var standardHandler = element.options.onInitialized;
        element.options.onInitialized = function (i) {
            standardHandler(i);
            _tooltipCreation(i.element);
        };
    } else _tooltipCreation(element);
}

export function getNombreFormulario(formContext) {
    let { user, idioma } = formContext.props;
    let formulario = history.location.pathname.split("/").reverse()[0];
    let objFormulario = user.visiblePages.find((x) => x.formulario === formulario);

    if (formulario === "MiCuenta") {
        return getTrad("miCuenta");
    } else {
        return objFormulario.tblTraduccion !== null && typeof idioma !== "undefined"
            ? objFormulario.tblTraduccion[idioma.codigo]
            : objFormulario.denominacion;
    }
}

export function getUserAccessLevel(formContext) {
    let { user } = formContext.props;
    let formulario = history.location.pathname.split("/").reverse()[0];
    let objFormulario = user.visiblePages.find((x) => x.formulario === formulario);

    let admin = objFormulario.isEscritura;
    if (user.idCargo === 1 || user.idCargo === 2) {
        admin = true;
    }
    return {
        admin: admin,
    };
}

export function getLoading() {
    return $("<div />")
        .addClass("loader-container")
        .append(
            $("<div />")
                .addClass("loader-container-inner")
                .append(
                    $("<div />")
                        .addClass("text-center")
                        .append(
                            $("<div />")
                                .addClass("loader loader-undefined loader-active")
                                .append(
                                    $("<div />")
                                        .addClass("loader-inner line-scale")
                                        .append($("<div />"))
                                        .append($("<div />"))
                                        .append($("<div />"))
                                        .append($("<div />"))
                                        .append($("<div />"))
                                )
                                .append($("<h6 />").addClass("mt-3").css("color", "var(--secondary)").text("Cargando"))
                        )
                )
        );
}

// Resolución
export function get_isMovil(resolucion) {
    return resolucion === "xs" || resolucion === "sm" || resolucion === "md";
}

// Color Uds/Bac
export function get_template_colorTapa(element, data) {
    var contenedor = $("<div>").addClass("container_spanCentrado").css("flex-basis", 50);

    if (data.codigoHexadecimal) {
        var cellWidth = element[0].clientWidth * 0.55;
        var colorMarca = "#FFFFFF";

        contenedor.css({
            height: 19,
            width: cellWidth,
            "background-color": data.codigoHexadecimal,
            "border-radius": 4,
            border: "1px solid #BFBFBF",
            "font-size": "12px",
            display: "flex",
            margin: "auto",
            "align-items": "normal",
        });

        if (data.codigoHexadecimal === "#FFFFFF") {
            colorMarca = "#000000";
        }
        contenedor.text(data.denoMarcaTapa).css({ color: colorMarca, "font-weight": "bold" });
    } else {
        contenedor.text("N/A");
    }
    return contenedor;
}

export function is_paramRedireccion_outOfRange(fechas_paramAlert) {
    let { paramString, bd } = fechas_paramAlert;
    // No se tienen en cuenta los días, si puede mostrar el mes para el año ya basta.
    let paramString_hasta_monthYear = new Date(paramString.hasta.getFullYear(), paramString.hasta.getMonth()),
        bd_hasta_monthYear = new Date(bd.hasta.getFullYear(), bd.hasta.getMonth()),
        paramString_desde_monthYear = new Date(paramString.desde.getFullYear(), paramString.desde.getMonth()),
        bd_desde_monthYear = new Date(bd.desde.getFullYear(), bd.desde.getMonth());

    if (paramString_desde_monthYear < bd_desde_monthYear || paramString_hasta_monthYear > bd_hasta_monthYear) {
        dxMensajePregunta(getTrad("sinDatos_filtro"), [
            [getTrad("aceptar"), function (e) {}, "danger", "dxButton_paramString_outOfRange"],
        ]);
    }
}

export function getUrlFormularioInicio(lavanderia, aplicacion, tblFormularioInicio) {
    let lavEstructura = !aplicacion || (aplicacion && aplicacion.idAplicacion === 1) ? lavanderia : null;
    let estructuraMenu = getEstructura(lavEstructura);
    // USUARIOS POR ENTIDAD
    let idsFormularioNUsuario = $.map(estructuraMenu, function mapper(obj) {
        return obj["idFormulario"] ? obj.idFormulario : $.map(obj.content, mapper);
    });

    if (tblFormularioInicio && $.inArray(tblFormularioInicio.idFormulario, idsFormularioNUsuario) > -1) {
        return tblFormularioInicio.to;
    }

    if (
        aplicacion &&
        aplicacion.to &&
        ((lavanderia && $.inArray(aplicacion.idFormularioInicio, lavanderia.visiblePages) > -1) ||
            (lavanderia == null && $.inArray(aplicacion.idFormularioInicio, idsFormularioNUsuario) > -1) ||
            (aplicacion.tblFormularioInicio && aplicacion.tblFormularioInicio.idApartado === 20)) //FORMULARIOS DE INICIO
    ) {
        return aplicacion.to;
    } else {
        if (estructuraMenu && estructuraMenu.Informes) return "/Informes";
        let redireccion = $.map(estructuraMenu, function mapper(obj) {
            return obj["to"] && ((aplicacion && aplicacion.idAplicacion === obj.idAplicacion) || !aplicacion)
                ? obj.to
                : $.map(obj.content, mapper);
        });

        if (redireccion.length > 0) return redireccion[0];
    }
}

export function getBrightnessColor(hexa) {
    //devuelve valor entre 0-255
    var c = hexa.substring(1);
    var rgb = parseInt(c, 16);
    var r = (rgb >> 16) & 0xff;
    var g = (rgb >> 8) & 0xff;
    var b = (rgb >> 0) & 0xff;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
}

export function stringToDateTime_timeZero(dateString) {
    return new Date(new Date(dateString).setHours(0, 0, 0, 0));
}

export function rotateBase64Image(srcBase64, degrees, callback) {
    const canvas = document.createElement("canvas");
    let ctx = canvas.getContext("2d");
    let image = new Image();

    image.onload = function () {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((degrees * Math.PI) / 180);
        ctx.drawImage(image, image.width / -2, image.height / -2);

        callback(canvas.toDataURL());
    };

    image.src = srcBase64;
}

export function subtractHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() - numOfHours);

    return date;
}

export function addHours(numOfHours, date = new Date()) {
    date.setHours(date.getHours() + numOfHours);

    return date;
}

export function monthToName(numeroMes, codIdioma) {
    numeroMes = parseInt(numeroMes);

    var mesNombre;
    if (codIdioma === "es") {
        mesNombre = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
        ];
    } else {
        mesNombre = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
    }
    return mesNombre[numeroMes - 1];
}

export async function getCierresFacturacion(idEntidad, fechaDesde, fechaHasta, tipo) {
    // false -> fechas están cerradas? | true -> fechas contienen algún cierre?
    let filtros = [];
    if (idEntidad != null) filtros.push("(idEntidad eq " + idEntidad + ")");
    if (fechaDesde != null && fechaHasta != null) {
        fechaDesde = formatDate_noTime_parameter(fechaDesde);
        fechaHasta = formatDate_noTime_parameter(fechaHasta);

        if (tipo) filtros.push("(" + fechaDesde + " le fechaDesde " + " and " + fechaHasta + " ge fechaHasta)");
        else
            filtros.push(
                "((fechaDesde le " +
                    fechaDesde +
                    " and fechaHasta ge " +
                    fechaDesde +
                    ") or (fechaDesde le " +
                    fechaHasta +
                    " and fechaHasta ge " +
                    fechaHasta +
                    "))"
            );
    }

    let datasource_tblCierreFactEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
            key: ["idEntidad", "fechaDesde", "fechaHasta"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        filter: filtros.length > 0 ? filtros.join(" and ") : ["true"],
    });

    return new Promise((resolve, reject) => {
        datasource_tblCierreFactEntidad
            .reload()
            .then((data) => {
                resolve(data);
            })
            .catch(reject);
    });
}

export function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

export function getDaysInRange(fechaDesde, fechaHasta) {
    fechaDesde = new Date(fechaDesde);
    fechaHasta = new Date(fechaHasta);
    var dateArray = new Array();
    var currentDate = fechaDesde;
    while (currentDate.setHours(0, 0, 0, 0) <= fechaHasta.setHours(0, 0, 0, 0)) {
        dateArray.push(new Date(currentDate));
        currentDate = addDays(currentDate, 1);
    }
    return dateArray;
}

export function handleErrors(error) {
    let errorMessage = "";
    if (error.httpStatus === 0) {
        errorMessage = "Error de conexión";
    } else if (error.httpStatus >= 400 && error.httpStatus < 500) {
        errorMessage = error.errorDetails.message;
    } else if (error.httpStatus >= 500 && error.httpStatus < 600) {
        errorMessage = "Error del servidor";
    } else {
        errorMessage = "Error desconocido";
    }

    return {
        errorMessage,
        httpStatus: error.httpStatus,
        details: error.errorDetails?.innererror,
    };
}

/**
 * Indexa un arreglo de objetos por un campo especificado, devolviendo un objeto donde cada clave es el valor de ese campo en cada objeto.
 * @param {Array} array El arreglo de objetos a indexar.
 * @param {string} fieldName El nombre del campo por el cual indexar.
 * @returns {Object} Objeto indexado por el valor de fieldName en cada objeto del arreglo.
 */
export function indexArrayByField(array, fieldName) {
    return array.reduce((indexedObjects, currentObject) => {
        const key = currentObject[fieldName];
        if (key !== undefined && key !== null) {
            indexedObjects[key] = currentObject;
        }
        return indexedObjects;
    }, {});
}

export function isSimpleObject(obj) {
    return typeof obj === "object" && obj !== null && Object.prototype.toString.call(obj) === "[object Object]";
}

export function flatObject(obj, prefijo = "") {
    let resultado = {};

    Object.keys(obj).forEach((key) => {
        const ruta = prefijo ? `${prefijo}/${key}` : key;

        if (isSimpleObject(obj[key])) {
            Object.assign(resultado, flatObject(obj[key], ruta));
        } else {
            resultado[ruta] = obj[key];
        }
    });

    return resultado;
}
