import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  getNombreFormulario,
  dxMensajePregunta,
  getTrad,
} from "../../../helpers";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

//Custom components
import ListCategoriaConvenio from "./components/ListCategoriaConvenio/index";
import GridCategoriaInterna from "./components/GridCategoriaInterna/index";
import SelectPais from "./components/SelectPais/index";
import { Switch } from "devextreme-react";

import Box, { Item } from "devextreme-react/box";

//Css
import "./Css.scss";

class CategoriasLaborales extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idCategoriaConvenioSel: null,
      idPaisSel: null,
      isOficina_checked: false,
    };

    this.ListCategoriaConvenio = React.createRef();

    this.categoriaConvenio_ListItemClick =
      this.categoriaConvenio_ListItemClick.bind(this);
    this.selectPais_onItemChange = this.selectPais_onItemChange.bind(this);
    this.getSwitchOficina = this.getSwitchOficina.bind(this);
    this.dxSwitch_isOficina_onValueChanged =
      this.dxSwitch_isOficina_onValueChanged.bind(this);
    this.gridCategoriaInterna_onModified =
      this.gridCategoriaInterna_onModified.bind(this);

    this.loadPanel_show();
  }

  render() {
    let { idCategoriaConvenioSel, idPaisSel, isOficina_checked } = this.state;
    let { idioma, user } = this.props;

    if (!user.enableDatosRRHH) return <Redirect to="/" />;
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={[this.getSwitchOficina(), this.getSelectorPais()]}
        />
        <div className={"media-body"}>
          <div
            id="CategoriasLaborales"
            className="formContainer he-100 scrollbar-container"
          >
            <Box direction="row" height="100%" width="100%">
              <Item baseSize={350}>
                <ListCategoriaConvenio
                  ref={this.ListCategoriaConvenio}
                  idPaisSel={idPaisSel}
                  isOficina_checked={isOficina_checked}
                  onItemClick={this.categoriaConvenio_ListItemClick}
                  idioma={idioma}
                />
              </Item>
              <Item baseSize={35} />
              <Item ratio={1}>
                <GridCategoriaInterna
                  idCategoriaConvenioSel={idCategoriaConvenioSel}
                  onModifyGrid={this.gridCategoriaInterna_onModified}
                  loadPanel_hide={this.props.loadPanel_hide}
                  idioma={idioma}
                />
              </Item>
            </Box>
          </div>
        </div>
      </Fragment>
    );
  }

  gridCategoriaInterna_onModified(idCategoriaConvenio) {
    this.ListCategoriaConvenio.current.reload_data(null, idCategoriaConvenio);
  }

  categoriaConvenio_ListItemClick(idSel) {
    this.setState({
      idCategoriaConvenioSel:
        idSel != null && idSel.length > 0 ? idSel[0] : null,
    });
  }

  selectPais_onItemChange(valueSel) {
    this.setState({
      idPaisSel: valueSel,
    });
  }

  getSwitchOficina() {
    let { isOficina_checked } = this.state;
    return (
      <Switch
        key={1}
        height={36}
        width={195}
        switchedOnText="Categorías de oficina"
        switchedOffText="Categorías de lavandería"
        elementAttr={this.dxSwitch_isOficina_elementAttr}
        value={isOficina_checked}
        onValueChanged={this.dxSwitch_isOficina_onValueChanged}
      />
    );
  }

  dxSwitch_isOficina_elementAttr = {
    class: "no-disabled bg-white centerText mr-5 dxSwitch_isOficina",
  };

  dxSwitch_isOficina_onValueChanged(e) {
    this.setState({ isOficina_checked: e.value });
  }

  getSelectorPais() {
    const { idioma } = this.props;
    return (
      <SelectPais
        key={2}
        idioma={idioma}
        onItemChange={this.selectPais_onItemChange}
      />
    );
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    let { user } = this.props;
    if (!user.enableDatosRRHH) {
      let _this = this;
      dxMensajePregunta(getTrad("sinPermisosNecesariosFormulario"), [
        [
          getTrad("aceptar"),
          function (e) {
            _this.loadPanel_hide();
          },
          "danger",
        ],
      ]);
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoriasLaborales);
