import React from "react";

import LottieIcon from "components/LottieIcon";
import { Box } from "devextreme-react";
import { Item } from "devextreme-react/box";

class LoaderDatos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { visible, mensaje } = this.props;
        return (
            <div
                className="align-items-center justify-content-center bg-white d-flex he-100 position-absolute w-100 z-index-6 loader"
                style={
                    !visible && !mensaje
                        ? {
                              transform: "translateY(100%)",
                              opacity: 0,
                              pointerEvents: "none",
                          }
                        : null
                }
            >
                {!mensaje ? (
                    <LottieIcon icon={"loading_input"} height={"50%"} />
                ) : (
                    <Box className="he-100 px-5 align-items-center" direction="row">
                        <Item ratio={2}>
                            <div className="he-100 align-content-center">
                                <LottieIcon icon={"error"} height={"30%"} isLoop={false} />
                            </div>
                        </Item>
                        <Item ratio={5}>
                            <span className="display-4 text-left">{mensaje}</span>
                        </Item>
                    </Box>
                )}
            </div>
        );
    }
}

export default LoaderDatos;
