import React from "react";

import SharedLookupAlmacen from "../SharedLookupAlmacen";

export const origenOptions = {
  dataField: "idAlmacenOrigen",
  colSpan: 3,
  isRequired: true,
};

const AlmacenOrigenItem = ({ formRef, value, onChange, ...props }) => {
  const this_onChange = (value) => {
    const updateObj = {
      idAlmacenOrigen: value,
      idAlmacenDestino: null,
    };

    formRef.current.instance.updateData(updateObj);
    onChange(updateObj);
  };

  return (
    <SharedLookupAlmacen value={value} onChange={this_onChange} {...props} />
  );
};

export default AlmacenOrigenItem;
