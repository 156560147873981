import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";

import PopupGrafica from "./components/PopupGrafica";
import PopupExportar from "./components/PopupExportar";

class LecturaRecursos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recursoContaSel: null,
      isVisible_dxPopup_grafica: false,
      isVisible_dxPopup_exportar: false,
    };

    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    const {
      isVisible_dxPopup_grafica,
      recursoContaSel,
      isVisible_dxPopup_exportar,
    } = this.state;

    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={<div id="dxDateBox_fechaSel"></div>}
        ></PageTitle>
        <div className={"media-body"}>
          <div id="LecturaRecursos" className={"formContainer"}>
            <div id="dxContainer">
              <div data-options="dxItem: {ratio: 1, baseSize: 0}">
                <div id="dxBox_Grids">
                  <div
                    data-options="dxItem: {ratio: 1} "
                    style={{ paddingRight: "25px" }}
                  >
                    <div id="dxDataGrid_RecursosContador"></div>
                  </div>
                </div>
              </div>
              <div></div>
              <div data-options="dxItem: {ratio: 0, baseSize: 30}">
                <div id="dxToolbar_guardarDatos"></div>
              </div>
            </div>
            <div id="dxPopup_finContador"></div>
            <PopupGrafica
              visible={isVisible_dxPopup_grafica}
              recursoContaSel={recursoContaSel}
              onHiding={this.onHiding_dxPopup_grafica}
            />
            <PopupExportar
              visible={isVisible_dxPopup_exportar}
              onHiding={this.onHiding_dxPopup_exportar}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  onShow_dxPopup_grafica = (recursoContaSel) => {
    this.setState({ isVisible_dxPopup_grafica: true, recursoContaSel });
  };

  onHiding_dxPopup_grafica = () => {
    this.setState({ isVisible_dxPopup_grafica: false });
  };

  onHiding_dxPopup_exportar = () => {
    this.setState({ isVisible_dxPopup_exportar: false });
  };

  onShow_dxPopup_exportar = () => {
    this.setState({ isVisible_dxPopup_exportar: true });
  };

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(
        this,
        lavanderia,
        idioma,
        this.onShow_dxPopup_grafica,
        this.onShow_dxPopup_exportar,
      ).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;

    cargaJs(
      this,
      lavanderia,
      idioma,
      this.onShow_dxPopup_grafica,
      this.onShow_dxPopup_exportar,
    ).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LecturaRecursos);
