import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatNumber,
  startOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  filtroTiempo_Resize,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var idLavanderia = LAVANDERIA.idLavanderia,
    fechaDesde = null,
    fechaHasta = null;
  //#endregion

  //#region DATASOURCE
  var datasource_RepartosNEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "infLogistica_spSelectRepartosNEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
      },
      version: 4,
    }),
    sort: "fecha",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
        denoEntidad: itemData.denoEntidad,
        idEntidad: itemData.idEntidad,
        tiempoEntrega: itemData.tiempoEntrega,
        totalKg: itemData.totalKg,
      };
    },
  });
  //#endregion

  $.when(datasource_RepartosNEntidad.load())
    .then(function (items) {
      var ultimaFecha;

      var dataSource_items = [];
      dataSource_items.push(items);

      if (items.length > 0) {
        ultimaFecha = new Date(items[items.length - 1].arg);
      } else {
        ultimaFecha = new Date();
        fechaDesde = startOfMonth(ultimaFecha);
        fechaHasta = new Date(ultimaFecha);
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "dxButton_sinLavanderia_error",
          ],
        ]);
      }

      $("#InfLogistica #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            // GRIDS
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div />").dxBox({
                  direction: "row",
                  align: "space-around",
                  crossAlign: "stretch",
                  height: "100%",
                  items: [
                    {
                      ratio: 5,
                      template: function (e, index, item) {
                        item.append(
                          $(
                            "<div id='dxDataGrid_repartosNEntidad_Agrupados' />",
                          ).dxDataGrid({
                            //Propiedades
                            headerFilter: {
                              visible: true,
                            },
                            export: {
                              enabled: true,
                              texts: {
                                exportAll: getTrad("exportar"),
                              },
                              fileName: getTrad("infLogistica_entidades"),
                            },
                            filterRow: {
                              visible: true,
                              applyFilter: "auto",
                              showAllText: getTrad("todos"),
                            },
                            height: "100%",
                            columnAutoWidth: true,
                            selection: {
                              mode: "single",
                            },
                            paging: {
                              enabled: false,
                            },
                            loadPanel: {
                              enabled: false,
                            },
                            columns: [
                              {
                                dataField: "denoEntidad",
                                caption: getTrad("denominacion"),
                                minWidth: 200,
                                sortIndex: 1,
                                sortOrder: "asc",
                                allowHeaderFiltering: true,
                              },
                              {
                                dataField: "tiempoEntrega",
                                caption: getTrad("tiempoEntrega"),
                                customizeText: function (data) {
                                  return decimalToTime_conversor(data.value);
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                              {
                                dataField: "totalKg",
                                caption: getTrad("totalKg"),
                                customizeText: function (data) {
                                  return formatNumber(data.value);
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                              {
                                name: "KgHora",
                                caption: getTrad("kgHora"),
                                customizeText: function (data) {
                                  return formatNumber(data.value);
                                },
                                calculateCellValue: function (rowData) {
                                  return (
                                    rowData.totalKg / rowData.tiempoEntrega
                                  );
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                            ],
                            summary: {
                              recalculateWhileEditing: true,
                              totalItems: [
                                {
                                  column: "denoEntidad",
                                  displayFormat: getTrad("total").toUpperCase(),
                                },
                                {
                                  column: "tiempoEntrega",
                                  summaryType: "sum",
                                  customizeText: function (data) {
                                    return decimalToTime_conversor(data.value);
                                  },
                                },
                                {
                                  column: "totalKg",
                                  summaryType: "sum",
                                  customizeText: function (data) {
                                    return formatNumber(data.value);
                                  },
                                  displayFormat: "{0}",
                                },
                                {
                                  showInColumn: "KgHora",
                                  name: "KgHora",
                                  summaryType: "custom",
                                  customizeText: function (data) {
                                    return formatNumber(data.value);
                                  },
                                  displayFormat: "{0}",
                                },
                              ],
                              calculateCustomSummary: function (options) {
                                if (options.name === "KgHora") {
                                  if (options.summaryProcess === "start") {
                                    options.totalValue = [0, 0];
                                  }
                                  if (options.summaryProcess === "calculate") {
                                    options.totalValue = [
                                      options.totalValue[0] +
                                        options.value.tiempoEntrega,
                                      options.totalValue[1] +
                                        options.value.totalKg,
                                    ];
                                  }
                                  if (options.summaryProcess === "finalize") {
                                    options.totalValue =
                                      options.totalValue[1] /
                                      options.totalValue[0];
                                  }
                                }
                              },
                            },
                            //Eventos
                            onContentReady: function (e) {
                              e.element
                                .find(".dx-datagrid-export-button")
                                .dxButton("instance")
                                .option("text", getTrad("exportar"));
                            },
                            onSelectionChanged: function (e) {
                              var dxDataGrid_repartosNEntidad = $(
                                "#dxDataGrid_repartosNEntidad",
                              ).dxDataGrid("instance");
                              var filteredData = query(dataSource_items[0])
                                .filter(function (dataItem) {
                                  var idEntidadSel =
                                    typeof e.selectedRowKeys[0] !== "undefined"
                                      ? e.selectedRowKeys[0].idEntidad
                                      : null;
                                  return (
                                    dataItem.idEntidad === idEntidadSel &&
                                    dataItem.arg >= fechaDesde &&
                                    dataItem.arg < fechaHasta
                                  );
                                })
                                .toArray();

                              dxDataGrid_repartosNEntidad.option(
                                "dataSource",
                                filteredData,
                              );
                            },
                            //Estilos
                            showColumnLines: false,
                            showRowLines: true,
                            rowAlternationEnabled: true,
                          }),
                        );
                      },
                    },
                    {
                      ratio: 3,
                      template: function (e, index, item) {
                        item.append(
                          $(
                            "<div id='dxDataGrid_repartosNEntidad' />",
                          ).dxDataGrid({
                            //Propiedades
                            filterRow: {
                              visible: true,
                              applyFilter: "auto",
                              showAllText: getTrad("todos"),
                            },
                            export: {
                              enabled: true,
                              texts: {
                                exportAll: getTrad("exportar"),
                              },
                              fileName: getTrad("infLogistica_fecha"),
                            },
                            height: "100%",
                            columnAutoWidth: true,
                            paging: {
                              enabled: false,
                            },
                            loadPanel: {
                              enabled: false,
                            },
                            columns: [
                              {
                                dataField: "arg",
                                caption: getTrad("fecha"),
                                dataType: "date",
                                format: "dd/MM/yyyy",
                                minWidth: 150,
                                sortIndex: 1,
                                sortOrder: "asc",
                                allowFiltering: true,
                              },
                              {
                                dataField: "tiempoEntrega",
                                caption: getTrad("tiempoEntrega"),
                                customizeText: function (data) {
                                  return decimalToTime_conversor(data.value);
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                              {
                                dataField: "totalKg",
                                caption: getTrad("totalKg"),
                                customizeText: function (data) {
                                  return formatNumber(data.value);
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                              {
                                name: "KgHora",
                                caption: getTrad("kgHora"),
                                customizeText: function (data) {
                                  return formatNumber(data.value);
                                },
                                calculateCellValue: function (rowData) {
                                  return (
                                    rowData.totalKg / rowData.tiempoEntrega
                                  );
                                },
                                width: 150,
                                allowFiltering: false,
                                alignment: "center",
                              },
                            ],
                            //EVENTOS
                            onContentReady: function (e) {
                              e.element
                                .find(".dx-datagrid-export-button")
                                .dxButton("instance")
                                .option("text", getTrad("exportar"));
                            },
                            onExporting: function (e) {
                              if (
                                $("#dxDataGrid_repartosNEntidad_Agrupados")
                                  .dxDataGrid("instance")
                                  .option("selectedRowKeys").length === 0
                              ) {
                                dxMensajePregunta(getTrad("sinEntiSel_error"), [
                                  [
                                    getTrad("aceptar"),
                                    function (e) {},
                                    "danger",
                                    "dxButton_sinEntiSel_error",
                                  ],
                                ]);
                                e.cancel = true;
                              }
                            },
                            summary: {
                              recalculateWhileEditing: true,
                              totalItems: [
                                {
                                  column: "arg",
                                  displayFormat: getTrad("total").toUpperCase(),
                                },
                                {
                                  column: "tiempoEntrega",
                                  summaryType: "sum",
                                  customizeText: function (data) {
                                    return decimalToTime_conversor(data.value);
                                  },
                                },
                                {
                                  column: "totalKg",
                                  summaryType: "sum",
                                  customizeText: function (data) {
                                    return formatNumber(data.value);
                                  },
                                  displayFormat: "{0}",
                                },
                                {
                                  showInColumn: "KgHora",
                                  name: "KgHora",
                                  summaryType: "custom",
                                  customizeText: function (data) {
                                    return formatNumber(data.value);
                                  },
                                  displayFormat: "{0}",
                                },
                              ],
                              calculateCustomSummary: function (options) {
                                if (options.name === "KgHora") {
                                  if (options.summaryProcess === "start") {
                                    options.totalValue = [0, 0];
                                  }
                                  if (options.summaryProcess === "calculate") {
                                    options.totalValue = [
                                      options.totalValue[0] +
                                        options.value.tiempoEntrega,
                                      options.totalValue[1] +
                                        options.value.totalKg,
                                    ];
                                  }
                                  if (options.summaryProcess === "finalize") {
                                    options.totalValue =
                                      options.totalValue[1] /
                                      options.totalValue[0];
                                  }
                                }
                              },
                            },
                            //Estilos
                            showColumnLines: false,
                            showRowLines: true,
                            rowAlternationEnabled: true,
                          }),
                        );
                      },
                    },
                  ],
                }),
              );
            },
          },
          {
            // FILTRO TIEMPO
            baseSize: 130,
            template: function (itemData, itemIndex, itemElement) {
              itemElement.append(
                create_rangeSelector(
                  datasource_RepartosNEntidad,
                  false,
                  false,
                  function (e) {
                    fechaDesde = e.value[0];
                    fechaHasta = e.component.option("valueHasta");

                    agrupar_repartosNEntidad(items);
                  },
                ),
              );
            },
          },
        ],
      });

      agrupar_repartosNEntidad(items);
    })
    .always(function () {
      setTimeout(() => {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  //#region FUNCIONES REUTILIZABLES

  function decimalToTime_conversor(tiempo) {
    var hora = Math.floor(Math.abs(tiempo));
    var min = Math.round((Math.abs(tiempo) * 60) % 60);
    return (hora < 10 ? "0" : "") + hora + ":" + (min < 10 ? "0" : "") + min;
  }

  function agrupar_repartosNEntidad(items) {
    if (!fechaDesde || !fechaHasta) {
      var dxRangeSelector_filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo",
      ).dxRangeSelector("instance");
      fechaDesde = dxRangeSelector_filtroTiempo.option("value")[0];
      fechaHasta = dxRangeSelector_filtroTiempo.option("valueHasta");
    }
    // FILTRA LOS DATOS POR FECHA, LOS AGRUPA POR ENTIDAD Y SUMA EL RESTO DE DATOS
    var data = query(items)
      .filter(function (dataItem) {
        return dataItem.arg >= fechaDesde && dataItem.arg < fechaHasta;
      })
      .select(["idEntidad", "tiempoEntrega", "totalKg", "denoEntidad"])
      .groupBy(function (dataItem) {
        return [dataItem.idEntidad, dataItem.denoEntidad];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("tiempoEntrega")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              tiempoEntrega: result,
            };
          });
        query(dataItem.items)
          .sum("totalKg")
          .done(function (result) {
            resultItem = {
              idEntidad: dataItem.key[0],
              denoEntidad: dataItem.key[1],
              tiempoEntrega: resultItem.tiempoEntrega,
              totalKg: result,
            };
          });
        return resultItem;
      })
      .toArray();

    var dxDataGrid_repartosNEntidad_Agrupados = $(
      "#dxDataGrid_repartosNEntidad_Agrupados",
    ).dxDataGrid("instance");
    dxDataGrid_repartosNEntidad_Agrupados.option({
      selectedRowKeys: [],
      dataSource: data,
    });
  }
  //#endregion

  return deferred.promise();
}
