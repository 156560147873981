import { Button, DataGrid } from "devextreme-react";
import { getNombreFormulario, getTrad, patchRequestHandler } from "helpers";
import PageTitle from "layout/AppMain/PageTitle";
import React from "react";
import { connect } from "react-redux";
import {
  dataSource_categoriaInterna_solicitudGestoria,
  dataSource_categoriasConvenio_solicitudGestoria,
  dataSource_tblSolicitudGestoria,
  dataSource_tipoContrato_solicitudGestoria,
  datasource_empresaPolarier_solicitudGestoria,
} from "./datasources";
import { Column, Editing, FilterRow, Scrolling, Sorting, Lookup as DataGrid_Lookup } from "devextreme-react/data-grid";
import SelectorEstadoSolicitudGestoria from "./SelectorEstadoSolicitudGestoria";
import { Tooltip } from "devextreme-react/tooltip";
import PopupDatosPersona from "./PopupDatosPersona";
import notify from "devextreme/ui/notify";
import PopUpContrato from "./PopUpContrato";
import PopUpCodigoGestoria from "./PopUpCodigoGestoria";

class SolicitudGestoria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource_tblSolicitudGestoria: null,
      estadoSel_aceptado: false,
      filtroUrgentes: false,
      idPersonaSel: null,
      showPopupDatosPersona: false,
      showPopupContrato: false,
      showPopupCodigoGestoria: false,
      solicitudGestoriaSel: null,
    };

    this.dataGrid_solicitudGestoria_REF = React.createRef();
  }

  componentDidMount() {
    const { estadoSel_aceptado } = this.state;

    dataSource_tblSolicitudGestoria(estadoSel_aceptado)
      .load()
      .done((data) => {
        this.setState({ dataSource_tblSolicitudGestoria: data });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { estadoSel_aceptado } = this.state;
    //Cuando cambia el estado de la solicitud de gestoria recarga solictudes
    if (prevState.estadoSel_aceptado !== estadoSel_aceptado) {
      dataSource_tblSolicitudGestoria(estadoSel_aceptado)
        .load()
        .done((data) => {
          this.setState({ dataSource_tblSolicitudGestoria: data });
        });
    }
  }

  onValueChange = (newState, callback) => {
    this.setState(newState, callback);
  };

  seleccionaPersona = (e) => {
    const { idPersonaNavigation } = e.data;
    const { idPersona } = idPersonaNavigation;

    this.setState({ idPersonaSel: idPersona, showPopupDatosPersona: true });
  };

  textMayus = (cellInfo) => {
    return cellInfo.valueText.toUpperCase();
  };

  onToolbarPreparing = ({ toolbarOptions, component }) => {
    const { estadoSel_aceptado } = this.state;
    toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: () => {
            dataSource_tblSolicitudGestoria(estadoSel_aceptado).reload();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: () => {
            component.clearFilter();
          },
        },
      }
    );
  };

  render() {
    const {
      dataSource_tblSolicitudGestoria,
      showPopupDatosPersona,
      idPersonaSel,
      estadoSel_aceptado,
      showPopupContrato,
      showPopupCodigoGestoria,
      solicitudGestoriaSel,
    } = this.state;
    const { idioma, user } = this.props;
    const heading = getNombreFormulario({ props: { idioma, user } });

    return (
      <>
        <PageTitle
          heading={heading}
          postHeading={
            <SelectorEstadoSolicitudGestoria onValueChange={this.onValueChange} value={estadoSel_aceptado} />
          }
        />
        <DataGrid
          dataSource={dataSource_tblSolicitudGestoria}
          showRowLines
          columnsAutoWidth
          rowAlternationEnabled
          showColumnLines={false}
          remoteOperations={true}
          height={"100%"}
          className="pb-5 pt-3"
          ref={this.dataGrid_solicitudGestoria_REF}
          hoverStateEnabled={true}
          onToolbarPreparing={this.onToolbarPreparing}
        >
          <Scrolling mode="infinite" />
          <Sorting mode={"multiple"} />
          <FilterRow visible={true} />
          <Editing mode={"cell"} allowUpdating />

          <Column
            dataField="isUrgente"
            caption={""}
            allowEditing={false}
            width={50}
            allowFiltering={false}
            allowReordering={false}
            cellRender={this.renderUrgentes}
            sortOrder={"desc"}
            sortIndex={0}
          />
          <Column
            cellComponent={this.visualizaDatosPersona}
            width={40}
            allowEditing={false}
            allowFiltering={false}
            allowSorting={false}
            allowHeaderFiltering={false}
          />
          <Column
            dataField="idPersonaNavigation.nombre"
            caption={getTrad("nombre")}
            allowEditing={false}
            sortOrder={"asc"}
            minWidth={"120px"}
            sortIndex={2}
          />
          <Column dataField="idPersonaNavigation.apellidos" caption={getTrad("apellidos")} allowEditing={false} />
          <Column
            dataField="idPersonaNavigation.idEmpresaPolarier"
            caption={getTrad("empresa")}
            alignment={"center"}
            minWidth={"100px"}
            allowEditing={false}
          >
            <DataGrid_Lookup
              dataSource={datasource_empresaPolarier_solicitudGestoria(estadoSel_aceptado).store()}
              valueExpr="idEmpresaPolarier"
              displayExpr="denominacion"
            />
          </Column>
          <Column
            dataField="idPersonaNavigation.idCategoriaInternaNavigation.idCategoriaConvenio"
            caption={getTrad("categoriaConvenio")}
            allowEditing={false}
            minWidth={"110px"}
            customizeText={this.textMayus}
          >
            <DataGrid_Lookup
              dataSource={dataSource_categoriasConvenio_solicitudGestoria(estadoSel_aceptado).store()}
              valueExpr="idCategoriaConvenio"
              displayExpr="denominacion"
            />
          </Column>
          <Column
            dataField="idPersonaNavigation.idCategoriaInterna"
            caption={getTrad("categoriaInterna")}
            customizeText={this.textMayus}
            minWidth={"120px"}
            allowEditing={false}
          >
            <DataGrid_Lookup
              dataSource={dataSource_categoriaInterna_solicitudGestoria(estadoSel_aceptado).store()}
              valueExpr="idCategoriaInterna"
              displayExpr="denominacion"
            />
          </Column>
          <Column
            dataField="idPersonaNavigation.idCentroLav"
            alignment={"center"}
            caption={getTrad("centroTrabajo")}
            customizeText={this.textMayus}
            allowEditing={false}
          />
          <Column
            dataField="idPersonaNavigation.tblPersonaNTipoContrato[0].idTipoContrato"
            alignment={"center"}
            caption={getTrad("tipoContrato")}
            customizeText={this.textMayus}
            minWidth={"100px"}
            allowEditing={false}
          >
            <DataGrid_Lookup
              dataSource={dataSource_tipoContrato_solicitudGestoria(estadoSel_aceptado).store()}
              valueExpr="idTipoContrato"
              displayExpr="denominacion"
            />
          </Column>
          <Column
            dataField="idPersonaNavigation.codigoGestoria"
            alignment={"center"}
            caption={getTrad("codigoGestoria")}
            customizeText={this.textMayus}
            allowEditing={false}
            minWidth={"120px"}
            cellComponent={this.renderCodigoGestoria}
          />
          <Column
            dataField="fecha_alta"
            alignment={"center"}
            caption={getTrad("fechaAlta")}
            customizeText={this.textMayus}
            minWidth={"110px"}
            format="dd/MM/yyyy"
            sortOrder={"desc"}
            allowEditing={false}
            sortIndex={1}
          />
          <Column
            dataField={getTrad("estado")}
            alignment={"center"}
            caption={getTrad("estado")}
            cssClass={"p-0"}
            width={110}
            allowEditing={false}
            cellComponent={this.renderEstadoSolicitud}
          />
          <Column
            dataField={getTrad("estado")}
            alignment={"center"}
            caption={""}
            cssClass={"p-0"}
            width={110}
            allowEditing={false}
            cellComponent={this.renderEstado}
          />
        </DataGrid>
        {/* ZONA POPUPS */}
        <PopupDatosPersona
          visible={showPopupDatosPersona}
          onValueChange={this.onValueChange}
          idPersonaSel={idPersonaSel}
        />
        <PopUpContrato
          visible={showPopupContrato}
          onValueChange={this.onValueChange}
          idPersonaSel={idPersonaSel}
          estadoSel_aceptado={estadoSel_aceptado}
          solicitudGestoriaSel={solicitudGestoriaSel}
        />
        <PopUpCodigoGestoria
          visible={showPopupCodigoGestoria}
          onValueChange={this.onValueChange}
          idPersonaSel={idPersonaSel}
          estadoSel_aceptado={estadoSel_aceptado}
          solicitudGestoriaSel={solicitudGestoriaSel}
        />
      </>
    );
  }

  renderUrgentes = (e) => {
    const isUrgente = e.data.isUrgente;
    const id = "urgente_" + e.rowIndex;

    return (
      isUrgente && (
        <div id={id} className="d-flex justify-content-center align-items-center" style={{ margin: 0 }}>
          <i className="icon_Warning danger" style={{ fontSize: 25, lineHeight: 0, margin: 0 }} />
          <Tooltip
            animation={{
              hide: {
                type: "pop",
                from: {
                  scale: 1,
                  opacity: 1,
                },
                to: {
                  opacity: 0,
                  scale: 0.1,
                },
              },
              show: {
                type: "pop",
                from: {
                  scale: 0.1,
                  opacity: 0,
                },
                to: {
                  opacity: 1,
                  scale: 1,
                },
              },
            }}
            target={"#" + id}
            position="left"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            <div>{getTrad("solicitudUrgente")}</div>
          </Tooltip>
        </div>
      )
    );
  };

  renderCodigoGestoria = (cell) => {
    const { codigoGestoria, idPersona } = cell.data.data.idPersonaNavigation;
    const solicitudGestoria = cell.data.data;
    const aceptada = solicitudGestoria.idEstadoSolicitudGestoria === 3;

    const configCodGestoria = () => {
      this.setState(
        {
          showPopupCodigoGestoria: true,
          solicitudGestoriaSel: solicitudGestoria,
        },
        () => {
          this.setState({ idPersonaSel: idPersona });
        }
      );
    };

    const text = codigoGestoria ? codigoGestoria : "Asignar código";

    return (
      <div
        onClick={aceptada ? null : configCodGestoria}
        className="d-flex flex-column p-1 shadow-sm pointer "
        style={{
          borderWidth: 1,
          borderStyle: "dashed",
          borderColor: "var(--primary)",
          borderRadius: 5,
        }}
      >
        {text}
      </div>
    );
  };

  renderEstado = (cell) => {
    const aceptada = cell.data.data.idEstadoSolicitudGestoria === 3;
    const text = aceptada ? getTrad("validado") : getTrad("validar");
    const bgColor = aceptada ? "var(--success)" : "var(--primary)";

    const validar = () => {
      const { idPersona } = cell.data.data.idPersonaNavigation;
      // Muestra el popup de contrato
      this.setState({ showPopupContrato: true, idPersonaSel: idPersona, solicitudGestoriaSel: cell.data.data });
    };

    return (
      <Button
        type="normal"
        className="d-flex w-100 justify-content-center align-items-center text-white d-flex shadow-sm pointer he-100 p-1"
        onClick={aceptada ? null : validar}
        style={{ backgroundColor: bgColor, borderRadius: 5 }}
      >
        {text}
      </Button>
    );
  };

  renderEstadoSolicitud = ({ data }) => {
    // idEstadoSolicitudGestoria 1: Pendiente código gestoría, 2: Pendiente documentación, 3: Validado
    const solicitudGestoria = data.data;
    const iconIdCodigo = "iconCodigo_" + data.rowIndex;
    const iconIdDocumentacion = "iconDocumentacion_" + data.rowIndex;
    const estado = solicitudGestoria.idEstadoSolicitudGestoria;
    const iconClassCodigo = estado === 1 ? "icon_Check lightGray" : "icon_Check success";
    const iconClassDocumentacion = estado === 2 || estado < 2 ? "icon_Check lightGray" : "icon_Check success";

    const textCodigo = estado === 1 ? getTrad("pendienteCodigoGestoria") : getTrad("codigoGestoriaAsignado");
    const textDocumentacion =
      estado === 2 || estado < 2 ? getTrad("pendienteDocumentacion") : getTrad("documentacionAsignada");

    return (
      <div className="d-flex justify-content-center align-items-center" style={{ margin: 0 }}>
        <div
          id={iconIdCodigo}
          className={iconClassCodigo}
          style={{ borderRadius: "5px", fontSize: 25, lineHeight: 0, margin: 0 }}
        />
        <div
          id={iconIdDocumentacion}
          className={iconClassDocumentacion}
          style={{ borderRadius: "5px", fontSize: 25, lineHeight: 0, margin: 0 }}
        />
        <Tooltip
          animation={{
            hide: {
              type: "pop",
              from: {
                scale: 1,
                opacity: 1,
              },
              to: {
                opacity: 0,
                scale: 0.1,
              },
            },
            show: {
              type: "pop",
              from: {
                scale: 0.1,
                opacity: 0,
              },
              to: {
                opacity: 1,
                scale: 1,
              },
            },
          }}
          target={"#" + iconIdCodigo}
          position="left"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{textCodigo}</div>
        </Tooltip>
        <Tooltip
          animation={{
            hide: {
              type: "pop",
              from: {
                scale: 1,
                opacity: 1,
              },
              to: {
                opacity: 0,
                scale: 0.1,
              },
            },
            show: {
              type: "pop",
              from: {
                scale: 0.1,
                opacity: 0,
              },
              to: {
                opacity: 1,
                scale: 1,
              },
            },
          }}
          target={"#" + iconIdDocumentacion}
          position="left"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{textDocumentacion}</div>
        </Tooltip>
      </div>
    );
  };

  visualizaDatosPersona = (cell) => {
    return (
      <div className="d-flex justify-content-center align-items-center  w-100" style={{ margin: 0, padding: 0 }}>
        <i
          className="icon_Edit pointer"
          style={{ fontSize: 25, margin: 0, lineHeight: 0 }}
          onClick={() => this.seleccionaPersona(cell.data)}
        />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolicitudGestoria);
