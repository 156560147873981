import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../../actions";

//Component
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../../helpers";

//Js
import cargaJs from "./Js.js";

//CSS
import "./Css.scss";

class InfInventarios extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeadingBegin={
            <div
              id="infoCompaEnti_container"
              className="font-size-lg flex-row "
              style={{ gap: "15px", display: "none" }}
            >
              <div>{" - "}</div>
              <div id="infoTipoInfInv">{"text"}</div>
              <div>{" - "}</div>
              <div id="infoCompaEnti">{"text"}</div>
            </div>
          }
        />

        {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
        <div id="InfInventarios" className={"formContainer"}>
          <div id="dxContainer"></div>
        </div>
        {/* </ReactCSSTransitionGroup> */}
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;
    cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfInventarios);
