import { PureComponent } from "react";
import CurrencyFormatter from "./CurrencyFormatter";

class CurrencyFormatterCell extends PureComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { valor, idMoneda } = this.props.data.data;
        return <CurrencyFormatter value={valor} idMoneda={idMoneda} />;
    }
}

export default CurrencyFormatterCell;
