import React, { Fragment } from "react";
import { connect } from "react-redux";
import cx from "classnames";

import ReportViewer from "../../components/ReportViewer";
import { impresionActions } from "../../actions";

class Impresion extends React.Component {
  constructor(props) {
    super(props);
    this.closeImpresion = this.closeImpresion.bind(this);
  }

  closeImpresion() {
    this.props.close();
  }

  render() {
    let { opened, report, params } = this.props;
    return (
      <Fragment>
        <div id="reportViewer_container" className={cx({ opened: opened })}>
          <ReportViewer report={report} params={params} />
          <div
            id="reportViewer_close"
            className="container_spanCentrado"
            onClick={this.closeImpresion}
          >
            <div className="icon_Cerrar h1" />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  opened: state.Impresion.opened,
  report: state.Impresion.report,
  params: state.Impresion.params,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Impresion);
