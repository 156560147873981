import { getArticulos, getDefaultIvaId, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearAlbaran = () => {
    return {
        idAdmAlbaranVenta: null,
        codigo: null,
        idTipoAlbaran: null,
        fechaCreacion: null,
        numPedidoCliente_id: null,
        numPedidoCliente_codigo: null,
        idAdmCliente: null,
        idAdmAlbaran_Estado: 1,
        idTipoFactura: null,
        idAdmFormaPago: null,
        descuento: 0,
        idAdmTipoDescuento: 1, //idAdmTipoDescuento
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1, //idTipoCambio
        idIvaNPais: getDefaultIvaId(false),
        tasaCambio: 1,
        observaciones: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        idIncoterm: null,
        articulosDraft: [],
        tblArticuloNAdmAlbaranVenta: [
            // {
            //   idArticulo: 1,
            //   codigoArticulo: 'ART00001',
            //   descripcion: 'Artículo 1',
            //   cantidad: 1,
            //   precio: 1,
            // }
        ],
    };
};

export const payloadBuilder = (data, queryReady = true) => {
    if (queryReady) {
        let payload = {
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idTipoAlbaran: data.idTipoAlbaran,
            idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
            idAdmCliente: data.idAdmCliente,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idAdmFormaPago: data.idAdmFormaPago,
            idAdmPedidoCliente: data.numPedidoCliente_id,
            idTipoFactura: data.idTipoFactura,
            idIvaNPais: data.idIvaNPais,
            observaciones: data.observaciones,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            idIncoterm: data.idIncoterm,
            idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
            tblArticuloNAdmAlbaranVenta: getArticulos(data.tblArticuloNAdmAlbaranVenta, queryReady),
        };
        if (data.idAdmAlbaranVenta != null) {
            payload.idAdmAlbaranVenta = data.idAdmAlbaranVenta;
        }
        return payload;
    } else {
        return {
            idAdmAlbaranVenta: data.idAdmAlbaranVenta,
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idTipoAlbaran: data.idTipoAlbaran,
            idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
            idAdmCliente: data.idAdmCliente,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idAdmFormaPago: data.idAdmFormaPago,
            numPedidoCliente_id: data.idAdmPedidoCliente,
            numPedidoCliente_codigo: data?.idAdmPedidoClienteNavigation?.codigo,
            idTipoFactura: data.idTipoFactura,
            idIvaNPais: data.idIvaNPais,
            observaciones: data.observaciones,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            idIncoterm: data.idIncoterm,
            // numPedidoClienteNavigation: data.numPedidoClienteNavigation,
            tblArticuloNAdmAlbaranVenta: getArticulos(data.tblArticuloNAdmAlbaranVenta),
        };
    }
};
