import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  errorHandler,
  authHeader,
  formatNumber,
  getTrad,
  getNombreFormulario,
  formatDate_parameter,
  history,
} from "../../../helpers";

//Actions
import {
  loadPanelActions,
  globalActions,
  impresionActions,
} from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CardSelector from "components/CardSelector";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { Col, Row } from "reactstrap";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { SelectBox } from "devextreme-react/select-box";
import { Tooltip } from "devextreme-react/tooltip";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
} from "devextreme-react/data-grid";

import { progressBarCellComponent } from "../../../components/DataGrid/Cells";
import {
  CircularGauge,
  Geometry,
  Scale,
  Tick,
  RangeContainer,
  Range,
  Label,
  ValueIndicator,
  Margin,
} from "devextreme-react/circular-gauge";

//Css
import "./Css.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);

    let { param_paginaSecundaria } = this.props;
    this.state = {
      fechaSel:
        param_paginaSecundaria != null
          ? new Date(param_paginaSecundaria.fechaDesde)
          : new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              1,
              0,
              0,
              0,
            ),
      is_lavCard_click: false,
      sistemaSel: null,
      categoriaSel: null,
      datosSelector_Lavanderia: [],
      datosSelector_SistemasMaquina: [],
      datosSelector_CategoriasMaquina: [],
      datosSelector_Maquina: [],
      dxMultiView_index: 0,
      idCorporacionSel: null,
      idPaisSel: null,
      firstLoad: true, // solo muestra el backbutton a dash ejecutivo en la primera carga
      hayCambiosFecha: false,
    };

    //REFERENCIAS
    this.dxDataGrid_tblMaquinas_REF = React.createRef();

    //DATASOURCE
    this.dataSource_spSelectDashboardMantenimiento_Selector_beforeSend =
      this.dataSource_spSelectDashboardMantenimiento_Selector_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardMantenimiento_Selector_postProcess =
      this.dataSource_spSelectDashboardMantenimiento_Selector_postProcess.bind(
        this,
      );
    this.dataSource_tblCorporacion_onLoaded =
      this.dataSource_tblCorporacion_onLoaded.bind(this);
    this.dataSource_tblPais_onLoaded =
      this.dataSource_tblPais_onLoaded.bind(this);

    //SELECTOR LAVANDERIA
    this.onCardClick_Lavanderia = this.onCardClick_Lavanderia.bind(this);
    this.onCardClick_SistemasMaquina =
      this.onCardClick_SistemasMaquina.bind(this);
    this.onCardClick_CategoriasMaquina =
      this.onCardClick_CategoriasMaquina.bind(this);
    this.onCardClick_Maquinas = this.onCardClick_Maquinas.bind(this);

    //EVENTOS
    this.onContentReady_gridMaquinas =
      this.onContentReady_gridMaquinas.bind(this);

    //DATE PICKER
    this.prevDate_Click = this.prevDate_Click.bind(this);
    this.nextDate_Click = this.nextDate_Click.bind(this);
    this.datePicker_onValueChanged = this.datePicker_onValueChanged.bind(this);
    this.datePicker_displayFormat = this.datePicker_displayFormat.bind(this);

    //SELECTOR CORPORACIÓN
    this.getSelectorCorporacion = this.getSelectorCorporacion.bind(this);
    this.selectorCorporacion_onSelectionChanged =
      this.selectorCorporacion_onSelectionChanged.bind(this);

    //SELECTOR PAÍS
    this.getSelectorPais = this.getSelectorPais.bind(this);
    this.selectorPais_onSelectionChanged =
      this.selectorPais_onSelectionChanged.bind(this);

    //BACK BUTTON
    this.backButton_onClick = this.backButton_onClick.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //REPORT VIEWER
    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
    //PrintButton
    this.printButton_onClick = this.printButton_onClick.bind(this);
  }

  get dxDataGrid_tblMaquinas() {
    return this.dxDataGrid_tblMaquinas_REF.current.instance;
  }

  //#region DataSources

  //#region Lavanderias
  dataSource_spSelectDashboardMantenimiento_Selector = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spSelectDashboardMantenimiento_Selector",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardMantenimiento_Selector_beforeSend(
          request,
        );
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.dataSource_spSelectDashboardMantenimiento_Selector_postProcess(
        data,
      );
    },
    onChanged: () => {
      this.dataSource_spSelectDashboardMantenimiento_Selector_onChanged();
    },
  });
  dataSource_spSelectDashboardMantenimiento_Selector_beforeSend(request) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idLavanderia = null;
    request.params.idSistemaMaquina = null;
    request.params.idCategoriaMaquina = null;
  }
  dataSource_spSelectDashboardMantenimiento_Selector_postProcess(data) {
    let _this = this;
    let paises = this.dataSource_tblPais.items();
    $.each(data, function (index, item) {
      item.id = item.idLavanderia;
      item.cardClass = "row-1";
      item.pais = paises.filter(
        (x) => x.idPais === item.idPais,
      )[0].tblTraduccion.clave;

      let minTotales = item.minutos_totales;
      item.porcentajeDisponibilidad = Math.abs(
        (item.minRojo + item.minAmarillo) / minTotales - 1,
      );
      item.porcentajeRoja = item.minRojo / minTotales;
      item.porcentajeAmarilla = item.minAmarillo / minTotales;

      item.gasto_manoObra = 0;

      item.bodyContent = function (item) {
        return _this.get_BodyContent(item);
      };
    });
    return data;
  }
  dataSource_spSelectDashboardMantenimiento_Selector_onChanged() {
    const { idPaisSel, idCorporacionSel } = this.state;
    let items = this.dataSource_spSelectDashboardMantenimiento_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );

    this.setState({
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //#region Sistemas Máquinas
  dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector =
    new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "spSelectDashboardMantenimiento_Selector",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_beforeSend(
            request,
          );
        },
        version: 4,
      }),
      postProcess: (data) => {
        return this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_postProcess(
          data,
        );
      },
      onChanged: () => {
        this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_onChanged();
      },
    });
  dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_beforeSend(
    request,
  ) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    let { lavanderia } = this.props;

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.idSistemaMaquina = null;
    request.params.idCategoriaMaquina = null;
  }
  dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_postProcess(
    data,
  ) {
    let _this = this;
    let paises = this.dataSource_tblPais.items();
    $.each(data, function (index, item) {
      item.id = item.idSistemaMaquina;
      item.cardClass = "row-1";
      item.pais = paises.filter(
        (x) => x.idPais === item.idPais,
      )[0].tblTraduccion.clave;

      let minTotales = item.minutos_totales;
      item.porcentajeDisponibilidad = Math.abs(
        (item.minRojo + item.minAmarillo) / minTotales - 1,
      );
      item.porcentajeRoja = item.minRojo / minTotales;
      item.porcentajeAmarilla = item.minAmarillo / minTotales;

      item.gasto_manoObra = 0;

      item.denominacion =
        item.denominacion == null ? getTrad("sinAsignar") : item.denominacion;

      item.bodyContent = function (item) {
        return _this.get_BodyContent(item);
      };
    });
    return data;
  }
  dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector_onChanged() {
    let _this = this,
      items =
        this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector.items();

    $.each(items, function (index, item) {
      item.color = _this.get_CardColor(item.porcentajeDisponibilidad, true);
    });
    this.setState({ datosSelector_SistemasMaquina: items });
  }
  //#endregion

  //#region Categorias Máquina
  dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector =
    new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "spSelectDashboardMantenimiento_Selector",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_beforeSend(
            request,
          );
        },
        version: 4,
      }),
      postProcess: (data) => {
        return this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_postProcess(
          data,
        );
      },
      onChanged: () => {
        this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_onChanged();
      },
    });
  dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_beforeSend(
    request,
  ) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    let { sistemaSel } = this.state;
    let { lavanderia } = this.props;

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.idSistemaMaquina = sistemaSel
      ? sistemaSel.idSistemaMaquina
      : null;
    request.params.idCategoriaMaquina = null;
  }
  dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_postProcess(
    data,
  ) {
    let _this = this;
    let paises = this.dataSource_tblPais.items();
    $.each(data, function (index, item) {
      item.id = item.idCategoriaMaquina;
      item.cardClass = "row-1";
      item.pais = paises.filter(
        (x) => x.idPais === item.idPais,
      )[0].tblTraduccion.clave;

      let minTotales = item.minutos_totales;
      item.porcentajeDisponibilidad = Math.abs(
        (item.minRojo + item.minAmarillo) / minTotales - 1,
      );
      item.porcentajeRoja = item.minRojo / minTotales;
      item.porcentajeAmarilla = item.minAmarillo / minTotales;

      item.gasto_manoObra = 0;

      item.denominacion =
        item.denominacion == null ? getTrad("sinAsignar") : item.denominacion;
      item.bodyContent = function (item) {
        return _this.get_BodyContent(item);
      };
    });

    return data;
  }
  dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector_onChanged() {
    let _this = this,
      items =
        this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector.items();

    $.each(items, function (index, item) {
      item.color = _this.get_CardColor(item.porcentajeDisponibilidad, true);
    });

    let categoriaSel = items.length > 0 ? items[0] : null;
    if (this.state.hayCambiosFecha)
      this.setState({
        datosSelector_CategoriasMaquina: items,
        hayCambiosFecha: false,
      });
    else
      this.setState({
        datosSelector_CategoriasMaquina: items,
        categoriaSel: categoriaSel,
        hayCambiosFecha: false,
      });
  }
  //#endregion

  //#region Máquinas
  dataSource_spSelectDashboardMantenimiento_maquina_Selector = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spSelectDashboardMantenimiento_Selector",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardMantenimiento_maquina_Selector_beforeSend(
          request,
        );
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.dataSource_spSelectDashboardMantenimiento_maquina_Selector_postProcess(
        data,
      );
    },
    onChanged: () => {
      this.dataSource_spSelectDashboardMantenimiento_maquina_Selector_onChanged();
    },
  });
  dataSource_spSelectDashboardMantenimiento_maquina_Selector_beforeSend(
    request,
  ) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    let { sistemaSel, categoriaSel } = this.state;
    let { lavanderia } = this.props;

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.idSistemaMaquina = sistemaSel
      ? sistemaSel.idSistemaMaquina
      : null;
    request.params.idCategoriaMaquina =
      categoriaSel == null ? null : categoriaSel.idCategoriaMaquina;
  }
  dataSource_spSelectDashboardMantenimiento_maquina_Selector_postProcess(data) {
    let _this = this;
    let paises = this.dataSource_tblPais.items();

    $.each(data, function (index, item) {
      item.id = item.idMaquina;
      item.cardClass = "";
      item.pais = paises.filter(
        (x) => x.idPais === item.idPais,
      )[0].tblTraduccion.clave;

      let minTotales = item.minutos_totales;
      item.porcentajeDisponibilidad = Math.abs(
        (item.minRojo + item.minAmarillo) / minTotales - 1,
      );
      item.porcentajeRoja = item.minRojo / minTotales;
      item.porcentajeAmarilla = item.minAmarillo / minTotales;

      item.gasto_extra = item.gasto_extra == null ? 0 : item.gasto_extra;
      item.gasto_recambio =
        item.gasto_recambio == null ? 0 : item.gasto_recambio;
      item.gasto_manoObra = 0;

      item.denominacion = item.denominacion;
      item.progressBarCellOptions_porcentajeDisponibilidad = {};
      item.progressBarCellOptions_porcentajeDisponibilidad.max = 1;
      item.progressBarCellOptions_porcentajeDisponibilidad.color =
        _this.get_CardColor(item.porcentajeDisponibilidad);

      item.headerContent = function (item) {
        return _this.get_HeaderContent_Maquina(item);
      };
      item.bodyContent = function (item) {
        return _this.get_BodyContent_Maquina(item);
      };
    });
    return data;
  }
  dataSource_spSelectDashboardMantenimiento_maquina_Selector_onChanged() {
    let _this = this,
      items =
        this.dataSource_spSelectDashboardMantenimiento_maquina_Selector.items();

    $.each(items, function (index, item) {
      item.color = _this.get_CardColor(item.porcentajeDisponibilidad);
    });

    let dataSource_maquinas = [
      {
        id: 1,
        value: items,
        cardClass: "heightFixed",
        denominacion: getTrad("maquinas"),
        headerContent: function (item) {
          return _this.get_HeaderContent_Maquina(item);
        },
        bodyContent: function (item) {
          return _this.get_BodyContent_Maquina(item);
        },
      },
    ];

    this.setState({ datosSelector_Maquina: dataSource_maquinas }, () => {
      _this.loadPanel_hide();
    });
  }
  //#endregion

  dataSource_tblCorporacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCorporacion",
      key: "idCorporacion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblCorporacion_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblCorporacion_onLoaded(data);
      },
      version: 4,
    }),
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblCorporacion_postProcess(data);
    },
  });
  dataSource_tblCorporacion_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblCorporacion_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idCorporacionSel: data[0].idCorporacion });
    }
  }
  dataSource_tblCorporacion_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idCorporacion: null, denominacion: "" });
    }
    return data;
  }

  dataSource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPais_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblPais_onLoaded(data);
      },
      version: 4,
    }),
    expand: "tblTraduccion",
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblPais_postProcess(data);
    },
  });
  dataSource_tblPais_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblPais_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idPaisSel: data[0].idPais });
    }
  }
  dataSource_tblPais_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idPais: null, denominacion: "" });
    }
    return data;
  }

  //#endregion

  render() {
    const {
      dxMultiView_index,
      datosSelector_Lavanderia,
      datosSelector_SistemasMaquina,
      datosSelector_CategoriasMaquina,
      datosSelector_Maquina,
    } = this.state;
    let categoriaSel =
      this.state.categoriaSel != null
        ? this.state.categoriaSel.idCategoriaMaquina
        : null;
    let { getBackButton_paginaSecundaria, param_paginaSecundaria } = this.props;

    return (
      <Fragment>
        <PageTitle
          heading={
            param_paginaSecundaria != null
              ? param_paginaSecundaria.nombre_paginaSecundaria
              : getNombreFormulario(this)
          }
          preHeading={
            getBackButton_paginaSecundaria && dxMultiView_index === 0
              ? getBackButton_paginaSecundaria()
              : this.getBackButton()
          }
          postHeading={[
            this.getSelectorCorporacion(),
            this.getSelectorPais(),
            this.getDatePicker(),
          ]}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <MultiView
            height="100%"
            width="100%"
            selectedIndex={dxMultiView_index}
            loop={true}
            swipeEnabled={false}
            deferRendering={true}
            animationEnabled={true}
          >
            <MultiViewItem>
              <CardSelector
                data={datosSelector_Lavanderia}
                onClick={this.onCardClick_Lavanderia}
                bodyClick={true}
                groupField="pais"
                zoom={true}
              ></CardSelector>
            </MultiViewItem>
            <MultiViewItem>
              <CardSelector
                data={datosSelector_SistemasMaquina}
                onClick={this.onCardClick_SistemasMaquina}
                {...this.state}
              ></CardSelector>
            </MultiViewItem>
            <MultiViewItem>
              <Row>
                <Col xs="4">
                  <div style={{ height: 780 }}>
                    <CardSelector
                      data={datosSelector_CategoriasMaquina}
                      numCol={12}
                      idSelected={categoriaSel}
                      onClick={this.onCardClick_CategoriasMaquina}
                      {...this.state}
                    ></CardSelector>
                  </div>
                </Col>
                <Col xs="8">
                  <CardSelector
                    data={datosSelector_Maquina}
                    numCol={12}
                    readOnly={true}
                    onClick={this.onCardClick_Maquinas}
                    {...this.state}
                  ></CardSelector>
                </Col>
              </Row>
            </MultiViewItem>
          </MultiView>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //#region SELECTOR LAVANDERIA
  onCardClick_Lavanderia(lavanderia) {
    this.setState({ is_lavCard_click: true }, () => {
      this.setLavanderia(lavanderia.idLavanderia);
    });
  }

  get_BodyContent(lavanderia) {
    let _this = this;
    let { porcentajeDisponibilidad, porcentajeAmarilla } = lavanderia;
    return (
      <Fragment>
        <Col xs="3" className="he-100 p-0 ml-2">
          <Fragment>
            <CircularGauge
              className={"CircularGauge_selector"}
              id={"CircularGauge_selector_" + lavanderia.idLavanderia}
              value={0}
            >
              <Scale startValue={0} endValue={1}>
                <Tick visible={false} />
                <Label visible={false} />
              </Scale>
              <Geometry startAngle={90} endAngle={90}></Geometry>
              <RangeContainer width={8} backgroundColor="lightgrey" offset={10}>
                <Range
                  startValue={0}
                  endValue={porcentajeDisponibilidad}
                  color="var(--success)"
                />
                <Range
                  startValue={porcentajeDisponibilidad}
                  endValue={porcentajeDisponibilidad + porcentajeAmarilla}
                  color="var(--primary)"
                />
                <Range
                  startValue={porcentajeDisponibilidad + porcentajeAmarilla}
                  endValue={1}
                  color="var(--danger)"
                />
              </RangeContainer>
              <ValueIndicator
                type="rangebar"
                offset={0}
                size={10}
              ></ValueIndicator>
              <Margin right={5} />
            </CircularGauge>
            <div
              className="container_spanCentrado position-absolute w-100 he-100"
              style={{ top: "0px", right: "2px" }}
            >
              <div className={_this.get_responsiveFont_gauge()}>
                {lavanderia.porcentajeDisponibilidad ? (
                  lavanderia.porcentajeDisponibilidad < 1 &&
                  lavanderia.porcentajeDisponibilidad > 0.99 ? (
                    formatNumber(0.99, 0, "percent") //Para que no redondee a 100% cuando es 99.5%
                  ) : lavanderia.porcentajeDisponibilidad < 1 ? (
                    formatNumber(
                      lavanderia.porcentajeDisponibilidad,
                      0,
                      "percent",
                    )
                  ) : (
                    <div className="icon_Check success font-size-max"></div>
                  )
                ) : (
                  <div className="success font-size-max">-</div>
                )}
              </div>
            </div>
          </Fragment>
        </Col>
        <Col xs="9" className="he-100">
          <table className="table w-100 he-100">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" className="text-center font-size-xxxs">
                  {getTrad("gasto")}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_Recambios dotStyle" />
                </th>
                <td>{getTrad("recambios").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(
                    lavanderia.gasto_recambio,
                    2,
                    "currency",
                    lavanderia.codigo,
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_ServicioExtra dotStyle" />
                </th>
                <td>{getTrad("serviciosExtra").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(
                    lavanderia.gasto_extra,
                    2,
                    "currency",
                    lavanderia.codigo,
                  )}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_ManoObra dotStyle" />
                </th>
                <td>{getTrad("manoObra").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(
                    lavanderia.gasto_manoObra,
                    2,
                    "currency",
                    lavanderia.codigo,
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Fragment>
    );
  }

  get_HeaderContent_Maquina(item) {
    return <div></div>;
  }

  get_BodyContent_Maquina(items) {
    let codigoMoneda =
      items && items.value.length > 0 ? items.value[0].codigo : "EUR";
    let format_moneda = {
      style: "currency",
      maximumFractionDigits: 2,
      currency: codigoMoneda,
    };
    return (
      <Fragment>
        <Col xs="12" className="he-100 p-0 ml-2">
          <Fragment>
            <DataGrid
              //Datos
              ref={this.dxDataGrid_tblMaquinas_REF}
              // height={1000}
              remoteOperations={true}
              dataSource={this.state.dxMultiView_index != 2 ? [] : items.value}
              //Propiedades
              columnsAutoWidth={true}
              height={"100%"}
              //Estilos
              showColumnLines={false}
              showRowLines={true}
              rowAlternationEnabled={true}
              //Eventos
              onContentReady={this.onContentReady_gridMaquinas}
            >
              <SearchPanel visible={false} />
              <HeaderFilter visible={true} />
              <FilterRow
                visible={true}
                showAllText={getTrad("todos").toUpperCase()}
                applyFilter="auto"
              />
              <Pager visible={false} />
              <Column
                dataField="denominacion"
                caption={getTrad("denominacion")}
                allowHeaderFiltering={false}
              />
              <Column
                dataField="porcentajeDisponibilidad"
                caption={getTrad("disponibilidad")}
                dataType="number"
                alignment="center"
                cssClass="p-0"
                width={150}
                cellComponent={progressBarCellComponent}
                allowFiltering={false}
              />
              <Column
                dataField="gasto_recambio"
                caption={getTrad("recambios")}
                dataType="number"
                alignment="center"
                cssClass="p-0"
                width={150}
                allowFiltering={false}
                format={format_moneda}
              />
              <Column
                dataField="gasto_extra"
                caption={getTrad("serviciosExtra")}
                dataType="number"
                alignment="center"
                cssClass="p-0"
                width={150}
                allowFiltering={false}
                format={format_moneda}
              />
              <Column
                dataField="gasto_manoObra"
                caption={getTrad("manoObra")}
                dataType="number"
                alignment="center"
                cssClass="p-0"
                width={150}
                allowFiltering={false}
                format={format_moneda}
              />
            </DataGrid>
          </Fragment>
        </Col>
      </Fragment>
    );
  }
  //#endregion

  //#region DATE PICKER
  getDatePicker() {
    return (
      <DateBox
        className="centerText font-size-sm"
        inputAttr={{ class: "font-size-sm" }}
        value={this.state.fechaSel}
        width="100%"
        stylingMode="underlined"
        displayFormat={this.datePicker_displayFormat}
        openOnFieldClick={true}
        calendarOptions={{
          maxZoomLevel: "year",
          minZoomLevel: "year",
        }}
        onValueChanged={this.datePicker_onValueChanged}
      >
        <DateBoxButton
          name="prevDate"
          location="before"
          options={{
            icon: "spinprev",
            stylingMode: "text",
            onClick: this.prevDate_Click,
          }}
        />
        <DateBoxButton
          name="nextDate"
          location="after"
          options={{
            icon: "spinnext",
            stylingMode: "text",
            onClick: this.nextDate_Click,
          }}
        />
      </DateBox>
    );
  }
  prevDate_Click(e) {
    const { fechaSel } = this.state;
    const _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)),
    });
  }
  nextDate_Click(e) {
    let { fechaSel } = this.state;
    let _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)),
    });
  }
  datePicker_onValueChanged(e) {
    this.setState({ fechaSel: e.value });
  }
  datePicker_displayFormat(fecha) {
    let { idioma } = this.props;
    if (new Date().getFullYear() === fecha.getFullYear()) {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long" })
        .toUpperCase();
    } else {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long", year: "numeric" })
        .toUpperCase();
    }
  }
  //#endregion

  //#region SELECTOR CORPORACIÓN
  getSelectorCorporacion() {
    const { idioma } = this.props;
    let { idCorporacionSel, dxMultiView_index } = this.state;
    let is_corpUnica = this.dataSource_tblCorporacion.items().length === 1;
    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblCorporacion}
        displayExpr={function (item) {
          if (item && item.denominacion) {
            return item.denominacion.toUpperCase();
          } else if (item) {
            return getTrad("todasCorporaciones");
          } else {
            return;
          }
        }}
        disabled={is_corpUnica}
        valueExpr="idCorporacion"
        value={idCorporacionSel}
        defaultValue={idCorporacionSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todasCorporaciones")}
        onSelectionChanged={this.selectorCorporacion_onSelectionChanged}
      />
    );
  }
  selectorCorporacion_onSelectionChanged(e) {
    let { idPaisSel } = this.state;

    let idCorporacionSel = e.selectedItem.idCorporacion;
    let items = this.dataSource_spSelectDashboardMantenimiento_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );
    this.setState({
      idCorporacionSel: idCorporacionSel,
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //#region SELECTOR PAÍS
  getSelectorPais() {
    const { idioma } = this.props;
    let { idPaisSel, dxMultiView_index } = this.state;
    let is_paisUnico = this.dataSource_tblPais.items().length === 1;

    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblPais}
        displayExpr={function (item) {
          if (item && item.tblTraduccion) {
            return item.tblTraduccion[idioma.codigo].toUpperCase();
          } else if (item) {
            return getTrad("todosPaises");
          } else {
            return;
          }
        }}
        disabled={is_paisUnico}
        valueExpr="idPais"
        value={idPaisSel}
        defaultValue={idPaisSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todosPaises")}
        onSelectionChanged={this.selectorPais_onSelectionChanged}
      />
    );
  }
  selectorPais_onSelectionChanged(e) {
    let idPaisSel = e.selectedItem.idPais;
    let { idCorporacionSel } = this.state;
    let items = this.dataSource_spSelectDashboardMantenimiento_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );

    this.setState({
      idPaisSel: idPaisSel,
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //#region PRINT BUTTON
  getPrintButton() {
    let { dxMultiView_index } = this.state;
    return (
      <Fragment>
        <div
          id="printButton"
          className="dx-icon-print container_spanCentrado font-size-xxl pr-3"
          onClick={this.printButton_onClick}
          hidden={dxMultiView_index === 0}
          style={{ height: "50px" }}
        />
        <Tooltip
          target="#printButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("imprimir")}</div>
        </Tooltip>
      </Fragment>
    );
  }
  printButton_onClick() {
    const { lavanderia, user } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    var params = {
      idLavanderia: lavanderia.idLavanderia,
      idUsuario: user.idUsuario,
      fechaDesde: formatDate_parameter(fechaDesde),
      fechaHasta: formatDate_parameter(fechaHasta),
    };

    this.setParams(params);
  }
  //#endregion

  //#region BACK BUTTON
  getBackButton() {
    let { dxMultiView_index } = this.state;
    let texto = getTrad("volverLavanderia");
    switch (dxMultiView_index) {
      case 1:
        texto = getTrad("volverLavanderia");
        break;

      case 2:
        texto = getTrad("volverSistemaMaquina");

        break;
    }

    return (
      <Fragment>
        <div
          id="backButton"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_onClick}
          hidden={dxMultiView_index === 0}
        />
        <Tooltip
          target="#backButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{texto}</div>
        </Tooltip>
      </Fragment>
    );
  }
  backButton_onClick() {
    let { dxMultiView_index } = this.state;
    this.setState({
      dxMultiView_index: dxMultiView_index - 1,
      kgEstancia_compañiaSel: null,
      logistica_compañiaSel: null,
      sistemaSel: dxMultiView_index == 2 ? null : this.state.sistemaSel,
    });
  }
  //SELECTOR CATEGORÍA MAQUINA
  onCardClick_SistemasMaquina(item) {
    this.setState({ sistemaSel: item });
  }

  onCardClick_CategoriasMaquina(item) {
    this.setState({ categoriaSel: item });
  }

  onCardClick_Maquinas(item) {}

  //#endregion

  onContentReady_gridMaquinas() {}

  //#region UTILS
  get_CardColor(porcentajeDisponibilidad, isBorder = false) {
    if (
      Number.isNaN(porcentajeDisponibilidad) ||
      porcentajeDisponibilidad == null
    )
      return "";

    let disponibilidad = porcentajeDisponibilidad.toFixed(2);
    if (isBorder)
      return disponibilidad < 0.7
        ? "border-danger"
        : disponibilidad < 0.95 && disponibilidad >= 0.7
          ? "border-primary"
          : "border-success";
    return disponibilidad < 0.7
      ? "danger"
      : disponibilidad < 0.95 && disponibilidad >= 0.7
        ? "primary"
        : "success";
  }

  setLavanderia(idLavanderia) {
    let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

    var lav = lavanderias.find((lav) => {
      return lav.idLavanderia === idLavanderia;
    });
    this.props.setLavanderia(lav);
  }

  get_responsiveFont_gauge() {
    let width = $(window).width();
    // 3 Columnas
    if (width > 2150) return "font-size-xxl";
    if (width >= 1630 && width < 2150) return "font-size-xl";
    else if (width >= 1260 && width < 1630) return "font-size-lg";
    else if (width >= 1200 && width < 1260) return "font-size-md";
    // 2 Columnas
    else if (width >= 800 && width < 1200) return "font-size-xl";
    else if (width >= 650 && width < 800) return "font-size-lg";
    else if (width >= 576 && width < 650) return "font-size-md";
    // 1 Column
    else if (width >= 400 && width < 576) return "font-size-xl";
    else if (width < 400) return "font-size-lg";
    else return "font-size-xl";
  }
  //#endregion

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  //REPORT VIEWER
  setParams(params) {
    this.props.setParams(params);
  }
  setReport(report) {
    this.props.setReport(report);
  }
  closeReport() {
    this.props.close();
  }
  CustomizeParameterEditors(report, s, e) {
    if (e.parameter.type === "System.DateTime") {
      //DATE
      e.info.editor = $.extend({}, e.info.editor);
      e.info.editor.extendedOptions = $.extend(
        e.info.editor.extendedOptions || {},
        { type: "date" },
        { displayFormat: "dd/MM/yyyy" },
      );
    }

    if (e.parameter.name === "idLavanderia") {
      //INVISIBLES
      e.info.editor = $.extend({}, e.info.editor);
      e.info.visible = false;
    }
  }

  componentDidMount() {
    let _this = this;
    this.dataSource_tblPais.load().done(function () {
      _this.dataSource_spSelectDashboardMantenimiento_Selector
        .load()
        .done(function () {
          _this.loadPanel_hide();
        });
    });

    this.setReport({
      denominacion: "dashboard_ejecutivo",
      parameterEvent: this.CustomizeParameterEditors,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    let { lavanderia } = this.props;
    let {
      fechaSel,
      sistemaSel,
      categoriaSel,
      dxMultiView_index,
      is_lavCard_click,
    } = this.state;

    let hayCambiosLavanderia =
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia;
    let hayCambiosFecha =
      fechaSel.getFullYear() !== prevState.fechaSel.getFullYear() ||
      fechaSel.getMonth() !== prevState.fechaSel.getMonth();
    let haySeleccionCartaSistema =
      prevState.sistemaSel !== sistemaSel && sistemaSel != null;
    let haySeleccionCartaCategoria = prevState.categoriaSel !== categoriaSel; //&& categoriaSel != null;

    if (
      (is_lavCard_click && dxMultiView_index === 0) ||
      hayCambiosLavanderia ||
      hayCambiosFecha ||
      haySeleccionCartaSistema ||
      haySeleccionCartaCategoria
    ) {
      if (hayCambiosFecha) {
        //Se carga los datos del selector y después el de los informes si se ha selecciona una lavandería.
        //#region Cambio fecha
        this.loadPanel_show(true);
        if (dxMultiView_index == 0) {
          _this.dataSource_spSelectDashboardMantenimiento_Selector
            .load()
            .done(function () {
              _this.loadPanel_hide();
            });
        } else {
          this.setState({ hayCambiosFecha: true });
          $.when(
            _this.dataSource_spSelectDashboardMantenimiento_Selector.load(),
            _this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector.load(),
            sistemaSel != null
              ? _this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector.reload()
              : null,
            categoriaSel != null
              ? _this.dataSource_spSelectDashboardMantenimiento_maquina_Selector.load()
              : null,
          ).then(function () {
            _this.loadPanel_hide();
          });
        }
        //#endregion
      }
      if (
        (is_lavCard_click && dxMultiView_index === 0) ||
        (hayCambiosLavanderia && !is_lavCard_click && dxMultiView_index > 0)
      ) {
        $.when(
          _this.dataSource_spSelectDashboardMantenimiento_sistemaMaquina_Selector.reload(),
          dxMultiView_index > 1 && sistemaSel != null
            ? _this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector.reload()
            : null,
          dxMultiView_index > 1 && categoriaSel != null
            ? _this.dataSource_spSelectDashboardMantenimiento_maquina_Selector.load()
            : null,
        ).then(function () {
          if (dxMultiView_index === 0) {
            _this.setState({
              dxMultiView_index: 1,
              firstLoad: false,
              is_lavCard_click: false,
            });
          }
          _this.loadPanel_hide();
        });
      } else if (
        haySeleccionCartaSistema ||
        (hayCambiosLavanderia && dxMultiView_index === 2)
      ) {
        this.dataSource_spSelectDashboardMantenimiento_categoriaMaquina_Selector
          .reload()
          .done(function () {
            let new_dxMultiView_index =
              dxMultiView_index === 1 ? 2 : dxMultiView_index;
            _this.setState({ dxMultiView_index: new_dxMultiView_index });
          });
      } else if (haySeleccionCartaCategoria) {
        this.dataSource_spSelectDashboardMantenimiento_maquina_Selector.load();
      }
    }
  }
  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  setLavanderia: (lavanderia) =>
    dispatch(globalActions.setLavanderia(lavanderia)),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
