import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Popup } from "devextreme-react/popup";
import { Progress } from "reactstrap";
import Box, { Item as ItemBox } from "devextreme-react/box";
import LottieIcon from "components/LottieIcon";
//Css
import "./Css.scss";

let intervalId;

class LoadPanel_ProgressBar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.dxPopup_loadPanel_onContentReady =
      this.dxPopup_loadPanel_onContentReady.bind(this);
  }

  render() {
    let { visible } = this.state;
    let { progress, title } = this.props;

    return (
      <Fragment>
        <Popup
          visible={visible}
          height="auto"
          width="auto"
          maxWidth={500}
          minWidth={300}
          showTitle={false}
          deferRendering={false}
          onContentReady={this.dxPopup_loadPanel_onContentReady}
        >
          <Box direction="col" height="100%" width="100%">
            <ItemBox baseSize={50}>
              <div className="d-flex justify-content-center align-items-center he-100">
                <h5 className="fontWeight-400">
                  {title != null ? title : "Subiendo archivos"}
                </h5>
                <span className="lottieIcon">
                  <LottieIcon height={90} width={90} icon={"loading_startup"} />
                </span>
              </div>
            </ItemBox>
            <ItemBox baseSize={30}>
              <Progress striped color="success" value={progress}>
                {progress + "%"}
              </Progress>
            </ItemBox>
          </Box>
        </Popup>
      </Fragment>
    );
  }

  dxPopup_loadPanel_onContentReady(e) {
    e.component.content().attr("id", "LoadPanel_ProgressBar");
  }

  componentDidUpdate(prevProps) {
    let { visible, progress } = this.props;

    if (visible !== prevProps.visible) {
      if (progress === 100) {
        this.setState({ visible: false });
      } else {
        this.setState({ visible: true });
      }
    }
  }

  componentDidMount() {
    let { visible } = this.props;
    this.setState({
      visible: visible,
    });
  }

  componentWillUnmount() {}
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(LoadPanel_ProgressBar);
