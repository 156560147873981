import { Component } from "react";
import Chart from "react-apexcharts";
import "./styles.scss";

class RadialBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { color } = this.props;

    const defaultOptions = {
      labels: [""],
      chart: {
        offsetY: -20,
        width: 200,
        animations: { enabled: false },
      },
      plotOptions: {
        radialBar: {
          startAngle: -120,
          endAngle: 120,
          dataLabels: {
            value: {
              show: false,
              //   offsetY: 15,
              fontSize: "1.75rem",
              color: color ? color : "#70d7c7",
            },
          },
        },
      },
      fill: { colors: [color ? color : "#70d7c7"] },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          filter: {
            type: "none",
          },
        },
      },
    };

    const {
      size = 200,
      height = 128,
      style,
      disabled,
      value,
      options,
      valueStyle,
      label,
    } = this.props;

    return (
      <div
        style={{ height: height }}
        className={`position-relative ${disabled ? " opacity-5" : ""}`}
      >
        <div style={{ ...style }}>
          <Chart
            series={[value > 100 ? 100 : value < 0 ? 0 : value]}
            type="radialBar"
            options={options ? options : defaultOptions}
            height={size}
          />
        </div>
        <span className="number" style={{ color: color, ...valueStyle }}>
          {value}%
        </span>
        <span className="label">{label}</span>
      </div>
    );
  }
}

export default RadialBar;
