import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";

import { getTrad } from "helpers";
import Form from "./components/Form";
import notify from "devextreme/ui/notify";
import { datasource_tblAdmFacturaCompra, saveData } from "pages/Administracion/shared/model";
import { payloadBuilder } from "../../model";

const FormFacturaCompra = forwardRef(
    (
        {
            facturaCompraSel,
            setFacturaCompraSel,
            validations,
            closePopup,
            loadPanel_show,
            loadPanel_hide,
        },
        ref
    ) => {
        const [idMoneda, setIdMoneda] = useState(1);
        const formFacturaCompra_REF = useRef();

        useImperativeHandle(ref, () => ({
            onSubmit_FormFacturaCompra,
            hasEditData: formFacturaCompra_REF.current.hasEditData,
        }));

        useEffect(() => {
            setIdMoneda(facturaCompraSel.idMoneda);
        }, [facturaCompraSel]);

        const onSubmit_FormFacturaCompra = () => {
            const formData = formFacturaCompra_REF.current.getFormData();
            let promises = [];

            validations.forEach((fnValidation) => {
                promises.push(fnValidation());
            });
            loadPanel_show(true);
            Promise.all(promises)
                .then(() => {
                    saveData("POST", payloadBuilder(formData), "IU_AdmFacturaCompra").then((data) => {
                        datasource_tblAdmFacturaCompra.reload().then(loadPanel_hide());
                    });
                    notify({
                        message: getTrad("aviso_C_RegistroInsertado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                    closePopup();
                })
                .catch((e) => {
                    loadPanel_hide();
                    notify({
                        message: getTrad("errorIntroduccionDatos"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                });
        };

        return (
            <>
                <div>
                    <Form
                        ref={formFacturaCompra_REF}
                        formData={facturaCompraSel}
                        setFormData={setFacturaCompraSel}
                        validations={validations}
                        setIdMoneda={setIdMoneda}
                        idMoneda={idMoneda}
                    />
                </div>
            </>
        );
    }
);

export default FormFacturaCompra;
