import { connectionConstants } from "../../../constants";
import { authHeader, errorHandler, getTrad, formatDate_parameter, dxMensajePregunta } from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
    var deferred = $.Deferred();

    //Ingresos
    var idEntidadSel = -1;
    var añoSel_ingresos = new Date().getFullYear();

    //Costes
    var idTipoKpiSel = -1;
    var añoSel_kpi = new Date().getFullYear();
    var idRecursoNivelSel = -1;
    var tipoCosteSel = 0;

    //#region Datasource

    //Datasource Nivel

    var datasource_compañias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = null;
                request.params.todas = true;
            },
            onLoading: function (loadOptions) {
                let idsEntidadVisibles = datasource_CompañiasEntidades.items();
                let filtroEntidades = $.map(idsEntidadVisibles, function (item) {
                    return item;
                });
                loadOptions.expand =
                    "tblEntidad($select=idEntidad, denominacion; $filter = idEntidad in (" + filtroEntidades + "))";
                loadOptions.filter = [["tblEntidad/any(e: e/idEntidad in (" + filtroEntidades + "))"]];
            },
            version: 4,
        }),
        select: ["idCompañia", "denominacion"],
    });

    var datasource_entidadesActivas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: ["idEntidad"],
    });

    var datasource_ingresosPresupuestados_entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblIngresosPresupuestados",
            key: "fecha",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                //request.params.$apply = "groupby((idEntidad))"
            },
            onLoading: function (loadOptions) {
                loadOptions.filter = [
                    ["fecha", ">=", new Date(añoSel_ingresos, 0, 1, 0, 0, 0)],
                    "and",
                    ["fecha", "<=", new Date(añoSel_ingresos, 12, 0, 0, 0)],
                ];
            },
            version: 4,
        }),
    });

    var datasource_CompañiasEntidades = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: function (loadOptions) {
                var deferred_ = $.Deferred();

                $.when(datasource_ingresosPresupuestados_entidades.load(), datasource_entidadesActivas.load()).done(
                    function (entidades_ingr, entidades_activas) {
                        const idsEntidad = [
                            ...new Set(
                                [].concat(...[entidades_ingr[0], entidades_activas[0]]).map((item) => item.idEntidad)
                            ),
                        ];
                        deferred_.resolve(idsEntidad);
                    }
                );

                return deferred_.promise();
            },
        }),
    });

    var datasource_tiposKpi = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoKpi",
            key: "idTipoKpi",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    var datasource_tipoKpi_observaciones = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoKpi_Observaciones",
            key: "idTipoKpi",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.idTipoKpi = idTipoKpiSel;
            },
            version: 4,
        }),
    });

    var datasource_objetivosKpi = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblObjetivosKpi",
            key: "fecha",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.idTipoKpi = idTipoKpiSel;
            },
            onLoading: function (loadOptions) {
                loadOptions.filter = [
                    ["fecha", ">=", new Date(añoSel_kpi, 0, 1, 0, 0, 0)],
                    "and",
                    ["fecha", "<=", new Date(añoSel_kpi, 12, 0, 0, 0)],
                ];
            },
            version: 4,
        }),
        postProcess: function (data) {
            var func_filtro = function (v) {
                return v.fecha.getMonth() === i;
            };
            for (var i = 0; i < 12; i++) {
                var encontrado = $.grep(data, func_filtro);

                if (encontrado.length === 0) {
                    data.splice(i, 0, {
                        idTipoKpi: idTipoKpiSel,
                        fecha: new Date(añoSel_kpi, i, 1),
                        idLavanderia: LAVANDERIA.idLavanderia,
                        kpiOperativo: 0,
                        costeUd: 0,
                        costeTeorico: 0,
                    });
                }
            }
            return data;
        },
    });

    var datasource_objetivosKpiNRecursoNivel = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblObjetivosKpiNRecursoNivel",
            key: "fecha",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.idRecursoNivel = idRecursoNivelSel;
            },
            onLoading: function (loadOptions) {
                loadOptions.filter = [
                    ["fecha", ">=", new Date(añoSel_kpi, 0, 1, 0, 0, 0)],
                    "and",
                    ["fecha", "<=", new Date(añoSel_kpi, 12, 0, 0, 0)],
                ];
            },
            version: 4,
        }),
        postProcess: function (data) {
            var func_filtro = function (v) {
                return v.fecha.getMonth() === i;
            };
            for (var i = 0; i < 12; i++) {
                var encontrado = $.grep(data, func_filtro);

                if (encontrado.length === 0) {
                    data.splice(i, 0, {
                        idRecursoNivelSel: idRecursoNivelSel,
                        fecha: new Date(añoSel_kpi, i, 1),
                        idLavanderia: LAVANDERIA.idLavanderia,
                        kpiOperativo: 0,
                        costeUd: 0,
                    });
                }
            }
            return data;
        },
    });

    var datasource_ingresosPresupuestados = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblIngresosPresupuestados",
            key: "fecha",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.idEntidad = idEntidadSel;
            },
            onLoading: function (loadOptions) {
                loadOptions.filter = [
                    ["fecha", ">=", new Date(añoSel_ingresos, 0, 1, 0, 0, 0)],
                    "and",
                    ["fecha", "<=", new Date(añoSel_ingresos, 12, 0, 0, 0)],
                ];
            },
            version: 4,
        }),
        postProcess: function (data) {
            var func_filtro = function (v) {
                return v.fecha.getMonth() === i;
            };
            for (var i = 0; i < 12; i++) {
                var encontrado = $.grep(data, func_filtro);

                if (encontrado.length === 0) {
                    data.splice(i, 0, {
                        idEntidad: idEntidadSel,
                        fecha: new Date(añoSel_ingresos, i, 1),
                        valor: 0,
                    });
                }
            }

            return data;
        },
        select: ["fecha", "valor"],
    });

    var datasource_nivel = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblRecursoNivel",
            key: "idRecursoNivel",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        filter: [["activo", "=", true], "and", ["eliminado", "=", false]],
    });

    //#endregion

    $.when(datasource_CompañiasEntidades.load())
        .then(function () {
            //#region Controles

            $("#PrevisionIngresosCostes #dxContainer").dxBox({
                direction: "col",
                height: "100%",
            });

            //TabPanel

            $("#PrevisionIngresosCostes #tabpanel-container")
                .addClass("h3")
                .dxTabPanel({
                    height: "100%",
                    width: "100%",
                    deferRendering: false,
                    focusStateEnabled: false,
                    animationEnabled: true,
                    items: [
                        {
                            title: "Ingresos", //getTrad('contador'),
                            template: function () {
                                return $("<div id='dxBox_Filtros_ingresos'>").dxBox({
                                    direction: "row",
                                    align: "space-around",
                                    crossAlign: "stretch",
                                    height: "100%",
                                    items: [
                                        {
                                            //COMPAÑIAS
                                            ratio: 1,
                                            template: function () {
                                                return $("<div style='margin-right:15px;'/>").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div class='font-size'>").text(
                                                                    "1. " + getTrad("seleccioneCompañia")
                                                                );
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $("<div />").dxList({
                                                                    dataSource: datasource_compañias,
                                                                    selectionMode: "single",
                                                                    keyExpr: "idCompañia",
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    scrolling: { mode: "infinite" },
                                                                    pageLoadMode: "scrollBottom",
                                                                    onSelectionChanged: function (e) {
                                                                        var compañiaSel = e.addedItems[0];

                                                                        var dxList_entidades =
                                                                            $("#dxList_entidades").dxList("instance");
                                                                        dxList_entidades.unselectAll();
                                                                        dxList_entidades.option(
                                                                            "dataSource",
                                                                            compañiaSel.tblEntidad
                                                                        );

                                                                        var dxBox_Filtros =
                                                                            $("#dxBox_Filtros_ingresos").dxBox(
                                                                                "instance"
                                                                            );
                                                                        dxBox_Filtros.option("items[1].visible", true);
                                                                        dxBox_Filtros.repaint();
                                                                    },
                                                                    itemTemplate: function (data, index) {
                                                                        let entidadesActivas =
                                                                            datasource_entidadesActivas.items();
                                                                        let todasActivas = data.tblEntidad
                                                                            .map((x) => x.idEntidad)
                                                                            .every(
                                                                                (idEntidad) =>
                                                                                    entidadesActivas.filter(
                                                                                        (x) => x.idEntidad === idEntidad
                                                                                    ).length > 0
                                                                            );

                                                                        let result = $("<div/>").text(
                                                                            data.denominacion
                                                                        );
                                                                        if (!todasActivas) {
                                                                            result.css("color", "red");
                                                                        }
                                                                        return result;
                                                                    },
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                        {
                                            //ENTIDADES
                                            ratio: 1,
                                            visible: false,
                                            template: function () {
                                                return $("<div style='margin-right:15px;'/>").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $(
                                                                    "<div class='font-size' style='margin-left:9px;'>"
                                                                ).text("2. " + getTrad("seleccioneEntidad"));
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $("<div id='dxList_entidades' />").dxList({
                                                                    selectionMode: "single",
                                                                    keyExpr: "idEntidad",
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    scrolling: { mode: "infinite" },
                                                                    pageLoadMode: "scrollBottom",
                                                                    onSelectionChanged: function (e) {
                                                                        var dxBox_Filtros =
                                                                            $("#dxBox_Filtros_ingresos").dxBox(
                                                                                "instance"
                                                                            );
                                                                        dxBox_Filtros.option(
                                                                            "items[2].visible",
                                                                            e.addedItems.length > 0
                                                                        );
                                                                        dxBox_Filtros.repaint();

                                                                        if (e.addedItems.length > 0) {
                                                                            idEntidadSel = e.addedItems[0].idEntidad;
                                                                            $("#dgvIngresosPresupuestados")
                                                                                .dxDataGrid("instance")
                                                                                .refresh(true);
                                                                        }
                                                                    },
                                                                    itemTemplate: function (data, index) {
                                                                        let entidadesActivas =
                                                                            datasource_entidadesActivas.items();
                                                                        let todasActivas =
                                                                            entidadesActivas.filter(
                                                                                (x) => x.idEntidad === data.idEntidad
                                                                            ).length > 0;

                                                                        let result = $("<div/>").text(
                                                                            data.denominacion
                                                                        );
                                                                        if (!todasActivas) {
                                                                            result.css("color", "red");
                                                                        }
                                                                        return result;
                                                                    },
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                        {
                                            //INGRESOS
                                            ratio: 1,
                                            visible: false,
                                            template: function () {
                                                return $("<div />").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $(
                                                                    "<div class='font-size' style='margin-left:9px;'>"
                                                                ).text("3. Modifica los ingresos mensuales.");
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $(
                                                                    "<div id='dgvIngresosPresupuestados'/>"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource: datasource_ingresosPresupuestados,
                                                                    remoteOperations: {
                                                                        groupPaging: true,
                                                                        paging: true,
                                                                        filtering: true,
                                                                        sorting: true,
                                                                        grouping: true,
                                                                        summary: false,
                                                                    },
                                                                    repaintChangesOnly: true,
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    width: "auto",
                                                                    height: "100%",
                                                                    editing: {
                                                                        mode: "cell",
                                                                        allowUpdating: true,
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    columns: [
                                                                        {
                                                                            dataField: "fecha",
                                                                            caption: "Mes", //getTrad('denominacion'),
                                                                            minWidth: "200px",
                                                                            sortOrder: "asc",
                                                                            allowEditing: false,
                                                                            format: "month",
                                                                            customizeText: function (cellInfo) {
                                                                                return (
                                                                                    cellInfo.valueText
                                                                                        .charAt(0)
                                                                                        .toUpperCase() +
                                                                                    cellInfo.valueText.slice(1)
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "valor",
                                                                            caption: "Valor", //getTrad('kgProducido'),
                                                                            width: "170px",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 2,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                                step: 0,
                                                                                min: 0,
                                                                                max: 9999999,
                                                                            },
                                                                        },
                                                                    ],
                                                                    summary: {
                                                                        recalculateWhileEditing: true,
                                                                        totalItems: [
                                                                            {
                                                                                column: "valor",
                                                                                summaryType: "sum",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                                displayFormat: "{0}",
                                                                            },
                                                                        ],
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                template: function () {
                                                                                    return $("<div />").dxToolbar({
                                                                                        height: "100%",
                                                                                        width: "150px",
                                                                                        items: [
                                                                                            {
                                                                                                location: "before",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinleft",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_ingresos =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_ingresos"
                                                                                                                    ).text()
                                                                                                                ) - 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_ingresos"
                                                                                                            ).text(
                                                                                                                añoSel_ingresos
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "center",
                                                                                                template: function () {
                                                                                                    return $(
                                                                                                        "<div id='contenedorañoSel_ingresos'/>"
                                                                                                    )
                                                                                                        .css(
                                                                                                            "font-size",
                                                                                                            "18px"
                                                                                                        )
                                                                                                        .css(
                                                                                                            "color",
                                                                                                            "rgb(115,115,115)"
                                                                                                        )
                                                                                                        .text(
                                                                                                            añoSel_ingresos
                                                                                                        );
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "after",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinright",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_ingresos =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_ingresos"
                                                                                                                    ).text()
                                                                                                                ) + 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_ingresos"
                                                                                                            ).text(
                                                                                                                añoSel_ingresos
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                options: {
                                                                                    icon: "refresh",
                                                                                    text: getTrad("actualizar"),
                                                                                    onClick: function () {
                                                                                        e.component.refresh(true);
                                                                                    },
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                    ],
                                });
                            },
                        },
                        {
                            title: "KPIs", //getTrad('nivel'),
                            template: function () {
                                return $("<div id='dxBox_Filtros_costes'>").dxBox({
                                    direction: "row",
                                    align: "space-around",
                                    crossAlign: "stretch",
                                    height: "100%",
                                    items: [
                                        {
                                            //CONCEPTOS
                                            ratio: 1,
                                            template: function () {
                                                return $("<div style='margin-right:15px;'/>").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div class='font-size'>").text(
                                                                    "Seleccione un tipo KPI"
                                                                );
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $("<div />").dxList({
                                                                    dataSource: datasource_tiposKpi,
                                                                    selectionMode: "single",
                                                                    keyExpr: "idTipoKpi",
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    scrolling: { mode: "infinite" },
                                                                    pageLoadMode: "scrollBottom",
                                                                    onSelectionChanged: function (e) {
                                                                        var dgvObjetivosKpi =
                                                                            $("#dgvObjetivosKpi").dxDataGrid(
                                                                                "instance"
                                                                            );
                                                                        dgvObjetivosKpi.beginUpdate();
                                                                        dgvObjetivosKpi
                                                                            .saveEditData()
                                                                            .done(function () {
                                                                                var tipoKpiSel = e.addedItems[0];
                                                                                idTipoKpiSel = tipoKpiSel.idTipoKpi;

                                                                                let idsTipoKpi_kpiOperativo = [
                                                                                    1, 2, 3, 4, 5, 6, 9,
                                                                                ];
                                                                                let idsTipoKpi_costeUd = [
                                                                                    2, 3, 4, 6, 7, 8,
                                                                                ];
                                                                                let idsTipoKpi_4_decimales_objKpi = [
                                                                                    2, 3, 4,
                                                                                ];

                                                                                let costeUd_visible =
                                                                                    idsTipoKpi_costeUd.indexOf(
                                                                                        idTipoKpiSel
                                                                                    ) >= 0;
                                                                                let kpiOperativo_visible =
                                                                                    idsTipoKpi_kpiOperativo.indexOf(
                                                                                        idTipoKpiSel
                                                                                    ) >= 0;

                                                                                dgvObjetivosKpi.cancelEditData();
                                                                                let format_objKpi = {
                                                                                        style: "decimal",
                                                                                        currency: LAVANDERIA.moneda,
                                                                                        minimumFractionDigits:
                                                                                            idsTipoKpi_4_decimales_objKpi.indexOf(
                                                                                                idTipoKpiSel
                                                                                            ) >= 0
                                                                                                ? 4
                                                                                                : 2,
                                                                                    },
                                                                                    editorFormat_objKpi = {
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            currency: LAVANDERIA.moneda,
                                                                                            minimumFractionDigits:
                                                                                                idsTipoKpi_4_decimales_objKpi.indexOf(
                                                                                                    idTipoKpiSel
                                                                                                ) >= 0
                                                                                                    ? 4
                                                                                                    : 2,
                                                                                        },
                                                                                        step: 0,
                                                                                        min: 0,
                                                                                        max: 999,
                                                                                    };
                                                                                dgvObjetivosKpi.columnOption(
                                                                                    "kpiOperativo",
                                                                                    "format",
                                                                                    format_objKpi
                                                                                );
                                                                                dgvObjetivosKpi.columnOption(
                                                                                    "kpiOperativo",
                                                                                    "editorOptions",
                                                                                    editorFormat_objKpi
                                                                                );
                                                                                dgvObjetivosKpi.endUpdate();

                                                                                datasource_tipoKpi_observaciones
                                                                                    .reload()
                                                                                    .done(function (items) {
                                                                                        let observaciones = "";

                                                                                        if (items.length > 0) {
                                                                                            observaciones =
                                                                                                items[0].observaciones;
                                                                                        }

                                                                                        var taObservaciones =
                                                                                            $(
                                                                                                "#taObservaciones"
                                                                                            ).dxTextArea("instance");
                                                                                        taObservaciones.option(
                                                                                            "value",
                                                                                            observaciones
                                                                                        );
                                                                                    });

                                                                                dgvObjetivosKpi.option(
                                                                                    "dataSource",
                                                                                    datasource_objetivosKpi
                                                                                );
                                                                                datasource_objetivosKpi
                                                                                    .reload()
                                                                                    .done(function (itemsKpi) {
                                                                                        let listaQuimicosVisible = false;
                                                                                        if (idTipoKpiSel == 7) {
                                                                                            listaQuimicosVisible =
                                                                                                $.grep(
                                                                                                    itemsKpi,
                                                                                                    function (item) {
                                                                                                        return (
                                                                                                            item.costeUd >
                                                                                                                0 ||
                                                                                                            item.kpiOperativo >
                                                                                                                0
                                                                                                        );
                                                                                                    }
                                                                                                ).length == 0;
                                                                                        }

                                                                                        var dxBox_Filtros =
                                                                                            $(
                                                                                                "#dxBox_Filtros_costes"
                                                                                            ).dxBox("instance");
                                                                                        dxBox_Filtros.beginUpdate();
                                                                                        dxBox_Filtros.option(
                                                                                            "items[2].visible",
                                                                                            listaQuimicosVisible
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[3].visible",
                                                                                            !listaQuimicosVisible
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[1].visible",
                                                                                            false
                                                                                        );
                                                                                        dxBox_Filtros.repaint();
                                                                                        dxBox_Filtros.endUpdate();

                                                                                        dgvObjetivosKpi.columnOption(
                                                                                            "costeUd",
                                                                                            { visible: costeUd_visible }
                                                                                        );
                                                                                        dgvObjetivosKpi.columnOption(
                                                                                            "kpiOperativo",
                                                                                            {
                                                                                                visible:
                                                                                                    kpiOperativo_visible,
                                                                                            }
                                                                                        );

                                                                                        var dxList_recursoNivel =
                                                                                            $(
                                                                                                "#dxList_recursoNivel"
                                                                                            ).dxList("instance");
                                                                                        dxList_recursoNivel.unselectAll();

                                                                                        var dxBox_parametrizacionGeneral =
                                                                                            $(
                                                                                                "#dxBox_parametrizacionGeneral"
                                                                                            ).dxBox("instance");
                                                                                        dxBox_parametrizacionGeneral.beginUpdate();
                                                                                        dxBox_parametrizacionGeneral.option(
                                                                                            "items[1].visible",
                                                                                            idTipoKpiSel == 7 &&
                                                                                                !listaQuimicosVisible
                                                                                        );
                                                                                        dxBox_parametrizacionGeneral.repaint();
                                                                                        dxBox_parametrizacionGeneral.endUpdate();
                                                                                        tipoCosteSel =
                                                                                            idTipoKpiSel == 7 &&
                                                                                            !listaQuimicosVisible
                                                                                                ? 1
                                                                                                : 0;
                                                                                    });
                                                                            });
                                                                    },
                                                                    itemTemplate: function (data, index) {
                                                                        var result = $(
                                                                            "<div class =' container_spanCentrado' style='height:50px;'>"
                                                                        );
                                                                        $("<div class='font_family font_size_titulo'>")
                                                                            .text(data.denominacion.toUpperCase())
                                                                            .appendTo(result);
                                                                        return result;
                                                                    },
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                        {
                                            //COSTE TEÓRICO
                                            ratio: 1,
                                            visible: false,
                                            template: function () {
                                                return $("<div id='dxBox_costeTeorico' />").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $(
                                                                    "<div class='font-size' style='margin-left:9px;'>"
                                                                ).text("Coste teórico");
                                                            },
                                                        },
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div />").dxButton({
                                                                    text: getTrad("atras"),
                                                                    icon: "chevronleft",
                                                                    height: 40,
                                                                    width: 120,
                                                                    onClick: function () {
                                                                        var dxBox_Filtros =
                                                                            $("#dxBox_Filtros_costes").dxBox(
                                                                                "instance"
                                                                            );
                                                                        dxBox_Filtros.option(
                                                                            "items[3].visible",
                                                                            tipoCosteSel === 1
                                                                        );
                                                                        dxBox_Filtros.option(
                                                                            "items[2].visible",
                                                                            tipoCosteSel === 0
                                                                        );
                                                                        dxBox_Filtros.option("items[1].visible", false);
                                                                        dxBox_Filtros.repaint();

                                                                        var dxList_recursoNivel =
                                                                            $("#dxList_recursoNivel").dxList(
                                                                                "instance"
                                                                            );
                                                                        dxList_recursoNivel.unselectAll();
                                                                    },
                                                                });
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $(
                                                                    "<div id='dxDataGrid_costeTeorico'/>"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource: datasource_objetivosKpi,
                                                                    remoteOperations: {
                                                                        groupPaging: true,
                                                                        paging: true,
                                                                        filtering: true,
                                                                        sorting: true,
                                                                        grouping: true,
                                                                        summary: false,
                                                                    },
                                                                    repaintChangesOnly: true,
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    width: "auto",
                                                                    height: "100%",
                                                                    editing: {
                                                                        mode: "cell",
                                                                        allowUpdating: true,
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    columns: [
                                                                        {
                                                                            dataField: "fecha",
                                                                            caption: "Mes", //getTrad('denominacion'),
                                                                            minWidth: "200px",
                                                                            sortOrder: "asc",
                                                                            allowEditing: false,
                                                                            format: "month",
                                                                            customizeText: function (cellInfo) {
                                                                                return (
                                                                                    cellInfo.valueText
                                                                                        .charAt(0)
                                                                                        .toUpperCase() +
                                                                                    cellInfo.valueText.slice(1)
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "costeTeorico",
                                                                            caption: "Coste teórico",
                                                                            width: "170px",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 5,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 5,
                                                                                },
                                                                                step: 0,
                                                                                min: 0,
                                                                                max: 9999.99999,
                                                                            },
                                                                        },
                                                                    ],
                                                                    onRowUpdating: function (cellInfo) {
                                                                        if (
                                                                            typeof cellInfo.newData.costeTeorico ===
                                                                            "undefined"
                                                                        ) {
                                                                            cellInfo.newData.costeTeorico =
                                                                                cellInfo.oldData.costeTeorico;
                                                                        }
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                template: function () {
                                                                                    return $("<div />").dxToolbar({
                                                                                        height: "100%",
                                                                                        width: "150px",
                                                                                        items: [
                                                                                            {
                                                                                                location: "before",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinleft",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_kpi =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_kpi_teorico"
                                                                                                                    ).text()
                                                                                                                ) - 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_kpi_teorico"
                                                                                                            ).text(
                                                                                                                añoSel_kpi
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "center",
                                                                                                template: function () {
                                                                                                    return $(
                                                                                                        "<div id='contenedorañoSel_kpi_teorico'/>"
                                                                                                    )
                                                                                                        .css(
                                                                                                            "font-size",
                                                                                                            "18px"
                                                                                                        )
                                                                                                        .css(
                                                                                                            "color",
                                                                                                            "rgb(115,115,115)"
                                                                                                        )
                                                                                                        .text(
                                                                                                            añoSel_kpi
                                                                                                        );
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "after",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinright",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_kpi =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_kpi_teorico"
                                                                                                                    ).text()
                                                                                                                ) + 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_kpi_teorico"
                                                                                                            ).text(
                                                                                                                añoSel_kpi
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                options: {
                                                                                    icon: "refresh",
                                                                                    text: getTrad("actualizar"),
                                                                                    onClick: function () {
                                                                                        e.component.refresh(true);
                                                                                    },
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                        {
                                            //RECURSOS POR NIVEL
                                            ratio: 1,
                                            visible: false,
                                            template: function () {
                                                return $("<div style='margin-right:15px;'/>").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div class='font-size'>").text(
                                                                    "Seleccione un recurso por nivel"
                                                                );
                                                            },
                                                        },
                                                        {
                                                            baseSize: 75,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div />").dxBox({
                                                                    direction: "row",
                                                                    align: "space-around",
                                                                    crossAlign: "stretch",
                                                                    height: "100%",
                                                                    items: [
                                                                        {
                                                                            baseSize: 200,
                                                                            shrink: 0,
                                                                            ratio: 0,
                                                                            template: function (ar) {
                                                                                return $(
                                                                                    "<div id='dxButton_costeTeorico' />"
                                                                                ).dxButton({
                                                                                    height: "42px",
                                                                                    width: "150px",
                                                                                    type: "normal",
                                                                                    text: "Coste teórico",
                                                                                    onClick: function (e) {
                                                                                        var dxDataGrid_costeTeorico =
                                                                                            $(
                                                                                                "#dxDataGrid_costeTeorico"
                                                                                            ).dxDataGrid("instance");
                                                                                        dxDataGrid_costeTeorico.option(
                                                                                            "dataSource",
                                                                                            datasource_objetivosKpi
                                                                                        );

                                                                                        var dxBox_Filtros =
                                                                                            $(
                                                                                                "#dxBox_Filtros_costes"
                                                                                            ).dxBox("instance");
                                                                                        dxBox_Filtros.option(
                                                                                            "items[3].visible",
                                                                                            false
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[2].visible",
                                                                                            false
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[1].visible",
                                                                                            true
                                                                                        );
                                                                                        dxBox_Filtros.repaint();
                                                                                    },
                                                                                });
                                                                            },
                                                                        },
                                                                        {
                                                                            baseSize: 0,
                                                                            shrink: 0,
                                                                            ratio: 1,
                                                                            template: function () {
                                                                                return $(
                                                                                    "<div id='dxRadioGroup_detallada'/>"
                                                                                ).dxRadioGroup({
                                                                                    items: [
                                                                                        {
                                                                                            text: "Coste real detallado",
                                                                                            value: 0,
                                                                                        },
                                                                                        {
                                                                                            text: "Coste real general",
                                                                                            value: 1,
                                                                                        },
                                                                                    ],
                                                                                    value: 0,
                                                                                    width: "100%",
                                                                                    valueExpr: "value",
                                                                                    layout: "horizontal",
                                                                                    height: "35px",
                                                                                    itemTemplate: function (
                                                                                        data,
                                                                                        index,
                                                                                        element
                                                                                    ) {
                                                                                        element
                                                                                            .parent()
                                                                                            .addClass(
                                                                                                "d-flex justify-content-center align-items-center"
                                                                                            );
                                                                                        return $("<div />")
                                                                                            .addClass("text-center")
                                                                                            .text(data.text);
                                                                                    },
                                                                                    onValueChanged: function (e) {
                                                                                        if (e.value === 1) {
                                                                                            dxMensajePregunta(
                                                                                                getTrad(
                                                                                                    "preg_cambioKpiQuimico_general"
                                                                                                ),
                                                                                                [
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "aceptar"
                                                                                                        ),
                                                                                                        function (e) {
                                                                                                            var dgvObjetivosKpi =
                                                                                                                $(
                                                                                                                    "#dgvObjetivosKpi"
                                                                                                                ).dxDataGrid(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            dgvObjetivosKpi.option(
                                                                                                                "dataSource",
                                                                                                                datasource_objetivosKpi
                                                                                                            );

                                                                                                            var dxBox_Filtros =
                                                                                                                $(
                                                                                                                    "#dxBox_Filtros_costes"
                                                                                                                ).dxBox(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            dxBox_Filtros.option(
                                                                                                                "items[3].visible",
                                                                                                                true
                                                                                                            );
                                                                                                            dxBox_Filtros.option(
                                                                                                                "items[2].visible",
                                                                                                                false
                                                                                                            );
                                                                                                            dxBox_Filtros.repaint();

                                                                                                            var dxList_recursoNivel =
                                                                                                                $(
                                                                                                                    "#dxList_recursoNivel"
                                                                                                                ).dxList(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            dxList_recursoNivel.unselectAll();

                                                                                                            var dxBox_parametrizacionGeneral =
                                                                                                                $(
                                                                                                                    "#dxBox_parametrizacionGeneral"
                                                                                                                ).dxBox(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            dxBox_parametrizacionGeneral.option(
                                                                                                                "items[1].visible",
                                                                                                                true
                                                                                                            );
                                                                                                            dxBox_parametrizacionGeneral.repaint();

                                                                                                            tipoCosteSel = 1;
                                                                                                        },
                                                                                                        "danger",
                                                                                                        "btnAceptar_alert",
                                                                                                    ],
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "cancelar"
                                                                                                        ),
                                                                                                        function () {},
                                                                                                        "normal",
                                                                                                        "btnCancelar_alert",
                                                                                                    ],
                                                                                                ]
                                                                                            );
                                                                                            tipoCosteSel = 0;
                                                                                            e.component.option(
                                                                                                "value",
                                                                                                0
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                });
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $("<div id='dxList_recursoNivel' />").dxList({
                                                                    dataSource: datasource_nivel,
                                                                    selectionMode: "single",
                                                                    keyExpr: "idRecursoNivel",
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    scrolling: { mode: "infinite" },
                                                                    pageLoadMode: "scrollBottom",
                                                                    onSelectionChanged: function (e) {
                                                                        var dgvObjetivosKpi =
                                                                            $("#dgvObjetivosKpi").dxDataGrid(
                                                                                "instance"
                                                                            );
                                                                        dgvObjetivosKpi.beginUpdate();
                                                                        dgvObjetivosKpi
                                                                            .saveEditData()
                                                                            .done(function () {
                                                                                var dgvObjetivosKpi =
                                                                                    $("#dgvObjetivosKpi").dxDataGrid(
                                                                                        "instance"
                                                                                    );
                                                                                var recursoNivel = e.addedItems[0];
                                                                                idRecursoNivelSel = recursoNivel
                                                                                    ? recursoNivel.idRecursoNivel
                                                                                    : null;

                                                                                if (idRecursoNivelSel !== null) {
                                                                                    var dxBox_Filtros =
                                                                                        $(
                                                                                            "#dxBox_Filtros_costes"
                                                                                        ).dxBox("instance");
                                                                                    dxBox_Filtros.option(
                                                                                        "items[3].visible",
                                                                                        true
                                                                                    );

                                                                                    dgvObjetivosKpi.option(
                                                                                        "dataSource",
                                                                                        datasource_objetivosKpiNRecursoNivel
                                                                                    );
                                                                                    dgvObjetivosKpi.cancelEditData();
                                                                                    dgvObjetivosKpi.repaint();
                                                                                    dgvObjetivosKpi.endUpdate();
                                                                                }
                                                                                dgvObjetivosKpi.endUpdate();

                                                                                var dxBox_parametrizacionGeneral = $(
                                                                                    "#dxBox_parametrizacionGeneral"
                                                                                ).dxBox("instance");
                                                                                dxBox_parametrizacionGeneral.option(
                                                                                    "items[1].visible",
                                                                                    idRecursoNivelSel == null
                                                                                );
                                                                                dxBox_parametrizacionGeneral.repaint();

                                                                                $("#dxButton_costeTeorico")
                                                                                    .dxButton("instance")
                                                                                    .option(
                                                                                        "height",
                                                                                        idRecursoNivelSel != null
                                                                                            ? 66
                                                                                            : 42
                                                                                    );

                                                                                tipoCosteSel = 0;
                                                                            });
                                                                    },
                                                                    itemTemplate: function (data, index) {
                                                                        var result = $(
                                                                            "<div class =' container_spanCentrado' style='height:40px;'>"
                                                                        );
                                                                        $("<div class='font_family font_size_titulo'>")
                                                                            .text(data.denominacion.toUpperCase())
                                                                            .appendTo(result);
                                                                        return result;
                                                                    },
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                        {
                                            //COSTES
                                            ratio: 1,
                                            visible: false,
                                            template: function () {
                                                return $("<div id='dxBox_parametrizacionGeneral' />").dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                        {
                                                            baseSize: 45,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $(
                                                                    "<div class='font-size' style='margin-left:9px;'>"
                                                                ).text("KPIs mensuales según kg. procesados");
                                                            },
                                                        },
                                                        {
                                                            baseSize: 55,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div />").dxBox({
                                                                    direction: "row",
                                                                    align: "space-around",
                                                                    crossAlign: "stretch",
                                                                    height: "100%",
                                                                    items: [
                                                                        {
                                                                            baseSize: 200,
                                                                            shrink: 0,
                                                                            ratio: 0,
                                                                            template: function () {
                                                                                return $("<div />").dxButton({
                                                                                    height: "42px",
                                                                                    width: "150px",
                                                                                    type: "normal",
                                                                                    text: "Coste teórico",
                                                                                    onClick: function (e) {
                                                                                        var dxDataGrid_costeTeorico =
                                                                                            $(
                                                                                                "#dxDataGrid_costeTeorico"
                                                                                            ).dxDataGrid("instance");
                                                                                        dxDataGrid_costeTeorico.option(
                                                                                            "dataSource",
                                                                                            datasource_objetivosKpi
                                                                                        );

                                                                                        var dxBox_Filtros =
                                                                                            $(
                                                                                                "#dxBox_Filtros_costes"
                                                                                            ).dxBox("instance");
                                                                                        dxBox_Filtros.option(
                                                                                            "items[3].visible",
                                                                                            false
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[2].visible",
                                                                                            false
                                                                                        );
                                                                                        dxBox_Filtros.option(
                                                                                            "items[1].visible",
                                                                                            true
                                                                                        );
                                                                                        dxBox_Filtros.repaint();
                                                                                    },
                                                                                });
                                                                            },
                                                                        },
                                                                        {
                                                                            shrink: 0,
                                                                            ratio: 1,
                                                                            template: function () {
                                                                                return $(
                                                                                    "<div id='dxRadioGroup_detallada'/>"
                                                                                ).dxRadioGroup({
                                                                                    items: [
                                                                                        {
                                                                                            text: "Coste real detallado",
                                                                                            value: 0,
                                                                                        },
                                                                                        {
                                                                                            text: "Coste real general",
                                                                                            value: 1,
                                                                                        },
                                                                                    ],
                                                                                    value: 1,
                                                                                    width: "100%",
                                                                                    valueExpr: "value",
                                                                                    layout: "horizontal",
                                                                                    height: "35px",
                                                                                    itemTemplate: function (e) {
                                                                                        return $("<div />")
                                                                                            .addClass("text-center")
                                                                                            .text(e.text);
                                                                                    },
                                                                                    onValueChanged: function (e) {
                                                                                        if (e.value === 0) {
                                                                                            dxMensajePregunta(
                                                                                                getTrad(
                                                                                                    "preg_cambioKpiQuimico_detallado"
                                                                                                ),
                                                                                                [
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "aceptar"
                                                                                                        ),
                                                                                                        function (e) {
                                                                                                            var dxBox_Filtros =
                                                                                                                $(
                                                                                                                    "#dxBox_Filtros_costes"
                                                                                                                ).dxBox(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            dxBox_Filtros.option(
                                                                                                                "items[3].visible",
                                                                                                                false
                                                                                                            );
                                                                                                            dxBox_Filtros.option(
                                                                                                                "items[2].visible",
                                                                                                                true
                                                                                                            );
                                                                                                            dxBox_Filtros.repaint();

                                                                                                            tipoCosteSel = 0;
                                                                                                        },
                                                                                                        "danger",
                                                                                                        "btnAceptar_alert",
                                                                                                    ],
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "cancelar"
                                                                                                        ),
                                                                                                        function () {},
                                                                                                        "normal",
                                                                                                        "btnCancelar_alert",
                                                                                                    ],
                                                                                                ]
                                                                                            );
                                                                                            tipoCosteSel = 1;
                                                                                            e.component.option(
                                                                                                "value",
                                                                                                1
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                });
                                                                            },
                                                                        },
                                                                    ],
                                                                });
                                                            },
                                                        },
                                                        {
                                                            ratio: 1,
                                                            template: function () {
                                                                return $("<div id='dgvObjetivosKpi'/>").dxDataGrid({
                                                                    //Datos
                                                                    dataSource: datasource_objetivosKpi,
                                                                    remoteOperations: {
                                                                        groupPaging: true,
                                                                        paging: true,
                                                                        filtering: true,
                                                                        sorting: true,
                                                                        grouping: true,
                                                                        summary: false,
                                                                    },
                                                                    repaintChangesOnly: true,
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    width: "auto",
                                                                    height: "100%",
                                                                    editing: {
                                                                        mode: "cell",
                                                                        allowUpdating: true,
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    columns: [
                                                                        {
                                                                            dataField: "fecha",
                                                                            caption: "Mes", //getTrad('denominacion'),
                                                                            minWidth: "200px",
                                                                            sortOrder: "asc",
                                                                            allowEditing: false,
                                                                            format: "month",
                                                                            customizeText: function (cellInfo) {
                                                                                return (
                                                                                    cellInfo.valueText
                                                                                        .charAt(0)
                                                                                        .toUpperCase() +
                                                                                    cellInfo.valueText.slice(1)
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "kpiOperativo",
                                                                            caption: "KPI Operativo", //getTrad('kgProducido'),
                                                                            width: "170px",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            format: {
                                                                                style: "decimal",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 4,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 4,
                                                                                },
                                                                                step: 0,
                                                                                min: 0,
                                                                                max: 999,
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "costeUd",
                                                                            caption: "Coste unitario", //getTrad('kgProducido'),
                                                                            width: "170px",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 5,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 5,
                                                                                },
                                                                                step: 0,
                                                                                min: 0,
                                                                                max: 9999.99999,
                                                                            },
                                                                        },
                                                                    ],
                                                                    onRowUpdating: function (cellInfo) {
                                                                        if (
                                                                            typeof cellInfo.newData.costeUd ===
                                                                            "undefined"
                                                                        ) {
                                                                            cellInfo.newData.costeUd =
                                                                                cellInfo.oldData.costeUd;
                                                                        }

                                                                        if (
                                                                            typeof cellInfo.newData.kpiOperativo ===
                                                                            "undefined"
                                                                        ) {
                                                                            cellInfo.newData.kpiOperativo =
                                                                                cellInfo.oldData.kpiOperativo;
                                                                        }
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                template: function () {
                                                                                    return $("<div />").dxToolbar({
                                                                                        height: "100%",
                                                                                        width: "150px",
                                                                                        items: [
                                                                                            {
                                                                                                location: "before",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinleft",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_kpi =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_kpi"
                                                                                                                    ).text()
                                                                                                                ) - 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_kpi"
                                                                                                            ).text(
                                                                                                                añoSel_kpi
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "center",
                                                                                                template: function () {
                                                                                                    return $(
                                                                                                        "<div id='contenedorañoSel_kpi'/>"
                                                                                                    )
                                                                                                        .css(
                                                                                                            "font-size",
                                                                                                            "18px"
                                                                                                        )
                                                                                                        .css(
                                                                                                            "color",
                                                                                                            "rgb(115,115,115)"
                                                                                                        )
                                                                                                        .text(
                                                                                                            añoSel_kpi
                                                                                                        );
                                                                                                },
                                                                                            },
                                                                                            {
                                                                                                location: "after",
                                                                                                widget: "dxButton",
                                                                                                options: {
                                                                                                    icon: "spinright",
                                                                                                    stylingMode: "text",
                                                                                                    onClick:
                                                                                                        function () {
                                                                                                            añoSel_kpi =
                                                                                                                parseInt(
                                                                                                                    $(
                                                                                                                        "#contenedorañoSel_kpi"
                                                                                                                    ).text()
                                                                                                                ) + 1;
                                                                                                            $(
                                                                                                                "#contenedorañoSel_kpi"
                                                                                                            ).text(
                                                                                                                añoSel_kpi
                                                                                                            );
                                                                                                            e.component.refresh(
                                                                                                                true
                                                                                                            );
                                                                                                        },
                                                                                                },
                                                                                            },
                                                                                        ],
                                                                                    });
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                options: {
                                                                                    icon: "refresh",
                                                                                    text: getTrad("actualizar"),
                                                                                    onClick: function () {
                                                                                        e.component.refresh(true);
                                                                                    },
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                });
                                                            },
                                                        },
                                                        {
                                                            baseSize: 35,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $(
                                                                    "<div class='font-size' style='margin-left:9px;'>"
                                                                ).text(getTrad("observaciones"));
                                                            },
                                                        },
                                                        {
                                                            baseSize: 75,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template: function () {
                                                                return $("<div id='taObservaciones' />").dxTextArea({
                                                                    height: "75px",
                                                                    width: "100%",
                                                                    onValueChanged: function (e) {
                                                                        datasource_tipoKpi_observaciones
                                                                            .store()
                                                                            .update(idTipoKpiSel, {
                                                                                observaciones: e.value,
                                                                            });
                                                                    },
                                                                });
                                                            },
                                                        },
                                                    ],
                                                });
                                            },
                                        },
                                    ],
                                });
                            },
                        },
                    ],
                });
            //#endregion
        })
        .always(function () {
            deferred.resolve();
        });

    return deferred.promise();
}
