import React from "react";
import { PersonaSel } from "../..";
import Form, { EmptyItem, Item, PatternRule, RequiredRule } from "devextreme-react/form";

class DatosLaboralesForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <PersonaSel.Consumer>
                {({ personaSel, dxFormItem_render }) => {
                    return <CustomForm personaSel={personaSel} dxFormItem_render={dxFormItem_render} />;
                }}
            </PersonaSel.Consumer>
        );
    }
}

class CustomForm extends React.Component {
    constructor(props) {
        super(props);

        this.horasSemanales_pattern = /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/;
        this.dxForm_REF = React.createRef();
    }

    //   shouldComponentUpdate(nextProps, nextState) {
    //     return JSON.stringify(this.props.personaSel) !== JSON.stringify(nextProps.personaSel);
    //   }

    label = { visible: false };
    render() {
        const { personaSel, dxFormItem_render } = this.props;

        let parsed = JSON.parse(personaSel?.idCentroLav ?? "[]");
        let idLavanderia = parsed[1] > 0 ? parsed[1] : null;

        return (
            <Form ref={this.dxForm_REF} formData={personaSel} colCount={8} colSpan={2}>
                {/* <Item colSpan={8} render={this.renderAsociarLlamamiento} /> */}
                <Item dataField="idCentroLav" colSpan={4} label={this.label} render={dxFormItem_render}>
                    <RequiredRule />
                </Item>
                <Item
                    dataField="idAdmElementoPEP"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                    visible={idLavanderia != null}
                />
                <Item
                    dataField="idAdmCentroCoste"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                    visible={idLavanderia == null}
                />
                <Item
                    dataField="idTipoTrabajo"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                    visible={idLavanderia != null}
                />
                <Item
                    dataField="idTurno"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                    visible={idLavanderia != null}
                />
                <Item
                    dataField="idAdmCuentaContable_Salario"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                />
                <Item
                    dataField="idAdmCuentaContable_SSEmpresa"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                />
                <Item dataField="idCategoriaConvenio" colSpan={4} label={this.label} render={dxFormItem_render} />
                <Item dataField="idCategoriaInterna" colSpan={4} label={this.label} render={dxFormItem_render} />
                <Item
                    dataField="horasDiarias"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                    visible={idLavanderia != null}
                >
                    <PatternRule pattern={this.horasSemanales_pattern} message={"Tiempo incorrecto"} />
                </Item>
                <Item dataField="idFormatoDiasLibres" colSpan={4} label={this.label} render={dxFormItem_render} />
                <Item
                    cssClass="p-0"
                    dataField="isFormPedidoAllowed"
                    colSpan={4}
                    label={this.label}
                    render={dxFormItem_render}
                />
                <Item dataField="selectorDiasLibres" colSpan={4} label={this.label} render={dxFormItem_render} />
                <EmptyItem colSpan={4} visible={idLavanderia == null} />
                <Item dataField="observacionesLaborales" colSpan={8} label={this.label} render={dxFormItem_render} />
            </Form>
        );
    }
}

export default DatosLaboralesForm;
