import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    getTrad,
    formatDate_parameter,
    startOfMonth,
    endOfMonth,
    dxMensajePregunta,
} from "../../../helpers";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import Query from "devextreme/data/query";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Box, { Item as ItemBox } from "devextreme-react/box";

import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import Resumen from "./components/Resumen";
import DataGridPersonas from "./components/DataGridPersonas";
import PopupModificarPluses from "./components/PopupModificarPluses";
import { Button, Switch } from "devextreme-react";
import query from "devextreme/data/query";
import ODataContext from "devextreme/data/odata/context";
import LoadPanel_ProgressBar from "components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";
import notify from "devextreme/ui/notify";
import PopupCompararDatosSalariales from "./components/PopupCompararDatosSalariales";
import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsCategoriaInterna from "constants/enums/RRHH/tblCategoriaInterna";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";
import idsTipoNomina from "constants/enums/RRHH/tblTipoNomina";

const pageSizeEnviarNominas = 20;
const pageSizeImportarNominas = 10;

class GestionNominas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            gestionaPluses: null,
            vista_GestionPluses: null,
            fechaIni: new Date(startOfMonth(new Date()).setHours(0, 0, 0, 0)),
            fechaFin: new Date(endOfMonth(new Date()).setHours(23, 59, 59, 999)),
            fullData: [],
            resumenData: [],
            selectorPersona: [],
            isVisibleSalarioBruto: false,
            iteracion: 1,
            progress: 0,
            nominas: [],
            isVisible_LoadPanel_ProgressBar: false,
            title_LoadPanel_ProgressBar: null,
            popupCompararDatosSalariales_visible: false,
            filtroFiniquitos: false,
        };

        //FECHA
        this.dateRangePicker_onDateSelected = this.dateRangePicker_onDateSelected.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
    }

    context_gestionNominas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/",
        entities: {
            EnviarNominasGestoria: {},
            ImportarNominasGestoria: {},
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => this.context_gestionNominas_beforeSend(request),
    });

    context_gestionNominas_beforeSend = (request) => {
        const { fechaIni, fechaFin, iteracion } = this.state;

        request.headers = { ...authHeader() };
        request.timeout = 90000;
        request.params.fechaDesde = formatDate_parameter(fechaIni);
        request.params.fechaHasta = formatDate_parameter(fechaFin);

        if (request.url.includes("EnviarNominasGestoria")) {
            request.payload = this.filter_enviarNominas().slice((iteracion - 1) * pageSizeEnviarNominas, iteracion * pageSizeEnviarNominas);
        } else if (request.url.includes("ImportarNominasGestoria")) {
            request.payload = this.filter_importarNominas().slice((iteracion - 1) * pageSizeImportarNominas, iteracion * pageSizeImportarNominas);
        }
    };

    datasource_gestionNominas_personas = new DataSource({
        paginate: false,
        store: new ODataStore({
            key: ["idPersona", "idNomina"],
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/Personas",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_gestionNominas_personas_beforeSend(request);
            },
            onLoaded: (data) => {
                this.datasource_gestionNominas_personas_onLoaded(data);
            },
            version: 4,
        }),
        postProcess: data => this.datasource_gestionNominas_personas_postProcess(data)
    });

    datasource_gestionNominas_personas_beforeSend(request) {
        let { fechaIni, fechaFin } = this.state;

        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaIni);
        request.params.fechaHasta = formatDate_parameter(fechaFin);
    }

    datasource_gestionNominas_personas_onLoaded = async (data) => {
        const { vista_GestionPluses } = this.state;

        const nominas = data.map((n) => ({ idNomina: n.idNomina, idPersona: n.idPersona, idEstadoNomina: n.idEstadoNomina, enableSend: n.enableSend }));

        let maxIdEstadoNomina = await Query(data).max("idEstadoNomina");

        let gestionaPluses = data.some((n) => n.gestionaPluses);
        let vista_GestionPluses_ = vista_GestionPluses == null ? gestionaPluses : vista_GestionPluses;

        this.setState({
            fullData: data,
            resumenData: this.calculate_resumenData(data, vista_GestionPluses_),
            isVisibleSalarioBruto: maxIdEstadoNomina === idsEstadoNomina.solicitudCambioRRHH || maxIdEstadoNomina >= idsEstadoNomina.solicitudCambioGestoria,
            gestionaPluses,
            vista_GestionPluses: vista_GestionPluses_,
            nominas,
        });
    };

    datasource_gestionNominas_personas_postProcess = data => {
        return data.map(actualN => ({
            ...actualN,
            conflictoNominaContrato: data.some(n => actualN.idPersona === n.idPersona && actualN.idTipoNomina === idsTipoNomina.pagaMensual && n.idTipoNomina === idsTipoNomina.pagaMensual && actualN.isContratoAsociado !== n.isContratoAsociado),
        }));
    };

    calculate_resumenData = (data, vista_GestionPluses) => {
        return Query(data)
            .filter((n) => (vista_GestionPluses ? n.gestionaPluses : true))
            .select("idTipoTrabajo", "plusActividad", "plusProductividad")
            .groupBy("idTipoTrabajo")
            .select(function ({ key, items }) {
                var resultItem = {
                    idTipoTrabajo: key,
                    isValido:
                        Query(items)
                            .filter((dataItem) => dataItem.plusActividad == null || dataItem.plusProductividad == null)
                            .toArray().length == 0,
                };
                return resultItem;
            })
            .toArray();
    };

    get_dateRangePicker = () => {
        let { fechaIni, fechaFin } = this.state;
        let fechaSel = {
            startDate: fechaIni,
            endDate: fechaFin,
            idTipoCalendario: 0,
        };

        return (
            <DateRangePicker
                month
                fechaSel={fechaSel}
                align={"right"}
                onDateSelected={this.dateRangePicker_onDateSelected}
            />
        );
    };

    dateRangePicker_onDateSelected(fecha) {
        if (fecha) {
            let _this = this;
            this.setState({ fechaIni: fecha.startDate, fechaFin: fecha.endDate }, () => {
                _this.datasource_gestionNominas_personas.reload();
            });
        }
    }

    render() {
        let {
            gestionaPluses,
            vista_GestionPluses,
            resumenData,
            fechaIni,
            fechaFin,
            PopupModificarPluses_visible,
            popupCompararDatosSalariales_visible,
            selectorPersona,
            isVisibleSalarioBruto,
            progress,
            isVisible_LoadPanel_ProgressBar,
            title_LoadPanel_ProgressBar,
            filtroFiniquitos
        } = this.state;
        const { user } = this.props;

        return (
            <Fragment>
                <PageTitle
                    heading={getNombreFormulario(this)}
                    postHeadingEnd={
                        <div className="d-flex flex-row he-100 align-items-center">
                            <div className="font-weight-bold pr-3">Fecha:</div>
                            {this.get_dateRangePicker()}
                        </div>
                    }
                />
                <div className={"media-body"}>
                    <div id="GestionNominas" className="formContainer scrollbar-container">
                        <Box direction="col" width="100%" height="100%">
                            <ItemBox baseSize={"auto"}>
                                <div className="w-100 d-flex flex-row justify-content-between">
                                    <Resumen resumenData={resumenData} />
                                    <div className="d-flex align-items-center">
                                        {user.enableDatosRRHH
                                            ? <Button
                                                text={getTrad("compararDatosSalariales")}
                                                className={"mr-3"}
                                                onClick={this.onClick_compararInformacion}
                                            />
                                            : null
                                        }
                                        {vista_GestionPluses === false && (
                                            [idsCategoriaInterna.administrativoRRHH].includes(user.idCategoriaInterna)
                                            || [idsCargo.desarrollador].includes(user.idCargo)
                                            || [idsUsuario.maribelMoranta].includes(user.idUsuario)
                                        ) ? (
                                            <>
                                                <Button
                                                    text={getTrad("enviarNominas")}
                                                    className={"mr-3"}
                                                    onClick={this.onClick_enviarNominas}
                                                />
                                                <Button
                                                    text={getTrad("importarNominas")}
                                                    className={"mr-3"}
                                                    onClick={this.onClick_importarNominas}
                                                />
                                            </>
                                        ) : null}
                                        {user.enableDatosRRHH && gestionaPluses
                                            ? <Switch
                                                width={200}
                                                height={36}
                                                className="no-disabled mr-3"
                                                switchedOnText={getTrad("vistaGestionPluses")}
                                                switchedOffText={getTrad("vistaCompleta")}
                                                value={vista_GestionPluses}
                                                onValueChanged={this.dxSwitch_vista_GestionPluses_onValueChanged}
                                            />
                                            : null
                                        }
                                        {gestionaPluses
                                            ? <Button
                                                disabled={!vista_GestionPluses}
                                                text={"Modificar pluses"}
                                                className={"mr-3"}
                                                onClick={this.dxButton_popupModificarPluses_onClick}
                                            />
                                            : null
                                        }
                                        {!vista_GestionPluses
                                            ? <Switch
                                                width={200}
                                                height={36}
                                                className={"no-disabled"}
                                                switchedOnText={getTrad("todasPagas")}
                                                switchedOffText={getTrad("soloPagaMensual")}
                                                value={filtroFiniquitos}
                                                onValueChanged={this.onValueChanged_filtroPagasMensuales}
                                            />
                                            : null
                                        }
                                    </div>
                                </div>
                            </ItemBox>
                            <ItemBox ratio={1}>
                                <DataGridPersonas
                                    vista_GestionPluses={vista_GestionPluses}
                                    filtroFiniquitos={filtroFiniquitos}
                                    fechaIni={fechaIni}
                                    fechaFin={fechaFin}
                                    isVisibleSalarioBruto={isVisibleSalarioBruto}
                                    datasource_gestionNominas_personas={this.datasource_gestionNominas_personas}
                                />
                            </ItemBox>
                        </Box>
                    </div>
                </div>
                <PopupModificarPluses
                    visible={PopupModificarPluses_visible}
                    onHiding={this.PopupModificarPluses_onHiding}
                    datasource_gestionNominas_personas={this.datasource_gestionNominas_personas}
                    dataSource={selectorPersona}
                />
                <PopupCompararDatosSalariales
                    visible={popupCompararDatosSalariales_visible}
                    onHiding={this.onHiding_compararInformacion}
                />
                <LoadPanel_ProgressBar
                    title={title_LoadPanel_ProgressBar}
                    progress={progress}
                    visible={isVisible_LoadPanel_ProgressBar}
                />
            </Fragment>
        );
    }

    filter_enviarNominas = () => {
        const { nominas } = this.state;

        return [...new Set(nominas.filter(n => n.enableSend && [idsEstadoNomina.enProceso, idsEstadoNomina.validadoRRHH].includes(n.idEstadoNomina)).map((n) => n.idPersona))];
    };

    filter_importarNominas = () => {
        const { nominas } = this.state;

        const idsUnicos = [...new Set(
            nominas
                .filter(
                    (n) =>
                        n.idNomina === null
                        || [
                            idsEstadoNomina.enProceso,
                            idsEstadoNomina.validadoRRHH,
                            idsEstadoNomina.solicitudCambioGestoria,
                            idsEstadoNomina.importadoConConflictos,
                            idsEstadoNomina.validadoGestoria
                        ].includes(n.idEstadoNomina)
                )
                .map(n => n.idPersona)
        )];

        return idsUnicos;
    };

    onClick_compararInformacion = () => this.setState({ popupCompararDatosSalariales_visible: true });

    onHiding_compararInformacion = () => this.setState({ popupCompararDatosSalariales_visible: false });

    onClick_enviarNominas = async () => {
        const idsPersona = this.filter_enviarNominas();

        const iteraciones = Math.ceil(idsPersona.length / pageSizeEnviarNominas);

        if (iteraciones === 0) {
            notify({
                message: getTrad("sinNominasEnviar"),
                type: "success",
                displayTime: 1500,
                closeOnClick: true,
            });

            return;
        }

        await this.setStateAsync({
            progress: 0,
            isVisible_LoadPanel_ProgressBar: true,
            title_LoadPanel_ProgressBar: getTrad("enviandoNominas"),
        });

        for (let i = 1; i <= iteraciones; i++) {
            await this.setStateAsync({ iteracion: i });
            await this.context_gestionNominas
                .invoke("EnviarNominasGestoria", {}, "POST")
                .done(() => {
                    const progress = (i / iteraciones) * 100;

                    if (progress === 100) {
                        notify({
                            message: getTrad("enviarNominasCorrecto"),
                            type: "success",
                            displayTime: 1500,
                            closeOnClick: true,
                        });

                        this.datasource_gestionNominas_personas.reload();
                    }

                    this.setState({ progress: Math.ceil(progress) });
                })
                .catch(() => {
                    i = iteraciones;
                    notify({
                        message: getTrad("errorEnviarNominas"),
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });

                    this.setState({ progress: 100 });
                });
        }
    };

    onClick_importarNominas = async () => {
        let conflictoNominaContrato = false;

        const personas = this.filter_importarNominas();

        const iteraciones = Math.ceil(personas.length / pageSizeImportarNominas);

        if (iteraciones === 0) {
            notify({
                message: getTrad("sinNominasImportar"),
                type: "success",
                displayTime: 1500,
                closeOnClick: true,
            });

            return;
        }

        await this.setStateAsync({
            progress: 0,
            isVisible_LoadPanel_ProgressBar: true,
            title_LoadPanel_ProgressBar: getTrad("importandoNominas"),
        });

        for (let i = 1; i <= iteraciones; i++) {
            await this.setStateAsync({ iteracion: i });
            await this.context_gestionNominas
                .invoke("ImportarNominasGestoria", {}, "POST")
                .done(result => {
                    conflictoNominaContrato = result || conflictoNominaContrato;

                    const progress = (i / iteraciones) * 100;

                    if (progress === 100) {
                        if (conflictoNominaContrato) {
                            dxMensajePregunta(
                                getTrad("aviso_I_nominasSinContrato"),
                                [
                                    [getTrad("aceptar"), () => { }, "danger"]
                                ]
                            );
                        }

                        notify({
                            message: getTrad("importarNominasCorrecto"),
                            type: "success",
                            displayTime: 1500,
                            closeOnClick: true,
                        });

                        this.datasource_gestionNominas_personas.reload();
                    }

                    this.setState({ progress: Math.ceil(progress) });
                })
                .catch(() => {
                    i = iteraciones;
                    notify({
                        message: getTrad("errorImportarNominas"),
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });

                    this.setState({ progress: 100 });
                });
        }
    };

    setStateAsync = (state) =>
        new Promise((resolve) => {
            this.setState(state, resolve);
        });

    dxSwitch_vista_GestionPluses_onValueChanged = (e) => {
        const { fullData } = this.state;
        this.setState({ vista_GestionPluses: e.value, resumenData: this.calculate_resumenData(fullData, e.value) });
    };

    dxButton_popupModificarPluses_onClick = () => {
        const { vista_GestionPluses, fullData } = this.state;

        var selectorPersona = query(fullData)
            .filter((n) => (vista_GestionPluses ? n.gestionaPluses : true))
            .select(
                "idPersona",
                "nombre",
                "apellidos",
                "centroLav",
                "tipoTrabajo",
                "fechaInicioNomina",
                "fechaFinNomina"
            )
            .toArray();

        this.setState({ PopupModificarPluses_visible: true, selectorPersona });
    };

    onValueChanged_filtroPagasMensuales = () => this.setState({ filtroFiniquitos: !this.state.filtroFiniquitos });

    PopupModificarPluses_onHiding = () => this.setState({ PopupModificarPluses_visible: false });

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { progress } = this.state;

        if (progress === 100 && prevState.progress !== progress) {
            setTimeout(() => {
                this.setState({ isVisible_LoadPanel_ProgressBar: false });
            }, 300);
        }
    };
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GestionNominas);
