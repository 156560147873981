import { getTrad } from "helpers";
import React, { Fragment } from "react";

export default () => {
  let toogleOpciones = () => {
    var opciones_container = document.getElementById("opciones_container");
    opciones_container.classList.toggle("opened");

    var opciones_close = document.getElementById("opciones_close");
    opciones_close.classList.toggle("opened");
    opciones_close.classList.toggle("icon_AbrirMenu");
    opciones_close.classList.toggle("icon_Cerrar");
  };

  return (
    <Fragment>
      <div
        id="opciones_title"
        className="container_spanCentrado"
        onClick={toogleOpciones}
      >
        <span>{getTrad("opciones").toUpperCase()}</span>
        <div
          id="opciones_close"
          className="container_spanCentrado icon_AbrirMenu"
        ></div>
      </div>
    </Fragment>
  );
};
