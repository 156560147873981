export const bell = {
  v: "5.6.5",
  fr: 60,
  ip: 0,
  op: 120,
  w: 48,
  h: 48,
  nm: "07 Bell",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Ball",
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.2], y: [0] },
              t: 5.18,
              s: [0],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.4], y: [0] },
              t: 17.266,
              s: [2],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.2], y: [0] },
              t: 36.26,
              s: [-9],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 53.525,
              s: [0],
            },
            {
              i: { x: [0.67], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 69.064,
              s: [-3],
            },
            { t: 80, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.2, y: 0 },
              t: 5.18,
              s: [11.637, 22, 0],
              to: [-0.723, 0.13, 0],
              ti: [0.008, -0.012, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.4, y: 0 },
              t: 17.266,
              s: [9.666, 22.277, 0],
              to: [-0.008, 0.012, 0],
              ti: [-0.019, -0.009, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.2, y: 0 },
              t: 36.26,
              s: [16.566, 21.53, 0],
              to: [0.019, 0.009, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0, y: 0 },
              t: 53.525,
              s: [6.116, 21.747, 0],
              to: [0, 0, 0],
              ti: [0.53, -0.096, 0],
            },
            {
              i: { x: 0.67, y: 1 },
              o: { x: 0.33, y: 0 },
              t: 69.064,
              s: [13.274, 21.67, 0],
              to: [-0.723, 0.13, 0],
              ti: [0, 0, 0],
            },
            { t: 80, s: [11.637, 22, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [5.637, 2, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.2, y: 0 },
                    t: 5.18,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0, 1.804],
                          [-2.58, 0.73],
                          [-3.637, -1.804],
                          [3.637, -1.804],
                          [2.538, 0.73],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.807 },
                    o: { x: 0.4, y: 0 },
                    t: 17.266,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [-0.66, 2.179],
                          [-3.324, 1.403],
                          [-3.757, -1.841],
                          [3.435, -1.775],
                          [2.336, 0.759],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.167, y: 0.193 },
                    t: 29.354,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [-0.923, 1.989],
                          [-3.733, 0.695],
                          [-4.721, -1.918],
                          [3.615, -1.801],
                          [2.516, 0.733],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.2, y: 0 },
                    t: 36.26,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0.085, 2.125],
                          [-2.495, 1.051],
                          [-3.637, -1.804],
                          [3.637, -1.804],
                          [2.623, 1.051],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    t: 45,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0.184, 2.277],
                          [-2.396, 1.203],
                          [-3.506, -1.402],
                          [3.716, -1.076],
                          [2.722, 1.203],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 48,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0.199, 2.3],
                          [-2.381, 1.226],
                          [-3.718, -1.456],
                          [3.503, -1.101],
                          [2.737, 1.226],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0, y: 0 },
                    t: 53.525,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0.206, 2.31],
                          [-2.374, 1.236],
                          [-3.637, -1.804],
                          [3.637, -1.804],
                          [2.744, 1.236],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.67, y: 1 },
                    o: { x: 0.33, y: 0 },
                    t: 69.064,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.76, -0.688],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.782, 0.708],
                        ],
                        v: [
                          [0.067, 1.706],
                          [-2.513, 0.632],
                          [-3.637, -1.804],
                          [3.63, -1.363],
                          [2.695, 0.723],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 80,
                    s: [
                      {
                        i: [
                          [0.958, 0],
                          [0.705, 0.716],
                          [0, 0.973],
                          [0, 0],
                          [0.733, -0.716],
                        ],
                        o: [
                          [-1.015, 0],
                          [-0.705, -0.716],
                          [0, 0],
                          [0, 0.973],
                          [-0.733, 0.716],
                        ],
                        v: [
                          [0, 1.804],
                          [-2.58, 0.73],
                          [-3.637, -1.804],
                          [3.637, -1.804],
                          [2.538, 0.73],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [5.637, 3.804], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Bell body",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.2], y: [0] }, t: 0, s: [0] },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.4], y: [0] },
              t: 17.266,
              s: [12.5],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.2], y: [0] },
              t: 31.08,
              s: [-25],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0], y: [0] },
              t: 48.346,
              s: [25],
            },
            {
              i: { x: [0.67], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 65.611,
              s: [-10],
            },
            { t: 77.69921875, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.2, y: 0 },
              t: 0,
              s: [24, 21.93, 0],
              to: [0, -0.322, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.4, y: 0 },
              t: 17.266,
              s: [24, 20, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0.2, y: 0 },
              t: 31.08,
              s: [24, 21.93, 0],
              to: [0, 0, 0],
              ti: [0, 0.022, 0],
            },
            {
              i: { x: 0.2, y: 1 },
              o: { x: 0, y: 0 },
              t: 48.346,
              s: [24, 20, 0],
              to: [0, -0.022, 0],
              ti: [0, -0.322, 0],
            },
            {
              i: { x: 0.67, y: 1 },
              o: { x: 0.33, y: 0 },
              t: 65.611,
              s: [24, 21.8, 0],
              to: [0, 0.322, 0],
              ti: [0, -0.022, 0],
            },
            { t: 77.69921875, s: [24, 21.93, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [11.5, 11.93, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.807 },
                    o: { x: 0.2, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [0, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.111, 6.32],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [0.033, 9.93],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.167, y: 0.193 },
                    t: 6.906,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [4.153, 0.103],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-4.103, -0.102],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.111, 6.32],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [0.007, 9.205],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.807 },
                    o: { x: 0.3, y: 0 },
                    t: 17.266,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [2.958, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-2.98, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.586, 6.15],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [-0.197, 10.621],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-6.766, 6.435],
                          [-6.766, 0.455],
                          [-5.349, -4.454],
                          [-1.446, -7.223],
                          [-1.446, -8.069],
                          [-0.946, -9.339],
                          [0.332, -9.875],
                          [1.609, -9.339],
                          [2.109, -8.069],
                          [2.109, -7.223],
                          [6.169, -4.739],
                          [7.586, 0.17],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.7, y: 1 },
                    o: { x: 0.167, y: 0.193 },
                    t: 24.172,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [1.479, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-1.49, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.111, 6.32],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [0.451, 9.032],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.807 },
                    o: { x: 0.2, y: 0 },
                    t: 31.08,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [2.958, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-2.98, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [6.79, 5.308],
                          [9.208, 7.244],
                          [9.624, 8.259],
                          [9.031, 8.85],
                          [0.062, 10.46],
                          [-9.161, 9.88],
                          [-9.753, 9.289],
                          [-9.338, 8.274],
                          [-7.365, 6.27],
                          [-7.365, 0.29],
                          [-5.948, -4.619],
                          [-2.397, -7.428],
                          [-2.397, -8.274],
                          [-1.897, -9.544],
                          [-0.619, -10.08],
                          [0.658, -9.544],
                          [1.158, -8.274],
                          [1.158, -7.428],
                          [5.248, -4.501],
                          [6.665, 0.408],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.167, y: 0.193 },
                    t: 39.713,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [2.948, -0.241],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-3.469, 0.284],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.235, 5.24],
                          [9.208, 7.244],
                          [9.624, 8.259],
                          [9.031, 8.85],
                          [0.695, 9.016],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.807 },
                    o: { x: 0, y: 0 },
                    t: 48.346,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [2.958, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-2.98, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.488, 5.191],
                          [9.461, 7.195],
                          [9.877, 8.21],
                          [9.284, 8.801],
                          [0.062, 10.46],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-6.689, 6.191],
                          [-6.689, 0.211],
                          [-5.272, -4.698],
                          [-1.468, -7.353],
                          [-1.468, -8.199],
                          [-0.968, -9.469],
                          [0.31, -10.005],
                          [1.587, -9.469],
                          [2.087, -8.199],
                          [2.087, -7.353],
                          [5.947, -4.619],
                          [7.364, 0.29],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.167, y: 0.193 },
                    t: 56.979,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [2.324, -0.023],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [-1.555, 0.015],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.172, 6.138],
                          [9.145, 8.142],
                          [9.561, 9.157],
                          [8.968, 9.748],
                          [0.028, 9.42],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.044, 6.299],
                          [-7.044, 0.319],
                          [-5.627, -4.59],
                          [-1.728, -7.29],
                          [-1.728, -8.136],
                          [-1.228, -9.406],
                          [0.05, -9.942],
                          [1.327, -9.406],
                          [1.827, -8.136],
                          [1.827, -7.29],
                          [5.735, -4.577],
                          [7.152, 0.332],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    i: { x: 0.65, y: 1 },
                    o: { x: 0.3, y: 0 },
                    t: 65.611,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [0, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.111, 6.32],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [0.033, 9.93],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 77.69921875,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -0.38],
                          [0.327, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0.327],
                          [-0.266, 0.27],
                          [0, 0],
                          [0, 0],
                          [-0.944, 1.391],
                          [-1.667, 0.413],
                          [0, 0],
                          [-0.334, 0.358],
                          [-0.519, 0],
                          [-0.333, -0.357],
                          [0, -0.49],
                          [0, 0],
                          [-0.944, -1.392],
                          [0, -1.881],
                        ],
                        o: [
                          [0, 0],
                          [0.266, 0.27],
                          [0, 0.327],
                          [0, 0],
                          [0, 0],
                          [-0.327, 0],
                          [0, -0.38],
                          [0, 0],
                          [0, 0],
                          [0, -1.881],
                          [0.945, -1.392],
                          [0, 0],
                          [0, -0.49],
                          [0.333, -0.357],
                          [0.518, 0],
                          [0.334, 0.358],
                          [0, 0],
                          [1.667, 0.413],
                          [0.944, 1.391],
                          [0, 0],
                        ],
                        v: [
                          [7.111, 6.32],
                          [9.084, 8.324],
                          [9.5, 9.339],
                          [8.907, 9.93],
                          [0.033, 9.93],
                          [-8.908, 9.93],
                          [-9.5, 9.339],
                          [-9.085, 8.324],
                          [-7.112, 6.32],
                          [-7.112, 0.34],
                          [-5.695, -4.57],
                          [-1.778, -7.277],
                          [-1.778, -8.124],
                          [-1.278, -9.393],
                          [0, -9.93],
                          [1.277, -9.393],
                          [1.777, -8.124],
                          [1.777, -7.277],
                          [5.694, -4.57],
                          [7.111, 0.34],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [11.5, 11.93], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Circle outline",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [24, 24, 0], ix: 2 },
        a: { a: 0, k: [25, 25, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-12.703, 0],
                    [0, -12.703],
                    [12.702, 0],
                    [0, 12.702],
                  ],
                  o: [
                    [12.702, 0],
                    [0, 12.702],
                    [-12.703, 0],
                    [0, -12.703],
                  ],
                  v: [
                    [0, -23],
                    [23, 0],
                    [0, 23],
                    [-23, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [25, 25], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 300,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
