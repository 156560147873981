import React from "react";

export class EmojiRating extends React.Component {
  render() {
    const {
      value,
      onClick_selection = null,
      size = "font-size-xxxl",
      spaceIcons = 2,
    } = this.props;

    const classNameCustom = onClick_selection ? "pointer shadowHover" : "";
    const spaceIconsBoostrap = " m-" + spaceIcons;

    return (
      <div className="he-100 d-flex flex-row d-flex justify-content-around px-5">
        {value == 1 ? (
          <div onClick={() => onClick_selection(1)} className={classNameCustom}>
            <div
              className={"icon_angry2 danger  " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        ) : (
          <div onClick={() => onClick_selection(1)} className={classNameCustom}>
            <div
              className={"icon_angry smokedWhite  " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        )}
        {value == 2 ? (
          <div onClick={() => onClick_selection(2)} className={classNameCustom}>
            <div
              className={"icon_sad2 danger " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        ) : (
          <div onClick={() => onClick_selection(2)} className={classNameCustom}>
            <div
              className={"icon_sad smokedWhite " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        )}
        {value == 3 ? (
          <div onClick={() => onClick_selection(3)} className={classNameCustom}>
            <div
              className={"icon_neutral2 warning " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        ) : (
          <div onClick={() => onClick_selection(3)} className={classNameCustom}>
            <div
              className={
                "icon_neutral smokedWhite " + size + spaceIconsBoostrap
              }
              style={size ? { fontSize: size } : {}}
            />
          </div>
        )}
        {value == 4 ? (
          <div onClick={() => onClick_selection(4)} className={classNameCustom}>
            <div
              className={"icon_smile2 turquoise " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        ) : (
          <div onClick={() => onClick_selection(4)} className={classNameCustom}>
            <div
              className={"icon_smile smokedWhite " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        )}
        {value == 5 ? (
          <div onClick={() => onClick_selection(5)} className={classNameCustom}>
            <div
              className={"icon_happy2 success " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        ) : (
          <div onClick={() => onClick_selection(5)} className={classNameCustom}>
            <div
              className={"icon_happy smokedWhite " + size + spaceIconsBoostrap}
              style={size ? { fontSize: size } : {}}
            />
          </div>
        )}
      </div>
    );
  }
}
