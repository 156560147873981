const idsCalendarioPersonal_Estado = {
    Vacaciones: 2,
    DiasTrabajados: 3,
    FestivosTrabajados: 4,
    Absentismo: 5,
    Baja: 6,
    PermisoRetribuido: 7,
    Festivo: 8,
    DiaLibre: 9,
    Cierre: 10,
    ERTE: 11,
    BajaAccidenteLaboral: 12,
    SanciónEmpleoSueldo: 13,
    CierreNomina: 14,
};

export default idsCalendarioPersonal_Estado;
