import React, { useEffect, useMemo, useState } from "react";
import $ from "jquery";

import DataGrid, {
    Column,
    ColumnChooser,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    FilterRow,
    Pager,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import { getTrad } from "helpers";
import { getClearPresupuesto } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    onCellPrepared_Total,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import {
    datasource_tblAdmPresupuestoVenta_Estado,
    datasource_tblCliente /* tblEstadoPedido */,
    datasource_tblMoneda,
    datasource_tblViasPagoCobro,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import { formats } from "pages/Administracion/shared/model";
import config_isCerrado from "pages/Administracion/shared/configs/config_isCerrado";
import { connect } from "react-redux";
import { impresionActions } from "actions";

const DataGridPresupuestosVenta = ({
    datasource,
    reloadData,
    setPresupuestoSel,
    openPopup,
    idEmpresaPolarier,
    loadPanel_hide,
    setParams,
    setReport,
    close,
}) => {
    const [clientes, setClientes] = useState([]);
    useEffect(() => {
        datasource_tblCliente.reload().done((data) => {
            setClientes(data);
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    useEffect(() => {
        setReport({
            denominacion: "administracion_presupuesto_cliente",
            parameterEvent: customizeParameterEditors,
        });

        return () => {
            close();
        };
    }, []);

    const customizeParameterEditors = (report, s, e) => {
        if (e.parameter.name === "idsAdmPresupuestoVenta") {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
    };

    const calculateCellValue = (e) => {
        if (e.tblArticuloNAdmPresupuestoVenta?.length > 0) {
            let total = 0;
            e.tblArticuloNAdmPresupuestoVenta.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += cantidad * precio * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
            return {
                valor: calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "venta"),
                idMoneda: e.idMoneda,
            };
        }
    };

    const cellRender = (e) => {
        return calculateDisplayValue(isNaN(e.value.valor) ? 0 : e.value.valor, e.value.idMoneda);
    };
    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    widget: "dxButton",
                    options: {
                        icon: "plus",
                        text: getTrad("nuevoPresupuesto"),
                        onClick: () => onNewPresupuesto(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            reloadData();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewPresupuesto = () => {
        const presupuesto = getClearPresupuesto();
        setPresupuestoSel({ ...presupuesto });
        openPopup();
    };

    const onRowPrepared_tblPresupuestos = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onCellClick_tblFacturas = (e) => {
        if (e.column?.name === "impresion") {
            setParams({ idsAdmPresupuestoVenta: [e.data.idAdmPresupuestoVenta] });
        } else if (e.data) {
            setPresupuestoSel({ ...e.data, articulosDraft: [...e.data.tblArticuloNAdmPresupuestoVenta] });
            openPopup();
        }
    };

    const cellRender_impresion = () => {
        const style = { color: "var(--secondary))" };

        return <i className={"dx-icon dx-icon-print container_spanCentrado font-size-lg"} style={style} />;
    };

    return (
        <DataGrid
            //Datos
            dataSource={datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblPresupuestos}
            onCellPrepared={onCellPrepared_Total}
            onCellClick={onCellClick_tblFacturas}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                name={"impresion"}
                caption={" "}
                width={30}
                allowReordering={false}
                allowResizing={false}
                cellRender={cellRender_impresion}
            />
            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fechaCreacion"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmCliente"}
                caption={getTrad("cliente")}
                // editorOptions={{
                //   valueExpr: 'idCliente',
                //   displayExpr: 'denominacion'
                // }}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={clientes} valueExpr="idAdmCliente" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmPresupuestoVenta_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmPresupuestoVenta_Estado, "", "presupuesto")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmPresupuestoVenta_Estado.store()}
                    valueExpr="idAdmPresupuestoVenta_Estado"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            {/* <Column
                dataField={"fechaEstimadaRecepcion"}
                caption={getTrad("fechaEstimadaRecepcion")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column
                dataField={"idMoneda"}
                caption={getTrad("moneda")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            {/* <Column
                dataField={"numPedidoCliente"}
                caption={getTrad("numPedidoCliente")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            {/* <Column
                dataField={"idPedidoCliente"}
                caption={getTrad("pedidoCliente")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                format={formats.percent}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            {/* <Column
                dataField={"idCentroCoste"}
                caption={getTrad("centroCoste")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            {/* <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            {/* <Column
                dataField={"numPresupuestoProveedor"}
                caption={getTrad("numPresupuestoProveedor")}
                visible={false}
                allowReordering
                allowResizing
            /> */}
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                visible={true}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue}
                cellRender={cellRender}
            />
            <Column {...config_isCerrado} />

            {/* <Column
        caption={' '}
        width={30}
        alignment={'center'}
        cssClass={'p-0'}
        cellComponent={removeCellComponent}
      /> */}

            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

const mapDispatchToProps = (dispatch) => ({
    setReport: (report) => dispatch(impresionActions.setReport(report)),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    close: () => dispatch(impresionActions.close()),
});

export default connect(null, mapDispatchToProps)(DataGridPresupuestosVenta);
