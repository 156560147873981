import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  formatDate,
  get_isMovil,
  convertClienteUtcToLavanderiaUtc,
} from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import query from "devextreme/data/query";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ScrollView from "devextreme-react/scroll-view";

import Box, { Item } from "devextreme-react/box";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
} from "devextreme-react/data-grid";

import {
  CircularGauge,
  Geometry,
  Scale,
  Label,
  Tick,
  RangeContainer,
  Range,
  ValueIndicator,
  Margin,
} from "devextreme-react/circular-gauge";

import Chart, {
  CommonSeriesSettings,
  Legend,
  Series,
  ValueAxis,
  ArgumentAxis,
  Tooltip,
} from "devextreme-react/chart";

import { Row, Col, Card, CardBody, CardHeader, Progress } from "reactstrap";

import UserBox from "../../../layout/AppHeader/Components/UserBox";
import LastUpdateClock from "../../../components/LastUpdateClock";

//Css
import "./Css.scss";

const tiempoRefresco_Actual = 30000;
const tiempoRefresco_Anterior = 60000;

class DashboardOperativo extends React.Component {
  constructor(props) {
    super(props);

    let { lavanderia } = this.props;
    this.state = {
      datos_Actual: {},
      datos_Anteriores: [],
      breaks_Chart: [],
      numDias: 8,
      ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
        lavanderia.GMT,
        new Date(),
      ),
    };

    //DATASOURCE
    this.datasource_datos_hoy_load = this.datasource_datos_hoy_load.bind(this);
    this.dataSource_spSelectOperativo_DiaActual_beforeSend =
      this.dataSource_spSelectOperativo_DiaActual_beforeSend.bind(this);

    this.dataSource_spSelectOperativo_DiasAnteriores_beforeSend =
      this.dataSource_spSelectOperativo_DiasAnteriores_beforeSend.bind(this);
    this.dataSource_spSelectOperativo_DiasAnteriores_onLoaded =
      this.dataSource_spSelectOperativo_DiasAnteriores_onLoaded.bind(this);

    //DATAGRID HORAS
    this.cellRender_kgProcesados = this.cellRender_kgProcesados.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  //#region DATSOURCES
  datasource_datos_hoy = new DataSource({
    paginate: false,
    store: new CustomStore({
      load: () => {
        this.datasource_datos_hoy_load();
      },
    }),
  });

  datasource_datos_hoy_load() {
    let { lavanderia } = this.props;
    var _deferred = $.Deferred();
    var _component = this;

    $.when(
      _component.dataSource_spSelectOperativo_DiaActual.load(),
      _component.dataSource_spSelectOperativo_DiaActual_Horas.load(),
    ).done(function () {
      var diaActual = _component.dataSource_spSelectOperativo_DiaActual.items();
      var diaActual_Horas =
        _component.dataSource_spSelectOperativo_DiaActual_Horas.items();

      var result = diaActual[0];
      result.horas = diaActual_Horas;

      // $.each(result.horas, function (index, dato_hora) {
      //     dato_hora.diferencia = dato_hora.acumulado - dato_hora.kgProcesados
      // })

      _component.setState({
        datos_Actual: result,
        ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
          lavanderia.GMT,
          new Date(),
        ),
      });
      _deferred.resolve(result);
    });

    return _deferred.promise();
  }

  dataSource_spSelectOperativo_DiaActual = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectOperativo_DiaActual",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectOperativo_DiaActual_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_spSelectOperativo_DiaActual_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  dataSource_spSelectOperativo_DiaActual_Horas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectOperativo_DiaActual_Horas",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectOperativo_DiaActual_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_spSelectOperativo_DiaActual_Horas_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  dataSource_spSelectOperativo_DiasAnteriores = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectOperativo_DiasAnteriores",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectOperativo_DiasAnteriores_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spSelectOperativo_DiasAnteriores_onLoaded(data);
      },
      version: 4,
    }),
  });

  dataSource_spSelectOperativo_DiasAnteriores_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { lavanderia } = this.props;
    let { numDias } = this.state;
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.numDias = numDias;
  }

  dataSource_spSelectOperativo_DiasAnteriores_onLoaded(data) {
    let { numDias } = this.state;
    let objFinal = {};

    //CHART Rendimiento
    $.each(data, function (index, item) {
      item.kgHorasProd =
        item.horasProductivas > 0
          ? item.kgProcesado / item.horasProductivas
          : null;
    });

    //BREAKS
    let breaks_Chart = [];
    for (let i = 1; i < data.length; i++) {
      const item = data[i];
      const itemAnterior = data[i - 1];
      if (item.fecha.getDate() - 1 !== itemAnterior.fecha.getDate()) {
        breaks_Chart.push({
          startValue: itemAnterior.fecha,
          endValue: item.fecha,
        });
      }
    }

    //KPIS
    objFinal.kpi_rechazo = data[0].kpi_rechazo;
    objFinal.kpi_agua = data[0].kpi_agua;
    objFinal.kpi_electricidad = data[0].kpi_electricidad;
    objFinal.kpi_calefaccion = data[0].kpi_calefaccion;
    objFinal.kpi_rendimiento = data[0].kpi_rendimiento;

    //DIA DE AYER
    let ayer = data[data.length - 1];
    objFinal.ayer_procesado = ayer.kgProcesado;

    if (ayer.kgProcesado && ayer.kgProcesado > 0) {
      objFinal.ayer_rendimiento =
        ayer.horasProductivas && ayer.horasProductivas > 0
          ? ayer.kgProcesado / ayer.horasProductivas
          : null;
      objFinal.ayer_agua =
        ayer.agua && ayer.agua > 0 ? ayer.agua / ayer.kgProcesado : null;
      objFinal.ayer_electricidad =
        ayer.electricidad && ayer.electricidad > 0
          ? ayer.electricidad / ayer.kgProcesado
          : null;
      objFinal.ayer_calefaccion =
        ayer.calefaccion && ayer.calefaccion > 0
          ? ayer.calefaccion / ayer.kgProcesado
          : null;
    } else {
      objFinal.ayer_rendimiento = null;
      objFinal.ayer_agua = null;
      objFinal.ayer_electricidad = null;
      objFinal.ayer_calefaccion = null;
    }

    if (ayer.kgProcesado_produccion && ayer.kgProcesado_produccion > 0) {
      objFinal.ayer_rechazo =
        ayer.kgRechazo && ayer.kgRechazo > 0
          ? (ayer.kgRechazo / ayer.kgProcesado_produccion) * 100
          : null;
    } else {
      objFinal.ayer_rechazo = null;
    }

    //MEDIA DIAS ANTERIORES

    objFinal.media_procesado = null;
    objFinal.media_rechazo = null;
    objFinal.media_procesado_produccion = null;
    objFinal.media_rendimiento = null;
    objFinal.media_agua = null;
    objFinal.media_electricidad = null;
    objFinal.media_calefaccion = null;

    const step = (objFinal, itemData) => {
      if (itemData.kgProcesado > 0) {
        objFinal.media_procesado =
          itemData.kgProcesado !== null
            ? objFinal.media_procesado + itemData.kgProcesado
            : objFinal.media_procesado;
        objFinal.media_rechazo =
          itemData.kgRechazo !== null
            ? objFinal.media_rechazo + itemData.kgRechazo
            : objFinal.media_rechazo;
        objFinal.media_procesado_produccion =
          itemData.kgProcesado_produccion !== null
            ? objFinal.media_procesado_produccion +
              itemData.kgProcesado_produccion
            : objFinal.media_procesado_produccion;
        objFinal.media_rendimiento =
          itemData.horasProductivas !== null
            ? objFinal.media_rendimiento + itemData.horasProductivas
            : objFinal.media_rendimiento;
        objFinal.media_agua =
          itemData.agua !== null
            ? objFinal.media_agua + itemData.agua
            : objFinal.media_agua;
        objFinal.media_electricidad =
          itemData.electricidad !== null
            ? objFinal.media_electricidad + itemData.electricidad
            : objFinal.media_electricidad;
        objFinal.media_calefaccion =
          itemData.calefaccion !== null
            ? objFinal.media_calefaccion + itemData.calefaccion
            : objFinal.media_calefaccion;
      }

      return objFinal;
    };
    const finalize = (objFinal) => {
      if (objFinal.media_procesado_produccion > 0) {
        objFinal.media_rechazo =
          objFinal.media_rechazo !== null
            ? (objFinal.media_rechazo / objFinal.media_procesado_produccion) *
              100
            : null;
      }
      if (objFinal.media_procesado > 0) {
        objFinal.media_rendimiento =
          objFinal.media_rendimiento !== null
            ? objFinal.media_procesado / objFinal.media_rendimiento
            : null;
        objFinal.media_agua =
          objFinal.media_agua !== null
            ? objFinal.media_agua / objFinal.media_procesado
            : null;
        objFinal.media_electricidad =
          objFinal.media_electricidad !== null
            ? objFinal.media_electricidad / objFinal.media_procesado
            : null;
        objFinal.media_calefaccion =
          objFinal.media_calefaccion !== null
            ? objFinal.media_calefaccion / objFinal.media_procesado
            : null;
        objFinal.media_procesado =
          objFinal.media_procesado !== null
            ? objFinal.media_procesado / numDias
            : null;
      }

      return objFinal;
    };

    query(data)
      .aggregate(objFinal, step, finalize)
      .then((result) => {
        let datos_Anteriores_mapeados = {
          procesado: {
            titulo: "procesado",
            icono: null,
            kpi: null,
            ayer: result.ayer_procesado,
            media: result.media_procesado,
          },
          rendimiento: {
            titulo: "rendimiento",
            icono: "icon_Rendimiento",
            kpi: result.kpi_rendimiento,
            ayer: result.ayer_rendimiento,
            media: result.media_rendimiento,
          },
          rechazo: {
            titulo: "rechazo",
            icono: "icon_Rechazo",
            kpi: result.kpi_rechazo,
            ayer: result.ayer_rechazo,
            media: result.media_rechazo,
          },
          agua: {
            titulo: "agua",
            icono: "icon_Agua",
            kpi: result.kpi_agua,
            ayer: result.ayer_agua,
            media: result.media_agua,
          },
          electricidad: {
            titulo: "electricidad",
            icono: "icon_Electricidad",
            kpi: result.kpi_electricidad,
            ayer: result.ayer_electricidad,
            media: result.media_electricidad,
          },
          calefaccion: {
            titulo: "calefaccion",
            icono: "icon_Calefaccion",
            kpi: result.kpi_calefaccion,
            ayer: result.ayer_calefaccion,
            media: result.media_calefaccion,
          },
        };

        let { lavanderia } = this.props;
        this.setState({
          datos_Anteriores: datos_Anteriores_mapeados,
          breaks_Chart: breaks_Chart,
          ultimaFecha_load: convertClienteUtcToLavanderiaUtc(
            lavanderia.GMT,
            new Date(),
          ),
        });
        this.loadPanel_hide();
      });
    return data;
  }
  //#endregion

  render() {
    let { datos_Actual, datos_Anteriores, breaks_Chart } = this.state;
    let { enableFullScreen, resolucion, title } = this.props;

    //Calculamos si el último registro coincide con la fecha de ayer para poner el label "AYER" o "ÚLTIMO DÍA"
    let isAyer = true;
    let itemsAnteriores =
      this.dataSource_spSelectOperativo_DiasAnteriores.items();
    if (itemsAnteriores.length > 0) {
      let ayer = ((d) => new Date(d.setDate(d.getDate() - 1)))(new Date());
      let ultimaFecha = itemsAnteriores[itemsAnteriores.length - 1].fecha;
      isAyer = formatDate(ayer) === formatDate(ultimaFecha);
    }

    setTimeout(() => {
      if (typeof $("#Chart").data("Chart") !== "undefined")
        $("#Chart").dxChart("instance").render();
    }, 0);
    return (
      <Fragment>
        <PageTitle
          heading={title ? title : getNombreFormulario(this)}
          postHeading={
            <Fragment>
              <LastUpdateClock fecha={this.state.ultimaFecha_load} />
              {enableFullScreen === true ? <UserBox /> : ""}
            </Fragment>
          }
        />
        <div className={"media-body he-100 "}>
          <div id="DashboardOperativo" className="he-100">
            <ScrollView id="dxScrollView_principal" className={"cardContent "}>
              <Row className="row-1 he-100 flex-fill">
                <Col
                  xs="12"
                  md="12"
                  lg=""
                  xl=""
                  className={
                    "col-xl-40 pt-3 d-flex flex-column " +
                    (get_isMovil(resolucion) ? "he-card" : "he-50")
                  }
                >
                  <Card className="d-flex he-100">
                    <CardHeader className="card-header-tab">
                      <div className="card-header-title">
                        {"KILOS PROCESADOS"}
                      </div>
                      <div className="ml-auto">
                        <div className="pr-2 font-size-xxl icon_Kg" />
                      </div>
                    </CardHeader>
                    <CardBody className="he-100 align-items-stretch p-0">
                      <div className="he-100 w-100 p-3">
                        <Chart
                          id="Chart"
                          dataSource={
                            this.dataSource_spSelectOperativo_DiasAnteriores
                          }
                          redrawOnResize={true}
                        >
                          <CommonSeriesSettings argumentField="fecha" />
                          <Series
                            name={getTrad("kgProcesados")}
                            valueField="kgProcesado"
                            axis="frequency"
                            type="bar"
                            color="var(--primary)"
                          />
                          <Series
                            name={getTrad("rendimiento")}
                            valueField="kgHorasProd"
                            axis="percentage"
                            type="spline"
                            color="var(--blue)"
                          />
                          <ValueAxis name="frequency" position="left" />
                          <ValueAxis
                            name="percentage"
                            position="right"
                            showZero={true}
                          />
                          <ArgumentAxis
                            breaks={breaks_Chart}
                            breakStyle={this.chart_breakStyle}
                            argumentType="datetime"
                            tickInterval="day"
                          />
                          <Tooltip
                            enabled={true}
                            location="edge"
                            contentRender={this.dxChart_tooltip_contentRender}
                            shared={true}
                          />
                          <Legend visible={false} />
                        </Chart>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg=""
                  xl=""
                  className={
                    "col-xl-20 pt-3 d-flex flex-column justify-content-evenly " +
                    (get_isMovil(resolucion) ? "he-card" : "he-50")
                  }
                >
                  <Card className="d-flex he-100 mb-3 flex-fill">
                    <div
                      className={
                        "widget-chart widget-chart2 text-left card-btm-border card-shadow-primary card flex-fill " +
                        (datos_Actual.kgProcesados !== null &&
                        !$.isEmptyObject(datos_Anteriores)
                          ? datos_Actual.kgProcesados >=
                            datos_Anteriores.procesado.media
                            ? "border-success"
                            : "border-danger"
                          : "")
                      }
                    >
                      <div className="widget-chat-wrapper-outer">
                        <div className="widget-chart-content">
                          <div className="widget-title opacity-5 text-uppercase">
                            HOY
                          </div>
                          <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                            <div className="widget-chart-flex align-items-center justify-content-around">
                              {datos_Actual.kgProcesados !== null ? (
                                <div
                                  className={
                                    "font-size-xxxl " +
                                    (!$.isEmptyObject(datos_Anteriores)
                                      ? datos_Actual.kgProcesados >=
                                        datos_Anteriores.procesado.media
                                        ? "icon_ArrowUp"
                                        : "icon_ArrowDown"
                                      : "")
                                  }
                                />
                              ) : null}
                              <div
                                className={
                                  "font-size-xxxl text-center text-cut pl-2"
                                }
                              >
                                {datos_Actual.kgProcesados !== null
                                  ? formatNumber(
                                      datos_Actual.kgProcesados,
                                      0,
                                      "decimal",
                                    )
                                  : "-"}
                              </div>
                              <small className="opacity-5 pl-1">Kg.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="d-flex he-100 mb-3 flex-fill">
                    <div
                      className={
                        "widget-chart widget-chart2 text-left card-btm-border card-shadow-primary card flex-fill " +
                        (!$.isEmptyObject(datos_Anteriores) &&
                        datos_Anteriores.procesado !== null
                          ? datos_Anteriores.procesado.ayer >=
                            datos_Anteriores.procesado.media
                            ? "border-success"
                            : "border-danger"
                          : "")
                      }
                    >
                      <div className="widget-chat-wrapper-outer">
                        <div className="widget-chart-content">
                          <div className="widget-title opacity-5 text-uppercase">
                            {isAyer ? "AYER" : "ÚLTIMO DÍA"}
                          </div>
                          <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                            <div className="widget-chart-flex align-items-center justify-content-around">
                              {!$.isEmptyObject(datos_Anteriores) &&
                              datos_Anteriores.procesado !== null ? (
                                <div
                                  className={
                                    "font-size-xxxl " +
                                    (!$.isEmptyObject(datos_Anteriores) &&
                                    datos_Anteriores.procesado.ayer >=
                                      datos_Anteriores.procesado.media
                                      ? "icon_ArrowUp"
                                      : "icon_ArrowDown")
                                  }
                                />
                              ) : null}
                              <div
                                className={
                                  "font-size-xxxl text-center text-cut pl-2"
                                }
                              >
                                {!$.isEmptyObject(datos_Anteriores) &&
                                datos_Anteriores.procesado !== null
                                  ? formatNumber(
                                      datos_Anteriores.procesado.ayer,
                                      0,
                                      "decimal",
                                    )
                                  : "-"}
                              </div>
                              <small className="opacity-5 pl-1">Kg.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                  <Card className="d-flex he-100 mb-0 flex-fill">
                    <div
                      className={
                        "widget-chart widget-chart2 text-left card-btm-border card-shadow-primary card flex-fill " +
                        (!$.isEmptyObject(datos_Anteriores) &&
                        datos_Anteriores.procesado !== null
                          ? "border-primary"
                          : "")
                      }
                    >
                      <div className="widget-chat-wrapper-outer">
                        <div className="widget-chart-content">
                          <div className="widget-title opacity-5 text-uppercase">
                            8 DÍAS
                          </div>
                          <div className="widget-numbers mt-2 fsize-4 mb-0 w-100">
                            <div className="widget-chart-flex align-items-center justify-content-around">
                              <div
                                className={"font-size-xxxl icon_CalendarioMes"}
                              />
                              <div
                                className={
                                  "font-size-xxxl text-center text-cut pl-2"
                                }
                              >
                                {!$.isEmptyObject(datos_Anteriores) &&
                                datos_Anteriores.procesado !== null
                                  ? formatNumber(
                                      datos_Anteriores.procesado.media,
                                      0,
                                      "decimal",
                                    )
                                  : "-"}
                              </div>
                              <small className="opacity-5 pl-1">Kg.</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col
                  xs="12"
                  md="12"
                  lg=""
                  xl=""
                  className={
                    "col-xl-40 pt-3 " +
                    (get_isMovil(resolucion) ? "he-card" : "he-50")
                  }
                >
                  <Card className="d-flex he-100 mb-2 card-btm-border border-primary">
                    <CardBody className="align-items-stretch p-0 he-100">
                      <div className="he-100 w-100">
                        <DataGrid
                          id="dxDatagrid_horas"
                          className="font-size-lg headerRow-lg"
                          //Datos
                          remoteOperations={true}
                          dataSource={datos_Actual.horas}
                          keyExpr="hora"
                          //Propiedades
                          columnsAutoWidth={true}
                          height={"100%"}
                          width={"100%"}
                          hoverStateEnabled={true}
                          repaintChangesOnly={true}
                          //Estilos
                          showColumnLines={false}
                        >
                          <SearchPanel visible={false} />
                          <HeaderFilter visible={false} />
                          <FilterRow visible={false} />
                          <Pager visible={false} />
                          <Column
                            dataField="hora"
                            caption={getTrad("hora")}
                            cssClass="p-0"
                            alignment="center"
                            dataType="number"
                            sortOrder="desc"
                            cellRender={this.cellRender_horas}
                            width={90}
                          />
                          <Column
                            caption={getTrad("total")}
                            alignment="center"
                            dataType="number"
                            format={this.number_format}
                            calculateCellValue={
                              this.calculateCellValue_gridHoras_total
                            }
                            cellRender={this.cellRender_total}
                            width={120}
                          />
                          <Column
                            dataField="kgRizo"
                            cssClass="p-0"
                            caption={getTrad("kgRizo")}
                            minWidth={80}
                            alignment="center"
                            format={this.number_format}
                            cellRender={this.cellRender_kgProcesados}
                          />
                          <Column
                            dataField="kgLiso"
                            cssClass="p-0"
                            caption={getTrad("kgLiso")}
                            minWidth={80}
                            alignment="center"
                            format={this.number_format}
                            cellRender={this.cellRender_kgProcesados}
                          />
                          <Column
                            dataField="acumulado"
                            caption={getTrad("acumulado")}
                            alignment="center"
                            dataType="number"
                            format={this.number_format}
                            width={120}
                          />
                        </DataGrid>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                {Object.entries(datos_Anteriores).map(([index, item]) => {
                  if (index !== "procesado") {
                    var isRendimiento = item.titulo == "rendimiento";
                    var borderColor = "";
                    var arrow_media_class = "";
                    var arrow_ayer_class = "";
                    if (item.ayer !== null) {
                      //AYER
                      if (
                        (isRendimiento && item.ayer >= item.kpi) ||
                        (!isRendimiento && item.ayer < item.kpi)
                      ) {
                        borderColor = "border-success";
                        arrow_ayer_class = isRendimiento
                          ? "icon_ArrowUp"
                          : "icon_ArrowDown success";
                      } else {
                        borderColor = "border-danger";
                        arrow_ayer_class = isRendimiento
                          ? "icon_ArrowDown"
                          : "icon_ArrowUp danger";
                      }

                      //MEDIA
                      if (
                        (isRendimiento && item.ayer >= item.media) ||
                        (!isRendimiento && item.ayer < item.media)
                      ) {
                        arrow_media_class = isRendimiento
                          ? "icon_ArrowUp"
                          : "icon_ArrowDown success";
                      } else {
                        arrow_media_class = isRendimiento
                          ? "icon_ArrowDown"
                          : "icon_ArrowUp danger";
                      }
                    }

                    var rangeContainer_color = isRendimiento
                      ? "var(--green)"
                      : "var(--danger)";
                    var range1_color = isRendimiento
                      ? "var(--danger)"
                      : "var(--green)";

                    return (
                      <Col
                        key={index}
                        xs="12"
                        md="6"
                        lg="4"
                        xl=""
                        className={
                          "col-xl-5ths pt-3 " +
                          (get_isMovil(resolucion) ? "he-card" : "he-50")
                        }
                      >
                        <Card
                          className={
                            "mb-0 card-btm-border he-100 " + borderColor
                          }
                        >
                          <CardHeader className="card-header-tab">
                            <div className="card-header-title">
                              {item.titulo.toUpperCase()}
                            </div>
                            <div className="ml-auto">
                              <div
                                className={"pr-2 font-size-xxl " + item.icono}
                              />
                            </div>
                          </CardHeader>
                          <CardBody className="he-100 w-100 pl-2 pr-2 pt-1 pb-0">
                            {item.media !== null && item.kpi > 0 ? (
                              <Fragment>
                                <Box
                                  direction="col"
                                  width="100%"
                                  height={"100%"}
                                >
                                  <Item ratio={3}>
                                    <Row className="card-row-header pr-2 pl-2 pt-1">
                                      <Col className="flex-column">
                                        <div
                                          className="widget-title opacity-5 text-uppercase"
                                          style={{ height: "15px" }}
                                        >
                                          8 DÍAS
                                        </div>
                                        <div className="font-size-xxl font-weight-bold">
                                          {formatNumber(
                                            item.media,
                                            2,
                                            "decimal",
                                          )}
                                        </div>
                                      </Col>
                                      <Col className="flex-column">
                                        <div
                                          className="widget-title opacity-5 text-uppercase text-right"
                                          style={{ height: "15px" }}
                                        >
                                          KPI
                                        </div>
                                        <div className="font-size-xxl opacity-5 font-weight-bold text-right">
                                          {formatNumber(item.kpi, 2, "decimal")}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Item>
                                  <Item ratio={8}>
                                    <CircularGauge
                                      elementAttr={{
                                        class: "CircularGauge pr-1 pl-1",
                                      }}
                                      redrawOnResize={true}
                                      value={item.media}
                                    >
                                      <Margin bottom={15} />
                                      <Scale
                                        startValue={item.kpi * 0.25}
                                        endValue={item.kpi * 1.25}
                                        tickInterval={
                                          (((item.kpi * 1.25) / 8) * 100) / 100
                                        }
                                      >
                                        <Label
                                          customizeText={this.customizeText}
                                        />
                                        <Tick length={6} />
                                      </Scale>
                                      <Geometry
                                        startAngle={180}
                                        endAngle={0}
                                      ></Geometry>
                                      <RangeContainer
                                        width={25}
                                        offset={24}
                                        backgroundColor={rangeContainer_color}
                                      >
                                        <Range
                                          color={range1_color}
                                          startValue={item.kpi * 0.25}
                                          endValue={item.kpi * 0.9}
                                        />
                                        <Range
                                          color="#d8ea4b"
                                          startValue={item.kpi * 0.9}
                                          endValue={item.kpi}
                                        />
                                      </RangeContainer>
                                      <ValueIndicator
                                        type="triangleNeedle"
                                        color="#575757"
                                        width={8}
                                        offset={15}
                                      ></ValueIndicator>
                                    </CircularGauge>
                                  </Item>
                                  <Item ratio={4}>
                                    <Row className="p-2 card-row-footer he-100 divider-top">
                                      <Col
                                        xs="2"
                                        className="container_spanCentrado pl-3 pt-3"
                                      >
                                        {item.ayer !== null ? (
                                          <div
                                            className={
                                              "font-size-max " +
                                              arrow_media_class
                                            }
                                          />
                                        ) : null}
                                      </Col>
                                      <Col
                                        xs="8"
                                        className="container_spanCentrado flex-column"
                                      >
                                        <div
                                          className="widget-title opacity-5 text-uppercase"
                                          style={{ height: "10px" }}
                                        >
                                          {isAyer ? "AYER" : "ÚLTIMO DÍA"}
                                        </div>
                                        <div
                                          className={
                                            "font-size-max text-center text-cut pl-3 pr-3 font-weight-bold"
                                          }
                                        >
                                          {item.ayer !== null
                                            ? formatNumber(
                                                item.ayer,
                                                2,
                                                "decimal",
                                              )
                                            : "-"}
                                        </div>
                                      </Col>
                                      <Col
                                        xs="2"
                                        className="container_spanCentrado pr-3 pt-3"
                                      >
                                        {item.ayer !== null ? (
                                          <div
                                            className={
                                              "font-size-max " +
                                              arrow_ayer_class
                                            }
                                          />
                                        ) : null}
                                      </Col>
                                    </Row>
                                  </Item>
                                </Box>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className="container_spanCentrado">
                                  <div className="font-size-xxl text-center font-weight-bold">
                                    {item.media == null
                                      ? getTrad("sinDatos").toUpperCase()
                                      : item.kpi <= 0
                                        ? getTrad("sinKPI").toUpperCase()
                                        : ""}
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  }
                })}
              </Row>
            </ScrollView>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //FORMATS
  number_format = { style: "decimal", maximumFractionDigits: 0 };

  //GAUGES
  customizeText({ value }) {
    return formatNumber(value, 2, "decimal");
  }

  //CHART
  chart_breakStyle = { color: "lightgray" };

  dxChart_tooltip_contentRender(arg) {
    return (
      <div>
        <div className="tooltip_header">
          <div className="tooltip_title"> {formatDate(arg.argument)}</div>
        </div>
        <div className="tooltip_body">
          {arg.points.map((point, i) => (
            <div key={point.seriesName} className="tooltip_row">
              <div className="tooltip_series-name">
                {point.seriesName.toUpperCase()}
              </div>
              <div className="tooltip_value-text">
                {formatNumber(point.value, 2, "decimal")}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  //DATAGRID HORAS
  cellRender_horas = (itemData) => {
    return <div className="font-horas">{itemData.value + " h."}</div>;
  };

  cellRender_kgProcesados = (itemData) => {
    let { datos_Actual } = this.state;
    let value = itemData.value;
    let text = itemData.text;
    let maxValue = Math.max.apply(
      Math,
      datos_Actual.horas.map(function (o) {
        return o[itemData.column.dataField];
      }),
    );
    let porcentaje = value / maxValue;

    //Referenciado al máximo
    let color =
      porcentaje > 0.9
        ? "success"
        : porcentaje > 0.8
          ? "yellow"
          : porcentaje > 0.7
            ? "orange"
            : porcentaje > 0
              ? "danger"
              : "";

    return (
      <div className="p-2">
        <Progress
          className="progress-bar-xl progress-bar-rounded"
          color={color}
          barClassName="he-100"
          value={value}
          max={maxValue}
        >
          <div className="progress-bar-info p-2 text-white font-weight-bold">
            {text}
          </div>
        </Progress>
      </div>
    );
  };

  cellRender_total = (itemData) => {
    let value = itemData.value;
    return (
      <div className="font-weight-bold">
        {formatNumber(value, 0, "decimal")}
      </div>
    );
  };

  calculateCellValue_gridHoras_total(rowData) {
    return rowData.kgRizo + rowData.kgLiso;
  }
  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    this.datasource_datos_hoy.load();

    this.interval_Actual = setInterval(() => {
      this.datasource_datos_hoy.reload();
    }, tiempoRefresco_Actual);

    this.interval_Anteriores = setInterval(() => {
      this.dataSource_spSelectOperativo_DiasAnteriores.load();
    }, tiempoRefresco_Anterior);
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, resolucion } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.datasource_datos_hoy.load();
      this.dataSource_spSelectOperativo_DiasAnteriores.load();
    }

    setTimeout(() => {
      //Si se quita, gráfica en móvil se ve mal, eje x sobresale de la carta.
      $("#Chart").dxChart("instance").render();
    }, 0);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval_Actual);
    clearInterval(this.interval_Anteriores);
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  enableFullScreen: state.ThemeOptions.enableFullScreen,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardOperativo);
