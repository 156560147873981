import { loadPanelConstants } from "../constants";

const initialState = { show: false, shading: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case loadPanelConstants.SHOW_LOADPANEL_SUCCESS:
      return {
        ...state,
        show: true,
        shading: action.shading,
      };
    case loadPanelConstants.HIDE_LOADPANEL_SUCCESS:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}
