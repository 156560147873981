import React, { useEffect, useState } from "react";

import DataGrid, { Column, Grouping, Paging } from "devextreme-react/data-grid";
import DataSource from "devextreme/data/data_source";
import LoadPanel from "devextreme-react/load-panel";
import ODataStore from "devextreme/data/odata/store";
import Popup from "devextreme-react/popup";

import { connectionConstants } from "constants";
import {
  authHeader,
  errorHandler,
  formatDateTime_parameter,
  formatDate_parameter,
  getTrad,
} from "helpers";

const elementAttr = { id: "dxDataGrid_PopupHistorico" };

const position = { my: "center", at: "center", of: `#${elementAttr.id}` };

const PopupHistorico = ({
  visible,
  onHiding,
  idsRecambioHistorico = [],
  idAlmacen,
  fecha = null,
  fechaCierre = null,
  recambioSel,
  codigoMoneda,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (visible && idsRecambioHistorico.length > 0) {
      setDataSource([]);
      setIsLoading(true);
      datasource_hitoricoRecambio
        .reload()
        .done(setDataSource)
        .always(() => setIsLoading(false));
    }
  }, [idsRecambioHistorico]);

  useEffect(() => {
    if (visible) {
      setDataSource([]);
    }
  }, [visible]);

  const datasource_hitoricoRecambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/HistoricoRecambio",
      errorHandler,
      beforeSend: (request) => {
        request.headers = { ...authHeader() };

        request.method = "POST";

        if (fechaCierre)
          request.params.fechaCierre = formatDate_parameter(fechaCierre);
        request.params.idAlmacen = idAlmacen;
        request.params.todos = false;
        request.payload = idsRecambioHistorico;

        if (fecha) {
          const fechaMod = new Date(fecha.setHours(23, 59, 59));
          request.params.fecha = formatDateTime_parameter(
            new Date(new Date(fechaMod).setHours(fechaMod.getUTCHours()))
          );
        }
      },
      version: 4,
    }),
    postProcess: (data) => {
      let balances = {};

      data = data.map((m) => {
        let clave = `${m.idAlmacen}-${m.idRecambio}`;
        let cambio =
          m.idTipoMovimientoRecambio === 3 ? -m.cantidad : m.cantidad;

        if (m.idTipoMovimientoRecambioOriginal === 4) {
          balances[clave] = cambio;
        } else if (balances[clave]) {
          balances[clave] += cambio;
        } else {
          balances[clave] = cambio;
        }

        return { ...m, balance: balances[clave] };
      });

      return data;
    },
  });

  const onRowPrepared = ({
    component,
    data,
    isExpanded,
    key,
    rowElement,
    rowType,
  }) => {
    if (rowType === "group") {
      const denoAlmacen = dataSource.find(
        (ds) => ds.idAlmacen === idAlmacen
      )?.denoAlmacen;

      if (isExpanded && key[0] !== denoAlmacen) {
        component.collapseRow(key);
      } else if (key[0] === denoAlmacen) {
        component.expandRow(key);
      }
    }

    if (rowType === "data") {
      if ([4, 7].includes(data.idTipoMovimientoRecambioOriginal)) {
        rowElement.css("background", "#FFEB9C");
      }
      if (data.balance < 0) {
        rowElement.css("background", "var(--danger)");
        rowElement.css("color", "white");
      }
    }
  };

  const groupCellRender_denoAlmacen = (data) => <div>{data.value}</div>;

  const calculateCellValue = (e, field) =>
    e[field] && e[field].length > 0 ? e[field] : "-";

  const currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: codigoMoneda,
  };

  const title = `${getTrad("historico")}${
    recambioSel?.referenciaInterna ? " - " + recambioSel?.referenciaInterna : ""
  } ${recambioSel?.denominacion ? " - " + recambioSel?.denominacion : ""}`;

  return (
    <Popup visible={visible} onHiding={onHiding} title={title}>
      <LoadPanel visible={isLoading} position={position} />
      <DataGrid
        elementAttr={elementAttr}
        dataSource={dataSource}
        height={"100%"}
        width={"100%"}
        columnsAutoWidth
        hoverStateEnabled
        rowAlternationEnabled
        showRowLines
        showColumnLines={false}
        remoteOperations={false}
        onRowPrepared={onRowPrepared}
      >
        <Grouping autoExpandAll={false} />
        <Paging enabled={false} />
        <Column
          dataField={"denoAlmacen"}
          caption={getTrad("almacen")}
          groupIndex={0}
          groupCellRender={groupCellRender_denoAlmacen}
        />
        <Column
          dataField={"ID"}
          alignment={"center"}
          width={100}
          calculateCellValue={(e) => calculateCellValue(e, "ID")}
        />
        <Column
          dataField={"fecha"}
          caption={getTrad("fecha")}
          alignment={"center"}
          dataType={"datetime"}
          format={"dd/MM/yyyy HH:mm"}
          sortOrder={"asc"}
          width={150}
        />
        <Column
          dataField={"tipoMovimiento"}
          caption={getTrad("tipoMovimiento")}
          alignment={"center"}
        />
        <Column
          dataField={"origen"}
          caption={getTrad("origen")}
          calculateCellValue={(e) => calculateCellValue(e, "origen")}
        />
        <Column
          dataField={"destino"}
          caption={getTrad("destino")}
          calculateCellValue={(e) => calculateCellValue(e, "destino")}
        />
        <Column
          dataField={"denoLavanderia"}
          caption={getTrad("lavanderia")}
          alignment={"center"}
          width={150}
        />
        <Column
          dataField={"cantidad"}
          caption={getTrad("cantidad")}
          dataType={"number"}
          alignment={"center"}
          width={100}
        />
        <Column
          dataField={"balance"}
          caption={getTrad("balance")}
          dataType={"number"}
          alignment={"center"}
          width={100}
        />
        <Column
          dataField={"precio"}
          caption={getTrad("precio")}
          dataType={"number"}
          format={currency_format}
          alignment={"center"}
          width={100}
        />
        <Column
          dataField={"importe"}
          caption={getTrad("importe")}
          dataType={"number"}
          format={currency_format}
          alignment={"center"}
          width={100}
        />
      </DataGrid>
    </Popup>
  );
};

export default PopupHistorico;
