import { Button, List } from "devextreme-react";
import MultiView, { Item as MultiViewItem } from "devextreme-react/multi-view";
import { errorHandler, authHeader } from "helpers";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";

const GestionCarpetas = forwardRef(({ documentos, setCarpetas, carpetaDocumentos }, ref) => {
  const dxList_carpetas_REF = useRef();
  const dxList_subCarpetas_REF = useRef();
  const [multiViewCarpetasIndex, setMultiViewCarpetasIndex] = useState(0);
  const [subcarpetas, setSubcarpetas] = useState([]);

  useImperativeHandle(ref, () => ({
    dxList_carpetas_REF,
    dxList_subCarpetas_REF,
    setMultiViewCarpetasIndex,
    unselectAll
  }));

  useEffect(() => {
    if(carpetaDocumentos !== null && carpetaDocumentos !== undefined && carpetaDocumentos.idCarpetaDocumentos !== null){
      loadSubcarpetas(carpetaDocumentos);
    }
  }, [carpetaDocumentos]);

  const selectSubCarpeta = (e) => {
    const {subCarpetaObjetivo} = carpetaDocumentos;
    if(subCarpetaObjetivo !== null && subCarpetaObjetivo !== undefined){
      let index = subcarpetas.findIndex(sc => sc.subCarpeta === subCarpetaObjetivo);
      e.component.selectItem(index);
      const carpeta = {...carpetaDocumentos};
      delete carpeta.subCarpetaObjetivo;
      setCarpetas(carpeta);
    }
  }

  const datasource_tblCarpetaDocumentos = useMemo(() => {
    return new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL + "tblCarpetaDocumentos",
        key: "idCarpetaDocumentos",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          datasource_tblCarpetaDocumentos_beforeSend(request);
        },
        version: 4,
      }),
      sort: ["denominacion"],
    });
  }, []);
  // Esta funcion no es accesible desde el datasource_tblCarpetaDocumentos salvo cuando se monta el componente
  // debido a que GestionCarpetas es un function component.
  // Si esta funcion necesita cambiar durante la ejecución, hay que pasar el componente a clase.
  const datasource_tblCarpetaDocumentos_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.todos = true;
  };

  const listSubCarpetas_onSelectionChanged = ({ addedItems }) => {
    setCarpetas((prevState) => {
      return { ...prevState, subCarpeta: addedItems[0]?.subCarpeta };
    });
  };

  const listCarpetas_onSelectionChanged = ({ addedItems }) => {
    setCarpetas(addedItems[0]);
  };

  const hasSubfolders = (subcarpetasNCarpeta) => {
    return subcarpetasNCarpeta.some((subcarpeta) => {
      return subcarpeta.subCarpeta !== "null" && subcarpeta.subCarpeta !== null;
    })
  }

  const loadSubcarpetas = (carpeta) => {
    let subcarpetasNCarpeta = [];
    const documentosInCarpeta = documentos.current.filter(
      (documento) => documento.idPadre === carpeta.idCarpetaDocumentos
    );
    Object.entries(
      Object.groupBy(documentosInCarpeta, ({ subCarpeta }) => subCarpeta)
    )
      .reverse()
      .forEach(([carpeta, documentos], index) => {
        subcarpetasNCarpeta.push({
          idSubCarpeta: index, // no deberia hacer falta pero lo pide el componente
          denominacion:
            carpeta == "null" || carpeta == "undefined"
              ? "Sin agrupación"
              : carpeta, // no deberia hacer falta pero lo pide el componente
          subCarpeta: carpeta,
        });
        if (carpeta == "null" || carpeta == "undefined") {
          documentos.map((x) => {
            x.subCarpeta = carpeta;
            return x;
          });
        }
      });
    if (hasSubfolders(subcarpetasNCarpeta)) {
      if(subcarpetasNCarpeta.length > 0 && !subcarpetasNCarpeta.some(sc => sc.subCarpeta === "null")){
        subcarpetasNCarpeta.push({
          idSubCarpeta: 1, // no deberia hacer falta pero lo pide el componente
          denominacion:"Sin agrupación", // no deberia hacer falta pero lo pide el componente
          subCarpeta: "null",
        });
      }
      setSubcarpetas(subcarpetasNCarpeta);
      setMultiViewCarpetasIndex(1);
    } else {
      setSubcarpetas(subcarpetasNCarpeta);
      setMultiViewCarpetasIndex(0);
    }
  };

  const listTipoDocumento_itemRender = (item) => {
    return (
      <div className="d-flex flex-row listTipoDocumento_item align-items-center">
        <div className="d-flex flex-60 itemIcon">
          {" "}
          <div className="font-size-xxxl mx-auto dx-icon-folder" />
        </div>
        <div className="d-flex flex-10px" />
        <div className="d-flex flex-1 folderTitle_documentos">
          <div className="d-flex font-weight-bold font-size-sm align-items-center">
            {item.denominacion}
          </div>
          {/* <div className="d-flex text-secondary font-size-xxxs align-items-center">
            {item.tblDocumento?.length + " ficheros"}
          </div> */}
        </div>
      </div>
    );
  };

  const unselectAll = () => {
    dxList_subCarpetas_REF.current.instance.unselectAll(); 
    if(multiViewCarpetasIndex === 0) {
      dxList_carpetas_REF.current.instance.unselectAll();
    }
  }

  return (
    <>      
      <MultiView
        selectedIndex={multiViewCarpetasIndex}
        swipeEnabled={false}
        animationEnabled={true}
        id="dxMultiView_Carpetas"
        deferRendering={false}
      >
        <MultiViewItem>
          <List
            ref={dxList_carpetas_REF}
            dataSource={datasource_tblCarpetaDocumentos}
            height={"100%"}
            selectionMode="single"
            displayExpr="denominacion"
            keyExpr="idCarpetaDocumentos"
            noDataText=""
            onSelectionChanged={listCarpetas_onSelectionChanged}
            itemRender={listTipoDocumento_itemRender}
          />
        </MultiViewItem>
        <MultiViewItem>
          <List
            ref={dxList_subCarpetas_REF}
            dataSource={subcarpetas}
            height={"100%"}
            selectionMode="single"
            displayExpr="subCarpeta"
            keyExpr="subCarpeta"
            noDataText=""
            onSelectionChanged={listSubCarpetas_onSelectionChanged}
            itemRender={listTipoDocumento_itemRender}
            onContentReady={selectSubCarpeta}
          />
        </MultiViewItem>
      </MultiView>
    </>
  );
});

export default GestionCarpetas;
