export const buscarEncuesta = {
  v: "5.9.4",
  fr: 15.9700012207031,
  ip: 0,
  op: 120.0000048877,
  w: 512,
  h: 512,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Layer 8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [344.363, 419.108, 0], ix: 2, l: 2 },
        a: { a: 0, k: [88.363, 163.108, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 50,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 60,
              s: [115, 115, 100],
            },
            { t: 70.0000028511585, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                  ],
                  v: [
                    [114.238, 184.481],
                    [62.488, 184.481],
                    [59.488, 181.481],
                    [59.488, 144.736],
                    [62.488, 141.736],
                    [114.238, 141.736],
                    [117.238, 144.736],
                    [117.238, 181.481],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.380392163992, 0.780392169952, 0.349019616842, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Layer 7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [270.986, 419.108, 0], ix: 2, l: 2 },
        a: { a: 0, k: [14.986, 163.108, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 30,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 40,
              s: [115, 115, 100],
            },
            { t: 50.0000020365418, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                  ],
                  v: [
                    [40.861, 184.481],
                    [-10.889, 184.481],
                    [-13.889, 181.481],
                    [-13.889, 144.736],
                    [-10.889, 141.736],
                    [40.861, 141.736],
                    [43.861, 144.736],
                    [43.861, 181.481],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.980392158031, 0.690196096897, 0.003921568859, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [196.506, 419.108, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-59.494, 163.108, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 10,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 20,
              s: [115, 115, 100],
            },
            { t: 30.0000012219251, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                  ],
                  v: [
                    [-33.619, 184.481],
                    [-85.369, 184.481],
                    [-88.369, 181.481],
                    [-88.369, 144.736],
                    [-85.369, 141.736],
                    [-33.619, 141.736],
                    [-30.619, 144.736],
                    [-30.619, 181.481],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.89411765337, 0.254901975393, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 0,
              s: [-20],
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 20,
              s: [14],
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 40,
              s: [-20],
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 60,
              s: [14],
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 80,
              s: [-20],
            },
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 100,
              s: [14],
            },
            { t: 119.000004846969, s: [-20] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [30.785, 443.442, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-225.215, 187.442, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.156, 0.782],
                    [-0.782, 1.156],
                    [25.463, 17.234],
                    [-0.782, 1.156],
                    [-1.156, -0.782],
                    [18.797, -27.773],
                  ],
                  o: [
                    [-1.156, -0.782],
                    [17.234, -25.463],
                    [-1.156, -0.782],
                    [0.782, -1.156],
                    [27.771, 18.796],
                    [-0.782, 1.156],
                  ],
                  v: [
                    [-56.291, 53.947],
                    [-56.968, 50.435],
                    [-71.891, -26.998],
                    [-72.567, -30.509],
                    [-69.056, -31.186],
                    [-52.78, 53.27],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.003921568859, 0.72549021244, 0.937254905701, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [21.195, -31.315],
                    [31.315, 21.195],
                    [-21.195, 31.315],
                    [-31.315, -21.195],
                  ],
                  o: [
                    [-21.195, 31.315],
                    [-31.315, -21.195],
                    [21.195, -31.315],
                    [31.315, 21.195],
                  ],
                  v: [
                    [-46.445, 57.558],
                    [-141.524, 75.882],
                    [-159.848, -19.196],
                    [-64.769, -37.52],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.486274510622, 0.811764717102, 0.960784316063, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [24.933, -36.838],
                    [36.838, 24.933],
                    [-24.933, 36.838],
                    [-36.838, -24.933],
                  ],
                  o: [
                    [-24.933, 36.838],
                    [-36.838, -24.933],
                    [24.933, -36.838],
                    [36.838, 24.933],
                  ],
                  v: [
                    [-36.446, 64.326],
                    [-148.291, 85.882],
                    [-169.847, -25.964],
                    [-58.002, -47.52],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.101960785687, 0.180392161012, 0.207843139768, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-149.233, 74.859],
                    [-158.874, 89.104],
                    [-147.349, 96.904],
                    [-137.708, 82.66],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.219607844949, 0.352941185236, 0.388235300779, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.372, -4.982],
                    [4.982, 3.372],
                    [-3.372, 4.982],
                    [-4.982, -3.372],
                  ],
                  o: [
                    [-3.372, 4.982],
                    [-4.982, -3.372],
                    [3.372, -4.982],
                    [4.982, 3.372],
                  ],
                  v: [
                    [-192.234, 170.239],
                    [-207.36, 173.154],
                    [-210.275, 158.028],
                    [-195.149, 155.113],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.945098042488, 0.952941179276, 0.956862747669, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [11.354, 7.685],
                    [0, 0],
                    [-8.86, 10.463],
                    [0, 0],
                    [-2.139, -1.448],
                    [0, 0],
                    [1.21, -2.282],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-11.354, -7.685],
                    [0, 0],
                    [1.669, -1.971],
                    [0, 0],
                    [2.139, 1.448],
                    [0, 0],
                    [-6.421, 12.114],
                  ],
                  v: [
                    [-214.338, 183.464],
                    [-214.338, 183.464],
                    [-219.009, 149.478],
                    [-167.537, 88.692],
                    [-160.842, 87.772],
                    [-145.381, 98.236],
                    [-143.748, 104.793],
                    [-181.05, 175.169],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.337254911661, 0.615686297417, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 6",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Layer 5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [171.937, 325.11, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-84.063, 69.11, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.623, 0],
                    [-0.476, -0.476],
                    [0.951, -0.951],
                    [0, 0],
                    [0.645, 0],
                    [0, 0],
                    [0, 1.344],
                    [-1.344, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.623, 0],
                    [0.951, 0.951],
                    [0, 0],
                    [-0.456, 0.456],
                    [0, 0],
                    [-1.344, 0],
                    [0, -1.344],
                    [0, 0],
                    [0, 0],
                    [0.476, -0.476],
                  ],
                  v: [
                    [-56.423, 58.746],
                    [-54.701, 59.459],
                    [-54.701, 62.902],
                    [-70.56, 78.761],
                    [-72.282, 79.474],
                    [-111.703, 79.474],
                    [-114.137, 77.039],
                    [-111.703, 74.605],
                    [-73.29, 74.605],
                    [-58.144, 59.459],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.101960785687, 0.180392161012, 0.207843139768, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10.0000004073083,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Layer 4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [330.522, 269.82, 0], ix: 2, l: 2 },
        a: { a: 0, k: [74.522, 13.82, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.623, 0],
                    [0.476, 0.476],
                    [-0.951, 0.951],
                    [0, 0],
                    [-0.645, 0],
                    [0, 0],
                    [0, -1.344],
                    [1.344, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.623, 0],
                    [-0.951, -0.951],
                    [0, 0],
                    [0.456, -0.456],
                    [0, 0],
                    [1.344, 0],
                    [0, 1.344],
                    [0, 0],
                    [0, 0],
                    [-0.476, 0.476],
                  ],
                  v: [
                    [53.252, -41.386],
                    [51.531, -42.099],
                    [51.531, -45.542],
                    [67.39, -61.401],
                    [69.111, -62.114],
                    [108.532, -62.114],
                    [110.967, -59.68],
                    [108.532, -57.245],
                    [70.119, -57.245],
                    [54.974, -42.099],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.101960785687, 0.180392161012, 0.207843139768, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.623, 0],
                    [0.476, -0.476],
                    [-0.951, -0.951],
                    [0, 0],
                    [-0.645, 0],
                    [0, 0],
                    [0, 1.344],
                    [1.344, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.623, 0],
                    [-0.951, 0.951],
                    [0, 0],
                    [0.456, 0.456],
                    [0, 0],
                    [1.344, 0],
                    [0, -1.344],
                    [0, 0],
                    [0, 0],
                    [-0.476, -0.476],
                  ],
                  v: [
                    [20.089, 84.884],
                    [18.368, 85.598],
                    [18.368, 89.04],
                    [44.87, 115.543],
                    [46.592, 116.256],
                    [128.954, 116.256],
                    [131.389, 113.822],
                    [128.954, 111.387],
                    [47.6, 111.387],
                    [21.81, 85.598],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.101960785687, 0.180392161012, 0.207843139768, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.623, 0],
                    [0.476, 0.476],
                    [-0.951, 0.951],
                    [0, 0],
                    [-0.645, 0],
                    [0, 0],
                    [0, -1.344],
                    [1.344, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.623, 0],
                    [-0.951, -0.951],
                    [0, 0],
                    [0.456, -0.456],
                    [0, 0],
                    [1.344, 0],
                    [0, 1.344],
                    [0, 0],
                    [0, 0],
                    [-0.476, 0.476],
                  ],
                  v: [
                    [20.089, -57.245],
                    [18.368, -57.958],
                    [18.368, -61.401],
                    [44.87, -87.904],
                    [46.592, -88.617],
                    [128.954, -88.617],
                    [131.389, -86.182],
                    [128.954, -83.747],
                    [47.6, -83.747],
                    [21.81, -57.958],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.101960785687, 0.180392161012, 0.207843139768, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 10.0000004073083,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Layer 3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.25], y: [1] },
              o: { x: [0.33], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 10.0000004073083, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [264.838, 275.005, 0], ix: 2, l: 2 },
        a: { a: 0, k: [8.838, 19.005, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.25, 0.25, 0.25], y: [1, 1, 1] },
              o: { x: [0.33, 0.33, 0.33], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            { t: 10.0000004073083, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.581, -29.111],
                    [0, 0],
                    [0, 0],
                    [-24.531, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [16.079, -16.079],
                    [34.774, 0],
                  ],
                  v: [
                    [88.311, -20.736],
                    [8.829, 18.996],
                    [-53.988, -43.821],
                    [8.829, -69.833],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.89411765337, 0.254901975393, 0, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -14.289],
                    [6.008, -11.947],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 14.289],
                    [0, 0],
                    [0, 0],
                    [5.991, 11.947],
                  ],
                  v: [
                    [97.676, 18.996],
                    [88.294, 58.746],
                    [8.829, 18.996],
                    [88.311, -20.736],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.219607844949, 0.352941185236, 0.388235300779, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [34.774, 0],
                    [0, 49.063],
                    [-16.079, 16.079],
                    [0, 0],
                  ],
                  o: [
                    [-14.581, 29.11],
                    [-49.063, 0],
                    [0, -24.531],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [88.294, 58.746],
                    [8.829, 107.843],
                    [-80, 18.996],
                    [-53.988, -43.821],
                    [8.829, 18.996],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 0.337254911661, 0.615686297417, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Layer 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [298.35, 255.882, 0], ix: 2, l: 2 },
        a: { a: 0, k: [42.35, -0.118, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.88, -0.88],
                    [0, -1.336],
                    [2.704, 0],
                    [0, 0],
                    [-0.142, 2.799],
                    [-2.626, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.88, 0.88],
                    [0, 2.689],
                    [0, 0],
                    [-2.783, 0],
                    [0.142, -2.641],
                    [0, 0],
                    [1.352, 0],
                  ],
                  v: [
                    [-80.523, -122.165],
                    [-79.108, -118.722],
                    [-83.982, -113.863],
                    [-116.371, -113.863],
                    [-121.245, -118.989],
                    [-116.104, -123.596],
                    [-83.982, -123.596],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.142, -2.641],
                    [2.783, 0],
                    [0, 0],
                    [0.88, 0.88],
                    [0, 1.336],
                    [-2.689, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.142, 2.799],
                    [0, 0],
                    [-1.352, 0],
                    [-0.88, -0.88],
                    [0, -2.689],
                    [0, 0],
                    [2.641, 0],
                  ],
                  v: [
                    [138.794, -118.989],
                    [133.92, -113.863],
                    [-55.367, -113.863],
                    [-58.81, -115.278],
                    [-60.241, -118.722],
                    [-55.367, -123.596],
                    [133.653, -123.596],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                  ],
                  v: [
                    [-8.356, -96.578],
                    [-116.376, -96.578],
                    [-121.245, -91.709],
                    [-121.245, -91.709],
                    [-116.376, -86.84],
                    [-8.356, -86.84],
                    [-3.487, -91.709],
                    [-3.487, -91.709],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                  ],
                  v: [
                    [-34.866, -150.619],
                    [-116.376, -150.619],
                    [-121.245, -145.75],
                    [-121.245, -145.75],
                    [-116.376, -140.881],
                    [-34.866, -140.881],
                    [-29.997, -145.75],
                    [-29.997, -145.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.689, 0],
                    [0, 0],
                    [0, 2.689],
                    [0, 0],
                    [2.689, 0],
                    [0, 0],
                    [0, -2.689],
                  ],
                  v: [
                    [133.927, -150.619],
                    [-1.845, -150.619],
                    [-6.714, -145.75],
                    [-6.714, -145.75],
                    [-1.845, -140.881],
                    [133.927, -140.881],
                    [138.796, -145.75],
                    [138.796, -145.75],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.532, 0],
                    [0, 0],
                    [0, 1.532],
                    [0, 0],
                    [-1.532, 0],
                    [0, 0],
                    [0, -1.532],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.532, 0],
                    [0, 0],
                    [0, -1.532],
                    [0, 0],
                    [1.532, 0],
                    [0, 0],
                    [0, 1.532],
                  ],
                  v: [
                    [136.023, -167.902],
                    [-118.471, -167.902],
                    [-121.245, -170.675],
                    [-121.245, -174.866],
                    [-118.471, -177.64],
                    [136.023, -177.64],
                    [138.796, -174.866],
                    [138.796, -170.675],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.850980401039, 0.870588243008, 0.886274516582, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 6",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [9.254, 0],
                    [0, 0],
                    [0, -9.254],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -9.254],
                    [0, 0],
                    [-9.254, 0],
                    [0, 0],
                  ],
                  v: [
                    [168.69, -95.224],
                    [227.516, -95.224],
                    [227.516, -191.142],
                    [210.759, -207.899],
                    [185.446, -207.899],
                    [168.69, -191.142],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.835294127464, 0.866666674614, 0.874509811401, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 7",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [8.964, 0],
                    [0, 0],
                    [0, 8.964],
                    [0, 0],
                    [-16.244, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 8.964],
                    [0, 0],
                    [-8.964, 0],
                    [0, 0],
                    [0, -16.244],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [168.69, -178.486],
                    [168.69, 191.432],
                    [152.459, 207.663],
                    [-126.584, 207.663],
                    [-142.816, 191.432],
                    [-142.816, -178.486],
                    [-113.402, -207.899],
                    [198.103, -207.899],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.945098042488, 0.952941179276, 0.956862747669, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 8",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 120.0000048877,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
