import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import $ from "jquery";
import {
    get_isMovil,
    formatDate,
    getTrad,
    authHeader,
    errorHandler,
    patchRequestHandler,
    dxMensajePregunta,
} from "../../../helpers";
import { connectionConstants } from "../../../constants";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

import { Button } from "devextreme-react";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import ScrollView from "devextreme-react/scroll-view";

import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Calendar from "devextreme-react/calendar";
import { Popover } from "devextreme-react/popover";
import notify from "devextreme/ui/notify";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import { Validator } from "devextreme-react/validator";
import { Form, Item, RequiredRule } from "devextreme-react/form";
import { TextBox, DateBox, Lookup } from "devextreme-react";
import Box, { Item as ItemBox } from "devextreme-react/box";

//Custom components
import ActionToast from "../../../components/ActionToast/ActionToast";
import LottieIcon from "../../../components/LottieIcon";

//Css
import "./Css.scss";

//SignalRCore
import PopupEnvioDocumentacion from "./components/EnvioDocumentacion/PopupEnvioDocumentacion";

import GestorPlus, { tipoConcepto } from "./components/GestorPlus/GestorPlus";
import { signalRCoreService } from "services";

class Notificaciones extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fechaSel: null,
            fechaHover: null,
            dxPopover_fechaHover_isVisible: false,
            dxCalendar_eventosLavanderia_dataSource: [],
            notificaciones: [],
            idTipoNotificacionSel: null,
            datasource_tblPersona_PeticionCambioDatos: null,
            dataSources_aux: null,
            hubConnection: null,
            isVisible_dxPopup_comunicado: false,
            isComunicado_editing: false,
            formData_comunicado: this.formData_comunicado_resetValues,
            tblTipoNotificacion_items: [],
            searchBox_notificaciones: null,
            notificacionSel_imagenes: null,
        };

        //REFERENCIAS
        this.dxForm_comunicado_REF = React.createRef();
        this.dxTextBox_titulo_comunicado_REF = React.createRef();
        this.dxTextBox_searchBoxNotificaciones_REF = React.createRef();

        //EVENTOS
        this.dxCalendar_eventosLavanderia_cellRender = this.dxCalendar_eventosLavanderia_cellRender.bind(this);
        this.dxCalendar_eventosLavanderia_onInitialized = this.dxCalendar_eventosLavanderia_onInitialized.bind(this);
        this.dxCalendar_eventosLavanderia_onOptionChanged =
            this.dxCalendar_eventosLavanderia_onOptionChanged.bind(this);
        this.dxCalendar_eventosLavanderia_onValueChanged = this.dxCalendar_eventosLavanderia_onValueChanged.bind(this);
        this.onClick_Button_comunicado = this.onClick_Button_comunicado.bind(this);
        this.onClick_Button_editComunicado = this.onClick_Button_editComunicado.bind(this);
        this.onClick_Button_removeComunicado = this.onClick_Button_removeComunicado.bind(this);
        this.onShown_popup_comunicado = this.onShown_popup_comunicado.bind(this);
        this.onHiding_popup_comunicado = this.onHiding_popup_comunicado.bind(this);
        this.dxButton_comunicado_guardar_onClick = this.dxButton_comunicado_guardar_onClick.bind(this);
        this.dxButton_comunicado_cancelar_onClick = this.dxButton_comunicado_cancelar_onClick.bind(this);
        this.dxTextBox_comunicado_titulo_onValueChanged = this.dxTextBox_comunicado_titulo_onValueChanged.bind(this);
        this.dxTextBox_comunicado_denominacion_onValueChanged =
            this.dxTextBox_comunicado_denominacion_onValueChanged.bind(this);
        this.dxDateBox_comunicado_fecha_onValueChanged = this.dxDateBox_comunicado_fecha_onValueChanged.bind(this);
        this.dxDateBox_comunicado_fechaFin_onValueChanged =
            this.dxDateBox_comunicado_fechaFin_onValueChanged.bind(this);
        this.dxLookup_icon_itemRender = this.dxLookup_icon_itemRender.bind(this);
        this.dxLookup_color_itemRender = this.dxLookup_color_itemRender.bind(this);
        this.dxSelectBox_color_fieldRender = this.dxSelectBox_color_fieldRender.bind(this);
        this.dxLookup_icon_fieldRender = this.dxLookup_icon_fieldRender.bind(this);
        this.dxLookup_comunicado_color_onValueChanged = this.dxLookup_comunicado_color_onValueChanged.bind(this);
        this.dxLookup_comunicado_icon_onValueChanged = this.dxLookup_comunicado_icon_onValueChanged.bind(this);
        this.dxTagBox_filterNotificaciones = this.dxTagBox_filterNotificaciones.bind(this);
        this.dxLookup_filtrarNotificaciones_onContentReady =
            this.dxLookup_filtrarNotificaciones_onContentReady.bind(this);
        this.dxButton_limpiarFiltro_onClick = this.dxButton_limpiarFiltro_onClick.bind(this);
        this.dxTextBox_searchBox_notificaciones_onValueChanged =
            this.dxTextBox_searchBox_notificaciones_onValueChanged.bind(this);

        // DATASOURCE
        this.datasource_tblTipoNotificacion_postProcess = this.datasource_tblTipoNotificacion_postProcess.bind(this);
        this.datasource_tblPersona_PeticionCambioDatos_postProcess =
            this.datasource_tblPersona_PeticionCambioDatos_postProcess.bind(this);
        this.datasource_datos_notificaciones_load = this.datasource_datos_notificaciones_load.bind(this);

        //NOTIFICACIONES
        this.reloadNotificacion = this.reloadNotificacion.bind(this);
        this.validarNotificacion = this.validarNotificacion.bind(this);
        this.denegarNotificacion = this.denegarNotificacion.bind(this);
        this.cargarImagenes_tblPeticionCambioDatos = this.cargarImagenes_tblPeticionCambioDatos.bind(this);
        this.formatNotificacion_camposNuevosAntiguos = this.formatNotificacion_camposNuevosAntiguos.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        this.searchBoxTimeout = null;
    }

    get dxForm_comunicado() {
        return this.dxForm_comunicado_REF.current.instance;
    }

    get dxTextBox_titulo_comunicado() {
        return this.dxTextBox_titulo_comunicado_REF.current.instance;
    }

    get dxTextBox_searchBoxNotificaciones() {
        return this.dxTextBox_searchBoxNotificaciones_REF.current.instance;
    }

    //#region DATASOURCES
    datasource_tblTipoNotificacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoNotificacion",
            key: "idTipoNotificacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoNotificacion_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionDenominacionNavigation,idTraduccionDescripcionNavigation"],
        postProcess: (data) => {
            return this.datasource_tblTipoNotificacion_postProcess(data);
        },
    });

    datasource_tblTipoNotificacion_postProcess(data) {
        data.unshift({
            idTipoNotificacion: null,
            denominacion: getTrad("todas"),
        });

        let _this = this;
        $.each(data, function (index, item) {
            if (item.clave === "tblPersona_PeticionCambioDatos_pendiente") {
                item.denominacion = getTrad(item.clave);
                item.badge = _this.datasource_tblPersona_PeticionCambioDatos.items().length;
            }
            if (item.clave === "tblPersona_PeticionCambioDatos_validado") {
                item.denominacion = getTrad(item.clave);
                item.visible = false;
            }
            if (item.clave === "tblPersona_PeticionCambioDatos_denegado") {
                item.denominacion = "Cambios denegados persona";
                item.visible = false;
            }

            if (item.clave === "tblDocumento_nuevo") {
                let numDocsNuevo = $.grep(_this.datasource_tblDocumento.items(), function (item) {
                    return item.data.nuevo == true;
                });
                item.denominacion = getTrad(item.clave);
                item.badge = numDocsNuevo.length;
            }
            if (item.clave === "tblDocumento_requerido") {
                let numDocsRequerido = $.grep(_this.datasource_tblDocumento.items(), function (item) {
                    return item.data.requerido == true;
                });
                item.denominacion = getTrad(item.clave);
                item.badge = numDocsRequerido.length;
            }
            if (item.clave === "tblDocumento_firmado") {
                let numDocsReqFirma = $.grep(_this.datasource_tblDocumento.items(), function (item) {
                    return item.data.firmado == 0 && (item.data.requerido == 0 || item.data.requerido == null);
                });
                item.denominacion = getTrad("firmaRequerida");
                item.badge = numDocsReqFirma.length;
            }
        });

        this.setState({ tblTipoNotificacion_items: data });

        return data;
    }

    datasource_tblTipoNotificacion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblPersona_PeticionCambioDatos = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona_PeticionCambioDatos",
            key: "idPeticionCambioDatos",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPersona_PeticionCambioDatos_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            return this.datasource_tblPersona_PeticionCambioDatos_postProcess(data);
        },
        filter: ["idPeticionCambioDatos_estado eq 1 and idPersonaNavigation/avisoLegalAceptado eq true"],
        sort: ["fechaPeticion"],
        expand: [
            "idPersonaNavigation($select=isIBANSolicitado,IBAN,NAF,apellidos,caducidadDocumentoIdentidad," +
                "calle,codigoPostal,email,fechaNacimiento,idDiscapacidad,idEstadoCivil,idFotoDocumentoIdentidad_A," +
                "idFotoDocumentoIdentidad_B,idFotoIBAN,idFotoNAF,idFotoPerfil,idGenero,idNivelEstudios,idPais," +
                "idPersona,idTallaAlfa_Camiseta,idTipoDocumentoIdentidad,localidad,nombre,numDocumentoIdentidad," +
                "numDomicilio,numHijos,piso,prefijoTelefonico,puerta,tallaPantalon,telefono,prefijoTelefonico," +
                "idComunidadAutonoma, nacionalidad, nombre_tutor, apellidos_tutor, idTipoDocumentoIdentidad_tutor," +
                "numDocumentoIdentidad_tutor, idFotoDemandanteEmpleo, idDocCertificadoDiscapacidad)",
        ],
    });

    datasource_tblPersona_PeticionCambioDatos_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todos = true;
    }

    datasource_tblPersona_PeticionCambioDatos_postProcess(data) {
        return this.formatNotificacion_camposNuevosAntiguos(data);
    }

    datasource_documentos_PeticionCambioDatos = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona_PeticionCambioDatos",
            key: "idPeticionCambioDatos",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_documentos_PeticionCambioDatos_beforeSend(request);
            },
            version: 4,
        }),
        expand: [
            "idFotoDocumentoIdentidad_ANavigation",
            "idFotoDocumentoIdentidad_BNavigation",
            "idFotoNAFNavigation",
            "idFotoIBANNavigation",
            "idFotoPerfilNavigation",
            "idFotoDemandanteEmpleoNavigation",
            "idDocCertificadoDiscapacidadNavigation",
            "idPersonaNavigation($expand=idFotoDocumentoIdentidad_ANavigation,idFotoDocumentoIdentidad_BNavigation, idFotoNAFNavigation, idFotoIBANNavigation, idFotoPerfilNavigation, idFotoDemandanteEmpleoNavigation, idDocCertificadoDiscapacidadNavigation)",
        ],
    });

    datasource_documentos_PeticionCambioDatos_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todos = true;
    }

    datasource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPersona_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblPersona_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.filtroTipoTrabajoRRHH = false;
    }

    datasource_tblDocumento = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDocumento",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDocumento_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            return this.datasource_tblDocumento_postProcess(data);
        },
        filter: [
            "notificacion eq true and (requerido eq true or firmado eq false) and idPersonaNavigation/avisoLegalAceptado eq true and idPersonaNavigation/activo eq true",
        ],
        select: ["idDocumento,fecha, fechaModificacion, denominacion, extension, idPersona, nuevo, requerido, firmado"],
        expand: ["idPersonaNavigation"],
    });

    datasource_tblDocumento_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.todos = true;
    }

    datasource_tblDocumento_postProcess(data) {
        let { tblTipoNotificacion_items } = this.state;
        data = $.map(data, function (item) {
            let tipoNotificacion = $.grep(tblTipoNotificacion_items, function (tipoNoti) {
                return (
                    (tipoNoti.clave === "tblDocumento_requerido" && item.requerido === true) ||
                    (tipoNoti.clave === "tblDocumento_firmado" && item.firmado === false)
                );
            })[0];

            return {
                data: item,
                fecha: item.fecha,
                dataSource: "datasource_tblDocumento",
                tblTipoNotificacion: {
                    idTipoNotificacion: tipoNotificacion.idTipoNotificacion,
                    icon: tipoNotificacion.icon,
                    color: tipoNotificacion.color,
                    idTraduccionDenominacionNavigation: tipoNotificacion.idTraduccionDenominacionNavigation,
                    idTraduccionDescripcionNavigation: tipoNotificacion.idTraduccionDescripcionNavigation,
                    clave: tipoNotificacion.clave,
                },
            };
        });

        return data;
    }

    datasource_datos_notificaciones = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => {
                this.datasource_datos_notificaciones_load();
            },
        }),
    });

    datasource_datos_notificaciones_load() {
        var _deferred = $.Deferred();
        let _this = this;
        $.when(_this.datasource_tblPersona_PeticionCambioDatos.reload(), _this.datasource_tblDocumento.reload()).done(
            function (peticionCambioDatos, documentos) {
                let notificaciones = [...peticionCambioDatos[0], ...documentos[0]];
                notificaciones.sort((a, b) => a.fecha - b.fecha);

                _this.datasource_tblTipoNotificacion.reload();

                _this.setState(
                    {
                        notificaciones: notificaciones,
                        datasource_tblPersona_PeticionCambioDatos: peticionCambioDatos,
                        dataSources_aux: {
                            tipoNotificacion: _this.datasource_tblTipoNotificacion.items(),
                            talla: _this.datasource_tblTallaAlfa.items(),
                            discapacidad: _this.datasource_tblDiscapacidad.items(),
                            nivelEstudios: _this.datasource_tblNivelEstudios.items(),
                            genero: _this.datasource_tblGenero.items(),
                            tipoDocumentoId: _this.datasource_tblTipoDocumentoIdentidad.items(),
                            pais: _this.datasource_tblPais.items(),
                            estadoCivil: _this.datasource_tblEstadoCivil.items(),
                            comunidadAutonoma: _this.datasource_tblComunidadAutonoma.items(),
                        },
                    },
                    () => {
                        _this.loadPanel_hide();
                    }
                );
                _deferred.resolve(_this.state.notificaciones);
            }
        );
        return _deferred.promise();
    }

    datasource_tblComunicado = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblComunicado",
            key: "idComunicado",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblComunicado_beforeSend(request);
            },
            version: 4,
        }),
        sort: "fecha",
    });
    datasource_tblComunicado_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblPais = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
            key: "idPais",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblPais_beforeSend(request);
            },
            version: 4,
        }),
        sort: "denominacion",
    });
    datasource_tblPais_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEstadoCivil = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoCivil",
            key: "idEstadoCivil",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstadoCivil_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblEstadoCivil_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTipoDocumentoIdentidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoDocumentoIdentidad",
            key: "idTipoDocumentoIdentidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTipoDocumentoIdentidad_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblTipoDocumentoIdentidad_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblGenero = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblGenero",
            key: "idGenero",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblGenero_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
    });

    datasource_tblGenero_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblNivelEstudios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblNivelEstudios",
            key: "idNivelEstudios",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblNivelEstudios_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
    });

    datasource_tblNivelEstudios_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblDiscapacidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDiscapacidad",
            key: "idDiscapacidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblDiscapacidad_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
    });

    datasource_tblDiscapacidad_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblTallaAlfa = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTallaAlfa",
            key: "idTallaAlfa",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblTallaAlfa_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblTallaAlfa_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblEstadoCivil = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoCivil",
            key: "idEstadoCivil",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblEstadoCivil_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["idTraduccionNavigation"],
    });

    datasource_tblEstadoCivil_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblComunidadAutonoma = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblComunidadAutonoma",
            key: "idComunidadAutonoma",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblComunidadAutonoma_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblComunidadAutonoma_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    //#endregion

    enum_colores = {
        info: "#30b1ff",
        success: "#3ac47d",
        warning: "#f7b924",
        danger: "#d92550",
    };

    array_lottieIcons = [
        "megaphone",
        "bell",
        "check",
        "check_input",
        "defaultUserPhoto",
        "error",
        "error_input",
        "exclamation_circle",
        "fingerDraw",
        "firma",
        "noImage",
        "password",
        "sendSMS",
        "upload",
        "warning",
    ];

    cargaDatos() {
        let _this = this;
        this.loadPanel_show();
        this.datasource_datos_notificaciones.reload();
        this.datasource_tblComunicado.reload().done(function (items) {
            let comunicados = $.map(items, function (item) {
                return {
                    data: {
                        idComunicado: item.idComunicado,
                        titulo: item.titulo,
                        contenido: item.contenido,
                        fecha: item.fecha,
                        fechaFin: item.fechaFin,
                    },
                    tblTipoNotificacion: {
                        clave: "comunicado",
                        color: _this.enum_colores[item.tipo],
                        icon: item.icon,
                    },
                };
            });
            _this.setState({
                comunicados: comunicados,
            });
        });
    }

    formData_comunicado_resetValues = {
        fecha: new Date(new Date().setSeconds(0)),
        icon: null,
        color: Object.keys(this.enum_colores).map(function (key) {
            return key;
        })[0],
    };

    render() {
        let { resolucion, user } = this.props;
        let {
            fechaSel,
            fechaHover,
            dxPopover_fechaHover_isVisible,
            notificaciones,
            dataSources_aux,
            comunicados,
            isVisible_dxPopup_comunicado,
            formData_comunicado,
            idTipoNotificacionSel,
            isComunicado_editing,
            searchBox_notificaciones,
            notificacionSel_imagenes,
        } = this.state;
        let class_responsiveHeight = get_isMovil(resolucion) ? "he-card" : "he-100";
        let height_scrollViewPrincipal = $("#dxScrollView_principal").height() - 10;

        if (!user.enableDatosRRHH) return <Redirect to="/" />;

        let notificacionesFiltrada = null;

        notificacionesFiltrada = notificaciones.filter((item) => {
            const nombreCompleto =
                item.dataSource == "datasource_tblDocumento"
                    ? `${item.data.idPersonaNavigation.nombre} ${item.data.idPersonaNavigation.apellidos}`
                    : `${item.data.nombre} ${item.data.apellidos}`;
            return searchBox_notificaciones != null
                ? nombreCompleto.toLowerCase().includes(searchBox_notificaciones.toLowerCase())
                : item;
        });

        return (
            <Fragment>
                <div className={"media-body"}>
                    <div id="Notificaciones" className="scrollbar-container">
                        <ScrollView id="dxScrollView_principal" className={"cardContent he-100"}>
                            <Row className="row-1 he-100 flex-fill px-2">
                                <Col
                                    xs="12"
                                    md="12"
                                    lg="4"
                                    xl="4"
                                    className={"d-flex flex-column col-xl-5ths "}
                                    style={{
                                        maxHeight: height_scrollViewPrincipal ? height_scrollViewPrincipal : "100%",
                                    }}
                                >
                                    <Card className="d-flex he-100 card-notificaciones">
                                        <CardHeader className="card-header-tab">
                                            <div className="d-flex w-100">
                                                <div className="d-flex align-items-center flex-40">
                                                    <div className="card-header-title">{getTrad("notificaciones")}</div>
                                                </div>
                                                <div className="d-flex" style={{ flex: "0 0 20px" }}></div>
                                                <div className="flex-1 containerFiltroNotificaciones">
                                                    <Lookup
                                                        dataSource={this.datasource_tblTipoNotificacion}
                                                        closeOnOutsideClick={true}
                                                        displayExpr="denominacion"
                                                        valueExpr="idTipoNotificacion"
                                                        value={idTipoNotificacionSel}
                                                        width="100%"
                                                        showCancelButton={false}
                                                        searchEnabled={false}
                                                        onContentReady={
                                                            this.dxLookup_filtrarNotificaciones_onContentReady
                                                        }
                                                        onValueChanged={this.dxTagBox_filterNotificaciones}
                                                    />
                                                </div>
                                            </div>
                                        </CardHeader>
                                        {/* Componente scroll izquierdo notificaciones */}
                                        <CardBody className="body-actionToast he-100 align-items-stretch">
                                            <ScrollView>
                                                <div className="pr-2 pl-2">
                                                    <Box direction="row" width="100%" height={45}>
                                                        <ItemBox baseSize="auto">
                                                            <Button
                                                                text={""}
                                                                icon=" icon_EliminarFiltros"
                                                                onClick={this.dxButton_limpiarFiltro_onClick}
                                                            />
                                                        </ItemBox>
                                                        <ItemBox baseSize={5}></ItemBox>
                                                        <ItemBox ratio={1}>
                                                            <TextBox
                                                                ref={this.dxTextBox_searchBoxNotificaciones_REF}
                                                                mode="search"
                                                                width="100%"
                                                                valueChangeEvent="keyup"
                                                                placeholder={getTrad("busqueda")}
                                                                onValueChanged={
                                                                    this
                                                                        .dxTextBox_searchBox_notificaciones_onValueChanged
                                                                }
                                                            />
                                                        </ItemBox>
                                                    </Box>
                                                </div>
                                                <div className="pr-2 pl-2 pb-1 pt-2">
                                                    <ActionToast
                                                        tipo="notificaciones"
                                                        data={notificacionesFiltrada}
                                                        notificacionSel_imagenes={notificacionSel_imagenes}
                                                        dataSources_aux={dataSources_aux}
                                                        direction="column"
                                                        idFiltro={idTipoNotificacionSel}
                                                        validarNotificacion={this.validarNotificacion}
                                                        denegarNotificacion={this.denegarNotificacion}
                                                        cargarImagenes_tblPeticionCambioDatos={
                                                            this.cargarImagenes_tblPeticionCambioDatos
                                                        }
                                                    />
                                                </div>
                                            </ScrollView>
                                        </CardBody>
                                    </Card>
                                    {/* Fin scroll izquierdo notificaciones */}
                                </Col>
                                {/* Componente de comunicados */}
                                <Col xs="12" md="12" lg="8" xl="8" className={"d-flex flex-column col-xl-5ths"}>
                                    <Row
                                        className="row-1"
                                        style={{
                                            height: get_isMovil(resolucion) ? "100%" : "35vh",
                                            minHeight: 250,
                                        }}
                                    >
                                        <Col
                                            xs="12"
                                            md="12"
                                            lg="6"
                                            xl="8"
                                            className={"d-flex flex-column col-xl-5ths " + class_responsiveHeight}
                                        >
                                            <Card className="d-flex he-100">
                                                <CardHeader className="card-header-tab">
                                                    <Row className="container_spanCentrado w-100">
                                                        <Col className="w-100 pl-2 pr-3 text-truncate text-left ">
                                                            {getTrad("comunicados")}
                                                        </Col>
                                                        <Col xs="5" className="d-flex justify-content-end p-0 ">
                                                            <PopupEnvioDocumentacion />
                                                            <Button
                                                                elementAttr={this.dxButton_comunicado}
                                                                icon="plus"
                                                                stylingMode="text"
                                                                hoverStateEnabled={false}
                                                                focusStateEnabled={false}
                                                                activeStateEnabled={false}
                                                                width={28}
                                                                onClick={this.onClick_Button_comunicado}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </CardHeader>
                                                <CardBody className="body-actionToast he-100 align-items-stretch">
                                                    <ScrollView>
                                                        <div className="pr-2 pl-2 pb-1">
                                                            <ActionToast
                                                                tipo="comunicados"
                                                                data={comunicados}
                                                                onClick_Button_editComunicado={
                                                                    this.onClick_Button_editComunicado
                                                                }
                                                                onClick_Button_removeComunicado={
                                                                    this.onClick_Button_removeComunicado
                                                                }
                                                                direction="row"
                                                            />
                                                        </div>
                                                    </ScrollView>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {/* Componente de calendario */}
                                        <Col
                                            xs="12"
                                            md="12"
                                            lg="6"
                                            xl="4"
                                            className={"d-flex flex-column col-xl-5ths " + class_responsiveHeight}
                                        >
                                            <Card className="d-flex he-100">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title">{getTrad("calendario")}</div>
                                                </CardHeader>
                                                <CardBody className="he-100 align-items-stretch p-0">
                                                    <div className="he-100 w-100 p-3">
                                                        <Calendar
                                                            firstDayOfWeek={1}
                                                            height="100%"
                                                            width="100%"
                                                            value={get_isMovil(resolucion) ? fechaSel : null}
                                                            cellRender={this.dxCalendar_eventosLavanderia_cellRender}
                                                            onInitialized={
                                                                this.dxCalendar_eventosLavanderia_onInitialized
                                                            }
                                                            onOptionChanged={
                                                                this.dxCalendar_eventosLavanderia_onOptionChanged
                                                            }
                                                            onValueChanged={
                                                                this.dxCalendar_eventosLavanderia_onValueChanged
                                                            }
                                                        />
                                                        <Popover
                                                            target={"#" + (fechaHover ? fechaHover[0].id : 1)}
                                                            position="top"
                                                            width={"auto"}
                                                            visible={dxPopover_fechaHover_isVisible}
                                                        >
                                                            <div className="flex flex-column">
                                                                <div>
                                                                    <h5>{getTrad("eventos")}</h5>
                                                                </div>
                                                                <div className="ml-2">
                                                                    {fechaHover
                                                                        ? fechaHover.map((item, i) => (
                                                                              <div className="d-flex flex-row align-items-center">
                                                                                  <div
                                                                                      style={{
                                                                                          "--circleColor": item.color,
                                                                                          "--circleSize": "8px",
                                                                                      }}
                                                                                      className="dxcalendar-date-circle"
                                                                                  />
                                                                                  <div className="pr-3" />
                                                                                  <div>{item.denominacion}</div>
                                                                              </div>
                                                                          ))
                                                                        : ""}
                                                                </div>
                                                            </div>
                                                        </Popover>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        {/* fin calendario */}
                                    </Row>
                                    <Row
                                        className="row-1"
                                        style={{
                                            height: get_isMovil(resolucion) ? "100%" : "35vh",
                                            minHeight: 250,
                                        }}
                                    >
                                        {/* Componente de anticipos */}
                                        <Col
                                            xs="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            className={"pt-3 d-flex flex-column col-xl-5ths " + class_responsiveHeight}
                                        >
                                            <GestorPlus tipoConcepto={tipoConcepto.anticipos} />
                                        </Col>
                                        {/* fin anticipos */}
                                    </Row>
                                    {/* Componente dashboard */}
                                    <Row className="row-1" style={{ flex: 3 }}>
                                        <Col
                                            xs="12"
                                            md="12"
                                            lg="12"
                                            xl="12"
                                            className={"pt-3 d-flex flex-column col-xl-5ths " + class_responsiveHeight}
                                        >
                                            <Card className="d-flex he-100">
                                                <CardHeader className="card-header-tab">
                                                    <div className="card-header-title">{getTrad("dashboard")}</div>
                                                </CardHeader>
                                                <CardBody className="he-100 align-items-stretch p-0">
                                                    <div className="he-100 w-100 p-3"></div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    {/* fub dashboard */}
                                </Col>
                            </Row>
                        </ScrollView>
                        {/* //#region  POP UP Comunicado*/}
                        <Popup
                            visible={isVisible_dxPopup_comunicado}
                            showTitle={true}
                            title={!isComunicado_editing ? "Añadir comunicado" : "Editar comunicado"}
                            closeOnOutsideClick={false}
                            width={400}
                            height={500}
                            onShown={this.onShown_popup_comunicado}
                            onHiding={this.onHiding_popup_comunicado}
                        >
                            <Form
                                ref={this.dxForm_comunicado_REF}
                                formData={formData_comunicado}
                                labelLocation="top"
                                colCount={2}
                                height="100%"
                                width="100%"
                                validationGroup="dxForm_comunicado_validationGroup"
                            >
                                <Item dataField="titulo" label={this.formLabel_titulo} colSpan={2}>
                                    <TextBox
                                        ref={this.dxTextBox_titulo_comunicado_REF}
                                        value={formData_comunicado.titulo}
                                        onValueChanged={this.dxTextBox_comunicado_titulo_onValueChanged}
                                    >
                                        <Validator validationGroup="dxForm_comunicado_validationGroup">
                                            <RequiredRule />
                                        </Validator>
                                    </TextBox>
                                </Item>
                                <Item
                                    dataField="denominacion"
                                    label={this.formLabel_denominacion}
                                    cssClass="pr-2"
                                    colSpan={2}
                                >
                                    <TextBox
                                        ref={this.dxTextBox_denominacion_comunicado_REF}
                                        value={formData_comunicado.denominacion}
                                        onValueChanged={this.dxTextBox_comunicado_denominacion_onValueChanged}
                                    ></TextBox>
                                </Item>
                                <Item dataField="fecha" label={this.formLabel_fecha} colSpan={2}>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy - HH:mm"
                                        stylingMode="underlined"
                                        value={
                                            formData_comunicado.fecha
                                                ? formData_comunicado.fecha
                                                : new Date(new Date().setSeconds(0))
                                        }
                                        min={!isComunicado_editing ? new Date().setHours(0, 0, 0, 0) : null}
                                        width="100%"
                                        type="datetime"
                                        onValueChanged={this.dxDateBox_comunicado_fecha_onValueChanged}
                                    />
                                </Item>
                                <Item dataField="fechaFin" label={this.formLabel_fechaFin} colSpan={2}>
                                    <DateBox
                                        displayFormat="dd/MM/yyyy - HH:mm"
                                        stylingMode="underlined"
                                        value={formData_comunicado.fechaFin}
                                        min={!isComunicado_editing ? new Date().setHours(0, 0, 0, 0) : null}
                                        width="100%"
                                        type="datetime"
                                        onValueChanged={this.dxDateBox_comunicado_fechaFin_onValueChanged}
                                    />
                                </Item>
                                <Item dataField="icon" label={this.formLabel_icono} colSpan={1}>
                                    <Lookup
                                        width={150}
                                        dataSource={this.array_lottieIcons}
                                        closeOnOutsideClick={true}
                                        searchEnabled={false}
                                        showCancelButton={false}
                                        showPopupTitle={false}
                                        stylingMode="underlined"
                                        value={formData_comunicado.icon}
                                        fieldRender={this.dxLookup_icon_fieldRender}
                                        itemRender={this.dxLookup_icon_itemRender}
                                        onValueChanged={this.dxLookup_comunicado_icon_onValueChanged}
                                    ></Lookup>
                                </Item>
                                <Item dataField="color" label={this.formLabel_color} colSpan={1}>
                                    <Lookup
                                        width={150}
                                        dataSource={Object.keys(this.enum_colores).map(function (key) {
                                            return key;
                                        })}
                                        closeOnOutsideClick={true}
                                        searchEnabled={false}
                                        showCancelButton={false}
                                        showPopupTitle={false}
                                        fieldRender={this.dxSelectBox_color_fieldRender}
                                        itemRender={this.dxLookup_color_itemRender}
                                        value={
                                            formData_comunicado.color
                                                ? formData_comunicado.color
                                                : Object.keys(this.enum_colores).map(function (key) {
                                                      return key;
                                                  })[0]
                                        }
                                        stylingMode="underlined"
                                        onValueChanged={this.dxLookup_comunicado_color_onValueChanged}
                                    ></Lookup>
                                </Item>
                            </Form>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={getTrad("guardar")}
                                    type="success"
                                    onClick={this.dxButton_comunicado_guardar_onClick}
                                />
                            </ToolbarItem_popup>
                            <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
                                <Button
                                    text={getTrad("cancelar")}
                                    type="normal"
                                    onClick={this.dxButton_comunicado_cancelar_onClick}
                                />
                            </ToolbarItem_popup>
                        </Popup>
                    </div>
                </div>
            </Fragment>
        );
    }

    //#region PROPIEDADAS
    dxButton_comunicado = {
        class: "iconHeader_dxButton_comunicado",
    };
    formLabel_denominacion = { text: "Contenido" };
    formLabel_titulo = { text: "Título" };
    formLabel_fecha = { text: getTrad("fecha") };
    formLabel_fechaFin = { text: "Fecha fin" };
    formLabel_icono = { text: "Icono" };
    formLabel_color = { text: "Color" };
    //#endregion

    //MÉTODOS
    reloadNotificacion(nombreDataSource, notifyText, loadPanel) {
        let _this = this;
        if (loadPanel) this.loadPanel_show(true);
        this[nombreDataSource].reload().done(function (items) {
            let notificacionesFinales = [
                ..._this.state.notificaciones.filter((x) => x.dataSource !== nombreDataSource),
                ...items,
            ];

            _this.loadPanel_hide();
            _this.datasource_tblTipoNotificacion.reload();
            notificacionesFinales.sort((a, b) => a.fecha - b.fecha);
            _this.setState(
                {
                    notificaciones: notificacionesFinales,
                },
                () => {
                    _this.loadPanel_hide();
                }
            );

            if (notifyText) {
                notify({
                    message: notifyText,
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        });
    }

    //#region EVENTOS
    //NOTIFICACIONES
    validarNotificacion(objValidacion) {
        let _this = this;
        this.datasource_tblPersona
            .store()
            .update(objValidacion.idPersona, patchRequestHandler(objValidacion.result))
            .done(function () {
                setTimeout(() => {
                    _this.datasource_tblPersona_PeticionCambioDatos
                        .store()
                        .update(
                            objValidacion.notificacionSel.data.idPeticionCambioDatos,
                            patchRequestHandler(objValidacion.obj_idPeticionCambioDatos_estado)
                        )
                        .done(function () {
                            var promises = [];
                            $.each(objValidacion.removeFotos, function (index, item) {
                                var promise = $.Deferred();
                                if (item.idDocumento) {
                                    _this.datasource_tblDocumento
                                        .store()
                                        .remove(item.idDocumento)
                                        .done(function (item) {
                                            promise.resolve();
                                        });
                                } else {
                                    promise.resolve();
                                }
                                promises.push(promise);
                            });

                            $.when.apply($, promises).done(function () {
                                _this.reloadNotificacion(
                                    "datasource_tblPersona_PeticionCambioDatos",
                                    getTrad("solicitudValidada"),
                                    false
                                );
                            });
                        });
                }, 500);
            });
    }

    denegarNotificacion(objDenegar) {
        let _this = this;
        this.datasource_tblPersona_PeticionCambioDatos
            .store()
            .update(
                objDenegar.notificacionSel.data.idPeticionCambioDatos,
                patchRequestHandler(objDenegar.obj_idPeticionCambioDatos_estado)
            )
            .done(function () {
                _this.reloadNotificacion(
                    "datasource_tblPersona_PeticionCambioDatos",
                    getTrad("solicitudDenegada"),
                    false
                );
            });
    }

    formatNotificacion_camposNuevosAntiguos(data) {
        let { tblTipoNotificacion_items } = this.state;
        let tipoNotificacion = $.grep(tblTipoNotificacion_items, function (item_) {
            return item_.clave === "tblPersona_PeticionCambioDatos_pendiente";
        })[0];

        data = $.map(data, function (item) {
            return {
                data: item,
                fecha: item.fechaPeticion,
                dataSource: "datasource_tblPersona_PeticionCambioDatos",
                tblTipoNotificacion: {
                    idTipoNotificacion: tipoNotificacion.idTipoNotificacion,
                    icon: tipoNotificacion.icon,
                    color: tipoNotificacion.color,
                    idTraduccionDenominacionNavigation: tipoNotificacion.idTraduccionDenominacionNavigation,
                    idTraduccionDescripcionNavigation: tipoNotificacion.idTraduccionDescripcionNavigation,
                    clave: tipoNotificacion.clave,
                },
            };
        });

        //#region Se convierten strings vacíos en null y datos vacíos cogen valores de tblPersona
        $.each(data, function (iNotificacion, notificacion) {
            // notificaciones
            $.each(notificacion.data.idPersonaNavigation, function (iNotificacion_bd, notificacion_bd) {
                notificacion.data.idPersonaNavigation[iNotificacion_bd] = notificacion_bd;
                $.each(notificacion.data, function (iNotificacion_props, notificacion_props) {
                    notificacion.data[iNotificacion_props] = notificacion_props;
                    if (iNotificacion_props === iNotificacion_bd) {
                        if (notificacion_props != notificacion_bd && notificacion_props === null) {
                            notificacion.data[iNotificacion_props] = notificacion_bd;
                        }
                    }
                });
            });
        });
        //#endregion

        return data;
    }

    cargarImagenes_tblPeticionCambioDatos(idPeticionCambioDatos) {
        let _this = this;

        const consulta =
            "idPersonaNavigation($expand=idFotoDocumentoIdentidad_ANavigation,idFotoDocumentoIdentidad_BNavigation, idFotoNAFNavigation, idFotoIBANNavigation, idFotoPerfilNavigation, idFotoDemandanteEmpleoNavigation, idDocCertificadoDiscapacidadNavigation)";
        const expandIdFotoDocumentoIdentidad_ANavigation = "idFotoDocumentoIdentidad_ANavigation";
        const expandIdFotoDocumentoIdentidad_BNavigation = "idFotoDocumentoIdentidad_BNavigation";
        const expandIdFotoNAFNavigation = "idFotoNAFNavigation";
        const expandIdFotoIBANNavigation = "idFotoIBANNavigation";
        const expandIdFotoPerfilNavigation = "idFotoPerfilNavigation";
        const expandIdFotoDemandanteEmpleoNavigation = "idFotoDemandanteEmpleoNavigation";
        const expandIdDocCertificadoDiscapacidadNavigation = "idDocCertificadoDiscapacidadNavigation";

        this.datasource_documentos_PeticionCambioDatos
            .store()
            .byKey(idPeticionCambioDatos, {
                expand: `${consulta},${expandIdFotoDocumentoIdentidad_ANavigation},${expandIdFotoDocumentoIdentidad_BNavigation}, ${expandIdFotoNAFNavigation}, ${expandIdFotoIBANNavigation}, ${expandIdFotoPerfilNavigation}, ${expandIdFotoDemandanteEmpleoNavigation}, ${expandIdDocCertificadoDiscapacidadNavigation}`,
            })
            .then((data) => {
                _this.setState({
                    notificacionSel_imagenes: _this.formatNotificacion_camposNuevosAntiguos(data)[0].data,
                });
            });
    }

    //#region COMUNICADO
    onClick_Button_comunicado(e) {
        this.setState({
            isVisible_dxPopup_comunicado: true,
            isComunicado_editing: false,
        });
    }

    onClick_Button_editComunicado(e, item) {
        let color = null;
        $.each(Object.entries(this.enum_colores), function (index, color_) {
            if (color_[1] === item.tblTipoNotificacion.color) color = color_[0];
        });
        let formatData = {
            idComunicado: item.data.idComunicado,
            fecha: item.data.fecha,
            fechaFin: item.data.fechaFin,
            titulo: item.data.titulo,
            denominacion: item.data.contenido,
            color: color,
            icon: item.tblTipoNotificacion.icon,
        };
        this.setState({
            isVisible_dxPopup_comunicado: true,
            isComunicado_editing: true,
            formData_comunicado: formatData,
        });
    }

    onClick_Button_removeComunicado(e, item) {
        let _this = this;
        dxMensajePregunta("¿Desea eliminar el comunicado?", [
            [
                getTrad("aceptar"),
                function (e) {
                    _this.datasource_tblComunicado
                        .store()
                        .remove(item.data.idComunicado)
                        .done(function () {
                            notify({
                                message: getTrad("aviso_C_RegistroEliminado"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                            });
                        });
                },
                "danger",
            ],
            [getTrad("cancelar"), function () {}],
        ]);
    }

    onHiding_popup_comunicado(e) {
        let _this = this;
        this.setState(
            {
                isVisible_dxPopup_comunicado: false,
                formData_comunicado: this.formData_comunicado_resetValues,
                isComunicado_editing: false,
            },
            () => {
                _this.dxForm_comunicado.repaint();
            }
        );
    }

    dxButton_comunicado_cancelar_onClick(e) {
        let _this = this;
        _this.setState(
            {
                isVisible_dxPopup_comunicado: false,
                formData_comunicado: _this.formData_comunicado_resetValues,
            },
            () => {
                _this.dxForm_comunicado.repaint();
            }
        );
    }

    onShown_popup_comunicado(e) {
        this.dxTextBox_titulo_comunicado.focus();
    }

    dxButton_comunicado_guardar_onClick(e) {
        if (this.dxForm_comunicado.validate().isValid) {
            let _this = this;
            let { formData_comunicado, isComunicado_editing } = this.state;

            let objComunicado = {
                fecha: formData_comunicado.fecha,
                fechaFin: formData_comunicado.fechaFin,
                titulo: formData_comunicado.titulo,
                contenido: formData_comunicado.denominacion,
                tipo: formData_comunicado.color,
                icon: formData_comunicado.icon,
            };

            this.loadPanel_show(true);
            if (!isComunicado_editing) {
                this.datasource_tblComunicado
                    .store()
                    .insert(objComunicado)
                    .done(function (result) {
                        _this.setState(
                            {
                                isVisible_dxPopup_comunicado: false,
                                formData_comunicado: _this.formData_comunicado_resetValues,
                            },
                            () => {
                                _this.dxForm_comunicado.repaint();
                            }
                        );
                        notify({
                            message: getTrad("aviso_C_RegistroInsertado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                        _this.loadPanel_hide();
                    });
            } else {
                this.datasource_tblComunicado
                    .store()
                    .update(formData_comunicado.idComunicado, patchRequestHandler(objComunicado))
                    .done(function (result) {
                        _this.setState(
                            {
                                isVisible_dxPopup_comunicado: false,
                                formData_comunicado: _this.formData_comunicado_resetValues,
                            },
                            () => {
                                _this.dxForm_comunicado.repaint();
                            }
                        );
                        notify({
                            message: getTrad("aviso_C_RegistroInsertado"),
                            type: "success",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                        _this.loadPanel_hide();
                    });
            }
        }
    }

    dxButton_limpiarFiltro_onClick(e) {
        this.dxTextBox_searchBoxNotificaciones.reset();
    }

    dxTextBox_searchBox_notificaciones_onValueChanged(e) {
        if (this.searchBoxTimeout != null) clearTimeout(this.searchBoxTimeout);

        this.searchBoxTimeout = setTimeout(() => {
            this.setState({ searchBox_notificaciones: e.value });
        }, 250);
    }

    dxTextBox_comunicado_titulo_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.titulo = e.value;
        this.setState({ formData_comunicado });
    }

    dxTextBox_comunicado_denominacion_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.denominacion = e.value;
        this.setState({ formData_comunicado });
    }

    dxDateBox_comunicado_fecha_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.fecha = e.value;
        this.setState({ formData_comunicado });
    }

    dxDateBox_comunicado_fechaFin_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.fechaFin = e.value;
        this.setState({ formData_comunicado });
    }

    dxLookup_icon_itemRender(e) {
        return <LottieIcon height={60} width={60} icon={e} />;
    }

    dxLookup_color_itemRender(e) {
        return <div className="border" style={{ backgroundColor: this.enum_colores[e], height: 30 }} />;
    }

    dxSelectBox_color_fieldRender(e) {
        let { formData_comunicado } = this.state;
        let formDataColor = formData_comunicado.color
            ? formData_comunicado.color
            : Object.keys(this.enum_colores).map(function (key) {
                  return key;
              })[0];
        if (formDataColor != e) return null;
        return (
            <div
                style={{
                    backgroundColor: this.enum_colores[e],
                    height: 20,
                    borderRadius: 2,
                }}
            />
        );
    }

    dxLookup_icon_fieldRender(e) {
        return (
            <div style={{ marginTop: -4 }}>
                <LottieIcon height={30} width={30} icon={e} />
            </div>
        );
    }

    dxLookup_comunicado_color_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.color = e.value;
        this.setState({ formData_comunicado });
    }

    dxLookup_comunicado_icon_onValueChanged(e) {
        let formData_comunicado = { ...this.state.formData_comunicado };
        formData_comunicado.icon = e.value;
        this.setState({ formData_comunicado });
    }

    dxLookup_filtrarNotificaciones_onContentReady(e) {
        let popup = e.component._popup;
        popup.option("showTitle", false);
        popup.content().css({ top: 0, minWidth: 300 });
        popup.content().addClass("dxLookupPopup_filtrarNotificaciones");
    }

    dxTagBox_filterNotificaciones(e) {
        this.setState({ idTipoNotificacionSel: e.value });
    }

    //#endregion

    //#region CALENDARIO
    dxCalendar_eventosLavanderia_onInitialized(e) {
        let _this = this;
        setTimeout(function () {
            e.element.find(".dx-calendar-cell").on("click", function (x) {
                let { resolucion } = _this.props;
                if (!get_isMovil(resolucion)) x.stopPropagation();
            });
        });
    }

    dxCalendar_eventosLavanderia_onValueChanged(e) {
        let { resolucion } = this.props;
        if (get_isMovil(resolucion)) {
            this.setState({ fechaSel: e.value });
        }
    }

    dxCalendar_eventosLavanderia_onOptionChanged(e) {
        let { resolucion } = this.props;
        let { dxCalendar_eventosLavanderia_dataSource } = this.state;
        if (e.name === "hoveredElement") {
            if (!get_isMovil(resolucion) && e.value && !$.isEmptyObject(e.value.data())) {
                //SE PONE HOVER
                let cellValue = e.value.data();
                let eventos = $.grep(dxCalendar_eventosLavanderia_dataSource, function (item) {
                    return formatDate(item.fecha) === formatDate(cellValue.dxDateValueKey);
                });

                if (eventos.length > 0) {
                    $.each(eventos, function (index, item) {
                        item.id = formatDate(cellValue.dxDateValueKey).replaceAll("/", "");
                    });
                    this.setState({
                        fechaHover: eventos,
                        dxPopover_fechaHover_isVisible: true,
                    });
                }
            } else {
                //SE QUITA HOVER
                this.setState({ dxPopover_fechaHover_isVisible: false });
            }
        } else if (get_isMovil(resolucion) && e.name === "value") {
            // CLICAR EN DÍA (MODO MÓVIL)
            let eventos = $.grep(dxCalendar_eventosLavanderia_dataSource, function (item) {
                return formatDate(item.fecha) === formatDate(e.value);
            });

            if (eventos.length > 0) {
                $.each(eventos, function (index, item) {
                    item.id = formatDate(e.value).replaceAll("/", "");
                });
                this.setState({
                    fechaHover: eventos,
                    dxPopover_fechaHover_isVisible: true,
                });
            } else this.setState({ dxPopover_fechaHover_isVisible: false });
        }
    }

    dxCalendar_eventosLavanderia_cellRender(itemData) {
        let { dxCalendar_eventosLavanderia_dataSource } = this.state;
        let eventos = $.grep(dxCalendar_eventosLavanderia_dataSource, function (item) {
            return formatDate(item.fecha) === formatDate(itemData.date);
        });

        let idFecha = formatDate(itemData.date).replaceAll("/", "");

        return (
            <div id={idFecha} className="d-flex flex-column align-items-center cellContainer">
                <div>{itemData.text}</div>
                {eventos.length > 0 ? (
                    <div className="d-flex flex-row align-items-center">
                        {eventos.slice(0, 6).map((item, i) => (
                            <Fragment key={i}>
                                <div
                                    style={{ "--circleColor": item.color, "--circleSize": "5px" }}
                                    className="dxcalendar-date-circle"
                                />
                                {i + 1 < eventos.length ? <div style={{ paddingRight: 2.5 }} /> : <div />}
                            </Fragment>
                        ))}
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    }
    //#endregion

    //#endregion

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        let _this = this;
        this.loadPanel_show();

        let { user } = this.props;
        if (!user.enableDatosRRHH) {
            let _this = this;
            dxMensajePregunta(getTrad("sinPermisosNecesariosFormulario"), [
                [
                    getTrad("aceptar"),
                    function (e) {
                        _this.loadPanel_hide();
                    },
                    "danger",
                ],
            ]);
        } else {
            $.when(
                this.datasource_tblTipoNotificacion.load(),
                this.datasource_tblTallaAlfa.load(),
                this.datasource_tblDiscapacidad.load(),
                this.datasource_tblNivelEstudios.load(),
                this.datasource_tblGenero.load(),
                this.datasource_tblTipoDocumentoIdentidad.load(),
                this.datasource_tblPais.load(),
                this.datasource_tblEstadoCivil.load(),
                this.datasource_tblComunidadAutonoma.load()
            ).then(function () {
                _this.cargaDatos();
            });

            //#region SignalRCore

            signalRCoreService.joinGroup("RRHH", [
                {
                    type: "RRHH/notificaciones",
                    listener: function (nombreDataSource) {
                        if (nombreDataSource != "tblPersona")
                            _this.reloadNotificacion("datasource_" + nombreDataSource, null, true);
                    },
                },
                {
                    type: "RRHH/comunicados",
                    listener: function (nombreDataSource) {
                        _this.datasource_tblComunicado.reload().done(function (items) {
                            let comunicados = $.map(items, function (item) {
                                return {
                                    data: {
                                        idComunicado: item.idComunicado,
                                        titulo: item.titulo,
                                        contenido: item.contenido,
                                        fecha: item.fecha,
                                        fechaFin: item.fechaFin,
                                    },
                                    tblTipoNotificacion: {
                                        clave: "comunicado",
                                        color: _this.enum_colores[item.tipo],
                                        icon: item.icon,
                                    },
                                };
                            });
                            _this.setState({
                                comunicados: comunicados,
                            });
                        });
                    },
                },
            ]);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { resolucion } = this.props;
        if (resolucion !== prevProps.resolucion) {
            this.setState({
                fechaSel: null,
                fechaHover: null,
                dxPopover_fechaHover_isVisible: false,
            });
        }
    }
    componentWillUnmount() {
        //#region SignalRCore
        signalRCoreService.leaveGroup("RRHH");
        //#endregion
    }
}
const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notificaciones);
