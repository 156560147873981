import { Button, Popup } from "devextreme-react";
import { ToolbarItem as ToolbarItemPopUp } from "devextreme-react/popup";
import React, { useRef, useState } from "react";
import EnvioDocumentacion from "./EnvioDocumentacion";
import { getTrad } from "helpers";

const PopupEnvioDocumentacion = () => {
    const [popup_isVisible, setPopup_isVisible] = useState(false);
    const envioDocumentos_REF = useRef();

    const abrirPopup = () => {
        setPopup_isVisible(true);
    };

    const cerrarPopup = () => {
        envioDocumentos_REF.current.clearComponent();
        setPopup_isVisible(false);
    };

    const btnGuardarPostMasivo_render = (e) => {
        return <Button text={getTrad("guardar")} type="success" onClick={btnGuardarPostMasivo_onClick}></Button>;
    };

    const btnGuardarPostMasivo_onClick = async (e) => {
        e.component.option("disabled", true);
        await envioDocumentos_REF.current.saveComponentData().then((payload) => {
            cerrarPopup();
        });
        e.component.option("disabled", false);
    };

    return (
        <>
            <Button
                text="Abrir envio documentación"
                style={{
                    marginBottom: "10px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    textTransform: "none",
                }}
                onClick={abrirPopup}
            />
            <Popup visible={popup_isVisible} onHiding={cerrarPopup} width={"95%"} height={"95%"}>
                <EnvioDocumentacion ref={envioDocumentos_REF} preventSend={false} overruleValidation={false} />
                <ToolbarItemPopUp render={btnGuardarPostMasivo_render} location="after" toolbar="bottom" />
            </Popup>
        </>
    );
};

export default PopupEnvioDocumentacion;
