import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  history,
  monthToName,
  patchRequestHandler,
  formatNumber,
} from "../../../../../helpers";

//Actions
import { loadPanelActions } from "../../../../../actions";

//Layout
import PageTitle from "../../../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

//Components
import notify from "devextreme/ui/notify";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pagers,
  Grouping,
  Lookup,
  Format,
  Pager,
  Editing,
  Scrolling,
  Paging,
  RemoteOperations,
  GroupPanel,
  Sorting,
} from "devextreme-react/data-grid";
import { Button, Popup } from "devextreme-react";

import EditCellComponent_isCerrado from "../EditCellComponent_isCerrado";
import CierreDatos_Uniformidad_Add from "./CierreDatos_Uniformidad_Add";

class CierreDatos_Uniformidad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      last_idLavanderia_Updated: null, //idLavanderia que se usa para el popup Add_Popup
      isVisible_Add_Popup: false,
    };

    //Referencias
    this.dataGrid_REF = React.createRef();

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    // this.loadPanel_show();
  }

  get dataGrid() {
    return this.dataGrid_REF.current.instance;
  }

  //#region tblCierreDatos_Uniformidad
  datasource_tblCierreDatos_Uniformidad = new DataSource({
    store: new ODataStore({
      key: ["idLavanderia", "año", "mes"],
      keyType: {
        idLavanderia: "Int32",
        año: "Int32",
        mes: "Int32",
      },
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreDatos_Uniformidad",
      errorHandler: errorHandler,
      beforeSend: (request) => {
        this.datasource_tblCierreDatos_Uniformidad_beforeSend(request);
      },
      onUpdated: () => {
        this.datasource_tblCierreDatos_Uniformidad_onUpdated();
      },
      onInserted: () => {
        this.datasource_tblCierreDatos_Uniformidad_onInserted();
      },
      version: 4,
    }),
    expand: [
      "idLavanderiaNavigation($select=denominacion; $expand=idMonedaNavigation)",
    ],
  });

  datasource_tblCierreDatos_Uniformidad_beforeSend = (request) => {
    request.headers = { ...authHeader() };
  };

  datasource_tblCierreDatos_Uniformidad_onInserted = () => {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  datasource_tblCierreDatos_Uniformidad_onUpdated = () => {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };
  //#endregion

  render() {
    const { last_idLavanderia_Updated, isVisible_Add_Popup } = this.state;

    return (
      <div className="scrollbar-container">
        <DataGrid
          ref={this.dataGrid_REF}
          //Datos
          dataSource={this.datasource_tblCierreDatos_Uniformidad}
          //Propiedades
          height={"100%"}
          width={"100%"}
          columnsAutoWidth={true}
          hoverStateEnabled={true}
          repaintChangesOnly={true}
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          onContentReady={this.dataGrid_onContentReady}
          onEditingStart={this.dataGrid_onEditingStart}
          onRowUpdating={this.dataGrid_onRowUpdating}
          onToolbarPreparing={this.dataGrid_onToolbarPreparing}
        >
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={false} />
          <Scrolling mode="virtual" />
          <Paging defaultPageSize={50} enabled={true} />

          <Sorting mode="multiple" />
          <HeaderFilter visible={true} />
          <FilterRow visible={true} />
          <Editing
            mode="cell"
            refreshMode="repaint"
            allowUpdating={true}
            selectTextOnEditStart={true}
          />
          <Column
            dataField="año"
            caption={getTrad("año")}
            width={150}
            groupIndex={0}
            defaultSortIndex={0}
            defaultSortOrder={"desc"}
          />
          <Column
            dataField="mes"
            caption={getTrad("mes")}
            width={150}
            groupIndex={1}
            defaultSortIndex={1}
            defaultSortOrder={"desc"}
            customizeText={this.customizeText_mes}
          />
          <Column
            caption=" "
            dataField="isCerrado"
            width={50}
            alignment="center"
            cssClass="p-1"
            showEditorAlways={true}
            editCellComponent={EditCellComponent_isCerrado}
            allowFiltering={false}
            headerFilter={false}
          />
          <Column
            dataField="idLavanderiaNavigation.denominacion"
            caption={getTrad("entidad")}
            allowEditing={false}
          />
          <Column
            dataField="fechaDesde"
            label={getTrad("inicio")}
            dataType={"date"}
            alignment={"center"}
            editorOptions={this.editorOptions_fecha}
          >
            <Format type="dd/MM/yyyy" />
          </Column>
          <Column
            dataField="fechaHasta"
            label={getTrad("fin")}
            dataType={"date"}
            alignment={"center"}
            editorOptions={this.editorOptions_fecha}
          >
            <Format type="dd/MM/yyyy" />
          </Column>
          <Column
            dataField="precioUnidad_MyUniform"
            label={getTrad("precioUnidad_MyUniform")}
            dataType={"decimal"}
            alignment={"center"}
            editorOptions={this.editorOptions_currency}
            allowSearch={false}
            cellRender={this.render_precioUnidad_MyUniform}
          >
            {/* <Format type="currency" precision={3} currency={"EUR"} /> */}
          </Column>
        </DataGrid>
        <Popup
          width={500}
          height={600}
          title={getTrad("añadir")}
          visible={isVisible_Add_Popup}
          closeOnOutsideClick={false}
          onHiding={this.onHiding_Add_Popup}
        >
          <div className="he-100 w-100">
            {isVisible_Add_Popup && (
              <CierreDatos_Uniformidad_Add
                idLavanderia={last_idLavanderia_Updated}
                datasource_tblCierreDatos_Uniformidad={
                  this.datasource_tblCierreDatos_Uniformidad
                }
                onSave={this.onSave_Add_Popup}
              />
            )}
          </div>
        </Popup>
      </div>
    );
  }

  //#region DataGrid Events
  dataGrid_onContentReady = (e) => {
    if (!e.component.__ready) {
      e.component.__ready = true;

      const añoActual = new Date().getFullYear();
      const mesActual = new Date().getMonth() + 1;

      e.component.expandRow([añoActual]);
      e.component.expandRow([añoActual, mesActual]);
    }
  };

  dataGrid_onEditingStart = (e) => {
    if (e.column.dataField != "isCerrado" && e.data.isCerrado) {
      e.cancel = true;
    }
  };

  dataGrid_onRowUpdating = (cellInfo) => {
    if (
      cellInfo.oldData.isCerrado === false &&
      cellInfo.newData.isCerrado === true
    ) {
      this.setState({
        isVisible_Add_Popup: true,
        last_idLavanderia_Updated: cellInfo.oldData.idLavanderia,
      });
    }

    cellInfo.newData = patchRequestHandler(cellInfo.newData);
  };

  dataGrid_onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: this.btnAdd_options,
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: this.btnClearFilter_options,
      },
      {
        location: "after",
        widget: "dxButton",
        options: this.btnRefresh_options,
      },
    );
  };

  btnAdd_options = {
    text: getTrad("nuevoPeriodo"),
    icon: "add",
    type: "success",
    onClick: () => {
      this.setState({ isVisible_Add_Popup: true });
    },
  };

  btnClearFilter_options = {
    text: getTrad("limpiarFiltro"),
    icon: " icon_EliminarFiltros",
    onClick: () => {
      this.dataGrid.clearFilter();
      notify({
        message: getTrad("aviso_C_FiltroRestaurado"),
        type: "success",
        displayTime: "1500",
        closeOnClick: true,
      });
    },
  };

  btnRefresh_options = {
    icon: "refresh",
    onClick: () => {
      this.dataGrid.refresh();
    },
  };

  customizeText_mes = (rowData) => {
    return monthToName(rowData.value, "es");
  };

  render_precioUnidad_MyUniform = (rowData) => {
    if (rowData.value) {
      const codigoMoneda =
        rowData.data.idLavanderiaNavigation.idMonedaNavigation.codigo;
      return formatNumber(rowData.value, 2, "currency", codigoMoneda);
    }
  };

  //#endregion

  //#region Add Popup

  onHiding_Add_Popup = () => {
    this.setState({
      isVisible_Add_Popup: false,
      last_idLavanderia_Updated: null,
    });
  };

  onSave_Add_Popup = (año, mes) => {
    this.setState({
      isVisible_Add_Popup: false,
      last_idLavanderia_Updated: null,
    });

    this.dataGrid.collapseAll();
    this.dataGrid.expandRow([año]);
    this.dataGrid.expandRow([año, mes]);
  };

  //#endregion

  init_editorOptions = () => {
    this.editorOptions_fecha = {
      type: "date",
      width: "100%",
      displayFormat: "dd/MM/yyyy",
    };

    this.editorOptions_currency = {
      placeholder: "0",
      step: 0,
      format: { style: "currency", currency: "EUR", minimumFractionDigits: 3 },
      min: 0,
      max: 9999.999,
    };
  };

  //#region LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }
  //#endregion

  componentDidMount() {
    const { user } = this.props;
    if (!user.isDepartamentoControl) {
      history.push("/");
    }
    this.init_editorOptions();
  }

  componentDidUpdate(prevProps) {}
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CierreDatos_Uniformidad);
