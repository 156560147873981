import { connectionConstants } from "../constants";
import { authHeader, errorHandler } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const empresasService = {
    load,
};

function load(user) {
    var deferred = $.Deferred();
    dataSource_tblEmpresasPolarier.store().load({
        filter: [`idEmpresaPolarier in (${user.tblEmpresaPolarierNUsuario.map((e) => e.idEmpresaPolarier).join(",")})`]
    }
    ).done(function (empresas) {
        localStorage.setItem("empresas", JSON.stringify(empresas));
        deferred.resolve();
    });
    return deferred.promise();
}

const dataSource_tblEmpresasPolarier = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEmpresasPolarier",
        key: "idEmpresaPolarier",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => {
            request.headers = { ...authHeader() };
        },
        version: 4,
        sort: ["denominacion"],
        expand: ["idPaisNavigation($Select=idMoneda)"],
    }),
});
