import { connectionConstants } from "../constants";
import { errorHandler, authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import ODataContext from "devextreme/data/odata/context";

export const fotosService = {
    loadFotoPerfil,
    loadFotoPerfilMasivo,
};

const promises = new Map();

function loadFotoPerfil(idPersona) {
    var deferred = $.Deferred();

    if (!promises.has(idPersona)) {
        datasource_tblPersona.filter(["idPersona", "=", idPersona]);
        let promise = datasource_tblPersona.load();
        promises.set(idPersona, promise);
        promise.done((data) => {
            deferred.resolve({
                idPersona: data[0].idPersona,
                documento: data[0].idFotoPerfilNavigation?.documento ?? null,
            });
        });
    } else {
        let promise = promises.get(idPersona);
        promise.done((data) => {
            deferred.resolve({
                idPersona: data[0].idPersona,
                documento: data[0].idFotoPerfilNavigation?.documento ?? null,
            });
        });
    }

    return deferred.promise();
}

async function loadFotoPerfilMasivo(idsPersona) {
    var deferred = $.Deferred();

    const context_tblPersona = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
        entities: {},
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => datasource_tblPersona_beforeSend(request),
    });

    function datasource_tblPersona_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params = {
            $select: "idPersona",
            $expand: "idFotoPerfilNavigation($select=documento)",
            $filter: `idPersona in (${idsPersona.join(",")})`,
        };
    }

    let promise = context_tblPersona.invoke("", {}, "get");
    idsPersona.forEach((id) => {
        promises.set(id, promise);
    });

    promise.then((data) => {
        let result = data.flat().map((item) => ({
            idPersona: item.idPersona,
            documento: item.idFotoPerfilNavigation?.documento ?? null,
        }));

        deferred.resolve(result);
    });

    return deferred.promise();
}
