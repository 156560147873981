// export const connectionConstants = {
//     WEB_API_URL: "https://testwebapi.polarier.com:444/",
//     ODATA_URL: "https://testwebapi.polarier.com:444/odata/",
//     REPORT_URL: "https://testreportservice.polarier.com:446/",
//     WEB_API_CORE_ODATA_URL: "https://testwebapicore.polarier.com:448/odata/",
//     WEB_API_CORE_URL: "https://testwebapicore.polarier.com:448/",
// };

export const connectionConstants = {
    WEB_API_URL:
        process.env.NODE_ENV === "development"
            ? "https://dtorrelloApi.polarier.com/"
            : "https://webapi.polarier.com:444/",
    ODATA_URL:
        process.env.NODE_ENV === "development"
            ? "https://dtorrelloApi.polarier.com/odata/"
            : "https://webapi.polarier.com:444/odata/",
    REPORT_URL:
        process.env.NODE_ENV === "development"
            ? "https://testreportservice.polarier.com:446/"
            : "https://reportservice.polarier.com:446/",
    WEB_API_CORE_ODATA_URL:
        process.env.NODE_ENV === "development"
            ? "https://dtorrelloApiCore.polarier.com/odata/"
            : "https://webapicore.polarier.com:448/odata/",
    WEB_API_CORE_URL:
        process.env.NODE_ENV === "development"
            ? "https://dtorrelloApiCore.polarier.com/"
            : "https://webapicore.polarier.com:448/",
};
