import { Component, createRef } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import "./Css.scss";
import {
  capitalize,
  getNombreFormulario,
  getTrad,
  authHeader,
  errorHandler,
  startOfMonth,
  formatDate_noTime_parameter,
  endOfMonth,
} from "helpers";
import { connectionConstants } from "constants";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";

import LottieIcon from "components/LottieIcon";
import ReportViewer from "components/ReportViewer";

import {
  Button,
  List,
  NumberBox,
  SelectBox,
  TextArea,
  TextBox,
  Tooltip,
} from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Box, { Item as ItemBox } from "devextreme-react/box";
import DateBox, { CalendarOptions } from "devextreme-react/date-box";
import Popup, { ToolbarItem as PopupToolbarItem } from "devextreme-react/popup";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import PopupComentarioCambioEstadoNomina from "components/RRHH/PopupComentarioCambioEstadoNomina";

function ds_authHeader(request) {
  request.headers = { ...authHeader() };
}

function ds_errorHandler(error) {
  errorHandler(error, null);
}

function CustomizeParameterEditors(report, s, e) {
  if (report === "nomina") {
    if (e.parameter.name === "idsNomina") {
      //INVISIBLES
      e.info.editor = $.extend({}, e.info.editor);
      e.info.visible = false;
    }
  }
}

const filter_idEstadoNomina = [
  [
    ["idEstadoNomina", "=", idsEstadoNomina.solicitudCambioRRHH],
    "or",
    ["idEstadoNomina", "=", idsEstadoNomina.validadoGestoria],
  ],
  "or",
  ["idEstadoNomina", "=", idsEstadoNomina.validadoEncargado]
];

class ValidacionNomina extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tblNomina: [],
      tblNominaFiltrada: [],

      tblEstadoNomina: [],
      idsPersonaGestionables: [],

      nominaSel: null,
      monthSel: new Date(),

      reportParamsNomina: {},
      reportParamsNominaAntigua: {},

      compararNomina: false,
      numNominasAnteriores: 1,

      tooltipData: {},

      dxPopup_isVisible: false,
    };
  }

  report = {
    denominacion: "nomina",
    parameterEvent: CustomizeParameterEditors,
  };

  monthYearFormat = { month: "long", year: "numeric" };
  shortDateFormat = { day: "numeric", month: "2-digit" };

  // #region TRADUCCIONES

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  // #endregion

  // #region Componente

  componentDidMount() {
    Promise.all([
      this.datasource_tblNomina.load(),
      this.datasource_tblEstadoNomina.load(),
      this.datasource_personasGestionables.load(),
    ]).then(([tblNomina, tblEstadoNomina, idsPersonaGestionables]) => {
      this.setState({
        tblNomina,
        tblNominaFiltrada: tblNomina,
        nominaSel: null,
        tblEstadoNomina,
        idsPersonaGestionables,
      });
    });
  }

  tblNominaReload = clearNominaSel => {
    this.datasource_tblNomina.load().then((tblNomina) => {
      let newState = {
        tblNomina,
        tblNominaFiltrada: tblNomina
      };

      if (clearNominaSel) {
        newState.nominaSel = null;
      }

      this.setState(newState);
    });
  };

  // #endregion

  // #region Datasources

  datasource_personasGestionables = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/RRHH/ValidacionNomina/idsPersonaGestionables",
      errorHandler: (error) => ds_errorHandler(error),
      beforeSend: (request) => ds_authHeader(request),
      version: 4,
    }),
  });

  datasource_tblNomina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblNomina",
      errorHandler: (error) => ds_errorHandler(error),
      beforeSend: (request) => ds_authHeader(request),
      onLoading: (loadOptions) =>
        this.datasource_tblNomina_onLoading(loadOptions),
      version: 4,
    }),
    expand: ["tblEstadoNominaNNomina($orderby= fecha desc;$top=1)"],
    map: (item) => this.datasource_tblNomina_map(item),
  });

  datasource_tblNomina_onLoading = (loadOptions) => {
    const { monthSel } = this.state;

    const fechaDesde = formatDate_noTime_parameter(startOfMonth(monthSel));
    const fechaHasta = formatDate_noTime_parameter(endOfMonth(monthSel));

    loadOptions.filter = [
      filter_idEstadoNomina,
      [
        [`fechaDesde ge ${fechaDesde} and fechaDesde le ${fechaHasta}`],
        "or",
        [`fechaHasta ge ${fechaDesde} and fechaHasta le ${fechaHasta}`],
      ],
    ];
  };

  datasource_tblNomina_map = (item) => {
    item.observaciones = item.tblEstadoNominaNNomina?.at(0)?.observaciones;
    delete item.tblEstadoNominaNNomina;
    return item;
  };

  datasource_tblEstadoNomina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEstadoNomina",
      errorHandler: (error) => ds_errorHandler(error),
      beforeSend: (request) => ds_authHeader(request),
      version: 4,
    }),
    expand: ["idTraduccionNavigation($select=clave)"],
    filter: filter_idEstadoNomina,
  });

  datasource_tblNominaAnteriores = new DataSource({
    paginate: true,
    pageSize: 1,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblNomina",
      errorHandler: (error) => ds_errorHandler(error),
      beforeSend: (request) => ds_authHeader(request),
      onLoading: (loadOptions) =>
        this.datasource_tblNominaAnteriores_onLoading(loadOptions),
      version: 4,
    }),
    select: ["idNomina"],
    sort: ["fechaDesde DESC"],
  });

  datasource_tblNominaAnteriores_onLoading = (loadOptions) => {
    const { nominaSel, numNominasAnteriores } = this.state;

    loadOptions.filter = [
      [`fechaDesde lt ${nominaSel.fechaDesde}`],
      [`idPersona eq ${nominaSel.idPersona}`],
    ];

    loadOptions.take = numNominasAnteriores;
  };

  context_validacionNominaController = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "MyPolarier/RRHH/ValidacionNomina/",
    entities: {
      DespacharNomina: {},
    },
    errorHandler: (error) => ds_errorHandler(error),
    beforeSend: (request) =>
      this.context_validacionNominaController_beforeSend(request),
  });

  context_validacionNominaController_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    const { nominaSel } = this.state;

    request.payload = {
      idNomina: nominaSel.idNomina,
      isValidada: true,
      observaciones: null,
    };
  };

  // #endregion

  render() {
    const {
      compararNomina,
      tooltipData,
      dxPopup_isVisible,
      nominaSel
    } = this.state;
    return (
      <>
        <Row className="he-100 ValidacionNominas position-relative">
          <Col
            xs={4}
            className={`w-100 he-100 colsTransition position-absolute col-Left`}
          >
            <this.renderListaNominas />
          </Col>
          <Col
            xs={6}
            className={`w-100 he-100 colsTransition position-absolute ${compararNomina ? "col-Left" : "col-Hidden"}`}
          >
            <this.renderPrevisualizacion />
          </Col>
          <Col
            xs={!compararNomina ? 8 : 6}
            className="w-100 he-100 colsTransition position-absolute col-Right"
          >
            <this.renderPrevisualizacion isActual />
          </Col>
        </Row>
        <Tooltip
          visible={tooltipData.target != null}
          target={tooltipData.target}
        >
          <div>{tooltipData.content}</div>
        </Tooltip>
        <PopupComentarioCambioEstadoNomina
          title={getTrad("solicitarCambio")}
          visible={dxPopup_isVisible}
          idNomina={nominaSel?.idNomina}
          idEstadoNomina={idsEstadoNomina.solicitudCambioRRHH}
          onConfirm={this.tblNominaReload}
          onHiding={this.dxPopup_onHiding}
        />
      </>
    );
  }

  renderListaNominas = () => {
    const { tblNomina, monthSel, filtroEstado, filtroBuscar } = this.state;
    return (
      <Card className="he-100">
        <CardHeader>
          <CardTitle className="m-0">{getNombreFormulario(this)}</CardTitle>
        </CardHeader>
        <CardBody>
          <div className="filtros">
            <Box>
              <ItemBox ratio={1}>
                <SelectBox
                  dataSource={this.datasource_tblEstadoNomina}
                  valueExpr={"idEstadoNomina"}
                  openOnFieldClick={true}
                  showClearButton={true}
                  stylingMode="underlined"
                  itemRender={this.dxSelectBox_itemRender}
                  fieldRender={this.dxSelectBox_fieldRender}
                  onValueChanged={this.dxSelectBox_onValueChanged}
                />
              </ItemBox>
              <ItemBox ratio={1}>
                <DateBox
                  max={Date.now()}
                  value={monthSel}
                  acceptCustomValue={false}
                  openOnFieldClick={true}
                  stylingMode="underlined"
                  displayFormat={this.dxDateBox_displayFormat}
                  onValueChanged={this.dxDateBox_onValueChanged}
                >
                  <CalendarOptions maxZoomLevel={"year"} />
                </DateBox>
              </ItemBox>
            </Box>
            <TextBox
              placeholder={this.getTrad("buscar…")}
              stylingMode="underlined"
              onValueChanged={this.dxTextBox_onValueChanged}
              mode="search"
              valueChangeEvent="input"
            />
            <List
              selectionMode="single"
              repaintChangesOnly
              items={tblNomina.filter(
                (x) =>
                  (!filtroEstado || filtroEstado(x.idEstadoNomina)) &&
                  (!filtroBuscar || filtroBuscar(x.nombreCompleto)),
              )}
              itemRender={this.dxList_itemRender}
              onSelectionChanged={this.dxList_onSelectionChanged}
              stylingMode="underlined"
            />
          </div>
        </CardBody>
      </Card>
    );
  };

  renderPrevisualizacion = ({ isActual }) => {
    const {
      nominaSel,
      reportParamsNomina,
      reportParamsNominaAntigua,
      compararNomina,
      numNominasAnteriores,
      idsPersonaGestionables,
    } = this.state;
    return (
      <Card className="he-100 dx-widget">
        <CardHeader className="justify-content-between">
          <CardTitle className="m-0">
            {isActual ? "Previsualización" : "Nóminas anteriores"}
          </CardTitle>
          {!isActual && (
            <div className="d-flex flex-row align-items-center">
              <span className="mr-2 font-weight-semiBold color-secondary">
                Número de nóminas:
              </span>
              <NumberBox
                className="text-center"
                width={50}
                min={1}
                value={numNominasAnteriores}
                showSpinButtons
                stylingMode="underlined"
                onValueChanged={this.dxNumberBox_onValueChanged}
              />
            </div>
          )}
        </CardHeader>
        <CardBody className="p-0 position-relative">
          <ReportViewer
            params={
              isActual
                ? reportParamsNomina
                : compararNomina
                  ? reportParamsNominaAntigua
                  : {}
            }
            report={this.report}
            defaultZoom={0.85}
            hideFilters
          />

          {((isActual &&
            (!reportParamsNomina.idsNomina ||
              reportParamsNomina.idsNomina?.length === 0)) ||
            (!isActual &&
              (!reportParamsNominaAntigua.idsNomina ||
                reportParamsNominaAntigua.idsNomina?.length === 0))) && (
              <div className="lottieDiv">
                <div className="lottieContainer">
                  <LottieIcon
                    icon="buscarEncuesta"
                    width="50%"
                    height="inherit"
                  />
                  <span>No se ha encontrado ninguna nómina</span>
                </div>
              </div>
            )}
        </CardBody>
        <CardFooter>
          <Toolbar>
            <ToolbarItem location="before">
              {/* TODO: Añadir traducciones */}
              <Button
                disabled={nominaSel == null}
                id="anterior"
                icon={isActual ? " icon_Eye" : " icon_Eye_Blocked"}
                text={
                  isActual ? "Ver nóminas anteriores" : this.getTrad("cerrar")
                }
                onClick={this.dxToolbarItem_dxButton_onClick}
              />
            </ToolbarItem>
            {isActual && (
              <ToolbarItem location="after">
                <Button
                  disabled={
                    nominaSel == null ||
                    nominaSel.idEstadoNomina !== idsEstadoNomina.validadoGestoria ||
                    !idsPersonaGestionables.includes(nominaSel.idPersona)
                  }
                  icon=" icon_Check"
                  type="success"
                  text={this.getTrad("validar")}
                  onClick={() => this.despacharNomina()}
                />
              </ToolbarItem>
            )}
            {isActual && (
              <ToolbarItem location="after">
                <Button
                  disabled={
                    nominaSel == null ||
                    nominaSel.idEstadoNomina !== idsEstadoNomina.validadoGestoria ||
                    !idsPersonaGestionables.includes(nominaSel.idPersona)
                  }
                  id="siguiente"
                  type="danger"
                  icon=" icon_Cerrar"
                  text={getTrad("solicitarCambio")}
                  onClick={this.rechazarNomina}
                />
              </ToolbarItem>
            )}
          </Toolbar>
        </CardFooter>
      </Card>
    );
  };

  rechazarNomina = () => {
    this.setState({ dxPopup_isVisible: true });
  };

  despacharNomina = () => {
    this.context_validacionNominaController
      .invoke("DespacharNomina", { isValidada: true }, "POST")
      .then(() => {
        this.tblNominaReload();
      });
  };

  // #region dxSelectBox

  dxSelectBox_itemRender = (value) => {
    return <span>{this.getTrad(value.idTraduccionNavigation.clave)}</span>;
  };

  dxSelectBox_fieldRender = (value) => {
    return (
      <TextBox
        readOnly
        value={value ? this.getTrad(value.idTraduccionNavigation.clave) : null}
        placeholder={this.getTrad("seleccionar")}
      />
    );
  };

  dxSelectBox_onValueChanged = (e) => {
    let filtroEstado = (a) => !e.value || a === e.value;
    this.setState({ filtroEstado });
  };

  // #endregion

  // #region dxDateBox

  dxDateBox_displayFormat = (value) => {
    return capitalize(
      value.toLocaleDateString(undefined, this.monthYearFormat),
    );
  };

  dxDateBox_onValueChanged = (e) => {
    this.setState({ monthSel: e.value });
    this.tblNominaReload(true);
  };

  // #endregion

  // #region dxTextBox

  dxTextBox_onValueChanged = (e) => {
    let filtroBuscar = (a) => a.includes(e.value.toUpperCase());
    this.setState({ filtroBuscar });
  };

  // #endregion

  // #region dxList

  dxList_itemRender = (value, index) => {
    const { tblEstadoNomina, nominaSel } = this.state;
    const {
      idNomina,
      fechaDesde,
      fechaHasta,
      nombreCompleto,
      idEstadoNomina,
      observaciones,
    } = value;

    let estadoNomina = tblEstadoNomina.find(
      (x) => x.idEstadoNomina === idEstadoNomina,
    )?.idTraduccionNavigation?.clave;
    let fechaDesdeString = new Date(fechaDesde).toLocaleDateString(
      undefined,
      this.shortDateFormat,
    );
    let fechaHastaString = new Date(fechaHasta).toLocaleDateString(
      undefined,
      this.shortDateFormat,
    );

    let bgChip =
      idEstadoNomina === idsEstadoNomina.validadoGestoria
        ? "bg-info"
        : idEstadoNomina === idsEstadoNomina.validadoEncargado
          ? "bg-success"
          : "bg-danger";

    return (
      <Box className="listItem align-items-center">
        <ItemBox ratio={1}>
          <span className="nombreLista">{nombreCompleto} </span>
          <span className={`chipEstado ${bgChip}`}>
            {this.getTrad(estadoNomina)}
          </span>
        </ItemBox>
        {observaciones && (
          <ItemBox baseSize={"auto"}>
            <i
              className="icon_Comment font-size-lg"
              onMouseEnter={(e) => this.dxTooltip_Observaciones_Show(e, value)}
              onMouseLeave={this.dxTooltip_Observaciones_Hide}
            />
          </ItemBox>
        )}
        <ItemBox baseSize={85}>
          <span
            className={`text-center${nominaSel?.idNomina === idNomina ? "" : " text-secondary"}`}
          >
            {fechaDesdeString} - {fechaHastaString}
          </span>
        </ItemBox>
      </Box>
    );
  };

  dxTooltip_Observaciones_Show = (e, value) => {
    this.setState({
      tooltipData: {
        target: e.target,
        content: <span>{value.observaciones}</span>,
      },
    });
  };

  dxTooltip_Observaciones_Hide = () => {
    this.setState({ tooltipData: {} });
  };

  dxList_onSelectionChanged = (e) => {
    let idsNomina = e.addedItems.map((x) => x.idNomina);
    var reportParamsNomina = {
      idsNomina: idsNomina,
    };

    this.setState({ nominaSel: e.addedItems[0], reportParamsNomina });
  };

  // #endregion

  // #region dxToolbarItem

  dxToolbarItem_dxButton_onClick = () => {
    const { compararNomina } = this.state;

    if (!compararNomina) {
      this.datasource_tblNominaAnteriores.load().then((data) => {
        data = data.map((x) => x.idNomina);
        let reportParamsNominaAntigua = {
          idsNomina: data,
        };

        this.setState({
          compararNomina: !compararNomina,
          reportParamsNominaAntigua,
        });
      });
    } else {
      this.setState({
        compararNomina: !compararNomina,
        reportParamsNominaAntigua: {},
      });
    }
  };

  // #endregion

  // #region dxNumberBox

  dxNumberBox_onValueChanged = (e) => {
    this.setState({ numNominasAnteriores: e.value });
    this.datasource_tblNominaAnteriores.load().then((data) => {
      data = data.map((x) => x.idNomina);
      let reportParamsNominaAntigua = {
        idsNomina: data,
      };

      this.setState({ reportParamsNominaAntigua });
    });
  };

  // #endregion

  // #region dxPopup

  dxPopup_onHiding = () => {
    this.setState({ dxPopup_isVisible: false });
  };

  // #endregion
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(ValidacionNomina);
