import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    getTrad,
    formatNumber,
    enum_colores_dashboard,
    startOfMonth,
    endOfMonth,
    formatDate_parameter,
    tooltipControl_creacion,
    leadingZero,
    capitalize,
    customMonthViewer_rangeSelector,
    filtroTiempo_Resize,
    init_CustomMarker_RangeSelector,
    dxRangeSelector_PosicionValor_nuevoMarker,
    selectorMeses_dxRangeSelector,
    remove_CustomMarker_RangeSelector,
} from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ScrollView from "devextreme-react/scroll-view";
import ArrayStore from "devextreme/data/array_store";

import { Button } from "devextreme-react/button";
import { CircularGauge } from "devextreme-react/circular-gauge";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import Box, { Item as ItemBox } from "devextreme-react/box";
import TileView from "devextreme-react/tile-view";
import { RangeSelector, SliderMarker } from "devextreme-react/range-selector";

import DataGrid, { Column, LoadPanel, FilterRow, Scrolling } from "devextreme-react/data-grid";

import Chart, {
    CommonSeriesSettings,
    Legend,
    SeriesTemplate,
    ArgumentAxis,
    LoadingIndicator,
    Tooltip,
} from "devextreme-react/chart";

import { LinearGauge } from "devextreme-react/linear-gauge";
import List from "devextreme-react/list";

import { locale } from "devextreme/localization";

//Css
import "./Css.scss";

class ControlUso extends React.Component {
    constructor(props) {
        super(props);

        this.data = {
            isMaster: true,
            idUsuarioSel: -1,
            idLavSel: -1,
            timeout_RangeSelector_valueChanged: null,
            fechaSel: {
                desde: !this.isMaster
                    ? new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1)) //Ayer
                    : new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()), //3 Meses atrás
                hasta: new Date(new Date().setHours(0, 0, 0, 0)), //Hoy
            },
        };

        this.state = {
            tabPanel_data: null,
            rangeSelector: {
                tipo: null, // mensual, anual, diario
                behavior: {
                    callValueChanged: "onMovingComplete",
                },
                sliderMarker: {
                    format: "monthAndDay",
                    paddingLeftRight: 30,
                },
                scale: {
                    minRange: { days: 1, months: null },
                    maxRange: null,
                    startValue: null,
                    endValue: null,
                    tickInterval: "day",
                    minorTickInterval: "day",
                    aggregationInterval: null,
                },
            },
        };

        //Referencias
        this.tabPanelPrincipal_REF = React.createRef();

        //TEMPLATE
        this.itemRender_datosMacro = this.itemRender_datosMacro.bind(this);
        this.itemRender_navegadores = this.itemRender_navegadores.bind(this);
        this.dxList_lavanderias_itemRender = this.dxList_lavanderias_itemRender.bind(this);
        this.dxTileView_lavanderias_itemRender = this.dxTileView_lavanderias_itemRender.bind(this);
        this.dxList_aplicaciones_render = this.dxList_aplicaciones_render.bind(this);
        this.dxChart_tooltip_contentRender = this.dxChart_tooltip_contentRender.bind(this);

        //DATASOURCE
        this.dataSource_datasource_General_DatosMacro_beforeSend =
            this.dataSource_datasource_General_DatosMacro_beforeSend.bind(this);

        //EVENTOS
        this.gridFormularios_onToolbarPreparing = this.gridFormularios_onToolbarPreparing.bind(this);
        this.gridFormularios_onInitialized = this.gridFormularios_onInitialized.bind(this);

        this.gridUsuarios_onToolbarPreparing = this.gridUsuarios_onToolbarPreparing.bind(this);
        this.gridUsuarios_onInitialized = this.gridUsuarios_onInitialized.bind(this);

        this.gridLavanderia_onToolbarPreparing = this.gridLavanderia_onToolbarPreparing.bind(this);
        this.gridLavanderia_onInitialized = this.gridLavanderia_onInitialized.bind(this);

        this.gridUsuarios_usuarios_onRowPrepared = this.gridUsuarios_usuarios_onRowPrepared.bind(this);
        this.gridUsuarios_usuarios_onSelectionChanged = this.gridUsuarios_usuarios_onSelectionChanged.bind(this);

        this.gridUsuarios_usuarios_onToolbarPreparing = this.gridUsuarios_usuarios_onToolbarPreparing.bind(this);

        this.dxList_lavanderias_onContentReady = this.dxList_lavanderias_onContentReady.bind(this);
        this.dxList_lavanderias_onSelectionChanged = this.dxList_lavanderias_onSelectionChanged.bind(this);

        this.dxTileView_lavanderias_onContentReady = this.dxTileView_lavanderias_onContentReady.bind(this);
        this.dxTileView_lavanderias_onItemRendered = this.dxTileView_lavanderias_onItemRendered.bind(this);

        this.dxTabPanel_onSelectionChanged = this.dxTabPanel_onSelectionChanged.bind(this);

        //Range selector
        this.dxButtonMensual_onInitialized = this.dxButtonMensual_onInitialized.bind(this);
        this.dxButtonDiario_onInitialized = this.dxButtonDiario_onInitialized.bind(this);

        this.dxButtonMensual_onClick = this.dxButtonMensual_onClick.bind(this);
        this.dxButtonDiario_onClick = this.dxButtonDiario_onClick.bind(this);

        this.dxRangeSelector_onValueChanged = this.dxRangeSelector_onValueChanged.bind(this);
        this.dxRangeSelector_onDrawn = this.dxRangeSelector_onDrawn.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();
    }
    get tabPanelPrincipal() {
        return this.tabPanelPrincipal_REF.current !== null ? this.tabPanelPrincipal_REF.current.instance : null;
    }

    //#region DATSOURCES

    //GENERAL
    datasource_datos_filtroTiempo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_filtroTiempo",
            key: "fecha",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
    });

    datasource_General_DatosMacro = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_DatosMacro",
            key: "totalVisitas",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.dataSource_datasource_General_DatosMacro_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            var datos = data[0]; //Solo hay una row

            return [
                {
                    text: "Total visitas",
                    value: formatNumber(datos.totalVisitas),
                    text2: "Media diaria",
                    value2: formatNumber(datos.mediaVisitas, 1, "decimal"),
                    baseSize: 250,
                    color: enum_colores_dashboard[0],
                    icon: "home",
                },
                {
                    text: "Usuarios activos",
                    value: formatNumber(datos.usuariosActivos),
                    text2: "Media diaria",
                    value2: formatNumber(datos.mediaUsuariosActivos, 1, "decimal"),
                    baseSize: 250,
                    color: enum_colores_dashboard[1],
                    icon: "group",
                },
            ];
        },
    });
    dataSource_datasource_General_DatosMacro_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_General_Navegadores = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Navegador",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.dataSource_datasource_General_Navegadores_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            $.each(data, function (index, item) {
                item.value = formatNumber(item.val, 1, "percent");
                item.baseSize = 170;

                if (item.arg === "chrome") {
                    item.color = enum_colores_dashboard[4];
                    item.icon = "chrome";
                } else if (item.arg === "msie") {
                    item.color = enum_colores_dashboard[3];
                    item.icon = "msie";
                } else if (item.arg === "mozilla") {
                    item.color = enum_colores_dashboard[2];
                    item.icon = "firefox";
                } else {
                    item.color = enum_colores_dashboard[5];
                    item.icon = "browser";
                }
            });

            return data;
        },
    });
    dataSource_datasource_General_Navegadores_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_General_Formularios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Formularios",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_General_Formularios_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_General_Formularios_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idUsuario = null;
        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_General_Usuarios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Usuarios",
            key: "idUsuario",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_General_Usuarios_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_General_Usuarios_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_General_Lavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Lavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_General_Lavanderia_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_General_Lavanderia_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    //USUARIOS
    datasource_Usuarios_DxDataGrid_Usuarios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Usuarios",
            key: "idUsuario",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Usuarios_DxDataGrid_Usuarios_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_Usuarios_DxDataGrid_Usuarios_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_Usuarios_DxDataGrid_UsuariosDesglose = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Usuarios_Desglose",
            key: "startDate",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Usuarios_DxDataGrid_UsuariosDesglose_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_Usuarios_DxDataGrid_UsuariosDesglose_beforeSend(request) {
        request.headers = { ...authHeader() };
        request.params.idUsuario = this.data.idUsuarioSel;
        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    datasource_Usuarios_DxChart_Formularios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_Formularios",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Usuarios_DxChart_Formularios_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            let otros = {
                arg: "Otros",
                val: 0,
                porcentaje: 0,
                series: "Otros",
            };

            $.each(data, function (index, item) {
                if (item.porcentaje * 100 < 5) {
                    // si es menor al 5% irá en otros
                    otros.val += item.val;
                    otros.porcentaje += item.porcentaje;
                }
            });

            let data_formated = $.grep(data, function (item) {
                return item.porcentaje * 100 >= 5;
            });
            data_formated.push(otros);

            return data_formated;
        },
        sort: ["val desc"],
    });
    datasource_Usuarios_DxChart_Formularios_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idUsuario = this.data.idUsuarioSel;
        request.params.fechaDesde = formatDate_parameter(this.data.fechaSel["desde"]);
        request.params.fechaHasta = formatDate_parameter(this.data.fechaSel["hasta"]);
    }

    dataSource_tabPanel = new DataSource({
        store: new CustomStore({
            load: () => {
                this.dataSource_tabPanel_load();
            },
        }),
    });

    //APLICACIONES
    datasource_Lavanderias_Lavanderias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_CriteriosValoracion_Lavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Lavanderias_Lavanderias_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: function (data) {
            data[0].denominacion = "NIVEL DE USO GENERAL";
            return data;
        },
    });
    datasource_Lavanderias_Lavanderias_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.fecha = formatDate_parameter(this.data.fechaSel["desde"]);
    }

    datasource_Lavanderias_Modulos = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_CriteriosValoracion_Modulo",
            key: "idModulo",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Lavanderias_Modulos_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_Lavanderias_Modulos_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = this.data.idLavSel ? (this.data.idLavSel !== -1 ? this.data.idLavSel : null) : -1;
        request.params.fecha = formatDate_parameter(this.data.fechaSel["desde"]);
    }

    datasource_Lavanderias_Criterios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "spSelectControlUso_CriteriosValoracion_Criterio",
            key: "idCriterioValoracion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_Lavanderias_Criterios_beforeSend(request);
            },
            version: 4,
        }),
    });
    datasource_Lavanderias_Criterios_beforeSend(request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = this.data.idLavSel ? (this.data.idLavSel !== -1 ? this.data.idLavSel : null) : -1;
        request.params.fecha = formatDate_parameter(this.data.fechaSel["desde"]);
    }

    datasource_Lavanderias_ModulosCriterios = new DataSource({
        store: new CustomStore({
            load: () => {
                this.dataSource_ModulosCriterios_load();
            },
        }),
    });

    dataSource_ModulosCriterios_load() {
        var deferred_ = $.Deferred();
        let _this = this;

        $.when(_this.datasource_Lavanderias_Modulos.load(), _this.datasource_Lavanderias_Criterios.load()).done(
            function () {
                var modulos = _this.datasource_Lavanderias_Modulos.items();
                var criterios = _this.datasource_Lavanderias_Criterios.items();

                var datos = [];
                $.each(modulos, function (index, modulo) {
                    var mod = $.extend(true, {}, modulo);
                    mod.tblCriterio = $.grep(criterios, function (criterio) {
                        return criterio.idModulo === mod.idModulo;
                    });

                    datos.push(mod);
                });
                if (_this.state.tabPanel_data !== null) {
                    let obj = _this.state.tabPanel_data;
                    obj[2].data.modulosCriterios = datos;
                    _this.setState({ tabPanel_data: obj });
                }

                _this.loadPanel_hide();
                deferred_.resolve(datos);

                return deferred_.promise();
            }
        );
    }

    dataSource_tabPanel_load() {
        var deferred_ = $.Deferred();
        let _this = this;

        $.when(
            _this.datasource_General_DatosMacro.load(),
            _this.datasource_General_Navegadores.load(),
            _this.datasource_General_Formularios.load(),
            _this.datasource_General_Usuarios.load(),
            _this.datasource_General_Lavanderia.load(),
            _this.datasource_Usuarios_DxDataGrid_Usuarios.load(),
            _this.datasource_Usuarios_DxDataGrid_UsuariosDesglose.load(),
            _this.datasource_Usuarios_DxChart_Formularios.load()
            // _this.datasource_Lavanderias_Lavanderias.load(),
            // _this.datasource_Lavanderias_Modulos.load(),
            // _this.datasource_Lavanderias_Criterios.load()
        ).done(function () {
            //#region Aplicaciones
            var modulos = _this.datasource_Lavanderias_Modulos.items();
            var criterios = _this.datasource_Lavanderias_Criterios.items();

            var datos_aplicaciones = [];
            $.each(modulos, function (index, modulo) {
                var mod = $.extend(true, {}, modulo);
                mod.tblCriterio = $.grep(criterios, function (criterio) {
                    return criterio.idModulo === mod.idModulo;
                });
                datos_aplicaciones.push(mod);
            });
            //#endregion

            let datos = [
                {
                    title: getTrad("general"),
                    key: "general",
                    visible: true,
                    data: {
                        datosMacro: _this.datasource_General_DatosMacro.items(),
                        navegadores: _this.datasource_General_Navegadores.items(),
                        formularios: _this.datasource_General_Formularios.items(),
                        usuarios: _this.datasource_General_Usuarios.items(),
                        lavanderia: _this.datasource_General_Lavanderia.items(),
                    },
                },
                {
                    title: getTrad("usuarios"),
                    key: "usuarios",
                    visible: true,
                    data: {
                        usuarios: new DataSource({
                            store: new ArrayStore({
                                key: "idUsuario",
                                data: _this.datasource_Usuarios_DxDataGrid_Usuarios.items(),
                            }),
                        }),
                        usuarioDesglose: _this.datasource_Usuarios_DxDataGrid_UsuariosDesglose.items(),
                        usuarioChart: _this.datasource_Usuarios_DxChart_Formularios.items(),
                    },
                },
                // {
                //     title: getTrad("aplicaciones"),
                //     key: "aplicaciones",
                //     visible: true,
                //     data: {
                //         lavanderias: _this.datasource_Lavanderias_Lavanderias.items(),
                //         modulosCriterios: datos_aplicaciones,
                //     },
                // },
            ];

            if (_this.state.tabPanel_data !== null) _this.loadPanel_hide();

            _this.setState({ tabPanel_data: datos });
            deferred_.resolve(datos);

            return deferred_.promise();
        });
    }

    //#endregion

    //#region Renders y campos de widgets

    itemRender_datosMacro(itemData) {
        return (
            <Box
                direction="row"
                height={"100%"}
                width={"100%"}
                align="space-around"
                crossAlign="stretch"
                style={{ backgroundColor: itemData.color, borderRadius: 3 }}
            >
                <ItemBox ratio={1}>
                    <Box
                        direction="col"
                        height={"100%"}
                        width={"100%"}
                        align="space-around"
                        crossAlign="stretch"
                        style={{ padding: "5px 10px" }}
                    >
                        <ItemBox ratio={1}>
                            <div style={{ width: "100%" }}>
                                <div
                                    style={{
                                        display: "inline-block",
                                        color: "white",
                                        fontSize: "22px",
                                        marginRight: "5px",
                                    }}
                                >
                                    {itemData.value}
                                </div>
                                <div style={{ display: "inline-block", color: "white" }}>{itemData.text}</div>
                            </div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <div style={{ width: "100%" }}>
                                <div
                                    style={{
                                        display: "inline-block",
                                        color: "white",
                                        fontSize: "22px",
                                        marginRight: "5px",
                                    }}
                                >
                                    {itemData.value2}
                                </div>
                                <div style={{ display: "inline-block", color: "white" }}>{itemData.text2}</div>
                            </div>
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={70}>
                    <div style={{ width: "100%", padding: "10px" }}>
                        <i className={"dx-icon-" + itemData.icon} style={{ fontSize: "45px", color: "white" }}></i>
                    </div>
                </ItemBox>
            </Box>
        );
    }

    itemRender_navegadores(itemData) {
        return (
            <div style={{ height: "100%", paddingRight: 10 }}>
                <Box
                    direction="row"
                    height={"100%"}
                    width={"100%"}
                    align="space-around"
                    crossAlign="stretch"
                    style={{ backgroundColor: itemData.color, borderRadius: 3 }}
                >
                    <ItemBox ratio={1}>
                        <div style={{ padding: "5px 10px" }}>
                            <div style={{ color: "white", fontSize: "22px" }}>{itemData.value}</div>
                            <div style={{ color: "white" }}>{itemData.arg.toUpperCase()}</div>
                        </div>
                    </ItemBox>
                    <ItemBox baseSize={70}>
                        <div className="container_spanCentrado">
                            <div className={"icon_" + itemData.icon + " whiteIcon"} style={{ fontSize: "40px" }} />
                        </div>
                    </ItemBox>
                </Box>
            </div>
        );
    }

    gridFormularios_customizeText(e) {
        return formatNumber(e.value, null, "percent");
    }

    gridUsuarioDesglose_customizeText(e) {
        if (e.value === null) return "-";

        let value = Number(e.value),
            mm = Math.floor((value % 3600) / 60),
            ss = Math.floor((value % 3600) % 60);

        return leadingZero(mm) + ":" + leadingZero(ss);
    }

    dxChart_tooltip_contentRender(e) {
        return e.argument;
    }

    getColor_porcentaje(porcentaje) {
        return porcentaje >= 0.9
            ? "#97DA97"
            : porcentaje >= 0.8
            ? "#C9D38F"
            : porcentaje >= 0.7
            ? "#FACB86"
            : porcentaje >= 0.6
            ? "#F1A985"
            : "#E78683";
    }

    dxList_lavanderias_itemRender(itemData) {
        let isTodas = itemData.idLavanderia === -1,
            color = this.getColor_porcentaje(itemData.val);

        return (
            <div className="container_spanCentrado" style={{ display: "flex", height: isTodas ? "80px" : "65px" }}>
                <div
                    style={{
                        flex: "1",
                        fontSize: isTodas ? "20px" : "16px",
                        padding: isTodas ? "14px 7px" : "13px 5px",
                        borderBottom: (isTodas ? "3px" : "2px") + " solid " + color,
                    }}
                >
                    {itemData.denominacion}
                </div>
                <div
                    style={{
                        padding: isTodas ? "10px" : "8px",
                        backgroundColor: color,
                        color: "white",
                        borderRadius: "3px 3px 3px 0",
                        fontSize: isTodas ? "28px" : "24px",
                    }}
                >
                    {formatNumber(itemData.val, 2, "percent")}
                </div>
            </div>
        );
    }

    dxList_aplicaciones_render(e) {
        return (
            <div className="container_spanCentrado" style={{ display: "flex", height: "30px" }}>
                <div style={{ paddingRight: "5px", width: "55px", fontWeigth: "600" }}>
                    {"(" + formatNumber(e.ponderacion, 0, "percent") + ")"}
                </div>
                <div
                    style={{
                        flex: "1",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        paddingRight: "5px",
                    }}
                >
                    {e.denominacion}
                </div>
                <LinearGauge
                    rangeContainer={{
                        width: 15,
                        backgroundColor: "lightgrey",
                    }}
                    scale={{
                        startValue: 0,
                        endValue: 1,
                        tick: { visible: false },
                        label: { visible: false },
                    }}
                    valueIndicator={{
                        type: "rangebar",
                        color: this.getColor_porcentaje(e.val),
                        offset: 0,
                        size: 15,
                    }}
                    value={e.val}
                    title={{
                        font: {
                            size: 14,
                            weight: "bold",
                        },
                        margin: {
                            bottom: 3,
                            left: 0,
                            right: 0,
                            top: 0,
                        },
                        text: formatNumber(e.val, 2, "percent"),
                        horizontalAlignment: "right",
                    }}
                    size={{
                        width: 100,
                        height: 30,
                    }}
                />
            </div>
        );
    }

    dxTileView_lavanderias_itemRender(e) {
        return (
            <Box
                direction="col"
                align="space-around"
                crossAlign="stretch"
                elementAttr={{ class: "cardShadow" }}
                height="100%"
                width="100%"
            >
                <ItemBox baseSize={100}>
                    <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={100}>
                            <div style={{ display: "inline-block", position: "relative" }}>
                                <CircularGauge
                                    geometry={{
                                        startAngle: 90,
                                        endAngle: 90,
                                    }}
                                    rangeContainer={{
                                        width: 10,
                                        backgroundColor: "lightgrey",
                                        offset: 10,
                                    }}
                                    scale={{
                                        startValue: 0,
                                        endValue: 1,
                                        tick: { visible: false },
                                        label: { visible: false },
                                    }}
                                    valueIndicator={{
                                        type: "rangebar",
                                        color: this.getColor_porcentaje(e.val),
                                        offset: 0,
                                    }}
                                    value={e.val}
                                    size={{
                                        width: 100,
                                        height: 100,
                                    }}
                                ></CircularGauge>

                                <div className="porcentaje_modulo container_spanCentrado">
                                    <span>{formatNumber(e.val * 100, 2, "decimal")}</span>
                                </div>
                            </div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <div>
                                <div
                                    style={{
                                        textAlign: "right",
                                        padding: "10px 10px 0px 10px",
                                        fontSize: "30px",
                                        color: "#888888",
                                        fontWeigth: "lighter",
                                    }}
                                >
                                    {e.denominacion}
                                </div>
                                <div
                                    style={{
                                        textAlign: "right",
                                        padding: "0px 10px 10px 10px",
                                        fontSize: "24px",
                                        color: "#888888",
                                        fontWeigth: "lighter",
                                    }}
                                >
                                    {"(" + formatNumber(e.ponderacion, 0, "percent") + ")"}
                                </div>
                            </div>
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={10} />
                <ItemBox ratio={1}>
                    <List
                        dataSource={e.tblCriterio}
                        focusStateEnabled={false}
                        activeStateEnabled={false}
                        hoverStateEnabled={false}
                        height="100%"
                        keyExpr="idCriterioValoracion"
                        itemRender={this.dxList_aplicaciones_render}
                    />
                </ItemBox>
            </Box>
        );
    }

    //#endregion

    render() {
        if (this.state.tabPanel_data === null) return false;

        let _this = this;

        return (
            <Fragment>
                <PageTitle heading={getNombreFormulario(this)} />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
                    <div id="ControlUso" className="formContainer scrollbar-container">
                        <Box direction="col" align="space-around" crossAlign="stretch" height={"100%"}>
                            <ItemBox ratio={1}>
                                <TabPanel
                                    ref={this.tabPanelPrincipal_REF}
                                    height="100%"
                                    width="100%"
                                    deferRendering={false}
                                    animationEnabled={true}
                                    swipeEnabled={false}
                                    onSelectionChanged={this.dxTabPanel_onSelectionChanged}
                                >
                                    <TabPanelItem title={this.state.tabPanel_data[0].title} tabIndex={0}>
                                        <Box direction="col" align="space-around" crossAlign="stretch" height={"100%"}>
                                            <ItemBox baseSize={65}>
                                                <Box
                                                    direction="row"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    height={"100%"}
                                                >
                                                    <ItemBox ratio={1}>
                                                        <Box
                                                            direction="row"
                                                            dataSource={this.state.tabPanel_data[0].data.datosMacro}
                                                            itemRender={this.itemRender_datosMacro}
                                                            align="space-around"
                                                            crossAlign="stretch"
                                                            height={"100%"}
                                                            width={"100%"}
                                                        />
                                                    </ItemBox>
                                                    <ItemBox ratio={2}>
                                                        <Box
                                                            direction="row"
                                                            dataSource={this.state.tabPanel_data[0].data.navegadores}
                                                            itemRender={this.itemRender_navegadores}
                                                            align="end"
                                                            crossAlign="stretch"
                                                            height={"100%"}
                                                            width={"100%"}
                                                        />
                                                    </ItemBox>
                                                </Box>
                                            </ItemBox>
                                            <ItemBox ratio={1}>
                                                <Box
                                                    direction="row"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    height={"100%"}
                                                >
                                                    <ItemBox ratio={1}>
                                                        <DataGrid
                                                            dataSource={this.state.tabPanel_data[0].data.formularios}
                                                            height={"100%"}
                                                            width={"100%"}
                                                            hoverStateEnabled={true}
                                                            repaintChangesOnly={true}
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            columnAutoWidth={false}
                                                            rowAlternationEnabled={true}
                                                            onInitialized={(e) => {
                                                                this.gridFormularios_onInitialized(e, this);
                                                            }}
                                                            onToolbarPreparing={(e) => {
                                                                this.gridFormularios_onToolbarPreparing(e, this);
                                                            }}
                                                        >
                                                            <Scrolling mode="infinite" />
                                                            <FilterRow visible={true} />
                                                            <LoadPanel enabled={false} />
                                                            <Column
                                                                dataField="arg"
                                                                caption={getTrad("denominacion")}
                                                                minWidth="200px"
                                                            />
                                                            <Column
                                                                dataField="val"
                                                                caption={getTrad("consultas")}
                                                                alignment="center"
                                                                sortIndex={0}
                                                                sortOrder="desc"
                                                                allowFiltering={false}
                                                            />
                                                            <Column
                                                                dataField="porcentaje"
                                                                caption={getTrad("porcentaje")}
                                                                alignment="center"
                                                                allowFiltering={false}
                                                                customizeText={this.gridFormularios_customizeText}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        <DataGrid
                                                            dataSource={this.state.tabPanel_data[0].data.usuarios}
                                                            height={"100%"}
                                                            width={"100%"}
                                                            hoverStateEnabled={true}
                                                            repaintChangesOnly={true}
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            columnAutoWidth={false}
                                                            rowAlternationEnabled={true}
                                                            onInitialized={(e) => {
                                                                this.gridUsuarios_onInitialized(e, this);
                                                            }}
                                                            onToolbarPreparing={(e) => {
                                                                this.gridUsuarios_onToolbarPreparing(e, this);
                                                            }}
                                                        >
                                                            <Scrolling mode="infinite" />
                                                            <FilterRow visible={true} />
                                                            <LoadPanel enabled={false} />
                                                            <Column
                                                                dataField="nombre"
                                                                caption={getTrad("nombre")}
                                                                minWidth="200px"
                                                            />
                                                            <Column
                                                                dataField="numForms"
                                                                caption={getTrad("accesos")}
                                                                alignment="center"
                                                                sortIndex={0}
                                                                sortOrder="desc"
                                                                allowFiltering={false}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        <DataGrid
                                                            dataSource={this.state.tabPanel_data[0].data.lavanderia}
                                                            height={"100%"}
                                                            width={"100%"}
                                                            hoverStateEnabled={true}
                                                            repaintChangesOnly={true}
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            columnAutoWidth={false}
                                                            rowAlternationEnabled={true}
                                                            onInitialized={(e) => {
                                                                this.gridLavanderia_onInitialized(e, this);
                                                            }}
                                                            onToolbarPreparing={(e) => {
                                                                this.gridLavanderia_onToolbarPreparing(e, this);
                                                            }}
                                                        >
                                                            <Scrolling mode="infinite" />
                                                            <FilterRow visible={true} />
                                                            <LoadPanel enabled={false} />
                                                            <Column
                                                                dataField="denominacion"
                                                                caption={getTrad("denominacion")}
                                                                minWidth="200px"
                                                            />
                                                            <Column
                                                                dataField="numForms"
                                                                caption={getTrad("accesos")}
                                                                alignment="center"
                                                                sortIndex={0}
                                                                sortOrder="desc"
                                                                allowFiltering={false}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                </Box>
                                            </ItemBox>
                                        </Box>
                                    </TabPanelItem>
                                    <TabPanelItem title={this.state.tabPanel_data[1].title} tabIndex={1}>
                                        <Box
                                            direction="row"
                                            className="m-1"
                                            align="space-around"
                                            crossAlign="stretch"
                                            height={"100%"}
                                        >
                                            <ItemBox ratio={1}>
                                                <DataGrid
                                                    dataSource={this.state.tabPanel_data[1].data.usuarios}
                                                    height={"100%"}
                                                    width={"100%"}
                                                    elementAttr={{ class: "p-10" }}
                                                    selection={{ mode: "single" }}
                                                    sorting={{ mode: "multiple" }}
                                                    hoverStateEnabled={true}
                                                    repaintChangesOnly={true}
                                                    showColumnLines={false}
                                                    showRowLines={true}
                                                    columnAutoWidth={false}
                                                    rowAlternationEnabled={true}
                                                    onRowPrepared={(e) => {
                                                        this.gridUsuarios_usuarios_onRowPrepared(e, this);
                                                    }}
                                                    onToolbarPreparing={this.gridUsuarios_usuarios_onToolbarPreparing}
                                                    onSelectionChanged={(e) => {
                                                        this.gridUsuarios_usuarios_onSelectionChanged(e, this);
                                                    }}
                                                >
                                                    <Scrolling mode="infinite" />
                                                    <FilterRow visible={true} />
                                                    <LoadPanel enabled={false} />
                                                    <Column
                                                        dataField="nombre"
                                                        caption={getTrad("nombre")}
                                                        minWidth="200px"
                                                    />
                                                    <Column dataField="cargo" caption={getTrad("cargo")} />
                                                    <Column dataField="compañia" caption={getTrad("compañia")} />
                                                    <Column
                                                        dataField="numForms"
                                                        caption={getTrad("numForms")}
                                                        alignment="center"
                                                        sortIndex={0}
                                                        sortOrder="desc"
                                                        allowFiltering={false}
                                                    />
                                                    <Column
                                                        dataField="ultimaAccion"
                                                        caption={getTrad("ultimaAccion")}
                                                        alignment="center"
                                                        sortIndex={1}
                                                        sortOrder="desc"
                                                        allowFiltering={false}
                                                    />
                                                </DataGrid>
                                            </ItemBox>
                                            <ItemBox ratio={1}>
                                                <Box
                                                    direction="col"
                                                    align="space-around"
                                                    crossAlign="stretch"
                                                    elementAttr={{ class: "p-10" }}
                                                    height={"100%"}
                                                >
                                                    <ItemBox ratio={1}>
                                                        <Chart
                                                            id="dxChart_formularios"
                                                            palette="Soft Blue"
                                                            dataSource={this.state.tabPanel_data[1].data.usuarioChart}
                                                        >
                                                            <CommonSeriesSettings
                                                                valueField="val"
                                                                type="stackedBar"
                                                                label={{
                                                                    showForZeroValues: false,
                                                                    visible: true,
                                                                    border: {
                                                                        width: 1,
                                                                        color: "darkgrey",
                                                                        visible: true,
                                                                    },
                                                                    font: {
                                                                        color: "white",
                                                                    },
                                                                }}
                                                            />
                                                            <SeriesTemplate nameField="arg" />
                                                            <ArgumentAxis
                                                                label={{
                                                                    visible: false,
                                                                }}
                                                            />
                                                            <Legend
                                                                verticalAlignment="top"
                                                                horizontalAlignment="left"
                                                            />
                                                            <Tooltip
                                                                enabled={true}
                                                                location="edge"
                                                                contentRender={this.dxChart_tooltip_contentRender}
                                                            />
                                                            <LoadingIndicator
                                                                backgroundColor="none"
                                                                font={{
                                                                    size: 12,
                                                                }}
                                                            />
                                                        </Chart>
                                                    </ItemBox>
                                                    <ItemBox ratio={1}>
                                                        <DataGrid
                                                            dataSource={
                                                                this.state.tabPanel_data[1].data.usuarioDesglose
                                                            }
                                                            height={"100%"}
                                                            width={"100%"}
                                                            sorting={{ mode: "multiple" }}
                                                            repaintChangesOnly={true}
                                                            showColumnLines={false}
                                                            showRowLines={true}
                                                            columnAutoWidth={false}
                                                            rowAlternationEnabled={true}
                                                        >
                                                            <Scrolling mode="infinite" />
                                                            <LoadPanel enabled={false} />
                                                            <Column
                                                                dataField="text"
                                                                caption={getTrad("accion")}
                                                                minWidth="200px"
                                                            />
                                                            <Column
                                                                dataField="fecha"
                                                                caption={getTrad("fecha")}
                                                                format="dd/MM/yyyy - HH:mm"
                                                                alignment="center"
                                                                sortIndex={0}
                                                                sortOrder="desc"
                                                            />
                                                            <Column
                                                                dataField="segundosPantalla"
                                                                caption={getTrad("tiempoPantalla")}
                                                                alignment="center"
                                                                sortIndex={1}
                                                                sortOrder="desc"
                                                                customizeText={this.gridUsuarioDesglose_customizeText}
                                                            />
                                                        </DataGrid>
                                                    </ItemBox>
                                                </Box>
                                            </ItemBox>
                                        </Box>
                                    </TabPanelItem>
                                    {/* <TabPanelItem title={this.state.tabPanel_data[2].title} tabIndex={2}>
                                        <div style={{ padding: "0px 20px 20px 20px", height: "100%" }}>
                                            <Box
                                                direction="row"
                                                align="space-around"
                                                crossAlign="stretch"
                                                height={"100%"}
                                            >
                                                <ItemBox baseSize={350}>
                                                    <div style={{ paddingTop: "20px", height: "100%" }}>
                                                        <List
                                                            dataSource={this.state.tabPanel_data[2].data.lavanderias}
                                                            focusStateEnabled={false}
                                                            activeStateEnabled={false}
                                                            height={"100%"}
                                                            keyExpr="idLavanderia"
                                                            selectionMode="single"
                                                            itemRender={this.dxList_lavanderias_itemRender}
                                                            onSelectionChanged={
                                                                this.dxList_lavanderias_onSelectionChanged
                                                            }
                                                            onContentReady={this.dxList_lavanderias_onContentReady}
                                                        ></List>
                                                    </div>
                                                </ItemBox>
                                                <ItemBox ratio={1}>
                                                    <TileView
                                                        dataSource={this.state.tabPanel_data[2].data.modulosCriterios}
                                                        width={"100%"}
                                                        direction="vertical"
                                                        itemMargin={16}
                                                        showScrollbar={true}
                                                        itemRender={this.dxTileView_lavanderias_itemRender}
                                                        onContentReady={this.dxTileView_lavanderias_onContentReady}
                                                        onItemRendered={this.dxTileView_lavanderias_onItemRendered}
                                                    />
                                                </ItemBox>
                                            </Box>
                                        </div>
                                    </TabPanelItem> */}
                                </TabPanel>
                            </ItemBox>
                            <ItemBox baseSize={150}>
                                <Box
                                    direction="row"
                                    align="space-around"
                                    crossAlign="stretch"
                                    height={"100%"}
                                    elementAttr={{ id: "dxBox_filtroTiempo" }}
                                >
                                    <ItemBox baseSize={50}>
                                        <Box direction="col" width={"100%"} height={"100%"}>
                                            <ItemBox ratio={1}>
                                                <Button
                                                    tooltipText={getTrad("mensual")}
                                                    height="100%"
                                                    width="100%"
                                                    icon="font-size-md icon_CalendarioMes"
                                                    elementAttr={{
                                                        id: "dxButton_mensual",
                                                        class:
                                                            this.state.rangeSelector["tipo"] === "anual"
                                                                ? "dxButton_activo"
                                                                : "",
                                                    }}
                                                    onInitialized={this.dxButtonMensual_onInitialized}
                                                    onClick={this.dxButtonMensual_onClick}
                                                ></Button>
                                            </ItemBox>
                                            <ItemBox baseSize={5} />
                                            <ItemBox ratio={1}>
                                                <Button
                                                    tooltipText={getTrad("diario")}
                                                    height="100%"
                                                    width="100%"
                                                    icon="font-size-md icon_CalendarioBlanco"
                                                    elementAttr={{
                                                        id: "dxButton_diario",
                                                        class:
                                                            this.state.rangeSelector["tipo"] !== "anual"
                                                                ? "dxButton_activo"
                                                                : "",
                                                    }}
                                                    onInitialized={this.dxButtonDiario_onInitialized}
                                                    onClick={this.dxButtonDiario_onClick}
                                                ></Button>
                                            </ItemBox>
                                        </Box>
                                    </ItemBox>
                                    <ItemBox baseSize={15} />
                                    <ItemBox ratio={1}>
                                        <ScrollView
                                            elementAttr={{ id: "scrollView_dxRangeSelector" }}
                                            showScrollbar="always"
                                            direction="horizontal"
                                            height="100%"
                                            width="100%"
                                        >
                                            <RangeSelector
                                                elementAttr={{ id: "dxRangeSelector_filtroTiempo" }}
                                                behavior={{
                                                    animationEnabled: false,
                                                    callValueChanged:
                                                        this.state.rangeSelector.behavior.callValueChanged,
                                                }}
                                                defaultValue={[
                                                    this.data.fechaSel["desde"],
                                                    this.data.fechaSel["hasta"],
                                                ]}
                                                valueHasta={
                                                    new Date(
                                                        this.datasource_datos_filtroTiempo[
                                                            this.datasource_datos_filtroTiempo.length
                                                        ]
                                                    )
                                                } // value[1] , sirve para por ejemplo mensual restar al value 1 dia y asignar la fecha a este, asi no desvirtua la fecha visible.
                                                dataSource={this.datasource_datos_filtroTiempo.items()}
                                                mesesVisibles={{ diario: 1, mensual: 1, anual: 12 }}
                                                is_anual={this.state.rangeSelector["tipo"] === "anual"}
                                                is_mensual={this.state.rangeSelector["tipo"] === "mensual"}
                                                size={{
                                                    height: 150,
                                                }}
                                                sliderMarker={{
                                                    format: this.state.rangeSelector.sliderMarker.format,
                                                    paddingLeftRight:
                                                        this.state.rangeSelector.sliderMarker.paddingLeftRight,
                                                    customizeText: function (e) {
                                                        if (
                                                            typeof $("#dxRangeSelector_filtroTiempo").data(
                                                                "dxRangeSelector"
                                                            ) !== "undefined" &&
                                                            $("#dxRangeSelector_filtroTiempo")
                                                                .data("dxRangeSelector")
                                                                .option("value") &&
                                                            ($("#dxRangeSelector_filtroTiempo")
                                                                .dxRangeSelector("instance")
                                                                .option("scale.tickInterval") === "month" ||
                                                                $("#dxRangeSelector_filtroTiempo")
                                                                    .dxRangeSelector("instance")
                                                                    .option("is_daytoDaySelection") ||
                                                                $("#dxRangeSelector_filtroTiempo")
                                                                    .dxRangeSelector("instance")
                                                                    .option("scale.tickInterval") === "year") // si es mensual, o es day to day o es anual
                                                        ) {
                                                            dxRangeSelector_PosicionValor_nuevoMarker(
                                                                $("#dxRangeSelector_filtroTiempo")
                                                            );
                                                        } else return e.valueText;
                                                    },
                                                }}
                                                scale={{
                                                    tickInterval: this.state.rangeSelector.scale.tickInterval,
                                                    minRange: this.state.rangeSelector.scale.minRange,
                                                    maxRange: this.state.rangeSelector.scale.maxRange,
                                                    startValue: this.state.rangeSelector.scale.startValue,
                                                    endValue: this.state.rangeSelector.scale.endValue,
                                                    minorTickInterval: this.state.rangeSelector.scale.minorTickInterval,
                                                    aggregationInterval:
                                                        this.state.rangeSelector.scale.aggregationInterval,
                                                    valueType: "datetime",
                                                    minorTick: {
                                                        visible: false,
                                                    },
                                                    marker: {
                                                        label: {
                                                            customizeText: function (e) {
                                                                if (_this.state.rangeSelector["tipo"] === "anual") {
                                                                    return e.valueText;
                                                                } else {
                                                                    return (
                                                                        capitalize(
                                                                            new Intl.DateTimeFormat(locale(), {
                                                                                month: "long",
                                                                            }).format(e.value)
                                                                        ) +
                                                                        " (" +
                                                                        e.value.getFullYear() +
                                                                        ")"
                                                                    );
                                                                }
                                                            },
                                                        },
                                                    },
                                                    label: {
                                                        customizeText: function (e) {
                                                            if (_this.state.rangeSelector["tipo"] === "anual") {
                                                                var fecha = new Date(e.value);
                                                                var mes = fecha.toLocaleString("es-ES", {
                                                                    month: "short",
                                                                });
                                                                var año = fecha.getFullYear();
                                                                var text = mes;

                                                                if (año !== new Date().getFullYear()) {
                                                                    var displayYear =
                                                                        " '" +
                                                                        e.value.getFullYear().toString().substr(-2);
                                                                    text += " " + displayYear;
                                                                }

                                                                return text.toUpperCase();
                                                            }
                                                            return e.valueText;
                                                        },
                                                    },
                                                    endValue: _this.state.tabPanel_data.endValue,
                                                }}
                                                onOptionChanged={this.dxRangeSelector_onOptionChanged}
                                                onDrawn={this.dxRangeSelector_onDrawn}
                                                onValueChanged={this.dxRangeSelector_onValueChanged}
                                            >
                                                <SliderMarker format="monthAndDay" />
                                            </RangeSelector>
                                        </ScrollView>
                                    </ItemBox>
                                </Box>
                            </ItemBox>
                        </Box>
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    //#region Eventos

    dxTabPanel_onSelectionChanged(e) {
        let is_disabled = false;
        if (e.component.option("selectedIndex") === 2) {
            $("#dxButton_mensual").removeClass("dxButton_activo");

            let rangeSelector = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance");
            let datasource_items = rangeSelector.option("dataSource");
            let ultimaFecha = new Date(datasource_items[datasource_items.length - 1].arg);

            this.data.fechaSel["desde"] = new Date(new Date(ultimaFecha).setDate(new Date(ultimaFecha).getDate() - 1));
            this.data.fechaSel["hasta"] = new Date(ultimaFecha);

            rangeSelector.option("value", [this.data.fechaSel["desde"], this.data.fechaSel["hasta"]]);

            let obj = this.state.rangeSelector;
            obj["tipo"] = "diario";
            obj.scale["tickInterval"] = "day";

            obj.scale["aggregationInterval"] = "month";

            obj.scale["minRange"] = { days: 1, months: null };
            obj.scale["maxRange"] = "day";
            obj.scale["startValue"] = new Date(datasource_items[0].arg);
            obj.scale["endValue"] = ultimaFecha;
            obj.scale["valueHasta"] = ultimaFecha;
            obj.scale["minorTickInterval"] = { days: 1, months: null };

            obj.sliderMarker["format"] = "monthAndDay";
            obj.sliderMarker["paddingLeftRight"] = 30;
            obj.behavior["callValueChanged"] = "onMovingComplete";

            this.setState({
                rangeSelector: obj,
            });

            init_CustomMarker_RangeSelector($("#dxRangeSelector_filtroTiempo"));
            dxRangeSelector_PosicionValor_nuevoMarker($("#dxRangeSelector_filtroTiempo"), false, false);
            filtroTiempo_Resize();

            is_disabled = true;
        } else if (e.removedItems.length > 0 && e.removedItems[0].tabIndex === 2) {
            let rangeSelector = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance");
            let datasource_items = rangeSelector.option("dataSource");
            let ultimaFecha = new Date(datasource_items[datasource_items.length - 1].arg);

            let obj = this.state.rangeSelector;
            obj["tipo"] = "mensual";
            obj.scale["tickInterval"] = "day";

            obj.scale["aggregationInterval"] = "month";

            obj.scale["minRange"] = { days: 1, months: null };
            obj.scale["maxRange"] = e.component.option("is_daytoDaySelection") ? "day" : null;
            obj.scale["startValue"] = new Date(datasource_items[0].arg);
            obj.scale["endValue"] = ultimaFecha;
            obj.scale["valueHasta"] = ultimaFecha;
            obj.scale["minorTickInterval"] = { days: 1, months: null };

            obj.sliderMarker["format"] = "monthAndDay";
            obj.sliderMarker["paddingLeftRight"] = 30;
            obj.behavior["callValueChanged"] = "onMovingComplete";

            this.setState({
                rangeSelector: obj,
            });

            remove_CustomMarker_RangeSelector($("#dxRangeSelector_filtroTiempo"));
            filtroTiempo_Resize();
        }

        $("#dxButton_mensual").dxButton("instance").option("disabled", is_disabled);
    }

    dxButtonMensual_onInitialized(e) {
        tooltipControl_creacion(e.element, e.component.option("tooltipText"));
    }

    dxButtonDiario_onInitialized(e) {
        tooltipControl_creacion(e.element, e.component.option("tooltipText"));
    }

    dxButtonMensual_onClick(e) {
        if (this.state.rangeSelector["tipo"] !== "anual") {
            if (!e.element.hasClass("dxButton_activo")) {
                $(".dxButton_activo").removeClass("dxButton_activo");
                e.element.addClass("dxButton_activo");
            }

            let rangeSelector = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance");
            let datasource_items = rangeSelector.option("dataSource");
            let ultimaFecha = new Date(datasource_items[datasource_items.length - 1].arg);

            let obj = this.state.rangeSelector;
            obj["tipo"] = "anual";
            obj.scale["tickInterval"] = "month";
            obj.scale["aggregationInterval"] = "month";
            obj.scale["minorTickInterval"] = { months: 2 };
            obj.scale["minRange"] = { days: null, months: 2 };
            obj.scale["maxRange"] = null;
            obj.scale["startValue"] = new Date(datasource_items[0].arg);
            obj.scale["endValue"] = endOfMonth(ultimaFecha);
            obj.scale["valueHasta"] = endOfMonth(ultimaFecha);
            obj.sliderMarker["format"] = "month";
            obj.sliderMarker["paddingLeftRight"] = 15;

            this.setState({
                rangeSelector: obj,
            });

            //#region Asignación valor Range selector
            let d = new Date(ultimaFecha);
            this.data.fechaSel["desde"] = startOfMonth(new Date(new Date(d).setMonth(new Date(d).getMonth() - 1)));
            this.data.fechaSel["hasta"] = endOfMonth(new Date(d));

            rangeSelector.option("value", [this.data.fechaSel["desde"], endOfMonth(this.data.fechaSel["hasta"])]);
            //#endregion

            init_CustomMarker_RangeSelector($("#dxRangeSelector_filtroTiempo"));
            dxRangeSelector_PosicionValor_nuevoMarker($("#dxRangeSelector_filtroTiempo"), true, false);

            filtroTiempo_Resize();
        }
    }

    dxButtonDiario_onClick(e) {
        if (this.state.rangeSelector["tipo"] === "anual") {
            if (!e.element.hasClass("dxButton_activo")) {
                $(".dxButton_activo").removeClass("dxButton_activo");
                e.element.addClass("dxButton_activo");
            }

            let rangeSelector = $("#dxRangeSelector_filtroTiempo").dxRangeSelector("instance");
            let datasource_items = rangeSelector.option("dataSource");
            let ultimaFecha = new Date(datasource_items[datasource_items.length - 1].arg);

            var hoy = new Date(new Date().setHours(0, 0, 0, 0));
            var ayer = new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1));

            let d = new Date(ultimaFecha);
            this.data.fechaSel["hasta"] = hoy;
            this.data.fechaSel["desde"] = ayer;

            rangeSelector.option("value", [this.data.fechaSel["desde"], this.data.fechaSel["hasta"]]);

            let obj = this.state.rangeSelector;
            obj["tipo"] = "mensual";
            obj.scale["tickInterval"] = "day";

            obj.scale["aggregationInterval"] = "month";

            obj.scale["minRange"] = { days: 1, months: null };
            obj.scale["maxRange"] = e.component.option("is_daytoDaySelection") ? "day" : null;
            obj.scale["startValue"] = new Date(datasource_items[0].arg);
            obj.scale["endValue"] = ultimaFecha;
            obj.scale["valueHasta"] = ultimaFecha;
            obj.scale["minorTickInterval"] = { days: 1, months: null };

            obj.sliderMarker["format"] = "monthAndDay";
            obj.sliderMarker["paddingLeftRight"] = 30;
            obj.behavior["callValueChanged"] = "onMovingComplete";

            this.setState({
                rangeSelector: obj,
            });

            remove_CustomMarker_RangeSelector($("#dxRangeSelector_filtroTiempo"));

            filtroTiempo_Resize();
        }
    }

    dxRangeSelector_onDrawn(e) {
        if (
            e.component.option("scale.tickInterval") === "month" ||
            e.component.option("scale.tickInterval") === "year"
        ) {
            customMonthViewer_rangeSelector();
        } else {
            selectorMeses_dxRangeSelector(e.component);
        }
    }

    dxRangeSelector_onValueChanged(e) {
        let _this = this;
        _this.data.fechaSel["desde"] = new Date(e.value[0]);
        _this.data.fechaSel["hasta"] = new Date(e.value[1]);

        if (this.data.timeout_RangeSelector_valueChanged) clearTimeout(this.data.timeout_RangeSelector_valueChanged);

        e.component.option("valueHasta", new Date(e.value[1]));

        if (
            e.component.option("scale.tickInterval") === "month" ||
            e.component.option("scale.tickInterval") === "year"
        ) {
            //MENSUAL
            if (e.value[1].getDate() === 1) {
                var d = new Date(e.value[1]);
                e.component.option("valueHasta", new Date(d.setDate(d.getDate() - 1)));
            }
            dxRangeSelector_PosicionValor_nuevoMarker(e.element);
        }

        _this.loadPanel_show(true);
        _this.dataSource_tabPanel.load();
    }

    gridFormularios_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                template: function (e) {
                    return $("<div />").addClass("font-size").text(getTrad("formularios"));
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: function () {
                        e.component.clearFilter();
                    },
                },
            }
        );
    }

    gridFormularios_onInitialized(e) {
        e.element.css("padding", 10);
    }

    gridUsuarios_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                template: function (e) {
                    return $("<div />").addClass("font-size").text(getTrad("usuarios"));
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: function () {
                        e.component.clearFilter();
                    },
                },
            }
        );
    }

    gridUsuarios_onInitialized(e) {
        e.element.css("padding", 10);
    }

    gridLavanderia_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "before",
                template: function (e) {
                    return $("<div />").addClass("font-size").text(getTrad("lavanderia"));
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: function () {
                        e.component.clearFilter();
                    },
                },
            }
        );
    }

    gridLavanderia_onInitialized(e) {
        e.element.css("padding", 10);
    }

    gridUsuarios_usuarios_onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            showText: "inMenu",
            locateInMenu: "auto",
            options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onClick: function () {
                    e.component.clearFilter();
                },
            },
        });
    }

    gridUsuarios_usuarios_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");
        }
    }

    gridUsuarios_usuarios_onSelectionChanged(e) {
        let _this = this;
        if (e.selectedRowsData.length > 0) {
            _this.data.idUsuarioSel = e.selectedRowsData[0].idUsuario;
            $.when(
                _this.datasource_Usuarios_DxDataGrid_UsuariosDesglose.load(),
                _this.datasource_Usuarios_DxChart_Formularios.load()
            ).then(function (items_usuarioDesglose, items_usuarioChart) {
                let obj = _this.state.tabPanel_data;
                obj[1].data.usuarioDesglose = items_usuarioDesglose[0];
                obj[1].data.usuarioChart = items_usuarioChart[0];
                _this.setState({ tabPanel_data: obj });
            });
        } else {
            this.data.idUsuarioSel = -1;
        }
    }

    dxList_lavanderias_onContentReady(e) {
        e.component.selectItem(0);
    }

    dxList_lavanderias_onSelectionChanged(e) {
        let _this = this;
        if (e.addedItems.length > 0) {
            if (e.addedItems[0].idLavanderia !== _this.data.idLavSel) {
                _this.data.idLavSel = e.addedItems[0].idLavanderia;
                _this.loadPanel_show(true);
                // _this.datasource_Lavanderias_ModulosCriterios.load();
            }
        }
    }

    dxTileView_lavanderias_onItemRendered(e) {
        if (e.element.height() > 0) {
            var width = e.element.width();

            var numItems = this.state.tabPanel_data[2].data.modulosCriterios.length;
            var numCols = width >= 1100 ? 3 : width >= 750 ? 2 : 1;
            var numRows = Math.ceil(numItems / numCols);

            var totalEspacioItems = numCols * numRows;

            e.itemData.heightRatio = 1;
            if (numItems < totalEspacioItems) {
                var espaciosVacios = totalEspacioItems - numItems;
                for (var i = 0; i < espaciosVacios; i++) {
                    if (i === e.itemIndex) {
                        e.itemData.heightRatio = 2;
                    }
                }
            }
        }
    }

    dxTileView_lavanderias_onContentReady(e) {
        let rootElement = e.element;
        if (rootElement.height() > 0) {
            var height = rootElement.height();
            var width = rootElement.width();
            var numItems = this.state.tabPanel_data[2].data.modulosCriterios.length;
            var numCols = width >= 1100 ? 3 : width >= 750 ? 2 : 1;
            var numRows = Math.ceil(numItems / numCols);

            var itemMargin = e.component.option("itemMargin");
            var itemMarginHeight = itemMargin * 2 - (itemMargin / 2) * (numRows - 1);

            var itemWidth = Math.floor(width / numCols) - itemMargin;
            var itemHeight = Math.floor(height / numRows) - itemMarginHeight;

            var itemMinHeight = 290;
            itemHeight = itemHeight < itemMinHeight ? itemMinHeight : itemHeight;
            e.component.beginUpdate();
            e.component.option("baseItemWidth", itemWidth);
            e.component.option("baseItemHeight", itemHeight);
            e.component.endUpdate();
        }
    }

    //#endregion

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        let _this = this;
        this.datasource_datos_filtroTiempo.load().done(function () {
            _this.dataSource_tabPanel.load().done(function () {
                setTimeout(function () {
                    filtroTiempo_Resize();
                    _this.loadPanel_hide();
                }, 2000);
            });
        });
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlUso);
