import React from "react";

import { connect } from "react-redux";

import AsientosNominas_RD from "./AsientosNominas_RD";
import AsientosNominas from "./AsientosNominas";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import { authHeader, errorHandler } from "helpers";

class AsientosNominas_lobby extends React.Component {
    constructor(props) {
        super(props);
        this.datasource_tblMoneda;
    }

    authHeaderAdd = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblMoneda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMoneda",
            // key: "idMoneda",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.authHeaderAdd(request);
            },
            version: 4,
        }),
    });

    componentDidMount() {
        this.datasource_tblMoneda.load();
    }

    getSymbol = (idMoneda) => {
        return this.datasource_tblMoneda.items().find((moneda) => moneda.idMoneda === idMoneda)?.codigo || "EUR";
    };

    render() {
        const { idEmpresaPolarier } = this.props.empresa;
        return (
            <>
                {idEmpresaPolarier === 4 ? (
                    <AsientosNominas_RD getSymbol={this.getSymbol} />
                ) : (
                    <AsientosNominas getSymbol={this.getSymbol} />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    empresa: state.Global.empresa,
});

export default connect(mapStateToProps)(AsientosNominas_lobby);
