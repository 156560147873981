import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Col, Row, Form, FormGroup, Alert } from "reactstrap";
import { connect } from "react-redux";

import { userActions } from "../../../actions";
import { userService } from "../../../services";

import { Button } from "devextreme-react";
import Loader from "react-loaders";
import { TextBox } from "devextreme-react/text-box";
import { Validator, AsyncRule, RequiredRule } from "devextreme-react/validator";
import ValidationGroup from "devextreme-react/validation-group";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    //Reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
      username: "",
      password: "",
      userCorp: null,
    };

    //Referencias
    this.textBox_username_REF = React.createRef();
    this.validationGroup_REF = React.createRef();

    this.handleChange = this.handleChange.bind(this);
    this.disablePaste = this.disablePaste.bind(this);
    this.asyncValidation = this.asyncValidation.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  get textBox_username() {
    return this.textBox_username_REF.current.instance;
  }

  get validationGroup() {
    return this.validationGroup_REF.current.instance;
  }

  handleChange(e) {
    const { name, value } = e.event.target;
    this.setState({ [name]: value });
  }

  disablePaste(e) {
    e.event.preventDefault();
  }

  asyncValidation = async (params) => {
    let value = params.value.replace(/\s+/g, " ").trim();
    let result = await userService.validateEmailUsuario(value);
    this.setState({ userCorp: result.userCorp });
    return result;
  };

  onSubmit() {
    const { dispatch } = this.props;
    const res = this.validationGroup.validate();
    res.status === "pending" &&
      res.complete.then((r) => {
        if (r.isValid) {
          const { username, password, userCorp } = this.state;
          if (userCorp == "PolarierUser") {
            dispatch(userActions.login(username, password));
          } else {
            const { host, protocol } = window.location;
            const credentials = btoa(
              btoa(JSON.stringify({ email: username, password: password })),
            );
            const url = protocol + "//sas-" + host + "/Redirect/" + credentials;
            window.location.replace(url);
          }
        }
      });
  }

  render() {
    const { message } = this.props.match.params;
    const { loggingIn, loggingError } = this.props; //Estado "loading" al hacer el request
    const { username, password } = this.state;

    return (
      <Fragment>
        <div className="app-container">
          <div className="he-100 bg-animation bg-white">
            <div className="d-flex he-100 justify-content-center align-items-center">
              <Col md="6" className="mx-auto app-login-box">
                <div className="logo-LoveYourLinen mx-auto mb-3" />
                {!loggingIn ? (
                  <div className="w-100 mx-auto modal-dialog">
                    <div
                      className={
                        "modal-content " +
                        (loggingError || message === "SesionCaducada"
                          ? "warning"
                          : "")
                      }
                    >
                      <ValidationGroup ref={this.validationGroup_REF}>
                        <div className="modal-body">
                          <div className="modal-title text-center mb-4">
                            <h4 className="mt-2">
                              <div>Bienvenido</div>
                            </h4>
                            <h6 className="mt-1">
                              <span>
                                Por favor, indique sus credenciales para entrar.
                              </span>
                            </h6>
                          </div>
                          {loggingError ? (
                            <Alert className="w-100" color="danger">
                              <div className="text-center">
                                <span>
                                  Las credenciales introducidas no son
                                  correctas.
                                </span>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          {message === "SesionCaducada" ? (
                            <Alert className="w-100" color="danger">
                              <div className="text-center">
                                <span>La sesión ha caducado.</span>
                              </div>
                            </Alert>
                          ) : (
                            ""
                          )}
                          <Form>
                            <Row form>
                              <Col md={12} className="mt-3">
                                <FormGroup>
                                  <span>Usuario</span>
                                  <TextBox
                                    ref={this.textBox_username_REF}
                                    name="username"
                                    stylingMode="underlined"
                                    placeholder="Introduzca usuario o email..."
                                    value={username}
                                    onChange={this.handleChange}
                                    onEnterKey={this.onSubmit}
                                  >
                                    <Validator>
                                      <RequiredRule message="Campo necesario" />
                                      <AsyncRule
                                        message="El nombre de usuario indicado no se encuentra registrado."
                                        validationCallback={
                                          this.asyncValidation
                                        }
                                      />
                                    </Validator>
                                  </TextBox>
                                </FormGroup>
                              </Col>
                              <Col md={12} className="mt-3">
                                <FormGroup>
                                  <span>Contraseña</span>
                                  <TextBox
                                    name="password"
                                    stylingMode="underlined"
                                    placeholder="Introduzca contraseña..."
                                    value={password}
                                    mode="password"
                                    onPaste={this.disablePaste}
                                    onChange={this.handleChange}
                                    onEnterKey={this.onSubmit}
                                  >
                                    <Validator>
                                      <RequiredRule message="Campo necesario" />
                                    </Validator>
                                  </TextBox>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <div className="modal-footer clearfix">
                          <div className="float-left w-100">
                            <h6 className="mb-0">
                              <Link
                                to={`/ForgotPassword`}
                                className={
                                  "text-" +
                                  (!loggingError ? "primary" : "success")
                                }
                              >
                                ¿Has olvidado la contraseña?
                              </Link>
                            </h6>
                          </div>
                          <div className="float-right ">
                            <Button
                              width={100}
                              text="Entrar"
                              type="default"
                              stylingMode="contained"
                              onClick={this.onSubmit}
                            />
                          </div>
                        </div>
                      </ValidationGroup>
                    </div>
                  </div>
                ) : (
                  <div className="loader-container">
                    <div className="loader-container-inner m-5">
                      <div className="text-center">
                        <Loader type="line-scale" />
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center opacity-8 mt-3">
                  <a href="https://www.polarier.com/aviso-legal/ ">
                    Copyright &copy; Polarier {new Date().getFullYear()}{" "}
                  </a>
                </div>
              </Col>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    this.textBox_username.focus();

    const { dispatch } = this.props;
    const { username, password } = this.props.match.params;
    if (username && password) {
      dispatch(userActions.login(username, password));
    }
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggingError } = state.Authentication;
  return {
    loggingIn,
    loggingError,
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export default connectedLoginPage;
