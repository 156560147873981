import React, { useEffect, useState } from "react";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import Box, { Item } from "devextreme-react/box";
import Popup from "devextreme-react/popup";

import $ from "jquery";

import {
  authHeader,
  dxMensajePregunta,
  errorHandler,
  formatDate,
  getTrad,
} from "helpers";
import { connectionConstants } from "constants";
import { List } from "devextreme-react";

const idsEstado = {
  cierreNomina: 14,
};

const idsEstado_unoPorMes = [
  //* Estados que solo pueden tener un evento por mes
  idsEstado.cierreNomina,
];

const PopupEditarEstado = ({
  visible = false,
  seleccion,
  onSave,
  onHiding,
}) => {
  const [tblCalendarioPersonal_Estado, setTblCalendarioPersonal_Estado] =
    useState([]);
  const [
    tblCalendarioPersonal_Estado_filtered,
    setTblCalendarioPersonal_Estado_filtered,
  ] = useState([]);

  useEffect(() => {
    datasource_tblCalendarioPersonal_Estado
      .load()
      .then(setTblCalendarioPersonal_Estado);
  }, []);

  useEffect(() => {
    const tblCalendarioPersonal_Estado_filtered =
      tblCalendarioPersonal_Estado.filter(
        (cpe) =>
          (seleccion.startDate !== seleccion.endDate &&
            !idsEstado_unoPorMes.includes(cpe.idEstado)) ||
          (seleccion.startDate === seleccion.endDate &&
            idsEstado_unoPorMes.includes(cpe.idEstado)),
      );
    setTblCalendarioPersonal_Estado_filtered(
      tblCalendarioPersonal_Estado_filtered,
    );
  }, [seleccion]);

  const datasource_tblCalendarioPersonal_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCalendarioPersonal_Estado",
      key: "idEstado",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    sort: ["denominacion"],
    expand: ["idTraduccionNavigation"],
    filter: [
      ["isLavanderia", "=", true],
      "and",
      ["idEstado", "=", idsEstado.cierreNomina],
    ],
  });

  const onContentReady = (e) => {
    let groupHeader = $($(e.element)[0]).find(".dx-list-group-header");

    $.each(groupHeader, (index, item) => {
      if (index === 0) $(item).css("padding-top", "0px");
    });
    e.element.css("opacity", "1");
  };

  const onSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      const formatData = () => {
        const { startDate, endDate, idsLavanderia } = seleccion;

        const idEstado = e.addedItems[0].idEstado;
        const tblCalendarioLavanderia = [];

        for (const idLavanderia of idsLavanderia) {
          let fechaActual = new Date(startDate);

          while (fechaActual <= endDate) {
            const objetoFecha = {
              fecha: new Date(fechaActual), // Clonar la fecha para evitar referencias
              idEstado,
              idLavanderia,
            };

            tblCalendarioLavanderia.push(objetoFecha);

            fechaActual.setDate(fechaActual.getDate() + 1);
          }
        }

        onSave(new Date(startDate).getMonth(), tblCalendarioLavanderia);
        onHiding();
      };

      dxMensajePregunta(
        `Estás a punto de eliminar el resto de eventos de las lavanderías seleccionadas del mes con estado de <strong>"${e.addedItems[0].denominacion}"</strong>. ¿Deseas continuar?`,
        [
          [getTrad("aceptar"), formatData, "danger"],
          [getTrad("cancelar"), () => {}],
        ],
      );
    }
  };

  return (
    <Popup
      visible={visible}
      height={500}
      width={350}
      title={getTrad("selTipoDiaLibre")}
      onHiding={onHiding}
    >
      <Box
        direction={"col"}
        align={"space-around"}
        crossAlign={"stretch"}
        height={"100%"}
        width={"100%"}
      >
        <Item baseSize={40}>
          <Box
            direction={"row"}
            align={"space-around"}
            crossAlign={"stretch"}
            height={"100%"}
            width={"100%"}
            style={{ borderBottom: "1px var(--primary) solid" }}
          >
            <Item baseSize={30}>
              <div
                className={
                  "dx-icon-event he-100 d-flex align-items-center justify-content-start font-size-xl pt-1"
                }
              />
            </Item>
            <Item ratio={1}>
              <div
                className={
                  "he-100 d-flex align-items-center font-size-lg p-2 title-dxpopup-fecha"
                }
              >
                {seleccion
                  ? seleccion.startDate === seleccion.endDate
                    ? formatDate(seleccion.startDate)
                    : formatDate(seleccion.startDate) +
                      " - " +
                      formatDate(seleccion.endDate)
                  : ""}
              </div>
            </Item>
          </Box>
        </Item>
        <Item baseSize={15} />
        <Item ratio={1}>
          <List
            dataSource={tblCalendarioPersonal_Estado_filtered}
            keyExpr={"idEstado"}
            displayExpr={"denominacion"}
            selectionMode={"single"}
            noDataText={getTrad("sinEventos")}
            selectedItemKeys={[]}
            onContentReady={onContentReady}
            onSelectionChanged={onSelectionChanged}
          />
        </Item>
      </Box>
    </Popup>
  );
};

export default PopupEditarEstado;
