import React, { useEffect, useState } from "react";

import $ from "jquery";

import ODataContext from "devextreme/data/odata/context";

import DataSource from "devextreme/data/data_source";
import NewYearCalendar from "components/NewYearCalendar";
import ODataStore from "devextreme/data/odata/store";
import TooltipDayEvents from "./components/TooltipDayEvents";
import { connectionConstants } from "constants";
import { errorHandler, authHeader, formatDate_noTime_parameter, startOfYear, endOfYear } from "helpers";
import OverlayCalendarioPersonal from "./components/OverlayCalendarioPersonal";
import "./css.scss";

const today = new Date();
let tooltipDelay;

const idsEstado = {
    trabajado: 3,
    festivoTrabajado: 4,
};

const CalendarioPersonal = ({ idPersona, showLoader = true, ts, ...newYearCalendarProps }) => {
    const [persona, setPersona] = useState(null);
    const [eventos, setEventos] = useState([]);
    const [year, setYear] = useState(today.getFullYear());
    const [tooltipTarget, setTooltipTarget] = useState(null);
    const [targetEvents, setTargetEvents] = useState(null);
    const [isLoading, setIsLoading] = useState(showLoader);

    useEffect(() => {
        if (idPersona != null) {
            datasource_tblPersona.load().done((data) => {
                data.forEach((p) => {
                    p.nombreCompleto = `${p.nombre} ${p.apellidos}`;

                    const contratos = p.tblPersonaNTipoContrato ?? [];
                    const primerContrato = contratos[0];
                    const ultimoContrato = contratos[contratos.length - 1];

                    p.fechaAlta = primerContrato?.fechaAltaContrato
                        ? new Date(`${primerContrato.fechaAltaContrato} 00:00:00`)
                        : null;
                    p.fechaBaja = ultimoContrato?.fechaBajaContrato
                        ? new Date(`${ultimoContrato.fechaBajaContrato} 00:00:00`)
                        : null;

                    delete p.tblPersonaNTipoContrato;
                });

                setPersona(data[0]);
            });
        } else {
            setPersona(null);
            setEventos([]);
        }
    }, [idPersona]);

    useEffect(() => {
        if (idPersona != null) {
            setIsLoading(showLoader);

            context_calendario.invoke("GetCalendarioPersonal", {}, "GET").done((data) => {
                data = context_calendario_postProcess(data);
                setIsLoading(false);
                setEventos(data);
            });
        }
    }, [idPersona, year]);

    const context_calendario = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "RRHH/Calendario/",
        entities: {
            GetCalendarioPersonal: {},
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => context_calendario_beforeSend(request),
    });

    const context_calendario_beforeSend = (request) => {
        let date = new Date(year, 0, 1);

        request.headers = { ...authHeader() };
        request.params.idPersona = idPersona;

        request.params.fechaDesde = formatDate_noTime_parameter(startOfYear(date));
        request.params.fechaHasta = formatDate_noTime_parameter(endOfYear(date));
    };

    const context_calendario_postProcess = (data) => {
        data = data.map((ep) => {
            ep.fecha = new Date(ep.fecha.split("T")[0]);
            ep.fecha.setHours(0, 0, 0, 0);
            return ep;
        });

        return data;
    };

    const datasource_tblPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            onLoading: (loadOptions) => datasource_tblPersona_onLoading(loadOptions),
            version: 4,
        }),
        select: ["idPersona", "nombre", "apellidos", "idLavanderia, idTurno"],
        expand: ["tblPersonaNTipoContrato($select=fechaAltaContrato, fechaBajaContrato;$orderby=fechaAltaContrato)"],
    });

    const datasource_tblPersona_onLoading = (loadOptions) => {
        const filter = ["idPersona", "=", idPersona];

        if (loadOptions.filter) loadOptions.filter = [loadOptions.filter, "and", filter];
        else loadOptions.filter = filter;
    };

    const dayRender = (element, date, eventos) => {
        const evento = eventos[0];
        let parentElement = $(element).parent();

        let hasCierre = eventos.filter((e) => e.idEstado === 10).length > 0;
        const color =
            evento.fecha.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)
                ? evento?.colorHexa + "80"
                : evento?.colorHexa;
        parentElement.css("background-color", color);

        if (hasCierre) parentElement.addClass("diaCierre");
        if (evento.tipoCelda.length > 0) parentElement.addClass(evento.tipoCelda);
        if (evento.errorMultipleEstados) parentElement.addClass("diaError");
        if (newYearCalendarProps.dayRender) {
            newYearCalendarProps.dayRender(element, date, eventos);
        }
    };

    const onYearChanged = ({ currentYear }) => setYear(currentYear);

    const onDayEnter = (e) => {
        tooltipDelay = setTimeout(() => {
            const evento = e.eventos.length > 0 ? e.eventos : null;
            const element = e.element;
            setTooltipTarget(`#${element.id}`);
            setTargetEvents(evento);
        }, 100);
    };

    const onDayLeave = () => {
        clearTimeout(tooltipDelay);
        setTooltipTarget(null);
        setTargetEvents(null);
    };

    return (
        <OverlayCalendarioPersonal isLoading={isLoading} key={ts}>
            <NewYearCalendar
                year={year}
                {...newYearCalendarProps}
                eventos={eventos}
                eventoRangeExpr={"idEstado"}
                dateExpr={"fecha"}
                colorExpr={"colorHexa"}
                minDateRestrictive={persona?.fechaAlta}
                maxDateRestrictive={persona?.fechaBaja}
                dayRender={dayRender}
                onDayEnter={onDayEnter}
                onDayLeave={onDayLeave}
                onYearChanged={onYearChanged}
            />
            <TooltipDayEvents tooltipTarget={tooltipTarget} targetEvents={targetEvents} />
        </OverlayCalendarioPersonal>
    );
};

export default CalendarioPersonal;
