import React, { Fragment } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../constants";
import {
  errorHandler,
  authHeader,
  formatNumber,
  getTrad,
  getNombreFormulario,
  formatDate_parameter,
  formatDate,
  convertClienteUtcToLavanderiaUtc,
  endOfMonth,
} from "../../../helpers";

//Actions
import {
  loadPanelActions,
  globalActions,
  impresionActions,
} from "../../../actions";

//Components
import CardSelector from "components/CardSelector";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { Col, Row } from "reactstrap";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { SelectBox } from "devextreme-react/select-box";
import { Tooltip } from "devextreme-react/tooltip";
import { Gallery, Item as GalleryItem } from "devextreme-react/gallery";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";

import Chart, {
  CommonSeriesSettings,
  Legend,
  Series,
  ValueAxis,
  ArgumentAxis,
  Tooltip as TooltipChart,
  Point,
  Animation,
  VisualRange,
} from "devextreme-react/chart";
import PieChart, {
  Series as PieSeries,
  Legend as PieLegend,
  Label as PieLabel,
  Connector,
  SmallValuesGrouping as PieSmallValuesGrouping,
} from "devextreme-react/pie-chart";
import {
  CircularGauge,
  Geometry,
  Scale,
  Tick,
  RangeContainer,
  Label,
  ValueIndicator,
  Margin,
  Range,
} from "devextreme-react/circular-gauge";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react";

import DropDownButton from "devextreme-react/drop-down-button";
import { progressBarCellComponent } from "../../../components/DataGrid/Cells";

import { Workbook } from "exceljs";
import saveAs from "file-saver";
//Css
import "./Css.scss";

//Informes
import InfOperativo from "pages/Informes/InformesEjecutivos/InfOperativo";
import InfReposicionesGeneral from "pages/Informes/InformesOperacionales/InfReposicionesGeneral";
import InfReposicionesOffice from "pages/Informes/InformesOperacionales/InfReposicionesOffice";
import InfLenceria from "pages/Informes/InformesEjecutivos/InfLenceria";
import DashboardMantenimiento from "../DashboardMantenimiento";
import InfPreventivoSAT from "pages/Informes/InformesOperacionales/InfPreventivoSAT";
import LoadPanel_ProgressBar from "components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";

class DashboardEjecutivo extends React.Component {
  constructor(props) {
    super(props);

    let ls_lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

    this.state = {
      fechaSel: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1,
        0,
        0,
        0,
      ),
      datosSelector_Lavanderia: [],
      datosSelector_Informes: [],
      dxMultiView_index: ls_lavanderias.length === 1 ? 1 : 0,
      idPaisSel: null,
      kgEstancia_compañiaSel: null,
      logistica_compañiaSel: null,
      idCorporacionSel: null,
      selectedIndex_Produccion: 0,
      selectedIndex_Valet: 0,
      param_paginaSecundaria: null,
      isVisible_LoadPanel_ProgressBar: false,
      progress: 0,
    };

    //DATASOURCE
    this.dataSource_spSelectDashboardEjecutivo_Selector_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Selector_beforeSend.bind(this);
    this.dataSource_spSelectDashboardEjecutivo_Selector_postProcess =
      this.dataSource_spSelectDashboardEjecutivo_Selector_postProcess.bind(
        this,
      );
    this.dataSource_tblPais_onLoaded =
      this.dataSource_tblPais_onLoaded.bind(this);

    this.datasource_datos_Informes_load =
      this.datasource_datos_Informes_load.bind(this);
    this.dataSource_spSelectDashboardEjecutivo_Produccion_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Produccion_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_Logistica_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Logistica_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_KgEstancia_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_KgEstancia_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_beforeSend.bind(
        this,
      );
    this.dataSource_spSelectDashboardEjecutivo_Valet_beforeSend =
      this.dataSource_spSelectDashboardEjecutivo_Valet_beforeSend.bind(this);
    this.dataSource_tblCorporacion_onLoaded =
      this.dataSource_tblCorporacion_onLoaded.bind(this);
    this.dataSource_spGetExcelKpiIberostar_RD_beforeSend =
      this.dataSource_spGetExcelKpiIberostar_RD_beforeSend.bind(this);
    this.dataSource_spGetExcelKpiIberostar_MX_beforeSend =
      this.dataSource_spGetExcelKpiIberostar_MX_beforeSend.bind(this);
    this.dataSource_spGetExcelKpiIberostar_BRASIL_beforeSend =
      this.dataSource_spGetExcelKpiIberostar_BRASIL_beforeSend.bind(this);
    this.dataSource_spGetExcelKpiIberostar_JAMAICA_beforeSend =
      this.dataSource_spGetExcelKpiIberostar_JAMAICA_beforeSend.bind(this);
    this.dataSource_spGetExcelKpiIberosta_onLoaded =
      this.dataSource_spGetExcelKpiIberosta_onLoaded.bind(this);

    //SELECTOR CORPORACIÓN
    this.getSelectorCorporacion = this.getSelectorCorporacion.bind(this);
    this.selectorCorporacion_onSelectionChanged =
      this.selectorCorporacion_onSelectionChanged.bind(this);

    //SELECTOR LAVANDERIA
    this.onCardClick_Lavanderia = this.onCardClick_Lavanderia.bind(this);

    // SELECTOR INFORMES
    this.onCardClick_Informes = this.onCardClick_Informes.bind(this);

    this.dxScrollView_onScroll = this.dxScrollView_onScroll.bind(this);

    //DATE PICKER
    this.prevDate_Click = this.prevDate_Click.bind(this);
    this.nextDate_Click = this.nextDate_Click.bind(this);
    this.datePicker_onValueChanged = this.datePicker_onValueChanged.bind(this);
    this.datePicker_displayFormat = this.datePicker_displayFormat.bind(this);

    //SELECTOR PAÍS
    this.getSelectorPais = this.getSelectorPais.bind(this);
    this.selectorPais_onSelectionChanged =
      this.selectorPais_onSelectionChanged.bind(this);

    //BACK BUTTON
    this.backButton_onClick = this.backButton_onClick.bind(this);

    //SELECTOR INFORMES

    //PRODUCCION
    this.onClick_Button_tarjetaProduccion =
      this.onClick_Button_tarjetaProduccion.bind(this);
    this.onSelectionChanged_dxGallery_Produccion =
      this.onSelectionChanged_dxGallery_Produccion.bind(this);

    //Logistica
    this.pieChart_Logistica_onPointClick =
      this.pieChart_Logistica_onPointClick.bind(this);
    this.backButton_Logistica_onClick =
      this.backButton_Logistica_onClick.bind(this);

    //KgEstancia
    this.onRowClick_gridCompañia_KgEstancia =
      this.onRowClick_gridCompañia_KgEstancia.bind(this);
    this.backButton_KgEstancia_onClick =
      this.backButton_KgEstancia_onClick.bind(this);
    this.onClick_Button_tarjetaKgEstancia =
      this.onClick_Button_tarjetaKgEstancia.bind(this);

    //Mantenimiento
    this.onItemClick_DropDownButton_tarjetaMantenimiento =
      this.onItemClick_DropDownButton_tarjetaMantenimiento.bind(this);
    this.onItemClick_DropDownButton_tarjetaLogistica =
      this.onItemClick_DropDownButton_tarjetaLogistica.bind(this);

    //VALET
    this.onSelectionChanged_dxGallery_Valet =
      this.onSelectionChanged_dxGallery_Valet.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //REPORT VIEWER
    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);

    //PRINT BUTTON
    this.printButton_onClick = this.printButton_onClick.bind(this);
    this.printIberostarButton_onClick =
      this.printIberostarButton_onClick.bind(this);

    this.backButton_paginaSecundaria_onClick =
      this.backButton_paginaSecundaria_onClick.bind(this);
    this.getBackButton_paginaSecundaria =
      this.getBackButton_paginaSecundaria.bind(this);
  }

  dataSource_spSelectDashboardEjecutivo_Selector = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "spSelectDashboardEjecutivo_Selector",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_Selector_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.dataSource_spSelectDashboardEjecutivo_Selector_postProcess(
        data,
      );
    },
    onChanged: () => {
      this.dataSource_spSelectDashboardEjecutivo_Selector_onChanged();
    },
  });
  dataSource_spSelectDashboardEjecutivo_Selector_beforeSend(request) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }
  dataSource_spSelectDashboardEjecutivo_Selector_postProcess(data) {
    let _this = this;

    let paises = this.dataSource_tblPais.items();

    $.each(data, function (index, item) {
      item.id = item.idLavanderia;
      item.cardClass = "row-1";
      item.pais = paises.filter(
        (x) => x.idPais === item.idPais,
      )[0].tblTraduccion.clave;

      //Porcentaje estado lavanderia
      item.porcentajeConsumos = 0;
      item.porcentajeConsumos +=
        item.mediaAgua && item.mediaAgua <= item.kpi_agua ? 1 : 0;
      item.porcentajeConsumos +=
        item.mediaElectricidad &&
        item.mediaElectricidad <= item.kpi_electricidad
          ? 1
          : 0;
      item.porcentajeConsumos +=
        item.mediaCalefaccion && item.mediaCalefaccion <= item.kpi_calefaccion
          ? 1
          : 0;
      item.porcentajeConsumos +=
        item.mediaRendimiento && item.mediaRendimiento >= item.kpi_rendimiento
          ? 1
          : 0;

      let numConsumosActivos = 0;
      numConsumosActivos += item.mediaAgua ? 1 : 0;
      numConsumosActivos += item.mediaElectricidad ? 1 : 0;
      numConsumosActivos += item.mediaCalefaccion ? 1 : 0;
      numConsumosActivos += item.mediaRendimiento ? 1 : 0;

      item.porcentajeConsumos = item.porcentajeConsumos / numConsumosActivos;

      item.color = _this.get_CardColor(item, true);
      item.headerContent = function (item) {
        return _this.get_HeaderContent(item);
      };
      item.bodyContent = function (item) {
        return _this.get_BodyContent(item);
      };
    });
    return data;
  }
  dataSource_spSelectDashboardEjecutivo_Selector_onChanged() {
    const { idPaisSel, idCorporacionSel } = this.state;
    let _this = this;
    let items = this.dataSource_spSelectDashboardEjecutivo_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );

    this.setState({
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }

  dataSource_tblCorporacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCorporacion",
      key: "idCorporacion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblCorporacion_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblCorporacion_onLoaded(data);
      },
      version: 4,
    }),
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblCorporacion_postProcess(data);
    },
  });
  dataSource_tblCorporacion_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblCorporacion_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idCorporacionSel: data[0].idCorporacion });
    }
  }
  dataSource_tblCorporacion_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idCorporacion: null, denominacion: "" });
    }
    return data;
  }

  dataSource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPais_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblPais_onLoaded(data);
      },
      version: 4,
    }),
    expand: "tblTraduccion",
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblPais_postProcess(data);
    },
  });
  dataSource_tblPais_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblPais_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idPaisSel: data[0].idPais });
    }
  }
  dataSource_tblPais_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idPais: null, denominacion: "" });
    }
    return data;
  }

  datasource_datos_Informes = new DataSource({
    paginate: false,
    store: new CustomStore({
      load: () => {
        this.datasource_datos_Informes_load();
      },
    }),
  });

  enum_link_tarjetaMantenimiento = [
    {
      idButton: 1,
      idFormulario: 111,
      pathname: "/Dashboard/DashboardMantenimiento",
      denominacion: getTrad("DashboardMantenimiento"),
    },
    {
      idButton: 2,
      idFormulario: 68,
      pathname: "/Informes/InfPreventivoSAT",
      denominacion: getTrad("infPreventivoSat"),
    },
  ];

  enum_link_tarjetaLogistica = [
    {
      idButton: 1,
      idFormulario: 14,
      pathname: "/Informes/InfReposicionesGeneral",
      denominacion: getTrad("infReposicionesGeneral"),
    },
    {
      idButton: 2,
      idFormulario: 15,
      pathname: "/Informes/InfReposicionesOffice",
      denominacion: getTrad("infReposicionesOffice"),
    },
  ];

  dataSource_spGetExcelKpiIberostar_RD = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Dashboard/spGetExcelKpiIberostar",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spGetExcelKpiIberostar_RD_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spGetExcelKpiIberosta_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_spGetExcelKpiIberostar_RD_beforeSend(request) {
    const { fechaSel } = this.state;
    let fechaDesde = new Date(fechaSel.getFullYear(), 0, 1);
    var fechaHasta = new Date(fechaSel.getFullYear(), 11, 31);

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idPais = 4;
  }

  dataSource_spGetExcelKpiIberostar_MX = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Dashboard/spGetExcelKpiIberostar",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spGetExcelKpiIberostar_MX_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spGetExcelKpiIberosta_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_spGetExcelKpiIberostar_MX_beforeSend(request) {
    const { fechaSel } = this.state;
    let fechaDesde = new Date(fechaSel.getFullYear(), 0, 1);
    var fechaHasta = new Date(fechaSel.getFullYear(), 11, 31);

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idPais = 5;
  }

  dataSource_spGetExcelKpiIberostar_BRASIL = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Dashboard/spGetExcelKpiIberostar",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spGetExcelKpiIberostar_BRASIL_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spGetExcelKpiIberosta_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_spGetExcelKpiIberostar_BRASIL_beforeSend(request) {
    const { fechaSel } = this.state;
    let fechaDesde = new Date(fechaSel.getFullYear(), 0, 1);
    var fechaHasta = new Date(fechaSel.getFullYear(), 11, 31);

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idPais = 6;
  }

  dataSource_spGetExcelKpiIberostar_JAMAICA = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Dashboard/spGetExcelKpiIberostar",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spGetExcelKpiIberostar_JAMAICA_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spGetExcelKpiIberosta_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_spGetExcelKpiIberostar_JAMAICA_beforeSend(request) {
    const { fechaSel } = this.state;
    let fechaDesde = new Date(fechaSel.getFullYear(), 0, 1);
    var fechaHasta = new Date(fechaSel.getFullYear(), 11, 31);

    request.headers = { ...authHeader() };
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
    request.params.idPais = 7;
  }

  dataSource_spGetExcelKpiIberosta_onLoaded() {
    let { progress } = this.state;
    progress += progress < 50 ? 25 : 15;
    this.setState({ progress: progress });
  }

  dataSource_spSelectDashboardEjecutivo_Produccion_loadPromise = null;
  dataSource_spSelectDashboardEjecutivo_Logistica_loadPromise = null;
  dataSource_spSelectDashboardEjecutivo_KgEstancia_loadPromise = null;
  dataSource_spSelectDashboardEjecutivo_Mantenimiento_loadPromise = null;
  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_loadPromise =
    null;
  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_loadPromise =
    null;
  dataSource_spSelectDashboardEjecutivo_Valet_loadPromise = null;

  datasource_datos_Informes_load() {
    var _deferred = $.Deferred();
    var _this = this;

    if (this.dataSource_spSelectDashboardEjecutivo_Produccion_loadPromise) {
      this.dataSource_spSelectDashboardEjecutivo_Produccion.cancel(
        this.dataSource_spSelectDashboardEjecutivo_Produccion_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Produccion_loadPromise = null;
    }

    if (this.dataSource_spSelectDashboardEjecutivo_Logistica_loadPromise) {
      this.dataSource_spSelectDashboardEjecutivo_Logistica.cancel(
        this.dataSource_spSelectDashboardEjecutivo_Logistica_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Logistica_loadPromise = null;
    }

    if (this.dataSource_spSelectDashboardEjecutivo_KgEstancia_loadPromise) {
      this.dataSource_spSelectDashboardEjecutivo_KgEstancia.cancel(
        this.dataSource_spSelectDashboardEjecutivo_KgEstancia_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_KgEstancia_loadPromise = null;
    }

    if (this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_loadPromise) {
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento.cancel(
        this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_loadPromise =
        null;
    }

    if (
      this
        .dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_loadPromise
    ) {
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia.cancel(
        this
          .dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_loadPromise =
        null;
    }

    if (
      this
        .dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_loadPromise
    ) {
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo.cancel(
        this
          .dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_loadPromise =
        null;
    }

    if (this.dataSource_spSelectDashboardEjecutivo_Valet_loadPromise) {
      this.dataSource_spSelectDashboardEjecutivo_Valet.cancel(
        this.dataSource_spSelectDashboardEjecutivo_Valet_loadPromise
          .operationId,
      );
      this.dataSource_spSelectDashboardEjecutivo_Valet_loadPromise = null;
    }

    $.when(
      (_this.dataSource_spSelectDashboardEjecutivo_Produccion_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Produccion.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_Logistica_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Logistica.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_KgEstancia_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_KgEstancia.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo.load()),
      (_this.dataSource_spSelectDashboardEjecutivo_Valet_loadPromise =
        _this.dataSource_spSelectDashboardEjecutivo_Valet.load()),
    ).done(function () {
      var produccion =
        _this.dataSource_spSelectDashboardEjecutivo_Produccion.items();
      var logistica =
        _this.dataSource_spSelectDashboardEjecutivo_Logistica.items();
      var kgEstancia =
        _this.dataSource_spSelectDashboardEjecutivo_KgEstancia.items();
      var mantenimiento =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento.items();
      var mantenimiento_lav =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia.items();
      var mantenimiento_prev =
        _this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo.items();
      var valet = _this.dataSource_spSelectDashboardEjecutivo_Valet.items();

      var result = [];

      //CARTA PRODUCCION
      let card_Producion = {};
      card_Producion.id = "Produccion";
      card_Producion.cardClass = "row-2";
      card_Producion.getDenominacion = function () {
        return getTrad("produccion");
      };
      card_Producion.items = produccion;

      //Agregamos los saltos de días cuando la lavandería está cerrada
      card_Producion.breaks_Chart = [];
      for (let i = 1; i < produccion.length; i++) {
        const item_ = produccion[i];
        const itemAnterior_ = produccion[i - 1];

        if (itemAnterior_.fecha.getDate() + 1 !== item_.fecha.getDate()) {
          card_Producion.breaks_Chart.push({
            startValue: itemAnterior_.fecha,
            endValue: item_.fecha,
          });
        }
      }

      //Determinamos el Viasual Range de cada una de las gráficas
      card_Producion.charts_VisualRange = {
        produccion: [null, null], //Sin restricción
        rendimiento: [null, null],
        rechazo: [null, null],
        agua: [null, null],
        electricidad: [null, null],
        calefaccion: [null, null],
      };

      //Rendimiento
      if (produccion.length > 0) {
        let kpi_Rendimiento = card_Producion.items[0].kpi_rendimiento,
          max_Default_Rendimiento = 100,
          min_Default_Rendimiento = 20,
          max_Rendimiento = Math.max.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { rendimiento: max_Default_Rendimiento },
                { rendimiento: kpi_Rendimiento },
              ],
            ].map(function (i) {
              return i.rendimiento;
            }),
          ),
          min_Rendimiento = Math.min.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { rendimiento: min_Default_Rendimiento },
                { rendimiento: kpi_Rendimiento },
              ],
            ].map(function (i) {
              return i.rendimiento;
            }),
          );
        card_Producion.charts_VisualRange.rendimiento = [
          min_Rendimiento,
          max_Rendimiento,
        ];

        //Rechazo
        let kpi_Rechazo = card_Producion.items[0].kpi_rechazo,
          max_Default_Rechazo = 10,
          min_Default_Rechazo = 0,
          max_Rechazo = Math.max.apply(
            Math,
            [
              ...card_Producion.items,
              ...[{ rechazo: max_Default_Rechazo }, { rechazo: kpi_Rechazo }],
            ].map(function (i) {
              return i.rechazo;
            }),
          ),
          min_Rechazo = Math.min.apply(
            Math,
            [
              ...card_Producion.items,
              ...[{ rechazo: min_Default_Rechazo }, { rechazo: kpi_Rechazo }],
            ].map(function (i) {
              return i.rechazo;
            }),
          );
        card_Producion.charts_VisualRange.rechazo = [min_Rechazo, max_Rechazo];

        //Agua
        let kpi_Agua = card_Producion.items[0].kpi_agua,
          max_Default_Agua = 20,
          min_Default_Agua = 0,
          max_Agua = Math.max.apply(
            Math,
            [
              ...card_Producion.items,
              ...[{ agua: max_Default_Agua }, { agua: kpi_Agua }],
            ].map(function (i) {
              return i.agua;
            }),
          ),
          min_Agua = Math.min.apply(
            Math,
            [
              ...card_Producion.items,
              ...[{ agua: min_Default_Agua }, { agua: kpi_Agua }],
            ].map(function (i) {
              return i.agua;
            }),
          );

        card_Producion.charts_VisualRange.agua = [min_Agua, max_Agua];

        //Electricidad
        let kpi_Electricidad = card_Producion.items[0].kpi_electricidad,
          max_Default_Electricidad = 0.4,
          min_Default_Electricidad = 0,
          max_Electricidad = Math.max.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { electricidad: max_Default_Electricidad },
                { electricidad: kpi_Electricidad },
              ],
            ].map(function (i) {
              return i.electricidad;
            }),
          ),
          min_Electricidad = Math.min.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { electricidad: min_Default_Electricidad },
                { electricidad: kpi_Electricidad },
              ],
            ].map(function (i) {
              return i.electricidad;
            }),
          );
        card_Producion.charts_VisualRange.electricidad = [
          min_Electricidad,
          max_Electricidad,
        ];

        //Calefaccion
        let kpi_Calefaccion = card_Producion.items[0].kpi_calefaccion,
          max_Default_Calefaccion = 2,
          min_Default_Calefaccion = 0,
          max_Calefaccion = Math.max.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { calefaccion: max_Default_Calefaccion },
                { calefaccion: kpi_Calefaccion },
              ],
            ].map(function (i) {
              return i.calefaccion;
            }),
          ),
          min_Calefaccion = Math.min.apply(
            Math,
            [
              ...card_Producion.items,
              ...[
                { calefaccion: min_Default_Calefaccion },
                { calefaccion: kpi_Calefaccion },
              ],
            ].map(function (i) {
              return i.calefaccion;
            }),
          );
        card_Producion.charts_VisualRange.calefaccion = [
          min_Calefaccion,
          max_Calefaccion,
        ];
      }

      card_Producion.headerContent = function (card_Producion) {
        return _this.get_HeaderContent_Produccion(card_Producion);
      };
      card_Producion.bodyContent = function (card_Producion) {
        return _this.get_BodyContent_Produccion(card_Producion);
      };
      result.push(card_Producion);

      //CARTA LOGÍSTICA
      let card_Logistica = {};
      card_Logistica.id = "Logistica";
      card_Logistica.cardClass = "row-2";
      card_Logistica.getDenominacion = function () {
        return getTrad("logistica");
      };
      //ITEMS COMPAÑÍA
      card_Logistica.tblCompañia = logistica.filter((x) => !x.idEntidad);
      $.each(card_Logistica.tblCompañia, function (index, itemCompañia) {
        itemCompañia.tblEntidad = logistica.filter(
          (x) => x.idEntidad && x.idCompañia === itemCompañia.idCompañia,
        );
      });

      //item.color = _this.get_CardColor_KgEstancia(item, true)
      card_Logistica.headerContent = function (card_Logistica) {
        return _this.get_HeaderContent_Logistica(card_Logistica);
      };
      card_Logistica.bodyContent = function (card_Logistica) {
        return _this.get_BodyContent_Logistica(card_Logistica);
      };
      result.push(card_Logistica);

      //CARTA KG ESTANCIA
      let card_KgEstancia = {};
      card_KgEstancia.id = "KgEstancia";
      card_KgEstancia.cardClass = "row-2";
      card_KgEstancia.getDenominacion = function () {
        return getTrad("cliente");
      };
      //ITEMS COMPAÑÍA
      card_KgEstancia.tblCompañia = kgEstancia.filter((x) => !x.idEntidad);
      $.each(card_KgEstancia.tblCompañia, function (index, itemCompañia) {
        itemCompañia.tblEntidad = kgEstancia.filter(
          (x) => x.idEntidad && x.idCompañia === itemCompañia.idCompañia,
        );

        //PROGRESS BAR OPTIONS ENTIDAD
        $.each(itemCompañia.tblEntidad, function (index, itemEntidad) {
          itemEntidad.progressBarCellOptions_kgEstancia = {};
          itemEntidad.progressBarCellOptions_kgEstancia.type = "decimal";
          itemEntidad.progressBarCellOptions_kgEstancia.max =
            itemEntidad.maxValue;
          itemEntidad.progressBarCellOptions_kgEstancia.tick =
            itemEntidad.objKgEstancia;
          itemEntidad.progressBarCellOptions_kgEstancia.tickTooltip =
            getTrad("objetivoKgEsta+ncia") + ": " + itemEntidad.objKgEstancia;
        });
      });
      //PROGRESS BAR OPTIONS COMPAÑÍA
      $.each(card_KgEstancia.tblCompañia, function (index, itemCompañia) {
        itemCompañia.progressBarCellOptions_kgEstancia = {};
        itemCompañia.progressBarCellOptions_kgEstancia.type = "decimal";
        itemCompañia.progressBarCellOptions_kgEstancia.max =
          itemCompañia.maxValue;
      });

      //item.color = _this.get_CardColor_KgEstancia(item, true)
      card_KgEstancia.headerContent = function (card_KgEstancia) {
        return _this.get_HeaderContent_KgEstancia(card_KgEstancia);
      };
      card_KgEstancia.bodyContent = function (card_KgEstancia) {
        return _this.get_BodyContent_KgEstancia(card_KgEstancia);
      };
      result.push(card_KgEstancia);

      //CARTA MANTENIMIENTO
      let card_Mantenimiento = {};
      card_Mantenimiento.id = "Mantenimiento";
      card_Mantenimiento.cardClass = "row-2";
      card_Mantenimiento.getDenominacion = function () {
        return getTrad("mantenimiento");
      };
      //ITEMS COMPAÑÍA
      card_Mantenimiento.tblMaquina = mantenimiento;
      card_Mantenimiento.lavanderia = mantenimiento_lav[0];
      card_Mantenimiento.preventivo = mantenimiento_prev[0].cumplimiento_medio;

      card_Mantenimiento.headerContent = function (card_Mantenimiento) {
        return _this.get_HeaderContent_Mantenimiento(card_Mantenimiento);
      };
      card_Mantenimiento.bodyContent = function (card_Mantenimiento) {
        return _this.get_BodyContent_Mantenimiento(card_Mantenimiento);
      };
      result.push(card_Mantenimiento);

      //CARTA VALET
      let card_Valet = {};
      card_Valet.id = "Valet";
      card_Valet.cardClass = "row-2";
      card_Valet.getDenominacion = function () {
        return getTrad("valet");
      };
      card_Valet.items = valet;

      //Agregamos los saltos de días cuando la lavandería está cerrada
      card_Valet.breaks_Chart = [];
      for (let i = 1; i < valet.length; i++) {
        const item_ = valet[i];
        const itemAnterior_ = valet[i - 1];

        if (itemAnterior_.fecha.getDate() + 1 !== item_.fecha.getDate()) {
          card_Valet.breaks_Chart.push({
            startValue: itemAnterior_.fecha,
            endValue: item_.fecha,
          });
        }
      }

      //Determinamos el Viasual Range de cada una de las gráficas
      card_Valet.charts_VisualRange = {
        produccion: [null, null], //Sin restricción
        rendimiento: [null, null],
        rechazo: [null, null],
        agua: [null, null],
        electricidad: [null, null],
        calefaccion: [null, null],
      };

      //Rendimiento
      if (valet.length > 0) {
        let kpi_Rendimiento = card_Valet.items[0].kpi_rendimiento,
          max_Default_Rendimiento = 100,
          min_Default_Rendimiento = 20,
          max_Rendimiento = Math.max.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { rendimiento: max_Default_Rendimiento },
                { rendimiento: kpi_Rendimiento },
              ],
            ].map(function (i) {
              return i.rendimiento;
            }),
          ),
          min_Rendimiento = Math.min.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { rendimiento: min_Default_Rendimiento },
                { rendimiento: kpi_Rendimiento },
              ],
            ].map(function (i) {
              return i.rendimiento;
            }),
          );
        card_Valet.charts_VisualRange.rendimiento = [
          min_Rendimiento,
          max_Rendimiento,
        ];

        //Rechazo
        let kpi_Rechazo = card_Valet.items[0].kpi_rechazo,
          max_Default_Rechazo = 10,
          min_Default_Rechazo = 0,
          max_Rechazo = Math.max.apply(
            Math,
            [
              ...card_Valet.items,
              ...[{ rechazo: max_Default_Rechazo }, { rechazo: kpi_Rechazo }],
            ].map(function (i) {
              return i.rechazo;
            }),
          ),
          min_Rechazo = Math.min.apply(
            Math,
            [
              ...card_Valet.items,
              ...[{ rechazo: min_Default_Rechazo }, { rechazo: kpi_Rechazo }],
            ].map(function (i) {
              return i.rechazo;
            }),
          );
        card_Valet.charts_VisualRange.rechazo = [min_Rechazo, max_Rechazo];

        //Agua
        let kpi_Agua = card_Valet.items[0].kpi_agua,
          max_Default_Agua = 20,
          min_Default_Agua = 0,
          max_Agua = Math.max.apply(
            Math,
            [
              ...card_Valet.items,
              ...[{ agua: max_Default_Agua }, { agua: kpi_Agua }],
            ].map(function (i) {
              return i.agua;
            }),
          ),
          min_Agua = Math.min.apply(
            Math,
            [
              ...card_Valet.items,
              ...[{ agua: min_Default_Agua }, { agua: kpi_Agua }],
            ].map(function (i) {
              return i.agua;
            }),
          );

        card_Valet.charts_VisualRange.agua = [min_Agua, max_Agua];

        //Electricidad
        let kpi_Electricidad = card_Valet.items[0].kpi_electricidad,
          max_Default_Electricidad = 0.4,
          min_Default_Electricidad = 0,
          max_Electricidad = Math.max.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { electricidad: max_Default_Electricidad },
                { electricidad: kpi_Electricidad },
              ],
            ].map(function (i) {
              return i.electricidad;
            }),
          ),
          min_Electricidad = Math.min.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { electricidad: min_Default_Electricidad },
                { electricidad: kpi_Electricidad },
              ],
            ].map(function (i) {
              return i.electricidad;
            }),
          );
        card_Valet.charts_VisualRange.electricidad = [
          min_Electricidad,
          max_Electricidad,
        ];

        //Calefaccion
        let kpi_Calefaccion = card_Valet.items[0].kpi_calefaccion,
          max_Default_Calefaccion = 2,
          min_Default_Calefaccion = 0,
          max_Calefaccion = Math.max.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { calefaccion: max_Default_Calefaccion },
                { calefaccion: kpi_Calefaccion },
              ],
            ].map(function (i) {
              return i.calefaccion;
            }),
          ),
          min_Calefaccion = Math.min.apply(
            Math,
            [
              ...card_Valet.items,
              ...[
                { calefaccion: min_Default_Calefaccion },
                { calefaccion: kpi_Calefaccion },
              ],
            ].map(function (i) {
              return i.calefaccion;
            }),
          );
        card_Valet.charts_VisualRange.calefaccion = [
          min_Calefaccion,
          max_Calefaccion,
        ];
      }

      card_Valet.headerContent = function (card_Valet) {
        return _this.get_HeaderContent_Valet(card_Valet);
      };
      card_Valet.bodyContent = function (card_Valet) {
        return _this.get_BodyContent_Valet(card_Valet);
      };

      let { lavanderia } = _this.props;
      if ($.inArray(7, lavanderia.visibleModulos) !== -1)
        // Chequea si lavanderia tiene el módulo de valet
        result.push(card_Valet);

      _this.setState(
        {
          datosSelector_Informes: result,
          kgEstancia_compañiaSel: _this.state.kgEstancia_compañiaSel,
          logistica_compañiaSel: _this.state.logistica_compañiaSel,
        },
        () => {
          _this.loadPanel_hide();
        },
      );
      _deferred.resolve(result);
    });

    return _deferred.promise();
  }

  dataSource_spSelectDashboardEjecutivo_Produccion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "spSelectDashboardEjecutivo_Produccion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_Produccion_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });
  dataSource_spSelectDashboardEjecutivo_Produccion_beforeSend(request) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );
    let fechaHoy = convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date());

    if (
      fechaHoy.getTime() > fechaDesde.getTime() &&
      fechaHoy.getTime() < fechaHasta.getTime()
    ) {
      fechaHoy.setDate(fechaHoy.getDate() - 1);
      fechaHasta = fechaHoy;
    }

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  dataSource_spSelectDashboardEjecutivo_Logistica = new DataSource({
    key: ["idCompañia", "idEntidad"],
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "spSelectDashboardEjecutivo_Logistica",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_Logistica_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });
  dataSource_spSelectDashboardEjecutivo_Logistica_beforeSend(request) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  dataSource_spSelectDashboardEjecutivo_KgEstancia = new DataSource({
    key: ["idCompañia", "idEntidad"],
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "spSelectDashboardEjecutivo_KgEstancia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_KgEstancia_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });
  dataSource_spSelectDashboardEjecutivo_KgEstancia_beforeSend(request) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  dataSource_spSelectDashboardEjecutivo_Mantenimiento = new DataSource({
    key: ["idCompañia", "idEntidad"],
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spSelectDashboardEjecutivo_Mantenimiento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });
  dataSource_spSelectDashboardEjecutivo_Mantenimiento_beforeSend(request) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia =
    new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "spSelectDashboardEjecutivo_Mantenimiento_Lavanderia",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_beforeSend(
            request,
          );
        },
        version: 4,
      }),
    });

  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Lavanderia_beforeSend(
    request,
  ) {
    let { lavanderia } = this.props;
    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo =
    new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.ODATA_URL +
          "spSelectDashboardEjecutivo_Mantenimiento_Preventivo",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          this.dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_beforeSend(
            request,
          );
        },
        version: 4,
      }),
    });

  dataSource_spSelectDashboardEjecutivo_Mantenimiento_Preventivo_beforeSend(
    request,
  ) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  dataSource_spSelectDashboardEjecutivo_Valet = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectDashboardEjecutivo_Valet",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectDashboardEjecutivo_Valet_beforeSend(request);
      },
      version: 4,
    }),
  });
  dataSource_spSelectDashboardEjecutivo_Valet_beforeSend(request) {
    let { lavanderia } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );
    let fechaHoy = convertClienteUtcToLavanderiaUtc(lavanderia.GMT, new Date());

    if (
      fechaHoy.getTime() > fechaDesde.getTime() &&
      fechaHoy.getTime() < fechaHasta.getTime()
    ) {
      fechaHoy.setDate(fechaHoy.getDate() - 1);
      fechaHasta = fechaHoy;
    }

    request.headers = { ...authHeader() };
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }

  enum_abreviaturas_lavanderias_iberostar = [
    {
      idLavanderia: 1,
      denominacion: "IBEROSTAR PLAYA PARAISO",
      abreviatura: "PSO",
    },
    {
      idLavanderia: 22,
      denominacion: "IBEROSTAR PRAIA DO FORTE",
      abreviatura: "CPR",
    },
    {
      idLavanderia: 23,
      denominacion: "IBEROSTAR ROSE HALL",
      abreviatura: "CRH",
    },
    {
      idLavanderia: 25,
      denominacion: "IBEROSTAR BÁVARO",
      abreviatura: "CBV",
    },
    {
      idLavanderia: 26,
      denominacion: "IBEROSTAR COSTA DORADA",
      abreviatura: "DOR",
    },
    {
      idLavanderia: 27,
      denominacion: "IBEROSTAR CANCÚN",
      abreviatura: "CUN / TORRE",
    },
    {
      idLavanderia: 28,
      denominacion: "IBEROSTAR COZUMEL",
      abreviatura: "COZ",
    },
    {
      idLavanderia: 30,
      denominacion: "IBEROSTAR PLAYA MITA",
      abreviatura: "MITA",
    },
    {
      idLavanderia: 31,
      denominacion: "IBEROSTAR HACIENDA DOMINICUS",
      abreviatura: "HAC",
    },
    {
      idLavanderia: 42,
      denominacion: "IBEROSTAR TUCAN QUETZAL",
      abreviatura: "T&Q",
    },
  ];

  enum_abreviaturas_entidades_iberostar = [
    {
      idEntidad: 1,
      denominacion: "LINDO MAYA",
      abreviatura: "L&M",
    },
    {
      idEntidad: 2,
      denominacion: "MAR BEACH",
      abreviatura: "M&B",
    },
    {
      idEntidad: 3,
      denominacion: "TUKAN QUETZAL",
      abreviatura: "T&Q",
    },
    {
      idEntidad: 4,
      denominacion: "COMUNES",
      abreviatura: "COMUNES",
    },
    {
      idEntidad: 5,
      denominacion: "GRAND PARAISO",
      abreviatura: "GHP",
    },
    {
      idEntidad: 314,
      denominacion: "COSTA DORADA",
      abreviatura: "DOR",
    },
    {
      idEntidad: 344,
      denominacion: "DOMINICANA-PUNTA CANA",
      abreviatura: "DOMPC",
    },
    {
      idEntidad: 346,
      denominacion: "ALOJAMIENTO PERSONAL",
      abreviatura: "ALP",
    },
    {
      idEntidad: 347,
      denominacion: "SPA COMPLEJO",
      abreviatura: "SPA",
    },
    {
      idEntidad: 372,
      denominacion: "PIRÁMIDE",
      abreviatura: "CUN",
    },
    {
      idEntidad: 371,
      denominacion: "TORRE CANCÚN",
      abreviatura: "TORRE",
    },
    {
      idEntidad: 359,
      denominacion: "IBEROSTAR COZUMEL",
      abreviatura: "COZ",
    },
    {
      idEntidad: 375,
      denominacion: "IBEROSTAR HACIENDA DOMINICUS",
      abreviatura: "HAC",
    },
    {
      idEntidad: 376,
      denominacion: "IBS PLAYA MITA",
      abreviatura: "MITA",
    },
    {
      idEntidad: 380,
      denominacion: "BAHIA",
      abreviatura: "BAH",
    },
    {
      idEntidad: 381,
      denominacion: "PRAIA",
      abreviatura: "PRA",
    },
    {
      idEntidad: 342,
      denominacion: "GRAND",
      abreviatura: "GHB",
    },
    {
      idEntidad: 343,
      denominacion: "BAVARO",
      abreviatura: "BAV",
    },
    {
      idEntidad: 406,
      denominacion: "CORAL",
      abreviatura: "COL",
    },
    {
      idEntidad: 388,
      denominacion: "GRAND",
      abreviatura: "GHR",
    },
    {
      idEntidad: 389,
      denominacion: "BEACH",
      abreviatura: "ROH",
    },
    {
      idEntidad: 390,
      denominacion: "SUITE",
      abreviatura: "ROA",
    },
    {
      idEntidad: 406,
      denominacion: "CORAL LEVEL",
      abreviatura: "CORAL LEVEL",
    },
  ];

  render() {
    const {
      dxMultiView_index,
      datosSelector_Lavanderia,
      datosSelector_Informes,
      param_paginaSecundaria,
      isVisible_LoadPanel_ProgressBar,
      progress,
    } = this.state;

    if (
      datosSelector_Lavanderia.length > 0 &&
      !datosSelector_Lavanderia[0].bodyContent
    ) {
      //Verifica que la carga de lavanderias haya pasado por postProcess y tenga el bodyContent
      return (
        <PageTitle
          heading={getNombreFormulario(this)}
          preHeading={this.getBackButton()}
          postHeading={[
            this.getSelectorCorporacion(),
            this.getSelectorPais(),
            this.getPrintButton(),
            this.getDatePicker(),
          ]}
        />
      );
    }
    return (
      <MultiView
        height="100%"
        width="100%"
        selectedIndex={param_paginaSecundaria != null ? 1 : 0}
        focusStateEnabled={false}
        loop={false}
        swipeEnabled={false}
        deferRendering={false}
        animationEnabled={true}
        className="MainTitle"
      >
        <MultiViewItem>
          <PageTitle
            heading={getNombreFormulario(this)}
            preHeading={this.getBackButton()}
            postHeading={[
              ,
              this.getPrintIberostarButton(),
              this.getSelectorCorporacion(),
              this.getSelectorPais(),
              this.getPrintButton(),
              this.getDatePicker(),
            ]}
          />
          <div className={"media-body"}>
            <MultiView
              height="100%"
              width="100%"
              selectedIndex={dxMultiView_index}
              loop={true}
              swipeEnabled={false}
              deferRendering={true}
              animationEnabled={true}
            >
              <MultiViewItem>
                <CardSelector
                  data={datosSelector_Lavanderia}
                  onClick={this.onCardClick_Lavanderia}
                  bodyClick={true}
                  groupField="pais"
                  zoom={true}
                ></CardSelector>
              </MultiViewItem>
              <MultiViewItem>
                <CardSelector
                  data={datosSelector_Informes}
                  readOnly={true}
                  onClick={this.onCardClick_Informes}
                  onScroll={this.dxScrollView_onScroll}
                  {...this.state}
                ></CardSelector>
              </MultiViewItem>
            </MultiView>
          </div>
          <LoadPanel_ProgressBar
            title={"Generando archivo"}
            progress={progress}
            visible={isVisible_LoadPanel_ProgressBar}
          />
        </MultiViewItem>
        <MultiViewItem>
          {param_paginaSecundaria ? (
            param_paginaSecundaria.index_paginaSecundaria === 1 ? (
              <InfOperativo
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : param_paginaSecundaria.index_paginaSecundaria === 2 ? (
              <InfReposicionesGeneral
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : param_paginaSecundaria.index_paginaSecundaria === 3 ? (
              <InfReposicionesOffice
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : param_paginaSecundaria.index_paginaSecundaria === 4 ? (
              <InfLenceria
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : param_paginaSecundaria.index_paginaSecundaria === 5 ? (
              <DashboardMantenimiento
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : param_paginaSecundaria.index_paginaSecundaria === 6 ? (
              <InfPreventivoSAT
                param_paginaSecundaria={param_paginaSecundaria}
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              />
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )}
        </MultiViewItem>
      </MultiView>
    );
  }

  backButton_paginaSecundaria_onClick() {
    this.setState({ param_paginaSecundaria: null });
    this.setReport({
      denominacion: "dashboard_ejecutivo",
      parameterEvent: this.CustomizeParameterEditors,
    });
  }

  getBackButton_paginaSecundaria() {
    return (
      <Fragment>
        <div
          id="backButton"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_paginaSecundaria_onClick}
        />
        <Tooltip
          target="#backButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("volverDashboardEjecutivo")}</div>
        </Tooltip>
      </Fragment>
    );
  }

  //SELECTOR LAVANDERIA
  onCardClick_Lavanderia(lavanderia) {
    this.setState({ dxMultiView_index: 1 }, () => {
      this.setLavanderia(lavanderia.idLavanderia);
    });
  }
  get_HeaderContent(lavanderia) {
    return (
      <div className="container_spanCentrado">
        {formatNumber(lavanderia.mediaKgProcesado)}
        <div className="pl-2 font-size-xl icon_Kg" />
      </div>
    );
  }
  get_BodyContent(lavanderia) {
    return (
      <Fragment>
        <Col xs="3" className="he-100 p-0 ml-2">
          <Fragment>
            <CircularGauge
              className={"CircularGauge_selector"}
              id={"CircularGauge_selector_" + lavanderia.idLavanderia}
              value={lavanderia.porcentajeConsumos}
            >
              <Scale startValue={0} endValue={1}>
                <Tick visible={false} />
                <Label visible={false} />
              </Scale>
              <Geometry startAngle={90} endAngle={90}></Geometry>
              <RangeContainer
                width={8}
                backgroundColor="lightgrey"
                offset={10}
              />
              <ValueIndicator
                type="rangebar"
                color={"var(--" + this.get_CardColor(lavanderia) + ")"}
                offset={0}
                size={10}
              ></ValueIndicator>
              <Margin right={5} />
            </CircularGauge>
            <div
              className="container_spanCentrado position-absolute w-100 he-100"
              style={{ top: "0px", right: "2px" }}
            >
              <div className={this.get_responsiveFont_gauge()}>
                {lavanderia.porcentajeConsumos ? (
                  lavanderia.porcentajeConsumos < 1 ? (
                    formatNumber(lavanderia.porcentajeConsumos, 0, "percent")
                  ) : (
                    <div className="icon_Check success font-size-max"></div>
                  )
                ) : (
                  <div className="success font-size-max">-</div>
                )}
              </div>
            </div>
          </Fragment>
        </Col>
        <Col xs="9" className="he-100">
          <table className="table w-100 he-100">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col" className="text-center font-size-xxxs">
                  KPI
                </th>
                <th scope="col" className="text-center font-size-xxxs">
                  {getTrad("mes").toUpperCase()}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_Agua dotStyle" />
                </th>
                <td>{getTrad("agua").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(lavanderia.kpi_agua, 2)}
                </td>
                <td
                  className={
                    "text-center font-weight-bold " +
                    (lavanderia.mediaAgua !== null
                      ? lavanderia.kpi_agua < lavanderia.mediaAgua
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderia.mediaAgua !== null
                    ? formatNumber(lavanderia.mediaAgua)
                    : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_Calefaccion dotStyle" />
                </th>
                <td>{getTrad("calefaccion").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(lavanderia.kpi_calefaccion)}
                </td>
                <td
                  className={
                    "text-center font-weight-bold " +
                    (lavanderia.mediaCalefaccion !== null
                      ? lavanderia.kpi_calefaccion < lavanderia.mediaCalefaccion
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderia.mediaCalefaccion !== null
                    ? formatNumber(lavanderia.mediaCalefaccion)
                    : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_Electricidad dotStyle" />
                </th>
                <td>{getTrad("electricidad").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(lavanderia.kpi_electricidad)}
                </td>
                <td
                  className={
                    "text-center font-weight-bold " +
                    (lavanderia.mediaElectricidad !== null
                      ? lavanderia.kpi_electricidad <
                        lavanderia.mediaElectricidad
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderia.mediaElectricidad !== null
                    ? formatNumber(lavanderia.mediaElectricidad)
                    : "-"}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <div className="font-size-xxll icon_timer dotStyle" />
                </th>
                <td>{getTrad("rendimiento").toUpperCase()}</td>
                <td className="text-center">
                  {formatNumber(lavanderia.kpi_rendimiento)}
                </td>
                <td
                  className={
                    "text-center font-weight-bold " +
                    (lavanderia.mediaRendimiento !== null
                      ? lavanderia.kpi_rendimiento > lavanderia.mediaRendimiento
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderia.mediaRendimiento !== null
                    ? formatNumber(lavanderia.mediaRendimiento)
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Fragment>
    );
  }
  get_CardColor(lavanderia, isBorder = false) {
    let value = lavanderia.porcentajeConsumos;
    if (!isBorder) {
      return value <= 0.5 ? "danger" : value < 1 ? "primary" : "success";
    }
    return value <= 0.5
      ? "border-danger"
      : value < 1
        ? "border-primary"
        : "border-success";
  }

  //DATE PICKER
  getDatePicker() {
    return (
      <DateBox
        className="centerText font-size-sm"
        inputAttr={{ class: "font-size-sm" }}
        value={this.state.fechaSel}
        width="100%"
        stylingMode="underlined"
        displayFormat={this.datePicker_displayFormat}
        openOnFieldClick={true}
        calendarOptions={{
          maxZoomLevel: "year",
          minZoomLevel: "year",
        }}
        onValueChanged={this.datePicker_onValueChanged}
      >
        <DateBoxButton
          name="prevDate"
          location="before"
          options={{
            icon: "spinprev",
            stylingMode: "text",
            onClick: this.prevDate_Click,
          }}
        />
        <DateBoxButton
          name="nextDate"
          location="after"
          options={{
            icon: "spinnext",
            stylingMode: "text",
            onClick: this.nextDate_Click,
          }}
        />
      </DateBox>
    );
  }
  prevDate_Click(e) {
    const { fechaSel } = this.state;
    const _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)),
    });
  }
  nextDate_Click(e) {
    let { fechaSel } = this.state;
    let _fechaSel = new Date(fechaSel.getTime());
    this.setState({
      fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)),
    });
  }
  datePicker_onValueChanged(e) {
    this.setState({ fechaSel: e.value });
  }
  datePicker_displayFormat(fecha) {
    let { idioma } = this.props;
    if (new Date().getFullYear() === fecha.getFullYear()) {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long" })
        .toUpperCase();
    } else {
      return fecha
        .toLocaleString(idioma.codigo, { month: "long", year: "numeric" })
        .toUpperCase();
    }
  }

  //SELECTOR PAÍS
  getSelectorPais() {
    const { idioma } = this.props;
    let { idPaisSel, dxMultiView_index } = this.state;

    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblPais}
        displayExpr={function (item) {
          if (item && item.tblTraduccion) {
            return item.tblTraduccion[idioma.codigo].toUpperCase();
          } else if (item) {
            return getTrad("todosPaises");
          } else {
            return;
          }
        }}
        valueExpr="idPais"
        value={idPaisSel}
        defaultValue={idPaisSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todosPaises")}
        onSelectionChanged={this.selectorPais_onSelectionChanged}
      />
    );
  }
  selectorPais_onSelectionChanged(e) {
    const { idCorporacionSel } = this.state;
    let idPaisSel = e.selectedItem.idPais;
    let items = this.dataSource_spSelectDashboardEjecutivo_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );

    this.setState({
      idPaisSel: idPaisSel,
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }

  getPrintIberostarButton() {
    let { dxMultiView_index, datosSelector_Lavanderia } = this.state;
    const lavsIberostar = datosSelector_Lavanderia.filter(
      (lav) => lav.idCorporacion === 1,
    );
    const hasLavIberostar = lavsIberostar.length > 0;

    if ((dxMultiView_index !== 0) | !hasLavIberostar) return;

    return (
      <Fragment>
        <Button
          width={220}
          text={getTrad("imprimir") + " KPIs Iberostar"}
          icon=" icon_Impresora"
          className="mr-2"
          onClick={this.printIberostarButton_onClick}
        />
        <div className="separadorHeader">
          <div></div>
        </div>
      </Fragment>
    );
  }

  printIberostarButton_onClick() {
    const { fechaSel } = this.state;

    let _this = this;
    const workbook = new Workbook();

    _this.setState({ isVisible_LoadPanel_ProgressBar: true, progress: 0 });

    $.when(
      _this.dataSource_spGetExcelKpiIberostar_RD.load(),
      _this.dataSource_spGetExcelKpiIberostar_MX.load(),
      _this.dataSource_spGetExcelKpiIberostar_BRASIL.load(),
      _this.dataSource_spGetExcelKpiIberostar_JAMAICA.load(),
    ).then(function (dataRD, dataMX, dataBRASIL, dataJAMAICA) {
      let data = [
        ...dataRD[0],
        ...dataMX[0],
        ...dataBRASIL[0],
        ...dataJAMAICA[0],
      ];

      const groupedArray = Object.values(
        data.reduce((result, obj) => {
          const key = `${obj.idLavanderia}-${obj.idEntidad || "null"}`;
          const abrLav = _this.enum_abreviaturas_lavanderias_iberostar.find(
            (item) => item.idLavanderia === obj.idLavanderia,
          ).abreviatura;
          const abreviatura =
            (
              _this.enum_abreviaturas_entidades_iberostar.find(
                (item) => item.idEntidad === obj.idEntidad,
              ) ||
              _this.enum_abreviaturas_lavanderias_iberostar.find(
                (item) => item.idLavanderia === obj.idLavanderia,
              )
            )?.abreviatura || "";

          if (!result[key]) {
            result[key] = {
              idLavanderia: obj.idLavanderia,
              idEntidad: obj.idEntidad,
              values: [],
              denoPais: obj.denoPais,
              denominacion: obj.denominacion,
              abreviatura,
              abrLav,
            };
          }
          result[key].values.push(obj);
          return result;
        }, {}),
      );

      // Se ordenan primero por países, luego por sus abreviaturas y por último primero las lavanderías y luego las entidades
      groupedArray.sort((a, b) => {
        const denoPaisA = a.denoPais.toLowerCase();
        const denoPaisB = b.denoPais.toLowerCase();

        const abrLavA = a.abrLav.toLowerCase();
        const abrLavB = b.abrLav.toLowerCase();

        const idEntidadA = a.idEntidad !== null ? 1 : 0;
        const idEntidadB = b.idEntidad !== null ? 1 : 0;

        const abreviaturaA = a.abreviatura.toLowerCase();
        const abreviaturaB = b.abreviatura.toLowerCase();

        if (denoPaisA > denoPaisB) {
          return 1;
        }
        if (denoPaisA < denoPaisB) {
          return -1;
        }

        if (abrLavA > abrLavB) {
          return 1;
        }
        if (abrLavA < abrLavB) {
          return -1;
        }

        if (idEntidadA > idEntidadB) {
          return 1;
        }
        if (idEntidadA < idEntidadB) {
          return -1;
        }

        if (abreviaturaA > abreviaturaB) {
          return 1;
        }
        if (abreviaturaA < abreviaturaB) {
          return -1;
        }
      });

      // const currentDate = new Date();
      const currentYear = fechaSel.getFullYear();
      const lastYear = currentYear - 1;
      const yearBeforeLast = currentYear - 2;

      let rowInicio = 0;
      groupedArray.forEach((lavanderia) => {
        const existingWorksheet = workbook.getWorksheet(
          lavanderia.denoPais.toString(),
        );
        let worksheet;
        if (existingWorksheet) {
          worksheet = existingWorksheet;
        } else {
          rowInicio = 0;
          worksheet = workbook.addWorksheet(lavanderia.denoPais.toString());
        }

        const isLavanderia = lavanderia.idEntidad == null;
        const lavAbreviatura =
          _this.enum_abreviaturas_lavanderias_iberostar.filter(
            (item) => item.idLavanderia === parseInt(lavanderia.idLavanderia),
          );
        const entiAbreviatura =
          _this.enum_abreviaturas_entidades_iberostar.filter(
            (item) => item.idEntidad === parseInt(lavanderia.idEntidad),
          );
        const valoresPorMes = lavanderia.values.filter(
          (x) =>
            !x.isTotalAnual && new Date(x.fecha).getFullYear() == currentYear,
        );
        const totalesAnuales = lavanderia.values.filter(
          (x) =>
            x.isTotalAnual &&
            (isLavanderia ? x.idEntidad == null : x.idEntidad !== null),
        );

        const grayColor_dark = { argb: "FF999999" };
        const grayColor_light = { argb: "FFE5E7EB" };
        const greenColor = { argb: "FFA9C47F" };
        const tealColor = { argb: "FF67BAAF" };
        const headerColor = { argb: "FF002855" };
        const whiteColor = { argb: "FFFFFFFF" };
        const blackColor = { argb: "00000000" };
        const redColor = { argb: "FFE5554F" };
        const brightRedColor = { argb: "FF0000" };

        //#region Config y estilos Header y Subheader
        let headers = [
          "OBJETIVO " + currentYear,
          "ENERO",
          "FEBRERO",
          "MARZO",
          "ABRIL",
          "MAYO",
          "JUNIO",
          "JULIO",
          "AGOSTO",
          "SEPTIEMBRE",
          "OCTUBRE",
          "NOVIEMBRE",
          "DICIEMBRE",
          "PROMEDIO " + currentYear,
          "PROMEDIO " + lastYear,
          "PROMEDIO " + yearBeforeLast,
        ];
        var subheaders_lavanderia = [
          { id: null, denominacion: "INFORME" },
          { id: null, denominacion: "KG PROCESADOS" },
          { id: null, denominacion: "KG LAVADOS POLARIER" },
          { id: null, denominacion: "KG LAVADOS ECOLAB" },
          { id: null, denominacion: "KG. LAVADOS / PROCESADOS" },
          { id: null, denominacion: "RECHAZO" },
          { id: null, denominacion: "AGUA" },
          { id: null, denominacion: "ELECTRICIDAD" },
          { id: null, denominacion: "CALEFACCIÓN" },
          { id: null, denominacion: "RENDIMIENTO" },
        ];
        var subheaders_compañia = [
          { id: null, denominacion: "INFORME" },
          { id: null, denominacion: "ESTANCIAS" },
          { id: null, denominacion: "KG LAVADOS POLARIER" },
          { id: null, denominacion: "KGS. X ESTANCIA" },
          { id: 2, denominacion: "RATIO SABANA X ESTANCIA" },
          { id: 6, denominacion: "RATIO TOALLA BAÑO X ESTANCIA" },
          { id: 8, denominacion: "RATIO TOALLA MANO X ESTANCIA" },
          { id: 11, denominacion: "RATIO FACIAL X ESTANCIA" },
          { id: 9, denominacion: "RATIO ALFOMBRIN X ESTANCIA" },
          { id: 20, denominacion: "RATIO TOALLA PISCINA X ESTANCIA" },
          // { id: null, denominacion: 'RATIO PROTECTOR COLCHON MATRI' },
          // { id: null, denominacion: 'RATIO PROTECTOR COLCHON KING' },
          // { id: null, denominacion: 'RATIO EDREDON MATRI' },
          // { id: null, denominacion: 'RATIO EDREDON KING' },
          // { id: null, denominacion: 'PROTECTOR DE ALMOHADA MATRI' },
          // { id: null, denominacion: 'PROTECTOR DE ALMOHADA KING' },
          { id: 16, denominacion: "RATIO SERVILLETA X ESTANCIA" },
          { id: 7, denominacion: "RATIO TOALLA DUCHA X ESTANCIA" },
        ];
        const subheaders = isLavanderia
          ? subheaders_lavanderia
          : subheaders_compañia;
        headers = [null, null, null, ...headers];

        const camposLavanderia = [
          "kgProcesado",
          "kgLavados",
          "kgLavadosEcolab",
          "kgLavProc",
          "mediaRechazo",
          "mediaAgua",
          "mediaElectricidad",
          "mediaCalefaccion",
          "mediaRendimiento",
        ];
        const camposCompañia = [
          "estancias",
          "kgLavados",
          "kgEstancia",
          "ratioEstancia",
        ];
        const campos = isLavanderia ? camposLavanderia : camposCompañia;

        if (rowInicio === 0) {
          worksheet.addRow(headers, rowInicio);
          worksheet.views = [
            {
              state: "frozen",
              xSplit: 3,
              ySplit: 1,
              activeCell: "D2",
              showGridLines: false,
            },
          ];
        }

        // Set subheaders en archivo
        for (let i = 0; i < subheaders.length; i++) {
          let row = Array(headers.length).fill(null);
          row[rowInicio + 2] = subheaders[i].denominacion;
          worksheet.getCell(rowInicio + 2 + i, 3).value =
            subheaders[i].denominacion;
        }

        if (rowInicio === 0) {
          worksheet.getRow(1 + rowInicio).font = { bold: true };
          worksheet.getRow(1 + rowInicio).height = 50;
        }
        for (let i = 2; i <= 1 + subheaders.length; i++) {
          worksheet.getRow(i + rowInicio).font = { bold: true };
        }
        //#endregion

        // Estilos grid lavs/compa
        for (let row = 2; row <= subheaders.length + 1; row++) {
          for (let col = 4; col <= 19; col++) {
            // Estilo celdas de grid
            let cell = worksheet.getCell(row + rowInicio, col);
            cell.border = {
              top: { style: "dotted", color: grayColor_dark },
              left: { style: "dotted", color: grayColor_dark },
            };

            // Relleno columnas Kpis objetivo y segunda row
            if (col === 4 || (row === 2 && col >= 4 && col < 20)) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: grayColor_light,
              };
            }
          }
        }

        //#region Width columnas
        worksheet.columns = headers.map((_) => ({ width: 12 }));
        worksheet.getColumn(1).width = 1;
        worksheet.getColumn(2).width = 10;
        worksheet.getColumn(3).width = 30;
        //#endregion

        //#region Estilos
        worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
          row.eachCell({ includeEmpty: true }, function (col, colNumber) {
            if (rowNumber > rowInicio) {
              if (rowNumber >= 2 && rowNumber <= subheaders.length + 1) {
                let cell = worksheet.getCell(rowInicio + rowNumber, 19);
                cell.border = {
                  top: { style: "dotted", color: grayColor_dark },
                  left: { style: "dotted", color: grayColor_dark },
                  bottom: { style: "dotted", color: grayColor_dark },
                  right: { style: "thin", color: grayColor_dark },
                };
              }

              //#region Estilos headers
              if (colNumber >= 4 && colNumber <= 19) {
                let cell = worksheet.getCell(1, colNumber);
                cell.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: headerColor.argb },
                };

                cell.font = {
                  color: whiteColor,
                  bold: true,
                };

                cell.border = {
                  right: { style: "thin", color: { argb: "FFFFFFFF" } },
                };

                //Estilo bordes grid
                worksheet.getCell(
                  rowInicio + subheaders.length + 1,
                  colNumber,
                ).border = {
                  top: { style: "dotted", color: grayColor_dark },
                  left: { style: "dotted", color: grayColor_dark },
                  right: { style: "dotted", color: grayColor_dark },
                  bottom: { style: "thin", color: grayColor_dark },
                };
              }
              //#region

              //Estilo fuente general
              col.font = {
                name: "Nunito",
                size: colNumber === 3 ? 8 : 10,
                bold: colNumber === 2 || colNumber === 3 || colNumber === 4,
                color:
                  !isLavanderia && colNumber === 2 ? whiteColor : blackColor,
              };

              //Título informe verde
              if (rowNumber === 2 + rowInicio && colNumber === 3) {
                col.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: greenColor,
                };
              }

              // Centrar texto nombre lavandería/compañía
              if (rowNumber === 1)
                col.alignment = {
                  wrapText: true,
                  shrinkToFit: true,
                  vertical: "middle",
                  horizontal: "center",
                };
            }
          });
        });

        //#region Estilos grid general país
        for (let col = 4; col <= 19; col++) {
          let cell = worksheet.getCell(rowInicio + subheaders.length + 1, col);
          cell.border = {
            top: { style: "dotted", color: grayColor_dark },
            left: { style: "dotted", color: grayColor_dark },
            right: { style: "dotted", color: grayColor_dark },
            bottom: { style: "thin", color: grayColor_dark },
          };
        }

        for (let row = 2; row <= subheaders.length + 1; row++) {
          let cell = worksheet.getCell(rowInicio + row, 19);
          cell.border = {
            top: { style: "dotted", color: grayColor_dark },
            left: { style: "dotted", color: grayColor_dark },
            bottom: { style: "dotted", color: grayColor_dark },
            right: { style: "thin", color: grayColor_dark },
          };
        }
        //#endregion

        //Columna Objetivo Año actual
        for (let row = 2; row <= subheaders.length + 1; row++) {
          worksheet.getCell(row + rowInicio, 4).alignment = {
            horizontal: "center",
            vertical: "middle",
          };
          worksheet.getCell(row + rowInicio, 4).font = { bold: true };
        }

        //#region Columna objetivos año actual
        if (valoresPorMes[0] != null) {
          if (isLavanderia) {
            worksheet.getCell(6 + rowInicio, 4).value =
              valoresPorMes[0].difKpiLavado != null
                ? formatNumber(valoresPorMes[0].difKpiLavado, 2, "percent")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].difKpiLavado);
            worksheet.getCell(7 + rowInicio, 4).value =
              valoresPorMes[0].kpi_rechazo != null
                ? formatNumber(
                    valoresPorMes[0].kpi_rechazo * 0.01,
                    2,
                    "percent",
                  )
                : _this.setGuionIfNullOrZero(
                    valoresPorMes[0].kpi_rechazo * 0.01,
                  );
            worksheet.getCell(8 + rowInicio, 4).value =
              valoresPorMes[0].kpi_agua != null
                ? formatNumber(valoresPorMes[0].kpi_agua, 2, "decimal")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].kpi_agua);
            worksheet.getCell(9 + rowInicio, 4).value =
              valoresPorMes[0].kpi_electricidad != null
                ? formatNumber(valoresPorMes[0].kpi_electricidad, 3, "decimal")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].kpi_electricidad);
            worksheet.getCell(10 + rowInicio, 4).value =
              valoresPorMes[0].kpi_calefaccion != null
                ? formatNumber(valoresPorMes[0].kpi_calefaccion, 2, "decimal")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].kpi_calefaccion);
            worksheet.getCell(subheaders.length + 1 + rowInicio, 4).value =
              valoresPorMes[0].kpi_rendimiento != null
                ? formatNumber(valoresPorMes[0].kpi_rendimiento, 2, "decimal")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].kpi_rendimiento);
          } else {
            worksheet.getCell(5 + rowInicio, 4).value =
              valoresPorMes[0].objKgEstancia != null
                ? formatNumber(valoresPorMes[0].objKgEstancia, 1, "decimal")
                : _this.setGuionIfNullOrZero(valoresPorMes[0].objKgEstancia);
          }
        }
        //#endregion

        //#region Valores por mes de año actual
        for (let col = 0; col < valoresPorMes.length; col++) {
          if (valoresPorMes[col] == null) return;

          const mes = new Date(valoresPorMes[col].fecha).getMonth();

          for (let row = 0; row <= subheaders.length + 1; row++) {
            worksheet.getCell(rowInicio + row, mes + 5).alignment = {
              horizontal: "center",
              vertical: "middle",
            };

            // Poner los totales, column 17
            if (
              (isLavanderia && row >= 3 && row !== 5) ||
              (!isLavanderia && row >= 3 && row <= 12)
            ) {
              const formula =
                "IFERROR(AVERAGE(E" +
                (row + rowInicio) +
                ":P" +
                (row + rowInicio) +
                '), "")';
              const cell = worksheet.getCell("Q" + (row + rowInicio));
              cell.value = {
                formula: formula,
              };

              if (row === 5) {
                const items = valoresPorMes.filter(
                  (x) =>
                    new Date(x.fecha).getFullYear() === currentYear &&
                    x.kgEstancia != null,
                );
                let sumOfKgEstancias = items.reduce(
                  (accumulator, currentItem) => {
                    if (!isNaN(currentItem.kgEstancia)) {
                      return accumulator + currentItem.kgEstancia;
                    } else {
                      return accumulator;
                    }
                  },
                  0,
                );

                const value =
                  items.length === 0 ? 0 : sumOfKgEstancias / items.length;
                const kpi = valoresPorMes[0]?.objKgEstancia ?? 0;

                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              }
            }
            if (row > 1)
              worksheet.getCell(row + rowInicio, 17).alignment = {
                horizontal: "center",
                vertical: "middle",
              };
            worksheet.getCell(5 + rowInicio, 17).numFmt = isLavanderia
              ? "#,##0.00%"
              : "#,##0.000";
            worksheet.getCell(6 + rowInicio, 17).numFmt = isLavanderia
              ? "#,##0.00%"
              : "#,##0.000";
            worksheet.getCell(7 + rowInicio, 17).numFmt = isLavanderia
              ? "#,##0.00%"
              : "#,##0.000";

            if (row === 3 || row === 4)
              worksheet.getCell(rowInicio + row, 17).numFmt = "#,##0";
            else if (row === 9)
              worksheet.getCell(rowInicio + row, 17).numFmt = "#,##0.000";
            else if (row === 8 || row === 10 || row === subheaders.length + 1)
              worksheet.getCell(rowInicio + row, 17).numFmt = isLavanderia
                ? "#,##0.00"
                : "#,##0.000";

            if (row >= 6 && row <= 12 && !isLavanderia) {
              worksheet.getCell(rowInicio + row, 17).numFmt = "#,##0.000";
            }
          }

          for (let subH = 0; subH <= subheaders.length; subH++) {
            if (subH === 0) continue; // Título informe

            const subHIndex = subH - 1;
            const rowIndex = subH + 2;
            const cell = worksheet.getCell(rowIndex + rowInicio, mes + 5);
            const valoresGeneralesCompañia = valoresPorMes.filter(
              (x) => x.idTipoPrenda == null,
            );

            if (rowIndex === 3) {
              if (!isLavanderia) {
                if (valoresGeneralesCompañia.length > col)
                  cell.value = valoresGeneralesCompañia[col][campos[subHIndex]];
              } else {
                cell.value = valoresPorMes[col][campos[subHIndex]];
              }
              cell.numFmt = "#,##0";
            } else if (rowIndex === 4) {
              if (!isLavanderia) {
                if (valoresGeneralesCompañia.length > col) {
                  cell.value = valoresGeneralesCompañia[col][campos[subHIndex]];
                }
              } else {
                cell.value =
                  valoresPorMes[col][campos[subHIndex]] === 0
                    ? ""
                    : valoresPorMes[col][campos[subHIndex]];
              }
              cell.numFmt = "#,##0";
            } else if (rowIndex === 5) {
              if (!isLavanderia) {
                if (valoresGeneralesCompañia.length > col) {
                  const value =
                    valoresGeneralesCompañia[col][campos[subHIndex]];
                  const kpi = valoresPorMes[0]?.objKgEstancia ?? 0;

                  cell.value = valoresGeneralesCompañia[col][campos[subHIndex]];
                  cell.font = {
                    color: value > kpi ? brightRedColor : blackColor,
                  };
                }
              } else {
                cell.value = "";
              }
              cell.numFmt = "#,##0.00";
            } else if (rowIndex === 6) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].difKpiLavado;

                cell.value = value === 0 ? "" : value;
                cell.numFmt = "#,##0.00%";
                cell.font = {
                  color: value < kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 7) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].kpi_rechazo;

                cell.value = value == null ? "" : value * 0.01;
                cell.numFmt = "#,##0.00%";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 8) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].kpi_agua;

                cell.value = value;
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 9) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].kpi_electricidad;

                cell.value = value;
                cell.numFmt = "#,##0.000";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 10) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].kpi_calefaccion;

                cell.value = value;
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 11) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              } else {
                const value = valoresPorMes[col][campos[subHIndex]];
                const kpi = valoresPorMes[0].kpi_rendimiento;

                cell.value = value;
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value < kpi ? brightRedColor : blackColor,
                };
              }
            } else if (rowIndex === 12) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              }
            } else if (rowIndex === 13) {
              if (!isLavanderia) {
                const valoresTipoPrendaEntidad = valoresPorMes.filter(
                  (x) => x.idTipoPrenda == subheaders[subH].id,
                );
                if (valoresTipoPrendaEntidad.length > col)
                  cell.value = valoresTipoPrendaEntidad[col]["ratioEstancia"];
                cell.numFmt = "#,##0.000";
              }
            }
          }
        }
        //#endregion

        //#region Valores anuales
        totalesAnuales.sort((a, b) => {
          const fechaA = new Date(a.fecha);
          const fechaB = new Date(b.fecha);
          return fechaB - fechaA;
        });

        if (isLavanderia) {
          for (let i = 0; i < totalesAnuales.length; i++) {
            let col = 17 + i;

            for (let row = 0; row <= subheaders.length + 1; row++) {
              if (row < 3) continue;
              worksheet.getCell(row + rowInicio, col).alignment = {
                horizontal: "center",
                vertical: "middle",
              };
            }

            for (let subH = 0; subH <= subheaders.length; subH++) {
              if (subH === 0) continue; // Título informe

              const rowIndex = subH + 2;
              const cell = worksheet.getCell(rowIndex + rowInicio, col);

              if (rowIndex === 3) {
                if (col > 17) {
                  //No promedio
                  cell.value = totalesAnuales[i].kgProcesado;
                }
                cell.numFmt = "#,##0";
              } else if (rowIndex === 4) {
                if (col > 17) {
                  //No promedio
                  cell.value =
                    totalesAnuales[i].kgLavados === 0
                      ? ""
                      : totalesAnuales[i].kgLavados;
                }
                cell.numFmt = "#,##0";
              } else if (rowIndex === 5) {
                if (col > 17) {
                  //No promedio
                  cell.value = "";
                }
              } else if (rowIndex === 6) {
                const value = totalesAnuales[i].kgLavProc;
                const kpi = valoresPorMes[0].difKpiLavado / 100;

                if (col > 17) {
                  //No promedio
                  cell.value = value == 0 ? "" : value;
                }
                cell.numFmt = "#,##0.00%";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              } else if (rowIndex === 7) {
                const value = totalesAnuales[i].mediaRechazo;
                const kpi = valoresPorMes[0].kpi_rechazo / 100;

                if (col > 17) {
                  //No promedio
                  cell.value = value == null ? "" : value;
                }
                cell.numFmt = "#,##0.00%";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              } else if (rowIndex === 8) {
                const value = totalesAnuales[i].mediaAgua;
                const kpi = valoresPorMes[0].kpi_agua;

                if (col > 17) {
                  //No promedio
                  cell.value = value;
                }
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              } else if (rowIndex === 9) {
                const value = totalesAnuales[i].mediaElectricidad;
                const kpi = valoresPorMes[0].kpi_electricidad;

                if (col > 17) {
                  //No promedio
                  cell.value = value;
                }
                cell.numFmt = "#,##0.000";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              } else if (rowIndex === 10) {
                const value = totalesAnuales[i].mediaCalefaccion;
                const kpi = valoresPorMes[0].kpi_calefaccion;

                if (col > 17) {
                  //No promedio
                  cell.value = value;
                }
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value > kpi ? brightRedColor : blackColor,
                };
              } else if (rowIndex === 11) {
                const value = totalesAnuales[i].mediaRendimiento;
                const kpi = valoresPorMes[0].kpi_rendimiento;

                if (col > 17) {
                  //No promedio
                  cell.value = value;
                }
                cell.numFmt = "#,##0.00";
                cell.font = {
                  color: value < kpi ? brightRedColor : blackColor,
                };
              }
            }
          }
        } else {
          for (let i = 0; i < totalesAnuales.length; i++) {
            let col = 17 + i;
            let año = col === 18 ? lastYear : yearBeforeLast;

            if (col >= 20) break;

            for (let row = 0; row <= subheaders.length + 1; row++) {
              if (row < 3) continue;
              worksheet.getCell(row + rowInicio, col).alignment = {
                horizontal: "center",
                vertical: "middle",
              };
            }

            for (let subH = 0; subH <= subheaders.length; subH++) {
              if (subH === 0) continue; // Título informe

              const rowIndex = subH + 2;
              const cell = worksheet.getCell(rowIndex + rowInicio, col);

              if (rowIndex === 3) {
                const valoresGeneralEntidad = totalesAnuales.filter(
                  (x) =>
                    x.estancias != null &&
                    new Date(x.fecha).getFullYear() === año,
                );
                if (valoresGeneralEntidad.length > 0) {
                  if (col > 17) {
                    //No promedio
                    cell.value = valoresGeneralEntidad[0]["estancias"];
                  }
                }
                cell.numFmt = "#,##0";
              } else if (rowIndex === 4) {
                const valoresGeneralEntidad = totalesAnuales.filter(
                  (x) =>
                    x.kgLavados != null &&
                    new Date(x.fecha).getFullYear() === año,
                );
                if (valoresGeneralEntidad.length > 0) {
                  if (col > 17) {
                    //No promedio
                    cell.value = valoresGeneralEntidad[0]["kgLavados"];
                  }
                }
                cell.numFmt = "#,##0";
              } else if (rowIndex === 5) {
                const valoresGeneralEntidad = totalesAnuales.filter(
                  (x) =>
                    x.kgEstancia != null &&
                    new Date(x.fecha).getFullYear() === año,
                );
                if (valoresGeneralEntidad.length > 0) {
                  const value = valoresGeneralEntidad[0]["kgEstancia"];
                  const kpi = valoresPorMes[0]?.objKgEstancia ?? 0;

                  if (col > 17) {
                    //No promedio
                    cell.value = value;
                  }
                  cell.font = {
                    color: value > kpi ? brightRedColor : blackColor,
                  };
                }
                cell.numFmt = "#,##0.000";
              } else if (rowIndex >= 6 && rowIndex <= 12) {
                const valoresTipoPrendaEntidad = totalesAnuales.filter(
                  (x) =>
                    x.idTipoPrenda == subheaders[subH].id &&
                    new Date(x.fecha).getFullYear() === año,
                );
                if (valoresTipoPrendaEntidad.length > 0) {
                  if (col > 17) {
                    //No promedio
                    cell.value = valoresTipoPrendaEntidad[0]["ratioEstancia"];
                  }
                }
                cell.numFmt = "#,##0.000";
              }
            }
          }
        }

        //#endregion

        //#region Celdas nombre lavandería/Entidades
        let psoCell = worksheet.getCell(2 + rowInicio, 2);

        worksheet.mergeCells(
          2 + rowInicio,
          2,
          rowInicio + subheaders.length + 1,
          2,
        );
        psoCell.value = isLavanderia
          ? lavAbreviatura[0].abreviatura
          : entiAbreviatura.length > 0
            ? entiAbreviatura[0].abreviatura
            : lavanderia.idEntidad;
        psoCell.alignment = {
          wrapText: true,
          shrinkToFit: true,
          vertical: "middle",
          horizontal: "center",
        };
        psoCell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: isLavanderia ? tealColor : redColor,
        };
        //#endregion

        rowInicio += subheaders.length + 1;
      });

      workbook.xlsx
        .writeBuffer()
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            currentYear + " - KPI'S My Polarier.xlsx",
          );
          _this.setState({
            isVisible_LoadPanel_ProgressBar: false,
            progress: 100,
          });
        })
        .then(function () {});
    });
  }

  setGuionIfNullOrZero(valor) {
    return valor == null || valor === 0 ? "-" : valor;
  }

  //PRINT BUTTON
  getPrintButton() {
    let { dxMultiView_index } = this.state;
    return (
      <Fragment>
        <div
          id="printButton"
          className="dx-icon-print container_spanCentrado font-size-xxl pr-3"
          onClick={this.printButton_onClick}
          hidden={dxMultiView_index === 0}
          style={{ height: "50px" }}
        />
        <Tooltip
          target="#printButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("imprimir")}</div>
        </Tooltip>
      </Fragment>
    );
  }
  printButton_onClick() {
    const { lavanderia, user } = this.props;
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    var params = {
      idLavanderia: lavanderia.idLavanderia,
      idUsuario: user.idUsuario,
      fechaDesde: formatDate_parameter(fechaDesde),
      fechaHasta: formatDate_parameter(fechaHasta),
    };

    this.setParams(params);
  }

  //BACK BUTTON
  getBackButton() {
    let { dxMultiView_index } = this.state;
    return (
      <Fragment>
        <div
          id="backButtonLav"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_onClick}
          hidden={dxMultiView_index === 0}
        />
        <Tooltip
          target="#backButtonLav"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("volverLavanderia")}</div>
        </Tooltip>
      </Fragment>
    );
  }
  backButton_onClick() {
    this.setState({
      dxMultiView_index: 0,
      kgEstancia_compañiaSel: null,
      logistica_compañiaSel: null,
      selectedIndex_Produccion: 0,
      selectedIndex_Valet: 0,
    });
  }

  //SELECTOR INFORMES
  onCardClick_Informes(informe) {}

  dxScrollView_onScroll(e) {
    $.each($(".iconHeader_tarjeta_DashboardEjecutivo"), function (index, item) {
      let dxType = $(item).data().dxComponents[0];
      if (dxType == "dxDropDownButton") {
        $(item)[dxType]("instance").close();
      }
    });
  }

  onClick_Button_tarjetaProduccion() {
    let params = {
      fechaSel: this.state.fechaSel,
      fechaDesde: formatDate_parameter(this.state.fechaSel),
      fechaHasta: formatDate_parameter(endOfMonth(this.state.fechaSel)),
      selectedTab: $("#dxGallery_Produccion")
        .dxGallery("instance")
        .option("selectedIndex"),
      nombre_paginaSecundaria: getTrad("infOperativo"),
      index_paginaSecundaria: 1,
    };
    this.setState({
      param_paginaSecundaria: params,
    });
  }

  onSelectionChanged_dxGallery_Produccion(e) {
    this.setState({
      selectedIndex_Produccion: e.component.option("selectedIndex"),
    });
  }

  onSelectionChanged_dxGallery_Valet(e) {
    this.setState({ selectedIndex_Valet: e.component.option("selectedIndex") });
  }

  //INFORME PRODUCCIÓN
  get_HeaderContent_Produccion() {
    let is_pathname_exists = this.is_pathname_exists(4);
    if (!is_pathname_exists) return <div></div>;

    return (
      <Button
        elementAttr={this.DropDownButton_tarjeta_elementAttr}
        icon="export"
        stylingMode="text"
        hoverStateEnabled={false}
        focusStateEnabled={false}
        activeStateEnabled={false}
        width={28}
        onClick={this.onClick_Button_tarjetaProduccion}
      />
    );
  }
  get_BodyContent_Produccion(group) {
    const { lavanderia } = this.props;
    const { datosSelector_Lavanderia, selectedIndex_Produccion } = this.state;
    let lavanderiaSel = datosSelector_Lavanderia.filter(
      (x) => x.id === lavanderia.idLavanderia,
    )[0];
    if (lavanderiaSel) {
      return (
        <div className="he-100 w-100 d-flex flex-column">
          <Gallery
            id="dxGallery_Produccion"
            className={"topNavButtons outIndicators"}
            slideshowDelay={0}
            showNavButtons={true}
            showIndicator={true}
            selectedIndex={selectedIndex_Produccion}
            width="100%"
            height="100%"
            onSelectionChanged={this.onSelectionChanged_dxGallery_Produccion}
          >
            <GalleryItem>{this.getChart_Produccion(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Rendimiento(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Rechazo(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Agua(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Eletricidad(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Calefaccion(group)}</GalleryItem>
          </Gallery>
          <div>
            <Row className="pl-3 pr-3">
              <Col
                id="content_produccion_kgLavProc"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Kg" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("porcentaje_Procesados_Lavados")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaKgProcesado !== null &&
                    lavanderiaSel.mediaKgLavado
                      ? lavanderiaSel.difKpiLavado <
                        Math.abs(
                          (lavanderiaSel.mediaKgProcesado -
                            lavanderiaSel.mediaKgLavado) /
                            lavanderiaSel.mediaKgLavado,
                        )
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaKgProcesado !== null &&
                  lavanderiaSel.mediaKgLavado
                    ? formatNumber(
                        ((lavanderiaSel.mediaKgProcesado -
                          lavanderiaSel.mediaKgLavado) /
                          lavanderiaSel.mediaKgLavado) *
                          100,
                        2,
                      )
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  %
                </div>
              </Col>
              <Col
                id="content_produccion_agua"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Agua" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("agua")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaAgua !== null
                      ? lavanderiaSel.kpi_agua < lavanderiaSel.mediaAgua
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaAgua !== null
                    ? formatNumber(lavanderiaSel.mediaAgua)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  L/Kg.
                </div>
              </Col>
              <Col
                id="content_produccion_rendimiento"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_timer" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("rendimiento")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaRendimiento !== null
                      ? lavanderiaSel.kpi_rendimiento >
                        lavanderiaSel.mediaRendimiento
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaRendimiento !== null
                    ? formatNumber(lavanderiaSel.mediaRendimiento)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kg./H.
                </div>
              </Col>
              <Col
                id="content_produccion_electricidad"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Electricidad" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("electricidad")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaElectricidad !== null
                      ? lavanderiaSel.kpi_electricidad <
                        lavanderiaSel.mediaElectricidad
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaElectricidad !== null
                    ? formatNumber(lavanderiaSel.mediaElectricidad)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kwh/Kg.
                </div>
              </Col>
              <Col
                id="content_produccion_rechazo"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Rechazo" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("rechazo")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaRechazo !== null
                      ? lavanderiaSel.kpi_rechazo < lavanderiaSel.mediaRechazo
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaRechazo !== null
                    ? formatNumber(lavanderiaSel.mediaRechazo)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  %
                </div>
              </Col>
              <Col
                id="content_produccion_calefaccion"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Calefaccion" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("calefaccion")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaCalefaccion !== null
                      ? lavanderiaSel.kpi_calefaccion <
                        lavanderiaSel.mediaCalefaccion
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaCalefaccion !== null
                    ? formatNumber(lavanderiaSel.mediaCalefaccion)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kwh/Kg.
                </div>
              </Col>
              <Tooltip
                target="#content_produccion_kgLavProc"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("porcentaje_Procesados_Lavados")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.difKpiLavado} %
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_difKgLavProc}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_produccion_agua"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("agua")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_agua} L/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_agua}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_produccion_rendimiento"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("rendimiento")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_rendimiento} Kg./H.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_rendimiento}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_produccion_electricidad"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("electricidad")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_electricidad} Kwh/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_electricidad}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_produccion_rechazo"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("rechazo")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_rechazo} %
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_rechazo}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_produccion_calefaccion"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("calefaccion")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_calefaccion} Kwh/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_calefaccion}
                  </div>
                </div>
              </Tooltip>
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  getChart_Produccion(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_kgProcesadosLavados"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("produccion")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("kgLavados")}
          valueField="kgLavados"
          type="spline"
          color="var(--secondary)"
        />
        <Series
          name={getTrad("kgProcesados")}
          valueField="kgProcesado"
          type="area"
          color="var(--primary)"
        />
        <ValueAxis position="left" maxValueMargin={0.3} minValueMargin={0} />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Rechazo(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_kgRechazados"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("rechazo")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_rechazo"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kgRechazados")}
          valueField="rechazo"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.rechazo}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Agua(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Agua"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("agua")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_agua"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("lAgua_kgProcesados")}
          valueField="agua"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.agua}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Eletricidad(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Electricidad"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("electricidad")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_electricidad"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kwhElectricidad_kgProc")}
          valueField="electricidad"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.electricidad}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Calefaccion(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Calefaccion"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("calefaccion")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_calefaccion"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kwhCalefaccion_kgProc")}
          valueField="calefaccion"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.calefaccion}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Rendimiento(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Rendimiento"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("rendimiento")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_rendimiento"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kgProc_HorasProd")}
          valueField="rendimiento"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.rendimiento}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }

  is_pathname_exists(idFormulario) {
    let { lavanderia } = this.props;
    return $.inArray(idFormulario, lavanderia.visiblePages) > -1;
  }

  //INFORME LOGÍSTICA
  get_HeaderContent_Logistica() {
    let _this = this;
    let items = $.grep(this.enum_link_tarjetaLogistica, function (item) {
      return _this.is_pathname_exists(item.idFormulario);
    });
    if (items.length === 0) return <div></div>;

    return (
      <DropDownButton
        elementAttr={this.DropDownButton_tarjeta_elementAttr}
        icon="export"
        stylingMode="text"
        showArrowIcon={false}
        useSelectMode={false}
        hoverStateEnabled={false}
        focusStateEnabled={false}
        items={items}
        displayExpr="denominacion"
        keyExpr="idButton"
        dropDownOptions={{ width: 200 }}
        onItemClick={this.onItemClick_DropDownButton_tarjetaLogistica}
      />
    );
  }
  get_BodyContent_Logistica(group) {
    const { logistica_compañiaSel } = this.state;

    let data = logistica_compañiaSel
      ? logistica_compañiaSel.tblEntidad
      : group.tblCompañia;
    data = data.filter((x) => x.kg > 0);

    return (
      <Fragment>
        <Box direction="col" width="100%" height={"100%"}>
          <ItemBox baseSize={"auto"} visible={logistica_compañiaSel}>
            <Row className="pl-3 align-items-center">
              <div
                className="backButton dx-icon-chevronleft container_spanCentrado"
                onClick={this.backButton_Logistica_onClick}
              />
              <div className="flex-fill font-weight-light font-size-xl pl-4">
                {logistica_compañiaSel
                  ? logistica_compañiaSel.denominacion
                  : ""}
              </div>
            </Row>
          </ItemBox>
          <ItemBox ratio={1}>
            <PieChart
              className="he-100"
              id="pie-chart"
              dataSource={
                logistica_compañiaSel
                  ? logistica_compañiaSel.tblEntidad
                  : group.tblCompañia
              }
              resolveLabelOverlapping="shift"
              sizeGroup="piesGroup"
              innerRadius={0.65}
              type="doughnut"
              startAngle={90}
              centerRender={this.pieChart_Logistica_centerRender}
              onPointClick={
                logistica_compañiaSel
                  ? "undefined"
                  : this.pieChart_Logistica_onPointClick
              }
            >
              <PieSeries argumentField={"denominacion"} valueField="kg">
                <PieSmallValuesGrouping
                  mode="topN"
                  topCount={18}
                  groupName={getTrad("otros").toUpperCase()}
                />
                <PieLabel
                  visible={true}
                  position="columns"
                  format="fixedPoint"
                  customizeText={this.pieChart_Logistica_customizeLabel}
                  backgroundColor="none"
                >
                  <Connector visible={true}></Connector>
                </PieLabel>
              </PieSeries>
              <PieLegend visible={false}></PieLegend>
            </PieChart>
          </ItemBox>
        </Box>
        {data.length == 0 ? (
          <div className="container_spanCentrado font-size-xxl w-100 position-absolute bg-white">
            {getTrad("sinDatos")}
          </div>
        ) : null}
      </Fragment>
    );
  }
  pieChart_Logistica_centerRender(pieChart) {
    const points = pieChart.getAllSeries()[0].getVisiblePoints();
    if (points.length > 0) {
      let totalKg = formatNumber(
        points.reduce((total, point) => total + point.originalValue, 0),
        0,
      );
      return (
        <svg>
          <circle
            cx="100"
            cy="100"
            r={pieChart.getInnerRadius() - 6}
            fill="#eee"
          ></circle>
          <text
            textAnchor="middle"
            x="100"
            y="100"
            className="font-size-lg"
            style={{ fill: "#494949" }}
          >
            <tspan x="100" dy="10px" style={{ fontWeight: 600 }}>
              {totalKg + " Kg."}
            </tspan>
          </text>
        </svg>
      );
    } else {
      return null;
    }
  }
  pieChart_Logistica_customizeLabel(e) {
    return `<b>${e.argumentText}</b>\n${formatNumber(e.value, 0)} Kg. (${e.percentText})`;
  }
  pieChart_Logistica_onPointClick(e) {
    this.setState({ logistica_compañiaSel: e.target.data });
  }
  backButton_Logistica_onClick() {
    this.setState({ logistica_compañiaSel: null });
  }
  onItemClick_DropDownButton_tarjetaLogistica(e) {
    let { idButton } = e.itemData;

    let params = {
      fechaDesde: new Date(this.state.fechaSel),
      fechaHasta: new Date(endOfMonth(this.state.fechaSel)),
      idCompañiaSel:
        this.state.logistica_compañiaSel != null
          ? this.state.logistica_compañiaSel.idCompañia
          : -1,
      selectedTab: null,
      nombre_paginaSecundaria:
        idButton === 1
          ? getTrad("infReposicionesGeneral")
          : getTrad("infReposicionesOffice"),
      index_paginaSecundaria: idButton + 1,
    };
    this.setState({
      param_paginaSecundaria: params,
    });
  }

  //#region SELECTOR CORPORACIÓN
  getSelectorCorporacion() {
    const { idioma } = this.props;
    let { idCorporacionSel, dxMultiView_index } = this.state;
    let is_corpUnica = this.dataSource_tblCorporacion.items().length === 1;
    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblCorporacion}
        displayExpr={function (item) {
          if (item && item.denominacion) {
            return item.denominacion.toUpperCase();
          } else if (item) {
            return getTrad("todasCorporaciones");
          } else {
            return;
          }
        }}
        disabled={is_corpUnica}
        valueExpr="idCorporacion"
        value={idCorporacionSel}
        defaultValue={idCorporacionSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todasCorporaciones")}
        onSelectionChanged={this.selectorCorporacion_onSelectionChanged}
      />
    );
  }
  selectorCorporacion_onSelectionChanged(e) {
    let { idPaisSel } = this.state;

    let idCorporacionSel = e.selectedItem.idCorporacion;
    let items = this.dataSource_spSelectDashboardEjecutivo_Selector.items();

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );
    this.setState({
      idCorporacionSel: idCorporacionSel,
      datosSelector_Lavanderia: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //INFORME KG ESTANCIA
  get_HeaderContent_KgEstancia() {
    let is_pathname_exists = this.is_pathname_exists(7); //InfLenceria
    if (!is_pathname_exists) return <div></div>;

    return (
      <Button
        elementAttr={this.DropDownButton_tarjeta_elementAttr}
        icon="export"
        stylingMode="text"
        hoverStateEnabled={false}
        focusStateEnabled={false}
        activeStateEnabled={false}
        width={28}
        onClick={this.onClick_Button_tarjetaKgEstancia}
      />
    );
  }

  onClick_Button_tarjetaKgEstancia() {
    let { kgEstancia_compañiaSel } = this.state;

    let params = {
      fechaSel: this.state.fechaSel,
      fechaDesde: new Date(this.state.fechaSel),
      fechaHasta: new Date(endOfMonth(this.state.fechaSel)),
      idCompañiaSel:
        kgEstancia_compañiaSel != null ? kgEstancia_compañiaSel.idCompañia : -1,
      selectedTab: kgEstancia_compañiaSel == null ? 0 : 1,
      nombre_paginaSecundaria: getTrad("infLenceria"),
      index_paginaSecundaria: 4,
    };
    this.setState({
      param_paginaSecundaria: params,
    });
  }

  get_BodyContent_KgEstancia(group) {
    const { kgEstancia_compañiaSel } = this.state;

    return (
      <MultiView
        height="100%"
        width="100%"
        selectedIndex={!kgEstancia_compañiaSel ? 0 : 1}
        loop={true}
        swipeEnabled={false}
        deferRendering={true}
        animationEnabled={true}
      >
        <MultiViewItem>{this.getGridCompañia_KgEstancia(group)}</MultiViewItem>
        <MultiViewItem>
          <Box direction="col" width="100%" height={"100%"}>
            <ItemBox baseSize={"auto"}>
              <Row className="pl-3 align-items-center">
                <div
                  className="backButton dx-icon-chevronleft container_spanCentrado"
                  onClick={this.backButton_KgEstancia_onClick}
                />
                <div className="flex-fill font-weight-light font-size-xl pl-4">
                  {kgEstancia_compañiaSel
                    ? kgEstancia_compañiaSel.denominacion
                    : ""}
                </div>
              </Row>
            </ItemBox>
            <ItemBox ratio={1}>{this.getGridEntidad_KgEstancia(group)}</ItemBox>
          </Box>
        </MultiViewItem>
      </MultiView>
    );
  }
  getGridCompañia_KgEstancia(group) {
    return (
      <DataGrid
        //Datos
        dataSource={group.tblCompañia}
        keyExpr="idCompañia"
        //Propiedades
        className="headerRow-lg"
        columnsAutoWidth={true}
        height={"100%"}
        width={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onRowClick={this.onRowClick_gridCompañia_KgEstancia}
        onRowPrepared={this.onRowPrepared_pointer}
      >
        <SearchPanel visible={false} />
        <Scrolling mode={"infinite"} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Paging enabled={false} />
        <Column
          dataField="denominacion"
          dataType="string"
          caption={getTrad("compañia")}
          cssClass="white-space-normal"
          sortOrder="asc"
        />
        <Column
          dataField="kgEstancia"
          dataType="number"
          caption={getTrad("kgEstancia")}
          alignment="center"
          cssClass="p-0"
          cellComponent={progressBarCellComponent}
        />
      </DataGrid>
    );
  }
  onRowClick_gridCompañia_KgEstancia(e) {
    const data = e.data;
    this.setState({ kgEstancia_compañiaSel: data });
  }
  getGridEntidad_KgEstancia(group) {
    let { kgEstancia_compañiaSel } = this.state;
    return (
      <DataGrid
        //Datos
        dataSource={
          kgEstancia_compañiaSel ? kgEstancia_compañiaSel.tblEntidad : []
        }
        keyExpr="idEntidad"
        //Propiedades
        elementAttr={{ class: "headerRow-lg" }}
        className="headerRow-lg"
        columnsAutoWidth={true}
        height={"100%"}
        width={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
      >
        <SearchPanel visible={false} />
        <Scrolling mode={"infinite"} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Paging enabled={false} />
        <Column
          dataField="denominacion"
          dataType="string"
          caption={getTrad("entidad")}
          cssClass="white-space-normal"
          sortOrder="asc"
        />
        <Column
          dataField="kgEstancia"
          dataType="number"
          caption={getTrad("kgEstancia")}
          alignment="center"
          cssClass="p-0"
          cellComponent={progressBarCellComponent}
        />
      </DataGrid>
    );
  }
  backButton_KgEstancia_onClick() {
    this.setState({ kgEstancia_compañiaSel: null });
  }

  //INFORME KG ESTANCIA
  get_HeaderContent_Mantenimiento() {
    let _this = this;
    let items = $.grep(this.enum_link_tarjetaMantenimiento, function (item) {
      return _this.is_pathname_exists(item.idFormulario);
    });
    if (items.length === 0) return <div></div>;

    return (
      <DropDownButton
        elementAttr={{
          id: "tarjeta_mantenimiento_button",
          class: "iconHeader_tarjeta_DashboardEjecutivo",
        }}
        icon="export"
        stylingMode="text"
        showArrowIcon={false}
        useSelectMode={false}
        hoverStateEnabled={false}
        focusStateEnabled={false}
        items={items}
        displayExpr="denominacion"
        keyExpr="idButton"
        dropDownOptions={{ width: 200 }}
        onItemClick={this.onItemClick_DropDownButton_tarjetaMantenimiento}
      />
    );
  }

  get_BodyContent_Mantenimiento(group) {
    if (group.lavanderia == null) {
      return (
        <div className="container_spanCentrado font-size-xxl w-100 position-absolute bg-white">
          {getTrad("sinDatos")}
        </div>
      );
    }

    let {
      operatividadLav,
      operatividadLav_amarillas,
      numInciRojas,
      numInciAmarillas,
    } = group.lavanderia;
    let _this = this;

    let value = group.preventivo;
    let color = value <= 0.5 ? "danger" : value < 1 ? "primary" : "success";

    return (
      <Fragment>
        <Col xs="3" className="he-100 p-0 ml-2">
          <Fragment>
            <Box direction="col" width="100%" height="100%" className="pt-3">
              <ItemBox ratio={1}>
                <div className="position-relative he-100">
                  <CircularGauge
                    className={"CircularGauge_selector"}
                    id={"CircularGauge_selector_mant"}
                    value={0}
                    margin={this.CircularGauge_selector_mant_margin}
                    title={this.CircularGauge_selector_mant_title}
                  >
                    <Scale startValue={0} endValue={1}>
                      <Tick visible={false} />
                      <Label visible={false} />
                    </Scale>
                    <Geometry startAngle={90} endAngle={90}></Geometry>
                    <RangeContainer
                      width={8}
                      backgroundColor="lightgrey"
                      offset={10}
                    >
                      <Range
                        startValue={0}
                        endValue={operatividadLav}
                        color="var(--success)"
                      />
                      <Range
                        startValue={operatividadLav}
                        endValue={operatividadLav + operatividadLav_amarillas}
                        color="var(--primary)"
                      />
                      <Range
                        startValue={operatividadLav + operatividadLav_amarillas}
                        endValue={1}
                        color="var(--danger)"
                      />
                    </RangeContainer>
                    <ValueIndicator
                      type="rangebar"
                      offset={0}
                      size={10}
                    ></ValueIndicator>
                    <Margin right={5} />
                  </CircularGauge>
                  <div
                    className="container_spanCentrado position-absolute w-100 he-100"
                    style={{ top: "1px", right: "0px", left: "2px" }}
                  >
                    <div className={_this.get_responsiveFont_gauge()}>
                      {numInciRojas > 0 || numInciAmarillas > 0 ? (
                        formatNumber(operatividadLav, 0, "percent")
                      ) : (
                        <div className="icon_Check success font-size-max"></div>
                      )}
                    </div>
                  </div>
                </div>
              </ItemBox>
              <ItemBox ratio={1}>
                <div className="position-relative he-100">
                  <CircularGauge
                    className={"CircularGauge_selector"}
                    id={"CircularGauge_selector_mant2"}
                    value={group.preventivo}
                    margin={this.CircularGauge_selector_mant2_margin}
                    title={this.CircularGauge_selector_mant2_title}
                  >
                    <Scale startValue={0} endValue={1}>
                      <Tick visible={false} />
                      <Label visible={false} />
                    </Scale>
                    <Geometry startAngle={90} endAngle={90}></Geometry>
                    <RangeContainer
                      width={8}
                      backgroundColor="lightgrey"
                      offset={10}
                    />
                    <ValueIndicator
                      type="rangebar"
                      color={"var(--" + color + ")"}
                      offset={0}
                      size={10}
                    ></ValueIndicator>
                    <Margin right={5} />
                  </CircularGauge>
                  <div
                    className="container_spanCentrado position-absolute w-100 he-100"
                    style={{ top: "35px", right: "0px", left: "2px" }}
                  >
                    <div className={_this.get_responsiveFont_gauge()}>
                      {formatNumber(group.preventivo, 0, "percent")}
                    </div>
                  </div>
                </div>
              </ItemBox>
            </Box>
          </Fragment>
        </Col>
        <Col xs="9" className="he-100">
          <DataGrid
            //Datos
            dataSource={group.tblMaquina}
            keyExpr="idMaquina"
            //Propiedades
            elementAttr={{ class: "headerRow-lg" }}
            className="headerRow-lg"
            columnsAutoWidth={true}
            height={"100%"}
            width={"100%"}
            hoverStateEnabled={true}
            //Estilos
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
          >
            <SearchPanel visible={false} />
            <Scrolling mode={"infinite"} />
            <HeaderFilter visible={false} />
            <FilterRow visible={false} />
            <Paging enabled={false} />
            <Column
              dataField="denominacion"
              dataType="string"
              caption={getTrad("maquina")}
            />
            <Column
              dataField="disponibilidad"
              dataType="number"
              caption={"DISPO."}
              width={80}
              alignment="center"
              cssClass="p-0"
              cellComponent={progressBarCellComponent}
              sortOrder="asc"
            />
          </DataGrid>
        </Col>
      </Fragment>
    );
  }

  onItemClick_DropDownButton_tarjetaMantenimiento(e) {
    let { idButton } = e.itemData;
    let params = {
      fechaDesde: new Date(this.state.fechaSel),
      fechaHasta: new Date(endOfMonth(this.state.fechaSel)),
      nombre_paginaSecundaria:
        idButton === 1
          ? getTrad("DashboardMantenimiento")
          : getTrad("infPreventivoSat"),
      index_paginaSecundaria: 4 + idButton,
    };
    this.setState({
      param_paginaSecundaria: params,
    });
  }

  //INFORME VALET
  get_HeaderContent_Valet() {
    return <div></div>;
  }
  get_BodyContent_Valet(group) {
    const { lavanderia } = this.props;
    const { datosSelector_Lavanderia, selectedIndex_Valet } = this.state;
    let lavanderiaSel = datosSelector_Lavanderia.filter(
      (x) => x.id === lavanderia.idLavanderia,
    )[0];

    if (lavanderiaSel) {
      return (
        <div className="he-100 w-100 d-flex flex-column">
          <Gallery
            id="dxGallery_Valet"
            className={"topNavButtons outIndicators"}
            slideshowDelay={0}
            showNavButtons={true}
            showIndicator={true}
            selectedIndex={selectedIndex_Valet}
            width="100%"
            height="100%"
            onSelectionChanged={this.onSelectionChanged_dxGallery_Valet}
          >
            <GalleryItem>{this.getChart_Produccion(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Rendimiento(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Rechazo(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Agua(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Eletricidad(group)}</GalleryItem>
            <GalleryItem>{this.getChart_Calefaccion(group)}</GalleryItem>
          </Gallery>
          <div>
            <Row className="pl-3 pr-3">
              <Col
                id="content_valet_kgLavProc"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Kg" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("porcentaje_Procesados_Lavados")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaKgProcesado_valet !== null &&
                    lavanderiaSel.mediaKgLavado_valet
                      ? lavanderiaSel.difKpiLavado_valet <
                        Math.abs(
                          (lavanderiaSel.mediaKgProcesado_valet -
                            lavanderiaSel.mediaKgLavado_valet) /
                            lavanderiaSel.mediaKgLavado_valet,
                        )
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaKgProcesado_valet !== null &&
                  lavanderiaSel.mediaKgLavado_valet
                    ? formatNumber(
                        ((lavanderiaSel.mediaKgProcesado_valet -
                          lavanderiaSel.mediaKgLavado_valet) /
                          lavanderiaSel.mediaKgLavado_valet) *
                          100,
                        2,
                      )
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  %
                </div>
              </Col>
              <Col
                id="content_valet_agua"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Agua" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("agua")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaAgua_valet !== null
                      ? lavanderiaSel.kpi_agua_valet <
                        lavanderiaSel.mediaAgua_valet
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaAgua_valet !== null
                    ? formatNumber(lavanderiaSel.mediaAgua_valet)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  L/Kg.
                </div>
              </Col>
              <Col
                id="content_valet_rendimiento"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_timer" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("rendimiento")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaRendimiento_valet !== null
                      ? lavanderiaSel.kpi_rendimiento_valet >
                        lavanderiaSel.mediaRendimiento_valet
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaRendimiento_valet !== null
                    ? formatNumber(lavanderiaSel.mediaRendimiento_valet)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kg./H.
                </div>
              </Col>
              <Col
                id="content_valet_electricidad"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Electricidad" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("electricidad")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaElectricidad_valet !== null
                      ? lavanderiaSel.kpi_electricidad_valet <
                        lavanderiaSel.mediaElectricidad_valet
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaElectricidad_valet !== null
                    ? formatNumber(lavanderiaSel.mediaElectricidad_valet)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kwh/Kg.
                </div>
              </Col>
              <Col
                id="content_valet_rechazo"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Rechazo" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("rechazo")}
                </div>
                <div className="text-center font-weight-bold font-size-xl">
                  {"-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  %
                </div>
              </Col>
              <Col
                id="content_valet_calefaccion"
                xs="6"
                className="container_spanCentrado p-1"
              >
                <div className="font-size-xxll icon_Calefaccion" />
                <div className="flex-fill pl-2 font-size-xxs text-cut">
                  {getTrad("calefaccion")}
                </div>
                <div
                  className={
                    "text-center font-weight-bold font-size-xl " +
                    (lavanderiaSel.mediaCalefaccion_valet !== null
                      ? lavanderiaSel.kpi_calefaccion_valet <
                        lavanderiaSel.mediaCalefaccion_valet
                        ? "text-danger"
                        : "text-success"
                      : "")
                  }
                >
                  {lavanderiaSel.mediaCalefaccion_valet !== null
                    ? formatNumber(lavanderiaSel.mediaCalefaccion_valet)
                    : "-"}
                </div>
                <div
                  className="opacity-5 font-size-xxxs pl-1 align-self-end pb-1"
                  style={{ flexBasis: "50px" }}
                >
                  Kwh/Kg.
                </div>
              </Col>
              <Tooltip
                target="#content_valet_kgLavProc"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("porcentaje_Procesados_Lavados")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.difKpiLavado_valet} %
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_difKgLavProc_valet}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_valet_agua"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("agua")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_agua_valet} L/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_agua}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_valet_rendimiento"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("rendimiento")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_rendimiento_valet} Kg./H.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_rendimiento}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_valet_electricidad"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("electricidad")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_electricidad_valet} Kwh/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_electricidad}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_valet_rechazo"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("rechazo")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_rechazo_valet} %
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_rechazo}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                target="#content_valet_calefaccion"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div className="tooltip_header">
                  <div className="tooltip_title text-left">
                    {" "}
                    {getTrad("calefaccion")}
                  </div>
                </div>
                <div className="tooltip_body">
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">{"KPI"}</div>
                    <div className="tooltip_value-text">
                      {lavanderiaSel.kpi_calefaccion_valet} Kwh/Kg.
                    </div>
                  </div>
                  <div className="tooltip_row">
                    <div className="tooltip_series-name text-left">
                      {getTrad("observaciones").toUpperCase()}
                    </div>
                  </div>
                  <div className="text-wrap text-left">
                    {lavanderiaSel.obs_calefaccion}
                  </div>
                </div>
              </Tooltip>
            </Row>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
  getChart_Valet(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_kgProcesadosLavados"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("produccion")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("kgLavados")}
          valueField="kgLavados"
          type="spline"
          color="var(--secondary)"
        />
        <Series
          name={getTrad("kgProcesados")}
          valueField="kgProcesado"
          type="area"
          color="var(--primary)"
        />
        <ValueAxis position="left" maxValueMargin={0.3} minValueMargin={0} />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Rechazo(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_kgRechazados"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("rechazo")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_rechazo"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kgRechazados")}
          valueField="rechazo"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.rechazo}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Agua(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Agua"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("agua")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_agua"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("lAgua_kgProcesados")}
          valueField="agua"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.agua}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Eletricidad(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Electricidad"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("electricidad")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_electricidad"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kwhElectricidad_kgProc")}
          valueField="electricidad"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.electricidad}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Calefaccion(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Calefaccion"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("calefaccion")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_calefaccion"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kwhCalefaccion_kgProc")}
          valueField="calefaccion"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.calefaccion}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }
  getChart_Rendimiento(group) {
    const { fechaSel } = this.state;

    let fechaDesde = new Date(fechaSel.getTime());
    var fechaHasta = new Date(
      fechaDesde.getFullYear(),
      fechaDesde.getMonth() + 1,
      0,
    );

    return (
      <Chart
        className="he-100 w-100"
        id="Chart_Rendimiento"
        dataSource={group.items}
        redrawOnResize={true}
        title={getTrad("rendimiento")}
      >
        <CommonSeriesSettings
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        >
          <Point size={6} />
        </CommonSeriesSettings>
        <Series
          name={getTrad("objetivosKpi")}
          valueField="kpi_rendimiento"
          color="var(--danger)"
          type="stepline"
          dashStyle="dash"
        >
          <Point visible={false} />
        </Series>
        <Series
          name={getTrad("kgProc_HorasProd")}
          valueField="rendimiento"
          color="var(--primary)"
          type="spline"
        />
        <ValueAxis
          position="left"
          maxValueMargin={0}
          minValueMargin={0}
          visualRange={group.charts_VisualRange.rendimiento}
        />
        <ArgumentAxis
          argumentType="datetime"
          tickInterval="day"
          breaks={group.breaks_Chart}
          breakStyle={this.chart_breakStyle}
        >
          <VisualRange startValue={fechaDesde} endValue={fechaHasta} />
        </ArgumentAxis>
        <TooltipChart
          enabled={true}
          location="edge"
          contentRender={this.dxChart_tooltip_contentRender}
          shared={true}
        />
        <Animation enabled={false} />
        <Legend visible={false} />
      </Chart>
    );
  }

  CircularGauge_selector_mant_margin = {
    top: 0,
    bottom: 30,
  };
  CircularGauge_selector_mant_title = {
    text: getTrad("disponibilidad").toUpperCase(),
    font: {
      size: "1.6vh",
    },
    margin: {
      top: 0,
      bottom: 20, //20
    },
    horizontalAlignment: "center",
    verticalAlignment: "top",
    textOverflow: "none",
  };

  CircularGauge_selector_mant2_margin = {
    top: 37,
    bottom: 0,
  };
  CircularGauge_selector_mant2_title = {
    text: getTrad("preventivo").toUpperCase(),
    font: {
      size: "1.6vh",
    },
    margin: {
      top: 0,
      bottom: 18,
    },
  };

  DropDownButton_tarjeta_elementAttr = {
    class: "iconHeader_tarjeta_DashboardEjecutivo",
  };

  ////CONFIGURACIÓN////

  //CHART
  chart_breakStyle = { color: "lightgray" };

  //GRID
  grid_Singleselection = { mode: "single" };
  grid_allowedPageSizes = [20, 50, 100];
  onRowPrepared_pointer(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  dxChart_tooltip_contentRender(arg) {
    return (
      <div>
        <div className="tooltip_header">
          <div className="tooltip_title"> {formatDate(arg.argument)}</div>
        </div>
        <div className="tooltip_body">
          {arg.points.map((point, i) => (
            <div key={point.seriesName} className="tooltip_row">
              <div className="tooltip_series-name">
                {point.seriesName.toUpperCase()}
              </div>
              <div className="tooltip_value-text">
                {formatNumber(point.value, 2, "decimal")}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  //UTILS
  setLavanderia(idLavanderia) {
    let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

    var lav = lavanderias.find((lav) => {
      return lav.idLavanderia === idLavanderia;
    });

    setTimeout(() => {
      this.props.setLavanderia(lav);
    }, 0);
  }

  get_responsiveFont_gauge() {
    let width = $(window).width();
    // 3 Columnas
    if (width > 2150) return "font-size-xxl";
    if (width >= 1630 && width < 2150) return "font-size-xl";
    else if (width >= 1260 && width < 1630) return "font-size-lg";
    else if (width >= 1200 && width < 1260) return "font-size-md";
    // 2 Columnas
    else if (width >= 800 && width < 1200) return "font-size-xl";
    else if (width >= 650 && width < 800) return "font-size-lg";
    else if (width >= 576 && width < 650) return "font-size-md";
    // 1 Column
    else if (width >= 400 && width < 576) return "font-size-xl";
    else if (width < 400) return "font-size-lg";
    else return "font-size-xl";
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  //REPORT VIEWER
  setParams(params) {
    this.props.setParams(params);
  }
  setReport(report) {
    this.props.setReport(report);
  }
  closeReport() {
    this.props.close();
  }
  CustomizeParameterEditors(report, s, e) {
    if (e.parameter.type === "System.DateTime") {
      //DATE
      e.info.editor = $.extend({}, e.info.editor);
      e.info.editor.extendedOptions = $.extend(
        e.info.editor.extendedOptions || {},
        { type: "date" },
        { displayFormat: "dd/MM/yyyy" },
      );
    }

    if (e.parameter.name === "idLavanderia") {
      //INVISIBLES
      e.info.editor = $.extend({}, e.info.editor);
      e.info.visible = false;
    }
  }

  componentDidMount() {
    let _this = this;

    this.dataSource_tblPais.load().done(function () {
      _this.dataSource_spSelectDashboardEjecutivo_Selector
        .load()
        .done(function () {
          _this.datasource_datos_Informes.load().done(function () {
            _this.loadPanel_hide();
          });
        });
    });

    this.setReport({
      denominacion: "dashboard_ejecutivo",
      parameterEvent: this.CustomizeParameterEditors,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    let _this = this;
    let { lavanderia, idioma } = this.props;
    let { fechaSel, param_paginaSecundaria } = this.state;

    let hayCambiosLavanderia =
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia;
    let hayCambiosFecha =
      fechaSel.getFullYear() !== prevState.fechaSel.getFullYear() ||
      fechaSel.getMonth() !== prevState.fechaSel.getMonth();

    if (hayCambiosLavanderia || hayCambiosFecha) {
      if (param_paginaSecundaria == null)
        // Solo se mostrará el cargando si se está viendo este dashboard
        this.loadPanel_show();
      if (hayCambiosFecha) {
        //Se carga los datos del selector y después el de los informes si se ha selecciona una lavandería.
        this.dataSource_spSelectDashboardEjecutivo_Selector
          .load()
          .done(function () {
            if (
              !_this.dataSource_spSelectDashboardEjecutivo_Selector.isLoading()
            ) {
              _this.datasource_datos_Informes.load();
            }
          });
      } else {
        this.datasource_datos_Informes.load();
      }
    }

    if (idioma.codigo != prevProps.idioma.codigo) {
      this.CircularGauge_selector_mant_title.text =
        getTrad("disponibilidad").toUpperCase();
      this.CircularGauge_selector_mant2_title.text =
        getTrad("preventivo").toUpperCase();
    }
  }
  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  setLavanderia: (lavanderia) =>
    dispatch(globalActions.setLavanderia(lavanderia)),
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardEjecutivo);
