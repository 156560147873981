import { Button, List, Popup } from "devextreme-react";
import DataGrid, {
    Column,
    ColumnChooser,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
} from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/autocomplete";
import notify from "devextreme/ui/notify";
import { dxMensajePregunta, getTrad } from "helpers";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    datasource_tblMoneda,
    datasource_tblAdmPedidoProveedor,
    datasource_tblProveedor,
    datasource_tblAdmPedido_Estado,
    formats,
    datasource_tblViasPagoCobro,
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblIncoterm,
    datasource_tblAdmTipoElemento,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    getArticulos,
    retrieveIvaValue,
} from "pages/Administracion/shared/functions";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import { getClearPedido } from "pages/Administracion/PedidoProveedor/model";

// const formats = {
//     percent: {
//       type: 'percent',
//       maximumFractionDigits: 0
//     },
//     currency: {
//       style: 'currency',
//       maximumFractionDigits: 2,
//       currency: datasource_tblMoneda.find(m => m.idMoneda === idMoneda)?.codigo || 'EUR'
//     },
//     number: {
//       style: 'decimal',
//       maximumFractionDigits: 0
//     }
//   };

const CargarPedidoProveedor = forwardRef(
    ({ cargarPedidoPopup_visibility, setCargarPedidoPopup_visibility, setPedido, hasEditData }, ref) => {
        const [selectedItem, setSelectedItem] = useState();

        useImperativeHandle(ref, () => ({
            quitarPedidoSeleccionado,
        }));

        const closePopup = () => {
            setSelectedItem(null);
            setCargarPedidoPopup_visibility(false);
        };

        const calculateTotalValueForDisplay = (e) => {
            if (e.tblArticuloNAdmPedidoProveedor.length > 0) {
                let total = 0;
                e.tblArticuloNAdmPedidoProveedor.forEach((articulo) => {
                    const { cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 } = articulo;
                    let iva = retrieveIvaValue(idIvaNPais, "compra");
                    total += cantidad * precio * (1 + iva) * (1 - descuento);
                });
                const { descuento = 0, idAdmTipoDescuento } = e;
                total = calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "compra");
                return calculateDisplayValue(isNaN(total) ? 0 : total, e.idMoneda);
            }
        };

        const confirmarPedido = () => {
            setPedido({
                // numPedidoProveedor: selectedItem.numPedidoProveedor,
                idAdmPedidoProveedor: selectedItem.idAdmPedidoProveedor,
                codigo: selectedItem.codigo,
                idAdmProveedor: selectedItem.idAdmProveedor,
                idAdmCentroCoste: selectedItem.idAdmCentroCoste,
                idAdmElementoPEP: selectedItem.idAdmElementoPEP,
                idAdmFormaPago: selectedItem.idAdmFormaPago,
                idMoneda: selectedItem.idMoneda,
                tasaCambio: selectedItem.tasaCambio,
                idAdmTipoCambio: selectedItem.idAdmTipoCambio,
                idTipoPedido: selectedItem.idTipoPedido,
                descuento: selectedItem.descuento,
                idIncoterm: selectedItem.idIncoterm,
                idAdmTipoDescuento: selectedItem.idAdmTipoDescuento,
                tblArticuloNAdmPedidoProveedor: getArticulos(selectedItem.tblArticuloNAdmPedidoProveedor),
            });
        }

        const pedidoSeleccionado = () => {
            if (selectedItem) {
                if(hasEditData(getClearPedido())){
                    dxMensajePregunta("Se van a sobreescribir datos al asociar el pedido. ¿Quieres continuar? ", [
                        [
                            getTrad("aceptar"),
                            () => {
                                confirmarPedido();
                                closePopup();
                            },
                            "danger",
                        ],
                        [getTrad("cancelar"), () => {}],
                    ]);
                } else {
                    confirmarPedido();
                    closePopup();
                }
            } else {
                notify({
                    message: getTrad("seleccionePedido"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        };

        const onSelectionChanged = (e) => {
            setSelectedItem(e.currentSelectedRowKeys[0]);
        };

        const quitarPedidoSeleccionado = () => {
            const defaultFormData = getClearPedido()
            dxMensajePregunta("Se van a vaciar algunos campos. ¿Está seguro de quitar el pedido asociado?", [
                [
                    getTrad("aceptar"),
                    () => {
                        setPedido({...defaultFormData})
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        };

        const onRowPrepared_tblPedidos = (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };

        return (
            <Popup
                showTitle={true}
                title={getTrad("pedidos")}
                visible={cargarPedidoPopup_visibility}
                showCloseButton={true}
                closeOnOutsideClick={true}
                // height={"fit-content"}
                height={"600px"}
                width={"60%"}
                onHiding={closePopup}
            >
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("seleccionar")} type="success" onClick={pedidoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                    <Button text={getTrad("quitarSeleccion")} type="default" onClick={quitarPedidoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} onClick={closePopup} />
                </ToolbarItem>
                <div className={"d-flex he-100 mt-4"}>
                    <DataGrid
                        dataSource={datasource_tblAdmPedidoProveedor}
                        height={"100%"}
                        width={"100%"}
                        showRowLines
                        columnsAutoWidth
                        repaintChangesOnly
                        rowAlternationEnabled
                        showColumnLines={false}
                        remoteOperations={false}
                        //   onToolbarPreparing={onToolbarPreparing}
                        hoverStateEnabled={true}
                        onRowPrepared={onRowPrepared_tblPedidos}
                        selection={{ mode: "single" }}
                        selectedRowKeys={[selectedItem]}
                        onSelectionChanged={onSelectionChanged}
                    >
                        <ColumnChooser enabled />
                        <SearchPanel visible width={240} />
                        <Column
                            dataField={"idTipoPedido"}
                            caption={getTrad("tipoPedido")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) => TipoElemento(e.data.data.idTipoPedido)}
                            isRequired
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmTipoElemento.store()}
                                valueExpr="idAdmTipoElemento"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"codigo"}
                            caption={getTrad("codigo")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            isRequired
                        />
                        <Column
                            dataField={"fechaCreacion"}
                            caption={getTrad("fecha")}
                            dataType={"date"}
                            alignment={"left"}
                            minWidth={150}
                            sortOrder={"desc"}
                            format={"dd/MM/yyyy"}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmPedido_Estado"}
                            caption={getTrad("estado")}
                            dataType={"number"}
                            alignment={"left"}
                            defaultFilterValue={1}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) => ChipEstado(e.data.data.idAdmPedido_Estado, "", "pedido")}
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmPedido_Estado.store()}
                                valueExpr="idAdmPedido_Estado"
                                // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"idAdmProveedor"}
                            caption={getTrad("proveedor")}
                            dataType={"number"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            isRequired
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblProveedor.store()}
                                valueExpr="idAdmProveedor"
                                displayExpr="nombreFiscal"
                            />
                        </Column>
                        <Column
                            dataField={"fechaEstimadaRecepcion"}
                            caption={getTrad("fechaEstimadaRecepcion")}
                            dataType={"date"}
                            format={"dd/MM/yyyy"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idMoneda"}
                            caption={getTrad("moneda")}
                            dataType={"number"}
                            visible={false}
                            // format={formats.currency}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblMoneda.store()}
                                valueExpr="idMoneda"
                                displayExpr="codigo"
                            />
                        </Column>
                        <Column
                            dataField={"tasaCambio"}
                            caption={getTrad("tasaCambio")}
                            format={sharedEditorOptions.tasaCambio.format}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        {/* <Column
            dataField={"numPedidoProveedor"}
            caption={getTrad("numPedidoProveedor")}
            visible={false}
            allowReordering
            allowResizing
          /> */}
                        {/* <Column
                            dataField={"idAdmPedidoProveedor"}
                            caption={getTrad("pedidoProveedor")}
                            visible={false}
                            allowReordering
                            allowResizing
                        /> */}
                        <Column
                            dataField={"descuento"}
                            caption={getTrad("descuento")}
                            dataType={"number"}
                            visible={false}
                            format={formats.percent}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmFormaPago"}
                            caption={getTrad("formaPago")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblViasPagoCobro.store()}
                                valueExpr="idAdmFormaPago"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"idAdmCentroCoste"}
                            caption={getTrad("centroCoste")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmCentroCoste.store()}
                                valueExpr="idAdmCentroCoste"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idAdmElementoPEP"}
                            caption={getTrad("elementoPep")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmElementoPEP.store()}
                                valueExpr="idAdmElementoPEP"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idIncoterm"}
                            caption={getTrad("incoterms")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblIncoterm.store()}
                                valueExpr="idIncoterm"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"numPresupuestoProveedor"}
                            caption={getTrad("numPresupuestoProveedor")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"observaciones"}
                            caption={getTrad("observaciones")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"total"}
                            caption={getTrad("total")}
                            dataType={"number"}
                            visible={true}
                            width={100}
                            // calculateCellValue={calculateTotal}
                            calculateDisplayValue={calculateTotalValueForDisplay}
                        />
                    </DataGrid>
                    {/* <List
          dataSource={pedidos}
          displayExpr={"codigo"}
          selectionMode="single"
          selectedItem={selectedItem}
          onSelectionChanged={onSelectionChanged}
        /> */}
                </div>
            </Popup>
        );
    }
);

export default CargarPedidoProveedor;
