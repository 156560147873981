import React, { createContext } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import { loadPanelActions } from "actions";
import {
    addDays,
    authHeader,
    endOfYear,
    errorHandler,
    formatDate_noTime_parameter,
    getTrad,
    startOfMonth,
    startOfYear,
} from "helpers";

import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import idsMoneda from "constants/enums/General/tblMoneda";
import { tasaCambioActions } from "actions/tasaCambio";
import { getValorTasaCambio } from "helpers/tasaCambio";

// #region Crear los contextos

export const ControlPresupuestarioCxt = createContext({
    moneda: {},
    setMoneda: () => {},
    empresa: {},
    setEmpresa: () => {},
    fecha: {},
    setFecha: () => {},
    centros: [],
    setCentros: () => {},
    resumenes: {
        presupuestado: "",
        real: "",
        realAjustado: "",
        desviacion: "",
    },
    setResumenes: () => {},
    vista: 0,
    setVista: () => {},
    tasaDivisa: true,
    setTasaDivisa: () => {},
    agruparCentros: false,
    setAgruparCentros: () => {},
    datasource_tblEmpresasPolarier: new DataSource({}),
    datasource_tblMoneda: new DataSource({}),
    tblAdmCentroCoste: [],
    tblAdmElementoPEP: [],
    tblCentroCoste_ElementoPEP: [],
    intercambiarDivisa: () => {},
    reloadCentros: () => {},
});

// #endregion

class ControlPresupuestarioContext extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            moneda: {},
            empresa: {},
            fecha: startOfMonth(new Date()),
            centros: [],
            vista: 2, // 1: Mensual, 2: Acumulado
            resumenes: {
                presupuestado: "",
                real: "",
                realAjustado: "",
                desviacion: "",
            },
            tasaDivisa: true,
            agruparCentros: false,
            tblAdmCentroCoste: [],
            tblAdmElementoPEP: [],
            tblCentroCoste_ElementoPEP: [],
        };
    }

    // #region Crear el objeto de contexto

    get cxtValue() {
        return {
            moneda: this.state.moneda,
            setMoneda: this.setMoneda,
            empresa: this.state.empresa,
            setEmpresa: this.setEmpresa,
            fecha: this.state.fecha,
            setFecha: this.setFecha,
            centros: this.state.centros,
            setCentros: this.setCentros,
            vista: this.state.vista,
            setVista: this.setVista,
            resumenes: this.state.resumenes,
            setResumenes: this.setResumenes,
            tasaDivisa: this.state.tasaDivisa,
            setTasaDivisa: this.setTasaDivisa,
            agruparCentros: this.state.agruparCentros,
            setAgruparCentros: this.setAgruparCentros,
            datasource_tblEmpresasPolarier: this.datasource_tblEmpresasPolarier,
            datasource_tblMoneda: this.datasource_tblMoneda,
            tblCentroCoste_ElementoPEP: this.state.tblCentroCoste_ElementoPEP,
            tblAdmCentroCoste: this.state.tblAdmCentroCoste,
            tblAdmElementoPEP: this.state.tblAdmElementoPEP,
            intercambiarDivisa: this.intercambiarDivisa,
            reloadCentros: this.reloadCentros,
        };
    }

    setMoneda = (moneda) => {
        this.setState({ moneda });
    };

    setEmpresa = (empresa) => {
        let moneda = this.datasource_tblMoneda.items().find((x) => x.idMoneda === empresa.idMoneda);
        this.setState({ empresa, moneda });
    };

    setFecha = (fecha) => {
        this.setState({ fecha });
    };

    setCentros = (centros) => {
        this.setState({ centros: centros });
    };

    setVista = (vista) => {
        this.setState({ vista });
    };

    setResumenes = (resumenes) => {
        this.setState({ resumenes });
    };

    setTasaDivisa = (tasaDivisa) => {
        this.setState({ tasaDivisa });
    };

    setAgruparCentros = (agruparCentros) => {
        this.setState({ agruparCentros });
    };

    intercambiarDivisa = ({ valor, fechaDocumento, fecha, idMoneda: idMonedaOrigen }, forcePresupuestado = false) => {
        const idMonedaDestino = this.state.moneda.idMoneda;
        const vistaTasaPresupuesto = !this.state.tasaDivisa;
        let fechaTasa = fechaDocumento ?? fecha;

        if (!valor || (!fechaTasa && !forcePresupuestado) || !idMonedaOrigen) return null;
        else if (idMonedaOrigen === idMonedaDestino) return valor;
        else if (vistaTasaPresupuesto || forcePresupuestado) {
            if (idMonedaOrigen === idsMoneda.Euro || idMonedaDestino === idsMoneda.Euro) {
                const tasaCambio = this.datasource_tblTasaCambioPresupuesto
                    .items()
                    .find(
                        (tc) =>
                            tc.idMonedaDestino ===
                                (idMonedaDestino === idsMoneda.Euro ? idMonedaOrigen : idMonedaDestino) &&
                            (vistaTasaPresupuesto || forcePresupuestado
                                ? true
                                : new Date(tc.fecha).setHours(0, 0, 0, 0) <= new Date(fechaTasa).setHours(0, 0, 0, 0))
                    );

                if (!tasaCambio) return null;

                return idMonedaDestino === idsMoneda.Euro
                    ? valor / (tasaCambio?.tasaCambio ?? 1)
                    : valor * (tasaCambio?.tasaCambio ?? 1);
            } else {
                const tasaCambioOrigen = this.datasource_tblTasaCambioPresupuesto
                    .items()
                    .find(
                        (tc) =>
                            tc.idMonedaDestino === idMonedaOrigen &&
                            (vistaTasaPresupuesto
                                ? true
                                : new Date(tc.fecha).setHours(0, 0, 0, 0) <= new Date(fechaTasa).setHours(0, 0, 0, 0))
                    );

                const tasaCambioDestino = this.datasource_tblTasaCambioPresupuesto
                    .items()
                    .find(
                        (tc) =>
                            tc.idMonedaDestino === idMonedaDestino &&
                            (vistaTasaPresupuesto
                                ? true
                                : new Date(tc.fecha).setHours(0, 0, 0, 0) <= new Date(fechaTasa).setHours(0, 0, 0, 0))
                    );

                if (!tasaCambioOrigen || !tasaCambioDestino) return null;

                return (valor / (tasaCambioOrigen?.tasaCambio ?? 1)) * (tasaCambioDestino?.tasaCambio ?? 1);
            }
        } else
            return getValorTasaCambio({ valor, idMoneda: idMonedaOrigen, fecha: new Date(fechaTasa) }, idMonedaDestino);
    };

    reloadCentros = () => {
        const { centros } = this.state;
        Promise.all([this.datasource_tblAdmCentroCoste.load(), this.datasource_tblAdmElementoPEP.load()]).then(
            ([tblAdmCentroCoste, tblAdmElementoPEP]) => {
                let new_centros =
                    centros.length > 0
                        ? centros.some((x) => x.idAdmCentroCoste)
                            ? tblAdmCentroCoste.filter((x) =>
                                  centros.map((x) => x.idAdmCentroCoste).includes(x.idAdmCentroCoste)
                              )
                            : tblAdmElementoPEP.filter((x) =>
                                  centros.map((x) => x.idAdmElementoPEP).includes(x.idAdmElementoPEP)
                              )
                        : [];

                this.setState({ tblAdmCentroCoste, tblAdmElementoPEP, centros: new_centros });
            }
        );
    };

    // #endregion

    // #region Lifecycle

    componentDidMount() {
        const { fecha } = this.state;
        this.props.loadPanel_show(false);
        Promise.all([
            this.datasource_tblEmpresasPolarier.load(),
            this.datasource_tblMoneda.load(),
            this.store_tblCentroCoste_ElementoPEP.load(),
            this.props.loadTasaCambio(addDays(startOfYear(fecha), -15), endOfYear(fecha)),
            this.datasource_tblTasaCambioPresupuesto.load(),
        ]).then(([tblEmpresaPolarier, tblMoneda, tblCentroCoste_ElementoPEP, tasaCambio, tblTasaCambioPresupuesto]) => {
            this.props.loadPanel_hide();
            let val = tblEmpresaPolarier.find((x) => x.idEmpresaPolarier === 3);
            if (val) {
                this.setEmpresa(val);
            } else {
                this.setEmpresa(tblEmpresaPolarier);
            }

            this.setState({ tblCentroCoste_ElementoPEP });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { empresa, fecha } = this.state;
        if (empresa !== prevState.empresa || fecha !== prevState.fecha) {
            this.reloadCentros();
        }
        if (fecha !== prevState.fecha && fecha.getFullYear() !== prevState.fecha.getFullYear()) {
            this.props.loadTasaCambio(addDays(startOfYear(fecha), -15), endOfYear(fecha));
            this.datasource_tblTasaCambioPresupuesto.reload();
        }
    }

    // #endregion

    // #region Datasources

    ds_errorHandler = (error) => {
        errorHandler(error, null);
    };

    ds_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblEmpresasPolarier = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEmpresasPolarier",
            key: "idEmpresaPolarier",
            keyType: "Int32",
            errorHandler: (error) => this.ds_errorHandler(error),
            beforeSend: (request) => this.ds_beforeSend(request),
            version: 4,
        }),
    });

    datasource_tblMoneda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMoneda",
            key: "idMoneda",
            errorHandler: (error) => this.ds_errorHandler(error),
            beforeSend: (request) => this.ds_beforeSend(request),
            version: 4,
        }),
    });

    store_tblCentroCoste_ElementoPEP = new CustomStore({
        key: "idCentroElem",
        load: (loadOptions) => this.store_tblCentroCoste_ElementoPEP_load(loadOptions),
        byKey: (key) => this.store_tblCentroCoste_ElementoPEP_byKey(key),
    });

    store_tblCentroCoste_ElementoPEP_load = (loadOptions) => {
        return new Promise((resolve, reject) => {
            Promise.all([this.store_tblAdmCentroCoste.load(), this.store_tblAdmElementoPEP.load()]).then(
                ([tblCentroCoste, tblElementoPEP]) => {
                    let datasource_tblCentroCoste_tblElementoPEP = $.map(
                        $.merge(tblCentroCoste, tblElementoPEP),
                        function (item, index) {
                            return {
                                groupTitle: item.idAdmCentroCoste ? getTrad("centroCoste") : getTrad("elementoPep"),
                                idCentroElem: JSON.stringify([
                                    item.idAdmCentroCoste ? item.idAdmCentroCoste : -1,
                                    item.idAdmElementoPEP ? item.idAdmElementoPEP : -1,
                                ]),
                                denominacion: item.denominacion,
                                codigo: item.codigo,
                            };
                        }
                    );

                    resolve(datasource_tblCentroCoste_tblElementoPEP);
                }
            );
        });
    };

    store_tblCentroCoste_ElementoPEP_byKey = (key) => {
        return new Promise((resolve, reject) => {
            this.store_tblCentroCoste_ElementoPEP_load().then((data) => {
                let result = data.filter((x) => x.idCentroElem === key)[0];
                resolve(result);
            });
        });
    };

    store_tblAdmCentroCoste = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCentroCoste",
        key: "idAdmCentroCoste",
        keyType: "Int32",
        errorHandler: (error) => this.ds_errorHandler(error),
        beforeSend: (request) => this.ds_beforeSend(request),
        onLoading: (loadOptions) => this.datasource_filtroCentros_onLoading(loadOptions),
        version: 4,
    });

    datasource_tblAdmCentroCoste = new DataSource({
        paginate: false,
        store: this.store_tblAdmCentroCoste,
        select: ["idAdmCentroCoste", "codigo", "denominacion"],
        filter: [["isEliminado", "=", false]],
    });

    store_tblAdmElementoPEP = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmElementoPEP",
        key: "idAdmElementoPEP",
        keyType: "Int32",
        errorHandler: (error) => this.ds_errorHandler(error),
        beforeSend: (request) => this.ds_beforeSend(request),
        onLoading: (loadOptions) => this.datasource_filtroCentros_onLoading(loadOptions),
        version: 4,
    });

    datasource_tblAdmElementoPEP = new DataSource({
        paginate: false,
        store: this.store_tblAdmElementoPEP,
        select: ["idAdmElementoPEP", "codigo", "denominacion"],
        filter: [["isEliminado", "=", false]],
    });

    datasource_filtroCentros_onLoading = (loadOptions) => {
        const { empresa, fecha } = this.state;
        if (empresa?.idEmpresaPolarier != null) {
            if (loadOptions.filter == null) {
                loadOptions.filter = [];
            }
            loadOptions.filter = [...loadOptions.filter, ["idEmpresaPolarier", "=", empresa.idEmpresaPolarier]];
            loadOptions.expand = [
                `tblCierrePresupuestario($select=idCierrePresupuestario;$filter=fecha eq ${formatDate_noTime_parameter(
                    fecha
                )})`,
            ];
        }
    };

    datasource_tblTasaCambioPresupuesto = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTasaCambioPresupuesto",
            errorHandler: (error) => this.ds_errorHandler(error),
            beforeSend: (request) => this.ds_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblTasaCambioPresupuesto_onLoading(loadOptions),
            version: 4,
        }),
    });

    datasource_tblTasaCambioPresupuesto_onLoading = (loadOptions) => {
        const { fecha } = this.state;
        if (loadOptions.filter == null) {
            loadOptions.filter = [];
        }
        loadOptions.filter = [...loadOptions.filter, ["año", "=", fecha.getFullYear()]];
    };

    // #endregion

    render() {
        return (
            <ControlPresupuestarioCxt.Provider value={this.cxtValue}>
                {this.props.children}
            </ControlPresupuestarioCxt.Provider>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
    loadTasaCambio: (fechaDesde, fechaHasta) => dispatch(tasaCambioActions.loadTasaCambio(fechaDesde, fechaHasta)),
    clearTasaCambio: () => dispatch(tasaCambioActions.clearTasaCambio()),
});

export default connect(null, mapDispatchToProps)(ControlPresupuestarioContext);
