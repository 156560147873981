import React from "react";

import {
  capitalize,
  formatDate,
  formatNumber,
  getTrad,
} from "../../../../../helpers";

import {
  Chart,
  Series,
  CommonSeriesSettings,
  ValueAxis,
  ArgumentAxis,
  Legend,
  Tooltip as Chart_Tooltip,
  Label,
  Grid,
  LoadingIndicator,
  AdaptiveLayout,
} from "devextreme-react/chart";

import ArrayStore from "devextreme/data/array_store";

export default class GraficaContador extends React.Component {
  render() {
    const { lecturaContador, fechaSel } = this.props;
    const isMensual = this.get_differenceInDays(fechaSel) > 60;
    const isDiario = this.get_differenceInDays(fechaSel) > 2;

    const interval = isMensual ? "month" : isDiario ? "day" : "hour";
    const tickInterval = isMensual
      ? { months: 1 }
      : isDiario
        ? "day"
        : { hours: 1 };
    const formatArgument = isMensual
      ? "month"
      : isDiario
        ? "monthDay"
        : "shorttime";
    const formatLabel = isMensual
      ? "month"
      : isDiario
        ? "monthAndDay"
        : "shorttime";

    return (
      <Chart
        dataSource={new ArrayStore({ data: lecturaContador })}
        size={{ height: "100%", width: "100%" }}
        onInitialized={this.dxChart_onInitialized}
        onPointClick={this.dxChart_onPointClick}
        palette={this.enum_palette_dxChart.series}
      >
        <CommonSeriesSettings
          type="bar"
          argumentField="fecha"
          ignoreEmptyPoints={true}
          hoverMode="allArgumentPoints"
        />
        <Series
          valueField="consumo"
          aggregation={{
            enabled: lecturaContador && lecturaContador.length > 1,
            method: "sum",
            groupInterval: interval,
          }}
          name={"Consumo"}
          color={this.enum_palette_dxChart.series[0]}
        ></Series>
        <ValueAxis position="left" maxValueMargin={0.3} minValueMargin={0.3}>
          <Grid visible={true} />
        </ValueAxis>
        <ArgumentAxis
          argumentType="datetime"
          tickInterval={tickInterval}
          format={formatArgument}
          aggregationInterval={interval}
        >
          <Label
            overlappingBehavior="none"
            rotationAngle={-45}
            displayMode="rotate"
            format={formatLabel}
            customizeText={this.dxChart_argumentAxis_label_customizeText}
          />
        </ArgumentAxis>
        <Chart_Tooltip
          zIndex={10000}
          enabled={true}
          location="edge"
          customizeTooltip={this.dxChart_customizeTooltip}
        />
        <LoadingIndicator
          backgroundColor="none"
          font={this.loadingIndicator_fontSize}
        />
        <AdaptiveLayout width={"100%"} />
      </Chart>
    );
  }

  loadingIndicator_fontSize = { size: 14 };

  enum_palette_dxChart = {
    series: ["#F1BE48", "#A9A9A9"],
    kpi: ["#C48D0F", "#767676"],
  };

  get_differenceInDays = () => {
    const { lecturaContador } = this.props;

    if (lecturaContador && lecturaContador.length > 1) {
      const firstDate = lecturaContador[0].fecha;
      const lastDate = lecturaContador[lecturaContador.length - 1].fecha;
      const Difference_In_Time = lastDate.getTime() - firstDate.getTime();

      return Difference_In_Time / (1000 * 3600 * 24);
    }
    return 0;
  };

  dxChart_customizeTooltip = (e) => {
    const { unidadMedidaRecursoContaSel } = this.props;

    const isMensual = this.get_differenceInDays() > 60;
    const isDiario = this.get_differenceInDays() > 2;

    let fecha;

    if (isMensual) {
      fecha = capitalize(
        new Intl.DateTimeFormat("es-ES", { month: "long" }).format(e.argument),
      );
    } else if (isDiario) {
      fecha = formatDate(e.argument);
    } else {
      fecha =
        e.argument.getDate().toString().padStart(2, "0") +
        "/" +
        (e.argument.getMonth() + 1).toString().padStart(2, "0") +
        " " +
        new Intl.DateTimeFormat("es-ES", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(e.argument);
    }

    let seriesData = {
      fecha,
      label:
        getTrad("consumoEnUnidades") + " " + unidadMedidaRecursoContaSel + ": ",
      totalConsumo: formatNumber(e.total, 2) + "",
    };

    return { html: this.tooltipCreation(seriesData) };
  };

  tooltipCreation = (seriesData) => {
    let html = "<div>";
    html += "<div class='tooltip-header'>";
    html += seriesData.fecha;
    html += "</div>";
    html += "<div class='tooltip-body'>";
    html += "<div class='tooltip-row'>";
    html += "<div class='series-name'>";
    html += seriesData.label;
    html += "</div>";
    html += "<div class='value-text'>";
    html += seriesData.totalConsumo;
    html += "</div>";
    html += "</div>";
    html += "</div>";
    html += "</div>";

    return html;
  };

  dxChart_argumentAxis_label_customizeText = (e) => {
    const isMensual = this.get_differenceInDays() > 60;
    const isDiario = this.get_differenceInDays() > 2;

    if (isMensual) {
      return capitalize(
        new Intl.DateTimeFormat("es-ES", { month: "long" }).format(e.value),
      );
    } else if (isDiario) {
      return (
        e.value.getDate() +
        " " +
        capitalize(
          new Intl.DateTimeFormat("es-ES", { month: "short" }).format(e.value),
        )
      );
    } else {
      return (
        e.value.getDate().toString().padStart(2, "0") +
        "/" +
        (e.value.getMonth() + 1).toString().padStart(2, "0") +
        " " +
        new Intl.DateTimeFormat("es-ES", {
          hour: "2-digit",
          minute: "2-digit",
        }).format(e.value)
      );
    }
  };

  dxChart_onInitialized = (e) => {
    e.element.css("height", "100%");
  };

  dxChart_onPointClick = (e) => {
    let point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  };
}
