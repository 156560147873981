import React, { Fragment } from "react";
import { Progress } from "reactstrap";
import { formatNumber } from "helpers";
import { Tooltip } from "devextreme-react/tooltip";

export class progressBarCellComponent extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let dataField = this.props.data.column.dataField;
    let value = this.props.data.value;
    let color = this.props.data.data.color;
    let options = this.props.data.data["progressBarCellOptions_" + dataField];
    let idTick = this.props.data.rowIndex;

    if (value === -1) return <div></div>;

    if (!options) {
      options = {};
    }

    //COLOR
    if (color) {
      options.color = color;
    }

    if (!options.color) {
      options.color =
        value < 0.75
          ? "danger"
          : value < 0.9
            ? "orange"
            : value < 1
              ? "yellow"
              : "success";
    }

    //MAX VALUE
    if (!options.max) {
      options.max = 1;
    }

    //DATA TYPE
    if (!options.type) {
      options.type = "percent";
    }

    let tickWidth = null;
    if (options.tick) {
      tickWidth = (options.tick / options.max) * 100 + "%";
    }

    let numDecimals =
      options.type == "percent"
        ? (value * 100) % 1 !== 0
          ? 2
          : 0
        : value % 1 !== 0
          ? 2
          : 0;
    return (
      <div className="progress-container ml-3 mr-2">
        <Progress
          className="progress-bar-lg progress-bar-rounded"
          barClassName="he-100"
          value={value > 1 ? 1 : value}
          max={options.max}
          color={options.color}
        >
          <div className="progress-bar-info pl-2 text-white font-weight-bold">
            {formatNumber(value, numDecimals, options.type)}
          </div>
        </Progress>
        {options.tick ? (
          <Fragment>
            <div
              id={idTick}
              className="tick"
              style={{ marginLeft: tickWidth }}
            ></div>
            {options.tickTooltip ? (
              <Tooltip
                target={"#" + idTick}
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div>{options.tickTooltip}</div>
              </Tooltip>
            ) : (
              ""
            )}
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}
