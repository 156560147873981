import React from "react";

import { connectionConstants } from "../../../../../constants";
import {
    errorHandler,
    authHeader,
    getTrad,
    formatNumber,
    formatDate_parameter,
    formatDate,
} from "../../../../../helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
    Column,
    Paging,
    HeaderFilter,
    FilterRow,
    Selection,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import { progressBarCellComponent as ProgressBarCellComponent } from "../../../../../components/DataGrid/Cells";
import { Tooltip } from "devextreme-react";

export default class GridPersonaNArea extends React.PureComponent {
    constructor(props) {
        super(props);

        this.grid_REF = React.createRef();
    }

    get grid() {
        return this.grid_REF.current.instance;
    }

    datasource_spSelectPersonaNAreaSmartHub = new DataSource({
        paginate: false,
        store: new ODataStore({
            key: "id",
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Dashboard/SmartView/spSelectPersonaNAreaSmartHub",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_spSelectPersonaNAreaSmartHub_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            data.forEach((item) => {
                item.progressBarCellOptions_cumplimiento = {
                    color: this.GET_COLOR_CUMPLIMIENTO(item.cumplimiento),
                };
            });
            return data;
        },
    });

    datasource_spSelectPersonaNAreaSmartHub_beforeSend(request) {
        let { filtros, group } = this.props;
        let { idLavanderia, idPersona, fechaIni, fechaFin } = filtros;

        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaIni = formatDate_parameter(fechaIni);
        request.params.fechaFin = formatDate_parameter(fechaFin);
        request.params.group = group;
        request.params.idPersona = idPersona;
    }

    GET_COLOR_CUMPLIMIENTO = (cumplimiento) => {
        if (cumplimiento >= 0.9) return "success";
        else if (cumplimiento >= 0.8) return "warning";
        else if (cumplimiento >= 0.7) return "orange";
        else return "danger";
    };

    render() {
        const { group, selectedRowKey } = this.props;

        return (
            <DataGrid
                ref={this.grid_REF}
                dataSource={this.datasource_spSelectPersonaNAreaSmartHub}
                remoteOperations={false}
                height="100%"
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                hoverStateEnabled={true}
                selectedRowKeys={selectedRowKey != null ? [selectedRowKey] : undefined}
                onToolbarPreparing={this.props.title ? this.onToolbarPreparing : undefined}
                onEditorPreparing={this.onEditorPreparing}
                onSelectionChanged={this.props.onSelectionChanged ? this.onSelectionChanged : undefined}
            >
                <Paging enabled={false} />
                <HeaderFilter visible={true} />
                <FilterRow visible={true} />
                <Selection mode={this.props.onSelectionChanged ? "single" : undefined} />
                <Column
                    dataField="nombreCompleto"
                    caption={getTrad("nombre")}
                    dataType="string"
                    minWidth={250}
                    visible={group === "persona"}
                    cellRender={this.cellRender_nombreCompleto}
                />
                <Column
                    dataField="categoriaInterna"
                    caption={getTrad("categoriaInterna")}
                    dataType="string"
                    width={200}
                    visible={group === "persona"}
                    calculateCellValue={this.calculateCellValue_categoriaInterna}
                />
                <Column
                    dataField="id"
                    caption={getTrad("fecha")}
                    dataType="date"
                    width={125}
                    format={"dd/MM/yyyy"}
                    sortOrder={group === "fecha" ? "asc" : undefined}
                    visible={group === "fecha"}
                    calculateCellValue={this.calculateCellValue_fecha}
                />
                <Column
                    dataField="horasNJornada"
                    caption={getTrad("horasNJornada")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={150}
                    customizeText={this.customizeText_horas}
                    cellRender={group === "fecha" ? this.cellRender_horasNJornada : undefined}
                />
                <Column
                    dataField="horasNArea"
                    caption={getTrad("horasActivas")}
                    dataType="number"
                    alignment={"center"}
                    defaultSelectedFilterOperation=">="
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    width={150}
                    customizeText={this.customizeText_horas}
                />
                <Column
                    dataField="cumplimiento"
                    caption={getTrad("cumplimiento")}
                    dataType="number"
                    alignment="center"
                    filterOperations={["=", "<>", "<", ">", "<=", ">="]}
                    defaultSelectedFilterOperation=">="
                    defaultFilterValue={0}
                    cssClass="p-0"
                    allowSearch={false}
                    allowHeaderFiltering={false}
                    sortOrder={group === "persona" ? "desc" : undefined}
                    minWidth={150}
                    cellComponent={this.cellComponent_cumplimiento}
                />
                <Column dataField="isRegInvalido" visible={false} />
                <Column dataField="errorNHoraEntrada" visible={false} />
                <Column dataField="errorNHoraSalida" visible={false} />
                <Summary calculateCustomSummary={this.calculateCustomSummary}>
                    <TotalItem column="nombreCompleto" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem column="id" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem
                        name="horasNJornada"
                        showInColumn={"horasNJornada"}
                        summaryType="custom"
                        displayFormat="{0}"
                        customizeText={this.customizeText_sum_horas}
                    />
                    <TotalItem
                        name="horasNArea"
                        showInColumn={"horasNArea"}
                        summaryType="custom"
                        displayFormat="{0}"
                        customizeText={this.customizeText_sum_horas}
                    />
                    <TotalItem
                        name="cumplimiento"
                        showInColumn={"cumplimiento"}
                        summaryType="custom"
                        displayFormat="{0}"
                        customizeText={this.customizeText_sum_cumplimiento}
                    />
                </Summary>
            </DataGrid>
        );
    }

    cellRender_nombreCompleto = (e) => {
        return e.data.errorNHoraEntrada || e.data.errorNHoraSalida || e.data.isRegInvalido ? (
            <div style={{ display: "flex" }}>
                <div id={`errorNJornada_nombreCompleto_${e.data.id}`} className="icon_Warning font-size-lg mr-2" />
                {e.text}
                <Tooltip
                    target={`#errorNJornada_nombreCompleto_${e.data.id}`}
                    position="bottom"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div style={{ textAlign: "left" }} className="font-weight-bold">
                        {e.data.isRegInvalido ? (
                            <div className="font-weight-bold">{getTrad("registroJornadaPendienteValidar")}</div>
                        ) : null}
                        {e.data.errorNHoraEntrada || e.data.errorNHoraSalida ? (
                            <div className="font-weight-bold">{getTrad("faltaRegistroNJornada")}</div>
                        ) : null}
                    </div>
                </Tooltip>
            </div>
        ) : (
            e.text
        );
    };

    cellRender_horasNJornada = (e) => {
        return e.data.errorNHoraEntrada || e.data.errorNHoraSalida || e.data.isRegInvalido ? (
            <>
                <div
                    style={{ width: 20, margin: "auto" }}
                    id={`errorNJornada_horasNJornada_${e.data.id}`}
                    className="icon_Warning font-size-lg"
                />
                <Tooltip
                    target={`#errorNJornada_horasNJornada_${e.data.id}`}
                    position="bottom"
                    showEvent="dxhoverstart"
                    hideEvent="dxhoverend"
                >
                    <div style={{ textAlign: "left" }} className="font-weight-bold">
                        {e.data.isRegInvalido ? <div>{getTrad("pendienteValidar")}</div> : null}
                        {e.data.errorNHoraEntrada || e.data.errorNHoraSalida ? (
                            <div>{getTrad("faltanSiguientesRegistros")}</div>
                        ) : null}
                        {e.data.errorNHoraEntrada ? <div className="ml-3">- {getTrad("horaEntrada")}</div> : null}
                        {e.data.errorNHoraSalida ? <div className="ml-3"> - {getTrad("horaSalida")}</div> : null}
                    </div>
                </Tooltip>
            </>
        ) : (
            e.text
        );
    };

    cellComponent_cumplimiento = (e) => {
        const { group } = this.props;

        return group !== "persona" && e.data.data.isRegInvalido ? "-" : <ProgressBarCellComponent {...e} />;
    };

    calculateCellValue_categoriaInterna = (e) => {
        return e.categoriaInterna ? e.categoriaInterna.toUpperCase() : "";
    };

    calculateCellValue_fecha = (e) => {
        const { group } = this.props;

        if (group === "fecha") {
            return new Date(e.id); // formatDate(new Date(e.id));
        }
        return "";
    };

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: "before",
            text: this.props.title,
        });
    };

    sliderText = null;
    onEditorPreparing = (e) => {
        let _this = this;
        if (e.parentType === "filterRow") {
            if (e.dataField === "cumplimiento") {
                e.editorName = "dxSlider";
                e.editorOptions = {
                    min: 0,
                    max: 1,
                    value: e.value,
                    step: 0.05,
                    width: "100%",
                    onValueChanged: function (data) {
                        e.setValue(data.value);
                        let textValue = formatNumber(data.value, 0, "percent");
                        _this.sliderText.text(textValue);
                    },
                    onInitialized: function (e) {
                        let value = e.component.option("value");
                        let textValue = formatNumber(value, 0, "percent");
                        _this.sliderText = $("<div />").addClass("slider-textValue").text(textValue);
                        e.element.append(_this.sliderText);
                    },
                };
            }
        }
    };

    customizeText_horas = (e) => {
        return this.format_hours_toHHMM(e.value);
    };

    calculateCustomSummary = (options) => {
        if (options.name == "horasNJornada") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    if (
                        !options.value.isRegInvalido &&
                        !options.value.errorNHoraEntrada &&
                        !options.value.errorNHoraSalida
                    ) {
                        options.totalValue += options.value.horasNJornada;
                    }
                    break;
            }
        }
        if (options.name == "horasNArea") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    if (
                        !options.value.isRegInvalido &&
                        !options.value.errorNHoraEntrada &&
                        !options.value.errorNHoraSalida
                    ) {
                        options.totalValue += options.value.horasNArea;
                    }
                    break;
            }
        }
        if (options.name == "cumplimiento") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    options.horasNJornada = 0;
                    options.horasNArea = 0;
                    break;
                case "calculate":
                    if (
                        !options.value.isRegInvalido &&
                        !options.value.errorNHoraEntrada &&
                        !options.value.errorNHoraSalida
                    ) {
                        options.horasNJornada += options.value.horasNJornada;
                        options.horasNArea += options.value.horasNArea;
                    }
                    break;
                case "finalize":
                    options.totalValue = options.horasNArea / options.horasNJornada;
                    options.totalValue = options.totalValue ? options.totalValue : 0;
                    break;
            }
        }
    };

    customizeText_sum_cumplimiento(itemInfo) {
        return formatNumber(itemInfo.value, 2, "percent");
    }

    customizeText_sum_horas = (itemInfo) => {
        return this.format_hours_toHHMM(itemInfo.value);
    };

    format_hours_toHHMM(totalHours) {
        var signo = totalHours < 0 ? "-" : "";
        var absTotalHours = Math.abs(totalHours);

        var horas = Math.floor(absTotalHours);
        var minutos = Math.round((absTotalHours - horas) * 60);

        var horas_formatted = String(horas).padStart(2, "0");
        var minutos_formatted = String(minutos).padStart(2, "0");

        return signo + horas_formatted + ":" + minutos_formatted;
    }

    onSelectionChanged = (e) => {
        if (e.selectedRowKeys.length > 0) {
            const key = e.selectedRowKeys[0].split("_");
            const nombreCompleto = e.selectedRowsData?.[0]?.nombreCompleto;
            if (nombreCompleto) {
                this.props.onSelectionChanged({ [key[0]]: parseInt(key[1]) }, nombreCompleto);
            }
            this.props.onSelectionChanged({ [key[0]]: parseInt(key[1]) });
        } else {
            this.props.onSelectionChanged({});
        }
    };

    componentDidUpdate(prevProps) {
        const { title, group, filtros } = this.props;
        if (JSON.stringify(prevProps.filtros) !== JSON.stringify(filtros) || prevProps.group !== group) {
            this.datasource_spSelectPersonaNAreaSmartHub.reload();
            this.grid.clearFilter();
        }

        if (prevProps.title !== title) {
            this.grid.repaint();
        }
    }
}
