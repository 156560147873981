import React, { useState, useEffect } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import Autocomplete from "./AutoComplete";
import { getTrad } from "helpers";
import { GOOGLE_MAPS_API_KEY } from "../../constants";

const defultCoordinates = { lat: 39.6036572, lng: 2.6730642 };

const MapContainer = ({
  google,
  coordinates = undefined,
  addressNoMod,
  onChange,
  isVisible,
  address: propAddress,
  debounce,
  language,
}) => {
  const [state, setState] = useState({
    address_components: undefined,
    address: undefined,
    selectedLat: undefined,
    selectedLng: undefined,
    actualPosition: undefined,
  });

  useEffect(() => {
    async function fetchData() {
      if (isVisible) {
        if (
          !propAddress ||
          !coordinates ||
          !coordinates.lat ||
          !coordinates.lng
        ) {
          if (
            propAddress &&
            (!coordinates || (!coordinates.lat && !coordinates.lng))
          ) {
            await geocoder({ address: propAddress });
          } else if (
            !propAddress &&
            coordinates &&
            coordinates.lat &&
            coordinates.lng
          ) {
            await geocoder({ location: coordinates });
          } else {
            const actualPosition = await new Promise((resolve) => {
              navigator.geolocation.getCurrentPosition((position) => {
                resolve({
                  actualPosition: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                  },
                });
              });
            }).then((position) => {
              return position;
            });

            setState((prevState) => ({ ...prevState, ...actualPosition }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            address: propAddress,
            selectedLat: coordinates.lat,
            selectedLng: coordinates.lng,
          }));
        }
      }
    }

    fetchData();
  }, [isVisible]);

  useEffect(() => {
    if (onChange) {
      let onChangeObj = {};

      if (state.address_components) {
        onChangeObj = {
          ...onChangeObj,
          address_components: state.address_components,
        };
      }
      if (state.address) {
        onChangeObj = { ...onChangeObj, address: state.address };
      }
      if (state.selectedLat || state.selectedLng) {
        onChangeObj = {
          ...onChangeObj,
          coordinates: { lat: state.selectedLat, lng: state.selectedLng },
        };
      }
      if (Object.keys(onChangeObj).length > 0) {
        onChange(onChangeObj);
      }
    }
  }, [state, isVisible]);

  const onPlaceSelected = async (place) => {
    if (place.geometry) {
      setState({
        address: place.formatted_address,
        selectedLat: place.geometry.location.lat(),
        selectedLng: place.geometry.location.lng(),
        address_components: [
          ...place.address_components,
          place.formatted_address,
        ],
      });
    } else if (place.name && place.name !== addressNoMod) {
      geocoder({ address: place.name });
    }
  };

  const onMapClick = async (mapProps, map, e) => {
    const coordinates = { lat: e.latLng.lat(), lng: e.latLng.lng() };

    await geocoder({ location: coordinates });
  };

  const geocoder = async (data) => {
    const geocoder = new window.google.maps.Geocoder();

    return await geocoder.geocode(data, (results, status) => {
      if (status === "OK" && results[0]) {
        if (Object.keys(data)[0] === "location") {
          let address_components = [];
          let address_components_formatted = {};
          const keys = {
            locality: "poblacion",
            country: "pais",
            postal_code: "codigoPostal",
            administrative_area_level_1: "provinicia",
          };

          results[0].address_components.forEach((component) => {
            if (component.types && keys[component.types[0]] !== undefined) {
              address_components.push(component);
              address_components_formatted[keys[component.types[0]]] =
                component.long_name;
            }
          });

          setState({
            address: results[0].formatted_address,
            selectedLat: data.location.lat,
            selectedLng: data.location.lng,
            address_components,
          });
        } else {
          setState({
            address: results[0].formatted_address,
            selectedLat: results[0].geometry.location.lat(),
            selectedLng: results[0].geometry.location.lng(),
            address_components: [
              ...results[0].address_components,
              results[0].formatted_address,
            ],
          });
        }
      }
    });
  };

  const selectedPosition =
    state.selectedLat && state.selectedLng
      ? { lat: state.selectedLat, lng: state.selectedLng }
      : undefined;
  const style = {
    borderRadius: 10,
    position: "relative",
    width: "78vw",
    height: "90%",
  };

  return (
    <>
      <Map
        draggableCursor={"default"}
        style={style}
        zoomControl={false}
        mapTypeControl={false}
        scaleControl={false}
        streetViewControl={false}
        rotateControl={false}
        fullscreenControl={false}
        keyboardShortcuts={false}
        disableDoubleClickZoom={false}
        onClick={onMapClick}
        google={google}
        zoom={14}
        initialCenter={coordinates ? coordinates : defultCoordinates}
        center={selectedPosition ? selectedPosition : state.actualPosition}
      >
        <Autocomplete
          onChange={(value) =>
            setState((prevState) => ({ ...prevState, address: value }))
          }
          value={state.address ?? undefined}
          placeholder={getTrad("introduceDireccion")}
          language={language}
          apiKey={GOOGLE_MAPS_API_KEY}
          debounce={debounce}
          onPlaceSelected={onPlaceSelected}
        />
        {state.selectedLat && state.selectedLng ? (
          <Marker position={selectedPosition} />
        ) : null}
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
  language: "es",
})(MapContainer);
