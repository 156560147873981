import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef, useMemo } from "react";

import DxForm, { Item } from "devextreme-react/form";
import { getTrad } from "helpers";

import DataGridArticulos from "components/Administracion/DataGridArticulos";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { Card, CardBody } from "reactstrap";

import {
    datasource_tblAdmTipoElemento, //tblTipoElemento,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import { generateCode } from "pages/Administracion/shared/functions";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import CentroCosteElementoPepComponent from "pages/Administracion/shared/components/CentroCosteElementoPepComponent";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";
import { getClearPedido } from "pages/Administracion/PedidoProveedor/model";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda }, ref) => {
    const [codigoElemento, setCodigoElemento] = useState();
    const getText = (trad) => ({ text: getTrad(trad) });
    const previousCode = useRef();
    const formSuperiorRef = useRef();
    const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const CCEPFormRef = useRef();
    const TCTCFormRef = useRef();
    const colCount = 5;
    const multiplier = 3;

    useImperativeHandle(ref, () => ({
        getFormData,
        hasEditData,
    }));

    const getFormData = () => formSuperiorRef?.current?.instance?.option("formData");

    useEffect(() => {
        validations.push(checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setCodigoElemento(formData.codigo);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if(prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const checkIsValid = () => {
        const ccepFormValidation = CCEPFormRef?.current?.checkIsValid(); 
        const tctcFormValidation = TCTCFormRef?.current?.checkIsValid();
        const superiorFormValidation = formSuperiorRef.current.instance.validate();
        const inferiorFormValidation = formInferiorRef.current.instance.validate();
        const isFormvalidated = superiorFormValidation.isValid && inferiorFormValidation.isValid && ccepFormValidation.isValid && tctcFormValidation.isValid;
        return new Promise((resolve, reject) => {
            if (isFormvalidated) {
                resolve();
            }
            reject();
        });
    };

    const labels = {
        codigo: getText("codigo"),
        idAdmProveedor: getText("proveedor"),
        fechaCreacion: getText("fecha"),
        idMoneda: getText("moneda"),
        idTipoPedido: getText("tipoPedido"),
        idAdmFormaPago: getText("formaPago"),
        descuento: getText("descuento"),
        idIncoterm: getText("incoterms"),
        idAdmPedido_Estado: getText("estado"),
        fechaEstimadaRecepcion: getText("fechaEstimadaRecepcion"),
        numPresupuestoProveedor: getText("numPresupuestoProveedor"),
        observaciones: getText("observaciones"),
        bruto: getText("totalBruto"),
        neto: getText("neto"),
    };

    const editorOptions = {
        idTipoPedido: {
            dataSource: datasource_tblAdmTipoElemento,
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) =>
                e.value != null && 
                    generateCode(formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date(), setCodigoElemento_Wrapper, e.value, "pedidoProveedor"),
            placeholder: getTrad("tipoPedido"),
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
        }
        if (e.dataField === "fechaCreacion" && formData.idTipoPedido != null) {
            generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoPedido, "pedidoProveedor");
        }
    };

    const hasEditData = (referenceFormData = getClearPedido()) => {
        let editData = true;
        const {
            numPresupuesto_codigo,
            idAdmProveedor,
            idAdmFormaPago,
            idMoneda,
            idAdmTipoCambio,
            tasaCambio,
            descuento,
            idAdmTipoDescuento,
            idIvaNPais
        } = formSuperiorRef.current.instance.option("formData")
        if(
            numPresupuesto_codigo == referenceFormData.numPresupuesto_codigo &&
            idAdmProveedor == referenceFormData.idAdmProveedor &&
            idAdmFormaPago == referenceFormData.idAdmFormaPago &&
            idMoneda == referenceFormData.idMoneda &&
            idAdmTipoCambio == referenceFormData.idAdmTipoCambio &&
            tasaCambio == referenceFormData.tasaCambio &&
            descuento == referenceFormData.descuento &&
            idAdmTipoDescuento == referenceFormData.idAdmTipoDescuento &&
            idIvaNPais == referenceFormData.idIvaNPais //&&
            // formData.articulosDraft.length == referenceFormData.articulosDraft.length
        ) {
            editData = false;
        }
        return editData;
    }

    return (
        <>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex mt-4"}>
                        <div className="ml-auto">{ChipEstado(formData.idAdmPedido_Estado, "", "pedido")}</div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={formData.isCerrado}
                        >
                            <Item
                                dataField={"idTipoPedido"}
                                label={labels.idTipoPedido}
                                editorOptions={editorOptions.idTipoPedido}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                editorOptions={sharedEditorOptions.codigo}
                                editorType={"dxTextBox"}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fechaCreacion"}
                                label={labels.fechaCreacion}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idMoneda}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmProveedor"}
                                label={labels.idAdmProveedor}
                                editorType={"dxSelectBox"}
                                editorOptions={sharedEditorOptions.idAdmProveedor}
                                colSpan={1 * multiplier + 2}
                                isRequired
                            />
                            <Item
                                colSpan={2 * multiplier}
                            >
                                <TasaCambioTipoCambioComponent
                                    ref={TCTCFormRef}
                                    formData={formData}
                                />
                            </Item>
                        </DxForm>
                    </div>
                </CardBody>
            </Card>

            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "300px",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "visible",
                    }}
                >
                    <CardBody>
                        <DataGridArticulos
                            idMoneda={idMoneda}
                            dataSource={formData.articulosDraft}
                            validations={validations}
                            updateSummary={summaryFormRef?.current?.updateSummary}
                            tipoArticulos={formData.idTipoPedido}
                            isCompra={true}
                            readOnly={formData.isCerrado}
                        />
                    </CardBody>
                </Card>
            </div>

            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <DxForm
                                ref={formInferiorRef}
                                formData={formData}
                                onFieldDataChanged={onFieldDataChanged}
                                colCount={4 * multiplier}
                                height={"100%"}
                                width={"100%"}
                                labelLocation={"top"}
                                readOnly={formData.isCerrado}
                            >
                                <Item
                                    dataField={"idAdmFormaPago"}
                                    label={labels.idAdmFormaPago}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idAdmFormaPago}
                                    colSpan={1 * multiplier + 1}
                                    isRequired
                                />
                                <Item colSpan={4 * multiplier - 4}>
                                    <CentroCosteElementoPepComponent ref={CCEPFormRef} formData={formData} />
                                    {/* <CustomRule validationCallback={CCEPFormRef?.current?.checkIsValid}/> */}
                                </Item>
                                <Item
                                    dataField={"idIncoterm"}
                                    label={labels.idIncoterm}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idIncoterm}
                                    colSpan={1 * multiplier}
                                />
                                <Item
                                    dataField={"numPresupuestoProveedor"}
                                    label={labels.numPresupuestoProveedor}
                                    editorOptions={editorOptions.numPresupuestoProveedor}
                                    colSpan={1 * multiplier}
                                />
                                <Item
                                    dataField={"fechaEstimadaRecepcion"}
                                    label={labels.fechaEstimadaRecepcion}
                                    editorOptions={sharedEditorOptions.fechaCreacion}
                                    editorType={"dxDateBox"}
                                    colSpan={2}
                                />
                                <Item
                                    dataField={"observaciones"}
                                    label={labels.observaciones}
                                    editorType={"dxTextArea"}
                                    colSpan={1 * multiplier + 1}
                                    // rowSpan={2}
                                />
                            </DxForm>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginRight: 0,
                            marginLeft: "auto",
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                        }}
                    >
                        <CardBody>
                            <TablaResumen
                                ref={summaryFormRef}
                                formData={formData}
                                idMoneda={idMoneda}
                                showIva={false}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    );
});

export default Form;
