import React, { useEffect, useRef, useState } from "react";

import { EmptyItem, Item, AsyncRule } from "devextreme-react/form";

import AlmacenDestinoItem, {
  destinoOptions,
} from "pages/AssistantNew/Movimientos/shared/components/FormItems/AlmacenDestinoItem";
import SharedForm from "pages/AssistantNew/Movimientos/shared/components/SharedFormMovimientoRecambio";
import { getTrad } from "helpers";
import { sharedLookupOptions } from "pages/AssistantNew/Movimientos/shared/constants";
import {
  context_tblMovimientoRecambio,
  datasource_tblProveedor,
} from "pages/AssistantNew/Movimientos/shared/api";

const FormMovimientoRecambio = ({
  formData,
  setCodigoMoneda,
  disableCoreFields,
  ...props
}) => {
  const formRef = useRef(null);

  const {
    fecha,
    idAlmacenDestino,
    idMovimientoRecambio,
    idProveedor,
    idTipoMovimientoRecambio,
  } = formData;

  const [tblProveedor, setTblProveedor] = useState([]);
  const [fieldsDisableBuscador, setFieldsDisableBuscador] = useState({
    fecha,
    idProveedor,
    idAlmacenDestino,
  });

  useEffect(() => {
    datasource_tblProveedor.load().done(setTblProveedor);
  }, []);

  useEffect(() => {
    setFieldsDisableBuscador({
      fecha,
      idProveedor,
      idAlmacenDestino,
    });
  }, [idMovimientoRecambio]);

  const getText = (label) => ({ text: getTrad(label) });

  const validationCallback = (e) => {
    let value = e.value.replace(/\s+/g, " ").trim();

    return new Promise((resolve) => {
      context_tblMovimientoRecambio
        .invoke(
          "fn_isNumPedidoAsociadoExists",
          {
            numPedidoAsociado: value,
            idMovimientoRecambio: idMovimientoRecambio,
            idTipoMovimientoRecambio: idTipoMovimientoRecambio,
          },
          "POST"
        )
        .done((exists) => resolve(!exists));
    });
  };

  const editorOptions = {
    idProveedor: {
      dataSource: tblProveedor,
      valueExpr: "idProveedor",
      displayExpr: "nombreComercial",
      placeholder: getTrad("seleccioneProveedor"),
      readOnly: disableCoreFields,
      onValueChanged: ({ value }) =>
        setFieldsDisableBuscador({
          ...fieldsDisableBuscador,
          idProveedor: value,
        }),
      ...sharedLookupOptions,
    },
  };

  const origenOptions = {
    dataField: "idProveedor",
    label: getText("proveedor"),
    editorOptions: editorOptions.idProveedor,
    editorType: "dxLookup",
    colSpan: 3,
    isRequired: true,
  };

  const destino = (
    <AlmacenDestinoItem
      formRef={formRef}
      formData={formData}
      onlyAlmacenPadre
      setCodigoMoneda={setCodigoMoneda}
      readOnly={disableCoreFields}
      onChange={(value) =>
        setFieldsDisableBuscador({
          ...fieldsDisableBuscador,
          idAlmacenDestino: value,
        })
      }
    />
  );

  const destinoOptionsFinal = {
    label: getText("almacenDestino"),
    ...destinoOptions,
  };

  const disableBuscador = Object.keys(fieldsDisableBuscador).some(
    (key) => fieldsDisableBuscador[key] === null
  );

  const onChangeFecha = ({ value }) =>
    setFieldsDisableBuscador({ ...fieldsDisableBuscador, fecha: value });

  return (
    <SharedForm
      ref={formRef}
      formData={formData}
      origenOptions={origenOptions}
      destino={destino}
      destinoOptions={destinoOptionsFinal}
      disableCoreFields={disableCoreFields}
      disableBuscador={disableBuscador}
      onChangeFecha={onChangeFecha}
      {...props}
    >
      <Item
        dataField={"numPedidoAsociado"}
        label={getText("pedidoAsociado")}
        colSpan={3}
      >
        <AsyncRule
          ignoreEmptyValue={true}
          message={getTrad("yaExisteNumPedidoAsociado")}
          validationCallback={validationCallback}
        />
      </Item>
      <Item
        dataField={"codigoAlbaranProveedor"}
        label={getText("codigoProveedor")}
        colSpan={2}
      />
      <EmptyItem />
    </SharedForm>
  );
};

export default FormMovimientoRecambio;
