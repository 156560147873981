import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  endOfMonth,
  startOfMonth,
  formatDate_parameter,
  dxMensajePregunta,
} from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var idEntidadSel = -1;
  var datasource_estanciaDiario,
    compaSel = null;

  //#region DATASOURCES
  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      onLoading: function (loadOptions) {
        if (compaSel) loadOptions.filter = ["idCompañia", "=", compaSel];
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "idCompañia", "denominacion"],
  });

  datasource_estanciaDiario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEstancia",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var fechaSel = $("#dxBox_dxCalendar").dxCalendar("option").value;
        var fechaHasta = endOfMonth(fechaSel);
        var fechaDesde = startOfMonth(fechaSel);
        request.params.idEntidad = idEntidadSel;

        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
    postProcess: function (data) {
      if ($("#dxBox_dxCalendar").length > 0) {
        var fechaSel = $("#dxBox_dxCalendar").dxCalendar("option").value;
        var diasTotales = new Date(
          fechaSel.getFullYear(),
          fechaSel.getMonth() + 1,
          0,
        ).getDate();
        var año = fechaSel.getFullYear();
        var mes = fechaSel.getMonth();

        var func_filtro = function (v) {
          return v.fecha.getDate() === i;
        };
        for (var i = 1; i <= diasTotales; i++) {
          var encontrado = $.grep(data, func_filtro);
          if (encontrado.length === 0) {
            data.splice(i, 0, {
              fecha: new Date(año, mes, i),
              estanciasPrevistas: 0,
              estanciasReal: 0,
              salidas: 0,
              diferencia: 0,
            });
          }
        }
        data = data.sort(function (a, b) {
          return new Date(a.fecha) - new Date(b.fecha);
        });
      }
      return data;
    },
  });

  //#endregion

  //#region Controles

  $("#Estancias #dxContainer").dxBox({
    direction: "row",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    items: [
      {
        baseSize: 280,
        shrink: 0,
        ratio: 0,
        template: function () {
          return $("<div />").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            items: [
              {
                //Calendar
                baseSize: 235,
                template: function (e, index, item) {
                  return $("<div  id='dxBox_dxCalendar'/>").dxCalendar({
                    minZoomLevel: "year",
                    maxZoomLevel: "year",
                    value: new Date(),
                    onValueChanged: function (e) {
                      var dxDataGrid_diario =
                        $("#dxDataGrid_diario").dxDataGrid("instance");
                      dxDataGrid_diario.refresh();
                    },
                  });
                },
              },
              {
                //Compañías
                baseSize: 50,
                shrink: 0,
                ratio: 0,
                template: function (e, index, item) {
                  item.css("padding-top", "15px");
                  datasource_compañias.load().done(function (compañias_items) {
                    item.append(
                      $("<div/>").dxSelectBox({
                        items: compañias_items,
                        valueExpr: "idCompañia",
                        displayExpr: "denominacion",
                        placeholder: getTrad("seleccioneCompañia"),
                        showClearButton: true,
                        width: "100%",
                        onValueChanged: function (e) {
                          var selectedItems =
                            e.component.option("selectedItem");
                          if (selectedItems)
                            compaSel =
                              e.component.option("selectedItem").idCompañia;
                          else compaSel = null;

                          datasource_entidades.load();
                        },
                      }),
                    );
                  });
                },
              },
              {
                //Entidades
                ratio: 1,
                template: function (e, index, item) {
                  item.css("padding-top", "10px");
                  return $("<div />").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 40,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          return $(
                            "<div class='font-size'>" +
                              getTrad("entidades") +
                              "</div>",
                          );
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id='dxList_entidad'>").dxList({
                            height: "100%",
                            dataSource: datasource_entidades,
                            selectionMode: "single",
                            searchEnabled: true,
                            searchExpr: ["denominacion"],
                            pageLoadMode: "scrollBottom",
                            onSelectionChanged: function (e) {
                              if (e.addedItems.length > 0) {
                                idEntidadSel =
                                  e.component.option("selectedItem").idEntidad;
                                var dxDataGrid_diario =
                                  $("#dxDataGrid_diario").dxDataGrid(
                                    "instance",
                                  );
                                dxDataGrid_diario.refresh().done(function (e) {
                                  var data = $("#dxDataGrid_diario")
                                    .dxDataGrid("instance")
                                    .getDataSource()
                                    .items();
                                  establecerMensualDiario(data);
                                });
                              }
                            },
                            itemTemplate: function (data, index) {
                              return $("<div>").text(data.denominacion);
                            },
                          });
                        },
                      },
                    ],
                  });
                },
              },
            ],
          });
        },
      },
      {
        ratio: 1,
        template: function () {
          return $("<div  />").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            items: [
              {
                baseSize: 140,
                shrink: 0,
                ratio: 0,
                template: function () {
                  return $("<div style='margin-left:25px;'/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 50,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          var datasource_mensual = [
                            { text: "Mensual", value: true },
                          ];
                          return $(
                            "<div id='dxRadioGroup_mensual'/>",
                          ).dxRadioGroup({
                            dataSource: datasource_mensual,
                            disabled: true,
                            value: null,
                            width: "100%",
                            valueExpr: "value",
                            height: "35px",
                            onValueChanged: function (e) {
                              if (e.value) {
                                var cont = 0;
                                var dxDataGrid_diario =
                                  $("#dxDataGrid_diario").dxDataGrid(
                                    "instance",
                                  );
                                var dxDataGrid_estanciasDiarias =
                                  dxDataGrid_diario.getVisibleRows();
                                var fechaSel =
                                  $("#dxBox_dxCalendar").dxCalendar(
                                    "option",
                                  ).value;
                                var fechaHasta = endOfMonth(fechaSel);

                                $.each(
                                  dxDataGrid_estanciasDiarias,
                                  function (rowIndex, item) {
                                    if (
                                      (item.data.estanciasPrevistas !== 0 ||
                                        item.data.estanciasReal !== 0 ||
                                        item.data.salidas !== 0) &&
                                      item.data.fecha.getTime() !==
                                        fechaHasta.getTime()
                                    ) {
                                      cont++;
                                    }
                                  },
                                );

                                if (cont >= 1) {
                                  dxMensajePregunta(
                                    getTrad("preg_DesbloquearMensual"),
                                    [
                                      [
                                        getTrad("aceptar"),
                                        function () {
                                          $.each(
                                            dxDataGrid_estanciasDiarias,
                                            function (rowIndex, item) {
                                              if (
                                                (item.data
                                                  .estanciasPrevistas !== 0 ||
                                                  item.data.estanciasReal !==
                                                    0 ||
                                                  item.data.salidas !== 0) &&
                                                item.data.fecha.getTime() !==
                                                  fechaHasta.getTime()
                                              ) {
                                                datasource_estanciaDiario
                                                  .store()
                                                  .remove(item.data.fecha);
                                              }
                                            },
                                          );
                                          var ultimoDiaMes = {
                                            fecha: fechaHasta,
                                            idEntidad: idEntidadSel,
                                            estanciasPrevistas:
                                              dxDataGrid_diario.getTotalSummaryValue(
                                                "suma_estanciasPrevistas",
                                              ),
                                            estanciasReal:
                                              dxDataGrid_diario.getTotalSummaryValue(
                                                "suma_estanciasReal",
                                              ),
                                            salidas:
                                              dxDataGrid_diario.getTotalSummaryValue(
                                                "suma_salidas",
                                              ),
                                          };

                                          datasource_estanciaDiario
                                            .store()
                                            .update(
                                              ultimoDiaMes.fecha,
                                              ultimoDiaMes,
                                            )
                                            .done(function () {
                                              dxDataGrid_diario.refresh();
                                            });

                                          $("#dxForm_mensual")
                                            .dxForm("instance")
                                            .option("disabled", false);
                                          $("#dxRadioGroup_diario")
                                            .dxRadioGroup("instance")
                                            .option("value", null);
                                          dxDataGrid_diario.option(
                                            "disabled",
                                            true,
                                          );
                                        },
                                        "normal",
                                        "btnAceptar_alert",
                                      ],
                                      [
                                        getTrad("cancelar"),
                                        function () {
                                          e.component.option("value", null);
                                        },
                                        "normal",
                                        "btnCancelar_alert",
                                      ],
                                    ],
                                  );
                                } else {
                                  $("#dxForm_mensual")
                                    .dxForm("instance")
                                    .option("disabled", false);
                                  $("#dxRadioGroup_diario")
                                    .dxRadioGroup("instance")
                                    .option("value", null);
                                  dxDataGrid_diario.option("disabled", true);
                                }
                              }
                            },
                          });
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id = 'dxForm_mensual'>").dxForm({
                            width: "100%",
                            height: "100%",
                            labelLocation: "top",
                            colCount: 4,
                            disabled: true,
                            items: [
                              {
                                dataField: "estanciasPrevistas",
                                editorType: "dxNumberBox",
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                                editorOptions: {
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  value: 0,
                                  step: 0,
                                },
                                label: {
                                  text: getTrad("estanciasPrevistas"),
                                },
                              },
                              {
                                dataField: "estanciasReal",
                                editorType: "dxNumberBox",
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                                editorOptions: {
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  value: 0,
                                  step: 0,
                                },
                                label: {
                                  text: getTrad("estanciasReal"),
                                },
                              },
                              {
                                dataField: "diferencia",
                                editorType: "dxNumberBox",
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                                editorOptions: {
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  value: 0,
                                  disabled: true,
                                },
                                label: {
                                  text: getTrad("diferencia"),
                                },
                              },
                              {
                                dataField: "salidas",
                                editorType: "dxNumberBox",
                                format: {
                                  style: "decimal",
                                  maximumFractionDigits: 0,
                                },
                                editorOptions: {
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  value: 0,
                                  step: 0,
                                },
                                label: {
                                  text: getTrad("salidas"),
                                },
                              },
                            ],
                            onFieldDataChanged: function (e) {
                              var dxDataGrid_diario =
                                $("#dxDataGrid_diario").dxDataGrid("instance");
                              if (typeof dxDataGrid_diario !== "undefined") {
                                var sum =
                                  dxDataGrid_diario.getTotalSummaryValue(
                                    "suma_" + e.dataField,
                                  );

                                if (sum !== e.value) {
                                  insertarDatosForm();
                                }
                              }
                            },
                            onEditorEnterKey: function (e) {
                              var dxFormElements = e.component.option().items;

                              $.each(dxFormElements, function (index, item) {
                                if (item.dataField === e.dataField) {
                                  var contador =
                                    item.dataField === "estanciasReal"
                                      ? index + 2
                                      : item.dataField === "salidas"
                                        ? 0
                                        : index + 1;
                                  e.component
                                    .getEditor(
                                      dxFormElements[contador].dataField,
                                    )
                                    .focus();
                                }
                              });
                            },
                          });
                        },
                      },
                    ],
                  });
                },
              },
              {
                ratio: 1,
                template: function () {
                  return $("<div style='margin-left:25px;'/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 50,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          var datasource_diario = [
                            { text: "Diario", value: true },
                          ];
                          return $(
                            "<div id='dxRadioGroup_diario'/>",
                          ).dxRadioGroup({
                            dataSource: datasource_diario,
                            value: null,
                            disabled: true,
                            width: "100%",
                            valueExpr: "value",
                            height: "35px",
                            onValueChanged: function (e) {
                              if (e.value) {
                                $("#dxDataGrid_diario")
                                  .dxDataGrid("instance")
                                  .option("disabled", false);
                                $("#dxRadioGroup_mensual")
                                  .dxRadioGroup("instance")
                                  .option("value", null);
                                $("#dxForm_mensual")
                                  .dxForm("instance")
                                  .option("disabled", true);
                              }
                            },
                          });
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id='dxDataGrid_diario' />").dxDataGrid(
                            {
                              //Propiedades
                              width: "100%",
                              height: "100%",
                              disabled: true,
                              paging: false,
                              remoteOperations: {
                                groupPaging: true,
                                paging: true,
                                filtering: true,
                                sorting: true,
                                grouping: true,
                                summary: false,
                              },
                              dataSource: datasource_estanciaDiario,
                              columnsAutoWidth: true,
                              keyboardNavigation: {
                                enterKeyAction: "moveFocus",
                                enterKeyDirection: "row",
                                editOnKeyPress: true,
                              },
                              editing: {
                                mode: "cell",
                                allowUpdating: true,
                                selectTextOnEditStart: true,
                              },
                              columns: [
                                {
                                  dataField: "fecha",
                                  caption: getTrad("fecha"),
                                  sortIndex: 1,
                                  sortOrder: "asc",
                                  dataType: "date",
                                  format: "dd/MM/yyyy",
                                  allowEditing: false,
                                  allowSorting: false,
                                },
                                {
                                  dataField: "estanciasPrevistas",
                                  caption: getTrad("estanciasPrevistas"),
                                  alignment: "center",
                                  cssClass: "dx-Cell_Editable",
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  allowSorting: false,
                                  editorOptions: {
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                    refreshMode: "repaint",
                                    step: 0,
                                    max: 1000000,
                                    min: 0,
                                  },
                                },
                                {
                                  dataField: "estanciasReal",
                                  caption: getTrad("estanciasReales"),
                                  alignment: "center",
                                  cssClass: "dx-Cell_Editable",
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  allowSorting: false,
                                  editorOptions: {
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                    refreshMode: "repaint",
                                    step: 0,
                                    max: 1000000,
                                    min: 0,
                                  },
                                },
                                {
                                  dataField: "diferencia",
                                  name: "diferencia",
                                  caption: getTrad("diferencia"),
                                  alignment: "center",
                                  allowEditing: false,
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  allowSorting: false,
                                  calculateCellValue: function (rowData) {
                                    return (
                                      rowData.estanciasReal -
                                      rowData.estanciasPrevistas
                                    );
                                  },
                                },

                                {
                                  dataField: "salidas",
                                  caption: getTrad("salidas"),
                                  alignment: "center",
                                  cssClass: "dx-Cell_Editable",
                                  format: {
                                    style: "decimal",
                                    maximumFractionDigits: 0,
                                  },
                                  allowSorting: false,
                                  editorOptions: {
                                    refreshMode: "repaint",
                                    format: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                    step: 0,
                                    max: 1000000,
                                    min: 0,
                                  },
                                },
                              ],
                              summary: {
                                recalculateWhileEditing: true,
                                totalItems: [
                                  {
                                    name: "suma_estanciasPrevistas",
                                    column: "estanciasPrevistas",
                                    summaryType: "sum",
                                    displayFormat: "{0}",
                                    valueformat: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                  },
                                  {
                                    name: "suma_estanciasReal",
                                    column: "estanciasReal",
                                    summaryType: "sum",
                                    displayFormat: "{0}",
                                    valueformat: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                  },
                                  {
                                    name: "suma_salidas",
                                    column: "salidas",
                                    summaryType: "sum",
                                    displayFormat: "{0}",
                                    valueformat: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                  },
                                  {
                                    name: "suma_diferencia",
                                    column: "diferencia",
                                    summaryType: "sum",
                                    displayFormat: "{0}",
                                    valueformat: {
                                      style: "decimal",
                                      maximumFractionDigits: 0,
                                    },
                                  },
                                ],
                              },
                              onContentReady: function (e) {
                                actualizarMensual();
                              },
                              showColumnLines: false,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            },
                          );
                        },
                      },
                    ],
                  });
                },
              },
            ],
          });
        },
      },
    ],
  });

  function actualizarMensual() {
    var dxDataGrid_diario = $("#dxDataGrid_diario").dxDataGrid("instance");
    if (dxDataGrid_diario) {
      var datosForm = {};
      datosForm.estanciasPrevistas = dxDataGrid_diario.getTotalSummaryValue(
        "suma_estanciasPrevistas",
      );
      datosForm.estanciasReal =
        dxDataGrid_diario.getTotalSummaryValue("suma_estanciasReal");
      datosForm.salidas =
        dxDataGrid_diario.getTotalSummaryValue("suma_salidas");
      datosForm.diferencia =
        dxDataGrid_diario.getTotalSummaryValue("suma_diferencia");

      $("#dxForm_mensual").dxForm("instance").updateData(datosForm);
    }
  }

  function insertarDatosForm() {
    var datosForm = $("#dxForm_mensual").dxForm("instance").option("formData");
    var datos = $.extend(true, {}, datosForm);

    var fechaSel = $("#dxBox_dxCalendar").dxCalendar("option").value;
    var fechaHasta = endOfMonth(fechaSel);

    delete datos.diferencia;
    datos.idEntidad = idEntidadSel;
    datos.fecha = fechaHasta;

    datasource_estanciaDiario
      .store()
      .update(fechaHasta, datos)
      .done(function () {
        $("#dxDataGrid_diario").dxDataGrid("instance").refresh();
      });
  }

  function establecerMensualDiario(data) {
    var fechaSel = $("#dxBox_dxCalendar").dxCalendar("option").value;
    var fechaHasta = endOfMonth(fechaSel);
    var cont = 0;

    var dxRadioGroup_mensual = $("#dxRadioGroup_mensual").dxRadioGroup(
      "instance",
    );
    var dxRadioGroup_diario = $("#dxRadioGroup_diario").dxRadioGroup(
      "instance",
    );

    $.each(data, function (rowIndex, item) {
      if (
        (item.estanciasPrevistas !== 0 ||
          item.estanciasReal !== 0 ||
          item.salidas !== 0) &&
        item.fecha.getTime() !== fechaHasta.getTime()
      ) {
        cont++;
        return false;
      }
    });
    if (cont === 0) {
      dxRadioGroup_mensual.option({
        value: true,
        disabled: false,
      });
      dxRadioGroup_diario.option({
        value: null,
        disabled: false,
      });
    } else {
      dxRadioGroup_mensual.option({
        value: null,
        disabled: false,
      });
      dxRadioGroup_diario.option({
        value: true,
        disabled: false,
      });
    }
  }

  deferred.resolve();
  return deferred.promise();
}
