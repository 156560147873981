import React, { Component } from "react";
import $ from "jquery";
import { connect } from "react-redux";

import { loadPanelActions } from "actions";
import {
    authHeader,
    errorHandler,
    formatDate_noTime_parameter,
    getNombreFormulario,
    getTrad,
    startOfMonth,
} from "helpers";
import PageTitle from "layout/AppMain/PageTitle";

import Box, { Item as ItemBox } from "devextreme-react/box";

import "./css.scss";
import GridPersonas from "./components/GridPersonas";
import CalendarioPersonal from "components/CalendarioPersonal";
import GridHoras from "./components/GridHoras";
import { DateBox, SelectBox, Switch } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import { personaActions } from "actions/fotos";
import { CalendarOptions } from "devextreme-react/date-box";
import ArrayStore from "devextreme/data/array_store";
import ODataContext from "devextreme/data/odata/context";
import LottieIcon from "components/LottieIcon";

class InformeEventosPersona extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tblDesglose: [],
            tblInforme: [],
            cierres: {},
            fechaSel: startOfMonth(new Date()),
            idPersonaSel: null,
            vista: this.vistas.HorasExtras,
            selEntreCierres: true,
            ts_CalendarioPersonal: `ts-${Date.now()}`,
        };

        this.GridHoras_REF = React.createRef();
        this.GridPersonas_REF = React.createRef();
    }

    get GridHoras() {
        return this.GridHoras_REF.current?.dxDataGrid_REF?.current?.instance;
    }

    get GridPersonas() {
        return this.GridPersonas_REF.current?.dxDataGrid_REF?.current?.instance;
    }

    vistas = {
        BolsaHoras: { id: 1, label: getTrad("bolsaHoras") },
        HorasExtras: { id: 2, label: getTrad("horasExtra") },
        Nocturnidad: { id: 3, label: getTrad("nocturnidad") },
        Absentismo: { id: 4, label: getTrad("absentismo") },
        Festivo: { id: 5, label: getTrad("festivoTrabajado") },
        Impuntualidad: { id: 6, label: getTrad("impuntualidad") },
    };

    dxDateBox_inputAttr = { class: "text-capitalize" };

    dxDateBox_max = new Date();

    // #region Lifecycle

    componentDidMount() {
        this.datasource_tblInforme.reload();
        this.context_Cierres.invoke("Cierres", {}, "GET").then((data) => {
            this.setState({ cierres: data });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { idPersonaSel, fechaSel, vista, selEntreCierres } = this.state;
        const { fechaSel: prevFechaSel, vista: prevVista, selEntreCierres: prevSelEntreCierres } = prevState;

        if (fechaSel !== prevFechaSel || vista !== prevVista || selEntreCierres !== prevSelEntreCierres) {
            if (vista !== prevVista) {
                this.controlarMeses();
            }

            if (fechaSel !== prevFechaSel || selEntreCierres !== prevSelEntreCierres) {
                this.context_Cierres.invoke("Cierres", {}, "GET").then((data) => {
                    this.setState({ cierres: data });
                });
            }

            this.datasource_tblInforme.reload().then((data) => {
                if (idPersonaSel != null) {
                    let tblDesglose = data.find((x) => x.idPersona === idPersonaSel)?.registros;
                    this.setState({ tblDesglose: tblDesglose ?? [], ts_CalendarioPersonal: `ts-${Date.now()}` });
                }
            });
        }

        if (prevProps.lavanderia.idLavanderia !== this.props.lavanderia.idLavanderia) {
            this.datasource_tblInforme.reload();
        }
    }

    controlarMeses = () => {
        const { fechaSel, vista } = this.state;
        const mesSeleccionado = fechaSel.getMonth();
        $("[data-month-id]").each((index, element) => {
            const monthId = element.attributes["data-month-id"].value;
            const isVisible =
                monthId == mesSeleccionado ||
                monthId == (mesSeleccionado === 0 ? 11 : mesSeleccionado - 1) ||
                vista === this.vistas.BolsaHoras;
            $(element).css({
                visibility: isVisible ? "visible" : "hidden",
                position: isVisible ? "relative" : "absolute",
            });
            if (monthId === "0" && mesSeleccionado === 0) $(element).appendTo($(element).parent());
        });
    };

    // #endregion

    // #region DataSource

    context_Cierres = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "InformeJornada/",
        entities: {
            Cierres: {},
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => this.context_Cierres_beforeSend(request),
    });

    context_Cierres_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { fechaSel, selEntreCierres } = this.state;
        const { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.fecha = formatDate_noTime_parameter(fechaSel);
        request.params.entreCierres = selEntreCierres;
    };

    datasource_tblInforme = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "InformeJornada",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_Informe_beforeSend(request),
            onLoaded: (data) => this.datasource_Informe_onLoaded(data),
            version: 4,
        }),
    });

    datasource_Informe_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { fechaSel, vista, selEntreCierres } = this.state;
        const { lavanderia } = this.props;
        request.params.idLavanderia = lavanderia.idLavanderia;
        request.params.fecha = formatDate_noTime_parameter(fechaSel);
        request.params.tipo = vista.id;
        request.params.entreCierres = selEntreCierres;
    };

    datasource_Informe_onLoaded = (data) => {
        const { fotosPerfil, loadFotoPerfilMasivo } = this.props;
        const idsPersona = data.map((x) => x.idPersona).filter((id) => fotosPerfil[id] == null);
        loadFotoPerfilMasivo(idsPersona);

        this.setState({ tblInforme: data });
    };

    datasource_vistas = new DataSource({
        store: new ArrayStore({
            data: Object.values(this.vistas),
            key: "id",
        }),
        sort: ["label"],
    });

    // #endregion

    // #region RENDERS

    render() {
        const { user, lavanderia } = this.props;
        const { tblInforme, tblDesglose, fechaSel, vista, selEntreCierres } = this.state;
        return !user.enableDatosRRHH &&
            !user.tblTipoTrabajoNUsuario.some((x) => x.idLavanderia === lavanderia.idLavanderia) ? (
            <div className="container_spanCentrado">
                <div className="container_spanCentrado h-100 w-75">
                    <LottieIcon height="250px" width="250px" icon={"warning"} />
                    <div>
                        <p className="display-4 p-5 m-0">{getTrad("sinAccesoTipoTrabajo")}</p>
                    </div>
                </div>
            </div>
        ) : (
            <Box height={"100%"} className="pb-4 EventosPersona">
                <ItemBox ratio={1} className="d-flex justify-content-center">
                    <PageTitle
                        heading={getNombreFormulario(this)}
                        postHeadingEnd={
                            <div className="d-flex flex-row align-items-center">
                                <SelectBox
                                    dataSource={this.datasource_vistas}
                                    value={vista}
                                    displayExpr={"label"}
                                    onValueChanged={this.dxSelectBox_vista_onValueChanged}
                                    width={180}
                                />
                                <DateBox
                                    defaultValue={fechaSel}
                                    openOnFieldClick={true}
                                    acceptCustomValue={false}
                                    max={this.dxDateBox_max}
                                    className="centerText mx-3"
                                    displayFormat={vista !== this.vistas.BolsaHoras ? "month" : "year"}
                                    inputAttr={this.dxDateBox_inputAttr}
                                    onValueChanged={this.dxDateBox_onValueChanged}
                                    width={180}
                                >
                                    <CalendarOptions
                                        maxZoomLevel={vista !== this.vistas.BolsaHoras ? "year" : "decade"}
                                    />
                                </DateBox>
                                <Switch
                                    value={selEntreCierres}
                                    switchedOnText={getTrad("entreCierres")}
                                    switchedOffText={getTrad("mesNatural")}
                                    width={180}
                                    className="bg-white dx-switch-header-active"
                                    onValueChanged={this.dxSwitch_onValueChanged}
                                />
                            </div>
                        }
                    />
                    <Box direction="row" width="auto" className="py-4" height={"100%"}>
                        <ItemBox ratio={1} className="d-flex justify-content-center">
                            <Box className="containerListaPersonas w-100 h-100 ">
                                <ItemBox ratio={1} className="d-flex justify-content-center">
                                    <GridPersonas
                                        ref={this.GridPersonas_REF}
                                        dataSource={tblInforme}
                                        vista={vista}
                                        onSelectionChanged={this.GridPersonas_onSelectionChanged}
                                    />
                                </ItemBox>
                            </Box>
                        </ItemBox>
                        <ItemBox baseSize={20} />
                        <ItemBox ratio={2} className="d-flex justify-content-center">
                            <Box direction="row" width="auto" height="100%">
                                <ItemBox ratio={1} className="d-flex justify-content-center">
                                    <Box direction="col" height={"100%"} width={"100%"}>
                                        <ItemBox ratio={1}>
                                            <this.render_calendario />
                                        </ItemBox>
                                        <ItemBox baseSize={20} />
                                        <ItemBox ratio={2}>
                                            <Box
                                                direction="row"
                                                width="auto"
                                                height="100%"
                                                className="containerListaPersonas"
                                            >
                                                <ItemBox ratio={1}>
                                                    <GridHoras
                                                        ref={this.GridHoras_REF}
                                                        dataSource={tblDesglose}
                                                        vista={vista}
                                                    />
                                                </ItemBox>
                                            </Box>
                                        </ItemBox>
                                    </Box>
                                </ItemBox>
                            </Box>
                        </ItemBox>
                    </Box>
                </ItemBox>
            </Box>
        );
    }

    render_calendario = () => {
        const { idPersonaSel, fechaSel, vista, cierres, ts_CalendarioPersonal } = this.state;
        const year = fechaSel.getFullYear();

        return (
            <Box direction="row" width="auto" height="100%" className="containerListaPersonas">
                <ItemBox ratio={1}>
                    <div className="w-100 he-100 container_spanCentrado">
                        <div
                            style={{
                                width: vista === this.vistas.BolsaHoras ? "100%" : 700,
                                [vista === this.vistas.BolsaHoras ? "height" : "maxHeight"]: "100%",
                            }}
                            className="px-5 position-relative"
                        >
                            <CalendarioPersonal
                                ts={ts_CalendarioPersonal}
                                idPersona={idPersonaSel}
                                showLoader={false}
                                displayHeader={false}
                                onRenderEnd={this.controlarMeses}
                                year={year}
                                minDate={vista === this.vistas.BolsaHoras ? null : cierres.fechaDesde}
                                maxDate={vista === this.vistas.BolsaHoras ? null : cierres.fechaHasta}
                                dayRender={this.CalendarioPersonal_dayRender}
                                onDayClick={this.CalendarioPersonal_onDayClick}
                            />
                        </div>
                    </div>
                </ItemBox>
            </Box>
        );
    };

    // #endregion

    // #region Eventos

    dxSelectBox_vista_onValueChanged = (e) => {
        this.setState({ vista: e.value });
    };

    dxDateBox_onValueChanged = (e) => {
        this.setState({ fechaSel: e.value });
    };

    dxSwitch_onValueChanged = (e) => {
        this.setState({ selEntreCierres: e.value });
    };

    GridPersonas_onSelectionChanged = (e) => {
        let data = e.selectedRowsData[0];

        if (data?.idPersona != null) {
            this.setState({ idPersonaSel: data.idPersona, tblDesglose: data.registros });
        } else {
            this.setState({ idPersonaSel: null, tblDesglose: [] });
        }
    };

    CalendarioPersonal_dayRender = (element, date, eventos) => {
        let evento = this.state.tblDesglose.find((x) => x.fecha.split("T")[0] === formatDate_noTime_parameter(date));
        if (evento != null) {
            $(element).parent().addClass("horas-mark");
        }
    };

    // #endregion
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    fotosPerfil: state.fotos.fotosPerfil,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
    loadFotoPerfilMasivo: (idPersona) => dispatch(personaActions.loadFotoPerfilMasivo(idPersona)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InformeEventosPersona);
