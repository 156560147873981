import React from "react";

export class colorCellComponent extends React.PureComponent {
  render() {
    return (
      <div className="container_spanCentrado colorCellComponent">
        <div
          className="content"
          style={{ backgroundColor: this.props.data.text }}
        ></div>
      </div>
    );
  }
}
