import React, { Fragment } from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../../../../constants";

//Actions
import { impresionActions, loadPanelActions } from "../../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import OpcionesTitle from "../../../../layout/AppOpciones/Title";
import OpcionesContainer from "../../../../layout/AppOpciones/Container";
import { locale } from "devextreme/localization";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import {
    getNombreFormulario,
    formatDate_parameter,
    errorHandler,
    authHeader,
    getTrad,
    formatNumber,
    formatDate,
    dayDiff,
    dxMensajePregunta,
    formatDate_noTime_parameter,
} from "../../../../helpers";

import DateRangePicker from "../../../../components/DateRangePicker/DateRangePicker";
import $ from "jquery";
import Chart, {
    CommonSeriesSettings,
    Legend,
    Series,
    ValueAxis,
    ArgumentAxis,
    Tooltip as TooltipChart,
    Point,
    Aggregation,
    LoadingIndicator,
} from "devextreme-react/chart";
import {
    CircularGauge,
    Geometry,
    Scale,
    Tick,
    RangeContainer,
    Label,
    ValueIndicator,
    Margin,
    Size,
} from "devextreme-react/circular-gauge";
import DataGrid, { Column, Paging, Summary, TotalItem } from "devextreme-react/data-grid";
import { Button } from "devextreme-react";
import { Cookies } from "react-cookie-banner";
import { Popover } from "devextreme-react/popover";
import Popup, { ToolbarItem } from "devextreme-react/popup";

import notify from "devextreme/ui/notify";

//Js
import cargaJs from "./Js.js";

//Css
import "./Css.scss";
import AppSelector from "../../../../components/AppSelector/AppSelector";

class InfFacturacionCliente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSelector: true,
            datos_Agrupado_Entidades: [],
            datos_Agrupado_Compañias: [],
            datos_Agrupado_Fechas: [],
            fechaSel: null,
            minDate: null,
            maxDate: null,
            entidadSel: null,
            idEntidad_CierreFacturacion: null,
            mesagge_CierreFacturacion: null,
            toolbarOptions_CierreFacturacion: null,
        };

        // REFERENCIAS
        this.dxCircularGauge_compa_REF = React.createRef();
        this.dxChart_compañiaSel_REF = React.createRef();
        this.dxDataGrid_compañiaSel_REF = React.createRef();
        this.dxPopover_REF = React.createRef();

        this.setParams = this.setParams.bind(this);
        this.setReport = this.setReport.bind(this);
        this.closeReport = this.closeReport.bind(this);
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        //DATASOURCE
        this.datasource_infFacturacion_Agrupado_Compañias_beforeSend =
            this.datasource_infFacturacion_Agrupado_Compañias_beforeSend.bind(this);
        this.datasource_infFacturacion_Agrupado_Entidades_beforeSend =
            this.datasource_infFacturacion_Agrupado_Entidades_beforeSend.bind(this);
        this.datasource_infFacturacion_Agrupado_Fechas_beforeSend =
            this.datasource_infFacturacion_Agrupado_Fechas_beforeSend.bind(this);
        this.datasource_infFacturacion_filtroTiempo_beforeSend =
            this.datasource_infFacturacion_filtroTiempo_beforeSend.bind(this);
        this.datasource_infFacturacion_Parametro_Familia_beforeSend =
            this.datasource_infFacturacion_Parametro_Familia_beforeSend.bind(this);
        this.datasource_infFacturacion_Parametro_TipoFacturacion_beforeSend =
            this.datasource_infFacturacion_Parametro_TipoFacturacion_beforeSend.bind(this);
        this.datasource_infFacturacion_filtroTiempos_postProcess =
            this.datasource_infFacturacion_filtroTiempos_postProcess.bind(this);
        this.datasource_infFacturacion_Parametro_TipoFacturacion_map =
            this.datasource_infFacturacion_Parametro_TipoFacturacion_map.bind(this);
        this.datasource_infFacturacion_Parametro_Familia_map =
            this.datasource_infFacturacion_Parametro_Familia_map.bind(this);
        this.datasource_tblCierreFactEntidad_beforeSend = this.datasource_tblCierreFactEntidad_beforeSend.bind(this);

        //EVENTOS
        this.dxChart_compañiaSel_onOptionChanged = this.dxChart_compañiaSel_onOptionChanged.bind(this);
        this.dxChart_customizeTooltip = this.dxChart_customizeTooltip.bind(this);
        this.dxDataGrid_onRowPrepared = this.dxDataGrid_onRowPrepared.bind(this);
        this.dxDataGrid_compañiaSel_onCellHoverChanged = this.dxDataGrid_compañiaSel_onCellHoverChanged.bind(this);
        this.dxDataGrid_onCellClick = this.dxDataGrid_onCellClick.bind(this);
        this.dxDataGrid_circularGauge_calculateCellValue = this.dxDataGrid_circularGauge_calculateCellValue.bind(this);
        this.dxDataGrid_circularGauge_cellRender = this.dxDataGrid_circularGauge_cellRender.bind(this);
        this.dxDataGrid_estancias_cellRender = this.dxDataGrid_estancias_cellRender.bind(this);
        this.dxDataGrid_tipoFactCliente_cellRender = this.dxDataGrid_tipoFactCliente_cellRender.bind(this);
        this.dxDataGrid_costeEstancia_cellRender = this.dxDataGrid_costeEstancia_cellRender.bind(this);
        this.dxDataGrid_importeTotal_cellRender = this.dxDataGrid_importeTotal_cellRender.bind(this);
        this.dxDataGrid_print_cellRender = this.dxDataGrid_print_cellRender.bind(this);
        this.dxDataGrid_cierre_cellRender = this.dxDataGrid_cierre_cellRender.bind(this);
        this.dxDataGrid_compañiaSel_btnPrint_onClick = this.dxDataGrid_compañiaSel_btnPrint_onClick.bind(this);
        this.dxDataGrid_compañiaSel_btnCierre_onClick = this.dxDataGrid_compañiaSel_btnCierre_onClick.bind(this);
        this.dxButton_imprimirTodo_onClick = this.dxButton_imprimirTodo_onClick.bind(this);
        this.dateRangePicker_onDateSelected = this.dateRangePicker_onDateSelected.bind(this);

        //FUNCIONES
        this.getContent_header = this.getContent_header.bind(this);
        this.getContent_front = this.getContent_front.bind(this);
        this.getContent_back = this.getContent_back.bind(this);
        this.getMinSize = this.getMinSize.bind(this);
        this.dataSource_load = this.dataSource_load.bind(this);
        this.refreshContent_back = this.refreshContent_back.bind(this);
        this.getAggregationInterval = this.getAggregationInterval.bind(this);
        this.CustomizeParameterEditors = this.CustomizeParameterEditors.bind(this);
    }

    get dxCircularGauge_compa() {
        return this.dxCircularGauge_compa_REF.current.instance;
    }

    get dxChart_compañiaSel() {
        return this.dxChart_compañiaSel_REF.current.instance;
    }

    get dxDataGrid_compañiaSel() {
        return this.dxDataGrid_compañiaSel_REF.current.instance;
    }

    get dxPopover() {
        return this.dxPopover_REF.current.instance;
    }

    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }

    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    setParams(params) {
        this.props.setParams(params);
    }

    setReport(report) {
        this.props.setReport(report);
    }

    closeReport() {
        this.props.close();
    }

    //#region DATASOURCES
    datasource_Prendas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_Prendas",
            key: ["idPrenda", "idReparto", "idRepartoOffice", "idProduccion", "idAbono"],
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => {
                const { fechaSel } = this;
                const { idEntidad_CierreFacturacion } = this.state;

                request.headers = { ...authHeader() };
                request.params.idEntidad = idEntidad_CierreFacturacion;
                request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
                request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
                request.params.idsRepartos = "";
                request.params.idsRepartosOffice = "";
                request.params.idsProducciones = "";
                request.params.idsAbonos = "";
            },
            version: 4,
        }),
    });

    datasource_infFacturacion_Agrupado_Compañias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_Compañias",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_Agrupado_Compañias_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infFacturacion_Agrupado_Compañias_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;

        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
    }

    datasource_infFacturacion_Agrupado_Entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_Entidades",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_Agrupado_Entidades_beforeSend(request);
            },
            version: 4,
        }),
        sort: [{ selector: "imp", desc: true }],
    });

    datasource_infFacturacion_Agrupado_Entidades_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;

        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
        request.params.idEntidad = null;
    }

    datasource_infFacturacion_Agrupado_Fechas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_Fechas",
            key: "arg",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_Agrupado_Fechas_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infFacturacion_Agrupado_Fechas_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;
        let { fechaSel } = this;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
        request.params.fechaDesde = formatDate_parameter(fechaSel.startDate);
        request.params.fechaHasta = formatDate_parameter(fechaSel.endDate);
    }

    datasource_infFacturacion_filtroTiempo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_filtroTiempo",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_filtroTiempo_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            return this.datasource_infFacturacion_filtroTiempos_postProcess(data);
        },
    });

    datasource_infFacturacion_filtroTiempos_postProcess(data) {
        var item = data[0];
        var datasource = [];
        datasource.push({ arg: item.fechaDesde, value: item.fechaDesde });
        datasource.push({ arg: item.fechaHasta, value: item.fechaHasta });
        return datasource;
    }

    datasource_infFacturacion_filtroTiempo_beforeSend(request) {
        request.headers = { ...authHeader() };
        let { lavanderia } = this.props;

        request.params.idLavanderia = lavanderia ? lavanderia.idLavanderia : null;
    }

    datasource_infFacturacion_Parametro_Familia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            key: "idFamilia",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_Parametro_Familia_beforeSend(request);
            },
            version: 4,
        }),
        expand: "tblTraduccion($select=" + locale() + ")",
        map: (dataItem) => {
            return this.datasource_infFacturacion_Parametro_Familia_map(dataItem);
        },
    });

    datasource_infFacturacion_Parametro_Familia_map(dataItem) {
        return {
            text: dataItem.tblTraduccion[locale()].toUpperCase(),
            value: dataItem.idFamilia,
        };
    }

    datasource_infFacturacion_Parametro_Familia_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_infFacturacion_Parametro_TipoFacturacion = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoFacturacion",
            key: "idTipoFacturacion",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infFacturacion_Parametro_TipoFacturacion_beforeSend(request);
            },
            version: 4,
        }),
        map: (dataItem) => {
            return this.datasource_infFacturacion_Parametro_TipoFacturacion_map(dataItem);
        },
    });

    datasource_infFacturacion_Parametro_TipoFacturacion_map(dataItem) {
        return {
            text: dataItem.denominacion,
            value: dataItem.idTipoFacturacion,
        };
    }

    datasource_infFacturacion_Parametro_TipoFacturacion_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblCierreFactEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCierreFactEntidad_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_tblCierreFactEntidad_beforeSend(request) {
        request.headers = { ...authHeader() };

        let { fechaSel, entidadSel } = this.state;

        if (request.method === "DELETE") {
            let newUrl = request.url.split("tblCierreFactEntidad");
            request.url =
                newUrl[0] +
                "tblCierreFactEntidad" +
                "/" +
                entidadSel.idEntidad +
                "/" +
                formatDate_noTime_parameter(fechaSel.startDate) +
                "/" +
                formatDate_noTime_parameter(fechaSel.endDate);
        }
    }

    enum_infFacturacion_Parametro_TipoPrenda = [
        { text: getTrad("estandar").toUpperCase(), value: 0 },
        { text: getTrad("extra").toUpperCase(), value: 1 },
    ];

    enum_infFacturacion_Parametro_TipoInforme = [
        { text: getTrad("prenda").toUpperCase(), value: "prenda" },
        {
            text: getTrad("familia").toUpperCase(),
            value: this.props.lavanderia && this.props.lavanderia.idLavanderia === 20 ? "familiaEcowhite" : "familia",
        },
        { text: getTrad("albaran").toUpperCase(), value: "tipoConsumoLenceria" },
        { text: getTrad("precio").toUpperCase(), value: "precio" },
    ];
    //#endregion

    render() {
        let {
            showSelector,
            datos_Agrupado_Entidades,
            datos_Agrupado_Compañias,
            datos_Agrupado_Fechas,
            fechaSel,
            minDate,
            maxDate,
            mesagge_CierreFacturacion,
            toolbarOptions_CierreFacturacion,
        } = this.state;

        return (
            <Fragment>
                <PageTitle
                    heading={getNombreFormulario(this)}
                    preHeading={
                        showSelector ? (
                            ""
                        ) : (
                            <div className="backButton dx-icon-chevronleft container_spanCentrado"></div>
                        )
                    }
                    postHeadingBegin={[
                        <DateRangePicker
                            month
                            year
                            day
                            defaultType="month"
                            fechaSel={fechaSel}
                            minDate={minDate}
                            maxDate={maxDate}
                            onDateSelected={this.dateRangePicker_onDateSelected}
                        />,
                        !showSelector && <OpcionesTitle />,
                    ]}
                />
                <div className={"media-body"}>
                    <OpcionesContainer />
                    <div id="InfFacturacionCliente" className="formContainer scrollbar-container">
                        <MultiView
                            height="100%"
                            width="100%"
                            selectedIndex={showSelector ? 0 : 1}
                            focusStateEnabled={false}
                            loop={false}
                            swipeEnabled={false}
                            deferRendering={false}
                            animationEnabled={true}
                        >
                            <MultiViewItem>
                                <AppSelector
                                    rangeSelector={true}
                                    selector="compañia"
                                    getContent_header={this.getContent_header}
                                    getContent_front={this.getContent_front}
                                    getContent_back={this.getContent_back}
                                    getMinSize={this.getMinSize}
                                    dataSource_load={this.dataSource_load}
                                    hideSelector={this.hideSelector}
                                    fechaSel={fechaSel}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    dxTileView_data={datos_Agrupado_Compañias}
                                    refreshContent_back={this.refreshContent_back}
                                />
                            </MultiViewItem>
                            <MultiViewItem>
                                <div id="dxContainer"></div>
                                <div id="dxPopup_refacturar"></div>
                            </MultiViewItem>
                        </MultiView>
                        <Popover ref={this.dxPopover_REF} width={"auto"} height={"auto"}></Popover>
                    </div>
                    <div id="dxTooltip_Facturacion"></div>
                </div>
                <Popup
                    visible={mesagge_CierreFacturacion !== null}
                    showTitle={false}
                    showCloseButton={false}
                    height={"auto"}
                    width={"auto"}
                    maxWidth={500}
                    minWidth={300}
                >
                    <div>{mesagge_CierreFacturacion}</div>
                    <ToolbarItem
                        toolbar={"bottom"}
                        location={"center"}
                        widget={"dxButton"}
                        options={toolbarOptions_CierreFacturacion}
                    />
                    <ToolbarItem
                        toolbar={"bottom"}
                        location={"center"}
                        widget={"dxButton"}
                        options={{
                            text: getTrad("cancelar"),
                            onClick: () =>
                                this.setState({
                                    mesagge_CierreFacturacion: null,
                                    toolbarOptions_CierreFacturacion: null,
                                }),
                        }}
                    />
                </Popup>
            </Fragment>
        );
    }

    dateRangePicker_onDateSelected(fecha) {
        let { fechaSel } = this.state;
        if (fecha) {
            this.dataSource_load(fecha);
        }
    }

    getContent_header(itemData) {
        return (
            <div className="d-flex w-100" style={{ paddingTop: 6, paddingBottom: 3 }}>
                <span className="d-flex align-items-center" style={{ flex: 1 }}>
                    {itemData.denominacion}
                </span>
                <Button
                    icon="icon icon_Impresora"
                    text="Imprimir todo"
                    className="onlyActive"
                    width={160}
                    stylingMode="outlined"
                    onClick={(e) => {
                        this.dxButton_imprimirTodo_onClick(e, itemData);
                    }}
                />
            </div>
        );
    }

    dxButton_imprimirTodo_onClick(e, data) {
        let { fechaSel } = this.state;
        let { lavanderia } = this.props;

        const cookies = new Cookies();
        const currentUser = cookies.get("user");
        let refreshToken = currentUser.refreshToken;

        var params = {
            idCompania: data.idCompañia,
            idEntidad: "",
            fechaDesde: formatDate_parameter(fechaSel.startDate),
            fechaHasta: formatDate_parameter(fechaSel.endDate),
            idLavanderia: lavanderia ? lavanderia.idLavanderia : "",
            idToken: refreshToken,
        };
        this.setParams(params);
    }

    getContent_front(idCompañia) {
        let { datos_Agrupado_Compañias, datos_Agrupado_Entidades } = this.state;
        let { lavanderia } = this.props;

        let compañia = $.grep(datos_Agrupado_Compañias, function (comp) {
            return comp.idCompañia === idCompañia;
        })[0];

        if (compañia) {
            let entidades = $.grep(datos_Agrupado_Entidades, function (entidad) {
                return entidad.idCompañia === idCompañia;
            });

            let moneda = lavanderia ? lavanderia.moneda : entidades[0] ? entidades[0].moneda : "EUR";

            return (
                <div className="he-100 d-flex">
                    <div style={{ padding: "0px 10px" }} className="d-flex flex-column">
                        <div
                            style={{
                                flex: "1",
                                fontSize: "22px",
                                fontWeight: "400",
                                textAlign: "center",
                            }}
                            className="container_spanCentrado"
                        >
                            {formatNumber(compañia.imp, 0, "currency", moneda)}
                        </div>
                        <div style={{ flex: "3" }} className="container_spanCentrado">
                            <CircularGauge value={compañia.perc}>
                                <Geometry startAngle={90} endAngle={90} />
                                <RangeContainer width={8} offset={13} backgroundColor="lightgrey" />
                                <Scale startValue={0} endValue={1}>
                                    <Label visible={false} />
                                    <Tick visible={false} />
                                </Scale>
                                <ValueIndicator type="rangebar" color="#EDB637" size={8} offset={0}></ValueIndicator>
                                <Size width={100} height={100} />
                            </CircularGauge>
                            <div style={{ position: "absolute", fontSize: "26px" }}>
                                {formatNumber(compañia.perc, 0, "percent")}
                            </div>
                        </div>
                    </div>

                    <div style={{ flex: 1 }} className="degradadoLista">
                        {entidades.map((entidad, i) => (
                            <Fragment key={i}>
                                <div
                                    style={{ padding: "0px 10px 5px 0px", height: 35 }}
                                    className="container_spanCentrado"
                                >
                                    <div style={{ flexBasis: "50px", fontSize: "14px" }}>
                                        {formatNumber(compañia.imp > 0 ? entidad.imp / compañia.imp : 0, 0, "percent")}
                                    </div>
                                    <div
                                        style={{
                                            flex: 1,
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                        }}
                                    >
                                        {entidad.denominacion}
                                    </div>
                                    <div style={{ fontWeight: "400" }}>
                                        {formatNumber(entidad.imp, 0, "currency", entidad.moneda)}
                                    </div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container_spanCentrado he-100 d-flex">
                    <div>{"SIN DATOS DE FACTURACIÓN"}</div>
                </div>
            );
        }
    }

    getContent_back(idCompañia) {
        let { datos_Agrupado_Compañias, datos_Agrupado_Entidades, datos_Agrupado_Fechas } = this.state;
        let { lavanderia, user } = this.props;
        let compañia = $.grep(datos_Agrupado_Compañias, function (comp) {
            return comp.idCompañia === idCompañia;
        })[0];

        let entidades = [];
        let datasource_chart = [];

        if (!compañia) {
            compañia = {
                imp: 0,
                perc: 0,
            };
        } else {
            entidades = $.grep(datos_Agrupado_Entidades, function (entidad) {
                return entidad.idCompañia === idCompañia;
            });
            datasource_chart = $.grep(datos_Agrupado_Fechas, function (item) {
                return item.idCompañia === idCompañia;
            });
        }

        let moneda = lavanderia ? lavanderia.moneda : entidades[0] ? entidades[0].moneda : "EUR";
        return (
            <div className="d-flex flex-column he-100">
                <div style={{ height: 200 }} className="w-100 d-flex">
                    <div className="d-flex flex-column">
                        <div
                            className="importe_compañiaSel container_spanCentrado text-center"
                            style={{
                                flex: 1,
                                fontSize: 25,
                                fontWeight: 400,
                                padding: "0px 10px",
                            }}
                        >
                            {formatNumber(compañia.imp, 0, "currency", moneda)}
                        </div>

                        <div style={{ flex: "3" }} className="container_spanCentrado">
                            <CircularGauge ref={this.dxCircularGauge_compa_REF} value={compañia.perc}>
                                <Geometry startAngle={90} endAngle={90} />
                                <RangeContainer width={10} offset={13} backgroundColor="lightgrey" />
                                <Scale startValue={0} endValue={1}>
                                    <Label visible={false} />
                                    <Tick visible={false} />
                                </Scale>
                                <ValueIndicator type="rangebar" color="#EDB637" size={10} offset={0}></ValueIndicator>
                                <Size width={120} height={120} />
                            </CircularGauge>
                            <div style={{ position: "absolute", fontSize: "26px" }}>
                                {formatNumber(compañia.perc, 0, "percent")}
                            </div>
                        </div>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                        <Chart
                            id="dxChart_compañiaSel"
                            ref={this.dxChart_compañiaSel_REF}
                            palette="Soft Blue"
                            dataSource={datasource_chart}
                            height={200}
                            onOptionChanged={this.dxChart_compañiaSel_onOptionChanged}
                        >
                            <CommonSeriesSettings type="spline" argumentField="arg">
                                <Aggregation method="sum" />
                            </CommonSeriesSettings>
                            <ArgumentAxis
                                argumentType="datetime"
                                aggregationInterval={this.getAggregationInterval()}
                                valueMarginsEnabled={true}
                            />
                            <ValueAxis name="imp" />
                            <Legend visible={false} />

                            <Series axis="imp" color="#EDB637" valueField="value">
                                <Point size={7} />
                                <Aggregation enabled={true} />
                            </Series>
                            <TooltipChart
                                enabled={true}
                                location="edge"
                                customizeTooltip={(e) => {
                                    return this.dxChart_customizeTooltip(e, moneda);
                                }}
                            />
                            <LoadingIndicator
                                backgroundColor="none"
                                font={{
                                    size: 14,
                                }}
                            />
                            <Margin top={0} bottom={0} left={5} right={5} />
                        </Chart>
                    </div>
                </div>

                <div style={{ marginTop: 30, flex: 1, overflow: "auto" }}>
                    <DataGrid
                        ref={this.dxDataGrid_compañiaSel_REF}
                        dataSource={entidades}
                        keyExpr="idEntidad"
                        //Propiedades
                        columnsAutoWidth={true}
                        height="100%"
                        hoverStateEnabled={true}
                        showColumnLines={false}
                        noDataText="SIN DATOS DE FACTURACIÓN"
                        //VARIABLES INTERNAS
                        importeCompañia={compañia.imp}
                        onCellHoverChanged={this.dxDataGrid_compañiaSel_onCellHoverChanged}
                        onRowPrepared={this.dxDataGrid_onRowPrepared}
                        onCellClick={this.dxDataGrid_onCellClick}
                    >
                        <Paging enabled={false} />

                        <Column
                            width="15%"
                            alignment="center"
                            cellRender={this.dxDataGrid_circularGauge_cellRender}
                            calculateCellValue={this.dxDataGrid_circularGauge_calculateCellValue}
                        />

                        <Column dataField="denominacion" caption={getTrad("denominacion")} minWidth={200} />

                        <Column
                            dataField="totalKg"
                            caption="Total Kg."
                            width="15%"
                            alignment="center"
                            format={this.decimal_2Digits_format}
                        />

                        <Column
                            dataField="estancias"
                            caption={getTrad("estancias")}
                            width="15%"
                            alignment="center"
                            format={this.decimal_0Digits_format}
                            cellRender={this.dxDataGrid_estancias_cellRender}
                        />

                        <Column
                            dataField="tipoFactCliente"
                            caption={" "}
                            width={50}
                            cellRender={this.dxDataGrid_tipoFactCliente_cellRender}
                        />

                        <Column
                            dataField="costeEstancia"
                            caption={"Coste estancia"}
                            width={130}
                            alignment="center"
                            cellRender={this.dxDataGrid_costeEstancia_cellRender}
                        />

                        <Column
                            dataField="imp"
                            caption="Importe total"
                            width="20%"
                            alignment="center"
                            sortOrder="desc"
                            cellRender={this.dxDataGrid_importeTotal_cellRender}
                        />
                        <Column
                            name="print"
                            caption=" "
                            width={80}
                            alignment="center"
                            cssClass="p-0"
                            cellRender={this.dxDataGrid_print_cellRender}
                        />
                        <Column
                            name="cierre"
                            caption=" "
                            width={80}
                            alignment="center"
                            cssClass="p-0"
                            cellRender={this.dxDataGrid_cierre_cellRender}
                            visible={user.isDepartamentoControl || user.idCargo == 1}
                        />
                        <Summary>
                            <TotalItem
                                summaryType="sum"
                                displayFormat={"{0} Kg."}
                                valueFormat={this.decimal_2minMax_format}
                                column="totalKg"
                            />
                        </Summary>
                    </DataGrid>
                </div>
            </div>
        );
    }

    getAggregationInterval() {
        let { fechaSel } = this.state;

        if (!fechaSel) return "day";
        var numDias = dayDiff(fechaSel.startDate, fechaSel.endDate);
        return numDias < 35 ? "day" : numDias < 180 ? "week" : "month";
    }

    decimal_2minMax_format = {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    decimal_2Digits_format = {
        style: "decimal",
        maximumFractionDigits: 2,
    };

    decimal_0Digits_format = {
        style: "decimal",
        maximumFractionDigits: 0,
    };

    dxDataGrid_compañiaSel_btnPrint_onClick(e, data) {
        let { lavanderia } = this.props;
        let { fechaSel } = this.state;

        const cookies = new Cookies();
        const currentUser = cookies.get("user");
        let refreshToken = currentUser.refreshToken;

        let params = {
            idEntidad: data.idEntidad,
            fechaDesde: formatDate_parameter(fechaSel.startDate),
            fechaHasta: formatDate_parameter(fechaSel.endDate),
            idLavanderia: lavanderia ? lavanderia.idLavanderia : "",
            idToken: refreshToken,
        };

        this.setParams(params); //this.props??
    }

    setStateAsync = (state) => new Promise((resolve) => this.setState(state, resolve));

    dxDataGrid_compañiaSel_btnCierre_onClick = async (grid, e, data) => {
        let _this = this;
        let { datos_Agrupado_Entidades, fechaSel } = this.state;
        let accion = !data.isFacturacionCerrada ? "cerrar" : "abrir";

        await this.setStateAsync({ idEntidad_CierreFacturacion: data.idEntidad });

        this.datasource_Prendas.reload().done((prendas) => {
            const error_UltimaEstancia = !data.ultimaEstancia || data.ultimaEstancia < fechaSel.endDate;

            const error_RepartosSinValidar = data.numRepNoValidados > 0 && data.idTipoConsumoLenceria === 1;

            const numPrecios0 = prendas.reduce((acumulador, prenda) => {
                if (prenda.tipoFact !== 2 && prenda.precio === 0) {
                    acumulador++;
                }
                return acumulador;
            }, 0);

            const errorStyle = {
                color: "white",
                fontSize: "16px",
                fontWeight: "lighter",
                textAlign: "center",
                backgroundColor: "var(--danger)",
                borderRadius: "5px",
                padding: "10px",
                width: "100%",
            };

            const mesagge_CierreFacturacion = (
                <>
                    {data.isFacturacionCerrada ? (
                        <>
                            {error_UltimaEstancia || error_RepartosSinValidar || numPrecios0 > 0 ? (
                                <div className="flex-row mb-3">Se han detectado los siguientes errores:</div>
                            ) : null}

                            {error_UltimaEstancia ? (
                                <div className="container_spanCentrado flex-row mb-3">
                                    <div style={errorStyle}>
                                        {`La fecha de la última estancia no coincide con la fecha fin del rango seleccionado. ${
                                            data.ultimaEstancia
                                                ? `Fecha última estancia: ${formatDate(data.ultimaEstancia)}`
                                                : ""
                                        }`}
                                    </div>
                                </div>
                            ) : null}

                            {error_RepartosSinValidar ? (
                                <div className="container_spanCentrado flex-row mb-3">
                                    <div style={errorStyle}>
                                        {`Existen (${data.numRepNoValidados}) repartos sin validar`}
                                    </div>
                                </div>
                            ) : null}

                            {numPrecios0 > 0 ? (
                                <div className="container_spanCentrado flex-row mb-3">
                                    <div style={errorStyle}>{`Existen (${numPrecios0}) prendas sin precio`}</div>
                                </div>
                            ) : null}
                        </>
                    ) : null}
                    <div className="text-center">
                        {"Se va a " +
                            accion +
                            " la facturación de esta entidad para la fecha seleccionada. ¿Desea continuar?"}
                    </div>
                </>
            );

            const toolbarOptions_CierreFacturacion = {
                text: getTrad("aceptar"),
                type: "danger",
                onClick: () => {
                    if (!data.isFacturacionCerrada) {
                        let objCierre = {
                            idEntidad: data.idEntidad,
                            fechaDesde: formatDate_noTime_parameter(fechaSel.startDate),
                            fechaHasta: formatDate_noTime_parameter(fechaSel.endDate),
                        };

                        _this.datasource_tblCierreFactEntidad
                            .store()
                            .insert(objCierre)
                            .done(function () {
                                datos_Agrupado_Entidades.forEach((x) => {
                                    if (x.idEntidad == data.idEntidad) {
                                        x.isFacturacionCerrada = 1;
                                    }
                                });
                                _this.setState(
                                    {
                                        datos_Agrupado_Entidades,
                                        mesagge_CierreFacturacion: null,
                                        toolbarOptions_CierreFacturacion: null,
                                    },
                                    () => {
                                        grid.component.repaintRows([grid.rowIndex]);
                                        notify({
                                            message: getTrad("aviso_C_RegistroActualizado"),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                        });
                                    }
                                );
                            });
                    } else {
                        _this.setState({ entidadSel: data }, () => {
                            _this.datasource_tblCierreFactEntidad
                                .store()
                                .remove({})
                                .done(function () {
                                    datos_Agrupado_Entidades.forEach((x) => {
                                        if (x.idEntidad == data.idEntidad) {
                                            x.isFacturacionCerrada = 0;
                                        }
                                    });
                                    _this.setState(
                                        {
                                            datos_Agrupado_Entidades,
                                            mesagge_CierreFacturacion: null,
                                            toolbarOptions_CierreFacturacion: null,
                                        },
                                        () => {
                                            grid.component.repaintRows([grid.rowIndex]);
                                            notify({
                                                message: getTrad("aviso_C_RegistroActualizado"),
                                                type: "success",
                                                displayTime: "1500",
                                                closeOnClick: true,
                                            });
                                        }
                                    );
                                });
                        });
                    }
                },
            };

            this.setState({ mesagge_CierreFacturacion, toolbarOptions_CierreFacturacion });
        });
    };

    dxDataGrid_print_cellRender(e) {
        return (
            <div
                className="container_spanCentrado"
                onMouseDown={(args) => {
                    this.dxDataGrid_compañiaSel_btnPrint_onClick(args, e.data);
                }}
            >
                <div className="icon icon_Impresora" style={{ fontSize: 25, cursor: "pointer" }}></div>
            </div>
        );
    }

    dxDataGrid_cierre_cellRender(e) {
        let icono, fontSize, paddingBottom, paddingRight;

        if (e.data.isFacturacionCerrada) {
            icono = "icon_lockOutline";
            fontSize = 23;
            paddingBottom = 1;
            paddingRight = 8;
        } else {
            icono = "icon_unlock";
            fontSize = 21;
            paddingBottom = 3;
            paddingRight = 0;
        }

        return (
            <div
                className="container_spanCentrado "
                style={{ paddingBottom: paddingBottom, paddingRight: paddingRight }}
                onMouseDown={(args) => {
                    this.dxDataGrid_compañiaSel_btnCierre_onClick(e, args, e.data);
                }}
            >
                <div className={"w-100 icon " + icono} style={{ fontSize: fontSize, cursor: "pointer" }}></div>
            </div>
        );
    }

    dxDataGrid_importeTotal_cellRender(e) {
        let valueText = formatNumber(e.data.imp, 2, "currency", e.data.moneda);
        return (
            <div className="position-relative">
                {valueText}
                {(e.data.numRepNoValidados > 0 && e.data.idTipoConsumoLenceria === 1) || e.data.numPrecios0 > 0 ? (
                    <div
                        className="icon_Warning"
                        style={{
                            fontSize: 25,
                            cursor: "pointer",
                            position: "absolute",
                            right: 0,
                            top: "-3px",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    dxDataGrid_costeEstancia_cellRender(e) {
        let valueText = formatNumber(e.data.costeEstancia, 3, "currency", e.data.moneda);
        let text = e.data.idTipoFacturacionCliente === 0 ? valueText : "-";

        return <div>{text}</div>;
    }

    dxDataGrid_tipoFactCliente_cellRender(e) {
        let icon = e.data.tipoFactCliente_icon === "svg_bed" ? "icon_bed" : "icon_Kg";

        return <div className={icon} style={{ fontSize: 25, cursor: "pointer" }}></div>;
    }

    dxDataGrid_estancias_cellRender(e) {
        let { fechaSel } = this.state;
        let valueText = e.data.estancias;

        return (
            <div className="position-relative">
                {valueText}
                {!e.data.ultimaEstancia || (fechaSel.endDate && e.data.ultimaEstancia < fechaSel.endDate) ? (
                    <div
                        className="icon_Warning"
                        style={{
                            fontSize: 25,
                            cursor: "pointer",
                            position: "absolute",
                            right: 0,
                            top: "-3px",
                        }}
                    />
                ) : null}
            </div>
        );
    }

    dxDataGrid_circularGauge_calculateCellValue(rowData) {
        let impCompa = this.dxDataGrid_compañiaSel.option("importeCompañia");

        let perc_entidad = impCompa > 0 ? rowData.imp / impCompa : 0;
        return perc_entidad;
    }

    dxDataGrid_circularGauge_cellRender(e) {
        return (
            <div className="container_spanCentrado">
                <CircularGauge className={"CircularGauge_selector"} value={e.value}>
                    <Geometry startAngle={90} endAngle={90} />
                    <RangeContainer width={3} backgroundColor="lightgrey" offset={3} />
                    <Scale startValue={0} endValue={1}>
                        <Tick visible={false} />
                        <Label visible={false} />
                    </Scale>

                    <ValueIndicator type="rangebar" color="#EDB637" offset={0} size={3} />
                    <Size width={53} height={50} />
                </CircularGauge>
                <div style={{ position: "absolute", fontSize: "14px" }}>{formatNumber(e.value, 0, "percent")}</div>
            </div>
        );
    }

    dxDataGrid_compañiaSel_onCellHoverChanged(e) {
        if (
            e.column &&
            e.row &&
            e.rowType == "data" &&
            (e.column.dataField == "estancias" ||
                e.column.dataField == "tipoFactCliente" ||
                e.column.dataField == "imp" ||
                e.column.name == "print" ||
                e.column.name == "cierre")
        ) {
            if (e.eventType === "mouseover") {
                let _this = this;
                let fechaHasta = this.state ? this.state.fechaSel.endDate : null;

                let error_UltimaEstancia = !e.data.ultimaEstancia || (fechaHasta && e.data.ultimaEstancia < fechaHasta);
                let error_RepartosSinValidar = e.data.numRepNoValidados > 0 && e.data.idTipoConsumoLenceria === 1;
                let error_NumPrecios0 = e.data.numPrecios0 > 0;

                this.dxPopover.option("contentTemplate", function (contentElement) {
                    let container = $("<div/>");

                    if (e.column.dataField == "estancias" && error_UltimaEstancia) {
                        var msg = "La fecha de la última estancia no coincide con la fecha fin del rango seleccionado.";

                        if (e.data.ultimaEstancia) {
                            msg += " Fecha última estancia: " + formatDate(e.data.ultimaEstancia);
                        }
                        container.append("<div>" + msg + "</div>");
                    } else if (e.column.dataField == "tipoFactCliente")
                        container.append("<div>" + "FACTURACIÓN POR " + e.data.tipoFactCliente + "</div>");
                    else if (e.column.dataField == "imp") {
                        if (error_RepartosSinValidar) {
                            var msg = "Existen (" + e.data.numRepNoValidados + ") repartos sin validar";
                            container.append("<div>" + msg + "</div>");
                        }
                        if (error_NumPrecios0) {
                            var msg = "Existen (" + e.data.numPrecios0 + ") prendas sin precio";
                            container.append("<div>" + msg + "</div>");
                        }
                    } else if (e.column.name == "print") {
                        container.append("<div>" + "Imprimir" + "</div>");
                    } else if (e.column.name == "cierre") {
                        let accion = !e.data.isFacturacionCerrada ? "abierta" : "cerrada";
                        container.append("<div>" + "Facturación " + accion + "</div>");
                    }
                    container.appendTo(contentElement);
                });

                this.dxPopover.option("target", e.cellElement);
                if (
                    (error_UltimaEstancia && e.column.dataField == "estancias") ||
                    ((error_NumPrecios0 || error_RepartosSinValidar) && e.column.dataField == "imp") ||
                    e.column.dataField == "tipoFactCliente" ||
                    e.column.name == "print" ||
                    e.column.name == "cierre"
                )
                    this.dxPopover.show();
            } else this.dxPopover.hide();
        }
    }

    dxDataGrid_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");
        }
    }

    dxDataGrid_onCellClick(e) {
        if (e.row) {
            if (e.row.rowType === "data" && e.column.name !== "print" && e.column.name !== "cierre") {
                this.setState({ showSelector: false, idEntidad: e.key });
                this.loadPanel_show();
            }
        }
    }

    hideSelector(params) {
        this.setState({ showSelector: false, ...params }, () => {
            this.loadPanel_show();
        });
    }

    refreshContent_back() {
        this.dxChart_compañiaSel.render();
    }

    dxChart_customizeTooltip(e, moneda) {
        var html = "";
        var title = "";
        var aggregation = e.point.aggregationInfo;

        if (aggregation.aggregationInterval === "day") {
            title = formatDate(e.point.argument);
        } else {
            var start = formatDate(aggregation.intervalStart);

            var dateEnd = new Date(aggregation.intervalEnd.getTime());
            var end = formatDate(new Date(dateEnd.setDate(dateEnd.getDate() - 1)));
            title = start + " - " + end;
        }

        html += "<div class='tooltip-header'>";
        html += title;
        html += "</div>";

        html += "<div class='tooltip-body'>";

        html += "<div class='tooltip-row'>";
        html += "<div class='series-name'>";
        html += "Importe:";
        html += "</div>";
        html += "<div class='value-text'>";
        html += formatNumber(e.point.value, 2, "currency", moneda);
        html += "</div>";
        html += "</div>";

        return {
            html: html,
        };
    }

    dxChart_compañiaSel_onOptionChanged(e) {
        if (e.name === "dataSource") {
            var maxValueMargin = 0;

            if (e.value.length > 0) {
                var minValueMargin = 0;

                var min = Math.min.apply(
                        null,
                        e.value.map(function (a) {
                            return a.value;
                        })
                    ),
                    max = Math.max.apply(
                        null,
                        e.value.map(function (a) {
                            return a.value;
                        })
                    );

                var diff = max - min;
                if (diff > 0) {
                    var perc = diff / max;
                    var percFinal_max = perc < 0.5 ? 1.25 : perc < 0.75 ? 1.1 : 1;
                    var percFinal_min = perc < 0.5 ? 1.5 : perc < 0.75 ? 1.3 : 1;

                    maxValueMargin = (max * percFinal_max - max) / diff;
                    minValueMargin = (min * percFinal_min - min) / diff;
                }

                e.component.option("valueAxis[0].maxValueMargin", maxValueMargin);
                e.component.option("valueAxis[0].minValueMargin", minValueMargin);
            }
        }
    }

    setParams(params) {
        this.props.setParams(params);
    }

    setReport(report) {
        this.props.setReport(report);
    }

    closeReport() {
        this.props.close();
    }

    dataSource_load(fechaSel) {
        this.fechaSel = fechaSel;
        let _this = this;
        this.loadPanel_show();
        $.when(
            this.datasource_infFacturacion_Agrupado_Compañias.reload(),
            this.datasource_infFacturacion_Agrupado_Entidades.reload(),
            this.datasource_infFacturacion_Agrupado_Fechas.reload()
        ).then(function () {
            _this.setState({
                fechaSel: fechaSel,
                datos_Agrupado_Compañias: _this.datasource_infFacturacion_Agrupado_Compañias.items(),
                datos_Agrupado_Entidades: _this.datasource_infFacturacion_Agrupado_Entidades.items(),
                datos_Agrupado_Fechas: _this.datasource_infFacturacion_Agrupado_Fechas.items(),
            });

            if (_this.state.showSelector) {
                setTimeout(() => {
                    _this.loadPanel_hide();
                }, 0);
            }
        });
    }

    getMinSize() {
        var minSize = {
            height: 210,
            width: 440,
        };
        return minSize;
    }

    cargaDatos_lavanderia() {
        let _this = this;
        this.loadPanel_show();
        $.when(this.datasource_infFacturacion_filtroTiempo.reload()).then(function () {
            let items_filtroTiempo = _this.datasource_infFacturacion_filtroTiempo.items();
            let ultimaFecha_dataSource = new Date(items_filtroTiempo[items_filtroTiempo.length - 1].arg);
            let primeraFecha_dataSource = new Date(items_filtroTiempo[0].arg);

            _this.setState({
                minDate: primeraFecha_dataSource,
                maxDate: ultimaFecha_dataSource,
            });

            if (!_this.state.showSelector) {
                setTimeout(() => {
                    _this.loadPanel_hide();
                }, 0);
            }
        });
    }

    CustomizeParameterEditors(report, s, e) {
        if (e.parameter.type === "System.DateTime") {
            //DATE
            e.info.editor = $.extend({}, e.info.editor);
            e.info.editor.extendedOptions = $.extend(
                e.info.editor.extendedOptions || {},
                { type: "date" },
                { displayFormat: "dd/MM/yyyy" }
            );
        }
        if (
            e.parameter.name === "idLavanderia" ||
            e.parameter.name === "idCompania" ||
            e.parameter.name === "idsRepartos" ||
            e.parameter.name === "idsRepartosOffice" ||
            e.parameter.name === "idsProducciones" ||
            e.parameter.name === "idsAbonos" ||
            e.parameter.name === "idToken" ||
            e.parameter.name === "idEntidad" ||
            e.parameter.name === "fechaDesde" ||
            e.parameter.name === "fechaHasta" ||
            e.parameter.name === "refacturar"
        ) {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
        //if (e.parameter.name === 'idEntidad' || e.parameter.name === 'fechaDesde' || e.parameter.name === 'fechaHasta') { //DESHABILITADOS
        //    e.info.editor = $.extend({}, e.info.editor);
        //    e.info.disabled = true
        //}
        if (
            e.parameter.name === "idFamilia" ||
            e.parameter.name === "idTipoFacturacion" ||
            e.parameter.name === "isExtra" ||
            e.parameter.name === "tipoInforme"
        ) {
            //PLACEHOLDER

            e.info.editor = $.extend({}, e.info.editor);

            e.info.displayName =
                e.parameter.name === "idFamilia"
                    ? getTrad("familia")
                    : e.parameter.name === "idTipoFacturacion"
                    ? getTrad("tipoFacturacion")
                    : e.parameter.name === "isExtra"
                    ? getTrad("tipoPrenda")
                    : e.parameter.name === "tipoInforme"
                    ? getTrad("agrupacion")
                    : [];

            var dataSource =
                e.parameter.name === "idFamilia"
                    ? this.datasource_infFacturacion_Parametro_Familia.items()
                    : e.parameter.name === "idTipoFacturacion"
                    ? this.datasource_infFacturacion_Parametro_TipoFacturacion.items()
                    : e.parameter.name === "isExtra"
                    ? this.enum_infFacturacion_Parametro_TipoPrenda
                    : e.parameter.name === "tipoInforme"
                    ? this.enum_infFacturacion_Parametro_TipoInforme
                    : [];

            var showClearButton = e.parameter.name !== "tipoInforme" ? true : false;

            var dataSourceString = dataSource
                .map(function (item) {
                    return JSON.stringify(item).replace(/"/g, "'");
                })
                .toString();

            var editorTemplate =
                '<div data-bind="dxSelectBox: { dataSource: [' +
                dataSourceString +
                "] , valueExpr: 'value', value: value, displayExpr: 'text', placeholder: 'TODAS', showClearButton: " +
                showClearButton +
                ',  dropDownOptions: { container: $root.getPopupContainer($element) } }">    </div>';

            var script = document.createElement("script");
            script.type = "text/hhtml";
            script.id = "Custom-editor-" + report + "-" + e.parameter.name;
            script.innerHTML = editorTemplate;

            document.head.append(script);
            e.info.editor = { header: script.id };
        }
    }

    componentDidMount() {
        let _this = this;
        this.cargaDatos_lavanderia();
        $.when(
            this.datasource_infFacturacion_Parametro_Familia.load(),
            this.datasource_infFacturacion_Parametro_TipoFacturacion.load()
        ).done(function () {
            _this.setReport({
                denominacion: "infFacturacion_prendas",
                parameterEvent: _this.CustomizeParameterEditors,
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let { fechaSel, showSelector } = this.state;

        let { lavanderia, user, idioma } = this.props;

        if (
            (lavanderia && lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
            this.state.showSelector !== prevState.showSelector ||
            idioma.idIdioma !== prevProps.idioma.idIdioma ||
            fechaSel !== prevState.fechaSel
        ) {
            if (lavanderia && lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) {
                this.setState({
                    showSelector: true,
                    // fechaSel: null,
                    minDate: null,
                    maxDate: null,
                });
                this.cargaDatos_lavanderia();
            }
            if (!showSelector) cargaJs(this, lavanderia, user, idioma);
            this.props.close();
        }
    }

    componentWillUnmount() {
        this.closeReport();
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    setReport: (report) => dispatch(impresionActions.setReport(report)),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    close: () => dispatch(impresionActions.close()),
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfFacturacionCliente);
