export const sharedLookupOptions = {
  searchTimeout: 0,
  showCancelButton: false,
  dropDownOptions: {
    closeOnOutsideClick: true,
    showTitle: false,
    width: 400,
  },
};

export const sharedFormatCurrency = (codigoMoneda) => ({
  style: "currency",
  maximumFractionDigits: 2,
  currency: codigoMoneda ?? "EUR",
});
