import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";

class Produccion extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div id="Produccion" className={"formContainer"}>
            <div id="dxContainer">
              <div
                data-options="dxItem: {ratio: 5}"
                style={{ paddingRight: "30px", height: "100%" }}
              >
                <div id="dxBox_PrendasProd_agrupadas">
                  <div data-options="dxItem: {ratio: 1}">
                    <div id="dxBox_dxDataGrid_PrendasProd_agrupadas">
                      <div data-options="dxItem: {baseSize: 45, shrink: 0, ratio: 0}">
                        <div id="dxToolbar_PrendasProd_agrupadas"></div>
                      </div>
                      <div data-options="dxItem: {ratio: 1}">
                        <div id="dxDataGrid_PrendasProd_agrupadas"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-options="dxItem: {ratio: 4}">
                <div
                  id="dxDataGrid_PrendasProd_separadas"
                  style={{ paddingTop: "45px" }}
                ></div>
              </div>
            </div>

            <div id="dxPopup_tiposRechazo"></div>
            <div id="dxPopup_PrendasProd_añadir"></div>
            <div id="dxTooltip_Produccion"></div>
          </div>
        </div>
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;
    cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Produccion);
