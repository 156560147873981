import React from "react";
import { connect } from "react-redux";
import { connectionConstants } from "constants";

//Layout
import { authHeader, errorHandler, getTrad } from "../../helpers";
import Box, { Item as ItemBox } from "devextreme-react/box";

//DevExtreme

import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import ODataContext from "devextreme/data/odata/context";
import { Button, Popup, ScrollView } from "devextreme-react";
import notify from "devextreme/ui/notify";

//Components
import PreguntaRango from "./PreguntaRango/index.js";
import PreguntaOpciones from "./PreguntaOpciones/index.js";
import PreguntaCaritas from "./PreguntaCaritas/index.js";
import PreguntaDescripcion from "./PreguntaDescripcion/index.js";

import "./css.scss";
import AvisoEncuesta from "./AvisoEncuesta";

class Encuestas extends React.Component {
  constructor(props) {
    super(props);
    //Refs

    this.state = {
      showPopup: false,
      showEncuesta: false,
      preguntas: null,
      respuestasUsuario: [],
      datosEncuesta: null,
      saveAvailable: true,
      required: false,
    };
  }

  get setWidth_popup() {
    const { resolucion } = this.props;

    switch (resolucion) {
      case "xs":
      case "sm":
        return "95%";
      case "md":
        return "80%";
      default:
        return "60%";
    }
  }
  //#region DataSource

  dataSource_checkEncuestaActiva = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/checkEncuestaActiva_v2",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_checkEncuestaActiva_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_checkEncuestaActiva_beforeSend(request) {
    request.headers = { ...authHeader() }; // Si no hay idEncuesta devuelve []
  }

  context_encuestas = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "Encuestas/",
    entities: {
      setEncuesta: {},
    },
    beforeSend: (request) => {
      this.context_encuestas_beforeSend(request);
    },
  });

  context_encuestas_beforeSend = (request) => {
    const { datosEncuesta } = this.state;
    const idEncuesta = datosEncuesta?.idEncuesta;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuesta;
    request.payload = this.context_encuestas_getPayLoad();
  };

  context_encuestas_getPayLoad = () => {
    const { respuestasUsuario } = this.state;
    return respuestasUsuario;
  };

  //#endregion

  onValueChange = (newState) => {
    this.setState(newState);
  };

  enviarEncuesta = () => {
    const { preguntas, respuestasUsuario, saveAvailable } = this.state;
    if (!saveAvailable) return;
    this.setState({ saveAvailable: false });

    const grupoPreguntaCompletas = preguntas
      .filter((x) => x.idGrupoPregunta != null)
      .every((x) =>
        x.preguntas.every(
          (x) =>
            x.isOpcional ||
            respuestasUsuario.some((y) => x.idPregunta == y.idPregunta),
        ),
      );

    const preguntasSinGrupoCompletas = preguntas
      .filter((x) => x.idGrupoPregunta == null)
      .every(
        (x) =>
          x.isOpcional ||
          respuestasUsuario.some((y) => x.idPregunta == y.idPregunta),
      );

    const encuestaCompleta =
      grupoPreguntaCompletas && preguntasSinGrupoCompletas;

    if (encuestaCompleta) {
      this.context_encuestas.invoke("setEncuesta", {}, "POST").done(() => {
        this.cerrarPopup();
        this.setState({ saveAvailable: true });
        notify({
          message: getTrad("encuestaEnviada"),
          type: "success",
          displayTime: 1500,
          closeOnClick: true,
        });
      });
    } else {
      this.setState({ saveAvailable: true, required: true });
      notify({
        message: getTrad("alerta_rellenarTodosCampos"),
        type: "error",
        displayTime: 2500,
        closeOnClick: true,
      });
    }
  };

  cerrarPopup = () => {
    this.setState({ showPopup: false });
  };

  dx_popup = () => {
    return this.popUpREF.current.instance;
  };

  componentDidMount() {
    this.dataSource_checkEncuestaActiva.load().done((datosEncuesta) => {
      let tblPregunta = datosEncuesta[0].tblPregunta;
      const tblPreguntaNOpcionNPregunta =
        datosEncuesta[0].tblPreguntaNOpcionNPregunta;

      tblPregunta = tblPregunta
        ?.filter(
          (x) =>
            !tblPreguntaNOpcionNPregunta.some(
              (y) => y.idPreguntaAnidada === x.idPregunta,
            ),
        )
        .sort((a, b) => a.orden - b.orden);
      const preguntas = tblPregunta;
      this.setState({ preguntas, datosEncuesta: datosEncuesta[0] });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { datosEncuesta, showPopup } = this.state;
    let newState = {};

    if (
      prevState.datosEncuesta == null &&
      datosEncuesta &&
      showPopup == false
    ) {
      newState.showPopup = true;
    }

    if (Object.keys(newState).length > 0) this.setState(newState);
  }

  render() {
    const { preguntas, showPopup, showEncuesta, datosEncuesta } = this.state;
    const isObligatorio = datosEncuesta?.isObligatorio;

    return (
      <Popup
        visible={showPopup}
        onHiding={this.cerrarPopup}
        showCloseButton={!isObligatorio}
        title={getTrad("encuestaSatisfaccion")}
        width={this.setWidth_popup}
        maxWidth={"700px"}
        height={"90%"}
        className="no-padding"
        enableBodyScroll={true}
      >
        <ScrollView>
          <div>
            {showEncuesta ? (
              <>
                <Box
                  direction="row"
                  width="100%"
                  height="100%"
                  className="d-flex justify-content-center"
                >
                  <ItemBox ratio={1}>
                    {preguntas?.map((pregunta, index) => {
                      return (
                        <div key={index + "_pregunta"}>
                          {pregunta.idGrupoPregunta && (
                            <>
                              <Box width="100%">
                                <ItemBox ratio={1}>
                                  <div
                                    key={index}
                                    className="font-size-lg mt-3 p-3 bg-light rounded d-flex justify-content-center text-gray   "
                                  >
                                    {pregunta.denominacion}
                                  </div>
                                </ItemBox>
                              </Box>
                              {pregunta?.preguntas?.map((pregunta, index) => {
                                return this.render_preguntas(
                                  pregunta,
                                  index + "_preguntaGrupo",
                                );
                              })}
                            </>
                          )}
                          {!pregunta.idGrupoPregunta &&
                            this.render_preguntas(
                              pregunta,
                              index + "_preguntaSinGrupo",
                            )}
                        </div>
                      );
                    })}
                  </ItemBox>
                </Box>
                <Box>
                  <ItemBox ratio={1}>
                    <div className="contentPosition_end p-4">
                      <Button
                        onClick={this.enviarEncuesta}
                        text={getTrad("aceptar")}
                        type="success"
                        className="m-2 p-1"
                      />
                      {isObligatorio ? (
                        <></>
                      ) : (
                        <Button
                          onClick={this.cerrarPopup}
                          text={getTrad("posponer")}
                          type="normal"
                          className="m-2 p-1"
                        />
                      )}
                    </div>
                  </ItemBox>
                </Box>
              </>
            ) : (
              <AvisoEncuesta
                onValueChange={this.onValueChange}
                isObligatorio={isObligatorio}
              />
            )}
          </div>
        </ScrollView>
      </Popup>
    );
  }

  render_preguntas = (pregunta, index) => {
    const { preguntas, respuestasUsuario, datosEncuesta, required } =
      this.state;

    const isRespondida = respuestasUsuario.some(
      (x) => x.idPregunta == pregunta.idPregunta,
    );
    const isRequired = required && !pregunta.isOpcional && !isRespondida;
    const required_className = isRequired
      ? "border border-danger"
      : "border border-white";

    return (
      <div key={index} className="px-3">
        <Box width="100%">
          <ItemBox ratio={1}>
            <div
              className="groupTitle font-size-xl p-4 "
              style={isRequired ? { color: "var(--danger)" } : {}}
            >
              {pregunta.pregunta}
            </div>
          </ItemBox>
        </Box>
        <Box width="100%" className="p-3">
          <ItemBox ratio={1}>
            <div
              className={
                " container_formRespuestaEncuesta shadow  " + required_className
              }
            >
              {pregunta.tipoPregunta == "opcion" &&
                pregunta.opciones.map((opcion) => {
                  return (
                    <PreguntaOpciones
                      key={opcion.idOpcion}
                      idPregunta={pregunta.idPregunta}
                      dataSource={opcion}
                      respuestas={respuestasUsuario}
                      onValueChange={this.onValueChange}
                      datosEncuesta={datosEncuesta}
                      preguntas={preguntas}
                    />
                  );
                })}
              {pregunta.tipoPregunta == "rango" && (
                <PreguntaRango
                  dataSource={pregunta}
                  respuestas={respuestasUsuario}
                  onValueChange={this.onValueChange}
                />
              )}
              {pregunta.tipoPregunta == "texto" && (
                <PreguntaDescripcion
                  dataSource={pregunta}
                  respuestas={respuestasUsuario}
                  onValueChange={this.onValueChange}
                />
              )}
              {pregunta.tipoPregunta == "satisfaccion" && (
                <PreguntaCaritas
                  dataSource={pregunta}
                  respuestas={respuestasUsuario}
                  onValueChange={this.onValueChange}
                />
              )}
            </div>
          </ItemBox>
        </Box>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
});

export default connect(mapStateToProps)(Encuestas);
