import React, { useEffect, useState } from "react";

import DxPopup from "devextreme-react/popup";
import Form, {
  ButtonItem,
  EmptyItem,
  Item,
  Label,
  RequiredRule,
} from "devextreme-react/form";

import { getTrad } from "helpers";
import { useRecambiosProvider } from "pages/AssistantNew/Recambios/components/RecambiosProvider";
import notify from "devextreme/ui/notify";

const Popup = ({ visible, onHiding }) => {
  const { recambioSel, setRecambioSel, tblProveedor, tblPais } =
    useRecambiosProvider();

  const initialState = {
    idRecambioNProveedor: 0,
    idProveedor: null,
    idProveedorNavigation: null,
    idRecambio: recambioSel?.idRecambio ?? 0,
    idPais: null,
    idPaisNavigation: null,
    orden: recambioSel?.tblRecambioNProveedor?.length + 1,
    ultimoPrecio: null,
    referencia: "",
    codigoFabricante: "",
    fabricante: "",
    codigoBarras: "",
    codigoBarrasFabricante: "",
    observaciones: "",
  };

  const [isFormVisible, setIsFormVisible] = useState(visible);
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    if (visible) {
      setIsFormVisible(true);
      setFormData(initialState);
    }
  }, [visible]);

  const editorOptions = {
    idProveedor: {
      dataSource: tblProveedor,
      valueExpr: "idProveedor",
      displayExpr: "nombreComercial",
      searchTimeout: 0,
      showCancelButton: false,
      dropDownOptions: {
        closeOnOutsideClick: true,
        showTitle: false,
        width: 400,
      },
    },
    idPais: {
      dataSource: tblPais,
      valueExpr: "idPais",
      displayExpr: "denominacion",
      searchTimeout: 0,
      showCancelButton: false,
      dropDownOptions: {
        closeOnOutsideClick: true,
        showTitle: false,
        width: 400,
      },
    },
    ultimoPrecio: {
      placeholder: "0,00",
    },
    activo: {
      switchedOffText: getTrad("noActivo"),
      switchedOnText: getTrad("activo"),
    },
    buttonOptions: {
      text: getTrad("guardar"),
      type: "success",
      useSubmitBehavior: true,
    },
  };

  const onHidden = () => setIsFormVisible(false);

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const camposARellenar = [
      "referencia",
      "codigoFabricante",
      "fabricante",
      "codigoBarras",
      "codigoBarrasFabricante",
      "observaciones",
    ];

    if (camposARellenar.some((campo) => formData[campo].length > 0)) {
      formData.ultimoPrecio = formData.ultimoPrecio ?? 0;
      formData.idProveedorNavigation = tblProveedor.find(
        (p) => p.idProveedor === formData.idProveedor
      );
      formData.idPaisNavigation = tblPais.find(
        (p) => p.idPais === formData.idPais
      );

      setRecambioSel((prev) => ({
        ...prev,
        tblRecambioNProveedor: [...prev.tblRecambioNProveedor, formData],
      }));
      onHiding();
    } else {
      notify({
        message: getTrad("alerta_RellenaAlMenosUnCampo"),
        type: "error",
        displayTime: 1500,
        closeOnClick: true,
      });
    }
  };

  return (
    <DxPopup
      visible={visible}
      title={getTrad("añadirProveedor")}
      height={"55%"}
      width={"25%"}
      onHiding={onHiding}
      onHidden={onHidden}
    >
      <div>
        {isFormVisible ? (
          <form onSubmit={onSubmit}>
            <Form
              formData={formData}
              labelLocation={"top"}
              colCount={2}
              showColonAfterLabel
            >
              <Item
                dataField={"idProveedor"}
                editorType={"dxLookup"}
                colSpan={2}
                editorOptions={editorOptions.idProveedor}
              >
                <Label text={getTrad("proveedor")} />
                <RequiredRule />
              </Item>
              <Item
                dataField={"idPais"}
                editorType={"dxLookup"}
                editorOptions={editorOptions.idPais}
              >
                <Label text={getTrad("pais")} />
                <RequiredRule />
              </Item>

              <Item
                dataField={"ultimoPrecio"}
                editorType={"dxNumberBox"}
                editorOptions={editorOptions.ultimoPrecio}
              >
                <Label text={getTrad("precioCompra")} />
              </Item>
              <Item dataField={"referencia"} editorType={"dxTextBox"}>
                <Label text={getTrad("refFabricante")} />
              </Item>
              <Item dataField={"codigoFabricante"} editorType={"dxTextBox"}>
                <Label text={getTrad("codigoFabricante")} />
              </Item>
              <Item dataField={"fabricante"} editorType={"dxTextBox"}>
                <Label text={getTrad("fabricante")} />
              </Item>
              <Item dataField={"codigoBarras"} editorType={"dxTextBox"}>
                <Label text={getTrad("codigoBarras")} />
              </Item>
              <Item
                dataField={"codigoBarrasFabricante"}
                editorType={"dxTextBox"}
              >
                <Label text={getTrad("codigoBarrasFabricante")} />
              </Item>
              <Item
                dataField={"observaciones"}
                editorType={"dxTextArea"}
                colSpan={2}
              >
                <Label text={getTrad("observaciones")} />
              </Item>
              <EmptyItem />
              <ButtonItem
                horizontalAlignment={"right"}
                verticalAlignment={"bottom"}
                buttonOptions={editorOptions.buttonOptions}
              />
            </Form>
          </form>
        ) : null}
      </div>
    </DxPopup>
  );
};

export default Popup;
