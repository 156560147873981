import React from "react";
import { datasource_tblAdmTipoElemento } from "../model";

const TipoElemento = (id, size = "") => {
    const bgColor = id === 1 ? "default" : id === 2 ? "warning" : "success";
    const text = datasource_tblAdmTipoElemento.items().find((e) => e.idAdmTipoElemento === id)?.denominacion;
    return <div className={"chip " + bgColor + size} >{text}</div>;
};

export default TipoElemento;
