import React from "react";

import Tooltip from "devextreme-react/tooltip";

import idsEstadoHistoricoAsientoNomina from "constants/enums/RRHH/tblEstadoHistoricoAsientoNomina";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import { getTrad } from "helpers";
class LastCell extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data, value: diff } = this.props.data;

    const error_diff = diff !== 0;
    const error_sinValidar =
      data.idEstadoNomina < idsEstadoNomina.validadoGestoria;

    const labels = {
      [idsEstadoHistoricoAsientoNomina.pagado]: getTrad("pagado"),
      [idsEstadoHistoricoAsientoNomina.anticipo]: getTrad("anticipo"),
      [idsEstadoHistoricoAsientoNomina.contabilizado]: getTrad("contabilizado"),
    };

    return (
      <div className={"container_spanCentrado"}>
        {data.isRetenida ? (
          <div className={`chipContainer dx-state-disabled`}>
            <div className={`chip danger`}>{getTrad("retenida")}</div>
          </div>
        ) : error_sinValidar || error_diff ? (
          <>
            <i
              id={`w-${data.idNomina}`}
              className={`icon_Warning ${
                error_sinValidar ? "warning" : "danger"
              }`}
            />
            <Tooltip
              target={`#w-${data.idNomina}`}
              showEvent="dxhoverstart"
              hideEvent="dxhoverend"
            >
              {error_sinValidar ? (
                <p className={"m-0"}>{getTrad("nomina_sinValidarRRHH")}</p>
              ) : error_diff ? (
                <p className={"m-0"}>
                  {getTrad("incidenciaNominaContactarTI")} {getTrad("total")}:{" "}
                  {diff}
                </p>
              ) : null}
            </Tooltip>
          </>
        ) : data.isPendientePagar || data.isPendienteAnticipo ? (
          <div className={`chipContainer`}>
            <div className={`chip secondary`}>
              {data.isPendientePagar ? getTrad("pagar") : getTrad("anticipar")}
            </div>
          </div>
        ) : [
            idsEstadoHistoricoAsientoNomina.pagado,
            idsEstadoHistoricoAsientoNomina.anticipo,
            idsEstadoHistoricoAsientoNomina.contabilizado,
          ].includes(data.idEstadoHistoricoAsientoNomina) ? (
          <div className={"text-success font-weight-bold"}>
            {labels[data.idEstadoHistoricoAsientoNomina]}
          </div>
        ) : null}
      </div>
    );
  }
}

export default LastCell;
