import React, { useEffect, useRef, useState } from "react";

import DataGrid, { Column, GroupItem, Paging, Sorting, Summary } from "devextreme-react/data-grid";

import PopupComentarioCambioEstadoNomina from "components/RRHH/PopupComentarioCambioEstadoNomina";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import { getTrad } from "helpers";

const format = { style: "currency", maximumFractionDigits: 2, currency: "EUR" };

const DataGridConceptos = ({ nominaSel, conflictoNominaContrato, onClick_aceptarNominaGestoria, onChange_comparacionNominaGestoria }) => {
  const dataGridRef = useRef(null);

  const [hayConflictos, setHayConflictos] = useState(false);
  const [isVisible_popupComentarioCambioEstadoNomina, setIsVisible_popupComentarioCambioEstadoNomina] = useState(false);

  const repaintDataGrid = () => dataGridRef?.current?.instance?.repaint();

  useEffect(() => {
    if (nominaSel.idEstadoNomina === idsEstadoNomina.solicitudCambioRRHH || nominaSel.idEstadoNomina >= idsEstadoNomina.solicitudCambioGestoria) {
      setHayConflictos(!conflictoNominaContrato && nominaSel.conceptosNominaNNomina.some(cng => cng.importe !== cng.importeA3));
    } else if (hayConflictos) {
      setHayConflictos(false);
    }
    repaintDataGrid();
  }, [nominaSel]);

  const onToolbarPreparing = ({ toolbarOptions }) => {
    if (hayConflictos) {
      if (nominaSel.idEstadoNomina !== idsEstadoNomina.solicitudCambioGestoria) {
        toolbarOptions.items.unshift(
          {
            location: "after",
            widget: "dxButton",
            options: {
              icon: "check",
              text: getTrad("aceptarDatosGestoria"),
              onClick: onClick_aceptarNominaGestoria,
            },
          }
        );
      }

      toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          options: {
            text: nominaSel.idEstadoNomina === idsEstadoNomina.solicitudCambioGestoria ? getTrad("editarSolicitudCambio") : getTrad("solicitarCambio"),
            onClick: () => setIsVisible_popupComentarioCambioEstadoNomina(true),
          },
        }
      );
    }
  };

  const onCellPrepared = e => {
    if (e.rowType === "data" && e.columnIndex === 0) {
      e.cellElement.addClass("font-weight-bold");
    }

    if (e.rowType === "data" && !conflictoNominaContrato && (nominaSel?.idEstadoNomina === idsEstadoNomina.solicitudCambioRRHH || nominaSel?.idEstadoNomina >= idsEstadoNomina.solicitudCambioGestoria) && e.data.importe !== e.data.importeA3) {
      e.cellElement.css("color", "var(--danger)");
    }
  };

  const groupCellRender_isDevengo = e => (<div>{e.value ? getTrad("devengos") : getTrad("deducciones")}</div>);

  const onConfirm_solicitarCambio = () => onChange_comparacionNominaGestoria({ ...nominaSel, idEstadoNomina: idsEstadoNomina.solicitudCambioGestoria });

  const dataSource = nominaSel.conceptosNominaNNomina.map(cnnn => {
    return {
      ...cnnn,
      tipo: cnnn.isConceptoVariable ? getTrad("variable") : getTrad("fijo"),
    }
  });

  const isNominaEnviada = nominaSel?.idEstadoNomina === idsEstadoNomina.solicitudCambioRRHH || nominaSel?.idEstadoNomina >= idsEstadoNomina.solicitudCambioGestoria;

  return (
    <>
      <DataGrid
        ref={dataGridRef}
        dataSource={dataSource}
        height={640}
        width={"100%"}
        showRowLines
        columnAutoWidth
        hoverStateEnabled
        rowAlternationEnabled
        showColumnLines={false}
        onToolbarPreparing={onToolbarPreparing}
        onCellPrepared={onCellPrepared}
      >
        <Paging enabled={false} />
        <Sorting mode={"multiple"} />
        <Column
          dataField={"isDevengo"}
          groupIndex={0}
          sortOrder={"desc"}
          groupCellRender={groupCellRender_isDevengo}
        />
        <Column
          dataField={"denominacion"}
          caption={getTrad("concepto")}
          sortIndex={0}
          sortOrder={"asc"}
          cssClass={"color-secondary"}
        />
        <Column
          dataField={"tipo"}
          alignment={"right"}
          caption={getTrad("tipo")}
          cssClass={"color-secondary"}
          visible={isNominaEnviada}
        />
        <Column
          dataField={"importe"}
          caption={getTrad("polarier")}
          width={125}
          alignment={"right"}
          cssClass={"color-secondary"}
          format={format}
          visible={!conflictoNominaContrato || nominaSel.isContratoAsociado}
        />
        <Column
          dataField={"importeA3"}
          caption={getTrad("gestoria")}
          width={125}
          alignment={"right"}
          cssClass={"color-secondary"}
          format={format}
          visible={(!conflictoNominaContrato || !nominaSel.isContratoAsociado) && isNominaEnviada}
        />
        <Column
          name={"diferencia"}
          caption={getTrad("diferencia")}
          width={125}
          alignment={"right"}
          cssClass={"color-secondary"}
          format={format}
          calculateCellValue={row => row.importe - row.importeA3}
          visible={!conflictoNominaContrato && isNominaEnviada}
        />
        <Summary>
          <GroupItem
            column={"denominacion"}
            alignByColumn
            showInGroupFooter
            displayFormat={getTrad("total").toUpperCase()}
          />
          <GroupItem
            name={"importe"}
            column={"importe"}
            summaryType={"sum"}
            displayFormat={"{0}"}
            alignByColumn
            showInGroupFooter
            cssClass={"font-size-md color-white bg-primary p-1 rounded text-center"}
            valueFormat={format}
          />
          <GroupItem
            name={"importeA3"}
            column={"importeA3"}
            summaryType={"sum"}
            displayFormat={"{0}"}
            alignByColumn
            showInGroupFooter
            cssClass={"font-size-md color-white bg-blue p-1 rounded text-center"}
            valueFormat={format}
          />
          <GroupItem
            name={"diferencia"}
            column={"diferencia"}
            summaryType={"sum"}
            displayFormat={"{0}"}
            alignByColumn
            showInGroupFooter
            cssClass={"font-size-md"}
            valueFormat={format}
          />
        </Summary>
      </DataGrid>
      <PopupComentarioCambioEstadoNomina
        title={getTrad("solicitarCambio")}
        visible={isVisible_popupComentarioCambioEstadoNomina}
        idNomina={nominaSel.idNomina}
        idEstadoNomina={idsEstadoNomina.solicitudCambioGestoria}
        onConfirm={onConfirm_solicitarCambio}
        onHiding={() => setIsVisible_popupComentarioCambioEstadoNomina(false)}
      />
    </>
  );
};

export default DataGridConceptos;
