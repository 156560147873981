import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { SVContext } from "../../components/SmartViewContext";
import { getColor_porcentaje, getMaquinaSVG } from "./helpers";
import Posicion from "./Posicion";
import { Box } from "devextreme-react";
import { Item as ItemBox } from "devextreme-react/box";

import "./Components.scss";
import LottieIcon from "components/LottieIcon";
import RadialBar from "components/RadialBar/RadialBar";
import Persona from "./Persona";

class Maquina extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <SVContext.Consumer>
                {({ tblPosicion, tblPersonas, datosMaquinas, setTooltipData, setMaquinaSel, layoutPages }) => {
                    const { id, idEstadoSmartHub } = this.props;
                    const data = tblPosicion.filter((x) => x.maquina?.idMaquina === id);

                    const datos = datosMaquinas.find((x) => x.idMaquina === id);

                    const maquina = data[0].maquina;
                    const isCalandra = maquina.idTipoMaquina === 1;
                    const isPlegadora = maquina.idTipoMaquina === 34;
                    const isApagada = idEstadoSmartHub === 2;
                    const isAlertaActiva = idEstadoSmartHub === 3;

                    const dataProduccion = data.filter((x) => x.idAreaLavanderia === 3);
                    const dataRecogida = data.filter((x) => x.idAreaLavanderia === 4);

                    let personas = tblPersonas.filter(
                        (y) =>
                            dataRecogida.filter((x) => x.idPosicion === y.idPosicionNAreaLavanderiaNLavanderia).length >
                            0
                    );

                    const collapseRecogida =
                        isPlegadora &&
                        layoutPages.flat(1).filter((x) => `layout-key-${id}` === x.i && x.w < 15).length > 0 &&
                        personas.length > 2;

                    return (
                        <Box
                            direction={isCalandra ? "row" : "col"}
                            className={`MaquinaBox he-100 justify-content-center align-content-center align-items-center ${
                                isCalandra ? "ml-3" : ""
                            }`}
                        >
                            {dataProduccion.length > 0 && (
                                <ItemBox baseSize={"auto"}>
                                    <div className="container_spanCentrado">
                                        <Row
                                            className={`he-100 justify-content-center flex-nowrap ${
                                                isCalandra ? "flex-column" : "flex-row"
                                            }`}
                                        >
                                            {(maquina.posicionVia1Izquierda
                                                ? dataProduccion.reverse()
                                                : dataProduccion
                                            ).map((x, i) => (
                                                <Posicion
                                                    key={`idPos-${x.idPosicion}-${i}`}
                                                    data={x}
                                                    isMaquina={true}
                                                />
                                            ))}
                                        </Row>
                                    </div>
                                </ItemBox>
                            )}

                            <ItemBox ratio={1}>
                                <div
                                    className="he-100 d-flex align-content-center align-items-center justify-content-center"
                                    onMouseEnter={(e) =>
                                        !isApagada
                                            ? setTooltipData(e.target, <this.tooltipContent id={id} />)
                                            : undefined
                                    }
                                    onMouseLeave={(e) => (!isApagada ? setTooltipData() : undefined)}
                                    onClick={() => setMaquinaSel(id)}
                                >
                                    {isAlertaActiva && (
                                        <div
                                            style={{
                                                position: "absolute",
                                                marginTop: isCalandra ? -25 : -25,
                                            }}
                                        >
                                            <LottieIcon icon={"warning"} />
                                        </div>
                                    )}
                                    <img
                                        src={getMaquinaSVG(maquina, datos?.ptgActual, idEstadoSmartHub)}
                                        className={`w-100 px-4 py-2`}
                                        style={{ maxHeight: "100%" }}
                                    />
                                </div>
                            </ItemBox>

                            {dataRecogida.length > 0 && (
                                <ItemBox baseSize={"auto"}>
                                    <div
                                        className={`container_spanCentrado w-100 ${
                                            isPlegadora ? "position-relative" : ""
                                        }`}
                                    >
                                        <div
                                            style={{ bottom: 0, height: "fit-content" }}
                                            className={`${
                                                isPlegadora ? "position-absolute" : ""
                                            } he-100 w-100 d-flex flex-items-center ${
                                                collapseRecogida ? "" : "justify-content-center"
                                            } flex-nowrap overflow-hidden ${
                                                isCalandra ? "flex-column" : "flex-row"
                                            } contenedorDinamicoPosiciones`}
                                        >
                                            {personas.length === 0 ? (
                                                <Posicion data={{}} isMaquina={true} />
                                            ) : collapseRecogida ? (
                                                [
                                                    personas.slice(0, 2).map((x, i) => {
                                                        return (
                                                            <div className="stack-item">
                                                                <Persona key={`idPers-${x.idPersona}-${i}`} data={x} />
                                                            </div>
                                                        );
                                                    }),
                                                    <div className="stack-item posicion p-2 w-100 he-100">
                                                        <div
                                                            className="sin-foto text-secondary bg-white d-flex align-items-center"
                                                            onMouseEnter={(e) =>
                                                                setTooltipData(
                                                                    e.target,
                                                                    <div
                                                                        style={{ maxWidth: 300 }}
                                                                        className="d-flex flex-row align-items-center justify-content-center"
                                                                    >
                                                                        {personas.slice(2).map((x, i) => (
                                                                            <Persona
                                                                                key={`idPers-${x.idPersona}-${i}`}
                                                                                data={x}
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )
                                                            }
                                                            onMouseLeave={() => setTooltipData()}
                                                        >
                                                            <span>+{personas.length - 2}</span>
                                                        </div>
                                                    </div>,
                                                ]
                                            ) : (
                                                personas.map((x, i) => {
                                                    return <Persona key={`idPers-${x.idPersona}-${i}`} data={x} />;
                                                })
                                            )}
                                        </div>
                                    </div>
                                </ItemBox>
                            )}
                        </Box>
                    );
                }}
            </SVContext.Consumer>
        );
    }

    timeFormat = { hour: "2-digit", minute: "2-digit" };

    tooltipContent = ({ id }) => {
        return (
            <SVContext.Consumer>
                {({ tblPosicion, datosMaquinas, tblClienteNMaquina }) => {
                    const data = tblPosicion.filter((x) => x.maquina?.idMaquina === id);
                    const maquina = data[0].maquina;
                    const datos = datosMaquinas.find((x) => x.idMaquina === id);
                    const cliente = tblClienteNMaquina.find((x) => x.idMaquina === id);

                    return (
                        <div className="tooltipMaquina d-flex flex-column he-100">
                            <div className="p-0 w-100 mb-2">
                                <Card className="tooltipMaquinaCard">
                                    <CardBody className="p-3">
                                        <Box className="align-items-center justify-content-between">
                                            <ItemBox ratio={1}>
                                                <span className="nombreMaquina">{maquina.denominacion}</span>
                                            </ItemBox>
                                            <ItemBox baseSize={65}>
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <i className="dx-icon-clock mr-2 font-size-lg color-secondary d-flex align-items-center" />
                                                    <span>
                                                        {cliente
                                                            ? cliente.fechaIni.toLocaleTimeString(
                                                                  undefined,
                                                                  this.timeFormat
                                                              )
                                                            : "00:00"}
                                                    </span>
                                                </div>
                                            </ItemBox>
                                        </Box>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="p-0 w-100 mb-2">
                                <Card className="tooltipMaquinaCard">
                                    <CardBody className="p-2">
                                        <Row className="align-items-center">
                                            <Col xs={8} className="subCointainer">
                                                <div
                                                    className={`bg-light d-flex align-items-center ${
                                                        !cliente ? "border-alert" : ""
                                                    }`}
                                                >
                                                    <i className="icon_city_variant_outline p-2 font-size-xxll d-flex align-items-center" />
                                                    {cliente ? (
                                                        <span className="clienteDenominacion">
                                                            {cliente.denominacion}
                                                        </span>
                                                    ) : (
                                                        <span className="clienteDenominacion text-danger">
                                                            CLIENTE NO INICIADO
                                                        </span>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col xs={4}>
                                                <span className="programaDenominacion">{cliente?.programa}</span>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={!cliente ? "filter-blur-5" : ""}>
                                <div className="p-0 w-100 mb-2">
                                    <Row>
                                        <Col xs={6}>
                                            <Card
                                                className="tooltipMaquinaCard he-100"
                                                style={{
                                                    border: `2px solid ${getColor_porcentaje(
                                                        datos?.ptgActual,
                                                        "var(--secondary)"
                                                    )}`,
                                                }}
                                            >
                                                <CardBody className="d-flex flex-column cardUnidades">
                                                    <span>{datos?.prendasCliente ? datos.prendasCliente : 0}</span>
                                                    <span>Uds.</span>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col xs={6}>
                                            <Card
                                                className="tooltipMaquinaCard he-100"
                                                style={{
                                                    border: `2px solid ${getColor_porcentaje(
                                                        datos?.ptgActual,
                                                        "var(--secondary)"
                                                    )}`,
                                                }}
                                            >
                                                <CardBody className="p-0">
                                                    {this.ApexChart(
                                                        datos && datos.ptgActual ? datos.ptgActual : 0,
                                                        datos?.ptgActual === -1
                                                    )}
                                                    {datos?.ptgCliente === -1 && (
                                                        <div
                                                            style={{
                                                                height: "8.5rem",
                                                                position: "absolute",
                                                                top: 0,
                                                                right: 0,
                                                                left: 0,
                                                            }}
                                                        >
                                                            <LottieIcon icon={"loading_input"} />
                                                        </div>
                                                    )}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="p-0 w-100">
                                    <Card className="tooltipMaquinaCard">
                                        <CardBody className="p-2">
                                            <Row>
                                                <Col xs={6}>
                                                    <Card className="p-2">
                                                        <span className="align-self-start color-secondary">
                                                            TEÓRICAS
                                                        </span>
                                                        <div className="cardResumen d-flex align-items-baseline justify-content-end">
                                                            <span>{datos ? datos.prendasHora : 0}</span>
                                                            <span className="color-secondary">Uds/h</span>
                                                        </div>
                                                    </Card>
                                                </Col>
                                                <Col xs={6}>
                                                    <Card
                                                        className="p-2 text-white"
                                                        style={{
                                                            background: getColor_porcentaje(
                                                                datos?.ptgActual,
                                                                "var(--secondary)"
                                                            ),
                                                        }}
                                                    >
                                                        <span className="align-self-start">ACTUALES</span>
                                                        <div className="cardResumen d-flex align-items-baseline justify-content-end">
                                                            <span>
                                                                {datos?.prendasHoraActual ? datos.prendasHoraActual : 0}
                                                            </span>
                                                            <span> Uds/h</span>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </SVContext.Consumer>
        );
    };

    ApexChart = (ptg, disabled) => {
        const porcentaje = ptg === -1 ? 0 : Math.round(ptg * 100);
        return <RadialBar value={porcentaje} color={getColor_porcentaje(ptg)} disabled={disabled} />;
    };
}

export default Maquina;
