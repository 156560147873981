import React, { createContext, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { loadPanelActions } from "../../../actions";

import DataGridFacturasCompra from "./components/DataGridFacturasCompra";
import FormFacturaCompra from "./components/FormFacturaCompra";
import PageTitle from "layout/AppMain/PageTitle";
import { dxMensajePregunta, getNombreFormulario, getTrad } from "helpers";
import { Button, Popup, ScrollView } from "devextreme-react";
import { ToolbarItem as ToolbarItem_Popup } from "devextreme-react/popup";
import { getClearFactura } from "./model";
import { refreshStaticDatasources, datasource_tblAdmFacturaCompra, empresaPolarierSel } from "../shared/model";
import "../shared/styles/css.scss";
import SelectorEmpresa from "../shared/components/SelectorEmpresa";

let validations = new Map();

const FacturaCompra = ({ idioma, user, loadPanel_show, loadPanel_hide }) => {
    const heading = getNombreFormulario({ props: { idioma, user } });
    const [facturaCompraSel, setFacturaCompraSel] = useState({});
    const [adminItemPopup_visibility, setAdminItemPopup_visibility] = useState(false);
    const [empresaSel, setEmpresaSel] = useState(empresaPolarierSel);
    const formFacturaCompra_REF = useRef();
    const savedData = useRef();

    useEffect(() => {
        if (empresaSel.idEmpresaPolarier == null) {
            const empresaAux = {
                idEmpresaPolarier: user.tblEmpresaPolarierNUsuario[0]?.idEmpresaPolarier,
                idPais: user.tblEmpresaPolarierNUsuario[0]?.idPais,
            };
            setEmpresaSel(empresaAux);
        }
        return () => {
            validations = new Map();
        };
    }, []);

    useEffect(() => {
        savedData.current = { ...facturaCompraSel };
    }, [facturaCompraSel]);

    const openPopup = () => {
        setAdminItemPopup_visibility(true);
    };

    const popup_onHiding = (e) => {
        if (formFacturaCompra_REF.current.hasEditData(savedData.current)) {
            e.cancel = true;
            dxMensajePregunta("Hay cambios sin guardar que se van a perder. ¿Desea salir sin guardar? ", [
                [
                    getTrad("aceptar"),
                    () => {
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        } else {
            closePopup();
        }
    };

    const closePopup = () => {
        datasource_tblAdmFacturaCompra.reload();
        loadPanel_hide();
        setAdminItemPopup_visibility(false);
    };

    const onContentReady = (e) => {
        e.component.content().addClass("admScrollPopup");
    };

    const selectorEmpresa_onSelectionChanged = (e) => {
        loadPanel_show();
        refreshStaticDatasources(e.selectedItem);
        setEmpresaSel(e.selectedItem);
    };

    const getTitle = () => {
        if (facturaCompraSel.idAdmFacturaCompra) {
            const string = getTrad("modificandoFacturaCompra") + " - " + facturaCompraSel.codigo;
            return string;
        } else {
            return getTrad("nuevaFacturaCompra");
        }
    };

    const getButtonText = () => {
        if (facturaCompraSel.idAdmFacturaCompra) {
            return getTrad("actualizar");
        } else {
            return getTrad("guardar");
        }
    };

    return (
        //     {/* <ConstantData.Provider value={datasources}> */}
        <>
            <PageTitle
                heading={heading}
                postHeading={
                    <SelectorEmpresa value={empresaSel} onSelectionChanged={selectorEmpresa_onSelectionChanged} />
                }
            />
            <div className="media-body">
                <Popup
                    showTitle={true}
                    title={getTitle()}
                    visible={adminItemPopup_visibility}
                    showCloseButton={true}
                    closeOnOutsideClick={false}
                    height="90%"
                    onHiding={popup_onHiding}
                    onContentReady={onContentReady}
                >
                    <ScrollView>
                        <FormFacturaCompra
                            ref={formFacturaCompra_REF}
                            facturaCompraSel={facturaCompraSel}
                            setFacturaCompraSel={setFacturaCompraSel}
                            validations={validations}
                            closePopup={() => setAdminItemPopup_visibility(false)}
                            loadPanel_show={loadPanel_show}
                            loadPanel_hide={loadPanel_hide}
                        />
                    </ScrollView>
                    <ToolbarItem_Popup toolbar="bottom" location="after">
                        <Button
                            text={getButtonText()}
                            type="success"
                            onClick={formFacturaCompra_REF.current?.onSubmit_FormFacturaCompra}
                            useSubmitBehavior={false}
                            disabled={facturaCompraSel.isCerrado}
                        />
                    </ToolbarItem_Popup>
                </Popup>
                <div className={"formContainer scrollbar-container"}>
                    <DataGridFacturasCompra
                        datasource={datasource_tblAdmFacturaCompra}
                        facturaCompraSel={facturaCompraSel}
                        setFacturaCompraSel={setFacturaCompraSel}
                        openPopup={openPopup}
                        idEmpresaPolarier={empresaSel.idEmpresaPolarier}
                        loadPanel_hide={loadPanel_hide}
                    />
                </div>
            </div>
            {/* </ConstantData.Provider> */}
        </>
        //     )}
        // </>
    );
};

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacturaCompra);
