export const noImage = {
  v: "5.5.7",
  meta: { g: "LottieFiles AE 0.1.20", a: "", k: "", d: "", tc: "#FFFFFF" },
  fr: 29.9700012207031,
  ip: 0,
  op: 70.0000028511585,
  w: 1200,
  h: 1200,
  nm: "no photo",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "zoom tr.mate",
      cl: "mate",
      parent: 4,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [328.532, 328.533, 0], ix: 2 },
        a: { a: 0, k: [274.967, 274.967, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-151.722, 0],
                    [0, 151.722],
                    [151.722, 0],
                    [0, -151.722],
                  ],
                  o: [
                    [151.722, 0],
                    [0, -151.722],
                    [-151.722, 0],
                    [0, 151.722],
                  ],
                  v: [
                    [0, 274.717],
                    [274.717, 0],
                    [0, -274.717],
                    [-274.717, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.941176470588, 0.925490196078, 0.952941176471, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [274.967, 274.967], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 119.000004846969,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "photo zoom",
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 95, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.53, y: 1 },
              o: { x: 0.47, y: 0 },
              t: 24,
              s: [764.714, 514.907, 0],
              to: [31.667, -10.333, 0],
              ti: [-31.667, 10.333, 0],
            },
            { t: 45.0000018328876, s: [954.714, 452.907, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [181.067, 159.642, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
              o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
              t: 24,
              s: [124.128, 124.128, 100],
            },
            { t: 45.0000018328876, s: [100, 100, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [14.626, -2.847],
                    [0, 0],
                    [-6.427, 9.532],
                    [0, 0],
                    [-6.358, -4.284],
                    [0, 0],
                    [-4.289, 6.355],
                    [0, 0],
                    [-6.359, -4.285],
                    [0, 0],
                    [-0.775, -3.989],
                  ],
                  o: [
                    [2.851, 14.629],
                    [0, 0],
                    [-11.969, 2.33],
                    [0, 0],
                    [4.29, -6.355],
                    [0, 0],
                    [6.359, 4.285],
                    [0, 0],
                    [4.282, -6.354],
                    [0, 0],
                    [3.175, 2.142],
                    [0, 0],
                  ],
                  v: [
                    [148.521, 53.916],
                    [127.101, 85.691],
                    [-80.373, 126.064],
                    [-94.079, 105.267],
                    [-55.522, 48.072],
                    [-36.897, 44.449],
                    [-27.371, 50.876],
                    [-8.746, 47.252],
                    [61.949, -57.605],
                    [80.567, -61.228],
                    [128.229, -29.094],
                    [134.026, -20.561],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.625, 2.847],
                    [-2.851, -14.63],
                    [14.633, -2.847],
                    [2.844, 14.629],
                  ],
                  o: [
                    [14.633, -2.847],
                    [2.843, 14.629],
                    [-14.626, 2.846],
                    [-2.849, -14.629],
                  ],
                  v: [
                    [-95.325, -64.268],
                    [-63.547, -42.845],
                    [-84.974, -11.07],
                    [-116.745, -32.493],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [29.259, -5.694],
                    [0, 0],
                    [-5.694, -29.259],
                    [0, 0],
                    [-29.259, 5.694],
                    [0, 0],
                    [5.694, 29.259],
                  ],
                  o: [
                    [-5.694, -29.258],
                    [0, 0],
                    [-29.258, 5.694],
                    [0, 0],
                    [5.694, 29.258],
                    [0, 0],
                    [29.259, -5.694],
                    [0, 0],
                  ],
                  v: [
                    [144.065, -110.853],
                    [80.518, -153.698],
                    [-132.277, -112.29],
                    [-175.123, -48.74],
                    [-144.066, 110.853],
                    [-80.511, 153.698],
                    [132.276, 112.29],
                    [175.123, 48.74],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [181.067, 159.642], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 119.000004846969,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "zoom",
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [328.532, 328.533, 0], ix: 2 },
        a: { a: 0, k: [274.967, 274.967, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-151.722, 0],
                    [0, 151.722],
                    [151.722, 0],
                    [0, -151.722],
                  ],
                  o: [
                    [151.722, 0],
                    [0, -151.722],
                    [-151.722, 0],
                    [0, 151.722],
                  ],
                  v: [
                    [0, 274.717],
                    [274.717, 0],
                    [0, -274.717],
                    [-274.717, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [274.967, 274.967], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 119.000004846969,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "glass",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.53], y: [1] },
              o: { x: [0.47], y: [0] },
              t: 24,
              s: [13.654],
            },
            { t: 45.0000018328876, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.546, y: 0.761 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [650.242, 649.033, 0],
              to: [0, 0, 0],
              ti: [0.358, -0.318, 0],
            },
            {
              i: { x: 0.986, y: 0 },
              o: { x: 0.014, y: 1 },
              t: 13,
              s: [726.44, 627.769, 0],
              to: [-0.348, 0.308, 0],
              ti: [0.288, -0.195, 0],
            },
            {
              i: { x: 0.633, y: 1 },
              o: { x: 0.297, y: 0.096 },
              t: 24,
              s: [726.44, 627.769, 0],
              to: [-1.384, 0.936, 0],
              ti: [6.077, 1.197, 0],
            },
            { t: 45.0000018328876, s: [518.242, 623.033, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [463.856, 470.137, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.53, 0.53, 0.53], y: [1, 1, 1] },
              o: { x: [0.47, 0.47, 0.47], y: [0, 0, 0] },
              t: 24,
              s: [84.025, 84.025, 100],
            },
            { t: 33.0000013441176, s: [100.113, 100.113, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [151.722, 0],
                    [0, 151.722],
                    [-151.722, 0],
                    [0, -151.722],
                  ],
                  o: [
                    [-151.722, 0],
                    [0, -151.722],
                    [151.722, 0],
                    [0, 151.722],
                  ],
                  v: [
                    [0, 274.717],
                    [-274.717, 0.001],
                    [0, -274.716],
                    [274.716, 0.001],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [181.306, 0],
                    [0, -181.305],
                    [-181.305, 0],
                    [0, 181.306],
                  ],
                  o: [
                    [-181.305, 0],
                    [0, 181.306],
                    [181.306, 0],
                    [0, -181.305],
                  ],
                  v: [
                    [0, -328.282],
                    [-328.282, 0],
                    [0, 328.282],
                    [328.282, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [328.532, 328.533], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.142, 1.128],
                    [0, 0],
                    [1.128, -1.141],
                    [0, 0],
                    [-1.141, -1.128],
                    [0, 0],
                    [-1.128, 1.142],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.142, -1.128],
                    [0, 0],
                    [-1.128, 1.141],
                    [0, 0],
                    [1.142, 1.127],
                    [0, 0],
                    [1.127, -1.141],
                  ],
                  v: [
                    [143.363, 59.963],
                    [-61.669, -142.633],
                    [-65.779, -142.609],
                    [-143.383, -64.073],
                    [-143.359, -59.963],
                    [61.675, 142.634],
                    [65.785, 142.612],
                    [143.384, 64.073],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [782.951, 796.264], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [157.86, 103.801],
                    [-105.675, -156.611],
                    [-157.86, -103.8],
                    [105.68, 156.611],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9333333333333333, 0.7725490196078432, 0.403921568627451, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [667.782, 682.459], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 119.000004846969,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "photo",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.53, y: 1 },
              o: { x: 0.47, y: 0 },
              t: 24,
              s: [764.714, 514.907, 0],
              to: [31.667, -10.333, 0],
              ti: [-31.667, 10.333, 0],
            },
            { t: 45.0000018328876, s: [954.714, 452.907, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [181.067, 159.642, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [14.626, -2.847],
                    [0, 0],
                    [-6.427, 9.532],
                    [0, 0],
                    [-6.358, -4.284],
                    [0, 0],
                    [-4.289, 6.355],
                    [0, 0],
                    [-6.359, -4.285],
                    [0, 0],
                    [-0.775, -3.989],
                  ],
                  o: [
                    [2.851, 14.629],
                    [0, 0],
                    [-11.969, 2.33],
                    [0, 0],
                    [4.29, -6.355],
                    [0, 0],
                    [6.359, 4.285],
                    [0, 0],
                    [4.282, -6.354],
                    [0, 0],
                    [3.175, 2.142],
                    [0, 0],
                  ],
                  v: [
                    [148.521, 53.916],
                    [127.101, 85.691],
                    [-80.373, 126.064],
                    [-94.079, 105.267],
                    [-55.522, 48.072],
                    [-36.897, 44.449],
                    [-27.371, 50.876],
                    [-8.746, 47.252],
                    [61.949, -57.605],
                    [80.567, -61.228],
                    [128.229, -29.094],
                    [134.026, -20.561],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.625, 2.847],
                    [-2.851, -14.63],
                    [14.633, -2.847],
                    [2.844, 14.629],
                  ],
                  o: [
                    [14.633, -2.847],
                    [2.843, 14.629],
                    [-14.626, 2.846],
                    [-2.849, -14.629],
                  ],
                  v: [
                    [-95.325, -64.268],
                    [-63.547, -42.845],
                    [-84.974, -11.07],
                    [-116.745, -32.493],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [29.259, -5.694],
                    [0, 0],
                    [-5.694, -29.259],
                    [0, 0],
                    [-29.259, 5.694],
                    [0, 0],
                    [5.694, 29.259],
                  ],
                  o: [
                    [-5.694, -29.258],
                    [0, 0],
                    [-29.258, 5.694],
                    [0, 0],
                    [5.694, 29.258],
                    [0, 0],
                    [29.259, -5.694],
                    [0, 0],
                  ],
                  v: [
                    [144.065, -110.853],
                    [80.518, -153.698],
                    [-132.277, -112.29],
                    [-175.123, -48.74],
                    [-144.066, 110.853],
                    [-80.511, 153.698],
                    [132.276, 112.29],
                    [175.123, 48.74],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9294117647058824, 0.7137254901960784, 0.21568627450980393,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [181.067, 159.642], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 7,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 119.000004846969,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "bg",
      sr: 1,
      ks: {
        o: { a: 0, k: 30, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [599.425, 597.205, 0], ix: 2 },
        a: { a: 0, k: [-0.5, 8, 0], ix: 1 },
        s: { a: 0, k: [115.063, 115.063, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [940, 940], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9764705882352941, 0.9098039215686274, 0.7490196078431373, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.5, 8], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 179.000007290819,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
