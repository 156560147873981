import React from "react";

import { connectionConstants } from "../../../../constants";
import {
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  formatDate_parameter,
  dateTime_hourMinute,
  formatDate,
  capitalize,
} from "../../../../helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  FilterRow,
  Selection,
  Lookup,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import { progressBarCellComponent } from "../../../../components/DataGrid/Cells";

const GET_COLOR_CUMPLIMIENTO = (cumplimiento) => {
  if (cumplimiento >= 0.95) return "success";
  else if (cumplimiento >= 0.9) return "warning";
  else return "danger";
};

export default class GridCumplimientoHorario extends React.PureComponent {
  constructor(props) {
    super(props);

    this.grid_REF = React.createRef();

    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.calculateCustomSummary = this.calculateCustomSummary.bind(this);

    this.cellRender_horarioCumplido =
      this.cellRender_horarioCumplido.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
  }

  get grid() {
    return this.grid_REF.current.instance;
  }

  datasource_cumplimientoHorario = new DataSource({
    paginate: false,
    store: new ODataStore({
      key: "id",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/ControlCalidad/CumplimientoHorario/" +
        this.props.group,
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_beforeSend(request);
      },
      version: 4,
    }),
    sort: ["fecha asc"],
    postProcess: (data) => {
      data.forEach((item) => {
        item.progressBarCellOptions_cumplimiento = {
          color: GET_COLOR_CUMPLIMIENTO(item.cumplimiento),
        };
      });
      return data;
    },
  });

  datasource_beforeSend(request) {
    let { filtros } = this.props;
    let { idLavanderia, fechaIni, fechaFin, idCompañia, idEntidad } = filtros;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderia;
    request.params.idCompañia = idCompañia;
    request.params.idEntidad = idEntidad;
    request.params.fechaIni = formatDate_parameter(fechaIni);
    request.params.fechaFin = formatDate_parameter(fechaFin);
  }

  enum_cumplido_noCumplido = [
    {
      text: capitalize("cumplido"),
      value: 1,
    },
    {
      text: capitalize("no cumplido"),
      value: 0,
    },
  ];

  render() {
    let { selectedRowKey, group } = this.props;

    return (
      <DataGrid
        ref={this.grid_REF}
        dataSource={this.datasource_cumplimientoHorario}
        remoteOperations={false}
        height="100%"
        width="100%"
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        hoverStateEnabled={group !== "detallado"}
        selectedRowKeys={selectedRowKey != null ? [selectedRowKey] : undefined}
        onContentReady={this.onContentReady}
        onToolbarPreparing={
          this.props.title ? this.onToolbarPreparing : undefined
        }
        onEditorPreparing={this.onEditorPreparing}
        onSelectionChanged={
          this.props.onSelectionChanged ? this.onSelectionChanged : undefined
        }
        onRowPrepared={this.onRowPrepared}
      >
        <Paging enabled={false} />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <Selection
          mode={this.props.onSelectionChanged ? "single" : undefined}
        />
        <Column
          name="fecha"
          dataType="date"
          format={"dd/MM/yyyy"}
          caption={getTrad("fecha")}
          width={group === "detallado" ? 160 : 145}
          visible={group === "detallado"}
          sortOrder={group === "detallado" ? "asc" : null}
          calculateCellValue={this.calculateCellValue_fecha}
          calculateSortValue={this.calculateCellValue_fecha}
          allowSorting={true}
          allowFiltering={true}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="nombreTransportista"
          dataType="string"
          caption={getTrad("nombreTransportista")}
          visible={group == "detallado"}
          minWidth={245}
          allowFiltering={false}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="denominacion"
          dataType="string"
          caption={getTrad("denominacion")}
          visible={group !== "detallado"}
          minWidth={245}
          allowFiltering={true}
          allowHeaderFiltering={false}
          sortOrder={group === "detallado" ? undefined : "asc"}
          sortIndex={group === "detallado" ? undefined : 0}
        />
        <Column
          name="horaPrevista"
          dataType="time"
          alignment="center"
          caption={getTrad("horaPrevista")}
          minWidth={125}
          visible={group === "detallado"}
          calculateCellValue={(rowData) => {
            if (!rowData.horarioInicioReparto) return;
            return (
              rowData.horarioInicioReparto.substring(0, 5) +
              " - " +
              rowData.horarioFinReparto.substring(0, 5)
            );
          }}
          allowFiltering={false}
          allowHeaderFiltering={false}
        />
        <Column
          name="horaEntregada"
          dataType="time"
          alignment="center"
          caption={getTrad("horaEntrega")}
          minWidth={200}
          visible={group === "detallado"}
          calculateCellValue={(rowData) => {
            if (!rowData.fechaLlegada || !rowData.fechaSalida) return;
            return dateTime_hourMinute(new Date(rowData.fechaLlegada));
          }}
          allowFiltering={false}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="totalEntregas"
          caption={getTrad("totalEntregas")}
          alignment="center"
          minWidth={100}
          visible={group !== "detallado"}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="entregaHorarioCumplido"
          caption={getTrad("entregasHora")}
          dataType={group === "detallado" ? "boolean" : "number"}
          alignment="center"
          minWidth={100}
          cellRender={
            group === "detallado" ? this.cellRender_horarioCumplido : null
          }
          allowHeaderFiltering={false}
        >
          {group === "detallado" && (
            <Lookup
              dataSource={this.enum_cumplido_noCumplido}
              displayExpr="text"
              valueExpr="value"
            />
          )}
        </Column>
        <Column
          dataField="entregaFueraHora"
          caption={getTrad("entregasFueraHora")}
          alignment="center"
          minWidth={170}
          visible={group !== "detallado"}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="cumplimiento"
          caption={getTrad("cumplimiento")}
          dataType="number"
          alignment="center"
          filterOperations={["=", "<>", "<", ">", "<=", ">="]}
          defaultSelectedFilterOperation=">="
          defaultFilterValue={0}
          cssClass="p-0"
          allowSearch={false}
          allowHeaderFiltering={false}
          allowFiltering={true}
          allowSorting={true}
          minWidth={100}
          visible={group !== "detallado"}
          cellComponent={progressBarCellComponent}
        />
        <Summary calculateCustomSummary={this.calculateCustomSummary}>
          <TotalItem
            column={group === "detallado" ? null : "denominacion"}
            displayFormat={getTrad("total").toUpperCase()}
          />
          {group === "detallado" && (
            <TotalItem
              column="horaPrevista"
              name="horaPrevista"
              showInColumn={group === "detallado" ? "fecha" : null}
              summaryType={group === "detallado" ? "count" : "sum"}
              displayFormat="Entregas totales: {0}"
            />
          )}
          {group === "detallado" && (
            <TotalItem
              name="horaEntregada"
              showInColumn={group === "detallado" ? "horaEntregada" : null}
              summaryType={group === "detallado" ? "custom" : "sum"}
              displayFormat="Entregas fuera de hora: {0}"
            />
          )}
          <TotalItem
            column="totalEntregas"
            name="totalEntregas"
            summaryType="sum"
            displayFormat="{0}"
          />
          <TotalItem
            column="entregaHorarioCumplido"
            name="entregaHorarioCumplido"
            showInColumn={group === "detallado" ? "horaPrevista" : null}
            summaryType="sum"
            displayFormat={
              group === "detallado" ? "Entregas en hora: {0}" : "{0}"
            }
          />
          <TotalItem
            column="entregaFueraHora"
            name="entregaFueraHora"
            summaryType="sum"
            displayFormat="{0}"
          />
          <TotalItem
            name="cumplimiento"
            showInColumn={
              group === "detallado" ? "entregaHorarioCumplido" : "cumplimiento"
            }
            summaryType="custom"
            displayFormat="{0}"
            customizeText={this.customizeText_sum_cumplimiento}
          />
        </Summary>
      </DataGrid>
    );
  }

  calculateCellValue_fecha(rowData) {
    if (!rowData.fechaLlegada) return;
    return formatDate(new Date(rowData.fechaLlegada));
  }

  onContentReady(e) {
    e.element.find(".dx-freespace-row").css("height", 0);
  }

  onRowPrepared(e) {
    let { group } = this.props;
    if (e.rowType === "data" && group !== "detallado") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  cellRender_horarioCumplido(e) {
    let icono = e.value ? "icon_Success" : "icon_Warning";

    return (
      <div
        className={"container_spanCentrado center-svg iconos_svg " + icono}
        style={{ fontSize: "23px" }}
      />
    );
  }

  elementAttr_horarioCumplido = {
    class: "pe-none",
  };

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      text: this.props.title,
    });
  }

  sliderText = null;
  onEditorPreparing(e) {
    let _this = this;
    if (e.parentType === "filterRow") {
      if (e.dataField === "cumplimiento") {
        e.editorName = "dxSlider";
        e.editorOptions = {
          min: 0,
          max: 1,
          value: e.value,
          step: 0.05,
          width: "100%",
          onValueChanged: function (data) {
            e.setValue(data.value);
            let textValue = formatNumber(data.value, 0, "percent");
            _this.sliderText.text(textValue);
          },
          onInitialized: function (e) {
            let value = e.component.option("value");
            let textValue = formatNumber(value, 0, "percent");
            _this.sliderText = $("<div />")
              .addClass("slider-textValue")
              .text(textValue);
            e.element.append(_this.sliderText);
          },
        };
      }
    }
  }

  calculateCustomSummary(options) {
    let { group } = this.props;
    if (options.name === "horaEntregada") {
      switch (options.summaryProcess) {
        case "start":
          options.totalEntregas = 0;
          options.entregaHorarioCumplido = 0;
          break;
        case "calculate":
          options.totalEntregas +=
            group === "detallado" ? 1 : options.value.totalEntregas;
          options.entregaHorarioCumplido +=
            options.value.entregaHorarioCumplido;
          break;
        case "finalize":
          options.totalValue =
            options.totalEntregas - options.entregaHorarioCumplido;
          options.totalValue = options.totalValue ? options.totalValue : 0;
          break;
        default:
          break;
      }
    }
    if (options.name === "cumplimiento") {
      switch (options.summaryProcess) {
        case "start":
          options.totalEntregas = 0;
          options.entregaHorarioCumplido = 0;
          break;
        case "calculate":
          options.totalEntregas +=
            group === "detallado" ? 1 : options.value.totalEntregas;
          options.entregaHorarioCumplido +=
            options.value.entregaHorarioCumplido;
          break;
        case "finalize":
          options.totalValue =
            options.entregaHorarioCumplido / options.totalEntregas;
          options.totalValue = options.totalValue ? options.totalValue : 0;

          break;
        default:
          break;
      }
    }
  }

  customizeText_sum_cumplimiento(itemInfo) {
    return formatNumber(itemInfo.value, 2, "percent");
  }

  onSelectionChanged(e) {
    if (e.selectedRowKeys.length > 0) {
      let key = e.selectedRowKeys[0].split("_");
      let { denominacion } = e.selectedRowsData[0];
      this.props.onSelectionChanged({
        [key[0]]: parseInt(key[1]),
        denominacion: denominacion,
      });
    } else {
      this.props.onSelectionChanged({});
    }
  }

  componentDidUpdate(prevProps) {
    let { title, filtros } = this.props;
    if (JSON.stringify(prevProps.filtros) !== JSON.stringify(filtros)) {
      this.datasource_cumplimientoHorario.reload();
      this.grid.clearFilter();
    }

    if (prevProps.title !== title) {
      this.grid.repaint();
    }
  }
}
