import { connectionConstants } from "../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    formatDate_parameter,
    convertClienteUtcToLavanderiaUtc,
    formatDate_noTime_parameter,
    patchRequestHandler,
} from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
    var deferred = $.Deferred();

    var datasource_resumenKilosTotales;

    var idCompañia;
    var entidadSel;
    var fechaSel = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date());
    var kilosLavadosTotales;

    //#region DATASOURCES

    //Datos de Compañia
    var datasource_compañia = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idCompañia", "denominacion"],
    });

    datasource_resumenKilosTotales = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "kgLavados_spSelectKilosTotalesLav",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.fecha = formatDate_parameter(fechaSel);
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
    });

    datasource_resumenKilosTotales.load();

    //Datos de Entidades
    var datasource_entidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            onLoading: function (loadOptions) {
                loadOptions.expand = [
                    `tblCierreFactEntidad($filter=fechaDesde le ${formatDate_noTime_parameter(
                        fechaSel
                    )} and fechaHasta ge ${formatDate_noTime_parameter(fechaSel)})`,
                ];
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idEntidad", "denominacion"],
    });

    var datasource_tuneles = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "kgLavados_spSelectKgLavadosTunel",
            key: "idKgLavadosTunel",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                if (request.method === "get") {
                    request.params.idLavanderia = LAVANDERIA.idLavanderia;
                    request.params.idEntidad = entidadSel?.idEntidad ? entidadSel.idEntidad : null;
                    request.params.idCompañia = idCompañia ? idCompañia : null;
                    request.params.fecha = formatDate_parameter(fechaSel);
                } else {
                    let key = request.url.substring(request.url.lastIndexOf("("), request.url.lastIndexOf(")") + 1);
                    request.url = connectionConstants.WEB_API_CORE_ODATA_URL + "tblKgLavadosTunel" + key;
                }
            },
            onInserting: function (item) {
                item.fecha = formatDate_noTime_parameter(item.fecha);
            },
            onInserted: function (data) {
                $("#dgvKilosTuneles").dxDataGrid("instance").refresh(true);
            },
            onUpdated: function (data) {
                $("#dgvKilosTuneles").dxDataGrid("instance").refresh(true);
            },
            version: 4,
        }),
        postProcess: function (data) {
            $.each(data, function (index, item) {
                if (!item.idKgLavadosTunel) {
                    item.idKgLavadosTunel = item.idMaquina * -1;
                }
            });
            return data;
        },
        onChanged: function (e) {
            refrescarTotales();
        },
    });

    var datasource_lavadoras = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "kgLavados_spSelectKgLavadosLavadora",
            key: "idKgLavadosLavadora",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                if (request.method === "get") {
                    request.params.idLavanderia = LAVANDERIA.idLavanderia;
                    request.params.fecha = formatDate_parameter(fechaSel);
                    request.params.idEntidad = entidadSel?.idEntidad ? entidadSel.idEntidad : null;
                    request.params.idCompañia = idCompañia ? idCompañia : null;
                } else {
                    let key = request.url.substring(request.url.lastIndexOf("("), request.url.lastIndexOf(")") + 1);
                    request.url = connectionConstants.WEB_API_CORE_ODATA_URL + "tblKgLavadosLavadora" + key;
                }
            },
            onInserting: function (item) {
                item.fecha = formatDate_noTime_parameter(item.fecha);
            },
            onInserted: function (data) {
                $("#dgvKilosLavadoras").dxDataGrid("instance").refresh(true);
            },
            onUpdated: function (data) {
                $("#dgvKilosLavadoras").dxDataGrid("instance").refresh(true);
            },
            version: 4,
        }),
        postProcess: function (data) {
            $.each(data, function (index, item) {
                $.each(item.items, function (iLav, lav) {
                    if (!lav.idKgLavadosLavadora) {
                        lav.idKgLavadosLavadora = lav.idMaquina * -1;
                    }
                });
            });
            return data;
        },
        onChanged: function (e) {
            refrescarTotales();
        },
    });

    //#endregion

    $("#KgLavados #dxContainer").dxBox({
        direction: "row",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
            {
                baseSize: 280,
                shrink: 0,
                ratio: 0,
                template: function () {
                    return $("<div />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                            {
                                //1.-Calendar
                                baseSize: 280,
                                template: function () {
                                    return $("<div  id='dxBox_dxCalendar'/>").dxCalendar({
                                        width: "100%",
                                        min: new Date(2000, 1, 1),
                                        max: fechaSel,
                                        firstDayOfWeek: 1,
                                        value: fechaSel,
                                        onValueChanged: function (e) {
                                            var dgvKilosTuneles = $("#dgvKilosTuneles").dxDataGrid("instance"),
                                                dgvKilosLavadoras = $("#dgvKilosLavadoras").dxDataGrid("instance");

                                            $.when(
                                                dgvKilosTuneles.saveEditData(),
                                                dgvKilosLavadoras.saveEditData()
                                            ).then(function () {
                                                fechaSel = e.value;

                                                dgvKilosTuneles.option("disabled", true);
                                                dgvKilosLavadoras.option("disabled", true);

                                                $.when(
                                                    datasource_entidad.reload(),
                                                    dgvKilosTuneles.refresh(true),
                                                    dgvKilosLavadoras.refresh(true)
                                                ).then(([tblEntidad]) => {
                                                    entidadSel = tblEntidad.find(
                                                        (x) => x.idEntidad === entidadSel?.idEntidad
                                                    );

                                                    refrescarCierreFacturacion();
                                                    dgvKilosTuneles.option("disabled", false);
                                                    dgvKilosLavadoras.option("disabled", false);
                                                });
                                            });
                                        },
                                    });
                                },
                            },
                            {
                                //2.-Compañias y Entidades
                                ratio: 1,
                                template: function () {
                                    return $("<div/>")
                                        .addClass("h5")
                                        .dxTabPanel({
                                            height: "100%",
                                            scrollByContent: true,
                                            scrollingEnabled: true,
                                            animationEnabled: true,
                                            items: [
                                                {
                                                    //Tab compañia
                                                    title: "Compañía",
                                                    template: function () {
                                                        return $("<div id='dxList_compañia'>").dxList({
                                                            height: "100%",
                                                            dataSource: datasource_compañia,
                                                            selectionMode: "single",
                                                            scrolling: { mode: "infinite" },
                                                            searchEnabled: true,
                                                            searchExpr: ["denominacion"],
                                                            pageLoadMode: "scrollBottom",
                                                            onSelectionChanged: function (e) {
                                                                var dgvKilosTuneles =
                                                                        $("#dgvKilosTuneles").dxDataGrid("instance"),
                                                                    dgvKilosLavadoras =
                                                                        $("#dgvKilosLavadoras").dxDataGrid("instance");

                                                                $.when(
                                                                    dgvKilosTuneles.saveEditData(),
                                                                    dgvKilosLavadoras.saveEditData()
                                                                ).then(function () {
                                                                    if (e.addedItems.length > 0) {
                                                                        idCompañia =
                                                                            e.component.option(
                                                                                "selectedItem"
                                                                            ).idCompañia;
                                                                        entidadSel = null;

                                                                        var dxListEntidad =
                                                                            $("#dxList_entidad").dxList("instance");
                                                                        if (typeof dxListEntidad !== "undefined") {
                                                                            dxListEntidad.unselectAll();
                                                                        }
                                                                    }

                                                                    dgvKilosTuneles.option("disabled", true);
                                                                    dgvKilosLavadoras.option("disabled", true);

                                                                    $.when(
                                                                        dgvKilosTuneles.refresh(true),
                                                                        dgvKilosLavadoras.refresh(true)
                                                                    ).then(() => {
                                                                        refrescarCierreFacturacion();
                                                                        dgvKilosTuneles.option("disabled", false);
                                                                        dgvKilosLavadoras.option("disabled", false);
                                                                    });
                                                                });
                                                            },
                                                            itemTemplate: function (data, index) {
                                                                return $("<div>").text(data.denominacion);
                                                            },
                                                        });
                                                    },
                                                },
                                                {
                                                    //Tab entidad
                                                    title: "Entidad",
                                                    template: function () {
                                                        return $("<div />").dxBox({
                                                            direction: "col",
                                                            align: "space-around",
                                                            crossAlign: "stretch",
                                                            height: "100%",
                                                            items: [
                                                                {
                                                                    //1.-Calendar
                                                                    ratio: 1,
                                                                    template: function () {
                                                                        return $("<div id='dxList_entidad'>").dxList({
                                                                            height: "100%",
                                                                            dataSource: datasource_entidad,
                                                                            selectionMode: "single",
                                                                            scrolling: { mode: "infinite" },
                                                                            searchEnabled: true,
                                                                            searchExpr: ["denominacion"],
                                                                            pageLoadMode: "scrollBottom",
                                                                            onSelectionChanged: function (e) {
                                                                                var dgvKilosTuneles =
                                                                                        $(
                                                                                            "#dgvKilosTuneles"
                                                                                        ).dxDataGrid("instance"),
                                                                                    dgvKilosLavadoras =
                                                                                        $(
                                                                                            "#dgvKilosLavadoras"
                                                                                        ).dxDataGrid("instance");

                                                                                $.when(
                                                                                    dgvKilosTuneles.saveEditData(),
                                                                                    dgvKilosLavadoras.saveEditData()
                                                                                ).then(function () {
                                                                                    if (e.addedItems.length > 0) {
                                                                                        entidadSel =
                                                                                            e.component.option(
                                                                                                "selectedItem"
                                                                                            );
                                                                                        idCompañia = null;

                                                                                        var dxListCompañia =
                                                                                            $(
                                                                                                "#dxList_compañia"
                                                                                            ).dxList("instance");
                                                                                        if (
                                                                                            typeof dxListCompañia !==
                                                                                            "undefined"
                                                                                        ) {
                                                                                            dxListCompañia.unselectAll();
                                                                                        }
                                                                                    }

                                                                                    dgvKilosTuneles.option(
                                                                                        "disabled",
                                                                                        true
                                                                                    );
                                                                                    dgvKilosLavadoras.option(
                                                                                        "disabled",
                                                                                        true
                                                                                    );

                                                                                    $.when(
                                                                                        dgvKilosTuneles.refresh(true),
                                                                                        dgvKilosLavadoras.refresh(true)
                                                                                    ).then(() => {
                                                                                        refrescarCierreFacturacion();
                                                                                        dgvKilosTuneles.option(
                                                                                            "disabled",
                                                                                            false
                                                                                        );
                                                                                        dgvKilosLavadoras.option(
                                                                                            "disabled",
                                                                                            false
                                                                                        );
                                                                                    });
                                                                                });
                                                                            },
                                                                            itemTemplate: function (data, index) {
                                                                                let container = $("<div>");
                                                                                container.addClass("position-relative");
                                                                                container.text(data.denominacion);

                                                                                if (
                                                                                    data.tblCierreFactEntidad.length > 0
                                                                                ) {
                                                                                    $("<i/>")
                                                                                        .addClass(
                                                                                            "icon_lockOutline position-absolute-center-right font-size-xl"
                                                                                        )
                                                                                        .appendTo(container);
                                                                                }

                                                                                return container;
                                                                            },
                                                                        });
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    },
                                                },
                                            ],
                                        });
                                },
                            },
                        ],
                    });
                },
            },
            {
                ratio: 1,
                template: function () {
                    return $("<div style='margin-left:20px;' />").dxBox({
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                            {
                                //Box Kilos Tuneles
                                ratio: 3,
                                template: function (e, index, item) {
                                    item.append(
                                        $("<div id='dgvKilosTuneles'/>").dxDataGrid({
                                            //Datos
                                            dataSource: datasource_tuneles,
                                            remoteOperations: {
                                                groupPaging: true,
                                                paging: true,
                                                filtering: true,
                                                sorting: true,
                                                grouping: true,
                                                summary: false,
                                            },
                                            repaintChangesOnly: true,
                                            //Propiedades
                                            columnsAutoWidth: true,
                                            width: "auto",
                                            height: "100%",
                                            searchPanel: {
                                                visible: true,
                                                width: 250,
                                                placeholder: getTrad("busqueda"),
                                            },
                                            editing: {
                                                mode: "cell",
                                                refreshMode: "repaint",
                                                allowUpdating: true,
                                            },
                                            keyboardNavigation: {
                                                enterKeyAction: "moveFocus",
                                                enterKeyDirection: "row",
                                                editOnKeyPress: true,
                                            },
                                            columns: [
                                                {
                                                    dataField: "idMaquina",
                                                    visible: false,
                                                },
                                                {
                                                    dataField: "denominacion",
                                                    caption: getTrad("denominacion"),
                                                    minWidth: "200px",
                                                    sortIndex: 1,
                                                    sortOrder: "asc",
                                                    allowHeaderFiltering: false,
                                                    allowEditing: false,
                                                },
                                                {
                                                    dataField: "numSerie",
                                                    caption: getTrad("numSerie"),
                                                    width: "150px",
                                                    allowEditing: false,
                                                    hidingPriority: 0,
                                                },
                                                {
                                                    dataField: "kgProd",
                                                    caption: getTrad("kgProducido"),
                                                    width: "170px",
                                                    alignment: "center",
                                                    cssClass: "dx-Cell_Editable",
                                                    format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                    },
                                                    editorOptions: {
                                                        step: 0,
                                                        format: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                        max: 99999,
                                                        min: 0,
                                                    },
                                                },
                                                {
                                                    dataField: "kgRech",
                                                    caption: getTrad("kgRechazo"),
                                                    width: "170px",
                                                    alignment: "center",
                                                    cssClass: "dx-Cell_Editable",
                                                    format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                    },
                                                    editorOptions: {
                                                        step: 0,
                                                        format: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                        max: 99999,
                                                        min: 0,
                                                    },
                                                },
                                            ],
                                            summary: {
                                                recalculateWhileEditing: true,
                                                totalItems: [
                                                    {
                                                        column: "denominacion",
                                                        displayFormat: getTrad("total").toUpperCase(),
                                                    },
                                                    {
                                                        name: "sumaKgProducidos",
                                                        column: "kgProd",
                                                        summaryType: "sum",
                                                        displayFormat: "{0} kg.",
                                                        valueFormat: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                    },
                                                    {
                                                        name: "sumaKgRechazados",
                                                        column: "kgRech",
                                                        summaryType: "sum",
                                                        displayFormat: "{0} kg.",
                                                        valueFormat: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                    },
                                                ],
                                            },
                                            //Estilos
                                            showColumnLines: false,
                                            showRowLines: true,
                                            rowAlternationEnabled: true,

                                            ////Eventos
                                            onRowUpdating: function (e) {
                                                if (e.key < 0) {
                                                    var rowIndex = e.component.getRowIndexByKey(e.key);
                                                    var idMaquina = e.component.cellValue(rowIndex, "idMaquina");

                                                    if (e.newData.kgProd) {
                                                        e.newData.kgRech = 0;
                                                        e.oldData.kgProd = e.newData.kgProd;
                                                    } else {
                                                        e.newData.kgProd = 0;
                                                        e.oldData.kgRech = e.newData.kgRech;
                                                    }

                                                    e.newData.idMaquina = idMaquina;
                                                    e.newData.idCompañia = idCompañia;
                                                    e.newData.idEntidad = entidadSel?.idEntidad;
                                                    e.newData.fecha = fechaSel;

                                                    datasource_tuneles.store().insert(e.newData);

                                                    e.component.cancelEditData();
                                                    e.cancel = true;
                                                } else {
                                                    e.newData = patchRequestHandler(e.newData);
                                                }
                                            },
                                            onToolbarPreparing: function (e) {
                                                e.toolbarOptions.items.unshift(
                                                    {
                                                        location: "before",
                                                        template: function () {
                                                            return $("<div class ='font-size'>").text(
                                                                getTrad("kgTuneles")
                                                            );
                                                        },
                                                    },
                                                    {
                                                        location: "after",
                                                        widget: "dxButton",
                                                        showText: "inMenu",
                                                        options: {
                                                            icon: "refresh",
                                                            text: getTrad("actualizar"),
                                                            onClick: function () {
                                                                e.component.refresh(true);
                                                            },
                                                        },
                                                    }
                                                );
                                            },
                                        })
                                    );
                                },
                            },
                            {
                                //Box Kilos lavadoras
                                ratio: 5,
                                template: function (e, index, item) {
                                    item.css("padding-top", "10px");
                                    item.append(
                                        $("<div id='dgvKilosLavadoras' />").dxDataGrid({
                                            //Datos
                                            dataSource: datasource_lavadoras,
                                            remoteOperations: {
                                                groupPaging: true,
                                                paging: true,
                                                filtering: true,
                                                sorting: true,
                                                grouping: true,
                                                summary: false,
                                            },
                                            repaintChangesOnly: true,
                                            //Propiedades
                                            height: "100%",
                                            columnsAutoWidth: true,
                                            searchPanel: {
                                                visible: true,
                                                width: 250,
                                                placeholder: getTrad("busqueda"),
                                            },
                                            editing: {
                                                mode: "cell",
                                                refreshMode: "repaint",
                                                allowUpdating: true,
                                            },
                                            keyboardNavigation: {
                                                enterKeyAction: "moveFocus",
                                                enterKeyDirection: "row",
                                                editOnKeyPress: true,
                                            },
                                            grouping: {
                                                autoExpandAll: true,
                                                allowCollapsing: false,
                                            },
                                            groupPanel: {
                                                visible: false,
                                            },
                                            columns: [
                                                {
                                                    dataField: "idMaquina",
                                                    visible: false,
                                                },
                                                {
                                                    dataField: "isValet",
                                                    width: 0,
                                                    groupCellTemplate: function (cellElement, cellInfo) {
                                                        switch (cellInfo.data.items[0].isValet) {
                                                            case 0:
                                                                cellElement.text(getTrad("lavanderia"));
                                                                break;
                                                            case 1:
                                                                cellElement.text(getTrad("valet"));
                                                                break;
                                                            default:
                                                                break;
                                                        }
                                                    },
                                                    visible: false,
                                                    groupIndex: 0,
                                                },
                                                {
                                                    dataField: "denominacion",
                                                    caption: getTrad("denominacion"),
                                                    minWidth: "200px",
                                                    sortIndex: 1,
                                                    sortOrder: "asc",
                                                    allowEditing: false,
                                                },
                                                {
                                                    dataField: "numSerie",
                                                    caption: getTrad("numSerie"),
                                                    width: "150px",
                                                    allowEditing: false,
                                                    hidingPriority: 0,
                                                },
                                                {
                                                    dataField: "cargasEstandard",
                                                    caption: getTrad("cargasEstandar_abr"),
                                                    width: "150px",
                                                    alignment: "center",
                                                    cssClass: "dx-Cell_Editable",
                                                    format: {
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                    },
                                                    editorOptions: {
                                                        step: 0,
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                        max: 99999,
                                                        min: 0,
                                                    },
                                                },
                                                {
                                                    dataField: "KgEstandar",
                                                    caption: getTrad("kgEstandar"),
                                                    dataType: "number",
                                                    width: "150px",
                                                    alignment: "center",
                                                    format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                    },
                                                    allowEditing: false,
                                                    allowSorting: false,
                                                    allowSearch: false,
                                                    calculateCellValue: function (e) {
                                                        var cargasEstandard = 0;
                                                        var capacidadMaquina = 0;

                                                        cargasEstandard = typeof (e.cargasEstandard !== "undefined")
                                                            ? e.cargasEstandard
                                                            : 0;
                                                        capacidadMaquina = typeof (e.capacidadMaquina !== "undefined")
                                                            ? e.capacidadMaquina
                                                            : 0;

                                                        return cargasEstandard * capacidadMaquina;
                                                    },
                                                },
                                                {
                                                    dataField: "cargasRechazo",
                                                    caption: getTrad("cargasRechazo_abr"),
                                                    width: "150px",
                                                    alignment: "center",
                                                    cssClass: "dx-Cell_Editable",
                                                    format: {
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                    },
                                                    editorOptions: {
                                                        step: 0,
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                        max: 99999,
                                                        min: 0,
                                                    },
                                                },
                                                {
                                                    dataField: "KgRechazo",
                                                    caption: getTrad("kgRechazo"),
                                                    width: "150px",
                                                    alignment: "center",
                                                    allowEditing: false,
                                                    allowSorting: false,
                                                    allowSearch: false,
                                                    format: {
                                                        style: "decimal",
                                                        minimumFractionDigits: 2,
                                                    },
                                                    calculateCellValue: function (e) {
                                                        var cargasRechazo = 0;
                                                        var capacidadMaquina = 0;
                                                        cargasRechazo = typeof (e.cargasRechazo !== "undefined")
                                                            ? e.cargasRechazo
                                                            : 0;
                                                        capacidadMaquina = typeof (e.capacidadMaquina !== "undefined")
                                                            ? e.capacidadMaquina
                                                            : 0;

                                                        return cargasRechazo * capacidadMaquina;
                                                    },
                                                },
                                            ],
                                            summary: {
                                                recalculateWhileEditing: true,
                                                totalItems: [
                                                    {
                                                        column: "denominacion",
                                                        displayFormat: getTrad("total").toUpperCase(),
                                                    },
                                                    {
                                                        name: "sumaKgEstandarLavadora",
                                                        column: "KgEstandar",
                                                        summaryType: "sum",
                                                        displayFormat: "{0} kg.",
                                                        valueFormat: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                    },
                                                    {
                                                        name: "sumaKgRechazadosLavadora",
                                                        column: "KgRechazo",
                                                        summaryType: "sum",
                                                        displayFormat: "{0} kg.",
                                                        valueFormat: {
                                                            style: "decimal",
                                                            minimumFractionDigits: 2,
                                                        },
                                                    },
                                                ],
                                            },
                                            //Eventos
                                            onRowUpdating: function (e) {
                                                if (e.key < 0) {
                                                    var rowIndex = e.component.getRowIndexByKey(e.key);
                                                    var idMaquina = e.component.cellValue(rowIndex, "idMaquina");

                                                    if (e.newData.cargasEstandard) {
                                                        e.newData.cargasRechazo = 0;
                                                        e.oldData.cargasEstandard = e.newData.cargasEstandard;
                                                    } else {
                                                        e.newData.cargasEstandard = 0;
                                                        e.oldData.cargasRechazo = e.newData.cargasRechazo;
                                                    }

                                                    e.newData.idMaquina = idMaquina;
                                                    e.newData.idCompañia = idCompañia;
                                                    e.newData.idEntidad = entidadSel?.idEntidad;
                                                    e.newData.fecha = fechaSel;

                                                    datasource_lavadoras.store().insert(e.newData);

                                                    e.component.cancelEditData();
                                                    e.cancel = true;
                                                } else {
                                                    e.newData = patchRequestHandler(e.newData);
                                                }
                                            },
                                            onToolbarPreparing: function (e) {
                                                e.toolbarOptions.items.unshift(
                                                    {
                                                        location: "before",
                                                        template: function () {
                                                            return $(
                                                                "<div class ='font-size' style='margin-top : 10px;'>"
                                                            ).text(getTrad("kgLavadoras"));
                                                        },
                                                    },
                                                    {
                                                        location: "after",
                                                        widget: "dxButton",
                                                        showText: "inMenu",
                                                        options: {
                                                            icon: "refresh",
                                                            text: getTrad("actualizar"),
                                                            onClick: function () {
                                                                e.component.refresh(true);
                                                            },
                                                        },
                                                    }
                                                );
                                            },
                                            //Estilos
                                            showColumnLines: false,
                                            showRowLines: true,
                                            rowAlternationEnabled: true,
                                        })
                                    );
                                },
                            },
                            {
                                baseSize: 85,
                                shrink: 0,
                                ratio: 0,
                                template: function (e, index, item) {
                                    item.css("padding-top", "10px");
                                    item.css("border-top", "1px solid gainsboro");

                                    item.append(
                                        $("<div id='dxForm_totales'/>").dxForm({
                                            formData: kilosLavadosTotales,
                                            labelLocation: "top",
                                            colCount: 4,
                                            items: [
                                                {
                                                    dataField: "kgLavadosEstandar",
                                                    editorType: "dxNumberBox",
                                                    disabled: true,
                                                    editorOptions: {
                                                        stylingMode: "underlined",
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                    },
                                                    label: {
                                                        text: getTrad("kgLavadosEstandar"),
                                                    },
                                                },
                                                {
                                                    dataField: "kgLavadosRechazo",
                                                    editorType: "dxNumberBox",
                                                    disabled: true,
                                                    editorOptions: {
                                                        stylingMode: "underlined",
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                    },
                                                    label: {
                                                        text: getTrad("kgLavadosRechazo"),
                                                    },
                                                },
                                                {
                                                    dataField: "kgLavadoraEstandar",
                                                    editorType: "dxNumberBox",
                                                    disabled: true,
                                                    editorOptions: {
                                                        stylingMode: "underlined",
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                    },
                                                    label: {
                                                        text: getTrad("kgLavadorasEstandar"),
                                                    },
                                                },
                                                {
                                                    dataField: "kgLavadoraRechazo",
                                                    editorType: "dxNumberBox",
                                                    disabled: true,
                                                    editorOptions: {
                                                        stylingMode: "underlined",
                                                        format: {
                                                            style: "decimal",
                                                            maximumFractionDigits: 0,
                                                        },
                                                        elementAttr: {
                                                            id: "kgLavadoraRechazo",
                                                        },
                                                    },
                                                    label: {
                                                        text: getTrad("kgLavadorasRechazo"),
                                                    },
                                                },
                                            ],
                                        })
                                    );
                                },
                            },
                        ],
                    });
                },
            },
        ],
    });

    function refrescarTotales() {
        datasource_resumenKilosTotales.load().done(function () {
            kilosLavadosTotales = datasource_resumenKilosTotales._items[0];
            if ($("#dxForm_totales").length > 0) {
                $("#dxForm_totales").dxForm("instance").updateData(kilosLavadosTotales);
            }
        });
    }

    function refrescarCierreFacturacion() {
        var dgvKilosTuneles = $("#dgvKilosTuneles").dxDataGrid("instance"),
            dgvKilosLavadoras = $("#dgvKilosLavadoras").dxDataGrid("instance");

        if (fechaSel != null && entidadSel != null) {
            let cierres = entidadSel.tblCierreFactEntidad;
            let isFacturacionAbierta = cierres.length === 0;

            dgvKilosTuneles.option("editing.allowUpdating", isFacturacionAbierta);
            dgvKilosLavadoras.option("editing.allowUpdating", isFacturacionAbierta);

            dgvKilosTuneles.columnOption("kgProd", "cssClass", isFacturacionAbierta ? "dx-Cell_Editable" : "");
            dgvKilosTuneles.columnOption("kgRech", "cssClass", isFacturacionAbierta ? "dx-Cell_Editable" : "");
            dgvKilosLavadoras.columnOption(
                "cargasEstandard",
                "cssClass",
                isFacturacionAbierta ? "dx-Cell_Editable" : ""
            );
            dgvKilosLavadoras.columnOption("cargasRechazo", "cssClass", isFacturacionAbierta ? "dx-Cell_Editable" : "");
        } else {
            dgvKilosTuneles.option("editing.allowUpdating", true);
            dgvKilosLavadoras.option("editing.allowUpdating", true);

            dgvKilosTuneles.columnOption("kgProd", "cssClass", "dx-Cell_Editable");
            dgvKilosTuneles.columnOption("kgRech", "cssClass", "dx-Cell_Editable");
            dgvKilosLavadoras.columnOption("cargasEstandard", "cssClass", "dx-Cell_Editable");
            dgvKilosLavadoras.columnOption("cargasRechazo", "cssClass", "dx-Cell_Editable");
        }
    }

    deferred.resolve();
    return deferred.promise();
}
