//PUBLIC
import Login from "../pages/NoAuthPages/Login";
import ForgotPassword from "../pages/NoAuthPages/ForgotPassword";
import RecoveryPassword from "../pages/NoAuthPages/RecoveryPassword";
import Redirect from "../pages/NoAuthPages/Redirect";

// PRIVATE
// Administración
import AlbaranCompra from "pages/Administracion/AlbaranCompra";
import AlbaranVenta from "pages/Administracion/AlbaranVenta";
import PedidoCliente from "pages/Administracion/PedidoCliente";
import PedidoProveedor from "pages/Administracion/PedidoProveedor";
import PresupuestoVenta from "pages/Administracion/PresupuestoVenta";
import FacturaCompra from "pages/Administracion/FacturaCompra";
import FacturaVenta from "pages/Administracion/FacturaVenta";
import Articulos from "pages/Administracion/Parametrizacion/Articulos";
import Clientes from "pages/Administracion/Parametrizacion/Clientes";

// Assistant
import MonitorSAT from "../pages/Assistant/MonitorSAT";
import OrdenesTrabajo from "../pages/Assistant/OrdenesTrabajo";
import Maquinaria from "../pages/Assistant/Maquinaria";
import GruposMaquinas from "../pages/Assistant/GruposMaquinas";
import CategoriasMaquinas from "../pages/Assistant/CategoriasMaquinas";
import Proveedores from "../pages/Assistant/Proveedores";
import Almacenes from "../pages/Assistant/Almacenes";
import Recambios from "../pages/Assistant/Recambios";
import GestionAlmacenes from "../pages/Assistant/GestionAlmacenes";
import InfRecambios from "../pages/Assistant/InfRecambios";

// AssistantNew
import GestionAlmacenesV2 from "pages/AssistantNew/GestionAlmacenes";
import RecambiosV2 from "pages/AssistantNew/Recambios";
import Entradas from "pages/AssistantNew/Movimientos/Entradas";
import Traspasos from "pages/AssistantNew/Movimientos/Traspasos";
import Salidas from "pages/AssistantNew/Movimientos/Salidas";

//Control Calidad
import Incidencias from "../pages/ControlCalidad/Incidencias";
import Auditorias from "../pages/ControlCalidad/Auditorias";
import NoConformidades from "../pages/ControlCalidad/NoConformidades";
import ReunionesComerciales from "../pages/ControlCalidad/ReunionesComerciales";
import InfClasificacionSucio from "../pages/ControlCalidad/InfClasificacionSucio";
import InfCumplimientoHorario from "../pages/ControlCalidad/InfCumplimientoHorario";
import InfRechazoCliente from "../pages/ControlCalidad/InfRechazoCliente";
import InfCumplimientoPedidos from "../pages/ControlCalidad/InfCumplimientoPedidos";
import InfControlAlmacenCliente from "../pages/ControlCalidad/InfControlAlmacenCliente";
import InfEncuestas from "../pages/ControlCalidad/InfEncuestas";

//Dashboard
import ControlUso from "../pages/Dashboard/ControlUso";
import IngresosCostes from "../pages/Dashboard/IngresosCostes/index";
import DashboardOperativo from "../pages/Dashboard/Operativo";
import ProduccionLavadoras from "../pages/Dashboard/ProduccionLavadoras";
import ControlEnvios from "../pages/Dashboard/ControlEnvios";
import DashboardEjecutivo from "../pages/Dashboard/DashboardEjecutivo";
import DashboardMantenimiento from "../pages/Dashboard/DashboardMantenimiento";
import DashboardEnergyHub from "../pages/Dashboard/DashboardEnergyHub";
import ControlRendimiento from "../pages/Dashboard/SmartView/ControlRendimiento";
import SmartView from "../pages/Dashboard/SmartView";
import VisorRutas from "../pages/Dashboard/VisorRutas";
import LaundryDashboard from "../pages/Dashboard/LaundryDashboard";

//Gestión interna
import Usuarios from "../pages/GestionInterna/Usuarios";
import MiCuenta from "../pages/GestionInterna/MiCuenta";

//Inventarios
import Inventarios from "../pages/Inventarios/Inventarios";
import Movimientos from "../pages/Inventarios/Movimientos";

//Logística
import Abonos from "../pages/Logistica/Abonos";
import PartesTransporte from "../pages/Logistica/PartesTransporte";
import Pedidos from "../pages/Logistica/Pedidos";
import Repartos from "../pages/Logistica/Repartos";
import RepartosOffice from "../pages/Logistica/RepartosOffice";
import ValidarRepartos from "../pages/Logistica/ValidarRepartos";
import Revisiones from "../pages/Logistica/Revisiones";

// Parámetros generales
import EstructuraOrganizativa from "../pages/ParametrosGenerales/EstructuraOrganizativa";
import Lavanderia from "../pages/ParametrosGenerales/Lavanderia";
import PrevisionIngresosCostes from "../pages/ParametrosGenerales/PrevisionIngresosCostes";
import RecursosEnergeticos from "../pages/ParametrosGenerales/RecursosEnergeticos";
import RutasExpedicion from "../pages/ParametrosGenerales/RutasExpedicion";
import RutasRevision from "../pages/ParametrosGenerales/RutasRevision";
import Vehiculos from "../pages/ParametrosGenerales/Vehiculos";
import Turno from "../pages/ParametrosGenerales/Turno";
import EstructuraOperativa from "../pages/ParametrosGenerales/EstructuraOperativa";

//Producción
import Estancias from "../pages/Produccion/Estancias";
import KgLavados from "../pages/Produccion/KgLavados";
import LecturaRecursos from "../pages/Produccion/LecturaRecursos";
import Produccion from "../pages/Produccion/Produccion";
import ProduccionMaquina from "../pages/Produccion/ProduccionMaquina";

//RRHH
import CalendarioLaboral from "../pages/RRHH/CalendarioLaboral";
import CalendarioLaboral_RRHH from "../pages/RRHH/CalendarioLaboral_RRHH";
import Personal from "../pages/RRHH/Personal";
import PersonalGeneral from "../pages/RRHH/PersonalGeneral/index";
import JornadaPersona from "../pages/RRHH/JornadaPersona/index";
import Notificaciones from "../pages/RRHH/Notificaciones";
import FestivosCierres from "../pages/RRHH/FestivosCierres";
import CategoriasLaborales from "../pages/RRHH/CategoriasLaborales";
import GestionNominas from "../pages/RRHH/GestionNominas";
import CuadrantePersonal from "../pages/RRHH/CuadrantePersonal";
import ValidacionNomina from "../pages/RRHH/ValidacionNomina";
import CalendarioLavanderia from "../pages/RRHH/CalendarioLavanderia";
import InformeEventosPersona from "pages/RRHH/InformeEventosPersona";

///Valet
import PrendasHueped from "../pages/Valet/PrendasHuesped";
import PedidosHueped from "../pages/Valet/PedidosHuesped";
import RepartosHuesped from "../pages/Valet/RepartosHuesped";
import ConfigUniformidad from "../pages/Valet/ConfigUniformidad";
import MovimientosUniformidad from "../pages/Valet/MovimientosUniformidad/index.js";

//Informes
import InformesHome from "../pages/Informes/Home";

import InfOperativo from "../pages/Informes/InformesEjecutivos/InfOperativo";
import InfOperativo_new from "../pages/Informes/InformesEjecutivos/InfOperativo_new";

import InfLenceria from "../pages/Informes/InformesEjecutivos/InfLenceria";
import InfAuditorias from "../pages/Informes/InformesEjecutivos/InfAuditorias";
import InfInventarios from "../pages/Informes/InformesEjecutivos/InfInventarios";
import InfGestionMantenimiento from "../pages/Informes/InformesEjecutivos/InfGestionMantenimiento";

import InfProduccion from "../pages/Informes/InformesOperacionales/InfProduccion";
import InfReposicionesGeneral from "../pages/Informes/InformesOperacionales/InfReposicionesGeneral";
import InfReposicionesOffice from "../pages/Informes/InformesOperacionales/InfReposicionesOffice";
import InfLogistica from "../pages/Informes/InformesOperacionales/InfLogistica";
import InfBalancePedidosRepartos from "../pages/Informes/InformesOperacionales/InfBalancePedidosRepartos";
import InfFacturacionCliente from "../pages/Informes/InformesOperacionales/InfFacturacionCliente";
import InfPartesTrabajo from "../pages/Informes/InformesOperacionales/InfPartesTrabajo";
import InfRRHH from "../pages/Informes/InformesOperacionales/InfRRHH";
import InfPreventivoSAT from "../pages/Informes/InformesOperacionales/InfPreventivoSAT";
import InfRutasExpedicion from "../pages/Informes/InformesOperacionales/InfRutasExpedicion";
import InfPedidosHuesped from "../pages/Informes/InformesOperacionales/InfPedidosHuesped";
import InfComparativaProcesadosEntregados from "../pages/Informes/InformesOperacionales/InfComparativaProcesadosEntregados";
import InfKgLavados from "../pages/Informes/InformesOperacionales/InfKgLavados";
import InfFactUnif from "../pages/Informes/InformesOperacionales/InfFactUnif";

//Finanzas
import DatosFinancieros from "../pages/Finanzas/DatosFinancieros";
import DashboardFinanciero from "../pages/Finanzas/DashboardFinanciero";
import ControlPresupuestario from "../pages/Finanzas/ControlPresupuestario";
import ControlPresupuestarioV2 from "../pages/Finanzas/ControlPresupuestarioV2";
import CierreDatos from "../pages/Finanzas/CierreDatos";

//Contabilidad
import AsientosNominas from "pages/Contabilidad/AsientosNominas";

//Gestoria
import solicitudGestoria from "pages/Gestoria/index";

export const publicRoutes = [
    {
        path: "/Login/:message?/:username?/:password?", // /:redirectTo?",
        component: Login,
    },
    {
        path: "/Redirect/:credentials?",
        component: Redirect,
    },
    {
        path: "/Redirect/:credentials?",
        component: Redirect,
    },
    {
        path: "/ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/RecoveryPassword/:token",
        component: RecoveryPassword,
    },
];

const administracion = [
    {
        path: "/Compras/AlbaranCompra",
        component: AlbaranCompra,
    },
    {
        path: "/Ventas/AlbaranVenta",
        component: AlbaranVenta,
    },
    {
        path: "/Ventas/PedidoCliente",
        component: PedidoCliente,
    },
    {
        path: "/Compras/PedidoProveedor",
        component: PedidoProveedor,
    },
    {
        path: "/Ventas/PresupuestoVenta",
        component: PresupuestoVenta,
    },
    {
        path: "/Compras/FacturaCompra",
        component: FacturaCompra,
    },
    {
        path: "/Ventas/FacturaVenta",
        component: FacturaVenta,
    },
    {
        path: "/Administracion/Articulos",
        component: Articulos,
    },
    {
        path: "/Administracion/Clientes",
        component: Clientes,
    },
];

const assistant = [
    {
        path: "/Assistant/MonitorSAT",
        component: MonitorSAT,
    },
    {
        path: "/Assistant/OrdenesTrabajo",
        component: OrdenesTrabajo,
    },
    {
        path: "/Assistant/Maquinaria",
        component: Maquinaria,
    },
    {
        path: "/Assistant/GruposMaquinas",
        component: GruposMaquinas,
    },
    {
        path: "/Assistant/CategoriasMaquinas",
        component: CategoriasMaquinas,
    },
    {
        path: "/Assistant/Proveedores",
        component: Proveedores,
    },
    {
        path: "/Assistant/Almacenes",
        component: Almacenes,
    },
    {
        path: "/Assistant/Recambios",
        component: Recambios,
    },
    {
        path: "/Assistant/GestionAlmacenes",
        component: GestionAlmacenes,
    },
    {
        path: "/Assistant/InfRecambios",
        component: InfRecambios,
    },
];

const assistantNew = [
    {
        path: "/AssistantNew/GestionAlmacenes",
        component: GestionAlmacenesV2,
    },
    {
        path: "/AssistantNew/Recambios",
        component: RecambiosV2,
    },
    {
        path: "/AssistantNew/Entradas",
        component: Entradas,
    },
    {
        path: "/AssistantNew/Traspasos",
        component: Traspasos,
    },
    {
        path: "/AssistantNew/Salidas",
        component: Salidas,
    },
];

const controlCalidad = [
    {
        path: "/ControlCalidad/Auditorias",
        component: Auditorias,
    },
    {
        path: "/ControlCalidad/NoConformidades",
        component: NoConformidades,
    },
    {
        path: "/ControlCalidad/Incidencias",
        component: Incidencias,
    },
    {
        path: "/ControlCalidad/ReunionesComerciales",
        component: ReunionesComerciales,
    },
    {
        path: "/ControlCalidad/InfClasificacionSucio",
        component: InfClasificacionSucio,
    },
    {
        path: "/ControlCalidad/InfCumplimientoHorario",
        component: InfCumplimientoHorario,
    },
    {
        path: "/ControlCalidad/InfRechazoCliente",
        component: InfRechazoCliente,
    },
    {
        path: "/ControlCalidad/InfCumplimientoPedidos",
        component: InfCumplimientoPedidos,
    },
    {
        path: "/ControlCalidad/InfControlAlmacenCliente",
        component: InfControlAlmacenCliente,
    },
    {
        path: "/ControlCalidad/InfEncuestas",
        component: InfEncuestas,
    },
];

const dashboard = [
    {
        path: "/Dashboard/DashboardEjecutivo",
        component: DashboardEjecutivo,
    },
    {
        path: "/Dashboard/ControlUso",
        component: ControlUso,
    },
    {
        path: "/Dashboard/IngresosCostes",
        component: IngresosCostes,
    },
    {
        path: "/Dashboard/DashboardOperativo",
        component: DashboardOperativo,
    },
    {
        path: "/Dashboard/ProduccionLavadoras",
        component: ProduccionLavadoras,
    },
    {
        path: "/Dashboard/ControlEnvios",
        component: ControlEnvios,
    },
    {
        path: "/Dashboard/DashboardMantenimiento",
        component: DashboardMantenimiento,
    },
    {
        path: "/Dashboard/DashboardEnergyHub",
        component: DashboardEnergyHub,
    },
    // {
    //     path: "/Dashboard/ControlRendimiento",
    //     component: ControlRendimiento
    // },
    {
        path: "/Dashboard/SmartView",
        component: SmartView,
    },
    {
        path: "/Dashboard/VisorRutas",
        component: VisorRutas,
    },
    {
        path: "/Dashboard/LaundryDashboard",
        component: LaundryDashboard,
    },
];

const inventarios = [
    {
        path: "/Inventarios/Inventarios",
        component: Inventarios,
    },
    {
        path: "/Inventarios/Movimientos",
        component: Movimientos,
    },
];

const logistica = [
    {
        path: "/Logistica/Abonos",
        component: Abonos,
    },
    {
        path: "/Logistica/PartesTransporte",
        component: PartesTransporte,
    },
    {
        path: "/Logistica/Pedidos",
        component: Pedidos,
    },
    {
        path: "/Logistica/Repartos",
        component: Repartos,
    },
    {
        path: "/Logistica/RepartosOffice",
        component: RepartosOffice,
    },
    {
        path: "/Logistica/ValidarRepartos",
        component: ValidarRepartos,
    },
    {
        path: "/Logistica/Revisiones",
        component: Revisiones,
    },
];

const parametrosGenerales = [
    {
        path: "/ParametrosGenerales/EstructuraOrganizativa",
        component: EstructuraOrganizativa,
    },
    {
        path: "/ParametrosGenerales/Lavanderia",
        component: Lavanderia,
    },
    {
        path: "/ParametrosGenerales/PrevisionIngresosCostes",
        component: PrevisionIngresosCostes,
    },
    {
        path: "/ParametrosGenerales/RecursosEnergeticos",
        component: RecursosEnergeticos,
    },
    {
        path: "/ParametrosGenerales/RutasExpedicion",
        component: RutasExpedicion,
    },
    {
        path: "/ParametrosGenerales/RutasRevision",
        component: RutasRevision,
    },
    {
        path: "/ParametrosGenerales/Vehiculos",
        component: Vehiculos,
    },
    {
        path: "/ParametrosGenerales/Turno",
        component: Turno,
    },
    {
        path: "/ParametrosGenerales/EstructuraOperativa",
        component: EstructuraOperativa,
    },
];

const produccion = [
    {
        path: "/Produccion/Estancias",
        component: Estancias,
    },
    {
        path: "/Produccion/KgLavados",
        component: KgLavados,
    },
    {
        path: "/Produccion/LecturaRecursos",
        component: LecturaRecursos,
    },
    {
        path: "/Produccion/Produccion",
        component: Produccion,
    },
    {
        path: "/Produccion/ProduccionMaquina",
        component: ProduccionMaquina,
    },
];

const rrhh = [
    {
        path: "/RRHH/CalendarioLaboral",
        component: CalendarioLaboral,
    },
    {
        path: "/RRHH/CalendarioLaboral_RRHH",
        component: CalendarioLaboral_RRHH,
    },
    {
        path: "/RRHH/PersonalGeneral",
        component: PersonalGeneral,
    },
    {
        path: "/RRHH/Personal",
        component: Personal,
    },
    {
        path: "/RRHH/JornadaPersona",
        component: JornadaPersona,
    },
    {
        path: "/RRHH/Notificaciones",
        component: Notificaciones,
    },
    {
        path: "/RRHH/FestivosCierres",
        component: FestivosCierres,
    },
    {
        path: "/RRHH/CategoriasLaborales",
        component: CategoriasLaborales,
    },
    {
        path: "/RRHH/GestionNominas",
        component: GestionNominas,
    },
    {
        path: "/RRHH/CuadrantePersonal",
        component: CuadrantePersonal,
    },
    {
        path: "/RRHH/ValidacionNomina",
        component: ValidacionNomina,
    },
    {
        path: "/RRHH/CalendarioLavanderia",
        component: CalendarioLavanderia,
    },
    {
        path: "/RRHH/InformeEventosPersona",
        component: InformeEventosPersona,
    },
];

const valet = [
    {
        path: "/Valet/PrendasHuesped",
        component: PrendasHueped,
    },
    {
        path: "/Valet/PedidosHuesped",
        component: PedidosHueped,
    },
    {
        path: "/Valet/RepartosHuesped",
        component: RepartosHuesped,
    },
    {
        path: "/Valet/ConfigUniformidad",
        component: ConfigUniformidad,
    },
    {
        path: "/Valet/MovimientosUniformidad",
        component: MovimientosUniformidad,
    },
];

const informes = [
    {
        exact: true,
        path: "/Informes",
        component: InformesHome,
    },
    {
        path: "/Informes/InfOperativo",
        component: InfOperativo,
    },
    {
        path: "/Informes/InfOperativo_new",
        component: InfOperativo_new,
    },
    {
        path: "/Informes/InfLenceria",
        component: InfLenceria,
    },
    {
        path: "/Informes/InfAuditorias",
        component: InfAuditorias,
    },
    {
        path: "/Informes/InfInventarios",
        component: InfInventarios,
    },
    {
        path: "/Informes/InfGestionMantenimiento",
        component: InfGestionMantenimiento,
    },
    {
        path: "/Informes/InfProduccion",
        component: InfProduccion,
    },
    {
        path: "/Informes/InfReposicionesGeneral",
        component: InfReposicionesGeneral,
    },
    {
        path: "/Informes/InfReposicionesOffice",
        component: InfReposicionesOffice,
    },
    {
        path: "/Informes/InfLogistica",
        component: InfLogistica,
    },
    {
        path: "/Informes/InfBalancePedidosRepartos",
        component: InfBalancePedidosRepartos,
    },
    {
        path: "/Informes/InfFacturacionCliente",
        component: InfFacturacionCliente,
    },
    {
        path: "/Informes/InfPartesTrabajo",
        component: InfPartesTrabajo,
    },
    {
        path: "/Informes/InfRRHH",
        component: InfRRHH,
    },
    {
        path: "/Informes/InfPreventivoSAT",
        component: InfPreventivoSAT,
    },
    {
        path: "/Informes/InfRutasExpedicion",
        component: InfRutasExpedicion,
    },
    {
        path: "/Informes/InfPedidosHuesped",
        component: InfPedidosHuesped,
    },
    {
        path: "/Informes/InfComparativaProcesadosEntregados",
        component: InfComparativaProcesadosEntregados,
    },
    {
        path: "/Informes/InfKgLavados",
        component: InfKgLavados,
    },
    {
        path: "/Informes/InfFactUnif",
        component: InfFactUnif,
    },
];

const gestionInterna = [
    {
        path: "/GestionInterna/Usuarios",
        component: Usuarios,
    },
    {
        path: "/GestionInterna/MiCuenta",
        component: MiCuenta,
    },
];

const finanzas = [
    {
        path: "/Finanzas/DashboardFinanciero",
        component: DashboardFinanciero,
    },
    {
        path: "/Finanzas/DatosFinancieros",
        component: DatosFinancieros,
    },
    {
        path: "/Finanzas/ControlPresupuestario",
        component: ControlPresupuestario,
    },
    {
        path: "/Finanzas/CierreDatos",
        component: CierreDatos,
    },
    {
        path: "/Finanzas/ControlPresupuestarioV2",
        component: ControlPresupuestarioV2,
    },
];

const contabilidad = [
    {
        path: "/Contabilidad/AsientosNominas",
        component: AsientosNominas,
    },
];

const gestoria = [
    {
        path: "/Gestoria/SolicitudGestoria",
        component: solicitudGestoria,
    },
];

export const privateRoutes = []
    .concat(administracion)
    .concat(assistant)
    .concat(assistantNew)
    .concat(controlCalidad)
    .concat(dashboard)
    .concat(inventarios)
    .concat(logistica)
    .concat(parametrosGenerales)
    .concat(produccion)
    .concat(rrhh)
    .concat(valet)
    .concat(informes)
    .concat(gestionInterna)
    .concat(finanzas)
    .concat(contabilidad)
    .concat(gestoria);
