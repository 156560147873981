import React, { useEffect, useState } from "react";

import MultiView, { Item } from "devextreme-react/multi-view";
import ODataContext from "devextreme/data/odata/context";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import notify from "devextreme/ui/notify";

import DataGridConflictos from "./components/DataGridConflictos";
import DisplayConfiltos from "./components/DisplayConfiltos";
import LoadPanel_ProgressBar from "components/LoadPanel_ProgressBar/LoadPanel_ProgressBar";
import { authHeader, errorHandler, getTrad } from "helpers";
import { connectionConstants } from "constants";

const PopupCompararDatosSalariales = ({ visible, onHiding }) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [resumenConflictos, setResumenConflictos] = useState([]);
  const [resumenConflictosSel, setResumenConflictosSel] = useState({ conflictos: [] });
  const [progress, setProgress] = useState(0);
  const [isVisible_LoadPanel_ProgressBar, setIsVisible_LoadPanel_ProgressBar] = useState(false);

  useEffect(() => {
    if (!visible)
      goBack();
  }, [visible]);

  useEffect(() => {
    if (visible && isFirstRender && resumenConflictos.length === 0) {
      setIsFirstRender(false);
      setIsVisible_LoadPanel_ProgressBar(true);
      loadDatosSalariales();
    }
  }, [visible, isFirstRender, resumenConflictos]);

  const loadDatosSalariales = async () => {
    await setStateAsync({
      progress: 0,
      resumenConflictos: [],
      isVisible_LoadPanel_ProgressBar: true,
    });

    let currentPage = 0;
    let totalPages = 1;

    while (currentPage < totalPages) {
      currentPage++;
      await context_gestionNominas
        .invoke("GetResumenConflictosDatosSalariales", { pageNumber: currentPage }, "GET")
        .done(data => {
          totalPages = data.totalPages;

          handleNewData(data.result);
          setProgress(Math.ceil(currentPage / totalPages * 100));
        })
        .catch(() => {
          notify({
            message: getTrad("errorObtenerDatosSalariales"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
          currentPage = totalPages;

          setProgress(100);
        });
    }

    setIsVisible_LoadPanel_ProgressBar(false);
    setProgress(0);
  };

  const setStateAsync = async state => {
    if (state?.resumenConflictos)
      setResumenConflictos(state.resumenConflictos);
    if (state?.progress)
      setProgress(state.progress);
    if (state?.isVisible_LoadPanel_ProgressBar)
      setIsVisible_LoadPanel_ProgressBar(state.isVisible_LoadPanel_ProgressBar);
  };

  const handleNewData = data => {
    setResumenConflictos(prevResumenConflictos => {
      return data.map(c => {
        const { field, conflictos } = c;
        const conceptoExistente = prevResumenConflictos.find(dato => dato.field === field);
        if (conceptoExistente) {
          return { ...conceptoExistente, conflictos: [...conceptoExistente.conflictos, ...conflictos] };
        } else {
          return c;
        }
      });
    });
  };

  const context_gestionNominas = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/",
    entities: {
      GetResumenConflictosDatosSalariales: {
        key: "pageNumber",
        keyType: "Int32",
      },
    },
    errorHandler: error => errorHandler(error, null),
    beforeSend: request => request.headers = { ...authHeader() },
  });

  const onSetConflictosSel = data => {
    setSelectedIndex(1);
    setResumenConflictosSel(data);
  };

  const goBack = () => {
    setSelectedIndex(0);
    setResumenConflictosSel({ conflictos: [] });
  };

  const optionsAtras = {
    icon: "chevronleft",
    width: 45,
    type: "normal",
    onClick: goBack
  };

  const optionsActualizar = {
    icon: "refresh",
    text: getTrad("actualizar"),
    type: "normal",
    onClick: async () => {
      setIsVisible_LoadPanel_ProgressBar(true);
      loadDatosSalariales();
    }
  };

  return (
    <>
      <Popup
        visible={visible && !isVisible_LoadPanel_ProgressBar}
        onHiding={onHiding}
      >
        <MultiView
          selectedIndex={selectedIndex}
          loop={false}
          swipeEnabled={false}
          deferRendering={false}
        >
          <Item>
            <DisplayConfiltos dataSource={resumenConflictos} setConflictosSel={onSetConflictosSel} />
          </Item>
          <Item>
            <DataGridConflictos resumenConflictos={resumenConflictosSel} />
          </Item>
        </MultiView>
        {selectedIndex === 1
          ? <ToolbarItem
            widget={"dxButton"}
            location={"before"}
            toolbar={"top"}
            options={optionsAtras}
          />
          : null
        }
        <ToolbarItem
          text={selectedIndex === 1 ? resumenConflictosSel.denominacion : getTrad("compararDatosSalariales")}
          location={"before"}
          toolbar={"top"}
        />
        {selectedIndex === 0
          ? <ToolbarItem
            widget={"dxButton"}
            location={"before"}
            toolbar={"bottom"}
            options={optionsActualizar}
          />
          : null
        }
      </Popup>
      <LoadPanel_ProgressBar
        title={getTrad("obteniendoDatosSalariales")}
        progress={progress}
        visible={isVisible_LoadPanel_ProgressBar}
      />
    </>
  );
}

export default PopupCompararDatosSalariales;