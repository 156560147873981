import $ from "jquery";
window.jQuery = $;
require("signalr");

export class Group {
  constructor(groupName, old_key, new_key) {
    this.groupName = groupName;
    this.old_key = old_key;
    this.new_key = new_key;
  }
}

let actualHubConnection = null;
export const signalRService = {
  openConnection,
  closeAllConnections,
  signalR_refresh,
  _set_grupos_cliente,
  set_grupos,
  remove_allGroups,
};

function openConnection(nombreHub, connection) {
  this.grupos = [];
  actualHubConnection = connection.createHubProxy(nombreHub);
  actualHubConnection.serverTimeoutInMilliseconds = 100000;

  //#region Para cada hub suscribir 1 método
  actualHubConnection.on("signalR_refresh", () => {});
  //#endregion

  window.hubReady = actualHubConnection.connection.start({ jsonp: true });
}

function closeAllConnections() {
  if (actualHubConnection) actualHubConnection.connection.stop(true, false);
}

//Evento que ejectuta el WEB API
function signalR_refresh(_this) {
  actualHubConnection.off("signalR_refresh").on("signalR_refresh", () => {
    _this.signalR_onRefresh();
  });
}

//Gestionar grupos cliente
function _set_grupos_cliente(grupos) {
  let _this = this;
  $.each(grupos, function (index, item) {
    let groupName = item.groupName + "|";

    if (item.old_key != null) {
      let posicion = $.inArray(groupName + item.old_key, _this.grupos);
      if (posicion !== -1) _this.grupos.splice(posicion, 1);
    }

    if (item.new_key != null) _this.grupos.push(groupName + item.new_key);
  });
}

//Subscribirse, substituir o dejar grupo en servidor.
function set_grupos(grupos) {
  let _this = this;
  function _set_grupos() {
    window.hubReady.done(function () {
      actualHubConnection.invoke("modificarGrupo", grupos);
    });
    _this._set_grupos_cliente(grupos);
  }

  if (actualHubConnection.state === 4) {
    //desconectado
    actualHubConnection.connection.start({ jsonp: true }).done(function () {
      _set_grupos();
    });
  } else _set_grupos();
}

function remove_allGroups() {
  let grupos = [];
  $.each(this.grupos, function () {
    let groupName = this.split("|")[0],
      old_key = this.split("|")[1];

    grupos.push(new Group(groupName, old_key, null));
  });
  this.set_grupos(grupos);
}
