import React, { forwardRef, useState } from "react";

import DataGrid, {
  Column,
  Editing,
  KeyboardNavigation,
  Paging,
} from "devextreme-react/data-grid";
import Popover from "devextreme-react/popover";
import notify from "devextreme/ui/notify";

import { formatDate, getTrad } from "helpers";

const onInitNewRow = ({ data }) =>
  (data.fecha = new Date(new Date().setHours(0, 0, 0, 0)));

const SharedDataGridHistorico = forwardRef(
  (
    { dataSource, keyExpr, visible, errorMessage, getIsCerrado, children },
    ref
  ) => {
    const [cellHover, setCellHover] = useState(null);

    const validationCallback_registroMismoDia = ({ value: fecha }) => {
      const rows = ref?.current?.instance?.getVisibleRows();
      const fechaFormateada = formatDate(fecha);

      const existeMismaFecha =
        rows.filter(({ data }) => formatDate(data.fecha) === fechaFormateada)
          .length > 1;

      return !existeMismaFecha;
    };

    const validationCallback_facturacionCerrada = ({ value }) =>
      !getIsCerrado(value);

    const validationRules = {
      fecha: [
        {
          type: "required",
          message: getTrad("campoNecesario"),
        },
        {
          type: "range",
          max: new Date(),
          message: getTrad("fechaInvalida"),
        },
        {
          type: "custom",
          reevaluate: true,
          validationCallback: validationCallback_registroMismoDia,
          message: getTrad("error_registroMismoDia"),
        },
        {
          type: "custom",
          reevaluate: true,
          validationCallback: validationCallback_facturacionCerrada,
          message: getTrad("error_facturacionCerrada"),
        },
      ],
    };

    const editorOptions = {
      fecha: {
        useMaskBehavior: true,
        displayFormat: "dd/MM/yyyy",
        calendarOptions: {
          max: new Date(),
        },
      },
    };

    const onRowRemoving = (e) => {
      if (e.component.getVisibleRows().length === 1) {
        e.cancel = true;
        notify({
          message: getTrad(errorMessage),
          type: "error",
          displayTime: 1500,
          closeOnClick: true,
        });
      }
    };

    const onToolbarPreparing = ({ toolbarOptions }) => {
      toolbarOptions.items.forEach((item) => {
        if (item.name === "addRowButton") {
          item.options.text = getTrad("añadir");
          item.options.icon = "plus";
          item.options.type = "success";
          item.showText = "always";
        }
      });
    };

    const geId = (data, column, rowIndex) => {
      const key = data[keyExpr] + "_" + rowIndex;
      const columnName = column.dataField ?? column.name;
      const id = columnName + "_" + key;
      return id;
    };

    const onEditingStart = (e) => (e.cancel = getIsCerrado(e.data.fecha));

    const onCellHoverChanged = ({
      rowType,
      column,
      eventType,
      data,
      rowIndex,
    }) => {
      if (
        rowType === "data" &&
        column.name === "eliminar" &&
        eventType === "mouseover"
      ) {
        setCellHover(`#${geId(data, column, rowIndex)}`);
      }
    };

    const cellRender = ({ data, column, component, rowIndex }) => {
      const isCerrado = getIsCerrado && getIsCerrado(data.fecha);
      const id = geId(data, column, rowIndex);

      return (
        <div
          id={isCerrado ? id : undefined}
          className={`container_spanCentrado ${isCerrado ? "" : "pointer"}`}
          onMouseDown={
            isCerrado ? undefined : () => component.deleteRow(rowIndex)
          }
        >
          <div
            className={`${
              isCerrado ? "icon_lockOutline" : "icon_Cerrar"
            } font-size-lg`}
          />
        </div>
      );
    };

    return (
      <>
        <div>
          {visible ? (
            <DataGrid
              ref={ref}
              dataSource={dataSource}
              keyExpr={keyExpr}
              height={360}
              columnsAutoWidth
              hoverStateEnabled
              repaintChangesOnly
              rowAlternationEnabled
              showColumnLines={false}
              showRowLines
              onInitNewRow={onInitNewRow}
              onRowRemoving={onRowRemoving}
              onToolbarPreparing={onToolbarPreparing}
              onEditingStart={onEditingStart}
              onCellHoverChanged={onCellHoverChanged}
            >
              <Editing
                mode={"cell"}
                refreshMode={"repaint"}
                allowAdding
                allowUpdating
                selectTextOnEditStart
                useIcons
              />
              <KeyboardNavigation
                enterKeyAction={"moveFocus"}
                enterKeyDirection={"row"}
                editOnKeyPress
              />
              <Paging enabled={false} />
              <Column
                dataField={"fecha"}
                caption={getTrad("fechaInicio")}
                alignment={"center"}
                dataType={"date"}
                format={"dd/MM/yyyy"}
                sortOrder={"desc"}
                editorOptions={editorOptions.fecha}
                validationRules={validationRules.fecha}
              />
              {children}
              <Column
                name={"eliminar"}
                caption={" "}
                width={40}
                alignment={"center"}
                cssClass={"p-0"}
                allowEditing={false}
                cellRender={cellRender}
              />
            </DataGrid>
          ) : null}
        </div>
        <Popover
          target={cellHover}
          position={"bottom"}
          showEvent={"mouseenter"}
          hideEvent={"mouseleave"}
        >
          {getTrad("cerrado")}
        </Popover>
      </>
    );
  }
);

export default SharedDataGridHistorico;
