import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import $ from "jquery";

import DataGrid, {
    Column,
    ColumnChooser,
    SearchPanel,
    FilterRow,
    Sorting,
    Lookup as DataGrid_Lookup,
    Summary,
    TotalItem,
    Pager,
} from "devextreme-react/data-grid";

import { formatNumber, getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { getClearAlbaran } from "../../model";
import {
    calculateDisplayValue,
    filterArticulo,
    calcularValorNeto,
    custom_displayExpr,
    getFormatDescuento,
    getLabel_impuesto,
    totalCustomSummary,
    onCellPrepared_Total,
} from "pages/Administracion/shared/functions";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblAdmTipoElemento,
    datasource_tblCliente,
    datasource_tblMoneda,
    datasource_tblViasPagoCobro,
    datasource_tblAdmAlbaran_Estado,
    sharedEditorOptions,
    formats,
    datasource_tblIvaNPais_Venta,
    datasource_tblIncoterm,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { MainScreenContext } from "../..";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import { formatNumber as dxFormatNumber } from "devextreme/localization";
import config_isCerrado from "pages/Administracion/shared/configs/config_isCerrado";
import { codigosMoneda } from "constants/enums/General/tblMoneda";

const DataGridAlbaranesVenta = ({
    datasource,
    setAlbaranVentaSel,
    openPopup,
    updateSummary,
    reloadData,
    idEmpresaPolarier,
    loadPanel_hide,
}) => {
    const ScreenCxt = useContext(MainScreenContext);

    const [clientes, setClientes] = useState([]);
    const [centrosCoste, setCentrosCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);

    useEffect(() => {
        Promise.all([
            datasource_tblCliente.reload(),
            datasource_tblAdmCentroCoste.reload(),
            datasource_tblAdmTipoElemento.reload(),
        ]).then(() => {
            setClientes(datasource_tblCliente.items());
            setCentrosCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    const calculateCellValue_bruto = (e) => {
        if (e.tblArticuloNAdmAlbaranVenta.length > 0) {
            let total = 0;
            e.tblArticuloNAdmAlbaranVenta.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += calcularValorNeto(cantidad * precio, descuento, 1);
                // total += cantidad * precio * (1 - descuento);
            });
            return { valor: total - total * e.descuento, idMoneda: e.idMoneda };
        }
    };

    const calculateCellValue_total = (e) => {
        if (e.tblArticuloNAdmAlbaranVenta.length > 0) {
            let total = 0;
            e.tblArticuloNAdmAlbaranVenta.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += cantidad * precio * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
            total = calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "venta");
            return { valor: total, idMoneda: e.idMoneda };
        }
    };

    const calculateCellDisplayValue_bruto = (e) => {
        let total = calculateCellValue_bruto(e)?.valor;
        return calculateDisplayValue(total == null || isNaN(total) ? 0 : total, e.idMoneda);
    };

    const calculateCellDisplayValue_total = (e) => {
        let total = calculateCellValue_total(e)?.valor;
        return calculateDisplayValue(total == null || isNaN(total) ? 0 : total, e.idMoneda);
    };

    const onToolbarPreparing = ({ toolbarOptions, component }) => {
        toolbarOptions.items.unshift(
            {
                location: "before",
                visible: !ScreenCxt.mainScreen,
                template: () =>
                    $('<div id="articulos_title" class="font-size" style="margin-top:10px" >').text(
                        getTrad("albaranes")
                    ),
            },
            {
                location: "before",
                widget: "dxButton",
                visible: ScreenCxt.mainScreen,
                options: {
                    icon: "plus",
                    text: getTrad("nuevoAlbaran"),
                    onClick: () => onNewAlbaran(),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                locateInMenu: "auto",
                options: {
                    text: getTrad("exportar"),
                    icon: "exportxlsx",
                    onClick: () => component.exportToExcel(),
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                visible: ScreenCxt.mainScreen,
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onClick: () => {
                        reloadData();
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                visible: ScreenCxt.mainScreen,
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: () => {
                        component.clearFilter();
                    },
                },
            }
        );
    };

    const onNewAlbaran = () => {
        const albaran = getClearAlbaran();
        setAlbaranVentaSel({ ...albaran });
        openPopup();
    };

    const onRowPrepared_tblAlbaranes = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onRowClick_tblAlbaranes = useMemo(() => {
        return (e) => {
            if (e.data) {
                setAlbaranVentaSel({ ...e.data, articulosDraft: [...e.data.tblArticuloNAdmAlbaranVenta] });
                openPopup();
            }
        };
    }, []);

    const calculateDisplayValue_descuento = (data) => {
        if (data.idAdmTipoDescuento === 1) {
            return dxFormatNumber(data.descuento, getFormatDescuento(data.idAdmTipoDescuento));
        }
        if (data.idAdmTipoDescuento === 2) {
            return calculateDisplayValue(data.descuento == null ? 0 : data.descuento, data.idMoneda);
        }
    };

    return (
        <DataGrid
            dataSource={ScreenCxt.mainScreen ? datasource : ScreenCxt.datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblAlbaranes}
            onCellPrepared={onCellPrepared_Total}
            onRowClick={onRowClick_tblAlbaranes}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={ScreenCxt.mainScreen} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idTipoAlbaran"}
                caption={getTrad("tipoAlbaran")}
                dataType={"number"}
                visible={false}
                showInColumnChooser={true}
                cellComponent={(e) => TipoElemento(e.data.data.idTipoAlbaran, "")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmTipoElemento.store()}
                    valueExpr="idAdmTipoElemento"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"fechaCreacion"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmCliente"}
                caption={getTrad("cliente")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={clientes} valueExpr="idAdmCliente" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmAlbaran_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmAlbaran_Estado, "", "albaran")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmAlbaran_Estado.store()}
                    valueExpr="idAdmAlbaran_Estado"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            <Column dataField={"idMoneda"} caption={getTrad("moneda")} visible={false} allowReordering allowResizing>
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"numPedidoCliente_codigo"}
                caption={getTrad("numPedidoCliente")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"bruto"}
                caption={getTrad("bruto")}
                dataType={"number"}
                visible={!ScreenCxt.mainScreen}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue_bruto}
                calculateDisplayValue={calculateCellDisplayValue_bruto}
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={!ScreenCxt.mainScreen}
                alignment={"center"}
                calculateDisplayValue={calculateDisplayValue_descuento}
                allowReordering
                allowResizing
                width={100}
            />
            <Column
                dataField={"idIvaNPais"}
                caption={getLabel_impuesto(idEmpresaPolarier)}
                dataType={"number"}
                visible={!ScreenCxt.mainScreen}
                alignment={"center"}
                format={formats.percent}
                allowReordering
                allowResizing
                width={100}
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIvaNPais_Venta.store()}
                    valueExpr="idIvaNPais"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                visible={true}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue_total}
                calculateDisplayValue={calculateCellDisplayValue_total}
            />
            <Column {...config_isCerrado} />
            <Column
                dataField={"tblArticuloNAlbaranCompra"}
                // caption={getTrad("articulos")}
                dataType={"object"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            {/* <Column
                caption={' '}
                width={30}
                alignment={'center'}
                visible={ScreenCxt.mainScreen}
                cssClass={'p-0'}
                cellComponent={removeCellComponent}
            /> */}
            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"bruto"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

export default DataGridAlbaranesVenta;
