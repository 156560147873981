import { authHeader } from "helpers";

export const addAuthHeader = (request) =>
  (request.headers = { ...authHeader() });

export const getInitialState = (idTipoMovimientoRecambio) => ({
  idMovimientoRecambio: null,
  idTipoMovimientoRecambio,
  idAlmacenOrigen: null,
  idAlmacenDestino: null,
  idProveedor: null,
  clienteDestino: null,
  fecha: null,
  observaciones: "",
  isEditable: true,
  tblRecambioNMovimientoRecambio: [],
});
