import React from "react";
import ControlPresupuestarioContext, { ControlPresupuestarioCxt } from "./contexts/context";
import ControlPresupuestarioWrapper from "./ControlPresupuestario";

class ControlPresupuestario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <ControlPresupuestarioContext>
                <ControlPresupuestarioCxt.Consumer>
                    {({
                        centros,
                        setCentros,
                        empresa,
                        setEmpresa,
                        fecha,
                        setFecha,
                        vista,
                        moneda,
                        tasaDivisa,
                        intercambiarDivisa,
                        agruparCentros,
                    }) => (
                        <ControlPresupuestarioWrapper
                            centros={centros}
                            setCentros={setCentros}
                            empresa={empresa}
                            setEmpresa={setEmpresa}
                            fecha={fecha}
                            setFecha={setFecha}
                            vista={vista}
                            moneda={moneda}
                            tasaDivisa={tasaDivisa}
                            intercambiarDivisa={intercambiarDivisa}
                            agruparCentros={agruparCentros}
                        />
                    )}
                </ControlPresupuestarioCxt.Consumer>
            </ControlPresupuestarioContext>
        );
    }
}

export default ControlPresupuestario;
