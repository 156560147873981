import React from "react";

import { SelectBox } from "devextreme-react";

import { ControlPresupuestarioCxt } from "../../../contexts/context";

class SelectorEmpresa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ empresa, setEmpresa, datasource_tblEmpresasPolarier }) => {
                    return (
                        <SelectBox
                            dataSource={datasource_tblEmpresasPolarier}
                            value={empresa}
                            className="centerText"
                            displayExpr={"denominacion"}
                            onValueChanged={(e) => setEmpresa(e.value)}
                            width={250}
                            {...this.props}
                        />
                    );
                }}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

export default SelectorEmpresa;
