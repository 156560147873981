import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../../constants";
import {
  capitalize,
  errorHandler,
  getTrad,
  history,
  monthToName,
  authHeader,
  formatDate_noTime_parameter,
  endOfMonth,
  startOfMonth,
} from "../../../../../helpers";

//Layout
import PageTitle from "../../../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Button, List, SelectBox, Tooltip } from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Form, {
  Item,
  RequiredRule,
  SimpleItem,
  Tab,
  TabPanelOptions,
  TabbedItem,
} from "devextreme-react/form";
import notify from "devextreme/ui/notify";

class CierreDatos_Facturacion_AddMasivo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        fechaDesde: startOfMonth(new Date()),
        fechaHasta: endOfMonth(new Date()),
        periodo: new Date(),
      },
      idLavanderia: null,
      compañia_selectedItemKeys: [],
      entidad_selectedItemKeys: [],
      isVisible_warning_periodo: false,
      isVisible_warning_entidades: false,
    };
  }

  datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      paginate: false,
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblLavanderia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    sort: ["denominacion asc"],
  });

  datasource_tblLavanderia_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCompañia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idCompañia", "denominacion"],
    filter: ["eliminado eq false"],
    sort: ["denominacion asc"],
  });

  datasource_tblCompañia_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    let { idLavanderia } = this.state;
    if (idLavanderia != null) {
      request.params.idLavanderia = idLavanderia;
    }
  };

  datasource_tblEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblEntidad_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblEntidad_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: ["idEntidad", "idCompañia", "denominacion"],
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.datasource_tblEntidad_postProcess(data);
    },
  });

  datasource_tblEntidad_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    let { idLavanderia } = this.state;
    if (idLavanderia != null) {
      request.params.idLavanderia = idLavanderia;
    }
  };

  datasource_tblEntidad_onLoading = (loadOptions) => {
    const { formData, compañia_selectedItemKeys } = this.state;
    const año = formData.periodo.getFullYear();
    const mes = formData.periodo.getMonth() + 1;

    loadOptions.expand =
      "tblCierreDatos_Facturacion($filter=año eq " +
      año +
      " and mes eq " +
      mes +
      ")";
    loadOptions.filter = [
      [loadOptions.filter?.length > 0 ? loadOptions.filter : ["1 eq 1"]],
      "and",
      ["idCompañia in (" + compañia_selectedItemKeys.map((item) => item) + ")"],
      "and",
      ["eliminado eq false"],
    ];
  };

  datasource_tblEntidad_postProcess = (data) => {
    data = data.map((itemData) => {
      return {
        idEntidad: itemData.idEntidad,
        idCompañia: itemData.idCompañia,
        denominacion: itemData.denominacion,
        disabled: itemData.tblCierreDatos_Facturacion.length > 0,
      };
    });
    data.sort((a, b) => a.disabled - b.disabled);
    return data;
  };

  render() {
    const { formData, compañia_selectedItemKeys, entidad_selectedItemKeys } =
      this.state;

    return (
      <form className="he-100 w-100" onSubmit={this.submit}>
        <Box direction="col" width="100%" height="100%">
          <ItemBox ratio={1}>
            <Form
              formData={formData}
              showColonAfterLabel={true}
              validationGroup="validationGroup"
              className="he-100 w-100"
              labelLocation="top"
            >
              <TabbedItem>
                <TabPanelOptions
                  deferRendering={false}
                  height={"100%"}
                  animationEnabled={true}
                  elementAttr={this.dxTabPanel_elementAttr}
                  itemTitleRender={this.dxTabPanel_itemTitleRender}
                />
                <Tab title="Periodo" name="periodo" colCount={2}>
                  <Item
                    colSpan={2}
                    dataField="periodo"
                    label={this.formLabel_periodo}
                    editorType="dxCalendar"
                    cssClass={"mb-4"}
                    editorOptions={this.editorOptions_calendar}
                  />
                  <Item
                    dataField="fechaDesde"
                    label={this.formLabel_fechaDesde}
                    editorType="dxDateBox"
                    editorOptions={this.editorOptions_date}
                  />
                  <Item
                    dataField="fechaHasta"
                    label={this.formLabel_fechaHasta}
                    editorType="dxDateBox"
                    editorOptions={this.editorOptions_date}
                  />
                </Tab>
                <Tab title={getTrad("entidades")} name="entidades">
                  <Box direction="row" width="100%" height={550}>
                    <ItemBox ratio={1}>
                      <Box direction="col" width="100%" height="100%">
                        <ItemBox baseSize={45}>
                          <div className="font-size">
                            {getTrad("compañias")}
                          </div>
                        </ItemBox>
                        <ItemBox baseSize={45}>
                          <SelectBox
                            dataSource={this.datasource_tblLavanderia}
                            displayExpr="denominacion"
                            valueExpr="idLavanderia"
                            placeholder={getTrad("lavanderías")}
                            showClearButton={true}
                            onValueChanged={this.lavanderia_onValueChanged}
                          />
                        </ItemBox>
                        <ItemBox ratio={1}>
                          <List
                            dataSource={this.datasource_tblCompañia}
                            height="100%"
                            pageLoadMode="scrollBottom"
                            keyExpr="idCompañia"
                            displayExpr="denominacion"
                            searchEnabled={true}
                            searchExpr={"denominacion"}
                            selectionMode="multiple"
                            selectedItemKeys={compañia_selectedItemKeys}
                            onOptionChanged={this.compañia_onOptionChanged}
                          />
                        </ItemBox>
                      </Box>
                    </ItemBox>
                    <ItemBox baseSize={20}></ItemBox>
                    <ItemBox ratio={1}>
                      <Box direction="col" width="100%" height="100%">
                        <ItemBox baseSize={45}>
                          <div className="font-size">
                            {getTrad("entidades")}
                          </div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                          <List
                            dataSource={this.datasource_tblEntidad}
                            height="100%"
                            keyExpr={["idEntidad", "idCompañia"]}
                            displayExpr="denominacion"
                            searchEnabled={true}
                            searchExpr={"denominacion"}
                            selectionMode="multiple"
                            pageLoadMode="scrollBottom"
                            showSelectionControls={true}
                            selectedItemKeys={entidad_selectedItemKeys}
                            onOptionChanged={this.entidad_onOptionChanged}
                          />
                        </ItemBox>
                      </Box>
                    </ItemBox>
                  </Box>
                </Tab>
              </TabbedItem>
            </Form>
          </ItemBox>
          <ItemBox baseSize={45}>
            <Toolbar>
              <ToolbarItem location={"after"}>
                <Button
                  text={getTrad("guardar")}
                  type="success"
                  useSubmitBehavior={true}
                />
              </ToolbarItem>
            </Toolbar>
          </ItemBox>
        </Box>
      </form>
    );
  }

  dxTabPanel_elementAttr = { class: "h4" };

  formLabel_periodo = { text: getTrad("periodo") };
  formLabel_fechaDesde = { text: getTrad("fechaDesde") };
  formLabel_fechaHasta = { text: getTrad("fechaHasta") };

  editorOptions_date = {
    displayFormat: "dd/MM/yyyy",
    openOnFieldClick: true,
    stylingMode: "underlined",
    acceptCustomValue: false,
  };

  editorOptions_calendar = {
    elementAttr: {
      class: "w-100",
    },
    height: 400,
    maxZoomLevel: "year",
    minZoomLevel: "year",
    cellTemplate: (cell) => {
      return capitalize(cell.date.toLocaleString("es-ES", { month: "long" }));
    },
    onValueChanged: (e) => {
      let { formData } = this.state;

      let _formData = {
        ...formData,
        fechaDesde: startOfMonth(e.value),
        fechaHasta: endOfMonth(e.value),
      };

      this.setState(
        { formData: _formData, entidad_selectedItemKeys: [] },
        () => {
          this.datasource_tblEntidad.load();
        },
      );
    },
  };

  dxTabPanel_itemTitleRender = (e) => {
    let { isVisible_warning_periodo, isVisible_warning_entidades } = this.state;

    let showWarning =
      (e.name === "periodo" && isVisible_warning_periodo) ||
      (e.name === "entidades" && isVisible_warning_entidades);

    return (
      <div>
        <i className={showWarning ? "icon_Warning" : ""} /> {e.title}
      </div>
    );
  };

  lavanderia_onValueChanged = (e) => {
    this.setState({ idLavanderia: e.value }, () => {
      this.datasource_tblCompañia.load();
    });
  };

  compañia_onOptionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys") {
      //Se filtran entidad_selectedItemKeys por las compañías visibles.
      const { entidad_selectedItemKeys } = this.state;
      let entidad_selectedItemKeys_ = entidad_selectedItemKeys.filter((x) =>
        value.includes(x.idCompañia),
      );

      this.setState(
        {
          compañia_selectedItemKeys: value,
          entidad_selectedItemKeys: entidad_selectedItemKeys_,
        },
        () => {
          this.datasource_tblEntidad.load();
        },
      );
    }
  };

  entidad_onOptionChanged = ({ name, value }) => {
    if (name === "selectedItemKeys") {
      this.setState({ entidad_selectedItemKeys: value });
    }
  };

  customizeText_mes = (rowData) => {
    return monthToName(rowData.value, "es");
  };

  submit = async (e) => {
    e.preventDefault();
    const { datasource_tblCierreDatos_Facturacion, onSave } = this.props;
    const { formData, entidad_selectedItemKeys } = this.state;

    if (entidad_selectedItemKeys.length > 0) {
      const año = formData.periodo.getFullYear();
      const mes = formData.periodo.getMonth() + 1;
      const objInsert = {
        año,
        mes,
        fechaDesde: formatDate_noTime_parameter(formData.fechaDesde),
        fechaHasta: formatDate_noTime_parameter(formData.fechaHasta),
      };

      let events_insert = [];
      entidad_selectedItemKeys.forEach((key) => {
        let objInsert_ = { ...objInsert };
        objInsert_.idEntidad = key.idEntidad;

        events_insert.push(
          datasource_tblCierreDatos_Facturacion.store().insert(objInsert_),
        );
      });

      await Promise.all(events_insert);
      await datasource_tblCierreDatos_Facturacion.reload();

      //Manda la señal al parent y expande el año y mes dónde se han insertado los datos.
      onSave(año, mes);
    } else {
      this.setState({ isVisible_warning_entidades: true });
      notify({
        message: getTrad("alerta_rellenarCamposObligatorios"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }
  };

  //#endregion

  componentDidMount() {
    const { user } = this.props;
    if (!user.isDepartamentoControl) {
      history.push("/");
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(CierreDatos_Facturacion_AddMasivo);
