import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  tooltipControl_creacion,
  compressBase64Img_scaleWidth,
  dxMensajePregunta,
  datetimeToDuration,
  durationToDatetime,
  get_isMovil,
  formatDateTime_parameter,
  patchRequestHandler,
  dateTime_hourMinute,
} from "../../../helpers";

import $ from "jquery";
//Actions
import {
  loadPanelActions,
  globalActions,
  impresionActions,
} from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";
import Guid from "devextreme/core/guid";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import ODataContext from "devextreme/data/odata/context";

import ScrollView from "devextreme-react/scroll-view";
import { Button, TileView, FileUploader, Gallery } from "devextreme-react";
import DataGrid, {
  Column,
  Paging,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Sorting,
  Lookup as Lookup_grid,
  Editing,
  Scrolling,
} from "devextreme-react/data-grid";
import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import {
  Form,
  Item,
  RequiredRule,
  TabbedItem,
  TabPanelOptions,
  Tab,
} from "devextreme-react/form";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

import {
  CircularGauge,
  Geometry,
  Scale,
  Tick,
  RangeContainer,
  Label,
  Range,
  ValueIndicator,
  Margin,
} from "devextreme-react/circular-gauge";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { Tooltip } from "devextreme-react/tooltip";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Loader from "react-loaders";
import { Popover } from "devextreme-react/popover";
import { Progress } from "reactstrap";
import SelectBox from "devextreme-react/select-box";
import Accordion from "devextreme-react/accordion";

//CUSTOM COMPONENTS
import {
  editCellComponent,
  removeCellComponent,
  colorCellComponent,
  progressBarCellComponent,
} from "../../../components/DataGrid/Cells";

//DEVEXTREME JQUERY
import cargaJs from "./Js.js";
import Query from "devextreme/data/query";

//Css
import "./Css.scss";
import PopupAñadirRecambio from "components/Recambios/PopupAñadirRecambio";

class MonitorSat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dxMultiView_index: 0,
      datos_selector: [],
      datos_selector_filtrados: [],
      datos_Anteriores: {},
      loadingData_multiview_page2: false,
      categoriasMaquina_LavSel: [],
      maquinaSel: null,
      categoriaMaquinaSel: null,
      incidenciaSel: null,
      parteTrabajoSel: null,
      showPopup_Add_tblPersonasNParte: false,
      show_Gallery: false,
      showPopup_Add_tblRecambioNParteTrabajo: false,
      idCorporacionSel: null,
      idPaisSel: null,
      idRecambioEditing: null,
    };

    // REFERENCIAS
    this.dxDataGrid_tblPersonasNParte_REF = React.createRef();
    this.dxDataGrid_tblRecambioNParteTrabajo_REF = React.createRef();
    this.dxPopover_REF = React.createRef();

    //DATASOURCE
    this.cargaDatos_lavanderia = this.cargaDatos_lavanderia.bind(this);
    this.datasource_datos_selector_load =
      this.datasource_datos_selector_load.bind(this);

    this.dataSource_tblCorporacion_onLoaded =
      this.dataSource_tblCorporacion_onLoaded.bind(this);
    this.dataSource_tblPais_onLoaded =
      this.dataSource_tblPais_onLoaded.bind(this);

    this.datasource_tblIncidencia_postProcess =
      this.datasource_tblIncidencia_postProcess.bind(this);

    this.datasource_tblTipoSubIncidencia_onLoading =
      this.datasource_tblTipoSubIncidencia_onLoading.bind(this);
    this.datasource_tblTipoSubIncidencia_postProcess =
      this.datasource_tblTipoSubIncidencia_postProcess.bind(this);

    this.datasource_tblPersona_Tecnicos_beforeSend =
      this.datasource_tblPersona_Tecnicos_beforeSend.bind(this);

    this.datasource_tblIncidencia_Documento_beforeSend =
      this.datasource_tblIncidencia_Documento_beforeSend.bind(this);

    this.datasource_tblParteTrabajo_beforeSend =
      this.datasource_tblParteTrabajo_beforeSend.bind(this);
    this.datasource_tblParteTrabajo_postProcess =
      this.datasource_tblParteTrabajo_postProcess.bind(this);

    this.datasource_tblMaquina_beforeSend =
      this.datasource_tblMaquina_beforeSend.bind(this);
    this.datasource_tblPesoIncidencia_beforeSend =
      this.datasource_tblPesoIncidencia_beforeSend.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //SELECTOR CORPORACIÓN
    this.getSelectorCorporacion = this.getSelectorCorporacion.bind(this);
    this.selectorCorporacion_onSelectionChanged =
      this.selectorCorporacion_onSelectionChanged.bind(this);

    //SELECTOR PAÍS
    this.getSelectorPais = this.getSelectorPais.bind(this);
    this.selectorPais_onSelectionChanged =
      this.selectorPais_onSelectionChanged.bind(this);

    //BACK BUTTON
    this.backButton_onClick = this.backButton_onClick.bind(this);

    //SELECTOR
    this.card_Click = this.card_Click.bind(this);

    //CATEGORÍAS MÁQUINA
    this.onSelectionChanged_gridCategoriasMaquina =
      this.onSelectionChanged_gridCategoriasMaquina.bind(this);
    this.dxDropdownBox_CategoriasMaq_render =
      this.dxDropdownBox_CategoriasMaq_render.bind(this);
    this.dxSelectBox_onValueChanged =
      this.dxSelectBox_onValueChanged.bind(this);
    this.itemTitleRender_graficasConsumos =
      this.itemTitleRender_graficasConsumos.bind(this);
    this.onInitialized_graficasConsumos =
      this.onInitialized_graficasConsumos.bind(this);
    this.itemRender_graficasConsumos =
      this.itemRender_graficasConsumos.bind(this);
    this.onContentReady_gridCategoriasMaquina =
      this.onContentReady_gridCategoriasMaquina.bind(this);
    this.onToolbarPreparing_gridCategoriasMaquina =
      this.onToolbarPreparing_gridCategoriasMaquina.bind(this);

    //INCIDENCIAS
    this.renderEditCell_modificarIncidencia =
      this.renderEditCell_modificarIncidencia.bind(this);
    this.onToolbarPreparing_gridIncidencias =
      this.onToolbarPreparing_gridIncidencias.bind(this);
    this.onRowClick_gridIncidencias =
      this.onRowClick_gridIncidencias.bind(this);
    this.onAdaptiveDetailRowPreparing_gridIncidencias =
      this.onAdaptiveDetailRowPreparing_gridIncidencias.bind(this);
    this.onAdaptiveDetailRowPreparing_gridParteTrabajo =
      this.onAdaptiveDetailRowPreparing_gridParteTrabajo.bind(this);

    //POPUP INCIDENCIAS
    this.hiding_popupIncidencias = this.hiding_popupIncidencias.bind(this);
    this.dxForm_incidencia_onFieldDataChanged =
      this.dxForm_incidencia_onFieldDataChanged.bind(this);
    this.onContentReady_popupIncidencias =
      this.onContentReady_popupIncidencias.bind(this);
    this.submit_formIncidencias = this.submit_formIncidencias.bind(this);

    //Upload FIle
    this.render_FileUploader = this.render_FileUploader.bind(this);
    this.onUploadStarted_FileUploader =
      this.onUploadStarted_FileUploader.bind(this);
    this.render_FileUploader_Item = this.render_FileUploader_Item.bind(this);
    this.hiding_popupGallery = this.hiding_popupGallery.bind(this);

    this.render_tblParteTrabajo = this.render_tblParteTrabajo.bind(this);
    this.calculateDisplayValue_tblIncidenciaNParte_estadoActual =
      this.calculateDisplayValue_tblIncidenciaNParte_estadoActual.bind(this);
    this.onEditingStart_tblParteTrabajo =
      this.onEditingStart_tblParteTrabajo.bind(this);
    this.onToolbarPreparing_tblParteTrabajo =
      this.onToolbarPreparing_tblParteTrabajo.bind(this);
    this.onRowRemoving_tblParteTrabajo =
      this.onRowRemoving_tblParteTrabajo.bind(this);
    this.onClick_btnAtras = this.onClick_btnAtras.bind(this);

    //PARTES DE TRABAJO
    this.onContentReady_form_parteTrabajo =
      this.onContentReady_form_parteTrabajo.bind(this);
    this.dxForm_partTrabajo_onFieldDataChanged =
      this.dxForm_partTrabajo_onFieldDataChanged.bind(this);
    this.render_tblPersonasNParte = this.render_tblPersonasNParte.bind(this);
    this.onToolbarPreparing_tblPersonasNParte =
      this.onToolbarPreparing_tblPersonasNParte.bind(this);
    this.submit_formParteTrabajo = this.submit_formParteTrabajo.bind(this);
    this.onCellHoverChanged_tblRecambioNAlmacenRecambios =
      this.onCellHoverChanged_tblRecambioNAlmacenRecambios.bind(this);

    this.hiding_popup_Add_tblRecambioNParteTrabajo =
      this.hiding_popup_Add_tblRecambioNParteTrabajo.bind(this);
    this.onToolbarPreparing_tblRecambioNParteTrabajo =
      this.onToolbarPreparing_tblRecambioNParteTrabajo.bind(this);
    this.render_tblRecambioNParteTrabajo =
      this.render_tblRecambioNParteTrabajo.bind(this);

    this.onToolbarPreparing_tblRecambioNParteTrabajoIBS =
      this.onToolbarPreparing_tblRecambioNParteTrabajoIBS.bind(this);
    this.onToolbarPreparing_tblServicioExternoNParteTrabajo =
      this.onToolbarPreparing_tblServicioExternoNParteTrabajo.bind(this);
    this.onInitNewRow_tblServicioExternoNParteTrabajo =
      this.onInitNewRow_tblServicioExternoNParteTrabajo.bind(this);
    this.screenByWidth_resolucion = this.screenByWidth_resolucion.bind(this);
    this.crearParteTrabajo = this.crearParteTrabajo.bind(this);

    //Add Técnicos
    this.hiding_popup_Add_tblPersonasNParte =
      this.hiding_popup_Add_tblPersonasNParte.bind(this);
    this.onShown_popup_Add_tblPersonasNParte =
      this.onShown_popup_Add_tblPersonasNParte.bind(this);
    this.getDataSource_Add_tblPersonasNParte =
      this.getDataSource_Add_tblPersonasNParte.bind(this);
    this.onClick_btnAddPersonasNParte =
      this.onClick_btnAddPersonasNParte.bind(this);

    //REPORT
    this.setParams = this.setParams.bind(this);
    this.setReport = this.setReport.bind(this);
    this.closeReport = this.closeReport.bind(this);
  }

  //REFERENCIAS
  get dxDataGrid_tblPersonasNParte() {
    return this.dxDataGrid_tblPersonasNParte_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNParteTrabajo() {
    return this.dxDataGrid_tblRecambioNParteTrabajo_REF.current.instance;
  }

  get dxPopover() {
    return this.dxPopover_REF.current.instance;
  }

  //#region DATSOURCES
  dataSource_monitorSAT_selector_Lavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "EF_monitorSAT_selector_Lavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblCorporacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCorporacion",
      key: "idCorporacion",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblCorporacion_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblCorporacion_onLoaded(data);
      },
      version: 4,
    }),
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblCorporacion_postProcess(data);
    },
  });
  dataSource_tblCorporacion_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblCorporacion_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idCorporacionSel: data[0].idCorporacion });
    }
  }
  dataSource_tblCorporacion_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idCorporacion: null, denominacion: "" });
    }
    return data;
  }

  dataSource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPais",
      key: "idPais",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPais_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_tblPais_onLoaded(data);
      },
      version: 4,
    }),
    expand: "tblTraduccion",
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.dataSource_tblPais_postProcess(data);
    },
  });
  dataSource_tblPais_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.conLavanderia = true;
  }
  dataSource_tblPais_onLoaded(data) {
    if (data.length === 1) {
      this.setState({ idPaisSel: data[0].idPais });
    }
  }
  dataSource_tblPais_postProcess(data) {
    if (data.length > 1) {
      data.splice(0, 0, { idPais: null, denominacion: "" });
    }
    return data;
  }

  dataSource_monitorSAT_selector_Maquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "EF_monitorSAT_selector_Maquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_monitorSAT_selector_CategoriaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "EF_monitorSAT_selector_CategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  datasource_datos_selector = new DataSource({
    paginate: false,
    store: new CustomStore({
      load: () => {
        this.datasource_datos_selector_load();
      },
    }),
  });
  datasource_datos_selector_load() {
    var _deferred = $.Deferred();
    var _component = this;
    $.when(
      _component.dataSource_monitorSAT_selector_Lavanderia.load(),
      _component.dataSource_monitorSAT_selector_Maquina.load(),
      _component.dataSource_monitorSAT_selector_CategoriaMaquina.load(),
    ).done(function () {
      var lavanderias =
        _component.dataSource_monitorSAT_selector_Lavanderia.items();
      var maquinas = _component.dataSource_monitorSAT_selector_Maquina.items();
      var categorias =
        _component.dataSource_monitorSAT_selector_CategoriaMaquina.items();

      var result = [];

      $.each(lavanderias, function (index, lav) {
        var lavanderia = $.extend(true, {}, lav);

        lavanderia.maquinas = $.grep(maquinas, function (maq) {
          return (
            lav.idLavanderia === maq.idLavanderia &&
            maq.numInciAmarillas + maq.numInciRojas > 0
          );
        });

        lavanderia.categoriasMaquina = $.grep(categorias, function (gr) {
          return lav.idLavanderia === gr.idLavanderia;
        });

        result.push(lavanderia);
      });

      //Agregamos el item TOTAL a categoriasMaquina para cada lavandería, y le damos nombre a las máquinas sin agrupación
      $.each(result, function (index, lav) {
        let isError = 0;
        $.each(lav.categoriasMaquina, function (index, gr) {
          if (gr.idCategoriaMaquina === -1) {
            gr.denominacion = getTrad("maquinasSinCategoria").toUpperCase();
            gr.operatividadCategoria = -1;
          }
          if (gr.error === 1) isError = 1;
          gr.color_operatividadCategoria = _component.getColor_operatividad(
            gr.operatividadCategoria,
          );
        });

        lav.categoriasMaquina.unshift({
          idLavanderia: lav.idLavanderia,
          idCategoriaMaquina: null,
          denominacion: getTrad("todos").toUpperCase(),
          numInciAmarillas: lav.numInciAmarillas,
          numInciRojas: lav.numInciRojas,
          operatividadCategoria: lav.operatividadLav,
          color_operatividadCategoria: _component.getColor_operatividad(
            lav.operatividadLav,
          ),
          error: isError,
        });
      });

      let { lavanderia } = _component.props;
      let lavanderiaSel = result.find(
        (x) => x.idLavanderia === lavanderia.idLavanderia,
      );

      let categoriasMaq = lavanderiaSel.categoriasMaquina.sort(function (a, b) {
        var aName = a.denominacion.toLowerCase();
        var bName = b.denominacion.toLowerCase();
        if (a.idCategoriaMaquina == null) return -1;
        else if (b.idCategoriaMaquina == null) return 1;
        return aName < bName ? -1 : aName > bName ? 1 : 0;
      });

      let { idCorporacionSel, idPaisSel } = _component.state;

      let resultFilter = result.filter(
        (x) =>
          (!idPaisSel || x.idPais === idPaisSel) &&
          (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
      );
      _component.setState({
        datos_selector: result,
        datos_selector_filtrados: resultFilter,
        categoriasMaquina_LavSel: categoriasMaq,
      });
      _component.loadPanel_hide();
      _deferred.resolve(result);
    });
    return _deferred.promise();
  }

  //#region RECURSOS ENERGETICOS

  dataSource_spSelectOperativo_DiasAnteriores = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectOperativo_DiasAnteriores",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_spSelectOperativo_DiasAnteriores_beforeSend(request);
      },
      onLoaded: (data) => {
        this.dataSource_spSelectOperativo_DiasAnteriores_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_spSelectOperativo_DiasAnteriores_beforeSend(request) {
    request.headers = { ...authHeader() };

    const { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.numDias = 8;
  }
  dataSource_spSelectOperativo_DiasAnteriores_onLoaded(data) {
    const { numDias } = this.state;
    let objFinal = {};

    //CHART Rendimiento
    $.each(data, function (index, item) {
      item.kgHorasProd =
        item.kgProcesado > 0 ? item.kgProcesado / item.horasProductivas : 0;
    });

    //KPIS
    objFinal.kpi_agua = data[0].kpi_agua;
    objFinal.kpi_electricidad = data[0].kpi_electricidad;
    objFinal.kpi_calefaccion = data[0].kpi_calefaccion;

    //DIA DE AYER
    let ayer = data[data.length - 1];
    objFinal.ayer_procesado = ayer.kgProcesado;
    objFinal.ayer_agua = ayer.agua / ayer.kgProcesado;
    objFinal.ayer_electricidad = ayer.electricidad / ayer.kgProcesado;
    objFinal.ayer_calefaccion = ayer.calefaccion / ayer.kgProcesado;

    //MEDIA DIAS ANTERIORES

    objFinal.media_procesado = 0;
    objFinal.media_agua = 0;
    objFinal.media_electricidad = 0;
    objFinal.media_calefaccion = 0;

    const step = (objFinal, itemData) => {
      if (itemData.kgProcesado > 0) {
        objFinal.media_procesado =
          objFinal.media_procesado + itemData.kgProcesado;
        objFinal.media_agua = objFinal.media_agua + itemData.agua;
        objFinal.media_electricidad =
          objFinal.media_electricidad + itemData.electricidad;
        objFinal.media_calefaccion =
          objFinal.media_calefaccion + itemData.calefaccion;
      }

      return objFinal;
    };
    const finalize = (objFinal) => {
      if (objFinal.media_procesado > 0) {
        objFinal.media_agua = objFinal.media_agua / objFinal.media_procesado;
        objFinal.media_electricidad =
          objFinal.media_electricidad / objFinal.media_procesado;
        objFinal.media_calefaccion =
          objFinal.media_calefaccion / objFinal.media_procesado;
        objFinal.media_procesado = objFinal.media_procesado / numDias;
      }

      return objFinal;
    };
    query(data)
      .aggregate(objFinal, step, finalize)
      .then((result) => {
        this.setState({ datos_Anteriores: result });
        this.loadPanel_hide();
      });

    return data;
  }

  //#endregion

  //#region INCIDECNIAS

  datasource_tblSistemaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblSistemaMaquina",
      key: "idSistemaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblSistemaMaquina_beforeSend(request);
      },
      version: 4,
    }),
    sort: "orden",
    postProcess: (data) => {
      return this.datasource_tblSistemaMaquina_postProcess(data);
    },
  });

  datasource_tblSistemaMaquina_postProcess(data) {
    data.push({
      idSistemaMaquina: -1,
      denominacion: getTrad("sinCategoria").toUpperCase(),
    });
    return data;
  }

  datasource_tblSistemaMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblIncidencia = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblIncidencia",
      key: "idIncidencia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblIncidencia_beforeSend(request);
      },
      updated: function () {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      onLoading: function (loadOptions) {
        if (loadOptions.filter) {
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            ["tblTipoSubIncidencia/idTipoIncidencia", "=", 3],
            "and",
            ["tblMaquina/activo", "=", true],
            "and",
            ["tblMaquina/eliminado", "=", false],
          ];
        } else {
          loadOptions.filter = [
            ["tblTipoSubIncidencia/idTipoIncidencia", "=", 3],
            "and",
            ["tblMaquina/activo", "=", true],
            "and",
            ["tblMaquina/eliminado", "=", false],
          ];
        }
      },
      onInserted: function () {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      onUpdated: function () {
        notify({
          message: getTrad("aviso_C_RegistroActualizado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    select: [
      "idIncidencia",
      "codigo",
      "idLavanderia",
      "estado",
      "fechaCierre",
      "fechaIncidencia",
      "descripcionIncidencia",
      "descripcionResolucion",
      "idSubTipoIncidencia",
      "idUsuarioCrea",
      "idUsuarioResponsable",
      "idUsuarioRevisor",
      "isInformativa",
      "isRevisada",
      "idEntidad",
      "idMaquina",
      "estadoMaquina",
      "idUsuarioAfecta",
      "idVehiculo",
      "idPoliticaDisciplinaria",
      "peso",
    ],
    expand: [
      "tblUsuarioCrea($select = nombre)",
      "tblUsuarioRevisor($select = nombre)",
      "tblAccionesCorrectivas",
      "tblPersona_responsable($select=idPersona,nombre,apellidos)",
      "tblMaquina($expand=tblTipoMaquinaNCategoriaMaquina)",
      "tblIncidenciaNParte($select=tblParteTrabajo;$orderby=tblParteTrabajo/fecha desc;$top=1;$expand=tblParteTrabajo($select=fecha;$expand=tblRecambioNParteTrabajo($expand=tblRecambio($select=denominacion,referencia),tblAlmacenRecambios($select=idAlmacen,idAlmacenPadre;$expand=tblMoneda($select=codigo)))))",
    ],
    postProcess: (data) => {
      return this.datasource_tblIncidencia_postProcess(data);
    },
  });
  datasource_tblIncidencia_beforeSend(request) {
    request.headers = { ...authHeader() };
    const { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }
  datasource_tblIncidencia_postProcess(data) {
    $.each(data, function (index, incidencia) {
      incidencia.fechaUltimoParte =
        incidencia.tblIncidenciaNParte &&
        incidencia.tblIncidenciaNParte.length > 0
          ? incidencia.tblIncidenciaNParte[0].tblParteTrabajo.fecha
          : null;
      delete incidencia.tblIncidenciaNParte;
    });

    return data;
  }

  datasource_tblTipoSubIncidencia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoSubIncidencia",
      key: "idSubTipoIncidencia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      onLoading: (loadOptions) => {
        this.datasource_tblTipoSubIncidencia_onLoading(loadOptions);
      },
      version: 4,
    }),
    filter: ["idTipoIncidencia", "=", 3],
    sort: "denominacion",
    select: ["idSubTipoIncidencia", "idTipoIncidencia"],
    postProcess: (data) => {
      return this.datasource_tblTipoSubIncidencia_postProcess(data);
    },
  });
  datasource_tblTipoSubIncidencia_onLoading(loadOptions) {
    const { idioma } = this.props;
    loadOptions.expand = "tblTraduccion($select=" + idioma.codigo + ")";
  }
  datasource_tblTipoSubIncidencia_postProcess(data) {
    const { idioma } = this.props;
    $.each(data, function (index, tipoSubIncidencia) {
      tipoSubIncidencia.denominacion =
        tipoSubIncidencia.tblTraduccion[idioma.codigo].toUpperCase();
    });

    this.idSubTipoIncidencia_editorOptions.items = data;

    return data;
  }

  idIncidenciaSel = null;
  datasource_tblIncidencia_Documento = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblIncidencia_Documento",
      key: "idSubTipoIncidencia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblIncidencia_Documento_beforeSend(request);
      },
      version: 4,
    }),
  });
  datasource_tblIncidencia_Documento_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.idIncidencia = this.idIncidenciaSel;
  }

  datasource_tblParteTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblParteTrabajo",
      key: "idParteTrabajo",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblParteTrabajo_beforeSend(request);
      },
      onInserting: (values) => {
        this.datasource_tblParteTrabajo_onInserting(values);
      },
      onInserted: () => {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      onUpdating: (key, values) => {
        this.datasource_tblParteTrabajo_onUpdating(key, values);
      },
      onUpdated: () => {
        notify({
          message: getTrad("aviso_C_RegistroActualizado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      onRemoved: () => {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    expand: [
      "tblPersonasNParte",
      "tblRecambioNParteTrabajoIBS",
      "tblServicioExternoNParteTrabajo",
      "tblRecambioNParteTrabajo($expand=idRecambioNavigation($select=denominacion,referencia),idAlmacenNavigation($select=idAlmacen,idAlmacenPadre,denominacion;$expand=idMonedaNavigation($select=codigo)))",
      "tblIncidenciaNParte",
    ],
    postProcess: (data) => {
      return this.datasource_tblParteTrabajo_postProcess(data);
    },
  });
  datasource_tblParteTrabajo_beforeSend(request) {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      request.params.idIncidencia = this.idIncidenciaSel;
    }
  }

  datasource_tblParteTrabajo_onInserting = (values) => {
    $.each(values.tblPersonasNParte, (index, pers) => {
      pers.horas = datetimeToDuration(pers.horas);
    });
  };

  datasource_tblParteTrabajo_onUpdating = (key, values) => {
    $.each(
      values.find((item) => item.path === "/tblPersonasNParte").value,
      (index, pers) => {
        pers.horas = dateTime_hourMinute(new Date(pers.horas));
      },
    );
  };

  datasource_tblParteTrabajo_postProcess(data) {
    $.each(data, function (index, item) {
      $.each(item.tblPersonasNParte, function (index, pers) {
        if (!(pers.horas instanceof Date)) {
          pers.horas = durationToDatetime(pers.horas);
        }
      });
    });
    return data;
  }

  datasource_tblPersona_Tecnicos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_Tecnicos_beforeSend(request);
      },
      version: 4,
      onLoading: (loadOptions) => {
        this.datasource_tblPersona_Tecnicos_onLoading(loadOptions);
      },
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
    map: (itemData) => {
      return this.datasource_tblPersona_Tecnicos_map(itemData);
    },
  });

  datasource_tblPersona_Tecnicos_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = false;
  }
  datasource_tblPersona_Tecnicos_onLoading(loadOptions) {
    let { lavanderia } = this.props;
    let filtroLavanderia =
      "idLavanderia eq " +
      lavanderia.idLavanderia +
      " or tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
      lavanderia.idLavanderia +
      "))";
    if (typeof loadOptions.filter === "undefined") {
      loadOptions.filter = [
        [
          ["idCategoria", "=", 4],
          "OR",
          ["idCategoria", "=", 13],
          "OR",
          ["idCategoria", "=", 19],
          "OR",
          ["idCategoriaInterna", "=", 34],
          "OR",
          ["idCategoriaInterna", "=", 35],
          "OR",
          ["idCategoriaInterna", "=", 38],
          "OR",
          ["idCategoriaInterna", "=", 39],
        ],
        "AND",
        ["activo", "=", true],
        "AND",
        ["eliminado", "=", false],
        "AND",
        [filtroLavanderia],
      ];
    }
  }
  datasource_tblPersona_Tecnicos_map(itemData) {
    return {
      idPersona: itemData.idPersona,
      nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
    };
  }

  datasource_tblPesoIncidencia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPesoIncidencia",
      key: "idPeso",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPesoIncidencia_beforeSend(request);
      },
      version: 4,
    }),
    sort: "valor",
    select: ["idPeso", "valor"],
  });

  datasource_tblPesoIncidencia_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblMaquina_beforeSend(request);
      },
      version: 4,
    }),
    expand: ["tblTipoMaquinaNCategoriaMaquina($select=idCategoriaMaquina)"],
    sort: "denominacion",
    select: [
      "idMaquina",
      "denominacion",
      "etiqueta",
      "numSerie",
      "tblTipoMaquinaNCategoriaMaquina",
      "activo",
    ],
  });

  datasource_tblMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
    const { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  enum_estadosMaquina = [
    {
      estadoMaquina: 0,
      denominacion: getTrad("noParada").toUpperCase(),
      color: "var(--primary)",
      visible: false,
    },
    {
      estadoMaquina: 1,
      denominacion: getTrad("noParada").toUpperCase(),
      color: "var(--primary)",
    },
    {
      estadoMaquina: 2,
      denominacion: getTrad("parada").toUpperCase(),
      color: "var(--danger)",
    },
  ];

  enum_estadoIncidencia = [
    {
      estado: true,
      denominacion: getTrad("cerrada").toUpperCase(),
    },
    {
      estado: false,
      denominacion: getTrad("abierta").toUpperCase(),
    },
  ];

  enum_EstadoParteTrabajo = [
    {
      estado: getTrad("ok").toUpperCase(),
      valor: 0,
      color: "#1BD319",
    },
    {
      estado: getTrad("noParada").toUpperCase(),
      valor: 1,
      color: "#FEF161",
    },
    {
      estado: getTrad("parada").toUpperCase(),
      valor: 2,
      color: "#FE3814",
    },
  ];

  ODataContext_tblIncidencia = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblIncidencia",
    entities: {
      Incidencias: {
        key: "idInventario",
        keyType: "Int32",
      },
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
  });

  //#endregion

  datasource_tblAlmacenRecambiosNPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambiosNPersona",
      key: ["idAlmacen", "idPersona"],
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblAlmacenRecambiosNPersona_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblAlmacenRecambiosNPersona_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { user } = this.props;
    request.params.idPersona = user.idPersona !== null ? user.idPersona : -1;
  }

  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    let { lavanderia } = this.props;
    let { datos_selector } = this.state;
    let lavanderiaSel = datos_selector.find(
      (x) => x.idLavanderia === lavanderia.idLavanderia,
    );
    _this.datasource_tblAlmacenRecambiosNPersona.load().done(function () {
      $.when(
        _this.dataSource_spSelectOperativo_DiasAnteriores.load(),
        _this.datasource_tblTipoSubIncidencia.load(),
        _this.datasource_tblMaquina.load(),
        _this.datasource_tblPersona_Tecnicos.reload(),
        _this.datasource_tblPesoIncidencia.load(),
        _this.datasource_tblSistemaMaquina.load(),
      ).then(function () {
        let itemsMaquinas = _this.datasource_tblMaquina.items();
        _this.idMaquina_editorOptions.dataSource = itemsMaquinas;

        _this.datasource_tblIncidencia.reload().done(function () {
          let categoriasMaq = lavanderiaSel.categoriasMaquina.sort(
            function (a, b) {
              var aName = a.denominacion.toLowerCase();
              var bName = b.denominacion.toLowerCase();
              if (a.idCategoriaMaquina == null) return -1;
              else if (b.idCategoriaMaquina == null) return 1;
              return aName < bName ? -1 : aName > bName ? 1 : 0;
            },
          );
          _this.setState({
            loadingData_multiview_page2: false,
            categoriasMaquina_LavSel: categoriasMaq,
          });
        });
      });
    });
  }

  //#region SELECTOR CORPORACIÓN
  getSelectorCorporacion() {
    const { idioma } = this.props;
    let { idCorporacionSel, dxMultiView_index } = this.state;
    let is_corpUnica = this.dataSource_tblCorporacion.items().length === 1;
    return (
      <SelectBox
        key={"selectBox_1"}
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblCorporacion}
        displayExpr={function (item) {
          if (item && item.denominacion) {
            return item.denominacion.toUpperCase();
          } else if (item) {
            return getTrad("todasCorporaciones");
          } else {
            return;
          }
        }}
        disabled={is_corpUnica}
        valueExpr="idCorporacion"
        value={idCorporacionSel}
        defaultValue={idCorporacionSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todasCorporaciones")}
        onSelectionChanged={this.selectorCorporacion_onSelectionChanged}
      />
    );
  }
  selectorCorporacion_onSelectionChanged(e) {
    let { idPaisSel } = this.state;

    let idCorporacionSel = e.selectedItem.idCorporacion;
    let items = this.state.datos_selector;

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );
    this.setState({
      idCorporacionSel: idCorporacionSel,
      datos_selector_filtrados: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //#region SELECTOR PAÍS
  getSelectorPais() {
    const { idioma } = this.props;
    let { idPaisSel, dxMultiView_index } = this.state;
    let is_paisUnico = this.dataSource_tblPais.items().length === 1;

    return (
      <SelectBox
        key={"selectBox_2"}
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblPais}
        displayExpr={function (item) {
          if (item && item.tblTraduccion) {
            return item.tblTraduccion[idioma.codigo].toUpperCase();
          } else if (item) {
            return getTrad("todosPaises");
          } else {
            return;
          }
        }}
        disabled={is_paisUnico}
        valueExpr="idPais"
        value={idPaisSel}
        defaultValue={idPaisSel}
        visible={dxMultiView_index === 0}
        width="100%"
        stylingMode="underlined"
        placeholder={getTrad("todosPaises")}
        onSelectionChanged={this.selectorPais_onSelectionChanged}
      />
    );
  }
  selectorPais_onSelectionChanged(e) {
    let idPaisSel = e.selectedItem.idPais;
    let { idCorporacionSel } = this.state;
    let items = this.state.datos_selector;

    let datosSelector_Lavanderia_Filtrado = items.filter(
      (x) =>
        (!idPaisSel || x.idPais === idPaisSel) &&
        (!idCorporacionSel || x.idCorporacion === idCorporacionSel),
    );

    this.setState({
      idPaisSel: idPaisSel,
      datos_selector_filtrados: datosSelector_Lavanderia_Filtrado,
    });
  }
  //#endregion

  //BACK BUTTON
  getBackButton() {
    return (
      <Fragment>
        <div
          id="backButton"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_onClick}
        />
        <Tooltip
          target="#backButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("volverLavanderia")}</div>
        </Tooltip>
      </Fragment>
    );
  }
  backButton_onClick() {
    this.datasource_datos_selector.load();
    this.setState({
      dxMultiView_index: 0,
      maquinaSel: null,
      categoriasMaquina_LavSel: [],
    });
  }

  //REPORTS
  setParams(params) {
    this.props.setParams(params);
  }

  setReport(report) {
    this.props.setReport(report);
  }

  closeReport() {
    this.props.close();
  }

  //LOADING
  getLoading(conTexto) {
    return (
      <div className="loader-container">
        <div className="loader-container-inner">
          <div className="text-center">
            <Loader type="line-scale" />
          </div>
          {conTexto ? (
            <h6 className="mt-3" style={{ color: "var(--secondary)" }}>
              Cargando
            </h6>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  render() {
    let {
      datos_selector_filtrados,
      categoriasMaquina_LavSel,
      dxMultiView_index,
      datos_Anteriores,
      maquinaSel,
      categoriaMaquinaSel,
      incidenciaSel,
      parteTrabajoSel,
      loadingData_multiview_page2,
      showPopup_Add_tblPersonasNParte,
      showPopup_Add_tblRecambioNParteTrabajo,
    } = this.state;
    let { resolucion, lavanderia } = this.props;

    let _this = this;
    let is_incidencia_noParada =
      incidenciaSel != null && parteTrabajoSel === null
        ? incidenciaSel.estadoMaquina !== 2 &&
          incidenciaSel.estadoMaquina != null
        : null;

    let groupedData = Query(datos_selector_filtrados).groupBy("pais").toArray();
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          preHeading={dxMultiView_index === 1 ? this.getBackButton() : ""}
          postHeading={[this.getSelectorCorporacion(), this.getSelectorPais()]}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="MonitorSat" className="formContainer scrollbar-container">
            <MultiView
              height="100%"
              width="100%"
              selectedIndex={dxMultiView_index}
              loop={true}
              swipeEnabled={false}
              deferRendering={false}
              animationEnabled={true}
            >
              <MultiViewItem>
                <ScrollView
                  id="dxScrollView_multiView"
                  className={
                    "page-1 cardContent" +
                    (get_isMovil(resolucion) ? " vMovil" : "")
                  }
                >
                  <div>
                    {
                      <div id="CardSelector">
                        <div>
                          {$.map(groupedData, function (group) {
                            return (
                              <Fragment key={"grupo" + group.key}>
                                <div className="groupTitle font-size-xl p-3 mb-3">
                                  {group.key.toUpperCase()}
                                </div>
                                <div className="row">
                                  {$.map(group.items, function (lavanderia) {
                                    return (
                                      <div
                                        key={lavanderia.idLavanderia}
                                        className="w-100 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4"
                                      >
                                        <div className="row-1 he-100 mb-3">
                                          <Card
                                            className={
                                              "card-btm-border d-flex he-100 hover " +
                                              (!get_isMovil(resolucion)
                                                ? "zoom "
                                                : "") +
                                              (lavanderia.numInciRojas > 0
                                                ? "border-danger"
                                                : lavanderia.numInciAmarillas >
                                                    0
                                                  ? "border-primary"
                                                  : "border-success")
                                            }
                                          >
                                            <CardHeader
                                              className={
                                                "card-header-tab pointer"
                                              }
                                              style={
                                                get_isMovil(resolucion)
                                                  ? { paddingRight: "0.12rem" }
                                                  : { paddingRight: "0.42rem" }
                                              }
                                              onClick={() => {
                                                _this.card_Click(
                                                  lavanderia,
                                                  null,
                                                );
                                              }}
                                            >
                                              <Row className="container_spanCentrado w-100">
                                                <Col className="w-100 pl-2 pr-3 text-truncate text-left">
                                                  {lavanderia.denominacion.toUpperCase()}
                                                </Col>
                                                {lavanderia.numInciRojas > 0 ||
                                                lavanderia.numInciAmarillas >
                                                  0 ? (
                                                  <Col
                                                    xs="3"
                                                    className="d-flex justify-content-end p-0"
                                                  >
                                                    {lavanderia.numInciRojas >
                                                    0 ? (
                                                      <div
                                                        className="p-2 mr-1 font-size-md"
                                                        style={{
                                                          backgroundColor:
                                                            "var(--danger)",
                                                          color: "white",
                                                          borderRadius: "5px",
                                                        }}
                                                      >
                                                        {
                                                          lavanderia.numInciRojas
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                    {lavanderia.numInciAmarillas >
                                                    0 ? (
                                                      <div
                                                        className="p-2 pl-2 mr-1 font-size-md"
                                                        style={{
                                                          backgroundColor:
                                                            "var(--warning)",
                                                          color: "white",
                                                          borderRadius: "5px",
                                                        }}
                                                      >
                                                        {
                                                          lavanderia.numInciAmarillas
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </Col>
                                                ) : (
                                                  ""
                                                )}
                                              </Row>
                                            </CardHeader>
                                            <CardBody
                                              className="pt-0 pb-0"
                                              style={{ height: "0px" }}
                                            >
                                              <div className="widget-content pl-0 pr-0 he-100">
                                                <div className="widget-content-outer he-100">
                                                  <Row className="flex-fill he-100">
                                                    <div className="widget-content-wrapper w-100 he-100">
                                                      <Col
                                                        xs="3"
                                                        className="he-100 p-0 ml-2"
                                                      >
                                                        <Fragment>
                                                          <CircularGauge
                                                            id="CircularGauge_selector"
                                                            value={0}
                                                          >
                                                            <Scale
                                                              startValue={0}
                                                              endValue={1}
                                                            >
                                                              <Tick
                                                                visible={false}
                                                              />
                                                              <Label
                                                                visible={false}
                                                              />
                                                            </Scale>
                                                            <Geometry
                                                              startAngle={90}
                                                              endAngle={90}
                                                            ></Geometry>
                                                            <RangeContainer
                                                              width={8}
                                                              backgroundColor="lightgrey"
                                                              offset={10}
                                                            >
                                                              <Range
                                                                startValue={0}
                                                                endValue={
                                                                  lavanderia.operatividadLav
                                                                }
                                                                color="var(--success)"
                                                              />
                                                              <Range
                                                                startValue={
                                                                  lavanderia.operatividadLav
                                                                }
                                                                endValue={
                                                                  lavanderia.operatividadLav +
                                                                  lavanderia.operatividadLav_amarillas
                                                                }
                                                                color="var(--primary)"
                                                              />
                                                              <Range
                                                                startValue={
                                                                  lavanderia.operatividadLav +
                                                                  lavanderia.operatividadLav_amarillas
                                                                }
                                                                endValue={1}
                                                                color="var(--danger)"
                                                              />
                                                            </RangeContainer>
                                                            <ValueIndicator
                                                              type="rangebar"
                                                              offset={0}
                                                              size={10}
                                                            ></ValueIndicator>
                                                            <Margin right={5} />
                                                          </CircularGauge>
                                                          <div
                                                            className="container_spanCentrado position-absolute w-100 he-100"
                                                            style={{
                                                              top: "0px",
                                                              right: "2px",
                                                            }}
                                                          >
                                                            <div
                                                              className={_this.get_responsiveFont_gauge()}
                                                            >
                                                              {lavanderia.numInciRojas >
                                                                0 ||
                                                              lavanderia.numInciAmarillas >
                                                                0 ||
                                                              lavanderia.operatividadLav ===
                                                                0 ? (
                                                                formatNumber(
                                                                  lavanderia.operatividadLav,
                                                                  0,
                                                                  "percent",
                                                                )
                                                              ) : (
                                                                <div className="icon_Check success font-size-max"></div>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </Fragment>
                                                      </Col>

                                                      <Col
                                                        xs="9"
                                                        className="he-100"
                                                      >
                                                        {lavanderia.numInciRojas >
                                                          0 ||
                                                        lavanderia.numInciAmarillas >
                                                          0 ? (
                                                          <ScrollView className="degradadoLista">
                                                            <div>
                                                              {lavanderia.maquinas.map(
                                                                (item) => {
                                                                  return (
                                                                    <Row
                                                                      key={
                                                                        item.idMaquina
                                                                      }
                                                                      className="container_spanCentrado p-2 pointer divider-bottom"
                                                                      onClick={(
                                                                        e,
                                                                      ) => {
                                                                        _this.card_Click(
                                                                          lavanderia,
                                                                          item,
                                                                        );
                                                                      }}
                                                                    >
                                                                      <Col className="w-100 pl-2 pr-3 text-truncate">
                                                                        {item.denominacion.toUpperCase()}
                                                                      </Col>
                                                                      {item.numInciRojas >
                                                                        0 ||
                                                                      item.numInciAmarillas >
                                                                        0 ? (
                                                                        <Col
                                                                          xs="3"
                                                                          className="d-flex justify-content-end p-0 pr-2"
                                                                        >
                                                                          {item.numInciRojas >
                                                                          0 ? (
                                                                            <div
                                                                              className="pr-2 pl-2 mr-2 font-size-md"
                                                                              style={{
                                                                                backgroundColor:
                                                                                  "var(--danger)",
                                                                                color:
                                                                                  "white",
                                                                                borderRadius:
                                                                                  "5px",
                                                                              }}
                                                                            >
                                                                              {
                                                                                item.numInciRojas
                                                                              }
                                                                            </div>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                          {item.numInciAmarillas >
                                                                          0 ? (
                                                                            <div
                                                                              className="pr-2 pl-2 mr-2 font-size-md"
                                                                              style={{
                                                                                backgroundColor:
                                                                                  "var(--warning)",
                                                                                color:
                                                                                  "white",
                                                                                borderRadius:
                                                                                  "5px",
                                                                              }}
                                                                            >
                                                                              {
                                                                                item.numInciAmarillas
                                                                              }
                                                                            </div>
                                                                          ) : (
                                                                            ""
                                                                          )}
                                                                        </Col>
                                                                      ) : (
                                                                        ""
                                                                      )}
                                                                    </Row>
                                                                  );
                                                                },
                                                              )}
                                                            </div>
                                                          </ScrollView>
                                                        ) : (
                                                          <div className="w-100 he-100 container_spanCentrado">
                                                            <div className="font-size-xxl">
                                                              SIN INCIDENCIAS
                                                            </div>
                                                          </div>
                                                        )}
                                                      </Col>
                                                    </div>
                                                  </Row>
                                                </div>
                                              </div>
                                            </CardBody>
                                          </Card>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </Fragment>
                            );
                          })}
                        </div>
                      </div>
                    }
                  </div>
                </ScrollView>
              </MultiViewItem>
              <MultiViewItem>
                {dxMultiView_index === 1 ? (
                  <Fragment>
                    <ScrollView
                      height={"100%"}
                      id="dxScrollView_multiView"
                      className={
                        "he-100 page-2 cardContent" +
                        (get_isMovil(resolucion) ? " vMovil" : "")
                      }
                    >
                      {get_isMovil(resolucion) ? (
                        <div>
                          <Accordion
                            id="graficasConsumos"
                            dataSource={[
                              {
                                agua: {
                                  ayer:
                                    datos_Anteriores.ayer_agua != null
                                      ? datos_Anteriores.ayer_agua.toFixed(2)
                                      : null,
                                  kpi:
                                    datos_Anteriores.kpi_agua != null
                                      ? datos_Anteriores.kpi_agua.toFixed(2)
                                      : null,
                                  media:
                                    datos_Anteriores.media_agua != null
                                      ? datos_Anteriores.media_agua.toFixed(2)
                                      : null,
                                  icono: "icon_Agua",
                                  titulo: "Agua".toUpperCase(),
                                },
                                calefaccion: {
                                  ayer:
                                    datos_Anteriores.ayer_calefaccion != null
                                      ? datos_Anteriores.ayer_calefaccion.toFixed(
                                          2,
                                        )
                                      : null,
                                  kpi:
                                    datos_Anteriores.kpi_calefaccion != null
                                      ? datos_Anteriores.kpi_calefaccion.toFixed(
                                          2,
                                        )
                                      : null,
                                  media:
                                    datos_Anteriores.media_calefaccion != null
                                      ? datos_Anteriores.media_calefaccion.toFixed(
                                          2,
                                        )
                                      : null,
                                  icono: "icon_Calefaccion",
                                  titulo: "Calefacción".toUpperCase(),
                                },
                                electricidad: {
                                  ayer:
                                    datos_Anteriores.ayer_electricidad != null
                                      ? datos_Anteriores.ayer_electricidad.toFixed(
                                          2,
                                        )
                                      : null,
                                  kpi:
                                    datos_Anteriores.kpi_electricidad != null
                                      ? datos_Anteriores.kpi_electricidad.toFixed(
                                          2,
                                        )
                                      : null,
                                  media:
                                    datos_Anteriores.media_electricidad != null
                                      ? datos_Anteriores.media_electricidad.toFixed(
                                          2,
                                        )
                                      : null,
                                  icono: "icon_Electricidad",
                                  titulo: "Electricidad".toUpperCase(),
                                },
                              },
                            ]}
                            onContentReady={this.onInitialized_graficasConsumos}
                            itemTitleRender={
                              this.itemTitleRender_graficasConsumos
                            }
                            itemRender={this.itemRender_graficasConsumos}
                            animationDuration={300}
                            collapsible={true}
                            multiple={false}
                          />
                        </div>
                      ) : (
                        <div>
                          <Row id="graficasConsumos">
                            <Col
                              xs="12"
                              md="6"
                              lg="4"
                              className="row-1 he-100 mb-3"
                            >
                              <Card
                                className={
                                  "card-btm-border d-flex he-100 " +
                                  (datos_Anteriores.ayer_agua >=
                                  datos_Anteriores.kpi_agua
                                    ? "border-danger"
                                    : "border-success")
                                }
                              >
                                <CardHeader className="card-header-tab">
                                  <Row className="container_spanCentrado w-100">
                                    <Col className="w-100 pl-2 pr-3 text-truncate">
                                      {"AGUA"}
                                    </Col>
                                    <Col
                                      xs="3"
                                      className="d-flex justify-content-end p-0"
                                    >
                                      <div className="font-size-xxl icon_Agua" />
                                    </Col>
                                  </Row>
                                </CardHeader>
                                {!loadingData_multiview_page2 ? (
                                  <CardBody className="pt-0 pb-0">
                                    {/* style={{ height: "0px" }} */}
                                    <div className="widget-content pl-0 pr-0 pt-0 pb-1 he-100">
                                      <div className="widget-content-outer he-100">
                                        <Row className="he-100">
                                          <Col xs="8" md="6" lg="8">
                                            <div className="widget-content-wrapper he-100">
                                              <CircularGauge
                                                id="CircularGauge_recursos"
                                                value={
                                                  datos_Anteriores.media_agua
                                                }
                                              >
                                                <Margin bottom={5} />
                                                <Scale
                                                  startValue={
                                                    datos_Anteriores.kpi_agua *
                                                    0.25
                                                  }
                                                  endValue={
                                                    datos_Anteriores.kpi_agua *
                                                    1.5
                                                  }
                                                  tickInterval={
                                                    (((datos_Anteriores.kpi_agua *
                                                      1.5) /
                                                      8) *
                                                      100) /
                                                    100
                                                  }
                                                >
                                                  <Label
                                                    customizeText={
                                                      this
                                                        .customizeText_recursosEnergeticos
                                                    }
                                                  />
                                                  <Tick length={6} />
                                                </Scale>
                                                <Geometry
                                                  startAngle={180}
                                                  endAngle={0}
                                                ></Geometry>
                                                <RangeContainer
                                                  width={15}
                                                  offset={14}
                                                  backgroundColor="var(--danger)"
                                                >
                                                  <Range
                                                    color="var(--green)"
                                                    startValue={
                                                      datos_Anteriores.kpi_agua *
                                                      0.25
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_agua
                                                    }
                                                  />
                                                  <Range
                                                    color="#d8ea4b"
                                                    startValue={
                                                      datos_Anteriores.kpi_agua
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_agua *
                                                      1.1
                                                    }
                                                  />
                                                </RangeContainer>
                                                <ValueIndicator
                                                  type="triangleNeedle"
                                                  color="#575757"
                                                  width={8}
                                                  offset={15}
                                                ></ValueIndicator>
                                              </CircularGauge>
                                            </div>
                                          </Col>
                                          <Col
                                            xs="4"
                                            md="6"
                                            lg="4"
                                            className="divider-left mt-3 mb-3"
                                          >
                                            <div className="widget-content-wrapper he-100 flex-column justify-content-around">
                                              <div className="w-100">
                                                <div className="widget-title opacity-5 text-uppercase">
                                                  8 DÍAS
                                                </div>
                                                <div className="font-size-xxxl font-weight-bold text-center">
                                                  {formatNumber(
                                                    datos_Anteriores.media_agua,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                              <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                  <div className="widget-title opacity-5 text-uppercase flex-fill">
                                                    AYER
                                                  </div>
                                                  <div
                                                    className={
                                                      "ml-3 font-size-xxl " +
                                                      (datos_Anteriores.ayer_agua >=
                                                      datos_Anteriores.kpi_agua
                                                        ? "icon_ArrowUp danger"
                                                        : "icon_ArrowDown success")
                                                    }
                                                  />
                                                </div>
                                                <div className="font-size-xxxl text-center text-cut font-weight-bold">
                                                  {formatNumber(
                                                    datos_Anteriores.ayer_agua,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </CardBody>
                                ) : (
                                  this.getLoading(false)
                                )}
                              </Card>
                            </Col>
                            <Col
                              xs="12"
                              md="6"
                              lg="4"
                              className="row-1 he-100 mb-3"
                            >
                              <Card
                                className={
                                  "card-btm-border d-flex he-100 " +
                                  (datos_Anteriores.ayer_calefaccion >=
                                  datos_Anteriores.kpi_calefaccion
                                    ? "border-danger"
                                    : "border-success")
                                }
                              >
                                <CardHeader className="card-header-tab">
                                  <Row className="container_spanCentrado w-100">
                                    <Col className="w-100 pl-2 pr-3 text-truncate">
                                      {"CALEFACCIÓN"}
                                    </Col>
                                    <Col
                                      xs="3"
                                      className="d-flex justify-content-end p-0"
                                    >
                                      <div className="font-size-xxl icon_Calefaccion" />
                                    </Col>
                                  </Row>
                                </CardHeader>
                                {!loadingData_multiview_page2 ? (
                                  <CardBody className="pt-0 pb-0">
                                    {/* style={{ height: "0px" }} */}
                                    <div className="widget-content pl-0 pr-0 pt-0 pb-1 he-100">
                                      <div className="widget-content-outer he-100">
                                        <Row className="he-100">
                                          <Col xs="8" md="6" lg="8">
                                            <div className="widget-content-wrapper he-100">
                                              <CircularGauge
                                                id="CircularGauge_recursos"
                                                value={
                                                  datos_Anteriores.media_calefaccion
                                                }
                                              >
                                                <Margin bottom={5} />
                                                <Scale
                                                  startValue={
                                                    datos_Anteriores.kpi_calefaccion *
                                                    0.25
                                                  }
                                                  endValue={
                                                    datos_Anteriores.kpi_calefaccion *
                                                    1.5
                                                  }
                                                  tickInterval={
                                                    (((datos_Anteriores.kpi_calefaccion *
                                                      1.5) /
                                                      8) *
                                                      100) /
                                                    100
                                                  }
                                                >
                                                  <Label
                                                    customizeText={
                                                      this
                                                        .customizeText_recursosEnergeticos
                                                    }
                                                  />
                                                  <Tick length={6} />
                                                </Scale>
                                                <Geometry
                                                  startAngle={180}
                                                  endAngle={0}
                                                ></Geometry>
                                                <RangeContainer
                                                  width={15}
                                                  offset={14}
                                                  backgroundColor="var(--danger)"
                                                >
                                                  <Range
                                                    color="var(--green)"
                                                    startValue={
                                                      datos_Anteriores.kpi_calefaccion *
                                                      0.25
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_calefaccion
                                                    }
                                                  />
                                                  <Range
                                                    color="#d8ea4b"
                                                    startValue={
                                                      datos_Anteriores.kpi_calefaccion
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_calefaccion *
                                                      1.1
                                                    }
                                                  />
                                                </RangeContainer>
                                                <ValueIndicator
                                                  type="triangleNeedle"
                                                  color="#575757"
                                                  width={8}
                                                  offset={15}
                                                ></ValueIndicator>
                                              </CircularGauge>
                                            </div>
                                          </Col>
                                          <Col
                                            xs="4"
                                            md="6"
                                            lg="4"
                                            className="divider-left mt-3 mb-3"
                                          >
                                            <div className="widget-content-wrapper he-100 flex-column justify-content-around">
                                              <div className="w-100">
                                                <div className="widget-title opacity-5 text-uppercase">
                                                  8 DÍAS
                                                </div>
                                                <div className="font-size-xxxl font-weight-bold text-center">
                                                  {formatNumber(
                                                    datos_Anteriores.media_calefaccion,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                              <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                  <div className="widget-title opacity-5 text-uppercase flex-fill">
                                                    AYER
                                                  </div>
                                                  <div
                                                    className={
                                                      "ml-3 font-size-xxl " +
                                                      (datos_Anteriores.ayer_calefaccion >=
                                                      datos_Anteriores.kpi_calefaccion
                                                        ? "icon_ArrowUp danger"
                                                        : "icon_ArrowDown success")
                                                    }
                                                  />
                                                </div>
                                                <div className="font-size-xxxl text-center text-cut font-weight-bold">
                                                  {formatNumber(
                                                    datos_Anteriores.ayer_calefaccion,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </CardBody>
                                ) : (
                                  this.getLoading(false)
                                )}
                              </Card>
                            </Col>
                            <Col
                              xs="12"
                              md="6"
                              lg="4"
                              className="row-1 he-100 mb-3"
                            >
                              <Card
                                className={
                                  "card-btm-border d-flex he-100 " +
                                  (datos_Anteriores.ayer_electricidad >=
                                  datos_Anteriores.kpi_electricidad
                                    ? "border-danger"
                                    : "border-success")
                                }
                              >
                                <CardHeader className="card-header-tab">
                                  <Row className="container_spanCentrado w-100">
                                    <Col className="w-100 pl-2 pr-3 text-truncate">
                                      {"ELECTRICIDAD"}
                                    </Col>
                                    <Col
                                      xs="3"
                                      className="d-flex justify-content-end p-0"
                                    >
                                      <div className="font-size-xxl icon_Electricidad" />
                                    </Col>
                                  </Row>
                                </CardHeader>
                                {!loadingData_multiview_page2 ? (
                                  <CardBody
                                    className="pt-0 pb-0"
                                    style={{ height: "0px" }}
                                  >
                                    <div className="widget-content pl-0 pr-0 pt-0 pb-1 he-100">
                                      <div className="widget-content-outer he-100">
                                        <Row className="he-100">
                                          <Col xs="8" md="6" lg="8">
                                            <div className="widget-content-wrapper he-100">
                                              <CircularGauge
                                                id="CircularGauge_recursos"
                                                value={
                                                  datos_Anteriores.media_electricidad
                                                }
                                              >
                                                <Margin bottom={5} />
                                                <Scale
                                                  startValue={
                                                    datos_Anteriores.kpi_electricidad *
                                                    0.25
                                                  }
                                                  endValue={
                                                    datos_Anteriores.kpi_electricidad *
                                                    1.5
                                                  }
                                                  tickInterval={
                                                    (((datos_Anteriores.kpi_electricidad *
                                                      1.5) /
                                                      8) *
                                                      100) /
                                                    100
                                                  }
                                                >
                                                  <Label
                                                    customizeText={
                                                      this
                                                        .customizeText_recursosEnergeticos
                                                    }
                                                  />
                                                  <Tick length={6} />
                                                </Scale>
                                                <Geometry
                                                  startAngle={180}
                                                  endAngle={0}
                                                ></Geometry>
                                                <RangeContainer
                                                  width={15}
                                                  offset={14}
                                                  backgroundColor="var(--danger)"
                                                >
                                                  <Range
                                                    color="var(--green)"
                                                    startValue={
                                                      datos_Anteriores.kpi_electricidad *
                                                      0.25
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_electricidad
                                                    }
                                                  />
                                                  <Range
                                                    color="#d8ea4b"
                                                    startValue={
                                                      datos_Anteriores.kpi_electricidad
                                                    }
                                                    endValue={
                                                      datos_Anteriores.kpi_electricidad *
                                                      1.1
                                                    }
                                                  />
                                                </RangeContainer>
                                                <ValueIndicator
                                                  type="triangleNeedle"
                                                  color="#575757"
                                                  width={8}
                                                  offset={15}
                                                ></ValueIndicator>
                                              </CircularGauge>
                                            </div>
                                          </Col>
                                          <Col
                                            xs="4"
                                            md="6"
                                            lg="4"
                                            className="divider-left mt-3 mb-3"
                                          >
                                            <div className="widget-content-wrapper he-100 flex-column justify-content-around">
                                              <div className="w-100">
                                                <div className="widget-title opacity-5 text-uppercase">
                                                  8 DÍAS
                                                </div>
                                                <div className="font-size-xxxl font-weight-bold text-center">
                                                  {formatNumber(
                                                    datos_Anteriores.media_electricidad,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                              <div className="w-100">
                                                <div className="d-flex align-items-center">
                                                  <div className="widget-title opacity-5 text-uppercase flex-fill">
                                                    AYER
                                                  </div>
                                                  <div
                                                    className={
                                                      "ml-3 font-size-xxl " +
                                                      (datos_Anteriores.ayer_electricidad >=
                                                      datos_Anteriores.kpi_electricidad
                                                        ? "icon_ArrowUp danger"
                                                        : "icon_ArrowDown success")
                                                    }
                                                  />
                                                </div>
                                                <div className="font-size-xxxl text-center text-cut font-weight-bold">
                                                  {formatNumber(
                                                    datos_Anteriores.ayer_electricidad,
                                                    2,
                                                    "decimal",
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </CardBody>
                                ) : (
                                  this.getLoading(false)
                                )}
                              </Card>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <Row
                        className={
                          "formContainer " +
                          (this.props.resolucion === "xs"
                            ? "flex-column he-auto"
                            : "he-100")
                        }
                      >
                        <Col
                          xs="12"
                          md="6"
                          lg="4"
                          style={{ flex: "inherit" }}
                          className={
                            "he-100 mb-3 " +
                            (this.props.resolucion === "xs"
                              ? "row-2-mobile"
                              : "row-2")
                          }
                        >
                          <Card
                            className={
                              "card-btm-border d-flex he-100 " +
                              (datos_Anteriores.ayer_calefaccion >=
                              datos_Anteriores.kpi_agua
                                ? "border-danger"
                                : "border-success")
                            }
                          >
                            <CardHeader className="card-header-tab">
                              <Row className="container_spanCentrado w-100 ml-0">
                                <Col className="d-flex w-100 pl-0 pr-3 text-truncate">
                                  {"CATEGORIAS DE MÁQUINAS"}
                                </Col>
                                <Col
                                  xs="3"
                                  className="d-flex justify-content-end p-0"
                                >
                                  <div className="font-size-xxl icon_Lavadora" />
                                </Col>
                              </Row>
                            </CardHeader>
                            {!loadingData_multiview_page2 ? (
                              <CardBody
                                className="pt-0 pb-1"
                                style={{ height: "0px" }}
                              >
                                <div
                                  className={
                                    "widget-content pl-0 pr-0 pb-1 he-100 " +
                                    (get_isMovil(resolucion) ? "pt-3" : "pt-2")
                                  }
                                >
                                  <div className="widget-content-outer he-100">
                                    {this.props.resolucion !== "xs" ? (
                                      <DataGrid
                                        //Datos
                                        remoteOperations={true}
                                        dataSource={categoriasMaquina_LavSel}
                                        keyExpr="idCategoriaMaquina"
                                        //Propiedades
                                        columnsAutoWidth={true}
                                        height={"100%"}
                                        hoverStateEnabled={true}
                                        selection={
                                          this.gridCategoriasMaquina_selection
                                        }
                                        selectedRowKeys={[
                                          categoriaMaquinaSel !== null
                                            ? categoriaMaquinaSel.idCategoriaMaquina
                                            : null,
                                        ]}
                                        defaultSelectedRowKeys={[null]}
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        //Eventos
                                        onContentReady={
                                          this
                                            .onContentReady_gridCategoriasMaquina
                                        }
                                        onToolbarPreparing={
                                          this
                                            .onToolbarPreparing_gridCategoriasMaquina
                                        }
                                        onSelectionChanged={
                                          this
                                            .onSelectionChanged_gridCategoriasMaquina
                                        }
                                        onRowPrepared={
                                          this.onRowPrepared_pointerRow
                                        }
                                      >
                                        <SearchPanel visible={false} />
                                        <HeaderFilter visible={true} />
                                        <FilterRow
                                          visible={true}
                                          showAllText={getTrad(
                                            "todos",
                                          ).toUpperCase()}
                                          applyFilter="auto"
                                        />
                                        <Pager visible={false} />
                                        <Scrolling mode="infinite" />
                                        <Column
                                          dataField="error"
                                          caption=""
                                          visible={
                                            categoriasMaquina_LavSel &&
                                            categoriasMaquina_LavSel.length > 0
                                              ? categoriasMaquina_LavSel[0]
                                                  .error === 1
                                              : false
                                          }
                                          width={35}
                                          alignment="center"
                                          cssClass="p-0"
                                          allowEditing={false}
                                          allowFiltering={false}
                                          allowSorting={false}
                                          cellTemplate={this.cellTemplate_error}
                                        />
                                        <Column
                                          dataField="denominacion"
                                          caption={getTrad("denominacion")}
                                          allowHeaderFiltering={false}
                                        />
                                        <Column
                                          dataField="idSistemaMaquina"
                                          caption={getTrad("sistemaMaquina")}
                                          allowHeaderFiltering={false}
                                        >
                                          <Lookup_grid
                                            dataSource={this.datasource_tblSistemaMaquina.items()}
                                            valueExpr={"idSistemaMaquina"}
                                            displayExpr={"denominacion"}
                                          />
                                        </Column>
                                        <Column
                                          dataField="operatividadCategoria"
                                          caption="Operatividad"
                                          width={"20%"}
                                          alignment="center"
                                          cssClass="p-0"
                                          allowFiltering={false}
                                          cellComponent={
                                            progressBarCellComponent
                                          }
                                        />
                                        <Column
                                          dataField="color_operatividadCategoria"
                                          visible={false}
                                        />
                                      </DataGrid>
                                    ) : (
                                      <SelectBox
                                        valueExpr="idCategoriaMaquina"
                                        displayExpr="denominacion"
                                        defaultValue={null}
                                        showClearButton={false}
                                        items={categoriasMaquina_LavSel}
                                        onValueChanged={
                                          this.dxSelectBox_onValueChanged
                                        }
                                        itemRender={
                                          this
                                            .dxDropdownBox_CategoriasMaq_render
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                              </CardBody>
                            ) : (
                              this.getLoading(false)
                            )}
                          </Card>
                        </Col>
                        <Col
                          xs="12"
                          md="6"
                          lg="8"
                          style={{ flex: 1 }}
                          className={"row-2 mb-3 " + "he-100"}
                        >
                          <Card
                            className={
                              "card-btm-border d-flex he-100 " +
                              (datos_Anteriores.ayer_agua >=
                              datos_Anteriores.kpi_agua
                                ? "border-danger"
                                : "border-success")
                            }
                          >
                            <CardHeader className="card-header-tab">
                              <Row className="container_spanCentrado w-100 mr-0 ml-0">
                                <Col className="d-flex w-100 pl-0 pr-3 text-truncate">
                                  {"INCIDENCIAS"}
                                </Col>
                                <Col
                                  xs="3"
                                  className="d-flex justify-content-end p-0"
                                >
                                  <div className="font-size-xxl icon_Cuaderno" />
                                </Col>
                              </Row>
                            </CardHeader>
                            {!loadingData_multiview_page2 ? (
                              <CardBody
                                className="pt-0 pb-0"
                                style={{ height: "0px" }}
                              >
                                <div className="widget-content pl-0 pr-0 pt-2 pb-1 he-100">
                                  <div className="widget-content-outer he-100">
                                    <DataGrid
                                      elementAttr={{
                                        id: "dxDataGrid_incidencias",
                                      }}
                                      //Datos
                                      dataSource={this.datasource_tblIncidencia}
                                      //Propiedades
                                      columnsAutoWidth={true}
                                      height={"100%"}
                                      hoverStateEnabled={true}
                                      //Estilos
                                      showColumnLines={false}
                                      showRowLines={true}
                                      rowAlternationEnabled={true}
                                      //Eventos
                                      onRowPrepared={
                                        this.onRowPrepared_gridIncidencias
                                      }
                                      onToolbarPreparing={
                                        this.onToolbarPreparing_gridIncidencias
                                      }
                                      onRowClick={
                                        this.onRowClick_gridIncidencias
                                      }
                                      onAdaptiveDetailRowPreparing={
                                        this
                                          .onAdaptiveDetailRowPreparing_gridIncidencias
                                      }
                                    >
                                      <SearchPanel
                                        visible={!get_isMovil(resolucion)}
                                        width={200}
                                        placeholder={getTrad("busqueda")}
                                        searchVisibleColumnsOnly={true}
                                      />
                                      <HeaderFilter visible={true} />
                                      <FilterRow
                                        visible={!get_isMovil(resolucion)}
                                        showAllText={getTrad(
                                          "todos",
                                        ).toUpperCase()}
                                        applyFilter="auto"
                                      />
                                      <Sorting mode="multiple" />
                                      <Paging pageSize={50} />
                                      <Pager
                                        showPageSizeSelector={true}
                                        allowedPageSizes={[20, 50, 100]}
                                        showInfo={true}
                                      />
                                      <Column
                                        caption=" "
                                        width={
                                          !get_isMovil(resolucion)
                                            ? "40px"
                                            : "20px"
                                        }
                                        alignment="center"
                                        cssClass="p-0"
                                        fixed={true}
                                        allowFixing={false}
                                        allowHiding={false}
                                        allowReordering={false}
                                        cellRender={
                                          this
                                            .renderEditCell_modificarIncidencia
                                        }
                                      />
                                      <Column
                                        caption=" "
                                        width="30px"
                                        alignment="center"
                                        cssClass="p-0"
                                        fixed={true}
                                        allowFixing={false}
                                        allowHiding={false}
                                        allowReordering={false}
                                        cellRender={
                                          this.renderEditCell_estadoMaquina
                                        }
                                      />
                                      <Column
                                        dataField="fechaIncidencia"
                                        caption={
                                          get_isMovil(resolucion)
                                            ? getTrad("fecha")
                                            : getTrad("fechaIncidencia")
                                        }
                                        dataType="date"
                                        format="dd/MM/yyyy"
                                        width={
                                          get_isMovil(resolucion)
                                            ? "70px"
                                            : "150px"
                                        }
                                        alignment={
                                          get_isMovil(resolucion)
                                            ? "left"
                                            : "center"
                                        }
                                        sortIndex={1}
                                        sortOrder="desc"
                                        allowSearch={false}
                                        allowHeaderFiltering={false}
                                        allowFiltering={
                                          !get_isMovil(resolucion)
                                        }
                                      />
                                      <Column
                                        dataField="idMaquina"
                                        dataType="number"
                                        caption={getTrad("maquina")}
                                        cssClass="white-space-normal"
                                        minWidth={
                                          !get_isMovil(resolucion) ? 200 : null
                                        }
                                        filterValue={
                                          maquinaSel !== null
                                            ? maquinaSel.idMaquina
                                            : null
                                        }
                                        editorOptions={this.columnEditorOptions}
                                        allowHeaderFiltering={
                                          !get_isMovil(resolucion)
                                        }
                                      >
                                        <Lookup_grid
                                          dataSource={this.datasource_tblMaquina.items()}
                                          valueExpr={"idMaquina"}
                                          displayExpr={"denominacion"}
                                        />
                                      </Column>
                                      <Column
                                        dataField="descripcionIncidencia"
                                        dataType="string"
                                        caption={getTrad("descripcion")}
                                        width={200}
                                        hidingPriority={0}
                                      />
                                      <Column
                                        dataField="fechaUltimoParte"
                                        caption={
                                          !get_isMovil(resolucion)
                                            ? "Fecha último parte"
                                            : "Fecha últ. parte"
                                        }
                                        width={150}
                                        dataType="date"
                                        format="dd/MM/yyyy"
                                        alignment="center"
                                        allowSearch={false}
                                        allowHeaderFiltering={false}
                                        hidingPriority={2}
                                      />
                                      <Column
                                        dataField="estado"
                                        caption={getTrad("estado")}
                                        dataType="boolean"
                                        width={100}
                                        alignment="left"
                                        sortIndex={1}
                                        sortOrder="asc"
                                        filterValue={false}
                                        falseText={
                                          this.enum_estadoIncidencia[0]
                                            .denominacion
                                        }
                                        trueText={
                                          this.enum_estadoIncidencia[1]
                                            .denominacion
                                        }
                                        allowHeaderFiltering={true}
                                        editorOptions={this.columnEditorOptions}
                                        hidingPriority={1}
                                      >
                                        <HeaderFilter allowSearch={true} />
                                        <Lookup_grid
                                          dataSource={
                                            this.enum_estadoIncidencia
                                          }
                                          valueExpr={"estado"}
                                          displayExpr={"denominacion"}
                                        />
                                      </Column>
                                      <Column
                                        dataField="estadoMaquina"
                                        dataType="number"
                                        visible={false}
                                      />
                                      <Column
                                        caption=" "
                                        dataType="number"
                                        dataField="tblMaquina.tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina"
                                        filterValue={
                                          categoriaMaquinaSel
                                            ? categoriaMaquinaSel.idCategoriaMaquina
                                            : null
                                        }
                                        calculateFilterExpression={
                                          this
                                            .calculateFilterExpression_idCategoriaMaquina
                                        }
                                        width={"1px"}
                                        allowHeaderFiltering={false}
                                        allowSearch={false}
                                      />
                                    </DataGrid>
                                  </div>
                                </div>
                              </CardBody>
                            ) : (
                              this.getLoading(true)
                            )}
                          </Card>
                        </Col>
                      </Row>
                    </ScrollView>
                    <Popup
                      deferRendering={true}
                      showTitle={true}
                      width={"90%"}
                      maxWidth={1500}
                      height={"85%"}
                      visible={incidenciaSel !== null}
                      showCloseButton={true}
                      closeOnOutsideClick={false}
                      onContentReady={this.onContentReady_popupIncidencias}
                      onHiding={this.hiding_popupIncidencias}
                    >
                      <ToolbarItem_popup
                        widget="dxButton"
                        location="before"
                        toolbar="top"
                        visible={parteTrabajoSel !== null}
                        options={{
                          icon: "chevronleft",
                          width: 45,
                          type: "normal",
                          visible: parteTrabajoSel !== null,
                          onClick: this.onClick_btnAtras,
                        }}
                      />
                      <ToolbarItem_popup
                        visible={true}
                        text={
                          parteTrabajoSel === null
                            ? "Incidencia"
                            : "Parte de trabajo"
                        }
                        location="before"
                        toolbar="top"
                      />
                      <MultiView
                        height="100%"
                        width="100%"
                        selectedIndex={parteTrabajoSel === null ? 0 : 1}
                        loop={true}
                        swipeEnabled={false}
                        animationEnabled={true}
                      >
                        <MultiViewItem>
                          <ScrollView id="dxScrollView_incidencias">
                            <form
                              id="form-incidencias"
                              onSubmit={this.submit_formIncidencias}
                              className="d-flex flex-column he-100 w-100"
                            >
                              <div className="flex-grow-1">
                                {incidenciaSel != null &&
                                parteTrabajoSel === null ? (
                                  <Form
                                    formData={incidenciaSel}
                                    height="100%"
                                    width="100%"
                                    labelLocation="top"
                                    scrollingEnabled={true}
                                    screenByWidth={
                                      this.screenByWidth_resolucion
                                    }
                                    colCountByScreen={
                                      this.frmIncidencias_colCountByScreen
                                    }
                                    validationGroup="validationGroup_form_incidencia"
                                    onFieldDataChanged={
                                      this.dxForm_incidencia_onFieldDataChanged
                                    }
                                  >
                                    <Item
                                      dataField="idUsuarioCrea"
                                      visible={false}
                                    />
                                    <Item
                                      dataField="estadoMaquina"
                                      visible={false}
                                    />
                                    <Item
                                      itemType="group"
                                      name="groupIncidencia"
                                      colCountByScreen={
                                        this
                                          .frmIncidencias_General_colCountByScreen
                                      }
                                      colSpan={2}
                                    >
                                      <Item
                                        dataField="tblUsuarioCrea.nombre"
                                        label={
                                          this.frmLabel_tblUsuarioCrea_nombre
                                        }
                                        editorType="dxTextBox"
                                        editorOptions={
                                          this.disabled_editorOptions
                                        }
                                        colSpan={2}
                                      />
                                      <Item
                                        dataField="tblUsuarioRevisor.nombre"
                                        label={
                                          this.frmLabel_tblUsuarioRevisor_nombre
                                        }
                                        editorType="dxTextBox"
                                        editorOptions={
                                          this.disabled_editorOptions
                                        }
                                        colSpan={2}
                                      />
                                      <Item
                                        dataField="fechaIncidencia"
                                        label={this.frmLabel_fechaIncidencia}
                                        editorType="dxDateBox"
                                        editorOptions={{
                                          pickerType:
                                            this.props.resolucion === "xs"
                                              ? "rollers"
                                              : "calendar",
                                          type: "datetime",
                                          inputAttr: {
                                            style: "text-transform: capitalize",
                                          },
                                          width: "100%",
                                          displayFormat: "dd/MM/yyyy - HH:mm",
                                          showClearButton: true,
                                        }}
                                        colSpan={2}
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="idSubTipoIncidencia"
                                        label={
                                          this.frmLabel_idSubTipoIncidencia
                                        }
                                        editorType="dxSelectBox"
                                        editorOptions={
                                          this.idSubTipoIncidencia_editorOptions
                                        }
                                        colSpan={2}
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="idMaquina"
                                        label={this.frmLabel_maquina}
                                        editorType="dxLookup"
                                        editorOptions={
                                          this.idMaquina_editorOptions
                                        }
                                        colSpan={2}
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="estadoMaquina"
                                        name="estadoMaquina"
                                        label={this.frmLabel_estado}
                                        editorType="dxSelectBox"
                                        editorOptions={
                                          this.estadoMaquina_editorOptions
                                        }
                                        disabled={
                                          incidenciaSel &&
                                          incidenciaSel.idIncidencia !== null
                                        }
                                        colSpan={
                                          is_incidencia_noParada &&
                                          incidenciaSel.peso != null
                                            ? 1
                                            : 2
                                        }
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="peso"
                                        name="peso"
                                        label={this.frmLabel_peso}
                                        editorType="dxSelectBox"
                                        editorOptions={this.peso_editorOptions}
                                        disabled={
                                          incidenciaSel &&
                                          incidenciaSel.idIncidencia !== null
                                        }
                                        visible={
                                          is_incidencia_noParada &&
                                          incidenciaSel.peso != null
                                        }
                                        colSpan={2}
                                      />
                                      <Item
                                        dataField="descripcionIncidencia"
                                        name="descripcionIncidencia"
                                        label={
                                          this.frmLabel_descripcionIncidencia
                                        }
                                        editorType="dxTextArea"
                                        editorOptions={
                                          this.textArea_editorOptions
                                        }
                                        colSpan={4}
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="tblIncidencia_Documento"
                                        name="tblIncidencia_Documento"
                                        label={
                                          this.frmLabel_tblIncidencia_Documento
                                        }
                                        render={this.render_FileUploader}
                                        colSpan={4}
                                      />
                                    </Item>
                                    <Item itemType="group" colSpan={2}>
                                      <Item
                                        dataField="idUsuarioResponsable"
                                        label={
                                          this.frmLabel_idUsuarioResponsable
                                        }
                                        editorType="dxLookup"
                                        editorOptions={
                                          this
                                            .idUsuarioResponsable_editorOptions
                                        }
                                      >
                                        <RequiredRule
                                          message={getTrad("campoNecesario")}
                                        />
                                      </Item>
                                      <Item
                                        dataField="tblParteTrabajo"
                                        label={this.frmLabel_tblParteTrabajo}
                                        render={this.render_tblParteTrabajo}
                                      />
                                    </Item>
                                  </Form>
                                ) : null}
                              </div>
                              <div className="text-right pt-3">
                                <Button
                                  elementAttr={this.dxButton_guardar_incidencia}
                                  text={getTrad("guardar")}
                                  type="success"
                                  useSubmitBehavior={true}
                                  validationGroup="validationGroup_form_incidencia"
                                />
                              </div>
                            </form>
                          </ScrollView>
                        </MultiViewItem>
                        <MultiViewItem>
                          <ScrollView>
                            <form
                              id="form-parteTrabajo"
                              onSubmit={this.submit_formParteTrabajo}
                              className="d-flex flex-column he-100 w-100"
                            >
                              <div className="flex-grow-1">
                                {parteTrabajoSel !== null ? (
                                  <Fragment>
                                    <Form
                                      height="100%"
                                      formData={parteTrabajoSel}
                                      labelLocation="top"
                                      scrollingEnabled={true}
                                      screenByWidth={this.dxForm_screenByWidth}
                                      colCountByScreen={
                                        this.frmParteTrabajo_colCountByScreen
                                      }
                                      validationGroup="validationGroup_form_parteTrabajo"
                                      onContentReady={
                                        this.onContentReady_form_parteTrabajo
                                      }
                                      onFieldDataChanged={
                                        this
                                          .dxForm_partTrabajo_onFieldDataChanged
                                      }
                                    >
                                      <Item
                                        dataField="idUsuarioCrea"
                                        visible={false}
                                      />
                                      <Item
                                        dataField="estadoMaquina"
                                        visible={false}
                                      />
                                      <Item
                                        itemType="group"
                                        name="groupParteTrabajo"
                                        caption={getTrad("general")}
                                        colCountByScreen={
                                          this
                                            .frmParteTrabajoGeneral_colCountByScreen
                                        }
                                        colSpan={4}
                                      >
                                        <Item
                                          dataField="fecha"
                                          label={this.frmLabel_fecha}
                                          editorType="dxDateBox"
                                          editorOptions={
                                            this.dxDateBox_form_editorOptions
                                          }
                                          colSpan={2}
                                          disabled={
                                            incidenciaSel &&
                                            (incidenciaSel.idIncidencia ===
                                              null ||
                                              incidenciaSel.estado === true)
                                          }
                                        >
                                          <RequiredRule
                                            message={getTrad("campoNecesario")}
                                          />
                                        </Item>
                                        <Item
                                          dataField="tblIncidenciaNParte[0].estadoActual"
                                          name="estadoActual"
                                          label={this.frmLabel_estadoActual}
                                          editorType="dxSelectBox"
                                          editorOptions={
                                            this.estadoActual_editorOptions
                                          }
                                          colSpan={2}
                                          disabled={
                                            incidenciaSel &&
                                            (incidenciaSel.idIncidencia ===
                                              null ||
                                              incidenciaSel.estado === true)
                                          }
                                        >
                                          <RequiredRule
                                            message={getTrad("campoNecesario")}
                                          />
                                        </Item>
                                        <Item
                                          dataField="tblIncidenciaNParte[0].peso"
                                          name="peso"
                                          label={this.frmLabel_peso}
                                          editorType="dxSelectBox"
                                          editorOptions={
                                            this.peso_editorOptions
                                          }
                                          visible={
                                            parteTrabajoSel
                                              .tblIncidenciaNParte[0]
                                              .estadoActual === 1
                                          }
                                          colSpan={1}
                                          disabled={
                                            incidenciaSel &&
                                            (incidenciaSel.idIncidencia ===
                                              null ||
                                              incidenciaSel.estado === true)
                                          }
                                        />
                                        <Item
                                          dataField="resolucion"
                                          name="resolucion"
                                          label={this.frmLabel_resolucion}
                                          editorType="dxTextArea"
                                          editorOptions={
                                            this.textArea_editorOptions
                                          }
                                          colSpan={
                                            parteTrabajoSel
                                              .tblIncidenciaNParte[0]
                                              .estadoActual === 1
                                              ? 7
                                              : 8
                                          }
                                          disabled={
                                            incidenciaSel &&
                                            (incidenciaSel.idIncidencia ===
                                              null ||
                                              incidenciaSel.estado === true)
                                          }
                                        >
                                          <RequiredRule
                                            message={getTrad("campoNecesario")}
                                          />
                                        </Item>
                                      </Item>
                                      <Item
                                        itemType="group"
                                        colCountByScreen={
                                          this
                                            .frmParteTrabajoTablas_colCountByScreen
                                        }
                                        colSpan={4}
                                      >
                                        <Item
                                          itemType="group"
                                          caption={getTrad("tecnicos")}
                                          colCount={1}
                                          colSpan={2}
                                          cssClass={"tblParteTrabajo_tecnicos"}
                                        >
                                          <Item
                                            dataField="tblPersonasNParte"
                                            label={this.formLabel_invisible}
                                            render={
                                              this.render_tblPersonasNParte
                                            }
                                            disabled={
                                              incidenciaSel &&
                                              (incidenciaSel.idIncidencia ===
                                                null ||
                                                incidenciaSel.estado === true)
                                            }
                                          />
                                        </Item>
                                        <TabbedItem colSpan={5}>
                                          <TabPanelOptions
                                            height="100%"
                                            width="100%"
                                            swipeEnabled={false}
                                            elementAttr={
                                              this.parteTrabajo_tabPanel
                                            }
                                          />
                                          <Tab title={getTrad("recambios")}>
                                            {lavanderia.idCorporacion !== 1 ? (
                                              <Item
                                                itemType="group"
                                                colCount={1}
                                                colSpan={2}
                                                disabled={
                                                  incidenciaSel &&
                                                  (incidenciaSel.idIncidencia ===
                                                    null ||
                                                    incidenciaSel.estado ===
                                                      true)
                                                }
                                              >
                                                <Item
                                                  dataField="tblRecambioNParteTrabajo"
                                                  label={
                                                    this.formLabel_invisible
                                                  }
                                                  render={
                                                    this
                                                      .render_tblRecambioNParteTrabajo
                                                  }
                                                />
                                              </Item>
                                            ) : (
                                              <Item
                                                itemType="group"
                                                colCount={1}
                                                colSpan={2}
                                                disabled={
                                                  incidenciaSel &&
                                                  (incidenciaSel.idIncidencia ===
                                                    null ||
                                                    incidenciaSel.estado ===
                                                      true)
                                                }
                                              >
                                                <Item
                                                  dataField="tblRecambioNParteTrabajoIBS"
                                                  label={
                                                    this.formLabel_invisible
                                                  }
                                                  render={
                                                    this
                                                      .render_tblRecambioNParteTrabajoIBS
                                                  }
                                                />
                                              </Item>
                                            )}
                                          </Tab>
                                          <Tab
                                            title={getTrad("serviciosExternos")}
                                          >
                                            <Item
                                              itemType="group"
                                              colCount={1}
                                              colSpan={2}
                                            >
                                              <Item
                                                dataField="tblServicioExternoNParteTrabajo"
                                                label={this.formLabel_invisible}
                                                render={
                                                  this
                                                    .render_tblServicioExternoNParteTrabajo
                                                }
                                                disabled={
                                                  incidenciaSel &&
                                                  (incidenciaSel.idIncidencia ===
                                                    null ||
                                                    incidenciaSel.estado ===
                                                      true)
                                                }
                                              />
                                            </Item>
                                          </Tab>
                                        </TabbedItem>
                                      </Item>
                                    </Form>
                                    <Popup
                                      title={"Añadir técnicos"}
                                      showTitle={true}
                                      width={600}
                                      height={500}
                                      visible={showPopup_Add_tblPersonasNParte}
                                      closeOnOutsideClick={false}
                                      onShown={
                                        this.onShown_popup_Add_tblPersonasNParte
                                      }
                                      onHiding={
                                        this.hiding_popup_Add_tblPersonasNParte
                                      }
                                    >
                                      <DataGrid
                                        //Datos
                                        height="100%"
                                        ref={
                                          this.dxDataGrid_tblPersonasNParte_REF
                                        }
                                        //Propiedades
                                        columnsAutoWidth={true}
                                        hoverStateEnabled={true}
                                        //Estilos
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                      >
                                        <SearchPanel visible={false} />
                                        <HeaderFilter visible={false} />
                                        <FilterRow visible={false} />
                                        <Pager visible={false} />
                                        <Editing
                                          mode="cell"
                                          allowUpdating={true}
                                        />
                                        <Column
                                          dataField="idPersona"
                                          visible={false}
                                        />
                                        <Column
                                          dataField="nombreCompuesto"
                                          caption={getTrad("nombre")}
                                          allowEditing={false}
                                        />
                                        <Column
                                          dataField="horas"
                                          caption={getTrad("horas")}
                                          alignment="center"
                                          dataType="datetime"
                                          width={80}
                                          format="HH:mm"
                                          mask="00:00"
                                          cssClass="dx-Cell_Editable"
                                          editorOptions={
                                            this.time_editorOptions
                                          }
                                        />
                                      </DataGrid>
                                      <ToolbarItem_popup
                                        toolbar="bottom"
                                        location="after"
                                      >
                                        <Button
                                          text={getTrad("aceptar")}
                                          type="success"
                                          onClick={
                                            this.onClick_btnAddPersonasNParte
                                          }
                                        />
                                      </ToolbarItem_popup>
                                    </Popup>
                                  </Fragment>
                                ) : null}
                              </div>
                              <div className="text-right pt-3">
                                <Button
                                  elementAttr={this.dxButton_guardar_parte}
                                  text={getTrad("guardar")}
                                  type="success"
                                  useSubmitBehavior={true}
                                  validationGroup="validationGroup_form_parteTrabajo"
                                  disabled={
                                    incidenciaSel &&
                                    (incidenciaSel.idIncidencia === null ||
                                      incidenciaSel.estado === true)
                                  }
                                />
                              </div>
                            </form>
                          </ScrollView>
                        </MultiViewItem>
                      </MultiView>
                    </Popup>
                  </Fragment>
                ) : null}
              </MultiViewItem>
            </MultiView>
            <PopupAñadirRecambio
              visible={showPopup_Add_tblRecambioNParteTrabajo}
              parteTrabajoSel={parteTrabajoSel}
              onHiding={this.hiding_popup_Add_tblRecambioNParteTrabajo}
              onSaveRecambiosNParteTrabajo={
                this.onClick_btnAddRecambioNParteTrabajo
              }
            />
            <Popover
              ref={this.dxPopover_REF}
              width={200}
              height={100}
            ></Popover>
            <div id="popup_insertarPreventivo_config"></div>
            <div id="popup_imprimirPreventivo"></div>
            <div id="popup_insertarPreventivo"></div>
            <div id="dxPopup_incidencia"></div>
            <div id="dxPopup_notificar"></div>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  cellTemplate_error = (cellElement, cellInfo) => {
    let rowData = cellInfo.data;
    let error = rowData.error;

    let icono = error == 1 ? "icon_Warning" : "";

    let icon = $("<div style='font-size:23px;' />").addClass(
      "container_spanCentrado center-svg iconos_svg " + icono,
    );
    if (rowData.error === 1)
      tooltipControl_creacion(icon, getTrad("pesoMalParametrizado"));
    cellElement.append(icon);
  };

  //FORMATS
  date_format = "dd/MM/yyyy";
  decimal_format = { style: "decimal", maximumFractionDigits: 2 };
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: this.props.lavanderia.moneda,
  };

  //EDITORES
  decimal_editorOptions = {
    format: this.decimal_format,
    step: 0,
    min: -999999999,
    max: 999999999,
  };

  currency_editorOptions = {
    format: this.currency_format,
    step: 0,
    min: 0,
    max: 999999.99,
  };

  disabled_editorOptions = {
    disabled: true,
  };

  columnEditorOptions = {
    showClearButton: true,
  };

  time_editorOptions = {
    displayFormat: "HH:mm",
    showAnalogClock: false,
    showDropDownButton: false,
    spellcheck: true,
    type: "time",
    useMaskBehavior: true,
  };

  idSubTipoIncidencia_editorOptions = {
    items: [],
    displayExpr: "denominacion",
    valueExpr: "idSubTipoIncidencia",
    showClearButton: true,
  };

  idMaquina_editorOptions = {
    dataSource: [], //Al modificar el categoriaMaquinaSel se actualiza
    displayExpr: "denominacion",
    valueExpr: "idMaquina",
    searchExpr: ["denominacion", "etiqueta", "numSerie"],
    cleanSearchOnOpening: true,
    searchEnabled: true,
    usePopover: true,
    showCancelButton: false,

    dropDownOptions: {
      title: getTrad("maquina"),
      closeOnOutsideClick: true,
      fullScreen: false,
    },
  };

  estadoMaquina_editorOptions = {
    dataSource: this.enum_estadosMaquina,
    displayExpr: "denominacion",
    valueExpr: "estadoMaquina",
  };

  peso_editorOptions = {
    dataSource: this.datasource_tblPesoIncidencia,
    displayExpr: function (item) {
      return item && formatNumber(item.valor, 0, "percent");
    },
    valueExpr: "idPeso",
  };

  textArea_editorOptions = {
    height: "75px",
  };

  idUsuarioResponsable_editorOptions = {
    dataSource: this.datasource_tblPersona_Tecnicos,
    displayExpr: "nombreCompuesto",
    valueExpr: "idPersona",
    searchExpr: ["nombre", "apellidos"],
    searchEnabled: false,
    cleanSearchOnOpening: true,
    usePopover: true,
    showCancelButton: false,
    dropDownOptions: {
      title: getTrad("responsable"),
      closeOnOutsideClick: true,
      fullScreen: false,
    },
  };

  btnSubmit_buttonOptions = {
    text: getTrad("aceptar"),
    type: "success",
    useSubmitBehavior: true,
  };

  estadoActual_editorOptions = {
    dataSource: this.enum_EstadoParteTrabajo,
    displayExpr: "estado",
    valueExpr: "valor",
  };

  dxForm_screenByWidth = () => {
    return this.props.resolucion;
  };

  //FORMS
  formLabel_invisible = { visible: false };
  frmLabel_tblUsuarioCrea_nombre = { text: getTrad("registradoPor") };
  frmLabel_tblUsuarioRevisor_nombre = { text: getTrad("validadoPor") };
  frmLabel_fechaIncidencia = { text: getTrad("fechaHora_Inc") };
  frmLabel_idSubTipoIncidencia = { text: getTrad("subTipo") };
  frmLabel_maquina = { text: getTrad("maquina") };
  frmLabel_estado = { text: getTrad("estado") };
  frmLabel_peso = { text: getTrad("peso") };
  frmLabel_descripcionIncidencia = { text: getTrad("descripcionIncidencia") };
  frmLabel_tblIncidencia_Documento = { text: getTrad("archivosAdjuntos") };
  frmLabel_idUsuarioResponsable = { text: getTrad("responsable") };
  frmLabel_tblParteTrabajo = { text: getTrad("parteTrabajo") };

  frmLabel_fecha = { text: getTrad("fecha") };
  frmLabel_estadoActual = { text: getTrad("estadoFinal") };
  frmLabel_resolucion = { text: getTrad("descripcionResolucion") };

  frmIncidencias_colCountByScreen = { xl: 4, lg: 4, md: 4, sm: 2, xs: 2 };
  frmIncidencias_General_colCountByScreen = {
    xl: 4,
    lg: 4,
    md: 4,
    sm: 4,
    xs: 2,
  };
  frmParteTrabajo_colCountByScreen = { xl: 4, lg: 4, md: 4, sm: 1, xs: 1 };
  frmParteTrabajoGeneral_colCountByScreen = {
    xl: 12,
    lg: 12,
    md: 12,
    sm: 2,
    xs: 1,
  };
  frmParteTrabajoTablas_colCountByScreen = {
    xl: 7,
    lg: 7,
    md: 7,
    sm: 2,
    xs: 1,
  };

  dxButton_guardar_parte = { id: "dxButton_guardar_parte" };
  dxButton_guardar_incidencia = { id: "dxButton_guardar_incidencia" };

  parteTrabajo_tabPanel = {
    class: "h4 parteTrabajo_tabPanel",
  };

  dxDateBox_form_editorOptions = {
    pickerType: this.props.resolucion === "xs" ? "rollers" : "calendar",
    type: "datetime",
    width: "100%",
    displayFormat: "dd/MM/yyyy - HH:mm",
    showClearButton: true,
    elementAttr: {
      class: "dxDateBoxForm",
    },
  };

  //SELECTOR
  card_Click(lavanderiaSel, maquina) {
    const { lavanderia } = this.props;
    this.setState({
      dxMultiView_index: 1,
      loadingData_multiview_page2: true,
      maquinaSel: maquina ? maquina : null,
    });

    if (lavanderia.idLavanderia !== lavanderiaSel.idLavanderia) {
      this.setLavanderia(lavanderiaSel.idLavanderia);
    } else {
      this.cargaDatos_lavanderia();
    }
  }

  setLavanderia(idLavanderia) {
    let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));

    var lav = lavanderias.find((lav) => {
      return lav.idLavanderia === idLavanderia;
    });
    this.props.setLavanderia(lav);
  }

  get_responsiveFont_gauge() {
    let width = $(window).width();
    // 3 Columnas
    if (width > 2150) return "font-size-xxl";
    if (width >= 1630 && width < 2150) return "font-size-xl";
    else if (width >= 1260 && width < 1630) return "font-size-lg";
    else if (width >= 1200 && width < 1260) return "font-size-md";
    // 2 Columnas
    else if (width >= 800 && width < 1200) return "font-size-xl";
    else if (width >= 650 && width < 800) return "font-size-lg";
    else if (width >= 576 && width < 650) return "font-size-md";
    // 1 Column
    else if (width >= 400 && width < 576) return "font-size-xl";
    else if (width < 400) return "font-size-lg";
    else return "font-size-xl";
  }

  onInitialized_graficasConsumos(e) {
    e.component.expandItem(0);
  }

  itemTitleRender_graficasConsumos() {
    return "KPIs";
  }

  itemRender_graficasConsumos(items) {
    let rows = [
      <div
        key={"cabeceras"}
        className="d-flex flex-row font-weight-bold font-size-xxs pb-2"
        style={{ fontColor: "#817f80", backgroundColor: "transparent" }}
      >
        <div style={{ flex: "0 0 35px" }} />
        <div style={{ flex: "0 0 20px" }} />
        <div style={{ flex: "1" }} />
        <div style={{ flex: "1" }} className="d-flex justify-content-center">
          KPI
        </div>
        <div style={{ flex: "1" }} className="d-flex justify-content-center">
          8 DÍAS
        </div>
        <div style={{ flex: "1" }} className="d-flex justify-content-center">
          AYER
        </div>
      </div>,
    ];

    $.each(items, function (index, item) {
      //#region colores
      let color_media = "#817f80"; //Gray
      if (item.kpi != null && item.media != null) {
        if (
          item.media >= (item.kpi * 0.25).toFixed(2) &&
          item.media <= item.kpi
        )
          color_media = "#28a745"; //Verde
        else if (
          item.media > item.kpi &&
          item.media <= (item.kpi * 1.1).toFixed(2)
        )
          color_media = "#b8cd18"; //Amarillo
        else if (item.media > (item.kpi * 1.1).toFixed(2))
          color_media = "#d92550"; //Rojo
      }

      let colorIcono = "#f5f5f5";
      if (index === "agua") colorIcono = "#679EC5";
      else if (index === "calefaccion") colorIcono = "#FD7E14";
      else if (index === "electricidad") colorIcono = "#EDB637";

      if (index === "agua") colorIcono = "#679EC5";
      else if (index === "calefaccion") colorIcono = "#FD7E14";
      else if (index === "electricidad") colorIcono = "#EDB637";
      //#endregion

      let border_row = "1px solid #dee2e6";
      let row = (
        <div
          key={item.titulo}
          className="d-flex flex-row font-weight-bold font-size-xxs"
          style={{ fontColor: "#817f80", backgroundColor: "#f5f5f5" }}
        >
          <div
            style={{
              flex: "0 0 35px",
              border: "2px solid " + colorIcono,
              backgroundColor: colorIcono,
            }}
            className={
              "d-flex align-items-center justify-content-center text-white text-center font-size-xxxl " +
              item.icono
            }
          />
          <div
            style={{
              flex: "0 0 5px",
              borderTop: border_row,
              borderBottom: border_row,
            }}
          />
          <div
            style={{
              flex: "1",
              minWidth: 82,
              paddingRight: 5,
              paddingLeft: 3,
              borderTop: border_row,
              borderBottom: border_row,
            }}
            className="d-flex align-items-center border-right"
          >
            {item.titulo}
          </div>
          <div
            style={{
              flex: "1",
              borderTop: border_row,
              borderBottom: border_row,
            }}
            className="d-flex flex-column align-items-center justify-content-center border-right font-size-xxs p-2"
          >
            <div className="d-flex align-items-center justify-content-center ">
              {(item.kpi * 1).toFixed(2)}
            </div>
          </div>
          <div
            style={{
              flex: "1",
              borderTop: border_row,
              borderBottom: border_row,
              color: color_media,
            }}
            className="d-flex align-items-center justify-content-center border-right font-size-xs"
          >
            {item.media}
          </div>
          <div
            style={{
              flex: "1",
              borderTop: border_row,
              borderBottom: border_row,
              borderRight: border_row,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            {item.ayer}
          </div>
        </div>
      );
      rows.push(row);
      if (index !== "electricidad")
        rows.push(
          $(<div key={item.titulo + "_" + index} style={{ height: "10px" }} />),
        );
    });

    return rows;
  }

  //RECURSOS ENERGETICOS
  customizeText_recursosEnergeticos({ value }) {
    return formatNumber(value, 2, "decimal");
  }

  //CATEGORÍAS DE MÁQUINA

  //CONFIG GRID
  gridCategoriasMaquina_selection = { mode: "single" };

  onRowPrepared_pointerRow(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  onContentReady_gridCategoriasMaquina(e) {
    e.component.endCustomLoading();
  }

  onToolbarPreparing_gridCategoriasMaquina(e) {
    let { resolucion } = this.props;
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          text: getTrad("imprimirPrev"),
          onClick: function (e) {
            $("#popup_imprimirPreventivo").dxPopup("instance").show();
          },
        },
      },
      {
        location: "before",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          text: getTrad("insertarPrev"),
          onClick: function (e) {
            $("#popup_insertarPreventivo_config").dxPopup("instance").show();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onInitialized: function (e) {
            tooltipControl_creacion(e.element, e.component.option("text"));
          },
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onInitialized: function (e) {
            tooltipControl_creacion(e.element, e.component.option("text"));
          },
          onClick: function () {
            e.component.beginCustomLoading();
            _this.datasource_datos_selector.load();
          },
        },
      },
    );
  }

  onSelectionChanged_gridCategoriasMaquina(e) {
    let catSel = e.selectedRowsData[0];
    this.setState({ categoriaMaquinaSel: catSel ? catSel : null });

    let itemsMaquinas = this.datasource_tblMaquina.items();
    this.idMaquina_editorOptions.dataSource = catSel
      ? catSel.idCategoriaMaquina == null
        ? itemsMaquinas
        : itemsMaquinas.filter(
            (x) =>
              (x.tblTipoMaquinaNCategoriaMaquina
                ? x.tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina
                : -1) == catSel.idCategoriaMaquina,
          )
      : itemsMaquinas;
  }

  dxSelectBox_onValueChanged(e) {
    let catSel = { idCategoriaMaquina: e.value };
    this.setState({ categoriaMaquinaSel: catSel ? catSel : null });

    let itemsMaquinas = this.datasource_tblMaquina.items();
    this.idMaquina_editorOptions.dataSource = catSel
      ? catSel.idCategoriaMaquina == null
        ? itemsMaquinas
        : itemsMaquinas.filter(
            (x) =>
              (x.tblTipoMaquinaNCategoriaMaquina
                ? x.tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina
                : -1) == catSel.idCategoriaMaquina,
          )
      : itemsMaquinas;
  }

  dxDropdownBox_CategoriasMaq_render(data) {
    if (data === null) data = {};
    return (
      <div className="d-flex flex-row align-items-center">
        <div
          className="flex-grow-1-"
          style={{
            flex: "60%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {data.denominacion}
        </div>
        <div className="flex-grow-1-" style={{ flex: "40%" }}>
          <Progress
            className="progress-bar-lg progress-bar-rounded"
            color={data.color_operatividadCategoria}
            barClassName="he-100"
            value={data.operatividadCategoria}
            max={1}
          >
            <div className="progress-bar-info pl-2 text-white font-weight-bold">
              {formatNumber(
                data.operatividadCategoria,
                (data.operatividadCategoria * 100) % 1 !== 0 ? 2 : 0,
                "percent",
              )}
            </div>
          </Progress>
        </div>
      </div>
    );
  }

  //INCIDENCIAS
  crearIncidencia() {
    const { lavanderia, user } = this.props;

    this.idIncidenciaSel = null;
    let incidenciaSel = {};

    incidenciaSel.tblUsuarioCrea = {};
    incidenciaSel.tblUsuarioRevisor = {};

    incidenciaSel.idIncidencia = null;
    incidenciaSel.fechaIncidencia = null;
    incidenciaSel.descripcionIncidencia = "";
    incidenciaSel.idUsuarioCrea = user.idUsuario;
    incidenciaSel.idUsuarioRevisor = null;
    incidenciaSel.idLavanderia = lavanderia.idLavanderia;
    incidenciaSel.tblUsuarioCrea.nombre = user.nombre;
    incidenciaSel.tblUsuarioRevisor.nombre = null;
    incidenciaSel.idSubTipoIncidencia = null;
    incidenciaSel.isRevisada = false;
    incidenciaSel.isInformativa = true;
    incidenciaSel.tblIncidencia_Documento = [];
    incidenciaSel.tblParteTrabajo = [];
    incidenciaSel.estado = false;
    incidenciaSel.fechaCierre = null;
    //Modificación
    incidenciaSel.idUsuarioResponsable = null;
    //SAT
    incidenciaSel.idMaquina = null;
    incidenciaSel.estadoMaquina = null;
    incidenciaSel.peso = null;

    this.setState({
      incidenciaSel: incidenciaSel,
    });
  }

  cargarIncidencia(incidenciaSel) {
    let _this = this;

    this.idIncidenciaSel = incidenciaSel.idIncidencia;
    $.when(
      _this.datasource_tblIncidencia_Documento.load(),
      _this.datasource_tblParteTrabajo.load(),
    ).then(function () {
      incidenciaSel.tblIncidencia_Documento = [];
      incidenciaSel.tblParteTrabajo = [];

      $.merge(
        incidenciaSel.tblIncidencia_Documento,
        _this.datasource_tblIncidencia_Documento.items(),
      );
      $.merge(
        incidenciaSel.tblParteTrabajo,
        _this.datasource_tblParteTrabajo.items(),
      );
      _this.setState({
        incidenciaSel: incidenciaSel,
      });
    });
  }

  onRowPrepared_gridIncidencias(e) {
    if (e.rowType === "data") {
      if (e.data.estado === true) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }
  onRowClick_gridIncidencias(e) {
    let is_adaptiveVisible = false; // checkea si columna adaptive (3 puntitos) es visible o no
    $.each(e.component.getVisibleColumns(), function (index, item) {
      if (item.type === "adaptive")
        is_adaptiveVisible =
          item.visibleWidth !== null && item.visibleWidth > 0;
    });

    if (is_adaptiveVisible) {
      setTimeout(() => {
        e.component.collapseAdaptiveDetailRow();
        e.component.expandAdaptiveDetailRow(e.data.idIncidencia);
      }, 0);
    }
  }
  onAdaptiveDetailRowPreparing_gridIncidencias(e) {
    e.formOptions.labelLocation = "left";
    e.formOptions.colCountByScreen = {
      xs: 1,
    };

    $.each(e.formOptions.items, function (index, item) {
      item.label = { alignment: "left" };
      item.cssClass = "text-left white-space-normal";

      if (item.dataField === "descripcionIncidencia") item.cssClass += " pb-1";
    });
  }
  onToolbarPreparing_gridIncidencias(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        toolbar: "bottom",
        options: {
          width: "100%",
          icon: "plus",
          text: getTrad("incidencia"),
          type: "normal",
          onClick: function () {
            _this.crearIncidencia();
          },
        },
      },
      {
        location: "before",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          width: "100%",
          icon: "exportxlsx",
          text: getTrad("exportar"),
          type: "normal",
          visible: !get_isMovil(this.props.resolucion),
          onClick: function () {
            e.component.exportToExcel();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onInitialized: function (e) {
            tooltipControl_creacion(e.element, e.component.option("text"));
          },
          onClick: function () {
            _this.setState({ maquinaSel: null, categoriaMaquinaSel: null });
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onInitialized: function (e) {
            tooltipControl_creacion(e.element, e.component.option("text"));
          },
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }

  renderEditCell_modificarIncidencia = (itemData) => {
    let _this = this;
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={() => _this.cargarIncidencia(itemData.data)}
      >
        <div className="icon_Edit font-size-xl" />
      </div>
    );
  };

  renderEditCell_estadoMaquina = (itemData) => {
    let estadoMaquina = this.enum_estadosMaquina.find(
      (x) => x.estadoMaquina === itemData.data.estadoMaquina,
    );
    if (estadoMaquina) {
      return (
        <div className="container_spanCentrado">
          <div
            className="pr-2 pl-2"
            style={{
              backgroundColor: estadoMaquina.color,
              borderRadius: "5px",
              height: "20px",
              width: "15px",
            }}
          ></div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  //POPUP INCIDENCIAS
  onContentReady_popupIncidencias(e) {
    var html = e.component.content();
    $(html).css("padding", "5");
  }

  dxForm_incidencia_onFieldDataChanged(e) {
    if (e.dataField === "estadoMaquina") {
      let isVisible;
      if (e.value !== 2 || e.value == null) isVisible = true;
      else isVisible = false;

      e.component.beginUpdate();
      e.component.itemOption("groupIncidencia.peso", "visible", isVisible);
      e.component.itemOption(
        "groupIncidencia.estadoMaquina",
        "colSpan",
        isVisible ? 1 : 2,
      );
      e.component.updateData({ peso: isVisible ? 3 : null });
      e.component.endUpdate();
    }
  }

  hiding_popupIncidencias() {
    this.idIncidenciaSel = null;
    this.setState({
      incidenciaSel: null,
      parteTrabajoSel: null,
    });
  }

  submit_formIncidencias(e) {
    e.preventDefault();

    let _this = this;
    this.hiding_popupIncidencias();

    const { incidenciaSel } = this.state;

    $.each(incidenciaSel.tblIncidencia_Documento, function (index, documento) {
      delete documento.idDocumento;
    });

    delete incidenciaSel.tblParteTrabajo;
    delete incidenciaSel.tblUsuarioCrea;
    delete incidenciaSel.tblUsuarioRevisor;
    delete incidenciaSel.tblMaquina;
    delete incidenciaSel.tblAccionesCorrectivas;
    delete incidenciaSel.fechaUltimoParte;

    let dxButton_guardar_incidencia = $(
      "#dxButton_guardar_incidencia",
    ).dxButton("instance");
    dxButton_guardar_incidencia.option("disabled", true);
    if (incidenciaSel.idIncidencia === null) {
      //POST
      delete incidenciaSel.idIncidencia;
      _this.datasource_tblIncidencia
        .store()
        .insert(incidenciaSel)
        .done(function () {
          _this.datasource_tblIncidencia.reload();
          _this.datasource_datos_selector.load();
          dxButton_guardar_incidencia.option("disabled", false);
        })
        .fail(function (error) {
          dxButton_guardar_incidencia.option("disabled", false);
        });
    } else {
      //UPDATE
      _this.datasource_tblIncidencia
        .store()
        .update(incidenciaSel.idIncidencia, incidenciaSel)
        .done(function () {
          _this.datasource_tblIncidencia.reload();
          dxButton_guardar_incidencia.option("disabled", false);
        })
        .fail(function (error) {
          dxButton_guardar_incidencia.option("disabled", false);
        });
    }
  }

  onClick_btnAtras() {
    this.setState({ parteTrabajoSel: null });
  }

  render_FileUploader(data) {
    const { show_Gallery } = this.state;
    let incidenciaSel = data.component.option("formData");
    if (incidenciaSel.tblIncidencia_Documento) {
      var imagenes_Gallery = $.grep(
        incidenciaSel.tblIncidencia_Documento,
        function (doc) {
          return doc.extension.split("/")[0] === "image";
        },
      );

      return (
        <Fragment>
          <TileView
            id="dxTileView_documentos"
            dataSource={incidenciaSel.tblIncidencia_Documento}
            noDataText={getTrad("alert_NoArchivosAdjuntos")}
            hoverStateEnabled={true}
            baseItemHeight={75}
            baseItemWidth={75}
            direction="horizontal"
            height="100"
            width="100%"
            itemMargin={10}
            showScrollbar={true}
            itemRender={this.render_FileUploader_Item}
          />
          <FileUploader
            width="100%"
            labelText={
              get_isMovil(this.props.resolucion)
                ? ""
                : getTrad("msg_arrastraArchivo5mb")
            }
            accept="*"
            minFileSize={1}
            multiple={true}
            uploadMode="instantly"
            showFileList={false}
            uploadFailedMessage={getTrad("subidaFallida")}
            selectButtonText={getTrad("subirArchivo")}
            onUploadStarted={this.onUploadStarted_FileUploader}
          />
          <Popup
            showTitle={true}
            title={getTrad("galeriaImagenes")}
            showCloseButton={true}
            closeOnOutsideClick={true}
            width="900px"
            height="700px"
            visible={show_Gallery}
            onHiding={this.hiding_popupGallery}
          >
            <Gallery
              dataSource={imagenes_Gallery}
              showIndicator={true}
              showNavButtons={true}
              loop={true}
              height="100%"
              width="100%"
              itemRender={this.render_Gallery_Item}
            />
          </Popup>
        </Fragment>
      );
    }
    return false;
  }

  onUploadStarted_FileUploader(e) {
    let _this = this;
    const { incidenciaSel } = this.state;
    var reader = new FileReader();

    if (e.file.size > 5242880) {
      notify({
        message: getTrad("subirArchivo"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    } else {
      reader.onloadend = function () {
        if (reader.result !== "") {
          var fileSplit = reader.result.split(",");
          var extension = e.file.type;
          var base64 = fileSplit[1];
          var fileName = e.file.name;

          function insertarDocumento() {
            e.component.option({
              value: [],
            });

            _this.setState((prevState) => {
              let incidenciaSel = { ...prevState.incidenciaSel };
              incidenciaSel.tblIncidencia_Documento =
                incidenciaSel.tblIncidencia_Documento.concat({
                  idDocumento: new Guid(),
                  nombre: fileName,
                  documento: base64,
                  extension: extension,
                });
              return { incidenciaSel };
            });
          }

          //Compress
          if (extension.split("/")[0] === "image") {
            compressBase64Img_scaleWidth(reader.result, 800).then(
              function (compressedBase64) {
                base64 = compressedBase64;
                insertarDocumento();
              },
            );
          } else {
            insertarDocumento();
          }
        }
      };
      reader.readAsDataURL(e.file);
    }

    e.request.abort();
  }

  onRowRemoved_tblRecambioNAlmacenRecambios(e) {
    $(".dxDateBoxForm")
      .dxDateBox("instance")
      .option("disabled", e.component.getDataSource().items().length > 0);
  }

  render_FileUploader_Item(data) {
    let _this = this;

    function download_click() {
      var downloadLink = document.createElement("a");
      downloadLink.href =
        "data:" + data.extension + ";base64," + data.documento;
      downloadLink.download = data.nombre;
      downloadLink.click();
    }

    function eliminar_click() {
      dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
        [
          getTrad("aceptar"),
          function () {
            _this.setState((prevState) => {
              let incidenciaSel = { ...prevState.incidenciaSel };
              incidenciaSel.tblIncidencia_Documento =
                incidenciaSel.tblIncidencia_Documento.filter(
                  (x) => x.idDocumento !== data.idDocumento,
                );
              return { incidenciaSel };
            });
          },
          "danger",
        ],
        [getTrad("cancelar"), function () {}],
      ]);
    }

    function img_click() {
      _this.setState({ show_Gallery: true });
    }

    function noImg_click() {
      notify({
        message: getTrad("alert_SoloAbrirImagenes"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }

    let tipoDoc = "";
    let icon_tipoDoc = "";

    if (data.extension.split("/")[0] === "image") {
      tipoDoc = "image";
      icon_tipoDoc = "";
    } else if (data.extension.split("/")[1] === "pdf") {
      tipoDoc = "pdf";
      icon_tipoDoc = "dx-icon-exportpdf";
    } else if (data.extension.split("/")[0] === "video") {
      tipoDoc = "video";
      icon_tipoDoc = "dx-icon-video";
    } else if (
      data.extension.includes("excel") ||
      data.extension.includes("spreadsheetml")
    ) {
      tipoDoc = "excel";
      icon_tipoDoc = "dx-icon-exportxlsx";
    } else {
      tipoDoc = "doc";
      icon_tipoDoc = "dx-icon-doc";
    }

    return (
      <div id="contenedorImg" className="pointer">
        {tipoDoc === "image" ? (
          <img
            className="image"
            style={{
              backgroundImage:
                "url(data:" +
                data.extension +
                ";base64," +
                data.documento +
                ")",
            }}
            onClick={img_click}
          ></img>
        ) : (
          <div className="container_spanCentrado" onClick={noImg_click}>
            <div className={icon_tipoDoc + " font-size-xxl"} />
          </div>
        )}
        <Toolbar
          className="dxToolbar_opciones position-absolute"
          style={{ bottom: "-4px", right: "2px" }}
        >
          <ToolbarItem location="after">
            <div
              className="dxToolbar_opcion container_spanCentrado"
              onClick={download_click}
            >
              <div className="icon_Arrow_Down"></div>
            </div>
          </ToolbarItem>
          <ToolbarItem location="after">
            <div
              className="dxToolbar_opcion container_spanCentrado"
              onClick={eliminar_click}
            >
              <div className="icon_Cerrar"></div>
            </div>
          </ToolbarItem>
        </Toolbar>
      </div>
    );
  }

  hiding_popupGallery() {
    setTimeout(() => {
      this.setState({
        show_Gallery: false,
      });
    }, 0);
  }

  render_Gallery_Item(data) {
    return (
      <img
        className="image"
        style={{
          backgroundImage:
            "url(data:" + data.extension + ";base64," + data.documento + ")",
        }}
      ></img>
    );
  }

  screenByWidth_resolucion() {
    return this.props.resolucion;
  }

  render_tblParteTrabajo(data) {
    let { resolucion } = this.props;
    let incidenciaSel = data.component.option("formData");
    if (incidenciaSel.tblParteTrabajo) {
      return (
        <DataGrid
          //Datos
          elementAttr={{ id: "dxDataGrid_parteTrabajo_monitorSat" }}
          dataSource={incidenciaSel.tblParteTrabajo}
          keyExpr={"idParteTrabajo"}
          //Propiedades
          columnsAutoWidth={true}
          height={350}
          hoverStateEnabled={true}
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          onAdaptiveDetailRowPreparing={
            this.onAdaptiveDetailRowPreparing_gridParteTrabajo
          }
          onToolbarPreparing={this.onToolbarPreparing_tblParteTrabajo}
          onEditingStart={this.onEditingStart_tblParteTrabajo}
          onRowRemoving={this.onRowRemoving_tblParteTrabajo}
        >
          <SearchPanel visible={false} />
          <HeaderFilter visible={false} />
          <FilterRow visible={false} />
          <Pager visible={false} />
          <Column
            caption=" "
            width={50}
            alignment="center"
            cssClass="p-0"
            cellComponent={editCellComponent}
          />
          <Column
            dataField="codigo"
            caption={getTrad("codigo")}
            hidingPriority={1}
            width={get_isMovil(this.props.resolucion) ? 80 : 100}
            alignment="center"
          />
          <Column
            dataField="fecha"
            caption={getTrad("fecha")}
            width={get_isMovil(this.props.resolucion) ? 100 : 120}
            alignment="center"
            format={this.date_format}
          />
          <Column
            dataField="resolucion"
            caption={
              get_isMovil(this.props.resolucion)
                ? "Descripción res."
                : getTrad("descripcionResolucion")
            }
            hidingPriority={0}
            alignment="left"
          />
          <Column
            dataField="tblIncidenciaNParte[0].estadoActual"
            caption={getTrad("estadoFinal")}
            width={get_isMovil(resolucion) ? 80 : 100}
            alignment="center"
            calculateDisplayValue={
              this.calculateDisplayValue_tblIncidenciaNParte_estadoActual
            }
            cellComponent={colorCellComponent}
          />
        </DataGrid>
      );
    }
  }
  onAdaptiveDetailRowPreparing_gridParteTrabajo(e) {
    e.formOptions.labelLocation = "left";
    e.formOptions.colCountByScreen = {
      xs: 1,
    };

    $.each(e.formOptions.items, function (index, item) {
      item.label = { alignment: "left" };
      item.cssClass = "text-left white-space-normal";
    });
  }
  onToolbarPreparing_tblParteTrabajo(e) {
    let _this = this;
    let { incidenciaSel } = this.state;
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      options: {
        width: null,
        icon: "plus",
        text: "Añadir parte trabajo",
        type: "normal",
        disabled:
          incidenciaSel &&
          (incidenciaSel.idIncidencia === null ||
            incidenciaSel.estado === true),
        onClick: function (e) {
          _this.crearParteTrabajo();
        },
      },
    });
  }
  onEditingStart_tblParteTrabajo(e) {
    e.cancel = true;
    let parteSel = $.extend(true, {}, e.data);
    this.setState({ parteTrabajoSel: parteSel }, () => {
      setTimeout(() => {
        $(".dxDateBoxForm")
          .dxDateBox("instance")
          .option(
            "disabled",
            this.state.parteTrabajoSel.tblRecambioNParteTrabajo.length > 0,
          );
      }, 0);
    });
  }
  onRowRemoving_tblParteTrabajo(e) {
    this.datasource_tblParteTrabajo.store().remove(e.key);
  }
  calculateDisplayValue_tblIncidenciaNParte_estadoActual(rowData) {
    let estadoActual = rowData.tblIncidenciaNParte[0].estadoActual;
    return this.enum_EstadoParteTrabajo.filter(
      (x) => x.valor === estadoActual,
    )[0].color;
  }

  //PARTES DE TRABAJO
  crearParteTrabajo() {
    let _this = this;
    const { incidenciaSel } = _this.state;
    let parteTrabajoSel = {};

    parteTrabajoSel.fecha = new Date(new Date().setSeconds(0));
    parteTrabajoSel.idLavanderia = incidenciaSel.idLavanderia;
    parteTrabajoSel.idMaquina = incidenciaSel.idMaquina;
    parteTrabajoSel.tblPersonasNParte = [];
    parteTrabajoSel.tblRecambioNParteTrabajo = [];
    parteTrabajoSel.tblRecambioNParteTrabajoIBS = [];
    parteTrabajoSel.tblServicioExternoNParteTrabajo = [];
    parteTrabajoSel.tblIncidenciaNParte = [
      {
        idIncidencia: incidenciaSel.idIncidencia,
        estadoInicial: incidenciaSel.estadoMaquina,
        estadoActual: null,
        fecha: new Date(),
        peso: null,
        peso: incidenciaSel.pesoInicial,
      },
    ];

    _this.setState({ parteTrabajoSel: parteTrabajoSel });
    $(".dxDateBoxForm").dxDateBox("instance").option("disabled", false);
  }

  onContentReady_form_parteTrabajo(e) {
    var form = e.component;
    let { incidenciaSel } = this.state;

    if (incidenciaSel?.fechaIncidencia)
      form.getEditor("fecha").option("min", incidenciaSel.fechaIncidencia);
  }

  dxForm_partTrabajo_onFieldDataChanged(e) {
    if (e.dataField === "tblIncidenciaNParte[0].estadoActual") {
      e.component.beginUpdate();
      e.component.itemOption(
        "groupParteTrabajo.peso",
        "visible",
        e.value === 1,
      );
      e.component.itemOption(
        "groupParteTrabajo.resolucion",
        "colSpan",
        e.value === 1 ? 7 : 8,
      );
      e.component.updateData({
        "tblIncidenciaNParte[0].peso": e.value === 1 ? 3 : null,
      }); //todo
      e.component.endUpdate();
    }
  }

  render_tblPersonasNParte = (data) => {
    let parteTrabajoSel = data.component.option("formData");

    return (
      <DataGrid
        //Datos
        dataSource={parteTrabajoSel.tblPersonasNParte}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        onToolbarPreparing={this.onToolbarPreparing_tblPersonasNParte}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} />
        <Column
          dataField="idPersona"
          caption={getTrad("nombre")}
          allowEditing={false}
        >
          <Lookup_grid
            dataSource={this.datasource_tblPersona_Tecnicos.items()}
            valueExpr={"idPersona"}
            displayExpr={"nombreCompuesto"}
          />
        </Column>
        <Column
          dataField="horas"
          caption={getTrad("horas")}
          alignment="center"
          dataType="datetime"
          width={80}
          format="HH:mm"
          mask="00:00"
          cssClass="dx-Cell_Editable"
          editorOptions={this.time_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onToolbarPreparing_tblPersonasNParte(e) {
    let _this = this;
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirTecnico"),
        type: "normal",
        onClick: function () {
          _this.dxDataGrid_tblPersonasNParte.option(
            "dataSource",
            _this.getDataSource_Add_tblPersonasNParte(),
          );
          _this.setState({ showPopup_Add_tblPersonasNParte: true });
        },
      },
    });
  }

  onShown_popup_Add_tblPersonasNParte(e) {
    this.dxDataGrid_tblPersonasNParte.updateDimensions();
  }

  hiding_popup_Add_tblPersonasNParte() {
    this.setState({ showPopup_Add_tblPersonasNParte: false });
  }

  //Se obtienen los técnicos totales de la Lavanderia, con las horas del parte seleccionado
  getDataSource_Add_tblPersonasNParte() {
    const { parteTrabajoSel } = this.state;
    let tecnicos_all = [...this.datasource_tblPersona_Tecnicos.items()];

    if (parteTrabajoSel) {
      let datasource = $.grep(tecnicos_all, function (item) {
        var personaEncontrada = $.grep(
          parteTrabajoSel.tblPersonasNParte,
          function (i) {
            return item.idPersona === i.idPersona;
          },
        );
        return personaEncontrada.length === 0;
      });

      return datasource.map(function (tec) {
        return {
          idPersona: tec.idPersona,
          nombreCompuesto: tec.nombreCompuesto,
          horas: new Date(2000, 1, 0, 0, 0, 0),
        };
      });
    } else {
      return [];
    }
  }

  onClick_btnAddPersonasNParte() {
    let _this = this;
    const { parteTrabajoSel } = this.state;
    let tecnicosAdd = this.dxDataGrid_tblPersonasNParte.option("dataSource");

    this.dxDataGrid_tblPersonasNParte.saveEditData().done(function () {
      let tecnicosMod = tecnicosAdd.filter(function (tecnico) {
        var horaTecnico = tecnico.horas.getHours();
        var minutoTecnico = tecnico.horas.getMinutes();

        if (minutoTecnico !== 0 || horaTecnico !== 0) {
          return true;
        }

        return false;
      });

      tecnicosMod = tecnicosMod.map(function (tecnico) {
        return {
          idPersona: tecnico.idPersona,
          horas: tecnico.horas,
        };
      });

      parteTrabajoSel.tblPersonasNParte =
        parteTrabajoSel.tblPersonasNParte.concat(tecnicosMod);
      _this.setState({
        showPopup_Add_tblPersonasNParte: false,
        parteTrabajoSel: parteTrabajoSel,
      });
    });
  }

  render_tblRecambioNParteTrabajoIBS = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        ref={this.dxDataGrid_tblRecambioNParteTrabajo_REF}
        //Datos
        dataSource={this.state.parteTrabajoSel.tblRecambioNParteTrabajoIBS}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblRecambioNParteTrabajoIBS}
        onToolbarPreparing={this.onToolbarPreparing_tblRecambioNParteTrabajoIBS}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} allowAdding={true} />
        <Column
          dataField="referenciaRecambio"
          caption={getTrad("referencia")}
          alignment="center"
          width={100}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="denominacion"
          caption={getTrad("denominacion")}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={90}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          width={120}
          format={this.currency_format}
          editorOptions={this.currency_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={145}
          allowEditing={false}
          format={this.currency_format}
          calculateDisplayValue={this.calculateDisplayValue_importeRecambioIBS}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };

  onInitNewRow_tblRecambioNParteTrabajoIBS(e) {
    e.data.cantidad = 0;
    e.data.precio = 0;
  }
  calculateDisplayValue_importeRecambioIBS(rowData) {
    return rowData.precio * rowData.cantidad;
  }
  onToolbarPreparing_tblRecambioNParteTrabajoIBS(e) {
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirRecambio"),
        type: "normal",
        onClick: function () {
          e.component.addRow();
        },
      },
    });
  }

  render_tblRecambioNParteTrabajo = (data) => {
    let { resolucion } = this.props;

    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        //Datos
        elementAttr={{ id: "dxDataGrid_recambios_monitorSat" }}
        ref={this.dxDataGrid_tblRecambioNParteTrabajo_REF}
        dataSource={this.state.parteTrabajoSel.tblRecambioNParteTrabajo}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        loadPanel={{ enabled: false }}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblRecambioNParteTrabajo}
        onCellPrepared={this.onCellPrepared_tblRecambioNParteTrabajo}
        onToolbarPreparing={this.onToolbarPreparing_tblRecambioNParteTrabajo}
        onRowRemoved={this.onRowRemoved_tblRecambioNAlmacenRecambios}
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Column
          dataField="idRecambioNavigation.referencia"
          caption={getTrad("refFabricante")}
          alignment="center"
          width={get_isMovil(resolucion) ? 85 : 110}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="idRecambioNavigation.denominacion"
          caption={getTrad("denominacion")}
          hidingPriority={get_isMovil(resolucion) ? 0 : null}
          sortOrder="asc"
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="idAlmacenNavigation.denominacion"
          caption={getTrad("almacen")}
          hidingPriority={get_isMovil(resolucion) ? 2 : null}
          alignment="left"
        />
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={get_isMovil(resolucion) ? 60 : 75}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          hidingPriority={get_isMovil(resolucion) ? 1 : null}
          width={get_isMovil(resolucion) ? 100 : 180}
          calculateDisplayValue={this.calculateDisplayValue_precio}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={get_isMovil(resolucion) ? 60 : 120}
          allowEditing={false}
          calculateDisplayValue={this.calculateDisplayValue_importeRecambio}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          visible={
            parteTrabajoSel !== null &&
            parteTrabajoSel.idParteTrabajo === undefined
          }
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onInitNewRow_tblRecambioNParteTrabajo(e) {
    e.data.cantidad = 0;
  }
  onCellPrepared_tblRecambioNParteTrabajo(e) {
    if (e.rowType === "data" && e.column.dataField === "precio") {
      let codigo =
        e.data.idAlmacenNavigation.idMonedaNavigation !== null &&
        e.data.idAlmacenNavigation.idMonedaNavigation !== undefined
          ? e.data.idAlmacenNavigation.idMonedaNavigation.codigo
          : "EUR";
      return formatNumber(e.data.precio, 2, "currency", codigo);
    }
  }
  calculateDisplayValue_precio(rowData) {
    let codigo =
      rowData.idAlmacenNavigation.idMonedaNavigation !== null &&
      rowData.idAlmacenNavigation.idMonedaNavigation !== undefined
        ? rowData.idAlmacenNavigation.idMonedaNavigation.codigo
        : "EUR";
    return formatNumber(rowData.precio, 2, "currency", codigo);
  }
  calculateDisplayValue_importeRecambio(rowData) {
    let codigo =
      rowData.idAlmacenNavigation.idMonedaNavigation !== null &&
      rowData.idAlmacenNavigation.idMonedaNavigation !== undefined
        ? rowData.idAlmacenNavigation.idMonedaNavigation.codigo
        : "EUR";
    let importe = rowData.precio * rowData.cantidad;

    return formatNumber(importe, 2, "currency", codigo);
  }

  onToolbarPreparing_tblRecambioNParteTrabajo(e) {
    let _this = this;
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });

    e.toolbarOptions.height = 36;

    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirRecambio"),
        type: "normal",
        disabled: get_isMovil(_this.props.resolucion),
        onClick: function () {
          _this.setState({ showPopup_Add_tblRecambioNParteTrabajo: true });
        },
      },
    });
  }

  calculateFilterExpression_idCategoriaMaquina(
    filterValue,
    selectedFilterOperation,
  ) {
    if (filterValue === -1) {
      return [this.dataField, "=", null];
    }

    return this.defaultCalculateFilterExpression.apply(this, arguments);
  }

  render_tblServicioExternoNParteTrabajo = (data) => {
    let parteTrabajoSel = data.component.option("formData");
    return (
      <DataGrid
        ref={this.dxDataGrid_tblServicioExternoNParteTrabajo_REF}
        //Datos
        dataSource={parteTrabajoSel.tblServicioExternoNParteTrabajo}
        //Propiedades
        columnsAutoWidth={true}
        height={250}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        //Eventos
        onInitNewRow={this.onInitNewRow_tblServicioExternoNParteTrabajo}
        onToolbarPreparing={
          this.onToolbarPreparing_tblServicioExternoNParteTrabajo
        }
      >
        <SearchPanel visible={false} />
        <HeaderFilter visible={false} />
        <FilterRow visible={false} />
        <Pager visible={false} />
        <Editing mode="cell" allowUpdating={true} allowAdding={true} />
        <Column
          dataField="denominacion"
          caption={getTrad("denominacion")}
          alignment="left"
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="cantidad"
          caption={getTrad("cantidad")}
          alignment="center"
          dataType="number"
          width={90}
          format={this.decimal_format}
          editorOptions={this.decimal_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          dataField="precio"
          caption={getTrad("precio")}
          alignment="center"
          dataType="number"
          width={120}
          format={this.currency_format}
          editorOptions={this.currency_editorOptions}
        >
          <RequiredRule />
        </Column>
        <Column
          caption={getTrad("importe")}
          alignment="center"
          dataType="number"
          width={145}
          allowEditing={false}
          format={this.currency_format}
          calculateDisplayValue={this.calculateDisplayValue_importeServicio}
        />
        <Column
          caption=" "
          width={50}
          alignment="center"
          cssClass="p-0"
          cellComponent={removeCellComponent}
        />
      </DataGrid>
    );
  };
  onInitNewRow_tblServicioExternoNParteTrabajo(e) {
    e.data.cantidad = 0;
    e.data.precio = 0;
  }
  calculateDisplayValue_importeServicio(rowData) {
    return rowData.precio * rowData.cantidad;
  }
  onToolbarPreparing_tblServicioExternoNParteTrabajo(e) {
    var toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name === "addRowButton") {
        item.visible = false;
      }
    });
    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirServicio"),
        type: "normal",
        onClick: function () {
          e.component.addRow();
        },
      },
    });
  }

  enviando = false;
  submit_formParteTrabajo(e) {
    e.preventDefault();

    if (!this.enviando) {
      this.enviando = true;

      let _this = this;
      const { parteTrabajoSel, incidenciaSel } = this.state;

      let parteSel_format = $.extend(true, {}, parteTrabajoSel);

      $.each(
        parteSel_format.tblRecambioNParteTrabajoIBS,
        function (index, recambio) {
          delete recambio.__KEY__;
        },
      );

      $.each(
        parteSel_format.tblServicioExternoNParteTrabajo,
        function (index, servicio) {
          delete servicio.__KEY__;
        },
      );

      $.each(
        parteSel_format.tblRecambioNParteTrabajo,
        function (index, recambio) {
          delete recambio.importe;
          delete recambio.idAlmacenNavigation;
          delete recambio.idRecambioNavigation;
        },
      );

      if (parteSel_format.codigo) {
        parteSel_format.codigo = parteSel_format.codigo.toString();
      }

      let dxButton_guardar_parte = $("#dxButton_guardar_parte").dxButton(
        "instance",
      );
      dxButton_guardar_parte.option("disabled", true);
      if (parteSel_format.idParteTrabajo) {
        //UPDATE, eliminamos el parte trabajo (Original) que se acaba de modificar
        incidenciaSel.tblParteTrabajo = incidenciaSel.tblParteTrabajo.filter(
          (x) => x.idParteTrabajo !== parteTrabajoSel.idParteTrabajo,
        );

        const idParteTrabajo = parteSel_format.idParteTrabajo;

        delete parteSel_format.idParteTrabajo;

        this.datasource_tblParteTrabajo
          .store()
          .update(idParteTrabajo, patchRequestHandler(parteSel_format))
          .then(
            () => {
              _this.datasource_tblIncidencia
                .store()
                .byKey(incidenciaSel.idIncidencia)
                .done(
                  function (incidencia) {
                    incidenciaSel.fechaCierre = incidencia.fechaCierre;
                    incidenciaSel.estado = incidencia.estado;
                    incidenciaSel.estadoMaquina = incidencia.estadoMaquina;
                    incidenciaSel.peso = incidencia.peso;

                    incidenciaSel.tblParteTrabajo.push(parteTrabajoSel);
                    _this.setState({
                      parteTrabajoSel: null,
                      incidenciaSel: incidenciaSel,
                    });
                    _this.datasource_datos_selector.load();
                    dxButton_guardar_parte.option("disabled", false);
                  },
                  () => (this.enviando = false),
                );
            },
            (e) => {
              dxButton_guardar_parte.option("disabled", false);
            },
          );
      } else {
        //INSERT
        parteSel_format.fecha = formatDateTime_parameter(
          new Date(
            new Date(parteSel_format.fecha).setHours(
              parteSel_format.fecha.getUTCHours(),
            ),
          ),
        );

        this.datasource_tblParteTrabajo
          .store()
          .insert(parteSel_format)
          .then(
            (parteTrabajo) => {
              _this.datasource_tblIncidencia
                .store()
                .byKey(incidenciaSel.idIncidencia)
                .done(
                  function (incidencia) {
                    parteTrabajoSel.idParteTrabajo =
                      parteTrabajo.idParteTrabajo;
                    parteTrabajoSel.codigo = parteTrabajo.codigo;
                    incidenciaSel.fechaCierre = incidencia.fechaCierre;
                    incidenciaSel.estado = incidencia.estado;
                    incidenciaSel.estadoMaquina = incidencia.estadoMaquina;
                    incidenciaSel.peso = incidencia.peso;

                    incidenciaSel.tblParteTrabajo.push(parteTrabajoSel);
                    _this.setState({
                      parteTrabajoSel: null,
                      incidenciaSel: incidenciaSel,
                    });
                    _this.datasource_datos_selector.load();
                    dxButton_guardar_parte.option("disabled", false);
                  },
                  () => (this.enviando = false),
                );
            },
            (e) => {
              dxButton_guardar_parte.option("disabled", false);
              this.enviando = false;
            },
          );
      }
    }
  }

  //#region Añadir recambio n parte trabajo

  onCellHoverChanged_tblRecambioNAlmacenRecambios(e) {
    if (e.column !== undefined && e.row !== undefined) {
      if (e.column.dataField == "cantidad" && e.row.data.is_añadido) {
        if (e.eventType === "mouseover") {
          this.dxPopover.option("contentTemplate", function (contentElement) {
            $("<div/>")
              .append("Debe eliminar el registro para poder insertar uno nuevo")
              .appendTo(contentElement);
          });
          this.dxPopover.option("target", e.cellElement);
          this.dxPopover.show();
        } else this.dxPopover.hide();
      }
    }
  }

  hiding_popup_Add_tblRecambioNParteTrabajo() {
    this.setState({ showPopup_Add_tblRecambioNParteTrabajo: false });
  }

  onClick_btnAddRecambioNParteTrabajo = (tblRecambioNParteTrabajo) => {
    const { parteTrabajoSel } = this.state;

    $(".dxDateBoxForm")
      .dxDateBox("instance")
      .option("disabled", tblRecambioNParteTrabajo.length > 0);

    this.setState(
      { parteTrabajoSel: { ...parteTrabajoSel, tblRecambioNParteTrabajo } },
      () => {
        this.dxDataGrid_tblRecambioNParteTrabajo.refresh();
      },
    );
  };

  //#endregion

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  //VARIOS
  getColor_operatividad(value) {
    return value < 0.75
      ? "danger"
      : value < 0.9
        ? "orange"
        : value < 1
          ? "yellow"
          : "success";
  }

  componentDidMount() {
    this.datasource_datos_selector.load();

    //DEVEXREME JQUERY
    const { lavanderia, user, idioma } = this.props;
    cargaJs(this, lavanderia, user, idioma);
  }

  componentDidUpdate(prevProps, prevState) {
    const { lavanderia, user, idioma, resolucion } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.currency_format.currency = lavanderia.moneda;
      this.currency_editorOptions.format = this.currency_format;

      cargaJs(this, lavanderia, user, idioma);
      this.cargaDatos_lavanderia();
    }
  }

  componentWillUnmount() {
    this.closeReport();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
  setLavanderia: (lavanderia) =>
    dispatch(globalActions.setLavanderia(lavanderia)),
  setReport: (report) => dispatch(impresionActions.setReport(report)),
  setParams: (params) => dispatch(impresionActions.setParams(params)),
  close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorSat);
