import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  durationToDatetime,
  dxRangeSelector_PosicionValor_nuevoMarker,
  leadingZero,
  create_rangeSelector,
  startOfMonth,
  endOfMonth,
  filtroTiempo_Resize,
  tooltipControl_creacion,
} from "../../../../helpers";

import $ from "jquery";
import query from "devextreme/data/query";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import { Workbook, gridCell } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var fechaDesde = null,
    fechaHasta = null;

  //#region DATASOURCE

  var datasource_filtroTiempo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infRRHH_filtroTiempo",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "fecha",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
      };
    },
  });

  var datasource_estadosCalendarioNPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infRRHH_spSelectEstadosCalendarioNPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
    sort: "idPersona",
  });

  var datasource_tblPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        let filtroLavanderia =
          "idLavanderia eq " +
          LAVANDERIA.idLavanderia +
          " or tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
          LAVANDERIA.idLavanderia +
          "))";
        if (typeof loadOptions.filter === "undefined") {
          loadOptions.filter = [filtroLavanderia];
        }
      },
      version: 4,
    }),
    select: ["idPersona", "nombre", "apellidos"],
    sort: "nombre",
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
      };
    },
  });

  var datasource_categoria = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoria",
      key: "idCategoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idCategoria", "denominacion"],
  });

  var datasource_estatoCalendario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioPersonal_Estado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_horasDiarias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infRRHH_spSelectHorasDiarias",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDataGrid_estadosCalendarioNPersona = $(
          "#dxDataGrid_estadosCalendarioNPersona",
        ).dxDataGrid("instance");
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.idPersona =
          dxDataGrid_estadosCalendarioNPersona.option("selectedRowKeys")[0];
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.lng = IDIOMA.codigo;
      },
      onLoaded: function (data) {
        $.each(data, function (index, item) {
          item.horaEntrada = item.horaEntrada
            ? durationToDatetime(item.horaEntrada)
            : null;
          item.horaSalida = item.horaSalida
            ? durationToDatetime(item.horaSalida)
            : null;
          item.horasTrabajadas = item.horasTrabajadas
            ? durationToDatetime(item.horasTrabajadas)
            : null;
          item.horasDesc = item.horasDesc
            ? durationToDatetime(item.horasDesc)
            : null;
          item.horasEfectivas = item.horasEfectivas
            ? durationToDatetime(item.horasEfectivas)
            : null;
          item.horasPrevDia = item.horasPrevDia
            ? durationToDatetime(item.horasPrevDia)
            : null;
        });
        return data;
      },
      version: 4,
    }),
    sort: "fecha",
  });

  var datasource_tblCategoriaConvenio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaConvenio",
      key: "idCategoriaConvenio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        let idPais = LAVANDERIA.idPais;
        let isOficina_checked_filter = false;

        if (loadOptions.filter)
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            ["idPais", "=", idPais],
            "and",
            ["isOficina", "=", isOficina_checked_filter],
          ];
        else
          loadOptions.filter = [
            ["idPais", "=", idPais],
            "and",
            ["isOficina", "=", isOficina_checked_filter],
          ];
      },
      version: 4,
    }),
    sort: ["denominacion"],
    expand: ["tblCategoriaInterna($top=1;$select=idCategoriaInterna)"],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.denominacion = item.denominacion.toUpperCase();
      });
      return data;
    },
  });

  var datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        let idPais = LAVANDERIA.idPais;
        let isOficina_checked_filter = false;

        if (loadOptions.filter)
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            ["idCategoriaConvenioNavigation/idPais", "=", idPais],
            "and",
            [
              "idCategoriaConvenioNavigation/isOficina",
              "=",
              isOficina_checked_filter,
            ],
          ];
        else
          loadOptions.filter = [
            ["idCategoriaConvenioNavigation/idPais", "=", idPais],
            "and",
            [
              "idCategoriaConvenioNavigation/isOficina",
              "=",
              isOficina_checked_filter,
            ],
          ];
      },
      version: 4,
    }),
    sort: ["denominacion"],
    select: ["idCategoriaInterna", "denominacion"],
    expand: ["idCategoriaConvenioNavigation($select=denominacion)"],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.denominacion = item.denominacion.toUpperCase();
      });
      return data;
    },
  });

  var datasource_tblCategoriaInterna_lookupGrid_grouped = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        let idPais = LAVANDERIA.idPais;
        let isOficina_checked_filter = false;

        if (loadOptions.filter)
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            ["idCategoriaConvenioNavigation/idPais", "=", idPais],
            "and",
            [
              "idCategoriaConvenioNavigation/isOficina",
              "=",
              isOficina_checked_filter,
            ],
          ];
        else
          loadOptions.filter = [
            ["idCategoriaConvenioNavigation/idPais", "=", idPais],
            "and",
            [
              "idCategoriaConvenioNavigation/isOficina",
              "=",
              isOficina_checked_filter,
            ],
          ];

        loadOptions.expand = [
          "idCategoriaConvenioNavigation($select=denominacion)",
        ];
      },
      version: 4,
    }),
    sort: ["denominacion"],
    select: ["idCategoriaInterna", "denominacion"],
    postProcess: function (data) {
      let groupedData = query(data)
        .groupBy(function (dataItem) {
          return dataItem.idCategoriaConvenioNavigation.denominacion;
        })
        .sortBy("key")
        .toArray();

      return groupedData;
    },
  });

  //#endregion

  $.when(
    datasource_filtroTiempo.load(),
    datasource_tblPersona.load(),
    datasource_categoria.load(),
    datasource_estatoCalendario.load(),
    datasource_tblCategoriaConvenio.load(),
    datasource_tblCategoriaInterna.load(),
    datasource_tblCategoriaInterna_lookupGrid_grouped.load(),
  )
    .then(function () {
      var itemsFiltro = datasource_filtroTiempo.items();

      fechaDesde = startOfMonth(
        new Date(itemsFiltro[itemsFiltro.length - 1].arg),
      );
      fechaHasta = new Date(itemsFiltro[itemsFiltro.length - 1].arg);

      $("#InfRRHH #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            baseSize: 70,
            template: function (e, index, item) {
              item.append(
                $("<div id='dxToolbar_title' />").dxToolbar({
                  items: [
                    {
                      location: "after",
                      template: function (data, index, template) {
                        template.css("padding-top", 5);
                        return $("<div />")
                          .addClass("dxSwitch_toolbar no-disabled")
                          .dxSwitch({
                            value: false,
                            switchedOnText: getTrad("detallado"),
                            switchedOffText: getTrad("resumido"),
                            width: 130,
                            onValueChanged: function (e) {
                              let dxDataGrid_estadosCalendarioNPersona = $(
                                "#dxDataGrid_estadosCalendarioNPersona",
                              ).dxDataGrid("instance");
                              set_dxSwitch_tipoGrid(
                                e.value,
                                dxDataGrid_estadosCalendarioNPersona,
                              );
                            },
                          });
                      },
                    },
                    {
                      location: "after",
                      template: function (data, index, template) {
                        template.css("padding-top", 5);
                        return $("<div />").dxButton({
                          text: getTrad("limpiarFiltro"),
                          icon: " icon_EliminarFiltros",
                          onClick: function (e) {
                            $("#dxDataGrid_estadosCalendarioNPersona")
                              .dxDataGrid("instance")
                              .clearFilter();
                            notify({
                              message: getTrad("aviso_C_FiltroRestaurado"),
                              type: "success",
                              displayTime: "1500",
                              closeOnClick: true,
                            });
                          },
                        });
                      },
                    },
                    {
                      location: "after",
                      template: function (data, index, template) {
                        template.css("padding-top", 5);
                        return $("<div />").dxButton({
                          text: getTrad("exportar"),
                          icon: "exportxlsx",
                          onClick: function () {
                            $("#dxDataGrid_estadosCalendarioNPersona")
                              .dxDataGrid("instance")
                              .exportToExcel();
                          },
                        });
                      },
                    },
                  ],
                }),
              );
            },
          },
          {
            ratio: 1,
            template: function (e, index, item) {
              datasource_estadosCalendarioNPersona.load().done(function () {
                item.css("padding-bottom", 25);
                item.append(
                  $(
                    "<div id='dxDataGrid_estadosCalendarioNPersona'/>",
                  ).dxDataGrid({
                    //Datos
                    dataSource: datasource_estadosCalendarioNPersona,
                    remoteOperations: false,
                    //Propiedades
                    columnsAutoWidth: true,
                    headerFilter: {
                      visible: true,
                    },
                    height: "100%",
                    filterRow: {
                      visible: true,
                      applyFilter: "auto",
                      showAllText: getTrad("todos").toUpperCase(),
                    },
                    hoverStateEnabled: true,
                    paging: {
                      enabled: false,
                    },
                    pager: {
                      visible: false,
                    },
                    selection: {
                      mode: "single",
                    },
                    export: {
                      enabled: false,
                      fileName: getTrad("infRRHH_estados"),
                    },
                    columns: [
                      {
                        dataField: "estado",
                        visible: false,
                        sortIndex: 1,
                        sortOrder: "desc",
                      },
                      {
                        dataField: "nombre",
                        caption: getTrad("nombre"),
                        minWidth: 300,
                        allowHeaderFiltering: false,
                        sortIndex: 2,
                        sortOrder: "asc",
                      },
                      {
                        dataField: "idCategoria",
                        visible: LAVANDERIA.idPais != 1,
                        width: 235,
                        caption: getTrad("categoria"),
                        allowHeaderFiltering: false,
                        lookup: {
                          dataSource: datasource_categoria.items(),
                          valueExpr: "idCategoria",
                          displayExpr: "denominacion",
                        },
                      },
                      {
                        dataField: "idCategoriaConvenio",
                        visible: LAVANDERIA.idPais == 1,
                        minWidth: 180,
                        caption: getTrad("categoriaConvenio"),
                        allowHeaderFiltering: false,
                        lookup: {
                          dataSource: datasource_tblCategoriaConvenio.items(),
                          valueExpr: "idCategoriaConvenio",
                          displayExpr: "denominacion",
                        },
                      },
                      {
                        dataField: "idCategoriaInterna",
                        visible: LAVANDERIA.idPais == 1,
                        minWidth: 200,
                        caption: getTrad("categoriaInterna"),
                        allowHeaderFiltering: false,
                        lookup: {
                          dataSource: datasource_tblCategoriaInterna.items(),
                          valueExpr: "idCategoriaInterna",
                          displayExpr: "denominacion",
                        },
                      },
                      {
                        dataField: "diasTrabajados",
                        caption: getTrad("diasTrabajados"),
                        width: 110,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "festivosTrab",
                        caption: "Fest. trabajados",
                        width: 115,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "diaLibre",
                        caption: getTrad("diasLibres"),
                        width: 85,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "vacaciones",
                        caption: getTrad("vacaciones"),
                        width: 90,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "asuntosPropios",
                        caption: getTrad("asuntosPropios"),
                        width: 120,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "faltas",
                        caption: getTrad("faltas"),
                        width: 80,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "baja",
                        caption: getTrad("baja"),
                        width: 70,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        caption: getTrad("sinAsignar"),
                        name: "sinAsignar",
                        width: 100,
                        dataType: "number",
                        allowFiltering: false,
                        alignment: "center",
                        calculateCellValue: function (e) {
                          var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                          var numDiasSel =
                            Math.round(
                              Math.abs(
                                (new Date(fechaDesde) - new Date(fechaHasta)) /
                                  oneDay,
                              ),
                            ) + 1;
                          var sumatorioEstados = 0;
                          // itera sobre los campos del grid que sean estados
                          $.each(e, function (index, item) {
                            if (
                              index !== "estado" &&
                              index !== "festivo" &&
                              index !== "horas" &&
                              index !== "idCategoria" &&
                              index !== "idCategoriaConvenio" &&
                              index !== "idCategoriaInterna" &&
                              index !== "idPersona" &&
                              index !== "nombre"
                            ) {
                              sumatorioEstados += item;
                            }
                          });
                          return numDiasSel - sumatorioEstados;
                        },
                        customizeText: function (e) {
                          if (!e.value) return "0";
                          return e.valueText;
                        },
                      },
                      {
                        dataField: "horasTrabajadas",
                        caption: getTrad("horasTrabajadas"),
                        width: 120,
                        allowFiltering: false,
                        alignment: "center",
                      },
                      {
                        dataField: "horasDesc",
                        caption: getTrad("horasDesc"),
                        width: 120,
                        allowFiltering: false,
                        alignment: "center",
                      },
                      {
                        dataField: "horasEfectivas",
                        caption: getTrad("horasEfectivas"),
                        width: 120,
                        allowFiltering: false,
                        alignment: "center",
                      },
                      {
                        dataField: "horasPrevDia",
                        caption: getTrad("horasPrevDia"),
                        width: 120,
                        allowFiltering: false,
                        alignment: "center",
                      },
                      {
                        dataField: "diferencia",
                        caption: getTrad("balanceHoras"),
                        width: 145,
                        allowFiltering: false,
                        alignment: "center",
                        fixedPosition: "right",
                        allowSorting: false,
                      },
                    ],
                    summary: {
                      totalItems: [
                        {
                          column: "nombre",
                          displayFormat: getTrad("total").toUpperCase(),
                        },
                        {
                          name: "diasTrabajados",
                          column: "diasTrabajados",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "festivosTrab",
                          column: "festivosTrab",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "diaLibre",
                          column: "diaLibre",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "vacaciones",
                          column: "vacaciones",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "asuntosPropios",
                          column: "asuntosPropios",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "faltas",
                          column: "faltas",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "baja",
                          column: "baja",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "sinAsignar",
                          column: "sinAsignar",
                          displayFormat: "{0}",
                          summaryType: "sum",
                        },
                        {
                          name: "horasTrabajadas",
                          column: "horasTrabajadas",
                          displayFormat: "{0}",
                          summaryType: "custom",
                        },
                        {
                          name: "horasDesc",
                          column: "horasDesc",
                          displayFormat: "{0}",
                          summaryType: "custom",
                        },
                        {
                          name: "horasEfectivas",
                          column: "horasEfectivas",
                          displayFormat: "{0}",
                          summaryType: "custom",
                        },
                        {
                          name: "horasPrevDia",
                          column: "horasPrevDia",
                          displayFormat: "{0}",
                          summaryType: "custom",
                        },
                        {
                          name: "diferencia",
                          column: "diferencia",
                          displayFormat: "{0}",
                          summaryType: "custom",
                        },
                      ],
                      calculateCustomSummary: function (options) {
                        var horas = 0;

                        if (
                          options.name === "diferencia" ||
                          options.name === "horasTrabajadas" ||
                          options.name === "horasDesc" ||
                          options.name === "horasEfectivas" ||
                          options.name === "horasPrevDia"
                        ) {
                          if (options.summaryProcess === "start") {
                            options.totalValue = 0;
                          }
                          if (options.summaryProcess === "calculate") {
                            if (options.value) {
                              horas = parseInt(options.value.split(":")[0]);
                              var minutos = parseInt(
                                options.value.split(":")[1],
                              );

                              if (isNegative(horas))
                                minutos = -Math.abs(minutos);

                              options.totalValue =
                                minutos +
                                horas * 60 +
                                parseInt(options.totalValue);
                            }
                          }
                          if (options.summaryProcess === "finalize") {
                            horas = leadingZero(
                              Math.abs(Math.trunc(options.totalValue / 60)),
                            );

                            options.totalValue =
                              (isNegative(options.totalValue) ? "-" : "") +
                              horas +
                              ":" +
                              leadingZero(Math.abs(options.totalValue % 60)) +
                              " ";
                          }
                        }
                      },
                    },
                    //EVENTOS
                    onInitialized: function (e) {
                      set_dxSwitch_tipoGrid(false, e.component);
                      e.component.columnOption(
                        "idCategoria",
                        "lookup.dataSource",
                        filtrar_dxLookupCategorias(),
                      );
                    },
                    onRowPrepared: function (e) {
                      if (e.rowType === "data") {
                        e.rowElement.css("cursor", "pointer");
                        if (e.data.estado === 0)
                          e.rowElement.addClass("disabledRow");
                      }
                    },
                    onCellPrepared: function (e) {
                      if (
                        e.rowType === "header" &&
                        e.column.dataField === "diferencia"
                      ) {
                        let icon = $("<span />").addClass(
                          "icon_Comment pointer pl-2",
                        );
                        tooltipControl_creacion(
                          icon,
                          getTrad("horasTrabajadas") +
                            " - " +
                            getTrad("horasEfectivas"),
                        );
                        e.cellElement
                          .find(".dx-datagrid-text-content")
                          .append(icon);
                      }
                      if (
                        (e.rowType === "data" || e.rowType === "totalFooter") &&
                        e.column.dataField === "diferencia"
                      )
                        e.cellElement.css({ "padding-right": 22 });
                    },
                    onEditorPreparing: function (e) {
                      if (
                        e.parentType == "filterRow" &&
                        e.dataField == "idCategoriaInterna"
                      ) {
                        e.editorName = "dxSelectBox";
                        e.editorOptions = {
                          items:
                            datasource_tblCategoriaInterna_lookupGrid_grouped.items(),
                          displayExpr: "denominacion",
                          valueExpr: "idCategoriaInterna",
                          grouped: true,
                          elementAttr: {
                            id: "categoriaInterna_lookupGrid_grouped_personal",
                          },
                          showClearButton: true,
                          placeholder: getTrad("todos").toUpperCase(),
                          onContentReady: function (e) {
                            e.component
                              .content()
                              .attr(
                                "id",
                                "dxPopup_dxDataGrid_tblPersona_lookUpFilter_catLav",
                              );
                          },
                          onValueChanged: function (arg) {
                            e.component.columnOption(
                              "idCategoriaInterna",
                              "filterValue",
                              arg.value,
                            );
                          },
                        };
                      }
                    },
                    onSelectionChanged: function (e) {
                      if (e.selectedRowKeys.length > 0) {
                        datasource_horasDiarias.reload().done(function () {
                          $("#dxPopup_horasDiarias").dxPopup("instance").show();
                        });
                      }
                    },
                    onExporting: function (e) {
                      let isVisible_columnasExtra = e.component.columnOption(
                        "horasPrevDia",
                        "visible",
                      );
                      let _this = this;
                      e.component.beginUpdate();
                      e.component.columnOption("horasPrevDia", "visible", true);
                      e.component.columnOption(
                        "horasTrabajadas",
                        "visible",
                        true,
                      );
                      e.component.columnOption(
                        "horasEfectivas",
                        "visible",
                        true,
                      );
                      e.component.columnOption("horasDesc", "visible", true);

                      const workbook = new Workbook();
                      const worksheet = workbook.addWorksheet("Sheet");
                      worksheet.columns = [
                        { width: 300 },
                        { width: 235 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 120 },
                        { width: 15 },
                        { width: 20 },
                        { width: 20 },
                        { width: 20 },
                      ];
                      exportDataGrid({
                        component: e.component,
                        worksheet: worksheet,
                      }).then(function () {
                        workbook.xlsx
                          .writeBuffer()
                          .then(function (buffer) {
                            saveAs(
                              new Blob([buffer], {
                                type: "application/octet-stream",
                              }),
                              "infRRHH_estados.xlsx",
                            );
                          })
                          .then(function () {
                            e.component.columnOption(
                              "horasPrevDia",
                              "visible",
                              isVisible_columnasExtra,
                            );
                            e.component.columnOption(
                              "horasTrabajadas",
                              "visible",
                              isVisible_columnasExtra,
                            );
                            e.component.columnOption(
                              "horasEfectivas",
                              "visible",
                              isVisible_columnasExtra,
                            );
                            e.component.columnOption(
                              "horasDesc",
                              "visible",
                              isVisible_columnasExtra,
                            );
                            e.component.endUpdate();
                          });
                      });
                      e.cancel = true;
                    },
                    //Estilos
                    showColumnLines: false,
                    showRowLines: true,
                    rowAlternationEnabled: true,
                  }),
                );
              });
            },
          },
          {
            // Range selector
            baseSize: 130,
            template: function (e, index, itemElement) {
              itemElement.append(
                create_rangeSelector(
                  datasource_filtroTiempo,
                  false,
                  false,
                  function (e) {
                    // onValueChanged
                    fechaDesde = new Date(e.value[0]);
                    fechaHasta = new Date(e.component.option("valueHasta"));

                    var dxDataGrid_estadosCalendarioNPersona = $(
                      "#dxDataGrid_estadosCalendarioNPersona",
                    ).dxDataGrid("instance");
                    dxDataGrid_estadosCalendarioNPersona
                      .refresh()
                      .done(function () {
                        dxDataGrid_estadosCalendarioNPersona.columnOption(
                          "idCategoria",
                          "lookup.dataSource",
                          filtrar_dxLookupCategorias(),
                        );
                      });
                    dxDataGrid_estadosCalendarioNPersona.deselectAll();
                    dxDataGrid_estadosCalendarioNPersona
                      .getScrollable()
                      .scrollTo(0);

                    dxRangeSelector_PosicionValor_nuevoMarker(
                      $("#dxRangeSelector_filtroTiempo"),
                      fechaDesde,
                      fechaHasta,
                    );
                  },
                  function () {
                    var ultimaFecha = new Date(
                      datasource_filtroTiempo.items()[
                        datasource_filtroTiempo.items().length - 1
                      ].arg,
                    );
                    $("#dxRangeSelector_filtroTiempo")
                      .dxRangeSelector("instance")
                      .option({
                        "scale.minRange": { days: null, months: 1 },
                        value: [
                          startOfMonth(ultimaFecha),
                          endOfMonth(new Date(ultimaFecha)),
                        ],
                      });
                    filtroTiempo_Resize();
                  },
                  function () {
                    $("#dxRangeSelector_filtroTiempo")
                      .dxRangeSelector("instance")
                      .option({
                        "scale.minRange": { days: 0, months: null },
                      });
                    filtroTiempo_Resize();
                  },
                ),
              );
            },
          },
        ],
      });
    })
    .always(function () {
      setTimeout(() => {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  $("#InfRRHH #dxPopup_horasDiarias").dxPopup({
    showTitle: true,
    title: getTrad("horasDiarias"),
    height: "70%",
    width: "70%",
    minWidth: 500,
    minHeight: 500,
    contentTemplate: function (contentElement) {
      contentElement.append(
        $("<div id='dxDataGrid_horasDiarias'/>").dxDataGrid({
          dataSource: datasource_horasDiarias,
          remoteOperations: false,
          //Propiedades
          columnsAutoWidth: true,
          headerFilter: {
            visible: true,
          },
          height: "100%",
          filterRow: {
            visible: false,
            applyFilter: "auto",
            showAllText: getTrad("todos").toUpperCase(),
          },
          loadPanel: {
            enabled: false,
          },
          paging: {
            enabled: false,
          },
          export: {
            enabled: true,
            texts: {
              exportAll: getTrad("exportar"),
            },
            fileName: getTrad("infRRHH_horasDiarias"),
          },
          columns: [
            {
              dataField: "fecha",
              caption: getTrad("fecha"),
              dataType: "datetime",
              format: "dd/MM/yyyy",
              width: 100,
              allowFiltering: false,
              allowSorting: false,
            },
            {
              dataField: "horaEntrada",
              caption: getTrad("horaEntrada"),
              width: 120,
              allowFiltering: false,
              alignment: "center",
              dataType: "datetime",
              format: "HH:mm",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
              allowSorting: false,
            },
            {
              dataField: "horaSalida",
              caption: getTrad("horaSalida"),
              width: 120,
              allowFiltering: false,
              alignment: "center",
              dataType: "datetime",
              format: "HH:mm",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
              allowSorting: false,
            },
            {
              dataField: "horasTrabajadas",
              caption: getTrad("horasTrabajadas"),
              width: 120,
              dataType: "datetime",
              format: "HH:mm",
              allowFiltering: false,
              alignment: "center",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },

              allowSorting: false,
            },
            {
              dataField: "horasDesc",
              caption: getTrad("horasDesc"),
              width: 120,
              dataType: "datetime",
              format: "HH:mm",
              allowFiltering: false,
              alignment: "center",

              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
            },
            {
              dataField: "horasEfectivas",
              caption: getTrad("horasEfectivas"),
              width: 135,
              dataType: "datetime",
              format: "HH:mm",
              allowFiltering: false,
              alignment: "center",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
              allowSorting: false,
            },
            {
              dataField: "horasPrevDia",
              caption: getTrad("horasPrevDia"),
              width: 120,
              dataType: "datetime",
              format: "HH:mm",
              allowFiltering: false,
              alignment: "center",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
              allowSorting: false,
            },
            {
              dataField: "diferencia",
              caption: getTrad("diferencia"),
              width: 100,
              allowFiltering: false,
              alignment: "center",
              customizeText: function (e) {
                if (!e.value) return "-";
                return e.valueText;
              },
              allowSorting: false,
            },
            {
              dataField: "tipoDia",
              caption: getTrad("tipoDia"),
              width: "12%",
              allowFiltering: false,
              alignment: "left",
              allowSorting: false,
            },
            {
              dataField: "denoLav",
              caption: getTrad("lavanderia"),
              width: "18%",
              allowFiltering: false,
              alignment: "left",
              allowSorting: false,
              cellTemplate: function (element, info) {
                element.append(
                  $("<div />").text(!info.value ? "-" : info.value),
                );
              },
            },
          ],
          summary: {
            totalItems: [
              {
                column: "fecha",
                displayFormat: getTrad("total").toUpperCase(),
              },
              {
                name: "horasTrabajadas",
                column: "horasTrabajadas",
                displayFormat: "{0}",
                summaryType: "custom",
              },
              {
                name: "horasDesc",
                column: "horasDesc",
                displayFormat: "{0}",
                summaryType: "custom",
              },
              {
                name: "horasEfectivas",
                column: "horasEfectivas",
                displayFormat: "{0}",
                summaryType: "custom",
              },
              {
                name: "horasPrevDia",
                column: "horasPrevDia",
                displayFormat: "{0}",
                summaryType: "custom",
              },
              {
                name: "diferencia",
                column: "diferencia",
                displayFormat: "{0}",
                summaryType: "custom",
              },
            ],
            calculateCustomSummary: function (options) {
              if (options.name !== "fecha") {
                var horas = 0;
                if (options.summaryProcess === "start") {
                  options.totalValue = 0;
                }
                if (options.summaryProcess === "calculate") {
                  if (options.value) {
                    var minutos = 0;
                    if (options.value instanceof Date) {
                      horas = options.value.getHours();
                      minutos = options.value.getMinutes();
                    } else {
                      horas = parseInt(options.value.split(":")[0]);
                      minutos = parseInt(options.value.split(":")[1]);
                    }

                    if (isNegative(horas)) minutos = -Math.abs(minutos);

                    options.totalValue =
                      minutos + horas * 60 + parseInt(options.totalValue);
                  }
                }
                if (options.summaryProcess === "finalize") {
                  horas = leadingZero(
                    Math.abs(Math.trunc(options.totalValue / 60)),
                  );

                  options.totalValue =
                    (isNegative(options.totalValue) ? "-" : "") +
                    horas +
                    ":" +
                    leadingZero(Math.abs(options.totalValue % 60)) +
                    " ";
                }
              }
            },
          },
          onContentReady: function (e) {
            e.element
              .find(".dx-datagrid-export-button")
              .dxButton("instance")
              .option("text", getTrad("exportar"));
          },
          onCellPrepared: function (e) {
            if (e.rowType === "header" && e.column.dataField === "diferencia") {
              let container = $("<div/>");
              container.addClass("d-flex");
              let icon = $("<div />").addClass("icon_Comment pointer pl-2");
              tooltipControl_creacion(
                icon,
                getTrad("horasTrabajadas") + " - " + getTrad("horasEfectivas"),
              );
              container.append(e.cellElement.find(".dx-datagrid-text-content"));
              container.append(icon);
              e.cellElement.append(container);
            }
            if (
              (e.rowType === "data" || e.rowType === "totalFooter") &&
              e.column.dataField === "diferencia"
            )
              e.cellElement.css({ "padding-right": 22 });
          },
          onToolbarPreparing: function (e) {
            e.toolbarOptions.items.unshift({
              location: "before",
              template: function () {
                return $("<div />")
                  .addClass("font-size")
                  .css("maxWidth", "400px")
                  .text(
                    $("#dxDataGrid_estadosCalendarioNPersona")
                      .dxDataGrid("instance")
                      .getSelectedRowsData()[0].nombre,
                  );
              },
            });
          },
          //Estilos
          showColumnLines: false,
          showRowLines: true,
          rowAlternationEnabled: true,
        }),
      );
    },
    onShowing: function (e) {
      $("#dxDataGrid_horasDiarias").dxDataGrid("instance").repaint();
    },
    onHiding: function (e) {
      var dxDataGrid_estadosCalendarioNPersona = $(
        "#dxDataGrid_estadosCalendarioNPersona",
      ).dxDataGrid("instance");
      dxDataGrid_estadosCalendarioNPersona.deselectAll();
      $("#dxDataGrid_horasDiarias")
        .dxDataGrid("instance")
        .getScrollable()
        .scrollTo(0);
    },
  });

  function set_dxSwitch_tipoGrid(value, dxDataGrid_estadosCalendarioNPersona) {
    dxDataGrid_estadosCalendarioNPersona.beginUpdate();
    dxDataGrid_estadosCalendarioNPersona.option("columnFixing.enabled", value);
    $.each(
      dxDataGrid_estadosCalendarioNPersona.option("columns"),
      function (index, item) {
        if (
          item.dataField === "nombre" ||
          item.dataField === "idCategoria" ||
          item.dataField === "diferencia"
        )
          dxDataGrid_estadosCalendarioNPersona.columnOption(
            item.dataField,
            "fixed",
            value,
          );

        if (
          item.dataField === "horasTrabajadas" ||
          item.dataField === "horasDesc" ||
          item.dataField === "horasEfectivas" ||
          item.dataField === "horasPrevDia"
        )
          dxDataGrid_estadosCalendarioNPersona.columnOption(
            item.dataField,
            "visible",
            value,
          );
      },
    );
    dxDataGrid_estadosCalendarioNPersona.endUpdate();
  }

  function isNegative(n) {
    //debido a que -0 y 0 son equivalentes.
    return ((n = +n) || 1 / n) < 0;
  }

  function filtrar_dxLookupCategorias() {
    var personasNEstado = datasource_estadosCalendarioNPersona.items();
    //Solo aparecen las categorías de las personas que hay en el grid.
    var categorias_itemsFiltradas = $.grep(
      datasource_categoria.items(),
      function (categoria) {
        var match = false;
        for (var i = 0; i < personasNEstado.length; i++) {
          if (personasNEstado[i].idCategoria === categoria.idCategoria)
            match = true;
        }
        return match;
      },
    );
    return categorias_itemsFiltradas;
  }

  return deferred.promise();
}
