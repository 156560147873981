import { connectionConstants } from "../constants";
import { authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const estructuraMenuService = {
  load,
};

function load() {
  var deferred = $.Deferred();

  $.when(dataSource_estructura.load(), dataSource_aplicaciones.load()).then(
    function (estruc, app) {
      localStorage.setItem("estructuraMenu", JSON.stringify(estruc[0]));
      localStorage.setItem("aplicaciones", JSON.stringify(app[0]));
      deferred.resolve();
    },
  );
  return deferred.promise();
}

const dataSource_aplicaciones = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.ODATA_URL + `tblAplicacion`,
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
  select: [
    "idAplicacion, denominacion, descripcion,idFormularioInicio, icon, tblTraduccion, color",
  ],
  sort: "orden",
  expand: ["tblTraduccion, tblFormularioInicio($expand=tblApartado)"],
  postProcess: function (data) {
    $.each(data, function (index, item) {
      let to = "/";
      if (item.tblFormularioInicio)
        to =
          "/" +
          (item.tblFormularioInicio.informe === true
            ? "Informes"
            : item.tblFormularioInicio.tblApartado.apartado) +
          "/" +
          item.tblFormularioInicio.formulario;
      item.to = to;
    });
    return data;
  },
});

const dataSource_estructura = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.ODATA_URL + `tblApartado`,
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
    version: 4,
  }),
  expand: [
    "tblTraduccion,tblFormulario($orderby = orden; $filter = tblFormularioNUsuario/any(f: f/idUsuario eq @@idUser) or idApartado eq 20; $expand = tblFormularioNUsuario($filter = idUsuario eq @@idUser),tblTraduccion)",
  ],
  sort: "idApartado",
  postProcess: function (data) {
    var estructura = $.map(data, function (apart, i) {
      return {
        idApartado: apart.idApartado,
        menuItemColor: apart.idApartado == 5 ? "categoriaPersonal" : null,
        label: apart.denominacion,
        apartado: apart.apartado,
        idApartadoPadre: apart.idApartadoPadre,
        icon: apart.icon,
        tblTraduccion: apart.tblTraduccion,
        content: $.map(apart.tblFormulario, function (frm, i) {
          return {
            idAplicacion: frm.idAplicacion,
            idFormulario: frm.idFormulario,
            label: frm.denominacion,
            icon: frm.icon,
            tblTraduccion: frm.tblTraduccion,
            to:
              "/" +
              (frm.informe === true ? "Informes" : apart.apartado) +
              "/" +
              frm.formulario,
          };
        }),
      };
    });
    var result = {};
    $.each(estructura, function (index, apartado) {
      apartado.content = apartado.content.concat(
        $.grep(estructura, function (apartadoHijo, index) {
          return apartadoHijo.idApartadoPadre === apartado.idApartado;
        }),
      );
    });

    $.each(estructura, function (index, apartado) {
      if (!apartado.idApartadoPadre) {
        result[apartado.apartado] = apartado;
      }
    });

    return result;
  },
});
