import { getArticulos, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearAlbaran = () => {
    return {
        idAdmAlbaranCompra: null,
        codigo: null,
        fechaCreacion: null,
        idAdmAlbaran_Estado: 1,
        idAdmProveedor: null,
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1,
        idTipoAlbaran: null,
        tasaCambio: 1,
        descuento: 0,
        idAdmTipoDescuento: 1,
        idAdmFormaPago: null,
        idIncoterm: null,
        numPedidoProveedor_id: null,
        numPedidoProveedor_codigo: null,
        observaciones: null,
        numAlbaranProveedor: null,
        idAdmCentroCoste: null,
        idAdmElementoPEP: null,
        articulosDraft: [],
        tblArticuloNAdmAlbaranCompra: [],
    };
};

export const payloadBuilder = (data, queryReady = true) => {
    if (queryReady) {
        let payload = {
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idTipoAlbaran: data.idTipoAlbaran,
            idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
            idAdmProveedor: data.idAdmProveedor,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idAdmFormaPago: data.idAdmFormaPago,
            idIncoterm: data.idIncoterm,
            idAdmPedidoProveedor: data.numPedidoProveedor_id,
            observaciones: data.observaciones,
            numAlbaranProveedor: data.numAlbaranProveedor,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
            tblArticuloNAdmAlbaranCompra: getArticulos(data.tblArticuloNAdmAlbaranCompra, queryReady),
        };
        if (data.idAdmAlbaranCompra != null) {
            payload.idAdmAlbaranCompra = data.idAdmAlbaranCompra;
        }
        return payload;
    } else {
        return {
            idAdmAlbaranCompra: data.idAdmAlbaranCompra,
            codigo: data.codigo,
            fechaCreacion: data.fechaCreacion,
            idTipoAlbaran: data.idTipoAlbaran,
            idAdmAlbaran_Estado: data.idAdmAlbaran_Estado,
            idAdmProveedor: data.idAdmProveedor,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            descuento: data.descuento,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            idAdmFormaPago: data.idAdmFormaPago,
            idIncoterm: data.idIncoterm,
            numPedidoProveedor_id: data.idAdmPedidoProveedor,
            numPedidoProveedor_codigo: data?.idAdmPedidoProveedorNavigation?.codigo,
            observaciones: data.observaciones,
            numAlbaranProveedor: data.numAlbaranProveedor,
            idAdmCentroCoste: data.idAdmCentroCoste,
            idAdmElementoPEP: data.idAdmElementoPEP,
            tblArticuloNAdmAlbaranCompra: getArticulos(data.tblArticuloNAdmAlbaranCompra),
        };
    }
};
