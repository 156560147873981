import { SelectBox } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/lookup";
import { datasource_tblEmpresasPolarier, setEmpresaPolarierSel } from "../model";
import { useEffect, useRef, useState } from "react";

const SelectorEmpresa = ({ value, onSelectionChanged }) => {
  const [empresas, setEmpresas] = useState(datasource_tblEmpresasPolarier.items());
  useEffect(() => {
    if (datasource_tblEmpresasPolarier.items().length === 0) {
      datasource_tblEmpresasPolarier.load().then((data) => {
        setEmpresaPolarierSel(data[0]);
        setEmpresas(data);
      });
    }
  }, []);

  return (
    <>
      {empresas.length > 0 && (
        <SelectBox
          dataSource={datasource_tblEmpresasPolarier}
          displayExpr="denominacion"
          valueExpr="idEmpresaPolarier"
          defaultValue={value.idEmpresaPolarier}
          value={value.idEmpresaPolarier}
          onSelectionChanged={onSelectionChanged}
          defaultOpened={empresas.length > 1}
          disabled={empresas.length <= 1}
        >
          <DropDownOptions
            showTitle={true}
            title={"Seleccione la empresa"}
            minWidth={300}
            shading={true}
            animation={{
              show: { type: "fade", duration: 400, from: 0, to: 1 },
              hide: { type: "fade", duration: 400, from: 1, to: 0 },
            }}
            position={{
              my: "center",
              at: "center",
              of: window,
            }}
          />
        </SelectBox>
      )}
    </>
  );
};

export default SelectorEmpresa;
