import React, { useState, useEffect } from "react";
import { FlyToInterpolator, StaticMap } from "react-map-gl";
import DeckGL from "@deck.gl/react";
import {
  AmbientLight,
  LightingEffect,
  _SunLight as SunLight,
} from "@deck.gl/core";
import { ScatterplotLayer } from "@deck.gl/layers";
import { TripsLayer } from "@deck.gl/geo-layers";
import { ScenegraphLayer } from "@deck.gl/mesh-layers";
import TripBuilder from "./trip-builder";
import { IconLayer, TextLayer } from "deck.gl";

import Box, { Item as ItemBox } from "devextreme-react/box";
import ReactSwitch from "react-switch";

import lavanderia from "../../assets/glbFiles/lavanderia.glb";
import entidad from "../../assets/glbFiles/entidad.glb";
import camion from "../../assets/glbFiles/camion.glb";
// import flag_green from '../../assets/glbFiles/flag_green.glb';
import flag_red from "../../assets/glbFiles/flag_red.glb";

const MAP_STYLE =
  "https://basemaps.cartocdn.com/gl/positron-gl-style/style.json";

const INITIAL_VIEW_STATE = {
  longitude: 2.89,
  latitude: 39.52,
  zoom: 9.8,
  pitch: 45,
  bearing: 0,
};

const ambientLight = new AmbientLight({
  color: [255, 255, 255],
  intensity: 1.0,
});

const models = {
  lavanderia: lavanderia,
  entidad: entidad,
  camion: camion,
  flag_green: process.env.PUBLIC_URL + "/flag_green.glb",
  flag_red: flag_red,
  entidad2d: process.env.PUBLIC_URL + "/entidad2d.svg",
};

const getTooltip = ({ object }) => {
  if (object && (object.denominacion || object.fecha)) {
    return (
      (object.denominacion ? object.denominacion + "" : "") +
      (object.conductor ? object.conductor + " - " : "") +
      (object.matricula ? object.matricula + " - " : "") +
      (object.index ? object.index + " - " : "") +
      (object.fecha ? object.fecha.toTimeString().split(" ")[0] : "") +
      (object.duracion ? " - Duración: " + object.duracion : "")
    );
  }
};

export default function MapDeckGL({
  date,
  rutas,
  lavanderias,
  entidades,
  vehiculos,
  flags,
  idParteTransporteSel = null,
  setIdParteTransporteSel,
}) {
  const [initialViewState, setInitialViewState] = useState(INITIAL_VIEW_STATE);
  const [zoom, setZoom] = useState(INITIAL_VIEW_STATE.zoom);

  const [showPaths, setShowPath] = useState(true);
  const [lightingEffect, setLightingEffect] = useState(null);
  const [trips, setTrips] = useState([]);
  const [tripSel, setTripSel] = useState([]);
  const [flagsSel_green, setFlagsSel_green] = useState([]);
  const [flagsSel_red, setFlagsSel_red] = useState([]);
  const [localizaciones, setLocalizaciones] = useState([]);

  const [truckFrames, setTruckFrames] = useState([]);
  const [truckSel, setTruckSel] = useState([]);

  //Options
  const [showAllTrips, setShowAllTrips] = useState(false);
  const [followCamera, setFollowCamera] = useState(false);
  const [show3d, setShow3d] = useState(true);

  useEffect(() => {
    const dirLight = new SunLight({
      timestamp: new Date(2023, 8, 8, 1, 0, 0),
      color: [255, 255, 255],
      intensity: 3.0,
    });

    setLightingEffect(new LightingEffect({ ambientLight, dirLight }));

    // setTruckFrames(trips.filter(trip => trip.keyframes.length > 0).map(trip => {
    //     trip.getFrame(timeStamp)
    // }))
  }, [date]);

  useEffect(() => {
    setTrips(
      rutas.map(
        (trip) =>
          new TripBuilder({
            waypoints: trip.localizaciones.map((l) => l.coordenadas),
            loop: false,
          }),
      ),
    );
  }, [rutas]);

  // useEffect(() => {
  //     setFollowCamera(true);
  // }, [idParteTransporteSel])

  useEffect(() => {
    setTripSel(
      rutas
        .filter((trip) => trip.idParteTransporte === idParteTransporteSel)
        .map(
          (trip) =>
            new TripBuilder({
              waypoints: trip.localizaciones.map((l) => l.coordenadas),
              loop: false,
            }),
        ),
    );

    setFlagsSel_green(
      flags.filter(
        (flag) =>
          flag.idParteTransporte === idParteTransporteSel &&
          flag.type == "salida",
      ),
    );
    setFlagsSel_red(
      flags.filter(
        (flag) =>
          flag.idParteTransporte === idParteTransporteSel &&
          flag.type == "llegada",
      ),
    );

    let localizaciones_ = rutas
      .filter((ruta) => ruta.idParteTransporte === idParteTransporteSel)
      .map((ruta) => ruta.localizaciones)[0];
    setLocalizaciones(localizaciones_);

    // rutas.filter(trip => trip.idParteTransporte === idParteTransporteSel)
    // .map

    if (followCamera && localizaciones_ && localizaciones_.length > 0) {
      let lastLoc = localizaciones_[localizaciones_.length - 1].coordenadas;
      setInitialViewState({
        longitude: lastLoc[0],
        latitude: lastLoc[1],
        zoom: 14,
        pitch: 40,
        bearing: 0,
        transitionDuration: 2000,
        transitionInterpolator: new FlyToInterpolator(),
      });
    }
  }, [idParteTransporteSel, rutas, flags, followCamera]);

  let factorZoom = 0.4 * (20 - zoom);
  return (
    <>
      <div
        className="container_spanCentrado"
        style={{
          zIndex: 1,
          position: "absolute",
          height: 50,
          top: 20,
          right: 20,
        }}
      >
        <div
          className="container_spanCentrado shadow"
          style={{
            backgroundColor: "rgba(245, 245, 245, 0.8)",
            padding: 10,
            borderRadius: 20,
            marginRight: 20,
          }}
        >
          <div style={{ paddingRight: 10 }}>
            <b>Seguimiento de la cámara</b>
          </div>
          <ReactSwitch
            checked={followCamera}
            onChange={(e) => {
              setFollowCamera(e);
            }}
            onColor="#EEC567"
            onHandleColor="#EDB637"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
          />
        </div>
        {/* <div className='container_spanCentrado shadow' style={{ backgroundColor: "rgba(245, 245, 245, 0.8)", padding: 10, borderRadius: 20, marginRight: 20 }}>
                <div style={{ paddingRight: 10 }}>
                    <b>Mostrar todas las rutas</b>
                </div>
                <ReactSwitch
                    checked={showAllTrips}
                    onChange={(e) => { setShowAllTrips(e) }}
                    onColor="#EEC567"
                    onHandleColor="#EDB637"
                    handleDiameter={30}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={48}
                    className="react-switch"
                />
            </div> */}
        <div
          className="container_spanCentrado shadow"
          style={{
            backgroundColor: "rgba(245, 245, 245, 0.8)",
            padding: 10,
            borderRadius: 20,
            marginRight: 20,
          }}
        >
          <div style={{ paddingRight: 10 }}>
            <b>Mostrar 3D</b>
          </div>
          <ReactSwitch
            checked={show3d}
            onChange={(e) => {
              setShow3d(e);
            }}
            onColor="#EEC567"
            onHandleColor="#EDB637"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
          />
        </div>
      </div>
      <Box direction="col" width="100%" height="100%">
        {/* <ItemBox baseSize={"auto"}>
            
        </ItemBox> */}
        <ItemBox ratio={1}>
          <div
            style={{
              position: "relative",
              height: "100%",
              overflow: "hidden",
              borderRadius: 10,
            }}
          >
            <DeckGL
              pickingRadius={5}
              initialViewState={initialViewState}
              //viewState={viewState}
              controller={true}
              effects={[lightingEffect]}
              getTooltip={getTooltip}
              onViewStateChange={({ viewState }) => {
                viewState.zoom != zoom && setZoom(viewState.zoom);
              }}
            >
              <StaticMap
                reuseMaps
                mapStyle={MAP_STYLE}
                preventStyleDiffing={true}
              />
              {/* {truckFrames.map((frame, i) =>
                        <ScenegraphLayer
                            id={'truck_' + i}
                            scenegraph={'camion.glb'}
                            sizeScale={150}
                            getPosition={d => d.point}
                            getTranslation={[0, 0, 1]}
                            getOrientation={d => [0, 130 - d.heading, 90]}
                            data={frame}
                            pickable={true}
                            _lighting='pbr'
                            onClick={() => {
                               
                            }}
                        />
                    )}
            */}
              <TripsLayer
                id="trip-lines"
                data={trips}
                getPath={(d) => d.keyframes.map((f) => f.point)}
                getColor={(d) => [0, 158, 205]}
                jointRounded={true}
                opacity={showAllTrips ? 1 : 0}
                getWidth={(d) => {
                  return 15 * factorZoom;
                }}
                rounded={true}
              />

              <TripsLayer
                id="tripSel-lines"
                data={tripSel}
                getPath={(d) => d.keyframes.map((f) => f.point)}
                getColor={[237, 182, 55]}
                jointRounded={true}
                opacity={showPaths ? 1 : 0}
                getWidth={(d) => {
                  return 10 * factorZoom;
                }}
                rounded={true}
              />

              <ScatterplotLayer
                id={"scatterplot-layer"}
                data={localizaciones}
                pickable={true}
                opacity={0.8}
                filled={true}
                radiusScale={1}
                radiusMinPixels={2}
                radiusMaxPixels={100}
                lineWidthMinPixels={1}
                getPosition={(d) => d.coordenadas}
                getFillColor={(d) =>
                  d.isOffline
                    ? [0, 0, 0]
                    : d.isParada
                      ? [55, 238, 55]
                      : [238, 55, 55]
                }
              />
              {show3d && (
                <ScenegraphLayer
                  id="lavanderia"
                  scenegraph={models.lavanderia}
                  getScale={(d) => {
                    let val = 35 * factorZoom;
                    return [val, val, val];
                  }}
                  getTranslation={(d) => {
                    let val = 80 * factorZoom;
                    return [0, 0, val];
                  }}
                  getPosition={(d) => d.coordenadas}
                  getOrientation={(d) => [0, 0, 90]}
                  data={lavanderias}
                  pickable={true}
                  _lighting="pbr"
                />
              )}
              {show3d && (
                <ScenegraphLayer
                  id="entidad"
                  scenegraph={models.entidad}
                  getPosition={(d) => d.coordenadas}
                  getScale={(d) => {
                    let val = 20 * factorZoom;
                    return [val, val, val];
                  }}
                  getTranslation={(d) => {
                    let val = 0 * factorZoom;
                    return [0, 0, val];
                  }}
                  getOrientation={(d) => [0, 0, 90]}
                  data={entidades}
                  pickable={true}
                  _lighting="pbr"
                />
              )}
              {show3d && (
                <ScenegraphLayer
                  id="vehiculos"
                  scenegraph={models.camion}
                  // sizeScale={25}
                  getPosition={(d) => d.coordenadas}
                  getScale={(d) => {
                    let val = 10 * factorZoom;
                    return [val, val, val];
                  }}
                  getTranslation={(d) => {
                    let val = -15 * factorZoom;
                    return [0, 0, val];
                  }}
                  getOrientation={(d) => [0, 20, 90]}
                  data={vehiculos}
                  pickable={true}
                  _lighting="pbr"
                />
              )}
              {/* {show3d &&
                            <ScenegraphLayer
                                id='flags_green'
                                scenegraph={models.flag_green}
                                // sizeScale={25}
                                getPosition={d => d.coordenadas}
                                getScale={d => { let val = 15 * factorZoom; return [val, val, val] }}
                                getTranslation={d => { let val = 5 * factorZoom; return [-val * 7, 20, val] }}
                                getOrientation={d => [0, 20, 90]}
                                data={flagsSel_green}
                                pickable={true}
                                _lighting='pbr'
                            />
                        } */}
              {show3d && (
                <ScenegraphLayer
                  id="flags_red"
                  scenegraph={models.flag_red}
                  // sizeScale={25}
                  getPosition={(d) => d.coordenadas}
                  getScale={(d) => {
                    let val = 15 * factorZoom;
                    return [val, val, val];
                  }}
                  getTranslation={(d) => {
                    let val = 5 * factorZoom;
                    return [-val * 7, 20, val];
                  }}
                  getOrientation={(d) => [0, 20, 90]}
                  data={flagsSel_red}
                  pickable={true}
                  _lighting="pbr"
                />
              )}
              {!show3d && (
                <IconLayer
                  id="entidad2d"
                  // scenegraph={models.entidad}
                  getIcon={(d) => ({
                    url: models.entidad2d,
                    width: 50,
                    height: 50,
                    anchorY: 50,
                  })}
                  getPosition={(d) => d.coordenadas}
                  // sizeScale={5}
                  getSize={(d) => 25 * factorZoom}
                  // getTranslation={d => { let val = 0 * factorZoom; return [0, 0, val] }}
                  // getOrientation={d => [0, 0, 90]}
                  data={entidades}
                  pickable={true}
                  getColor={(d) => [Math.sqrt(d.exits), 140, 0]}
                />
              )}
              <TextLayer
                id="text-layer"
                data={[...lavanderias, ...entidades]}
                pickable={true}
                getPosition={(d) => d.coordenadas}
                getText={(d) => d.denominacion.toString()}
                getColor={[255, 128, 0]}
                getPixelOffset={[0, -60]}
                getSize={12}
                getTextAnchor="middle"
                getAlignmentBaseline="center"
              />
              {/* <TextLayer
            id='text-layer'
            data={localizaciones}
            pickable={true}
            getPosition={d => d.coordenadas}
            getText={d => d.index.toString()}
            getPixelOffset={[5, 20]}
            background={true}
            backgroundPadding={[10, 5]}
            getSize={14}
            getTextAnchor='middle'
            getAlignmentBaseline='top'
        /> */}
            </DeckGL>
          </div>
        </ItemBox>
      </Box>
    </>
  );
}
