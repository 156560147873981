import React, { useEffect, useMemo, useState } from "react";

import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    Lookup,
    Pager,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import ODataContext from "devextreme/data/odata/context";

import { removeCellComponent } from "components/DataGrid/Cells";
import { getClearFactura } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    filterArticulo,
    onCellPrepared_Total,
    retrieveIvaValue,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblProveedor,
    datasource_tblViasPagoCobro,
    datasource_tblAdmFactura_Estado,
    datasource_tblAdmAlbaran_Estado,
    sharedEditorOptions,
    formats,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";

import { connectionConstants } from "constants";
import { authHeader, errorHandler, getTrad } from "helpers";
import notify from "devextreme/ui/notify";

const DataGridFacturasCompra = ({ datasource, setFacturaCompraSel, openPopup, idEmpresaPolarier, loadPanel_hide }) => {
    const [idAdmFacturaCompraInProgress, setIdAdmFacturaCompraInProgress] = useState(null);

    const [proveedores, setProveedores] = useState([]);
    const [centrosCoste, setCentroCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);

    useEffect(() => {
        Promise.all([
            datasource_tblProveedor.reload(),
            datasource_tblAdmCentroCoste.reload(),
            datasource_tblAdmElementoPEP.reload(),
        ]).then((data) => {
            setProveedores(datasource_tblProveedor.items());
            setCentroCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    const context_asientosFacturas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/Contabilidad/AsientosFacturas/",
        entities: {
            GenerarAsientosFacturaCompraSAP: {
                key: "idAdmFacturaCompra",
                keyType: "Int32",
            },
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => (request.headers = { ...authHeader() }),
    });

    const calculateCellValue = (e) => {
        let total = 0;

        if (e.tblAdmConceptoCompra.length > 0) {
            e.tblAdmConceptoCompra.forEach((articulo) => {
                const { cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 } = articulo;
                let iva = retrieveIvaValue(idIvaNPais, "compra");
                total += cantidad * precio * (1 + iva) * (1 - descuento);
            });
        }

        if (e.idAdmAlbaranCompra.length > 0) {
            e.idAdmAlbaranCompra.forEach((albaran) => {
                albaran.tblArticuloNAdmAlbaranCompra.forEach((articulo) => {
                    const { cantidad = 0, descuento = 0, precio = 0, idIvaNPais = 0 } = articulo;
                    let iva = retrieveIvaValue(idIvaNPais, "compra");
                    total += cantidad * precio * (1 + iva) * (1 - descuento);
                });
                const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = albaran;
                total = calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "compra");
            });
        }
        const { descuento = 0, idAdmTipoDescuento } = e;
        return { valor: calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "compra"), idMoneda: e.idMoneda };
    };

    const cellRender = (e) => {
        return calculateDisplayValue(e.value.valor, e.value.idMoneda);
    };

    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    widget: "dxButton",
                    options: {
                        icon: "plus",
                        text: getTrad("nuevaFacturaCompra"),
                        onClick: () => onNewFactura(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            datasource.reload();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewFactura = () => {
        const factura = getClearFactura();
        setFacturaCompraSel({ ...factura });
        openPopup();
    };

    const onRowPrepared_tblFacturas = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onCellPrepared_tblFacturas = useMemo(() => {
        return (e) => {
            if (
                e.column?.dataField === "isCerrado" &&
                e.rowType === "data" &&
                (idAdmFacturaCompraInProgress || e.data.isCerrado)
            ) {
                e.cellElement.css("cursor", "default");
            }
        };
    }, []);

    const generarAsientosFacturaCompraSAP = ({ isCerrado, idAdmFacturaCompra }) => {
        if (!idAdmFacturaCompraInProgress && !isCerrado) {
            setIdAdmFacturaCompraInProgress(idAdmFacturaCompra);
            context_asientosFacturas
                .invoke("GenerarAsientosFacturaCompraSAP", { idAdmFacturaCompra }, "POST")
                .done(() => {
                    datasource.reload().done(() => {
                        setIdAdmFacturaCompraInProgress(null);
                        notify({
                            message: getTrad("asientosGeneradosCorrectamente"),
                            type: "success",
                            displayTime: 1500,
                            closeOnClick: true,
                        });
                    });
                })
                .catch(() => {
                    setIdAdmFacturaCompraInProgress(null);
                    notify({
                        message: getTrad("errorGenerarAsientos"),
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });
                });
        }
    };

    const onRowClick_tblFacturas = (e) => {
        if (e.data) {
            setFacturaCompraSel({ ...e.data });
            openPopup();
        }
    };

    const cellRender_isCerrado = (e) => {
        return e.data.isCerrado ? (
            <i className={`icon_lockOutline container_spanCentrado font-size-lg`} />
        ) : (
            <i
                className={`dx-icon dx-icon-export ${
                    idAdmFacturaCompraInProgress === e.data.idAdmFacturaCompra ? "primary" : ""
                } container_spanCentrado font-size-lg`}
            />
        );
    };

    return (
        <DataGrid
            dataSource={datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblFacturas}
            onCellPrepared={onCellPrepared_Total}
            onRowClick={onRowClick_tblFacturas}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fecha"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"left"}
                minWidth={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFactura_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmFactura_Estado, "", "factura")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmFactura_Estado.store()}
                    valueExpr="idAdmFactura_Estado"
                    displayExpr="denominacion"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                />
            </Column>
            <Column
                dataField={"idAdmProveedor"}
                caption={getTrad("proveedor")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <Lookup dataSource={proveedores} valueExpr="idAdmProveedor" displayExpr="nombreFiscal" />
            </Column>
            <Column dataField={"idMoneda"} caption={getTrad("moneda")} visible={false} allowReordering allowResizing>
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPedidoCliente"}
                caption={getTrad("numPedidoCliente")}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numFacturaProveedor"}
                caption={getTrad("numFacturaProveedor")}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPresupuesto"}
                caption={getTrad("numPresupuesto")}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                visible={false}
                format={formats.percent}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                visible={true}
                width={100}
                calculateCellValue={calculateCellValue}
                cellRender={cellRender}
            />
            <Column
                dataField={"isCerrado"}
                caption={" "}
                width={40}
                alignment={"center"}
                allowReordering={false}
                allowResizing={false}
                allowFiltering={false}
                cellRender={cellRender_isCerrado}
            />
            <Column
                dataField={"idAdmAlbaranCompra"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            <Column
                dataField={"tblAdmConceptoCompra"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            {/* <Column
                caption={" "}
                width={30}
                alignment={"center"}
                cssClass={"p-0"}
                cellComponent={removeCellComponent}
            /> */}

            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

export default DataGridFacturasCompra;
