import { Switch, Route, Redirect } from "react-router-dom";
import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PrivateRoute from "../../components/PrivateRoute";
import {
  publicRoutes,
  privateRoutes,
  getUrlFormularioInicio,
} from "../../helpers";

import NotFound from "components/NotFound";
import AppMaster from "../../layout/AppMaster";

class AppMain extends Component {
  render() {
    let { lavanderia, aplicacionActiva, user } = this.props;
    let redireccion = getUrlFormularioInicio(
      lavanderia,
      aplicacionActiva,
      user ? user.tblFormularioInicio : null,
    );
    return (
      <Fragment>
        <Switch>
          {/* NO AUTH PAGES */}
          {publicRoutes.map((route, i) => (
            <Route {...route} />
          ))}

          {/* AUTH PAGES */}
          {privateRoutes.map((route, i) => (
            <PrivateRoute {...route} />
          ))}

          <Route
            exact
            path="/"
            render={(props) => (
              <Redirect to={redireccion ? redireccion : "/Informes"} />
            )}
          />

          {/* NOT FOUND */}
          <AppMaster>
            <Route path="*" component={NotFound} />
          </AppMaster>
        </Switch>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  aplicacionActiva: state.Global.aplicacionActiva,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(AppMain);
