import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  startOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  filtroTiempo_Resize,
  formatNumber,
} from "../../../../helpers";
import { svg_print } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import config from "devextreme/core/config";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var opciones = {
      fechaDesde: new Date(),
      fechaHasta: new Date(),
    },
    idsPedidosHuespedSel = [];

  //#region DATASOURCES
  var datasource_rangeSelector_fechasPedidos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRepartosValet",
      key: "idRepartoValet",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA?.idLavanderia ?? null;
      },
      version: 4,
    }),
    select: ["fecha"],
    sort: "fecha asc",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
      };
    },
  });

  var datasource_tblPedidosHuesped = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infPedidosHuesped_spSelectPedidosHuesped",
      key: "idRepartoValet",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA?.idLavanderia ?? null;
        request.params.fechaIni = formatDate_parameter(opciones.fechaDesde);
        request.params.fechaFin = formatDate_parameter(opciones.fechaHasta);
      },
      version: 4,
    }),
  });

  var datasource_tblPrendaNPedidoHuesped = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infPedidosHuesped_spSelectPrendaHuespedNRepartoValet",
      key: ["idPrenda", "idTipoLavado"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDataGrid_pedidosHuesped = $(
            "#dxDataGrid_pedidosHuesped",
          ).dxDataGrid("instance"),
          selectedRowKeys = dxDataGrid_pedidosHuesped.option("selectedRowKeys"),
          filterIds = [];

        $.merge(filterIds, selectedRowKeys);

        if (filterIds.length === 0) {
          filterIds.push(-1);
        }

        request.params.idsRepartoValet = filterIds.join("|");
      },
      version: 4,
    }),
  });

  var datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA?.idLavanderia ?? null;
      },
      onLoaded: function (data) {
        if (LAVANDERIA == null) {
          var moneda = data[0]?.tblMoneda.codigo;
          config({ defaultCurrency: moneda ? moneda : "EUR" });
        }
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion"],
    expand: ["tblMoneda($select=codigo)"],
  });

  var enum_tipoLavadoHuesped = [
    {
      id: 1,
      denominacion: "LAVADO EJECUTIVO",
    },
    {
      id: 2,
      denominacion: "PLANCHADO",
    },
    {
      id: 3,
      denominacion: "LAVADO / PLANCHADO",
    },
    {
      id: 4,
      denominacion: "TINTORERÍA / SECO",
    },
    {
      id: 5,
      denominacion: "LAVADO / DOBLADO",
    },
    {
      id: 6,
      denominacion: "LAVADO / VAPORIZADO",
    },
    {
      id: 7,
      denominacion: "LAVADO / SECADO / PLANCHADO",
    },
  ];

  var enum_tipoLavadoHuespedPedido = [
    {
      id: 1,
      denominacion: "Lavado normal",
    },
    {
      id: 2,
      denominacion: "Lavado express",
    },
  ];

  var enum_tipoRepartoValet = [
    {
      id: 1,
      denominacion: "Huésped",
    },
    {
      id: 2,
      denominacion: "Extra",
    },
    {
      id: 3,
      denominacion: "Ejecutivo",
    },
  ];

  var datasource_tipoPrenda = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoPrendaHuesped",
      key: "idTipoServicio",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA?.idLavanderia ?? null;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idTipoPrendaHuesped", "denominacion"],
  });
  //#endregion

  $.when(datasource_entidades.load(), datasource_tipoPrenda.load()).done(
    function () {
      $.when(datasource_rangeSelector_fechasPedidos.load())
        .done(function (fechas) {
          //Impresión
          COMPONENT.setReport({
            denominacion: "infPedidosHuesped",
            parameterEvent: CustomizeParameterEditors,
          });

          if (fechas.length > 0) {
            var ultimaFecha = new Date(
              datasource_rangeSelector_fechasPedidos.items()[
                datasource_rangeSelector_fechasPedidos.items().length - 1
              ].arg,
            );
            opciones.fechaDesde = startOfMonth(ultimaFecha);
            opciones.fechaHasta = new Date(ultimaFecha);

            $("#InfPedidosHuesped #dxContainer").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              height: "100%",
              items: [
                {
                  // GRIDS
                  ratio: 1,
                  template: function (e, index, item) {
                    item.append(
                      $("<div />").dxBox({
                        direction: "row",
                        align: "space-around",
                        crossAlign: "stretch",
                        height: "100%",
                        items: [
                          {
                            // GRID PEDIDOS
                            ratio: 5,
                            template: function (e, index, item) {
                              item.css("padding-right", 30);
                              item.append(
                                $("<div />").dxBox({
                                  direction: "col",
                                  align: "space-around",
                                  crossAlign: "stretch",
                                  height: "100%",
                                  items: [
                                    {
                                      //GRID
                                      ratio: 1,
                                      template: function (e, index, item) {
                                        item.append(
                                          $(
                                            "<div id='dxDataGrid_pedidosHuesped' />",
                                          ).dxDataGrid({
                                            dataSource:
                                              datasource_tblPedidosHuesped,
                                            //Propiedades
                                            remoteOperations: false,
                                            columnsAutoWidth: true,
                                            height: "100%",
                                            filterRow: {
                                              visible: true,
                                              applyFilter: "auto",
                                            },
                                            headerFilter: {
                                              visible: true,
                                            },
                                            selection: {
                                              mode: "multiple",
                                              showCheckBoxesMode: "always",
                                            },
                                            paging: {
                                              pageSize: 20,
                                            },
                                            pager: {
                                              showPageSizeSelector: true,
                                              allowedPageSizes: [20, 50, 100],
                                              showInfo: true,
                                              visible: true,
                                            },
                                            hoverStateEnabled: true,
                                            columns: [
                                              {
                                                dataField: "fecha",
                                                caption: getTrad("fecha"),
                                                width: 100,
                                                alignment: "center",
                                                dataType: "date",
                                                format: "dd/MM/yyyy",
                                                sortOrder: "desc",
                                                allowHeaderFiltering: false,
                                              },
                                              {
                                                dataField: "idEntidad",
                                                caption: getTrad("entidad"),
                                                lookup: {
                                                  dataSource:
                                                    datasource_entidades.items(),
                                                  valueExpr: "idEntidad",
                                                  displayExpr: "denominacion",
                                                },
                                                allowHeaderFiltering: false,
                                              },
                                              {
                                                dataField: "denominacion",
                                                caption: getTrad("habitacion"),
                                                allowHeaderFiltering: false,
                                                width: 150,
                                              },
                                              {
                                                dataField: "tipoLavado",
                                                caption: getTrad("tipoLavado"),
                                                lookup: {
                                                  dataSource:
                                                    enum_tipoLavadoHuespedPedido,
                                                  valueExpr: "id",
                                                  displayExpr: "denominacion",
                                                },
                                                width: 130,
                                                allowHeaderFiltering: false,
                                              },
                                              {
                                                dataField: "idTipoRepartoValet",
                                                caption: getTrad("tipoReparto"),
                                                lookup: {
                                                  dataSource:
                                                    enum_tipoRepartoValet,
                                                  valueExpr: "id",
                                                  displayExpr: "denominacion",
                                                },
                                                width: 130,
                                                allowHeaderFiltering: false,
                                              },
                                              {
                                                dataField: "coste",
                                                caption: getTrad("coste"),
                                                alignment: "right",
                                                dataType: "number",
                                                allowFiltering: false,
                                                width: 120,
                                                format: {
                                                  style: "currency",
                                                  maximumFractionDigits: 2,
                                                  currency:
                                                    config().defaultCurrency,
                                                },
                                              },
                                              {
                                                dataField: "costeSinDescuento",
                                                visible: false,
                                                allowSearch: false,
                                              },
                                              {
                                                dataField: "descuento",
                                                visible: false,
                                                allowSearch: false,
                                              },
                                            ],
                                            summary: {
                                              totalItems: [
                                                {
                                                  column: "fecha",
                                                  displayFormat:
                                                    getTrad(
                                                      "total",
                                                    ).toUpperCase() + ":",
                                                },
                                                {
                                                  column: "idEntidad",
                                                  summaryType: "count",
                                                  displayFormat:
                                                    "{0} " + getTrad("pedidos"),
                                                },
                                                {
                                                  column: "peso",
                                                  summaryType: "sum",
                                                  displayFormat: "{0} Kg.",
                                                  valueFormat: {
                                                    style: "decimal",
                                                    maximumFractionDigits: 2,
                                                  },
                                                },
                                                {
                                                  column: "coste",
                                                  summaryType: "sum",
                                                  displayFormat: "{0}",
                                                  valueFormat: {
                                                    style: "currency",
                                                    maximumFractionDigits: 2,
                                                    currency:
                                                      config().defaultCurrency,
                                                  },
                                                },
                                              ],
                                            },
                                            //Eventos
                                            onContentReady: function (e) {
                                              //onFilterChanged
                                              var filter_old =
                                                e.component.option(
                                                  "filter_old",
                                                );
                                              var filter_new =
                                                e.component.getCombinedFilter();

                                              if (
                                                JSON.stringify(filter_old) !==
                                                JSON.stringify(filter_new)
                                              ) {
                                                e.component.option(
                                                  "filter_old",
                                                  filter_new,
                                                );
                                                e.component.option(
                                                  "selectedRowKeys",
                                                  [],
                                                );
                                              }
                                            },
                                            onEditorPreparing: function (e) {
                                              if (e.parentType === "filterRow")
                                                e.editorOptions.showClearButton = true;
                                            },
                                            onSelectionChanged: function (e) {
                                              var itemsSel =
                                                e.component.getSelectedRowsData();

                                              idsPedidosHuespedSel = $.map(
                                                itemsSel,
                                                function (item, i) {
                                                  return item.idRepartoValet;
                                                },
                                              );
                                              datasource_tblPrendaNPedidoHuesped.reload();
                                            },
                                            onRowClick: function (e) {
                                              if (!e.isSelected)
                                                e.component.selectRows(
                                                  e.key,
                                                  true,
                                                );
                                              else
                                                e.component.deselectRows(e.key);
                                            },
                                            onRowPrepared: function (info) {
                                              if (info.rowType === "data") {
                                                info.rowElement.css(
                                                  "cursor",
                                                  "pointer",
                                                );
                                              }
                                            },
                                            //Estilos
                                            showColumnLines: false,
                                            showRowLines: true,
                                            rowAlternationEnabled: true,
                                          }),
                                        );
                                      },
                                    },
                                  ],
                                }),
                              );
                            },
                          },
                          {
                            //GRID PRENDAS
                            ratio: 4,
                            template: function (e, index, item) {
                              item.append(
                                $("<div />").dxBox({
                                  direction: "col",
                                  align: "space-around",
                                  crossAlign: "stretch",
                                  height: "100%",
                                  items: [
                                    {
                                      // GRID
                                      ratio: 1,
                                      template: function (e, index, item) {
                                        item.append(
                                          $(
                                            "<div id='dxDataGrid_prendasHuesped' />",
                                          ).dxDataGrid({
                                            //Datos
                                            dataSource:
                                              datasource_tblPrendaNPedidoHuesped,
                                            //Propiedades
                                            columnsAutoWidth: true,
                                            height: "100%",
                                            filterRow: {
                                              visible: true,
                                              applyFilter: "auto",
                                            },
                                            headerFilter: {
                                              visible: true,
                                            },
                                            paging: {
                                              pageSize: 50,
                                            },
                                            pager: {
                                              showPageSizeSelector: true,
                                              allowedPageSizes: [20, 50, 100],
                                              showInfo: true,
                                              visible: true,
                                            },
                                            loadPanel: {
                                              enabled: false,
                                            },
                                            columns: [
                                              {
                                                dataField: "denominacion",
                                                sortOrder: "asc",
                                                caption:
                                                  getTrad("denominacion"),
                                                allowHeaderFiltering: false,
                                              },
                                              {
                                                dataField: "tipoLavado",
                                                caption: getTrad("tipoLavado"),
                                                allowHeaderFiltering: false,
                                                width: 170,
                                                lookup: {
                                                  dataSource:
                                                    enum_tipoLavadoHuesped,
                                                  valueExpr: "id",
                                                  displayExpr: "denominacion",
                                                },
                                              },
                                              {
                                                dataField: "cantidad",
                                                allowFiltering: false,
                                                caption: getTrad("cantidad"),
                                                alignment: "center",
                                                width: 100,
                                              },
                                              {
                                                dataField: "precioUnidad",
                                                allowFiltering: false,
                                                caption:
                                                  getTrad("precioUnidad"),
                                                alignment: "center",
                                                dataType: "number",
                                                format: {
                                                  style: "currency",
                                                  maximumFractionDigits: 2,
                                                  currency:
                                                    config().defaultCurrency,
                                                },
                                                width: 100,
                                              },
                                              {
                                                dataField: "coste",
                                                caption: getTrad("coste"),
                                                alignment: "center",
                                                dataType: "number",
                                                allowFiltering: false,
                                                width: 125,
                                                format: {
                                                  style: "currency",
                                                  maximumFractionDigits: 2,
                                                  currency:
                                                    config().defaultCurrency,
                                                },
                                              },
                                            ],
                                            summary: {
                                              totalItems: [
                                                {
                                                  column:
                                                    "tblPrendaHuesped.denominacion",
                                                  displayFormat:
                                                    getTrad(
                                                      "total",
                                                    ).toUpperCase() + ":",
                                                },
                                                {
                                                  column: "precioUnidad",
                                                  cssClass:
                                                    "font-weight-normal",
                                                  customizeText: function (
                                                    data,
                                                  ) {
                                                    if (
                                                      $(
                                                        "#dxDataGrid_pedidosHuesped",
                                                      )
                                                        .dxDataGrid("instance")
                                                        .getSelectedRowsData() ==
                                                      0
                                                    )
                                                      return "";
                                                    let selectedRepartos = $(
                                                      "#dxDataGrid_pedidosHuesped",
                                                    )
                                                      .dxDataGrid("instance")
                                                      .getSelectedRowsData();

                                                    let descuentoFinal = 0;
                                                    $.each(
                                                      selectedRepartos,
                                                      function (index, item) {
                                                        descuentoFinal +=
                                                          item.costeSinDescuento *
                                                          item.descuento;
                                                      },
                                                    );

                                                    if (
                                                      descuentoFinal &&
                                                      descuentoFinal > 0
                                                    )
                                                      return "PRECIO";
                                                    else return "";
                                                  },
                                                },
                                                {
                                                  column: "precioUnidad",
                                                  cssClass:
                                                    "font-weight-normal",
                                                  customizeText: function (
                                                    data,
                                                  ) {
                                                    if (
                                                      $(
                                                        "#dxDataGrid_pedidosHuesped",
                                                      )
                                                        .dxDataGrid("instance")
                                                        .getSelectedRowsData() ==
                                                      0
                                                    )
                                                      return "";
                                                    let selectedRepartos = $(
                                                      "#dxDataGrid_pedidosHuesped",
                                                    )
                                                      .dxDataGrid("instance")
                                                      .getSelectedRowsData();

                                                    let descuentoFinal = 0;
                                                    $.each(
                                                      selectedRepartos,
                                                      function (index, item) {
                                                        descuentoFinal +=
                                                          item.costeSinDescuento *
                                                          item.descuento;
                                                      },
                                                    );

                                                    if (
                                                      descuentoFinal &&
                                                      descuentoFinal > 0
                                                    )
                                                      return "DTO.";
                                                    else return "";
                                                  },
                                                },
                                                {
                                                  column: "precioUnidad",
                                                  displayFormat:
                                                    getTrad(
                                                      "total",
                                                    ).toUpperCase(),
                                                },
                                                {
                                                  column: "coste",
                                                  summaryType: "sum",
                                                  displayFormat: "{0}",
                                                  valueFormat: {
                                                    style: "currency",
                                                    maximumFractionDigits: 2,
                                                    currency:
                                                      config().defaultCurrency,
                                                  },
                                                },
                                                // {
                                                //     column: "coste",
                                                //     name: "coste",
                                                //     summaryType: "sum",
                                                //     displayFormat: "{0}",
                                                //     valueFormat: { style: 'currency', maximumFractionDigits: 2, currency: config().defaultCurrency },
                                                //     customizeText: function (data) {
                                                //         if ($("#dxDataGrid_pedidosHuesped").dxDataGrid("instance").getSelectedRowsData() == 0) return "";
                                                //         let selectedRepartos = $("#dxDataGrid_pedidosHuesped").dxDataGrid("instance").getSelectedRowsData();

                                                //         let descuentoFinal = 0;
                                                //         $.each(selectedRepartos, function (index, item) {
                                                //             descuentoFinal += item.costeSinDescuento * item.descuento;
                                                //         });

                                                //         if (descuentoFinal == 0) return "";

                                                //         var moneda = LAVANDERIA?.moneda ?? datasource_entidades.items()[0].moneda
                                                //         return "- " + formatNumber(descuentoFinal, 2, "currency", moneda);
                                                //     }
                                                // },
                                                // {
                                                //     column: "coste",
                                                //     name: "coste",
                                                //     summaryType: "sum",
                                                //     displayFormat: "{0}",
                                                //     valueFormat: { style: 'currency', maximumFractionDigits: 2, currency: config().defaultCurrency },
                                                //     customizeText: function (data) {
                                                //         if ($("#dxDataGrid_pedidosHuesped").dxDataGrid("instance").getSelectedRowsData() == 0) return "";
                                                //         let selectedRepartos = $("#dxDataGrid_pedidosHuesped").dxDataGrid("instance").getSelectedRowsData();

                                                //         let descuentoFinal = 0;
                                                //         let costeFinal = 0;
                                                //         $.each(selectedRepartos, function (index, item) {
                                                //             costeFinal += item.coste;
                                                //             descuentoFinal += item.costeSinDescuento * item.descuento;
                                                //         });

                                                //         if (descuentoFinal == 0) return "";

                                                //         var moneda = LAVANDERIA?.moneda ?? datasource_entidades.items()[0].moneda;
                                                //         return formatNumber(costeFinal, 2, "currency", moneda);
                                                //     }
                                                // }
                                              ],
                                            },
                                            //Eventos
                                            onToolbarPreparing: function (e) {
                                              e.toolbarOptions.items.unshift({
                                                location: "before",
                                                template: function () {
                                                  return $(
                                                    "<div class ='font-size' style='margin-top : 10px;'>",
                                                  ).text(
                                                    getTrad("prendasPedido"),
                                                  );
                                                },
                                              });
                                            },
                                            onEditorPreparing: function (e) {
                                              if (e.parentType === "filterRow")
                                                e.editorOptions.showClearButton = true;
                                            },
                                            //Estilos
                                            showColumnLines: false,
                                            showRowLines: true,
                                            rowAlternationEnabled: true,
                                          }),
                                        );
                                      },
                                    },
                                  ],
                                }),
                              );
                            },
                          },
                        ],
                      }),
                    );
                  },
                },
                {
                  // FILTRO TIEMPO
                  baseSize: 130,
                  template: function (itemData, itemIndex, itemElement) {
                    itemElement.append(
                      create_rangeSelector(
                        datasource_rangeSelector_fechasPedidos,
                        false,
                        false,
                        function (e) {
                          opciones.fechaDesde = e.component.option("value")[0];
                          opciones.fechaHasta =
                            e.component.option("valueHasta");

                          var dxDataGrid_pedidosHuesped = $(
                            "#dxDataGrid_pedidosHuesped",
                          ).dxDataGrid("instance");
                          dxDataGrid_pedidosHuesped.deselectAll();
                          dxDataGrid_pedidosHuesped.refresh(true);
                        },
                      ),
                    );
                  },
                },
              ],
            });

            var print = svg_print
              .clone()
              .css("height", "18px")
              .css("width", "18px");

            var serializer_print = new XMLSerializer();
            var imgHtml_print = serializer_print.serializeToString(print[0]);

            $("#dxButton_print").dxButton({
              width: "100%",
              icon: imgHtml_print,
              text: getTrad("imprimir"),
              visible: LAVANDERIA != null,
              onClick: function () {
                var params = {
                  idLavanderia: LAVANDERIA.idLavanderia,
                  idsPedidosHuesped: idsPedidosHuespedSel
                    .toString()
                    .replace(/,/g, "|"),
                };
                COMPONENT.setParams(params);
              },
            });
          } else {
            dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
              [
                getTrad("aceptar"),
                function (e) {
                  history.push("/");
                },
                "danger",
                "btnErrorSinDatos",
              ],
            ]);
          }
        })
        .always(function () {
          setTimeout(() => {
            filtroTiempo_Resize();
          }, 50);
          deferred.resolve();
        });
    },
  );

  //IMPRESIÓN
  function CustomizeParameterEditors(report, s, e) {
    if (
      e.parameter.name === "idLavanderia" ||
      e.parameter.name === "idsPedidosHuesped"
    ) {
      //INVISIBLES
      e.info.editor = $.extend({}, e.info.editor);
      e.info.visible = false;
    }
  }

  return deferred.promise();
}
