import React, { useEffect, useMemo, useRef } from "react";

import DxPopup from "devextreme-react/popup";
import Form, { ButtonItem, GroupItem, Item } from "devextreme-react/form";
import ScrollView from "devextreme-react/scroll-view";

import { getTrad } from "helpers";
import ImagenEditor from "./ImagenEditor";

const lookupOptions = {
    showCancelButton: false,
    showClearButton: true,
    dropDownOptions: {
        closeOnOutsideClick: true,
        showTitle: false,
        height: 400,
        width: 400,
    },
};

const Popup = ({
    visible,
    formData,
    dataSources,
    isSaving,
    setIsSaving,
    setIdMonedaFilter,
    onSave,
    onHiding,
    empresa,
}) => {
    const form = useRef(null);

    useEffect(() => {
        setIsSaving(false);
    }, [formData]);

    const getText = (clave) => ({ text: getTrad(clave) });

    const onSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
        setIsSaving(true);
    };

    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const editorOptions = useMemo(() => {
        return {
            readOnly: {
                readOnly: true,
            },
            idPais: {
                dataSource: dataSources.tblPais,
                valueExpr: "idPais",
                displayExpr: "denominacion",
                readOnly: true,
                ...lookupOptions,
            },
            idAdmCentroCoste: {
                dataSource: dataSources.tblAdmCentroCoste,
                valueExpr: "idAdmCentroCoste",
                displayExpr: "denominacion",
                onValueChanged: () => {
                    form.current.instance.option("formData").idAdmElementoPEP = null;
                    form.current.instance.repaint();
                },
                ...lookupOptions,
            },
            idAdmElementoPEP: {
                dataSource: dataSources.tblAdmElementoPEP,
                valueExpr: "idAdmElementoPEP",
                displayExpr: "denominacion",
                onValueChanged: () => {
                    form.current.instance.option("formData").idAdmCentroCoste = null;
                    form.current.instance.repaint();
                },
                ...lookupOptions,
            },
            idAdmFormaCobro: {
                dataSource: dataSources.tblAdmFormaPago,
                valueExpr: "idAdmFormaPago",
                displayExpr: "denominacion",
                ...lookupOptions,
            },
            idAdmCondicionPago: {
                dataSource: dataSources.tblAdmCondicionPago,
                valueExpr: "idAdmCondicionPago",
                displayExpr: "denominacion",
                ...lookupOptions,
            },
            diaPago: {
                showClearButton: true,
                showSpinButtons: true,
                min: 1,
                max: 31,
                format: {
                    type: "fixedPoint",
                    precision: 0,
                },
            },
            idMoneda: {
                dataSource: dataSources.tblMoneda,
                valueExpr: "idMoneda",
                displayExpr: "codigo",
                onValueChanged: (e) => setIdMonedaFilter(e.value),
                ...lookupOptions,
            },
            idCuentaBancaria: {
                dataSource: dataSources.tblAdmCuentaBancaria,
                valueExpr: "idCuentaBancaria",
                displayExpr: "denominacion",
                ...lookupOptions,
            },
            idIvaNPais: {
                dataSource: dataSources.tblIvaNPais,
                valueExpr: "idIvaNPais",
                displayExpr: "denominacion",
                ...lookupOptions,
            },
            isModificableNFactura: {
                width: 200,
                switchedOffText: getTrad("noModificable"),
                switchedOnText: getTrad("modificable"),
            },
            buttonOptions: {
                text: getTrad("guardar"),
                type: "success",
                useSubmitBehavior: true,
                disabled: isSaving,
            },
        };
    }, [dataSources, isSaving]);

    return (
        <DxPopup
            visible={visible}
            title={`${formData?.codigo} | ${formData?.CIF} | ${formData?.nombreFiscal}`}
            onHiding={onHiding}
        >
            <ScrollView height={"100%"} width={"100%"}>
                <form onSubmit={onSubmit} onKeyDown={onKeyDown}>
                    <Form ref={form} formData={formData} showColonAfterLabel={false} labelLocation="top">
                        <GroupItem caption={getTrad("general")} colCount={4}>
                            <GroupItem colSpan={3} colCount={3}>
                                <Item
                                    dataField={"codigo"}
                                    label={getText("codigo")}
                                    editorOptions={editorOptions.readOnly}
                                />
                                <Item dataField={"CIF"} label={getText("cif")} editorOptions={editorOptions.readOnly} />
                                <Item
                                    dataField={"nombreFiscal"}
                                    label={getText("nombreFiscal")}
                                    editorOptions={editorOptions.readOnly}
                                />
                                <Item
                                    dataField={"idPais"}
                                    label={getText("pais")}
                                    editorType={"dxLookup"}
                                    editorOptions={editorOptions.idPais}
                                />
                                <Item
                                    dataField={"provincia"}
                                    label={getText("provincia")}
                                    editorOptions={editorOptions.readOnly}
                                />
                                <Item
                                    dataField={"poblacion"}
                                    label={getText("poblacion")}
                                    editorOptions={editorOptions.readOnly}
                                />
                                <Item
                                    dataField={"codigoPostal"}
                                    label={getText("codigoPostal")}
                                    editorOptions={editorOptions.readOnly}
                                />
                                <Item
                                    dataField={"direccion"}
                                    label={getText("direccion")}
                                    editorOptions={editorOptions.readOnly}
                                />
                            </GroupItem>
                            <GroupItem colSpan={1} colCount={1} cssClass={"he-100"}>
                                <Item
                                    dataField={"imagen"}
                                    label={getText("imagen")}
                                    editorOptions={editorOptions.readOnly}
                                    component={ImagenEditor}
                                />
                            </GroupItem>
                        </GroupItem>
                        <GroupItem caption={getTrad("facturacion")} colCount={4}>
                            <Item
                                dataField={"tipoRetencion"}
                                label={getText("tipoRetencion")}
                                editorOptions={editorOptions.readOnly}
                            />
                            <Item
                                dataField={"codigoRetencion"}
                                label={getText("indicadorRetencion")}
                                editorType={"dxNumberBox"}
                                editorOptions={editorOptions.readOnly}
                            />
                            <Item
                                dataField={"idAdmCentroCoste"}
                                label={getText("centroCoste")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idAdmCentroCoste}
                                colSpan={2}
                            />
                            <Item
                                dataField={"idAdmElementoPEP"}
                                label={getText("elementoPep")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idAdmElementoPEP}
                                colSpan={2}
                            />
                            <Item
                                dataField={"idAdmFormaCobro"}
                                label={getText("formaPago")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idAdmFormaCobro}
                                colSpan={2}
                            />
                            <Item
                                dataField={"idAdmCondicionPago"}
                                label={getText("condicionPago")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idAdmCondicionPago}
                                colSpan={2}
                            />
                            <Item
                                dataField={"diaPago"}
                                label={getText("diaPago")}
                                editorType={"dxNumberBox"}
                                editorOptions={editorOptions.diaPago}
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={getText("moneda")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idMoneda}
                            />
                            <Item
                                dataField={"idCuentaBancaria"}
                                label={getText("cuentaBancaria")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idCuentaBancaria}
                                colSpan={2}
                            />
                            <Item
                                dataField={"idIvaNPais"}
                                label={getText(empresa.idEmpresaPolarier == 4 ? "itbis" : "iva")}
                                editorType={"dxLookup"}
                                editorOptions={editorOptions.idIvaNPais}
                            />
                            <Item
                                dataField={"isModificableNFactura"}
                                label={getText("isModificableNFactura")}
                                editorType={"dxSwitch"}
                                editorOptions={editorOptions.isModificableNFactura}
                                visible={false}
                            />
                            <ButtonItem
                                buttonOptions={editorOptions.buttonOptions}
                                horizontalAlignment={"right"}
                                verticalAlignment={"bottom"}
                            />
                        </GroupItem>
                    </Form>
                </form>
            </ScrollView>
        </DxPopup>
    );
};

export default Popup;
