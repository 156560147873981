import Bonds from "./Bonds.json";
import BusinessDay from "./Business Day.json";
import CandleBar from "./Candle Bar.json";
import Commission from "./Commission.json";
import DefensiveStock from "./Defensive Stock.json";
import Devidend from "./Devidend.json";
import FinanceSupport from "./Finance Support.json";
import Finance from "./Finance.json";
import IncomeStock from "./Income Stock.json";
import MoneyPiggy from "./Money Piggy.json";
import Money from "./Money.json";
import SearchMoney from "./Search Money.json";
import StockBalance from "./Stock Balance.json";
import StockMarketGraphDown from "./Stock Market Graph Down.json";
import StockMarketGraphUp from "./Stock Market Graph Up.json";
import StockMarketNews from "./Stock Market News.json";
import StockMarket from "./Stock Market.json";
import StockTarget from "./Stock Target.json";

export default {
    Bonds,
    BusinessDay,
    CandleBar,
    Commission,
    DefensiveStock,
    Devidend,
    FinanceSupport,
    Finance,
    IncomeStock,
    MoneyPiggy,
    Money,
    SearchMoney,
    StockBalance,
    StockMarketGraphDown,
    StockMarketGraphUp,
    StockMarketNews,
    StockMarket,
    StockTarget,
};
