import React, { Component } from "react";
import { getTrad } from "helpers";
import { TileView } from "devextreme-react/tile-view";
import { Popup } from "devextreme-react";

const InfoItem = ({ icon, label, value }) => (
  <div className="d-flex w-100 m-2">
    <div className="d-flex w-100 m-2">
      <i className={icon + " " + "mr-4"} style={{ fontSize: 20 }} />
      <div>{getTrad(label)}</div>
    </div>
    <div className="d-flex w-100 m-2 justify-content-end">{value}</div>
  </div>
);

const InfoItemFoto = ({ icon, label, value, onValueChange, field }) => {
  const item = [
    {
      document: value,
    },
  ];

  const imageBase64 = "data:image/png;base64," + value;

  const setFieldFotoSel = () => {
    onValueChange({ field_fotoSel: field, label_sel: label });
  };

  const itemRender = () => {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "100%", height: "100%" }}
        onClick={!!value ? setFieldFotoSel : null}
      >
        {!!value ? (
          <img
            className="image pointer"
            style={{
              backgroundImage: `url(${imageBase64})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              backgroundRepeat: "no-repeat",
            }}
          />
        ) : (
          <i className={icon + " " + "mr-4"} style={{ fontSize: 20 }} />
        )}
      </div>
    );
  };

  return (
    <div className="w-100 ">
      <div className="d-flex w-100 m-2 ">
        <div className="d-flex w-100 ">
          <i className={icon + " " + "mr-4"} style={{ fontSize: 20 }} />
          <div>{getTrad(label)}</div>
        </div>
      </div>
      <div className="d-flex w-100 m-2 justify-content-end">
        <TileView
          items={item}
          itemRender={itemRender}
          height={150}
          baseItemWidth={250}
          baseItemHeight={150}
          itemMargin={0}
        />
      </div>
    </div>
  );
};

class DatosFotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field_fotoSel: null,
      label_sel: null,
    };
  }

  hidePopup = () => {
    this.setState({ field_fotoSel: null, label_sel: null });
  };

  onValueChange = (newState) => {
    this.setState(newState);
  };

  get titlePopup() {
    const { label_sel } = this.state;

    let title = "";
    switch (label_sel) {
      case "fotoCaraDelantera":
        title = "Documento de identidad cara delantera";
        break;
      case "fotoCaraTrasera":
        title = "Documento de identidad cara trasera";
        break;
      case "fotoNAF":
        title = "NAF";
        break;
      case "fotoIBAN":
        title = "IBAN";
        break;
      default:
        title = "";
    }
    return title;
  }

  render() {
    const { datosPersonaSel, formIcons } = this.props;
    const { field_fotoSel } = this.state;

    return (
      <>
        <div className="d-flex justify-content-center shadow flex-column align-items-center border-radius p-4 mt-4">
          <InfoItem icon={formIcons.nacionalidad} label="nacionalidad" value={datosPersonaSel?.nacionalidad} />
          <InfoItem
            icon={formIcons.tipoDocumento}
            label="tipoDocumento"
            value={datosPersonaSel?.idTipoDocumentoIdentidadNavigation?.denominacion}
          />
          <InfoItem icon={formIcons.numDocumento} label="numDocumento" value={datosPersonaSel?.numDocumentoIdentidad} />
          <InfoItemFoto
            icon={formIcons.fotoCaraDelantera}
            label="fotoCaraDelantera"
            field="idFotoDocumentoIdentidad_ANavigation"
            value={datosPersonaSel?.idFotoDocumentoIdentidad_ANavigation?.documento}
            onValueChange={this.onValueChange}
          />
          <InfoItemFoto
            icon={formIcons.fotoCaraDelantera}
            label="fotoCaraTrasera"
            field="idFotoDocumentoIdentidad_BNavigation"
            onValueChange={this.onValueChange}
            value={datosPersonaSel?.idFotoDocumentoIdentidad_BNavigation?.documento}
          />
          <InfoItem icon={formIcons.NAF} label="NAF" value={datosPersonaSel?.NAF} />
          <InfoItemFoto
            icon={formIcons.fotoNAF}
            field="idFotoNAFNavigation"
            label="fotoNAF"
            onValueChange={this.onValueChange}
            value={datosPersonaSel?.idFotoNAFNavigation?.documento}
          />
          <InfoItem icon={formIcons.IBAN} label="IBAN" value={datosPersonaSel?.IBAN} />
          <InfoItemFoto
            icon={formIcons.fotoIBAN}
            field="idFotoIBANNavigation"
            label="fotoIBAN"
            onValueChange={this.onValueChange}
            value={datosPersonaSel?.idFotoIBANNavigation?.documento}
          />
        </div>
        <Popup
          title={this.titlePopup}
          visible={field_fotoSel !== null}
          showCloseButton={true}
          closeOnOutsideClick={true}
          width={"40%"}
          minWidth={400}
          height={"85%"}
          onHiding={this.hidePopup}
          className="px-1"
        >
          <img
            className="image"
            style={{
              backgroundImage: `url(data:image/png;base64,${
                field_fotoSel ? datosPersonaSel[field_fotoSel]?.documento : ""
              })`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              width: "100%",
              height: "100%",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Popup>
      </>
    );
  }
}

export default DatosFotos;
