import React, { Component } from "react";
import { connect } from "react-redux";

import { getTrad } from "helpers";

import { DataGrid } from "devextreme-react";

import { Column, FilterRow, Paging, Scrolling, Selection } from "devextreme-react/data-grid";
import "../css.scss";
import { balanceHorasRender } from "../shared/functions";
import FotoPersona from "components/FotoPersona";

class GridPersonas extends Component {
    constructor(props) {
        super(props);

        this.dxDataGrid_REF = React.createRef();
    }

    img_style = { width: 40, height: 40, borderRadius: 500 };

    imgRender = (cellData) => {
        return <FotoPersona style={this.img_style} idPersona={cellData.value} />;
    };

    render() {
        const { dataSource, vista, onSelectionChanged } = this.props;

        return (
            <DataGrid
                ref={this.dxDataGrid_REF}
                dataSource={dataSource}
                height={"100%"}
                width={"100%"}
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                noDataText={getTrad("sinDatos")}
                keyExpr={"idPersona"}
                onSelectionChanged={onSelectionChanged}
            >
                <Paging enabled={false} />
                <Selection mode="single" />
                <FilterRow visible={true} />
                <Scrolling mode={"infinite"} />

                <Column
                    caption={""}
                    dataField="idPersona"
                    alignment={"center"}
                    allowFiltering={false}
                    allowSorting={false}
                    allowEditing={false}
                    allowSearch={false}
                    dataType={"string"}
                    cellRender={this.imgRender}
                    width={54}
                />
                <Column
                    dataField="nombreCompleto"
                    caption={getTrad("nombre")}
                    allowFiltering={true}
                    dataType={"string"}
                    sortIndex={3}
                    sortOrder={"asc"}
                />
                <Column
                    dataField="balance"
                    caption={getTrad("balance")}
                    alignment={"center"}
                    allowFiltering={false}
                    dataType={"number"}
                    cssClass={vista.id !== 4 && vista.id !== 5 ? null : "font-weight-semiBold"}
                    cellRender={vista.id !== 4 && vista.id !== 5 ? balanceHorasRender : null}
                    calculateCellValue={this.balance_calculateCellValue}
                    width={150}
                    sortIndex={0}
                    sortOrder={"desc"}
                />
            </DataGrid>
        );
    }

    balance_calculateCellValue = (rowData) => {
        return rowData.registros.reduce((acc, item) => acc + item.minutos, 0);
    };
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(GridPersonas);
