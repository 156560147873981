import React from "react";
import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";
import { Button, DataGrid } from "devextreme-react";
import { formatNumber, getTrad } from "helpers";
import { Column, Paging } from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";

import "./Css.scss";

export const PopupIncoherencia = ({
  visible = false,
  data = [],
  title = "",
  isLoading,
  onClickAceptar,
  onHiding,
}) => {
  const dxPopup_onContentReady = (e) => {
    e.component.content().css("white-space", "pre-line"); // salto de linea en string con \n
  };

  let balances = {};
  const dataCustom = data.map((mov) => {
    let clave = `${mov.idAlmacen}-${mov.idRecambio}`;
    let cambio =
      mov.idTipoMovimientoRecambio === 3 ? -mov.cantidad : mov.cantidad;

    const regex = /^\/Date\(\d+\)\/$/;
    if (regex.test(mov.fecha)) {
      const milliseconds = Number(mov.fecha.match(/\d+/)[0]);
      mov.fecha = new Date(milliseconds);
    }

    if (mov.idTipoMovimientoRecambioOriginal === 4) {
      balances[clave] = cambio;
    } else if (balances[clave]) {
      balances[clave] += cambio;
    } else {
      balances[clave] = cambio;
    }

    return { ...mov, balance: balances[clave] };
  });

  const onRowPrepared = (e) => {
    if (e.rowType == "data" && e.data.idTipoMovimientoRecambioOriginal === 7) {
      e.rowElement.css("background", "#FFEB9C");
    }
    if (e.rowType == "data" && e.data.balance < 0) {
      e.rowElement.css("background", "var(--danger)");
      e.rowElement.css("color", "white");
    }
  };

  const groupCellRender = (e) => {
    return <div>{e.value}</div>;
  };

  const calculateCellValue = (e, field) => {
    return e[field] && e[field].toString().length > 0 ? e[field] : "-";
  };

  const calculateCellValue_currency = (e, field) => {
    return formatNumber(e[field], 2, "currency", e.codigoMoneda);
  };

  return (
    <Popup
      title={title}
      visible={visible}
      height="80%"
      width="80%"
      onContentReady={dxPopup_onContentReady}
      onHiding={onHiding}
    >
      <div className="d-flex flex-column he-100">
        <div className={"PopupGrid_subtitle"}>
          <div
            dangerouslySetInnerHTML={{
              __html: getTrad("incoherenciaRecambiosAlmacen").replace(
                "{0}",
                dataCustom.find((m) => m.balance < 0)?.denoRecambio,
              ),
            }}
          />
        </div>
        <div
          className="he-100 flex-1 dxDataGridContainer_popupGrid"
          style={{ paddingBottom: 50 }}
        >
          <DataGrid
            dataSource={dataCustom}
            columnsAutoWidth={true}
            height={"100%"}
            width={"100%"}
            hoverStateEnabled={true}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            onRowPrepared={onRowPrepared}
          >
            <Paging enabled={false} />
            <Column
              dataField={"denoRecambio"}
              caption={getTrad("recambio")}
              groupIndex={0}
              groupCellRender={groupCellRender}
            />
            <Column
              dataField={"denoAlmacen"}
              caption={getTrad("almacen")}
              groupIndex={1}
              groupCellRender={groupCellRender}
            />
            <Column
              dataField={"ID"}
              alignment={"center"}
              width={100}
              calculateCellValue={(e) => calculateCellValue(e, "ID")}
            />
            <Column
              dataField={"fecha"}
              caption={getTrad("fecha")}
              alignment={"center"}
              dataType={"datetime"}
              format={"dd/MM/yyyy HH:mm"}
              sortOrder={"asc"}
              width={150}
            />
            <Column
              dataField={"tipoMovimiento"}
              caption={getTrad("tipoMovimiento")}
              alignment={"center"}
            />
            <Column
              dataField={"origen"}
              caption={getTrad("origen")}
              calculateCellValue={(e) => calculateCellValue(e, "origen")}
            />
            <Column
              dataField={"destino"}
              caption={getTrad("destino")}
              calculateCellValue={(e) => calculateCellValue(e, "destino")}
            />
            <Column
              dataField={"denoLavanderia"}
              caption={getTrad("lavanderia")}
              alignment={"center"}
            />
            <Column
              dataField={"cantidad"}
              caption={getTrad("cantidad")}
              dataType={"number"}
              alignment={"center"}
              width={100}
            />
            <Column
              dataField={"balance"}
              caption={getTrad("balance")}
              dataType={"number"}
              alignment={"center"}
              width={100}
            />
            <Column
              dataField={"precio"}
              caption={getTrad("precio")}
              dataType={"number"}
              calculateCellValue={(e) =>
                calculateCellValue_currency(e, "precio")
              }
              alignment={"center"}
              width={150}
            />
            <Column
              dataField={"importe"}
              caption={getTrad("importe")}
              dataType={"number"}
              calculateCellValue={(e) =>
                calculateCellValue_currency(e, "importe")
              }
              alignment={"center"}
              width={150}
            />
            <LoadPanel enabled={true} />
          </DataGrid>
          <LoadPanel
            shadingColor="transparent"
            delay={0}
            animation={null}
            visible={isLoading}
            showIndicator={true}
            shading={true}
            showPane={true}
          />
        </div>
      </div>

      <ToolbarItemPopup widget="dxButton" location="after" toolbar="bottom">
        <Button
          text={getTrad("aceptar")}
          type="success"
          onClick={onClickAceptar}
        />
      </ToolbarItemPopup>
    </Popup>
  );
};
