import { Box } from "devextreme-react";
import { Item } from "devextreme-react/box";
import React from "react";
import { getTrad } from "helpers";
import { ControlPresupuestarioCxt } from "../../contexts/context";
import ResumenCard from "./ResumenCard";
import CentroCard from "./CentroCard";

class ResumenControlPresupuestario extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    style = {
        box: { gap: 20 },
    };

    render() {
        const { isLoading } = this.props;
        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ resumenes, centros }) => {
                    return (
                        <Box height={"100%"} style={this.style.box}>
                            <Item ratio={4}>
                                <CentroCard
                                    title={`${getTrad("centroCoste")} / ${getTrad("elementoPep")}`}
                                    placeholder={getTrad("sinSeleccion")}
                                    value={
                                        centros.length > 1 ? getTrad("agrupacionCentros") : centros.at(0)?.denominacion
                                    }
                                    icon={"icon_Entidad"}
                                />
                            </Item>
                            <Item ratio={3}>
                                <ResumenCard
                                    title={getTrad("presupuestado")}
                                    placeholder={"---"}
                                    value={resumenes.presupuestado}
                                    icon={"Commission"}
                                    isLoading={isLoading}
                                />
                            </Item>
                            <Item ratio={3}>
                                <ResumenCard
                                    title={getTrad("realAjustado")}
                                    placeholder={"---"}
                                    value={resumenes.realAjustado}
                                    icon={"IncomeStock"}
                                    isLoading={isLoading}
                                    color={
                                        resumenes.realAjustado > 0
                                            ? "var(--success)"
                                            : resumenes.realAjustado < 0
                                            ? "var(--danger)"
                                            : "inherit"
                                    }
                                />
                            </Item>
                            <Item ratio={3}>
                                <ResumenCard
                                    title={getTrad("desviacion")}
                                    placeholder={"---"}
                                    value={resumenes.desviacion}
                                    icon={"StockMarket"}
                                    isLoading={isLoading}
                                    color={
                                        resumenes.desviacion > 0
                                            ? "var(--success)"
                                            : resumenes.desviacion < 0
                                            ? "var(--danger)"
                                            : "inherit"
                                    }
                                />
                            </Item>
                        </Box>
                    );
                }}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

export default ResumenControlPresupuestario;
