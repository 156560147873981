import { connectionConstants } from "../../../constants";
import {
    history,
    authHeader,
    errorHandler,
    getTrad,
    dxMensajePregunta,
    formatDate,
    formatDate_noTime_parameter,
    tooltipControl_creacion,
    convertClienteUtcToLavanderiaUtc,
    get_isMovil,
    get_template_colorTapa,
    patchRequestHandler,
    getCierresFacturacion,
    getDaysInRange,
} from "../../../helpers";
import { svg_warning, svg_elemTrans_ud, svg_elemTrans_bac, svg_lock_outline } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import CustomStore from "devextreme/data/custom_store";

import validationEngine from "devextreme/ui/validation_engine";

//Css
import "./Css.scss";

export default function cargaJs(COMPONENT, LAVANDERIA, USER) {
    var deferred = $.Deferred();

    var pedidoSel = {
        idPedido: null,
        idEntidad: null,
        idTipoPedido: null,
        fecha: null,
        observaciones: null,
        idUsuarioCreador: null,
    }; // PEDIDO SELECCIONADO EN GRID NUEVO PEDIDO

    var guardando = false; //Variable que sirve para pasar la validación de HasChanges y no tener que hacer un cancelEdit y verse todo a 0 cuando se guarda
    var entidadSel = null;
    var hayPrendas_Stock = false;
    var hayPrendas_Peticion = false;
    var isCerradoFactEntidad = false;
    var dxDateBox_disabledDates = [];
    var idsEntidad_disabled = [];
    let fechasEntidadesVisibles = [];

    //#region DATASOURCES
    var datasource_compañias = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
            key: "idCompañia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA ? LAVANDERIA.idLavanderia : null;
            },
            version: 4,
        }),
        sort: "denominacion",
        select: ["idCompañia", "denominacion"],
        postProcess: function (data) {
            if (datasource_compañias.items().length === 0) {
                data.splice(0, 0, { idCompañia: 0, denominacion: "TODAS" });
            }
            return data;
        },
    });

    var datasource_entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA ? LAVANDERIA.idLavanderia : null;
                request.params.fechaEntidadActiva = null;
            },
            version: 4,
        }),
        expand: "idLavanderia($select=horarioVerano,idZonaHorariaNavigation;$expand = idZonaHorariaNavigation($select=GMT))",
        sort: "denominacion",
        select: [
            "idEntidad",
            "denominacion",
            "idCompañia",
            "isTodasPrendaNNuevoPedido_compañia",
            "isTodasPrendaNNuevoPedido_entidad",
            "enableObservacionesPedido",
        ],
        map: function (dataItem) {
            let lavanderia = dataItem.idLavanderia[0];
            return {
                idEntidad: dataItem.idEntidad,
                denominacion: dataItem.denominacion,
                GMT: lavanderia
                    ? lavanderia.horarioVerano
                        ? parseInt(lavanderia.idZonaHorariaNavigation.GMT) + 1
                        : lavanderia.idZonaHorariaNavigation.GMT
                    : null,
                idCompañia: dataItem.idCompañia,
                isTodasPrendaNNuevoPedido_compañia: dataItem.isTodasPrendaNNuevoPedido_compañia,
                isTodasPrendaNNuevoPedido_entidad: dataItem.isTodasPrendaNNuevoPedido_entidad,
                disabled: idsEntidad_disabled.filter((idEntidad) => idEntidad == dataItem.idEntidad).length > 0,
                enableObservacionesPedido: dataItem.enableObservacionesPedido,
            };
        },
    });

    var prendaNPedido_map = function (dataItem) {
        return {
            idPrenda: dataItem.idPrenda,
            idPedido: dataItem.idPedido,
            idPrendaNPedido: dataItem.idPrendaNPedido,
            codigoPrenda: dataItem.idPrendaNavigation.codigoPrenda,
            cantidad: dataItem.peticion / (dataItem.idPrendaNavigation.udsXBacPedido || 1),
            idPrendaNavigation: dataItem.idPrendaNavigation, // Se añade porque en las columnas hay que referenciar a idPrendaNavigation, sino llamada peta
            denoPrenda: dataItem.idPrendaNavigation.denominacion,
            udsXBacPedido: dataItem.idPrendaNavigation.udsXBacPedido,
            fecha: dataItem.idPedidoNavigation.fecha,
            denoElemTransPedido: dataItem.idPrendaNavigation.elementoPedidoNavigation
                ? dataItem.idPrendaNavigation.elementoPedidoNavigation.denominacion
                : null,
            codigoElemTransPedido: dataItem.idPrendaNavigation.elementoPedidoNavigation
                ? dataItem.idPrendaNavigation.elementoPedidoNavigation.codigo
                : null,
            idEntidad: dataItem.idPrendaNavigation.idEntidad,
            codigoHexadecimal: dataItem.idPrendaNavigation.idColorTapaNavigation
                ? dataItem.idPrendaNavigation.idColorTapaNavigation.codigoHexadecimal
                : null,
            observaciones: dataItem.idPedidoNavigation.observaciones,
            denoMarcaTapa: dataItem.idPrendaNavigation.idMarcaTapaNavigation
                ? dataItem.idPrendaNavigation.idMarcaTapaNavigation.marca
                : null,
            stockActual:
                dataItem.stockActual != null
                    ? dataItem.stockActual / (dataItem.idPrendaNavigation.udsXBacPedido || 1)
                    : null,
            stockDefinido:
                dataItem.idPrendaNavigation.tblPrendaNEntidad_NuevoPedido.length > 0
                    ? dataItem.idPrendaNavigation.tblPrendaNEntidad_NuevoPedido[0].stockDefinido /
                      (dataItem.idPrendaNavigation.udsXBacPedido || 1)
                    : 0,
        };
    };

    var prendaNPedido_onLoading = function (loadOptions) {
        loadOptions.expand = [
            "idPrendaNavigation($select=codigoPrenda, denominacion, udsXBacPedido, idEntidad; $expand=tblPrendaNEntidad_NuevoPedido($select=stockDefinido; $filter=idEntidad eq " +
                pedidoSel.idEntidad +
                "),elementoPedidoNavigation($select=denominacion, codigo),idColorTapaNavigation($select=codigoHexadecimal), idMarcaTapaNavigation($select=marca)), idPedidoNavigation($select=fecha,idEntidad,observaciones;$filter=idTipoProduccion eq null)",
        ];
    };

    var prendaNPedido_onLoaded = function (data) {
        if (data.length == 0) return [];

        let prendas = [];
        if (Array.isArray(data[0].items)) {
            $.each(data, function (index, item) {
                $.merge(prendas, item.items);
            });
        } else prendas = data;

        $.each(prendas, function (index, item) {
            if (pedidoSel.idPedido != null) {
                if (item.stockActual == null) hayPrendas_Peticion = true;
                else hayPrendas_Stock = true;
            } else {
                if (
                    item.tblPrendaNEntidad_NuevoPedido &&
                    ((item.tblPrendaNEntidad_NuevoPedido.length > 0 &&
                        (item.tblPrendaNEntidad_NuevoPedido[0].stockDefinido == null ||
                            item.tblPrendaNEntidad_NuevoPedido[0].stockDefinido == 0)) ||
                        item.tblPrendaNEntidad_NuevoPedido.length == 0)
                )
                    hayPrendas_Peticion = true;
                else hayPrendas_Stock = true;
            }
        });

        return prendas;
    };

    var prendaNPedido_NuevoPedido_onLoaded = function (data) {
        if (data.length == 0) return [];

        let prendas = [];
        if (Array.isArray(data[0].items)) {
            $.each(data, function (index, item) {
                $.merge(prendas, item.items);
            });
        } else prendas = data;
        $.each(prendas, function (index, item) {
            if (pedidoSel.idPedido != null) {
                if (item.stockActual == null) hayPrendas_Peticion = true;
                else hayPrendas_Stock = true;
            } else {
                if (item.stockDefinido == null || item.stockDefinido == 0) hayPrendas_Peticion = true;
                else hayPrendas_Stock = true;
            }
        });

        return prendas;
    };

    var datasource_tblPrendaNEntidad_NuevoPedido = new DataSource({
        group: [{ selector: "idEntidad", desc: false }],
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Pedidos/GetPrendasNuevoPedido",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                if (
                    datasource_entidades.items().length === 1 &&
                    typeof $("#dxDropDownBox_selCompaEnti")[0] !== "undefined"
                ) {
                    request.params.idEntidad = $("#dxDropDownBox_selCompaEnti")
                        .dxDropDownBox("instance")
                        .option("value");
                } else request.params.idEntidad = entidadSel != null ? entidadSel.idEntidad : -1;
            },
            onLoaded: prendaNPedido_NuevoPedido_onLoaded,
            version: 4,
        }),
        sort: ["codigoPrenda"],
        map: function (dataItem) {
            let udsXBacPedido = dataItem.udsXBacPedido ? dataItem.udsXBacPedido : 1;
            return {
                idPrenda: dataItem.idPrenda,
                idPedido: null,
                idPrendaNPedido: null,
                codigoPrenda: dataItem.codigoPrenda,
                cantidad: 0,
                idPrendaNavigation: dataItem, // Se añade porque en las columnas hay que referenciar a idPrendaNavigation, sino llamada peta
                denoPrenda: dataItem.denominacion,
                udsXBacPedido: dataItem.udsXBacPedido,
                fecha: null,
                denoElemTransPedido: dataItem.denoElemTransPedido,
                codigoElemTransPedido: dataItem.codigoElemTransPedido,
                idEntidad: dataItem.idEntidad,
                codigoHexadecimal: dataItem.colorTapa_hexadecimal,
                observaciones: null,
                denoMarcaTapa: dataItem.marcaTapa,
                stockActual: null,
                stockDefinido:
                    dataItem.stockDefinido != null && dataItem.stockDefinido != 0
                        ? dataItem.stockDefinido / udsXBacPedido
                        : null,
            };
        },
    });

    var datasource_prendaPedido = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaNPedido",
            key: "idPrendaNPedido",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idPedido = pedidoSel.idPedido;
            },
            onLoading: prendaNPedido_onLoading,
            onLoaded: prendaNPedido_onLoaded,
            version: 4,
        }),
        select: ["idPrenda", "idPedido", "idPrendaNPedido", "peticion", "stockActual"],
        map: prendaNPedido_map,
    });

    // Se pone uno igual a datasource_prendaPedido porque a la hora de hcer filter() si es el mismo no funciona bien.
    var datasource_prendaPedido_stock = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaNPedido",
            key: "idPrendaNPedido",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idPedido = pedidoSel.idPedido;
            },
            onLoading: prendaNPedido_onLoading,
            onLoaded: prendaNPedido_onLoaded,
            version: 4,
        }),
        select: ["idPrenda", "idPedido", "idPrendaNPedido", "peticion", "stockActual"],
        map: prendaNPedido_map,
    });

    var datasource_pedidos = new DataSource({
        store: new CustomStore({
            key: ["idPrenda", "idPrendaNPedido"],
            load: function () {
                if (pedidoSel.idPedido) {
                    datasource_prendaPedido.filter("stockActual eq null");
                    datasource_prendaPedido.group({
                        selector: "idPrendaNavigation.idEntidad",
                        desc: false,
                    });
                    datasource_prendaPedido.sort(["idPrendaNavigation/codigoPrenda"]);
                    return datasource_prendaPedido.load();
                } else {
                    var deferred = $.Deferred();
                    datasource_tblPrendaNEntidad_NuevoPedido.reload().done(function (data) {
                        $.each(data, function (index, item) {
                            item.items = $.grep(item.items, function (item) {
                                return item.stockDefinido == null || item.stockDefinido == 0;
                            });
                        });
                        deferred.resolve({ data: data });
                    });
                    return deferred.promise();
                }
            },
            update: (key, values) => {
                return datasource_prendaPedido.store().update(key.idPrendaNPedido, values);
            },
            remove: (key) => {
                return datasource_prendaPedido.store().remove(key.idPrendaNPedido);
            },
        }),
    });

    var datasource_pedidos_stock = new DataSource({
        store: new CustomStore({
            key: ["idPrenda", "idPrendaNPedido"],
            load: function () {
                if (pedidoSel.idPedido) {
                    datasource_prendaPedido_stock.filter("stockActual ne null");
                    datasource_prendaPedido_stock.group({
                        selector: "idPrendaNavigation.idEntidad",
                        desc: false,
                    });
                    datasource_prendaPedido_stock.sort(["idPrendaNavigation/codigoPrenda"]);
                    return datasource_prendaPedido_stock.load();
                } else {
                    var deferred = $.Deferred();
                    datasource_tblPrendaNEntidad_NuevoPedido.reload().done(function (data) {
                        $.each(data, function (index, item) {
                            item.items = $.grep(item.items, function (item) {
                                return item.stockDefinido != null && item.stockDefinido != 0;
                            });
                        });
                        deferred.resolve({ data: data });
                    });
                    return deferred.promise();
                }
            },
            update: (key, values) => {
                return datasource_prendaPedido_stock.store().update(key.idPrendaNPedido, values);
            },
            remove: (key) => {
                return datasource_prendaPedido_stock.store().remove(key.idPrendaNPedido);
            },
        }),
    });

    var datasource_añadirPrenda = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Pedidos/GetPrendasNuevoPedido",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idEntidad = pedidoSel != null ? pedidoSel.idEntidad : -1;
            },
            version: 4,
        }),
        sort: ["codigoPrenda"],
        postProcess: function (data) {
            var dxDataGrid_NuevoPedido = $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
            let totalItems = [];
            $.each(dxDataGrid_NuevoPedido.getDataSource().items(), function (index, item) {
                $.each(item.items, function (iEl, el) {
                    totalItems.push(el.idPrenda);
                });
            });

            if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid")) {
                var dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance");
                $.each(dxDataGrid_NuevoPedido_almacen.getDataSource().items(), function (index, item) {
                    $.each(item.items, function (iEl, el) {
                        totalItems.push(el.idPrenda);
                    });
                });
            }

            var procesedData = data.filter(function (item) {
                return $.inArray(item.idPrenda, totalItems) === -1;
            });
            return procesedData;
        },
        map: function (dataItem) {
            return {
                idPrenda: dataItem.idPrenda,
                idPedido: null,
                idPrendaNPedido: null,
                codigoPrenda: dataItem.codigoPrenda,
                cantidad: 0,
                idPrendaNavigation: dataItem, // Se añade porque en las columnas hay que referenciar a idPrendaNavigation, sino llamada peta
                denoPrenda: dataItem.denominacion,
                udsXBacPedido: dataItem.udsXBacPedido,
                fecha: null,
                denoElemTransPedido: dataItem.denoElemTransPedido,
                codigoElemTransPedido: dataItem.codigoElemTransPedido,
                idEntidad: dataItem.idEntidad,
                codigoHexadecimal: dataItem.colorTapa_hexadecimal,
                observaciones: null,
                denoMarcaTapa: dataItem.marcaTapa,
                stockActual: dataItem.stockDefinido || dataItem.stockDefinido == 0 ? null : 0,
                stockDefinido:
                    dataItem.stockDefinido != null && dataItem.stockDefinido != 0
                        ? dataItem.stockDefinido / dataItem.udsXBacPedido
                        : null,
            };
        },
    });

    var datasource_pedidosAnteriores_Cierres = new DataSource({
        store: new CustomStore({
            key: "idPedido",
            load: function (loadOptions) {
                var deferred = $.Deferred();

                let pageIndex = loadOptions.skip == 0 ? 0 : loadOptions.skip / loadOptions.take;

                datasource_pedidosAnteriores.paginate(true);
                datasource_pedidosAnteriores.pageSize(loadOptions.take);
                datasource_pedidosAnteriores.sort(loadOptions.sort);
                datasource_pedidosAnteriores.requireTotalCount(true);
                datasource_pedidosAnteriores.searchValue(loadOptions.searchValue);
                datasource_pedidosAnteriores.searchOperation(loadOptions.searchOperation);
                datasource_pedidosAnteriores.filter(loadOptions.filter ? loadOptions.filter : null);
                datasource_pedidosAnteriores.pageIndex(pageIndex);

                datasource_pedidosAnteriores.load(loadOptions).done(function (data) {
                    fechasEntidadesVisibles = [];
                    let pedidosMapeados = data.map((x) => {
                        return {
                            fecha: formatDate_noTime_parameter(x.fecha),
                            idEntidad: x.idEntidad,
                        };
                    });
                    fechasEntidadesVisibles = [...new Set(pedidosMapeados)];

                    datasource_tblCierreFactEntidad.load().done((fechasEntidadCierre) => {
                        data.forEach((pedido) => {
                            fechasEntidadCierre.forEach((item) => {
                                let { fechaDesde, fechaHasta } = item;
                                let rangoFechas = getDaysInRange(fechaDesde, fechaHasta).map((x) =>
                                    formatDate_noTime_parameter(x)
                                );

                                if (!pedido.isCerradoFactEntidad)
                                    pedido.isCerradoFactEntidad =
                                        pedido.idEntidad == item.idEntidad &&
                                        rangoFechas.filter(
                                            (fecha) => fecha == formatDate_noTime_parameter(pedido.fecha)
                                        ).length > 0;
                            });
                        });
                        deferred.resolve(data, {
                            totalCount: datasource_pedidosAnteriores.totalCount(),
                        });
                    });
                });

                return deferred.promise();
            },
        }),
    });

    var datasource_tblCierreFactEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
            key: ["idEntidad", "fechaDesde", "fechaHasta"],
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            onLoading: function (loadOptions) {
                let idsEntidad = [...new Set(fechasEntidadesVisibles.map((x) => x.idEntidad))];
                let fechas = [...new Set(fechasEntidadesVisibles.map((x) => x.fecha))];

                let filtroEntidades = idsEntidad.length > 0 ? ["idEntidad in (" + idsEntidad + ")"] : ["true"];
                let filtroFechas = "";

                fechas.forEach((fecha, index) => {
                    if (index != 0) filtroFechas += " or ";
                    filtroFechas += "(fechaDesde le " + fecha + " and fechaHasta ge " + fecha + ")";
                });

                if (filtroFechas == "") filtroFechas = "true";
                if (loadOptions.filter) {
                    loadOptions.filter = [loadOptions.filter, "and", [filtroEntidades], "and", [filtroFechas]];
                } else {
                    loadOptions.filter = [[filtroEntidades], "and", [filtroFechas]];
                }
            },
            version: 4,
        }),
    });

    var datasource_pedidosAnteriores = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPedido",
            key: "idPedido",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                if (request.method === "get") {
                    request.params.idLavanderia = LAVANDERIA ? LAVANDERIA.idLavanderia : null;
                }
            },
            onLoading: function (loadOptions) {
                if (loadOptions.filter) {
                    loadOptions.filter = [loadOptions.filter, "and", ["idTipoProduccion eq null"]];
                } else {
                    loadOptions.filter = ["idTipoProduccion eq null"];
                }
            },
            version: 4,
        }),
        select: [
            "idPedido",
            "idEntidad",
            "idEstadoPedido",
            "idTipoPedido",
            "fecha",
            "observaciones",
            "codigo",
            "idTipoProduccion",
            "idUsuarioCreador",
            "isCerrado",
            "isAutomatico",
        ],
        expand: [
            "idTipoPedidoNavigation($select=denominacion)",
            "idEntidadNavigation($select=denominacion)",
            "idUsuarioCreadorNavigation($select=nombre)",
            "tblPrendaNPedido($top=1;$select=idPedido)",
        ],
    });

    var datasource_tipoPedido = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoPedido",
            key: "idTipoPedido",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idEntidad = entidadSel ? entidadSel.idEntidad : -1;
            },
            version: 4,
        }),
        select: ["idTipoPedido", "denominacion"],
    });
    //#endregion

    //#region Cierre Facturación Entidad
    function deshabilitarEdicionCierreFact(idEntidad, fechaDesde, fechaHasta) {
        return new Promise((resolve, reject) => {
            getCierresFacturacion(idEntidad, fechaDesde, fechaHasta)
                .then((data) => {
                    // Comprobación si entidad actual con la fecha seleccionada es editable
                    isCerradoFactEntidad = data.length > 0;

                    let columnasEditables = ["stockActual", "cantidad"];
                    let grids = [
                        $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance"),
                        $("#dxDataGrid_NuevoPedido").dxDataGrid("instance"),
                    ];

                    grids.forEach((grid) => {
                        if (grid) grid.option("editing.allowUpdating", !isCerradoFactEntidad);

                        columnasEditables.forEach((dataField) => {
                            if (grid)
                                grid.columnOption(dataField, "cssClass", !isCerradoFactEntidad && "dx-Cell_Editable");
                        });
                    });
                    deshabilitarFechasFacturacion(idEntidad, fechaDesde);
                    resolve(data);
                })
                .catch(reject);
        });
    }

    function deshabilitarFechasFacturacion(idEntidad, fechaDesde) {
        dxDateBox_disabledDates = [];
        return new Promise((resolve, reject) => {
            if (idEntidad) {
                getCierresFacturacion(idEntidad, null, null)
                    .then((data) => {
                        //region deshabilitar días
                        let disabledDates = [];
                        data.forEach((item) => {
                            disabledDates.push(...getDaysInRange(new Date(item.fechaDesde), new Date(item.fechaHasta)));
                        });
                        dxDateBox_disabledDates = [...disabledDates];

                        //Aplicar fechaFiltrada
                        $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option({
                            value: fechaDesde,
                            disabledDates: dxDateBox_disabledDates,
                        });
                        //#endregion
                        resolve(data);
                    })
                    .catch(reject);
            } else {
                $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option({
                    value: fechaDesde,
                    disabledDates: [],
                });
                resolve();
            }
        });
    }

    function deshabilitarEntidadesFacturacion(fechaDesde, fechaHasta) {
        idsEntidad_disabled = [];
        getCierresFacturacion(null, fechaDesde, fechaHasta).then(function (entidadesDisabled) {
            idsEntidad_disabled = [...entidadesDisabled.map((x) => x.idEntidad)];

            datasource_entidades
                .items()
                .forEach(
                    (item) =>
                        (item.disabled =
                            idsEntidad_disabled.filter((idEntidad) => idEntidad == item.idEntidad).length > 0)
                );
            if ($("#dxDropDownBox_lista_entidades").data("dxList"))
                $("#dxDropDownBox_lista_entidades")
                    .dxList("instance")
                    .option("dataSource", datasource_entidades.items());
        });
    }
    deshabilitarEntidadesFacturacion(new Date(), new Date());
    //#endregion

    $("#dxTooltip_Pedidos").dxTooltip({
        showEvent: "mouseenter",
        hideEvent: "mouseleave",
    });

    COMPONENT.setReport({
        denominacion: "albaran_pedidos",
        parameterEvent: CustomizeParameterEditors,
    });

    //#region DATOS DEL PEDIDO (Entidad, TipoPedido, Fecha)
    $.when(datasource_entidades.reload(), datasource_compañias.reload()).then(function (entidades, compañias) {
        if (entidades[0].length === 1) {
            entidadSel = entidades[0][0];
            pedidoSel.idEntidad = entidadSel.idEntidad;
            datasource_tipoPedido.reload().done(function (items) {
                if ($("#dxSelectBox_tipoPedido").data("dxSelectBox"))
                    $("#dxSelectBox_tipoPedido").dxSelectBox("instance").option("value", items[0].idTipoPedido);
            });
            datasource_tblPrendaNEntidad_NuevoPedido.reload().done(function () {
                if (typeof $("#dxResponsiveBox_filtros").data("dxResponsiveBox") !== "undefined")
                    $("#dxResponsiveBox_filtros")
                        .dxResponsiveBox("instance")
                        .option("items", get_dxResponsiveBox_filtros_items());
            });
        }

        let entidadesFiltradas = [];
        if (pedidoSel.idEntidad) {
            entidadesFiltradas = $.grep(datasource_entidades.items(), function (item) {
                return item.idEntidad === pedidoSel.idEntidad;
            });
        }

        $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel({
            shadingColor: "transparent",
            position: { of: "#dxScrollView_nuevoPedido" },
            visible: false,
            showIndicator: true,
            showPane: true,
            shading: true,
            closeOnOutsideClick: false,
        });

        let get_grid_pedidosAnteriores = $("<div id='dxDatagrid_pedidosAnteriores' />").dxDataGrid({
            //Datos
            dataSource: datasource_pedidosAnteriores_Cierres,
            remoteOperations: {
                filtering: true,
                grouping: false,
                groupPaging: true,
                paging: true,
                sorting: true,
                summary: true,
            },
            //Propiedades
            columnsAutoWidth: true,
            height: "100%",
            width: "100%",
            filterRow: {
                visible: false,
                applyFilter: "auto",
            },
            loadPanel: {
                shading: true,
            },
            headerFilter: {
                visible: true,
            },
            searchPanel: {
                visible: true,
                width: 240,
                placeholder: getTrad("busqueda"),
            },
            selection: {
                mode: "single",
                deferred: false,
            },
            selectedRowKeys: pedidoSel.idPedido !== null ? [pedidoSel.idPedido] : [],
            scrolling: {
                mode: get_isMovil(COMPONENT.props.resolucion) ? "infinite" : "standard",
            },
            paging: {
                pageSize: 50,
            },
            pager: {
                visible: !get_isMovil(COMPONENT.props.resolucion),
                showPageSizeSelector: true,
                allowedPageSizes: [20, 50, 100],
                showInfo: true,
            },
            hoverStateEnabled: true,
            columns: [
                {
                    dataField: "isAutomatico",
                    caption: " ",
                    allowHeaderFiltering: false,
                    alignment: "center",
                    width: 25,
                    cssClass: "p-0 position-relative",
                    cellTemplate: function (cellElement, cellInfo) {
                        if (cellInfo.value) {
                            $("<i/>")
                                .addClass("position-absolute-center icon_auto_awesome text-primary font-size-lg")
                                .appendTo(cellElement);
                        }
                    },
                },
                {
                    dataField: "codigo",
                    caption: !get_isMovil(COMPONENT.props.resolucion) ? getTrad("codigo") : getTrad("codigo_abr"),
                    allowHeaderFiltering: false,
                    alignment: "center",
                    width: 80,
                },
                {
                    dataField: "fecha",
                    caption: getTrad("fecha"),
                    dataType: "datetime",
                    format: "dd/MM/yyyy - HH:mm",
                    width: get_isMovil(COMPONENT.props.resolucion) ? 110 : 145,
                    alignment: "center",
                    sortOrder: "desc",
                    allowHeaderFiltering: true,
                },
                {
                    dataField: "idEntidadNavigation.denominacion",
                    caption: getTrad("entidad"),
                    minWidth: 175,
                    visible: !get_isMovil(COMPONENT.props.resolucion),
                    alignment: "left",
                    allowHeaderFiltering: true,
                    cellTemplate: function (cellElement, cellInfo) {
                        var container = $("<div />").text(cellInfo.text).css("position", "relative");
                        let text;

                        if (cellInfo.data.tblPrendaNPedido.length === 0) {
                            text = getTrad("alerta_PedidoSinPrendas");
                        }

                        if (cellInfo.data.isCerrado) {
                            if (text != null) {
                                text = getTrad("alerta_PedidoSinPrendas") + " y está cerrado";
                            } else text = "El pedido está cerrado";
                        }

                        if (cellInfo.data.isCerradoFactEntidad) {
                            text = getTrad("factBloqueadaEntiFecha");
                            container.append(
                                svg_lock_outline
                                    .clone()
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer")
                                    .css("position", "absolute")
                                    .css("right", "0px")
                                    .css("top", "-3px")
                            );
                            tooltipControl_creacion(container, text);
                        } else if (text != null) {
                            container.append(
                                svg_warning
                                    .clone()
                                    .css("height", "25px")
                                    .css("width", "25px")
                                    .css("cursor", "pointer")
                                    .css("position", "absolute")
                                    .css("right", "0px")
                                    .css("top", "-3px")
                            );
                            tooltipControl_creacion(container, text);
                        }
                        cellElement.append(container);
                    },
                },
                {
                    dataField: "idTipoPedidoNavigation.denominacion",
                    width: "20%",
                    hidingPriority: 0,
                    caption: getTrad("tipoPedido"),
                    alignment: "left",
                },
            ],
            //Eventos
            onContentReady: function () {
                deferred.resolve();
            },
            onRowPrepared: function (info) {
                if (info.rowType === "data") {
                    info.rowElement.css("cursor", "pointer");
                }
            },
            onSelectionChanged: function (e) {
                if (e.selectedRowsData.length > 0) {
                    var grid = $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
                    if (nuevoPedido_HasChanges()) {
                        var popup = $("#popup_alert_AceptarCancelar").dxPopup("instance");
                        if (!popup.option("visible")) {
                            $("#popup_alert_AceptarCancelar").dxPopup("instance").show();
                            $("#btnAceptar")
                                .dxButton("instance")
                                .option("onClick", function () {
                                    //Al limpiar el pedidoSel la próxima vez que entre en el evento onSelectionChanged no detectará cambios.
                                    set_nuevoPedido();
                                    grid.cancelEditData();

                                    if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid"))
                                        $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance").cancelEditData();

                                    e.component.option("selectedRowKeys", e.currentSelectedRowKeys);
                                    $("#popup_alert_AceptarCancelar").dxPopup("instance").hide();

                                    if (get_isMovil(COMPONENT.props.resolucion)) {
                                        $("#popup_pedidosAnteriores").dxPopup("instance").hide();
                                        $("#dxAccordion_opcionesPedido").dxAccordion("instance").expandItem(0);
                                    }
                                });
                            e.component.option("selectedRowKeys", e.currentDeselectedRowKeys);
                        }
                    } else {
                        entidadSel = datasource_entidades
                            .items()
                            .find((x) => x.idEntidad === e.selectedRowsData[0].idEntidad);
                        $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel("instance").show();
                        let dxToolbar_filtros_nuevoPed = $("#dxToolbar_filtros_nuevoPed").dxToolbar("instance");
                        dxToolbar_filtros_nuevoPed.option("disabled", true);
                        e.component.option("disabled", true);
                        var data = e.selectedRowsData[0];
                        pedidoSel = data;

                        hayPrendas_Peticion = false;
                        hayPrendas_Stock = false;

                        if ($("#dxDataGrid_NuevoPedido").data("dxDataGrid"))
                            $("#dxDataGrid_NuevoPedido").dxDataGrid("instance").beginUpdate();
                        if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid"))
                            $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance").beginUpdate();

                        $.when(
                            datasource_pedidos_stock.reload(),
                            datasource_pedidos.reload(),
                            deshabilitarEdicionCierreFact(pedidoSel.idEntidad, pedidoSel.fecha, pedidoSel.fecha)
                        )
                            .then(function () {
                                let grids = [];
                                let dxDataGrid_NuevoPedido, dxDataGrid_NuevoPedido_almacen;

                                let dxResponsiveBox_filtros = $("#dxResponsiveBox_filtros").dxResponsiveBox("instance");
                                dxResponsiveBox_filtros.option("items", get_dxResponsiveBox_filtros_items());
                                dxResponsiveBox_filtros.beginUpdate();

                                if ($("#dxDataGrid_NuevoPedido").data("dxDataGrid")) {
                                    dxDataGrid_NuevoPedido = $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
                                    dxDataGrid_NuevoPedido.endUpdate();
                                    grids.push("#dxDataGrid_NuevoPedido");
                                }

                                if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid")) {
                                    dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid(
                                        "instance"
                                    );
                                    dxDataGrid_NuevoPedido_almacen.endUpdate();
                                    grids.push("#dxDataGrid_NuevoPedido_almacen");
                                }

                                let isBloqueadoAutomatico =
                                    pedidoSel != null &&
                                    pedidoSel.isAutomatico &&
                                    formatDate_noTime_parameter(pedidoSel.fecha) !==
                                        formatDate_noTime_parameter(new Date());

                                let allowUpdating =
                                    !isCerradoFactEntidad &&
                                    (pedidoSel == null || (pedidoSel != null && !pedidoSel.isCerrado)) &&
                                    !isBloqueadoAutomatico;

                                $.each(grids, function (index, item) {
                                    let grid = $(item).dxDataGrid("instance");
                                    if (grid.getDataSource().items().length == 0) {
                                        grid.option("noDataText", getTrad("alerta_pedidoSinPrendas"));
                                    } else {
                                        grid.option("noDataText", getTrad("sinDatos"));
                                    }
                                    grid.option(
                                        "editing.allowDeleting",
                                        !isCerradoFactEntidad &&
                                            pedidoSel &&
                                            !pedidoSel.isCerrado &&
                                            item == "#dxDataGrid_NuevoPedido"
                                    );

                                    grid.option("editing.allowUpdating", allowUpdating);
                                    if (guardando) {
                                        guardando = false;
                                        grid.cancelEditData();
                                    }

                                    $(item).dxDataGrid(
                                        "columnOption",
                                        "cantidad",
                                        "cssClass",
                                        allowUpdating ? "dx-Cell_Editable" : ""
                                    );
                                    $(item).dxDataGrid(
                                        "columnOption",
                                        "stockActual",
                                        "cssClass",
                                        allowUpdating ? "dx-Cell_Editable" : ""
                                    );

                                    if (get_isMovil(COMPONENT.props.resolucion)) {
                                        $("#popup_pedidosAnteriores").dxPopup("instance").hide();
                                        if (typeof $("#dxAccordion_opcionesPedido").data("dxAccordion") !== "undefined")
                                            $("#dxAccordion_opcionesPedido").dxAccordion("instance").expandItem(0);
                                    }
                                });

                                dxResponsiveBox_filtros.endUpdate();

                                if (dxDataGrid_NuevoPedido) dxDataGrid_NuevoPedido.repaint();
                                if (dxDataGrid_NuevoPedido_almacen) dxDataGrid_NuevoPedido_almacen.repaint();

                                if (typeof $("#taObservaciones").data("dxTextArea") !== "undefined")
                                    $("#taObservaciones")
                                        .dxTextArea("instance")
                                        .option("value", pedidoSel.observaciones);
                                $("#dxSelectBox_tipoPedido")
                                    .dxSelectBox("instance")
                                    .option("value", pedidoSel.idTipoPedido);
                                $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option("value", pedidoSel.fecha);

                                if (
                                    isCerradoFactEntidad ||
                                    (!hayPrendas_Stock && !hayPrendas_Peticion) ||
                                    pedidoSel.isCerrado
                                ) {
                                    if (typeof $("#taObservaciones").data("dxTextArea") !== "undefined")
                                        $("#taObservaciones").dxTextArea("instance").option("disabled", true);
                                    $("#dxSelectBox_tipoPedido").dxSelectBox("instance").option("disabled", true);
                                    $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option("disabled", true);
                                }

                                $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel("instance").hide();
                                e.component.option("disabled", false);
                                dxToolbar_filtros_nuevoPed.option("disabled", false);
                            })
                            .fail(() => {
                                $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel("instance").hide();
                                e.component.option("disabled", false);
                                dxToolbar_filtros_nuevoPed.option("disabled", false);
                            });
                    }
                } else set_nuevoPedido();
            },
            onToolbarPreparing: function (e) {
                e.toolbarOptions.items.unshift(
                    {
                        location: "before",
                        template: function () {
                            return $(
                                "<div id='gridAnteriores_title' class ='font-size' style='margin-top : 10px;display : " +
                                    (get_isMovil(COMPONENT.props.resolucion) ? "none" : "block") +
                                    " '>"
                            ).text(getTrad("pedidosAnteriores"));
                        },
                    },
                    {
                        location: "after",
                        widget: "dxButton",
                        showText: "inMenu",
                        locateInMenu: "auto",
                        options: {
                            text: getTrad("limpiarFiltro"),
                            icon: " icon_EliminarFiltros",
                            onClick: function () {
                                e.component.clearFilter();
                                if (typeof $("#dxSelectBox_filtroEntidad_pedidosAnteriores") !== "undefined")
                                    $("#dxSelectBox_filtroEntidad_pedidosAnteriores")
                                        .dxSelectBox("instance")
                                        .option("value", null);
                                notify({
                                    message: getTrad("aviso_C_FiltroRestaurado"),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                });
                            },
                        },
                    },
                    {
                        location: "after",
                        widget: "dxButton",
                        showText: "inMenu",
                        locateInMenu: "auto",
                        options: {
                            icon: "refresh",
                            text: getTrad("actualizar"),
                            onClick: function () {
                                datasource_pedidosAnteriores_Cierres.reload().done(function () {
                                    e.component.beginUpdate();
                                    let selectedRowKeys = e.component.option("selectedRowKeys");
                                    e.component.clearSelection();
                                    e.component.option("selectedRowKeys", selectedRowKeys);
                                    e.component.endUpdate();
                                });
                            },
                        },
                    }
                );
            },
            //Estilos
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: true,
        });

        let grid_nuevoPedido = $("<div id='dxDataGrid_NuevoPedido' />").dxDataGrid({
            dataSource: datasource_pedidos,
            remoteOperations: true,
            //Propiedades
            columnsAutoWidth: true,
            editing: {
                mode: "batch",
                allowUpdating: true,
                allowDeleting: pedidoSel,
                useIcons: true,
            },
            height: "100%",
            width: "100%",
            paging: {
                enabled: false,
            },
            grouping: {
                autoExpandAll: true,
                allowCollapsing: false,
            },
            groupPanel: {
                visible: false,
            },
            scrolling: { mode: "standard" },
            keyboardNavigation: {
                enterKeyAction: "moveFocus",
                enterKeyDirection: "row",
                editOnKeyPress: true,
            },
            loadPanel: {
                enabled: false,
            },
            columns: [
                {
                    dataField: "idEntidad",
                    width: 0,
                    groupCellTemplate: function (cellElement, cellInfo) {
                        if (cellInfo.data.items.length > 0) {
                            let isEntidad = cellInfo.data.items[0].idEntidad != null ? 1 : 0;
                            switch (isEntidad) {
                                case 0:
                                    cellElement.text(getTrad("prendasCompañia"));
                                    break;
                                case 1:
                                    cellElement.text(getTrad("prendasEnti"));
                                    break;
                                default:
                                    break;
                            }
                        }
                    },
                    visible: false,
                    groupIndex: 0,
                },
                {
                    dataField: "idPrenda",
                    visible: false,
                    width: 0,
                },
                {
                    dataField: pedidoSel.idPedido != null ? "idPrendaNavigation.codigoPrenda" : "codigoPrenda",
                    caption: getTrad("codigo"),
                    width: 95,
                    alignment: "center",
                    allowEditing: false,
                    sortOrder: "asc",
                    allowSorting: false,
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 0 : null,
                },
                {
                    dataField: "denoPrenda",
                    caption: getTrad("denominacion"),
                    minWidth: 120,
                    allowSorting: false,
                    allowEditing: false,
                },
                {
                    dataField: "colorTapa",
                    caption: getTrad("colorTapa"),
                    width: 80,
                    alignment: "center",
                    allowEditing: false,
                    allowSorting: false,
                    cellTemplate: function (element, options) {
                        return get_template_colorTapa(element, options.data);
                    },
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 1 : null,
                },
                {
                    dataField: "codigoElemTransPedido",
                    caption: get_isMovil(COMPONENT.props.resolucion) ? "Elem." : getTrad("elementoTransporte_abr"),
                    width: 50,
                    allowEditing: false,
                    allowHeaderFiltering: false,
                    allowFiltering: false,
                    alignment: "center",
                    cssClass: "p-0",
                    allowSorting: false,
                    cellTemplate: function (container, options) {
                        var contenedor = $("<div />").addClass("container_spanCentrado");

                        var svg;
                        var htmlTooltip = "";
                        if (parseInt(options.data.codigoElemTransPedido) === 1) {
                            // POR UDS
                            svg = svg_elemTrans_ud.clone();
                            htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
                        } else if (parseInt(options.data.codigoElemTransPedido) === 2) {
                            // BAC/SACA
                            svg = svg_elemTrans_bac.clone();
                            htmlTooltip = "<p>" + getTrad("bacSaca") + ": " + options.data.udsXBacPedido + "</p>";
                        }

                        svg.css("height", "1.2rem").css("width", "25px").css("cursor", "pointer").appendTo(contenedor);

                        tooltipControl_creacion(contenedor, htmlTooltip);

                        return contenedor;
                    },
                },
                {
                    dataField: "cantidad",
                    caption: "Pedido",
                    width: "10.5%",
                    minWidth: 75,
                    alignment: "center",
                    dataType: "number",
                    cssClass: "dx-Cell_Editable",
                    allowHeaderFiltering: false,
                    allowFiltering: false,
                    allowSorting: false,
                    format: { style: "decimal", maximumFractionDigits: 0 },
                    editorOptions: {
                        step: 0,
                        format: { style: "decimal", maximumFractionDigits: 0 },
                        min: 0,
                        max: 99999999,
                    },
                },
                {
                    dataField: "stockDefinido",
                    visible: false,
                },
                {
                    dataField: "peticion",
                    caption: !get_isMovil(COMPONENT.props.resolucion) ? getTrad("total") : "Total",
                    width: !get_isMovil(COMPONENT.props.resolucion) ? 55 : "14%",
                    alignment: "center",
                    allowEditing: false,
                    allowHeaderFiltering: false,
                    allowSorting: false,
                    allowFiltering: false,
                    format: { style: "decimal", maximumFractionDigits: 0 },
                    calculateDisplayValue: function (e) {
                        var cantidad = typeof (e.cantidad !== "undefined") ? e.cantidad : 0;
                        let resultado = cantidad * e.udsXBacPedido;
                        return resultado > 0 ? resultado : 0;
                    },
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 2 : null,
                },
            ],
            //Eventos
            onInitialized: function (e) {
                e.component.columnOption("command:edit", "width", 35);
            },
            onContentReady: function (e) {
                e.component.option("groupPanel.visible", false);
                e.component.columnOption("command:expand", "width", 1);
            },
            onEditingStart: function (e) {
                let is_adaptiveVisible = false; // checkea si columna adaptive (3 puntitos) es visible o no
                $.each(e.component.getVisibleColumns(), function (index, item) {
                    if (item.type === "adaptive")
                        is_adaptiveVisible = item.visibleWidth !== null && item.visibleWidth > 0;
                });

                if (is_adaptiveVisible) {
                    setTimeout(() => {
                        e.component.collapseAdaptiveDetailRow();
                        e.component.expandAdaptiveDetailRow(e.data.idPrenda);
                    }, 0);
                }
            },
            onRowUpdating: function (cellInfo) {
                cellInfo.newData.peticion = cellInfo.newData.cantidad * cellInfo.oldData.udsXBacPedido;

                delete cellInfo.newData.cantidad;
                cellInfo.newData = patchRequestHandler(cellInfo.newData);
            },
            onAdaptiveDetailRowPreparing: function (e) {
                e.formOptions.labelLocation = "top";
                e.formOptions.colCountByScreen = {
                    xs: 4,
                };

                $.each(e.formOptions.items, function (index, item) {
                    item.label = { alignment: "center" };
                    item.cssClass = "text-center";
                });
            },
            onToolbarPreparing: function (e) {
                e.toolbarOptions.visible = false;
                //Modificar los iconos por defecto de guardar y cancelar del batchEdti
                $.each(e.toolbarOptions.items, function (index, item) {
                    if (item.name === "saveButton") {
                        item.visible = false;
                    }
                });
            },
            //Estilos
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: true,
        });

        let grid_nuevoPedido_almacen = $("<div id='dxDataGrid_NuevoPedido_almacen' />").dxDataGrid({
            dataSource: datasource_pedidos_stock,
            remoteOperations: true,
            //Propiedades
            columnsAutoWidth: true,
            editing: {
                mode: "batch",
                allowUpdating: true,
                allowDeleting: false,
                useIcons: true,
            },
            height: "100%",
            width: "100%",
            paging: {
                enabled: false,
            },
            grouping: {
                autoExpandAll: true,
                allowCollapsing: false,
            },
            groupPanel: {
                visible: false,
            },
            scrolling: { mode: "standard" },
            keyboardNavigation: {
                enterKeyAction: "moveFocus",
                enterKeyDirection: "row",
                editOnKeyPress: true,
            },
            loadPanel: {
                enabled: false,
            },
            columns: [
                {
                    dataField: "idEntidad",
                    width: 0,
                    groupCellTemplate: function (cellElement, cellInfo) {
                        if (cellInfo.data.items.length > 0) {
                            let isEntidad = cellInfo.data.items[0].idEntidad != null ? 1 : 0;
                            switch (isEntidad) {
                                case 0:
                                    cellElement.text(getTrad("prendasCompañia"));
                                    break;
                                case 1:
                                    cellElement.text(getTrad("prendasEnti"));
                                    break;
                                default:
                                    break;
                            }
                        }
                    },
                    visible: false,
                    groupIndex: 0,
                },
                {
                    dataField: "idPrenda",
                    visible: false,
                    width: 0,
                },
                {
                    dataField: pedidoSel.idPedido != null ? "idPrendaNavigation.codigoPrenda" : "codigoPrenda",
                    caption: getTrad("codigo"),
                    width: 95,
                    alignment: "center",
                    allowEditing: false,
                    sortOrder: "asc",
                    allowSorting: false,
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 0 : null,
                },
                {
                    dataField: "denoPrenda",
                    caption: getTrad("denominacion"),
                    minWidth: 120,
                    allowSorting: false,
                    allowEditing: false,
                },
                {
                    dataField: "colorTapa",
                    caption: getTrad("colorTapa"),
                    width: 80,
                    alignment: "center",
                    allowEditing: false,
                    allowSorting: false,
                    cellTemplate: function (element, options) {
                        return get_template_colorTapa(element, options.data);
                    },
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 1 : null,
                },
                {
                    dataField: "codigoElemTransPedido",
                    caption: get_isMovil(COMPONENT.props.resolucion) ? "Elem." : getTrad("elementoTransporte_abr"),
                    width: 50,
                    allowEditing: false,
                    allowHeaderFiltering: false,
                    allowFiltering: false,
                    alignment: "center",
                    cssClass: "p-0",
                    allowSorting: false,
                    cellTemplate: function (container, options) {
                        var contenedor = $("<div />").addClass("container_spanCentrado");

                        var svg;
                        var htmlTooltip = "";
                        if (parseInt(options.data.codigoElemTransPedido) === 1) {
                            // POR UDS
                            svg = svg_elemTrans_ud.clone();
                            htmlTooltip = "<p>" + getTrad("porUds") + "</p>";
                        } else if (parseInt(options.data.codigoElemTransPedido) === 2) {
                            // BAC/SACA
                            svg = svg_elemTrans_bac.clone();
                            htmlTooltip = "<p>" + getTrad("bacSaca") + ": " + options.data.udsXBacPedido + "</p>";
                        }

                        svg.css("height", "1.2rem").css("width", "25px").css("cursor", "pointer").appendTo(contenedor);

                        tooltipControl_creacion(contenedor, htmlTooltip);

                        return contenedor;
                    },
                },
                {
                    dataField: "stockActual",
                    caption: !get_isMovil(COMPONENT.props.resolucion) ? getTrad("stockActual") : "Stock",
                    width: "10.5%",
                    minWidth: 75,
                    alignment: "center",
                    dataType: "number",
                    cssClass: "dx-Cell_Editable",
                    allowHeaderFiltering: false,
                    allowFiltering: false,
                    allowSorting: false,
                    showEditorAlways: true,
                    editCellTemplate: function (cellElement, cellInfo) {
                        cellElement.append(
                            $("<div/>")
                                .dxNumberBox({
                                    width: "100%",
                                    value: cellInfo.value,
                                    step: 1,
                                    max: 99999999,
                                    format: { style: "decimal", maximumFractionDigits: 0 },
                                    min: 0,
                                    onValueChanged: function (e) {
                                        if (e.value === 0) {
                                            dxMensajePregunta(
                                                getTrad("avisoStockActual0"),
                                                [[getTrad("aceptar"), function () {}, "danger", ""]],
                                                "dx-icon-warning primary"
                                            );
                                        }

                                        let dxDataGrid_NuevoPedido =
                                            $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
                                        let hasPrendas_dxDataGrid_NuevoPedido =
                                            dxDataGrid_NuevoPedido.getDataSource().items().length > 0;

                                        let numItems = 0;
                                        $.each(datasource_pedidos_stock.items(), function (index, item) {
                                            numItems += item.items.length;
                                        });

                                        if (cellInfo.rowIndex == numItems && hasPrendas_dxDataGrid_NuevoPedido) {
                                            //* Si es la última row pasa automáticamente al siguiente editor
                                            setTimeout(() => {
                                                dxDataGrid_NuevoPedido.editCell(1, "cantidad");
                                            }, 120);
                                        }
                                        cellInfo.setValue(e.value);
                                    },
                                })
                                .dxValidator({
                                    validationGroup: "validationGroup_Stock",
                                    validationRules: [{ type: "required" }],
                                })
                        );
                    },
                },
                {
                    dataField: "stockDefinido",
                    visible: false,
                },
                {
                    caption: !get_isMovil(COMPONENT.props.resolucion) ? getTrad("total") : "Total",
                    width: !get_isMovil(COMPONENT.props.resolucion) ? 55 : "14%",
                    alignment: "center",
                    allowEditing: false,
                    allowHeaderFiltering: false,
                    allowSorting: false,
                    allowFiltering: false,
                    calculateDisplayValue: function (e) {
                        if (pedidoSel.idPedido) {
                            let resultado = e.stockActual * e.udsXBacPedido;
                            return resultado > 0 ? resultado : 0;
                        } else if (entidadSel) {
                            let resultado = e.stockActual * e.udsXBacPedido;
                            return resultado > 0 ? resultado : 0;
                        }
                    },
                    hidingPriority: get_isMovil(COMPONENT.props.resolucion) ? 2 : null,
                },
                {
                    name: "delete",
                    allowSorting: false,
                    visible: hayPrendas_Peticion,
                    width: 35,
                    allowEditing: false,
                },
            ],
            //Eventos
            onInitialized: function (e) {
                e.component.columnOption("command:edit", "width", 35);
                e.component.columnOption("command:expand", "width", 1);
            },
            onContentReady: function (e) {
                e.component.option("groupPanel.visible", false);
                e.component.columnOption("command:expand", "width", 1);
            },
            onEditingStart: function (e) {
                let is_adaptiveVisible = false; // checkea si columna adaptive (3 puntitos) es visible o no
                $.each(e.component.getVisibleColumns(), function (index, item) {
                    if (item.type === "adaptive")
                        is_adaptiveVisible = item.visibleWidth !== null && item.visibleWidth > 0;
                });

                if (is_adaptiveVisible) {
                    setTimeout(() => {
                        e.component.collapseAdaptiveDetailRow();
                        e.component.expandAdaptiveDetailRow(e.data.idPrenda);
                    }, 0);
                }
            },
            onAdaptiveDetailRowPreparing: function (e) {
                e.formOptions.labelLocation = "top";
                e.formOptions.colCountByScreen = {
                    xs: 4,
                };

                $.each(e.formOptions.items, function (index, item) {
                    item.label = { alignment: "center" };
                    item.cssClass = "text-center";
                });
            },
            onRowUpdating: function (cellInfo) {
                cellInfo.newData.peticion = Math.ceil(
                    (cellInfo.oldData.stockDefinido - cellInfo.newData.stockActual) * cellInfo.oldData.udsXBacPedido
                );
                if (cellInfo.newData.peticion < 0) cellInfo.newData.peticion = 0;
                cellInfo.newData.stockActual = cellInfo.newData.stockActual * cellInfo.oldData.udsXBacPedido;

                delete cellInfo.newData.cantidad;
                cellInfo.newData = patchRequestHandler(cellInfo.newData);
            },
            onToolbarPreparing: function (e) {
                e.toolbarOptions.visible = false;
                //Modificar los iconos por defecto de guardar y cancelar del batchEdti
                $.each(e.toolbarOptions.items, function (index, item) {
                    if (item.name === "saveButton") {
                        item.visible = false;
                    }
                });
            },
            //Estilos
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: true,
        });

        $("#dxScrollView_pedidos").dxScrollView({
            showScrollbar: "always",
            direction: "vertical",
            height: "100%",
            width: "100%",
            elementAttr: { class: "formContent" },
        });

        $("#Pedidos #dxContainer").dxResponsiveBox({
            rows: [{ ratio: 1, screen: "xs sm md lg xl" }],
            cols: [
                { ratio: 1, screen: "xs sm md" },
                { ratio: 5, screen: "lg xl" },
                { ratio: 0, baseSize: 30, screen: "lg xl" },
                { ratio: 4, screen: "lg xl" },
            ],
            singleColumnScreen: "xs",
            screenByWidth: function () {
                return COMPONENT.props.resolucion;
            },
            items: [
                {
                    location: [
                        {
                            row: 0,
                            col: 0,
                            colspan: 1,
                            screen: "lg xl",
                        },
                        {
                            row: 0,
                            col: 0,
                            colspan: 2,
                            screen: "xs sm md",
                        },
                    ],
                    template: function (e, index, item) {
                        return $("<div id='dxResponsiveBox_filtros' />").dxResponsiveBox({
                            rows: [
                                { ratio: 0, baseSize: "auto", screen: "xs sm md lg xl" },
                                { ratio: 0, baseSize: "auto", screen: "xs sm md lg xl" },
                                { ratio: 1, screen: "xs sm md lg xl" },
                                { ratio: 0, baseSize: "auto", screen: "xs sm md lg xl" },
                            ],
                            cols: [{ ratio: 1, screen: "xs sm md lg xl" }],
                            screenByWidth: function () {
                                return COMPONENT.props.resolucion;
                            },
                            items: get_dxResponsiveBox_filtros_items(),
                        });
                    },
                },
                {
                    location: [
                        {
                            row: 0,
                            col: 1,
                            colspan: 1,
                            screen: "lg xl",
                        },
                    ],
                },
                {
                    location: [
                        {
                            row: 0,
                            col: 2,
                            colspan: 3,
                            screen: "lg xl",
                        },
                    ],
                    template: function (e, index, item) {
                        item.css("padding-top", "45px");

                        item.append(
                            $("<div />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height: "100%",
                                width: "100%",
                                items: [
                                    {
                                        ratio: 1,
                                        template: function (e, index, item) {
                                            return $("<div id='parent_pedidosAnteriores' />").append(
                                                get_grid_pedidosAnteriores
                                            );
                                        },
                                    },
                                ],
                            })
                        );
                    },
                },
            ],
            onOptionChanged: function (e) {
                if (e.name === "currentScreenFactor" || e.name === "screenByWidth") {
                    // currentScreenFactor se ejecuta al entrar al formulario y al cambiar de resolucion || screenByWidth se ejecuta al cambiar de lavanderia
                    let icon = {
                        btn_guardarPedido: !pedidoSel.idPedido ? null : "save",
                        btn_nuevoPedido: null,
                        btn_addPrenda: null,
                    };

                    if (!get_isMovil(COMPONENT.props.resolucion)) {
                        icon.btn_guardarPedido = !pedidoSel.idPedido ? "check" : "save";
                        icon.btn_nuevoPedido = "plus";
                        icon.btn_addPrenda = "plus";

                        if (typeof $("#popup_pedidosAnteriores").data("dxPopup") !== "undefined")
                            $("#popup_pedidosAnteriores").dxPopup("instance").hide();
                    }

                    if (typeof $("#dxDataGrid_NuevoPedido").data("dxDataGrid") !== "undefined") {
                        $("#btnGuardarPedido").dxButton("instance").option("icon", icon.btn_guardarPedido);
                        $("#btnNuevoPedido").dxButton("instance").option("icon", icon.btn_nuevoPedido);
                        $("#btnAddPrenda").dxButton("instance").option("icon", icon.btn_addPrenda);
                    }

                    if (typeof $("#dxResponsiveBox_filtros").data("dxResponsiveBox") !== "undefined")
                        $("#dxResponsiveBox_filtros")
                            .dxResponsiveBox("instance")
                            .option("items", get_dxResponsiveBox_filtros_items());

                    set_opcionesWidgets_resize();

                    //#region Pedidos anteriores - Mover grid de posición
                    // En resolución movil solo encuentra el grid con setTimeout, con res. pc solo encuentra grid sin set timeout.
                    let dxDatagrid_pedidosAnteriores;
                    if ($("#dxDatagrid_pedidosAnteriores").length > 0)
                        dxDatagrid_pedidosAnteriores = $("#dxDatagrid_pedidosAnteriores");

                    setTimeout(() => {
                        if ($("#dxDatagrid_pedidosAnteriores").length > 0)
                            dxDatagrid_pedidosAnteriores = $("#dxDatagrid_pedidosAnteriores");

                        if (get_isMovil(COMPONENT.props.resolucion))
                            dxDatagrid_pedidosAnteriores.insertAfter("#parentPopup_pedidosAnteriores");
                        else dxDatagrid_pedidosAnteriores.insertAfter("#parent_pedidosAnteriores");
                        dxDatagrid_pedidosAnteriores.dxDataGrid("instance").updateDimensions();

                        //#region Limpiar filtros del grid
                        if (
                            e.previousValue === "lg" ||
                            e.value === "lg" ||
                            e.previousValue === "xl" ||
                            e.value === "xl"
                        ) {
                            // cambio resolucion pc a movil o movil pc
                            if ($("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance") !== undefined)
                                $("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance").clearFilter();

                            if (
                                typeof $("#dxSelectBox_filtroEntidad_pedidosAnteriores").data("dxSelectBox") !==
                                "undefined"
                            )
                                $("#dxSelectBox_filtroEntidad_pedidosAnteriores").dxSelectBox("instance").reset();
                        }
                        //#endregion
                    }, 0);
                    //#endregion
                }
            },
        });

        $("#Pedidos #popup_alert_AceptarCancelar").dxPopup({
            showTitle: false,
            width: 350,
            height: "auto",
            minHeight: 150,
            onContentReady: function (container) {
                var html = container.component.content();
                $(html).css("padding-top", "5");
            },
            contentTemplate: function () {
                return $(
                    "<h4>" +
                        getTrad("alerta") +
                        "</h4><div style='font-size: 14px;'><p>" +
                        getTrad("preg_PerderCambios") +
                        "</p></div>"
                );
            },
            toolbarItems: [
                {
                    toolbar: "bottom",
                    location: "after",
                    template: function (item) {
                        var container = $("<div></div>");

                        $("<div id='popup_dxButton_aceptar'>")
                            .dxButton({
                                text: getTrad("aceptar"),
                                type: "danger",
                                elementAttr: {
                                    id: "btnAceptar",
                                },
                            })
                            .appendTo(container);

                        $("<div>")
                            .dxTooltip({
                                target: "#popup_dxButton_aceptar",
                                height: "42px",
                                showEvent: "dxhoverstart",
                                hideEvent: "dxhoverend",
                                position: "bottom",
                                contentTemplate: function (contentElement) {
                                    contentElement.html("<p>Se descartarán los cambios.</p>");
                                },
                                animation: {
                                    show: {
                                        type: "pop",
                                        from: {
                                            scale: 0.1,
                                            opacity: 0,
                                        },
                                        to: {
                                            opacity: 1,
                                            scale: 1,
                                        },
                                    },
                                    hide: {
                                        type: "pop",
                                        from: {
                                            scale: 1,
                                            opacity: 1,
                                        },
                                        to: {
                                            opacity: 0,
                                            scale: 0.1,
                                        },
                                    },
                                },
                            })
                            .appendTo(container);

                        return container;
                    },
                },
                {
                    toolbar: "bottom",
                    location: "after",
                    template: function (item) {
                        var container = $("<div></div>");

                        $("<div id='popup_dxButton_cancelar'>")
                            .dxButton({
                                text: getTrad("cancelar"),
                                elementAttr: {
                                    id: "btnCancelar_alert",
                                },
                                onClick: function (e) {
                                    $("#popup_alert_AceptarCancelar").dxPopup("instance").hide();
                                },
                            })
                            .appendTo(container);

                        return container;
                    },
                },
            ],
        });

        $("#Pedidos #popup_añadirPrendas").dxPopup({
            showTitle: true,
            title: getTrad("añadirPrenda"),
            height: "80%",
            width: "95%",
            maxHeight: 750,
            maxWidth: 700,
            showCloseButton: true,
            contentTemplate: function () {
                return $("<div id='dxList_añadirPrendas' />").dxList({
                    dataSource: datasource_añadirPrenda,
                    selectionMode: "multiple",
                    showSelectionControls: false,
                    itemTemplate: function (data, index, item) {
                        var contenedor = $("<div>").css("display", "flex").css("margin", "0px 15px");
                        contenedor.append($("<div>").text(data.codigoPrenda).css("flex-basis", 80));
                        contenedor.append($("<div>").text(data.denoPrenda).css("flex", 1));

                        var container = $("<div />")
                            .addClass("container_spanCentrado")
                            .css("padding-left", "10px")
                            .css("flex-basis", 100);
                        var svg;
                        var htmlTooltip = "";
                        if (parseInt(data.codigoElemTransPedido) === 1) {
                            // POR UDS
                            svg = svg_elemTrans_ud.clone();
                            htmlTooltip = "<p>" + data.denoElemTransPedido + "</p>";
                        } else if (parseInt(data.codigoElemTransPedido) === 2) {
                            // BAC/SACA
                            svg = svg_elemTrans_bac.clone();
                            htmlTooltip = "<p>" + data.denoElemTransPedido + ": " + data.udsXBacPedido + "</p>";
                        }

                        svg.css("height", "20px").css("width", "25px").css("cursor", "pointer").appendTo(container);

                        tooltipControl_creacion(container, htmlTooltip);

                        contenedor.append(container);

                        let colorTapa = get_template_colorTapa(item, data);
                        colorTapa.appendTo(contenedor);

                        return contenedor;
                    },
                });
            },
            toolbarItems: [
                {
                    toolbar: "bottom",
                    location: "after",
                    widget: "dxButton",
                    options: {
                        text: getTrad("añadir"),
                        type: "success",
                        onClick: function (e) {
                            //Añadir prenda a las prendas N reparto
                            var prendasSeleccionadas = $("#dxList_añadirPrendas")
                                .dxList("instance")
                                .option("selectedItems");

                            if (prendasSeleccionadas.length > 0) {
                                $.each(prendasSeleccionadas, function (index, item) {
                                    var isLastElement = index === prendasSeleccionadas.length - 1;
                                    let isPedidoPorPeticion = item.stockDefinido == null || item.stockDefinido == 0;

                                    var prendaNPedido = {};
                                    prendaNPedido.idPedido = pedidoSel.idPedido;
                                    prendaNPedido.idPrenda = item.idPrenda;
                                    prendaNPedido.stockActual = !isPedidoPorPeticion ? 0 : null;
                                    prendaNPedido.peticion = isPedidoPorPeticion
                                        ? 0
                                        : Math.ceil((item.stockDefinido + item.cantidad) * item.udsXBacPedido);

                                    let dxLoadPanel_GridsNuevoPedido = $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel(
                                        "instance"
                                    );
                                    dxLoadPanel_GridsNuevoPedido.show();
                                    datasource_prendaPedido
                                        .store()
                                        .insert(prendaNPedido)
                                        .done(function () {
                                            if (isLastElement) {
                                                $.when(
                                                    datasource_pedidos_stock.reload(),
                                                    datasource_pedidos.reload()
                                                ).then(function () {
                                                    let dxDataGrid_NuevoPedido =
                                                        $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
                                                    let dxDataGrid_NuevoPedido_almacen = $(
                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                    ).dxDataGrid("instance");

                                                    $("#dxResponsiveBox_filtros")
                                                        .dxResponsiveBox("instance")
                                                        .option("items", get_dxResponsiveBox_filtros_items());

                                                    if (dxDataGrid_NuevoPedido) dxDataGrid_NuevoPedido.repaint();
                                                    if (dxDataGrid_NuevoPedido_almacen)
                                                        dxDataGrid_NuevoPedido_almacen.repaint();
                                                    dxLoadPanel_GridsNuevoPedido.hide();
                                                });
                                            } else dxLoadPanel_GridsNuevoPedido.hide();
                                        });
                                });
                                $("#popup_añadirPrendas").dxPopup("instance").hide();

                                notify({
                                    message: getTrad("aviso_C_RegistroInsertado"),
                                    type: "success",
                                    displayTime: "600",
                                    closeOnClick: true,
                                });
                            }
                        },
                    },
                },
            ],
        });

        $("#popup_pedidosAnteriores").dxPopup({
            showTitle: true,
            showCloseButton: true,
            deferRendering: false,
            title: getTrad("pedidosAnteriores"),
            height: "80%",
            width: "50%",
            maxHeight: 750,
            maxWidth: 400,
            minWidth: 300,
            contentTemplate: function get_popup_pedidosAnteriores_template() {
                return $("<div id='dxTabPanel_pedidosAnteriores' />")
                    .addClass("no-header")
                    .dxTabPanel({
                        height: "100%",
                        width: "100%",
                        scrollByContent: true,
                        scrollingEnabled: true,
                        animationEnabled: true,
                        deferRendering: false,
                        items: [
                            {
                                template: function () {
                                    return $("<div />").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        height: "100%",
                                        items: [
                                            {
                                                baseSize: 35,
                                                template: function () {
                                                    return $(
                                                        "<div class='font-size' style='margin-left:5px;'>" +
                                                            getTrad("seleccioneEntidad") +
                                                            "</div>"
                                                    );
                                                },
                                            },
                                            {
                                                ratio: 1,
                                                template: function () {
                                                    return $("<div id='dxList_entidades_pedidosAnteriores' />").dxList({
                                                        dataSource: datasource_entidades.items(),
                                                        selectionMode: "single",
                                                        keyExpr: "idEntidad",
                                                        displayExpr: "denominacion",
                                                        height: "100%",
                                                        scrolling: { mode: "infinite" },
                                                        pageLoadMode: "scrollBottom",
                                                        //EVENTOS
                                                        onSelectionChanged: function (e) {
                                                            if (e.addedItems.length > 0) {
                                                                let entidadSel = e.addedItems[0];
                                                                $("#dxSelectBox_filtroEntidad_pedidosAnteriores")
                                                                    .dxSelectBox("instance")
                                                                    .option("value", entidadSel.idEntidad);
                                                                $("#dxTabPanel_pedidosAnteriores")
                                                                    .dxTabPanel("instance")
                                                                    .option("selectedIndex", 1);

                                                                deshabilitarEdicionCierreFact(
                                                                    entidadSel.idEntidad,
                                                                    pedidoSel.fecha,
                                                                    pedidoSel.fecha
                                                                );
                                                            }
                                                        },
                                                    });
                                                },
                                            },
                                        ],
                                    });
                                },
                            },
                            {
                                template: function () {
                                    return $("<div />").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        height: "100%",
                                        items: [
                                            {
                                                baseSize: 50,
                                                template: function () {
                                                    return $("<div />").dxSelectBox({
                                                        elementAttr: {
                                                            id: "dxSelectBox_filtroEntidad_pedidosAnteriores",
                                                        },
                                                        dataSource: datasource_entidades,
                                                        valueExpr: "idEntidad",
                                                        displayExpr: "denominacion",
                                                        onValueChanged: function (e) {
                                                            let dxDatagrid_pedidosAnteriores = $(
                                                                "#dxDatagrid_pedidosAnteriores"
                                                            ).dxDataGrid("instance");
                                                            if (e.value !== null) {
                                                                dxDatagrid_pedidosAnteriores.columnOption(
                                                                    "denoEntidad",
                                                                    "filterValues",
                                                                    [e.component.option("displayValue")]
                                                                );
                                                                dxDatagrid_pedidosAnteriores.filter(
                                                                    "idEntidad",
                                                                    "=",
                                                                    e.value
                                                                );
                                                            } else dxDatagrid_pedidosAnteriores.clearFilter();
                                                        },
                                                        width: "100%",
                                                    });
                                                },
                                            },
                                            {
                                                ratio: 1,
                                                template: function () {
                                                    return $("<div id='parentPopup_pedidosAnteriores' />").append(
                                                        get_grid_pedidosAnteriores
                                                    );
                                                },
                                            },
                                        ],
                                    });
                                },
                            },
                        ],
                    });
            },
        });

        function set_opcionesWidgets_resize() {
            //#region Grid pedidos anteriores
            let dxDatagrid_pedidosAnteriores = $("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance");
            if (dxDatagrid_pedidosAnteriores !== undefined) {
                dxDatagrid_pedidosAnteriores.option({
                    scrolling: {
                        mode: get_isMovil(COMPONENT.props.resolucion) ? "infinite" : "standard",
                    },
                    pager: {
                        visible: !get_isMovil(COMPONENT.props.resolucion),
                    },
                });
                dxDatagrid_pedidosAnteriores.columnOption(
                    "denoEntidad",
                    "visible",
                    !get_isMovil(COMPONENT.props.resolucion)
                );
                dxDatagrid_pedidosAnteriores.columnOption(
                    "codigo",
                    "caption",
                    !get_isMovil(COMPONENT.props.resolucion) ? getTrad("codigo") : getTrad("codigo_abr")
                );
                dxDatagrid_pedidosAnteriores.columnOption(
                    "fecha",
                    "width",
                    get_isMovil(COMPONENT.props.resolucion) ? 120 : 145
                );

                $("#gridAnteriores_title").css("display", get_isMovil(COMPONENT.props.resolucion) ? "none" : "block");
                $("#popup_pedidosAnteriores").dxPopup("instance").repaint();

                let entidadSel = $("#dxSelectBox_filtroEntidad_pedidosAnteriores")
                    .dxSelectBox("instance")
                    .option("value");
                if (entidadSel === null)
                    $("#dxTabPanel_pedidosAnteriores").dxTabPanel("instance").option("selectedIndex", 0);
                $("#dxList_entidades_pedidosAnteriores").dxList("instance").unselectAll();
                //#endregion

                //#region Grid nuevo pedido
                if (typeof $("#dxAccordion_opcionesPedido").data("dxAccordion") !== "undefined")
                    $("#dxAccordion_opcionesPedido")
                        .dxAccordion("instance")
                        .option("direction", COMPONENT.props.resolucion === "xs" ? "row" : "col");

                let dxDataGrid_NuevoPedido = $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
                let dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance");

                $.each(["#dxDataGrid_NuevoPedido", "#dxDataGrid_NuevoPedido_almacen"], function (index, item) {
                    if ($(item).data("dxDataGrid")) {
                        let grid =
                            item == "#dxDataGrid_NuevoPedido" ? dxDataGrid_NuevoPedido : dxDataGrid_NuevoPedido_almacen;
                        grid.repaint();
                        grid.updateDimensions();

                        grid.columnOption(
                            "codigoPrenda",
                            "hidingPriority",
                            get_isMovil(COMPONENT.props.resolucion) ? 0 : null
                        );
                        grid.columnOption(
                            "colorTapa",
                            "hidingPriority",
                            get_isMovil(COMPONENT.props.resolucion) ? 1 : null
                        );
                        grid.columnOption(
                            "peticion",
                            "hidingPriority",
                            get_isMovil(COMPONENT.props.resolucion) ? 2 : null
                        );
                    }
                });
                //#endregion

                if (typeof $("#dxAccordion_opcionesPedido").data("dxAccordion") !== "undefined")
                    $("#dxAccordion_opcionesPedido").dxAccordion("instance").updateDimensions();
            }
        }

        function get_dxResponsiveBox_filtros_items() {
            let prendasPeticion = $.map(datasource_pedidos.items(), function (item) {
                return item.items;
            });
            let prendasStock = $.map(datasource_pedidos_stock.items(), function (item) {
                return item.items;
            });
            let isDosGrids = prendasPeticion.length > 0 && prendasStock.length > 0;

            let heightGrid = isDosGrids ? "he-auto" : "he-100";
            if (
                pedidoSel.idPedido != null &&
                (pedidoSel.tblPrendaNPedido.length == 0 || (!hayPrendas_Peticion && !hayPrendas_Stock))
            )
                heightGrid = "he-100";

            let grid_nuevoPedido_padre = $("<div id='parent_grid_nuevoPedido' class='" + heightGrid + "' />").append(
                grid_nuevoPedido
            );
            let grid_nuevoPedido_almacen_padre = $(
                "<div id='parent_grid_nuevoPedido_almacen' class='" + heightGrid + "' />"
            ).append(grid_nuevoPedido_almacen);

            return [
                {
                    location: [
                        {
                            row: 0,
                            col: 0,
                            screen: "xs sm md lg xl",
                        },
                    ],
                    template: function (e, index, item) {
                        let dxForm_dxDataGrid_NuevoPedido = $("<div id='dxForm_dxDataGrid_NuevoPedido' />").dxForm({
                            colCountByScreen: {
                                xl: 12,
                                lg: 12,
                                md: 9,
                                sm: 9,
                                xs: 1,
                            },
                            width: "100%",
                            items: [
                                {
                                    colSpan: get_isMovil(COMPONENT.props.resolucion) ? 9 : 6,
                                    label: {
                                        visible: get_isMovil(COMPONENT.props.resolucion),
                                        text: getTrad("entidad"),
                                        location: "top",
                                    },
                                    template: function () {
                                        var container = $("<div>");
                                        var dxDropDownBox = $(
                                            "<div id='dxDropDownBox_selCompaEnti' style='float:left'>"
                                        ).dxDropDownBox({
                                            dataSource: datasource_entidades.items(),
                                            valueExpr: "idEntidad",
                                            displayExpr: "denominacion",
                                            width: "100%",
                                            value: pedidoSel.idEntidad
                                                ? pedidoSel.idEntidad
                                                : datasource_entidades.items().length === 1
                                                ? datasource_entidades.items()[0].idEntidad
                                                : null,
                                            disabled: pedidoSel.idPedido
                                                ? true
                                                : datasource_entidades.items().length === 1
                                                ? true
                                                : false,
                                            deferRendering: false,
                                            remoteOperations: true,
                                            placeholder: getTrad("entidades"),
                                            // EVENTS
                                            onOpened: function (e) {
                                                if (nuevoPedido_HasChanges()) {
                                                    e.component.close();

                                                    $("#popup_alert_AceptarCancelar").dxPopup("instance").show();
                                                    $("#btnAceptar")
                                                        .dxButton("instance")
                                                        .option("onClick", function () {
                                                            let grids = [
                                                                "#dxDataGrid_NuevoPedido",
                                                                "#dxDataGrid_NuevoPedido_almacen",
                                                            ];
                                                            $.each(grids, function (index, item) {
                                                                let grid = $(item).dxDataGrid("instance");
                                                                if (grid) {
                                                                    grid.option(
                                                                        "editing.allowDeleting",
                                                                        pedidoSel && item == "#dxDataGrid_NuevoPedido"
                                                                    );
                                                                    grid.cancelEditData();
                                                                    grid.refresh();
                                                                    grid.repaint();
                                                                }
                                                            });

                                                            e.component.open();
                                                            $("#popup_alert_AceptarCancelar")
                                                                .dxPopup("instance")
                                                                .hide();
                                                        });
                                                }
                                            },
                                            onClosed: function (e) {
                                                if ($("#dxDropDownBox_lista_entidades").data("dxList"))
                                                    $("#dxDropDownBox_lista_entidades")
                                                        .dxList("instance")
                                                        .option("searchValue", "");
                                                if ($("#dxDropDownBox_lista_compañias").data("dxList"))
                                                    $("#dxDropDownBox_lista_compañias")
                                                        .dxList("instance")
                                                        .option("searchValue", "");
                                            },
                                            dropDownOptions: {
                                                maxWidth: 800,
                                                width: "95%",
                                                height: 500,
                                                position: { my: "center", at: "center", of: window },
                                                closeOnOutsideClick: true,
                                            },
                                            contentTemplate: function (dxDropDownBox) {
                                                if (get_isMovil(COMPONENT.props.resolucion)) {
                                                    return $("<div id='dxTabPanel_compaEnti_nuevoPedido' />")
                                                        .addClass("no-header")
                                                        .dxTabPanel({
                                                            height: "100%",
                                                            width: "100%",
                                                            scrollByContent: true,
                                                            scrollingEnabled: true,
                                                            animationEnabled: true,
                                                            deferRendering: false,
                                                            items: [
                                                                {
                                                                    template: function () {
                                                                        return $("<div />").dxBox({
                                                                            direction: "col",
                                                                            align: "space-around",
                                                                            crossAlign: "stretch",
                                                                            height: "100%",
                                                                            items: [
                                                                                {
                                                                                    baseSize: 35,
                                                                                    template: function () {
                                                                                        return $(
                                                                                            "<div class='font-size' style='margin-left:5px;'>" +
                                                                                                getTrad(
                                                                                                    "seleccioneCompañia"
                                                                                                ) +
                                                                                                "</div>"
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    ratio: 1,
                                                                                    template: function () {
                                                                                        return $(
                                                                                            "<div id='dxList_compañias_nuevoPedido' />"
                                                                                        ).dxList({
                                                                                            dataSource:
                                                                                                datasource_compañias.items(),
                                                                                            selectionMode: "single",
                                                                                            keyExpr: "idCompañia",
                                                                                            displayExpr: "denominacion",
                                                                                            height: "100%",
                                                                                            scrolling: {
                                                                                                mode: "infinite",
                                                                                            },
                                                                                            pageLoadMode:
                                                                                                "scrollBottom",
                                                                                            //EVENTOS
                                                                                            onSelectionChanged:
                                                                                                function (e) {
                                                                                                    if (
                                                                                                        e.addedItems
                                                                                                            .length > 0
                                                                                                    ) {
                                                                                                        let idCompaSel =
                                                                                                            e
                                                                                                                .addedItems[0]
                                                                                                                .idCompañia;
                                                                                                        let entidadesFiltradas =
                                                                                                            $.grep(
                                                                                                                datasource_entidades.items(),
                                                                                                                function (
                                                                                                                    item
                                                                                                                ) {
                                                                                                                    return (
                                                                                                                        (item.idCompañia ===
                                                                                                                            idCompaSel ||
                                                                                                                            idCompaSel ===
                                                                                                                                0) &&
                                                                                                                        item.idEntidad !==
                                                                                                                            pedidoSel.idEntidad
                                                                                                                    );
                                                                                                                }
                                                                                                            );
                                                                                                        $(
                                                                                                            "#dxList_entidades_nuevoPedido"
                                                                                                        )
                                                                                                            .dxList(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .option(
                                                                                                                "dataSource",
                                                                                                                entidadesFiltradas
                                                                                                            );

                                                                                                        $(
                                                                                                            "#dxTabPanel_compaEnti_nuevoPedido"
                                                                                                        )
                                                                                                            .dxTabPanel(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .option(
                                                                                                                "selectedIndex",
                                                                                                                1
                                                                                                            );
                                                                                                    }
                                                                                                },
                                                                                        });
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    },
                                                                },
                                                                {
                                                                    template: function () {
                                                                        return $("<div />").dxBox({
                                                                            direction: "col",
                                                                            align: "space-around",
                                                                            crossAlign: "stretch",
                                                                            height: "100%",
                                                                            items: [
                                                                                {
                                                                                    baseSize: 30,
                                                                                    template: function () {
                                                                                        return $("<div />")
                                                                                            .addClass(
                                                                                                "d-flex align-items-center"
                                                                                            )
                                                                                            .append(
                                                                                                $(
                                                                                                    "<div id='dxButton_atras_selEntidad' style='flex: 0 0 30px; font-size:10px' />"
                                                                                                ).dxButton({
                                                                                                    height: 30,
                                                                                                    width: 30,
                                                                                                    type: "normal",
                                                                                                    icon: "chevronleft",
                                                                                                    onClick: function (
                                                                                                        e
                                                                                                    ) {
                                                                                                        $(
                                                                                                            "#dxList_compañias_nuevoPedido"
                                                                                                        )
                                                                                                            .dxList(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .unselectAll();
                                                                                                        $(
                                                                                                            "#dxTabPanel_compaEnti_nuevoPedido"
                                                                                                        )
                                                                                                            .dxTabPanel(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .option(
                                                                                                                "selectedIndex",
                                                                                                                0
                                                                                                            );
                                                                                                    },
                                                                                                }),
                                                                                                $(
                                                                                                    "<div class='font-size pb-1' style='margin-left:10px; flex:1;'>" +
                                                                                                        getTrad(
                                                                                                            "seleccioneEntidad"
                                                                                                        ) +
                                                                                                        "</div>"
                                                                                                )
                                                                                            );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    ratio: 1,
                                                                                    template: function () {
                                                                                        let dxList_entidades_nuevoPedido =
                                                                                            $(
                                                                                                "<div id='dxList_entidades_nuevoPedido' />"
                                                                                            ).dxList({
                                                                                                selectionMode: "single",
                                                                                                keyExpr: "idEntidad",
                                                                                                displayExpr:
                                                                                                    "denominacion",
                                                                                                height: "100%",
                                                                                                scrolling: {
                                                                                                    mode: "infinite",
                                                                                                },
                                                                                                pageLoadMode:
                                                                                                    "scrollBottom",
                                                                                                //EVENTOS
                                                                                                onSelectionChanged:
                                                                                                    function (e) {
                                                                                                        if (
                                                                                                            e.addedItems
                                                                                                                .length >
                                                                                                            0
                                                                                                        ) {
                                                                                                            if (
                                                                                                                typeof e
                                                                                                                    .addedItems[0] !==
                                                                                                                "undefined"
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    dxDropDownBox.component.option(
                                                                                                                        "value"
                                                                                                                    ) !==
                                                                                                                    e
                                                                                                                        .addedItems[0]
                                                                                                                        .idEntidad
                                                                                                                ) {
                                                                                                                    dxDropDownBox.component.option(
                                                                                                                        "value",
                                                                                                                        e
                                                                                                                            .addedItems[0]
                                                                                                                            .idEntidad
                                                                                                                    );

                                                                                                                    entidadSel =
                                                                                                                        e
                                                                                                                            .addedItems[0];
                                                                                                                    let fecha =
                                                                                                                        pedidoSel &&
                                                                                                                        pedidoSel.fecha
                                                                                                                            ? pedidoSel.fecha
                                                                                                                            : new Date();
                                                                                                                    $(
                                                                                                                        "#dxDateBox_dbFechaPedido"
                                                                                                                    )
                                                                                                                        .dxDateBox(
                                                                                                                            "instance"
                                                                                                                        )
                                                                                                                        .option(
                                                                                                                            "value",
                                                                                                                            convertClienteUtcToLavanderiaUtc(
                                                                                                                                entidadSel.GMT,
                                                                                                                                fecha
                                                                                                                            )
                                                                                                                        );
                                                                                                                    deshabilitarEdicionCierreFact(
                                                                                                                        entidadSel.idEntidad,
                                                                                                                        pedidoSel.fecha,
                                                                                                                        pedidoSel.fecha
                                                                                                                    );
                                                                                                                    datasource_tipoPedido
                                                                                                                        .reload()
                                                                                                                        .done(
                                                                                                                            function (
                                                                                                                                items
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    items.length >
                                                                                                                                    0
                                                                                                                                ) {
                                                                                                                                    $(
                                                                                                                                        "#dxSelectBox_tipoPedido"
                                                                                                                                    )
                                                                                                                                        .dxSelectBox(
                                                                                                                                            "instance"
                                                                                                                                        )
                                                                                                                                        .option(
                                                                                                                                            "value",
                                                                                                                                            items[0]
                                                                                                                                                .idTipoPedido
                                                                                                                                        );
                                                                                                                                } else {
                                                                                                                                    dxMensajePregunta(
                                                                                                                                        getTrad(
                                                                                                                                            "alerta_error_noTipoPedido"
                                                                                                                                        ),
                                                                                                                                        [
                                                                                                                                            [
                                                                                                                                                getTrad(
                                                                                                                                                    "aceptar"
                                                                                                                                                ),
                                                                                                                                                function (
                                                                                                                                                    e
                                                                                                                                                ) {
                                                                                                                                                    history.push(
                                                                                                                                                        "/"
                                                                                                                                                    );
                                                                                                                                                },
                                                                                                                                                "danger",
                                                                                                                                                "dxButton_sinLavanderia_error",
                                                                                                                                            ],
                                                                                                                                        ]
                                                                                                                                    );
                                                                                                                                }
                                                                                                                            }
                                                                                                                        );

                                                                                                                    $(
                                                                                                                        "#dxList_compañias_nuevoPedido"
                                                                                                                    )
                                                                                                                        .dxList(
                                                                                                                            "instance"
                                                                                                                        )
                                                                                                                        .unselectAll();
                                                                                                                    $(
                                                                                                                        "#dxTabPanel_compaEnti_nuevoPedido"
                                                                                                                    )
                                                                                                                        .dxTabPanel(
                                                                                                                            "instance"
                                                                                                                        )
                                                                                                                        .option(
                                                                                                                            "selectedIndex",
                                                                                                                            0
                                                                                                                        );
                                                                                                                    $(
                                                                                                                        "#dxResponsiveBox_filtros"
                                                                                                                    )
                                                                                                                        .dxResponsiveBox(
                                                                                                                            "instance"
                                                                                                                        )
                                                                                                                        .option(
                                                                                                                            "items",
                                                                                                                            get_dxResponsiveBox_filtros_items()
                                                                                                                        );
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    },
                                                                                            });

                                                                                        dxDropDownBox.component.on(
                                                                                            "valueChanged",
                                                                                            function (args) {
                                                                                                if (
                                                                                                    $(
                                                                                                        "#dxList_entidades_nuevoPedido"
                                                                                                    )
                                                                                                        .dxList(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .option(
                                                                                                            "selectedItemKeys"
                                                                                                        )[0] !==
                                                                                                    args.value
                                                                                                ) {
                                                                                                    $(
                                                                                                        "#dxList_entidades_nuevoPedido"
                                                                                                    )
                                                                                                        .dxList(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .option(
                                                                                                            "selectedItemKeys",
                                                                                                            [args.value]
                                                                                                        );
                                                                                                }
                                                                                                let dxLoadPanel_GridsNuevoPedido =
                                                                                                    $(
                                                                                                        "#dxLoadPanel_GridsNuevoPedido"
                                                                                                    ).dxLoadPanel(
                                                                                                        "instance"
                                                                                                    );
                                                                                                dxLoadPanel_GridsNuevoPedido.show();
                                                                                                pedidoSel.idEntidad =
                                                                                                    args.value;

                                                                                                hayPrendas_Peticion = false;
                                                                                                hayPrendas_Stock = false;

                                                                                                if (
                                                                                                    $(
                                                                                                        "#dxDataGrid_NuevoPedido"
                                                                                                    ).data("dxDataGrid")
                                                                                                )
                                                                                                    $(
                                                                                                        "#dxDataGrid_NuevoPedido"
                                                                                                    )
                                                                                                        .dxDataGrid(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .beginUpdate();
                                                                                                if (
                                                                                                    $(
                                                                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                                                                    ).data("dxDataGrid")
                                                                                                )
                                                                                                    $(
                                                                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                                                                    )
                                                                                                        .dxDataGrid(
                                                                                                            "instance"
                                                                                                        )
                                                                                                        .beginUpdate();

                                                                                                $.when(
                                                                                                    datasource_pedidos.reload(),
                                                                                                    datasource_pedidos_stock.reload()
                                                                                                ).then(function () {
                                                                                                    let grids = [];
                                                                                                    let dxDataGrid_NuevoPedido,
                                                                                                        dxDataGrid_NuevoPedido_almacen;

                                                                                                    let dxResponsiveBox_filtros =
                                                                                                        $(
                                                                                                            "#dxResponsiveBox_filtros"
                                                                                                        ).dxResponsiveBox(
                                                                                                            "instance"
                                                                                                        );
                                                                                                    dxResponsiveBox_filtros.option(
                                                                                                        "items",
                                                                                                        get_dxResponsiveBox_filtros_items()
                                                                                                    );
                                                                                                    dxResponsiveBox_filtros.beginUpdate();

                                                                                                    if (
                                                                                                        $(
                                                                                                            "#dxDataGrid_NuevoPedido"
                                                                                                        ).data(
                                                                                                            "dxDataGrid"
                                                                                                        )
                                                                                                    ) {
                                                                                                        dxDataGrid_NuevoPedido =
                                                                                                            $(
                                                                                                                "#dxDataGrid_NuevoPedido"
                                                                                                            ).dxDataGrid(
                                                                                                                "instance"
                                                                                                            );
                                                                                                        dxDataGrid_NuevoPedido.endUpdate();
                                                                                                        grids.push(
                                                                                                            "#dxDataGrid_NuevoPedido"
                                                                                                        );
                                                                                                    }

                                                                                                    if (
                                                                                                        $(
                                                                                                            "#dxDataGrid_NuevoPedido_almacen"
                                                                                                        ).data(
                                                                                                            "dxDataGrid"
                                                                                                        )
                                                                                                    ) {
                                                                                                        dxDataGrid_NuevoPedido_almacen =
                                                                                                            $(
                                                                                                                "#dxDataGrid_NuevoPedido_almacen"
                                                                                                            ).dxDataGrid(
                                                                                                                "instance"
                                                                                                            );
                                                                                                        dxDataGrid_NuevoPedido_almacen.endUpdate();
                                                                                                        grids.push(
                                                                                                            "#dxDataGrid_NuevoPedido_almacen"
                                                                                                        );
                                                                                                    }

                                                                                                    $.each(
                                                                                                        grids,
                                                                                                        function (
                                                                                                            index,
                                                                                                            item
                                                                                                        ) {
                                                                                                            let grid =
                                                                                                                $(
                                                                                                                    item
                                                                                                                ).dxDataGrid(
                                                                                                                    "instance"
                                                                                                                );
                                                                                                            grid.option(
                                                                                                                "editing.allowDeleting",
                                                                                                                pedidoSel &&
                                                                                                                    item ==
                                                                                                                        "#dxDataGrid_NuevoPedido"
                                                                                                            );
                                                                                                            grid.cancelEditData();
                                                                                                            grid.refresh();
                                                                                                            grid.option(
                                                                                                                "noDataText",
                                                                                                                getTrad(
                                                                                                                    "sinDatos"
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    );

                                                                                                    dxResponsiveBox_filtros.endUpdate();

                                                                                                    if (
                                                                                                        dxDataGrid_NuevoPedido
                                                                                                    )
                                                                                                        dxDataGrid_NuevoPedido.repaint();
                                                                                                    if (
                                                                                                        dxDataGrid_NuevoPedido_almacen
                                                                                                    )
                                                                                                        dxDataGrid_NuevoPedido_almacen.repaint();

                                                                                                    dxLoadPanel_GridsNuevoPedido.hide();
                                                                                                });
                                                                                                args.component.close();
                                                                                            }
                                                                                        );

                                                                                        return dxList_entidades_nuevoPedido;
                                                                                    },
                                                                                },
                                                                            ],
                                                                        });
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                } else {
                                                    return $("<div>").dxBox({
                                                        direction: "row",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        items: [
                                                            {
                                                                ratio: 1,
                                                                template: function () {
                                                                    // COMPAÑÍAS
                                                                    var container = $("<div>");
                                                                    var title = $(
                                                                        "<div class='font-size' style='margin-left:9px;'>" +
                                                                            getTrad("compañia") +
                                                                            "</div>"
                                                                    );
                                                                    var lista_compañias = $(
                                                                        "<div id='dxDropDownBox_lista_compañias'>"
                                                                    ).dxList({
                                                                        dataSource: datasource_compañias.items(),
                                                                        selectionMode: "single",
                                                                        searchEnabled: true,
                                                                        searchExpr: ["denominacion"],
                                                                        height: "375px",
                                                                        width: "95%",
                                                                        keyExpr: "idCompañia",
                                                                        scrolling: { mode: "infinite" },
                                                                        pageLoadMode: "scrollBottom",
                                                                        // EVENTOS
                                                                        onContentReady: function (e) {
                                                                            // SELECCIONA EL ITEM 0 POR DEFECTO
                                                                            e.component.selectItem(0);
                                                                        },
                                                                        onSelectionChanged: function (e) {
                                                                            var lista_entidades = $(
                                                                                "#dxDropDownBox_lista_entidades"
                                                                            ).dxList("instance");

                                                                            var entidadesFiltradas =
                                                                                datasource_entidades.items();
                                                                            if (
                                                                                e.addedItems.length > 0 &&
                                                                                e.addedItems[0].idCompañia !== 0
                                                                            ) {
                                                                                // SI EL ITEM SELECCIONADO NO ES TODOS
                                                                                entidadesFiltradas =
                                                                                    datasource_entidades
                                                                                        .items()
                                                                                        .filter(
                                                                                            (x) =>
                                                                                                x.idCompañia ===
                                                                                                e.addedItems[0]
                                                                                                    .idCompañia
                                                                                        );
                                                                            }

                                                                            if (lista_entidades !== undefined) {
                                                                                lista_entidades.option(
                                                                                    "dataSource",
                                                                                    entidadesFiltradas
                                                                                );
                                                                            }
                                                                        },
                                                                        itemTemplate: function (data, index) {
                                                                            var result = $("<div>");
                                                                            $("<div>")
                                                                                .text(data.denominacion)
                                                                                .appendTo(result);

                                                                            return result;
                                                                        },
                                                                    });

                                                                    title.appendTo(container);
                                                                    lista_compañias.appendTo(container);

                                                                    return container;
                                                                },
                                                            },
                                                            {
                                                                // ENTIDADES
                                                                ratio: 1,
                                                                template: function () {
                                                                    var container = $("<div>");
                                                                    var title = $(
                                                                        "<div class='font-size' style='margin-left:9px;'>" +
                                                                            getTrad("entidad") +
                                                                            "</div>"
                                                                    );
                                                                    var lista_entidades = $(
                                                                        "<div id='dxDropDownBox_lista_entidades' class='dxList-pe-auto'>"
                                                                    ).dxList({
                                                                        //Datos
                                                                        dataSource: datasource_entidades.items(),
                                                                        selectionMode: "single",
                                                                        searchEnabled: true,
                                                                        searchExpr: ["denominacion"],
                                                                        keyExpr: "idEntidad",
                                                                        height: "375px",
                                                                        scrolling: { mode: "infinite" },
                                                                        pageLoadMode: "scrollBottom",
                                                                        //EVENTOS
                                                                        onSelectionChanged: function (e) {
                                                                            if (
                                                                                typeof e.addedItems[0] !== "undefined"
                                                                            ) {
                                                                                if (
                                                                                    dxDropDownBox.component.option(
                                                                                        "value"
                                                                                    ) !== e.addedItems[0].idEntidad
                                                                                ) {
                                                                                    $("#btnGuardarPedido")
                                                                                        .dxButton("instance")
                                                                                        .option("disabled", true);
                                                                                    dxDropDownBox.component.option(
                                                                                        "value",
                                                                                        e.addedItems[0].idEntidad
                                                                                    );

                                                                                    entidadSel = e.addedItems[0];

                                                                                    let fecha =
                                                                                        pedidoSel && pedidoSel.fecha
                                                                                            ? pedidoSel.fecha
                                                                                            : new Date();
                                                                                    $("#dxDateBox_dbFechaPedido")
                                                                                        .dxDateBox("instance")
                                                                                        .option(
                                                                                            "value",
                                                                                            convertClienteUtcToLavanderiaUtc(
                                                                                                entidadSel.GMT,
                                                                                                fecha
                                                                                            )
                                                                                        );

                                                                                    deshabilitarEdicionCierreFact(
                                                                                        entidadSel.idEntidad,
                                                                                        pedidoSel.fecha,
                                                                                        pedidoSel.fecha
                                                                                    );

                                                                                    datasource_tipoPedido
                                                                                        .reload()
                                                                                        .done(function (items) {
                                                                                            if (items.length > 0) {
                                                                                                $(
                                                                                                    "#dxSelectBox_tipoPedido"
                                                                                                )
                                                                                                    .dxSelectBox(
                                                                                                        "instance"
                                                                                                    )
                                                                                                    .option(
                                                                                                        "value",
                                                                                                        items[0]
                                                                                                            .idTipoPedido
                                                                                                    );
                                                                                            } else {
                                                                                                dxMensajePregunta(
                                                                                                    getTrad(
                                                                                                        "alerta_error_noTipoPedido"
                                                                                                    ),
                                                                                                    [
                                                                                                        [
                                                                                                            getTrad(
                                                                                                                "aceptar"
                                                                                                            ),
                                                                                                            function (
                                                                                                                e
                                                                                                            ) {
                                                                                                                history.push(
                                                                                                                    "/"
                                                                                                                );
                                                                                                            },
                                                                                                            "danger",
                                                                                                            "dxButton_sinLavanderia_error",
                                                                                                        ],
                                                                                                    ]
                                                                                                );
                                                                                            }
                                                                                        });
                                                                                }
                                                                            }
                                                                        },
                                                                        itemTemplate: function (
                                                                            data,
                                                                            index,
                                                                            itemElement
                                                                        ) {
                                                                            let isDisabled = data.disabled == true;
                                                                            let tooltip =
                                                                                $("#dxTooltip_Pedidos").dxTooltip(
                                                                                    "instance"
                                                                                );

                                                                            let div = $("<div>")
                                                                                .addClass("id_" + data.idEntidad)
                                                                                .text(data.denominacion)
                                                                                .on("mouseout", function () {
                                                                                    tooltip.hide();
                                                                                });

                                                                            if (isDisabled) {
                                                                                $(itemElement)
                                                                                    .parent()
                                                                                    .on("mouseover", function () {
                                                                                        tooltip.option({
                                                                                            visible: isDisabled,
                                                                                            target:
                                                                                                ".id_" + data.idEntidad,
                                                                                            contentTemplate: function (
                                                                                                contentElement
                                                                                            ) {
                                                                                                contentElement.html(
                                                                                                    getTrad(
                                                                                                        "factBloqueadaEntiFecha"
                                                                                                    )
                                                                                                );
                                                                                            },
                                                                                        });
                                                                                        tooltip.show();
                                                                                    });
                                                                            } else {
                                                                                tooltip.option({
                                                                                    showEvent: null,
                                                                                    hideEvent: null,
                                                                                });
                                                                            }

                                                                            $(e.element).on("mouseout", function () {
                                                                                tooltip.hide();
                                                                            });

                                                                            div.appendTo(itemElement).text(
                                                                                data.denominacion
                                                                            );
                                                                        },
                                                                    });

                                                                    dxDropDownBox.component.on(
                                                                        "valueChanged",
                                                                        function (args) {
                                                                            if (
                                                                                $("#dxDropDownBox_lista_entidades")
                                                                                    .dxList("instance")
                                                                                    .option("selectedItemKeys")[0] !==
                                                                                args.value
                                                                            ) {
                                                                                $("#dxDropDownBox_lista_entidades")
                                                                                    .dxList("instance")
                                                                                    .option("selectedItemKeys", [
                                                                                        args.value,
                                                                                    ]);
                                                                            }

                                                                            let dxLoadPanel_GridsNuevoPedido = $(
                                                                                "#dxLoadPanel_GridsNuevoPedido"
                                                                            ).dxLoadPanel("instance");
                                                                            dxLoadPanel_GridsNuevoPedido.show();
                                                                            pedidoSel.idEntidad = args.value;

                                                                            hayPrendas_Peticion = false;
                                                                            hayPrendas_Stock = false;

                                                                            if (
                                                                                $("#dxDataGrid_NuevoPedido").data(
                                                                                    "dxDataGrid"
                                                                                )
                                                                            )
                                                                                $("#dxDataGrid_NuevoPedido")
                                                                                    .dxDataGrid("instance")
                                                                                    .beginUpdate();
                                                                            if (
                                                                                $(
                                                                                    "#dxDataGrid_NuevoPedido_almacen"
                                                                                ).data("dxDataGrid")
                                                                            )
                                                                                $("#dxDataGrid_NuevoPedido_almacen")
                                                                                    .dxDataGrid("instance")
                                                                                    .beginUpdate();

                                                                            $.when(
                                                                                datasource_pedidos.reload(),
                                                                                datasource_pedidos_stock.reload()
                                                                            ).then(function () {
                                                                                let grids = [];
                                                                                let dxDataGrid_NuevoPedido,
                                                                                    dxDataGrid_NuevoPedido_almacen;

                                                                                let dxResponsiveBox_filtros =
                                                                                    $(
                                                                                        "#dxResponsiveBox_filtros"
                                                                                    ).dxResponsiveBox("instance");
                                                                                dxResponsiveBox_filtros.option(
                                                                                    "items",
                                                                                    get_dxResponsiveBox_filtros_items()
                                                                                );
                                                                                dxResponsiveBox_filtros.beginUpdate();

                                                                                if (
                                                                                    $("#dxDataGrid_NuevoPedido").data(
                                                                                        "dxDataGrid"
                                                                                    )
                                                                                ) {
                                                                                    dxDataGrid_NuevoPedido =
                                                                                        $(
                                                                                            "#dxDataGrid_NuevoPedido"
                                                                                        ).dxDataGrid("instance");
                                                                                    dxDataGrid_NuevoPedido.endUpdate();
                                                                                    grids.push(
                                                                                        "#dxDataGrid_NuevoPedido"
                                                                                    );
                                                                                }

                                                                                if (
                                                                                    $(
                                                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                                                    ).data("dxDataGrid")
                                                                                ) {
                                                                                    dxDataGrid_NuevoPedido_almacen = $(
                                                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                                                    ).dxDataGrid("instance");
                                                                                    dxDataGrid_NuevoPedido_almacen.endUpdate();
                                                                                    grids.push(
                                                                                        "#dxDataGrid_NuevoPedido_almacen"
                                                                                    );
                                                                                }

                                                                                $.each(grids, function (index, item) {
                                                                                    let grid =
                                                                                        $(item).dxDataGrid("instance");
                                                                                    grid.option(
                                                                                        "editing.allowDeleting",
                                                                                        pedidoSel &&
                                                                                            item ==
                                                                                                "#dxDataGrid_NuevoPedido"
                                                                                    );
                                                                                    grid.cancelEditData();
                                                                                    grid.refresh();
                                                                                    grid.option(
                                                                                        "noDataText",
                                                                                        getTrad("sinDatos")
                                                                                    );
                                                                                });

                                                                                dxResponsiveBox_filtros.endUpdate();

                                                                                if (dxDataGrid_NuevoPedido) {
                                                                                    dxDataGrid_NuevoPedido.repaint();
                                                                                    dxDataGrid_NuevoPedido.updateDimensions();
                                                                                }
                                                                                if (dxDataGrid_NuevoPedido_almacen) {
                                                                                    dxDataGrid_NuevoPedido_almacen.repaint();
                                                                                    dxDataGrid_NuevoPedido_almacen.updateDimensions();
                                                                                }

                                                                                dxLoadPanel_GridsNuevoPedido.hide();
                                                                            });
                                                                            args.component.close();
                                                                        }
                                                                    );

                                                                    title.appendTo(container);
                                                                    lista_entidades.appendTo(container);

                                                                    return container;
                                                                },
                                                            },
                                                        ],
                                                    });
                                                }
                                            },
                                        });
                                        dxDropDownBox.appendTo(container);
                                        return container;
                                    },
                                },
                                {
                                    colSpan: get_isMovil(COMPONENT.props.resolucion) ? 6 : 3,
                                    label: {
                                        visible: get_isMovil(COMPONENT.props.resolucion),
                                        text: getTrad("tipoPedido"),
                                        location: "top",
                                    },
                                    template: function () {
                                        var container = $("<div>");
                                        var dxSelectBox = $("<div />").dxSelectBox({
                                            elementAttr: { id: "dxSelectBox_tipoPedido" },
                                            dataSource: datasource_tipoPedido,
                                            valueExpr: "idTipoPedido",
                                            displayExpr: "denominacion",
                                            value:
                                                datasource_tipoPedido.items().length == 1
                                                    ? datasource_tipoPedido.items()[0].idTipoPedido
                                                    : pedidoSel.idTipoPedido !== null
                                                    ? pedidoSel.idTipoPedido
                                                    : null,
                                            onValueChanged: function (e) {
                                                pedidoSel.idTipoPedido = e.value;
                                            },
                                            width: "100%",
                                        });
                                        dxSelectBox.appendTo(container);
                                        return container;
                                    },
                                },
                                {
                                    colSpan: get_isMovil(COMPONENT.props.resolucion) ? 3 : 3,
                                    label: {
                                        visible: get_isMovil(COMPONENT.props.resolucion),
                                        text: getTrad("fecha"),
                                        location: "top",
                                    },
                                    editorType: "dxDateBox",
                                    editorOptions: {
                                        elementAttr: { id: "dxDateBox_dbFechaPedido" },
                                        displayFormat: "dd/MM/yyyy",
                                        width: "100%",
                                        value: pedidoSel.fecha !== null ? pedidoSel.fecha : new Date(),
                                        disabled: USER.idCargo !== 7 ? false : true,
                                        disabledDates: dxDateBox_disabledDates,
                                        calendarOptions: {
                                            cellTemplate: function (e) {
                                                let tooltip = $("#dxTooltip_Pedidos").dxTooltip("instance");
                                                let result = $("<div>")
                                                    .addClass("id_" + formatDate_noTime_parameter(e.date))
                                                    .text(e.text)
                                                    .on("mouseenter", function () {
                                                        let fechasDeshabilitadas = dxDateBox_disabledDates.filter(
                                                            (fecha) =>
                                                                formatDate_noTime_parameter(fecha) ==
                                                                formatDate_noTime_parameter(e.date)
                                                        );
                                                        if (fechasDeshabilitadas.length > 0) {
                                                            tooltip.option({
                                                                target: ".id_" + formatDate_noTime_parameter(e.date),
                                                                contentTemplate: function (contentElement) {
                                                                    contentElement.html(
                                                                        getTrad("factBloqueadaEntiFecha")
                                                                    );
                                                                },
                                                            });
                                                            tooltip.show();
                                                        }
                                                    })
                                                    .on("mouseleave", function () {
                                                        tooltip.hide();
                                                    });

                                                return result;
                                            },
                                        },
                                        onValueChanged: function (e) {
                                            pedidoSel.fecha = e.value;
                                            deshabilitarEntidadesFacturacion(pedidoSel.fecha, pedidoSel.fecha);
                                        },
                                    },
                                },
                                {
                                    colSpan: 9,
                                    label: {
                                        visible: get_isMovil(COMPONENT.props.resolucion),
                                        text: getTrad("observaciones"),
                                        location: "top",
                                    },
                                    visible: get_isMovil(COMPONENT.props.resolucion),
                                    template: function () {
                                        var container = $("<div>");
                                        var dxTextArea = $("<div id='taObservaciones' />").dxTextArea({
                                            height: 36,
                                            width: "100%",
                                            value: pedidoSel.observaciones,
                                            placeholder: getTrad("observaciones"),
                                            onValueChanged: function (e) {
                                                pedidoSel.observaciones = e.value;
                                            },
                                        });
                                        dxTextArea.appendTo(container);
                                        return container;
                                    },
                                },
                                {
                                    colSpan: 4,
                                    label: {
                                        visible: get_isMovil(COMPONENT.props.resolucion),
                                        text: getTrad("usuCreador"),
                                        location: "top",
                                    },
                                    name: "usuarioCreador",
                                    visible: get_isMovil(COMPONENT.props.resolucion) && pedidoSel.idPedido !== null,
                                    template: function () {
                                        var contenedor = $("<div />")
                                            .css("border", "1px dotted lightgray")
                                            .css("padding", "9px 10px")
                                            .css("margin-right", "2px")
                                            .css("border-radius", "3px")
                                            .text(
                                                pedidoSel.idPedido &&
                                                    pedidoSel.idUsuarioCreador &&
                                                    pedidoSel.idUsuarioCreadorNavigation
                                                    ? pedidoSel.idUsuarioCreadorNavigation.nombre
                                                    : getTrad("noIdentidicado")
                                            );
                                        tooltipControl_creacion(contenedor, getTrad("usuCreador"));

                                        return contenedor;
                                    },
                                },
                            ],
                            onContentReady: function () {
                                setTimeout(() => {
                                    var dxSelectBox_tipoPedido = $("#dxSelectBox_tipoPedido").dxSelectBox("instance");
                                    if (typeof dxSelectBox_tipoPedido !== "undefined") {
                                        dxSelectBox_tipoPedido.option("disabled", pedidoSel.idPedido ? true : false);
                                    }
                                }, 0);
                            },
                        });

                        if (get_isMovil(COMPONENT.props.resolucion)) {
                            return $("<div />")
                                .attr("id", "dxAccordion_opcionesPedido")
                                .css("padding-bottom", 10)
                                .dxAccordion({
                                    items: [{}],
                                    itemTitleTemplate: function () {
                                        return getTrad("datosPedido");
                                    },
                                    itemTemplate: function () {
                                        return dxForm_dxDataGrid_NuevoPedido;
                                    },
                                    animationDuration: 300,
                                    collapsible: true,
                                    multiple: false,
                                });
                        } else {
                            return dxForm_dxDataGrid_NuevoPedido;
                        }
                    },
                },
                {
                    location: [
                        {
                            row: 1,
                            col: 0,
                            screen: "xs sm md lg xl",
                        },
                    ],
                    template: function (e, index, item) {
                        let dxDataGrid_NuevoPedido = grid_nuevoPedido.dxDataGrid("instance"),
                            dxDataGrid_NuevoPedido_almacen = grid_nuevoPedido_almacen.dxDataGrid("instance");

                        let prendasPeticion = $.map(datasource_pedidos.items(), function (item) {
                            return item.items;
                        });
                        let prendasStock = $.map(datasource_pedidos_stock.items(), function (item) {
                            return item.items;
                        });
                        let isDosGrids = prendasPeticion.length > 0 && prendasStock.length > 0;

                        return $("<div id='dxToolbar_filtros_nuevoPed' class='dxToolbar_filtros'/>")
                            .css("height", 45)
                            .dxToolbar({
                                items: [
                                    {
                                        location: "before",
                                        widget: "dxButton",
                                        toolbar: "bottom",
                                        options: {
                                            width: "100%",
                                            icon: !get_isMovil(COMPONENT.props.resolucion) ? "plus" : null,
                                            text: getTrad("nuevoPedido"),
                                            type: "normal",
                                            elementAttr: {
                                                id: "btnNuevoPedido",
                                            },
                                            onClick: function (e) {
                                                if (nuevoPedido_HasChanges()) {
                                                    $("#popup_alert_AceptarCancelar").dxPopup("instance").show();
                                                    $("#btnAceptar")
                                                        .dxButton("instance")
                                                        .option("onClick", function (e) {
                                                            $("#popup_alert_AceptarCancelar")
                                                                .dxPopup("instance")
                                                                .hide();
                                                            nuevoPedido();
                                                        });
                                                } else nuevoPedido();
                                            },
                                        },
                                    },
                                    {
                                        location: "before",
                                        widget: "dxButton",
                                        toolbar: "bottom",
                                        options: {
                                            width: "100%",
                                            elementAttr: {
                                                id: "btnAddPrenda",
                                            },
                                            icon: !get_isMovil(COMPONENT.props.resolucion) ? "plus" : null,
                                            text: getTrad("añadirPrenda"),
                                            type: "success",
                                            visible: !pedidoSel.idPedido ? false : true,
                                            disabled:
                                                isCerradoFactEntidad ||
                                                (pedidoSel.idPedido &&
                                                    ((!hayPrendas_Peticion && !hayPrendas_Stock) ||
                                                        pedidoSel.isCerrado))
                                                    ? true
                                                    : false, //Pedido sin prendas o cerrado
                                            onClick: function (e) {
                                                if (typeof $("#dxList_añadirPrendas")[0] !== "undefined") {
                                                    $("#dxList_añadirPrendas").dxList("instance").unselectAll();
                                                    datasource_añadirPrenda.load();
                                                }
                                                $("#popup_añadirPrendas").dxPopup("instance").show();
                                            },
                                        },
                                    },
                                    {
                                        location: "after",
                                        widget: "dxButton",
                                        toolbar: "bottom",
                                        options: {
                                            width: "100%",
                                            text: !get_isMovil(COMPONENT.props.resolucion)
                                                ? getTrad("reposicionNoNecesaria")
                                                : "Sin prendas",
                                            type: "normal",
                                            visible:
                                                hayPrendas_Stock && !pedidoSel.idPedido && pedidoSel.idEntidad != null,
                                            disabled:
                                                isCerradoFactEntidad ||
                                                (pedidoSel.idPedido &&
                                                    ((!hayPrendas_Peticion && !hayPrendas_Stock) ||
                                                        pedidoSel.isCerrado))
                                                    ? true
                                                    : false, //Pedido sin prendas o cerrado
                                            onClick: function (e) {
                                                dxMensajePregunta(getTrad("prendasPedidos_noRepo"), [
                                                    [
                                                        getTrad("noReponerLenceria"),
                                                        function () {
                                                            guardarPedido([]);
                                                        },
                                                        "success",
                                                        "btnAceptarPedidoVacio",
                                                    ],
                                                    [
                                                        getTrad("cancelar"),
                                                        function () {},
                                                        "danger",
                                                        "btnCancelarPedidoVacio",
                                                    ],
                                                ]);
                                            },
                                        },
                                    },
                                    {
                                        location: "after",
                                        visible: !get_isMovil(COMPONENT.props.resolucion)
                                            ? pedidoSel.idPedido
                                                ? true
                                                : false
                                            : false,
                                        showText: "inMenu",
                                        name: "usuarioCreador",
                                        template: function () {
                                            var contenedor = $("<div />")
                                                .css("border", "1px dotted lightgray")
                                                .css("padding", "7px 10px")
                                                .css("margin-right", "2px")
                                                .css("border-radius", "3px")
                                                .css("min-width", "120px")
                                                .css("width", "100%")
                                                .text(
                                                    pedidoSel.idPedido &&
                                                        pedidoSel.idUsuarioCreador &&
                                                        pedidoSel.idUsuarioCreadorNavigation
                                                        ? pedidoSel.idUsuarioCreadorNavigation.nombre
                                                        : getTrad("noIdentidicado")
                                                );
                                            tooltipControl_creacion(contenedor, getTrad("usuCreador"));

                                            return contenedor;
                                        },
                                    },
                                    {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                            icon: " icon_Impresora",
                                            disabled:
                                                pedidoSel.idPedido && !hayPrendas_Peticion && !hayPrendas_Stock
                                                    ? true
                                                    : false, //Pedido sin prendas
                                            onClick: function (e) {
                                                if (hayPrendas_Stock || hayPrendas_Peticion) {
                                                    if (pedidoSel.idPedido) {
                                                        if (!nuevoPedido_HasChanges()) {
                                                            var params = {
                                                                idPedido: pedidoSel.idPedido,
                                                                idUsuario: USER.idUsuario,
                                                            };
                                                            COMPONENT.setParams(params);
                                                        } else {
                                                            notify({
                                                                message: getTrad("alerta_GuardarParaImprimir"),
                                                                type: "error",
                                                                displayTime: "1500",
                                                                closeOnClick: true,
                                                            });
                                                        }
                                                    } else {
                                                        notify({
                                                            message: getTrad("alerta_SeleccionarParaImprimir"),
                                                            type: "error",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                        });
                                                    }
                                                } else {
                                                    notify({
                                                        message: getTrad("alerta_impresionPedidoSinPrendas"),
                                                        type: "error",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                    });
                                                }
                                            },
                                        },
                                    },
                                    {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                            width: !pedidoSel.idPedido ? "100%" : "36px",
                                            icon: !pedidoSel.idPedido
                                                ? !get_isMovil(COMPONENT.props.resolucion)
                                                    ? "check"
                                                    : null
                                                : "save",
                                            type: !pedidoSel.idPedido ? "success" : "normal",
                                            text: !pedidoSel.idPedido ? getTrad("realizarPedido") : null,
                                            disabled:
                                                isCerradoFactEntidad ||
                                                (pedidoSel.idPedido &&
                                                    ((!hayPrendas_Stock && !hayPrendas_Peticion) ||
                                                        pedidoSel.isCerrado))
                                                    ? true
                                                    : false, //Pedido sin prendas
                                            elementAttr: {
                                                id: "btnGuardarPedido",
                                            },
                                            onClick: (button) => {
                                                let someStockActual_null = false;
                                                setTimeout(() => {
                                                    if (
                                                        datasource_pedidos_stock.items().length > 0 &&
                                                        datasource_pedidos_stock.items()[0].items.length > 0 &&
                                                        !validationEngine.validateGroup("validationGroup_Stock").isValid
                                                    ) {
                                                        notify({
                                                            message: getTrad("introducirStockActual"),
                                                            type: "error",
                                                            displayTime: "2000",
                                                            closeOnClick: true,
                                                        });
                                                        return;
                                                    }

                                                    if (
                                                        $("#dxDropDownBox_selCompaEnti")
                                                            .dxDropDownBox("instance")
                                                            .option("value")
                                                    ) {
                                                        button.component.option("disabled", true);

                                                        if (!pedidoSel.idPedido) {
                                                            let grids = [];

                                                            if (hayPrendas_Peticion) grids.push(dxDataGrid_NuevoPedido);

                                                            if (hayPrendas_Stock)
                                                                grids.push(dxDataGrid_NuevoPedido_almacen);

                                                            var tblPrendaNPedido = [];
                                                            $.each(grids, function (iGrid, grid) {
                                                                $.each(grid.getVisibleRows(), function (index, item) {
                                                                    if (item.rowType === "data") {
                                                                        if (
                                                                            grid.cellValue(
                                                                                item.rowIndex,
                                                                                "stockActual"
                                                                            ) === null
                                                                        ) {
                                                                            //* Para evitar enviar pedidos con prendas por almacén que tengan stockActual null
                                                                            someStockActual_null = true;
                                                                            return;
                                                                        }
                                                                        var prenda = {};
                                                                        let isPedidoPorPeticion =
                                                                            item.data.stockDefinido == null ||
                                                                            item.data.stockDefinido == 0;
                                                                        prenda.idPrenda = grid.cellValue(
                                                                            item.rowIndex,
                                                                            "idPrenda"
                                                                        );
                                                                        if (isPedidoPorPeticion) {
                                                                            let cellValue_cantidad = grid.cellValue(
                                                                                item.rowIndex,
                                                                                "cantidad"
                                                                            );
                                                                            let cantidad = typeof (
                                                                                cellValue_cantidad !== "undefined"
                                                                            )
                                                                                ? cellValue_cantidad
                                                                                : 0;
                                                                            let resultado =
                                                                                cantidad * item.data.udsXBacPedido;
                                                                            prenda.peticion =
                                                                                resultado > 0 ? resultado : 0;
                                                                        } else {
                                                                            prenda.stockActual =
                                                                                grid.cellValue(
                                                                                    item.rowIndex,
                                                                                    "stockActual"
                                                                                ) * item.data.udsXBacPedido;
                                                                            prenda.peticion = Math.ceil(
                                                                                (grid.cellValue(
                                                                                    item.rowIndex,
                                                                                    "stockDefinido"
                                                                                ) -
                                                                                    grid.cellValue(
                                                                                        item.rowIndex,
                                                                                        "stockActual"
                                                                                    )) *
                                                                                    item.data.udsXBacPedido
                                                                            );
                                                                        }

                                                                        if (prenda.peticion > 0) {
                                                                            tblPrendaNPedido.push(prenda);
                                                                        } else if (!isPedidoPorPeticion) {
                                                                            prenda.peticion = 0;
                                                                            tblPrendaNPedido.push(prenda);
                                                                        }
                                                                    }
                                                                });
                                                            });
                                                            if (someStockActual_null) {
                                                                button.component.option("disabled", false);
                                                                return;
                                                            }

                                                            if (tblPrendaNPedido.length > 0) {
                                                                guardarPedido(tblPrendaNPedido);
                                                            } else {
                                                                dxMensajePregunta(getTrad("generarPedidoVacio"), [
                                                                    [
                                                                        getTrad("noReponerLenceria"),
                                                                        function () {
                                                                            guardarPedido([]);
                                                                        },
                                                                        "success",
                                                                        "btnAceptarPedidoVacio",
                                                                    ],
                                                                    [
                                                                        getTrad("cancelar"),
                                                                        function () {
                                                                            button.component.option("disabled", false);
                                                                        },
                                                                        "danger",
                                                                        "btnCancelarPedidoVacio",
                                                                    ],
                                                                ]);
                                                            }
                                                        } else if (nuevoPedido_HasChanges()) {
                                                            var pedido = {};

                                                            pedido.idTipoPedido = $("#dxSelectBox_tipoPedido")
                                                                .dxSelectBox("instance")
                                                                .option("value");
                                                            pedido.fecha = $("#dxDateBox_dbFechaPedido")
                                                                .dxDateBox("instance")
                                                                .option("value");
                                                            pedido.observaciones = $("#taObservaciones")
                                                                .dxTextArea("instance")
                                                                .option("value");

                                                            let dxLoadPanel_GridsNuevoPedido = $(
                                                                "#dxLoadPanel_GridsNuevoPedido"
                                                            ).dxLoadPanel("instance");
                                                            dxLoadPanel_GridsNuevoPedido.show();
                                                            let dxToolbar_filtros_nuevoPed =
                                                                $("#dxToolbar_filtros_nuevoPed").dxToolbar("instance");
                                                            let gridAnteriores = $(
                                                                "#dxDatagrid_pedidosAnteriores"
                                                            ).dxDataGrid("instance");

                                                            gridAnteriores.option("disabled", true);
                                                            dxToolbar_filtros_nuevoPed.option("disabled", true);
                                                            $.when(
                                                                $("#dxDataGrid_NuevoPedido").data("dxDataGrid") &&
                                                                    dxDataGrid_NuevoPedido.saveEditData(),
                                                                $("#dxDataGrid_NuevoPedido_almacen").data(
                                                                    "dxDataGrid"
                                                                ) && dxDataGrid_NuevoPedido_almacen.saveEditData()
                                                            ).then(function () {
                                                                datasource_pedidosAnteriores
                                                                    .store()
                                                                    .update(
                                                                        pedidoSel.idPedido,
                                                                        patchRequestHandler(pedido)
                                                                    )
                                                                    .done(function () {
                                                                        hayPrendas_Peticion = false;
                                                                        hayPrendas_Stock = false;

                                                                        $.when(
                                                                            datasource_pedidos_stock.reload(),
                                                                            datasource_pedidos.reload()
                                                                        ).then(function () {
                                                                            //Seteamos los cambios en la variable global pedidosSel para que no detecte que siga habiendo cambios.
                                                                            pedidoSel.idTipoPedido =
                                                                                pedido.idTipoPedido;
                                                                            pedidoSel.fecha = pedido.fecha;
                                                                            pedidoSel.observaciones =
                                                                                pedido.observaciones;

                                                                            notify({
                                                                                message:
                                                                                    getTrad(
                                                                                        "aviso_C_RegistroActualizado"
                                                                                    ),
                                                                                type: "success",
                                                                                displayTime: "2500",
                                                                                closeOnClick: true,
                                                                            });

                                                                            $("#dxDatagrid_pedidosAnteriores")
                                                                                .dxDataGrid("instance")
                                                                                .refresh(true);
                                                                            button.component.option("disabled", false);

                                                                            if (
                                                                                !hayPrendas_Peticion &&
                                                                                !hayPrendas_Stock
                                                                            ) {
                                                                                var gridAnteriores = $(
                                                                                    "#dxDatagrid_pedidosAnteriores"
                                                                                ).dxDataGrid("instance");
                                                                                gridAnteriores.repaint();
                                                                                gridAnteriores.refresh();

                                                                                $("#dxResponsiveBox_filtros")
                                                                                    .dxResponsiveBox("instance")
                                                                                    .option(
                                                                                        "items",
                                                                                        get_dxResponsiveBox_filtros_items()
                                                                                    );
                                                                                dxDataGrid_NuevoPedido.option(
                                                                                    "noDataText",
                                                                                    getTrad("alerta_pedidoSinPrendas")
                                                                                );
                                                                            } else {
                                                                                $("#dxResponsiveBox_filtros")
                                                                                    .dxResponsiveBox("instance")
                                                                                    .option(
                                                                                        "items",
                                                                                        get_dxResponsiveBox_filtros_items()
                                                                                    );

                                                                                let dxDataGrid_NuevoPedido =
                                                                                    $(
                                                                                        "#dxDataGrid_NuevoPedido"
                                                                                    ).dxDataGrid("instance");
                                                                                let dxDataGrid_NuevoPedido_almacen = $(
                                                                                    "#dxDataGrid_NuevoPedido_almacen"
                                                                                ).dxDataGrid("instance");

                                                                                if (dxDataGrid_NuevoPedido)
                                                                                    dxDataGrid_NuevoPedido.repaint();
                                                                                if (dxDataGrid_NuevoPedido_almacen)
                                                                                    dxDataGrid_NuevoPedido_almacen.repaint();
                                                                            }

                                                                            dxLoadPanel_GridsNuevoPedido.hide();
                                                                            $("#dxDatagrid_pedidosAnteriores")
                                                                                .dxDataGrid("instance")
                                                                                .option("disabled", false);
                                                                            dxToolbar_filtros_nuevoPed.option(
                                                                                "disabled",
                                                                                false
                                                                            );
                                                                        });
                                                                    });
                                                            });
                                                        } else {
                                                            notify({
                                                                message: getTrad("alerta_SinCambios"),
                                                                type: "error",
                                                                displayTime: "2500",
                                                                closeOnClick: true,
                                                            });
                                                            button.component.option("disabled", false);
                                                        }
                                                    } else {
                                                        notify({
                                                            message: "Debe seleccionar una entidad",
                                                            type: "error",
                                                            displayTime: "2000",
                                                            closeOnClick: true,
                                                        });
                                                    }
                                                }, 0);
                                            },
                                        },
                                    },
                                ],
                            });
                    },
                },
                {
                    location: [
                        {
                            row: 2,
                            col: 0,
                            screen: "xs sm md lg xl",
                        },
                    ],
                    template: function () {
                        let prendasPeticion = $.map(datasource_pedidos.items(), function (item) {
                            return item.items;
                        });
                        let prendasStock = $.map(datasource_pedidos_stock.items(), function (item) {
                            return item.items;
                        });
                        let isDosGrids = prendasPeticion.length > 0 && prendasStock.length > 0;

                        let pantalla_height = $("#Pedidos").height();
                        let dxForm_height = 96;

                        let observaciones_height = 75;
                        let observacionesTit_height = 38 + 20;

                        let finalHeight = entidadSel?.enableObservacionesPedido
                            ? pantalla_height - dxForm_height - observaciones_height - observacionesTit_height
                            : pantalla_height - dxForm_height;

                        var scrollView = $("<div id='dxScrollView_nuevoPedido' />");
                        let tituloPrendasStock = $("<div />")
                            .addClass("d-flex flex-column justify-content-begin contenedorSeparadoGrids")
                            .append(
                                $("<div />")
                                    .addClass("tituloSeparadorGrids pl-2 pt-2 font-size-xxs")
                                    .text("PRENDAS POR ALMACÉN".toUpperCase()),
                                $("<div />")
                                    .addClass("subTituloSeparadorGrids pb-2 font-size-xxxs")
                                    .text("Indicar la cantidad de prendas existentes en el almacén.")
                            );

                        let tituloPrendasPeticion = $("<div />")
                            .addClass("d-flex flex-column justify-content-begin contenedorSeparadoGrids")
                            .append(
                                $("<div />")
                                    .addClass("tituloSeparadorGrids pl-2 pt-2 font-size-xxs")
                                    .text("PRENDAS POR PETICIÓN".toUpperCase()),
                                $("<div />")
                                    .addClass("subTituloSeparadorGrids pb-2 font-size-xxxs")
                                    .text("Indicar la cantidad de prendas que se envían o solicitan.")
                            );
                        let contenedor = $("<div class='he-100' />");

                        tituloPrendasStock.attr("style", "display: none !important");
                        tituloPrendasPeticion.attr("style", "display: none !important");

                        grid_nuevoPedido.dxDataGrid("instance").option("visible", hayPrendas_Peticion);
                        grid_nuevoPedido_almacen.dxDataGrid("instance").option("visible", hayPrendas_Stock);

                        if (hayPrendas_Stock)
                            grid_nuevoPedido_almacen
                                .dxDataGrid("instance")
                                .columnOption("delete", "visible", hayPrendas_Peticion);

                        if (!hayPrendas_Stock && !hayPrendas_Peticion) {
                            contenedor.height(finalHeight);
                            grid_nuevoPedido.dxDataGrid("instance").option("visible", true);
                            if (isDosGrids) {
                                grid_nuevoPedido_almacen.dxDataGrid("instance").option("visible", true);
                            } else {
                                grid_nuevoPedido.dxDataGrid("instance").option("visible", true);
                            }
                        }

                        if (hayPrendas_Stock && hayPrendas_Peticion) tituloPrendasPeticion.css({ "margin-top": 30 });

                        if (hayPrendas_Stock) {
                            tituloPrendasStock.css({ display: "block" });
                            contenedor.append(tituloPrendasStock, grid_nuevoPedido_almacen_padre);
                        }
                        if (hayPrendas_Peticion) {
                            tituloPrendasPeticion.css({ display: "block" });
                            contenedor.append(tituloPrendasPeticion);
                        }

                        if (get_isMovil(COMPONENT.props.resolucion)) return contenedor.append(grid_nuevoPedido_padre);

                        contenedor.append(grid_nuevoPedido_padre).appendTo(scrollView);
                        scrollView.dxScrollView({
                            width: "100%",
                            height: finalHeight,
                            direction: "vertical",
                            showScrollbar: "always",
                        });

                        return scrollView;
                    },
                },
                {
                    visible: entidadSel?.enableObservacionesPedido ?? false,
                    location: [
                        {
                            row: 3,
                            col: 0,
                            screen: "lg xl",
                        },
                    ],
                    template: function (e, index, item) {
                        item.append(
                            $("<div id='tit_observaciones' style='height:38px' />")
                                .css("marginBottom", "10px")
                                .css("marginTop", "10px")
                                .addClass("font-size")
                                .text(getTrad("observaciones"))
                        );

                        item.append(
                            $("<div id='taObservaciones' />").dxTextArea({
                                height: "75px",
                                width: "100%",
                                value: pedidoSel.observaciones,
                                onValueChanged: function (e) {
                                    pedidoSel.observaciones = e.value;
                                },
                            })
                        );
                    },
                },
            ];
        }

        function nuevoPedido() {
            let dxLoadPanel_GridsNuevoPedido = $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel("instance");
            let dxToolbar_filtros_nuevoPed = $("#dxToolbar_filtros_nuevoPed").dxToolbar("instance");
            let gridAnteriores = $("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance");

            gridAnteriores.option("disabled", true);
            dxToolbar_filtros_nuevoPed.option("disabled", true);
            dxLoadPanel_GridsNuevoPedido.show();

            if (pedidoSel.idPedido) {
                gridAnteriores.option("selectedRowKeys", []);
                gridAnteriores.repaint();
            }

            set_nuevoPedido();

            if (datasource_entidades.items().length === 1) {
                // Si solo hay una entidad no se habre el selector y se refresca directamente
                if (
                    $("#dxDropDownBox_lista_compañias").dxList("instance") !== null &&
                    $("#dxDropDownBox_lista_compañias").dxList("instance") !== undefined
                )
                    $("#dxDropDownBox_lista_compañias").dxList("instance").selectItem(0);
            }

            var gridPedidos = $("#dxDataGrid_NuevoPedido").dxDataGrid("instance");
            gridPedidos.beginUpdate();
            gridPedidos.cancelEditData();
            gridPedidos.option("noDataText", getTrad("sinDatos"));

            if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid")) {
                let dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance");
                dxDataGrid_NuevoPedido_almacen.beginUpdate();
                dxDataGrid_NuevoPedido_almacen.cancelEditData();
            }

            $.when(datasource_pedidos_stock.reload(), datasource_pedidos.reload())
                .then(function () {
                    let dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance");
                    let dxResponsiveBox_filtros = $("#dxResponsiveBox_filtros").dxResponsiveBox("instance");
                    dxResponsiveBox_filtros.option("items", get_dxResponsiveBox_filtros_items());
                    dxResponsiveBox_filtros.beginUpdate();

                    gridPedidos.endUpdate();

                    if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid")) {
                        dxDataGrid_NuevoPedido_almacen = $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance");
                        dxDataGrid_NuevoPedido_almacen.endUpdate();
                    }

                    if (datasource_entidades.items().length > 1) {
                        // Si hay más de una entidad
                        $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").open();
                    }

                    $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option("value", new Date());
                    $("#taObservaciones").dxTextArea("instance").option("value", "");
                    $("#taObservaciones").dxTextArea("instance").option("disabled", false);

                    if (datasource_entidades.items().length > 1) {
                        entidadSel = null; // Si hay más de una entidad
                        datasource_tipoPedido.reload();
                    } else if (datasource_tipoPedido.items().length > 0) {
                        $("#dxSelectBox_tipoPedido")
                            .dxSelectBox("instance")
                            .option("value", datasource_tipoPedido.items()[0].idTipoPedido);
                    }

                    if ($("#dxAccordion_opcionesPedido").dxAccordion("instance") !== undefined)
                        $("#dxAccordion_opcionesPedido").dxAccordion("instance").expandItem(0);

                    gridPedidos.option("editing.allowDeleting", pedidoSel && pedidoSel.idEstadoPedido != 4);
                    gridPedidos.option(
                        "editing.allowUpdating",
                        pedidoSel == null || (pedidoSel != null && pedidoSel.idEstadoPedido != 4)
                    );

                    $("#dxDataGrid_NuevoPedido").dxDataGrid(
                        "columnOption",
                        "cantidad",
                        "cssClass",
                        pedidoSel.idEstadoPedido != 4 ? "dx-Cell_Editable" : ""
                    );
                    $("#dxDataGrid_NuevoPedido").dxDataGrid(
                        "columnOption",
                        "stockActual",
                        "cssClass",
                        pedidoSel.idEstadoPedido != 4 ? "dx-Cell_Editable" : ""
                    );

                    dxResponsiveBox_filtros.endUpdate();

                    if (gridPedidos) gridPedidos.repaint();
                    if (dxDataGrid_NuevoPedido_almacen) dxDataGrid_NuevoPedido_almacen.repaint();

                    dxToolbar_filtros_nuevoPed.option("disabled", false);
                    dxLoadPanel_GridsNuevoPedido.hide();
                    gridAnteriores.option("disabled", false);
                })
                .fail(() => {
                    dxToolbar_filtros_nuevoPed.option("disabled", false);
                    dxLoadPanel_GridsNuevoPedido.hide();
                    gridAnteriores.option("disabled", false);
                });
        }
    });

    function set_nuevoPedido() {
        let uniqueEnti = datasource_entidades.items().length == 1;
        hayPrendas_Peticion = false;
        hayPrendas_Stock = false;
        entidadSel = uniqueEnti ? datasource_entidades.items()[0] : null;
        pedidoSel = {
            idPedido: null,
            idEntidad: entidadSel ? entidadSel.idEntidad : null,
            idTipoPedido: null,
            fecha: null,
            observaciones: null,
            idUsuarioCreador: null,
        };
    }

    function nuevoPedido_HasChanges() {
        let isPedidoAlmacen_hasChanges =
            $("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid") &&
            $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance").getController("editing").hasChanges();
        let isPedido_hasChanges =
            $("#dxDataGrid_NuevoPedido").data("dxDataGrid") &&
            $("#dxDataGrid_NuevoPedido").dxDataGrid("instance").getController("editing").hasChanges();

        if (guardando) {
            return false;
        }

        if (!pedidoSel.idPedido) {
            // Para que al cambiar de pedido estando creando uno nuevo, te salte la ventana de descarte/cancelar.
            if (isPedido_hasChanges || isPedidoAlmacen_hasChanges) {
                return true;
            }
            return false;
        }

        var hasChanges = false;

        hasChanges = isPedido_hasChanges || isPedidoAlmacen_hasChanges;
        hasChanges =
            hasChanges ||
            formatDate($("#dxDateBox_dbFechaPedido").dxDateBox("instance").option("value")) !==
                formatDate(pedidoSel.fecha);
        hasChanges =
            hasChanges || $("#taObservaciones").dxTextArea("instance").option("value") !== pedidoSel.observaciones;
        hasChanges =
            hasChanges ||
            $("#dxSelectBox_tipoPedido").dxSelectBox("instance").option("value") !== pedidoSel.idTipoPedido;

        return hasChanges;
    }

    function guardarPedido(tblPrendaNPedido) {
        let dxToolbar_filtros_nuevoPed = $("#dxToolbar_filtros_nuevoPed").dxToolbar("instance");
        let gridAnteriores = $("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance");

        gridAnteriores.option("disabled", true);
        dxToolbar_filtros_nuevoPed.option("disabled", true);

        var pedido = {};
        pedido.idEntidad = $("#dxDropDownBox_selCompaEnti").dxDropDownBox("instance").option("value");

        let entidadesFiltradas = [];
        if (pedidoSel.idEntidad) {
            entidadesFiltradas = $.grep(datasource_entidades.items(), function (item) {
                return item.idEntidad === pedidoSel.idEntidad;
            });
        }

        pedido.idEstadoPedido = 1; // Pendiente
        if (tblPrendaNPedido.length === 0) {
            //Sin prendas = isCerrado
            pedido.isCerrado = true;
        }

        pedido.idTipoPedido = $("#dxSelectBox_tipoPedido").dxSelectBox("instance").option("value");

        pedido.fecha = $("#dxDateBox_dbFechaPedido").dxDateBox("instance").option("value");
        pedido.fecha.setTime(pedido.fecha.getTime() - 2 * 60 * 60 * 1000); //TODO -2 horas horario de verano servidor

        pedido.observaciones = $("#taObservaciones").dxTextArea("instance").option("value");
        pedido.tblPrendaNPedido = tblPrendaNPedido;
        pedido.idUsuarioCreador = USER.idUsuario;

        if (pedido.idTipoPedido !== null) {
            let dxLoadPanel_GridsNuevoPedido = $("#dxLoadPanel_GridsNuevoPedido").dxLoadPanel("instance");
            dxLoadPanel_GridsNuevoPedido.show();
            datasource_pedidosAnteriores
                .store()
                .insert(pedido)
                .done(function (data, idPedido) {
                    guardando = true;

                    var dxDatagrid_pedidosAnteriores = $("#dxDatagrid_pedidosAnteriores").dxDataGrid("instance");
                    dxDatagrid_pedidosAnteriores.refresh().done(function () {
                        notify({
                            message: getTrad("aviso_C_RegistroInsertado") + " - " + data.codigo + ".",
                            type: "success",
                            displayTime: "2500",
                            closeOnClick: true,
                        });

                        if ($("#dxDataGrid_NuevoPedido").data("dxDataGrid"))
                            $("#dxDataGrid_NuevoPedido").dxDataGrid("instance").cancelEditData();
                        if ($("#dxDataGrid_NuevoPedido_almacen").data("dxDataGrid"))
                            $("#dxDataGrid_NuevoPedido_almacen").dxDataGrid("instance").cancelEditData();

                        gridAnteriores.option("disabled", false);
                        dxToolbar_filtros_nuevoPed.option("disabled", false);

                        dxDatagrid_pedidosAnteriores.option("selectedRowKeys", [idPedido]);
                        $("#btnGuardarPedido").dxButton("instance").option("disabled", false);
                        dxLoadPanel_GridsNuevoPedido.hide();
                    });
                });
        } else {
            notify({
                message: getTrad("campoNecesario") + " - " + getTrad("tipoPedido") + ".",
                type: "error",
                displayTime: "2500",
                closeOnClick: true,
            });
            $("#btnGuardarPedido").dxButton("instance").option("disabled", false);
            gridAnteriores.option("disabled", false);
            dxToolbar_filtros_nuevoPed.option("disabled", false);
        }
        return;
    }

    //IMPRESIÓN
    function CustomizeParameterEditors(report, s, e) {
        if (report === "albaran_pedidos") {
            if (e.parameter.name === "idPedido") {
                //INVISIBLES
                e.info.editor = $.extend({}, e.info.editor);
                e.info.visible = false;
            }
        }
    }

    return deferred.promise();
}
