import { Switch } from "devextreme-react";
import React, { Component } from "react";
import { connect } from "react-redux";

class SwitchGrid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch
        style={{ margin: "auto" /* padding: '0 10px' */ }}
        defaultValue={this.props.data.value}
        switchedOnText="Activo"
        switchedOffText="Desactivo"
        onValueChanged={(e) => {
          this.props.data.setValue(e.value);

          // dataSource_tblPosicionNAreaLavanderiaNLavanderia.store().update(idPosicionNAreaLavanderiaNLavanderia, patchRequestHandler(data)).done((data) => {
          //   // Se necesita hacer algo?
          // })
        }}
      />
    );
  }
}

export default SwitchGrid;
