import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatNumber,
  formatDate_parameter,
  endOfMonth,
  startOfMonth,
  dxMensajePregunta,
  filtroTiempo_Resize,
  dxRangeSelector_PosicionValor_nuevoMarker,
  create_rangeSelector,
  init_CustomMarker_RangeSelector,
  tooltipControl_creacion,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";
import config from "devextreme/core/config";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var fechaHasta = null,
    datasource_categoriasMaquinas = null;
  //#endregion

  //#region Datasource

  var datasource_filtroTiempo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblIncidencia",
      key: "idIncidencia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
      onLoaded: function (data) {
        data.push({ fechaIncidencia: new Date() });
        return data;
      },
    }),
    select: ["fechaIncidencia"],
    sort: ["fechaIncidencia asc"],
    pageSize: ["1"],
    map: function (dataItem) {
      return {
        arg: dataItem.fechaIncidencia,
        val: 1,
      };
    },
  });

  var datasource_spSelectMaquinas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infGestionMantenimiento_spSelectMaquinas",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxRangeSelector_filtroTiempo = $(
          "#dxRangeSelector_filtroTiempo",
        ).dxRangeSelector("instance");

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fechaSel = formatDate_parameter(fechaHasta);
        request.params.isAnual =
          typeof dxRangeSelector_filtroTiempo !== "undefined"
            ? dxRangeSelector_filtroTiempo.option("is_anual")
            : false;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var data_extend = $.extend(true, [], data),
        numMaqNoCat = $.grep(data, function (maq) {
          return !maq.idCategoriaMaquina;
        }).length;

      $.each(data_extend, function (index, item) {
        if (item.restrictiva && item.idCategoriaMaquina) {
          if (item.segundosParada > item.segundosFechaSel)
            item.segundosParada = item.segundosFechaSel;
        } else if (item.restrictiva === false && item.idCategoriaMaquina) {
          if (item.segundosParada > item.segundosFechaSel)
            item.segundosParada =
              item.segundosFechaSel * (item.pesoMaquina / 100);
          else
            item.segundosParada =
              item.segundosParada * (item.pesoMaquina / 100);
        } else if (!item.idCategoriaMaquina) {
          if (item.segundosParada > item.segundosFechaSel)
            item.segundosParada =
              item.segundosFechaSel * (100 / numMaqNoCat / 100);
          else
            item.segundosParada =
              item.segundosParada * (100 / numMaqNoCat / 100);
        }
      });
      set_datasource_categoriasMaquinas(data_extend);
      return data;
    },
  });

  var datasource_spSelectKpi = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "infGestionMantenimiento_spSelectKpi",
      key: "kpi",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxRangeSelector_filtroTiempo = $(
          "#dxRangeSelector_filtroTiempo",
        ).dxRangeSelector("instance");

        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fechaSel = formatDate_parameter(fechaHasta);

        request.params.isAnual =
          typeof dxRangeSelector_filtroTiempo !== "undefined"
            ? dxRangeSelector_filtroTiempo.option("is_anual")
            : false;
      },
      version: 4,
    }),
  });

  var datasource_tblSistemaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblSistemaMaquina",
      key: "idSistemaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    sort: "orden",
    postProcess: (data) => {
      data.push({
        idSistemaMaquina: -1,
        denominacion: getTrad("sinCategoria").toUpperCase(),
      });
      return data;
    },
  });

  //#endregion

  $.when(datasource_filtroTiempo.load(), datasource_tblSistemaMaquina.load())
    .then(function (items) {
      if (items.length > 1) {
        fechaHasta = new Date(items[0][items.length - 1].arg);

        $.when(
          datasource_spSelectMaquinas.load(),
          datasource_spSelectKpi.load(),
        ).then(function () {
          $("#InfGestionMantenimiento #dxContainer").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            items: [
              {
                baseSize: "auto",
                template: function (e, index, item) {
                  item.css("margin-bottom", "15px");
                  item.append(
                    $("<div id='flexBox' />").css({
                      display: "flex",
                      "flex-direction": "row",
                      "flex-wrap": "wrap",
                      "justify-content": "space-between",
                    }),
                  );

                  $.each(
                    datasource_spSelectKpi.items(),
                    function (index, itemKpi) {
                      $("#flexBox").append(
                        $("<div />")
                          .css({
                            display: "flex",
                            "flex-direction": "column",
                            "flex-wrap": "wrap",
                            border: "1px solid gainsboro",
                            "box-shadow": "1px 1px 6px -3px #777",
                            "border-radius": "3px",
                            margin: "5px",
                          })
                          .append(
                            $("<span />")
                              .css({
                                "text-align": "center",
                                padding: 5,
                                "background-color": "#EDB637",
                                color: "white",
                                "font-size": "16px",
                                "font-weight": "lighter",
                              })
                              .addClass("dx-datagrid-headers")
                              .text(itemKpi.kpi),
                            $("<div id='" + itemKpi.idKpi + "_kpi' />")
                              .addClass("no-border")
                              .dxDataGrid({
                                dataSource: [itemKpi],
                                columns: [
                                  {
                                    dataField: "mes",
                                    caption: getTrad("mes"),
                                    alignment: "center",
                                    allowSorting: false,
                                    calculateCellValue: function (rowData) {
                                      if (rowData.idKpi !== 3)
                                        return (
                                          formatNumber(rowData.mes, 2) + "%"
                                        );
                                      else
                                        return formatNumber(
                                          rowData.mes,
                                          2,
                                          "currency",
                                          config().defaultCurrency,
                                        );
                                    },
                                  },
                                  {
                                    dataField: "año",
                                    caption: getTrad("año"),
                                    alignment: "center",
                                    allowSorting: false,
                                    calculateCellValue: function (rowData) {
                                      if (rowData.idKpi !== 3)
                                        return (
                                          formatNumber(rowData.año, 2) + "%"
                                        );
                                      else
                                        return formatNumber(
                                          rowData.año,
                                          2,
                                          "currency",
                                          config().defaultCurrency,
                                        );
                                    },
                                  },
                                ],
                              }),
                          )
                          .css({ flex: "0 0 250px" }),
                      );
                    },
                  );
                },
              },
              {
                // GRIDS
                ratio: 1,
                template: function (e, index, item) {
                  item.append(
                    $("<div />").dxBox({
                      direction: "row",
                      align: "space-around",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          ratio: 4,
                          template: function (e, index, item) {
                            item.css("margin-right", "15px");
                            item.append(
                              $(
                                "<div id='dxDataGrid_categoriasMaquina' />",
                              ).dxDataGrid({
                                dataSource: datasource_categoriasMaquinas,
                                //Propiedades
                                headerFilter: {
                                  visible: true,
                                },
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                  showAllText: getTrad("todos"),
                                },
                                height: "100%",
                                columnAutoWidth: true,
                                selection: {
                                  mode: "single",
                                },
                                paging: {
                                  enabled: false,
                                },
                                hoverStateEnabled: true,
                                columns: [
                                  {
                                    dataField: "idCategoriaMaquina",
                                    visible: false,
                                    sortIndex: 0,
                                    sortOrder: "asc",
                                    sortingMethod: function (value1, value2) {
                                      if (value1 === -1) return 1;
                                      if (value2 === -1) return -1;
                                      return 0;
                                    },
                                  },
                                  {
                                    dataField: "denominacion",
                                    caption: getTrad("denominacion"),
                                    minWidth: 200,
                                    sortIndex: 1,
                                    sortOrder: "asc",
                                    allowSorting: false,
                                    allowHeaderFiltering: false,
                                    alignment: "left",
                                  },
                                  {
                                    dataField: "coste",
                                    caption: getTrad("coste"),
                                    format: {
                                      style: "currency",
                                      maximumFractionDigits: 2,
                                      currency: config().defaultCurrency,
                                    },
                                    width: 150,
                                    allowFiltering: false,
                                    allowSorting: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "segundosParada",
                                    caption: getTrad("disponibilidad"),
                                    allowFiltering: false,
                                    allowSorting: false,
                                    width: 120,
                                    alignment: "center",
                                    calculateCellValue: function (rowData) {
                                      if (rowData.segundosParada === 0)
                                        return formatNumber(1, 2, "percent");
                                      return formatNumber(
                                        1 -
                                          rowData.segundosParada /
                                            rowData.segundosFechaSel,
                                        2,
                                        "percent",
                                      );
                                    },
                                  },
                                ],
                                summary: {
                                  recalculateWhileEditing: true,
                                  totalItems: [
                                    {
                                      column: "denominacion",
                                      displayFormat:
                                        getTrad("total").toUpperCase(),
                                    },
                                    {
                                      column: "coste",
                                      summaryType: "sum",
                                      displayFormat: "{0}",
                                      customizeText: function (data) {
                                        return formatNumber(
                                          data.value,
                                          2,
                                          "currency",
                                          config().defaultCurrency,
                                        );
                                      },
                                    },
                                  ],
                                },
                                //Eventos
                                onRowPrepared: function (e) {
                                  if (e.rowType === "data") {
                                    e.rowElement.css("cursor", "pointer");
                                  }
                                },
                                onSelectionChanged: function (e) {
                                  var dxDataGrid_maquinas = $(
                                    "#dxDataGrid_maquinas",
                                  ).dxDataGrid("instance");
                                  if (e.selectedRowKeys.length > 0) {
                                    var filteredData = query(
                                      datasource_spSelectMaquinas.items(),
                                    )
                                      .filter(function (dataItem) {
                                        var idCategoriaMaquinaSel =
                                          typeof e.selectedRowKeys[0] !==
                                          "undefined"
                                            ? e.selectedRowKeys[0]
                                                .idCategoriaMaquina
                                            : null;
                                        return idCategoriaMaquinaSel !== -1
                                          ? dataItem.idCategoriaMaquina ===
                                              idCategoriaMaquinaSel
                                          : !dataItem.idCategoriaMaquina;
                                      })
                                      .toArray();

                                    dxDataGrid_maquinas.option(
                                      "dataSource",
                                      filteredData,
                                    );
                                  } else
                                    dxDataGrid_maquinas.option(
                                      "dataSource",
                                      [],
                                    );
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              }),
                            );
                          },
                        },
                        {
                          ratio: 3,
                          template: function (e, index, item) {
                            item.append(
                              $("<div id='dxDataGrid_maquinas' />").dxDataGrid({
                                //Propiedades
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                  showAllText: getTrad("todos"),
                                },
                                height: "100%",
                                columnAutoWidth: true,
                                paging: {
                                  enabled: false,
                                },
                                columns: [
                                  {
                                    dataField: "denominacion",
                                    caption: getTrad("denominacion"),
                                    width: "40%",
                                    sortIndex: 1,
                                    sortOrder: "asc",
                                    allowSorting: false,
                                    allowHeaderFiltering: false,
                                    alignment: "left",
                                  },
                                  {
                                    dataField: "idSistemaMaquina",
                                    caption: getTrad("sistemaMaquina"),
                                    width: "20%",
                                    allowHeaderFiltering: false,
                                    alignment: "left",
                                    lookup: {
                                      dataSource:
                                        datasource_tblSistemaMaquina.items(),
                                      valueExpr: "idSistemaMaquina",
                                      displayExpr: "denominacion",
                                    },
                                  },
                                  {
                                    dataField: "etiqueta",
                                    caption: getTrad("etiqueta"),
                                    allowSorting: false,
                                    width: 70,
                                  },
                                  {
                                    dataField: "coste",
                                    caption: getTrad("coste"),
                                    format: {
                                      style: "currency",
                                      maximumFractionDigits: 2,
                                      currency: config().defaultCurrency,
                                    },
                                    width: 110,
                                    allowFiltering: false,
                                    alignment: "center",
                                    allowSorting: false,
                                  },
                                  {
                                    dataField: "segundosParada",
                                    caption: getTrad("disponibilidad"),
                                    allowFiltering: false,
                                    allowSorting: false,
                                    width: 120,
                                    alignment: "center",
                                    calculateCellValue: function (rowData) {
                                      if (rowData.segundosParada === 0)
                                        return formatNumber(1, 2, "percent");
                                      return formatNumber(
                                        1 -
                                          rowData.segundosParada /
                                            rowData.segundosFechaSel,
                                        2,
                                        "percent",
                                      );
                                    },
                                  },
                                ],
                                summary: {
                                  recalculateWhileEditing: true,
                                  totalItems: [
                                    {
                                      column: "denominacion",
                                      displayFormat:
                                        getTrad("total").toUpperCase(),
                                    },
                                    {
                                      column: "coste",
                                      summaryType: "sum",
                                      displayFormat: "{0}",
                                      customizeText: function (data) {
                                        return formatNumber(
                                          data.value,
                                          2,
                                          "currency",
                                          config().defaultCurrency,
                                        );
                                      },
                                    },
                                  ],
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              }),
                            );
                          },
                        },
                      ],
                    }),
                  );
                },
              },
              {
                // FILTRO TIEMPO
                baseSize: 130,
                template: function (itemData, itemIndex, itemElement) {
                  itemElement.append(
                    create_rangeSelector(
                      datasource_filtroTiempo,
                      false,
                      false,
                      function (e) {
                        fechaHasta = e.component.option("valueHasta");
                        COMPONENT.loadPanel_show(true);
                        $.when(
                          datasource_spSelectMaquinas.load(),
                          datasource_spSelectKpi.load(),
                        ).then(function () {
                          COMPONENT.loadPanel_hide();
                          $.each(
                            datasource_spSelectKpi.items(),
                            function (index, item) {
                              $("#" + item.idKpi + "_kpi")
                                .dxDataGrid("instance")
                                .option("dataSource", [item]);
                            },
                          );

                          $("#dxDataGrid_categoriasMaquina")
                            .dxDataGrid("instance")
                            .option(
                              "dataSource",
                              datasource_categoriasMaquinas,
                            );
                        });
                      },
                      function () {
                        var ultimaFecha = new Date(
                          items[0][items.length - 1].arg,
                        );
                        $("#dxRangeSelector_filtroTiempo")
                          .dxRangeSelector("instance")
                          .option({
                            "scale.tickInterval": "month",
                            "scale.minRange": { days: null, months: 1 },
                            "scale.maxRange": { days: null, months: 1 },
                            "scale.marker.visible": true,
                            value: [
                              startOfMonth(ultimaFecha),
                              endOfMonth(new Date(ultimaFecha)),
                            ],
                          });
                      },
                      function () {
                        $("#dxRangeSelector_filtroTiempo")
                          .dxRangeSelector("instance")
                          .option({
                            "scale.tickInterval": "year",
                            "scale.marker.visible": false,
                          });
                        init_CustomMarker_RangeSelector(
                          $("#dxRangeSelector_filtroTiempo"),
                        );
                        dxRangeSelector_PosicionValor_nuevoMarker(
                          $("#dxRangeSelector_filtroTiempo"),
                          true,
                          true,
                        );
                      },
                    ),
                  );

                  var ultimaFecha = new Date(items[0][items.length - 1].arg);
                  $("#dxRangeSelector_filtroTiempo")
                    .dxRangeSelector("instance")
                    .option({
                      "scale.tickInterval": "month",
                      "scale.minRange": { days: null, months: 1 },
                      "scale.maxRange": { days: null, months: 1 },
                      value: [
                        startOfMonth(ultimaFecha),
                        endOfMonth(new Date(ultimaFecha)),
                      ],
                    });
                  init_CustomMarker_RangeSelector(
                    $("#dxRangeSelector_filtroTiempo"),
                  );
                  dxRangeSelector_PosicionValor_nuevoMarker(
                    $("#dxRangeSelector_filtroTiempo"),
                    true,
                    true,
                  );

                  $("#dxButton_diario")
                    .dxButton("instance")
                    .option("visible", getTrad("anual"));
                  tooltipControl_creacion(
                    $("#dxButton_diario"),
                    getTrad("anual"),
                  );

                  $("#dxButton_diario").removeClass("dxButton_activo");
                  $("#dxButton_mensual").addClass("dxButton_activo");
                },
              },
            ],
          });
        });
      } else {
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "dxButton_sinLavanderia_error",
          ],
        ]);
      }
    })
    .always(function () {
      setTimeout(function () {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  function set_datasource_categoriasMaquinas(maquinas) {
    datasource_categoriasMaquinas = query(maquinas)
      .select([
        "idMaquina",
        "idCategoriaMaquina",
        "denoCatMaquina",
        "coste",
        "segundosParada",
        "segundosFechaSel",
        "restrictiva",
        "pesoMaquina",
      ])
      .groupBy(function (dataItem) {
        return [
          dataItem.idCategoriaMaquina,
          dataItem.denoCatMaquina,
          dataItem.segundosFechaSel,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("coste")
          .done(function (result) {
            resultItem = {
              idCategoriaMaquina: dataItem.key[0] ? dataItem.key[0] : -1,
              denominacion: dataItem.key[1]
                ? dataItem.key[1]
                : getTrad("maquinasSinCategoria").toUpperCase(),
              segundosFechaSel: dataItem.key[2],
              coste: result,
            };
          });
        query(dataItem.items)
          .sum("segundosParada")
          .done(function (result) {
            resultItem = {
              idCategoriaMaquina: dataItem.key[0] ? dataItem.key[0] : -1,
              denominacion: dataItem.key[1]
                ? dataItem.key[1]
                : getTrad("maquinasSinCategoria").toUpperCase(),
              coste: resultItem.coste,
              segundosFechaSel: dataItem.key[2],
              segundosParada: result,
            };
          });
        return resultItem;
      })
      //#endregion
      .toArray();
  }

  return deferred.promise();
}
