import React, { Fragment } from "react";
import { connect } from "react-redux";

//Layout
//Js

//Componentes
import PieChart, {
  Series as PieSeries,
  Label as PieLabel,
  Connector,
  Legend,
} from "devextreme-react/pie-chart";
import { authHeader, errorHandler, getTrad } from "helpers";

import "./css.scss";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { connectionConstants } from "constants";
import LottieIcon from "components/LottieIcon";

import PopupPreguntasGrafico from "./PopupPreguntasGrafico";
import { Size } from "devextreme-react/bar-gauge";

class Seleccion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pieChart_data: [],
      idPreguntaSel: null,
    };

    this.dx_PieChart_encuestas_REF = React.createRef();
  }

  render() {
    const { pieChart_data, idPreguntaSel } = this.state;
    const { idEncuestaSel, idCompañiaSel, idLavanderiaSel } = this.props;

    return (
      <>
        {pieChart_data.length == 0 ? (
          <div className="he-100 w-100">
            <div className="h2 text-center w-100 p-4">
              {getTrad("sinDatos")}
            </div>
            <LottieIcon height="30%" width="30%" icon={"buscarEncuesta"} />
          </div>
        ) : (
          <div className="d-flex justify-content-between flex-wrap">
            {pieChart_data.map((data) => {
              return (
                <div
                  key={data.idPregunta + "_id_container_chart"}
                  onClick={() =>
                    this.setState({ idPreguntaSel: data.idPregunta })
                  }
                  className="cardGrafico pointer"
                >
                  <PieChart
                    ref={this.dx_PieChart_encuestas_REF}
                    key={data.idPregunta}
                    dataSource={data.respuestas}
                    sizeGroup="piesGroup"
                    title={{
                      text: data.pregunta,
                      font: { size: 18 },
                      horizontalAlignment: "left",
                    }}
                    animation={true}
                    palette="Soft Blue"
                    paletteExtensionMode={"blend"}
                  >
                    <Size width={500} />
                    <Legend
                      verticalAlignment="bot"
                      horizontalAlignment="center"
                    />
                    <PieSeries
                      argumentField="respuesta"
                      valueField={"porcentaje"}
                    >
                      <PieLabel
                        visible={true}
                        position="rows"
                        format="fixedPoint"
                        backgroundColor="none"
                        customizeText={this.customizeLabel}
                      >
                        <Connector visible={true}></Connector>
                      </PieLabel>
                    </PieSeries>
                  </PieChart>
                </div>
              );
            })}
          </div>
        )}
        <PopupPreguntasGrafico
          idPreguntaSel={idPreguntaSel}
          idEncuestaSel={idEncuestaSel}
          idLavanderiaSel={idLavanderiaSel}
          idCompañiaSel={idCompañiaSel}
          visible={idPreguntaSel !== null}
          onValueChange={this.onValueChange}
        />
      </>
    );
  }

  customizeLabel = (pointInfo) => {
    return pointInfo.valueText + "%";
  };

  onValueChange = (newState) => {
    this.setState(newState);
  };

  get dx_PieChart_encuestas() {
    return this.dx_PieChart_encuestas_REF.current.instance;
  }

  dataSource_getDatosEncuestas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas",
      key: "idPregunta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_getDatosEncuestas_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_getDatosEncuestas_beforeSend(request) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idCompañia = idCompañiaSel;
    request.params.group = "seleccion";

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  }

  formatDataSource = (data) => {
    const { idEncuestaSel } = this.props;

    if (idEncuestaSel == null) {
      this.setState({ pieChart_data: [] });
      return;
    }

    if (data.length === 0) {
      this.setState({ pieChart_data: [] });
      return;
    }

    const preguntasAgrupadas = [];
    data.forEach((itemPregunta) => {
      const idPregunta = itemPregunta.idPregunta;
      const { descripcion, idOpcion, porcentaje, pregunta } = itemPregunta;

      let grupoPreguntas = preguntasAgrupadas.find(
        (grupo) => grupo.idPregunta === idPregunta,
      );

      if (!grupoPreguntas) {
        grupoPreguntas = { idPregunta: idPregunta, pregunta, respuestas: [] };
        preguntasAgrupadas.push(grupoPreguntas);
      }

      grupoPreguntas.respuestas.push({
        respuesta: descripcion,
        idOpcion,
        porcentaje: porcentaje * 100,
      });
    });

    this.setState({ pieChart_data: preguntasAgrupadas });
  };

  componentDidMount() {
    this.dataSource_getDatosEncuestas.load().done((data) => {
      this.formatDataSource(data);
    });
  }

  componentDidUpdate(prevProps) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;

    if (
      prevProps.idEncuestaSel !== idEncuestaSel ||
      prevProps.idLavanderiaSel !== idLavanderiaSel ||
      prevProps.idCompañiaSel !== idCompañiaSel
    ) {
      this.dataSource_getDatosEncuestas.reload().done((data) => {
        this.formatDataSource(data);
      });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(Seleccion);
