import React, { Component, createRef } from "react";
import { connect } from "react-redux";

import Popup from "devextreme-react/popup";
import Form, { Item } from "devextreme-react/form";
import { Button } from "devextreme-react";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import ContadorVirtual from "./ContadorVirtual";
import {
  authHeader,
  dxMensajePregunta,
  errorHandler,
  getTrad,
  patchRequestHandler,
} from "../../../../../helpers";
import { connectionConstants } from "../../../../../constants";

const formData_default = {
  idRecursoContador: null,
  denominacion: "",
  idUnidadMedida_Contador: null,
  valorPulsoEnergyHub: 1,
  factorNormalizacion: null,
  poderCalorifico: null,
  idUnidadMedida_Kpi: null,
  margen: null,
  idLavanderia: null,
  activo: true,
  eliminado: false,
  idCategoriaRecurso: null,
  idGrupoEnergetico: null,
  densidad: null,
  sumaInforme: false,
  isVisibleDashboard: false,
  isVirtual: false,
  isAutomatico: false,
  tblRecursoVirtual_CalculoidRecursoVirtualNavigation: [],
};

class PopupRecursosEnergeticos extends Component {
  constructor(props) {
    super(props);

    let formData = this.init_formData;
    this.state = {
      recursoEnergeticoMod: formData,
      isVirtual: formData.isVirtual,
      isCalefaccion: formData.idGrupoEnergetico === 3,
    };

    this.form_REF = createRef();
    this.onValueChanged_idGrupoEnergetico =
      this.onValueChanged_idGrupoEnergetico.bind(this);
    this.validationCallback_sumaInforme =
      this.validationCallback_sumaInforme.bind(this);
    this.dxMensajePregunta_virtual = this.dxMensajePregunta_virtual.bind(this);
  }

  get form() {
    return this.form_REF.current.instance;
  }

  get init_formData() {
    const { recursoEnergeticoSel, lavanderia } = this.props;

    if (
      recursoEnergeticoSel != null &&
      Object.keys(recursoEnergeticoSel).length > 0
    ) {
      const recursoEnergeticoSel_ = JSON.parse(
        JSON.stringify(recursoEnergeticoSel),
      );

      return Object.keys(recursoEnergeticoSel_)
        .filter((key) => formData_default[key] !== undefined)
        .reduce(
          (a, field) => ({ ...a, [field]: recursoEnergeticoSel_[field] }),
          {},
        );
    } else {
      return {
        ...formData_default,
        ...{ idLavanderia: lavanderia.idLavanderia },
      };
    }
  }

  datasource_tblRecursoContador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecursoContador",
      key: "idRecursoContador",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      onInserted: () => {
        this.datasource_tblRecursoContador_onInserted();
      },
      onUpdated: () => {
        this.datasource_tblRecursoContador_onUpdated();
      },
      version: 4,
    }),
  });

  datasource_tblRecursoContador_onInserted = () => {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  datasource_tblRecursoContador_onUpdated = () => {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  };
  datasource_tblRecursoContador_sumaInforme = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecursoContador",
      key: "idRecursoContador",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      onLoading: (loadOptions) =>
        this.datasource_tblRecursoContador_sumaInforme_onLoading(loadOptions),
      version: 4,
    }),
    select: ["idRecursoContador"],
  });

  datasource_tblRecursoContador_sumaInforme_onLoading = (loadOptions) => {
    const { lavanderia } = this.props;
    const { recursoEnergeticoMod } = this.state;

    const customFilter = [
      ["idLavanderia", "=", lavanderia.idLavanderia],
      "and",
      ["eliminado", "=", false],
      "and",
      ["idGrupoEnergetico", "=", recursoEnergeticoMod?.idGrupoEnergetico ?? -1],
      "and",
      [
        "idCategoriaRecurso",
        "=",
        recursoEnergeticoMod?.idCategoriaRecurso ?? -1,
      ],
      "and",
      [
        "idRecursoContador",
        "<>",
        recursoEnergeticoMod?.idRecursoContador ?? -1,
      ],
      "and",
      ["sumaInforme", "=", true],
    ];

    if (loadOptions.filter)
      loadOptions.filter = [loadOptions.filter, "and", customFilter];
    else loadOptions.filter = customFilter;
  };

  colCountByScreen = { xl: 5, lg: 5, md: 2, sm: 1, xs: 1 };
  colCountByScreen_general = { xl: 3, lg: 2, md: 1, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  save_buttonOptions = {
    text: getTrad("guardar"),
    type: "success",
    useSubmitBehavior: true,
  };

  label_form = {
    denominacion: { text: getTrad("denominacion") },
    factorNormalizacion: { text: "Factor normalizacion" },
    poderCalorifico: { text: "Poder calorífico" },
    valorPulsoEnergyHub: { text: getTrad("valorPulsoEnergyHub") },
    margen: { text: getTrad("margenLectura") },
    densidad: { text: "Densidad" },
    sumaInforme: { text: "Suma en informe" },
    idCategoriaRecurso: { text: getTrad("categoriaRecurso") },
    isVisibleDashboard: { text: "Visible en dashboard" },
    isVirtual: { text: "Tipo contador" },
    isAutomatico: { text: "Automático / Manual" },
    idUnidadMedida_Contador: { text: getTrad("udMedidaCont") },
    idUnidadMedida_Kpi: { text: "Unidad medida KPI" },
    idGrupoEnergetico: { text: getTrad("grupoEnergetico") },
    tblRecursoVirtual_CalculoidRecursoVirtualNavigation: { visible: false },
  };

  editorOptions_sumaInforme = {
    elementAttr: { id: "dxSwitch_sumaInforme" },
    switchedOffText: "No suma en informe",
    switchedOnText: "Suma en informe",
  };

  editorOptions_isVisibleDashboard = {
    switchedOffText: "No visible",
    switchedOnText: "Visible",
  };

  editorOptions_isVirtual = {
    switchedOffText: "Físico",
    switchedOnText: "Virtual",
    onValueChanged: (e) => {
      this.onValueChanged_isVirtual(e);
    },
  };

  editorOptions_isAutomatico = {
    switchedOffText: "Manual",
    switchedOnText: "Automático",
  };

  editorOptions_3Decimales = {
    format: { style: "decimal", minimumFractionDigits: 3 },
    min: 1,
    max: 99999999,
  };

  editorOptions_4Decimales = {
    format: { style: "decimal", minimumFractionDigits: 4 },
    min: 0.0001,
    max: 99999999,
  };

  editorOptions_4Decimales_min0 = {
    format: { style: "decimal", minimumFractionDigits: 4 },
    min: 0,
    max: 99999999,
  };

  editorOptions_idGrupoEnergetico = {
    valueExpr: "idGrupoEnergetico",
    displayExpr: "denominacion",
    onValueChanged: (e) => {
      this.onValueChanged_idGrupoEnergetico(e);
    },
  };

  editorOptions_idCategoriaRecurso = {
    valueExpr: "idCategoriaRecurso",
    displayExpr: "denominacion",
  };

  editorOptions_idUnidadMedida_ContadorNavigation = {
    valueExpr: "idUnidadMedida",
    displayExpr: "denominacion",
  };

  editorOptions_idUnidadMedida_KpiNavigation = {
    valueExpr: "idUnidadMedida",
    displayExpr: "denominacion",
  };

  render() {
    const { visible } = this.props;
    const { recursoEnergeticoMod, isVirtual, isCalefaccion } = this.state;

    const title = !recursoEnergeticoMod?.idRecursoContador
      ? "Nuevo recurso energético"
      : recursoEnergeticoMod?.denominacion ?? "";
    return (
      <Popup
        visible={visible}
        title={title}
        width={"70%"}
        height={"80%"}
        onHiding={this.onHiding}
      >
        <form
          className="he-100 d-flex flex-column pl-2 pr-2"
          action="submit"
          onSubmit={this.submit_form}
        >
          <Form
            ref={this.form_REF}
            formData={recursoEnergeticoMod}
            scrollingEnabled={true}
            screenByWidth={this.screenByWidth}
            colCountByScreen={this.colCountByScreen}
            labelLocation="top"
            height={"100%"}
            validationGroup="validationGroup_form"
          >
            <Item
              itemType={"group"}
              colCountByScreen={this.colCountByScreen_general}
              colSpan={4}
            >
              <Item
                dataField={"denominacion"}
                label={this.label_form["denominacion"]}
                colSpan={2}
                isRequired
              />
              <Item
                dataField={"idGrupoEnergetico"}
                label={this.label_form["idGrupoEnergetico"]}
                editorType={"dxSelectBox"}
                editorOptions={this.editorOptions_idGrupoEnergetico}
                disabled={isVirtual}
                colSpan={1}
                isRequired
              />
              <Item
                dataField={"idCategoriaRecurso"}
                label={this.label_form["idCategoriaRecurso"]}
                editorType={"dxSelectBox"}
                editorOptions={this.editorOptions_idCategoriaRecurso}
                disabled={isVirtual}
                colSpan={1}
                isRequired
              />
              <Item
                dataField={"valorPulsoEnergyHub"}
                label={this.label_form["valorPulsoEnergyHub"]}
                editorType={"dxNumberBox"}
                editorOptions={this.editorOptions_4Decimales}
                colSpan={1}
              />
              <Item
                dataField={"idUnidadMedida_Contador"}
                label={this.label_form["idUnidadMedida_Contador"]}
                editorType={"dxSelectBox"}
                editorOptions={
                  this.editorOptions_idUnidadMedida_ContadorNavigation
                }
                disabled={isVirtual}
                colSpan={1}
                isRequired={!isVirtual}
              />
              <Item
                dataField={"factorNormalizacion"}
                label={this.label_form["factorNormalizacion"]}
                editorType={"dxNumberBox"}
                editorOptions={this.editorOptions_4Decimales}
                colSpan={1}
                isRequired
              />
              <Item
                dataField={"idUnidadMedida_Kpi"}
                label={this.label_form["idUnidadMedida_Kpi"]}
                editorType={"dxSelectBox"}
                editorOptions={this.editorOptions_idUnidadMedida_KpiNavigation}
                colSpan={1}
                isRequired
              />
              <Item
                dataField={"margen"}
                label={this.label_form["margen"]}
                editorType={"dxNumberBox"}
                editorOptions={this.editorOptions_3Decimales}
                disabled={isVirtual}
                colSpan={1}
                isRequired={!isVirtual}
              />
              <Item
                dataField={"poderCalorifico"}
                label={this.label_form["poderCalorifico"]}
                editorType={"dxNumberBox"}
                editorOptions={this.editorOptions_4Decimales_min0}
                disabled={!isCalefaccion}
                colSpan={1}
              />
              <Item
                dataField={"densidad"}
                label={this.label_form["densidad"]}
                editorType={"dxNumberBox"}
                editorOptions={this.editorOptions_4Decimales_min0}
                disabled={!isCalefaccion}
                colSpan={1}
              />
            </Item>
            <Item
              itemType={"group"}
              caption={"Opciones"}
              colSpan={1}
              colCount={1}
            >
              <Item
                dataField={"sumaInforme"}
                colSpan={1}
                label={this.label_form["sumaInforme"]}
                editorType={"dxSwitch"}
                editorOptions={this.editorOptions_sumaInforme}
                //isRequired={true}
              >
                {/* <AsyncRule
                                ignoreEmptyValue={true}
                                reevaluate={true}
                                message="Ya hay un contador marcado como sumaInforme del mismo tipo."
                                validationCallback={this.validationCallback_sumaInforme} /> */}
              </Item>
              <Item
                dataField={"isVisibleDashboard"}
                label={this.label_form["isVisibleDashboard"]}
                editorType={"dxSwitch"}
                editorOptions={this.editorOptions_isVisibleDashboard}
                colSpan={1}
              />
              <Item
                dataField={"isVirtual"}
                label={this.label_form["isVirtual"]}
                editorType={"dxSwitch"}
                editorOptions={this.editorOptions_isVirtual}
                colSpan={1}
              />
              <Item
                dataField={"isAutomatico"}
                label={this.label_form["isAutomatico"]}
                editorType={"dxSwitch"}
                editorOptions={this.editorOptions_isAutomatico}
                colSpan={1}
                disabled={isVirtual}
              />
            </Item>
            <Item
              visible={isVirtual}
              itemType={"group"}
              caption={"Calculo contador virtual"}
              colSpan={5}
              colCount={1}
            >
              <Item
                colSpan={3}
                dataField={
                  "tblRecursoVirtual_CalculoidRecursoVirtualNavigation"
                }
                label={
                  this.label_form[
                    "tblRecursoVirtual_CalculoidRecursoVirtualNavigation"
                  ]
                }
              >
                <ContadorVirtual
                  recursoEnergeticoMod={recursoEnergeticoMod}
                  setRecursoEnergeticoMod={this.setRecursoEnergeticoMod}
                />
              </Item>
            </Item>
          </Form>
          <div className="d-flex w-100 justify-content-end mt-3">
            <Button
              {...this.save_buttonOptions}
              validationGroup="validationGroup_form"
            />
          </div>
        </form>
      </Popup>
    );
  }

  onValueChanged_idGrupoEnergetico = (e) => {
    const { recursoEnergeticoMod, isCalefaccion } = this.state;
    let _this = this;
    const isCalefaccion_ = e.value === 3;

    if (isCalefaccion !== isCalefaccion_) {
      recursoEnergeticoMod.densidad = recursoEnergeticoMod.poderCalorifico =
        null;
      this.setState({ isCalefaccion: isCalefaccion_ }, () => {
        _this.form.repaint();
      });
    }
  };

  onValueChanged_isVirtual = (e) => {
    const { recursoEnergeticoMod } = this.state;
    if (
      !e.value &&
      recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation
        .length > 0
    ) {
      this.dxMensajePregunta_virtual(e.component, e.value, e.previousValue);
    } else {
      if (e.value == true) {
        recursoEnergeticoMod.isAutomatico = true;
      }
      this.setState({ isVirtual: e.value });
    }
  };

  dxMensajePregunta_virtual(component, value, previousValue) {
    let { recursoEnergeticoMod } = this.state;
    let _this = this;

    dxMensajePregunta(getTrad("preg_PerderCambios"), [
      [
        getTrad("aceptar"),
        function () {
          recursoEnergeticoMod.tblRecursoVirtual_CalculoidRecursoVirtualNavigation =
            [];
          recursoEnergeticoMod.isAutomatico = true;
          _this.setState({ recursoEnergeticoMod, isVirtual: value });
        },
        "danger",
      ],
      [
        getTrad("cancelar"),
        function () {
          component.option("value", previousValue);
        },
      ],
    ]);
  }

  setRecursoEnergeticoMod = (recursoEnergeticoMod) => {
    this.setState({ recursoEnergeticoMod });
  };

  validationCallback_sumaInforme() {
    let _this = this;
    return new Promise((resolve) => {
      _this.datasource_tblRecursoContador_sumaInforme.load().done((items) => {
        resolve(items.length === 0);
      });
    });
  }

  submit_form = async (e) => {
    e.preventDefault();
    const { endEdit_recursoContador } = this.props;
    const { recursoEnergeticoMod } = this.state;

    if (recursoEnergeticoMod.idRecursoContador === null) {
      //INSERT
      let objInsert = { ...recursoEnergeticoMod };
      delete objInsert.idRecursoContador;

      this.datasource_tblRecursoContador
        .store()
        .insert(objInsert)
        .done(() => {
          endEdit_recursoContador(true);
        });
    } else {
      //UPDATE
      const bk_formData = this.init_formData;
      let diff = Object.keys(recursoEnergeticoMod).reduce((diff, key) => {
        if (
          JSON.stringify(bk_formData[key]) ===
          JSON.stringify(recursoEnergeticoMod[key])
        )
          return diff;
        return {
          ...diff,
          [key]: recursoEnergeticoMod[key],
        };
      }, {});

      this.datasource_tblRecursoContador
        .store()
        .update(
          recursoEnergeticoMod.idRecursoContador,
          patchRequestHandler(diff),
        )
        .done(() => {
          endEdit_recursoContador(true);
        });
    }
  };

  onHiding = () => {
    this.props.endEdit_recursoContador(false);
  };

  componentDidUpdate(prevProps) {
    const {
      recursoEnergeticoSel,
      tblGrupoEnergetico,
      tblCategoriaRecurso,
      tblUnidadMedida,
    } = this.props;
    //DATASOURCE SELECT BOX
    let repaintForm = false;
    if (
      this.editorOptions_idGrupoEnergetico.items === undefined &&
      tblGrupoEnergetico.length > 0
    ) {
      this.editorOptions_idGrupoEnergetico.items = tblGrupoEnergetico;
      repaintForm = true;
    }
    if (
      this.editorOptions_idCategoriaRecurso.items === undefined &&
      tblCategoriaRecurso.length > 0
    ) {
      this.editorOptions_idCategoriaRecurso.items = tblCategoriaRecurso;
      repaintForm = true;
    }
    if (
      this.editorOptions_idUnidadMedida_ContadorNavigation.items ===
        undefined &&
      tblUnidadMedida.length > 0
    ) {
      this.editorOptions_idUnidadMedida_ContadorNavigation.items =
        tblUnidadMedida;
      repaintForm = true;
    }
    if (
      this.editorOptions_idUnidadMedida_KpiNavigation.items === undefined &&
      tblUnidadMedida.length > 0
    ) {
      this.editorOptions_idUnidadMedida_KpiNavigation.items = tblUnidadMedida;
      repaintForm = true;
    }
    if (repaintForm) {
      this.form.repaint();
    }

    if (prevProps.recursoEnergeticoSel !== recursoEnergeticoSel) {
      let formData = this.init_formData;
      this.setState(
        {
          recursoEnergeticoMod: formData,
          isVirtual: formData.isVirtual,
          isCalefaccion: formData.idGrupoEnergetico === 3,
        },
        () => {
          this.form.repaint();
          if (recursoEnergeticoSel?.isVirtual) {
            this.datasource_tblRecursoContador_sumaInforme.reload();
          }
        },
      );
    }
  }
}
const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
});

export default connect(mapStateToProps)(PopupRecursosEnergeticos);
