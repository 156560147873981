import { Button, Popup } from "devextreme-react";
import DataGrid, { Column, ColumnChooser, SearchPanel, Lookup as DataGrid_Lookup } from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/autocomplete";
import notify from "devextreme/ui/notify";
import { dxMensajePregunta, getTrad } from "helpers";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    datasource_tblMoneda,
    datasource_tblAdmPresupuestoVenta,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import { datasource_tblCliente } from "pages/Administracion/shared/model";
import { getArticulos, getDefaultIvaId } from "pages/Administracion/shared/functions";
import { getClearPresupuesto } from "pages/Administracion/PresupuestoVenta/model";
import { getClearPedido } from "pages/Administracion/PedidoCliente/model";

const CargarPresupuestoVenta = forwardRef(
    ({ cargarPresupuestoPopup_visibility, setCargarPresupuestoPopup_visibility, setPresupuesto, hasEditData }, ref) => {
        const [selectedItem, setSelectedItem] = useState();
        useImperativeHandle(ref, () => ({
            quitarPresupuestoSeleccionado,
        }));

        const closePopup = () => {
            setSelectedItem(null);
            setCargarPresupuestoPopup_visibility(false);
        };

        const confirmarPresupuesto = () => {
            setPresupuesto({
                idAdmPresupuestoVenta: selectedItem.idAdmPresupuestoVenta,
                codigo: selectedItem.codigo,
                idAdmCliente: selectedItem.idAdmCliente,
                idAdmFormaPago: selectedItem.idAdmFormaPago,
                idMoneda: selectedItem.idMoneda,
                idAdmTipoCambio: selectedItem.idAdmTipoCambio,
                tasaCambio: selectedItem.tasaCambio,
                idTipoPresupuesto: selectedItem.idTipoPresupuesto,
                idIvaNPais: selectedItem.idIvaNPais,
                descuento: selectedItem.descuento,
                idAdmTipoDescuento: selectedItem.idAdmTipoDescuento,
                tblArticuloNAdmPedidoCliente: getArticulos(
                    selectedItem.tblArticuloNAdmPresupuestoVenta
                ),
            });
        }

        const presupuestoSeleccionado = () => {
            if (selectedItem) {
                if(hasEditData(getClearPedido())) {
                    dxMensajePregunta("Se van a sobreescribir datos al asociar el presupuesto. ¿Quieres continuar? ", [
                        [
                            getTrad("aceptar"),
                            () => {
                                confirmarPresupuesto();
                                closePopup();
                            },
                            "danger",
                        ],
                        [getTrad("cancelar"), () => {}],
                    ]);
                } else {
                    confirmarPresupuesto();
                    closePopup();
                }
            } else {
                notify({
                    message: getTrad("seleccionePedido"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        };

        const onSelectionChanged = (e) => {
            setSelectedItem(e.currentSelectedRowKeys[0]);
        };

        const quitarPresupuestoSeleccionado = () => {
            const defaultFormData = getClearPresupuesto()
            dxMensajePregunta("Se van a vaciar algunos campos. ¿Está seguro de quitar el presupuesto asociado?", [
                [
                    getTrad("aceptar"),
                    () => {
                        setPresupuesto({...defaultFormData})
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        };

        const onRowPrepared_tblPedidos = (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };

        return (
            <Popup
                showTitle={true}
                title={getTrad("presupuestos")}
                visible={cargarPresupuestoPopup_visibility}
                showCloseButton={true}
                closeOnOutsideClick={true}
                // height={"fit-content"}
                height={"600px"}
                width={"60%"}
                onHiding={closePopup}
            >
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("seleccionar")} type="success" onClick={presupuestoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                    <Button text={getTrad("quitarSeleccion")} type="default" onClick={quitarPresupuestoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} onClick={closePopup} />
                </ToolbarItem>
                <div className={"d-flex he-100 mt-4"}>
                    <DataGrid
                        dataSource={datasource_tblAdmPresupuestoVenta}
                        height={"100%"}
                        width={"100%"}
                        showRowLines
                        columnsAutoWidth
                        repaintChangesOnly
                        rowAlternationEnabled
                        showColumnLines={false}
                        remoteOperations={false}
                        //   onToolbarPreparing={onToolbarPreparing}
                        hoverStateEnabled={true}
                        onRowPrepared={onRowPrepared_tblPedidos}
                        selection={{ mode: "single" }}
                        selectedRowKeys={[selectedItem]}
                        onSelectionChanged={onSelectionChanged}
                    >
                        <ColumnChooser enabled />
                        <SearchPanel visible width={240} />
                        <Column
                            dataField={"idTipoPresupuesto"}
                            caption={getTrad("idTipoPresupuesto")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) => TipoElemento(e.data.data.idTipoPresupuesto)}
                        />
                        <Column
                            dataField={"codigo"}
                            caption={getTrad("codigo")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"fechaCreacion"}
                            caption={getTrad("fecha")}
                            dataType={"date"}
                            alignment={"left"}
                            minWidth={150}
                            sortOrder={"desc"}
                            format={"dd/MM/yyyy"}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmPresupuestoVenta_Estado"}
                            caption={getTrad("estado")}
                            dataType={"number"}
                            alignment={"left"}
                            defaultFilterValue={1}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) =>
                                ChipEstado(e.data.data.idAdmPresupuestoVenta_Estado, "", "presupuesto")
                            }
                        />
                        <Column
                            dataField={"idAdmCliente"}
                            caption={getTrad("cliente")}
                            editorOptions={sharedEditorOptions.idAdmCliente}
                            dataType={"number"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblCliente.store()}
                                valueExpr="idAdmCliente"
                                displayExpr="nombreFiscal"
                            />
                        </Column>
                        <Column
                            dataField={"idMoneda"}
                            caption={getTrad("moneda")}
                            dataType={"number"}
                            visible={false}
                            // format={formats.currency}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblMoneda.store()}
                                valueExpr="idMoneda"
                                displayExpr="codigo"
                            />
                        </Column>
                        <Column
                            dataField={"tasaCambio"}
                            caption={getTrad("tasaCambio")}
                            dataType={"number"}
                            format={sharedEditorOptions.tasaCambio.format}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"descuento"}
                            caption={getTrad("descuento")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idIvaNPais"}
                            caption={getTrad("iva")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmFormaPago"}
                            caption={getTrad("formaPago")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"observaciones"}
                            caption={getTrad("observaciones")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        {/* <Column
            dataField={"total"}
            caption={getTrad("total")}
            visible={true}
            width={100}
            // calculateCellValue={calculateTotal}
            calculateDisplayValue={calculateTotalValueForDisplay}
          /> */}
                    </DataGrid>
                    {/* <List
          dataSource={presupuestos}
          displayExpr={"codigo"}
          selectionMode="single"
          selectedItem={selectedItem}
          onSelectionChanged={onSelectionChanged}
        /> */}
                </div>
            </Popup>
        );
    }
);

export default CargarPresupuestoVenta;
