import { connectionConstants } from "../constants";
import { errorHandler, authHeader } from "../helpers";

import $ from "jquery";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

export const tasaCambioService = {
    loadTasaCambio,
};

function loadTasaCambio(fechaDesde, fechaHasta) {
    var deferred = $.Deferred();

    const datasource_tblTasaCambio = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTasaCambio",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            onLoading: (loadOptions) => {
                if (fechaDesde != null || fechaHasta != null) {
                    loadOptions.filter = [];
                }

                if (fechaDesde != null) {
                    loadOptions.filter.push(["fecha", ">=", fechaDesde]);
                }
                if (fechaHasta != null) {
                    loadOptions.filter.push(["fecha", "<=", fechaHasta]);
                }
            },
            version: 4,
        }),
        sort: [{ selector: "fecha", desc: true }],
    });

    datasource_tblTasaCambio
        .reload()
        .then((data) => {
            data = data.map((tasa) => {
                tasa.fecha = new Date(tasa.fecha);
                tasa.fecha.setHours(0, 0, 0, 0);
                return tasa;
            });

            deferred.resolve(data);
        })
        .catch((error) => {
            deferred.reject(error);
        });

    return deferred.promise();
}
