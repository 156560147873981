import React, { useState } from "react";

import { connect } from "react-redux";

import DataGrid from "./components/DataGrid";
import PageTitle from "layout/AppMain/PageTitle";
import Popup from "./components/Popup";
import RecambiosProvider from "./components/RecambiosProvider";
import { getInitialState } from "./shared/utils";
import { getNombreFormulario } from "helpers";
import { useRef } from "react";

const Recambios = ({ idioma, user }) => {
  const buscadorRef = useRef(null);

  const [isVisible_Popup, setIsVisible_Popup] = useState(false);
  const [recambioSel, setRecambioSel] = useState(getInitialState());

  const heading = getNombreFormulario({ props: { idioma, user } });

  const onSelectionChanged = (recambio) => {
    if (!recambio) {
      setRecambioSel(getInitialState());
    } else {
      setRecambioSel(recambio);
    }

    setIsVisible_Popup(true);
  };

  const onHiding = () => setIsVisible_Popup(false);

  const onHidden = () => setRecambioSel(getInitialState());

  return (
    <>
      <PageTitle heading={heading} />
      <div className={"media-body"}>
        <div className={"formContainer scrollbar-container"}>
          <RecambiosProvider
            recambioSel={recambioSel}
            setRecambioSel={setRecambioSel}
          >
            <DataGrid
              buscadorRef={buscadorRef}
              onSelectionChanged={onSelectionChanged}
            />
            <Popup
              buscadorRef={buscadorRef}
              visible={isVisible_Popup}
              onHiding={onHiding}
              onHidden={onHidden}
            />
          </RecambiosProvider>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Global, Authentication }) => ({
  idioma: Global.idioma,
  user: Authentication.user,
});

export default connect(mapStateToProps)(Recambios);
