import React, { Fragment } from "react";

import { getTrad } from "../../../../../helpers/traduccion";

const { Popup, SelectBox, Button, DateBox, Tooltip, Form } = require("devextreme-react");
import { Item } from "devextreme-react/form";

import "./SettingsPopup.css";
import { dateTime_hourMinute, durationToDatetime, formatDate_noTime_parameter, formatDate_parameter } from "helpers";
import { ToolbarItem } from "devextreme-react/autocomplete";
import { patchCuadrantePersonalRecalcular, postChangesIntoCuadrantePersonal } from "../../services/apiCalls";

const hourFormat = { hour: "2-digit", minute: "2-digit", second: "2-digit", hour12: false };

class SettingsPopup extends React.Component {
    dx_SelectBox_itemsEstado;
    constructor(props) {
        super(props);
        this.state = {
            popupVisible: false,
            drillDownDataSource: {},
            popupMode: false,
            hasJornada: false,
        };
    }

    // #region TRADUCCIONES

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    // #endregion

    updatePopupState = (params) => {
        this.setState({ ...this.state, ...params });
    };

    isFormValid = () => {
        const { prev } = this.state;
        return (
            (prev.idEstado && prev.idTurno && prev.horaEntrada && prev.horaSalida) ||
            (prev.idEstado &&
                prev.idTurno &&
                !prev.horaEntrada &&
                !prev.horaSalida &&
                prev.idEstado !== 3 &&
                prev.idEstado !== 4)
        );
    };

    // onClick_cancelar
    // onHiding
    dxPopup_onHiding = () => {
        this.setState({ popupVisible: false });
    };

    dxPopup_onHidden = () => {
        this.setState({
            drillDownDataSource: {},
            prev: {},
            eventos: [],
            isMasivo: false,
            hasJornada: false,
        });
    };

    dxSelectBox_estado_onValueChanged = (e) => {
        const { prev, eventos, isMasivo } = this.state;
        const { tblTurno } = this.props;

        const isTrabajado = e.value === 3 || e.value === 4;
        const turno = tblTurno.find(
            (x) =>
                (prev?.idTurno && x.idTurno === prev.idTurno) ||
                (eventos && eventos.length > 0 && eventos[0].idTurno && x.idTurno === eventos[0].idTurno)
        );

        if (prev?.idEstado !== e.value) {
            if (isMasivo) {
                let newEventos = eventos.map((x) => {
                    return {
                        ...x,
                        idEstado: e.value,
                        horaEntrada: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaEntrada)) : null,
                        horaSalida: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaSalida)) : null,
                    };
                });

                this.setState({
                    prev: {
                        ...prev,
                        idEstado: e.value,
                        idTurno: turno.idTurno,
                        horaEntrada: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaEntrada)) : null,
                        horaSalida: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaSalida)) : null,
                    },
                    eventos: newEventos,
                });
            } else {
                this.setState({
                    prev: {
                        ...prev,
                        idEstado: e.value,
                        horaEntrada: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaEntrada)) : null,
                        horaSalida: isTrabajado ? dateTime_hourMinute(durationToDatetime(turno.horaSalida)) : null,
                    },
                });
            }
        }
    };

    dxSelectBox_turno_onValueChanged = (e) => {
        const { prev, eventos, isMasivo } = this.state;
        if (prev?.idTurno !== e.value) {
            const { tblTurno } = this.props;
            let turno = tblTurno.find((x) => x.idTurno === e.value);

            if (isMasivo) {
                let newEventos = eventos.map((x) => {
                    return {
                        ...x,
                        idTurno: turno.idTurno,
                        horaEntrada: dateTime_hourMinute(durationToDatetime(turno.horaEntrada)),
                        horaSalida: dateTime_hourMinute(durationToDatetime(turno.horaSalida)),
                    };
                });

                this.setState({
                    prev: {
                        ...prev,
                        idTurno: turno.idTurno,
                        horaEntrada: dateTime_hourMinute(durationToDatetime(turno.horaEntrada)),
                        horaSalida: dateTime_hourMinute(durationToDatetime(turno.horaSalida)),
                    },
                    eventos: newEventos,
                });
            } else {
                this.setState({
                    prev: {
                        ...prev,
                        idTurno: turno.idTurno,
                        horaEntrada: dateTime_hourMinute(durationToDatetime(turno.horaEntrada)),
                        horaSalida: dateTime_hourMinute(durationToDatetime(turno.horaSalida)),
                    },
                });
            }
        }
    };

    dxDateBox_horaEntrada_onValueChanged = (e) => {
        const { prev, eventos, isMasivo } = this.state;
        if (isMasivo) {
            let newEventos = eventos.map((x) => {
                return {
                    ...x,
                    horaEntrada:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                };
            });
            this.setState({
                prev: {
                    ...prev,
                    horaEntrada:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                },
                eventos: newEventos,
            });
        } else {
            this.setState({
                prev: {
                    ...prev,
                    horaEntrada:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                },
            });
        }
    };

    dxDateBox_horaSalida_onValueChanged = (e) => {
        const { prev, eventos, isMasivo } = this.state;

        if (isMasivo) {
            let newEventos = eventos.map((x) => {
                return {
                    ...x,
                    horaSalida:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                };
            });
            this.setState({
                prev: {
                    ...prev,
                    horaSalida:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                },
                eventos: newEventos,
            });
        } else {
            this.setState({
                prev: {
                    ...prev,
                    horaSalida:
                        e.value && e.value instanceof Date && !isNaN(e.value)
                            ? e.value.toLocaleDateString(undefined, hourFormat).split(" ").pop()
                            : e.value,
                },
            });
        }
    };

    dxButton_onClick_reset = () => {
        const { isMasivo, prev, eventos } = this.state;
        this.onReset(isMasivo ? eventos : prev);
        this.dxPopup_onHiding();
    };

    dxButton_onClick_save = () => {
        const { isMasivo, prev, eventos } = this.state;
        if (isMasivo || this.isFormValid()) {
            this.onSave(isMasivo ? eventos : prev);
            this.dxPopup_onHiding();
        }
    };

    onSave = (updateCard) => {
        const obj = {
            fecha: formatDate_noTime_parameter(updateCard.fecha),
            horaEntrada:
                !updateCard.horaEntrada || typeof updateCard.horaEntrada === "string"
                    ? updateCard.horaEntrada
                    : dateTime_hourMinute(updateCard.horaEntrada),
            horaSalida:
                !updateCard.horaSalida || typeof updateCard.horaSalida === "string"
                    ? updateCard.horaSalida
                    : dateTime_hourMinute(updateCard.horaSalida),
            idCuadrantePersonal: updateCard.idCuadrantePersonal,
            idEstado: updateCard.idEstado,
            idLavanderia: updateCard.idLavanderia,
            idPersona: updateCard.idPersona,
            idTurno: updateCard.idTurno,
        };

        const payload = [obj];

        if (this.state.saveHandler) this.state.saveHandler(() => postChangesIntoCuadrantePersonal(payload), obj);
        else postChangesIntoCuadrantePersonal(payload).then(() => this.props.CuadranteContext.reload());
    };

    onReset = (prev) => {
        patchCuadrantePersonalRecalcular([prev]).then((serverResponse) => {
            this.props.CuadranteContext.reload();
            this.setState({
                isVisible: false,
            });
        });
    };

    render = () => {
        let { popupVisible, popupTitle, prev, eventos, isMasivo, hasJornada } = this.state;
        const { tblTurno, tblCalendarioPersonal_estado, CuadranteContext } = this.props;

        return (
            <Popup
                visible={popupVisible}
                title={popupTitle}
                onHiding={this.dxPopup_onHiding}
                onHidden={this.dxPopup_onHidden}
                width={"fit-content"}
                height={300}
            >
                <div className="mb-3">
                    <Form height="100%" formData={prev} colCount={2} labelLocation="top">
                        <Item label={this.getTrad("estado")}>
                            <span className="dx-field-item-label-text">{this.getTrad("estado")}:</span>
                            <SelectBox
                                disabled={hasJornada}
                                stylingMode="underlined"
                                style={{ marginTop: "7px" }}
                                width={300}
                                dataSource={tblCalendarioPersonal_estado.map((x) => {
                                    if (x.idEstado === 8 && prev && prev.fecha) {
                                        x.disabled =
                                            CuadranteContext.tblCalendarioLavanderia.filter(
                                                (x) =>
                                                    x.idEstado === 8 &&
                                                    x.fecha === formatDate_noTime_parameter(prev.fecha)
                                            ).length === 0;
                                    } else {
                                        x.disabled = false;
                                    }
                                    return x;
                                })}
                                valueExpr={"idEstado"}
                                displayExpr={"denominacion"}
                                value={prev?.idEstado == 4 ? 3 : prev?.idEstado} //si se trata de un festivo trabajado, se muestra como dia trabajado en el popup
                                onValueChanged={this.dxSelectBox_estado_onValueChanged}
                                isValid={isMasivo || prev?.idEstado != null}
                            />
                        </Item>
                        <Item label={this.getTrad("turno")}>
                            <span className="dx-field-item-label-text">{this.getTrad("turno")}:</span>
                            <SelectBox
                                stylingMode="underlined"
                                style={{ marginTop: "7px" }}
                                width={300}
                                dataSource={tblTurno}
                                valueExpr={"idTurno"}
                                displayExpr={"denominacion"}
                                value={prev?.idTurno}
                                onValueChanged={this.dxSelectBox_turno_onValueChanged}
                                isValid={isMasivo || prev?.idTurno != null}
                            />
                        </Item>
                    </Form>
                </div>
                <div className="mb-3">
                    {(!prev?.idEstado || prev?.idEstado == 3 || prev?.idEstado == 4) && (
                        <Form height="100%" formData={prev} colCount={2} labelLocation="top">
                            <Item dataField="horaEntrada" label={this.getTrad("horaEntrada")} dataType="datetime">
                                <DateBox
                                    stylingMode="underlined"
                                    width="100%"
                                    type="time"
                                    format="HH:mm"
                                    showDropDownButton={false}
                                    useMaskBehavior={true}
                                    onValueChanged={this.dxDateBox_horaEntrada_onValueChanged}
                                    value={
                                        prev?.horaEntrada //!formData_addPersona ? null : formData_addPersona.horaEntrada
                                    }
                                    isValid={isMasivo || prev?.horaEntrada != null}
                                />
                            </Item>
                            <Item dataField="horaSalida" label={this.getTrad("horaSalida")} dataType="datetime">
                                <DateBox
                                    stylingMode="underlined"
                                    width="100%"
                                    type="time"
                                    format="HH:mm"
                                    showDropDownButton={false}
                                    useMaskBehavior={true}
                                    onValueChanged={this.dxDateBox_horaSalida_onValueChanged}
                                    value={
                                        prev?.horaSalida //!formData_addPersona ? null : formData_addPersona.horaEntrada
                                    }
                                    isValid={isMasivo || prev?.horaSalida != null}
                                />
                            </Item>
                        </Form>
                    )}
                </div>
                <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                    <div id="recalcular_button">
                        {!prev?.isFijado && (
                            <Tooltip
                                target={"#recalcular_button"}
                                showEvent={"mouseenter"}
                                hideEvent={"mouseleave"}
                                position={"right"}
                            >
                                <span>No se puede recalcular una previsión sin fijar.</span>
                            </Tooltip>
                        )}
                        <Button
                            type="default"
                            text={"Recalcular"}
                            disabled={!prev?.isFijado || hasJornada}
                            onClick={this.dxButton_onClick_reset}
                        />
                    </div>
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button type="success" text={getTrad("guardar")} onClick={this.dxButton_onClick_save} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} onClick={this.dxPopup_onHiding} />
                </ToolbarItem>
            </Popup>
        );
    };
}

export default SettingsPopup;
