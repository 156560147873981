export const sendSMS = {
  v: "5.5.5",
  fr: 30,
  ip: 0,
  op: 150,
  w: 512,
  h: 512,
  nm: "carousel_horizontal",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -405, ix: 10 },
            p: { a: 0, k: [235, 259, 0], ix: 2 },
            a: { a: 0, k: [7, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6, -28],
                        [6, -78],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [0],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.387], y: [1] },
                        o: { x: [0.556], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [100],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 25, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -315, ix: 10 },
            p: { a: 0, k: [275, 259, 0], ix: 2 },
            a: { a: 0, k: [7, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6, -28],
                        [6, -78],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [0],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.387], y: [1] },
                        o: { x: [0.556], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [100],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 25, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [255, 259, 0], ix: 2 },
            a: { a: 0, k: [7, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [6, -28],
                        [6, -78],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "tm",
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [0],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 1,
                  },
                  e: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.387], y: [1] },
                        o: { x: [0.556], y: [0] },
                        t: 0,
                        s: [0],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 7,
                        s: [100],
                      },
                      { t: 14, s: [100] },
                    ],
                    ix: 2,
                  },
                  o: { a: 0, k: 0, ix: 3 },
                  m: 1,
                  ix: 2,
                  nm: "Trim Paths 1",
                  mn: "ADBE Vector Filter - Trim",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 25, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "line 12",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 213.478, 0], ix: 2 },
            a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.292, y: 0.987 },
                    o: { x: 0.408, y: 0 },
                    t: 102,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.065, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [-5.065, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 121,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [181.935, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [181.935, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [77.957, -14.174],
                        [-77.958, -14.174],
                        [-85.044, -7.087],
                        [-85.044, 7.087],
                        [-77.958, 14.174],
                        [77.957, 14.174],
                        [85.044, 7.087],
                        [85.044, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [85.294, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 77,
          op: 150,
          st: 77,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "line 11",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
            a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.248, y: 0.992 },
                    o: { x: 0.48, y: 0 },
                    t: 94,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.284, -6.75],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [-5.284, 34.098],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 111,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [121.716, -6.902],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [121.716, 33.945],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [49.609, -14.174],
                        [-49.609, -14.174],
                        [-56.696, -7.087],
                        [-56.696, 7.087],
                        [-49.609, 14.174],
                        [49.609, 14.174],
                        [56.696, 7.087],
                        [56.696, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [56.946, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 77,
          op: 150,
          st: 77,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "line 10",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 213.478, 0], ix: 2 },
            a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.292, y: 0.987 },
                    o: { x: 0.408, y: 0 },
                    t: 96,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.065, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [-5.065, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 115,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [181.935, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [181.935, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [77.957, -14.174],
                        [-77.958, -14.174],
                        [-85.044, -7.087],
                        [-85.044, 7.087],
                        [-77.958, 14.174],
                        [77.957, 14.174],
                        [85.044, 7.087],
                        [85.044, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [85.294, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 71,
          op: 150,
          st: 71,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "line 9",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
            a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.248, y: 0.992 },
                    o: { x: 0.48, y: 0 },
                    t: 88,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.284, -6.75],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [-5.284, 34.098],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 105,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [121.716, -6.902],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [121.716, 33.945],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [49.609, -14.174],
                        [-49.609, -14.174],
                        [-56.696, -7.087],
                        [-56.696, 7.087],
                        [-49.609, 14.174],
                        [49.609, 14.174],
                        [56.696, 7.087],
                        [56.696, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [56.946, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 71,
          op: 150,
          st: 71,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "line 8",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 213.478, 0], ix: 2 },
            a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.292, y: 0.987 },
                    o: { x: 0.408, y: 0 },
                    t: 31,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.065, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [-5.065, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 50,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [181.935, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [181.935, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [77.957, -14.174],
                        [-77.958, -14.174],
                        [-85.044, -7.087],
                        [-85.044, 7.087],
                        [-77.958, 14.174],
                        [77.957, 14.174],
                        [85.044, 7.087],
                        [85.044, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [85.294, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6,
          op: 150,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "line 7",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
            a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.248, y: 0.992 },
                    o: { x: 0.48, y: 0 },
                    t: 23,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.284, -6.75],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [-5.284, 34.098],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 40,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [121.716, -6.902],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [121.716, 33.945],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [49.609, -14.174],
                        [-49.609, -14.174],
                        [-56.696, -7.087],
                        [-56.696, 7.087],
                        [-49.609, 14.174],
                        [49.609, 14.174],
                        [56.696, 7.087],
                        [56.696, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [56.946, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6,
          op: 150,
          st: 6,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "line 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 213.478, 0], ix: 2 },
            a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.292, y: 0.987 },
                    o: { x: 0.408, y: 0 },
                    t: 25,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.065, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [-5.065, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 44,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [181.935, -10.054],
                          [-11.706, -10.054],
                          [-11.706, 35.946],
                          [181.935, 35.946],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [77.957, -14.174],
                        [-77.958, -14.174],
                        [-85.044, -7.087],
                        [-85.044, 7.087],
                        [-77.958, 14.174],
                        [77.957, 14.174],
                        [85.044, 7.087],
                        [85.044, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [85.294, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "line 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
            a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.248, y: 0.992 },
                    o: { x: 0.48, y: 0 },
                    t: 17,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-5.284, -6.75],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [-5.284, 34.098],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 34,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [121.716, -6.902],
                          [-14.706, -6.75],
                          [-14.706, 34.098],
                          [121.716, 33.945],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [49.609, -14.174],
                        [-49.609, -14.174],
                        [-56.696, -7.087],
                        [-56.696, 7.087],
                        [-49.609, 14.174],
                        [49.609, 14.174],
                        [56.696, 7.087],
                        [56.696, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [56.946, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "line 6",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 213.478, 0], ix: 2 },
            a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.913, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [77.957, -14.174],
                        [-77.958, -14.174],
                        [-85.044, -7.087],
                        [-85.044, 7.087],
                        [-77.958, 14.174],
                        [77.957, 14.174],
                        [85.044, 7.087],
                        [85.044, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [85.294, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "line 5",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
            a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-3.914, 0],
                        [0, 0],
                        [0, 3.914],
                        [0, 0],
                        [3.914, 0],
                        [0, 0],
                        [0, -3.914],
                      ],
                      v: [
                        [49.609, -14.174],
                        [-49.609, -14.174],
                        [-56.696, -7.087],
                        [-56.696, 7.087],
                        [-49.609, 14.174],
                        [49.609, 14.174],
                        [56.696, 7.087],
                        [56.696, -7.087],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [56.946, 14.424], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "Box 5",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 256, 0], ix: 2 },
            a: { a: 0, k: [141.99, 127.817, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.346, y: 1 },
                    o: { x: 0.855, y: 0 },
                    t: 75,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-136.01, 111.816],
                          [-176.01, 231.816],
                          [15.99, 407.816],
                          [136.49, 384.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 105,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [163.99, -150.184],
                          [-240.01, 191.816],
                          [31.99, 451.816],
                          [436.49, 122.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [-2.64, -2.618],
                        [0, 0],
                        [-2.818, 0],
                        [0, 0],
                        [-1.991, 1.994],
                        [0, 0],
                        [-3.719, 0.016],
                        [0, 0],
                        [0, 15.657],
                      ],
                      o: [
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [0, 15.657],
                        [0, 0],
                        [3.718, 0.016],
                        [0, 0],
                        [1.992, 1.994],
                        [0, 0],
                        [2.818, 0],
                        [0, 0],
                        [2.641, -2.618],
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                      ],
                      v: [
                        [141.74, -99.219],
                        [113.391, -127.566],
                        [-113.393, -127.566],
                        [-141.74, -99.219],
                        [-141.74, 70.869],
                        [-113.393, 99.217],
                        [-41.247, 99.217],
                        [-31.325, 103.327],
                        [-10.206, 124.449],
                        [-2.694, 127.566],
                        [2.693, 127.566],
                        [10.205, 124.449],
                        [31.324, 103.327],
                        [41.246, 99.217],
                        [113.391, 99.217],
                        [141.74, 70.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.22745098039215686, 0.7686274509803922,
                      0.49019607843137253, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [141.99, 127.816], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 75,
          op: 150,
          st: 75,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "Box 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 256, 0], ix: 2 },
            a: { a: 0, k: [141.99, 127.817, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.346, y: 1 },
                    o: { x: 0.855, y: 0 },
                    t: 71,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-136.01, 111.816],
                          [-176.01, 231.816],
                          [15.99, 407.816],
                          [136.49, 384.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 101,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [163.99, -150.184],
                          [-240.01, 191.816],
                          [31.99, 451.816],
                          [436.49, 122.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [-2.64, -2.618],
                        [0, 0],
                        [-2.818, 0],
                        [0, 0],
                        [-1.991, 1.994],
                        [0, 0],
                        [-3.719, 0.016],
                        [0, 0],
                        [0, 15.657],
                      ],
                      o: [
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [0, 15.657],
                        [0, 0],
                        [3.718, 0.016],
                        [0, 0],
                        [1.992, 1.994],
                        [0, 0],
                        [2.818, 0],
                        [0, 0],
                        [2.641, -2.618],
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                      ],
                      v: [
                        [141.74, -99.219],
                        [113.391, -127.566],
                        [-113.393, -127.566],
                        [-141.74, -99.219],
                        [-141.74, 70.869],
                        [-113.393, 99.217],
                        [-41.247, 99.217],
                        [-31.325, 103.327],
                        [-10.206, 124.449],
                        [-2.694, 127.566],
                        [2.693, 127.566],
                        [10.205, 124.449],
                        [31.324, 103.327],
                        [41.246, 99.217],
                        [113.391, 99.217],
                        [141.74, 70.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [141.99, 127.816], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 71,
          op: 150,
          st: 71,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "Box 3",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 256, 0], ix: 2 },
            a: { a: 0, k: [141.99, 127.817, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.346, y: 1 },
                    o: { x: 0.855, y: 0 },
                    t: 4,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-136.01, 111.816],
                          [-176.01, 231.816],
                          [15.99, 407.816],
                          [136.49, 384.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 34,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [163.99, -150.184],
                          [-240.01, 191.816],
                          [31.99, 451.816],
                          [436.49, 122.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [-2.64, -2.618],
                        [0, 0],
                        [-2.818, 0],
                        [0, 0],
                        [-1.991, 1.994],
                        [0, 0],
                        [-3.719, 0.016],
                        [0, 0],
                        [0, 15.657],
                      ],
                      o: [
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [0, 15.657],
                        [0, 0],
                        [3.718, 0.016],
                        [0, 0],
                        [1.992, 1.994],
                        [0, 0],
                        [2.818, 0],
                        [0, 0],
                        [2.641, -2.618],
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                      ],
                      v: [
                        [141.74, -99.219],
                        [113.391, -127.566],
                        [-113.393, -127.566],
                        [-141.74, -99.219],
                        [-141.74, 70.869],
                        [-113.393, 99.217],
                        [-41.247, 99.217],
                        [-31.325, 103.327],
                        [-10.206, 124.449],
                        [-2.694, 127.566],
                        [2.693, 127.566],
                        [10.205, 124.449],
                        [31.324, 103.327],
                        [41.246, 99.217],
                        [113.391, 99.217],
                        [141.74, 70.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [141.99, 127.816], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 4,
          op: 150,
          st: 4,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "Box 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 256, 0], ix: 2 },
            a: { a: 0, k: [141.99, 127.817, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 1,
                k: [
                  {
                    i: { x: 0.346, y: 1 },
                    o: { x: 0.855, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-136.01, 111.816],
                          [-176.01, 231.816],
                          [15.99, 407.816],
                          [136.49, 384.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                  {
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [163.99, -150.184],
                          [-240.01, 191.816],
                          [31.99, 451.816],
                          [436.49, 122.316],
                        ],
                        c: true,
                      },
                    ],
                  },
                ],
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [-2.64, -2.618],
                        [0, 0],
                        [-2.818, 0],
                        [0, 0],
                        [-1.991, 1.994],
                        [0, 0],
                        [-3.719, 0.016],
                        [0, 0],
                        [0, 15.657],
                      ],
                      o: [
                        [0, -15.656],
                        [0, 0],
                        [-15.656, 0],
                        [0, 0],
                        [0, 15.657],
                        [0, 0],
                        [3.718, 0.016],
                        [0, 0],
                        [1.992, 1.994],
                        [0, 0],
                        [2.818, 0],
                        [0, 0],
                        [2.641, -2.618],
                        [0, 0],
                        [15.657, 0],
                        [0, 0],
                      ],
                      v: [
                        [141.74, -99.219],
                        [113.391, -127.566],
                        [-113.393, -127.566],
                        [-141.74, -99.219],
                        [-141.74, 70.869],
                        [-113.393, 99.217],
                        [-41.247, 99.217],
                        [-31.325, 103.327],
                        [-10.206, 124.449],
                        [-2.694, 127.566],
                        [2.693, 127.566],
                        [10.205, 124.449],
                        [31.324, 103.327],
                        [41.246, 99.217],
                        [113.391, 99.217],
                        [141.74, 70.869],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [141.99, 127.816], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 150,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -135, ix: 10 },
        p: { a: 0, k: [96, 368, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 93,
      op: 243,
      st: 93,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: { a: 0, k: [96, 122, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 93,
      op: 243,
      st: 93,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 135, ix: 10 },
        p: { a: 0, k: [412, 368, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 93,
      op: 243,
      st: 93,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 45, ix: 10 },
        p: { a: 0, k: [412, 122, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 93,
      op: 243,
      st: 93,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -135, ix: 10 },
        p: { a: 0, k: [96, 368, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 22,
      op: 172,
      st: 22,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: -45, ix: 10 },
        p: { a: 0, k: [96, 122, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 22,
      op: 172,
      st: 22,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 135, ix: 10 },
        p: { a: 0, k: [412, 368, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 22,
      op: 172,
      st: 22,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: "Pre-comp 1",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 45, ix: 10 },
        p: { a: 0, k: [412, 122, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [48.072, 48.072, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 22,
      op: 172,
      st: 22,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: "black",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [256, 256, 0], ix: 2 },
        a: { a: 0, k: [256, 256, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 512,
      h: 512,
      ip: 0,
      op: 121,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "line 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [256, 213.478, 0], ix: 2 },
        a: { a: 0, k: [85.294, 14.424, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.914, 0],
                    [0, 0],
                    [0, -3.914],
                    [0, 0],
                    [-3.913, 0],
                    [0, 0],
                    [0, 3.914],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.913, 0],
                    [0, 0],
                    [0, 3.914],
                    [0, 0],
                    [3.914, 0],
                    [0, 0],
                    [0, -3.914],
                  ],
                  v: [
                    [77.957, -14.174],
                    [-77.958, -14.174],
                    [-85.044, -7.087],
                    [-85.044, 7.087],
                    [-77.958, 14.174],
                    [77.957, 14.174],
                    [85.044, 7.087],
                    [85.044, -7.087],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [85.294, 14.424], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "line 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [227.652, 270.174, 0], ix: 2 },
        a: { a: 0, k: [56.946, 14.424, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.914, 0],
                    [0, 0],
                    [0, -3.914],
                    [0, 0],
                    [-3.914, 0],
                    [0, 0],
                    [0, 3.914],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.914, 0],
                    [0, 0],
                    [0, 3.914],
                    [0, 0],
                    [3.914, 0],
                    [0, 0],
                    [0, -3.914],
                  ],
                  v: [
                    [49.609, -14.174],
                    [-49.609, -14.174],
                    [-56.696, -7.087],
                    [-56.696, 7.087],
                    [-49.609, 14.174],
                    [49.609, 14.174],
                    [56.696, 7.087],
                    [56.696, -7.087],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [56.946, 14.424], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "Box",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [256, 256, 0], ix: 2 },
        a: { a: 0, k: [141.99, 127.817, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [15.657, 0],
                    [0, 0],
                    [0, -15.656],
                    [0, 0],
                    [-15.656, 0],
                    [0, 0],
                    [-2.64, -2.618],
                    [0, 0],
                    [-2.818, 0],
                    [0, 0],
                    [-1.991, 1.994],
                    [0, 0],
                    [-3.719, 0.016],
                    [0, 0],
                    [0, 15.657],
                  ],
                  o: [
                    [0, -15.656],
                    [0, 0],
                    [-15.656, 0],
                    [0, 0],
                    [0, 15.657],
                    [0, 0],
                    [3.718, 0.016],
                    [0, 0],
                    [1.992, 1.994],
                    [0, 0],
                    [2.818, 0],
                    [0, 0],
                    [2.641, -2.618],
                    [0, 0],
                    [15.657, 0],
                    [0, 0],
                  ],
                  v: [
                    [141.74, -99.219],
                    [113.391, -127.566],
                    [-113.393, -127.566],
                    [-141.74, -99.219],
                    [-141.74, 70.869],
                    [-113.393, 99.217],
                    [-41.247, 99.217],
                    [-31.325, 103.327],
                    [-10.206, 124.449],
                    [-2.694, 127.566],
                    [2.693, 127.566],
                    [10.205, 124.449],
                    [31.324, 103.327],
                    [41.246, 99.217],
                    [113.391, 99.217],
                    [141.74, 70.869],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.862999949736, 0.862999949736, 0.862999949736, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [141.99, 127.816], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 150,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
