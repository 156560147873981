import React from 'react';

const cellRender_isCerrado = e => {
  return (
    e.data.isCerrado
      ? <i className={`icon_lockOutline container_spanCentrado font-size-lg`} />
      : null
  )
};

const config_isCerrado = {
  dataField: "isCerrado",
  caption: " ",
  width: 40,
  alignment: "center",
  allowReordering: false,
  allowResizing: false,
  allowFiltering: false,
  cellRender: cellRender_isCerrado,
};

export default config_isCerrado;