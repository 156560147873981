import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

import $ from "jquery";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";
import { PopupIdentificacion } from "../../../components/Popup/popupIdentificacion";

class Personal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      identifyNeeded: false,
      identifySuccess: false,
    };

    this.onIdentifySuccess = this.onIdentifySuccess.bind(this);
    this.onCancelIdentify = this.onCancelIdentify.bind(this);

    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    let { identifyNeeded } = this.state;
    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="Personal" className={"formContainer"}>
            <div id="dxContainer">
              <div data-options="dxItem: {ratio:1, baseSize: 0}">
                <div id="dxTabPanel_tipoPersonal"></div>
              </div>
            </div>
            <PopupIdentificacion
              identifyNeeded={identifyNeeded}
              onIdentifySuccess={this.onIdentifySuccess}
              onCancelIdentify={this.onCancelIdentify}
            ></PopupIdentificacion>
            <div id="dxPopup_importarPersona"></div>
            <div id="dxPopup_importarPersona_datosPersona"></div>
            <div id="dxPopup_almacenesRecambios"></div>
            <div id="dxPopup_datosPrivados"></div>
            <div id="dxPopup_identificador"></div>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  onIdentifySuccess = () => {
    this.setState({
      identifySuccess: true,
      identifyNeeded: false,
    });

    $("#dxPopup_datosPrivados").dxPopup("instance").show();
  };

  onCancelIdentify = () => {
    this.setState({ identifyNeeded: false });
  };

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma, user } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma, user).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma, user } = this.props;
    cargaJs(this, lavanderia, idioma, user).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Personal);
