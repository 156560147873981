import React, { createContext, useContext, useEffect, useState } from "react";

import DataSource from "devextreme/data/data_source";
import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";

import { authHeader, errorHandler } from "helpers";
import { connectionConstants } from "constants";

const RecambiosContext = createContext();

export const useRecambiosProvider = () => {
  const context = useContext(RecambiosContext);

  return context;
};

const datasource_tblRecambio = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecambio",
    key: "idRecambio",
    errorHandler,
    beforeSend: (request) => (request.headers = { ...authHeader() }),
    version: 4,
  }),
  filter: ["eliminado", "=", false],
});

const datasource_tblProveedor = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblProveedor",
    key: "idProveedor",
    errorHandler,
    beforeSend: (request) => (request.headers = { ...authHeader() }),
    version: 4,
  }),
  sort: ["nombreComercial"],
});

const datasource_tblPais = new DataSource({
  paginate: false,
  store: new ODataStore({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
    key: "idPais",
    errorHandler,
    beforeSend: (request) => (request.headers = { ...authHeader() }),
    version: 4,
  }),
  select: ["idPais", "denominacion"],
  expand: ["idMonedaNavigation($select=codigo)"],
  filter: ["idMoneda", "<>", null],
  sort: ["denominacion"],
});

const context_recambios = new ODataContext({
  url:
    connectionConstants.WEB_API_CORE_ODATA_URL +
    "MyPolarier/Assistant/Recambios/",
  entities: {
    CheckReferencia: {
      key: ["idRecambio", "referencia"],
      keyType: {
        idRecambio: "Int32",
        referencia: "String",
      },
    },
    CheckReferenciaInterna: {
      key: ["idRecambio", "referenciaInterna"],
      keyType: {
        idRecambio: "Int32",
        referenciaInterna: "String",
      },
    },
  },
  errorHandler,
  beforeSend: (request) => (request.headers = { ...authHeader() }),
});

const RecambiosProvider = ({ recambioSel, setRecambioSel, children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [idsRecambio, setIdsRecambio] = useState(undefined);
  const [tblRecambio, setTblRecambio] = useState([]);
  const [tblProveedor, setTblProveedor] = useState([]);
  const [tblPais, setTblPais] = useState([]);

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (recambioSel.idRecambio) {
      datasource_Get_tblRecambioNAlmacenRecambios.reload().done((data) => {
        setRecambioSel((prevState) => ({
          ...prevState,
          tblRecambioNAlmacenRecambios: data,
        }));
      });
      datasource_tblRecambioNProveedor.reload().done((data) => {
        setRecambioSel((prevState) => ({
          ...prevState,
          tblRecambioNProveedor: data,
        }));
      });
    }
  }, [recambioSel.idRecambio]);

  const datasource_tblRecambioNProveedor_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.idRecambio = recambioSel.idRecambio;
  };

  const datasource_tblRecambioNProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblRecambioNProveedor",
      key: "idRecambioNProveedor",
      errorHandler,
      beforeSend: datasource_tblRecambioNProveedor_beforeSend,
      version: 4,
    }),
    expand: [
      "idProveedorNavigation($select=nombreComercial)",
      "idPaisNavigation($select=denominacion;$expand=idMonedaNavigation($select=codigo))",
    ],
    sort: ["orden"],
  });

  const datasource_Get_tblRecambioNAlmacenRecambios_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.idRecambio = recambioSel.idRecambio;
  };

  const datasource_Get_tblRecambioNAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/Recambios/Get_tblRecambioNAlmacenRecambios",
      key: ["idAlmacen", "idRecambio"],
      errorHandler,
      beforeSend: datasource_Get_tblRecambioNAlmacenRecambios_beforeSend,
      version: 4,
    }),
  });

  const refreshData = () =>
    new Promise((resolve) => {
      setIsLoading(true);

      Promise.all([
        datasource_tblProveedor.reload(),
        datasource_tblPais.reload(),
        datasource_tblRecambio.reload(),
      ]).then(([tblProveedor, tblPais, tblRecambio]) => {
        setTblProveedor(tblProveedor);
        setTblPais(tblPais);
        setTblRecambio(tblRecambio);
        setIsLoading(false);
        resolve();
      });
    });

  const tblRecambioFinal = idsRecambio
    ? tblRecambio.filter((r) => idsRecambio.includes(r.idRecambio))
    : tblRecambio;

  const value = {
    recambioSel,
    setRecambioSel,
    idsRecambio,
    setIdsRecambio,
    context_recambios,
    tblProveedor,
    tblPais,
    datasource_tblRecambio,
    tblRecambio: tblRecambioFinal,
    refreshData,
    isLoading,
    isSending,
    setIsSending,
  };

  return (
    <RecambiosContext.Provider value={value}>
      {children}
    </RecambiosContext.Provider>
  );
};

export default RecambiosProvider;
