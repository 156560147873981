import { connectionConstants } from "../../../constants";
import {
    authHeader,
    datetimeToDuration,
    errorHandler,
    getTrad,
    phone_Pattern,
    tooltipControl_creacion,
    stringToDateTime_timeZero,
    formatDate_parameter,
    isSameDay,
    compressBase64Img_scaleWidth,
    dxMensajePregunta,
} from "../../../helpers";
import { svg_edit, svg_remove, svg_tick_circle, svg_remove_circle, svg_user_lock } from "../../../styles/svg_iconos";

import { allIcons as svg_iconos } from "../../../styles/svg_iconos";
import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import ArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";
import config from "devextreme/core/config";
import LottieIcon from "../../../components/LottieIcon";
import Guid from "devextreme/core/guid";

//Css
import "./Css.scss";
import { createLogger } from "redux-logger";

import idsPais from "constants/enums/General/tblPais";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA, USER) {
    var deferred = $.Deferred();

    var datasource_persona;
    var datasource_turno;
    var datasource_categoria;
    var datasource_tipoTrabajo;

    var tipoPersonalSel = 0,
        categoria_idsServTecnico = [4, 13, 19],
        categoria_idsAdministrativo = [5, 9, 10, 11],
        tipoTrabajo_idsServTecnico = [4],
        tipoTrabajo_idsAdministrativo = [3],
        idLavSel = -1,
        categoriaSel_importar = null,
        personasImportadas = [],
        personaSel = null,
        tblAlmacenesRecambiosSel = null,
        tblAlmacenesRecambiosSel_bak = null,
        isValid_dxDataGrid_personaNTipoContrato = true;

    //#region Datasource
    datasource_persona = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "EF_spSelectPersonas",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                if (request.method !== "get") {
                    request.url = request.url.replace("EF_spSelectPersonas", "tblPersona");
                }
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
                request.params.enableDatosRRHH = USER.enableDatosRRHH;
            },
            version: 4,
            onInserting: function (data) {
                let dxDataGrid_tblPersonaNTipoContrato = $("#dxDataGrid_tblPersonaNTipoContrato").dxDataGrid(
                    "instance"
                );
                dxDataGrid_tblPersonaNTipoContrato.saveEditData();
                let contratoData = $.map(dxDataGrid_tblPersonaNTipoContrato.option("dataSource"), function (item) {
                    return {
                        fechaAltaContrato: formatDate_parameter(new Date(item.fechaAltaContrato)),
                        fechaBajaContrato: item.fechaBajaContrato
                            ? formatDate_parameter(new Date(item.fechaBajaContrato))
                            : null,
                        idTipoContrato: item.idTipoContrato,
                        numDiasPeriodoPrueba: item.numDiasPeriodoPrueba,
                    };
                });
                data.tblPersonaNTipoContrato = contratoData;

                format_datosSalariales(data);
                format_almacenesHoras(data);
            },
            onUpdating: function (id, data) {
                format_datosSalariales(data);
                format_almacenesHoras(data);
            },
        }),
    });

    datasource_turno = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTurno",
            key: "idTurno",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: ["idTurno", "denominacion"],
        filter: ["idTurnoPadre eq null"],
    });

    datasource_categoria = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblCategoria",
            key: "idCategoria",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idCategoria", "denominacion"],
    });

    datasource_tipoTrabajo = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoTrabajo",
            key: "idTipoTrabajo",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idTipoTrabajo", "denominacion"],
    });

    let datasource_fotoPerfil = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblDocumento",
            key: "idDocumento",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idDocumento,denominacion,documento,extension"],
    });

    var datasource_tblLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblLavanderia",
            key: "idLavanderia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.todas = true;
            },
            version: 4,
        }),
        sort: ["denominacion"],
        postProcess: function (data) {
            return $.grep(data, function (lavanderia) {
                return (
                    lavanderia.idLavanderia !== LAVANDERIA.idLavanderia &&
                    lavanderia.idCorporacion === LAVANDERIA.idCorporacion
                );
            });
        },
    });

    var datasource_personaNLavanderia = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idLavanderia = idLavSel;
                request.params.todas = true;
            },
            onLoading: function (loadOptions) {
                var filtroCategoria;

                if (categoriaSel_importar) {
                    filtroCategoria = ["idCategoria", "=", categoriaSel_importar.idCategoria];
                } else filtroCategoria = "idCategoria ne -1";

                let filtroPersonasTransversales = "idLavanderia ne null";

                if (!loadOptions.filter || loadOptions.filter.length === 0) {
                    loadOptions.filter = [[filtroCategoria], "and", [filtroPersonasTransversales]];
                } else if (loadOptions.filter[0][0] !== "idPersona") {
                    loadOptions.filter = [
                        ["concat(concat(nombre, ' '), apellidos)", "contains", loadOptions.filter[0][2]],
                        "and",
                        [filtroCategoria],
                        "and",
                        [filtroPersonasTransversales],
                    ];
                }
            },
            version: 4,
        }),
        select: ["nombre,apellidos,idPersona,idTipoTrabajo"],
        sort: "nombre",
        map: function (itemData) {
            return {
                idPersona: itemData.idPersona,
                nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
                idTipoTrabajo: itemData.idTipoTrabajo,
            };
        },
    });

    var datasource_tblAlmacenRecambios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
            key: "idAlmacen",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        filter: ["idAlmacenPadre eq null"],
        expand: ["tblAlmacenHijo"],
        postProcess: function (data) {
            $.each(data, function (iAlmacen, almacen) {
                almacen.isConsulta = false;
                almacen.isSalida = false;

                $.each(almacen.tblAlmacenHijo, function (iSubAlm, subAlm) {
                    subAlm.isConsulta = false;
                    subAlm.isSalida = false;
                });
            });
            return data;
        },
    });

    var datasource_tblAlmacenRecambiosNPersona = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacenRecambiosNPersona",
            key: ["idAlmacen", "idPersona"],
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idPersona = personaSel !== null ? personaSel.idPersona : -1;
            },
            version: 4,
        }),
    });

    var context_persona = new ODataContext({
        url: connectionConstants.ODATA_URL + "tblPersona",
        entities: {
            fn_importPersonas: {},
            fn_isNumDocIdentidadPersonaExists: {},
            fn_check_codigoRFID: {},
        },
        beforeSend: function (request) {
            request.headers = { ...authHeader() };
            var entityName = request.url.substring(request.url.lastIndexOf("/") + 1);

            if (entityName === "fn_importPersonas") {
                request.payload = tratarDatos_importarPersonas();
            }
        },
    });

    var datasource_genero = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblGenero",
            key: "idGenero",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idGenero", "idTraduccionNavigation"],
        expand: ["idTraduccionNavigation"],
        map: function (data) {
            return {
                idGenero: data.idGenero,
                denominacion: data.idTraduccionNavigation[IDIOMA.codigo],
            };
        },
    });

    var datasource_tblPersonaNTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersonaNTipoContrato",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idPersona = personaSel ? personaSel.idPersona : -1;
            },
            version: 4,
        }),
    });

    var datasource_tblTipoContrato = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoContrato",
            key: "idTipoContrato",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        sort: ["denominacion"],
    });

    var enum_colores = {
        rojo: "#BC2929",
        ambar: "#F4BC46",
        verde: "#5FBA46",
        violeta: "#A004FA",
        gris: "#737373",
    };

    var datasource_persona_datosPersonales = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.todas = true;
            },
            onLoading: function (loadOptions) {
                if (personaSel && personaSel.idPersona) loadOptions.filter = ["idPersona eq " + personaSel.idPersona];
                else loadOptions.filter = ["idPersona eq -1"];
            },
            version: 4,
        }),
        select: ["numDocumentoIdentidad, fechaNacimiento,direccion, codigoPostal,poblacion,numSegSocial"],
        expand: ["tblDatosSalariales"],
        map: function (dataItem) {
            let { tblDatosSalariales } = dataItem;
            return {
                numDocumentoIdentidad: dataItem.numDocumentoIdentidad,
                fechaNacimiento: dataItem.fechaNacimiento,
                codigoPostal: dataItem.codigoPostal,
                poblacion: dataItem.poblacion,
                numSegSocial: dataItem.numSegSocial,
                direccion: dataItem.direccion,
                fechaAntiguedad: tblDatosSalariales == null ? null : tblDatosSalariales.fechaAntiguedad,
                incentivo: tblDatosSalariales == null ? null : tblDatosSalariales.incentivo,
                impHoraExtra: tblDatosSalariales == null ? null : tblDatosSalariales.impHoraExtra,
                plusProductividad: tblDatosSalariales == null ? null : tblDatosSalariales.plusProductividad,
                numPagas: tblDatosSalariales == null ? null : tblDatosSalariales.numPagas,
                percSegSocial: tblDatosSalariales == null ? null : tblDatosSalariales.percSegSocial,
                salarioBase: tblDatosSalariales == null ? null : tblDatosSalariales.salarioBase,
                plusAsistencia: tblDatosSalariales == null ? null : tblDatosSalariales.plusAsistencia,
                plusPeligrosidad: tblDatosSalariales == null ? null : tblDatosSalariales.plusPeligrosidad,
                plusResponsabilidad: tblDatosSalariales == null ? null : tblDatosSalariales.plusResponsabilidad,
            };
        },
    });

    //#endregion

    const context_personal = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/Personal/",
        entities: {
            UpdatePersonasVIPS: {},
        },
        errorHandler,
        beforeSend: (request) => (request.headers = { ...authHeader() }),
    });

    $("#Personal #dxContainer").dxBox({
        height: "100%",
        direction: "col",
    });

    $.when(
        datasource_turno.load(),
        datasource_tipoTrabajo.load(),
        datasource_categoria.load(),
        datasource_tblLavanderia.load(),
        datasource_tblAlmacenRecambios.load(),
        datasource_genero.load(),
        datasource_tblTipoContrato.load()
    ).then(function () {
        $("#dxTabPanel_tipoPersonal")
            .addClass("no-header")
            .dxTabPanel({
                height: "100%",
                width: "100%",
                focusStateEnabled: false,
                deferRendering: false,
                items: [
                    {
                        template: function () {
                            return $("<div id='dgvPersonas' />").dxDataGrid({
                                //Datos
                                dataSource: datasource_persona,
                                remoteOperations: true,
                                //Propiedades
                                height: "100%",
                                columnsAutoWidth: true,
                                headerFilter: {
                                    visible: true,
                                },
                                filterRow: {
                                    visible: true,
                                    showAllText: getTrad("todos").toUpperCase(),
                                    applyFilter: "auto",
                                },
                                searchPanel: {
                                    visible: true,
                                    width: 240,
                                    placeholder: getTrad("busqueda"),
                                },
                                paging: {
                                    pageSize: 50,
                                },
                                sorting: {
                                    mode: "multiple",
                                },
                                pager: {
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [20, 50, 100],
                                    showInfo: true,
                                },
                                editing: {
                                    mode: "popup",
                                    allowUpdating: false,
                                    allowAdding: true,
                                    useIcons: true,
                                    popup: {
                                        title: getTrad("fichaPersona"),
                                        elementAttr: { id: "dxPopup_fichaPersona" },
                                        height: "95%",
                                        showTitle: true,
                                        position: { at: "center", of: window },
                                        toolbarItems: [
                                            {
                                                widget: "dxButton",
                                                location: "after",
                                                toolbar: "bottom",
                                                options: {
                                                    text: getTrad("guardar"),
                                                    onClick: function (e) {
                                                        let dgvPersonas = $("#dgvPersonas").dxDataGrid("instance");
                                                        dgvPersonas
                                                            .getController("validating")
                                                            .validate(true)
                                                            .done(function (dgvPersonas_isValid) {
                                                                if (
                                                                    dgvPersonas_isValid &&
                                                                    isValid_dxDataGrid_personaNTipoContrato
                                                                ) {
                                                                    let is_tecnico = tipoPersonalSel === 1;
                                                                    dgvPersonas.beginUpdate();
                                                                    if (is_tecnico) {
                                                                        let dxAccordion_hasChanges = !(
                                                                            JSON.stringify(tblAlmacenesRecambiosSel) ===
                                                                            JSON.stringify(tblAlmacenesRecambiosSel_bak)
                                                                        );

                                                                        // Modificamos almacen sin modificar información de la persona
                                                                        if (
                                                                            dxAccordion_hasChanges &&
                                                                            personaSel !== null &&
                                                                            !dgvPersonas.hasEditData()
                                                                        ) {
                                                                            let rowIndex = dgvPersonas.getRowIndexByKey(
                                                                                personaSel.idPersona
                                                                            );
                                                                            dgvPersonas.cellValue(
                                                                                rowIndex,
                                                                                "tblAlmacenRecambiosNPersona",
                                                                                tblAlmacenesRecambiosSel
                                                                            );
                                                                        }
                                                                    }

                                                                    let dxDataGrid_tblPersonaNTipoContrato = $(
                                                                        "#dxDataGrid_tblPersonaNTipoContrato"
                                                                    ).dxDataGrid("instance");
                                                                    if (personaSel !== null) {
                                                                        dxDataGrid_tblPersonaNTipoContrato.saveEditData();
                                                                        let formatData = $.map(
                                                                            personaSel.tblPersonaNTipoContrato,
                                                                            function (item) {
                                                                                return {
                                                                                    fechaAltaContrato:
                                                                                        formatDate_parameter(
                                                                                            new Date(
                                                                                                item.fechaAltaContrato
                                                                                            )
                                                                                        ),
                                                                                    fechaBajaContrato:
                                                                                        item.fechaBajaContrato
                                                                                            ? formatDate_parameter(
                                                                                                  new Date(
                                                                                                      item.fechaBajaContrato
                                                                                                  )
                                                                                              )
                                                                                            : null,
                                                                                    idTipoContrato: item.idTipoContrato,
                                                                                    numDiasPeriodoPrueba:
                                                                                        item.numDiasPeriodoPrueba,
                                                                                };
                                                                            }
                                                                        );
                                                                        let rowIndex = dgvPersonas.getRowIndexByKey(
                                                                            personaSel.idPersona
                                                                        );

                                                                        dgvPersonas.cellValue(
                                                                            rowIndex,
                                                                            "tblPersonaNTipoContrato",
                                                                            formatData
                                                                        );

                                                                        dgvPersonas.cellValue(
                                                                            rowIndex,
                                                                            "activo",
                                                                            setPersonaActiva(formatData)
                                                                        );
                                                                    }

                                                                    // if(personaSel.tblDocumento_fotoPerfil != null){
                                                                    //     let fotoPerfil = personaSel.tblDocumento_fotoPerfil;
                                                                    //     let payload;
                                                                    //     if(fotoPerfil.nombre != null){
                                                                    //         if(personaSel.idFotoPerfil == null){//insert
                                                                    //             payload = {
                                                                    //                 denominacion: "idFotoPerfil",
                                                                    //                 documento: fotoPerfil.documento,
                                                                    //                 extension: fotoPerfil.extension,
                                                                    //                 fecha: new Date(),
                                                                    //                 fechaModificacion: new Date(),
                                                                    //                 idPersona: personaSel.idPersona
                                                                    //             }
                                                                    //         } else { //update
                                                                    //             payload = {
                                                                    //                 denominacion: "idFotoPerfil",
                                                                    //                 documento: fotoPerfil.documento,
                                                                    //                 extension: fotoPerfil.extension,
                                                                    //                 fechaModificacion: new Date(),
                                                                    //                 idPersona: personaSel.idPersona
                                                                    //             }
                                                                    //         }
                                                                    //         let rowIndex = dgvPersonas.getRowIndexByKey(personaSel.idPersona);
                                                                    //         dgvPersonas.cellValue(rowIndex, "tblDocumento_fotoPerfil", payload);
                                                                    //     }
                                                                    // }

                                                                    dgvPersonas.saveEditData();

                                                                    setTimeout(() => {
                                                                        dgvPersonas.endUpdate(); // Evita flickering por dgvPersonas.cellValue
                                                                    }, 250);
                                                                }
                                                            });
                                                    },
                                                },
                                            },
                                            {
                                                widget: "dxButton",
                                                location: "after",
                                                toolbar: "bottom",
                                                options: {
                                                    text: getTrad("cancelar"),
                                                    onClick: function (e) {
                                                        $("#dxPopup_fichaPersona").dxPopup("instance").hide();
                                                    },
                                                },
                                            },
                                        ],
                                        onShowing: function (e) {
                                            let isPersonaTrans =
                                                personaSel !== null && personaSel.idLavanderia === null;
                                            let dxForm_fichaPersona = $("#dxForm_fichaPersona").dxForm("instance");
                                            let is_tecnico = tipoPersonalSel === 1;

                                            dxForm_fichaPersona.itemOption(
                                                "datosPersonales.dxButton_almacenesRecambios",
                                                "visible",
                                                is_tecnico
                                            );

                                            dxForm_fichaPersona.itemOption(
                                                "datosAdicionales.idTurno",
                                                "visible",
                                                !isPersonaTrans
                                            );

                                            dxForm_fichaPersona.itemOption(
                                                "datosAdicionales.horasDiarias",
                                                "visible",
                                                !isPersonaTrans
                                            );

                                            dxForm_fichaPersona.itemOption(
                                                "datosPersonales.numDocumentoIdentidad",
                                                "visible",
                                                personaSel === null
                                            );

                                            dxForm_fichaPersona.itemOption(
                                                "datosPersonales.numSegSocial",
                                                "visible",
                                                personaSel === null
                                            );

                                            // A partir de todos los almacenes, selecciona los que tiene la persona sel
                                            if (is_tecnico) {
                                                datasource_tblAlmacenRecambiosNPersona.load().done(function (result) {
                                                    let almacenesTotales = $.extend(
                                                        true,
                                                        [],
                                                        datasource_tblAlmacenRecambios.items()
                                                    );
                                                    $.each(almacenesTotales, function (iAlmacen, almacen) {
                                                        let almacenNUsuario = $.grep(result, function (item) {
                                                            return item.idAlmacen === almacen.idAlmacen;
                                                        });
                                                        if (almacenNUsuario.length > 0) {
                                                            almacen.isConsulta = almacenNUsuario[0].isConsulta;
                                                            almacen.isSalida = almacenNUsuario[0].isSalida;
                                                        }
                                                        $.each(
                                                            almacen.tblAlmacenHijo,
                                                            function (iSubAlmacen, subAlmacen) {
                                                                let subAlmacenNUsuario = $.grep(
                                                                    result,
                                                                    function (item) {
                                                                        return item.idAlmacen === subAlmacen.idAlmacen;
                                                                    }
                                                                );
                                                                if (subAlmacenNUsuario.length > 0) {
                                                                    subAlmacen.isConsulta =
                                                                        subAlmacenNUsuario[0].isConsulta;
                                                                    subAlmacen.isSalida =
                                                                        subAlmacenNUsuario[0].isSalida;
                                                                }
                                                            }
                                                        );
                                                    });
                                                    tblAlmacenesRecambiosSel = almacenesTotales;
                                                    tblAlmacenesRecambiosSel_bak = $.extend(true, [], almacenesTotales);

                                                    if (
                                                        typeof $("#dxAccordion_almacenesRecambios").data(
                                                            "dxAccordion"
                                                        ) !== "undefined"
                                                    ) {
                                                        $("#dxAccordion_almacenesRecambios")
                                                            .dxAccordion("instance")
                                                            .option("dataSource", tblAlmacenesRecambiosSel);
                                                    }
                                                });
                                            }
                                            if (personaSel) {
                                                datasource_tblPersonaNTipoContrato
                                                    .load()
                                                    .done(function (tblPersonaNTipoContrato) {
                                                        personaSel.tblPersonaNTipoContrato = tblPersonaNTipoContrato;
                                                        $("#dxDataGrid_tblPersonaNTipoContrato")
                                                            .dxDataGrid("instance")
                                                            .option("dataSource", personaSel.tblPersonaNTipoContrato);
                                                    });
                                            }
                                        },
                                        onHiding: function (e) {
                                            personaSel = null;
                                            tblAlmacenesRecambiosSel = null;
                                            tblAlmacenesRecambiosSel_bak = null;
                                            isValid_dxDataGrid_personaNTipoContrato = true;
                                        },
                                    },
                                    form: {
                                        elementAttr: { id: "dxForm_fichaPersona" },
                                        labelLocation: "top",
                                        height: "100%",
                                        scrollingEnabled: true,
                                        colCount: 1,
                                        items: [
                                            {
                                                itemType: "group",
                                                caption: getTrad("datosPersonales"),
                                                colCountByScreen: {
                                                    xl: 4,
                                                    lg: 4,
                                                    md: 3,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                                screenByWidth: function (width) {
                                                    return width >= 1200
                                                        ? "xl"
                                                        : width >= 992
                                                        ? "lg"
                                                        : width >= 768
                                                        ? "md"
                                                        : width >= 576
                                                        ? "sm"
                                                        : "xs";
                                                },
                                                items: [
                                                    // #region dxTileView_documentos
                                                    // { //circular_tileView
                                                    //     template: function (data, itemElement) {
                                                    //         itemElement.addClass('container_spanCentrado');
                                                    //         let container = $("<div>").css({paddingLeft: '80px'});

                                                    //         let itemFoto = {
                                                    //             denominacion: "idFotoPerfil",
                                                    //             idDocumento: null,
                                                    //             documento: null
                                                    //         }

                                                    //         if( personaSel.tblDocumento_fotoPerfil != null){
                                                    //            itemFoto = personaSel.tblDocumento_fotoPerfil;
                                                    //         }

                                                    //         container.append($("<div id='dxTileView_documentos'>").addClass("circular_tileView").css("max-width", "600px").dxTileView({
                                                    //             items: [itemFoto],
                                                    //             noDataText: "Sin foto de Perfil",//getTrad('alert_NoArchivosAdjuntos'),
                                                    //             hoverStateEnabled: true,
                                                    //             baseItemHeight: 150,
                                                    //             baseItemWidth: 130,
                                                    //             direction: "horizontal",
                                                    //             height: "160",
                                                    //             width: "160",
                                                    //             itemMargin: 10,
                                                    //             showScrollbar: true,
                                                    //             itemTemplate: function (itemData, itemIndex, itemElement) {
                                                    //                 itemElement.addClass("contenedorImg");
                                                    //                 if (itemData.idDocumento != null && itemData.extension.split('/')[0] === 'image') {
                                                    //                     itemElement.css("cursor", 'pointer')
                                                    //                     itemElement.append(getProfilePicComponent(itemData));
                                                    //                 }
                                                    //                 else {
                                                    //                     itemElement.append(getDefaultImageComponent());
                                                    //                 }
                                                    //                 if(itemData.documento != null){
                                                    //                     itemElement.append(
                                                    //                         $("<div class='dxToolbar_opciones'/>")
                                                    //                             .css("position", "absolute")
                                                    //                             .css("bottom", "-5px")
                                                    //                             .css("right", "0px")
                                                    //                             .dxToolbar({
                                                    //                                 items: [
                                                    //                                     {
                                                    //                                         location: "after",
                                                    //                                         template: function (dxToolbar_itemData, dxToolbar_itemIndex, dxToolbar_itemElement) {
                                                    //                                             dxToolbar_itemElement.append($("<div/>")
                                                    //                                                 .addClass('container_spanCentrado')
                                                    //                                                 .addClass('dxToolbar_opcion')

                                                    //                                                 .css("height", '25px')
                                                    //                                                 .css("width", '25px')
                                                    //                                                 .append(svg_iconos.svg_flecha.clone()
                                                    //                                                     .css("height", '15px')
                                                    //                                                     .css("width", '15px')
                                                    //                                                     .on('click', function () {
                                                    //                                                         var downloadLink = document.createElement("a");
                                                    //                                                         downloadLink.href = "data:" + itemData.extension + ";base64," + itemData.documento;
                                                    //                                                         downloadLink.download = itemData.nombre;
                                                    //                                                         downloadLink.click();
                                                    //                                                     })
                                                    //                                                 ));
                                                    //                                         }
                                                    //                                     },
                                                    //                                     {
                                                    //                                         location: "after",
                                                    //                                         template: function (dxToolbar_itemData, dxToolbar_itemIndex, dxToolbar_itemElement) {
                                                    //                                             dxToolbar_itemElement.append($("<div/>")
                                                    //                                                 .addClass('container_spanCentrado')
                                                    //                                                 .addClass('dxToolbar_opcion')
                                                    //                                                 .append(svg_iconos.svg_remove.clone()
                                                    //                                                     .css("height", '25px')
                                                    //                                                     .css("width", '25px')
                                                    //                                                     .on('click', function () {
                                                    //                                                         dxMensajePregunta(getTrad('preg_RegistroEliminarCorto'),
                                                    //                                                             [
                                                    //                                                                 [
                                                    //                                                                     getTrad('aceptar'),
                                                    //                                                                     function () {
                                                    //                                                                         personaSel.tblDocumento_fotoPerfil = {
                                                    //                                                                             idDocumento: null,
                                                    //                                                                             nombre: null,
                                                    //                                                                             documento: null,
                                                    //                                                                             extension: null
                                                    //                                                                         };
                                                    //                                                                         $("#dxTileView_documentos").dxTileView("instance").option("items", [personaSel.tblDocumento_fotoPerfil]);
                                                    //                                                                     },
                                                    //                                                                     "danger"
                                                    //                                                                 ],
                                                    //                                                                 [
                                                    //                                                                     getTrad('cancelar'),
                                                    //                                                                     function () {
                                                    //                                                                     },
                                                    //                                                                 ]
                                                    //                                                             ]);
                                                    //                                                     })
                                                    //                                                 ));
                                                    //                                         }
                                                    //                                     },
                                                    //                                     {
                                                    //                                         location: "after",
                                                    //                                         template: function (dxToolbar_itemData, dxToolbar_itemIndex, dxToolbar_itemElement) {
                                                    //                                             dxToolbar_itemElement.append($("<div/>")
                                                    //                                                 .css("height", '25px')
                                                    //                                                 .css("width", '16px')
                                                    //                                                 );
                                                    //                                         }
                                                    //                                     },
                                                    //                                 ]
                                                    //                             })
                                                    //                     );
                                                    //                 }
                                                    //             },
                                                    //         }
                                                    //         ));

                                                    //         container.append($("<div id='dxPopup_galeria_documento'>").dxPopup({
                                                    //             deferRendering: false,
                                                    //             showTitle: true,
                                                    //             title: getTrad('galeriaImagenes'),
                                                    //             showCloseButton: true,
                                                    //             closeOnOutsideClick: true,
                                                    //             width: "900px",
                                                    //             height: "850px",
                                                    //             contentTemplate: function (contentElement) {
                                                    //                 contentElement.append($("<div id='dxGallery_documento'>").dxGallery({
                                                    //                     showIndicator: false,
                                                    //                     showNavButtons: false,
                                                    //                     loop: true,
                                                    //                     height: "100%",
                                                    //                     width: "100%",
                                                    //                     itemTemplate: function (itemData, itemIndex, itemElement) {
                                                    //                         itemElement.append(
                                                    //                             $('<img />').addClass('image')
                                                    //                                 .attr('style', "background-image: url(data:" + itemData.extension + ";base64," + itemData.documento));
                                                    //                     }
                                                    //                 }))
                                                    //             }
                                                    //         }));
                                                    //         container.append($("<div id='dxFileUploader_documento'/>").css({paddingLeft: "25px", marginTop: "20px"})
                                                    //                 .dxFileUploader({
                                                    //                     width: "100%",
                                                    //                     height: "100%",
                                                    //                     labelText: "",//getTrad('msg_arrastraArchivo5mb'),
                                                    //                     accept: "*",
                                                    //                     minFileSize: 1,
                                                    //                     multiple: false,
                                                    //                     uploadMode: "instantly",
                                                    //                     showFileList: false,
                                                    //                     uploadFailedMessage: getTrad('subidaFallida'),
                                                    //                     selectButtonText: getTrad('subirArchivo'),
                                                    //                     disabled: false,
                                                    //                     onUploadStarted: function (e) {
                                                    //                         var reader = new FileReader();

                                                    //                         if (e.file.size > 5242880) {
                                                    //                             notify({
                                                    //                                 message: getTrad('subirArchivo'),
                                                    //                                 type: "error",
                                                    //                                 displayTime: "1500",
                                                    //                                 closeOnClick: true,
                                                    //                             });
                                                    //                         }
                                                    //                         else {
                                                    //                             reader.onloadend = function () {
                                                    //                                 if (reader.result !== "") {
                                                    //                                     var fileSplit = reader.result.split(',')
                                                    //                                     var extension = e.file.type
                                                    //                                     var base64 = fileSplit[1]
                                                    //                                     var fileName = e.file.name

                                                    //                                     function insertarDocumento() {

                                                    //                                         personaSel.tblDocumento_fotoPerfil = {
                                                    //                                             idDocumento: new Guid(),
                                                    //                                             nombre: fileName,
                                                    //                                             documento: base64,
                                                    //                                             extension: extension
                                                    //                                         };

                                                    //                                         e.component.option({
                                                    //                                             value: []
                                                    //                                         });

                                                    //                                         $("#dxTileView_documentos").dxTileView("instance").option("items", [personaSel.tblDocumento_fotoPerfil]);
                                                    //                                     }

                                                    //                                     //Compress
                                                    //                                     if (extension.split('/')[0] === 'image') {
                                                    //                                         compressBase64Img_scaleWidth(reader.result, 800).then(function (compressedBase64) {
                                                    //                                             base64 = compressedBase64
                                                    //                                             insertarDocumento();
                                                    //                                         });
                                                    //                                     }
                                                    //                                     else {
                                                    //                                         insertarDocumento();
                                                    //                                     }
                                                    //                                 }
                                                    //                             }
                                                    //                             reader.readAsDataURL(e.file);
                                                    //                         }

                                                    //                         e.request.abort()
                                                    //                     },
                                                    //                 }))
                                                    //         itemElement.append(container);
                                                    //     }
                                                    // },
                                                    // #endregion
                                                    {
                                                        itemType: "group",
                                                        colSpan: 3,
                                                        colCount: 3,
                                                        items: [
                                                            {
                                                                dataField: "nombre",
                                                                template:
                                                                    LAVANDERIA.idPais === idsPais.republicaDominicana
                                                                        ? templateEditorVIPS
                                                                        : undefined,
                                                            },
                                                            {
                                                                dataField: "apellidos",
                                                                template:
                                                                    LAVANDERIA.idPais === idsPais.republicaDominicana
                                                                        ? templateEditorVIPS
                                                                        : undefined,
                                                            },
                                                            {
                                                                dataField: "numDocumentoIdentidad",
                                                                editorOptions: {
                                                                    inputAttr: {
                                                                        style: "text-transform: uppercase",
                                                                    },
                                                                },
                                                                validationRules: [
                                                                    {
                                                                        type: "required",
                                                                        message: getTrad("campoNecesario"),
                                                                    },
                                                                    {
                                                                        type: "pattern",
                                                                        pattern: /^[a-zA-Z0-9]+$/,
                                                                        message: getTrad("validation_alfanumerico"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                dataField: "activo",
                                                            },
                                                            {
                                                                dataField: "idGenero",
                                                                template:
                                                                    LAVANDERIA.idPais === idsPais.republicaDominicana
                                                                        ? templateEditorVIPS
                                                                        : undefined,
                                                            },
                                                            {
                                                                dataField: "telefono",
                                                                template:
                                                                    LAVANDERIA.idPais === idsPais.republicaDominicana
                                                                        ? templateEditorVIPS
                                                                        : undefined,
                                                                editorOptions: {
                                                                    maskInvalidMessage: getTrad("validation_Formato"),
                                                                    useMaskedValue: true,
                                                                },
                                                                validationRules: [
                                                                    {
                                                                        type: "pattern",
                                                                        pattern: phone_Pattern,
                                                                        message: getTrad("validation_Formato"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                dataField: "email",
                                                                validationRules: [
                                                                    {
                                                                        type: "pattern",
                                                                        pattern:
                                                                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                                        message: getTrad("validation_Formato"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                dataField: "numSegSocial",
                                                                editorOptions: {
                                                                    inputAttr: {
                                                                        style: "text-transform: uppercase",
                                                                    },
                                                                },
                                                                validationRules: [
                                                                    {
                                                                        type: "required",
                                                                        message: getTrad("campoNecesario"),
                                                                    },
                                                                    {
                                                                        type: "pattern",
                                                                        pattern: /^[a-zA-Z0-9]+$/,
                                                                        message: getTrad("validation_alfanumerico"),
                                                                    },
                                                                ],
                                                            },
                                                            {
                                                                itemType: "button",
                                                                horizontalAlignment: "left",
                                                                verticalAlignment: "bottom",
                                                                name: "dxButton_almacenesRecambios",
                                                                buttonOptions: {
                                                                    type: "normal",
                                                                    text: getTrad("almacenesRecambios"),
                                                                    onClick: (e) => {
                                                                        $("#dxPopup_almacenesRecambios")
                                                                            .dxPopup("instance")
                                                                            .show();
                                                                    },
                                                                },
                                                            },
                                                        ],
                                                    },
                                                ],
                                            },
                                            {
                                                itemType: "group",
                                                name: "datosAdicionales",
                                                caption: getTrad("datosAdicionales"),
                                                colCountByScreen: {
                                                    xl: 4,
                                                    lg: 4,
                                                    md: 3,
                                                    sm: 2,
                                                    xs: 1,
                                                },
                                                screenByWidth: function (width) {
                                                    return width >= 1200
                                                        ? "xl"
                                                        : width >= 992
                                                        ? "lg"
                                                        : width >= 768
                                                        ? "md"
                                                        : width >= 576
                                                        ? "sm"
                                                        : "xs";
                                                },
                                                items: [
                                                    {
                                                        dataField: "idCategoria",
                                                    },
                                                    {
                                                        dataField: "idTurno",
                                                    },
                                                    {
                                                        dataField: "idTipoTrabajo",
                                                        template:
                                                            LAVANDERIA.idPais === idsPais.republicaDominicana
                                                                ? templateEditorVIPS
                                                                : undefined,
                                                        validationRules: [
                                                            {
                                                                type: "required",
                                                            },
                                                            {
                                                                type: "custom",
                                                                validationCallback: function () {
                                                                    context_persona
                                                                        .invoke("fn_importPersonas", {}, "POST")
                                                                        .done(function () {
                                                                            $("#dgvPersonas")
                                                                                .dxDataGrid("instance")
                                                                                .refresh();
                                                                            notify({
                                                                                message:
                                                                                    getTrad(
                                                                                        "aviso_C_RegistroActualizado"
                                                                                    ),
                                                                                type: "success",
                                                                                displayTime: "1500",
                                                                                closeOnClick: true,
                                                                            });
                                                                        });
                                                                },
                                                            },
                                                        ],
                                                    },
                                                    {
                                                        dataField: "horasDiarias",
                                                    },
                                                    {
                                                        dataField: "observaciones",
                                                        colSpan: 1,
                                                    },
                                                ],
                                            },
                                            {
                                                name: "Contratos",
                                                itemType: "group",
                                                colCount: 4,
                                                colSpan: 2,
                                                caption: getTrad("contratos"),
                                                items: [
                                                    {
                                                        dataField: "tblPersonaNTipoContrato",
                                                        colSpan: 2,
                                                        label: {
                                                            text: getTrad("contratos"),
                                                            visible: false,
                                                            icon: "",
                                                        },
                                                        template: function () {
                                                            return $("<div style='min-height:425px' />").append(
                                                                $(
                                                                    "<div id='dxDataGrid_tblPersonaNTipoContrato' />"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource: personaSel
                                                                        ? personaSel.tblPersonaNTipoContrato
                                                                        : [],
                                                                    columnsAutoWidth: true,
                                                                    height: "100%",
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                    paging: {
                                                                        enabled: false,
                                                                    },
                                                                    loadPanel: {
                                                                        enabled: false,
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    editing: {
                                                                        refreshMode: "repaint",
                                                                        mode: "cell",
                                                                        allowUpdating: true,
                                                                        allowAdding: true,
                                                                        useIcons: true,
                                                                        selectTextOnEditStart: true,
                                                                    },
                                                                    columns: [
                                                                        {
                                                                            dataField: "idPersona",
                                                                            visible: false,
                                                                        },
                                                                        {
                                                                            dataField: "fechaAltaContrato",
                                                                            caption: getTrad("fechaAlta"),
                                                                            sortOrder: "desc",
                                                                            dataType: "date",
                                                                            alignment: "center",
                                                                            format: "dd/MM/yyyy",
                                                                            width: 142,
                                                                            sortIndex: 1,
                                                                            allowHeaderFiltering: false,
                                                                            editorOptions: {
                                                                                displayFormat: "dd/MM/yyyy",
                                                                                min: "01/01/1900",
                                                                            },
                                                                            validationRules: [
                                                                                { type: "required" },
                                                                                {
                                                                                    type: "custom",
                                                                                    message:
                                                                                        "Ya existe un contrato para esa fecha",
                                                                                    reevaluate: true,
                                                                                    validationCallback:
                                                                                        validationCallback_CustomRule_contratoExistente_fechaAltaContrato,
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            dataField: "fechaBajaContrato",
                                                                            caption: getTrad("fechaBaja"),
                                                                            sortOrder: "desc",
                                                                            dataType: "date",
                                                                            alignment: "center",
                                                                            format: "dd/MM/yyyy",
                                                                            width: 150,
                                                                            sortIndex: 2,
                                                                            allowHeaderFiltering: false,
                                                                            editorOptions: {
                                                                                displayFormat: "dd/MM/yyyy",
                                                                                min: "01/01/1900",
                                                                            },
                                                                            validationRules: [
                                                                                {
                                                                                    type: "custom",
                                                                                    message:
                                                                                        "La fecha de baja debe ser mayor o igual a la fecha de alta.",
                                                                                    reevaluate: true,
                                                                                    validationCallback:
                                                                                        validationCallback_CustomRule_fechaBajaContrato,
                                                                                },
                                                                                {
                                                                                    type: "custom",
                                                                                    message:
                                                                                        "Ya existe un contrato para esa fecha",
                                                                                    reevaluate: true,
                                                                                    validationCallback:
                                                                                        validationCallback_CustomRule_contratoExistente_fechaBajaContrato,
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            dataField: "numDiasPeriodoPrueba",
                                                                            caption: getTrad("periodoPruebaDias"),
                                                                            width: 180,
                                                                            alignment: "center",
                                                                            dataType: "number",
                                                                            allowSorting: false,
                                                                            allowHeaderFiltering: false,
                                                                            editorType: "dxNumberBox",
                                                                            editorOptions: {
                                                                                max: 9999,
                                                                            },
                                                                            validationRules: [
                                                                                {
                                                                                    type: "custom",
                                                                                    message:
                                                                                        "El nº de días debe ser menor a la diferencia entre fecha de inicio y fecha de fin.",
                                                                                    reevaluate: true,
                                                                                    validationCallback:
                                                                                        validationCallback_CustomRule_numDiasPeriodoPrueba,
                                                                                },
                                                                            ],
                                                                        },
                                                                        {
                                                                            dataField: "idTipoContrato",
                                                                            caption: getTrad("tipoContrato"),
                                                                            allowSearch: false,
                                                                            lookup: {
                                                                                dataSource:
                                                                                    datasource_tblTipoContrato.store(),
                                                                                valueExpr: "idTipoContrato",
                                                                                displayExpr: "denominacion",
                                                                            },
                                                                            validationRules: [{ type: "required" }],
                                                                        },
                                                                        {
                                                                            caption: " ",
                                                                            width: "40px",
                                                                            alignment: "center",
                                                                            cssClass: "p-0",
                                                                            allowEditing: false,
                                                                            cellTemplate: function (
                                                                                cellElement,
                                                                                cellInfo
                                                                            ) {
                                                                                var contenedor =
                                                                                    $("<div/>").addClass(
                                                                                        "container_spanCentrado"
                                                                                    );
                                                                                svg_iconos.svg_remove
                                                                                    .clone()
                                                                                    .css("height", "25px")
                                                                                    .css("width", "25px")
                                                                                    .css("cursor", "pointer")
                                                                                    .appendTo(contenedor);

                                                                                cellElement.on("click", function () {
                                                                                    if (cellInfo.row.isNewRow) {
                                                                                        cellInfo.component.cancelEditData();
                                                                                        isValid_dxDataGrid_personaNTipoContrato = true;
                                                                                    } else {
                                                                                        cellInfo.component.deleteRow(
                                                                                            cellInfo.rowIndex
                                                                                        );
                                                                                    }
                                                                                });

                                                                                contenedor.appendTo(cellElement);
                                                                            },
                                                                        },
                                                                    ],
                                                                    onEditorPreparing: function (e) {
                                                                        if (
                                                                            e.dataField === "fechaAltaContrato" &&
                                                                            e.parentType === "dataRow" &&
                                                                            e.row.data.fechaBajaContrato != null
                                                                        ) {
                                                                            let date = new Date(
                                                                                new Date(
                                                                                    e.row.data.fechaBajaContrato
                                                                                ).setDate(
                                                                                    new Date(
                                                                                        e.row.data.fechaBajaContrato
                                                                                    ).getDate()
                                                                                )
                                                                            );
                                                                            e.editorOptions.max = date;
                                                                        }
                                                                        if (
                                                                            e.dataField === "fechaBajaContrato" &&
                                                                            e.parentType === "dataRow"
                                                                        ) {
                                                                            let date = new Date(
                                                                                new Date(
                                                                                    e.row.data.fechaAltaContrato
                                                                                ).setDate(
                                                                                    new Date(
                                                                                        e.row.data.fechaAltaContrato
                                                                                    ).getDate()
                                                                                )
                                                                            );
                                                                            e.editorOptions.min = date;
                                                                        }
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        $.each(
                                                                            e.toolbarOptions.items,
                                                                            function (index, item) {
                                                                                switch (item.name) {
                                                                                    case "addRowButton":
                                                                                        item.options.text =
                                                                                            getTrad("nuevoContrato");
                                                                                        item.options.icon = "plus";
                                                                                        item.options.type = "success";
                                                                                        item.showText = "always";
                                                                                        break;
                                                                                    default:
                                                                                        break;
                                                                                }
                                                                            }
                                                                        );
                                                                    },
                                                                    onRowValidating: function (e) {
                                                                        isValid_dxDataGrid_personaNTipoContrato =
                                                                            e.brokenRules.length === 0;
                                                                    },
                                                                })
                                                            );
                                                        },
                                                    },
                                                    {
                                                        itemType: "empty",
                                                    },
                                                    {
                                                        itemType: "empty",
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                },
                                columns: [
                                    {
                                        dataField: "idPersona",
                                        caption: "idPersona",
                                        minWidth: 150,
                                        // sortOrder: "asc",
                                        sortIndex: 1,
                                        allowHeaderFiltering: false,
                                        visible: false,
                                    },
                                    {
                                        caption: " ",
                                        width: "30px",
                                        alignment: "center",
                                        cssClass: "p-0",
                                        cellTemplate: function (cellElement, cellInfo) {
                                            var contenedor = $("<div/>").addClass("container_spanCentrado");
                                            svg_edit
                                                .clone()
                                                .css("height", "25px")
                                                .css("width", "25px")
                                                .css("cursor", "pointer")
                                                .appendTo(contenedor);

                                            cellElement.on("click", async function () {
                                                personaSel = $.extend(true, {}, cellInfo.data);
                                                // if(personaSel.idFotoPerfil !== null){
                                                //     personaSel.tblDocumento_fotoPerfil = await datasource_fotoPerfil.store().byKey(personaSel.idFotoPerfil);
                                                //     /*
                                                //     //Se puede reducir la cantidad de campos que este fetch consigue porque no todos son necesarios
                                                //     , {
                                                //         select: "idDocumento, denominacion, documento, extension, fecha, idPersona"
                                                //     }
                                                //     //No se ha implementado porque el parámetro select fallaba. Si se hace, seria una pequeña optimización
                                                //     */
                                                // }
                                                // else{
                                                //     personaSel.tblDocumento_fotoPerfil = null
                                                // }
                                                cellInfo.component.editRow(cellInfo.rowIndex);
                                            });
                                            contenedor.appendTo(cellElement);
                                        },
                                        allowSearch: false,
                                    },
                                    {
                                        caption: " ",
                                        width: "30px",
                                        alignment: "center",
                                        cssClass: "p-0",
                                        visible: USER.enableDatosRRHH,
                                        cellTemplate: function (cellElement, cellInfo) {
                                            var rojo = "#C00000";
                                            var verde = "#2AB71B";

                                            var contenedor = $("<div/>").addClass("container_spanCentrado");
                                            svg_user_lock
                                                .clone()
                                                .css("height", "25px")
                                                .css("width", "25px")
                                                .css("cursor", "pointer")
                                                .css("fill", cellInfo.data.hasDatosSalariales ? verde : rojo)
                                                .appendTo(contenedor);

                                            cellElement.on("click", function () {
                                                personaSel = $.extend(true, {}, cellInfo.data);
                                                if (!COMPONENT.state.identifySuccess) {
                                                    COMPONENT.setState({ identifyNeeded: true });
                                                } else {
                                                    $("#dxPopup_datosPrivados").dxPopup("instance").show();
                                                }
                                            });

                                            contenedor.appendTo(cellElement);
                                        },
                                        allowSearch: false,
                                    },
                                    {
                                        dataField: "codigoRFID",
                                        caption: " ",
                                        width: "33px",
                                        height: "19px",
                                        alignment: "center",
                                        cssClass: "p-0",
                                        visible: LAVANDERIA.enableRFID_Personal,
                                        allowEditing: false,
                                        allowHeaderFiltering: false,
                                        cellTemplate: function (cellElement, cellInfo) {
                                            let contenedor = $("<div/>")
                                                .addClass("container_spanCentrado")
                                                .addClass("icon_credit_card_outline font-size-xl");
                                            if (cellInfo.value !== null) {
                                                contenedor.addClass("identificador_añadido");
                                            }
                                            cellElement.on("click", function () {
                                                personaSel = $.extend(true, {}, cellInfo.data);
                                                // if (!COMPONENT.state.identifySuccess) {
                                                //     COMPONENT.setState({ identifyNeeded: true })
                                                // } else {
                                                $("#dxPopup_identificador").dxPopup("instance").show();
                                                // }
                                            });
                                            contenedor.appendTo(cellElement);
                                        },
                                    },
                                    {
                                        dataField: "nombre",
                                        caption: getTrad("nombre"),
                                        minWidth: 150,
                                        sortOrder: "asc",
                                        sortIndex: 1,
                                        allowHeaderFiltering: false,
                                        editorOptions: { showClearButton: true },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("validation_Nombre"),
                                            },
                                            {
                                                type: "pattern",
                                                pattern: /^[^0-9]+$/,
                                                message: getTrad("validation_NoNumeros"),
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "apellidos",
                                        caption: getTrad("apellidos"),
                                        minWidth: 200,
                                        sortOrder: "asc",
                                        sortIndex: 1,
                                        allowHeaderFiltering: false,
                                        editorOptions: { showClearButton: true },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("validation_Nombre"),
                                            },
                                            {
                                                type: "pattern",
                                                pattern: /^[^0-9]+$/,
                                                message: getTrad("validation_NoNumeros"),
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "idCategoria",
                                        minWidth: 150,
                                        caption: getTrad("categoria"),
                                        lookup: {
                                            dataSource: function (options) {
                                                return {
                                                    store: datasource_categoria.store(),
                                                    filter: function (item) {
                                                        switch (tipoPersonalSel) {
                                                            case 0:
                                                                return (
                                                                    $.inArray(
                                                                        item.idCategoria,
                                                                        categoria_idsServTecnico
                                                                    ) === -1 &&
                                                                    $.inArray(
                                                                        item.idCategoria,
                                                                        categoria_idsAdministrativo
                                                                    ) === -1 &&
                                                                    item.idCategoria != 18
                                                                );
                                                            case 1:
                                                                return (
                                                                    $.inArray(
                                                                        item.idCategoria,
                                                                        categoria_idsServTecnico
                                                                    ) !== -1 && item.idCategoria != 18
                                                                );
                                                            case 2:
                                                                return (
                                                                    $.inArray(
                                                                        item.idCategoria,
                                                                        categoria_idsAdministrativo
                                                                    ) !== -1 && item.idCategoria != 18
                                                                );
                                                            default:
                                                                break;
                                                        }
                                                    },
                                                };
                                            },
                                            valueExpr: "idCategoria",
                                            displayExpr: "denominacion",
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("seleccioneCategoria"),
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "horasDiarias",
                                        caption: getTrad("horas"),
                                        width: 120,
                                        allowHeaderFiltering: false,
                                        allowFiltering: false,
                                        editorOptions: {
                                            mask: "00:00",
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("validation_HorasSemanales"),
                                            },
                                            {
                                                type: "pattern",
                                                message: "Tiempo incorrecto",
                                                pattern: /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/,
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "idTurno",
                                        caption: getTrad("turno"),
                                        minWidth: 120,
                                        lookup: {
                                            dataSource: datasource_turno.store(),
                                            valueExpr: "idTurno",
                                            displayExpr: "denominacion",
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("seleccioneTurno"),
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "idTipoTrabajo",
                                        caption: getTrad("tipoTrabajo"),
                                        minWidth: 150,
                                        lookup: {
                                            dataSource: function (options) {
                                                return {
                                                    store: datasource_tipoTrabajo.store(),
                                                    filter: function (item) {
                                                        switch (tipoPersonalSel) {
                                                            case 0:
                                                                return (
                                                                    $.inArray(
                                                                        item.idTipoTrabajo,
                                                                        tipoTrabajo_idsServTecnico
                                                                    ) === -1
                                                                );
                                                            case 1:
                                                                return (
                                                                    $.inArray(
                                                                        item.idTipoTrabajo,
                                                                        tipoTrabajo_idsServTecnico
                                                                    ) !== -1
                                                                );
                                                            case 2:
                                                                return (
                                                                    $.inArray(
                                                                        item.idTipoTrabajo,
                                                                        tipoTrabajo_idsAdministrativo
                                                                    ) !== -1
                                                                );
                                                            default:
                                                                break;
                                                        }
                                                    },
                                                };
                                            },
                                            valueExpr: "idTipoTrabajo",
                                            displayExpr: "denominacion",
                                        },
                                        validationRules: [
                                            {
                                                type: "required",
                                                message: getTrad("validation_AsignacionDefecto"),
                                            },
                                        ],
                                    },
                                    {
                                        dataField: "activo",
                                        caption: getTrad("activo"),
                                        width: 120,
                                        cssClass: "p-0",
                                        sortOrder: "desc",
                                        sortIndex: 0,
                                        showEditorAlways: true,
                                        allowFiltering: false,
                                        allowHeaderFiltering: true,
                                        editCellTemplate: function (container, options) {
                                            let text;
                                            let bgColor;
                                            if (options.data.activo) {
                                                text = getTrad("activo");
                                                bgColor = "success";
                                            } else {
                                                text = getTrad("noActivo");
                                                bgColor = "danger";
                                            }

                                            $("<div/>")
                                                .addClass("chip " + bgColor)
                                                .text(text)
                                                .appendTo(container);

                                            if (options.data.isUsuario) {
                                                tooltipControl_creacion(container, getTrad("persona_noDeshabilitar"));
                                            }
                                        },
                                        headerFilter: {
                                            dataSource: [
                                                {
                                                    text: getTrad("activo"),
                                                    value: true,
                                                },
                                                {
                                                    text: getTrad("noActivo"),
                                                    value: false,
                                                },
                                            ],
                                        },
                                    },
                                    {
                                        caption: " ",
                                        width: "30px",
                                        alignment: "center",
                                        cssClass: "p-0",
                                        cellTemplate: function (cellElement, cellInfo) {
                                            var contenedor = $("<div/>").addClass("container_spanCentrado");
                                            svg_remove
                                                .clone()

                                                .css("height", "25px")
                                                .css("width", "25px")
                                                .css("cursor", cellInfo.data.isUsuario ? "cursor" : "pointer")
                                                .css("opacity", cellInfo.data.isUsuario ? 0.5 : 1)
                                                .appendTo(contenedor);

                                            cellElement.on("click", function () {
                                                if (!cellInfo.data.isUsuario)
                                                    cellInfo.component.deleteRow(cellInfo.rowIndex);
                                            });

                                            contenedor.appendTo(cellElement);

                                            if (cellInfo.data.isUsuario) {
                                                tooltipControl_creacion(contenedor, getTrad("persona_noEliminar"));
                                            }
                                        },
                                    },
                                    {
                                        dataField: "idGenero",
                                        caption: getTrad("genero"),
                                        lookup: {
                                            dataSource: datasource_genero.items(),
                                            valueExpr: "idGenero",
                                            displayExpr: "denominacion",
                                        },
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "numDocumentoIdentidad",
                                        caption: getTrad("numDocumentoIdentidad"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "fechaNacimiento",
                                        caption: getTrad("fechaNacimiento"),
                                        dataType: "date",
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            displayFormat: "dd/MM/yyyy",
                                            min: "01/01/1900",
                                        },
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "direccion",
                                        caption: getTrad("direccion"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "codigoPostal",
                                        caption: getTrad("codigoPostal"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "poblacion",
                                        caption: getTrad("poblacion"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "telefono",
                                        caption: getTrad("telefono"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "email",
                                        caption: getTrad("email"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "numSegSocial",
                                        caption: getTrad("nSegSocial"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "observaciones",
                                        caption: getTrad("observaciones"),
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    {
                                        dataField: "tblAlmacenRecambiosNPersona",
                                        visible: false,
                                    },
                                    {
                                        dataField: "tblPersonaNTipoContrato",
                                        allowSearch: false,
                                        visible: false,
                                    },
                                    // {
                                    //     dataField: "tblDocumento_fotoPerfil",
                                    //     allowSearch: false,
                                    //     visible: false
                                    // },
                                ],
                                //Eventos
                                onRowRemoved: function () {
                                    notify({
                                        message: getTrad("aviso_C_RegistroEliminado"),
                                        type: "error",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                },
                                onRowInserting: function (e) {
                                    let d = $.Deferred();
                                    context_persona
                                        .invoke(
                                            "fn_isNumDocIdentidadPersonaExists",
                                            { numDocumentoIdentidad: e.data.numDocumentoIdentidad },
                                            "GET"
                                        )
                                        .done(function (result) {
                                            if (result) {
                                                notify({
                                                    message: getTrad("numDocIdentidadExistente"),
                                                    type: "error",
                                                    displayTime: "1500",
                                                    closeOnClick: true,
                                                });
                                                return d.resolve("cancel");
                                            } else {
                                                //Como el Switch se instancia como TRUE(Activo) el valor por defecto sin modificaciones ha de ser TRUE.
                                                if (typeof e.data.activo === "undefined") {
                                                    e.data.activo = false;
                                                }

                                                var strHoras = e.data.horasDiarias.replace(":", "");
                                                e.data.horasDiarias =
                                                    strHoras.substring(0, 2) + ":" + strHoras.substring(2, 4);
                                            }
                                            return d.resolve();
                                        });
                                    e.cancel = d.promise();
                                },
                                onRowInserted: function (e) {
                                    notify({
                                        message: getTrad("aviso_C_RegistroInsertado"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                },
                                onRowUpdating: function (e) {
                                    if (typeof e.newData.activo === "undefined") {
                                        e.newData.activo = e.oldData.activo;
                                    }

                                    if (typeof e.newData.horasDiarias !== "undefined") {
                                        var strHoras = e.newData.horasDiarias.replace(":", "");
                                        e.newData.horasDiarias =
                                            strHoras.substring(0, 2) + ":" + strHoras.substring(2, 4);
                                    }
                                },
                                onRowUpdated: function (e) {
                                    notify({
                                        message: getTrad("aviso_C_RegistroActualizado"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                },
                                onEditorPreparing: function (e) {
                                    if (e.dataField === "observaciones") {
                                        e.editorName = "dxTextArea";
                                        e.editorOptions.height = "80px";
                                    }
                                },
                                onToolbarPreparing: function (e) {
                                    e.toolbarOptions.items.unshift(
                                        {
                                            location: "before",
                                            widget: "dxRadioGroup",
                                            options: {
                                                elementAttr: { id: "dxRadioGroup_tipoPersona" },
                                                height: "100px",
                                                width: "100%",
                                                dataSource: [
                                                    { text: getTrad("operaciones"), val: 0 },
                                                    { text: getTrad("servTecnico_abr"), val: 1 },
                                                    { text: getTrad("administracion"), val: 2 },
                                                ],
                                                valueExpr: "val",
                                                layout: "horizontal",
                                                itemTemplate: function (itemData, itemIndex, itemElement) {
                                                    itemElement.parent().css("padding", "6px 30px");
                                                    itemElement.addClass("container_spanCentrado");
                                                    itemElement.append($("<span>").text(itemData.text));
                                                },
                                                onValueChanged: function (e) {
                                                    tipoPersonalSel = e.value;
                                                    filter_tipoPersonal();
                                                },
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxDropDownButton",
                                            showText: "always",
                                            locateInMenu: "auto",
                                            visible: LAVANDERIA.idPais !== idsPais.republicaDominicana,
                                            options: {
                                                text: getTrad("añadir"),
                                                icon: "plus",
                                                items: [
                                                    { id: "nuevaPersona", text: getTrad("nuevaPersona") },
                                                    {
                                                        id: "importarPersona",
                                                        text: getTrad("personaExistente"),
                                                    },
                                                ],
                                                keyExpr: "id",
                                                displayExpr: "text",
                                                width: "150px",
                                                onItemClick: function (e) {
                                                    if (e.itemData.id === "nuevaPersona")
                                                        $("#dgvPersonas").dxDataGrid("instance").addRow();
                                                    else if (e.itemData.id === "importarPersona")
                                                        $("#dxPopup_importarPersona").dxPopup("instance").show();
                                                },
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            showText: "always",
                                            locateInMenu: "auto",
                                            visible: LAVANDERIA.idPais === idsPais.republicaDominicana,
                                            options: {
                                                text: getTrad("actualizarDatosVIPS"),
                                                onClick: () => {
                                                    e.component.beginCustomLoading();
                                                    context_personal
                                                        .invoke("UpdatePersonasVIPS")
                                                        .done(() => {
                                                            datasource_persona
                                                                .reload()
                                                                .then(() => e.component.endCustomLoading());
                                                            notify({
                                                                message: getTrad("registrosActualizadosCorrectamente"),
                                                                type: "success",
                                                                displayTime: 1500,
                                                                closeOnClick: true,
                                                            });
                                                        })
                                                        .catch(() => {
                                                            e.component.endCustomLoading();
                                                            notify({
                                                                message: getTrad("errorActualizarRegistros"),
                                                                type: "error",
                                                                displayTime: 1500,
                                                                closeOnClick: true,
                                                            });
                                                        });
                                                },
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            showText: "inMenu",
                                            locateInMenu: "auto",
                                            options: {
                                                text: getTrad("limpiarFiltro"),
                                                icon: " icon_EliminarFiltros",
                                                onClick: function (e) {
                                                    $("#dgvPersonas").dxDataGrid("instance").clearFilter();

                                                    filter_tipoPersonal();
                                                    notify({
                                                        message: getTrad("aviso_C_FiltroRestaurado"),
                                                        type: "success",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                    });
                                                },
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            showText: "inMenu",
                                            options: {
                                                icon: "refresh",
                                                text: getTrad("actualizar"),
                                                onClick: function () {
                                                    $("#dgvPersonas").dxDataGrid("instance").refresh();
                                                },
                                            },
                                        }
                                    );
                                    $.each(e.toolbarOptions.items, function (index, item) {
                                        switch (item.name) {
                                            case "addRowButton":
                                                item.visible = false;
                                                break;
                                            default:
                                                break;
                                        }
                                    });
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                            });
                        },
                    },
                ],
            });

        $("#Personal #dxPopup_importarPersona").dxPopup({
            title: getTrad("importarPersona"),
            deferRendering: false,
            showTitle: true,
            width: "700px",
            height: "550px",
            contentTemplate: function (contentElement) {
                contentElement.append(
                    $("<div>").dxBox({
                        direction: "row",
                        align: "space-around",
                        crossAlign: "stretch",
                        items: [
                            {
                                ratio: 1,
                                template: function () {
                                    // LAVANDERÍAS
                                    var container = $("<div>");
                                    var title = $(
                                        "<div class='font-size' style='margin-left:9px;'>" +
                                            getTrad("lavanderia") +
                                            "</div>"
                                    );
                                    var lista_compañias = $("<div id='dxList_lavanderias'>").dxList({
                                        dataSource: datasource_tblLavanderia.items(),
                                        focusStateEnabled: false,
                                        activeStateEnabled: false,
                                        searchEnabled: true,
                                        searchExpr: "denominacion",
                                        selectionMode: "single",
                                        height: "375px",
                                        width: "95%",
                                        keyExpr: "idLavanderia",
                                        scrolling: { mode: "infinite" },
                                        pageLoadMode: "scrollBottom",
                                        // EVENTOS
                                        onContentReady: function (e) {
                                            if (e.component.option("selectedItem") === null) e.component.selectItem(0);
                                        },
                                        onSelectionChanged: function (e) {
                                            let dxList_personasNLav = $("#dxList_personasNLav").dxList("instance");

                                            if (e.addedItems.length > 0) idLavSel = e.addedItems[0].idLavanderia;
                                            else idLavSel = -1;

                                            if (dxList_personasNLav !== undefined) dxList_personasNLav.reload();
                                        },
                                        itemTemplate: function (data, index) {
                                            var result = $("<div>");
                                            $("<div>").text(data.denominacion).appendTo(result);

                                            return result;
                                        },
                                    });
                                    title.appendTo(container);
                                    lista_compañias.appendTo(container);

                                    return container;
                                },
                            },
                            {
                                ratio: 1,
                                template: function () {
                                    return $("<div>").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        items: [
                                            {
                                                baseSize: 38,
                                                shrink: 0,
                                                ratio: 0,
                                                template: function (e, index, item) {
                                                    item.append(
                                                        $(
                                                            "<div class='font-size' style='margin-left:9px;'>" +
                                                                getTrad("personas") +
                                                                "</div>"
                                                        )
                                                    );
                                                },
                                            },
                                            {
                                                baseSize: 45,
                                                shrink: 0,
                                                ratio: 0,
                                                template: function (e, index, item) {
                                                    item.append(
                                                        $("<div/>").dxSelectBox({
                                                            items: datasource_categoria.items(),
                                                            value: categoriaSel_importar,
                                                            valueExpr: "idCategoria",
                                                            displayExpr: "denominacion",
                                                            elementAttr: {
                                                                id: "dxSelectBox_importar_categoria",
                                                            },
                                                            placeholder: getTrad("seleccioneCategoria"),
                                                            showClearButton: true,
                                                            width: "100%",
                                                            onValueChanged: function (e) {
                                                                categoriaSel_importar =
                                                                    e.component.option("selectedItem");
                                                                datasource_personaNLavanderia.load();
                                                            },
                                                        })
                                                    );
                                                },
                                            },
                                            {
                                                ratio: 1,
                                                template: function () {
                                                    // PERSONAS
                                                    var container = $("<div>");
                                                    var lista_compañias = $("<div id='dxList_personasNLav'>").dxList({
                                                        dataSource: datasource_personaNLavanderia,
                                                        height: "330px",
                                                        scrolling: { mode: "infinite" },
                                                        pageLoadMode: "scrollBottom",
                                                        displayExpr: "nombreCompuesto",
                                                        searchEnabled: true,
                                                        selectionMode: "all",
                                                        showSelectionControls: true,
                                                        // EVENTOS
                                                        onContentReady: function (e) {
                                                            e.component.unselectAll();
                                                        },
                                                    });
                                                    lista_compañias.appendTo(container);

                                                    return container;
                                                },
                                            },
                                        ],
                                    });
                                },
                            },
                        ],
                    })
                );
            },
            toolbarItems: [
                {
                    location: "after",
                    widget: "dxButton",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("importar"),
                        type: "success",
                        onClick: function (e) {
                            let dxList_personasNLav = $("#dxList_personasNLav").dxList("instance");
                            $.merge(personasImportadas, dxList_personasNLav.option("selectedItems"));
                            $("#dxPopup_importarPersona_datosPersona").dxPopup("instance").show();
                            $("#dxPopup_importarPersona").dxPopup("instance").hide();
                        },
                    },
                },
            ],
            onShowing: function (e) {
                $("#dxList_personasNLav").dxList("instance").reload();
            },
            onHiding: function (e) {
                let dxList_lavanderias = $("#dxList_lavanderias").dxList("instance"),
                    dxList_personasNLav = $("#dxList_personasNLav").dxList("instance");
                dxList_lavanderias.selectItem(0);
                dxList_lavanderias.scrollTo(0);
                dxList_lavanderias.option("searchValue", "");

                dxList_personasNLav.unselectAll();
                dxList_personasNLav.option("searchValue", "");

                $("#dxSelectBox_importar_categoria").dxSelectBox("instance").reset();
            },
        });

        $("#Personal #dxPopup_importarPersona_datosPersona").dxPopup({
            title: getTrad("datosJornada"),
            deferRendering: false,
            showTitle: true,
            width: "550px",
            height: "220px",
            contentTemplate: function (contentElement) {
                contentElement.append(
                    $("<div id='dxForm_datosPersona' />").dxForm({
                        labelLocation: "top",
                        colCount: 2,
                        items: [
                            {
                                dataField: "idTurno",
                                label: {
                                    text: getTrad("turno"),
                                },
                                minWidth: 120,
                                editorType: "dxSelectBox",
                                editorOptions: {
                                    dataSource: datasource_turno.items(),
                                    valueExpr: "idTurno",
                                    displayExpr: "denominacion",
                                    value:
                                        datasource_turno.items().length > 0
                                            ? datasource_turno.items()[0].idTurno
                                            : null,
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: getTrad("seleccioneTurno"),
                                    },
                                ],
                            },
                            {
                                dataField: "horasDiarias",
                                label: {
                                    text: getTrad("horas"),
                                },
                                width: 120,
                                allowHeaderFiltering: false,
                                allowFiltering: false,
                                editorOptions: {
                                    mask: "00:00",
                                    value: null,
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: getTrad("validation_HorasSemanales"),
                                    },
                                    {
                                        type: "pattern",
                                        message: "Tiempo incorrecto",
                                        pattern: /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/,
                                    },
                                ],
                            },
                        ],
                    })
                );
            },
            toolbarItems: [
                {
                    location: "after",
                    widget: "dxButton",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("guardar"),
                        type: "success",
                        onClick: function (e) {
                            if ($("#dxForm_datosPersona").dxForm("instance").validate().isValid) {
                                context_persona.invoke("fn_importPersonas", {}, "POST").done(function () {
                                    $("#dgvPersonas").dxDataGrid("instance").refresh();
                                    notify({
                                        message: getTrad("aviso_C_RegistroActualizado"),
                                        type: "success",
                                        displayTime: "1500",
                                        closeOnClick: true,
                                    });
                                });
                                $("#dxPopup_importarPersona_datosPersona").dxPopup("instance").hide();
                            }
                        },
                    },
                },
            ],
            onShowing: function (e) {
                $("#dxForm_datosPersona").dxForm("instance").repaint();
            },
            onHiding: function (e) {
                personasImportadas = [];
            },
        });

        $("#Personal #dxPopup_almacenesRecambios").dxPopup({
            title: getTrad("gestionAlmRecambios"),
            showTitle: true,
            width: 800,
            height: 700,
            contentTemplate: function () {
                var scrollView = $("<div id='dxScrollView_almacenesRecambios' />");
                var dxAccordion = $("<div id='dxAccordion_almacenesRecambios' style='padding-right:15px;'>");

                $("<div />")
                    .height(43)
                    .width(755)
                    .addClass("border-bottom d-flex")
                    .append(
                        $("<div style='margin:auto 0px; padding-left:12px; font-size:18px;'>")
                            .addClass("flex-fill")
                            .text(getTrad("almacenes").toUpperCase()),
                        $("<div style='width:111px; margin:auto 0px;'>")
                            .addClass("d-flex flex-row")
                            .append(
                                $("<div style='color:silver; font-size:14px;'>")
                                    .addClass("h5 small mb-0 mr-3")
                                    .text("Consulta")
                            ),
                        $("<div style='width:auto; margin:auto 0px; padding-right:20px;'>")
                            .addClass("d-flex flex-row")
                            .append(
                                $("<div style='color:silver; font-size:14px;'>")
                                    .addClass("h5 small mb-0 mr-3")
                                    .text("Salida")
                            )
                    )
                    .appendTo(scrollView);
                dxAccordion.appendTo(scrollView);

                scrollView.dxScrollView({
                    width: "100%",
                    direction: "vertical",
                    showScrollbar: "always",
                });

                dxAccordion.dxAccordion({
                    width: 770,
                    dataSource: tblAlmacenesRecambiosSel,
                    collapsible: false,
                    multiple: true,
                    selectedIndex: 0,
                    animationDuration: 75,
                    itemTitleTemplate: function (itemData, itemIndex, itemElement) {
                        itemElement.css("background-color", "whitesmoke").addClass("border-bottom");
                        return $("<div />")
                            .addClass("d-flex")
                            .append(
                                $(
                                    "<div id='dxCheckBox_almacen_" +
                                        itemData.idAlmacen +
                                        "' style='flex-basis:35px;margin:auto 0px;'>"
                                )
                                    .addClass("align-middle")
                                    .dxCheckBox({
                                        value: itemData.isConsulta || itemData.isSalida ? true : false,
                                        onValueChanged: function (e) {
                                            let dxDataGrid_subAlmacenes = $(
                                                "#dxDataGrid_subAlmacenes_" + itemData.idAlmacen
                                            ).dxDataGrid("instance");
                                            itemData.isConsulta = e.value;
                                            itemData.isSalida = e.value;

                                            if (e.value === false) {
                                                $.each(itemData.tblAlmacenHijo, function (index, item) {
                                                    item.isConsulta = false;
                                                    item.isSalida = false;
                                                });

                                                if (dxDataGrid_subAlmacenes !== undefined) {
                                                    dxDataGrid_subAlmacenes.deselectAll();
                                                    dxDataGrid_subAlmacenes.repaint();
                                                }
                                            }

                                            //Para que al pulsar checkbox no collapse el dxAccordion
                                            if (e.jQueryEvent !== undefined) e.jQueryEvent.stopPropagation();
                                        },
                                    }),
                                $("<div style='margin:auto 0px;'>").addClass("flex-fill").text(itemData.denominacion)
                            );
                    },
                    itemTemplate: function (itemData, itemIndex, itemElement) {
                        itemElement.addClass("pt-1");
                        itemElement.css("padding-left", 5);
                        itemElement.css("padding-right", 5);
                        itemElement.append(
                            $("<div id='dxDataGrid_subAlmacenes_" + itemData.idAlmacen + "' />").dxDataGrid({
                                dataSource: new DataSource({
                                    store: new ArrayStore({
                                        data: itemData.tblAlmacenHijo,
                                        key: "idAlmacen",
                                    }),
                                }),
                                //Propiedades
                                columnsAutoWidth: true,
                                showColumnHeaders: false,
                                remoteOperations: false,
                                height: "100%",
                                paging: { enabled: false },
                                headerFilter: {
                                    visible: false,
                                },
                                searchPanel: {
                                    visible: false,
                                },
                                filterRow: {
                                    visible: false,
                                },
                                editing: {
                                    mode: "cell",
                                    allowUpdating: true,
                                },
                                selection: {
                                    mode: "multiple",
                                    showCheckBoxesMode: "always",
                                },
                                columns: [
                                    {
                                        dataField: "denominacion",
                                        caption: getTrad("almacen"),
                                        minWidth: 250,
                                        allowSorting: false,
                                        allowHeaderFiltering: false,
                                        allowEditing: false,
                                    },
                                    {
                                        dataField: "isConsulta",
                                        caption: getTrad("consulta"),
                                        width: 100,
                                        alignment: "center",
                                        allowSorting: false,
                                        showEditorAlways: true,
                                        cssClass: "p-0",
                                        editCellTemplate: function (cellElement, cellInfo) {
                                            let dataField = cellInfo.column.dataField;
                                            let estadoImg = {};

                                            if (cellInfo.data[dataField]) {
                                                estadoImg = {
                                                    svg: svg_tick_circle,
                                                    color: enum_colores["verde"],
                                                };
                                            } else {
                                                estadoImg = {
                                                    svg: svg_remove_circle,
                                                    color: enum_colores["rojo"],
                                                };
                                            }

                                            estadoImg.svg
                                                .clone()
                                                .css({
                                                    fill: estadoImg.color,
                                                    "vertical-align": "middle",
                                                    cursor: "pointer",
                                                    height: "20px",
                                                    width: "20px",
                                                })
                                                .on("click", function () {
                                                    cellInfo.component.cellValue(
                                                        cellInfo.rowIndex,
                                                        dataField,
                                                        !cellInfo.data[dataField]
                                                    );
                                                    cellInfo.component.saveEditData();

                                                    if (cellInfo.row.data[dataField] || cellInfo.row.data["isSalida"]) {
                                                        cellInfo.component.selectRows(cellInfo.key, true);
                                                    } else if (
                                                        cellInfo.row.data[dataField] === false &&
                                                        cellInfo.row.data["isSalida"] === false
                                                    ) {
                                                        cellInfo.component.deselectRows(cellInfo.key);
                                                    }
                                                })
                                                .appendTo(cellElement);
                                        },
                                    },
                                    {
                                        dataField: "isSalida",
                                        caption: getTrad("salida"),
                                        width: 100,
                                        alignment: "center",
                                        allowSorting: false,
                                        showEditorAlways: true,
                                        cssClass: "p-0",
                                        editCellTemplate: function (cellElement, cellInfo) {
                                            let dataField = cellInfo.column.dataField;
                                            let estadoImg = {};

                                            if (cellInfo.data[dataField]) {
                                                estadoImg = {
                                                    svg: svg_tick_circle,
                                                    color: enum_colores["verde"],
                                                };
                                            } else {
                                                estadoImg = {
                                                    svg: svg_remove_circle,
                                                    color: enum_colores["rojo"],
                                                };
                                            }

                                            estadoImg.svg
                                                .clone()
                                                .css({
                                                    fill: estadoImg.color,
                                                    "vertical-align": "middle",
                                                    cursor: "pointer",
                                                    height: "20px",
                                                    width: "20px",
                                                })
                                                .on("click", function () {
                                                    cellInfo.component.cellValue(
                                                        cellInfo.rowIndex,
                                                        dataField,
                                                        !cellInfo.data[dataField]
                                                    );
                                                    cellInfo.component.saveEditData();

                                                    if (cellInfo.row.data[dataField] || cellInfo.row.data["isConsulta"])
                                                        cellInfo.component.selectRows(cellInfo.key, true);
                                                    else if (
                                                        cellInfo.row.data[dataField] === false &&
                                                        cellInfo.row.data["isConsulta"] === false
                                                    )
                                                        cellInfo.component.deselectRows(cellInfo.key);
                                                })
                                                .appendTo(cellElement);
                                        },
                                    },
                                ],
                                onRowPrepared: function (e) {
                                    if (e.rowType === "data") {
                                        if (e.data.isConsulta || e.data.isSalida) {
                                            e.component.option("selectedRowKeys").push(e.key);
                                        }
                                    }
                                },
                                onCellClick: function (e) {
                                    if (e.column.command === "select") {
                                        var selectAll = e.cellElement.children(".dx-checkbox");
                                        if (selectAll.dxCheckBox("instance").option("value")) {
                                            e.component.beginUpdate();
                                            $.each(e.component.getVisibleRows(), function (index, item) {
                                                var rowIndex = e.component.getRowIndexByKey(item.key);
                                                e.component.cellValue(rowIndex, "isConsulta", true);
                                                e.component.cellValue(rowIndex, "isSalida", true);
                                            });
                                            e.component.saveEditData();
                                            e.component.endUpdate();
                                        }
                                    }
                                },
                                onSelectionChanged: function (e) {
                                    e.component.beginUpdate();
                                    if (e.currentSelectedRowKeys.length > 0) {
                                        $.each(e.currentSelectedRowKeys, function (index, item) {
                                            var rowIndex = e.component.getRowIndexByKey(item);
                                            if (
                                                e.component.cellValue(rowIndex, "isConsulta") === false &&
                                                e.component.cellValue(rowIndex, "isSalida") === false
                                            ) {
                                                itemData.isConsulta = true;
                                                itemData.isSalida = true;

                                                e.component.cellValue(rowIndex, "isConsulta", true);
                                                e.component.cellValue(rowIndex, "isSalida", true);
                                            }
                                        });
                                    } else {
                                        $.each(e.currentDeselectedRowKeys, function (index, item) {
                                            var rowIndex = e.component.getRowIndexByKey(item);
                                            e.component.cellValue(rowIndex, "isConsulta", false);
                                            e.component.cellValue(rowIndex, "isSalida", false);
                                        });
                                    }
                                    if (e.component.hasEditData()) e.component.saveEditData();
                                    e.component.endUpdate();

                                    let checkAlmacen_value = setAlmacen_checkBox(itemData);

                                    if (checkAlmacen_value) {
                                        $("#dxCheckBox_almacen_" + itemData.idAlmacen)
                                            .dxCheckBox("instance")
                                            .option("value", checkAlmacen_value);
                                    }
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                            })
                        );
                    },
                });
                return scrollView;
            },
            toolbarItems: [
                {
                    location: "after",
                    widget: "dxButton",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("aceptar"),
                        type: "success",
                        onClick: function (e) {
                            $("#dxPopup_almacenesRecambios").dxPopup("instance").hide();
                        },
                    },
                },
            ],
        });

        $("#Personal #dxPopup_identificador").dxPopup({
            deferRendering: false,
            showTitle: true,
            width: "500px",
            height: "200px",
            contentTemplate: function (contentElement) {
                let form_html = $("<form id='form-container' />").on("submit", (e) => {
                    let form = $("#dxForm_identificador").dxForm("instance");
                    verificaYGuarda(form);
                    e.preventDefault();
                });

                let form_dx = $("<div id='dxForm_identificador' />").dxForm({
                    labelLocation: "top",
                    colCount: 1,
                    items: [
                        {
                            dataField: "codigoRFID",
                            label: {
                                text: "Código RFID",
                            },
                            editorOptions: {
                                placeholder: "Pasa la tarjeta por el lector",
                                onFocusIn: function (e) {
                                    e.element.find(".dx-texteditor-input").select();
                                },
                            },
                            validationRules: [
                                {
                                    type: "async",
                                    message: "Código RFID inválido",
                                    ignoreEmptyValue: true,
                                    validationCallback(params) {
                                        const codigoRFID = params.value;
                                        if (!codigoRFID) {
                                            return true;
                                        }
                                        return new Promise((resolve, reject) => {
                                            context_persona
                                                .invoke(
                                                    "fn_check_codigoRFID",
                                                    {
                                                        idPersona: personaSel.idPersona,
                                                        codigoRFID: codigoRFID,
                                                    },
                                                    "POST"
                                                )
                                                .then((isValid) => {
                                                    if (isValid) {
                                                        resolve();
                                                    } else {
                                                        reject();
                                                        const form = $("#dxForm_identificador").dxForm("instance");
                                                        form.getEditor("codigoRFID").focus();
                                                    }
                                                });
                                        });
                                    },
                                },
                            ],
                        },
                    ],
                });

                form_html.append(form_dx);
                contentElement.append(form_html);
            },
            onHidden: function (e) {
                personaSel = null;
            },
            onShowing: function (e) {
                setTimeout(() => {
                    const form = $("#dxForm_identificador").dxForm("instance");
                    form.option("formData", personaSel);
                }, 0);

                e.component.option("title", personaSel.nombre + " " + personaSel.apellidos);
            },
            onShown: function (e) {
                const form = $("#dxForm_identificador").dxForm("instance");
                form.getEditor("codigoRFID").focus();
            },
            toolbarItems: [
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("guardar"),
                        onClick: function (e) {
                            let form = $("#dxForm_identificador").dxForm("instance");
                            verificaYGuarda(form);
                        },
                    },
                },
                {
                    widget: "dxButton",
                    location: "after",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("cancelar"),
                        onClick: function (e) {
                            $("#dxPopup_identificador").dxPopup("instance").hide();
                        },
                    },
                },
            ],
        });

        $("#Personal #dxPopup_datosPrivados").dxPopup({
            title: getTrad("datosPersonales"),
            deferRendering: false,
            showTitle: true,
            width: "850px",
            height: "600px",
            contentTemplate: function (contentElement) {
                contentElement.append(
                    $("<div id='dxForm_datosPrivados' />").dxForm({
                        labelLocation: "top",
                        colCount: 4,
                        items: [
                            {
                                dataField: "numDocumentoIdentidad",
                                label: {
                                    text: getTrad("numDocumentoIdentidad"),
                                },
                                editorOptions: {
                                    inputAttr: { style: "text-transform: uppercase" },
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: getTrad("campoNecesario"),
                                    },
                                    {
                                        type: "pattern",
                                        pattern: /^[a-zA-Z0-9]+$/,
                                        message: getTrad("validation_alfanumerico"),
                                    },
                                ],
                            },
                            {
                                dataField: "fechaNacimiento",
                                label: {
                                    text: getTrad("fechaNacimiento"),
                                },
                                editorType: "dxDateBox",
                                editorOptions: {
                                    displayFormat: "dd/MM/yyyy",
                                    min: "01/01/1900",
                                },
                            },
                            {
                                dataField: "direccion",
                                label: {
                                    text: getTrad("direccion"),
                                },
                            },
                            {
                                dataField: "codigoPostal",
                                label: {
                                    text: getTrad("codigoPostal"),
                                },
                            },
                            {
                                dataField: "poblacion",
                                label: {
                                    text: getTrad("poblacion"),
                                },
                            },
                            {
                                dataField: "numSegSocial",
                                label: {
                                    text: getTrad("nSegSocial"),
                                },
                                editorOptions: {
                                    inputAttr: { style: "text-transform: uppercase" },
                                },
                                validationRules: [
                                    {
                                        type: "required",
                                        message: getTrad("campoNecesario"),
                                    },
                                    {
                                        type: "pattern",
                                        pattern: /^[a-zA-Z0-9]+$/,
                                        message: getTrad("validation_alfanumerico"),
                                    },
                                ],
                            },
                            {
                                itemType: "empty",
                            },
                            {
                                itemType: "empty",
                            },
                            {
                                itemType: "group",
                                caption: getTrad("datosSalariales"),
                                colSpan: 4,
                                colCount: 4,
                                items: [
                                    {
                                        dataField: "fechaAntiguedad",
                                        label: {
                                            text: getTrad("fechaAntiguedad"),
                                        },
                                        editorType: "dxDateBox",
                                        editorOptions: {
                                            displayFormat: "dd/MM/yyyy",
                                            min: "01/01/1900",
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        label: {
                                            text: getTrad("numAños"),
                                        },
                                        template: function (data, itemElement) {
                                            let numAños = 0;
                                            let fechaAntiguedad = data.component.option("formData").fechaAntiguedad;
                                            if (fechaAntiguedad) {
                                                numAños =
                                                    new Date(
                                                        new Date().getTime() - fechaAntiguedad.getTime()
                                                    ).getUTCFullYear() - 1970;
                                            }
                                            $("<div/>")
                                                .dxNumberBox({
                                                    format: {
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                    },
                                                    placeholder: 0,
                                                    value: numAños,
                                                    disabled: true,
                                                })
                                                .appendTo(itemElement);
                                        },
                                    },
                                    {
                                        label: {
                                            text: getTrad("numTrienios"),
                                        },
                                        template: function (data, itemElement) {
                                            let numTrienios = 0;
                                            let fechaAntiguedad = data.component.option("formData").fechaAntiguedad;
                                            if (fechaAntiguedad) {
                                                let numAños =
                                                    new Date(
                                                        new Date().getTime() - fechaAntiguedad.getTime()
                                                    ).getUTCFullYear() - 1970;
                                                numTrienios = Math.floor(numAños / 3);
                                            }
                                            $("<div/>")
                                                .dxNumberBox({
                                                    format: {
                                                        style: "decimal",
                                                        maximumFractionDigits: 0,
                                                    },
                                                    placeholder: 0,
                                                    value: numTrienios,
                                                    disabled: true,
                                                })
                                                .appendTo(itemElement);
                                        },
                                    },
                                    {
                                        dataField: "salarioBase",
                                        label: {
                                            text: getTrad("salarioBase"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "plusAsistencia",
                                        label: {
                                            text: getTrad("plusAsistencia"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "plusResponsabilidad",
                                        label: {
                                            text: getTrad("plusResponsabilidad"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "plusPeligrosidad",
                                        label: {
                                            text: getTrad("plusPeligrosidad"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "incentivo",
                                        label: {
                                            text: getTrad("incentivo"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "impHoraExtra",
                                        label: {
                                            text: getTrad("impHoraExtra"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "plusProductividad",
                                        label: {
                                            text: getTrad("plusProductividad"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: {
                                                style: "currency",
                                                maximumFractionDigits: 2,
                                                currency: config().defaultCurrency,
                                            },
                                            min: 0,
                                            max: 999999999999.99,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "numPagas",
                                        label: {
                                            text: getTrad("numPagas"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: { style: "decimal", maximumFractionDigits: 0 },
                                            min: 0,
                                            max: 20,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        dataField: "percSegSocial",
                                        label: {
                                            text: getTrad("percSegSocial"),
                                        },
                                        editorType: "dxNumberBox",
                                        editorOptions: {
                                            step: 0,
                                            format: { style: "percent", maximumFractionDigits: 1 },
                                            min: 0,
                                            max: 1,
                                            onValueChanged: function (e) {
                                                let form = $("#dxForm_datosPrivados").dxForm("instance");
                                                form.repaint();
                                            },
                                        },
                                    },
                                    {
                                        label: {
                                            text: getTrad("salarioBrutoAnual"),
                                        },
                                        template: function (data, itemElement) {
                                            let formData = data.component.option("formData");
                                            let val = getSalarioBrutoAnual(formData);

                                            $("<div/>")
                                                .dxNumberBox({
                                                    placeholder: 0,
                                                    value: val,
                                                    disabled: true,
                                                    step: 0,
                                                    format: {
                                                        style: "currency",
                                                        maximumFractionDigits: 2,
                                                        currency: config().defaultCurrency,
                                                    },
                                                    min: 0,
                                                    max: 999999999999.99,
                                                })
                                                .appendTo(itemElement);
                                        },
                                    },
                                    {
                                        label: {
                                            text: getTrad("costeEmpresa"),
                                        },
                                        template: function (data, itemElement) {
                                            let formData = data.component.option("formData");
                                            let salarioBrutoAnual = getSalarioBrutoAnual(formData);

                                            let val = salarioBrutoAnual * (1 + formData.percSegSocial);

                                            $("<div/>")
                                                .dxNumberBox({
                                                    placeholder: 0,
                                                    value: val,
                                                    disabled: true,
                                                    step: 0,
                                                    format: {
                                                        style: "currency",
                                                        maximumFractionDigits: 2,
                                                        currency: config().defaultCurrency,
                                                    },
                                                    min: 0,
                                                    max: 999999999999.99,
                                                })
                                                .appendTo(itemElement);
                                        },
                                    },
                                ],
                            },
                        ],
                    })
                );
            },
            toolbarItems: [
                {
                    location: "after",
                    widget: "dxButton",
                    toolbar: "bottom",
                    options: {
                        text: getTrad("guardar"),
                        type: "success",
                        onClick: function (e) {
                            let form = $("#dxForm_datosPrivados").dxForm("instance");
                            if (form.validate().isValid) {
                                let formData = form.option("formData");
                                let data = $.extend(true, {}, formData);
                                delete data.isUsuario;

                                let is_tecnico = tipoPersonalSel === 1;

                                function updatePersona() {
                                    delete data.hasDatosSalariales;
                                    datasource_persona
                                        .store()
                                        .update(personaSel.idPersona, data)
                                        .done(function () {
                                            $("#dgvPersonas").dxDataGrid("instance").refresh();
                                            notify({
                                                message: getTrad("aviso_C_RegistroActualizado"),
                                                type: "success",
                                                displayTime: "1500",
                                                closeOnClick: true,
                                            });
                                            $("#dxPopup_datosPrivados").dxPopup("instance").hide();
                                        });
                                }

                                // A partir de todos los almacenes, selecciona los que tiene la persona sel
                                if (is_tecnico) {
                                    datasource_tblAlmacenRecambiosNPersona.load().done(function (result) {
                                        let almacenesTotales = $.extend(
                                            true,
                                            [],
                                            datasource_tblAlmacenRecambios.items()
                                        );
                                        $.each(almacenesTotales, function (iAlmacen, almacen) {
                                            let almacenNUsuario = $.grep(result, function (item) {
                                                return item.idAlmacen === almacen.idAlmacen;
                                            });
                                            if (almacenNUsuario.length > 0) {
                                                almacen.isConsulta = almacenNUsuario[0].isConsulta;
                                                almacen.isSalida = almacenNUsuario[0].isSalida;
                                            }
                                            $.each(almacen.tblAlmacenHijo, function (iSubAlmacen, subAlmacen) {
                                                let subAlmacenNUsuario = $.grep(result, function (item) {
                                                    return item.idAlmacen === subAlmacen.idAlmacen;
                                                });
                                                if (subAlmacenNUsuario.length > 0) {
                                                    subAlmacen.isConsulta = subAlmacenNUsuario[0].isConsulta;
                                                    subAlmacen.isSalida = subAlmacenNUsuario[0].isSalida;
                                                }
                                            });
                                        });
                                        tblAlmacenesRecambiosSel = almacenesTotales;
                                        updatePersona();
                                    });
                                } else {
                                    updatePersona();
                                }
                            }
                        },
                    },
                },
            ],
            onShowing: function () {
                datasource_persona_datosPersonales.reload().done(function (datosPersonales) {
                    if (datosPersonales.length > 0) {
                        $.extend(personaSel, datosPersonales[0]);
                    }
                    $("#dxForm_datosPrivados").dxForm("instance").option("formData", personaSel);
                    $("#dxForm_datosPrivados").dxForm("instance").repaint();
                });
            },
            onHiding: function (e) {
                personaSel = null;
                tblAlmacenesRecambiosSel = null;
                tblAlmacenesRecambiosSel_bak = null;
            },
        });

        $("#dxRadioGroup_tipoPersona").dxRadioGroup("instance").option("value", 0);
    });

    function guardarIdentificador() {
        const payload = {
            codigoRFID: personaSel.codigoRFID,
            codigoRFID_fecha: new Date(),
            activo: personaSel.activo,
        };

        datasource_persona
            .store()
            .update(personaSel.idPersona, payload)
            .done(function () {
                notify({
                    message: getTrad("aviso_C_RegistroActualizado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
                datasource_persona.reload();
                $("#dxPopup_identificador").dxPopup("instance").hide();
            });
    }

    function verificaYGuarda(form) {
        const res = form.validate();
        if (res.status === "valid") {
            guardarIdentificador();
        }
        res.status === "pending" &&
            res.complete.then((r) => {
                if (r.isValid) {
                    guardarIdentificador();
                }
            });
    }

    function getDefaultImageComponent() {
        const imageElement = $("<div></div>");
        imageElement.css({ fontSize: "10.5rem" });
        imageElement.addClass("icon_Persona container_spanCentrado");
        imageElement.on("click", function () {
            let fileUploader = $("#dxFileUploader_documento").dxFileUploader("instance");
            fileUploader._selectButton.element().trigger("click");
        });

        return imageElement;
    }

    function getProfilePicComponent(itemData) {
        const profilePicElement = $("<img />");
        profilePicElement.addClass("image pointer");
        profilePicElement.css({
            backgroundImage: "url(data:" + itemData.extension + ";base64," + itemData.documento + ")",
        });
        profilePicElement.on("click", function () {
            let dxGallery_documento = $("#dxGallery_documento").dxGallery("instance");
            dxGallery_documento.beginUpdate();
            dxGallery_documento.option("items", [itemData]);
            dxGallery_documento.endUpdate();

            $("#dxPopup_galeria_documento").dxPopup("instance").show();
        });
        return profilePicElement;
    }

    function validationCallback_CustomRule_fechaBajaContrato(e) {
        let { fechaAltaContrato } = e.data;
        return fechaAltaContrato == null || e.value == null
            ? true
            : new Date(e.value.toDateString()) >= new Date(new Date(fechaAltaContrato).toDateString());
    }

    function validationCallback_CustomRule_contratoExistente_fechaBajaContrato(e, x, y) {
        let fechaAltaContrato = stringToDateTime_timeZero(e.data.fechaAltaContrato);
        let dxDataGrid_items = $("#dxDataGrid_tblPersonaNTipoContrato").dxDataGrid("instance").getDataSource().items();

        let isFiltrado_valorActual = false;
        let filtroValorActual = [];

        $.each(dxDataGrid_items, function (index, item) {
            if (
                isSameDay(stringToDateTime_timeZero(item.fechaBajaContrato), e.value) ||
                isSameDay(stringToDateTime_timeZero(item.fechaAltaContrato), fechaAltaContrato)
            ) {
                if (isFiltrado_valorActual == false) isFiltrado_valorActual = true;
                else {
                    filtroValorActual.push(item);
                }
            } else {
                filtroValorActual.push(item);
            }
        });

        let isContratoExistente = false;
        $.each(filtroValorActual, function (index, item) {
            if (
                (e.value <= stringToDateTime_timeZero(item.fechaBajaContrato) &&
                    e.value >= stringToDateTime_timeZero(item.fechaAltaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) &&
                    fechaAltaContrato <= stringToDateTime_timeZero(item.fechaAltaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) && item.fechaBajaContrato == null) ||
                (e.value == null &&
                    (stringToDateTime_timeZero(item.fechaAltaContrato) >= fechaAltaContrato ||
                        stringToDateTime_timeZero(item.fechaBajaContrato) >= fechaAltaContrato))
            )
                isContratoExistente = true;
        });

        return !isContratoExistente || e.value == null;
    }

    function validationCallback_CustomRule_contratoExistente_fechaAltaContrato(e) {
        let fechaBajaContrato =
            e.data.fechaBajaContrato != null ? stringToDateTime_timeZero(e.data.fechaBajaContrato) : null;
        let dxDataGrid_items = $("#dxDataGrid_tblPersonaNTipoContrato").dxDataGrid("instance").getDataSource().items();

        let isFiltrado_valorActual = false;
        let filtroValorActual = [];

        $.each(dxDataGrid_items, function (index, item) {
            if (
                isSameDay(stringToDateTime_timeZero(item.fechaAltaContrato), e.value) ||
                isSameDay(stringToDateTime_timeZero(item.fechaBajaContrato), fechaBajaContrato)
            ) {
                if (isFiltrado_valorActual == false) isFiltrado_valorActual = true;
                else {
                    filtroValorActual.push(item);
                }
            } else {
                filtroValorActual.push(item);
            }
        });

        let isContratoExistente = false;
        $.each(filtroValorActual, function (index, item) {
            if (
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) &&
                    e.value <= stringToDateTime_timeZero(item.fechaBajaContrato)) ||
                (e.value >= stringToDateTime_timeZero(item.fechaAltaContrato) && item.fechaBajaContrato == null) ||
                (e.value <= stringToDateTime_timeZero(item.fechaAltaContrato) && fechaBajaContrato == null)
            )
                isContratoExistente = true;
        });

        return !isContratoExistente;
    }

    function validationCallback_CustomRule_numDiasPeriodoPrueba(e) {
        let fechaAltaContrato = stringToDateTime_timeZero(e.data.fechaAltaContrato);
        let fechaBajaContrato = stringToDateTime_timeZero(e.data.fechaBajaContrato);

        var Difference_In_Time = fechaBajaContrato.getTime() - fechaAltaContrato.getTime();

        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;
        return (
            e.value == null ||
            (e.value != null &&
                (e.data.fechaBajaContrato == null ||
                    (e.data.fechaBajaContrato != null && e.value <= Difference_In_Days)))
        );
    }

    function getSalarioBrutoAnual(data) {
        return (
            data.salarioBase * 14 +
            12 *
                (data.plusAsistencia +
                    data.plusResponsabilidad +
                    data.plusPeligrosidad +
                    data.incentivo +
                    data.plusProductividad)
        );
    }

    function setAlmacen_checkBox(itemData) {
        let almacenesSel = [];
        $.each(itemData.tblAlmacenHijo, function (index, item) {
            if (item.isConsulta === true || item.isSalida === true) almacenesSel.push(true);
        });

        return almacenesSel.length === 0 ? false : true;
    }

    function format_datosSalariales(data) {
        data.tblDatosSalariales = {
            salarioBase: data.salarioBase,
            plusAsistencia: data.plusAsistencia,
            plusResponsabilidad: data.plusResponsabilidad,
            plusPeligrosidad: data.plusPeligrosidad,
            incentivo: data.incentivo,
            impHoraExtra: data.impHoraExtra,
            plusProductividad: data.plusProductividad,
            numPagas: data.numPagas,
            fechaAntiguedad: data.fechaAntiguedad,
            percSegSocial: data.percSegSocial,
        };

        delete data.salarioBase;
        delete data.plusAsistencia;
        delete data.plusResponsabilidad;
        delete data.plusPeligrosidad;
        delete data.incentivo;
        delete data.impHoraExtra;
        delete data.plusProductividad;
        delete data.numPagas;
        delete data.fechaAntiguedad;
        delete data.percSegSocial;

        let hasDatosSalariales = !Object.values(data.tblDatosSalariales).every((o) => o == null);
        if (!hasDatosSalariales) data.tblDatosSalariales = null;
    }

    function format_almacenesHoras(data) {
        let tblAlmacenRecambiosNPersona = [];

        $.each(tblAlmacenesRecambiosSel, function (iAlmacen, almacen) {
            if (almacen.isConsulta || almacen.isSalida) tblAlmacenRecambiosNPersona.push(almacen);

            $.each(almacen.tblAlmacenHijo, function (iSubAlm, subAlm) {
                if (subAlm.isConsulta || subAlm.isSalida) tblAlmacenRecambiosNPersona.push(subAlm);
            });
        });

        tblAlmacenesRecambiosSel = $.map(tblAlmacenRecambiosNPersona, function (item) {
            return {
                idAlmacen: item.idAlmacen,
                idPersona: item.idPersona,
                isConsulta: item.isConsulta,
                isSalida: item.isSalida,
            };
        });

        // Formateo a duration
        if (data.horasDiarias !== null && data.horasDiarias !== undefined) {
            let time = data.horasDiarias.split(":");
            var horasPersona = datetimeToDuration(new Date(null, null, null, time[0], time[1]));
            data.horasDiarias = horasPersona;
        }
        data.tblAlmacenRecambiosNPersona = tblAlmacenesRecambiosSel;
    }

    function tratarDatos_importarPersonas() {
        let idsImportados = personasImportadas.map(function (item) {
                return item.idPersona;
            }),
            formData = $("#dxForm_datosPersona").dxForm("instance").option("formData");

        let personas = [];

        $.each(idsImportados, function (index, item) {
            var strHoras = formData.horasDiarias.replace(":", "");
            formData.horasDiarias = strHoras.substring(0, 2) + ":" + strHoras.substring(2, 4);

            let persona = {
                idPersona: item,
                idLavanderia: LAVANDERIA.idLavanderia,
                idTurno: formData.idTurno,
                horasDiarias: formData.horasDiarias,
            };
            personas.push(persona);
        });

        return personas;
    }

    const setPersonaActiva = (data) => {
        const today = new Date();
        let activo = false;
        if (data != null) {
            const contratos = data
                .map((contrato) => ({
                    ...contrato,
                    fechaAltaContrato: new Date(contrato.fechaAltaContrato),
                    fechaBajaContrato: contrato.fechaBajaContrato
                        ? new Date(new Date(contrato.fechaBajaContrato).getTime() + 24 * 60 * 60 * 1000) // Añadimos un día para que la fecha de baja sea hasta las 23:59:59
                        : null,
                }))
                .sort((prev, post) => post.fechaAltaContrato - prev.fechaAltaContrato)
                .slice(0, 2);

            contratos.forEach((contrato) => {
                if (
                    today.getTime() - contrato.fechaAltaContrato.getTime() >= 0 &&
                    (contrato.fechaBajaContrato == null || today.getTime() - contrato.fechaBajaContrato.getTime() < 0)
                ) {
                    activo = true;
                }
            });
        }
        return activo;
    };

    function filter_tipoPersonal() {
        datasource_persona.filter(function (item) {
            switch (tipoPersonalSel) {
                case 0:
                    return (
                        $.inArray(item.idCategoria, categoria_idsServTecnico) === -1 &&
                        $.inArray(item.idCategoria, categoria_idsAdministrativo) === -1
                    );
                case 1:
                    return $.inArray(item.idCategoria, categoria_idsServTecnico) !== -1;
                case 2:
                    return $.inArray(item.idCategoria, categoria_idsAdministrativo) !== -1;
                default:
                    break;
            }
        });
        $("#dgvPersonas").dxDataGrid("instance").refresh();
    }

    function templateEditorVIPS(data, itemElement) {
        let editorElement;
        if (data.dataField === "idGenero") {
            editorElement = $(`<div id="dxTextBox_${data.dataField}_VIPS"/>`);
            editorElement.dxSelectBox({
                stylingMode: "filled",
                readOnly: true,
                value: personaSel[data.dataField],
                dataSource: datasource_genero.items(),
                valueExpr: "idGenero",
                displayExpr: "denominacion",
            });
        } else if (data.dataField === "idTipoTrabajo") {
            editorElement = $(`<div id="dxTextBox_${data.dataField}_VIPS"/>`);
            editorElement.dxSelectBox({
                stylingMode: "filled",
                readOnly: true,
                value: personaSel[data.dataField],
                dataSource: datasource_tipoTrabajo.items(),
                valueExpr: "idTipoTrabajo",
                displayExpr: "denominacion",
            });
        } else {
            editorElement = $(`<div id="dxTextBox_${data.dataField}_VIPS"/>`);
            editorElement.dxTextBox({
                stylingMode: "filled",
                readOnly: true,
                value: personaSel[data.dataField],
            });
        }
        itemElement.append(editorElement);

        itemElement.append(
            $(`<div id="dxTooltip_${data.dataField}_VIPS"/>`).dxTooltip({
                target: editorElement.parent(),
                height: "42px",
                showEvent: "dxhoverstart",
                hideEvent: "dxhoverend",
                contentTemplate: function (contentElement) {
                    contentElement.text(getTrad("bloqueadoPorVips"));
                },
            })
        );
    }

    deferred.resolve();
    return deferred.promise();
}
