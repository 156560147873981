import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";

import { errorHandler, authHeader, getTrad } from "helpers";

import { connectionConstants } from "constants";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { List } from "devextreme-react";

class SelectorCategoriaMaquina extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sistemaSel: null,
            datasource_tblSistemaMaquina: null,
            datasource_tblCategoriaMaquina: null,
            datasource_tblCategoriaMaquina_filtered: null,
        };

        this.dxList_REF = React.createRef();
    }

    // #region Lifecycle

    componentDidMount() {
        this.datasource_tblSistemaMaquina.load().then((data) => {
            let datasource_tblCategoriaMaquina = data.map((item) => item.tblCategoriaMaquina).flat();
            this.setState({ datasource_tblSistemaMaquina: data, datasource_tblCategoriaMaquina });
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.tipoPrendaSel !== this.props.tipoPrendaSel && this.props.tipoPrendaSel == null) {
            this.dxList_REF.current.instance.unselectAll();
            this.setState({
                sistemaSel: null,
                datasource_tblCategoriaMaquina_filtered: null,
            });
        }
    }

    // #endregion

    // #region DataSource

    datasource_tblSistemaMaquina = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblSistemaMaquina",
            key: "idSistemaMaquina",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_tblSistemaMaquina_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblSistemaMaquina_onLoading(loadOptions),
            version: 4,
        }),
    });

    datasource_tblSistemaMaquina_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblSistemaMaquina_onLoading(loadOptions) {
        loadOptions.expand = ["tblCategoriaMaquina($select=idCategoriaMaquina,denominacion)"];
    }

    // #endregion

    render() {
        const {
            datasource_tblSistemaMaquina,
            datasource_tblCategoriaMaquina,
            datasource_tblCategoriaMaquina_filtered,
            sistemaSel,
        } = this.state;

        return (
            <Box direction="row" align="space-around" crossAlign="stretch" height="100%" width="100%">
                <ItemBox ratio={1}>
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <div className="font-size">{getTrad("sistemaMaquina")}</div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <List
                                items={datasource_tblSistemaMaquina}
                                focusStateEnabled={false}
                                pageLoadMode="scrollBottom"
                                selectionMode="single"
                                height="100%"
                                keyExpr="idSistemaMaquina"
                                displayExpr={"denominacion"}
                                selectedItems={[sistemaSel]}
                                onSelectionChanged={this.dxList_sistema_onSelectionChanged}
                            />
                        </ItemBox>
                    </Box>
                </ItemBox>
                <ItemBox baseSize={20}></ItemBox>
                <ItemBox ratio={1}>
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <div className="font-size">{getTrad("categoriaMaquina")}</div>
                        </ItemBox>
                        <ItemBox ratio={1}>
                            <List
                                ref={this.dxList_REF}
                                items={datasource_tblCategoriaMaquina_filtered ?? datasource_tblCategoriaMaquina}
                                focusStateEnabled={false}
                                pageLoadMode="scrollBottom"
                                selectionMode="single"
                                height="100%"
                                keyExpr="idCategoriaMaquina"
                                displayExpr={"denominacion"}
                                onSelectionChanged={this.dxList_tipoPrenda_onSelectionChanged}
                            />
                        </ItemBox>
                    </Box>
                </ItemBox>
            </Box>
        );
    }

    // #region dxList

    dxList_sistema_onSelectionChanged = (e) => {
        let datasource_tblCategoriaMaquina_filtered = null,
            sistemaSel = null;

        if (e.addedItems.length > 0) {
            datasource_tblCategoriaMaquina_filtered = e.addedItems[0].tblCategoriaMaquina;
            sistemaSel = e.addedItems[0];
        }

        this.setState({
            datasource_tblCategoriaMaquina_filtered,
            sistemaSel,
        });
    };

    dxList_tipoPrenda_onSelectionChanged = (e) => {
        if (e.addedItems.length > 0) {
            const { sistemaSel } = this.state;
            const tipoPrendaSel = e.addedItems[0];
            this.props.onSelectionChanged({ sistemaSel, tipoPrendaSel });
        }
    };

    // #endregion
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(SelectorCategoriaMaquina);
