import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle, useMemo } from "react";

import DxForm, { Item } from "devextreme-react/form";
import { getTrad } from "helpers";

import DataGridArticulos from "components/Administracion/DataGridArticulos";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { Card, CardBody } from "reactstrap";

import {
    datasource_tblAdmTipoElemento,
    sharedEditorOptions,
} from "pages/Administracion/shared/model";
import { generateCode } from "pages/Administracion/shared/functions";
import CargarPedidoProveedor from "./CargarPedidoProveedor";
import { Button, TextBox } from "devextreme-react";
import TablaResumen from "pages/Administracion/shared/components/TablaResumen";
import CentroCosteElementoPepComponent from "pages/Administracion/shared/components/CentroCosteElementoPepComponent";
import { getClearAlbaran } from "pages/Administracion/AlbaranCompra/model";
import TasaCambioTipoCambioComponent from "pages/Administracion/shared/components/TasaCambioTipoCambioComponent";

const Form = forwardRef(({ formData = {}, setIdMoneda, validations, idMoneda }, ref) => {
    const [cargarPedidoPopup_visibility, setCargarPedidoPopup_visibility] = useState(false);
    const [codigoElemento, setCodigoElemento] = useState();
    const [pedidoAsociado, setPedidoAsociado] = useState(formData?.numPedidoProveedor_codigo);
    const getText = (clave) => ({ text: getTrad(clave) });
    const previousCode = useRef();
    const formSuperiorRef = useRef();
    const formInferiorRef = useRef();
    const summaryFormRef = useRef();
    const CCEPFormRef = useRef();
    const TCTCFormRef = useRef();
    const cargarPedidoProveedor_REF = useRef();
    const colCount = 5;
    const multiplier = 3;

    useImperativeHandle(ref, () => ({
        getFormData,
        hasEditData,
    }));

    const getFormData = () => formSuperiorRef?.current?.instance?.option("formData");

    useEffect(() => {
        validations.push(checkIsValid);
    }, []);

    useEffect(() => {
        if (formData.codigo != null) {
            previousCode.current = formData.codigo;
        }
        setPedidoAsociado(formData?.numPedidoProveedor_codigo);
        setCodigoElemento(formData.codigo);
    }, [formData]);

    const setCodigoElemento_Wrapper = (code) => {
        const prefijo = code.substring(0, 2);
        const fecha = code.substring(2, 6);
        const prevPrefijo = previousCode.current?.substring(0, 2);
        const prevFecha = previousCode.current?.substring(2, 6);
        if(prefijo == prevPrefijo && fecha == prevFecha) {
            formData.codigo = previousCode.current;
            setCodigoElemento(code);
        } else {
            formData.codigo = code;
            setCodigoElemento(code);
        }
    };

    const checkIsValid = () => {
        const isCCEPFormValidated = CCEPFormRef?.current?.checkIsValid();
        const TCTCFormValidated = TCTCFormRef?.current?.checkIsValid();
        const isSuperiorFormValidated = formSuperiorRef.current.instance.validate();
        const isInferiorFormValidated = formInferiorRef.current.instance.validate();
        const isFormValidated = isSuperiorFormValidated.isValid && isInferiorFormValidated.isValid && isCCEPFormValidated.isValid && TCTCFormValidated.isValid;
        return new Promise((resolve, reject) => {
            if (isFormValidated) {
                resolve();
            }
            reject();
        });
    };

    const cargarPedidos = () => {
        setCargarPedidoPopup_visibility(true);
    };

    const labels = {
        codigo: getText("codigo"),
        idAdmProveedor: getText("proveedor"),
        fechaCreacion: getText("fecha"),
        idTipoAlbaran: getText("tipoAlbaran"),
        idMoneda: getText("moneda"),
        idAdmFormaPago: getText("formaPago"),
        descuento: getText("descuento"),
        idIncoterm: getText("incoterms"),
        numPedidoProveedor: getText("numPedidoProveedor"),
        numAlbaranProveedor: getText("numAlbaranProveedor"),
        observaciones: getText("observaciones"),
        bruto: getText("totalBruto"),
        neto: getText("neto"),
    };

    const editorOptions = {
        idAdmProveedor: {
            ...sharedEditorOptions.idAdmProveedor,
            readOnly: pedidoAsociado != null ? true : false,
        },
        idTipoAlbaran: {
            dataSource: datasource_tblAdmTipoElemento, //el tipo de albaran se determina por el tipo de pedido
            valueExpr: "idAdmTipoElemento",
            displayExpr: "denominacion",
            onValueChanged: (e) => e.value != null && 
                generateCode(formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date() ,setCodigoElemento_Wrapper, e.value, "albaranCompra"),
            placeholder: getTrad("tipoAlbaran"),
            readOnly: pedidoAsociado != null ? true : false,
        },
        idMoneda: {
            ...sharedEditorOptions.idMoneda,
            readOnly: pedidoAsociado != null ? true : false,
        },
        numPedidoProveedor: {
            readOnly: true,
        },
        idAdmFormaPago: {
            ...sharedEditorOptions.idAdmFormaPago,
            readOnly: pedidoAsociado != null ? true : false,
        },
        cargarPedido: {
            text: getTrad("cargarPedido"),
            disabled: formData.isCerrado,
            onClick: cargarPedidos,
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idMoneda") {
            setIdMoneda(e.value);
        }
        if (e.dataField === "fechaCreacion" && formData.idTipoAlbaran != null) {
            generateCode(e.value, setCodigoElemento_Wrapper, formData.idTipoAlbaran, "albaranCompra");
        }
    };

    const setPedido = (pedido) => {
        formSuperiorRef.current.instance.option("formData").numPedidoProveedor_id = pedido.idAdmPedidoProveedor; //pedido.numPedidoProveedor;
        formSuperiorRef.current.instance.option("formData").numPedidoProveedor_codigo = pedido.codigo; //pedido.numPedidoProveedor;
        formSuperiorRef.current.instance.option("formData").idAdmProveedor = pedido.idAdmProveedor;
        formSuperiorRef.current.instance.option("formData").idAdmCentroCoste = pedido.idAdmCentroCoste;
        formSuperiorRef.current.instance.option("formData").idAdmElementoPEP = pedido.idAdmElementoPEP;
        formSuperiorRef.current.instance.option("formData").idAdmFormaPago = pedido.idAdmFormaPago;
        formSuperiorRef.current.instance.option("formData").idMoneda = pedido.idMoneda;
        formSuperiorRef.current.instance.option("formData").tasaCambio = pedido.tasaCambio;
        formSuperiorRef.current.instance.option("formData").descuento = pedido.descuento;
        formSuperiorRef.current.instance.option("formData").idIncoterm = pedido.idIncoterm;
        formSuperiorRef.current.instance.option("formData").idAdmTipoDescuento = pedido.idAdmTipoDescuento;
        formData.articulosDraft = pedido.tblArticuloNAdmPedidoProveedor;
        // formSuperiorRef.current.instance.option("formData").idPedidoProveedorAsociado = pedido.numPedidoProveedor;
        formSuperiorRef.current.instance.option("formData").idAdmTipoCambio = pedido.idAdmTipoCambio;
        if (
            pedido.idTipoPedido != null &&
            formSuperiorRef.current.instance.option("formData").idTipoAlbaran != pedido.idTipoPedido
        ) {
            formSuperiorRef.current.instance.option("formData").idTipoAlbaran = pedido.idTipoPedido;
            const fecha = formSuperiorRef.current.instance.option("formData").fechaCreacion ?? new Date();
            generateCode( fecha, setCodigoElemento_Wrapper, pedido.idTipoPedido, "albaranCompra");
        }
        setIdMoneda(pedido.idMoneda);
        setPedidoAsociado(pedido.codigo);
    };

    const hasEditData = (referenceFormData = getClearAlbaran()) => {
        let hasEditData = true;
        const {
            numPedidoProveedor_codigo,
            idAdmProveedor,
            idAdmCentroCoste,
            idAdmElementoPEP,
            idAdmFormaPago,
            idMoneda,
            tasaCambio,
            descuento,
            idIncoterm,
            idAdmTipoDescuento,
            idAdmTipoCambio
        } = formSuperiorRef.current.instance.option("formData")

        if(
            numPedidoProveedor_codigo == referenceFormData.numPedidoProveedor_codigo &&
            idAdmProveedor == referenceFormData.idAdmProveedor &&
            idAdmCentroCoste == referenceFormData.idAdmCentroCoste &&
            idAdmElementoPEP == referenceFormData.idAdmElementoPEP &&
            idAdmFormaPago == referenceFormData.idAdmFormaPago &&
            idMoneda == referenceFormData.idMoneda &&
            tasaCambio == referenceFormData.tasaCambio &&
            descuento == referenceFormData.descuento &&
            idIncoterm == referenceFormData.idIncoterm &&
            idAdmTipoDescuento == referenceFormData.idAdmTipoDescuento &&
            idAdmTipoCambio == referenceFormData.idAdmTipoCambio //&&
            // formData.articulosDraft.length == 0
        ) {
            hasEditData = false;
        }
        return hasEditData;
    }

    return (
        <>
            <Card
                style={{
                    width: "100%",
                    height: "100%",
                    margin: "auto",
                    borderWidth: 0.1,
                    overflow: "visible",
                }}
            >
                <CardBody>
                    <div className={"d-flex"} style={{ justifyContent: "space-between" }}>
                        <div className={"d-flex"}>
                            <Button {...editorOptions.cargarPedido} />
                            {pedidoAsociado && (
                                <div className="ml-3 position-relative">
                                    <TextBox
                                        value={`${getTrad("pedido")} ${pedidoAsociado}`}
                                        stylingMode="outlined"
                                        readOnly
                                    />
                                    <Button
                                        className="clearButtonLlamamiento"
                                        stylingMode="text"
                                        icon="clear"
                                        disabled={formData.isCerrado}
                                        onClick={cargarPedidoProveedor_REF.current.quitarPedidoSeleccionado}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={"d-flex"} style={{ marginTop: "auto", marginBottom: "auto" }}>
                            {ChipEstado(formData.idAdmAlbaran_Estado, "", "albaran")}
                        </div>
                    </div>
                    <div className={"d-flex mt-4"}>
                        <DxForm
                            ref={formSuperiorRef}
                            formData={formData}
                            onFieldDataChanged={onFieldDataChanged}
                            colCount={colCount * multiplier}
                            height={"100%"}
                            width={"100%"}
                            labelLocation={"top"}
                            readOnly={formData.isCerrado}
                        >
                            <Item
                                dataField={"idTipoAlbaran"}
                                label={labels.idTipoAlbaran}
                                editorOptions={editorOptions.idTipoAlbaran}
                                editorType={"dxSelectBox"}
                                colSpan={1 * multiplier - 1}
                                isRequired
                            />
                            <Item
                                dataField={"codigo"}
                                label={labels.codigo}
                                editorOptions={sharedEditorOptions.codigo}
                                editorType={"dxTextBox"}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"fechaCreacion"}
                                label={labels.fechaCreacion}
                                editorOptions={sharedEditorOptions.fechaCreacion}
                                editorType={"dxDateBox"}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idMoneda"}
                                label={labels.idMoneda}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idMoneda}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"idAdmProveedor"}
                                label={labels.idAdmProveedor}
                                editorType={"dxSelectBox"}
                                editorOptions={editorOptions.idAdmProveedor}
                                colSpan={2 * multiplier}
                                isRequired
                            />
                            <Item
                                dataField={"numAlbaranProveedor"}
                                label={labels.numAlbaranProveedor}
                                colSpan={2}
                                isRequired
                            />
                            <Item
                                dataField={"numPedidoProveedor_codigo"}
                                label={labels.numPedidoProveedor}
                                editorOptions={editorOptions.numPedidoProveedor}
                                colSpan={1 * multiplier}
                                isRequired
                            />
                            <Item
                                colSpan={2 * multiplier - 2}
                            >
                                <TasaCambioTipoCambioComponent
                                    ref={TCTCFormRef}
                                    formData={formData}
                                />
                            </Item>
                            <Item colSpan={2 * multiplier}>
                                <CentroCosteElementoPepComponent ref={CCEPFormRef} formData={formData} readOnly={pedidoAsociado != null ? true : false}/>
                                {/* <CustomRule validationCallback={CCEPFormRef?.current?.checkIsValid}/> */}
                            </Item>
                        </DxForm>
                    </div>
                </CardBody>
            </Card>

            <div className={"d-flex mt-4"}>
                <Card
                    style={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        borderWidth: 0.1,
                        overflow: "visible",
                    }}
                >
                    <CardBody>
                        <DataGridArticulos
                            idMoneda={idMoneda}
                            dataSource={formData.articulosDraft}
                            validations={validations}
                            updateSummary={summaryFormRef?.current?.updateSummary}
                            tipoArticulos={formData.idTipoAlbaran}
                            isCompra={true}
                            readOnly={formData.isCerrado}
                        />
                    </CardBody>
                </Card>
            </div>
            <div className={"d-flex mt-4"} style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                    style={{
                        alignItems: "stretch",
                        display: "flex",
                        width: "100%",
                    }}
                >
                    <Card
                        style={{
                            width: "80%",
                            height: "100%",
                            marginLeft: 0,
                            marginTop: "auto",
                            marginBottom: "auto",
                            borderWidth: 0.1,
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <DxForm
                                ref={formInferiorRef}
                                formData={formData}
                                onFieldDataChanged={onFieldDataChanged}
                                colCount={4 * multiplier}
                                height={"100%"}
                                width={"100%"}
                                labelLocation={"top"}
                                readOnly={formData.isCerrado}
                            >
                                <Item
                                    dataField={"idIncoterm"}
                                    label={labels.idIncoterm}
                                    editorType={"dxSelectBox"}
                                    editorOptions={sharedEditorOptions.idIncoterm}
                                    colSpan={1 * multiplier}
                                />
                                <Item
                                    dataField={"idAdmFormaPago"}
                                    label={labels.idAdmFormaPago}
                                    editorType={"dxSelectBox"}
                                    editorOptions={editorOptions.idAdmFormaPago}
                                    colSpan={1 * multiplier}
                                    isRequired
                                />
                                <Item
                                    dataField={"observaciones"}
                                    label={labels.observaciones}
                                    editorType={"dxTextArea"}
                                    colSpan={2 * multiplier}
                                />
                            </DxForm>
                        </CardBody>
                    </Card>
                    <Card
                        style={{
                            width: "18%",
                            height: "100%",
                            marginRight: 0,
                            marginLeft: "auto",
                            borderWidth: 0.1,
                            borderColor: "lightgray",
                            overflow: "visible",
                        }}
                    >
                        <CardBody>
                            <TablaResumen
                                ref={summaryFormRef}
                                formData={formData}
                                idMoneda={idMoneda}
                                showIva={false}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
            <CargarPedidoProveedor
                ref={cargarPedidoProveedor_REF}
                cargarPedidoPopup_visibility={cargarPedidoPopup_visibility}
                setCargarPedidoPopup_visibility={setCargarPedidoPopup_visibility}
                setPedido={setPedido}
                hasEditData={hasEditData}
            />
        </>
    );
});

export default Form;
