import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    Lookup,
    Pager,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import ODataContext from "devextreme/data/odata/context";

import $ from "jquery";

import { getClearFactura } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    filterArticulo,
    onCellPrepared_Total,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblCliente,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblViasPagoCobro,
    datasource_tblAdmTipoFactura,
    sharedEditorOptions,
    formats,
    datasource_tblCondicionPagoCobro,
    displayDeno_CondicionTermino,
    datasource_tblAdmFactura_Estado,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { connectionConstants } from "constants";
import { authHeader, errorHandler, formatNumber, getTrad } from "helpers";
import { impresionActions } from "actions";
import notify from "devextreme/ui/notify";

import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";
import { codigosMoneda } from "constants/enums/General/tblMoneda";

const DataGridFacturasVenta = ({
    datasource,
    setFacturaVentaSel,
    openPopup,
    setParams,
    setReport,
    close,
    idEmpresaPolarier,
    loadPanel_hide,
    user,
}) => {
    const [idAdmFacturaVentaInProgress, setIdAdmFacturaVentaInProgress] = useState(null);

    const [clientes, setClientes] = useState([]);
    const [centrosCoste, setCentrosCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);
    useEffect(() => {
        Promise.all([
            datasource_tblCliente.load(),
            datasource_tblAdmCentroCoste.load(),
            datasource_tblAdmElementoPEP.load(),
        ]).then((data) => {
            setClientes(datasource_tblCliente.items());
            setCentrosCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    useEffect(() => {
        setReport({
            denominacion: "administracion_factura_cliente",
            parameterEvent: customizeParameterEditors,
        });

        return () => {
            close();
        };
    }, []);

    const customizeParameterEditors = (report, s, e) => {
        if (e.parameter.name === "idsAdmFacturaVenta") {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
    };

    const context_asientosFacturas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/Contabilidad/AsientosFacturas/",
        entities: {
            GenerarAsientosFacturaVentaSAP: {
                key: "idAdmFacturaVenta",
                keyType: "Int32",
            },
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => (request.headers = { ...authHeader() }),
    });

    const calculateCellValue = (e) => {
        let total = 0;

        if (e.tblAdmConceptoVenta.length > 0) {
            e.tblAdmConceptoVenta.forEach((articulo) => {
                const { cantidad = 0, precio = 0, descuento = 0 } = articulo;
                total += cantidad * precio * (1 - descuento);
            });
        }

        if (e.idAdmAlbaranVenta.length > 0) {
            e.idAdmAlbaranVenta.forEach((albaran) => {
                albaran.tblArticuloNAdmAlbaranVenta.forEach((articulo) => {
                    const { cantidad = 0, descuento = 0, precio = 0 } = articulo;
                    total += cantidad * precio * (1 - descuento);
                });
                const { descuento = 0, idAdmTipoDescuento } = albaran;
                total = calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "venta");
            });
        }
        const { descuento = 0, idAdmTipoDescuento, idIvaNPais } = e;
        total = calcularValorNeto(total, descuento, idAdmTipoDescuento, idIvaNPais, "venta");
        return { valor: total, idMoneda: e.idMoneda, idTipoFactura: e.idTipoFactura };
    };

    const calculateTotalValueForDisplay = (e) => {
        let total = calculateCellValue(e).valor;
        return calculateDisplayValue(isNaN(total) ? 0 : total, e.idMoneda);
    };

    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    widget: "dxButton",
                    options: {
                        icon: "plus",
                        text: getTrad("nuevaFacturaVenta"),
                        onClick: () => onNewFactura(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            datasource.reload();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewFactura = () => {
        const factura = getClearFactura();
        setFacturaVentaSel({ ...factura });
        openPopup();
    };

    const onRowPrepared_tblFacturas = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onCellPrepared_tblFacturas = useMemo(() => {
        return (e) => {
            if (
                e.column?.dataField === "isCerrado" &&
                e.rowType === "data" &&
                (idAdmFacturaVentaInProgress || e.data.isCerrado)
            ) {
                e.cellElement.css("cursor", "default");
            }
            onCellPrepared_Total(e);
        };
    }, []);

    const generarAsientosFacturaVentaSAP = ({ isCerrado, idAdmFacturaVenta }) => {
        if (!idAdmFacturaVentaInProgress /* && !isCerrado */) {
            setIdAdmFacturaVentaInProgress(idAdmFacturaVenta);
            context_asientosFacturas
                .invoke("GenerarAsientosFacturaVentaSAP", { idAdmFacturaVenta }, "POST")
                .done(() => {
                    datasource.reload().done(() => {
                        setIdAdmFacturaVentaInProgress(null);
                        notify({
                            message: getTrad("asientosGeneradosCorrectamente"),
                            type: "success",
                            displayTime: 1500,
                            closeOnClick: true,
                        });
                    });
                })
                .catch(() => {
                    setIdAdmFacturaVentaInProgress(null);
                    notify({
                        message: getTrad("errorGenerarAsientos"),
                        type: "error",
                        displayTime: 1500,
                        closeOnClick: true,
                    });
                });
        }
    };

    const onCellClick_tblFacturas = (e) => {
        if (e.column?.name === "impresion") {
            setParams({ idsAdmFacturaVenta: [e.data.idAdmFacturaVenta] });
        } else if (e.column?.dataField === "isCerrado") {
            generarAsientosFacturaVentaSAP(e.data);
        } else if (e.data) {
            setFacturaVentaSel({ ...e.data });
            openPopup();
        }
    };

    const cellRender_impresion = () => {
        const style = { color: "var(--secondary))" };

        return <i className={"dx-icon dx-icon-print container_spanCentrado font-size-lg"} style={style} />;
    };

    const cellRender_isCerrado = (e) => {
        // Todo: temporalmente habilitado siempre
        // return e.data.isCerrado ? (
        //     <i className={`icon_lockOutline container_spanCentrado font-size-lg`} />
        // ) : (
        if (e.data.idAdmFactura_Estado === 3) return null;

        return (
            <i
                className={`dx-icon dx-icon-export ${
                    idAdmFacturaVentaInProgress === e.data.idAdmFacturaVenta
                        ? "primary"
                        : e.data.isCerrado
                        ? "text-success"
                        : ""
                } container_spanCentrado font-size-lg`}
            />
        );
        // );
    };

    const isCerrado_visible =
        [idsUsuario.silverioLaraudogoitia, idsUsuario.jorgeGarcia, idsUsuario.maribelMoranta].includes(
            user.idUsuario
        ) || [idsCargo.desarrollador].includes(user.idCargo);

    return (
        <DataGrid
            dataSource={datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblFacturas}
            onCellPrepared={onCellPrepared_tblFacturas}
            onCellClick={onCellClick_tblFacturas}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={true} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                name={"impresion"}
                caption={" "}
                width={30}
                allowReordering={false}
                allowResizing={false}
                cellRender={cellRender_impresion}
            />
            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"fecha"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmCliente"}
                caption={getTrad("cliente")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <Lookup dataSource={clientes} valueExpr="idAdmCliente" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmFactura_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmFactura_Estado, "", "factura")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmFactura_Estado.store()}
                    valueExpr="idAdmFactura_Estado"
                    displayExpr="denominacion"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                />
            </Column>
            <Column
                dataField={"idMoneda"}
                caption={getTrad("moneda")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPedido"}
                caption={getTrad("numPedido")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                format={formats.percent}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaCobro"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCondicionPago"}
                caption={getTrad("condicionesPagoCobro")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblCondicionPagoCobro.store()}
                    valueExpr="idAdmCondicionPago"
                    displayExpr={displayDeno_CondicionTermino}
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"NCF"}
                caption={"NCF"}
                dataType={"string"}
                showInColumnChooser={idEmpresaPolarier === 4}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"comentario"}
                caption={getTrad("comentario")}
                dataType={"string"}
                visible={true}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                alignment={"center"}
                dataType={"number"}
                visible={true}
                width={200}
                calculateCellValue={calculateCellValue}
                calculateDisplayValue={calculateTotalValueForDisplay}
            />
            <Column
                dataField={"isCerrado"}
                caption={" "}
                width={40}
                alignment={"center"}
                allowReordering={false}
                allowResizing={false}
                allowFiltering={false}
                visible={isCerrado_visible}
                showInColumnChooser={false}
                allowHiding={false}
                cellRender={cellRender_isCerrado}
            />
            {/* <Column
                dataField={"idAdmAlbaranVenta"}
                dataType={"number"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            <Column
                dataField={"tblAdmConceptoVenta"}
                dataType={"object"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            /> */}
            {/* <Column
                caption={" "}
                width={30}
                alignment={"center"}
                cssClass={"p-0"}
                cellComponent={removeCellComponent}
            /> */}
            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

const mapStateToProps = (state) => ({
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    setReport: (report) => dispatch(impresionActions.setReport(report)),
    setParams: (params) => dispatch(impresionActions.setParams(params)),
    close: () => dispatch(impresionActions.close()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DataGridFacturasVenta);
