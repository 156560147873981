import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  dxMensajePregunta,
  getNombreFormulario,
  errorHandler,
  patchRequestHandler,
  authHeader,
  getTrad,
  compressBase64Img_scaleWidth,
  getUserAccessLevel,
  formatDate_noTime_parameter,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";

import {
  Button as TextBoxButton,
  Options as TextBoxButtonOptions,
} from "devextreme-react/text-box";
import notify from "devextreme/ui/notify";
import { Popup, ToolbarItem as ToolbarItemPopUp } from "devextreme-react/popup";
import { Tooltip } from "devextreme-react/tooltip";
import List from "devextreme-react/list";
import ScrollView from "devextreme-react/scroll-view";
import { SelectBox } from "devextreme-react";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import { Button } from "devextreme-react";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { TextBox } from "devextreme-react";
import { CheckBox } from "devextreme-react/check-box";
import { Gallery } from "devextreme-react";
import { LoadPanel } from "devextreme-react/load-panel";
import DataGrid, {
  Column,
  ColumnChooser,
  Editing,
  Paging,
  Pager,
  Scrolling,
  KeyboardNavigation,
  FilterRow,
  SearchPanel,
  LoadPanel as LoadPanelItem,
  Lookup,
  Summary,
  PatternRule,
  CustomRule,
  TotalItem,
} from "devextreme-react/data-grid";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import {
  Form,
  Item,
  RequiredRule,
  ButtonItem,
  TabbedItem,
  Tab,
  TabPanelOptions,
} from "devextreme-react/form";
import Guid from "devextreme/core/guid";

import {
  editCellComponent,
  removeCellComponent,
} from "../../../components/DataGrid/Cells";

import AdjuntarArchivos from "../../../components/AdjuntarArchivos/AdjuntarArchivos";

//Css
import "./Css.scss";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";

const TabDocumentos_Padre_controlEnvios = {
  of: "#TabDocumentos_Padre_controlEnvios",
};
const TabPackingList_Padre_controlEnvios = {
  of: "#TabPackingList_Padre_controlEnvios",
};

class ControlEnvios extends React.Component {
  constructor(props) {
    super(props);
    this.userAccessLevel = getUserAccessLevel(this); //permisos disponibles para la pantalla: admin
    this.refTooltip = React.createRef();
    this.dxpopup_packingList_fotos_REF = React.createRef();
    this.state = {
      formData_envio: {},
      formData_envio_reset: {},
      documentos: [],
      documentos_originalSel: [],
      envio_originalSel: {},
      tipoDocumento_selected: null,
      popup_configuracion_visible: false,
      popup_galeriaDocumentos_visible: false,
      selectedDoc: null,
      editorOptions: {},
      popupEnvio_visible: false,
      popup_Observaciones_visible: false,
      popup_fotos_visible: false,
      currentRow_packingList: {},
      is_item_configSel: false,
      tipoConfigSel: {},
      documentoTab_index: 0,
      dataSource_tblPackingList: {},
      packingList_original: {},
      showLoading_document: false,
      showLoading_packingList: false,
      documents_cargados: false,
      packingList_cargados: false,
      config_tabpanel_index: 0,
      config_tabpanel_itemSel: null,
      gridEnvio_cellEdit: null,
      key: 0,
      accionesModificarDatos_fileUploader: [],
      accionesModificarDatos_packingList: [],
      showLoading_adjuntarArchivos: null,
      tblEnvio_filtrarArchivados: true,
      permisosEscritura: false,
    };

    // REFERENCIAS
    this.grid_datos_REF = React.createRef();
    this.grid_packingList_REF = React.createRef();
    this.grid_correosNEnvio_REF = React.createRef();
    this.form_envio_REF = React.createRef();
    this.form_packingList_REF = React.createRef();
    this.list_configControlEnvios_REF = React.createRef();
    this.dxTextBox_addItem_REF = React.createRef();
    this.dxButton_addItem_REF = React.createRef();
    this.dxSelectBox_addItem_pais_REF = React.createRef();
    this.list_selectConfig_REF = React.createRef();
    this.popup_envio_REF = React.createRef();
    this.dxGallery_documento_REF = React.createRef();
    this.dxTileView_documentos_REF = React.createRef();
    this.dxTileView_fotos_articuloEnvio_REF = React.createRef();

    //EVENTOS
    //#region Grid envios
    this.gridEnvios_onContentReady = this.gridEnvios_onContentReady.bind(this);
    this.gridEnvios_onEditingStart = this.gridEnvios_onEditingStart.bind(this);
    this.gridEnvios_onRowUpdating = this.gridEnvios_onRowUpdating.bind(this);
    this.gridEnvios_onRowUpdated = this.gridEnvios_onRowUpdated.bind(this);
    this.gridEnvios_onEditorPreparing =
      this.gridEnvios_onEditorPreparing.bind(this);
    this.gridEnvios_onRowPrepared = this.gridEnvios_onRowPrepared.bind(this);
    this.gridEnvios_onToolbarPreparing =
      this.gridEnvios_onToolbarPreparing.bind(this);
    this.gridEnvios_Añadir_onClick = this.gridEnvios_Añadir_onClick.bind(this);
    this.render_gridEnvios_archivarIcono =
      this.render_gridEnvios_archivarIcono.bind(this);

    //#endregion
    //#region Popup envios
    this.hiding_popup_envio = this.hiding_popup_envio.bind(this);
    this.hidden_popup_envio = this.hidden_popup_envio.bind(this);

    this.listTipoDocumento_onSelectionChanged =
      this.listTipoDocumento_onSelectionChanged.bind(this);
    this.datasource_documentos_beforeSend =
      this.datasource_documentos_beforeSend.bind(this);
    this.dataSource_tblTipoDocumento_Envio_onLoading =
      this.dataSource_tblTipoDocumento_Envio_onLoading.bind(this);
    this.documentos_onUploadStarted =
      this.documentos_onUploadStarted.bind(this);

    this.checkboxTipoDoc_onContentReady =
      this.checkboxTipoDoc_onContentReady.bind(this);
    this.checkboxTipoDoc_onValueChanged =
      this.checkboxTipoDoc_onValueChanged.bind(this);

    this.gridPackingList_onInitNewRow =
      this.gridPackingList_onInitNewRow.bind(this);
    this.gridPackingList_onExporting =
      this.gridPackingList_onExporting.bind(this);
    this.gridPackingList_onInitialized =
      this.gridPackingList_onInitialized.bind(this);
    this.gridPackingList_onToolbarPreparing =
      this.gridPackingList_onToolbarPreparing.bind(this);
    this.render_gridPackingList_isEnDestino =
      this.render_gridPackingList_isEnDestino.bind(this);
    this.render_gridPackingList_observaciones =
      this.render_gridPackingList_observaciones.bind(this);
    this.render_gridPackingList_fotos =
      this.render_gridPackingList_fotos.bind(this);

    this.gridCorreosNEnvio_onInitialized =
      this.gridCorreosNEnvio_onInitialized.bind(this);
    this.grid_correosNEnvio_onInitNewRow =
      this.grid_correosNEnvio_onInitNewRow.bind(this);
    this.gridCorreosNEnvio_onToolbarPreparing =
      this.gridCorreosNEnvio_onToolbarPreparing.bind(this);

    this.hiding_popup_observaciones =
      this.hiding_popup_observaciones.bind(this);
    this.popup_submit_packingList_observaciones =
      this.popup_submit_packingList_observaciones.bind(this);
    this.onContentReady_popup_fotos =
      this.onContentReady_popup_fotos.bind(this);
    this.hiding_popup_fotos = this.hiding_popup_fotos.bind(this);
    this.btnGuardarFoto_onClick = this.btnGuardarFoto_onClick.bind(this);
    this.editarEnvio_tabbedItem_onInitialized =
      this.editarEnvio_tabbedItem_onInitialized.bind(this);
    this.tabPanelEnvio_onSelectionChanged =
      this.tabPanelEnvio_onSelectionChanged.bind(this);
    this.btnGuardarFoto_render = this.btnGuardarFoto_render.bind(this);
    this.btnAtras_onClick = this.btnAtras_onClick.bind(this);
    //#endregion

    //#region Popup configuración
    this.onShowing_popup_configuracion =
      this.onShowing_popup_configuracion.bind(this);
    this.onHiding_popup_configuracion =
      this.onHiding_popup_configuracion.bind(this);
    this.onHidden_popup_configuracion =
      this.onHidden_popup_configuracion.bind(this);
    this.onHiding_popup_galeriaDocumentos =
      this.onHiding_popup_galeriaDocumentos.bind(this);
    this.onSelectionChanged_list_configControlEnvios =
      this.onSelectionChanged_list_configControlEnvios.bind(this);
    this.onContentReady_list_configControlEnvios =
      this.onContentReady_list_configControlEnvios.bind(this);
    this.onItemDeleting_list_configControlEnvios =
      this.onItemDeleting_list_configControlEnvios.bind(this);
    this.onSelectionChanged_list_selectConfig =
      this.onSelectionChanged_list_selectConfig.bind(this);
    this.onContentReady_list_selectConfig =
      this.onContentReady_list_selectConfig.bind(this);
    this.itemRender_list_selectConfig =
      this.itemRender_list_selectConfig.bind(this);
    this.onValueChanged_textBoxAñadir =
      this.onValueChanged_textBoxAñadir.bind(this);
    this.onEnterKey_textBoxAñadir = this.onEnterKey_textBoxAñadir.bind(this);
    this.PopupConfig_addItem = this.PopupConfig_addItem.bind(this);
    this.PopupConfig_revert = this.PopupConfig_revert.bind(this);
    this.dxButton_PopupConfig_añadir_form =
      this.dxButton_PopupConfig_añadir_form.bind(this);
    this.onClick_atras_tab_config = this.onClick_atras_tab_config.bind(this);
    this.config_tabpanel_submit = this.config_tabpanel_submit.bind(this);

    //#endregion

    //Funciones
    this.Form_envio_guardar = this.Form_envio_guardar.bind(this);
    this.updateArchivos = this.updateArchivos.bind(this);
    this.updateArchivos_documentos = this.updateArchivos_documentos.bind(this);

    //TEMPLATE
    this.template_tile_documento = this.template_tile_documento.bind(this);
    this.render_toolbar_popupEditEnvio =
      this.render_toolbar_popupEditEnvio.bind(this);
    this.listTipoDocumento_onItemRendered =
      this.listTipoDocumento_onItemRendered.bind(this);
    this.listTipoDocumento_itemRender =
      this.listTipoDocumento_itemRender.bind(this);
    this.dxGallery_itemRender = this.dxGallery_itemRender.bind(this);

    //EditorOptions form envío
    this.formEnvio_editorOptions_tblProyecto = {
      dataSource: this.dataSource_tblProyecto.store(),
      valueExpr: "idProyecto",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.formEnvio_editorOptions_tblDestinatario = {
      dataSource: this.dataSource_tblDestinatario.store(),
      valueExpr: "idDestinatario",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.formEnvio_editorOptions_tblEmbarcador = {
      dataSource: this.dataSource_tblEmbarcador.store(),
      valueExpr: "idEmbarcador",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.formEnvio_editorOptions_tblTipoContenedor = {
      dataSource: this.dataSource_tblTipoContenedor.store(),
      valueExpr: "idTipoContenedor",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.formEnvio_editorOptions_tblIncoterm = {
      dataSource: this.dataSource_tblIncoterm.store(),
      valueExpr: "idIncoterm",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.formEnvio_editorOptions_tblPuerto = {
      dataSource: this.dataSource_tblPuerto.store(),
      valueExpr: "idPuerto",
      displayExpr: "denominacion",
      disabled: !this.userAccessLevel["admin"],
    };
    this.editorOptions_textBox = {
      disabled: !this.userAccessLevel["admin"],
    };
    this.editorOptions_dateBox = {
      displayFormat: "dd/MM/yyyy",
      showAnalogClock: false,
      // spellcheck: true,
      type: "date",
      useMaskBehavior: true,
      disabled: !this.userAccessLevel["admin"],
    };
    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  //REFERENCIAS
  get grid_datos() {
    return this.grid_datos_REF.current.instance;
  }

  get grid_packingList() {
    return this.grid_packingList_REF.current.instance;
  }

  get grid_correosNEnvio() {
    return this.grid_correosNEnvio_REF.current.instance;
  }

  get form_envio() {
    return this.form_envio_REF.current.instance;
  }

  get form_packingList() {
    return this.form_packingList_REF.current.instance;
  }

  get list_configControlEnvios() {
    return this.list_configControlEnvios_REF.current.instance;
  }

  get dxTextBox_addItem() {
    return this.dxTextBox_addItem_REF.current.instance;
  }
  get dxSelectBox_addItem_pais() {
    return this.dxSelectBox_addItem_pais_REF.current.instance;
  }
  get list_selectConfig() {
    return this.list_selectConfig_REF.current.instance;
  }
  get popup_envio() {
    return this.popup_envio_REF.current.instance;
  }

  get dxGallery_documento() {
    return this.dxGallery_documento_REF.current.instance;
  }

  get dxTileView_documentos() {
    return this.dxTileView_documentos_REF.current.instance;
  }

  get dxTileView_fotos_articuloEnvio() {
    return this.dxTileView_fotos_articuloEnvio_REF.current.instance;
  }

  get tooltip() {
    return this.refTooltip.current.instance;
  }

  //#region DATSOURCES
  dataSource_tblEnvio = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEnvio",
      key: "idEnvio",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblEnvio_beforeSend(request);
      },
      version: 4,
      onLoading: (request) => {
        this.dataSource_tblEnvio_onLoading(request);
      },
    }),
    expand: [
      "idProyectoNavigation($select=denominacion), \
            idDestinatarioNavigation($select=denominacion,direccion,codigoPostal,ciudad,provincia,pais,nif), \
            idEmbarcadorNavigation($select=denominacion,direccion,codigoPostal,ciudad,provincia,pais,nif), \
            idTipoContenedorNavigation($select=denominacion, colorHexa), \
            idIncotermClienteNavigation($select=denominacion), \
            idIncotermProvNavigation($select=denominacion), \
            idCorreo($select=idCorreo,denominacion) \
        ",
    ],
    map: (item) => {
      item.etaDestino = this.getDateIfExists(item.etaDestino);
      item.etd = this.getDateIfExists(item.etd);
      item.despacho = this.getDateIfExists(item.despacho);
      item.fechaEstimacionCarga = this.getDateIfExists(
        item.fechaEstimacionCarga,
      );
      item.entrega = this.getDateIfExists(item.entrega);
      return item;
    },
  });

  getDateIfExists = (date) => {
    if (date === null) {
      return date;
    }
    return new Date(date);
  };

  dataSource_tblEnvio_beforeSend = (request) => {
    request.timeout = 300000;
    request.async = true;
    if (request.method !== "get" && request.method !== "DELETE") {
      let obj_notificar = $.extend(
        true,
        {},
        { notificar: request.payload.notificar == true },
      );
      delete request.payload.notificar;
      request.params.notificar = obj_notificar.notificar;
    }
    request.headers = { ...authHeader() };
    if (request.method === "PATCH") {
      request.payload = patchRequestHandler(
        this.removeTblEnvioNavigationKeys(request.payload),
      );
      delete Object.values(request.payload).find(
        (x) => x.path === "/tblPackingList",
      )?.value[0].idPackingList;
    }
  };

  dataSource_tblEnvio_onLoading = (request) => {
    const { tblEnvio_filtrarArchivados } = this.state;
    if (tblEnvio_filtrarArchivados) {
      if (typeof request.filter === "undefined") {
        request.filter = ["isArchivado", "=", false];
      } else {
        request.filter = [request.filter, "and", ["isArchivado", "=", false]];
      }
    }
  };

  dataSource_tblPackingList = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPackingList",
      key: "idPackingList",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPackingList_beforeSend(request);
      },
      version: 4,
    }),
    expand: ["tblArticuloEnvio($expand=tblFotoNArticuloEnvio)"],
    postProcess: function (data) {
      $.each(data, function (index, item) {
        $.each(item.tblArticuloEnvio, function (index2, item2) {
          $.each(item2.tblFotoNArticuloEnvio, function (index3, item3) {
            item3.id = item3.idFoto;
          });
        });
      });
      return data;
    },
  });

  dataSource_tblPackingList_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    if (request.method === "get") {
      request.params.idEnvio = this.state.formData_envio.idEnvio;
    }
  };

  dataSource_tblFotoNArticuloEnvio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFotoNArticuloEnvio",
      key: ["idFoto", "idArticuloEnvio"],
      keyType: {
        idDocumento: "Int32",
        idEnvio: "Int32",
      },
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblFotoNArticuloEnvio_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_tblFotoNArticuloEnvio_beforeSend = (request) => {
    request.headers = { ...authHeader() };
  };

  context_tblCorreosNLav = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCorreosNLav",
    entities: {
      OverrideMasivo: {},
    },
    beforeSend: (request) => {
      this.context_tblCorreosNLav_beforeSend(request);
    },
  });

  context_tblCorreosNLav_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.idLavanderia = null;
    request.payload = this.state.formData_envio.idCorreo;
  };

  dataSource_tblPuerto = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPuerto",
      key: "idPuerto",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    expand: ["idPaisNavigation($select=codigo)"],
    map: (itemData) => {
      if (
        itemData["idPaisNavigation"] !== null &&
        typeof itemData["idPaisNavigation"] !== "undefined"
      )
        itemData.denominacion =
          itemData["idPaisNavigation"].codigo + " - " + itemData.denominacion;
      return itemData;
    },
    sort: (e) => {
      if (e.idPaisNavigation !== null) return e.idPaisNavigation.codigo;
      return e.denominacion;
    },
  });

  dataSource_tblIncoterm = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblIncoterm",
      key: "idIncoterm",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblProyecto = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblProyecto",
      key: "idProyecto",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblDestinatario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblDestinatario",
      key: "idDestinatario",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblEmbarcador = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEmbarcador",
      key: "idEmbarcador",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblTipoContenedor = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoContenedor",
      key: "idTipoContenedor",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblPais = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPais",
      key: "idPais",
      keyType: "Int32",
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  dataSource_tblTipoDocumento_Envio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoDocumento_Envio",
      key: "idTipoDocumento_Envio",
      keyType: "Int32",
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
      },
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      onLoading: (loadOptions) => {
        this.dataSource_tblTipoDocumento_Envio_onLoading(loadOptions);
      },
      version: 4,
    }),
    map: function (data) {
      return {
        denominacion: data.denominacion,
        idTipoDocumento_Envio: data.idTipoDocumento_Envio,
        isCompleto:
          data.idTipoDocumento_Envio != null &&
          data.idTipoDocumento_Envio.length > 0
            ? true
            : false,
        tblEnvio_Documento: [],
      };
    },
  });

  datasource_documentos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEnvio_Documento",
      key: ["idDocumento", "idEnvio"],
      keyType: {
        idDocumento: "Int32",
        idEnvio: "Int32",
      },
      errorHandler: (error) => {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_documentos_beforeSend(request);
      },
      version: 4,
    }),
    map: function (data) {
      return {
        id: data.idDocumento,
        idDocumento: data.idDocumento,
        idEnvio: data.idEnvio,
        nombre: data.nombre,
        documento: data.documento,
        extension: data.extension,
        idTipoDocumento_Envio: data.idTipoDocumento_Envio,
      };
    },
  });

  datasource_documentos_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.async = true;
    request.timeout = 300000;
    if (request.method === "get")
      request.params.idEnvio = this.state.formData_envio.idEnvio;
  };

  enum_configListas = [
    {
      idConfig: 1,
      denominacion: getTrad("proyectos").toUpperCase(),
      dataSource: this.dataSource_tblProyecto,
      keyExpr: "idProyecto",
    },
    {
      idConfig: 2,
      denominacion: getTrad("destinatarios").toUpperCase(),
      dataSource: this.dataSource_tblDestinatario,
      keyExpr: "idDestinatario",
    },
    {
      idConfig: 3,
      denominacion: getTrad("embarcadores").toUpperCase(),
      dataSource: this.dataSource_tblEmbarcador,
      keyExpr: "idEmbarcador",
    },
    {
      idConfig: 4,
      denominacion: getTrad("tiposContenedor").toUpperCase(),
      dataSource: this.dataSource_tblTipoContenedor,
      keyExpr: "idTipoContenedor",
    },
    {
      idConfig: 5,
      denominacion: getTrad("puertos").toUpperCase(),
      dataSource: this.dataSource_tblPuerto,
      keyExpr: "idPuerto",
    },
    {
      idConfig: 6,
      denominacion: getTrad("incoterms").toUpperCase(),
      dataSource: this.dataSource_tblIncoterm,
      keyExpr: "idIncoterm",
    },
  ];

  // Nos dice que columnas de tblEnvio apuntan a una misma tabla, para que a la hora de eliminar coincidan los campos de idPuerto o idIncoterm
  enum_tblEnvio_listasSelectivas = {
    tblPuerto: ["idPuertoCarga", "idPuertoDestino"],
    tblIncoterm: ["idIncotermCliente", "idIncotermProv"],
  };
  //#endregion

  //#region templates, eventos y funciones externos al render
  dataSource_tblTipoDocumento_Envio_onLoading = (loadOptions) => {
    let { formData_envio } = this.state;
    let idEnvioSel =
      formData_envio != null && formData_envio.idEnvio != null
        ? this.state.formData_envio.idEnvio
        : -1;
    loadOptions.expand = [
      "tblEnvio_Documento($select=idEnvio;$filter=idEnvio eq " +
        idEnvioSel +
        ")",
    ];
  };

  btnGuardarEnvio_onClick = () => {
    this.Form_envio_guardar();
  };

  render_toolbar_popupEditEnvio = () => {
    let toolbar_bottom = $(
      this.popup_envio.content().parent().find(".dx-popup-bottom"),
    );

    $(toolbar_bottom).css("padding", "5px 20px 15px 20px");
    $(this.popup_envio.content()).css("padding", "5px 20px");

    return (
      <Button
        elementAttr={this.dxButton_guardarEnvio_controlEnvios_elemAttr}
        disabled={!this.userAccessLevel["admin"]}
        text={getTrad("guardar")}
        type="success"
        onClick={(e) => this.btnGuardarEnvio_onClick()}
      ></Button>
    );
  };

  //#region Documentos
  //#region btnDownload documentos
  btnDownloadDocument_onClick = (e, itemData) => {
    let downloadLink = document.createElement("a");
    downloadLink.href =
      "data:" + itemData.extension + ";base64," + itemData.documento;
    downloadLink.download = itemData.nombre;
    downloadLink.click();
  };

  btnDownloadDocument_template = (e, itemData) => {
    return (
      <div
        className="container_spanCentrado dxToolbar_opcion"
        style={this.btnDownloadDocument_template_style}
      >
        <div
          className="icon_Arrow_Down icon_color_hover iconos_svg"
          style={this.btnDownloadDocument_template_style_icon}
          onClick={(e) => this.btnDownloadDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  };
  //#endregion

  //#region btnRemove documentos
  btnRemoveDocument_onClick = (e, itemData) => {
    let _this = this;
    let selectedTab_envio = this.state.tabPanel_envio.option("selectedIndex");
    dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
      [
        getTrad("aceptar"),
        function () {
          if (selectedTab_envio === 2) {
            let documentos = _this.state.documentos,
              tipoDoc_index = null,
              doc_eliminar_index = null;

            $.each(documentos, function (i, el) {
              if (
                el.idTipoDocumento_Envio ===
                _this.state.tipoDocumento_selected.idTipoDocumento_Envio
              ) {
                tipoDoc_index = i;
                $.each(el.tblEnvio_Documento, function (index, item) {
                  if (item.idDocumento === itemData.idDocumento) {
                    doc_eliminar_index = index;
                  }
                });
              }
            });

            if (doc_eliminar_index !== null)
              documentos[tipoDoc_index].tblEnvio_Documento.splice(
                doc_eliminar_index,
                1,
              );

            $("#dxTileView_documentos")
              .dxTileView("instance")
              .option(
                "dataSource",
                documentos[tipoDoc_index].tblEnvio_Documento,
              );
          } else if (selectedTab_envio === 1) {
            let articulo = { ..._this.state.currentRow_packingList },
              doc_eliminar_index = null;

            $.each(articulo.tblFotoNArticuloEnvio, function (index, item) {
              if (item.id === itemData.id) {
                doc_eliminar_index = index;
              }
            });

            if (doc_eliminar_index !== null)
              articulo.tblFotoNArticuloEnvio.splice(doc_eliminar_index, 1);

            _this.dxTileView_fotos_articuloEnvio.option(
              "dataSource",
              articulo.tblFotoNArticuloEnvio,
            );
          }
        },
        "danger",
      ],
      [getTrad("cancelar"), function () {}],
    ]);
  };

  btnRemoveDocument_template = (e, itemData) => {
    return (
      <div
        className="container_spanCentrado dxToolbar_opcion"
        style={this.btnRemoveDocument_template_style}
      >
        <div
          className="icon_Cerrar icon_color_hover iconos_svg"
          style={this.btnRemoveDocument_template_style_icon}
          onClick={(e) => this.btnRemoveDocument_onClick(e, itemData)}
        ></div>
      </div>
    );
  };
  //#endregion

  tile_documento_onMouseEnter = (e, itemData) => {
    let selectedTab_envio = this.state.tabPanel_envio.option("selectedIndex");
    if (selectedTab_envio === 2)
      $("#tooltip_" + itemData.idDocumento)
        .dxTooltip("instance")
        .show();
    else if (selectedTab_envio === 1)
      $("#tooltip_" + itemData.idFoto)
        .dxTooltip("instance")
        .show();
  };

  tile_documento_onMouseLeave = (e, itemData) => {
    let selectedTab_envio = this.state.tabPanel_envio.option("selectedIndex");
    if (selectedTab_envio === 2)
      $("#tooltip_" + itemData.idDocumento)
        .dxTooltip("instance")
        .hide();
    else if (selectedTab_envio === 1)
      $("#tooltip_" + itemData.idFoto)
        .dxTooltip("instance")
        .hide();
  };

  tile_documento_alertTipoDoc = () => {
    notify({
      message: getTrad("alert_SoloAbrirImagenesPdf"),
      type: "error",
      displayTime: "1500",
      closeOnClick: true,
    });
  };

  template_tile_documento = (itemData) => {
    let is_popupVisible = !$.isEmptyObject(this.state.formData_envio);
    let selectedTab_envio = is_popupVisible
      ? this.state.tabPanel_envio.option("selectedIndex")
      : 0;
    let _this = this,
      toolbar = (
        <Toolbar
          style={this.template_tile_documento_style}
          elementAttr={this.template_tile_documento_elemAttr}
        >
          <ToolbarItem
            location="after"
            render={(e) => _this.btnDownloadDocument_template(e, itemData)}
          />
          <ToolbarItem
            location="after"
            visible={
              (selectedTab_envio === 2 &&
                !this.state.formData_envio.isArchivado) ||
              selectedTab_envio === 1
            }
            render={(e) => _this.btnRemoveDocument_template(e, itemData)}
          />
        </Toolbar>
      ),
      fileName = (
        <div
          id={
            "filename_" +
            (selectedTab_envio === 2 ? itemData.idDocumento : itemData.idFoto)
          }
          style={this.template_tile_documento_file_style}
          onMouseEnter={(e) => this.tile_documento_onMouseEnter(e, itemData)}
          onMouseLeave={(e) => this.tile_documento_onMouseLeave(e, itemData)}
        >
          {itemData.nombre}
        </div>
      ),
      tooltip = (
        <Tooltip
          deferRendering={false}
          elementAttr={{
            id:
              "tooltip_" +
              (selectedTab_envio === 2
                ? itemData.idDocumento
                : itemData.idFoto),
          }}
          target={
            "#filename_" +
            (selectedTab_envio === 2 ? itemData.idDocumento : itemData.idFoto)
          }
          position="bottom"
          closeOnOutsideClick={false}
        >
          <div>{itemData.nombre}</div>
        </Tooltip>
      );

    function _onClick() {
      let dxTileView_documentos_data =
        selectedTab_envio === 2
          ? _this.dxTileView_documentos.option("dataSource")._array
          : _this.dxTileView_fotos_articuloEnvio.option("dataSource");
      var imagenes = $.grep(dxTileView_documentos_data, function (doc) {
        return (
          doc.extension.split("/")[0] === "image" ||
          doc.extension.split("/")[1] === "pdf"
        );
      });

      _this.dxGallery_documento.beginUpdate();
      _this.dxGallery_documento.option("dataSource", imagenes);
      _this.dxGallery_documento.option("selectedItem", itemData);
      _this.dxGallery_documento.endUpdate();

      _this.setState({ popup_galeriaDocumentos_visible: true });
    }

    if (itemData.extension.split("/")[0] === "image") {
      return (
        <Fragment>
          <img
            className="image"
            style={{
              backgroundImage:
                "url(data:" +
                itemData.extension +
                ";base64," +
                itemData.documento +
                ")",
            }}
            onClick={() => {
              _onClick();
            }}
          />
          {tooltip}
          {toolbar}
          {fileName}
        </Fragment>
      );
    } else if (itemData.extension.split("/")[1] === "pdf") {
      return (
        <Fragment>
          <div
            className={"container_spanCentrado  dx-icon-exportpdf"}
            style={this.template_tile_documento_pdf}
            onClick={() => {
              _onClick();
            }}
          />
          {tooltip}
          {toolbar}
          {fileName}
        </Fragment>
      );
    } else {
      let icon;
      if (itemData.extension.split("/")[0] === "video") {
        icon = "dx-icon-video";
      } else if (
        itemData.extension.includes("excel") ||
        itemData.extension.includes("spreadsheetml")
      ) {
        icon = "dx-icon-exportxlsx";
      } else {
        icon = "dx-icon-doc";
      }

      return (
        <Fragment>
          <div
            style={this.template_tile_documento_other}
            className={"container_spanCentrado " + icon}
            onClick={this.tile_documento_alertTipoDoc}
          ></div>
          {tooltip}
          {toolbar}
          {fileName}
        </Fragment>
      );
    }
  };

  documentos_onUploadStarted(e) {
    var reader = new FileReader(),
      _this = this;

    if (e.file.size > 5242880) {
      notify({
        message: getTrad("subirArchivo"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    } else {
      reader.onloadend = function () {
        let selectedTab_envio =
          _this.state.tabPanel_envio.option("selectedIndex");
        if (reader.result !== "") {
          var fileSplit = reader.result.split(",");
          var extension = e.file.type;
          var base64 = fileSplit[1];
          var fileName = e.file.name;

          function insertarDocumento() {
            var añadirDoc;
            if (selectedTab_envio === 2) {
              añadirDoc = $.merge(
                _this.state.tipoDocumento_selected.tblEnvio_Documento,
                [
                  {
                    idDocumento: new Guid(),
                    nombre: fileName,
                    documento: base64,
                    extension: extension,
                  },
                ],
              );
            } else if (selectedTab_envio === 1) {
              añadirDoc = $.merge(
                { ..._this.state.currentRow_packingList.tblFotoNArticuloEnvio },
                [
                  {
                    id: new Guid(),
                    nombre: fileName,
                    documento: base64,
                    extension: extension,
                  },
                ],
              );
            }

            e.component.option({
              value: [],
            });
            if (selectedTab_envio === 2)
              _this.dxTileView_documentos.option("dataSource", añadirDoc);
            else if (selectedTab_envio === 1)
              _this.dxTileView_fotos_articuloEnvio.option(
                "dataSource",
                añadirDoc,
              );
          }

          //Compress
          if (extension.split("/")[0] === "image") {
            compressBase64Img_scaleWidth(reader.result, 800).then(
              function (compressedBase64) {
                base64 = compressedBase64;
                insertarDocumento();
              },
            );
          } else {
            insertarDocumento();
          }
        }
      };
      reader.readAsDataURL(e.file);
    }
    e.request.abort();
  }

  //#endregion

  //#region lista tipos documento
  listTipoDocumento_onSelectionChanged(e) {
    if (e.addedItems.length > 0) {
      this.setState({
        tipoDocumento_selected: e.addedItems[0],
        documentoTab_index: 1,
        showLoading_document:
          this.state.documents_cargados === false ? true : false,
        showLoading_packingList:
          this.state.packingList_cargados === false ? true : false,
      });
    }
  }

  listTipoDocumento_itemRender(itemData) {
    return (
      <Box direction="row" width="100%" height={"100%"}>
        <ItemBox baseSize={50}>
          <CheckBox
            readOnly={
              !$.isEmptyObject(this.state.formData_envio) &&
              this.state.formData_envio.isArchivado
            }
            onContentReady={(e) =>
              this.checkboxTipoDoc_onContentReady(e, itemData)
            }
            onValueChanged={(e) =>
              this.checkboxTipoDoc_onValueChanged(e, itemData)
            }
          />
        </ItemBox>
        <ItemBox ratio={1}>
          <div className="container_spanCentrado he-100">
            <div className="font_family font_size_titulo he-100 d-flex align-items-center">
              {itemData.denominacion.toUpperCase()}
            </div>
          </div>
        </ItemBox>
      </Box>
    );
  }

  listTipoDocumento_onItemRendered(e) {
    //#region Resize de los items de lista para que ocupen todo el height
    let popupHeight_percent =
        parseInt(this.popup_envio.option("height")) * 0.01,
      popupHeight_pixel = $(window).height() * popupHeight_percent,
      listaConfig_height = popupHeight_pixel - 40 - 49 - 45 - 18 - 25 - 25; // 40 - padding(20 top y 20 bottom)| 49 - Cabecera popup con titulo | 45 - cabecera tabPanel | 18- padding top con respeto al tabpanel || 25 padidngs del tabpanel de documentacion

    let selectConfig_count = e.component.option("items").length,
      selectConfig_itemHeight =
        Math.trunc(listaConfig_height / selectConfig_count) - 1;

    e.itemElement.height(selectConfig_itemHeight);
    //#endregion
  }

  checkboxTipoDoc_onContentReady(e, itemData) {
    e.element.parent().addClass("justify-content-center text-center ml-5");
    e.component.option("value", itemData.isCompleto);
  }

  checkboxTipoDoc_onValueChanged = (e, itemData) => {
    $.each(this.state.documentos, function (index, item) {
      if (item.idTipoDocumento_Envio === itemData.idTipoDocumento_Envio) {
        item.isCompleto = e.value;
      }
    });
    if (typeof e.event !== "undefined") e.event.stopPropagation();
  };

  render_gridPackingList_isEnDestino(itemData) {
    let rowData = itemData.data;
    itemData.data.isEnDestino = itemData.value != null ? itemData.value : false;
    let icono = rowData.isEnDestino
      ? "icon_Circle_check"
      : "icon_Circle_uncheck";
    const disabledIfReadOnly = !this.userAccessLevel["admin"]
      ? " disabledComponent"
      : "";
    return (
      <div
        onClick={() => {
          if (this.userAccessLevel["admin"]) {
            if (
              this.state.formData_envio != null &&
              !this.state.formData_envio.isArchivado
            ) {
              itemData.setValue(
                itemData.value != null ? !itemData.value : false,
              );
            }
          }
        }}
        className={
          "container_spanCentrado center-svg iconos_svg " +
          icono +
          disabledIfReadOnly
        }
      />
    );
  }

  render_gridPackingList_observaciones(itemData) {
    let color = itemData.row.data.observaciones != null ? "" : "opacity-2",
      brightness =
        itemData.row.data.observaciones != null ? "brightness(0.8)" : "";
    let _this = this;
    return (
      <div
        style={{ cursor: "pointer", filter: brightness }}
        onClick={() => {
          if (
            itemData.component
              .getController("validating")
              .validate(true)
              .done(function (isValid) {
                if (isValid) {
                  _this.setState({
                    currentRow_packingList: itemData.row.data,
                    popup_Observaciones_visible: true,
                  });
                }
              })
          );
        }}
        className={
          "container_spanCentrado dx-icon dx-icon-menu font-size-lg " + color
        }
      />
    );
  }

  render_gridPackingList_fotos(itemData) {
    let color =
      itemData.row.data.tblFotoNArticuloEnvio != undefined &&
      itemData.row.data.tblFotoNArticuloEnvio.length > 0
        ? ""
        : "opacity-2";
    let brightness =
      itemData.row.data.tblFotoNArticuloEnvio != undefined &&
      itemData.row.data.tblFotoNArticuloEnvio.length > 0
        ? "brightness(0.8)"
        : "";
    let _this = this;

    return (
      <div
        style={{ cursor: "pointer", filter: brightness }}
        onClick={() => {
          if (
            itemData.component
              .getController("validating")
              .validate(true)
              .done(function (isValid) {
                if (isValid) {
                  _this.setState({
                    currentRow_packingList: itemData.data,
                    popup_fotos_visible: true,
                  });
                }
              })
          );
        }}
        className={"dx-icon dx-icon-photo font-size-xl " + color}
      />
    );
  }

  //#endregion

  dxGallery_itemRender(itemData) {
    if (itemData.extension.split("/")[0] === "image") {
      return (
        <img
          className="image"
          style={{
            backgroundImage:
              "url(data:" +
              itemData.extension +
              ";base64," +
              itemData.documento,
          }}
        />
      );
    } else if (itemData.extension.split("/")[1] === "pdf") {
      return (
        <object
          toolbar="0"
          width="100%"
          height="100%"
          data={"data:" + itemData.extension + ";base64," + itemData.documento}
        />
      );
    }
  }

  editarEnvio_tabbedItem_onInitialized(e) {
    this.setState({ tabPanel_envio: e.component });
  }

  btnAtras_onClick(_this) {
    this.setState({ tipoDocumento_selected: null, documentoTab_index: 0 });
  }

  //#region Grid envios

  gridEnvios_onContentReady(e) {
    setTimeout(() => {
      this.loadPanel_hide();
      e.component.option("loadPanel.enabled", true);
    }, 0);
  }

  gridEnvios_onRowUpdated(e) {
    this.setState({ gridEnvio_cellEdit: null });
    this.grid_datos.refresh();
    // this.dataSource_tblEnvio.reload();
  }
  gridEnvios_onRowUpdating(e) {
    let _this = this;
    if (
      _this.state.gridEnvio_cellEdit == null &&
      e.oldData.idCorreo.length > 0
    ) {
      e.cancel = true;
      _this.setState({ gridEnvio_cellEdit: true });
      dxMensajePregunta(getTrad("preg_cambiosEnvio"), [
        [
          getTrad("si"),
          function () {
            e.newData.notificar = true;
            e.cancel = false;
          },
          "danger",
        ],
        [
          getTrad("no"),
          function () {
            e.newData.notificar = false;
            e.cancel = false;
          },
        ],
      ]);
    }
  }

  gridEnvios_onEditingStart(e) {
    if (e.column != null) {
      e.cancel = e.data.isArchivado;
    } else {
      e.cancel = true;
    }
    if (e.column == null) {
      // Botón editar envío
      const rowData = { ...e.data };
      let _this = this;

      this.setState(
        {
          formData_envio: rowData,
          popupEnvio_visible: true,
          envio_originalSel: $.extend(true, {}, rowData),
          documents_cargados: false,
          packingList_cargados: false,
          documentos: [],
          documentos_originalSel: [],
          packingList_original: {},
          dataSource_tblPackingList: {},
        },
        () => {
          setTimeout(() => {
            //#region Tipos de documento
            this.dataSource_tblTipoDocumento_Envio
              .load()
              .done(function (tipoDocumento) {
                //#region Documentos y PackingList
                $.when(
                  _this.datasource_documentos.reload(),
                  _this.dataSource_tblPackingList.reload(),
                ).then(function (items_documentos, items_packingList) {
                  let documentos = items_documentos[0];
                  let packingList = items_packingList[0];
                  if (packingList.length === 0) {
                    packingList = [{ tblArticuloEnvio: [] }];
                  }
                  let tipoDocumentos = tipoDocumento;
                  $.each(tipoDocumentos, function (index, item) {
                    $.each(documentos, function (idDoc, doc) {
                      if (
                        item.idTipoDocumento_Envio === doc.idTipoDocumento_Envio
                      ) {
                        item.tblEnvio_Documento.push(doc);
                      }
                    });
                  });
                  _this.setState({
                    documentos: tipoDocumentos,
                    documentos_originalSel: $.extend(true, [], tipoDocumentos),
                    packingList_original: $.extend(true, {}, packingList[0]),
                    dataSource_tblPackingList: packingList[0],
                    showLoading_document: false,
                    showLoading_packingList: false,
                    documents_cargados: true,
                    packingList_cargados: true,
                  });
                });
                //#endregion
              });
            //#endregion
          }, 20);
        },
      );
    }
  }

  gridEnvios_onEditorPreparing(e) {
    if (e.parentType === "dataRow") {
      e.editorOptions.disabled = e.row.data.isArchivado;
    }
  }

  gridEnvios_onRowPrepared(e) {
    if (e.rowType === "data") {
      if (e.data.isArchivado) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  gridEnvios_Añadir_onClick(e) {
    let data = { idEnvio: null };
    data.tblPackingList = [{ tblArticuloEnvio: [] }];
    data.idCorreo = [];

    let _this = this;
    this.setState(
      {
        formData_envio: data,
        popupEnvio_visible: true,
        envio_originalSel: $.extend(true, {}, data),
        packingList_original: $.extend(true, {}, data.tblPackingList[0]),
        dataSource_tblPackingList: data.tblPackingList[0],
        packingList_cargados: true,
      },
      () => {
        setTimeout(() => {
          _this.dataSource_tblTipoDocumento_Envio
            .load()
            .done(function (documentos) {
              _this.setState({
                documentos: documentos,
                documentos_originalSel: [],
                showLoading_document: false,
                showLoading_packingList: false,
                documents_cargados: true,
              });
            });
        });
      },
      100,
    );
  }

  gridEnvios_onToolbarPreparing = (e) => {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        options: {
          style: { left: "20px" },
          text: getTrad("añadir"),
          icon: "plus",
          onClick: _this.gridEnvios_Añadir_onClick,
        },
        disabled: !this.userAccessLevel["admin"], //( 1 /* user */, 1 /* required */)
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("configuracion"),
          onClick: function () {
            _this.setState({ popup_configuracion_visible: true });
          },
        },
        disabled: !this.userAccessLevel["admin"],
      },
      {
        location: "after",
        widget: "dxSwitch",
        options: {
          height: 36,
          defaultValue: this.state.tblEnvio_filtrarArchivados
            ? this.state.tblEnvio_filtrarArchivados
            : true,
          value: this.state.tblEnvio_filtrarArchivados, //value == null ? true : value}
          switchedOnText: "Activos" /* this.getTrad('activo') */,
          switchedOffText: "Todos" /* this.getTrad('noActivo') */,
          onValueChanged: this.dxSwitch_visibilidadArchivados_onValueChanged,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(false);
          },
        },
      },
    );
  };

  dxSwitch_visibilidadArchivados_onValueChanged = () => {
    this.setState(
      {
        tblEnvio_filtrarArchivados: !this.state.tblEnvio_filtrarArchivados,
      },
      () => this.dataSource_tblEnvio.reload(),
    );
  };

  render_gridEnvios_archivarIcono(e) {
    let rowData = e.data;
    let icono = "";
    let mensaje = "";
    let _this = this;
    const disabledIfReadOnly =
      !this.userAccessLevel["admin"] && "disabledComponent";
    if (rowData.isArchivado) {
      icono = "icon_Circle_check ";
      mensaje = "Deseas reanudar el envío?";
    } else {
      icono = "icon_Circle_uncheck ";
      mensaje = "Deseas finalizar el envío?";
    }
    return (
      <div
        onClick={() => {
          if (this.userAccessLevel["admin"]) {
            dxMensajePregunta(mensaje, [
              [
                getTrad("aceptar"),
                function (e) {
                  _this.dataSource_tblEnvio
                    .store()
                    .update(rowData.idEnvio, {
                      isArchivado: !rowData.isArchivado,
                    })
                    .done(function () {
                      _this.grid_datos.refresh(true);
                    });
                },
                "danger",
              ],
              [getTrad("cancelar"), function (e) {}, ""],
            ]);
          }
        }}
        className={
          "container_spanCentrado center-svg iconos_svg " +
          icono +
          disabledIfReadOnly
        }
        style={{ fontSize: "23px" }}
      />
    );
  }

  render_gridEnvios_eliminarIcono = (itemData) => {
    let _this = this;
    const disabledIfReadOnly =
      !this.userAccessLevel["admin"] && "disabledComponent";
    return (
      <div
        onClick={() => {
          if (this.userAccessLevel["admin"]) {
            dxMensajePregunta(getTrad("preg_RegistroEliminar"), [
              [
                getTrad("aceptar"),
                function () {
                  _this.dataSource_tblEnvio
                    .store()
                    .remove(itemData.data.idEnvio)
                    .done(function () {
                      _this.grid_datos.refresh(true).done(function () {
                        notify({
                          message: getTrad("aviso_C_RegistroActualizado"),
                          type: "success",
                          displayTime: "1500",
                          closeOnClick: true,
                        });
                      });
                    });
                },
                "danger",
              ],
              [getTrad("cancelar"), function () {}],
            ]);
          }
        }}
        className={
          "container_spanCentrado center-svg icon_Cerrar iconos_svg " +
          disabledIfReadOnly
        }
      />
    );
  };

  render_gridEnvios_tipoContenedor = (cellInfo) => {
    let itemData = cellInfo.data;
    var bgColor = itemData.idTipoContenedorNavigation?.colorHexa ?? "white";
    return (
      <div
        className={"container_spanCentrado chip-noColor"}
        style={{ backgroundColor: bgColor }}
      >
        {cellInfo.text}
      </div>
    );
  };

  render_gridEnvios_comentarios = (htmlNode, gridCell) => {
    htmlNode[0].id = gridCell.rowIndex + "-" + gridCell.columnIndex;
    htmlNode[0].textContent = gridCell.text;
    htmlNode[0].addEventListener("mouseover", () => {
      this.tooltip.content()[0].innerHTML = `
            <div>
              <div class="controlEnvios_tooltipComentarios">${gridCell.text}</div>
            </div>`;
      if (gridCell.text != "") {
        this.tooltip.show(`td[id="${htmlNode[0].id}"]`);
      }
    });
    htmlNode[0].addEventListener("mouseout", () => {
      this.tooltip.hide();
    });

    return htmlNode;
  };

  //#endregion

  //#endregion

  filter_tblPackingList = (filterValue) => {
    return [
      [
        "(tblPackingList/any(pl: contains(tolower(pl/personaRecibido), tolower('" +
          filterValue +
          "'))\
        or (pl/tblArticuloEnvio/any(articulo: contains(articulo/descripcion, tolower('" +
          filterValue +
          "')))) \
        ))",
      ], //funciona
      "or",
      [
        "(tblPackingList/any(pl: (pl/tblArticuloEnvio/any(\
           articulo: contains(articulo/dimensiones, '" +
          filterValue +
          "')\
           ) eq true)))",
      ], //funciona]
    ];
  };

  filter_tblEnvio_Documento = (filterValue) => {
    return [
      "(tblEnvio_Documento/any(doc: contains(tolower(doc/nombre), tolower('" +
        filterValue +
        "'))) eq true)",
    ];
  };

  filter_idCorreo = (filterValue) => {
    return [
      "idCorreo/any(correo: contains(tolower(correo/denominacion), tolower('" +
        filterValue +
        "')))",
    ];
  };

  render() {
    let { currentRow_packingList } = this.state;
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={
            !this.userAccessLevel["admin"] && this.avisoSoloLectura()
          }
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup  className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="ControlEnvios" className="formContainer">
            <DataGrid
              ref={this.grid_datos_REF}
              dataSource={this.dataSource_tblEnvio}
              repaintChangesOnly={false}
              height={"100%"}
              width={"100%"}
              showColumnLines={false}
              showRowLines={true}
              columnAutoWidth={true}
              rowAlternationEnabled={true}
              loadPanel={this.loadPanelOptions}
              onContentReady={this.gridEnvios_onContentReady}
              onEditingStart={this.gridEnvios_onEditingStart}
              onRowUpdating={this.gridEnvios_onRowUpdating}
              onRowUpdated={this.gridEnvios_onRowUpdated}
              onEditorPreparing={this.gridEnvios_onEditorPreparing}
              onRowPrepared={this.gridEnvios_onRowPrepared}
              onToolbarPreparing={this.gridEnvios_onToolbarPreparing}
            >
              <Scrolling showScrollbar="always" />
              <LoadPanelItem enabled={true} />
              <SearchPanel
                visible={true}
                width={240}
                placeholder={getTrad("busqueda")}
              />
              <FilterRow visible={true} />
              <Paging enabled={true} pageSize={50} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[20, 50, 100]}
                showInfo={true}
              />
              {this.userAccessLevel["admin"] && (
                <Editing
                  mode="cell"
                  refreshMode="repaint"
                  allowUpdating={true}
                  selectTextOnEditStart={true}
                />
              )}
              <KeyboardNavigation
                enterKeyAction="moveFocus"
                enterKeyDirection="row"
                editOnKeyPress={true}
              />
              <ColumnChooser enabled={true} height={600} />
              <Column
                caption=" "
                width={35}
                alignment="center"
                cssClass="p-0"
                allowEditing={false}
                fixed={true}
                fixedPosition="left"
                cellComponent={editCellComponent}
              />
              <Column
                caption=" "
                dataField="isArchivado"
                width={35}
                alignment="center"
                cssClass="p-0"
                allowEditing={false}
                allowFiltering={false}
                allowSorting={false}
                sortIndex={0}
                fixed={true}
                fixedPosition="left"
                sortOrder="asc"
                cellRender={this.render_gridEnvios_archivarIcono}
              />
              <Column
                dataField="idEnvio"
                sortOrder="asc"
                visible={false}
                showInColumnChooser={false}
              />
              <Column
                dataField="idTipoContenedor"
                caption={getTrad("tipoCont")}
                allowFiltering={true}
                placeholder=""
                alignment="center"
                width={160}
                visible={true}
                fixed={true}
                fixedPosition="left"
                cellRender={this.render_gridEnvios_tipoContenedor}
              >
                <Lookup
                  dataSource={this.dataSource_tblTipoContenedor.store()}
                  valueExpr="idTipoContenedor"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="idProyecto"
                caption={getTrad("proyecto")}
                allowFiltering={true}
                placeholder=""
                width={195}
                fixed={true}
                fixedPosition="left"
                visible={true}
              >
                <Lookup
                  dataSource={this.dataSource_tblProyecto.store()}
                  valueExpr="idProyecto"
                  displayExpr="denominacion"
                  onValueChanged={"Brother"}
                />
              </Column>
              <Column
                dataField="idDestinatario"
                caption={getTrad("destinatario")}
                allowFiltering={true}
                placeholder=""
                width={190}
                visible={true}
              >
                <Lookup
                  dataSource={this.dataSource_tblDestinatario.store()}
                  valueExpr="idDestinatario"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="idEmbarcador"
                caption={getTrad("embarcador")}
                allowFiltering={true}
                placeholder=""
                width={165}
                visible={true}
              >
                <Lookup
                  dataSource={this.dataSource_tblEmbarcador.store()}
                  valueExpr="idEmbarcador"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="idIncotermProv"
                allowFiltering={true}
                placeholder=""
                width={150}
                caption={getTrad("incProv")}
                alignment="center"
                visible={false}
              >
                <Lookup
                  dataSource={this.dataSource_tblIncoterm.store()}
                  valueExpr="idIncoterm"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="numCont"
                caption={getTrad("numCont")}
                width={110}
                alignment="center"
                visible={true}
              />
              <Column
                dataField="idPuertoCarga"
                caption={getTrad("puertoCarga")}
                allowFiltering={true}
                placeholder=""
                width={135}
                visible={true}
              >
                <Lookup
                  dataSource={this.dataSource_tblPuerto.store()}
                  valueExpr="idPuerto"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="idPuertoDestino"
                caption={getTrad("puertoDestino")}
                allowFiltering={true}
                placeholder=""
                width={135}
                visible={true}
              >
                <Lookup
                  dataSource={this.dataSource_tblPuerto.store()}
                  valueExpr="idPuerto"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="idIncotermCliente"
                caption={getTrad("incCliente")}
                allowFiltering={true}
                placeholder=""
                width={95}
                alignment="center"
                visible={false}
              >
                <Lookup
                  dataSource={this.dataSource_tblIncoterm.store()}
                  valueExpr="idIncoterm"
                  displayExpr="denominacion"
                />
              </Column>
              <Column
                dataField="blNumero"
                caption={getTrad("blNumero")}
                width={135}
                alignment="center"
                visible={false}
                showInColumnChooser={false}
                groupIndex={0}
              />
              <Column
                dataField="fechaEstimacionCarga"
                caption={getTrad("fechaEstimacionCarga")}
                width={160}
                alignment="center"
                dataType="date"
                format="dd/MM/yyyy"
                editorOptions={this.editorOptions_dateBox}
              />
              <Column
                dataField="etd"
                caption={getTrad("etd")}
                width={125}
                alignment="center"
                dataType="date"
                format="dd/MM/yyyy"
                editorOptions={this.editorOptions_dateBox}
              />
              <Column
                dataField="etaDestino"
                caption={getTrad("etaDestino")}
                width={125}
                alignment="center"
                dataType="date"
                format="dd/MM/yyyy"
                editorOptions={this.editorOptions_dateBox}
                visible={true}
              />
              <Column
                dataField="despacho"
                caption={getTrad("despacho")}
                width={125}
                alignment="center"
                dataType="date"
                format="dd/MM/yyyy"
                editorOptions={this.editorOptions_dateBox}
                visible={false}
              />
              <Column
                dataField="entrega"
                caption={getTrad("entrega")}
                width={125}
                alignment="center"
                dataType="date"
                format="dd/MM/yyyy"
                editorOptions={this.editorOptions_dateBox}
                visible={false}
              />
              <Column
                dataField="comentarios"
                caption={"Comentarios"}
                width={350}
                minWidth={200}
                alignment="center"
                visible={true}
                allowFiltering={true}
                cellTemplate={this.render_gridEnvios_comentarios}
              />
              <Column
                caption=" "
                width={35}
                alignment="center"
                cssClass="p-0"
                allowEditing={false}
                fixed={true}
                fixedPosition="right"
                cellRender={this.render_gridEnvios_eliminarIcono}
                showInColumnChooser={false}
              />
              <Column
                dataField={"tblEnvio_Documento"}
                visible={false}
                allowSearch={true}
                allowFiltering={true}
                calculateFilterExpression={this.filter_tblEnvio_Documento}
                showInColumnChooser={false}
              />
              <Column
                dataField={"tblPackingList"}
                visible={false}
                allowSearch={true}
                allowFiltering={true}
                calculateFilterExpression={this.filter_tblPackingList}
                showInColumnChooser={false}
              />
              <Column
                dataField={"idCorreo"}
                visible={false}
                allowSearch={true}
                allowFiltering={true}
                dataType={"object"}
                calculateFilterExpression={this.filter_idCorreo}
                showInColumnChooser={false}
              />
            </DataGrid>

            <Popup
              title={getTrad("configuracion")}
              showTitle={true}
              deferRendering={false}
              visible={this.state.popup_configuracion_visible}
              width={875}
              height={"60%"}
              onShowing={this.onShowing_popup_configuracion}
              onHiding={this.onHiding_popup_configuracion}
              onHidden={this.onHidden_popup_configuracion}
            >
              <Box direction="row" width="100%" height={"100%"}>
                <ItemBox ratio={1}>
                  <List
                    items={this.enum_configListas}
                    elementAttr={this.list_selectConfig_elementAttr}
                    ref={this.list_selectConfig_REF}
                    height="100%"
                    selectionMode="single"
                    displayExpr="denominacion"
                    keyExpr="idConfig"
                    scrolling={this.list_selectConfig_scrolling}
                    pageLoadMode="scrollBottom"
                    loadPanel={this.list_selectConfig_loadPanel}
                    onContentReady={this.onContentReady_list_selectConfig}
                    onSelectionChanged={
                      this.onSelectionChanged_list_selectConfig
                    }
                    itemRender={this.itemRender_list_selectConfig}
                  ></List>
                </ItemBox>

                <ItemBox baseSize={30} />
                <ItemBox ratio={1}>
                  <Box direction="col" width="100%" height={"100%"}>
                    <ItemBox
                      baseSize={30}
                      visible={
                        this.state.config_tabpanel_index === 0 &&
                        this.state.tipoConfigSel.idConfig !== 2 &&
                        this.state.tipoConfigSel.idConfig !== 3
                      }
                    >
                      <TextBox
                        placeholder={getTrad("añadir")}
                        maxLength={50}
                        ref={this.dxTextBox_addItem_REF}
                        onValueChanged={this.onValueChanged_textBoxAñadir}
                        onEnterKey={this.onEnterKey_textBoxAñadir}
                      >
                        <TextBoxButton name="revertButton" location="after">
                          <TextBoxButtonOptions
                            icon="revert"
                            type="danger"
                            visible={this.state.is_item_configSel}
                            elementAttr={this.PopupConfig_revert_elementAttr}
                            onClick={this.PopupConfig_revert}
                          ></TextBoxButtonOptions>
                        </TextBoxButton>
                        <TextBoxButton name="addItem" location="after">
                          <TextBoxButtonOptions
                            icon={
                              this.state.is_item_configSel ? "save" : "plus"
                            }
                            type="default"
                            elementAttr={this.PopupConfig_addItem_elementAttr}
                            onClick={this.PopupConfig_addItem}
                          ></TextBoxButtonOptions>
                        </TextBoxButton>
                      </TextBox>
                    </ItemBox>

                    <ItemBox
                      baseSize={30}
                      visible={
                        this.state.config_tabpanel_index === 0 &&
                        this.state.tipoConfigSel.idConfig !== 2 &&
                        this.state.tipoConfigSel.idConfig !== 3
                      }
                    >
                      <SelectBox
                        elementAttr={this.dxSelectBox_addItem_pais_elementAttr}
                        ref={this.dxSelectBox_addItem_pais_REF}
                        className="mt-3"
                        dataSource={this.dataSource_tblPais.store()}
                        displayExpr="denominacion"
                        valueExpr="idPais"
                        placeholder={getTrad("pais")}
                      />
                    </ItemBox>

                    <ItemBox
                      baseSize={30}
                      visible={
                        this.state.config_tabpanel_index === 0 &&
                        (this.state.tipoConfigSel.idConfig === 2 ||
                          this.state.tipoConfigSel.idConfig === 3)
                      }
                    >
                      <Toolbar>
                        <ToolbarItem location="after">
                          <Button
                            width={120}
                            text={getTrad("añadir")}
                            type="success"
                            icon="plus"
                            onClick={this.dxButton_PopupConfig_añadir_form}
                          ></Button>
                        </ToolbarItem>
                      </Toolbar>
                    </ItemBox>

                    <ItemBox
                      baseSize={15}
                      visible={this.state.config_tabpanel_index === 0}
                    />
                    <ItemBox ratio={1}>
                      <TabPanel
                        height={"100%"}
                        selectedIndex={this.state.config_tabpanel_index}
                        elementAttr={this.elementAttr_tabPanelConfig}
                        animationEnabled={true}
                        deferRendering={false}
                      >
                        <TabPanelItem tabIndex={0}>
                          <List
                            ref={this.list_configControlEnvios_REF}
                            dataSource={this.state.tipoConfigSel.dataSource}
                            keyExpr={this.state.tipoConfigSel.keyExpr}
                            selectionMode="single"
                            height="100%"
                            width="100%"
                            displayExpr="denominacion"
                            pageLoadMode="scrollBottom"
                            allowItemDeleting={true}
                            itemDeleteMode="static"
                            focusStateEnabled={false}
                            showScrollbar="always"
                            onContentReady={
                              this.onContentReady_list_configControlEnvios
                            }
                            onSelectionChanged={
                              this.onSelectionChanged_list_configControlEnvios
                            }
                            onItemDeleting={
                              this.onItemDeleting_list_configControlEnvios
                            }
                          ></List>
                        </TabPanelItem>
                        <TabPanelItem tabIndex={1}>
                          <form onSubmit={this.config_tabpanel_submit}>
                            <Toolbar elementAttr={{ class: "pb-3" }}>
                              <ToolbarItem location="before">
                                <Button
                                  width={100}
                                  text={getTrad("atras")}
                                  type="normal"
                                  icon="chevronprev"
                                  onClick={this.onClick_atras_tab_config}
                                ></Button>
                              </ToolbarItem>
                              <ToolbarItem location="after">
                                <Button
                                  width={120}
                                  text={getTrad("guardar")}
                                  type="success"
                                  icon="check"
                                  useSubmitBehavior={true}
                                ></Button>
                              </ToolbarItem>
                            </Toolbar>

                            <Form
                              formData={this.state.config_tabpanel_itemSel}
                              labelLocation="top"
                            >
                              <Item itemType="group" colCount={2}>
                                <Item
                                  dataField="denominacion"
                                  editorType="dxTextBox"
                                  colSpan={2}
                                />
                                <Item
                                  dataField="direccion"
                                  editorType="dxTextBox"
                                  colSpan={2}
                                />
                                <Item
                                  dataField="codigoPostal"
                                  editorType="dxTextBox"
                                  colSpan={1}
                                />
                                <Item
                                  dataField="ciudad"
                                  editorType="dxTextBox"
                                  colSpan={1}
                                />
                                <Item
                                  dataField="provincia"
                                  editorType="dxTextBox"
                                  colSpan={1}
                                />
                                <Item
                                  dataField="pais"
                                  editorType="dxTextBox"
                                  colSpan={1}
                                />
                                <Item
                                  dataField="nif"
                                  editorType="dxTextBox"
                                  colSpan={1}
                                />
                              </Item>
                            </Form>
                          </form>
                        </TabPanelItem>
                      </TabPanel>
                    </ItemBox>
                  </Box>
                </ItemBox>
              </Box>
            </Popup>

            <Popup
              title={
                !$.isEmptyObject(this.state.formData_envio) &&
                (typeof this.state.formData_envio.idEnvio === "undefined" ||
                  this.state.formData_envio.idEnvio === null)
                  ? getTrad("nuevoEnvio")
                  : getTrad("modificarEnvio")
              }
              showTitle={true}
              ref={this.popup_envio_REF}
              visible={this.state.popupEnvio_visible}
              deferRendering={false}
              width={950}
              height={"82%"}
              onHiding={this.hiding_popup_envio}
              onHidden={this.hidden_popup_envio}
            >
              <ToolbarItemPopUp
                widget="dxButton"
                visible={
                  !$.isEmptyObject(this.state.formData_envio) &&
                  !this.state.formData_envio.isArchivado
                }
                render={this.render_toolbar_popupEditEnvio}
                location="after"
                toolbar="bottom"
              ></ToolbarItemPopUp>
              <div
                id="popupEnvio_formContainer"
                className="formContainer scrollbar-container"
              >
                <ScrollView showScrollbar="always">
                  <Form
                    id="form"
                    ref={this.form_envio_REF}
                    elementAttr={this.elementAttr_formEnvio}
                    formData={this.state.formData_envio}
                    deferRendering={false}
                    labelLocation="top"
                    scrollingEnabled={true}
                  >
                    <TabbedItem colSpan={4} name="tabbedItem_envio">
                      <TabPanelOptions
                        deferRendering={false}
                        selectedIndex={0}
                        onSelectionChanged={
                          this.tabPanelEnvio_onSelectionChanged
                        }
                        onInitialized={
                          this.editarEnvio_tabbedItem_onInitialized
                        }
                      />
                      <Tab title={getTrad("datosEnvio")}>
                        <Item
                          itemType="group"
                          colCount={3}
                          caption={getTrad("datosProyecto")}
                          disabled={
                            this.state.formData_envio != null &&
                            this.state.formData_envio.isArchivado
                          }
                        >
                          <Item
                            dataField="idProyecto"
                            editorType="dxSelectBox"
                            label={this.formLabel_idProyecto}
                            editorOptions={
                              this.formEnvio_editorOptions_tblProyecto
                            }
                          />
                          <Item
                            dataField="idDestinatario"
                            editorType="dxSelectBox"
                            label={this.formLabel_idDestinatario}
                            editorOptions={
                              this.formEnvio_editorOptions_tblDestinatario
                            }
                          />
                          <Item
                            dataField="idEmbarcador"
                            editorType="dxSelectBox"
                            label={this.formLabel_idEmbarcador}
                            editorOptions={
                              this.formEnvio_editorOptions_tblEmbarcador
                            }
                          />
                        </Item>
                        <Item
                          itemType="group"
                          colCount={4}
                          caption={getTrad("datosEnvio")}
                          disabled={
                            this.state.formData_envio != null &&
                            this.state.formData_envio.isArchivado
                          }
                        >
                          <Item
                            dataField="idTipoContenedor"
                            editorType="dxSelectBox"
                            label={this.formLabel_idTipoContenedor}
                            editorOptions={
                              this.formEnvio_editorOptions_tblTipoContenedor
                            }
                          />
                          <Item
                            dataField="idIncotermProv"
                            editorType="dxSelectBox"
                            label={this.formLabel_idIncotermProv}
                            editorOptions={
                              this.formEnvio_editorOptions_tblIncoterm
                            }
                          />
                          <Item
                            dataField="numCont"
                            editorType="dxTextBox"
                            label={this.formLabel_numCont}
                            editorOptions={this.editorOptions_textBox}
                          />
                          <Item
                            dataField="idPuertoCarga"
                            editorType="dxSelectBox"
                            label={this.formLabel_idPuertoCarga}
                            editorOptions={
                              this.formEnvio_editorOptions_tblPuerto
                            }
                          />
                          <Item
                            dataField="idPuertoDestino"
                            editorType="dxSelectBox"
                            label={this.formLabel_idPuertoDestino}
                            editorOptions={
                              this.formEnvio_editorOptions_tblPuerto
                            }
                          />
                          <Item
                            dataField="idIncotermCliente"
                            editorType="dxSelectBox"
                            label={this.formLabel_idIncotermCliente}
                            editorOptions={
                              this.formEnvio_editorOptions_tblIncoterm
                            }
                          />
                          <Item
                            dataField="blNumero"
                            editorType="dxTextBox"
                            label={this.formLabel_blNumero}
                            editorOptions={this.editorOptions_textBox}
                          />
                        </Item>
                        <Item
                          itemType="group"
                          colCount={4}
                          caption={"Fechas"}
                          disabled={
                            this.state.formData_envio != null &&
                            this.state.formData_envio.isArchivado
                          }
                        >
                          <Item
                            dataField="fechaEstimacionCarga"
                            editorType="dxDateBox"
                            label={this.formLabel_fechaEstimacionCarga}
                            editorOptions={this.editorOptions_dateBox}
                          />
                          <Item
                            dataField="etd"
                            editorType="dxDateBox"
                            label={this.formLabel_etd}
                            editorOptions={this.editorOptions_dateBox}
                          />
                          <Item
                            dataField="etaDestino"
                            editorType="dxDateBox"
                            label={this.formLabel_etaDestino}
                            editorOptions={this.editorOptions_dateBox}
                          />
                          <Item
                            dataField="despacho"
                            editorType="dxDateBox"
                            label={this.formLabel_despacho}
                            editorOptions={this.editorOptions_dateBox}
                          />
                          <Item
                            dataField="entrega"
                            editorType="dxDateBox"
                            label={this.formLabel_entrega}
                            editorOptions={this.editorOptions_dateBox}
                          />
                        </Item>
                        <Item
                          itemType="group"
                          colCount={1}
                          caption={"Otros"}
                          disabled={
                            this.state.formData_envio != null &&
                            this.state.formData_envio.isArchivado
                          }
                        >
                          <Item
                            dataField="comentarios"
                            editorType="dxTextBox"
                            label={this.formLabel_comentarios}
                            editorOptions={this.editorOptions_textBox}
                          />
                        </Item>
                      </Tab>
                      <Tab title={"Packing list"}>
                        <LoadPanel
                          deferRendering={false}
                          animation={null}
                          position={TabPackingList_Padre_controlEnvios}
                          visible={this.state.showLoading_packingList}
                          shadingColor="transparent"
                          showIndicator={true}
                          shading={true}
                          showPane={true}
                        />
                        <div
                          id="TabPackingList_Padre_controlEnvios"
                          style={{ height: 580 }}
                          className="he-100"
                        >
                          <Box direction="col" width="100%" height="100%">
                            <ItemBox baseSize={475}>
                              <DataGrid
                                ref={this.grid_packingList_REF}
                                dataSource={
                                  this.state.dataSource_tblPackingList
                                    .tblArticuloEnvio
                                }
                                height="100%"
                                width="100%"
                                columnAutoWidth={false}
                                hoverStateEnabled={false}
                                repaintChangesOnly={true}
                                keyExpr="idArticuloEnvio"
                                //Estilos
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                                //Eventos
                                onInitialized={
                                  this.gridPackingList_onInitialized
                                }
                                onInitNewRow={this.gridPackingList_onInitNewRow}
                                onExporting={this.gridPackingList_onExporting}
                                onToolbarPreparing={
                                  this.gridPackingList_onToolbarPreparing
                                }
                              >
                                <Scrolling
                                  mode="infinite"
                                  showScrollbar="always"
                                />
                                <LoadPanelItem enabled={true} />
                                <FilterRow visible={false} />
                                {this.userAccessLevel["admin"] && (
                                  <Editing
                                    mode="cell"
                                    allowUpdating={true}
                                    refreshMode="reshape"
                                    selectTextOnEditStart={true}
                                    useIcons={true}
                                  />
                                )}
                                <KeyboardNavigation
                                  enterKeyAction="moveFocus"
                                  enterKeyDirection="row"
                                  editOnKeyPress={true}
                                />
                                <Column
                                  dataField="idArticuloEnvio"
                                  sortOrder="asc"
                                  visible={false}
                                />
                                <Column
                                  caption="En destino"
                                  dataField="isEnDestino"
                                  width={65}
                                  alignment="center"
                                  cssClass="p-0"
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                  allowFiltering={false}
                                  allowSorting={false}
                                  showEditorAlways={true}
                                  fixedPosition="left"
                                  value={false}
                                  editCellRender={
                                    this.render_gridPackingList_isEnDestino
                                  }
                                />
                                <Column
                                  dataField="descripcion"
                                  caption={getTrad("descripcion")}
                                  alignment="left"
                                  allowFiltering={false}
                                  showEditorAlways={true}
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                  cssClass={
                                    this.state.formData_envio != null &&
                                    !this.state.formData_envio.isArchivado
                                      ? "dx-Cell_Editable"
                                      : ""
                                  }
                                >
                                  <RequiredRule />
                                </Column>
                                <Column
                                  dataField="pesoNeto"
                                  caption={getTrad("pesoNeto")}
                                  alignment="center"
                                  allowFiltering={false}
                                  showEditorAlways={true}
                                  width={120}
                                  dataType="number"
                                  format={this.decimal_format}
                                  editorOptions={this.decimal_editorOptions}
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                  cssClass={
                                    this.state.formData_envio != null &&
                                    !this.state.formData_envio.isArchivado
                                      ? "dx-Cell_Editable"
                                      : ""
                                  }
                                />
                                <Column
                                  dataField="pesoBruto"
                                  caption={getTrad("pesoBruto")}
                                  alignment="center"
                                  allowFiltering={false}
                                  showEditorAlways={true}
                                  width={120}
                                  format={this.decimal_format}
                                  editorOptions={this.decimal_editorOptions}
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                  cssClass={
                                    this.state.formData_envio != null &&
                                    !this.state.formData_envio.isArchivado
                                      ? "dx-Cell_Editable"
                                      : ""
                                  }
                                />
                                <Column
                                  dataField="dimensiones"
                                  caption={getTrad("dimensiones")}
                                  alignment="center"
                                  allowFiltering={false}
                                  showEditorAlways={true}
                                  width={120}
                                  cssClass={
                                    this.state.formData_envio != null &&
                                    !this.state.formData_envio.isArchivado
                                      ? "dx-Cell_Editable"
                                      : ""
                                  }
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                />
                                <Column
                                  dataField="observaciones"
                                  caption=" "
                                  width={40}
                                  alignment="center"
                                  cssClass="p-0"
                                  showEditorAlways={true}
                                  editCellRender={
                                    this.render_gridPackingList_observaciones
                                  }
                                  allowEditing={
                                    this.state.formData_envio != null
                                      ? !this.state.formData_envio.isArchivado
                                      : false
                                  }
                                />
                                <Column
                                  caption=" "
                                  width={40}
                                  alignment="center"
                                  cssClass="p-0"
                                  allowEditing={false}
                                  cellRender={this.render_gridPackingList_fotos}
                                />
                                {this.userAccessLevel["admin"] && (
                                  <Column
                                    caption=" "
                                    width={50}
                                    alignment="center"
                                    cssClass="p-0"
                                    visible={
                                      this.state.formData_envio != null &&
                                      !this.state.formData_envio.isArchivado
                                        ? true
                                        : false
                                    }
                                    cellComponent={removeCellComponent}
                                  />
                                )}
                                <Summary>
                                  <TotalItem
                                    column="descripcion"
                                    displayFormat={getTrad(
                                      "total",
                                    ).toUpperCase()}
                                  />
                                  <TotalItem
                                    column="pesoNeto"
                                    summaryType="sum"
                                    displayFormat="{0}"
                                  />
                                  <TotalItem
                                    column="pesoBruto"
                                    summaryType="sum"
                                    displayFormat="{0}"
                                  />
                                </Summary>
                              </DataGrid>
                            </ItemBox>
                            <ItemBox ratio={0} baseSize={20} />
                            <ItemBox ratio={0} baseSize={65}>
                              <Form
                                ref={this.form_packingList_REF}
                                formData={this.state.dataSource_tblPackingList}
                                labelLocation="top"
                              >
                                <Item itemType="group" colCount={2}>
                                  <Item
                                    dataField="personaRecibido"
                                    label={this.formLabel_recibidoPor}
                                    editorType="dxTextBox"
                                    disabled={
                                      (this.state.formData_envio != null &&
                                        this.state.formData_envio
                                          .isArchivado) ||
                                      !this.userAccessLevel["admin"]
                                    }
                                  />
                                  <Item
                                    dataField="fecha"
                                    label={this.formLabel_fecha}
                                    editorType="dxDateBox"
                                    editorOptions={this.dateTime_editorOptions}
                                    disabled={
                                      (this.state.formData_envio != null &&
                                        this.state.formData_envio
                                          .isArchivado) ||
                                      !this.userAccessLevel["admin"]
                                    }
                                  />
                                </Item>
                              </Form>
                            </ItemBox>
                          </Box>
                        </div>
                      </Tab>
                      <Tab title={getTrad("archivosAdjuntos")}>
                        <Box direction="col" width="100%" height="100%">
                          <ItemBox baseSize={580}>
                            <TabPanel
                              selectedIndex={
                                this.state.tipoDocumento_selected !== null
                                  ? 1
                                  : 0
                              }
                              elementAttr={this.elemAttr_archivosTab}
                              animationEnabled={true}
                            >
                              <TabPanelItem>
                                <LoadPanel
                                  deferRendering={false}
                                  animation={null}
                                  position={TabDocumentos_Padre_controlEnvios}
                                  visible={
                                    this.state.showLoading_document &&
                                    this.state.showLoading_document
                                  }
                                  message="Cargando documentos..."
                                  showIndicator={true}
                                  shadingColor="transparent"
                                  shading={true}
                                  showPane={true}
                                />
                                <div
                                  id="TabDocumentos_Padre_controlEnvios"
                                  style={{ height: 580 }}
                                  className="he-100"
                                >
                                  <List
                                    items={this.state.documentos}
                                    elementAttr={
                                      this.list_tipoDocumento_elementAttr
                                    }
                                    height="100%"
                                    selectionMode="single"
                                    selectedItems={
                                      this.state.tipoDocumento_selected != null
                                        ? [this.state.tipoDocumento_selected]
                                        : []
                                    }
                                    displayExpr="denominacion"
                                    keyExpr="idTipoDocumento_Envio"
                                    scrolling={
                                      this.list_tipoDocumento_scrolling
                                    }
                                    pageLoadMode="scrollBottom"
                                    noDataText=""
                                    loadPanel={
                                      this.list_tipoDocumento_loadPanel
                                    }
                                    onItemRendered={
                                      this.listTipoDocumento_onItemRendered
                                    }
                                    onSelectionChanged={
                                      this.listTipoDocumento_onSelectionChanged
                                    }
                                    itemRender={
                                      this.listTipoDocumento_itemRender
                                    }
                                  />
                                </div>
                              </TabPanelItem>
                              <TabPanelItem>
                                <Box
                                  direction="col"
                                  width="100%"
                                  height={"100%"}
                                >
                                  <ItemBox baseSize={36}>
                                    <Box
                                      direction="row"
                                      width="100%"
                                      height={"100%"}
                                    >
                                      <ItemBox baseSize={90}>
                                        <Button
                                          height={36}
                                          width={88}
                                          text={getTrad("atras")}
                                          onClick={this.btnAtras_onClick}
                                        />
                                      </ItemBox>
                                      <ItemBox ratio={1}>
                                        <h4 className="text-center">
                                          {this.state.tipoDocumento_selected !==
                                          null
                                            ? this.state.tipoDocumento_selected
                                                .denominacion
                                            : ""}
                                        </h4>
                                      </ItemBox>
                                      <ItemBox baseSize={90}></ItemBox>
                                    </Box>
                                  </ItemBox>

                                  <ItemBox baseSize={15}></ItemBox>
                                  <ItemBox baseSize={525}>
                                    <AdjuntarArchivos
                                      acceptedTypes={"*"}
                                      allowedFileExtensions={[]}
                                      isRemoveAllowed={
                                        !this.state.formData_envio
                                          .isArchivado &&
                                        this.userAccessLevel["admin"]
                                      }
                                      data={
                                        this.state.tipoDocumento_selected !=
                                        null
                                          ? $.extend(
                                              true,
                                              [],
                                              this.state.tipoDocumento_selected
                                                .tblEnvio_Documento,
                                            )
                                          : []
                                      }
                                      idPadreSel={
                                        this.state.tipoDocumento_selected
                                          ? this.state.tipoDocumento_selected
                                              .idTipoDocumento_Envio
                                          : null
                                      }
                                      tipoArchivos="fotos"
                                      isMultipleLoadFile={true}
                                      isVisibleRequerirDocumento={false}
                                      documentosNGrandParent={null}
                                      isDropZone={this.userAccessLevel["admin"]}
                                      accionesModificarDatos={
                                        this.state
                                          .accionesModificarDatos_fileUploader
                                      }
                                      numItemPerRow={5}
                                      tieneCabecera={false}
                                      showLoading_adjuntarArchivos={
                                        this.state.showLoading_adjuntarArchivos
                                      }
                                      updateArchivos={
                                        this.updateArchivos_documentos
                                      }
                                      isUploadAllowed={
                                        this.userAccessLevel["admin"]
                                      }
                                    />
                                  </ItemBox>
                                </Box>
                              </TabPanelItem>
                            </TabPanel>
                          </ItemBox>
                        </Box>
                      </Tab>
                      <Tab title={"Lista distribución"}>
                        <DataGrid
                          ref={this.grid_correosNEnvio_REF}
                          elementAttr={this.grid_correosNEnvio_elementAttr}
                          dataSource={
                            !$.isEmptyObject(this.state.formData_envio)
                              ? this.state.formData_envio.idCorreo
                              : []
                          }
                          height="100%"
                          width="100%"
                          columnAutoWidth={false}
                          hoverStateEnabled={false}
                          repaintChangesOnly={true}
                          //Estilos
                          showColumnLines={false}
                          showRowLines={true}
                          rowAlternationEnabled={true}
                          onInitialized={this.gridCorreosNEnvio_onInitialized}
                          onInitNewRow={this.grid_correosNEnvio_onInitNewRow}
                          onToolbarPreparing={
                            this.userAccessLevel["admin"] &&
                            this.gridCorreosNEnvio_onToolbarPreparing
                          }
                        >
                          <Scrolling mode="infinite" showScrollbar="always" />
                          <LoadPanelItem enabled={false} />
                          <FilterRow visible={false} />
                          {this.userAccessLevel["admin"] && (
                            <Editing
                              mode="cell"
                              allowUpdating={true}
                              refreshMode="reshape"
                              selectTextOnEditStart={true}
                              useIcons={true}
                            />
                          )}
                          <KeyboardNavigation
                            enterKeyAction="moveFocus"
                            enterKeyDirection="row"
                            editOnKeyPress={true}
                          />
                          <Column
                            dataField="idCorreo"
                            sortOrder="asc"
                            visible={false}
                          />
                          <Column
                            dataField="denominacion"
                            caption={getTrad("email")}
                            alignment="left"
                            editorOptions={this.email_editorOptions}
                            allowFiltering={false}
                            showEditorAlways={true}
                            allowEditing={
                              this.state.formData_envio != null
                                ? !this.state.formData_envio.isArchivado
                                : false
                            }
                            cssClass={
                              this.state.formData_envio != null &&
                              !this.state.formData_envio.isArchivado
                                ? "dx-Cell_Editable"
                                : ""
                            }
                          >
                            <RequiredRule />
                            <PatternRule
                              message={getTrad("validation_Formato")}
                              pattern={
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/
                              }
                            />
                            <CustomRule
                              validationCallback={function validationCallback(
                                options,
                              ) {
                                const correo = options.value;
                                const rows = $("#grid_correosNEnvio")
                                  .dxDataGrid("instance")
                                  .getVisibleRows();

                                const existe =
                                  $.grep(rows, function (row) {
                                    return (
                                      row.data.denominacion.toLowerCase() ===
                                      correo.toLowerCase()
                                    );
                                  }).length > 1;
                                return !existe;
                              }}
                            />
                          </Column>
                          {this.userAccessLevel["admin"] && (
                            <Column
                              caption=" "
                              width={50}
                              alignment="center"
                              cssClass="p-0"
                              cellComponent={removeCellComponent}
                            />
                          )}
                        </DataGrid>
                      </Tab>
                    </TabbedItem>
                  </Form>
                </ScrollView>
              </div>
              <Popup
                showTitle={true}
                deferRendering={false}
                title={getTrad("galeria")}
                visible={this.state.popup_galeriaDocumentos_visible}
                showCloseButton={true}
                closeOnOutsideClick={true}
                width="900px"
                height={"90%"}
                onHiding={this.onHiding_popup_galeriaDocumentos}
              >
                <Gallery
                  ref={this.dxGallery_documento_REF}
                  elementAttr={this.dxGallery_documento_elementAttr}
                  showIndicator={true}
                  showNavButtons={true}
                  loop={true}
                  height="100%"
                  width="100%"
                  itemRender={this.dxGallery_itemRender}
                />
              </Popup>
            </Popup>

            <Popup
              title={
                this.state.currentRow_packingList != null
                  ? this.state.currentRow_packingList.descripcion
                  : ""
              }
              showTitle={true}
              width={550}
              height={"auto"}
              visible={this.state.popup_Observaciones_visible}
              closeOnOutsideClick={true}
              onHiding={this.hiding_popup_observaciones}
              deferRendering={false}
            >
              <form onSubmit={this.popup_submit_packingList_observaciones}>
                <Form
                  formData={this.state.currentRow_packingList}
                  labelLocation="top"
                  scrollingEnabled={true}
                >
                  <Item itemType="group" colCount={1}>
                    <Item
                      dataField="observaciones"
                      label={this.observaciones_label}
                      editorType="dxTextArea"
                      editorOptions={this.observaciones_editorOptions}
                    />
                  </Item>
                  <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={
                      this.submit_packingList_observaciones_buttonOptions
                    }
                  />
                </Form>
              </form>
            </Popup>

            <Popup
              title={
                currentRow_packingList != null
                  ? currentRow_packingList.descripcion
                  : ""
              }
              elementAttr={this.controlEnvios_dxPopup_fotos_elementAttr}
              ref={this.dxpopup_packingList_fotos_REF}
              deferRendering={false}
              showTitle={true}
              width={550}
              height={590}
              minHeight={550}
              visible={this.state.popup_fotos_visible}
              closeOnOutsideClick={true}
              onInitialized={this.onContentReady_popup_fotos}
              onHiding={this.hiding_popup_fotos}
            >
              <AdjuntarArchivos
                acceptedTypes={"image/*"}
                allowedFileExtensions={[".jpg", ".jpeg", ".gif", ".png"]}
                isRemoveAllowed={!this.state.formData_envio.isArchivado}
                data={
                  currentRow_packingList.tblFotoNArticuloEnvio != null
                    ? $.extend(
                        true,
                        [],
                        currentRow_packingList.tblFotoNArticuloEnvio,
                      )
                    : []
                }
                idPadreSel={
                  this.state.currentRow_packingList
                    ? this.state.currentRow_packingList.idArticuloEnvio
                    : null
                }
                tipoArchivos="fotos"
                isMultipleLoadFile={true}
                isVisibleRequerirDocumento={false}
                documentosNGrandParent={null}
                isDropZone={true}
                accionesModificarDatos={
                  this.state.accionesModificarDatos_packingList
                }
                numItemPerRow={3}
                tieneCabecera={false}
                showLoading_adjuntarArchivos={
                  this.state.showLoading_adjuntarArchivos
                }
                updateArchivos={this.updateArchivos}
              />
              <ToolbarItemPopUp
                render={this.btnGuardarFoto_render}
                location="after"
                toolbar="bottom"
              />
            </Popup>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
        <Tooltip ref={this.refTooltip} key={"dataPrevTooltip"} maxWidth={600} />
      </Fragment>
    );
  }

  updateArchivos(data, accionesModificarDatos) {
    let formatData = $.map(data, function (item) {
      return {
        id: item.id,
        idArticuloEnvio: item.idPadre,
        nombre: item.nombre,
        extension: item.extension,
        documento: item.documento,
      };
    });
    let _currentRow_packingList = $.extend(
      true,
      {},
      this.state.currentRow_packingList,
    );
    _currentRow_packingList.tblFotoNArticuloEnvio = formatData;

    let _this = this;
    const _dataSource_tblPackingList = $.extend(
      true,
      {},
      this.state.dataSource_tblPackingList,
    );
    $.each(_dataSource_tblPackingList.tblArticuloEnvio, function (iArt, art) {
      if (
        art.idArticuloEnvio ===
        _this.state.currentRow_packingList.idArticuloEnvio
      )
        art.tblFotoNArticuloEnvio = formatData;
    });

    $.each(accionesModificarDatos, function (index, item) {
      item.tipoUpdateArchivo = "packingList";
    });
    this.setState(
      {
        currentRow_packingList: _currentRow_packingList,
        dataSource_tblPackingList: _dataSource_tblPackingList,
        accionesModificarDatos_packingList: accionesModificarDatos,
        showLoading_adjuntarArchivos: false,
      },
      () => {
        this.setState({ showLoading_adjuntarArchivos: null });
      },
    );
  }

  updateArchivos_documentos(data, accionesModificarDatos) {
    let _tipoDocumento_selected = $.extend(
      true,
      {},
      this.state.tipoDocumento_selected,
    );
    let _documentos = $.extend(true, [], this.state.documentos);
    let { tipoDocumento_selected } = this.state;

    $.each(_documentos, function (index, item) {
      if (
        item.idTipoDocumento_Envio ===
        tipoDocumento_selected.idTipoDocumento_Envio
      )
        item.tblEnvio_Documento = data;
    });

    _tipoDocumento_selected.tblEnvio_Documento = data;

    $.each(accionesModificarDatos, function (index, item) {
      item.tipoUpdateArchivo = "documentos";
    });
    this.setState(
      {
        tipoDocumento_selected: _tipoDocumento_selected,
        documentos: _documentos,
        accionesModificarDatos_fileUploader: accionesModificarDatos,
        showLoading_adjuntarArchivos: false,
      },
      () => {
        this.setState({ showLoading_adjuntarArchivos: null });
      },
    );
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  //#region Labels form envío
  formLabel_idProyecto = { text: getTrad("proyecto") };
  formLabel_idDestinatario = { text: getTrad("destinatario") };
  formLabel_idEmbarcador = { text: getTrad("embarcador") };
  formLabel_idTipoContenedor = { text: getTrad("tipoCont") };
  formLabel_idIncotermProv = { text: getTrad("incotermProveedor") };
  formLabel_numCont = { text: "Num. Cont." };
  formLabel_idPuertoCarga = { text: getTrad("puertoCarga") };
  formLabel_idPuertoDestino = { text: getTrad("puertoDestino") };
  formLabel_idIncotermCliente = { text: getTrad("incotermCliente") };
  formLabel_blNumero = { text: getTrad("blNumero") };
  formLabel_fechaEstimacionCarga = { text: getTrad("fechaEstimacionCarga") };
  formLabel_etd = { text: getTrad("etd") };
  formLabel_etaDestino = { text: getTrad("etaDestino") };
  formLabel_despacho = { text: getTrad("despacho") };
  formLabel_entrega = { text: getTrad("entrega") };
  formLabel_documentos = { visible: false };
  formLabel_tblPackingList = { visible: false };
  formLabel_recibidoPor = { text: getTrad("recibidoPor") };
  formLabel_fecha = { text: getTrad("fecha") };
  formLabel_comentarios = { text: "Comentarios" };
  //#endregion

  //#region Documentos
  elemAttr_archivosTab = { id: "dxTabPanel_documentacion" };
  dxTileView_documentos_elemAttr = { id: "dxTileView_documentos" };
  dxButton_guardarEnvio_controlEnvios_elemAttr = {
    id: "dxButton_guardarEnvio_controlEnvios",
  };
  btnDownloadDocument_template_style = { height: 25, width: 25 };
  btnDownloadDocument_template_style_icon = { height: 22, width: 30 };
  btnRemoveDocument_template_style = { height: 25, width: 25 };
  btnRemoveDocument_template_style_icon = { height: 22, width: 30 };
  template_tile_documento_style = {
    position: "absolute",
    bottom: "-5px",
    right: "0px",
  };
  template_tile_documento_elemAttr = { class: "dxToolbar_opciones" };
  template_tile_documento_file_style = {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    paddingTop: 2,
  };
  template_tile_documento_pdf = { fontSize: "45px" };
  template_tile_documento_other = { fontSize: "45px" };

  dxGallery_documento_elementAttr = { id: "dxGallery_documento" };
  list_tipoDocumento_scrolling = {
    mode: "infinite",
  };
  list_tipoDocumento_loadPanel = {
    enabled: false,
  };

  list_tipoDocumento_elementAttr = {
    id: "list_tipoDocumento",
  };
  //#endregion

  //#region Popup Config
  list_selectConfig_scrolling = { mode: "infinite" };
  list_selectConfig_loadPanel = { enabled: false };
  dxSelectBox_addItem_pais_elementAttr = { id: "dxSelectBox_addItem_pais" };
  elementAttr_tabPanelConfig = { class: "no-header" };

  removeTblEnvioNavigationKeys = (request) => {
    delete request.idEnvio; //
    // delete request.idCorreo //
    delete request.idDestinatarioNavigation; //
    delete request.idEmbarcadorNavigation; //
    delete request.idIncotermClienteNavigation; //
    delete request.idIncotermProvNavigation; //
    delete request.idProyectoNavigation; //
    delete request.idTipoContenedorNavigation; //
    delete request.idTipoDocumento_Envio; //
    // delete request.tblPackingList//
    return request;
  };

  config_tabpanel_submit(e) {
    e.preventDefault();
    let _this = this,
      { tipoConfigSel, config_tabpanel_itemSel } = this.state;
    let is_update =
      (tipoConfigSel.idConfig === 2 &&
        config_tabpanel_itemSel.idDestinatario != null) ||
      (tipoConfigSel.idConfig === 3 &&
        config_tabpanel_itemSel.idEmbarcador != null);
    if (is_update) {
      // UPDATE
      if (tipoConfigSel.idConfig === 2) {
        // Destinatario
        this.dataSource_tblDestinatario
          .store()
          .update(
            config_tabpanel_itemSel.idDestinatario,
            patchRequestHandler(config_tabpanel_itemSel),
          )
          .done(function (result) {
            _this.list_configControlEnvios.unselectAll();
            _this.setState({ config_tabpanel_index: 0 });
            _this.dataSource_tblDestinatario.load();
            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
      } else {
        // Embarcador
        this.dataSource_tblEmbarcador
          .store()
          .update(
            config_tabpanel_itemSel.idEmbarcador,
            patchRequestHandler(config_tabpanel_itemSel),
          )
          .done(function (result) {
            _this.list_configControlEnvios.unselectAll();
            _this.setState({ config_tabpanel_index: 0 });
            _this.dataSource_tblEmbarcador.load();

            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
      }
    } else {
      //INSERT
      if (tipoConfigSel.idConfig === 2) {
        // Destinatario
        this.dataSource_tblDestinatario
          .store()
          .insert(config_tabpanel_itemSel)
          .done(function (result) {
            _this.list_configControlEnvios.unselectAll();
            _this.setState({ config_tabpanel_index: 0 });
            _this.dataSource_tblDestinatario.load();
            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
      } else {
        // Embarcador
        this.dataSource_tblEmbarcador
          .store()
          .insert(config_tabpanel_itemSel)
          .done(function (result) {
            _this.list_configControlEnvios.unselectAll();
            _this.setState({ config_tabpanel_index: 0 });
            _this.dataSource_tblEmbarcador.load();
            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
      }
    }
  }

  btnGuardarFoto_render(e) {
    return (
      <Button
        text={getTrad("guardar")}
        type="success"
        onClick={this.btnGuardarFoto_onClick}
      ></Button>
    );
  }

  PopupConfig_revert_elementAttr = {
    id: "dxButton_revertButton",
  };
  PopupConfig_addItem_elementAttr = {
    id: "dxButton_addItem",
  };

  dxButton_PopupConfig_añadir_form(e) {
    this.setState({ config_tabpanel_index: 1, config_tabpanel_itemSel: {} });
  }

  PopupConfig_revert(e) {
    this.list_configControlEnvios.unselectAll();
  }

  onClick_atras_tab_config(e) {
    this.list_configControlEnvios.unselectAll();
    this.setState({ config_tabpanel_index: 0 });
  }

  PopupConfig_addItem(e) {
    let _this = this;
    let list_configControlEnvios = _this.list_configControlEnvios;

    let selectConfig_item = _this.list_selectConfig.option("selectedItems");
    let newItem = {};

    let value =
      selectConfig_item[0].idConfig === 6
        ? _this.dxTextBox_addItem.option("value").toUpperCase()
        : _this.dxTextBox_addItem.option("value");

    if (selectConfig_item[0].idConfig === 5) {
      newItem = {
        denominacion: value,
        idPais: _this.dxSelectBox_addItem_pais.option("value"),
      };
    } else {
      newItem = {
        denominacion: value,
      };
    }

    if (value !== "") {
      if (list_configControlEnvios.option("selectedItems").length > 0) {
        // UPDATE
        let id = list_configControlEnvios.option("selectedItemKeys")[0];
        let _this = this;
        list_configControlEnvios
          .getDataSource()
          .store()
          .update(id, patchRequestHandler(newItem))
          .done(function (result) {
            list_configControlEnvios.reload();
            _this.dxTextBox_addItem.reset();
          });
      } else {
        //INSERT
        list_configControlEnvios
          .getDataSource()
          .store()
          .insert(newItem)
          .done(function (result) {
            list_configControlEnvios.reload();
            _this.dxTextBox_addItem.reset();
          });
      }
    }
  }

  onValueChanged_textBoxAñadir(e) {
    e.element.dxTextBox("instance").option("isValid", true);
  }

  onEnterKey_textBoxAñadir(e) {
    $("#dxButton_addItem")[0].click();
  }

  onShowing_popup_configuracion(e) {
    this.dataSource_tblProyecto.load();
    this.dataSource_tblDestinatario.load();
    this.dataSource_tblEmbarcador.load();
    this.dataSource_tblTipoContenedor.load();
    this.dataSource_tblPuerto.load();
    this.dataSource_tblIncoterm.load();
    //#region Resize de los items de lista para que ocupen todo el height
    let popupHeight_percent = parseInt(e.component.option("height")) * 0.01,
      popupHeight_pixel = $(window).height() * popupHeight_percent,
      listaConfig_height = popupHeight_pixel - 40 - 49; // 40 - padding(20 top y 20 bottom)| 49 - Cabecera popup con titulo

    let selectConfig_count = this.list_selectConfig.option("items").length,
      selectConfig_itemHeight =
        Math.trunc(listaConfig_height / selectConfig_count) - 1;

    $.each(
      $("#list_selectConfig .dx-scrollview-content > div"),
      function (index, item) {
        $(item).height(selectConfig_itemHeight);
      },
    );
    //#endregion
  }

  onHiding_popup_configuracion(e) {
    this.setState({
      popup_configuracion_visible: false,
      config_tabpanel_index: 0,
    });
    this.grid_datos.refresh(false); // Para recargar cambios en el formData
  }

  onHidden_popup_configuracion(e) {
    this.list_selectConfig.selectItem(0);
    this.list_configControlEnvios.unselectAll();
  }

  onContentReady_list_configControlEnvios(e) {
    let selectConfig_item = this.list_selectConfig.option("selectedItems");
    if (selectConfig_item.length > 0) {
      //idConfig 5 -> Puerto
      //idConfig 6 -> Incoterm
      let mask = selectConfig_item[0].idConfig === 6 ? "LLL" : null,
        textTransform =
          selectConfig_item[0].idConfig === 6 ? "uppercase" : "none";

      if (selectConfig_item[0].idConfig === 5) {
        this.dxSelectBox_addItem_pais.option("visible", true);
        $("#dxSelectBox_addItem_pais")
          .parent()
          .parent()
          .css("margin-bottom", "12px");
        $("#dxSelectBox_addItem_pais").parent().parent().show();
      } else {
        this.dxSelectBox_addItem_pais.option("visible", false);
        $("#dxSelectBox_addItem_pais")
          .parent()
          .parent()
          .css("margin-bottom", "0px");
        $("#dxSelectBox_addItem_pais").parent().parent().hide();
      }

      this.dxTextBox_addItem.option({ mask: mask });
      this.dxTextBox_addItem.option("inputAttr", {
        style: "text-transform:" + textTransform,
      });
    }
  }

  onSelectionChanged_list_configControlEnvios(e) {
    let selectConfig_item = this.list_selectConfig.option("selectedItems"),
      dxButton_revertButton = $("#dxButton_revertButton").dxButton("instance"),
      dxButton_addItem = $("#dxButton_addItem").dxButton("instance");

    if (e.addedItems.length > 0) {
      if (selectConfig_item[0].idConfig === 5) {
        let extraText_pais =
          e.addedItems[0].idPaisNavigation !== null
            ? e.addedItems[0].idPaisNavigation.codigo + " - "
            : "";
        this.dxTextBox_addItem.option(
          "value",
          e.addedItems[0].denominacion.replace(extraText_pais, ""),
        );
        this.dxSelectBox_addItem_pais.option("value", e.addedItems[0].idPais);
        this.setState({
          is_item_configSel: true,
          config_tabpanel_itemSel: null,
        });
      } else if (
        selectConfig_item[0].idConfig === 2 ||
        selectConfig_item[0].idConfig === 3
      ) {
        this.setState({
          is_item_configSel: true,
          config_tabpanel_index: 1,
          config_tabpanel_itemSel: e.addedItems[0],
        });
      } else {
        this.dxTextBox_addItem.option("value", e.addedItems[0].denominacion);
        this.setState({
          is_item_configSel: true,
          config_tabpanel_itemSel: null,
        });
      }

      dxButton_revertButton.option("visible", true);
      dxButton_addItem.option("icon", "save");
    } else {
      if (selectConfig_item[0].idConfig === 5)
        this.dxSelectBox_addItem_pais.option("value", null);

      this.setState({
        is_item_configSel: false,
        config_tabpanel_itemSel: null,
      });
      this.dxTextBox_addItem.option("value", "");
      dxButton_revertButton.option("visible", false);
      dxButton_addItem.option("icon", "plus");
    }
  }

  onItemDeleting_list_configControlEnvios(e) {
    let selectedConfig = this.list_selectConfig.option("selectedItems");
    if (selectedConfig.length > 0) {
      let id = selectedConfig[0].keyExpr,
        _this = this;

      dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
        // ACEPTAR
        [
          getTrad("aceptar"),
          function () {
            let is_Used =
              $.grep(_this.dataSource_tblEnvio.items(), function (item) {
                if (selectedConfig[0].idConfig === 5) {
                  return (
                    $.grep(
                      _this.enum_tblEnvio_listasSelectivas.tblPuerto,
                      function (puerto) {
                        return item[puerto] === e.itemData[id];
                      },
                    ).length > 0
                  );
                } else if (selectedConfig[0].idConfig === 6) {
                  return (
                    $.grep(
                      _this.enum_tblEnvio_listasSelectivas.tblIncoterm,
                      function (incoterm) {
                        return item[incoterm] === e.itemData[id];
                      },
                    ).length > 0
                  );
                }
                return item[selectedConfig[0].keyExpr] === e.itemData[id];
              }).length > 0;

            if (!is_Used) {
              e.component
                .getDataSource()
                .store()
                .remove(e.itemData[id])
                .done(function () {
                  e.component.reload();
                });
            } else {
              notify({
                message: getTrad("alert_eliminarRegistro_error"),
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
              });
            }
          },
          "normal",
          "btnAceptar",
        ],
        // CANCELAR
        [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
      ]);
    }
    e.cancel = true;
  }

  onContentReady_list_selectConfig(e) {
    e.component.selectItem(0);
  }
  onSelectionChanged_list_selectConfig(e) {
    let _this = this;

    if (e.addedItems.length > 0) {
      let selItem = e.addedItems[0];
      _this.setState({
        tipoConfigSel: selItem,
        config_tabpanel_index: 0,
      });
    }
  }

  itemRender_list_selectConfig(data, index, elem) {
    return (
      <div className="container_spanCentrado he-100">
        <div className="font_family font_size_titulo he-100 d-flex align-items-center">
          {data.denominacion.toUpperCase()}
        </div>
      </div>
    );
  }
  //#endregion

  //#region PackingList
  dxTileView_fotos_articuloEnvio_elementAttr = {
    id: "dxTileView_fotos_articuloEnvio",
  };
  controlEnvios_dxPopup_fotos_elementAttr = {
    id: "controlEnvios_dxPopup_fotos",
  };
  submit_packingList_observaciones_buttonOptions = {
    text: getTrad("aceptar"),
    type: "success",
    useSubmitBehavior: true,
  };
  observaciones_label = { text: getTrad("observaciones") };
  observaciones_editorOptions = { minHeight: 100 };

  onContentReady_popup_fotos(args) {
    var html = args.component.content();
    $(html).css("padding-bottom", "3px");

    args.component
      .content()
      .attr("id", "dxPopup_ControlEnvios_fotos_packingList");
  }

  hiding_popup_fotos() {
    this.setState({
      currentRow_packingList: {},
      popup_fotos_visible: false,
    });
  }
  btnGuardarFoto_onClick(e) {
    this.hiding_popup_fotos();
  }

  hiding_popup_observaciones() {
    this.setState({
      currentRow_packingList: {},
      popup_Observaciones_visible: false,
    });
  }

  onHiding_popup_galeriaDocumentos(e) {
    this.setState({ popup_galeriaDocumentos_visible: false });
  }
  loadPanelOptions = { enabled: false };

  popup_submit_packingList_observaciones(e) {
    e.preventDefault();
    let _this = this;
    let { ...currentRow_packingList } = this.state.currentRow_packingList;

    var dataSource_tblPackingList = this.state.dataSource_tblPackingList;
    $.each(dataSource_tblPackingList.tblArticuloEnvio, function (index, item) {
      if (item.idArticuloEnvio === currentRow_packingList.idArticuloEnvio) {
        item.observaciones = currentRow_packingList["observaciones"];
      }
    });

    this.setState({ dataSource_tblPackingList: dataSource_tblPackingList });

    this.grid_packingList.refresh(true).done(function () {
      _this.hiding_popup_observaciones();
    });
  }

  gridPackingList_onInitialized(e) {
    e.element.find(e.element.find(".dx-freespace-row")).height(0);
  }

  gridPackingList_onExporting(e) {
    let _this = this;
    e.component.beginUpdate();
    e.component.columnOption("isEnDestino", "visible", false);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    worksheet.columns = [
      { width: 50 },
      { width: 50 },
      { width: 30 },
      { width: 30 },
      { width: 50 },
      { width: 50 },
    ];
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      topLeftCell: { row: 11, column: 1 },
      customizeCell: function ({ gridCell, excelCell }) {
        gridCell.font = { name: "Arial", size: 12 };
        gridCell.alignment = { horizontal: "left" };

        if (gridCell.rowType === "header") {
          if (gridCell.column.dataField === "observaciones") {
            excelCell.value = getTrad("observaciones");
          }
        }
      },
    }).then(function () {
      var headerRow_3 = worksheet.getRow(3),
        headerRow_4 = worksheet.getRow(4),
        headerRow_5 = worksheet.getRow(5),
        headerRow_6 = worksheet.getRow(6),
        headerRow_7 = worksheet.getRow(7);

      let titulos = {
        font: { size: 11, underline: true, bold: true },
        alignment: { horizontal: "left", vertical: "top" },
      };

      headerRow_3.getCell(1).value = "CONSIGNATARIO";
      headerRow_3.getCell(1).font = titulos.font;
      headerRow_3.getCell(1).alignment = titulos.alignment;

      headerRow_3.getCell(2).value = "DIRECCION ENTREGA";
      headerRow_3.getCell(2).font = titulos.font;
      headerRow_3.getCell(2).alignment = titulos.alignment;

      headerRow_3.getCell(5).value = "EXPORTADOR";
      headerRow_3.getCell(5).font = titulos.font;
      headerRow_3.getCell(5).alignment = titulos.alignment;
      let { idDestinatarioNavigation, idEmbarcadorNavigation } =
        _this.state.formData_envio;
      let { dataSource_tblPackingList } = _this.state;

      let info_embarcador = {
        denominacion: "",
        nif: "",
        direccion: "",
        codigoPostal: "",
        ciudad: "",
        provincia: "",
        pais: "",
      };

      if (idEmbarcadorNavigation != null) {
        info_embarcador = {
          denominacion:
            idEmbarcadorNavigation.denominacion != null
              ? idEmbarcadorNavigation.denominacion
              : "",
          nif:
            idEmbarcadorNavigation.nif != null
              ? idEmbarcadorNavigation.nif
              : "",
          direccion:
            idEmbarcadorNavigation.direccion != null
              ? idEmbarcadorNavigation.direccion
              : "",
          codigoPostal:
            idEmbarcadorNavigation.codigoPostal != null
              ? idEmbarcadorNavigation.codigoPostal
              : "",
          ciudad:
            idEmbarcadorNavigation.ciudad != null
              ? idEmbarcadorNavigation.ciudad
              : "",
          provincia:
            idEmbarcadorNavigation.provincia != null
              ? idEmbarcadorNavigation.provincia
              : "",
          pais:
            idEmbarcadorNavigation.pais != null
              ? idEmbarcadorNavigation.pais
              : "",
        };
      }

      let info_destinatario = {
        denominacion: "",
        nif: "",
        direccion: "",
        codigoPostal: "",
        ciudad: "",
        provincia: "",
        pais: "",
      };

      if (idDestinatarioNavigation != null) {
        info_destinatario = {
          denominacion:
            idDestinatarioNavigation.denominacion != null
              ? idDestinatarioNavigation.denominacion
              : "",
          nif:
            idDestinatarioNavigation.nif != null
              ? idDestinatarioNavigation.nif
              : "",
          direccion:
            idDestinatarioNavigation.direccion != null
              ? idDestinatarioNavigation.direccion
              : "",
          codigoPostal:
            idDestinatarioNavigation.codigoPostal != null
              ? idDestinatarioNavigation.codigoPostal
              : "",
          ciudad:
            idDestinatarioNavigation.ciudad != null
              ? idDestinatarioNavigation.ciudad
              : "",
          provincia:
            idDestinatarioNavigation.provincia != null
              ? idDestinatarioNavigation.provincia
              : "",
          pais:
            idDestinatarioNavigation.pais != null
              ? idDestinatarioNavigation.pais
              : "",
        };
      }

      worksheet.getRow(1).getCell(2).value =
        "Fecha: " +
        (dataSource_tblPackingList.fecha != null
          ? dataSource_tblPackingList.fecha
          : "");
      worksheet.getRow(1).getCell(2).font = {
        size: 12,
        bold: true,
        underline: true,
      };

      headerRow_4.getCell(1).font = { size: 11, bold: true };
      headerRow_4.getCell(1).alignment = {
        horizontal: "left",
        vertical: "top",
        wrapText: true,
      };
      headerRow_4.getCell(1).value = idDestinatarioNavigation?.denominacion;

      headerRow_4.getCell(2).font = { size: 11 };
      headerRow_4.getCell(2).alignment = {
        horizontal: "left",
        vertical: "top",
        wrapText: true,
      };
      headerRow_4.getCell(2).value = idDestinatarioNavigation?.direccion;

      headerRow_4.getCell(5).font = { size: 11, bold: true };
      headerRow_4.getCell(5).alignment = {
        horizontal: "left",
        vertical: "top",
        wrapText: true,
      };

      headerRow_4.getCell(5).alignment = { wrapText: true };
      headerRow_4.getCell(5).value =
        info_embarcador.denominacion +
        (idEmbarcadorNavigation?.nif != null
          ? " \n" + info_embarcador?.nif
          : "");
      headerRow_5.getCell(5).value = info_embarcador.direccion;
      headerRow_6.getCell(5).value =
        info_embarcador.codigoPostal + " - " + info_embarcador.ciudad;
      headerRow_7.getCell(5).value =
        info_embarcador.provincia + " - " + info_embarcador.pais;

      headerRow_5.getCell(1).font = { size: 11 };
      headerRow_5.getCell(1).value = idDestinatarioNavigation?.nif;

      headerRow_4.getCell(2).value =
        info_destinatario.denominacion +
        (idDestinatarioNavigation?.nif != null
          ? " \n" + info_destinatario.nif
          : "");
      headerRow_5.getCell(2).value = info_destinatario.direccion;
      headerRow_6.getCell(2).value =
        info_destinatario.codigoPostal + " - " + info_destinatario.ciudad;
      headerRow_7.getCell(2).value =
        info_destinatario.provincia + " - " + info_destinatario.pais;

      workbook.xlsx
        .writeBuffer()
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "PackingList.xlsx",
          );
        })
        .then(function () {
          e.component.columnOption("isEnDestino", "visible", true);
          e.component.endUpdate();
        });
    });
    e.cancel = true;
  }

  gridPackingList_onInitNewRow(e) {
    let articulos_datasource = e.component.getDataSource().items(),
      min_idArticulo = Math.min.apply(
        Math,
        articulos_datasource.map(function (o) {
          return o.idArticuloEnvio;
        }),
      );

    if (e.data.idArticuloEnvio == null) {
      if (min_idArticulo > 0) e.data.idArticuloEnvio = -1;
      else e.data.idArticuloEnvio = min_idArticulo - 1;
    }
    e.data.tblFotoNArticuloEnvio = [];
    setTimeout(() => {
      e.component.editCell(0, "descripcion");
    }, 10);
  }

  gridPackingList_onToolbarPreparing(e) {
    let _this = this;
    $.each(e.toolbarOptions.items, function (index, item) {
      if (item.name === "saveButton" && item.name === "addRowButton") {
        item.visible = false;
      }
    });

    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        toolbar: "bottom",
        visible:
          this.state.formData_envio != null &&
          !this.state.formData_envio.isArchivado
            ? true
            : false,
        options: {
          width: "100%",
          icon: "plus",
          text: getTrad("añadirArticulo"),
          type: "normal",
          onClick: function () {
            e.component.addRow();
          },
        },
        disabled: !this.userAccessLevel["admin"],
      },
      {
        location: "after",
        widget: "dxButton",
        toolbar: "bottom",
        options: {
          width: "100%",
          icon: "export",
          text: getTrad("exportar"),
          type: "normal",
          onClick: function () {
            e.component.exportToExcel(false);
          },
        },
      },
    );
  }

  gridCorreosNEnvio_onInitialized(e) {
    e.element.find(e.element.find(".dx-freespace-row")).height(0);
  }

  grid_correosNEnvio_onInitNewRow(e) {
    let correos_datasource = e.component.getDataSource().items(),
      min_idCorreo = Math.min.apply(
        Math,
        correos_datasource.map(function (o) {
          return o.idCorreo;
        }),
      );

    if (e.data.idCorreo == null) {
      if (min_idCorreo > 0) e.data.idCorreo = -1;
      else e.data.idCorreo = min_idCorreo - 1;
    }

    setTimeout(() => {
      e.component.editCell(0, "denominacion");
    }, 10);
  }

  gridCorreosNEnvio_onToolbarPreparing = (e) => {
    $.each(e.toolbarOptions.items, function (index, item) {
      if (item.name === "saveButton" && item.name === "addRowButton") {
        item.visible = false;
      }
    });

    e.toolbarOptions.items.unshift({
      location: "before",
      widget: "dxButton",
      toolbar: "bottom",
      visible:
        this.state.formData_envio != null &&
        !this.state.formData_envio.isArchivado
          ? true
          : false,
      options: {
        width: "100%",
        icon: "plus",
        text: getTrad("añadirCorreo"),
        type: "normal",
        onClick: function () {
          e.component.addRow();
        },
      },
    });
  };

  //#endregion
  list_selectConfig_elementAttr = { id: "list_selectConfig" };

  //Correos
  email_editorOptions = { maxLength: 50 };

  grid_correosNEnvio_elementAttr = { id: "grid_correosNEnvio" };

  //#region Popup Envío
  elementAttr_formEnvio = { id: "Form_envio" };

  tabPanelEnvio_onSelectionChanged(e) {
    if (e.addedItems.length > 0) {
      let selectedIndex = e.component.option("selectedIndex");
      let _this = this;

      if (selectedIndex === 1) {
        this.form_packingList.option(
          "formData",
          this.state.dataSource_tblPackingList,
        );

        if (!this.state.packingList_cargados) {
          setTimeout(() => {
            _this.setState({ showLoading_packingList: true });
          }, 0);
        }
      } else {
        setTimeout(() => {
          _this.setState({ showLoading_packingList: false });
        }, 0);
      }

      if (selectedIndex === 2) {
        if (!this.state.documents_cargados) {
          setTimeout(() => {
            _this.setState({ showLoading_document: true });
          }, 0);
        }
      } else {
        setTimeout(() => {
          _this.setState({ showLoading_document: false });
        }, 0);
      }
    }
  }

  hidden_popup_envio(e) {
    this.form_envio.repaint(); // reinicia tab panel index
  }

  formEnvio_hasChanges(is_correo) {
    function _getObjectSize(obj) {
      let len = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) len++;
      }
      return len;
    }

    let _this = this;
    let form_hasChanges;
    if (_this.state.formData_envio.idEnvio !== null) {
      // Es un envío ya existente
      let document_hasChanges = !(
        JSON.stringify(_this.state.documentos_originalSel) ===
        JSON.stringify(_this.state.documentos)
      );
      let packingList_hasChanges = !(
        JSON.stringify(_this.state.packingList_original) ===
        JSON.stringify(_this.state.dataSource_tblPackingList)
      );
      if (!is_correo) {
        let form_noCorreo_original = $.extend(
          true,
          {},
          _this.state.envio_originalSel,
        );
        let form_noCorreo_actual = $.extend(
          true,
          {},
          _this.state.formData_envio,
        );
        delete form_noCorreo_original.idCorreo;
        delete form_noCorreo_actual.idCorreo;

        form_hasChanges =
          !(
            JSON.stringify(form_noCorreo_original) ===
            JSON.stringify(form_noCorreo_actual)
          ) ||
          document_hasChanges ||
          packingList_hasChanges;
      } else {
        form_hasChanges =
          !(
            JSON.stringify(_this.state.envio_originalSel) ===
            JSON.stringify(_this.state.formData_envio)
          ) ||
          document_hasChanges ||
          packingList_hasChanges;
      }
    } else {
      // Nuevo envío
      let document_hasChanges =
        $.grep(_this.state.documentos, function (item) {
          return item.tblEnvio_Documento.length > 0;
        }).length > 0;

      let tblArticuloEnvio =
        _this.state.formData_envio.tblPackingList[0].tblArticuloEnvio;
      form_hasChanges =
        _getObjectSize(_this.state.formData_envio) > 2 ||
        document_hasChanges ||
        tblArticuloEnvio.length > 0;
    }

    return form_hasChanges;
  }

  hiding_popup_envio(e, is_guardando) {
    let _this = this;
    e.cancel = true;

    setTimeout(() => {
      function _closePopup() {
        _this.form_envio.resetValues(); // Si no reseteo los valores, no permite vaciar formData_envio
        _this.setState({
          popupEnvio_visible: false,
          documentos_originalSel: [],
          envio_originalSel: {},
          dataSource_tblPackingList: {},
          packingList_original: {},
          tipoDocumento_selected: null,
          showLoading_document: false,
          showLoading_packingList: false,
          documents_cargados: false,
          packingList_cargados: false,
          formData_envio: {},
          accionesModificarDatos_fileUploader: [],
          accionesModificarDatos_packingList: [],
        });
      }

      if (
        !$.isEmptyObject(_this.state.formData_envio) &&
        is_guardando == null
      ) {
        // Se cierra con datos y sin guardar
        if (this.formEnvio_hasChanges(true)) {
          dxMensajePregunta(getTrad("preg_PerderCambios"), [
            [
              getTrad("aceptar"),
              function () {
                _closePopup();
              },
              "danger",
            ],
            [getTrad("cancelar"), function () {}],
          ]);
        } else _closePopup();
      } else _closePopup();
    }, 0);
  }

  Form_envio_guardar = () => {
    let _this = this;
    let packingList_hasChanges = !(
      JSON.stringify(_this.state.packingList_original) ===
      JSON.stringify(_this.state.dataSource_tblPackingList)
    );

    function guardarEnvio(notificar) {
      let formData = _this.state.formData_envio;

      if (
        formData.fechaEstimacionCarga !== undefined &&
        formData.fechaEstimacionCarga !== null &&
        typeof formData.fechaEstimacionCarga !== "string"
      ) {
        formData.fechaEstimacionCarga = formatDate_noTime_parameter(
          formData.fechaEstimacionCarga,
        );
      }
      if (
        formData.etd !== undefined &&
        formData.etd !== null &&
        typeof formData.etd !== "string"
      ) {
        formData.etd = formatDate_noTime_parameter(formData.etd);
      }
      if (
        formData.etaDestino !== undefined &&
        formData.etaDestino !== null &&
        typeof formData.etaDestino !== "string"
      ) {
        formData.etaDestino = formatDate_noTime_parameter(formData.etaDestino);
      }
      if (
        formData.despacho !== undefined &&
        formData.despacho !== null &&
        typeof formData.despacho !== "string"
      ) {
        formData.despacho = formatDate_noTime_parameter(formData.despacho);
      }
      if (
        formData.entrega !== undefined &&
        formData.entrega !== null &&
        typeof formData.entrega !== "string"
      ) {
        formData.entrega = formatDate_noTime_parameter(formData.entrega);
      }

      let tipoDocumentos = _this.formateo_documentos()[1];
      let { accionesModificarDatos_fileUploader } = _this.state;

      formData.idTipoDocumento_Envio = tipoDocumentos;

      $.each(
        _this.state.dataSource_tblPackingList.tblArticuloEnvio,
        function (index, articulo) {
          // delete articulo.__KEY__;
          delete articulo.idPackingList;

          $.each(articulo.tblFotoNArticuloEnvio, function (iFoto, foto) {
            delete foto.idArticuloEnvio;
            delete foto.idFoto;
            delete foto.id;
          });
        },
      );

      _this.grid_correosNEnvio.saveEditData();
      formData.idCorreo = $.map(
        _this.grid_correosNEnvio.getDataSource().items(),
        function (item) {
          return {
            /* idCorreo: item.idCorreo,  */ denominacion: item.denominacion,
          };
        },
      );

      formData.tblPackingList = [_this.state.dataSource_tblPackingList];
      delete formData.tblPackingList[0].idPackingList;
      if (packingList_hasChanges) {
        if (
          _this.form_packingList.option("formData").fecha !== null &&
          _this.form_packingList.option("formData").fecha !== undefined &&
          typeof _this.form_packingList.option("formData").fecha !== "string"
        ) {
          formData.tblPackingList[0].fecha = formatDate_noTime_parameter(
            _this.form_packingList.option("formData").fecha,
          );
        }
        formData.tblPackingList[0].personaRecibido =
          _this.form_packingList.option("formData").personaRecibido;
      }
      formData.notificar = notificar;
      formData.tblPackingList.forEach((packingListItem) => {
        packingListItem.tblArticuloEnvio.forEach((articulo) => {
          delete articulo.idArticuloEnvio;
        });
      });
      // delete formData.tblPackingList.idArticuloEnvio

      _this.hiding_popup_envio({}, true);

      function saveDocuments(idEnvio) {
        var promises = [];
        $.each(accionesModificarDatos_fileUploader, function (index, item) {
          if (item.action === "delete") {
            var def = new $.Deferred();
            if (item.tipoUpdateArchivo === "documentos") {
              _this.datasource_documentos
                .store()
                .remove({ idDocumento: item.id, idEnvio: idEnvio })
                .done(function (result) {
                  def.resolve(result);
                });
            }
            promises.push(def);
          } else if (item.action === "insert") {
            var def = new $.Deferred();
            if (item.tipoUpdateArchivo === "documentos") {
              let documento = {
                nombre: item.data.nombre,
                documento: item.data.documento,
                extension: item.data.extension,
                idTipoDocumento_Envio: item.data.idPadre,
                idEnvio: idEnvio,
              };
              _this.datasource_documentos
                .store()
                .insert(documento)
                .done(function (result) {
                  def.resolve(result);
                });
            }
            promises.push(def);
          }
        });
        return $.when.apply(undefined, promises).promise();
      }

      // delete formData.tblEnvio_Documento;
      $.each(formData.tblPackingList, function (iPackingList, packingList) {
        $.each(packingList.tblArticuloEnvio, function (iArticulo, articulo) {
          $.each(articulo.tblFotoNArticuloEnvio, function (iFoto, foto) {
            delete foto.id;
          });
        });
      });

      if (formData.idEnvio == null) {
        //INSERT
        delete formData.idEnvio;
        _this.loadPanel_show(true);
        _this.dataSource_tblEnvio
          .store()
          .insert(formData)
          .done(function (envio) {
            saveDocuments(envio.idEnvio).done(function (results) {
              _this.grid_datos.refresh(true).done(function () {
                _this.loadPanel_hide();
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              });
            });
          });
      } else {
        _this.loadPanel_show(true);
        const idEnvioAux = formData.idEnvio;
        _this.dataSource_tblEnvio.store().update(formData.idEnvio, formData);
        saveDocuments(idEnvioAux).done(function (results) {
          _this.grid_datos.refresh(true).done(function () {
            _this.loadPanel_hide();
            notify({
              message: getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
        });
      }
    }

    _this.grid_packingList.saveEditData();
    _this.grid_packingList
      .getController("validating")
      .validate(true)
      .done(function (isValid_packing) {
        if (isValid_packing) {
          _this.grid_correosNEnvio.saveEditData();
          _this.grid_correosNEnvio
            .getController("validating")
            .validate(true)
            .done(function (isValid_correo) {
              if (isValid_correo) {
                // filtrar solo cambios correo
                if (
                  _this.formEnvio_hasChanges(false) &&
                  _this.state.formData_envio.idCorreo.length > 0
                ) {
                  dxMensajePregunta(getTrad("preg_cambiosEnvio"), [
                    [
                      getTrad("si"),
                      function () {
                        guardarEnvio(true);
                      },
                      "danger",
                    ],
                    [
                      getTrad("no"),
                      function () {
                        guardarEnvio(false);
                      },
                    ],
                  ]);
                } else {
                  guardarEnvio(false);
                }
              } else {
                _this.loadPanel_hide();
                notify({
                  message: getTrad("aviso_validacion_listaDistribucion"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              }
            });
        } else {
          _this.loadPanel_hide();
          notify({
            message: getTrad("aviso_validacion_packingList"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        }
      });
  };

  //#endregion

  decimal_format = { style: "decimal", maximumFractionDigits: 0 };

  //EDITORES
  decimal_editorOptions = {
    format: this.decimal_format,
    step: 0,
    min: 0,
    max: 99999,
  };

  dateTime_editorOptions = {
    type: "date",
    width: "100%",
    displayFormat: "dd/MM/yyyy",
  };

  formateo_documentos() {
    let documentos = [],
      tipoDocumentos = [],
      _this = this;

    $.each(_this.state.documentos, function (index, item) {
      $.each(item.tblEnvio_Documento, function (index2, item2) {
        delete item2.id;
        item2.idTipoDocumento_Envio = item.idTipoDocumento_Envio;
      });
      if (item.isCompleto) {
        $.merge(tipoDocumentos, [
          { idTipoDocumento_Envio: item.idTipoDocumento_Envio },
        ]);
      }
      $.merge(documentos, item.tblEnvio_Documento);
    });
    return [documentos, tipoDocumentos];
  }

  componentDidMount() {}

  avisoSoloLectura = () => {
    return <div className="alert alert-danger m-0 py-1 px-3">Solo Lectura</div>;
  };
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlEnvios);
