import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { sharedEditorOptions } from "../model";
import DxForm, { Item, CustomRule } from "devextreme-react/form";
import { getDefaultMonedaId, getDefaultTasaCambio } from "../functions";
import { getTrad } from "helpers";
import { getTasaCambio } from "helpers/tasaCambio";
import { idsAdmTipoCambio } from "constants/enums/Administracion/tblAdmTipoCambio";

const TasaCambioTipoCambioComponent = forwardRef(({ formData, keyExpr, userCantEdit = true }, ref) => {
    const [tipoCambio, setTipoCambio] = useState(formData?.idAdmTipoCambio);
    const [tasaCambioDisabled, setTasaCambioDisabled] = useState(formData?.idAdmTipoCambio == 1);
    const [tipoCambioDisabled, setTipoCambioDisabled] = useState(formData?.idReferenciaFacturaVenta != null);
    const formRef = useRef();

    useEffect(() => {
        const form = formRef.current.instance;
        if (formData[keyExpr] == null && !tipoCambioDisabled) {
            if (formData?.idMoneda !== getDefaultMonedaId()) {
                form.option("formData").idAdmTipoCambio = 1;
                recalcularTasaCambio();
            } else {
                form.option("formData").idAdmTipoCambio = 2;
            }
        }
        setTasaCambioDisabled(formData?.idAdmTipoCambio == 1 || tipoCambioDisabled);
        form.repaint();
    }, [formData?.idMoneda]);

    useImperativeHandle(ref, () => ({
        checkIsValid,
        recalcularTasaCambio,
        setTasaReferenciaFactura,
    }));

    const checkIsValid = () => {
        return formRef?.current?.instance?.validate();
    };

    const recalcularTasaCambio = () => {
        if (formData?.idAdmTipoCambio === 1) {
            const form = formRef.current.instance;
            form.option("formData").tasaCambio = getTasaCambio({
                idMonedaOrigen: getDefaultMonedaId(),
                idMonedaDestino: formData.idMoneda,
                fecha:
                    (formData.fechaCreacion ?? formData.fecha) instanceof Date
                        ? formData.fechaCreacion ?? formData.fecha
                        : new Date(formData.fechaCreacion ?? formData.fecha),
            })?.tasaCambio;
            form.repaint();
        }
    };

    const setTasaReferenciaFactura = (factura) => {
        const form = formRef.current.instance;
        form.option("formData").tasaCambio = factura.tasaCambio;
        form.option("formData").idAdmTipoCambio = idsAdmTipoCambio.Manual;
        setTipoCambioDisabled(factura?.idReferenciaFacturaVenta != null);
        form.repaint();
    };

    const getText = (trad) => ({ text: getTrad(trad) });

    const colCount = 4;
    const multiplier = 3;
    const labels = {
        idAdmTipoCambio: getText("tipoCambio"),
        tasaCambio: getText("tasaCambio"),
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "idAdmTipoCambio") {
            if (e.value === 1) {
                formRef.current.instance.option("formData").tasaCambio = getTasaCambio({
                    idMonedaOrigen: getDefaultMonedaId(),
                    idMonedaDestino: formData.idMoneda,
                    fecha: new Date(formData.fechaCreacion ?? formData.fecha),
                })?.tasaCambio;
                formRef.current.instance.itemOption("tasaCambio", "disabled", true);
            } else {
                formRef.current.instance.option("formData").tasaCambio = getDefaultTasaCambio();
                formRef.current.instance.itemOption("tasaCambio", "disabled", false);
            }
            setTipoCambio(e.value);
            setTasaCambioDisabled(e.value == 1 || tipoCambioDisabled);
        }
    };

    const validationCallback_tasaCambio = ({ value: tasaCambio }) => {
        if (!isFormVisible()) {
            formRef.current.instance.option("formData").tasaCambio = 1.0;
            return true;
        }
        const data = formRef.current.instance.option("formData");
        const { idAdmTipoCambio } = data;
        if (
            ((idAdmTipoCambio !== 1 && tasaCambio !== 1) || idAdmTipoCambio === 1) &&
            tasaCambio !== 0 &&
            tasaCambio !== null
        ) {
            return true;
        }
        return false;
    };

    const isFormVisible = () => {
        return !(formData.idMoneda === getDefaultMonedaId());
    };

    return (
        <DxForm
            ref={formRef}
            formData={formData}
            onFieldDataChanged={onFieldDataChanged}
            visible={isFormVisible()}
            colCount={colCount * multiplier}
            height={"100%"}
            width={"100%"}
            labelLocation={"top"}
            readOnly={formData.isCerrado && userCantEdit}
        >
            <Item
                dataField={"idAdmTipoCambio"}
                label={labels.idAdmTipoCambio}
                editorType={"dxSelectBox"}
                editorOptions={sharedEditorOptions.idAdmTipoCambio}
                colSpan={2 * multiplier}
                disabled={tipoCambioDisabled}
            />
            <Item
                dataField={"tasaCambio"}
                label={labels.tasaCambio}
                editorType={"dxNumberBox"}
                editorOptions={sharedEditorOptions.tasaCambio}
                colSpan={2 * multiplier}
                isRequired={true}
                disabled={tasaCambioDisabled}
            >
                <CustomRule validationCallback={validationCallback_tasaCambio} />
            </Item>
        </DxForm>
    );
});

export default TasaCambioTipoCambioComponent;
