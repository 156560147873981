import { Component, createRef } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  errorHandler,
  authHeader,
  patchRequestHandler,
  getNombreFormulario,
} from "../../../helpers";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { Box, Item as ItemBox } from "devextreme-react/box";
import { Button, DataGrid, List } from "devextreme-react";
import {
  Editing,
  Column as GridColumn,
  GroupPanel,
  SearchPanel,
} from "devextreme-react/data-grid";

import { Card, CardBody, CardHeader } from "reactstrap";

import GroupCell from "./components/GroupCell";
import SwitchGrid from "./components/SwitchGrid";
import PopupAreas from "./components/PopupAreas";
import PopupMaquinas from "./components/PopupMaquinas";
import PageTitle from "layout/AppMain/PageTitle";

class EstructuraOperativa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idAreaLavanderia: 0,
      idLavanderia: props.lavanderia.idLavanderia,
      idAreaLavanderiaNavigation: {},
    };

    this.popupAreas = createRef(null);
    this.popupMaquinas = createRef(null);

    this.dataSource_tblAreaLavanderiaNLavanderia = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "tblAreaLavanderiaNLavanderia",
        key: ["idLavanderia", "idAreaLavanderia"],
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      select: ["idLavanderia", "idAreaLavanderia"],
      filter: [["idLavanderia", "=", props.lavanderia.idLavanderia]],
      expand: ["idAreaLavanderiaNavigation"],
    });

    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia = new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "tblPosicionNAreaLavanderiaNLavanderia",
        key: "idPosicionNAreaLavanderiaNLavanderia",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      select: ["*"],
      filter: [
        ["idLavanderia", "=", props.lavanderia.idLavanderia],
        "and",
        ["idAreaLavanderia", "=", 0],
        ["!", ["idMaquina", "=", null]],
      ],
      expand: ["idMaquinaNavigation($select=denominacion)"],
      sort: "numPos",
    });

    this.dataSource_tblAreaLavanderia = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAreaLavanderia",
        key: "idAreaLavanderia",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      select: ["*"],
    });

    this.datasource_tblMaquina = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMaquina",
        key: "idMaquina",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      sort: "denominacion",
      select: ["*"],
      filter: [
        ["idLavanderia", "=", props.lavanderia.idLavanderia],
        "and",
        ["activo", "=", true],
        "and",
        ["eliminado", "=", false],
      ],
      expand: ["idTipoMaquinaNCategoriaMaquinaNavigation"],
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) return true;
    if (nextProps.lavanderia !== this.props.lavanderia) {
      this.recargarSources(nextProps.lavanderia.idLavanderia);
      return true;
    }
    return false;
  }

  recargarSources(idLavanderia) {
    this.dataSource_tblAreaLavanderiaNLavanderia.filter([
      "idLavanderia",
      "=",
      idLavanderia,
    ]);
    this.dataSource_tblAreaLavanderiaNLavanderia.reload();

    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia.filter([
      ["idLavanderia", "=", idLavanderia],
      "and",
      ["idAreaLavanderia", "=", 0],
      ["!", ["idMaquina", "=", null]],
    ]);
    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia.reload();

    this.datasource_tblMaquina.filter([
      ["idLavanderia", "=", idLavanderia],
      "and",
      ["activo", "=", true],
      "and",
      ["eliminado", "=", false],
    ]);
    this.datasource_tblMaquina.reload();
  }

  recargarPosiciones(idLavanderia, idAreaLavanderia) {
    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia.filter([
      ["idLavanderia", "=", idLavanderia],
      "and",
      ["idAreaLavanderia", "=", idAreaLavanderia],
      ["!", ["idMaquina", "=", null]],
    ]);
    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia.load();
  }

  render() {
    return (
      <>
        <PageTitle
          heading={"Estructura operativa" /* getNombreFormulario(this) */}
        />
        <Box
          height={"100%"}
          width={"100%"}
          style={{ margin: "auto", paddingTop: 20 }}
        >
          <ItemBox ratio={2}>
            <Card style={{ width: "97%", height: "100%", margin: "auto" }}>
              <CardHeader style={{ justifyContent: "space-between" }}>
                <span>Areas</span>
                <Button
                  onClick={() => this.popupAreas.current.toggle()}
                  stylingMode="contained"
                  type="success"
                  text="Añadir"
                />
              </CardHeader>
              <CardBody>
                <List
                  onContentReady={(e) => {
                    if (
                      this.state.idLavanderia ===
                      this.props.lavanderia.idLavanderia
                    ) {
                      this.recargarPosiciones(
                        this.state.idLavanderia,
                        this.state.idAreaLavanderia,
                      );
                    }
                  }}
                  selectionMode="single"
                  dataSource={this.dataSource_tblAreaLavanderiaNLavanderia}
                  displayExpr={"idAreaLavanderiaNavigation.denominacion"}
                  onItemClick={({ itemData }) => {
                    if (itemData !== this.state) {
                      this.setState(itemData);
                      this.recargarPosiciones(
                        itemData.idLavanderia,
                        itemData.idAreaLavanderia,
                      );
                    }
                  }}
                />
              </CardBody>
            </Card>
          </ItemBox>
          <ItemBox ratio={3}>
            <Card style={{ width: "97%", height: "100%", margin: "auto" }}>
              <CardHeader style={{ justifyContent: "space-between" }}>
                <span>Posiciones</span>
                <Button
                  onClick={() => this.popupMaquinas.current.toggle()}
                  disabled={
                    !this.state.idAreaLavanderia ||
                    this.props.lavanderia.idLavanderia !==
                      this.state.idLavanderia
                  }
                  stylingMode="contained"
                  type="success"
                  text="Añadir máquinas"
                />
              </CardHeader>
              <CardBody>
                <DataGrid
                  height={"680"}
                  dataSource={
                    this.dataSource_tblPosicionNAreaLavanderiaNLavanderia
                  }
                  grouping={{ autoExpandAll: true, allowCollapsing: false }}
                  showColumnHeaders={true}
                  paging={{ enabled: false }}
                  onRowUpdating={(e) => {
                    e.newData = patchRequestHandler(e.newData);
                  }}
                >
                  <GroupPanel visible={true} />
                  <SearchPanel visible={true} />

                  <Editing allowUpdating={true} mode="cell" />

                  <GridColumn
                    dataField="denominacion"
                    dataType="string"
                    width={"60%"}
                    allowEditing={false}
                  />
                  <GridColumn
                    dataField="numPos"
                    dataType="number"
                    caption={"Num."}
                    alignment={"center"}
                    allowEditing={false}
                  />
                  <GridColumn
                    dataField="activo"
                    dataType="boolean"
                    showEditorAlways={true}
                    editCellComponent={SwitchGrid}
                  />
                  <GridColumn
                    dataField="idMaquinaNavigation.denominacion"
                    dataType="string"
                    caption={"Máquina"}
                    groupCellRender={(e) => (
                      <GroupCell {...e} popupMaquinas={this.popupMaquinas} />
                    )}
                    groupIndex={0}
                  />
                </DataGrid>
              </CardBody>
            </Card>
          </ItemBox>
        </Box>
        <PopupAreas
          ref={this.popupAreas}
          idLavanderia={this.props.lavanderia.idLavanderia}
          dataSource_tblAreaLavanderia={this.dataSource_tblAreaLavanderia}
          dataSource_tblAreaLavanderiaNLavanderia={
            this.dataSource_tblAreaLavanderiaNLavanderia
          }
        />
        <PopupMaquinas
          ref={this.popupMaquinas}
          idLavanderia={this.props.lavanderia.idLavanderia}
          areaSeleccionada={this.state}
          datasource_tblMaquina={this.datasource_tblMaquina}
          dataSource_tblPosicionNAreaLavanderiaNLavanderia={
            this.dataSource_tblPosicionNAreaLavanderiaNLavanderia
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  // idioma: state.Global.idioma,
  // user: state.Authentication.user,
  // resolucion: state.Global.resolucion
});

export default connect(mapStateToProps)(EstructuraOperativa);
