import React, { Fragment } from "react";
import { connect } from "react-redux";

//Componentes devExtreme
import Box, { Item as ItemBox } from "devextreme-react/box";

//Componentes
import { connectionConstants } from "constants";
import { DataGrid, SelectBox } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  HeaderFilter,
  KeyboardNavigation,
  Paging,
  Selection,
} from "devextreme-react/data-grid";

//helpers
import { errorHandler, authHeader, getTrad } from "../../../../helpers";

//Odata
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

//Layout
//Js

class Opinion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      idPreguntaSel: null,
      dataSource_tblPregunta: [],
      dataSource_getDatosEncuestas: [],
    };
  }

  render() {
    const {
      idPreguntaSel,
      dataSource_tblPregunta,
      dataSource_getDatosEncuestas,
    } = this.state;

    return (
      <Box direction="col" width="100%" height="100%">
        <ItemBox baseSize={50}>
          <div className="d-flex ">
            <div className="font-weight-bold p-2">{getTrad("pregunta")}:</div>
            <SelectBox
              value={idPreguntaSel}
              valueExpr={"idPregunta"}
              dataSource={dataSource_tblPregunta}
              keyExpr="idPregunta"
              displayExpr="pregunta"
              searchExpr={"pregunta"}
              searchEnabled={true}
              noDataText={getTrad("sinDatos")}
              showClearButton={true}
              width="700px"
              onSelectionChanged={this.dx_list_seleccionaPregunta}
            />
          </div>
        </ItemBox>
        <ItemBox ratio={1}>
          <DataGrid
            dataSource={dataSource_getDatosEncuestas}
            height={"100%"}
            remoteOperations={false}
            showColumnLines={false}
            showRowLines={true}
            rowAlternationEnabled={true}
            hoverStateEnabled={true}
            noDataText={getTrad("sinDatos")}
            keyExpr="idRespuesta"
          >
            <Editing
              mode="cell"
              allowUpdating={true}
              selectTextOnEditStart={true}
            />
            <Paging enabled={false} />
            <HeaderFilter visible={true} />
            <FilterRow visible={true} />
            <KeyboardNavigation
              enterKeyAction="moveFocus"
              enterKeyDirection="row"
              editOnKeyPress={true}
            />
            <Column
              dataField="nombre"
              caption={getTrad("usuario")}
              dataType="string"
              allowHeaderFiltering={true}
              allowSearch={true}
              width={200}
              allowEditing={false}
            />
            <Column
              dataField="texto"
              caption={getTrad("respuesta")}
              dataType="string"
              allowHeaderFiltering={true}
              allowSearch={true}
              allowEditing={false}
            />
          </DataGrid>
        </ItemBox>
      </Box>
    );
  }

  dx_list_seleccionaPregunta = (e) => {
    const idPreguntaSel = e?.selectedItem?.idPregunta ?? null;
    this.setState({ idPreguntaSel }, () => {
      this.dataSource_getDatosEncuestas.reload();
    });
  };

  dataSource_tblPregunta = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPregunta",
      key: "idPregunta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_tblPregunta_beforeSend(request);
      },
      onLoading: (loadOptions) =>
        this.dataSource_tblPregunta_onLoading(loadOptions),
      version: 4,
    }),
    filter: ["idTipoPregunta eq 3"],
  });

  dataSource_tblPregunta_beforeSend = (request) => {
    const { idEncuestaSel } = this.props;
    request.headers = { ...authHeader() };

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  };

  dataSource_tblPregunta_onLoading = (loadOptions) => {
    const { idEncuestaSel } = this.props;
    let idEncuesta_filter;

    if (!idEncuestaSel) {
      idEncuesta_filter = [
        "idEncuestaPlantillaNavigation/tblEncuesta/any(e: e/idEncuesta eq -1)",
      ];
    } else {
      idEncuesta_filter = [
        "idEncuestaPlantillaNavigation/tblEncuesta/any(e: e/idEncuesta eq " +
          idEncuestaSel +
          ")",
      ];
    }

    if (loadOptions.filter) {
      loadOptions.filter = [loadOptions.filter, "and", idEncuesta_filter];
    } else {
      loadOptions.filter = [loadOptions.filter];
    }
  };

  dataSource_getDatosEncuestas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "Encuestas/getDatosEncuestas",
      key: "idRespuesta",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.dataSource_getDatosEncuestas_beforeSend(request);
      },
      version: 4,
    }),
  });

  dataSource_getDatosEncuestas_beforeSend(request) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;
    const { idPreguntaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idEncuesta = idEncuestaSel;
    request.params.idLavanderia = idLavanderiaSel;
    request.params.idCompañia = idCompañiaSel;
    request.params.group = "opinion";
    request.params.idPregunta = idPreguntaSel;

    if (idEncuestaSel) {
      request.params.idEncuesta = idEncuestaSel;
    } else {
      request.response = { value: [] }; // Return an empty array in the response
    }
  }

  setDatosIniciales = () => {
    this.dataSource_tblPregunta.load().done((data) => {
      this.setState(
        {
          dataSource_tblPregunta: data,
          idPreguntaSel: data[0]?.idPregunta ?? null,
        },
        () => {
          const { idPreguntaSel } = this.state;
          this.dataSource_getDatosEncuestas.reload().done((data) => {
            this.setState({ dataSource_getDatosEncuestas: data });
          });
          return idPreguntaSel ?? null;
        },
      );
    });
  };

  componentDidMount() {
    if (this.setDatosIniciales() === null) {
      this.setDatosIniciales();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { idEncuestaSel, idLavanderiaSel, idCompañiaSel } = this.props;
    const { idPreguntaSel } = this.state;

    if (
      idEncuestaSel !== prevProps.idEncuestaSel ||
      prevProps.idLavanderiaSel !== idLavanderiaSel ||
      prevProps.idCompañiaSel !== idCompañiaSel ||
      idPreguntaSel !== prevState.idPreguntaSel
    ) {
      this.dataSource_tblPregunta.reload().done((data) => {
        const idPreguntaSel_new =
          idPreguntaSel != null ? idPreguntaSel : data[0]?.idPregunta ?? null;
        this.setState(
          { dataSource_tblPregunta: data, idPreguntaSel: idPreguntaSel_new },
          () => {
            this.dataSource_getDatosEncuestas.reload().done((data) => {
              this.setState({ dataSource_getDatosEncuestas: data });
            });
          },
        );
      });
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

export default connect(mapStateToProps)(Opinion);
