import GestorArticulos from "components/Administracion/GestorArticulos";
import { getTrad } from "helpers";
import PageTitle from "layout/AppMain/PageTitle";
import React from "react";

class Articulos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <>
                <PageTitle heading={getTrad("articulos")} />
                <div className="media-body pt-3">
                    <GestorArticulos />
                </div>
            </>
        );
    }
}

export default Articulos;
