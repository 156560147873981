export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  REFRESH_TOKEN_REQUEST: "REFRESH_TOKEN_REQUEST",
  REFRESH_TOKEN_SUCCESS: "REFRESH_TOKEN_SUCCESS",
  REFRESH_TOKEN_FAILURE: "REFRESH_TOKEN_FAILURE",
  REFRESH_TOKEN_RESET: "REFRESH_TOKEN_RESET",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
};
