import React, { Fragment } from "react";

import Mapa from "./components/map-partes-transporte";

export default class VisorRutas extends React.Component {
  render() {
    return (
      <Fragment>
        <div className={"media-body"}>
          <div id="VisorRutas" className="scrollbar-container">
            <Mapa />
          </div>
        </div>
      </Fragment>
    );
  }
}
