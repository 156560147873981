import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  convertClienteUtcToLavanderiaUtc,
  dxMensajePregunta,
  formatDate_parameter,
  getCierresFacturacion,
} from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var dxDataGrid_PrendasProd_filtros = {
    idTurno: null,
    idTipoProduccion: null,
  };
  var addPrenda_rechazoNPrenda = [];
  var prendaProdSel = null;
  var addPrendaSel;
  var tipoRech_guardado = false;
  var addPrendas_guardado = false;
  var dxDropDownBoxSelCompaEnti_Compañia = false;
  var fechaSel = new Date();
  var entidadSel = null;
  var isCierreFactEntidad = false;

  //#region DATASOURCES

  var datasource_PrendasProd_agrupadas = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendaNProd_agrupado",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        var dxDropDownBox_selCompaEnti = $(
          "#dxDropDownBox_selCompaEnti",
        ).dxDropDownBox("instance");

        request.params.idEntidad = null;
        request.params.idCompañia = null;

        if (dxDropDownBoxSelCompaEnti_Compañia) {
          request.params.idCompañia =
            dxDropDownBox_selCompaEnti.option("value");
        } else {
          request.params.idEntidad = dxDropDownBox_selCompaEnti.option("value");
        }

        request.params.idTipoProduccion =
          typeof $(
            "#dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
          ).dxSelectBox("instance") !== "undefined"
            ? $("#dxSelectBox_TipoProduccion_PrendasProd_agrupadas")
                .dxSelectBox("instance")
                .option("value")
            : null;
        request.params.idTurno =
          typeof $("#dxSelectBox_Turno_PrendasProd_agrupadas").dxSelectBox(
            "instance",
          ) !== "undefined"
            ? $("#dxSelectBox_Turno_PrendasProd_agrupadas")
                .dxSelectBox("instance")
                .option("value")
            : null;

        request.params.fecha = formatDate_parameter(
          $("#dxDateBox_PrendasProd_agrupadas")
            .dxDateBox("instance")
            .option("value"),
        );
      },
      version: 4,
    }),
  });

  var datasource_PrendasProd_separadas = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendaNProd",
      key: "idPrendaNProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectPrendaNProd",
            "tblPrendaNProduccion",
          );
        } else {
          var dxDataGrid_PrendasProd_agrupadas = $(
            "#dxDataGrid_PrendasProd_agrupadas",
          ).dxDataGrid("instance");

          var dxDropDownBox_selCompaEnti = $(
            "#dxDropDownBox_selCompaEnti",
          ).dxDropDownBox("instance");
          var idPrendaSel =
            dxDataGrid_PrendasProd_agrupadas.option().selectedRowKeys[0];

          request.params.idEntidad = null;
          request.params.idCompañia = null;

          if (dxDropDownBoxSelCompaEnti_Compañia) {
            request.params.idCompañia =
              dxDropDownBox_selCompaEnti.option("value");
          } else {
            request.params.idEntidad =
              dxDropDownBox_selCompaEnti.option("value");
          }

          request.params.idPrenda =
            idPrendaSel != null
              ? dxDataGrid_PrendasProd_agrupadas.option().selectedRowKeys[0]
              : -1;
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
          request.params.fecha = formatDate_parameter(
            $("#dxDateBox_PrendasProd_agrupadas")
              .dxDateBox("instance")
              .option("value"),
          );
        }
      },
      version: 4,
    }),
  });

  var datasource_turnos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idTurno", "denominacion"],
    filter: ["idTurnoPadre eq null"],
  });

  var datasource_tipoProduccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoProduccion",
      key: "idTipoProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idTipoProduccion", "denominacion"],
  });

  var datasource_compañias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion"],
  });

  var datasource_rechazoNProd = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectRechazoNProduccion",
      key: "idTipoRechazo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectRechazoNProduccion",
            "tblPrendaNProduccion",
          );
        } else {
          request.params.idPrendaNProduccion = prendaProdSel
            ? prendaProdSel.idPrendaNProduccion
            : null;
        }
      },
      version: 4,
    }),
  });

  var datasource_PrendasProd_añadir = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendasNuevaProduccion",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var dxDropDownBox_selCompaEnti = $(
          "#dxDropDownBox_selCompaEnti",
        ).dxDropDownBox("instance");

        request.params.idEntidad = null;
        request.params.idCompañia = null;

        if (dxDropDownBoxSelCompaEnti_Compañia) {
          request.params.idCompañia =
            dxDropDownBox_selCompaEnti.option("value");
        } else {
          request.params.idEntidad = dxDropDownBox_selCompaEnti.option("value");
        }
      },
      version: 4,
    }),
    paginate: false,
    postProcess: function (data) {
      var dxDataGrid_PrendasProd_agrupadas = $(
        "#dxDataGrid_PrendasProd_agrupadas",
      ).dxDataGrid("instance");
      var procesedData = data.filter(function (item) {
        return (
          dxDataGrid_PrendasProd_agrupadas.getRowIndexByKey(item.idPrenda) ===
          -1
        );
      });

      $.each(procesedData, function (index, element) {
        element.producido = 0;
        element.rechazo = 0;
        element.retiro = 0;
      });
      return procesedData;
    },
  });

  var datasource_produccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblProduccion",
      key: "idProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  //#endregion

  //#region TRAD TÍTULOS

  //#endregion

  //#region Cierre Facturación Entidad
  function setRestriccionesCierreFact(idEntidad, fechaDesde, fechaHasta) {
    let _idEntidad = idEntidad ?? -1;
    getCierresFacturacion(_idEntidad, fechaDesde, fechaHasta).then(
      function (data) {
        isCierreFactEntidad = data.length > 0;

        let columnasEditables = ["Producido", "Rechazo", "Retiro"];
        let dxDataGrid_PrendasProd_separadas = $(
          "#Produccion #dxDataGrid_PrendasProd_separadas",
        ).dxDataGrid("instance");

        dxDataGrid_PrendasProd_separadas.option(
          "editing.allowUpdating",
          !isCierreFactEntidad,
        );
        columnasEditables.forEach((dataField) =>
          dxDataGrid_PrendasProd_separadas.columnOption(
            dataField,
            "cssClass",
            !isCierreFactEntidad && "dx-Cell_Editable",
          ),
        );
      },
    );
  }
  //#endregion

  //#region LAYOUT

  $("#dxTooltip_Produccion").dxTooltip({
    showEvent: "mouseenter",
    hideEvent: "mouseleave",
  });

  $("#Produccion #dxContainer").dxBox({
    direction: "row",
    height: "100%",
  });

  $("#Produccion #dxBox_PrendasProd_agrupadas").dxBox({
    direction: "col",
    height: "100%",
  });

  $("#Produccion #dxBox_dxDataGrid_PrendasProd_agrupadas").dxBox({
    direction: "col",
    height: "100%",
  });

  //#endregion

  // TOOLBAR DE dxDataGrid_PrendasProd_agrupadas - SELECTOR COMPA-ENTI + SELECTOR FECHA
  $("#Produccion #dxToolbar_PrendasProd_agrupadas").dxToolbar({
    items: [
      {
        location: "before",
        template: function () {
          return $(
            "<div id='dxDropDownBox_selCompaEnti' style='float:left'>",
          ).dxDropDownBox({
            width: "350px",
            deferRendering: false,
            remoteOperations: true,
            placeholder: getTrad("seleccionaCompaEnti"),
            // EVENTS
            dropDownOptions: {
              width: 800,
              height: 500,
              position: { my: "center", at: "center", of: window },
              closeOnOutsideClick: true,
            },
            contentTemplate: function (dxDropDownBox) {
              return $("<div>").dxBox({
                direction: "row",
                align: "space-around",
                crossAlign: "stretch",
                items: [
                  {
                    ratio: 1,
                    template: function () {
                      // COMPAÑÍAS
                      var container = $("<div>");
                      var title = $(
                        "<div class='font-size' style='margin-left:9px;'>" +
                          getTrad("compañia") +
                          "</div>",
                      );
                      var lista_compañias = $(
                        "<div id='dxDropDownBox_lista_compañias'>",
                      ).dxList({
                        dataSource: datasource_compañias,
                        selectionMode: "single",
                        height: "375px",
                        focusStateEnabled: false,
                        width: "95%",
                        keyExpr: "idCompañia",
                        scrolling: { mode: "infinite" },
                        pageLoadMode: "scrollBottom",
                        searchEnabled: true,
                        searchExpr: ["denominacion"],
                        // EVENTOS
                        onSelectionChanged: function (e) {
                          if (e.component.option().selectedItems.length > 0) {
                            dxDropDownBox.component.option("value", null); // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                            dxDropDownBox.component.option(
                              "valueExpr",
                              "idCompañia",
                            );
                            dxDropDownBox.component.option(
                              "displayExpr",
                              "denominacion",
                            );
                            dxDropDownBox.component.option(
                              "dataSource",
                              datasource_compañias,
                            );
                            dxDropDownBox.component.option(
                              "value",
                              e.component.option().selectedItems[0].idCompañia,
                            );
                            dxDropDownBoxSelCompaEnti_Compañia = true;
                            entidadSel = null;
                            setRestriccionesCierreFact(
                              null,
                              fechaSel,
                              fechaSel,
                            );

                            $("#dxDropDownBox_lista_entidades")
                              .dxList("instance")
                              .unselectAll();
                            dxDropDownBox.component.close();
                            $("#dxDataGrid_PrendasProd_agrupadas")
                              .dxDataGrid("instance")
                              .refresh(true);
                            $("#dxDataGrid_PrendasProd_separadas")
                              .dxDataGrid("instance")
                              .refresh(true);
                          }
                        },
                        itemTemplate: function (data, index) {
                          var result = $("<div>");
                          $("<div>").text(data.denominacion).appendTo(result);

                          return result;
                        },
                      });

                      title.appendTo(container);
                      lista_compañias.appendTo(container);

                      return container;
                    },
                  },
                  {
                    // ENTIDADES
                    ratio: 1,
                    template: function () {
                      var container = $("<div>");
                      var title = $(
                        "<div class='font-size' style='margin-left:9px;'>" +
                          getTrad("entidad") +
                          "</div>",
                      );
                      var lista_entidades = $(
                        "<div id='dxDropDownBox_lista_entidades'>",
                      ).dxList({
                        //Datos
                        dataSource: datasource_entidades,
                        selectionMode: "single",
                        keyExpr: "idEntidad",
                        focusStateEnabled: false,
                        height: "375px",
                        scrolling: { mode: "infinite" },
                        pageLoadMode: "scrollBottom",
                        searchEnabled: true,
                        searchExpr: ["denominacion"],
                        //EVENTOS
                        onSelectionChanged: function (e) {
                          if (e.component.option().selectedItems.length > 0) {
                            // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                            dxDropDownBox.component.option("value", null);
                            dxDropDownBox.component.option(
                              "valueExpr",
                              "idEntidad",
                            );
                            dxDropDownBox.component.option(
                              "displayExpr",
                              "denominacion",
                            );
                            dxDropDownBox.component.option(
                              "dataSource",
                              datasource_entidades,
                            );
                            dxDropDownBox.component.option(
                              "value",
                              e.component.option().selectedItems[0].idEntidad,
                            );
                            dxDropDownBoxSelCompaEnti_Compañia = false;

                            entidadSel = e.component.option().selectedItems[0];

                            setRestriccionesCierreFact(
                              entidadSel.idEntidad,
                              fechaSel,
                              fechaSel,
                            );

                            $("#dxDropDownBox_lista_compañias")
                              .dxList("instance")
                              .unselectAll();
                            dxDropDownBox.component.close();
                            $("#dxDataGrid_PrendasProd_agrupadas")
                              .dxDataGrid("instance")
                              .refresh(true);
                            $("#dxDataGrid_PrendasProd_separadas")
                              .dxDataGrid("instance")
                              .refresh(true);
                          }
                        },
                        itemTemplate: function (data, index) {
                          var result = $("<div>");
                          $("<div>").text(data.denominacion).appendTo(result);

                          return result;
                        },
                      });

                      title.appendTo(container);
                      lista_entidades.appendTo(container);

                      return container;
                    },
                  },
                ],
              });
            },
          });
        },
      },
      {
        location: "after",
        widget: "dxDateBox",
        options: {
          elementAttr: { id: "dxDateBox_PrendasProd_agrupadas" },
          displayFormat: "dd/MM/yyyy",
          value: convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, fechaSel),
          width: 160,
          onValueChanged: function (e) {
            fechaSel = e.value;
            if (fechaSel) {
              let idEntidad = entidadSel ? entidadSel.idEntidad : null;
              setRestriccionesCierreFact(idEntidad, fechaSel, fechaSel);
              var dxDropDownBox_selCompaEnti = $(
                "#dxDropDownBox_selCompaEnti",
              ).dxDropDownBox("instance");
              if (dxDropDownBox_selCompaEnti.option("value")) {
                $("#dxDataGrid_PrendasProd_agrupadas")
                  .dxDataGrid("instance")
                  .refresh(true);
                $("#dxDataGrid_PrendasProd_separadas")
                  .dxDataGrid("instance")
                  .refresh(true);
              }
            }
          },
        },
      },
    ],
  });

  //DATA GRID PRENDAS DE PRODUCCIÓN AGRUPADAS
  $("#Produccion #dxDataGrid_PrendasProd_agrupadas").dxDataGrid({
    //Datos
    dataSource: datasource_PrendasProd_agrupadas,
    remoteOperations: true,
    repaintChangesOnly: true,
    //Propiedades
    columnsAutoWidth: true,
    height: "100%",
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
      applyFilter: "auto",
    },
    searchPanel: {
      visible: false,
      placeholder: getTrad("busqueda"),
    },
    paging: {
      pageSize: 50,
    },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
      showInfo: true,
    },
    selection: {
      mode: "single",
    },
    columns: [
      {
        dataField: "idPrenda",
        visible: false,
        allowFiltering: false,
        allowEditing: false,
      },
      {
        dataField: "codigoPrenda",
        caption: getTrad("codigo"),
        sortOrder: "asc",
        width: "15%",
        alignment: "left",
        allowFiltering: true,
        allowHeaderFiltering: false,
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        alignment: "left",
        allowFiltering: true,
        allowHeaderFiltering: true,
      },
      {
        dataField: "Producido",
        caption: getTrad("produccion"),
        width: "13.5%",
        alignment: "center",
        allowFiltering: false,
        allowSorting: false,
      },
      {
        dataField: "Rechazo",
        caption: getTrad("rechazo"),
        width: "13.5%",
        alignment: "center",
        allowFiltering: false,
        allowSorting: false,
      },
      {
        dataField: "Retiro",
        caption: getTrad("retiro"),
        width: "13.5%",
        alignment: "center",
        allowFiltering: false,
        allowSorting: false,
      },
    ],
    //Eventos
    onEditorPreparing: function (e) {
      if (e.parentType === "filterRow") {
        var standardHandler = e.editorOptions.onValueChanged;
        e.editorOptions.onValueChanged = function (args) {
          e.component.clearSelection();
          standardHandler(args);
        };
      }
    },
    onSelectionChanged: function (selectedItems) {
      var dxDataGrid_PrendasProd_separadas = $(
        "#dxDataGrid_PrendasProd_separadas",
      ).dxDataGrid("instance");
      var dxSelectBox_TipoProduccion_PrendasProd_agrupadas = $(
        "#dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
      ).dxSelectBox("instance");
      var dxSelectBox_Turno_PrendasProd_agrupadas = $(
        "#dxSelectBox_Turno_PrendasProd_agrupadas",
      ).dxSelectBox("instance");

      if (
        !dxSelectBox_TipoProduccion_PrendasProd_agrupadas.option("value") &&
        !dxSelectBox_Turno_PrendasProd_agrupadas.option("value")
      ) {
        dxDataGrid_PrendasProd_separadas.option("filterValue", []);
      } else {
        dxDataGrid_PrendasProd_filtros.idTipoProduccion =
          dxSelectBox_TipoProduccion_PrendasProd_agrupadas.option("value");
        dxDataGrid_PrendasProd_filtros.idTurno =
          dxSelectBox_Turno_PrendasProd_agrupadas.option("value");
        var filtros = PrendasProd_getFiltros();
        dxDataGrid_PrendasProd_separadas.option("filterValue", filtros);
      }
      dxDataGrid_PrendasProd_separadas.refresh(true);
    },
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          widget: "dxButton",
          toolbar: "bottom",
          options: {
            width: "100%",
            icon: "plus",
            locateInMenu: "auto",
            text: getTrad("añadirPrenda"),
            type: "normal",
            onClick: function (e) {
              if (!isCierreFactEntidad) {
                var dxDropDownBox_selCompaEnti = $(
                  "#dxDropDownBox_selCompaEnti",
                ).dxDropDownBox("instance");

                var dxSelectBox_Turno_PrendasProd_agrupadas_value = $(
                  "#dxSelectBox_Turno_PrendasProd_agrupadas",
                )
                  .dxSelectBox("instance")
                  .option("value");
                var dxSelectBox_TipoProduccion_PrendasProd_agrupadas_value = $(
                  "#dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
                )
                  .dxSelectBox("instance")
                  .option("value");

                if (
                  !dxSelectBox_Turno_PrendasProd_agrupadas_value ||
                  !dxSelectBox_TipoProduccion_PrendasProd_agrupadas_value ||
                  !dxDropDownBox_selCompaEnti.option("value")
                ) {
                  var alertText =
                    "<div style='font-size:13px;text-align:left;padding-left:33px;line-height:1.6;'>";

                  if (!dxSelectBox_TipoProduccion_PrendasProd_agrupadas_value) {
                    alertText += "- " + getTrad("tipoProduccion") + " <br />";
                  }
                  if (!dxSelectBox_Turno_PrendasProd_agrupadas_value) {
                    alertText += "- " + getTrad("turno") + " <br />";
                  }
                  if (!dxDropDownBox_selCompaEnti.option("value")) {
                    alertText += "- " + getTrad("compaEnti") + " <br />";
                  }
                  alertText += "</div>";

                  dxMensajePregunta(
                    getTrad("alerta_RellenaCampos") + " <br />" + alertText,
                    [
                      [
                        getTrad("aceptar"),
                        function (e) {},
                        "danger",
                        "btnError_addPrenda",
                      ],
                    ],
                  );
                } else {
                  var dxDataGrid_PrendasProd_agrupadas = $(
                    "#dxDataGrid_PrendasProd_agrupadas",
                  ).dxDataGrid("instance");
                  var dxDataGrid_PrendasProd_separadas = $(
                    "#dxDataGrid_PrendasProd_separadas",
                  ).dxDataGrid("instance");
                  var dxDataGrid_PrendasProd_añadir = $(
                    "#dxDataGrid_PrendasProd_añadir",
                  ).dxDataGrid("instance");

                  dxDataGrid_PrendasProd_agrupadas.clearFilter("row");

                  dxDataGrid_PrendasProd_agrupadas
                    .refresh(true)
                    .done(function () {
                      if (
                        typeof $("#dxDataGrid_PrendasProd_añadir")[0] !==
                        "undefined"
                      ) {
                        dxDataGrid_PrendasProd_añadir
                          .refresh()
                          .done(function () {
                            $("#dxPopup_PrendasProd_añadir")
                              .dxPopup("instance")
                              .show();
                          });
                      } else {
                        // 1era vez AUTOCARGA
                        $("#dxPopup_PrendasProd_añadir")
                          .dxPopup("instance")
                          .show();
                      }
                    });
                  dxDataGrid_PrendasProd_agrupadas.option(
                    "selectedRowKeys",
                    [],
                  );
                  dxDataGrid_PrendasProd_separadas.refresh(true);
                }
              } else {
                dxMensajePregunta(
                  "La entidad seleccionada tiene la facturación bloqueada para esta fecha.",
                  [
                    [
                      getTrad("aceptar"),
                      function (e) {},
                      "danger",
                      "btnError_addPrenda",
                    ],
                  ],
                );
              }
            },
          },
        },
        {
          location: "after",
          widget: "dxSelectBox",
          toolbar: "top",
          options: {
            elementAttr: {
              id: "dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
            },
            width: 260,
            dataSource: datasource_tipoProduccion,
            displayExpr: "denominacion",
            valueExpr: "idTipoProduccion",
            showClearButton: true,
            placeholder: getTrad("seleccioneTipoProd"),
            onValueChanged: function (options) {
              var dxDropDownBox_selCompaEnti = $(
                "#dxDropDownBox_selCompaEnti",
              ).dxDropDownBox("instance");

              if (dxDropDownBox_selCompaEnti.option("value")) {
                var dxDataGrid_PrendasProd_agrupadas = $(
                  "#dxDataGrid_PrendasProd_agrupadas",
                ).dxDataGrid("instance");
                var dxDataGrid_PrendasProd_separadas = $(
                  "#dxDataGrid_PrendasProd_separadas",
                ).dxDataGrid("instance");

                dxDataGrid_PrendasProd_agrupadas.refresh(true);

                if (options.value) {
                  dxDataGrid_PrendasProd_filtros.idTipoProduccion =
                    options.value;
                  var filtros = PrendasProd_getFiltros();
                  dxDataGrid_PrendasProd_separadas.option(
                    "filterValue",
                    filtros,
                  );
                } else {
                  dxDataGrid_PrendasProd_filtros.idTipoProduccion = null;
                  dxDataGrid_PrendasProd_separadas.option("filterValue", []);
                }
              }
              e.component.clearSelection();
            },
          },
        },
        {
          location: "after",
          widget: "dxSelectBox",
          toolbar: "top",
          options: {
            elementAttr: {
              id: "dxSelectBox_Turno_PrendasProd_agrupadas",
            },
            width: 175,
            dataSource: datasource_turnos,
            displayExpr: "denominacion",
            valueExpr: "idTurno",
            showClearButton: true,
            placeholder: getTrad("seleccioneTurno"),
            onValueChanged: function (options) {
              var dxDropDownBox_selCompaEnti = $(
                "#dxDropDownBox_selCompaEnti",
              ).dxDropDownBox("instance");

              if (dxDropDownBox_selCompaEnti.option("value")) {
                var dxDataGrid_PrendasProd_agrupadas = $(
                  "#dxDataGrid_PrendasProd_agrupadas",
                ).dxDataGrid("instance");
                var dxDataGrid_PrendasProd_separadas = $(
                  "#dxDataGrid_PrendasProd_separadas",
                ).dxDataGrid("instance");

                dxDataGrid_PrendasProd_agrupadas.refresh(true);
                if (options.value) {
                  dxDataGrid_PrendasProd_filtros.idTurno = options.value;
                  var filtros = PrendasProd_getFiltros();
                  dxDataGrid_PrendasProd_separadas.option(
                    "filterValue",
                    filtros,
                  );
                } else {
                  dxDataGrid_PrendasProd_filtros.idTurno = null;
                  dxDataGrid_PrendasProd_separadas.option("filterValue", []);
                }
              }
              e.component.clearSelection();
            },
          },
        },
      );
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });

  //DATA GRID PRENDAS DE PRODUCCIÓN SEPARADAS
  $("#Produccion #dxDataGrid_PrendasProd_separadas").dxDataGrid({
    //Datos
    dataSource: datasource_PrendasProd_separadas,
    remoteOperations: true,
    //Propiedades
    columnsAutoWidth: true,
    height: "100%",
    headerFilter: {
      visible: true,
    },
    filterRow: {
      visible: true,
      applyFilter: "auto",
    },
    searchPanel: {
      visible: false,
      width: 150,
      placeholder: getTrad("busqueda"),
    },
    paging: {
      pageSize: 50,
    },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
      showInfo: true,
    },
    editing: {
      mode: "cell",
      refreshMode: "repaint",
      allowUpdating: true,
      allowDeleting: false,
      useIcons: true,
      selectTextOnEditStart: true,
    },
    keyboardNavigation: {
      enterKeyAction: "moveFocus",
      enterKeyDirection: "row",
      editOnKeyPress: true,
    },
    loadPanel: { enabled: false },
    columns: [
      {
        dataField: "idPrendaNProduccion",
        visible: false,
        allowFiltering: false,
        allowEditing: false,
      },
      {
        dataField: "idPrenda",
        visible: false,
        allowFiltering: false,
        allowEditing: false,
      },
      {
        dataField: "idProduccion",
        visible: false,
        allowFiltering: false,
        allowEditing: false,
      },
      {
        dataField: "hora",
        caption: getTrad("fecha"),
        format: "dd/MM/yyyy - HH:mm",
        sortOrder: "asc",
        width: "21%",
        alignment: "center",
        allowFiltering: false,
        allowEditing: false,
      },
      {
        dataField: "idTurno",
        caption: getTrad("turno"),
        lookup: {
          dataSource: function (options) {
            return {
              store: datasource_turnos.store(),
            };
          },
          displayExpr: "denominacion",
          valueExpr: "idTurno",
        },
        visible: true,
        allowEditing: false,
        allowHeadFiltering: false,
      },
      {
        dataField: "idTipoProduccion",
        caption: getTrad("tipoProduccion"),
        width: "19%",
        lookup: {
          dataSource: function (options) {
            return {
              store: datasource_tipoProduccion.store(),
            };
          },
          displayExpr: "denominacion",
          valueExpr: "idTipoProduccion",
        },
        visible: true,
        allowEditing: false,
        allowHeadFiltering: false,
      },
      {
        dataField: "Producido",
        caption: getTrad("producido"),
        width: "19%",
        alignment: "center",
        cssClass: "dx-Cell_Editable",
        allowFiltering: false,
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 99999999,
        },
        cellTemplate: camposEditabled_cellTemplate,
      },
      {
        dataField: "Rechazo",
        caption: getTrad("rechazo"),
        width: "15.5%",
        alignment: "center",
        cssClass: "dx-Cell_Editable",
        allowFiltering: false,
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 99999999,
        },
        cellTemplate: camposEditabled_cellTemplate,
      },
      {
        dataField: "Retiro",
        caption: getTrad("retiro"),
        width: "13.5%",
        alignment: "center",
        cssClass: "dx-Cell_Editable",
        allowFiltering: false,
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 99999999,
        },
        cellTemplate: camposEditabled_cellTemplate,
      },
    ],
    //Eventos
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift({
        location: "before",
        template: function () {
          return $("<div class ='font-size' style='margin-top : 10px;'>").text(
            getTrad("desglosePrendas"),
          );
        },
      });
    },
    onEditingStart: function (e) {
      if (e.column.dataField === "Rechazo" && LAVANDERIA.gestionaRechazo) {
        prendaProdSel = e.data;
        var popup = $("#dxPopup_tiposRechazo").dxPopup("instance");
        var dgvTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");
        if (!popup.option("visible")) {
          popup.show();
          if (typeof dgvTipoRechazo !== "undefined") {
            dgvTipoRechazo.option("dataSource", datasource_rechazoNProd);
          }
        }
        e.cancel = true;
      }
    },
    onRowUpdating: function (option) {
      option.newData.idPrenda = option.oldData.idPrenda;
      option.newData.idProduccion = option.oldData.idProduccion;
      option.newData.idPrendaNProduccion = option.oldData.idPrendaNProduccion;
      prendaProdSel = option.newData;

      if (typeof option.newData.Producido === "undefined") {
        option.newData.Producido = option.oldData.Producido;
      } else if (LAVANDERIA.gestionaRechazo) {
        var popup = $("#dxPopup_tiposRechazo").dxPopup("instance");
        var dgvTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");

        if (!popup.option("visible")) {
          popup.show();
          if (typeof dgvTipoRechazo !== "undefined") {
            dgvTipoRechazo.option("dataSource", datasource_rechazoNProd);
          }
        }
      }
      if (typeof option.newData.Rechazo === "undefined")
        option.newData.Rechazo = option.oldData.Rechazo;
      if (typeof option.newData.Retiro === "undefined")
        option.newData.Retiro = option.oldData.Retiro;
    },
    onRowUpdated: function (options) {
      var dgvTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");
      $("#dxDataGrid_PrendasProd_agrupadas")
        .dxDataGrid("instance")
        .refresh(true)
        .done(function () {
          if (typeof dgvTipoRechazo !== "undefined") {
            dgvTipoRechazo.editCell(1, "Cantidad");
            dgvTipoRechazo.editCell(0, "Cantidad");
          }
        });
    },
    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });

  //POPUP AÑADIR PRENDAS
  $("#Produccion #dxPopup_PrendasProd_añadir").dxPopup({
    showTitle: true,
    title: getTrad("añadirPrenda"),
    height: 585,
    width: 800,
    contentTemplate: function () {
      return $("<div id='dxDataGrid_PrendasProd_añadir' />").dxDataGrid({
        //Datos
        dataSource: datasource_PrendasProd_añadir,
        remoteOperations: true,
        //Propiedades
        height: "100%",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          refreshMode: "repaint",
          allowUpdating: true,
          allowDeleting: false,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        paging: {
          enabled: false,
        },
        loadPanel: { enabled: false },
        columns: [
          {
            dataField: "idPrenda",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "codigoPrenda",
            width: "12%",
            caption: getTrad("codigo"),
            alignment: "left",
            allowEditing: false,
          },
          {
            dataField: "denoPrenda",
            caption: getTrad("denominacion"),
            alignment: "left",
            allowEditing: false,
          },
          {
            dataField: "producido",
            caption: getTrad("produccion"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
          {
            dataField: "rechazo",
            caption: getTrad("rechazo"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
          {
            dataField: "retiro",
            caption: getTrad("retiro"),
            width: "13.5%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowSorting: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
        ],
        onEditingStart: function (e) {
          if (e.column.dataField === "rechazo" && LAVANDERIA.gestionaRechazo) {
            addPrendaSel = e.data;
            var popup = $("#dxPopup_tiposRechazo").dxPopup("instance");
            if (!popup.option("visible")) {
              if (addPrenda_rechazoNPrenda.length > 0) {
                if (
                  typeof addPrenda_rechazoNPrenda[addPrendaSel.idPrenda] !==
                  "undefined"
                ) {
                  $("#dgvTipoRechazo")
                    .dxDataGrid("instance")
                    .option(
                      "dataSource",
                      addPrenda_rechazoNPrenda[addPrendaSel.idPrenda],
                    );
                } else {
                  $("#dgvTipoRechazo")
                    .dxDataGrid("instance")
                    .option("dataSource", datasource_rechazoNProd);
                }
              } else if (
                typeof $("#dgvTipoRechazo").dxDataGrid("instance") !==
                "undefined"
              ) {
                $("#dgvTipoRechazo")
                  .dxDataGrid("instance")
                  .option("dataSource", datasource_rechazoNProd);
              }
              $("#dxPopup_tiposRechazo").dxPopup("instance").show();
            }
            e.cancel = true;
          }
        },
        onEditorPreparing: function (e) {
          if (e.parentType === "dataRow" && e.dataField === "producido") {
            var standardHandler = e.editorOptions.onValueChanged;
            e.editorOptions.onValueChanged = function (i) {
              e.component.editCell(e.row.rowIndex, "rechazo");
              standardHandler(i);
            };
          }
        },
        onToolbarPreparing: function (e) {
          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });

          var dxSelectBox_TipoProduccion_PrendasProd_agrupadas = $(
            "#dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
          ).dxSelectBox("instance");
          var idTipoProduccion =
            dxSelectBox_TipoProduccion_PrendasProd_agrupadas.option("value");

          var dxSelectBox_Turno_PrendasProd_agrupadas = $(
            "#dxSelectBox_Turno_PrendasProd_agrupadas",
          ).dxSelectBox("instance");
          var idTurno = dxSelectBox_Turno_PrendasProd_agrupadas.option("value");

          e.toolbarOptions.items.unshift(
            {
              location: "before",
              widget: "dxSelectBox",
              toolbar: "top",
              options: {
                elementAttr: {
                  id: "sbTipoProduccion_addPrenda",
                },
                width: 260,
                dataSource: datasource_tipoProduccion,
                displayExpr: "denominacion",
                valueExpr: "idTipoProduccion",
                placeholder: getTrad("seleccioneTipoProd"),
                readOnly: true,
                value: idTipoProduccion,
              },
            },
            {
              location: "before",
              widget: "dxSelectBox",
              toolbar: "top",
              options: {
                elementAttr: {
                  id: "sbTurno",
                },
                width: 175,
                dataSource: datasource_turnos,
                displayExpr: "denominacion",
                valueExpr: "idTurno",
                placeholder: getTrad("seleccioneTurno"),
                readOnly: true,
                value: idTurno,
              },
            },
          );
        },
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    toolbarItems: [
      {
        location: "after",
        widget: "dxButton",
        toolbar: "bottom",
        options: {
          text: getTrad("guardar"),
          type: "success",
          onClick: function (e) {
            var sbTurno_value = $("#sbTurno")
              .dxSelectBox("instance")
              .option("value");
            var sbTipoProduccion_addPrenda_value = $(
              "#sbTipoProduccion_addPrenda",
            )
              .dxSelectBox("instance")
              .option("value");

            if (!sbTurno_value || !sbTipoProduccion_addPrenda_value) {
              var alertText =
                "<div style='font-size:13px;text-align:left;padding-left:33px;line-height:1.6;'>";

              if (!sbTipoProduccion_addPrenda_value) {
                alertText += "- " + getTrad("tipoProduccion") + " <br />";
              }
              if (!sbTurno_value) {
                alertText += "- " + getTrad("turno") + " <br />";
              }
              alertText += "</div>";

              dxMensajePregunta(
                getTrad("alerta_RellenaCampos") + " <br />" + alertText,
                [
                  [
                    getTrad("aceptar"),
                    function (e) {},
                    "danger",
                    "btnError_addPrenda",
                  ],
                ],
              );
            } else {
              if (
                !$("#dxDataGrid_PrendasProd_añadir")
                  .dxDataGrid("instance")
                  .getController("editing")
                  .hasChanges()
              ) {
                notify({
                  message: getTrad("sinCambios"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              } else {
                //GUARDADO
                var rechazoNPrenda = $.extend(
                  true,
                  {},
                  addPrenda_rechazoNPrenda,
                );
                addPrendas_guardado = true;
                var dxDataGrid_PrendasProd_agrupadas = $(
                  "#dxDataGrid_PrendasProd_agrupadas",
                ).dxDataGrid("instance");
                var dxDataGrid_PrendasProd_añadir = $(
                  "#dxDataGrid_PrendasProd_añadir",
                ).dxDataGrid("instance");
                var dxDataGrid_PrendasProd_añadir_editedRows =
                  dxDataGrid_PrendasProd_añadir
                    .getVisibleRows()
                    .filter(function (item) {
                      return (
                        item.data.producido +
                          item.data.rechazo +
                          item.data.retiro >
                        0
                      );
                    });

                var tblPrendasNProduccion = [];
                $.each(
                  dxDataGrid_PrendasProd_añadir_editedRows,
                  function (rowIndex, item) {
                    var prendaNProduccion = {};
                    prendaNProduccion.idPrenda = item.data.idPrenda;
                    prendaNProduccion.hora = convertClienteUtcToLavanderiaUtc(
                      LAVANDERIA.GMT,
                      new Date(),
                    );
                    prendaNProduccion.Producido = item.data.producido;
                    prendaNProduccion.Rechazo = item.data.rechazo;
                    prendaNProduccion.Retiro = item.data.retiro;

                    if (
                      LAVANDERIA.gestionaRechazo &&
                      prendaNProduccion.Rechazo > 0
                    ) {
                      prendaNProduccion.tblRechazoNProduccion =
                        typeof rechazoNPrenda[prendaNProduccion.idPrenda] !==
                        "undefined"
                          ? rechazoNPrenda[prendaNProduccion.idPrenda].filter(
                              function (item) {
                                delete item.denominacion;
                                return item.Cantidad > 0;
                              },
                            )
                          : null;
                    }

                    tblPrendasNProduccion.push(prendaNProduccion);
                  },
                );

                var dxDropDownBox_selCompaEnti = $(
                  "#dxDropDownBox_selCompaEnti",
                ).dxDropDownBox("instance");

                var produccion = {
                  fecha: formatDate_parameter(
                    $("#dxDateBox_PrendasProd_agrupadas")
                      .dxDateBox("instance")
                      .option("value"),
                  ), // confirmar
                  idTipoProduccion: $("#sbTipoProduccion_addPrenda")
                    .dxSelectBox("instance")
                    .option("value"),
                  idEntidad: dxDropDownBoxSelCompaEnti_Compañia
                    ? null
                    : dxDropDownBox_selCompaEnti.option("value"),
                  idCompañia: dxDropDownBoxSelCompaEnti_Compañia
                    ? dxDropDownBox_selCompaEnti.option("value")
                    : null,
                  idLavanderia: LAVANDERIA.idLavanderia,
                  idTurno: $("#sbTurno")
                    .dxSelectBox("instance")
                    .option("value"),
                  tblPrendaNProduccion: tblPrendasNProduccion,
                };

                datasource_produccion
                  .store()
                  .insert(produccion)
                  .done(function () {
                    dxDataGrid_PrendasProd_agrupadas.refresh(true);
                    dxDataGrid_PrendasProd_añadir.refresh(true);
                  });
                $("#dxPopup_PrendasProd_añadir").dxPopup("instance").hide();
                dxDataGrid_PrendasProd_agrupadas.getScrollable().scrollTo(0);
              }
            }
          },
        },
      },
    ],
    onShown: function (args) {
      var dxDataGrid_PrendasProd_añadir = $(
          "#dxDataGrid_PrendasProd_añadir",
        ).dxDataGrid("instance"),
        sbTipoProduccion_value = $(
          "#dxSelectBox_TipoProduccion_PrendasProd_agrupadas",
        )
          .dxSelectBox("instance")
          .option("value"),
        sbTurno_value = $("#dxSelectBox_Turno_PrendasProd_agrupadas")
          .dxSelectBox("instance")
          .option("value");

      $("#sbTipoProduccion_addPrenda")
        .dxSelectBox("instance")
        .option("value", sbTipoProduccion_value);
      $("#sbTurno").dxSelectBox("instance").option("value", sbTurno_value);
      dxDataGrid_PrendasProd_añadir.editCell(0, "producido");

      args.component.show();
    },
    onHiding: function (args) {
      var dxDataGrid_PrendasProd_añadir = $(
        "#dxDataGrid_PrendasProd_añadir",
      ).dxDataGrid("instance");
      if (
        dxDataGrid_PrendasProd_añadir.getController("editing").hasChanges() &&
        addPrendas_guardado === false
      ) {
        args.cancel = true;
        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          // ACEPTAR
          [
            getTrad("aceptar"),
            function () {
              dxDataGrid_PrendasProd_añadir.cancelEditData();
              args.component.hide();
            },
            "normal",
            "btnAceptar",
          ],
          // CANCELAR
          [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
        ]);
      }
      addPrendas_guardado = false;
    },
    onHidden: function (args) {
      var dxDataGrid_PrendasProd_añadir = $(
        "#dxDataGrid_PrendasProd_añadir",
      ).dxDataGrid("instance");

      dxDataGrid_PrendasProd_añadir.clearFilter("row");
      dxDataGrid_PrendasProd_añadir.cancelEditData();
      addPrenda_rechazoNPrenda = [];
    },
  });

  //POPUP TIPOS DE RECHAZO
  $("#Produccion #dxPopup_tiposRechazo").dxPopup({
    showTitle: true,
    title: getTrad("tipoRechazo"),
    height: 400,
    width: "25%",
    contentTemplate: function () {
      return $("<div id='dgvTipoRechazo' />").dxDataGrid({
        //Datos
        dataSource: datasource_rechazoNProd,
        //Propiedades
        height: "100%",
        columnsAutoWidth: true,
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          applyFilter: "auto",
        },
        editing: {
          mode: "batch",
          refreshMode: "repaint",
          allowUpdating: true,
          allowDeleting: false,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "column",
          editOnKeyPress: true,
        },
        paging: {
          enabled: false,
        },
        keyExpr: "idTipoRechazo",
        loadPanel: { enabled: false },
        columns: [
          {
            dataField: "idRechazoNProduccion",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "idPrendaNProduccion",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "idTipoRechazo",
            visible: false,
            allowEditing: false,
          },
          {
            dataField: "denominacion",
            caption: getTrad("denominacion"),
            sortOrder: "asc",
            alignment: "left",
            allowHeaderFiltering: false,
            allowFiltering: false,
            allowEditing: false,
          },
          {
            dataField: "Cantidad",
            caption: getTrad("cantidad"),
            width: "40%",
            alignment: "center",
            cssClass: "dx-Cell_Editable",
            allowHeaderFiltering: false,
            allowFiltering: false,
            format: { style: "decimal", maximumFractionDigits: 0 },
            editorOptions: {
              step: 0,
              format: { style: "decimal", maximumFractionDigits: 0 },
              min: 0,
              max: 99999999,
            },
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          $.each(e.toolbarOptions.items, function (_, item) {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });

          e.toolbarOptions.items.unshift({
            location: "before",
            widget: "dxButton",
            locateInMenu: "auto",
            options: {
              text: getTrad("guardar"),
              type: "success",
              width: 120,
              elementAttr: { id: "dxButton_guardar_tipoRech" },
              onClick: function (args) {
                try {
                  args.component.option("disabled", true);
                  tipoRech_guardado = true;
                  var dgvTipoRechazo =
                    $("#dgvTipoRechazo").dxDataGrid("instance");
                  var dxDataGrid_PrendasProd_separadas = $(
                    "#dxDataGrid_PrendasProd_separadas",
                  ).dxDataGrid("instance");
                  var rechazoTotal = 0;

                  if (prendaProdSel) {
                    var idPrendaNProduccion = prendaProdSel.idPrendaNProduccion;

                    var tblRechazoNProduccion = [];
                    $.each(
                      dgvTipoRechazo.getVisibleRows(),
                      function (index, item) {
                        if (item.rowType === "data") {
                          var rechazoNProd = {};

                          if (
                            dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "idRechazoNProduccion",
                            )
                          ) {
                            rechazoNProd.idRechazoNProduccion =
                              dgvTipoRechazo.cellValue(
                                item.rowIndex,
                                "idRechazoNProduccion",
                              );
                          }

                          rechazoNProd.idPrendaNProduccion =
                            idPrendaNProduccion;
                          rechazoNProd.idTipoRechazo = dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "idTipoRechazo",
                          );
                          rechazoNProd.Cantidad = dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "Cantidad",
                          );
                          rechazoTotal += dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "Cantidad",
                          );
                          tblRechazoNProduccion.push(rechazoNProd);
                        }
                      },
                    );

                    var tblPrendaNProduccion = {
                      idPrendaNProduccion: idPrendaNProduccion,
                      idPrenda: prendaProdSel.idPrenda,
                      idProduccion: prendaProdSel.idProduccion,
                      Producido: prendaProdSel.Producido,
                      Rechazo: rechazoTotal,
                      Retiro: prendaProdSel.Retiro,
                      tblRechazoNProduccion: tblRechazoNProduccion,
                    };

                    if (tblRechazoNProduccion.length > 0) {
                      datasource_rechazoNProd
                        .store()
                        .update(idPrendaNProduccion, tblPrendaNProduccion)
                        .done(function () {
                          $("#dxPopup_tiposRechazo").dxPopup("instance").hide();
                          dxDataGrid_PrendasProd_separadas.refresh(true);

                          var dxDataGrid_PrendasProd_separadas_rowIndex =
                            dxDataGrid_PrendasProd_separadas.getRowIndexByKey(
                              prendaProdSel.idPrendaNProduccion,
                            );
                          dxDataGrid_PrendasProd_separadas.cellValue(
                            dxDataGrid_PrendasProd_separadas_rowIndex,
                            "Rechazo",
                            rechazoTotal,
                          );
                          dxDataGrid_PrendasProd_separadas.editCell(
                            dxDataGrid_PrendasProd_separadas_rowIndex,
                            "Retiro",
                          );
                          //
                        });
                      return;
                    } else {
                      notify({
                        message: getTrad("sinCambios"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                      });
                      args.component.option("disabled", false);
                    }
                  } else {
                    var dxDataGrid_PrendasProd_añadir = $(
                      "#dxDataGrid_PrendasProd_añadir",
                    ).dxDataGrid("instance");

                    addPrenda_rechazoNPrenda[addPrendaSel.idPrenda] = [];

                    $.each(
                      dgvTipoRechazo.getVisibleRows(),
                      function (index, item) {
                        if (item.rowType === "data") {
                          var rechazoNPrenda = {};
                          rechazoNPrenda.idTipoRechazo =
                            dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "idTipoRechazo",
                            );
                          rechazoNPrenda.denominacion =
                            dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "denominacion",
                            );
                          rechazoNPrenda.Cantidad = dgvTipoRechazo.cellValue(
                            item.rowIndex,
                            "Cantidad",
                          );

                          if (
                            dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "Cantidad",
                            ) > 0
                          ) {
                            rechazoTotal += dgvTipoRechazo.cellValue(
                              item.rowIndex,
                              "Cantidad",
                            );
                          }
                          addPrenda_rechazoNPrenda[addPrendaSel.idPrenda].push(
                            rechazoNPrenda,
                          );
                        }
                      },
                    );

                    var dxDataGrid_PrendasProd_añadir_rowIndex =
                      dxDataGrid_PrendasProd_añadir.getRowIndexByKey(
                        addPrendaSel.idPrenda,
                      );
                    dxDataGrid_PrendasProd_añadir.cellValue(
                      dxDataGrid_PrendasProd_añadir_rowIndex,
                      "rechazo",
                      rechazoTotal,
                    );
                    dxDataGrid_PrendasProd_añadir.editCell(
                      dxDataGrid_PrendasProd_añadir_rowIndex,
                      "retiro",
                    );
                    $("#dxPopup_tiposRechazo").dxPopup("instance").hide();
                    //
                  }
                } catch (error) {
                  args.component.option("disabled", false);
                }
              },
            },
          });
        },
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });
    },
    //EVENTOS
    onShown: function (args) {
      $("#dgvTipoRechazo").dxDataGrid("instance").editCell(0, "Cantidad");
    },
    onHiding: function (args) {
      var gridTipoRechazo = $("#dgvTipoRechazo").dxDataGrid("instance");
      if (
        gridTipoRechazo.getController("editing").hasChanges() &&
        tipoRech_guardado === false
      ) {
        args.cancel = true;
        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          // ACEPTAR
          [
            getTrad("aceptar"),
            function () {
              gridTipoRechazo.cancelEditData();
              args.component.hide();
            },
            "normal",
            "btnAceptar",
          ],
          // CANCELAR
          [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
        ]);
      }
      tipoRech_guardado = false;
    },
    onHidden: function (args) {
      $("#dgvTipoRechazo").dxDataGrid("instance").cancelEditData();
      prendaProdSel = null;
      $("#dxButton_guardar_tipoRech")
        .dxButton("instance")
        .option("disabled", false);
    },
  });

  //#region OTRAS FUNCIONES

  function PrendasProd_getFiltros() {
    var filtros = [];
    $.each(dxDataGrid_PrendasProd_filtros, function (filter, value) {
      if (value) {
        if (filtros.length > 0) filtros.push("and");
        filtros.push([filter, "=", value]);
      }
    });
    return filtros;
  }

  function camposEditabled_cellTemplate(container, options) {
    let tooltip = $("#dxTooltip_Produccion").dxTooltip("instance");
    let className =
      "cell_" +
      options.row.data.idPrendaNProduccion +
      "_" +
      options.column.caption;

    let result = $("<div>")
      .addClass(className)
      .text(options.value)
      .on("mouseenter", function () {
        if (isCierreFactEntidad) {
          tooltip.option({
            target: "." + className,
            contentTemplate: function (contentElement) {
              contentElement.html(getTrad("factBloqueadaEntiFecha"));
            },
          });
          tooltip.show();
        }
      })
      .on("mouseleave", function () {
        tooltip.hide();
      });

    return result;
  }

  //#endregion

  deferred.resolve();
  return deferred.promise();
}
