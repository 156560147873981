import React, { useEffect, useRef, useState } from "react";

import $ from "jquery";

import ArrayStore from "devextreme/data/array_store";
import Query from "devextreme/data/query";
import notify from "devextreme/ui/notify";
import { Column, Lookup } from "devextreme-react/data-grid";

import SharedDataGridHistorico from "../shared/SharedDataGridHistorico";
import SharedPopupHistorico from "../shared/SharedPopupHistorico";
import {
  convertClienteUtcToSinUtc,
  formatDate_parameter,
  getTrad,
} from "helpers";

const PopupHistoricoTipoFacturacionEntidad = ({
  visible,
  entidadSel,
  tblTipoFacturacionCliente,
  getIsCerrado,
  onHiding,
  onSave,
}) => {
  const dataGridRef = useRef(null);

  const [entidadSelState, setEntidadSelState] = useState(entidadSel);

  useEffect(() => {
    if (visible) {
      dataGridRef?.current?.instance?.repaint();
      setEntidadSelState($.extend(true, {}, entidadSel));
    }
  }, [visible]);

  const handleOnSave = () => {
    const dataGridInstance = dataGridRef?.current?.instance;

    dataGridInstance.saveEditData().done(() => {
      setTimeout(() => {
        let isValid = true;

        const data = dataGridInstance.getVisibleRows();

        data.forEach((row) => (isValid = isValid && !row.isEditing));

        const tblEntidad_historico_idTipoFacturacion = data.map(({ data }) => {
          return {
            idTipoFacturacion: data.idTipoFacturacion,
            fecha: data.fecha,
            idEntidad: entidadSelState.idEntidad ?? 0,
          };
        });

        if (isValid) {
          var idTipoFacturacionCliente = Query(
            tblEntidad_historico_idTipoFacturacion
          )
            .filter(
              (tf) =>
                convertClienteUtcToSinUtc(
                  new Date(formatDate_parameter(tf.fecha))
                ) <=
                convertClienteUtcToSinUtc(
                  new Date(formatDate_parameter(new Date()))
                )
            )
            .sortBy("fecha", true)
            .toArray()?.[0]?.idTipoFacturacion;

          onSave({
            ...entidadSelState,
            idTipoFacturacionCliente,
            tblEntidad_historico_idTipoFacturacion,
          });
          onHiding();
        } else {
          notify({
            message: getTrad("errorIntroduccionDatos"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
        }
      }, 0);
    });
  };

  const validationRules = [
    {
      type: "required",
      message: getTrad("campoNecesario"),
    },
  ];

  const dataSource = new ArrayStore({
    data: entidadSelState?.tblEntidad_historico_idTipoFacturacion,
  });

  return (
    <SharedPopupHistorico
      visible={visible}
      title={getTrad("tipoFacturacion")}
      centerTitle={entidadSelState?.denominacion}
      onHiding={onHiding}
      onSave={handleOnSave}
    >
      <SharedDataGridHistorico
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={"idTipoFacturacion"}
        visible={visible}
        errorMessage={"error_historicoTipoFacturacionAlmenosUnTipoFacturacion"}
        getIsCerrado={getIsCerrado}
      >
        <Column
          dataField={"idTipoFacturacion"}
          caption={getTrad("tipoFact")}
          alignment={"center"}
          dataType={"number"}
          validationRules={validationRules}
        >
          <Lookup
            dataSource={tblTipoFacturacionCliente}
            valueExpr={"idTipoFacturacionCliente"}
            displayExpr={"denominacion"}
          />
        </Column>
      </SharedDataGridHistorico>
    </SharedPopupHistorico>
  );
};

export default PopupHistoricoTipoFacturacionEntidad;
