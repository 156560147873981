import { connectionConstants } from "../../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    formatDate_parameter,
    formatDate,
    formatNumber,
    tooltipControl_creacion,
} from "../../../../helpers";
import { allIcons as svg_iconos } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";
import { Cookies } from "react-cookie-banner";

export default function cargaJs(COMPONENT, LAVANDERIA, USER, IDIOMA) {
    var deferred = $.Deferred();

    var entidadSel = null,
        idsRepartos = [],
        idsRepartosOffice = [],
        idsProducciones = [],
        idsAbonos = [],
        datasource_tblTipoFacturacion = null,
        datasource_familias = null,
        enum_isExtra = null;

    var opciones = {
        idEntidad: null,
        fechaDesde: null,
        fechaHasta: null,
    };

    var opcionesFiltro = {
        idFamilia: null,
        isExtra: null,
        idTipoFacturacion: null,
    };

    opciones.fechaDesde = COMPONENT.state.fechaSel.startDate;
    opciones.fechaHasta = COMPONENT.state.fechaSel.endDate;

    opciones.idEntidad = COMPONENT.state.idEntidad;

    const cookies = new Cookies();
    const currentUser = cookies.get("user");
    var refreshToken = currentUser.refreshToken;

    //#region DATASOURCE
    var datasource_infFacturacion_Agrupado_Entidades = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_Entidades",
            key: "idEntidad",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA ? LAVANDERIA.idLavanderia : null;
                request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
                request.params.fechahasta = formatDate_parameter(opciones.fechaHasta);
                request.params.idEntidad = opciones.idEntidad;
            },
            onLoaded: function (data) {
                entidadSel = data[0];
                actualizar_importeTotal();
            },
            version: 4,
        }),
    });

    var datasource_TipoConsumoLenceria = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "infFacturacion_Agrupado_TipoConsumoLenceria",
            key: ["idReparto", "idRepartoOffice", "idProduccion", "idAbono"],
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idEntidad = opciones.idEntidad;
                request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
                request.params.fechaHasta = formatDate_parameter(opciones.fechaHasta);
            },
            version: 4,
        }),
    });

    var datasource_Prendas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url:
                connectionConstants.WEB_API_CORE_ODATA_URL +
                "Informes/InformesOperacionales/InfFacturacionCliente/EF_infFacturacion_Agrupado_Prendas",
            key: ["idPrenda", "idReparto", "idRepartoOffice", "idProduccion", "idAbono"],
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idEntidad = opciones.idEntidad;
                request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
                request.params.fechaHasta = formatDate_parameter(opciones.fechaHasta);

                request.params.idsRepartos = idsRepartos.toString().replace(/,/g, "|");
                request.params.idsRepartosOffice = idsRepartosOffice.toString().replace(/,/g, "|");
                request.params.idsProducciones = idsProducciones.toString().replace(/,/g, "|");
                request.params.idsAbonos = idsAbonos.toString().replace(/,/g, "|");
            },
            version: 4,
        }),
    });

    var datasource_tblPrendaPrecioRefact = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrendaPrecioRefact",
            key: "idPrenda",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
                request.params.idEntidad = opciones.idEntidad;
            },
            version: 4,
        }),
    });

    datasource_tblTipoFacturacion = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblTipoFacturacion",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        map: function (itemData) {
            return {
                text: itemData.denominacion,
                value: itemData.idTipoFacturacion,
            };
        },
        postProcess: function (data) {
            if (datasource_tblTipoFacturacion.items().length === 0) {
                data.splice(0, 0, {
                    value: null,
                    text: getTrad("todas").toUpperCase(),
                });
            }
            return data;
        },
        sort: "denominacion",
    });

    datasource_familias = new DataSource({
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblFamilia",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            version: 4,
        }),
        select: ["idFamilia", "codigo", "denominacion"],
        expand: "tblTraduccion($select=" + IDIOMA.codigo + ")",
        map: function (dataItem) {
            return {
                text: dataItem.tblTraduccion[IDIOMA.codigo].toUpperCase(),
                value: dataItem.idFamilia,
            };
        },
        postProcess: function (data) {
            if (datasource_familias.items().length === 0) {
                data.splice(0, 0, {
                    value: null,
                    text: getTrad("todas").toUpperCase(),
                });
            }
            return data;
        },
        sort: "codigo",
    });

    enum_isExtra = [
        {
            value: null,
            text: getTrad("todas").toUpperCase(),
        },
        {
            value: false,
            text: getTrad("estandar").toUpperCase(),
        },
        {
            value: true,
            text: getTrad("extra").toUpperCase(),
        },
    ];

    //#endregion

    function actualizar() {
        $.when(
            datasource_infFacturacion_Agrupado_Entidades.load(),
            datasource_TipoConsumoLenceria.reload(),
            datasource_Prendas.reload()
        ).then(function () {
            COMPONENT.loadPanel_hide();
        });
    }

    $.when(
        datasource_infFacturacion_Agrupado_Entidades.load(),
        datasource_tblTipoFacturacion.load(),
        datasource_familias.load()
    )
        .then(function () {
            $("#dxForm_opciones").dxForm({
                formData: opcionesFiltro,
                labelLocation: "top",
                height: "100%",
                scrollingEnabled: true,
                colCount: 1,
                items: [
                    {
                        dataField: "idFamilia",
                        label: { text: getTrad("familias") },
                        editorType: "dxRadioGroup",
                        editorOptions: {
                            elementAttr: { class: "dxRadioGroup_opciones" },
                            dataSource: datasource_familias,
                            valueExpr: "value",
                            displayExpr: "text",
                            width: "100%",
                        },
                    },
                    {
                        dataField: "idTipoFacturacion",
                        label: { text: "Tipo facturación" },
                        editorType: "dxRadioGroup",
                        editorOptions: {
                            elementAttr: { class: "dxRadioGroup_opciones" },
                            items: datasource_tblTipoFacturacion.items(),
                            displayExpr: "text",
                            valueExpr: "value",
                        },
                    },
                    {
                        dataField: "isExtra",
                        label: { text: "Tipo prenda" },
                        editorType: "dxRadioGroup",
                        editorOptions: {
                            elementAttr: { class: "dxRadioGroup_opciones" },
                            items: enum_isExtra,
                            displayExpr: "text",
                            valueExpr: "value",
                        },
                    },
                ],
                onFieldDataChanged: function () {
                    $("#dxDataGrid_prendas")
                        .dxDataGrid("instance")
                        .filter(function (itemData) {
                            return (
                                (!opcionesFiltro.idFamilia || itemData.idFamilia === opcionesFiltro.idFamilia) &&
                                (opcionesFiltro.isExtra == null || itemData.isExtra === opcionesFiltro.isExtra) &&
                                (!opcionesFiltro.idTipoFacturacion ||
                                    itemData.tipoFact === opcionesFiltro.idTipoFacturacion)
                            );
                        });
                },
            });

            $("#dxTooltip_Facturacion").dxTooltip({
                showEvent: "mouseenter",
                hideEvent: "mouseleave",
            });

            $("#InfFacturacionCliente #dxContainer").dxBox({
                direction: "col",
                align: "space-around",
                crossAlign: "stretch",
                height: "100%",
                items: [
                    {
                        baseSize: 60,
                        template: function (e, index, itemElement) {
                            var container = $("<div />").css("display", "flex");

                            container.append(
                                $("<div />").addClass("titForm").append($("<h3 />").text(entidadSel.denominacion))
                            );

                            container.append(
                                $("<div id='importeTotal'/>")
                                    .css("color", "rgb(100,100,100)")
                                    .css("font-weight", "lighter")
                                    .css("font-size", "30px")
                                    .css("padding-left", "50px")
                            );

                            var info = $("<div id='contenedorInfo'/>");
                            container.append(info);

                            container.append(
                                $("<div id='dxPopover_importeTotal'/>").dxPopover({
                                    target: info,
                                    showEvent: "mouseenter",
                                    hideEvent: "mouseleave",
                                    position: "bottom",
                                })
                            );

                            setTimeout(function () {
                                actualizar_importeTotal();
                            }, 0);

                            var print = svg_iconos.svg_print.clone().css("height", "18px").css("width", "18px");

                            var serializer_print = new XMLSerializer();
                            var imgHtml_print = serializer_print.serializeToString(print[0]);

                            var edit = svg_iconos.svg_edit.clone().css("height", "18px").css("width", "18px");

                            var serializer_edit = new XMLSerializer();
                            var imgHtml_edit = serializer_edit.serializeToString(edit[0]);

                            container.append(
                                $("<div />")
                                    .css("flex", "1")
                                    .dxToolbar({
                                        items: [
                                            {
                                                location: "after",
                                                widget: "dxButton",
                                                showText: "always",
                                                options: {
                                                    icon: imgHtml_print,
                                                    text: getTrad("imprimir"),
                                                    onClick: function () {
                                                        var params = {
                                                            idEntidad: opciones.idEntidad,
                                                            fechaDesde: formatDate_parameter(opciones.fechaDesde),
                                                            fechaHasta: formatDate_parameter(opciones.fechaHasta),
                                                            idLavanderia: LAVANDERIA ? LAVANDERIA.idLavanderia : "",
                                                            idToken: refreshToken,
                                                            idsRepartos: idsRepartos.toString().replace(/,/g, "|"),
                                                            idsRepartosOffice: idsRepartosOffice
                                                                .toString()
                                                                .replace(/,/g, "|"),
                                                            idsProducciones: idsProducciones
                                                                .toString()
                                                                .replace(/,/g, "|"),
                                                            idsAbonos: idsAbonos.toString().replace(/,/g, "|"),
                                                        };

                                                        COMPONENT.setParams(params);
                                                    },
                                                },
                                            },
                                            {
                                                location: "after",
                                                widget: "dxButton",
                                                showText: "always",
                                                visible: USER.refactura,
                                                options: {
                                                    icon: imgHtml_edit,
                                                    text: getTrad("refacturar"),
                                                    disabled: entidadSel.isFacturacionCerrada == 1,
                                                    elementAttr: {
                                                        id: "dxButton_refacturar",
                                                        class: "pe-auto",
                                                    },
                                                    onContentReady: function (e) {
                                                        let tooltip = $("#dxTooltip_Facturacion").dxTooltip("instance");
                                                        $(e.element).on("mouseover", function () {
                                                            if (entidadSel.isFacturacionCerrada == 1) {
                                                                tooltip.option({
                                                                    showEvent: "mouseenter",
                                                                    hideEvent: "mouseleave",
                                                                    target: "#dxButton_refacturar",
                                                                    contentTemplate: function (contentElement) {
                                                                        contentElement.html(
                                                                            getTrad("factBloqueadaEntiFecha")
                                                                        );
                                                                    },
                                                                });
                                                                tooltip.show();
                                                            } else {
                                                                tooltip.option({
                                                                    target: "#dxButton_refacturar",
                                                                    showEvent: null,
                                                                    hideEvent: null,
                                                                });
                                                            }
                                                        });

                                                        $(e.element).on("mouseout", function () {
                                                            tooltip.hide();
                                                        });
                                                    },
                                                    onClick: function () {
                                                        if (USER.refactura) {
                                                            $("#dxPopup_refacturar").dxPopup("instance").show();
                                                        }
                                                    },
                                                },
                                            },
                                            {
                                                location: "after",
                                                widget: "dxButton",
                                                showText: "inMenu",
                                                options: {
                                                    icon: "refresh",
                                                    text: getTrad("actualizar"),
                                                    onInitialized: function (e) {
                                                        tooltipControl_creacion(e.element, e.component.option("text"));
                                                    },
                                                    onClick: function () {
                                                        COMPONENT.loadPanel_show(true);
                                                        actualizar();
                                                    },
                                                },
                                            },
                                        ],
                                    })
                            );

                            return container;
                        },
                    },
                    {
                        ratio: 1,
                        template: function (e, index, item) {
                            item.append(
                                $("<div />").dxBox({
                                    direction: "row",
                                    align: "space-around",
                                    crossAlign: "stretch",
                                    height: "100%",
                                    items: [
                                        {
                                            ratio: entidadSel.idTipoConsumoLenceria === 2 ? 4 : 3, //En reparto office se le da más espacio
                                            template: function (e, index, item) {
                                                item.css("margin-right", "15px");
                                                item.append(
                                                    $("<div id='dxDataGrid_tipoConsumoLenceria' />").dxDataGrid({
                                                        //Datos
                                                        dataSource: datasource_TipoConsumoLenceria,
                                                        remoteOperations: false,
                                                        //Propiedades
                                                        columnsAutoWidth: true,
                                                        headerFilter: {
                                                            visible: true,
                                                        },
                                                        height: "100%",
                                                        selection: {
                                                            mode: "multiple",
                                                            showCheckBoxesMode: "always",
                                                        },
                                                        export: {
                                                            enabled: false,
                                                            fileName: "infFacturacionCliente",
                                                        },
                                                        scrolling: {
                                                            mode: "infinite",
                                                        },
                                                        loadPanel: { enabled: false },
                                                        columns: [
                                                            {
                                                                dataField: "codigo",
                                                                caption: getTrad("codigo"),
                                                                width: "90px",
                                                                alignment: "center",
                                                                allowHeaderFiltering: false,
                                                            },
                                                            {
                                                                dataField: "fecha",
                                                                caption: getTrad("fecha"),
                                                                dataType: "date",
                                                                format: "dd/MM/yyyy",
                                                                width: "110px",
                                                                alignment: "center",
                                                                sortOrder: "desc",
                                                                allowSearch: false,
                                                                allowHeaderFiltering: false,
                                                                headerFilter: {
                                                                    allowSearch: true,
                                                                },
                                                            },
                                                            {
                                                                dataField: "almacen",
                                                                caption: "Almacén",
                                                                width: "150px",
                                                                visible: entidadSel.idTipoConsumoLenceria === 2,
                                                            },
                                                            {
                                                                dataField: "tipo",
                                                                caption: "Tipo",
                                                            },
                                                            {
                                                                dataField: "importe",
                                                                caption: "Importe",
                                                                width: "135px",
                                                                alignment: "center",
                                                                format: {
                                                                    style: "currency",
                                                                    maximumFractionDigits: 2,
                                                                    currency: entidadSel.moneda,
                                                                },
                                                                allowHeaderFiltering: false,
                                                                allowFiltering: false,
                                                            },
                                                        ],
                                                        summary: {
                                                            recalculateWhileEditing: true,
                                                            totalItems: [
                                                                {
                                                                    column: "codigo",
                                                                    displayFormat: getTrad("total").toUpperCase(),
                                                                },
                                                                {
                                                                    column: "importe",
                                                                    summaryType: "sum",
                                                                    customizeText: function (data) {
                                                                        return formatNumber(
                                                                            data.value,
                                                                            2,
                                                                            "currency",
                                                                            entidadSel.moneda
                                                                        );
                                                                    },
                                                                    displayFormat: "{0}",
                                                                },
                                                            ],
                                                        },
                                                        //Eventos
                                                        onContentReady: function (e) {
                                                            //onFilterChanged
                                                            var filter_old = e.component.option("filter_old");
                                                            var filter_new = e.component.getCombinedFilter();

                                                            if (
                                                                JSON.stringify(filter_old) !==
                                                                JSON.stringify(filter_new)
                                                            ) {
                                                                e.component.option("filter_old", filter_new);
                                                                e.component.option("selectedRowKeys", []);
                                                            }
                                                        },
                                                        onSelectionChanged: function (e) {
                                                            var itemsSel = e.component.getSelectedRowsData();
                                                            idsRepartos = [];
                                                            idsRepartosOffice = [];
                                                            idsProducciones = [];
                                                            idsAbonos = [];

                                                            $.each(itemsSel, function (index, item) {
                                                                if (item.idReparto) idsRepartos.push(item.idReparto);
                                                                else if (item.idRepartoOffice)
                                                                    idsRepartosOffice.push(item.idRepartoOffice);
                                                                else if (item.idProduccion)
                                                                    idsProducciones.push(item.idProduccion);
                                                                else if (item.idAbono) idsAbonos.push(item.idAbono);
                                                            });

                                                            //Filtro grid prendas
                                                            datasource_Prendas.reload();
                                                        },
                                                        //Estilos
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                        rowAlternationEnabled: true,
                                                    })
                                                );
                                            },
                                        },
                                        {
                                            ratio: entidadSel.idTipoConsumoLenceria === 2 ? 6 : 5, //En reparto office se le da más espacio
                                            template: function (e, index, item) {
                                                item.append(
                                                    $("<div id='dxDataGrid_prendas' />").dxDataGrid({
                                                        //Datos
                                                        dataSource: datasource_Prendas,
                                                        remoteOperations: false,
                                                        //Propiedades
                                                        columnsAutoWidth: true,
                                                        headerFilter: {
                                                            visible: true,
                                                        },
                                                        height: "100%",
                                                        scrolling: {
                                                            mode: "infinite",
                                                        },
                                                        loadPanel: { enabled: false },
                                                        columns: [
                                                            {
                                                                dataField: "codigoPrenda",
                                                                caption: getTrad("codigo"),
                                                                width: "100px",
                                                                alignment: "center",
                                                                sortOrder: "asc",
                                                                sortIndex: 1,
                                                                allowHeaderFiltering: false,
                                                            },
                                                            {
                                                                dataField: "denominacion",
                                                                caption: getTrad("denominacion"),
                                                                minWidth: 300,
                                                                allowEditing: false,
                                                                allowHeaderFiltering: false,
                                                            },
                                                            {
                                                                dataField: "tipoFact",
                                                                caption: " ",
                                                                width: "50px",
                                                                allowHeaderFiltering: false,
                                                                alignment: "center",
                                                                cssClass: "p-0",
                                                                groupIndex: 0,
                                                                groupCellTemplate: function (cellElement, cellInfo) {
                                                                    var container = $("<div />").addClass(
                                                                        "container_spanCentrado justify-content-start"
                                                                    );
                                                                    container.append(
                                                                        svg_iconos[cellInfo.data.items[0].tipoFact_icon]
                                                                            .clone()
                                                                            .css("height", "25px")
                                                                            .css("width", "25px")
                                                                            .css("margin-right", "10px")
                                                                            .css("cursor", "pointer")
                                                                    );

                                                                    container.append(
                                                                        "FACTURACIÓN " +
                                                                            cellInfo.data.items[0].tipoFact_denominacion
                                                                    );

                                                                    return container;
                                                                },
                                                            },
                                                            {
                                                                dataField: "peso",
                                                                caption: getTrad("peso"),
                                                                alignment: "center",
                                                                width: "80px",
                                                                format: {
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 3,
                                                                },
                                                                allowHeaderFiltering: false,
                                                                allowFiltering: false,
                                                            },
                                                            {
                                                                dataField: "precio",
                                                                caption: getTrad("precio"),
                                                                width: "120px",
                                                                alignment: "center",
                                                                format: {
                                                                    style: "currency",
                                                                    maximumFractionDigits: 4,
                                                                    currency: entidadSel.moneda,
                                                                },
                                                                calculateCellValue: function (cellInfo) {
                                                                    if (cellInfo.precio === 0) {
                                                                        return getTrad("sinPrecio");
                                                                    }
                                                                    return cellInfo.precio;
                                                                },
                                                                allowHeaderFiltering: false,
                                                                allowFiltering: false,
                                                            },
                                                            {
                                                                dataField: "cantidad",
                                                                caption: getTrad("udsEntregadas"),
                                                                width: "120px",
                                                                alignment: "center",
                                                                format: {
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                },
                                                                allowHeaderFiltering: false,
                                                                allowFiltering: false,
                                                            },
                                                            {
                                                                dataField: "cantidadAbo",
                                                                caption: "Abono",
                                                                width: "90px",
                                                                alignment: "center",
                                                                format: {
                                                                    style: "decimal",
                                                                    maximumFractionDigits: 0,
                                                                },
                                                                allowHeaderFiltering: false,
                                                                allowFiltering: false,
                                                            },
                                                            {
                                                                caption: "Importe total",
                                                                name: "importeTotal",
                                                                width: "135px",
                                                                alignment: "center",
                                                                format: {
                                                                    style: "currency",
                                                                    maximumFractionDigits: 2,
                                                                    currency: entidadSel.moneda,
                                                                },
                                                                allowHeaderFiltering: false,
                                                                calculateCellValue: function (rowData) {
                                                                    return rowData.importe + rowData.importeAbo;
                                                                },
                                                                cellTemplate: function (cellElement, cellInfo) {
                                                                    //Tooltip

                                                                    var htmlTooltip = "";
                                                                    htmlTooltip +=
                                                                        "<b>Importe:</b> " +
                                                                        formatNumber(
                                                                            cellInfo.data.importe,
                                                                            2,
                                                                            "currency",
                                                                            entidadSel.moneda
                                                                        );
                                                                    htmlTooltip += "</br>";
                                                                    htmlTooltip +=
                                                                        "<b>Abonado:</b> " +
                                                                        formatNumber(
                                                                            cellInfo.data.importeAbo,
                                                                            2,
                                                                            "currency",
                                                                            entidadSel.moneda
                                                                        );

                                                                    cellElement.append(
                                                                        $("<div />").dxTooltip({
                                                                            target: cellElement,
                                                                            // height: "42px",
                                                                            showEvent: "dxhoverstart",
                                                                            hideEvent: "dxhoverend",
                                                                            position: "top",
                                                                            contentTemplate: function (contentElement) {
                                                                                contentElement.html(htmlTooltip);
                                                                            },
                                                                            animation: {
                                                                                show: {
                                                                                    type: "pop",
                                                                                    from: {
                                                                                        scale: 0.1,
                                                                                        opacity: 0,
                                                                                    },
                                                                                    to: {
                                                                                        opacity: 1,
                                                                                        scale: 1,
                                                                                    },
                                                                                },
                                                                                hide: {
                                                                                    type: "pop",
                                                                                    from: {
                                                                                        scale: 1,
                                                                                        opacity: 1,
                                                                                    },
                                                                                    to: {
                                                                                        opacity: 0,
                                                                                        scale: 0.1,
                                                                                    },
                                                                                },
                                                                            },
                                                                        })
                                                                    );

                                                                    return $("<div />").text(cellInfo.text);
                                                                },
                                                            },
                                                            {
                                                                dataField: "tipoFact",
                                                                visible: false,
                                                            },
                                                            {
                                                                dataField: "idFamilia",
                                                                visible: false,
                                                            },
                                                            {
                                                                dataField: "isExtra",
                                                                visible: false,
                                                            },
                                                        ],
                                                        onRowPrepared: function (options) {
                                                            if (
                                                                options.rowType === "data" &&
                                                                options.data.tipoFact !== 2 &&
                                                                options.data.precio === 0
                                                            ) {
                                                                $(options.rowElement).addClass("warningRow_onlyText");
                                                            }
                                                        },
                                                        summary: {
                                                            recalculateWhileEditing: true,
                                                            totalItems: [
                                                                {
                                                                    column: "codigoPrenda",
                                                                    displayFormat: getTrad("total").toUpperCase(),
                                                                },
                                                                {
                                                                    column: "importeTotal",
                                                                    summaryType: "sum",
                                                                    customizeText: function (data) {
                                                                        return formatNumber(
                                                                            data.value,
                                                                            2,
                                                                            "currency",
                                                                            entidadSel.moneda
                                                                        );
                                                                    },
                                                                    displayFormat: "{0}",
                                                                },
                                                            ],
                                                            groupItems: [
                                                                {
                                                                    column: "importeTotal",
                                                                    summaryType: "sum",
                                                                    customizeText: function (data) {
                                                                        return formatNumber(
                                                                            data.value,
                                                                            2,
                                                                            "currency",
                                                                            entidadSel.moneda
                                                                        );
                                                                    },
                                                                    displayFormat: "{0}",
                                                                    alignByColumn: true,
                                                                    showInGroupFooter: false,
                                                                },
                                                            ],
                                                        },
                                                        onContentReady: function (e) {
                                                            query(datasource_Prendas.items()[0].items)
                                                                .sum("cantidadAbo")
                                                                .done(function (totalAbo) {
                                                                    e.component.columnOption(
                                                                        "cantidadAbo",
                                                                        "visible",
                                                                        totalAbo > 0
                                                                    );
                                                                    setTimeout(() => {
                                                                        COMPONENT.loadPanel_hide();
                                                                    }, 225);
                                                                });
                                                        },
                                                        //Estilos
                                                        showColumnLines: false,
                                                        showRowLines: true,
                                                        rowAlternationEnabled: true,
                                                    })
                                                );
                                            },
                                        },
                                    ],
                                })
                            );
                        },
                    },
                ],
            });

            $("#InfFacturacionCliente #dxPopup_refacturar").dxPopup({
                showTitle: true,
                title: "Refacturar prendas", //getTrad('prendasPedido'),
                height: "80%",
                width: 900,
                //evento
                onHidden: function (e) {
                    $("#dxDataGrid_refacturarPrendas").dxDataGrid("instance").cancelEditData();
                },
                contentTemplate: function () {
                    return $("<div id='dxDataGrid_refacturarPrendas' />").dxDataGrid({
                        //Datos
                        dataSource: datasource_tblPrendaPrecioRefact,
                        remoteOperations: true,
                        //Propiedades
                        height: "100%",
                        columnsAutoWidth: true,
                        searchPanel: {
                            visible: true,
                            width: 240,
                            placeholder: getTrad("busqueda"),
                        },
                        filterRow: {
                            visible: true,
                            applyFilter: "auto",
                        },
                        editing: {
                            mode: "cell",
                            allowUpdating: true,
                            allowDeleting: false,
                            useIcons: true,
                            selectTextOnEditStart: true,
                        },
                        keyboardNavigation: {
                            enterKeyAction: "moveFocus",
                            enterKeyDirection: "row",
                            editOnKeyPress: true,
                        },
                        paging: {
                            enabled: false,
                        },
                        //Columnas
                        columns: [
                            {
                                dataField: "idPrenda",
                                caption: "",
                                visible: false,
                            },
                            {
                                dataField: "codigoPrenda",
                                caption: getTrad("codigo"),
                                sortOrder: "asc",
                                width: "100px",
                                alignment: "center",
                                allowEditing: false,
                                editorOptions: { showClearButton: true },
                            },
                            {
                                dataField: "denominacion",
                                caption: getTrad("denominacion"),
                                allowEditing: false,
                                editorOptions: { showClearButton: true },
                            },
                            {
                                dataField: "denoTipoFact",
                                caption: getTrad("tipoFact"),
                                width: "200px",
                                allowEditing: false,
                                editorOptions: { showClearButton: true },
                            },
                            {
                                dataField: "precioLavado",
                                caption: getTrad("precioLav"),
                                width: "100px",
                                alignment: "center",
                                dataType: "number",
                                format: {
                                    style: "currency",
                                    maximumFractionDigits: 4,
                                    currency: entidadSel.moneda,
                                },
                                allowEditing: false,
                                allowFiltering: false,
                                allowSorting: false,
                            },
                            {
                                dataField: "precioRefacturado",
                                caption: getTrad("precioRefacturado"),
                                width: "100px",
                                alignment: "center",
                                dataType: "number",
                                format: {
                                    style: "currency",
                                    maximumFractionDigits: 4,
                                    currency: entidadSel.moneda,
                                },
                                editorOptions: {
                                    max: 99.99,
                                    format: {
                                        style: "currency",
                                        maximumFractionDigits: 4,
                                        currency: entidadSel.moneda,
                                    },
                                    step: 0,
                                },
                                cssClass: "dx-Cell_Editable",
                                allowFiltering: false,
                                allowSorting: false,
                            },
                        ],
                        //Eventos
                        onToolbarPreparing: function (e) {
                            e.toolbarOptions.items.unshift(
                                {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    locateInMenu: "auto",
                                    options: {
                                        text: getTrad("limpiarFiltro"),
                                        icon: " icon_EliminarFiltros",
                                        onClick: function () {
                                            e.clearFilter();
                                            notify({
                                                message: getTrad("aviso_C_FiltroRestaurado"),
                                                type: "success",
                                                displayTime: "1500",
                                                closeOnClick: true,
                                            });
                                        },
                                    },
                                },
                                {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                        elementAttr: { id: "btnActualizar" },
                                        icon: "refresh",
                                        text: getTrad("actualizar"),
                                        onClick: function () {
                                            e.refresh();
                                        },
                                    },
                                }
                            );
                        },
                        onRowUpdating: function (e) {
                            var prendaPrecioRefact = {};
                            prendaPrecioRefact.precioRefacturado = e.newData.precioRefacturado;
                            if (e.oldData.precioRefacturado === e.oldData.precioLavado) {
                                //Insert
                                prendaPrecioRefact.idPrenda = e.key;
                                datasource_tblPrendaPrecioRefact
                                    .store()
                                    .insert(prendaPrecioRefact)
                                    .done(function () {
                                        notify({
                                            message: getTrad("aviso_C_RegistroInsertado"),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                        });

                                        var grid = $("#dxDataGrid_refacturarPrendas").dxDataGrid("instance");
                                        grid.cancelEditData();
                                        grid.refresh();
                                    });
                            } else if (e.newData.precioRefacturado === e.oldData.precioLavado) {
                                //Delete
                                datasource_tblPrendaPrecioRefact
                                    .store()
                                    .remove(e.key)
                                    .done(function () {
                                        notify({
                                            message: getTrad("aviso_C_RegistroInsertado"),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                        });

                                        var grid = $("#dxDataGrid_refacturarPrendas").dxDataGrid("instance");
                                        grid.cancelEditData();
                                        grid.refresh();
                                    });
                            } else {
                                //Update
                                datasource_tblPrendaPrecioRefact
                                    .store()
                                    .update(e.key, prendaPrecioRefact)
                                    .done(function () {
                                        notify({
                                            message: getTrad("aviso_C_RegistroInsertado"),
                                            type: "success",
                                            displayTime: "1500",
                                            closeOnClick: true,
                                        });

                                        var grid = $("#dxDataGrid_refacturarPrendas").dxDataGrid("instance");
                                        grid.cancelEditData();
                                        grid.refresh();
                                    });
                            }

                            e.cancel = true;
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                    });
                },
                toolbarItems: [
                    {
                        toolbar: "bottom",
                        location: "after",
                        widget: "dxButton",
                        options: {
                            text: getTrad("imprimir"),
                            icon: "check",
                            type: "success",
                            onClick: function () {
                                $("#dxPopup_refacturar").dxPopup("instance").hide();

                                var params = {
                                    idEntidad: opciones.idEntidad,
                                    fechaDesde: formatDate_parameter(opciones.fechaDesde),
                                    fechaHasta: formatDate_parameter(opciones.fechaHasta),
                                    idLavanderia: LAVANDERIA ? LAVANDERIA.idLavanderia : "",
                                    idToken: refreshToken,
                                    refacturar: 1,
                                    idsRepartos: "",
                                    idsRepartosOffice: "",
                                    idsProducciones: "",
                                    idsAbonos: "",
                                };

                                COMPONENT.setParams(params);
                            },
                        },
                    },
                ],
            });

            $(".backButton").on("click", function () {
                COMPONENT.setState({
                    showSelector: true,
                });
            });

            tooltipControl_creacion($(".backButton"), getTrad("volverLavanderia"));
        })
        .always(function () {
            deferred.resolve();
        });

    function actualizar_importeTotal() {
        //IMPORTE
        if ($("#importeTotal").length > 0) {
            $("#importeTotal").text(formatNumber(entidadSel.imp, 2, "currency", entidadSel.moneda));
        }

        //ICONO
        var error_UltimaEstancia = !entidadSel.ultimaEstancia || entidadSel.ultimaEstancia < opciones.fechaHasta;
        var error_RepartosSinValidar = entidadSel.numRepNoValidados > 0 && entidadSel.idTipoConsumoLenceria === 1;
        var facturaEstancia = entidadSel.idTipoFacturacionCliente === 0;

        if ($("#contenedorInfo").length > 0 && (facturaEstancia || error_UltimaEstancia || error_RepartosSinValidar)) {
            var infoSvg;

            if (error_UltimaEstancia || error_RepartosSinValidar) {
                infoSvg = svg_iconos.svg_warning.clone();
            } else {
                infoSvg = svg_iconos.svg_info.clone();
            }

            infoSvg.css("height", "35px").css("width", "25px").css("cursor", "pointer").css("margin-left", "15px");

            var container = $("#contenedorInfo");
            container.empty();
            container.append(infoSvg);

            //POPOVER
            if ($("#dxPopover_importeTotal").length > 0) {
                $("#dxPopover_importeTotal")
                    .dxPopover("instance")
                    .option("contentTemplate", function (contentElement) {
                        const numPrecios0 = datasource_Prendas.items()[0].items.reduce((acumulador, prenda) => {
                            if (prenda.tipoFact !== 2 && prenda.precio === 0) {
                                acumulador++;
                            }
                            return acumulador;
                        }, 0);
                        contentElement.append(
                            $("<div />").dxBox({
                                direction: "col",
                                align: "space-around",
                                crossAlign: "stretch",
                                height:
                                    (facturaEstancia ? 150 : 0) +
                                    (error_UltimaEstancia ? 120 : 0) +
                                    (error_RepartosSinValidar ? 70 : 0),
                                width: "350px",
                                items: [
                                    {
                                        ratio: 2,
                                        visible: error_UltimaEstancia,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("flex-direction", "row");
                                            itemElement.addClass("container_spanCentrado");

                                            var msg =
                                                "La fecha de la última estancia no coincide con la fecha fin del rango seleccionado.";
                                            if (entidadSel.ultimaEstancia) {
                                                msg +=
                                                    " Fecha última estancia: " + formatDate(entidadSel.ultimaEstancia);
                                            }

                                            itemElement.append(
                                                $("<div />")
                                                    .css("color", "white")
                                                    .css("font-size", "16px")
                                                    .css("font-weight", "lighter")
                                                    .css("text-align", "center")
                                                    .css("background-color", "rgb(217, 83, 79)")
                                                    .css("border-radius", "5px")
                                                    .css("padding", "10px")
                                                    .css("width", "100%")
                                                    .text(msg)
                                            );
                                        },
                                    },
                                    {
                                        ratio: 1,
                                        visible: error_RepartosSinValidar,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("flex-direction", "row");
                                            itemElement.addClass("container_spanCentrado");

                                            var msg =
                                                "Existen (" + entidadSel.numRepNoValidados + ") repartos sin validar";

                                            itemElement.append(
                                                $("<div />")
                                                    .css("color", "white")
                                                    .css("font-size", "16px")
                                                    .css("font-weight", "lighter")
                                                    .css("text-align", "center")
                                                    .css("background-color", "rgb(217, 83, 79)")
                                                    .css("border-radius", "5px")
                                                    .css("padding", "10px")
                                                    .css("width", "100%")
                                                    .text(msg)
                                            );
                                        },
                                    },
                                    {
                                        ratio: 1,
                                        visible: numPrecios0 > 0,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("flex-direction", "row");
                                            itemElement.addClass("container_spanCentrado");

                                            var msg = "Existen (" + numPrecios0 + ") prendas sin precio";

                                            itemElement.append(
                                                $("<div />")
                                                    .css("color", "white")
                                                    .css("font-size", "16px")
                                                    .css("font-weight", "lighter")
                                                    .css("text-align", "center")
                                                    .css("background-color", "rgb(217, 83, 79)")
                                                    .css("border-radius", "5px")
                                                    .css("padding", "10px")
                                                    .css("width", "100%")
                                                    .text(msg)
                                            );
                                        },
                                    },
                                    {
                                        ratio: 1,
                                        visible: facturaEstancia,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("flex-direction", "row");
                                            itemElement.addClass("container_spanCentrado");
                                            itemElement.css("padding", "0px 10px");

                                            itemElement.append(
                                                $("<div />")
                                                    .css("flex", "1")
                                                    .css("font-weight", "bold")
                                                    .css("font-size", "14px")
                                                    .css("color", "rgb(100,100,100)")
                                                    .text("Facturación por estancia:")
                                            );

                                            itemElement.append(
                                                $("<div />")
                                                    .css("font-size", "18px")
                                                    .css("font-weight", "lighter")
                                                    .css("text-align", "right")
                                                    .css("color", "rgb(100,100,100)")
                                                    .text(
                                                        formatNumber(
                                                            entidadSel.impEstancias,
                                                            2,
                                                            "currency",
                                                            entidadSel.moneda
                                                        )
                                                    )
                                            );
                                        },
                                    },
                                    {
                                        ratio: 2,
                                        visible: facturaEstancia,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("padding-left", "20px");

                                            itemElement.append(
                                                $("<div />")
                                                    .css("display", "flex")
                                                    .css("flex-direction", "row")
                                                    .css("justify-content", "left")
                                                    .addClass("container_spanCentrado")
                                                    .append(
                                                        $("<div />")
                                                            .css("flex-basis", "150px")
                                                            .css("font-size", "14px")
                                                            .css("color", "rgb(100,100,100)")
                                                            .text("Estancias:")
                                                    )

                                                    .append(
                                                        $("<div />")
                                                            .css("font-size", "16px")
                                                            .css("font-weight", "lighter")
                                                            .css("text-align", "right")
                                                            .css("color", "rgb(100,100,100)")
                                                            .text(formatNumber(entidadSel.estancias, 0, "decimal"))
                                                    )
                                            );

                                            itemElement.append(
                                                $("<div />")
                                                    .css("display", "flex")
                                                    .css("flex-direction", "row")
                                                    .css("justify-content", "left")
                                                    .addClass("container_spanCentrado")
                                                    .append(
                                                        $("<div />")
                                                            .css("flex-basis", "150px")
                                                            .css("font-size", "14px")
                                                            .css("color", "rgb(100,100,100)")
                                                            .text("Coste estancia:")
                                                    )

                                                    .append(
                                                        $("<div />")
                                                            .css("font-size", "16px")
                                                            .css("font-weight", "lighter")
                                                            .css("text-align", "right")
                                                            .css("color", "rgb(100,100,100)")
                                                            .text(
                                                                formatNumber(
                                                                    entidadSel.costeEstancia,
                                                                    3,
                                                                    "currency",
                                                                    entidadSel.moneda
                                                                )
                                                            )
                                                    )
                                            );
                                        },
                                    },
                                    {
                                        ratio: 1,
                                        visible: facturaEstancia,
                                        template: function (e, index, itemElement) {
                                            itemElement.css("flex-direction", "row");
                                            itemElement.addClass("container_spanCentrado");
                                            itemElement.css("padding", "0px 10px");

                                            itemElement.append(
                                                $("<div />")
                                                    .css("flex", "1")
                                                    .css("font-weight", "bold")
                                                    .css("font-size", "14px")
                                                    .css("color", "rgb(100,100,100)")
                                                    .text("Extras:")
                                            );

                                            itemElement.append(
                                                $("<div />")
                                                    .css("font-size", "18px")
                                                    .css("font-weight", "lighter")
                                                    .css("text-align", "right")
                                                    .css("color", "rgb(100,100,100)")
                                                    .text(
                                                        formatNumber(
                                                            entidadSel.impExtras,
                                                            2,
                                                            "currency",
                                                            entidadSel.moneda
                                                        )
                                                    )
                                            );
                                        },
                                    },
                                ],
                            })
                        );
                    });
            }
        }
    }

    return deferred.promise();
}
