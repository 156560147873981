import React, { Fragment } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { DefinedRange } from "react-date-range";
import { DateRange_ as DateRange } from "../../libraries/react-date-range.js";
import $ from "jquery";
import {
  DropDownBox,
  Button as DropDownBoxButton,
  DropDownOptions,
} from "devextreme-react/drop-down-box";
import {
  formatDate,
  getTrad,
  startOfYear,
  endOfYear,
  isSameDay,
  startOfMonth,
  endOfMonth,
  getYesterday,
  getCurrentWeek,
  capitalize,
  formatDate_noTime_parameter,
} from "../../helpers";
import Box, { Item as ItemBox } from "devextreme-react/box";
import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import { Tooltip } from "devextreme-react/tooltip";
import List from "devextreme-react/list";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import Calendar from "devextreme-react/calendar";
import Toolbar, { Item as ItemToolbar } from "devextreme-react/toolbar";
import TextArea from "devextreme-react/text-area";
import { ButtonGroup } from "devextreme-react/button-group";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";

//Css
import "./Css.scss";

class DateRangePicker extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isVisible_dxPopup_dxDateBox: false,
      idTipoCalendarioSel: 0,
      calendarioMensual_zoomLevel: "year",
      dateRange_inputValue: null,
    };

    this.dxDropDownBox_dateRangePicker_REF = React.createRef();

    this.dxDropDownBox_onContentReady =
      this.dxDropDownBox_onContentReady.bind(this);
    this.dxDropDownBox_dateRangePicker_fieldRender =
      this.dxDropDownBox_dateRangePicker_fieldRender.bind(this);
    this.onOptionChanged_dxDropDownBox =
      this.onOptionChanged_dxDropDownBox.bind(this);
    this.render_dxDropdownBox = this.render_dxDropdownBox.bind(this);
    this.onChange_dateRange = this.onChange_dateRange.bind(this);
    this.dateRangePicker_onDateSelected =
      this.dateRangePicker_onDateSelected.bind(this);
    this.dxTexBoxButton_date_onClick =
      this.dxTexBoxButton_date_onClick.bind(this);

    this.renderStaticRangeLabel = this.renderStaticRangeLabel.bind(this);

    this.dxButtonGroup_onItemClick = this.dxButtonGroup_onItemClick.bind(this);
    this.dxListEstados_onOptionChanged =
      this.dxListEstados_onOptionChanged.bind(this);
    this.dxCalendar_monthSelector_onOptionChanged =
      this.dxCalendar_monthSelector_onOptionChanged.bind(this);
    this.dxCalendar_monthSelector_onValueChanged =
      this.dxCalendar_monthSelector_onValueChanged.bind(this);
    this.dxCalendar_monthSelector_cellRender =
      this.dxCalendar_monthSelector_cellRender.bind(this);
    this.dxCalendar_yearSelector_onValueChanged =
      this.dxCalendar_yearSelector_onValueChanged.bind(this);
    this.dxButton_aceptar_onClick = this.dxButton_aceptar_onClick.bind(this);

    this.formatFechaSel = this.formatFechaSel.bind(this);
    this.customDayContent = this.customDayContent.bind(this);

    let { month, year, day } = this.props;
    this.enum_tipoCalendario = [
      {
        idTipoCalendario: 0,
        denominacion: getTrad("mensual"),
        visible: month != null,
      },
      {
        idTipoCalendario: 1,
        denominacion: getTrad("anual"),
        visible: year != null,
      },
      {
        idTipoCalendario: 2,
        denominacion: getTrad("personalizado"),
        visible: day != null,
      },
    ];
  }

  get dxDropDownBox_dateRangePicker() {
    return this.dxDropDownBox_dateRangePicker_REF.current.instance;
  }

  //PROPS ACEPTADAS
  // month - sin valor
  // year - sin valor
  // day- sin valor
  // defaultType - month, year, personalizado
  // fechaSel
  // minDate
  // maxDate
  // onDateSelected

  render() {
    let { isVisible_dxPopup_dxDateBox, idTipoCalendarioSel } = this.state;
    let { fechaSel, resolucion, align, disabled = false } = this.props;
    let position;
    let isUnicoEstado =
      this.enum_tipoCalendario.filter((x) => x.visible).length == 1;
    let isVisible_ListSelectEstado =
      !isUnicoEstado || (isUnicoEstado && idTipoCalendarioSel == 2);

    if (resolucion === "xs")
      position = { my: "center", at: "center", of: window };
    else
      position = {
        my: "top " + (align ? align : "left"),
        at: "bottom " + (align ? align : "left"),
        of: "#dxDropDownBox_dateRangePicker",
      };

    return (
      <Fragment>
        <DropDownBox
          ref={this.dxDropDownBox_dateRangePicker_REF}
          elementAttr={this.dxDropDownBox_dateRangePicker_elementAttr}
          width={
            resolucion == "xs"
              ? 160
              : !fechaSel
                ? 165
                : fechaSel.idTipoCalendario === 2
                  ? fechaSel.startDate.getTime() === fechaSel.endDate.getTime()
                    ? 135
                    : 220
                  : fechaSel.idTipoCalendario === 0
                    ? 165
                    : 110
          }
          value={this.formatFechaSel()}
          disabled={!fechaSel || disabled}
          onContentReady={this.dxDropDownBox_onContentReady}
          onOptionChanged={this.onOptionChanged_dxDropDownBox}
          contentRender={(e) =>
            this.render_dxDropdownBox(
              e,
              isVisible_ListSelectEstado,
              isUnicoEstado,
            )
          }
          opened={isVisible_dxPopup_dxDateBox}
          fieldRender={this.dxDropDownBox_dateRangePicker_fieldRender}
        >
          <DropDownBoxButton
            name="DateBoxButton"
            location="after"
            options={this.dxTexBoxButton_date}
          />
          <DropDownOptions
            height={
              resolucion === "xs" && isUnicoEstado && idTipoCalendarioSel != 2
                ? 350
                : resolucion === "xs" &&
                    isUnicoEstado &&
                    idTipoCalendarioSel == 2
                  ? 450
                  : resolucion === "xs" && !isUnicoEstado
                    ? 500
                    : isVisible_ListSelectEstado
                      ? 500
                      : 375
            }
            width={
              resolucion === "xs"
                ? "90%"
                : isVisible_ListSelectEstado
                  ? "80vw"
                  : idTipoCalendarioSel == 2
                    ? "30vw"
                    : "21vw"
            }
            minWidth={345}
            maxWidth={970}
            closeOnOutsideClick={true}
            position={position}
          />
        </DropDownBox>
      </Fragment>
    );
  }

  //#region ELEMENTOS
  staticRanges = [
    {
      label: getTrad("hoy"),
      hasCustomRendering: true,
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: getTrad("ayer"),
      hasCustomRendering: true,
      range: () => ({
        startDate: getYesterday(),
        endDate: getYesterday(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: getTrad("semanaActual"),
      hasCustomRendering: true,
      range: () => ({
        startDate: getCurrentWeek()[0],
        endDate: getCurrentWeek()[1],
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: getTrad("mesActual"),
      hasCustomRendering: true,
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfMonth(new Date()),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
    {
      label: getTrad("añoActual"),
      hasCustomRendering: true,
      range: () => ({
        startDate: startOfYear(new Date()),
        endDate: new Date(),
      }),
      isSelected(range) {
        const definedRange = this.range();
        return (
          isSameDay(range.startDate, definedRange.startDate) &&
          isSameDay(range.endDate, definedRange.endDate)
        );
      },
    },
  ];

  dxTexBoxButton_date = {
    icon: "event",
    stylingMode: "text",
    onClick: this.dxTexBoxButton_date_onClick,
  };

  dxButton_aceptar = {
    elementAttr: { id: "dxButton_aceptar" },
    text: getTrad("aceptar"),
    type: "success",
    onClick: (e) => {
      this.dxButton_aceptar_onClick(e, this);
    },
  };

  dxResponsiveBox_PopupDatePicker_elementAttr = {
    id: "dxResponsiveBox_PopupDatePicker",
  };

  dxBox_tiposCalendario_elementAttr = {
    class: "p-3",
  };

  dxDropDownBox_dateRangePicker_elementAttr = {
    id: "dxDropDownBox_dateRangePicker",
  };

  renderStaticRangeLabel(e) {
    e.disabled = true;
    e.readOnly = true;

    return e.label;
  }
  //#endregion

  //#region MÉTODOS
  formatFechaSel() {
    let { idioma, fechaSel } = this.props;

    if (fechaSel && fechaSel.startDate) {
      if (fechaSel.idTipoCalendario === 1) {
        return fechaSel.startDate.getFullYear();
      } else if (fechaSel.idTipoCalendario === 0) {
        return (
          capitalize(
            fechaSel.startDate.toLocaleString(idioma.codigo, { month: "long" }),
          ) +
          " " +
          fechaSel.startDate.getFullYear()
        );
      } else if (fechaSel.idTipoCalendario === 2) {
        if (fechaSel.startDate.getTime() === fechaSel.endDate.getTime())
          return formatDate(fechaSel.startDate);
        return (
          formatDate(fechaSel.startDate) + " - " + formatDate(fechaSel.endDate)
        );
      }
    }
    return null;
  }

  customDayContent(day) {
    let { minDate, maxDate, dataSource } = this.props;
    let colorEvento = "";
    if (dataSource) {
      let dataInDate = $.grep(dataSource.items(), function (item) {
        if (item.fecha.toDateString() === day.toDateString()) {
          return true;
        }
      });
      let id = formatDate_noTime_parameter(day);

      //#region FORMAT DATA
      let formatData = $.map(dataInDate, function (item) {
        return {
          idEvento: item.idEvento,
          titulo: item.titulo,
          denoEventoSingular: item.denoEventoSingular,
          denoEventoPlural: item.denoEventoPlural,
          colorEvento: item.colorEvento,
        };
      });

      let groups = {};
      formatData.forEach(function (item) {
        var dataObj = {
          idEvento: item.idEvento,
          titulo: item.titulo,
          denoEventoSingular: item.denoEventoSingular,
          denoEventoPlural: item.denoEventoPlural,
          colorEvento: item.colorEvento,
        };

        if (groups[item.idEvento]) {
          groups[item.idEvento]["Data"].push(dataObj);
        } else {
          groups[item.idEvento] = { idEvento: item.idEvento, Data: [dataObj] };
        }
      });

      var data = Object.keys(groups)
        .map(function (k) {
          return groups[k];
        })
        .sort(function (a, b) {
          return b.idEvento - a.idEvento;
        });
      //#endregion

      $.each(data, function (index, item) {
        colorEvento = item.Data[0].colorEvento;
      });

      let isBetween_min_max = day >= minDate && day <= maxDate;

      let extraDot = null;
      if (isBetween_min_max && dataInDate.length > 0) {
        extraDot = (
          <div
            style={{
              height: "5px",
              width: "5px",
              borderRadius: "100%",
              background: colorEvento,
              position: "absolute",
              top: 2,
              right: 2,
            }}
          />
        );
      }

      return (
        <Fragment>
          <div
            id={id}
            className="test w-100"
            onMouseEnter={(e) => this.fecha_onMouseEnter(e, id, data)}
            onMouseLeave={(e) => this.fecha_onMouseLeave(e, id)}
          >
            {extraDot}
            <span>{format(day, "d")}</span>
          </div>
          <Tooltip
            deferRendering={false}
            elementAttr={{ id: "tooltip_" + id }}
            target={"#" + id}
            position="bottom"
            closeOnOutsideClick={false}
          >
            <div>
              {data.map((item, i) => (
                <div key={i} className="d-flex flex-row align-items-center">
                  <div
                    style={{
                      "--circleColor": item.Data[0].colorEvento,
                      "--circleSize": "8px",
                    }}
                    className="dxcalendar-date-circle"
                  />
                  <div className="pr-3" />
                  <div>
                    {item.Data.length +
                      " " +
                      (item.Data.length == 1
                        ? item.Data[0].denoEventoSingular
                        : item.Data[0].denoEventoPlural)}
                  </div>
                </div>
              ))}
            </div>
          </Tooltip>
        </Fragment>
      );
    }
  }

  fecha_onMouseEnter(e, id, data) {
    if (data.length > 0)
      $("#tooltip_" + id)
        .dxTooltip("instance")
        .show();
  }

  fecha_onMouseLeave(e, id) {
    $("#tooltip_" + id)
      .dxTooltip("instance")
      .hide();
  }
  //#endregion

  //#region EVENTOS

  //#region OTRO WIDGETS
  dxTexBoxButton_date_onClick(e) {
    this.dxDropDownBox_dateRangePicker.open();
  }
  dxButton_aceptar_onClick(e, _this) {
    this.dateRangePicker_onDateSelected(this.state.fechaSel);
  }
  dxButtonGroup_buttonRender(data) {
    return <div style={{ padding: "8px" }}>{data.denominacion}</div>;
  }

  dxButtonGroup_onItemClick(e) {
    this.setState({
      idTipoCalendarioSel: e.itemData.idTipoCalendario,
      calendarioMensual_zoomLevel: "year",
    });
  }

  dxListEstados_onOptionChanged(e) {
    if (e.name === "selectedItemKeys") {
      this.setState({
        idTipoCalendarioSel: e.value[0],
        calendarioMensual_zoomLevel: "year",
      });
    }
  }
  //#endregion DROPDOWN
  dxDropDownBox_dateRangePicker_fieldRender(value) {
    let { resolucion, fechaSel } = this.props;

    return (
      <TextArea
        autoResizeEnabled={true}
        height={
          resolucion == "xs" && fechaSel && fechaSel.idTipoCalendario == 2
            ? 55
            : 34
        }
        defaultValue={value}
        readOnly={true}
      />
    );
  }

  dateRangePicker_onDateSelected(fechaSel) {
    let { maxDate, minDate } = this.props;
    this.setState({
      fechaSel: fechaSel,
      isVisible_dxPopup_dxDateBox: false,
    });

    if (fechaSel.startDate < minDate) fechaSel.startDate = minDate;
    if (fechaSel.endDate > maxDate) fechaSel.endDate = maxDate;

    this.props.onDateSelected(fechaSel);
  }

  render_dxDropdownBox(e, isVisible_ListSelectEstado, isUnicoEstado) {
    let { idTipoCalendarioSel, fechaSel, calendarioMensual_zoomLevel } =
      this.state;
    let { idioma, resolucion, minDate, maxDate, daysWithMark } = this.props;
    return (
      <ResponsiveBox
        elementAttr={this.dxResponsiveBox_PopupDatePicker_elementAttr}
      >
        <Row ratio={1} screen="sm md lg xl" />
        <Col ratio={1} screen="md lg xl" />
        {isVisible_ListSelectEstado && <Col ratio={2} screen="md lg xl" />}

        <Col ratio={1} screen="sm" />
        {isVisible_ListSelectEstado && <Col ratio={2} screen="sm" />}

        <Col ratio={1} screen="xs" />
        {!isUnicoEstado && <Row ratio={0} baseSize={50} screen="xs" />}
        <Row ratio={1} screen="xs" />

        {isVisible_ListSelectEstado && (
          <Item>
            <Location row={0} col={0} screen="sm md lg xl"></Location>
            {!isUnicoEstado && (
              <Location row={0} col={0} screen="xs"></Location>
            )}
            <ResponsiveBox>
              <Row ratio={1} />
              <Col ratio={1} />

              <Item>
                <Location row={0} col={0}></Location>
                <Box
                  direction="col"
                  align="space-around"
                  crossAlign="stretch"
                  width="100%"
                  height="100%"
                  elementAttr={this.dxBox_tiposCalendario_elementAttr}
                >
                  {!isUnicoEstado && (
                    <ItemBox baseSize={40} visible={resolucion !== "xs"}>
                      <div className="font-size">
                        {getTrad("tiposCalendario")}
                      </div>
                    </ItemBox>
                  )}
                  <ItemBox ratio={1}>
                    {!isUnicoEstado && (
                      <div className="he-auto">
                        {resolucion === "xs" ? (
                          <ButtonGroup
                            height="100%"
                            width="100%"
                            items={this.enum_tipoCalendario}
                            buttonRender={this.dxButtonGroup_buttonRender}
                            keyExpr="idTipoCalendario"
                            selectedItemKeys={[idTipoCalendarioSel]}
                            onItemClick={this.dxButtonGroup_onItemClick}
                          />
                        ) : (
                          <List
                            dataSource={this.enum_tipoCalendario}
                            height="100%"
                            keyExpr="idTipoCalendario"
                            displayExpr="denominacion"
                            selectionMode="single"
                            selectedItemKeys={[idTipoCalendarioSel]}
                            onOptionChanged={this.dxListEstados_onOptionChanged}
                          />
                        )}
                      </div>
                    )}
                    {idTipoCalendarioSel === 2 && resolucion !== "xs" ? (
                      <div
                        className={"he-auto " + (!isUnicoEstado ? "pl-5" : "")}
                      >
                        <DefinedRange
                          onChange={this.onChange_dateRange}
                          renderStaticRangeLabel={this.renderStaticRangeLabel}
                          staticRanges={this.staticRanges}
                          ranges={[fechaSel]}
                          rangeColors={["#F5F5F5"]}
                          inputRanges={[]}
                        />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </ItemBox>
                </Box>
              </Item>
            </ResponsiveBox>
          </Item>
        )}
        <Item>
          <Location
            row={0}
            col={isVisible_ListSelectEstado ? 1 : 0}
            screen="sm md lg xl"
          ></Location>
          <Location row={isUnicoEstado ? 0 : 1} col={0} screen="xs"></Location>
          <div className="header item he-100 d-flex align-items-center justify-content-center">
            <MultiView
              height="100%"
              width="100%"
              deferRendering={false}
              selectedIndex={
                idTipoCalendarioSel != null ? idTipoCalendarioSel : 0
              }
              focusStateEnabled={false}
              loop={false}
              swipeEnabled={false}
              animationEnabled={true}
            >
              <MultiViewItem>
                <div className="d-flex justify-content-center align-items-center he-100">
                  <Calendar
                    height="90%"
                    width={isVisible_ListSelectEstado ? "85%" : "100%"}
                    maxZoomLevel="year"
                    minZoomLevel="decade"
                    zoomLevel={calendarioMensual_zoomLevel}
                    min={minDate}
                    max={maxDate}
                    value={
                      fechaSel != null && fechaSel.idTipoCalendario === 0
                        ? fechaSel.startDate
                          ? fechaSel.startDate
                          : maxDate
                        : null
                    }
                    onValueChanged={
                      this.dxCalendar_monthSelector_onValueChanged
                    }
                    onOptionChanged={
                      this.dxCalendar_monthSelector_onOptionChanged
                    }
                    cellRender={this.dxCalendar_monthSelector_cellRender}
                  />
                </div>
              </MultiViewItem>
              <MultiViewItem>
                <div className="d-flex justify-content-center align-items-center he-100">
                  <Calendar
                    height="90%"
                    width={isVisible_ListSelectEstado ? "85%" : "100%"}
                    maxZoomLevel="decade"
                    minZoomLevel="decade"
                    min={minDate}
                    max={maxDate}
                    value={
                      fechaSel.idTipoCalendario === 1
                        ? fechaSel.startDate
                        : null
                    }
                    onValueChanged={this.dxCalendar_yearSelector_onValueChanged}
                  />
                </div>
              </MultiViewItem>
              <MultiViewItem>
                <Box
                  direction="col"
                  align="space-around"
                  crossAlign="stretch"
                  width="100%"
                  height="100%"
                >
                  <ItemBox ratio={1}>
                    <DateRange
                      resolucion={resolucion}
                      calendarFocus="forwards"
                      dateDisplayFormat="dd/MM/yyyy"
                      editableDateInputs={true}
                      startDatePlaceholder={getTrad("inicio")}
                      endDatePlaceholder={getTrad("fin")}
                      fixedHeight={true}
                      months={
                        resolucion !== "xs" && resolucion !== "sm" ? 2 : 1
                      }
                      color="#EDB637"
                      minDate={minDate}
                      maxDate={maxDate}
                      preventSnapRefocus={true}
                      direction={
                        resolucion !== "xs" ? "horizontal" : "vertical"
                      }
                      shownDate={maxDate}
                      showMonthAndYearPickers={true}
                      showMonthArrow={true}
                      showDateDisplay={true}
                      locale={rdrLocales[idioma.codigo]}
                      staticRanges={this.staticRanges}
                      rangeColors={["#EDB637"]}
                      ranges={[fechaSel]}
                      inputValue={this.state.dateRange_inputValue}
                      dayContentRenderer={this.customDayContent}
                      onChange={this.onChange_dateRange}
                    />
                  </ItemBox>
                  <ItemBox baseSize={40}>
                    <Toolbar>
                      <ItemToolbar
                        location="after"
                        widget="dxButton"
                        options={this.dxButton_aceptar}
                      />
                    </Toolbar>
                  </ItemBox>
                </Box>
              </MultiViewItem>
            </MultiView>
          </div>
        </Item>
      </ResponsiveBox>
    );
  }

  dxDropDownBox_onContentReady(e) {
    $(e.element).find("input").css({
      cursor: "pointer",
      "caret-color": "transparent",
    });

    $(e.element)
      .find(".dx-texteditor-buttons-container")
      .css("cursor", "pointer");
    $(e.element)
      .find(".dx-button.dx-button-normal")
      .css("pointer-events", "none");
    $(e.element)
      .find(".dx-button.dx-button-normal > .dx-button-content")
      .css("padding-top", 6);
  }

  onOptionChanged_dxDropDownBox(e) {
    if (e.name === "hoveredElement") {
      let dxTextBox_button = $(e.element).find(".dx-button.dx-button-normal");
      let isHoverIn = e.previousValue == null;
      if (isHoverIn) dxTextBox_button.addClass("dx-state-hover");
      else dxTextBox_button.removeClass("dx-state-hover");
    } else if (e.name === "opened") {
      let { fechaSel } = this.props;
      if (e.value) {
        this.setState({
          isVisible_dxPopup_dxDateBox: e.value,
          calendarioMensual_zoomLevel: "year",
          fechaSel: fechaSel,
          idTipoCalendarioSel: fechaSel.idTipoCalendario,
          dateRange_inputValue: null,
        });
      } else {
        this.setState({
          isVisible_dxPopup_dxDateBox: e.value,
          dateRange_inputValue: this.props.fechaSel,
        });
      }
    }
  }
  onChange_dateRange(e) {
    let { maxDate } = this.props;
    let fecha = e.selection ? e.selection : e.range1;

    fecha.idTipoCalendario = 2;

    //No se pueden selccionar fechas mayores a maxDate
    if (fecha.startDate > maxDate && !isSameDay(fecha.startDate, maxDate))
      return false;

    if (fecha.startDate <= maxDate && fecha.endDate > maxDate) {
      fecha.endDate = maxDate;
    }

    this.setState({
      fechaSel: fecha,
    });
  }
  //#region

  //#region CALENDAR
  dxCalendar_monthSelector_onOptionChanged(e) {
    if (e.name === "zoomLevel") {
      this.setState({ calendarioMensual_zoomLevel: e.value });
    }
  }

  dxCalendar_monthSelector_onValueChanged(e) {
    let fechaSel = {
      idTipoCalendario: 0,
      startDate: startOfMonth(e.value),
      endDate: endOfMonth(e.value),
    };

    this.dateRangePicker_onDateSelected(fechaSel);
  }

  dxCalendar_yearSelector_onValueChanged(e) {
    let fechaSel = {
      idTipoCalendario: 1,
      startDate: startOfYear(e.value),
      endDate: endOfYear(e.value),
    };

    this.dateRangePicker_onDateSelected(fechaSel);
  }

  dxCalendar_monthSelector_cellRender(e) {
    let { idioma, resolucion } = this.props;
    if (e.view === "decade") return e.date.getFullYear();
    else if (e.view === "year")
      return (
        <span>
          {capitalize(
            e.date.toLocaleString(idioma.codigo, {
              month: resolucion === "xs" ? "short" : "long",
            }),
          )}
        </span>
      );
  }
  //#endregion
  //#endregion
  //#endregion

  componentDidUpdate(prevProps, prevState) {
    let { minDate, maxDate, fechaSel, defaultType } = this.props;

    if (
      minDate &&
      maxDate &&
      ((fechaSel == null && fechaSel !== prevProps.fechaSel) ||
        (minDate != null ? minDate.toDateString() : null) !==
          (prevProps.minDate != null
            ? prevProps.minDate.toDateString()
            : null) ||
        (maxDate != null ? maxDate.toDateString() : null) !==
          (prevProps.maxDate != null ? prevProps.maxDate.toDateString() : null))
    ) {
      let endDate, startDate;

      if (defaultType == "year") {
        startDate = startOfYear(maxDate);
        endDate =
          endOfYear(maxDate).getTime() > maxDate.getTime()
            ? maxDate
            : endOfYear(maxDate);
      } else {
        // Month u otro
        startDate = startOfMonth(maxDate);
        endDate =
          endOfMonth(maxDate).getTime() > maxDate.getTime()
            ? maxDate
            : endOfMonth(maxDate);
      }

      if (startDate > new Date() && new Date() >= minDate && fechaSel == null) {
        startDate =
          defaultType == "year"
            ? startOfYear(new Date())
            : startOfMonth(new Date());
        endDate =
          defaultType == "year"
            ? endOfYear(new Date())
            : endOfMonth(new Date());
      }

      let fechaSel_init = {
        key: "selection",
        idTipoCalendario:
          defaultType == "month" ? 0 : defaultType == "year" ? 1 : 2,
        fechaSel: maxDate ? endDate : new Date(),
        startDate: maxDate ? startDate : new Date(),
        endDate: maxDate ? endDate : new Date(),
      };

      this.props.onDateSelected(fechaSel_init);
    } else if (!minDate || !maxDate) {
      // no tiene datos
      this.props.onDateSelected(null);
    }
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
  idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(DateRangePicker);
