import { loadPanelConstants } from "../constants";

export const loadPanelActions = {
  show,
  hide,
};

function show(shading = false) {
  return (dispatch) => {
    dispatch(success(shading));
  };
  function success(shading) {
    return { type: loadPanelConstants.SHOW_LOADPANEL_SUCCESS, shading };
  }
}

function hide() {
  return (dispatch) => {
    dispatch(success());
  };
  function success(empty = {}) {
    return { type: loadPanelConstants.HIDE_LOADPANEL_SUCCESS, empty };
  }
}
