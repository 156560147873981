import React, { Fragment } from "react";
import $ from "jquery";

import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";
import DataSource from "devextreme/data/data_source";
import { connectionConstants } from "constants";

import { authHeader, errorHandler, formatDate_noTime_parameter, formatNumber, getTrad } from "helpers";
import DataGrid, {
    Column,
    Editing,
    Export,
    Format,
    GroupItem,
    HeaderFilter,
    Lookup,
    MasterDetail,
    Paging,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";
import MasterDetail_Partidas from "./components/MasterDetail_Partidas";
import CurrencyCell from "./components/CurrencyCell";
import PopupAjustes from "./components/PopupAjustes";
import { Box, Button, Switch } from "devextreme-react";
import { Item } from "devextreme-react/box";
import Loader from "react-loaders";
import notify from "devextreme/ui/notify";
import { displayExpr_CodigoDenominacion, getPorcentajeDesviacion } from "../../shared/functions";
import DesviacionCell from "./components/DesviacionCell";
import { ControlPresupuestarioCxt } from "../../contexts/context";
import HeaderCell from "./components/HeaderCell";

class DataGridControl extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tblAdmCuentaContable: null,
            disableCierreButton: false,

            rowIndex: null,
            rowData: null,
            dxPopup_visible: false,
        };

        this.dxDataGrid_REF = React.createRef();
    }

    get dxDataGrid() {
        return this.dxDataGrid_REF.current?.instance;
    }

    // #region Lifecycle

    componentDidMount() {
        this.datasource_tblAdmCuentaContable.load().then((tblAdmCuentaContable) => {
            this.setState({ tblAdmCuentaContable });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.moneda?.codigo !== prevProps.moneda?.codigo) {
            this.dxDataGrid?.repaintRows();
        }
        if (
            this.props.fecha !== prevProps.fecha ||
            JSON.stringify(this.props.centros) !== JSON.stringify(prevProps.centros)
        ) {
            this.setState({ rowData: null });
        }
        if (this.props.agruparCentros !== prevProps.agruparCentros) {
            if (this.props.agruparCentros) {
                this.dxDataGrid?.columnOption("idCentroElem", "groupIndex", 0);
            } else {
                this.dxDataGrid?.clearGrouping();
            }
        }
    }

    // #endregion

    // #region Stores

    datasource_tblAdmCuentaContable = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaContable",
            key: "idAdmCuentaContable",
            keyType: "Int32",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => (request.headers = { ...authHeader() }),
            version: 4,
        }),
        filter: [["codigo", "startsWith", "6"], "or", ["codigo", "startsWith", "7"]],
    });

    context_CierreCentros = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/ControlPresupuestario/",
        entities: {
            CerrarCentros: {},
        },
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => this.context_CierreCentros_beforeSend(request),
    });

    context_CierreCentros_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { empresa, moneda, fecha, centros, original_data, original_data_SAP } = this.props;

        request.params.idEmpresaPolarier = empresa.idEmpresaPolarier;
        request.params.fecha = formatDate_noTime_parameter(fecha);
        (request.params.idMoneda = moneda.idMoneda),
            (request.payload = {
                datosCierre: original_data_SAP.length > 0 ? original_data_SAP : original_data,
                idsCentroCoste: centros.filter((x) => x.idAdmCentroCoste != null).map((x) => x.idAdmCentroCoste),
                idsElementoPEP: centros.filter((x) => x.idAdmElementoPEP != null).map((x) => x.idAdmElementoPEP),
            });
    };

    // #endregion

    currencySummaryColumn = {
        summaryType: "sum",
        displayFormat: "{0}",
        showInColumn: true,
        alignByColumn: true,
    };
    gap = { gap: 10 };

    render() {
        const { dataSource, centros, vista } = this.props;
        const { tblAdmCuentaContable, disableCierreButton } = this.state;
        const { dxPopup_visible: PopupAjustes_visible, rowIndex, rowData } = this.state;

        const isCierreDisabled = centros.length === 0 || vista === 2 || disableCierreButton;
        const hasCerrados = centros.some((x) => x.tblCierrePresupuestario.length > 0);

        return tblAdmCuentaContable == null ? null : (
            <Box direction="col" className="he-100">
                <Item baseSize={50}>
                    <Box direction="row" className="p-2 justify-content-between he-100" style={this.gap}>
                        <Item baseSize={"auto"}>
                            <Box className="he-100" style={this.gap}>
                                <Item baseSize={"auto"}>
                                    <Button text={getTrad("exportar")} icon="exportxlsx" onClick={this.exportarXlsx} />
                                </Item>
                                <Item baseSize={"auto"}>
                                    <ControlPresupuestarioCxt.Consumer>
                                        {({ agruparCentros, setAgruparCentros }) => (
                                            <Switch
                                                value={agruparCentros}
                                                width={200}
                                                switchedOnText={getTrad("agrupacionActiva")}
                                                switchedOffText={getTrad("agrupacionInactiva")}
                                                onValueChanged={(e) => setAgruparCentros(e.value)}
                                            />
                                        )}
                                    </ControlPresupuestarioCxt.Consumer>
                                </Item>
                                <Item baseSize={"auto"}>
                                    <Button
                                        disabled={centros.length === 0}
                                        text={getTrad("importar")}
                                        icon="add"
                                        onClick={this.incluirCuentaContable}
                                    />
                                </Item>
                                {this.props.isLoadingSAP && (
                                    <Item baseSize={"auto"}>
                                        <div
                                            className="p-3 position-relative he-100 d-flex flex-row align-items-center text-info border border-info"
                                            style={{ borderRadius: 4 }}
                                        >
                                            <div style={{ paddingRight: 20, zoom: 0.5 }}>
                                                {/* <LottieIcon icon={"loading_startup"} height={"36px"} width={"36px"} /> */}
                                                <Loader type="line-scale" />
                                            </div>
                                            {getTrad("buscandoDatosSAP")}
                                        </div>
                                    </Item>
                                )}
                            </Box>
                        </Item>
                        <Item baseSize={"auto"}>
                            <Box style={this.gap}>
                                <Item baseSize={"auto"}>
                                    <Button icon={"refresh"} onClick={this.refreshData} />
                                </Item>
                                <Item baseSize={"auto"}>
                                    <Button
                                        text={hasCerrados ? getTrad("sincronizarSAP") : getTrad("cerrarCentrosSel")}
                                        stylingMode={hasCerrados ? "contained" : "outlined"}
                                        type={hasCerrados ? "default" : "normal"}
                                        icon={hasCerrados ? "refresh" : " icon_lockOutline"}
                                        disabled={isCierreDisabled}
                                        onClick={this.cerrarCentros}
                                    />
                                </Item>
                            </Box>
                        </Item>
                    </Box>
                </Item>
                <Item ratio={1}>
                    <ControlPresupuestarioCxt.Consumer>
                        {({ tblCentroCoste_ElementoPEP }) => {
                            return (
                                <DataGrid
                                    ref={this.dxDataGrid_REF}
                                    id="dxDataGrid_ControlPresupuestario"
                                    className="he-100 card border-radius"
                                    dataSource={dataSource}
                                    remoteOperations={false}
                                    hoverStateEnabled={true}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    onToolbarPreparing={this.dxDataGrid_onToolbarPreparing}
                                    onCellPrepared={this.dxDataGrid_onCellPrepared}
                                    onRowPrepared={this.dxDataGrid_onRowPrepared}
                                    onCellClick={this.dxDataGrid_onCellClick}
                                >
                                    <Paging enabled={false} />
                                    <Editing
                                        mode={"cell"}
                                        allowAdding={false}
                                        allowUpdating={false}
                                        allowDeleting={false}
                                    />
                                    <Export enabled={true} fileName={getTrad("controlPresupuestario")} />

                                    <HeaderFilter visible={true} allowSearch={true} />
                                    <MasterDetail enabled={true} component={MasterDetail_Partidas} />

                                    <Column
                                        dataField={"idCentroElem"}
                                        caption={" "}
                                        visible={false}
                                        groupCellComponent={this.groupCellComponent}
                                    >
                                        <Lookup
                                            dataSource={tblCentroCoste_ElementoPEP}
                                            displayExpr={displayExpr_CodigoDenominacion}
                                            valueExpr={"idCentroElem"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"idAdmCuentaContable"}
                                        caption={getTrad("cuentaContable")}
                                        cssClass={"position-relative"}
                                        allowEditing={false}
                                        minWidth={120}
                                        sortingMethod={this.dxDataGrid_sortingMethod_denominacion}
                                        headerCellComponent={HeaderCell}
                                    >
                                        <Lookup
                                            dataSource={tblAdmCuentaContable}
                                            displayExpr={"denominacion"}
                                            valueExpr={"idAdmCuentaContable"}
                                        />
                                    </Column>
                                    <Column
                                        name={"codigoCuentaContable"}
                                        dataField={"idAdmCuentaContable"}
                                        caption={getTrad("codigo")}
                                        width={130}
                                        alignment={"center"}
                                        allowEditing={false}
                                        sortIndex={0}
                                        sortOrder={"asc"}
                                        sortingMethod={this.dxDataGrid_sortingMethod_codigo}
                                    >
                                        <Lookup
                                            dataSource={tblAdmCuentaContable}
                                            displayExpr={"codigo"}
                                            valueExpr={"idAdmCuentaContable"}
                                        />
                                    </Column>
                                    <Column
                                        dataField={"valorPresupuestado"}
                                        caption={getTrad("presupuestado")}
                                        dataType={"number"}
                                        alignment={"center"}
                                        width={200}
                                        allowEditing={false}
                                        cssClass={"position-relative"}
                                        cellComponent={CurrencyCell}
                                        headerCellComponent={HeaderCell}
                                        allowHeaderFiltering={false}
                                    />
                                    <Column
                                        dataField={"valorReal"}
                                        caption={getTrad("real")}
                                        dataType={"number"}
                                        alignment={"center"}
                                        width={200}
                                        allowEditing={false}
                                        cssClass={"position-relative"}
                                        headerCellComponent={HeaderCell}
                                        cellComponent={CurrencyCell}
                                        allowHeaderFiltering={false}
                                    />
                                    <Column
                                        dataField={"valorAjuste"}
                                        caption={"Real ajustado"}
                                        dataType={"number"}
                                        alignment={"center"}
                                        cssClass={"columnOverflow position-relative"}
                                        calculateCellValue={this.calculateCellValue_valorAjuste}
                                        width={200}
                                        allowEditing={false}
                                        headerCellComponent={HeaderCell}
                                        cellComponent={CurrencyCell}
                                        allowHeaderFiltering={false}
                                    />
                                    <Column
                                        dataField={"desviacion"}
                                        caption={getTrad("desviacion")}
                                        dataType={"number"}
                                        alignment={"center"}
                                        calculateCellValue={this.calculateCellValue_desviacion}
                                        cellComponent={DesviacionCell}
                                        width={300}
                                        allowEditing={false}
                                        allowHeaderFiltering={false}
                                    />

                                    <Summary>
                                        <TotalItem column={"valorPresupuestado"} {...this.currencySummaryColumn} />
                                        <GroupItem column={"valorPresupuestado"} {...this.currencySummaryColumn} />
                                        <TotalItem column={"valorReal"} {...this.currencySummaryColumn} />
                                        <GroupItem column={"valorReal"} {...this.currencySummaryColumn} />
                                        <TotalItem column={"valorAjuste"} {...this.currencySummaryColumn} />
                                        <GroupItem column={"valorAjuste"} {...this.currencySummaryColumn} />
                                        <TotalItem column={"desviacion"} {...this.currencySummaryColumn} />
                                        <GroupItem column={"desviacion"} {...this.currencySummaryColumn} />
                                    </Summary>
                                </DataGrid>
                            );
                        }}
                    </ControlPresupuestarioCxt.Consumer>
                    <ControlPresupuestarioCxt.Consumer>
                        {({ fecha, moneda, intercambiarDivisa, centros, tasaDivisa }) => (
                            <PopupAjustes
                                visible={PopupAjustes_visible}
                                rowIndex={rowIndex}
                                rowData={rowData}
                                tblAdmCuentaContable={tblAdmCuentaContable}
                                onHiding={this.PopupAjustes_onHiding}
                                onSaving={this.PopupAjustes_unSaving}
                                fecha={fecha}
                                moneda={moneda}
                                tasaDivisa={tasaDivisa}
                                intercambiarDivisa={intercambiarDivisa}
                                centros={centros}
                                dataSource={dataSource}
                            />
                        )}
                    </ControlPresupuestarioCxt.Consumer>
                </Item>
            </Box>
        );
    }

    // #region Toolbar

    exportarXlsx = () => {
        this.dxDataGrid?.exportToExcel(false);
    };

    incluirCuentaContable = (e) => {
        this.setState({ dxPopup_visible: true, rowIndex: null, rowData: null });
    };

    refreshData = (e) => {
        e.component.option("disabled", true);
        this.props.datasource_ControlPresupuestario_load().then(() => {
            e.component.option("disabled", false);
        });
    };

    cerrarCentros = (e) => {
        this.setState({ disableCierreButton: true });
        this.context_CierreCentros
            .invoke("CerrarCentros", {}, "POST")
            .done(() => {
                this.props.reloadCentros();
                this.props.datasource_ControlPresupuestario_load().then(() => {
                    this.setState({ disableCierreButton: false });
                });
                notify({
                    message: "Centros cerrados correctamente",
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            })
            .catch((error) => {
                this.setState({ disableCierreButton: false });
                notify({
                    message: "Error al cerrar centros",
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            });
    };

    // #endregion

    // #region DataGrid

    dxDataGrid_onToolbarPreparing = (e) => {
        e.toolbarOptions.visible = false;
    };

    dxDataGrid_onCellPrepared = (e) => {
        const { rowType, cellElement, column } = e;
        if (rowType === "detail") {
            cellElement?.addClass("p-0");
            // cellElement?.css("padding-left", "30px");
        }

        if (
            (column.dataField === "valorPresupuestado" ||
                column.dataField === "valorReal" ||
                column.dataField === "valorAjuste") &&
            (rowType === "group" || rowType === "totalFooter")
        ) {
            const value =
                column.dataField === "valorPresupuestado"
                    ? rowType === "group"
                        ? e.totalItem.summaryCells[4][0].value
                        : (e.component.getTotalSummaryValue("valorPresupuestado") ?? 0)?.toFixed(2) * 1
                    : column.dataField === "valorReal"
                    ? rowType === "group"
                        ? e.totalItem.summaryCells[5][0].value
                        : (e.component.getTotalSummaryValue("valorReal") ?? 0)?.toFixed(2) * 1
                    : column.dataField === "valorAjuste"
                    ? rowType === "group"
                        ? e.totalItem.summaryCells[6][0].value
                        : (e.component.getTotalSummaryValue("valorAjuste") ?? 0)?.toFixed(2) * 1
                    : 0;

            cellElement.empty().append(
                $("<div />")
                    .addClass("dx-datagrid-summary-item d-flex mr-3")
                    .append(
                        $("<div />")
                            .addClass("flex-fill w-100 text-center")
                            .text(formatNumber(value, 2, "currency", this.props.moneda.codigo ?? "EUR"))
                    )
            );
        }

        if (column.dataField === "desviacion" && (rowType === "group" || rowType === "totalFooter")) {
            const totalPresupuestado =
                rowType === "group"
                    ? e.totalItem.summaryCells[4][0].value
                    : (e.component.getTotalSummaryValue("valorPresupuestado") ?? 0)?.toFixed(2) * 1;
            const totalAjustado =
                rowType === "group"
                    ? e.totalItem.summaryCells[6][0].value
                    : (e.component.getTotalSummaryValue("valorAjuste") ?? 0)?.toFixed(2) * 1;

            var desviacion =
                rowType === "group"
                    ? e.totalItem.summaryCells[7][0].value
                    : (e.component.getTotalSummaryValue("desviacion") ?? 0)?.toFixed(2) * 1;

            let valorPorcentaje = (((totalAjustado - totalPresupuestado) / totalPresupuestado) * 100) / 100;
            let porcentaje = getPorcentajeDesviacion(Number.isNaN(valorPorcentaje) ? 0 : valorPorcentaje);

            const { setResumenes, resumenes } = this.props;
            let newResumenes = {
                presupuestado: parseFloat(e.component.getTotalSummaryValue("valorPresupuestado").toFixed(2)),
                real: parseFloat(e.component.getTotalSummaryValue("valorReal").toFixed(2)),
                realAjustado: parseFloat(e.component.getTotalSummaryValue("valorAjuste").toFixed(2)),
                desviacion: parseFloat(e.component.getTotalSummaryValue("desviacion").toFixed(2)),
            };

            if (JSON.stringify(resumenes) !== JSON.stringify(newResumenes)) {
                setResumenes(newResumenes);
            }

            cellElement.empty().append(
                $("<div />")
                    .addClass("dx-datagrid-summary-item d-flex mr-3")
                    .append(
                        $("<div />")
                            .addClass("flex-fill w-100 text-right")
                            .text(formatNumber(desviacion, 2, "currency", this.props.moneda.codigo ?? "EUR"))
                    )
                    .append($("<div />").addClass("text-right").css("flex-basis", "150px").text(porcentaje))
            );
        }
    };

    dxDataGrid_onRowPrepared = (e) => {
        const { rowType, rowElement } = e;
        if (rowType === "data") {
            rowElement.addClass("pointer");
        }
    };

    dxDataGrid_onCellClick = (e) => {
        const { rowType, column, component, row, data: rowData } = e;
        if (
            rowType === "data" &&
            column.dataField === "valorAjuste" &&
            column.dataField === "valorAjuste" &&
            (!rowData.idCentroElem || (rowData.idCentroElem != null && rowData.isLocalItem))
        ) {
            this.setState({ dxPopup_visible: true, rowIndex: row.rowIndex, rowData: row.data });
        } else if (rowType === "data" && column.dataField !== "detailExpand") {
            if (row.isExpanded) {
                component.collapseRow(row.key);
            } else {
                component.expandRow(row.key);
            }
        }
    };

    dxDataGrid_sortingMethod_denominacion = (a, b) => {
        const { tblAdmCuentaContable } = this.state;
        var x = tblAdmCuentaContable.find((x) => x.idAdmCuentaContable === a);
        var y = tblAdmCuentaContable.find((x) => x.idAdmCuentaContable === b);
        if (x?.denominacion < y?.denominacion) return -1;
        if (x?.denominacion > y?.denominacion) return 1;
        return 0;
    };

    dxDataGrid_sortingMethod_codigo = (a, b) => {
        const { tblAdmCuentaContable } = this.state;
        var x = tblAdmCuentaContable.find((x) => x.idAdmCuentaContable === a);
        var y = tblAdmCuentaContable.find((x) => x.idAdmCuentaContable === b);
        if (x?.codigo < y?.codigo) return -1;
        if (x?.codigo > y?.codigo) return 1;
        return 0;
    };

    dxDataGrid_groupCellRender_3dig = (e) => {
        const { items, collapsedItems } = e.data;
        return (
            <span>
                ({e.text}) {(collapsedItems ?? items)[0].GLAccountLongName_1}
            </span>
        );
    };

    groupCellComponent = (e) => {
        return <span>{e.data.text}</span>;
    };

    calculateCellValue_valorAjuste = (data) => {
        const { valorReal, valorAjuste } = data;
        return valorReal + valorAjuste;
    };

    calculateCellValue_desviacion = (data) => {
        const { valorPresupuestado = 0, valorReal, valorAjuste } = data;
        return valorReal + valorAjuste - valorPresupuestado;
    };

    // #endregion

    // #region Popup

    PopupAjustes_onHiding = () => {
        this.setState({ dxPopup_visible: false });
    };

    PopupAjustes_unSaving = () => {
        this.props.datasource_ControlPresupuestario_load(true).then(() => {
            this.dxDataGrid?.refresh();
            this.setState({ dxPopup_visible: false });
        });
    };

    // #endregion
}

export default DataGridControl;
