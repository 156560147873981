import React from "react";

import { connectionConstants } from "../../../../../constants";

import {
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  get_isMovil,
  datetimeToDuration,
  patchRequestHandler,
  dateTime_hourMinute,
} from "../../../../../helpers";

import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import Form, { Item } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { DropDownOptions, TagBox } from "devextreme-react/tag-box";
import { TextBox, DateBox, Switch } from "devextreme-react";
import { Tooltip } from "devextreme-react/tooltip";

import Validator, {
  RequiredRule,
  StringLengthRule,
} from "devextreme-react/validator";
import validationEngine from "devextreme/ui/validation_engine";
import DropDownBox_filtroCategoriasInternas from "components/DropDownBox_filtroCategoriasInternas";

export default class PopupTurnoEdit extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dxForm_formDataTurno: {},
      dxButton_guardar_isDisabled: false,
    };

    this.dxForm_nuevoTurno_REF = React.createRef();

    this.onShowing_popup_turno = this.onShowing_popup_turno.bind(this);
    this.onShown_popup_turno = this.onShown_popup_turno.bind(this);
    this.dxFormEditor_onContentReady =
      this.dxFormEditor_onContentReady.bind(this);
    this.onHiding_popup_turno = this.onHiding_popup_turno.bind(this);
    this.onHidden_popup_turno = this.onHidden_popup_turno.bind(this);
    this.dxFormItem_render = this.dxFormItem_render.bind(this);
    this.dxFormEditor_onValueChanged =
      this.dxFormEditor_onValueChanged.bind(this);
    this.dxButton_popupCategoria_eliminar_onClick =
      this.dxButton_popupCategoria_eliminar_onClick.bind(this);
    this.dxButton_popupCategoria_guardar_onClick =
      this.dxButton_popupCategoria_guardar_onClick.bind(this);
    this.dxButton_popupCategoria_cancelar_onClick =
      this.dxButton_popupCategoria_cancelar_onClick.bind(this);
    this.DropDownBox_filtroCategoriasInternas_onValueChanged =
      this.DropDownBox_filtroCategoriasInternas_onValueChanged.bind(this);

    this.getTrad = this.getTrad.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxForm_nuevoTurno() {
    return this.dxForm_nuevoTurno_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblTurno = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
      key: "idTurno",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblTurno_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblTurno_beforeSend(request) {
    request.headers = { ...authHeader() };
  }
  //#endregion

  render() {
    let { dxForm_formDataTurno, dxButton_guardar_isDisabled } = this.state;
    let {
      isVisible_popup_showTurno,
      editTurno_itemSel,
      isSubTurno,
      resolucion,
    } = this.props;

    let height_add = !isSubTurno ? 290 : 500; // turnoPadre | subTurno
    let height_upd = dxForm_formDataTurno.idTurnoPadre == null ? 320 : 550; // turnoPadre | subTurno
    let height_popup = editTurno_itemSel == null ? height_add : height_upd;

    return (
      <Popup
        title={
          editTurno_itemSel == null
            ? !isSubTurno
              ? "Nuevo turno"
              : "Nuevo subturno"
            : dxForm_formDataTurno.idTurnoPadre == null
              ? "Modificar turno"
              : "Modificar subturno"
        }
        showTitle={true}
        maxWidth={650}
        height={get_isMovil(resolucion) ? "80%" : height_popup}
        width={get_isMovil(resolucion) ? "80%" : "60%"}
        deferRendering={false}
        visible={isVisible_popup_showTurno}
        closeOnOutsideClick={false}
        onShowing={this.onShowing_popup_turno}
        onShown={this.onShown_popup_turno}
        onHiding={this.onHiding_popup_turno}
        onHidden={this.onHidden_popup_turno}
      >
        <Form
          ref={this.dxForm_nuevoTurno_REF}
          formData={dxForm_formDataTurno}
          deferRendering={false}
          labelLocation="top"
          colCount={2}
          height="100%"
          width="100%"
        >
          <Item
            dataField="abreviatura"
            label={this.formLabel_abreviatura}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="denominacion"
            label={this.formLabel_denominacion}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="horaEntrada"
            label={this.formLabel_horaEntrada}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="horaSalida"
            label={this.formLabel_horaSalida}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="descanso"
            label={this.formLabel_descanso}
            render={this.dxFormItem_render}
          />
          {(dxForm_formDataTurno.idTurnoPadre != null || isSubTurno) && (
            <Item colSpan={1} itemType="empty" />
          )}
          {(dxForm_formDataTurno.idTurnoPadre != null || isSubTurno) && (
            <Item
              dataField="tblCategoriaInternaNTurno"
              colSpan={2}
              label={this.formLabel_tblCategoriaInternaNTurno}
              render={this.dxFormItem_render}
            />
          )}
          {editTurno_itemSel != null &&
            dxForm_formDataTurno.idTurnoPadre != null && (
              <Item colSpan={1} itemType="empty" />
            )}
          {editTurno_itemSel != null && (
            <Item
              dataField="activo"
              label={this.formLabel_activo}
              render={this.dxFormItem_render}
            />
          )}
        </Form>
        {editTurno_itemSel != null && (
          <ToolbarItem_popup
            toolbar="bottom"
            location="before"
            widget="dxButton"
          >
            <div id="dxButton_popupCategoria_eliminar">
              <Button
                text={this.getTrad("eliminar")}
                disabled={editTurno_itemSel.hasPersonaAsignada}
                type="danger"
                onClick={this.dxButton_popupCategoria_eliminar_onClick}
              />
              <Tooltip
                target="#dxButton_popupCategoria_eliminar"
                position="bottom"
                showEvent="dxhoverstart"
                hideEvent="dxhoverend"
              >
                <div>{this.getTrad("aviso_eliminarTurno_personaAsociada")}</div>
              </Tooltip>
            </div>
          </ToolbarItem_popup>
        )}
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("guardar")}
            disabled={dxButton_guardar_isDisabled}
            type="success"
            onClick={this.dxButton_popupCategoria_guardar_onClick}
          />
        </ToolbarItem_popup>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("cancelar")}
            type="normal"
            onClick={this.dxButton_popupCategoria_cancelar_onClick}
          />
        </ToolbarItem_popup>
      </Popup>
    );
  }

  formLabel_abreviatura = { text: this.getTrad("abreviatura"), visible: false };
  formLabel_denominacion = {
    text: this.getTrad("denominacion"),
    visible: false,
  };
  formLabel_activo = { text: this.getTrad("activo"), visible: false };
  formLabel_horaEntrada = { text: this.getTrad("horaEntrada"), visible: false };
  formLabel_horaSalida = { text: this.getTrad("horaSalida"), visible: false };
  formLabel_descanso = { text: this.getTrad("descanso"), visible: false };
  formLabel_tblCategoriaInternaNTurno = { text: "Categorías", visible: false };

  dxFormItem_render(data) {
    if (data == null) return null;
    let { categoriaSalarial_items, isSubTurno, resolucion, idPais, idioma } =
      this.props;
    let { dxForm_formDataTurno } = this.state;

    let formLabel = this["formLabel_" + data.dataField.replace(".", "_")];
    let isRequired =
      data.dataField == "abreviatura" ||
      data.dataField == "denominacion" ||
      data.dataField == "horaEntrada" ||
      data.dataField == "horaSalida" ||
      data.dataField == "descanso";

    let value = !dxForm_formDataTurno
      ? null
      : dxForm_formDataTurno[data.editorOptions.name.replace("_", ".")];
    value = !value ? null : value;

    return (
      <div className="d-flex flex-row align-items-center">
        {data.dataField != "activo" && (
          <div
            className="flex-row d-flex"
            style={{
              flex: data.dataField == "tblCategoriaInternaNTurno" ? 2 : 6,
              paddingRight: 0,
            }}
          >
            <div className="pr-3 pe-none ">
              {formLabel.text}
              <span className="dx-field-item-required-mark">
                {isRequired && " *"}
              </span>
            </div>
          </div>
        )}
        <div
          style={{
            flex: 7,
            height: data.dataField == "tblCategoriaInternaNTurno" ? "100%" : 38,
          }}
          className="ml-auto d-flex justify-content-end "
        >
          {data.dataField == "tblCategoriaInternaNTurno" &&
            (dxForm_formDataTurno.idTurnoPadre != null || isSubTurno) && (
              <DropDownBox_filtroCategoriasInternas
                value={value}
                idPaisSel={idPais}
                isOficina_checked={false}
                hasFiltroConvenio={true}
                idioma={idioma}
                noDataText_sinPais="Seleccione una lavandería"
                multiSeleccion={true}
                onValueChanged={
                  this.DropDownBox_filtroCategoriasInternas_onValueChanged
                }
              />
            )}
          {data.dataField == "activo" && (
            <Switch
              name={data.dataField}
              value={value}
              width={120}
              elementAttr={this.dxSwitch_activo_elementAttr}
              switchedOnText={this.getTrad("activo")}
              switchedOffText={this.getTrad("noActivo")}
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            />
          )}
          {(data.dataField == "horaEntrada" ||
            data.dataField == "horaSalida" ||
            data.dataField == "descanso") && (
            <DateBox
              name={data.dataField}
              type="time"
              useMaskBehavior={true}
              showDropDownButton={false}
              inputAttr={this.dxDateBox_inputAttr}
              displayFormat="HH:mm"
              stylingMode="underlined"
              value={value}
              onContentReady={this.dxFormEditor_onContentReady}
              onFocusIn={this.dxFormEditor_DateBox_onFocusIn}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              <Validator validationGroup={"validationTurno"}>
                {isRequired && <RequiredRule />}
              </Validator>
            </DateBox>
          )}
          {(data.dataField == "abreviatura" ||
            data.dataField == "denominacion") && (
            <TextBox
              name={data.dataField}
              width="100%"
              stylingMode="underlined"
              className={
                "pr-2 textUpperCase " +
                (data.dataField == "abreviatura"
                  ? "dxForm_abreviatura_editor"
                  : "")
              }
              value={value}
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              <Validator validationGroup={"validationTurno"}>
                {isRequired && <RequiredRule />}
                {data.dataField == "abreviatura" && (
                  <StringLengthRule
                    max={2}
                    message="Debe de tener 2 caracteres"
                  />
                )}
              </Validator>
            </TextBox>
          )}
        </div>
      </div>
    );
  }

  dxDateBox_inputAttr = {
    class: "pr-2",
  };

  dxSwitch_activo_elementAttr = { class: "no-disabled mt-3" };

  dxFormEditor_onContentReady(e) {
    $(e.element).find("input").css({
      "text-align": "end",
      "font-weight": 500,
    });

    setTimeout(() => {
      $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
        "font-weight": 500,
        "text-align": "start",
      });
    }, 0);
  }

  dxFormEditor_DateBox_onFocusIn(e) {
    e.element.find(".dx-texteditor-input").off("dxmousewheel");
  }

  dxFormEditor_onValueChanged(e) {
    let dxForm_formDataTurno = { ...this.state.dxForm_formDataTurno };
    let dataField = e.component.option("name");

    if (dataField == "denominacion" || dataField == "abreviatura")
      e.value = e.value.toUpperCase();

    dxForm_formDataTurno[dataField] = e.value;

    this.setState({
      dxForm_formDataTurno: dxForm_formDataTurno,
    });
  }

  dxButton_popupCategoria_eliminar_onClick(e) {
    let _this = this;
    let { editTurno_itemSel } = this.props;
    let { dxForm_formDataTurno } = this.state;
    let isTurnoPadre_conSubTurnos =
      editTurno_itemSel.tblSubTurno != null &&
      editTurno_itemSel.tblSubTurno.length > 0;
    let msg = isTurnoPadre_conSubTurnos
      ? "aviso_eliminarTurno"
      : "preg_RegistroEliminarCorto";

    dxMensajePregunta(_this.getTrad(msg), [
      [
        _this.getTrad("aceptar"),
        function () {
          _this.datasource_tblTurno
            .store()
            .remove(editTurno_itemSel.id)
            .done(function () {
              _this.props.onHiding(
                true,
                dxForm_formDataTurno.idTurnoPadre == null
                  ? -1
                  : dxForm_formDataTurno.idTurnoPadre,
              ); //-1 para seleccionar primer registro
            });
        },
        "danger",
      ],
      [_this.getTrad("cancelar"), function () {}],
    ]);
  }

  dxButton_popupCategoria_guardar_onClick(e) {
    let isValid = validationEngine.validateGroup("validationTurno").isValid;
    if (isValid) {
      this.setState({ dxButton_guardar_isDisabled: true });
      let _this = this;
      let { idLavanderia, editTurno_itemSel, isSubTurno, idTurnoPadreSel } =
        this.props;
      let dxForm_formDataTurno = { ...this.state.dxForm_formDataTurno };

      delete dxForm_formDataTurno.dxSwitch_Activo;
      delete dxForm_formDataTurno.tagBox_categorias;
      delete dxForm_formDataTurno.hasPersonaAsignada;

      let dateTimeFields = ["horaEntrada", "horaSalida", "descanso"];
      if (editTurno_itemSel == null) {
        $.each(dateTimeFields, function (index, item) {
          if (dxForm_formDataTurno[item] != null)
            dxForm_formDataTurno[item] = datetimeToDuration(
              dxForm_formDataTurno[item],
            );
        });

        dxForm_formDataTurno.idLavanderia = idLavanderia;
        if (isSubTurno) dxForm_formDataTurno.idTurnoPadre = idTurnoPadreSel;

        let objCategoriaNTurno = [];
        $.each(
          dxForm_formDataTurno.tblCategoriaInternaNTurno,
          function (index, item) {
            objCategoriaNTurno.push({
              idCategoriaInterna: item,
            });
          },
        );

        dxForm_formDataTurno.tblCategoriaInternaNTurno = objCategoriaNTurno;
        this.datasource_tblTurno
          .store()
          .insert(dxForm_formDataTurno)
          .done(function (id) {
            _this.props.onHiding(
              true,
              idTurnoPadreSel != null && isSubTurno ? idTurnoPadreSel : id,
            );
          });
      } else {
        $.each(dateTimeFields, function (index, item) {
          if (dxForm_formDataTurno[item] != null)
            dxForm_formDataTurno[item] = dateTime_hourMinute(
              dxForm_formDataTurno[item],
            );
        });

        delete dxForm_formDataTurno.id;
        delete dxForm_formDataTurno.descripcion;
        delete dxForm_formDataTurno.tblSubTurno;
        delete dxForm_formDataTurno.id;
        this.datasource_tblTurno
          .store()
          .update(
            editTurno_itemSel.id,
            patchRequestHandler(dxForm_formDataTurno),
          )
          .done(function () {
            _this.props.onHiding(
              true,
              idTurnoPadreSel != null ? idTurnoPadreSel : editTurno_itemSel.id,
            );
          });
      }
    }
  }

  dxButton_popupCategoria_cancelar_onClick(e) {
    this.props.onHiding();
  }

  DropDownBox_filtroCategoriasInternas_onValueChanged(e) {
    let dxForm_formDataTurno = { ...this.state.dxForm_formDataTurno };
    let dataField = "tblCategoriaInternaNTurno";

    dxForm_formDataTurno[dataField] = e;

    this.setState({
      dxForm_formDataTurno: dxForm_formDataTurno,
    });
  }

  onShowing_popup_turno(e) {
    let { editTurno_itemSel } = this.props;

    if (editTurno_itemSel != null) {
      this.setState({
        dxForm_formDataTurno: editTurno_itemSel,
        dxButton_guardar_isDisabled: false,
      });
    } else {
      this.setState({
        dxButton_guardar_isDisabled: false,
      });
    }
  }

  onShown_popup_turno(e) {
    $(".dxForm_abreviatura_editor").dxTextBox("instance").focus();
  }

  onHiding_popup_turno(e) {
    this.props.onHiding();
  }

  onHidden_popup_turno(e) {
    this.setState({ dxForm_formDataTurno: {} });
    this.dxForm_nuevoTurno.repaint();

    this.props.onHidden();
  }
}
