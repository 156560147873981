import { DataGrid } from "devextreme-react";
import { Column, FilterRow, LoadPanel, Lookup, Paging } from "devextreme-react/data-grid";
import { getTrad } from "helpers";
import React from "react";
import { ControlPresupuestarioCxt } from "../../../contexts/context";
import { displayExpr_CodigoDenominacion } from "../../../shared/functions";
import { DataSourceSAPContext } from "./../components/CurrencyCell";
import LoaderDatos from "../../LoaderDatos";
import CurrencyFormatterCell from "../../CurrencyFormatterCell";

class MasterDetail_Partidas extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    minHeight = { minHeight: 170, maxHeight: 300 };

    render() {
        const { data } = this.props;
        const { data: rowData } = data;

        const dataSource =
            this.context.dataSource.find((x) => x.idAdmCuentaContable === rowData.idAdmCuentaContable)?.tblPartida ??
            rowData.tblPartida;

        return (
            <div style={this.minHeight} className="position-relative shadow-sm m-5">
                <div style={{ width: "100%" }}>
                    <LoaderDatos visible={this.context.isLoading} />
                </div>
                <ControlPresupuestarioCxt.Consumer>
                    {({ tblCentroCoste_ElementoPEP }) => (
                        <DataGrid
                            dataSource={dataSource}
                            style={this.minHeight}
                            showColumnLines={true}
                            showRowLines={true}
                        >
                            <FilterRow visible={true} />

                            <Paging enabled={false} />
                            <LoadPanel enabled={false} />

                            <Column
                                dataField="asientoDocumento"
                                caption={"Asiento contable"}
                                dataType={"string"}
                                width={125}
                                alignment={"center"}
                            />
                            <Column
                                dataField="fechaDocumento"
                                caption={getTrad("fecha")}
                                dataType={"date"}
                                format={"dd/MM/yyyy"}
                                width={100}
                                alignment={"center"}
                            />
                            <Column
                                dataField="idCentroElem"
                                caption={`${getTrad("centroCoste")} / ${getTrad("elementoPep")}`}
                                width={300}
                            >
                                <Lookup
                                    dataSource={tblCentroCoste_ElementoPEP}
                                    valueExpr={"idCentroElem"}
                                    displayExpr={displayExpr_CodigoDenominacion}
                                />
                            </Column>
                            <Column
                                dataField="comentarioDocumento"
                                caption={getTrad("descripcion") + " / " + getTrad("comentario")}
                                dataType={"string"}
                            />
                            <Column
                                dataField="valor"
                                caption={getTrad("importe")}
                                dataType={"number"}
                                alignment={"center"}
                                width={300}
                                cellComponent={CurrencyFormatterCell}
                            />
                        </DataGrid>
                    )}
                </ControlPresupuestarioCxt.Consumer>
            </div>
        );
    }
}

MasterDetail_Partidas.contextType = DataSourceSAPContext;

export default MasterDetail_Partidas;
