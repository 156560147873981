import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class PageTitle extends Component {
  render() {
    let {
      heading,
      preHeading,
      postHeading,
      resolucion,
      visible,
      postHeadingBegin,
      postHeadingEnd,
    } = this.props;

    if (visible === false) return <div />;

    return (
      <div className={"app-page-title"}>
        <div className="page-title-wrapper">
          <div className="d-flex page-title-heading pr-2">
            {preHeading ? (
              <div
                className={
                  "mr-3 " +
                  (resolucion === "xs" || resolucion === "sm"
                    ? "position-absolute"
                    : "")
                }
              >
                {preHeading}
              </div>
            ) : (
              ""
            )}
            <div className="flex d-flex justify-content-between he-100 w-100">
              <div className="m-auto font-size-lg">{heading}</div>
            </div>
          </div>
          {postHeading ? (
            <div className="page-title-actions">
              <div className="d-flex flex-row justify-content-center">
                {postHeading}
              </div>
            </div>
          ) : (
            <Fragment>
              {postHeadingBegin && (
                <div
                  className="page-title-actions"
                  style={{ margin: "0px 0px 0px 30px" }}
                >
                  <div className="d-flex flex-row justify-content-center">
                    {postHeadingBegin}
                  </div>
                </div>
              )}
              {postHeadingEnd && (
                <div className="page-title-actions">
                  <div className="d-flex flex-row justify-content-center">
                    {postHeadingEnd}
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resolucion: state.Global.resolucion,
});

export default connect(mapStateToProps)(PageTitle);
