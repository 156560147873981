import React, { useState, useEffect, Fragment } from "react";

import { DateBox, SelectBox, TextBox } from "devextreme-react";

const SelectorDiasLibres = ({
  formato,
  readOnly,
  disabled,
  persona,
  stylingMode,
  isRequired,
  onContentReady,
  isLabelsVisible,
  onValidChange,
  onValueChanged,
  customValidation,
  isLlamamiento = false,
}) => {
  const [tblKey, setTblKey] = useState(
    isLlamamiento
      ? "tblDiasLibresPersonal_Llamamiento"
      : "tblDiasLibresPersonal",
  );

  useEffect(() => {
    setTblKey(
      isLlamamiento
        ? "tblDiasLibresPersonal_Llamamiento"
        : "tblDiasLibresPersonal",
    );
  }, [isLlamamiento]);

  const ValidarDias = (dias) => {
    if (dias.length > 0) {
      let valid = true;
      let exists = [];
      dias.forEach((x) => {
        if (
          (!x.idDiaMes ||
            x.idDiaMes === null ||
            x.idDiaMes === 0 ||
            exists.includes(x.idDiaMes)) &&
          (!x.idDiaSemana ||
            x.idDiaSemana === null ||
            x.idDiaSemana === 0 ||
            exists.includes(x.idDiaSemana))
        ) {
          valid = false;
        } else {
          x.idDiaMes && exists.push(x.idDiaMes);
          x.idDiaSemana && exists.push(x.idDiaSemana);
        }
      });
      setDatesAreValid(valid ? exists.length === formato.numDiasLibres : valid);
    }
  };

  const GenerarDias = (data) => {
    let arrayDias = [];

    if (data[0] && data[0] !== null) {
      arrayDias = [...data];
      arrayDias = arrayDias.splice(0, formato.numDiasLibres);

      arrayDias.map((x) => (x.idPersonaNavigation = undefined));
      let numDia = data.length + 1;
      while (arrayDias.length < formato.numDiasLibres) {
        arrayDias.push({ idPersona: persona.idPersona, numDia: numDia++ });
      }
    } else {
      let numDia = 1;
      while (arrayDias.length < formato.numDiasLibres) {
        arrayDias.push({ idPersona: persona.idPersona, numDia: numDia++ });
      }
    }
    ValidarDias(arrayDias);
    return arrayDias;
  };

  const [datesAreValid, setDatesAreValid] = useState(false);
  const [dias, setDias] = useState(() =>
    GenerarDias(
      persona[tblKey] && persona[tblKey].length === formato.numDiasLibres
        ? persona[tblKey]
        : [],
    ),
  );
  const [diaChanged, setDiaChanged] = useState({});

  useEffect(() => {
    if (persona && persona[tblKey]) {
      setDias(
        GenerarDias(
          persona[tblKey]?.length === formato.numDiasLibres
            ? persona[tblKey]
            : [],
        ),
      );
    } else {
      setDias(GenerarDias([]));
    }
  }, [formato, persona[tblKey]]);

  const minDate = new Date().setDate(1);
  const maxDate = new Date().setDate(30);

  const semana = [
    { disabled: false, id: 1, denominacion: "Lunes" },
    { disabled: false, id: 2, denominacion: "Martes" },
    { disabled: false, id: 3, denominacion: "Miércoles" },
    { disabled: false, id: 4, denominacion: "Jueves" },
    { disabled: false, id: 5, denominacion: "Viernes" },
    { disabled: false, id: 6, denominacion: "Sábado" },
    { disabled: false, id: 7, denominacion: "Domingo" },
  ];

  useEffect(() => {
    let c = [...dias];
    c.map((x) => {
      if (x.numDia === diaChanged.numDia) {
        x.idDiaMes = diaChanged.idDiaMes;
        x.idDiaSemana = diaChanged.idDiaSemana;
      }
      return x;
    });
    setDias(c);

    semana.map((x) => {
      x.disabled = false;
      c.forEach((c) => {
        if (x.id === c.idDiaSemana) {
          x.disabled = true;
        }
      });
      return x;
    });

    ValidarDias(c);
    if (onValueChanged) onValueChanged(dias);
  }, [diaChanged]);

  useEffect(() => {
    if (dias.length > 0) {
      if (onValidChange) onValidChange(datesAreValid);
    } else {
      if (onValidChange) onValidChange(false);
    }
  }, [datesAreValid]);

  let numDia = 1;

  return (
    <div id="SelectorDiasLibres" className={"d-flex flex-column"}>
      {dias.length === 0 ? (
        <div>
          <TextBox
            placeholder="Formato no definido"
            stylingMode={stylingMode}
            readOnly
          >
            {customValidation != null && customValidation}
          </TextBox>
        </div>
      ) : (
        dias.map((dia) => (
          <Fragment key={"diaFormatosDiasLibres_" + numDia}>
            <div
              className={
                "d-flex he-100 justify-content-end " +
                (!datesAreValid ? "dxDateBoxError" : "")
              }
              style={{ minHeight: 36 }}
            >
              {isLabelsVisible && (
                <div
                  className="text-nowrap d-flex align-items-center "
                  style={{ flex: "2 1 0%" }}
                >
                  Día {numDia++}:{" "}
                </div>
              )}
              <div className="position-relative" style={{ flex: "8 1 0%" }}>
                {formato.isMensual ? (
                  <>
                    {!customValidation &&
                    (!dia.idDiaMes ||
                      dia.idDiaMes === null ||
                      dia.idDiaMes === 0) ? (
                      <div
                        style={{
                          right: 30,
                          paddingLeft: 0,
                          marginRight: 2,
                          zIndex: 1,
                        }}
                        className="validationError_icon"
                      >
                        !
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <DateBox
                      value={
                        dia.idDiaMes &&
                        new Date(
                          `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${dia.idDiaMes < 10 ? "0" + dia.idDiaMes : dia.idDiaMes}`,
                        )
                      }
                      displayFormat={"dd"}
                      min={minDate}
                      max={maxDate}
                      showClearButton={true}
                      disabledDates={dias.map((x) => {
                        if (x.idDiaMes)
                          return new Date(
                            `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10 ? "0" + (new Date().getMonth() + 1) : new Date().getMonth() + 1}-${x.idDiaMes < 10 ? "0" + x.idDiaMes : x.idDiaMes}`,
                          );
                        else return null;
                      })}
                      onContentReady={
                        onContentReady ? (e) => onContentReady(e) : null
                      }
                      openOnFieldClick={true}
                      onValueChanged={({ value }) => {
                        setDiaChanged({
                          ...dia,
                          idDiaMes: value ? value.getDate() : null,
                        });
                      }}
                      readOnly={readOnly}
                      disabled={disabled}
                    >
                      {customValidation != null && customValidation}
                    </DateBox>
                  </>
                ) : (
                  <>
                    {!customValidation &&
                    (!dia.idDiaSemana ||
                      dia.idDiaSemana === null ||
                      dia.idDiaSemana === 0 ||
                      dias.filter((x) => x.idDiaSemana === dia.idDiaSemana)
                        .length > 1) ? (
                      <div
                        style={{
                          right: 5,
                          paddingLeft: 0,
                          marginRight: 2,
                          zIndex: 1,
                        }}
                        className="validationError_icon"
                      >
                        !
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <SelectBox
                      value={dia.idDiaSemana}
                      dataSource={semana.filter((x) => !x.disabled)}
                      valueExpr={"id"}
                      displayExpr={"denominacion"}
                      stylingMode={stylingMode}
                      onContentReady={
                        onContentReady ? (e) => onContentReady(e) : null
                      }
                      onValueChanged={({ value }) => {
                        setDiaChanged({ ...dia, idDiaSemana: value });
                      }}
                      readOnly={readOnly}
                      disabled={disabled}
                    >
                      {customValidation != null && customValidation}
                    </SelectBox>
                  </>
                )}
              </div>
            </div>
            {formato.idFormatoDiasLibres === 3 && numDia === 4 && (
              <div style={{ margin: 5, borderTop: "1px solid #ddd" }}></div>
            )}
          </Fragment>
        ))
      )}
    </div>
  );
};

export default SelectorDiasLibres;
