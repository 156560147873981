import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { datasource_tblAdmCentroCoste, datasource_tblAdmElementoPEP } from '../model';
import DxForm, { Item, GroupItem, CustomRule } from "devextreme-react/form";
import { custom_displayExpr } from '../functions';
import { getTrad } from 'helpers';

const CentroCosteElementoPepComponent = forwardRef(({formData, readOnly = false, userCantEdit = true}, ref) => {

    useImperativeHandle(ref, () => ({
        checkIsValid,
        updateData: (...props) => formRef.current.instance.updateData(...props)
    }));
    
    const checkIsValid = () => {
        return formRef?.current?.instance?.validate()
    };

    const formRef = useRef();

    const getText = (trad) => ({ text: getTrad(trad) });

    const colCount = 4;
    const multiplier = 3;
    const labels = {
        idAdmCentroCoste: getText("centroCoste"),
        idAdmElementoPEP: getText("elementoPep"),
    }

    const editorOptions = {
        idAdmCentroCoste: {
            dataSource: datasource_tblAdmCentroCoste,
            valueExpr: "idAdmCentroCoste",
            displayExpr: custom_displayExpr,
            searchEnabled: true,
            dropDownOptions: { minWidth: 350 },
        },
        idAdmElementoPEP: {
            dataSource: datasource_tblAdmElementoPEP,
            valueExpr: "idAdmElementoPEP",
            displayExpr: custom_displayExpr,
            searchEnabled: true,
            dropDownOptions: { minWidth: 350 },
        },
    }
    
    const onFieldDataChanged = (e) => {
        if(e.dataField === "idAdmElementoPEP"){
            e.component.option("formData").idAdmCentroCoste = null;
            e.component.repaint();
        } else if(e.dataField === "idAdmCentroCoste"){
            e.component.option("formData").idAdmElementoPEP = null;
            e.component.repaint();
        }
    }

    const validationCallback_CCEP = (e, a) => {
        if (
            !(
                formRef.current.instance.option("formData").idAdmCentroCoste == null &&
                formRef.current.instance.option("formData").idAdmElementoPEP == null
            )
        ) {
            return true;
        }
        return false;
    }

    return (
        <DxForm
            ref={formRef}
            formData={formData}
            onFieldDataChanged={onFieldDataChanged}
            colCount={colCount * multiplier}
            height={"100%"}
            width={"100%"}
            labelLocation={"top"}
            readOnly={(formData.isCerrado || readOnly) && userCantEdit}
        >
            <Item
                    dataField={"idAdmCentroCoste"}
                    label={labels.idAdmCentroCoste}
                    editorType={"dxSelectBox"}
                    editorOptions={editorOptions.idAdmCentroCoste}
                    colSpan={2 * multiplier}
                >
                    <CustomRule validationCallback={validationCallback_CCEP}/>
                </Item>
                <Item
                    dataField={"idAdmElementoPEP"}
                    label={labels.idAdmElementoPEP}
                    editorType={"dxSelectBox"}
                    editorOptions={editorOptions.idAdmElementoPEP}
                    colSpan={2 * multiplier}
                >
                    <CustomRule validationCallback={validationCallback_CCEP}/>
                </Item>
        </DxForm>
    );
});

export default CentroCosteElementoPepComponent;