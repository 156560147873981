import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
  formatDate,
  leadingZero,
  email_Pattern,
  formatDateTime_parameter,
  formatTimeOffset,
} from "../../../helpers";

import {
  svg_edit,
  svg_tick,
  svg_tick_circle,
  svg_remove_circle,
  allIcons as svg_iconos,
} from "../../../styles/svg_iconos";
import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import ArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";
import { userService } from "../../../services";
import query from "devextreme/data/query";

//Css
import "./Css.scss";

export default function cargaJs(COMPONENT, USER, IDIOMA) {
  var deferred = $.Deferred();

  var userSel = null,
    estatusUsuario = null,
    dataSource_lavanderias_todas,
    idUsuarioImportar = null,
    usuarios_rowEditing = null,
    isImportandoUsuario = false,
    userEliminar = null,
    categoriaSel_importar = null,
    idAplicacionSel = 1,
    idAplicacionSel_formInicio = 1,
    categorias_transversales = {
      categoria_idsServTecnico: [4, 13, 19],
      categoria_idsAdministrativo: [5, 9, 10, 11],
    },
    tiposTrabajo_transversales = {
      tipoTrabajo_idsServTecnico: [4],
      tipoTrabajo_idsAdministrativo: [3],
    },
    cargaCompaEnti = null,
    tblCategoriaInterna_items = [],
    tblCategoriaInterna_items_filtrados = [],
    idsUsuarios_accesoLoginChangeUser = [178, 179, 180, 931, 2397]; //TI + Laura

  //#region Datasource
  var dataSource_aplicaciones = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + `tblAplicacion`,
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    filter: ["idAplicacion ne 2"],
    select: ["idAplicacion, icon, tblTraduccion, color"],
    sort: "orden",
    expand: ["tblTraduccion"],
  });
  dataSource_lavanderias_todas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var lavsUserLog = JSON.parse(localStorage.getItem("lavanderias"));

      $.each(data, function (index, item) {
        item.visible = false;
        if (lavsUserLog.find((x) => x.idLavanderia === item.idLavanderia))
          item.visible = true;
      });
      return data;
    },
    sort: ["denominacion"],
    expand: ["tblEntidad($select=idEntidad,idCompañia,denominacion)"],
    select: ["idLavanderia", "denominacion"],
  });

  var datasource_usuarios = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "usuarios_spSelectUsuariosVisibles",
      key: "idUsuario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.estatus = estatusUsuario;

        if (request.method !== "get") {
          request.url = request.url.replace(
            "usuarios_spSelectUsuariosVisibles",
            "tblUsuario",
          );

          if (
            $("#dxTabPanel_principal")
              .dxTabPanel("instance")
              .option("selectedIndex") === 0
          ) {
            request.params.isDelta = true;
          }
        }
      },
      version: 4,
    }),
  });

  var datasource_tblCargo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCargo",
      key: "idCargo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var dataSource_formularios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFormulario",
      key: "idFormulario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_formulariosNApartado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblApartado",
      key: "idApartado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
      onLoading: function (loadOptions) {
        var idUsuario =
          idUsuarioImportar !== null ? idUsuarioImportar : userSel.idUsuario;
        loadOptions.expand = [
          "tblFormulario($select=idFormulario,denominacion,visibleEntidad,orden, idAplicacion, idApartado;$orderBy=orden;$expand=tblFormularioNUsuario($filter=idUsuario eq " +
            idUsuario +
            ")),tblApartado1",
        ];
      },
    }),
    select: ["idApartado", "denominacion", "orden", "idApartadoPadre"],
    filter: ["idApartado ne 99"],
    sort: ["orden"],
    postProcess: function (data) {
      userSel.tblFormulario = [];
      data = $.each(data, function (index, item) {
        item.tblFormulario = $.grep(item.tblFormulario, function (form) {
          return (
            userSel.subtipoUsuario === 1 ||
            userSel.subtipoUsuario === 3 ||
            (form.visibleEntidad === true &&
              (userSel.subtipoUsuario === 0 || userSel.subtipoUsuario === 2))
          );
        });
      });

      //#region Insertar informes operacionales en apartado correspondiente.
      let infOp_forms = [];
      data = $.each(data, function (index, item) {
        if (item.idApartadoPadre === 13) {
          //Informes operacionales
          $.each(item.tblFormulario, function (index2, form) {
            infOp_forms.push(form);
          });
        }
        if (item.idApartado === 13)
          //Informes operacionales
          item.tblFormulario = infOp_forms;
      });
      //#endregion

      //#region Eliminar informes operacionales duplicados
      data = $.grep(data, function (item) {
        return item.idApartadoPadre !== 13;
      });
      //#endregion

      data = data.filter(function (item) {
        return (
          item.tblFormulario.length > 0 &&
          ((item.idApartado === 3 &&
            (estatusUsuario === 0 || estatusUsuario === 1)) ||
            item.idApartado !== 3)
        );
      });

      $.each(data, function (index, apartado) {
        if (apartado.idApartado === 1 || apartado.idApartado === 20)
          apartado.visible = false;

        apartado.tblFormulario = apartado.tblFormulario.filter(function (item) {
          return item.idFormulario !== 119 && item.idFormulario !== 122;
        });

        $.each(apartado.tblFormulario, function (index, formulario) {
          var formNUser = formulario.tblFormularioNUsuario;
          formulario.idUsuario = userSel.idUsuario;
          formulario.isEscritura =
            formNUser.length > 0 ? formNUser[0].isEscritura : false;
          formulario.isBorrado =
            formNUser.length > 0 ? formNUser[0].isBorrado : false;
          delete formulario.tblFormularioNUsuario;
        });
      });
      userSel.tblFormulario = data;
      return data;
    },
  });

  var datasource_idiomas = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblIdioma",
      key: "idIdioma",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_moduloNLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "usuarios_spSelectModuloNLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idUsuarioSel =
          idUsuarioImportar !== null
            ? idUsuarioImportar
            : userSel !== null
              ? userSel.idUsuario
              : null;
        request.params.idUsuarioGestor = USER.idUsuario;
      },
      onLoaded: function (data) {
        $.each(data, function (index, item) {
          userSel.tblLavanderia[item.idLavanderia] =
            item.tieneLav === 1 ? true : false;
        });
        $("#dxPivotGrid_formulariosNLavanderia")
          .dxPivotGrid("instance")
          .option("dataSource.store", data);
        return data;
      },
      version: 4,
    }),
  });

  var datasource_datos_compaEnti = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        var dxList_lavanderias = $("#dxList_lavanderias").dxList("instance");

        var idLavanderia = -1;
        if (dxList_lavanderias) {
          idLavanderia = $("#dxList_lavanderias")
            .dxList("instance")
            .option("selectedItemKeys")[0];
        }

        request.params.idLavanderia = !idLavanderia ? -1 : idLavanderia;
        request.params.todas = true;
      },
      version: 4,
      onLoading: function (loadOptions) {
        var dxList_lavanderias = $("#dxList_lavanderias").dxList("instance");
        if (dxList_lavanderias) {
          let idLavSel = dxList_lavanderias.option("selectedItemKeys")[0];
          if (idLavSel)
            loadOptions.expand = [
              "tblEntidad($select = idEntidad, denominacion;$filter=tblLavanderia/any(x:x/idLavanderia eq " +
                idLavSel +
                ");$orderby = denominacion;)",
            ];
        }
      },
    }),
    postProcess: function (data) {
      let filterItems = [];
      $.each(data, function (index, item) {
        if (item.tblEntidad.length > 0) filterItems.push(item);
      });
      return filterItems;
    },
    select: ["idCompañia", "denominacion"],
    sort: ["denominacion"],
  });

  // Entidades que tiene el usuario padre tanto por uss lavanderias como por sus entidades
  var datasource_entidadesNUsuarioPadre = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idUsuario = USER.idUsuario;
      },
      version: 4,
    }),
  });

  var datasource_entidadNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidadNUsuario",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        var idUsuario =
          idUsuarioImportar !== null
            ? idUsuarioImportar
            : userSel !== null && userSel.idUsuario !== null
              ? userSel.idUsuario
              : -1;
        request.params.idUsuario = idUsuario;
      },
      onLoaded: function (data) {
        var entidades = [];

        $.map(data, function (entidad, index) {
          var indexCompa = entidades
            .map(function (e) {
              return e.idCompañia;
            })
            .indexOf(entidad.idCompañia);
          if (indexCompa === -1) {
            entidades.push({
              idCompañia: entidad.idCompañia,
              tblEntidad: [
                {
                  idEntidad: entidad.idEntidad,
                  denominacion: entidad.denominacion,
                },
              ],
            });
          } else {
            $.each(entidades, function (index, item) {
              if (item.idCompañia === entidad.idCompañia)
                item.tblEntidad.push({
                  idEntidad: entidad.idEntidad,
                  denominacion: entidad.denominacion,
                });
            });
          }
        });
        userSel.tblEntidad = entidades;
        return data;
      },
      version: 4,
    }),
  });

  var datasource_tblFormularioNCargo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFormularioNCargo",
      key: "idFormulario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var idCargo = $("#dxForm_datosUser")
          .dxForm("instance")
          .getEditor("idCargo")
          .option("value");

        request.params.idCargo = idCargo !== null ? idCargo : -1;
      },
      version: 4,
    }),
  });

  var datasource_tblTipoUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoUsuario",
      key: "idTipoUsuario",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_tblLocalizacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLocalizacion",
      key: "idLocalizacion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = null;
        request.params.todas = true;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_categoria = new DataSource({
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblCategoria",
        key: "idCategoria",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        onLoading: function (loadOptions) {
          loadOptions.filter =
            "idCategoria in (" +
            $.map(categorias_transversales, function (item) {
              return item;
            }) +
            ")";
        },
        version: 4,
      }),
      select: ["idCategoria", "denominacion"],
    }),
    datasource_tblPersona = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblPersona",
        key: "idPersona",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.todas = true;
        },
        onLoading: function (loadOptions) {
          let idPersona =
            userSel !== null && userSel.idPersona !== null
              ? userSel.idPersona
              : -1;
          loadOptions.filter = ["idPersona eq " + idPersona];
        },
        version: 4,
      }),
      select: ["idPersona", "nombre", "apellidos"],
      map: function (itemData) {
        return {
          idPersona: itemData.idPersona,
          nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
        };
      },
    }),
    datasource_personaNLavanderia = new DataSource({
      paginate: true,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblPersona",
        key: "idPersona",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
          request.params.todas = true;
        },
        onLoading: function (loadOptions) {
          let idPersonaSel = userSel !== null ? userSel.idPersona : null;

          let filtroCategoria_tipoTrabajo,
            filtroPersona_sel =
              idPersonaSel !== null
                ? "idPersona ne " + idPersonaSel
                : "idPersona ne -1";

          if (categoriaSel_importar) {
            filtroCategoria_tipoTrabajo = [
              "idCategoria",
              "=",
              categoriaSel_importar.idCategoria,
            ];
          } else {
            filtroCategoria_tipoTrabajo = [
              [
                "idCategoria in (" +
                  $.map(categorias_transversales, function (item) {
                    return item;
                  }) +
                  ")",
              ],
              "or",
              [
                "idTipoTrabajo in (" +
                  $.map(tiposTrabajo_transversales, function (item) {
                    return item;
                  }) +
                  ")",
              ],
            ];
          }

          if (!loadOptions.filter || loadOptions.filter.length === 0) {
            loadOptions.filter = [
              [filtroCategoria_tipoTrabajo],
              "and",
              [filtroPersona_sel],
            ];
          } else if (loadOptions.filter[0][0] !== "idPersona") {
            loadOptions.filter = [
              [
                "concat(concat(nombre, ' '), apellidos)",
                "contains",
                loadOptions.filter[0][2],
              ],
              "and",
              [filtroCategoria_tipoTrabajo],
              "and",
              [filtroPersona_sel],
            ];
          }
        },
        version: 4,
      }),
      select: ["idPersona", "nombre", "apellidos", "idTipoTrabajo"],
      sort: "nombre",
      expand: ["tblUsuario($count=true;$top=1;$select=idUsuario)"],
      map: function (itemData) {
        if (typeof itemData.tblUsuario !== "undefined") {
          // Al limpiar el campo de búsqueda de dxList_personasNLav el mapeo no incluye tblUsuario
          let idUsuario =
            itemData.tblUsuario.length > 0
              ? itemData.tblUsuario[0].idUsuario
              : null;
          return {
            idPersona: itemData.idPersona,
            nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
            idTipoTrabajo: itemData.idTipoTrabajo,
            visible:
              itemData["tblUsuario@odata.count"] === 0 ||
              (userSel !== null &&
                itemData["tblUsuario@odata.count"] > 0 &&
                idUsuario === userSel.idUsuario),
          };
        }
      },
    });

  var context_usuario = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblUsuario",
    entities: {
      fn_deleteUsuario: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
      request.payload = [{ idUsuario: userEliminar }];
    },
  });

  var datasource_genero = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblGenero",
      key: "idGenero",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idGenero", "idTraduccionNavigation"],
    expand: ["idTraduccionNavigation"],
    map: function (data) {
      return {
        idGenero: data.idGenero,
        denominacion: data.idTraduccionNavigation[IDIOMA.codigo],
      };
    },
  });

  var dataSource_tblCentroTrabajo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
      key: "idCentroTrabajo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      version: 4,
    }),
    sort: ["denominacion"],
  });

  var datasource_tblCentroTrabajoNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
      key: "idCentroTrabajo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        let idUsuario =
          idUsuarioImportar !== null
            ? idUsuarioImportar
            : userSel !== null && userSel.idUsuario !== null
              ? userSel.idUsuario
              : -1;
        if (loadOptions.filter)
          loadOptions.filter = [
            loadOptions.filter,
            "and",
            ["idUsuario/any(x: x/idUsuario eq  " + idUsuario + ")"],
          ];
        else
          loadOptions.filter = [
            "idUsuario/any(x: x/idUsuario eq  " + idUsuario + ")",
          ];
      },
      onLoaded: function (data) {
        var idsCentro = [];
        Object.keys(data).forEach(function (key) {
          var id = data[key]["idCentroTrabajo"];
          idsCentro.push(id);
        });
        userSel.tblCentroTrabajo = idsCentro;
        return data;
      },
      version: 4,
    }),
  });

  var enum_navbar = [
    {
      id: "dxButton_datosUser",
      text: getTrad("datosUsuario"),
      icon: " icon_Persona",
    },
    {
      id: "dxButton_lavanderias",
      text: getTrad("lavanderías"),
      icon: " icon_Almacen",
    },
    {
      id: "dxButton_centrosTrabajo",
      text: getTrad("centrosTrabajo"),
      icon: " icon_office_building",
    },
    {
      id: "dxButton_entidades",
      text: getTrad("entidades"),
      icon: " icon_Entidad",
    },
    {
      id: "dxButton_resumen",
      text: getTrad("resumen"),
      icon: " icon_Cuaderno",
    },
  ];

  var enum_colores = {
    rojo: "#BC2929",
    ambar: "#F4BC46",
    verde: "#5FBA46",
    violeta: "#A004FA",
    gris: "#737373",
  };

  var enum_subtipoUsuario = [
    {
      value: 1,
      text: getTrad("usuarioLavanderia"),
      subText: getTrad("usuarioLavanderia_subTexto"),
    },
    {
      value: 0,
      text: getTrad("usuarioEntidad"),
      subText: getTrad("usuarioEntidad_subTexto"),
    },
    {
      value: 2,
      text: getTrad("usuarioPedidos"),
      subText: getTrad("usuarioPedidos_subTexto"),
    },
    //{
    //    value: 3,
    //    text: getTrad("RRHH"),
    //    subText: "",
    //    visible:false
    //}
  ];

  var enum_subtipoUsuario_dxDataGridUsuarios = [
    {
      value: 1,
      text: getTrad("lavanderia"),
    },
    {
      value: 0,
      text: getTrad("entidad"),
    },
    {
      value: 2,
      text: getTrad("pedidos"),
    },
  ];

  var datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      version: 4,
    }),
    select: ["idCategoriaInterna, idCategoriaConvenio, denominacion"],
    expand: ["idCategoriaConvenioNavigation($select=denominacion, idPais)"],
    postProcess: function (data) {
      let groupedData = query(data)
        .groupBy(function (dataItem) {
          return dataItem.idCategoriaConvenioNavigation.denominacion;
        })
        .sortBy("key")
        .toArray();

      tblCategoriaInterna_items = $.extend(true, [], groupedData);
      return data;
    },
  });

  var datasource_tblCategoriaInternaNUsuario = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        let idUsuario =
          idUsuarioImportar !== null
            ? idUsuarioImportar
            : userSel !== null && userSel.idUsuario !== null
              ? userSel.idUsuario
              : -1;
        loadOptions.filter = [
          "idUsuario/any(x: x/idUsuario eq " + idUsuario + ")",
        ];
      },
      version: 4,
    }),
    select: ["idCategoriaInterna"],
    postProcess: function (data) {
      userSel.tblCategoriaInterna = $.extend(
        true,
        [],
        $.map(data, function (item) {
          return item.idCategoriaInterna;
        }),
      );
      $("#dxForm_datosUser")
        .dxForm("instance")
        .updateData("tblCategoriaInterna", userSel.tblCategoriaInterna);
      return data;
    },
  });

  function reenviarCorreoContraseña() {
    let requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        idUsuario: userSel.idUsuario,
        email: userSel.email,
      }),
    };

    COMPONENT.loadPanel_show(true);
    return fetch(
      connectionConstants.WEB_API_CORE_URL + `emailAltaUsuario`,
      requestOptions,
    ).then((user) => {
      COMPONENT.loadPanel_hide();
      notify({
        message: getTrad("correoConfigContraseña_enviado"),
        type: "success",
        displayTime: "1500",
        closeOnClick: true,
      });
    });
  }
  //#endregion

  $.when(
    datasource_tblCargo.load(),
    dataSource_formularios.load(),
    dataSource_lavanderias_todas.load(),
    datasource_idiomas.load(),
    datasource_tblTipoUsuario.load(),
    datasource_tblLocalizacion.load(),
    datasource_tblCompañia.load(),
    datasource_categoria.load(),
    datasource_personaNLavanderia.load(),
    dataSource_aplicaciones.load(),
    datasource_genero.load(),
    dataSource_tblCentroTrabajo.reload(),
    datasource_tblCategoriaInterna.reload(),
  )
    .then(function () {
      estatusUsuario = $.grep(datasource_tblCargo.items(), function (cargo) {
        if (USER.idCargo === cargo.idCargo) return cargo;
      })[0].estatus;

      $("#Usuarios #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            baseSize: 80,
            visible: false,
            template: function (e, index, item) {
              item.append(
                $("<div id='dxNavbarUsuarios' />")
                  .addClass("fill")
                  .addClass("h4")
                  .dxNavBar({
                    dataSource: enum_navbar,
                    onItemClick: function (e) {
                      var dxForm_datosUser =
                          $("#dxForm_datosUser").dxForm("instance"),
                        selectedItem = e.component.option("selectedItem");

                      var dxTabPanel_principal = $(
                        "#dxTabPanel_principal",
                      ).dxTabPanel("instance");

                      if (selectedItem.id === "dxButton_datosUser") {
                        dxTabPanel_principal.option("selectedIndex", 1);
                      } else {
                        $.when(dxForm_datosUser.validate().complete).then(
                          (r) => {
                            if (dxForm_datosUser.validate().isValid) {
                              switch (selectedItem.id) {
                                case "dxButton_lavanderias":
                                  dxTabPanel_principal.option(
                                    "selectedIndex",
                                    2,
                                  );
                                  break;
                                case "dxButton_centrosTrabajo":
                                  dxTabPanel_principal.option(
                                    "selectedIndex",
                                    3,
                                  );
                                  break;
                                case "dxButton_entidades":
                                  dxTabPanel_principal.option(
                                    "selectedIndex",
                                    4,
                                  );
                                  break;
                                case "dxButton_resumen":
                                  if (check_subtipoUsuario()) {
                                    setResumenForm();
                                    dxTabPanel_principal.option(
                                      "selectedIndex",
                                      5,
                                    );
                                  }
                                  break;
                                default:
                                  break;
                              }
                            } else {
                              e.component.option("selectedIndex", 0);
                            }
                          },
                        );
                      }
                    },
                    selectionMode: "single",
                  }),
              );
            },
          },
          {
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div id='dxTabPanel_principal' />")
                  .addClass("no-header")
                  .dxTabPanel({
                    height: "100%",
                    width: "100%",
                    scrollByContent: true,
                    scrollingEnabled: true,
                    animationEnabled: true,
                    deferRendering: false,
                    swipeEnabled: false,
                    // EVENTOS
                    items: [
                      {
                        template: function () {
                          return $("<div id='dxDataGrid_Usuarios'>").dxDataGrid(
                            {
                              //Datos
                              dataSource: datasource_usuarios,
                              remoteOperations: false,
                              //Propiedades
                              repaintChangesOnly: true,
                              columnsAutoWidth: true,
                              height: "100%",
                              width: "100%",
                              editing: {
                                mode: "cell",
                                allowUpdating: true,
                                refreshMode: "repaint",
                              },
                              keyboardNavigation: {
                                enterKeyAction: "moveFocus",
                                enterKeyDirection: "column",
                                editOnKeyPress: true,
                              },
                              loadPanel: {
                                enabled: false,
                              },
                              headerFilter: {
                                visible: true,
                              },
                              filterRow: {
                                visible: true,
                                applyFilter: "auto",
                              },
                              paging: {
                                pageSize: 50,
                              },
                              pager: {
                                showPageSizeSelector: true,
                                allowedPageSizes: [20, 50, 100],
                                showInfo: true,
                              },
                              columns: [
                                {
                                  caption: " ",
                                  width: 40,
                                  name: "logUsuario",
                                  alignment: "right",
                                  cssClass: "p-0",
                                  allowEditing: false,
                                  cellTemplate: function (
                                    cellElement,
                                    cellInfo,
                                  ) {
                                    let data = cellInfo.data;
                                    if (data.subtipoUsuario == 3) return null; // Usuario SubTipo RRHH
                                    var contenedor = $(
                                      "<div id='dxDataGrid_Usuarios_logUsuario_" +
                                        data.idUsuario +
                                        "' />",
                                    ).addClass(
                                      "container_spanCentrado pointer icon_GestionInterna",
                                    );
                                    contenedor
                                      .css("font-size", "25px")
                                      .css("float", "right")
                                      .css("line-height", "1px")
                                      .css("cursor", "pointer")
                                      .appendTo(contenedor);

                                    cellElement.on("click", function () {
                                      dxMensajePregunta(
                                        "¿Está seguro que desea acceder como <b>" +
                                          data.nombre +
                                          "</b>?",
                                        [
                                          [
                                            getTrad("aceptar"),
                                            function () {
                                              COMPONENT.loadPanel_show(true);
                                              COMPONENT.props.loginChangeUser(
                                                data.idUsuario,
                                                USER.idUsuario,
                                              );
                                            },
                                            "success",
                                          ],
                                          [getTrad("cancelar"), function () {}],
                                        ],
                                      );
                                    });

                                    contenedor.appendTo(cellElement);
                                  },
                                  allowSearch: false,
                                  visible:
                                    idsUsuarios_accesoLoginChangeUser.includes(
                                      USER.idUsuario,
                                    ),
                                },
                                {
                                  caption: " ",
                                  width: 60, // 60
                                  alignment: "center", // center
                                  cssClass: "p-0",
                                  allowEditing: false,
                                  cellTemplate: function (
                                    cellElement,
                                    cellInfo,
                                  ) {
                                    var contenedor = $("<div/>").addClass(
                                      "container_spanCentrado",
                                    );
                                    svg_edit
                                      .clone()
                                      .css("height", "25px")
                                      .css("width", "25px")
                                      .css("cursor", "pointer")
                                      .appendTo(contenedor);

                                    cellElement.on("click", function () {
                                      COMPONENT.loadPanel_show(true);
                                      setTimeout(() => {
                                        userSel = $.extend(
                                          true,
                                          {},
                                          cellInfo.data,
                                        );
                                        loadUserData();
                                        $("#dxTabPanel_principal")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", 1);
                                      }, 0);
                                    });

                                    contenedor.appendTo(cellElement);
                                  },
                                  allowSearch: false,
                                },
                                {
                                  dataField: "nombre",
                                  caption: getTrad("nombreUsuario"),
                                  allowHeaderFiltering: false,
                                  minWidth: 175,
                                  sortOrder: "asc",
                                  allowEditing: false,
                                },
                                {
                                  dataField: "usuario",
                                  caption: getTrad("usuario"),
                                  width: "9%",
                                  allowHeaderFiltering: false,
                                  allowEditing: false,
                                },
                                {
                                  dataField: "email",
                                  caption: getTrad("email"),
                                  width: "15%",
                                  dataType: "string",
                                  allowHeaderFiltering: false,
                                  validationRules: [
                                    {
                                      type: "async",
                                      validationCallback: function (params) {
                                        let originalEmail = $.grep(
                                          datasource_usuarios.items(),
                                          function (item) {
                                            return params.data.idUsuario !==
                                              null
                                              ? item.idUsuario ===
                                                  params.data.idUsuario
                                              : false;
                                          },
                                        );

                                        return $.when(
                                          userService.validateEmailUsuario(
                                            params.value,
                                          ),
                                        ).then(function (email) {
                                          let isEmpty =
                                            params.data.email === "" ||
                                            !params.data.email;

                                          if (params.data.email === null)
                                            return true;

                                          // Requerido
                                          if (isEmpty) {
                                            params.validator.option(
                                              "validationRules[0].message",
                                              getTrad("emailObligatorio"),
                                            );
                                            return false;
                                          }
                                          //Formato Email
                                          if (
                                            !email_Pattern.test(
                                              params.data.email,
                                            )
                                          ) {
                                            params.validator.option(
                                              "validationRules[0].message",
                                              getTrad("emailInvalido"),
                                            );
                                            return false;
                                          }
                                          // Mail duplicado
                                          else {
                                            params.validator.option(
                                              "validationRules[0].message",
                                              getTrad("alert_correoDuplicado"),
                                            );
                                            let isEmail_inDB = email.isValid,
                                              isOriginalEmail =
                                                originalEmail.length > 0
                                                  ? originalEmail[0].email ===
                                                      params.value ||
                                                    originalEmail[0].usuario ===
                                                      params.value
                                                  : false;
                                            return isEmail_inDB &&
                                              !isOriginalEmail
                                              ? false
                                              : true;
                                          }
                                        });
                                      },
                                      message: getTrad("emailObligatorio"),
                                    },
                                  ],
                                },
                                {
                                  dataField: "idTipoUsuario",
                                  caption: getTrad("tipoUsuario"),
                                  width: 110,
                                  alignment: "left",
                                  allowHeaderFiltering: false,
                                  lookup: {
                                    dataSource:
                                      datasource_tblTipoUsuario.items(),
                                    displayExpr: "denominacion",
                                    valueExpr: "idTipoUsuario",
                                  },
                                  allowEditing: false,
                                },
                                {
                                  dataField: "subtipoUsuario",
                                  caption: getTrad("subtipoUsuario"),
                                  width: 140,
                                  alignment: "left",
                                  allowHeaderFiltering: false,
                                  lookup: {
                                    dataSource:
                                      enum_subtipoUsuario_dxDataGridUsuarios,
                                    displayExpr: "text",
                                    valueExpr: "value",
                                  },
                                  allowEditing: false,
                                },
                                {
                                  dataField: "idCargo",
                                  caption: getTrad("cargo"),
                                  width: "14%",
                                  alignment: "left",
                                  allowHeaderFiltering: false,
                                  lookup: {
                                    dataSource: datasource_tblCargo.items(),
                                    displayExpr: "denominacion",
                                    valueExpr: "idCargo",
                                  },
                                  cellTemplate: function (container, options) {
                                    let data = options.data,
                                      result = $(
                                        "<div id='dxDataGrid_Usuarios_idCargo_" +
                                          data.idUsuario +
                                          "' />",
                                      ).text(options.text);

                                    if (data.detallesCargo) {
                                      result.append(
                                        $("<div/>").addClass(
                                          "pointer dx-icon icon_Comment pl-2",
                                        ),
                                      );
                                    }
                                    return result;
                                  },
                                  allowEditing: false,
                                },
                                {
                                  dataField: "idCompañia",
                                  caption: getTrad("compañia"),
                                  width: "12%",
                                  alignment: "left",
                                  lookup: {
                                    dataSource: datasource_tblCompañia.items(),
                                    displayExpr: "denominacion",
                                    valueExpr: "idCompañia",
                                  },
                                  allowHeaderFiltering: false,
                                  allowEditing: false,
                                },
                                {
                                  dataField: "idLocalizacion",
                                  caption: getTrad("localizacion"),
                                  width: "14%",
                                  alignment: "left",
                                  allowHeaderFiltering: false,
                                  lookup: {
                                    dataSource:
                                      datasource_tblLocalizacion.items(),
                                    displayExpr: "denominacion",
                                    valueExpr: "idLocalizacion",
                                  },
                                  allowEditing: false,
                                },
                                {
                                  dataField: "ultimaConexion",
                                  caption: getTrad("ultimaConexion"),
                                  width: 190,
                                  calculateSortValue: function (e) {
                                    let value = e.ultimaConexion;
                                    if (value === null) return -1;
                                    else {
                                      let fechaString = value.split(" "),
                                        offset =
                                          parseInt(
                                            fechaString[2].split(":")[0],
                                          ) * -1,
                                        fecha = new Date(
                                          fechaString[0] + "T" + fechaString[1],
                                        );

                                      fecha.setHours(fecha.getHours() + offset);
                                      return fecha;
                                    }
                                  },
                                  customizeText: function (data) {
                                    if (data.value !== null) {
                                      let valor = data.value.split(" "),
                                        fecha = new Date(
                                          valor[0] + "T" + valor[1],
                                        );

                                      let time =
                                        leadingZero(fecha.getHours()) +
                                        ":" +
                                        leadingZero(fecha.getMinutes());
                                      return (
                                        formatDate(fecha) +
                                        " - " +
                                        time +
                                        (valor[2] !== "+00:00"
                                          ? " (" + valor[2] + ")"
                                          : "")
                                      );
                                    }
                                  },
                                  alignment: "center",
                                  allowHeaderFiltering: false,
                                  allowFiltering: false,
                                  allowEditing: false,
                                },
                                {
                                  dataField: "fechaCreacion",
                                  visible: false,
                                },
                                {
                                  caption: " ",
                                  visible: estatusUsuario === 0,
                                  width: "60px",
                                  alignment: "center",
                                  cssClass: "p-0",
                                  cellTemplate: function (
                                    cellElement,
                                    cellInfo,
                                  ) {
                                    var contenedor = $("<div/>").addClass(
                                      "container_spanCentrado",
                                    );
                                    svg_iconos.svg_remove
                                      .clone()
                                      .css("height", "25px")
                                      .css("width", "25px")
                                      .css("cursor", "pointer")
                                      .appendTo(contenedor);

                                    cellElement.on("click", function () {
                                      if (
                                        cellInfo.data.idUsuario !== null &&
                                        cellInfo.data.idCargo != 12
                                      ) {
                                        dxMensajePregunta(
                                          getTrad("preg_RegistroEliminar"),
                                          [
                                            [
                                              getTrad("aceptar"),
                                              function () {
                                                userEliminar =
                                                  cellInfo.data.idUsuario;
                                                context_usuario
                                                  .invoke(
                                                    "fn_deleteUsuario",
                                                    {},
                                                    "POST",
                                                  )
                                                  .done(function (result) {
                                                    if (result !== -1) {
                                                      $("#dxDataGrid_Usuarios")
                                                        .dxDataGrid("instance")
                                                        .refresh(true)
                                                        .done(function () {
                                                          notify({
                                                            message: getTrad(
                                                              "aviso_C_RegistroEliminado",
                                                            ),
                                                            type: "success",
                                                            displayTime: "1500",
                                                            closeOnClick: true,
                                                          });
                                                        });
                                                    } else {
                                                      notify({
                                                        message: getTrad(
                                                          "aviso_RegistroNoEliminado",
                                                        ),
                                                        type: "error",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                      });
                                                    }
                                                    userEliminar = null;
                                                  });
                                              },
                                              "danger",
                                            ],
                                            [
                                              getTrad("cancelar"),
                                              function () {},
                                            ],
                                          ],
                                        );
                                      }
                                    });

                                    if (cellInfo.data.idCargo != 12)
                                      // Usuarios colaborador no deben borrarse
                                      contenedor.appendTo(cellElement);
                                  },
                                },
                              ],
                              //EVENTOS
                              onKeyDown: function (e) {
                                if (e.event.key === "Tab")
                                  e.component.focus(
                                    e.component.getCellElement(0, "email"),
                                  );
                              },
                              onCellHoverChanged: function (e) {
                                if (
                                  typeof e.column !== "undefined" &&
                                  e.column.dataField == "idCargo"
                                ) {
                                  let dxPopover_cargo =
                                    $("#dxPopover_cargo").dxPopover("instance");
                                  if (e.eventType === "mouseover") {
                                    let data = e.data;
                                    if (
                                      typeof data !== "undefined" &&
                                      data.detallesCargo
                                    ) {
                                      dxPopover_cargo.option(
                                        "target",
                                        "#dxDataGrid_Usuarios_idCargo_" +
                                          e.data.idUsuario,
                                      );
                                      dxPopover_cargo.option(
                                        "contentTemplate",
                                        function (contentElement) {
                                          $("<div style='font-size:12px;'/>")
                                            .append(data.detallesCargo)
                                            .appendTo(contentElement);
                                        },
                                      );
                                      dxPopover_cargo.show();
                                    }
                                  } else dxPopover_cargo.hide();
                                } else if (
                                  typeof e.column !== "undefined" &&
                                  e.column.name == "logUsuario" &&
                                  e.data &&
                                  e.data.subtipoUsuario != 3
                                ) {
                                  let dxPopover_logUsuario = $(
                                    "#dxPopover_logUsuario",
                                  ).dxPopover("instance");
                                  if (e.eventType === "mouseover") {
                                    dxPopover_logUsuario.option(
                                      "target",
                                      "#dxDataGrid_Usuarios_logUsuario_" +
                                        e.data.idUsuario,
                                    );
                                    dxPopover_logUsuario.option(
                                      "contentTemplate",
                                      function (contentElement) {
                                        $("<div style='font-size:12px;'/>")
                                          .append(
                                            "Acceder a MyPolarier con este usuario",
                                          )
                                          .appendTo(contentElement);
                                      },
                                    );
                                    dxPopover_logUsuario.show();
                                  } else dxPopover_logUsuario.hide();
                                }
                              },
                              onToolbarPreparing: function (e) {
                                $.each(
                                  e.toolbarOptions.items,
                                  function (index, item) {
                                    if (item.name === "saveButton") {
                                      item.visible = false;
                                    }
                                  },
                                );
                                e.toolbarOptions.items.unshift(
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    locateInMenu: "auto",
                                    options: {
                                      text: getTrad("limpiarFiltro"),
                                      icon: " icon_EliminarFiltros",
                                      onClick: function (e) {
                                        $("#dxDataGrid_Usuarios")
                                          .dxDataGrid("instance")
                                          .clearFilter();
                                        notify({
                                          message: getTrad(
                                            "aviso_C_FiltroRestaurado",
                                          ),
                                          type: "success",
                                          displayTime: "1500",
                                          closeOnClick: true,
                                        });
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    showText: "inMenu",
                                    options: {
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onClick: function () {
                                        e.component.refresh();
                                      },
                                    },
                                  },
                                  {
                                    location: "before",
                                    widget: "dxButton",
                                    toolbar: "bottom",
                                    options: {
                                      width: "100%",
                                      icon: "plus",
                                      text: getTrad("nuevoUsuario"),
                                      type: "normal",
                                      onClick: function (e) {
                                        userSel = null;
                                        loadUserData();

                                        if (
                                          $(
                                            "#dxAccordion_formularioVisibles",
                                          ).data("dxAccordion")
                                        )
                                          $("#dxAccordion_formularioVisibles")
                                            .dxAccordion("instance")
                                            .option("selectedIndex", -1);

                                        if (
                                          $("#dxTabPanel_principal").data(
                                            "dxTabPanel",
                                          )
                                        )
                                          $("#dxTabPanel_principal")
                                            .dxTabPanel("instance")
                                            .option("selectedIndex", 1);
                                      },
                                    },
                                  },
                                );
                              },
                              //Estilos
                              showColumnLines: false,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            },
                          );
                        },
                      },
                      {
                        template: function () {
                          return $("<div />").dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            elementAttr: {
                              id: "dxBox_datosUsuarios",
                            },
                            items: [
                              {
                                baseSize: 62,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='dxToolbar_usuarios' />",
                                  ).dxToolbar({
                                    items: [
                                      {
                                        location: "before",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("atras"),
                                          icon: "chevronleft",
                                          onClick: function () {
                                            let dxTabPanel_formUser = $(
                                                "#dxTabPanel_formUser",
                                              ).dxTabPanel("instance"),
                                              formUser_selIndex =
                                                dxTabPanel_formUser.option(
                                                  "selectedIndex",
                                                );

                                            if (
                                              userSel.idUsuario === null &&
                                              formUser_selIndex === 1
                                            ) {
                                              $("#dxList_tipoUsuario")
                                                .dxList("instance")
                                                .unselectAll();
                                              dxTabPanel_formUser.option(
                                                "selectedIndex",
                                                formUser_selIndex - 1,
                                              );
                                            } else {
                                              $("#dxTabPanel_principal")
                                                .dxTabPanel("instance")
                                                .option("selectedIndex", 0);
                                              dxTabPanel_formUser.option(
                                                "selectedIndex",
                                                0,
                                              );
                                            }
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("siguiente"),
                                          icon: "chevronright",
                                          onClick: function () {
                                            var dxForm_datosUser =
                                              $("#dxForm_datosUser").dxForm(
                                                "instance",
                                              );
                                            var dxTabPanel_principal = $(
                                              "#dxTabPanel_principal",
                                            ).dxTabPanel("instance");

                                            $.when(
                                              dxForm_datosUser.validate()
                                                .complete,
                                            ).then((r) => {
                                              if (r.isValid) {
                                                var subtipoUser =
                                                  userSel.subtipoUsuario;
                                                if (
                                                  subtipoUser === 0 ||
                                                  subtipoUser === 2
                                                ) {
                                                  let tab = is_centrosTrabajo()
                                                    ? 3
                                                    : is_FormLavanderia()
                                                      ? 4
                                                      : is_FormRecambio()
                                                        ? 5
                                                        : 5;
                                                  dxTabPanel_principal.option(
                                                    "selectedIndex",
                                                    tab,
                                                  );
                                                  if (tab === 5)
                                                    setResumenForm();
                                                } else if (
                                                  subtipoUser === 1 ||
                                                  subtipoUser == 3
                                                ) {
                                                  let tab = is_FormLavanderia()
                                                    ? 2
                                                    : is_centrosTrabajo()
                                                      ? 3
                                                      : is_FormRecambio()
                                                        ? 5
                                                        : 5;
                                                  dxTabPanel_principal.option(
                                                    "selectedIndex",
                                                    tab,
                                                  );
                                                  if (tab === 5)
                                                    setResumenForm();
                                                }
                                              }
                                            });
                                          },
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='dxForm_datosUser'>",
                                  ).dxForm({
                                    height: "100%",
                                    labelLocation: "top",
                                    colCount: 2,
                                    repaintChangesOnly: true,
                                    scrollingEnabled: true,
                                    items: [
                                      {
                                        itemType: "group",
                                        caption: getTrad("datosUsuario"),
                                        name: "datosUsuario",
                                        height: "100%",
                                        colCount: 1,
                                        colSpan: 1,
                                        cssClass: "pb-0",
                                        items: [
                                          {
                                            itemType: "tabbed",
                                            tabPanelOptions: {
                                              deferRendering: false,
                                              swipeEnabled: false,
                                              elementAttr: {
                                                id: "dxTabPanel_formUser",
                                                class: "noPadding",
                                              },
                                              selectedIndex:
                                                userSel === null ? 0 : 2,
                                              onSelectionChanged: function (e) {
                                                var tabSelected =
                                                    e.component.option(
                                                      "selectedIndex",
                                                    ),
                                                  dxForm_datosUser =
                                                    $(
                                                      "#dxForm_datosUser",
                                                    ).dxForm("instance"),
                                                  dxToolbar_usuarios = $(
                                                    ".dxToolbar_usuarios",
                                                  ).dxToolbar("instance");

                                                if (
                                                  tabSelected === 0 ||
                                                  tabSelected === 1
                                                ) {
                                                  // SELECCIÓN TIPO DE USUARIO
                                                  dxToolbar_usuarios.option(
                                                    "items[1].disabled",
                                                    true,
                                                  );
                                                } else {
                                                  let isUserEntidad =
                                                      userSel !== null
                                                        ? userSel.subtipoUsuario ===
                                                            0 ||
                                                          userSel.subtipoUsuario ===
                                                            2
                                                        : false,
                                                    isAsociarPersona_visible =
                                                      !isUserEntidad &&
                                                      userSel.idTipoUsuario ===
                                                        2,
                                                    dxForm_ResumenDatosUser = $(
                                                      "#dxForm_ResumenDatosUser",
                                                    ).dxForm("instance");
                                                  dxToolbar_usuarios.option(
                                                    "items[1].disabled",
                                                    false,
                                                  );

                                                  if (
                                                    userSel.idUsuario === null
                                                  ) {
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.idCompañia",
                                                      "visible",
                                                      isUserEntidad,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupo_asociarPersona.idPersona",
                                                      "visible",
                                                      isAsociarPersona_visible,
                                                    );
                                                    mostrarCamposForm_resumen(
                                                      dxForm_ResumenDatosUser,
                                                    );

                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.idCompañia",
                                                      "visible",
                                                      isUserEntidad,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupo_asociarPersona.dxButton_asociarPersona",
                                                      "visible",
                                                      isAsociarPersona_visible,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupo_asociarPersona.idPersona",
                                                      "visible",
                                                      isAsociarPersona_visible,
                                                    );
                                                    mostrarCamposForm(
                                                      dxForm_datosUser,
                                                    );

                                                    if (!isUserEntidad)
                                                      userSel.idCompañia = null;

                                                    if (
                                                      userSel.subtipoUsuario ===
                                                      2
                                                    ) {
                                                      userSel.idCargo = 7;
                                                      dxForm_datosUser
                                                        .getEditor("idCargo")
                                                        .option(
                                                          "value",
                                                          userSel.idCargo,
                                                        );
                                                    }
                                                    setTimeout(() => {
                                                      dxForm_datosUser
                                                        .getEditor("idCargo")
                                                        .option(
                                                          "dataSource",
                                                          filtrar_tblCargo(),
                                                        );
                                                    }, 0);
                                                  } else {
                                                    mostrarCamposForm(
                                                      dxForm_datosUser,
                                                    );
                                                    mostrarCamposForm_resumen(
                                                      dxForm_ResumenDatosUser,
                                                    );
                                                  }

                                                  function mostrarCamposForm(
                                                    dxForm_datosUser,
                                                  ) {
                                                    dxForm_datosUser.beginUpdate();
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.subtipoUsuarioPedidos",
                                                      "visible",
                                                      userSel.subtipoUsuario ===
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.subtipoUsuarioPedidos",
                                                    ).editorOptions.value =
                                                      "Pedidos";
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.subtipoUsuario",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.importarPermisos",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.enableDatosRRHH",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.refactura",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.detallesCargo",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.email",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.password",
                                                      "visible",
                                                      userSel.subtipoUsuario ===
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.confirmarContraseña",
                                                      "visible",
                                                      userSel.subtipoUsuario ===
                                                        2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.idCargo",
                                                    ).editorOptions.readOnly =
                                                      userSel.subtipoUsuario ===
                                                      2;
                                                    dxForm_datosUser.endUpdate();
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.isGestionaUsuarios",
                                                      "visible",
                                                      userSel.idTipoUsuario !==
                                                        1 &&
                                                        userSel.subtipoUsuario !==
                                                          2,
                                                    );
                                                    dxForm_datosUser.itemOption(
                                                      "datosUsuario.formTab.grupoGeneral.isDepartamentoControl",
                                                      "visible",
                                                      USER.isDepartamentoControl &&
                                                        userSel.idTipoUsuario ===
                                                          2,
                                                    );
                                                  }

                                                  function mostrarCamposForm_resumen(
                                                    dxForm_ResumenDatosUser,
                                                  ) {
                                                    dxForm_ResumenDatosUser.beginUpdate();
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.subtipoUsuarioPedidos",
                                                      "visible",
                                                      userSel.subtipoUsuario ===
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.subtipoUsuario",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.enableDatosRRHH",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.refactura",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.fechaCreacion",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.detallesCargo",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.email",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2,
                                                    );
                                                    dxForm_ResumenDatosUser.endUpdate();
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.isGestionaUsuarios",
                                                      "visible",
                                                      userSel.subtipoUsuario !==
                                                        2 &&
                                                        userSel.idTipoUsuario !==
                                                          1,
                                                    );
                                                    dxForm_ResumenDatosUser.itemOption(
                                                      "datosUsuario.grupoGeneral.isDepartamentoControl",
                                                      "visible",
                                                      USER.isDepartamentoControl &&
                                                        userSel.idTipoUsuario ===
                                                          2,
                                                    );
                                                  }
                                                }
                                              },
                                            },
                                            height: "100%",
                                            tabs: [
                                              //#region
                                              {
                                                height: "100%",
                                                colCount: 1,
                                                template: function (
                                                  data,
                                                  itemElement,
                                                ) {
                                                  return $(
                                                    "<div id='dxBox_tipoUsuario' />",
                                                  ).dxBox({
                                                    direction: "col",
                                                    align: "space-around",
                                                    crossAlign: "stretch",
                                                    height: "100%",
                                                    items: [
                                                      {
                                                        baseSize: 50,
                                                        shrink: 0,
                                                        ratio: 0,
                                                        template: function () {
                                                          return $("<div />")
                                                            .css(
                                                              "padding-left",
                                                              10,
                                                            )
                                                            .addClass(
                                                              "font-size-md",
                                                            )
                                                            .text(
                                                              getTrad(
                                                                "selectTipoUsuarioCrear",
                                                              ),
                                                            );
                                                        },
                                                      },
                                                      {
                                                        ratio: 1,
                                                        template: function (
                                                          itemData,
                                                          itemIndex,
                                                          itemElement,
                                                        ) {
                                                          return $(
                                                            "<div id='dxList_tipoUsuario' />",
                                                          ).dxList({
                                                            dataSource:
                                                              datasource_tblTipoUsuario.items(),
                                                            height: "100%",
                                                            selectionMode:
                                                              "single",
                                                            scrolling: {
                                                              mode: "infinite",
                                                            },
                                                            pageLoadMode:
                                                              "scrollBottom",
                                                            keyExpr:
                                                              "idTipoUsuario",
                                                            onSelectionChanged:
                                                              function (e) {
                                                                if (
                                                                  e.addedItems
                                                                    .length > 0
                                                                ) {
                                                                  userSel.idTipoUsuario =
                                                                    e.addedItems[0].idTipoUsuario;

                                                                  let isUserEntidad =
                                                                    userSel !==
                                                                    null
                                                                      ? userSel.subtipoUsuario ===
                                                                          0 ||
                                                                        userSel.subtipoUsuario ===
                                                                          2
                                                                      : false;
                                                                  $(
                                                                    "#dxForm_ResumenDatosUser",
                                                                  )
                                                                    .dxForm(
                                                                      "instance",
                                                                    )
                                                                    .itemOption(
                                                                      "datosUsuario.grupoGeneral.idCompañia",
                                                                      "visible",
                                                                      isUserEntidad,
                                                                    );
                                                                  $(
                                                                    "#dxForm_datosUser",
                                                                  )
                                                                    .dxForm(
                                                                      "instance",
                                                                    )
                                                                    .itemOption(
                                                                      "datosUsuario.formTab.grupoGeneral.idCompañia",
                                                                      "visible",
                                                                      isUserEntidad,
                                                                    );
                                                                  $(
                                                                    "#dxTabPanel_formUser",
                                                                  )
                                                                    .dxTabPanel(
                                                                      "instance",
                                                                    )
                                                                    .option(
                                                                      "selectedIndex",
                                                                      1,
                                                                    );
                                                                }
                                                              },
                                                            itemTemplate:
                                                              function (
                                                                data,
                                                                index,
                                                                element,
                                                              ) {
                                                                var heightTitulo = 50;
                                                                var heightForm =
                                                                  $(
                                                                    "#dxForm_datosUser",
                                                                  ).height() -
                                                                  heightTitulo -
                                                                  80; // Al form e le suma un extraheight de 80 que se pone en container #Usuarios
                                                                setTimeout(
                                                                  () => {
                                                                    $(
                                                                      "#dxBox_tipoUsuario",
                                                                    ).height(
                                                                      heightForm,
                                                                    );
                                                                    var listHeight =
                                                                      $(
                                                                        "#dxList_tipoUsuario",
                                                                      ).height();
                                                                    element
                                                                      .parent()
                                                                      .css(
                                                                        "height",
                                                                        listHeight /
                                                                          3,
                                                                      );
                                                                  },
                                                                  0,
                                                                );
                                                                var result = $(
                                                                  "<div/>",
                                                                ).addClass(
                                                                  "d-flex align-items-center he-100 justify-content-center",
                                                                );
                                                                $("<div />")
                                                                  .addClass(
                                                                    "font-size w-100",
                                                                  )
                                                                  .append(
                                                                    $("<h3>")
                                                                      .css(
                                                                        "text-align",
                                                                        "center",
                                                                      )
                                                                      .text(
                                                                        data.denominacion.toUpperCase(),
                                                                      ),
                                                                  )
                                                                  .appendTo(
                                                                    result,
                                                                  );
                                                                return result;
                                                              },
                                                          });
                                                        },
                                                      },
                                                    ],
                                                  });
                                                },
                                              },
                                              {
                                                items: [
                                                  {
                                                    template: function (
                                                      data,
                                                      itemElement,
                                                    ) {
                                                      return $(
                                                        "<div id='dxBox_subtipoUsuario' />",
                                                      ).dxBox({
                                                        direction: "col",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        height: "100%",
                                                        items: [
                                                          {
                                                            baseSize: 50,
                                                            shrink: 0,
                                                            ratio: 0,
                                                            template:
                                                              function () {
                                                                return $(
                                                                  "<div />",
                                                                )
                                                                  .css(
                                                                    "padding-left",
                                                                    10,
                                                                  )
                                                                  .addClass(
                                                                    "font-size-md",
                                                                  )
                                                                  .text(
                                                                    getTrad(
                                                                      "selectSubtipoUsuarioCrear",
                                                                    ),
                                                                  );
                                                              },
                                                          },
                                                          {
                                                            ratio: 1,
                                                            template: function (
                                                              itemData,
                                                              itemIndex,
                                                              itemElement,
                                                            ) {
                                                              return $(
                                                                "<div id='dxList_subtipoUsuario' />",
                                                              ).dxList({
                                                                dataSource:
                                                                  enum_subtipoUsuario,
                                                                height: "100%",
                                                                selectionMode:
                                                                  "single",
                                                                scrolling: {
                                                                  mode: "infinite",
                                                                },
                                                                pageLoadMode:
                                                                  "scrollBottom",
                                                                keyExpr:
                                                                  "value",
                                                                onSelectionChanged:
                                                                  function (e) {
                                                                    let dxLoadPanel_formularios =
                                                                      $(
                                                                        "#dxLoadPanel_formularios",
                                                                      ).dxLoadPanel(
                                                                        "instance",
                                                                      );
                                                                    if (
                                                                      e
                                                                        .addedItems
                                                                        .length >
                                                                      0
                                                                    ) {
                                                                      var value =
                                                                          e
                                                                            .addedItems[0]
                                                                            .value,
                                                                        isUserLav =
                                                                          e
                                                                            .addedItems[0]
                                                                            .value ===
                                                                            1 ||
                                                                          e
                                                                            .addedItems[0]
                                                                            .value ===
                                                                            3;

                                                                      userSel.subtipoUsuario =
                                                                        value;

                                                                      let isDataLoaded = false;
                                                                      setTimeout(
                                                                        () => {
                                                                          dxLoadPanel_formularios.show();
                                                                          if (
                                                                            isDataLoaded
                                                                          )
                                                                            dxLoadPanel_formularios.hide();
                                                                        },
                                                                        750,
                                                                      );

                                                                      $(
                                                                        "#dxTabPanel_formUser",
                                                                      )
                                                                        .dxTabPanel(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "selectedIndex",
                                                                          2,
                                                                        );

                                                                      dxScrollView_formulariosNUsuario_resize();
                                                                      $(
                                                                        "#dxButtonGroup_formularioVisibles",
                                                                      )
                                                                        .dxButtonGroup(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "selectedItemKeys",
                                                                          [
                                                                            idAplicacionSel,
                                                                          ],
                                                                        );

                                                                      $(
                                                                        "#dxForm_datosUser",
                                                                      )
                                                                        .dxForm(
                                                                          "instance",
                                                                        )
                                                                        .itemOption(
                                                                          "datosUsuario.formTab.grupoGeneral.idLavanderiaInicio",
                                                                          "visible",
                                                                          isUserLav,
                                                                        );
                                                                      $(
                                                                        "#dxForm_ResumenDatosUser",
                                                                      )
                                                                        .dxForm(
                                                                          "instance",
                                                                        )
                                                                        .itemOption(
                                                                          "datosUsuario.grupoGeneral.idLavanderiaInicio",
                                                                          "visible",
                                                                          isUserLav,
                                                                        );

                                                                      datasource_formulariosNApartado
                                                                        .load()
                                                                        .done(
                                                                          function () {
                                                                            isDataLoaded = true;
                                                                            if (
                                                                              value ===
                                                                              2
                                                                            ) {
                                                                              //Usuario pedidos
                                                                              $.each(
                                                                                userSel.tblFormulario,
                                                                                function (
                                                                                  iApartado,
                                                                                  apartado,
                                                                                ) {
                                                                                  $.each(
                                                                                    apartado.tblFormulario,
                                                                                    function (
                                                                                      iFormulario,
                                                                                      formulario,
                                                                                    ) {
                                                                                      if (
                                                                                        formulario.idFormulario ===
                                                                                        30
                                                                                      ) {
                                                                                        formulario.isEscritura = true;
                                                                                        formulario.isBorrado = true;
                                                                                      }
                                                                                    },
                                                                                  );
                                                                                },
                                                                              );
                                                                            }

                                                                            dxNavbar_disableItems(
                                                                              isUserLav,
                                                                            );
                                                                            dxLoadPanel_formularios.hide();

                                                                            $(
                                                                              "#dxAccordion_formularioVisibles",
                                                                            )
                                                                              .dxAccordion(
                                                                                "instance",
                                                                              )
                                                                              .option(
                                                                                {
                                                                                  dataSource:
                                                                                    userSel.tblFormulario,
                                                                                },
                                                                              );
                                                                          },
                                                                        );
                                                                      if (
                                                                        userSel.subtipoUsuario !==
                                                                        2
                                                                      ) {
                                                                        let dxSwitch_subtipoUser =
                                                                          $(
                                                                            "#dxSwitch_subtipoUser",
                                                                          ).dxSwitch(
                                                                            "instance",
                                                                          );
                                                                        dxSwitch_subtipoUser.option(
                                                                          "value",
                                                                          isUserLav,
                                                                        );
                                                                      }
                                                                    }
                                                                  },
                                                                itemTemplate:
                                                                  function (
                                                                    data,
                                                                    index,
                                                                    element,
                                                                  ) {
                                                                    var heightTitulo = 50;
                                                                    var heightForm =
                                                                      $(
                                                                        "#dxForm_datosUser",
                                                                      ).height() -
                                                                      heightTitulo -
                                                                      80; // Al form e le suma un extraheight de 80 que se pone en container #Usuarios
                                                                    setTimeout(
                                                                      () => {
                                                                        $(
                                                                          "#dxBox_subtipoUsuario",
                                                                        ).height(
                                                                          heightForm,
                                                                        );
                                                                        var listHeight =
                                                                          $(
                                                                            "#dxList_subtipoUsuario",
                                                                          ).height()
                                                                            ? $(
                                                                                "#dxList_subtipoUsuario",
                                                                              ).height()
                                                                            : 500;
                                                                        element
                                                                          .parent()
                                                                          .height(
                                                                            listHeight /
                                                                              3 -
                                                                              5,
                                                                          );
                                                                      },
                                                                      0,
                                                                    );

                                                                    var result =
                                                                      $(
                                                                        "<div/>",
                                                                      ).addClass(
                                                                        "d-flex align-items-center he-100 justify-content-center",
                                                                      );
                                                                    $("<div />")
                                                                      .addClass(
                                                                        "font-size w-100",
                                                                      )
                                                                      .append(
                                                                        $(
                                                                          "<h3>",
                                                                        )
                                                                          .css(
                                                                            "text-align",
                                                                            "center",
                                                                          )
                                                                          .text(
                                                                            data.text.toUpperCase(),
                                                                          ),
                                                                        $(
                                                                          "<div/>",
                                                                        )
                                                                          .addClass(
                                                                            "font-size-xs",
                                                                          )
                                                                          .css({
                                                                            padding:
                                                                              "15px 95px",
                                                                            "white-space":
                                                                              "normal",
                                                                            "text-align":
                                                                              "center",
                                                                          })
                                                                          .text(
                                                                            data.subText,
                                                                          ),
                                                                      )
                                                                      .appendTo(
                                                                        result,
                                                                      );
                                                                    return result;
                                                                  },
                                                              });
                                                            },
                                                          },
                                                        ],
                                                      });
                                                    },
                                                  },
                                                ],
                                              },
                                              //#endregion
                                              {
                                                height: "100%",
                                                title: "formTab",
                                                items: [
                                                  {
                                                    itemType: "group",
                                                    name: "grupoGeneral",
                                                    colCount: 3,
                                                    visibleIndex: 1,
                                                    items: [
                                                      {
                                                        dataField: "nombre",
                                                        label: {
                                                          text: getTrad(
                                                            "nombre",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                        ],
                                                        visibleIndex: 0,
                                                      },
                                                      {
                                                        dataField: "usuario",
                                                        label: {
                                                          text: getTrad(
                                                            "usuario",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                          {
                                                            type: "async",
                                                            validationCallback:
                                                              function (
                                                                params,
                                                              ) {
                                                                return new Promise(
                                                                  (
                                                                    resolve,
                                                                    reject,
                                                                  ) => {
                                                                    let originalUser =
                                                                      $.grep(
                                                                        datasource_usuarios.items(),
                                                                        function (
                                                                          item,
                                                                        ) {
                                                                          return userSel.idUsuario !==
                                                                            null
                                                                            ? item.idUsuario ===
                                                                                userSel.idUsuario
                                                                            : false;
                                                                        },
                                                                      );

                                                                    $.when(
                                                                      userService.validateEmailUsuario(
                                                                        params.value,
                                                                      ),
                                                                    ).then(
                                                                      function (
                                                                        usuario,
                                                                      ) {
                                                                        let isUsuario_inDB =
                                                                            usuario.isValid,
                                                                          isOriginalUsuario =
                                                                            originalUser.length >
                                                                            0
                                                                              ? originalUser[0]
                                                                                  .usuario ===
                                                                                  params.value ||
                                                                                originalUser[0]
                                                                                  .email ===
                                                                                  params.value
                                                                              : false;
                                                                        if (
                                                                          isUsuario_inDB &&
                                                                          !isOriginalUsuario
                                                                        )
                                                                          reject();
                                                                        else
                                                                          resolve();
                                                                      },
                                                                    );
                                                                  },
                                                                );
                                                              },
                                                            message: getTrad(
                                                              "alert_usuarioDuplicado",
                                                            ),
                                                          },
                                                        ],
                                                        visibleIndex: 1,
                                                      },
                                                      {
                                                        dataField:
                                                          "subtipoUsuario",
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                          switchedOnText:
                                                            getTrad(
                                                              "lavanderia",
                                                            ),
                                                          switchedOffText:
                                                            getTrad("entidad"),
                                                          elementAttr: {
                                                            id: "dxSwitch_subtipoUser",
                                                            class:
                                                              "no-disabled",
                                                          },
                                                          isImportandoUsuario: false, // Para no mostrar alert cambios pendientes cuando un usuario se importa(tiene otro alert)
                                                          inicializarValue: false, // si es true, al asignar valor por 1era vez omite onValueChanged.
                                                          onValueChanged:
                                                            function (e) {
                                                              if (
                                                                e.value ===
                                                                  null ||
                                                                userSel ===
                                                                  null ||
                                                                e.component.option(
                                                                  "inicializarValue",
                                                                ) ||
                                                                (userSel !=
                                                                  null &&
                                                                  userSel.subtipoUsuario ===
                                                                    2)
                                                              ) {
                                                                e.component.option(
                                                                  "inicializarValue",
                                                                  false,
                                                                );
                                                                return;
                                                              }

                                                              var tblLavanderia =
                                                                [];
                                                              $.each(
                                                                userSel.tblLavanderia,
                                                                function (
                                                                  index,
                                                                  value,
                                                                ) {
                                                                  var tblLavanderiaSel =
                                                                    {};

                                                                  if (
                                                                    value ===
                                                                    true
                                                                  ) {
                                                                    tblLavanderiaSel.idLavanderia =
                                                                      index;
                                                                    tblLavanderia.push(
                                                                      tblLavanderiaSel,
                                                                    );
                                                                  }
                                                                },
                                                              );

                                                              let isFormSelected =
                                                                is_FormSelected();
                                                              let isChange_entiLav =
                                                                userSel
                                                                  .tblEntidad
                                                                  .length > 0 ||
                                                                tblLavanderia.length >
                                                                  0;
                                                              let dxForm_datosUser =
                                                                $(
                                                                  "#dxForm_datosUser",
                                                                ).dxForm(
                                                                  "instance",
                                                                );

                                                              if (
                                                                e.component.option(
                                                                  "isImportandoUsuario",
                                                                ) === false &&
                                                                ((e.value &&
                                                                  userSel
                                                                    .tblEntidad
                                                                    .length >
                                                                    0) || // lavandería
                                                                  (!e.value &&
                                                                    tblLavanderia.length >
                                                                      0) || // entidad
                                                                  isFormSelected)
                                                              ) {
                                                                var mensaje =
                                                                  "";

                                                                if (
                                                                  isChange_entiLav
                                                                ) {
                                                                  if (
                                                                    isFormSelected
                                                                  ) {
                                                                    mensaje =
                                                                      e.value >
                                                                      0
                                                                        ? getTrad(
                                                                            "alert_eliminarEntiSel_formularios",
                                                                          )
                                                                        : getTrad(
                                                                            "alert_eliminarLavSel_formularios",
                                                                          );
                                                                  } else {
                                                                    mensaje =
                                                                      e.value >
                                                                      0
                                                                        ? getTrad(
                                                                            "alert_eliminarEntiSel",
                                                                          )
                                                                        : getTrad(
                                                                            "alert_eliminarLavSel",
                                                                          );
                                                                  }
                                                                } else
                                                                  mensaje =
                                                                    getTrad(
                                                                      "preg_PerderCambios",
                                                                    );

                                                                dxMensajePregunta(
                                                                  mensaje,
                                                                  [
                                                                    [
                                                                      getTrad(
                                                                        "aceptar",
                                                                      ),
                                                                      function () {
                                                                        dxForm_datosUser.itemOption(
                                                                          "datosUsuario.formTab.grupoGeneral.idLavanderiaInicio",
                                                                          "visible",
                                                                          e.value,
                                                                        );
                                                                        $(
                                                                          "#dxForm_ResumenDatosUser",
                                                                        )
                                                                          .dxForm(
                                                                            "instance",
                                                                          )
                                                                          .itemOption(
                                                                            "datosUsuario.grupoGeneral.idLavanderiaInicio",
                                                                            "visible",
                                                                            e.value,
                                                                          );
                                                                        userSel.subtipoUsuario =
                                                                          e.value
                                                                            ? 1
                                                                            : 0;

                                                                        if (
                                                                          e.value
                                                                        ) {
                                                                          userSel.tblEntidad =
                                                                            [];
                                                                          $(
                                                                            "#dxList_entidades",
                                                                          )
                                                                            .dxList(
                                                                              "instance",
                                                                            )
                                                                            .option(
                                                                              "selectedItemKeys",
                                                                              [],
                                                                            );
                                                                          $(
                                                                            "#dxList_compañias",
                                                                          )
                                                                            .dxList(
                                                                              "instance",
                                                                            )
                                                                            .selectItem(
                                                                              0,
                                                                            );
                                                                        } else
                                                                          dxPivotGridSelection_reset();

                                                                        if (
                                                                          $(
                                                                            "#dxSwitch_isGestionaUsuarios",
                                                                          ).data(
                                                                            "dxSwitch",
                                                                          ) !=
                                                                          null
                                                                        ) {
                                                                          $(
                                                                            "#dxSwitch_isGestionaUsuarios",
                                                                          )
                                                                            .dxSwitch(
                                                                              "instance",
                                                                            )
                                                                            .option(
                                                                              {
                                                                                value: false,
                                                                                disabled:
                                                                                  !e.value,
                                                                              },
                                                                            );
                                                                        }

                                                                        if (
                                                                          typeof $(
                                                                            "#dxSelectBox_lavanderiaInicio",
                                                                          ).dxSelectBox(
                                                                            "instance",
                                                                          ) !==
                                                                          "undefined"
                                                                        ) {
                                                                          $(
                                                                            "#dxSelectBox_lavanderiaInicio",
                                                                          )
                                                                            .dxSelectBox(
                                                                              "instance",
                                                                            )
                                                                            .option(
                                                                              {
                                                                                value:
                                                                                  null,
                                                                                disabled:
                                                                                  !e.value,
                                                                              },
                                                                            );
                                                                        }

                                                                        datasource_formulariosNApartado
                                                                          .load()
                                                                          .done(
                                                                            function () {
                                                                              $.each(
                                                                                userSel.tblFormulario,
                                                                                function (
                                                                                  index,
                                                                                  apartado,
                                                                                ) {
                                                                                  $.each(
                                                                                    apartado.tblFormulario,
                                                                                    function (
                                                                                      index2,
                                                                                      item,
                                                                                    ) {
                                                                                      item.isEscritura = false;
                                                                                      item.isBorrado = false;
                                                                                    },
                                                                                  );
                                                                                },
                                                                              );

                                                                              dxNavbar_disableItems(
                                                                                e.value,
                                                                              );
                                                                              checkFormularioInicio();
                                                                              $(
                                                                                "#dxAccordion_formularioVisibles",
                                                                              )
                                                                                .dxAccordion(
                                                                                  "instance",
                                                                                )
                                                                                .option(
                                                                                  "dataSource",
                                                                                  userSel.tblFormulario,
                                                                                );
                                                                            },
                                                                          );

                                                                        let isUserEntidad =
                                                                            userSel !==
                                                                            null
                                                                              ? userSel.subtipoUsuario ===
                                                                                  0 ||
                                                                                userSel.subtipoUsuario ===
                                                                                  2
                                                                              : false,
                                                                          dxForm_ResumenDatosUser =
                                                                            $(
                                                                              "#dxForm_ResumenDatosUser",
                                                                            ).dxForm(
                                                                              "instance",
                                                                            ),
                                                                          isAsociarPersona_visible =
                                                                            !isUserEntidad &&
                                                                            userSel.idTipoUsuario ===
                                                                              2;

                                                                        dxForm_ResumenDatosUser.itemOption(
                                                                          "datosUsuario.grupoGeneral.idCompañia",
                                                                          "visible",
                                                                          isUserEntidad,
                                                                        );
                                                                        dxForm_ResumenDatosUser.itemOption(
                                                                          "datosUsuario.grupo_asociarPersona.idPersona",
                                                                          "visible",
                                                                          isAsociarPersona_visible,
                                                                        );
                                                                        dxForm_datosUser.itemOption(
                                                                          "datosUsuario.formTab.grupoGeneral.idCompañia",
                                                                          "visible",
                                                                          isUserEntidad,
                                                                        );
                                                                        dxForm_datosUser.itemOption(
                                                                          "datosUsuario.formTab.grupo_asociarPersona.dxButton_asociarPersona",
                                                                          "visible",
                                                                          isAsociarPersona_visible,
                                                                        );
                                                                        dxForm_datosUser.itemOption(
                                                                          "datosUsuario.formTab.grupo_asociarPersona.idPersona",
                                                                          "visible",
                                                                          isAsociarPersona_visible,
                                                                        );

                                                                        dxForm_datosUser
                                                                          .getEditor(
                                                                            "idCargo",
                                                                          )
                                                                          .option(
                                                                            {
                                                                              value:
                                                                                null,
                                                                              dataSource:
                                                                                filtrar_tblCargo(),
                                                                            },
                                                                          );

                                                                        dxForm_datosUser
                                                                          .getEditor(
                                                                            "detallesCargo",
                                                                          )
                                                                          .option(
                                                                            "value",
                                                                            null,
                                                                          );
                                                                        dxForm_datosUser
                                                                          .getEditor(
                                                                            "idFormularioInicio",
                                                                          )
                                                                          .option(
                                                                            "selectedItem",
                                                                            null,
                                                                          );
                                                                        dxForm_datosUser
                                                                          .getEditor(
                                                                            "idCompañia",
                                                                          )
                                                                          .option(
                                                                            "value",
                                                                            null,
                                                                          );
                                                                        userSel.idCompañia =
                                                                          null;
                                                                      },
                                                                      "danger",
                                                                    ],
                                                                    [
                                                                      getTrad(
                                                                        "cancelar",
                                                                      ),
                                                                      function () {
                                                                        e.component.option(
                                                                          "value",
                                                                          !e.value,
                                                                        );
                                                                      },
                                                                    ],
                                                                  ],
                                                                );
                                                              } else {
                                                                $(
                                                                  "#dxForm_datosUser",
                                                                )
                                                                  .dxForm(
                                                                    "instance",
                                                                  )
                                                                  .itemOption(
                                                                    "datosUsuario.formTab.grupoGeneral.idLavanderiaInicio",
                                                                    "visible",
                                                                    e.value,
                                                                  );
                                                                $(
                                                                  "#dxForm_ResumenDatosUser",
                                                                )
                                                                  .dxForm(
                                                                    "instance",
                                                                  )
                                                                  .itemOption(
                                                                    "datosUsuario.grupoGeneral.idLavanderiaInicio",
                                                                    "visible",
                                                                    e.value,
                                                                  );
                                                                userSel.subtipoUsuario =
                                                                  e.value
                                                                    ? 1
                                                                    : 0;

                                                                let is_lavInicioVisible =
                                                                  typeof $(
                                                                    "#dxSelectBox_lavanderiaInicio",
                                                                  ).dxSelectBox(
                                                                    "instance",
                                                                  ) !==
                                                                  "undefined"
                                                                    ? true
                                                                    : false;

                                                                if (
                                                                  $(
                                                                    "#dxSwitch_isGestionaUsuarios",
                                                                  ).data(
                                                                    "dxSwitch",
                                                                  ) != null
                                                                ) {
                                                                  var previousValue_gestionUsuarios =
                                                                    $(
                                                                      "#dxSwitch_isGestionaUsuarios",
                                                                    )
                                                                      .dxSwitch(
                                                                        "instance",
                                                                      )
                                                                      .option(
                                                                        "value",
                                                                      );

                                                                  $(
                                                                    "#dxSwitch_isGestionaUsuarios",
                                                                  )
                                                                    .dxSwitch(
                                                                      "instance",
                                                                    )
                                                                    .option({
                                                                      value:
                                                                        e.value ===
                                                                        false
                                                                          ? false
                                                                          : previousValue_gestionUsuarios,
                                                                      disabled:
                                                                        !e.value,
                                                                    });
                                                                }

                                                                var previousValue_lavInicio =
                                                                  is_lavInicioVisible
                                                                    ? $(
                                                                        "#dxSelectBox_lavanderiaInicio",
                                                                      )
                                                                        .dxSelectBox(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "value",
                                                                        )
                                                                    : null;

                                                                if (
                                                                  is_lavInicioVisible
                                                                ) {
                                                                  $(
                                                                    "#dxSelectBox_lavanderiaInicio",
                                                                  )
                                                                    .dxSelectBox(
                                                                      "instance",
                                                                    )
                                                                    .option({
                                                                      value:
                                                                        e.value ===
                                                                        false
                                                                          ? null
                                                                          : previousValue_lavInicio,
                                                                      disabled:
                                                                        !e.value,
                                                                    });
                                                                }

                                                                datasource_formulariosNApartado
                                                                  .load()
                                                                  .done(
                                                                    function () {
                                                                      $.each(
                                                                        userSel.tblFormulario,
                                                                        function (
                                                                          index,
                                                                          apartado,
                                                                        ) {
                                                                          $.each(
                                                                            apartado.tblFormulario,
                                                                            function (
                                                                              index2,
                                                                              item,
                                                                            ) {
                                                                              item.isEscritura = false;
                                                                              item.isBorrado = false;
                                                                            },
                                                                          );
                                                                        },
                                                                      );

                                                                      dxNavbar_disableItems(
                                                                        e.value,
                                                                      );
                                                                      checkFormularioInicio();
                                                                      $(
                                                                        "#dxAccordion_formularioVisibles",
                                                                      )
                                                                        .dxAccordion(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "dataSource",
                                                                          userSel.tblFormulario,
                                                                        );
                                                                    },
                                                                  );

                                                                let isUserEntidad =
                                                                    userSel !==
                                                                    null
                                                                      ? userSel.subtipoUsuario ===
                                                                          0 ||
                                                                        userSel.subtipoUsuario ===
                                                                          2
                                                                      : false,
                                                                  dxForm_ResumenDatosUser =
                                                                    $(
                                                                      "#dxForm_ResumenDatosUser",
                                                                    ).dxForm(
                                                                      "instance",
                                                                    ),
                                                                  isAsociarPersona_visible =
                                                                    !isUserEntidad &&
                                                                    userSel.idTipoUsuario ===
                                                                      2;

                                                                dxForm_ResumenDatosUser.itemOption(
                                                                  "datosUsuario.grupoGeneral.idCompañia",
                                                                  "visible",
                                                                  isUserEntidad,
                                                                );
                                                                dxForm_ResumenDatosUser.itemOption(
                                                                  "datosUsuario.grupo_asociarPersona.idPersona",
                                                                  "visible",
                                                                  isAsociarPersona_visible,
                                                                );
                                                                dxForm_datosUser.itemOption(
                                                                  "datosUsuario.formTab.grupoGeneral.idCompañia",
                                                                  "visible",
                                                                  isUserEntidad,
                                                                );
                                                                dxForm_datosUser.itemOption(
                                                                  "datosUsuario.formTab.grupo_asociarPersona.dxButton_asociarPersona",
                                                                  "visible",
                                                                  isAsociarPersona_visible,
                                                                );
                                                                dxForm_datosUser.itemOption(
                                                                  "datosUsuario.formTab.grupo_asociarPersona.idPersona",
                                                                  "visible",
                                                                  isAsociarPersona_visible,
                                                                );

                                                                dxForm_datosUser
                                                                  .getEditor(
                                                                    "idCargo",
                                                                  )
                                                                  .option({
                                                                    value: null,
                                                                    dataSource:
                                                                      filtrar_tblCargo(),
                                                                  });
                                                                dxForm_datosUser
                                                                  .getEditor(
                                                                    "detallesCargo",
                                                                  )
                                                                  .option(
                                                                    "value",
                                                                    null,
                                                                  );
                                                                dxForm_datosUser
                                                                  .getEditor(
                                                                    "idFormularioInicio",
                                                                  )
                                                                  .option(
                                                                    "selectedItem",
                                                                    null,
                                                                  );
                                                                dxForm_datosUser
                                                                  .getEditor(
                                                                    "idCompañia",
                                                                  )
                                                                  .option(
                                                                    "value",
                                                                    null,
                                                                  );
                                                                userSel.idCompañia =
                                                                  null;
                                                              }
                                                              e.component.option(
                                                                "isImportandoUsuario",
                                                                false,
                                                              );
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "subtipoUsuario",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 2,
                                                      },
                                                      {
                                                        dataField:
                                                          "subtipoUsuarioPedidos",
                                                        editorType: "dxTextBox",
                                                        editorOptions: {
                                                          readOnly: true,
                                                          text: "Pedidos",
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "subtipoUsuario",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 2,
                                                        visible: false,
                                                      },
                                                      {
                                                        dataField: "password",
                                                        editorType: "dxTextBox",
                                                        editorOptions: {
                                                          mode: "password",
                                                          value: "",
                                                          buttons: [
                                                            {
                                                              name: "password",
                                                              location: "after",
                                                              options: {
                                                                icon: " icon_Eye",
                                                                type: "default",
                                                                onClick:
                                                                  function () {
                                                                    let password_editor =
                                                                      $(
                                                                        "#dxForm_datosUser",
                                                                      )
                                                                        .dxForm(
                                                                          "instance",
                                                                        )
                                                                        .getEditor(
                                                                          "password",
                                                                        );
                                                                    password_editor.option(
                                                                      "mode",
                                                                      password_editor.option(
                                                                        "mode",
                                                                      ) ===
                                                                        "text"
                                                                        ? "password"
                                                                        : "text",
                                                                    );
                                                                  },
                                                              },
                                                            },
                                                          ],
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "contraseña",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 3,
                                                      },
                                                      {
                                                        name: "confirmarContraseña",
                                                        editorType: "dxTextBox",
                                                        editorOptions: {
                                                          mode: "password",
                                                          value: "",
                                                          buttons: [
                                                            {
                                                              name: "password",
                                                              location: "after",
                                                              options: {
                                                                icon: " icon_Eye",
                                                                type: "default",
                                                                onClick:
                                                                  function () {
                                                                    let password_editor =
                                                                      $(
                                                                        "#dxForm_datosUser",
                                                                      )
                                                                        .dxForm(
                                                                          "instance",
                                                                        )
                                                                        .getEditor(
                                                                          "confirmarContraseña",
                                                                        );
                                                                    password_editor.option(
                                                                      "mode",
                                                                      password_editor.option(
                                                                        "mode",
                                                                      ) ===
                                                                        "text"
                                                                        ? "password"
                                                                        : "text",
                                                                    );
                                                                  },
                                                              },
                                                            },
                                                          ],
                                                        },
                                                        validationRules: [
                                                          {
                                                            type: "compare",
                                                            comparisonTarget:
                                                              function () {
                                                                let password_editor_value =
                                                                  $(
                                                                    "#dxForm_datosUser",
                                                                  )
                                                                    .dxForm(
                                                                      "instance",
                                                                    )
                                                                    .getEditor(
                                                                      "password",
                                                                    );
                                                                if (
                                                                  password_editor_value
                                                                ) {
                                                                  return password_editor_value.option(
                                                                    "value",
                                                                  );
                                                                }
                                                              },
                                                            message:
                                                              "Contraseña y confirmar contraseña no coinciden",
                                                          },
                                                        ],
                                                        customizeItem:
                                                          function (item) {
                                                            if (
                                                              item.dataField ===
                                                                "confirmarContraseña" &&
                                                              userSel.idUsuario ==
                                                                null
                                                            ) {
                                                              item.validationRules =
                                                                [
                                                                  {
                                                                    type: "required",
                                                                  },
                                                                ];
                                                            }
                                                          },
                                                        label: {
                                                          text: getTrad(
                                                            "confirmarContraseña",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 3,
                                                      },
                                                      {
                                                        dataField: "email",
                                                        label: {
                                                          text: getTrad(
                                                            "email",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                          {
                                                            name: "email",
                                                            type: "email",
                                                          },
                                                          {
                                                            name: "email",
                                                            type: "async",
                                                            validationCallback:
                                                              function (
                                                                params,
                                                              ) {
                                                                return new Promise(
                                                                  (
                                                                    resolve,
                                                                    reject,
                                                                  ) => {
                                                                    let originalUser =
                                                                      $.grep(
                                                                        datasource_usuarios.items(),
                                                                        function (
                                                                          item,
                                                                        ) {
                                                                          return userSel.idUsuario !==
                                                                            null
                                                                            ? item.idUsuario ===
                                                                                userSel.idUsuario
                                                                            : false;
                                                                        },
                                                                      );

                                                                    $.when(
                                                                      userService.validateEmailUsuario(
                                                                        params.value,
                                                                      ),
                                                                    ).then(
                                                                      function (
                                                                        usuario,
                                                                      ) {
                                                                        let isEmail_inDB =
                                                                            usuario.isValid,
                                                                          isOriginalEmail =
                                                                            originalUser.length >
                                                                            0
                                                                              ? originalUser[0]
                                                                                  .email ===
                                                                                  params.value ||
                                                                                originalUser[0]
                                                                                  .usuario ===
                                                                                  params.value
                                                                              : false;

                                                                        if (
                                                                          isEmail_inDB &&
                                                                          !isOriginalEmail
                                                                        )
                                                                          reject();
                                                                        else
                                                                          resolve();
                                                                      },
                                                                    );
                                                                  },
                                                                );
                                                              },
                                                            message: getTrad(
                                                              "alert_correoDuplicado",
                                                            ),
                                                          },
                                                        ],
                                                        visibleIndex: 4,
                                                      },
                                                      {
                                                        dataField:
                                                          "idLocalizacion",
                                                        label: {
                                                          text: getTrad(
                                                            "localizacion",
                                                          ),
                                                        },
                                                        alignment: "left",
                                                        allowHeaderFiltering: false,
                                                        editorType:
                                                          "dxSelectBox",
                                                        editorOptions: {
                                                          elementAttr: {
                                                            id: "dxSelectBox_localizacion",
                                                          },
                                                          dataSource:
                                                            datasource_tblLocalizacion.items(),
                                                          displayExpr:
                                                            "denominacion",
                                                          valueExpr:
                                                            "idLocalizacion",
                                                          onValueChanged:
                                                            function (e) {
                                                              let dxTagBox_tblCategoriaInterna =
                                                                $(
                                                                  "#dxTagBox_tblCategoriaInterna",
                                                                ).dxTagBox(
                                                                  "instance",
                                                                );
                                                              let dxTagBox_tblCategoriaInterna_resumen =
                                                                $(
                                                                  "#dxTagBox_tblCategoriaInterna_resumen",
                                                                ).dxTagBox(
                                                                  "instance",
                                                                );
                                                              if (
                                                                e.value != null
                                                              ) {
                                                                let dataSource =
                                                                  $.extend(
                                                                    true,
                                                                    [],
                                                                    e.component.option(
                                                                      "dataSource",
                                                                    ),
                                                                  );
                                                                let selectedItem =
                                                                  $.grep(
                                                                    dataSource,
                                                                    function (
                                                                      item,
                                                                    ) {
                                                                      return (
                                                                        item.idLocalizacion ==
                                                                        e.value
                                                                      );
                                                                    },
                                                                  )[0];
                                                                let idPais =
                                                                  selectedItem.idPais;
                                                                let previousSelItem =
                                                                  $.grep(
                                                                    dataSource,
                                                                    function (
                                                                      item,
                                                                    ) {
                                                                      return (
                                                                        item.idLocalizacion ==
                                                                        e.previousValue
                                                                      );
                                                                    },
                                                                  )[0];

                                                                function setValueLocalizacion() {
                                                                  tblCategoriaInterna_items_filtrados =
                                                                    $.each(
                                                                      $.extend(
                                                                        true,
                                                                        [],
                                                                        tblCategoriaInterna_items,
                                                                      ),
                                                                      function (
                                                                        index,
                                                                        item,
                                                                      ) {
                                                                        item.items =
                                                                          $.grep(
                                                                            item.items,
                                                                            function (
                                                                              catConv,
                                                                            ) {
                                                                              return (
                                                                                catConv
                                                                                  .idCategoriaConvenioNavigation
                                                                                  .idPais ===
                                                                                idPais
                                                                              );
                                                                            },
                                                                          );
                                                                      },
                                                                    );

                                                                  tblCategoriaInterna_items_filtrados =
                                                                    $.grep(
                                                                      tblCategoriaInterna_items_filtrados,
                                                                      function (
                                                                        item,
                                                                      ) {
                                                                        return (
                                                                          item
                                                                            .items
                                                                            .length >
                                                                          0
                                                                        );
                                                                      },
                                                                    );

                                                                  if (
                                                                    $(
                                                                      "#dxTagBox_tblCategoriaInterna",
                                                                    ).data(
                                                                      "dxTagBox",
                                                                    )
                                                                  ) {
                                                                    dxTagBox_tblCategoriaInterna.option(
                                                                      "dataSource",
                                                                      tblCategoriaInterna_items_filtrados,
                                                                    );

                                                                    if (
                                                                      previousSelItem !=
                                                                        null &&
                                                                      idPais !=
                                                                        previousSelItem.idPais
                                                                    )
                                                                      dxTagBox_tblCategoriaInterna.option(
                                                                        "value",
                                                                        [],
                                                                      );
                                                                  }

                                                                  if (
                                                                    $(
                                                                      "#dxTagBox_tblCategoriaInterna_resumen",
                                                                    ).data(
                                                                      "dxTagBox",
                                                                    )
                                                                  ) {
                                                                    dxTagBox_tblCategoriaInterna_resumen.option(
                                                                      "dataSource",
                                                                      tblCategoriaInterna_items_filtrados,
                                                                    );

                                                                    if (
                                                                      previousSelItem !=
                                                                        null &&
                                                                      idPais !=
                                                                        previousSelItem.idPais
                                                                    )
                                                                      dxTagBox_tblCategoriaInterna_resumen.option(
                                                                        "value",
                                                                        [],
                                                                      );
                                                                  }
                                                                }

                                                                if (
                                                                  dxTagBox_tblCategoriaInterna.option(
                                                                    "value",
                                                                  ).length >
                                                                    0 &&
                                                                  previousSelItem !=
                                                                    null &&
                                                                  idPais !=
                                                                    previousSelItem.idPais
                                                                ) {
                                                                  dxMensajePregunta(
                                                                    "Se eliminarán las categorías internas seleccionadas. ¿Desea continuar?",
                                                                    [
                                                                      // ACEPTAR
                                                                      [
                                                                        getTrad(
                                                                          "aceptar",
                                                                        ),
                                                                        function () {
                                                                          setValueLocalizacion();
                                                                        },
                                                                        "normal",
                                                                        "btnAceptar",
                                                                      ],
                                                                      // CANCELAR
                                                                      [
                                                                        getTrad(
                                                                          "cancelar",
                                                                        ),
                                                                        function () {
                                                                          e.component.option(
                                                                            "value",
                                                                            e.previousValue,
                                                                          );
                                                                        },
                                                                        "normal",
                                                                        "btnCancelar",
                                                                      ],
                                                                    ],
                                                                  );
                                                                } else {
                                                                  setValueLocalizacion();
                                                                }
                                                              } else {
                                                                if (
                                                                  $(
                                                                    "#dxTagBox_tblCategoriaInterna",
                                                                  ).data(
                                                                    "dxTagBox",
                                                                  )
                                                                ) {
                                                                  dxTagBox_tblCategoriaInterna.option(
                                                                    "dataSource",
                                                                    [],
                                                                  );
                                                                  dxTagBox_tblCategoriaInterna.option(
                                                                    "value",
                                                                    [],
                                                                  );
                                                                }

                                                                if (
                                                                  $(
                                                                    "#dxTagBox_tblCategoriaInterna_resumen",
                                                                  ).data(
                                                                    "dxTagBox",
                                                                  )
                                                                ) {
                                                                  dxTagBox_tblCategoriaInterna_resumen.option(
                                                                    "dataSource",
                                                                    [],
                                                                  );
                                                                  dxTagBox_tblCategoriaInterna_resumen.option(
                                                                    "value",
                                                                    [],
                                                                  );
                                                                }
                                                              }
                                                            },
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                        ],
                                                        visibleIndex: 5,
                                                      },
                                                      {
                                                        dataField: "idIdioma",
                                                        editorType:
                                                          "dxSelectBox",
                                                        editorOptions: {
                                                          dataSource:
                                                            datasource_idiomas.items(),
                                                          valueExpr: "idIdioma",
                                                          displayExpr:
                                                            "denominacion",
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "idioma",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                        ],
                                                        visibleIndex: 6,
                                                      },
                                                      {
                                                        dataField: "idCompañia",
                                                        editorType: "dxLookup",
                                                        name: "idCompañia",
                                                        allowHeaderFiltering: false,
                                                        editorOptions: {
                                                          dataSource:
                                                            datasource_tblCompañia.items(),
                                                          displayExpr:
                                                            "denominacion",
                                                          valueExpr:
                                                            "idCompañia",
                                                          closeOnOutsideClick: true,
                                                          showPopupTitle: false,
                                                          showCancelButton: false,
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "compañia",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        allowEditing: false,
                                                        validationRules: [
                                                          { type: "required" },
                                                        ],
                                                        visibleIndex: 7,
                                                      },
                                                      {
                                                        dataField: "idCargo",
                                                        editorType:
                                                          "dxSelectBox",
                                                        editorOptions: {
                                                          valueExpr: "idCargo",
                                                          displayExpr:
                                                            "denominacion",
                                                          onValueChanged:
                                                            function (e) {
                                                              if (
                                                                e.value !== null
                                                              ) {
                                                                if (
                                                                  $(
                                                                    "#dxTabPanel_principal",
                                                                  )
                                                                    .dxTabPanel(
                                                                      "instance",
                                                                    )
                                                                    .option(
                                                                      "selectedIndex",
                                                                    ) === 1
                                                                ) {
                                                                  function changeFormularios_cargoUser(
                                                                    idCargo,
                                                                  ) {
                                                                    var dxAccordion_formularioVisibles =
                                                                      $(
                                                                        "#dxAccordion_formularioVisibles",
                                                                      ).dxAccordion(
                                                                        "instance",
                                                                      );
                                                                    if (
                                                                      idCargo ===
                                                                        1 ||
                                                                      idCargo ===
                                                                        2
                                                                    ) {
                                                                      //SI ES DESARROLLADOR O MASTER SE CARGA GESTIÓN INTERNA;
                                                                      datasource_formulariosNApartado
                                                                        .load()
                                                                        .done(
                                                                          function () {
                                                                            $.each(
                                                                              userSel.tblFormulario,
                                                                              function (
                                                                                index,
                                                                                item,
                                                                              ) {
                                                                                $.each(
                                                                                  item.tblFormulario,
                                                                                  function (
                                                                                    indexForm,
                                                                                    itemForm,
                                                                                  ) {
                                                                                    itemForm.isEscritura = true;
                                                                                    itemForm.isBorrado = true;
                                                                                  },
                                                                                );
                                                                              },
                                                                            );

                                                                            filtrar_formulariosApp(
                                                                              idAplicacionSel,
                                                                            );

                                                                            dxAccordion_formularioVisibles.option(
                                                                              {
                                                                                dataSource:
                                                                                  userSel.tblFormulario,
                                                                                selectedIndex:
                                                                                  -1,
                                                                              },
                                                                            );
                                                                            checkFormularioInicio();
                                                                            dxAccordion_formularioVisibles.repaint();
                                                                            $(
                                                                              "#dxButtonGroup_formularioVisibles",
                                                                            )
                                                                              .dxButtonGroup(
                                                                                "instance",
                                                                              )
                                                                              .repaint();
                                                                          },
                                                                        );
                                                                    } else {
                                                                      datasource_tblFormularioNCargo
                                                                        .load()
                                                                        .done(
                                                                          function (
                                                                            formNCargo,
                                                                          ) {
                                                                            dxAccordion_formularioVisibles.option(
                                                                              {
                                                                                selectedIndex:
                                                                                  -1,
                                                                              },
                                                                            );

                                                                            let isFormRRHH = false;
                                                                            // SI SE CAMBIA EL CARGO, LOS PERMISOS SE CAMBIAN
                                                                            $.each(
                                                                              userSel.tblFormulario,
                                                                              function (
                                                                                index,
                                                                                apartado,
                                                                              ) {
                                                                                $.each(
                                                                                  apartado.tblFormulario,
                                                                                  function (
                                                                                    index2,
                                                                                    item,
                                                                                  ) {
                                                                                    if (
                                                                                      $.inArray(
                                                                                        item.idFormulario,
                                                                                        formNCargo,
                                                                                      ) !==
                                                                                      -1
                                                                                    ) {
                                                                                      isFormRRHH =
                                                                                        item.idApartado ===
                                                                                        5;
                                                                                      item.isEscritura = true;
                                                                                      item.isBorrado = true;
                                                                                    } else {
                                                                                      item.isEscritura = false;
                                                                                      item.isBorrado = false;
                                                                                    }
                                                                                  },
                                                                                );
                                                                              },
                                                                            );

                                                                            if (
                                                                              !isFormRRHH
                                                                            ) {
                                                                              userSel.tblCentroTrabajo =
                                                                                [];
                                                                              $(
                                                                                "#dxList_centrosTrabajo",
                                                                              )
                                                                                .dxList(
                                                                                  "instance",
                                                                                )
                                                                                .option(
                                                                                  "selectedItemKeys",
                                                                                  [],
                                                                                );
                                                                            }

                                                                            let isUserLavanderia =
                                                                              userSel.subtipoUsuario ===
                                                                                1 ||
                                                                              userSel.subtipoUsuario ===
                                                                                3;
                                                                            dxNavbar_disableItems(
                                                                              isUserLavanderia,
                                                                            );

                                                                            filtrar_formulariosApp(
                                                                              idAplicacionSel,
                                                                            );
                                                                            checkFormularioInicio();
                                                                            dxAccordion_formularioVisibles.repaint();
                                                                            $(
                                                                              "#dxButtonGroup_formularioVisibles",
                                                                            )
                                                                              .dxButtonGroup(
                                                                                "instance",
                                                                              )
                                                                              .repaint();
                                                                          },
                                                                        );
                                                                    }
                                                                  }

                                                                  let dxSwitch_subtipoUser =
                                                                    $(
                                                                      "#dxSwitch_subtipoUser",
                                                                    ).dxSwitch(
                                                                      "instance",
                                                                    );
                                                                  if (
                                                                    $(
                                                                      "#dxSwitch_subtipoUser",
                                                                    ).data(
                                                                      "dxSwitch",
                                                                    ) != null &&
                                                                    dxSwitch_subtipoUser.option(
                                                                      "isImportandoUsuario",
                                                                    ) == false
                                                                  ) {
                                                                    if (
                                                                      is_FormSelected() &&
                                                                      isImportandoUsuario ===
                                                                        false
                                                                    ) {
                                                                      //tiene campos seleccionados
                                                                      dxMensajePregunta(
                                                                        getTrad(
                                                                          "preg_PerderCambios",
                                                                        ),
                                                                        [
                                                                          // ACEPTAR
                                                                          [
                                                                            getTrad(
                                                                              "aceptar",
                                                                            ),
                                                                            function () {
                                                                              changeFormularios_cargoUser(
                                                                                e.value,
                                                                              );
                                                                            },
                                                                            "normal",
                                                                            "btnAceptar",
                                                                          ],
                                                                          // CANCELAR
                                                                          [
                                                                            getTrad(
                                                                              "cancelar",
                                                                            ),
                                                                            function () {
                                                                              e.component.option(
                                                                                "value",
                                                                                e.previousValue,
                                                                              );
                                                                            },
                                                                            "normal",
                                                                            "btnCancelar",
                                                                          ],
                                                                        ],
                                                                      );
                                                                    } else {
                                                                      changeFormularios_cargoUser(
                                                                        e.value,
                                                                      );
                                                                    }
                                                                    isImportandoUsuario = false;
                                                                  }
                                                                }
                                                              }
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "cargo",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        validationRules: [
                                                          { type: "required" },
                                                        ],
                                                        visibleIndex: 8,
                                                      },
                                                      {
                                                        dataField:
                                                          "detallesCargo",
                                                        label: {
                                                          text: getTrad(
                                                            "detallesCargo",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 9,
                                                      },
                                                      {
                                                        dataField:
                                                          "idFormularioInicio",
                                                        editorType:
                                                          "dxDropDownBox",
                                                        editorOptions: {
                                                          elementAttr: {
                                                            id: "dxDropDownBox_formularioInicio",
                                                          },
                                                          deferRendering: false,
                                                          dataSource:
                                                            dataSource_formularios,
                                                          valueExpr:
                                                            "idFormulario",
                                                          displayExpr:
                                                            "denominacion",
                                                          width: "auto",
                                                          showClearButton: true,
                                                          placeholder: getTrad(
                                                            "seleccioneFormulario",
                                                          ),
                                                          dropDownOptions: {
                                                            width: 485,
                                                            height: 550,
                                                            maxHeight: null,
                                                            position: {
                                                              my: "center",
                                                              at: "center",
                                                              of: window,
                                                            },
                                                            closeOnOutsideClick: true,
                                                          },
                                                          contentTemplate:
                                                            function (
                                                              templateData,
                                                              contentElement,
                                                            ) {
                                                              var scrollView =
                                                                $(
                                                                  "<div id='dxScrollView_formulariosInicio'>",
                                                                );
                                                              let dxDataGrid_formulariosNApartado_inicio =
                                                                $(
                                                                  "<div id='dxDataGrid_formulariosNApartado_inicio' />",
                                                                ).dxDataGrid({
                                                                  columnsAutoWidth: true,
                                                                  hoverStateEnabled: true,
                                                                  paging: {
                                                                    enabled: false,
                                                                  },
                                                                  keyExpr: [
                                                                    "idFormulario",
                                                                  ],
                                                                  filterRow: {
                                                                    visible: true,
                                                                  },
                                                                  grouping: {
                                                                    autoExpandAll: true,
                                                                  },
                                                                  loadPanel: {
                                                                    enabled: false,
                                                                  },
                                                                  groupPanel: {
                                                                    visible: false,
                                                                  },
                                                                  selection: {
                                                                    mode: "single",
                                                                  },
                                                                  selectedRowKeys:
                                                                    userSel
                                                                      ? userSel.idFormularioInicio
                                                                      : null,
                                                                  noDataText:
                                                                    getTrad(
                                                                      "debeSelFormVisible",
                                                                    ),
                                                                  sorting: {
                                                                    mode: "none",
                                                                  },
                                                                  height:
                                                                    "100%",
                                                                  columns: [
                                                                    {
                                                                      dataField:
                                                                        "idApartado",
                                                                      calculateDisplayValue:
                                                                        "denoApartado",
                                                                      groupCellTemplate:
                                                                        function (
                                                                          cellElement,
                                                                          cellInfo,
                                                                        ) {
                                                                          cellElement.append(
                                                                            $(
                                                                              "<span>",
                                                                            ).text(
                                                                              cellInfo.value,
                                                                            ),
                                                                          );
                                                                        },
                                                                      groupIndex: 0,
                                                                    },
                                                                    {
                                                                      dataField:
                                                                        "denoFormulario",
                                                                      caption:
                                                                        getTrad(
                                                                          "formularios",
                                                                        ),
                                                                    },
                                                                  ],
                                                                  onContentReady:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      e.component.selectRows(
                                                                        templateData.component.option(
                                                                          "value",
                                                                        ),
                                                                      );
                                                                    },
                                                                  onSelectionChanged:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      if (
                                                                        e
                                                                          .selectedRowsData
                                                                          .length >
                                                                        0
                                                                      )
                                                                        templateData.component.option(
                                                                          "value",
                                                                          e
                                                                            .selectedRowsData[0]
                                                                            .idFormulario,
                                                                        );
                                                                    },
                                                                  onRowPrepared:
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      if (
                                                                        e.rowType ===
                                                                        "data"
                                                                      )
                                                                        e.rowElement.css(
                                                                          "cursor",
                                                                          "pointer",
                                                                        );
                                                                    },
                                                                  //Estilos
                                                                  showColumnLines: false,
                                                                  showRowLines: true,
                                                                });

                                                              var dxButtonGroup_formInicio_scrollview =
                                                                $(
                                                                  "<div id='dxButtonGroup_formInicio_scrollview'>",
                                                                );
                                                              let dxButtonGroup_formulariosInicio =
                                                                $(
                                                                  "<div id='dxButtonGroup_formulariosInicio' style='padding-bottom:12px;'>",
                                                                ).dxButtonGroup(
                                                                  {
                                                                    width:
                                                                      "100%",
                                                                    items:
                                                                      dataSource_aplicaciones.items(),
                                                                    keyExpr:
                                                                      "idAplicacion",
                                                                    selectionMode:
                                                                      "single",
                                                                    buttonTemplate:
                                                                      function (
                                                                        itemData,
                                                                        $buttonContent,
                                                                      ) {
                                                                        $buttonContent
                                                                          .parent()
                                                                          .css(
                                                                            "--buttonGroup_color",
                                                                            itemData.color,
                                                                          );

                                                                        return $(
                                                                          "<div />",
                                                                        )
                                                                          .addClass(
                                                                            "he-100",
                                                                          )
                                                                          .append(
                                                                            $(
                                                                              "<div>",
                                                                            )
                                                                              .addClass(
                                                                                "d-flex align-items-center he-100",
                                                                              )
                                                                              .append(
                                                                                $(
                                                                                  "<div />",
                                                                                )
                                                                                  .addClass(
                                                                                    "w-100 d-flex flex-column align-items-center",
                                                                                  )
                                                                                  .append(
                                                                                    "<i class='" +
                                                                                      itemData.icon +
                                                                                      " font-size-xxl flex-fill' ></i>",
                                                                                    $(
                                                                                      "<span style='line-height:20px;' class='text-break he-100 flex-fill text-center text-break'>" +
                                                                                        itemData
                                                                                          .tblTraduccion[
                                                                                          IDIOMA
                                                                                            .codigo
                                                                                        ] +
                                                                                        "</span>",
                                                                                    ),
                                                                                  ),
                                                                              ),
                                                                          );
                                                                      },
                                                                    onSelectionChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        if (
                                                                          e
                                                                            .addedItems
                                                                            .length >
                                                                          0
                                                                        ) {
                                                                          idAplicacionSel_formInicio =
                                                                            e
                                                                              .addedItems[0]
                                                                              .idAplicacion;
                                                                          let formularios =
                                                                            [];
                                                                          if (
                                                                            userSel
                                                                          ) {
                                                                            filtrar_formulariosApp(
                                                                              idAplicacionSel_formInicio,
                                                                            );
                                                                            $.each(
                                                                              userSel.tblFormulario,
                                                                              function (
                                                                                index,
                                                                                item,
                                                                              ) {
                                                                                let hasForm_visible = false;
                                                                                $.each(
                                                                                  item.tblFormulario,
                                                                                  function (
                                                                                    i2,
                                                                                    item2,
                                                                                  ) {
                                                                                    if (
                                                                                      item2.idAplicacion ==
                                                                                        idAplicacionSel_formInicio &&
                                                                                      (item2.isEscritura ||
                                                                                        item2.isBorrado)
                                                                                    ) {
                                                                                      if (
                                                                                        item2.idFormulario !=
                                                                                          41 &&
                                                                                        item2.idFormulario !=
                                                                                          63
                                                                                      ) {
                                                                                        // No formulario Personal ni calendario laboral
                                                                                        formularios.push(
                                                                                          {
                                                                                            idApartado:
                                                                                              item.idApartado,
                                                                                            denoApartado:
                                                                                              item.denominacion,
                                                                                            idFormulario:
                                                                                              item2.idFormulario,
                                                                                            denoFormulario:
                                                                                              item2.denominacion,
                                                                                          },
                                                                                        );
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                );
                                                                                if (
                                                                                  item.idApartado !==
                                                                                  1
                                                                                )
                                                                                  item.visible =
                                                                                    hasForm_visible;
                                                                              },
                                                                            );
                                                                          }

                                                                          $(
                                                                            "#dxDataGrid_formulariosNApartado_inicio",
                                                                          )
                                                                            .dxDataGrid(
                                                                              "instance",
                                                                            )
                                                                            .option(
                                                                              {
                                                                                dataSource:
                                                                                  {
                                                                                    store:
                                                                                      {
                                                                                        type: "array",
                                                                                        key: "idFormulario",
                                                                                        data: formularios,
                                                                                      },
                                                                                  },
                                                                              },
                                                                            );
                                                                        }
                                                                      },
                                                                  },
                                                                );

                                                              dxButtonGroup_formulariosInicio.appendTo(
                                                                dxButtonGroup_formInicio_scrollview,
                                                              );
                                                              dxButtonGroup_formInicio_scrollview.dxScrollView(
                                                                {
                                                                  width: "100%",
                                                                  direction:
                                                                    "horizontal",
                                                                  showScrollbar:
                                                                    "always",
                                                                },
                                                              );
                                                              contentElement.append(
                                                                dxButtonGroup_formInicio_scrollview,
                                                              );

                                                              dxDataGrid_formulariosNApartado_inicio.appendTo(
                                                                scrollView,
                                                              );
                                                              scrollView.dxScrollView(
                                                                {
                                                                  width: "100%",
                                                                  direction:
                                                                    "vertical",
                                                                  showScrollbar:
                                                                    "always",
                                                                },
                                                              );
                                                              contentElement.append(
                                                                scrollView,
                                                              );

                                                              templateData.component.on(
                                                                "valueChanged",
                                                                function (
                                                                  args,
                                                                ) {
                                                                  if (
                                                                    args.value
                                                                  )
                                                                    args.component.close();
                                                                },
                                                              );
                                                            },
                                                          onFocusIn: function (
                                                            e,
                                                          ) {
                                                            var dataSource = [];
                                                            $.each(
                                                              dataSource_formularios.items(),
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                $.each(
                                                                  userSel.tblFormulario,
                                                                  function (
                                                                    iApartado,
                                                                    apartado,
                                                                  ) {
                                                                    $.each(
                                                                      apartado.tblFormulario,
                                                                      function (
                                                                        iFormulario,
                                                                        formulario,
                                                                      ) {
                                                                        if (
                                                                          item.idFormulario ===
                                                                            formulario.idFormulario &&
                                                                          (formulario.isEscritura ||
                                                                            formulario.isBorrado) &&
                                                                          item.idFormulario !=
                                                                            41 &&
                                                                          item.idFormulario !=
                                                                            63
                                                                        ) {
                                                                          // No formulario Personal ni calendario laboral
                                                                          dataSource.push(
                                                                            {
                                                                              idApartado:
                                                                                apartado.idApartado,
                                                                              denoApartado:
                                                                                apartado.denominacion,
                                                                              idFormulario:
                                                                                formulario.idFormulario,
                                                                              denoFormulario:
                                                                                formulario.denominacion,
                                                                            },
                                                                          );
                                                                        }
                                                                      },
                                                                    );
                                                                  },
                                                                );
                                                              },
                                                            );

                                                            $(
                                                              "#dxDataGrid_formulariosNApartado_inicio",
                                                            )
                                                              .dxDataGrid(
                                                                "instance",
                                                              )
                                                              .option({
                                                                dataSource: {
                                                                  store: {
                                                                    type: "array",
                                                                    key: "idFormulario",
                                                                    data: dataSource,
                                                                  },
                                                                },
                                                              });

                                                            let formulario =
                                                              $.grep(
                                                                dataSource_formularios.items(),
                                                                function (
                                                                  item,
                                                                ) {
                                                                  return (
                                                                    item.idFormulario ==
                                                                    userSel.idFormularioInicio
                                                                  );
                                                                },
                                                              );
                                                            let idAplicacion =
                                                              formulario.length >
                                                              0
                                                                ? formulario[0]
                                                                    .idAplicacion
                                                                : 1;
                                                            $(
                                                              "#dxButtonGroup_formulariosInicio",
                                                            )
                                                              .dxButtonGroup(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "selectedItemKeys",
                                                                [idAplicacion],
                                                              );
                                                          },
                                                          onOpened: function (
                                                            e,
                                                          ) {
                                                            $(
                                                              "#dxButtonGroup_formInicio_scrollview",
                                                            )
                                                              .dxScrollView(
                                                                "instance",
                                                              )
                                                              .scrollTo(0);
                                                            $(
                                                              "#dxScrollView_formulariosInicio",
                                                            )
                                                              .dxScrollView(
                                                                "instance",
                                                              )
                                                              .scrollTo(0);
                                                            filtrar_formulariosApp(
                                                              idAplicacionSel_formInicio,
                                                            );

                                                            //#region resize ScrollView formularios inicio
                                                            let height_dxButtonGroup =
                                                              $(
                                                                "#dxButtonGroup_formulariosInicio",
                                                              ).height() + 12; //12 padding
                                                            let parent = $(
                                                              "#dxScrollView_formulariosInicio",
                                                            )
                                                              .parent()
                                                              .height();
                                                            $(
                                                              "#dxScrollView_formulariosInicio",
                                                            ).height(
                                                              parent -
                                                                height_dxButtonGroup,
                                                            );
                                                            //#endregion
                                                          },
                                                          onClosed: function (
                                                            e,
                                                          ) {
                                                            $(
                                                              "#dxButtonGroup_formulariosInicio",
                                                            )
                                                              .dxButtonGroup(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "selectedItemKeys",
                                                                [],
                                                              );
                                                          },
                                                          onOptionChanged:
                                                            function (e) {
                                                              if (
                                                                e.name ===
                                                                "dataSource"
                                                              )
                                                                $(
                                                                  "#dxDataGrid_formulariosNApartado_inicio",
                                                                )
                                                                  .dxDataGrid(
                                                                    "instance",
                                                                  )
                                                                  .refresh();
                                                            },
                                                          onValueChanged:
                                                            function (e) {
                                                              if (userSel)
                                                                userSel.idFormularioInicio =
                                                                  e.value;
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "formularioInicio",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 10,
                                                      },
                                                      {
                                                        dataField:
                                                          "idLavanderiaInicio",
                                                        visible:
                                                          userSel !== null
                                                            ? userSel.subtipoUsuario ===
                                                                1 ||
                                                              userSel.subtipoUsuario ===
                                                                3
                                                            : false,
                                                        editorType:
                                                          "dxSelectBox",
                                                        editorOptions: {
                                                          elementAttr: {
                                                            id: "dxSelectBox_lavanderiaInicio",
                                                          },
                                                          valueExpr:
                                                            "idLavanderia",
                                                          displayExpr:
                                                            "denominacion",
                                                          onOpened: function (
                                                            e,
                                                          ) {
                                                            e.component.option(
                                                              "dataSource",
                                                              getDenoLavActivas(),
                                                            );
                                                          },
                                                          onSelectionChanged:
                                                            function (e) {
                                                              if (userSel) {
                                                                userSel.idLavanderiaInicio =
                                                                  e.selectedItem
                                                                    ? e
                                                                        .selectedItem
                                                                        .idLavanderia
                                                                    : null;
                                                                e.component.option(
                                                                  "value",
                                                                  userSel.idLavanderiaInicio,
                                                                );
                                                              } else
                                                                e.component.option(
                                                                  "value",
                                                                  null,
                                                                );
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "lavanderiaInicio",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 11,
                                                      },
                                                      {
                                                        name: "importarPermisos",
                                                        itemType: "button",
                                                        verticalAlignment:
                                                          "bottom",
                                                        horizontalAlignment:
                                                          "left",
                                                        buttonOptions: {
                                                          width: "100%",
                                                          text: getTrad(
                                                            "importarPermisos",
                                                          ),
                                                          type: "normal",
                                                          onClick: function (
                                                            e,
                                                          ) {
                                                            $(
                                                              "#dxPopup_importarPermisos",
                                                            )
                                                              .dxPopup(
                                                                "instance",
                                                              )
                                                              .show();
                                                          },
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 12,
                                                      },
                                                      {
                                                        dataField:
                                                          "isGestionaUsuarios",
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          elementAttr: {
                                                            id: "dxSwitch_isGestionaUsuarios",
                                                          },
                                                          switchedOnText:
                                                            getTrad("gestiona"),
                                                          switchedOffText:
                                                            getTrad(
                                                              "noGestiona",
                                                            ),
                                                          onValueChanged:
                                                            function (e) {
                                                              if (
                                                                userSel !== null
                                                              ) {
                                                                $.each(
                                                                  userSel.tblFormulario,
                                                                  function (
                                                                    iApartado,
                                                                    apartado,
                                                                  ) {
                                                                    $.each(
                                                                      apartado.tblFormulario,
                                                                      function (
                                                                        iFormulario,
                                                                        formulario,
                                                                      ) {
                                                                        if (
                                                                          formulario.idFormulario ===
                                                                          21
                                                                        ) {
                                                                          formulario.isEscritura =
                                                                            e.value;
                                                                          formulario.isBorrado =
                                                                            e.value;
                                                                        }
                                                                      },
                                                                    );
                                                                  },
                                                                );
                                                              }
                                                            },
                                                        },
                                                        label: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          text: getTrad(
                                                            "gestionaUsuarios",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 13,
                                                      },
                                                      {
                                                        dataField:
                                                          "enableDatosRRHH",
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          switchedOnText:
                                                            getTrad("activo"),
                                                          switchedOffText:
                                                            getTrad("noActivo"),
                                                        },
                                                        label: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          text: getTrad(
                                                            "enableDatosRRHH",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 14,
                                                      },
                                                      {
                                                        dataField: "refactura",
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                          switchedOnText:
                                                            getTrad("activo"),
                                                          switchedOffText:
                                                            getTrad("noActivo"),
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                        },
                                                        label: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          text: getTrad(
                                                            "refactura",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 15,
                                                      },
                                                      {
                                                        dataField:
                                                          "isDepartamentoControl",
                                                        editorType: "dxSwitch",
                                                        editorOptions: {
                                                          switchedOnText:
                                                            getTrad("activo"),
                                                          switchedOffText:
                                                            getTrad("noActivo"),
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                        },
                                                        label: {
                                                          visible:
                                                            estatusUsuario ===
                                                            0,
                                                          text: getTrad(
                                                            "departamentoControl",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 16,
                                                      },
                                                      {
                                                        dataField:
                                                          "fechaCreacion",
                                                        template: function (
                                                          data,
                                                          itemElement,
                                                        ) {
                                                          let value =
                                                              userSel.fechaCreacion,
                                                            valueVisible = null;
                                                          if (
                                                            value !== null &&
                                                            typeof value !==
                                                              "undefined"
                                                          ) {
                                                            let valor =
                                                              value.split(" ");
                                                            let fecha =
                                                              new Date(
                                                                valor[0] +
                                                                  "T" +
                                                                  valor[1],
                                                              );
                                                            let time =
                                                              leadingZero(
                                                                fecha.getHours(),
                                                              ) +
                                                              ":" +
                                                              leadingZero(
                                                                fecha.getMinutes(),
                                                              );
                                                            valueVisible =
                                                              formatDate(
                                                                fecha,
                                                              ) +
                                                              " - " +
                                                              time +
                                                              (valor[2] !==
                                                              "+00:00"
                                                                ? " (" +
                                                                  valor[2] +
                                                                  ")"
                                                                : "");
                                                          }
                                                          itemElement
                                                            .append(
                                                              "<div id='dxTextBox_fechaCreacion'>",
                                                            )
                                                            .dxTextBox({
                                                              value:
                                                                valueVisible,
                                                              readOnly: true,
                                                            });
                                                        },
                                                        visible:
                                                          userSel !== null
                                                            ? userSel.idUsuario !==
                                                              null
                                                            : false,
                                                        label: {
                                                          text: getTrad(
                                                            "fechaCreacion",
                                                          ),
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 17,
                                                      },
                                                      {
                                                        name: "dxButton_reenviarCorreo",
                                                        itemType: "button",
                                                        visible:
                                                          userSel !== null
                                                            ? userSel.ultimaConexion !==
                                                              null
                                                            : false,
                                                        verticalAlignment:
                                                          "bottom",
                                                        horizontalAlignment:
                                                          "left",
                                                        buttonOptions: {
                                                          width: "100%",
                                                          text: getTrad(
                                                            "reenviarCorreoContraseña",
                                                          ),
                                                          type: "normal",
                                                          onClick: function (
                                                            e,
                                                          ) {
                                                            let originalUser =
                                                              $.grep(
                                                                datasource_usuarios.items(),
                                                                function (
                                                                  item,
                                                                ) {
                                                                  return userSel.idUsuario !==
                                                                    null
                                                                    ? item.idUsuario ===
                                                                        userSel.idUsuario
                                                                    : false;
                                                                },
                                                              );
                                                            $.when(
                                                              userService.validateEmailUsuario(
                                                                userSel.email,
                                                              ),
                                                            ).then(
                                                              function (
                                                                usuario,
                                                              ) {
                                                                let isUsuario_inDB =
                                                                    usuario.isValid,
                                                                  isOriginalUsuario =
                                                                    originalUser.length >
                                                                    0
                                                                      ? originalUser[0]
                                                                          .email ===
                                                                          userSel.email ||
                                                                        originalUser[0]
                                                                          .usuario ===
                                                                          userSel.email
                                                                      : false;

                                                                let isEmail_valid = true;
                                                                $.each(
                                                                  $(
                                                                    "#dxForm_datosUser",
                                                                  )
                                                                    .dxForm(
                                                                      "instance",
                                                                    )
                                                                    .validate()
                                                                    .brokenRules,
                                                                  function (
                                                                    index,
                                                                    item,
                                                                  ) {
                                                                    if (
                                                                      item.name ===
                                                                      "email"
                                                                    )
                                                                      isEmail_valid = false;
                                                                  },
                                                                );
                                                                if (
                                                                  (!isUsuario_inDB ||
                                                                    (isUsuario_inDB &&
                                                                      isOriginalUsuario)) &&
                                                                  isEmail_valid
                                                                ) {
                                                                  reenviarCorreoContraseña();
                                                                }
                                                              },
                                                            );
                                                          },
                                                        },
                                                        colSpan: 1,
                                                        visibleIndex: 18,
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    itemType: "group",
                                                    name: "grupo_asociarPersona",
                                                    colCount: 3,
                                                    visibleIndex: 2,
                                                    items: [
                                                      {
                                                        name: "dxButton_asociarPersona",
                                                        verticalAlignment:
                                                          "bottom",
                                                        horizontalAlignment:
                                                          "left",
                                                        template: function (
                                                          data,
                                                          itemElement,
                                                        ) {
                                                          itemElement
                                                            .parent()
                                                            .parent()
                                                            .css(
                                                              "justify-content",
                                                              "flex-end",
                                                            );
                                                          itemElement.append(
                                                            $(
                                                              "<div />",
                                                            ).dxButton({
                                                              icon: "plus",
                                                              text: getTrad(
                                                                "asociarPersona",
                                                              ),
                                                              width: "100%",
                                                              onClick: (e) => {
                                                                $(
                                                                  "#dxPopup_asociarPersona",
                                                                )
                                                                  .dxPopup(
                                                                    "instance",
                                                                  )
                                                                  .show();
                                                              },
                                                            }),
                                                          );
                                                        },
                                                        colSpan: 1,
                                                      },
                                                      {
                                                        dataField: "idPersona",
                                                        editorType:
                                                          "dxSelectBox",
                                                        editorOptions: {
                                                          readOnly: true,
                                                          elementAttr: {
                                                            id: "dxSelectBox_asociarPersona",
                                                          },
                                                          displayExpr:
                                                            "nombreCompuesto",
                                                          valueExpr:
                                                            "idPersona",
                                                          placeholder: "",
                                                          onValueChanged:
                                                            function (e) {
                                                              if (
                                                                userSel !==
                                                                  null &&
                                                                $.isEmptyObject(
                                                                  userSel.tblPersona,
                                                                )
                                                              )
                                                                setDataSource_asociarPersona(
                                                                  e.value,
                                                                );
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "personaAsociada",
                                                          ),
                                                        },
                                                        colSpan: 2,
                                                      },
                                                    ],
                                                  },
                                                  {
                                                    itemType: "group",
                                                    name: "group_idCategoriaInterna",
                                                    colCount: 3,
                                                    visibleIndex: 2,
                                                    items: [
                                                      {
                                                        dataField:
                                                          "tblCategoriaInterna",
                                                        editorType: "dxTagBox",
                                                        editorOptions: {
                                                          elementAttr: {
                                                            id: "dxTagBox_tblCategoriaInterna",
                                                          },
                                                          dataSource:
                                                            tblCategoriaInterna_items_filtrados,
                                                          value: userSel
                                                            ? userSel.tblCategoriaInterna
                                                            : [],
                                                          valueExpr:
                                                            "idCategoriaInterna",
                                                          displayExpr:
                                                            "denominacion",
                                                          grouped: true,
                                                          showSelectionControls: true,
                                                          onContentReady:
                                                            function (e) {
                                                              e.component
                                                                .content()
                                                                .attr(
                                                                  "id",
                                                                  "dxPopup_dxTagBox_idCategoriaInterna",
                                                                );
                                                            },
                                                          onOptionChanged:
                                                            function (e) {
                                                              if (
                                                                e.name ==
                                                                  "opened" &&
                                                                userSel &&
                                                                userSel.idLocalizacion ==
                                                                  null
                                                              ) {
                                                                e.component.option(
                                                                  "opened",
                                                                  false,
                                                                );
                                                                const editor =
                                                                  $(
                                                                    "#dxForm_datosUser",
                                                                  )
                                                                    .dxForm(
                                                                      "instance",
                                                                    )
                                                                    .getEditor(
                                                                      "idLocalizacion",
                                                                    );
                                                                const validator =
                                                                  editor
                                                                    .element()
                                                                    .dxValidator(
                                                                      "instance",
                                                                    );
                                                                validator.validate();
                                                                editor.open();

                                                                notify({
                                                                  message:
                                                                    "Debe selecciona una localización para acceder a las categorías internas.",
                                                                  type: "error",
                                                                  displayTime:
                                                                    "1500",
                                                                  closeOnClick: true,
                                                                });
                                                              } else if (
                                                                userSel &&
                                                                e.name ==
                                                                  "value"
                                                              ) {
                                                                let mapedValues =
                                                                  $.map(
                                                                    e.value,
                                                                    function (
                                                                      item,
                                                                    ) {
                                                                      return {
                                                                        idCategoriaInterna:
                                                                          item,
                                                                      };
                                                                    },
                                                                  );
                                                                userSel.tblCategoriaInterna =
                                                                  mapedValues;
                                                              }
                                                            },
                                                        },
                                                        label: {
                                                          text: getTrad(
                                                            "categoriaInterna",
                                                          ),
                                                        },
                                                        colSpan: 3,
                                                      },
                                                    ],
                                                  },
                                                ],
                                                //#endregion
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        itemType: "group",
                                        caption: getTrad("formulariosVisibles"),
                                        name: "formulariosVisibles",
                                        colCount: 1,
                                        colSpan: 1,
                                        height: "100%",
                                        cssClass: "pb-0",
                                        items: [
                                          {
                                            colSpan: 1,
                                            template: function (data, element) {
                                              $(element).css({
                                                display: "flex",
                                                "flex-direction": "column",
                                              });

                                              var scrollView = $(
                                                "<div id='dxScrollView_formulariosNUsuario'>",
                                              );
                                              var dxAccordion = $(
                                                "<div id='dxAccordion_formularioVisibles' style='padding-right:15px;'>",
                                              );
                                              var dxButtonGroup = $(
                                                "<div id='dxButtonGroup_formularioVisibles' style='padding-right:15px; padding-bottom:12px;'>",
                                              );
                                              var dxScrollView_dxButtonGroup =
                                                $(
                                                  "<div id='dxScrollView_dxButtonGroup_formulariosNUsuario'>",
                                                );
                                              dxButtonGroup.appendTo(
                                                dxScrollView_dxButtonGroup,
                                              );
                                              dxScrollView_dxButtonGroup.appendTo(
                                                element,
                                              );
                                              dxAccordion.appendTo(scrollView);
                                              scrollView.dxScrollView({
                                                width: "100%",
                                                direction: "vertical",
                                                showScrollbar: "always",
                                              });

                                              dxScrollView_dxButtonGroup.dxScrollView(
                                                {
                                                  width: "100%",
                                                  direction: "horizontal",
                                                  showScrollbar: "always",
                                                },
                                              );
                                              dxButtonGroup.dxButtonGroup({
                                                height: "100%",
                                                width: "100%",
                                                items:
                                                  dataSource_aplicaciones.items(),
                                                keyExpr: "idAplicacion",
                                                selectionMode: "single",
                                                buttonTemplate: function (
                                                  itemData,
                                                  $buttonContent,
                                                ) {
                                                  $buttonContent
                                                    .parent()
                                                    .css(
                                                      "--buttonGroup_color",
                                                      itemData.color,
                                                    );

                                                  return $("<div />")
                                                    .addClass("he-100")
                                                    .append(
                                                      $("<div>")
                                                        .addClass(
                                                          "d-flex align-items-center he-100",
                                                        )
                                                        .append(
                                                          $(
                                                            "<div id='dxCheckBox_aplicacion_" +
                                                              itemData.idAplicacion +
                                                              "' style='flex-basis:36px; pointer-events:none;'>",
                                                          ).dxCheckBox({
                                                            readOnly: true,
                                                            onContentReady:
                                                              function (e) {
                                                                let {
                                                                  idAplicacion,
                                                                } = itemData;
                                                                if (userSel) {
                                                                  let form_currentApp =
                                                                    [];
                                                                  $.each(
                                                                    userSel.tblFormulario,
                                                                    function (
                                                                      index,
                                                                      item,
                                                                    ) {
                                                                      $.each(
                                                                        item.tblFormulario,
                                                                        function (
                                                                          i2,
                                                                          item2,
                                                                        ) {
                                                                          if (
                                                                            item2.idAplicacion ==
                                                                            idAplicacion
                                                                          ) {
                                                                            form_currentApp.push(
                                                                              item2,
                                                                            );
                                                                          }
                                                                        },
                                                                      );
                                                                    },
                                                                  );
                                                                  let formTotales_app =
                                                                    $.extend(
                                                                      true,
                                                                      [],
                                                                      form_currentApp,
                                                                    ).length;
                                                                  let formActivos =
                                                                    $.extend(
                                                                      true,
                                                                      [],
                                                                      form_currentApp,
                                                                    ).filter(
                                                                      (x) =>
                                                                        x.isEscritura,
                                                                    ).length;
                                                                  e.component.option(
                                                                    "value",
                                                                    formActivos ===
                                                                      0
                                                                      ? false
                                                                      : formActivos <
                                                                          formTotales_app
                                                                        ? undefined
                                                                        : true,
                                                                  );
                                                                }
                                                              },
                                                          }),
                                                          $("<div />")
                                                            .addClass(
                                                              "w-100 d-flex flex-column align-items-center",
                                                            )
                                                            .append(
                                                              "<i class='" +
                                                                itemData.icon +
                                                                " font-size-xxl flex-fill d-flex align-items-center pb-1' ></i>",
                                                              $(
                                                                "<span style='line-height:20px;' class='he-100 flex-fill text-center'>" +
                                                                  itemData
                                                                    .tblTraduccion[
                                                                    IDIOMA
                                                                      .codigo
                                                                  ] +
                                                                  "</span>",
                                                              ),
                                                            ),
                                                          $(
                                                            "<div style='flex-basis:36px; pointer-events:none;' />",
                                                          ),
                                                        ),
                                                    );
                                                },
                                                onSelectionChanged: function (
                                                  e,
                                                ) {
                                                  idAplicacionSel =
                                                    e.addedItems[0]
                                                      .idAplicacion;
                                                  if (userSel) {
                                                    filtrar_formulariosApp(
                                                      idAplicacionSel,
                                                    );
                                                  }
                                                  var dxAccordion_formularioVisibles =
                                                    $(
                                                      "#dxAccordion_formularioVisibles",
                                                    ).dxAccordion("instance");
                                                  dxAccordion_formularioVisibles.option(
                                                    {
                                                      selectedIndex: -1,
                                                    },
                                                  );
                                                  dxAccordion_formularioVisibles.repaint();
                                                },
                                              });

                                              dxAccordion.dxAccordion({
                                                dataSource: userSel
                                                  ? userSel.tblFormulario
                                                  : [],
                                                selectedIndex: -1,
                                                collapsible: true,
                                                disabled:
                                                  userSel &&
                                                  (userSel.idCargo === 1 ||
                                                    userSel.idCargo === 2)
                                                    ? true
                                                    : false,
                                                animationDuration: 75,
                                                itemTitleTemplate: function (
                                                  itemData,
                                                  itemIndex,
                                                  itemElement,
                                                ) {
                                                  return $("<div />")
                                                    .addClass("d-flex")
                                                    .append(
                                                      $(
                                                        "<div id='dxCheckBox_apartado_" +
                                                          itemData.idApartado +
                                                          "' style='flex-basis:35px;'>",
                                                      ).dxCheckBox({
                                                        readOnly: true,
                                                        value:
                                                          setApartado_checkBox(
                                                            itemData,
                                                          ),
                                                      }),
                                                      $("<div>")
                                                        .addClass("flex-fill")
                                                        .text(
                                                          itemData.denominacion,
                                                        ),
                                                    );
                                                },
                                                itemTemplate: function (
                                                  itemData,
                                                  itemIndex,
                                                  itemElement,
                                                ) {
                                                  itemElement.append(
                                                    $(
                                                      "<div class='dxDataGrid_formularios' />",
                                                    ).dxDataGrid({
                                                      dataSource:
                                                        new DataSource({
                                                          store: new ArrayStore(
                                                            {
                                                              data: itemData.tblFormulario,
                                                              key: "idFormulario",
                                                            },
                                                          ),
                                                          postProcess:
                                                            function (data) {
                                                              let filterItems =
                                                                [];
                                                              $.each(
                                                                data,
                                                                function (
                                                                  index,
                                                                  item,
                                                                ) {
                                                                  if (
                                                                    item.idAplicacion ===
                                                                    idAplicacionSel
                                                                  )
                                                                    filterItems.push(
                                                                      item,
                                                                    );
                                                                },
                                                              );
                                                              return filterItems;
                                                            },
                                                        }),
                                                      //Propiedades
                                                      columnsAutoWidth: true,
                                                      remoteOperations: false,
                                                      height: "100%",
                                                      paging: {
                                                        enabled: false,
                                                      },
                                                      headerFilter: {
                                                        visible: false,
                                                      },
                                                      searchPanel: {
                                                        visible: false,
                                                      },
                                                      filterRow: {
                                                        visible: false,
                                                      },
                                                      editing: {
                                                        mode: "cell",
                                                        allowUpdating: true,
                                                      },
                                                      selection: {
                                                        mode: "multiple",
                                                        showCheckBoxesMode:
                                                          "always",
                                                      },
                                                      columns: [
                                                        {
                                                          dataField:
                                                            "denominacion",
                                                          caption:
                                                            getTrad(
                                                              "formulario",
                                                            ),
                                                          minWidth: 250,
                                                          allowSorting: false,
                                                          allowHeaderFiltering: false,
                                                          allowEditing: false,
                                                        },
                                                        {
                                                          dataField:
                                                            "isEscritura",
                                                          caption:
                                                            getTrad(
                                                              "escritura",
                                                            ),
                                                          width: 88,
                                                          alignment: "center",
                                                          allowSorting: false,
                                                          showEditorAlways: true,
                                                          cssClass: "p-0",
                                                          editCellTemplate:
                                                            function (
                                                              cellElement,
                                                              cellInfo,
                                                            ) {
                                                              var dataField =
                                                                  cellInfo
                                                                    .column
                                                                    .dataField,
                                                                estadoImg = {};

                                                              if (
                                                                cellInfo.data[
                                                                  dataField
                                                                ]
                                                              ) {
                                                                estadoImg = {
                                                                  svg: svg_tick_circle,
                                                                  color:
                                                                    enum_colores[
                                                                      "verde"
                                                                    ],
                                                                };
                                                              } else {
                                                                estadoImg = {
                                                                  svg: svg_remove_circle,
                                                                  color:
                                                                    enum_colores[
                                                                      "rojo"
                                                                    ],
                                                                };
                                                              }

                                                              estadoImg.svg
                                                                .clone()
                                                                .css({
                                                                  fill: estadoImg.color,
                                                                  "vertical-align":
                                                                    "middle",
                                                                  cursor:
                                                                    "pointer",
                                                                  height:
                                                                    "20px",
                                                                  width: "20px",
                                                                })
                                                                .on(
                                                                  "click",
                                                                  function () {
                                                                    cellInfo.component.cellValue(
                                                                      cellInfo.rowIndex,
                                                                      dataField,
                                                                      !cellInfo
                                                                        .data[
                                                                        dataField
                                                                      ],
                                                                    );
                                                                    cellInfo.component.saveEditData();
                                                                    checkFormularioInicio();

                                                                    if (
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        dataField
                                                                      ] ||
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        "isBorrado"
                                                                      ]
                                                                    )
                                                                      cellInfo.component.selectRows(
                                                                        cellInfo.key,
                                                                        true,
                                                                      );
                                                                    else if (
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        dataField
                                                                      ] ===
                                                                        false &&
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        "isBorrado"
                                                                      ] ===
                                                                        false
                                                                    )
                                                                      cellInfo.component.deselectRows(
                                                                        cellInfo.key,
                                                                      );
                                                                  },
                                                                )
                                                                .appendTo(
                                                                  cellElement,
                                                                );
                                                            },
                                                        },
                                                        {
                                                          dataField:
                                                            "isBorrado",
                                                          caption:
                                                            getTrad("borrado"),
                                                          width: 88,
                                                          alignment: "center",
                                                          allowSorting: false,
                                                          showEditorAlways: true,
                                                          cssClass: "p-0",
                                                          editCellTemplate:
                                                            function (
                                                              cellElement,
                                                              cellInfo,
                                                            ) {
                                                              var dataField =
                                                                  cellInfo
                                                                    .column
                                                                    .dataField,
                                                                estadoImg = {};

                                                              if (
                                                                cellInfo.data[
                                                                  dataField
                                                                ]
                                                              ) {
                                                                estadoImg = {
                                                                  svg: svg_tick_circle,
                                                                  color:
                                                                    enum_colores[
                                                                      "verde"
                                                                    ],
                                                                };
                                                              } else {
                                                                estadoImg = {
                                                                  svg: svg_remove_circle,
                                                                  color:
                                                                    enum_colores[
                                                                      "rojo"
                                                                    ],
                                                                };
                                                              }

                                                              estadoImg.svg
                                                                .clone()
                                                                .css({
                                                                  fill: estadoImg.color,
                                                                  "vertical-align":
                                                                    "middle",
                                                                  cursor:
                                                                    "pointer",
                                                                  height:
                                                                    "20px",
                                                                  width: "20px",
                                                                })
                                                                .on(
                                                                  "click",
                                                                  function () {
                                                                    cellInfo.component.cellValue(
                                                                      cellInfo.rowIndex,
                                                                      dataField,
                                                                      !cellInfo
                                                                        .data[
                                                                        dataField
                                                                      ],
                                                                    );
                                                                    cellInfo.component.saveEditData();
                                                                    checkFormularioInicio();

                                                                    if (
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        dataField
                                                                      ] ||
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        "isEscritura"
                                                                      ]
                                                                    )
                                                                      cellInfo.component.selectRows(
                                                                        cellInfo.key,
                                                                        true,
                                                                      );
                                                                    else if (
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        dataField
                                                                      ] ===
                                                                        false &&
                                                                      cellInfo
                                                                        .row
                                                                        .data[
                                                                        "isEscritura"
                                                                      ] ===
                                                                        false
                                                                    )
                                                                      cellInfo.component.deselectRows(
                                                                        cellInfo.key,
                                                                      );
                                                                  },
                                                                )
                                                                .appendTo(
                                                                  cellElement,
                                                                );
                                                            },
                                                        },
                                                      ],
                                                      onRowPrepared: function (
                                                        e,
                                                      ) {
                                                        if (
                                                          e.rowType === "data"
                                                        ) {
                                                          if (
                                                            e.data
                                                              .isEscritura ||
                                                            e.data.isBorrado
                                                          )
                                                            e.component
                                                              .option(
                                                                "selectedRowKeys",
                                                              )
                                                              .push(e.key);
                                                        }
                                                      },
                                                      onCellClick: function (
                                                        e,
                                                      ) {
                                                        if (
                                                          e.column.command ===
                                                          "select"
                                                        ) {
                                                          var selectAll =
                                                            e.cellElement.children(
                                                              ".dx-checkbox",
                                                            );
                                                          if (
                                                            selectAll
                                                              .dxCheckBox(
                                                                "instance",
                                                              )
                                                              .option("value")
                                                          ) {
                                                            e.component.beginUpdate();
                                                            $.each(
                                                              e.component.getVisibleRows(),
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                var rowIndex =
                                                                  e.component.getRowIndexByKey(
                                                                    item.key,
                                                                  );
                                                                e.component.cellValue(
                                                                  rowIndex,
                                                                  "isEscritura",
                                                                  true,
                                                                );
                                                                e.component.cellValue(
                                                                  rowIndex,
                                                                  "isBorrado",
                                                                  true,
                                                                );
                                                              },
                                                            );
                                                            e.component.saveEditData();
                                                            checkFormularioInicio();
                                                            e.component.endUpdate();

                                                            let isUserLavanderia =
                                                              userSel.subtipoUsuario ===
                                                                1 ||
                                                              userSel.subtipoUsuario ===
                                                                3;
                                                            dxNavbar_disableItems(
                                                              isUserLavanderia,
                                                            );
                                                          }
                                                        }
                                                      },
                                                      onSelectionChanged:
                                                        function (e) {
                                                          function setSelectionChanged() {
                                                            if (
                                                              e.component.hasEditData()
                                                            )
                                                              e.component.saveEditData();
                                                            checkFormularioInicio();
                                                            e.component.endUpdate();

                                                            let isUserLavanderia =
                                                              userSel.subtipoUsuario ===
                                                                1 ||
                                                              userSel.subtipoUsuario ===
                                                                3;
                                                            dxNavbar_disableItems(
                                                              isUserLavanderia,
                                                            );
                                                            $(
                                                              "#dxCheckBox_apartado_" +
                                                                itemData.idApartado,
                                                            )
                                                              .dxCheckBox(
                                                                "instance",
                                                              )
                                                              .option(
                                                                "value",
                                                                setApartado_checkBox(
                                                                  itemData,
                                                                ),
                                                              );
                                                            $(
                                                              "#dxButtonGroup_formularioVisibles",
                                                            )
                                                              .dxButtonGroup(
                                                                "instance",
                                                              )
                                                              .repaint();
                                                          }
                                                          e.component.beginUpdate();
                                                          if (
                                                            e
                                                              .currentSelectedRowKeys
                                                              .length > 0
                                                          ) {
                                                            $.each(
                                                              e.currentSelectedRowKeys,
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                var rowIndex =
                                                                  e.component.getRowIndexByKey(
                                                                    item,
                                                                  );
                                                                if (
                                                                  e.component.cellValue(
                                                                    rowIndex,
                                                                    "isEscritura",
                                                                  ) === false &&
                                                                  e.component.cellValue(
                                                                    rowIndex,
                                                                    "isBorrado",
                                                                  ) === false
                                                                ) {
                                                                  e.component.cellValue(
                                                                    rowIndex,
                                                                    "isEscritura",
                                                                    true,
                                                                  );
                                                                  e.component.cellValue(
                                                                    rowIndex,
                                                                    "isBorrado",
                                                                    true,
                                                                  );
                                                                }
                                                              },
                                                            );
                                                            setSelectionChanged();
                                                          } else {
                                                            $.each(
                                                              e.currentDeselectedRowKeys,
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                let formulario =
                                                                  $.grep(
                                                                    dataSource_formularios.items(),
                                                                    function (
                                                                      form,
                                                                    ) {
                                                                      return (
                                                                        form.idFormulario ===
                                                                        item
                                                                      );
                                                                    },
                                                                  );

                                                                var rowIndex =
                                                                  e.component.getRowIndexByKey(
                                                                    item,
                                                                  );
                                                                e.component.cellValue(
                                                                  rowIndex,
                                                                  "isEscritura",
                                                                  false,
                                                                );
                                                                e.component.cellValue(
                                                                  rowIndex,
                                                                  "isBorrado",
                                                                  false,
                                                                );
                                                              },
                                                            );
                                                            setSelectionChanged();

                                                            var tblFormularioNUsuarioVisibles =
                                                              [];
                                                            $.each(
                                                              userSel.tblFormulario,
                                                              function (
                                                                index,
                                                                value,
                                                              ) {
                                                                $.each(
                                                                  value.tblFormulario,
                                                                  function (
                                                                    itemIndex,
                                                                    item,
                                                                  ) {
                                                                    if (
                                                                      (item.isEscritura ||
                                                                        item.isBorrado) &&
                                                                      item.idAplicacion ===
                                                                        idAplicacionSel
                                                                    ) {
                                                                      tblFormularioNUsuarioVisibles.push(
                                                                        item,
                                                                      );
                                                                    }
                                                                  },
                                                                );
                                                              },
                                                            );

                                                            var tblFormularioNUsuarioVisibles_RRHH =
                                                              [];
                                                            $.each(
                                                              userSel.tblFormulario,
                                                              function (
                                                                index,
                                                                value,
                                                              ) {
                                                                $.each(
                                                                  value.tblFormulario,
                                                                  function (
                                                                    itemIndex,
                                                                    item,
                                                                  ) {
                                                                    if (
                                                                      (item.isEscritura ||
                                                                        item.isBorrado) &&
                                                                      item.idAplicacion ===
                                                                        idAplicacionSel &&
                                                                      item.idApartado ==
                                                                        5
                                                                    ) {
                                                                      tblFormularioNUsuarioVisibles_RRHH.push(
                                                                        item,
                                                                      );
                                                                    }
                                                                  },
                                                                );
                                                              },
                                                            );

                                                            // if(idAplicacionSel === 2 && userSel.tblCentroTrabajo.length > 0 && tblFormularioNUsuarioVisibles.length === 0)
                                                            if (
                                                              userSel
                                                                .tblCentroTrabajo
                                                                .length > 0 &&
                                                              tblFormularioNUsuarioVisibles_RRHH.length ===
                                                                0
                                                            ) {
                                                              dxMensajePregunta(
                                                                getTrad(
                                                                  "seEliminaranCentrosTrabajo",
                                                                ),
                                                                [
                                                                  // ACEPTAR
                                                                  [
                                                                    getTrad(
                                                                      "aceptar",
                                                                    ),
                                                                    function () {
                                                                      userSel.tblCentroTrabajo =
                                                                        [];
                                                                      $(
                                                                        "#dxList_centrosTrabajo",
                                                                      )
                                                                        .dxList(
                                                                          "instance",
                                                                        )
                                                                        .option(
                                                                          "selectedItemKeys",
                                                                          [],
                                                                        );
                                                                    },
                                                                    "normal",
                                                                    "btnAceptar",
                                                                  ],
                                                                  // CANCELAR
                                                                  [
                                                                    getTrad(
                                                                      "cancelar",
                                                                    ),
                                                                    function () {
                                                                      e.component.selectRows(
                                                                        e.currentDeselectedRowKeys,
                                                                      );
                                                                    },
                                                                    "normal",
                                                                    "btnCancelar",
                                                                  ],
                                                                ],
                                                              );
                                                            }

                                                            let lavanderiasSeleccionadas =
                                                              $.grep(
                                                                userSel.tblLavanderia,
                                                                function (
                                                                  item,
                                                                ) {
                                                                  return (
                                                                    item ===
                                                                    true
                                                                  );
                                                                },
                                                              );
                                                            if (
                                                              idAplicacionSel ===
                                                                1 &&
                                                              e.selectedRowsData
                                                                .length > 0 &&
                                                              e
                                                                .selectedRowsData[0]
                                                                .idApartado !==
                                                                5 &&
                                                              (lavanderiasSeleccionadas.length >
                                                                0 ||
                                                                userSel
                                                                  .tblEntidad
                                                                  .length >
                                                                  0) &&
                                                              tblFormularioNUsuarioVisibles.length ===
                                                                0
                                                            ) {
                                                              dxMensajePregunta(
                                                                userSel
                                                                  .tblEntidad
                                                                  .length > 0
                                                                  ? getTrad(
                                                                      "seEliminaranEntidades",
                                                                    )
                                                                  : getTrad(
                                                                      "seEliminaranLavanderias",
                                                                    ),
                                                                [
                                                                  // ACEPTAR
                                                                  [
                                                                    getTrad(
                                                                      "aceptar",
                                                                    ),
                                                                    function () {
                                                                      if (
                                                                        userSel
                                                                          .tblEntidad
                                                                          .length >
                                                                        0
                                                                      ) {
                                                                        userSel.tblEntidad =
                                                                          [];
                                                                        $(
                                                                          "#dxList_entidades",
                                                                        )
                                                                          .dxList(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            "selectedItemKeys",
                                                                            [],
                                                                          );
                                                                      } else if (
                                                                        lavanderiasSeleccionadas.length >
                                                                        0
                                                                      ) {
                                                                        userSel.tblLavanderia =
                                                                          [];
                                                                        userSel.idLavanderiaInicio =
                                                                          null;
                                                                        $(
                                                                          "#dxSelectBox_lavanderiaInicio",
                                                                        )
                                                                          .dxSelectBox(
                                                                            "instance",
                                                                          )
                                                                          .option(
                                                                            {
                                                                              value:
                                                                                null,
                                                                            },
                                                                          );
                                                                        dxPivotGridSelection_reset();
                                                                      }
                                                                    },
                                                                    "normal",
                                                                    "btnAceptar",
                                                                  ],
                                                                  // CANCELAR
                                                                  [
                                                                    getTrad(
                                                                      "cancelar",
                                                                    ),
                                                                    function () {
                                                                      e.component.selectRows(
                                                                        e.currentDeselectedRowKeys,
                                                                      );
                                                                    },
                                                                    "normal",
                                                                    "btnCancelar",
                                                                  ],
                                                                ],
                                                              );
                                                            }
                                                          }
                                                        },
                                                      //Estilos
                                                      showColumnLines: false,
                                                      showRowLines: true,
                                                      rowAlternationEnabled: true,
                                                    }),
                                                  );
                                                },
                                              });
                                              return scrollView;
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        template: function () {
                          return $("<div />").dxBox({
                            direction: "col",
                            height: "100%",
                            items: [
                              {
                                baseSize: 62,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='dxToolbar_usuarios' />",
                                  ).dxToolbar({
                                    items: [
                                      {
                                        location: "before",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("atras"),
                                          icon: "chevronleft",
                                          onClick: function () {
                                            $("#dxTabPanel_principal")
                                              .dxTabPanel("instance")
                                              .option("selectedIndex", 1);
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("siguiente"),
                                          icon: "chevronright",
                                          onClick: function () {
                                            if (check_subtipoUsuario()) {
                                              setResumenForm();

                                              $("#dxTabPanel_principal")
                                                .dxTabPanel("instance")
                                                .option(
                                                  "selectedIndex",
                                                  is_centrosTrabajo() ? 3 : 5,
                                                );
                                            }
                                          },
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='dxPivotGrid_formulariosNLavanderia'>",
                                  ).dxPivotGrid({
                                    height: "100%",
                                    showBorders: true,
                                    showColumnGrandTotals: false,
                                    showRowGrandTotals: false,
                                    fieldChooser: {
                                      enabled: false,
                                    },
                                    allowSorting: true,
                                    dataSource: {
                                      fields: [
                                        {
                                          caption: "idLavanderia",
                                          width: 240,
                                          dataField: "idLavanderia",
                                          customizeText: function (cellInfo) {
                                            return getLavName(cellInfo.value);
                                          },
                                          area: "row",
                                          sortingMethod: function (a, b) {
                                            return a.text.localeCompare(b.text);
                                          },
                                        },
                                        {
                                          dataField: "denoMod",
                                          area: "column",
                                        },
                                        {
                                          dataField: "tieneMod",
                                          area: "data",
                                          dataType: "number",
                                          summaryType: "max",
                                        },
                                      ],
                                    },
                                    onContentReady: function (e) {
                                      $(".dx-area-description-cell")
                                        .css({
                                          padding: "0",
                                          "text-align": "center",
                                          "vertical-align": "middle",
                                        })
                                        .text(getTrad("lavanderías"));
                                    },
                                    onCellPrepared: function (e) {
                                      var parent = e.cell,
                                        moduloNLavIterada;
                                      if (
                                        e.area === "data" &&
                                        e.cell.rowType === "D"
                                      ) {
                                        moduloNLavIterada =
                                          datasource_moduloNLavanderia
                                            .items()
                                            .find(
                                              (x) =>
                                                x.idLavanderia ===
                                                parent.rowPath[0],
                                            );

                                        if (!moduloNLavIterada.isEnabled)
                                          $(e.cellElement).css("opacity", ".5");
                                      }
                                      if (
                                        e.area === "row" &&
                                        e.cell.type === "D"
                                      ) {
                                        moduloNLavIterada =
                                          datasource_moduloNLavanderia
                                            .items()
                                            .find(
                                              (x) =>
                                                x.idLavanderia ===
                                                parent.path[0],
                                            );

                                        for (
                                          var i = 0;
                                          i < parent.path.length - 1;
                                          i++
                                        ) {
                                          parent = parent[e.cell.path[i]];
                                        }
                                        parent[
                                          parent.path[parent.path.length - 1]
                                        ] =
                                          parent[
                                            parent.path[parent.path.length - 1]
                                          ] || {};

                                        $(e.cellElement).attr(
                                          "id",
                                          "dxCheckBox_moduloNLav_" +
                                            moduloNLavIterada.idLavanderia,
                                        );
                                        e.cellElement.prepend(
                                          $("<div />")
                                            .dxCheckBox({
                                              disabled:
                                                !moduloNLavIterada.isEnabled,
                                              value: userSel.tblLavanderia[
                                                parent.path[0]
                                              ]
                                                ? true
                                                : false,
                                              onValueChanged: function (args) {
                                                var value = args.value;
                                                userSel.tblLavanderia[
                                                  parent.path[0]
                                                ] = value;
                                                parent[
                                                  parent.path[
                                                    parent.path.length - 1
                                                  ]
                                                ].checked = value;

                                                $(
                                                  "#dxSelectBox_lavanderiaInicio",
                                                )
                                                  .dxSelectBox("instance")
                                                  .option(
                                                    "dataSource",
                                                    getDenoLavActivas(),
                                                  );
                                              },
                                            })
                                            .css({ "padding-right": "10px" }),
                                        );
                                        if (!moduloNLavIterada.isEnabled) {
                                          $(e.cellElement).css("opacity", ".5");
                                          $("<div />")
                                            .dxTooltip({
                                              target:
                                                "#dxCheckBox_moduloNLav_" +
                                                moduloNLavIterada.idLavanderia,
                                              height: "42px",
                                              showEvent: {
                                                name: "dxhoverstart",
                                                delay: 400,
                                              },
                                              hideEvent: "dxhoverend",
                                              position: "top",
                                              contentTemplate: function (
                                                contentElement,
                                              ) {
                                                contentElement.html(
                                                  getTrad("noAccesoLav"),
                                                );
                                              },
                                              animation: {
                                                show: {
                                                  type: "pop",
                                                  from: {
                                                    scale: 0.1,
                                                    opacity: 0,
                                                  },
                                                  to: {
                                                    opacity: 1,
                                                    scale: 1,
                                                  },
                                                },
                                                hide: {
                                                  type: "pop",
                                                  from: {
                                                    scale: 1,
                                                    opacity: 1,
                                                  },
                                                  to: {
                                                    opacity: 0,
                                                    scale: 0.1,
                                                  },
                                                },
                                              },
                                            })
                                            .appendTo(e.cellElement);
                                        }
                                      } else if (e.area === "data") {
                                        var container = e.cellElement;
                                        container.empty();

                                        if (e.cell.text === "1") {
                                          container.append(
                                            $("<div />")
                                              .addClass(
                                                "container_spanCentrado",
                                              )
                                              .append(
                                                svg_tick
                                                  .clone()
                                                  .css("pointer-events", "none")
                                                  .css("height", "14px")
                                                  .css("width", "14px"),
                                              ),
                                          );
                                        }
                                      }
                                    },
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        template: function () {
                          return $("<div />").dxBox({
                            direction: "col",
                            height: "100%",
                            items: [
                              {
                                baseSize: 62,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='dxToolbar_usuarios' />",
                                  ).dxToolbar({
                                    items: [
                                      {
                                        location: "before",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("atras"),
                                          icon: "chevronleft",
                                          onClick: function () {
                                            $("#dxTabPanel_principal")
                                              .dxTabPanel("instance")
                                              .option(
                                                "selectedIndex",
                                                is_FormLavanderia() ? 2 : 1,
                                              );
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("siguiente"),
                                          icon: "chevronright",
                                          onClick: function () {
                                            if (check_subtipoUsuario()) {
                                              setResumenForm();

                                              var subtipoUser =
                                                userSel.subtipoUsuario;
                                              $("#dxTabPanel_principal")
                                                .dxTabPanel("instance")
                                                .option(
                                                  "selectedIndex",
                                                  subtipoUser === 0 ||
                                                    subtipoUser === 2
                                                    ? 4
                                                    : 5,
                                                );
                                            }
                                          },
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $("<div />").dxBox({
                                    direction: "col",
                                    height: "100%",
                                    items: [
                                      {
                                        baseSize: 34,
                                        shrink: 0,
                                        ratio: 0,
                                        template: function () {
                                          return $(
                                            "<div class='font-size'>" +
                                              getTrad("centrosTrabajo") +
                                              "</div>",
                                          );
                                        },
                                      },
                                      {
                                        ratio: 1,
                                        template: function () {
                                          return $(
                                            "<div id='dxList_centrosTrabajo'>",
                                          ).dxList({
                                            dataSource:
                                              dataSource_tblCentroTrabajo,
                                            indicateLoading: false,
                                            selectionMode: "all",
                                            searchEnabled: true,
                                            searchExpr: "denominacion",
                                            displayExpr: "denominacion",
                                            keyExpr: "idCentroTrabajo",
                                            height: "100%",
                                            width: 500,
                                            showScrollbar: "onHover",
                                            scrolling: { mode: "infinite" },
                                            pageLoadMode: "scrollBottom",
                                            showSelectionControls: true,
                                            onOptionChanged: function (e) {
                                              if (
                                                userSel &&
                                                e.name === "selectedItems"
                                              ) {
                                                userSel.tblCentroTrabajo =
                                                  e.value;
                                              }
                                            },
                                          });
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        template: function () {
                          return $("<div />").dxBox({
                            direction: "col",
                            height: "100%",
                            items: [
                              {
                                baseSize: 62,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='dxToolbar_usuarios' />",
                                  ).dxToolbar({
                                    items: [
                                      {
                                        location: "before",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("atras"),
                                          icon: "chevronleft",
                                          onClick: function () {
                                            $("#dxTabPanel_principal")
                                              .dxTabPanel("instance")
                                              .option(
                                                "selectedIndex",
                                                is_centrosTrabajo() ? 3 : 1,
                                              );
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("siguiente"),
                                          icon: "chevronright",
                                          onClick: function () {
                                            if (check_subtipoUsuario()) {
                                              setResumenForm();
                                              $("#dxTabPanel_principal")
                                                .dxTabPanel("instance")
                                                .option(
                                                  "selectedIndex",
                                                  is_centrosTrabajo() ? 3 : 5,
                                                );
                                            }
                                          },
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $("<div />").dxBox({
                                    direction: "row",
                                    height: "100%",
                                    align: "space-around",
                                    crossAlign: "stretch",
                                    items: [
                                      {
                                        ratio: 1,
                                        template: function () {
                                          //LAVANDERÍAS
                                          return $("<div />")
                                            .css({
                                              "margin-right": "15px",
                                              paddingTop: "10px",
                                            })
                                            .dxBox({
                                              direction: "col",
                                              height: "100%",
                                              align: "space-around",
                                              crossAlign: "stretch",
                                              items: [
                                                {
                                                  baseSize: 34,
                                                  shrink: 0,
                                                  ratio: 0,
                                                  template: function () {
                                                    return $(
                                                      "<div class='font-size'>" +
                                                        getTrad("lavanderia") +
                                                        "</div>",
                                                    );
                                                  },
                                                },
                                                {
                                                  ratio: 1,
                                                  template: function () {
                                                    return $(
                                                      "<div id='dxList_lavanderias'>",
                                                    ).dxList({
                                                      dataSource:
                                                        dataSource_lavanderias_todas,
                                                      focusStateEnabled: false,
                                                      activeStateEnabled: false,
                                                      selectionMode: "single",
                                                      searchEnabled: true,
                                                      searchExpr: [
                                                        "denominacion",
                                                      ],
                                                      height: "100%",
                                                      width: "100%",
                                                      keyExpr: "idLavanderia",
                                                      showScrollbar: "onHover",
                                                      scrolling: {
                                                        mode: "infinite",
                                                      },
                                                      pageLoadMode:
                                                        "scrollBottom",
                                                      // EVENTOS
                                                      onSelectionChanged:
                                                        function (e) {
                                                          if (
                                                            e.addedItems
                                                              .length > 0
                                                          ) {
                                                            var dxList_entidades =
                                                              $(
                                                                "#dxList_entidades",
                                                              ).dxList(
                                                                "instance",
                                                              );
                                                            var dxList_compañias =
                                                              $(
                                                                "#dxList_compañias",
                                                              ).dxList(
                                                                "instance",
                                                              );
                                                            dxList_compañias.unselectAll();

                                                            if (
                                                              cargaCompaEnti !=
                                                              null
                                                            )
                                                              datasource_datos_compaEnti.cancel(
                                                                cargaCompaEnti.operationId,
                                                              );
                                                            cargaCompaEnti =
                                                              datasource_datos_compaEnti.reload();
                                                            cargaCompaEnti.done(
                                                              function (
                                                                compañias,
                                                              ) {
                                                                dxList_entidades.option(
                                                                  "searchValue",
                                                                  "",
                                                                );

                                                                if (
                                                                  compañias.length >
                                                                  0
                                                                )
                                                                  dxList_compañias.option(
                                                                    "selectedItemKeys",
                                                                    [
                                                                      compañias[0]
                                                                        .idCompañia,
                                                                    ],
                                                                  );
                                                              },
                                                            );
                                                          }
                                                        },
                                                      itemTemplate: function (
                                                        data,
                                                        index,
                                                      ) {
                                                        return $(
                                                          "<div/>",
                                                        ).dxBox({
                                                          direction: "row",
                                                          align: "space-around",
                                                          crossAlign: "stretch",
                                                          height: "100%",
                                                          items: [
                                                            {
                                                              baseSize: 30,
                                                              shrink: 0,
                                                              ratio: 0,
                                                              template:
                                                                function () {
                                                                  return $(
                                                                    "<div id='dxCheckBox_lavanderia_" +
                                                                      data.idLavanderia +
                                                                      "'/>",
                                                                  ).dxCheckBox({
                                                                    onValueChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        setTimeout(
                                                                          () => {
                                                                            var idLavanderiaSel =
                                                                              $(
                                                                                "#dxList_lavanderias",
                                                                              )
                                                                                .dxList(
                                                                                  "instance",
                                                                                )
                                                                                .option(
                                                                                  "selectedItemKeys",
                                                                                )[0];
                                                                            if (
                                                                              data.idLavanderia ===
                                                                              idLavanderiaSel
                                                                            ) {
                                                                              //Solo cambio de estado sin cambio de lavanderia
                                                                              let idsEntidadesLavanderia =
                                                                                $.map(
                                                                                  data.tblEntidad,
                                                                                  function (
                                                                                    item,
                                                                                  ) {
                                                                                    return item.idEntidad;
                                                                                  },
                                                                                );
                                                                              let idsEntidadesUsuario =
                                                                                [];
                                                                              $.each(
                                                                                userSel.tblEntidad,
                                                                                function (
                                                                                  index,
                                                                                  item,
                                                                                ) {
                                                                                  $.each(
                                                                                    item.tblEntidad,
                                                                                    function (
                                                                                      iEntidad,
                                                                                      entidad,
                                                                                    ) {
                                                                                      idsEntidadesUsuario.push(
                                                                                        entidad.idEntidad,
                                                                                      );
                                                                                    },
                                                                                  );
                                                                                },
                                                                              );

                                                                              let lavanderiaFiltrada =
                                                                                $.grep(
                                                                                  dataSource_lavanderias_todas.items(),
                                                                                  function (
                                                                                    item,
                                                                                  ) {
                                                                                    return (
                                                                                      item.idLavanderia ===
                                                                                      data.idLavanderia
                                                                                    );
                                                                                  },
                                                                                );

                                                                              let idsCompañia =
                                                                                [];
                                                                              $.each(
                                                                                lavanderiaFiltrada[0]
                                                                                  .tblEntidad,
                                                                                function (
                                                                                  index,
                                                                                  item,
                                                                                ) {
                                                                                  if (
                                                                                    $.inArray(
                                                                                      item.idCompañia,
                                                                                      idsCompañia,
                                                                                    ) ===
                                                                                    -1
                                                                                  )
                                                                                    idsCompañia.push(
                                                                                      item.idCompañia,
                                                                                    );
                                                                                },
                                                                              );

                                                                              let compañias =
                                                                                [];
                                                                              $.each(
                                                                                lavanderiaFiltrada[0]
                                                                                  .tblEntidad,
                                                                                function (
                                                                                  index,
                                                                                  item,
                                                                                ) {
                                                                                  let index_ =
                                                                                    $.inArray(
                                                                                      item.idCompañia,
                                                                                      $.map(
                                                                                        compañias,
                                                                                        function (
                                                                                          item,
                                                                                        ) {
                                                                                          return item.idCompañia;
                                                                                        },
                                                                                      ),
                                                                                    );
                                                                                  if (
                                                                                    index_ ===
                                                                                    -1
                                                                                  ) {
                                                                                    compañias.push(
                                                                                      {
                                                                                        idCompañia:
                                                                                          item.idCompañia,
                                                                                        tblEntidad:
                                                                                          [
                                                                                            {
                                                                                              idEntidad:
                                                                                                item.idEntidad,
                                                                                              denominacion:
                                                                                                item.denominacion,
                                                                                            },
                                                                                          ],
                                                                                      },
                                                                                    );
                                                                                  } else {
                                                                                    compañias[
                                                                                      index_
                                                                                    ].tblEntidad.push(
                                                                                      {
                                                                                        idEntidad:
                                                                                          item.idEntidad,
                                                                                        denominacion:
                                                                                          item.denominacion,
                                                                                      },
                                                                                    );
                                                                                  }
                                                                                },
                                                                              );

                                                                              var checkValue =
                                                                                e.value;
                                                                              if (
                                                                                checkValue ===
                                                                                true
                                                                              ) {
                                                                                $.each(
                                                                                  compañias,
                                                                                  function (
                                                                                    index,
                                                                                    item,
                                                                                  ) {
                                                                                    let compaModificar =
                                                                                      $.grep(
                                                                                        userSel.tblEntidad,
                                                                                        function (
                                                                                          compañia,
                                                                                        ) {
                                                                                          return (
                                                                                            compañia.idCompañia ===
                                                                                            item.idCompañia
                                                                                          );
                                                                                        },
                                                                                      );
                                                                                    if (
                                                                                      compaModificar.length >
                                                                                      0
                                                                                    ) {
                                                                                      let unirCompas =
                                                                                        compaModificar[0].tblEntidad.concat(
                                                                                          item.tblEntidad,
                                                                                        );

                                                                                      let uniqueIds =
                                                                                        [];
                                                                                      let unique =
                                                                                        unirCompas.filter(
                                                                                          (
                                                                                            element,
                                                                                          ) => {
                                                                                            let isDuplicate =
                                                                                              uniqueIds.includes(
                                                                                                element.idEntidad,
                                                                                              );

                                                                                            if (
                                                                                              !isDuplicate
                                                                                            ) {
                                                                                              uniqueIds.push(
                                                                                                element.idEntidad,
                                                                                              );

                                                                                              return true;
                                                                                            }

                                                                                            return false;
                                                                                          },
                                                                                        );
                                                                                      compaModificar[0].tblEntidad =
                                                                                        unique;
                                                                                    } else {
                                                                                      userSel.tblEntidad.push(
                                                                                        item,
                                                                                      );
                                                                                    }
                                                                                  },
                                                                                );
                                                                              } else if (
                                                                                checkValue ===
                                                                                false
                                                                              ) {
                                                                                $.each(
                                                                                  userSel.tblEntidad,
                                                                                  function (
                                                                                    index,
                                                                                    compa,
                                                                                  ) {
                                                                                    compa.tblEntidad =
                                                                                      $.grep(
                                                                                        compa.tblEntidad,
                                                                                        function (
                                                                                          enti,
                                                                                        ) {
                                                                                          if (
                                                                                            $.inArray(
                                                                                              enti.idEntidad,
                                                                                              idsEntidadesLavanderia,
                                                                                            ) ===
                                                                                            -1
                                                                                          )
                                                                                            return enti;
                                                                                        },
                                                                                      );
                                                                                  },
                                                                                );
                                                                              }
                                                                              setCheckBoxCompañia();
                                                                              setCheckBoxLavanderia();
                                                                              $(
                                                                                "#dxList_resumenLavanderiasSel",
                                                                              )
                                                                                .dxList(
                                                                                  "instance",
                                                                                )
                                                                                .option(
                                                                                  "dataSource",
                                                                                  getDatasourceResumenList(),
                                                                                );
                                                                            }
                                                                          },
                                                                          0,
                                                                        );
                                                                      },
                                                                  });
                                                                },
                                                            },
                                                            {
                                                              ratio: 1,
                                                              template:
                                                                function () {
                                                                  return $(
                                                                    "<div/>",
                                                                  ).text(
                                                                    data.denominacion,
                                                                  );
                                                                },
                                                            },
                                                          ],
                                                        });
                                                      },
                                                    });
                                                  },
                                                },
                                              ],
                                            });
                                        },
                                      },
                                      {
                                        ratio: 1,
                                        template: function () {
                                          //COMPAÑÍAS
                                          return $("<div />")
                                            .css({
                                              "margin-right": "15px",
                                              paddingTop: "10px",
                                            })
                                            .dxBox({
                                              direction: "col",
                                              height: "100%",
                                              align: "space-around",
                                              crossAlign: "stretch",
                                              items: [
                                                {
                                                  baseSize: 34,
                                                  shrink: 0,
                                                  ratio: 0,
                                                  template: function () {
                                                    return $(
                                                      "<div class='font-size'>" +
                                                        getTrad("compañia") +
                                                        "</div>",
                                                    );
                                                  },
                                                },
                                                {
                                                  ratio: 1,
                                                  template: function () {
                                                    return $(
                                                      "<div id='dxList_compañias'>",
                                                    ).dxList({
                                                      dataSource:
                                                        datasource_datos_compaEnti,
                                                      focusStateEnabled: false,
                                                      activeStateEnabled: false,
                                                      searchEnabled: true,
                                                      selectionMode: "single",
                                                      displayExpr:
                                                        "denominacion",
                                                      searchExpr: [
                                                        "denominacion",
                                                      ],
                                                      height: "100%",
                                                      width: "100%",
                                                      keyExpr: "idCompañia",
                                                      showScrollbar: "onHover",
                                                      scrolling: {
                                                        mode: "infinite",
                                                      },
                                                      pageLoadMode:
                                                        "scrollBottom",
                                                      itemTemplate: function (
                                                        data,
                                                        index,
                                                      ) {
                                                        return $(
                                                          "<div/>",
                                                        ).dxBox({
                                                          direction: "row",
                                                          align: "space-around",
                                                          crossAlign: "stretch",
                                                          height: "100%",
                                                          items: [
                                                            {
                                                              baseSize: 30,
                                                              shrink: 0,
                                                              ratio: 0,
                                                              template:
                                                                function () {
                                                                  //#region Cálculo valor checkbox con las entidades del usuario
                                                                  let value = false;
                                                                  let idsEntidadesUsuario =
                                                                    [];
                                                                  $.each(
                                                                    userSel.tblEntidad,
                                                                    function (
                                                                      index,
                                                                      item,
                                                                    ) {
                                                                      $.each(
                                                                        item.tblEntidad,
                                                                        function (
                                                                          iEntidad,
                                                                          entidad,
                                                                        ) {
                                                                          idsEntidadesUsuario.push(
                                                                            entidad.idEntidad,
                                                                          );
                                                                        },
                                                                      );
                                                                    },
                                                                  );

                                                                  $.each(
                                                                    datasource_datos_compaEnti.items(),
                                                                    function (
                                                                      index,
                                                                      item,
                                                                    ) {
                                                                      if (
                                                                        item.idCompañia ===
                                                                        data.idCompañia
                                                                      ) {
                                                                        let numEntiNCompa =
                                                                          item
                                                                            .tblEntidad
                                                                            .length;
                                                                        let numEntiSel = 0;

                                                                        $.each(
                                                                          item.tblEntidad,
                                                                          function (
                                                                            iEntidad,
                                                                            entidad,
                                                                          ) {
                                                                            if (
                                                                              $.inArray(
                                                                                entidad.idEntidad,
                                                                                idsEntidadesUsuario,
                                                                              ) !==
                                                                              -1
                                                                            ) {
                                                                              numEntiSel += 1;
                                                                            }
                                                                          },
                                                                        );

                                                                        value =
                                                                          numEntiNCompa ==
                                                                          numEntiSel
                                                                            ? true
                                                                            : undefined;
                                                                        if (
                                                                          numEntiSel ==
                                                                          0
                                                                        )
                                                                          value = false;
                                                                      }
                                                                    },
                                                                  );
                                                                  //#endregion

                                                                  return $(
                                                                    "<div class='dxCheckBox_compañia_" +
                                                                      data.idCompañia +
                                                                      "'/>",
                                                                  ).dxCheckBox({
                                                                    value:
                                                                      value,
                                                                    onValueChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        var idCompañiaSel =
                                                                          $(
                                                                            "#dxList_compañias",
                                                                          )
                                                                            .dxList(
                                                                              "instance",
                                                                            )
                                                                            .option(
                                                                              "selectedItemKeys",
                                                                            )[0];
                                                                        if (
                                                                          data.idCompañia ===
                                                                          idCompañiaSel
                                                                        ) {
                                                                          //Solo cambio de estado sin cambio de compañia
                                                                          var dxList_entidades =
                                                                            $(
                                                                              "#dxList_entidades",
                                                                            ).dxList(
                                                                              "instance",
                                                                            );

                                                                          var checkValue =
                                                                            e.value;
                                                                          if (
                                                                            checkValue ===
                                                                            true
                                                                          ) {
                                                                            dxList_entidades.selectAll();
                                                                          } else if (
                                                                            checkValue ===
                                                                            false
                                                                          ) {
                                                                            dxList_entidades.unselectAll();
                                                                          }
                                                                        }
                                                                      },
                                                                  });
                                                                },
                                                            },
                                                            {
                                                              ratio: 1,
                                                              template:
                                                                function () {
                                                                  return $(
                                                                    "<div/>",
                                                                  ).text(
                                                                    data.denominacion,
                                                                  );
                                                                },
                                                            },
                                                          ],
                                                        });
                                                      },
                                                      // EVENTOS
                                                      onSelectionChanged:
                                                        function (e) {
                                                          var dxList_entidades =
                                                            $(
                                                              "#dxList_entidades",
                                                            ).dxList(
                                                              "instance",
                                                            );
                                                          if (
                                                            e.addedItems
                                                              .length > 0
                                                          ) {
                                                            var idCompañiaSel =
                                                              e.addedItems[0]
                                                                .idCompañia;
                                                            var selectedKeys =
                                                              [];
                                                            $.each(
                                                              userSel.tblEntidad,
                                                              function (
                                                                index,
                                                                item,
                                                              ) {
                                                                if (
                                                                  item.idCompañia ===
                                                                  e
                                                                    .addedItems[0]
                                                                    .idCompañia
                                                                ) {
                                                                  $.each(
                                                                    item.tblEntidad,
                                                                    function (
                                                                      index,
                                                                      entidad,
                                                                    ) {
                                                                      selectedKeys.push(
                                                                        entidad.idEntidad,
                                                                      );
                                                                    },
                                                                  );
                                                                }
                                                              },
                                                            );

                                                            dxList_entidades.option(
                                                              {
                                                                dataSource:
                                                                  e
                                                                    .addedItems[0]
                                                                    .tblEntidad,
                                                                selectedItemKeys:
                                                                  selectedKeys,
                                                              },
                                                            );

                                                            var checkValue = $(
                                                              ".dxCheckBox_compañia_" +
                                                                idCompañiaSel,
                                                            )
                                                              .dxCheckBox(
                                                                "instance",
                                                              )
                                                              .option("value");
                                                            if (
                                                              checkValue ===
                                                              true
                                                            ) {
                                                              dxList_entidades.selectAll();
                                                            } else if (
                                                              checkValue ===
                                                              false
                                                            ) {
                                                              dxList_entidades.unselectAll();
                                                            }
                                                          } else if (
                                                            e.removedItems
                                                              .length > 0
                                                          ) {
                                                            dxList_entidades.option(
                                                              {
                                                                dataSource: [],
                                                                selectedItemKeys:
                                                                  [],
                                                              },
                                                            );
                                                          }
                                                        },
                                                    });
                                                  },
                                                },
                                              ],
                                            });
                                        },
                                      },
                                      {
                                        ratio: 1,
                                        template: function () {
                                          // ENTIDADES
                                          return $("<div />")
                                            .css({
                                              "margin-right": "15px",
                                              paddingTop: "10px",
                                            })
                                            .dxBox({
                                              direction: "col",
                                              height: "100%",
                                              align: "space-around",
                                              crossAlign: "stretch",
                                              items: [
                                                {
                                                  baseSize: 34,
                                                  shrink: 0,
                                                  ratio: 0,
                                                  template: function () {
                                                    return $(
                                                      "<div class='font-size'>" +
                                                        getTrad("entidad") +
                                                        "</div>",
                                                    );
                                                  },
                                                },
                                                {
                                                  ratio: 1,
                                                  template: function () {
                                                    return $(
                                                      "<div id='dxList_entidades'>",
                                                    ).dxList({
                                                      focusStateEnabled: false,
                                                      activeStateEnabled: false,
                                                      selectionMode: "all",
                                                      height: "100%",
                                                      width: "100%",
                                                      keyExpr: "idEntidad",
                                                      showScrollbar: "onHover",
                                                      scrolling: {
                                                        mode: "infinite",
                                                      },
                                                      pageLoadMode:
                                                        "scrollBottom",
                                                      selectAllMode: "allPages",
                                                      searchEnabled: true,
                                                      searchExpr: [
                                                        "denominacion",
                                                      ],
                                                      showSelectionControls: true,
                                                      onSelectionChanged:
                                                        function (e) {
                                                          var dxList_compañias =
                                                              $(
                                                                "#dxList_compañias",
                                                              ).dxList(
                                                                "instance",
                                                              ),
                                                            idCompañiaSel =
                                                              dxList_compañias.option(
                                                                "selectedItemKeys",
                                                              )[0];

                                                          if (
                                                            userSel &&
                                                            idCompañiaSel
                                                          ) {
                                                            var compañiaFiltrada =
                                                              userSel.tblEntidad.filter(
                                                                function (
                                                                  element,
                                                                  index,
                                                                ) {
                                                                  return (
                                                                    element.idCompañia ===
                                                                    idCompañiaSel
                                                                  );
                                                                },
                                                              );

                                                            // pushear toda la compañia si no el user no la tiene o solamente añadir a la compañia la enti
                                                            if (
                                                              compañiaFiltrada.length ===
                                                              0
                                                            ) {
                                                              userSel.tblEntidad.push(
                                                                {
                                                                  idCompañia:
                                                                    idCompañiaSel,
                                                                  tblEntidad:
                                                                    e.component.option(
                                                                      "selectedItems",
                                                                    ),
                                                                },
                                                              );
                                                            } else {
                                                              let idsEntidadesUsuario =
                                                                [];
                                                              $.each(
                                                                userSel.tblEntidad,
                                                                function (
                                                                  index,
                                                                  item,
                                                                ) {
                                                                  $.each(
                                                                    item.tblEntidad,
                                                                    function (
                                                                      iEntidad,
                                                                      entidad,
                                                                    ) {
                                                                      idsEntidadesUsuario.push(
                                                                        entidad.idEntidad,
                                                                      );
                                                                    },
                                                                  );
                                                                },
                                                              );

                                                              $.each(
                                                                e.addedItems,
                                                                function (
                                                                  iEntidad,
                                                                  entidad,
                                                                ) {
                                                                  if (
                                                                    $.inArray(
                                                                      entidad.idEntidad,
                                                                      idsEntidadesUsuario,
                                                                    ) === -1
                                                                  ) {
                                                                    let compaModificar =
                                                                      $.grep(
                                                                        userSel.tblEntidad,
                                                                        function (
                                                                          compañia,
                                                                        ) {
                                                                          return (
                                                                            compañia.idCompañia ===
                                                                            idCompañiaSel
                                                                          );
                                                                        },
                                                                      );

                                                                    if (
                                                                      compaModificar.length >
                                                                      0
                                                                    )
                                                                      compaModificar[0].tblEntidad.push(
                                                                        entidad,
                                                                      );
                                                                  }
                                                                },
                                                              );

                                                              $.each(
                                                                e.removedItems,
                                                                function (
                                                                  iEntidad,
                                                                  entidad,
                                                                ) {
                                                                  if (
                                                                    $.inArray(
                                                                      entidad.idEntidad,
                                                                      idsEntidadesUsuario,
                                                                    ) !== -1
                                                                  ) {
                                                                    let compaModificar =
                                                                      $.grep(
                                                                        userSel.tblEntidad,
                                                                        function (
                                                                          compañia,
                                                                        ) {
                                                                          return (
                                                                            compañia.idCompañia ===
                                                                            idCompañiaSel
                                                                          );
                                                                        },
                                                                      );
                                                                    if (
                                                                      compaModificar.length >
                                                                      0
                                                                    ) {
                                                                      let index;
                                                                      $.each(
                                                                        compaModificar[0]
                                                                          .tblEntidad,
                                                                        function (
                                                                          iEntidad_,
                                                                          entidad_,
                                                                        ) {
                                                                          if (
                                                                            entidad_.idEntidad ===
                                                                            entidad.idEntidad
                                                                          ) {
                                                                            index =
                                                                              iEntidad_;
                                                                          }
                                                                        },
                                                                      );

                                                                      if (
                                                                        index !=
                                                                        null
                                                                      )
                                                                        compaModificar[0].tblEntidad.splice(
                                                                          index,
                                                                          1,
                                                                        );
                                                                    }
                                                                  }
                                                                },
                                                              );
                                                            }

                                                            // Borrar compañias sin entidades
                                                            userSel.tblEntidad =
                                                              $.grep(
                                                                userSel.tblEntidad,
                                                                function (
                                                                  item,
                                                                ) {
                                                                  if (
                                                                    typeof item.tblEntidad !==
                                                                    "undefined"
                                                                  )
                                                                    return (
                                                                      item
                                                                        .tblEntidad
                                                                        .length >
                                                                      0
                                                                    );
                                                                },
                                                              );

                                                            setTimeout(() => {
                                                              setCheckBoxCompañia();
                                                              setCheckBoxLavanderia();
                                                              $(
                                                                "#dxList_resumenLavanderiasSel",
                                                              )
                                                                .dxList(
                                                                  "instance",
                                                                )
                                                                .option(
                                                                  "dataSource",
                                                                  getDatasourceResumenList(),
                                                                );
                                                            }, 0);
                                                          }
                                                        },
                                                      itemTemplate: function (
                                                        data,
                                                        index,
                                                      ) {
                                                        return $("<div>").text(
                                                          data.denominacion,
                                                        );
                                                      },
                                                    });
                                                  },
                                                },
                                              ],
                                            });
                                        },
                                      },
                                      {
                                        ratio: 1,
                                        template: function () {
                                          // ENTIDADES
                                          return $(
                                            "<div id='dxBox_resumenLavanderiasSel' />",
                                          ).dxBox({
                                            direction: "col",
                                            height: "100%",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            items: [
                                              {
                                                baseSize: 34,
                                                shrink: 0,
                                                ratio: 0,
                                                template: function () {
                                                  return $(
                                                    "<div class='font-size'>" +
                                                      getTrad("resumen") +
                                                      "</div>",
                                                  );
                                                },
                                              },
                                              {
                                                ratio: 1,
                                                template: function () {
                                                  return $(
                                                    "<div id='dxList_resumenLavanderiasSel'>",
                                                  ).dxList({
                                                    focusStateEnabled: false,
                                                    activeStateEnabled: false,
                                                    hoverStateEnabled: false,
                                                    allowItemDeleting: true,
                                                    readOnly: true,
                                                    height: "100%",
                                                    width: "100%",
                                                    scrolling: {
                                                      mode: "infinite",
                                                    },
                                                    pageLoadMode:
                                                      "scrollBottom",
                                                    itemDeleteMode: "static",
                                                    noDataText:
                                                      "Sin entidades seleccionadas",
                                                    displayExpr: "denominacion",
                                                    //EVENTOS
                                                    onItemDeleting: function (
                                                      e,
                                                    ) {
                                                      let { idEntidad } =
                                                        e.itemData;
                                                      let dxList_entidades =
                                                        $(
                                                          "#dxList_entidades",
                                                        ).dxList("instance");

                                                      userSel.tblEntidad =
                                                        userSel.tblEntidad.map(
                                                          (compañias) => {
                                                            return {
                                                              ...compañias,
                                                              tblEntidad:
                                                                compañias.tblEntidad.filter(
                                                                  (entidad) =>
                                                                    entidad.idEntidad !==
                                                                    idEntidad,
                                                                ),
                                                            };
                                                          },
                                                        );

                                                      let filteredKeys = $.grep(
                                                        dxList_entidades.option(
                                                          "selectedItemKeys",
                                                        ),
                                                        function (item) {
                                                          return (
                                                            item !== idEntidad
                                                          );
                                                        },
                                                      );
                                                      dxList_entidades.option(
                                                        "selectedItemKeys",
                                                        filteredKeys,
                                                      );

                                                      setCheckBoxLavanderia();
                                                    },
                                                  });
                                                },
                                              },
                                            ],
                                          });
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        template: function () {
                          return $("<div />").dxBox({
                            direction: "col",
                            height: "100%",
                            items: [
                              {
                                baseSize: 50,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='dxToolbar_usuarios' />",
                                  ).dxToolbar({
                                    items: [
                                      {
                                        location: "before",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("atras"),
                                          icon: "chevronleft",
                                          onClick: function () {
                                            var subtipoUser =
                                                userSel.subtipoUsuario,
                                              dxTabPanel_principal = $(
                                                "#dxTabPanel_principal",
                                              ).dxTabPanel("instance");
                                            if (
                                              subtipoUser === 0 ||
                                              subtipoUser === 2
                                            )
                                              dxTabPanel_principal.option(
                                                "selectedIndex",
                                                is_FormLavanderia()
                                                  ? 4
                                                  : is_centrosTrabajo()
                                                    ? 3
                                                    : 1,
                                              );
                                            else if (subtipoUser === 1)
                                              dxTabPanel_principal.option(
                                                "selectedIndex",
                                                is_centrosTrabajo()
                                                  ? 3
                                                  : is_FormLavanderia()
                                                    ? 2
                                                    : 1,
                                              );
                                          },
                                        },
                                      },
                                      {
                                        location: "after",
                                        widget: "dxButton",
                                        options: {
                                          text: getTrad("guardar"),
                                          type: "success",
                                          onClick: function () {
                                            var tblLavanderia = [];
                                            $.each(
                                              userSel.tblLavanderia,
                                              function (index, value) {
                                                var tblLavanderiaSel = {};

                                                if (value === true) {
                                                  tblLavanderiaSel.idLavanderia =
                                                    index;
                                                  tblLavanderia.push(
                                                    tblLavanderiaSel,
                                                  );
                                                }
                                              },
                                            );
                                            COMPONENT.loadPanel_show(true);
                                            //#region FORMATEAR DATOS PARA WEBAPI
                                            userSel.tblLavanderia =
                                              tblLavanderia;

                                            var tblEntidad = [];
                                            $.each(
                                              userSel.tblEntidad,
                                              function (index, compañia) {
                                                $.each(
                                                  compañia.tblEntidad,
                                                  function (index, entidad) {
                                                    var tblEntidadSel = {};
                                                    tblEntidadSel.idEntidad =
                                                      entidad.idEntidad;
                                                    tblEntidad.push(
                                                      tblEntidadSel,
                                                    );
                                                  },
                                                );
                                              },
                                            );
                                            userSel.tblEntidad = tblEntidad;

                                            var tblFormularioNUsuario = [];
                                            $.each(
                                              userSel.tblFormulario,
                                              function (index, value) {
                                                $.each(
                                                  value.tblFormulario,
                                                  function (itemIndex, item) {
                                                    if (
                                                      item.isEscritura ||
                                                      item.isBorrado
                                                    ) {
                                                      var tblFormularioSel = {
                                                        idFormulario:
                                                          item.idFormulario,
                                                        isEscritura:
                                                          item.isEscritura,
                                                        isBorrado:
                                                          item.isBorrado,
                                                      };

                                                      if (
                                                        item.idFormulario === 41
                                                      ) {
                                                        //Personal
                                                        tblFormularioNUsuario.push(
                                                          {
                                                            idFormulario: 119, // PersonalGeneral
                                                            isEscritura:
                                                              item.isEscritura,
                                                            isBorrado:
                                                              item.isBorrado,
                                                          },
                                                        );
                                                      }
                                                      if (
                                                        item.idFormulario === 63
                                                      ) {
                                                        //Calendario laboral
                                                        tblFormularioNUsuario.push(
                                                          {
                                                            idFormulario: 122, //Calendario Laboral RRHH
                                                            isEscritura:
                                                              item.isEscritura,
                                                            isBorrado:
                                                              item.isBorrado,
                                                          },
                                                        );
                                                      }
                                                      tblFormularioNUsuario.push(
                                                        tblFormularioSel,
                                                      );
                                                    }
                                                  },
                                                );
                                              },
                                            );

                                            userSel.tblCentroTrabajo = $.map(
                                              userSel.tblCentroTrabajo,
                                              function (item) {
                                                return {
                                                  idCentroTrabajo:
                                                    item.idCentroTrabajo,
                                                };
                                              },
                                            );

                                            userSel.tblFormularioNUsuario =
                                              tblFormularioNUsuario;
                                            delete userSel.tblFormulario;
                                            delete userSel.ultimaConexion;
                                            delete userSel.isGestionaUsuarios;
                                            delete userSel.subtipoUsuarioPedidos;
                                            delete userSel.isEncuesta;
                                            delete userSel.fechaCreacion;

                                            if (userSel.subtipoUsuario != 2)
                                              delete userSel.password;

                                            //#endregion

                                            let dxDataGrid_Usuarios = $(
                                                "#dxDataGrid_Usuarios",
                                              ).dxDataGrid("instance"),
                                              dxTabPanel_principal = $(
                                                "#dxTabPanel_principal",
                                              ).dxTabPanel("instance");
                                            userSel.tblPersona =
                                              $.isEmptyObject(
                                                userSel.tblPersona,
                                              ) || userSel.idPersona !== -1
                                                ? null
                                                : userSel.tblPersona;
                                            userSel.idPersona =
                                              userSel.idPersona === -1
                                                ? null
                                                : userSel.idPersona;

                                            try {
                                              if (!userSel.idUsuario) {
                                                // INSERT
                                                delete userSel.idUsuario;
                                                let actualDate =
                                                  formatDateTime_parameter(
                                                    new Date(),
                                                  );
                                                actualDate = actualDate.replace(
                                                  "Z",
                                                  "",
                                                ); // Elimina la Z (último caracter date)
                                                actualDate += formatTimeOffset(
                                                  new Date(),
                                                );

                                                userSel.fechaCreacion =
                                                  actualDate;
                                                datasource_usuarios
                                                  .store()
                                                  .insert(userSel)
                                                  .done(function () {
                                                    dxDataGrid_Usuarios.refresh();
                                                    userSel = null;
                                                    dxTabPanel_principal.option(
                                                      "selectedIndex",
                                                      0,
                                                    );
                                                    $("#dxTabPanel_formUser")
                                                      .dxTabPanel("instance")
                                                      .option(
                                                        "selectedIndex",
                                                        0,
                                                      );

                                                    notify({
                                                      message: getTrad(
                                                        "aviso_C_RegistroActualizado",
                                                      ),
                                                      type: "success",
                                                      displayTime: "1500",
                                                      closeOnClick: true,
                                                    });
                                                    COMPONENT.loadPanel_hide();
                                                  })
                                                  .fail(function (error) {
                                                    COMPONENT.loadPanel_hide();
                                                    dxMensajePregunta(
                                                      getTrad(
                                                        "aviso_RegistroNoInsertado",
                                                      ),
                                                      [
                                                        [
                                                          getTrad("aceptar"),
                                                          function () {
                                                            dxDataGrid_Usuarios.refresh();
                                                            userSel = null;
                                                            dxTabPanel_principal.option(
                                                              "selectedIndex",
                                                              0,
                                                            );
                                                          },
                                                          "normal",
                                                          "btnAceptar_alert",
                                                        ],
                                                      ],
                                                    );
                                                  });
                                              } // UPDATE
                                              else {
                                                datasource_usuarios
                                                  .store()
                                                  .update(
                                                    userSel.idUsuario,
                                                    userSel,
                                                  )
                                                  .done(
                                                    function (resultUsuario) {
                                                      dxDataGrid_Usuarios.refresh();
                                                      userSel = null;
                                                      dxTabPanel_principal.option(
                                                        "selectedIndex",
                                                        0,
                                                      );
                                                      $("#dxTabPanel_formUser")
                                                        .dxTabPanel("instance")
                                                        .option(
                                                          "selectedIndex",
                                                          0,
                                                        );

                                                      notify({
                                                        message: getTrad(
                                                          "aviso_C_RegistroActualizado",
                                                        ),
                                                        type: "success",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                      });
                                                      COMPONENT.loadPanel_hide();
                                                    },
                                                  )
                                                  .fail(function (error) {
                                                    dxMensajePregunta(
                                                      getTrad(
                                                        "aviso_RegistroNoInsertado",
                                                      ),
                                                      [
                                                        [
                                                          getTrad("aceptar"),
                                                          function () {
                                                            dxDataGrid_Usuarios.refresh();
                                                            userSel = null;
                                                            dxTabPanel_principal.option(
                                                              "selectedIndex",
                                                              0,
                                                            );
                                                          },
                                                          "normal",
                                                          "btnAceptar_alert",
                                                        ],
                                                      ],
                                                    );
                                                    COMPONENT.loadPanel_hide();
                                                  });
                                              }
                                            } catch (error) {
                                              COMPONENT.loadPanel_hide();
                                            }
                                          },
                                        },
                                      },
                                    ],
                                  });
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='dxForm_ResumenDatosUser'>",
                                  ).dxForm({
                                    readOnly: true,
                                    height: "100%",
                                    labelLocation: "top",
                                    colCount: 2,
                                    items: [
                                      {
                                        itemType: "group",
                                        caption: getTrad("datosUsuario"),
                                        height: "100%",
                                        name: "datosUsuario",
                                        colCount: 1,
                                        items: [
                                          {
                                            itemType: "group",
                                            name: "grupoGeneral",
                                            colCount: 6,
                                            visibleIndex: 1,
                                            items: [
                                              {
                                                dataField: "nombre",
                                                label: {
                                                  text: getTrad("nombre"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 1,
                                              },
                                              {
                                                dataField: "usuario",
                                                label: {
                                                  text: getTrad("usuario"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 2,
                                              },
                                              {
                                                dataField: "subtipoUsuario",
                                                editorType: "dxSwitch",
                                                editorOptions: {
                                                  switchedOnText:
                                                    getTrad("lavanderia"),
                                                  switchedOffText:
                                                    getTrad("entidad"),
                                                  elementAttr: {
                                                    class: "no-disabled",
                                                  },
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "subtipoUsuario",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 3,
                                                visible:
                                                  userSel != null &&
                                                  userSel.subtipoUsuario !== 2,
                                              },
                                              {
                                                dataField:
                                                  "subtipoUsuarioPedidos",
                                                editorType: "dxTextBox",
                                                editorOptions: {
                                                  readOnly: true,
                                                  text: "Pedidos",
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "subtipoUsuario",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 3,
                                                visible:
                                                  userSel != null &&
                                                  userSel.subtipoUsuario === 2,
                                              },
                                              {
                                                dataField: "email",
                                                label: {
                                                  text: getTrad("email"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 4,
                                              },
                                              {
                                                dataField: "idLocalizacion",
                                                label: {
                                                  text: getTrad("localizacion"),
                                                },
                                                alignment: "left",
                                                allowHeaderFiltering: false,
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  dataSource:
                                                    datasource_tblLocalizacion.items(),
                                                  displayExpr: "denominacion",
                                                  valueExpr: "idLocalizacion",
                                                },
                                                colSpan: 2,
                                                visibleIndex: 5,
                                              },
                                              {
                                                dataField: "idIdioma",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  dataSource:
                                                    datasource_idiomas.items(),
                                                  valueExpr: "idIdioma",
                                                  displayExpr: "denominacion",
                                                },
                                                label: {
                                                  text: getTrad("idioma"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 6,
                                              },
                                              {
                                                dataField: "idCompañia",
                                                name: "idCompañia",
                                                editorType: "dxLookup",
                                                editorOptions: {
                                                  dataSource:
                                                    datasource_tblCompañia.items(),
                                                  valueExpr: "idCompañia",
                                                  displayExpr: "denominacion",
                                                  closeOnOutsideClick: true,
                                                  showPopupTitle: false,
                                                  showCancelButton: false,
                                                  placeholder: "",
                                                },
                                                label: {
                                                  text: getTrad("compañia"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 7,
                                              },
                                              {
                                                dataField: "idCargo",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  dataSource:
                                                    datasource_tblCargo.items(),
                                                  valueExpr: "idCargo",
                                                  displayExpr: "denominacion",
                                                },
                                                label: {
                                                  text: getTrad("cargo"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 8,
                                              },
                                              {
                                                dataField: "detallesCargo",
                                                label: {
                                                  text: getTrad(
                                                    "detallesCargo",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 9,
                                              },
                                              {
                                                dataField: "idFormularioInicio",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  dataSource:
                                                    dataSource_formularios,
                                                  valueExpr: "idFormulario",
                                                  displayExpr: "denominacion",
                                                  placeholder: "",
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "formularioInicio",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 10,
                                              },
                                              {
                                                dataField: "idLavanderiaInicio",
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  dataSource:
                                                    dataSource_lavanderias_todas.items(),
                                                  valueExpr: "idLavanderia",
                                                  displayExpr: "denominacion",
                                                  placeholder: "",
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "lavanderiaInicio",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 11,
                                              },
                                              {
                                                dataField: "isGestionaUsuarios",
                                                editorType: "dxSwitch",
                                                editorOptions: {
                                                  visible: estatusUsuario === 0,
                                                  switchedOnText:
                                                    getTrad("gestiona"),
                                                  switchedOffText:
                                                    getTrad("noGestiona"),
                                                },
                                                label: {
                                                  visible: estatusUsuario === 0,
                                                  text: getTrad(
                                                    "gestionaUsuarios",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 12,
                                              },
                                              {
                                                dataField: "enableDatosRRHH",
                                                editorType: "dxSwitch",
                                                editorOptions: {
                                                  visible: estatusUsuario === 0,
                                                  switchedOnText:
                                                    getTrad("activo"),
                                                  switchedOffText:
                                                    getTrad("noActivo"),
                                                },
                                                label: {
                                                  visible: estatusUsuario === 0,
                                                  text: getTrad(
                                                    "enableDatosRRHH",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 13,
                                              },
                                              {
                                                dataField: "refactura",
                                                editorType: "dxSwitch",
                                                editorOptions: {
                                                  visible: estatusUsuario === 0,
                                                  switchedOnText:
                                                    getTrad("activo"),
                                                  switchedOffText:
                                                    getTrad("noActivo"),
                                                },
                                                label: {
                                                  visible: estatusUsuario === 0,
                                                  text: getTrad("refactura"),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 14,
                                              },
                                              {
                                                dataField:
                                                  "isDepartamentoControl",
                                                editorType: "dxSwitch",
                                                editorOptions: {
                                                  visible: estatusUsuario === 0,
                                                  switchedOnText:
                                                    getTrad("activo"),
                                                  switchedOffText:
                                                    getTrad("noActivo"),
                                                },
                                                label: {
                                                  visible: estatusUsuario === 0,
                                                  text: getTrad(
                                                    "departamentoControl",
                                                  ),
                                                },
                                                colSpan: 2,
                                                visibleIndex: 14,
                                              },
                                            ],
                                          },
                                          {
                                            itemType: "group",
                                            name: "grupo_asociarPersona",
                                            colCount: 3,
                                            visibleIndex: 2,
                                            items: [
                                              {
                                                name: "idPersona",
                                                editorType: "dxTextBox",
                                                editorOptions: {
                                                  elementAttr: {
                                                    id: "dxTextBox_asociarPersona_resumen",
                                                  },
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "personaAsociada",
                                                  ),
                                                },
                                                colSpan: 1,
                                              },
                                            ],
                                          },
                                          {
                                            itemType: "group",
                                            name: "grupotblCategoriaInterna",
                                            colCount: 3,
                                            visibleIndex: 3,
                                            items: [
                                              {
                                                dataField:
                                                  "tblCategoriaInterna",
                                                editorType: "dxTagBox",
                                                editorOptions: {
                                                  elementAttr: {
                                                    id: "dxTagBox_tblCategoriaInterna_resumen",
                                                  },
                                                  dataSource:
                                                    tblCategoriaInterna_items_filtrados,
                                                  value: userSel
                                                    ? userSel.tblCategoriaInterna
                                                    : [],
                                                  valueExpr:
                                                    "idCategoriaInterna",
                                                  displayExpr: "denominacion",
                                                  grouped: true,
                                                  showSelectionControls: true,
                                                },
                                                label: {
                                                  text: getTrad(
                                                    "categoriaInterna",
                                                  ),
                                                },
                                                colSpan: 3,
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        itemType: "group",
                                        caption: getTrad("lavanderías"),
                                        cssClass:
                                          "formGroup_resumenLavanderias cursorDefault",
                                        colCount: 1,
                                        height: "100%",
                                        items: [
                                          {
                                            colSpan: 1,
                                            template: function (data, element) {
                                              return $("<div />").dxBox({
                                                direction: "col",
                                                elementAttr: {
                                                  id: "dxBox_resumenCentrosTrabajo",
                                                },
                                                align: "space-around",
                                                crossAlign: "stretch",
                                                height: "100%",
                                                items: [
                                                  {
                                                    ratio: 1,
                                                    template: function (
                                                      itemData,
                                                      itemIndex,
                                                      itemElement,
                                                    ) {
                                                      return $(
                                                        "<div id='dxList_resumenLavanderias'>",
                                                      ).dxList({
                                                        focusStateEnabled: false,
                                                        activeStateEnabled: false,
                                                        hoverStateEnabled: false,
                                                        readOnly: true,
                                                        height: "100%",
                                                        width: "100%",
                                                        scrolling: {
                                                          mode: "infinite",
                                                        },
                                                        pageLoadMode:
                                                          "scrollBottom",
                                                        // EVENTOS
                                                        itemTemplate: function (
                                                          data,
                                                          index,
                                                        ) {
                                                          return $(
                                                            "<div>",
                                                          ).text(
                                                            data.denominacion,
                                                          );
                                                        },
                                                      });
                                                    },
                                                  },
                                                  {
                                                    baseSize: 20,
                                                    shrink: 0,
                                                    ratio: 0,
                                                    visible:
                                                      userSel &&
                                                      is_centrosTrabajo(),
                                                  },
                                                  {
                                                    baseSize: 35,
                                                    shrink: 0,
                                                    ratio: 0,
                                                    visible:
                                                      userSel &&
                                                      is_centrosTrabajo(),
                                                    template: function () {
                                                      return $(
                                                        "<div style='font-size:20px;' />",
                                                      ).text(
                                                        getTrad(
                                                          "centrosTrabajo",
                                                        ),
                                                      );
                                                    },
                                                  },
                                                  {
                                                    ratio: 1,
                                                    visible:
                                                      userSel &&
                                                      is_centrosTrabajo(),
                                                    template: function (
                                                      itemData,
                                                      itemIndex,
                                                      itemElement,
                                                    ) {
                                                      return $(
                                                        "<div id='dxList_resumenCentrosTrabajo'>",
                                                      ).dxList({
                                                        focusStateEnabled: false,
                                                        activeStateEnabled: false,
                                                        hoverStateEnabled: false,
                                                        readOnly: true,
                                                        height: "100%",
                                                        width: "100%",
                                                        scrolling: {
                                                          mode: "infinite",
                                                        },
                                                        pageLoadMode:
                                                          "scrollBottom",
                                                        // EVENTOS
                                                        itemTemplate: function (
                                                          data,
                                                          index,
                                                        ) {
                                                          return $(
                                                            "<div>",
                                                          ).text(
                                                            data.denominacion,
                                                          );
                                                        },
                                                      });
                                                    },
                                                  },
                                                ],
                                              });
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                    ],
                    //EVENTOS
                    onSelectionChanged: function (e) {
                      var tabSelected = e.component.option("selectedIndex"),
                        dxList_entidades =
                          $("#dxList_entidades").dxList("instance"),
                        dxList_compañias =
                          $("#dxList_compañias").dxList("instance"),
                        dxList_lavanderias = $("#dxList_lavanderias").dxList(
                          "instance",
                        ),
                        dxList_centrosTrabajo = $(
                          "#dxList_centrosTrabajo",
                        ).dxList("instance");

                      switch (tabSelected) {
                        case 0: // SELECCIÓN DE USUARIOS
                          $("#dxContainer")
                            .dxBox("instance")
                            .option("items[0].visible", false);
                          dxList_entidades.option("selectedItemKeys", []);
                          // dxList_entidades.unselectAll();
                          dxList_compañias.unselectAll();
                          dxList_lavanderias.unselectAll();
                          dxList_centrosTrabajo.option("selectedItemKeys", []);
                          if (userSel) userSel.tblCategoriaInterna = [];
                          tblCategoriaInterna_items_filtrados = [];

                          $("#dxSelectBox_localizacion")
                            .dxSelectBox("instance")
                            .option("value", null);

                          if (
                            $(
                              "#dxScrollView_dxButtonGroup_formulariosNUsuario",
                            ).data("dxScrollView")
                          )
                            $("#dxScrollView_dxButtonGroup_formulariosNUsuario")
                              .dxScrollView("instance")
                              .scrollTo(0);
                          break;
                        case 1:
                          let formUsert_selectedIndex = $(
                              "#dxTabPanel_formUser",
                            )
                              .dxTabPanel("instance")
                              .option("selectedIndex"),
                            dxToolbar_usuarios = $(
                              ".dxToolbar_usuarios",
                            ).dxToolbar("instance");

                          //Deshabilitar siguiente si está en selección tipoUser/subtipoUsert
                          dxToolbar_usuarios.option(
                            "items[1].disabled",
                            $.inArray(formUsert_selectedIndex, [0, 1]) !== -1,
                          );
                          dxScrollView_formulariosNUsuario_resize();
                          break;
                        case 4: // SELECCIÓN DE ENTIDADES
                          dxList_lavanderias.option(
                            "dataSource",
                            dataSource_lavanderias_todas,
                          );
                          setCheckBoxLavanderia();
                          $("#dxList_resumenLavanderiasSel")
                            .dxList("instance")
                            .option("dataSource", getDatasourceResumenList());
                          break;
                        case 5:
                          if (
                            typeof $("#dxSelectBox_asociarPersona").dxSelectBox(
                              "instance",
                            ) !== "undefined"
                          ) {
                            let dxSelectBox_asociarPersona_datasource = $(
                                "#dxSelectBox_asociarPersona",
                              )
                                .dxSelectBox("instance")
                                .option("dataSource"),
                              dxTextBox_asociarPersona_resumen = $(
                                "#dxTextBox_asociarPersona_resumen",
                              ).dxTextBox("instance");

                            if (dxSelectBox_asociarPersona_datasource != null) {
                              if (
                                dxSelectBox_asociarPersona_datasource.length > 0
                              )
                                dxTextBox_asociarPersona_resumen.option(
                                  "value",
                                  dxSelectBox_asociarPersona_datasource[0]
                                    .nombreCompuesto,
                                );
                              else
                                dxTextBox_asociarPersona_resumen.option(
                                  "value",
                                  "",
                                );
                            }
                          }
                          $("#dxList_resumenLavanderias")
                            .dxList("instance")
                            .option("dataSource", getDatasourceResumenList());

                          let formRRHH = [];
                          $.each(userSel.tblFormulario, function (index, item) {
                            $.each(
                              item.tblFormulario,
                              function (index2, item2) {
                                // if(item2.idAplicacion === 2 && (item2.isBorrado || item2.isEscritura)){
                                if (
                                  item2.idApartado === 5 &&
                                  (item2.isBorrado || item2.isEscritura)
                                ) {
                                  formRRHH.push(item2);
                                }
                              },
                            );
                          });

                          $("#dxList_resumenCentrosTrabajo")
                            .dxList("instance")
                            .option({
                              visible: is_centrosTrabajo(),
                              dataSource: userSel.tblCentroTrabajo,
                            });
                          break;
                        default:
                          break;
                      }

                      if (typeof dxList_entidades !== "undefined") {
                        dxList_entidades.option("searchValue", "");
                        dxList_compañias.option("searchValue", "");
                        dxList_lavanderias.option("searchValue", "");
                        dxList_centrosTrabajo.option("searchValue", "");
                      }

                      if (
                        typeof $("#dxAccordion_formularioVisibles").data(
                          "dxAccordion",
                        ) !== "undefined"
                      )
                        $("#dxAccordion_formularioVisibles")
                          .dxAccordion("instance")
                          .option("selectedIndex", -1);

                      $("#dxNavbarUsuarios")
                        .dxNavBar("instance")
                        .option("selectedIndex", tabSelected - 1);
                    },
                  }),
              );
            },
          },
        ],
      });

      $("#dxLoadPanel_formularios").dxLoadPanel({
        shadingColor: "rgba(255, 255, 255, 0.699)",
        showIndicator: true,
        showPane: true,
        shading: true,
        message: getTrad("cargando") + " ...",
        position: {
          my: "center",
          at: "center",
          of: "#dxScrollView_formulariosNUsuario",
        },
      });

      $("#Usuarios #dxPopup_importarPermisos").dxPopup({
        width: 600,
        height: 600,
        showTitle: false,
        closeOnOutsideClick: true,
        deferRendering: false,
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div id='dxDataGrid_copiarPermisos' />").dxDataGrid({
              dataSource: datasource_usuarios.store(),
              hoverStateEnabled: true,
              paging: { enabled: true, pageSize: 10 },
              filterRow: { visible: true },
              scrolling: { mode: "infinite" },
              selection: { mode: "single" },
              sorting: { mode: "none" },
              height: "100%",
              columns: [
                {
                  dataField: "nombre",
                  caption: getTrad("nombreUsuario"),
                  alignment: "left",
                  allowHeaderFiltering: false,
                },
                {
                  dataField: "usuario",
                  caption: getTrad("usuario"),
                  alignment: "left",
                  allowHeaderFiltering: false,
                },
                {
                  dataField: "idCargo",
                  caption: getTrad("cargo"),
                  minWidth: 215,
                  alignment: "left",
                  allowHeaderFiltering: false,
                  lookup: {
                    dataSource: datasource_tblCargo.items(),
                    displayExpr: "denominacion",
                    valueExpr: "idCargo",
                  },
                },
              ],
              onSelectionChanged: function (selectedItems) {
                let selData = selectedItems.selectedRowsData;
                if (selData.length > 0) {
                  if (selData[0].subtipoUsuario === userSel.subtipoUsuario) {
                    let dxAccordion_formularioVisibles = $(
                        "#dxAccordion_formularioVisibles",
                      ).dxAccordion("instance"),
                      dxForm_datosUser =
                        $("#dxForm_datosUser").dxForm("instance"),
                      selectedData = selectedItems.selectedRowsData[0],
                      cargo = selectedData.idCargo,
                      dxPopup_importarPermisos = $(
                        "#dxPopup_importarPermisos",
                      ).dxPopup("instance");
                    idUsuarioImportar = selectedData.idUsuario;

                    function _importarUser() {
                      COMPONENT.loadPanel_show(true);
                      dxAccordion_formularioVisibles.beginUpdate();
                      let dxSwitch_subtipoUser;
                      if (userSel.subtipoUsuario !== 2) {
                        dxSwitch_subtipoUser = $(
                          "#dxSwitch_subtipoUser",
                        ).dxSwitch("instance");
                        dxSwitch_subtipoUser.option(
                          "isImportandoUsuario",
                          true,
                        );
                      }

                      isImportandoUsuario = true;
                      let cargoPrevio = dxForm_datosUser
                          .getEditor("idCargo")
                          .option("value"),
                        subTipoUser_previo = userSel.subtipoUsuario;

                      dxForm_datosUser
                        .getEditor("idCargo")
                        .option({
                          dataSource: filtrar_tblCargo(),
                          value: cargo,
                        });
                      dxForm_datosUser
                        .getEditor("detallesCargo")
                        .option("value", selectedData.detallesCargo);

                      $.when(
                        datasource_moduloNLavanderia.load(),
                        datasource_formulariosNApartado.load(),
                        datasource_entidadesNUsuarioPadre.load(),
                        datasource_entidadNUsuario.load(),
                        datasource_tblCentroTrabajoNUsuario.load(),
                      ).then(function () {
                        //#region Set switch tipo user
                        var lavanderiaNUser = $.grep(
                          userSel.tblLavanderia,
                          function (isLav) {
                            return isLav;
                          },
                        );
                        if (userSel.subtipoUsuario !== 2)
                          dxSwitch_subtipoUser.option(
                            "value",
                            userSel.subtipoUsuario === 1 ||
                              userSel.subtipoUsuario === 3
                              ? true
                              : false,
                          );
                        //#endregion

                        if (cargoPrevio === cargo) isImportandoUsuario = false;
                        if (
                          userSel.subtipoUsuario !== 2 &&
                          subTipoUser_previo === userSel.subtipoUsuario
                        )
                          dxSwitch_subtipoUser.option(
                            "isImportandoUsuario",
                            false,
                          );

                        dxAccordion_formularioVisibles.option({
                          dataSource: userSel.tblFormulario,
                          selectedIndex: -1,
                        });

                        // filtraje entidades y lavanderias por las que puede ver el user padre,
                        // si lo hago en el datasource, me lo filtrará siempre y cuando
                        // modifique un usuario cuyo padre no ve una entidad la borraría
                        if (lavanderiaNUser.length > 0) {
                          var lavsUserLog = JSON.parse(
                            localStorage.getItem("lavanderias"),
                          );

                          $.each(userSel.tblLavanderia, function (index, item) {
                            if (index === 0) return true;

                            var is_inUserPadre = lavsUserLog.find(
                              (x) => x.idLavanderia === index,
                            );
                            userSel.tblLavanderia[index] = is_inUserPadre
                              ? item
                              : false;
                          });
                        } else {
                          var tblEntidad_filtradas = [];
                          $.grep(userSel.tblEntidad, function (itemComp) {
                            var entidadesFiltradas = $.grep(
                              itemComp.tblEntidad,
                              function (itemEnti) {
                                var is_inUserPadre =
                                  datasource_entidadesNUsuarioPadre
                                    .items()
                                    .find(
                                      (x) => x.idEntidad === itemEnti.idEntidad,
                                    );
                                return is_inUserPadre;
                              },
                            );
                            itemComp.tblEntidad = entidadesFiltradas;
                            tblEntidad_filtradas.push(itemComp);
                          });
                          userSel.tblEntidad = tblEntidad_filtradas;
                        }

                        var idsCentro = [];
                        let centros =
                          datasource_tblCentroTrabajoNUsuario.items();
                        Object.keys(centros).forEach(function (key) {
                          var id = centros[key]["idCentroTrabajo"];
                          idsCentro.push(id);
                        });
                        userSel.tblCentroTrabajo = idsCentro;
                        $("#dxList_centrosTrabajo")
                          .dxList("instance")
                          .option("selectedItemKeys", userSel.tblCentroTrabajo);

                        let isUserLavanderia =
                          userSel.subtipoUsuario === 1 ||
                          userSel.subtipoUsuario === 3;
                        dxNavbar_disableItems(isUserLavanderia);

                        $("#dxPivotGrid_formulariosNLavanderia")
                          .dxPivotGrid("instance")
                          .repaint();

                        dxAccordion_formularioVisibles.repaint();
                        dxAccordion_formularioVisibles.endUpdate();
                        $("#dxButtonGroup_formularioVisibles")
                          .dxButtonGroup("instance")
                          .repaint();
                        dxPopup_importarPermisos.hide();
                        checkFormularioInicio();

                        COMPONENT.loadPanel_hide();
                      });
                      dxPopup_importarPermisos.hide();
                    }

                    if (is_FormSelected()) {
                      //tiene campos seleccionados
                      dxMensajePregunta(getTrad("preg_PerderCambios"), [
                        // ACEPTAR
                        [
                          getTrad("aceptar"),
                          function () {
                            _importarUser();
                          },
                          "normal",
                          "btnAceptar",
                        ],
                        // CANCELAR
                        [
                          getTrad("cancelar"),
                          function () {
                            selectedItems.component.deselectAll();
                          },
                          "normal",
                          "btnCancelar",
                        ],
                      ]);
                    } else {
                      datasource_tblCentroTrabajoNUsuario
                        .load()
                        .done(function () {
                          if (
                            datasource_tblCentroTrabajoNUsuario.items()
                              .length === 0 &&
                            is_centrosTrabajo()
                          ) {
                            dxMensajePregunta(
                              "Se eliminarán los centros de trabajo seleccionados. ¿Desea continuar?",
                              [
                                // ACEPTAR
                                [
                                  getTrad("aceptar"),
                                  function () {
                                    _importarUser();
                                  },
                                  "normal",
                                  "btnAceptar",
                                ],
                                // CANCELAR
                                [
                                  getTrad("cancelar"),
                                  function () {},
                                  "normal",
                                  "btnCancelar",
                                ],
                              ],
                            );
                          } else {
                            _importarUser();
                          }
                        });
                    }
                  } else {
                    notify({
                      message:
                        "El usuario seleccionado debe ser del mismo subtipo que el actual.",
                      type: "error",
                      displayTime: "1500",
                      closeOnClick: true,
                    });
                  }
                }
              },
              onRowPrepared: function (e) {
                if (e.rowType === "data") e.rowElement.css("cursor", "pointer");
              },
            }),
          );
        },
        onShowing: function (e) {
          $("#dxDataGrid_copiarPermisos")
            .dxDataGrid("instance")
            .filter(["idCargo", "<>", 1]);
        },
        onHiding: function (e) {
          idUsuarioImportar = null;
          let scrollGrid_permisos = $("#dxDataGrid_copiarPermisos")
            .dxDataGrid("instance")
            .getScrollable();

          if (typeof scrollGrid_permisos !== "undefined")
            scrollGrid_permisos.scrollTo(0);
        },
        onHidden: function (e) {
          let dxDataGrid_copiarPermisos = $(
            "#dxDataGrid_copiarPermisos",
          ).dxDataGrid("instance");
          dxDataGrid_copiarPermisos.clearFilter();
          dxDataGrid_copiarPermisos.deselectAll();
        },
      });

      $("#Usuarios #dxPopup_asociarPersona").dxPopup({
        title: getTrad("asociarPersona"),
        deferRendering: false,
        showTitle: true,
        width: "400px",
        height: "550px",
        contentTemplate: function (contentElement) {
          contentElement.append(
            $("<div>").dxBox({
              direction: "col",
              align: "space-around",
              crossAlign: "stretch",
              items: [
                {
                  ratio: 1,
                  template: function (e, index, item) {
                    // PERSONAS
                    var container = $("<div>");
                    var lista_compañias = $(
                      "<div id='dxList_personasNLav'>",
                    ).dxList({
                      dataSource: datasource_personaNLavanderia,
                      height: "400px",
                      scrolling: { mode: "infinite" },
                      pageLoadMode: "scrollBottom",
                      displayExpr: "nombreCompuesto",
                      searchEnabled: true,
                      selectionMode: "single",
                      // EVENTOS
                      onContentReady: function (e) {
                        e.component.unselectAll();
                      },
                      onSelectionChanged: function (e) {
                        let selection = e.addedItems;
                        if (selection.length > 0) {
                          userSel.tblPersona = {};
                          userSel.idPersona = selection[0].idPersona;
                          $("#dxSelectBox_asociarPersona")
                            .dxSelectBox("instance")
                            .option("value", userSel.idPersona);
                          $("#dxPopup_asociarPersona")
                            .dxPopup("instance")
                            .hide();
                        }
                      },
                    });
                    lista_compañias.appendTo(container);

                    return container;
                  },
                },
              ],
            }),
          );
        },
        onShowing: function (e) {
          $("#dxList_personasNLav").dxList("instance").reload();
        },
        onHiding: function (e) {
          let dxList_personasNLav = $("#dxList_personasNLav").dxList(
            "instance",
          );
          dxList_personasNLav.scrollTo(0);
          dxList_personasNLav.option("searchValue", "");
        },
      });

      $("#dxPopover_cargo")
        .dxPopover({
          position: "top",
          width: "auto",
          height: "auto",
          onContentReady: function (e) {
            var html = e.component.content();
            $(html).css("padding", 13);
          },
        })
        .dxPopover("instance");

      $("#dxPopover_logUsuario")
        .dxPopover({
          position: "left",
          width: "auto",
          height: "auto",
          onContentReady: function (e) {
            var html = e.component.content();
            $(html).css("padding", 13);
          },
        })
        .dxPopover("instance");
    })
    .always(function () {
      setTimeout(() => {
        if ($("#dxDataGrid_Usuarios").data("dxDataGrid"))
          $("#dxDataGrid_Usuarios").dxDataGrid("instance").repaint();
      }, 50);
      deferred.resolve();
    });

  function setCheckBoxCompañia() {
    let idsEntidadesUsuario = [];
    $.each(userSel.tblEntidad, function (index, item) {
      $.each(item.tblEntidad, function (iEntidad, entidad) {
        idsEntidadesUsuario.push(entidad.idEntidad);
      });
    });

    $.each(datasource_datos_compaEnti.items(), function (index, item) {
      let numEntiNCompa = item.tblEntidad.length;
      let numEntiSel = 0;

      $.each(item.tblEntidad, function (iEntidad, entidad) {
        if ($.inArray(entidad.idEntidad, idsEntidadesUsuario) !== -1) {
          numEntiSel += 1;
        }
      });
      let checkBox = $(".dxCheckBox_compañia_" + item.idCompañia).dxCheckBox(
        "instance",
      );
      if (checkBox) {
        checkBox.option(
          "value",
          numEntiNCompa == numEntiSel ? true : undefined,
        );

        if (numEntiSel == 0) checkBox.option("value", false);
      }
    });
  }

  function setCheckBoxLavanderia() {
    let idsEntidadesUsuario = [];
    $.each(userSel.tblEntidad, function (index, item) {
      $.each(item.tblEntidad, function (iEntidad, entidad) {
        idsEntidadesUsuario.push(entidad.idEntidad);
      });
    });

    $.each(dataSource_lavanderias_todas.items(), function (index, item) {
      if ($("#dxCheckBox_lavanderia_" + item.idLavanderia).data("dxCheckBox")) {
        let numEntidades = item.tblEntidad.length;
        let numEntiSel = 0;
        $.each(item.tblEntidad, function (iEntidad, entidad) {
          if ($.inArray(entidad.idEntidad, idsEntidadesUsuario) !== -1) {
            numEntiSel += 1;
          }
        });

        let checkBox = $(
          "#dxCheckBox_lavanderia_" + item.idLavanderia,
        ).dxCheckBox("instance");
        if (numEntiSel == 0) checkBox.option("value", false);
        else
          checkBox.option(
            "value",
            numEntidades == numEntiSel ? true : undefined,
          );
      }
    });
  }

  function setDataSource_asociarPersona(value) {
    let dxSelectBox_asociarPersona = $(
      "#dxSelectBox_asociarPersona",
    ).dxSelectBox("instance");
    if (dxSelectBox_asociarPersona) {
      let idPersona = value !== null ? value : -1;
      datasource_tblPersona.filter("idPersona eq " + idPersona);
      datasource_tblPersona.load().done(function (item) {
        dxSelectBox_asociarPersona.option({
          dataSource: item,
        });
      });
    }
  }

  function dxNavbar_disableItems(subtipoUser_value) {
    var dxNavbarUsuarios_items = $("#dxNavbarUsuarios")
      .dxNavBar("instance")
      .getDataSource()
      .items();
    var subtipoUserSel = $("#dxList_subtipoUsuario")
      .dxList("instance")
      .option("selectedIndex");

    $.each(dxNavbarUsuarios_items, function (index, item) {
      if (
        userSel.idUsuario === null &&
        subtipoUserSel === -1 &&
        item.id !== "dxButton_centrosTrabajo"
      )
        item.disabled = true;
      else {
        if (item.id === "dxButton_lavanderias")
          item.disabled = !subtipoUser_value || !is_FormLavanderia();
        else if (item.id === "dxButton_entidades")
          item.disabled = subtipoUser_value || !is_FormLavanderia();
        else if (item.id === "dxButton_centrosTrabajo") {
          item.disabled = !is_centrosTrabajo();
        } else item.disabled = false;
      }
    });
    $("#dxNavbarUsuarios").dxNavBar("instance").repaint();
  }

  function is_FormSelected() {
    var is_FormSelected = false;
    $.each(userSel.tblFormulario, function (index, value) {
      $.each(value.tblFormulario, function (itemIndex, item) {
        if (item.isEscritura || item.isBorrado) {
          is_FormSelected = true;
          return false;
        }
      });
    });
    return is_FormSelected;
  }

  function setApartado_checkBox(itemData) {
    let formulariosSel = [];
    let formulariosNApp = $.extend(true, [], itemData.tblFormulario).filter(
      (x) => x.idAplicacion == idAplicacionSel,
    );

    $.each(formulariosNApp, function (index, item) {
      if (item.isEscritura === true) formulariosSel.push(true);
    });

    return formulariosSel.length === 0
      ? false
      : formulariosSel.length === formulariosNApp.length
        ? true
        : undefined;
  }

  function dxPivotGridSelection_reset() {
    $.each(userSel.tblLavanderia, function (index, item) {
      if (typeof item !== "undefined") userSel.tblLavanderia[index] = false;
    });
    $("#dxPivotGrid_formulariosNLavanderia").dxPivotGrid("instance").repaint();
  }

  function filtrar_formulariosApp(idAplicacionSel) {
    $.each(userSel.tblFormulario, function (index, item) {
      let hasForm_visible = false;
      $.each(item.tblFormulario, function (i2, item2) {
        if (item2.idAplicacion == idAplicacionSel) hasForm_visible = true;
        item2.visible = item2.idAplicacion == idAplicacionSel;
      });
      if (item.idApartado !== 1 && item.idApartado !== 20)
        item.visible = hasForm_visible;
    });
  }

  function filtrar_tblCargo() {
    if (userSel !== null) {
      let tblCargo_filtrado = $.grep(
        datasource_tblCargo.items(),
        function (item) {
          return (
            ((userSel.idCargo === 1 || userSel.idCargo === 2) &&
              userSel.idCargo === item.idCargo) || // usuario es desarrollador o master ve su propio cago
            (USER.idCargo === 1 && item.idCargo === 2) || // Si el user logeado es desarrollador, puede escoger master
            ((item.idCargo === 3 || // cargo otros
              (item.isEntidad &&
                (userSel.subtipoUsuario === 0 ||
                  userSel.subtipoUsuario === 2)) ||
              (!item.isEntidad &&
                item.isEntidad !== null &&
                userSel.subtipoUsuario != 0 &&
                userSel.subtipoUsuario != 2)) &&
              item.idCargo != 12)
          );
        },
      );

      let cargosFiltrados = tblCargo_filtrado.sort(function (item) {
        if (item.idCargo !== 3) return -1;
        else return 1;
      });

      return cargosFiltrados;
    }
    return datasource_tblCargo.items();
  }

  function getDenoLavActivas() {
    var tblLavanderia = [];
    $.each(userSel.tblLavanderia, function (index, value) {
      var tblLavanderiaSel = {};
      if (value === true) {
        tblLavanderiaSel.idLavanderia = index;
        $.each(dataSource_lavanderias_todas.items(), function (index2, item2) {
          if (item2.idLavanderia === index)
            tblLavanderiaSel.denominacion = item2.denominacion;
        });
        tblLavanderia.push(tblLavanderiaSel);
      }
    });
    return tblLavanderia;
  }

  function getDatasourceResumenList() {
    if (userSel) {
      var lavanderiaNUser = $.grep(userSel.tblLavanderia, function (isLav) {
        return isLav;
      });

      if (lavanderiaNUser.length > 0) {
        return getDenoLavActivas();
      } else {
        var entidades = [];
        $.each(userSel.tblEntidad, function (index, item) {
          $.each(item.tblEntidad, function (index, entidad) {
            entidades.push(entidad);
          });
        });
        return entidades.sort(function (a, b) {
          return a.denominacion
            .toUpperCase()
            .localeCompare(b.denominacion.toUpperCase());
        });
      }
    } else return [];
  }

  function setResumenForm() {
    var dxForm_ResumenDatosUser = $("#dxForm_ResumenDatosUser").dxForm(
        "instance",
      ),
      resumenForm_items = dxForm_ResumenDatosUser.option("items"),
      dxList_resumenLavanderias = $("#dxList_resumenLavanderias").dxList(
        "instance",
      );

    resumenForm_items[1].caption = getTrad("lavanderías");
    dxForm_ResumenDatosUser.option("items", resumenForm_items);

    if (userSel.subtipoUsuario === 1 || userSel.subtipoUsuario === 3) {
      resumenForm_items[1].caption = getTrad("lavanderías");
      dxForm_ResumenDatosUser.option("items", resumenForm_items);

      dxList_resumenLavanderias.option(
        "dataSource",
        getDatasourceResumenList(),
      );
    } else {
      resumenForm_items[1].caption = getTrad("entidades");
      dxForm_ResumenDatosUser.option("items", resumenForm_items);

      dxList_resumenLavanderias.option(
        "dataSource",
        getDatasourceResumenList(),
      );
    }

    if (is_centrosTrabajo()) {
      $("#dxList_resumenCentrosTrabajo")
        .dxList("instance")
        .option("dataSource", userSel.tblCentroTrabajo);
    }

    var formData = $.extend(true, {}, userSel);
    formData.subtipoUsuarioPedidos = "Pedidos";

    formData.tblCategoriaInterna = $.map(
      formData.tblCategoriaInterna,
      function (item) {
        return item.idCategoriaInterna;
      },
    );

    if ($("#dxTagBox_tblCategoriaInterna_resumen").data("dxTagBox")) {
      let dxTagBox_tblCategoriaInterna = $(
        "#dxTagBox_tblCategoriaInterna",
      ).dxTagBox("instance");
      let dxTagBox_tblCategoriaInterna_resumen = $(
        "#dxTagBox_tblCategoriaInterna_resumen",
      ).dxTagBox("instance");

      dxTagBox_tblCategoriaInterna_resumen.option(
        "dataSource",
        tblCategoriaInterna_items_filtrados,
      );
      dxTagBox_tblCategoriaInterna_resumen.option(
        "value",
        dxTagBox_tblCategoriaInterna.option("value"),
      );
    }

    dxForm_ResumenDatosUser.option("formData", formData);
  }

  function check_subtipoUsuario() {
    var subtipoUser = userSel.subtipoUsuario,
      dxNavbarUsuarios = $("#dxNavbarUsuarios").dxNavBar("instance"),
      dxTabPanel_principal = $("#dxTabPanel_principal").dxTabPanel("instance");

    var lavanderiasSel = $.grep(
      userSel.tblLavanderia,
      function (itemData, itemIndex) {
        return itemData;
      },
    );

    if (
      (subtipoUser === 0 || subtipoUser === 2) &&
      userSel.tblEntidad.length === 0 &&
      is_FormLavanderia()
    ) {
      dxNavbarUsuarios.option(
        "selectedIndex",
        dxTabPanel_principal.option("selectedIndex") - 1,
      );
      dxMensajePregunta(getTrad("alerta_seleccionarEntidad"), [
        [getTrad("aceptar"), function (e) {}, "danger", "btnError_lavanderia"],
      ]);
      return false;
    } else if (
      (subtipoUser === 1 || subtipoUser === 3) &&
      lavanderiasSel.length === 0 &&
      is_FormLavanderia()
    ) {
      dxNavbarUsuarios.option(
        "selectedIndex",
        dxTabPanel_principal.option("selectedIndex") - 1,
      );
      dxMensajePregunta(getTrad("alerta_seleccionarLavanderia"), [
        [getTrad("aceptar"), function (e) {}, "danger", "btnError_lavanderia"],
      ]);
      return false;
    }
    return true;
  }

  function is_FormLavanderia() {
    var tblFormularioLavanderiaNUsuario = [];
    $.each(userSel.tblFormulario, function (index, value) {
      $.each(value.tblFormulario, function (itemIndex, item) {
        if ((item.isEscritura || item.isBorrado) && item.idAplicacion === 1) {
          //&& item.idApartado != 5
          tblFormularioLavanderiaNUsuario.push(item);
        }
      });
    });

    return tblFormularioLavanderiaNUsuario.length > 0;
  }

  function is_FormRecambio() {
    var tblFormularioLavanderiaNUsuario = [];
    $.each(userSel.tblFormulario, function (index, value) {
      $.each(value.tblFormulario, function (itemIndex, item) {
        if ((item.isEscritura || item.isBorrado) && item.idAplicacion === 3) {
          tblFormularioLavanderiaNUsuario.push(item);
        }
      });
    });

    return tblFormularioLavanderiaNUsuario.length > 0;
  }

  function is_centrosTrabajo() {
    let formRRHH = [];
    $.each(userSel.tblFormulario, function (index, item) {
      $.each(item.tblFormulario, function (index2, item2) {
        // if(item2.idAplicacion === 2 && (item2.isBorrado || item2.isEscritura)){
        if (item2.idApartado === 5 && (item2.isBorrado || item2.isEscritura)) {
          formRRHH.push(item2);
        }
      });
    });

    return formRRHH.length > 0;
  }

  function checkFormularioInicio() {
    var is_formularioInicio = false;

    $.each(
      $.extend(true, {}, userSel.tblFormulario),
      function (iApartado, apartado) {
        $.each(apartado.tblFormulario, function (iFormulario, formulario) {
          if (
            (formulario.isEscritura || formulario.isBorrado) &&
            formulario.idFormulario === userSel.idFormularioInicio
          )
            is_formularioInicio = true;
        });
      },
    );

    if (is_formularioInicio === false) userSel.idFormularioInicio = null;

    if ($("#dxDropDownBox_formularioInicio").data("dxDropDownBox") != null)
      $("#dxDropDownBox_formularioInicio")
        .dxDropDownBox("instance")
        .option("value", userSel.idFormularioInicio);
  }

  function loadUserData() {
    var dxTabPanel_formUser = $("#dxTabPanel_formUser").dxTabPanel("instance"),
      dxList_tipoUsuario = $("#dxList_tipoUsuario").dxList("instance"),
      dxList_subtipoUsuario = $("#dxList_subtipoUsuario").dxList("instance"),
      dxForm_datosUser = $("#dxForm_datosUser").dxForm("instance"),
      dxForm_ResumenDatosUser = $("#dxForm_ResumenDatosUser").dxForm(
        "instance",
      );

    if ($("#dxSwitch_subtipoUser").data("dxSwitch") != null)
      $("#dxSwitch_subtipoUser")
        .dxSwitch("instance")
        .option("inicializarValue", true);

    let dxButtonGroup_formularioVisibles = $(
      "#dxButtonGroup_formularioVisibles",
    ).dxButtonGroup("instance");
    if ($("#dxButtonGroup_formularioVisibles").data("dxButtonGroup"))
      dxButtonGroup_formularioVisibles.option("selectedItemKeys", [1]);

    if (userSel) {
      userSel.tblLavanderia = [];
      userSel.tblEntidad = [];
      dxForm_datosUser.option("formData", userSel);
      dxTabPanel_formUser.option("selectedIndex", 2);
    } else {
      dxList_tipoUsuario.repaint();
      dxList_subtipoUsuario.repaint();

      dxForm_datosUser.resetValues();
      userSel = dxForm_datosUser.option("formData");
      userSel.idUsuario = null;
      userSel.idPersona = null;
      userSel.subtipoUsuario = null;

      //#region Elección tipo usuario
      dxTabPanel_formUser.option("selectedIndex", 0);
      dxList_tipoUsuario.unselectAll();
      dxList_subtipoUsuario.unselectAll();
      //#endregion
      userSel.tblLavanderia = [];
      userSel.tblEntidad = [];
    }
    userSel.tblPersona = {};
    dxForm_ResumenDatosUser.resetValues();

    // MOSTRAR NAVBAR
    var dxContainer = $("#dxContainer").dxBox("instance");
    setTimeout(() => {
      dxContainer.option("items[0].visible", true);
      dxContainer.repaint();
    }, 0);

    //#region Si tiene alguna lavandería asignada, el usuario trabaja por entidad, no por lavandería
    if (userSel.idUsuario === null) userSel.tblFormulario = [];

    let isUserLavanderia =
      userSel.subtipoUsuario === 1 || userSel.subtipoUsuario === 3;
    if ($("#dxSwitch_subtipoUser").data("dxSwitch") != null) {
      var dxSwitch_subtipoUser = $("#dxSwitch_subtipoUser").dxSwitch(
        "instance",
      );
      dxSwitch_subtipoUser.option({
        inicializarValue: true,
        value: userSel.subtipoUsuario !== 2 ? isUserLavanderia : false,
      });
      dxSwitch_subtipoUser.option("inicializarValue", false); // para que al cargar el tipo de usuario si es diferente al por defecto no salte el mensaje de alerta.
    }

    let isReenviarVisible =
        userSel.idUsuario !== null ? userSel.ultimaConexion === null : false,
      isAsociarPersona_visible =
        isUserLavanderia && userSel.idTipoUsuario === 2;

    dxForm_ResumenDatosUser.itemOption(
      "datosUsuario.grupoGeneral.idCompañia",
      "visible",
      !isUserLavanderia,
    );
    dxForm_ResumenDatosUser.itemOption(
      "datosUsuario.grupoGeneral.idLavanderiaInicio",
      "visible",
      isUserLavanderia ? true : false,
    );
    dxForm_ResumenDatosUser.itemOption(
      "datosUsuario.grupo_asociarPersona.idPersona",
      "visible",
      isAsociarPersona_visible,
    );

    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupoGeneral.idCompañia",
      "visible",
      !isUserLavanderia,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupoGeneral.idLavanderiaInicio",
      "visible",
      isUserLavanderia ? true : false,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupoGeneral.dxButton_reenviarCorreo",
      "visible",
      isReenviarVisible && userSel.subtipoUsuario != 2,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupoGeneral.fechaCreacion",
      "visible",
      userSel.idUsuario !== null,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupoGeneral.fechaCreacion",
      "value",
      userSel.fechaCreacion,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupo_asociarPersona.dxButton_asociarPersona",
      "visible",
      isAsociarPersona_visible,
    );
    dxForm_datosUser.itemOption(
      "datosUsuario.formTab.grupo_asociarPersona.idPersona",
      "visible",
      isAsociarPersona_visible,
    );
    //#endregion

    $("#dxDataGrid_formulariosNApartado_inicio")
      .dxDataGrid("instance")
      .option("selectedRowKeys", userSel.idFormularioInicio);

    if (userSel.subtipoUsuario === 1 || userSel.subtipoUsuario === 3) {
      if ($("#dxSwitch_isGestionaUsuarios").data("dxSwitch") != null) {
        $("#dxSwitch_isGestionaUsuarios").dxSwitch("instance").option({
          value: false,
          disabled: true,
        });
      }
    } else {
      if ($("#dxSwitch_isGestionaUsuarios").data("dxSwitch") != null)
        $("#dxSwitch_isGestionaUsuarios")
          .dxSwitch("instance")
          .option("disabled", false);
    }

    dxForm_datosUser
      .getEditor("idCargo")
      .option("dataSource", filtrar_tblCargo());
    setDataSource_asociarPersona(userSel.idPersona);
    datasource_tblCategoriaInternaNUsuario.reload();
    // CARGA FORMULARIOS VISIBLES POR USUARIO DXLIST
    $.when(
      datasource_moduloNLavanderia.load(),
      datasource_datos_compaEnti.load(),
      datasource_entidadNUsuario.load(),
      datasource_tblCentroTrabajoNUsuario.load(),
      dataSource_lavanderias_todas.load(),
      datasource_formulariosNApartado.load().then(function () {
        dataSource_formularios.load().done(function () {
          COMPONENT.loadPanel_hide();

          var dxSelectBox_lavanderiaInicio = $(
            "#dxSelectBox_lavanderiaInicio",
          ).dxSelectBox("instance");

          if (dxSelectBox_lavanderiaInicio != null) {
            if (userSel.subtipoUsuario !== 1 && userSel.subtipoUsuario !== 3) {
              dxSelectBox_lavanderiaInicio.option({
                value: null,
                disabled: true,
                dataSource: [],
              });
            } else {
              dxSelectBox_lavanderiaInicio.option({
                dataSource: getDenoLavActivas(),
                disabled: false,
                value: userSel ? userSel.idLavanderiaInicio : null,
              });
            }
          }

          var is_gestionaUsuarios = false;
          $.each(
            datasource_formulariosNApartado.items(),
            function (iApartado, apartado) {
              $.each(
                apartado.tblFormulario,
                function (iFormulario, formulario) {
                  if (
                    formulario.idFormulario === 21 &&
                    (formulario.isEscritura || formulario.isBorrado)
                  ) {
                    is_gestionaUsuarios = true;
                  }
                },
              );
            },
          );

          dxNavbar_disableItems(isUserLavanderia);

          if ($("#dxSwitch_isGestionaUsuarios").data("dxSwitch") != null)
            $("#dxSwitch_isGestionaUsuarios")
              .dxSwitch("instance")
              .option("value", is_gestionaUsuarios);

          //#region Lista entidades seleccionadas
          var selectedKeys = [];
          $.each(userSel.tblEntidad, function (index, item) {
            $.each(item.tblEntidad, function (index, entidad) {
              selectedKeys.push(entidad.idEntidad);
            });
          });
          $("#dxList_entidades")
            .dxList("instance")
            .option("selectedItemKeys", selectedKeys);
          $("#dxList_compañias").dxList("instance").selectItem(0);
          //#endregion

          $("#dxList_centrosTrabajo")
            .dxList("instance")
            .option("selectedItemKeys", userSel.tblCentroTrabajo);

          if ($("#dxAccordion_formularioVisibles").data("dxAccordion")) {
            var dxAccordion_formularioVisibles = $(
              "#dxAccordion_formularioVisibles",
            ).dxAccordion("instance");
            dxAccordion_formularioVisibles.beginUpdate();
            dxAccordion_formularioVisibles.option({
              dataSource: userSel.tblFormulario,
              selectedIndex: -1,
            });
            dxAccordion_formularioVisibles.endUpdate();
          }

          if ($("#dxButtonGroup_formularioVisibles").data("dxButtonGroup"))
            dxButtonGroup_formularioVisibles.repaint();
          dxScrollView_formulariosNUsuario_resize();
        });
      }),
    );
  }

  function getLavName(idLavanderia) {
    var lavanderiasFiltradas = $.grep(
      dataSource_lavanderias_todas.items(),
      function (element, index) {
        return element.idLavanderia === idLavanderia;
      },
    );
    if (lavanderiasFiltradas.length > 0) {
      return lavanderiasFiltradas[0].denominacion;
    } else {
      return "";
    }
  }

  function dxScrollView_formulariosNUsuario_resize() {
    let height_dxButtonGroup =
      $("#dxButtonGroup_formularioVisibles").height() + 12; //12 padding
    let heightForm = $("#dxForm_datosUser").height(); // Al form e le suma un extraheight de 80 que se pone en container #Usuarios
    $("#dxScrollView_formulariosNUsuario").height(
      heightForm - 60 - height_dxButtonGroup,
    );
  }

  return deferred.promise();
}
