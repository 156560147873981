import { connectionConstants } from "../../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var auditoriaSel = null;
  var noConfSel = null;
  var fotoSel; //Almacena el índice de la imagen que se quiere visualizar en grande
  var array_fotos = [];

  //#region Datasource

  var dataSource_Auditorias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAuditoria",
      key: "idAuditoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: [
      "idAuditoria",
      "denoLavanderia",
      "fecha",
      "idTipoAuditoria",
      "denoTipoAuditoria",
    ],
  });

  var dataSource_NoConformidades = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infAuditorias_spSelectNoConformidadNAuditoria",
      key: "idNoConformidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idAuditoria = auditoriaSel
          ? auditoriaSel.idAuditoria
          : -1;
      },
      version: 4,
    }),
  });

  var dataSource_Fotos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFoto",
      key: "idFoto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        request.params.idNoConformidad = noConfSel.idNoConformidad;
      },
      version: 4,
    }),
    select: ["idNoConformidad", "img"],
  });

  var dataSource_NoConformidad_Estados = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEstado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idEstado", "denominacion"],
  });

  var dataSource_ambitosNAreas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "infAuditorias_spSelectAmbitosNAreas",
      key: "id",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idAuditoria = auditoriaSel
          ? auditoriaSel.idAuditoria
          : -1;
      },
      version: 4,
    }),
    postProcess: function (items) {
      $.each(items[0].areas, function (index, item) {
        item.ratio = 1;
      });
      var total = (items[0].puntTotal * 100).toFixed(0);
      $("#totalPunt_value")[0].textContent = total + "%";
      return items[0].areas;
    },
  });

  var dataSource_NoConformidad_Grado = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblNoConformidad_Grado",
      key: "idGrado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idGrado", "denominacion"],
  });

  //#endregion

  $("#InfAuditorias #dxContainer").dxBox({
    direction: "row",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    width: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          var tabpanel = $("<div id='tabpanel-container' />")
            .addClass("no-header")
            .dxTabPanel({
              height: "100%",
              width: "100%",
              deferRendering: false,
              animationEnabled: true,
              swipeEnabled: false,
              focusStateEnabled: false,
              items: [
                {
                  title: "Filtros",
                  template: function () {
                    return $("<div />").dxBox({
                      direction: "col",
                      height: "100%",
                      items: [
                        {
                          baseSize: 75,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $(
                              "<div class='dxToolbar_selectorTab' />",
                            ).dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxRadioGroup",
                                  options: {
                                    height: "100px",
                                    width: "200px",
                                    dataSource: [
                                      { text: getTrad("filtros"), val: true },
                                    ],
                                    value: true,
                                    valueExpr: "val",
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          baseSize: 45,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $(
                              "<div class='font-size' style='margin-left:9px;'>1. " +
                                getTrad("elijaAuditoria") +
                                "</div>",
                            );
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $(
                              "<div id='dxDataGrid_Auditorias'>",
                            ).dxDataGrid({
                              //Datos
                              dataSource: dataSource_Auditorias,
                              remoteOperations: true,
                              //Propiedades
                              columnsAutoWidth: true,
                              height: "100%",
                              headerFilter: {
                                visible: true,
                              },
                              filterRow: {
                                visible: true,
                                applyFilter: "auto",
                              },
                              paging: {
                                pageSize: 50,
                              },
                              pager: {
                                showPageSizeSelector: true,
                                allowedPageSizes: [20, 50, 100],
                                showInfo: true,
                              },
                              selection: {
                                mode: "single",
                              },
                              hoverStateEnabled: true,
                              columns: [
                                {
                                  dataField: "fecha",
                                  caption: getTrad("fecha"),
                                  dataType: "date",
                                  format: "dd/MM/yyyy",
                                  width: "25%",
                                  alignment: "center",
                                  sortOrder: "desc",
                                  allowHeaderFiltering: false,
                                },
                                {
                                  dataField: "denoLavanderia",
                                  caption: getTrad("denominacion"),
                                  width: "50%",
                                  minWidth: "150px",
                                  alignment: "left",
                                },
                                {
                                  dataField: "denoTipoAuditoria",
                                  caption: getTrad("tipo"),
                                  width: "25%",
                                  minWidth: "100px",
                                  alignment: "left",
                                },
                              ],
                              //  EVENTOS
                              onRowPrepared: function (e) {
                                if (e.rowType === "data") {
                                  e.rowElement.css({
                                    height: 45,
                                    fontSize: 14,
                                    verticalAlign: "middle",
                                  });
                                  e.rowElement.css("cursor", "pointer");
                                }
                              },
                              onCellPrepared: function (e) {
                                if (e.rowType === "data") {
                                  e.cellElement.css({
                                    verticalAlign: "middle",
                                  });
                                }
                              },
                              onSelectionChanged: function (selectedItem) {
                                auditoriaSel =
                                  typeof selectedItem.selectedRowsData[0] !==
                                  "undefined"
                                    ? selectedItem.selectedRowsData[0]
                                    : null;

                                $("#dxDataGrid_NoConformidades")
                                  .dxDataGrid("instance")
                                  .option(
                                    "dataSource",
                                    dataSource_NoConformidades,
                                  );
                                dataSource_ambitosNAreas.load();

                                if (auditoriaSel) {
                                  tabpanel.option("selectedIndex", 1); // EN FUNCIÓN DE SI HAY SELECCIÓN O NO PONE TAB PRINCIPAL O SECUNDARIA
                                }
                              },
                              //Estilos
                              showColumnLines: false,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            });
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  title: getTrad("auditorias"),
                  visible: false,
                  template: function () {
                    return $("<div />").dxBox({
                      direction: "col",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          baseSize: 75,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $(
                              "<div class='dxToolbar_selectorTab' />",
                            ).dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxRadioGroup",
                                  options: {
                                    height: "100px",
                                    width: "450px",
                                    layout: "horizontal",
                                    dataSource: [
                                      { text: getTrad("auditorias"), val: 1 },
                                      {
                                        text: getTrad("noConformidades"),
                                        val: 2,
                                      },
                                    ],
                                    value: 1,
                                    valueExpr: "val",
                                    onValueChanged: function (e) {
                                      if (e.value === 2) {
                                        tabpanel.option(
                                          "selectedIndex",
                                          e.value,
                                        );
                                        e.component.option("value", 1);
                                      }
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  template: function () {
                                    var container = $("<div />").css({
                                      display: "grid",
                                      "grid-auto-flow": "column",
                                    });

                                    $("<span />")
                                      .css({
                                        "font-size": "34px",
                                        color: "#6d6c6c",
                                        padding: "5px 21px",
                                        "line-height": "53px",
                                        "border-bottom": "3px solid #EBB342",
                                      })
                                      .text(getTrad("puntTotal"))
                                      .appendTo(container);
                                    $("<span id='totalPunt_value' />")
                                      .css({
                                        padding: "10px 17px",
                                        "background-color": "#EBB342",
                                        color: "white",
                                        "border-radius": "3px 3px 3px 0",
                                        "font-size": "32px",
                                        "min-width": "50px",
                                      })
                                      .appendTo(container);
                                    return container;
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          baseSize: 36,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $(
                              "<div id='dxToolbar_auditorias' />",
                            ).dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxButton",
                                  options: {
                                    text: getTrad("volverFiltrar"),
                                    icon: "chevronleft",
                                    onClick: function () {
                                      tabpanel.option("selectedIndex", 0);
                                    },
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            // PARA CAMBIAR PROPORCIÓN DE GRADICAS, CAMBIAR LOS RATIOS DE LOS GRIDS Y GRAFICAS Y EL TAMAÑO gauge_height DE LAS GRAFICAS.
                            return $("<div id='dxBox_graficas' />").dxBox({
                              direction: "row",
                              height: "100%",
                              width: "100%",
                              dataSource: dataSource_ambitosNAreas,
                              itemTemplate: function (
                                itemData,
                                itemIndex,
                                itemElement,
                              ) {
                                // APLICA EL PADDING DE SEPARACIÓN A LOS GRID EXCEPTO EL ÚLTIMO
                                if (
                                  itemIndex + 1 <
                                  dataSource_ambitosNAreas.items().length
                                )
                                  $(itemElement).css("padding-right", "30px");

                                return $("<div />").dxBox({
                                  direction: "col",
                                  height: "100%",
                                  items: [
                                    {
                                      baseSize: 10,
                                      shrink: 0,
                                      ratio: 0, // Para dejar un margen superior
                                    },
                                    {
                                      baseSize: 70,
                                      shrink: 0,
                                      ratio: 0,
                                      template: function () {
                                        return $("<div/>").dxToolbar({
                                          items: [
                                            {
                                              location: "center",
                                              template: function (e) {
                                                return $("<div>")
                                                  .text(itemData.denoArea)
                                                  .css({
                                                    "font-size": 22,
                                                  });
                                              },
                                            },
                                          ],
                                        });
                                      },
                                    },
                                    {
                                      ratio: 0.85,
                                      template: function (
                                        data,
                                        index,
                                        element,
                                      ) {
                                        var container = $("<div>")
                                          .addClass("container_spanCentrado")
                                          .css("height", "auto");

                                        var colorGrafica = "#374046";
                                        if (
                                          itemData.puntArea > 0 &&
                                          itemData.puntArea <= 0.5
                                        ) {
                                          colorGrafica = "#F5564A";
                                        } else if (
                                          itemData.puntArea > 0.5 &&
                                          itemData.puntArea <= 0.8
                                        ) {
                                          colorGrafica = "#FEC007";
                                        } else if (itemData.puntArea > 0.8) {
                                          colorGrafica = "#97C95C";
                                        }

                                        var gauge = $("<div>")
                                          .dxBarGauge({
                                            geometry: {
                                              endAngle: 360,
                                              startAngle: 360,
                                            },
                                            size: {
                                              height: "100%",
                                              width: "100%",
                                            },
                                            label: {
                                              visible: false,
                                            },
                                            relativeInnerRadius: 0.55,
                                            values: (
                                              itemData.puntArea * 100
                                            ).toFixed(0),
                                            palette: [colorGrafica],
                                            endValue: 100,
                                          })
                                          .appendTo(container);

                                        //#region El gauge no se adapta al height del contenedor automáticamente -> hay que cogerlo y asignarselo después

                                        var gauge_height = 267; // HACER DINÁMICO TODO

                                        if (
                                          element.length > 0 &&
                                          element[0].clientHeight > 0
                                        )
                                          gauge_height =
                                            element[0].clientHeight;

                                        if (gauge_height > 0) {
                                          gauge
                                            .dxBarGauge("instance")
                                            .option(
                                              "size.height",
                                              gauge_height,
                                            );
                                        }

                                        $("<span>")
                                          .css({
                                            "line-height": "100%",
                                            position: "absolute",
                                            "font-family": "Segoe UI",
                                            "font-size":
                                              gauge
                                                .dxBarGauge("instance")
                                                .option("size.height") / 4.28, //proporción altura gráfica y tamaño letra
                                            color: colorGrafica,
                                          })
                                          .text(
                                            (itemData.puntArea * 100).toFixed(
                                              0,
                                            ) + "%",
                                          )
                                          .appendTo(container);

                                        return container;
                                      },
                                    },
                                    {
                                      ratio: 1,
                                      template: function () {
                                        return $("<div>").dxDataGrid({
                                          //Datos
                                          dataSource: itemData.ambitos,
                                          height: "100%",
                                          width: "100%",
                                          remoteOperations: true,
                                          //Propiedades
                                          columnsAutoWidth: true,
                                          headerFilter: {
                                            visible: true,
                                          },
                                          filterRow: {
                                            visible: true,
                                            applyFilter: "auto",
                                          },
                                          paging: {
                                            enabled: false,
                                          },
                                          columns: [
                                            {
                                              dataField: "denoAmbito",
                                              caption: getTrad("ambito"),
                                              sortOrder: "asc",
                                              sortIndex: 0,
                                              alignment: "left",
                                            },
                                            {
                                              dataField: "puntAmbito",
                                              caption: getTrad("cumplimiento"),
                                              format: "percent",
                                              width: 180,
                                              alignment: "center",
                                              allowFiltering: false,
                                            },
                                          ],
                                          //Estilos
                                          showColumnLines: false,
                                          showRowLines: true,
                                          rowAlternationEnabled: true,
                                        });
                                      },
                                    },
                                  ],
                                });
                              },
                            });
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  title: getTrad("noConformidades"),
                  visible: false,
                  template: function () {
                    return $("<div />").dxBox({
                      direction: "col",
                      height: "100%",
                      items: [
                        {
                          baseSize: 75,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $(
                              "<div class='dxToolbar_selectorTab' />",
                            ).dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxRadioGroup",
                                  options: {
                                    height: "100px",
                                    width: "450px",
                                    layout: "horizontal",
                                    dataSource: [
                                      { text: getTrad("auditorias"), val: 1 },
                                      {
                                        text: getTrad("noConformidades"),
                                        val: 2,
                                      },
                                    ],
                                    value: 2,
                                    valueExpr: "val",
                                    onValueChanged: function (e) {
                                      if (e.value === 1) {
                                        tabpanel.option(
                                          "selectedIndex",
                                          e.value,
                                        );
                                        e.component.option("value", 2);
                                      }
                                    },
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          baseSize: 50,
                          shrink: 0,
                          ratio: 0,
                          template: function () {
                            return $("<div />").dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  widget: "dxButton",
                                  options: {
                                    text: getTrad("volverFiltrar"),
                                    icon: "chevronleft",
                                    onClick: function () {
                                      tabpanel.option("selectedIndex", 0);
                                    },
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  locateInMenu: "auto",
                                  visible: true,
                                  options: {
                                    text: getTrad("exportar"),
                                    icon: "exportxlsx",
                                    onClick: function () {
                                      $("#dxDataGrid_NoConformidades")
                                        .dxDataGrid("instance")
                                        .exportToExcel();
                                      // modalImpresion.PerformCallback(auditoriaSel.idAuditoria + "|" + LAVANDERIA.denominacion + "|" + formatDate_parameter(auditoriaSel.fecha));
                                    },
                                  },
                                },
                              ],
                            });
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $(
                              "<div id='dxDataGrid_NoConformidades'>",
                            ).dxDataGrid({
                              //Datos
                              remoteOperations: true,
                              //Propiedades
                              columnsAutoWidth: true,
                              height: "100%",
                              headerFilter: {
                                visible: true,
                              },
                              filterRow: {
                                visible: true,
                                applyFilter: "auto",
                              },
                              paging: {
                                pageSize: 50,
                              },
                              pager: {
                                showPageSizeSelector: true,
                                allowedPageSizes: [20, 50, 100],
                                showInfo: true,
                              },
                              selection: {
                                mode: "single",
                              },
                              hoverStateEnabled: true,
                              columns: [
                                {
                                  dataField: "denoAmbito",
                                  caption: getTrad("ambito"),
                                  sortOrder: "asc",
                                  sortIndex: 0,
                                  width: 225,
                                  alignment: "left",
                                },
                                {
                                  dataField: "denoSubAmbito",
                                  caption: getTrad("subAmbito"),
                                  sortOrder: "asc",
                                  sortIndex: 1,
                                  width: 180,
                                  alignment: "left",
                                },
                                {
                                  dataField: "descripcion",
                                  caption: getTrad("noConf"),
                                  allowHeaderFiltering: false,
                                  alignment: "left",
                                },
                                {
                                  dataField: "accionCorrectora",
                                  caption: getTrad("accionCorrect"),
                                  width: 500,
                                  allowHeaderFiltering: false,
                                  alignment: "left",
                                  visible: false,
                                },
                                {
                                  dataField: "idGrado",
                                  caption: getTrad("grado"),
                                  width: 130,
                                  alignment: "left",
                                  lookup: {
                                    dataSource: function (options) {
                                      return {
                                        store:
                                          dataSource_NoConformidad_Grado.store(),
                                      };
                                    },
                                    displayExpr: "denominacion",
                                    valueExpr: "idGrado",
                                  },
                                },
                                {
                                  dataField: "responsable",
                                  caption: getTrad("responsable"),
                                  width: 170,
                                  allowHeaderFiltering: false,
                                  alignment: "left",
                                },
                                {
                                  dataField: "responsable2",
                                  caption: getTrad("responsable") + " 2",
                                  visible: false,
                                  alignment: "left",
                                },
                                {
                                  dataField: "responsable3",
                                  caption: getTrad("responsable") + " 3",
                                  visible: false,
                                  alignment: "left",
                                },
                                {
                                  name: "estado",
                                  caption: getTrad("estado"),
                                  visible: false,
                                  calculateCellValue: (rowData) => {
                                    switch (rowData.idEstado) {
                                      case null:
                                        return getTrad("sinEstado");
                                        break;
                                      case 1: // ABIERTA ? -> TODAVÍA NO SE HA DEFINIDO
                                        return getTrad("abierta");
                                        break;
                                      case 2: // EN PROCESO ? -> TODAVÍA NO SE HA DEFINIDO
                                        return getTrad("enProceso");
                                        break;
                                      case 3: // CERRADA ? -> TODAVÍA NO SE HA DEFINIDO
                                        return getTrad("cerrada");
                                        break;
                                      default:
                                        break;
                                    }
                                  },
                                },
                                {
                                  dataField: "idEstado",
                                  caption: getTrad("estado"),
                                  width: 95,
                                  alignment: "left",
                                  cellTemplate: function (container, options) {
                                    var contenedor = $("<div />")
                                      .addClass("container_spanCentrado")
                                      .css("padding-left", "10px");

                                    var cuadrado = $(
                                      "<span id='cuadrado_" +
                                        options.data.idNoConformidad +
                                        "'>",
                                    );
                                    var idEstado = options.data.idEstado;
                                    var denoEstado = "";

                                    switch (idEstado) {
                                      case null:
                                        cuadrado.css(
                                          "background-color",
                                          "#FE3814",
                                        );
                                        denoEstado = getTrad("sinEstado");
                                        break;
                                      case 1: // ABIERTA ? -> TODAVÍA NO SE HA DEFINIDO
                                        cuadrado.css(
                                          "background-color",
                                          "#FE3814",
                                        ); // ROJO
                                        denoEstado = getTrad("abierta");
                                        break;
                                      case 2: // EN PROCESO ? -> TODAVÍA NO SE HA DEFINIDO
                                        cuadrado.css(
                                          "background-color",
                                          "#FEC007",
                                        ); // VERDE
                                        denoEstado = getTrad("enProceso");
                                        break;
                                      case 3: // CERRADA ? -> TODAVÍA NO SE HA DEFINIDO
                                        cuadrado.css(
                                          "background-color",
                                          "#63FF14",
                                        ); // VERDE
                                        denoEstado = getTrad("cerrada");
                                        break;
                                      default:
                                        break;
                                    }
                                    var cellWidth =
                                      container[0].clientWidth * 0.55;

                                    cuadrado.css("height", 19);
                                    cuadrado.css("width", cellWidth);
                                    cuadrado.css("border-radius", "4px");
                                    cuadrado.css("border", "1px solid #BFBFBF");
                                    cuadrado.css("font-size", "12px");

                                    cuadrado.appendTo(contenedor);

                                    $("<div />")
                                      .dxTooltip({
                                        target:
                                          "#cuadrado_" +
                                          options.data.idNoConformidad,
                                        height: "42px",
                                        showEvent: "dxhoverstart",
                                        hideEvent: "dxhoverend",
                                        position: "bottom",
                                        contentTemplate: function (
                                          contentElement,
                                        ) {
                                          contentElement.html(denoEstado);
                                        },
                                        animation: {
                                          show: {
                                            type: "pop",
                                            from: {
                                              scale: 0.1,
                                              opacity: 0,
                                            },
                                            to: {
                                              opacity: 1,
                                              scale: 1,
                                            },
                                          },
                                          hide: {
                                            type: "pop",
                                            from: {
                                              scale: 1,
                                              opacity: 1,
                                            },
                                            to: {
                                              opacity: 0,
                                              scale: 0.1,
                                            },
                                          },
                                        },
                                      })
                                      .appendTo(contenedor);

                                    return contenedor;
                                  },
                                },
                                {
                                  dataField: "observaciones",
                                  caption: getTrad("observaciones"),
                                  visible: false,
                                  alignment: "left",
                                },
                              ],
                              customizeColumns: function (columns) {
                                columns.forEach(function (column) {
                                  column.allowEditing = false;
                                });
                              },
                              onRowPrepared: function (e) {
                                if (e.rowType === "data") {
                                  e.rowElement.css("cursor", "pointer");
                                }
                              },
                              onEditorPreparing: function (e) {
                                if (e.parentType === "filterRow") return;

                                switch (e.dataField) {
                                  case "descripcion":
                                    e.editorName = "dxTextArea";
                                    e.editorOptions.height = 90;
                                    break;
                                  case "accionCorrectora":
                                    e.editorName = "dxTextArea";
                                    e.editorOptions.height = 90;
                                    break;
                                  default:
                                    break;
                                }
                              },
                              onSelectionChanged: function (selectedItem) {
                                //TODO
                                if (selectedItem.selectedRowsData.length > 0) {
                                  noConfSel = selectedItem.selectedRowsData[0];
                                  var dxTileView_documentos = $(
                                    "#dxTileView_documentos",
                                  ).dxTileView("instance");
                                  var dxForm_NoConformidad = $(
                                    "#dxForm_NoConformidad",
                                  ).dxForm("instance");

                                  dataSource_Fotos.load().done(function (data) {
                                    array_fotos = data;

                                    if (
                                      typeof dxTileView_documentos !==
                                      "undefined"
                                    ) {
                                      dxTileView_documentos.option(
                                        "dataSource",
                                        array_fotos,
                                      );
                                    }
                                    if (
                                      typeof dxForm_NoConformidad !==
                                      "undefined"
                                    ) {
                                      dxForm_NoConformidad.option(
                                        "formData",
                                        noConfSel,
                                      );
                                    }
                                    $("#popup_infoNoConf")
                                      .dxPopup("instance")
                                      .show();
                                  });
                                }
                              },
                              onExporting: function (e) {
                                e.component.beginUpdate();
                                e.component.columnOption(
                                  "accionCorrectora",
                                  "visible",
                                  true,
                                );
                                e.component.columnOption(
                                  "idEstado",
                                  "visible",
                                  false,
                                );
                                e.component.columnOption(
                                  "estado",
                                  "visible",
                                  true,
                                );
                              },
                              onExported: function (e) {
                                e.component.columnOption(
                                  "accionCorrectora",
                                  "visible",
                                  false,
                                );
                                e.component.columnOption(
                                  "idEstado",
                                  "visible",
                                  true,
                                );
                                e.component.columnOption(
                                  "estado",
                                  "visible",
                                  false,
                                );
                                e.component.endUpdate();
                              },
                              //Estilos
                              showColumnLines: false,
                              showRowLines: true,
                              rowAlternationEnabled: true,
                            });
                          },
                        },
                      ],
                    });
                  },
                },
              ],
              onSelectionChanged: function (e) {
                var tabpanel = e.component;
                var tabpanel_index = tabpanel.option("selectedIndex");
                if (tabpanel_index === 0) {
                  $("#dxDataGrid_Auditorias")
                    .dxDataGrid("instance")
                    .option("selectedRowKeys", []);
                  tabpanel.option("items[0].visible", true);
                  tabpanel.option("items[1].visible", false);
                  tabpanel.option("items[2].visible", false);
                } else {
                  tabpanel.option("items[0].visible", false);
                  tabpanel.option("items[1].visible", true);
                  tabpanel.option("items[2].visible", true);
                }
              },
            })
            .dxTabPanel("instance");
          return tabpanel.element();
        },
      },
    ],
  });

  $("#InfAuditorias #popup_infoNoConf").dxPopup({
    showTitle: true,
    title: getTrad("noConf"),
    height: "auto",
    width: "60%",
    minWidth: "500px",
    contentTemplate: function () {
      var container = $("<div style='height:100%;'></div>");
      $("<div id='dxForm_NoConformidad' style='margin-bottom:20px;'>")
        .dxForm({
          formData: noConfSel,
          readOnly: true,
          labelLocation: "top",
          colCount: 4,
          items: [
            {
              dataField: "responsable",
              colSpan: 2,
              label: {
                text: getTrad("responsable"),
              },
            },
            {
              colSpan: 2,
              dataField: "responsable2",
              label: {
                text: getTrad("responsable") + " 2",
              },
            },
            {
              colSpan: 2,
              dataField: "responsable3",
              label: {
                text: getTrad("responsable") + " 3",
              },
            },
            {
              colSpan: 1,
              dataField: "idGrado",
              editorType: "dxSelectBox",
              label: { text: getTrad("grado") },
              editorOptions: {
                dataSource: dataSource_NoConformidad_Grado.store(),
                displayExpr: "denominacion",
                valueExpr: "idGrado",
              },
            },
            {
              colSpan: 1,
              dataField: "idEstado",
              editorType: "dxSelectBox",
              editorOptions: {
                dataSource: dataSource_NoConformidad_Estados.store(),
                valueExpr: "idEstado",
                displayExpr: "denominacion",
                placeholder: getTrad("sinEstado"),
              },
              label: {
                text: getTrad("estado"),
              },
            },
            {
              colSpan: 2,
              dataField: "descripcion",
              editorType: "dxTextArea",
              editorOptions: {
                height: "90px",
              },
              label: {
                text: getTrad("noConf"),
              },
            },
            {
              colSpan: 2,
              dataField: "accionCorrectora",
              editorType: "dxTextArea",
              editorOptions: {
                height: "90px",
              },
              label: {
                text: getTrad("accionCorrect"),
              },
            },
          ],
        })
        .appendTo(container);

      $("<div id='dxTileView_documentos'>")
        .dxTileView({
          dataSource: array_fotos,
          readOnly: true,
          baseItemHeight: 100,
          baseItemWidth: 100,
          noDataText: getTrad("noConf_sinImagenes"),
          hoverStateEnabled: true,
          direction: "horizontal",
          height: 130,
          width: "100%",
          itemMargin: 10,
          showScrollbar: true,
          itemTemplate: function (itemData, itemIndex, itemElement) {
            itemElement.addClass("contenedorImg");
            itemElement.css("cursor", "pointer");
            itemElement.append(
              $("<img />")
                .addClass("image")
                .attr(
                  "style",
                  "background-image: url(data:image/png;base64," + itemData.img,
                )
                .on("click", function () {
                  fotoSel = itemIndex;
                  $("#popup_galeriaImagenes").dxPopup("show");
                }),
            );
          },
        })
        .appendTo(container);

      return container;
    },
    // EVENTOS
    onContentReady: function (e) {
      var html = e.component.content();
      $(html).css("padding-bottom", "0");
    },
    onHiding: function (e) {
      $("#dxDataGrid_NoConformidades")
        .dxDataGrid("instance")
        .option("selectedRowKeys", []);
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("aceptar"),
          type: "success",
          onClick: function (e) {
            $("#popup_infoNoConf").dxPopup("instance").hide();
          },
        },
      },
    ],
  });

  $("#InfAuditorias #popup_galeriaImagenes").dxPopup({
    showTitle: true,
    title: getTrad("galeriaImagenes"),
    showCloseButton: true,
    closeOnOutsideClick: true,
    width: "700px",
    height: "500px",
    onShowing: function (e) {
      $("#galeriaImagenes")
        .dxGallery("instance")
        .option("dataSource", array_fotos);
      $("#galeriaImagenes")
        .dxGallery("instance")
        .option("selectedIndex", fotoSel);
    },
    contentTemplate: function () {
      var container = $("<div>");
      $("<div id='galeriaImagenes'>")
        .dxGallery({
          dataSource: array_fotos,
          showIndicator: true,
          showNavButtons: true,
          loop: true,
          height: "400",
          width: "100%",
          itemTemplate: function (itemData, itemIndex, itemElement) {
            itemElement.addClass("contenedorImg");
            itemElement.append(
              $("<img />")
                .addClass("image")
                .attr(
                  "style",
                  "background-image: url(data:image/png;base64," + itemData.img,
                )
                .on("click", function () {
                  fotoSel = itemIndex;
                  $("#popup_galeriaImagenes").dxPopup("show");
                }),
            );
          },
        })
        .appendTo(container);

      return container;
    },
  });

  deferred.resolve();
  return deferred.promise();
}
