import React from "react";

import DataGrid, {
  Column,
  Editing,
  LoadPanel,
  Sorting,
} from "devextreme-react/data-grid";

import { formatNumber, getTrad } from "helpers";

const DataGridTblRecambioNAlmacen = ({ dataSource }) => {
  const groupCellRender = ({ value }) => value;

  const calculateDisplayValue_precio = ({ precio, codigoMoneda }) =>
    formatNumber(precio, 2, "currency", codigoMoneda);

  const calculateDisplayValue_total = ({ cantidad, precio, codigoMoneda }) =>
    formatNumber(cantidad * precio, 2, "currency", codigoMoneda);

  return (
    <DataGrid
      dataSource={dataSource}
      keyExpr={"idAlmacen"}
      height={290}
      width={"100%"}
      allowColumnReordering
      columnsAutoWidth
      hoverStateEnabled
      rowAlternationEnabled
      showRowLines
      showColumnLines={false}
    >
      <LoadPanel enabled={false} />
      <Editing mode={"cell"} allowUpdating selectTextOnEditStart changes={[]} />
      <Sorting mode={"multiple"} />
      <Column
        dataField={"denoAlmacenPadre"}
        caption={""}
        groupIndex={0}
        sortIndex={0}
        sortOrder={"asc"}
        groupCellRender={groupCellRender}
      />
      <Column
        dataField={"denoAlmacen"}
        caption={getTrad("almacen")}
        sortIndex={1}
        sortOrder={"asc"}
        allowEditing={false}
      />
      <Column
        dataField={"ubicacion"}
        caption={getTrad("ubicacion")}
        width={120}
      />
      <Column
        dataField={"cantidad"}
        caption={getTrad("cantidad")}
        dataType={"number"}
        width={120}
        allowEditing={false}
      />
      <Column
        dataField={"precio"}
        caption={getTrad("precio")}
        dataType={"number"}
        width={120}
        allowEditing={false}
        calculateDisplayValue={calculateDisplayValue_precio}
      />
      <Column
        name={"total"}
        caption={getTrad("total")}
        dataType={"number"}
        width={120}
        allowEditing={false}
        calculateDisplayValue={calculateDisplayValue_total}
      />
    </DataGrid>
  );
};

export default DataGridTblRecambioNAlmacen;
