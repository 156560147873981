import React from "react";

import DataGridMovimientos from "./components/DataGridMovimientos";
import DataGridRecambios from "./components/DataGridRecambios";
import FormMovimientoRecambio from "./components/FormMovimientoRecambio";
import MovimientoRecambioWrapper from "../shared/components/MovimientoRecambioWrapper";
import idsTipoMovimientoRecambio from "constants/enums/Assistant/tblTipoMovimientoRecambio";

const Entradas = () => {
  return (
    <MovimientoRecambioWrapper
      FormMovimientoRecambio={FormMovimientoRecambio}
      DataGridRecambios={DataGridRecambios}
      DataGridMovimientos={DataGridMovimientos}
      idTipoMovimientoRecambio={idsTipoMovimientoRecambio.entrada}
    />
  );
};

export default Entradas;
