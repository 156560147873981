import React, { Fragment } from "react";
import { connectionConstants } from "../../../../../constants";

import {
  errorHandler,
  authHeader,
  getTrad,
  patchRequestHandler,
  formatNumber,
} from "../../../../../helpers";

import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ArrayStore from "devextreme/data/array_store";

import notify from "devextreme/ui/notify";
import { editCellComponent } from "../../../../../components/DataGrid/Cells";

// Devextreme components
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Editing,
  KeyboardNavigation,
  RequiredRule,
} from "devextreme-react/data-grid";

import { saveAs } from "file-saver";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";

//CUSTOM COMPONENTS
import { removeCellComponent } from "../../../../../components/DataGrid/Cells";

import PopupAddCategoria from "../PopupAddCategoria/index";
import PopUpEdicionCategoriaInterna from "../PopUpEdicionCategoriaInterna/index";

export default class GridCategoriaInterna extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      categoriaInterna_items: [],
      isVisible_popup_showCategoria: false,
      isVisible_popup_modifyCategoria: false,
      categoriaInternaSel: null,
    };

    this.dxDataGrid_tblCategoriaInterna_REF = React.createRef();

    this.datasource_tblCategoriaInterna_onLoading =
      this.datasource_tblCategoriaInterna_onLoading.bind(this);

    this.dxDataGrid_tblCategoriaInterna_onRowUpdating =
      this.dxDataGrid_tblCategoriaInterna_onRowUpdating.bind(this);
    this.dxDataGrid_tblCategoriaInterna_onRowRemoving =
      this.dxDataGrid_tblCategoriaInterna_onRowRemoving.bind(this);
    this.dxDataGrid_tblCategoriaInterna_onToolbarPreparing =
      this.dxDataGrid_tblCategoriaInterna_onToolbarPreparing.bind(this);
    this.dxDataGrid_tblCategoriaInterna_onExporting =
      this.dxDataGrid_tblCategoriaInterna_onExporting.bind(this);
    this.dxPopup_tblCategoriaInterna_onHiding =
      this.dxPopup_tblCategoriaInterna_onHiding.bind(this);
    this.addCategoria_onClick = this.addCategoria_onClick.bind(this);
    this.modifyCategoria_onClick = this.modifyCategoria_onClick.bind(this);
    this.onCellClick = this.onCellClick.bind(this);

    this.reload_data = this.reload_data.bind(this);

    this.getTrad = this.getTrad.bind(this);
    this.onModifyGrid = this.onModifyGrid.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxDataGrid_tblCategoriaInterna() {
    return this.dxDataGrid_tblCategoriaInterna_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblCategoriaInterna_onLoading(loadOptions);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    postProcess: (data) => {
      return this.datasource_tblCategoriaInterna_postProcess(data);
    },
  });

  datasource_tblCategoriaInterna_onLoading(loadOptions) {
    let { idCategoriaConvenioSel } = this.props;

    let idCategoriaConvenioSel_filter = [
      "idCategoriaConvenio",
      "=",
      idCategoriaConvenioSel != null ? idCategoriaConvenioSel : -1,
    ];

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        idCategoriaConvenioSel_filter,
      ];
    else loadOptions.filter = [idCategoriaConvenioSel_filter];
  }

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = true;
  }

  datasource_tblCategoriaInterna_postProcess(data) {
    $.each(data, function (index, item) {
      item.denominacion = item.denominacion.toUpperCase();
    });
    return data;
  }

  //#endregion

  render() {
    let {
      categoriaInterna_items,
      isVisible_popup_showCategoria,
      isVisible_popup_modifyCategoria,
      categoriaInternaSel,
    } = this.state;
    let { idCategoriaConvenioSel, idioma } = this.props;

    return (
      <Fragment>
        <DataGrid
          //Datos
          ref={this.dxDataGrid_tblCategoriaInterna_REF}
          dataSource={
            new DataSource({
              store: new ArrayStore({
                key: "idCategoriaInterna",
                data: categoriaInterna_items,
              }),
            })
          }
          //Propiedades
          columnsAutoWidth={true}
          height={"100%"}
          width={"100%"}
          hoverStateEnabled={false}
          //Estilos
          showColumnLines={false}
          showRowLines={true}
          rowAlternationEnabled={true}
          repaintChangesOnly={true}
          onCellClick={this.onCellClick}
          onRowUpdating={this.dxDataGrid_tblCategoriaInterna_onRowUpdating}
          onRowRemoving={this.dxDataGrid_tblCategoriaInterna_onRowRemoving}
          onToolbarPreparing={
            this.dxDataGrid_tblCategoriaInterna_onToolbarPreparing
          }
          onExporting={this.dxDataGrid_tblCategoriaInterna_onExporting}
        >
          <KeyboardNavigation
            enterKeyAction="moveFocus"
            enterKeyDirection="row"
            editOnKeyPress={true}
          />
          <Editing
            mode="cell"
            allowAdding={true}
            allowUpdating={true}
            refreshMode="reshape"
            selectTextOnEditStart={true}
          ></Editing>
          <SearchPanel visible={true} width={200} />
          <HeaderFilter visible={false} />
          <FilterRow
            visible={true}
            showAllText={this.getTrad("todos").toUpperCase()}
          />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={this.dxDataGrid_allowedPageSizes}
            showInfo={true}
          />
          <Paging defaultPageSize={50} />
          <Column
            caption=" "
            name={"editCell"}
            width={30}
            alignment="center"
            cssClass="p-0"
            cellComponent={editCellComponent}
          />
          <Column
            dataField="denominacion"
            caption={this.getTrad("denominacion")}
            minWidth={200}
            alignment="left"
            allowEditing={false}
            allowHeaderFiltering={false}
            sortOrder="asc"
            sortIndex={1}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField="salarioBase"
            caption={this.getTrad("salarioBase")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            format={this.currency_format}
            calculateDisplayValue={(rowData) => {
              return rowData.salarioBase ?? 0;
            }}
          />
          <Column
            dataField="percSegSocial"
            caption={this.getTrad("percSegSocial")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            format={this.percSegSocial_format}
            calculateDisplayValue={(rowData) => {
              return rowData.percSegSocial ?? 0;
            }}
          />
          <Column
            dataField="plusAsistencia"
            caption={this.getTrad("plusAsistencia")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            format={this.currency_format}
            calculateDisplayValue={(rowData) => {
              return rowData.plusAsistencia ?? 0;
            }}
          />
          <Column
            dataField="plusResponsabilidad"
            caption={this.getTrad("plusResponsabilidad")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            format={this.currency_format}
            calculateDisplayValue={(rowData) => {
              return rowData.plusResponsabilidad ?? 0;
            }}
          />
          <Column
            dataField="plusPeligrosidad"
            caption={this.getTrad("plusPeligrosidad")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            calculateDisplayValue={(rowData) => {
              return rowData.plusPeligrosidad ?? 0;
            }}
            format={this.currency_format}
          />
          <Column
            dataField="incentivo"
            caption={this.getTrad("incentivo")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            calculateDisplayValue={(rowData) => {
              return rowData.incentivo ?? 0;
            }}
            format={this.currency_format}
          />
          <Column
            dataField="plusProductividad"
            caption={this.getTrad("plusProductividad")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            calculateDisplayValue={(rowData) => {
              return rowData.plusProductividad ?? 0;
            }}
            format={this.currency_format}
          />
          <Column
            dataField="impHoraExtra"
            caption={this.getTrad("impHoraExtra")}
            dataType="number"
            alignment="center"
            allowFiltering={false}
            allowSearch={false}
            allowEditing={false}
            calculateDisplayValue={(rowData) => {
              return rowData.impHoraExtra ?? 0;
            }}
            format={this.currency_format}
          />
          <Column
            name="removeCell"
            caption=" "
            width={50}
            alignment="center"
            cssClass="p-0"
            cellComponent={removeCellComponent}
          />
        </DataGrid>
        <PopupAddCategoria
          isVisible_popup_showCategoria={isVisible_popup_showCategoria}
          idCategoriaConvenioSel={idCategoriaConvenioSel}
          onHiding={this.dxPopup_tblCategoriaInterna_onHiding}
          onModifyGrid={this.onModifyGrid}
          idioma={idioma}
        />
        <PopUpEdicionCategoriaInterna
          isVisible_popup_showCategoria={isVisible_popup_modifyCategoria}
          categoriaInternaSel={categoriaInternaSel}
          onHiding={this.dxPopup_tblCategoriaInterna_onHiding}
          onModifyGrid={this.onModifyGrid}
          idioma={idioma}
          reloadGrid={this.reload_data}
        />
      </Fragment>
    );
  }

  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
  };
  percSegSocial_format = { style: "percent", maximumFractionDigits: 1 };
  currency_editorOptions = {
    format: this.currency_format,
    placeholder: "0.00 €",
    step: 0,
    min: 0,
    max: 999999999999.99,
  };

  onModifyGrid(idCategoriaConvenio) {
    this.props.onModifyGrid(idCategoriaConvenio);
  }

  onCellClick(e) {
    if (e.column?.name && e.column?.name === "editCell") {
      this.modifyCategoria_onClick(e.data);
    }
  }

  dxDataGrid_tblCategoriaInterna_onRowRemoving(e) {
    let _this = this;
    this.datasource_tblCategoriaInterna
      .store()
      .remove(e.key)
      .fail(function (error) {
        notify({
          message: "No se puede eliminar una categoría en uso.",
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
        _this.reload_data(_this);
      })
      .done(function () {
        _this.props.onModifyGrid(e.data.idCategoriaConvenio);
      });
  }

  dxDataGrid_tblCategoriaInterna_onToolbarPreparing(e) {
    let _this = this;
    let toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (item.name == "addRowButton") {
        item.visible = false;
      }
    });

    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("pl-2 pb-2")
            .text(_this.getTrad("categoriasInternas"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          text: "Añadir",
          icon: "plus",
          onClick: _this.addCategoria_onClick,
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: _this.getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: _this.getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: _this.getTrad("actualizar"),
          icon: "refresh",
          onClick: function () {
            _this.dxDataGrid_tblCategoriaInterna.beginCustomLoading();
            _this.reload_data(_this);
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          text: _this.getTrad("exportar"),
          icon: "exportxlsx",
          onClick: function () {
            e.component.exportToExcel();
          },
        },
      },
    );
  }

  addCategoria_onClick(e) {
    this.setState({ isVisible_popup_showCategoria: true });
  }

  modifyCategoria_onClick(categoriaInternaSel) {
    this.setState({
      isVisible_popup_modifyCategoria: true,
      categoriaInternaSel,
    });
  }

  dxDataGrid_tblCategoriaInterna_onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function ({ gridCell, excelCell }) {
        if (gridCell.rowType === "data") {
          if (
            gridCell.column.dataField != "denominacion" &&
            gridCell.column.name != "removeCell"
          ) {
            excelCell.value =
              gridCell.value != null
                ? formatNumber(gridCell.value, 2, "currency", "EUR")
                : "";
          }
        }
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "Categorías internas" + ".xlsx",
        );
      });
    });
    e.cancel = true;
  }

  dxDataGrid_tblCategoriaInterna_onRowUpdating(e) {
    let _this = this;
    this.datasource_tblCategoriaInterna
      .store()
      .update(e.key, patchRequestHandler(e.newData))
      .fail(function (error) {
        notify({
          message: _this.getTrad("aviso_RegistroNoActualizado"),
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
        _this.reload_data(_this);
      });
  }

  dxPopup_tblCategoriaInterna_onHiding(isSaved) {
    if (isSaved) {
      this.reload_data(this);
    }

    this.setState({
      isVisible_popup_showCategoria: false,
      isVisible_popup_modifyCategoria: false,
      categoriaInternaSel: null,
    });
  }

  reload_data() {
    const _this = this;
    let { idCategoriaConvenioSel } = this.props;

    if (idCategoriaConvenioSel != null) {
      this.datasource_tblCategoriaInterna.load().done(function (items) {
        _this.setState(
          {
            categoriaInterna_items: items,
          },
          () => {
            _this.props.loadPanel_hide();
            _this.dxDataGrid_tblCategoriaInterna.endCustomLoading();
          },
        );
      });
    } else {
      _this.setState({
        categoriaInterna_items: [],
      });
    }
  }

  componentDidMount() {
    this.reload_data(this);
  }

  componentDidUpdate(prevProps) {
    let _this = this;
    let { idCategoriaConvenioSel } = this.props;
    if (prevProps.idCategoriaConvenioSel !== idCategoriaConvenioSel) {
      _this.reload_data(_this);
    }
  }
}
