import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  startOfMonth,
  endOfMonth,
  dxMensajePregunta,
  create_rangeSelector,
  dxRangeSelector_PosicionValor_nuevoMarker,
  filtroTiempo_Resize,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  //#region VARIABLES GLOBALES
  var idLavanderia = LAVANDERIA.idLavanderia,
    fechaDesde = null,
    fechaHasta = null,
    is_unidades = true,
    idCompaSel = null,
    idEntidadSel = null,
    is_todasCompa = null,
    kgLavados = [];
  //#endregion

  //#region DATASOURCE
  var datasource_filtroTiempo_spSelectComparativa = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProcesadosEntregados_filtroTiempo_spSelectComparativa",
      key: "fecha",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
      },
      version: 4,
    }),
    sort: "fecha",
    map: function (itemData) {
      return {
        arg: itemData.fecha,
        producido: itemData.producido,
      };
    },
  });

  var datasource_infProcesadosEntregados_spSelectComparativa = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProcesadosEntregados_spSelectComparativa",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idioma = IDIOMA.codigo;
      },
      version: 4,
      onLoading: function (loadOptions) {
        COMPONENT.loadPanel_hide();
        setTimeout(() => {
          $("#dxDataGrid_comparativa")
            .dxDataGrid("instance")
            .beginCustomLoading();
        }, 0);
      },
    }),
    sort: "idPrenda",
  });

  var infProcesadosEntregados_kgLavadosProcesados = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infProcesadosEntregados_kgLavadosProcesados",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
      },
      version: 4,
    }),
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion", "idCompañia"],
    postProcess: function (data) {
      if (datasource_entidades.items().length === 0) {
        var filtro_dxList_compañia = $("#filtro_dxList_compañia").dxList(
          "instance",
        );
        data.splice(0, 0, {
          idEntidad: 0,
          denominacion: getTrad("todas").toUpperCase(),
          idCompañia: 0,
        }); // Todas

        if (
          typeof filtro_dxList_compañia !== "undefined" &&
          filtro_dxList_compañia.option("selectedItems[0].idCompañia") !== 0
        )
          data.splice(1, 0, {
            idEntidad: -1,
            denominacion: getTrad("prendasCompañia").toUpperCase(),
            idCompañia: 0,
          }); // Prendas de compañía
      }
      return data;
    },
  });

  var datasource_tipoProduccion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoProduccion",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "idTipoProduccion",
  });

  var datasource_familias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFamilia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idFamilia", "denominacion", "abreviatura"],
    postProcess: function (data) {
      if (datasource_familias.items().length === 0) {
        data.splice(0, 0, {
          idFamilia: 0,
          denominacion: getTrad("todas").toUpperCase(),
          abreviatura: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
    sort: "idFamilia",
  });

  var enum_tipoTabla = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("unidades").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("peso").toUpperCase(),
    },
  ];

  //#endregion

  $.when(
    datasource_tipoProduccion.load(),
    datasource_familias.load(),
    datasource_filtroTiempo_spSelectComparativa.load(),
  )
    .then(function () {
      if (datasource_filtroTiempo_spSelectComparativa.items().length > 0) {
        $("#dxForm_opciones").dxForm({
          labelLocation: "top",
          height: "100%",
          scrollingEnabled: true,
          colCount: 1,
          items: [
            {
              label: { text: getTrad("seleccioneEntidad") },
              editorType: "dxDropDownBox",
              editorOptions: {
                width: "100%",
                deferRendering: false,
                placeholder: getTrad("todas").toUpperCase(),
                // EVENTS
                elementAttr: {
                  id: "dxDropDownBox_selCompaEnti",
                },
                dropDownOptions: {
                  width: 800,
                  height: 500,
                },
                contentTemplate: function (dxDropDownBox, contentElement) {
                  return $("<div>").dxBox({
                    direction: "row",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        ratio: 1,
                        template: function () {
                          return $(
                            "<div id='dxBox_Filtros_compañias' />",
                          ).dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              {
                                baseSize: 40,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='font-size' style='margin-left:9px;'>",
                                  ).text(getTrad("compañia"));
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='filtro_dxList_compañia'>",
                                  ).dxList({
                                    dataSource: datasource_compañias,
                                    selectionMode: "single",
                                    keyExpr: "idCompañia",
                                    scrolling: { mode: "infinite" },
                                    pageLoadMode: "scrollBottom",
                                    height: "100%",
                                    searchEnabled: true,
                                    searchExpr: ["denominacion"],
                                    onContentReady: function (e) {
                                      e.component.selectItem(0); // SELECCIONA EL ITEM 0 POR DEFECTO
                                    },
                                    onSelectionChanged: function (e) {
                                      var lista_entidades = $(
                                        "#filtro_dxList_entidad",
                                      ).dxList("instance");
                                      if (
                                        typeof lista_entidades !== "undefined"
                                      ) {
                                        if (
                                          e.addedItems.length > 0 &&
                                          e.addedItems[0].idCompañia !== 0
                                        ) {
                                          lista_entidades
                                            .getDataSource()
                                            .filter([
                                              [
                                                "idCompañia",
                                                "=",
                                                e.addedItems[0].idCompañia,
                                              ],
                                            ]);
                                        } else {
                                          lista_entidades
                                            .getDataSource()
                                            .filter("");
                                        }
                                        lista_entidades.reload();
                                      }
                                    },
                                    itemTemplate: function (data, index) {
                                      return $("<div>").text(data.denominacion);
                                    },
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $(
                            "<div id='dxBox_Filtros_entidades' style='padding-left:20px;' />",
                          ).dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              {
                                baseSize: 40,
                                shrink: 0,
                                ratio: 0,
                                template: function () {
                                  return $(
                                    "<div class='font-size' style='margin-left:9px;'>",
                                  ).text(getTrad("entidades"));
                                },
                              },
                              {
                                ratio: 1,
                                template: function () {
                                  return $(
                                    "<div id='filtro_dxList_entidad' />",
                                  ).dxList({
                                    dataSource: datasource_entidades,
                                    height: "100%",
                                    selectionMode: "single",
                                    keyExpr: "idEntidad",
                                    scrolling: { mode: "infinite" },
                                    pageLoadMode: "scrollBottom",
                                    searchEnabled: true,
                                    searchExpr: ["denominacion"],
                                    onContentReady: function (e) {
                                      e.component.selectItem(0); // SELECCIONA EL ITEM 0 POR DEFECTO
                                    },
                                    onItemClick: function (e) {
                                      $("#dxDropDownBox_selCompaEnti")
                                        .dxDropDownBox("instance")
                                        .close();
                                    },
                                    onSelectionChanged: function (e) {
                                      if (
                                        e.component.option().selectedItems
                                          .length > 0 &&
                                        $("#filtro_dxList_compañia").data(
                                          "dxList",
                                        ) != null
                                      ) {
                                        // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                        if (
                                          e.component.option().selectedItems[0]
                                            .idEntidad !== 0 &&
                                          e.component.option().selectedItems[0]
                                            .idEntidad !== -1
                                        ) {
                                          // SI NO SE SELECCIONA TODAS O DE COMPAÑÍA
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            "idEntidad",
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            "denominacion",
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            datasource_entidades,
                                          );
                                          dxDropDownBox.component.option(
                                            "value",
                                            e.component.option()
                                              .selectedItems[0].idEntidad,
                                          );
                                        } else if (
                                          $("#filtro_dxList_compañia")
                                            .dxList("instance")
                                            .option().selectedItems[0]
                                            ?.idCompañia === 0
                                        ) {
                                          // SI ESTÁS EN TODAS LAS COMPAÑÍAS O EN PRENDAS DE COMPAÑIA
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          ); // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            null,
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            null,
                                          );
                                        } else {
                                          dxDropDownBox.component.option(
                                            "value",
                                            null,
                                          ); // SI NO SE REINICIA EL VALOR DEL DDL ANTES DE REASIGNAR EL DATASOURCE -> PETA
                                          dxDropDownBox.component.option(
                                            "valueExpr",
                                            "idCompañia",
                                          );
                                          dxDropDownBox.component.option(
                                            "displayExpr",
                                            "denominacion",
                                          );
                                          dxDropDownBox.component.option(
                                            "dataSource",
                                            datasource_compañias,
                                          );
                                          dxDropDownBox.component.option(
                                            "value",
                                            $("#filtro_dxList_compañia")
                                              .dxList("instance")
                                              .option().selectedItems[0]
                                              ?.idCompañia,
                                          );
                                        }
                                        setDataSource_comparativa();
                                      }
                                    },
                                    itemTemplate: function (data, index) {
                                      return $("<div>").text(data.denominacion);
                                    },
                                  });
                                },
                              },
                            ],
                          });
                        },
                      },
                    ],
                  });
                },
              },
            },
            {
              label: { text: getTrad("familias") },
              editorType: "dxRadioGroup",
              editorOptions: {
                elementAttr: {
                  id: "dxRadioGroup_familias",
                  class: "dxRadioGroup_opciones",
                },
                dataSource: datasource_familias,
                valueExpr: "idFamilia",
                displayExpr: "denominacion",
                width: "100%",
                value: 0,
                onValueChanged: function (e) {
                  setDataSource_comparativa();
                },
              },
            },
            {
              label: { text: getTrad("tipoTabla") },
              editorType: "dxRadioGroup",
              editorOptions: {
                elementAttr: {
                  id: "dxRadioGroup_unidadesPeso",
                  class: "dxRadioGroup_opciones",
                },
                dataSource: enum_tipoTabla,
                valueExpr: "idTipoTabla",
                displayExpr: "denominacion",
                layout: "horizontal",
                value: 2,
                height: "100%",
                onValueChanged: function (e) {
                  setDataSource_comparativa();
                  is_unidades = e.value === 1 ? true : false;
                },
              },
            },
          ],
        });

        $("#InfComparativaProcesadosEntregados #dxContainer").dxBox({
          direction: "col",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          items: [
            {
              ratio: 1,
              template: function (itemData, itemIndex, itemElement) {
                itemElement.append(
                  $("<div />").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    items: [
                      {
                        baseSize: 60,
                        template: function (e, index, item) {
                          item.append(
                            $("<div id='dxToolbar_title' />").dxToolbar({
                              items: [
                                {
                                  location: "before",
                                  template: function (e, index, item) {
                                    item.addClass("d-flex w-100");
                                    item.append(
                                      $("<div id='toolbarTitle'/>")
                                        .addClass("font-size")
                                        .text(
                                          getTrad("lavanderia") +
                                            " - " +
                                            LAVANDERIA.denominacion,
                                        ),
                                      $("<div id='separador_familia' />").css(
                                        "flex",
                                        "0 0 5px",
                                      ),
                                      $("<div id='toolbarFamily'/>")
                                        .addClass("font-size")
                                        .text(""),
                                      $("<div id='separador_tipoTabla' />").css(
                                        "flex",
                                        "0 0 5px",
                                      ),
                                      $("<div id='toolbarTipoTabla'/>")
                                        .addClass("font-size")
                                        .text(""),
                                    );
                                  },
                                },
                                {
                                  location: "after",
                                  template: function (e, index, item) {
                                    //item.parent().parent().addClass("w-100");

                                    return $("<div />")
                                      .addClass("dxChart_contenedorIndividual")
                                      .append(
                                        $("<div />")
                                          .text(
                                            getTrad(
                                              "totalKgLavados",
                                            ).toUpperCase(),
                                          )
                                          .append(
                                            $(
                                              "<div id='kgLavados_text' />",
                                            ).addClass("lblKgLavados"),
                                          ),
                                      );
                                  },
                                },
                                {
                                  location: "after",
                                  template: function (e, index, item) {
                                    return $("<div />")
                                      .addClass("dxChart_contenedorIndividual")
                                      .append(
                                        $("<div id='toolbarPorcentaje' />")
                                          .text(
                                            getTrad(
                                              "porcentaje_kgProcesados_kgLavados",
                                            ).toUpperCase(),
                                          )
                                          .append(
                                            $(
                                              "<div id='kgLavadosProcesados_text'  />",
                                            ).addClass("kgLavadosProcesados"),
                                          ),
                                      );
                                  },
                                },
                                {
                                  location: "after",
                                  widget: "dxButton",
                                  options: {
                                    text: getTrad("exportar"),
                                    icon: "exportxlsx",
                                    onClick: function () {
                                      $("#dxDataGrid_comparativa")
                                        .dxDataGrid("instance")
                                        .exportToExcel();
                                    },
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          //#region La primera vez que se inicia coge estas fechas por defecto
                          var ultimaFecha = new Date(
                            datasource_filtroTiempo_spSelectComparativa.items()[
                              datasource_filtroTiempo_spSelectComparativa.items()
                                .length - 1
                            ].arg,
                          );
                          fechaDesde = startOfMonth(ultimaFecha);
                          fechaHasta = new Date(ultimaFecha);
                          //#endregion

                          is_unidades =
                            $("#dxRadioGroup_unidadesPeso")
                              .dxRadioGroup("instance")
                              .option("value") === 1
                              ? true
                              : false;
                          item.append(
                            $("<div id='dxDataGrid_comparativa' />").dxDataGrid(
                              {
                                //Propiedades
                                headerFilter: {
                                  visible: true,
                                },
                                export: {
                                  fileName:
                                    "InfComparativaProcesadosEntregados_Reducido",
                                },
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                  showAllText: getTrad("todos").toUpperCase(),
                                },
                                height: "100%",
                                columnAutoWidth: true,
                                paging: {
                                  enabled: false,
                                },
                                columns: [
                                  {
                                    dataField: "codigoPrenda",
                                    caption: getTrad("codigo"),
                                    width: 100,
                                    sortIndex: 1,
                                    sortOrder: "asc",
                                  },
                                  {
                                    dataField: "denominacion",
                                    caption: getTrad("denominacion"),
                                    minWidth: 250,
                                  },
                                  {
                                    dataField: "procesadas",
                                    caption: getTrad("procesadas"),
                                    customizeText: function (data) {
                                      return is_unidades
                                        ? formatNumber(data.value)
                                        : formatNumber(
                                            data.value,
                                            2,
                                            "decimal",
                                          );
                                    },
                                    dataType: "string",
                                    minWidth: 150,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "rechazadas",
                                    caption: getTrad("rechazadas"),
                                    customizeText: function (data) {
                                      return is_unidades
                                        ? formatNumber(data.value)
                                        : formatNumber(
                                            data.value,
                                            2,
                                            "decimal",
                                          );
                                    },
                                    dataType: "string",
                                    minWidth: 150,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "porcentaje_rechazo",
                                    caption: "% " + getTrad("rechazo"),
                                    customizeText: function (data) {
                                      return formatNumber(
                                        data.value,
                                        2,
                                        "percent",
                                      );
                                    },
                                    dataType: "string",
                                    minWidth: 150,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "entregadas",
                                    caption: getTrad("entregadas"),
                                    customizeText: function (data) {
                                      return is_unidades
                                        ? formatNumber(data.value)
                                        : formatNumber(
                                            data.value,
                                            2,
                                            "decimal",
                                          );
                                    },
                                    dataType: "string",
                                    minWidth: 150,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "porcentaje_entrega",
                                    caption: "% " + getTrad("entrega"),
                                    customizeText: function (data) {
                                      return formatNumber(
                                        data.value,
                                        2,
                                        "percent",
                                      );
                                    },
                                    dataType: "string",
                                    minWidth: 150,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                ],
                                summary: {
                                  recalculateWhileEditing: true,
                                  totalItems: [
                                    {
                                      column: "denominacion",
                                      displayFormat:
                                        getTrad("total").toUpperCase(),
                                    },
                                    {
                                      column: "procesadas",
                                      summaryType: "sum",
                                      customizeText: function (data) {
                                        return is_unidades
                                          ? ""
                                          : formatNumber(
                                              data.value,
                                              2,
                                              "decimal",
                                            ) + " Kg";
                                      },
                                      displayFormat: "{0}",
                                    },
                                    {
                                      column: "rechazadas",
                                      summaryType: "sum",
                                      customizeText: function (data) {
                                        return is_unidades
                                          ? ""
                                          : formatNumber(
                                              data.value,
                                              2,
                                              "decimal",
                                            ) + " Kg";
                                      },
                                      displayFormat: "{0}",
                                    },
                                    {
                                      name: "porcentaje_rechazo",
                                      showInColumn: "porcentaje_rechazo",
                                      summaryType: "custom",
                                      customizeText: function (data) {
                                        return formatNumber(
                                          data.value,
                                          2,
                                          "percent",
                                        );
                                      },
                                      displayFormat: "{0}",
                                    },
                                    {
                                      column: "entregadas",
                                      summaryType: "sum",
                                      customizeText: function (data) {
                                        return is_unidades
                                          ? ""
                                          : formatNumber(
                                              data.value,
                                              2,
                                              "decimal",
                                            ) + " Kg";
                                      },
                                    },
                                    {
                                      name: "porcentaje_entrega",
                                      showInColumn: "porcentaje_entrega",
                                      summaryType: "custom",
                                      customizeText: function (data) {
                                        return formatNumber(
                                          data.value,
                                          2,
                                          "percent",
                                        );
                                      },
                                      displayFormat: "{0}",
                                    },
                                  ],
                                  calculateCustomSummary: function (options) {
                                    if (options.name === "porcentaje_rechazo") {
                                      if (options.summaryProcess === "start") {
                                        options.totalValue = [0, 0];
                                      }
                                      if (
                                        options.summaryProcess === "calculate"
                                      ) {
                                        options.totalValue = [
                                          options.totalValue[0] +
                                            options.value.rechazadas,
                                          options.totalValue[1] +
                                            options.value.procesadas,
                                        ];
                                      }
                                      if (
                                        options.summaryProcess === "finalize"
                                      ) {
                                        options.totalValue =
                                          options.totalValue[0] /
                                          options.totalValue[1];
                                      }
                                    }

                                    if (options.name === "porcentaje_entrega") {
                                      if (options.summaryProcess === "start") {
                                        options.totalValue = [0, 0];
                                      }
                                      if (
                                        options.summaryProcess === "calculate"
                                      ) {
                                        options.totalValue = [
                                          options.totalValue[0] +
                                            options.value.entregadas,
                                          options.totalValue[1] +
                                            options.value.procesadas,
                                        ];
                                      }
                                      if (
                                        options.summaryProcess === "finalize"
                                      ) {
                                        options.totalValue =
                                          options.totalValue[1] !== 0
                                            ? options.totalValue[0] /
                                              options.totalValue[1]
                                            : 0;
                                      }
                                    }
                                  },
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              },
                            ),
                          );
                        },
                      },
                    ],
                  }),
                );
              },
            },
            {
              // Range selector
              baseSize: 130,
              template: function (e, index, itemElement) {
                itemElement.append(
                  create_rangeSelector(
                    datasource_filtroTiempo_spSelectComparativa,
                    false,
                    false,
                    function (e) {
                      fechaDesde = e.value[0];
                      fechaHasta = e.component.option("valueHasta");
                      $.when(
                        datasource_infProcesadosEntregados_spSelectComparativa.load(),
                        infProcesadosEntregados_kgLavadosProcesados.load(),
                      ).then(function (itemsComparativa, itemsKgLavados) {
                        kgLavados =
                          itemsKgLavados.length > 0 ? itemsKgLavados[0] : [];
                        setDataSource_comparativa();

                        calculo_lavadosProcesados_lavanderia(itemsKgLavados);

                        COMPONENT.loadPanel_hide();
                        $("#dxDataGrid_comparativa")
                          .dxDataGrid("instance")
                          .endCustomLoading();
                      });
                      dxRangeSelector_PosicionValor_nuevoMarker(
                        $("#dxRangeSelector_filtroTiempo"),
                        fechaDesde,
                        fechaHasta,
                      );
                    },
                    function () {
                      var ultimaFecha = new Date(
                        datasource_filtroTiempo_spSelectComparativa.items()[
                          datasource_filtroTiempo_spSelectComparativa.items()
                            .length - 1
                        ].arg,
                      );
                      $("#dxRangeSelector_filtroTiempo")
                        .dxRangeSelector("instance")
                        .option({
                          "scale.minRange": { days: null, months: 1 },
                          value: [
                            startOfMonth(ultimaFecha),
                            endOfMonth(new Date(ultimaFecha)),
                          ],
                        });
                    },
                  ),
                );
                $.when(
                  datasource_infProcesadosEntregados_spSelectComparativa.load(),
                  infProcesadosEntregados_kgLavadosProcesados.load(),
                ).then(function (itemsComparativa, itemsKgLavados) {
                  kgLavados =
                    itemsKgLavados.length > 0 ? itemsKgLavados[0] : [];
                  setDataSource_comparativa();
                  calculo_lavadosProcesados_lavanderia(itemsKgLavados);

                  COMPONENT.loadPanel_hide();
                  $("#dxDataGrid_comparativa")
                    .dxDataGrid("instance")
                    .endCustomLoading();
                });
              },
            },
          ],
        });
      } else {
        COMPONENT.loadPanel_hide();
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              history.push("/");
            },
            "danger",
            "btnErrorSinDatos",
          ],
        ]);
      }
    })
    .always(function () {
      setTimeout(() => {
        filtroTiempo_Resize();
      }, 50);
      deferred.resolve();
    });

  //#region FUNCIONES INTERNAS

  function calculo_lavadosProcesados_lavanderia(itemsKgLavados) {
    let kgLavados = 0;
    $.each(itemsKgLavados[0], function (index, item) {
      kgLavados += item.kgLavados;
    });

    let kgProcesados =
      itemsKgLavados.length > 0 && itemsKgLavados[0].length > 0
        ? itemsKgLavados[0][0].kgProcesados
        : 0;
    let porc_lavadosProcesados =
      kgProcesados > 0 ? kgProcesados / kgLavados - 1 : 0;

    $("#kgLavados_text").text(formatNumber(kgLavados, 2, "decimal") + " Kg.");
    $("#kgLavadosProcesados_text").text(
      formatNumber(porc_lavadosProcesados, 2, "percent") + " Kg.",
    );
  }

  function cambioTitulo() {
    var titulo = "",
      compaSel = $("#filtro_dxList_compañia")
        .dxList("instance")
        .option("selectedItem"),
      entiSel = $("#filtro_dxList_entidad")
        .dxList("instance")
        .option("selectedItem");

    //#region Lavandería || Compañía || Entidad
    if (compaSel?.idCompañia === 0 && entiSel.idEntidad === 0)
      titulo += getTrad("lavanderia") + " - " + LAVANDERIA.denominacion;
    else if (compaSel?.idCompañia !== 0 && entiSel.idEntidad === -1)
      titulo += compaSel?.denominacion + " - " + getTrad("prendasCompañia");
    else if (compaSel?.idCompañia !== 0 && entiSel.idEntidad === 0)
      titulo += compaSel?.denominacion + " - " + getTrad("todas");
    else titulo += entiSel.denominacion;

    $("#toolbarTitle").text(titulo);
    //#endregion

    let dxRadioGroup_familias = $("#dxRadioGroup_familias").dxRadioGroup(
        "instance",
      ),
      dxRadioGroup_unidadesPeso = $("#dxRadioGroup_unidadesPeso").dxRadioGroup(
        "instance",
      );

    //#region Família
    let selectedItem_familia = $.grep(
      dxRadioGroup_familias.getDataSource().items(),
      function (item) {
        return item.idFamilia === dxRadioGroup_familias.option("value");
      },
    );

    if (selectedItem_familia[0].idFamilia > 0) {
      $("#toolbarFamily").show();
      $("#separador_familia").show();
      $("#toolbarFamily").text(
        selectedItem_familia.length > 0
          ? " - " + selectedItem_familia[0].denominacion
          : "",
      );
    } else {
      $("#toolbarFamily").hide();
      $("#separador_familia").hide();
    }

    //#endregion

    //#region Tipo tabla
    let selectedItem_tipoTabla = $.grep(
      dxRadioGroup_unidadesPeso.getDataSource().items(),
      function (item) {
        return item.idTipoTabla === dxRadioGroup_unidadesPeso.option("value");
      },
    );

    $("#toolbarTipoTabla").text(
      selectedItem_tipoTabla.length > 0
        ? " - " + selectedItem_tipoTabla[0].denominacion
        : "",
    );
    //#endregion
  }

  function filtroDatos_comparativa(items, isExport) {
    isExport = isExport || false;
    if (!fechaDesde || !fechaHasta) {
      var dxRangeSelector_filtroTiempo = $(
        "#dxRangeSelector_filtroTiempo",
      ).dxRangeSelector("instance");
      fechaDesde = dxRangeSelector_filtroTiempo.option("value")[0];
      fechaHasta = dxRangeSelector_filtroTiempo.option("valueHasta");
    }

    // COGER LOS FILTROS DE LA PÁGINA
    var filtro_dxList_entidad = $("#filtro_dxList_entidad").dxList("instance"),
      filtro_dxList_compañia = $("#filtro_dxList_compañia").dxList("instance");
    var items_local = $.extend(true, [], items),
      idFamiliaSel =
        typeof $("#dxRadioGroup_familias").data("dxRadioGroup") !== "undefined"
          ? $("#dxRadioGroup_familias").dxRadioGroup("instance").option("value")
          : 0,
      idsTipoProduccionSel =
        typeof $("#dxList_TipoProduccion").data("dxList") !== "undefined"
          ? $("#dxList_TipoProduccion")
              .dxList("instance")
              .option("selectedItemKeys")
          : [];

    idCompaSel =
      filtro_dxList_compañia.option("selectedItemKeys").length > 0
        ? filtro_dxList_compañia.option("selectedItemKeys")[0]
        : 0;
    idEntidadSel =
      filtro_dxList_entidad.option("selectedItemKeys").length > 0
        ? filtro_dxList_entidad.option("selectedItemKeys")[0]
        : 0;
    is_todasCompa = idEntidadSel === 0;

    // CAMBIAR A UNIDADES O A PESO SEGÚN SELECTOR SWITCH
    if (isExport === false) {
      is_unidades =
        $("#dxRadioGroup_unidadesPeso")
          .dxRadioGroup("instance")
          .option("value") === 1
          ? true
          : false;
      if (!is_unidades) {
        $.each(items_local, function (index, element) {
          element.procesadas = element.peso * 0.001 * element.procesadas;
          element.rechazadas = element.peso * 0.001 * element.rechazadas;
          element.entregadas = element.peso * 0.001 * element.entregadas;
        });
      }
    }

    var listaEntidadesNCompa = [];
    $.each(
      $("#filtro_dxList_entidad").dxList("instance").getDataSource().items(),
      function (index, item) {
        if (item.idEntidad !== -1 && item.idEntidad !== 0)
          listaEntidadesNCompa.push(parseInt(item.idEntidad));
      },
    );

    return items_local.filter(function (dataItem) {
      var is_tipoProduccion =
          idsTipoProduccionSel.length !== 0
            ? idsTipoProduccionSel.indexOf(dataItem.idTipoProduccion) > -1
            : true,
        is_familia =
          idFamiliaSel !== 0 ? idFamiliaSel === dataItem.idFamilia : true,
        is_idCompaEnti = (function () {
          if (idCompaSel === 0 && idEntidadSel === 0) return true; // TODA LA LAVANDERIA
          if (idCompaSel !== 0 && idEntidadSel === 0)
            return (
              dataItem.idCompañia === idCompaSel ||
              $.inArray(dataItem.idEntidad, listaEntidadesNCompa) >= 0
            ); // Prendas compa + prenda entis de la compa
          if (idCompaSel !== 0 && idEntidadSel === -1)
            return dataItem.idCompañia === idCompaSel; // Prendas de la compañía
          if (idEntidadSel !== 0 || idEntidadSel !== -1)
            return dataItem.idEntidad === idEntidadSel; // Prendas de la entidad
        })();
      return is_tipoProduccion && is_idCompaEnti && is_familia;
    });
  }

  function setDataSource_comparativa() {
    var items_comparativa = filtroDatos_comparativa(
      datasource_infProcesadosEntregados_spSelectComparativa.items(),
    );
    // FILTRADO, AGRUPACIÓN Y SUMATORIO DE DATOS
    var dataSource = query(items_comparativa)
      .groupBy(function (dataItem) {
        if (!is_todasCompa)
          // SI ESTÁ SELECCIONADO PRENDAS DE COMPAÑÍA
          return [
            dataItem.idPrenda,
            dataItem.codigoPrenda,
            dataItem.denoPrenda,
          ];
        return [
          dataItem.idTipoPrenda,
          dataItem.codigoPrenda.substr(0, 2),
          dataItem.denoTipoPrenda,
        ];
      })
      .select(function (dataItem) {
        var resultItem = null;
        query(dataItem.items)
          .sum("entregadas")
          .done(function (result) {
            resultItem = {
              idTipoPrenda: dataItem.key[0],
              entregadas: result,
            };
          });
        query(dataItem.items)
          .sum("rechazadas")
          .done(function (result) {
            resultItem = {
              idTipoPrenda: dataItem.key[0],
              entregadas: resultItem.entregadas,
              rechazadas: result,
            };
          });
        query(dataItem.items)
          .sum("procesadas")
          .done(function (result) {
            resultItem = {
              porcentaje_rechazo:
                result !== 0 ? resultItem.rechazadas / result : 0,
              porcentaje_entrega:
                result !== 0 ? resultItem.entregadas / result : 0,
              idTipoPrenda: dataItem.key[0],
              idPrenda: !is_todasCompa ? dataItem.key[0] : null,
              codigoPrenda: dataItem.key[1],
              denominacion: dataItem.key[2],
              entregadas: resultItem.entregadas,
              rechazadas: resultItem.rechazadas,
              procesadas: result,
            };
          });
        return resultItem;
      })
      .toArray();

    if (idCompaSel === 0 && idEntidadSel === 0)
      $(".dxChart_contenedorIndividual").show();
    else $(".dxChart_contenedorIndividual").hide();

    cambioTitulo();
    $("#dxDataGrid_comparativa")
      .dxDataGrid("instance")
      .option("dataSource", dataSource);
  }
  //#endregion

  return deferred.promise();
}
