import Loader from "react-loaders";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { Popup, ScrollView, TagBox } from "devextreme-react";
import { Box, Item as ItemBox } from "devextreme-react/box";

import { Row, Col, Alert } from "reactstrap";

import { connectionConstants } from "constants";
import { authHeader, errorHandler } from "helpers";

import Maquina from "./Maquina";
import Area from "./Area";
import Tooltip from "./components/Tooltip";
import DraggableListItem from "./components/DraggableListItem";

import {
    svg_user,
    svg_add_circle,
    svg_delete_circle,
    svg_ask_circle,
    svg_calandra_jensen,
    svg_plegadora,
} from "../../../../styles/svg_iconos";
const colorHover = "rgba(237, 182, 55, 0.68)";

import $ from "jquery";
import { connect } from "react-redux";

const { Component, Fragment, createRef } = require("react");

class EstructuraOperativa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fecha: new Date(),
            turno: {},
            visible: false,
            posiciones: undefined,
            previsionesFiltradas: undefined,
            areas: [],
            maquinas: [],
            previsiones: [],
            categorias: [],
            tooltipData: { posicion: null, prevision: null, target: "" },
            previsionSeleccionada: undefined,
        };

        this.svg_icons();
    }

    get previsionSeleccionada() {
        return this.state.previsionSeleccionada;
    }

    // Lanzar popup con la informacion del turno
    launch(dia, turno) {
        const idLavanderia = this.props.idLavanderia;

        this.setState({ fecha: dia, turno: turno, visible: true });

        // Posiciones estructura operativa
        this.datasource_tblPosicionNAreaLavanderiaNLavanderia = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPosicionNAreaLavanderiaNLavanderia",
                key: "idPosicionNAreaLavanderiaNLavanderia",
                errorHandler: function (error) {
                    errorHandler(error, null);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };
                },
                version: 4,
            }),
            sort: "numPos",
            select: ["*"],
            filter: [["idLavanderia", "=", idLavanderia], "and", ["activo", "=", true]],
            expand: ["idAreaLavanderiaNavigation($select=denominacion)", "idMaquinaNavigation($select=etiqueta)"],
        });

        // Areas de la lavanderia
        this.datasource_tblAreaLavanderiaNLavanderia = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAreaLavanderiaNLavanderia",
                key: ["idAreaLavanderiaNLavanderia", "idLavanderia"],
                errorHandler: function (error) {
                    errorHandler(error, null);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };
                },
                version: 4,
            }),
            select: ["*"],
            filter: ["idLavanderia", "=", idLavanderia],
            expand: ["idAreaLavanderiaNavigation"],
        });

        // Maquinas de las posiciones
        this.datasource_tblMaquina = new DataSource({
            paginate: false,
            store: new ODataStore({
                url:
                    connectionConstants.WEB_API_CORE_ODATA_URL +
                    "MyPolarier/ParametrosGenerales/EstructuraOperativa/EstructuraOperativa_Maquinas",
                key: "idMaquina",
                errorHandler: function (error) {
                    errorHandler(error, null);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };
                    request.params.idLavanderia = idLavanderia;
                },
                version: 4,
            }),
            select: ["*"],
        });

        // Datasource del personal para la actualizacion de registros
        this.datasource_tblCuadrantePersonal = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCuadrantePersonal",
                key: "idCuadrantePersonal",
                errorHandler: function (error) {
                    errorHandler(error, null);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };
                },
                version: 4,
            }),
            select: [
                "idPersona",
                "idCuadrantePersonal",
                "idPosicionNAreaLavanderiaNLavanderia",
                "idLavanderia",
                "idTurno",
                "idEstado",
            ],
            filter: [
                ["fecha", "=", dia],
                ["idLavanderia", "=", idLavanderia],
                ["idTurno", "=", turno.idTurno],
                [["idEstado", "=", 3], "or", ["idEstado", "=", 4]],
                ["idPersonaNavigation/idTipoTrabajo", "=", 1], // 1 = Produccion
            ],
            expand: [
                "idPersonaNavigation($select=nombre,apellidos;$expand=idCategoriaInternaNavigation($select=denominacion))",
            ],
            map: (item) => this.datasource_tblCuadrantePersonal_map(item),
        });

        this.datasource_tblCuadrantePersonal_map = (item) => {
            item.nombre = (item.idPersonaNavigation.nombre + " " + item.idPersonaNavigation.apellidos).toUpperCase();
            item.categoria = item.idPersonaNavigation.idCategoriaInternaNavigation?.denominacion.toUpperCase();
            delete item.idPersonaNavigation;

            return item;
        };

        // Esperar a que terminen todas las llamadas
        Promise.all([
            this.datasource_tblPosicionNAreaLavanderiaNLavanderia.load(),
            this.datasource_tblAreaLavanderiaNLavanderia.load(),
            this.datasource_tblMaquina.load(),
            this.datasource_tblCuadrantePersonal.load(),
        ]).then(
            ([
                tblPosicionNAreaLavanderiaNLavanderia,
                tblAreaLavanderiaNLavanderia,
                tblMaquina,
                tblCuadrantePersonal,
            ]) => {
                const posiciones = tblPosicionNAreaLavanderiaNLavanderia;
                const areasLavanderia = tblAreaLavanderiaNLavanderia.filter((x) => {
                    return posiciones.filter((p) => x.idAreaLavanderia === p.idAreaLavanderia).length <= 1;
                });
                const maquinas = tblMaquina;
                const previsiones = tblCuadrantePersonal;

                const categorias = [];
                previsiones.map((x) => {
                    x.posicion = posiciones.find(
                        (p) => x.idPosicionNAreaLavanderiaNLavanderia === p.idPosicionNAreaLavanderiaNLavanderia
                    );

                    x.denominacion = x.posicion?.denominacion;
                    x.idAreaLavanderia = x.posicion?.idAreaLavanderia;
                    x.idMaquina = x.posicion?.idMaquina;

                    let maquina = maquinas.find((m) => m.idMaquina === x.posicion?.idMaquina);
                    x.etiqueta = maquina?.etiqueta;

                    if (!categorias.includes(x.categoria)) {
                        categorias.push(x.categoria);
                    }
                    return x;
                });

                this.setState({
                    posiciones: posiciones,
                    areas: areasLavanderia,
                    maquinas: maquinas,
                    previsiones: previsiones,
                    previsionesFiltradas: previsiones,
                    categorias: categorias,
                });
            }
        );
    }

    // Seteo de los SVG utilizados
    svg_icons() {
        function svg_color(svg, color) {
            $.each(svg[0].childNodes, function (i, node) {
                node.style["stroke"] = color;
                node.style.fill = color;
            });

            return svg;
        }
        this.svg_shapes = {
            remove: encodeURI("data:image/svg+xml;utf-8," + svg_delete_circle.clone()[0].outerHTML),
            missing: encodeURI("data:image/svg+xml;utf-8," + svg_ask_circle.clone()[0].outerHTML),

            add: encodeURI("data:image/svg+xml;utf-8," + svg_add_circle.clone()[0].outerHTML),
            addHover: encodeURI(
                "data:image/svg+xml;utf-8," + svg_color(svg_add_circle.clone(), colorHover)[0].outerHTML
            ),

            //#region  PERSONA
            persona: encodeURI("data:image/svg+xml;utf-8," + svg_user.clone()[0].outerHTML),
            personaHover: encodeURI("data:image/svg+xml;utf-8," + svg_color(svg_user.clone(), colorHover)[0].outerHTML),
            //#endregion

            calandra: encodeURI("data:image/svg+xml;utf-8," + svg_calandra_jensen.clone()[0].outerHTML),
            plegador: encodeURI("data:image/svg+xml;utf-8," + svg_plegadora.clone()[0].outerHTML),
        };
    }

    // Establecer informacion de las tarjetas
    setTooltipData = (posicion, prevision, target) => {
        this.setDelay({
            tooltipData: {
                posicion: posicion,
                prevision: prevision,
                target: target,
            },
        });
    };

    // Restablecer tarjeta y ocultarla
    resetTooltipData = () => {
        this.clearDelay();
        this.setState({
            tooltipData: {
                posicion: null,
                prevision: null,
                target: "",
            },
        });
    };

    getTooltipData = () => {
        return this.state.tooltipData;
    };

    // Actualizar cambios en la parte visible del mapa
    notificarCambio(cambios) {
        const { posiciones, previsiones } = this.state;
        previsiones.map((x) => {
            cambios.forEach((c) => {
                if (c.idCuadrantePersonal === x.idCuadrantePersonal) {
                    x.idPosicionNAreaLavanderiaNLavanderia = c.idPosicionNAreaLavanderiaNLavanderia;
                    if (c.idPosicionNAreaLavanderiaNLavanderia !== null) {
                        const pos = posiciones.find(
                            (x) => x.idPosicionNAreaLavanderiaNLavanderia === c.idPosicionNAreaLavanderiaNLavanderia
                        );
                        x.idMaquina = pos.idMaquina;
                        x.idAreaLavanderia = pos.idAreaLavanderia;
                        x.posicion = pos;
                    } else {
                        x.idMaquina = null;
                        x.idAreaLavanderia = null;
                        x.posicion = null;
                    }
                }
            });
            return x;
        });
        this.setState({
            previsiones: previsiones,
            previsionSeleccionada: undefined,
            tooltipData: { posicion: null, prevision: null, target: "" },
        });
    }

    // Establecer delay para enseñar las tarjetas (evitar renderizados excesivos)
    setDelay(newState) {
        this.delay = setTimeout(() => {
            this.setState({ ...newState });
        }, 100);
    }

    // Limpiar delay
    clearDelay() {
        clearTimeout(this.delay);
    }

    render() {
        const {
            posiciones,
            previsionesFiltradas,
            areas,
            maquinas,
            previsiones,
            tooltipData,
            categorias,
            fecha,
            turno,
            visible,
        } = this.state;

        const fechaString = fecha.toLocaleDateString(undefined, {
            weekday: "long",
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        });

        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        const habilitarInteraccion = hoy <= fecha;

        return (
            <Popup
                visible={visible}
                width={"92.5%"}
                height={"92.5%"}
                dragEnabled={false}
                id="pop"
                title={`${fechaString.toUpperCase()} - ${turno.denominacion}`}
                onHiding={() => {
                    this.setState({
                        visible: false,
                        previsiones: undefined,
                        previsionesFiltradas: undefined,
                        posiciones: undefined,
                    });
                    this.props.CuadranteContext.reload();
                }}
                onContentReady={({ component, element }) => {
                    component.content().css("padding", "0");
                }}
            >
                <div style={{ height: "100%" }}>
                    {posiciones && previsionesFiltradas && posiciones.length > 0 && previsionesFiltradas.length > 0 ? (
                        <Fragment>
                            <Box style={{ height: "100%" }}>
                                <ItemBox ratio={8}>
                                    <ScrollView className={"cardContent"} style={{ height: "100%" }}>
                                        <Row
                                            style={{
                                                justifyContent: "space-evenly",
                                                paddingRight: 5,
                                            }}
                                        >
                                            {maquinas
                                                .filter(
                                                    (x) =>
                                                        posiciones.filter((y) => y.idMaquina === x.idMaquina).length > 0
                                                )
                                                .map((maquina) => {
                                                    return (
                                                        <Maquina
                                                            key={maquina.idMaquina}
                                                            layout_REF={this}
                                                            svg={this.svg_shapes}
                                                            maquina={maquina}
                                                            posiciones={posiciones.filter(
                                                                (x) => x.idMaquina === maquina.idMaquina
                                                            )}
                                                            previsiones={previsiones.filter(
                                                                (x) => x.idMaquina === maquina.idMaquina
                                                            )}
                                                            enablePosition={habilitarInteraccion}
                                                        />
                                                    );
                                                })}
                                            {areas.map((area) => {
                                                return (
                                                    <Area
                                                        key={area.idAreaLavanderia}
                                                        layout_REF={this}
                                                        svg={this.svg_shapes}
                                                        area={area}
                                                        posicion={posiciones.find(
                                                            (x) =>
                                                                x.idMaquina === null &&
                                                                x.idAreaLavanderia === area.idAreaLavanderia
                                                        )}
                                                        previsiones={previsiones.filter(
                                                            (x) =>
                                                                x.idAreaLavanderia === area.idAreaLavanderia &&
                                                                x.idMaquina === null
                                                        )}
                                                        enablePosition={habilitarInteraccion}
                                                    />
                                                );
                                            })}
                                        </Row>
                                    </ScrollView>
                                </ItemBox>
                                <ItemBox ratio={2}>
                                    <ScrollView style={{ paddingLeft: 15, height: "100%" }} showScrollbar="always">
                                        <div style={{ position: "sticky", top: 0, background: "#fff" }}>
                                            {/* Filtro de categorias */}
                                            <TagBox
                                                items={categorias}
                                                style={{ marginBottom: 10 }}
                                                onValueChanged={({ value }) => {
                                                    this.setState({
                                                        previsionesFiltradas: this.state.previsiones.filter((x) =>
                                                            value.length > 0
                                                                ? value.includes(x.categoria)
                                                                : this.state.previsiones
                                                        ),
                                                    });
                                                }}
                                            />
                                            {/* Aviso de empleados pendientes */}
                                            {previsiones.filter((x) => x.idPosicionNAreaLavanderiaNLavanderia === null)
                                                .length > 0 ? (
                                                <Alert color="warning">Quedan personas sin asignar</Alert>
                                            ) : (
                                                <Alert color="success">Todas las personas están asignadas</Alert>
                                            )}
                                            {/* Crear tarjetas arrastables de los trabajadores */}
                                        </div>
                                        <div>
                                            {previsionesFiltradas.map((prevision) => (
                                                <DraggableListItem
                                                    enableDrag={habilitarInteraccion}
                                                    key={prevision.idCuadrantePersonal}
                                                    layout_REF={this}
                                                    data={prevision}
                                                    svg={this.svg_shapes}
                                                    group={"draggingGroup"}
                                                />
                                            ))}
                                        </div>
                                    </ScrollView>
                                </ItemBox>
                            </Box>

                            <Tooltip
                                target={tooltipData.target}
                                posicion={tooltipData.posicion}
                                prevision={tooltipData.prevision}
                            />
                        </Fragment>
                    ) : posiciones && posiciones.length === 0 ? (
                        <div className="container_spanCentrado">
                            <Alert color="danger">No se han encontrado posiciones</Alert>
                        </div>
                    ) : previsionesFiltradas && previsionesFiltradas.length === 0 ? (
                        <div className="container_spanCentrado">
                            <Alert color="danger">No se han encontrado previsiones</Alert>
                        </div>
                    ) : (
                        <Fragment>
                            <div className={"loader-container absolute shading"}>
                                <div className="loader-container-inner">
                                    <div className="text-center">
                                        <Loader type="line-scale" />
                                    </div>
                                    <h6 className="mt-3">Cargando...</h6>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>
            </Popup>
        );
    }
}

const mapStateToProps = (state) => ({
    fotosPerfil: state.fotos.fotosPerfil,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(EstructuraOperativa);
