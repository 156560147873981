import React, { createRef } from "react";
import $ from "jquery";
import "./SelectorPersonas.scss";

import { errorHandler, authHeader, getTrad } from "helpers";
import { connectionConstants } from "constants";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

import DataGrid, { Column, FilterRow, Lookup, Selection } from "devextreme-react/data-grid";
import { DropDownBox, TextBox } from "devextreme-react";
import { DropDownOptions } from "devextreme-react/drop-down-box";

/**
 * ## Selector de personas a las que el usuario tiene acceso.
 * @class
 * @extends React.Component
 *
 * @property {boolean} selectorCompacto - Indica si el selector es compacto.
 * @function {function} refresh - Función que recarga el origen de datos del dxDataGrid.
 *
 * ### Propiedades DataSource
 * @property {DataSource} dataSource - El origen de datos del dxDataGrid.
 * @property {Array<string>} select - Las columnas a seleccionar del origen de datos.
 * @property {Array<string>} filter - Las condiciones de filtrado del origen de datos.
 * @property {Array<string>} expand - Las columnas a expandir del origen de datos.
 * @property {function} map - Las columnas a mapear del origen de datos.
 *
 *
 * ### Propiedades dxDataGrid
 * Acepta los props de dxDataGrid y de algunos de sus hijos.
 * @property {Array} selectedRowKeys - Las claves primarias de las filas seleccionadas.
 * @property {Array} columns - Las columnas del dxDataGrid. Las columnas de lavandería, categoría interna y tipo de trabajo se mapean con Lookup.
 * @property {string} selectionMode - El modo de selección del dxDataGrid.
 * @property {boolean} allowFiltering - Indica si se permite filtrar el dxDataGrid.
 * @property {function} onSelectionChanged - Función que se ejecuta cuando cambia la selección.
 *
 *
 * ### Propiedades dxDropDownBox
 * @property {string} stylingMode - El modo de estilo del dxDropDownBox.
 * @property {React.Element} children - Validador para dxDropDownBox.
 */
class SelectorPersonas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedRowKeys: [],
            selectedRowsData: [],
        };

        this.dxDropDownBox_personas_REF = createRef();
        this.dxDataGrid_personas_REF = createRef();
    }

    get dxDropDownBox() {
        return this.dxDropDownBox_personas_REF.current.instance;
    }

    get dxDataGrid() {
        return this.dxDataGrid_personas_REF.current?.instance;
    }

    // #region Componente

    componentDidUpdate = (prevProps, prevState) => {
        if (JSON.stringify(prevProps.selectedRowKeys) !== JSON.stringify(this.props.selectedRowKeys))
            if (this.props.selectedRowKeys?.at(0) != null)
                this.dxDataGrid?.selectRows(this.props.selectedRowKeys, false);
            else this.dxDataGrid?.deselectAll();
    };

    // #endregion

    // #region Datasources

    datasource_beforeSend = (request) => {
        request.headers = { ...authHeader() };
    };

    datasource_tblPersona = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
            key: "idPersona",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => this.datasource_beforeSend_tblPersona(request),
            onLoading: (loadOptions) => this.datasource_tblPersona_onLoading(loadOptions),
            onLoaded: (data) => this.datasource_tblPersona_onLoaded(data),
            version: 4,
        }),
        select: this.props.select,
        filter: this.props.filter,
        expand: this.props.expand,
        map: this.props.map,
    });

    datasource_beforeSend_tblPersona = (request) => {
        request.headers = { ...authHeader() };

        if (this.props.beforeSend) this.props.beforeSend(request);
    };

    datasource_tblPersona_onLoading = (loadOptions) => {
        if (loadOptions.sort) {
            if (loadOptions.sort.some((x) => x.selector === "idCentroLav")) {
                loadOptions.sort.shift();
                if (!loadOptions.sort.some((x) => x.selector === "idCentroTrabajo"))
                    loadOptions.sort.unshift({ selector: "idCentroTrabajo", desc: false });
                if (!loadOptions.sort.some((x) => x.selector === "idLavanderia"))
                    loadOptions.sort.unshift({ selector: "idLavanderia", desc: false });
            }
        }
        if (loadOptions.filter) {
            let filter = findNestedArray(loadOptions.filter, "idCentroLav");
            if (filter != null) {
                let parsed = JSON.parse(filter[2]);
                let idLavanderia = !parsed ? null : parsed[1] > 0 ? parsed[1] : null;
                let idCentroTrabajo = !parsed ? null : parsed[0] > 0 ? parsed[0] : null;

                filter.splice(0);
                if (idLavanderia) filter.push("idLavanderia", "=", idLavanderia);
                else if (idCentroTrabajo) filter.push("idCentroTrabajo", "=", idCentroTrabajo);
            }
        }
    };

    datasource_tblPersona_onLoaded = (data) => {
        data.map((item) => {
            item.idCentroLav = JSON.stringify([
                item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                item.idLavanderia ? item.idLavanderia : -1,
            ]);
            return item;
        });
    };

    store_tblLavanderia = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
        key: "idLavanderia",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => this.datasource_beforeSend(request),
        version: 4,
    });

    store_tblTipoTrabajo = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoTrabajo",
        key: "idTipoTrabajo",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        onLoaded: (result) => this.store_tblTipoTrabajo_onLoaded(result),
        beforeSend: (request) => this.datasource_beforeSend(request),
        version: 4,
    });

    store_tblTipoTrabajo_onLoaded = (result) => {
        result.map((x) => {
            delete x.icon;
            return x;
        });
    };

    store_tblCategoriaInterna = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
        key: "idCategoriaInterna",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => this.datasource_beforeSend(request),
        version: 4,
    });

    store_tblCentroTrabajo = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCentroTrabajo",
        key: "idCentroTrabajo",
        errorHandler: function (error) {
            errorHandler(error, null);
        },
        beforeSend: (request) => this.datasource_beforeSend(request),
        version: 4,
    });

    store_tblCentroTrabajo_Lavanderia = new CustomStore({
        key: "idCentroLav",
        load: (loadOptions) => this.store_tblCentroTrabajo_Lavanderia_load(loadOptions),
        byKey: (key) => this.store_tblCentroTrabajo_Lavanderia_byKey(key),
    });

    store_tblCentroTrabajo_Lavanderia_load = (loadOptions) => {
        return new Promise((resolve, reject) => {
            Promise.all([this.store_tblCentroTrabajo.load(), this.store_tblLavanderia.load()]).then(
                ([tblCentroTrabajo, tblLavanderia]) => {
                    let datasource_tblCentroTrabajo_Lavanderia = $.map(
                        $.merge(tblCentroTrabajo, tblLavanderia),
                        function (item, index) {
                            return {
                                groupTitle: item.idCentroTrabajo ? getTrad("centrosTrabajo") : getTrad("lavanderías"),
                                idCentroLav: JSON.stringify([
                                    item.idCentroTrabajo ? item.idCentroTrabajo : -1,
                                    item.idLavanderia ? item.idLavanderia : -1,
                                ]),
                                denominacion: item.denominacion,
                                idPais: item.idPais,
                            };
                        }
                    );

                    resolve(datasource_tblCentroTrabajo_Lavanderia);
                }
            );
        });
    };

    store_tblCentroTrabajo_Lavanderia_byKey = (key) => {
        return new Promise((resolve, reject) => {
            this.store_tblCentroTrabajo_Lavanderia_load().then((data) => {
                let result = data.filter((x) => x.idCentroLav === key)[0];
                resolve(result);
            });
        });
    };

    // #endregion

    refresh = () => {
        this.datasource_tblPersona.reload();
        this.dxDataGrid?.clearFilter("row");
    };

    render() {
        const { selectorCompacto = false, stylingMode, dropDownWidth } = this.props;
        const { selectedRowKeys } = this.state;

        return selectorCompacto ? (
            <DropDownBox
                value={selectedRowKeys}
                ref={this.dxDropDownBox_personas_REF}
                fieldRender={this.dxDropDownBox_fieldRender}
                contentRender={this.renderDataGrid}
                deferRendering={false}
                stylingMode={stylingMode}
            >
                <DropDownOptions minWidth={700} width={dropDownWidth} />
            </DropDownBox>
        ) : (
            <this.renderDataGrid />
        );
    }

    renderDataGrid = () => {
        const { selectedRowKeys } = this.state;
        const {
            dataSource,
            columns,
            selectionMode = "single",
            selectorCompacto = false,
            allowFiltering = false,
            ...dataGridOptions
        } = this.props;

        return (
            <div className="fillContent">
                <DataGrid
                    ref={this.dxDataGrid_personas_REF}
                    showColumnLines={false}
                    showRowLines={true}
                    // {...dataGridOptions}
                    dataSource={dataSource ?? this.datasource_tblPersona}
                    selectedRowKeys={selectedRowKeys}
                    className={`dxDataGridRowHover ${dataGridOptions?.className ?? ""}`}
                    onSelectionChanged={this.dxDataGrid_onSelectionChanged}
                    onRowClick={selectionMode === "multiple" ? this.dxDataGrid_onRowClick : undefined}
                >
                    <Selection mode={selectionMode} showCheckBoxesMode={"always"} />
                    <FilterRow visible={allowFiltering} />
                    <Column
                        dataField={"nombre"}
                        caption={getTrad("nombre")}
                        allowSorting={false}
                        sortOrder={"asc"}
                        minWidth={150}
                    />
                    <Column
                        dataField={"apellidos"}
                        caption={getTrad("apellidos")}
                        allowSorting={false}
                        sortOrder={"asc"}
                        minWidth={150}
                    />
                    {(columns != null ? columns : this.defaultColumns).map(this.dxDataGrid_columns_mapLookup)}
                    <Column
                        dataField={"disabled"}
                        caption={""}
                        width={0}
                        visible={true}
                        allowFiltering={false}
                        allowSorting={false}
                        dataType={"bool"}
                        cellTemplate={this.dxDataGridPersonas_cellTemplate_disabled}
                    />
                </DataGrid>
            </div>
        );
    };

    /**
     * Columnas predeterminadas para el selector de personas.
     * @type {Array<Column>}
     */
    defaultColumns = [
        <Column
            dataField={"idCentroLav"}
            caption={getTrad("centro")}
            allowSorting={false}
            sortOrder={"asc"}
            sortIndex={0}
            minWidth={150}
        />,
        <Column
            dataField={"idTipoTrabajo"}
            caption={getTrad("tipoTrabajo")}
            allowSorting={false}
            sortOrder={"asc"}
            sortIndex={1}
            minWidth={150}
        />,
    ];

    /**
     * Función que mapea las columnas del dxDataGrid con Lookup.
     * @param {React.Element} column - La columna a mapear.
     * @returns {React.Element} - La columna mapeada.
     */
    dxDataGrid_columns_mapLookup = (column) => {
        if (column.props.dataField == "idLavanderia" && column.props.children == null) {
            column = React.cloneElement(column, {
                children: (
                    <Lookup
                        dataSource={this.store_tblLavanderia}
                        valueExpr={"idLavanderia"}
                        displayExpr={"denominacion"}
                    />
                ),
            });
        }
        if (column.props.dataField == "idCentroTrabajo" && column.props.children == null) {
            column = React.cloneElement(column, {
                children: (
                    <Lookup
                        dataSource={this.store_tblCentroTrabajo}
                        valueExpr={"idCentroTrabajo"}
                        displayExpr={"denominacion"}
                    />
                ),
            });
        }
        if (column.props.dataField == "idCentroLav" && column.props.children == null) {
            column = React.cloneElement(column, {
                children: (
                    <Lookup
                        dataSource={this.store_tblCentroTrabajo_Lavanderia}
                        valueExpr={"idCentroLav"}
                        displayExpr={"denominacion"}
                    />
                ),
            });
        }
        if (column.props.dataField == "idTipoTrabajo" && column.props.children == null) {
            column = React.cloneElement(column, {
                children: (
                    <Lookup
                        dataSource={this.store_tblTipoTrabajo}
                        valueExpr={"idTipoTrabajo"}
                        displayExpr={"denominacion"}
                    />
                ),
            });
        }
        if (column.props.dataField == "idCategoriaInterna" && column.props.children == null) {
            column = React.cloneElement(column, {
                children: (
                    <Lookup
                        dataSource={this.store_tblCategoriaInterna}
                        valueExpr={"idCategoriaInterna"}
                        displayExpr={"denominacion"}
                    />
                ),
            });
        }
        return column;
    };

    dxDataGrid_onSelectionChanged = (e) => {
        const { selectorCompacto, onSelectionChanged, selectionMode = "single" } = this.props;

        if (selectorCompacto && selectionMode === "single" && e.selectedRowsData?.at(0)) this.dxDropDownBox.close();

        this.setState({
            selectedRowKeys: e.selectedRowKeys,
            selectedRowsData: e.selectedRowsData,
        });

        if (onSelectionChanged) onSelectionChanged(e);
    };

    dxDataGrid_onRowClick = (e) => {
        if (e.component.isRowSelected(e.key)) {
            e.component.deselectRows([e.key]);
        } else {
            const { selectionMode } = this.props;
            e.component.selectRows([e.key], selectionMode === "multiple");
        }
    };

    dxDataGridPersonas_cellTemplate_disabled = (element, e) => {
        e.value && $(element).parent().addClass("dx-state-disabled opacity-5");
    };

    dxDropDownBox_fieldRender = (e) => {
        const { stylingMode, selectionMode, children } = this.props;
        const { selectedRowsData } = this.state;

        let value = selectedRowsData.at(0);

        return (
            <TextBox
                value={
                    selectedRowsData.length > 1 || (selectedRowsData.length === 0 && selectionMode === "multiple")
                        ? selectedRowsData.length + " " + getTrad("personas")
                        : value
                        ? value?.nombre + " " + value?.apellidos
                        : ""
                }
                stylingMode={stylingMode}
                readOnly={true}
            >
                {children}
            </TextBox>
        );
    };
}

function findNestedArray(arr, key) {
    let result = null;
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            result = findNestedArray(arr[i], key);
            if (result) break;
        } else if (arr[i] === key) {
            result = arr;
            break;
        }
    }
    return result;
}

export default SelectorPersonas;
