import { connectionConstants } from "../../../../constants";
import {
  history,
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  startOfMonth,
  leadingZero,
  dxMensajePregunta,
  create_rangeSelector,
  filtroTiempo_Resize,
  is_paramRedireccion_outOfRange,
} from "../../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();
  //#region DATASOURCE
  var datasource_spSelectCumplimientoTareasNMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infPreventivoSAT_spSelectCumplimientoTareasNMaquina",
      key: "idTareaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.idMaquina = idMaquinaSel;
      },
      version: 4,
    }),
  });

  var datasource_filtroTiempo_cumplimiento = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infPreventivoSAT_filtroTiempo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var item = data[0];
      var dataSource = [];
      dataSource.push({ arg: item.fechaDesde, value: item.fechaDesde });
      dataSource.push({ arg: item.fechaHasta, value: item.fechaHasta });
      return dataSource;
    },
  });

  var datasource_tblMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infPreventivoSAT_spSelectMaquinas",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
        request.params.getInactivas = true;
      },
      version: 4,
    }),
    sort: [{ selector: "activo", desc: true }, "denominacion"],
  });

  //#endregion

  //#region VARIABLES GLOBALES
  var fechaDesde = null,
    fechaHasta = null,
    idMaquinaSel = -1;
  //#endregion

  let { param_paginaSecundaria } = COMPONENT.props;
  let fechaDesde_redireccion =
    param_paginaSecundaria != null ? param_paginaSecundaria.fechaDesde : null;
  let fechaHasta_redireccion =
    param_paginaSecundaria != null ? param_paginaSecundaria.fechaHasta : null;

  $.when(datasource_filtroTiempo_cumplimiento.load())
    .then(function () {
      var items = datasource_filtroTiempo_cumplimiento.items();
      if (items[0].arg) {
        let fechaHasta_Datos = items[1].arg;

        fechaDesde =
          param_paginaSecundaria != null &&
          fechaDesde_redireccion <= fechaHasta_Datos
            ? fechaDesde_redireccion
            : startOfMonth(new Date(items[1].arg));
        fechaHasta =
          param_paginaSecundaria != null &&
          fechaHasta_redireccion <= fechaHasta_Datos
            ? fechaHasta_redireccion
            : items[1].arg;

        let fechas_paramAlert = {
          paramString: {
            desde: new Date(fechaDesde_redireccion),
            hasta: new Date(fechaHasta_redireccion),
          },
          bd: {
            desde: items[0].arg,
            hasta: items[1].arg,
          },
        };
        if (param_paginaSecundaria != null)
          is_paramRedireccion_outOfRange(fechas_paramAlert);

        datasource_tblMaquina.load().done(function () {
          COMPONENT.loadPanel_hide();
          $("#InfPreventivoSAT #dxContainer").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            items: [
              {
                baseSize: 40,
                template: function (e, index, item) {
                  item.append(
                    $("<div />").dxToolbar({
                      items: [
                        {
                          location: "after",
                          widget: "dxButton",
                          options: {
                            text: getTrad("exportar"),
                            icon: "exportxlsx",
                            onClick: function (e) {
                              $("#dxDataGrid_cumplimientoMaquina")
                                .dxDataGrid("instance")
                                .exportToExcel();
                            },
                          },
                        },
                      ],
                    }),
                  );
                },
              },
              {
                ratio: 1,
                template: function (e, index, item) {
                  item.append(
                    $("<div />").dxBox({
                      direction: "row",
                      align: "space-around",
                      crossAlign: "stretch",
                      height: "100%",
                      items: [
                        {
                          ratio: 2,
                          template: function (e, index, item) {
                            item.append(
                              $("<div id='dxDataGrid_seleccionMaquina' />")
                                .css("padding-bottom", 10)
                                .dxDataGrid({
                                  dataSource: datasource_tblMaquina.items(),
                                  columnsAutoWidth: true,
                                  hoverStateEnabled: true,
                                  paging: {
                                    enabled: false,
                                  },
                                  keyExpr: ["idMaquina"],
                                  filterRow: { visible: true },
                                  grouping: {
                                    autoExpandAll: true,
                                  },
                                  loadPanel: { enabled: false },
                                  groupPanel: {
                                    visible: false,
                                  },
                                  selection: { mode: "single" },
                                  sorting: { mode: "none" },
                                  height: "100%",
                                  columns: [
                                    {
                                      dataField: "denominacion",
                                      caption: getTrad("denominacion"),
                                    },
                                    {
                                      dataField: "cumplimiento",
                                      caption: getTrad("cumplimiento"),
                                      allowFiltering: false,
                                      width: 110,
                                      alignment: "center",
                                      calculateDisplayValue: function (e) {
                                        return formatNumber(
                                          e.cumplimiento,
                                          2,
                                          "percent",
                                        );
                                      },
                                    },
                                  ],
                                  summary: {
                                    totalItems: [
                                      {
                                        column: "denominacion",
                                        displayFormat:
                                          getTrad("total").toUpperCase(),
                                      },
                                      {
                                        name: "cumplimiento",
                                        showInColumn: "cumplimiento",
                                        summaryType: "custom",
                                        customizeText: function (data) {
                                          return formatNumber(
                                            data.value,
                                            2,
                                            "percent",
                                          );
                                        },
                                        displayFormat: "{0}",
                                      },
                                    ],
                                    calculateCustomSummary: function (options) {
                                      if (options.name === "cumplimiento") {
                                        if (
                                          options.summaryProcess === "start"
                                        ) {
                                          options.totalValue = [0, 0];
                                        }
                                        if (
                                          options.summaryProcess === "calculate"
                                        ) {
                                          let { tiempoTotal, tiempoRealizado } =
                                            options.value;
                                          if (options.value.activo)
                                            options.totalValue = [
                                              tiempoTotal +
                                                parseInt(options.totalValue[0]),
                                              tiempoRealizado +
                                                parseInt(options.totalValue[1]),
                                            ];
                                        }
                                        if (
                                          options.summaryProcess === "finalize"
                                        ) {
                                          if (
                                            options.totalValue[0] === 0 ||
                                            options.totalValue[1] === 0
                                          )
                                            options.totalValue = 0;
                                          else
                                            options.totalValue =
                                              options.totalValue[1] /
                                              options.totalValue[0];
                                        }
                                      }
                                    },
                                  },
                                  onContentReady: function (e) {
                                    if (
                                      e.component.getSelectedRowKeys().length <
                                      1
                                    )
                                      e.component.selectRowsByIndexes(0);
                                  },
                                  onRowPrepared: function (e) {
                                    if (e.rowType === "data") {
                                      e.rowElement.css("cursor", "pointer");

                                      if (!e.data.activo)
                                        e.rowElement.addClass("disabledRow");
                                    }
                                  },
                                  onSelectionChanged: function (e) {
                                    idMaquinaSel = -1;
                                    if (e.selectedRowsData.length > 0)
                                      idMaquinaSel =
                                        e.selectedRowsData[0].idMaquina;

                                    if (
                                      typeof $(
                                        "#dxDataGrid_cumplimientoMaquina",
                                      ).data("dxDataGrid") !== "undefined"
                                    ) {
                                      $("#dxDataGrid_cumplimientoMaquina")
                                        .dxDataGrid("instance")
                                        .refresh()
                                        .done(function () {});
                                    }
                                  },
                                }),
                            );
                          },
                        },
                        {
                          ratio: 7,
                          template: function (e, index, item) {
                            var date = new Date();
                            item.css("padding-left", 20);
                            item.append(
                              $(
                                "<div id='dxDataGrid_cumplimientoMaquina' />",
                              ).dxDataGrid({
                                dataSource:
                                  datasource_spSelectCumplimientoTareasNMaquina,
                                //Propiedades
                                filterRow: {
                                  visible: true,
                                  applyFilter: "auto",
                                  showAllText: getTrad("todos"),
                                },
                                height: "100%",
                                columnAutoWidth: true,
                                paging: {
                                  enabled: false,
                                },
                                loadPanel: {
                                  enabled: false,
                                },
                                export: {
                                  enabled: false,
                                  fileName:
                                    "PrenventivoSAT_" +
                                    date.getFullYear() +
                                    (date.getMonth() + 1) +
                                    date.getDate(),
                                  allowExportSelectedData: true,
                                },
                                columns: [
                                  {
                                    dataField: "denoTarea",
                                    caption: getTrad("denominacion"),
                                    width: "50%",
                                  },
                                  {
                                    dataField: "tiempoTarea",
                                    caption: getTrad("tiempoTarea"),
                                    width: 120,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "frecuencia",
                                    caption: getTrad("frecuencia"),
                                    width: 110,
                                    allowFiltering: true,
                                  },
                                  {
                                    dataField: "responsable",
                                    caption: getTrad("responsable"),
                                    width: 100,
                                    allowFiltering: true,
                                  },
                                  {
                                    dataField: "tareasTotales",
                                    caption: getTrad("tareasTotales"),
                                    alignment: "center",
                                    width: 100,
                                    allowFiltering: false,
                                    allowSorting: false,
                                  },
                                  {
                                    dataField: "tiempoTotal",
                                    caption: getTrad("hTotales"),
                                    width: 80,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "tareasRealizadas",
                                    caption: getTrad("tareasRealizadas"),
                                    alignment: "center",
                                    width: 120,
                                    allowFiltering: false,
                                    allowSorting: false,
                                  },
                                  {
                                    dataField: "tiempoRealizado",
                                    caption: getTrad("hRealizadas"),
                                    width: 95,
                                    allowFiltering: false,
                                    alignment: "center",
                                  },
                                  {
                                    dataField: "cumplimiento",
                                    caption: getTrad("cumplimiento"),
                                    alignment: "center",
                                    width: 120,
                                    calculateCellValue: function (e) {
                                      if (
                                        e.tareasTotales === 0 ||
                                        e.isActivo === false
                                      )
                                        return "-";
                                      return formatNumber(
                                        e.cumplimiento,
                                        2,
                                        "percent",
                                      );
                                    },
                                    allowFiltering: false,
                                  },
                                ],
                                onRowPrepared: function (e) {
                                  if (typeof e.data !== "undefined") {
                                    if (!e.data.isActivo)
                                      e.rowElement.addClass("disabledRow");
                                  }
                                },
                                summary: {
                                  recalculateWhileEditing: true,
                                  totalItems: [
                                    {
                                      column: "denoTarea",
                                      displayFormat:
                                        getTrad("total").toUpperCase(),
                                    },
                                    {
                                      showInColumn: "tiempoTotal",
                                      name: "tiempoTotal",
                                      displayFormat: "{0}",
                                      summaryType: "custom",
                                    },
                                    {
                                      showInColumn: "tiempoRealizado",
                                      name: "tiempoRealizado",
                                      displayFormat: "{0}",
                                      summaryType: "custom",
                                    },
                                    {
                                      showInColumn: "cumplimiento",
                                      name: "cumplimiento",
                                      summaryType: "custom",
                                      customizeText: function (data) {
                                        return formatNumber(
                                          data.value,
                                          2,
                                          "percent",
                                        );
                                      },
                                    },
                                  ],
                                  calculateCustomSummary: function (options) {
                                    if (options.name === "cumplimiento") {
                                      if (options.summaryProcess === "start") {
                                        options.totalValue = [0, 0];
                                      }
                                      if (
                                        options.summaryProcess === "calculate"
                                      ) {
                                        if (options.value.isActivo) {
                                          let { tiempoRealizado, tiempoTotal } =
                                            options.value;
                                          let sum_total = {
                                              minutos: parseInt(
                                                tiempoTotal.split(":")[1],
                                              ),
                                              horas: parseInt(
                                                tiempoTotal.split(":")[0],
                                              ),
                                            },
                                            sum_realizado = {
                                              minutos: parseInt(
                                                tiempoRealizado.split(":")[1],
                                              ),
                                              horas: parseInt(
                                                tiempoRealizado.split(":")[0],
                                              ),
                                            };

                                          if (isNegative(sum_total.horas))
                                            sum_total.minutos = -Math.abs(
                                              sum_total.minutos,
                                            );
                                          if (isNegative(sum_realizado.horas))
                                            sum_realizado.minutos = -Math.abs(
                                              sum_realizado.minutos,
                                            );

                                          options.totalValue = [
                                            sum_total.minutos +
                                              sum_total.horas * 60 +
                                              parseInt(options.totalValue[0]),
                                            sum_realizado.minutos +
                                              sum_realizado.horas * 60 +
                                              parseInt(options.totalValue[1]),
                                          ];
                                        }
                                      }
                                      if (
                                        options.summaryProcess === "finalize"
                                      ) {
                                        if (
                                          options.totalValue[0] === 0 ||
                                          options.totalValue[1] === 0
                                        )
                                          options.totalValue = 0;
                                        else
                                          options.totalValue =
                                            options.totalValue[1] /
                                            options.totalValue[0];
                                      }
                                    }
                                    if (
                                      options.name === "tiempoTotal" ||
                                      options.name === "tiempoRealizado"
                                    ) {
                                      var horas = 0;
                                      if (options.summaryProcess === "start") {
                                        options.totalValue = 0;
                                      }
                                      if (
                                        options.summaryProcess === "calculate"
                                      ) {
                                        if (
                                          options.value &&
                                          options.value.isActivo
                                        ) {
                                          let value =
                                            options.value[options.name];
                                          var minutos = 0;
                                          if (value instanceof Date) {
                                            horas = value.getHours();
                                            minutos = value.getMinutes();
                                          } else {
                                            horas = parseInt(
                                              value.split(":")[0],
                                            );
                                            minutos = parseInt(
                                              value.split(":")[1],
                                            );
                                          }

                                          if (isNegative(horas))
                                            minutos = -Math.abs(minutos);

                                          options.totalValue =
                                            minutos +
                                            horas * 60 +
                                            parseInt(options.totalValue);
                                        }
                                      }
                                      if (
                                        options.summaryProcess === "finalize"
                                      ) {
                                        horas = leadingZero(
                                          Math.abs(
                                            Math.trunc(options.totalValue / 60),
                                          ),
                                        );

                                        options.totalValue =
                                          (isNegative(options.totalValue)
                                            ? "-"
                                            : "") +
                                          horas +
                                          ":" +
                                          leadingZero(
                                            Math.abs(options.totalValue % 60),
                                          ) +
                                          " ";
                                      }
                                    }
                                  },
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                              }),
                            );
                          },
                        },
                      ],
                    }),
                  );
                },
              },
              {
                // FILTRO TIEMPO
                baseSize: 130,
                template: function (itemData, itemIndex, itemElement) {
                  itemElement.append(
                    create_rangeSelector(
                      datasource_filtroTiempo_cumplimiento,
                      false,
                      false,
                      function (e) {
                        COMPONENT.loadPanel_show(true);
                        fechaDesde = e.value[0];
                        fechaHasta = e.component.option("valueHasta");

                        var dxDataGrid_seleccionMaquina = $(
                          "#dxDataGrid_seleccionMaquina",
                        ).dxDataGrid("instance");
                        datasource_tblMaquina.load().done(function () {
                          dxDataGrid_seleccionMaquina.option(
                            "dataSource",
                            datasource_tblMaquina.items(),
                          );
                          $("#dxDataGrid_cumplimientoMaquina")
                            .dxDataGrid("instance")
                            .refresh();
                          COMPONENT.loadPanel_hide();
                        });
                      },
                      function () {
                        $("#dxRangeSelector_filtroTiempo")
                          .dxRangeSelector("instance")
                          .option({
                            "scale.minRange": { days: null, months: 1 },
                          });
                      },
                      function () {
                        $("#dxRangeSelector_filtroTiempo")
                          .dxRangeSelector("instance")
                          .option({
                            "scale.minRange": { days: null, months: null },
                          });
                      }, //Diario
                      [fechaDesde, fechaHasta], //Values
                    ),
                  );
                },
              },
            ],
          });

          filtroTiempo_Resize();
        });
      } else {
        COMPONENT.loadPanel_hide();
        dxMensajePregunta(getTrad("alert_sinDatos_lavanderia"), [
          [
            getTrad("aceptar"),
            function (e) {
              if (param_paginaSecundaria != null)
                $("#backButton").trigger("click");
              else history.push("/");
            },
            "danger",
            "dxButton_sinLavanderia_error",
          ],
        ]);
      }
    })
    .always(function () {
      deferred.resolve();
    });

  function isNegative(n) {
    //debido a que -0 y 0 son equivalentes.
    return ((n = +n) || 1 / n) < 0;
  }

  return deferred.promise();
}
