export const globalConstants = {
  GET_LAVANDERIAS_REQUEST: "GET_LAVANDERIAS_REQUEST",
  GET_LAVANDERIAS_SUCCESS: "GET_LAVANDERIAS_SUCCESS",
  GET_LAVANDERIAS_FAILURE: "GET_LAVANDERIAS_FAILURE",

  SET_LAVANDERIA_REQUEST: "SET_LAVANDERIA_REQUEST",
  SET_LAVANDERIA_SUCCESS: "SET_LAVANDERIA_SUCCESS",
  SET_LAVANDERIA_FAILURE: "SET_LAVANDERIA_FAILURE",

  SET_EMPRESA_REQUEST: "SET_EMPRESA_REQUEST",
  SET_EMPRESA_SUCCESS: "SET_EMPRESA_SUCCESS",
  SET_EMPRESA_FAILURE: "SET_EMPRESA_FAILURE",

  GET_ESTRUCTURA_MENU_REQUEST: "GET_ESTRUCTURA_MENU_REQUEST",
  GET_ESTRUCTURA_MENU_SUCCESS: "GET_ESTRUCTURA_MENU_SUCCESS",
  GET_ESTRUCTURA_MENU_FAILURE: "GET_ESTRUCTURA_MENU_FAILURE",

  SET_IDIOMA_SUCCESS: "SET_IDIOMA_SUCCESS",

  SET_RESOLUCION_SUCCESS: "SET_RESOLUCION_SUCCESS",
  SET_FORMULARIO_SUCCESS: "SET_FORMULARIO_SUCCESS",
  SET_APLICACION_SUCCESS: "SET_APLICACION_SUCCESS",

  SET_IDUSUARIO_ORIGINAL_SUCCESS: "SET_IDUSUARIO_ORIGINAL_SUCCESS",
};
