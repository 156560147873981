import React, { useContext, useEffect, useMemo, useState } from "react";
import $ from "jquery";

import DataGrid, {
    Column,
    ColumnChooser,
    FilterRow,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
    Summary,
    TotalItem,
    Pager,
} from "devextreme-react/data-grid";

import { getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { getClearAlbaran } from "../../model";
import {
    calcularValorNeto,
    calculateDisplayValue,
    custom_displayExpr,
    filterArticulo,
    onCellPrepared_Total,
    retrieveIvaValue,
    totalCustomSummary,
} from "pages/Administracion/shared/functions";
import {
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblAdmTipoElemento,
    datasource_tblIncoterm,
    datasource_tblMoneda,
    datasource_tblProveedor,
    datasource_tblViasPagoCobro,
    datasource_tblAdmAlbaran_Estado,
    sharedEditorOptions,
    formats,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import { MainScreenContext } from "../..";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import config_isCerrado from "pages/Administracion/shared/configs/config_isCerrado";

const DataGridAlbaranesCompra = ({
    datasource,
    setAlbaranCompraSel,
    openPopup,
    updateSummary,
    reloadData,
    idEmpresaPolarier,
    loadPanel_hide,
}) => {
    const ScreenCxt = useContext(MainScreenContext);

    const [proveedores, setProveedores] = useState([]);
    const [centrosCoste, setCentrosCoste] = useState([]);
    const [elementosPEP, setElementosPEP] = useState([]);

    useEffect(() => {
        Promise.all([
            datasource_tblProveedor.reload(),
            datasource_tblAdmCentroCoste.reload(),
            datasource_tblAdmElementoPEP.reload(),
        ]).then((data) => {
            setProveedores(datasource_tblProveedor.items());
            setCentrosCoste(datasource_tblAdmCentroCoste.items());
            setElementosPEP(datasource_tblAdmElementoPEP.items());
            datasource.reload().then(() => {
                loadPanel_hide();
            });
        });
    }, [idEmpresaPolarier]);

    const calculateCellValue = (e) => {
        if (e.tblArticuloNAdmAlbaranCompra.length > 0) {
            let total = 0;
            e.tblArticuloNAdmAlbaranCompra.forEach((articulo) => {
                const { cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 } = articulo;
                let iva = retrieveIvaValue(idIvaNPais, "compra");
                total += cantidad * precio * (1 + iva) * (1 - descuento);
            });
            const { descuento = 0, idAdmTipoDescuento } = e;
            return {
                valor: calcularValorNeto(total, descuento, idAdmTipoDescuento, null, "compra"),
                idMoneda: e.idMoneda,
            };
        }
    };

    const cellRender = (e) => {
        return calculateDisplayValue(isNaN(e.value.valor) ? 0 : e.value.valor, e.value.idMoneda);
    };

    const onToolbarPreparing = useMemo(() => {
        return ({ toolbarOptions, component }) => {
            toolbarOptions.items.unshift(
                {
                    location: "before",
                    visible: !ScreenCxt.mainScreen,
                    template: () =>
                        $('<div id="articulos_title" class="font-size" style="margin-top:10px" >').text(
                            getTrad("albaranes")
                        ),
                },
                {
                    location: "before",
                    widget: "dxButton",
                    visible: ScreenCxt.mainScreen,
                    options: {
                        icon: "plus",
                        text: getTrad("nuevoAlbaran"),
                        onClick: () => onNewAlbaran(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("exportar"),
                        icon: "exportxlsx",
                        onClick: () => component.exportToExcel(),
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    visible: ScreenCxt.mainScreen,
                    options: {
                        icon: "refresh",
                        text: getTrad("actualizar"),
                        onClick: () => {
                            reloadData();
                        },
                    },
                },
                {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    visible: ScreenCxt.mainScreen,
                    locateInMenu: "auto",
                    options: {
                        text: getTrad("limpiarFiltro"),
                        icon: " icon_EliminarFiltros",
                        onClick: () => {
                            component.clearFilter();
                        },
                    },
                }
            );
        };
    }, []);

    const onNewAlbaran = () => {
        const albaran = getClearAlbaran();
        setAlbaranCompraSel({ ...albaran });
        openPopup();
    };

    const onRowPrepared_tblAlbaranes = useMemo(() => {
        return (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };
    }, []);

    const onRowClick_tblAlbaranes = useMemo(() => {
        return (e) => {
            if (e.data) {
                setAlbaranCompraSel({ ...e.data, articulosDraft: [...e.data.tblArticuloNAdmAlbaranCompra] });
                openPopup();
            }
        };
    }, []);

    const displayTotal = (data) => {
        // ScreenCxt.updateSummary(data);
        if (updateSummary) {
            updateSummary(data);
        }
        return calculateDisplayValue(data, ScreenCxt.idMoneda);
    };

    const calculateDisplayValue_descuento = (data) => {
        if (data.idAdmTipoDescuento === 1) {
            return formatNumber(data.descuento, getFormatDescuento(data.idAdmTipoDescuento));
        }
        if (data.idAdmTipoDescuento === 2) {
            return calculateDisplayValue(data.descuento == null ? 0 : data.descuento, data.idMoneda);
        }
    };

    return (
        <DataGrid
            dataSource={ScreenCxt.mainScreen ? datasource : ScreenCxt.datasource}
            height={"100%"}
            width={"100%"}
            showRowLines
            columnsAutoWidth
            repaintChangesOnly
            rowAlternationEnabled
            showColumnLines={false}
            remoteOperations={false}
            onToolbarPreparing={onToolbarPreparing}
            hoverStateEnabled={true}
            onRowPrepared={onRowPrepared_tblAlbaranes}
            onCellPrepared={onCellPrepared_Total}
            onRowClick={onRowClick_tblAlbaranes}
        >
            <Sorting mode={"multiple"} />
            <ColumnChooser enabled />
            <SearchPanel visible width={240} />
            <FilterRow visible={ScreenCxt.mainScreen} applyFilter="auto" />

            <Pager visible={true} showInfo={true} showPageSizeSelector={true} />

            <Column
                dataField={"codigo"}
                caption={getTrad("codigo")}
                dataType={"string"}
                alignment={"center"}
                width={100}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idTipoAlbaran"}
                caption={getTrad("tipoAlbaran")}
                dataType={"number"}
                visible={false}
                showInColumnChooser={true}
                cellComponent={(e) => TipoElemento(e.data.data.idTipoAlbaran, "")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmTipoElemento.store()}
                    valueExpr="idAdmTipoElemento"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"fechaCreacion"}
                caption={getTrad("fecha")}
                dataType={"date"}
                alignment={"center"}
                width={150}
                sortOrder={"desc"}
                format={"dd/MM/yyyy"}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmProveedor"}
                caption={getTrad("proveedor")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={proveedores} valueExpr="idAdmProveedor" displayExpr="nombreFiscal" />
            </Column>
            <Column
                dataField={"idAdmAlbaran_Estado"}
                caption={getTrad("estado")}
                dataType={"number"}
                alignment={"left"}
                minWidth={150}
                cellComponent={(e) => ChipEstado(e.data.data.idAdmAlbaran_Estado, "", "albaran")}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblAdmAlbaran_Estado.store()}
                    valueExpr="idAdmAlbaran_Estado"
                    // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idMoneda"}
                caption={getTrad("moneda")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup dataSource={datasource_tblMoneda.store()} valueExpr="idMoneda" displayExpr="codigo" />
            </Column>
            <Column
                dataField={"tasaCambio"}
                caption={getTrad("tasaCambio")}
                dataType={"number"}
                format={sharedEditorOptions.tasaCambio.format}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPedidoProveedor"}
                caption={getTrad("numPedidoProveedor")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numAlbaranProveedor"}
                caption={getTrad("numAlbaranProveedor")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"numPresupuesto"}
                caption={getTrad("numPresupuesto")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"idAdmFormaPago"}
                caption={getTrad("formaPago")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblViasPagoCobro.store()}
                    valueExpr="idAdmFormaPago"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"idAdmCentroCoste"}
                caption={getTrad("centroCoste")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={centrosCoste}
                    valueExpr="idAdmCentroCoste"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idAdmElementoPEP"}
                caption={getTrad("elementoPep")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={elementosPEP}
                    valueExpr="idAdmElementoPEP"
                    displayExpr={custom_displayExpr}
                />
            </Column>
            <Column
                dataField={"idIncoterm"}
                caption={getTrad("incoterms")}
                dataType={"number"}
                visible={false}
                allowReordering
                allowResizing
            >
                <DataGrid_Lookup
                    dataSource={datasource_tblIncoterm.store()}
                    valueExpr="idIncoterm"
                    displayExpr="denominacion"
                />
            </Column>
            <Column
                dataField={"observaciones"}
                caption={getTrad("observaciones")}
                dataType={"string"}
                visible={false}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"descuento"}
                caption={getTrad("descuento")}
                dataType={"number"}
                visible={false}
                alignment={"center"}
                calculateDisplayValue={calculateDisplayValue_descuento}
                allowReordering
                allowResizing
            />
            <Column
                dataField={"total"}
                caption={getTrad("total")}
                dataType={"number"}
                visible={true}
                alignment={"center"}
                width={200}
                calculateCellValue={calculateCellValue}
                cellRender={cellRender}
            />
            <Column {...config_isCerrado} />
            <Column
                dataField={"tblArticuloNAdmAlbaranCompra"}
                // caption={getTrad("articulos")}
                dataType={"object"}
                visible={false}
                showInColumnChooser={false}
                allowFiltering={true}
                calculateFilterExpression={filterArticulo}
            />
            {/* <Column
                caption={" "}
                width={30}
                alignment={"center"}
                cssClass={"p-0"}
                visible={ScreenCxt.mainScreen}
                cellComponent={removeCellComponent}
            /> */}
            <Summary calculateCustomSummary={totalCustomSummary}>
                <TotalItem column={"total"} summaryType={"custom"} showInColumn={true} alignByColumn={true} />
            </Summary>
        </DataGrid>
    );
};

export default DataGridAlbaranesCompra;
