import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Cookies } from "react-cookie-banner";

//Actions
import { userActions } from "../actions";

import { leadingZero, fechaUltimaPeticion } from "../helpers";

import { Button } from "devextreme-react";
import LottieIcon from "./LottieIcon";

const minAviso = 5;
const minRefresco = 5;

class CheckToken extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            diferencia: null,
            ocultar: false,
        };

        this.refreshToken = this.refreshToken.bind(this);
        this.ocultar = this.ocultar.bind(this);
    }

    refreshToken() {
        this.props.refreshToken(true);
    }

    ocultar() {
        this.setState({ ocultar: true });
    }

    render() {
        let { ocultar } = this.state;
        let { refresh_state, loggedIn } = this.props;
        const cookies = new Cookies();
        const user = cookies.get("user");

        var mostrarAviso = false;
        var ultimoAviso = false;
        let min_dif;
        let sec_dif;

        if (loggedIn && user && !ocultar) {
            let fechaCaducidad = new Date(user.token_expireDate);
            let diferencia = fechaCaducidad - new Date();
            mostrarAviso = diferencia / 1000 / 60 <= minAviso;

            min_dif = leadingZero(Math.floor((diferencia / 1000 / 60) % 60));
            sec_dif = leadingZero(Math.floor((diferencia / 1000) % 60));
            ultimoAviso = new Date(user.token_expireDate) >= new Date(user.refreshToken_expireDate);
        }

        var lootie = refresh_state == "loading" ? "loading_circular" : refresh_state == "done" ? "check" : "error";

        return (
            <Fragment>
                {loggedIn ? (
                    refresh_state || mostrarAviso ? (
                        <div className="modal modal-dialog-centered container_spanCentrado">
                            {refresh_state ? (
                                <div className={"container_spanCentrado"}>
                                    <LottieIcon icon={lootie} isLoop={false} height={245} width={245} />
                                </div>
                            ) : (
                                <div className="w-100 modal-dialog">
                                    <div className={"modal-content p-4 " + (min_dif >= 1 ? "caution" : "warning")}>
                                        <div className="modal-title text-center">
                                            <h4 className="mt-2">
                                                <div className="h2">
                                                    {ultimoAviso
                                                        ? "La sesión se va a cerrar"
                                                        : "La sesión caducará pronto"}
                                                </div>
                                            </h4>
                                            <h6 className="mt-3">
                                                <span>
                                                    {ultimoAviso
                                                        ? "Debes iniciar sesión de nuevo, se cerrará automaticamente en:"
                                                        : "Por motivos de seguridad su sesión va a expirar en:"}
                                                </span>
                                            </h6>
                                        </div>
                                        <div className="h1 p-4 container_spanCentrado text-primary">
                                            {(min_dif > 0 ? min_dif + " : " : "") + (sec_dif > 0 ? sec_dif : "")}
                                        </div>
                                        <div className="clearfix">
                                            <div className="float-right ">
                                                {ultimoAviso ? (
                                                    <Button
                                                        width={150}
                                                        text="Posponer cierre"
                                                        type="danger"
                                                        stylingMode="contained"
                                                        onClick={this.ocultar}
                                                    />
                                                ) : (
                                                    <Button
                                                        width={150}
                                                        text="Continuar sesión"
                                                        type="default"
                                                        stylingMode="contained"
                                                        onClick={this.refreshToken}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ""
                    )
                ) : (
                    ""
                )}
            </Fragment>
        );
    }

    componentDidMount() {
        let { loggedIn } = this.props;
        const cookies = new Cookies();
        const user = cookies.get("user");

        if (user && loggedIn) {
            let fechaCaducidad = new Date(user.token_expireDate);
            this.setState({ diferencia: fechaCaducidad - new Date() });
        }
    }

    componentWillUnmount() {
        this.setState({ ocultar: false });
    }

    componentDidUpdate(prevProps, prevState) {
        let { diferencia } = this.state;
        let { loggedIn, user } = this.props;

        const cookies = new Cookies();
        const user_cookies = cookies.get("user");

        if (user_cookies && loggedIn) {
            let token_expireDate = new Date(user_cookies.token_expireDate);
            let refreshToken_expireDate = new Date(user_cookies.refreshToken_expireDate);
            let new_diferencia = token_expireDate - new Date();

            if (!prevProps.loggedIn || diferencia !== prevState.diferencia) {
                if (new_diferencia < 0 && user.enableFullScreen == false) {
                    //LOGOUT
                    this.setState({ ocultar: false });
                    this.props.logout_SesionCaducada();
                }

                if (token_expireDate < refreshToken_expireDate) {
                    //REFRESCAR TOKEN CADA X MIN SI HAY USO
                    let diferencia_refresco = new Date() - new Date(user_cookies.token_Date);
                    if (
                        fechaUltimaPeticion > new Date(user_cookies.token_Date) &&
                        diferencia_refresco / 1000 / 60 >= minRefresco
                    ) {
                        this.props.refreshToken(false);
                    }
                }
                setTimeout(() => {
                    this.setState({ diferencia: new_diferencia });
                }, 1000);
            }
        }
    }
}

const mapStateToProps = (state) => ({
    loggedIn: state.Authentication.loggedIn,
    refresh_state: state.Authentication.refresh_state,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout_SesionCaducada: () => dispatch(userActions.logout_SesionCaducada()),
    refreshToken: (mostrarMensaje) => dispatch(userActions.refreshToken(mostrarMensaje)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckToken);
