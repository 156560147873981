import React from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../../constants";

import {
    getNombreFormulario,
    getTrad,
    authHeader,
    errorHandler,
    dxMensajePregunta,
    formatNumber,
    formatDate_parameter,
    formatDate,
    patchRequestHandler,
} from "../../../../../helpers";

import $ from "jquery";

import { Button } from "devextreme-react";

import notify from "devextreme/ui/notify";

import ODataContext from "devextreme/data/odata/context";
import ODataStore from "devextreme/data/odata/store";

import { Popover } from "devextreme-react/popover";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Scrolling,
    ColumnFixing,
    Export,
    ColumnChooser,
    Selection,
    Paging,
    Lookup,
    Sorting,
    Summary,
    TotalItem,
    Pager,
} from "devextreme-react/data-grid";
import { Form, Item as ItemForm } from "devextreme-react/form";

import { editCellComponent } from "../../../../../components/DataGrid/Cells";
import CalendarioPersonal from "components/CalendarioPersonal";
import EditcellComponent_idEstadoNomina from "./components/EditcellComponent_idEstadoNomina";

import PersonalGeneral from "../../../PersonalGeneral";
import PopupComparativaConceptos from "./components/PopupComparativaConceptos";

import "./Css.scss";
import idsCargo from "constants/enums/GestionInterna/tblCargo";
import idsCategoriaInterna from "constants/enums/RRHH/tblCategoriaInterna";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";
import idsUsuario from "constants/enums/GestionInterna/tblUsuario";
import idsTipoNomina from "constants/enums/RRHH/tblTipoNomina";
import DataSource from "devextreme/data/data_source";

export const currency_editorOptions = {
    format: { style: "currency", maximumFractionDigits: 2, currency: "EUR" },
    step: 0,
    min: 0,
    max: 99999.99,
};

const datasource_tblNomina = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + 'tblNomina',
        key: 'idNomina',
        errorHandler: error => errorHandler(error, null),
        beforeSend: request => request.headers = { ...authHeader() },
        version: 4
    }),
});

class DataGridPersonas extends React.Component {
    constructor(props) {
        super(props);

        const { vista_GestionPluses, filtroFiniquitos } = this.props;

        let filterValue = vista_GestionPluses
            ? ["gestionaPluses", "=", vista_GestionPluses]
            : [];

        filterValue = filtroFiniquitos
            ? filterValue
            : [["idTipoNomina", "=", idsTipoNomina.pagaMensual], "and", filterValue];

        this.state = {
            show_Popup: false,
            popup_Title: "",
            formData_concepto: null,
            obs_required: false,
            popover_data: null,
            nominaSel: null,
            comparativaConceptos_popupTitle: "",
            idPersonaSel: null,
            comparacionNominaGestoria: [],
            isLoading_comparacionNominaGestoria: false,
            filterValue,
        };

        //REFS
        this.DataGrid_Personas_REF = React.createRef();
        this.Form_Concepto_REF = React.createRef();
        this.PersonalGeneral_REF = React.createRef();

        //DATAGRID
        this.onCellClick = this.onCellClick.bind(this);
        this.onToolbarPreparing = this.onToolbarPreparing.bind(this);

        //POPUPS ACTIVIDAD y PRODUCTIVIDAD
        this.render_dxButton_guardar = this.render_dxButton_guardar.bind(this);
        this.render_dxButton_cancelar = this.render_dxButton_cancelar.bind(this);
    }

    get DataGrid_Personas() {
        return this.DataGrid_Personas_REF.current.instance;
    }

    get Form_Concepto() {
        return this.Form_Concepto_REF.current.instance;
    }

    context_GestionNominas = new ODataContext({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/GestionNominas/",
        entities: {
            AddConceptoNNomina: {},
            EnviarNominaGestoria: {},
            CompararNominaGestoria: {},
        },
        beforeSend: (request) => {
            request.headers = { ...authHeader() };

            if (request.url.includes("AddConceptoNNomina")) {
                this.context_GestionNominas_AddConceptoNNomina_beforeSend(request);
            } else if (request.url.includes("EnviarNominaGestoria")) {
                this.context_GestionNominas_EnviarNominaGestoria_beforeSend(request);
            }else if (request.url.includes("CompararNominaGestoria")) {
                this.context_GestionNominas_CompararNominaGestoria_beforeSend(request);
            }
        },
    });

    context_GestionNominas_AddConceptoNNomina_beforeSend = (request) => {
        let { formData_concepto } = this.state;

        let body = {
            idPersona: formData_concepto.idPersona,
            fechaInicioNomina: formData_concepto.fechaInicioNomina,
            fechaFinNomina: formData_concepto.fechaFinNomina,
            field: formData_concepto.field,
            cantidad: null,
            precioUnitario: null,
            importe: formData_concepto[formData_concepto.field],
            observaciones: formData_concepto[Object.keys(formData_concepto).filter((key) => key.includes("obs"))],
        };

        request.payload = body;
    };

    context_GestionNominas_EnviarNominaGestoria_beforeSend = (request) => {
        const { fechaIni, fechaFin } = this.props;

        request.params.fechaDesde = formatDate_parameter(fechaIni);
        request.params.fechaHasta = formatDate_parameter(fechaFin);
    };

    context_GestionNominas_CompararNominaGestoria_beforeSend = (request) => {
        const { fechaIni, fechaFin } = this.props;
        const { nominaSel } = this.state;

        request.params.idPersona = nominaSel.idPersona;
        request.params.fechaDesde = formatDate_parameter(new Date(fechaIni));
        request.params.fechaHasta = formatDate_parameter(new Date(fechaFin));
    };

    render() {
        let {
            user,
            vista_GestionPluses,
            datasource_gestionNominas_personas,
            isVisibleSalarioBruto,
            fechaIni,
            fechaFin,
        } = this.props;

        let {
            show_Popup,
            popup_Title,
            formData_concepto,
            popover_data,
            idPersonaSel,
            obs_required,
            comparacionNominaGestoria,
            nominaSel,
            filterValue,
        } = this.state;

        const comparativaConceptos_title = `${nominaSel?.nombre} ${nominaSel?.apellidos}`;

        return (
            <>
                <DataGrid
                    ref={this.DataGrid_Personas_REF}
                    dataSource={datasource_gestionNominas_personas}
                    remoteOperations={false}
                    height="100%"
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnReordering={true}
                    hoverStateEnabled={true}
                    repaintChangesOnly={true}
                    columnAutoWidth={true}
                    onCellClick={this.onCellClick}
                    onCellHoverChanged={this.onCellHoverChanged}
                    onEditingStart={this.onEditingStart}
                    onToolbarPreparing={this.onToolbarPreparing}
                    onRowPrepared={this.onRowPrepared}
                    onEditorPreparing={this.onEditorPreparing}
                    filterValue={filterValue}
                >
                    <Sorting mode={"multiple"} />
                    <SearchPanel visible={true} width={240} />
                    <Scrolling mode="virtual" />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <ColumnFixing enabled={true} />
                    <ColumnChooser enabled={user.enableDatosRRHH} height={550} />
                    <Export enabled={true} fileName={getNombreFormulario(this)} />
                    <Column caption="idNomina" visible={false} showInColumnChooser={false} />
                    <Column caption="fechaInicioNomina" visible={false} showInColumnChooser={false} />
                    <Column caption="fechaFinNomina" visible={false} showInColumnChooser={false} />
                    <Column dataField="gestionaPluses" dataType="boolean" visible={false} showInColumnChooser={false} />
                    <Column
                        caption=" "
                        name="errorCell"
                        width={30}
                        alignment="center"
                        cssClass="pt-0 pb-4"
                        calculateCellValue={this.calculateCellValue_errorCell}
                        cellTemplate={this.cellTemplate_errorCell}
                        fixed={true}
                        allowFiltering={false}
                        headerFilter={false}
                        allowExporting={false}
                    />
                    <Column
                        caption=" "
                        name={"editCell"}
                        width={30}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={editCellComponent}
                        fixed={true}
                        allowExporting={false}
                    />
                    <Column
                        caption=" "
                        dataField="idEstadoNomina"
                        width={35}
                        alignment="center"
                        cssClass="p-0"
                        editCellComponent={EditcellComponent_idEstadoNomina}
                        showEditorAlways={true}
                        fixed={true}
                        allowFiltering={false}
                        headerFilter={false}
                        allowExporting={false}
                        visible={
                            !vista_GestionPluses &&
                            ([idsCategoriaInterna.administrativoRRHH].includes(user.idCategoriaInterna) ||
                                [idsCargo.desarrollador].includes(user.idCargo) ||
                                [idsUsuario.maribelMoranta].includes(user.idUsuario))
                        }
                        showInColumnChooser={false}
                    />
                    <Column
                        name="calendario"
                        caption=" "
                        width={35}
                        alignment="center"
                        cssClass="p-0 pt-2"
                        fixed={true}
                        allowFiltering={false}
                        headerFilter={false}
                        allowExporting={false}
                        cellTemplate={this.cellTemplate_calendario}
                    />
                    <Column
                        dataField="nombre"
                        dataType="string"
                        caption={getTrad("nombre")}
                        fixed={true}
                        minWidth={200}
                    />
                    <Column
                        dataField="apellidos"
                        dataType="string"
                        caption={getTrad("apellidos")}
                        fixed={true}
                        minWidth={250}
                    />
                    <Column dataField="centroLav" caption={getTrad("centro")} alignment={"left"} minWidth={240} />
                    <Column
                        dataField={"idTipoNomina"}
                        visible={false}
                        showInColumnChooser={false}
                    />
                    <Column
                        dataField={"tipoNomina"}
                        caption={getTrad("tipoPaga")}
                        minWidth={180}
                    />
                    <Column
                        dataField="tipoTrabajo"
                        caption={getTrad("tipoTrabajo")}
                        alignment={"left"}
                        minWidth={240}
                    />
                    <Column
                        dataField="tipoContrato"
                        dataType="string"
                        caption={getTrad("tipoContrato")}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="categoriaConvenio"
                        dataType="string"
                        caption={getTrad("categoriaConvenio")}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="categoriaInterna"
                        dataType="string"
                        caption={getTrad("categoriaInterna")}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="salarioBase"
                        dataType="number"
                        caption={getTrad("salarioBase")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusAntiguedad"
                        dataType="number"
                        caption={getTrad("plusAntiguedad")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusAsistencia"
                        dataType="number"
                        caption={getTrad("plusAsistencia")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="segAccidenteConvenio"
                        caption={getTrad("segAccidenteConvenio")}
                        dataType="number"
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusFestivoTrab"
                        dataType="number"
                        caption={getTrad("plusFestivoTrab")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="incentivo"
                        dataType="number"
                        caption={getTrad("incentivo")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="numPagas"
                        dataType="number"
                        caption={getTrad("numPagas")}
                        alignment="center"
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField={"pagaExtra1"}
                        dataType={"number"}
                        caption={getTrad("pagaExtra1")}
                        alignment={"center"}
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField={"pagaExtra2"}
                        dataType={"number"}
                        caption={getTrad("pagaExtra2")}
                        alignment={"center"}
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusNocturnidad"
                        dataType="number"
                        caption={getTrad("nocturnidad")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusPeligrosidad"
                        dataType="number"
                        caption={getTrad("plusPeligrosidad")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusResponsabilidad"
                        dataType="number"
                        caption={getTrad("plusResponsabilidad")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="plusActividad"
                        dataType="number"
                        caption={getTrad("plusActividad")}
                        alignment="center"
                        cssClass="pointer dx-Cell_Editable"
                        cellTemplate={this.cellTemplate_currency}
                    />
                    <Column
                        dataField="plusProductividad"
                        dataType="number"
                        caption={getTrad("plusProductividad")}
                        alignment="center"
                        cssClass="pointer dx-Cell_Editable"
                        cellTemplate={this.cellTemplate_currency}
                    />
                    <Column
                        dataField="plusAbsorbible"
                        dataType="number"
                        caption={getTrad("plusAbsorbible")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="anticipos"
                        dataType="number"
                        caption={getTrad("anticipos")}
                        alignment="center"
                        cssClass="pointer dx-Cell_Editable"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="horasExtra"
                        dataType="number"
                        caption={getTrad("horasExtra")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_number}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="impHorasExtra"
                        dataType="number"
                        caption={getTrad("impHorasExtra")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="acuerdoNC"
                        dataType="number"
                        caption={getTrad("acuerdoNC")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="salarioEspecie"
                        dataType="number"
                        caption={getTrad("salarioEnEspecie")}
                        alignment="center"
                        cellTemplate={this.cellTemplate_currency}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField="isEmbargo"
                        caption={getTrad("embargo")}
                        dataType="boolean"
                        alignment="center"
                        cellTemplate={this.cellTemplate_isEmbargo}
                        allowFiltering={false}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField={"total"}
                        width={125}
                        dataType="number"
                        caption={getTrad("total")}
                        alignment="center"
                        fixed={true}
                        fixedPosition="right"
                        allowSorting={true}
                        format={currency_editorOptions.format}
                        visible={!vista_GestionPluses}
                    />
                    <Column
                        dataField={"salarioBruto"}
                        caption={getTrad("salarioBruto")}
                        width={125}
                        dataType={"number"}
                        alignment={"center"}
                        fixedPosition={"right"}
                        fixed
                        allowFiltering={false}
                        headerFilter={false}
                        showInColumnChooser={false}
                        format={currency_editorOptions.format}
                        visible={!vista_GestionPluses && isVisibleSalarioBruto}
                    />
                    <Column
                        name={"diferencia"}
                        caption={getTrad("diferencia")}
                        width={125}
                        dataType={"number"}
                        alignment={"center"}
                        fixedPosition={"right"}
                        fixed
                        allowSorting
                        allowFiltering={false}
                        headerFilter={false}
                        showInColumnChooser={false}
                        visible={!vista_GestionPluses && isVisibleSalarioBruto}
                        calculateCellValue={this.calculateCellValue_diferencia}
                        calculateSortValue={this.calculateSortValue_diferencia}
                        cellRender={this.cellRender_diferencia}
                    />
                    <Column
                        dataField={"isRetenida"}
                        caption={""}
                        alignment={"center"}
                        fixedPosition={"right"}
                        cssClass={"p-0"}
                        width={35}
                        fixed
                        allowSorting
                        allowFiltering={false}
                        headerFilter={false}
                        allowExporting={false}
                        showInColumnChooser={false}
                        visible={!vista_GestionPluses && isVisibleSalarioBruto}
                        cellRender={this.cellRender_isRetenida}
                    />
                    <Column
                        name={"compararNomina"}
                        alignment={"center"}
                        fixedPosition={"right"}
                        cssClass={"p-0"}
                        width={35}
                        fixed
                        allowSorting
                        allowFiltering={false}
                        headerFilter={false}
                        allowExporting={false}
                        showInColumnChooser={false}
                        visible={!vista_GestionPluses}
                        calculateCellValue={this.calculateCellValue_compararNomina}
                        cellRender={this.cellRender_compararNomina}
                    />
                    <Column
                        dataField="añosAntiguedad"
                        dataType="number"
                        caption={getTrad("añosAntiguedad")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="horasNocturnas"
                        dataType="number"
                        caption={getTrad("horasNocturnas")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="numRetrasos"
                        dataType="number"
                        caption={getTrad("numRetrasos")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="festivosTrab"
                        dataType="number"
                        caption={getTrad("festivosTrab")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="baja"
                        dataType="number"
                        caption={getTrad("baja")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="bajaLaboral"
                        dataType="number"
                        caption={getTrad("bajaLaboral")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="ceseTemporal"
                        dataType="number"
                        caption={getTrad("erte")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="numAbsentismo"
                        dataType="number"
                        caption={getTrad("faltas")}
                        visible={false}
                        alignment="center"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="obsPlusActividad"
                        caption={getTrad("obsPlusActividad")}
                        visible={false}
                        cssClass="pointer dx-Cell_Editable"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="obsPlusProductividad"
                        caption={getTrad("obsPlusProductividad")}
                        visible={false}
                        cssClass="pointer dx-Cell_Editable"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="obsHorasExtra"
                        caption={getTrad("obsHorasExtra")}
                        visible={false}
                        cssClass="pointer dx-Cell_Editable"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="obsPlusAbsorbible"
                        caption={getTrad("obsPlusAbsorbible")}
                        visible={false}
                        cssClass="pointer dx-Cell_Editable"
                        showInColumnChooser={true}
                    />
                    <Column
                        dataField="obsAnticipos"
                        caption={getTrad("obsAnticipos")}
                        visible={false}
                        cssClass="pointer dx-Cell_Editable"
                        showInColumnChooser={true}
                    />
                </DataGrid>
                <Popover
                    target={popover_data ? popover_data.id : undefined}
                    position="top"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    maxWidth={600}
                    width={"auto"}
                >
                    <div>
                        {popover_data?.messages
                            ? popover_data.messages.map((row, index) => {
                                const lastRow = popover_data.messages.length - 1 == index;
                                return (
                                    <div
                                        key={"popover_" + index}
                                        className={"container_spanCentrado " + (!lastRow ? "mb-2" : "")}
                                    >
                                        {row.errorText && (
                                            <div className={"flex-1 font-size-md color-white bg-danger p-3 rounded"}>
                                                {row.errorText}
                                            </div>
                                        )}
                                        {row.text && (
                                            <div className="flex-1 color-secondary font-weight-bold">{row.text}</div>
                                        )}
                                        {row.value != null && row.value != undefined && (
                                            <div
                                                className={
                                                    "p-1 pl-2 pr-2 ml-2 font-size-lg color-white rounded bg-primary"
                                                }
                                            >
                                                {row.value}
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                            : null}
                    </div>
                </Popover>
                <Popup
                    width={650}
                    height={320}
                    title={getTrad(popup_Title)}
                    visible={show_Popup}
                    onShown={this.dxPopup_onShown}
                    onHiding={this.dxPopup_onHiding}
                >
                    <Form
                        ref={this.Form_Concepto_REF}
                        formData={formData_concepto}
                        labelLocation="top"
                        height="100%"
                        scrollingEnabled={true}
                        colCount={1}
                        onFieldDataChanged={this.onFieldDataChanged}
                        validationGroup="validationGroup"
                    >
                        {/* ACTIVIDAD */}
                        <ItemForm
                            dataField="plusActividad"
                            editorType="dxNumberBox"
                            editorOptions={currency_editorOptions}
                            visible={popup_Title == "plusActividad"}
                            label={this.formLabel_plusActividad}
                        ></ItemForm>
                        <ItemForm
                            dataField="obsPlusActividad"
                            visible={popup_Title == "plusActividad"}
                            label={this.formLabel_obsPlusActividad}
                            editorType="dxTextArea"
                            isRequired={obs_required}
                        ></ItemForm>

                        {/* PRODUCTIVIDAD */}
                        <ItemForm
                            dataField="plusProductividad"
                            editorType="dxNumberBox"
                            editorOptions={currency_editorOptions}
                            visible={popup_Title == "plusProductividad"}
                            label={this.formLabel_plusProductividad}
                        ></ItemForm>
                        <ItemForm
                            dataField="obsPlusProductividad"
                            visible={popup_Title == "plusProductividad"}
                            label={this.formLabel_obsPlusProductividad}
                            editorType="dxTextArea"
                            isRequired={obs_required}
                        ></ItemForm>
                        {/* ANTICIPOS */}
                        <ItemForm
                            dataField="anticipos"
                            editorType="dxNumberBox"
                            editorOptions={currency_editorOptions}
                            visible={popup_Title == "anticipos"}
                            label={this.formLabel_anticipos}
                        ></ItemForm>
                        <ItemForm
                            dataField="obsAnticipos"
                            visible={popup_Title == "anticipos"}
                            label={this.formLabel_obsAnticipos}
                            editorType="dxTextArea"
                            isRequired={obs_required}
                        ></ItemForm>
                    </Form>
                    <ToolbarItem_popup render={this.render_dxButton_guardar} location="after" toolbar="bottom" />

                    <ToolbarItem_popup render={this.render_dxButton_cancelar} location="after" toolbar="bottom" />
                </Popup>
                <PopupComparativaConceptos
                    title={comparativaConceptos_title}
                    visible={comparacionNominaGestoria.length > 0}
                    comparacionNominaGestoria={comparacionNominaGestoria}
                    nominaCompara={nominaSel}
                    fechaIni={fechaIni}
                    fechaFin={fechaFin}
                    onChange_comparacionNominaGestoria={this.onChange_comparacionNominaGestoria}
                    onHiding={this.comparativaConceptos_closePopup}
                />
                <div style={{ overflow: "hidden", height: 0 }}>
                    <PersonalGeneral ref={(ref) => (this.PersonalGeneral_REF = ref)} />
                </div>
                <Popup
                    width={"90%"}
                    title={popup_Title}
                    visible={idPersonaSel != null}
                    onHiding={this.dxPopup_onHiding}
                >
                    <div className="container_spanCentrado">
                        {idPersonaSel != null && <CalendarioPersonal idPersona={idPersonaSel} />}
                    </div>
                </Popup>
            </>
        );
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: () => {
                        this.dxDataGrid_limpiarFiltros_onClick(e);
                    },
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onClick: () => {
                        this.dxDataGrid_actualizar_onClick(e);
                    },
                },
            }
        );
    }

    onRowPrepared = e => {
        if (e.rowType === "data" && e.data.tipoContrato === null) {
            e.rowElement.addClass("warningRow_onlyText");
        }
    };

    onEditorPreparing = (e) => {
        if (e.parentType === "filterRow") {
            e.editorOptions.showClearButton = true;
        }
    };

    onChange_comparacionNominaGestoria = (comparacionNominaGestoria) => {
        this.props.datasource_gestionNominas_personas.reload().done(() => {
            this.DataGrid_Personas.refresh(false);
        });

        this.setState({ comparacionNominaGestoria });
    };

    comparativaConceptos_closePopup = () => this.setState({ comparacionNominaGestoria: [], nominaSel: null });

    dxDataGrid_limpiarFiltros_onClick(e) {
        e.component.clearFilter();
        notify({
            message: getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxDataGrid_actualizar_onClick(e) {
        e.component.refresh(true);
    }

    calculateCellValue_errorCell = (rowData) => {
        return (
            Object.keys(rowData).filter((field) => field.startsWith("errorText_") && rowData[field] != null).length >
            0 || rowData.isEmbargo === true
        );
    };

    calculateCellValue_diferencia = (rowData) => {
        return rowData.salarioBruto !== null ? rowData.total - rowData.salarioBruto : "";
    };

    calculateSortValue_diferencia = rowData => {
        const diferencia = rowData.total - (rowData?.salarioBruto ?? 0)
        return diferencia === 0 && rowData.hayConflictos ? -1 : diferencia;
    };

    cellRender_diferencia = ({ value, data }) =>
        value !== "" ? (
            <span className={value !== 0 || data.hayConflictos ? "text-danger font-weight-bold" : ""}>
                {formatNumber(value, 2, "currency", data.codMoneda)}
            </span>
        ) : null;

    cellRender_isRetenida = ({ value, data }) => {
        if (
          value === null ||
          data.idEstadoHistoricoAsientoNomina !== null ||
          data.idEstadoNomina < idsEstadoNomina.importadoConConflictos
        ) {
            return null;
        }

        const onMouseDown = () => {
            datasource_tblNomina
            .store()
            .update(data.idNomina, patchRequestHandler({ isRetenida: !value }))
            .done(() => {
                this.DataGrid_Personas.refresh();
                notify({
                    message: getTrad('aviso_C_RegistroActualizado'),
                    type: 'success',
                    displayTime: 1500,
                    closeOnClick: true
                });
            })
            .catch(() => 
                notify({
                    message: getTrad('aviso_RegistroNoActualizado'),
                    type: 'error',
                    displayTime: 1500,
                    closeOnClick: true
                })
            );
        }

        return <div className={"container_spanCentrado pointer"} onMouseDown={onMouseDown}>
            {value ? (
                    <div
                        title={getTrad("retenida")}
                        className={"icon icon_lockOutline danger font-size-lg p-0"}
                    />
                ) : (
                    <div
                        title={getTrad("sinRetencion")}
                        className={"icon icon_unlock font-size-xs p-0"}
                    />
                )
            }
        </div>;
    }

    calculateCellValue_compararNomina = (rowData) => {
        return rowData.idEstadoNomina;
    };

    cellRender_compararNomina = ({ data }) => {
        const { isLoading_comparacionNominaGestoria } = this.state;

        const error = data.conflictoNominaContrato || data.hayConflictos;
        const isClicable = !isLoading_comparacionNominaGestoria;

        const status =
            data.idEstadoNomina >= idsEstadoNomina.solicitudCambioRRHH
                ? error
                    ? "danger"
                    : "success"
                : "lightGray";

        const cursor = isClicable ? "pointer" : "";

        return (
            <div
                className={`font-size-lg ${error ? "inf_icons_Incidencias" : "dx-icon-info"} ${status} dx-icon font-size-lg ${cursor}`}
                onClick={isClicable ? () => this.compararNomina_onClick(data) : undefined}
            />
        );
    };

    infoFields = [
        "tipoContrato",
        "plusAntiguedad",
        "plusAsistencia",
        "plusFestivoTrab",
        "pagaExtra1",
        "pagaExtra2",
        "impHorasExtra",
        "horasExtra",
        "plusNocturnidad",
        "plusActividad",
        "plusProductividad",
        "plusAbsorbible",
        "anticipos",
    ];

    cellTemplate_currency = (cellElement, cellInfo) => {
        let { codMoneda, obsPlusActividad, obsPlusProductividad, obsPlusAbsorbible, obsAnticipos } = cellInfo.data;

        if (cellInfo.value != null) {
            cellElement.append(formatNumber(cellInfo.value, 2, "currency", codMoneda));
        } else {
            cellElement.append(formatNumber(0, 2, "currency", codMoneda));
        }

        const error = cellInfo.data["errorText_" + cellInfo.column.dataField] != null;
        let info =
            this.infoFields.includes(cellInfo.column.dataField) || this.infoFields.includes(cellInfo.column.name);

        //Excepciones PlusActividad y PlusProductividad
        if (info && cellInfo.column.dataField === "plusActividad") {
            info = obsPlusActividad != null && obsPlusActividad.length > 0 ? info : null;
        } else if (info && cellInfo.column.dataField === "plusProductividad") {
            info = obsPlusProductividad != null && obsPlusProductividad.length > 0 ? info : null;
        } else if (info && cellInfo.column.dataField === "plusAbsorbible") {
            info = obsPlusAbsorbible != null && obsPlusAbsorbible.length > 0 ? info : null;
        } else if (info && cellInfo.column.dataField === "anticipos") {
            info = obsAnticipos != null && obsAnticipos.length > 0 ? info : null;
        }

        const icon = error ? "icon_Warning" : info ? "dx-icon-info dx-icon" : null;
        if (icon != null) {
            cellElement.append(
                $("<div />")
                    .addClass("float-right pr-4")
                    .append($("<div />").addClass("font-size-xl position-absolute " + icon))
            );

            let key = cellInfo.key.idPersona + "_" + cellInfo.rowIndex;
            let columnName = cellInfo.column.dataField ? cellInfo.column.dataField : cellInfo.column.name;
            cellElement.attr("id", columnName + "_" + key);
        }
    };

    compararNomina_onClick = (rowData) => {
        if ([null, idsEstadoNomina.enProceso, idsEstadoNomina.validadoRRHH].includes(rowData.idEstadoNomina)) {
            const {
                salarioBase,
                incentivo,
                plusAntiguedad,
                plusAsistencia,
                segAccidenteConvenio,
                plusFestivoTrab,
                pagaExtra1,
                pagaExtra2,
                impHorasExtra,
                acuerdoNC,
                salarioEspecie,
                plusNocturnidad,
                plusPeligrosidad,
                plusResponsabilidad,
                plusActividad,
                plusProductividad,
                plusAbsorbible,
                codMoneda,
            } = rowData;

            const fields = {
                salarioBase,
                incentivo,
                plusAntiguedad,
                plusAsistencia,
                segAccidenteConvenio,
                plusFestivoTrab,
                pagaExtra1,
                pagaExtra2,
                impHorasExtra,
                acuerdoNC,
                salarioEspecie,
                plusNocturnidad,
                plusPeligrosidad,
                plusResponsabilidad,
                plusActividad,
                plusProductividad,
                plusAbsorbible,
            };

            var conceptosNominaNNomina = Object.keys(fields)
                .filter((field) => fields[field] !== null && fields[field] > 0)
                .map((field) => ({
                    denominacion: getTrad(field),
                    importe: fields[field],
                    isDevengo: true,
                    codMoneda,
                }));

            this.setState({
                comparacionNominaGestoria: [
                    {
                        idNomina: rowData.idNomina,
                        idEstadoNomina: rowData.idEstadoNomina,
                        fechaDesde: rowData.fechaInicioNomina,
                        fechaHasta: rowData.fechaFinNomina,
                        isContratoAsociado: true,
                        conceptosNominaNNomina,
                    },
                ],
                nominaSel: rowData,
            });
        } else if (
            rowData.idEstadoNomina === idsEstadoNomina.solicitudCambioRRHH ||
            rowData.idEstadoNomina >= idsEstadoNomina.solicitudCambioGestoria
        ) {
            this.setState(
                {
                    isLoading_comparacionNominaGestoria: true,
                    nominaSel: rowData,
                },
                () => {
                    this.context_GestionNominas
                        .invoke("CompararNominaGestoria", {}, "GET")
                        .done((data) => {
                            if (data.length > 0) {
                                this.setState({
                                    comparacionNominaGestoria: data,
                                    nominaSel: rowData,
                                    isLoading_comparacionNominaGestoria: false,
                                });
                            } else {
                                notify({
                                    message:
                                        "Se han superado el número máximo de llamadas, tienes que esperar " +
                                        data +
                                        " segundos.",
                                    type: "error",
                                    displayTime: 1500,
                                    closeOnClick: true,
                                });
                                this.setState({ isLoading_comparacionNominaGestoria: false });
                            }
                        })
                        .catch(() => {
                            notify({
                                message: "Ha ocurrido un error no contemplado.",
                                type: "error",
                                displayTime: 1500,
                                closeOnClick: true,
                            });
                            this.setState({ isLoading_comparacionNominaGestoria: false });
                        });
                }
            );
        }
    };

    cellTemplate_number = (cellElement, cellInfo) => {
        let { obsHorasExtra } = cellInfo.data;
        cellElement.append(formatNumber(cellInfo.value, 0, "decimal"));

        const error = cellInfo.data["errorText_" + cellInfo.column.dataField] != null;
        let info =
            this.infoFields.includes(cellInfo.column.dataField) || this.infoFields.includes(cellInfo.column.name);

        if (info && cellInfo.column.dataField === "horasExtra") {
            info = obsHorasExtra != null && obsHorasExtra.length > 0 ? info : null;
        }

        const icon = error ? "icon_Warning font-size-xl" : info ? "dx-icon-info dx-icon" : null;
        if (icon != null) {
            cellElement.append(
                $("<div />")
                    .addClass("float-right pr-4")
                    .append($("<div />").addClass("font-size-xl position-absolute " + icon))
            );

            let key = cellInfo.key.idPersona + "_" + cellInfo.rowIndex;
            let columnName = cellInfo.column.dataField ? cellInfo.column.dataField : cellInfo.column.name;
            cellElement.attr("id", columnName + "_" + key);
        }
    };

    cellTemplate_isEmbargo = (cellElement, cellInfo) => {
        let icon = cellInfo.value
            ? "dx-icon-money dx-icon font-size-xl danger"
            : "dx-icon-money dx-icon font-size-xl lightGray"; // "icon_unlock font-size-lg pl-2 danger";
        cellElement.append(
            $("<div />")
                .addClass("container_spanCentrado")
                .append($("<div />").addClass("font-size-lg position-absolute " + icon))
        );

        if (cellInfo.value === true) {
            let key = cellInfo.key.idPersona + "_" + cellInfo.rowIndex;
            let columnName = cellInfo.column.dataField ? cellInfo.column.dataField : cellInfo.column.name;
            cellElement.attr("id", columnName + "_" + key);
        }
    };

    cellTemplate_errorCell = (cellElement, cellInfo) => {
        if (cellInfo.value) {
            const icon = "icon_Warning font-size-xl";
            cellElement.append(
                $("<div />")
                    .addClass("float-right pr-4")
                    .append($("<div />").addClass("font-size-lg position-absolute " + icon))
            );

            let key = cellInfo.key.idPersona + "_" + cellInfo.rowIndex;
            let columnName = cellInfo.column.dataField ? cellInfo.column.dataField : cellInfo.column.name;
            cellElement.attr("id", columnName + "_" + key);
        }
    };

    cellTemplate_calendario = (cellElement, cellInfo) => {
        cellElement.append($("<div />").addClass("font-size-lg dx-icon dx-icon-event"));
    };

    getMessages_errorCell = (data) => {
        var messages = Object.keys(data)
            .filter((field) => field.startsWith("errorText_") && data[field] != null)
            .map((dataField) => {
                return getTrad(data[dataField]);
            })
            .filter((value, index, self) => self.indexOf(value) === index)
            .map((errorText) => {
                return { errorText };
            });

        const error = data.isEmbargo === true;
        const msgError = error ? { errorText: getTrad("msg_alert_nominaEmbargada") } : undefined;
        if (error) {
            messages.push(msgError);
        }

        return messages;
    };

    getMessages_salarioBase = (data) => {
        return [{ errorText: getTrad(data.errorText_salarioBase) }];
    };

    getMessages_tipoContrato = (data) => {
        return [{ text: "Días de contrato", value: data.diasContrato }];
    };

    getMessages_plusAntiguedad = (data) => {
        let messages = [];

        const error = data.errorText_plusAntiguedad != null;
        const msgError = error ? { errorText: getTrad(data.errorText_plusAntiguedad) } : undefined;
        if (error) {
            messages.push(msgError);
        } else {
            messages.push({
                text: "Años de antiguedad",
                value: data.añosAntiguedad,
            });
        }
        return messages;
    };

    getMessages_plusAsistencia = (data) => {
        let messages = [];

        const error = data.errorText_plusAsistencia != null;
        const msgError = error ? { errorText: getTrad(data.errorText_plusAsistencia) } : undefined;
        if (error) {
            messages.push(msgError);
        }
        messages.push({ text: "Número de absentismos", value: data.numAbsentismo });
        messages.push({
            text: "Número de retrasos (No aplica)",
            value: data.numRetrasos,
        });
        return messages;
    };

    getMessages_incentivo = (data) => {
        if (data.errorText_incentivo != null) {
            return [{ errorText: getTrad(data.errorText_incentivo) }];
        }
    };

    getMessages_plusFestivoTrab = (data) => {
        let messages = [];

        const error = data.errorText_plusFestivoTrab != null;
        const msgError = error ? { errorText: getTrad(data.errorText_plusFestivoTrab) } : undefined;
        if (error) {
            messages.push(msgError);
        }
        messages.push({
            text: "Número de festivos trabajados",
            value: data.festivosTrab,
        });
        return messages;
    };

    getMessages_ppext = (data) => {
        let messages = [];

        const error = data.errorText_ppext != null;
        const msgError = error ? { errorText: getTrad(data.errorText_ppext) } : undefined;
        if (error) {
            messages.push(msgError);
        }
        messages.push({ text: "Número de días de baja", value: data.baja });
        messages.push({
            text: "Número de días de cese temporal",
            value: data.ceseTemporal,
        });
        messages.push({
            text: "Número de días de baja laboral",
            value: data.bajaLaboral,
        });
        return messages;
    };

    getMessages_impHorasExtra = (data) => {
        let messages = [];

        const error = data.errorText_impHorasExtra != null;
        const msgError = error ? { errorText: getTrad(data.errorText_impHorasExtra) } : undefined;
        if (error) {
            messages.push(msgError);
        }
        messages.push({ text: "Número de horas extra", value: data.horasExtra });
        return messages;
    };

    getMessages_plusNocturnidad = (data) => {
        let messages = [];

        const error = data.errorText_plusNocturnidad != null;
        const msgError = error ? { errorText: getTrad(data.errorText_plusNocturnidad) } : undefined;
        if (error) {
            messages.push(msgError);
        }
        messages.push({
            text: "Número de horas nocturnas",
            value: data.horasNocturnas,
        });
        return messages;
    };

    getMessages_plusActividad = (data) => {
        if (data.obsPlusActividad != null) {
            return [{ text: data.obsPlusActividad }];
        }
    };

    getMessages_plusProductividad = (data) => {
        if (data.obsPlusProductividad != null) {
            return [{ text: data.obsPlusProductividad }];
        }
    };

    getMessages_plusPeligrosidad = (data) => {
        if (data.errorText_plusPeligrosidad != null) {
            return [{ errorText: getTrad(data.errorText_plusPeligrosidad) }];
        }
    };

    getMessages_plusResponsabilidad = (data) => {
        if (data.errorText_plusResponsabilidad != null) {
            return [{ errorText: getTrad(data.errorText_plusResponsabilidad) }];
        }
    };

    getMessages_plusAbsorbible = (data) => {
        if (data.obsPlusAbsorbible != null) {
            return [{ text: data.obsPlusAbsorbible }];
        }
    };

    getMessages_anticipos = (data) => {
        if (data.obsAnticipos != null) {
            return [{ text: data.obsAnticipos }];
        }
    };

    getMessages_isEmbargo = (data) => {
        if (data.isEmbargo === true) {
            return [{ errorText: getTrad("msg_alert_nominaEmbargada") }];
        }
    };

    getMessages_horasExtra = (data) => {
        if (data.obsHorasExtra != null) {
            return [{ text: data.obsHorasExtra }];
        }
    };

    isEditing = false;
    onCellClick = (e) => {
        if (e.column && e.rowType == "data") {
            let { fechaIni } = this.props;
            let rowData = { ...e.row.data };
            let formData_concepto = {
                fechaInicioNomina: rowData.fechaInicioNomina,
                fechaFinNomina: rowData.fechaFinNomina,
                idPersona: rowData.idPersona,
                field: e.column.dataField,
            };

            if (e.column.name == "editCell" && rowData && rowData.idPersona != undefined) {
                let idPersona = rowData.idPersona;
                let grid = this.PersonalGeneral_REF.dxDataGrid_personas;

                if (grid.getRowIndexByKey(idPersona) === 0) {
                    //Persona ya cargada
                    grid.editRow(0);
                } else {
                    this.isEditing = true;
                    grid.navigateToRow(idPersona);
                }
            } else if (e.column.name == "calendario" && rowData.idPersona != undefined) {
                this.setState({
                    popup_Title: rowData.nombre + " " + rowData.apellidos,
                    idPersonaSel: rowData.idPersona,
                });
            } else if (
                e.column.dataField == "idEstadoNomina" &&
                [idsEstadoNomina.enProceso, idsEstadoNomina.solicitudCambioRRHH, idsEstadoNomina.validadoRRHH].includes(
                    rowData.idEstadoNomina
                )
                && rowData.enableSend
            ) {
                let _this = this;

                this.context_GestionNominas
                    .invoke("EnviarNominaGestoria", { idNomina: rowData.idNomina }, "POST")
                    .done(function (result) {
                        if (result == true) {
                            notify({
                                message: getTrad("enviarNominasGestoria_success"),
                                type: "success",
                                displayTime: "1500",
                                closeOnClick: true,
                            });

                            _this.props.datasource_gestionNominas_personas.reload();
                            e.component.refresh(true);
                        } else {
                            //Mensaje tiempo
                            e.component.refresh(true);
                            notify({
                                message:
                                    "Se han superado el número máximo de llamadas, tienes que esperar " +
                                    result +
                                    " segundos.",
                                type: "error",
                                displayTime: "1500",
                                closeOnClick: true,
                            });
                        }
                    })
                    .catch(function () {
                        e.component.refresh(false);
                        notify({
                            message: "Ha ocurrido un error.",
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                        });
                    });
            } else if (e.column.dataField == "plusActividad" || e.column.dataField == "obsPlusActividad") {
                formData_concepto.plusActividad = rowData.plusActividad;
                formData_concepto.obsPlusActividad = rowData.obsPlusActividad;

                this.setState({
                    show_Popup: true,
                    popup_Title: "plusActividad",
                    formData_concepto,
                    obs_required: formData_concepto.plusActividad > 0,
                });
            } else if (e.column.dataField == "plusProductividad" || e.column.dataField == "obsPlusProductividad") {
                formData_concepto.plusProductividad = rowData.plusProductividad;
                formData_concepto.obsPlusProductividad = rowData.obsPlusProductividad;

                this.setState({
                    show_Popup: true,
                    popup_Title: "plusProductividad",
                    formData_concepto,
                    obs_required: formData_concepto.plusProductividad > 0,
                });
            } else if (e.column.dataField == "anticipos" || e.column.dataField == "obsAnticipos") {
                formData_concepto.anticipos = rowData.anticipos;
                formData_concepto.obsAnticipos = rowData.obsAnticipos;

                this.setState({
                    show_Popup: true,
                    popup_Title: "anticipos",
                    formData_concepto,
                    obs_required: formData_concepto.anticipos > 0,
                });
            }
        }
    };

    onCellHoverChanged = (e) => {
        if (e.rowType === "data") {
            if (e.eventType === "mouseover") {
                let key = e.key.idPersona + "_" + e.rowIndex;
                let columnName = e.column.dataField ? e.column.dataField : e.column.name;
                let id = "#" + columnName + "_" + key;

                let mismoId = this.state.popover_data && this.state.popover_data.id === id;
                if ($(id).length > 0 && !mismoId) {
                    let messages = [];

                    switch (columnName) {
                        case "errorCell":
                            messages = this.getMessages_errorCell(e.row.data);
                            break;
                        case "salarioBase":
                            messages = this.getMessages_salarioBase(e.row.data);
                            break;
                        case "tipoContrato":
                            messages = this.getMessages_tipoContrato(e.row.data);
                            break;
                        case "plusAntiguedad":
                            messages = this.getMessages_plusAntiguedad(e.row.data);
                            break;
                        case "plusAsistencia":
                            messages = this.getMessages_plusAsistencia(e.row.data);
                            break;
                        case "incentivo":
                            messages = this.getMessages_incentivo(e.row.data);
                            break;
                        case "plusFestivoTrab":
                            messages = this.getMessages_plusFestivoTrab(e.row.data);
                            break;
                        case "pagaExtra1":
                            messages = this.getMessages_ppext(e.row.data);
                        case "pagaExtra2":
                            messages = this.getMessages_ppext(e.row.data);
                            break;
                        case "impHorasExtra":
                            messages = this.getMessages_impHorasExtra(e.row.data);
                            break;
                        case "plusNocturnidad":
                            messages = this.getMessages_plusNocturnidad(e.row.data);
                            break;
                        case "plusActividad":
                            messages = this.getMessages_plusActividad(e.row.data);
                            break;
                        case "plusProductividad":
                            messages = this.getMessages_plusProductividad(e.row.data);
                            break;
                        case "plusPeligrosidad":
                            messages = this.getMessages_plusPeligrosidad(e.row.data);
                            break;
                        case "plusResponsabilidad":
                            messages = this.getMessages_plusResponsabilidad(e.row.data);
                            break;
                        case "horasExtra":
                            messages = this.getMessages_horasExtra(e.row.data);
                            break;
                        case "plusAbsorbible":
                            messages = this.getMessages_plusAbsorbible(e.row.data);
                            break;
                        case "anticipos":
                            messages = this.getMessages_anticipos(e.row.data);
                            break;
                        case "isEmbargo":
                            messages = this.getMessages_isEmbargo(e.row.data);
                            break;
                    }

                    this.setState({
                        popover_data: {
                            id: "#" + columnName + "_" + key,
                            messages,
                        },
                    });
                }
            }
        }
    };

    onEditingStart(e) {
        e.cancel = true;
    }

    dxMensajePregunta_concepto = (msg) => {
        let _this = this;
        dxMensajePregunta(msg, [
            [
                getTrad("aceptar"),
                function (e) {
                    // _this.context_GestionNominas.invoke("IU", {}, "PATCH").done(function () {
                    //     notify({
                    //         message: getTrad('aviso_C_RegistroActualizado'),
                    //         type: "success",
                    //         displayTime: "1500",
                    //         closeOnClick: true,
                    //     });
                    //     _this.props.datasource_gestionNominas_personas.reload();
                    //     _this.setState({ formData_concepto: null })
                    // });
                },
                "danger",
            ],
            [getTrad("cancelar"), function () { }],
        ]);
    };

    //#region  POPUPS ACTIVIDAD Y PRODUCTIVIDAD

    formLabel_plusActividad = {
        text: getTrad("plusActividad"),
    };

    formLabel_obsPlusActividad = {
        text: getTrad("obsPlusActividad"),
    };

    formLabel_plusProductividad = {
        text: getTrad("plusProductividad"),
    };

    formLabel_obsPlusProductividad = {
        text: getTrad("obsPlusProductividad"),
    };

    formLabel_horasExtra = {
        text: getTrad("horasExtra"),
    };

    formLabel_obsHorasExtra = {
        text: getTrad("obsHorasExtra"),
    };

    formLabel_plusAbsorbible = {
        text: getTrad("plusAbsorbible"),
    };

    formLabel_obsPlusAbsorbible = {
        text: getTrad("obsPlusAbsorbible"),
    };

    formLabel_anticipos = {
        text: getTrad("anticipos"),
    };

    formLabel_obsAnticipos = {
        text: getTrad("obsAnticipos"),
    };

    dxPopup_onShown = () => {
        this.Form_Concepto.focus();
    };

    dxPopup_onHiding = () => {
        this.setState({
            show_Popup: false,
            popup_Title: "",
            formData_concepto: null,
            idPersonaSel: null,
        });
    };

    onFieldDataChanged = (e) => {
        const obs_required = this.state;
        if (this.infoFields.includes(e.dataField)) {
            let obligatorio = e.value > 0;
            if (obligatorio != obs_required) {
                this.setState({ obs_required: obligatorio });
            }
        }
    };

    render_dxButton_guardar(e) {
        return (
            <Button
                text={getTrad("guardar")}
                onClick={this.onClick_guardar}
                validationGroup="validationGroup"
                useSubmitBehavior={true}
            />
        );
    }

    onClick_guardar = (e) => {
        let _this = this;
        const isValid = e.validationGroup.validate().isValid;

        if (isValid) {
            this.context_GestionNominas
                .invoke("AddConceptoNNomina", {}, "POST")
                .done(function () {
                    notify({
                        message: getTrad("aviso_C_RegistroInsertado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                    });

                    _this.DataGrid_Personas.refresh();
                    _this.setState({
                        show_Popup: false,
                        popup_Title: "",
                        formData_concepto: null,
                    });
                })
                .catch(function () {
                    notify({
                        message: getTrad("error_parametrizacion_persona"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                    });
                });
        }
    };

    render_dxButton_cancelar(e) {
        return <Button text={getTrad("cancelar")} onClick={this.onClick_cancelar} />;
    }

    onClick_cancelar = () => {
        this.setState({
            show_Popup: false,
            popup_Title: "",
            formData_concepto: null,
        });
    };

    //#endregion

    personalGeneral_onContentReady = (e) => {
        if (e.component.pageSize() > 1) {
            e.component.pageSize(1);
        }

        if (this.isEditing) {
            this.isEditing = false;
            e.component.editRow(0);
        }
    };

    personalGeneral_modified = (e) => {
        this.props.datasource_gestionNominas_personas.reload().done(() => {
            this.DataGrid_Personas.refresh(false);
        });
    };

    componentDidMount() {
        this.PersonalGeneral_REF.dxDataGrid_personas.option("onContentReady", this.personalGeneral_onContentReady);
        this.PersonalGeneral_REF.datasource_persona.store().on("modified", this.personalGeneral_modified);
    }

    componentDidUpdate(prevProps, prevState) {
        let { vista_GestionPluses, filtroFiniquitos } = this.props;
        if (prevProps.vista_GestionPluses !== vista_GestionPluses || prevProps.filtroFiniquitos !== filtroFiniquitos) {
            let filterValue = vista_GestionPluses ? ["gestionaPluses", "=", vista_GestionPluses] : [];
            filterValue = filtroFiniquitos
                ? filterValue
                : [["idTipoNomina", "=", idsTipoNomina.pagaMensual], "and", filterValue];
            this.setState({ filterValue });
        }
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DataGridPersonas);
