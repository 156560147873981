import React, { useContext, useState } from "react";
import { SelectBox } from "devextreme-react/select-box";
import { DateBox, Button as DateBoxButton, ToolbarItem } from "devextreme-react/date-box";
import RadioGroup from "devextreme-react/radio-group";
// import { Button } from "reactstrap";
//Layout
import PageTitle from "../../../../../layout/AppMain/PageTitle";
import "./Filters.scss";
import { useEffect } from "react";
import { Popup } from "devextreme-react";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DropDownOptions } from "devextreme-react/autocomplete";
import { useRef } from "react";
import { CuadranteContext } from "../../services/apiCalls";
import { connect } from "react-redux";
import { getTrad } from "helpers";

const estiloLeyendaPrevision = [
    {
        backgroundColor: "#1e9aff50",
        color: "#3f3f3f",
        fontWeight: 700,
        minHeight: 40,
    },
    {
        backgroundColor: "#1e9aff25",
        color: "#3f3f3f",
        fontWeight: 700,
        minHeight: 40,
    },
];

function Filters({
    user,
    lavanderia,
    selectedRange,
    setSelectedRange,
    viewType,
    setViewType,
    selectedIndex,
    setTurnoSeleccionado,
    tblCalendarioPersonal_estado,
}) {
    const Context = useContext(CuadranteContext);

    const dateBox_ref = useRef(null);
    const [multiViewItems, setMultiViewItems] = useState(null);
    const [leyendVisible, setLeyendVisible] = useState(false);

    const [tblTipoTrabajo, setTblTipoTrabajo] = useState(null);
    const [tipoTrabajoReadOnly, setTipoTrabajoReadOnly] = useState(true);

    useEffect(() => {
        const turnos = Context.tblTurno.filter(
            (x) => x.idLavanderia === lavanderia.idLavanderia && x.idTurnoPadre === null
        );
        const turnosConColores = turnos.map((x, index) => {
            return { color: "--primary", index, ...x };
        });
        setMultiViewItems(turnosConColores);
    }, [lavanderia.idLavanderia, Context.tblTurno]);

    useEffect(() => {
        const tbl = Context.tblTipoTrabajo.filter(
            (tt) =>
                user.enableDatosRRHH ||
                user.tblTipoTrabajoNUsuario.length === 0 ||
                user.tblTipoTrabajoNUsuario.some(
                    (ttnu) => ttnu.idTipoTrabajo === tt.idTipoTrabajo && ttnu.idLavanderia === lavanderia.idLavanderia
                )
        );

        setTipoTrabajoReadOnly(tbl.length === 1);
        setTblTipoTrabajo(tbl);

        if (tbl.length === 1) {
            Context.selectTipoTrabajo(tbl[0].idTipoTrabajo);
        }
    }, [lavanderia.idLavanderia, user.tblTipoTrabajoNUsuario, Context.tblTipoTrabajo]);

    const rangeValues = [
        {
            value: 0,
            text: "1 semana",
        },
        {
            value: 1,
            text: "2 semanas",
        },
        {
            value: 2,
            text: "3 semanas",
        },
        {
            value: 3,
            text: "4 semanas",
        },
    ];

    const enum_viewTypes = [
        {
            key: "compacto",
            text: "Compacto",
            style: "bold",
            hint: "Compacto",
        },
        {
            key: "detallado",
            text: "Detallado",
            style: "bold",
            hint: "Detallado",
        },
    ];

    const getRange = (date, weekAmount) => {
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
        startOfWeek.setHours(0, 0, 0, 0);
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 6 + 7 * weekAmount);
        endOfWeek.setHours(23, 59, 59);
        return [startOfWeek, endOfWeek];
    };

    const handleDateChange = (e) => {
        let selectedDate;
        if (e.value) {
            selectedDate = e.value;
        } else {
            selectedDate = e;
        }
        const range = getRange(selectedDate, selectedRange.weekAmount);

        setSelectedRange({
            firstDay: range[0],
            lastDay: range[1],
            weekAmount: selectedRange.weekAmount,
        });
    };

    const handleRangeDiff = (amount) => {
        const range = getRange(selectedRange.firstDay, amount);

        setSelectedRange({
            firstDay: range[0],
            lastDay: range[1],
            weekAmount: amount,
        });
    };

    const handlePreviousWeek = () => {
        if (selectedRange) {
            const previousWeekStart = new Date(selectedRange.firstDay);
            previousWeekStart.setDate(previousWeekStart.getDate() - 7);
            const previousWeekRange = getRange(previousWeekStart, selectedRange.weekAmount);
            const range = {
                firstDay: previousWeekRange[0],
                lastDay: previousWeekRange[1],
                weekAmount: selectedRange.weekAmount,
            };

            setSelectedRange(range);
        }
    };

    const handleNextWeek = () => {
        if (selectedRange) {
            const nextWeekStart = new Date(selectedRange.firstDay);
            nextWeekStart.setDate(nextWeekStart.getDate() + 7);
            const nextWeekRange = getRange(nextWeekStart, selectedRange.weekAmount);
            const range = {
                firstDay: nextWeekRange[0],
                lastDay: nextWeekRange[1],
                weekAmount: selectedRange.weekAmount,
            };

            setSelectedRange(range);
        }
    };

    const toggleViewType = (input) => {
        setViewType(input.value);
        // setViewType(input.addedItems[0].text);
    };

    const dateDisplayFormat = () => {
        selectedRange.firstDay.toLocaleString(undefined, {
            month: "long",
            day: "2-digit",
            // year: "numeric",
        });
        const firstDate = selectedRange.firstDay;
        const lastDate = selectedRange.lastDay;
        const firstMonth = firstDate.toLocaleString(undefined, { month: "long" });
        const lastMonth = lastDate.toLocaleString(undefined, { month: "long" });
        if (firstDate.getMonth() === lastDate.getMonth()) {
            return `${firstMonth.charAt(0).toUpperCase() + firstMonth.slice(1)} | ${firstDate.getFullYear()}`;
        } else if (firstDate.getFullYear() === lastDate.getFullYear()) {
            return `${firstMonth.charAt(0).toUpperCase() + firstMonth.slice(1)} - ${
                lastMonth.charAt(0).toUpperCase() + lastMonth.slice(1)
            } | ${firstDate.getFullYear()}`;
        } else {
            return `${firstMonth.charAt(0).toUpperCase() + firstMonth.slice(1)} - ${
                lastMonth.charAt(0).toUpperCase() + lastMonth.slice(1)
            } | ${firstDate.getFullYear()} - ${lastDate.getFullYear()}`;
        }
    };

    const dxDateBox_button_onClick = () => {
        let date = new Date();
        date.setDate(new Date().getDate() - 7);
        handleDateChange(date);
        dateBox_ref.current.instance.close();
    };

    const dateSelector = () => {
        return (
            <DateBox
                ref={dateBox_ref}
                id="SelectorFecha"
                key="SelectorFecha"
                className="centerText mr-3"
                height={35}
                width={375}
                acceptCustomValue={false}
                openOnFieldClick={true}
                value={selectedRange.firstDay ? selectedRange.firstDay : handleDateChange(Date.now())[0]}
                type="date"
                displayFormat={dateDisplayFormat}
                stylingMode="underlined"
                onValueChanged={(e) => {
                    handleDateChange(e.value);
                }}
            >
                <DateBoxButton
                    name={"previousWeek"}
                    location={"before"}
                    options={{
                        icon: "spinprev",
                        stylingMode: "text",
                        onClick: handlePreviousWeek,
                    }}
                />
                <DateBoxButton
                    name={"nextWeek"}
                    location={"after"}
                    options={{
                        icon: "spinnext",
                        stylingMode: "text",
                        onClick: handleNextWeek,
                    }}
                />
                <DropDownOptions>
                    <ToolbarItem
                        toolbar={"bottom"}
                        location={"before"}
                        widget={"dxButton"}
                        options={{
                            text: "Hoy",
                            stylingMode: "outlined",
                            onClick: dxDateBox_button_onClick,
                        }}
                    />
                </DropDownOptions>
            </DateBox>
        );
    };

    const weekRangeSelector = () => {
        return (
            <SelectBox
                key="SelectorSemanas"
                className="centerText mr-3"
                height={35}
                width={180}
                // inputAttr={{ class: "font-size-sm" }}
                dataSource={rangeValues}
                valueExpr={"value"}
                displayExpr={"text"}
                stylingMode="underlined"
                value={selectedRange.weekAmount}
                onValueChanged={(e) => {
                    handleRangeDiff(e.value);
                }}
            />
        );
    };

    const radio = (data) => {
        return (
            <div className="d-flex w-100 flex-column centerText">
                <div className="d-flex w-100 justify-content-center align-items-start">
                    <div className={"iconoRespuesta "}>{data.text}</div>
                </div>
            </div>
        );
    };

    const styleSelector = () => {
        return (
            <RadioGroup
                key="SelectorEstilo"
                id="key"
                items={enum_viewTypes}
                value={viewType}
                valueExpr="key"
                displayExpr="text"
                onValueChanged={toggleViewType}
                layout="horizontal"
                className="centerText dx-radiogroup-stylechoosercuadrante mr-3"
                itemRender={radio}
                height={35}
            />
        );
    };

    const selectView = ({ selectedItem }) => {
        setTurnoSeleccionado(selectedItem);
    };

    const selectorTurnos = () => {
        return (
            <SelectBox
                key="SelectorTurnos"
                value={selectedIndex}
                items={multiViewItems}
                placeholder={getTrad("turno")}
                valueExpr={"index"}
                onSelectionChanged={selectView}
                stylingMode="underlined"
                displayExpr={"denominacion"}
                className="centerText mr-3"
                height={35}
                width={180}
            />
        );
    };

    const selectTipoTrabajo = (e) => {
        Context.selectTipoTrabajo(e.selectedItem?.idTipoTrabajo);
    };

    const selectorTipoTrabajo = () => {
        return (
            <SelectBox
                key="SelectorTipoTrabajo"
                value={Context.idTipoTrabajoSel}
                items={tblTipoTrabajo}
                readOnly={tipoTrabajoReadOnly}
                placeholder={getTrad("tipoTrabajo")}
                showClearButton={true}
                valueExpr={"idTipoTrabajo"}
                onSelectionChanged={selectTipoTrabajo}
                stylingMode="underlined"
                displayExpr={"denominacion"}
                className="centerText mr-3"
                height={35}
                width={230}
            />
        );
    };

    const showLeyendPopup = () => {
        setLeyendVisible(true);
    };

    const popupLeyenda = () => {
        return (
            <div
                key={"IconLeyenda"}
                className="align-items-center justify-content-center font-size-max d-flex pointer"
                onClick={showLeyendPopup}
            >
                <i className="font-size-xxl dx-icon dx-icon-help"></i>
            </div>
        );
    };

    const hideLeyendPopup = () => {
        setLeyendVisible(false);
    };

    return (
        <div className="cuadranteFilters">
            <PageTitle
                heading={"Cuadrante personal"}
                postHeading={[
                    dateSelector(),
                    weekRangeSelector(),
                    selectorTurnos(),
                    selectorTipoTrabajo(),
                    styleSelector(),
                    popupLeyenda(),
                ]}
            />
            <Popup
                visible={leyendVisible}
                onHiding={hideLeyendPopup}
                width={700}
                maxWidth={"90%"}
                height={"fit-content"}
                title="Leyenda"
                className="PivotCuadrante"
                dragEnabled={false}
            >
                <div className="font-size-lg text-center mb-1 ml-3 mr-3 px-2 pb-2 underline_bottom_dotted">
                    Tipo de previsión
                </div>
                <Row>
                    <Col sm="4" className="p-2">
                        <div className="cellData" style={estiloLeyendaPrevision[1]}>
                            Previsión pasada
                        </div>
                    </Col>
                    <Col sm="4" className="p-2">
                        <div className="cellData" style={estiloLeyendaPrevision[0]}>
                            Previsión fijada
                        </div>
                    </Col>
                    <Col sm="4" className="p-2">
                        <div className="cellData stripe" style={estiloLeyendaPrevision[0]}>
                            Previsión no fijada
                        </div>
                    </Col>
                </Row>
                <div className="font-size-lg text-center my-1 ml-3 mr-3 p-2 underline_bottom_dotted">Estados</div>
                <Row className="justify-content-center">
                    {tblCalendarioPersonal_estado &&
                        tblCalendarioPersonal_estado.map((estado) => {
                            return (
                                <Col key={`Leyenda${estado.idEstado}`} sm="4" className="p-2">
                                    <div
                                        className={`cellData${estado.idEstado === 10 ? " diaCierre" : ""}`}
                                        style={{
                                            backgroundColor: estado.colorHexa,
                                            color: "#3f3f3f",
                                            fontWeight: 700,
                                            minHeight: 40,
                                            display: "flex",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <span>{estado.denominacion}</span>
                                    </div>
                                </Col>
                            );
                        })}
                </Row>
            </Popup>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.Authentication.user,
});

export default connect(mapStateToProps)(Filters);
