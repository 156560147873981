import { connectionConstants } from "../../../constants";
import {
    authHeader,
    errorHandler,
    getTrad,
    tooltipControl_creacion,
    dxMensajePregunta,
    convertClienteUtcToLavanderiaUtc,
    formatNumber,
    addDays,
    formatDate_noTime_parameter,
    getDaysInRange,
} from "../../../helpers";

import { svg_remove_circle, svg_tick_circle, svg_remove, svg_lock_outline } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";
import query from "devextreme/data/query";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
    var deferred = $.Deferred();

    var fechasEntidadesVisibles = {};
    var repartoValetSel_bak,
        repartoValetSel = (repartoValetSel_bak = {
            tblPrendaHuespedNRepartoValet: [],
            tblPrendaExtraNRepartoValet: [],
            tblPrendaEjecutivoNRepartoValet: [],
        }),
        rowEditing = null, //Row de grid prendas actual que se está editando
        arg_impresion = {};

    //#region DATASOURCES
    var datasource_tblRepartosValet_anteriores = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblRepartosValet",
            key: "idRepartoValet",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            onInserted: function () {
                datasource_tblRepartosValet_anteriores_cierres.reload();
            },
            onUpdated: function () {
                datasource_tblRepartosValet_anteriores_cierres.reload();
            },
            version: 4,
        }),
        select: [
            "idRepartoValet,codigoRepartosValet,fecha,numHabitacion,tipoLavado,idTipoRepartoValet,isEnviadoCorreo, descuento",
        ],
        expand: [
            "tblPrendaHuespedNRepartoValet($select=idPrendaHuesped,cantidadLavadoSeco,cantidadPlanchado,cantidadLavadoPlanchado,cantidadTintoreria;$expand=tblPrendaHuesped($select=codigoPrendaHuesped,denominacion,precioLavado,precioPlanchado,precioLavadoPlanchado,precioTintoreria,incLavadoExpress))",
            "tblPrendaExtraNRepartoValet($select=idPrendaExtra,cantidadLavadoSeco,cantidadPlanchado,cantidadLavadoPlanchado,cantidadTintoreria;$expand=tblPrendaExtra($select=codigoPrendaExtra,denominacion,precioLavado,precioPlanchado,precioLavadoPlanchado,precioTintoreria,incLavadoExpress))",
            "tblEntidad($select=idEntidad,denominacion)",
            "tblPrendaEjecutivoNRepartoValet($select=idPrendaEjecutivo, cantidadLavadoDoblado, cantidadLavadoVaporizado, cantidadPlanchado, cantidadLavadoSecadoPlanchado;$expand=tblPrendaEjecutivo($select=codigoPrendaEjecutivo, denominacion, precioLavadoDoblado, precioLavadoVaporizado, precioPlanchado, precioLavadoSecadoPlanchado, incLavadoExpress))",
        ],
    });

    var datasource_tblCierreFactEntidad = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreFactEntidad",
            key: ["idEntidad", "fechaDesde", "fechaHasta"],
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            onLoading: function (loadOptions) {
                let filtroEntidades =
                    fechasEntidadesVisibles.entidades.length > 0
                        ? ["idEntidad in (" + fechasEntidadesVisibles.entidades + ")"]
                        : ["true"];

                let filtroFechas = fechasEntidadesVisibles.fechas.reduce((filtroFechas, fecha, index) => {
                    if (index != 0) filtroFechas += " or ";
                    filtroFechas += "(fechaDesde le " + fecha + " and fechaHasta ge " + fecha + ")";
                    return filtroFechas;
                }, "");

                if (filtroFechas == "") filtroFechas = "true";
                if (loadOptions.filter) {
                    loadOptions.filter = [loadOptions.filter, "and", [filtroEntidades], "and", [filtroFechas]];
                } else {
                    loadOptions.filter = [[filtroEntidades], "and", [filtroFechas]];
                }
            },
            version: 4,
        }),
    });

    var datasource_tblRepartosValet_anteriores_cierres = new DataSource({
        store: new CustomStore({
            key: "idRepartoValet",
            load: function (loadOptions) {
                var deferred = $.Deferred();

                let pageIndex = loadOptions.skip == 0 ? 0 : loadOptions.skip / loadOptions.take;

                datasource_tblRepartosValet_anteriores.paginate(true);
                datasource_tblRepartosValet_anteriores.pageSize(loadOptions.take);
                datasource_tblRepartosValet_anteriores.sort(loadOptions.sort);
                datasource_tblRepartosValet_anteriores.requireTotalCount(true);
                datasource_tblRepartosValet_anteriores.searchValue(loadOptions.searchValue);
                datasource_tblRepartosValet_anteriores.searchOperation(loadOptions.searchOperation);
                datasource_tblRepartosValet_anteriores.filter(loadOptions.filter ? loadOptions.filter : null);
                datasource_tblRepartosValet_anteriores.pageIndex(pageIndex);

                datasource_tblRepartosValet_anteriores.load(loadOptions).done(function (repartos) {
                    fechasEntidadesVisibles = repartos.reduce(
                        (acc, reparto) => {
                            if (!acc.entidades.includes(reparto.tblEntidad.idEntidad))
                                acc.entidades.push(reparto.tblEntidad.idEntidad);
                            if (!acc.fechas.includes(formatDate_noTime_parameter(reparto.fecha)))
                                acc.fechas.push(formatDate_noTime_parameter(reparto.fecha));

                            return acc;
                        },
                        { entidades: [], fechas: [] }
                    );

                    if (repartos.length == 0) {
                        deferred.resolve(repartos, {
                            totalCount: 0,
                        });
                    } else {
                        datasource_tblCierreFactEntidad.load().done((fechasEntidadCierre) => {
                            repartos
                                .filter((x) => fechasEntidadCierre.some((y) => x.tblEntidad.idEntidad === y.idEntidad))
                                .forEach((reparto) => {
                                    fechasEntidadCierre.forEach((item) => {
                                        let { fechaDesde, fechaHasta } = item;
                                        let rangoFechas = getDaysInRange(fechaDesde, fechaHasta).map((x) =>
                                            formatDate_noTime_parameter(x)
                                        );

                                        if (!reparto.isCerradoFactEntidad)
                                            reparto.isCerradoFactEntidad =
                                                reparto.tblEntidad.idEntidad == item.idEntidad &&
                                                rangoFechas.filter(
                                                    (fecha) => fecha == formatDate_noTime_parameter(reparto.fecha)
                                                ).length > 0;
                                    });
                                });

                            deferred.resolve(repartos, {
                                totalCount: datasource_tblRepartosValet_anteriores.totalCount(),
                            });
                        });
                    }
                });

                return deferred.promise();
            },
            remove: function (key) {
                var deferred = $.Deferred();

                datasource_tblRepartosValet_anteriores
                    .store()
                    .remove(key)
                    .done(function () {
                        deferred.resolve();
                    });

                return deferred.promise();
            },
        }),
    });

    var datasource_tblPrendaHuesped = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrendaHuesped",
            key: "idPrendaHuesped",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: [
            "idPrendaHuesped,codigoPrendaHuesped,denominacion,peso,precioLavado,precioPlanchado,precioLavadoPlanchado,precioTintoreria,incLavadoExpress",
        ],
        sort: "codigoPrendaHuesped",
    });

    var datasource_tblPrendaExtra = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblPrendaExtra",
            key: "idPrendaExtra",
            errorHandler: function (error) {
                errorHandler(error, COMPONENT);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: [
            "idPrendaExtra,codigoPrendaExtra,denominacion,peso,precioLavado,precioPlanchado,precioLavadoPlanchado,precioTintoreria,incLavadoExpress",
        ],
        sort: "codigoPrendaExtra",
    });

    var datasource_tblPrendaEjecutivo = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaEjecutivo",
            key: "idPrendaEjecutivo",
            errorHandler: (error) => errorHandler(error, COMPONENT),
            beforeSend: (request) => {
                request.headers = { ...authHeader() };

                request.params.idLavanderia = LAVANDERIA.idLavanderia;
            },
            version: 4,
        }),
        select: [
            "idPrendaEjecutivo, codigoPrendaEjecutivo, denominacion, peso, precioLavadoDoblado, precioLavadoVaporizado, precioPlanchado, precioLavadoSecadoPlanchado, incLavadoExpress",
        ],
        sort: "codigoPrendaEjecutivo",
    });

    //#region SELECTOR COMPAÑÍA - ENTIDAD
    var datasource_datos_compaEnti_compañia = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.ODATA_URL + "tblCompañia",
                key: "idCompañia",
                errorHandler: function (error) {
                    errorHandler(error, COMPONENT);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };

                    request.params.idLavanderia = LAVANDERIA.idLavanderia;
                },
                version: 4,
            }),
            select: ["idCompañia", "denominacion", "activo"],
            sort: ["denominacion"],
        }),
        datasource_datos_compaEnti_entidad = new DataSource({
            paginate: false,
            store: new ODataStore({
                url: connectionConstants.ODATA_URL + "tblEntidad",
                key: "idEntidad",
                errorHandler: function (error) {
                    errorHandler(error, COMPONENT);
                },
                beforeSend: function (request) {
                    request.headers = { ...authHeader() };

                    request.params.idLavanderia = LAVANDERIA.idLavanderia;

                    if (request.method === "get") {
                        request.params.todas = true;
                    }
                },
                version: 4,
            }),
            select: ["idEntidad", "denominacion", "idCompañia", "inventarioPorEntidad"],
            expand: ["tblLavanderia($select=idLavanderia,denominacion;)", "tblCierreFactEntidad"],
        }),
        datasource_datos_compaEnti = new DataSource({
            store: new CustomStore({
                load: function (loadOptions) {
                    var _deferred = $.Deferred();

                    $.when(datasource_datos_compaEnti_compañia.load(), datasource_datos_compaEnti_entidad.load()).done(
                        function () {
                            var compañias = query(datasource_datos_compaEnti_compañia.items())
                                .filter(["activo", "=", true])
                                .toArray();
                            var entidades = datasource_datos_compaEnti_entidad.items(),
                                compaEnti = [],
                                todasEntidades = [];

                            $.each(compañias, function (index, compañia) {
                                var compa = $.extend(true, {}, compañia);

                                compa.tblEntidad = $.grep(entidades, function (entidad) {
                                    return entidad.idCompañia === compañia.idCompañia;
                                });
                                compaEnti.push(compa);
                                $.merge(todasEntidades, compa.tblEntidad);
                            });

                            compaEnti = $.grep(compaEnti, function (compañia) {
                                return compañia.tblEntidad.length > 0;
                            });

                            todasEntidades = query(todasEntidades).sortBy("denominacion").toArray();

                            compaEnti.splice(0, 0, {
                                idCompañia: -1,
                                denominacion: getTrad("todas").toUpperCase(),
                                tblEntidad: todasEntidades,
                            });
                            _deferred.resolve(compaEnti);
                        }
                    );
                    return _deferred.promise();
                },
            }),
        });

    //#endregion

    var enum_tipoLavado = [
        {
            tipoLavado: 1,
            denominacion: getTrad("normal"),
        },
        {
            tipoLavado: 2,
            denominacion: getTrad("express"),
        },
    ];
    var enum_huespedExtra = [
        {
            value: 1,
            denominacion: getTrad("prendasHuesped"),
        },
        {
            value: 2,
            denominacion: getTrad("prendasExtra"),
        },
        {
            value: 3,
            denominacion: getTrad("prendasEjecutivo"),
        },
    ];

    var enum_tipoReparto = [
        {
            value: 1,
            denominacion: getTrad("huesped"),
        },
        {
            value: 2,
            denominacion: getTrad("extra"),
        },
        {
            value: 3,
            denominacion: getTrad("ejecutivo"),
        },
    ];
    let enum_colores = {
        rojo: "#BC2929",
        ambar: "#F4BC46",
        verde: "#5FBA46",
        violeta: "#A004FA",
        gris: "#737373",
    };
    //#endregion

    $.when(
        datasource_tblPrendaHuesped.load(),
        datasource_tblPrendaExtra.load(),
        datasource_tblPrendaEjecutivo.load(),
        datasource_datos_compaEnti.load()
    )
        .done(function (prendasHuesped_items, prendasExtra_items, prendasEjecutivo_items, compaEnti_items) {
            //Impresión
            COMPONENT.setReport({
                denominacion: "albaran_repartosValet",
                enviarMail: true,
                parameterEvent: CustomizeParameterEditors,
            });

            function cargaRepartoValet(objReparto) {
                repartoValetSel.idRepartoValet = objReparto.idRepartoValet;
                repartoValetSel.fecha = objReparto.fecha;
                repartoValetSel.numHabitacion = objReparto.numHabitacion;
                repartoValetSel.tipoLavado = objReparto.tipoLavado;
                repartoValetSel.idEntidad = objReparto.tblEntidad.idEntidad;
                repartoValetSel.observaciones = objReparto.observaciones;
                repartoValetSel.estado = objReparto.estado;
                repartoValetSel.precio = objReparto.precio;
                repartoValetSel.codigoRepartosValet = objReparto.codigoRepartosValet;
                repartoValetSel.idLavanderia = objReparto.idLavanderia;
                repartoValetSel.idTipoRepartoValet = objReparto.idTipoRepartoValet;
                repartoValetSel.descuento = objReparto.descuento;

                if (objReparto.tblPrendaHuespedNRepartoValet != null) {
                    repartoValetSel.tblPrendaHuespedNRepartoValet.length = 0;
                    $.merge(repartoValetSel.tblPrendaHuespedNRepartoValet, objReparto.tblPrendaHuespedNRepartoValet);
                }
                if (objReparto.tblPrendaExtraNRepartoValet != null) {
                    repartoValetSel.tblPrendaExtraNRepartoValet.length = 0;
                    $.merge(repartoValetSel.tblPrendaExtraNRepartoValet, objReparto.tblPrendaExtraNRepartoValet);
                }
                if (objReparto.tblPrendaEjecutivoNRepartoValet != null) {
                    repartoValetSel.tblPrendaEjecutivoNRepartoValet.length = 0;
                    $.merge(
                        repartoValetSel.tblPrendaEjecutivoNRepartoValet,
                        objReparto.tblPrendaEjecutivoNRepartoValet
                    );
                }
                repartoValetSel_bak = $.extend(true, {}, repartoValetSel);

                $("#dxForm_nuevoReparto").dxForm("instance").updateData(repartoValetSel);

                //#region DataGrids prendas
                // Se hacen cambios en los tres para que al cambiar de tab estén los tres igual y no se vea ningún cambio en el layout
                let gridsRepartos = [
                    "#dxDataGrid_nuevoRepartoHuesped",
                    "#dxDataGrid_nuevoRepartoExtra",
                    "#dxDataGrid_nuevoRepartoEjecutivo",
                ];
                $.each(gridsRepartos, function (index, item) {
                    let dxDataGrid_nuevoReparto = $(item).dxDataGrid("instance");
                    dxDataGrid_nuevoReparto.beginUpdate();
                    dxDataGrid_nuevoReparto.cancelEditData();
                    dxDataGrid_nuevoReparto.refresh(true).done(function () {
                        dxDataGrid_nuevoReparto.option("editing.allowDeleting", !objReparto.isCerradoFactEntidad);
                        dxDataGrid_nuevoReparto.option("editing.allowUpdating", !objReparto.isCerradoFactEntidad);

                        $(item).dxDataGrid(
                            "columnOption",
                            "cantidadLavadoSeco",
                            "cssClass",
                            !objReparto.isCerradoFactEntidad ? "dx-Cell_Editable" : ""
                        );
                        $(item).dxDataGrid(
                            "columnOption",
                            "cantidadPlanchado",
                            "cssClass",
                            !objReparto.isCerradoFactEntidad ? "dx-Cell_Editable" : ""
                        );
                        $(item).dxDataGrid(
                            "columnOption",
                            "cantidadLavadoPlanchado",
                            "cssClass",
                            !objReparto.isCerradoFactEntidad ? "dx-Cell_Editable" : ""
                        );
                        $(item).dxDataGrid(
                            "columnOption",
                            "cantidadTintoreria",
                            "cssClass",
                            !objReparto.isCerradoFactEntidad ? "dx-Cell_Editable" : ""
                        );
                    });
                    dxDataGrid_nuevoReparto.endUpdate();
                });
                //#endregion

                $("#dxTabPanel_tipoPrenda")
                    .dxTabPanel("instance")
                    .option(
                        "selectedIndex",
                        repartoValetSel.idTipoRepartoValet == null
                            ? 0
                            : repartoValetSel.idTipoRepartoValet === 1
                            ? 1
                            : repartoValetSel.idTipoRepartoValet === 2
                            ? 2
                            : 3
                    );
            }

            function formatoPrendas_addCantidades(prendas_items) {
                var prendas = [];

                $.each(prendas_items, function (index, prenda) {
                    var tblPrendaNRepartoValet = {
                        tblPrenda: [],
                    };

                    tblPrendaNRepartoValet.tblPrenda.denominacion = prenda.denominacion;
                    tblPrendaNRepartoValet.tblPrenda.incLavadoExpress = prenda.incLavadoExpress;

                    if ([1, 2].includes(repartoValetSel.idTipoRepartoValet)) {
                        tblPrendaNRepartoValet.cantidadLavadoSeco = 0;
                        tblPrendaNRepartoValet.cantidadPlanchado = 0;
                        tblPrendaNRepartoValet.cantidadLavadoPlanchado = 0;
                        tblPrendaNRepartoValet.cantidadTintoreria = 0;
                        tblPrendaNRepartoValet.tblPrenda.precioLavado = prenda.precioLavado;
                        tblPrendaNRepartoValet.tblPrenda.precioPlanchado = prenda.precioPlanchado;
                        tblPrendaNRepartoValet.tblPrenda.precioLavadoPlanchado = prenda.precioLavadoPlanchado;
                        tblPrendaNRepartoValet.tblPrenda.precioTintoreria = prenda.precioTintoreria;
                    } else {
                        tblPrendaNRepartoValet.cantidadLavadoDoblado = 0;
                        tblPrendaNRepartoValet.cantidadLavadoVaporizado = 0;
                        tblPrendaNRepartoValet.cantidadPlanchado = 0;
                        tblPrendaNRepartoValet.cantidadLavadoSecadoPlanchado = 0;
                        tblPrendaNRepartoValet.tblPrenda.precioLavadoDoblado = prenda.precioLavadoDoblado;
                        tblPrendaNRepartoValet.tblPrenda.precioLavadoVaporizado = prenda.precioLavadoVaporizado;
                        tblPrendaNRepartoValet.tblPrenda.precioPlanchado = prenda.precioPlanchado;
                        tblPrendaNRepartoValet.tblPrenda.precioLavadoSecadoPlanchado =
                            prenda.precioLavadoSecadoPlanchado;
                    }

                    if (repartoValetSel.idTipoRepartoValet === 1) {
                        // huesped
                        tblPrendaNRepartoValet.idPrendaHuesped = prenda.idPrendaHuesped;
                        tblPrendaNRepartoValet.tblPrenda.codigoPrendaHuesped = prenda.codigoPrendaHuesped;
                        tblPrendaNRepartoValet.tblPrendaHuesped = tblPrendaNRepartoValet.tblPrenda;
                        delete tblPrendaNRepartoValet.tblPrenda;
                    } else if (repartoValetSel.idTipoRepartoValet === 2) {
                        //extra
                        tblPrendaNRepartoValet.idPrendaExtra = prenda.idPrendaExtra;
                        tblPrendaNRepartoValet.tblPrenda.codigoPrendaExtra = prenda.codigoPrendaExtra;
                        tblPrendaNRepartoValet.tblPrendaExtra = tblPrendaNRepartoValet.tblPrenda;
                        delete tblPrendaNRepartoValet.tblPrenda;
                    } else {
                        //ejecutivo
                        tblPrendaNRepartoValet.idPrendaEjecutivo = prenda.idPrendaEjecutivo;
                        tblPrendaNRepartoValet.tblPrenda.codigoPrendaEjecutivo = prenda.codigoPrendaEjecutivo;
                        tblPrendaNRepartoValet.tblPrendaEjecutivo = tblPrendaNRepartoValet.tblPrenda;
                        delete tblPrendaNRepartoValet.tblPrenda;
                    }

                    prendas.push(tblPrendaNRepartoValet);
                });
                return prendas;
            }

            function nuevoRepartoHuesped() {
                let gridsRepartos = [
                    "#dxDataGrid_nuevoRepartoHuesped",
                    "#dxDataGrid_nuevoRepartoExtra",
                    "#dxDataGrid_nuevoRepartoEjecutivo",
                ];
                $.each(gridsRepartos, function (index, item) {
                    let dxDataGrid_nuevoReparto = $(item).dxDataGrid("instance");
                    if (dxDataGrid_nuevoReparto != null) {
                        dxDataGrid_nuevoReparto.option("editing.allowDeleting", true);
                        dxDataGrid_nuevoReparto.option("editing.allowUpdating", true);

                        $(item).dxDataGrid("columnOption", "cantidadLavadoSeco", "cssClass", "dx-Cell_Editable");
                        $(item).dxDataGrid("columnOption", "cantidadPlanchado", "cssClass", "dx-Cell_Editable");
                        $(item).dxDataGrid("columnOption", "cantidadLavadoPlanchado", "cssClass", "dx-Cell_Editable");
                        $(item).dxDataGrid("columnOption", "cantidadTintoreria", "cssClass", "dx-Cell_Editable");
                    }
                });

                repartoValetSel.idRepartoValet = null;
                repartoValetSel.fecha = convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date());
                repartoValetSel.numHabitacion = null;
                repartoValetSel.tipoLavado = 1;
                repartoValetSel.idEntidad = null;
                repartoValetSel.observaciones = null;
                repartoValetSel.estado = null;
                repartoValetSel.precio = null;
                repartoValetSel.codigoRepartosValet = null;
                repartoValetSel.idLavanderia = LAVANDERIA.idLavanderia;
                repartoValetSel.idTipoRepartoValet = null;
                repartoValetSel.descuento = 0;

                let dxForm_nuevoReparto = $("#dxForm_nuevoReparto").dxForm("instance");
                if (dxForm_nuevoReparto != null) {
                    dxForm_nuevoReparto.updateData(repartoValetSel);
                    $.each(dxForm_nuevoReparto.option("formData"), function (index, item) {
                        if (dxForm_nuevoReparto.getEditor(index) != null)
                            dxForm_nuevoReparto.getEditor(index).option("isValid", true);
                    });
                }

                let dxTabPanel_tipoPrenda = $("#dxTabPanel_tipoPrenda").dxTabPanel("instance");
                if (dxTabPanel_tipoPrenda != null) dxTabPanel_tipoPrenda.resetOption("selectedIndex");

                repartoValetSel_bak = $.extend(true, {}, repartoValetSel);
            }
            nuevoRepartoHuesped();

            function set_nuevasPrendas() {
                repartoValetSel.tblPrendaHuespedNRepartoValet.length = 0;
                repartoValetSel.tblPrendaExtraNRepartoValet.length = 0;
                repartoValetSel.tblPrendaEjecutivoNRepartoValet.length = 0;

                if (repartoValetSel.idTipoRepartoValet === 1) {
                    var prendas = formatoPrendas_addCantidades(prendasHuesped_items[0]);
                    $.merge(repartoValetSel.tblPrendaHuespedNRepartoValet, prendas.slice());
                } else if (repartoValetSel.idTipoRepartoValet === 2) {
                    var prendas = formatoPrendas_addCantidades(prendasExtra_items[0]);
                    $.merge(repartoValetSel.tblPrendaExtraNRepartoValet, prendas.slice());
                } else {
                    var prendas = formatoPrendas_addCantidades(prendasEjecutivo_items[0]);
                    $.merge(repartoValetSel.tblPrendaEjecutivoNRepartoValet, prendas.slice());
                }

                repartoValetSel_bak = $.extend(true, {}, repartoValetSel);

                if ($("#dxForm_nuevoReparto").length > 0) {
                    let dxDataGrid_nuevoReparto =
                        repartoValetSel.idTipoRepartoValet === 1
                            ? $("#dxDataGrid_nuevoRepartoHuesped").dxDataGrid("instance")
                            : repartoValetSel.idTipoRepartoValet === 2
                            ? $("#dxDataGrid_nuevoRepartoExtra").dxDataGrid("instance")
                            : $("#dxDataGrid_nuevoRepartoEjecutivo").dxDataGrid("instance");
                    dxDataGrid_nuevoReparto.cancelEditData();
                    dxDataGrid_nuevoReparto.option("editing.allowDeleting", repartoValetSel.idRepartoValet);
                    dxDataGrid_nuevoReparto.refresh();
                }

                let index = 2;
                if (repartoValetSel.idTipoRepartoValet === 1) index = 1;
                $("#dxTabPanel_tipoPrenda")
                    .dxTabPanel("instance")
                    .option(
                        "selectedIndex",
                        repartoValetSel.idTipoRepartoValet == null
                            ? 0
                            : repartoValetSel.idTipoRepartoValet === 1
                            ? 1
                            : repartoValetSel.idTipoRepartoValet === 2
                            ? 2
                            : 3
                    );
            }

            $("#RepartosHuesped #dxContainer").dxBox({
                direction: "row",
                align: "space-around",
                crossAlign: "stretch",
                height: "100%",
                items: [
                    {
                        ratio: 7,
                        template: function (e, index, item) {
                            item.css("padding-right", 30);
                            return $("<div id='dxForm_nuevoReparto'>").dxForm({
                                labelLocation: "top",
                                width: "100%",
                                height: "100%",
                                colCount: 11,
                                formData: repartoValetSel,
                                scrollingEnabled: false,
                                deferRendering: false,
                                repaintChangesOnly: true,
                                items: [
                                    {
                                        itemType: "group",
                                        cssClass: "groups1",
                                        colSpan: 11,
                                        colCount: 11,
                                        width: "100%",
                                        items: [
                                            {
                                                dataField: "idEntidad",
                                                colSpan: 4,
                                                label: { text: getTrad("entidad") },
                                                editorType: "dxDropDownBox",
                                                editorOptions: {
                                                    elementAttr: { id: "dxDropDownBox_selCompaEnti" },
                                                    dataSource: datasource_datos_compaEnti_entidad.items(),
                                                    valueExpr: "idEntidad",
                                                    displayExpr: "denominacion",
                                                    placeholder: getTrad("seleccioneEntidad"),
                                                    deferRendering: false,
                                                    dropDownOptions: {
                                                        minWidth: 800,
                                                        minHeight: 500,
                                                        position: {
                                                            my: "center",
                                                            at: "center",
                                                            of: window,
                                                        },
                                                        closeOnOutsideClick: true,
                                                        showTitle: true,
                                                        title: getTrad("seleccioneEntidad"),
                                                        showCloseButton: true,
                                                        deferRendering: false,
                                                    },
                                                    contentTemplate: function (templateData, contentElement) {
                                                        return $("<div>").dxBox({
                                                            direction: "row",
                                                            align: "space-around",
                                                            crossAlign: "stretch",
                                                            height: "100%",
                                                            items: [
                                                                {
                                                                    ratio: 1,
                                                                    template: function (
                                                                        itemData,
                                                                        itemIndex,
                                                                        itemElement
                                                                    ) {
                                                                        itemElement.css("padding-right", "15px");

                                                                        // COMPAÑÍAS
                                                                        itemElement.append(
                                                                            $("<div/>").dxBox({
                                                                                direction: "col",
                                                                                align: "space-around",
                                                                                crossAlign: "stretch",
                                                                                height: "100%",
                                                                                width: "100%",
                                                                                items: [
                                                                                    {
                                                                                        baseSize: 40,
                                                                                        shrink: 0,
                                                                                        ratio: 0,
                                                                                        template: function () {
                                                                                            return $(
                                                                                                "<div class='font-size'>" +
                                                                                                    getTrad(
                                                                                                        "compañia"
                                                                                                    ) +
                                                                                                    "</div>"
                                                                                            );
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        ratio: 1,
                                                                                        template: function () {
                                                                                            return $(
                                                                                                "<div id ='dxList_compañia'>"
                                                                                            ).dxList({
                                                                                                dataSource:
                                                                                                    datasource_datos_compaEnti.items(),
                                                                                                focusStateEnabled: false,
                                                                                                selectionMode: "single",
                                                                                                height: "100%",
                                                                                                keyExpr: "idCompañia",
                                                                                                displayExpr:
                                                                                                    "denominacion",
                                                                                                pageLoadMode:
                                                                                                    "scrollBottom",
                                                                                                selectedItemKeys:
                                                                                                    repartoValetSel.tblEntidad
                                                                                                        ? [
                                                                                                              repartoValetSel
                                                                                                                  .tblEntidad
                                                                                                                  .idCompañia,
                                                                                                          ]
                                                                                                        : -1,
                                                                                                onContentReady:
                                                                                                    function (e) {
                                                                                                        e.component.selectItem(
                                                                                                            0
                                                                                                        ); // SELECCIONA EL ITEM 0 POR DEFECTO
                                                                                                    },
                                                                                                onSelectionChanged:
                                                                                                    function (e) {
                                                                                                        var dataSource =
                                                                                                            null;
                                                                                                        if (
                                                                                                            e.addedItems
                                                                                                                .length >
                                                                                                            0
                                                                                                        ) {
                                                                                                            var compañia =
                                                                                                                e
                                                                                                                    .addedItems[0];

                                                                                                            dataSource =
                                                                                                                compañia.tblEntidad;
                                                                                                        }

                                                                                                        var dxList_entidad =
                                                                                                            $(
                                                                                                                "#dxList_entidad"
                                                                                                            ).dxList(
                                                                                                                "instance"
                                                                                                            );
                                                                                                        dxList_entidad.option(
                                                                                                            "selectedItemKeys",
                                                                                                            []
                                                                                                        );
                                                                                                        dxList_entidad.option(
                                                                                                            "dataSource",
                                                                                                            dataSource
                                                                                                        );
                                                                                                    },
                                                                                                itemTemplate: function (
                                                                                                    data,
                                                                                                    index
                                                                                                ) {
                                                                                                    return $(
                                                                                                        "<div>"
                                                                                                    ).text(
                                                                                                        data.denominacion
                                                                                                    );
                                                                                                },
                                                                                            });
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            })
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    ratio: 1,
                                                                    template: function (
                                                                        itemData,
                                                                        itemIndex,
                                                                        itemElement
                                                                    ) {
                                                                        //ENTIDADES
                                                                        itemElement.append(
                                                                            $("<div/>").dxBox({
                                                                                direction: "col",
                                                                                align: "space-around",
                                                                                crossAlign: "stretch",
                                                                                height: "100%",
                                                                                width: "100%",
                                                                                items: [
                                                                                    {
                                                                                        baseSize: 40,
                                                                                        shrink: 0,
                                                                                        ratio: 0,
                                                                                        template: function () {
                                                                                            return $(
                                                                                                "<div class='font-size'>" +
                                                                                                    getTrad("entidad") +
                                                                                                    "</div>"
                                                                                            );
                                                                                        },
                                                                                    },
                                                                                    {
                                                                                        ratio: 1,
                                                                                        template: function () {
                                                                                            var dataSource = null;
                                                                                            var compa = $.grep(
                                                                                                datasource_datos_compaEnti.items(),
                                                                                                function (compa) {
                                                                                                    var idCompañia =
                                                                                                        repartoValetSel.tblEntidad
                                                                                                            ? [
                                                                                                                  repartoValetSel
                                                                                                                      .tblEntidad
                                                                                                                      .idCompañia,
                                                                                                              ]
                                                                                                            : -1;
                                                                                                    return (
                                                                                                        compa.idCompañia ===
                                                                                                        idCompañia
                                                                                                    );
                                                                                                }
                                                                                            );

                                                                                            if (compa.length > 0) {
                                                                                                dataSource =
                                                                                                    compa[0].tblEntidad;
                                                                                            }

                                                                                            return $(
                                                                                                "<div id='dxList_entidad'>"
                                                                                            ).dxList({
                                                                                                focusStateEnabled: false,
                                                                                                height: "100%",
                                                                                                dataSource: dataSource,
                                                                                                keyExpr: "idEntidad",
                                                                                                displayExpr:
                                                                                                    "denominacion",
                                                                                                pageLoadMode:
                                                                                                    "scrollBottom",
                                                                                                searchEnabled: true,
                                                                                                searchExpr:
                                                                                                    "denominacion",
                                                                                                selectionMode: "single",
                                                                                                selectedItemKeys:
                                                                                                    templateData.component.option(
                                                                                                        "value"
                                                                                                    ),
                                                                                                // EVENTOS
                                                                                                onSelectionChanged:
                                                                                                    function (e) {
                                                                                                        if (
                                                                                                            e.addedItems
                                                                                                                .length >
                                                                                                            0
                                                                                                        ) {
                                                                                                            templateData.component.option(
                                                                                                                "value",
                                                                                                                e
                                                                                                                    .addedItems[0]
                                                                                                                    .idEntidad
                                                                                                            );
                                                                                                            templateData.component.close();
                                                                                                        }
                                                                                                    },
                                                                                            });
                                                                                        },
                                                                                    },
                                                                                ],
                                                                            })
                                                                        );
                                                                    },
                                                                },
                                                            ],
                                                        });
                                                    },
                                                    onValueChanged: function (e) {
                                                        let dxList_compañia = $("#dxList_compañia").dxList("instance"),
                                                            dxList_entidad = $("#dxList_entidad").dxList("instance");

                                                        if (e.value != null) {
                                                            if (dxList_entidad.option("value") !== e.value)
                                                                dxList_entidad.option("selectedItemKeys", [e.value]);

                                                            let selectedIndex = $("#dxTabPanel_tipoPrenda")
                                                                .dxTabPanel("instance")
                                                                .option("selectedIndex");
                                                            if (
                                                                e.component.option("value") && // Entidad Seleccionada
                                                                repartoValetSel.idTipoRepartoValet != null && // Tipo de reparto seleccionado
                                                                selectedIndex === 0 && // Está en primera tab
                                                                repartoValetSel.idRepartoValet == null
                                                            )
                                                                // no tiene reparto seleccionado
                                                                set_nuevasPrendas();
                                                        } else {
                                                            $("#dxDropDownBox_selCompaEnti")
                                                                .dxDropDownBox("instance")
                                                                .open();
                                                            dxList_compañia.selectItem(0);
                                                            dxList_entidad.unselectAll();
                                                        }
                                                    },
                                                },
                                                validationRules: [
                                                    {
                                                        type: "required",
                                                        message: getTrad("seleccioneEntidad"),
                                                    },
                                                ],
                                            },
                                            {
                                                dataField: "numHabitacion",
                                                label: { text: getTrad("habitacion") },
                                                colSpan: 2,
                                                editorType: "dxTextBox",
                                                validationRules: [
                                                    {
                                                        type: "required",
                                                    },
                                                ],
                                            },
                                            {
                                                dataField: "tipoLavado",
                                                label: { text: getTrad("tipoLavado") },
                                                colSpan: 2,
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                    elementAttr: { id: "dxSelectBox_tipoLavado" },
                                                    dataSource: enum_tipoLavado,
                                                    valueExpr: "tipoLavado",
                                                    displayExpr: "denominacion",
                                                    width: "100%",
                                                    onValueChanged: function (e) {
                                                        let dxDataGrid_nuevoReparto =
                                                            repartoValetSel.idTipoRepartoValet === 1
                                                                ? $("#dxDataGrid_nuevoRepartoHuesped").dxDataGrid(
                                                                      "instance"
                                                                  )
                                                                : repartoValetSel.idTipoRepartoValet === 2
                                                                ? $("#dxDataGrid_nuevoRepartoExtra").dxDataGrid(
                                                                      "instance"
                                                                  )
                                                                : $("#dxDataGrid_nuevoRepartoEjecutivo").dxDataGrid(
                                                                      "instance"
                                                                  );
                                                        dxDataGrid_nuevoReparto.refresh();
                                                    },
                                                },
                                            },
                                            {
                                                dataField: "fecha",
                                                label: { text: getTrad("fecha") },
                                                colSpan: 2,
                                                editorType: "dxDateBox",
                                                editorOptions: {
                                                    width: "100%",
                                                    displayFormat: "dd/MM/yyyy",
                                                    min: "01/01/1900",
                                                },
                                            },
                                            {
                                                dataField: "descuento",
                                                label: { text: getTrad("descuento") },
                                                colSpan: 1,
                                                editorType: "dxNumberBox",
                                                editorOptions: {
                                                    format: "#0%",
                                                    min: 0,
                                                    max: 1,
                                                    step: 0.01,
                                                    onValueChanged: function (e) {
                                                        setTimeout(() => {
                                                            $("#dxDataGrid_nuevoRepartoHuesped")
                                                                .dxDataGrid("instance")
                                                                .repaint();
                                                        }, 200);
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        itemType: "tabbed",
                                        colSpan: 11,
                                        height: "100%",
                                        width: "100%",
                                        tabPanelOptions: {
                                            elementAttr: {
                                                id: "dxTabPanel_tipoPrenda",
                                                class: "no-header",
                                            },
                                            deferRendering: false,
                                            repaintChangesOnly: true,
                                            selectedIndex:
                                                repartoValetSel.idTipoRepartoValet == null
                                                    ? 0
                                                    : repartoValetSel.idTipoRepartoValet === 1
                                                    ? 1
                                                    : repartoValetSel.idTipoRepartoValet === 2
                                                    ? 2
                                                    : 3,
                                            onSelectionChanged: function (e) {
                                                if (
                                                    e.addedItems.length > 0 &&
                                                    e.component.option("selectedIndex") === 0
                                                ) {
                                                    $("#dxList_huespedExtra").dxList("instance").unselectAll();
                                                }
                                            },
                                        },
                                        tabs: [
                                            {
                                                height: "100%",
                                                colCount: 1,
                                                template: function (itemData, itemIndex, itemElement) {
                                                    itemElement.css("padding-top", 10);
                                                    itemElement.append(
                                                        $("<div id='dxList_huespedExtra' />").dxList({
                                                            dataSource: enum_huespedExtra,
                                                            height: "100%",
                                                            selectionMode: "single",
                                                            keyExpr: "value",
                                                            scrolling: { mode: "infinite" },
                                                            pageLoadMode: "scrollBottom",
                                                            validationError: false, // si lo tiene true, al seleccionar compa o enti pasará subtipo
                                                            onItemClick: function (e) {
                                                                repartoValetSel.idTipoRepartoValet = e.itemData.value;
                                                                if (
                                                                    $("#dxDropDownBox_selCompaEnti")
                                                                        .dxDropDownBox("instance")
                                                                        .option("value")
                                                                )
                                                                    set_nuevasPrendas();
                                                                else {
                                                                    $("#dxDropDownBox_selCompaEnti")
                                                                        .dxDropDownBox("instance")
                                                                        .open();
                                                                    e.component.option("validationError", true);
                                                                }
                                                            },
                                                            itemTemplate: function (data, index, element) {
                                                                var formHeight =
                                                                    $("#dxForm_nuevoReparto").height() -
                                                                    $(".groups1").height() -
                                                                    10; //10 - padding-top
                                                                element.parent().css("height", formHeight / 3);

                                                                var result = $("<div/>")
                                                                    .addClass("container_spanCentrado")
                                                                    .css("height", "100%");
                                                                $("<div class='font-size'>")
                                                                    .text(data.denominacion.toUpperCase())
                                                                    .appendTo(result);
                                                                return result;
                                                            },
                                                        })
                                                    );
                                                },
                                            },
                                            {
                                                height: "100%",
                                                colCount: 1,
                                                items: [
                                                    {
                                                        dataField: "tblPrendaHuespedNRepartoValet",
                                                        label: { visible: false },
                                                        template: function (itemData, itemElement) {
                                                            itemElement.append(
                                                                $(
                                                                    "<div id='dxDataGrid_nuevoRepartoHuesped' />"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource:
                                                                        itemData.component.option("formData")[
                                                                            itemData.dataField
                                                                        ],
                                                                    remoteOperations: {
                                                                        summary: false,
                                                                    },
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    height: $("#dxList_huespedExtra").height() - 8, // Para alinear con el grid anteriores
                                                                    keyExpr: "idPrendaHuesped",
                                                                    headerFilter: {
                                                                        visible: true,
                                                                    },
                                                                    paging: {
                                                                        pageSize: 50,
                                                                    },
                                                                    pager: {
                                                                        showPageSizeSelector: true,
                                                                        allowedPageSizes: [20, 50, 100],
                                                                        showInfo: true,
                                                                    },
                                                                    filterRow: { visible: false },
                                                                    editing: {
                                                                        mode: "batch",
                                                                        allowUpdating: true,
                                                                        allowDeleting: repartoValetSel.idRepartoValet,
                                                                        useIcons: true,
                                                                        selectTextOnEditStart: true,
                                                                        refreshMode: "reshape", // Para que reordene al insertar nueva prenda
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    loadPanel: { enabled: false },
                                                                    columns: [
                                                                        {
                                                                            dataField:
                                                                                "tblPrendaHuesped.codigoPrendaHuesped",
                                                                            caption: getTrad("codigo"),
                                                                            width: 85,
                                                                            allowEditing: false,
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            sortOrder: "asc",
                                                                        },
                                                                        {
                                                                            dataField: "tblPrendaHuesped.denominacion",
                                                                            caption: getTrad("denominacion"),
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoSeco",
                                                                            caption: getTrad("lavadoEjecutivo"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoLavadoEjecutivo");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadPlanchado",
                                                                            caption: getTrad("planchado"),
                                                                            dataType: "number",
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoPrecioPlanchado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoPlanchado",
                                                                            caption: getTrad("lavadoPlanchado"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoLavadoPlanchado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadTintoreria",
                                                                            caption: getTrad("tintoreriaSeco"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoTintoreria");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "precio",
                                                                            caption: getTrad("precio"),
                                                                            alignment: "center",
                                                                            dataType: "number",
                                                                            width: "12%",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 2,
                                                                            },
                                                                            editorOptions: {
                                                                                min: 0,
                                                                                max: 99999999,
                                                                            },
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                            calculateCellValue: function (rowData) {
                                                                                if (
                                                                                    typeof rowData.tblPrendaHuesped !==
                                                                                    "undefined"
                                                                                ) {
                                                                                    let tipoLavado =
                                                                                        repartoValetSel.tipoLavado;
                                                                                    var incLavExpress =
                                                                                            tipoLavado === 1
                                                                                                ? 0
                                                                                                : rowData
                                                                                                      .tblPrendaHuesped
                                                                                                      .incLavadoExpress *
                                                                                                  0.01,
                                                                                        cantLavado =
                                                                                            rowData.cantidadLavadoSeco,
                                                                                        cantPlanchado =
                                                                                            rowData.cantidadPlanchado,
                                                                                        cantLavPlan =
                                                                                            rowData.cantidadLavadoPlanchado,
                                                                                        cantTintoreria =
                                                                                            rowData.cantidadTintoreria,
                                                                                        precioLavado =
                                                                                            rowData.tblPrendaHuesped
                                                                                                .precioLavado,
                                                                                        precioPlanchado =
                                                                                            rowData.tblPrendaHuesped
                                                                                                .precioPlanchado,
                                                                                        precioLavPlan =
                                                                                            rowData.tblPrendaHuesped
                                                                                                .precioLavadoPlanchado,
                                                                                        precioTintoreria =
                                                                                            rowData.tblPrendaHuesped
                                                                                                .precioTintoreria;

                                                                                    return (
                                                                                        cantLavado *
                                                                                            (precioLavado +
                                                                                                precioLavado *
                                                                                                    incLavExpress) +
                                                                                        cantPlanchado *
                                                                                            (precioPlanchado +
                                                                                                precioPlanchado *
                                                                                                    incLavExpress) +
                                                                                        cantLavPlan *
                                                                                            (precioLavPlan +
                                                                                                precioLavPlan *
                                                                                                    incLavExpress) +
                                                                                        cantTintoreria *
                                                                                            (precioTintoreria +
                                                                                                precioTintoreria *
                                                                                                    incLavExpress)
                                                                                    );
                                                                                }
                                                                            },
                                                                        },
                                                                    ],
                                                                    summary: {
                                                                        recalculateWhileEditing: true,
                                                                        totalItems: [
                                                                            {
                                                                                column: "cantidadTintoreria",
                                                                                cssClass: "font-weight-normal",
                                                                                customizeText: function (data) {
                                                                                    if (
                                                                                        $("#dxForm_nuevoReparto").data(
                                                                                            "dxForm"
                                                                                        )
                                                                                    ) {
                                                                                        let descuento = $(
                                                                                            "#dxForm_nuevoReparto"
                                                                                        )
                                                                                            .dxForm("instance")
                                                                                            .option(
                                                                                                "formData"
                                                                                            ).descuento;

                                                                                        if (descuento && descuento > 0)
                                                                                            return "PRECIO";
                                                                                        else return "";
                                                                                    } else return "";
                                                                                },
                                                                            },
                                                                            {
                                                                                column: "cantidadTintoreria",
                                                                                cssClass: "font-weight-normal",
                                                                                customizeText: function (data) {
                                                                                    if (
                                                                                        $("#dxForm_nuevoReparto").data(
                                                                                            "dxForm"
                                                                                        )
                                                                                    ) {
                                                                                        let descuento = $(
                                                                                            "#dxForm_nuevoReparto"
                                                                                        )
                                                                                            .dxForm("instance")
                                                                                            .option(
                                                                                                "formData"
                                                                                            ).descuento;

                                                                                        if (descuento && descuento > 0)
                                                                                            return "DTO.";
                                                                                        else return "";
                                                                                    } else return "";
                                                                                },
                                                                            },
                                                                            {
                                                                                column: "cantidadTintoreria",
                                                                                displayFormat:
                                                                                    getTrad("total").toUpperCase(),
                                                                            },
                                                                            {
                                                                                column: "precio",
                                                                                summaryType: "sum",
                                                                                displayFormat: "{0}",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                                cssClass: "font-weight-normal",
                                                                                customizeText: function (data) {
                                                                                    if (
                                                                                        $("#dxForm_nuevoReparto").data(
                                                                                            "dxForm"
                                                                                        )
                                                                                    ) {
                                                                                        let descuento = $(
                                                                                            "#dxForm_nuevoReparto"
                                                                                        )
                                                                                            .dxForm("instance")
                                                                                            .option(
                                                                                                "formData"
                                                                                            ).descuento;

                                                                                        if (descuento && descuento > 0)
                                                                                            return formatNumber(
                                                                                                data.value,
                                                                                                2,
                                                                                                "currency",
                                                                                                LAVANDERIA.moneda
                                                                                            );
                                                                                        else return "";
                                                                                    } else return "";
                                                                                },
                                                                            },
                                                                            {
                                                                                column: "precio",
                                                                                summaryType: "sum",
                                                                                displayFormat: "{0}",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                                cssClass: "font-weight-normal",
                                                                                customizeText: function (data) {
                                                                                    if (
                                                                                        $("#dxForm_nuevoReparto").data(
                                                                                            "dxForm"
                                                                                        )
                                                                                    ) {
                                                                                        let descuento = $(
                                                                                            "#dxForm_nuevoReparto"
                                                                                        )
                                                                                            .dxForm("instance")
                                                                                            .option(
                                                                                                "formData"
                                                                                            ).descuento;
                                                                                        descuento = descuento
                                                                                            ? descuento
                                                                                            : 0;

                                                                                        if (descuento > 0)
                                                                                            return formatNumber(
                                                                                                data.value * descuento,
                                                                                                2,
                                                                                                "currency",
                                                                                                LAVANDERIA.moneda
                                                                                            );
                                                                                        else return "";
                                                                                    } else return "";
                                                                                },
                                                                            },
                                                                            {
                                                                                column: "precio",
                                                                                summaryType: "sum",
                                                                                displayFormat: "{0}",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                                customizeText: function (data) {
                                                                                    if (
                                                                                        $("#dxForm_nuevoReparto").data(
                                                                                            "dxForm"
                                                                                        )
                                                                                    ) {
                                                                                        let descuento = $(
                                                                                            "#dxForm_nuevoReparto"
                                                                                        )
                                                                                            .dxForm("instance")
                                                                                            .option(
                                                                                                "formData"
                                                                                            ).descuento;
                                                                                        descuento = descuento
                                                                                            ? descuento
                                                                                            : 0;
                                                                                        return formatNumber(
                                                                                            data.value -
                                                                                                data.value * descuento,
                                                                                            2,
                                                                                            "currency",
                                                                                            LAVANDERIA.moneda
                                                                                        );
                                                                                    } else return "";
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                    onContentReady: function (e) {
                                                                        e.component.columnOption("command:edit", {
                                                                            width: 45,
                                                                        });
                                                                    },
                                                                    onEditingStart: function (e) {
                                                                        rowEditing = e;
                                                                    },
                                                                    onCellPrepared: function (e) {
                                                                        set_dxDataGrid_cellPrepared(e);
                                                                    },
                                                                    onFocusedCellChanged: function (e) {
                                                                        $("#dxPopover_gridPrendas")
                                                                            .dxPopover("instance")
                                                                            .hide();
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        //Modificar los iconos por defecto de guardar y cancelar del batchEdti
                                                                        $.each(
                                                                            e.toolbarOptions.items,
                                                                            function (index, item) {
                                                                                if (item.name === "saveButton") {
                                                                                    item.visible = false;
                                                                                } else {
                                                                                    item.options.tooltipText =
                                                                                        item.options.hint;
                                                                                    item.options.hint = null;

                                                                                    tooltipControl_creacion(
                                                                                        item,
                                                                                        item.options.tooltipText,
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("nuevoReparto"),
                                                                                    type: "normal",
                                                                                    elementAttr: {
                                                                                        id: "btnNuevoReparto",
                                                                                    },
                                                                                    onClick: function (e) {
                                                                                        var dxDataGrid_repartosAnteriores =
                                                                                            $(
                                                                                                "#dxDataGrid_repartosAnteriores"
                                                                                            ).dxDataGrid("instance");
                                                                                        if (dxForm_hasChanges()) {
                                                                                            dxMensajePregunta(
                                                                                                getTrad(
                                                                                                    "preg_PerderCambios"
                                                                                                ),
                                                                                                [
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "aceptar"
                                                                                                        ),
                                                                                                        function () {
                                                                                                            nuevoRepartoHuesped();
                                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                                "selectedRowKeys",
                                                                                                                []
                                                                                                            );
                                                                                                        },
                                                                                                        "danger",
                                                                                                    ],
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "cancelar"
                                                                                                        ),
                                                                                                        function () {},
                                                                                                    ],
                                                                                                ]
                                                                                            );
                                                                                        } else {
                                                                                            nuevoRepartoHuesped();
                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                "selectedRowKeys",
                                                                                                []
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("añadirPrenda"),
                                                                                    type: "success",
                                                                                    visible:
                                                                                        !repartoValetSel.idRepartoValet
                                                                                            ? false
                                                                                            : true,
                                                                                    onClick: function () {
                                                                                        // Se difine aquí porque tiene que encontrar la función formatoPrendas_addCantidades
                                                                                        var añadirPrendas_filtro =
                                                                                            $.grep(
                                                                                                datasource_tblPrendaHuesped.items(),
                                                                                                function (v) {
                                                                                                    return (
                                                                                                        $(
                                                                                                            "#dxDataGrid_nuevoRepartoHuesped"
                                                                                                        )
                                                                                                            .dxDataGrid(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .getRowIndexByKey(
                                                                                                                v.idPrendaHuesped
                                                                                                            ) === -1
                                                                                                    );
                                                                                                }
                                                                                            );

                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasHuesped"
                                                                                        )
                                                                                            .dxDataGrid("instance")
                                                                                            .option(
                                                                                                "dataSource",
                                                                                                formatoPrendas_addCantidades(
                                                                                                    añadirPrendas_filtro
                                                                                                )
                                                                                            );
                                                                                        $(
                                                                                            "#dxPopup_añadirPrendasHuesped"
                                                                                        )
                                                                                            .dxPopup("instance")
                                                                                            .show();
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                locateInMenu: "auto",
                                                                                options: {
                                                                                    text: getTrad("imprimir"),
                                                                                    icon: " icon_Impresora",
                                                                                    visible:
                                                                                        repartoValetSel.idRepartoValet !=
                                                                                        null,
                                                                                    onClick: function (e) {
                                                                                        if (!dxForm_hasChanges()) {
                                                                                            var params = {
                                                                                                idRepartoValet:
                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                idEntidad:
                                                                                                    repartoValetSel.idEntidad,
                                                                                                idLavanderia:
                                                                                                    LAVANDERIA.idLavanderia,
                                                                                                idioma: "es",
                                                                                            };
                                                                                            COMPONENT.setParams(params);
                                                                                        } else {
                                                                                            notify({
                                                                                                message:
                                                                                                    getTrad(
                                                                                                        "alerta_GuardarParaImprimir"
                                                                                                    ),
                                                                                                type: "error",
                                                                                                displayTime: "1500",
                                                                                                closeOnClick: true,
                                                                                            });
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                template: function (
                                                                                    itemData,
                                                                                    itemIndex,
                                                                                    itemElement
                                                                                ) {
                                                                                    itemElement.append(
                                                                                        $("<div />").dxButton({
                                                                                            width: !repartoValetSel.idRepartoValet
                                                                                                ? "100%"
                                                                                                : "36px",
                                                                                            icon: !repartoValetSel.idRepartoValet
                                                                                                ? "check"
                                                                                                : "save",
                                                                                            type: !repartoValetSel.idRepartoValet
                                                                                                ? "success"
                                                                                                : "normal",
                                                                                            text: !repartoValetSel.idRepartoValet
                                                                                                ? getTrad(
                                                                                                      "realizarReparto"
                                                                                                  )
                                                                                                : null,
                                                                                            elementAttr: {
                                                                                                id: !repartoValetSel.idRepartoValet
                                                                                                    ? ""
                                                                                                    : "dxButton_guardarRepartoHuesped",
                                                                                            },
                                                                                            tooltipText:
                                                                                                getTrad(
                                                                                                    "guardarReparto"
                                                                                                ),
                                                                                            onInitialized: function (
                                                                                                e
                                                                                            ) {
                                                                                                if (
                                                                                                    repartoValetSel.idRepartoValet
                                                                                                )
                                                                                                    tooltipControl_creacion(
                                                                                                        e.element,
                                                                                                        e.component.option(
                                                                                                            "tooltipText"
                                                                                                        )
                                                                                                    );
                                                                                            },
                                                                                            onClick: function (button) {
                                                                                                if (
                                                                                                    dxForm_hasChanges()
                                                                                                ) {
                                                                                                    var form =
                                                                                                            $(
                                                                                                                "#dxForm_nuevoReparto"
                                                                                                            ).dxForm(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        formData =
                                                                                                            form.option(
                                                                                                                "formData"
                                                                                                            ),
                                                                                                        dxDataGrid_nuevoRepartoHuesped =
                                                                                                            $(
                                                                                                                "#dxDataGrid_nuevoRepartoHuesped"
                                                                                                            ).dxDataGrid(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        rows_nuevoReparto =
                                                                                                            dxDataGrid_nuevoRepartoHuesped.getVisibleRows(),
                                                                                                        prendasVaciasFiltradas =
                                                                                                            filtrarPrendasVacias(
                                                                                                                rows_nuevoReparto
                                                                                                            );

                                                                                                    if (
                                                                                                        formData &&
                                                                                                        form.validate()
                                                                                                            .isValid &&
                                                                                                        prendasVaciasFiltradas.length >
                                                                                                            0
                                                                                                    ) {
                                                                                                        dxDataGrid_nuevoRepartoHuesped.saveEditData();
                                                                                                        var data =
                                                                                                            tratarDatos(
                                                                                                                $.extend(
                                                                                                                    true,
                                                                                                                    {},
                                                                                                                    repartoValetSel
                                                                                                                )
                                                                                                            );

                                                                                                        if (
                                                                                                            !repartoValetSel.idRepartoValet
                                                                                                        ) {
                                                                                                            delete data.idRepartoValet;
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .insert(
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function (
                                                                                                                        reparto
                                                                                                                    ) {
                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroInsertado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "1500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );

                                                                                                                        datasource_tblRepartosValet_anteriores
                                                                                                                            .reload()
                                                                                                                            .done(
                                                                                                                                function () {
                                                                                                                                    repartoValetSel_bak =
                                                                                                                                        $.extend(
                                                                                                                                            true,
                                                                                                                                            {},
                                                                                                                                            repartoValetSel
                                                                                                                                        );
                                                                                                                                    $(
                                                                                                                                        "#dxDataGrid_repartosAnteriores"
                                                                                                                                    )
                                                                                                                                        .dxDataGrid(
                                                                                                                                            "instance"
                                                                                                                                        )
                                                                                                                                        .selectRows(
                                                                                                                                            [
                                                                                                                                                reparto.idRepartoValet,
                                                                                                                                            ],
                                                                                                                                            false
                                                                                                                                        );
                                                                                                                                }
                                                                                                                            );
                                                                                                                    }
                                                                                                                );
                                                                                                        } else {
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .update(
                                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function () {
                                                                                                                        repartoValetSel_bak =
                                                                                                                            $.extend(
                                                                                                                                true,
                                                                                                                                {},
                                                                                                                                repartoValetSel
                                                                                                                            );
                                                                                                                        datasource_tblRepartosValet_anteriores.reload();

                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroActualizado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "2500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                );
                                                                                                        }
                                                                                                    } else if (
                                                                                                        prendasVaciasFiltradas.length ===
                                                                                                        0
                                                                                                    ) {
                                                                                                        notify({
                                                                                                            message:
                                                                                                                getTrad(
                                                                                                                    "alerta_cantidadRepartir"
                                                                                                                ),
                                                                                                            type: "error",
                                                                                                            displayTime:
                                                                                                                "2500",
                                                                                                            closeOnClick: true,
                                                                                                        });
                                                                                                    }
                                                                                                } else {
                                                                                                    notify({
                                                                                                        message:
                                                                                                            getTrad(
                                                                                                                "alerta_SinCambios"
                                                                                                            ),
                                                                                                        type: "error",
                                                                                                        displayTime:
                                                                                                            "2500",
                                                                                                        closeOnClick: true,
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                })
                                                            );
                                                            itemElement.append(
                                                                $("<div id='dxPopup_añadirPrendasHuesped' />").dxPopup({
                                                                    showTitle: true,
                                                                    title: getTrad("añadirPrenda"),
                                                                    height: 585,
                                                                    width: 800,
                                                                    deferRendering: false,
                                                                    contentTemplate: function () {
                                                                        return $(
                                                                            "<div id='dxDataGrid_añadirPrendasHuesped' />"
                                                                        ).dxDataGrid({
                                                                            //Propiedades
                                                                            height: "100%",
                                                                            columnsAutoWidth: true,
                                                                            headerFilter: {
                                                                                visible: true,
                                                                            },
                                                                            filterRow: {
                                                                                visible: true,
                                                                                applyFilter: "auto",
                                                                            },
                                                                            editing: {
                                                                                mode: "batch",
                                                                                refreshMode: "repaint",
                                                                                allowUpdating: true,
                                                                                allowDeleting: false,
                                                                                useIcons: true,
                                                                                selectTextOnEditStart: true,
                                                                            },
                                                                            keyboardNavigation: {
                                                                                enterKeyAction: "moveFocus",
                                                                                enterKeyDirection: "row",
                                                                                editOnKeyPress: true,
                                                                            },
                                                                            paging: {
                                                                                enabled: false,
                                                                            },
                                                                            keyExpr: "idPrendaHuesped",
                                                                            loadPanel: { enabled: false },
                                                                            columns: [
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaHuesped.codigoPrendaHuesped",
                                                                                    caption: getTrad("codigo_abr"),
                                                                                    sortOrder: "asc",
                                                                                    alignment: "center",
                                                                                    allowHeaderFiltering: false,
                                                                                    width: 110,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaHuesped.denominacion",
                                                                                    caption: getTrad("denominacion"),
                                                                                    alignment: "left",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadLavadoSeco",
                                                                                    caption: getTrad("lavadoEjecutivo"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoEjecutivo"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadPlanchado",
                                                                                    caption: getTrad("planchado"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoPrecioPlanchado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "cantidadLavadoPlanchado",
                                                                                    caption: getTrad("lavadoPlanchado"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoPlanchado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadTintoreria",
                                                                                    caption: getTrad("tintoreriaSeco"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoTintoreria"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                            ],
                                                                            //EVENTOS
                                                                            onEditingStart: function (e) {
                                                                                rowEditing = e;
                                                                            },
                                                                            onCellPrepared: function (e) {
                                                                                set_dxDataGrid_cellPrepared(e);
                                                                            },
                                                                            onFocusedCellChanged: function (e) {
                                                                                $("#dxPopover_gridPrendas")
                                                                                    .dxPopover("instance")
                                                                                    .hide();
                                                                            },
                                                                            onToolbarPreparing: function (e) {
                                                                                $.each(
                                                                                    e.toolbarOptions.items,
                                                                                    function (_, item) {
                                                                                        if (
                                                                                            item.name === "saveButton"
                                                                                        ) {
                                                                                            item.visible = false;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            },
                                                                            showColumnLines: false,
                                                                            showRowLines: true,
                                                                            rowAlternationEnabled: true,
                                                                        });
                                                                    },
                                                                    toolbarItems: [
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                text: getTrad("guardar"),
                                                                                type: "success",
                                                                                onClick: function (e) {
                                                                                    var dxDataGrid_añadirPrendasHuesped =
                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasHuesped"
                                                                                        ).dxDataGrid("instance");
                                                                                    dxDataGrid_añadirPrendasHuesped.saveEditData();

                                                                                    $.merge(
                                                                                        repartoValetSel.tblPrendaHuespedNRepartoValet,
                                                                                        filtrarPrendasVacias(
                                                                                            dxDataGrid_añadirPrendasHuesped.option(
                                                                                                "dataSource"
                                                                                            )
                                                                                        )
                                                                                    );
                                                                                    $("#dxForm_nuevoReparto")
                                                                                        .dxForm("instance")
                                                                                        .updateData(repartoValetSel);
                                                                                    $("#dxDataGrid_nuevoRepartoHuesped")
                                                                                        .dxDataGrid("instance")
                                                                                        .refresh();
                                                                                    $("#dxPopup_añadirPrendasHuesped")
                                                                                        .dxPopup("instance")
                                                                                        .hide();
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                    //EVENTOS
                                                                    onHiding: function (args) {
                                                                        var dxDataGrid_añadirPrendasHuesped = $(
                                                                            "#dxDataGrid_añadirPrendasHuesped"
                                                                        ).dxDataGrid("instance");
                                                                        dxDataGrid_añadirPrendasHuesped.cancelEditData();
                                                                        dxDataGrid_añadirPrendasHuesped
                                                                            .getScrollable()
                                                                            .scrollTo(0);
                                                                        dxDataGrid_añadirPrendasHuesped.clearFilter();
                                                                    },
                                                                })
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                height: "100%",
                                                colCount: 1,
                                                items: [
                                                    {
                                                        dataField: "tblPrendaExtraNRepartoValet",
                                                        label: { visible: false },
                                                        template: function (itemData, itemElement) {
                                                            itemElement.append(
                                                                $(
                                                                    "<div id='dxDataGrid_nuevoRepartoExtra' />"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource:
                                                                        itemData.component.option("formData")[
                                                                            itemData.dataField
                                                                        ],
                                                                    remoteOperations: {
                                                                        summary: false,
                                                                    },
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    height: 694,
                                                                    keyExpr: "idPrendaExtra",
                                                                    headerFilter: {
                                                                        visible: true,
                                                                    },
                                                                    paging: {
                                                                        pageSize: 50,
                                                                    },
                                                                    pager: {
                                                                        showPageSizeSelector: true,
                                                                        allowedPageSizes: [20, 50, 100],
                                                                        showInfo: true,
                                                                    },
                                                                    filterRow: { visible: false },
                                                                    editing: {
                                                                        mode: "batch",
                                                                        allowUpdating: true,
                                                                        allowDeleting: repartoValetSel.idRepartoValet,
                                                                        useIcons: true,
                                                                        selectTextOnEditStart: true,
                                                                        refreshMode: "reshape", // Para que reordene al insertar nueva prenda
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    loadPanel: { enabled: false },
                                                                    columns: [
                                                                        {
                                                                            dataField:
                                                                                "tblPrendaExtra.codigoPrendaExtra",
                                                                            caption: getTrad("codigo"),
                                                                            width: 85,
                                                                            allowEditing: false,
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            sortOrder: "asc",
                                                                        },
                                                                        {
                                                                            dataField: "tblPrendaExtra.denominacion",
                                                                            caption: getTrad("denominacion"),
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoSeco",
                                                                            caption: getTrad("lavadoEjecutivo"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoLavadoEjecutivo");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadPlanchado",
                                                                            caption: getTrad("planchado"),
                                                                            dataType: "number",
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoPrecioPlanchado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoPlanchado",
                                                                            caption: getTrad("lavadoPlanchado"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoLavadoPlanchado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadTintoreria",
                                                                            caption: getTrad("tintoreriaSeco"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                let texto =
                                                                                    getTrad("prendaNoTintoreria");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "precio",
                                                                            caption: getTrad("precio"),
                                                                            alignment: "center",
                                                                            dataType: "number",
                                                                            width: "12%",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 2,
                                                                            },
                                                                            editorOptions: {
                                                                                min: 0,
                                                                                max: 99999999,
                                                                            },
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                            calculateCellValue: function (rowData) {
                                                                                if (
                                                                                    typeof rowData.tblPrendaExtra !==
                                                                                    "undefined"
                                                                                ) {
                                                                                    let tipoLavado =
                                                                                        repartoValetSel.tipoLavado;
                                                                                    var incLavExpress =
                                                                                            tipoLavado === 1
                                                                                                ? 0
                                                                                                : rowData.tblPrendaExtra
                                                                                                      .incLavadoExpress *
                                                                                                  0.01,
                                                                                        cantLavado =
                                                                                            rowData.cantidadLavadoSeco,
                                                                                        cantPlanchado =
                                                                                            rowData.cantidadPlanchado,
                                                                                        cantLavPlan =
                                                                                            rowData.cantidadLavadoPlanchado,
                                                                                        cantTintoreria =
                                                                                            rowData.cantidadTintoreria,
                                                                                        precioLavado =
                                                                                            rowData.tblPrendaExtra
                                                                                                .precioLavado,
                                                                                        precioPlanchado =
                                                                                            rowData.tblPrendaExtra
                                                                                                .precioPlanchado,
                                                                                        precioLavPlan =
                                                                                            rowData.tblPrendaExtra
                                                                                                .precioLavadoPlanchado,
                                                                                        precioTintoreria =
                                                                                            rowData.tblPrendaExtra
                                                                                                .precioTintoreria;

                                                                                    return (
                                                                                        cantLavado *
                                                                                            (precioLavado +
                                                                                                precioLavado *
                                                                                                    incLavExpress) +
                                                                                        cantPlanchado *
                                                                                            (precioPlanchado +
                                                                                                precioPlanchado *
                                                                                                    incLavExpress) +
                                                                                        cantLavPlan *
                                                                                            (precioLavPlan +
                                                                                                precioLavPlan *
                                                                                                    incLavExpress) +
                                                                                        cantTintoreria *
                                                                                            (precioTintoreria +
                                                                                                precioTintoreria *
                                                                                                    incLavExpress)
                                                                                    );
                                                                                }
                                                                            },
                                                                        },
                                                                    ],
                                                                    summary: {
                                                                        recalculateWhileEditing: true,
                                                                        totalItems: [
                                                                            {
                                                                                column: "tblPrendaExtra.codigoPrendaExtra",
                                                                                displayFormat:
                                                                                    getTrad("total").toUpperCase(),
                                                                            },
                                                                            {
                                                                                column: "precio",
                                                                                summaryType: "sum",
                                                                                displayFormat: "{0}",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                    onContentReady: function (e) {
                                                                        e.component.columnOption("command:edit", {
                                                                            width: 45,
                                                                        });
                                                                    },
                                                                    onEditingStart: function (e) {
                                                                        rowEditing = e;
                                                                    },
                                                                    onCellPrepared: function (e) {
                                                                        set_dxDataGrid_cellPrepared(e);
                                                                    },
                                                                    onFocusedCellChanged: function (e) {
                                                                        $("#dxPopover_gridPrendas")
                                                                            .dxPopover("instance")
                                                                            .hide();
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        //Modificar los iconos por defecto de guardar y cancelar del batchEdti
                                                                        $.each(
                                                                            e.toolbarOptions.items,
                                                                            function (index, item) {
                                                                                if (item.name === "saveButton") {
                                                                                    item.visible = false;
                                                                                } else {
                                                                                    item.options.tooltipText =
                                                                                        item.options.hint;
                                                                                    item.options.hint = null;

                                                                                    tooltipControl_creacion(
                                                                                        item,
                                                                                        item.options.tooltipText,
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("nuevoReparto"),
                                                                                    type: "normal",
                                                                                    elementAttr: {
                                                                                        id: "btnNuevoReparto",
                                                                                    },
                                                                                    onClick: function (e) {
                                                                                        var dxDataGrid_repartosAnteriores =
                                                                                            $(
                                                                                                "#dxDataGrid_repartosAnteriores"
                                                                                            ).dxDataGrid("instance");
                                                                                        if (dxForm_hasChanges()) {
                                                                                            dxMensajePregunta(
                                                                                                getTrad(
                                                                                                    "preg_PerderCambios"
                                                                                                ),
                                                                                                [
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "aceptar"
                                                                                                        ),
                                                                                                        function () {
                                                                                                            nuevoRepartoHuesped();
                                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                                "selectedRowKeys",
                                                                                                                []
                                                                                                            );
                                                                                                        },
                                                                                                        "danger",
                                                                                                    ],
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "cancelar"
                                                                                                        ),
                                                                                                        function () {},
                                                                                                    ],
                                                                                                ]
                                                                                            );
                                                                                        } else {
                                                                                            nuevoRepartoHuesped();
                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                "selectedRowKeys",
                                                                                                []
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("añadirPrenda"),
                                                                                    type: "success",
                                                                                    visible:
                                                                                        !repartoValetSel.idRepartoValet
                                                                                            ? false
                                                                                            : true,
                                                                                    onClick: function () {
                                                                                        // Se difine aquí porque tiene que encontrar la función formatoPrendas_addCantidades
                                                                                        var añadirPrendas_filtro =
                                                                                            $.grep(
                                                                                                datasource_tblPrendaExtra.items(),
                                                                                                function (v) {
                                                                                                    return (
                                                                                                        $(
                                                                                                            "#dxDataGrid_nuevoRepartoExtra"
                                                                                                        )
                                                                                                            .dxDataGrid(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .getRowIndexByKey(
                                                                                                                v.idPrendaExtra
                                                                                                            ) === -1
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasExtra"
                                                                                        )
                                                                                            .dxDataGrid("instance")
                                                                                            .option(
                                                                                                "dataSource",
                                                                                                formatoPrendas_addCantidades(
                                                                                                    añadirPrendas_filtro
                                                                                                )
                                                                                            );
                                                                                        $("#dxPopup_añadirPrendasExtra")
                                                                                            .dxPopup("instance")
                                                                                            .show();
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                locateInMenu: "auto",
                                                                                options: {
                                                                                    text: getTrad("imprimir"),
                                                                                    icon: " icon_Impresora",
                                                                                    visible:
                                                                                        repartoValetSel.idRepartoValet !=
                                                                                        null,
                                                                                    onClick: function (e) {
                                                                                        if (!dxForm_hasChanges()) {
                                                                                            var params = {
                                                                                                idRepartoValet:
                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                idEntidad:
                                                                                                    repartoValetSel.idEntidad,
                                                                                                idLavanderia:
                                                                                                    LAVANDERIA.idLavanderia,
                                                                                                idioma: "es",
                                                                                            };

                                                                                            COMPONENT.setParams(params);
                                                                                        } else {
                                                                                            notify({
                                                                                                message:
                                                                                                    getTrad(
                                                                                                        "alerta_GuardarParaImprimir"
                                                                                                    ),
                                                                                                type: "error",
                                                                                                displayTime: "1500",
                                                                                                closeOnClick: true,
                                                                                            });
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                template: function (
                                                                                    itemData,
                                                                                    itemIndex,
                                                                                    itemElement
                                                                                ) {
                                                                                    itemElement.append(
                                                                                        $("<div />").dxButton({
                                                                                            width: !repartoValetSel.idRepartoValet
                                                                                                ? "100%"
                                                                                                : "36px",
                                                                                            icon: !repartoValetSel.idRepartoValet
                                                                                                ? "check"
                                                                                                : "save",
                                                                                            type: !repartoValetSel.idRepartoValet
                                                                                                ? "success"
                                                                                                : "normal",
                                                                                            text: !repartoValetSel.idRepartoValet
                                                                                                ? getTrad(
                                                                                                      "realizarReparto"
                                                                                                  )
                                                                                                : null,
                                                                                            elementAttr: {
                                                                                                id: !repartoValetSel.idRepartoValet
                                                                                                    ? ""
                                                                                                    : "dxButton_guardarRepartoExtra",
                                                                                            },
                                                                                            tooltipText:
                                                                                                getTrad(
                                                                                                    "guardarReparto"
                                                                                                ),
                                                                                            onInitialized: function (
                                                                                                e
                                                                                            ) {
                                                                                                if (
                                                                                                    repartoValetSel.idRepartoValet
                                                                                                )
                                                                                                    tooltipControl_creacion(
                                                                                                        e.element,
                                                                                                        e.component.option(
                                                                                                            "tooltipText"
                                                                                                        )
                                                                                                    );
                                                                                            },
                                                                                            onClick: function (button) {
                                                                                                if (
                                                                                                    dxForm_hasChanges()
                                                                                                ) {
                                                                                                    var form =
                                                                                                            $(
                                                                                                                "#dxForm_nuevoReparto"
                                                                                                            ).dxForm(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        formData =
                                                                                                            form.option(
                                                                                                                "formData"
                                                                                                            ),
                                                                                                        dxDataGrid_nuevoRepartoExtra =
                                                                                                            $(
                                                                                                                "#dxDataGrid_nuevoRepartoExtra"
                                                                                                            ).dxDataGrid(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        rows_nuevoReparto =
                                                                                                            dxDataGrid_nuevoRepartoExtra.getVisibleRows(),
                                                                                                        prendasVaciasFiltradas =
                                                                                                            filtrarPrendasVacias(
                                                                                                                rows_nuevoReparto
                                                                                                            );
                                                                                                    if (
                                                                                                        formData &&
                                                                                                        form.validate()
                                                                                                            .isValid &&
                                                                                                        prendasVaciasFiltradas.length >
                                                                                                            0
                                                                                                    ) {
                                                                                                        dxDataGrid_nuevoRepartoExtra.saveEditData();
                                                                                                        var data =
                                                                                                            tratarDatos(
                                                                                                                $.extend(
                                                                                                                    true,
                                                                                                                    {},
                                                                                                                    repartoValetSel
                                                                                                                )
                                                                                                            );

                                                                                                        if (
                                                                                                            !repartoValetSel.idRepartoValet
                                                                                                        ) {
                                                                                                            delete data.idRepartoValet;
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .insert(
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function (
                                                                                                                        reparto
                                                                                                                    ) {
                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroInsertado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "1500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );

                                                                                                                        datasource_tblRepartosValet_anteriores
                                                                                                                            .reload()
                                                                                                                            .done(
                                                                                                                                function () {
                                                                                                                                    repartoValetSel_bak =
                                                                                                                                        $.extend(
                                                                                                                                            true,
                                                                                                                                            {},
                                                                                                                                            repartoValetSel
                                                                                                                                        );
                                                                                                                                    $(
                                                                                                                                        "#dxDataGrid_repartosAnteriores"
                                                                                                                                    )
                                                                                                                                        .dxDataGrid(
                                                                                                                                            "instance"
                                                                                                                                        )
                                                                                                                                        .selectRows(
                                                                                                                                            [
                                                                                                                                                reparto.idRepartoValet,
                                                                                                                                            ],
                                                                                                                                            false
                                                                                                                                        );
                                                                                                                                }
                                                                                                                            );
                                                                                                                    }
                                                                                                                );
                                                                                                        } else {
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .update(
                                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function () {
                                                                                                                        repartoValetSel_bak =
                                                                                                                            $.extend(
                                                                                                                                true,
                                                                                                                                {},
                                                                                                                                repartoValetSel
                                                                                                                            );
                                                                                                                        datasource_tblRepartosValet_anteriores.reload();

                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroActualizado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "2500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                );
                                                                                                        }
                                                                                                    } else if (
                                                                                                        prendasVaciasFiltradas.length ===
                                                                                                        0
                                                                                                    ) {
                                                                                                        notify({
                                                                                                            message:
                                                                                                                getTrad(
                                                                                                                    "alerta_cantidadRepartir"
                                                                                                                ),
                                                                                                            type: "error",
                                                                                                            displayTime:
                                                                                                                "2500",
                                                                                                            closeOnClick: true,
                                                                                                        });
                                                                                                    }
                                                                                                } else {
                                                                                                    notify({
                                                                                                        message:
                                                                                                            getTrad(
                                                                                                                "alerta_SinCambios"
                                                                                                            ),
                                                                                                        type: "error",
                                                                                                        displayTime:
                                                                                                            "2500",
                                                                                                        closeOnClick: true,
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                })
                                                            );
                                                            itemElement.append(
                                                                $("<div id='dxPopup_añadirPrendasExtra' />").dxPopup({
                                                                    showTitle: true,
                                                                    title: getTrad("añadirPrenda"),
                                                                    height: 585,
                                                                    width: 800,
                                                                    deferRendering: false,
                                                                    contentTemplate: function () {
                                                                        return $(
                                                                            "<div id='dxDataGrid_añadirPrendasExtra' />"
                                                                        ).dxDataGrid({
                                                                            //Propiedades
                                                                            height: "100%",
                                                                            columnsAutoWidth: true,
                                                                            headerFilter: {
                                                                                visible: true,
                                                                            },
                                                                            filterRow: {
                                                                                visible: true,
                                                                                applyFilter: "auto",
                                                                            },
                                                                            editing: {
                                                                                mode: "batch",
                                                                                refreshMode: "repaint",
                                                                                allowUpdating: true,
                                                                                allowDeleting: false,
                                                                                useIcons: true,
                                                                                selectTextOnEditStart: true,
                                                                            },
                                                                            keyboardNavigation: {
                                                                                enterKeyAction: "moveFocus",
                                                                                enterKeyDirection: "row",
                                                                                editOnKeyPress: true,
                                                                            },
                                                                            paging: {
                                                                                enabled: false,
                                                                            },
                                                                            keyExpr: "idPrendaExtra",
                                                                            loadPanel: { enabled: false },
                                                                            columns: [
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaExtra.codigoPrendaExtra",
                                                                                    caption: getTrad("codigo_abr"),
                                                                                    sortOrder: "asc",
                                                                                    alignment: "center",
                                                                                    allowHeaderFiltering: false,
                                                                                    width: 110,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaExtra.denominacion",
                                                                                    caption: getTrad("denominacion"),
                                                                                    alignment: "left",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadLavadoSeco",
                                                                                    caption: getTrad("lavadoEjecutivo"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoEjecutivo"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadPlanchado",
                                                                                    caption: getTrad("planchado"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoPrecioPlanchado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "cantidadLavadoPlanchado",
                                                                                    caption: getTrad("lavadoPlanchado"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoPlanchado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadTintoreria",
                                                                                    caption: getTrad("tintoreriaSeco"),
                                                                                    width: "18%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        let texto =
                                                                                            getTrad(
                                                                                                "prendaNoTintoreria"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                            ],
                                                                            //EVENTOS
                                                                            onEditingStart: function (e) {
                                                                                rowEditing = e;
                                                                            },
                                                                            onCellPrepared: function (e) {
                                                                                set_dxDataGrid_cellPrepared(e);
                                                                            },
                                                                            onFocusedCellChanged: function (e) {
                                                                                $("#dxPopover_gridPrendas")
                                                                                    .dxPopover("instance")
                                                                                    .hide();
                                                                            },
                                                                            onToolbarPreparing: function (e) {
                                                                                $.each(
                                                                                    e.toolbarOptions.items,
                                                                                    function (_, item) {
                                                                                        if (
                                                                                            item.name === "saveButton"
                                                                                        ) {
                                                                                            item.visible = false;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            },
                                                                            showColumnLines: false,
                                                                            showRowLines: true,
                                                                            rowAlternationEnabled: true,
                                                                        });
                                                                    },
                                                                    toolbarItems: [
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                text: getTrad("guardar"),
                                                                                type: "success",
                                                                                onClick: function (e) {
                                                                                    var dxDataGrid_añadirPrendasExtra =
                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasExtra"
                                                                                        ).dxDataGrid("instance");
                                                                                    dxDataGrid_añadirPrendasExtra.saveEditData();

                                                                                    $.merge(
                                                                                        repartoValetSel.tblPrendaExtraNRepartoValet,
                                                                                        filtrarPrendasVacias(
                                                                                            dxDataGrid_añadirPrendasExtra.option(
                                                                                                "dataSource"
                                                                                            )
                                                                                        )
                                                                                    );
                                                                                    $("#dxForm_nuevoReparto")
                                                                                        .dxForm("instance")
                                                                                        .updateData(repartoValetSel);
                                                                                    $("#dxDataGrid_nuevoRepartoExtra")
                                                                                        .dxDataGrid("instance")
                                                                                        .refresh();
                                                                                    $("#dxPopup_añadirPrendasExtra")
                                                                                        .dxPopup("instance")
                                                                                        .hide();
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                    //EVENTOS
                                                                    onHiding: function (args) {
                                                                        var dxDataGrid_añadirPrendasExtra = $(
                                                                            "#dxDataGrid_añadirPrendasExtra"
                                                                        ).dxDataGrid("instance");
                                                                        dxDataGrid_añadirPrendasExtra.cancelEditData();
                                                                        dxDataGrid_añadirPrendasExtra
                                                                            .getScrollable()
                                                                            .scrollTo(0);
                                                                        dxDataGrid_añadirPrendasExtra.clearFilter();
                                                                    },
                                                                })
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                height: "100%",
                                                colCount: 1,
                                                items: [
                                                    {
                                                        dataField: "tblPrendaEjecutivoNRepartoValet",
                                                        label: { visible: false },
                                                        template: function (itemData, itemElement) {
                                                            itemElement.append(
                                                                $(
                                                                    "<div id='dxDataGrid_nuevoRepartoEjecutivo' />"
                                                                ).dxDataGrid({
                                                                    //Datos
                                                                    dataSource:
                                                                        itemData.component.option("formData")[
                                                                            itemData.dataField
                                                                        ],
                                                                    remoteOperations: {
                                                                        summary: false,
                                                                    },
                                                                    //Propiedades
                                                                    columnsAutoWidth: true,
                                                                    height: 694,
                                                                    keyExpr: "idPrendaEjecutivo",
                                                                    headerFilter: {
                                                                        visible: true,
                                                                    },
                                                                    paging: {
                                                                        pageSize: 50,
                                                                    },
                                                                    pager: {
                                                                        showPageSizeSelector: true,
                                                                        allowedPageSizes: [20, 50, 100],
                                                                        showInfo: true,
                                                                    },
                                                                    filterRow: { visible: false },
                                                                    editing: {
                                                                        mode: "batch",
                                                                        allowUpdating: true,
                                                                        allowDeleting: repartoValetSel.idRepartoValet,
                                                                        useIcons: true,
                                                                        selectTextOnEditStart: true,
                                                                        refreshMode: "reshape", // Para que reordene al insertar nueva prenda
                                                                    },
                                                                    keyboardNavigation: {
                                                                        enterKeyAction: "moveFocus",
                                                                        enterKeyDirection: "row",
                                                                        editOnKeyPress: true,
                                                                    },
                                                                    loadPanel: { enabled: false },
                                                                    columns: [
                                                                        {
                                                                            dataField:
                                                                                "tblPrendaEjecutivo.codigoPrendaEjecutivo",
                                                                            caption: getTrad("codigo"),
                                                                            width: 80,
                                                                            allowEditing: false,
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            sortOrder: "asc",
                                                                        },
                                                                        {
                                                                            dataField:
                                                                                "tblPrendaEjecutivo.denominacion",
                                                                            caption: getTrad("denominacion"),
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoDoblado",
                                                                            caption: getTrad("lavadoDoblado"),
                                                                            width: "14%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                const texto =
                                                                                    getTrad("prendaNoLavadoDoblado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoVaporizado",
                                                                            caption: getTrad("lavadoVaporizado"),
                                                                            dataType: "number",
                                                                            width: "16%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                const texto =
                                                                                    getTrad("prendaNoLavadoVaporizado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadPlanchado",
                                                                            caption: getTrad("planchado"),
                                                                            width: "10%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                const texto =
                                                                                    getTrad("prendaNoPrecioPlanchado");
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "cantidadLavadoSecadoPlanchado",
                                                                            caption: getTrad("lavadoSecadoPlanchado"),
                                                                            width: "22%",
                                                                            alignment: "center",
                                                                            cssClass: "dx-Cell_Editable",
                                                                            allowHeaderFiltering: false,
                                                                            allowFiltering: false,
                                                                            allowSorting: false,
                                                                            format: {
                                                                                style: "decimal",
                                                                                maximumFractionDigits: 0,
                                                                            },
                                                                            editorOptions: {
                                                                                format: {
                                                                                    style: "decimal",
                                                                                    maximumFractionDigits: 0,
                                                                                },
                                                                                refreshMode: "repaint",
                                                                                step: 0,
                                                                                max: 1000000,
                                                                                min: 0,
                                                                            },
                                                                            setCellValue: function (
                                                                                newData,
                                                                                value,
                                                                                currentRowData
                                                                            ) {
                                                                                const texto = getTrad(
                                                                                    "prendaNoLavadoSecadoPlanchado"
                                                                                );
                                                                                return set_dxDataGrid_setCellValue(
                                                                                    newData,
                                                                                    value,
                                                                                    currentRowData,
                                                                                    texto
                                                                                );
                                                                            },
                                                                        },
                                                                        {
                                                                            dataField: "precio",
                                                                            caption: getTrad("precio"),
                                                                            alignment: "center",
                                                                            dataType: "number",
                                                                            width: "10%",
                                                                            format: {
                                                                                style: "currency",
                                                                                currency: LAVANDERIA.moneda,
                                                                                minimumFractionDigits: 2,
                                                                            },
                                                                            editorOptions: {
                                                                                min: 0,
                                                                                max: 99999999,
                                                                            },
                                                                            allowFiltering: false,
                                                                            allowEditing: false,
                                                                            calculateCellValue: function (rowData) {
                                                                                if (
                                                                                    typeof rowData.tblPrendaEjecutivo !==
                                                                                    "undefined"
                                                                                ) {
                                                                                    let tipoLavado =
                                                                                        repartoValetSel.tipoLavado;
                                                                                    var incLavExpress =
                                                                                            tipoLavado === 1
                                                                                                ? 0
                                                                                                : rowData
                                                                                                      .tblPrendaEjecutivo
                                                                                                      .incLavadoExpress *
                                                                                                  0.01,
                                                                                        cantidadLavadoDoblado =
                                                                                            rowData.cantidadLavadoDoblado,
                                                                                        cantidadLavadoVaporizado =
                                                                                            rowData.cantidadLavadoVaporizado,
                                                                                        cantidadPlanchado =
                                                                                            rowData.cantidadPlanchado,
                                                                                        cantidadLavadoSecadoPlanchado =
                                                                                            rowData.cantidadLavadoSecadoPlanchado,
                                                                                        precioLavadoDoblado =
                                                                                            rowData.tblPrendaEjecutivo
                                                                                                .precioLavadoDoblado,
                                                                                        precioLavadoVaporizado =
                                                                                            rowData.tblPrendaEjecutivo
                                                                                                .precioLavadoVaporizado,
                                                                                        precioPlanchado =
                                                                                            rowData.tblPrendaEjecutivo
                                                                                                .precioPlanchado,
                                                                                        precioLavadoSecadoPlanchado =
                                                                                            rowData.tblPrendaEjecutivo
                                                                                                .precioLavadoSecadoPlanchado;

                                                                                    return (
                                                                                        cantidadLavadoDoblado *
                                                                                            (precioLavadoDoblado +
                                                                                                precioLavadoDoblado *
                                                                                                    incLavExpress) +
                                                                                        cantidadLavadoVaporizado *
                                                                                            (precioLavadoVaporizado +
                                                                                                precioLavadoVaporizado *
                                                                                                    incLavExpress) +
                                                                                        cantidadPlanchado *
                                                                                            (precioPlanchado +
                                                                                                precioPlanchado *
                                                                                                    incLavExpress) +
                                                                                        cantidadLavadoSecadoPlanchado *
                                                                                            (precioLavadoSecadoPlanchado +
                                                                                                precioLavadoSecadoPlanchado *
                                                                                                    incLavExpress)
                                                                                    );
                                                                                }
                                                                            },
                                                                        },
                                                                    ],
                                                                    summary: {
                                                                        recalculateWhileEditing: true,
                                                                        totalItems: [
                                                                            {
                                                                                column: "tblPrendaEjecutivo.codigoPrendaEjecutivo",
                                                                                displayFormat:
                                                                                    getTrad("total").toUpperCase(),
                                                                            },
                                                                            {
                                                                                column: "precio",
                                                                                summaryType: "sum",
                                                                                displayFormat: "{0}",
                                                                                valueFormat: {
                                                                                    style: "currency",
                                                                                    currency: LAVANDERIA.moneda,
                                                                                    minimumFractionDigits: 2,
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                    onContentReady: function (e) {
                                                                        e.component.columnOption("command:edit", {
                                                                            width: 45,
                                                                        });
                                                                    },
                                                                    onEditingStart: function (e) {
                                                                        rowEditing = e;
                                                                    },
                                                                    onCellPrepared: function (e) {
                                                                        set_dxDataGrid_cellPrepared(e);
                                                                    },
                                                                    onFocusedCellChanged: function (e) {
                                                                        $("#dxPopover_gridPrendas")
                                                                            .dxPopover("instance")
                                                                            .hide();
                                                                    },
                                                                    onToolbarPreparing: function (e) {
                                                                        //Modificar los iconos por defecto de guardar y cancelar del batchEdti
                                                                        $.each(
                                                                            e.toolbarOptions.items,
                                                                            function (index, item) {
                                                                                if (item.name === "saveButton") {
                                                                                    item.visible = false;
                                                                                } else {
                                                                                    item.options.tooltipText =
                                                                                        item.options.hint;
                                                                                    item.options.hint = null;

                                                                                    tooltipControl_creacion(
                                                                                        item,
                                                                                        item.options.tooltipText,
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }
                                                                        );
                                                                        e.toolbarOptions.items.unshift(
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("nuevoReparto"),
                                                                                    type: "normal",
                                                                                    elementAttr: {
                                                                                        id: "btnNuevoReparto",
                                                                                    },
                                                                                    onClick: function (e) {
                                                                                        var dxDataGrid_repartosAnteriores =
                                                                                            $(
                                                                                                "#dxDataGrid_repartosAnteriores"
                                                                                            ).dxDataGrid("instance");
                                                                                        if (dxForm_hasChanges()) {
                                                                                            dxMensajePregunta(
                                                                                                getTrad(
                                                                                                    "preg_PerderCambios"
                                                                                                ),
                                                                                                [
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "aceptar"
                                                                                                        ),
                                                                                                        function () {
                                                                                                            nuevoRepartoHuesped();
                                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                                "selectedRowKeys",
                                                                                                                []
                                                                                                            );
                                                                                                        },
                                                                                                        "danger",
                                                                                                    ],
                                                                                                    [
                                                                                                        getTrad(
                                                                                                            "cancelar"
                                                                                                        ),
                                                                                                        function () {},
                                                                                                    ],
                                                                                                ]
                                                                                            );
                                                                                        } else {
                                                                                            nuevoRepartoHuesped();
                                                                                            dxDataGrid_repartosAnteriores.option(
                                                                                                "selectedRowKeys",
                                                                                                []
                                                                                            );
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "before",
                                                                                widget: "dxButton",
                                                                                toolbar: "bottom",
                                                                                options: {
                                                                                    width: "100%",
                                                                                    icon: "plus",
                                                                                    text: getTrad("añadirPrenda"),
                                                                                    type: "success",
                                                                                    visible:
                                                                                        !repartoValetSel.idRepartoValet
                                                                                            ? false
                                                                                            : true,
                                                                                    onClick: function () {
                                                                                        // Se difine aquí porque tiene que encontrar la función formatoPrendas_addCantidades
                                                                                        var añadirPrendas_filtro =
                                                                                            $.grep(
                                                                                                datasource_tblPrendaEjecutivo.items(),
                                                                                                function (v) {
                                                                                                    return (
                                                                                                        $(
                                                                                                            "#dxDataGrid_nuevoRepartoEjecutivo"
                                                                                                        )
                                                                                                            .dxDataGrid(
                                                                                                                "instance"
                                                                                                            )
                                                                                                            .getRowIndexByKey(
                                                                                                                v.idPrendaEjecutivo
                                                                                                            ) === -1
                                                                                                    );
                                                                                                }
                                                                                            );
                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasEjecutivo"
                                                                                        )
                                                                                            .dxDataGrid("instance")
                                                                                            .option(
                                                                                                "dataSource",
                                                                                                formatoPrendas_addCantidades(
                                                                                                    añadirPrendas_filtro
                                                                                                )
                                                                                            );
                                                                                        $(
                                                                                            "#dxPopup_añadirPrendasEjecutivo"
                                                                                        )
                                                                                            .dxPopup("instance")
                                                                                            .show();
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                widget: "dxButton",
                                                                                showText: "inMenu",
                                                                                locateInMenu: "auto",
                                                                                options: {
                                                                                    text: getTrad("imprimir"),
                                                                                    icon: " icon_Impresora",
                                                                                    visible:
                                                                                        repartoValetSel.idRepartoValet !=
                                                                                        null,
                                                                                    onClick: function (e) {
                                                                                        if (!dxForm_hasChanges()) {
                                                                                            var params = {
                                                                                                idRepartoValet:
                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                idEntidad:
                                                                                                    repartoValetSel.idEntidad,
                                                                                                idLavanderia:
                                                                                                    LAVANDERIA.idLavanderia,
                                                                                                idioma: "es",
                                                                                            };

                                                                                            COMPONENT.setParams(params);
                                                                                        } else {
                                                                                            notify({
                                                                                                message:
                                                                                                    getTrad(
                                                                                                        "alerta_GuardarParaImprimir"
                                                                                                    ),
                                                                                                type: "error",
                                                                                                displayTime: "1500",
                                                                                                closeOnClick: true,
                                                                                            });
                                                                                        }
                                                                                    },
                                                                                },
                                                                            },
                                                                            {
                                                                                location: "after",
                                                                                template: function (
                                                                                    itemData,
                                                                                    itemIndex,
                                                                                    itemElement
                                                                                ) {
                                                                                    itemElement.append(
                                                                                        $("<div />").dxButton({
                                                                                            width: !repartoValetSel.idRepartoValet
                                                                                                ? "100%"
                                                                                                : "36px",
                                                                                            icon: !repartoValetSel.idRepartoValet
                                                                                                ? "check"
                                                                                                : "save",
                                                                                            type: !repartoValetSel.idRepartoValet
                                                                                                ? "success"
                                                                                                : "normal",
                                                                                            text: !repartoValetSel.idRepartoValet
                                                                                                ? getTrad(
                                                                                                      "realizarReparto"
                                                                                                  )
                                                                                                : null,
                                                                                            elementAttr: {
                                                                                                id: !repartoValetSel.idRepartoValet
                                                                                                    ? ""
                                                                                                    : "dxButton_guardarRepartoEjecutivo",
                                                                                            },
                                                                                            tooltipText:
                                                                                                getTrad(
                                                                                                    "guardarReparto"
                                                                                                ),
                                                                                            onInitialized: function (
                                                                                                e
                                                                                            ) {
                                                                                                if (
                                                                                                    repartoValetSel.idRepartoValet
                                                                                                )
                                                                                                    tooltipControl_creacion(
                                                                                                        e.element,
                                                                                                        e.component.option(
                                                                                                            "tooltipText"
                                                                                                        )
                                                                                                    );
                                                                                            },
                                                                                            onClick: function (button) {
                                                                                                if (
                                                                                                    dxForm_hasChanges()
                                                                                                ) {
                                                                                                    var form =
                                                                                                            $(
                                                                                                                "#dxForm_nuevoReparto"
                                                                                                            ).dxForm(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        formData =
                                                                                                            form.option(
                                                                                                                "formData"
                                                                                                            ),
                                                                                                        dxDataGrid_nuevoRepartoEjecutivo =
                                                                                                            $(
                                                                                                                "#dxDataGrid_nuevoRepartoEjecutivo"
                                                                                                            ).dxDataGrid(
                                                                                                                "instance"
                                                                                                            ),
                                                                                                        rows_nuevoReparto =
                                                                                                            dxDataGrid_nuevoRepartoEjecutivo.getVisibleRows(),
                                                                                                        prendasVaciasFiltradas =
                                                                                                            filtrarPrendasVacias(
                                                                                                                rows_nuevoReparto
                                                                                                            );
                                                                                                    if (
                                                                                                        formData &&
                                                                                                        form.validate()
                                                                                                            .isValid &&
                                                                                                        prendasVaciasFiltradas.length >
                                                                                                            0
                                                                                                    ) {
                                                                                                        dxDataGrid_nuevoRepartoEjecutivo.saveEditData();
                                                                                                        var data =
                                                                                                            tratarDatos(
                                                                                                                $.extend(
                                                                                                                    true,
                                                                                                                    {},
                                                                                                                    repartoValetSel
                                                                                                                )
                                                                                                            );

                                                                                                        if (
                                                                                                            !repartoValetSel.idRepartoValet
                                                                                                        ) {
                                                                                                            delete data.idRepartoValet;
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .insert(
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function (
                                                                                                                        reparto
                                                                                                                    ) {
                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroInsertado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "1500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );

                                                                                                                        datasource_tblRepartosValet_anteriores
                                                                                                                            .reload()
                                                                                                                            .done(
                                                                                                                                function () {
                                                                                                                                    repartoValetSel_bak =
                                                                                                                                        $.extend(
                                                                                                                                            true,
                                                                                                                                            {},
                                                                                                                                            repartoValetSel
                                                                                                                                        );
                                                                                                                                    $(
                                                                                                                                        "#dxDataGrid_repartosAnteriores"
                                                                                                                                    )
                                                                                                                                        .dxDataGrid(
                                                                                                                                            "instance"
                                                                                                                                        )
                                                                                                                                        .selectRows(
                                                                                                                                            [
                                                                                                                                                reparto.idRepartoValet,
                                                                                                                                            ],
                                                                                                                                            false
                                                                                                                                        );
                                                                                                                                }
                                                                                                                            );
                                                                                                                    }
                                                                                                                );
                                                                                                        } else {
                                                                                                            datasource_tblRepartosValet_anteriores
                                                                                                                .store()
                                                                                                                .update(
                                                                                                                    repartoValetSel.idRepartoValet,
                                                                                                                    data
                                                                                                                )
                                                                                                                .done(
                                                                                                                    function () {
                                                                                                                        repartoValetSel_bak =
                                                                                                                            $.extend(
                                                                                                                                true,
                                                                                                                                {},
                                                                                                                                repartoValetSel
                                                                                                                            );
                                                                                                                        datasource_tblRepartosValet_anteriores.reload();

                                                                                                                        notify(
                                                                                                                            {
                                                                                                                                message:
                                                                                                                                    getTrad(
                                                                                                                                        "aviso_C_RegistroActualizado"
                                                                                                                                    ),
                                                                                                                                type: "success",
                                                                                                                                displayTime:
                                                                                                                                    "2500",
                                                                                                                                closeOnClick: true,
                                                                                                                            }
                                                                                                                        );
                                                                                                                    }
                                                                                                                );
                                                                                                        }
                                                                                                    } else if (
                                                                                                        prendasVaciasFiltradas.length ===
                                                                                                        0
                                                                                                    ) {
                                                                                                        notify({
                                                                                                            message:
                                                                                                                getTrad(
                                                                                                                    "alerta_cantidadRepartir"
                                                                                                                ),
                                                                                                            type: "error",
                                                                                                            displayTime:
                                                                                                                "2500",
                                                                                                            closeOnClick: true,
                                                                                                        });
                                                                                                    }
                                                                                                } else {
                                                                                                    notify({
                                                                                                        message:
                                                                                                            getTrad(
                                                                                                                "alerta_SinCambios"
                                                                                                            ),
                                                                                                        type: "error",
                                                                                                        displayTime:
                                                                                                            "2500",
                                                                                                        closeOnClick: true,
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                        })
                                                                                    );
                                                                                },
                                                                            }
                                                                        );
                                                                    },
                                                                    //Estilos
                                                                    showColumnLines: false,
                                                                    showRowLines: true,
                                                                    rowAlternationEnabled: true,
                                                                })
                                                            );
                                                            itemElement.append(
                                                                $(
                                                                    "<div id='dxPopup_añadirPrendasEjecutivo' />"
                                                                ).dxPopup({
                                                                    showTitle: true,
                                                                    title: getTrad("añadirPrenda"),
                                                                    height: 585,
                                                                    width: 950,
                                                                    deferRendering: false,
                                                                    contentTemplate: function () {
                                                                        return $(
                                                                            "<div id='dxDataGrid_añadirPrendasEjecutivo' />"
                                                                        ).dxDataGrid({
                                                                            //Propiedades
                                                                            height: "100%",
                                                                            columnsAutoWidth: true,
                                                                            headerFilter: {
                                                                                visible: true,
                                                                            },
                                                                            filterRow: {
                                                                                visible: true,
                                                                                applyFilter: "auto",
                                                                            },
                                                                            editing: {
                                                                                mode: "batch",
                                                                                refreshMode: "repaint",
                                                                                allowUpdating: true,
                                                                                allowDeleting: false,
                                                                                useIcons: true,
                                                                                selectTextOnEditStart: true,
                                                                            },
                                                                            keyboardNavigation: {
                                                                                enterKeyAction: "moveFocus",
                                                                                enterKeyDirection: "row",
                                                                                editOnKeyPress: true,
                                                                            },
                                                                            paging: {
                                                                                enabled: false,
                                                                            },
                                                                            keyExpr: "idPrendaEjecutivo",
                                                                            loadPanel: { enabled: false },
                                                                            columns: [
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaEjecutivo.codigoPrendaEjecutivo",
                                                                                    caption: getTrad("codigo"),
                                                                                    sortOrder: "asc",
                                                                                    alignment: "center",
                                                                                    allowHeaderFiltering: false,
                                                                                    width: 110,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "tblPrendaEjecutivo.denominacion",
                                                                                    caption: getTrad("denominacion"),
                                                                                    alignment: "left",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowEditing: false,
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadLavadoDoblado",
                                                                                    caption: getTrad("lavadoDoblado"),
                                                                                    width: "14%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        const texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoDoblado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "cantidadLavadoVaporizado",
                                                                                    caption:
                                                                                        getTrad("lavadoVaporizado"),
                                                                                    width: "16%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        const texto =
                                                                                            getTrad(
                                                                                                "prendaNoLavadoVaporizado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField: "cantidadPlanchado",
                                                                                    caption: getTrad("planchado"),
                                                                                    width: "10%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        const texto =
                                                                                            getTrad(
                                                                                                "prendaNoPrecioPlanchado"
                                                                                            );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    dataField:
                                                                                        "cantidadLavadoSecadoPlanchado",
                                                                                    caption:
                                                                                        getTrad(
                                                                                            "lavadoSecadoPlanchado"
                                                                                        ),
                                                                                    width: "22%",
                                                                                    alignment: "center",
                                                                                    cssClass: "dx-Cell_Editable",
                                                                                    allowHeaderFiltering: false,
                                                                                    allowFiltering: false,
                                                                                    allowSorting: false,
                                                                                    format: {
                                                                                        style: "decimal",
                                                                                        maximumFractionDigits: 0,
                                                                                    },
                                                                                    editorOptions: {
                                                                                        step: 0,
                                                                                        format: {
                                                                                            style: "decimal",
                                                                                            maximumFractionDigits: 0,
                                                                                        },
                                                                                        min: 0,
                                                                                        max: 99999999,
                                                                                    },
                                                                                    setCellValue: function (
                                                                                        newData,
                                                                                        value,
                                                                                        currentRowData
                                                                                    ) {
                                                                                        const texto = getTrad(
                                                                                            "prendaNoLavadoSecadoPlanchado"
                                                                                        );
                                                                                        return set_dxDataGrid_setCellValue(
                                                                                            newData,
                                                                                            value,
                                                                                            currentRowData,
                                                                                            texto
                                                                                        );
                                                                                    },
                                                                                },
                                                                            ],
                                                                            //EVENTOS
                                                                            onEditingStart: function (e) {
                                                                                rowEditing = e;
                                                                            },
                                                                            onCellPrepared: function (e) {
                                                                                set_dxDataGrid_cellPrepared(e);
                                                                            },
                                                                            onFocusedCellChanged: function (e) {
                                                                                $("#dxPopover_gridPrendas")
                                                                                    .dxPopover("instance")
                                                                                    .hide();
                                                                            },
                                                                            onToolbarPreparing: function (e) {
                                                                                $.each(
                                                                                    e.toolbarOptions.items,
                                                                                    function (_, item) {
                                                                                        if (
                                                                                            item.name === "saveButton"
                                                                                        ) {
                                                                                            item.visible = false;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            },
                                                                            showColumnLines: false,
                                                                            showRowLines: true,
                                                                            rowAlternationEnabled: true,
                                                                        });
                                                                    },
                                                                    toolbarItems: [
                                                                        {
                                                                            location: "after",
                                                                            widget: "dxButton",
                                                                            toolbar: "bottom",
                                                                            options: {
                                                                                text: getTrad("guardar"),
                                                                                type: "success",
                                                                                onClick: function (e) {
                                                                                    var dxDataGrid_añadirPrendasEjecutivo =
                                                                                        $(
                                                                                            "#dxDataGrid_añadirPrendasEjecutivo"
                                                                                        ).dxDataGrid("instance");
                                                                                    dxDataGrid_añadirPrendasEjecutivo.saveEditData();

                                                                                    $.merge(
                                                                                        repartoValetSel.tblPrendaEjecutivoNRepartoValet,
                                                                                        filtrarPrendasVacias(
                                                                                            dxDataGrid_añadirPrendasEjecutivo.option(
                                                                                                "dataSource"
                                                                                            )
                                                                                        )
                                                                                    );
                                                                                    $("#dxForm_nuevoReparto")
                                                                                        .dxForm("instance")
                                                                                        .updateData(repartoValetSel);
                                                                                    $(
                                                                                        "#dxDataGrid_nuevoRepartoEjecutivo"
                                                                                    )
                                                                                        .dxDataGrid("instance")
                                                                                        .refresh();
                                                                                    $("#dxPopup_añadirPrendasEjecutivo")
                                                                                        .dxPopup("instance")
                                                                                        .hide();
                                                                                },
                                                                            },
                                                                        },
                                                                    ],
                                                                    //EVENTOS
                                                                    onHiding: function (args) {
                                                                        var dxDataGrid_añadirPrendasEjecutivo = $(
                                                                            "#dxDataGrid_añadirPrendasEjecutivo"
                                                                        ).dxDataGrid("instance");
                                                                        dxDataGrid_añadirPrendasEjecutivo.cancelEditData();
                                                                        dxDataGrid_añadirPrendasEjecutivo
                                                                            .getScrollable()
                                                                            .scrollTo(0);
                                                                        dxDataGrid_añadirPrendasEjecutivo.clearFilter();
                                                                    },
                                                                })
                                                            );
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                ],
                            });
                        },
                    },
                    {
                        ratio: 5,
                        template: function (e, index, item) {
                            item.css("padding-top", 39);
                            return $("<div id='dxDataGrid_repartosAnteriores' />").dxDataGrid({
                                //Datos
                                dataSource: datasource_tblRepartosValet_anteriores_cierres,
                                remoteOperations: true,
                                //Propiedades
                                columnsAutoWidth: true,
                                height: "100%",
                                filterRow: {
                                    visible: true,
                                    applyFilter: "auto",
                                },
                                headerFilter: {
                                    visible: true,
                                },
                                searchPanel: {
                                    visible: true,
                                    width: 240,
                                    placeholder: getTrad("busqueda"),
                                },
                                selection: {
                                    mode: "single",
                                },
                                paging: {
                                    pageSize: 20,
                                },
                                pager: {
                                    showPageSizeSelector: true,
                                    allowedPageSizes: [20, 50, 100],
                                    showInfo: true,
                                    visible: true,
                                },
                                hoverStateEnabled: true,
                                columns: [
                                    {
                                        dataField: "isEnviadoCorreo",
                                        allowHeaderFiltering: false,
                                        width: 30,
                                        alignment: "center",
                                        cssClass: "p-0",
                                        headerCellTemplate: function (header, info) {
                                            $("<i>")
                                                .addClass("dx-icon-email align-bottom")
                                                .css("font-size", "22px")
                                                .appendTo(header);
                                        },
                                        cellTemplate: function (container, options) {
                                            var estadoImg = {};
                                            switch (options.data.isEnviadoCorreo) {
                                                case false:
                                                    estadoImg = {
                                                        svg: svg_remove_circle,
                                                        color: enum_colores["rojo"],
                                                    };
                                                    break;
                                                case true:
                                                    estadoImg = {
                                                        svg: svg_tick_circle,
                                                        color: enum_colores["verde"],
                                                    };
                                                    break;
                                                default:
                                                    estadoImg = {
                                                        svg: svg_remove_circle,
                                                        color: enum_colores["rojo"],
                                                    };
                                                    break;
                                            }

                                            estadoImg.svg
                                                .clone()
                                                .css({
                                                    fill: estadoImg.color,
                                                    "vertical-align": "middle",
                                                    cursor: "pointer",
                                                    height: "20px",
                                                    width: "20px",
                                                })
                                                .appendTo(container);
                                        },
                                        allowHeaderFiltering: false,
                                        allowFiltering: false,
                                    },
                                    {
                                        dataField: "codigoRepartosValet",
                                        caption: getTrad("codigo"),
                                        allowHeaderFiltering: false,
                                        width: 75,
                                    },
                                    {
                                        dataField: "fecha",
                                        caption: getTrad("fecha"),
                                        width: 100,
                                        alignment: "center",
                                        dataType: "date",
                                        format: "dd/MM/yyyy",
                                        sortOrder: "desc",
                                        allowHeaderFiltering: false,
                                    },
                                    {
                                        dataField: "tblEntidad.idEntidad",
                                        lookup: {
                                            dataSource: datasource_datos_compaEnti_entidad.items(),
                                            valueExpr: "idEntidad",
                                            displayExpr: "denominacion",
                                        },
                                        caption: getTrad("entidad"),
                                        allowHeaderFiltering: false,
                                        cssClass: "position-relative",
                                        // cellTemplate: function (cellElement, cellInfo) {
                                        //     let container = $("<div/>")
                                        //         .addClass("d-flex flex-row justify-content-between align-items-center")
                                        //         .appendTo(cellElement);

                                        //     $("<span/>")
                                        //         .text(cellInfo.text)
                                        //         .css("overflow", "hidden")
                                        //         .css("text-overflow", "ellipsis")
                                        //         .appendTo(container);

                                        //     if (cellInfo.data.isCerradoFactEntidad) {
                                        //         let iconContainer = $("<div/>")
                                        //             .css("width", "25px")
                                        //             .addClass("position-relative")
                                        //             .appendTo(container);

                                        //         iconContainer.append(
                                        //             svg_lock_outline
                                        //                 .clone()
                                        //                 .css("height", "25px")
                                        //                 .css("width", "25px")
                                        //                 .addClass("position-absolute-center-right")
                                        //         );
                                        //     }
                                        // },
                                    },
                                    {
                                        dataField: "numHabitacion",
                                        caption: getTrad("habitacion"),
                                        allowHeaderFiltering: false,
                                        allowSorting: false,
                                        width: 90,
                                    },
                                    {
                                        dataField: "tipoLavado",
                                        caption: getTrad("tipoLavado"),
                                        width: 95,
                                        allowHeaderFiltering: false,
                                        lookup: {
                                            dataSource: enum_tipoLavado,
                                            valueExpr: "tipoLavado",
                                            displayExpr: "denominacion",
                                        },
                                    },
                                    {
                                        dataField: "idTipoRepartoValet",
                                        caption: getTrad("tipoReparto"),
                                        width: 95,
                                        allowHeaderFiltering: false,
                                        lookup: {
                                            dataSource: enum_tipoReparto,
                                            valueExpr: "value",
                                            displayExpr: "denominacion",
                                        },
                                    },
                                    {
                                        dataField: "descuento",
                                        visible: false,
                                        allowSearch: false,
                                    },
                                    {
                                        caption: " ",
                                        width: 25,
                                        alignment: "center",
                                        cssClass: "p-0",
                                        allowEditing: false,
                                        allowHeaderFiltering: false,
                                        allowUpdating: false,
                                        cellTemplate: function (cellElement, cellInfo) {
                                            if (!cellInfo.data.isCerradoFactEntidad) {
                                                cellElement.on("click", function () {
                                                    cellInfo.component.deleteRow(cellInfo.rowIndex);
                                                });

                                                cellElement.append(
                                                    $("<div/>").addClass("container_spanCentrado").append(
                                                        svg_remove
                                                            .clone()

                                                            .css("height", "25px")
                                                            .css("width", "25px")
                                                            .css("cursor", "pointer")
                                                    )
                                                );
                                            } else {
                                                cellElement.append(
                                                    $("<div/>").addClass("container_spanCentrado").append(
                                                        svg_lock_outline
                                                            .clone()

                                                            .css("height", "25px")
                                                            .css("width", "25px")
                                                            .css("cursor", "pointer")
                                                    )
                                                );
                                            }
                                        },
                                    },
                                ],
                                //Eventos
                                onSelectionChanged: function (e) {
                                    var data = e.selectedRowsData[0];
                                    if (data) {
                                        if (dxForm_hasChanges()) {
                                            dxMensajePregunta(getTrad("preg_PerderCambios"), [
                                                [
                                                    getTrad("aceptar"),
                                                    function () {
                                                        cargaRepartoValet(data);
                                                    },
                                                    "danger",
                                                ],
                                                [
                                                    getTrad("cancelar"),
                                                    function () {
                                                        e.component.option(
                                                            "selectedRowKeys",
                                                            e.currentDeselectedRowKeys
                                                        );
                                                    },
                                                ],
                                            ]);
                                        } else cargaRepartoValet(data);
                                    } else if (repartoValetSel.idRepartoValet) nuevoRepartoHuesped();
                                },
                                onToolbarPreparing: function (e) {
                                    e.toolbarOptions.items.unshift(
                                        {
                                            location: "before",
                                            template: function () {
                                                return $("<div class ='font-size'>").text(
                                                    getTrad("repartosAnteriores")
                                                );
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            showText: "inMenu",
                                            locateInMenu: "auto",
                                            options: {
                                                tfext: getTrad("limpiarFiltro"),
                                                icon: " icon_EliminarFiltros",
                                                tooltipText: getTrad("limpiarFiltro"),
                                                onInitialized: function (e) {
                                                    tooltipControl_creacion(
                                                        e.element,
                                                        e.component.option("tooltipText")
                                                    );
                                                },
                                                onClick: function () {
                                                    e.component.clearFilter();
                                                    notify({
                                                        message: getTrad("aviso_C_FiltroRestaurado"),
                                                        type: "success",
                                                        displayTime: "1500",
                                                        closeOnClick: true,
                                                    });
                                                },
                                            },
                                        },
                                        {
                                            location: "after",
                                            widget: "dxButton",
                                            showText: "inMenu",
                                            options: {
                                                icon: "refresh",
                                                text: getTrad("actualizar"),
                                                tooltipText: getTrad("actualizar"),
                                                onInitialized: function (e) {
                                                    tooltipControl_creacion(
                                                        e.element,
                                                        e.component.option("tooltipText")
                                                    );
                                                },
                                                onClick: function () {
                                                    e.component.refresh(true);
                                                },
                                            },
                                        }
                                    );
                                },
                                onRowPrepared: function (info) {
                                    if (info.rowType === "data") {
                                        info.rowElement.css("cursor", "pointer");
                                    }
                                },
                                //Estilos
                                showColumnLines: false,
                                showRowLines: true,
                                rowAlternationEnabled: true,
                            });
                        },
                    },
                ],
            });

            $("#dxPopover_gridPrendas")
                .dxPopover({
                    position: "top",
                    width: "auto",
                    height: "auto",
                    onContentReady: function (e) {
                        var html = e.component.content();
                        $(html).css("padding", 13);
                    },
                })
                .dxPopover("instance");
        })
        .always(function () {
            var dxForm_nuevoReparto = $("#dxForm_nuevoReparto").dxForm("instance");
            if (dxForm_nuevoReparto) {
                dxForm_nuevoReparto.repaint();
            }
            deferred.resolve();
        });

    function set_dxDataGrid_cellPrepared(e) {
        if (e.rowType === "data") {
            let data = e.data,
                dataField = e.column.dataField,
                id = e.component.element()[0].id,
                idPrenda =
                    data.idPrendaHuesped != null
                        ? data.idPrendaHuesped
                        : data.idPrendaExtra != null
                        ? data.idPrendaExtra
                        : data.idPrendaEjecutivo,
                cell_id = id + "_" + dataField + "_" + idPrenda;

            $(e.cellElement).attr("id", cell_id);
        }
    }

    function set_dxDataGrid_setCellValue(newData, value, currentRowData, texto) {
        let tblPrenda =
            currentRowData.tblPrendaHuesped != null
                ? currentRowData.tblPrendaHuesped
                : currentRowData.tblPrendaExtra != null
                ? currentRowData.tblPrendaExtra
                : currentRowData.tblPrendaEjecutivo;
        const getPrecio = {
            cantidadLavadoSeco: tblPrenda?.precioLavado,
            cantidadPlanchado: tblPrenda?.precioPlanchado,
            cantidadLavadoPlanchado: tblPrenda?.precioLavadoPlanchado,
            cantidadTintoreria: tblPrenda?.precioTintoreria,
            cantidadLavadoDoblado: tblPrenda?.precioLavadoDoblado,
            cantidadLavadoVaporizado: tblPrenda?.precioLavadoVaporizado,
            cantidadLavadoSecadoPlanchado: tblPrenda?.precioLavadoSecadoPlanchado,
        };

        let precio = getPrecio[rowEditing.column.dataField],
            rowIndex = rowEditing.component.getRowIndexByKey(rowEditing.key),
            dataField = rowEditing.column.dataField,
            id = rowEditing.component.element()[0].id;

        if (precio > 0) newData[dataField] = value;
        else {
            setTimeout(() => {
                let dxPopover_gridPrendas = $("#dxPopover_gridPrendas").dxPopover("instance");
                let data = rowEditing.data;
                if (typeof data !== "undefined") {
                    let idPrenda =
                        data.idPrendaHuesped != null
                            ? data.idPrendaHuesped
                            : data.idPrendaExtra != null
                            ? data.idPrendaExtra
                            : data.idPrendaEjecutivo;

                    dxPopover_gridPrendas.option("target", "#" + id + "_" + dataField + "_" + idPrenda);

                    dxPopover_gridPrendas.option("contentTemplate", function (contentElement) {
                        $("<div style='font-size:12px;'/>").append(texto).appendTo(contentElement);
                    });
                    dxPopover_gridPrendas.show();
                }
                rowEditing.component.editCell(rowIndex, dataField);
                rowEditing.component.focus(rowEditing.component.getCellElement(rowIndex, dataField));
            }, 0);
        }
    }

    function dxForm_hasChanges() {
        var obj = tratarDatos($.extend(true, {}, repartoValetSel));
        var obj_bak = tratarDatos($.extend(true, {}, repartoValetSel_bak));

        let dxDataGrid_nuevoReparto =
            repartoValetSel.idTipoRepartoValet === 1
                ? $("#dxDataGrid_nuevoRepartoHuesped").dxDataGrid("instance")
                : repartoValetSel.idTipoRepartoValet === 2
                ? $("#dxDataGrid_nuevoRepartoExtra").dxDataGrid("instance")
                : $("#dxDataGrid_nuevoRepartoEjecutivo").dxDataGrid("instance");

        // En los casos que no se haga saveEditData del grid los cambios todavia no aparecen en el form
        return !(JSON.stringify(obj) === JSON.stringify(obj_bak)) || dxDataGrid_nuevoReparto.hasEditData();
    }

    function filtrarPrendasVacias(prendas) {
        var prendasVaciasFiltradas = $.grep(prendas, function (item, index) {
            var prenda = typeof item.data !== "undefined" ? item.data : item; // Para diferenciar si iteramos un array de prendas o un array de rows visibles del grid
            var cantidadTotal = 0;
            cantidadTotal += prenda?.cantidadLavadoSeco ?? 0;
            cantidadTotal += prenda?.cantidadPlanchado ?? 0;
            cantidadTotal += prenda?.cantidadLavadoPlanchado ?? 0;
            cantidadTotal += prenda?.cantidadTintoreria ?? 0;

            // Cantidades prendas ejecutivo
            cantidadTotal += prenda?.cantidadLavadoDoblado ?? 0;
            cantidadTotal += prenda?.cantidadLavadoVaporizado ?? 0;
            // cantidadTotal += prenda?.cantidadPlanchado ?? 0; //* Ya se ha sumado
            cantidadTotal += prenda?.cantidadLavadoSecadoPlanchado ?? 0;
            return cantidadTotal > 0 && item.removed !== true ? true : false;
        });
        return prendasVaciasFiltradas;
    }

    function tratarDatos(repartoValetSel_copy) {
        repartoValetSel_copy.tblPrendaHuespedNRepartoValet = filtrarPrendasVacias(
            repartoValetSel_copy.tblPrendaHuespedNRepartoValet
        );
        repartoValetSel_copy.tblPrendaExtraNRepartoValet = filtrarPrendasVacias(
            repartoValetSel_copy.tblPrendaExtraNRepartoValet
        );
        repartoValetSel_copy.tblPrendaEjecutivoNRepartoValet = filtrarPrendasVacias(
            repartoValetSel_copy.tblPrendaEjecutivoNRepartoValet
        );

        $.each(repartoValetSel_copy.tblPrendaHuespedNRepartoValet, function (index, item) {
            delete item.tblPrendaHuesped;
        });
        $.each(repartoValetSel_copy.tblPrendaExtraNRepartoValet, function (index, item) {
            delete item.tblPrendaExtra;
        });
        $.each(repartoValetSel_copy.tblPrendaEjecutivoNRepartoValet, function (index, item) {
            delete item.tblPrendaEjecutivo;
        });
        return repartoValetSel_copy;
    }

    //IMPRESIÓN
    function CustomizeParameterEditors(report, s, e) {
        if (e.parameter.name === "idRepartoValet" || e.parameter.name === "idLavanderia") {
            //INVISIBLES
            e.info.editor = $.extend({}, e.info.editor);
            e.info.visible = false;
        }
    }

    return deferred.promise();
}
