import { bell } from "./bell";
import { buscarEncuesta } from "./buscarEncuesta";
import { check } from "./check";
import { check_input } from "./check_input";
import { defaultUserPhoto } from "./defaultUserPhoto";
import { enConstruccion } from "./enConstruccion";
import { error } from "./error";
import { error_input } from "./error_input";
import { exclamation_circle } from "./exclamation_circle";
import { fingerDraw } from "./fingerDraw";
import { firma } from "./firma";
import { loading_circular } from "./loading_circular";
import { loading_input } from "./loading_input";
import { loading_startup } from "./loading_startup";
import { lock } from "./lock";
import { megaphone } from "./megaphone";
import { noImage } from "./noImage";
import { noNotification } from "./noNotification";
import { password } from "./password";
import { sendSMS } from "./sendSMS";
import { upload } from "./upload";
import { surveyResult } from "./surveyResult";
import { warning } from "./warning";
import { pdffile } from "./pdffile";
import finanzas from "./Finanzas";

export const allIcons = {
    bell,
    buscarEncuesta,
    check,
    check_input,
    defaultUserPhoto,
    enConstruccion,
    error,
    error_input,
    exclamation_circle,
    fingerDraw,
    firma,
    loading_circular,
    loading_input,
    loading_startup,
    lock,
    megaphone,
    noImage,
    noNotification,
    password,
    pdffile,
    sendSMS,
    upload,
    surveyResult,
    warning,
    ...finanzas,
};
