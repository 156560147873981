import { connectionConstants } from "../../constants";
import { authHeader, errorHandler, filtroTiempo_Resize } from "../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import fx from "devextreme/animation/fx";

var COMPONENT;

export default function cargaJs(_component, LAVANDERIA, IDIOMA) {
  COMPONENT = _component;
  var deferred = $.Deferred();

  COMPONENT.idSel = COMPONENT.props.idSel ? COMPONENT.props.idSel : null;
  COMPONENT.fechaDesde = COMPONENT.props.fechaDesde
    ? COMPONENT.props.fechaDesde
    : null;
  COMPONENT.fechaHasta = COMPONENT.props.fechaHasta
    ? COMPONENT.props.fechaHasta
    : null;
  COMPONENT.scrollLeft = COMPONENT.props.scrollLeft
    ? COMPONENT.props.scrollLeft
    : null;

  //#region REDIRECCIÓN DESDE PÁGINA PRINCIPAL
  COMPONENT.fechaDesde_redireccion =
    COMPONENT.props != null ? COMPONENT.props.fechaDesde_redireccion : null;
  COMPONENT.fechaHasta_redireccion =
    COMPONENT.props != null ? COMPONENT.props.fechaHasta_redireccion : null;
  //#endregion

  var datasource_datos;
  if (COMPONENT.props.selector === "lavanderia") {
    datasource_datos = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblLavanderia",
        key: "idLavanderia",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };
        },
        version: 4,
      }),
      expand: [
        "tblZonaHoraria($select=GMT), tblMoneda($select=codigo), tblMonedaLocal($select=codigo), tblIdioma($select=codigo)",
      ],
      select: [
        "idLavanderia",
        "denominacion",
        "horarioVerano",
        "gestionaRechazo",
        "direccion",
        "telefono",
      ],
      sort: ["denominacion"],
      postProcess: function (data) {
        $.each(data, function (index, lav) {
          lav.id = lav.idLavanderia;
          lav.tblZonaHoraria.GMT =
            lav.horarioVerano === 1
              ? parseInt(lav.tblZonaHoraria.GMT) + 1
              : lav.tblZonaHoraria.GMT;
        });

        return data;
      },
    });
  } else if (COMPONENT.props.selector === "compañia") {
    datasource_datos = new DataSource({
      paginate: false,
      store: new ODataStore({
        url: connectionConstants.ODATA_URL + "tblCompañia",
        key: "idCompañia",
        errorHandler: function (error) {
          errorHandler(error, COMPONENT);
        },
        beforeSend: function (request) {
          request.headers = { ...authHeader() };

          request.params.idLavanderia = LAVANDERIA
            ? LAVANDERIA.idLavanderia
            : null;
        },
        version: 4,
      }),
      select: ["idCompañia", "denominacion"],
      sort: ["denominacion"],
      postProcess: function (data) {
        $.each(data, function (index, comp) {
          comp.id = comp.idCompañia;
        });

        return data;
      },
    });
  }

  $.when(
    COMPONENT.props.cargaDatos(COMPONENT, LAVANDERIA, true),
    datasource_datos.load(),
  )
    .then(function () {
      if (datasource_datos.items().length === 1 && !COMPONENT.props.expandir) {
        //Redirección automática o selección
        COMPONENT.props.cardClick();
        return false;
      }

      $("#dxContainer_selector").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            ratio: 1,
            template: function (e, index, item) {
              item.css("padding", "5px 15px 10px 15px");
              item.append(
                $("<div id='dxBox_tileViews'/>")
                  .css("position", "relative")
                  .dxBox({
                    direction: "row",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    elementAttr: { class: "cardContainer" },
                    items: [
                      {
                        visible: false,
                        ratio: 2,
                        template: function (e, index, item) {
                          item.css("padding-bottom", "5px"); //El 5 es para que se vea la sombra del último item del dxTileView
                          item.addClass("activeCard");
                        },
                      },
                      {
                        ratio: 1,
                        template: function (e, index, item) {
                          item.append(
                            $("<div id='dxTileView'/>").dxTileView({
                              items: datasource_datos.items(),
                              height: "100%",
                              width: "100%",
                              direction: "vertical",
                              itemMargin: 15,
                              showScrollbar: "always",
                              itemTemplate: function (
                                itemData,
                                itemIndex,
                                itemElement,
                              ) {
                                itemElement
                                  .parent()
                                  .addClass("dxTileView_item_" + itemData.id);
                                itemElement.css("padding", "0px 1px 1px 1px");
                                itemElement.addClass("cardShadow");

                                itemElement.append(
                                  $("<div style='padding:2px;' />").dxBox({
                                    direction: "col",
                                    align: "space-around",
                                    crossAlign: "stretch",
                                    height: "100%",
                                    items: [
                                      {
                                        baseSize: 50,
                                        shrink: 0,
                                        ratio: 0,
                                        template: function (e, index, item) {
                                          item.addClass("cardHeader");
                                          item.append(
                                            COMPONENT.props.getContent_header(
                                              itemData,
                                            ),
                                          );
                                        },
                                      },
                                      {
                                        ratio: 1,
                                        template: function (e, index, item) {
                                          item.addClass("cardContent");
                                          item.append(
                                            $("<div />")
                                              .addClass("dxCard_front")
                                              .append(
                                                COMPONENT.props.getContent_front(
                                                  itemData.id,
                                                ),
                                              ),
                                          );
                                        },
                                      },
                                    ],
                                  }),
                                );
                              },
                              onContentReady: function (e) {
                                if (e.element.height() > 0) {
                                  var height = e.element.height();
                                  var width = e.element.width();

                                  var minSize = COMPONENT.props.getMinSize();

                                  var itemMinWidth = minSize.width;
                                  var itemMinHeight = minSize.height;

                                  var itemMargin =
                                    e.component.option("itemMargin");

                                  var numCols = Math.floor(
                                    width / itemMinWidth,
                                  );
                                  var numRows = Math.floor(
                                    height / itemMinHeight,
                                  );

                                  numCols = numCols < 1 ? 1 : numCols;
                                  numRows = numRows < 1 ? 1 : numRows;

                                  var itemWidth =
                                    Math.floor(width / numCols) -
                                    itemMargin -
                                    itemMargin / numCols;
                                  var itemHeight =
                                    Math.floor(height / numRows) -
                                    itemMargin -
                                    5 / (numRows * 2); //El 5 es para que se vea la sombra del último item

                                  e.component.beginUpdate();
                                  e.component.option(
                                    "baseItemWidth",
                                    itemWidth,
                                  );
                                  e.component.option(
                                    "baseItemHeight",
                                    itemHeight,
                                  );
                                  e.component.endUpdate();
                                }
                              },
                              onItemClick: function (e) {
                                if (COMPONENT.props.expandir) {
                                  var isPrimeraCarga = !COMPONENT.idSel;
                                  expandirCard(e.itemData.id, isPrimeraCarga);
                                }

                                if (!COMPONENT.props.expandir) {
                                  //El evento click se hará en el contenedor (activeCard)
                                  COMPONENT.props.cardClick();
                                }

                                if (COMPONENT.props.selector === "lavanderia") {
                                  var idLavanderia = e.itemData.idLavanderia;
                                  if (
                                    LAVANDERIA.idLavanderia !== idLavanderia
                                  ) {
                                    COMPONENT.setLavanderia(idLavanderia);
                                  }
                                }
                              },
                            }),
                          );
                        },
                      },
                    ],
                  }),
              );
            },
          },
          {
            // Range selector
            baseSize: 160,
            visible: COMPONENT.props.rangeSelector,
            template: function (e, index, itemElement) {
              if (COMPONENT.props.rangeSelector) {
                itemElement.css("background-color", "white");
                itemElement.css("padding", "15px");
                itemElement.append(COMPONENT.props.getContent_rangeSelector());
              }
            },
          },
        ],
      });
    })
    .always(function () {
      function refresh() {
        if ($("#dxTileView").length > 0) {
          $("#dxTileView").dxTileView("instance").repaint();

          let param_paginaSecundaria = COMPONENT.props.param_paginaSecundaria;
          let idCompañiaSel = param_paginaSecundaria
            ? param_paginaSecundaria.idCompañiaSel
            : -1;

          if (
            COMPONENT.props.expandir &&
            (COMPONENT.idSel ||
              datasource_datos.items().length <= 4 ||
              (param_paginaSecundaria != null && idCompañiaSel != -1))
          ) {
            var id;
            if (COMPONENT.idSel) {
              id = COMPONENT.idSel;
            } else {
              id = datasource_datos.items()[0].id;
            }
            expandirCard(
              param_paginaSecundaria != null && idCompañiaSel != -1
                ? idCompañiaSel
                : id,
              true,
            );
          }

          filtroTiempo_Resize(COMPONENT.props.scrollLeft);
        }
      }

      deferred.resolve();
      setTimeout(function () {
        refresh();
      }, 0);
    });

  function expandirCard(id, isPrimeraCarga) {
    COMPONENT.idSel = id;

    var itemData = $.grep(datasource_datos.items(), function (item) {
      return item.id === id;
    })[0];

    if (itemData) {
      var dxTileView = $("#dxTileView").dxTileView("instance");
      var itemMargin = dxTileView.option("itemMargin");
      var cardElement = $("#dxTileView .dxTileView_item_" + id);

      var container = $("#dxBox_tileViews");
      var widthTotal = container.width();
      var heightTotal = container.height();

      var item = cardElement.clone();
      var itemHeight = item.height();
      var itemWidth = item.width();

      var bodyRect = container[0].getBoundingClientRect(),
        elemRect = cardElement[0].getBoundingClientRect(),
        offsetY_itemSel = elemRect.top - bodyRect.top,
        offsetX_itemSel = elemRect.left - bodyRect.left;

      container.append(item);

      //Reseteamos la visibilidad de los items.
      var datasource = dxTileView.option("items");
      $.each(datasource, function (index, item) {
        item.widthRatio = 1;
      });

      //Escondemos el item seleccionado
      itemData.widthRatio = -1;

      //Adaptamos el item seleccionado y le añadimos el contenido BACK
      item.removeClass("dx-state-active");
      item.css("top", offsetY_itemSel);
      item.css("left", offsetX_itemSel);
      item.css("position", "absolute");

      //PRIMERA CARGA
      if (isPrimeraCarga) {
        //Creamos el contenido del back
        item
          .find(".cardContent")
          .append(
            $("<div />")
              .addClass("dxCard_back")
              .append(COMPONENT.props.getContent_back(COMPONENT.idSel)),
          );

        //Eliminamos el item
        cardElement.remove();

        //ITEMS NO SELECCIONADOS SE POSICIONAN A LA DERECHA.
        var offsetX = Math.ceil(widthTotal / 3 - itemWidth - itemMargin) * -1;
        var offsetY = itemMargin;

        $.each($("#dxTileView .dx-item.dx-tile"), function (index, card) {
          fx.animate(card, {
            duration: 200,
            to: {
              width: widthTotal / 3 - itemMargin * 2,
              position: {
                my: "right top",
                at: "right top",
                offset: offsetX + " " + offsetY,
                of: container,
              },
            },
          });

          offsetY += itemMargin + itemHeight;
        });
      }

      //ACUTALIZAMOS LA ACTIVE CARD
      if (!isPrimeraCarga) {
        $(".activeCard .cardHeader > div > span").text(itemData.denominacion);
        refreshContent();
      }

      ////Posicionamos la carta en la misma posicion pero fixed para que no se oculte detrás de dxCardSel
      var isCardFullPage = datasource.length === 1;

      //Substituimos el dxCard_front por el nombre del item sel
      item.find(".dxCard_front").empty();
      item
        .find(".dxCard_front")
        .append(
          $("<div />")
            .addClass("container_spanCentrado")
            .append(
              $("<span />")
                .text(itemData.denominacion)
                .css("font-weight", "lighter")
                .addClass("font-size-xxl"),
            ),
        );

      fx.animate(item, {
        duration: isPrimeraCarga ? 600 : 450,
        to: {
          opacity: isPrimeraCarga ? 1 : 0.3,
          width: isCardFullPage ? widthTotal : (widthTotal / 3) * 2,
          height: heightTotal - itemMargin - 5, //El 5 es para que se vea la sombra del último item del TileView
          position: {
            my: "left top",
            at: "left top",
            offset: "0 " + itemMargin,
            of: container,
          },
        },
        complete: function ($element, config) {
          if (isPrimeraCarga) {
            $element.css("position", "inherit");
            $element.css("height", "100%");
            $element.css("width", "100%");

            $(".activeCard").empty();

            $(".activeCard").append($element);

            if ($("#dxBox_tileViews").data("dxBox") != null)
              $("#dxBox_tileViews")
                .dxBox("instance")
                .option("items[0].visible", true); //Active card visible

            if (!isCardFullPage) {
              $("#dxTileView .dx-item.dx-tile")
                .css("left", itemMargin + "px")
                .css("width", widthTotal / 3 - itemMargin * 2);
            } else {
              if ($("#dxBox_tileViews").data("dxBox") != null)
                $("#dxBox_tileViews")
                  .dxBox("instance")
                  .option("items[1].visible", false);
            }

            setTimeout(function () {
              refreshContent();
            }, 0);
          } else {
            $element.remove();
          }
        },
      });
    }
  }

  return deferred.promise();
}

export function refreshContent() {
  var dxBox_tileViews = $("#dxBox_tileViews").dxBox("instance");
  if (dxBox_tileViews) {
    dxBox_tileViews.beginUpdate();

    COMPONENT.props.refreshContent_header(COMPONENT.idSel);
    COMPONENT.props.refreshContent_front();
    COMPONENT.props.refreshContent_back(COMPONENT.idSel);

    dxBox_tileViews.endUpdate();
  }
}
