import React, { useEffect, useState } from "react";

import DxPopup from "devextreme-react/popup";

import Form from "./components/Form";
import { getTrad } from "helpers";
import { useRecambiosProvider } from "../RecambiosProvider";

const Popup = ({ buscadorRef, visible, onHiding, onHidden }) => {
  const { recambioSel } = useRecambiosProvider();

  const [isFormVisible, setIsFormVisible] = useState(visible);

  useEffect(() => {
    if (visible) {
      setIsFormVisible(true);
    }
  }, [visible]);

  const { idRecambio, referenciaInterna, referencia, denominacion } =
    recambioSel;

  const title = idRecambio
    ? `${referenciaInterna ? `${referenciaInterna} - ` : ""}${
        referencia ? `${referencia} - ` : ""
      }${denominacion}`
    : getTrad("nuevoRecambio");

  const this_onHidden = () => {
    setIsFormVisible(false);
    onHidden();
  };

  return (
    <DxPopup
      visible={visible}
      title={title}
      width={"60%"}
      onHiding={onHiding}
      onHidden={this_onHidden}
    >
      <div>
        {isFormVisible ? (
          <Form buscadorRef={buscadorRef} onHiding={onHiding} />
        ) : null}
      </div>
    </DxPopup>
  );
};

export default Popup;
