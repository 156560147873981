import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  formatDate_parameter,
  formatNumber,
  getNextNMonth,
  formatDateTime_parameter,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";
import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import { Button, DateBox, RadioGroup, TextArea } from "devextreme-react";
import { SelectBox } from "devextreme-react/select-box";
import TreeView from "devextreme-react/tree-view";
import { TextBox } from "devextreme-react/text-box";
import { Lookup, DropDownOptions } from "devextreme-react/lookup";
import Form, { TabbedItem, TabPanelOptions, Tab } from "devextreme-react/form";
import { Switch } from "devextreme-react/switch";
import { Tooltip } from "devextreme-react/tooltip";
import ODataContext from "devextreme/data/odata/context";
import { PopupIncoherencia } from "components/Recambios/PopupIncoherencia";
import { PopupHistorico } from "./components/PopupHistorico";

import Box, { Item as ItemBox } from "devextreme-react/box";
import DataGrid, {
  Column,
  KeyboardNavigation,
  FilterRow,
  Summary,
  TotalItem,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  CustomRule,
  Editing,
  Scrolling,
  LoadPanel,
  Export,
  Format,
  Grouping,
  Lookup as LookupGrid,
} from "devextreme-react/data-grid";
import List from "devextreme-react/list";
import {
  Popup,
  ToolbarItem as ToolbarItemPopup,
  Position,
} from "devextreme-react/popup";
import { Row, Col } from "reactstrap";
import {
  Validator,
  AsyncRule as AsyncRuleTextBox,
} from "devextreme-react/validator";

//CUSTOM COMPONENTS
import PopupCierrePrecios from "./components/PopupCierrePrecios/index";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

//Css
import "./Css.scss";

//Informes
import InfRecambios from "pages/Assistant/InfRecambios";

const idsCargo = {
  Desarrollador: 1,
  Master: 2,
};

const idsCategoriaInterna = {
  JEFE_DE_MANTENIMIENTO: 24,
};

class GestionAlmacenes extends React.Component {
  constructor(props) {
    super(props);

    this.visibleToUser_isValidado =
      [idsCargo.Desarrollador, idsCargo.Master].includes(
        this.props.user.idCargo,
      ) ||
      this.props.user.idCategoriaInterna ===
        idsCategoriaInterna.JEFE_DE_MANTENIMIENTO;

    this.state = {
      param_paginaSecundaria: null,
      almacenSel: null,
      subAlmacenSel: null,
      showPopup_MovimientoRecambios: false,
      showPopup_list_proveedores: false,
      items_almacen_general: [],
      items_almacen_origen: [],
      items_almacen_destino: [],
      tblMovimientoRecambio: {
        almacenDestino: null,
        almacenDestino_bak: null,
        idAlmacenOrigen: null,
        idProveedor: null,
        clienteDestino: null,
        fecha: null,
        observaciones: null,
        codigoAlbaranProveedor: null,
        numPedidoAsociado: null,
        numRegistro: null,
        idTipoMovimientoRecambio: null,
        tblRecambioNMovimientoRecambio: [],
      },
      redirect: false,
      loaded: false,
      idTipoAlmacen: null,
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio: null,
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario: null,
      is_submitData: false,
      list_tipoMovimiento_selectedIndex: 0,
      is_switchSubAlmacen: true,
      isVisible_busquedaAvanzada: false,
      recambioSel_busquedaAvanzada: null,
      is_inventarioPistolaLectora: false,
      recambioPistolaSel: {},
      isVisible_cierrePrecios: false,
      dxCalendar_cierrePrecios_value: null,
      tblCierreRecambioNAlmacen: [],
      is_movimientoBloqueado: false,
      isNumPedidoAsociadoValid: true,
      isNumRegistroValid: true,
      tblMovimientoRecambio_bk: null,
      isGuardarClick: false,
      data_busquedaAvanzada_tblRecambioNAlmacenRecambios: [],
      isVisible_dxPopup_errorIncoherencia: false,
      data_dxPopup_errorIncoherencia: [],
      isVisible_dxPopup_historialRecambio: false,
      data_dxPopup_dxPopup_historialRecambio: [],
      recambioNAlmacenSel: null,
      isLoading_popupGrid: false,
    };

    this.data = {
      almacenSel_validation_row: null, //row pendiente de validación para que si das a cancelar vuelva atrás
      movimientoSel_validation_row: null,
      nuevoRecambioSel: null,
      referenciasProveedor_datasource: null,
      tblRecambioNEntrada_editingKey: null,
    };

    // REFERENCIAS
    this.dxForm_tblAlmacenRecambios_REF = React.createRef();
    this.dxDataGrid_tblRecambioNMovimientoRecambio_REF = React.createRef();
    this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF =
      React.createRef();
    this.dxLookupAlmacenes_REF = React.createRef();
    this.dxSelectboxRecambios_REF = React.createRef();
    this.dxDataGrid_tblMovimientoRecambio_REF = React.createRef();
    this.dxDataGrid_tblRecambioNAlmacenRecambios_REF = React.createRef();
    this.dxTextBox_clienteDestino_REF = React.createRef();
    this.list_proveedor_REF = React.createRef();
    this.dxLookupProveedores_REF = React.createRef();
    this.dxLookupAlmacenOrigen_REF = React.createRef();
    this.popup_movimientoRecambios_REF = React.createRef();
    this.list_referenciasProveedor_REF = React.createRef();
    this.dxSelectboxRecambios_busquedaAvanzada_REF = React.createRef();
    this.dxDataGrid_busquedaAvanzada_REF = React.createRef();
    this.dxTreeView_AlmacenRecambios_REF = React.createRef();
    this.dxTextBox_refFabricante_regularizacion_REF = React.createRef();
    this.dxTextBox_real_regularizacion_REF = React.createRef();
    this.dxPopover_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblAlmacenRecambios_onInserted =
      this.datasource_tblAlmacenRecambios_onInserted.bind(this);
    this.datasource_tblAlmacenRecambios_onUpdated =
      this.datasource_tblAlmacenRecambios_onUpdated.bind(this);
    this.datasource_tblMovimientoRecambio_onInsertedUpdated =
      this.datasource_tblMovimientoRecambio_onInsertedUpdated.bind(this);
    this.datasource_tblMovimientoRecambio_onRemoved =
      this.datasource_tblMovimientoRecambio_onRemoved.bind(this);

    this.datasource_tblRecambioNAlmacenRecambios_regularizacion_beforeSend =
      this.datasource_tblRecambioNAlmacenRecambios_regularizacion_beforeSend.bind(
        this,
      );
    this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onInserted =
      this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onInserted.bind(
        this,
      );
    this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onUpdated =
      this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onUpdated.bind(
        this,
      );
    this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onLoaded =
      this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onLoaded.bind(
        this,
      );
    this.datasource_tblRecambio_busquedaAvanzada_onLoading =
      this.datasource_tblRecambio_busquedaAvanzada_onLoading.bind(this);
    this.datasource_tblRecambio_byReferencia_onLoading =
      this.datasource_tblRecambio_byReferencia_onLoading.bind(this);
    this.datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_onLoaded =
      this.datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_onLoaded.bind(
        this,
      );

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //EVENTOS
    this.onSelectionChanged_tblAlmacenRecambios_principales =
      this.onSelectionChanged_tblAlmacenRecambios_principales.bind(this);
    this.onToolbarPreparing_tblAlmacenRecambios_principales =
      this.onToolbarPreparing_tblAlmacenRecambios_principales.bind(this);
    this.onToolbarPreparing_tblAlmacenRecambios_secundarios =
      this.onToolbarPreparing_tblAlmacenRecambios_secundarios.bind(this);
    this.onSelectionChanged_tblAlmacenRecambios_secundarios =
      this.onSelectionChanged_tblAlmacenRecambios_secundarios.bind(this);
    this.onRowPrepared_tblAlmacenRecambios =
      this.onRowPrepared_tblAlmacenRecambios.bind(this);
    this.onRowPrepared_tblMovimientoRecambio =
      this.onRowPrepared_tblMovimientoRecambio.bind(this);
    this.onToolbarPreparing_tblRecambioNAlmacenRecambios =
      this.onToolbarPreparing_tblRecambioNAlmacenRecambios.bind(this);
    this.onHiding_dxPopup_busquedaAvanzada =
      this.onHiding_dxPopup_busquedaAvanzada.bind(this);
    this.PopupCierrePrecios_onHiding =
      this.PopupCierrePrecios_onHiding.bind(this);
    this.dxSelectboxRecambios_busquedaAvanzada_onValueChanged =
      this.dxSelectboxRecambios_busquedaAvanzada_onValueChanged.bind(this);
    this.render_toolbar_dxPopupBusquedadAvanzada =
      this.render_toolbar_dxPopupBusquedadAvanzada.bind(this);
    this.btnAceptar_busquedaAvanzada_onClick =
      this.btnAceptar_busquedaAvanzada_onClick.bind(this);
    this.dxTextArea_comentarioMovimiento_onValueChanged =
      this.dxTextArea_comentarioMovimiento_onValueChanged.bind(this);
    this.dxButton_regularizacion_cancelSelectRow_onClick =
      this.dxButton_regularizacion_cancelSelectRow_onClick.bind(this);
    this.dxDatagrid_regularizacion_cancelSelectRow =
      this.dxDatagrid_regularizacion_cancelSelectRow.bind(this);
    this.cellRender_tblRecambioNAlmacen_edit =
      this.cellRender_tblRecambioNAlmacen_edit.bind(this);
    this.tblRecambioNAlmacen_edit_onClick =
      this.tblRecambioNAlmacen_edit_onClick.bind(this);
    this.onHiding_dxPopup_historialRecambio =
      this.onHiding_dxPopup_historialRecambio.bind(this);
    this.onHidden_dxPopup_historialRecambio =
      this.onHidden_dxPopup_historialRecambio.bind(this);
    this.onShown_dxPopup_historialRecambio =
      this.onShown_dxPopup_historialRecambio.bind(this);
    this.onCellPrepared_PopupGrid_recambios =
      this.onCellPrepared_PopupGrid_recambios.bind(this);

    //#region Popup Movimientos
    this.hiding_popup_MovimientoRecambios =
      this.hiding_popup_MovimientoRecambios.bind(this);
    this.dxSelectboxRecambios_filter_onValueChanged =
      this.dxSelectboxRecambios_filter_onValueChanged.bind(this);
    this.dxSelectboxRecambios_displayExpr =
      this.dxSelectboxRecambios_displayExpr.bind(this);
    this.onToolbarPreparing_tblMovimientoRecambio =
      this.onToolbarPreparing_tblMovimientoRecambio.bind(this);
    this.onSelectionChanged_dxLookupProveedores =
      this.onSelectionChanged_dxLookupProveedores.bind(this);
    this.onSelectionChanged_dxLookupAlmacenOrigen =
      this.onSelectionChanged_dxLookupAlmacenOrigen.bind(this);
    this.dxValidator_numPedidoAsociado_asyncRule_validationCallback =
      this.dxValidator_numPedidoAsociado_asyncRule_validationCallback.bind(
        this,
      );
    this.dxValidator_numRegistro_asyncRule_validationCallback =
      this.dxValidator_numRegistro_asyncRule_validationCallback.bind(this);
    this.onSelectionChanged_dxLookupAlmacenes =
      this.onSelectionChanged_dxLookupAlmacenes.bind(this);
    this.onSelectionChanged_tblMovimientoRecambio =
      this.onSelectionChanged_tblMovimientoRecambio.bind(this);
    this.onEditorPreparing_tblMovimientoRecambio_gestionAlmacenes =
      this.onEditorPreparing_tblMovimientoRecambio_gestionAlmacenes.bind(this);
    this.onToolbarPreparing_tblRecambioNMovimientoRecambio =
      this.onToolbarPreparing_tblRecambioNMovimientoRecambio.bind(this);
    this.onEditorPreparing_tblRecambioNMovimientoRecambio =
      this.onEditorPreparing_tblRecambioNMovimientoRecambio.bind(this);
    this.onValueChanged_dxDateBox_fechaMovimiento =
      this.onValueChanged_dxDateBox_fechaMovimiento.bind(this);
    this.onValueChanged_dxTextBox_numPedidoAsociado =
      this.onValueChanged_dxTextBox_numPedidoAsociado.bind(this);
    this.onValueChanged_dxTextBox_codigoAlbaranProveedor =
      this.onValueChanged_dxTextBox_codigoAlbaranProveedor.bind(this);
    this.onValueChanged_dxTextBox_numRegistro =
      this.onValueChanged_dxTextBox_numRegistro.bind(this);
    this.onValueChanged_dxSwitch_isInventario =
      this.onValueChanged_dxSwitch_isInventario.bind(this);
    this.datasource_tblAlmacenRecambios_postProcess =
      this.datasource_tblAlmacenRecambios_postProcess.bind(this);
    this.datasource_tblAlmacenRecambios_updRecambios_postProcess =
      this.datasource_tblAlmacenRecambios_updRecambios_postProcess.bind(this);
    this.datasource_tblAlmacenRecambios_updRecambios_onLoading =
      this.datasource_tblAlmacenRecambios_updRecambios_onLoading.bind(this);
    this.onSelectionChanged_listProveedor =
      this.onSelectionChanged_listProveedor.bind(this);
    this.onSelectionChanged_listAlmacen =
      this.onSelectionChanged_listAlmacen.bind(this);
    this.datasource_tblRecambio_beforeSend =
      this.datasource_tblRecambio_beforeSend.bind(this);
    this.validationCallback_CustomRule_MaxVal =
      this.validationCallback_CustomRule_MaxVal.bind(this);
    this.validationCallback_CustomRule_MinVal =
      this.validationCallback_CustomRule_MinVal.bind(this);
    this.datasource_tblRecambioNMovimientoRecambio_beforeSend =
      this.datasource_tblRecambioNMovimientoRecambio_beforeSend.bind(this);
    this.datasource_tblMovimientoRecambio_beforeSend =
      this.datasource_tblMovimientoRecambio_beforeSend.bind(this);
    this.datasource_tblCierreRecambioNAlmacen_beforeSend =
      this.datasource_tblCierreRecambioNAlmacen_beforeSend.bind(this);
    this.datasource_tblCierreRecambioNAlmacen_onLoaded =
      this.datasource_tblCierreRecambioNAlmacen_onLoaded.bind(this);
    this.dxTreeView_almacenes_onItemClick =
      this.dxTreeView_almacenes_onItemClick.bind(this);
    this.onSelectionChanged_list_tipoMovimientoSel =
      this.onSelectionChanged_list_tipoMovimientoSel.bind(this);
    this.list_tipoMovimientoSe_itemRender =
      this.list_tipoMovimientoSe_itemRender.bind(this);
    this.onClick_btnAtras_movimientoRecambio =
      this.onClick_btnAtras_movimientoRecambio.bind(this);
    this.toolbar_tblMovimientoRecambio_render =
      this.toolbar_tblMovimientoRecambio_render.bind(this);
    this.onSelectionChanged_listProveedorNRecambio =
      this.onSelectionChanged_listProveedorNRecambio.bind(this);
    this.onHiding_showPopup_list_proveedores =
      this.onHiding_showPopup_list_proveedores.bind(this);
    this.titleRender_dxLookup_almacenReg =
      this.titleRender_dxLookup_almacenReg.bind(this);
    this.onValueChanged_dxLookup_tipoAlmacen =
      this.onValueChanged_dxLookup_tipoAlmacen.bind(this);
    this.titleRender_dxLookup_almacenTraspaso =
      this.titleRender_dxLookup_almacenTraspaso.bind(this);
    this.onContentReady_dxLookup_tipoAlmacen =
      this.onContentReady_dxLookup_tipoAlmacen.bind(this);
    this.onCellPrepared_tblRecambioNAlmacenRecambios_regularizacion =
      this.onCellPrepared_tblRecambioNAlmacenRecambios_regularizacion.bind(
        this,
      );
    this.onRowPrepared_tblRecambioNAlmacenRecambios_regularizacion =
      this.onRowPrepared_tblRecambioNAlmacenRecambios_regularizacion.bind(this);
    this.onEditorPreparing_tblRecambioNAlmacenRecambios_regularizacion =
      this.onEditorPreparing_tblRecambioNAlmacenRecambios_regularizacion.bind(
        this,
      );
    this.onRowUpdating_tblRecambioNAlmacenRecambios =
      this.onRowUpdating_tblRecambioNAlmacenRecambios.bind(this);
    this.onToolbarPreparing_tblRecambioNAlmacenRecambios_regularizacion =
      this.onToolbarPreparing_tblRecambioNAlmacenRecambios_regularizacion.bind(
        this,
      );
    this.dxSelectboxRecambios_regularizacion_filter_onValueChanged =
      this.dxSelectboxRecambios_regularizacion_filter_onValueChanged.bind(this);
    this.onValueChanged_Switch_tblRecambioNAlmacenRecambios =
      this.onValueChanged_Switch_tblRecambioNAlmacenRecambios.bind(this);
    this.calculateCellValue_dxDataGrid_regularizacion =
      this.calculateCellValue_dxDataGrid_regularizacion.bind(this);
    this.datasource_tblMovimientoRecambio_onLoaded =
      this.datasource_tblMovimientoRecambio_onLoaded.bind(this);
    this.datasource_tblMovimientoRecambio_postProcess =
      this.datasource_tblMovimientoRecambio_postProcess.bind(this);
    this.datasource_tblRecambios_regularizacion_postProcess =
      this.datasource_tblRecambios_regularizacion_postProcess.bind(this);
    this.onValueChanged_dxSwitch_isInventarioPistolaLectora =
      this.onValueChanged_dxSwitch_isInventarioPistolaLectora.bind(this);
    this.dxTextBox_refFabricante_regularizacion_onValueChanged =
      this.dxTextBox_refFabricante_regularizacion_onValueChanged.bind(this);
    this.dxTextBox_real_regularizacion_onValueChanged =
      this.dxTextBox_real_regularizacion_onValueChanged.bind(this);
    this.dxTextBox_real_regularizacion_onFocusOut =
      this.dxTextBox_real_regularizacion_onFocusOut.bind(this);
    this.dxButton_eliminarFiltro_regulariacion_onClick =
      this.dxButton_eliminarFiltro_regulariacion_onClick.bind(this);
    this.tblMovimientos_remove_cellRender =
      this.tblMovimientos_remove_cellRender.bind(this);
    this.allowDeleting = this.allowDeleting.bind(this);
    this.idAlmacenPadre_groupCellRender =
      this.idAlmacenPadre_groupCellRender.bind(this);
    this.dxSelectboxRecambiosNProveedor_itemRender =
      this.dxSelectboxRecambiosNProveedor_itemRender.bind(this);
    this.dxSelectboxRecambiosNProveedor_itemRender_onMouseOver =
      this.dxSelectboxRecambiosNProveedor_itemRender_onMouseOver.bind(this);
    this.dxSelectboxRecambiosNProveedor_itemRender_onMouseLeave =
      this.dxSelectboxRecambiosNProveedor_itemRender_onMouseLeave.bind(this);
    this.onHiding_dxPopup_errorIncoherencia =
      this.onHiding_dxPopup_errorIncoherencia.bind(this);

    //HIPERVINCULO
    this.backButton_paginaSecundaria_onClick =
      this.backButton_paginaSecundaria_onClick.bind(this);
    this.getBackButton_paginaSecundaria =
      this.getBackButton_paginaSecundaria.bind(this);

    //#endregion

    this.get_recambios = this.get_recambios.bind(this);

    //TEMPLATE
    this.tituloAlmacenes_render = this.tituloAlmacenes_render.bind(this);
    this.dxTreeView_almacenes_render =
      this.dxTreeView_almacenes_render.bind(this);

    //MÉTODOS
    this.customizeText_sum_totalEntrada =
      this.customizeText_sum_totalEntrada.bind(this);
    this.setCantidadesTotales = this.setCantidadesTotales.bind(this);

    //OPCIONES WIDGETS
    this.popup_MovimientoRecambios_backBtn_options = {
      icon: "chevronleft",
      width: 45,
      type: "normal",
      onClick: this.onClick_btnAtras_movimientoRecambio,
    };
  }

  //REFERENCIAS
  get dxForm_tblAlmacenRecambios() {
    return this.dxForm_tblAlmacenRecambios_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNMovimientoRecambio() {
    return this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion() {
    return this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
      .current.instance;
  }

  get dxLookupAlmacenes() {
    return this.dxLookupAlmacenes_REF.current.instance;
  }

  get dxSelectboxRecambios() {
    return this.dxSelectboxRecambios_REF.current.instance;
  }

  get dxDataGrid_tblMovimientoRecambio() {
    return this.dxDataGrid_tblMovimientoRecambio_REF.current.instance;
  }

  get dxDataGrid_tblRecambioNAlmacenRecambios() {
    return this.dxDataGrid_tblRecambioNAlmacenRecambios_REF.current.instance;
  }

  get list_proveedor() {
    return this.list_proveedor_REF.current.instance;
  }

  get dxLookupProveedores() {
    return this.dxLookupProveedores_REF.current.instance;
  }

  get dxLookupAlmacenOrigen() {
    return this.dxLookupAlmacenOrigen_REF.current.instance;
  }

  get dxTextBox_clienteDestino() {
    return this.dxTextBox_clienteDestino_REF.current.instance;
  }

  get popup_movimientoRecambios() {
    return this.popup_movimientoRecambios_REF.current.instance;
  }

  get list_referenciasProveedor() {
    return this.list_referenciasProveedor_REF.current.instance;
  }

  get dxSelectboxRecambios_busquedaAvanzada() {
    return this.dxSelectboxRecambios_busquedaAvanzada_REF.current.instance;
  }

  get dxDataGrid_busquedaAvanzada() {
    return this.dxDataGrid_busquedaAvanzada_REF.current.instance;
  }

  get dxTreeView_AlmacenRecambios() {
    return this.dxTreeView_AlmacenRecambios_REF.current.instance;
  }

  get dxTextBox_refFabricante_regularizacion() {
    return this.dxTextBox_refFabricante_regularizacion_REF.current.instance;
  }

  get dxTextBox_real_regularizacion() {
    return this.dxTextBox_real_regularizacion_REF.current.instance;
  }

  get dxPopover() {
    return this.dxPopover_REF.current.instance;
  }

  //#region DATA SOURCES
  datasource_tblAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblAlmacenRecambios_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblAlmacenRecambios_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblAlmacenRecambios_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_tblAlmacenRecambios_postProcess(data);
    },
    filter: ["activo eq true and eliminado eq false"],
    select: ["idAlmacen,denominacion,idAlmacenPadre, idPais"],
    sort: ["denominacion"],
    expand: [
      "tblMoneda($select=codigo),tblRecambioNAlmacenRecambios($select=cantidad,idRecambio,precioMedioPonderado,ubicacion;$expand=tblRecambio($select=referencia,referenciaInterna,denominacion)),tblAlmacenHijo($expand=tblRecambioNAlmacenRecambios($select=cantidad,idRecambio,precioMedioPonderado,ubicacion;$expand=tblRecambio($select=referencia,referenciaInterna,denominacion)))",
    ],
  });

  datasource_tblAlmacenRecambios_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { user } = this.props;
    request.params.idPersona = user.idPersona !== null ? user.idPersona : -1;
  }

  datasource_tblAlmacenRecambios_postProcess(data) {
    let ids_almacenesConsulta = $.grep(
      this.datasource_tblAlmacenRecambiosNPersona.items(),
      function (item) {
        return item.isConsulta;
      },
    );
    let ids_almacenesNPersona = $.map(ids_almacenesConsulta, function (item) {
      return item.idAlmacen;
    });

    let filterData = this.setCantidadesTotales(data, ids_almacenesNPersona);
    this.setState({
      items_almacen_general: filterData,
      items_almacen_origen: filterData,
      items_almacen_destino: filterData,
    });
    return filterData;
  }

  datasource_tblAlmacenRecambios_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { almacenSel } = this.state;
    almacenSel.idAlmacen = key;

    $.when(this.datasource_tblAlmacenRecambios.reload()).then(function () {
      _this.setState({ almacenSel: almacenSel }, async () => {
        _this.datasource_tblAlmacenRecambios.reload();
      });
    });
  }

  datasource_tblAlmacenRecambios_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
    this.datasource_tblAlmacenRecambios.reload();
  }

  //#region tblAlmacenRecambios Actualizar recambios
  datasource_tblAlmacenRecambios_updRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblAlmacenRecambios_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblAlmacenRecambios_updRecambios_onLoading(loadOptions);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_tblAlmacenRecambios_updRecambios_postProcess(data);
    },
    filter: ["activo eq true and eliminado eq false"],
    select: ["idAlmacen, idAlmacenPadre", "denominacion", "idPais"],
    sort: ["denominacion"],
    expand: [
      "tblMoneda($select=codigo),tblRecambioNAlmacenRecambios($select=cantidad,idRecambio,precioMedioPonderado,ubicacion;$expand=tblRecambio($select=referencia,referenciaInterna,denominacion)),tblAlmacenHijo($expand=tblRecambioNAlmacenRecambios($select=cantidad,idRecambio,precioMedioPonderado,ubicacion;$expand=tblRecambio($select=referencia,referenciaInterna,denominacion)))",
    ],
  });

  datasource_tblAlmacenRecambios_updRecambios_onLoading(loadOptions) {
    let { tblMovimientoRecambio } = this.state;

    let idsRecambio;
    if (tblMovimientoRecambio.idTipoMovimientoRecambio != 4)
      idsRecambio = tblMovimientoRecambio.tblRecambioNMovimientoRecambio.map(
        (rec) => rec.idRecambio,
      );
    else
      idsRecambio = this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion
        .getVisibleRows()
        .map((rec) => rec.data.idRecambio);

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          "tblRecambioNAlmacenRecambios/any(x: x/idRecambio in (" +
            idsRecambio +
            "))",
        ],
      ];
    else
      loadOptions.filter = [
        [
          "tblRecambioNAlmacenRecambios/any(x: x/idRecambio in (" +
            idsRecambio +
            "))",
        ],
      ];

    loadOptions.expand =
      "tblRecambioNAlmacenRecambios($select=cantidad,idRecambio,precioMedioPonderado,ubicacion;$expand=tblRecambio($select=referencia,referenciaInterna,denominacion);$filter=idRecambio in (" +
      idsRecambio +
      "))";
  }

  datasource_tblAlmacenRecambios_updRecambios_postProcess(data) {
    let ids_almacenesConsulta = $.grep(
      this.datasource_tblAlmacenRecambiosNPersona.items(),
      function (item) {
        return item.isConsulta;
      },
    );
    let ids_almacenesNPersona = $.map(ids_almacenesConsulta, function (item) {
      return item.idAlmacen;
    });

    let { items_almacen_general } = this.state;
    data = this.setCantidadesTotales(data, ids_almacenesNPersona, true);
    const updRecambiosNAlm = (almacen) => {
      data.forEach((udpAlm) => {
        if (almacen.idAlmacen == udpAlm.idAlmacen) {
          udpAlm.tblRecambioNAlmacenRecambios.forEach((udpRec) => {
            // Recambios N Alm nuevos
            let idsRecambioAlmAntiguo =
              almacen.tblRecambioNAlmacenRecambios.map(
                (item) => item.idRecambio,
              );
            if (!idsRecambioAlmAntiguo.includes(udpRec.idRecambio)) {
              almacen.tblRecambioNAlmacenRecambios.push(udpRec);
            }

            almacen.tblRecambioNAlmacenRecambios.forEach((recambioNAlm) => {
              // Recambios N Alm antiguos
              if (udpRec.idRecambio == recambioNAlm.idRecambio) {
                //Actualizo el objeto del recambioNAlmacen con los datos del wapi
                Object.keys(udpRec).forEach(
                  (x) => (recambioNAlm[x] = udpRec[x]),
                );
              }
            });
          });
        }
      });
    };

    items_almacen_general.forEach((almacen) => {
      almacen.tblAlmacenHijo.forEach((subAlm) => {
        updRecambiosNAlm(subAlm);
      });
      updRecambiosNAlm(almacen);
    });

    this.setState({
      items_almacen_general: items_almacen_general,
      items_almacen_origen: items_almacen_general,
      items_almacen_destino: items_almacen_general,
    });
    return data;
  }
  //#endregion

  datasource_tblCierreRecambioNAlmacen = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblCierreRecambioNAlmacen/GetFechasNAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCierreRecambioNAlmacen_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_tblCierreRecambioNAlmacen_onLoaded(data);
      },
      version: 4,
    }),
  });

  datasource_tblCierreRecambioNAlmacen_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblCierreRecambioNAlmacen_onLoaded(data) {
    this.setState({ tblCierreRecambioNAlmacen: data });
  }

  context_GestionAlmacenes = new ODataContext({
    url:
      connectionConstants.WEB_API_CORE_ODATA_URL +
      "MyPolarier/Assistant/GestionAlmacenes/",
    entities: {
      setIsValidado: {
        key: "idMovimientoRecambio",
        keyType: "Int32",
      },
    },
    beforeSend: (request) => (request.headers = { ...authHeader() }),
  });

  datasource_tblMovimientoRecambio = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMovimientoRecambio",
      key: "idMovimientoRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblMovimientoRecambio_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblMovimientoRecambio_onInsertedUpdated(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblMovimientoRecambio_onInsertedUpdated(values, key);
      },
      onRemoved: (values, key) => {
        this.datasource_tblMovimientoRecambio_onRemoved(values, key);
      },
      onLoaded: (data) => {
        this.datasource_tblMovimientoRecambio_onLoaded(data);
      },
      version: 4,
    }),
    expand: [
      "tblAlmacenRecambios_destino($select=idAlmacen,denominacion;$expand=tblMoneda($select=codigo),tblPais($select=idPais)),tblAlmacenRecambios_origen($select=idAlmacen,denominacion,idAlmacenPadre;$expand=tblAlmacenHijo($select=idAlmacen, denominacion, activo, eliminado, idAlmacenPadre, idMoneda, idPais),tblMoneda($select=codigo)),tblRecambioNMovimientoRecambio($expand=tblRecambio)",
    ],
    postProcess: (data) => {
      return this.datasource_tblMovimientoRecambio_postProcess(data);
    },
  });

  datasource_tblMovimientoRecambio_postProcess(data) {
    let tblCierreRecambioNAlmacen = [...this.state.tblCierreRecambioNAlmacen];

    $.each(data, function (index, item) {
      let cierresAlmMov = tblCierreRecambioNAlmacen.filter(
        (x) =>
          x.idAlmacen == item.idAlmacenOrigen ||
          x.idAlmacen == item.idAlmacenDestino,
      ); // Fecha cierre con almacen actual

      item.isEditable =
        cierresAlmMov.length == 0
          ? true
          : $.grep(cierresAlmMov, function (arg) {
              return (
                item.fecha >=
                  new Date(getNextNMonth(new Date(arg.fecha), 1)[0]) && // Fecha movimiento mayor a fecha cierre (Mov no cerrado)
                new Date(item.fecha).getTime() <
                  new Date(getNextNMonth(new Date(arg.fecha), 3)[0]).getTime()
              ); // Dos meses después al mes mov cerrado
            }).length > 0;

      item.isEliminarEnable =
        cierresAlmMov.length == 0 ? true : item.isEditable;
    });

    return data;
  }

  datasource_tblMovimientoRecambio_onLoaded(data) {
    let _this = this;
    let ids_almacenesNPersona = this.get_idsAlmacenNPersona(data);

    let { user } = this.props;
    let is_userMasterDev = user.idCargo === 1 || user.idCargo === 2;

    let movimientosFiltrados = $.grep(data, function (item) {
      // si es master se muestran todos los almacenes + filtro por tipo de movimiento sel
      if (!is_userMasterDev)
        return (
          (ids_almacenesNPersona.includes(item.idAlmacenOrigen) ||
            ids_almacenesNPersona.includes(item.idAlmacenDestino)) &&
          item.idTipoMovimientoRecambio ===
            _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio
        );
      else
        return (
          item.idTipoMovimientoRecambio ===
          _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio
        );
    });

    let item = $.map(movimientosFiltrados, function (item, index) {
      if (
        item.tblAlmacenRecambios_origen !== null &&
        item.tblAlmacenRecambios_origen.idAlmacenPadre !== null
      ) {
        // Es almacén hijo
        let almacenes = _this.state.items_almacen_general;
        let almacenPadre = $.grep(almacenes, function (almPadre) {
          return (
            almPadre.idAlmacen ===
            item.tblAlmacenRecambios_origen.idAlmacenPadre
          );
        })[0];
        if (almacenPadre !== undefined) {
          item.tblAlmacenRecambios_origen.tblMoneda =
            almacenPadre.tblMoneda !== null &&
            almacenPadre.tblMoneda !== undefined
              ? {
                  codigo: almacenPadre.tblMoneda.codigo,
                }
              : null;
        }
      }

      return {
        idMovimientoRecambio: item.idMovimientoRecambio,
        idTipoMovimientoRecambio: item.idTipoMovimientoRecambio,
        almacenDestino: item.tblAlmacenRecambios_destino,
        idAlmacenOrigen: item.idAlmacenOrigen,
        idProveedor: item.idProveedor,
        clienteDestino: item.clienteDestino,
        fecha: item.fecha,
        observaciones: item.observaciones,
        codigoAlbaranProveedor: item.codigoAlbaranProveedor,
        numPedidoAsociado: item.numPedidoAsociado,
        numRegistro: item.numRegistro,
        tblAlmacenRecambios_origen: item.tblAlmacenRecambios_origen,
        tblAlmacenRecambios_destino: item.tblAlmacenRecambios_destino,
        tblRecambioNMovimientoRecambio: $.map(
          item.tblRecambioNMovimientoRecambio,
          function (item1, index1) {
            return {
              idRecambio: item1.idRecambio,
              cantidad: item1.cantidad,
              precio: item1.precio ? item1.precio : 0,
              cantidadTeorico: item.cantidadTeorico,
              tblRecambio: {
                idRecambio: item1.idRecambio,
                referencia: item1.tblRecambio.referencia,
                referenciaInterna: item1.tblRecambio.referenciaInterna,
                denominacion: item1.tblRecambio.denominacion,
              },
            };
          },
        ),
      };
    });
    return item;
  }

  datasource_tblMovimientoRecambio_beforeSend(request) {
    let { tblMovimientoRecambio } = this.state;
    let { idTipoMovimientoRecambio } = this.state.tblMovimientoRecambio;
    let { user } = this.props;

    request.timeout = 300000;
    request.headers = { ...authHeader() };

    if (
      request.method == "PATCH" &&
      tblMovimientoRecambio.idMovimientoRecambio != null
    ) {
      request.params.isPatchPrendas = true;
    } else if (request.method != "DELETE") {
      request.params.idTipoMovimientoRecambio =
        idTipoMovimientoRecambio != null ? idTipoMovimientoRecambio : -1;
      request.params.idPersona = user.idPersona !== null ? user.idPersona : -1;
    }

    if (request.method == "DELETE") {
      if (this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
        this.dxDataGrid_tblMovimientoRecambio.beginCustomLoading();

      if (this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current != null)
        this.dxDataGrid_tblRecambioNMovimientoRecambio.beginCustomLoading();
    }
  }

  setCantidadesTotales(data, ids_almacenesNPersona, isUpdateData) {
    let { user } = this.props;
    let is_userMasterDev = user.idCargo === 1 || user.idCargo === 2;

    // SUMATORIO CANTIDADES DE ALMACEN Y SUB ALMACENES
    let sumSubAlm = [];
    $.each(data, function (index, item) {
      if (item.idAlmacenPadre !== null) {
        $.each(item.tblRecambioNAlmacenRecambios, function (subIndex, subItem) {
          let subAlm = {
            idAlmacen: item.idAlmacenPadre,
            idRecambio: subItem.idRecambio,
            cantidad: subItem.cantidad,
            precioMedioPonderado: subItem.precioMedioPonderado,
            ubicacion: subItem.ubicacion,
            tblRecambio: subItem.tblRecambio,
          };

          if (subItem.cantidad > 0) sumSubAlm.push(subAlm);
        });
      }
    });

    let filterData = $.grep(data, function (item) {
      if (item.idAlmacenPadre === null) {
        if (is_userMasterDev != null) {
          $.each(item.tblAlmacenHijo, function (iAlmHijo, almHijo) {
            //Mostrar según permisos de persona
            almHijo.visible = true;
            if (
              !ids_almacenesNPersona.includes(almHijo.idAlmacen) &&
              !is_userMasterDev
            )
              almHijo.visible = false;

            //MONEDA
            almHijo.tblMoneda =
              item.tblMoneda !== null && item.tblMoneda !== undefined
                ? {
                    codigo: item.tblMoneda.codigo,
                  }
                : null;
          });
        }

        //Añadir cantidad 0 a los que tengan en subAlm y no en almPrinc
        $.each(sumSubAlm, function (subIndex, subItem) {
          //Comprobar que es subAlm de este alm. principal
          if (item.idAlmacen === subItem.idAlmacen) {
            let recambioNAlmPrinc = $.grep(
              item.tblRecambioNAlmacenRecambios,
              function (itemAlm) {
                return itemAlm.idRecambio === subItem.idRecambio;
              },
            );

            //Si el recambio del subAlm no está en alm. principal
            if (recambioNAlmPrinc.length <= 0) {
              let subItem_data = (data = $.extend(true, {}, subItem));
              subItem_data.cantidad = 0;
              subItem_data.cantidadTotal = subItem.cantidad;
              subItem_data.cantidadSecundarios = subItem.cantidad;
              item.tblRecambioNAlmacenRecambios.push(subItem_data);
            }
          }
        });

        //CANTIDADES
        $.each(item.tblRecambioNAlmacenRecambios, function (recIndex, recItem) {
          let cantidadTotal = recItem.cantidad,
            cantidadSecundarios = 0;
          $.each(sumSubAlm, function (subIndex, subItem) {
            // Para Sumar a la cantidad del almacen Principal, las cantidades de los sub almacenes.
            if (
              item.idAlmacen === subItem.idAlmacen &&
              recItem.idRecambio === subItem.idRecambio
            ) {
              cantidadSecundarios += subItem.cantidad;
              cantidadTotal += subItem.cantidad;
            }
          });
          recItem.cantidadTotal = cantidadTotal;
          recItem.cantidadSecundarios = cantidadSecundarios;
        });

        //Mostrar según permisos de persona
        item.visible = true;
        return item;
      }

      if (isUpdateData) return item;
    });

    return filterData;
  }

  datasource_tblMovimientoRecambio_onInsertedUpdated(values, key) {
    // INSERT / UPDATE Movimientos
    let _this = this;
    let { tblMovimientoRecambio } = this.state;
    tblMovimientoRecambio.idMovimientoRecambio =
      key && key.idMovimientoRecambio ? key.idMovimientoRecambio : key;

    let tblCierreRecambioNAlmacen = [...this.state.tblCierreRecambioNAlmacen];
    tblMovimientoRecambio.isEditable =
      $.grep(tblCierreRecambioNAlmacen, function (arg) {
        return (
          tblMovimientoRecambio.fecha >=
            new Date(getNextNMonth(new Date(arg.fecha), 1)[0]) && // Fecha movimiento mayor a fecha cierre (Mov no cerrado)
          new Date(tblMovimientoRecambio.fecha).getTime() <
            new Date(getNextNMonth(new Date(arg.fecha), 3)[0]).getTime() && // Dos meses después al mes mov cerrado
          (arg.idAlmacen == tblMovimientoRecambio.idAlmacenOrigen ||
            arg.idAlmacen == tblMovimientoRecambio.idAlmacenDestino ||
            arg.idAlmacen ==
              (tblMovimientoRecambio.almacenDestino
                ? tblMovimientoRecambio.almacenDestino.idAlmacen
                : null))
        ); // Fecha cierre con almacen actual
      }).length > 0;

    if (!tblMovimientoRecambio.isInventario) {
      $.when(
        this.dxDataGrid_tblMovimientoRecambio.refresh(),
        this.datasource_tblAlmacenRecambios_updRecambios.reload(), // Solo para recambios modificados en todos almacenes
      ).then(function () {
        notify({
          message: getTrad("aviso_C_RegistroInsertado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });

        if (_this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
          _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

        if (_this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current != null)
          _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();

        let almacenSel_ = $.grep(
          _this.state.items_almacen_general,
          function (item) {
            return (
              item.idAlmacen ===
              (_this.state.almacenSel !== null
                ? _this.state.almacenSel.idAlmacen
                : -1)
            );
          },
        );
        almacenSel_ = almacenSel_.length === 0 ? null : almacenSel_[0];

        let subAlmacenSel_ = null;
        $.each(_this.state.items_almacen_general, function (index, almacen) {
          let subAlmacen = $.grep(almacen.tblAlmacenHijo, function (subAlm) {
            return (
              subAlm.idAlmacen ===
              (_this.state.subAlmacenSel !== null
                ? _this.state.subAlmacenSel.idAlmacen
                : -1)
            );
          });

          if (subAlmacen.length > 0) subAlmacenSel_ = subAlmacen[0];
        });

        if (!_this.state.showPopup_MovimientoRecambios) {
          _this.reset_popupMovimientoRecambios(false, true);
          tblMovimientoRecambio.idTipoMovimientoRecambio = null;
        }

        _this.setState(
          {
            tblMovimientoRecambio: tblMovimientoRecambio,
            almacenSel: almacenSel_,
            subAlmacenSel: subAlmacenSel_,
            is_movimientoBloqueado:
              !tblMovimientoRecambio.isEditable ||
              tblMovimientoRecambio.idTipoMovimientoRecambio == 4,
            isGuardarClick: false,
          },
          () => {
            let subAlmacenSel = _this.state.subAlmacenSel;
            let almacenSel = _this.state.almacenSel;

            let dataSource =
              subAlmacenSel !== null
                ? subAlmacenSel.tblRecambioNAlmacenRecambios
                : almacenSel !== null
                  ? almacenSel.tblRecambioNAlmacenRecambios
                  : [];

            _this.dxDataGrid_tblRecambioNAlmacenRecambios.option(
              "dataSource",
              dataSource,
            );
            setTimeout(() => {
              _this.dxDataGrid_tblRecambioNAlmacenRecambios.refresh();
              _this.dxDataGrid_tblRecambioNAlmacenRecambios.repaint();
            }, 0);
          },
        );
      });
    } else {
      $.when(
        this.dxDataGrid_tblMovimientoRecambio.refresh(),
        this.datasource_tblAlmacenRecambios_updRecambios.reload(),
      ).then(function () {
        let almacenSel_ = $.grep(
          _this.state.items_almacen_general,
          function (item) {
            return (
              item.idAlmacen ===
              (_this.state.almacenSel !== null
                ? _this.state.almacenSel.idAlmacen
                : -1)
            );
          },
        );
        almacenSel_ = almacenSel_.length === 0 ? null : almacenSel_[0];

        let subAlmacenSel_ = null;
        $.each(_this.state.items_almacen_general, function (index, almacen) {
          let subAlmacen = $.grep(almacen.tblAlmacenHijo, function (subAlm) {
            return (
              subAlm.idAlmacen ===
              (_this.state.subAlmacenSel !== null
                ? _this.state.subAlmacenSel.idAlmacen
                : -1)
            );
          });

          if (subAlmacen.length > 0) subAlmacenSel_ = subAlmacen[0];
        });

        _this.setState(
          {
            tblMovimientoRecambio: tblMovimientoRecambio,
            is_movimientoBloqueado:
              !tblMovimientoRecambio.isEditable ||
              tblMovimientoRecambio.idTipoMovimientoRecambio == 4,
            isGuardarClick: false,
            almacenSel: almacenSel_,
            subAlmacenSel: subAlmacenSel_,
          },
          () => {
            let subAlmacenSel = _this.state.subAlmacenSel;
            let almacenSel = _this.state.almacenSel;

            let dataSource =
              subAlmacenSel !== null
                ? subAlmacenSel.tblRecambioNAlmacenRecambios
                : almacenSel !== null
                  ? almacenSel.tblRecambioNAlmacenRecambios
                  : [];

            _this.dxDataGrid_tblRecambioNAlmacenRecambios.option(
              "dataSource",
              dataSource,
            );

            _this.datasource_tblRecambioNMovimientoRecambio
              .reload()
              .done(function () {
                _this.dxDataGrid_tblRecambioNAlmacenRecambios.refresh();
                _this.dxDataGrid_tblRecambioNAlmacenRecambios.repaint();

                _this.setState({
                  datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
                    {
                      store: {
                        type: "array",
                        data: _this.datasource_tblRecambioNMovimientoRecambio.items(),
                        key: "idRecambio",
                      },
                    },
                });
              });
          },
        );
      });
    }
  }

  datasource_tblMovimientoRecambio_onRemoved(values, key) {
    let _this = this;
    this.datasource_tblAlmacenRecambios_updRecambios.reload().done(function () {
      // Solo para recambios modificados en todos almacenes
      notify({
        message: getTrad("aviso_C_RegistroEliminado"),
        type: "success",
        displayTime: "1500",
        closeOnClick: true,
      });

      if (_this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
        _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

      if (_this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current != null)
        _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();

      _this.datasource_tblMovimientoRecambio.reload();
      let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
      tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];

      delete tblMovimientoRecambio.idMovimientoRecambio;
      delete tblMovimientoRecambio.tblAlmacenRecambios_destino;
      delete tblMovimientoRecambio.tblAlmacenRecambios_origen;
      tblMovimientoRecambio.almacenDestino = null;
      tblMovimientoRecambio.almacenDestino_bak = null;
      tblMovimientoRecambio.idAlmacenDestino = null;
      tblMovimientoRecambio.idAlmacenOrigen = null;
      tblMovimientoRecambio.idProveedor = null;
      tblMovimientoRecambio.clienteDestino = null;
      tblMovimientoRecambio.fecha = null;
      tblMovimientoRecambio.observaciones = null;
      tblMovimientoRecambio.codigoAlbaranProveedor = null;
      tblMovimientoRecambio.numPedidoAsociado = null;
      tblMovimientoRecambio.numRegistro = null;
      tblMovimientoRecambio.isInventario = false;

      let almacenSel_ = $.grep(
        _this.state.items_almacen_general,
        function (item) {
          return (
            item.idAlmacen ===
            (_this.state.almacenSel !== null
              ? _this.state.almacenSel.idAlmacen
              : -1)
          );
        },
      );
      almacenSel_ = almacenSel_.length === 0 ? null : almacenSel_[0];

      let subAlmacenSel_ = null;
      $.each(_this.state.items_almacen_general, function (index, almacen) {
        let subAlmacen = $.grep(almacen.tblAlmacenHijo, function (subAlm) {
          return (
            subAlm.idAlmacen ===
            (_this.state.subAlmacenSel !== null
              ? _this.state.subAlmacenSel.idAlmacen
              : -1)
          );
        });

        if (subAlmacen.length > 0) subAlmacenSel_ = subAlmacen[0];
      });
      _this.setState(
        {
          tblMovimientoRecambio: tblMovimientoRecambio,
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio: null,
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario: null,
          almacenSel: almacenSel_,
          subAlmacenSel: subAlmacenSel_,
        },
        () => {
          setTimeout(() => {
            let subAlmacenSel = _this.state.subAlmacenSel;
            let almacenSel = _this.state.almacenSel;

            let dataSource =
              subAlmacenSel !== null
                ? subAlmacenSel.tblRecambioNAlmacenRecambios
                : almacenSel !== null
                  ? almacenSel.tblRecambioNAlmacenRecambios
                  : [];
            _this.dxDataGrid_tblRecambioNAlmacenRecambios.option(
              "dataSource",
              dataSource,
            );
            _this.dxDataGrid_tblRecambioNAlmacenRecambios.refresh();
            _this.dxDataGrid_tblRecambioNAlmacenRecambios.repaint();
          }, 0);
        },
      );
    });
  }

  datasource_tblRecambioNMovimientoRecambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambioNMovimientoRecambio",
      key: ["idRecambio", "idMovimientoRecambio"],
      keyType: {
        idRecambio: "Int32",
        idMovimientoRecambio: "Int32",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambioNMovimientoRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.dataSource_tblRecambioNMovimientoRecambio_onLoading(loadOptions);
      },
      version: 4,
    }),
    expand: ["tblRecambio"],
  });

  datasource_tblRecambioNMovimientoRecambio_beforeSend(request) {
    if (request.method === "POST") {
      let { idAlmacenOrigen, idProveedor } = this.state.tblMovimientoRecambio;
      request.params.idAlmacenOrigen = idAlmacenOrigen;
      request.params.idProveedor = idProveedor;
    }
    request.headers = { ...authHeader() };
  }

  dataSource_tblRecambioNMovimientoRecambio_onLoading(loadOptions) {
    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          [
            "idMovimientoRecambio",
            "=",
            this.state.tblMovimientoRecambio.idMovimientoRecambio,
          ],
        ],
      ];
    else
      loadOptions.filter = [
        [
          "idMovimientoRecambio",
          "=",
          this.state.tblMovimientoRecambio.idMovimientoRecambio,
        ],
      ];
  }

  datasource_almOrigen_tblRecambioNAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambioNAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_almOrigen_tblRecambioNAlmacenRecambios_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });

  datasource_almOrigen_tblRecambioNAlmacenRecambios_beforeSend(request) {
    let { idAlmacenOrigen } = this.state.tblMovimientoRecambio;

    request.headers = { ...authHeader() };
    request.params.idAlmacen = idAlmacenOrigen;
    request.params.idRecambio = this.data.nuevoRecambioSel.idRecambio;
  }

  datasource_almDestino_tblRecambioNAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambioNAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_almDestino_tblRecambioNAlmacenRecambios_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });

  datasource_almDestino_tblRecambioNAlmacenRecambios_beforeSend(request) {
    let {
      almacenDestino,
      idAlmacenDestino,
      idTipoMovimientoRecambio,
      idAlmacenOrigen,
    } = this.state.tblMovimientoRecambio;

    request.headers = { ...authHeader() };
    request.params.idAlmacen =
      idTipoMovimientoRecambio === 3
        ? idAlmacenOrigen
        : almacenDestino
          ? almacenDestino.idAlmacen
          : idAlmacenDestino;
    request.params.idRecambio = this.data.nuevoRecambioSel.idRecambio;
  }

  datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblRecambioNAlmacenRecambios/GetPrecioCalculado",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });

  datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado_beforeSend(
    request,
  ) {
    let {
      almacenDestino,
      idAlmacenDestino,
      idAlmacenOrigen,
      fecha,
      idTipoMovimientoRecambio,
    } = this.state.tblMovimientoRecambio;
    let selectRecambio = this.data.nuevoRecambioSel;

    let idsRecambios =
      selectRecambio != null
        ? selectRecambio.idRecambio.toString().replace(/,/g, "|")
        : this.state.tblMovimientoRecambio.tblRecambioNMovimientoRecambio
            .map((x) => x.idRecambio)
            .join("|");

    request.headers = { ...authHeader() };
    request.params.idAlmacenOrigen = idAlmacenOrigen;
    request.params.idAlmacenDestino = almacenDestino
      ? almacenDestino.idAlmacen
      : idAlmacenDestino;
    request.params.idTipoMovimientoRecambio = idTipoMovimientoRecambio;
    request.params.fecha = formatDateTime_parameter(
      new Date(new Date(fecha).setHours(fecha.getUTCHours())),
    );
    request.params.idsRecambios = idsRecambios;
  }

  datasource_tblRecambioNAlmacenRecambios_GetPrecioHistoricoRecambio =
    new DataSource({
      paginate: false,
      store: new ODataStore({
        url:
          connectionConstants.WEB_API_CORE_ODATA_URL +
          "tblRecambioNAlmacenRecambios/GetPrecioHistoricoRecambio",
        key: "idAlmacen",
        errorHandler: function (error) {
          errorHandler(error, null);
        },
        beforeSend: (request) => {
          this.datasource_tblRecambioNAlmacenRecambios_GetPrecioHistoricoRecambio_beforeSend(
            request,
          );
        },
        version: 4,
      }),
    });

  datasource_tblRecambioNAlmacenRecambios_GetPrecioHistoricoRecambio_beforeSend(
    request,
  ) {
    const { almacenSel, recambioNAlmacenSel, subAlmacenSel } = this.state;
    let selectRecambio = this.data.nuevoRecambioSel;

    request.headers = { ...authHeader() };
    request.params.idAlmacenOrigen = null;
    request.params.idAlmacenDestino = almacenSel
      ? almacenSel.idAlmacen
      : subAlmacenSel.idAlmacen;
    request.params.idTipoMovimientoRecambio = null;
    request.params.fecha = null;
    request.params.getHistorico = 1;
    request.params.idsRecambios = recambioNAlmacenSel.idRecambio.toString();
  }

  datasource_tblRecambioNProveedor_beforeSend(request) {
    let { idProveedor } = this.state.tblMovimientoRecambio;
    request.headers = { ...authHeader() };
    request.params.idProveedor = idProveedor;
    request.params.idRecambio = this.data.nuevoRecambioSel.idRecambio;
  }

  datasource_tblRecambioNProveedor = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambioNProveedor_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: [
      "idRecambio,referencia,referenciaInterna,denominacion,tblRecambioNAlmacenRecambios",
    ],
    sort: ["denominacion", "referenciaInterna"],

    postProcess: (data) => {
      return this.datasource_tblRecambioNProveedor_postProcess(data);
    },
  });

  datasource_tblRecambioNProveedor_onLoading(loadOptions) {
    //Expand
    let tblMovimientoRecambio = this.state.tblMovimientoRecambio;
    let idAlmacen = null;
    let idPais = tblMovimientoRecambio.almacenDestino
      ? tblMovimientoRecambio.almacenDestino.idPais
      : tblMovimientoRecambio.tblAlmacenRecambios_destino.tblPais.idPais;

    let filtroRecNAlm =
      "tblRecambioNAlmacenRecambios/any(r: r/cantidad gt 0 and r/idAlmacen eq " +
      idAlmacen +
      ")";

    let expandRecambio =
      "tblRecambioNProveedor($filter=idPais eq " +
      idPais +
      "; $expand=tblRecambio, tblProveedor($select= idProveedor, nombreComercial), \
        tblPais($select=idPais, denominacion, codigo; $expand=tblMoneda($select=codigo)), \
        ),  tblRecambioNAlmacenRecambios($filter=idAlmacen eq " +
      idAlmacen +
      ")";
    if (
      tblMovimientoRecambio.idTipoMovimientoRecambio === 2 ||
      tblMovimientoRecambio.idTipoMovimientoRecambio === 3
    ) {
      idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;
      if (loadOptions.filter) {
        loadOptions.filter.push("and");
        loadOptions.filter.push([filtroRecNAlm]);
      } else loadOptions.filter += "and " + filtroRecNAlm;
    }

    if (tblMovimientoRecambio.idTipoMovimientoRecambio !== 4) {
      idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;
      loadOptions.expand = expandRecambio;
    }

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4)
      // Para regularizaciones coge los recambios de almacen destino
      idAlmacen =
        tblMovimientoRecambio.almacenDestino != null
          ? tblMovimientoRecambio.almacenDestino.idAlmacen
          : tblMovimientoRecambio.idAlmacenDestino;
    else idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;

    loadOptions.expand = expandRecambio;
  }

  datasource_tblRecambioNProveedor_postProcess(data) {
    let idsRecambios_insertados = $.map(
      [...this.state.tblMovimientoRecambio.tblRecambioNMovimientoRecambio],
      function (item) {
        if (item) return item.idRecambio;
      },
    );

    let idsRecambiosNProv_insertados = $.map(
      [...this.state.tblMovimientoRecambio.tblRecambioNMovimientoRecambio],
      function (item) {
        return item.idRecambioNProveedor;
      },
    );

    let formatedData = [];
    data.forEach((item) => {
      if (item.tblRecambioNProveedor.length > 0) {
        if (idsRecambios_insertados.includes(item.idRecambio)) {
          item.tblRecambioNProveedor.forEach((x) => {
            x.disabled = true;
          });
        }

        item.tblRecambioNProveedor.forEach((x) => {
          if (!idsRecambiosNProv_insertados.includes(x.idRecambioNProveedor)) {
            formatedData.push(x);
          }
        });
      } else {
        if (!idsRecambios_insertados.includes(item.idRecambio)) {
          formatedData.push({
            codigoBarras: null,
            fabricante: null,
            refFabricante: null,
            referencia: null,
            idPais: item.idPais,
            idProveedor: null,
            idRecambio: item.idRecambio,
            idRecambioNProveedor: null,
            tblPais: null,
            tblRecambio: item,
            tblProveedor: { nombreComercial: null },
          });
        }
      }
    });
    return formatedData;
  }

  datasource_tblRecambio = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambio_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: [
      "idRecambio,referencia,referenciaInterna,denominacion,tblRecambioNAlmacenRecambios",
    ],
  });

  datasource_tblRecambio_beforeSend(request) {
    let tblMovimientoRecambio = this.state.tblMovimientoRecambio;

    let idAlmacen = null;

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4)
      // Para regularizaciones salen recambios de cualquier almacén
      idAlmacen = null;
    else idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;

    request.headers = { ...authHeader() };
    request.params.idAlmacen = idAlmacen;
  }

  datasource_tblRecambio_onLoading(loadOptions) {
    let idsRecambios_insertados = $.map(
      this.state.tblMovimientoRecambio.tblRecambioNMovimientoRecambio,
      function (item) {
        if (item) return item.idRecambio;
      },
    );

    //Expand
    let tblMovimientoRecambio = this.state.tblMovimientoRecambio;

    let idAlmacen = null;

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        [
          "not(idRecambio in (" +
            $.map(idsRecambios_insertados, function (item) {
              return item;
            }) +
            "))",
        ],
      ];
    else
      loadOptions.filter =
        "not (idRecambio in (" +
        $.map(idsRecambios_insertados, function (item) {
          return item;
        }) +
        "))";

    if (
      tblMovimientoRecambio.idTipoMovimientoRecambio === 2 ||
      tblMovimientoRecambio.idTipoMovimientoRecambio === 3
    ) {
      idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;
      if (loadOptions.filter) {
        loadOptions.filter.push("and");
        loadOptions.filter.push([
          "tblRecambioNAlmacenRecambios/any(r: r/cantidad gt 0 and r/idAlmacen eq " +
            idAlmacen +
            ")",
        ]);
      } else
        loadOptions.filter +=
          "and tblRecambioNAlmacenRecambios/any(r: r/cantidad gt 0 and r/idAlmacen eq " +
          idAlmacen +
          ")";
    }

    if (tblMovimientoRecambio.idTipoMovimientoRecambio !== 4) {
      idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;
      loadOptions.expand =
        "tblRecambioNAlmacenRecambios($filter=idAlmacen eq " + idAlmacen + ")";
    }

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4)
      // Para regularizaciones coge los recambios de almacen destino
      idAlmacen =
        tblMovimientoRecambio.almacenDestino != null
          ? tblMovimientoRecambio.almacenDestino.idAlmacen
          : tblMovimientoRecambio.idAlmacenDestino;
    else idAlmacen = tblMovimientoRecambio.idAlmacenOrigen;

    loadOptions.expand =
      "tblRecambioNAlmacenRecambios($filter=idAlmacen eq " + idAlmacen + ")";
  }

  datasource_tblProveedor = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblProveedor",
      key: "idProveedor",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    filter: ["activo eq true and eliminado eq false"],
  });

  datasource_tblAlmacenRecambiosNPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAlmacenRecambiosNPersona",
      key: ["idAlmacen", "idPersona"],
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblAlmacenRecambiosNPersona_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblAlmacenRecambiosNPersona_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { user } = this.props;
    request.params.idPersona = user.idPersona !== null ? user.idPersona : -1;
  }

  datasource_tblTipoMovimientoRecambio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoMovimientoRecambio",
      key: "idTipoMovimiento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  enum_tipoAlmacen = [
    {
      idTipoAlmacen: 1,
      denominacion: getTrad("almacenes").toUpperCase(),
    },
    {
      idTipoAlmacen: 2,
      denominacion: getTrad("subAlmacenes").toUpperCase(),
    },
  ];

  datasource_tblRecambioNAlmacenRecambios_regularizacion = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambioNAlmacenRecambios",
      key: ["idAlmacen", "idRecambio"],
      keyType: {
        idAlmacen: "Int32",
        idRecambio: "Int32",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambioNAlmacenRecambios_regularizacion_beforeSend(
          request,
        );
      },

      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
      onLoaded: (data) => {
        return this.datasource_tblRecambioNAlmacenRecambios_regularizacion_onLoaded(
          data,
        );
      },
    }),
    select: ["idAlmacen,cantidad,idRecambio,precioMedioPonderado,ubicacion"],
    expand: [
      "tblRecambio($select=referencia,referenciaInterna,denominacion,idProveedor)",
    ],
  });

  datasource_tblRecambioNAlmacenRecambios_regularizacion_onLoaded(data) {
    $.each(data, function (index, item) {
      item.isEdited = false;
      item.cantidadTeorico = item.cantidad;
    });
    return data;
  }

  datasource_tblRecambioNAlmacenRecambios_regularizacion_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { tblMovimientoRecambio } = this.state;
    if (request.method === "get") {
      request.params.idAlmacen =
        tblMovimientoRecambio.almacenDestino != null
          ? tblMovimientoRecambio.almacenDestino.idAlmacen
          : -1;

      if (!tblMovimientoRecambio.isInventario) {
        request.params.idRecambio = this.data.nuevoRecambioSel
          ? this.data.nuevoRecambioSel.idRecambio
          : -1;
      }
    }
  }

  datasource_tblRecambioNAlmacenRecambios_regularizacion_onInserted(
    values,
    key,
  ) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { almacenSel } = this.state;
    almacenSel.idAlmacen = key;

    $.when(
      this.datasource_tblRecambioNAlmacenRecambios_regularizacion.reload(),
    ).then(function () {
      _this.setState({ almacenSel: almacenSel }, async () => {
        _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.reload();
      });
    });
  }

  datasource_tblRecambioNAlmacenRecambios_regularizacion_onUpdated(
    values,
    key,
  ) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
    this.datasource_tblRecambioNAlmacenRecambios_regularizacion.reload();
  }

  datasource_tblRecambioNAlmacenRecambios_gridPrincipal = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambioNAlmacenRecambios",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambioNAlmacenRecambios_gridPrincipal_beforeSend(
          request,
        );
      },
      version: 4,
    }),
  });

  datasource_tblRecambioNAlmacenRecambios_gridPrincipal_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "tblRecambioNAlmacenRecambios",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_beforeSend(
          request,
        );
      },
      onLoaded: (data) => {
        this.datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_onLoaded(
          data,
        );
      },
      version: 4,
    }),
    filter: ["cantidad gt 0"],
    expand: [
      "idAlmacenNavigation($expand=idAlmacenPadreNavigation($select=denominacion))",
    ],
  });

  datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_beforeSend(request) {
    let { recambioSel_busquedaAvanzada } = this.state;

    request.headers = { ...authHeader() };
    request.params.idRecambio = recambioSel_busquedaAvanzada
      ? recambioSel_busquedaAvanzada.idRecambio
      : -1;
    request.params.isBusquedaRecambio = true;
  }

  datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios_onLoaded(data) {
    data.forEach((x) => {
      let almacen = x.idAlmacenNavigation;
      almacen.idAlmacenPadre =
        almacen.idAlmacenPadre == null
          ? almacen.idAlmacen
          : almacen.idAlmacenPadre;
      almacen.idAlmacenPadreNavigation =
        almacen.idAlmacenPadreNavigation == null
          ? {
              denominacion: almacen.denominacion,
            }
          : almacen.idAlmacenPadreNavigation;
    });

    data.sort((a, b) =>
      a.idAlmacenNavigation.idAlmacenPadreNavigation.denominacion.localeCompare(
        b.idAlmacenNavigation.idAlmacenPadreNavigation.denominacion,
      ),
    );
    this.setState({ data_busquedaAvanzada_tblRecambioNAlmacenRecambios: data });
    return data;
  }

  datasource_tblRecambio_busquedaAvanzada = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambio_busquedaAvanzada_onLoading(loadOptions);
      },
      version: 4,
    }),
    select: ["denominacion", "referencia", "referenciaInterna", "idRecambio"],
    expand: ["tblRecambioNAlmacenRecambios($select=cantidad)"],
  });

  datasource_tblRecambio_busquedaAvanzada_onLoading(loadOptions, x, y) {
    if (loadOptions.filter) {
      let filterValue = loadOptions.filter[0][2];
      loadOptions.filter = [
        [
          loadOptions.filter,
          "or",
          [
            " \
                tblRecambioNProveedor/any(x:(contains(tolower(x/referencia),'" +
              filterValue +
              "')) or \
                (contains(tolower(x/codigoBarras),'" +
              filterValue +
              "')) or \
                (contains(tolower(x/fabricante),'" +
              filterValue +
              "')) or \
                (contains(tolower(x/refFabricante),'" +
              filterValue +
              "'))) \
            ",
          ],
          "or",
          ["tblProveedor/nombreComercial", "contains", filterValue],
          "or",
          ["referenciaInterna", "contains", filterValue],
        ],
        "and",
        ["tblRecambioNAlmacenRecambios/any(x:(x/cantidad gt 0))"],
      ];
    }
  }

  datasource_tblRecambio_regularizacion = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambio_onLoading(loadOptions);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.datasource_tblRecambios_regularizacion_postProcess(data);
    },
    select: [
      "idRecambio,referencia,referenciaInterna,denominacion,tblRecambioNAlmacenRecambios",
    ],
  });

  datasource_tblRecambios_regularizacion_postProcess(data) {
    let dataSource =
      this.state
        .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario;
    let datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario =
      dataSource.store == null
        ? [
            ...this.state
              .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
          ]
        : dataSource.store.data != null
          ? [...dataSource.store.data]
          : [
              ...this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.items(),
            ];

    let filteredData = data.filter(
      (elem) =>
        !datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.find(
          ({ idRecambio }) => elem.idRecambio === idRecambio,
        ),
    );

    return filteredData;
  }

  datasource_tblRecambio_byReferencia = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblRecambio",
      key: "idRecambio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblRecambio_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblRecambio_byReferencia_onLoading(loadOptions);
      },
      version: 4,
    }),
  });

  datasource_tblRecambio_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblRecambio_byReferencia_onLoading(loadOptions) {
    let { recambioPistolaSel } = this.state;
    let referencia = (
      recambioPistolaSel.referencia ? recambioPistolaSel.referencia : -1
    ).toString();
    loadOptions.filter = "referencia eq '" + referencia + "'";
  }

  context_movimiento = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimientoRecambio",
    entities: {
      fn_isNumPedidoAsociadoExists: {},
      fn_isNumRegistroExists: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
    },
  });

  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;
    _this.loadPanel_show();
    this.datasource_tblCierreRecambioNAlmacen.reload();
    _this.datasource_tblAlmacenRecambiosNPersona.load().done(function () {
      $.when(
        _this.datasource_tblRecambio.load(),
        _this.datasource_tblAlmacenRecambios.load(),
        _this.datasource_tblProveedor.load(),
        _this.datasource_tblTipoMovimientoRecambio.load(),
      ).then(function () {
        _this.setState({ loaded: true });
        _this.loadPanel_hide();
      });
    });
  }

  render() {
    const { user } = this.props;

    if (this.state.loaded) {
      let {
        almacenSel,
        data_busquedaAvanzada_tblRecambioNAlmacenRecambios,
        subAlmacenSel,
        showPopup_MovimientoRecambios,
        tblMovimientoRecambio,
        showPopup_list_proveedores,
        param_paginaSecundaria,
        isVisible_busquedaAvanzada,
        isVisible_cierrePrecios,
        is_movimientoBloqueado,
        isVisible_dxPopup_errorIncoherencia,
        data_dxPopup_errorIncoherencia,
        isVisible_dxPopup_historialRecambio,
        data_dxPopup_dxPopup_historialRecambio,
        recambioNAlmacenSel,
        isLoading_popupGrid,
      } = this.state;
      let is_nuevoMovimiento =
        this.state.tblMovimientoRecambio.idMovimientoRecambio === undefined;
      let idAlmacenDestino =
        tblMovimientoRecambio.almacenDestino != null
          ? tblMovimientoRecambio.almacenDestino.idAlmacen
          : tblMovimientoRecambio.idAlmacenDestino;

      //#region Config moneda
      let formatoMoneda_almacenSel = {
        style: "currency",
        maximumFractionDigits: 2,
        currency:
          almacenSel != null && almacenSel.tblMoneda != null
            ? almacenSel.tblMoneda.codigo
            : subAlmacenSel != null && subAlmacenSel.tblMoneda != null
              ? subAlmacenSel.tblMoneda.codigo
              : "EUR",
      };

      let almacenDestino =
        tblMovimientoRecambio.almacenDestino != null
          ? tblMovimientoRecambio.almacenDestino
          : tblMovimientoRecambio.tblAlmacenRecambios_destino;

      let formatoMoneda_movimientoSel = {
        style: "currency",
        maximumFractionDigits: 2,
        currency:
          almacenDestino != null && almacenDestino.tblMoneda != null
            ? almacenDestino.tblMoneda.codigo
            : tblMovimientoRecambio.tblAlmacenRecambios_origen
              ? tblMovimientoRecambio.tblAlmacenRecambios_origen.tblMoneda
                  .codigo
              : "EUR",
      };

      let currency_editorOptions = {
        format: formatoMoneda_movimientoSel,
        step: 0,
        min: 0,
        max: 999999.99,
      };
      //#endregion

      let is_subAlmacen_almDestino =
        tblMovimientoRecambio.almacenDestino != null &&
        tblMovimientoRecambio.almacenDestino !== undefined
          ? tblMovimientoRecambio.almacenDestino.is_subAlmacen
          : false;
      let is_subAlmacen_almOrigen =
        tblMovimientoRecambio.tblAlmacenRecambios_origen != null
          ? tblMovimientoRecambio.tblAlmacenRecambios_origen.is_subAlmacen
          : false;

      let is_almacenesVisibles =
        $.grep(this.state.items_almacen_general, function (item) {
          return item.visible;
        }).length > 0;
      if (this.state.redirect) return <Redirect to="/" />;

      if (is_almacenesVisibles === false) {
        let _this = this;
        return (
          <Popup
            visible={true}
            height="auto"
            width="auto"
            maxWidth={500}
            minWidth={300}
            showTitle={false}
            deferRendering={false}
            toolbarItems={[
              {
                widget: "dxButton",
                location: "center",
                toolbar: "bottom",
                options: {
                  text: getTrad("aceptar"),
                  type: "danger",
                  onClick: function (e) {
                    _this.setState({
                      redirect: true,
                    });
                  },
                },
              },
            ]}
            contentRender={function (e) {
              return (
                <div style={{ fontSize: "14px" }}>
                  <p>{getTrad("alert_noAlmVisibles_persona")}</p>
                </div>
              );
            }}
            onContentReady={function (args) {
              var html = args.component.content();
              $(html).css("padding-top", "20px");
              $(html).css("padding-bottom", "10px");
              $(html).css("text-align", "center");
            }}
          ></Popup>
        );
      }

      let idTipoMovimientoRecambio =
        this.state.tblMovimientoRecambio.idTipoMovimientoRecambio;

      //#region Título popup movimientos recambios
      let textoTitle = getTrad("movimientRecambios");
      if (idTipoMovimientoRecambio === 1)
        textoTitle = " - " + getTrad("entrada").toUpperCase();
      else if (idTipoMovimientoRecambio === 2)
        textoTitle = " - " + getTrad("traspasoAlmacenes").toUpperCase();
      else if (idTipoMovimientoRecambio === 3)
        textoTitle = " - " + getTrad("salida").toUpperCase();
      else if (idTipoMovimientoRecambio === 4)
        textoTitle = " - " + getTrad("regularizacion").toUpperCase();
      //#endregion

      let popup_title = getTrad("movimientosRecambios") + textoTitle;

      const visible_isValidadoColumn =
        tblMovimientoRecambio.idTipoMovimientoRecambio === 2 &&
        this.visibleToUser_isValidado;

      return (
        <MultiView
          height="100%"
          width="100%"
          selectedIndex={param_paginaSecundaria != null ? 1 : 0}
          focusStateEnabled={false}
          loop={false}
          swipeEnabled={false}
          deferRendering={false}
          animationEnabled={true}
          className="MainTitle"
        >
          <MultiViewItem>
            <PageTitle heading={getNombreFormulario(this)} />
            <div className={"media-body"}>
              <div
                id="GestionAlmacenes"
                className="formContainer scrollbar-container"
              >
                <Row className="pt-4 he-100">
                  <Col xs="12" md="3" className="he-100">
                    <Toolbar elementAttr={{ class: "mb-2" }}>
                      <ToolbarItem
                        location="before"
                        render={this.tituloAlmacenes_render}
                      />
                      <ToolbarItem
                        location="after"
                        widget="dxButton"
                        locateInMenu="auto"
                        options={this.añadirMovimiento_buttonOption}
                      />
                    </Toolbar>
                    <TreeView
                      ref={this.dxTreeView_AlmacenRecambios_REF}
                      dataSource={this.datasource_tblAlmacenRecambios}
                      keyExpr="idAlmacen"
                      itemsExpr="tblAlmacenHijo"
                      displayExpr="denominacion"
                      height="74vh"
                      selectionMode="single"
                      activeStateEnabled={false}
                      focusStateEnabled={false}
                      selectByClick={true}
                      onItemClick={this.dxTreeView_almacenes_onItemClick}
                      itemRender={this.dxTreeView_almacenes_render}
                      onContentReady={(e) => {
                        if (almacenSel !== null)
                          e.component.selectItem(almacenSel.idAlmacen);
                        else if (subAlmacenSel !== null) {
                          e.component.expandItem(subAlmacenSel.idAlmacenPadre);
                          e.component.selectItem(subAlmacenSel.idAlmacen);
                        }
                      }}
                    />
                  </Col>
                  <Col xs="12" md="9" className="he-100">
                    {/* RECAMBIOS N ALMACEN GRID PRINCIPAL */}
                    <DataGrid
                      //Datos
                      ref={this.dxDataGrid_tblRecambioNAlmacenRecambios_REF}
                      elementAttr={{
                        id: "dxDataGrid_tblRecambioNAlmacenRecambios_gestionAlmacenes",
                      }} //para css
                      dataSource={this.get_recambios()}
                      //Propiedades
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onRowUpdating={
                        this.onRowUpdating_tblRecambioNAlmacenRecambios
                      }
                      onToolbarPreparing={
                        this.onToolbarPreparing_tblRecambioNAlmacenRecambios
                      }
                    >
                      <SearchPanel visible={true} width={220} />
                      <HeaderFilter visible={true} />
                      <FilterRow visible={true} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={
                          this.tblRecambioNAlmacenRecambios_allowedPageSizes
                        }
                        showInfo={true}
                      />
                      <Paging defaultPageSize={50} />
                      <Export fileName={"Recambios"} />
                      <Editing
                        mode="cell"
                        allowUpdating={true}
                        selectTextOnEditStart={true}
                      />
                      <KeyboardNavigation
                        enterKeyAction="moveFocus"
                        enterKeyDirection="row"
                        editOnKeyPress={true}
                      />
                      <Column
                        caption=" "
                        width={40}
                        alignment="center"
                        cssClass="p-0"
                        fixed={true}
                        allowFixing={false}
                        allowHiding={false}
                        allowReordering={false}
                        cellRender={this.cellRender_tblRecambioNAlmacen_edit}
                      />
                      <Column
                        dataField="tblRecambio.referenciaInterna"
                        caption={getTrad("refInterna")}
                        // visible={false}
                        alignment="center"
                        width={85}
                        allowHeaderFiltering={false}
                        allowEditing={false}
                      />
                      <Column
                        dataField="tblRecambio.referencia"
                        caption={getTrad("refFabricante")}
                        alignment="center"
                        minWidth={100}
                        width="11%"
                        allowHeaderFiltering={false}
                        allowEditing={false}
                      />
                      <Column
                        dataField="tblRecambio.denominacion"
                        caption={getTrad("denominacion")}
                        alignment="left"
                        allowHeaderFiltering={false}
                        allowEditing={false}
                      />
                      <Column
                        dataField="cantidad"
                        caption={
                          subAlmacenSel === null
                            ? getTrad("almPrinc_abr")
                            : getTrad("cantidad")
                        }
                        width="9.5%"
                        alignment="center"
                        allowFiltering={subAlmacenSel !== null}
                        dataType="number"
                        allowHeaderFiltering={false}
                        selectedFilterOperation={">"}
                        filterValue={0}
                        allowEditing={false}
                      />
                      <Column
                        dataField="ubicacion"
                        caption={getTrad("ubicacion")}
                        width="9.5%"
                        alignment="left"
                        cssClass={"dx-Cell_Editable"}
                        allowHeaderFiltering={true}
                        allowEditing={true}
                      />
                      <Column
                        dataField="cantidadSecundarios"
                        caption={getTrad("almSec_abr")}
                        width="12.5%"
                        alignment="center"
                        allowFiltering={false}
                        visible={subAlmacenSel === null}
                        allowEditing={false}
                      />
                      <Column
                        dataField="cantidadTotal"
                        caption={getTrad("total")}
                        width="6.5%"
                        alignment="center"
                        dataType="number"
                        allowFiltering={true}
                        selectedFilterOperation={">"}
                        filterValue={0}
                        allowHeaderFiltering={false}
                        visible={subAlmacenSel === null}
                        allowEditing={false}
                      />
                      <Column
                        dataField="precioMedioPonderado"
                        caption={getTrad("precioCompra")}
                        width="12%"
                        alignment="center"
                        allowHeaderFiltering={false}
                        format={formatoMoneda_almacenSel}
                        allowEditing={false}
                      />
                      <Column
                        dataField="valor"
                        caption={getTrad("valor")}
                        alignment="center"
                        dataType="number"
                        width="12.5%"
                        calculateCellValue={function (e) {
                          return (
                            e.precioMedioPonderado *
                            (subAlmacenSel === null
                              ? e.cantidadTotal
                              : e.cantidad)
                          );
                        }}
                        format={formatoMoneda_almacenSel}
                        allowFiltering={false}
                        editorOptions={this.currency_editorOptions}
                        allowEditing={false}
                      ></Column>
                      <Summary
                        calculateCustomSummary={this.calculateSelectedRow}
                      >
                        <TotalItem
                          summaryType="sum"
                          valueFormat={this.currency_format}
                          displayFormat={"{0}"}
                          column="valor"
                        />
                      </Summary>
                    </DataGrid>
                    <PopupHistorico
                      title="Historial del recambio"
                      subTitle={
                        recambioNAlmacenSel
                          ? recambioNAlmacenSel.denominacion
                          : ""
                      }
                      isSubtitleOneLine={true}
                      isLoading={isLoading_popupGrid}
                      visible={isVisible_dxPopup_historialRecambio}
                      data={data_dxPopup_dxPopup_historialRecambio}
                      colExtraConfig={
                        this.columnConfig_PopupGrid_historicoRecambio
                      }
                      onClickAceptar={this.onHiding_dxPopup_historialRecambio}
                      onHiding={this.onHiding_dxPopup_historialRecambio}
                      onHidden={this.onHidden_dxPopup_historialRecambio}
                      onShown={this.onShown_dxPopup_historialRecambio}
                      onCellPrepared={this.onCellPrepared_PopupGrid_recambios}
                    />
                  </Col>
                </Row>
                <Popup
                  ref={this.popup_movimientoRecambios_REF}
                  showTitle={true}
                  width={"90%"}
                  height={850}
                  visible={showPopup_MovimientoRecambios}
                  closeOnOutsideClick={false}
                  onHiding={this.hiding_popup_MovimientoRecambios}
                >
                  {tblMovimientoRecambio.idTipoMovimientoRecambio !== null ? (
                    <ToolbarItemPopup
                      widget="dxButton"
                      visible={true}
                      location="before"
                      toolbar="top"
                      options={this.popup_MovimientoRecambios_backBtn_options}
                    />
                  ) : null}
                  <ToolbarItemPopup
                    visible={true}
                    text={popup_title}
                    location="before"
                    toolbar="top"
                  />
                  <form
                    id="form-movimiento"
                    className="he-100"
                    onSubmit={this.submit_formMovimiento}
                  >
                    <Form
                      height="100%"
                      width="100%"
                      validationGroup="validationGroup_form_movimiento"
                    >
                      <TabbedItem>
                        <TabPanelOptions
                          height="100%"
                          width="100%"
                          deferRendering={false}
                          swipeEnabled={false}
                          elementAttr={this.formMovimiento_tabPanel_elem}
                          selectedIndex={
                            tblMovimientoRecambio.idTipoMovimientoRecambio ===
                            null
                              ? 0
                              : 1
                          }
                        />
                        <Tab tabIndex={0}>
                          <List
                            dataSource={this.datasource_tblTipoMovimientoRecambio
                              .items()
                              .filter((mr) =>
                                [1, 2, 3, 4].includes(
                                  mr.idTipoMovimientoRecambio,
                                ),
                              )}
                            displayExpr="denominacion"
                            keyExpr="idTipoMovimientoRecambio"
                            selectionMode="single"
                            height="100%"
                            width="100%"
                            selectedItemKeys={[
                              tblMovimientoRecambio.idTipoMovimientoRecambio,
                            ]}
                            focusStateEnabled={false}
                            activeStateEnabled={false}
                            scrolling={this.tblTipoMovimientoRecambio_scrolling}
                            pageLoadMode="scrollBottom"
                            itemRender={this.list_tipoMovimientoSe_itemRender}
                            onSelectionChanged={
                              this.onSelectionChanged_list_tipoMovimientoSel
                            }
                          />
                        </Tab>
                        <Tab tabIndex={1}>
                          <Row className="he-100">
                            <Col xs="12" md="8" className="he-auto">
                              <Row className="he-100">
                                <Col
                                  xs="12"
                                  style={{
                                    height:
                                      tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                      4
                                        ? 140
                                        : !tblMovimientoRecambio.isInventario
                                          ? 190
                                          : 180,
                                  }}
                                >
                                  {this.toolbar_tblMovimientoRecambio_render()}
                                </Col>

                                {tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                4 ? ( // Regularización
                                  <Fragment>
                                    <Col
                                      xs="12"
                                      style={this.dxSelectboxRecambios_style}
                                    >
                                      <SelectBox
                                        width="100%"
                                        ref={this.dxSelectboxRecambios_REF}
                                        dataSource={
                                          tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                          1
                                            ? this.datasource_tblRecambio
                                            : this
                                                .datasource_tblRecambioNProveedor
                                        }
                                        searchEnabled={true}
                                        searchExpr={[
                                          "denominacion",
                                          "referencia",
                                          "referenciaInterna",
                                        ]}
                                        showDropDownButton={false}
                                        openOnFieldClick={false}
                                        minSearchLength={1}
                                        displayExpr={
                                          this
                                            .dxSelectboxRecambiosNProveedor_displayExpr
                                        }
                                        itemRender={
                                          this
                                            .dxSelectboxRecambiosNProveedor_itemRender
                                        }
                                        onValueChanged={
                                          this
                                            .dxSelectboxRecambios_filter_onValueChanged
                                        }
                                        showClearButton={true}
                                        disabled={
                                          (!is_nuevoMovimiento &&
                                            is_movimientoBloqueado) ||
                                          (tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                            4 &&
                                            tblMovimientoRecambio.idAlmacenOrigen ==
                                              null &&
                                            tblMovimientoRecambio.idProveedor ==
                                              null) || // tipoMov - 4 = regularización
                                          (tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                            3 &&
                                            idAlmacenDestino == null) || // tipoMov - 3 = salida
                                          this.state.tblMovimientoRecambio
                                            .fecha == null
                                        }
                                        placeholder={getTrad(
                                          "busquedaNuevoRecambio",
                                        )}
                                      />
                                    </Col>
                                    <Col
                                      xs="12"
                                      className="gridMovimiento_observaciones"
                                    >
                                      <Box
                                        direction="col"
                                        width="100%"
                                        height="100%"
                                      >
                                        <ItemBox ratio={1}>
                                          {/* RECAMBIOS N ALMACEN GRID POPUP (ENTRADAS, TRASPASOS Y SALIDAS) */}
                                          <DataGrid
                                            //Datos
                                            ref={
                                              this
                                                .dxDataGrid_tblRecambioNMovimientoRecambio_REF
                                            }
                                            elementAttr={{
                                              id: "dxDataGrid_tblRecambioNMovimientoRecambio_gestionAlmacenes",
                                            }} //para css
                                            dataSource={
                                              this.state
                                                .datasource_dxDataGrid_tblRecambioNMovimientoRecambio
                                            }
                                            //Propiedadaes
                                            columnsAutoWidth={true}
                                            height="100%"
                                            width="100%"
                                            //Estilos
                                            showColumnLines={false}
                                            showRowLines={true}
                                            rowAlternationEnabled={true}
                                            onEditorPreparing={
                                              this
                                                .onEditorPreparing_tblRecambioNMovimientoRecambio
                                            }
                                            onToolbarPreparing={
                                              this
                                                .onToolbarPreparing_tblRecambioNMovimientoRecambio
                                            }
                                          >
                                            <HeaderFilter visible={false} />
                                            <FilterRow visible={false} />
                                            <KeyboardNavigation
                                              enterKeyAction="moveFocus"
                                              enterKeyDirection="row"
                                              editOnKeyPress={true}
                                            />
                                            <Pager
                                              showPageSizeSelector={true}
                                              allowedPageSizes={
                                                this
                                                  .tblAlmacenRecambios_allowedPageSizes
                                              }
                                              showInfo={true}
                                            />
                                            <Paging defaultPageSize={50} />
                                            <Editing
                                              mode="batch"
                                              useIcons={true}
                                              allowUpdating={true}
                                              allowDeleting={this.allowDeleting}
                                              selectTextOnEditStart={true}
                                            />
                                            <Column
                                              dataField="idMovimientoRecambio"
                                              visible={false}
                                              allowEditing={false}
                                            />
                                            <Column
                                              dataField="idRecambio"
                                              visible={false}
                                              allowEditing={false}
                                            />
                                            <Column
                                              dataField="idRecambioNProveedor"
                                              visible={false}
                                              allowEditing={false}
                                            />
                                            <Column
                                              dataField={
                                                "tblRecambio.referenciaInterna"
                                              }
                                              caption={getTrad("refInterna")}
                                              alignment="center"
                                              width={100}
                                              allowHeaderFiltering={false}
                                              allowEditing={false}
                                            />
                                            <Column
                                              dataField={
                                                "tblRecambio.referencia"
                                              }
                                              caption={getTrad("refFabricante")}
                                              alignment="center"
                                              width={110}
                                              allowEditing={false}
                                              allowHeaderFiltering={false}
                                            />
                                            <Column
                                              dataField={
                                                "tblRecambio.denominacion"
                                              }
                                              caption={getTrad("denominacion")}
                                              alignment="left"
                                              allowEditing={false}
                                              allowHeaderFiltering={false}
                                            />
                                            <Column
                                              dataField="referenciaProveedor"
                                              caption={getTrad("refProveedor")}
                                              alignment="left"
                                              width={110}
                                              visible={
                                                tblMovimientoRecambio.idTipoMovimientoRecambio ===
                                                1
                                              }
                                              allowHeaderFiltering={false}
                                              cssClass={
                                                !is_movimientoBloqueado
                                                  ? "dx-Cell_Editable"
                                                  : ""
                                              }
                                              allowEditing={
                                                !is_movimientoBloqueado
                                              }
                                            ></Column>
                                            <Column
                                              dataField="cantidad"
                                              caption={getTrad("cantidad")}
                                              width={80}
                                              format={{
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                              }}
                                              dataType="number"
                                              editorOptions={{
                                                step: 0,
                                                format: {
                                                  style: "decimal",
                                                  maximumFractionDigits: 0,
                                                },
                                                min:
                                                  tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                                  4
                                                    ? 0
                                                    : null,
                                                max: 999999,
                                              }}
                                              alignment="center"
                                              allowFiltering={false}
                                              allowEditing={
                                                !is_movimientoBloqueado
                                              }
                                              cssClass={
                                                !is_movimientoBloqueado
                                                  ? "dx-Cell_Editable"
                                                  : ""
                                              }
                                            >
                                              <CustomRule
                                                reevaluate={true}
                                                validationCallback={
                                                  this
                                                    .validationCallback_CustomRule_MaxVal
                                                }
                                              />
                                              <CustomRule
                                                reevaluate={true}
                                                validationCallback={
                                                  this
                                                    .validationCallback_CustomRule_MinVal
                                                }
                                              />
                                            </Column>
                                            <Column
                                              dataField="maxCantidad"
                                              caption={getTrad("stock")}
                                              width={80}
                                              format={{
                                                style: "decimal",
                                                maximumFractionDigits: 0,
                                              }}
                                              dataType="number"
                                              alignment="center"
                                              allowFiltering={false}
                                              allowEditing={false}
                                              visible={
                                                tblMovimientoRecambio.idTipoMovimientoRecambio ===
                                                  3 &&
                                                tblMovimientoRecambio.idMovimientoRecambio ==
                                                  null
                                              }
                                            ></Column>
                                            <Column
                                              dataField="ubicacion"
                                              caption={getTrad("ubicacion")}
                                              alignment="left"
                                              width={110}
                                              visible={
                                                tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                                3
                                              }
                                              allowHeaderFiltering={false}
                                              cssClass={
                                                !is_movimientoBloqueado
                                                  ? "dx-Cell_Editable"
                                                  : ""
                                              }
                                              allowEditing={
                                                !is_movimientoBloqueado
                                              }
                                            />
                                            <Column
                                              dataField="precio"
                                              caption={getTrad("precio")}
                                              alignment="center"
                                              dataType="number"
                                              width={110}
                                              cssClass={
                                                (tblMovimientoRecambio.idTipoMovimientoRecambio ==
                                                  1 ||
                                                  (tblMovimientoRecambio.idTipoMovimientoRecambio ==
                                                    2 &&
                                                    !this.isSubAlmacenDestino(
                                                      tblMovimientoRecambio,
                                                    ))) &&
                                                (!is_movimientoBloqueado &&
                                                (is_subAlmacen_almDestino ===
                                                  false ||
                                                  is_subAlmacen_almDestino ===
                                                    undefined) &&
                                                (is_subAlmacen_almOrigen ===
                                                  false ||
                                                  is_subAlmacen_almOrigen ===
                                                    undefined)
                                                  ? "dx-Cell_Editable"
                                                  : "")
                                              }
                                              allowEditing={
                                                (tblMovimientoRecambio.idTipoMovimientoRecambio ==
                                                  1 ||
                                                  (tblMovimientoRecambio.idTipoMovimientoRecambio ==
                                                    2 &&
                                                    !this.isSubAlmacenDestino(
                                                      tblMovimientoRecambio,
                                                    ))) &&
                                                !is_movimientoBloqueado &&
                                                (is_subAlmacen_almDestino ===
                                                  false ||
                                                  is_subAlmacen_almDestino ===
                                                    undefined) &&
                                                (is_subAlmacen_almOrigen ===
                                                  false ||
                                                  is_subAlmacen_almOrigen ===
                                                    undefined)
                                              }
                                              format={
                                                formatoMoneda_movimientoSel
                                              }
                                              editorOptions={
                                                currency_editorOptions
                                              }
                                            ></Column>
                                            <Column
                                              caption={getTrad("total")}
                                              name="total"
                                              alignment="center"
                                              dataType="number"
                                              width={120}
                                              calculateCellValue={function (e) {
                                                return e.precio * e.cantidad;
                                              }}
                                              format={
                                                formatoMoneda_movimientoSel
                                              }
                                              allowEditing={false}
                                              editorOptions={
                                                currency_editorOptions
                                              }
                                            ></Column>
                                            <Column type="buttons" width={50}>
                                              <Button name="delete" />
                                            </Column>
                                            {tblMovimientoRecambio.idTipoMovimientoRecambio ==
                                              1 && (
                                              <Summary
                                                recalculateWhileEditing={true}
                                              >
                                                <TotalItem
                                                  summaryType="sum"
                                                  valueFormat={
                                                    this.currency_format
                                                  }
                                                  displayFormat={"{0}"}
                                                  column="total"
                                                  customizeText={
                                                    this
                                                      .customizeText_sum_totalEntrada
                                                  }
                                                />
                                              </Summary>
                                            )}
                                          </DataGrid>
                                        </ItemBox>
                                        <ItemBox baseSize={10} />
                                        <ItemBox baseSize={80}>
                                          <TextArea
                                            value={
                                              tblMovimientoRecambio.observaciones
                                            }
                                            placeholder={getTrad(
                                              "observaciones",
                                            )}
                                            onValueChanged={
                                              this
                                                .dxTextArea_comentarioMovimiento_onValueChanged
                                            }
                                            disabled={!is_nuevoMovimiento}
                                            height={"100%"}
                                            width="100%"
                                          />
                                        </ItemBox>
                                      </Box>
                                    </Col>
                                  </Fragment>
                                ) : (
                                  <Col
                                    xs="12"
                                    className="gridMovimiento_observaciones_reg"
                                  >
                                    <Box
                                      direction="col"
                                      width="100%"
                                      height="100%"
                                    >
                                      <ItemBox ratio={1}>
                                        {/* RECAMBIOS N ALMACEN GRID POPUP REGULARIZACIONES */}
                                        <DataGrid
                                          //Datos
                                          ref={
                                            this
                                              .dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
                                          }
                                          dataSource={
                                            !tblMovimientoRecambio.isInventario
                                              ? this.state
                                                  .datasource_dxDataGrid_tblRecambioNMovimientoRecambio
                                              : this.state
                                                  .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario
                                          }
                                          //Propiedades
                                          keyExpr="idRecambio"
                                          columnsAutoWidth={true}
                                          height="100%"
                                          width="100%"
                                          remoteOperations={
                                            this
                                              .dxDataGrid_tblRecambioNAlmacenRecambios_remoteOperations
                                          }
                                          repaintChangesOnly={true}
                                          //Estilos
                                          showColumnLines={false}
                                          showRowLines={true}
                                          rowAlternationEnabled={true}
                                          onEditorPreparing={
                                            this
                                              .onEditorPreparing_tblRecambioNAlmacenRecambios_regularizacion
                                          }
                                          onRowPrepared={
                                            this
                                              .onRowPrepared_tblRecambioNAlmacenRecambios_regularizacion
                                          }
                                          onCellPrepared={
                                            this
                                              .onCellPrepared_tblRecambioNAlmacenRecambios_regularizacion
                                          }
                                          onToolbarPreparing={
                                            this
                                              .onToolbarPreparing_tblRecambioNAlmacenRecambios_regularizacion
                                          }
                                        >
                                          <Paging enabled={false} />
                                          <Editing
                                            mode="cell"
                                            refreshMode="reshape"
                                            allowUpdating={true}
                                            selectTextOnEditStart={true}
                                          />
                                          <Export
                                            enabled={true}
                                            fileName={
                                              "Regularización de recambios"
                                            }
                                          />
                                          <KeyboardNavigation
                                            enterKeyAction="moveFocus"
                                            enterKeyDirection="row"
                                            editOnKeyPress={true}
                                          />
                                          <HeaderFilter visible={true} />
                                          <FilterRow visible={true} />
                                          <Scrolling mode="virtual" />
                                          <Column
                                            dataField="tblRecambio.referenciaInterna"
                                            caption={getTrad("refInterna")}
                                            alignment="center"
                                            minWidth={85}
                                            width="8%"
                                            allowHeaderFiltering={false}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="tblRecambio.referencia"
                                            caption={getTrad("refFabricante")}
                                            alignment="center"
                                            minWidth={100}
                                            width="11%"
                                            allowHeaderFiltering={false}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="tblRecambio.denominacion"
                                            caption={getTrad("denominacion")}
                                            alignment="left"
                                            sortIndex={3}
                                            sortOrder="asc"
                                            allowHeaderFiltering={false}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="ubicacion"
                                            caption={getTrad("ubicacion")}
                                            width="9%"
                                            alignment="left"
                                            allowHeaderFiltering={true}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="cantidadTeorico"
                                            caption={getTrad("teorico")}
                                            width="6%"
                                            alignment="center"
                                            dataType="number"
                                            allowHeaderFiltering={false}
                                            selectedFilterOperation={">"}
                                            calculateFilterExpression={
                                              this
                                                .cantidadTeorico_calculateFilterExpression
                                            }
                                            filterValue={
                                              tblMovimientoRecambio.isInventario
                                                ? 0
                                                : null
                                            }
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="cantidad"
                                            dataType="number"
                                            caption={
                                              this.state.tblMovimientoRecambio
                                                .idMovimientoRecambio == null
                                                ? getTrad("real")
                                                : getTrad("cantidad")
                                            }
                                            width="6.5%"
                                            alignment="center"
                                            allowFiltering={false}
                                            allowEditing={
                                              !is_movimientoBloqueado
                                            }
                                            cssClass={
                                              !is_movimientoBloqueado
                                                ? "dx-Cell_Editable"
                                                : ""
                                            }
                                          />

                                          <Column
                                            name="diferencia"
                                            caption={getTrad("diferencia")}
                                            width={125}
                                            alignment="center"
                                            dataType="number"
                                            sortIndex={2}
                                            sortOrder="desc"
                                            calculateCellValue={
                                              this.calculateCellValue_diferencia
                                            }
                                            calculateSortValue={
                                              this.calculateSortValue_diferencia
                                            }
                                            allowSorting={false}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="precioMedioPonderado"
                                            caption={getTrad("precioCompra")}
                                            width="11%"
                                            alignment="center"
                                            allowFiltering={false}
                                            editorOptions={
                                              currency_editorOptions
                                            }
                                            allowEditing={false}
                                            visible={
                                              this.state.tblMovimientoRecambio
                                                .idMovimientoRecambio == null
                                            }
                                          >
                                            <Format
                                              type="currency"
                                              maximumFractionDigits={2}
                                              currency={
                                                almacenDestino != null &&
                                                almacenDestino.tblMoneda != null
                                                  ? almacenDestino.tblMoneda
                                                      .codigo
                                                  : tblMovimientoRecambio.tblAlmacenRecambios_origen
                                                    ? tblMovimientoRecambio
                                                        .tblAlmacenRecambios_origen
                                                        .tblMoneda.codigo
                                                    : "EUR"
                                              }
                                            />
                                          </Column>

                                          <Column
                                            dataField="precio"
                                            caption={getTrad("precio")}
                                            width="11%"
                                            alignment="center"
                                            allowFiltering={false}
                                            allowEditing={false}
                                            editorOptions={
                                              currency_editorOptions
                                            }
                                            visible={
                                              this.state.tblMovimientoRecambio
                                                .idMovimientoRecambio != null
                                            }
                                          >
                                            <Format
                                              type="currency"
                                              maximumFractionDigits={2}
                                              currency={
                                                almacenDestino != null &&
                                                almacenDestino.tblMoneda != null
                                                  ? almacenDestino.tblMoneda
                                                      .codigo
                                                  : tblMovimientoRecambio.tblAlmacenRecambios_origen
                                                    ? tblMovimientoRecambio
                                                        .tblAlmacenRecambios_origen
                                                        .tblMoneda.codigo
                                                    : "EUR"
                                              }
                                            />
                                          </Column>

                                          <Column
                                            dataField="valor"
                                            caption={getTrad("valor")}
                                            alignment="center"
                                            dataType="number"
                                            width="11%"
                                            calculateCellValue={
                                              this
                                                .calculateCellValue_dxDataGrid_regularizacion
                                            }
                                            editorOptions={
                                              currency_editorOptions
                                            }
                                            allowFiltering={false}
                                            allowEditing={false}
                                          >
                                            <Format
                                              type="currency"
                                              maximumFractionDigits={2}
                                              currency={
                                                almacenDestino != null &&
                                                almacenDestino.tblMoneda != null
                                                  ? almacenDestino.tblMoneda
                                                      .codigo
                                                  : tblMovimientoRecambio.tblAlmacenRecambios_origen
                                                    ? tblMovimientoRecambio
                                                        .tblAlmacenRecambios_origen
                                                        .tblMoneda.codigo
                                                    : "EUR"
                                              }
                                            />
                                          </Column>
                                          <Column
                                            dataType="boolean"
                                            dataField="isEdited"
                                            visible={false}
                                            sortIndex={1}
                                            sortOrder="desc"
                                            calculateCellValue={
                                              this.calculateCellValue_isEdited
                                            }
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataField="tblRecambio.idProveedor"
                                            width={0}
                                            allowEditing={false}
                                          />
                                          <Column
                                            dataType="boolean"
                                            dataField="isNuevo"
                                            sortIndex={0}
                                            sortOrder="asc"
                                            visible={false}
                                            allowEditing={false}
                                            allowFiltering={false}
                                          />
                                          <Column
                                            caption=" "
                                            width={50}
                                            alignment="center"
                                            visible={true}
                                            allowEditing={false}
                                            allowFiltering={false}
                                            allowSorting={false}
                                            cssClass="p-0"
                                            cellRender={
                                              this
                                                .dxDatagrid_regularizacion_cancelSelectRow
                                            }
                                          />
                                        </DataGrid>
                                      </ItemBox>
                                      <ItemBox baseSize={10} />
                                      <ItemBox baseSize={80}>
                                        <TextArea
                                          value={
                                            tblMovimientoRecambio.observaciones
                                          }
                                          placeholder={getTrad("observaciones")}
                                          onValueChanged={
                                            this
                                              .dxTextArea_comentarioMovimiento_onValueChanged
                                          }
                                          disabled={!is_nuevoMovimiento}
                                          height={"100%"}
                                          width="100%"
                                        />
                                      </ItemBox>
                                    </Box>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                            <Col xs="12" md="4">
                              <Box direction="col" width="100%" height="100%">
                                <ItemBox baseSize={27} />
                                <ItemBox ratio={1}>
                                  {/* GRID SELECTOR MOVIMIENTOS POPUP */}
                                  <DataGrid
                                    //Datos
                                    ref={
                                      this.dxDataGrid_tblMovimientoRecambio_REF
                                    }
                                    elementAttr={{
                                      id: "dxDataGrid_tblMovimientoRecambio_gestionAlmacenes",
                                    }} //para css
                                    dataSource={
                                      this.datasource_tblMovimientoRecambio
                                    }
                                    //Propiedades
                                    selection={this.dxDataGrid_singleSelection}
                                    selectedRowKeys={
                                      tblMovimientoRecambio &&
                                      tblMovimientoRecambio.idMovimientoRecambio
                                        ? [
                                            tblMovimientoRecambio.idMovimientoRecambio,
                                          ]
                                        : []
                                    }
                                    columnsAutoWidth={true}
                                    height="100%"
                                    width="100%"
                                    hoverStateEnabled={true}
                                    //Estilos
                                    sorting={this.sorting_multiple}
                                    showColumnLines={false}
                                    showRowLines={true}
                                    rowAlternationEnabled={true}
                                    onEditorPreparing={
                                      this
                                        .onEditorPreparing_tblMovimientoRecambio_gestionAlmacenes
                                    }
                                    onToolbarPreparing={
                                      this
                                        .onToolbarPreparing_tblMovimientoRecambio
                                    }
                                    onSelectionChanged={
                                      this
                                        .onSelectionChanged_tblMovimientoRecambio
                                    }
                                    onRowPrepared={
                                      this.onRowPrepared_tblMovimientoRecambio
                                    }
                                  >
                                    <HeaderFilter visible={true} />
                                    <FilterRow visible={true} />
                                    <Pager
                                      showPageSizeSelector={true}
                                      allowedPageSizes={
                                        this
                                          .tblAlmacenRecambios_allowedPageSizes
                                      }
                                      showInfo={true}
                                    />
                                    <Paging defaultPageSize={50} />
                                    <LoadPanel enabled={true} />
                                    <Column
                                      dataField={"isValidado"}
                                      caption={getTrad("valido")}
                                      width={70}
                                      alignment={"left"}
                                      allowFiltering={false}
                                      allowHeaderFiltering={true}
                                      visible={visible_isValidadoColumn}
                                      cssClass={"p-0"}
                                      cellRender={
                                        this
                                          .tblMovimientos_isValidado_cellRender
                                      }
                                    >
                                      <LookupGrid
                                        dataSource={
                                          this.datasource_lookupGrid_isValidado
                                        }
                                        valueExpr={"value"}
                                        displayExpr={"denominacion"}
                                      />
                                    </Column>
                                    <Column
                                      dataField="idMovimientoRecambio"
                                      caption="ID"
                                      width={75}
                                      alignment={"center"}
                                      sortIndex={2}
                                      sortOrder="desc"
                                      allowHeaderFiltering={false}
                                      dataType="number"
                                    />
                                    <Column
                                      caption=" "
                                      dataField="isInventario"
                                      width={35}
                                      alignment="center"
                                      cssClass="p-0"
                                      allowEditing={false}
                                      allowFiltering={false}
                                      allowSorting={false}
                                      visible={
                                        tblMovimientoRecambio.idTipoMovimientoRecambio ===
                                        4
                                      }
                                      cellRender={this.render_isInventario}
                                    />
                                    <Column
                                      dataField="fecha"
                                      caption={getTrad("fecha")}
                                      width={125}
                                      dataType="date"
                                      format="dd/MM/yyyy HH:mm"
                                      alignment="center"
                                      sortIndex={1}
                                      sortOrder="desc"
                                      allowSearch={true}
                                      allowHeaderFiltering={false}
                                    />
                                    <Column
                                      dataField="tblAlmacenRecambios_destino.denominacion"
                                      caption={getTrad("almacen")}
                                      visible={
                                        tblMovimientoRecambio.idTipoMovimientoRecambio !==
                                        3
                                      }
                                      allowSearch={true}
                                      alignment="left"
                                      allowFiltering={false}
                                      allowHeaderFiltering={true}
                                    />
                                    <Column
                                      dataField="clienteDestino"
                                      caption={getTrad("clienteDestino")}
                                      visible={
                                        tblMovimientoRecambio.idTipoMovimientoRecambio ===
                                        3
                                      }
                                      allowSearch={true}
                                      alignment="left"
                                      allowFiltering={false}
                                      allowHeaderFiltering={true}
                                    />
                                    <Column
                                      dataField="isEditable"
                                      visible={false}
                                      allowEditing={false}
                                      allowSearch={false}
                                      allowFiltering={false}
                                    />
                                    <Column
                                      caption=" "
                                      width={50}
                                      alignment="center"
                                      visible={true}
                                      cssClass="p-0"
                                      cellRender={
                                        this.tblMovimientos_remove_cellRender
                                      }
                                    />
                                  </DataGrid>
                                </ItemBox>
                              </Box>
                            </Col>
                          </Row>
                        </Tab>
                      </TabbedItem>
                    </Form>
                  </form>
                </Popup>
                <Popup
                  showTitle={false}
                  width={110}
                  height={
                    this.data.referenciasProveedor_datasource !== null
                      ? this.data.referenciasProveedor_datasource.length * 40.5
                      : 120
                  } // 40.5 es la altura de 1 item por defecto
                  maxHeight={120}
                  animation={null}
                  shading={false}
                  visible={showPopup_list_proveedores}
                  closeOnOutsideClick={true}
                  onContentReady={(e) => {
                    var html = e.component.content();
                    $(html).css("padding", 0);
                  }}
                  onShowing={(e) => {
                    this.list_referenciasProveedor.unselectAll();
                  }}
                  onHiding={this.onHiding_showPopup_list_proveedores}
                >
                  <Position
                    my="left top"
                    at="left bottom"
                    of=".dx-Cell_Editable.dx-editor-cell"
                  />
                  <List
                    ref={this.list_referenciasProveedor_REF}
                    dataSource={this.data.referenciasProveedor_datasource}
                    selectionMode="single"
                    focusStateEnabled={false}
                    activeStateEnabled={false}
                    scrolling={{ mode: "infinite" }}
                    pageLoadMode="scrollBottom"
                    onSelectionChanged={
                      this.onSelectionChanged_listProveedorNRecambio
                    }
                  />
                </Popup>

                <PopupCierrePrecios
                  isVisible_cierrePrecios={isVisible_cierrePrecios}
                  idioma={this.props.idioma}
                  almacenSel={almacenSel}
                  onHiding={this.PopupCierrePrecios_onHiding}
                />

                <Popup
                  title={getTrad("busquedaAvanzada")}
                  width={"40%"}
                  height={"60%"}
                  visible={isVisible_busquedaAvanzada}
                  onContentReady={this.dxPopup_busquedaAvanzada_onContentReady}
                  onHiding={this.onHiding_dxPopup_busquedaAvanzada}
                >
                  <Box direction="col" width="100%" height="100%">
                    <ItemBox baseSize={40}>
                      <SelectBox
                        width="100%"
                        ref={this.dxSelectboxRecambios_busquedaAvanzada_REF}
                        dataSource={
                          this.datasource_tblRecambio_busquedaAvanzada
                        }
                        searchEnabled={true}
                        searchExpr={["denominacion", "referencia"]}
                        showDropDownButton={false}
                        openOnFieldClick={false}
                        minSearchLength={1}
                        displayExpr={this.dxSelectboxRecambios_displayExpr}
                        onValueChanged={
                          this
                            .dxSelectboxRecambios_busquedaAvanzada_onValueChanged
                        }
                        showClearButton={true}
                        placeholder={getTrad("introduzcaRecambioBuscar")}
                      />
                    </ItemBox>
                    <ItemBox ratio={1}>
                      {/* BÚSQUEDA AVANZADA RECAMBIOS */}
                      <DataGrid
                        //Datos
                        ref={this.dxDataGrid_busquedaAvanzada_REF}
                        dataSource={
                          data_busquedaAvanzada_tblRecambioNAlmacenRecambios
                        }
                        //Propiedades
                        columnsAutoWidth={true}
                        height={"100%"}
                        width={"100%"}
                        //Estilos
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        noDataText={getTrad("seleccioneRecambio")}
                        onRowPrepared={
                          this.dxDatagrid_busquedaAvanzada_onRowPrepared
                        }
                      >
                        <Grouping autoExpandAll={true} />
                        <HeaderFilter visible={false} />
                        <FilterRow visible={false} />
                        <Column
                          dataField="idAlmacenNavigation.idAlmacenPadreNavigation.denominacion"
                          groupIndex={0}
                          visible={false}
                          allowEditing={false}
                          calculateFilterExpression={
                            this.idAlmacenPadre_calculateFilterExpression
                          }
                          groupCellRender={this.idAlmacenPadre_groupCellRender}
                        />
                        <Column
                          dataField="idAlmacenNavigation.denominacion"
                          caption={getTrad("almacen")}
                          alignment="left"
                          minWidth={100}
                          cssClass="pl-4"
                          allowHeaderFiltering={false}
                        />
                        <Column
                          dataField="cantidad"
                          caption={getTrad("cantidad")}
                          alignment="center"
                          width={100}
                          allowHeaderFiltering={false}
                        />
                      </DataGrid>
                    </ItemBox>
                  </Box>
                  <ToolbarItemPopup
                    widget="dxButton"
                    visible={true}
                    render={this.render_toolbar_dxPopupBusquedadAvanzada}
                    location="after"
                    toolbar="bottom"
                  ></ToolbarItemPopup>
                </Popup>

                <PopupIncoherencia
                  title="Error de incoherencia"
                  visible={isVisible_dxPopup_errorIncoherencia}
                  data={data_dxPopup_errorIncoherencia}
                  onClickAceptar={this.onHiding_dxPopup_errorIncoherencia}
                  onHiding={this.onHiding_dxPopup_errorIncoherencia}
                  onCellPrepared={this.onCellPrepared_PopupGrid_recambios}
                />
              </div>
            </div>
          </MultiViewItem>

          <MultiViewItem>
            {param_paginaSecundaria ? (
              <InfRecambios
                getBackButton_paginaSecundaria={
                  this.getBackButton_paginaSecundaria
                }
              ></InfRecambios>
            ) : null}
          </MultiViewItem>
        </MultiView>
      );
    }
    return <div />;
  }

  sorting_multiple = { mode: "multiple" };

  onHiding_dxPopup_errorIncoherencia() {
    this.setState({ isVisible_dxPopup_errorIncoherencia: false });
  }

  onCellPrepared_PopupGrid_recambios(e) {
    var field = e.column.dataField;
    if (
      e.rowType == "data" &&
      e.data.stockFinalAlmOrigen < 0 &&
      field === "stockFinalAlmOrigen"
    ) {
      e.cellElement.css("background", "#f4d7d7");
      e.cellElement.css("color", "#a22a2a");
    }

    if (e.rowType == "data" && field === "codigo") {
      let rowData = e.data;
      let { denoLavanderia } = rowData;

      let icono = "icon_helpCircle";

      if (!denoLavanderia) return;
      e.cellElement.append(
        $("<div />")
          .addClass("d-inline-flex")
          .append(
            $("<div style='font-size:18px; flex:0 0 40px;' />").addClass(
              "container_spanCentrado center-svg iconos_svg " +
                icono +
                " tl_" +
                rowData.codigo +
                rowData.idMovimientoRecambio,
            ),
            $("<div />").dxTooltip({
              target: ".tl_" + rowData.codigo + rowData.idMovimientoRecambio,
              position: "bottom",
              showEvent: "dxhoverstart",
              hideEvent: "dxhoverend",
              contentTemplate: function (contentElement) {
                contentElement.html(rowData.denoLavanderia);
              },
            }),
          ),
      );
      return (
        <>
          <div
            className={
              "container_spanCentrado center-svg iconos_svg " +
              icono +
              " tl_" +
              rowData.codigo +
              rowData.idMovimientoRecambio
            }
            style={{ fontSize: "23px" }}
          />
          <Tooltip
            target={".tl_" + rowData.codigo + rowData.idMovimientoRecambio}
            position="bottom"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            <div>{denoLavanderia}</div>
          </Tooltip>
        </>
      );
    } else if (e.rowType == "data" && field === "precioMedio") {
      var cellElement = e.cellElement;
      let { almacenSel, subAlmacenSel } = this.state;
      let formatoMoneda_almacenSel = {
        style: "currency",
        maximumFractionDigits: 2,
        currency:
          almacenSel != null && almacenSel.tblMoneda != null
            ? almacenSel.tblMoneda.codigo
            : subAlmacenSel != null && subAlmacenSel.tblMoneda != null
              ? subAlmacenSel.tblMoneda.codigo
              : "EUR",
      };

      var cellValue = e.value;
      cellElement.text(
        formatNumber(
          cellValue,
          2,
          "currency",
          formatoMoneda_almacenSel.currency,
        ),
      );
    }
  }

  columnConfig_PopupGrid_historicoRecambio = [
    {
      dataField: "denoLavanderia",
      visible: false,
    },
    {
      dataField: "idMovimientoRecambio",
      visible: false,
    },
    {
      dataField: "fecha",
      visibleIndex: 0,
      width: 100,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "tipoMovimiento",
      visibleIndex: 1,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "codigo",
      visibleIndex: 2,
      width: 145,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "almacenOrigenAfectado",
      visibleIndex: 3,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "almacenDestino",
      visibleIndex: 4,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "stockInicialAlmOrigen",
      visibleIndex: 5,
      width: 170,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "cantidad",
      visibleIndex: 6,
      width: 120,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "stockFinalAlmOrigen",
      visibleIndex: 7,
      width: 160,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
    },
    {
      dataField: "precioMedio",
      caption: getTrad("precio"),
      visibleIndex: 8,
      width: 160,
      alignment: "center",
      allowFiltering: false,
      allowEditing: false,
      format: this.formatoMoneda_almacenSel,
    },
    {
      dataField: "refIntRec",
      visible: false,
    },
    {
      dataField: "refRec",
      visible: false,
    },
    {
      dataField: "denoRec",
      visible: false,
    },
  ];

  cantidadTeorico_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [
      ["cantidadTeorico", ">", filterValue],
      "or",
      ["isNuevo", "=", true],
    ];
  }

  idAlmacenPadre_calculateFilterExpression(
    filterValue,
    selectedFilterOperation,
  ) {
    return [filterValue != null];
  }

  idAlmacenPadre_groupCellRender(e) {
    let denominacion,
      cantidad = "";
    let { items_almacen_general } = this.state;
    let items = e.data.items ? e.data.items : e.data.collapsedItems;
    if (items && items.length > 0) {
      let almacenPadre = items.filter(
        (x) => x.idAlmacenNavigation.denominacion == e.key[0],
      ); // padre con datos
      let almacenPadre_hijos = items.filter(
        (x) => x.idAlmacenNavigation.denominacion != e.key[0],
      ); // hijos sin padre

      denominacion =
        almacenPadre.length > 0
          ? almacenPadre[0].idAlmacenNavigation.denominacion
          : almacenPadre_hijos[0].idAlmacenNavigation.idAlmacenPadreNavigation
              .denominacion;
      cantidad = almacenPadre.length > 0 ? almacenPadre[0].cantidad : 0;
    } else {
      denominacion = items_almacen_general.filter(
        (x) => x.idAlmacen == e.value,
      )[0].denominacion;
      cantidad = 0;
    }

    return (
      <div className="d-flex" style={{ marginRight: -7 }}>
        {" "}
        {/*Para contrarrestrar padding del padre que viene por defecto*/}
        <div className="d-flex justify-content-left flex-fill">
          {denominacion}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ flexBasis: 100 }}
        >
          {cantidad}
        </div>
      </div>
    );
  }

  dxDatagrid_busquedaAvanzada_onRowPrepared(e) {
    let almacen = e.data ? e.data.idAlmacenNavigation : null;
    if (
      e.rowType == "data" &&
      almacen &&
      almacen.idAlmacenPadre == almacen.idAlmacen
    ) {
      // Almacén padre
      $(e.rowElement).hide();
    } else if (e.rowType == "group") {
      let items = e.component
        .getDataSource()
        .items()
        .filter((x) => x.key == e.key[0])[0].items;
      if (
        items &&
        items.length == 1 &&
        e.key[0] == items[0].idAlmacenNavigation.denominacion
      ) {
        // Solo el padre
        let element = e.rowElement.find(".dx-command-expand")[0];
        element.classList.remove("dx-datagrid-expand");
        element.firstChild.classList.remove("dx-datagrid-group-closed");
        element.firstChild.classList.remove("dx-datagrid-group-opened");
      }
    }
  }

  calculateSortValue_busquedaAvanzada(rowData) {
    let almacenPadre = rowData.idAlmacenNavigation.idAlmacenPadreNavigation;
    return almacenPadre
      ? almacenPadre.denominacion
      : rowData.idAlmacenNavigation.denominacion;
  }

  //FORMATS
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: "EUR",
  };

  //EDITORES
  currency_editorOptions = {
    format: this.currency_format,
    step: 0,
    min: 0,
    max: 9999.99,
  };

  //CONFIG GRID
  dxDataGrid_singleSelection = { mode: "single" };
  tblAlmacenRecambios_allowedPageSizes = [20, 50, 100];

  toolbarAttributes = {
    id: "movimientoRecambiosToolbar",
  };

  toolbarSecundario_Attributes = {
    id: "toolbarSecundario",
  };

  allowDeleting(e) {
    let { is_nuevoMovimiento, is_movimientoBloqueado } = this.state;
    return is_nuevoMovimiento || !is_movimientoBloqueado;
  }

  onClick_tblAlmacenRecambios_remove(arg, e, _this) {
    dxMensajePregunta(getTrad("alert_eliminarRegistro"), [
      [
        "Sí",
        function () {
          _this.datasource_tblMovimientoRecambio
            .store()
            .remove(e.data.idMovimientoRecambio)
            .then(
              () => {},
              (error) => {
                let message = JSON.parse(error.message);
                if (typeof message !== "number") {
                  if (
                    _this.dxDataGrid_tblMovimientoRecambio_REF.current != null
                  )
                    _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

                  if (
                    _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF
                      .current != null
                  )
                    _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();

                  _this.setState({
                    isVisible_dxPopup_errorIncoherencia: true,
                    data_dxPopup_errorIncoherencia: message,
                  });
                }
                _this.setState({ isGuardarClick: false });
              },
            );
        },
        "normal",
      ],
      ["No", function () {}, "normal"],
    ]);
  }

  tblMovimientos_isValidado_cellRender = (e) => {
    if (e.data.idTipoMovimientoRecambio !== 2) return null;

    const icons = {
      true: "icon_Circle_check",
      false: "icon_Circle_uncheck",
    };

    const title = {
      true: getTrad("validado"),
      false: getTrad("noValidado"),
    };

    return (
      <div className={"d-flex align-items-center justify-content-center"}>
        {e.data.isValidado === null ? (
          <div />
        ) : (
          <div
            title={title[e.data.isValidado]}
            className={`${icons[e.data.isValidado] || ""} font-size-lg`}
            onMouseDown={() => this.onClick_Validar(e)}
          />
        )}
      </div>
    );
  };

  onClick_Validar = ({ data }) => {
    const idMovimientoRecambio = data.idMovimientoRecambio;

    this.context_GestionAlmacenes
      .invoke("setIsValidado", { idMovimientoRecambio }, "POST")
      .then((response) => {
        if (response) {
          notify({
            message: getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "250",
            closeOnClick: true,
          });

          this.dxDataGrid_tblMovimientoRecambio.refresh(true);
        }
      });
  };

  datasource_lookupGrid_isValidado = [
    { value: true, denominacion: getTrad("validado") },
    { value: false, denominacion: getTrad("noValidado") },
  ];

  tblMovimientos_remove_cellRender(e) {
    if (!e.data.isEditable || e.data.idTipoMovimientoRecambio == 4) return null;
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={(arg) => {
          this.onClick_tblAlmacenRecambios_remove(arg, e, this);
        }}
      >
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  }

  dxButton_regularizacion_cancelSelectRow_onClick(e, itemData) {
    let { is_movimientoBloqueado } = this.state;
    if (
      itemData.component.cellValue(itemData.row.rowIndex, "isEdited") ||
      !is_movimientoBloqueado
    ) {
      let cantidadTeorico = itemData.component.cellValue(
        itemData.row.rowIndex,
        "cantidadTeorico",
      );
      itemData.component.beginUpdate();
      itemData.component.cellValue(itemData.row.rowIndex, "isEdited", false);
      itemData.component.cellValue(itemData.row.rowIndex, "isNuevo", null);
      itemData.component.cellValue(
        itemData.row.rowIndex,
        "cantidad",
        cantidadTeorico,
      );
      itemData.component.saveEditData();
      itemData.component.endUpdate();
    }
  }

  dxDatagrid_regularizacion_cancelSelectRow(itemData) {
    let { recambioPistolaSel, is_movimientoBloqueado } = this.state;
    if (
      is_movimientoBloqueado &&
      (!itemData.component.cellValue(itemData.row.rowIndex, "isEdited") ||
        (recambioPistolaSel.tblRecambio &&
          recambioPistolaSel.tblRecambio.referencia ===
            itemData.row.data.tblRecambio.referencia))
    )
      return <div></div>;
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={(e) => {
          this.dxButton_regularizacion_cancelSelectRow_onClick(e, itemData);
        }}
      >
        <div className="icon_Cerrar font-size-lg" />
      </div>
    );
  }

  // MÉTODOS
  //MULTIVIEW HIPERVINCULO
  getBackButton_paginaSecundaria() {
    return (
      <Fragment>
        <div
          id="backButton"
          className="backButton dx-icon-chevronleft container_spanCentrado"
          onClick={this.backButton_paginaSecundaria_onClick}
        />
        <Tooltip
          target="#backButton"
          position="bottom"
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
        >
          <div>{getTrad("volverDashboardEjecutivo")}</div>
        </Tooltip>
      </Fragment>
    );
  }

  backButton_paginaSecundaria_onClick() {
    this.setState({ param_paginaSecundaria: null });
  }

  get_idsAlmacenNPersona(items) {
    let ids_almacenesConsulta = $.grep(
      this.datasource_tblAlmacenRecambiosNPersona.items(),
      function (item) {
        return item.isConsulta;
      },
    );
    return $.map(ids_almacenesConsulta, function (item) {
      return item.idAlmacen;
    });
  }

  get_recambios() {
    let { almacenSel, subAlmacenSel, is_switchSubAlmacen } = this.state;

    if (almacenSel) {
      return $.grep(almacenSel.tblRecambioNAlmacenRecambios, function (item) {
        if (is_switchSubAlmacen) return item;
        else if (item.cantidad > 0) return item;
      });
    } else if (subAlmacenSel) return subAlmacenSel.tblRecambioNAlmacenRecambios;
  }

  //#region EVENTOS
  customizeText_sum_totalEntrada(rowData) {
    let { tblMovimientoRecambio } = this.state;
    let almacenDestino =
      tblMovimientoRecambio.almacenDestino != null
        ? tblMovimientoRecambio.almacenDestino
        : tblMovimientoRecambio.tblAlmacenRecambios_destino;

    let currency =
      almacenDestino != null && almacenDestino.tblMoneda != null
        ? almacenDestino.tblMoneda.codigo
        : tblMovimientoRecambio.tblAlmacenRecambios_origen
          ? tblMovimientoRecambio.tblAlmacenRecambios_origen.tblMoneda.codigo
          : "EUR";

    return formatNumber(rowData.value, 2, "currency", currency);
  }

  calculateSortValue_diferencia(rowData) {
    return rowData.cantidadTeorico
      ? rowData.cantidad - rowData.cantidadTeorico
      : 0;
  }

  calculateCellValue_diferencia(rowData) {
    let cantidad = rowData.cantidad ? rowData.cantidad : 0;
    let cantidadTeorico = rowData.cantidadTeorico ? rowData.cantidadTeorico : 0;

    return cantidad - cantidadTeorico;
  }

  calculateCellValue_isEdited(rowData) {
    return rowData.isEdited;
  }

  render_toolbar_dxPopupBusquedadAvanzada(e) {
    return (
      <Button
        text={getTrad("aceptar")}
        type="success"
        onClick={(e) => this.btnAceptar_busquedaAvanzada_onClick(this)}
      ></Button>
    );
  }

  btnAceptar_busquedaAvanzada_onClick(e) {
    this.setState({
      isVisible_busquedaAvanzada: false,
      recambioSel_busquedaAvanzada: null,
    });
    this.dxSelectboxRecambios_busquedaAvanzada.reset();
  }

  dxSelectboxRecambios_busquedaAvanzada_onValueChanged(e) {
    this.setState({ recambioSel_busquedaAvanzada: e.value }, () => {
      this.datasource_busquedaAvanzada_tblRecambioNAlmacenRecambios.reload();
    });
  }

  onHiding_dxPopup_busquedaAvanzada(e) {
    this.setState({
      isVisible_busquedaAvanzada: false,
      recambioSel_busquedaAvanzada: null,
    });
    this.dxSelectboxRecambios_busquedaAvanzada.reset();
    this.dxDataGrid_busquedaAvanzada.expandAll();
  }

  dxPopup_busquedaAvanzada_onContentReady(e) {
    e.component.content().attr("id", "dxPopup_busquedaAvanzada");
  }

  PopupCierrePrecios_onHiding(e) {
    let _this = this;
    this.datasource_tblCierreRecambioNAlmacen.reload().done(function () {
      _this.setState({
        isVisible_cierrePrecios: false,
      });
    });
  }

  onHiding_showPopup_list_proveedores(e) {
    this.setState({
      showPopup_list_proveedores: false,
    });
  }

  onSelectionChanged_listProveedorNRecambio(e) {
    if (e.addedItems.length > 0) {
      let rowIndex =
        this.dxDataGrid_tblRecambioNMovimientoRecambio.getRowIndexByKey(
          this.data.tblRecambioNEntrada_editingKey,
        );
      this.dxDataGrid_tblRecambioNMovimientoRecambio.cellValue(
        rowIndex,
        "referenciaProveedor",
        e.addedItems[0],
      );

      this.setState({
        showPopup_list_proveedores: false,
      });
    }
  }

  calculateCellValue_dxDataGrid_regularizacion(e) {
    let precio =
      this.state.tblMovimientoRecambio.idMovimientoRecambio == null
        ? e.precioMedioPonderado
        : e.precio;
    return precio * e.cantidad;
  }

  onSelectionChanged_list_tipoMovimientoSel(e) {
    let idTipoMovimientoRecambio = null;

    if (e.addedItems.length > 0)
      idTipoMovimientoRecambio = e.addedItems[0].idTipoMovimientoRecambio;

    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.idTipoMovimientoRecambio = idTipoMovimientoRecambio;
    tblMovimientoRecambio.fecha = null;
    this.setState({
      tblMovimientoRecambio: tblMovimientoRecambio,
      list_tipoMovimiento_selectedIndex: 1,
      is_inventarioPistolaLectora: false,
      recambioPistolaSel: {},
    });
  }

  list_tipoMovimientoSe_itemRender(itemData) {
    let popupHeight = this.popup_movimientoRecambios.option("height") - 91; // Popup height - 91 para sacar el espacio que ocupa la lista
    let tiposMovRecambio = this.datasource_tblTipoMovimientoRecambio
      .items()
      .filter((mr) => [1, 2, 3, 4].includes(mr.idTipoMovimientoRecambio));
    let itemHeight = popupHeight / tiposMovRecambio.length - 21; // 21 es el padding del padre

    return (
      <div style={{ height: itemHeight }} className="container_spanCentrado">
        <div className="font-size">{itemData.denominacion.toUpperCase()}</div>
      </div>
    );
  }

  onClick_btnAtras_movimientoRecambio(e) {
    let _this = this;
    function resetData() {
      let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
      tblMovimientoRecambio.idTipoMovimientoRecambio = null;

      if (_this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
        _this.datasource_tblRecambioNAlmacenRecambios_regularizacion
          .load()
          .done(function () {
            _this.setState({
              datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
                _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.items(),
              tblMovimientoRecambio: tblMovimientoRecambio,
              idTipoAlmacen: 1,
            });
            _this.reset_popupMovimientoRecambios();
          });
        e.component.repaint();
      } else {
        _this.setState({
          tblMovimientoRecambio: tblMovimientoRecambio,
          idTipoAlmacen: 1,
        });
        _this.reset_popupMovimientoRecambios();
      }
    }

    if (_this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
      let notSaved =
        $.grep(
          _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.items(),
          function (item) {
            return item.isEdited;
          },
        ).length > 0;
      if (notSaved) {
        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              resetData();
            },
            "danger",
          ],
          [getTrad("cancelar"), function () {}],
        ]);
      } else resetData();
    } else {
      resetData();
    }
  }

  toolbar_tblMovimientoRecambio_render() {
    let {
      tblMovimientoRecambio,
      is_inventarioPistolaLectora,
      recambioPistolaSel,
      is_movimientoBloqueado,
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio,
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
    } = this.state;
    let is_nuevoMovimiento =
      this.state.tblMovimientoRecambio.idMovimientoRecambio === undefined;
    let idAlmacenDestino =
      tblMovimientoRecambio.almacenDestino !== null &&
      tblMovimientoRecambio.almacenDestino !== undefined
        ? tblMovimientoRecambio.almacenDestino.idAlmacen
        : tblMovimientoRecambio.idAlmacenDestino;
    let _this = this;

    //#region Almacenes destino
    let subAlmacenesDestino = [];
    let almacenesDestino = $.map(
      this.state.items_almacen_destino,
      function (item, index) {
        $.each(item.tblAlmacenHijo, function (subIndex, subItem) {
          subAlmacenesDestino.push(subItem); // Para tener array los subAlmacenes
        });
        if (
          _this.is_almacenPrincipal(tblMovimientoRecambio.idAlmacenOrigen) ||
          tblMovimientoRecambio.idTipoMovimientoRecambio !== 2
        ) {
          // Almacén origen es principal
          if (item.idAlmacen !== tblMovimientoRecambio.idAlmacenOrigen) {
            // Para que no salga mismo almacén en origen y destino
            return {
              idAlmacen: item.idAlmacen,
              denominacion: item.denominacion,
              is_subAlmacen: false,
              tblMoneda: item.tblMoneda,
              idPais: item.idPais,
            };
          } // Si son iguales, pon los hijos
          else {
            $.each(item.tblAlmacenHijo, function (subIndex, subItem) {
              subItem.is_subAlmacen = true;
              subItem.recambiosPadre = item.tblRecambioNAlmacenRecambios;
              subItem.tblMoneda = item.tblMoneda;
            });
            return item.tblAlmacenHijo;
          }
        } // Almacén origen es secundario
        else {
          let is_princOrigen = $.grep(item.tblAlmacenHijo, function (item) {
            return item.idAlmacen === tblMovimientoRecambio.idAlmacenOrigen;
          });

          if (is_princOrigen.length > 0) {
            // Almacen principal es el padre del almacén origen
            $.each(item.tblAlmacenHijo, function (subIndex, subItem) {
              subItem.is_subAlmacen = true;
              subItem.recambiosPadre = item.tblRecambioNAlmacenRecambios;
              subItem.tblMoneda = item.tblMoneda;
            });

            let filtroAlmacen = $.grep(item.tblAlmacenHijo, function (item) {
              return item.idAlmacen !== tblMovimientoRecambio.idAlmacenOrigen;
            });

            let almPrincipal = {
              idAlmacen: item.idAlmacen,
              denominacion: item.denominacion,
              is_subAlmacen: false,
              tblMoneda: item.tblMoneda,
              idPais: item.idPais,
            };

            filtroAlmacen.push(almPrincipal);

            return filtroAlmacen;
          }
        }
      },
    ).sort(function (a, b) {
      if (
        (a.is_subAlmacen && b.is_subAlmacen) ||
        (!a.is_subAlmacen && !b.is_subAlmacen)
      ) {
        var textA = a.denominacion.toUpperCase();
        var textB = b.denominacion.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      } else {
        return a.is_subAlmacen ? 1 : -1;
      }
    });

    subAlmacenesDestino.sort(function (a, b) {
      var textA = a.denominacion.toUpperCase();
      var textB = b.denominacion.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    //#endregion

    let tblCierreRecambioNAlmacen = [...this.state.tblCierreRecambioNAlmacen];
    let fechaMin_sinCierreAlmacen;

    let disabledToolbarSuperior_noReg =
      this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio &&
      this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio.store.data
        .length > 0 &&
      this.state.tblMovimientoRecambio.fecha != null;

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 1) {
      // ENTRADA
      let cierreAlmacen = $.grep(tblCierreRecambioNAlmacen, function (item) {
        return item.idAlmacen == idAlmacenDestino;
      });
      if (cierreAlmacen.length > 0) {
        let date = new Date(cierreAlmacen[0].fecha);
        fechaMin_sinCierreAlmacen = new Date(
          date.setMonth(date.getMonth() + 1),
        );
      }

      return (
        <Fragment>
          <Toolbar elementAttr={this.toolbarAttributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">ID</span>
              </label>
              <TextBox
                value={
                  tblMovimientoRecambio.idMovimientoRecambio
                    ? tblMovimientoRecambio.idMovimientoRecambio + ""
                    : ""
                }
                disabled
                width={135}
              />
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("origen")}
                </span>
              </label>
              <Lookup
                ref={this.dxLookupProveedores_REF}
                placeholder={getTrad("seleccioneProveedor")}
                disabled={
                  (!is_nuevoMovimiento && !tblMovimientoRecambio.isEditable) ||
                  disabledToolbarSuperior_noReg
                }
                valueExpr={"idProveedor"}
                displayExpr={"nombreComercial"}
                dataSource={this.datasource_tblProveedor.items()}
                value={tblMovimientoRecambio.idProveedor}
                showCancelButton={false}
                width={305}
                onSelectionChanged={this.onSelectionChanged_dxLookupProveedores}
              >
                <DropDownOptions showTitle={false} closeOnOutsideClick={true} />
              </Lookup>
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("almacenDestino")}
                </span>
              </label>
              <Lookup
                dataSource={almacenesDestino}
                showCancelButton={false}
                ref={this.dxLookupAlmacenes_REF}
                value={idAlmacenDestino}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                width={305}
                displayExpr="denominacion"
                valueExpr="idAlmacen"
                placeholder={getTrad("seleccioneAlmacen")}
                onSelectionChanged={this.onSelectionChanged_dxLookupAlmacenes}
              >
                <DropDownOptions
                  showTitle={false}
                  height={600}
                  closeOnOutsideClick={true}
                />
              </Lookup>
            </ToolbarItem>

            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("fecha")}
                </span>
              </label>
              <DateBox
                displayFormat="dd/MM/yyyy HH:mm"
                min={is_nuevoMovimiento ? fechaMin_sinCierreAlmacen : null}
                value={this.state.tblMovimientoRecambio.fecha}
                onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                openOnFieldClick={true}
                acceptCustomValue={false}
                type={"datetime"}
                width={160}
              />
            </ToolbarItem>
            <ToolbarItem location="before" cssClass="align-bottom">
              <Button
                text={getTrad("guardarEntrada")}
                type="success"
                icon="check"
                disabled={
                  (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                  (datasource_dxDataGrid_tblRecambioNMovimientoRecambio ==
                    null &&
                    is_nuevoMovimiento)
                }
                useSubmitBehavior={true}
                validationGroup="validationGroup_form_movimiento"
                onClick={() => {
                  this.setState({ is_submitData: true });
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <Toolbar elementAttr={this.toolbarSecundario_Attributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("pedidoAsociado")}
                </span>
              </label>
              <TextBox
                value={this.state.tblMovimientoRecambio.numPedidoAsociado}
                onValueChanged={this.onValueChanged_dxTextBox_numPedidoAsociado}
                disabled={
                  !is_nuevoMovimiento && !tblMovimientoRecambio.isEditable
                }
                width={135}
              >
                <Validator>
                  <AsyncRuleTextBox
                    ignoreEmptyValue={true}
                    message="Ya existe un movimiento con este pedido asociado."
                    validationCallback={
                      this
                        .dxValidator_numPedidoAsociado_asyncRule_validationCallback
                    }
                  />
                </Validator>
              </TextBox>
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("codigoProveedor")}
                </span>
              </label>
              <TextBox
                value={this.state.tblMovimientoRecambio.codigoAlbaranProveedor}
                onValueChanged={
                  this.onValueChanged_dxTextBox_codigoAlbaranProveedor
                }
                disabled={
                  !is_nuevoMovimiento && !tblMovimientoRecambio.isEditable
                }
                width={135}
              />
            </ToolbarItem>
          </Toolbar>
        </Fragment>
      );
    } else if (tblMovimientoRecambio.idTipoMovimientoRecambio === 2) {
      // TRASPASO
      let datasource_origen;
      if (this.state.idTipoAlmacen === 1) {
        // Almacén principal
        if (tblMovimientoRecambio.almacenDestino != null)
          // Si hay seleccionado almacén destino
          datasource_origen = $.grep(
            this.state.items_almacen_origen,
            function (item) {
              return (
                item.idAlmacen !==
                tblMovimientoRecambio.almacenDestino.idAlmacen
              );
            },
          );
        else datasource_origen = this.state.items_almacen_origen;
      } // Subalmacén
      else {
        if (tblMovimientoRecambio.almacenDestino != null)
          // Si hay seleccionado almacén destino
          datasource_origen = $.grep(subAlmacenesDestino, function (item) {
            return (
              item.idAlmacen !== tblMovimientoRecambio.almacenDestino.idAlmacen
            );
          });
        else datasource_origen = subAlmacenesDestino;
      }

      let fechaOrigen, fechaDestino;
      let cierreAlmacenOrigen = $.grep(
        tblCierreRecambioNAlmacen,
        function (item) {
          return (
            item.idAlmacen ==
            (tblMovimientoRecambio
              ? tblMovimientoRecambio.idAlmacenOrigen
              : null)
          );
        },
      );

      if (cierreAlmacenOrigen.length > 0) {
        let date = new Date(cierreAlmacenOrigen[0].fecha);
        fechaOrigen = new Date(date.setMonth(date.getMonth() + 1));
      }

      let cierreAlmacenDestino = $.grep(
        tblCierreRecambioNAlmacen,
        function (item) {
          return item.idAlmacen == idAlmacenDestino;
        },
      );

      if (cierreAlmacenDestino.length > 0) {
        let date = new Date(cierreAlmacenDestino[0].fecha);
        fechaDestino = new Date(date.setMonth(date.getMonth() + 1));
      }

      if (
        tblMovimientoRecambio.idAlmacenOrigen != null &&
        tblMovimientoRecambio.almacenDestino != null
      ) {
        var minDate;
        if (fechaOrigen != null && fechaDestino != null) {
          minDate = new Date(Math.max.apply(null, [fechaOrigen, fechaDestino]));
        } else if (fechaOrigen != null) {
          minDate = new Date(fechaOrigen);
        } else if (fechaDestino != null) {
          minDate = new Date(fechaDestino);
        }

        fechaMin_sinCierreAlmacen = new Date(minDate);
      }

      return (
        <Fragment>
          <Toolbar elementAttr={this.toolbarAttributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">ID</span>
              </label>
              <TextBox
                value={
                  tblMovimientoRecambio.idMovimientoRecambio
                    ? tblMovimientoRecambio.idMovimientoRecambio + ""
                    : ""
                }
                disabled
                width={135}
              />
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("origen")}
                </span>
              </label>
              <Lookup
                ref={this.dxLookupAlmacenOrigen_REF}
                placeholder={getTrad("seleccioneAlmacen")}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                valueExpr={"idAlmacen"}
                displayExpr={"denominacion"}
                dataSource={datasource_origen}
                value={tblMovimientoRecambio.idAlmacenOrigen}
                showCancelButton={false}
                width={320}
                onSelectionChanged={
                  this.onSelectionChanged_dxLookupAlmacenOrigen
                }
              >
                <DropDownOptions
                  showTitle={true}
                  titleRender={this.titleRender_dxLookup_almacenTraspaso}
                  closeOnOutsideClick={true}
                ></DropDownOptions>
              </Lookup>
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("almacenDestino")}
                </span>
              </label>
              <Lookup
                dataSource={almacenesDestino}
                showCancelButton={false}
                ref={this.dxLookupAlmacenes_REF}
                value={idAlmacenDestino}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                width={305}
                displayExpr="denominacion"
                valueExpr="idAlmacen"
                placeholder={getTrad("seleccioneAlmacen")}
                onSelectionChanged={this.onSelectionChanged_dxLookupAlmacenes}
              >
                <DropDownOptions
                  showTitle={false}
                  height={600}
                  closeOnOutsideClick={true}
                />
              </Lookup>
            </ToolbarItem>

            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("fecha")}
                </span>
              </label>
              <DateBox
                displayFormat="dd/MM/yyyy HH:mm"
                min={is_nuevoMovimiento ? fechaMin_sinCierreAlmacen : null}
                value={this.state.tblMovimientoRecambio.fecha}
                type={"datetime"}
                width={160}
                onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                openOnFieldClick={true}
                acceptCustomValue={false}
              />
            </ToolbarItem>
            <ToolbarItem location="before" cssClass="align-bottom">
              <Button
                text={getTrad("guardarTraspaso")}
                type="success"
                icon="check"
                disabled={
                  (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                  (datasource_dxDataGrid_tblRecambioNMovimientoRecambio ==
                    null &&
                    is_nuevoMovimiento)
                }
                useSubmitBehavior={true}
                validationGroup="validationGroup_form_movimiento"
                onClick={() => {
                  this.setState({ is_submitData: true });
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <Toolbar elementAttr={this.toolbarSecundario_Attributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("numRegistro")}
                </span>
              </label>
              <TextBox
                value={this.state.tblMovimientoRecambio.numRegistro}
                onValueChanged={this.onValueChanged_dxTextBox_numRegistro}
                disabled={!is_nuevoMovimiento}
                width={135}
              >
                <Validator>
                  <AsyncRuleTextBox
                    ignoreEmptyValue={true}
                    message="Ya existe un movimiento con este número registro asociado."
                    validationCallback={
                      this.dxValidator_numRegistro_asyncRule_validationCallback
                    }
                  />
                </Validator>
              </TextBox>
            </ToolbarItem>
          </Toolbar>
        </Fragment>
      );
    } else if (tblMovimientoRecambio.idTipoMovimientoRecambio === 3) {
      // SALIDA
      let cierreAlmacen = $.grep(tblCierreRecambioNAlmacen, function (item) {
        return (
          item.idAlmacen ==
          (tblMovimientoRecambio ? tblMovimientoRecambio.idAlmacenOrigen : null)
        );
      });

      if (cierreAlmacen.length > 0) {
        let date = new Date(cierreAlmacen[0].fecha);
        fechaMin_sinCierreAlmacen = new Date(
          date.setMonth(date.getMonth() + 1),
        );
      }

      return (
        <Fragment>
          <Toolbar elementAttr={this.toolbarAttributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">ID</span>
              </label>
              <TextBox
                value={
                  tblMovimientoRecambio.idMovimientoRecambio
                    ? tblMovimientoRecambio.idMovimientoRecambio + ""
                    : ""
                }
                disabled
                width={135}
              />
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("origen")}
                </span>
              </label>
              <Lookup
                ref={this.dxLookupAlmacenOrigen_REF}
                placeholder={getTrad("seleccioneAlmacen")}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                valueExpr={"idAlmacen"}
                displayExpr={"denominacion"}
                dataSource={this.state.items_almacen_origen}
                value={tblMovimientoRecambio.idAlmacenOrigen}
                showCancelButton={false}
                width={305}
                onSelectionChanged={
                  this.onSelectionChanged_dxLookupAlmacenOrigen
                }
              >
                <DropDownOptions showTitle={false} closeOnOutsideClick={true} />
              </Lookup>
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("clienteDestino")}
                </span>
              </label>
              <TextBox
                ref={this.dxTextBox_clienteDestino_REF}
                width={325}
                value={tblMovimientoRecambio.clienteDestino}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
              />
            </ToolbarItem>

            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("fecha")}
                </span>
              </label>
              <DateBox
                displayFormat="dd/MM/yyyy HH:mm"
                min={is_nuevoMovimiento ? fechaMin_sinCierreAlmacen : null}
                value={this.state.tblMovimientoRecambio.fecha}
                type={"datetime"}
                width={160}
                onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                disabled={!is_nuevoMovimiento || disabledToolbarSuperior_noReg}
                openOnFieldClick={true}
                acceptCustomValue={false}
              />
            </ToolbarItem>
            <ToolbarItem location="before" cssClass="align-bottom">
              <Button
                text={getTrad("guardarSalida")}
                type="success"
                icon="check"
                disabled={
                  (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                  (datasource_dxDataGrid_tblRecambioNMovimientoRecambio ==
                    null &&
                    is_nuevoMovimiento)
                }
                useSubmitBehavior={true}
                validationGroup="validationGroup_form_movimiento"
                onClick={() => {
                  this.setState({ is_submitData: true });
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <Toolbar elementAttr={this.toolbarSecundario_Attributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("numRegistro")}
                </span>
              </label>
              <TextBox
                value={this.state.tblMovimientoRecambio.numRegistro}
                onValueChanged={this.onValueChanged_dxTextBox_numRegistro}
                disabled={!is_nuevoMovimiento}
                width={135}
              >
                <Validator>
                  <AsyncRuleTextBox
                    ignoreEmptyValue={true}
                    message="Ya existe un movimiento con este número registro asociado."
                    validationCallback={
                      this.dxValidator_numRegistro_asyncRule_validationCallback
                    }
                  />
                </Validator>
              </TextBox>
            </ToolbarItem>
          </Toolbar>
        </Fragment>
      );
    } else if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
      // REGULARIZACIÓN
      let cierreAlmacen = $.grep(tblCierreRecambioNAlmacen, function (item) {
        return item.idAlmacen == idAlmacenDestino;
      });

      if (cierreAlmacen.length > 0) {
        let date = new Date(cierreAlmacen[0].fecha);
        fechaMin_sinCierreAlmacen = new Date(
          date.setMonth(date.getMonth() + 1),
        );
      }

      let disabledToolbarSuperior =
        !is_nuevoMovimiento ||
        (((datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario !=
          null &&
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.length) ||
          (this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio &&
            this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio
              .store.data.length > 0)) &&
          this.state.tblMovimientoRecambio.fecha != null);

      let gridWidth = $(
        ".gridMovimiento_observaciones_reg .dx-datagrid-content",
      ).width();
      return (
        <Fragment>
          <Toolbar elementAttr={this.toolbarAttributes}>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">ID</span>
              </label>
              <TextBox
                value={
                  tblMovimientoRecambio.idMovimientoRecambio
                    ? tblMovimientoRecambio.idMovimientoRecambio + ""
                    : ""
                }
                disabled
                width={135}
              />
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("almacen")}
                </span>
              </label>
              <Lookup
                ref={this.dxLookupAlmacenes_REF}
                dataSource={
                  this.state.idTipoAlmacen === 1
                    ? almacenesDestino
                    : subAlmacenesDestino
                }
                value={idAlmacenDestino}
                placeholder={getTrad("seleccioneAlmacen")}
                disabled={disabledToolbarSuperior}
                valueExpr={"idAlmacen"}
                displayExpr={"denominacion"}
                showCancelButton={false}
                width={375}
                onSelectionChanged={this.onSelectionChanged_dxLookupAlmacenes}
              >
                <DropDownOptions
                  showTitle={true}
                  titleRender={this.titleRender_dxLookup_almacenReg}
                  closeOnOutsideClick={true}
                ></DropDownOptions>
              </Lookup>
            </ToolbarItem>
            <ToolbarItem location="before">
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("fecha")}
                </span>
              </label>
              <DateBox
                displayFormat="dd/MM/yyyy HH:mm"
                min={is_nuevoMovimiento ? fechaMin_sinCierreAlmacen : null}
                value={
                  tblMovimientoRecambio.isInventario
                    ? new Date()
                    : this.state.tblMovimientoRecambio.fecha
                }
                disabled={
                  disabledToolbarSuperior || tblMovimientoRecambio.isInventario
                }
                onValueChanged={this.onValueChanged_dxDateBox_fechaMovimiento}
                type={"datetime"}
                width={160}
              />
            </ToolbarItem>
            <ToolbarItem location="before">
              <div style={{ paddingTop: 27 }} />
              <Switch
                switchedOnText={getTrad("inventario")}
                switchedOffText={getTrad("regularizacion")}
                className={"no-disabled"}
                width={130}
                disabled={disabledToolbarSuperior}
                value={this.state.tblMovimientoRecambio.isInventario}
                onValueChanged={this.onValueChanged_dxSwitch_isInventario}
              />
            </ToolbarItem>
            <ToolbarItem location="after" cssClass="align-bottom">
              <div style={{ paddingTop: 27 }} />
              <Button
                text={getTrad("guardarRegularizacion")}
                type="success"
                icon="check"
                useSubmitBehavior={true}
                validationGroup="validationGroup_form_movimiento"
                disabled={
                  (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                  this.state.tblMovimientoRecambio.fecha == null ||
                  ((datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario ==
                    null ||
                    datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.length ==
                      0) &&
                    (this.state
                      .datasource_dxDataGrid_tblRecambioNMovimientoRecambio ==
                      null ||
                      this.state
                        .datasource_dxDataGrid_tblRecambioNMovimientoRecambio
                        .store.data.length == 0)) // ||
                }
                onClick={() => {
                  this.setState({ is_submitData: true });
                }}
              />
            </ToolbarItem>
          </Toolbar>
          <Toolbar elementAttr={this.toolbarSecundario_Attributes}>
            <ToolbarItem
              location="before"
              visible={!this.state.tblMovimientoRecambio.isInventario}
            >
              <label className="dx-form pl-1">
                <span className="dx-field-item-label-text">
                  {getTrad("numRegistro")}
                </span>
              </label>
              <TextBox
                value={this.state.tblMovimientoRecambio.numRegistro}
                onValueChanged={this.onValueChanged_dxTextBox_numRegistro}
                disabled={!is_nuevoMovimiento}
                width={135}
              >
                <Validator>
                  <AsyncRuleTextBox
                    ignoreEmptyValue={true}
                    message="Ya existe un movimiento con este número registro asociado."
                    validationCallback={
                      this.dxValidator_numRegistro_asyncRule_validationCallback
                    }
                  />
                </Validator>
              </TextBox>
            </ToolbarItem>
            <ToolbarItem
              location="before"
              visible={this.state.tblMovimientoRecambio.isInventario === true}
            >
              <Box direction="row" width="100%" height="100%">
                <ItemBox ratio={1}>
                  <Switch
                    switchedOnText={"Pistola lectora"}
                    switchedOffText={"Manual"}
                    className={"no-disabled"}
                    width={155}
                    value={is_inventarioPistolaLectora}
                    disabled={
                      !is_nuevoMovimiento ||
                      tblMovimientoRecambio.almacenDestino == null
                    }
                    onValueChanged={
                      this.onValueChanged_dxSwitch_isInventarioPistolaLectora
                    }
                  />
                </ItemBox>
              </Box>
            </ToolbarItem>
            <ToolbarItem location="before">
              <div
                style={{
                  paddingTop: this.state.tblMovimientoRecambio.isInventario
                    ? 0
                    : 27,
                }}
              />
              <Box direction="row" width={515} height="100%">
                <ItemBox ratio={1}>
                  <SelectBox
                    dataSource={_this.datasource_tblProveedor}
                    displayExpr="nombreComercial"
                    valueExpr="idProveedor"
                    placeholder={
                      getTrad("seleccionar") +
                      " " +
                      getTrad("fabricante").toLowerCase()
                    }
                    showClearButton={true}
                    width="250px"
                    onValueChanged={function (i) {
                      _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                        "tblRecambio.idProveedor",
                        "filterValue",
                        i.value,
                      );
                      _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.repaint();
                    }}
                  />
                </ItemBox>
                <ItemBox ratio={1}>
                  {tblMovimientoRecambio.isInventario && (
                    <SelectBox
                      width={210}
                      dropDownOptions={
                        this.dxSelectBox_recambios_regulacion_dropDownOptions
                      }
                      value={null}
                      dataSource={_this.datasource_tblRecambio_regularizacion}
                      searchEnabled={true}
                      searchExpr={[
                        "denominacion",
                        "referencia",
                        "referenciaInterna",
                      ]}
                      showDropDownButton={false}
                      openOnFieldClick={false}
                      minSearchLength={1}
                      displayExpr={_this.dxSelectboxRecambios_displayExpr}
                      onValueChanged={
                        _this.dxSelectboxRecambios_regularizacion_filter_onValueChanged
                      }
                      visible={!is_inventarioPistolaLectora}
                      disabled={
                        (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                        (tblMovimientoRecambio.almacenDestino == null &&
                          tblMovimientoRecambio.idAlmacenDestino == null) ||
                        this.state.tblMovimientoRecambio.fecha == null
                      }
                      placeholder={getTrad("busquedaNuevoRecambio")}
                    />
                  )}
                </ItemBox>
              </Box>
            </ToolbarItem>
          </Toolbar>

          <div
            className={
              "w-100 d-flex flex-row " +
              (!tblMovimientoRecambio.isInventario ? "pt-3" : "")
            }
          >
            {!tblMovimientoRecambio.isInventario && (
              <div className="flex-1">
                <SelectBox
                  width="100%"
                  ref={this.dxSelectboxRecambios_REF}
                  dataSource={
                    tblMovimientoRecambio.idTipoMovimientoRecambio !== 1
                      ? this.datasource_tblRecambio
                      : this.datasource_tblRecambioNProveedor
                  }
                  searchEnabled={true}
                  searchExpr={[
                    "denominacion",
                    "referencia",
                    "referenciaInterna",
                  ]}
                  showDropDownButton={false}
                  openOnFieldClick={false}
                  minSearchLength={1}
                  displayExpr={this.dxSelectboxRecambiosNProveedor_displayExpr}
                  itemRender={this.dxSelectboxRecambiosNProveedor_itemRender}
                  onValueChanged={
                    this.dxSelectboxRecambios_filter_onValueChanged
                  }
                  showClearButton={true}
                  disabled={
                    (!is_nuevoMovimiento && is_movimientoBloqueado) ||
                    (tblMovimientoRecambio.idTipoMovimientoRecambio !== 4 &&
                      tblMovimientoRecambio.idAlmacenOrigen == null &&
                      tblMovimientoRecambio.idProveedor == null) || // tipoMov - 4 = regularización
                    (tblMovimientoRecambio.idTipoMovimientoRecambio !== 3 &&
                      idAlmacenDestino == null) || // tipoMov - 3 = salida
                    this.state.tblMovimientoRecambio.fecha == null
                  }
                  placeholder={getTrad("busquedaNuevoRecambio")}
                />
              </div>
            )}

            <div
              style={{
                width: !tblMovimientoRecambio.isInventario ? 40 : "100%",
              }}
            >
              <Toolbar>
                <ToolbarItem
                  location="before"
                  visible={is_inventarioPistolaLectora}
                  widget="dxTextBox"
                  showText="inMenu"
                  locateInMenu="auto"
                >
                  <TextBox
                    ref={this.dxTextBox_refFabricante_regularizacion_REF}
                    width={gridWidth * 0.11}
                    value={
                      recambioPistolaSel.tblRecambio
                        ? recambioPistolaSel.tblRecambio.referencia
                        : null
                    }
                    placeholder={getTrad("refFabricante")}
                    onValueChanged={
                      this.dxTextBox_refFabricante_regularizacion_onValueChanged
                    }
                  />
                </ToolbarItem>
                <ToolbarItem
                  location="before"
                  visible={is_inventarioPistolaLectora}
                  showText="inMenu"
                  locateInMenu="auto"
                >
                  <TextBox
                    width={gridWidth * 0.47 - 190}
                    readOnly={true}
                    value={
                      recambioPistolaSel.tblRecambio
                        ? recambioPistolaSel.tblRecambio.denominacion
                        : null
                    }
                    placeholder={getTrad("denominacion")}
                  />
                </ToolbarItem>
                <ToolbarItem
                  location="before"
                  visible={is_inventarioPistolaLectora}
                  showText="inMenu"
                  locateInMenu="auto"
                >
                  <TextBox
                    width={gridWidth * 0.08}
                    readOnly={true}
                    value={
                      recambioPistolaSel.cantidadTeorico
                        ? recambioPistolaSel.cantidadTeorico.toString()
                        : null
                    }
                    placeholder={getTrad("teorico")}
                  />
                </ToolbarItem>
                <ToolbarItem
                  location="before"
                  visible={is_inventarioPistolaLectora}
                  showText="inMenu"
                  locateInMenu="auto"
                >
                  <TextBox
                    ref={this.dxTextBox_real_regularizacion_REF}
                    width={gridWidth * 0.08}
                    placeholder={getTrad("real")}
                    value={recambioPistolaSel.cantidad}
                    onFocusOut={this.dxTextBox_real_regularizacion_onFocusOut}
                    onValueChanged={
                      this.dxTextBox_real_regularizacion_onValueChanged
                    }
                  />
                </ToolbarItem>
                <ToolbarItem
                  location="after"
                  widget="dxButton"
                  showText="inMenu"
                  locateInMenu="never"
                >
                  <Button
                    text={getTrad("limpiarFiltro")}
                    icon=" icon_EliminarFiltros"
                    onClick={this.dxButton_eliminarFiltro_regulariacion_onClick}
                  />
                </ToolbarItem>
              </Toolbar>
            </div>
          </div>
        </Fragment>
      );
    }
  }

  isSubAlmacenDestino(tblMovimientoRecambio) {
    if (tblMovimientoRecambio.tblAlmacenRecambios_origen) {
      if (tblMovimientoRecambio.tblAlmacenRecambios_origen.tblAlmacenHijo)
        return (
          tblMovimientoRecambio.tblAlmacenRecambios_origen.tblAlmacenHijo.find(
            (alm) => alm.idAlmacen == tblMovimientoRecambio.idAlmacenDestino,
          ) != null
        );
      return false;
    }
    return false;
  }

  dxSelectBox_recambios_regulacion_dropDownOptions = {
    minWidth: 750,
  };

  dxButton_eliminarFiltro_regulariacion_onClick(e) {
    let grid = this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion;
    let idFabricante = grid.columnOption(
      "tblRecambio.idProveedor",
      "filterValue",
    );
    grid.beginUpdate();
    grid.clearFilter();
    grid.clearSorting();
    grid.columnOption("isNuevo", "sortIndex", 0);
    grid.columnOption("isNuevo", "sortOrder", "asc");
    grid.columnOption("isEdited", "sortIndex", 1);
    grid.columnOption("isEdited", "sortOrder", "desc");
    grid.columnOption("diferencia", "sortIndex", 2);
    grid.columnOption("diferencia", "sortOrder", "desc");
    grid.columnOption("tblRecambio.denominacion", "sortIndex", 3);
    grid.columnOption("tblRecambio.denominacion", "sortOrder", "asc");
    grid.columnOption("tblRecambio.idProveedor", "filterValue", idFabricante);
    grid.endUpdate();
    notify({
      message: getTrad("aviso_C_FiltroRestaurado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  }

  validationCallback_CustomRule_MaxVal(e) {
    let _this = this;
    let maxCantidad = null;
    let idTipoMovimientoRecambio =
      _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio;

    maxCantidad = e.data.maxCantidad;
    let cantidad = e.data.cantidad;

    e.rule.message =
      getTrad("alerta_cantidadExcedida") +
      " " +
      maxCantidad +
      " " +
      getTrad("unidades").toLowerCase() +
      ".";
    if (maxCantidad !== null) {
      if (idTipoMovimientoRecambio !== 4 && cantidad > maxCantidad) {
        return false;
      } else if (idTipoMovimientoRecambio === 4) {
        // Regularización
        if (cantidad < 0) {
          return Math.abs(cantidad) <= maxCantidad;
        } else return true;
      } else {
        return true;
      }
    } else return true;
  }

  validationCallback_CustomRule_MinVal(e) {
    let _this = this;
    let idTipoMovimientoRecambio =
      _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio;

    let cantidad = e.data.cantidad;
    e.rule.message = getTrad("aviso_insertarUnRecambio");

    if (idTipoMovimientoRecambio !== 4) {
      return !(cantidad == 0);
    } else if (idTipoMovimientoRecambio === 4) {
      // Regularización
      return true;
    }
  }

  titleRender_dxLookup_almacenTraspaso(e) {
    return (
      <RadioGroup
        elementAttr={this.elementAttr_titleRender_dxLookup_tipoAlmacen}
        dataSource={this.enum_tipoAlmacen}
        valueExpr="idTipoAlmacen"
        displayExpr="denominacion"
        layout="horizontal"
        height="100%"
        value={this.state.idTipoAlmacen}
        onContentReady={this.onContentReady_dxLookup_tipoAlmacen}
        onValueChanged={this.onValueChanged_dxLookup_tipoAlmacen}
      />
    );
  }

  formMovimiento_tabPanel_elem = {
    class: "no-header",
  };

  elementAttr_titleRender_dxLookup_tipoAlmacen = {
    class: "titleRender_dxLookup_tipoAlmacen",
  };

  dxDataGrid_tblRecambioNAlmacenRecambios_remoteOperations = { sorting: false };

  onContentReady_dxLookup_tipoAlmacen(e) {
    if (!e.component.option("value")) e.component.option("value", 1);
  }

  onValueChanged_dxLookup_tipoAlmacen(e) {
    this.setState({ idTipoAlmacen: e.value });
  }

  titleRender_dxLookup_almacenReg(e) {
    return (
      <RadioGroup
        elementAttr={this.elementAttr_titleRender_dxLookup_tipoAlmacen}
        dataSource={this.enum_tipoAlmacen}
        valueExpr="idTipoAlmacen"
        displayExpr="denominacion"
        layout="horizontal"
        height="100%"
        value={this.state.idTipoAlmacen}
        onContentReady={this.onContentReady_dxLookup_tipoAlmacen}
        onValueChanged={this.onValueChanged_dxLookup_tipoAlmacen}
      />
    );
  }

  onValueChanged_dxSwitch_isInventarioPistolaLectora(e) {
    if (e.value) {
      this.setState({
        is_inventarioPistolaLectora: e.value,
      });
    } else {
      this.setState({
        is_inventarioPistolaLectora: e.value,
      });
    }
  }

  onValueChanged_dxSwitch_isInventario(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.isInventario = e.value;
    if (e.value) tblMovimientoRecambio.fecha = new Date();
    this.setState(
      {
        tblMovimientoRecambio: tblMovimientoRecambio,
        is_inventarioPistolaLectora: false,
      },
      () => {
        let _this = this;
        let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };

        tblMovimientoRecambio.almacenDestino = e.selectedItem;
        tblMovimientoRecambio.almacenDestino_bak = e.selectedItem;
        tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];

        let isRegularizacion =
          tblMovimientoRecambio.idTipoMovimientoRecambio === 4;

        let grid = _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion;
        let dataSource =
          _this.datasource_tblRecambioNAlmacenRecambios_regularizacion;

        if (e.value) {
          dataSource.load().done(function () {
            grid.option("noDataText", "");
            _this.setState({
              ["datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario"]:
                dataSource.items(),
            });
            grid.option("noDataText", getTrad("sinDatos"));
          });
        } else {
          grid.option("noDataText", "");
          if (_this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio)
            _this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio.store.data =
              [];
          _this.setState({
            ["datasource_dxDataGrid_tblRecambioNMovimientoRecambio"]:
              dataSource,
            datasource_dxDataGrid_tblRecambioNMovimientoRecambio: {
              store: {
                type: "array",
                data: _this.state.tblMovimientoRecambio
                  .tblRecambioNMovimientoRecambio,
                key: "idRecambio",
              },
            },
          });
          grid.option("noDataText", getTrad("sinDatos"));
        }
      },
    );
  }

  onValueChanged_dxDateBox_fechaMovimiento(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.fecha = e.value;
    this.setState({ tblMovimientoRecambio });
    e.component.close();
  }

  onValueChanged_dxTextBox_numPedidoAsociado(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.numPedidoAsociado = e.value;
    this.setState({ tblMovimientoRecambio });
  }

  onValueChanged_dxTextBox_codigoAlbaranProveedor(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.codigoAlbaranProveedor = e.value;
    this.setState({ tblMovimientoRecambio });
  }

  dxValidator_numPedidoAsociado_asyncRule_validationCallback(e) {
    let value = e.value.replace(/\s+/g, " ").trim();
    let _this = this;
    let { tblMovimientoRecambio } = this.state;

    this.setState({ isNumPedidoAsociadoValid: true });
    return new Promise((resolve, reject) => {
      _this.context_movimiento
        .invoke(
          "fn_isNumPedidoAsociadoExists",
          {
            numPedidoAsociado: value,
            idMovimientoRecambio: tblMovimientoRecambio.idMovimientoRecambio,
          },
          "POST",
        )
        .done(function (exists) {
          if (!exists) {
            _this.setState({ isNumPedidoAsociadoValid: true });
            resolve();
          } else {
            _this.setState({ isNumPedidoAsociadoValid: false });
            reject();
          }
        });
    });
  }

  dxValidator_numRegistro_asyncRule_validationCallback(e) {
    let value = e.value.replace(/\s+/g, " ").trim();
    let _this = this;
    let { tblMovimientoRecambio } = this.state;

    this.setState({ isNumRegistroValid: false });
    return new Promise((resolve, reject) => {
      _this.context_movimiento
        .invoke(
          "fn_isNumRegistroExists",
          {
            numRegistro: value,
            idMovimientoRecambio: tblMovimientoRecambio.idMovimientoRecambio,
          },
          "POST",
        )
        .done(function (exists) {
          if (!exists) {
            _this.setState({ isNumRegistroValid: true });
            resolve();
          } else {
            _this.setState({ isNumRegistroValid: false });
            reject();
          }
        });
    });
  }

  onValueChanged_dxTextBox_numRegistro(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.numRegistro = e.value;
    this.setState({ tblMovimientoRecambio });
  }

  onEditorPreparing_tblRecambioNAlmacenRecambios_regularizacion(e) {
    if (e.parentType === "dataRow") {
      if (e.dataField === "cantidad") {
        e.editorOptions.onKeyDown = (args) => {
          e.row.data.isEdited = true;
        };
      }
    }
  }

  onRowPrepared_tblRecambioNAlmacenRecambios_regularizacion(e) {
    if (e.rowType === "data") {
      let warningRow_recambios =
        e.data.cantidad !== null
          ? e.data.cantidad - e.data.cantidadTeorico < 0
            ? true
            : false
          : false;

      if (e.data.isNuevo) {
        e.rowElement.addClass("nuevaRow");
      } else if (
        this.state.tblMovimientoRecambio.idMovimientoRecambio == null &&
        e.data.isEdited
      ) {
        if (warningRow_recambios) {
          e.rowElement.addClass("warningRow");
        }
        if (!warningRow_recambios) {
          e.rowElement.addClass("successRow");
        }
      }
    }
  }

  onCellPrepared_tblRecambioNAlmacenRecambios_regularizacion(e) {
    if (e.rowType === "data") {
      if (e.column.dataField === "cantidadTeorico") {
        if (e.value == null) e.cellElement.text("-");
      }
    }
  }

  cellRender_tblRecambioNAlmacen_edit(itemData) {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={(e) => {
          this.tblRecambioNAlmacen_edit_onClick(e, itemData);
        }}
      >
        <div
          className="icon_Comment font-size-lg"
          title="Historial del recambio"
        />
      </div>
    );
  }

  tblRecambioNAlmacen_edit_onClick(e, itemData) {
    let _this = this;
    this.setState({
      recambioNAlmacenSel: {
        idRecambio: itemData.data.idRecambio,
        denominacion: itemData.data.tblRecambio.denominacion,
      },
      isVisible_dxPopup_historialRecambio: true,
    });
  }

  onHiding_dxPopup_historialRecambio(e) {
    this.setState({
      isVisible_dxPopup_historialRecambio: false,
      data_dxPopup_dxPopup_historialRecambio: [],
    });
  }

  onHidden_dxPopup_historialRecambio(e) {
    this.setState({
      recambioNAlmacenSel: null,
      isLoading_popupGrid: false,
    });
  }

  onShown_dxPopup_historialRecambio(e) {
    let _this = this;
    this.setState({ isLoading_popupGrid: true });
    this.datasource_tblRecambioNAlmacenRecambios_GetPrecioHistoricoRecambio
      .reload()
      .done(function (data) {
        _this.setState({
          data_dxPopup_dxPopup_historialRecambio: data,
          isLoading_popupGrid: false,
        });
      });
  }

  onToolbarPreparing_tblRecambioNAlmacenRecambios(e) {
    let _this = this;
    let { almacenSel } = this.state;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: 95,
          text: getTrad("informes"),
          onClick: function () {
            _this.setState({ param_paginaSecundaria: {} });
          },
        },
      },
      {
        location: "before",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          width: "100%",
          text: getTrad("cierrePrecios"),
          type: "normal",
          disabled: !almacenSel,
          onClick: function () {
            _this.setState({ isVisible_cierrePrecios: true });
          },
        },
      },
      {
        location: "before",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          width: "100%",
          icon: "exportxlsx",
          text: getTrad("exportar"),
          type: "normal",
          onClick: function () {
            e.component.exportToExcel();
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          width: "100%",
          icon: "search",
          text: getTrad("busquedaAvanzada"),
          type: "normal",
          onClick: function () {
            _this.setState({ isVisible_busquedaAvanzada: true });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxSwitch",
        locateInMenu: "auto",
        options: {
          visible: this.state.almacenSel,
          width: 180,
          switchedOnText: "Incluir subalmacén",
          switchedOffText: "No incluir subalmacén",
          defaultValue: this.state.is_switchSubAlmacen,
          value: this.state.is_switchSubAlmacen,
          elementAttr: this.elementAttr_dxSwitch_toolbar,
          onValueChanged: (value) =>
            this.onValueChanged_Switch_tblRecambioNAlmacenRecambios(value, e),
        },
      },
    );
  }

  dxTextArea_comentarioMovimiento_onValueChanged(e) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    tblMovimientoRecambio.observaciones = e.value;
    this.setState({ tblMovimientoRecambio });
  }

  onValueChanged_Switch_tblRecambioNAlmacenRecambios(value, e) {
    this.setState({ is_switchSubAlmacen: value.value });
  }

  elementAttr_dxSwitch_toolbar = {
    class: "dxSwitch_toolbar",
  };

  onRowUpdating_tblRecambioNAlmacenRecambios(e) {
    let _this = this;
    let { almacenSel, subAlmacenSel } = this.state;
    delete e.newData.isEliminarEnable;
    this.datasource_tblRecambioNAlmacenRecambios_gridPrincipal
      .store()
      .update(
        {
          idAlmacen: almacenSel
            ? almacenSel.idAlmacen
            : subAlmacenSel.idAlmacen,
          idRecambio: e.key.idRecambio,
        },
        e.newData,
      )
      .done(function () {
        $.each(
          _this.dxTreeView_AlmacenRecambios.option("items"),
          function (iAlmacen, almacen) {
            if (almacenSel && almacen.idAlmacen === almacenSel.idAlmacen) {
              $.each(
                almacen.tblRecambioNAlmacenRecambios,
                function (iRec, recambio) {
                  if (recambio.idRecambio === e.key.idRecambio) {
                    recambio.ubicacion = e.newData.ubicacion;
                  }
                },
              );
            } else if (
              subAlmacenSel &&
              almacen.idAlmacen === subAlmacenSel.idAlmacenPadre
            ) {
              $.each(almacen.tblAlmacenHijo, function (iAlmHijo, almHijo) {
                if (almHijo.idAlmacen === subAlmacenSel.idAlmacen) {
                  $.each(
                    almHijo.tblRecambioNAlmacenRecambios,
                    function (iRec, recambio) {
                      if (recambio.idRecambio === e.key.idRecambio) {
                        recambio.ubicacion = e.newData.ubicacion;
                      }
                    },
                  );
                }
              });
            }
          },
        );
      });
  }

  dxTextBox_refFabricante_regularizacion_onValueChanged(e) {
    let _this = this;
    let {
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
      tblMovimientoRecambio,
    } = this.state;
    let getRecambioByRef = $.extend(
      true,
      [],
      $.grep(
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
        function (item) {
          return item.tblRecambio.referencia === e.value;
        },
      ),
    );

    if (e.value != null) {
      if (getRecambioByRef.length > 0) {
        function setRecambio() {
          getRecambioByRef[0].cantidad = null;
          _this.setState({
            recambioPistolaSel: getRecambioByRef[0],
            datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
              datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
          });

          _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.refresh();

          _this.dxTextBox_real_regularizacion.focus();

          var scrollable =
            _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.getView(
              "rowsView",
            )._scrollable;
          scrollable.scrollTo(0);
        }

        $.each(
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
          function (index, item) {
            if (item.idRecambio === getRecambioByRef[0].idRecambio) {
              if (item.isEdited) {
                dxMensajePregunta(
                  getTrad("recambioIntroducido_sobreescribir"),
                  [
                    [
                      getTrad("aceptar"),
                      function () {
                        item.isEdited = true;
                        setRecambio();
                      },
                      "danger",
                    ],
                    [
                      getTrad("cancelar"),
                      function () {
                        _this.dxTextBox_refFabricante_regularizacion.option(
                          "value",
                          null,
                        );
                        _this.dxTextBox_refFabricante_regularizacion.focus();
                      },
                    ],
                  ],
                );
              } else {
                item.isEdited = true;
                setRecambio();
              }
            }
          },
        );
      } else {
        this.setState({ recambioPistolaSel: { referencia: e.value } });
        this.datasource_tblRecambio_byReferencia
          .load()
          .done(function (recambio) {
            if (recambio.length > 0) {
              //Está en otro almacén
              let recambioFormated = {
                cantidad: 0,
                cantidadTeorico: 0,
                idAlmacen: tblMovimientoRecambio.almacenDestino.idAlmacen,
                idRecambio: recambio[0].idRecambio,
                isEdited: true,
                precioMedioPonderado: 0,
                ubicacion: null,
                isNuevo: true,
                tblRecambio: {
                  denominacion: recambio[0].denominacion,
                  idProveedor: recambio[0].idRecambio,
                  referencia: recambio[0].referencia,
                },
              };
              if (_this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
                _this.dxDataGrid_tblMovimientoRecambio.beginCustomLoading();

              if (
                _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
                  .current != null
              )
                _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.beginCustomLoading();

              delete recambioFormated.isEliminarEnable;

              _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion
                .getDataSource()
                .store()
                .insert($.extend(true, {}, recambioFormated));

              _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.refresh();
              recambioFormated.cantidad = null;
              _this.setState({ recambioPistolaSel: recambioFormated });
              _this.dxTextBox_real_regularizacion.focus();
            } else {
              _this.dxTextBox_refFabricante_regularizacion.option(
                "value",
                null,
              );
              _this.dxTextBox_refFabricante_regularizacion.focus();

              notify({
                message: "La refencia no existe",
                type: "error",
                displayTime: "1500",
                closeOnClick: true,
              });
            }
          });
      }
    }
  }

  dxTextBox_real_regularizacion_onFocusOut(e) {
    let {
      recambioPistolaSel,
      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
    } = this.state;
    let value = e.component.option("value");

    if (value != null && isNaN(value) && isNaN(parseFloat(value))) {
      e.component.focus();
      e.component.option("value", null);

      notify({
        message: "La cantidad debe tener un valor numérico",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    } else {
      if (value) {
        $.each(
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
          function (index, item) {
            if (item.idRecambio === recambioPistolaSel.idRecambio) {
              item.cantidad = value;
            }
          },
        );
      }

      this.setState({
        recambioPistolaSel: {},
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
      });
      this.dxTextBox_refFabricante_regularizacion.focus();
      this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.saveEditData();
    }
  }

  dxTextBox_real_regularizacion_onValueChanged(e) {
    if (e.value) {
      let { recambioPistolaSel } = this.state;

      let rowIndex =
        this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.getRowIndexByKey(
          recambioPistolaSel.idRecambio,
        );
      this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.cellValue(
        rowIndex,
        "cantidad",
        e.value,
      );

      this.setState({ recambioPistolaSel: {} });
      this.dxTextBox_refFabricante_regularizacion.focus();
      this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.saveEditData();
    }
  }

  onToolbarPreparing_tblRecambioNAlmacenRecambios_regularizacion(e) {
    let toolbarItems = e.toolbarOptions.items;
    $.each(toolbarItems, function (_, item) {
      if (
        item.name == "saveButton" ||
        item.name == "revertButton" ||
        item.name == "exportButton"
      ) {
        item.visible = false;
      }
    });

    e.toolbarOptions.visible = false;
  }

  dxSelectboxRecambios_regularizacion_filter_onValueChanged(e) {
    if (e.value) {
      let dataSource =
        this.state
          .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario;
      let datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario =
        dataSource.store == null
          ? [
              ...this.state
                .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
            ]
          : dataSource.store.data != null
            ? [...dataSource.store.data]
            : [
                ...this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.items(),
              ];

      let { tblMovimientoRecambio } = this.state;
      let recambio = {
        cantidad: 0,
        cantidadTeorico: 0,
        idAlmacen:
          tblMovimientoRecambio.almacenDestino != null
            ? tblMovimientoRecambio.almacenDestino.idAlmacen
            : tblMovimientoRecambio.idAlmacenDestino,
        idRecambio: e.value.idRecambio,
        isEdited: true,
        precioMedioPonderado: 0,
        precio: 0,
        ubicacion: null,
        isNuevo: true,
        tblRecambio: {
          denominacion: e.value.denominacion,
          idProveedor: null,
          referencia: e.value.referencia,
        },
      };

      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario.unshift(
        recambio,
      );
      this.setState({
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
      });
      e.component.blur();
      setTimeout(() => {
        e.component.option("value", null);
      }, 0);
    }
  }

  onEditorPreparing_tblMovimientoRecambio_gestionAlmacenes(e) {
    if (e.parentType == "dataRow" && e.dataField == "ubicacion") {
      let value = null;
      if (e.dataField == "ubicacion" && e.row.oldData !== undefined)
        value = e.row.oldData.ubicacion;

      if (
        this.state.tblMovimientoRecambio.idTipoMovimientoRecambio !== 4 &&
        (value !== null || value)
      )
        e.editorOptions.disabled = true;
    }

    if (e.parentType == "dataRow" && e.dataField === "referenciaProveedor") {
      let proveedores = e.row.data.referenciasProveedor_datasource;

      if (proveedores !== null && proveedores.length < 1)
        e.editorOptions.buttons = [];

      this.data.tblRecambioNEntrada_editingKey = e.row.key;
      this.data.referenciasProveedor_datasource = proveedores;
    }
  }

  onEditorPreparing_tblRecambioNMovimientoRecambio(e) {
    let _this = this;
    let rowsTotales = e.component.totalCount();
    if (e.dataField === "precio" && e.row.rowIndex + 1 == rowsTotales) {
      var onValueChanged = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = function (i) {
        onValueChanged.apply(this, arguments);
        _this.dxDataGrid_tblRecambioNMovimientoRecambio.cellValue(
          e.row.rowIndex,
          e.dataField,
          i.value,
        );
        setTimeout(() => {
          _this.dxSelectboxRecambios.focus();
        }, 500);
      };
    }

    if (e.parentType === "dataRow") {
      e.editorOptions.onKeyDown = (args) => {
        let source = e.component.option("dataSource");
        if (source) source.store.data[e.row.rowIndex].isEdited = true;
        e.row.data.isEdited = true;
      };
    }
  }

  onToolbarPreparing_tblRecambioNMovimientoRecambio(e) {
    let toolbarItems = e.toolbarOptions.items;
    e.toolbarOptions.height = 5;

    $.each(toolbarItems, function (_, item) {
      if (item.name == "saveButton" || item.name == "revertButton") {
        item.visible = false;
      }
    });
  }

  onRowPrepared_tblMovimientoRecambio(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  render_isInventario(e) {
    let rowData = e.data;
    let isInventario = rowData.isInventario;

    let icono = isInventario === true ? "inf_icons_Inventarios" : "";

    return (
      <div
        className={"container_spanCentrado center-svg iconos_svg " + icono}
        style={{ fontSize: "23px" }}
      />
    );
  }

  tituloAlmacenes_render() {
    return (
      <div className="font-size pl-1">{getTrad("almacenesPrincipales")}</div>
    );
  }

  dxSelectboxRecambios_style = {
    height: 50,
  };

  tblTipoMovimientoRecambio_scrolling = {
    mode: "infinite",
  };

  añadirMovimiento_buttonOption = {
    text: getTrad("movimientos"),
    onClick: () => {
      this.setState({ showPopup_MovimientoRecambios: true });
    },
  };

  tblRecambioNAlmacenRecambios_allowedPageSizes = [20, 50, 100];

  dxTreeView_almacenes_onItemClick(e) {
    if (e.node.selected === false) {
      let is_AlmacenPrincipal = e.itemData.idAlmacenPadre === null,
        data = $.extend(true, {}, e.itemData);

      if (is_AlmacenPrincipal) {
        this.setState({ almacenSel: data, subAlmacenSel: null });
      } else {
        this.setState({ almacenSel: null, subAlmacenSel: data });
      }
      if (data !== null) {
        if (data.tblMoneda !== null && data.tblMoneda !== undefined) {
          this.currency_format.currency = data.tblMoneda.codigo;
          this.currency_editorOptions.format = this.currency_format;
        } else {
          this.currency_format.currency = "EUR";
          this.currency_editorOptions.format = this.currency_format;
        }
        this.dxDataGrid_tblRecambioNAlmacenRecambios.repaint();
        this.dxDataGrid_tblRecambioNAlmacenRecambios.option(
          "paging.pageIndex",
          0,
        );
      }
    } else {
      this.setState({ almacenSel: null, subAlmacenSel: null });
      this.dxDataGrid_tblRecambioNAlmacenRecambios.repaint();
    }
  }

  dxTreeView_almacenes_render(item) {
    let is_disabled = item.activo === false;
    return (
      <div
        className={is_disabled ? "disabledRow" : ""}
        style={{
          fontSize: 16,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: 8,
        }}
      >
        {item.denominacion}
      </div>
    );
  }

  onSelectionChanged_listProveedor(e, dxDropDownBox) {
    let _this = this;
    if (e.addedItems.length > 0) {
      let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
      tblMovimientoRecambio.idProveedor = e.addedItems[0].idProveedor;
      tblMovimientoRecambio.idAlmacenOrigen = null;
      tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];

      _this.setState({ tblMovimientoRecambio });
      _this.dxSelectboxRecambios.reset();
      dxDropDownBox.component.close();
    }
  }

  onSelectionChanged_listAlmacen(e, dxDropDownBox) {
    let _this = this;
    if (e.addedItems.length > 0) {
      let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };

      tblMovimientoRecambio.idAlmacenOrigen = e.addedItems[0].idAlmacen;
      tblMovimientoRecambio.idProveedor = null;
      tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];
      _this.setState({ tblMovimientoRecambio });

      if (_this.dxSelectboxRecambios_REF.current != null)
        _this.dxSelectboxRecambios.reset();
      dxDropDownBox.component.close();
    }
  }

  onSelectionChanged_tblAlmacenRecambios_secundarios(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let subAlmacenSel = { ..._this.state.subAlmacenSel };
      subAlmacenSel = data;
      _this.setState({ subAlmacenSel });
    }
  }

  onToolbarPreparing_tblAlmacenRecambios_principales(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("almacenesPrincipales"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          text: getTrad("movimientosRecambios"),
          onClick: function () {
            _this.setState({ showPopup_MovimientoRecambios: true });
          },
        },
      },
    );
  }

  onToolbarPreparing_tblAlmacenRecambios_secundarios(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("subAlmacenes"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          text: getTrad("eliminarSeleccion"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            _this.setState({ subAlmacenSel: null });
          },
        },
      },
    );
  }

  dxSelectboxRecambiosNProveedor_displayExpr(e) {
    if (e !== null) {
      let tblRecambio = e.tblRecambio ? e.tblRecambio : e;
      let ultimoPrecio = e.ultimoPrecio ? e.ultimoPrecio : null;
      let tblProveedor = e.tblProveedor ? e.tblProveedor : null;
      let tblPais = e.tblPais ? e.tblPais : null;
      let disabled = e.disabled ? e.disabled : null;
      let idRecambioNProveedor = e.idRecambioNProveedor
        ? e.idRecambioNProveedor
        : null;

      let denominacion = e.tblRecambio
        ? e.tblRecambio.denominacion
        : e.denominacion;
      let referenciaInterna = e.tblRecambio
        ? e.tblRecambio.referenciaInterna
        : e.referenciaInterna;
      let referencia = e.tblRecambio ? e.tblRecambio.referencia : e.referencia;
      let nombreComercial = tblProveedor ? tblProveedor.nombreComercial : null;

      let referenciaInterna_ =
        referenciaInterna != null ? referenciaInterna : "N/A";
      let referencia_ = referencia != null ? referencia : "Sin referencia";
      let pais_ = tblPais != null ? tblPais.denominacion : "Sin país";
      let nombreComercial_ =
        nombreComercial != null
          ? nombreComercial
          : "Proveedor sin nombre comercial";

      let ultimoPrecio_ = ultimoPrecio != null ? ultimoPrecio : 0;
      if (tblPais)
        ultimoPrecio_ = formatNumber(
          ultimoPrecio_,
          2,
          "currency",
          tblPais.tblMoneda.codigo,
        );

      let fields = [
        { value: referenciaInterna_ },
        { value: referencia_ },
        { value: denominacion },
        { value: pais_ },
        { value: nombreComercial_ },
        { value: ultimoPrecio_ },
      ];
      return fields.map((field) => field.value).join(" - ");
    }
  }

  dxSelectboxRecambiosNProveedor_itemRender(e) {
    if (e != null) {
      let { tblMovimientoRecambio } = this.state;
      const isMovEntrada = tblMovimientoRecambio.idTipoMovimientoRecambio == 1;
      let ultimoPrecio = e.ultimoPrecio ? e.ultimoPrecio : null;
      let tblProveedor = e.tblProveedor ? e.tblProveedor : null;
      let tblPais = e.tblPais ? e.tblPais : null;
      let idRecambioNProveedor = e.idRecambioNProveedor
        ? e.idRecambioNProveedor
        : null;

      let denominacion = e.tblRecambio
        ? e.tblRecambio.denominacion
        : e.denominacion;
      let referenciaInterna = e.tblRecambio
        ? e.tblRecambio.referenciaInterna
        : e.referenciaInterna;
      let referencia = e.tblRecambio ? e.tblRecambio.referencia : e.referencia;

      let nombreComercial = tblProveedor ? tblProveedor.nombreComercial : null;

      let referenciaInterna_ =
        referenciaInterna != null ? referenciaInterna : "N/A";
      let referencia_ = referencia != null ? referencia : "Sin referencia";
      let pais_ = tblPais != null ? tblPais.denominacion : "Sin país";
      let nombreComercial_ =
        nombreComercial != null
          ? nombreComercial
          : "Proveedor sin nombre comercial";

      let ultimoPrecio_ = ultimoPrecio != null ? ultimoPrecio : 0;
      if (tblPais)
        ultimoPrecio_ = formatNumber(
          ultimoPrecio_,
          2,
          "currency",
          tblPais.tblMoneda.codigo,
        );

      let fields = [
        {
          colSpan: "1",
          text: getTrad("refInterna"),
          value: referenciaInterna_,
        },
        { colSpan: "2", text: getTrad("refFabricante"), value: referencia_ },
        {
          colSpan: isMovEntrada ? "5" : "9",
          text: getTrad("recambio"),
          value: denominacion,
        },
        {
          colSpan: "1",
          text: getTrad("pais"),
          value: pais_,
          isVisible: isMovEntrada,
        },
        {
          colSpan: "2",
          text: getTrad("proveedor"),
          value: nombreComercial_,
          isVisible: isMovEntrada,
        },
        {
          colSpan: "1",
          text: getTrad("precioCompra"),
          value: ultimoPrecio_,
          isVisible: isMovEntrada,
        },
      ];

      return (
        <>
          <div id={idRecambioNProveedor}>
            <Row disabled className="w-100">
              {fields.map((field, index) => {
                if (field.isVisible === false) return null;
                return (
                  <Col
                    key={field.text}
                    xs={field.colSpan}
                    className={
                      "p-2 text-truncate " +
                      (fields[index + 1] &&
                        fields[index + 1].isVisible !== false &&
                        "border-right")
                    }
                  >
                    {field.value}
                  </Col>
                );
              })}
            </Row>
          </div>
        </>
      );
    }
  }

  dxSelectboxRecambiosNProveedor_itemRender_onMouseOver(e) {
    if (this.dxPopover) this.dxPopover.show();
  }

  dxSelectboxRecambiosNProveedor_itemRender_onMouseLeave(e) {
    if (this.dxPopover) this.dxPopover.hide();
  }

  dxSelectboxRecambios_displayExpr(e) {
    if (e !== null)
      return (
        (e.referenciaInterna ? e.referenciaInterna + " - " : "") +
        (e.referencia ? e.referencia + " - " : "") +
        e.denominacion
      );
  }

  dxSelectboxRecambios_filter_onValueChanged(e) {
    let _this = this;
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    let value = e.value;

    function _formatData(
      maxCantidad,
      ubicacion,
      referenciasProveedor_datasource,
      precio,
      itemsRegularizacion,
    ) {
      _this.data.nuevoRecambioSel = null;

      let idRecambio =
        value.tblRecambio != null
          ? value.tblRecambio.idRecambio
          : value.idRecambio;
      let tblRecambioNAlmacenRecambios = value.tbRecambio
        ? value.tbRecambio.tblRecambioNAlmacenRecambios
        : value.tblRecambioNAlmacenRecambios;
      let objRecambioNMovimiento = {};

      if (tblMovimientoRecambio.idTipoMovimientoRecambio != 4) {
        objRecambioNMovimiento = {
          tblRecambio: value.tblRecambio ? value.tblRecambio : value,
          idRecambioNProveedor: value.idRecambioNProveedor,
          idRecambio: idRecambio,
          maxCantidad: maxCantidad,
          ubicacion: ubicacion,
          referenciaProveedor: value.referencia ? value.referencia : null,
          referenciasProveedor_datasource: referenciasProveedor_datasource,
          cantidad: tblMovimientoRecambio.idMovimientoRecambio != 4 ? 1 : 0,
          precio:
            tblMovimientoRecambio.idTipoMovimientoRecambio == 1
              ? value.ultimoPrecio
                ? value.ultimoPrecio
                : 0
              : precio,
          tblPais: e.value.tblPais,
        };
      } else {
        objRecambioNMovimiento = itemsRegularizacion;
      }

      tblMovimientoRecambio.tblRecambioNMovimientoRecambio.push(
        objRecambioNMovimiento,
      );

      e.component.reset();
      _this.setState({
        tblMovimientoRecambio: tblMovimientoRecambio,
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio: {
          store: {
            type: "array",
            data: _this.state.tblMovimientoRecambio
              .tblRecambioNMovimientoRecambio,
            key: "idRecambio",
          },
        },
      });

      setTimeout(() => {
        if (value !== null) {
          let grid = _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current
            ? _this.dxDataGrid_tblRecambioNMovimientoRecambio
            : _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion;
          let rowIndex = grid.getRowIndexByKey(idRecambio);
          grid.cellValue(rowIndex, "cantidad", objRecambioNMovimiento.cantidad);
          grid.editCell(rowIndex, "cantidad");
        }
      }, 25);
    }

    _this.data.nuevoRecambioSel = value;

    if (value !== null) {
      let maxCantidad = null,
        ubicacion = null,
        referenciaProveedor = null,
        precio = null;

      if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
        // Tipo regularización
        if (tblMovimientoRecambio.isInventario) {
          _this.datasource_tblRecambioNAlmacenRecambios_regularizacion
            .load()
            .done(function (recNAlm_destino) {
              if (!recNAlm_destino.length) {
                recNAlm_destino = [
                  {
                    cantidad: 0,
                    cantidadTeorico: 0,
                    idAlmacen:
                      tblMovimientoRecambio.almacenDestino != null
                        ? tblMovimientoRecambio.almacenDestino.idAlmacen
                        : tblMovimientoRecambio.idAlmacenDestino,
                    idRecambio: e.value.idRecambio,
                    isEdited: true,
                    precioMedioPonderado: 0,
                    precio: 0,
                    ubicacion: null,
                    isNuevo: true,
                    tblRecambio: {
                      denominacion: e.value.denominacion,
                      idProveedor: null,
                      referencia: e.value.referencia,
                    },
                  },
                ];
              }
              maxCantidad =
                recNAlm_destino.length > 0 ? recNAlm_destino[0].cantidad : null;
              _formatData(
                maxCantidad,
                ubicacion,
                referenciaProveedor,
                null,
                recNAlm_destino[0],
              );
            });
        } else {
          $.when(
            _this.datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado.load(),
          ).then(function (GetPrecioCalculado) {
            let recNAlm_destino;
            if (!GetPrecioCalculado.length) {
              recNAlm_destino = [
                {
                  cantidad: 0,
                  cantidadTeorico: 0,
                  idAlmacen:
                    tblMovimientoRecambio.almacenDestino != null
                      ? tblMovimientoRecambio.almacenDestino.idAlmacen
                      : tblMovimientoRecambio.idAlmacenDestino,
                  idRecambio: e.value.idRecambio,
                  precioMedioPonderado: 0,
                  precio: 0,
                  isEdited: true,
                  isNuevo: true,
                  ubicacion: null,
                  tblRecambio: {
                    denominacion: e.value.denominacion,
                    idProveedor: null,
                    referencia: e.value.referencia,
                  },
                },
              ];
            } else {
              recNAlm_destino = [
                {
                  cantidad: GetPrecioCalculado[0].cantidad,
                  cantidadTeorico: GetPrecioCalculado[0].cantidad,
                  idAlmacen:
                    tblMovimientoRecambio.almacenDestino != null
                      ? tblMovimientoRecambio.almacenDestino.idAlmacen
                      : tblMovimientoRecambio.idAlmacenDestino,
                  idRecambio: e.value.idRecambio,
                  precioMedioPonderado:
                    GetPrecioCalculado[0].precioMedioPonderado,
                  precio: GetPrecioCalculado[0].precioMedioPonderado,
                  ubicacion: GetPrecioCalculado[0].ubicacion,
                  tblRecambio: {
                    denominacion: e.value.denominacion,
                    idProveedor: null,
                    referencia: e.value.referencia,
                  },
                },
              ];
            }
            _formatData(
              maxCantidad,
              ubicacion,
              referenciaProveedor,
              null,
              recNAlm_destino[0],
            );
          });
        }
      } else {
        if (tblMovimientoRecambio.idAlmacenOrigen !== null) {
          // Origen almacén
          let _this = this;
          if (tblMovimientoRecambio.idTipoMovimientoRecambio === 3) {
            //Salida
            $.when(
              _this.datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado.load(),
              _this.datasource_almDestino_tblRecambioNAlmacenRecambios.load(),
            ).then(function (GetPrecioCalculado, recambioNAlm_destino) {
              maxCantidad =
                GetPrecioCalculado.length > 0
                  ? GetPrecioCalculado[0][0].cantidad
                  : 0;
              ubicacion =
                recambioNAlm_destino[0].length > 0
                  ? recambioNAlm_destino[0][0].ubicacion
                  : null;
              precio =
                GetPrecioCalculado.length > 0
                  ? GetPrecioCalculado[0][0].precioMedioPonderado
                  : 0;
              _formatData(maxCantidad, ubicacion, referenciaProveedor, precio);
            });
          } else {
            $.when(
              _this.datasource_tblRecambioNAlmacenRecambios_GetPrecioCalculado.load(),
              _this.datasource_almDestino_tblRecambioNAlmacenRecambios.load(),
            ).then(function (GetPrecioCalculado, recambioNAlm_destino) {
              maxCantidad =
                GetPrecioCalculado[0].length > 0
                  ? GetPrecioCalculado[0][0].cantidad
                  : 0;
              ubicacion =
                recambioNAlm_destino[0].length > 0
                  ? recambioNAlm_destino[0][0].ubicacion
                  : null;
              precio =
                GetPrecioCalculado[0].length > 0
                  ? GetPrecioCalculado[0][0].precioMedioPonderado
                  : 0;

              _formatData(maxCantidad, ubicacion, referenciaProveedor, precio);
            });
          }
        } // Proveedor
        else {
          let _this = this;
          $.when(
            _this.datasource_almDestino_tblRecambioNAlmacenRecambios.load(),
            _this.datasource_tblRecambioNProveedor.load(),
          ).then(function (recNAlm, recNProv) {
            let recambioNAlm = recNAlm[0];
            ubicacion =
              recambioNAlm.length > 0 ? recambioNAlm[0].ubicacion : null;

            let referenciasProveedor_datasource = $.map(
              recNProv[0],
              function (item) {
                return item.referencia;
              },
            ).filter(function (itm, i, a) {
              return i == a.indexOf(itm);
            });

            _this.data.referenciasProveedor_datasource =
              referenciasProveedor_datasource;

            _formatData(
              maxCantidad,
              ubicacion,
              referenciasProveedor_datasource,
            );
          });
        }
      }
    }
  }

  onToolbarPreparing_tblMovimientoRecambio(e) {
    let _this = this;
    let idTipoMovimientoRecambio =
      this.state.tblMovimientoRecambio.idTipoMovimientoRecambio;
    let textoBtn = getTrad("nuevaEntrada");

    if (idTipoMovimientoRecambio === 1) textoBtn = getTrad("nuevaEntrada");
    else if (idTipoMovimientoRecambio === 2)
      textoBtn = getTrad("nuevoTraspaso");
    else if (idTipoMovimientoRecambio === 3) textoBtn = getTrad("nuevaSalida");
    else if (idTipoMovimientoRecambio === 4)
      textoBtn = getTrad("nuevaRegularizacion");

    e.toolbarOptions.items.unshift(
      {
        location: "before",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          width: "200px",
          icon: "plus",
          text: textoBtn,
          onClick: function () {
            function resetData() {
              _this.reset_popupMovimientoRecambios(false, true);
              if (
                _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4
              ) {
                _this.datasource_tblRecambioNAlmacenRecambios_regularizacion
                  .load()
                  .done(function () {
                    _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.option(
                      "noDataText",
                      "",
                    );
                    _this.setState({
                      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
                        _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.items(),
                    });
                    _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.option(
                      "noDataText",
                      getTrad("sinDatos"),
                    );
                  });
                e.component.repaint();
              }

              let idTipoMovimientoRecambio =
                _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio;
              if (idTipoMovimientoRecambio === 1)
                _this.dxLookupProveedores.open();
              else if (
                idTipoMovimientoRecambio === 2 ||
                idTipoMovimientoRecambio === 3
              )
                _this.dxLookupAlmacenOrigen.open();
              else if (idTipoMovimientoRecambio === 4) {
                _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                  "cantidadTeorico",
                  "filterValue",
                  0,
                );
                _this.dxLookupAlmacenes.open();
              }
            }

            if (
              _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4
            ) {
              let notSaved =
                $.grep(
                  _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.items(),
                  function (item) {
                    return item.isEdited;
                  },
                ).length > 0;
              if (notSaved) {
                dxMensajePregunta(getTrad("preg_PerderCambios"), [
                  [
                    getTrad("aceptar"),
                    function () {
                      resetData();
                    },
                    "danger",
                  ],
                  [getTrad("cancelar"), function () {}],
                ]);
              } else resetData();
            } else {
              resetData();
            }
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          visible:
            idTipoMovimientoRecambio === 4 &&
            _this.state.tblMovimientoRecambio.idMovimientoRecambio != null,
          height: "100%",
          text: getTrad("exportar"),
          icon: "exportxlsx",
          onClick: function (e) {
            _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.exportToExcel();
          },
        },
      },
    );
  }

  hiding_popup_MovimientoRecambios() {
    this.reset_popupMovimientoRecambios(true);
  }

  onSelectionChanged_dxLookupProveedores(e) {
    if (e.selectedItem !== null) {
      let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
      tblMovimientoRecambio.idProveedor = e.selectedItem.idProveedor;
      this.setState({
        tblMovimientoRecambio,
      });
    }
  }

  onSelectionChanged_dxLookupAlmacenOrigen(e) {
    if (e.selectedItem !== null) {
      let _this = this;
      let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
      let bkAlmacen = $.extend(
        true,
        {},
        tblMovimientoRecambio.almacenDestino_bak,
      );
      function loadData() {
        let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
        tblMovimientoRecambio.idAlmacenOrigen = e.selectedItem.idAlmacen;
        tblMovimientoRecambio.almacenDestino = null;
        tblMovimientoRecambio.almacenDestino_bak = null;
        tblMovimientoRecambio.idAlmacenDestino = null;
        tblMovimientoRecambio.tblAlmacenRecambios_origen = e.selectedItem;

        tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];
        _this.setState({
          tblMovimientoRecambio,
          datasource_dxDataGrid_tblRecambioNMovimientoRecambio: null,
        });
        if (_this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current != null)
          _this.dxDataGrid_tblRecambioNMovimientoRecambio.cancelEditData();
      }

      if (tblMovimientoRecambio.idTipoMovimientoRecambio === 3) {
        // SALIDA
        let notSaved =
          _this.dxDataGrid_tblRecambioNMovimientoRecambio.getDataSource()
            ? $.grep(
                _this.dxDataGrid_tblRecambioNMovimientoRecambio
                  .getDataSource()
                  .items(),
                function (item) {
                  return item.isEdited;
                },
              ).length > 0
            : false;

        if (!notSaved) {
          loadData();
        } else {
          dxMensajePregunta(getTrad("preg_PerderCambios"), [
            [
              getTrad("aceptar"),
              function () {
                loadData();
              },
              "danger",
            ],
            [
              getTrad("cancelar"),
              function () {
                let tblMovimientoRecambio = {
                  ..._this.state.tblMovimientoRecambio,
                };
                tblMovimientoRecambio.almacenDestino = bkAlmacen;
                tblMovimientoRecambio.almacenDestino_bak = bkAlmacen;

                _this.setState({
                  tblMovimientoRecambio: tblMovimientoRecambio,
                });
              },
            ],
          ]);
        }
      } else {
        loadData();
      }
    }
  }

  onSelectionChanged_dxLookupAlmacenes(e) {
    let _this = this;
    let idAlmacenDestino =
      _this.state.tblMovimientoRecambio.almacenDestino != null
        ? _this.state.tblMovimientoRecambio.almacenDestino.idAlmacen
        : null;
    if (e.selectedItem !== null) {
      if (e.selectedItem.idAlmacen !== idAlmacenDestino) {
        let tblMovimientoRecambio = { ..._this.state.tblMovimientoRecambio };
        let bkAlmacen = $.extend(
          true,
          {},
          tblMovimientoRecambio.almacenDestino_bak,
        );

        tblMovimientoRecambio.almacenDestino = e.selectedItem;
        tblMovimientoRecambio.almacenDestino_bak = e.selectedItem;
        tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];

        let isRegularizacion =
          tblMovimientoRecambio.idTipoMovimientoRecambio === 4;

        let grid = isRegularizacion
          ? _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion
          : _this.dxDataGrid_tblRecambioNMovimientoRecambio;
        let dataSource = isRegularizacion
          ? _this.datasource_tblRecambioNAlmacenRecambios_regularizacion
          : _this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio;
        let stateName =
          isRegularizacion && tblMovimientoRecambio.isInventario
            ? "datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario"
            : "datasource_dxDataGrid_tblRecambioNMovimientoRecambio";

        if (!dataSource) {
          _this.setState({
            tblMovimientoRecambio,
          });
        } else {
          function loadData() {
            grid.getScrollable().scrollTo(0);
            grid.cancelEditData();
            _this.setState({ tblMovimientoRecambio });
            grid.repaint();

            if (isRegularizacion && tblMovimientoRecambio.isInventario) {
              dataSource.load().done(function () {
                grid.option("noDataText", "");
                _this.setState({ [stateName]: dataSource.items() });
                grid.option("noDataText", getTrad("sinDatos"));
              });
            } else {
              grid.option("noDataText", "");
              if (
                _this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio
              )
                _this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio.store.data =
                  [];
              _this.setState({
                [stateName]: dataSource,
                datasource_dxDataGrid_tblRecambioNMovimientoRecambio: {
                  store: {
                    type: "array",
                    data: _this.state.tblMovimientoRecambio
                      .tblRecambioNMovimientoRecambio,
                    key: "idRecambio",
                  },
                },
              });
              grid.option("noDataText", getTrad("sinDatos"));
            }
          }

          let notSaved =
            $.grep(
              isRegularizacion
                ? dataSource.items()
                : _this.dxDataGrid_tblRecambioNMovimientoRecambio
                    .getDataSource()
                    .items(),
              function (item) {
                return item.isEdited;
              },
            ).length > 0;
          if (!notSaved) {
            loadData();
          } else {
            dxMensajePregunta(getTrad("preg_PerderCambios"), [
              [
                getTrad("aceptar"),
                function () {
                  loadData();
                },
                "danger",
              ],
              [
                getTrad("cancelar"),
                function () {
                  let tblMovimientoRecambio = {
                    ..._this.state.tblMovimientoRecambio,
                  };
                  tblMovimientoRecambio.almacenDestino = bkAlmacen;
                  tblMovimientoRecambio.almacenDestino_bak = bkAlmacen;

                  _this.setState({
                    tblMovimientoRecambio: tblMovimientoRecambio,
                  });
                },
              ],
            ]);
          }
        }

        if (_this.dxSelectboxRecambios_REF.current != null)
          _this.dxSelectboxRecambios.reset();
      }
    }
  }

  onSelectionChanged_tblMovimientoRecambio(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    let grid;
    if (this.state.tblMovimientoRecambio.idTipoMovimientoRecambio !== 4)
      grid = this.dxDataGrid_tblRecambioNMovimientoRecambio;
    else grid = this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion;

    if (data) {
      let notSaved_regularizacion =
        $.grep(
          _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.items(),
          function (item) {
            return item.isEdited;
          },
        ).length > 0;
      var notSaved = grid.getController("editing").hasEditData();
      if (notSaved || notSaved_regularizacion) {
        if (_this.data.movimientoSel_validation_row === null)
          _this.data.movimientoSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              grid.cancelEditData();

              let movimientoSel = $.extend(
                true,
                {},
                _this.data.movimientoSel_validation_row,
              );
              let movimientoSel_ = $.extend(true, {}, movimientoSel);

              let idTipoMov =
                _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio; // 2 - traspaso || 4 - regularización
              let idAlmacen =
                idTipoMov === 2 || idTipoMov === 3
                  ? data.idAlmacenOrigen
                  : data.tblAlmacenRecambios_destino.idAlmacen;
              let idTipoAlmacen;

              if (_this.is_almacenPrincipal(idAlmacen)) idTipoAlmacen = 1;
              else idTipoAlmacen = 2;

              if (
                _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio ===
                  4 &&
                _this.state.tblMovimientoRecambio.isInventario
              ) {
                _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.load();
                _this.setState(
                  {
                    tblMovimientoRecambio: movimientoSel_,
                    tblMovimientoRecambio_bk: $.extend(
                      true,
                      {},
                      movimientoSel_,
                    ),
                    idTipoAlmacen: idTipoAlmacen,
                    is_movimientoBloqueado: true,
                    isNumPedidoAsociadoValid: true,
                    isNumRegistroValid: true,
                  },
                  () => {
                    _this.setState({
                      datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
                        {
                          store: {
                            type: "array",
                            data: _this.state.tblMovimientoRecambio
                              .tblRecambioNMovimientoRecambio,
                            key: "idRecambio",
                          },
                        },
                    });
                  },
                );

                e.component.repaint();
              } else {
                _this.setState(
                  {
                    tblMovimientoRecambio: movimientoSel_,
                    tblMovimientoRecambio_bk: $.extend(
                      true,
                      {},
                      movimientoSel_,
                    ),
                    idTipoAlmacen: idTipoAlmacen,
                    is_movimientoBloqueado:
                      !movimientoSel.isEditable ||
                      movimientoSel.idTipoMovimientoRecambio == 4,
                    isNumPedidoAsociadoValid: true,
                    isNumRegistroValid: true,
                  },
                  () => {
                    _this.setState({
                      datasource_dxDataGrid_tblRecambioNMovimientoRecambio: {
                        store: {
                          type: "array",
                          data: _this.state.tblMovimientoRecambio
                            .tblRecambioNMovimientoRecambio,
                          key: "idRecambio",
                        },
                      },
                    });
                  },
                );
              }

              _this.data.movimientoSel_validation_row = null;
              if (_this.dxSelectboxRecambios_REF.current != null)
                _this.dxSelectboxRecambios.reset();

              _this.datasource_tblRecambioNMovimientoRecambio
                .reload()
                .done(function () {
                  if (
                    _this.state.tblMovimientoRecambio
                      .idTipoMovimientoRecambio === 4
                  ) {
                    _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                      "cantidadTeorico",
                      "filterValue",
                      null,
                    );
                  }
                });
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.movimientoSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let idTipoMov =
          this.state.tblMovimientoRecambio.idTipoMovimientoRecambio; // 4 - regularización
        let idAlmacen =
          idTipoMov === 2 || idTipoMov === 3
            ? data.idAlmacenOrigen
            : data.tblAlmacenRecambios_destino.idAlmacen;
        let idTipoAlmacen;

        if (this.is_almacenPrincipal(idAlmacen)) idTipoAlmacen = 1;
        else idTipoAlmacen = 2;

        if (
          _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4 &&
          _this.state.tblMovimientoRecambio.isInventario
        ) {
          _this.setState(
            {
              tblMovimientoRecambio: $.extend(true, {}, data),
              tblMovimientoRecambio_bk: $.extend(true, {}, data),
              idTipoAlmacen: idTipoAlmacen,
              is_movimientoBloqueado: !data.isEditable || idTipoMov == 4,
              isNumPedidoAsociadoValid: true,
              isNumRegistroValid: true,
            },
            () => {
              _this.setState({
                datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario:
                  {
                    store: {
                      type: "array",
                      data: _this.state.tblMovimientoRecambio
                        .tblRecambioNMovimientoRecambio,
                      key: "idRecambio",
                    },
                  },
              });
            },
          );

          _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.repaint();
          e.component.repaint();
        } else {
          _this.setState(
            {
              tblMovimientoRecambio: $.extend(true, {}, data),
              tblMovimientoRecambio_bk: $.extend(true, {}, data),
              idTipoAlmacen: idTipoAlmacen,
              is_movimientoBloqueado: !data.isEditable || idTipoMov == 4,
              isNumPedidoAsociadoValid: true,
              isNumRegistroValid: true,
            },
            () => {
              _this.setState({
                datasource_dxDataGrid_tblRecambioNMovimientoRecambio: {
                  store: {
                    type: "array",
                    data: _this.state.tblMovimientoRecambio
                      .tblRecambioNMovimientoRecambio,
                    key: "idRecambio",
                  },
                },
              });
            },
          );
          _this.datasource_tblRecambioNMovimientoRecambio.reload();
        }

        if (_this.dxSelectboxRecambios_REF.current != null)
          _this.dxSelectboxRecambios.reset();

        _this.datasource_tblRecambioNMovimientoRecambio
          .reload()
          .done(function () {
            if (
              _this.state.tblMovimientoRecambio.idTipoMovimientoRecambio === 4
            ) {
              _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                "cantidadTeorico",
                "filterValue",
                null,
              );
            }
          });
      }
    }
  }

  is_almacenPrincipal(idAlmacen) {
    let is_almacenPrincipal = $.grep(
      this.state.items_almacen_general,
      function (item) {
        return item.idAlmacen === idAlmacen;
      },
    );
    return is_almacenPrincipal.length > 0;
  }

  onSelectionChanged_tblAlmacenRecambios_principales(e) {
    let _this = this;
    const data = e.selectedRowsData[0];
    if (data) {
      let almacenSel = $.extend(true, {}, data);
      _this.setState({ almacenSel: almacenSel, subAlmacenSel: null });
    } else _this.setState({ subAlmacenSel: null });
  }

  onRowPrepared_tblAlmacenRecambios(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }
  //#endregion

  reset_popupMovimientoRecambios(closePopup, isNuevoMovimiento) {
    let tblMovimientoRecambio = { ...this.state.tblMovimientoRecambio };
    let idTipoMovimientoRecambio =
      tblMovimientoRecambio != null &&
      tblMovimientoRecambio.idTipoMovimientoRecambio != null
        ? tblMovimientoRecambio.idTipoMovimientoRecambio
        : null;
    tblMovimientoRecambio.tblRecambioNMovimientoRecambio = [];
    delete tblMovimientoRecambio.idMovimientoRecambio;
    delete tblMovimientoRecambio.tblAlmacenRecambios_destino;
    delete tblMovimientoRecambio.tblAlmacenRecambios_origen;
    tblMovimientoRecambio.almacenDestino = null;
    tblMovimientoRecambio.almacenDestino_bak = null;
    tblMovimientoRecambio.idAlmacenDestino = null;
    tblMovimientoRecambio.idAlmacenOrigen = null;
    tblMovimientoRecambio.idProveedor = null;
    tblMovimientoRecambio.clienteDestino = null;
    tblMovimientoRecambio.fecha = null;
    tblMovimientoRecambio.observaciones = null;
    tblMovimientoRecambio.codigoAlbaranProveedor = null;
    tblMovimientoRecambio.numPedidoAsociado = null;
    tblMovimientoRecambio.numRegistro = null;
    tblMovimientoRecambio.isInventario = false;

    if (!isNuevoMovimiento)
      tblMovimientoRecambio.idTipoMovimientoRecambio = null;

    if (closePopup) {
      this.setState({
        tblMovimientoRecambio: tblMovimientoRecambio,
        tblMovimientoRecambio_bk: $.extend(true, {}, tblMovimientoRecambio),
        showPopup_MovimientoRecambios: false,
        isGuardarClick: false,
        idTipoAlmacen: 1,
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio: null,
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario: null,
        is_inventarioPistolaLectora: false,
        recambioPistolaSel: {},
        is_movimientoBloqueado: false,
        isNumPedidoAsociadoValid: true,
        isNumRegistroValid: true,
      });
    } else {
      this.setState({
        tblMovimientoRecambio: tblMovimientoRecambio,
        tblMovimientoRecambio_bk: $.extend(true, {}, tblMovimientoRecambio),
        idTipoAlmacen: 1,
        isGuardarClick: false,
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio: null,
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario: null,
        is_inventarioPistolaLectora: false,
        recambioPistolaSel: {},
        is_movimientoBloqueado: false,
        isNumPedidoAsociadoValid: true,
        isNumRegistroValid: true,
      });
    }

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 3)
      //Salida
      this.dxTextBox_clienteDestino.reset();

    if (this.dxSelectboxRecambios_REF.current != null)
      // Puede ser null en caso de regularización
      this.dxSelectboxRecambios.reset();
    this.datasource_tblProveedor.load();

    if (this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
      this.dxDataGrid_tblMovimientoRecambio.clearFilter();

    if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
      //Regularización
      if (
        this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
          .current != null
      ) {
        this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.clearFilter();
        this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.cancelEditData();
      }
    } else {
      if (this.dxDataGrid_tblRecambioNMovimientoRecambio.current != null)
        this.dxDataGrid_tblRecambioNMovimientoRecambio.cancelEditData();
    }
  }

  formatData_tblMovimientoRecambio = () => {
    let _this = this;
    //#region FORMATEAR DATOS
    let tblMovimientoRecambio_ = $.extend(
      true,
      {},
      _this.state.tblMovimientoRecambio,
    );
    tblMovimientoRecambio_.fecha = formatDateTime_parameter(
      tblMovimientoRecambio_.fecha,
    );
    tblMovimientoRecambio_.idAlmacenDestino =
      tblMovimientoRecambio_.almacenDestino != null
        ? tblMovimientoRecambio_.almacenDestino.idAlmacen
        : tblMovimientoRecambio_.tblAlmacenRecambios_destino != null
          ? tblMovimientoRecambio_.tblAlmacenRecambios_destino.idAlmacen
          : null;
    tblMovimientoRecambio_.clienteDestino =
      tblMovimientoRecambio_.idTipoMovimientoRecambio === 3
        ? _this.dxTextBox_clienteDestino.option("value")
        : null;

    delete tblMovimientoRecambio_.almacenDestino;
    delete tblMovimientoRecambio_.almacenDestino_bak;
    delete tblMovimientoRecambio_.isEditable;
    delete tblMovimientoRecambio_.isEliminarEnable;

    if (tblMovimientoRecambio_.idTipoMovimientoRecambio !== 4) {
      $.each(
        tblMovimientoRecambio_.tblRecambioNMovimientoRecambio,
        function (index, item) {
          delete item.referenciasProveedor_datasource;
          delete item.tblRecambio;
          delete item.maxCantidad;
          delete item.idRecambioNProveedor;
          delete item.tblPais;
          delete item.isEdited;
          item.idMovimientoRecambio = tblMovimientoRecambio_?.idMovimientoRecambio;
        },
      );
      delete tblMovimientoRecambio_.tblAlmacenRecambios_destino;
      delete tblMovimientoRecambio_.tblAlmacenRecambios_origen;
    } else {
      //Se genera el array con los recambios con los nuevos datos.
      let dataSource = tblMovimientoRecambio_.isInventario
        ? _this.state
            .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario
        : _this.state.datasource_dxDataGrid_tblRecambioNMovimientoRecambio;

      let datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario =
        dataSource.store == null
          ? tblMovimientoRecambio_.isInventario
            ? [
                ..._this.state
                  .datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
              ]
            : [
                ..._this.state
                  .datasource_dxDataGrid_tblRecambioNMovimientoRecambio,
              ]
          : [...dataSource.store.data];

      let recambios_ = $.grep(
        datasource_dxDataGrid_tblRecambioNMovimientoRecambio_inventario,
        function (recambio, index) {
          return (
            (tblMovimientoRecambio_.idMovimientoRecambio == null &&
              recambio.isEdited) ||
            tblMovimientoRecambio_.idMovimientoRecambio != null
          );
        },
      );

      let recambios = $.map(recambios_, function (item) {
        return {
          idMovimientoRecambio: tblMovimientoRecambio_?.idMovimientoRecambio,
          idRecambio: item.idRecambio,
          ubicacion: item.ubicacion,
          referenciaProveedor: item.referenciaProveedor,
          cantidad: item.cantidad,
          cantidadTeorico: item.cantidadTeorico,
          precio: item.precioMedioPonderado
            ? item.precioMedioPonderado
            : item.precio,
        };
      });
      tblMovimientoRecambio_.tblRecambioNMovimientoRecambio = recambios;
    }
    return tblMovimientoRecambio_;
    //#endregion
  };

  submit_formMovimiento = (e) => {
    e.preventDefault();
    let _this = this;
    let {
      tblMovimientoRecambio,
      idTipoAlmacen,
      isNumPedidoAsociadoValid,
      isNumRegistroValid,
      isGuardarClick,
    } = this.state;

    let invalid_pedidoRegistro =
      (!isNumPedidoAsociadoValid &&
        tblMovimientoRecambio.numPedidoAsociado != "" &&
        tblMovimientoRecambio.numPedidoAsociado != null) ||
      (!isNumRegistroValid &&
        tblMovimientoRecambio.numRegistro != "" &&
        tblMovimientoRecambio.numRegistro != null);

    if (
      _this.state.is_submitData &&
      (invalid_pedidoRegistro || tblMovimientoRecambio.fecha == null)
    ) {
      notify({
        message: "Hay campos no válidos.",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return false;
    }

    if (
      !_this.state.is_submitData ||
      invalid_pedidoRegistro ||
      tblMovimientoRecambio.fecha == null
    )
      return false; //Solo cogerá submit cuando se haya pulsado el botón de guardar

    if (!isGuardarClick) {
      _this.setState({ isGuardarClick: true });
      if (tblMovimientoRecambio.idMovimientoRecambio) {
        let visibleRows =
          this.dxDataGrid_tblRecambioNMovimientoRecambio.getVisibleRows();
        let filteredRows = $.grep(visibleRows, function (item) {
          return !item.removed;
        });

        let bk_tblMovimientoRecambio = $.extend(
          true,
          {},
          _this.state.tblMovimientoRecambio,
        );
        if (filteredRows.length > 0) {
          let grid;
          if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4)
            grid = _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion;
          else grid = _this.dxDataGrid_tblRecambioNMovimientoRecambio;

          grid
            .getController("validating")
            .validate(true)
            .done(function (dxDataGrid_tblRecambioNMovimientoRecambio_isValid) {
              if (dxDataGrid_tblRecambioNMovimientoRecambio_isValid) {
                if (_this.dxDataGrid_tblMovimientoRecambio_REF.current != null)
                  _this.dxDataGrid_tblMovimientoRecambio.beginCustomLoading();

                if (
                  _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF.current !=
                  null
                )
                  _this.dxDataGrid_tblRecambioNMovimientoRecambio.beginCustomLoading();

                grid.saveEditData().done(function () {
                  let formData = _this.formatData_tblMovimientoRecambio();

                  let deletedRows = visibleRows
                    .filter((x) => x.removed)
                    .map((x) => x.data);
                  deletedRows.forEach((x) => (x.cantidad = 0));
                  formData.tblRecambioNMovimientoRecambio = [
                    ...formData.tblRecambioNMovimientoRecambio,
                    ...deletedRows,
                  ];

                  delete formData.isEliminarEnable;
                  _this.datasource_tblMovimientoRecambio
                    .store()
                    .update(
                      tblMovimientoRecambio.idMovimientoRecambio,
                      formData,
                    )
                    .then(
                      () => {},
                      (error) => {
                        let message = JSON.parse(error.message);
                        if (typeof message !== "number") {
                          if (
                            _this.dxDataGrid_tblMovimientoRecambio_REF
                              .current != null
                          )
                            _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

                          if (
                            _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF
                              .current != null
                          ) {
                            _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();
                          }

                          //Revertir cambios
                          _this.setState(
                            {
                              tblMovimientoRecambio: {
                                // Resetea el grid
                                ..._this.state.tblMovimientoRecambio,
                                idMovimientoRecambio: -1,
                              },
                              isNumPedidoAsociadoValid: true,
                              isNumRegistroValid: true,
                              isGuardarClick: false,
                              isVisible_dxPopup_errorIncoherencia: true,
                              data_dxPopup_errorIncoherencia: message,
                            },
                            () => {
                              _this.setState({
                                tblMovimientoRecambio: $.extend(
                                  true,
                                  {},
                                  bk_tblMovimientoRecambio,
                                ),
                                tblMovimientoRecambio_bk: $.extend(
                                  true,
                                  {},
                                  bk_tblMovimientoRecambio,
                                ),
                                datasource_dxDataGrid_tblRecambioNMovimientoRecambio:
                                  {
                                    store: {
                                      type: "array",
                                      data: bk_tblMovimientoRecambio.tblRecambioNMovimientoRecambio,
                                      key: "idRecambio",
                                    },
                                  },
                              });
                              grid.refresh();
                            },
                          );
                        }
                        _this.setState({ isGuardarClick: false });
                      },
                    );
                });
              } else {
                _this.setState({ isGuardarClick: false });
              }
            });
        } else {
          notify({
            message: "No se puede guardar un movimiento vacío.",
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
          _this.setState({ is_submitData: false, isGuardarClick: false });
        }
      } else if (tblMovimientoRecambio.idTipoMovimientoRecambio === 4) {
        // Regularización
        let formData = this.formatData_tblMovimientoRecambio();
        if (formData.tblRecambioNMovimientoRecambio.length > 0) {
          if (idTipoAlmacen === 2) {
            //Subalmacen
            formData.isInventario = false;
          }

          _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion
            .saveEditData()
            .done(function () {
              delete formData.isEliminarEnable;

              _this.datasource_tblMovimientoRecambio
                .store()
                .insert(formData)
                .then(
                  (e) => {
                    const response = JSON.parse(e);
                    tblMovimientoRecambio.idMovimientoRecambio = response[0].ID;

                    _this.setState({ tblMovimientoRecambio });
                  },
                  (error) => {
                    let message = JSON.parse(error.message);
                    if (typeof message !== "number") {
                      if (
                        _this.dxDataGrid_tblMovimientoRecambio_REF.current !=
                        null
                      )
                        _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

                      if (
                        _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF
                          .current != null
                      )
                        _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();
                    }
                    _this.setState({
                      isGuardarClick: false,
                      isVisible_dxPopup_errorIncoherencia: true,
                      data_dxPopup_errorIncoherencia: message,
                    });
                  },
                )
                .done(function (almacen) {
                  _this.datasource_tblRecambioNAlmacenRecambios_regularizacion.load();
                  if (tblMovimientoRecambio.isInventario) {
                    _this.datasource_tblAlmacenRecambios
                      .reload()
                      .done(function (almacenes) {
                        let almacenSel = almacen.idAlmacenDestino;
                        let almacen_bd;
                        $.each(almacenes, function (index, item) {
                          let almacenHijo = $.grep(
                            item.tblAlmacenHijo,
                            function (hijo) {
                              return hijo.idAlmacen === almacenSel;
                            },
                          );
                          if (almacenHijo.length > 0)
                            almacen_bd = almacenHijo[0];
                          else if (item.idAlmacen === almacenSel)
                            almacen_bd = item;
                        });

                        if (
                          _this.state.almacenSel &&
                          almacen_bd.idAlmacen ===
                            _this.state.almacenSel.idAlmacen
                        ) {
                          _this.setState({ almacenSel: almacen_bd });
                        } else if (
                          _this.state.subAlmacenSel &&
                          almacen_bd.idAlmacen ===
                            _this.state.subAlmacenSel.idAlmacen
                        ) {
                          _this.setState({ subAlmacenSel: almacen_bd });
                        }

                        if (
                          _this
                            .dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
                            .current != null
                        ) {
                          _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.endCustomLoading();
                          _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                            "cantidadTeorico",
                            "filterValue",
                            null,
                          );
                        }
                      });
                  }
                  if (
                    _this
                      .dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion_REF
                      .current != null
                  ) {
                    _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.endCustomLoading();
                    _this.dxDataGrid_tblRecambioNAlmacenRecambios_regularizacion.columnOption(
                      "cantidadTeorico",
                      "filterValue",
                      null,
                    );
                  }
                });
              _this.setState({ is_submitData: false });
            });
        } else {
          notify({
            message: "No se puede guardar un movimiento vacío.",
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
          _this.setState({ is_submitData: false, isGuardarClick: false });
        }
      } else {
        let visibleRows =
          this.dxDataGrid_tblRecambioNMovimientoRecambio.getVisibleRows();
        let filteredRows = $.grep(visibleRows, function (item) {
          return !item.removed;
        });

        if (filteredRows.length > 0) {
          this.dxDataGrid_tblRecambioNMovimientoRecambio
            .getController("validating")
            .validate(true)
            .done(function (dxDataGrid_tblRecambioNMovimientoRecambio_isValid) {
              if (dxDataGrid_tblRecambioNMovimientoRecambio_isValid) {
                if (!_this.state.tblMovimientoRecambio.idMovimientoRecambio) {
                  //INSERT
                  if (
                    _this.dxDataGrid_tblMovimientoRecambio_REF.current != null
                  )
                    _this.dxDataGrid_tblMovimientoRecambio.beginCustomLoading();

                  if (
                    _this.dxDataGrid_tblRecambioNMovimientoRecambio_REF
                      .current != null
                  )
                    _this.dxDataGrid_tblRecambioNMovimientoRecambio.beginCustomLoading();

                  _this.dxDataGrid_tblRecambioNMovimientoRecambio
                    .saveEditData()
                    .done(function () {
                      _this.datasource_tblMovimientoRecambio
                        .store()
                        .insert(_this.formatData_tblMovimientoRecambio())
                        .then(
                          (e) => {
                            const response = JSON.parse(e);
                            tblMovimientoRecambio.idMovimientoRecambio =
                              response[0].ID;

                            _this.setState({ tblMovimientoRecambio });
                          },
                          (error) => {
                            let message = JSON.parse(error.message);
                            if (typeof message !== "number") {
                              if (
                                _this.dxDataGrid_tblMovimientoRecambio_REF
                                  .current != null
                              )
                                _this.dxDataGrid_tblMovimientoRecambio.endCustomLoading();

                              if (
                                _this
                                  .dxDataGrid_tblRecambioNMovimientoRecambio_REF
                                  .current != null
                              )
                                _this.dxDataGrid_tblRecambioNMovimientoRecambio.endCustomLoading();
                            }
                            _this.setState({
                              isGuardarClick: false,
                              isVisible_dxPopup_errorIncoherencia: true,
                              data_dxPopup_errorIncoherencia: message,
                            });
                          },
                        );
                      _this.setState({
                        is_submitData: false,
                        isGuardarClick: false,
                      });
                    });
                }
              } else {
                _this.setState({ isGuardarClick: false });
              }
            });
        } else {
          notify({
            message: "No se puede guardar un movimiento vacío.",
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
          _this.setState({ is_submitData: false });
        }
      }
    }
  };

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps) {}
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GestionAlmacenes);
