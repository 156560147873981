import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  tooltipControl_creacion,
  formatDate,
  dxMensajePregunta,
} from "../../../helpers";
import { svg_warning } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ODataContext from "devextreme/data/odata/context";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";

import Calendar from "../../../libraries/js-year-calendar";
import "js-year-calendar/dist/js-year-calendar.css";
import "js-year-calendar/locales/js-year-calendar.es";
import "js-year-calendar/locales/js-year-calendar.pt";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var calendarLaboral = null,
    calendarLavanderia = null,
    personaSel = null,
    diasSel = {},
    calendarioLavSel = {
      isFestivo: null,
      isDiaCierre: null,
    };

  //#region DATASOURCES
  var datasource_tblPersonas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        let filtroCategoria = "(idCategoria ne 18 and idCategoria ne null)";
        let filtroLavanderia =
          "idLavanderia eq " +
          LAVANDERIA.idLavanderia +
          " or tblUsuario/any(l: l/tblLavanderia/any(t: t/idLavanderia eq " +
          LAVANDERIA.idLavanderia +
          "))";
        if (!loadOptions.filter || loadOptions.filter.length === 0) {
          loadOptions.filter = [[filtroCategoria], "and", [filtroLavanderia]];
        } else if (loadOptions.filter[0][0] !== "idPersona") {
          loadOptions.filter = [
            [
              "concat(concat(nombre, ' '), apellidos)",
              "contains",
              loadOptions.filter[0][2],
            ],
            "and",
            [filtroCategoria],
            "and",
            [filtroLavanderia],
          ];
        }
      },
      version: 4,
    }),
    select: ["idPersona", "nombre", "apellidos", "idCategoria", "activo"],
    expand: ["tblPersonaNTipoContrato"],
    sort: "nombre",
    map: function (itemData) {
      return {
        idPersona: itemData.idPersona,
        nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
        activo: itemData.activo,
        idCategoria: itemData.idCategoria,
        tblPersonaNTipoContrato: itemData.tblPersonaNTipoContrato,
      };
    },
  });

  var datasource_tblCategoria = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoria",
      key: "idCategoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  var datasource_tblCalendarioPersonal_Estado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioPersonal_Estado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idEstado", "traduccion", "colorHexa", "isLavanderia"],
    filter: "idEstado ne 14",
    map: function (itemData) {
      return {
        idEstado: itemData.idEstado,
        traduccion: itemData.traduccion,
        colorHexa: itemData.colorHexa,
        visible:
          itemData.idEstado !== 3 && itemData.idEstado !== 4 ? true : false,
        isLavanderia: itemData.isLavanderia,
      };
    },
  });

  var context_calendarioLaboral = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblCalendarioPersonal",
    entities: {
      fn_spSelectCalendarioLaboral: {
        key: ["idPersona", "fecha"],
      },
      fn_iudCalendarioPersonal: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };

      if (request.method !== "get") {
        request.params.fechaIni = formatDate_parameter(diasSel.fechaIni);
        request.params.fechaFin = formatDate_parameter(diasSel.fechaFin);
      }
    },
  });

  var datasource_tblCalendarioLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
      key: ["fecha", "idLavanderia", "idEstado"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        if (request.method === "get")
          request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    expand: ["tblCalendarioPersonal_Estado($select=colorHexa)"],
    filter: "idEstado eq 8 or idEstado eq 10",
  });

  var context_tblCalendarioLavanderia = new ODataContext({
    url: connectionConstants.ODATA_URL + "tblCalendarioLavanderia",
    entities: {
      fn_iudCalendarioLavanderia: {},
    },
    beforeSend: function (request) {
      request.headers = { ...authHeader() };
      request.params.isFestivo = calendarioLavSel.isFestivo;
      request.params.isDiaCierre = calendarioLavSel.isDiaCierre;
      request.params.fechaIni = formatDate_parameter(diasSel.fechaIni);
      request.params.fechaFin = formatDate_parameter(diasSel.fechaFin);

      request.params.idLavanderia = LAVANDERIA.idLavanderia;
    },
  });

  var dataSource_tblJornadaPersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblJornadaPersona",
      key: ["idPersona", "fecha"],
      keyType: {
        idPersona: "Int32",
        fecha: "String",
      },
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method === "DELETE") {
          let newUrl = request.url.split("tblJornadaPersona");
          request.url =
            newUrl[0] +
            "tblJornadaPersona" +
            "/" +
            personaSel.idPersona +
            "/" +
            formatDate_parameter(diasSel.fechaIni);
        }
      },
      version: 4,
    }),
  });

  var enum_activo = [
    {
      id: false,
      text: getTrad("noActivos"),
    },
    {
      id: true,
      text: getTrad("activos"),
    },
  ];
  //#endregion

  $.when(
    datasource_tblPersonas.load(),
    datasource_tblCalendarioPersonal_Estado.load(),
    datasource_tblCategoria.load(),
  )
    .done(function (personas_items, estados_items, categorias_items) {
      if (estados_items.length > 0)
        estados_items[0].sort((a, b) =>
          getTrad(a.traduccion) > getTrad(b.traduccion)
            ? 1
            : getTrad(b.traduccion) > getTrad(a.traduccion)
              ? -1
              : 0,
        );
      //Solo aparecen las categorías de las personas que hay en el grid.
      var categorias_itemsFiltradas = $.grep(
        categorias_items[0],
        function (categoria) {
          var match = false;
          for (var i = 0; i < personas_items[0].length; i++) {
            if (personas_items[0][i].idCategoria === categoria.idCategoria)
              match = true;
          }
          return match;
        },
      );

      $("#CalendarioLaboral #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div id='dxTabPanel_calendarioLaboral'/>")
                  .addClass("h4")
                  .dxTabPanel({
                    height: "100%",
                    width: "100%",
                    animationEnabled: true,
                    swipeEnabled: false,
                    deferRendering: false,
                    items: [
                      {
                        title: getTrad("calendarioLaboral"),
                        template: function (itemData, itemIndex, itemElement) {
                          itemElement.append(
                            $("<div />").dxBox({
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              height: "100%",
                              items: [
                                {
                                  ratio: 4,
                                  template: function (e, index, item) {
                                    item.css("padding-right", 30);
                                    item.append(
                                      $("<div />").dxBox({
                                        direction: "col",
                                        align: "space-around",
                                        crossAlign: "stretch",
                                        height: "100%",
                                        items: [
                                          {
                                            ratio: 1,
                                            template: function (
                                              e,
                                              index,
                                              item,
                                            ) {
                                              item.css("padding-bottom", 15);
                                              function filtroPersonas() {
                                                var activo = $(
                                                    "#dxSelectBox_activo",
                                                  )
                                                    .dxSelectBox("instance")
                                                    .option("value"),
                                                  idCategoria = $(
                                                    "#dxSelectBox_categoria",
                                                  )
                                                    .dxSelectBox("instance")
                                                    .option("value"),
                                                  datosFiltrados = $.grep(
                                                    personas_items[0],
                                                    function (persona) {
                                                      return (
                                                        ((activo &&
                                                          persona.activo ===
                                                            activo) ||
                                                          !activo) &&
                                                        ((idCategoria &&
                                                          persona.idCategoria ===
                                                            idCategoria) ||
                                                          !idCategoria)
                                                      );
                                                    },
                                                  );
                                                $("#dxList_seleccionPersona")
                                                  .dxList("instance")
                                                  .option(
                                                    "dataSource",
                                                    datosFiltrados,
                                                  );
                                              }

                                              item.append(
                                                $(
                                                  "<div id='dxToolbar_personas' />",
                                                ).dxToolbar({
                                                  items: [
                                                    {
                                                      location: "before",
                                                      widget: "dxSelectBox",
                                                      options: {
                                                        items: enum_activo,
                                                        valueExpr: "id",
                                                        width: "150px",
                                                        displayExpr: "text",
                                                        placeholder:
                                                          getTrad("todos"),
                                                        showClearButton: true,
                                                        elementAttr: {
                                                          id: "dxSelectBox_activo",
                                                        },
                                                        onValueChanged:
                                                          function (e) {
                                                            filtroPersonas();
                                                          },
                                                      },
                                                    },
                                                    {
                                                      location: "before",
                                                      widget: "dxSelectBox",
                                                      options: {
                                                        items:
                                                          categorias_itemsFiltradas,
                                                        valueExpr:
                                                          "idCategoria",
                                                        displayExpr:
                                                          "denominacion",
                                                        placeholder:
                                                          getTrad("todos"),
                                                        showClearButton: true,
                                                        elementAttr: {
                                                          id: "dxSelectBox_categoria",
                                                        },
                                                        width: "100%",
                                                        onValueChanged:
                                                          function (e) {
                                                            filtroPersonas();
                                                          },
                                                      },
                                                    },
                                                  ],
                                                }),
                                                $(
                                                  "<div id='dxList_seleccionPersona' />",
                                                )
                                                  .css("padding-top", 5)
                                                  .dxList({
                                                    dataSource:
                                                      personas_items[0],
                                                    height: "100%",
                                                    keyExpr: "idPersona",
                                                    searchExpr:
                                                      "nombreCompuesto",
                                                    displayExpr:
                                                      "nombreCompuesto",
                                                    searchEnabled: true,
                                                    focusStateEnabled: false,
                                                    selectionMode: "single",
                                                    pageLoadMode:
                                                      "scrollBottom",
                                                    onSelectionChanged:
                                                      function (e) {
                                                        personaSel = null;
                                                        if (
                                                          e.addedItems.length >
                                                          0
                                                        )
                                                          personaSel =
                                                            e.component.option(
                                                              "selectedItems",
                                                            )[0];

                                                        if (calendarLaboral)
                                                          // cuando se entra en pantalla por 1era vez
                                                          getDatosCalendarioLaboral();
                                                      },
                                                  }),
                                              );
                                            },
                                          },
                                          {
                                            baseSize: 180,
                                            template: function (
                                              e,
                                              index,
                                              item,
                                            ) {
                                              var contenedor_flex = $(
                                                "<div />",
                                              ).addClass("containerPadre_flex");
                                              $.each(
                                                estados_items[0],
                                                function (index, item) {
                                                  contenedor_flex.append(
                                                    $("<div/>")
                                                      .css({
                                                        width: "50%",
                                                        display: "flex",
                                                      })
                                                      // Colores y denominación
                                                      .append(
                                                        $("<div />")
                                                          .css(
                                                            "flex",
                                                            "0 0 20px",
                                                          )
                                                          .append(
                                                            $("<span />")
                                                              .addClass(
                                                                "circulo",
                                                              )
                                                              .css(
                                                                "background-color",
                                                                item.colorHexa,
                                                              ),
                                                          ),
                                                        $("<div />")
                                                          .css({
                                                            flex: "0 0 1",
                                                            "align-self":
                                                              "flex-start",
                                                            height: 30,
                                                          })
                                                          .text(
                                                            getTrad(
                                                              item.traduccion,
                                                            ) + ": ",
                                                          )
                                                          .append(
                                                            $(
                                                              "<span id='idEstado_" +
                                                                item.idEstado +
                                                                "' />",
                                                            ).text(0),
                                                          ),
                                                      ),
                                                    // Fin: colores y dneominación
                                                  );
                                                },
                                              );
                                              item.append(contenedor_flex);
                                            },
                                          },
                                        ],
                                      }),
                                    );
                                  },
                                },
                                {
                                  ratio: 9,
                                  template: function (e, index, item) {
                                    item.append(
                                      $("<div />").addClass("calendarLaboral"),
                                    );
                                  },
                                },
                              ],
                            }),
                          );
                        },
                      },
                      {
                        title: getTrad("festivosCierre"),
                        template: function (itemData, itemIndex, itemElement) {
                          itemElement.css("overflow", "auto");
                          itemElement.append(
                            $("<div />").addClass("calendarLavanderia"),
                          );
                        },
                      },
                    ],
                  })
                  .append(
                    $("<div id='dxPopup_updateDay' />").dxPopup({
                      width: 325,
                      height: 350,
                      title: getTrad("selTipoDiaLibre"),
                      deferRendering: false,
                      contentTemplate: function (contentElement) {
                        contentElement.append(
                          $("<div id='dxForm_updateDay'/>").dxForm({
                            formData: {},
                            labelLocation: "top",
                            height: "100%",
                            scrollingEnabled: true,
                            colCountByScreen: {
                              xl: 1,
                              lg: 1,
                              md: 1,
                              sm: 1,
                              xs: 1,
                            },
                            screenByWidth: function (width) {
                              return width >= 1200
                                ? "xl"
                                : width >= 992
                                  ? "lg"
                                  : width >= 768
                                    ? "md"
                                    : width >= 576
                                      ? "sm"
                                      : "xs";
                            },
                            items: [
                              {
                                dataField: "fechaSel",
                                label: { text: getTrad("fecha") },
                                template: function (data, itemElement) {
                                  var fechaSel =
                                    data.component.option("formData")[
                                      data.dataField
                                    ];

                                  itemElement.append(
                                    $("<span />").text(fechaSel),
                                  );
                                },
                              },
                              {
                                dataField: "idEstado",
                                label: { text: getTrad("estado") },
                                template: function (data, itemElement, x) {
                                  var formData =
                                      data.component.option("formData"),
                                    idEstado = formData.idEstado,
                                    is_readOnly = formData.is_readOnly;

                                  var is_mismoEstado =
                                    data.component.option("formData")[
                                      "is_mismoEstado"
                                    ];
                                  let is_mismoDia =
                                    diasSel.fechaIni == diasSel.fechaFin;

                                  let estadosTrabajado =
                                    !is_mismoEstado && idEstado
                                      ? $.grep(idEstado, function (item) {
                                          return item === 3 || item === 4;
                                        })
                                      : [];
                                  let estadosNoTrabajo =
                                    !is_mismoEstado && idEstado
                                      ? $.grep(idEstado, function (item) {
                                          return item !== 3 && item !== 4;
                                        })
                                      : [];

                                  let dxSelectBox_estado_value =
                                    !is_mismoEstado &&
                                    estadosNoTrabajo.length > 0 &&
                                    is_mismoDia
                                      ? estadosNoTrabajo[0]
                                      : idEstado;

                                  itemElement.append(
                                    $(
                                      "<div id='dxSelectBox_diasTrabajados' />",
                                    ).dxSelectBox({
                                      dataSource: $.grep(
                                        $.extend(true, [], estados_items)[0],
                                        function (estado) {
                                          if (
                                            estadosTrabajado.length > 0 &&
                                            estadosTrabajado[0] ==
                                              estado.idEstado
                                          ) {
                                            estado.visible = true;
                                            return (
                                              estado.idEstado == 3 ||
                                              estado.idEstado == 4
                                            );
                                          }
                                        },
                                      ),
                                      valueExpr: "idEstado",
                                      displayExpr: "traduccion",
                                      itemTemplate: function (itemData) {
                                        return getTrad(itemData.traduccion);
                                      },
                                      fieldTemplate: function (
                                        selectedItem,
                                        fieldElement,
                                      ) {
                                        return $("<div />").dxTextBox({
                                          readOnly: true,
                                          placeholder: getTrad("sinSeleccion"),
                                          value: selectedItem
                                            ? getTrad(selectedItem.traduccion)
                                            : null,
                                        });
                                      },
                                      title: getTrad("estado"),
                                      closeOnOutsideClick: true,
                                      showClearButton: true,
                                      placeholder: getTrad("sinSeleccion"),
                                      value:
                                        estadosTrabajado.length > 0
                                          ? estadosTrabajado[0]
                                          : null,
                                      visible: !is_mismoEstado && is_mismoDia,
                                    }),
                                    !is_mismoEstado && is_mismoDia
                                      ? $("<div />").addClass("pt-3")
                                      : null,
                                    $(
                                      "<div id='dxSelectBox_estado' />",
                                    ).dxSelectBox({
                                      dataSource: $.grep(
                                        estados_items[0],
                                        function (estado) {
                                          return !estado.isLavanderia;
                                        },
                                      ),
                                      valueExpr: "idEstado",
                                      displayExpr: "traduccion",
                                      itemTemplate: function (itemData) {
                                        return getTrad(itemData.traduccion);
                                      },
                                      fieldTemplate: function (
                                        selectedItem,
                                        fieldElement,
                                      ) {
                                        return $("<div />").dxTextBox({
                                          readOnly: true,
                                          placeholder:
                                            !is_mismoEstado && !is_mismoDia
                                              ? getTrad("variosEstados")
                                              : getTrad("sinSeleccion"),
                                          value: selectedItem
                                            ? getTrad(selectedItem.traduccion)
                                            : null,
                                        });
                                      },
                                      title: getTrad("estado"),
                                      closeOnOutsideClick: true,
                                      showClearButton: true,
                                      placeholder: getTrad("sinSeleccion"),
                                      value: dxSelectBox_estado_value,
                                      readOnly:
                                        is_readOnly && !Array.isArray(idEstado),
                                    }),
                                  );
                                },
                              },
                            ],
                          }),
                        );
                      },
                      toolbarItems: [
                        {
                          location: "after",
                          widget: "dxButton",
                          toolbar: "bottom",
                          options: {
                            text: getTrad("cancelar"),
                            type: "normal",
                            onClick: function (e) {
                              $("#dxPopup_updateDay")
                                .dxPopup("instance")
                                .hide();
                            },
                          },
                        },
                        {
                          location: "after",
                          widget: "dxButton",
                          toolbar: "bottom",
                          options: {
                            elementAttr: { id: "dxButton_guardarDias" },
                            text: getTrad("guardar"),
                            type: "success",
                            onClick: function (e) {
                              var idEstado = $("#dxSelectBox_estado")
                                .dxSelectBox("instance")
                                .option("value");

                              let eliminarJornada = false;
                              let dxForm_updateDay =
                                $("#dxForm_updateDay").dxForm("instance");
                              var is_mismoEstado =
                                dxForm_updateDay.option("formData")[
                                  "is_mismoEstado"
                                ];

                              if (!is_mismoEstado) {
                                var idEstadoJornada = $(
                                  "#dxSelectBox_diasTrabajados",
                                )
                                  .dxSelectBox("instance")
                                  .option("value");
                                if (idEstadoJornada == null) {
                                  //Eliminar jornada
                                  eliminarJornada = true;
                                  dxMensajePregunta(
                                    "Se eliminará el día trabajado, ¿desea continuar?",
                                    [
                                      // ACEPTAR
                                      [
                                        getTrad("aceptar"),
                                        function () {
                                          $("#dxButton_guardarDias")
                                            .dxButton("instance")
                                            .option("disabled", true);
                                          $.when(
                                            context_calendarioLaboral.invoke(
                                              "fn_iudCalendarioPersonal",
                                              {
                                                idEstado: idEstado,
                                                idPersona: personaSel.idPersona,
                                              },
                                              "POST",
                                            ),
                                            dataSource_tblJornadaPersona
                                              .store()
                                              .remove({}),
                                          ).then(function () {
                                            $("#dxButton_guardarDias")
                                              .dxButton("instance")
                                              .option("disabled", false);
                                            getDatosCalendarioLaboral();
                                            $("#dxPopup_updateDay")
                                              .dxPopup("instance")
                                              .hide();

                                            notify({
                                              message: getTrad(
                                                "aviso_C_RegistroInsertado",
                                              ),
                                              type: "success",
                                              displayTime: "1500",
                                              closeOnClick: true,
                                            });
                                          });
                                        },
                                        "normal",
                                        "btnAceptar",
                                      ],
                                      // CANCELAR
                                      [
                                        getTrad("cancelar"),
                                        function () {},
                                        "normal",
                                        "btnCancelar",
                                      ],
                                    ],
                                  );
                                }
                              }

                              if (eliminarJornada == false) {
                                $("#dxButton_guardarDias")
                                  .dxButton("instance")
                                  .option("disabled", true);
                                context_calendarioLaboral
                                  .invoke(
                                    "fn_iudCalendarioPersonal",
                                    {
                                      idEstado: idEstado,
                                      idPersona: personaSel.idPersona,
                                    },
                                    "POST",
                                  )
                                  .done(function (result) {
                                    $("#dxButton_guardarDias")
                                      .dxButton("instance")
                                      .option("disabled", false);
                                    getDatosCalendarioLaboral();
                                    $("#dxPopup_updateDay")
                                      .dxPopup("instance")
                                      .hide();

                                    notify({
                                      message: getTrad(
                                        "aviso_C_RegistroInsertado",
                                      ),
                                      type: "success",
                                      displayTime: "1500",
                                      closeOnClick: true,
                                    });
                                  });
                              }
                            },
                          },
                        },
                      ],
                      onShowing: function (e) {
                        var formData = $("#dxForm_updateDay")
                          .dxForm("instance")
                          .option("formData");
                        let idEstado = $("#dxForm_updateDay")
                          .dxForm("instance")
                          .option("formData").idEstado;

                        $("#dxButton_guardarDias")
                          .dxButton("instance")
                          .option(
                            "disabled",
                            formData.is_readOnly && !Array.isArray(idEstado),
                          );
                      },
                    }),
                    $("<div id='dxPopup_updateDay_lavanderia' />").dxPopup({
                      width: 275,
                      height: 375,
                      title: getTrad("indiqueDia"),
                      deferRendering: false,
                      contentTemplate: function (contentElement) {
                        contentElement.append(
                          $("<div id='dxForm_updateDay_lavanderia'/>").dxForm({
                            formData: {},
                            width: "100%",
                            height: "100%",
                            labelLocation: "top",
                            scrollingEnabled: true,
                            colCountByScreen: {
                              xl: 1,
                              lg: 1,
                              md: 1,
                              sm: 1,
                              xs: 1,
                            },
                            screenByWidth: function (width) {
                              return width >= 1200
                                ? "xl"
                                : width >= 992
                                  ? "lg"
                                  : width >= 768
                                    ? "md"
                                    : width >= 576
                                      ? "sm"
                                      : "xs";
                            },
                            items: [
                              {
                                dataField: "fechaSel",
                                colSpan: 1,
                                label: { text: getTrad("fecha") },
                                template: function (data, itemElement) {
                                  var fechaSel =
                                    data.component.option("formData")[
                                      data.dataField
                                    ];
                                  itemElement.append(
                                    $("<span />").text(fechaSel),
                                  );
                                },
                              },
                              {
                                dataField: "idEstado_festivo",
                                colSpan: 1,
                                label: { text: getTrad("festivo") },
                                template: function (data, itemElement) {
                                  var formData =
                                      data.component.option("formData"),
                                    idEstado_festivo =
                                      formData.idEstado_festivo;

                                  itemElement.append(
                                    $("<div />").dxSwitch({
                                      switchedOnText: getTrad("noFestivo"),
                                      switchedOffText: getTrad("festivo"),
                                      width: "230px",
                                      value: idEstado_festivo
                                        ? idEstado_festivo.value
                                        : 1,
                                      onContentReady: function (e) {
                                        if (
                                          idEstado_festivo &&
                                          !idEstado_festivo.isMismoEstado
                                        ) {
                                          tooltipControl_creacion(
                                            e.element,
                                            getTrad(
                                              "alert_periodoVariosEstados",
                                            ),
                                            false,
                                          );
                                        }
                                      },
                                      onValueChanged: function (e) {
                                        formData.idEstado_festivo.value =
                                          e.value;
                                      },
                                    }),
                                  );
                                },
                              },
                              {
                                dataField: "idEstado_diaCierre",
                                colSpan: 1,
                                label: { text: getTrad("diaCierre") },
                                template: function (data, itemElement) {
                                  var formData =
                                      data.component.option("formData"),
                                    idEstado_diaCierre =
                                      formData.idEstado_diaCierre;

                                  itemElement.append(
                                    $("<div />").dxSwitch({
                                      switchedOnText: getTrad("abierto"),
                                      switchedOffText: getTrad("cerrado"),
                                      width: "230px",
                                      value: idEstado_diaCierre
                                        ? idEstado_diaCierre.value
                                        : 1,
                                      onContentReady: function (e) {
                                        if (
                                          idEstado_diaCierre &&
                                          !idEstado_diaCierre.isMismoEstado
                                        ) {
                                          tooltipControl_creacion(
                                            e.element,
                                            getTrad(
                                              "alert_periodoVariosEstados",
                                            ),
                                            false,
                                          );
                                        }
                                      },
                                      onValueChanged: function (e) {
                                        formData.idEstado_diaCierre.value =
                                          e.value;
                                      },
                                    }),
                                  );
                                },
                              },
                            ],
                          }),
                        );
                      },
                      toolbarItems: [
                        {
                          location: "after",
                          widget: "dxButton",
                          toolbar: "bottom",
                          options: {
                            text: getTrad("cancelar"),
                            type: "normal",
                            onClick: function (e) {
                              $("#dxPopup_updateDay_lavanderia")
                                .dxPopup("instance")
                                .hide();
                            },
                          },
                        },
                        {
                          location: "after",
                          widget: "dxButton",
                          toolbar: "bottom",
                          options: {
                            text: getTrad("guardar"),
                            type: "success",
                            onClick: function (e) {
                              var formData = $("#dxForm_updateDay_lavanderia")
                                .dxForm("instance")
                                .option("formData");
                              calendarioLavSel.isFestivo =
                                !formData.idEstado_festivo.value;
                              calendarioLavSel.isDiaCierre =
                                !formData.idEstado_diaCierre.value;

                              context_tblCalendarioLavanderia
                                .invoke(
                                  "fn_iudCalendarioLavanderia",
                                  {},
                                  "POST",
                                )
                                .done(function () {
                                  getDatosCalendarioLavanderia();
                                  getDatosCalendarioLaboral();
                                  $("#dxPopup_updateDay_lavanderia")
                                    .dxPopup("instance")
                                    .hide();

                                  notify({
                                    message: getTrad(
                                      "aviso_C_RegistroInsertado",
                                    ),
                                    type: "success",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                });
                            },
                          },
                        },
                      ],
                    }),
                  ),
              );
            },
          },
        ],
      });

      //Para preseleccionar un registro de persona al iniciar pantalla
      var dxList_seleccionPersona = $("#dxList_seleccionPersona").dxList(
        "instance",
      );

      if (dxList_seleccionPersona) {
        var persona_items = dxList_seleccionPersona.option("items");
        dxList_seleccionPersona.selectItem(persona_items[0]);
      }

      //#region Calendario laboral
      var datasource_calendarLaboral = [];

      calendarLaboral = new Calendar(".calendarLaboral", {
        style: "background",
        language: IDIOMA.codigo,
        enableRangeSelection: true,
        weekStart: 1,
        roundRangeLimits: false,
        customDayRenderer: function (element, date) {
          var objDia = $.grep(datasource_calendarLaboral, function (dia) {
            return (
              dia.startDate.getTime() === date.getTime() && dia.idEstado !== 7
            ); // estado 7 es amarillo, quiero que el texto sea negro para distinguirlo.
          });

          if (objDia.length > 1) {
            //Más de 1 evento por día
            $(element).parent().addClass("backgroundWhite");
            $(element)
              .addClass("position-relative")
              .append(
                svg_warning
                  .clone()
                  .css("height", "22px")
                  .css("width", "22px")
                  .css("cursor", "pointer")
                  .css("position", "absolute")
                  .css("left", "6px")
                  .css("top", "1px"),
              )
              .append(
                $("<div />")
                  .addClass("position-absolute")
                  .dxTooltip({
                    showEvent: "dxhoverstart",
                    hideEvent: "dxhoverend  dxclick",
                    position: "top",
                    contentTemplate: function (contentElement) {
                      let estados = "";
                      $.each(objDia, function (index, item) {
                        let denoEstado = $.grep(
                          estados_items[0],
                          function (item_) {
                            return item.idEstado === item_.idEstado;
                          },
                        );
                        estados +=
                          "<li class='text-left'> " +
                          getTrad(denoEstado[0].traduccion) +
                          "</li>";
                      });

                      contentElement.html(
                        $("<div />")
                          .css("font-size", "13px")
                          .append(
                            $("<span />")
                              .css("font-weight", "600")
                              .text(getTrad("variosEstadosDia")),
                          )
                          .append(
                            $("<ul />")
                              .addClass("he-100 m-0 pl-5 pt-1")
                              .append(estados),
                          ),
                      );
                    },
                    target: $(element).parent(),
                    animation: {
                      show: {
                        type: "pop",
                        from: {
                          scale: 0.1,
                          opacity: 0,
                        },
                        to: {
                          opacity: 1,
                          scale: 1,
                        },
                      },
                      hide: {
                        type: "pop",
                        from: {
                          scale: 1,
                          opacity: 1,
                        },
                        to: {
                          opacity: 0,
                          scale: 0.1,
                        },
                      },
                    },
                  }),
              );
          }

          if (objDia.length > 0) $(element).css("color", "#FFFFFF");

          if (personaSel) {
            let notDisabled =
              $.grep(personaSel.tblPersonaNTipoContrato, function (item) {
                return (
                  new Date(date.setHours(0)) >=
                    new Date(new Date(item.fechaAltaContrato).setHours(0)) &&
                  ((item.fechaBajaContrato != null &&
                    new Date(date.setHours(0)) <=
                      new Date(new Date(item.fechaBajaContrato).setHours(0))) ||
                    item.fechaBajaContrato == null)
                );
              }).length > 0;

            if (!notDisabled) {
              $(element).parent().addClass("disabled");
            }
          } else {
            $(element).parent().addClass("disabled");
          }
        },
        selectRange: function (range) {
          var fechaIni = formatDate(range.startDate),
            fechaFin = formatDate(range.endDate),
            is_readOnly = false,
            is_mismoEstado = true,
            idEstado = null;

          diasSel = {
            fechaIni: range.startDate,
            fechaFin: range.endDate,
          };

          var tiempoPorDia = 24 * 60 * 60 * 1000, // hours*minutes*seconds*milliseconds
            countDias =
              Math.round(
                Math.abs(
                  (new Date(range.startDate) - new Date(range.endDate)) /
                    tiempoPorDia,
                ),
              ) + 1; // se suma 1 porque quiero los días totales, no la diferencia

          let is_mismoDia = diasSel.fechaIni == diasSel.fechaFin;
          // Modificar fecha con datos previos
          if (range.events.length > 0) {
            var diasSel_conDatos = range.events,
              estadoInicial = range.events[0].idEstado;

            var arrayEstados = [];
            $.each(diasSel_conDatos, function (index, item) {
              arrayEstados.push(item.idEstado);
              if (item.idEstado !== estadoInicial) {
                is_mismoEstado = false;
              }
              if (item.idEstado === 3 || item.idEstado === 4)
                is_readOnly = true;
            });

            if (
              diasSel_conDatos.length !== countDias &&
              !arrayEstados.includes(8)
            )
              // Si no incluye festivos
              is_mismoEstado = false;

            idEstado = is_mismoEstado
              ? diasSel_conDatos[0].idEstado
              : is_mismoDia
                ? $.map(diasSel_conDatos, function (item) {
                    return item.idEstado;
                  })
                : null; //is_mismoEstado ? diasSel_conDatos[0].idEstado : null;
          }

          var dxForm_updateDay = $("#dxForm_updateDay").dxForm("instance");
          dxForm_updateDay.updateData({
            fechaSel:
              range.startDate.getTime() !== range.endDate.getTime()
                ? fechaIni + " - " + fechaFin
                : fechaIni,
            idEstado: idEstado !== 8 ? idEstado : null, // Solo nulea los festivos en calendario laboral.
            is_readOnly: is_readOnly,
            is_mismoEstado: is_mismoEstado,
          });
          dxForm_updateDay.repaint();
          $("#dxPopup_updateDay").dxPopup("instance").show();
        },
        yearChanged: function (e) {
          getDatosCalendarioLaboral();
        },
        renderEnd: function (e) {
          set_calendarSize();
        },
      });

      function set_calendarSize() {
        let size = $("#CalendarioLaboral").height() - 45 - 20; //45 - tab height, 20 - padding contenedor general

        $(".calendar").height(size);
        $(".calendarLavanderia").height(size);
      }

      $(window).resize(function () {
        set_calendarSize();
      });

      function getDatosCalendarioLaboral() {
        var fechaSel = calendarLaboral
          ? calendarLaboral.options.startYear
          : new Date().getFullYear();
        context_calendarioLaboral
          .invoke(
            "fn_spSelectCalendarioLaboral",
            {
              idPersona: personaSel ? personaSel.idPersona : null,
              año: fechaSel,
            },
            "GET",
          )
          .done(function (result) {
            // Reiniciar días
            $.each(estados_items[0], function (index, item) {
              $("#idEstado_" + item.idEstado).text(0);
            });

            //Asignar días
            $.each(result, function (index, item) {
              var dias_idEstado = $("#idEstado_" + item.idEstado).text();
              $("#idEstado_" + item.idEstado).text(parseInt(dias_idEstado) + 1);
            });

            datasource_calendarLaboral = $.map(result, function (item, index) {
              return {
                idEstado: item.idEstado,
                startDate: item.fecha,
                endDate: item.fecha,
                color: item.colorHexa,
              };
            });

            calendarLaboral.setDataSource(datasource_calendarLaboral);
          });
      }

      //#endregion

      //#region Calendario lavanderia
      var datasource_calendarLavanderia = [];
      calendarLavanderia = new Calendar(".calendarLavanderia", {
        style: "background",
        language: IDIOMA.codigo,
        enableRangeSelection: true,
        weekStart: 1,
        roundRangeLimits: false,
        customDayRenderer: function (element, date) {
          var objDia = $.grep(datasource_calendarLavanderia, function (dia) {
            return dia.startDate.getTime() === date.getTime();
          });

          $.each(objDia, function (index, item) {
            if (item.idEstado !== 10) $(element).css("color", "#FFFFFF");

            if (item.idEstado === 10)
              //día de cierre
              $(element).addClass("diaCierre");
          });
        },
        selectRange: function (range) {
          var fechaIni = formatDate(range.startDate),
            fechaFin = formatDate(range.endDate),
            idEstado_festivo = {
              value: true,
              estadoInicial: true,
              isMismoEstado: true,
            },
            idEstado_diaCierre = {
              value: true,
              estadoInicial: true,
              isMismoEstado: true,
            };

          diasSel = {
            fechaIni: range.startDate,
            fechaFin: range.endDate,
          };

          var tiempoPorDia = 24 * 60 * 60 * 1000, // hours*minutes*seconds*milliseconds
            countDias =
              Math.round(
                Math.abs(
                  (new Date(range.startDate) - new Date(range.endDate)) /
                    tiempoPorDia,
                ),
              ) + 1; // se suma 1 porque quiero los días totales, no la diferencia

          // Modificar fecha con datos previos
          if (range.events.length > 0) {
            //#region Agrupar los estados por días y formatear los estados para que sean true o false.
            var agrupacionDia = query(range.events)
              .groupBy(function (dataItem) {
                return [dataItem.startDate];
              })
              .select(function (dataItem) {
                var estados = [];
                $.each(dataItem.items, function (index, item) {
                  estados.push(item.idEstado);
                });

                return {
                  fecha: dataItem.key[0],
                  idEstado_festivo: $.inArray(8, estados) === -1 ? true : false,
                  idEstado_diaCierre:
                    $.inArray(10, estados) === -1 ? true : false,
                };
              })
              .toArray();
            //#endregion

            //#region Comprobación si los estados son iguales en todos los días o varían
            idEstado_festivo.estadoInicial = agrupacionDia[0].idEstado_festivo;
            idEstado_diaCierre.estadoInicial =
              agrupacionDia[0].idEstado_diaCierre;

            $.each(agrupacionDia, function (index, item) {
              if (item.idEstado_diaCierre !== idEstado_diaCierre.estadoInicial)
                idEstado_diaCierre.isMismoEstado = false;

              if (item.idEstado_festivo !== idEstado_festivo.estadoInicial)
                idEstado_festivo.isMismoEstado = false;
            });

            //Si hay días sin estado, nunca tendrá el mismo estado
            if (agrupacionDia.length !== countDias) {
              idEstado_festivo.isMismoEstado = false;
              idEstado_diaCierre.isMismoEstado = false;
            }
            //#endregion
            idEstado_festivo.value = idEstado_festivo.isMismoEstado
              ? agrupacionDia[0].idEstado_festivo
              : true;
            idEstado_diaCierre.value = idEstado_diaCierre.isMismoEstado
              ? agrupacionDia[0].idEstado_diaCierre
              : true;
          }

          var dxForm_updateDay_lavanderia = $(
            "#dxForm_updateDay_lavanderia",
          ).dxForm("instance");
          dxForm_updateDay_lavanderia.updateData({
            fechaSel:
              range.startDate.getTime() !== range.endDate.getTime()
                ? fechaIni + " - " + fechaFin
                : fechaIni,
            idEstado_festivo: idEstado_festivo,
            idEstado_diaCierre: idEstado_diaCierre,
          });

          dxForm_updateDay_lavanderia.repaint();
          $("#dxPopup_updateDay_lavanderia").dxPopup("instance").show();
        },
        yearChanged: function (e) {
          getDatosCalendarioLavanderia();
        },
        renderEnd: function (e) {
          set_calendarSize();
        },
      });

      function getDatosCalendarioLavanderia() {
        datasource_tblCalendarioLavanderia.load().done(function (result) {
          // Reiniciar días
          $.each(estados_items[0], function (index, item) {
            $("#idEstado_" + item.idEstado).text(0);
          });

          //Asignar días
          $.each(result, function (index, item) {
            var dias_idEstado = $("#idEstado_" + item.idEstado).text();
            $("#idEstado_" + item.idEstado).text(parseInt(dias_idEstado) + 1);
          });

          var resultado = query(result)
            .sortBy("tblCalendarioPersonal_Estado.colorHexa")
            .toArray(); // Se ordena para que los colores null aparezcan primero, así se sobreescribirán con el siguiente color.
          datasource_calendarLavanderia = $.map(
            resultado,
            function (item, index) {
              return {
                idEstado: item.idEstado,
                startDate: item.fecha,
                endDate: item.fecha,
                color: item.tblCalendarioPersonal_Estado.colorHexa
                  ? item.tblCalendarioPersonal_Estado.colorHexa
                  : "transparent",
              };
            },
          );
          calendarLavanderia.setDataSource(datasource_calendarLavanderia);
        });
      }
      //#endregion
    })
    .always(function () {
      deferred.resolve();
    });

  return deferred.promise();
}
