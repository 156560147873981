import { connectionConstants } from "../constants";
import { authHeader } from "../helpers";

import { HubConnectionBuilder } from "@microsoft/signalr";

export const signalRCoreService = {
    joinGroup,
    leaveGroup,
    leaveAllGroups,
    setEvents,
    clearEvents,
};

let hubConnection = undefined;
let hubStartPromise = undefined;

let groups = new Map();

async function getHubConnection() {
    return new Promise(async function (resolve) {
        if (hubConnection === undefined) {
            hubConnection = new HubConnectionBuilder()
                .withUrl(connectionConstants.WEB_API_CORE_URL + "hub/notificaciones", {
                    accessTokenFactory: () => {
                        return { ...authHeader() }.Authorization;
                    },
                })
                .withAutomaticReconnect()
                .build();

            hubStartPromise = hubConnection.start();

            hubConnection.onreconnected(() => {
                groups.forEach((events, group) => {
                    if (group !== "global") {
                        hubConnection.invoke("JoinGroup", group);
                    }
                });
            });
        }

        await hubStartPromise;

        resolve(hubConnection);
    });
}

function signalRUpdateNotifier(event) {
    if (process.env.NODE_ENV === "development") {
        console.group("SignalR UPDATED");
        if (event) console.log("Event: ", event);
        console.log("Groups: ", groups);
        console.groupEnd();
    }
}

async function joinGroup(group, events) {
    return new Promise(async function (resolve) {
        let hubConnection = await getHubConnection();

        hubConnection.invoke("JoinGroup", group).then(() => {
            groups.set(group, events);

            if (events) {
                events.forEach((event) => {
                    hubConnection.on(event.type, event.listener);
                });
            }

            signalRUpdateNotifier("Group joined: " + group);
            resolve();
        });
    });
}

async function leaveGroup(group) {
    return new Promise(async function (resolve) {
        if (hubConnection != undefined) {
            if (group !== "global") {
                hubConnection.invoke("RemoveGroup", group);
            }

            let events = groups.get(group);
            if (events) {
                events.forEach((event) => {
                    hubConnection.off(event.type);
                });
            }

            groups.delete(group);
        }

        signalRUpdateNotifier("Group left: " + group);
        resolve();
    });
}

async function leaveAllGroups() {
    return new Promise(async function (resolve) {
        if (hubConnection != undefined) {
            groups.forEach((events, group) => {
                leaveGroup(group);
            });
        }

        resolve();
    });
}

async function setEvents(events, group = "global") {
    return new Promise(async function (resolve) {
        let hubConnection = await getHubConnection();

        groups.set(group, events);
        events.forEach((event) => {
            hubConnection.on(event.type, event.listener);
        });

        signalRUpdateNotifier("Events set: " + group);
        resolve();
    });
}

async function clearEvents(group = "global") {
    return new Promise(async function (resolve) {
        if (hubConnection != undefined) {
            if (groups.has(group)) {
                let events = groups.get(group);
                if (events) {
                    events.forEach((event) => {
                        hubConnection.off(event.type);
                    });
                }
            }
        }

        signalRUpdateNotifier("Events cleared: " + group);
        resolve();
    });
}
