import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  dxMensajePregunta,
  formatDate_parameter,
} from "../../../helpers";
import { svg_edit, svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var pedidoHuespedSel = null,
    prendaHuespedSel = null,
    idNegativoPrendaNPedido = -1,
    prendaNPedidoHuesped = [];

  //#region Datasource

  var datasource_pedidosHuesped = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPedidosHuesped",
      key: "idPedidoHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "spSelectPedidosHuesped",
            "tblPedidoHuesped",
          );
        }
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_prendaNPedidoHuesped = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectPrendasNPedidosHuesped",
      key: "idPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        var idPedidoHuesped = -1;
        if (typeof $("#dxForm_Llamada")[0] !== "undefined") {
          idPedidoHuesped = pedidoHuespedSel
            ? pedidoHuespedSel.idPedidoHuesped
            : -1;
        }
        request.params.idPedidoHuesped = idPedidoHuesped;
      },
      version: 4,
    }),
  });

  var datasource_tipoServicio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoServicio",
      key: "idTipoServicio",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_tipoLavado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoLavado",
      key: "idTipoLavado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoaded: function (data) {
        if (datasource_tipoLavado.items().length === 0) {
          data.splice(0, 0, { idTipoLavado: 0, denominacion: "Normal" });
        }
        return data;
      },
      version: 4,
    }),
    select: ["idTipoLavado", "denominacion"],
  });

  var datasource_tipoPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoPrendaHuesped",
      key: "idTipoPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_compañias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_compañias.items().length === 0) {
        data.splice(0, 0, {
          idCompañia: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_entidades = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "idCompañia", "denominacion"],
    postProcess: function (data) {
      if (datasource_entidades.items().length === 0) {
        data.splice(0, 0, {
          idEntidad: 0,
          denominacion: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
  });

  var datasource_almacenes = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectAlmacen",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.url = request.url.replace(
          "spSelectAlmacen('')",
          "spSelectAlmacen",
        );
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.idTipoAlmacen = 4; //Habitación (no identity)
      },
      version: 4,
    }),
    select: ["idAlmacen", "denominacion", "idCompañia", "idEntidad"],
  });

  var datasource_persona = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idPersona", "nombre", "apellidos"],
  });

  var datasource_prendaHuesped = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaHuesped",
      key: "idPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_colorPrendaHuesped = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblColorPrendaHuesped",
      key: "idColorPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_defectoPrendaHuesped = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblDefectoPrendaHuesped",
      key: "idDefectoPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoaded: function (data) {
        if (datasource_defectoPrendaHuesped.items().length === 0) {
          data.splice(0, 0, {
            idDefectoPrendaHuesped: 0,
            denominacion: "Sin defectos",
          });
        }
        return data;
      },
      version: 4,
    }),
    select: ["idDefectoPrendaHuesped", "denominacion"],
  });

  //#endregion

  $("#PedidosHuesped #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          item.append(
            $("<div id='tabpanel-container' />")
              .addClass("no-header")
              .dxTabPanel({
                height: "100%",
                width: "100%",
                scrollByContent: true,
                scrollingEnabled: true,
                animationEnabled: true,
                deferRendering: false,
                // EVENTOS
                onSelectionChanged: function (e) {
                  $("#font-size").text(e.addedItems[0].title);
                },
                items: [
                  {
                    title: getTrad("pedidosHuesped"),
                    template: function () {
                      return $("<div id='dgvPedidosHuesped' />").dxDataGrid({
                        //Datos
                        dataSource: datasource_pedidosHuesped,
                        remoteOperations: true,
                        //Propiedades
                        height: "100%",
                        width: "100%",
                        editing: {
                          allowUpdating: false,
                          allowAdding: true,
                        },
                        headerFilter: {
                          visible: true,
                        },
                        filterRow: {
                          visible: true,
                          applyFilter: "auto",
                        },
                        searchPanel: {
                          visible: true,
                          width: 240,
                          placeholder: getTrad("busqueda"),
                        },
                        paging: {
                          pageSize: 50,
                        },
                        pager: {
                          showPageSizeSelector: true,
                          allowedPageSizes: [20, 50, 100],
                          showInfo: true,
                        },
                        columns: [
                          {
                            type: "buttons",
                            visible: false,
                          },
                          {
                            caption: " ",
                            width: 30,
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              var contenedor = $("<div/>").addClass(
                                "container_spanCentrado",
                              );
                              svg_edit
                                .clone()
                                .css("height", "25px")
                                .css("width", "25px")
                                .css("cursor", "pointer")
                                .appendTo(contenedor);

                              cellElement.on("click", function () {
                                var formData = $.extend(
                                  true,
                                  {},
                                  cellInfo.data,
                                );
                                var dxDropDownBox_selCompaEnti = $(
                                  "#dxDropDownBox_selCompaEnti",
                                ).dxDropDownBox("instance");
                                pedidoHuespedSel = formData;
                                if (pedidoHuespedSel.estadoPedido !== 3) {
                                  // SI EL ESTADO DEL PEDIDO NO ES ENTREGADO
                                  datasource_prendaNPedidoHuesped
                                    .load()
                                    .done(function (prendas) {
                                      prendaNPedidoHuesped = prendas;
                                      idNegativoPrendaNPedido = -1;
                                      $("#popup_prendasHuesped")
                                        .dxPopup("instance")
                                        .hide();

                                      $("#dxDataGrid_PedidoClienteSel_Repartos")
                                        .dxDataGrid("instance")
                                        .option(
                                          "dataSource",
                                          prendaNPedidoHuesped,
                                        );

                                      $("#dxForm_Llamada")
                                        .dxForm("instance")
                                        .updateData(
                                          $.extend(true, {}, pedidoHuespedSel),
                                        );
                                      $("#tabpanel-container")
                                        .dxTabPanel("instance")
                                        .option("selectedIndex", 1);

                                      dxDropDownBox_selCompaEnti.option(
                                        "value",
                                        pedidoHuespedSel.idAlmacen,
                                      );
                                      $("#dxForm_dxToolbar")
                                        .dxTabPanel("instance")
                                        .option("selectedIndex", 1);

                                      if (!cellInfo.data.fechaRecepcion) {
                                        $("#font-size").text(
                                          getTrad("recepcion"),
                                        );

                                        // RESETEAR VALORES Y VALIDACIÓN A LOS POR DEFECTO DEL PEDIDO SEL.
                                        var form =
                                          $("#dxForm_Llamada").dxForm(
                                            "instance",
                                          );
                                        $.each(
                                          form.option().items[0].tabs[1]
                                            .items[0].items,
                                          function (index, item) {
                                            if (
                                              typeof form.getEditor(
                                                item.dataField,
                                              ) !== "undefined"
                                            ) {
                                              form
                                                .getEditor(item.dataField)
                                                .option(
                                                  "value",
                                                  pedidoHuespedSel[
                                                    item.dataField
                                                  ],
                                                );
                                              form
                                                .getEditor(item.dataField)
                                                .option("isValid", true);
                                            }
                                          },
                                        );
                                        var grid = $(
                                          "#dxDataGrid_PedidoClienteSel_Repartos",
                                        );
                                        grid.dxDataGrid(
                                          "columnOption",
                                          "modificarGridPrendas",
                                          "visible",
                                          true,
                                        );
                                        grid.dxDataGrid(
                                          "columnOption",
                                          "eliminarGridPrendas",
                                          "visible",
                                          true,
                                        );
                                        grid.dxDataGrid(
                                          "columnOption",
                                          "procesado",
                                          "visible",
                                          false,
                                        );

                                        $("#btnVolverListado")
                                          .dxButton("instance")
                                          .option("visible", true);
                                        $("#btnGuardarForm")
                                          .dxButton("instance")
                                          .option("text", getTrad("guardar"));
                                        $("#btnModificarRecepcion")
                                          .dxButton("instance")
                                          .option("visible", false);
                                        $("#btnAñadirPrenda")
                                          .dxButton("instance")
                                          .option("visible", true);
                                      }
                                    });
                                } else {
                                  notify({
                                    message: getTrad(
                                      "alert_selPedidoEntregado",
                                    ),
                                    type: "error",
                                    displayTime: "1500",
                                    closeOnClick: true,
                                  });
                                }
                              });

                              contenedor.appendTo(cellElement);
                            },
                          },
                          {
                            dataField: "fechaLlamada",
                            caption: getTrad("fechaLlamada"),
                            dataType: "date",
                            format: "dd/MM/yyyy",
                            width: 180,
                            alignment: "center",
                            sortIndex: 1,
                            sortOrder: "desc",
                          },
                          {
                            dataField: "fechaRecepcion",
                            caption: getTrad("fechaRecepcion"),
                            dataType: "date",
                            format: "dd/MM/yyyy",
                            width: 180,
                            alignment: "center",
                          },
                          {
                            dataField: "denoEntidad",
                            minWidth: 200,
                            allowHeaderFiltering: true,
                            allowFiltering: false,
                            caption: getTrad("entidad"),
                          },
                          {
                            dataField: "idAlmacen",
                            width: 150,
                            allowHeaderFiltering: true,
                            allowFiltering: false,
                            caption: getTrad("almacen"),
                            lookup: {
                              dataSource: datasource_almacenes.store(),
                              valueExpr: "idAlmacen",
                              displayExpr: "denominacion",
                            },
                          },
                          {
                            dataField: "personaSolicita",
                            caption: getTrad("solicitante"),
                            width: 100,
                            hidingPriority: 0,
                          },
                          {
                            dataField: "estadoPedido",
                            width: 120,
                            caption: getTrad("estado"),
                            cssClass: "p-0",
                            alignment: "center",
                            allowHeaderFiltering: false,
                            allowFiltering: false,
                            cellTemplate: function (container, options) {
                              var content = $("<div />")
                                .css("display", "flex")
                                .css("justify-content", "space-around");
                              var containerId =
                                options.data.idPedidoHuesped +
                                "_" +
                                options.column.dataField;

                              var imagenes = [
                                {
                                  imgId: containerId + "_img_Llamada",
                                  img: "icon_Telefono",
                                },
                                {
                                  imgId: containerId + "_img_Recepcion",
                                  img: "icon_Cuaderno",
                                },
                                {
                                  imgId: containerId + "_img_tickAmbar",
                                  img: "icon_Circle_check",
                                },
                              ];

                              var tooltips = $("<div id='tooltips' />");

                              $.each(imagenes, function (index, item) {
                                var destacar = false;

                                if (
                                  (options.data.estadoPedido === 1 &&
                                    index === 0) ||
                                  (options.data.estadoPedido === 2 &&
                                    index === 1) ||
                                  (options.data.estadoPedido === 3 &&
                                    index === 2)
                                ) {
                                  destacar = true;
                                }

                                $("<div id=" + item.imgId + " />")
                                  .attr("class", item.img)
                                  .attr(
                                    "style",
                                    "color: " +
                                      (destacar
                                        ? "var(--primary)"
                                        : "var(--secondary)") +
                                      " !important; font-size:25px;",
                                  )
                                  .appendTo(content);

                                $(
                                  "<div id=" +
                                    containerId +
                                    "_" +
                                    item.imgId +
                                    " />",
                                )
                                  .dxTooltip({
                                    target: "#" + item.imgId,
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    contentTemplate: function (contentElement) {
                                      switch (index) {
                                        case 0:
                                          contentElement.html(
                                            "<p>" +
                                              getTrad("llamadaPendiente") +
                                              "</p>",
                                          );
                                          break;
                                        case 1:
                                          contentElement.html(
                                            "<p>" +
                                              getTrad("pedidoRecogido") +
                                              "</p>",
                                          );
                                          break;
                                        case 2:
                                          contentElement.html(
                                            "<p>" +
                                              getTrad("pedidoEntregado") +
                                              "</p>",
                                          );
                                          break;
                                        default:
                                          break;
                                      }
                                    },
                                  })
                                  .appendTo(tooltips);
                              });
                              tooltips.appendTo(content);

                              return content;
                            },
                            sortIndex: 0,
                            sortOrder: "asc",
                          },
                          {
                            dataField: "idTipoServicio",
                            allowHeaderFiltering: true,
                            allowFiltering: false,
                            width: 125,
                            caption: getTrad("tipoServicio"),
                            lookup: {
                              dataSource: datasource_tipoServicio.store(),
                              valueExpr: "idTipoServicio",
                              displayExpr: "denominacion",
                            },
                          },
                          {
                            caption: getTrad("entrega"),
                            width: 88,
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              $("<div />")
                                .attr("class", "icon_Carro")
                                .attr(
                                  "style",
                                  (options.data.estadoPedido === 2 ||
                                    options.data.estadoPedido === 3) &&
                                    options.data.porcentaje > 0
                                    ? "cursor: pointer;"
                                    : "cursor: default;opacity: 0.33;filter: alpha(opacity=33);",
                                )
                                .css("font-size", "25px")
                                .on("click", function () {
                                  if (
                                    (options.data.estadoPedido === 2 ||
                                      options.data.estadoPedido === 3) &&
                                    options.data.porcentaje > 0
                                  ) {
                                    var formData = $.extend(
                                      true,
                                      {},
                                      options.data,
                                    );
                                    pedidoHuespedSel = formData;
                                    $("#popup_datosEntrega")
                                      .dxPopup("instance")
                                      .show();
                                  }
                                })
                                .appendTo(container);
                            },
                          },
                          {
                            dataField: "porcentaje",
                            caption: " ",
                            alignment: "center",
                            width: 160,
                            allowHeaderFiltering: false,
                            allowFiltering: false,
                            cssClass: "p-0",
                            cellTemplate: function (container, options) {
                              $("<div />")
                                .attr(
                                  "style",
                                  "padding-left: 10px;margin-right:40px;",
                                )
                                .dxProgressBar({
                                  min: 0,
                                  max: 100,
                                  value: options.data.porcentaje,
                                  showStatus: true,
                                  statusFormat: function (ratio, value) {
                                    return Math.floor(ratio * 100) + "%";
                                  },
                                  onContentReady: function (e) {
                                    if (options.data.estadoPedido === 3) {
                                      e.element
                                        .find(".dx-progressbar-range")
                                        .css({
                                          "background-color": "#5cb85c",
                                          border: "1px solid #5cb85c",
                                        });
                                    }
                                  },
                                })
                                .appendTo(container);
                            },
                            hidingPriority: 1,
                          },
                          {
                            caption: " ",
                            width: 60,
                            alignment: "center",
                            cssClass: "p-0",
                            cellTemplate: function (cellElement, cellInfo) {
                              var contenedor = $("<div/>").addClass(
                                "container_spanCentrado",
                              );
                              svg_remove
                                .clone()

                                .css("height", "25px")
                                .css("width", "25px")
                                .css("cursor", "pointer")
                                .appendTo(contenedor);

                              cellElement.on("click", function () {
                                cellInfo.component.deleteRow(cellInfo.rowIndex);
                              });

                              contenedor.appendTo(cellElement);
                            },
                          },
                        ],
                        //Eventos
                        onContentReady: function (e) {
                          if (deferred.state() === "pending") {
                            deferred.resolve();
                            setTimeout(() => {
                              e.component.repaint();
                            }, 0);
                          }
                        },
                        onToolbarPreparing: function (e) {
                          e.toolbarOptions.items.unshift(
                            {
                              location: "after",
                              widget: "dxButton",
                              locateInMenu: "auto",
                              showText: "inMenu",
                              template: function (item) {
                                var container = $("<div></div>");

                                $("<div id='btnLimpiarFiltro'>")
                                  .dxButton({
                                    icon: " icon_EliminarFiltros",
                                    onClick: function (options) {
                                      e.component.clearFilter();
                                    },
                                  })
                                  .appendTo(container);
                                $("<div>")
                                  .dxTooltip({
                                    target: "#btnLimpiarFiltro",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("limpiarFiltro"),
                                      );
                                    },
                                    animation: {
                                      show: {
                                        type: "pop",
                                        from: {
                                          scale: 0.1,
                                          opacity: 0,
                                        },
                                        to: {
                                          opacity: 1,
                                          scale: 1,
                                        },
                                      },
                                      hide: {
                                        type: "pop",
                                        from: {
                                          scale: 1,
                                          opacity: 1,
                                        },
                                        to: {
                                          opacity: 0,
                                          scale: 0.1,
                                        },
                                      },
                                    },
                                  })
                                  .appendTo(container);

                                return container;
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              showText: "inMenu",
                              locateInMenu: "auto",
                              template: function (item) {
                                var container = $("<div></div>");

                                $("<div id='btnActualizar'>")
                                  .dxButton({
                                    icon: "refresh",
                                    text: getTrad("actualizar"),
                                    onClick: function (options) {
                                      e.component.refresh();
                                    },
                                  })
                                  .appendTo(container);
                                $("<div>")
                                  .dxTooltip({
                                    target: "#btnActualizar",
                                    height: "42px",
                                    showEvent: "dxhoverstart",
                                    hideEvent: "dxhoverend",
                                    position: "bottom",
                                    contentTemplate: function (contentElement) {
                                      contentElement.html(
                                        getTrad("actualizar"),
                                      );
                                    },
                                    animation: {
                                      show: {
                                        type: "pop",
                                        from: {
                                          scale: 0.1,
                                          opacity: 0,
                                        },
                                        to: {
                                          opacity: 1,
                                          scale: 1,
                                        },
                                      },
                                      hide: {
                                        type: "pop",
                                        from: {
                                          scale: 1,
                                          opacity: 1,
                                        },
                                        to: {
                                          opacity: 0,
                                          scale: 0.1,
                                        },
                                      },
                                    },
                                  })
                                  .appendTo(container);

                                return container;
                              },
                            },
                          );
                          $.each(
                            e.toolbarOptions.items,
                            function (index, item) {
                              switch (item.name) {
                                case "addRowButton":
                                  item.location = "before";
                                  item.showText = "always";
                                  item.options.text = getTrad("nuevaLlamada");
                                  item.options.icon = "plus";
                                  item.options.onClick = function () {
                                    let dxDropDownBox_lista_almacenes = $(
                                      "#dxDropDownBox_lista_almacenes",
                                    ).dxList("instance");
                                    pedidoHuespedSel = null;
                                    idNegativoPrendaNPedido = -1;
                                    prendaNPedidoHuesped = [];
                                    $("#btnModificarRecepcion")
                                      .dxButton("instance")
                                      .option("visible", false);
                                    $("#btnVolverListado")
                                      .dxButton("instance")
                                      .option("visible", false);
                                    $("#btnGuardarForm")
                                      .dxButton("instance")
                                      .option("text", getTrad("guardar"));

                                    var formLLamada =
                                      $("#dxForm_Llamada").dxForm("instance");

                                    var formData = {
                                      idPedidoHuesped: null,
                                      idAlmacen: null,
                                      fechaLlamada: null,
                                      fechaRecepcion: null,
                                      fechaEntrega: null,
                                      observaciones: null,
                                      idTipoServicio: null,
                                      personaSolicita: null,
                                      idPesonaRecibeLLamada: null,
                                      idPersonaRecibeRecepcion: null,
                                      denoEntidad: null,
                                      estadoPedido: null,
                                      porcentaje: null,
                                    };

                                    $("#dxDropDownBox_selCompaEnti")
                                      .dxDropDownBox("instance")
                                      .reset();
                                    dxDropDownBox_lista_almacenes.unselectAll();
                                    dxDropDownBox_lista_almacenes.option(
                                      "searchValue",
                                      "",
                                    );

                                    formLLamada.option("formData", formData);
                                    formLLamada.resetValues();
                                    formLLamada.repaint();

                                    $("#tabpanel-container")
                                      .dxTabPanel("instance")
                                      .option("selectedIndex", 1);
                                  };
                                  break;
                                default:
                                  break;
                              }
                            },
                          );
                        },
                        onRowRemoved: function () {
                          $("#dgvPedidosHuesped")
                            .dxDataGrid("instance")
                            .refresh();
                          notify({
                            message: getTrad("aviso_C_RegistroEliminado"),
                            type: "error",
                            displayTime: "1500",
                            closeOnClick: true,
                          });
                        },
                        //Estilos
                        showColumnLines: false,
                        showRowLines: true,
                        rowAlternationEnabled: true,
                      });
                    },
                  },
                  {
                    title: getTrad("llamada"),
                    template: function () {
                      return $("<div>").dxBox({
                        height: "100%",
                        direction: "col",
                        align: "space-around",
                        crossAlign: "stretch",
                        items: [
                          {
                            baseSize: 40,
                            shrink: 0,
                            ratio: 0,
                            template: function () {
                              return $(
                                "<div id='dxToolbar_Llamada'>",
                              ).dxToolbar({
                                items: [
                                  {
                                    location: "before",
                                    widget: "dxButton",
                                    toolbar: "bottom",
                                    options: {
                                      text: getTrad("atrás"),
                                      type: "back",
                                      onClick: function () {
                                        prendaHuespedSel = null;
                                        var dxForm_dxToolbar =
                                            $("#dxForm_dxToolbar").dxTabPanel(
                                              "instance",
                                            ),
                                          index =
                                            dxForm_dxToolbar.option(
                                              "selectedIndex",
                                            ),
                                          form =
                                            $("#dxForm_Llamada").dxForm(
                                              "instance",
                                            );

                                        switch (index) {
                                          case 0:
                                            $("#dxDropDownBox_selCompaEnti")
                                              .dxDropDownBox("instance")
                                              .option("isValid", true);
                                            $("#tabpanel-container")
                                              .dxTabPanel("instance")
                                              .option("selectedIndex", 0);
                                            break;
                                          case 1:
                                            dxForm_dxToolbar.option(
                                              "selectedIndex",
                                              index - 1,
                                            );

                                            // RESETEAR VALORES Y VALIDACIÓN A LOS POR DEFECTO DEL PEDIDO SEL.
                                            $.each(
                                              form.option().items[0].tabs[index]
                                                .items[0].items,
                                              function (index, item) {
                                                if (
                                                  typeof form.getEditor(
                                                    item.dataField,
                                                  ) !== "undefined"
                                                ) {
                                                  form
                                                    .getEditor(item.dataField)
                                                    .option(
                                                      "value",
                                                      pedidoHuespedSel[
                                                        item.dataField
                                                      ],
                                                    );
                                                  form
                                                    .getEditor(item.dataField)
                                                    .option("isValid", true);
                                                }
                                              },
                                            );
                                            break;
                                          default:
                                            break;
                                        }
                                      },
                                    },
                                  },
                                  {
                                    location: "before",
                                    widget: "dxButton",
                                    toolbar: "bottom",
                                    options: {
                                      visible: false,
                                      text: "Volver al listado",
                                      type: "doc",
                                      elementAttr: {
                                        id: "btnVolverListado",
                                      },
                                      onClick: function (options) {
                                        prendaHuespedSel = null;
                                        var dxForm_dxToolbar =
                                            $("#dxForm_dxToolbar").dxTabPanel(
                                              "instance",
                                            ),
                                          index =
                                            dxForm_dxToolbar.option(
                                              "selectedIndex",
                                            ),
                                          form =
                                            $("#dxForm_Llamada").dxForm(
                                              "instance",
                                            );

                                        $("#tabpanel-container")
                                          .dxTabPanel("instance")
                                          .option("selectedIndex", 0);
                                        dxForm_dxToolbar.option(
                                          "selectedIndex",
                                          index - 1,
                                        );
                                        $("#dxDropDownBox_selCompaEnti")
                                          .dxDropDownBox("instance")
                                          .option("isValid", true);
                                        // RESETEAR VALORES Y VALIDACIÓN A LOS POR DEFECTO DEL PEDIDO SEL.
                                        $.each(
                                          form.option().items[0].tabs[index]
                                            .items[0].items,
                                          function (index, item) {
                                            if (
                                              typeof form.getEditor(
                                                item.dataField,
                                              ) !== "undefined"
                                            ) {
                                              form
                                                .getEditor(item.dataField)
                                                .option(
                                                  "value",
                                                  pedidoHuespedSel[
                                                    item.dataField
                                                  ],
                                                );
                                              form
                                                .getEditor(item.dataField)
                                                .option("isValid", true);
                                            }
                                          },
                                        );
                                        $("#font-size").text(
                                          getTrad("pedidosHuesped"),
                                        );
                                      },
                                    },
                                  },
                                  {
                                    location: "after",
                                    widget: "dxButton",
                                    toolbar: "bottom",
                                    options: {
                                      text: getTrad("guardar"),
                                      icon: "check",
                                      type: "success",
                                      elementAttr: {
                                        id: "btnGuardarForm",
                                      },
                                      onClick: function (e) {
                                        var dxForm_dxToolbar =
                                            $("#dxForm_dxToolbar").dxTabPanel(
                                              "instance",
                                            ),
                                          indexForm =
                                            dxForm_dxToolbar.option(
                                              "selectedIndex",
                                            ),
                                          form =
                                            $("#dxForm_Llamada").dxForm(
                                              "instance",
                                            ),
                                          formData = $.extend(
                                            true,
                                            {},
                                            form.option("formData"),
                                          );

                                        if (
                                          formData &&
                                          customValidation(indexForm)
                                        ) {
                                          delete formData.estadoPedido;
                                          delete formData.denoEntidad;

                                          //INSERT
                                          if (
                                            typeof formData.idPedidoHuesped ===
                                              "undefined" ||
                                            !formData.idPedidoHuesped
                                          ) {
                                            delete formData.idPedidoHuesped;
                                            delete formData.porcentaje;

                                            e.component.option(
                                              "disabled",
                                              true,
                                            );
                                            datasource_pedidosHuesped
                                              .store()
                                              .insert(formData)
                                              .done(function (data) {
                                                e.component.option(
                                                  "disabled",
                                                  false,
                                                );

                                                pedidoHuespedSel = data;
                                                form.updateData(
                                                  $.extend(true, {}, data),
                                                );

                                                $("#dgvPedidosHuesped")
                                                  .dxDataGrid("instance")
                                                  .refresh();
                                                dxForm_dxToolbar.option(
                                                  "selectedIndex",
                                                  indexForm + 1,
                                                );
                                                $("#btnVolverListado")
                                                  .dxButton("instance")
                                                  .option("visible", true);
                                              });
                                          }

                                          //UPDATE
                                          else {
                                            // PÁGINA RECEPCIÓN
                                            //#region FILTROS PARA GUARDAR
                                            if (indexForm === 1) {
                                              // SI LA FECHA RECEPCIÓN ES MENOR A LA FECHA DE LLAMADA
                                              if (
                                                formData.fechaRecepcion <
                                                formData.fechaLlamada
                                              ) {
                                                notify({
                                                  message: getTrad(
                                                    "alerta_fechaRec_fechaLl",
                                                  ),
                                                  type: "error",
                                                  displayTime: "2500",
                                                  closeOnClick: true,
                                                });
                                                return;
                                              }

                                              // SI NO HAY PRENDAS AÑADIDAS AL PEDIDO
                                              if (
                                                $(
                                                  "#dxDataGrid_PedidoClienteSel_Repartos",
                                                )
                                                  .dxDataGrid("instance")
                                                  .totalCount() === -1 ||
                                                prendaNPedidoHuesped.length ===
                                                  0
                                              ) {
                                                notify({
                                                  message: getTrad(
                                                    "alerta_prenda_añadir",
                                                  ),
                                                  type: "error",
                                                  displayTime: "2500",
                                                  closeOnClick: true,
                                                });
                                                return;
                                              } else {
                                                for (
                                                  var i = 0;
                                                  i <
                                                  prendaNPedidoHuesped.length;
                                                  i++
                                                ) {
                                                  delete prendaNPedidoHuesped[i]
                                                    .denominacion;
                                                  if (
                                                    prendaNPedidoHuesped[i]
                                                      .idDefectoPrendaHuesped ===
                                                    0
                                                  ) {
                                                    prendaNPedidoHuesped[
                                                      i
                                                    ].idDefectoPrendaHuesped =
                                                      null;
                                                  }
                                                  if (
                                                    prendaNPedidoHuesped[i]
                                                      .idTipoLavado === 0
                                                  ) {
                                                    prendaNPedidoHuesped[
                                                      i
                                                    ].idTipoLavado = null;
                                                  }
                                                }
                                                formData.tblPrendaNPedidoHuesped =
                                                  prendaNPedidoHuesped;
                                              }
                                            }

                                            //#endregion
                                            delete formData.porcentaje;

                                            datasource_pedidosHuesped
                                              .store()
                                              .update(
                                                formData.idPedidoHuesped,
                                                formData,
                                              )
                                              .done(function (data) {
                                                datasource_prendaNPedidoHuesped
                                                  .load()
                                                  .done(function (prendas) {
                                                    delete data.tblPrendaNPedidoHuesped;
                                                    pedidoHuespedSel = data;
                                                    form.updateData(
                                                      $.extend(true, {}, data),
                                                    );

                                                    prendaNPedidoHuesped =
                                                      prendas;

                                                    var gridPrendas = $(
                                                      "#dxDataGrid_PedidoClienteSel_Repartos",
                                                    ).dxDataGrid("instance");
                                                    gridPrendas.option(
                                                      "dataSource",
                                                      prendaNPedidoHuesped,
                                                    );

                                                    $("#dgvPedidosHuesped")
                                                      .dxDataGrid("instance")
                                                      .refresh();
                                                    if (indexForm === 1) {
                                                      // SI LA COLUMNA PROCESADO DEL GRID PRENDAS ES VISIBLE, ESTAMOS EN ESTADO Y POR LO TANTO IRÁ A LA PÁGINA PRINCIPAL
                                                      if (
                                                        $(
                                                          "#dxDataGrid_PedidoClienteSel_Repartos",
                                                        ).dxDataGrid(
                                                          "columnOption",
                                                          "procesado",
                                                          "visible",
                                                        ) === true
                                                      ) {
                                                        $("#tabpanel-container")
                                                          .dxTabPanel(
                                                            "instance",
                                                          )
                                                          .option(
                                                            "selectedIndex",
                                                            0,
                                                          );
                                                      } else {
                                                        cambioPantallaProcesarPrendas();
                                                      }
                                                    } else {
                                                      dxForm_dxToolbar.option(
                                                        "selectedIndex",
                                                        indexForm + 1,
                                                      );
                                                    }
                                                  });
                                              });
                                          }
                                          idNegativoPrendaNPedido = -1;
                                        }
                                      },
                                    },
                                  },
                                ],
                              });
                            },
                          },
                          {
                            ratio: 1,
                            template: function () {
                              return $("<div id='dxForm_Llamada'>").dxForm({
                                labelLocation: "top",
                                height: "100%",
                                scrollingEnabled: true,
                                colCount: 1,

                                items: [
                                  {
                                    itemType: "tabbed",
                                    tabPanelOptions: {
                                      deferRendering: false,
                                      scrollByContent: true,
                                      scrollingEnabled: true,
                                      animationEnabled: true,
                                      elementAttr: {
                                        id: "dxForm_dxToolbar",
                                      },
                                      onSelectionChanged: function (e) {
                                        var index = e.addedItems[0].tabIndex;

                                        if (
                                          pedidoHuespedSel.fechaRecepcion &&
                                          e.addedItems[0].tabIndex !== 1
                                        ) {
                                          cambioPantallaProcesarPrendas();
                                        } else if (
                                          !pedidoHuespedSel ||
                                          !pedidoHuespedSel.fechaRecepcion ||
                                          index === 1
                                        ) {
                                          $("#font-size").text(
                                            e.addedItems[0].title,
                                          );
                                        }

                                        $("#dxToolbar_Llamada")
                                          .dxToolbar("instance")
                                          .repaint();

                                        if (index === 2) {
                                          var gridPrendas = $(
                                            "#dxDataGrid_PedidoClienteSel_Repartos",
                                          ).dxDataGrid("instance");
                                          gridPrendas.refresh();
                                          gridPrendas.repaint();

                                          $("#btnVolverListado")
                                            .dxButton("instance")
                                            .option("visible", true);
                                          // RESETEAR VALIDACIÓN DE LOS ELEMENTOS QUE SE VAN A MOSTRAR AL ENTRAR EN LA PÁGINA
                                          $.each(
                                            $("#dxForm_Llamada")
                                              .dxForm("instance")
                                              .option().items[0].tabs[index - 1]
                                              .items[0].items,
                                            function (indice, item) {
                                              $("#dxForm_Llamada")
                                                .dxForm("instance")
                                                .getEditor(item.dataField)
                                                .option("isValid", true);
                                            },
                                          );
                                        }
                                      },
                                    },
                                    tabs: [
                                      {
                                        title: getTrad("llamada"),
                                        tabIndex: 1,
                                        items: [
                                          {
                                            itemType: "group",
                                            caption: getTrad("llamada"),
                                            colCountByScreen: {
                                              xl: 4,
                                              lg: 4,
                                              md: 3,
                                              sm: 2,
                                              xs: 1,
                                            },
                                            screenByWidth: function (width) {
                                              return width >= 1200
                                                ? "xl"
                                                : width >= 992
                                                  ? "lg"
                                                  : width >= 768
                                                    ? "md"
                                                    : width >= 576
                                                      ? "sm"
                                                      : "xs";
                                            },
                                            items: [
                                              {
                                                dataField: "fechaLlamada",
                                                label: {
                                                  text: getTrad("fechaLlamada"),
                                                },
                                                editorType: "dxDateBox",
                                                editorOptions: {
                                                  width: "100%",
                                                  placeholder:
                                                    getTrad("seleccioneFecha"),
                                                  type: "datetime",
                                                  displayFormat:
                                                    "dd/MM/yyyy - HH:mm",
                                                  showClearButton: true,
                                                  useMaskBehavior: true,
                                                  min: "01/01/1900",
                                                },
                                                validationRules: [
                                                  {
                                                    type: "required",
                                                  },
                                                ],
                                              },
                                              {
                                                dataField: "idAlmacen",
                                                label: {
                                                  text: getTrad("almacen"),
                                                },
                                                template: function (
                                                  data,
                                                  itemElement,
                                                ) {
                                                  var container = $("<div>");
                                                  var dxDropDownBox = $(
                                                    "<div id='dxDropDownBox_selCompaEnti' style='float:left'>",
                                                  ).dxDropDownBox({
                                                    dataSource:
                                                      datasource_almacenes.store(),
                                                    valueExpr: "idAlmacen",
                                                    displayExpr: "denominacion",
                                                    width: "100%",
                                                    value:
                                                      data.component.option(
                                                        "formData",
                                                      )[data.dataField],
                                                    deferRendering: false,
                                                    remoteOperations: true,
                                                    placeholder:
                                                      getTrad("almacenes"),
                                                    dropDownOptions: {
                                                      width: 1000,
                                                      height: 500,
                                                      position: {
                                                        my: "center",
                                                        at: "center",
                                                        of: window,
                                                      },
                                                      closeOnOutsideClick: true,
                                                    },
                                                    onValueChanged: function (
                                                      e,
                                                    ) {
                                                      $(
                                                        "#dxDropDownBox_lista_almacenes",
                                                      )
                                                        .dxList("instance")
                                                        .option(
                                                          "selectedItemKeys",
                                                          e.value,
                                                        );
                                                    },
                                                    contentTemplate: function (
                                                      dxDropDownBox,
                                                    ) {
                                                      return $("<div>").dxBox({
                                                        direction: "row",
                                                        align: "space-around",
                                                        crossAlign: "stretch",
                                                        items: [
                                                          {
                                                            ratio: 1,
                                                            template:
                                                              function () {
                                                                // COMPAÑÍAS
                                                                var container =
                                                                  $("<div>");
                                                                var title = $(
                                                                  "<div class='font-size' style='margin-left:9px;'>" +
                                                                    getTrad(
                                                                      "compañia",
                                                                    ) +
                                                                    "</div>",
                                                                );
                                                                var lista_compañias =
                                                                  $(
                                                                    "<div id='dxDropDownBox_lista_compañias'>",
                                                                  ).dxList({
                                                                    dataSource:
                                                                      datasource_compañias,
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    selectionMode:
                                                                      "single",
                                                                    height:
                                                                      "375px",
                                                                    width:
                                                                      "95%",
                                                                    keyExpr:
                                                                      "idCompañia",
                                                                    scrolling: {
                                                                      mode: "infinite",
                                                                    },
                                                                    pageLoadMode:
                                                                      "scrollBottom",
                                                                    // EVENTOS
                                                                    onSelectionChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        var lista_entidades =
                                                                          $(
                                                                            "#dxDropDownBox_lista_entidades",
                                                                          ).dxList(
                                                                            "instance",
                                                                          );

                                                                        if (
                                                                          e
                                                                            .addedItems
                                                                            .length >
                                                                            0 &&
                                                                          e
                                                                            .addedItems[0]
                                                                            .idCompañia !==
                                                                            0
                                                                        ) {
                                                                          // SI EL ITEM SELECCIONADO NO ES TODOS
                                                                          lista_entidades
                                                                            .getDataSource()
                                                                            .filter(
                                                                              [
                                                                                [
                                                                                  "idCompañia",
                                                                                  "=",
                                                                                  e
                                                                                    .addedItems[0]
                                                                                    .idCompañia,
                                                                                ],
                                                                              ],
                                                                            );
                                                                        } else if (
                                                                          lista_entidades !==
                                                                          undefined
                                                                        ) {
                                                                          //AL ABRIR POR PRIMERA VEZ lista_entidades es undefined
                                                                          lista_entidades
                                                                            .getDataSource()
                                                                            .filter(
                                                                              "",
                                                                            );
                                                                        }

                                                                        lista_entidades.reload();
                                                                        var lista_almacenes =
                                                                          $(
                                                                            "#dxDropDownBox_lista_almacenes",
                                                                          ).dxList(
                                                                            "instance",
                                                                          );

                                                                        if (
                                                                          e
                                                                            .addedItems
                                                                            .length >
                                                                            0 &&
                                                                          e
                                                                            .addedItems[0]
                                                                            .idCompañia !==
                                                                            0
                                                                        ) {
                                                                          // SI EL ITEM SELECCIONADO NO ES TODOS
                                                                          lista_almacenes
                                                                            .getDataSource()
                                                                            .filter(
                                                                              [
                                                                                [
                                                                                  "idCompañia",
                                                                                  "=",
                                                                                  e
                                                                                    .addedItems[0]
                                                                                    .idCompañia,
                                                                                ],
                                                                              ],
                                                                            );
                                                                        } else if (
                                                                          lista_almacenes !==
                                                                          undefined
                                                                        ) {
                                                                          //AL ABRIR POR PRIMERA VEZ lista_almacenes es undefined
                                                                          lista_almacenes
                                                                            .getDataSource()
                                                                            .filter(
                                                                              "",
                                                                            );
                                                                        }
                                                                        datasource_almacenes.load();
                                                                      },
                                                                    itemTemplate:
                                                                      function (
                                                                        data,
                                                                        index,
                                                                      ) {
                                                                        var result =
                                                                          $(
                                                                            "<div>",
                                                                          );
                                                                        $(
                                                                          "<div>",
                                                                        )
                                                                          .text(
                                                                            data.denominacion,
                                                                          )
                                                                          .appendTo(
                                                                            result,
                                                                          );

                                                                        return result;
                                                                      },
                                                                  });

                                                                title.appendTo(
                                                                  container,
                                                                );
                                                                lista_compañias.appendTo(
                                                                  container,
                                                                );

                                                                return container;
                                                              },
                                                          },
                                                          {
                                                            ratio: 1,
                                                            template:
                                                              function () {
                                                                // ENTIDADES
                                                                var container =
                                                                  $("<div>");
                                                                var title = $(
                                                                  "<div class='font-size' style='margin-left:9px;'>" +
                                                                    getTrad(
                                                                      "entidad",
                                                                    ) +
                                                                    "</div>",
                                                                );
                                                                var lista_entidades =
                                                                  $(
                                                                    "<div id='dxDropDownBox_lista_entidades'>",
                                                                  ).dxList({
                                                                    dataSource:
                                                                      datasource_entidades,
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    selectionMode:
                                                                      "single",
                                                                    height:
                                                                      "375px",
                                                                    width:
                                                                      "95%",
                                                                    keyExpr:
                                                                      "idEntidad",
                                                                    scrolling: {
                                                                      mode: "infinite",
                                                                    },
                                                                    pageLoadMode:
                                                                      "scrollBottom",
                                                                    // EVENTOS
                                                                    onSelectionChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        var lista_almacenes =
                                                                          $(
                                                                            "#dxDropDownBox_lista_almacenes",
                                                                          ).dxList(
                                                                            "instance",
                                                                          );
                                                                        var dxDropDownBox_lista_compañias =
                                                                          $(
                                                                            "#dxDropDownBox_lista_compañias",
                                                                          ).dxList(
                                                                            "instance",
                                                                          );
                                                                        var idCompaSel =
                                                                          dxDropDownBox_lista_compañias.option(
                                                                            "selectedItemKeys",
                                                                          )
                                                                            .length >
                                                                          0
                                                                            ? dxDropDownBox_lista_compañias.option(
                                                                                "selectedItemKeys",
                                                                              )[0]
                                                                            : 0;
                                                                        var filtroCompa =
                                                                          [
                                                                            "idCompañia",
                                                                            "=",
                                                                            idCompaSel,
                                                                          ];

                                                                        if (
                                                                          typeof lista_almacenes !==
                                                                          "undefined"
                                                                        ) {
                                                                          var filtros =
                                                                            [];

                                                                          if (
                                                                            e
                                                                              .addedItems
                                                                              .length >
                                                                              0 &&
                                                                            e
                                                                              .addedItems[0]
                                                                              .idEntidad !==
                                                                              0
                                                                          ) {
                                                                            // SI EL ITEM SELECCIONADO NO ES TODOS
                                                                            filtros.push(
                                                                              [
                                                                                "idEntidad",
                                                                                "=",
                                                                                e
                                                                                  .addedItems[0]
                                                                                  .idEntidad,
                                                                              ],
                                                                            );
                                                                            if (
                                                                              idCompaSel !==
                                                                              0
                                                                            ) {
                                                                              filtros.push(
                                                                                filtroCompa,
                                                                              );
                                                                            }
                                                                            lista_almacenes
                                                                              .getDataSource()
                                                                              .filter(
                                                                                filtros,
                                                                              );
                                                                          } else if (
                                                                            lista_almacenes !==
                                                                            undefined
                                                                          ) {
                                                                            if (
                                                                              idCompaSel !==
                                                                              0
                                                                            ) {
                                                                              filtros.push(
                                                                                filtroCompa,
                                                                              );
                                                                            }
                                                                            if (
                                                                              filtros.length >
                                                                              0
                                                                            ) {
                                                                              lista_almacenes
                                                                                .getDataSource()
                                                                                .filter(
                                                                                  filtros,
                                                                                );
                                                                            } else {
                                                                              lista_almacenes
                                                                                .getDataSource()
                                                                                .filter(
                                                                                  "",
                                                                                );
                                                                            }
                                                                          }
                                                                          datasource_almacenes.load();
                                                                        }
                                                                      },
                                                                    itemTemplate:
                                                                      function (
                                                                        data,
                                                                        index,
                                                                      ) {
                                                                        var result =
                                                                          $(
                                                                            "<div>",
                                                                          );
                                                                        $(
                                                                          "<div>",
                                                                        )
                                                                          .text(
                                                                            data.denominacion,
                                                                          )
                                                                          .appendTo(
                                                                            result,
                                                                          );

                                                                        return result;
                                                                      },
                                                                  });

                                                                title.appendTo(
                                                                  container,
                                                                );
                                                                lista_entidades.appendTo(
                                                                  container,
                                                                );

                                                                return container;
                                                              },
                                                          },
                                                          {
                                                            // ALMACENES
                                                            ratio: 1,
                                                            template:
                                                              function () {
                                                                var container =
                                                                  $("<div>");
                                                                var title = $(
                                                                  "<div class='font-size' style='margin-left:9px;'>" +
                                                                    getTrad(
                                                                      "almacenes",
                                                                    ) +
                                                                    "</div>",
                                                                );
                                                                var lista_almacenes =
                                                                  $(
                                                                    "<div id='dxDropDownBox_lista_almacenes'>",
                                                                  ).dxList({
                                                                    //Datos
                                                                    dataSource:
                                                                      datasource_almacenes,
                                                                    focusStateEnabled: false,
                                                                    activeStateEnabled: false,
                                                                    selectionMode:
                                                                      "single",
                                                                    keyExpr:
                                                                      "idAlmacen",
                                                                    height:
                                                                      "375px",
                                                                    scrolling: {
                                                                      mode: "infinite",
                                                                    },
                                                                    pageLoadMode:
                                                                      "scrollBottom",
                                                                    searchEnabled: true,
                                                                    searchExpr:
                                                                      "denominacion",
                                                                    //EVENTOS
                                                                    onSelectionChanged:
                                                                      function (
                                                                        e,
                                                                      ) {
                                                                        if (
                                                                          typeof e
                                                                            .addedItems[0] !==
                                                                          "undefined"
                                                                        ) {
                                                                          if (
                                                                            dxDropDownBox.component.option(
                                                                              "value",
                                                                            ) !==
                                                                            e
                                                                              .addedItems[0]
                                                                              .idAlmacen
                                                                          ) {
                                                                            dxDropDownBox.component.option(
                                                                              "value",
                                                                              e
                                                                                .addedItems[0]
                                                                                .idAlmacen,
                                                                            );
                                                                            $(
                                                                              "#dxDropDownBox_selCompaEnti",
                                                                            )
                                                                              .dxDropDownBox(
                                                                                "instance",
                                                                              )
                                                                              .option(
                                                                                "isValid",
                                                                                true,
                                                                              );
                                                                          }
                                                                        }
                                                                      },
                                                                    itemTemplate:
                                                                      function (
                                                                        data,
                                                                        index,
                                                                      ) {
                                                                        var result =
                                                                          $(
                                                                            "<div>",
                                                                          );
                                                                        $(
                                                                          "<div>",
                                                                        )
                                                                          .text(
                                                                            data.denominacion,
                                                                          )
                                                                          .appendTo(
                                                                            result,
                                                                          );

                                                                        return result;
                                                                      },
                                                                  });

                                                                dxDropDownBox.component.on(
                                                                  "valueChanged",
                                                                  function (
                                                                    args,
                                                                  ) {
                                                                    data.component.updateData(
                                                                      data.dataField,
                                                                      args.value,
                                                                    );
                                                                    args.component.close();
                                                                  },
                                                                );

                                                                title.appendTo(
                                                                  container,
                                                                );
                                                                lista_almacenes.appendTo(
                                                                  container,
                                                                );

                                                                return container;
                                                              },
                                                          },
                                                        ],
                                                      });
                                                    },
                                                    onOpened: function (e) {
                                                      var dxDropDownBox_lista_entidades =
                                                        $(
                                                          "#dxDropDownBox_lista_entidades",
                                                        ).dxList("instance");
                                                      var dxDropDownBox_lista_compañias =
                                                        $(
                                                          "#dxDropDownBox_lista_compañias",
                                                        ).dxList("instance");

                                                      dxDropDownBox_lista_entidades.unselectAll();
                                                      dxDropDownBox_lista_compañias.unselectAll();
                                                      dxDropDownBox_lista_compañias.selectItem(
                                                        0,
                                                      );
                                                      dxDropDownBox_lista_entidades.selectItem(
                                                        0,
                                                      );
                                                    },
                                                  });
                                                  dxDropDownBox.appendTo(
                                                    container,
                                                  );
                                                  return container;
                                                },
                                              },
                                              {
                                                dataField: "observaciones",
                                                label: {
                                                  text: getTrad(
                                                    "observaciones",
                                                  ),
                                                },
                                                editorType: "dxTextArea",
                                                colSpan: 2,
                                              },
                                              {
                                                dataField: "personaSolicita",
                                                label: {
                                                  text: getTrad("solicitante"),
                                                },
                                                validationRules: [
                                                  {
                                                    type: "required",
                                                  },
                                                ],
                                              },
                                              {
                                                dataField:
                                                  "idPesonaRecibeLLamada",
                                                label: {
                                                  text: getTrad("receptor"),
                                                },
                                                editorType: "dxLookup",
                                                editorOptions: {
                                                  dataSource:
                                                    datasource_persona.store(),
                                                  valueExpr: "idPersona",
                                                  displayExpr: function (data) {
                                                    if (data)
                                                      return (
                                                        data.nombre +
                                                        " " +
                                                        data.apellidos
                                                      );
                                                  },
                                                  title: getTrad("personas"),
                                                  popupWidth: 300,
                                                  popupHeight: 500,
                                                  closeOnOutsideClick: true,
                                                },
                                                validationRules: [
                                                  {
                                                    type: "required",
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      {
                                        title: getTrad("recepcion"),
                                        tabIndex: 2,
                                        items: [
                                          {
                                            itemType: "group",
                                            caption: getTrad("recepcion"),
                                            colCountByScreen: {
                                              xl: 4,
                                              lg: 4,
                                              md: 3,
                                              sm: 2,
                                              xs: 1,
                                            },
                                            screenByWidth: function (width) {
                                              return width >= 1200
                                                ? "xl"
                                                : width >= 992
                                                  ? "lg"
                                                  : width >= 768
                                                    ? "md"
                                                    : width >= 576
                                                      ? "sm"
                                                      : "xs";
                                            },
                                            items: [
                                              {
                                                dataField: "fechaRecepcion",
                                                label: {
                                                  text: getTrad(
                                                    "fechaRecepcion",
                                                  ),
                                                },
                                                editorType: "dxDateBox",
                                                editorOptions: {
                                                  width: "100%",
                                                  placeholder:
                                                    getTrad("seleccioneFecha"),
                                                  type: "datetime",
                                                  displayFormat:
                                                    "dd/MM/yyyy - HH:mm",
                                                  useMaskBehavior: true,
                                                  min: "01/01/1900",
                                                },
                                                validationRules: [
                                                  {
                                                    type: "required",
                                                  },
                                                ],
                                              },
                                              {
                                                dataField: "idTipoServicio",
                                                label: {
                                                  text: getTrad("tipoServicio"),
                                                },
                                                editorType: "dxSelectBox",
                                                editorOptions: {
                                                  placeholder:
                                                    getTrad("seleccioneTipo"),
                                                  dataSource:
                                                    datasource_tipoServicio,
                                                  valueExpr: "idTipoServicio",
                                                  displayExpr: "denominacion",
                                                },
                                                validationRules: [
                                                  {
                                                    type: "required",
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                          {
                                            itemType: "group",
                                            caption: getTrad("prendas"),
                                            colCount: 1,

                                            items: [
                                              {
                                                template: function (
                                                  data,
                                                  itemElement,
                                                ) {
                                                  return $(
                                                    "<div id='dxDataGrid_PedidoClienteSel_Repartos'>",
                                                  ).dxDataGrid({
                                                    //Datos
                                                    dataSource:
                                                      prendaNPedidoHuesped,
                                                    height: "100%",
                                                    remoteOperations: true,
                                                    //Propiedades
                                                    columnsAutoWidth: true,
                                                    editing: {
                                                      allowUpdating: false,
                                                    },
                                                    headerFilter: {
                                                      visible: true,
                                                    },
                                                    filterRow: {
                                                      visible: true,
                                                      applyFilter: "auto",
                                                    },
                                                    searchPanel: {
                                                      visible: true,
                                                      width: 240,
                                                      placeholder:
                                                        getTrad("busqueda"),
                                                    },
                                                    paging: {
                                                      pageSize: 10,
                                                    },
                                                    scrolling: {
                                                      mode: "infinite",
                                                    },
                                                    columns: [
                                                      {
                                                        caption: " ",
                                                        name: "modificarGridPrendas",
                                                        width: "30px",
                                                        alignment: "center",
                                                        cssClass: "p-0",
                                                        cellTemplate: function (
                                                          cellElement,
                                                          cellInfo,
                                                        ) {
                                                          var contenedor = $(
                                                            "<div/>",
                                                          ).addClass(
                                                            "container_spanCentrado",
                                                          );
                                                          svg_edit
                                                            .clone()
                                                            .css(
                                                              "height",
                                                              "25px",
                                                            )
                                                            .css(
                                                              "width",
                                                              "25px",
                                                            )
                                                            .css(
                                                              "cursor",
                                                              "pointer",
                                                            )
                                                            .appendTo(
                                                              contenedor,
                                                            );

                                                          cellElement.on(
                                                            "click",
                                                            function () {
                                                              prendaHuespedSel =
                                                                cellInfo.data;
                                                              $(
                                                                "#popup_prendasHuesped",
                                                              )
                                                                .dxPopup(
                                                                  "instance",
                                                                )
                                                                .show();

                                                              //#region PONER LOS VALORES DE LA PRENDA SELECCIONADA
                                                              var formPrendas =
                                                                $(
                                                                  "#formPrendasHuesped",
                                                                ).dxForm(
                                                                  "instance",
                                                                );
                                                              var formLlamada =
                                                                $(
                                                                  "#dxForm_Llamada",
                                                                ).dxForm(
                                                                  "instance",
                                                                );
                                                              formPrendas.resetValues();

                                                              var idDefectoPrendaHuesped =
                                                                !prendaHuespedSel.idDefectoPrendaHuesped
                                                                  ? 0
                                                                  : prendaHuespedSel.idDefectoPrendaHuesped;
                                                              var idTipoLavado =
                                                                !prendaHuespedSel.idTipoLavado
                                                                  ? 0
                                                                  : prendaHuespedSel.idTipoLavado;

                                                              formPrendas
                                                                .getEditor(
                                                                  "idPrendaHuesped",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  prendaHuespedSel.idPrendaHuesped,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "idColorPrendaHuesped",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  prendaHuespedSel.idColorPrendaHuesped,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "idDefectoPrendaHuesped",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  idDefectoPrendaHuesped,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "marca",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  prendaHuespedSel.marca,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "idTipoPrendaHuesped",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  prendaHuespedSel.idTipoPrendaHuesped,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "idTipoLavado",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  idTipoLavado,
                                                                );
                                                              formPrendas
                                                                .getEditor(
                                                                  "idTipoServicio",
                                                                )
                                                                .option(
                                                                  "value",
                                                                  formLlamada
                                                                    .getEditor(
                                                                      "idTipoServicio",
                                                                    )
                                                                    .option(
                                                                      "text",
                                                                    ),
                                                                );
                                                              //#endregion
                                                            },
                                                          );

                                                          contenedor.appendTo(
                                                            cellElement,
                                                          );
                                                        },
                                                      },
                                                      {
                                                        dataField:
                                                          "idPrendaHuesped",
                                                        visible: false,
                                                      },
                                                      {
                                                        dataField:
                                                          "denominacion",
                                                        caption:
                                                          getTrad("prenda"),
                                                      },
                                                      {
                                                        dataField:
                                                          "idColorPrendaHuesped",
                                                        caption:
                                                          getTrad("color"),
                                                        lookup: {
                                                          dataSource:
                                                            datasource_colorPrendaHuesped.store(),
                                                          valueExpr:
                                                            "idColorPrendaHuesped",
                                                          displayExpr:
                                                            "denominacion",
                                                          closeOnOutsideClick: true,
                                                        },
                                                        width: 150,
                                                      },
                                                      {
                                                        dataField:
                                                          "idDefectoPrendaHuesped",
                                                        caption:
                                                          getTrad("defecto"),
                                                        lookup: {
                                                          dataSource:
                                                            datasource_defectoPrendaHuesped.store(),
                                                          valueExpr:
                                                            "idDefectoPrendaHuesped",
                                                          displayExpr:
                                                            "denominacion",
                                                          closeOnOutsideClick: true,
                                                        },
                                                        width: 150,
                                                      },
                                                      {
                                                        dataField: "marca",
                                                        caption:
                                                          getTrad("marca"),
                                                        allowEditing: false,
                                                        allowHeaderFiltering: false,
                                                        width: 180,
                                                      },
                                                      {
                                                        dataField:
                                                          "idTipoPrendaHuesped",
                                                        caption:
                                                          getTrad("tipoPrenda"),
                                                        lookup: {
                                                          dataSource:
                                                            datasource_tipoPrenda.store(),
                                                          valueExpr:
                                                            "idTipoPrendaHuesped",
                                                          displayExpr:
                                                            "denominacion",
                                                          closeOnOutsideClick: true,
                                                        },
                                                        allowEditing: false,
                                                        allowHeaderFiltering: false,
                                                        width: 180,
                                                      },
                                                      {
                                                        dataField:
                                                          "idTipoLavado",
                                                        caption:
                                                          getTrad("tipoLavado"),
                                                        lookup: {
                                                          dataSource:
                                                            datasource_tipoLavado.store(),
                                                          valueExpr:
                                                            "idTipoLavado",
                                                          displayExpr:
                                                            "denominacion",
                                                          closeOnOutsideClick: true,
                                                        },
                                                        allowEditing: false,
                                                        allowHeaderFiltering: false,
                                                        width: 180,
                                                      },
                                                      {
                                                        dataField: "procesado",
                                                        caption: "Procesado",
                                                        visible: false,
                                                        width: 150,
                                                        sortOrder: "desc",
                                                        sortIndex: 0,
                                                        cssClass: "p-0",
                                                        editCellTemplate:
                                                          function (
                                                            container,
                                                            options,
                                                          ) {
                                                            $("<div/>")
                                                              .dxSwitch({
                                                                switchedOnText:
                                                                  getTrad(
                                                                    "procesado",
                                                                  ),
                                                                switchedOffText:
                                                                  getTrad(
                                                                    "noProcesado",
                                                                  ),
                                                                value:
                                                                  typeof options
                                                                    .data
                                                                    .procesado !==
                                                                  "undefined"
                                                                    ? options
                                                                        .data
                                                                        .procesado
                                                                    : false,
                                                                width: "100px",
                                                                height: "30px",
                                                                onValueChanged:
                                                                  function (e) {
                                                                    //UPDATE DEL ARRAY DE PRENDAS
                                                                    $.each(
                                                                      prendaNPedidoHuesped,
                                                                      function (
                                                                        index,
                                                                        item,
                                                                      ) {
                                                                        if (
                                                                          item.idPrendaNPedidoHuesped ===
                                                                          options
                                                                            .data
                                                                            .idPrendaNPedidoHuesped
                                                                        ) {
                                                                          item.procesado =
                                                                            e.value;
                                                                        }
                                                                      },
                                                                    );
                                                                  },
                                                              })
                                                              .appendTo(
                                                                container,
                                                              );
                                                          },
                                                      },
                                                      {
                                                        caption: " ",
                                                        name: "eliminarGridPrendas",
                                                        width: "30px",
                                                        alignment: "center",
                                                        cssClass: "p-0",
                                                        cellTemplate: function (
                                                          cellElement,
                                                          cellInfo,
                                                        ) {
                                                          var contenedor = $(
                                                            "<div/>",
                                                          ).addClass(
                                                            "container_spanCentrado",
                                                          );
                                                          svg_remove
                                                            .clone()

                                                            .css(
                                                              "height",
                                                              "25px",
                                                            )
                                                            .css(
                                                              "width",
                                                              "25px",
                                                            )
                                                            .css(
                                                              "cursor",
                                                              "pointer",
                                                            )
                                                            .appendTo(
                                                              contenedor,
                                                            );

                                                          cellElement.on(
                                                            "click",
                                                            function () {
                                                              dxMensajePregunta(
                                                                getTrad(
                                                                  "preg_RegistroEliminarCorto",
                                                                ),
                                                                [
                                                                  [
                                                                    getTrad(
                                                                      "aceptar",
                                                                    ),
                                                                    function (
                                                                      e,
                                                                    ) {
                                                                      var indice =
                                                                        prendaNPedidoHuesped.indexOf(
                                                                          cellInfo.data,
                                                                        );
                                                                      prendaNPedidoHuesped.splice(
                                                                        indice,
                                                                        1,
                                                                      );
                                                                      var gridPrendas =
                                                                        $(
                                                                          "#dxDataGrid_PedidoClienteSel_Repartos",
                                                                        ).dxDataGrid(
                                                                          "instance",
                                                                        );
                                                                      gridPrendas.refresh();
                                                                    },
                                                                    "normal",
                                                                    "btnErrorTipoLavadoServicio",
                                                                  ],
                                                                  [
                                                                    getTrad(
                                                                      "cancelar",
                                                                    ),
                                                                    function (
                                                                      e,
                                                                    ) {},
                                                                    "normal",
                                                                    "btnCancel",
                                                                  ],
                                                                ],
                                                              );
                                                            },
                                                          );

                                                          contenedor.appendTo(
                                                            cellElement,
                                                          );
                                                        },
                                                      },
                                                    ],
                                                    onToolbarPreparing:
                                                      function (option) {
                                                        option.toolbarOptions.items.unshift(
                                                          {
                                                            location: "after",
                                                            widget: "dxButton",
                                                            showText: "inMenu",
                                                            options: {
                                                              icon: "refresh",
                                                              text: getTrad(
                                                                "actualizar",
                                                              ),
                                                              onClick:
                                                                function () {
                                                                  option.component.refresh();
                                                                },
                                                            },
                                                          },
                                                          {
                                                            location: "before",
                                                            widget: "dxButton",
                                                            options: {
                                                              icon: "plus",
                                                              elementAttr: {
                                                                id: "btnAñadirPrenda",
                                                              },
                                                              text: getTrad(
                                                                "añadirPrenda",
                                                              ),
                                                              onClick:
                                                                function (e) {
                                                                  prendaHuespedSel =
                                                                    null;

                                                                  var formLlamada =
                                                                    $(
                                                                      "#dxForm_Llamada",
                                                                    ).dxForm(
                                                                      "instance",
                                                                    );
                                                                  var fechaRecepcion =
                                                                      formLlamada
                                                                        .getEditor(
                                                                          "fechaRecepcion",
                                                                        )
                                                                        .option(
                                                                          "value",
                                                                        ),
                                                                    idTipoServicio =
                                                                      formLlamada
                                                                        .getEditor(
                                                                          "idTipoServicio",
                                                                        )
                                                                        .option(
                                                                          "value",
                                                                        );

                                                                  if (
                                                                    fechaRecepcion &&
                                                                    idTipoServicio
                                                                  ) {
                                                                    $(
                                                                      "#popup_prendasHuesped",
                                                                    )
                                                                      .dxPopup(
                                                                        "instance",
                                                                      )
                                                                      .show();

                                                                    var formPrendas =
                                                                      $(
                                                                        "#formPrendasHuesped",
                                                                      ).dxForm(
                                                                        "instance",
                                                                      );
                                                                    formPrendas.resetValues();

                                                                    formPrendas
                                                                      .getEditor(
                                                                        "idTipoServicio",
                                                                      )
                                                                      .option(
                                                                        "value",
                                                                        formLlamada
                                                                          .getEditor(
                                                                            "idTipoServicio",
                                                                          )
                                                                          .option(
                                                                            "text",
                                                                          ),
                                                                      );
                                                                    formPrendas
                                                                      .getEditor(
                                                                        "idDefectoPrendaHuesped",
                                                                      )
                                                                      .option(
                                                                        "value",
                                                                        0,
                                                                      );
                                                                    formPrendas
                                                                      .getEditor(
                                                                        "idTipoLavado",
                                                                      )
                                                                      .option(
                                                                        "value",
                                                                        0,
                                                                      );
                                                                  } else {
                                                                    var alertText =
                                                                      "<div style='font-size:13px;text-align:left;padding-left:33px;line-height:1.6;'>";

                                                                    if (
                                                                      !fechaRecepcion
                                                                    ) {
                                                                      alertText +=
                                                                        "- " +
                                                                        getTrad(
                                                                          "fechaRecepcion",
                                                                        ) +
                                                                        " <br />";
                                                                    }
                                                                    if (
                                                                      !idTipoServicio
                                                                    ) {
                                                                      alertText +=
                                                                        "- " +
                                                                        getTrad(
                                                                          "tipoServicio",
                                                                        ) +
                                                                        " <br />";
                                                                    }
                                                                    alertText +=
                                                                      "</div>";

                                                                    dxMensajePregunta(
                                                                      getTrad(
                                                                        "alerta_RellenaCampos",
                                                                      ) +
                                                                        " <br />" +
                                                                        alertText,
                                                                      [
                                                                        [
                                                                          getTrad(
                                                                            "aceptar",
                                                                          ),
                                                                          function (
                                                                            e,
                                                                          ) {},
                                                                          "danger",
                                                                          "btnErrorTipoLavadoServicio",
                                                                        ],
                                                                      ],
                                                                    );
                                                                  }
                                                                },
                                                            },
                                                          },
                                                          {
                                                            location: "before",
                                                            widget: "dxButton",
                                                            toolbar: "bottom",
                                                            options: {
                                                              visible: false,
                                                              text: getTrad(
                                                                "modificarPrendas",
                                                              ),
                                                              type: "doc",
                                                              elementAttr: {
                                                                id: "btnModificarRecepcion",
                                                              },
                                                              onClick:
                                                                function (
                                                                  options,
                                                                ) {
                                                                  $(
                                                                    "#dxDataGrid_PedidoClienteSel_Repartos",
                                                                  ).dxDataGrid(
                                                                    "columnOption",
                                                                    "modificarGridPrendas",
                                                                    "visible",
                                                                    true,
                                                                  );
                                                                  $(
                                                                    "#dxDataGrid_PedidoClienteSel_Repartos",
                                                                  ).dxDataGrid(
                                                                    "columnOption",
                                                                    "eliminarGridPrendas",
                                                                    "visible",
                                                                    true,
                                                                  );
                                                                  $(
                                                                    "#dxDataGrid_PedidoClienteSel_Repartos",
                                                                  ).dxDataGrid(
                                                                    "columnOption",
                                                                    "procesado",
                                                                    "visible",
                                                                    false,
                                                                  );

                                                                  $(
                                                                    "#dxDataGrid_PedidoClienteSel_Repartos",
                                                                  )
                                                                    .dxDataGrid(
                                                                      "instance",
                                                                    )
                                                                    .refresh();

                                                                  $(
                                                                    "#font-size",
                                                                  ).text(
                                                                    getTrad(
                                                                      "recepcion",
                                                                    ),
                                                                  );
                                                                  $(
                                                                    "#btnGuardarForm",
                                                                  )
                                                                    .dxButton(
                                                                      "instance",
                                                                    )
                                                                    .option(
                                                                      "text",
                                                                      getTrad(
                                                                        "guardar",
                                                                      ),
                                                                    );
                                                                  $(
                                                                    "#btnAñadirPrenda",
                                                                  )
                                                                    .dxButton(
                                                                      "instance",
                                                                    )
                                                                    .option(
                                                                      "visible",
                                                                      true,
                                                                    );
                                                                  options.element
                                                                    .dxButton(
                                                                      "instance",
                                                                    )
                                                                    .option(
                                                                      "visible",
                                                                      false,
                                                                    );
                                                                },
                                                            },
                                                          },
                                                        );
                                                      },
                                                    //Estilos
                                                    showColumnLines: false,
                                                    showRowLines: true,
                                                    rowAlternationEnabled: true,
                                                  });
                                                },
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              });
                            },
                          },
                        ],
                      });
                    },
                  },
                ],
              }),
          );
        },
      },
    ],
  });

  // POPUP ENTREGA
  $("#PedidosHuesped #popup_datosEntrega").dxPopup({
    showTitle: true,
    title: getTrad("entrega"),
    height: 500,
    width: 375,
    contentTemplate: function () {
      return $("<div>").dxBox({
        direction: "row",
        align: "center",
        crossAlign: "center",
        items: [
          {
            ratio: 1,
            template: function () {
              var container = $("<div>");
              var title = $(
                "<div class='font-size'>" +
                  getTrad("preg_FechaRealizarEntrega") +
                  "</div>",
              );
              var calendar = $(
                "<div id='dxCalendar_fechaEntrega' style='margin:auto;'>",
              ).dxCalendar({
                value: new Date(),
                disabled: false,
                firstDayOfWeek: 1,
                zoomLevel: "month",
                minZoomLevel: "month",
              });

              title.appendTo(container);
              calendar.appendTo(container);

              return container;
            },
          },
        ],
      });
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("generarEntrega"),
          icon: "check",
          type: "success",
          onClick: function (e, a) {
            var fechaEntrega = $("#dxCalendar_fechaEntrega")
              .dxCalendar("instance")
              .option("value");
            // SI LA FECHA ENTREGA ES MENOR A LA FECHA DE RECEPCIÓN

            if (
              fechaEntrega.setHours(0, 0, 0, 0) <
              pedidoHuespedSel.fechaRecepcion.setHours(0, 0, 0, 0)
            ) {
              notify({
                message: getTrad("alerta_fechaEnt_fechaRec"),
                type: "error",
                displayTime: "2500",
                closeOnClick: true,
              });
              return;
            }
            pedidoHuespedSel.fechaEntrega = formatDate_parameter(fechaEntrega);
            delete pedidoHuespedSel.estadoPedido;
            delete pedidoHuespedSel.denoEntidad;
            delete pedidoHuespedSel.porcentaje;
            datasource_pedidosHuesped
              .store()
              .update(pedidoHuespedSel.idPedidoHuesped, pedidoHuespedSel)
              .done(function (data) {
                $("#dgvPedidosHuesped")
                  .dxDataGrid("instance")
                  .refresh()
                  .done(function () {
                    notify({
                      message: getTrad("pedidoEntrega"),
                      type: "success",
                      displayTime: "2500",
                      closeOnClick: true,
                    });
                    $("#popup_datosEntrega").dxPopup("instance").hide();
                  });
              });
          },
        },
      },
    ],
  });

  // POPUP PRENDAS
  $("#PedidosHuesped #popup_prendasHuesped").dxPopup({
    showTitle: true,
    title: getTrad("prendasHuesped"),
    showCloseButton: true,
    deferRendering: false,
    closeOnOutsideClick: false,
    width: "1000px",
    height: "auto",
    contentTemplate: function () {
      var container = $("<div style='height:100%;'></div>");

      $("<div id='formPrendasHuesped' style='margin-bottom:14px;'>")
        .dxForm({
          formData: {},
          labelLocation: "top",
          colCount: 4,
          items: [
            {
              dataField: "idPrendaNPedido",
              visible: false,
            },
            {
              colSpan: 2,
              dataField: "idPrendaHuesped",
              editorType: "dxLookup",
              editorOptions: {
                dataSource: datasource_prendaHuesped.store(),
                valueExpr: "idPrendaHuesped",
                displayExpr: "denominacion",
                closeOnOutsideClick: true,
                showPopupTitle: false,
              },
              label: {
                text: getTrad("prendas"),
              },
              validationRules: [
                {
                  type: "required",
                },
              ],
            },
            {
              colSpan: 1,
              dataField: "idColorPrendaHuesped",
              editorType: "dxLookup",
              editorOptions: {
                dataSource: datasource_colorPrendaHuesped.store(),
                valueExpr: "idColorPrendaHuesped",
                displayExpr: "denominacion",
                closeOnOutsideClick: true,
                showPopupTitle: false,
              },
              label: {
                text: getTrad("color"),
              },
              validationRules: [
                {
                  type: "required",
                },
              ],
            },
            {
              colSpan: 1,
              dataField: "idDefectoPrendaHuesped",
              editorType: "dxLookup",
              editorOptions: {
                deferRendering: false,
                dataSource: datasource_defectoPrendaHuesped,
                valueExpr: "idDefectoPrendaHuesped",
                displayExpr: "denominacion",
                closeOnOutsideClick: true,
                showPopupTitle: false,
              },
              label: {
                text: getTrad("defecto"),
              },
            },
            {
              colSpan: 1,
              dataField: "marca",
              label: {
                text: getTrad("marca"),
              },
              validationRules: [
                {
                  type: "required",
                },
              ],
            },
            {
              colSpan: 1,
              dataField: "idTipoPrendaHuesped",
              editorType: "dxLookup",
              editorOptions: {
                dataSource: datasource_tipoPrenda.store(),
                valueExpr: "idTipoPrendaHuesped",
                displayExpr: "denominacion",
                closeOnOutsideClick: true,
                showPopupTitle: false,
              },
              label: {
                text: getTrad("tipoPrenda"),
              },
              validationRules: [
                {
                  type: "required",
                },
              ],
            },
            {
              colSpan: 1,
              dataField: "idTipoLavado",
              label: { text: getTrad("tipoLavado") },
              editorType: "dxSelectBox",
              editorOptions: {
                deferRendering: false,
                placeholder: getTrad("seleccioneTipo"),
                dataSource: datasource_tipoLavado,
                valueExpr: "idTipoLavado",
                displayExpr: "denominacion",
              },
            },
            {
              colSpan: 1,
              dataField: "idTipoServicio",
              editorOptions: {
                readOnly: true,
              },
              label: {
                text: getTrad("tipoServicio"),
              },
            },
          ],
        })
        .appendTo(container);

      return container;
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("guardar"),
          type: "success",
          onClick: function (e) {
            var form = $("#formPrendasHuesped").dxForm("instance");
            var data = form.option("formData");

            if (data && form.validate().isValid) {
              if (prendaHuespedSel) {
                //UPDATE
                $.each(prendaNPedidoHuesped, function (index, item) {
                  if (
                    item.idPrendaNPedidoHuesped ===
                    prendaHuespedSel.idPrendaNPedidoHuesped
                  ) {
                    item.denominacion = form
                      .getEditor("idPrendaHuesped")
                      .option().displayValue;
                    item.idColorPrendaHuesped = data.idColorPrendaHuesped;
                    item.idDefectoPrendaHuesped = data.idDefectoPrendaHuesped;
                    item.idPrendaHuesped = data.idPrendaHuesped;
                    item.idTipoLavado = data.idTipoLavado;
                    item.idTipoPrendaHuesped = data.idTipoPrendaHuesped;
                    item.marca = data.marca;
                  }
                });
              } else {
                //INSERT
                var prenda = {};
                prenda.idPrendaNPedidoHuesped = idNegativoPrendaNPedido;
                prenda.idPedidoHuesped = pedidoHuespedSel.idPedidoHuesped;
                prenda.denominacion = form
                  .getEditor("idPrendaHuesped")
                  .option().displayValue;
                prenda.idColorPrendaHuesped = data.idColorPrendaHuesped;
                prenda.idDefectoPrendaHuesped = data.idDefectoPrendaHuesped;
                prenda.idPrendaHuesped = data.idPrendaHuesped;
                prenda.idTipoLavado = data.idTipoLavado;
                prenda.idTipoPrendaHuesped = data.idTipoPrendaHuesped;
                prenda.marca = data.marca;
                prenda.procesado = false;
                prendaNPedidoHuesped.push(prenda);
                idNegativoPrendaNPedido--;
              }

              $("#popup_prendasHuesped").dxPopup("instance").hide();
              $("#dxDataGrid_PedidoClienteSel_Repartos")
                .dxDataGrid("instance")
                .refresh();
              $("#dxDataGrid_PedidoClienteSel_Repartos")
                .dxDataGrid("instance")
                .repaint();
            }
          },
        },
      },
    ],
    //EVENTS
    onContentReady: function (args) {
      var html = args.component.content();
      $(html).css("padding-bottom", "0");
    },
  });

  function customValidation(tabIndex) {
    var form = $("#dxForm_Llamada").dxForm("instance");
    var validation = true;

    if (
      tabIndex === 0 &&
      typeof $("#dxDropDownBox_selCompaEnti")
        .dxDropDownBox("instance")
        .option("value") === "undefined"
    ) {
      $("#dxDropDownBox_selCompaEnti")
        .dxDropDownBox("instance")
        .option("isValid", false);
      validation = false;
    }
    $.each(
      $("#dxForm_Llamada").dxForm("instance").option().items[0].tabs[tabIndex]
        .items[0].items,
      function (index, item) {
        if (
          typeof form.getEditor(item.dataField) !== "undefined" &&
          typeof item.validationRules !== "undefined"
        ) {
          if (
            !form.getEditor(item.dataField).option("value") ||
            form.getEditor(item.dataField).option("text") === ""
          ) {
            form.getEditor(item.dataField).option("isValid", false);
            validation = false;
          } else {
            form.getEditor(item.dataField).option("isValid", true);
          }
        }
      },
    );
    return validation;
  }

  function cambioPantallaProcesarPrendas() {
    $("#font-size").text(getTrad("recepcion"));

    $("#dxDataGrid_PedidoClienteSel_Repartos").dxDataGrid(
      "columnOption",
      "modificarGridPrendas",
      "visible",
      false,
    );
    $("#dxDataGrid_PedidoClienteSel_Repartos").dxDataGrid(
      "columnOption",
      "eliminarGridPrendas",
      "visible",
      false,
    );
    $("#dxDataGrid_PedidoClienteSel_Repartos").dxDataGrid(
      "columnOption",
      "procesado",
      "visible",
      true,
    );

    $("#btnGuardarForm")
      .dxButton("instance")
      .option("text", getTrad("guardarSalir"));
    $("#btnModificarRecepcion").dxButton("instance").option("visible", true);
    $("#btnVolverListado").dxButton("instance").option("visible", true);
    $("#btnAñadirPrenda").dxButton("instance").option("visible", false);
  }

  return deferred.promise();
}
