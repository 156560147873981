import React, { forwardRef } from "react";

import { Column } from "devextreme-react/data-grid";

import IdAlmacenOrigenColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/idAlmacenOrigenColumn";
import NumRegistroColumn from "pages/AssistantNew/Movimientos/shared/components/DataGridColumns/NumRegistroColumn";
import SharedDataGridMovimientos from "pages/AssistantNew/Movimientos/shared/components/SharedDataGridMovimientos";
import { getTrad } from "helpers";

const DataGridMovimientos = forwardRef(({ ...props }, ref) => {
  return (
    <SharedDataGridMovimientos
      ref={ref}
      newMovimientoTrad={"nuevaSalida"}
      {...props}
    >
      <Column
        dataField={"clienteDestino"}
        caption={getTrad("clienteDestino")}
        minWidth={250}
        alignment={"left"}
        allowResizing
        allowHeaderFiltering={false}
      />
      <Column {...IdAlmacenOrigenColumn(getTrad)} />
      <Column {...NumRegistroColumn(getTrad)} />
    </SharedDataGridMovimientos>
  );
});

export default DataGridMovimientos;
