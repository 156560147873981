import idsMoneda from "constants/enums/General/tblMoneda";
import { store } from "../config";

export function getTasaCambio({ idMonedaOrigen, idMonedaDestino, fecha }) {
    let tblTasaCambio = store.getState().tasaCambio.tblTasaCambio;

    if (!(fecha instanceof Date)) {
        console.error("La fecha debe ser un objeto Date");
        return null;
    }

    // Buscar conversion directa
    let tasa = tblTasaCambio.find(
        (tasa) =>
            ((tasa.idMonedaOrigen === idMonedaOrigen && tasa.idMonedaDestino === idMonedaDestino) ||
                (tasa.idMonedaOrigen === idMonedaDestino && tasa.idMonedaDestino === idMonedaOrigen)) &&
            tasa.fecha.getTime() <= fecha.setHours(0, 0, 0, 0)
    );

    // Si no se encuentra una conversion directa, buscar conversion a Euro
    if (!tasa) {
        let tblTasaCambio = store.getState().tasaCambio.tblTasaCambio;
        // Buscar conversion a Euro desde la moneda de origen
        const tasaCambioOrigen = tblTasaCambio.find(
            (tc) =>
                tc.idMonedaOrigen === idsMoneda.Euro &&
                tc.idMonedaDestino === idMonedaOrigen &&
                new Date(tc.fecha).setHours(0, 0, 0, 0) <= fecha.setHours(0, 0, 0, 0)
        );

        // Buscar conversion a Euro desde la moneda de destino
        const tasaCambioDestino = tblTasaCambio.find(
            (tc) =>
                tc.idMonedaOrigen === idsMoneda.Euro &&
                tc.idMonedaDestino === idMonedaDestino &&
                new Date(tc.fecha).setHours(0, 0, 0, 0) <= fecha.setHours(0, 0, 0, 0)
        );

        // Si no se encuentra una conversion a Euro, no se puede realizar la conversion
        if (!tasaCambioOrigen || !tasaCambioDestino) return null;

        // Realizar conversion a Euro y luego a la moneda destino
        return {
            idMonedaOrigen,
            idMonedaDestino,
            fecha,
            tasaCambio: tasaCambioOrigen.tasaCambio / tasaCambioDestino.tasaCambio,
            isConversionDirecta: false,
        };
    }

    return tasa;
}

export function getValorTasaCambio({ valor, idMoneda: idMonedaOrigen, fecha }, idMonedaDestino) {
    if (typeof valor !== "number") {
        console.error("El valor debe ser un número");
        return null;
    }

    // Buscar conversion directa
    let tasa = getTasaCambio({ idMonedaOrigen, idMonedaDestino, fecha });

    if (!tasa) return null;

    let isConversionDirecta =
        tasa.idMonedaOrigen === idMonedaOrigen &&
        tasa.idMonedaDestino === idMonedaDestino &&
        tasa.isConversionDirecta !== false;
    if (isConversionDirecta) {
        return valor * tasa.tasaCambio;
    } else {
        return valor / tasa.tasaCambio;
    }
}
