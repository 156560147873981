export const loading_input = {
  v: "4.6.8",
  fr: 60,
  ip: 0,
  op: 106,
  w: 500,
  h: 500,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Shape Layer 5",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 20,
              s: [0],
              e: [360],
            },
            { t: 110 },
          ],
        },
        p: { a: 0, k: [251, 250, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [10, 10] },
              p: { a: 0, k: [0, -100] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.996078431372549, 0.7843137254901961, 0.18823529411764706, 1,
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 20,
      op: 620,
      st: 20,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 4",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 15,
              s: [0],
              e: [360],
            },
            { t: 105 },
          ],
        },
        p: { a: 0, k: [251, 250, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [20, 20] },
              p: { a: 0, k: [0, -100] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.996078431372549, 0.7843137254901961, 0.18823529411764706, 1,
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 15,
      op: 615,
      st: 15,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 3",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 10,
              s: [0],
              e: [360],
            },
            { t: 100 },
          ],
        },
        p: { a: 0, k: [251, 250, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [30, 30] },
              p: { a: 0, k: [0, -100] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.996078431372549, 0.7843137254901961, 0.18823529411764706, 1,
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 10,
      op: 610,
      st: 10,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 2",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 5,
              s: [0],
              e: [360],
            },
            { t: 95 },
          ],
        },
        p: { a: 0, k: [251, 250, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [40, 40] },
              p: { a: 0, k: [0, -100] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.996078431372549, 0.7843137254901961, 0.18823529411764706, 1,
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 5,
      op: 605,
      st: 5,
      bm: 0,
      sr: 1,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 1",
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              n: ["0p667_1_0p333_0"],
              t: 0,
              s: [0],
              e: [360],
            },
            { t: 90 },
          ],
        },
        p: { a: 0, k: [250, 250, 0] },
        a: { a: 0, k: [0, 0, 0] },
        s: { a: 0, k: [100, 100, 100] },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    n: ["0p667_1_0p333_0", "0p667_1_0p333_0"],
                    t: 0,
                    s: [50, 50],
                    e: [40, 40],
                  },
                  {
                    i: { x: [0.667, 0.667], y: [1, 1] },
                    o: { x: [0.333, 0.333], y: [0, 0] },
                    n: ["0p667_1_0p333_0", "0p667_1_0p333_0"],
                    t: 84,
                    s: [40, 40],
                    e: [50, 50],
                  },
                  { t: 100 },
                ],
              },
              p: { a: 0, k: [0, -100] },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
            },
            {
              ty: "st",
              c: { a: 0, k: [0, 0, 0, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 0 },
              lc: 1,
              lj: 1,
              ml: 4,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.996078431372549, 0.7843137254901961, 0.18823529411764706, 1,
                ],
              },
              o: { a: 0, k: 100 },
              r: 1,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          ix: 1,
          mn: "ADBE Vector Group",
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
      sr: 1,
    },
  ],
};
