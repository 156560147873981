import React from "react";
import { connect } from "react-redux";
import $ from "jquery";

import "./styles.scss";

import { loadPanelActions } from "actions";
import { errorHandler, authHeader, getTrad, startOfMonth, formatDate_noTime_parameter } from "helpers";
import { connectionConstants } from "constants";
import PageTitle from "layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Box, { Item } from "devextreme-react/box";

import { ControlPresupuestarioCxt } from "./contexts/context";
import DataGridControl from "./components/DataGrid_Datos";
import ResumenControlPresupuestario from "./components/Resumen";
import LoaderDatos from "./components/LoaderDatos";
import { SelectorMoneda, SelectorFecha, SelectorVista } from "./components/Filtros";
import query from "devextreme/data/query";
import { DataSourceSAPContext } from "./components/DataGrid_Datos/components/CurrencyCell";
import Guid from "devextreme/core/guid";
import SelectorTasaDivisa from "./components/Filtros/SelectorTasaDivisa";

export const ResumenesContext = React.createContext({});

class ControlPresupuestarioWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fechaSel: startOfMonth(new Date()),
            original_data: [],
            original_data_SAP: [],
            dataSource: [],
            dataSourceSAP: [],
            tblAjustePresupuestario: [],
            stateMessage: null,
            isLoading: false,
            isLoadingSAP: false,
        };

        this.DataGridControl_REF = React.createRef();
    }

    get DataGridControl() {
        return this.DataGridControl_REF.current.dxDataGrid_REF.current.instance;
    }

    get DataSourceSAP_ctx() {
        return {
            isLoading: this.state.isLoadingSAP,
            dataSource: this.state.dataSourceSAP,
        };
    }

    // #region LifeCycle

    componentDidUpdate(prevProps, prevState) {
        const { empresa, fecha, centros, vista, moneda, tasaDivisa, agruparCentros } = this.props;

        if (
            centros.length > 0 &&
            (empresa !== prevProps.empresa ||
                fecha !== prevProps.fecha ||
                vista !== prevProps.vista ||
                JSON.stringify(centros) !== JSON.stringify(prevProps.centros))
        ) {
            if (
                empresa.idEmpresaPolarier != null &&
                (fecha !== prevProps.fecha ||
                    vista !== prevProps.vista ||
                    JSON.stringify(centros.map((x) => x.idAdmCentroCoste ?? x.idAdmElementoPEP)) !==
                        JSON.stringify(prevProps.centros.map((x) => x.idAdmCentroCoste ?? x.idAdmElementoPEP)))
            ) {
                this.datasource_ControlPresupuestario_load();
            }
        }
        if (
            moneda?.idMoneda !== prevProps.moneda?.idMoneda ||
            tasaDivisa !== prevProps.tasaDivisa ||
            agruparCentros !== prevProps.agruparCentros
        ) {
            const { original_data, original_data_SAP } = this.state;
            this.setState({
                dataSource: this.agruparDataSource(original_data),
                dataSourceSAP: this.agruparDataSource(original_data_SAP),
            });
        }
    }

    // #endregion

    // #region DataSource

    datasource_ControlPresupuestario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/ControlPresupuestario",
            errorHandler: (error) => this.datasource_ControlPresupuestario_errorHandler(error),
            beforeSend: (request) => this.datasource_ControlPresupuestario_beforeSend(request),
            version: 4,
        }),
    });

    datasource_ControlPresupuestario_errorHandler = (error) => {
        errorHandler(error, null);
        error.errorDetails().catch((data) => {
            this.setState({ stateMessage: getTrad(data.responseText) });
        });
    };

    datasource_ControlPresupuestario_SAP = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/ControlPresupuestario/SAP",
            errorHandler: (error) => this.datasource_ControlPresupuestario_SAP_errorHandler(error),
            beforeSend: (request) => this.datasource_ControlPresupuestario_beforeSend(request),
            version: 4,
        }),
    });

    datasource_ControlPresupuestario_SAP_errorHandler = (error) => {
        errorHandler(error, null);
        error.errorDetails().catch((data) => {
            let stateMessage = null;
            if (data.statusText === "timeout" && this.state.isLoading) {
                stateMessage = "Se ha producido un error al cargar los datos.";
            }
            this.setState({ isLoadingSAP: false, stateMessage });
        });
    };

    datasource_ControlPresupuestario_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        if (request.url.includes("ControlPresupuestario/SAP")) {
            request.timeout = 60000;
        }

        const { empresa, fecha, centros, vista } = this.props;

        let idAdmCentroCoste = centros.some((x) => x.idAdmCentroCoste != null)
            ? centros.map((x) => x.idAdmCentroCoste)
            : [];
        let idAdmElementoPEP = centros.some((x) => x.idAdmElementoPEP != null)
            ? centros.map((x) => x.idAdmElementoPEP)
            : [];

        request.params = {
            idEmpresaPolarier: empresa?.idEmpresaPolarier,
            fecha: formatDate_noTime_parameter(fecha),
            vista: vista,
            idsCentroCosteJSON: `'[${idAdmCentroCoste.join(",")}]'`,
            idsElementoPEPJSON: `'[${idAdmElementoPEP.join(",")}]'`,
        };

        this.setState({ stateMessage: null });
    };

    datasource_tblAjustePresupuestario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/ControlPresupuestario/AjustesPresupuestarios",
            key: "idAjustePresupuestario",
            errorHandler: (error) => errorHandler(error, null),
            beforeSend: (request) => this.datasource_tblAjustePresupuestario_beforeSend(request),
            onLoading: (loadOptions) => this.datasource_tblAjustePresupuestario_onLoading(loadOptions),
            version: 4,
        }),
        select: [
            "idAjustePresupuestario",
            "idAdmCuentaContable",
            "idAdmCentroCoste",
            "idAdmElementoPEP",
            "idMoneda",
            "fecha",
            "valor",
        ],
        expand: ["tblMesNAjustePresupuestario($select=fecha)"],
    });

    datasource_tblAjustePresupuestario_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        const { fecha, centros, vista } = this.props;

        let idAdmCentroCoste = centros.some((x) => x.idAdmCentroCoste != null)
            ? centros.map((x) => x.idAdmCentroCoste)
            : [];
        let idAdmElementoPEP = centros.some((x) => x.idAdmElementoPEP != null)
            ? centros.map((x) => x.idAdmElementoPEP)
            : [];

        request.params = {
            fecha: formatDate_noTime_parameter(fecha),
            vista: vista,
            idsCentroCosteJSON: `'[${idAdmCentroCoste.join(",")}]'`,
            idsElementoPEPJSON: `'[${idAdmElementoPEP.join(",")}]'`,
        };
    };

    datasource_tblAjustePresupuestario_onLoading = (loadOptions) => {
        const { empresa, fecha, centros, vista } = this.props;

        let idAdmCentroCoste = centros.some((x) => x.idAdmCentroCoste != null)
            ? centros.map((x) => x.idAdmCentroCoste)
            : [];
        let idAdmElementoPEP = centros.some((x) => x.idAdmElementoPEP != null)
            ? centros.map((x) => x.idAdmElementoPEP)
            : [];

        loadOptions.filter = [
            [`idAdmCentroCoste in (${idAdmCentroCoste})`],
            "or",
            [`idAdmElementoPEP in (${idAdmElementoPEP})`],
        ];
    };

    agruparDataSource = (data, tblAjustePresupuestario = this.state.tblAjustePresupuestario) => {
        if (data.length === 0) return [];
        const { agruparCentros, intercambiarDivisa, moneda } = this.props;

        let dataCentro = $.extend(true, [], data).map((x) => {
            x.idCentroElem = JSON.stringify([x.idAdmCentroCoste ?? -1, x.idAdmElementoPEP ?? -1]);
            return x;
        });

        const queryDataMap = (x, centro) => {
            const [idAdmCentroCoste, idAdmElementoPEP] = centro != null ? JSON.parse(centro) : [];

            var items = $.extend(true, [], x.items);

            var tblPlanificacion = items
                .flatMap((item) => item.tblPlanificacion)
                .filter((item) => item != null)
                .map((item) => {
                    let newValor = intercambiarDivisa(item, true);
                    if (newValor) {
                        item.valor = newValor.toFixed(2) * 1;
                        item.idMoneda = moneda.idMoneda;
                    }
                    return item;
                });

            var tblPartida = items
                .flatMap((item) => item.tblPartida)
                .filter((item) => item != null)
                .map((item) => {
                    let newValor = intercambiarDivisa(item);
                    if (newValor) {
                        item.valor = newValor.toFixed(2) * 1;
                        item.idMoneda = moneda.idMoneda;
                    }
                    return item;
                });

            let valorPresupuestado =
                tblPlanificacion
                    .reduce((acc, item) => this.calcularTotalReducer(acc, item, "valor"), {
                        valor: 0,
                        idMoneda: moneda.idMoneda,
                    })
                    ?.valor.toFixed(2) * 1;

            let valorReal =
                tblPartida
                    .reduce((acc, item) => this.calcularTotalReducer(acc, item, "valor"), {
                        valor: 0,
                        idMoneda: moneda.idMoneda,
                    })
                    ?.valor.toFixed(2) * 1;

            return {
                idCentroElem: centro,
                idAdmCuentaContable: x.key,
                idMoneda: moneda.idMoneda,
                valorPresupuestado,
                valorReal,
                valorAjuste:
                    tblAjustePresupuestario
                        .filter(
                            (ap) =>
                                (centro == null && ap.idAdmCuentaContable === x.key) ||
                                (centro != null &&
                                    ap.idAdmCuentaContable === x.key &&
                                    (ap.idAdmCentroCoste === idAdmCentroCoste ||
                                        ap.idAdmElementoPEP === idAdmElementoPEP))
                        )
                        .reduce((acc, item) => acc + intercambiarDivisa(item)?.toFixed(2) * 1, 0)
                        .toFixed(2) * 1,
                tblPartida: tblPartida,
            };
        };

        let dataSource = [];
        if (agruparCentros) {
            dataSource = query(dataCentro)
                .groupBy("idCentroElem")
                .toArray()
                .map((x) => {
                    x.items = query(x.items)
                        .groupBy("idAdmCuentaContable")
                        .toArray()
                        .map((item) => queryDataMap(item, x.key));
                    return x;
                })
                .flatMap((x) => x.items);
        } else {
            dataSource = query(dataCentro)
                .groupBy("idAdmCuentaContable")
                .toArray()
                .map((item) => queryDataMap(item));
        }

        let ajustesSinCuenta = $.extend(true, [], tblAjustePresupuestario)
            .map((x) => {
                x.idCentroElem = JSON.stringify([x.idAdmCentroCoste ?? -1, x.idAdmElementoPEP ?? -1]);
                return x;
            })
            .filter(
                (ap) =>
                    !dataSource.some(
                        (x) =>
                            x.idAdmCuentaContable === ap.idAdmCuentaContable &&
                            (!x.idCentroElem || x.idCentroElem === ap.idCentroElem)
                    )
            );
        if (agruparCentros) {
            ajustesSinCuenta = query(ajustesSinCuenta)
                .groupBy("idCentroElem")
                .toArray()
                .map((x) => {
                    x.items = query(x.items)
                        .groupBy("idAdmCuentaContable")
                        .toArray()
                        .map((item) => {
                            return {
                                idCentroElem: x.key,
                                idAdmCuentaContable: item.key,
                                idMoneda: moneda.idMoneda,
                                valorPresupuestado: 0,
                                valorReal: 0,
                                valorAjuste:
                                    item.items
                                        .reduce((acc, item) => acc + intercambiarDivisa(item)?.toFixed(2) * 1, 0)
                                        .toFixed(2) * 1,
                                tblPartida: [],
                                isLocalItem: true,
                            };
                        });
                    return x;
                })
                .flatMap((x) => x.items);
        } else {
            ajustesSinCuenta = query(ajustesSinCuenta)
                .groupBy("idAdmCuentaContable")
                .toArray()
                .map((item) => {
                    return {
                        idCentroElem: null,
                        idAdmCuentaContable: item.key,
                        idMoneda: moneda.idMoneda,
                        valorPresupuestado: 0,
                        valorReal: 0,
                        valorAjuste:
                            item.items
                                .reduce((acc, item) => acc + intercambiarDivisa(item)?.toFixed(2) * 1, 0)
                                .toFixed(2) * 1,
                        tblPartida: [],
                        isLocalItem: true,
                    };
                });
        }
        dataSource.push(...ajustesSinCuenta);

        return dataSource;
    };

    calcularTotalReducer = (acc, item, key) => {
        if (acc?.idMoneda !== item.idMoneda) return null;
        acc[key] += item[key];
        return acc;
    };

    datasource_ControlPresupuestario_load = (onlyAjustes = false) => {
        return new Promise((resolve, reject) => {
            const promGUID = new Guid().toString();
            this.promGUID = promGUID;

            if (this.promise_Datos != null) {
                this.datasource_ControlPresupuestario.cancel(this.promise_Datos.MyPo?.operationId);
                if (!onlyAjustes) {
                    this.datasource_ControlPresupuestario_SAP.cancel(this.promise_Datos.SAP?.operationId);
                    this.datasource_tblAjustePresupuestario.cancel(this.promise_Datos.Ajustes?.operationId);
                }
            }

            if (!onlyAjustes)
                this.setState({
                    isLoading: true,
                    isLoadingSAP: true,
                });

            setTimeout(() => {
                if (!onlyAjustes)
                    this.setState({
                        dataSource: [],
                        original_data: [],
                        dataSourceSAP: [],
                        original_data_SAP: [],
                    });
                this.promise_Datos = {};

                this.promise_Datos.Ajustes = this.datasource_tblAjustePresupuestario.reload();

                if (onlyAjustes) {
                    this.promise_Datos.Ajustes.then((tblAjustePresupuestario) => {
                        if (promGUID === this.promGUID) {
                            let dataSource = this.agruparDataSource(this.state.original_data, tblAjustePresupuestario);
                            this.setState({ dataSource, tblAjustePresupuestario });
                        }
                    });
                } else {
                    this.promise_Datos.MyPo = this.datasource_ControlPresupuestario.reload();
                    Promise.all([this.promise_Datos.MyPo, this.promise_Datos.Ajustes]).then(
                        ([data, tblAjustePresupuestario]) => {
                            if (promGUID === this.promGUID) {
                                let dataSource = this.agruparDataSource(data, tblAjustePresupuestario);
                                let isLoading = dataSource.length === 0;

                                this.setState({
                                    dataSource,
                                    original_data: data,
                                    isLoading,
                                    stateMessage: null,
                                    tblAjustePresupuestario,
                                });
                            }
                        }
                    );

                    this.promise_Datos.SAP = this.datasource_ControlPresupuestario_SAP.reload();
                    Promise.all([this.promise_Datos.SAP, this.promise_Datos.Ajustes]).then(
                        ([data, tblAjustePresupuestario]) => {
                            if (promGUID === this.promGUID) {
                                let dataSource = this.agruparDataSource(data, tblAjustePresupuestario);
                                if (this.state.dataSource.length === 0) {
                                    this.setState({
                                        dataSource: dataSource,
                                        original_data: data,
                                        isLoading: false,
                                        isLoadingSAP: false,
                                        tblAjustePresupuestario,
                                    });
                                } else {
                                    this.setState({
                                        dataSourceSAP: dataSource,
                                        original_data_SAP: data,
                                        isLoadingSAP: false,
                                    });
                                }
                            }
                        }
                    );
                }

                Promise.all(Object.values(this.promise_Datos)).finally(() => {
                    this.promise_Datos = null;
                    resolve();
                });
            }, 500);
        });
    };

    // #endregion

    render() {
        const { dataSource, original_data, original_data_SAP } = this.state;
        const { fecha, setFecha } = this.props;
        const { isLoading, stateMessage } = this.state;
        return (
            <>
                <PageTitle
                    heading={getTrad("controlPresupuestario")}
                    postHeading={
                        <div className="d-flex flex-row" style={this.gap}>
                            <SelectorVista />
                            {/* <SelectorCentro /> */}
                            {/* <SelectorEmpresa inputAttr={this.inputAttr} stylingMode="underlined" /> */}
                            <SelectorMoneda inputAttr={this.inputAttr} stylingMode="underlined" />
                            <SelectorTasaDivisa />
                            <SelectorFecha value={fecha} onValueChanged={setFecha} />
                        </div>
                    }
                />
                <div className={"mt-3 media-body position-relative"}>
                    <Box direction="col" height={"100%"} style={this.gap}>
                        <Item baseSize={100}>
                            <ResumenControlPresupuestario isLoading={isLoading} />
                        </Item>
                        <Item ratio={1}>
                            <div className="he-100 position-relative card border-radius">
                                <DataSourceSAPContext.Provider value={this.DataSourceSAP_ctx}>
                                    <ControlPresupuestarioCxt.Consumer>
                                        {({
                                            fecha,
                                            moneda,
                                            resumenes,
                                            setResumenes,
                                            empresa,
                                            vista,
                                            centros,
                                            agruparCentros,
                                            reloadCentros,
                                        }) => (
                                            <DataGridControl
                                                ref={this.DataGridControl_REF}
                                                isLoadingSAP={this.state.isLoadingSAP}
                                                dataSource={dataSource}
                                                original_data={original_data}
                                                original_data_SAP={original_data_SAP}
                                                tblAjustePresupuestario={this.state.tblAjustePresupuestario}
                                                datasource_ControlPresupuestario_load={
                                                    this.datasource_ControlPresupuestario_load
                                                }
                                                centros={centros}
                                                vista={vista}
                                                empresa={empresa}
                                                fecha={fecha}
                                                moneda={moneda}
                                                resumenes={resumenes}
                                                setResumenes={setResumenes}
                                                agruparCentros={agruparCentros}
                                                reloadCentros={reloadCentros}
                                            />
                                        )}
                                    </ControlPresupuestarioCxt.Consumer>
                                </DataSourceSAPContext.Provider>
                                <LoaderDatos visible={isLoading} mensaje={stateMessage} />
                            </div>
                        </Item>
                    </Box>
                </div>
            </>
        );
    }

    // #PageTitle

    inputAttr = { class: "font-size-sm text-uppercase" };
    gap = { gap: 20 };

    // #endregion
}

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(null, mapDispatchToProps)(ControlPresupuestarioWrapper);
