import React, { useEffect, useRef, useState } from "react";

import Box, { Item as ItemBox } from "devextreme-react/box";
import ValidationGroup from "devextreme-react/validation-group";
import { Col, Row, Form, FormGroup, Alert } from "reactstrap";
import { Popup } from "devextreme-react/popup";
import { TextBox, Button } from "devextreme-react";
import { Validator, AsyncRule, RequiredRule } from "devextreme-react/validator";

import { connectionConstants } from "constants";
import { getTrad } from "helpers";
import { userService } from "services";

const PopupIdentificacion = ({ onIdentifySuccess, onCancelIdentify }) => {
    const validationGroupRef = useRef();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loggingError, setLoggingError] = useState(false);
    const [enableDatosSalarialesOficinaError, setEnableDatosSalarialesOficinaError] = useState(false);

    const handleChange_username = (e) => setUsername(e.event.target.value);

    const handleChange_password = (e) => setPassword(e.event.target.value);

    const disablePaste = (e) => e.event.preventDefault();

    const asyncValidation = async (params) => {
        const value = params.value.replace(/\s+/g, " ").trim();
        return await userService.validateEmailUsuario(value);
    };

    const handleResponse = (response) => {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                return Promise.reject(response.status);
            }
            return data;
        });
    };

    const onSubmit = () => {
        const res = validationGroupRef.current.instance.validate();
        res.status === "pending" &&
            res.complete.then(async (r) => {
                if (r.isValid) {
                    const requestOptions = {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ email: username, password }),
                    };

                    await fetch(
                        connectionConstants.WEB_API_CORE_URL + "GetEnableDatosSalarialesOficina",
                        requestOptions
                    )
                        .then(handleResponse)
                        .then((enableDatosSalarialesOficina) => {
                            if (enableDatosSalarialesOficina) {
                                onIdentifySuccess();
                            } else {
                                setLoggingError(false);
                                setEnableDatosSalarialesOficinaError(true);
                            }
                        })
                        .catch((error) => {
                            setPassword("");
                            setLoggingError(true);
                            setEnableDatosSalarialesOficinaError(false);
                        });
                }
            });
    };

    return (
        <Popup
            visible={true}
            height={"auto"}
            width={"30%"}
            showTitle
            title={getTrad("identificacion")}
            onHiding={onCancelIdentify}
        >
            <ValidationGroup ref={validationGroupRef}>
                {loggingError || enableDatosSalarialesOficinaError ? (
                    <Alert className={"w-100"} color={"danger"}>
                        <div className={"text-center"}>
                            <span>
                                {loggingError
                                    ? getTrad("credencialesIncorrectas")
                                    : getTrad("sinPermisosParaVisualizarDatosSalariales")}
                            </span>
                        </div>
                    </Alert>
                ) : null}
                <Box direction={"col"} width={"auto"} height={"220px"}>
                    <ItemBox ratio={1}>
                        <Form>
                            <Row form>
                                <Col md={12} className="mt-3">
                                    <FormGroup>
                                        <span>{getTrad("usuario")}</span>
                                        <TextBox
                                            stylingMode={"underlined"}
                                            placeholder={getTrad("introduzcaUsuarioOEmail")}
                                            value={username}
                                            onChange={handleChange_username}
                                            onEnterKey={onSubmit}
                                        >
                                            <Validator>
                                                <RequiredRule message={getTrad("campoNecesario")} />
                                                <AsyncRule
                                                    message={getTrad("nombreUsuarioNoRegistrado")}
                                                    validationCallback={asyncValidation}
                                                />
                                            </Validator>
                                        </TextBox>
                                    </FormGroup>
                                </Col>
                                <Col md={12} className={"mt-3"}>
                                    <FormGroup>
                                        <span>{getTrad("contraseña")}</span>
                                        <TextBox
                                            stylingMode={"underlined"}
                                            placeholder={getTrad("introduzcaContraseña")}
                                            mode={"password"}
                                            value={password}
                                            onPaste={disablePaste}
                                            onChange={handleChange_password}
                                            onEnterKey={onSubmit}
                                        >
                                            <Validator>
                                                <RequiredRule message={getTrad("campoNecesario")} />
                                            </Validator>
                                        </TextBox>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </ItemBox>
                    <ItemBox baseSize={50}>
                        <div className={"he-100 d-flex flex-column justify-content-end align-self-end"}>
                            <Button
                                width={100}
                                text={getTrad("entrar")}
                                type={"default"}
                                stylingMode={"contained"}
                                onClick={onSubmit}
                            />
                        </div>
                    </ItemBox>
                </Box>
            </ValidationGroup>
        </Popup>
    );
};

export default PopupIdentificacion;
