import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  convertClienteUtcToLavanderiaUtc,
  dxMensajePregunta,
} from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var prodMaqSel = null,
    fechaSel = new Date(new Date().setHours(0, 0, 0, 0)),
    idMaqSel = null,
    prodMaq_Añadir_selectedRow = -1;

  //#region DATASOURCES
  var datasource_compañias_Añadir = null;
  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
    postProcess: function (data) {
      datasource_compañias_Añadir = data;
      return data;
    },
  });
  datasource_compañias.load();

  var datasource_entidades_Añadir = null;
  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion"],
    postProcess: function (data) {
      datasource_entidades_Añadir = data;
      return data;
    },
  });
  datasource_entidades.load();

  var datasource_maquinaria = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectMaquinasNProduccion",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
  });

  var datasource_prodMaq = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "prodMaquina_spSelectProdMaquinaNCliente",
      key: "idProduccionMaquinaNCliente",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          delete request.payload.denoCliente;
          request.url = request.url.replace(
            "prodMaquina_spSelectProdMaquinaNCliente",
            "tblProduccionMaquinaNCliente",
          );
        } else {
          if (!idMaqSel) {
            idMaqSel = $("#dxLookup_SelMaquinas")
              .dxLookup("instance")
              .option("value");
          }

          request.params.idMaquina = idMaqSel;
          request.params.fecha = formatDate_parameter(fechaSel);
        }
      },
      version: 4,
    }),
  });

  var datasource_prodMaqAnteriores = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "prodMaquina_spSelectProdMaquina_Anteriores",
      key: ["fecha", "idMaquina"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  //#endregion

  //#region dxBox Contenedores layout
  $("#ProduccionMaquina #dxContainer").dxBox({
    direction: "row",
    height: "100%",
  });

  $("#ProduccionMaquina #dxBox_col").dxBox({
    direction: "col",
    height: "100%",
  });
  //#endregion

  datasource_maquinaria.load().done(function (itemsMaquinas) {
    $("#ProduccionMaquina #dxToolbar_ProdMaq").dxToolbar({
      items: [
        {
          text: getTrad("maquinas") + ":",
          cssClass: "font-size",
          location: "before",
        },
        {
          location: "before",
          widget: "dxLookup",
          options: {
            elementAttr: { id: "dxLookup_SelMaquinas" },
            searchEnabled: true,
            showPopupTitle: false,
            showCancelButton: false,
            dataSource: itemsMaquinas,
            valueExpr: "idMaquina",
            displayExpr: "denominacion",
            value: itemsMaquinas.length > 0 ? itemsMaquinas[0].idMaquina : null,
            width: 400,
            onValueChanged: function (e) {
              // SI SE HA CAMBIADO EL VALOR MEDIANTE EL GRID ANTERIORES,NO VA HA HACER NINGÚN CAMBIO, COGE LOS VALORES DEL GRID.
              if (typeof e.event !== "undefined") {
                fechaSel = new Date(
                  new Date(
                    $("#dxDateBox_ProdMaq")
                      .dxDateBox("instance")
                      .option("value"),
                  ).setHours(0, 0, 0, 0),
                );
                idMaqSel = e.component.option("value");

                $("#dxDataGrid_ProdMaq").dxDataGrid("instance").refresh();
                var key = {
                  fecha: fechaSel,
                  idMaquina: idMaqSel,
                };

                var gridAnterior = $("#dxDataGrid_ProdAnteriores").dxDataGrid(
                  "instance",
                );
                gridAnterior.selectRowsByIndexes(
                  gridAnterior.getRowIndexByKey(key),
                );
              }
            },
          },
        },
        {
          location: "after",
          widget: "dxDateBox",
          options: {
            elementAttr: { id: "dxDateBox_ProdMaq" },
            displayFormat: "dd/MM/yyyy",
            value: convertClienteUtcToLavanderiaUtc(LAVANDERIA.GMT, new Date()),
            width: 160,
            onValueChanged: function (e) {
              // SI SE HA CAMBIADO EL VALOR MEDIANTE EL GRID ANTERIORES,NO VA HA HACER NINGÚN CAMBIO, COGE LOS VALORES DEL GRID.
              if (typeof e.event !== "undefined") {
                fechaSel = new Date(
                  new Date(e.component.option("value")).setHours(0, 0, 0, 0),
                );
                idMaqSel = $("#dxLookup_SelMaquinas")
                  .dxLookup("instance")
                  .option("value");

                $("#dxDataGrid_ProdMaq").dxDataGrid("instance").refresh();
                var key = {
                  fecha: fechaSel,
                  idMaquina: idMaqSel,
                };
                var gridAnterior = $("#dxDataGrid_ProdAnteriores").dxDataGrid(
                  "instance",
                );
                gridAnterior.selectRowsByIndexes(
                  gridAnterior.getRowIndexByKey(key),
                );
              }
            },
          },
        },
      ],
    });

    $("#ProduccionMaquina #dxDataGrid_ProdMaq")
      .dxDataGrid({
        dataSource: datasource_prodMaq,
        remoteOperations: true,
        //Propiedades
        columnsAutoWidth: true,
        editing: {
          mode: "cell",
          allowAdding: true,
          allowUpdating: true,
          useIcons: true,
          selectTextOnEditStart: true,
        },
        keyboardNavigation: {
          enterKeyAction: "moveFocus",
          enterKeyDirection: "row",
          editOnKeyPress: true,
        },
        height: "100%",
        paging: {
          enabled: false,
        },
        grouping: {
          autoExpandAll: true,
          allowCollapsing: false,
        },
        groupPanel: {
          visible: false,
        },
        columns: [
          {
            dataField: "idCompañia",
            visible: false,
          },
          {
            dataField: "idEntidad",
            visible: false,
          },
          {
            dataField: "horaInicio",
            caption: getTrad("horaInicio"),
            dataType: "datetime",
            width: "25%",
            format: "HH:mm",
            cssClass: "dx-Cell_Editable",
            editorOptions: {
              displayFormat: "HH:mm",
              showAnalogClock: false,
              showDropDownButton: false,
              spellcheck: true,
              type: "time",
              useMaskBehavior: true,
            },
          },
          {
            dataField: "horaFin",
            caption: getTrad("horaFin"),
            dataType: "datetime",
            format: "HH:mm",
            width: "25%",
            cssClass: "dx-Cell_Editable",
            editorOptions: {
              displayFormat: "HH:mm",
              showAnalogClock: false,
              showDropDownButton: false,
              spellcheck: true,
              type: "time",
              useMaskBehavior: true,
            },
          },
          {
            dataField: "denoCliente",
            caption: getTrad("cliente"),
            cssClass: "dx-Cell_Editable",
            editorOptions: {
              readOnly: true,
            },
          },
        ],
        //Eventos
        onEditorPreparing: function (e) {
          if (e.parentType === "dataRow") {
            if (e.dataField === "denoCliente") {
              prodMaqSel = e.row.data;
              e.editorOptions.onFocusIn = function (args) {
                var popup = $("#dxPopup_selCompaEnti").dxPopup("instance");
                if (!popup.option("visible")) {
                  popup.show();
                }
              };
            }
          }
        },
        onEditorPrepared: function (e) {
          if (e.parentType === "dataRow" && e.dataField !== "denoCliente") {
            e.element.find(".dx-texteditor-input").off("dxmousewheel");
          }
        },
        onInitNewRow: function (e) {
          e.data.idMaquina = idMaqSel;
          e.data.fecha = fechaSel;
        },
        onToolbarPreparing: function (e) {
          $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
              case "addRowButton":
                item.location = "before";
                item.showText = "always";
                item.options.text = getTrad("añadir");
                item.options.icon = "plus";
                item.options.onClick = function () {
                  if (
                    $("#dxLookup_SelMaquinas")
                      .dxLookup("instance")
                      .option("selectedItem")
                  ) {
                    var popup = $("#dxPopup_ProdMaq_Añadir").dxPopup(
                      "instance",
                    );
                    if (!popup.option("visible")) {
                      popup.show();
                    }
                  } else {
                    dxMensajePregunta(getTrad("alerta_SeleccionaMaquina"), [
                      [
                        getTrad("aceptar"),
                        function (e) {},
                        "danger",
                        "dxButton_sinLavanderia_error",
                      ],
                    ]);
                  }
                };
                break;
              default:
                break;
            }
          });
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      })
      .dxDataGrid("instance");

    $("#ProduccionMaquina #dxDataGrid_ProdAnteriores")
      .dxDataGrid({
        dataSource: datasource_prodMaqAnteriores,
        remoteOperations: true,
        //Propiedades
        columnsAutoWidth: true,
        height: "100%",
        paging: {
          enabled: false,
        },
        selection: {
          mode: "single",
        },
        columns: [
          {
            dataField: "idMaquina",
            visible: false,
          },
          {
            dataField: "fecha",
            dataType: "date",
            format: "dd/MM/yyyy",
          },
          {
            dataField: "denominacion",
            caption: "Máquina",
          },
        ],
        //Eventos
        onContentReady: function (e) {
          var key = null;
          if (
            $("#dxDateBox_ProdMaq").length > 0 &&
            $("#dxLookup_SelMaquinas").length > 0
          ) {
            key = {
              fecha: new Date(
                $("#dxDateBox_ProdMaq")
                  .dxDateBox("instance")
                  .option("value")
                  .setHours(0, 0, 0, 0),
              ),
              idMaquina: $("#dxLookup_SelMaquinas")
                .dxLookup("instance")
                .option("value"),
            };
          }
          if (key)
            e.component.selectRowsByIndexes(e.component.getRowIndexByKey(key));
        },
        onSelectionChanged: function (e) {
          if (e.selectedRowsData.length > 0) {
            fechaSel = e.selectedRowKeys[0].fecha;
            idMaqSel = e.selectedRowKeys[0].idMaquina;

            $("#dxLookup_SelMaquinas")
              .dxLookup("instance")
              .option("value", idMaqSel);
            $("#dxDateBox_ProdMaq")
              .dxDateBox("instance")
              .option("value", fechaSel);
            $("#dxDataGrid_ProdMaq").dxDataGrid("instance").refresh();
          }
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      })
      .dxDataGrid("instance");

    $("#ProduccionMaquina #dxPopup_selCompaEnti").dxPopup({
      showTitle: false,
      width: "800px",
      height: "500px",
      closeOnOutsideClick: true,
      shading: false,
      dragEnabled: false,
      contentTemplate: function (e) {
        return $("<div>").dxBox({
          direction: "row",
          align: "space-around",
          crossAlign: "stretch",
          items: [
            {
              ratio: 1,
              template: function () {
                // COMPAÑÍAS
                var container = $("<div>");
                var title = $(
                  "<div class='font-size' style='margin-left:9px;'>" +
                    getTrad("compañia") +
                    "</div>",
                );
                var lista_compañias = $("<div id='dxList_compañias'>").dxList({
                  dataSource: datasource_compañias,
                  selectionMode: "single",
                  height: "375px",
                  focusStateEnabled: false,
                  width: "95%",
                  keyExpr: "idCompañia",
                  scrolling: { mode: "infinite" },
                  pageLoadMode: "scrollBottom",
                  searchEnabled: true,
                  searchExpr: ["denominacion"],
                  // EVENTOS
                  onSelectionChanged: function (e) {
                    if (
                      e.addedItems.length > 0 &&
                      e.addedItems[0].idCompañia !== prodMaqSel.idCompañia
                    ) {
                      var dxDataGrid_ProdMaq = $(
                        "#dxDataGrid_ProdMaq",
                      ).dxDataGrid("instance");
                      var dxDataGrid_ProdMaq_rowIndex =
                        typeof prodMaqSel.idProduccionMaquinaNCliente !==
                        "undefined"
                          ? dxDataGrid_ProdMaq.getRowIndexByKey(
                              prodMaqSel.idProduccionMaquinaNCliente,
                            )
                          : 0;

                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "idEntidad",
                        null,
                      );
                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "idCompañia",
                        e.addedItems[0].idCompañia,
                      );
                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "denoCliente",
                        e.addedItems[0].denominacion,
                      );

                      if (
                        typeof dxDataGrid_ProdMaq.getCellElement(
                          dxDataGrid_ProdMaq_rowIndex + 1,
                          "horaInicio",
                        ) !== "undefined"
                      ) {
                        dxDataGrid_ProdMaq.editCell(
                          dxDataGrid_ProdMaq_rowIndex + 1,
                          "horaInicio",
                        );
                      } else {
                        dxDataGrid_ProdMaq.saveEditData();
                        dxDataGrid_ProdMaq.cancelEditData();
                      }
                      $("#dxPopup_selCompaEnti").dxPopup("instance").hide();
                    }
                  },
                  itemTemplate: function (data, index) {
                    var result = $("<div>");
                    $("<div>").text(data.denominacion).appendTo(result);

                    return result;
                  },
                });

                title.appendTo(container);
                lista_compañias.appendTo(container);

                return container;
              },
            },
            {
              ratio: 1,
              template: function () {
                var container = $("<div>");
                var title = $(
                  "<div class='font-size' style='margin-left:9px;'>" +
                    getTrad("entidad") +
                    "</div>",
                );
                var lista_entidades = $("<div id='dxList_entidades'>").dxList({
                  //Datos
                  dataSource: datasource_entidades,
                  selectionMode: "single",
                  keyExpr: "idEntidad",
                  focusStateEnabled: false,
                  height: "375px",
                  scrolling: { mode: "infinite" },
                  pageLoadMode: "scrollBottom",
                  searchEnabled: true,
                  searchExpr: ["denominacion"],
                  //EVENTOS
                  onSelectionChanged: function (e) {
                    if (
                      e.addedItems.length > 0 &&
                      e.addedItems[0].idEntidad !== prodMaqSel.idEntidad
                    ) {
                      var dxDataGrid_ProdMaq = $(
                        "#dxDataGrid_ProdMaq",
                      ).dxDataGrid("instance");
                      var dxDataGrid_ProdMaq_rowIndex =
                        typeof prodMaqSel.idProduccionMaquinaNCliente !==
                        "undefined"
                          ? dxDataGrid_ProdMaq.getRowIndexByKey(
                              prodMaqSel.idProduccionMaquinaNCliente,
                            )
                          : 0;

                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "idCompañia",
                        null,
                      );
                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "idEntidad",
                        e.addedItems[0].idEntidad,
                      );
                      dxDataGrid_ProdMaq.cellValue(
                        dxDataGrid_ProdMaq_rowIndex,
                        "denoCliente",
                        e.addedItems[0].denominacion,
                      );

                      if (
                        typeof dxDataGrid_ProdMaq.getCellElement(
                          dxDataGrid_ProdMaq_rowIndex + 1,
                          "horaInicio",
                        ) !== "undefined"
                      ) {
                        dxDataGrid_ProdMaq.editCell(
                          dxDataGrid_ProdMaq_rowIndex + 1,
                          "horaInicio",
                        );
                      } else {
                        dxDataGrid_ProdMaq.saveEditData();
                        dxDataGrid_ProdMaq.cancelEditData();
                      }
                      $("#dxPopup_selCompaEnti").dxPopup("instance").hide();
                    }
                  },
                  itemTemplate: function (data, index) {
                    var result = $("<div>");
                    $("<div>").text(data.denominacion).appendTo(result);

                    return result;
                  },
                });

                title.appendTo(container);
                lista_entidades.appendTo(container);

                return container;
              },
            },
          ],
        });
      },
      onShown: function (e) {
        //#region SELECCIÓN DE LA ENTIDAD O COMPAÑÍA ACTUAL
        var dxList_compañias = $("#dxList_compañias").dxList("instance");
        var dxList_entidades = $("#dxList_entidades").dxList("instance");
        dxList_compañias.option("searchValue", null);
        dxList_entidades.option("searchValue", null);
        dxList_compañias.unselectAll();
        dxList_entidades.unselectAll();

        if (prodMaqSel.idEntidad) {
          dxList_entidades.option("selectedItemKeys", prodMaqSel.idEntidad);
        } else if (prodMaqSel.idCompañia) {
          dxList_compañias.option("selectedItemKeys", prodMaqSel.idCompañia);
        }
        //#endregion
      },
    });

    $("#ProduccionMaquina #dxPopup_ProdMaq_Añadir").dxPopup({
      title: "Añadir registros",
      showTitle: true,
      width: "1050px",
      height: "500px",
      showCloseButton: true,
      dragEnabled: false,
      contentTemplate: function (e) {
        return $("<div>").dxBox({
          direction: "row",
          height: "100%",
          align: "space-around",
          crossAlign: "stretch",
          items: [
            {
              ratio: 3,
              template: function () {
                return $(
                  "<div id='dxDataGrid_ProdMaq_Añadir' style='padding-right:30px'>",
                ).dxDataGrid({
                  dataSource: {},
                  remoteOperations: true,
                  //Propiedades
                  columnsAutoWidth: true,
                  editing: {
                    mode: "batch",
                    allowAdding: true,
                    allowUpdating: true,
                    useIcons: true,
                  },
                  selection: {
                    mode: "single",
                  },
                  keyboardNavigation: {
                    enterKeyAction: "moveFocus",
                    enterKeyDirection: "row",
                    editOnKeyPress: true,
                  },
                  height: "100%",
                  paging: {
                    enabled: false,
                  },
                  grouping: {
                    autoExpandAll: true,
                    allowCollapsing: false,
                  },
                  groupPanel: {
                    visible: false,
                  },
                  columns: [
                    {
                      dataField: "idCompañia",
                      visible: false,
                    },
                    {
                      dataField: "idEntidad",
                      visible: false,
                    },
                    {
                      dataField: "horaInicio",
                      caption: getTrad("horaInicio"),
                      dataType: "datetime",
                      width: "25%",
                      format: "HH:mm",
                      cssClass: "dx-Cell_Editable",
                      editorOptions: {
                        displayFormat: "HH:mm",
                        showAnalogClock: false,
                        showDropDownButton: false,
                        spellcheck: true,
                        type: "time",
                        useMaskBehavior: true,
                      },
                      validationRules: [
                        {
                          type: "required",
                          message: getTrad("campoNecesario"),
                        },
                      ],
                    },
                    {
                      dataField: "horaFin",
                      caption: getTrad("horaFin"),
                      dataType: "datetime",
                      format: "HH:mm",
                      width: "25%",
                      cssClass: "dx-Cell_Editable",
                      editorOptions: {
                        displayFormat: "HH:mm",
                        showAnalogClock: false,
                        showDropDownButton: false,
                        spellcheck: true,
                        type: "time",
                        useMaskBehavior: true,
                      },
                      validationRules: [
                        {
                          type: "required",
                          message: getTrad("campoNecesario"),
                        },
                      ],
                    },
                    {
                      dataField: "denoCliente",
                      caption: getTrad("cliente"),
                      sortOrder: "desc",
                      cssClass: "dx-Cell_Editable",
                      editorOptions: {
                        readOnly: true,
                      },
                      validationRules: [
                        {
                          type: "required",
                          message: getTrad("campoNecesario"),
                        },
                      ],
                    },
                  ],
                  onFocusedCellChanging: function (e) {
                    prodMaq_Añadir_selectedRow = e.newRowIndex;
                  },
                  onEditorPreparing: function (e) {
                    if (e.parentType === "dataRow") {
                      e.component.option("focusedRowIndex", e.row.rowIndex);
                      if (
                        e.dataField === "denoCliente" &&
                        (!e.row.data.horaInicio || !e.row.data.horaFin)
                      ) {
                        e.component.editCell(
                          e.component.option("focusedRowIndex"),
                          "horaInicio",
                        );
                      }
                    }
                  },
                  onEditorPrepared: function (e) {
                    if (
                      e.parentType === "dataRow" &&
                      e.dataField !== "denoCliente"
                    ) {
                      e.element
                        .find(".dx-texteditor-input")
                        .off("dxmousewheel");
                    }
                  },
                  onEditingStart: function (e) {
                    var dxList_compañias_ProdMaq_Añadir = $(
                      "#dxList_compañias_ProdMaq_Añadir",
                    ).dxList("instance");
                    var dxList_entidades_ProdMaq_Añadir = $(
                      "#dxList_entidades_ProdMaq_Añadir",
                    ).dxList("instance");

                    //#region SELECCIONAR ENTI O COMPA DE LA ROW EN LAS LISTAS
                    if (e.column.dataField === "denoCliente") {
                      if (e.data.horaInicio && e.data.horaFin) {
                        dxList_compañias_ProdMaq_Añadir.option(
                          "disabled",
                          false,
                        );
                        dxList_entidades_ProdMaq_Añadir.option(
                          "disabled",
                          false,
                        );
                        dxList_compañias_ProdMaq_Añadir.option(
                          "searchValue",
                          null,
                        );
                        dxList_entidades_ProdMaq_Añadir.option(
                          "searchValue",
                          null,
                        );
                        dxList_compañias_ProdMaq_Añadir.unselectAll();
                        dxList_entidades_ProdMaq_Añadir.unselectAll();

                        if (e.data.idEntidad) {
                          dxList_entidades_ProdMaq_Añadir.option(
                            "selectedItemKeys",
                            e.data.idEntidad,
                          );
                        } else if (e.data.idCompañia) {
                          dxList_compañias_ProdMaq_Añadir.option(
                            "selectedItemKeys",
                            e.data.idCompañia,
                          );
                        }
                      } else {
                        // SI FALTA HORA INICIO O HORA FIN SE DESABILITAN Y DESELECCIONAN LISTAS
                        notify({
                          message: getTrad("validation_horaIni_horaFin"),
                          type: "error",
                          displayTime: "1500",
                          closeOnClick: true,
                        });
                        $("#dxList_compañias_ProdMaq_Añadir")
                          .dxList("instance")
                          .option("selectedItems", []);
                        $("#dxList_entidades_ProdMaq_Añadir")
                          .dxList("instance")
                          .option("selectedItems", []);
                        dxList_compañias_ProdMaq_Añadir.option(
                          "disabled",
                          true,
                        );
                        dxList_entidades_ProdMaq_Añadir.option(
                          "disabled",
                          true,
                        );
                      }
                      //#endregion
                    } else {
                      dxList_compañias_ProdMaq_Añadir.option("disabled", true);
                      dxList_entidades_ProdMaq_Añadir.option("disabled", true);
                    }
                  },
                  onToolbarPreparing: function (e) {
                    $.each(e.toolbarOptions.items, function (index, item) {
                      switch (item.name) {
                        case "addRowButton":
                          item.visible = false;
                          break;
                        case "saveButton":
                          item.visible = false;
                          break;
                        case "revertButton":
                          item.visible = false;
                          break;
                        default:
                          break;
                      }
                    });
                  },
                  //Estilos
                  showColumnLines: false,
                  showRowLines: true,
                  rowAlternationEnabled: true,
                });
              },
            },
            {
              ratio: 4,
              template: function () {
                return $("<div>").dxBox({
                  direction: "row",
                  height: "100%",
                  align: "space-around",
                  crossAlign: "stretch",
                  items: [
                    {
                      ratio: 1,
                      template: function () {
                        return $("<div>").dxBox({
                          direction: "col",
                          height: "100%",
                          align: "space-around",
                          crossAlign: "stretch",
                          items: [
                            {
                              baseSize: 41,
                              shrink: 0,
                              ratio: 0,
                              template: function () {
                                return $(
                                  "<div class='font-size' style='margin-left:9px;'>",
                                ).text(getTrad("compañia"));
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                // COMPAÑÍAS
                                return $(
                                  "<div id='dxList_compañias_ProdMaq_Añadir'>",
                                ).dxList({
                                  dataSource: datasource_compañias_Añadir,
                                  disabled: true,
                                  selectionMode: "single",
                                  height: "100%",
                                  focusStateEnabled: false,
                                  width: "95%",
                                  keyExpr: "idCompañia",
                                  scrolling: { mode: "infinite" },
                                  pageLoadMode: "scrollBottom",
                                  searchEnabled: true,
                                  searchExpr: ["denominacion"],
                                  // EVENTOS
                                  onSelectionChanged: function (e) {
                                    if (e.addedItems.length > 0) {
                                      var idCompañia =
                                        e.addedItems[0].idCompañia;
                                      var denoCliente =
                                        e.addedItems[0].denominacion;

                                      ProdMaqAñadir_UpdateValues(
                                        null,
                                        idCompañia,
                                        denoCliente,
                                      );
                                    }
                                  },
                                  itemTemplate: function (data, index) {
                                    var result = $("<div>");
                                    $("<div>")
                                      .text(data.denominacion)
                                      .appendTo(result);

                                    return result;
                                  },
                                });
                              },
                            },
                          ],
                        });
                      },
                    },
                    {
                      ratio: 1,
                      template: function () {
                        return $("<div>").dxBox({
                          direction: "col",
                          height: "100%",
                          align: "space-around",
                          crossAlign: "stretch",
                          items: [
                            {
                              baseSize: 41,
                              shrink: 0,
                              ratio: 0,
                              template: function () {
                                return $(
                                  "<div class='font-size' style='margin-left:9px;'>",
                                ).text(getTrad("entidad"));
                              },
                            },
                            {
                              ratio: 1,
                              template: function () {
                                return $(
                                  "<div id='dxList_entidades_ProdMaq_Añadir'>",
                                ).dxList({
                                  //Datos
                                  dataSource: datasource_entidades_Añadir,
                                  disabled: true,
                                  selectionMode: "single",
                                  keyExpr: "idEntidad",
                                  focusStateEnabled: false,
                                  height: "100%",
                                  scrolling: { mode: "infinite" },
                                  pageLoadMode: "scrollBottom",
                                  searchEnabled: true,
                                  searchExpr: ["denominacion"],
                                  //EVENTOS
                                  onSelectionChanged: function (e) {
                                    if (e.addedItems.length > 0) {
                                      var idEntidad = e.addedItems[0].idEntidad;
                                      var denoCliente =
                                        e.addedItems[0].denominacion;

                                      ProdMaqAñadir_UpdateValues(
                                        idEntidad,
                                        null,
                                        denoCliente,
                                      );
                                    }
                                  },
                                  itemTemplate: function (data, index) {
                                    var result = $("<div>");
                                    $("<div>")
                                      .text(data.denominacion)
                                      .appendTo(result);

                                    return result;
                                  },
                                });
                              },
                            },
                          ],
                        });
                      },
                    },
                  ],
                });
              },
            },
          ],
        });
      },
      toolbarItems: [
        {
          location: "after",
          widget: "dxButton",
          toolbar: "bottom",
          options: {
            text: getTrad("guardar"),
            type: "success",
            onClick: function (e) {
              var dxDataGrid_ProdMaq_Añadir = $(
                "#dxDataGrid_ProdMaq_Añadir",
              ).dxDataGrid("instance");

              var rowIncorrecta = false;
              var dxDataGrid_ProdMaq_Añadir_editedRows =
                dxDataGrid_ProdMaq_Añadir
                  .getVisibleRows()
                  .filter(function (item) {
                    if (
                      item.rowIndex !== 0 ||
                      (item.rowIndex === 0 && item.data.horaInicio) ||
                      (item.rowIndex === 0 && item.data.horaFin)
                    ) {
                      if (
                        !item.data.horaInicio ||
                        !item.data.horaFin ||
                        (item.rowIndex === 0 && !item.data.denoCliente)
                      ) {
                        rowIncorrecta = true;
                      }
                    }
                    return (
                      item.data.denoCliente ||
                      item.data.horaFin ||
                      item.data.horaInicio
                    );
                  });

              // SI NO HAY NINGUNA ROW CON ALGUN CAMPO NULL GUARDAR
              if (
                dxDataGrid_ProdMaq_Añadir_editedRows.length > 0 &&
                rowIncorrecta === false
              ) {
                $.each(
                  dxDataGrid_ProdMaq_Añadir_editedRows,
                  function (rowIndex, item) {
                    var isLastElement =
                      rowIndex ===
                      dxDataGrid_ProdMaq_Añadir_editedRows.length - 1;
                    var producciones = {};
                    producciones.idMaquina = idMaqSel;
                    producciones.fecha = formatDate_parameter(fechaSel);
                    producciones.idEntidad = item.data.idEntidad;
                    producciones.idCompañia = item.data.idCompañia;
                    producciones.horaInicio = item.data.horaInicio;
                    producciones.horaFin = item.data.horaFin;

                    datasource_prodMaq
                      .store()
                      .insert(producciones)
                      .done(function () {
                        if (isLastElement) {
                          $("#dxDataGrid_ProdMaq")
                            .dxDataGrid("instance")
                            .refresh(true);
                          $("#dxDataGrid_ProdAnteriores")
                            .dxDataGrid("instance")
                            .refresh(true);
                          $("#dxDataGrid_ProdMaq_Añadir")
                            .dxDataGrid("instance")
                            .cancelEditData();
                          $("#dxPopup_ProdMaq_Añadir")
                            .dxPopup("instance")
                            .hide();
                        }
                      });
                  },
                );
              } else {
                dxDataGrid_ProdMaq_Añadir
                  .getController("validating")
                  .validate(true);
                notify({
                  message: getTrad("alerta_rellenarTodosCampos"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              }
            },
          },
        },
      ],
      onShown: function (e) {
        var dxDataGrid_ProdMaq_Añadir = $(
          "#dxDataGrid_ProdMaq_Añadir",
        ).dxDataGrid("instance");
        dxDataGrid_ProdMaq_Añadir.cancelEditData();
        dxDataGrid_ProdMaq_Añadir.addRow();
        dxDataGrid_ProdMaq_Añadir.editCell(0, "horaInicio");
      },
      onHiding: function (args) {
        var dxDataGrid_ProdMaq_Añadir = $(
          "#dxDataGrid_ProdMaq_Añadir",
        ).dxDataGrid("instance");

        // COMPRUEBA SI EL GRID TIENE CAMBIOS
        var grid_hasChanges =
          dxDataGrid_ProdMaq_Añadir.getVisibleRows().filter(function (item) {
            if (
              item.rowIndex !== 0 ||
              (item.rowIndex === 0 && item.data.horaInicio) ||
              (item.rowIndex === 0 && item.data.horaFin)
            ) {
              return (
                item.data.idCompañia ||
                item.data.idEntidad ||
                item.data.horaFin ||
                item.data.horaInicio
              );
            }
            return false;
          }).length > 0;

        if (grid_hasChanges) {
          args.cancel = true;
          dxMensajePregunta(getTrad("preg_PerderCambios"), [
            // ACEPTAR
            [
              getTrad("aceptar"),
              function () {
                dxDataGrid_ProdMaq_Añadir.cancelEditData();
                args.component.hide();
              },
              "normal",
              "btnAceptar",
            ],
            // CANCELAR
            [getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
          ]);
        }
        prodMaq_Añadir_selectedRow = -1;
      },
    });

    deferred.resolve();
  });

  function ProdMaqAñadir_UpdateValues(idEntidad, idCompañia, denoCliente) {
    var lista_entidades = $("#dxList_entidades_ProdMaq_Añadir").dxList(
      "instance",
    );
    var lista_compañias = $("#dxList_compañias_ProdMaq_Añadir").dxList(
      "instance",
    );

    var grid = $("#dxDataGrid_ProdMaq_Añadir").dxDataGrid("instance");

    var old_Compañia = grid.cellValue(prodMaq_Añadir_selectedRow, "idCompañia");
    var old_Entidad = grid.cellValue(prodMaq_Añadir_selectedRow, "idEntidad");

    grid.cellValue(prodMaq_Añadir_selectedRow, "idEntidad", idEntidad);
    grid.cellValue(prodMaq_Añadir_selectedRow, "idCompañia", idCompañia);
    grid.cellValue(prodMaq_Añadir_selectedRow, "denoCliente", denoCliente);

    // SI SE AÑADE UNA NUEVA ROW
    if (!old_Compañia && !old_Entidad) {
      lista_compañias.option("disabled", true);
      lista_entidades.option("disabled", true);

      grid.addRow();
      grid.editCell(prodMaq_Añadir_selectedRow, "horaInicio");
    }
    // SI SE MODIFICA UNA ROW EXISTENTE
    else {
      lista_compañias.option("disabled", false);
      lista_entidades.option("disabled", false);
    }

    if (!idEntidad) {
      lista_entidades.option("selectedItems", []);
    }
    if (!idCompañia) {
      lista_compañias.option("selectedItems", []);
    }
  }

  return deferred.promise();
}
