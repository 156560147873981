import React, { forwardRef } from "react";

import DataGrid, {
  Column,
  KeyboardNavigation,
  HeaderFilter,
  Pager,
  Paging,
  CustomRule,
  Editing,
  Sorting,
} from "devextreme-react/data-grid";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import $ from "jquery";

import {
  errorHandler,
  authHeader,
  getTrad,
  formatDateTime_parameter,
} from "helpers";
import { connectionConstants } from "constants";

const allowedPageSizes = [20, 50, 100];

const editorOptions = {
  cantidad: {
    step: 0,
    format: { style: "decimal", maximumFractionDigits: 0 },
    min: 0,
    max: 999999,
  },
};

const DataGridRecambios = forwardRef(({ parteTrabajoSel, almacenSel }, ref) => {
  const formats = {
    cantidad: { style: "decimal", maximumFractionDigits: 0 },
    precioMedioPonderado: {
      style: "currency",
      maximumFractionDigits: 2,
      currency: almacenSel?.idMonedaNavigation?.codigo || "EUR",
    },
  };

  const datasource_recambios = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/Assistant/OrdenesTrabajo/recambios",
      key: "idRecambio",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => datasource_recambios_beforeSend(request),
      version: 4,
    }),
    postProcess: (data) => datasource_recambios_postProcess(data),
  });

  const datasource_recambios_beforeSend = (request) => {
    request.headers = { ...authHeader() };
    request.params.fecha = formatDateTime_parameter(
      parteTrabajoSel?.fecha ?? new Date(),
    );
    if (almacenSel) request.params.idAlmacen = almacenSel.idAlmacen;
    if (parteTrabajoSel?.idParteTrabajo)
      request.params.idParteTrabajo = parteTrabajoSel.idParteTrabajo;
  };

  const datasource_recambios_postProcess = (data) =>
    data.map((r) => {
      const recambio = parteTrabajoSel.tblRecambioNParteTrabajo.find(
        (rpt) => rpt.idRecambio === r.idRecambio,
      );

      return {
        ...r,
        cantidad: recambio?.cantidad ?? 0,
        is_añadido: recambio !== undefined,
      };
    });

  const onToolbarPreparing_tblRecambioNAlmacenRecambios = (e) => {
    let toolbarItems = e.toolbarOptions.items;
    e.toolbarOptions.height = 5;

    $.each(toolbarItems, function (_, item) {
      if (item.name == "saveButton" || item.name == "revertButton")
        item.visible = false;
    });
  };

  const onEditingStart_tblRecambioNAlmacenRecambios = (e) => {
    if (e.data.is_añadido || e.data.maxCantidad <= 0) e.cancel = true;
  };

  const onRowPrepared_tblRecambioNAlmacenRecambios = (e) => {
    if (e.rowType === "data" && (e.data.is_añadido || e.data.maxCantidad <= 0))
      e.rowElement.addClass("disabledRow");
  };

  const validationCallback_CustomRule_MaxVal = (e) => {
    let maxCantidad = null;

    maxCantidad = e.data.maxCantidad;
    let cantidad = e.data.cantidad;

    e.rule.message =
      getTrad("alerta_cantidadExcedida") +
      " " +
      maxCantidad +
      " " +
      getTrad("unidades").toLowerCase() +
      ".";
    if (maxCantidad !== null) {
      if (cantidad > maxCantidad) return false;
      else return true;
    } else return true;
  };

  const cellRender_maxCantidad = (e) => <center>{e.data.maxCantidad}</center>;

  const calculateSortValue_stock = ({ maxCantidad }) =>
    maxCantidad > 0 ? 1 : 0;

  return (
    <DataGrid
      ref={ref}
      dataSource={datasource_recambios}
      keyExpr={["idAlmacen", "idRecambio"]}
      height={"85%"}
      width={"100%"}
      showRowLines
      columnsAutoWidth
      repaintChangesOnly
      rowAlternationEnabled
      showColumnLines={false}
      remoteOperations={false}
      onToolbarPreparing={onToolbarPreparing_tblRecambioNAlmacenRecambios}
      onEditingStart={onEditingStart_tblRecambioNAlmacenRecambios}
      onRowPrepared={onRowPrepared_tblRecambioNAlmacenRecambios}
    >
      <Sorting mode={"multiple"} />
      <HeaderFilter visible />
      <KeyboardNavigation
        enterKeyAction={"moveFocus"}
        enterKeyDirection={"row"}
        ditOnKeyPress
      />
      <Pager
        showPageSizeSelector
        allowedPageSizes={allowedPageSizes}
        showInfo
      />
      <Paging defaultPageSize={50} />
      <Editing
        mode={"batch"}
        refreshMode={"repaint"}
        allowUpdating
        selectTextOnEditStart
      />
      <Column
        dataField={"referencia"}
        caption={getTrad("refFabricante")}
        allowEditing={false}
        alignment={"center"}
        width={110}
        allowHeaderFiltering={false}
      />
      <Column
        dataField={"denominacion"}
        caption={getTrad("denominacion")}
        allowEditing={false}
        alignment={"left"}
        sortIndex={1}
        sortOrder={"asc"}
        allowHeaderFiltering={false}
      />
      <Column
        dataField={"cantidad"}
        caption={getTrad("cantidad")}
        width={80}
        format={formats.cantidad}
        dataType={"number"}
        editorOptions={editorOptions.cantidad}
        cssClass={"dx-Cell_Editable"}
        alignment={"center"}
        allowEditing
        allowFiltering={false}
      >
        <CustomRule
          reevaluate
          validationCallback={validationCallback_CustomRule_MaxVal}
        />
      </Column>
      <Column
        dataField={"maxCantidad"}
        caption={getTrad("stock")}
        width={80}
        alignment={"left"}
        sortIndex={0}
        sortOrder={"desc"}
        allowEditing={false}
        allowFiltering={false}
        cellRender={cellRender_maxCantidad}
        calculateSortValue={calculateSortValue_stock}
      />
      <Column
        dataField={"precioMedioPonderado"}
        caption={getTrad("precioCompra")}
        cssClass={"dx-Cell_No_Editable"}
        allowEditing={false}
        width={200}
        alignment={"center"}
        allowFiltering={false}
        format={formats.precioMedioPonderado}
      />
    </DataGrid>
  );
});

export default DataGridRecambios;
