export const enConstruccion = {
  v: "5.5.10",
  fr: 30,
  ip: 0,
  op: 60,
  w: 1000,
  h: 1000,
  nm: "Comp 1",
  ddd: 0,
  assets: [],
  fonts: {
    list: [
      {
        fName: "Helvetica",
        fFamily: "Helvetica",
        fStyle: "Regular",
        ascent: 71.97265625,
      },
    ],
  },
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 1",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [520, 396, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [75, 75, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 87,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 5,
      nm: "WORK IN PROGRESS",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-29.854, 658.462, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [153.846, 153.846, 100], ix: 6 },
      },
      ao: 0,
      ef: [
        {
          ty: 21,
          nm: "Fill",
          np: 9,
          mn: "ADBE Fill",
          ix: 1,
          en: 1,
          ef: [
            {
              ty: 10,
              nm: "Fill Mask",
              mn: "ADBE Fill-0001",
              ix: 1,
              v: { a: 0, k: 0, ix: 1 },
            },
            {
              ty: 7,
              nm: "All Masks",
              mn: "ADBE Fill-0007",
              ix: 2,
              v: { a: 0, k: 0, ix: 2 },
            },
            {
              ty: 2,
              nm: "Color",
              mn: "ADBE Fill-0002",
              ix: 3,
              v: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 4,
                    s: [0.564705908298, 0.564705908298, 0.564705908298, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 8,
                    s: [0.980392158031, 0.12710043788, 0.038446750492, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 20,
                    s: [0.980392158031, 0.12710043788, 0.038446750492, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 24,
                    s: [0.564705908298, 0.564705908298, 0.564705908298, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 36,
                    s: [0.564705908298, 0.564705908298, 0.564705908298, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 40,
                    s: [0.980392158031, 0.12710043788, 0.038446750492, 1],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 52,
                    s: [0.980392158031, 0.12710043788, 0.038446750492, 1],
                  },
                  {
                    t: 56,
                    s: [0.564705908298, 0.564705908298, 0.564705908298, 1],
                  },
                ],
                ix: 3,
              },
            },
            {
              ty: 7,
              nm: "Invert",
              mn: "ADBE Fill-0006",
              ix: 4,
              v: { a: 0, k: 0, ix: 4 },
            },
            {
              ty: 0,
              nm: "Horizontal Feather",
              mn: "ADBE Fill-0003",
              ix: 5,
              v: { a: 0, k: 0, ix: 5 },
            },
            {
              ty: 0,
              nm: "Vertical Feather",
              mn: "ADBE Fill-0004",
              ix: 6,
              v: { a: 0, k: 0, ix: 6 },
            },
            {
              ty: 0,
              nm: "Opacity",
              mn: "ADBE Fill-0005",
              ix: 7,
              v: { a: 0, k: 1, ix: 7 },
            },
          ],
        },
      ],
      t: {
        d: {
          k: [
            {
              s: {
                s: 66,
                f: "Helvetica",
                t: "",
                j: 2,
                tr: 0,
                lh: 79.2,
                ls: 0,
                fc: [0.565, 0.565, 0.565],
              },
              t: 0,
            },
          ],
        },
        p: {},
        m: { g: 1, a: { a: 0, k: [0, 0], ix: 2 } },
        a: [],
      },
      ip: 0,
      op: 87,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 1",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, 14.815, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [27.991, 19.347],
                        [15.536, -41.521],
                        [2.77, -2.868],
                        [42.859, 27.247],
                        [9.649, 3.123],
                        [-85.12, -41.325],
                        [-4.55, 7.34],
                        [-1.617, 21.646],
                      ],
                      o: [
                        [41.521, 15.536],
                        [-2.011, 5.374],
                        [-6.967, 7.986],
                        [-6.53, -4.151],
                        [-5.485, -1.775],
                        [13.385, 5.541],
                        [6.817, -10.996],
                        [0.512, -6.857],
                      ],
                      v: [
                        [36.528, -102.013],
                        [83.578, 1.299],
                        [93.314, 21.75],
                        [-9.991, -21.392],
                        [-62.786, -45.589],
                        [31.92, -11.996],
                        [40.56, -33.006],
                        [65.306, -59.213],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9333333333333333, 0.7725490196078432, 0.403921568627451,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 2",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ind: 2,
                  ty: "sh",
                  ix: 3,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-41.521, -15.536],
                        [15.536, -41.521],
                        [2.77, -2.868],
                        [42.859, 27.247],
                        [-5.089, 9.051],
                        [-0.786, 2.1],
                      ],
                      o: [
                        [41.521, 15.536],
                        [-2.011, 5.374],
                        [-6.967, 7.986],
                        [-29.569, -18.799],
                        [0.936, -1.664],
                        [15.536, -41.521],
                      ],
                      v: [
                        [36.528, -102.013],
                        [83.578, 1.299],
                        [93.314, 21.75],
                        [-9.991, -21.392],
                        [-76.578, -51.778],
                        [-66.784, -54.963],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [-20.377, -205.62],
                        to: [1.864, -4.846],
                        ti: [-2.278, 5.922],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 12,
                        s: [-20.377, -205.62],
                        to: [1.864, -4.846],
                        ti: [-0.75, 1.95],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 23,
                        s: [-16, -217],
                        to: [1.667, -4.333],
                        ti: [-0.75, 1.95],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 1, y: 0 },
                        t: 36,
                        s: [-16, -217],
                        to: [0.824, -2.142],
                        ti: [-1.152, 2.995],
                      },
                      { t: 59, s: [-20.377, -205.62] },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [-33, 46], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 0,
                        s: [28],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 12,
                        s: [-10.222],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 23,
                        s: [-4.058],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [1], y: [0] },
                        t: 36,
                        s: [4],
                      },
                      { t: 59, s: [28] },
                    ],
                    ix: 6,
                  },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 2",
              np: 4,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  d: 1,
                  ty: "el",
                  s: { a: 0, k: [146, 146], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  nm: "Ellipse Path 1",
                  mn: "ADBE Vector Shape - Ellipse",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.6078431372549019, 0.6078431372549019,
                      0.6078431372549019, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 0,
                        s: [-20.377, -205.62],
                        to: [1.864, -4.846],
                        ti: [-2.278, 5.922],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 12,
                        s: [-20.377, -205.62],
                        to: [1.864, -4.846],
                        ti: [-0.75, 1.95],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 23,
                        s: [-16, -217],
                        to: [1.667, -4.333],
                        ti: [-0.75, 1.95],
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 1, y: 0 },
                        t: 36,
                        s: [-16, -217],
                        to: [0.824, -2.142],
                        ti: [-1.152, 2.995],
                      },
                      { t: 59, s: [-20.377, -205.62] },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [-33, 46], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 0,
                        s: [28],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 12,
                        s: [-10.222],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [0.333], y: [0] },
                        t: 23,
                        s: [-4.058],
                      },
                      {
                        i: { x: [0.667], y: [1] },
                        o: { x: [1], y: [0] },
                        t: 36,
                        s: [4],
                      },
                      { t: 59, s: [28] },
                    ],
                    ix: 6,
                  },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Ellipse 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ty: "rc",
                  d: 1,
                  s: { a: 0, k: [134, 293.524], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 50, ix: 4 },
                  nm: "Rectangle Path 1",
                  mn: "ADBE Vector Shape - Rect",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-117, -70], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 25, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-15.219, -5.409],
                              [1.959, -23.511],
                              [22.009, -0.67],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [15.509, 5.512],
                              [-1.526, 18.316],
                              [-21.542, 0.656],
                              [0, 0],
                            ],
                            v: [
                              [216.643, 145.436],
                              [-264.921, 105.678],
                              [-259.374, 74.852],
                              [221.019, 115.67],
                              [223.931, 94.492],
                              [291.938, 100.58],
                              [326.649, 141.711],
                              [281.433, 173.492],
                              [216.949, 168.769],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-15.34, -5.064],
                              [1.427, -23.553],
                              [21.991, -1.167],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [15.632, 5.161],
                              [-1.112, 18.349],
                              [-21.525, 1.143],
                              [0, 0],
                            ],
                            v: [
                              [282.348, 162.962],
                              [-200.072, 134.097],
                              [-195.222, 103.149],
                              [286.05, 133.101],
                              [288.483, 111.858],
                              [356.622, 116.408],
                              [392.259, 156.75],
                              [347.766, 189.551],
                              [283.181, 186.287],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-15.819, -3.275],
                              [-1.278, -23.561],
                              [21.713, -3.677],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [16.12, 3.338],
                              [0.995, 18.356],
                              [-21.253, 3.599],
                              [0, 0],
                            ],
                            v: [
                              [265.446, 128.789],
                              [-217.107, 155.325],
                              [-215.831, 124.026],
                              [265.706, 98.7],
                              [265.692, 77.318],
                              [333.904, 74.04],
                              [373.924, 110.039],
                              [333.477, 147.716],
                              [268.942, 151.865],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 1, y: 0 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-15.34, -5.064],
                              [1.427, -23.553],
                              [21.991, -1.167],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [15.632, 5.161],
                              [-1.112, 18.349],
                              [-21.525, 1.143],
                              [0, 0],
                            ],
                            v: [
                              [282.348, 162.962],
                              [-200.072, 134.097],
                              [-195.222, 103.149],
                              [286.05, 133.101],
                              [288.483, 111.858],
                              [356.622, 116.408],
                              [392.259, 156.75],
                              [347.766, 189.551],
                              [283.181, 186.287],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 59,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-15.219, -5.409],
                              [1.959, -23.511],
                              [22.009, -0.67],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [15.509, 5.512],
                              [-1.526, 18.316],
                              [-21.542, 0.656],
                              [0, 0],
                            ],
                            v: [
                              [216.643, 145.436],
                              [-264.921, 105.678],
                              [-259.374, 74.852],
                              [221.019, 115.67],
                              [223.931, 94.492],
                              [291.938, 100.58],
                              [326.649, 141.711],
                              [281.433, 173.492],
                              [216.949, 168.769],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.807843137254902, 0.807843137254902, 0.807843137254902,
                      1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-117, -70], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 25, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-15.219, -5.409],
                              [1.959, -23.511],
                              [22.009, -0.67],
                            ],
                            o: [
                              [0, 0],
                              [15.509, 5.512],
                              [-1.526, 18.316],
                              [-21.542, 0.656],
                            ],
                            v: [
                              [248.333, 126.431],
                              [290.332, 103.286],
                              [321.418, 141.985],
                              [277.26, 160.598],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-4.877, 15.401],
                              [-23.569, -1.14],
                              [-1.436, -21.975],
                            ],
                            o: [
                              [0, 0],
                              [4.969, -15.694],
                              [18.361, 0.888],
                              [1.405, 21.509],
                            ],
                            v: [
                              [353.163, 176.769],
                              [316.546, 142.414],
                              [356.451, 106.288],
                              [389.792, 150.377],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-7.014, 14.552],
                              [-23.168, -4.475],
                              [1.7, -21.957],
                            ],
                            o: [
                              [0, 0],
                              [7.148, -14.829],
                              [18.049, 3.487],
                              [-1.664, 21.491],
                            ],
                            v: [
                              [304.548, 129.364],
                              [298.302, 92.953],
                              [342.933, 62.859],
                              [369.674, 111.237],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 27,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-14.423, 7.276],
                              [-15.657, -17.653],
                              [31.151, 6.151],
                            ],
                            o: [
                              [0, 0],
                              [14.697, -7.415],
                              [12.198, 13.752],
                              [-21.147, -4.176],
                            ],
                            v: [
                              [324.814, 154.615],
                              [336.202, 117.36],
                              [395.484, 129.638],
                              [387.254, 184.299],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-13.115, 1.437],
                              [-12.224, -19.402],
                              [22.655, 15.745],
                            ],
                            o: [
                              [0, 0],
                              [13.365, -1.465],
                              [14.746, 23.404],
                              [-15.635, -10.787],
                            ],
                            v: [
                              [367.986, 183.661],
                              [388.264, 155.287],
                              [424.931, 186.102],
                              [418.868, 234.412],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.167, y: 0.167 },
                        t: 46,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [2.438, 24.95],
                              [-11.482, -19.85],
                              [22.043, 16.59],
                            ],
                            o: [
                              [0, 0],
                              [-1.307, -13.381],
                              [13.851, 23.945],
                              [-15.216, -11.37],
                            ],
                            v: [
                              [427.411, 257.417],
                              [458.837, 266.193],
                              [494.313, 298.371],
                              [486.427, 346.417],
                            ],
                            c: true,
                          },
                        ],
                      },
                      {
                        t: 59,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [-15.219, -5.409],
                              [1.959, -23.511],
                              [22.008, -0.67],
                            ],
                            o: [
                              [0, 0],
                              [15.509, 5.512],
                              [-1.526, 18.316],
                              [-21.542, 0.656],
                            ],
                            v: [
                              [519.158, 6.464],
                              [561.156, -16.681],
                              [592.242, 22.017],
                              [548.085, 40.63],
                            ],
                            c: true,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.6862745098039216, 0.4588235294117647,
                      0.25882352941176473, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [-117.616, -70.044], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 25, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Rectangle 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-36.758, -132.576],
                              [-58.354, -17.072],
                              [-39.352, 112.085],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-31.691, -127.227],
                              [-17.414, 14.384],
                              [9.131, 146.028],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-40, -136],
                              [-40.096, 1.683],
                              [4.307, 122.857],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 1, y: 0 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-38, -124],
                              [-25, 13],
                              [10.5, 147.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 59,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-36.758, -132.576],
                              [-58.354, -17.072],
                              [-39.352, 112.085],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ind: 1,
                  ty: "sh",
                  ix: 2,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-106.995, -185.109],
                              [-250.222, -177.08],
                              [-345.789, -74.055],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 0.333, y: 0 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-102, -171],
                              [-232, -156],
                              [-301, -25.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 20,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-103.265, -185.085],
                              [-238.515, -146.328],
                              [-322.652, -13.617],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        i: { x: 0.833, y: 0.833 },
                        o: { x: 1, y: 0 },
                        t: 36,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-99, -182],
                              [-233, -173],
                              [-301, -25.5],
                            ],
                            c: false,
                          },
                        ],
                      },
                      {
                        t: 59,
                        s: [
                          {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-106.995, -185.109],
                              [-250.222, -177.08],
                              [-345.789, -74.055],
                            ],
                            c: false,
                          },
                        ],
                      },
                    ],
                    ix: 2,
                  },
                  nm: "Path 2",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.9294117647058824, 0.7137254901960784,
                      0.21568627450980393, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 63, ix: 5 },
                  lc: 2,
                  lj: 2,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "tr",
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [-238, 41],
                    to: [5.167, 5.167],
                    ti: [-9.833, 2.5],
                  },
                  {
                    i: { x: 0.833, y: 0.899 },
                    o: { x: 0.333, y: 0 },
                    t: 9,
                    s: [-207, 72],
                    to: [9.833, -2.5],
                    ti: [-8.833, 7.667],
                  },
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.167, y: 0.304 },
                    t: 20,
                    s: [-179, 26],
                    to: [8.833, -7.667],
                    ti: [-4.167, 0],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 36,
                    s: [-154, 26],
                    to: [4.167, 0],
                    ti: [14, -2.5],
                  },
                  { t: 59, s: [-238, 41] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [-155, 40], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [23],
                  },
                  {
                    i: { x: [0.833], y: [0.941] },
                    o: { x: [0.333], y: [0] },
                    t: 9,
                    s: [19],
                  },
                  {
                    i: { x: [0], y: [1] },
                    o: { x: [0.167], y: [-0.626] },
                    t: 20,
                    s: [-3],
                  },
                  {
                    i: { x: [0.833], y: [1.559] },
                    o: { x: [0.492], y: [0] },
                    t: 36,
                    s: [0],
                  },
                  {
                    i: { x: [0.441], y: [1] },
                    o: { x: [0.167], y: [0.069] },
                    t: 48,
                    s: [-3.575],
                  },
                  { t: 59, s: [23] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-214, 55],
                          [-147.912, 170.606],
                          [-118, 325],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-191, 70],
                          [-105.912, 160.606],
                          [-118, 325],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-158.5, 19],
                          [-127.912, 190.606],
                          [-118, 325],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-141, 35],
                          [-114.912, 190.606],
                          [-118, 325],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 59,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-214, 55],
                          [-147.912, 170.606],
                          [-118, 325],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 3",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-267, -2],
                          [-292.955, 149.457],
                          [-359, 318.5],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.333, y: 0 },
                    t: 9,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-238, 17],
                          [-262.955, 158.457],
                          [-359.25, 318.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.167, y: 0.167 },
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-214, -3],
                          [-281.955, 161.457],
                          [-359.25, 318.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 36,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-191, 10],
                          [-266.955, 167.457],
                          [-359.25, 318.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                  {
                    t: 59,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-267, -2],
                          [-292.955, 149.457],
                          [-359.25, 318.25],
                        ],
                        c: false,
                      },
                    ],
                  },
                ],
                ix: 2,
              },
              nm: "Path 4",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.1843137254901961, 0.4588235294117647, 0.7647058823529411, 1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 63, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 4",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 87,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 2",
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [0, -24.691, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [22.909, -6.377],
                    [77.16, 15.774],
                    [15.491, 0.719],
                    [-82.627, -11.716],
                  ],
                  o: [
                    [-26.693, -0.514],
                    [-26.53, -5.424],
                    [41.416, 53.805],
                    [144.392, 20.474],
                  ],
                  v: [
                    [101.783, 332.303],
                    [3.087, 394.411],
                    [-72.281, 332.615],
                    [35.855, 442.489],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.4666666666666667, 0.3411764705882353, 0.23529411764705882,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 2",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ind: 2,
              ty: "sh",
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [35.523, 2.783],
                    [39.004, 12.6],
                    [30.319, -15.603],
                    [31.152, -32.226],
                    [17.303, -25.829],
                    [22.909, -6.377],
                    [77.16, 15.774],
                    [15.491, 0.719],
                    [23.577, -11.666],
                    [-191.798, 11.894],
                  ],
                  o: [
                    [0, 0],
                    [-27.889, -2.185],
                    [-22.116, -28.406],
                    [-12.396, 1.187],
                    [-24.825, 4.426],
                    [-10.088, 15.059],
                    [-26.693, -0.514],
                    [-26.53, -5.424],
                    [-198.857, -2.729],
                    [-21.287, 10.533],
                    [258, -16],
                  ],
                  v: [
                    [485.728, 342.025],
                    [440.403, 333.637],
                    [356.057, 272.585],
                    [256.718, 225.48],
                    [197.86, 266.794],
                    [147.512, 312.249],
                    [101.783, 332.303],
                    [3.087, 394.411],
                    [-72.281, 332.615],
                    [-470, 342],
                    [2, 526],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.6862745098039216, 0.4588235294117647, 0.25882352941176473,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 87,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
  chars: [
    {
      ch: "W",
      size: 66,
      style: "Regular",
      w: 94.38,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [1.807, -71.729],
                      [20.557, 0],
                      [30.762, 0],
                      [47.412, -59.473],
                      [63.965, 0],
                      [74.17, 0],
                      [93.018, -71.729],
                      [82.373, -71.729],
                      [68.848, -13.379],
                      [52.637, -71.729],
                      [42.09, -71.729],
                      [25.879, -13.379],
                      [12.354, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "W",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "W",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "O",
      size: 66,
      style: "Regular",
      w: 77.78,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [12.663, 0],
                      [6.184, -8.854],
                      [0, -9.7],
                      [-5.632, -7.031],
                      [-11.035, 0],
                      [-6.38, 8.334],
                      [0, 10.71],
                      [4.752, 6.348],
                    ],
                    o: [
                      [-12.109, 0],
                      [-4.818, 6.902],
                      [0, 10.742],
                      [6.315, 7.292],
                      [11.816, 0],
                      [5.436, -7.096],
                      [0, -9.895],
                      [-6.087, -8.138],
                    ],
                    v: [
                      [38.574, -73.682],
                      [11.133, -60.4],
                      [3.906, -35.498],
                      [12.354, -8.838],
                      [38.379, 2.1],
                      [65.674, -10.4],
                      [73.828, -37.109],
                      [66.699, -61.475],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "O",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [8.561, 0],
                      [4.02, 5.453],
                      [0, 7.943],
                      [-4.688, 5.095],
                      [-7.422, 0],
                      [-4.411, -5.143],
                      [0, -8.43],
                      [3.825, -6.136],
                    ],
                    o: [
                      [-9.017, 0],
                      [-4.021, -5.452],
                      [0, -9.928],
                      [4.688, -5.094],
                      [7.649, 0],
                      [4.411, 5.144],
                      [0, 7.976],
                      [-3.825, 6.136],
                    ],
                    v: [
                      [39.551, -6.592],
                      [19.995, -14.771],
                      [13.965, -34.863],
                      [20.996, -57.397],
                      [39.16, -65.039],
                      [57.251, -57.324],
                      [63.867, -36.963],
                      [58.13, -15.796],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "O",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "O",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "R",
      size: 66,
      style: "Regular",
      w: 72.22,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.986, -0.977],
                      [0, -5.11],
                      [2.653, -1.823],
                      [4.557, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [3.32, 0],
                      [3.711, 1.855],
                      [0, 4.753],
                      [-2.654, 1.823],
                      [0, 0],
                    ],
                    v: [
                      [18.506, -38.867],
                      [18.506, -63.379],
                      [42.236, -63.379],
                      [50.195, -61.914],
                      [55.762, -51.465],
                      [51.782, -41.602],
                      [40.967, -38.867],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-1.855, -0.879],
                      [-0.228, -4.688],
                      [0, 0],
                      [-0.195, -0.977],
                      [-0.326, -0.618],
                      [0, 0],
                      [0, 0],
                      [0.586, 2.279],
                      [0.098, 2.734],
                      [0, 0],
                      [1.416, 1.855],
                      [2.799, 1.14],
                      [-1.775, 2.734],
                      [0, 4.297],
                      [6.738, 3.06],
                      [5.469, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.971, 0],
                      [3.255, 1.595],
                      [0, 0],
                      [0.13, 2.8],
                      [0.195, 0.977],
                      [0, 0],
                      [0, 0],
                      [-1.498, -0.651],
                      [-0.359, -1.334],
                      [0, 0],
                      [-0.163, -4.166],
                      [-1.416, -1.855],
                      [3.19, -1.66],
                      [1.774, -2.734],
                      [0, -8.235],
                      [-3.548, -1.595],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.789, 0],
                      [18.506, 0],
                      [18.506, -30.762],
                      [40.625, -30.762],
                      [49.365, -29.443],
                      [54.59, -20.02],
                      [55.176, -8.057],
                      [55.664, -2.393],
                      [56.445, 0],
                      [68.359, 0],
                      [68.359, -1.611],
                      [65.234, -6.006],
                      [64.551, -12.109],
                      [64.209, -21.729],
                      [61.841, -30.762],
                      [55.518, -35.254],
                      [62.964, -41.846],
                      [65.625, -52.393],
                      [55.518, -69.336],
                      [41.992, -71.729],
                      [8.789, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "R",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "R",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "K",
      size: 66,
      style: "Regular",
      w: 66.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.617, 0],
                      [17.09, 0],
                      [17.09, -25],
                      [28.564, -35.986],
                      [53.662, 0],
                      [66.309, 0],
                      [35.645, -42.822],
                      [65.479, -71.729],
                      [52.051, -71.729],
                      [17.09, -36.768],
                      [17.09, -71.729],
                      [7.617, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "K",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "K",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: " ",
      size: 66,
      style: "Regular",
      w: 27.78,
      data: {},
      fFamily: "Helvetica",
    },
    {
      ch: "I",
      size: 66,
      style: "Regular",
      w: 27.78,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [9.814, 0],
                      [19.629, 0],
                      [19.629, -71.729],
                      [9.814, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "I",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "I",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "N",
      size: 66,
      style: "Regular",
      w: 72.22,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [7.617, 0],
                      [16.895, 0],
                      [16.895, -58.057],
                      [53.662, 0],
                      [64.551, 0],
                      [64.551, -71.729],
                      [55.322, -71.729],
                      [55.322, -13.623],
                      [19.092, -71.729],
                      [7.617, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "N",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "N",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "P",
      size: 66,
      style: "Regular",
      w: 66.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-3.484, 4.15],
                      [0, 5.599],
                      [3.906, 3.597],
                      [6.38, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [7.227, 0],
                      [3.483, -4.15],
                      [0, -6.51],
                      [-3.906, -3.596],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.545, 0],
                      [18.262, 0],
                      [18.262, -30.322],
                      [40.82, -30.322],
                      [56.885, -36.548],
                      [62.109, -51.172],
                      [56.25, -66.333],
                      [40.82, -71.729],
                      [8.545, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "P",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
              {
                ind: 1,
                ty: "sh",
                ix: 2,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, -5.273],
                      [2.718, -1.855],
                      [4.362, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-2.148, -1.009],
                    ],
                    o: [
                      [0, 4.688],
                      [-2.718, 1.855],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [3.743, 0],
                      [3.906, 1.888],
                    ],
                    v: [
                      [52.295, -51.123],
                      [48.218, -41.309],
                      [37.598, -38.525],
                      [18.262, -38.525],
                      [18.262, -63.379],
                      [37.598, -63.379],
                      [46.436, -61.865],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "P",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "P",
            np: 5,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "G",
      size: 66,
      style: "Regular",
      w: 77.78,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [6.738, 0],
                      [6.184, -6.77],
                      [0, -12.012],
                      [-6.673, -6.901],
                      [-8.496, 0],
                      [-4.265, 2.409],
                      [-3.288, 3.613],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.248, -3.76],
                      [6.477, 0],
                      [3.743, 5.355],
                      [0, 8.529],
                      [-4.541, 4.98],
                      [-6.641, 0],
                      [-3.679, -2.393],
                      [-1.172, -5.241],
                      [0, 0],
                      [7.129, 3.744],
                    ],
                    o: [
                      [-9.831, 0],
                      [-6.511, 7.097],
                      [0, 12.045],
                      [6.12, 5.502],
                      [6.575, 0],
                      [2.539, -1.399],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [-0.098, 8.398],
                      [-4.248, 3.76],
                      [-8.464, 0],
                      [-3.744, -5.354],
                      [0, -9.863],
                      [4.541, -4.98],
                      [5.598, 0],
                      [3.678, 2.393],
                      [0, 0],
                      [-1.595, -9.375],
                      [-4.916, -2.604],
                    ],
                    v: [
                      [38.623, -73.584],
                      [14.6, -63.428],
                      [4.834, -34.766],
                      [14.844, -6.348],
                      [36.768, 1.904],
                      [53.027, -1.709],
                      [61.768, -9.229],
                      [64.111, 0],
                      [70.361, 0],
                      [70.361, -38.379],
                      [38.867, -38.379],
                      [38.867, -30.322],
                      [61.328, -30.322],
                      [54.81, -12.085],
                      [38.721, -6.445],
                      [20.41, -14.478],
                      [14.795, -35.303],
                      [21.606, -57.568],
                      [38.379, -65.039],
                      [52.295, -61.45],
                      [59.57, -50],
                      [69.189, -50],
                      [56.104, -69.678],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "G",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "G",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "E",
      size: 66,
      style: "Regular",
      w: 66.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [8.545, 0],
                      [61.572, 0],
                      [61.572, -8.545],
                      [18.018, -8.545],
                      [18.018, -32.861],
                      [57.617, -32.861],
                      [57.617, -41.162],
                      [18.018, -41.162],
                      [18.018, -62.939],
                      [60.84, -62.939],
                      [60.84, -71.729],
                      [8.545, -71.729],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "E",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "E",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
    {
      ch: "S",
      size: 66,
      style: "Regular",
      w: 66.7,
      data: {
        shapes: [
          {
            ty: "gr",
            it: [
              {
                ind: 0,
                ty: "sh",
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [-5.046, -4.524],
                      [-8.92, 0],
                      [-5.779, 3.418],
                      [0, 7.943],
                      [4.557, 3.353],
                      [4.85, 1.107],
                      [0, 0],
                      [1.92, 1.042],
                      [0, 3.516],
                      [-2.507, 2.409],
                      [-5.73, 0],
                      [-2.962, -3.743],
                      [-0.488, -3.841],
                      [0, 0],
                      [5.257, 3.63],
                      [7.52, 0],
                      [4.459, -4.134],
                      [0, -6.445],
                      [-4.558, -2.995],
                      [-6.348, -1.432],
                      [0, 0],
                      [-2.474, -1.627],
                      [0, -3.906],
                      [5.794, -2.018],
                      [3.645, 0],
                      [3.223, 4.753],
                      [0.227, 4.07],
                    ],
                    o: [
                      [-0.098, 7.748],
                      [5.045, 4.558],
                      [7.649, 0],
                      [5.778, -3.418],
                      [0, -6.38],
                      [-2.637, -1.92],
                      [0, 0],
                      [-6.771, -1.563],
                      [-2.93, -1.627],
                      [0, -3.19],
                      [2.506, -2.409],
                      [7.096, 0],
                      [1.595, 2.051],
                      [0, 0],
                      [0, -7.975],
                      [-5.257, -3.629],
                      [-8.171, 0],
                      [-4.46, 4.134],
                      [0, 5.957],
                      [2.637, 1.726],
                      [0, 0],
                      [5.273, 1.205],
                      [2.441, 1.66],
                      [0, 5.209],
                      [-2.995, 1.042],
                      [-8.138, 0],
                      [-1.693, -2.539],
                      [0, 0],
                    ],
                    v: [
                      [4.834, -23.145],
                      [12.256, -4.736],
                      [33.203, 2.1],
                      [53.345, -3.027],
                      [62.012, -20.068],
                      [55.176, -34.668],
                      [43.945, -39.209],
                      [33.887, -41.504],
                      [20.85, -45.41],
                      [16.455, -53.125],
                      [20.215, -61.523],
                      [32.568, -65.137],
                      [47.656, -59.521],
                      [50.781, -50.684],
                      [59.912, -50.684],
                      [52.026, -68.091],
                      [32.861, -73.535],
                      [13.916, -67.334],
                      [7.227, -51.465],
                      [14.063, -38.037],
                      [27.539, -33.301],
                      [37.256, -31.104],
                      [48.877, -26.855],
                      [52.539, -18.506],
                      [43.848, -7.666],
                      [33.887, -6.104],
                      [16.846, -13.232],
                      [13.965, -23.145],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: "S",
                mn: "ADBE Vector Shape - Group",
                hd: false,
              },
            ],
            nm: "S",
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: "ADBE Vector Group",
            hd: false,
          },
        ],
      },
      fFamily: "Helvetica",
    },
  ],
};
