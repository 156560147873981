import React from "react";

import "./Css.scss";
import LottieIcon from "components/LottieIcon";
import idsEstadoNomina from "constants/enums/RRHH/tblEstadoNomina";

export default class EditcellComponent_idEstadoNomina extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      idEstadoNomina: props.data.value,
      uploading: false,
    };
  }

  render = () => {
    const { enableSend } = this.props.data.data;
    const { idEstadoNomina, uploading } = this.state;
    var status = idEstadoNomina === idsEstadoNomina.enProceso
      ? "danger "
      : "success ";

    const isPointer = [idsEstadoNomina.enProceso, idsEstadoNomina.solicitudCambioRRHH, idsEstadoNomina.validadoRRHH].includes(idEstadoNomina);

    return (
      idEstadoNomina !== null && enableSend
        ? <div
          className={
            "editCellComponent_idEstadoNomina container_spanCentrado " +
            (uploading ? "bg-white" : status)
          }
        >
          {uploading ? (
            <LottieIcon icon={"upload"} isLoop={true} height={35} width={40} />
          ) : (
            <div
              className={"icon icon_Arrow_Down font-size-lg " + status + (isPointer ? "pointer" : "")}
              onClick={isPointer ? this.onClick : undefined}
            />
          )}
        </div>
        : null
    );
  };

  onClick = () => {
    const { idEstadoNomina } = this.state;
    if (idEstadoNomina != null) {
      this.setState({ uploading: true });
    }
  };
}
