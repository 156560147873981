import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import AdjuntarArchivos from "components/AdjuntarArchivos/AdjuntarArchivos";

const GestionDocumentos = forwardRef(({ carpetaDocumentos, setCarpetas, setDocumentos }, ref) => {
  const [documentosSubidos, setDocumentosSubidos] = useState([]);
  const accionesModificarDatos = useRef([]);

  useImperativeHandle(ref, () => ({
    clearComponent,
  }));

  const clearComponent = () => {
    setDocumentosSubidos([]);
    setAccionesModificarDatos([]);
  }

  const setAccionesModificarDatos = (acciones) => {
    accionesModificarDatos.current = acciones; 
  }

  const updateArchivos_ImportarDocumentos = (data, accionesModificarDatos) => {
    let documentosOtrasSubCarpetasNCarpetaSel = [];
    setAccionesModificarDatos(accionesModificarDatos);
    if (data.length === 1 && Array.isArray(data[0])) {
      data = data[0];
    }
    if(carpetaDocumentos.idCarpetaDocumentos != null){
      const documentosOtrasCarpetas = documentosSubidos.filter((documento) => {
        return documento.idPadre !== carpetaDocumentos?.idCarpetaDocumentos;
      });

      if ('subCarpeta' in carpetaDocumentos && carpetaDocumentos.subCarpeta !== null) {
        documentosOtrasSubCarpetasNCarpetaSel = documentosSubidos.filter(
          (documento) => {
            if (documento.idPadre == carpetaDocumentos?.idCarpetaDocumentos) {
              return documento.subCarpeta !== carpetaDocumentos?.subCarpeta && documento?.id.valueOf() !== data[data.length - 1]?.id.valueOf();
            }
          }
        );
      }
      setDocumentosSubidos([
        ...documentosOtrasCarpetas,
        ...documentosOtrasSubCarpetasNCarpetaSel,
        ...data,
      ]); //provoca que se actualice este componente
      setDocumentos([
        ...documentosOtrasCarpetas,
        ...documentosOtrasSubCarpetasNCarpetaSel,
        ...data,
      ]); //No provoca que se renderice todo el popup, pero deja los documentos accesibles en el componente padre

      const lastDocAdded = data[data.length - 1];
      if(lastDocAdded != null /*&&  lastDocAdded.subCarpeta !== null */) {
        if(lastDocAdded.subCarpeta === undefined || lastDocAdded.subCarpeta === "null"){
          lastDocAdded.subCarpeta = null;
        }
        const subCarpetaObjetivo = lastDocAdded.subCarpeta == null || lastDocAdded.subCarpeta == "undefined"  ? "null" : lastDocAdded.subCarpeta
        const subCarpeta = documentosOtrasSubCarpetasNCarpetaSel.length > 0 ? subCarpetaObjetivo : lastDocAdded.subCarpeta;
        setCarpetas((prevState) => {
          return {...prevState, subCarpetaObjetivo: subCarpetaObjetivo, subCarpeta: subCarpeta};
        })
      }
    } else {
      setDocumentosSubidos(data); //no es necesario renderizar el padre pero si este componente
      setDocumentos(data); 
    }
  };

  const showDocumentosNCarpeta = () => {
    const documentosFiltrados = documentosSubidos.filter((documento) => {
      return documento.idPadre === carpetaDocumentos?.idCarpetaDocumentos;
    });
    if (carpetaDocumentos?.subCarpeta) {
      return documentosFiltrados.filter((documento) => {
        return documento.subCarpeta === carpetaDocumentos?.subCarpeta;
      });
    }
    return documentosFiltrados;
  };

  const getSubCarpetas = () => {
    const documentosNCarpeta = documentosSubidos.filter((documento) => {
      return documento.idPadre === carpetaDocumentos?.idCarpetaDocumentos;
    });
    let subCarpetas = [];
    Object.entries(
      Object.groupBy(documentosNCarpeta, ({ subCarpeta }) => subCarpeta)
    )
      .reverse()
      .forEach(([carpeta, documentos], index) => {
        if(carpeta !== "null" && carpeta !== "undefined" && carpeta !== null && carpeta !== undefined) {
          subCarpetas.push({
            idSubCarpeta: index, // no deberia hacer falta pero lo pide el componente
            denominacion: carpeta, // no deberia hacer falta pero lo pide el componente
            subCarpeta: carpeta,
          });
        }
      });
    return subCarpetas;
  }

  return (
    <div
        style={{
          overflowY: "hidden",
          height: "100%",
          flexDirection: "column",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <AdjuntarArchivos
          isEditAllowed={false}
          isRemoveAllowed={true}
          isUploadAllowed={carpetaDocumentos?.idCarpetaDocumentos ? true : false}
          data={
            carpetaDocumentos?.idCarpetaDocumentos
              ? showDocumentosNCarpeta()
              : documentosSubidos
          }
          updateArchivos={updateArchivos_ImportarDocumentos}
          accionesModificarDatos={accionesModificarDatos.current}
          idPadreSel={carpetaDocumentos?.idCarpetaDocumentos}
          tipoArchivos={"documentos"}
          documentosNGrandParent={null}
          isVisibleRequerirDocumento={true}
          isMultipleLoadFile={true}
          isDropZone={true}
          showProgressBar={true}
          tieneCabecera={false}
          numItemPerRow={2}
          showLoading_adjuntarArchivos={false}
          subcarpetas={getSubCarpetas()}
        />
    </div>
  );
});

export default GestionDocumentos;
