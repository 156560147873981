import { impresionConstants } from "../constants";

const initialState = { opened: false, report: "", params: {} };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case impresionConstants.SET_IMPRESION_REPORT_SUCCESS:
      return {
        ...state,
        report: action.report,
        params: {},
      };
    case impresionConstants.SET_IMPRESION_PARAMS_SUCCESS:
      return {
        ...state,
        opened: true,
        params: action.params,
      };
    case impresionConstants.SET_IMPRESION_CLOSE_SUCCESS:
      return {
        ...state,
        opened: false,
      };
    default:
      return state;
  }
}
