import $ from "jquery";

export function getAplicaciones(lavanderia) {
  // MUESTRO APLICACIONES EN FUNCIÓN DE LOS FORMULARIOS VISIBLES FILTRADOS POR LAVANDERÍA.
  const aplicaciones = JSON.parse(localStorage.getItem("aplicaciones"));

  //#region ESTRUCTURA MENÚ -> NO LO COJO A TRAVES DE getEstructura() PORQUE ESTE FILTRA LAS APPS POR FORMS VISIBLES.
  const estructuraMenu = JSON.parse(localStorage.getItem("estructuraMenu"));
  if (estructuraMenu && Object.keys(estructuraMenu).length > 0) {
    //Filtro lavanderia
    for (let apartado in estructuraMenu) {
      estructuraMenu[apartado].content = estructuraMenu[apartado].content.filter(function f(item) {
        if (estructuraMenu[apartado].idApartado == 20) return false;
        if (lavanderia) {
          if (lavanderia.visiblePages.includes(item.idFormulario)) return true;
        } else {
          return true;
        }
        if (item.content) {
          return (item.content = item.content.filter(f)).length;
        }

        return false;
      });

      if (estructuraMenu[apartado].content.length === 0) {
        delete estructuraMenu[apartado];
      }
    }
  }

  // OBTENER idsAplicacion DE FORMULARIOS VISIBLES
  let idsAplicacion = $.map(estructuraMenu, function mapper(obj) {
    return obj["idAplicacion"] ? obj.idAplicacion : $.map(obj.content, mapper);
  });

  let idsAplicacion_agrupados = idsAplicacion.filter(function (itm, i, idsAplicacion) {
    return i == idsAplicacion.indexOf(itm) || itm == null;
  });

  let aplicaciones_filtradas;
  if (aplicaciones) {
    aplicaciones_filtradas = $.grep(aplicaciones, function (item) {
      return $.inArray(item.idAplicacion, idsAplicacion_agrupados) >= 0;
    });
  }

  return aplicaciones_filtradas && aplicaciones_filtradas.length > 0 ? aplicaciones_filtradas : aplicaciones;
}
