import React from "react";

import { connectionConstants } from "../../../../../constants";

import { errorHandler, authHeader, getTrad } from "../../../../../helpers";

import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import {
  Popup,
  ToolbarItem as ToolbarItem_popup,
} from "devextreme-react/popup";
import Form, { Item } from "devextreme-react/form";
import { TextBox, NumberBox } from "devextreme-react";
import { Button } from "devextreme-react/button";

import Validator, { RequiredRule } from "devextreme-react/validator";
import validationEngine from "devextreme/ui/validation_engine";

export default class PopupAddCategoria extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      dxForm_formDataCategoria: {},
    };

    this.dxForm_nuevaCategoria_REF = React.createRef();

    this.onShown_popup_categoria = this.onShown_popup_categoria.bind(this);
    this.onHiding_popup_categoria = this.onHiding_popup_categoria.bind(this);
    this.onHidden_popup_categoria = this.onHidden_popup_categoria.bind(this);
    this.dxFormItem_render = this.dxFormItem_render.bind(this);
    this.dxFormEditor_onValueChanged =
      this.dxFormEditor_onValueChanged.bind(this);
    this.dxButton_popupCategoria_guardar_onClick =
      this.dxButton_popupCategoria_guardar_onClick.bind(this);
    this.dxButton_popupCategoria_cancelar_onClick =
      this.dxButton_popupCategoria_cancelar_onClick.bind(this);

    this.getTrad = this.getTrad.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxForm_nuevaCategoria() {
    return this.dxForm_nuevaCategoria_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaInterna = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaInterna",
      key: "idCategoriaInterna",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaInterna_beforeSend(request);
      },
      version: 4,
    }),
  });

  datasource_tblCategoriaInterna_beforeSend(request) {
    request.headers = { ...authHeader() };
  }
  //#endregion

  render() {
    let { dxForm_formDataCategoria } = this.state;
    let { isVisible_popup_showCategoria } = this.props;

    return (
      <Popup
        title={"Nueva categoría"}
        showTitle={true}
        width="60%"
        maxWidth={650}
        height={350}
        deferRendering={false}
        visible={isVisible_popup_showCategoria}
        closeOnOutsideClick={false}
        onShown={this.onShown_popup_categoria}
        onHiding={this.onHiding_popup_categoria}
        onHidden={this.onHidden_popup_categoria}
      >
        <Form
          ref={this.dxForm_nuevaCategoria_REF}
          formData={dxForm_formDataCategoria}
          deferRendering={false}
          labelLocation="top"
          colCount={2}
          height="100%"
          width="100%"
        >
          <Item
            dataField="denominacion"
            label={this.formLabel_denominacion}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="salarioBase"
            label={this.formLabel_salarioBase}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="plusAsistencia"
            label={this.formLabel_plusAsistencia}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="plusResponsabilidad"
            label={this.formLabel_plusResponsabilidad}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="plusPeligrosidad"
            label={this.formLabel_plusPeligrosidad}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="incentivo"
            label={this.formLabel_incentivo}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="impHoraExtra"
            label={this.formLabel_impHoraExtra}
            render={this.dxFormItem_render}
          />
          <Item
            dataField="plusProductividad"
            label={this.formLabel_plusProductividad}
            render={this.dxFormItem_render}
          />
        </Form>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("guardar")}
            type="success"
            onClick={this.dxButton_popupCategoria_guardar_onClick}
          />
        </ToolbarItem_popup>
        <ToolbarItem_popup toolbar="bottom" location="after" widget="dxButton">
          <Button
            text={this.getTrad("cancelar")}
            type="normal"
            onClick={this.dxButton_popupCategoria_cancelar_onClick}
          />
        </ToolbarItem_popup>
      </Popup>
    );
  }

  formLabel_denominacion = {
    text: this.getTrad("denominacion"),
    visible: false,
  };
  formLabel_salarioBase = { text: "Salario base", visible: false };
  formLabel_plusAsistencia = {
    text: this.getTrad("plusAsistencia"),
    visible: false,
  };
  formLabel_plusResponsabilidad = {
    text: this.getTrad("plusResponsabilidad"),
    visible: false,
  };
  formLabel_plusPeligrosidad = {
    text: this.getTrad("plusPeligrosidad"),
    visible: false,
  };
  formLabel_incentivo = { text: this.getTrad("incentivo"), visible: false };
  formLabel_impHoraExtra = {
    text: this.getTrad("impHoraExtra"),
    visible: false,
  };
  formLabel_plusProductividad = {
    text: this.getTrad("plusProductividad"),
    visible: false,
  };

  dxFormItem_render(data) {
    if (data == null) return null;
    let formLabel = this["formLabel_" + data.dataField.replace(".", "_")];
    let isDatosSalariales = data.dataField != "denominacion";
    let isRequired = data.dataField == "denominacion";

    return (
      <div className="d-flex flex-row align-items-center">
        <div className="flex-row d-flex" style={{ flex: 6, paddingRight: 0 }}>
          <div className="pr-3 pe-none ">
            {formLabel.text}
            <span className="dx-field-item-required-mark">
              {isRequired && " *"}
            </span>
          </div>
        </div>
        <div
          style={{ flex: 7, height: 38 }}
          className="ml-auto d-flex justify-content-end "
        >
          {isDatosSalariales ? (
            <NumberBox
              name={data.dataField}
              stylingMode="underlined"
              step={0}
              min={0}
              max={999999999999}
              format={{
                style: "currency",
                maximumFractionDigits: 2,
                currency: "EUR",
              }}
              width="100%"
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              <Validator validationGroup={"validationCategoria"}>
                {isRequired && <RequiredRule />}
              </Validator>
            </NumberBox>
          ) : (
            <TextBox
              name={data.dataField}
              width="100%"
              stylingMode="underlined"
              className={"textUpperCase dxForm_denominacion_editor"}
              onContentReady={this.dxFormEditor_onContentReady}
              onValueChanged={this.dxFormEditor_onValueChanged}
            >
              <Validator validationGroup={"validationCategoria"}>
                {isRequired && <RequiredRule />}
              </Validator>
            </TextBox>
          )}
        </div>
      </div>
    );
  }

  dxFormEditor_onContentReady(e) {
    $(e.element).find("input").css({
      "text-align": "end",
      "font-weight": 500,
    });

    setTimeout(() => {
      $(e.element).find(".dx-selectbox-container .dx-texteditor-input").css({
        "font-weight": 500,
        "text-align": "start",
      });
    }, 0);
  }

  dxFormEditor_onValueChanged(e) {
    let dxForm_formDataCategoria = { ...this.state.dxForm_formDataCategoria };
    let dataField = e.component.option("name");

    dxForm_formDataCategoria[dataField] = e.value;

    this.setState({
      dxForm_formDataCategoria: dxForm_formDataCategoria,
    });
  }

  dxButton_popupCategoria_guardar_onClick(e) {
    let isValid = validationEngine.validateGroup("validationCategoria").isValid;
    if (isValid) {
      let _this = this;
      let { idCategoriaConvenioSel, isOficina, idPaisSel } = this.props;
      let dxForm_formDataCategoria = { ...this.state.dxForm_formDataCategoria };

      dxForm_formDataCategoria.idCategoriaConvenio = idCategoriaConvenioSel;
      dxForm_formDataCategoria.isOficina = isOficina;
      dxForm_formDataCategoria.idPais = idPaisSel;

      this.datasource_tblCategoriaInterna
        .store()
        .insert(dxForm_formDataCategoria)
        .done(function () {
          _this.props.onHiding(true);
          _this.props.onModifyGrid(idCategoriaConvenioSel);
        });
    }
  }

  dxButton_popupCategoria_cancelar_onClick(e) {
    this.props.onHiding();
  }

  onShown_popup_categoria(e) {
    $(".dxForm_denominacion_editor").dxTextBox("instance").focus();
  }

  onHiding_popup_categoria(e) {
    this.props.onHiding();
  }

  onHidden_popup_categoria(e) {
    this.setState({ dxForm_formDataCategoria: {} });
    this.dxForm_nuevaCategoria.repaint();
  }
}
