import React, { useEffect, useState } from "react";

import Tooltip from "devextreme-react/tooltip";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "constants";
import { errorHandler, authHeader, getTrad, capitalize } from "helpers";

const idsEstado = {
  trabajado: 3,
  festivoTrabajado: 4,
  festivo: 8,
  cierre: 10,
};

const TooltipDayEvents = ({ tooltipTarget, targetEvents }) => {
  const [tblLavanderia, setTblLavanderia] = useState([]);
  const [tblTurno, setTblTurno] = useState([]);
  const [tblCalendarioPersonal_estado, setTblCalendarioPersonal_estado] = useState([]);

  useEffect(() => {
    dataSource_tblLavanderia.load().done(setTblLavanderia);
    dataSource_tblTurno.load().done(setTblTurno);
    datasource_tblCalendarioPersonal_estado.load().done(setTblCalendarioPersonal_estado);
  }, []);

  const dataSource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblLavanderia",
      errorHandler: error => errorHandler(error, null),
      beforeSend: request => request.headers = { ...authHeader() },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion"],
    sort: ["denominacion"],
  });

  const dataSource_tblTurno = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTurno",
      errorHandler: error => errorHandler(error, null),
      beforeSend: request => request.headers = { ...authHeader() },
      version: 4,
    }),
    filter: [["activo", "=", true], "and", ["eliminado", "=", false]],
    select: ["idTurno", "denominacion", "idLavanderia", "horaEntrada", "horaSalida"],
    sort: ["denominacion"],
  });

  const datasource_tblCalendarioPersonal_estado = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCalendarioPersonal_estado",
      key: "idEstado",
      errorHandler: error => errorHandler(error, null),
      beforeSend: request => request.headers = { ...authHeader() },
      version: 4,
    }),
  });


  const onContentReady = ({ component }) => {
    component
      .content()
      .css("padding", "0")
      .parent()
      .css("border-radius", "15px");
  };

  const formateadorHora = hora => {
    const partes = hora ? hora.split(":") : ["HH", "MM"];
    return `${partes[0]}:${partes[1]}`;
  };

  const getIsDiaTrabajado = idEstado => (idEstado === idsEstado.trabajado || idEstado === idsEstado.festivoTrabajado);

  const getIsDiaFestivo_Cierre = idEstado => (idEstado === idsEstado.festivo || idEstado === idsEstado.cierre);

  const getTradEstado = idEstado => (getTrad(tblCalendarioPersonal_estado.find(cpe => cpe.idEstado === idEstado)?.traduccion));

  const getTargetEventStyle = backgroundColor => ({ backgroundColor });

  const getDenoLavanderia = idLavanderia => (tblLavanderia.find(l => l.idLavanderia === idLavanderia)?.denominacion);

  const renderDiaTrabajado = (e, tradEstado) => (
    <div className={"d-flex flex-column align-items-center w-100"}>
      <span className={"underline_bottom_dotted pb-2 mb-2"}>{getDenoLavanderia(e.idLavanderia)}</span>
      <div className={"d-flex flex-column align-items-start"}>
        <span>{tradEstado}</span>
        <span>{capitalize(tblTurno.find(t => t.idTurno === e?.idTurno)?.denominacion, true)}</span>
        <span>{`${formateadorHora(e?.horaEntrada)} - ${formateadorHora(e?.horaSalida)}`}</span>
      </div>
    </div>
  );

  const renderDiaFestivo_Cierre = (idLavanderia, tradEstado) => (
    <div className={"d-flex flex-column align-items-center w-100"}>
      <span className={"underline_bottom_dotted pb-2 mb-2"}>{getDenoLavanderia(idLavanderia)}</span>
      <div className={"d-flex flex-column align-items-start"}>
        <span>{tradEstado}</span>
      </div>
    </div>
  );

  const renderGenerico = tradEstado => (<span>{tradEstado}</span>);

  const content_render = () => {
    if (targetEvents !== null) {
      return targetEvents.map((te, index) => {
        if (te.errorMultipleEstados) {
          return (
            <div
              className={"tooltipEvento bg-danger p-2"}
            >
              <div
                className={"bg-danger px-3 pt-2 pb-3 w-100"}
              >
                <span className={"color-white w-100"}>{getTrad("errorMultiplesEventosDia")}</span>
              </div>
              <div className={"d-flex aling-items-center flex-column p-2 w-100 bg-white"} style={{ borderRadius: 10 }}>
                {te.eventos.map((e, i) => {
                  const tradEstado = getTradEstado(e.idEstado);

                  return (
                    <div
                      key={`targetEvent-${index}-${i}`}
                      className={"tooltipEvento p-3"}
                      style={getTargetEventStyle(e.colorHexa)}
                    >
                      {getIsDiaTrabajado(e.idEstado)
                        ? renderDiaTrabajado(e, tradEstado)
                        : renderGenerico(tradEstado)
                      }
                    </div>
                  )
                })}
              </div>
            </div>
          );
        } else {
          const eventos = te.eventos.filter(eh => eh.idEstado === te.idEstado);

          return (
            <div className={eventos.length > 1 ? "p-2" : ""}>
              {eventos.map((e, i) => {
                const tradEstado = getTradEstado(e.idEstado);
                const containerEventoStyle = { minWidth: 200, ...getTargetEventStyle(e.colorHexa) };

                return (
                  <div
                    key={`targetEvent-${index}-${i}`}
                    className={"tooltipEvento p-3"}
                    style={containerEventoStyle}
                  >
                    {getIsDiaTrabajado(e.idEstado)
                      ? renderDiaTrabajado(e, tradEstado)
                      : getIsDiaFestivo_Cierre(e.idEstado)
                        ? renderDiaFestivo_Cierre(e.idLavanderia, tradEstado)
                        : renderGenerico(tradEstado)
                    }
                  </div>
                )
              })}
            </div>
          );
        }
      });
    }

    return (
      <div className={"tooltipEvento px-5 py-3"}>
        <span>{getTrad("sinPrevisionesDia")}</span>
      </div>
    );
  };

  return (
    <Tooltip
      target={tooltipTarget}
      visible={tooltipTarget !== null}
      onContentReady={onContentReady}
    >
      <div>{content_render()}</div>
    </Tooltip>
  );
};

export default TooltipDayEvents;
