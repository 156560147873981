import React, { useState, useEffect } from "react";

import notify from "devextreme/ui/notify";
import Button from "devextreme-react/button";
import LoadPanel from "devextreme-react/load-panel";
import Popup, { ToolbarItem } from "devextreme-react/popup";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { connectionConstants } from "constants";
import AdjuntarArchivos from "components/AdjuntarArchivos/AdjuntarArchivos";
import { errorHandler, authHeader, getTrad, dxMensajePregunta } from "helpers";

const PopupDocumentacion = ({
  visible = false,
  idInventarioSel = null,
  onHiding,
}) => {
  const [tblInventario_Documento, setTblInventario_Documento] = useState([]);
  const [showLoading_adjuntarArchivos, setShowLoading_adjuntarArchivos] =
    useState(null);
  const [
    showLoading_tblInventario_Documento,
    setShowLoading_tblInventario_Documento,
  ] = useState(false);
  const [
    accionesModificarDatos_fileUploader,
    setAccionesModificarDatos_fileUploader,
  ] = useState([]);

  useEffect(() => {
    setTblInventario_Documento([]);
  }, [idInventarioSel]);

  useEffect(() => {
    if (visible) {
      setShowLoading_adjuntarArchivos(null);
      setAccionesModificarDatos_fileUploader([]);
      load_tblInventario_Documento();
    }
  }, [visible]);

  useEffect(() => {
    if (showLoading_adjuntarArchivos === false)
      setShowLoading_adjuntarArchivos(null);
  }, [showLoading_adjuntarArchivos]);

  const onHiding_PopupDocumentacion = (e) => {
    e.cancel = true;

    if (accionesModificarDatos_fileUploader.length > 0) {
      dxMensajePregunta(getTrad("preg_PerderCambios"), [
        [getTrad("aceptar"), onHiding, "danger"],
        [getTrad("cancelar"), () => {}],
      ]);
    } else {
      e.cancel = false;
      onHiding();
    }
  };

  const onSave_tblInventario_Documento = () => {
    accionesModificarDatos_fileUploader.forEach((x) => {
      if (x.action === "delete") {
        datasource_tblInventario_Documento.store().remove(x.id);
      } else if (x.action === "insert") {
        const documento = {
          nombre: x.data.nombre,
          documento: x.data.documento,
          extension: x.data.extension,
          idInventario: idInventarioSel,
        };

        datasource_tblInventario_Documento.store().insert(documento);
      }
    });

    if (accionesModificarDatos_fileUploader.length > 0) {
      notify({
        message: getTrad("aviso_C_RegistroActualizado"),
        type: "success",
        displayTime: 1500,
        closeOnClick: true,
      });
      setAccionesModificarDatos_fileUploader([]);
    }

    onHiding();
  };

  const load_tblInventario_Documento = () => {
    if (idInventarioSel)
      datasource_tblInventario_Documento
        .load()
        .done(setTblInventario_Documento);
  };

  const datasource_tblInventario_Documento_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    if (request.method === "get") request.params.idInventario = idInventarioSel;
  };

  const datasource_tblInventario_Documento_postProcess = (data) =>
    data.map((item) => ({ ...item, id: item.idDocumento }));

  const datasource_tblInventario_Documento_onLoading = () => {
    setShowLoading_tblInventario_Documento(true);
  };

  const datasource_tblInventario_Documento_onLoaded = () => {
    setShowLoading_tblInventario_Documento(false);
  };

  const datasource_tblInventario_Documento = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL + "tblInventario_Documento",
      key: "idDocumento",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: datasource_tblInventario_Documento_beforeSend,
      onLoading: datasource_tblInventario_Documento_onLoading,
      onLoaded: datasource_tblInventario_Documento_onLoaded,
      version: 4,
    }),
    postProcess: datasource_tblInventario_Documento_postProcess,
    onInserted: load_tblInventario_Documento,
    onRemoved: load_tblInventario_Documento,
  });

  const updateArchivos = (data, accionesModificarDatos) => {
    setTblInventario_Documento(data);
    setAccionesModificarDatos_fileUploader(accionesModificarDatos);
    setShowLoading_adjuntarArchivos(false);
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding_PopupDocumentacion}
      title={getTrad("documentacion")}
    >
      <LoadPanel
        shadingColor={"transparent"}
        deferRendering={false}
        animation={null}
        visible={showLoading_tblInventario_Documento}
        showIndicator={true}
        shading={true}
        showPane={true}
      />
      <AdjuntarArchivos
        acceptedTypes={"*"}
        allowedFileExtensions={[]}
        isRemoveAllowed
        data={tblInventario_Documento}
        idPadreSel={null}
        tipoArchivos={"fotos"}
        isMultipleLoadFile
        isVisibleRequerirDocumento={false}
        documentosNGrandParent={null}
        isDropZone
        accionesModificarDatos={accionesModificarDatos_fileUploader}
        numItemPerRow={5}
        tieneCabecera={false}
        showLoading_adjuntarArchivos={showLoading_adjuntarArchivos}
        updateArchivos={updateArchivos}
      />
      <ToolbarItem toolbar={"bottom"} location={"after"}>
        <Button
          text={getTrad("guardar")}
          type={"success"}
          onClick={onSave_tblInventario_Documento}
        />
      </ToolbarItem>
    </Popup>
  );
};

export default PopupDocumentacion;
