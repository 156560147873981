export const pdffile = {
  v: "5.9.5",
  fr: 60,
  ip: 0,
  op: 321,
  w: 1000,
  h: 1000,
  nm: "PDF",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Trigger",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 3,
      nm: "Out Animation",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0], y: [1] }, o: { x: [0.333], y: [0] }, t: 250, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [1], y: [0] }, t: 270, s: [5] },
            { t: 310, s: [-25] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
        a: { a: 0, k: [50, 50, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [100, 100, 100],
            },
            {
              i: { x: [0, 0, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 250,
              s: [100, 100, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [1, 1, 0.333], y: [0, 0, 0] },
              t: 270,
              s: [110, 110, 100],
            },
            { t: 310, s: [0, 0, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      ip: 0,
      op: 600,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Layer 2 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 788.783, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 788.783, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 75,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 115,
              s: [103, 100, 100],
            },
            { t: 135, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-215.577, 6.866],
                    [215.577, 6.866],
                    [215.577, -6.865],
                    [-215.577, -6.865],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [495.25, 788.783], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Layer 3 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 460.467, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 460.467, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 55,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 95,
              s: [103, 100, 100],
            },
            { t: 115, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-215.577, 6.866],
                    [215.577, 6.866],
                    [215.577, -6.866],
                    [-215.577, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [495.25, 460.467], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Layer 4 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 420.832, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 420.832, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 50,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 90,
              s: [103, 100, 100],
            },
            { t: 110, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-215.577, 6.866],
                    [215.577, 6.866],
                    [215.577, -6.866],
                    [-215.577, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [495.25, 420.832], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Layer 5 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 381.198, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 381.198, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 45,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 85,
              s: [103, 100, 100],
            },
            { t: 105, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-215.577, 6.865],
                    [215.577, 6.865],
                    [215.577, -6.865],
                    [-215.577, -6.865],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [495.25, 381.198], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Layer 6 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 341.565, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 341.565, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 80,
              s: [103, 100, 100],
            },
            { t: 100, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-215.577, 6.866],
                    [215.577, 6.866],
                    [215.577, -6.866],
                    [-215.577, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [495.25, 341.565], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: "Layer 7 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 302.803, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 302.803, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 35,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 75,
              s: [103, 100, 100],
            },
            { t: 95, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-144.42, 6.866],
                    [144.42, 6.866],
                    [144.42, -6.866],
                    [-144.42, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [424.093, 302.803], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: "Layer 8 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 263.168, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 263.168, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 30,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 70,
              s: [103, 100, 100],
            },
            { t: 90, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-144.42, 6.866],
                    [144.42, 6.866],
                    [144.42, -6.866],
                    [-144.42, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [424.093, 263.168], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: "Layer 9 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 223.534, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 223.534, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 25,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 65,
              s: [103, 100, 100],
            },
            { t: 85, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-144.42, 6.865],
                    [144.42, 6.865],
                    [144.42, -6.865],
                    [-144.42, -6.865],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [424.093, 223.534], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: "Layer 10 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [279.673, 183.902, 0], ix: 2, l: 2 },
        a: { a: 0, k: [279.673, 183.902, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 20,
              s: [0, 100, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 60,
              s: [103, 100, 100],
            },
            { t: 80, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-144.42, 6.866],
                    [144.42, 6.866],
                    [144.42, -6.866],
                    [-144.42, -6.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.736999990426, 0.745000023935, 0.752999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [424.093, 183.902], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "Layer 11 Outlines",
      parent: 13,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [499.627, 624.625, 0], ix: 2, l: 2 },
        a: { a: 0, k: [499.627, 624.625, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 65,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 105,
              s: [103, 103, 100],
            },
            { t: 125, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-25.141, -52.36],
                    [-25.141, -10.387],
                    [42.632, -10.387],
                    [42.632, 13.447],
                    [-25.141, 13.447],
                    [-25.141, 76.415],
                    [-50.941, 76.415],
                    [-50.941, -76.415],
                    [50.94, -76.415],
                    [50.726, -52.358],
                    [-25.141, -52.358],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.882000014361, 0.902000038297, 0.913999968884, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [659.887, 624.625], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 17.205],
                    [40.086, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-9.841, 8.826],
                  ],
                  o: [
                    [0, -34.979],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [18.505, 0],
                    [9.838, -8.815],
                  ],
                  v: [
                    [42.854, -0.111],
                    [-17.267, -52.583],
                    [-43.068, -52.583],
                    [-43.068, 52.145],
                    [-14.421, 52.145],
                    [28.104, 38.917],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.714, -13.337],
                    [0, -23.762],
                    [14.288, -13.843],
                    [29.429, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [14.733, 13.335],
                    [0, 23.753],
                    [-14.287, 13.851],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [27.4, 0],
                  ],
                  v: [
                    [46.786, -56.407],
                    [68.867, -0.76],
                    [47.444, 55.642],
                    [-18.139, 76.415],
                    [-68.867, 76.415],
                    [-68.867, -76.415],
                    [-16.396, -76.415],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            { ty: "mm", mm: 1, nm: "Merge Paths 1", mn: "ADBE Vector Filter - Merge", hd: false },
            {
              ty: "fl",
              c: { a: 0, k: [0.882000014361, 0.902000038297, 0.913999968884, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [506.843, 624.625], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-5.178, 5.463],
                    [0, 10.569],
                    [6.567, 4.378],
                    [14.003, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [5.177, -5.473],
                    [0, -10.559],
                    [-6.566, -4.368],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [14.288, 0.003],
                  ],
                  v: [
                    [25.365, 0.005],
                    [33.123, -24.052],
                    [23.282, -46.462],
                    [-7.552, -53.019],
                    [-33.778, -53.019],
                    [-33.778, 8.196],
                    [-3.832, 8.196],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-10.638, -8.898],
                    [0, -18.512],
                    [10.872, -8.601],
                    [22.438, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [10.641, 8.887],
                    [0, 18.513],
                    [-10.855, 8.603],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [23.184, 0],
                  ],
                  v: [
                    [43.62, -63.07],
                    [59.579, -21.97],
                    [43.282, 18.695],
                    [-6.663, 31.595],
                    [-33.781, 31.595],
                    [-33.781, 76.415],
                    [-59.578, 76.415],
                    [-59.578, -76.415],
                    [-7.106, -76.415],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            { ty: "mm", mm: 1, nm: "Merge Paths 1", mn: "ADBE Vector Filter - Merge", hd: false },
            {
              ty: "fl",
              c: { a: 0, k: [0.882000014361, 0.902000038297, 0.913999968884, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [348.005, 624.625], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 4,
      nm: "Layer 12 Outlines",
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [49.627, 166.888, 0], ix: 2, l: 2 },
        a: { a: 0, k: [499.627, 616.888, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 55,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 95,
              s: [103, 103, 100],
            },
            { t: 115, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [10.31, 0],
                    [0, 0],
                    [0, 14.85],
                    [0, 0],
                    [-10.31, 0],
                    [0, 0],
                    [0, -14.849],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-10.31, 0],
                    [0, 0],
                    [0, -14.849],
                    [0, 0],
                    [10.31, 0],
                    [0, 0],
                    [0, 14.848],
                  ],
                  v: [
                    [257.18, 145.243],
                    [-257.18, 145.243],
                    [-275.847, 118.355],
                    [-275.847, -118.356],
                    [-257.18, -145.243],
                    [257.18, -145.243],
                    [275.847, -118.356],
                    [275.847, 118.357],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.929000016755, 0.109999997008, 0.141000007181, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [499.627, 616.888], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: "Layer 13 Outlines",
      parent: 15,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [670.364, 222.481, 0], ix: 2, l: 2 },
        a: { a: 0, k: [670.364, 222.481, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 10,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 50,
              s: [103, 103, 100],
            },
            { t: 70, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-71.929, 71.928],
                    [71.928, 71.928],
                    [-71.928, -71.928],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.922000002394, 0.941000007181, 0.952999997606, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [670.364, 222.481], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 4,
      nm: "Layer 14 Outlines",
      parent: 2,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [49.628, 400.551, 0], ix: 2, l: 2 },
        a: { a: 0, k: [499.628, 850.551, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.355, 0.355, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 0,
              s: [0, 0, 100],
            },
            {
              i: { x: [0.352, 0.352, 0.667], y: [1, 1, 1] },
              o: { x: [0.646, 0.646, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [103, 103, 100],
            },
            { t: 60, s: [100, 100, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -15.464],
                    [0, 0],
                    [-15.465, 0],
                    [0, 0],
                    [0, 15.465],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-15.465, 0],
                    [0, 0],
                    [0, 15.463],
                    [0, 0],
                    [15.464, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [98.809, -350],
                    [-214.666, -350],
                    [-242.667, -322],
                    [-242.667, 322],
                    [-214.666, 350],
                    [214.667, 350],
                    [242.667, 322],
                    [242.667, -206.143],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.882000014361, 0.902000038297, 0.913999968884, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [499.628, 500.551], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 600,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};
