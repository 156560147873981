import { connectionConstants } from "../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../helpers";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var auditoriaSel = null;
  var noConfSel = null;
  var fotoSel; //Almacena el índice de la imagen que se quiere visualizar en grande
  var array_fotos = [];

  //#region Datasource

  var dataSource_Auditorias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAuditoria",
      key: "idAuditoria",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: [
      "idAuditoria",
      "denoLavanderia",
      "fecha",
      "idTipoAuditoria",
      "denoTipoAuditoria",
    ],
  });

  var dataSource_NoConformidades = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infAuditorias_spSelectNoConformidadNAuditoria",
      key: "idNoConformidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        if (request.method !== "get") {
          request.url = request.url.replace(
            "infAuditorias_spSelectNoConformidadNAuditoria",
            "tblNoConformidad",
          );
        } else {
          request.params.idAuditoria = auditoriaSel
            ? auditoriaSel.idAuditoria
            : -1;
        }
      },
      version: 4,
    }),
  });

  var dataSource_Fotos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFoto",
      key: "idFoto",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;

        request.params.idNoConformidad = noConfSel.idNoConformidad;
      },
      version: 4,
    }),
    select: ["idNoConformidad", "img"],
  });

  var dataSource_NoConformidad_Estados = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEstado",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idEstado", "denominacion"],
  });

  var dataSource_NoConformidad_Grado = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblNoConformidad_Grado",
      key: "idGrado",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idGrado", "denominacion"],
  });

  //#endregion

  $("#NoConformidades #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
  });

  if (
    typeof $("#NoConformidades #tabpanel-container").data("dxTabPanel") !==
    "undefined"
  ) {
    $("#NoConformidades #tabpanel-container").dxTabPanel("instance").dispose();
  }

  var tabpanel = $("#NoConformidades #tabpanel-container")
    .addClass("no-header")
    .dxTabPanel({
      height: "100%",
      width: "100%",
      deferRendering: false,
      animationEnabled: true,
      swipeEnabled: false,
      focusStateEnabled: false,
      items: [
        {
          title: "Filtros",
          template: function () {
            return $("<div />").dxBox({
              direction: "col",
              height: "100%",
              items: [
                {
                  baseSize: 45,
                  shrink: 0,
                  ratio: 0,
                  template: function () {
                    return $(
                      "<div class='font-size' style='margin-left:9px;'>1. " +
                        getTrad("elijaAuditoria") +
                        "</div>",
                    );
                  },
                },
                {
                  ratio: 1,
                  template: function () {
                    return $("<div id='dxDataGrid_Auditorias'>").dxDataGrid({
                      //Datos
                      dataSource: dataSource_Auditorias,
                      remoteOperations: true,
                      //Propiedades
                      columnsAutoWidth: true,
                      height: "100%",
                      headerFilter: {
                        visible: true,
                      },
                      filterRow: {
                        visible: true,
                        applyFilter: "auto",
                      },
                      paging: {
                        pageSize: 50,
                      },
                      pager: {
                        showPageSizeSelector: true,
                        allowedPageSizes: [20, 50, 100],
                        showInfo: true,
                      },
                      selection: {
                        mode: "single",
                      },
                      hoverStateEnabled: true,
                      columns: [
                        {
                          dataField: "fecha",
                          caption: getTrad("fecha"),
                          dataType: "date",
                          format: "dd/MM/yyyy",
                          width: "25%",
                          alignment: "center",
                          sortOrder: "desc",
                          allowHeaderFiltering: false,
                        },
                        {
                          dataField: "denoLavanderia",
                          caption: getTrad("denominacion"),
                          width: "50%",
                          minWidth: "150px",
                          alignment: "left",
                        },
                        {
                          dataField: "denoTipoAuditoria",
                          caption: getTrad("tipo"),
                          width: "25%",
                          minWidth: "100px",
                          alignment: "left",
                        },
                      ],
                      //  EVENTOS
                      onRowPrepared: function (e) {
                        if (e.rowType === "data") {
                          e.rowElement.css({
                            height: 45,
                            fontSize: 14,
                            verticalAlign: "middle",
                          });
                          e.rowElement.css("cursor", "pointer");
                        }
                      },
                      onCellPrepared: function (e) {
                        if (e.rowType === "data") {
                          e.cellElement.css({ verticalAlign: "middle" });
                        }
                      },
                      onSelectionChanged: function (selectedItem) {
                        auditoriaSel =
                          typeof selectedItem.selectedRowsData[0] !==
                          "undefined"
                            ? selectedItem.selectedRowsData[0]
                            : null;

                        $("#dxDataGrid_NoConformidades")
                          .dxDataGrid("instance")
                          .refresh();
                        if (auditoriaSel) {
                          tabpanel.option("selectedIndex", 1); // EN FUNCIÓN DE SI HAY SELECCIÓN O NO PONE TAB PRINCIPAL O SECUNDARIA
                        }
                      },
                      //Estilos
                      showColumnLines: false,
                      showRowLines: true,
                      rowAlternationEnabled: true,
                    });
                  },
                },
              ],
            });
          },
        },
        {
          title: getTrad("noConformidades"),
          visible: false,
          template: function () {
            return $("<div />").dxBox({
              direction: "col",
              height: "100%",
              items: [
                {
                  baseSize: 50,
                  shrink: 0,
                  ratio: 0,
                  template: function () {
                    return $("<div />").dxToolbar({
                      items: [
                        {
                          location: "before",
                          widget: "dxButton",
                          options: {
                            text: getTrad("volverFiltrar"),
                            icon: "chevronleft",
                            onClick: function () {
                              tabpanel.option("selectedIndex", 0);
                            },
                          },
                        },
                        {
                          location: "after",
                          widget: "dxButton",
                          locateInMenu: "auto",
                          visible: true,
                          options: {
                            text: getTrad("exportar"),
                            icon: "exportxlsx",
                            onClick: function () {
                              $("#dxDataGrid_NoConformidades")
                                .dxDataGrid("instance")
                                .exportToExcel();
                              //modalImpresion.PerformCallback(auditoriaSel.idAuditoria + "|" + LAVANDERIA.denominacion + "|" + formatDate_parameter(auditoriaSel.fecha));
                            },
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  ratio: 1,
                  template: function () {
                    return $(
                      "<div id='dxDataGrid_NoConformidades'>",
                    ).dxDataGrid({
                      //Datos
                      remoteOperations: true,
                      dataSource: dataSource_NoConformidades,
                      //Propiedades
                      columnsAutoWidth: true,
                      height: "100%",
                      headerFilter: {
                        visible: true,
                      },
                      filterRow: {
                        visible: true,
                        applyFilter: "auto",
                      },
                      paging: {
                        pageSize: 50,
                      },
                      pager: {
                        showPageSizeSelector: true,
                        allowedPageSizes: [20, 50, 100],
                        showInfo: true,
                      },
                      selection: {
                        mode: "single",
                      },
                      hoverStateEnabled: true,
                      columns: [
                        {
                          dataField: "idNoConformidad",
                          visible: false,
                        },
                        {
                          dataField: "idPuntoRevisionNAuditoria",
                          visible: false,
                        },
                        {
                          dataField: "denoAmbito",
                          caption: getTrad("ambito"),
                          sortOrder: "asc",
                          sortIndex: 0,
                          width: 225,
                          alignment: "left",
                        },
                        {
                          dataField: "denoSubAmbito",
                          caption: getTrad("subAmbito"),
                          sortOrder: "asc",
                          sortIndex: 1,
                          width: 180,
                          alignment: "left",
                        },
                        {
                          dataField: "descripcion",
                          caption: getTrad("noConf"),
                          allowHeaderFiltering: false,
                          alignment: "left",
                        },
                        {
                          dataField: "accionCorrectora",
                          caption: getTrad("accionCorrect"),
                          minWidth: 500,
                          allowHeaderFiltering: false,
                          alignment: "left",
                          visible: false,
                          allowExporting: true,
                        },
                        {
                          dataField: "idGrado",
                          caption: getTrad("grado"),
                          width: 130,
                          alignment: "left",
                          lookup: {
                            dataSource: function (options) {
                              return {
                                store: dataSource_NoConformidad_Grado.store(),
                              };
                            },
                            displayExpr: "denominacion",
                            valueExpr: "idGrado",
                          },
                        },
                        {
                          dataField: "responsable",
                          caption: getTrad("responsable"),
                          width: 170,
                          allowHeaderFiltering: false,
                          alignment: "left",
                        },
                        {
                          dataField: "responsable2",
                          caption: getTrad("responsable") + " 2",
                          visible: false,
                          alignment: "left",
                        },
                        {
                          dataField: "responsable3",
                          caption: getTrad("responsable") + " 3",
                          visible: false,
                          alignment: "left",
                        },
                        {
                          name: "estado",
                          caption: getTrad("estado"),
                          visible: false,
                          calculateCellValue: (rowData) => {
                            switch (rowData.idEstado) {
                              case null:
                                return getTrad("sinEstado");
                                break;
                              case 1: // ABIERTA ? -> TODAVÍA NO SE HA DEFINIDO
                                return getTrad("abierta");
                                break;
                              case 2: // EN PROCESO ? -> TODAVÍA NO SE HA DEFINIDO
                                return getTrad("enProceso");
                                break;
                              case 3: // CERRADA ? -> TODAVÍA NO SE HA DEFINIDO
                                return getTrad("cerrada");
                                break;
                              default:
                                break;
                            }
                          },
                        },
                        {
                          dataField: "idEstado",
                          caption: getTrad("estado"),
                          width: 95,
                          alignment: "left",
                          cellTemplate: function (container, options) {
                            var contenedor = $("<div />")
                              .addClass("container_spanCentrado")
                              .css("padding-left", "10px");

                            var cuadrado = $(
                              "<span id='cuadrado_" +
                                options.data.idNoConformidad +
                                "'>",
                            );
                            var idEstado = options.data.idEstado;
                            var denoEstado = "";

                            switch (idEstado) {
                              case null:
                                cuadrado.css("background-color", "#FE3814");
                                denoEstado = getTrad("sinEstado");
                                break;
                              case 1: // ABIERTA ? -> TODAVÍA NO SE HA DEFINIDO
                                cuadrado.css("background-color", "#FE3814"); // ROJO
                                denoEstado = getTrad("abierta");
                                break;
                              case 2: // EN PROCESO ? -> TODAVÍA NO SE HA DEFINIDO
                                cuadrado.css("background-color", "#FEC007"); // AMARILLO
                                denoEstado = getTrad("enProceso");
                                break;
                              case 3: // CERRADA ? -> TODAVÍA NO SE HA DEFINIDO
                                cuadrado.css("background-color", "#63FF14"); // VERDE
                                denoEstado = getTrad("cerrada");
                                break;
                              default:
                                break;
                            }
                            var cellWidth = container[0].clientWidth * 0.55;

                            cuadrado.css("height", 19);
                            cuadrado.css("width", cellWidth);
                            cuadrado.css("border-radius", "4px");
                            cuadrado.css("border", "1px solid #BFBFBF");
                            cuadrado.css("font-size", "12px");

                            cuadrado.appendTo(contenedor);

                            $("<div />")
                              .dxTooltip({
                                target:
                                  "#cuadrado_" + options.data.idNoConformidad,
                                height: "42px",
                                showEvent: "dxhoverstart",
                                hideEvent: "dxhoverend",
                                position: "bottom",
                                contentTemplate: function (contentElement) {
                                  contentElement.html(denoEstado);
                                },
                                animation: {
                                  show: {
                                    type: "pop",
                                    from: {
                                      scale: 0.1,
                                      opacity: 0,
                                    },
                                    to: {
                                      opacity: 1,
                                      scale: 1,
                                    },
                                  },
                                  hide: {
                                    type: "pop",
                                    from: {
                                      scale: 1,
                                      opacity: 1,
                                    },
                                    to: {
                                      opacity: 0,
                                      scale: 0.1,
                                    },
                                  },
                                },
                              })
                              .appendTo(contenedor);

                            return contenedor;
                          },
                        },
                        {
                          dataField: "observaciones",
                          visible: false,
                          alignment: "left",
                        },
                      ],
                      customizeColumns: function (columns) {
                        columns.forEach(function (column) {
                          column.allowEditing = false;
                        });
                      },
                      onRowPrepared: function (e) {
                        if (e.rowType === "data") {
                          e.rowElement.css("cursor", "pointer");
                        }
                      },
                      onEditorPreparing: function (e) {
                        if (e.parentType === "filterRow") return;
                        switch (e.dataField) {
                          case "descripcion":
                            e.editorName = "dxTextArea";
                            e.editorOptions.height = 90;
                            break;
                          case "accionCorrectora":
                            e.editorName = "dxTextArea";
                            e.editorOptions.height = 90;
                            break;
                          default:
                            break;
                        }
                      },
                      onSelectionChanged: function (selectedItem) {
                        //TODO
                        if (selectedItem.selectedRowsData.length > 0) {
                          noConfSel = selectedItem.selectedRowsData[0];
                          var dxTileView_documentos = $(
                            "#dxTileView_documentos",
                          ).dxTileView("instance");
                          var dxForm_NoConformidad = $(
                            "#dxForm_NoConformidad",
                          ).dxForm("instance");

                          dataSource_Fotos.load().done(function (data) {
                            array_fotos = data;

                            if (typeof dxTileView_documentos !== "undefined") {
                              dxTileView_documentos.option(
                                "dataSource",
                                array_fotos,
                              );
                            }
                            if (typeof dxForm_NoConformidad !== "undefined") {
                              dxForm_NoConformidad.option(
                                "formData",
                                $.extend(true, {}, noConfSel),
                              );
                            }
                            $("#popup_infoNoConf").dxPopup("instance").show();
                          });
                        }
                      },
                      onExporting: function (e) {
                        e.component.beginUpdate();
                        e.component.columnOption(
                          "accionCorrectora",
                          "visible",
                          true,
                        );
                        e.component.columnOption("idEstado", "visible", false);
                        e.component.columnOption("estado", "visible", true);
                      },
                      onExported: function (e) {
                        e.component.columnOption(
                          "accionCorrectora",
                          "visible",
                          false,
                        );
                        e.component.columnOption("idEstado", "visible", true);
                        e.component.columnOption("estado", "visible", false);
                        e.component.endUpdate();
                      },
                      //Estilos
                      showColumnLines: false,
                      showRowLines: true,
                      rowAlternationEnabled: true,
                    });
                  },
                },
              ],
            });
          },
        },
      ],
      onSelectionChanged: function (e) {
        var tabpanel = e.component;
        var tabpanel_index = tabpanel.option("selectedIndex");
        if (tabpanel_index === 0) {
          $("#dxDataGrid_Auditorias")
            .dxDataGrid("instance")
            .option("selectedRowKeys", []);
          tabpanel.option("items[0].visible", true);
          tabpanel.option("items[1].visible", false);
        } else {
          tabpanel.option("items[0].visible", false);
          tabpanel.option("items[1].visible", true);
          setTimeout(() => {
            $("#dxDataGrid_NoConformidades").dxDataGrid("instance").repaint();
          }, 50);
        }
      },
    })
    .dxTabPanel("instance");

  $("#NoConformidades #popup_infoNoConf").dxPopup({
    showTitle: true,
    title: getTrad("noConf"),
    height: "auto",
    width: "60%",
    minWidth: "500px",
    contentTemplate: function () {
      var container = $("<div style='height:100%;'></div>");
      $("<div id='dxForm_NoConformidad' style='margin-bottom:20px;'>")
        .dxForm({
          formData: $.extend(true, {}, noConfSel),
          labelLocation: "top",
          colCount: 1,
          items: [
            {
              itemType: "group",
              caption: getTrad("fichaNoConf"),
              colCount: 4,
              items: [
                {
                  dataField: "idNoConformidad",
                  visible: false,
                },
                {
                  dataField: "idPuntoRevisionNAuditoria",
                  visible: false,
                },
                {
                  dataField: "denoAmbito",
                  colSpan: 1,
                  label: {
                    text: getTrad("ambito"),
                  },
                },
                {
                  dataField: "denoSubAmbito",
                  colSpan: 1,
                  label: {
                    text: getTrad("subAmbito"),
                  },
                },
                {
                  colSpan: 1,
                  dataField: "idGrado",
                  editorType: "dxSelectBox",
                  label: { text: getTrad("grado") },
                },
                { itemType: "empty", colSpan: 1 },
                {
                  colSpan: 2,
                  dataField: "descripcion",
                  editorType: "dxTextArea",
                  editorOptions: {
                    height: "90px",
                  },
                  label: {
                    text: getTrad("noConf"),
                  },
                },
                {
                  colSpan: 2,
                  dataField: "accionCorrectora",
                  editorType: "dxTextArea",
                  editorOptions: {
                    height: "90px",
                  },
                  label: {
                    text: getTrad("accionCorrect"),
                  },
                },
              ],
            },
            {
              itemType: "group",
              caption: getTrad("responsablesGrado"),
              colCount: 4,
              items: [
                {
                  dataField: "responsable",
                  colSpan: 1,
                  label: {
                    text: getTrad("responsable"),
                  },
                },
                {
                  colSpan: 1,
                  dataField: "responsable2",
                  label: {
                    text: getTrad("responsable") + " 2",
                  },
                },
                {
                  colSpan: 1,
                  dataField: "responsable3",
                  label: {
                    text: getTrad("responsable") + " 3",
                  },
                },
                {
                  colSpan: 1,
                  dataField: "idEstado",
                  editorType: "dxSelectBox",
                  editorOptions: {
                    dataSource: dataSource_NoConformidad_Estados.store(),
                    valueExpr: "idEstado",
                    displayExpr: "denominacion",
                    placeholder: getTrad("sinEstado"),
                  },
                  label: {
                    text: getTrad("estado"),
                  },
                },
                {
                  colSpan: 2,
                  dataField: "observaciones",
                  editorType: "dxTextArea",
                  editorOptions: {
                    height: "90px",
                  },
                  label: {
                    text: getTrad("observaciones"),
                  },
                },
              ],
            },
          ],
          customizeItem: function (item) {
            if (item.dataField === "idGrado") {
              item.editorOptions = {
                dataSource: dataSource_NoConformidad_Grado.store(),
                displayExpr: "denominacion",
                valueExpr: "idGrado",
                disabled: true,
              };
            } else if (
              item.dataField === "accionCorrectora" ||
              item.dataField === "descripcion"
            ) {
              item.editorOptions = {
                height:
                  item.dataField === "accionCorrectora" ||
                  item.dataField === "descripcion"
                    ? 90
                    : "auto",
                disabled: true,
              };
            } else if (
              item.dataField === "denoAmbito" ||
              item.dataField === "denoSubAmbito"
            ) {
              item.editorOptions = {
                disabled: true,
              };
            }
          },
        })
        .appendTo(container);

      $("<div id='dxTileView_documentos'>")
        .dxTileView({
          dataSource: array_fotos,
          readOnly: true,
          baseItemHeight: 100,
          baseItemWidth: 100,
          noDataText: getTrad("noConf_sinImagenes"),
          hoverStateEnabled: true,
          direction: "horizontal",
          height: 130,
          width: "100%",
          itemMargin: 10,
          showScrollbar: true,
          itemTemplate: function (itemData, itemIndex, itemElement) {
            var container = $("<div id='contenedorImg' style='height:100%'>");
            $("<img />")
              .attr("class", "image")
              .attr(
                "style",
                "background-image: url('data:image/png;base64," + itemData.img,
              )
              .on("click", function () {
                fotoSel = itemIndex;
                $("#popup_galeriaImagenes").dxPopup("show");
              })
              .appendTo(container);

            return container;
          },
        })
        .appendTo(container);
      return container;
    },
    // EVENTOS
    onContentReady: function (e) {
      var html = e.component.content();
      $(html).css("padding-bottom", "0");
    },
    onShowing: function (e) {
      $("#dxForm_NoConformidad")
        .dxForm("instance")
        .option("formData", $.extend(true, {}, noConfSel));
      $("#dxForm_NoConformidad").dxForm("instance").repaint();
    },
    onHiding: function (e) {
      $("#dxDataGrid_NoConformidades")
        .dxDataGrid("instance")
        .option("selectedRowKeys", []);
    },
    toolbarItems: [
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("cancelar"),
          type: "normal",
          onClick: function (e) {
            $("#popup_infoNoConf").dxPopup("instance").hide();
          },
        },
      },
      {
        toolbar: "bottom",
        location: "after",
        widget: "dxButton",
        options: {
          text: getTrad("guardar"),
          type: "success",
          onClick: function (e) {
            var data = $("#dxForm_NoConformidad")
              .dxForm("instance")
              .option("formData");
            delete data.denoAmbito;
            delete data.denoSubAmbito;
            data.tblFoto = array_fotos;

            dataSource_NoConformidades
              .store()
              .update(noConfSel.idNoConformidad, data)
              .done(function () {
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: "1500",
                  closeOnClick: true,
                });
                $("#dxDataGrid_NoConformidades")
                  .dxDataGrid("instance")
                  .refresh();
              });
            $("#popup_infoNoConf").dxPopup("instance").hide();
          },
        },
      },
    ],
  });

  //#region POPUP GALERIA
  $("#NoConformidades #popup_galeriaImagenes").dxPopup({
    showTitle: true,
    title: getTrad("galeriaImagenes"),
    showCloseButton: true,
    closeOnOutsideClick: true,
    width: "700px",
    height: "500px",
    onShowing: function (e) {
      $("#galeriaImagenes")
        .dxGallery("instance")
        .option("dataSource", array_fotos);
      $("#galeriaImagenes")
        .dxGallery("instance")
        .option("selectedIndex", fotoSel);
    },
    contentTemplate: function () {
      var container = $("<div>");
      $("<div id='galeriaImagenes'>")
        .dxGallery({
          dataSource: array_fotos,
          showIndicator: true,
          showNavButtons: true,
          loop: true,
          height: "400",
          width: "100%",
          itemTemplate: function (itemData, itemIndex, itemElement) {
            itemElement.addClass("contenedorImg");
            itemElement.append(
              $("<img />")
                .addClass("image")
                .attr(
                  "style",
                  "background-image: url(data:image/png;base64," + itemData.img,
                )
                .on("click", function () {
                  fotoSel = itemIndex;
                  $("#popup_galeriaImagenes").dxPopup("show");
                }),
            );
          },
        })
        .appendTo(container);

      return container;
    },
  });
  //#endregion

  deferred.resolve();
  return deferred.promise();
}
