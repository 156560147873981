import { connectionConstants } from "../../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  formatDate_parameter,
  formatNumber,
  tooltipControl_creacion,
  create_rangeSelector,
  filtroTiempo_Resize,
  dxRangeSelector_PosicionValor_nuevoMarker,
  remove_CustomMarker_RangeSelector,
  is_paramRedireccion_outOfRange,
  startOfMonth,
} from "../../../../helpers";
import { svg_warning } from "../../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var entidadSel = null,
    prendaSel = [],
    almacenSel = [],
    repartoOfficeSel = [],
    tipoTablaSel = 1,
    abonos = { uds: 0, kgs: 0 };

  var opciones = {
    idEntidad: null,
    fechaDesde: null,
    fechaHasta: null,
    scrollLeft: null,
  };

  opciones.fechaDesde = COMPONENT.state.fechaDesde;
  opciones.fechaHasta = COMPONENT.state.fechaHasta;
  opciones.idEntidad = COMPONENT.state.idEntidad;
  opciones.scrollLeft = COMPONENT.state.scrollLeft;

  //#region DATASOURCE

  var datasource_spSelectPrendas = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL + "infReposicionesOffice_spSelectPrendas",
      key: ["idPrenda", "peso"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = opciones.idEntidad;
        request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
        request.params.fechaHasta = formatDate_parameter(opciones.fechaHasta);
        request.params.idAlmacen =
          almacenSel.length > 0
            ? almacenSel[0].idAlmacen
            : tipoTablaSel === 2
              ? -1
              : null;
        request.params.idRepartoOffice =
          repartoOfficeSel.length > 0
            ? repartoOfficeSel[0].idRepartoOffice
            : null;
        request.params.idAbono =
          repartoOfficeSel.length > 0
            ? repartoOfficeSel[0].idAbono
            : almacenSel.length > 0
              ? almacenSel[0].idAbono
              : tipoTablaSel === 2
                ? -1
                : null;
      },
      onLoaded: function (data) {
        COMPONENT.loadPanel_hide();
      },
      version: 4,
    }),
  });

  var datasource_spSelectAlmacenes = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infReposicionesOffice_spSelectAlmacenes",
      key: "idAlmacen",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        const prenda = prendaSel.length > 0
          ? prendaSel[0]
          : null;

        request.headers = { ...authHeader() };
        request.params.idEntidad = opciones.idEntidad;
        request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
        request.params.fechaHasta = formatDate_parameter(opciones.fechaHasta);
        request.params.idPrenda = prenda?.idPrenda ?? (tipoTablaSel === 1 ? -1 : null);
        request.params.peso = prenda?.peso * 1000;
      },
      onLoaded: function (data) {
        COMPONENT.loadPanel_hide();
      },
      version: 4,
    }),
  });

  var datasource_spSelectRepartosOffice = new DataSource({
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infReposicionesOffice_spSelectRepartosOffice",
      key: ["idRepartoOffice", "idAbono"],
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idEntidad = opciones.idEntidad;
        request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
        request.params.fechaHasta = formatDate_parameter(opciones.fechaHasta);
        request.params.idPrenda =
          prendaSel.length > 0
            ? prendaSel[0].idPrenda
            : tipoTablaSel === 3
              ? null
              : -1;
        request.params.idAlmacen =
          almacenSel.length > 0
            ? almacenSel[0].idAlmacen
            : tipoTablaSel === 3
              ? null
              : -1;
        request.params.idAbono =
          almacenSel.length > 0
            ? almacenSel[0].idAbono
            : tipoTablaSel === 3
              ? null
              : -1;
      },
      onLoaded: function (data) {
        COMPONENT.loadPanel_hide();
      },
      version: 4,
    }),
  });

  var datasource_familias = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFamilia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idFamilia", "denominacion", "abreviatura"],
    postProcess: function (data) {
      if (datasource_familias.items().length === 0) {
        data.splice(0, 0, {
          idFamilia: 0,
          denominacion: getTrad("todas").toUpperCase(),
          abreviatura: getTrad("todas").toUpperCase(),
        });
      }
      return data;
    },
    sort: "idFamilia",
  });

  var datasource_filtroTiempo_tblRepartoOffice = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "infReposicionesOffice_filtroTiempo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
      },
      version: 4,
    }),
    postProcess: function (data) {
      var item = data[0];
      var dataSource = [];
      dataSource.push({ arg: item.fechaDesde, value: item.fechaDesde });
      dataSource.push({ arg: item.fechaHasta, value: item.fechaHasta });
      return dataSource;
    },
  });

  var enum_tipoTabla = [
    {
      idTipoTabla: 1,
      denominacion: getTrad("prendas").toUpperCase(),
    },
    {
      idTipoTabla: 2,
      denominacion: getTrad("almacenes").toUpperCase(),
    },
    {
      idTipoTabla: 3,
      denominacion: getTrad("repartos").toUpperCase(),
    },
  ];

  var datasource_infReposicionesOffice_Agrupado_Entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "infReposicionesOffice_Agrupado_Entidades",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };

        request.params.idLavanderia = LAVANDERIA
          ? LAVANDERIA.idLavanderia
          : null;
        request.params.fechaDesde = formatDate_parameter(opciones.fechaDesde);
        request.params.fechahasta = formatDate_parameter(opciones.fechaHasta);
        request.params.idEntidad = null;
      },
      version: 4,
    }),
    postProcess: function (data) {
      entidadSel = $.grep(data, function (item) {
        return item.idEntidad === opciones.idEntidad;
      })[0];
      return data;
    },
  });

  var datasource_abonos = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblAbono",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: function (loadOptions) {
        var fechaDesde = formatDate_parameter(opciones.fechaDesde).split(
          "T",
        )[0],
          fechaHasta = formatDate_parameter(opciones.fechaHasta).split("T")[0];
        loadOptions.filter = [
          "(idEntidad eq " +
          opciones.idEntidad +
          ") and idTipoAbono eq 2 and (date(fecha) ge " +
          fechaDesde +
          " and date(fecha) le " +
          fechaHasta +
          ")",
        ];
      },
      version: 4,
    }),
    select: ["tblPrendaNAbono"],
    expand: [
      "tblPrendaNAbono($select=idPrenda,cantidad;$expand=tblPrenda($select=peso))",
    ],
    postProcess: function (data) {
      abonos.uds = 0;
      abonos.kgs = 0;
      if (data.length > 0) {
        $.each(data, function (index, tblPrendaNAbono) {
          $.each(tblPrendaNAbono, function (index2, item1) {
            $.each(item1, function (index3, item) {
              abonos.uds += item.cantidad;
              abonos.kgs += item.cantidad * (item.tblPrenda.peso * 0.001);
            });
          });
        });
      }
      return data;
    },
  });

  //#endregion
  $.when(
    datasource_abonos.load(),
    datasource_filtroTiempo_tblRepartoOffice.load(),
    datasource_infReposicionesOffice_Agrupado_Entidades.load(),
  )
    .then(function () {
      $("#dxForm_opciones").dxForm({
        labelLocation: "top",
        height: "100%",
        scrollingEnabled: true,
        colCount: 1,
        items: [
          {
            label: { text: getTrad("familias") },
            editorType: "dxRadioGroup",
            editorOptions: {
              elementAttr: {
                id: "dxRadioGroup_familias",
                class: "dxRadioGroup_opciones",
              },
              dataSource: datasource_familias,
              valueExpr: "idFamilia",
              displayExpr: "denominacion",
              width: "100%",
              value: 0,
              onValueChanged: function (e) {
                var idFamiliaSel = $("#dxRadioGroup_familias")
                  .dxRadioGroup("instance")
                  .option("value"),
                  filteredData = query(datasource_spSelectPrendas.items())
                    .filter(function (dataItem) {
                      if (
                        (idFamiliaSel !== 0 &&
                          dataItem.idFamilia === idFamiliaSel) ||
                        idFamiliaSel === 0
                      ) {
                        return true;
                      }
                      return false;
                    })
                    .toArray();
                $("#dxDataGrid_prendas")
                  .dxDataGrid("instance")
                  .option("dataSource", filteredData);
              },
            },
          },
        ],
      });

      var popover_abonoEntrega = $(
        "#InfReposicionesOffice #dxPopover_abonoEntrega",
      )
        .dxPopover({
          width: 300,
          position: "top",
        })
        .dxPopover("instance");

      $("#InfReposicionesOffice #dxContainer").append(popover_abonoEntrega);

      var items_toolbars = {
        primario: {
          baseSize: 50,
          template: function (e, index, item) {
            item.append(
              $("<div />").dxToolbar({
                items: [
                  {
                    location: "before",
                    template: function (e, index, item) {
                      item.append(
                        $("<div />").dxRadioGroup({
                          elementAttr: {
                            id: "dxRadioGroup_tipoTabla",
                            class: "dxRadioGroup_opciones",
                          },
                          dataSource: enum_tipoTabla,
                          valueExpr: "idTipoTabla",
                          displayExpr: "denominacion",
                          value: tipoTablaSel,
                          layout: "horizontal",
                          height: "100%",
                          width: "525px",
                          onValueChanged: function (e) {
                            prendaSel = [];
                            almacenSel = [];
                            repartoOfficeSel = [];
                            tipoTablaSel = e.value;

                            $("#dxButton_eliminarSeleccion")
                              .dxButton("instance")
                              .option("disabled", true);
                            $("#dxRadioGroup_familias")
                              .dxRadioGroup("instance")
                              .option("value", 0);

                            COMPONENT.loadPanel_show(true);
                            switch (tipoTablaSel) {
                              case 1: //Prendas
                                $("#dxRadioGroup_familias")
                                  .dxRadioGroup("instance")
                                  .option("disabled", false);
                                $("#dxBox_principal")
                                  .dxBox("instance")
                                  .option("items", [
                                    {
                                      ratio: 3,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.primario,
                                              items_principales.prendas,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                    {
                                      ratio: 2,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.secundario,
                                              items_principales.almacenes,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                  ]);
                                break;
                              case 2: //Almacenes
                                $("#dxRadioGroup_familias")
                                  .dxRadioGroup("instance")
                                  .option("disabled", true);
                                $("#dxBox_principal")
                                  .dxBox("instance")
                                  .option("items", [
                                    {
                                      ratio: 4,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.primario,
                                              items_principales.almacenes,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                    {
                                      ratio: 3,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.secundario,
                                              items_principales.prendas,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                  ]);
                                break;
                              case 3: //Repartos
                                $("#dxRadioGroup_familias")
                                  .dxRadioGroup("instance")
                                  .option("disabled", true);
                                $("#dxBox_principal")
                                  .dxBox("instance")
                                  .option("items", [
                                    {
                                      ratio: 4,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.primario,
                                              items_principales.repartos,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                    {
                                      ratio: 3,
                                      template: function (e, index, item) {
                                        item.append(
                                          $("<div />").dxBox({
                                            direction: "col",
                                            align: "space-around",
                                            crossAlign: "stretch",
                                            height: "100%",
                                            items: [
                                              items_toolbars.secundario,
                                              items_principales.prendas,
                                            ],
                                          }),
                                        );
                                      },
                                    },
                                  ]);
                                break;
                              default:
                                break;
                            }
                          },
                        }),
                      );
                    },
                  },
                  {
                    location: "after",
                    template: function (e, index, item) {
                      item.append(
                        $("<div />").dxToolbar({
                          items: [
                            {
                              location: "after",
                              widget: "dxButton",
                              options: {
                                text: getTrad("eliminarSeleccion"),
                                icon: " icon_EliminarFiltros",
                                disabled: true,
                                elementAttr: {
                                  id: "dxButton_eliminarSeleccion",
                                },
                                onClick: function (e) {
                                  var dxDataGrid_prendas = $(
                                    "#dxDataGrid_prendas",
                                  ).dxDataGrid("instance");
                                  var dxDataGrid_almacenes = $(
                                    "#dxDataGrid_almacenes",
                                  ).dxDataGrid("instance");
                                  var dxDataGrid_repartosOffice = $(
                                    "#dxDataGrid_repartosOffice",
                                  ).dxDataGrid("instance");

                                  if (dxDataGrid_prendas) {
                                    dxDataGrid_prendas.clearSelection();
                                  }
                                  if (dxDataGrid_almacenes) {
                                    dxDataGrid_almacenes.clearSelection();
                                  }
                                  if (dxDataGrid_repartosOffice) {
                                    dxDataGrid_repartosOffice.clearSelection();
                                  }

                                  e.component.option("disabled", true);
                                },
                              },
                            },
                            {
                              location: "after",
                              widget: "dxButton",
                              options: {
                                text: getTrad("exportar"),
                                icon: "exportxlsx",
                                onClick: function (e) {
                                  if (tipoTablaSel === 1)
                                    $("#dxDataGrid_prendas")
                                      .dxDataGrid("instance")
                                      .exportToExcel();
                                  else if (tipoTablaSel === 2)
                                    $("#dxDataGrid_almacenes")
                                      .dxDataGrid("instance")
                                      .exportToExcel();
                                  else if (tipoTablaSel === 3)
                                    $("#dxDataGrid_repartosOffice")
                                      .dxDataGrid("instance")
                                      .exportToExcel();
                                },
                              },
                            },
                          ],
                        }),
                      );
                    },
                  },
                ],
              }),
            );
          },
        },
        secundario: {
          baseSize: 50,
          template: function (e, index, item) {
            item.append(
              $("<div />").dxToolbar({
                items: [
                  {
                    location: "after",
                    widget: "dxButton",
                    options: {
                      text: getTrad("exportar"),
                      icon: "exportxlsx",
                      onClick: function (e) {
                        if (tipoTablaSel === 1)
                          $("#dxDataGrid_almacenes")
                            .dxDataGrid("instance")
                            .exportToExcel();
                        else if (tipoTablaSel === 2 || tipoTablaSel === 3)
                          $("#dxDataGrid_prendas")
                            .dxDataGrid("instance")
                            .exportToExcel();
                      },
                    },
                  },
                ],
              }),
            );
          },
        },
        popup: {
          baseSize: 60,
          template: function (e, index, item) {
            item.append(
              $("<div />").dxToolbar({
                items: [
                  {
                    location: "before",
                    template: function (e, index, item) {
                      item.css("width", 285);
                      item.append(
                        $("<div id='titReparto_container' />")
                          .css({
                            display: "flex",
                            "flex-direction": "column",
                          })
                          .append(
                            $("<div />") // ellipsis no se aplica si se hace directamente sobre flexbox item.
                              .append(
                                $("<h5 id='title_prendaSel' />").css({
                                  flex: 1,
                                  "text-overflow": "ellipsis",
                                  overflow: "hidden",
                                  "white-space": "nowrap",
                                }),
                              ),
                            $("<div />").append(
                              $("<h5 id='title_almacenSel' />").css({
                                flex: 1,
                                "text-overflow": "ellipsis",
                                overflow: "hidden",
                                "white-space": "nowrap",
                              }),
                            ),
                          ),
                      );
                    },
                  },
                  {
                    location: "after",
                    widget: "dxButton",
                    showText: "inMenu",
                    options: {
                      text: getTrad("limpiarFiltro"),
                      icon: " icon_EliminarFiltros",
                      onInitialized: function (e) {
                        tooltipControl_creacion(
                          e.element,
                          e.component.option("text"),
                        );
                      },
                      onClick: function (e) {
                        $("#dxDataGrid_repartosOffice")
                          .dxDataGrid("instance")
                          .clearFilter();
                        notify({
                          message: getTrad("aviso_C_FiltroRestaurado"),
                          type: "success",
                          displayTime: "1500",
                          closeOnClick: true,
                        });
                      },
                    },
                  },
                  {
                    location: "after",
                    widget: "dxButton",
                    options: {
                      text: getTrad("exportar"),
                      icon: "exportxlsx",
                      onClick: function (e) {
                        $("#dxDataGrid_repartosOffice")
                          .dxDataGrid("instance")
                          .exportToExcel();
                      },
                    },
                  },
                ],
              }),
            );
          },
        },
      };

      var items_principales = {
        prendas: {
          ratio: 1,
          template: function (e, index, item) {
            item.css("margin", "0px 5px");
            item.append(
              $("<div id='dxDataGrid_prendas' />").dxDataGrid({
                dataSource:
                  tipoTablaSel === 1 ? datasource_spSelectPrendas : null,
                //Propiedades
                headerFilter: {
                  visible: true,
                },
                export: {
                  enabled: false,
                  fileName: "RepOffice_" + getTrad("prendas"),
                  allowExportSelectedData: true,
                },
                filterRow: {
                  visible: true,
                  applyFilter: "auto",
                  showAllText: getTrad("todos"),
                },
                height: "100%",
                columnAutoWidth: true,
                paging: {
                  enabled: false,
                },
                loadPanel: {
                  enabled: tipoTablaSel === 1 ? false : true,
                },
                hoverStateEnabled: tipoTablaSel === 3 ? false : true,
                selection: tipoTablaSel === 3 ? null : { mode: "single" },
                columns: [
                  {
                    dataField: "codigoPrenda",
                    caption: getTrad("codigo"),
                    width: 80,
                    sortIndex: 1,
                    sortOrder: "asc",
                    allowHeaderFiltering: false,
                  },
                  {
                    dataField: "denominacion",
                    caption: getTrad("denominacion"),
                    allowHeaderFiltering: false,
                    width: "50%",
                  },
                  {
                    dataField: "peso",
                    caption: getTrad("peso") + " (g)",
                    width: 70,
                    allowFiltering: false,
                    alignment: "center",
                    calculateCellValue: e => e.peso * 1000
                  },
                  {
                    name: "cumplimiento",
                    caption: getTrad("cumplimiento"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return formatNumber(
                        e.peticionTotal > 0
                          ? e.repartidoTotal / e.peticionTotal
                          : 0,
                        2,
                        "percent",
                      );
                    },
                    width: 110,
                    visible: tipoTablaSel === 1 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    name: "reposicion",
                    caption: getTrad("reposicion"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return e.stockTotal !== 0
                        ? formatNumber(
                          e.peticionTotal / e.stockTotal,
                          2,
                          "percent",
                        )
                        : 0;
                    },
                    width: 110,
                    visible: tipoTablaSel === 1 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "peticionTotal",
                    caption: getTrad("pedidas"),
                    customizeText: function (data) {
                      if (data.value === 0) {
                        return "-";
                      } else {
                        return formatNumber(data.value);
                      }
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "repartidoTotal",
                    caption: getTrad("entregadas"),
                    customizeText: function (data) {
                      return formatNumber(data.value);
                    },
                    cellTemplate: function (cellElement, cellInfo) {
                      var dataField = cellInfo.column.dataField;
                      var isAbono =
                        (almacenSel.length > 0 &&
                          almacenSel.idAlmacen === -1) ||
                          (repartoOfficeSel.length > 0 &&
                            repartoOfficeSel.idRepartoOffice === -1)
                          ? true
                          : false;

                      var imgWarning = svg_warning
                        .clone()
                        .css("display", "block")
                        .css("pointer-events", "none")
                        .css("height", "15px")
                        .css("width", "15px");

                      var abonadas = cellInfo.data.abonadas,
                        repartidoTotal = cellInfo.data[dataField],
                        data = 0;

                      data = formatNumber(repartidoTotal - abonadas, 0);

                      cellElement.append(
                        $("<div />")
                          .css({
                            display: "flex",
                            "flex-direction": "row",
                            "text-align": "center",
                            "vertical-align": "middle",
                          })
                          .append(
                            $("<div />").css({ flex: "1 0 0" }).text(data),
                          )
                          .append(
                            $("<div />")
                              .css({
                                flex: "0 0 0",
                                "align-self": "center",
                                display:
                                  abonadas > 0 && tipoTablaSel === 1
                                    ? "block"
                                    : "none",
                              })
                              .append(imgWarning),
                          ),
                      );
                    },
                    calculateCellValue: function (e) {
                      if (tipoTablaSel === 1)
                        return e.repartidoTotal - e.abonadas;
                      else return e.repartidoTotal;
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    name: "kgEntregados",
                    caption: getTrad("kgEntregados"),
                    calculateCellValue: function (e) {
                      return formatNumber(
                        (e.repartidoTotal - e.abonadas) * e.peso,
                        2,
                        "decimal",
                      );
                    },
                    width: 140,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "abonadas",
                    visible: false,
                  },
                ],
                summary: {
                  totalItems: [
                    {
                      column: "codigoPrenda",
                      displayFormat: getTrad("total").toUpperCase(),
                    },
                    {
                      name: "kgEntregados",
                      showInColumn: "kgEntregados",
                      summaryType: "custom",
                      displayFormat: "{0} Kg.",
                    },
                  ],
                  calculateCustomSummary: function (options) {
                    if (options.name === "kgEntregados") {
                      if (options.summaryProcess === "start") {
                        options.totalValue = 0;
                      }
                      if (options.summaryProcess === "calculate") {
                        options.totalValue =
                          options.totalValue +
                          (options.value.repartidoTotal -
                            options.value.abonadas) *
                          options.value.peso;
                      }
                      if (options.summaryProcess === "finalize") {
                        options.totalValue = formatNumber(
                          options.totalValue,
                          2,
                          "decimal",
                        );
                      }
                    }
                  },
                },
                onCellHoverChanged: function (e) {
                  if (tipoTablaSel === 1) {
                    if (
                      e.rowType === "totalFooter" &&
                      e.column.name === "kgEntregados" &&
                      e.eventType === "mouseover"
                    ) {
                      var totalAbonadas = 0,
                        totalEntregadas = 0;

                      $.each(
                        e.component.getDataSource().items(),
                        function (index, item) {
                          totalAbonadas += item.abonadas * item.peso;
                          totalEntregadas += item.repartidoTotal * item.peso;
                        },
                      );

                      if (totalAbonadas > 0) {
                        let enum_abonos = [
                          {
                            idTipoDato: 1,
                            denominacion: getTrad("entregados"),
                            kgs:
                              formatNumber(totalEntregadas, 2, "decimal") +
                              " Kg.",
                          },
                          {
                            idTipoDato: 2,
                            denominacion: getTrad("abonados"),
                            kgs:
                              "-" +
                              formatNumber(totalAbonadas, 2, "decimal") +
                              " Kg.",
                          },
                        ];

                        popover_abonoEntrega.option(
                          "contentTemplate",
                          function (contentElement) {
                            $("<div/>")
                              .append(
                                $("<div />").dxDataGrid({
                                  showColumnHeaders: false,
                                  dataSource: enum_abonos,
                                  columns: [
                                    {
                                      dataField: "denominacion",
                                      caption: " ",
                                    },
                                    {
                                      dataField: "kgs",
                                      caption: "Kgs",
                                      alignment: "center",
                                    },
                                  ],
                                }),
                              )
                              .appendTo(contentElement);
                          },
                        );
                        popover_abonoEntrega.option({
                          target: e.cellElement,
                        });

                        popover_abonoEntrega.show();
                      }
                    } else if (
                      e.rowType === "data" &&
                      e.eventType === "mouseover" &&
                      (e.column.dataField === "repartidoTotal" ||
                        e.column.name === "kgEntregados") &&
                      e.data.abonadas > 0
                    ) {
                      var data = e.row.data;
                      let enum_abonos = [
                        {
                          idTipoDato: 1,
                          denominacion: getTrad("entregadas"),
                          uds: data.repartidoTotal,
                          kgs: formatNumber(
                            data.repartidoTotal * data.peso,
                            2,
                            "decimal",
                          ),
                        },
                        {
                          idTipoDato: 2,
                          denominacion: getTrad("abonadas"),
                          uds: -data.abonadas,
                          kgs: formatNumber(
                            -data.abonadas * data.peso,
                            2,
                            "decimal",
                          ),
                        },
                      ];

                      popover_abonoEntrega.option(
                        "contentTemplate",
                        function (contentElement) {
                          $("<div/>")
                            .append(
                              $("<div />").dxDataGrid({
                                dataSource: enum_abonos,
                                columns: [
                                  {
                                    dataField: "denominacion",
                                    caption: " ",
                                  },
                                  {
                                    dataField: "uds",
                                    caption: "Uds",
                                    alignment: "center",
                                  },

                                  {
                                    dataField: "kgs",
                                    caption: "Kgs",
                                    alignment: "center",
                                  },
                                ],
                              }),
                            )
                            .appendTo(contentElement);
                        },
                      );
                      popover_abonoEntrega.option({
                        target: e.cellElement,
                      });

                      popover_abonoEntrega.show();
                    } else popover_abonoEntrega.hide();
                  }
                },
                onExporting: function (e) {
                  const workbook = new Workbook();
                  const worksheet = workbook.addWorksheet("Sheet");
                  exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function ({ gridCell, excelCell }) {
                      if (
                        gridCell.rowType === "data" &&
                        (gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal" ||
                          gridCell.column.name === "kgEntregados")
                      ) {
                        if (
                          gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal"
                        ) {
                          excelCell.value = parseInt(gridCell.value);
                          excelCell.numFmt = "#,##";
                        } else {
                          excelCell.value = parseFloat(
                            gridCell.value.replace(".", "").replace(",", "."),
                          );
                          excelCell.numFmt = "0.00";
                        }
                      }
                    },
                  }).then(function () {
                    workbook.xlsx.writeBuffer().then(function (buffer) {
                      saveAs(
                        new Blob([buffer], {
                          type: "application/octet-stream",
                        }),
                        "RepOffice_" + getTrad("prendas") + ".xlsx",
                      );
                    });
                  });
                  e.cancel = true;
                },
                onSelectionChanged: function (e) {
                  if (e.selectedRowKeys.length > 0)
                    prendaSel = e.selectedRowsData;
                  else prendaSel = [];

                  var dxDataGrid_almacenes = $(
                    "#dxDataGrid_almacenes",
                  ).dxDataGrid("instance");
                  dxDataGrid_almacenes.option("loadPanel.enabled", true);

                  if (tipoTablaSel === 1) {
                    // prendas
                    if (e.selectedRowKeys.length > 0) {
                      $("#dxButton_eliminarSeleccion")
                        .dxButton("instance")
                        .option("disabled", false);
                      dxDataGrid_almacenes.option(
                        "dataSource",
                        datasource_spSelectAlmacenes,
                      );
                    } else dxDataGrid_almacenes.option("dataSource", []);
                  } else if (tipoTablaSel === 2) {
                    if (e.selectedRowKeys.length > 0) {
                      var dxDataGrid_repartosOffice = $(
                        "#dxDataGrid_repartosOffice",
                      ).dxDataGrid("instance");
                      dxDataGrid_repartosOffice.option({
                        "loadPanel.enabled": true,
                      });
                      dxDataGrid_repartosOffice.option(
                        "dataSource",
                        datasource_spSelectRepartosOffice,
                      );
                      var denoPrendaSel =
                        prendaSel.length > 0 ? prendaSel[0].denominacion : "",
                        denoAlmSel =
                          almacenSel.length > 0
                            ? almacenSel[0].denominacion
                            : "";

                      $("#title_prendaSel").text(denoPrendaSel);
                      $("#title_almacenSel").text(denoAlmSel);
                      $("#dxPopup_repartos").dxPopup("instance").show();
                    }
                  }
                },
                onCellPrepared: function (e) {
                  if (e.rowType === "totalFooter") {
                    if (
                      e.column.name === "kgEntregados" &&
                      tipoTablaSel === 1
                    ) {
                      var totalAbonadas = 0;

                      $.each(
                        e.component.getDataSource().items(),
                        function (index, item) {
                          totalAbonadas += item.abonadas * item.peso;
                        },
                      );

                      if (totalAbonadas > 0) {
                        var imgWarning = svg_warning
                          .clone()
                          .css("display", "block")
                          .css("float", "right")
                          .css("height", "15px")
                          .css("width", "15px");
                        e.cellElement
                          .find(".dx-datagrid-summary-item")
                          .append(imgWarning);
                      }
                    }
                  }
                },
                onRowPrepared: function (e) {
                  if (e.rowType === "data") {
                    if (tipoTablaSel === 2) {
                      e.rowElement.attr("id", "prendas_row_" + e.rowIndex);
                      e.element.append(
                        $("<div />")
                          .text(getTrad("selRegistroInfo"))
                          .dxPopover({
                            target: "#prendas_row_" + e.rowIndex,
                            showEvent: { name: "mouseenter", delay: 750 },
                            hideEvent: "mouseleave",
                            position: "top",
                            width: 210,
                          }),
                      );
                    }
                    if (tipoTablaSel !== 3)
                      e.rowElement.css("cursor", "pointer");
                  }
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              }),
            );
          },
        },
        almacenes: {
          ratio: 1,
          template: function (e, index, item) {
            item.css("margin", "0px 5px");
            item.append(
              $("<div id='dxDataGrid_almacenes' />").dxDataGrid({
                dataSource:
                  tipoTablaSel === 2 ? datasource_spSelectAlmacenes : null,
                //Propiedades
                headerFilter: {
                  visible: true,
                },
                filterRow: {
                  visible: true,
                  applyFilter: "auto",
                  showAllText: getTrad("todos"),
                },
                export: {
                  enabled: false,
                  fileName: "RepOffice_" + getTrad("almacenes"),
                  allowExportSelectedData: true,
                },
                height: "100%",
                hint: null,
                columnAutoWidth: true,
                paging: {
                  enabled: false,
                },
                loadPanel: {
                  enabled: tipoTablaSel === 1 ? true : false,
                },
                hoverStateEnabled: true,
                selection: {
                  mode: "single",
                },
                columns: [
                  {
                    dataField: "denominacion",
                    caption: getTrad("denominacion"),
                    allowHeaderFiltering: false,
                    width: "50%",
                  },
                  {
                    name: "cumplimiento",
                    caption: getTrad("cumplimiento"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return e.repartidoTotal !== 0 && e.peticionTotal !== 0
                        ? formatNumber(
                          e.repartidoTotal / e.peticionTotal,
                          2,
                          "percent",
                        )
                        : formatNumber(0, 2, "percent");
                    },
                    width: 110,
                    visible: tipoTablaSel === 2 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    name: "reposicion",
                    caption: getTrad("reposicion"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return e.peticionTotal !== 0 && e.stockTotal !== 0
                        ? formatNumber(
                          e.peticionTotal / e.stockTotal,
                          2,
                          "percent",
                        )
                        : formatNumber(0, 2, "percent");
                    },
                    width: 110,
                    visible: tipoTablaSel === 2 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "peticionTotal",
                    caption: getTrad("pedidas"),
                    customizeText: function (data) {
                      if (data.value === 0) return "-";
                      return formatNumber(data.value);
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "repartidoTotal",
                    caption: getTrad("entregadas"),
                    customizeText: function (data) {
                      return formatNumber(data.value);
                    },
                    calculateCellValue: function (e) {
                      if (e.idAlmacen === -1) return e.repartidoTotal * -1;
                      else return e.repartidoTotal;
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "kgEntregados",
                    name: "kgEntregados",
                    caption: getTrad("kgEntregados"),
                    width: 130,
                    allowFiltering: false,
                    alignment: "center",
                    calculateCellValue: function (e) {
                      var kgEntregados =
                        e.idAlmacen === -1
                          ? e.kgEntregados * -1
                          : e.kgEntregados;
                      return formatNumber(kgEntregados, 2, "decimal") + " Kg.";
                    },
                  },
                ],
                summary: {
                  totalItems: [
                    {
                      column: "denominacion",
                      displayFormat: getTrad("total").toUpperCase(),
                    },
                    {
                      name: "peticionTotal",
                      showInColumn: "peticionTotal",
                      summaryType: "custom",
                      displayFormat: "{0}",
                    },
                    {
                      name: "repartidoTotal",
                      showInColumn: "repartidoTotal",
                      summaryType: "custom",
                      displayFormat: "{0}",
                    },
                    {
                      name: "kgEntregados",
                      showInColumn: "kgEntregados",
                      summaryType: "custom",
                      valueFormat: "#,##0.00",
                      displayFormat: "{0} Kg.",
                    },
                  ],
                  calculateCustomSummary: function (options) {
                    if (options.name === "kgEntregados") {
                      if (options.summaryProcess === "start") {
                        options.totalValue = 0;
                      }
                      if (options.summaryProcess === "calculate") {
                        var isAbono =
                          options.value.idAbono !== -1 ? true : false;
                        options.totalValue = !isAbono
                          ? options.totalValue + options.value.kgEntregados
                          : options.totalValue - options.value.kgEntregados;
                      }
                    } else {
                      if (options.summaryProcess === "start") {
                        options.totalValue = 0;
                      }
                      if (options.summaryProcess === "calculate") {
                        var isAbono =
                          options.value.idAbono !== -1 ? true : false;
                        options.totalValue =
                          tipoTablaSel === 1
                            ? !isAbono
                              ? options.totalValue + options.value[options.name]
                              : options.totalValue - options.value[options.name]
                            : "";
                      }
                    }
                  },
                },
                onExporting: function (e) {
                  const workbook = new Workbook();
                  const worksheet = workbook.addWorksheet("Sheet");
                  exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function ({ gridCell, excelCell }) {
                      if (
                        gridCell.rowType === "data" &&
                        (gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal" ||
                          gridCell.column.name === "kgEntregados")
                      ) {
                        if (
                          gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal"
                        ) {
                          excelCell.value = parseInt(gridCell.value);
                          excelCell.numFmt = "#,##";
                        } else {
                          excelCell.value = parseFloat(
                            gridCell.value.replace(".", "").replace(",", "."),
                          );
                          excelCell.numFmt = "0.00";
                        }
                      }
                    },
                  }).then(function () {
                    workbook.xlsx.writeBuffer().then(function (buffer) {
                      saveAs(
                        new Blob([buffer], {
                          type: "application/octet-stream",
                        }),
                        "RepOffice_" + getTrad("almacenes") + ".xlsx",
                      );
                    });
                  });
                  e.cancel = true;
                },
                onCellHoverChanged: function (e) {
                  if (
                    e.rowType === "totalFooter" &&
                    e.column.name === "kgEntregados" &&
                    e.eventType === "mouseover"
                  ) {
                    var totalEntregadas =
                      e.component.getTotalSummaryValue("kgEntregados"),
                      kgAbonados =
                        prendaSel.length > 0
                          ? prendaSel[0].abonadas * prendaSel[0].peso
                          : abonos.kgs;

                    if (kgAbonados > 0) {
                      var enum_abonos = [
                        {
                          idTipoDato: 1,
                          denominacion: getTrad("entregados"),
                          kgs:
                            formatNumber(
                              totalEntregadas + kgAbonados,
                              2,
                              "decimal",
                            ) + " Kg.",
                        },
                        {
                          idTipoDato: 2,
                          denominacion: getTrad("abonados"),
                          kgs:
                            "-" +
                            formatNumber(kgAbonados, 2, "decimal") +
                            " Kg.",
                        },
                      ];

                      popover_abonoEntrega.option(
                        "contentTemplate",
                        function (contentElement) {
                          $("<div/>")
                            .append(
                              $("<div />").dxDataGrid({
                                dataSource: enum_abonos,
                                showColumnHeaders: false,
                                columns: [
                                  {
                                    dataField: "denominacion",
                                    caption: " ",
                                  },
                                  {
                                    dataField: "kgs",
                                    caption: "Kgs",
                                    alignment: "center",
                                  },
                                ],
                              }),
                            )
                            .appendTo(contentElement);
                        },
                      );
                      popover_abonoEntrega.option({
                        target: e.cellElement,
                      });

                      popover_abonoEntrega.show();
                    }
                  } else popover_abonoEntrega.hide();
                },
                onSelectionChanged: function (e) {
                  if (e.selectedRowKeys.length > 0)
                    almacenSel = e.selectedRowsData;
                  else almacenSel = [];

                  var dxDataGrid_prendas = $("#dxDataGrid_prendas").dxDataGrid(
                    "instance",
                  );
                  dxDataGrid_prendas.option("loadPanel.enabled", true);

                  if (tipoTablaSel === 2) {
                    if (e.selectedRowKeys.length > 0) {
                      $("#dxButton_eliminarSeleccion")
                        .dxButton("instance")
                        .option("disabled", false);
                      dxDataGrid_prendas.option(
                        "dataSource",
                        datasource_spSelectPrendas,
                      );
                    } else dxDataGrid_prendas.option("dataSource", []);
                  } else {
                    if (e.selectedRowKeys.length > 0) {
                      var dxDataGrid_repartosOffice = $(
                        "#dxDataGrid_repartosOffice",
                      ).dxDataGrid("instance");
                      dxDataGrid_repartosOffice.option({
                        "loadPanel.enabled": true,
                      });

                      dxDataGrid_repartosOffice.option(
                        "dataSource",
                        datasource_spSelectRepartosOffice,
                      );
                      var denoPrendaSel =
                        prendaSel.length > 0 ? prendaSel[0].denominacion : "",
                        denoAlmSel =
                          almacenSel.length > 0
                            ? almacenSel[0].denominacion
                            : "";

                      $("#title_prendaSel").text(denoPrendaSel);
                      $("#title_almacenSel").text(denoAlmSel);
                      $("#dxPopup_repartos").dxPopup("instance").show();
                    }
                  }
                },
                onCellPrepared: function (e) {
                  if (e.rowType === "totalFooter") {
                    if (
                      e.column.name === "kgEntregados" &&
                      (tipoTablaSel === 1 || tipoTablaSel === 2)
                    ) {
                      var kgAbonados =
                        prendaSel.length > 0
                          ? prendaSel[0].abonadas * prendaSel[0].peso
                          : abonos.kgs;

                      if (kgAbonados > 0) {
                        var imgWarning = svg_warning
                          .clone()
                          .css("display", "block")
                          .css("float", "right")
                          .css("height", "15px")
                          .css("width", "15px");
                        e.cellElement
                          .find(".dx-datagrid-summary-item")
                          .append(imgWarning);
                      }
                    }
                  }
                },
                onRowPrepared: function (e) {
                  if (e.rowType === "data") {
                    if (tipoTablaSel === 1) {
                      e.rowElement.attr("id", "almacenes_row_" + e.rowIndex);
                      e.element.append(
                        $("<div />")
                          .text(getTrad("selRegistroInfo"))
                          .dxPopover({
                            target: "#almacenes_row_" + e.rowIndex,
                            showEvent: { name: "mouseenter", delay: 750 },
                            hideEvent: "mouseleave",
                            position: "top",
                            width: 210,
                          }),
                      );
                    }
                    e.rowElement.css("cursor", "pointer");

                    if (e.data.repartidoTotal === 0 && e.data.idAlmacen !== -1)
                      e.rowElement.addClass("disabledRow");
                  }
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              }),
            );
          },
        },
        repartos: {
          ratio: 1,
          template: function (e, index, item) {
            item.css("margin", "0px 5px");
            item.append(
              $("<div id='dxDataGrid_repartosOffice' />").dxDataGrid({
                dataSource:
                  tipoTablaSel === 3 ? datasource_spSelectRepartosOffice : null,
                //Propiedades
                headerFilter: {
                  visible: true,
                },
                filterRow: {
                  visible: true,
                  applyFilter: "auto",
                  showAllText: getTrad("todos"),
                },
                export: {
                  enabled: false,
                  fileName: "RepOffice_" + getTrad("repartos"),
                  allowExportSelectedData: true,
                },
                height: "100%",
                hint: null,
                columnAutoWidth: true,
                paging: {
                  pageSize: 50,
                },
                pager: {
                  showPageSizeSelector: true,
                  allowedPageSizes: [20, 50, 100],
                  showInfo: true,
                },
                loadPanel: {
                  enabled: false,
                },
                hoverStateEnabled: tipoTablaSel === 3 ? true : false,
                selection: tipoTablaSel === 3 ? { mode: "single" } : null,
                columns: [
                  {
                    dataField: "fecha",
                    caption: getTrad("fecha"),
                    sortIndex: 1,
                    sortOrder: "asc",
                    format: "dd/MM/yyyy",
                    allowHeaderFiltering: false,
                    minWidth: 100,
                    cellTemplate: function (cellElement, cellInfo) {
                      var isAbono = cellInfo.data.idAbono !== -1;
                      var text = isAbono
                        ? cellInfo.text + " - " + getTrad("abono").toUpperCase()
                        : cellInfo.text;
                      return $("<div>").text(text);
                    },
                  },
                  {
                    dataField: "denoAlmacen",
                    caption: getTrad("almacen"),
                    visible: false,
                    width: "50%",
                  },
                  {
                    name: "cumplimiento",
                    caption: getTrad("cumplimiento"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return formatNumber(
                        e.repartidoTotal / e.peticionTotal,
                        2,
                        "percent",
                      );
                    },
                    width: 110,
                    visible: tipoTablaSel === 3 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    name: "reposicion",
                    caption: getTrad("reposicion"),
                    calculateCellValue: function (e) {
                      if (e.peticionTotal === 0) return "-";
                      return formatNumber(
                        e.peticionTotal / e.stockTotal,
                        2,
                        "percent",
                      );
                    },
                    width: 110,
                    visible: tipoTablaSel === 3 ? true : false,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "peticionTotal",
                    caption: getTrad("pedidas"),
                    customizeText: function (data) {
                      if (data.value === 0) return "-";
                      return formatNumber(data.value);
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "repartidoTotal",
                    caption: getTrad("entregadas"),
                    customizeText: function (data) {
                      return formatNumber(data.value);
                    },
                    calculateCellValue: function (e) {
                      if (e.idRepartoOffice === -1)
                        return e.repartidoTotal * -1;
                      else return e.repartidoTotal;
                    },
                    width: 85,
                    allowFiltering: false,
                    alignment: "center",
                  },
                  {
                    dataField: "kgEntregados",
                    name: "kgEntregados",
                    caption: getTrad("kgEntregados"),
                    width: 130,
                    allowFiltering: false,
                    alignment: "center",
                    calculateCellValue: function (e) {
                      var kgEntregados =
                        e.idRepartoOffice === -1
                          ? e.kgEntregados * -1
                          : e.kgEntregados;
                      return formatNumber(kgEntregados, 2) + " Kg.";
                    },
                  },
                ],
                summary: {
                  totalItems: [
                    {
                      column: "fecha",
                      displayFormat: getTrad("total").toUpperCase(),
                    },
                    {
                      name: "kgEntregados",
                      showInColumn: "kgEntregados",
                      summaryType: "custom",
                      valueFormat: "#,##0.00",
                      displayFormat: "{0} Kg.",
                    },
                  ],
                  calculateCustomSummary: function (options) {
                    if (options.name === "kgEntregados") {
                      if (options.summaryProcess === "start") {
                        options.totalValue = 0;
                      }
                      if (options.summaryProcess === "calculate") {
                        var isAbono =
                          options.value.idAbono !== -1 ? true : false;
                        options.totalValue = !isAbono
                          ? options.totalValue + options.value.kgEntregados
                          : options.totalValue - options.value.kgEntregados;
                      }
                    }
                  },
                },
                onExporting: function (e) {
                  const workbook = new Workbook();
                  const worksheet = workbook.addWorksheet("Sheet");
                  exportDataGrid({
                    component: e.component,
                    worksheet: worksheet,
                    customizeCell: function ({ gridCell, excelCell }) {
                      if (
                        gridCell.rowType === "data" &&
                        (gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal" ||
                          gridCell.column.name === "kgEntregados")
                      ) {
                        if (
                          gridCell.column.dataField === "peticionTotal" ||
                          gridCell.column.dataField === "repartidoTotal"
                        ) {
                          excelCell.value = parseInt(gridCell.value);
                          excelCell.numFmt = "#,##";
                        } else {
                          excelCell.value = parseFloat(
                            gridCell.value.replace(".", "").replace(",", "."),
                          );
                          excelCell.numFmt = "0.00";
                        }
                      }
                    },
                  }).then(function () {
                    workbook.xlsx.writeBuffer().then(function (buffer) {
                      saveAs(
                        new Blob([buffer], {
                          type: "application/octet-stream",
                        }),
                        "RepOffice_" + getTrad("repartos") + ".xlsx",
                      );
                    });
                  });
                  e.cancel = true;
                },
                onCellHoverChanged: function (e) {
                  if (tipoTablaSel === 3) {
                    if (
                      e.rowType === "totalFooter" &&
                      e.column.name === "kgEntregados" &&
                      e.eventType === "mouseover"
                    ) {
                      var totalEntregadas =
                        e.component.getTotalSummaryValue("kgEntregados"),
                        kgAbonados =
                          prendaSel.length > 0
                            ? prendaSel[0].abonadas * prendaSel[0].peso
                            : abonos.kgs;

                      if (kgAbonados > 0) {
                        var enum_abonos = [
                          {
                            idTipoDato: 1,
                            denominacion: getTrad("entregados"),
                            kgs:
                              formatNumber(
                                totalEntregadas + kgAbonados,
                                2,
                                "decimal",
                              ) + " Kg.",
                          },
                          {
                            idTipoDato: 2,
                            denominacion: getTrad("abonados"),
                            kgs:
                              "-" +
                              formatNumber(kgAbonados, 2, "decimal") +
                              " Kg.",
                          },
                        ];

                        popover_abonoEntrega.option(
                          "contentTemplate",
                          function (contentElement) {
                            $("<div/>")
                              .append(
                                $("<div />").dxDataGrid({
                                  dataSource: enum_abonos,
                                  showColumnHeaders: false,
                                  columns: [
                                    {
                                      dataField: "denominacion",
                                      caption: " ",
                                    },
                                    {
                                      dataField: "kgs",
                                      caption: "Kgs",
                                      alignment: "center",
                                    },
                                  ],
                                }),
                              )
                              .appendTo(contentElement);
                          },
                        );
                        popover_abonoEntrega.option({
                          target: e.cellElement,
                        });

                        popover_abonoEntrega.show();
                      }
                    } else popover_abonoEntrega.hide();
                  }
                },
                onSelectionChanged: function (e) {
                  if (tipoTablaSel === 3) {
                    var dxDataGrid_prendas = $(
                      "#dxDataGrid_prendas",
                    ).dxDataGrid("instance");
                    dxDataGrid_prendas.option("loadPanel.enabled", true);

                    if (e.selectedRowKeys.length > 0) {
                      repartoOfficeSel = e.selectedRowsData;
                      $("#dxButton_eliminarSeleccion")
                        .dxButton("instance")
                        .option("disabled", false);
                      dxDataGrid_prendas.option(
                        "dataSource",
                        datasource_spSelectPrendas,
                      );
                    } else {
                      dxDataGrid_prendas.option("dataSource", []);
                      repartoOfficeSel = [];
                    }
                  }
                },
                onCellPrepared: function (e) {
                  if (e.rowType === "totalFooter") {
                    if (
                      e.column.name === "kgEntregados" &&
                      tipoTablaSel === 3
                    ) {
                      var kgAbonados =
                        prendaSel.length > 0
                          ? prendaSel[0].abonadas * prendaSel[0].peso
                          : abonos.kgs;

                      if (kgAbonados > 0) {
                        var imgWarning = svg_warning
                          .clone()
                          .css("display", "block")
                          .css("float", "right")
                          .css("height", "15px")
                          .css("width", "15px");
                        e.cellElement
                          .find(".dx-datagrid-summary-item")
                          .append(imgWarning);
                      }
                    }
                  }
                },
                onRowPrepared: function (e) {
                  if (e.rowType === "data" && tipoTablaSel === 3)
                    e.rowElement.css("cursor", "pointer");
                },
                //Estilos
                showColumnLines: false,
                showRowLines: true,
                rowAlternationEnabled: true,
              }),
            );
          },
        },
      };

      $("#InfReposicionesOffice #dxContainer").dxBox({
        direction: "col",
        align: "space-around",
        crossAlign: "stretch",
        height: "100%",
        items: [
          {
            baseSize: 70,
            template: function (e, index, item) {
              var container = $("<div />").css("display", "flex");

              container.append(
                $("<div />")
                  .addClass("titForm")
                  .append($("<h3 />").text(entidadSel.denominacion)),
              );

              container.append(
                $("<div />")
                  .css("flex", "1")
                  .dxToolbar({
                    width: 300,
                    items: [
                      {
                        location: "after",
                        widget: "dxButton",
                        options: {
                          text: getTrad("limpiarFiltro"),
                          icon: " icon_EliminarFiltros",
                          onInitialized: function (e) {
                            tooltipControl_creacion(
                              e.element,
                              e.component.option("text"),
                            );
                          },
                          onClick: function (e) {
                            var dxDataGrid_almacenes = $(
                              "#dxDataGrid_almacenes",
                            ).dxDataGrid("instance"),
                              dxDataGrid_repartosOffice = $(
                                "#dxDataGrid_repartosOffice",
                              ).dxDataGrid("instance");
                            $("#dxRadioGroup_familias")
                              .dxRadioGroup("instance")
                              .option("value", 0);
                            $("#dxDataGrid_prendas")
                              .dxDataGrid("instance")
                              .clearFilter();

                            if (dxDataGrid_almacenes) {
                              if (dxDataGrid_almacenes.getDataSource()) {
                                dxDataGrid_almacenes.clearFilter();
                              }
                            }

                            if (dxDataGrid_repartosOffice) {
                              if (dxDataGrid_repartosOffice.getDataSource()) {
                                dxDataGrid_repartosOffice.clearFilter();
                              }
                            }

                            notify({
                              message: getTrad("aviso_C_FiltroRestaurado"),
                              type: "success",
                              displayTime: "1500",
                              closeOnClick: true,
                            });
                          },
                        },
                      },
                      {
                        location: "after",
                        widget: "dxButton",
                        options: {
                          icon: "refresh",
                          text: getTrad("actualizar"),
                          width: "auto",
                          onClick: function (e) {
                            COMPONENT.loadPanel_show(true);

                            if ($("#dxDataGrid_prendas").length > 0) {
                              var dxDataGrid_prendas = $(
                                "#dxDataGrid_prendas",
                              ).dxDataGrid("instance");
                              if (dxDataGrid_prendas && tipoTablaSel === 1) {
                                dxDataGrid_prendas.refresh();
                              }
                            }

                            if ($("#dxDataGrid_almacenes").length > 0) {
                              var dxDataGrid_almacenes = $(
                                "#dxDataGrid_almacenes",
                              ).dxDataGrid("instance");
                              if (dxDataGrid_almacenes && tipoTablaSel === 2) {
                                dxDataGrid_almacenes.refresh();
                              }
                            }

                            if ($("#dxDataGrid_repartosOffice").length > 0) {
                              var dxDataGrid_repartosOffice = $(
                                "#dxDataGrid_repartosOffice",
                              ).dxDataGrid("instance");
                              if (
                                dxDataGrid_repartosOffice &&
                                tipoTablaSel === 3
                              ) {
                                dxDataGrid_repartosOffice.refresh();
                              }
                            }
                          },
                        },
                      },
                    ],
                  }),
              );

              item.append(container);
            },
          },
          {
            ratio: 1,
            template: function (e, index, item) {
              item.append(
                $("<div id='dxBox_principal' />").dxBox({
                  direction: "row",
                  align: "space-around",
                  crossAlign: "stretch",
                  height: "100%",
                  items: [
                    {
                      ratio: 3,
                      template: function (e, index, item) {
                        item.append(
                          $("<div />").dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              items_toolbars.primario,
                              items_principales.prendas,
                            ],
                          }),
                        );
                      },
                    },
                    {
                      ratio: 2,
                      template: function (e, index, item) {
                        item.append(
                          $("<div />").dxBox({
                            direction: "col",
                            align: "space-around",
                            crossAlign: "stretch",
                            height: "100%",
                            items: [
                              items_toolbars.secundario,
                              items_principales.almacenes,
                            ],
                          }),
                        );
                      },
                    },
                  ],
                }),
              );
            },
          },
          {
            baseSize: 130,
            template: function (e, index, itemElement) {
              itemElement.append(
                create_rangeSelector(
                  datasource_filtroTiempo_tblRepartoOffice,
                  false,
                  false,
                  function (e) {
                    COMPONENT.loadPanel_show(true);
                    opciones.fechaDesde = e.value[0];
                    opciones.fechaHasta = e.component.option("valueHasta");

                    $("#dxRadioGroup_familias")
                      .dxRadioGroup("instance")
                      .option("value", 0);
                    resetGrids();
                    datasource_abonos.reload();

                    //#region Quitar loading panel por defecto de grid para que no salgan ambos al cambiar fecha
                    var dxDataGrid_prendas = $(
                      "#dxDataGrid_prendas",
                    ).dxDataGrid("instance"),
                      dxDataGrid_almacenes = $(
                        "#dxDataGrid_almacenes",
                      ).dxDataGrid("instance"),
                      dxDataGrid_repartosOffice = $(
                        "#dxDataGrid_repartosOffice",
                      ).dxDataGrid("instance");

                    dxDataGrid_prendas.option("loadPanel.enabled", false);
                    if (typeof dxDataGrid_almacenes !== "undefined")
                      dxDataGrid_almacenes.option("loadPanel.enabled", false);
                    if (typeof dxDataGrid_repartosOffice !== "undefined")
                      dxDataGrid_repartosOffice.option(
                        "loadPanel.enabled",
                        false,
                      );
                    //#endregion

                    if (tipoTablaSel === 1)
                      $("#dxDataGrid_prendas")
                        .dxDataGrid("instance")
                        .option("dataSource", datasource_spSelectPrendas);
                    else if (tipoTablaSel === 2)
                      $("#dxDataGrid_almacenes")
                        .dxDataGrid("instance")
                        .option("dataSource", datasource_spSelectAlmacenes);
                    else if (tipoTablaSel === 3)
                      $("#dxDataGrid_repartosOffice")
                        .dxDataGrid("instance")
                        .option(
                          "dataSource",
                          datasource_spSelectRepartosOffice,
                        );

                    $("#dxButton_eliminarSeleccion")
                      .dxButton("instance")
                      .option("disabled", true);
                    dxRangeSelector_PosicionValor_nuevoMarker(
                      $("#dxRangeSelector_filtroTiempo"),
                      opciones.fechaDesde,
                      opciones.fechaHasta,
                    );
                  },
                  function () {
                    $("#dxRangeSelector_filtroTiempo")
                      .dxRangeSelector("instance")
                      .option({
                        "scale.minRange": { days: null, months: 1 },
                      });
                  },
                  function () {
                    $("#dxRangeSelector_filtroTiempo")
                      .dxRangeSelector("instance")
                      .option({
                        "scale.minRange": { days: null, months: null },
                      });
                  }, //Diario
                  [opciones.fechaDesde, opciones.fechaHasta], //Values
                ),
              );
            },
          },
        ],
      });

      function resetGrids() {
        var dxDataGrid_prendas = $("#dxDataGrid_prendas").dxDataGrid(
          "instance",
        ),
          dxDataGrid_almacenes = $("#dxDataGrid_almacenes").dxDataGrid(
            "instance",
          ),
          dxDataGrid_repartosOffice = $(
            "#dxDataGrid_repartosOffice",
          ).dxDataGrid("instance");

        if (tipoTablaSel === 1) {
          dxDataGrid_prendas.clearSelection();
          dxDataGrid_prendas.getScrollable().scrollTo(0);
        }

        if (
          typeof dxDataGrid_almacenes !== "undefined" &&
          dxDataGrid_almacenes.getDataSource()
        ) {
          dxDataGrid_almacenes.clearSelection();
          dxDataGrid_almacenes.clearFilter();
          dxDataGrid_almacenes.getScrollable().scrollTo(0);
        }

        if (
          typeof dxDataGrid_repartosOffice !== "undefined" &&
          dxDataGrid_repartosOffice.getDataSource()
        ) {
          dxDataGrid_repartosOffice.clearSelection();
          dxDataGrid_repartosOffice.clearFilter();
          dxDataGrid_repartosOffice.getScrollable().scrollTo(0);
        }
      }

      $("#InfReposicionesOffice #dxPopup_repartos").dxPopup({
        showTitle: true,
        title: getTrad("repartos"),
        deferRendering: false,
        height: 600,
        width: 500,
        contentTemplate: function (e) {
          return $("<div id='dxBox_popup' />").dxBox({
            direction: "col",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            items: [items_toolbars.popup, items_principales.repartos],
          });
        },
        onContentReady: function (e) {
          var html = e.component.content();
          $(html).css("padding-top", 15);
        },
        onShowing: function () {
          $("#dxDataGrid_repartosOffice").dxDataGrid("instance").option({
            "paging.enabled": false,
          });
        },
        onShown: function (e) {
          tooltipControl_creacion(
            $("#titReparto_container"),
            $("#title_prendaSel").text() +
            " - " +
            $("#title_almacenSel").text(),
          );
        },
        onHiding: function (e) {
          $("#dxDataGrid_repartosOffice")
            .dxDataGrid("instance")
            .getScrollable()
            .scrollTo(0);

          if (tipoTablaSel === 1)
            $("#dxDataGrid_almacenes").dxDataGrid("instance").clearSelection();
          else if (tipoTablaSel === 2)
            $("#dxDataGrid_prendas").dxDataGrid("instance").clearSelection();
        },
        onHidden: function () {
          $("#dxDataGrid_repartosOffice").dxDataGrid("instance").option({
            dataSource: [],
            "paging.enabled": true,
            "loadPanel.enabled": false,
          });
        },
      });

      $("#btnAtras_InformeRepOffice").on("click", function () {
        var scrollLeft = Math.ceil(
          $("#scrollView_dxRangeSelector")
            .dxScrollView("instance")
            .scrollLeft(),
        );
        remove_CustomMarker_RangeSelector($("#dxRangeSelector_filtroTiempo"));

        COMPONENT.setState({
          showSelector: true,
          idSel: entidadSel.idCompañia,
          fechaHasta: opciones.fechaHasta,
          fechaDesde: opciones.fechaDesde,
          scrollLeft: scrollLeft,
          fechaDesde_redireccion: null,
          fechaHasta_redireccion: null,
        });
      });

      tooltipControl_creacion(
        $("#btnAtras_InformeRepOffice"),
        getTrad("volverLavanderia"),
      );
    })
    .always(function () {
      let { fechaDesde_redireccion, fechaHasta_redireccion } = COMPONENT.state;
      if (fechaDesde_redireccion != null) {
        let items_filtroTiempo =
          datasource_filtroTiempo_tblRepartoOffice.items();
        let ultimaFecha = items_filtroTiempo[items_filtroTiempo.length - 1].arg;

        let fechaDesde_ =
          new Date(fechaDesde_redireccion) <= ultimaFecha
            ? new Date(fechaDesde_redireccion)
            : startOfMonth(new Date(ultimaFecha));
        let fechaHasta_ =
          new Date(fechaHasta_redireccion) <= ultimaFecha
            ? new Date(fechaHasta_redireccion)
            : ultimaFecha;

        opciones.fechaDesde = new Date(fechaDesde_);
        opciones.fechaHasta = new Date(fechaHasta_);
        $("#dxRangeSelector_filtroTiempo")
          .dxRangeSelector("instance")
          .option({
            value: [opciones.fechaDesde, opciones.fechaHasta],
            valueHasta: opciones.fechaHasta,
          });

        let fechas_paramAlert = {
          paramString: {
            desde: new Date(fechaDesde_),
            hasta: new Date(fechaHasta_),
          },
          bd: {
            desde: items_filtroTiempo[0].arg,
            hasta: items_filtroTiempo[1].arg,
          },
        };
        is_paramRedireccion_outOfRange(fechas_paramAlert);

        COMPONENT.setState({
          fechaDesde_redireccion: null,
          fechaHasta_redireccion: null,
        });
      }

      setTimeout(() => {
        filtroTiempo_Resize(opciones.scrollLeft);
      }, 50);
      deferred.resolve();
    });

  return deferred.promise();
}
