import React, { Fragment } from "react";
import { connect } from "react-redux";

//Actions
import { loadPanelActions } from "../../../actions";

//Component
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import { getNombreFormulario } from "../../../helpers";

//Js
import cargaJs from "./Js.js";

class ProduccionMaquina extends React.Component {
  constructor(props) {
    super(props);
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }

  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  render() {
    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1200}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="ProduccionMaquina" className={"formContainer"}>
            <div data-options="dxItem: {ratio: 9, baseSize: 0}">
              <div id="dxContainer">
                <div
                  data-options="dxItem: {ratio: 5}"
                  style={{ paddingRight: "30px", height: "100%" }}
                >
                  <div id="dxBox_col">
                    <div data-options="dxItem: {baseSize: 45, shrink: 0, ratio: 0}">
                      <div id="dxToolbar_ProdMaq"></div>
                    </div>
                    <div data-options="dxItem: {ratio: 1}">
                      <div id="dxDataGrid_ProdMaq"></div>
                    </div>
                  </div>
                </div>
                <div data-options="dxItem: {ratio: 4}">
                  <div
                    id="dxDataGrid_ProdAnteriores"
                    style={{ paddingTop: "45px" }}
                  ></div>
                </div>
              </div>
            </div>
            <div id="dxPopup_selCompaEnti"></div>
            <div id="dxPopup_ProdMaq_Añadir"></div>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia, idioma } = this.props;
    if (
      (lavanderia &&
        lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia) ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      this.loadPanel_show();
      cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
    }
  }

  componentDidMount() {
    let { lavanderia, idioma } = this.props;
    cargaJs(this, lavanderia, idioma).then(() => this.loadPanel_hide());
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProduccionMaquina);
