import { Component } from "react";
import { ControlPresupuestarioCxt } from "../contexts/context";
import { formatNumber } from "helpers";
import idsMoneda, { codigosMoneda } from "constants/enums/General/tblMoneda";

class CurrencyFormatter extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { value, idMoneda } = this.props;

        if (value == null || Number.isNaN(value) || idMoneda == null || codigosMoneda[idMoneda] == null) {
            return "---";
        }

        return formatNumber(value, 2, "currency", codigosMoneda[idMoneda]);
    }
}

CurrencyFormatter.contextType = ControlPresupuestarioCxt;

export default CurrencyFormatter;
