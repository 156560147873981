import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  handleErrors,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

import ArrayStore from "devextreme/data/array_store";
import { Button, DropDownBox } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Lookup,
  Editing,
  Texts,
  Scrolling,
} from "devextreme-react/data-grid";

import {
  Form,
  Item,
  EmptyItem,
  RequiredRule,
  AsyncRule,
} from "devextreme-react/form";
import { Row, Col } from "reactstrap";
import { Popup } from "devextreme-react/popup";

//DEVEXTREME JQUERY
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";

//CUSTOM COMPONENTS
import { removeCellComponent } from "../../../components/DataGrid/Cells";

//Css
import "./Css.scss";

class ConfigUniformidad extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tipoPrendaSel: null,
      tipoPrendaSel_bak: null,
      prendaSel: null,
      prendaSel_bak: null,
      personaSel: null,
      personaSel_bak: null,
      departamentoSel: null,
      departamentoSel_bak: null,
      tblPersona_MyUnif_lookup: null,
      tblPersona_MyUnif_form: null,
      tipoPrenda_tallaSel: null,
      datasource_tblDepartamento: null,
      datasource_tblTipoPrenda: null,
      datasource_tblTalla_lookup: null,
      isVisible_popupCodigosPersona: false,
      datasource_spSelectCodigoLibrePersona: [],
    };

    this.data = {
      tipoPrendaSel_validation_row: null, //row pendiente de validación para que si das a cancelar vuelva atrás
      prendaSel_validation_row: null,
      personaSel_validation_row: null,
      departamentoSel_validation_row: null,
      dataSource_tallaNTipoPrenda: null,
      comprobar_barcode: null,
      comprobar_codigoPersona: null,
    };

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    this.cargaDatos_lavanderia = this.cargaDatos_lavanderia.bind(this);

    //#region TIPO DE PRENDA
    // REFERENCIAS
    this.dxDataGrid_tblTipoPrenda_MyUnif = React.createRef();
    this.dxTabPanel_general_REF = React.createRef();
    this.dxForm_tblPersona_REF = React.createRef();
    this.dxDataGrid_codigoLibrePersona_REF = React.createRef();

    //DATASOURCE
    this.datasource_tblTipoPrenda_onLoaded =
      this.datasource_tblTipoPrenda_onLoaded.bind(this);
    this.datasource_tblTipoPrenda_onInserted =
      this.datasource_tblTipoPrenda_onInserted.bind(this);
    this.datasource_tblTipoPrenda_onUpdated =
      this.datasource_tblTipoPrenda_onUpdated.bind(this);

    // EVENTOS
    this.onSelectionChanged_tblTipoPrenda =
      this.onSelectionChanged_tblTipoPrenda.bind(this);
    this.onToolbarPreparing_tblTipoPrenda =
      this.onToolbarPreparing_tblTipoPrenda.bind(this);
    this.onEditorPreparing_tblTipoPrenda =
      this.onEditorPreparing_tblTipoPrenda.bind(this);
    this.dxSelectBox_genero_tipoPrenda_onSelectionChanged =
      this.dxSelectBox_genero_tipoPrenda_onSelectionChanged.bind(this);
    this.onRowRemoving_tblTipoPrenda =
      this.onRowRemoving_tblTipoPrenda.bind(this);
    this.onRowRemoved_tblTipoPrenda =
      this.onRowRemoved_tblTipoPrenda.bind(this);
    this.datasource_tblTipoPrenda_postProcess =
      this.datasource_tblTipoPrenda_postProcess.bind(this);

    //RENDER
    this.genero_tipoPrenda_cellRender =
      this.genero_tipoPrenda_cellRender.bind(this);

    //MÉTODOS
    this.crearTipoPrenda = this.crearTipoPrenda.bind(this);
    this.submit_formTipoPrenda = this.submit_formTipoPrenda.bind(this);

    //#endregion

    //#region PRENDAS
    // REFERENCIAS
    this.dxDataGrid_tblPrenda_MyUnif = React.createRef();

    //DATASOURCE
    this.datasource_tblTalla_onLoaded =
      this.datasource_tblTalla_onLoaded.bind(this);
    this.datasource_tblPrenda_onInserted =
      this.datasource_tblPrenda_onInserted.bind(this);
    this.datasource_tblPrenda_onUpdated =
      this.datasource_tblPrenda_onUpdated.bind(this);

    // EVENTOS
    this.onSelectionChanged_tblPrenda =
      this.onSelectionChanged_tblPrenda.bind(this);
    this.onToolbarPreparing_tblPrenda =
      this.onToolbarPreparing_tblPrenda.bind(this);
    this.onRowRemoved_tblPrenda = this.onRowRemoved_tblPrenda.bind(this);
    this.idTipoPrenda_prenda_onSelectionChanged =
      this.idTipoPrenda_prenda_onSelectionChanged.bind(this);
    this.isExtra_prenda_onValueChanged =
      this.isExtra_prenda_onValueChanged.bind(this);
    this.datasource_tblPrenda_exists_MyUniform_onLoading =
      this.datasource_tblPrenda_exists_MyUniform_onLoading.bind(this);
    this.render_tblPersona = this.render_tblPersona.bind(this);
    this.onValueChanged_dropDownBox_tblPersona =
      this.onValueChanged_dropDownBox_tblPersona.bind(this);
    this.onRowClick_dropDownBox_tblPersona =
      this.onRowClick_dropDownBox_tblPersona.bind(this);

    //MÉTODOS
    this.crearPrenda = this.crearPrenda.bind(this);
    this.submit_formPrenda = this.submit_formPrenda.bind(this);
    this.asyncValidation_barcode = this.asyncValidation_barcode.bind(this);
    //#endregion

    //#region PERSONAS
    // REFERENCIAS
    this.dxDataGrid_tblPersona_MyUnif = React.createRef();

    //DATASOURCE
    this.datasource_tblPersona_onInserted =
      this.datasource_tblPersona_onInserted.bind(this);
    this.datasource_tblPersona_onUpdated =
      this.datasource_tblPersona_onUpdated.bind(this);
    this.datasource_tblPersona_MyUnif_lookup_load =
      this.datasource_tblPersona_MyUnif_lookup_load.bind(this);
    this.datasource_tblPersona_exists_MyUniform_onLoading =
      this.datasource_tblPersona_exists_MyUniform_onLoading.bind(this);
    this.datasource_spSelectCodigoLibrePersona_onLoaded =
      this.datasource_spSelectCodigoLibrePersona_onLoaded.bind(this);

    // EVENTOS
    this.onSelectionChanged_tblPersona =
      this.onSelectionChanged_tblPersona.bind(this);
    this.onToolbarPreparing_tblPersona =
      this.onToolbarPreparing_tblPersona.bind(this);

    this.onRowRemoving_tblPersona = this.onRowRemoving_tblPersona.bind(this);
    this.onRowRemoved_tblPersona = this.onRowRemoved_tblPersona.bind(this);
    this.popupCodigosPersona_onHiding =
      this.popupCodigosPersona_onHiding.bind(this);
    this.gridCodigoLibrePersona_onContentReady =
      this.gridCodigoLibrePersona_onContentReady.bind(this);
    this.onSelectionChanged_codigoLibre =
      this.onSelectionChanged_codigoLibre.bind(this);

    //MÉTODOS
    this.crearPersona = this.crearPersona.bind(this);
    this.submit_formPersona = this.submit_formPersona.bind(this);
    this.asyncValidation_codigoPersona =
      this.asyncValidation_codigoPersona.bind(this);
    //#endregion

    //#region DEPARTAMENTOS
    // REFERENCIAS
    this.dxDataGrid_tblDepartamento_MyUnif = React.createRef();

    //DATASOURCE
    this.datasource_tblDepartamento_onLoaded =
      this.datasource_tblDepartamento_onLoaded.bind(this);
    this.datasource_tblDepartamento_onInserted =
      this.datasource_tblDepartamento_onInserted.bind(this);
    this.datasource_tblDepartamento_onUpdated =
      this.datasource_tblDepartamento_onUpdated.bind(this);

    // EVENTOS
    this.onSelectionChanged_tblDepartamento =
      this.onSelectionChanged_tblDepartamento.bind(this);

    this.onToolbarPreparing_tblDepartamento =
      this.onToolbarPreparing_tblDepartamento.bind(this);
    this.onRowRemoving_tblDepartamento =
      this.onRowRemoving_tblDepartamento.bind(this);
    this.onRowRemoved_tblDepartamento =
      this.onRowRemoved_tblDepartamento.bind(this);

    // //MÉTODOS
    this.crearDepartamento = this.crearDepartamento.bind(this);
    this.submit_formDepartamento = this.submit_formDepartamento.bind(this);
    //#endregion
  }

  //REFERENCIAS
  get dxDataGrid_tblPrenda() {
    return this.dxDataGrid_tblPrenda_MyUnif.current.instance;
  }
  get dxDataGrid_tblPersona() {
    return this.dxDataGrid_tblPersona_MyUnif.current.instance;
  }
  get dxDataGrid_tblDepartamento() {
    return this.dxDataGrid_tblDepartamento_MyUnif.current.instance;
  }

  get dxTabPanel_general() {
    return this.dxTabPanel_general_REF.current.instance;
  }

  get dxForm_tblPersona() {
    return this.dxForm_tblPersona_REF.current.instance;
  }
  get dxDataGrid_codigoLibrePersona() {
    return this.dxDataGrid_codigoLibrePersona_REF.current.instance;
  }

  //#region TIPO PRENDA
  //DATAGRID
  datasource_tblTipoPrenda = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoPrenda_MyUnif",
      key: "idTipoPrenda",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      onLoaded: (request) => {
        this.datasource_tblTipoPrenda_onLoaded(request);
      },
      beforeSend: (request) => {
        this.datasource_tblTipoPrenda_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblTipoPrenda_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblTipoPrenda_onUpdated(values, key);
      },
      version: 4,
    }),
    expand: ["tblTalla_MyUnif"],
    postProcess: (data) => {
      return this.datasource_tblTipoPrenda_postProcess(data);
    },
  });

  datasource_tblTipoPrenda_postProcess(data) {
    $.each(data, function (index, item) {
      item.tblTalla_MyUnif_ids = $.map(item.tblTalla_MyUnif, function (talla) {
        return talla.idTalla;
      });
    });
    return data;
  }

  datasource_tblTipoPrenda_onLoaded(result) {
    this.setState({ datasource_tblTipoPrenda: result });
  }
  datasource_tblTipoPrenda_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }
  datasource_tblTipoPrenda_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { tipoPrendaSel } = this.state;
    tipoPrendaSel.idTipoPrenda = key;
    let tipoPrendaSel_ = $.extend(true, {}, tipoPrendaSel);
    _this.setState({
      tipoPrendaSel: tipoPrendaSel,
      tipoPrendaSel_bak: tipoPrendaSel_,
    });
    this.datasource_tblTipoPrenda.load();
  }
  datasource_tblTipoPrenda_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.datasource_tblPrenda_MyUnif.load();
    this.datasource_tblTipoPrenda.load();
  }

  //#endregion

  //#region PRENDAS
  datasource_tblPrenda_MyUnif = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrenda_MyUnif",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPrenda_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblPrenda_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblPrenda_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
  });

  datasource_tblPrenda_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  datasource_tblPrenda_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { prendaSel } = this.state;
    prendaSel.idPrenda = key;
    let prendaSel_ = $.extend(true, {}, prendaSel);

    this.dxDataGrid_tblPrenda.refresh().done(function () {
      _this.setState({ prendaSel: prendaSel, prendaSel_bak: prendaSel_ });
    });
  }

  datasource_tblPrenda_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.dxDataGrid_tblPrenda.refresh();
  }

  datasource_tblPersona_MyUnif_lookup_form = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona_MyUnif",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_beforeSend(request);
      },
      version: 4,
    }),
    map: (itemData) => {
      return this.datasource_tblPersona_MyUnif_map(itemData);
    },
    sort: ["nombre", "apellidos"],
    filter: [
      ["idEstadoPersona", "<>", 0],
      "and",
      ["codigo", "<>", null],
      "and",
      ["codigo", "<>", ""],
    ],
  });

  datasource_tblPersona_MyUnif_lookup = new DataSource({
    store: new CustomStore({
      key: "idPersona",
      load: () => {
        this.datasource_tblPersona_MyUnif_lookup_load();
      },
    }),
  });

  datasource_tblPersona_MyUnif_lookup_load() {
    var deferred = $.Deferred();
    let _this = this;
    _this.datasource_tblPersona_MyUnif_lookup_form
      .load()
      .done(function (personas) {
        let personas_form = $.extend(true, [], personas);
        personas.splice(0, 0, {
          idPersona: "null",
          nombreCompuesto: getTrad("noAsociado"),
        });

        _this.setState({
          tblPersona_MyUnif_lookup: personas,
          tblPersona_MyUnif_form: personas_form,
        });
        deferred.resolve(personas);
      });
    return deferred.promise();
  }

  datasource_tblPersona_MyUnif = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona_MyUnif",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_beforeSend(request);
      },
      version: 4,
    }),
    map: (itemData) => {
      return this.datasource_tblPersona_MyUnif_map(itemData);
    },
  });

  datasource_tblPersona_MyUnif_map(itemData) {
    return {
      idPersona: itemData.idPersona,
      nombreCompuesto: itemData.nombre + " " + itemData.apellidos,
      codigo: itemData.codigo,
    };
  }

  datasource_tblPersona_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  datasource_tblEstado_MyUnif = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEstado_MyUnif",
      key: "idEstado",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  datasource_tblTalla_MyUnif = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTalla_MyUnif",
      key: "idTalla",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      onLoaded: (request) => {
        this.datasource_tblTalla_onLoaded(request);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    key: "idTalla",
    group: "isDigito",
  });
  datasource_tblTalla_onLoaded(result) {
    let ids_tblTalla = [];

    $.each(result, function (index, item) {
      $.each(item.items, function (iTalla, talla) {
        ids_tblTalla.push(talla);
      });
    });

    //#region SORT datasource_tblTalla_lookup
    const digitoTrue = ids_tblTalla.filter((talla) => talla.isDigito);
    const digitoFalse = ids_tblTalla.filter((talla) => !talla.isDigito);

    digitoTrue.sort(
      (a, b) => parseInt(a.denominacion) - parseInt(b.denominacion),
    );
    const sortedTallas = [...digitoTrue, ...digitoFalse];
    //#endregion

    //#region SORT datasource_tblTalla
    const result_keyTrue = result.filter((item) => item.key);
    const result_keyFalse = result.filter((item) => !item.key);

    if (result_keyTrue.length)
      result_keyTrue[0].items.sort(
        (a, b) => parseInt(a.denominacion) - parseInt(b.denominacion),
      );
    const sortedResult = [...result_keyFalse, ...result_keyTrue];
    //#endregion

    this.setState({
      datasource_tblTalla: sortedResult,
      datasource_tblTalla_lookup: sortedTallas,
    });
  }

  datasource_tblPrenda_exists_MyUniform = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrenda_MyUnif",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPrenda_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblPrenda_exists_MyUniform_onLoading(loadOptions);
      },
      version: 4,
    }),
  });
  datasource_tblPrenda_exists_MyUniform_onLoading(loadOptions) {
    let barcode = this.data.comprobar_barcode;
    let idPrenda = this.state.prendaSel.idPrenda;

    if (idPrenda !== undefined) {
      loadOptions.filter = [
        ["barcode", "=", barcode],
        "and",
        ["idPrenda", "<>", idPrenda],
      ];
    } else {
      loadOptions.filter = [["barcode", "=", barcode]];
    }
  }
  //#endregion

  //#region PERSONAS
  datasource_tblPersona_DataGrid = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona_MyUnif",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblPersona_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblPersona_onUpdated(values, key);
      },
      onRemoved: function () {
        notify({
          message: getTrad("aviso_C_RegistroEliminado"),
          type: "success",
          displayTime: "1500",
          closeOnClick: true,
        });
      },
      version: 4,
    }),
    expand: ["tblPrenda_MyUnif"],
  });

  datasource_tblPersona_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let _this = this;
    let { personaSel } = this.state;
    personaSel.idPersona = key;
    let personaSel_ = $.extend(true, {}, personaSel);

    $.when(
      this.datasource_tblPersona_MyUnif_lookup_form.reload(),
      this.datasource_tblPersona_DataGrid.reload(),
      this.datasource_tblPersona_MyUnif.reload(),
      this.datasource_tblPersona_MyUnif_lookup.reload(),
    ).done(function () {
      _this.setState({ personaSel: personaSel, personaSel_bak: personaSel_ });
    });
  }

  datasource_tblPersona_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.datasource_tblPersona_MyUnif_lookup_form.reload();
    this.datasource_tblPersona_DataGrid.reload();
    this.datasource_tblPersona_MyUnif.reload();
    this.datasource_tblPersona_MyUnif_lookup.reload();
    this.datasource_tblPrenda_MyUnif.reload();
  }

  datasource_tblPersona_exists_MyUniform = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPersona_MyUnif",
      key: "idPrenda",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblPersona_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblPersona_exists_MyUniform_onLoading(loadOptions);
      },
      version: 4,
    }),
  });
  datasource_tblPersona_exists_MyUniform_onLoading(loadOptions) {
    let { personaSel } = this.state;
    let codigo = this.data.comprobar_codigoPersona;

    if (codigo !== undefined) {
      if (typeof personaSel.idPersona != "undefined") {
        loadOptions.filter = [
          ["codigo", "=", codigo.toString()],
          "and",
          ["idEstadoPersona", "<>", 2],
          "and",
          ["idPersona", "<>", personaSel.idPersona],
        ];
      } else {
        loadOptions.filter = [
          ["codigo", "=", codigo.toString()],
          "and",
          ["idEstadoPersona", "<>", 2],
        ];
      }
    }
  }

  enum_genero_tipoPrenda = [
    {
      value: false,
      denominacion: getTrad("mujer"),
    },
    {
      value: true,
      denominacion: getTrad("hombre"),
    },
  ];

  enum_genero_persona = [
    {
      value: false,
      denominacion: getTrad("mujer"),
    },
    {
      value: true,
      denominacion: getTrad("hombre"),
    },
  ];

  datasource_spSelectCodigoLibrePersona = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectCodigoLibrePersona",
      key: "codigo",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_spSelectCodigoLibrePersona_beforeSend(request);
      },
      onLoaded: (request) => {
        this.datasource_spSelectCodigoLibrePersona_onLoaded(request);
      },
      version: 4,
    }),
  });

  datasource_spSelectCodigoLibrePersona_onLoaded(result) {
    let { personaSel } = this.state;
    if (personaSel && personaSel.codigo)
      result.push({ codigo: personaSel.codigo });
    this.setState({ datasource_codigoLibrePersona: result });
  }

  datasource_spSelectCodigoLibrePersona_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;

    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.todos = true;
  }
  //#endregion

  //#region DEPARTAMENTOS
  datasource_tblDepartamento_DataGrid = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblDepartamento_MyUnif",
      key: "idDepartamento",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      onLoaded: (request) => {
        this.datasource_tblDepartamento_onLoaded(request);
      },
      beforeSend: (request) => {
        this.datasource_tblDepartamento_beforeSend(request);
      },
      onInserted: (values, key) => {
        this.datasource_tblDepartamento_onInserted(values, key);
      },
      onUpdated: (values, key) => {
        this.datasource_tblDepartamento_onUpdated(values, key);
      },
      version: 4,
    }),
  });

  datasource_tblDepartamento_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }

  datasource_tblDepartamento_onLoaded(result) {
    this.setState({ datasource_tblDepartamento: result });
  }

  datasource_tblDepartamento_onInserted(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroInsertado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    let { departamentoSel } = this.state;
    departamentoSel.idDepartamento = key;
    let departamentoSel_ = $.extend(true, {}, departamentoSel);

    this.setState({
      departamentoSel: departamentoSel,
      departamentoSel_bak: departamentoSel_,
    });
    this.datasource_tblDepartamento_DataGrid.load();
  }

  datasource_tblDepartamento_onUpdated(values, key) {
    notify({
      message: getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });

    this.datasource_tblPersona_MyUnif.reload();
    this.datasource_tblPersona_MyUnif_lookup.reload();
    this.datasource_tblPersona_MyUnif_lookup_form.reload();
    this.datasource_tblPersona_DataGrid.reload();
    this.datasource_tblDepartamento_DataGrid.load();
  }
  //#endregion
  //#endregion

  cargaDatos_lavanderia() {
    let _this = this;

    let { isNum_codPersona_MyUniform } = this.props.lavanderia;

    //#region para que se vean los items de los lookups de datagrid prendas
    this.datasource_tblDepartamento_DataGrid.load();
    this.datasource_tblTipoPrenda.load();
    this.datasource_tblPersona_MyUnif_lookup.load();
    this.datasource_tblPersona_MyUnif_lookup_form.load();
    this.datasource_tblEstado_MyUnif.load();
    this.datasource_spSelectCodigoLibrePersona.reload();
    //#endregion

    this.datasource_tblTalla_MyUnif.load().done(function () {
      //#region Resetear datos del state
      _this.crearPrenda();
      _this.crearTipoPrenda();
      _this.crearPersona();
      _this.crearDepartamento();
      //#endregion

      _this.loadPanel_hide();
    });

    this.codigo_editorType = isNum_codPersona_MyUniform
      ? "dxNumberBox"
      : "dxTextBox";

    this.codigo_editorOptions = isNum_codPersona_MyUniform
      ? {
          max: 9999999999,
          buttons: [
            {
              location: "after",
              name: "btn_codigosLibres",
              options: {
                visible: true,
                height: "100%",
                icon: "spindown",
                type: "default",
                onClick: function (e) {
                  _this.setState({ isVisible_popupCodigosPersona: true });
                },
              },
            },
          ],
        }
      : {
          maxLength: 10,
          buttons: [
            {
              location: "after",
              name: "btn_codigosLibres",
              options: {
                visible: false,
              },
            },
          ],
        };
  }

  render() {
    let {
      tipoPrendaSel,
      prendaSel,
      personaSel,
      departamentoSel,
      isVisible_popupCodigosPersona,
      datasource_codigoLibrePersona,
      datasource_tblDepartamento,
    } = this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          <div
            id="ConfigUniformidad"
            className="formContainer scrollbar-container"
          >
            <TabPanel
              height="100%"
              width="100%"
              deferRendering={false}
              animationEnabled={true}
              swipeEnabled={false}
              ref={this.dxTabPanel_general_REF}
              onContentReady={this.tabPanel_onContentReady}
            >
              <TabPanelItem title={getTrad("tipoPrenda")} tabIndex={0}>
                <Row className="pt-4 he-100">
                  <Col xs="12" md="6" className="pl-3">
                    <form
                      onSubmit={this.submit_formTipoPrenda}
                      className="d-flex flex-column he-100 w-100"
                    >
                      <div className="text-left pb-3">
                        <Toolbar>
                          <ToolbarItem location="before">
                            <Button
                              text={getTrad("nuevoTipoPrenda")}
                              icon="add"
                              onClick={this.crearTipoPrenda}
                            />
                          </ToolbarItem>
                          <ToolbarItem location="after">
                            <Button
                              text={getTrad("actualizar")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_tipoPrenda"
                              visible={
                                tipoPrendaSel && tipoPrendaSel.idTipoPrenda
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              text={getTrad("crearTipoPrenda")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_tipoPrenda"
                              visible={
                                !(tipoPrendaSel && tipoPrendaSel.idTipoPrenda)
                              }
                            />
                          </ToolbarItem>
                        </Toolbar>
                      </div>
                      <div className="flex-grow-1">
                        <Form
                          height="100%"
                          formData={tipoPrendaSel}
                          ref={this.dxForm_tblTipoPrenda_REF}
                          labelLocation="top"
                          scrollingEnabled={true}
                          screenByWidth={this.screenByWidth}
                          colCountByScreen={this.colCountByScreen}
                          validationGroup="validationGroup_form_tipoPrenda"
                        >
                          <Item
                            dataField="denominacion"
                            label={this.formLabel_denominacion}
                            colSpan={3}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="precio"
                            label={this.formLabel_precio}
                            format={this.currency_format}
                            editorType="dxNumberBox"
                            editorOptions={this.currency_editorOptions}
                            colSpan={2}
                          />
                          <Item
                            dataField="coste"
                            label={this.formLabel_coste}
                            format={this.currency_format}
                            editorType="dxNumberBox"
                            editorOptions={this.currency_editorOptions}
                            colSpan={2}
                          />
                          <Item
                            dataField="peso"
                            label={this.formLabel_peso_gramos}
                            format={"#"}
                            editorType="dxNumberBox"
                            editorOptions={this.pesoG_editorOptions}
                            colSpan={2}
                          />
                          <Item
                            dataField="genero"
                            label={this.formLabel_genero}
                            colSpan={2}
                            editorType="dxSelectBox"
                            editorOptions={this.genero_tipoPrenda_editorOptions}
                          ></Item>
                          {this.state.datasource_tblTalla ? (
                            <Item
                              dataField="tblTalla_MyUnif_ids"
                              label={this.formLabel_talla}
                              colSpan={2}
                              editorType="dxTagBox"
                              editorOptions={{
                                dataSource: this.state.datasource_tblTalla,
                                displayExpr: "denominacion",
                                valueExpr: "idTalla",
                                showSelectionControls: true,
                                grouped: true,
                                groupTemplate: function (e, _, groupElement) {
                                  groupElement.append(
                                    $("<span>").text(
                                      e.key ? "Números" : "Letras",
                                    ),
                                  );
                                },
                              }}
                            />
                          ) : null}
                        </Form>
                      </div>
                    </form>
                  </Col>
                  <Col xs="12" md="6" className="he-100">
                    <DataGrid
                      //Datos
                      dataSource={this.state.datasource_tblTipoPrenda}
                      keyExpr="idTipoPrenda"
                      elementAttr={
                        this.elementAttr_dxDataGrid_tblTipoPrenda_MyUnif
                      }
                      //Propiedades
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      hoverStateEnabled={true}
                      selection={this.tblTipoPrenda_selection}
                      selectedRowKeys={
                        tipoPrendaSel && tipoPrendaSel.idTipoPrenda
                          ? [tipoPrendaSel.idTipoPrenda]
                          : []
                      }
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onEditorPreparing={this.onEditorPreparing_tblTipoPrenda}
                      onToolbarPreparing={this.onToolbarPreparing_tblTipoPrenda}
                      onRowRemoving={this.onRowRemoving_tblTipoPrenda}
                      onRowRemoved={this.onRowRemoved_tblTipoPrenda}
                      onSelectionChanged={this.onSelectionChanged_tblTipoPrenda}
                      onRowPrepared={this.onRowPrepared_tblTipoPrenda}
                    >
                      <SearchPanel visible={true} width={240} />
                      <HeaderFilter visible={true} />
                      <FilterRow visible={true} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={this.tblTipoPrenda_allowedPageSizes}
                        showInfo={true}
                      />
                      <Column
                        dataField="denominacion"
                        caption={getTrad("denominacion")}
                        alignment="left"
                        allowHeaderFiltering={false}
                        sortOrder="asc"
                      />
                      <Column
                        dataField="precio"
                        caption={getTrad("precio")}
                        width={"15%"}
                        alignment="center"
                        allowHeaderFiltering={false}
                        dataType="number"
                        format={this.currency_format}
                        editorOptions={this.currency_editorOptions}
                      />
                      <Column
                        dataField="coste"
                        caption={getTrad("coste")}
                        width={"15%"}
                        alignment="center"
                        allowHeaderFiltering={false}
                        dataType="number"
                        format={this.currency_format}
                        editorOptions={this.currency_editorOptions}
                      />
                      <Column
                        dataField="peso"
                        caption={getTrad("peso_gramos")}
                        width={"15%"}
                        alignment="center"
                        allowHeaderFiltering={false}
                        dataType="number"
                      />
                      <Column
                        dataField="genero"
                        caption={getTrad("genero")}
                        width={120}
                        alignment="center"
                        allowHeaderFiltering={false}
                        dataType="boolean"
                        calculateFilterExpression={function (
                          filterValue,
                          selectedFilterOperation,
                        ) {
                          if (filterValue === "null") {
                            return [this.dataField, "=", null];
                          } else {
                            return [
                              this.dataField,
                              selectedFilterOperation || "=",
                              filterValue,
                            ];
                          }
                        }}
                        cellRender={this.genero_tipoPrenda_cellRender}
                      ></Column>
                      <Column
                        caption=" "
                        width={50}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={removeCellComponent}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </TabPanelItem>
              <TabPanelItem title={getTrad("prendas")} tabIndex={1}>
                <Row className="pt-4 he-100">
                  <Col xs="12" md="6" className="pl-3">
                    <form
                      onSubmit={this.submit_formPrenda}
                      className="d-flex flex-column he-100 w-100"
                    >
                      <div className="text-left pb-3">
                        <Toolbar>
                          <ToolbarItem location="before">
                            <Button
                              text={getTrad("nuevaPrenda")}
                              icon="add"
                              onClick={this.crearPrenda}
                            />
                          </ToolbarItem>
                          <ToolbarItem location="after">
                            <Button
                              text={getTrad("actualizar")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_prenda"
                              visible={
                                prendaSel && prendaSel.idPrenda ? true : false
                              }
                            />
                            <Button
                              text={getTrad("crearPrenda")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_prenda"
                              visible={!(prendaSel && prendaSel.idPrenda)}
                            />
                          </ToolbarItem>
                        </Toolbar>
                      </div>
                      <div className="flex-grow-1">
                        <Form
                          height="100%"
                          formData={prendaSel}
                          ref={this.dxForm_tblPrenda_REF}
                          labelLocation="top"
                          scrollingEnabled={true}
                          screenByWidth={this.screenByWidth}
                          colCountByScreen={this.colCountByScreen}
                          validationGroup="validationGroup_form_prenda"
                        >
                          <Item
                            dataField="barcode"
                            label={this.formLabel_barcode}
                            editorType="dxTextBox"
                            editorOptions={this.barcode_editorOptions}
                            colSpan={2}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                            <AsyncRule
                              message="El barcode introducido ya existe"
                              validationCallback={this.asyncValidation_barcode}
                            />
                          </Item>
                          <Item
                            dataField="idTipoPrenda"
                            label={this.formLabel_idTipoPrenda}
                            editorType="dxLookup"
                            editorOptions={{
                              dataSource: this.state.datasource_tblTipoPrenda,
                              displayExpr: "denominacion",
                              valueExpr: "idTipoPrenda",
                              dropDownOptions: this.dropDownOptions,
                              onSelectionChanged:
                                this.idTipoPrenda_prenda_onSelectionChanged,
                            }}
                            colSpan={4}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="isExtra"
                            label={this.formLabel_isExtra}
                            colSpan={1}
                            editorType="dxSwitch"
                            editorOptions={{
                              ...this.isExtra_editorOptions,
                              onValueChanged:
                                this.isExtra_prenda_onValueChanged,
                            }}
                          />
                          <Item
                            dataField="idPersona"
                            label={this.formLabel_idPersona}
                            colSpan={4}
                          >
                            <DropDownBox
                              dataSource={this.state.tblPersona_MyUnif_form}
                              value={prendaSel ? prendaSel.idPersona : null}
                              valueExpr="idPersona"
                              deferRendering={false}
                              disabled={prendaSel && prendaSel.isExtra}
                              displayExpr={function (item) {
                                return (
                                  item &&
                                  item.codigo + " - " + item.nombreCompuesto
                                );
                              }}
                              showClearButton={true}
                              onValueChanged={
                                this.onValueChanged_dropDownBox_tblPersona
                              }
                              contentRender={this.render_tblPersona}
                            />
                          </Item>
                          <Item
                            dataField="idTalla"
                            label={this.formLabel_idTalla}
                            editorType="dxSelectBox"
                            editorOptions={{
                              dataSource: this.data.dataSource_tallaNTipoPrenda,
                              displayExpr: "denominacion",
                              valueExpr: "idTalla",
                              showClearButton: true,
                              disabled: prendaSel && prendaSel.isExtra,
                            }}
                            colSpan={3}
                          ></Item>
                          <Item
                            dataField="idEstado"
                            label={this.formLabel_idEstado}
                            disabled={true}
                            editorType="dxSelectBox"
                            editorOptions={{
                              dataSource:
                                this.datasource_tblEstado_MyUnif.items(),
                              valueExpr: "idEstado",
                              displayExpr: "denominacion",
                              elementAttr: { id: "dxSelectBox_idEstado" },
                            }}
                            colSpan={2}
                          ></Item>
                        </Form>
                      </div>
                    </form>
                  </Col>
                  <Col xs="12" md="6" className="he-100">
                    <DataGrid
                      //Datos
                      ref={this.dxDataGrid_tblPrenda_MyUnif}
                      dataSource={this.datasource_tblPrenda_MyUnif}
                      //Propiedades
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      hoverStateEnabled={true}
                      selection={this.tblPrenda_selection}
                      selectedRowKeys={
                        prendaSel && prendaSel.idPrenda
                          ? [prendaSel.idPrenda]
                          : []
                      }
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onEditorPreparing={this.onEditorPreparing_tblPrenda}
                      onToolbarPreparing={this.onToolbarPreparing_tblPrenda}
                      onRowRemoved={this.onRowRemoved_tblPrenda}
                      onSelectionChanged={this.onSelectionChanged_tblPrenda}
                      onRowPrepared={this.onRowPrepared_tblPrenda}
                    >
                      <SearchPanel visible={true} width={240} />
                      <HeaderFilter visible={false} />
                      <FilterRow visible={false} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={this.tblPrenda_allowedPageSizes}
                        showInfo={true}
                      />
                      <Column
                        dataField="barcode"
                        caption={getTrad("barcode")}
                        width={100}
                        alignment="center"
                        allowHeaderFiltering={false}
                        sortOrder="asc"
                      />
                      <Column
                        dataField="idTipoPrenda"
                        caption={getTrad("tipoPrenda")}
                        alignment="left"
                        allowHeaderFiltering={false}
                      >
                        <Lookup
                          dataSource={this.state.datasource_tblTipoPrenda}
                          valueExpr="idTipoPrenda"
                          displayExpr="denominacion"
                        />
                      </Column>
                      <Column
                        width={200}
                        dataField="idPersona"
                        caption={getTrad("persona")}
                        alignment="left"
                        allowHeaderFiltering={false}
                        calculateFilterExpression={function (
                          filterValue,
                          selectedFilterOperation,
                        ) {
                          if (filterValue === "null") {
                            return [this.dataField, "=", null];
                          } else {
                            return [
                              this.dataField,
                              selectedFilterOperation || "=",
                              filterValue,
                            ];
                          }
                        }}
                        cellRender={(e) => {
                          let idPersona = e.data.idPersona;
                          if (idPersona === null) return "";
                          else return e.text;
                        }}
                      >
                        <Lookup
                          dataSource={this.state.tblPersona_MyUnif_lookup}
                          valueExpr="idPersona"
                          displayExpr="nombreCompuesto"
                        />
                      </Column>
                      <Column
                        width={120}
                        dataField="idEstado"
                        caption={getTrad("estado")}
                        alignment="left"
                        allowHeaderFiltering={false}
                      >
                        <Lookup
                          dataSource={this.datasource_tblEstado_MyUnif.items()}
                          valueExpr="idEstado"
                          displayExpr="denominacion"
                        />
                      </Column>
                      <Column
                        width={100}
                        dataField="idTalla"
                        caption={getTrad("talla")}
                        alignment="left"
                        allowHeaderFiltering={false}
                      >
                        <Lookup
                          dataSource={this.state.datasource_tblTalla_lookup}
                          valueExpr="idTalla"
                          displayExpr="denominacion"
                        />
                      </Column>
                      <Column
                        caption=" "
                        width={50}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={removeCellComponent}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </TabPanelItem>
              <TabPanelItem title={getTrad("personas")} tabIndex={2}>
                <Row className="pt-4 he-100">
                  <Col xs="12" md="6" className="pl-3">
                    <form
                      onSubmit={this.submit_formPersona}
                      className="d-flex flex-column he-100 w-100"
                    >
                      <div className="text-left pb-3">
                        <Toolbar>
                          <ToolbarItem location="before">
                            <Button
                              text={getTrad("nuevaPersona")}
                              icon="add"
                              onClick={this.crearPersona}
                            />
                          </ToolbarItem>
                          <ToolbarItem location="after">
                            <Button
                              text={getTrad("actualizar")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_persona"
                              visible={
                                personaSel && personaSel.idPersona
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              text={getTrad("crearPersona")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_persona"
                              visible={!(personaSel && personaSel.idPersona)}
                            />
                          </ToolbarItem>
                        </Toolbar>
                      </div>
                      <div className="flex-grow-1">
                        <Form
                          height="100%"
                          formData={personaSel}
                          ref={this.dxForm_tblPersona_REF}
                          labelLocation="top"
                          scrollingEnabled={true}
                          screenByWidth={this.screenByWidth}
                          colCountByScreen={this.colCountByScreen}
                          validationGroup="validationGroup_form_persona"
                        >
                          <Item
                            dataField="nombre"
                            label={this.formLabel_nombre}
                            colSpan={3}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="apellidos"
                            label={this.formLabel_apellidos}
                            colSpan={3}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="genero"
                            label={this.formLabel_genero}
                            colSpan={2}
                            editorType="dxSelectBox"
                            editorOptions={this.genero_editorOptions}
                          ></Item>
                          <Item
                            dataField="codigo"
                            label={this.formLabel_codigo}
                            editorType={this.codigo_editorType}
                            editorOptions={this.codigo_editorOptions}
                            colSpan={2}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                            <AsyncRule
                              message="El código de persona introducido ya existe"
                              validationCallback={
                                this.asyncValidation_codigoPersona
                              }
                            />
                          </Item>
                          <Item
                            dataField="idDepartamento"
                            label={this.formLabel_departamento}
                            editorType="dxSelectBox"
                            editorOptions={this.idDepartamento_editorOptions}
                            colSpan={3}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="idEstadoPersona"
                            label={this.formLabel_activo}
                            colSpan={2}
                            editorType="dxSwitch"
                            editorOptions={this.activo_editorOptions}
                          />
                          <Item
                            dataField="fechaAlta"
                            label={this.formLabel_fechaAlta}
                            colSpan={2}
                            editorType="dxDateBox"
                            editorOptions={this.fechaAlta_editorOptions}
                          />
                          <Item
                            dataField="fechaBaja"
                            label={this.formLabel_fechaBaja}
                            colSpan={2}
                            editorType="dxDateBox"
                            editorOptions={this.fechaBaja_editorOptions}
                          />
                          <EmptyItem colSpan={3} />
                          <Item
                            dataField="tblPrenda_MyUnif"
                            render={this.render_tblPrenda_MyUnif}
                            colSpan={7}
                            label={this.formLabel_prendas}
                          />
                        </Form>
                      </div>
                    </form>
                  </Col>
                  <Col xs="12" md="6" className="he-100">
                    <DataGrid
                      //Datos
                      ref={this.dxDataGrid_tblPersona_MyUnif}
                      dataSource={this.datasource_tblPersona_DataGrid}
                      //Propiedades
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      hoverStateEnabled={true}
                      selection={this.tblPersona_selection}
                      selectedRowKeys={
                        personaSel && personaSel.idPersona
                          ? [personaSel.idPersona]
                          : []
                      }
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onToolbarPreparing={this.onToolbarPreparing_tblPersona}
                      onRowRemoving={this.onRowRemoving_tblPersona}
                      onRowRemoved={this.onRowRemoved_tblPersona}
                      onSelectionChanged={this.onSelectionChanged_tblPersona}
                      onRowPrepared={this.onRowPrepared_tblPersona}
                    >
                      <Editing>
                        <Texts
                          confirmDeleteMessage={getTrad("pregBajaPersona")}
                        />
                      </Editing>
                      <SearchPanel visible={true} width={240} />
                      <HeaderFilter visible={true} />
                      <FilterRow visible={true} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={this.tblPersona_allowedPageSizes}
                        showInfo={true}
                      />
                      <Column
                        dataField="nombre"
                        caption={getTrad("nombre")}
                        alignment="left"
                        allowHeaderFiltering={false}
                        sortIndex={1}
                        sortOrder="asc"
                      />
                      <Column
                        dataField="apellidos"
                        caption={getTrad("apellidos")}
                        alignment="left"
                        sortIndex={2}
                        sortOrder="asc"
                        allowHeaderFiltering={false}
                      />
                      <Column
                        width={200}
                        dataField="idDepartamento"
                        caption={getTrad("departamento")}
                        alignment="left"
                        allowHeaderFiltering={false}
                      >
                        <Lookup
                          dataSource={{
                            store: {
                              type: "array",
                              data: datasource_tblDepartamento,
                              key: "idDepartamento",
                            },
                          }}
                          valueExpr="idDepartamento"
                          displayExpr="denominacion"
                        />
                      </Column>
                      <Column
                        width={200}
                        dataField="codigo"
                        dataType={"string"}
                        caption={getTrad("codigo")}
                        alignment="left"
                        allowHeaderFiltering={false}
                        sortIndex={1}
                        sortingMethod={this.sortingMethod_codigo}
                      />
                      <Column
                        dataField="idEstadoPersona"
                        visible={false}
                        allowFiltering={false}
                        sortOrder="desc"
                        sortIndex={0}
                      />
                      <Column
                        dataField="tblPrenda_MyUnif"
                        visible={false}
                        allowFiltering={false}
                      />
                      <Column
                        caption=" "
                        width={50}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={removeCellComponent}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </TabPanelItem>
              <TabPanelItem title={getTrad("departamentos")} tabIndex={3}>
                <Row className="pt-4 he-100">
                  <Col xs="12" md="8" className="pl-3">
                    <form
                      onSubmit={this.submit_formDepartamento}
                      className="d-flex flex-column he-100 w-100"
                    >
                      <div className="text-left pb-3">
                        <Toolbar>
                          <ToolbarItem location="before">
                            <Button
                              text={getTrad("nuevoDepartamento")}
                              icon="add"
                              onClick={this.crearDepartamento}
                            />
                          </ToolbarItem>
                          <ToolbarItem location="after">
                            <Button
                              text={getTrad("actualizar")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_departamento"
                              visible={
                                departamentoSel &&
                                departamentoSel.idDepartamento
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              text={getTrad("crearDepartamento")}
                              type="success"
                              icon="check"
                              useSubmitBehavior={true}
                              validationGroup="validationGroup_form_departamento"
                              visible={
                                !(
                                  departamentoSel &&
                                  departamentoSel.idDepartamento
                                )
                              }
                            />
                          </ToolbarItem>
                        </Toolbar>
                      </div>
                      <div className="flex-grow-1">
                        <Form
                          height="100%"
                          formData={departamentoSel}
                          ref={this.dxForm_tblDepartamento_REF}
                          labelLocation="top"
                          scrollingEnabled={true}
                          screenByWidth={this.screenByWidth}
                          colCountByScreen={this.colCountByScreen}
                          validationGroup="validationGroup_form_departamento"
                        >
                          <Item
                            dataField="denominacion"
                            label={this.formLabel_denominacion}
                            colSpan={3}
                          >
                            <RequiredRule message={getTrad("campoNecesario")} />
                          </Item>
                          <Item
                            dataField="siglas"
                            label={this.formLabel_siglas}
                            colSpan={2}
                          />
                        </Form>
                      </div>
                    </form>
                  </Col>
                  <Col xs="12" md="4" className="he-100">
                    <DataGrid
                      //Datos
                      ref={this.dxDataGrid_tblDepartamento_MyUnif}
                      dataSource={this.state.datasource_tblDepartamento}
                      keyExpr="idDepartamento"
                      //Propiedades
                      elementAttr={
                        this.elementAttr_dxDataGrid_tblDepartamento_MyUnif
                      }
                      columnsAutoWidth={true}
                      height={"100%"}
                      width={"100%"}
                      hoverStateEnabled={true}
                      selection={this.tblDepartamento_selection}
                      selectedRowKeys={
                        departamentoSel && departamentoSel.idDepartamento
                          ? [departamentoSel.idDepartamento]
                          : []
                      }
                      //Estilos
                      showColumnLines={false}
                      showRowLines={true}
                      rowAlternationEnabled={true}
                      onToolbarPreparing={
                        this.onToolbarPreparing_tblDepartamento
                      }
                      onRowRemoving={this.onRowRemoving_tblDepartamento}
                      onRowRemoved={this.onRowRemoved_tblDepartamento}
                      onSelectionChanged={
                        this.onSelectionChanged_tblDepartamento
                      }
                      onRowPrepared={this.onRowPrepared_tblDepartamento}
                    >
                      <SearchPanel visible={true} width={240} />
                      <HeaderFilter visible={true} />
                      <FilterRow visible={true} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={this.tblDepartamento_allowedPageSizes}
                        showInfo={true}
                      />
                      <Column
                        dataField="siglas"
                        caption={getTrad("siglas")}
                        width={140}
                        alignment="left"
                        allowHeaderFiltering={false}
                      />
                      <Column
                        dataField="denominacion"
                        caption={getTrad("denominacion")}
                        alignment="left"
                        sortIndex={2}
                        sortOrder="asc"
                        allowHeaderFiltering={false}
                      />
                      <Column
                        caption=" "
                        width={50}
                        alignment="center"
                        cssClass="p-0"
                        cellComponent={removeCellComponent}
                      />
                    </DataGrid>
                  </Col>
                </Row>
              </TabPanelItem>
            </TabPanel>
            <Popup
              visible={isVisible_popupCodigosPersona}
              height="600px"
              width="325px"
              showTitle={true}
              title={getTrad("codigosLibresPersonas")}
              deferRendering={false}
              onHiding={this.popupCodigosPersona_onHiding}
            >
              <DataGrid
                //Datos
                ref={this.dxDataGrid_codigoLibrePersona_REF}
                dataSource={
                  new DataSource({
                    store: new ArrayStore({
                      key: "codigo",
                      data: datasource_codigoLibrePersona,
                    }),
                  })
                }
                //Propiedades
                columnsAutoWidth={true}
                height={"100%"}
                width={"100%"}
                hoverStateEnabled={true}
                selection={this.codigoLibre_selection}
                //Estilos
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                onContentReady={this.gridCodigoLibrePersona_onContentReady}
                onSelectionChanged={this.onSelectionChanged_codigoLibre}
                onRowPrepared={this.onRowPrepared_pointerRow}
              >
                <Scrolling mode="infinite" />
                <Column
                  dataField="codigo"
                  caption={getTrad("codigo")}
                  sortOrder="asc"
                  alignment="center"
                  allowHeaderFiltering={false}
                />
              </DataGrid>
            </Popup>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //#region TIPO PRENDA

  //FORMATS
  formLabel_peso_gramos = { text: getTrad("peso_gramos") };
  formLabel_precio = { text: getTrad("precio") };
  formLabel_coste = { text: getTrad("coste") };
  formLabel_talla = { text: getTrad("talla") };
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: this.props.lavanderia.moneda,
  };

  elementAttr_dxDataGrid_tblTipoPrenda_MyUnif = {
    id: "dxDataGrid_tblTipoPrenda_MyUnif",
  };
  elementAttr_dxDataGrid_tblDepartamento_MyUnif = {
    id: "dxDataGrid_tblDepartamento_MyUnif",
  };
  //EDITORES
  pesoG_editorOptions = {
    min: 0,
    max: 9999,
    step: 0,
    format: "###0",
  };
  currency_editorOptions = {
    format: this.currency_format,
    step: 0,
    min: 0,
    max: 9999.99,
  };

  dropDownOptions = { showTitle: false, closeOnOutsideClick: true };
  colCountByScreen = { xl: 7, lg: 7, md: 7, sm: 1, xs: 1 };

  //CONFIG GRID
  tblTipoPrenda_selection = { mode: "single" };
  tblTipoPrenda_allowedPageSizes = [20, 50, 100];

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //#region EVENTOS
  tabPanel_onContentReady(e) {
    e.element.find(".dx-tabs").addClass("fill");
  }

  onEditorPreparing_tblTipoPrenda(e) {
    if (e.parentType === "filterRow" && e.dataField === "genero") {
      e.editorOptions.valueExpr = "filterValue";
      e.editorOptions.displayExpr = "filterText";
      e.editorOptions.dataSource = [
        { filterValue: null, filterText: getTrad("todos") },
        { filterValue: "null", filterText: getTrad("unisex") },
        { filterValue: true, filterText: getTrad("hombre") },
        { filterValue: false, filterText: getTrad("mujer") },
      ];
    }
  }

  dxSelectBox_genero_tipoPrenda_onSelectionChanged(e) {
    let tipoPrendaSel = { ...this.state.tipoPrendaSel };
    tipoPrendaSel.genero =
      e.selectedItem !== null ? e.selectedItem.value : null;
    this.setState({ tipoPrendaSel: tipoPrendaSel });
  }

  genero_tipoPrenda_cellRender(e) {
    let genero = e.data.genero;

    if (genero === null) return getTrad("unisex");
    else if (genero) return getTrad("hombre");
    else return getTrad("mujer");
  }

  onToolbarPreparing_tblTipoPrenda(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("tiposPrenda"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }

  onRowRemoving_tblTipoPrenda(e) {
    this.datasource_tblTipoPrenda
      .store()
      .remove(e.key)
      .then(
        (key) => {
          notify({
            message: getTrad("aviso_C_RegistroEliminado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        },
        (error) => {
          notify({
            message: getTrad("alert_eliminarRegistro_error"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        },
      );
  }

  onRowRemoved_tblTipoPrenda(e) {
    let { tipoPrendaSel } = this.state;
    if (e.key === tipoPrendaSel.idPrenda) {
      this.crearTipoPrenda();
    }
  }

  onSelectionChanged_tblTipoPrenda(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let dxForm_hasChanges = !(
        JSON.stringify(_this.state.tipoPrendaSel) ===
        JSON.stringify(_this.state.tipoPrendaSel_bak)
      );

      if (dxForm_hasChanges) {
        if (_this.data.tipoPrendaSel_validation_row === null)
          _this.data.tipoPrendaSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              let tipoPrendaSel = $.extend(
                true,
                {},
                _this.data.tipoPrendaSel_validation_row,
              );
              let tipoPrendaSel_ = $.extend(true, {}, tipoPrendaSel);

              _this.setState({
                tipoPrendaSel: tipoPrendaSel,
                tipoPrendaSel_bak: tipoPrendaSel_,
              });
              _this.data.tipoPrendaSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.tipoPrendaSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let tipoPrendaSel = $.extend(true, {}, data),
          tipoPrendaSel_bak = $.extend(true, {}, tipoPrendaSel);
        _this.setState({
          tipoPrendaSel: tipoPrendaSel,
          tipoPrendaSel_bak: tipoPrendaSel_bak,
        });
      }
    }
  }

  onRowPrepared_tblTipoPrenda(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }
  //#endregion

  crearTipoPrenda() {
    let tipoPrendaSel = {};

    tipoPrendaSel.denominacion = undefined;
    tipoPrendaSel.precio = 0;
    tipoPrendaSel.coste = 0;
    tipoPrendaSel.peso = 0;
    tipoPrendaSel.genero = undefined;

    let tipoPrendaSel_ = $.extend(true, {}, tipoPrendaSel);
    this.setState({
      tipoPrendaSel: tipoPrendaSel_,
      tipoPrendaSel_bak: tipoPrendaSel_,
    });
  }

  submit_formTipoPrenda(e) {
    e.preventDefault();

    let _this = this;
    let { tipoPrendaSel } = _this.state;
    let tipoPrendaSel_ = $.extend(true, {}, tipoPrendaSel);
    let { lavanderia } = this.props;

    //#region FORMAT DATA
    tipoPrendaSel_.idLavanderia = lavanderia.idLavanderia;

    tipoPrendaSel_.tblTalla_MyUnif = $.map(
      tipoPrendaSel_.tblTalla_MyUnif_ids,
      function (item) {
        return {
          idTalla: item, //c
        };
      },
    );

    $.each(tipoPrendaSel_.tblTalla_MyUnif, function (index, item) {
      delete item.key;
    });
    delete tipoPrendaSel_.tblTalla_MyUnif_ids;
    //#endregion

    if (tipoPrendaSel_.idTipoPrenda) {
      //UPDATE
      _this.loadPanel_show(true);
      _this.datasource_tblTipoPrenda
        .store()
        .update(tipoPrendaSel_.idTipoPrenda, tipoPrendaSel_)
        .done(function () {
          tipoPrendaSel_.tblTalla_MyUnif_ids =
            tipoPrendaSel.tblTalla_MyUnif_ids;
          tipoPrendaSel_.tblTalla_MyUnif = tipoPrendaSel.tblTalla_MyUnif;
          _this.setState({
            tipoPrendaSel: tipoPrendaSel_,
            tipoPrendaSel_bak: tipoPrendaSel_,
          });
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    } else {
      //INSERT
      _this.loadPanel_show(true);
      _this.datasource_tblTipoPrenda
        .store()
        .insert(tipoPrendaSel_)
        .done(function () {
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    }
  }

  //#endregion

  //#region PRENDA

  //FORMATS
  formLabel_barcode = { text: getTrad("barcode") };
  formLabel_idTipoPrenda = { text: getTrad("tipoPrenda") };
  formLabel_idPersona = { text: getTrad("persona") };
  formLabel_idTalla = { text: getTrad("talla") };
  formLabel_idEstado = { text: getTrad("estado") };
  formLabel_isExtra = { text: getTrad("extra") };

  barcode_editorOptions = { maxLength: 10 };

  idPersona_editorOptions = {
    dataSource: this.datasource_tblPersona_MyUnif_lookup_form,
    displayExpr: "nombreCompuesto",
    valueExpr: "idPersona",
    showClearButton: true,
    showCancelButton: false,
    dropDownOptions: { showTitle: false, closeOnOutsideClick: true },
  };

  asyncValidation_barcode(params) {
    let barcode = params.value.replace(/\s+/g, " ").trim();
    this.data.comprobar_barcode = barcode;

    let _this = this;
    return new Promise((resolve, reject) => {
      _this.datasource_tblPrenda_exists_MyUniform
        .load()
        .done(function (result) {
          if (result.length > 0) reject();
          else resolve();
        });
    });
  }

  asyncValidation_codigoPersona(params) {
    let codigoPersona = params.value.replace
      ? params.value.replace(/\s+/g, " ").trim()
      : params.value;
    this.data.comprobar_codigoPersona = codigoPersona;

    let _this = this;
    return new Promise((resolve, reject) => {
      _this.datasource_tblPersona_exists_MyUniform
        .load()
        .done(function (result) {
          if (result.length > 0) reject();
          else resolve();
        });
    });
  }

  //CONFIG GRID
  tblPrenda_selection = { mode: "single" };
  tblPrenda_allowedPageSizes = [20, 50, 100];

  colCountByScreen = { xl: 7, lg: 7, md: 7, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  genero_tipoPrenda_editorOptions = {
    dataSource: this.enum_genero_tipoPrenda,
    elementAttr: { id: "dxSelectBox_genero" },
    valueExpr: "value",
    displayExpr: "denominacion",
    showClearButton: true,
    placeholder: getTrad("unisex"),
  };

  //#region EVENTOS
  idTipoPrenda_prenda_onSelectionChanged(e) {
    let prendaSel = { ...this.state.prendaSel };

    prendaSel.idTipoPrenda =
      e.selectedItem !== null && e.selectedItem !== undefined
        ? e.selectedItem.idTipoPrenda
        : undefined;
    this.data.dataSource_tallaNTipoPrenda =
      prendaSel.idTipoPrenda !== undefined
        ? e.selectedItem.tblTalla_MyUnif
        : [];
    this.setState({ prendaSel: prendaSel });
  }

  isExtra_prenda_onValueChanged(e) {
    let { prendaSel } = this.state;
    this.setState({ prendaSel: prendaSel });
  }

  onToolbarPreparing_tblPrenda(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("prendas"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }

  onRowRemoved_tblPrenda(e) {
    let { prendaSel } = this.state;
    if (e.key === prendaSel.idPrenda) {
      this.crearPrenda();
    }
  }

  onSelectionChanged_tblPrenda(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let prendaSel_ = $.extend(true, {}, _this.state.prendaSel);
      let prendaSel_bak_ = $.extend(true, {}, _this.state.prendaSel_bak);

      let dxForm_hasChanges = !(
        JSON.stringify(prendaSel_) === JSON.stringify(prendaSel_bak_)
      );
      if (dxForm_hasChanges) {
        if (_this.data.prendaSel_validation_row === null)
          _this.data.prendaSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              let prendaSel = $.extend(
                true,
                {},
                _this.data.prendaSel_validation_row,
              );
              let prendaSel_ = $.extend(true, {}, prendaSel);

              _this.setState({
                prendaSel: prendaSel,
                prendaSel_bak: prendaSel_,
              });
              _this.data.prendaSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.prendaSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let prendaSel = $.extend(true, {}, data),
          prendaSel_bak = $.extend(true, {}, prendaSel);
        _this.setState({ prendaSel: prendaSel, prendaSel_bak: prendaSel_bak });
      }
    }
  }

  onRowPrepared_tblPrenda(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.idEstado === 5) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  onValueChanged_dropDownBox_tblPersona(e) {
    let { prendaSel } = this.state;
    prendaSel.idPersona = e.value;
    this.setState({ prendaSel: prendaSel });
  }

  render_tblPersona = (data) => {
    let { prendaSel, tblPersona_MyUnif_form } = this.state;
    return (
      <DataGrid
        //Datos
        dataSource={tblPersona_MyUnif_form}
        //Propiedades
        keyExpr="idPersona"
        columnsAutoWidth={true}
        height={"100%"}
        width={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        filterValue={
          prendaSel && prendaSel.idPersona
            ? ["idPersona", "<>", prendaSel.idPersona]
            : null
        }
        onRowClick={(e) => {
          this.onRowClick_dropDownBox_tblPersona(e, data.component);
        }}
      >
        <Scrolling mode="infinite" />
        <SearchPanel visible={true} width={240} />
        <Pager visible={false} />
        <Column dataField="idPersona" visible={false} />
        <Column
          width={100}
          dataField="codigo"
          caption={getTrad("codigo")}
          alignment="left"
          sortOrder="asc"
        />
        <Column
          dataField="nombreCompuesto"
          caption={getTrad("nombre")}
          alignment="left"
        />
      </DataGrid>
    );
  };

  onRowClick_dropDownBox_tblPersona(e, dropdown) {
    let { prendaSel } = this.state;
    prendaSel.idPersona = e.key;
    dropdown.close();
    this.setState({ prendaSel: prendaSel });
  }
  //#endregion

  crearPrenda() {
    let prendaSel = {
      barcode: undefined,
      idTipoPrenda: undefined,
      idPersona: undefined,
      idEstado: 0,
      idTalla: undefined,
      isExtra: false,
    };

    let prendaSel_ = $.extend(true, {}, prendaSel);
    let prendaSel_bak = $.extend(true, {}, prendaSel);

    this.setState({ prendaSel: prendaSel_, prendaSel_bak: prendaSel_bak });
  }

  submit_formPrenda(e) {
    e.preventDefault();

    let _this = this;
    let { prendaSel } = _this.state;
    let prendaSel_ = $.extend(true, {}, prendaSel);
    if (prendaSel_.idEstado === 5) {
      prendaSel_.idEstado = 4;
    }

    if (prendaSel_.isExtra) {
      prendaSel_.idPersona = null;
      prendaSel_.idTalla = null;
    }

    if (prendaSel_.idPrenda) {
      //UPDATE
      _this.loadPanel_show(true);
      _this.datasource_tblPrenda_MyUnif
        .store()
        .update(prendaSel_.idPrenda, prendaSel_)
        .done(function () {
          _this.setState({ prendaSel: prendaSel_, prendaSel_bak: prendaSel_ });
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    } else {
      //INSERT
      prendaSel_.idEstado = 0;

      _this.loadPanel_show(true);
      _this.datasource_tblPrenda_MyUnif
        .store()
        .insert(prendaSel_)
        .done(function () {
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    }
  }

  //#endregion

  //#region PERSONAS

  //FORMATS
  formLabel_nombre = { text: getTrad("nombre") };
  formLabel_apellidos = { text: getTrad("apellidos") };
  formLabel_codigo = { text: getTrad("codigo") };
  formLabel_genero = { text: getTrad("genero") };
  formLabel_departamento = { text: getTrad("departamento") };
  formLabel_activo = { text: getTrad("activo") };
  formLabel_prendas = { text: getTrad("prendas") };
  formLabel_fechaAlta = { text: getTrad("fechaAlta") };
  formLabel_fechaBaja = { text: getTrad("fechaBaja") };

  sortingMethod_codigo = function (value1, value2) {
    if (this.dataType === "string") {
      let maxString_codigo = "zzzzzzzzzz";
      let val1 = !value1 ? maxString_codigo : value1;
      let val2 = !value2 ? maxString_codigo : value2;

      return val1.localeCompare(val2);
    } else if (this.dataType === "number") {
      let maxNum_codigo = 9999999999;
      let val1 = !value1 ? maxNum_codigo : value1;
      let val2 = !value2 ? maxNum_codigo : value2;

      if (parseInt(val1) > parseInt(val2)) return 1;
      else return -1;
    }
  };

  idDepartamento_editorOptions = {
    dataSource: this.datasource_tblDepartamento_DataGrid,
    displayExpr: "denominacion",
    valueExpr: "idDepartamento",
  };

  genero_editorOptions = {
    dataSource: this.enum_genero_persona,
    displayExpr: "denominacion",
    valueExpr: "value",
    placeholder: "Sel.",
  };

  activo_editorOptions = {
    switchedOnText: getTrad("activo"),
    switchedOffText: getTrad("noActivo"),
  };

  isExtra_editorOptions = {
    switchedOnText: getTrad("extra"),
    switchedOffText: getTrad("normal"),
  };

  fechaAlta_editorOptions = {
    displayFormat: "dd/MM/yyyy",
    readOnly: true,
  };

  fechaBaja_editorOptions = {
    displayFormat: "dd/MM/yyyy",
    readOnly: true,
  };

  //CONFIG GRID
  tblPersona_selection = { mode: "single" };
  codigoLibre_selection = { mode: "single" };
  tblPersona_allowedPageSizes = [20, 50, 100];

  colCountByScreen = { xl: 7, lg: 7, md: 7, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //#region EVENTOS
  onToolbarPreparing_tblPersona(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("personas"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }

  onRowRemoving_tblPersona(e) {
    let _this = this;
    this.datasource_tblPersona_DataGrid
      .store()
      .remove(e.key)
      .done(function () {
        _this.datasource_spSelectCodigoLibrePersona.reload();
      });
  }

  onRowRemoved_tblPersona(e) {
    let { personaSel, prendaSel } = this.state;
    if (e.key === personaSel.idPersona) {
      this.crearPersona();
    }
    if (e.key === prendaSel.idPersona) {
      let prendaSel = { ...this.state.prendaSel };
      prendaSel.idPersona = null;
      this.setState({ prendaSel });
    }

    this.datasource_tblPersona_MyUnif.reload();
    this.datasource_tblPersona_DataGrid.load();
    this.datasource_tblPrenda_MyUnif.reload();
  }

  popupCodigosPersona_onHiding(e) {
    this.setState({ isVisible_popupCodigosPersona: false });
  }

  gridCodigoLibrePersona_onContentReady(e) {
    let { personaSel } = this.state;
    if (personaSel) e.component.selectRows([personaSel.codigo]);
    else e.component.deselectAll();

    e.element.find(e.element.find(".dx-freespace-row")).height(0);
  }

  onSelectionChanged_codigoLibre(e) {
    if (e.selectedRowKeys.length > 0) {
      let codigo = e.selectedRowKeys[0];
      let { personaSel, isVisible_popupCodigosPersona } = this.state;

      if (
        codigo &&
        codigo != personaSel.codigo &&
        isVisible_popupCodigosPersona
      ) {
        this.dxForm_tblPersona.updateData("codigo", codigo);
        this.setState({ isVisible_popupCodigosPersona: false });
      }
    }
  }
  onRowPrepared_pointerRow(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  onSelectionChanged_tblPersona(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let dxForm_hasChanges = !(
        JSON.stringify(_this.state.personaSel) ===
        JSON.stringify(_this.state.personaSel_bak)
      );

      if (dxForm_hasChanges) {
        if (_this.data.personaSel_validation_row === null)
          _this.data.personaSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              let personaSel = $.extend(
                true,
                {},
                _this.data.personaSel_validation_row,
              );
              let personaSel_ = $.extend(true, {}, personaSel);

              _this.setState({
                personaSel: personaSel,
                personaSel_bak: personaSel_,
              });
              _this.datasource_spSelectCodigoLibrePersona.reload();
              _this.data.personaSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.personaSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let personaSel = $.extend(true, {}, data),
          personaSel_bak = $.extend(true, {}, personaSel);
        _this.setState({
          personaSel: personaSel,
          personaSel_bak: personaSel_bak,
        });
        _this.datasource_spSelectCodigoLibrePersona.reload();
      }
    }
  }

  onRowPrepared_tblPersona(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.idEstadoPersona != 1) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  render_tblPrenda_MyUnif = (data) => {
    let personaSel = data.component.option("formData");
    return (
      <DataGrid
        //Datos
        dataSource={personaSel.tblPrenda_MyUnif}
        //Propiedades
        columnsAutoWidth={true}
        height={"100%"}
        width={"100%"}
        hoverStateEnabled={true}
        //Estilos
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
      >
        <HeaderFilter visible={true} />
        <FilterRow visible={false} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={this.tblPrenda_allowedPageSizes}
          showInfo={true}
        />
        <Column
          dataField="barcode"
          caption={getTrad("barcode")}
          width={100}
          alignment="center"
          allowHeaderFiltering={false}
          sortOrder="asc"
        />
        <Column
          dataField="idTipoPrenda"
          caption={getTrad("tipoPrenda")}
          alignment="left"
          allowHeaderFiltering={false}
        >
          <Lookup
            dataSource={this.state.datasource_tblTipoPrenda}
            valueExpr="idTipoPrenda"
            displayExpr="denominacion"
          />
        </Column>
        <Column
          width={120}
          dataField="idEstado"
          caption={getTrad("estado")}
          alignment="left"
          allowHeaderFiltering={false}
        >
          <Lookup
            dataSource={this.datasource_tblEstado_MyUnif.items()}
            valueExpr="idEstado"
            displayExpr="denominacion"
          />
        </Column>
        <Column
          width={100}
          dataField="idTalla"
          caption={getTrad("talla")}
          alignment="left"
          allowHeaderFiltering={false}
        >
          <Lookup
            dataSource={this.state.datasource_tblTalla_lookup}
            valueExpr="idTalla"
            displayExpr="denominacion"
          />
        </Column>
      </DataGrid>
    );
  };
  //#endregion

  crearPersona() {
    let personaSel = {};

    personaSel.nombre = undefined;
    personaSel.apellidos = undefined;
    personaSel.genero = undefined;
    personaSel.codigo = undefined;
    personaSel.idDepartamento = undefined;
    personaSel.idEstadoPersona = true;

    let personaSel_ = $.extend(true, {}, personaSel);
    this.setState({
      personaSel: personaSel,
      personaSel_bak: $.extend(true, {}, personaSel_),
    });
    this.datasource_spSelectCodigoLibrePersona.reload();
  }

  submit_formPersona(e) {
    e.preventDefault();

    let _this = this;
    let { personaSel } = _this.state;
    personaSel.codigo = personaSel.codigo.toString();
    let personaSel_ = $.extend(true, {}, personaSel);
    let { lavanderia } = this.props;

    function insertUpdate() {
      personaSel_.idLavanderia = lavanderia.idLavanderia;
      personaSel_.idEstadoPersona =
        personaSel_.idEstadoPersona === true ||
        personaSel_.idEstadoPersona === 1
          ? 1
          : 0;
      delete personaSel_.tblPrenda_MyUnif;

      if (personaSel_.idPersona) {
        //UPDATE
        _this.loadPanel_show(true);
        _this.datasource_tblPersona_DataGrid
          .store()
          .update(personaSel_.idPersona, personaSel_)
          .done(function () {
            if (personaSel_.idEstadoPersona === 1) {
              //ACTIVO
              personaSel_.idEstadoPersona = personaSel.idEstadoPersona;
              personaSel_.tblPrenda_MyUnif = personaSel.tblPrenda_MyUnif;
              _this.setState({
                personaSel: personaSel_,
                personaSel_bak: personaSel_,
              });
              _this.loadPanel_hide();
            } else {
              //NO ACTIVO
              _this.crearPersona();
              _this.loadPanel_hide();
            }
          })
          .catch(function (error) {
            _this.loadPanel_hide();
            notify({
              message: handleErrors(error).errorMessage,
              type: "error",
              displayTime: "3500",
              closeOnClick: true,
            });
          });
      } else {
        //INSERT
        _this.loadPanel_show(true);
        _this.datasource_tblPersona_DataGrid
          .store()
          .insert(personaSel_)
          .done(function () {
            _this.loadPanel_hide();
          })
          .catch(function (error) {
            _this.loadPanel_hide();
            notify({
              message: handleErrors(error).errorMessage,
              type: "error",
              displayTime: "3500",
              closeOnClick: true,
            });
          });
      }
    }

    if (
      personaSel_.idPersona &&
      typeof personaSel_.idEstadoPersona != "undefined" &&
      personaSel_.idEstadoPersona === false
    ) {
      dxMensajePregunta(getTrad("pregBajaPersona"), [
        [getTrad("aceptar"), insertUpdate, "danger"],
        [getTrad("cancelar"), function () {}],
      ]);
    } else {
      insertUpdate();
    }
  }
  //#endregion

  //#region DEPARTAMENTOS

  //FORMATS
  formLabel_denominacion = { text: getTrad("denominacion") };
  formLabel_siglas = { text: getTrad("siglas") };

  //CONFIG GRID
  tblDepartamento_selection = { mode: "single" };
  tblDepartamento_allowedPageSizes = [20, 50, 100];

  colCountByScreen = { xl: 7, lg: 7, md: 7, sm: 1, xs: 1 };

  screenByWidth(width) {
    return width >= 1200
      ? "xl"
      : width >= 992
        ? "lg"
        : width >= 768
          ? "md"
          : width >= 576
            ? "sm"
            : "xs";
  }

  //#region EVENTOS
  onToolbarPreparing_tblDepartamento(e) {
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div>")
            .addClass("font-size")
            .addClass("mt-2 pl-2")
            .text(getTrad("departamentos"));
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onClick: function () {
            e.component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onClick: function () {
            e.component.refresh(true);
          },
        },
      },
    );
  }
  onRowRemoving_tblDepartamento(e) {
    this.datasource_tblDepartamento_DataGrid
      .store()
      .remove(e.key)
      .then(
        (key) => {
          notify({
            message: getTrad("aviso_C_RegistroEliminado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        },
        (error) => {
          notify({
            message: getTrad("alert_eliminarRegistro_error"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        },
      );
  }

  onRowRemoved_tblDepartamento(e) {
    let { departamentoSel } = this.state;
    if (e.key === departamentoSel.idDepartamento) {
      this.crearDepartamento();
    }
  }

  onSelectionChanged_tblDepartamento(e) {
    let _this = this;
    const data = e.selectedRowsData[0];

    if (data) {
      let dxForm_hasChanges = !(
        JSON.stringify(_this.state.departamentoSel) ===
        JSON.stringify(_this.state.departamentoSel_bak)
      );
      if (dxForm_hasChanges) {
        if (_this.data.departamentoSel_validation_row === null)
          _this.data.departamentoSel_validation_row = e.selectedRowsData[0];

        dxMensajePregunta(getTrad("preg_PerderCambios"), [
          [
            getTrad("aceptar"),
            function () {
              let departamentoSel = $.extend(
                true,
                {},
                _this.data.departamentoSel_validation_row,
              );
              let departamentoSel_ = $.extend(true, {}, departamentoSel);

              _this.setState({
                departamentoSel: departamentoSel,
                departamentoSel_bak: departamentoSel_,
              });
              _this.data.departamentoSel_validation_row = null;
            },
            "danger",
          ],
          [
            getTrad("cancelar"),
            function () {
              _this.data.departamentoSel_validation_row = null;
            },
          ],
        ]);
      } else {
        let departamentoSel = $.extend(true, {}, data),
          departamentoSel_bak = $.extend(true, {}, departamentoSel);
        _this.setState({
          departamentoSel: departamentoSel,
          departamentoSel_bak: departamentoSel_bak,
        });
      }
    }
  }

  onRowPrepared_tblDepartamento(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
      if (e.data.activo === false) {
        e.rowElement.addClass("disabledRow");
      }
    }
  }
  //#endregion

  crearDepartamento() {
    let departamentoSel = {};

    departamentoSel.denominacion = undefined;
    departamentoSel.siglas = "";

    let departamentoSel_ = $.extend(true, {}, departamentoSel);
    this.setState({
      departamentoSel: departamentoSel,
      departamentoSel_bak: $.extend(true, {}, departamentoSel_),
    });
  }

  submit_formDepartamento(e) {
    e.preventDefault();

    let _this = this;
    let { departamentoSel } = _this.state;
    let departamentoSel_ = $.extend(true, {}, departamentoSel);

    let { lavanderia } = this.props;
    departamentoSel_.idLavanderia = lavanderia.idLavanderia;

    if (departamentoSel_.idDepartamento) {
      _this.loadPanel_show(true);
      _this.datasource_tblDepartamento_DataGrid
        .store()
        .update(departamentoSel_.idDepartamento, departamentoSel_)
        .done(function () {
          _this.setState({
            departamentoSel: departamentoSel_,
            departamentoSel_bak: departamentoSel_,
          });
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    } else {
      //INSERT
      _this.loadPanel_show(true);
      _this.datasource_tblDepartamento_DataGrid
        .store()
        .insert(departamentoSel_)
        .done(function () {
          _this.loadPanel_hide();
        })
        .catch(function (error) {
          _this.loadPanel_hide();
          notify({
            message: handleErrors(error).errorMessage,
            type: "error",
            displayTime: "3500",
            closeOnClick: true,
          });
        });
    }
  }
  //#endregion

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    //DEVEXREME JQUERY
    this.cargaDatos_lavanderia();
  }

  componentDidUpdate(prevProps) {
    let { lavanderia } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.loadPanel_show();
      this.currency_format.currency = lavanderia.moneda;
      this.currency_editorOptions.format = this.currency_format;

      this.currency_format = {
        style: "currency",
        maximumFractionDigits: 2,
        currency: this.props.lavanderia.moneda,
      };
      this.cargaDatos_lavanderia();
      this.datasource_tblPersona_DataGrid.reload();
      this.datasource_tblPersona_MyUnif_lookup.reload();
      this.datasource_tblPrenda_MyUnif.reload();
      this.dxTabPanel_general.option("selectedIndex", 0);
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigUniformidad);
