import React, { useState } from "react";
import { connect } from "react-redux";

import PageTitle from "layout/AppMain/PageTitle";
import { getNombreFormulario } from "helpers";

const InfOperativo_new = ({ user, idioma }) => {
    const [idsLavanderia, setIdsLavanderia] = useState([]);
    const objNombreFormulario = { props: { user, idioma } };

    return (
        <>
            <PageTitle heading={getNombreFormulario(objNombreFormulario)} />
            <div
                style={{
                    position: "absolute",
                    top: "65px",
                    left: "0px",
                    width: "100%",
                    height: "calc(100% - 65px)",
                }}
            >
                <iframe
                    width="100%"
                    height="100%"
                    src="https://lookerstudio.google.com/embed/reporting/997548e2-731d-4a47-8d4d-d5ae01e44342/page/A246D"
                    frameborder="0"
                    // style="border:0"
                    allowfullscreen
                    sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                ></iframe>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.Authentication.user,
    idioma: state.Global.idioma,
});

export default connect(mapStateToProps)(InfOperativo_new);
