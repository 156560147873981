import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
    getNombreFormulario,
    errorHandler,
    authHeader,
    getTrad,
    formatDate,
    formatDate_parameter,
    tooltipControl_creacion,
    startOfMonth,
    endOfMonth,
    formatDateTime_parameter,
} from "../../../helpers";

import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import notify from "devextreme/ui/notify";

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import List from "devextreme-react/list";
import Box, { Item as ItemBox } from "devextreme-react/box";

import DataGrid, {
    Column,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Paging,
    Summary,
    TotalItem,
    GroupItem,
    RemoteOperations,
    GroupPanel,
    Grouping,
    ColumnChooser,
} from "devextreme-react/data-grid";
import Toolbar, { Item as ItemToolbar } from "devextreme-react/toolbar";

// Export excel
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";
import CustomStore from "devextreme/data/custom_store";

import PopupHistorico from "./components/PopupHistorico";
import "./Css.scss";

const idsTipoHistorico = {
    entradas: 1,
    cantidadTeorico: 2,
    salidas: 3,
};

class InfRecambios extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idInformeSel: null,
            almacenSel: null,
            inventarioSel: null,

            inventariosTotales: [],
            inventariosFiltrados: [],
            almacenes: [],

            fechaDesde: startOfMonth(new Date()),
            fechaHasta: new Date(),
            isDataGridVentasExpanded: false,

            popupHistoricoVisible: false,
            tipoHistorico: null,
            idsRecambioHistorico: [],
            recambioSel: "",
            referenciaInternaSel: "",
        };

        // REFERENCIAS
        this.dxList_enum_informes_REF = React.createRef();
        this.dxDataGrid_inventario_REF = React.createRef();
        this.dxDataGrid_ventas_REF = React.createRef();
        this.dxDataGrid_compras_REF = React.createRef();

        //DATASOURCE
        this.dxList_enum_informes_itemRender = this.dxList_enum_informes_itemRender.bind(this);
        this.onSelectionChanged_dxList_enum_informes = this.onSelectionChanged_dxList_enum_informes.bind(this);
        this.onSelectionChanged_dxList_almacen = this.onSelectionChanged_dxList_almacen.bind(this);

        this.datasource_tblAlmacenRecambios_postProcess = this.datasource_tblAlmacenRecambios_postProcess.bind(this);

        //GRID COMUNES
        this.dxDataGrid_onInitialized_tooltip = this.dxDataGrid_onInitialized_tooltip.bind(this);

        //INVENTARIO
        this.onToolbarPreparing_inventario = this.onToolbarPreparing_inventario.bind(this);
        this.dxDataGrid_inventario_btnAtras = this.dxDataGrid_inventario_btnAtras.bind(this);
        this.dxDataGrid_inventario_limpiarFiltros = this.dxDataGrid_inventario_limpiarFiltros.bind(this);
        this.dxDataGrid_inventario_actualizar = this.dxDataGrid_inventario_actualizar.bind(this);

        this.dxDataGrid_inventario_btnExport = this.dxDataGrid_inventario_btnExport.bind(this);
        this.dxDataGrid_inventario_title = this.dxDataGrid_inventario_title.bind(this);

        //COMPRAS/VENTAS
        this.dxDataGrid_compras_ventas_title = this.dxDataGrid_compras_ventas_title.bind(this);

        //COMPRAS-VENTAS
        this.onToolbarPreparing_compras_ventas = this.onToolbarPreparing_compras_ventas.bind(this);
        this.dxDataGrid_compras_ventas_btnAtras = this.dxDataGrid_compras_ventas_btnAtras.bind(this);
        this.dxDataGrid_compras_ventas_limpiarFiltros = this.dxDataGrid_compras_ventas_limpiarFiltros.bind(this);
        this.dxDataGrid_compras_ventas_actualizar = this.dxDataGrid_compras_ventas_actualizar.bind(this);
        this.dxDataGrid_compras_ventas_btnExport = this.dxDataGrid_compras_ventas_btnExport.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
    }

    //REFERENCIAS
    get dxList_enum_informes() {
        return this.dxList_enum_informes_REF.current.instance;
    }

    get dxDataGrid_inventario() {
        return this.dxDataGrid_inventario_REF.current.instance;
    }

    get dxDataGrid_ventas() {
        return this.dxDataGrid_ventas_REF.current.instance;
    }

    get dxDataGrid_compras() {
        return this.dxDataGrid_compras_REF.current.instance;
    }

    //#region DATA SOURCE
    datasource_inventarios = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => {
                this.datasource_inventarios_load();
            },
        }),
    });

    datasource_inventarios_load = () => {
        var _deferred = $.Deferred();
        $.when(this.datasource_tblMovimientoRecambio.load(), this.datasource_tblCierreRecambioNAlmacen.load()).done(
            () => {
                let movimientosRecambio = this.datasource_tblMovimientoRecambio.items();
                let cierresRecambio = this.datasource_tblCierreRecambioNAlmacen.items();

                const getMonthName = (date) => {
                    const monthNames = [
                        "ENERO",
                        "FEBRERO",
                        "MARZO",
                        "ABRIL",
                        "MAYO",
                        "JUNIO",
                        "JULIO",
                        "AGOSTO",
                        "SEPTIEMBRE",
                        "OCTUBRE",
                        "NOVIEMBRE",
                        "DICIEMBRE",
                    ];

                    const monthIndex = date.getMonth();
                    return monthNames[monthIndex];
                };

                movimientosRecambio = movimientosRecambio.map((mov) => {
                    return {
                        idAlmacen: mov.idAlmacenDestino,
                        fecha: mov.fecha,
                        denominacion: `${getTrad("inventario").toUpperCase()} - ${formatDate(new Date(mov.fecha))}`,
                        idMovimientoRecambio: mov.idMovimientoRecambio,
                        isCierre: false,
                    };
                });

                cierresRecambio = cierresRecambio.map((cierre) => {
                    const fechaCierre = new Date(cierre.fecha);
                    return {
                        ...cierre,
                        denominacion: `${getTrad(
                            "cierre"
                        ).toUpperCase()} - ${fechaCierre.getFullYear()} - ${getMonthName(fechaCierre)}`,
                        isCierre: true,
                    };
                });

                const inventariosTotales = [...movimientosRecambio, ...cierresRecambio].sort(
                    (a, b) => new Date(b.fecha) - new Date(a.fecha)
                );

                this.setState({ inventariosTotales });

                _deferred.resolve(inventariosTotales);
            }
        );
        return _deferred.promise();
    };

    datasource_tblCierreRecambioNAlmacen = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCierreRecambioNAlmacen/GetFechasNAlmacen",
            key: ["idAlmacen", "fecha"],
            errorHandler: (error) => {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblCierreRecambioNAlmacen_beforeSend(request);
            },
            version: 4,
        }),
        sort: ["fecha desc"],
    });

    datasource_tblCierreRecambioNAlmacen_beforeSend = (request) => {
        request.headers = { ...authHeader() };
        request.params.todas = true;
    };

    datasource_tblMovimientoRecambio = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblMovimientoRecambio",
            key: "idMovimientoRecambio",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblMovimientoRecambio_beforeSend(request);
            },
            version: 4,
        }),
        expand: ["tblAlmacenRecambios_destino($select=denominacion;)"],
        select: ["idMovimientoRecambio, isInventario, fecha, idAlmacenDestino"],
        filter: ["isInventario eq true"],
        map: (itemData) => {
            return this.datasource_tblMovimientoRecambio_map(itemData);
        },
    });

    datasource_tblMovimientoRecambio_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblMovimientoRecambio_map(itemData) {
        return {
            idMovimientoRecambio: itemData.idMovimientoRecambio,
            idAlmacenDestino: itemData.idAlmacenDestino,
            fecha: itemData.fecha,
        };
    }

    datasource_tblAlmacenRecambios = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblAlmacenRecambios",
            key: "idAlmacen",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_tblAlmacenRecambios_beforeSend(request);
            },
            version: 4,
        }),
        select: ["idAlmacen, denominacion"],
        filter: ["idAlmacenPadre eq null and activo eq true and eliminado eq false"],
        expand: ["tblMoneda($select=codigo), tblTipoSalidaRecambio($expand=tblTraduccion($select=clave))"],
        postProcess: (data) => {
            return this.datasource_tblAlmacenRecambios_postProcess(data);
        },
    });

    datasource_tblAlmacenRecambios_beforeSend(request) {
        request.headers = { ...authHeader() };
    }

    datasource_tblAlmacenRecambios_postProcess(data) {
        data = data.map((alm) => {
            return {
                ...alm,
                tipoSalidaRecambio: getTrad(alm.tblTipoSalidaRecambio.tblTraduccion.clave).toUpperCase(),
            };
        });
        this.setState({ almacenes: data });
        return data;
    }

    datasource_infRecambios_inventario = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "Assistant/infInventario",
            key: ["idRecambio"],
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infRecambios_inventario_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infRecambios_inventario_beforeSend(request) {
        let { inventarioSel, fechaHasta } = this.state;

        const fechaMod = new Date(fechaHasta.setHours(23, 59, 59));

        delete request.params.$orderby;

        request.headers = { ...authHeader() };
        request.params.idMovimientoRecambio = inventarioSel ? inventarioSel.idMovimientoRecambio ?? -1 : -1;
        request.params.idAlmacenPadre = inventarioSel ? inventarioSel.idAlmacen : -1;
        request.params.fechaHasta = formatDateTime_parameter(
            new Date(new Date(fechaMod).setHours(fechaMod.getUTCHours()))
        );
        request.params.fechaCierre = inventarioSel?.isCierre
            ? formatDate_parameter(startOfMonth(new Date(inventarioSel?.fecha)))
            : null;
    }

    datasource_infRecambios_compras = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyReporting/infRecambios_compras",
            key: ["idRecambio", "idMovimientoRecambio"],
            errorHandler: (error) => {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infRecambios_compras_ventas_beforeSend(request);
            },
            version: 4,
        }),
    });

    datasource_infRecambios_ventas = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyReporting/infRecambios_ventas",
            key: ["idRecambio", "idMovimientoRecambio"],
            errorHandler: (error) => {
                errorHandler(error, null);
            },
            beforeSend: (request) => {
                this.datasource_infRecambios_compras_ventas_beforeSend(request);
            },
            version: 4,
        }),
        postProcess: (data) => {
            return this.datasource_infRecambios_ventas_postProcess(data);
        },
    });

    datasource_infRecambios_ventas_postProcess = (data) => {
        let { isDataGridVentasExpanded } = this.state;

        if (!isDataGridVentasExpanded && this.dxDataGrid_ventas_REF.current) {
            setTimeout(() => {
                this.setState({ isDataGridVentasExpanded: true });
            }, 200);
            this.dxDataGrid_ventas.expandAll(0);
        }
        return data;
    };

    datasource_infRecambios_compras_ventas_beforeSend = (request) => {
        let { almacenSel, fechaDesde, fechaHasta } = this.state;

        request.headers = { ...authHeader() };
        request.params.idAlmacenPadre = almacenSel !== null ? almacenSel.idAlmacen : -1;
        request.params.fechaDesde = formatDate_parameter(fechaDesde);
        request.params.fechaHasta = formatDate_parameter(fechaHasta);
    };

    enum_informes = [
        {
            idInforme: 1,
            denominacion: getTrad("infInventarios"),
        },
        {
            idInforme: 2,
            denominacion: getTrad("infVentas"),
        },
        {
            idInforme: 3,
            denominacion: getTrad("infCompras"),
        },
    ];

    //#endregion

    cargaDatos_general = () => {
        this.loadPanel_show();
        $.when(
            this.datasource_tblMovimientoRecambio.load(),
            this.datasource_tblAlmacenRecambios.load(),
            this.datasource_inventarios.load()
        ).then(() => {
            this.loadPanel_hide();
        });
    };

    render() {
        let { getBackButton_paginaSecundaria } = this.props;
        let {
            idInformeSel,
            almacenSel,
            inventarioSel,
            almacenes,
            inventariosFiltrados,
            popupHistoricoVisible,
            tipoHistorico,
            idsRecambioHistorico,
            fechaHasta,
            recambioSel,
            referenciaInternaSel,
        } = this.state;

        return (
            <Fragment>
                <PageTitle
                    preHeading={getBackButton_paginaSecundaria ? getBackButton_paginaSecundaria() : ""}
                    heading={getNombreFormulario(this)}
                />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                        component='div'
                        className={'media-body'}
                        transitionName='PageAnimation'
                        transitionAppear={true}
                        transitionAppearTimeout={1000}
                        transitionEnter={false}
                        transitionLeave={false}> */}
                    <div id="InfRecambios" className="formContainer scrollbar-container">
                        <TabPanel
                            height="100%"
                            elementAttr={this.dxTabPanel_elementAttr}
                            selectedIndex={
                                inventarioSel
                                    ? 1
                                    : idInformeSel === 2 && almacenSel != null
                                    ? 2
                                    : idInformeSel === 3 && almacenSel != null
                                    ? 3
                                    : 0
                            }
                            animationEnabled={true}
                        >
                            <TabPanelItem>
                                <Box
                                    direction="row"
                                    align="space-around"
                                    crossAlign="stretch"
                                    width="100%"
                                    height="100%"
                                >
                                    <ItemBox ratio={1}>
                                        <Box direction="col" width="100%" height="100%">
                                            <ItemBox baseSize={40}>
                                                <div className="font-size ml-2">
                                                    {" "}
                                                    {"1. " + getTrad("seleccioneInforme")}{" "}
                                                </div>
                                            </ItemBox>
                                            <ItemBox ratio={1}>
                                                <List
                                                    ref={this.dxList_enum_informes_REF}
                                                    dataSource={this.enum_informes}
                                                    displayExpr="denominacion"
                                                    keyExpr="idInforme"
                                                    selectionMode="single"
                                                    height="100%"
                                                    width="100%"
                                                    itemRender={this.dxList_enum_informes_itemRender}
                                                    onSelectionChanged={this.onSelectionChanged_dxList_enum_informes}
                                                />
                                            </ItemBox>
                                        </Box>
                                    </ItemBox>
                                    <ItemBox baseSize={20} />
                                    <ItemBox ratio={1} visible={idInformeSel != null}>
                                        <Box direction="col" width="100%" height="100%">
                                            <ItemBox baseSize={40}>
                                                <div className="font-size ml-2">
                                                    {"2. " + getTrad("seleccioneAlmacen")}
                                                </div>
                                            </ItemBox>
                                            <ItemBox ratio={1}>
                                                <List
                                                    dataSource={almacenes}
                                                    displayExpr="denominacion"
                                                    keyExpr="idAlmacen"
                                                    selectionMode="single"
                                                    selectedItemKeys={[
                                                        almacenSel !== null ? almacenSel.idAlmacen : null,
                                                    ]}
                                                    height="100%"
                                                    width="100%"
                                                    onSelectionChanged={this.onSelectionChanged_dxList_almacen}
                                                />
                                            </ItemBox>
                                        </Box>
                                    </ItemBox>
                                    <ItemBox baseSize={20} />
                                    <ItemBox ratio={1} visible={idInformeSel === 1 && almacenSel != null}>
                                        <Box direction="col" width="100%" height="100%">
                                            <ItemBox baseSize={40}>
                                                <div className="font-size ml-2">
                                                    {"3. " + getTrad("seleccioneInventarioReferencia")}
                                                </div>
                                            </ItemBox>
                                            <ItemBox ratio={1}>
                                                <List
                                                    dataSource={inventariosFiltrados}
                                                    displayExpr="denominacion"
                                                    keyExpr={"idInf"}
                                                    selectionMode="single"
                                                    selectedItemKeys={[
                                                        inventarioSel != null ? inventarioSel.idInf : null,
                                                    ]}
                                                    height="100%"
                                                    width="100%"
                                                    pageLoadMode="scrollBottom"
                                                    onSelectionChanged={this.onSelectionChanged_dxList_inventarios}
                                                />
                                            </ItemBox>
                                        </Box>
                                    </ItemBox>
                                </Box>
                            </TabPanelItem>
                            <TabPanelItem>
                                {inventarioSel != null ? (
                                    <DataGrid
                                        ref={this.dxDataGrid_inventario_REF}
                                        dataSource={this.datasource_infRecambios_inventario}
                                        columnsAutoWidth={true}
                                        height={"100%"}
                                        width={"100%"}
                                        className={"pt-2"}
                                        hoverStateEnabled={true}
                                        showColumnLines={false}
                                        showRowLines={true}
                                        rowAlternationEnabled={true}
                                        onToolbarPreparing={this.onToolbarPreparing_inventario}
                                        onCellClick={this.onCellClick_inventario}
                                        onCellPrepared={this.onCellPrepared_inventario}
                                    >
                                        <RemoteOperations />
                                        <SearchPanel visible={true} width={240} />
                                        <HeaderFilter visible={true} />
                                        <FilterRow visible={true} />
                                        <Paging enabled={false} />
                                        <Column
                                            dataField="referenciaInterna"
                                            caption={getTrad("refInterna")}
                                            width={100}
                                            alignment="left"
                                            allowSearch={true}
                                            allowHeaderFiltering={false}
                                        />
                                        <Column
                                            dataField="refRecambio"
                                            caption={getTrad("refFabricante")}
                                            width={140}
                                            alignment="left"
                                            allowHeaderFiltering={false}
                                        />
                                        <Column
                                            dataField="denoRecambio"
                                            caption={getTrad("denominacion")}
                                            alignment="left"
                                        />
                                        <Column
                                            dataField="ubicacion"
                                            caption={getTrad("ubicacion")}
                                            alignment="left"
                                            width={110}
                                        />
                                        <Column
                                            dataField="denoFabricante"
                                            caption={getTrad("fabricante")}
                                            alignment="left"
                                            width={150}
                                        />
                                        <Column
                                            dataField="cantidad"
                                            caption={getTrad("almPrinc_abr")}
                                            width={125}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="cantidadSecundarios"
                                            caption={getTrad("almSec_abr")}
                                            width={125}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="cantidadTotal"
                                            caption={getTrad("total")}
                                            width={100}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="entradas"
                                            caption={getTrad("entradas")}
                                            width={100}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="salidas"
                                            caption={getTrad("salidas")}
                                            width={100}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="cantidadTeorico"
                                            caption={getTrad("teorico")}
                                            width={100}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="precioMedioPonderado"
                                            caption={getTrad("precioCompra")}
                                            dataType="number"
                                            width={140}
                                            format={this.currency_format}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Column
                                            dataField="coste"
                                            caption={getTrad("coste")}
                                            dataType="number"
                                            width={140}
                                            format={this.currency_format}
                                            alignment="center"
                                            allowHeaderFiltering={false}
                                            allowFiltering={false}
                                        />
                                        <Summary>
                                            <TotalItem
                                                column="referenciaInterna"
                                                displayFormat={getTrad("total").toUpperCase()}
                                            />
                                            <TotalItem
                                                column="coste"
                                                summaryType="sum"
                                                valueFormat={this.currency_format}
                                                displayFormat="{0}"
                                            />
                                        </Summary>
                                    </DataGrid>
                                ) : null}
                            </TabPanelItem>
                            <TabPanelItem>
                                {idInformeSel === 2 && almacenSel != null ? (
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox baseSize={"auto"}>
                                            <Toolbar>
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxButton"}
                                                    options={this.options_btnAtras_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    text={getTrad("fechaInicio")}
                                                    cssClass={"font-size-xxs font-lighter pl-2 pr-2"}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxDateBox"}
                                                    options={this.options_fechaInicio_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    text={getTrad("fechaFin")}
                                                    cssClass={"font-size-xxs font-lighter pl-2 pr-2"}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxDateBox"}
                                                    options={this.options_fechaFin_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"center"}
                                                    template={this.dxDataGrid_compras_ventas_title}
                                                />
                                            </Toolbar>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <DataGrid
                                                //Datos
                                                ref={this.dxDataGrid_ventas_REF}
                                                dataSource={this.datasource_infRecambios_ventas}
                                                //Propiedades
                                                columnsAutoWidth={true}
                                                height={"100%"}
                                                width={"100%"}
                                                className={"pt-2"}
                                                hoverStateEnabled={true}
                                                //Estilos
                                                showColumnLines={false}
                                                showRowLines={true}
                                                rowAlternationEnabled={true}
                                                onToolbarPreparing={this.onToolbarPreparing_compras_ventas}
                                            >
                                                <SearchPanel visible={true} width={240} />
                                                <HeaderFilter visible={true} />
                                                <FilterRow visible={true} />
                                                <Paging enabled={false} />
                                                <GroupPanel visible={true} />
                                                <Grouping autoExpandAll={false} />
                                                <ColumnChooser enabled={true} height={600} />
                                                <Column
                                                    dataField="isAlmacen"
                                                    caption={"Tipo movimiento"}
                                                    alignment="left"
                                                    allowFiltering={false}
                                                    groupIndex={0}
                                                    groupCellRender={this.groupCellRender_almacenCliente}
                                                    sortOrder="desc"
                                                    autoExpandGroup={true}
                                                    allowGrouping={false}
                                                    calculateCellValue={this.calculate_isAlmacen}
                                                />
                                                <Column
                                                    dataField="denominacion"
                                                    caption={getTrad("almacen")}
                                                    alignment="left"
                                                    minWidth={200}
                                                    groupIndex={1}
                                                    autoExpandGroup={false}
                                                    groupCellRender={this.groupCellRender}
                                                />
                                                <Column
                                                    dataField="codigo"
                                                    caption={getTrad("codigo")}
                                                    alignment="left"
                                                    width={80}
                                                />
                                                <Column
                                                    dataField="denoLavanderia"
                                                    caption={getTrad("lavanderia")}
                                                    alignment="left"
                                                    minWidth={200}
                                                />
                                                <Column
                                                    dataField="denoMaquina"
                                                    caption={getTrad("maquina")}
                                                    alignment="left"
                                                    minWidth={200}
                                                />

                                                <Column
                                                    dataField="referenciaInterna"
                                                    caption={getTrad("refInterna")}
                                                    width={120}
                                                    alignment="left"
                                                    allowSearch={true}
                                                    allowHeaderFiltering={false}
                                                />
                                                <Column
                                                    dataField="refRecambio"
                                                    caption={getTrad("refFabricante")}
                                                    width={140}
                                                    alignment="left"
                                                    allowHeaderFiltering={false}
                                                    visible={false}
                                                    showInColumnChooser={true}
                                                />
                                                <Column
                                                    dataField="denoRecambio"
                                                    caption={getTrad("denominacion")}
                                                    alignment="left"
                                                    minWidth={200}
                                                />
                                                <Column
                                                    dataField="ubicacion"
                                                    caption={getTrad("ubicacion")}
                                                    alignment="left"
                                                    width={110}
                                                    visible={false}
                                                    showInColumnChooser={true}
                                                />
                                                <Column
                                                    dataField="denoFabricante"
                                                    caption={getTrad("fabricante")}
                                                    alignment="left"
                                                    width={150}
                                                    visible={false}
                                                    showInColumnChooser={true}
                                                />
                                                <Column
                                                    dataField="cantidad"
                                                    caption={getTrad("cantidad")}
                                                    width={100}
                                                    alignment="center"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                />
                                                <Column
                                                    dataField="precio"
                                                    caption={getTrad("precio")}
                                                    dataType="number"
                                                    width={140}
                                                    format={this.currency_format}
                                                    alignment="right"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                />
                                                <Column
                                                    dataField="precioTotal"
                                                    caption={getTrad("precioTotal")}
                                                    dataType="number"
                                                    width={120}
                                                    format={this.currency_format}
                                                    alignment="right"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                    fixed={true}
                                                    fixedPosition={"right"}
                                                />
                                                <Summary>
                                                    <TotalItem
                                                        column="codigo"
                                                        displayFormat={getTrad("total").toUpperCase()}
                                                    />
                                                    <GroupItem
                                                        column="precioTotal"
                                                        summaryType="sum"
                                                        valueFormat={this.currency_format}
                                                        displayFormat={"{0}"}
                                                        alignByColumn={true}
                                                    />
                                                    <TotalItem
                                                        column="precioTotal"
                                                        summaryType="sum"
                                                        valueFormat={this.currency_format}
                                                        displayFormat="{0}"
                                                    />
                                                </Summary>
                                            </DataGrid>
                                        </ItemBox>
                                    </Box>
                                ) : null}
                            </TabPanelItem>
                            <TabPanelItem>
                                {idInformeSel === 3 && almacenSel != null ? (
                                    <Box direction="col" width="100%" height="100%">
                                        <ItemBox baseSize={"auto"}>
                                            <Toolbar>
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxButton"}
                                                    options={this.options_btnAtras_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    text={getTrad("fechaInicio")}
                                                    cssClass={"font-size-xxs font-lighter pl-2 pr-2"}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxDateBox"}
                                                    options={this.options_fechaInicio_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    text={getTrad("fechaFin")}
                                                    cssClass={"font-size-xxs font-lighter pl-2 pr-2"}
                                                />
                                                <ItemToolbar
                                                    location={"before"}
                                                    widget={"dxDateBox"}
                                                    options={this.options_fechaFin_compras_ventas}
                                                />
                                                <ItemToolbar
                                                    location={"center"}
                                                    template={this.dxDataGrid_compras_ventas_title}
                                                />
                                            </Toolbar>
                                        </ItemBox>
                                        <ItemBox ratio={1}>
                                            <DataGrid
                                                //Datos
                                                ref={this.dxDataGrid_compras_REF}
                                                dataSource={this.datasource_infRecambios_compras}
                                                //Propiedades
                                                columnsAutoWidth={true}
                                                height={"100%"}
                                                width={"100%"}
                                                className={"pt-2"}
                                                hoverStateEnabled={true}
                                                //Estilos
                                                showColumnLines={false}
                                                showRowLines={true}
                                                rowAlternationEnabled={true}
                                                onToolbarPreparing={this.onToolbarPreparing_compras_ventas}
                                            >
                                                <SearchPanel visible={true} width={240} />
                                                <HeaderFilter visible={true} />
                                                <FilterRow visible={true} />
                                                <Paging enabled={false} />
                                                <GroupPanel visible={true} />
                                                <Grouping autoExpandAll={false} />
                                                <ColumnChooser enabled={true} height={600} />
                                                <Column
                                                    dataField="codigo"
                                                    caption={getTrad("codigo")}
                                                    width={80}
                                                    alignment="left"
                                                    allowSearch={true}
                                                    allowHeaderFiltering={false}
                                                />
                                                <Column
                                                    dataField="referenciaInterna"
                                                    caption={getTrad("refInterna")}
                                                    width={140}
                                                    alignment="left"
                                                    allowSearch={true}
                                                    allowHeaderFiltering={false}
                                                />
                                                <Column
                                                    dataField="refRecambio"
                                                    caption={getTrad("refFabricante")}
                                                    width={140}
                                                    alignment="left"
                                                    allowHeaderFiltering={false}
                                                />
                                                <Column
                                                    dataField="denoRecambio"
                                                    caption={getTrad("denominacion")}
                                                    alignment="left"
                                                />
                                                <Column
                                                    dataField="ubicacion"
                                                    caption={getTrad("ubicacion")}
                                                    alignment="left"
                                                    width={110}
                                                />
                                                <Column
                                                    dataField="denoFabricante"
                                                    caption={getTrad("fabricante")}
                                                    alignment="left"
                                                    width={150}
                                                />
                                                <Column
                                                    dataField="cantidad"
                                                    caption={getTrad("cantidad")}
                                                    width={100}
                                                    alignment="center"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                />
                                                <Column
                                                    dataField="precio"
                                                    caption={getTrad("precio")}
                                                    dataType="number"
                                                    width={140}
                                                    format={this.currency_format}
                                                    alignment="right"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                />
                                                <Column
                                                    dataField="precioTotal"
                                                    caption={getTrad("precioTotal")}
                                                    dataType="number"
                                                    width={150}
                                                    format={this.currency_format}
                                                    alignment="right"
                                                    allowHeaderFiltering={false}
                                                    allowFiltering={false}
                                                />
                                                <Summary>
                                                    <TotalItem
                                                        column="refRecambio"
                                                        displayFormat={getTrad("total").toUpperCase()}
                                                    />
                                                    <GroupItem
                                                        column="precioTotal"
                                                        summaryType="sum"
                                                        valueFormat={this.currency_format}
                                                        displayFormat={"{0}"}
                                                        alignByColumn={true}
                                                    />
                                                    <TotalItem
                                                        column="precioTotal"
                                                        summaryType="sum"
                                                        valueFormat={this.currency_format}
                                                        displayFormat="{0}"
                                                    />
                                                </Summary>
                                            </DataGrid>
                                        </ItemBox>
                                    </Box>
                                ) : null}
                            </TabPanelItem>
                        </TabPanel>
                        <PopupHistorico
                            visible={popupHistoricoVisible}
                            tipoHistorico={tipoHistorico}
                            idsRecambioHistorico={idsRecambioHistorico}
                            idAlmacenHistorico={inventarioSel?.idAlmacen ?? -1}
                            fecha={fechaHasta}
                            fechaCierre={inventarioSel?.fecha ? startOfMonth(new Date(inventarioSel?.fecha)) : null}
                            recambioSel={recambioSel}
                            referenciaInternaSel={referenciaInternaSel}
                            moneda={almacenSel?.tblMoneda?.codigo}
                            onHiding={this.onHiding_PopupHistorico}
                        />
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    onHiding_PopupHistorico = () => this.setState({ popupHistoricoVisible: false });

    onCellClick_inventario = ({ rowType, column, data }) => {
        const dataField = column.dataField;

        if (rowType === "data" && idsTipoHistorico[dataField]) {
            const { idRecambio, denoRecambio, referenciaInterna } = data;
            this.onClick_cantidadMoviemientos(
                idsTipoHistorico[dataField],
                [idRecambio],
                denoRecambio,
                referenciaInterna
            );
        }
    };

    onCellPrepared_inventario = ({ cellElement, rowType, column }) => {
        const dataField = column.dataField;

        if (rowType === "data" && idsTipoHistorico[dataField]) {
            cellElement.addClass("cellClicable");
        }
    };

    onClick_cantidadMoviemientos = (tipoHistorico, idsRecambioHistorico, denoRecambio, referenciaInterna) => {
        this.setState({
            popupHistoricoVisible: true,
            tipoHistorico,
            idsRecambioHistorico,
            recambioSel: denoRecambio,
            referenciaInternaSel: referenciaInterna,
        });
    };

    //FORMATS
    currency_format = {
        style: "currency",
        maximumFractionDigits: 2,
        currency: "EUR",
    };

    dxTabPanel_elementAttr = { class: "no-header" };

    //Options
    //Compra ventas
    options_btnAtras_compras_ventas = {
        text: getTrad("atras"),
        icon: "back",
        onClick: () => {
            this.dxDataGrid_compras_ventas_btnAtras();
        },
    };

    options_fechaInicio_compras_ventas = {
        type: "date",
        pickerType: "calendar",
        width: "150px",
        displayFormat: "dd/MM/yyyy",
        value: startOfMonth(new Date()),
        onValueChanged: (e) => {
            this.setState({
                fechaDesde: e.value,
            });
        },
    };

    options_fechaFin_compras_ventas = {
        type: "date",
        pickerType: "calendar",
        width: "150px",
        displayFormat: "dd/MM/yyyy",
        value: new Date(),
        onValueChanged: (e) => {
            this.setState({
                fechaHasta: e.value,
            });
        },
    };

    calculate_isAlmacen = (rowData) => {
        const { almacenSel } = this.state;

        if (rowData.isAlmacen == true) return almacenSel.tipoSalidaRecambio;
        else return "CLIENTES";
    };

    dxList_enum_informes_itemRender(itemData) {
        let dxList_height = this.dxList_enum_informes.element().height(),
            innerPadding_itemList = 21,
            itemHeight = dxList_height / this.enum_informes.length - innerPadding_itemList;

        return (
            <div style={{ height: itemHeight }} className="font-size container_spanCentrado">
                {itemData.denominacion.toUpperCase()}
            </div>
        );
    }

    onSelectionChanged_dxList_enum_informes(e) {
        if (e.addedItems.length > 0)
            this.setState({
                idInformeSel: e.addedItems[0].idInforme,
                almacenSel: null,
            });
        else this.setState({ idInformeSel: null });
    }

    onSelectionChanged_dxList_almacen(e) {
        if (e.addedItems.length > 0) {
            let { inventariosTotales, idInformeSel } = this.state;
            let almacenSel = e.addedItems[0];
            this.currency_format.currency = almacenSel.tblMoneda.codigo;
            if (idInformeSel === 1) {
                let inventarios_filtrados = $.grep(inventariosTotales, function (item) {
                    return item.idAlmacen === almacenSel.idAlmacen;
                });

                inventarios_filtrados = inventarios_filtrados.map((inv, index) => {
                    return {
                        ...inv,
                        idInf: index,
                    };
                });

                this.setState({
                    inventariosFiltrados: inventarios_filtrados,
                    almacenSel: almacenSel,
                });
            } else {
                this.setState({
                    almacenSel: almacenSel,
                });
            }
        } else {
            this.setState({ almacenSel: null, inventariosFiltrados: [] });
        }
    }

    onSelectionChanged_dxList_inventarios = (e) => {
        if (e.addedItems.length > 0) this.setState({ inventarioSel: e.addedItems[0] });
        else this.setState({ inventarioSel: null });
    };

    //GRID COMUNES
    dxDataGrid_onInitialized_tooltip(e) {
        tooltipControl_creacion(e.element, e.component.option("text"));
    }

    //#region INVENTARIO

    onToolbarPreparing_inventario(e) {
        let _this = this;
        let { inventarioSel, fechaHasta } = this.state;

        let fechaMin = new Date(inventarioSel.fecha);
        if (inventarioSel?.isCierre) {
            fechaMin = endOfMonth(fechaMin);
            fechaMin = new Date(fechaMin.setDate(fechaMin.getDate()));
        }

        const fechaMax = null;

        e.toolbarOptions.items.unshift(
            {
                location: "before",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    text: getTrad("atras"),
                    icon: "back",
                    onClick: this.dxDataGrid_inventario_btnAtras,
                },
            },
            {
                location: "before",
                cssClass: "font-size-xxs font-lighter pl-2 pr-2",
                text: getTrad("fechaLimite"),
            },
            {
                location: "before",
                widget: "dxDateBox",
                options: {
                    type: "date",
                    pickerType: "calendar",
                    width: "150px",
                    displayFormat: "dd/MM/yyyy",
                    value: fechaHasta,
                    min: fechaMin,
                    max: fechaMax,
                    onValueChanged: function (e) {
                        _this.setState({
                            fechaHasta: e.value,
                        });
                    },
                },
            },
            {
                location: "center",
                template: this.dxDataGrid_inventario_title,
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    width: "100%",
                    icon: "exportxlsx",
                    text: getTrad("exportar"),
                    type: "normal",
                    onClick: this.dxDataGrid_inventario_btnExport,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onInitialized: this.dxDataGrid_onInitialized_tooltip,
                    onClick: this.dxDataGrid_inventario_limpiarFiltros,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onInitialized: this.dxDataGrid_onInitialized_tooltip,
                    onClick: this.dxDataGrid_inventario_actualizar,
                },
            }
        );
    }
    dxDataGrid_inventario_title() {
        let { almacenSel, inventarioSel } = this.state;
        return $("<div>")
            .addClass("font-weight-lighter pl-2 font-size-xl")
            .text(inventarioSel ? `${inventarioSel.denominacion} - ${almacenSel.denominacion}` : "");
    }

    dxDataGrid_inventario_btnAtras() {
        this.setState({
            inventarioSel: null,
            fechaDesde: startOfMonth(new Date()),
            fechaHasta: new Date(),
        });
    }

    dxDataGrid_inventario_btnExport(e) {
        let { inventarioSel } = this.state;
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Recambios");
        let nombreArchivo = "Inventario_recambios.xlsx";

        worksheet.getRow(2).getCell(2).value = inventarioSel.denominacion;
        worksheet.getRow(2).getCell(2).font = {
            bold: true,
            size: 16,
            underline: "double",
        };

        exportDataGrid({
            worksheet,
            component: this.dxDataGrid_inventario,
            topLeftCell: { row: 4, column: 2 },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), nombreArchivo);
            });
        });
    }

    dxDataGrid_inventario_limpiarFiltros() {
        this.dxDataGrid_inventario.clearFilter();

        notify({
            message: getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxDataGrid_inventario_actualizar() {
        this.datasource_infRecambios_inventario.reload();
    }

    //#endregion

    //#region COMPRAS-VENTAS
    onToolbarPreparing_compras_ventas(e) {
        let _this = this;
        let { fechaDesde, fechaHasta } = this.state;

        e.toolbarOptions.items.unshift(
            {
                location: "after",
                widget: "dxButton",
                showText: "always",
                locateInMenu: "auto",
                options: {
                    width: "100%",
                    icon: "exportxlsx",
                    text: getTrad("exportar"),
                    type: "normal",
                    onClick: this.dxDataGrid_compras_ventas_btnExport,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                locateInMenu: "auto",
                options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onInitialized: this.dxDataGrid_onInitialized_tooltip,
                    onClick: this.dxDataGrid_compras_ventas_limpiarFiltros,
                },
            },
            {
                location: "after",
                widget: "dxButton",
                showText: "inMenu",
                options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onInitialized: this.dxDataGrid_onInitialized_tooltip,
                    onClick: this.dxDataGrid_compras_ventas_actualizar,
                },
            }
        );
    }

    dxDataGrid_compras_ventas_title() {
        let { almacenSel } = this.state;
        return $("<div>")
            .addClass("font-weight-lighter pl-2 font-size-xl")
            .text(almacenSel ? almacenSel.denominacion : "");
    }

    dxDataGrid_compras_ventas_btnAtras() {
        this.setState({
            almacenSel: null,
            fechaDesde: startOfMonth(new Date()),
            fechaHasta: new Date(),
            isDataGridVentasExpanded: false,
        });

        this.options_fechaInicio_compras_ventas.value = startOfMonth(new Date());
        this.options_fechaFin_compras_ventas.value = new Date();
    }

    dxDataGrid_compras_ventas_btnExport(e) {
        let { almacenSel, idInformeSel } = this.state;
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Recambios");
        let nombreArchivo = idInformeSel === 2 ? "Ventas_recambios.xlsx" : "Compras_recambios.xlsx";

        worksheet.getRow(2).getCell(2).value = almacenSel.denominacion;
        worksheet.getRow(2).getCell(2).font = {
            bold: true,
            size: 16,
            underline: "double",
        };

        exportDataGrid({
            worksheet,
            component: idInformeSel === 2 ? this.dxDataGrid_ventas : this.dxDataGrid_compras,
            topLeftCell: { row: 4, column: 2 },
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), nombreArchivo);
            });
        });
    }

    dxDataGrid_compras_ventas_limpiarFiltros() {
        let { idInformeSel } = this.state;

        let dataGrid = idInformeSel === 2 ? this.dxDataGrid_ventas : this.dxDataGrid_compras;
        dataGrid.clearFilter();

        notify({
            message: getTrad("aviso_C_FiltroRestaurado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
        });
    }

    dxDataGrid_compras_ventas_actualizar() {
        const { idInformeSel } = this.state;
        idInformeSel === 2
            ? this.datasource_infRecambios_ventas.reload()
            : this.datasource_infRecambios_compras.reload();
    }

    groupCellRender(e) {
        let fontWeight = "700";
        $.each(e.row.cells, function (index, item) {
            if (item.column.dataField == "precioTotal") {
                if (item.cellElement) {
                    item.cellElement.find(".dx-datagrid-summary-item").css({
                        "font-weight": fontWeight,
                    });
                }
            }
        });
        return <div style={{ fontWeight: fontWeight }}>{e.text}</div>;
    }

    groupCellRender_almacenCliente(e) {
        let fontWeight = "700";
        if (e.row.cells && e.row.cells.length > 0) {
            $(e.row.cells[0].cellElement).css({
                visibility: "hidden",
                width: 1,
            });

            $.each(e.row.cells, function (index, item) {
                if (item.column.dataField == "precioTotal") {
                    if (item.cellElement) {
                        item.cellElement.find(".dx-datagrid-summary-item").css({
                            "font-weight": fontWeight,
                            color: "var(--primary)",
                        });
                    }
                }
            });
        }
        return (
            <div
                style={{
                    color: "var(--primary)",
                    padding: "5px",
                    fontWeight: fontWeight,
                }}
            >
                {e.text}
            </div>
        );
    }

    //#endregion

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        this.cargaDatos_general();
    }

    componentDidUpdate(prevProps, prevState) {
        let { idInformeSel, fechaDesde, fechaHasta, almacenSel } = this.state;

        if (idInformeSel === 1) {
            if (fechaHasta && fechaHasta.getTime() !== prevState.fechaHasta.getTime()) {
                this.datasource_infRecambios_inventario.reload();
            }
        } else {
            if (
                almacenSel !== null &&
                ((fechaDesde && fechaDesde.getTime() !== prevState.fechaDesde.getTime()) ||
                    (fechaHasta && fechaHasta.getTime() !== prevState.fechaHasta.getTime()))
            ) {
                idInformeSel === 2
                    ? this.datasource_infRecambios_ventas.reload()
                    : this.datasource_infRecambios_compras.reload();
            }
        }
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfRecambios);
