import React from "react";

import { DateBox } from "devextreme-react";
import { Button, CalendarOptions } from "devextreme-react/date-box";

class SelectorFecha extends React.Component {
    constructor(props) {
        super(props);
    }

    inputAttr = { class: "font-size-sm text-uppercase" };

    prevDate_options = {
        icon: "spinprev",
        stylingMode: "text",
        onClick: () => this.prevDate_Click(),
    };

    nextDate_options = {
        icon: "spinnext",
        stylingMode: "text",
        onClick: () => this.nextDate_Click(),
    };

    render() {
        const { value } = this.props;
        return (
            <DateBox
                key="postHeading_1"
                className="centerText"
                value={value}
                openOnFieldClick={true}
                acceptCustomValue={false}
                displayFormat={this.datePicker_displayFormat}
                onValueChanged={this.datePicker_onValueChanged}
                width={250}
                stylingMode="underlined"
                inputAttr={this.inputAttr}
            >
                <CalendarOptions maxZoomLevel="year" minZoomLevel="year" />
                <Button name="prevDate" location="before" options={this.prevDate_options} />
                <Button name="nextDate" location="after" options={this.nextDate_options} />
            </DateBox>
        );
    }

    prevDate_Click = (e) => {
        const { value, onValueChanged } = this.props;
        let _fechaSel = new Date(value.getTime());
        onValueChanged(new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)));
    };

    nextDate_Click = (e) => {
        const { value, onValueChanged } = this.props;
        let _fechaSel = new Date(value.getTime());
        onValueChanged(new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)));
    };

    datePicker_onValueChanged = (e) => {
        const { onValueChanged } = this.props;
        onValueChanged(e.value);
    };

    datePicker_displayFormat = (fecha) => {
        if (new Date().getFullYear() === fecha.getFullYear()) {
            return fecha.toLocaleString(undefined, { month: "long" }).toUpperCase();
        } else {
            return fecha.toLocaleString(undefined, { month: "long", year: "numeric" }).toUpperCase();
        }
    };
}

export default SelectorFecha;
