import { List } from "devextreme-react";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import SelectorPersonas from "components/SelectorPersonas";
import { Column } from "devextreme-react/data-grid";
import { getTrad } from "helpers";

const GestionDestinatarios = forwardRef(({ setDestinatarios }, ref) => {
    const [renderedDestinatarios, setRenderedDestinatarios] = useState([]);
    const selectorPersonasREF = useRef();

    useImperativeHandle(ref, () => ({
        clearComponent,
    }));

    const clearComponent = () => {
        selectorPersonasREF.current.dxDataGrid.clearSelection();
        setRenderedDestinatarios([]);
    };

    const onSelectionChanged = (e) => {
        const rendered = e.selectedRowsData.map((destinatario) => {
            const nombreCompuesto = destinatario.nombre + " " + destinatario.apellidos;
            return {
                idPersona: destinatario.idPersona,
                nombreCompuesto: nombreCompuesto,
            };
        });

        const destinatariosOrderByAlphabet = rendered.sort((a, b) => {
            if (a.nombreCompuesto < b.nombreCompuesto) {
                return -1;
            }
            if (a.nombreCompuesto > b.nombreCompuesto) {
                return 1;
            }
            return 0;
        });

        setDestinatarios(e.selectedRowsData);
        setRenderedDestinatarios(destinatariosOrderByAlphabet);
    };

    const removePersonaSel = (idPersona) => {
        const filtered = renderedDestinatarios.filter((destinatario) => destinatario.idPersona !== idPersona);
        selectorPersonasREF.current.dxDataGrid.selectRows(filtered.map((destinatario) => destinatario.idPersona));
        setRenderedDestinatarios(filtered);
    };

    const listItemRender = (item, index) => {
        return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {item.nombreCompuesto}
                <div className="container_spanCentrado pointer" onMouseDown={() => removePersonaSel(item.idPersona)}>
                    <div className="icon_Cerrar font-size-lg" />
                </div>
            </div>
        );
    };

    const getActiveTag = (data) => {
        const bgColor = data.data.activo ? "success" : "danger";

        let properties = {
            className: "chip " + bgColor,
        };

        return <div {...properties}>{data.data.activo ? getTrad("activo") : getTrad("noActivo")}</div>;
    };

    const columns = [
        <Column
            dataField={"idCentroLav"}
            caption={getTrad("centro")}
            allowSorting={false}
            sortIndex={0}
            minWidth={150}
        />,
        <Column
            dataField={"idTipoTrabajo"}
            caption={getTrad("tipoTrabajo")}
            allowSorting={false}
            sortOrder={"asc"}
            sortIndex={1}
            minWidth={150}
        />,
        <Column
            dataField={"idCategoriaInterna"}
            caption={getTrad("categoriaInterna")}
            allowSorting={false}
            sortIndex={0}
            minWidth={150}
        />,
        <Column
            dataField={"activo"}
            caption={getTrad("activo")}
            allowSorting={false}
            defaultFilterValue={true}
            cellRender={getActiveTag}
            sortIndex={1}
            width={100}
        />,
    ];

    return (
        <>
            <div
                style={{
                    overflowY: "hidden",
                    height: "100%",
                    flexDirection: "column",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <SelectorPersonas
                    ref={selectorPersonasREF}
                    filter={["eliminado", "=", false]}
                    selectorCompacto={true}
                    selectionMode="multiple"
                    allowFiltering={true}
                    onSelectionChanged={onSelectionChanged}
                    columns={columns}
                    useDefaultToolbar={true}
                    useUberSearchBar={true}
                    dropDownWidth={1100}
                />
                <List
                    dataSource={renderedDestinatarios}
                    displayExpr={"nombreCompuesto"}
                    selectionMode="none"
                    itemRender={listItemRender}
                />
            </div>
        </>
    );
});

export default GestionDestinatarios;
