import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import { history, errorHandler, authHeader, getTrad, getNombreFormulario, formatNumber } from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import ScrollView from "devextreme-react/scroll-view";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import {
    CircularGauge,
    Geometry,
    Scale,
    Label,
    Tick,
    RangeContainer,
    Range,
    ValueIndicator,
    SubvalueIndicator,
    Margin,
} from "devextreme-react/circular-gauge";
import Chart, {
    CommonSeriesSettings,
    SeriesTemplate,
    ArgumentAxis,
    Series,
    Legend,
    Label as ChartLabel,
    Tooltip as ChartTooltip,
    ValueAxis,
} from "devextreme-react/chart";
import { Tooltip } from "devextreme-react/tooltip";

import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";

class DashboardFinanciero extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            idGrupoEmpresarialSel: null,
            añoSel: new Date().getFullYear(),
            trimestreSel: 1,
            conceptos: [],
            comparacionAnual: {},
            show_ajustado_EBITDA: false,
            show_ajustado_DeudaFinancieraTotal_EBITDA: false,
            show_ajustado_DFN_EBITDA: false,
            show_ajustado_CoberturaDeuda: false,
        };

        //REFERENCIAS
        this.dxChart_Tesoreria_REF = React.createRef();
        this.dxChart_LiquidezSolvencia_REF = React.createRef();
        this.dxChart_SolvenciaTotal_REF = React.createRef();
        this.dxChart_Endeudamiento_REF = React.createRef();
        this.dxChart_EndeudamientoFinanciero_REF = React.createRef();
        this.dxChart_DeudaFinancieraTotal_EBITDA_REF = React.createRef();
        this.dxChart_DFN_EBITDA_REF = React.createRef();
        this.dxChart_CoberturaDeuda_REF = React.createRef();

        //DATASOURCE
        this.dataSource_tblConceptosFinancieros_onLoaded = this.dataSource_tblConceptosFinancieros_onLoaded.bind(this);
        this.dataSource_tblGrupoEmpresarial_onLoaded = this.dataSource_tblGrupoEmpresarial_onLoaded.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        //YEAR PICKER
        this.yearPicker = this.yearPicker.bind(this);
        this.prevYear_Click = this.prevYear_Click.bind(this);
        this.nextYear_Click = this.nextYear_Click.bind(this);
        this.yearPicker_onValueChanged = this.yearPicker_onValueChanged.bind(this);

        //SELECTOR GRUPO EMPRESARIAL
        this.selectorGrupoEmpresarial = this.selectorGrupoEmpresarial.bind(this);
        this.selectorGrupoEmpresarial_onSelectionChanged = this.selectorGrupoEmpresarial_onSelectionChanged.bind(this);

        //SELECTOR TRIMESTRE
        this.selectorTrimestre = this.selectorTrimestre.bind(this);
        this.selectorTrimestre_onSelectionChanged = this.selectorTrimestre_onSelectionChanged.bind(this);

        //CAHRT
        this.customizeTooltip_Charts = this.customizeTooltip_Charts.bind(this);

        //CONCEPTOS AJUSTADOS
        this.toogle_DeudaFinancieraTotal_EBITDA = this.toogle_DeudaFinancieraTotal_EBITDA.bind(this);
        this.toogle_DFN_EBITDA = this.toogle_DFN_EBITDA.bind(this);
        this.toogle_CoberturaDeuda = this.toogle_CoberturaDeuda.bind(this);
        this.toogle_EBITDA = this.toogle_EBITDA.bind(this);

        //OTROS
        this.get_BorderColor_Concepto_Trimestral = this.get_BorderColor_Concepto_Trimestral.bind(this);

        this.loadPanel_show();
    }

    get dxChart_Tesoreria() {
        return this.dxChart_Tesoreria_REF.current.instance;
    }
    get dxChart_LiquidezSolvencia() {
        return this.dxChart_LiquidezSolvencia_REF.current.instance;
    }
    get dxChart_SolvenciaTotal() {
        return this.dxChart_SolvenciaTotal_REF.current.instance;
    }
    get dxChart_Endeudamiento() {
        return this.dxChart_Endeudamiento_REF.current.instance;
    }
    get dxChart_EndeudamientoFinanciero() {
        return this.dxChart_EndeudamientoFinanciero_REF.current.instance;
    }
    get dxChart_DeudaFinancieraTotal_EBITDA() {
        return this.dxChart_DeudaFinancieraTotal_EBITDA_REF.current.instance;
    }
    get dxChart_DFN_EBITDA() {
        return this.dxChart_DFN_EBITDA_REF.current.instance;
    }
    get dxChart_CoberturaDeuda() {
        return this.dxChart_CoberturaDeuda_REF.current.instance;
    }

    //DATSOURCES
    dataSource_tblConceptosFinancieros = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblConceptosFinancieros",
            key: "idConceptoFinanciero",
            keyType: "Int32",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            onLoaded: (data) => {
                this.dataSource_tblConceptosFinancieros_onLoaded(data);
            },
            version: 4,
        }),
        expand: ["tblDatosFinancieros"],
    });
    dataSource_tblConceptosFinancieros_onLoaded(data) {
        this.setState({ conceptos: data });
        this.loadPanel_hide();
    }
    dataSource_tblGrupoEmpresarial = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblGrupoEmpresarial",
            key: "idGrupoEmpresarial",
            keyType: "Int32",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            onLoaded: (data) => {
                this.dataSource_tblGrupoEmpresarial_onLoaded(data);
            },
            version: 4,
        }),
    });
    dataSource_tblGrupoEmpresarial_onLoaded(data) {
        this.setState({ idGrupoEmpresarialSel: data[0].idGrupoEmpresarial });
    }

    enum_trimestres = [
        { trimestre: null, denominacion: "Comparación anual", abr: "C/A" },
        { trimestre: 1, denominacion: getTrad("primerTrimestre"), abr: "T1" },
        { trimestre: 2, denominacion: getTrad("segundoTrimestre"), abr: "T2" },
        { trimestre: 3, denominacion: getTrad("tercerTrimestre"), abr: "T3" },
        { trimestre: 4, denominacion: getTrad("cuartoTrimestre"), abr: "T4" },
    ];

    customPalette = "Soft Blue";

    //YEAR PICKER
    yearPicker() {
        return (
            <DateBox
                className="centerText font-size-sm"
                inputAttr={{ class: "font-size-sm" }}
                value={new Date(this.state.añoSel, 0, 1, 0, 0, 0)}
                width={200}
                stylingMode="underlined"
                displayFormat={"yyyy"}
                onValueChanged={this.yearPicker_onValueChanged}
            >
                <DateBoxButton
                    name="prevYear"
                    location="before"
                    options={{ icon: "spinprev", stylingMode: "text", onClick: this.prevYear_Click }}
                />
                <DateBoxButton
                    name="nextYear"
                    location="after"
                    options={{ icon: "spinnext", stylingMode: "text", onClick: this.nextYear_Click }}
                />
            </DateBox>
        );
    }
    prevYear_Click(e) {
        this.setState({ añoSel: this.state.añoSel - 1 });
    }
    nextYear_Click(e) {
        this.setState({ añoSel: this.state.añoSel + 1 });
    }
    yearPicker_onValueChanged(e) {
        this.setState({ añoSel: e.value.getFullYear() });
    }

    //SELECTOR GRUPO EMPRESARIAL
    selectorGrupoEmpresarial() {
        let { idGrupoEmpresarialSel } = this.state;
        return (
            <SelectBox
                deferRendering={false}
                className="centerText font-size-sm mr-3"
                inputAttr={{ class: "font-size-sm" }}
                dataSource={this.dataSource_tblGrupoEmpresarial}
                displayExpr="denominacion"
                valueExpr="idGrupoEmpresarial"
                value={idGrupoEmpresarialSel}
                defaultValue={idGrupoEmpresarialSel}
                width={300}
                stylingMode="underlined"
                onSelectionChanged={this.selectorGrupoEmpresarial_onSelectionChanged}
            />
        );
    }
    selectorGrupoEmpresarial_onSelectionChanged(e) {
        this.setState({ idGrupoEmpresarialSel: e.selectedItem.idGrupoEmpresarial });
    }

    //SELECTOR TRIMESTRE
    selectorTrimestre() {
        let { trimestreSel } = this.state;
        return (
            <SelectBox
                className="centerText font-size-sm ml-3"
                inputAttr={{ class: "font-size-sm" }}
                items={this.enum_trimestres}
                displayExpr="denominacion"
                valueExpr="trimestre"
                value={trimestreSel}
                defaultValue={trimestreSel}
                width={250}
                stylingMode="underlined"
                onSelectionChanged={this.selectorTrimestre_onSelectionChanged}
            />
        );
    }

    selectorTrimestre_onSelectionChanged(e) {
        this.setState({ trimestreSel: e.selectedItem.trimestre });
    }

    render() {
        let {
            conceptos,
            añoSel,
            trimestreSel,
            comparacionAnual,
            show_ajustado_EBITDA,
            show_ajustado_DeudaFinancieraTotal_EBITDA,
            show_ajustado_DFN_EBITDA,
            show_ajustado_CoberturaDeuda,
        } = this.state;

        //Fondo de maniobra necesario
        let conceptos_FM_Necesario = conceptos.filter((x) => x.idConceptoFinanciero === 1)[0];

        //Fondo de maniobra ajustado
        let conceptos_FM_Ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 2)[0];

        //Deuda financiera
        let conceptos_DeudaFinanciera = conceptos.filter((x) => x.idConceptoFinanciero === 3)[0];

        //Riesgo bancario total
        let conceptos_RiesgoBancario = conceptos.filter((x) => x.idConceptoFinanciero === 4)[0];

        //Tesorería
        let conceptos_Tesoreria = conceptos.filter((x) => x.idConceptoFinanciero === 5)[0];

        //Liquidez y solvencia a c/p (sin existencias)
        let conceptos_LiquidezSolvencia = conceptos.filter((x) => x.idConceptoFinanciero === 6)[0];

        //Solvencia total
        let conceptos_SolvenciaTotal = conceptos.filter((x) => x.idConceptoFinanciero === 7)[0];

        //Endeudamiento total
        let conceptos_Endeudamiento = conceptos.filter((x) => x.idConceptoFinanciero === 8)[0];

        //Ratio deuda financiera total / EBITDA
        let conceptos_DeudaFinancieraTotal_EBITDA = conceptos.filter((x) => x.idConceptoFinanciero === 9)[0];

        //Ratio deuda financiera total / EBITDA ajustado
        let conceptos_DeudaFinancieraTotal_EBITDA_ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 10)[0];

        //Cobertura deuda
        let conceptos_CoberturaDeuda = conceptos.filter((x) => x.idConceptoFinanciero === 11)[0];

        //Ratio cobertura deuda con EBITDA ajustado
        let conceptos_CoberturaDeuda_ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 12)[0];

        //EBITDA
        let conceptos_EBITDA = conceptos.filter((x) => x.idConceptoFinanciero === 13)[0];

        //Endeudamiento financiero
        let conceptos_EndeudamientoFinanciero = conceptos.filter((x) => x.idConceptoFinanciero === 14)[0];

        //EBITDA ajustado
        let conceptos_EBITDA_ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 15)[0];

        //EBITDA CFW
        let conceptos_EBITDA_CFW = conceptos.filter((x) => x.idConceptoFinanciero === 16)[0];

        //Ratio DFN/EBITDA
        let conceptos_DFN_EBITDA = conceptos.filter((x) => x.idConceptoFinanciero === 19)[0];

        //Ratio DFN/EBITDA ajustado
        let conceptos_DFN_EBITDA_ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 20)[0];

        return (
            <Fragment>
                <PageTitle
                    heading={getNombreFormulario(this)}
                    postHeading={[this.selectorGrupoEmpresarial(), this.yearPicker(), this.selectorTrimestre()]}
                />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
                    <div id="DashboardFinanciero" className="formContainer scrollbar-container">
                        {conceptos.length > 0 ? (
                            <ScrollView className="cardContent">
                                <Row>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 mb-3 " +
                                                this.get_BorderColor_FondoManiobra(
                                                    conceptos_FM_Necesario,
                                                    conceptos_FM_Ajustado
                                                )
                                            }
                                            style={{ flex: "3" }}
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {trimestreSel !== null &&
                                                    !comparacionAnual[conceptos_FM_Ajustado.denominacion]
                                                        ? "FONDO DE MANIOBRA"
                                                        : "FONDO DE MANIOBRA REAL"}
                                                    {this.get_observaciones(conceptos_FM_Ajustado).length > 0 ||
                                                    this.get_observaciones(conceptos_FM_Ajustado, true).length > 0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_FondoManiobra"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_FondoManiobra"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_FM_Ajustado)
                                                                        .length > 0
                                                                        ? this.get_observaciones(conceptos_FM_Ajustado)
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_FM_Ajustado)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(conceptos_FM_Ajustado, true)
                                                                        .length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(conceptos_FM_Ajustado, true)
                                                                        .length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_FM_Ajustado,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_FM_Ajustado.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_FM_Ajustado
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div
                                                    className="widget-content pl-0 pr-0 he-100"
                                                    style={{ padding: "0.8rem" }}
                                                >
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[conceptos_FM_Ajustado.denominacion]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row>
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_FondoManiobra_Trimestral(
                                                                                                    conceptos_FM_Necesario,
                                                                                                    conceptos_FM_Ajustado
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="d-flex flex-column">
                                                                                        <div className="font-size-xxl font-weight-bold">
                                                                                            {this.get_Dif_FondoManiobra(
                                                                                                conceptos_FM_Necesario,
                                                                                                conceptos_FM_Ajustado,
                                                                                                "largeNumber"
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_FondoManiobra_Anual(
                                                                                                    conceptos_FM_Necesario,
                                                                                                    conceptos_FM_Ajustado
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="flex-fill ml-1 mr-1">
                                                                            <div className="widget-content-wrapper w-100 pb-3">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    dataSource={this.get_DatosCharts_FondoManiobra(
                                                                                        conceptos_FM_Necesario,
                                                                                        conceptos_FM_Ajustado
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    rotated={true}
                                                                                    customizePoint={
                                                                                        this
                                                                                            .chart_fondoManiobra_customizePoint
                                                                                    }
                                                                                >
                                                                                    <Series type="bar">
                                                                                        <ChartLabel
                                                                                            visible={true}
                                                                                            backgroundColor="rgba(48,48,48,0.5)"
                                                                                            font={{
                                                                                                color: "white",
                                                                                                size: "18px",
                                                                                            }}
                                                                                            format={{
                                                                                                type: "largeNumber",
                                                                                                precision: 2,
                                                                                            }}
                                                                                            position="inside"
                                                                                        />
                                                                                    </Series>
                                                                                    <ArgumentAxis inverted={true}>
                                                                                        <ChartLabel
                                                                                            font={{
                                                                                                color: "var(--gray)",
                                                                                                size: "22px",
                                                                                            }}
                                                                                            overlappingBehavior="none"
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <ValueAxis
                                                                                        grid={{ visible: false }}
                                                                                    />
                                                                                    <Legend visible={false} />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        customizeTooltip={
                                                                                            this
                                                                                                .customizeTooltip_Charts_FondoManiobra
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_FM_Ajustado,
                                                                                        false
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                >
                                                                                    <Series
                                                                                        argumentField="trimestre_arg"
                                                                                        type="spline"
                                                                                        valueField="valor"
                                                                                        color="var(--primary)"
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend visible={false} />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto_Trimestral(
                                                    !show_ajustado_EBITDA ? conceptos_EBITDA : conceptos_EBITDA_ajustado
                                                )
                                            }
                                            style={{ flex: "2" }}
                                        >
                                            <CardHeader className="card-header-tab">
                                                {!show_ajustado_EBITDA ? (
                                                    <div className="card-header-title">
                                                        {conceptos_EBITDA.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_EBITDA).length > 0 ||
                                                        this.get_observaciones(conceptos_EBITDA, true).length > 0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_EBITDA"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_EBITDA"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(conceptos_EBITDA)
                                                                            .length > 0
                                                                            ? this.get_observaciones(conceptos_EBITDA)
                                                                            : ""}
                                                                        {this.get_observaciones(conceptos_EBITDA)
                                                                            .length > 0 &&
                                                                        this.get_observaciones(conceptos_EBITDA, true)
                                                                            .length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(conceptos_EBITDA, true)
                                                                            .length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_EBITDA,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="card-header-title">
                                                        {conceptos_EBITDA_ajustado.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_EBITDA_ajustado).length > 0 ||
                                                        this.get_observaciones(conceptos_EBITDA_ajustado, true).length >
                                                            0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_EBITDA_ajustado"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_EBITDA_ajustado"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_EBITDA_ajustado
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_EBITDA_ajustado
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_EBITDA_ajustado
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_EBITDA_ajustado,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                                <div
                                                    className="switch has-switch position-absolute"
                                                    style={{ width: "105px", right: "10px" }}
                                                    onClick={this.toogle_EBITDA}
                                                >
                                                    <div
                                                        className={
                                                            "switch-animate " +
                                                            (!show_ajustado_EBITDA ? "switch-on" : "switch-off")
                                                        }
                                                    >
                                                        <span className="switch-left bg-warning font-size-lg">
                                                            NOMINAL
                                                        </span>
                                                        <label>&nbsp;</label>
                                                        <span className="switch-right bg-warning">REAL AJUST.</span>
                                                    </div>
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="he-100 w-100 d-flex flex-column pt-3 pb-3">
                                                    {trimestreSel !== null ? (
                                                        <div className="fsize-4 align-items-center justify-content-around d-flex pb-3">
                                                            <div className="container_spanCentrado flex-column">
                                                                <div className="widget-title opacity-5 text-uppercase font-size-xxs font-weight-lighter">
                                                                    ANUAL
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "font-size-max " +
                                                                        this.get_Arrow_Concepto_Anual(
                                                                            !show_ajustado_EBITDA
                                                                                ? conceptos_EBITDA
                                                                                : conceptos_EBITDA_ajustado
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="font-size-xxl text-center text-cut pl-2 font-weight-bold">
                                                                {this.get_Valor_Concepto(
                                                                    !show_ajustado_EBITDA
                                                                        ? conceptos_EBITDA
                                                                        : conceptos_EBITDA_ajustado,
                                                                    "number"
                                                                )}
                                                            </div>
                                                            <small className="opacity-5 pl-1">€</small>
                                                        </div>
                                                    ) : null}
                                                    <div className="w-100 flex-fill">
                                                        <Chart
                                                            id="Chart"
                                                            key={trimestreSel !== null}
                                                            dataSource={this.get_DatosCharts_Concepto(
                                                                !show_ajustado_EBITDA
                                                                    ? conceptos_EBITDA
                                                                    : conceptos_EBITDA_ajustado,
                                                                false
                                                            )}
                                                            redrawOnResize={true}
                                                            palette={this.customPalette}
                                                            onLegendClick={this.onLegendClick}
                                                        >
                                                            <CommonSeriesSettings
                                                                argumentField="trimestre"
                                                                type="bar"
                                                                valueField="valor"
                                                                ignoreEmptyPoints={true}
                                                            />
                                                            <SeriesTemplate
                                                                nameField={
                                                                    trimestreSel === null ? "año" : "trimestre_arg"
                                                                }
                                                                customizeSeries={this.customizeSeries_EBITDA}
                                                            />
                                                            <ArgumentAxis allowDecimals={false}>
                                                                <ChartLabel
                                                                    visible={trimestreSel === null}
                                                                    overlappingBehavior="rotate"
                                                                    rotationAngle={-35}
                                                                    customizeText={this.customizeText_EBITDA}
                                                                />
                                                            </ArgumentAxis>
                                                            <Legend visible={true} />
                                                            <ChartTooltip
                                                                enabled={true}
                                                                shared={trimestreSel === null}
                                                                customizeTooltip={this.customizeTooltip_Charts}
                                                            />
                                                        </Chart>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 mb-3 " +
                                                this.get_BorderColor_Concepto_Trimestral(conceptos_DeudaFinanciera)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_DeudaFinanciera.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_DeudaFinanciera).length > 0 ||
                                                    this.get_observaciones(conceptos_DeudaFinanciera, true).length >
                                                        0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_DeudaFinanciera"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_DeudaFinanciera"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_DeudaFinanciera)
                                                                        .length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_DeudaFinanciera
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_DeudaFinanciera)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_DeudaFinanciera,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_DeudaFinanciera,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_DeudaFinanciera,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="he-100 w-100 d-flex flex-column pt-3 pb-3">
                                                    {trimestreSel !== null ? (
                                                        <div className="fsize-4 align-items-center justify-content-around d-flex pb-3">
                                                            <div className="container_spanCentrado flex-column">
                                                                <div className="widget-title opacity-5 text-uppercase font-size-xxs font-weight-lighter">
                                                                    TRIMESTRAL
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "font-size-max " +
                                                                        this.get_Arrow_Concepto_Trimestral(
                                                                            conceptos_DeudaFinanciera
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="font-size-xxl text-center text-cut pl-2 font-weight-bold">
                                                                {this.get_Valor_Concepto(
                                                                    conceptos_DeudaFinanciera,
                                                                    "number"
                                                                )}
                                                            </div>
                                                            <small className="opacity-5 pl-1">€</small>
                                                        </div>
                                                    ) : null}
                                                    <div className="w-100 flex-fill">
                                                        <Chart
                                                            id="Chart"
                                                            key={trimestreSel !== null}
                                                            dataSource={this.get_DatosCharts_Concepto(
                                                                conceptos_DeudaFinanciera,
                                                                false
                                                            )}
                                                            redrawOnResize={true}
                                                        >
                                                            <Series
                                                                argumentField="trimestre_arg"
                                                                type="spline"
                                                                valueField="valor"
                                                                color="var(--primary)"
                                                            />
                                                            <ArgumentAxis>
                                                                <ChartLabel
                                                                    overlappingBehavior="rotate"
                                                                    rotationAngle={-45}
                                                                />
                                                            </ArgumentAxis>
                                                            <Legend visible={false} />
                                                            <ChartTooltip
                                                                enabled={true}
                                                                customizeTooltip={this.customizeTooltip_Charts}
                                                            />
                                                        </Chart>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto_Trimestral(conceptos_RiesgoBancario)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_RiesgoBancario.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_RiesgoBancario).length > 0 ||
                                                    this.get_observaciones(conceptos_RiesgoBancario, true).length >
                                                        0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_RiesgoBancario"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_RiesgoBancario"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_RiesgoBancario)
                                                                        .length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_RiesgoBancario
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_RiesgoBancario)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_RiesgoBancario,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_RiesgoBancario,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_RiesgoBancario,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="he-100 w-100 d-flex flex-column pt-3 pb-3">
                                                    {trimestreSel !== null ? (
                                                        <div className="fsize-4 align-items-center justify-content-around d-flex pb-3">
                                                            <div className="container_spanCentrado flex-column">
                                                                <div className="widget-title opacity-5 text-uppercase font-size-xxs font-weight-lighter">
                                                                    TRIMESTRAL
                                                                </div>
                                                                <div
                                                                    className={
                                                                        "font-size-max " +
                                                                        this.get_Arrow_Concepto_Trimestral(
                                                                            conceptos_RiesgoBancario
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="font-size-xxl text-center text-cut pl-2 font-weight-bold">
                                                                {this.get_Valor_Concepto(
                                                                    conceptos_RiesgoBancario,
                                                                    "number"
                                                                )}
                                                            </div>
                                                            <small className="opacity-5 pl-1">€</small>
                                                        </div>
                                                    ) : null}
                                                    <div className="w-100 flex-fill">
                                                        <Chart
                                                            id="Chart"
                                                            key={trimestreSel !== null}
                                                            dataSource={this.get_DatosCharts_Concepto(
                                                                conceptos_RiesgoBancario,
                                                                false
                                                            )}
                                                            redrawOnResize={true}
                                                        >
                                                            <Series
                                                                argumentField="trimestre_arg"
                                                                type="spline"
                                                                valueField="valor"
                                                                color="var(--primary)"
                                                            />
                                                            <ArgumentAxis>
                                                                <ChartLabel
                                                                    overlappingBehavior="rotate"
                                                                    rotationAngle={-45}
                                                                />
                                                            </ArgumentAxis>
                                                            <Legend visible={false} />
                                                            <ChartTooltip
                                                                enabled={true}
                                                                customizeTooltip={this.customizeTooltip_Charts}
                                                            />
                                                        </Chart>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_Tesoreria)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_Tesoreria.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_Tesoreria).length > 0 ||
                                                    this.get_observaciones(conceptos_Tesoreria, true).length > 0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_Tesoreria"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_Tesoreria"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_Tesoreria)
                                                                        .length > 0
                                                                        ? this.get_observaciones(conceptos_Tesoreria)
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_Tesoreria)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(conceptos_Tesoreria, true)
                                                                        .length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(conceptos_Tesoreria, true)
                                                                        .length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_Tesoreria,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_Tesoreria.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(conceptos_Tesoreria);
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[conceptos_Tesoreria.denominacion]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            conceptos_Tesoreria
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        conceptos_Tesoreria,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Tesoreria
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Tesoreria
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Tesoreria
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Tesoreria
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Tesoreria
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Tesoreria
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Tesoreria
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_Tesoreria
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_Tesoreria,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    conceptos_Tesoreria
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            conceptos_Tesoreria,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    conceptos_Tesoreria
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={this.dxChart_Tesoreria_REF}
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_Tesoreria,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        position="outside"
                                                                                        itemsAlignment="center"
                                                                                        itemTextPosition="left"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="center"
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_LiquidezSolvencia)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_LiquidezSolvencia.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_LiquidezSolvencia).length > 0 ||
                                                    this.get_observaciones(conceptos_LiquidezSolvencia, true).length >
                                                        0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_LiquidezSolvencia"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_LiquidezSolvencia"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_LiquidezSolvencia)
                                                                        .length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_LiquidezSolvencia
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_LiquidezSolvencia)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_LiquidezSolvencia,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_LiquidezSolvencia,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_LiquidezSolvencia,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_LiquidezSolvencia.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_LiquidezSolvencia
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[
                                                                      conceptos_LiquidezSolvencia.denominacion
                                                                  ]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            conceptos_LiquidezSolvencia
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        conceptos_LiquidezSolvencia,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_LiquidezSolvencia
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_LiquidezSolvencia
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_LiquidezSolvencia
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_LiquidezSolvencia
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_LiquidezSolvencia,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            conceptos_LiquidezSolvencia,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    conceptos_LiquidezSolvencia
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={
                                                                                        this
                                                                                            .dxChart_LiquidezSolvencia_REF
                                                                                    }
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_LiquidezSolvencia,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        position="outside"
                                                                                        itemsAlignment="center"
                                                                                        itemTextPosition="left"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="center"
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_SolvenciaTotal)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_SolvenciaTotal.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_SolvenciaTotal).length > 0 ||
                                                    this.get_observaciones(conceptos_SolvenciaTotal, true).length >
                                                        0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_SolvenciaTotal"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_SolvenciaTotal"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_SolvenciaTotal)
                                                                        .length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_SolvenciaTotal
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_SolvenciaTotal)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_SolvenciaTotal,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_SolvenciaTotal,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_SolvenciaTotal,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_SolvenciaTotal.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_SolvenciaTotal
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[
                                                                      conceptos_SolvenciaTotal.denominacion
                                                                  ]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            conceptos_SolvenciaTotal
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        conceptos_SolvenciaTotal,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_SolvenciaTotal
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_SolvenciaTotal
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_SolvenciaTotal
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_SolvenciaTotal
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_SolvenciaTotal,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            conceptos_SolvenciaTotal,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    conceptos_SolvenciaTotal
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={
                                                                                        this.dxChart_SolvenciaTotal_REF
                                                                                    }
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_SolvenciaTotal,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        position="outside"
                                                                                        itemsAlignment="center"
                                                                                        itemTextPosition="left"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="center"
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_Endeudamiento)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_Endeudamiento.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_Endeudamiento).length > 0 ||
                                                    this.get_observaciones(conceptos_Endeudamiento, true).length > 0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_Endeudamiento"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_Endeudamiento"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(conceptos_Endeudamiento)
                                                                        .length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_Endeudamiento
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(conceptos_Endeudamiento)
                                                                        .length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_Endeudamiento,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_Endeudamiento,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_Endeudamiento,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_Endeudamiento.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_Endeudamiento
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[conceptos_Endeudamiento.denominacion]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            conceptos_Endeudamiento
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        conceptos_Endeudamiento,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Endeudamiento
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Endeudamiento
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_Endeudamiento
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Endeudamiento
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Endeudamiento
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Endeudamiento
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_Endeudamiento
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_Endeudamiento
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_Endeudamiento,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    conceptos_Endeudamiento
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            conceptos_Endeudamiento,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    conceptos_Endeudamiento
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={this.dxChart_Endeudamiento_REF}
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_Endeudamiento,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        position="outside"
                                                                                        itemsAlignment="center"
                                                                                        itemTextPosition="left"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="center"
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border d-flex he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_EndeudamientoFinanciero)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                <div className="card-header-title">
                                                    {conceptos_EndeudamientoFinanciero.denominacion.toUpperCase()}
                                                    {this.get_observaciones(conceptos_EndeudamientoFinanciero).length >
                                                        0 ||
                                                    this.get_observaciones(conceptos_EndeudamientoFinanciero, true)
                                                        .length > 0 ? (
                                                        <Fragment>
                                                            <div
                                                                id="tooltip_observaciones_EndeudamientoFinanciero"
                                                                className="icon_Comment pointer pl-2 font-size-lg"
                                                            />
                                                            <Tooltip
                                                                container="#DashboardFinanciero"
                                                                target="#tooltip_observaciones_EndeudamientoFinanciero"
                                                                position="right"
                                                                showEvent="dxhoverstart"
                                                                hideEvent="dxhoverend"
                                                            >
                                                                <div>
                                                                    {this.get_observaciones(
                                                                        conceptos_EndeudamientoFinanciero
                                                                    ).length > 0
                                                                        ? this.get_observaciones(
                                                                              conceptos_EndeudamientoFinanciero
                                                                          )
                                                                        : ""}
                                                                    {this.get_observaciones(
                                                                        conceptos_EndeudamientoFinanciero
                                                                    ).length > 0 &&
                                                                    this.get_observaciones(
                                                                        conceptos_EndeudamientoFinanciero,
                                                                        true
                                                                    ).length > 0 ? (
                                                                        <div className="divider mt-2 mb-2" />
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    {this.get_observaciones(
                                                                        conceptos_EndeudamientoFinanciero,
                                                                        true
                                                                    ).length > 0
                                                                        ? this.enum_trimestres.find(
                                                                              (x) => x.trimestre === trimestreSel
                                                                          ).abr +
                                                                          ": " +
                                                                          this.get_observaciones(
                                                                              conceptos_EndeudamientoFinanciero,
                                                                              true
                                                                          )
                                                                        : ""}
                                                                </div>
                                                            </Tooltip>
                                                        </Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[
                                                                conceptos_EndeudamientoFinanciero.denominacion
                                                            ]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_EndeudamientoFinanciero
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[
                                                                      conceptos_EndeudamientoFinanciero.denominacion
                                                                  ]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            conceptos_EndeudamientoFinanciero
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        conceptos_EndeudamientoFinanciero,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_EndeudamientoFinanciero
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_EndeudamientoFinanciero
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_EndeudamientoFinanciero
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_EndeudamientoFinanciero
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                conceptos_EndeudamientoFinanciero,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            conceptos_EndeudamientoFinanciero,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    conceptos_EndeudamientoFinanciero
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={
                                                                                        this
                                                                                            .dxChart_EndeudamientoFinanciero_REF
                                                                                    }
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        conceptos_EndeudamientoFinanciero,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        position="outside"
                                                                                        itemsAlignment="center"
                                                                                        itemTextPosition="left"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="center"
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_DeudaFinancieraTotal_EBITDA)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                {!show_ajustado_DeudaFinancieraTotal_EBITDA ? (
                                                    <div className="card-header-title">
                                                        {conceptos_DeudaFinancieraTotal_EBITDA.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_DeudaFinancieraTotal_EBITDA)
                                                            .length > 0 ||
                                                        this.get_observaciones(
                                                            conceptos_DeudaFinancieraTotal_EBITDA,
                                                            true
                                                        ).length > 0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_DeudaFinancieraTotal_EBITDA"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_DeudaFinancieraTotal_EBITDA"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_DeudaFinancieraTotal_EBITDA
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_DeudaFinancieraTotal_EBITDA,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="card-header-title">
                                                        {conceptos_DeudaFinancieraTotal_EBITDA_ajustado.denominacion.toUpperCase()}
                                                        {this.get_observaciones(
                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                        ).length > 0 ||
                                                        this.get_observaciones(
                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado,
                                                            true
                                                        ).length > 0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_DeudaFinancieraTotal_EBITDA"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_DeudaFinancieraTotal_EBITDA"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_DeudaFinancieraTotal_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_DeudaFinancieraTotal_EBITDA_ajustado,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[
                                                                conceptos_DeudaFinancieraTotal_EBITDA.denominacion
                                                            ]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_DeudaFinancieraTotal_EBITDA
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <div
                                                        className="switch has-switch position-absolute"
                                                        style={{ width: "105px", right: "10px" }}
                                                        onClick={this.toogle_DeudaFinancieraTotal_EBITDA}
                                                    >
                                                        <div
                                                            className={
                                                                "switch-animate " +
                                                                (!show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                    ? "switch-on"
                                                                    : "switch-off")
                                                            }
                                                        >
                                                            <span className="switch-left bg-warning font-size-lg">
                                                                NOMINAL
                                                            </span>
                                                            <label>&nbsp;</label>
                                                            <span className="switch-right bg-warning">REAL AJUST.</span>
                                                        </div>
                                                    </div>
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[
                                                                      conceptos_DeudaFinancieraTotal_EBITDA.denominacion
                                                                  ]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                : conceptos_DeudaFinancieraTotal_EBITDA
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                            ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                            : conceptos_DeudaFinancieraTotal_EBITDA,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DeudaFinancieraTotal_EBITDA
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DeudaFinancieraTotal_EBITDA
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DeudaFinancieraTotal_EBITDA
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                    ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                    : conceptos_DeudaFinancieraTotal_EBITDA
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                    ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                    : conceptos_DeudaFinancieraTotal_EBITDA,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                        ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                        : conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                : conceptos_DeudaFinancieraTotal_EBITDA,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                                        ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                                        : conceptos_DeudaFinancieraTotal_EBITDA
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={
                                                                                        this
                                                                                            .dxChart_DeudaFinancieraTotal_EBITDA_REF
                                                                                    }
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        show_ajustado_DeudaFinancieraTotal_EBITDA
                                                                                            ? conceptos_DeudaFinancieraTotal_EBITDA_ajustado
                                                                                            : conceptos_DeudaFinancieraTotal_EBITDA,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        orientation="horizontal"
                                                                                        position="outside"
                                                                                        itemsAlignment="left"
                                                                                        itemTextPosition="top"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="left"
                                                                                        margin={{ top: 0 }}
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_DFN_EBITDA)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                {!show_ajustado_DFN_EBITDA ? (
                                                    <div className="card-header-title">
                                                        {conceptos_DFN_EBITDA.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_DFN_EBITDA).length > 0 ||
                                                        this.get_observaciones(conceptos_DFN_EBITDA, true).length >
                                                            0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_DFN_EBITDA"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_DFN_EBITDA"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(conceptos_DFN_EBITDA)
                                                                            .length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_DFN_EBITDA
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(conceptos_DFN_EBITDA)
                                                                            .length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_DFN_EBITDA,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_DFN_EBITDA,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_DFN_EBITDA,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="card-header-title">
                                                        {conceptos_DFN_EBITDA_ajustado.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_DFN_EBITDA_ajustado).length >
                                                            0 ||
                                                        this.get_observaciones(conceptos_DFN_EBITDA_ajustado, true)
                                                            .length > 0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_DFN_EBITDA"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_DFN_EBITDA"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_DFN_EBITDA_ajustado
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_DFN_EBITDA_ajustado
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_DFN_EBITDA_ajustado
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_DFN_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_DFN_EBITDA_ajustado,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_DFN_EBITDA_ajustado,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_DFN_EBITDA.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(conceptos_DFN_EBITDA);
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <div
                                                        className="switch has-switch position-absolute"
                                                        style={{ width: "105px", right: "10px" }}
                                                        onClick={this.toogle_DFN_EBITDA}
                                                    >
                                                        <div
                                                            className={
                                                                "switch-animate " +
                                                                (!show_ajustado_DFN_EBITDA ? "switch-on" : "switch-off")
                                                            }
                                                        >
                                                            <span className="switch-left bg-warning font-size-lg">
                                                                NOMINAL
                                                            </span>
                                                            <label>&nbsp;</label>
                                                            <span className="switch-right bg-warning">REAL AJUST.</span>
                                                        </div>
                                                    </div>
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[conceptos_DFN_EBITDA.denominacion]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            show_ajustado_DFN_EBITDA
                                                                                                ? conceptos_DFN_EBITDA_ajustado
                                                                                                : conceptos_DFN_EBITDA
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        show_ajustado_DFN_EBITDA
                                                                                            ? conceptos_DFN_EBITDA_ajustado
                                                                                            : conceptos_DFN_EBITDA,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DFN_EBITDA
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DFN_EBITDA
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_DFN_EBITDA
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DFN_EBITDA
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DFN_EBITDA
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DFN_EBITDA
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_DFN_EBITDA
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_DFN_EBITDA
                                                                                                    ? conceptos_DFN_EBITDA_ajustado
                                                                                                    : conceptos_DFN_EBITDA
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_DFN_EBITDA
                                                                                                    ? conceptos_DFN_EBITDA_ajustado
                                                                                                    : conceptos_DFN_EBITDA,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    show_ajustado_DFN_EBITDA
                                                                                                        ? conceptos_DFN_EBITDA_ajustado
                                                                                                        : conceptos_DFN_EBITDA
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            show_ajustado_DFN_EBITDA
                                                                                                ? conceptos_DFN_EBITDA_ajustado
                                                                                                : conceptos_DFN_EBITDA,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    show_ajustado_DFN_EBITDA
                                                                                                        ? conceptos_DFN_EBITDA_ajustado
                                                                                                        : conceptos_DFN_EBITDA
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={this.dxChart_DFN_EBITDA_REF}
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        show_ajustado_DFN_EBITDA
                                                                                            ? conceptos_DFN_EBITDA_ajustado
                                                                                            : conceptos_DFN_EBITDA,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        orientation="horizontal"
                                                                                        position="outside"
                                                                                        itemsAlignment="left"
                                                                                        itemTextPosition="top"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="left"
                                                                                        margin={{ top: 0 }}
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col xs="12" md="12" lg="12" xl="6" className="row-1 he-100 mb-3">
                                        <Card
                                            className={
                                                "card-btm-border he-100 " +
                                                this.get_BorderColor_Concepto(conceptos_CoberturaDeuda)
                                            }
                                        >
                                            <CardHeader className="card-header-tab">
                                                {!show_ajustado_CoberturaDeuda ? (
                                                    <div className="card-header-title">
                                                        {conceptos_CoberturaDeuda.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_CoberturaDeuda).length > 0 ||
                                                        this.get_observaciones(conceptos_CoberturaDeuda, true).length >
                                                            0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_CoberturaDeuda"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_CoberturaDeuda"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_CoberturaDeuda
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_CoberturaDeuda,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_CoberturaDeuda,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="card-header-title">
                                                        {conceptos_CoberturaDeuda_ajustado.denominacion.toUpperCase()}
                                                        {this.get_observaciones(conceptos_CoberturaDeuda_ajustado)
                                                            .length > 0 ||
                                                        this.get_observaciones(conceptos_CoberturaDeuda_ajustado, true)
                                                            .length > 0 ? (
                                                            <Fragment>
                                                                <div
                                                                    id="tooltip_observaciones_CoberturaDeuda"
                                                                    className="icon_Comment pointer pl-2 font-size-lg"
                                                                />
                                                                <Tooltip
                                                                    container="#DashboardFinanciero"
                                                                    target="#tooltip_observaciones_CoberturaDeuda"
                                                                    position="right"
                                                                    showEvent="dxhoverstart"
                                                                    hideEvent="dxhoverend"
                                                                >
                                                                    <div>
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda_ajustado
                                                                        ).length > 0
                                                                            ? this.get_observaciones(
                                                                                  conceptos_CoberturaDeuda_ajustado
                                                                              )
                                                                            : ""}
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda_ajustado
                                                                        ).length > 0 &&
                                                                        this.get_observaciones(
                                                                            conceptos_CoberturaDeuda_ajustado,
                                                                            true
                                                                        ).length > 0 ? (
                                                                            <div className="divider mt-2 mb-2" />
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        {this.get_observaciones(
                                                                            conceptos_CoberturaDeuda_ajustado,
                                                                            true
                                                                        ).length > 0
                                                                            ? this.enum_trimestres.find(
                                                                                  (x) => x.trimestre === trimestreSel
                                                                              ).abr +
                                                                              ": " +
                                                                              this.get_observaciones(
                                                                                  conceptos_CoberturaDeuda_ajustado,
                                                                                  true
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </Tooltip>
                                                            </Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                )}
                                                <div className="ml-auto">
                                                    <div
                                                        className={
                                                            "pr-2 font-size-xxl pointer " +
                                                            (comparacionAnual[conceptos_CoberturaDeuda.denominacion]
                                                                ? "icon_Gauge"
                                                                : "icon_Grafica_Incremento")
                                                        }
                                                        onClick={() => {
                                                            this.toogle_ComparacionAnual_Concepto(
                                                                conceptos_CoberturaDeuda
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </CardHeader>
                                            <CardBody className="pt-0 pb-0" style={{ height: "0px" }}>
                                                <div className="widget-content pl-0 pr-0 he-100">
                                                    <div
                                                        className="switch has-switch position-absolute"
                                                        style={{ width: "105px", right: "10px" }}
                                                        onClick={this.toogle_CoberturaDeuda}
                                                    >
                                                        <div
                                                            className={
                                                                "switch-animate " +
                                                                (!show_ajustado_CoberturaDeuda
                                                                    ? "switch-on"
                                                                    : "switch-off")
                                                            }
                                                        >
                                                            <span className="switch-left bg-warning font-size-lg">
                                                                NOMINAL
                                                            </span>
                                                            <label>&nbsp;</label>
                                                            <span className="switch-right bg-warning">REAL AJUST.</span>
                                                        </div>
                                                    </div>
                                                    <MultiView
                                                        height="100%"
                                                        width="100%"
                                                        selectedIndex={
                                                            trimestreSel !== null
                                                                ? comparacionAnual[
                                                                      conceptos_CoberturaDeuda.denominacion
                                                                  ]
                                                                    ? 1
                                                                    : 0
                                                                : 1
                                                        }
                                                        swipeEnabled={false}
                                                    >
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <CircularGauge
                                                                                    id="CircularGauge"
                                                                                    subvalues={[
                                                                                        this.get_Valor_Concepto(
                                                                                            show_ajustado_CoberturaDeuda
                                                                                                ? conceptos_CoberturaDeuda_ajustado
                                                                                                : conceptos_CoberturaDeuda
                                                                                        ),
                                                                                    ]}
                                                                                    value={this.get_Valor_Concepto(
                                                                                        show_ajustado_CoberturaDeuda
                                                                                            ? conceptos_CoberturaDeuda_ajustado
                                                                                            : conceptos_CoberturaDeuda,
                                                                                        null,
                                                                                        añoSel - 1
                                                                                    )}
                                                                                >
                                                                                    <Margin bottom={15} />
                                                                                    <Scale
                                                                                        startValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_CoberturaDeuda
                                                                                            ).minVal
                                                                                        }
                                                                                        endValue={
                                                                                            this.get_GaugeConfig_Concepto(
                                                                                                conceptos_CoberturaDeuda
                                                                                            ).maxVal
                                                                                        }
                                                                                        tickInterval={
                                                                                            ((this.get_GaugeConfig_Concepto(
                                                                                                conceptos_CoberturaDeuda
                                                                                            ).maxVal /
                                                                                                8) *
                                                                                                100) /
                                                                                            100
                                                                                        }
                                                                                    >
                                                                                        <Label
                                                                                            customizeText={
                                                                                                this.customizeText
                                                                                            }
                                                                                        />
                                                                                        <Tick length={6} />
                                                                                    </Scale>
                                                                                    <Geometry
                                                                                        startAngle={180}
                                                                                        endAngle={0}
                                                                                    ></Geometry>
                                                                                    <RangeContainer
                                                                                        width={25}
                                                                                        offset={24}
                                                                                        backgroundColor="var(--danger)"
                                                                                    >
                                                                                        <Range
                                                                                            color="var(--success)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_CoberturaDeuda
                                                                                                ).inicioRango1
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_CoberturaDeuda
                                                                                                ).finRango1
                                                                                            }
                                                                                        />
                                                                                        <Range
                                                                                            color="var(--warning)"
                                                                                            startValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_CoberturaDeuda
                                                                                                ).inicioRango2
                                                                                            }
                                                                                            endValue={
                                                                                                this.get_GaugeConfig_Concepto(
                                                                                                    conceptos_CoberturaDeuda
                                                                                                ).finRango2
                                                                                            }
                                                                                        />
                                                                                    </RangeContainer>
                                                                                    <SubvalueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_CoberturaDeuda
                                                                                                    ? conceptos_CoberturaDeuda_ajustado
                                                                                                    : conceptos_CoberturaDeuda
                                                                                            ) !== "undefined"
                                                                                                ? "#575757"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></SubvalueIndicator>
                                                                                    <ValueIndicator
                                                                                        type="triangleNeedle"
                                                                                        color={
                                                                                            typeof this.get_Valor_Concepto(
                                                                                                show_ajustado_CoberturaDeuda
                                                                                                    ? conceptos_CoberturaDeuda_ajustado
                                                                                                    : conceptos_CoberturaDeuda,
                                                                                                null,
                                                                                                añoSel - 1
                                                                                            ) !== "undefined"
                                                                                                ? "#d3d3d3"
                                                                                                : "transparent"
                                                                                        }
                                                                                        width={8}
                                                                                        offset={15}
                                                                                    ></ValueIndicator>
                                                                                </CircularGauge>
                                                                            </div>
                                                                        </Row>
                                                                        <Row className="pt-2">
                                                                            <div className="fsize-4 mb-0 w-100">
                                                                                <div className="widget-chart-flex align-items-center justify-content-around d-flex flex-row">
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            TRIMESTRAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Trimestral(
                                                                                                    show_ajustado_CoberturaDeuda
                                                                                                        ? conceptos_CoberturaDeuda_ajustado
                                                                                                        : conceptos_CoberturaDeuda
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                    <div className="font-size-max text-center text-cut font-weight-bold">
                                                                                        {this.get_Valor_Concepto(
                                                                                            show_ajustado_CoberturaDeuda
                                                                                                ? conceptos_CoberturaDeuda_ajustado
                                                                                                : conceptos_CoberturaDeuda,
                                                                                            "decimal"
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="container_spanCentrado flex-column flex-fill">
                                                                                        <div className="widget-title opacity-5 text-uppercase font-size-xxs">
                                                                                            ANUAL
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                "font-size-max " +
                                                                                                this.get_Arrow_Concepto_Anual(
                                                                                                    show_ajustado_CoberturaDeuda
                                                                                                        ? conceptos_CoberturaDeuda_ajustado
                                                                                                        : conceptos_CoberturaDeuda
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                        ,
                                                        <MultiViewItem
                                                            render={() => {
                                                                return (
                                                                    <div className="widget-content-outer he-100">
                                                                        <Row className="flex-fill ml-1 mr-3">
                                                                            <div className="widget-content-wrapper w-100">
                                                                                <Chart
                                                                                    id="Chart"
                                                                                    ref={
                                                                                        this.dxChart_CoberturaDeuda_REF
                                                                                    }
                                                                                    dataSource={this.get_DatosCharts_Concepto(
                                                                                        show_ajustado_CoberturaDeuda
                                                                                            ? conceptos_CoberturaDeuda_ajustado
                                                                                            : conceptos_CoberturaDeuda,
                                                                                        true
                                                                                    )}
                                                                                    redrawOnResize={true}
                                                                                    palette={this.customPalette}
                                                                                    onLegendClick={this.onLegendClick}
                                                                                >
                                                                                    <CommonSeriesSettings
                                                                                        argumentField="trimestre_arg"
                                                                                        type="bar"
                                                                                        valueField="valor"
                                                                                    />
                                                                                    <SeriesTemplate
                                                                                        nameField="año"
                                                                                        customizeSeries={
                                                                                            this.customizeSeries
                                                                                        }
                                                                                    />
                                                                                    <ArgumentAxis>
                                                                                        <ChartLabel
                                                                                            overlappingBehavior="rotate"
                                                                                            rotationAngle={-45}
                                                                                        />
                                                                                    </ArgumentAxis>
                                                                                    <Legend
                                                                                        orientation="horizontal"
                                                                                        position="outside"
                                                                                        itemsAlignment="left"
                                                                                        itemTextPosition="top"
                                                                                        verticalAlignment="top"
                                                                                        horizontalAlignment="left"
                                                                                        margin={{ top: 0 }}
                                                                                    />
                                                                                    <ChartTooltip
                                                                                        enabled={true}
                                                                                        shared={true}
                                                                                        customizeTooltip={
                                                                                            this.customizeTooltip_Charts
                                                                                        }
                                                                                    />
                                                                                </Chart>
                                                                            </div>
                                                                        </Row>
                                                                    </div>
                                                                );
                                                            }}
                                                        />
                                                    </MultiView>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </ScrollView>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    //UTILS
    get_Valor_Concepto(concepto, format, año, trim) {
        let { trimestreSel, añoSel, idGrupoEmpresarialSel } = this.state;
        let tblDatosFinancieros = concepto.tblDatosFinancieros.filter(
            (x) => x.año === (año ? año : añoSel) && x.idGrupoEmpresarial === idGrupoEmpresarialSel
        )[0];

        if (tblDatosFinancieros) {
            let tblDatosFinancierosNTrimestre = tblDatosFinancieros.tblDatosFinancierosNTrimestre.filter(
                (x) => x.trimestre === (trim ? trim : trimestreSel)
            )[0];

            if (tblDatosFinancierosNTrimestre) {
                let value = tblDatosFinancierosNTrimestre.valor;
                if (format) {
                    if (format === "number") {
                        return formatNumber(value, 0, "decimal");
                    }
                    if (format === "decimal") {
                        return formatNumber(value, 2, "decimal");
                    } else if (format === "currency") {
                        return formatNumber(value, 2, "currency", "EUR");
                    }
                }
                return value;
            }
        }

        if (format) {
            return "N/A";
        }
        return;
    }

    get_DatosCharts_Concepto = (concepto, comparativa) => {
        let { añoSel, idGrupoEmpresarialSel, trimestreSel } = this.state;

        let datosTrimestres = [];
        let datosAnuales = concepto.tblDatosFinancieros.filter(
            (x) => x.idGrupoEmpresarial === idGrupoEmpresarialSel && x.año <= añoSel
        );

        $.each(datosAnuales, function (index, datoAnual) {
            $.each(datoAnual.tblDatosFinancierosNTrimestre, function (index, datoTrimestre) {
                //En caso de EBITDA y que haya un trismestre seleccionado solo se mostrarán los datos hasta el trimestre seleccionado
                if (
                    (concepto.idConceptoFinanciero === 13 || concepto.idConceptoFinanciero === 15) &&
                    trimestreSel != null &&
                    datoTrimestre.trimestre != trimestreSel
                ) {
                    return true;
                }
                var datoT = $.extend(true, {}, datoTrimestre);
                datoT.trimestre_arg = "T" + datoT.trimestre;

                if (!comparativa) {
                    datoT.trimestre_arg += " (" + datoT.año + ")"; //añoSel != datoT.año ? : "";
                }

                if (trimestreSel !== null && datoAnual.año === añoSel && datoT.trimestre <= trimestreSel) {
                    datosTrimestres.push(datoT);
                } else if (trimestreSel === null || datoAnual.año < añoSel) {
                    datosTrimestres.push(datoT);
                }
            });
        });

        // datosTrimestres.slice(Math.max(datosTrimestres.length - 8, 0));
        return datosTrimestres;
    };

    get_Dif_FondoManiobra(conceptos_FM_Necesario, conceptos_FM_Ajustado, format) {
        let valor_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
        let valor_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);

        if (typeof valor_Necesario !== "undefined" && typeof valor_Ajustado !== "undefined") {
            if (format === "largeNumber") {
                let value = valor_Ajustado - valor_Necesario;
                return (value > 0 ? "+" : "") + formatNumber(value, 2, "largeNumber");
            } else if (format === "percent") {
                let value = valor_Ajustado / valor_Necesario - 1;
                return (value > 0 ? "+" : "") + formatNumber(value, 0, "percent");
            }
        } else {
            return "N/A";
        }
    }

    get_DatosCharts_FondoManiobra(conceptos_FM_Necesario, conceptos_FM_Ajustado) {
        let valor_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
        let valor_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);
        return [
            { arg: "Necesario", val: valor_Necesario },
            { arg: "Real ajust.", val: valor_Ajustado },
        ];
    }

    get_GaugeConfig_Concepto(concepto, isAñoPasado) {
        let { añoSel, idGrupoEmpresarialSel } = this.state;
        if (concepto.idConceptoFinanciero > 2) {
            let tblDatosFinancieros = concepto.tblDatosFinancieros.filter(
                (x) => x.año === (isAñoPasado ? añoSel - 1 : añoSel) && x.idGrupoEmpresarial === idGrupoEmpresarialSel
            )[0];

            if (tblDatosFinancieros) {
                if (tblDatosFinancieros.minVal === 0 && tblDatosFinancieros.maxVal === 0) {
                    tblDatosFinancieros.maxVal = 0.0001;
                }
                return tblDatosFinancieros;
            }
        } else {
            //FONDO MANIOBRA
            let { conceptos } = this.state;
            let conceptos_FM_Necesario = conceptos.filter((x) => x.idConceptoFinanciero === 1)[0];
            let conceptos_FM_Ajustado = conceptos.filter((x) => x.idConceptoFinanciero === 2)[0];

            let necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
            let ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);

            let minVal = necesario >= 0 && ajustado >= 0 ? 0 : necesario > ajustado ? ajustado : necesario;
            let maxVal = necesario < 0 && ajustado < 0 ? 0 : necesario > ajustado ? necesario : ajustado;
            let inicioRango1 = 0;
            let finRango1 = 0;

            let isNecesario = concepto.idConceptoFinanciero === conceptos_FM_Necesario.idConceptoFinanciero;

            if (isNecesario && necesario > 0) {
                finRango1 = necesario;
            } else if (!isNecesario && ajustado > 0) {
                finRango1 = ajustado;
            } else {
                inicioRango1 = isNecesario ? necesario : ajustado;
                finRango1 = 0;
            }

            return {
                minVal: minVal,
                maxVal: maxVal,
                inicioRango1,
                finRango1,
            };
        }
        return {
            minVal: 0,
            maxVal: 0.0001,
            inicioRango1: 0,
            finRango1: 0,
            inicioRango2: 0,
            finRango2: 0,
        };
    }

    get_observaciones(concepto, isTrimestre) {
        let { trimestreSel, añoSel, idGrupoEmpresarialSel } = this.state;
        let observaciones = "";
        let tblDatosFinancieros = concepto.tblDatosFinancieros.filter(
            (x) => x.año === añoSel && x.idGrupoEmpresarial === idGrupoEmpresarialSel
        )[0];

        if (tblDatosFinancieros) {
            //OBSERVACIONES DEL TRIMESTRE SELECCIONADO
            if (isTrimestre) {
                let tblDatosFinancierosNTrimestre = tblDatosFinancieros.tblDatosFinancierosNTrimestre.filter(
                    (x) => x.trimestre === trimestreSel
                )[0];

                if (tblDatosFinancierosNTrimestre) {
                    observaciones = tblDatosFinancierosNTrimestre.observaciones
                        ? tblDatosFinancierosNTrimestre.observaciones
                        : "";
                }
            } else {
                observaciones = tblDatosFinancieros.observaciones ? tblDatosFinancieros.observaciones : "";
            }
        }

        return observaciones;
    }

    get_BorderColor_Concepto(concepto) {
        let { añoSel } = this.state;
        let valorActual = this.get_Valor_Concepto(concepto);
        let valorAnterior = this.get_Valor_Concepto(concepto, null, añoSel - 1);

        if (typeof valorActual !== "undefined" && typeof valorAnterior !== "undefined") {
            //ENDEUDAMIENTO GLOBAL
            var conceptos_flexInversa = [3, 4, 8, 9, 10, 14];
            if (conceptos_flexInversa.includes(concepto.idConceptoFinanciero)) {
                return valorActual === valorAnterior
                    ? "border-primary"
                    : valorActual < valorAnterior
                    ? "border-success"
                    : "border-danger";
            }

            return valorActual === valorAnterior
                ? "border-primary"
                : valorActual > valorAnterior
                ? "border-success"
                : "border-danger";
        } else {
            return "border-primary";
        }
    }

    get_BorderColor_Concepto_Trimestral(concepto) {
        let { añoSel, trimestreSel } = this.state;
        let año = añoSel;
        let trim = trimestreSel;

        if (trim === 1) {
            //Comparación del primer trimestre con el último del año pasado
            año -= 1;
            trim = 4;
        } else {
            trim -= 1;
        }

        let valorActual = this.get_Valor_Concepto(concepto);
        let valorAnterior = this.get_Valor_Concepto(concepto, null, año, trim);

        if (typeof valorActual !== "undefined" && typeof valorAnterior !== "undefined") {
            //ENDEUDAMIENTO GLOBAL
            var conceptos_flexInversa = [3, 4, 8, 9, 10, 14];
            if (conceptos_flexInversa.includes(concepto.idConceptoFinanciero)) {
                return valorActual === valorAnterior
                    ? "border-primary"
                    : valorActual < valorAnterior
                    ? "border-success"
                    : "border-danger";
            }

            return valorActual === valorAnterior
                ? "border-primary"
                : valorActual > valorAnterior
                ? "border-success"
                : "border-danger";
        } else {
            return "border-primary";
        }
    }

    get_BorderColor_FondoManiobra(conceptos_FM_Necesario, conceptos_FM_Ajustado) {
        let { añoSel } = this.state;

        let valorActual_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);
        let valorActual_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
        let valorAnterior_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado, null, añoSel - 1);
        let valorAnterior_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario, null, añoSel - 1);

        if (
            typeof valorActual_Ajustado !== "undefined" &&
            typeof valorActual_Necesario !== "undefined" &&
            typeof valorAnterior_Ajustado !== "undefined" &&
            typeof valorAnterior_Necesario !== "undefined"
        ) {
            let porcentaje_Actual = valorActual_Ajustado - valorActual_Necesario;
            let porcentaje_Anterior = valorAnterior_Ajustado - valorAnterior_Necesario;
            return porcentaje_Actual === porcentaje_Anterior
                ? "border-primary"
                : porcentaje_Actual > porcentaje_Anterior
                ? "border-success"
                : "border-danger";
        } else {
            return "border-primary";
        }
    }

    get_Arrow_Concepto_Trimestral(concepto) {
        let { añoSel, trimestreSel } = this.state;
        let año = añoSel;
        let trim = trimestreSel;

        if (trim === 1) {
            //Comparación del primer trimestre con el último del año pasado
            año -= 1;
            trim = 4;
        } else {
            trim -= 1;
        }

        let valorActual = this.get_Valor_Concepto(concepto);
        let valorAnterior = this.get_Valor_Concepto(concepto, null, año, trim);

        if (typeof valorActual !== "undefined" && typeof valorAnterior !== "undefined") {
            var conceptos_flexInversa = [3, 4, 8, 9, 10, 14, 19, 20];
            if (conceptos_flexInversa.includes(concepto.idConceptoFinanciero)) {
                return valorActual === valorAnterior
                    ? ""
                    : valorActual > valorAnterior
                    ? "icon_ArrowUp danger"
                    : "icon_ArrowDown success";
            }

            return valorActual === valorAnterior ? "" : valorActual > valorAnterior ? "icon_ArrowUp" : "icon_ArrowDown";
        } else {
            return "";
        }
    }

    get_Arrow_Concepto_Anual(concepto) {
        let { añoSel } = this.state;
        let valorActual = this.get_Valor_Concepto(concepto);
        let valorAnterior = this.get_Valor_Concepto(concepto, null, añoSel - 1);

        if (typeof valorActual !== "undefined" && typeof valorAnterior !== "undefined") {
            var conceptos_flexInversa = [3, 4, 8, 9, 10, 14, 19, 20];
            if (conceptos_flexInversa.includes(concepto.idConceptoFinanciero)) {
                return valorActual === valorAnterior
                    ? ""
                    : valorActual > valorAnterior
                    ? "icon_ArrowUp danger"
                    : "icon_ArrowDown success";
            }

            return valorActual === valorAnterior ? "" : valorActual > valorAnterior ? "icon_ArrowUp" : "icon_ArrowDown";
        } else {
            return "";
        }
    }

    get_Arrow_FondoManiobra_Trimestral(conceptos_FM_Necesario, conceptos_FM_Ajustado) {
        let { añoSel, trimestreSel } = this.state;
        let año = añoSel;
        let trim = trimestreSel;

        if (trim === 1) {
            //Comparación del primer trimestre con el último del año pasado
            año -= 1;
            trim = 4;
        } else {
            trim -= 1;
        }

        let valorActual_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);
        let valorActual_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
        let valorAnterior_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado, null, año, trim);
        let valorAnterior_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario, null, año, trim);

        if (
            typeof valorActual_Ajustado !== "undefined" &&
            typeof valorActual_Necesario !== "undefined" &&
            typeof valorAnterior_Ajustado !== "undefined" &&
            typeof valorAnterior_Necesario !== "undefined"
        ) {
            let porcentaje_Actual = valorActual_Ajustado - valorActual_Necesario;
            let porcentaje_Anterior = valorAnterior_Ajustado - valorAnterior_Necesario;

            return porcentaje_Actual === porcentaje_Anterior
                ? ""
                : porcentaje_Actual > porcentaje_Anterior
                ? "icon_ArrowUp"
                : "icon_ArrowDown";
        } else {
            return "";
        }
    }

    get_Arrow_FondoManiobra_Anual(conceptos_FM_Necesario, conceptos_FM_Ajustado) {
        let { añoSel } = this.state;
        let valorActual_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado);
        let valorActual_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario);
        let valorAnterior_Ajustado = this.get_Valor_Concepto(conceptos_FM_Ajustado, null, añoSel - 1);
        let valorAnterior_Necesario = this.get_Valor_Concepto(conceptos_FM_Necesario, null, añoSel - 1);

        if (
            typeof valorActual_Ajustado !== "undefined" &&
            typeof valorActual_Necesario !== "undefined" &&
            typeof valorAnterior_Ajustado !== "undefined" &&
            typeof valorAnterior_Necesario !== "undefined"
        ) {
            let porcentaje_Actual = valorActual_Ajustado - valorActual_Necesario;
            let porcentaje_Anterior = valorAnterior_Ajustado - valorAnterior_Necesario;

            return porcentaje_Actual === porcentaje_Anterior
                ? ""
                : porcentaje_Actual > porcentaje_Anterior
                ? "icon_ArrowUp"
                : "icon_ArrowDown";
        } else {
            return "";
        }
    }

    toogle_ComparacionAnual_Concepto(concepto) {
        let { comparacionAnual } = this.state;
        comparacionAnual[concepto.denominacion] = !comparacionAnual[concepto.denominacion];
        this.setState({
            comparacionAnual: comparacionAnual,
        });
    }

    toogle_DeudaFinancieraTotal_EBITDA() {
        this.setState(function (prevState) {
            return { show_ajustado_DeudaFinancieraTotal_EBITDA: !prevState.show_ajustado_DeudaFinancieraTotal_EBITDA };
        });
    }

    toogle_DFN_EBITDA() {
        this.setState(function (prevState) {
            return { show_ajustado_DFN_EBITDA: !prevState.show_ajustado_DFN_EBITDA };
        });
    }

    toogle_CoberturaDeuda() {
        this.setState(function (prevState) {
            return { show_ajustado_CoberturaDeuda: !prevState.show_ajustado_CoberturaDeuda };
        });
    }

    toogle_EBITDA() {
        this.setState(function (prevState) {
            return { show_ajustado_EBITDA: !prevState.show_ajustado_EBITDA };
        });
    }

    //CHART
    customizeSeries = (valueFromNameField) => {
        const { añoSel } = this.state;
        const añosVisibles = 3;

        return valueFromNameField <= añoSel - añosVisibles ? { visible: false } : {};
    };

    customizeSeries_EBITDA = (valueFromNameField) => {
        const { añoSel, trimestreSel } = this.state;
        const añosVisibles = 2;

        if (trimestreSel != null) {
            let trimestre_serie = valueFromNameField.split(" ")[0].replace("T", "");
            let año_serie = valueFromNameField.match(/\(([^)]+)\)/)[1];
            return año_serie <= añoSel - añosVisibles || trimestreSel != trimestre_serie ? { visible: false } : {};
        } else {
            return valueFromNameField <= añoSel - añosVisibles ? { visible: false } : {};
        }
    };

    customizeText_EBITDA = ({ valueText }) => {
        return "T" + valueText;
    };

    customizeTooltip_Charts(info) {
        let html = $("<div />");

        let headerText = info.argumentText;
        if (info.argumentText.length == 1) {
            headerText = info.seriesName;
        }
        html.append($("<div />").addClass("tooltip-header").text(headerText));

        let body = $("<div />").addClass("tooltip-body");
        html.append(body);

        if (info.points) {
            $.each(info.points, function (index, point) {
                let row = $("<div />").addClass("tooltip-row");
                body.append(row);
                row.append($("<div />").addClass("series-name").text(point.seriesName));
                row.append($("<div />").addClass("value-text").text(formatNumber(point.valueText, 2, "decimal")));
            });
        } else {
            let row = $("<div />").addClass("tooltip-row");
            body.append(row);
            row.append(
                $("<div />").addClass("text-center font-size-xs").text(formatNumber(info.value, 2, "currency", "EUR"))
            );
        }

        return {
            html: html.html(),
        };
    }

    onLegendClick(e) {
        var series = e.target;
        if (series.isVisible()) {
            series.hide();
        } else {
            series.show();
        }
    }

    customizeTooltip_Charts_FondoManiobra(info) {
        let html = $("<div />");
        html.append(
            $("<div />")
                .addClass("d-flex justify-content-between")
                .css("width", "250px")
                .append(
                    $("<div />")
                        .addClass("tooltip-title font-size-md")
                        .css("color", "var(--secondary)")
                        .text(info.argumentText)
                )
                .append(
                    $("<div />")
                        .addClass("tooltip_value-text font-size-md")
                        .css("color", "var(--gray)")
                        .text(formatNumber(info.value, 2, "currency", "EUR"))
                )
        );

        return {
            html: html.html(),
        };
    }

    chart_fondoManiobra_customizePoint(pointInfo) {
        return {
            color: pointInfo.value === 0 ? "var(--primary)" : pointInfo.value > 0 ? "var(--success)" : "var(--danger)",
        };
    }

    //GAUGES
    customizeText({ value }) {
        return formatNumber(value, 2, "decimal");
    }

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        this.dataSource_tblConceptosFinancieros.load();
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardFinanciero);
