import React from "react";

import { SVContext } from "./SmartViewContext";
import { Tooltip, Popover } from "devextreme-react";

class ResumenOperarios extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            operariosTotal,
            operariosActuales,
            operariosInactivos,
            operariosActivosProduccion,
            kgProduccion,
            produccionKgHoraActual,
            hora,
            enableFullScreen,
            mainScreen = true,
        } = this.props;
        const { fechaLavanderia } = this.context;
        return (
            <div
                className={`d-flex flex-row align-items-center${
                    !mainScreen && enableFullScreen === true ? "" : " mr-5"
                }`}
            >
                <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="font-weight-bold">Total operarios: </div>
                    <div className="d-flex flex-row mx-2 headerTile">
                        <span className="px-2">{operariosTotal}</span>
                    </div>
                </div>
                <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="font-weight-bold">Operarios activos: </div>
                    <div className="d-flex flex-row mx-2 headerTile">
                        <span className="px-2">{operariosActuales}</span>
                    </div>
                </div>
                {operariosInactivos?.length > 0 && (
                    <div className="d-flex flex-row flex-wrap align-items-center">
                        <div className="font-weight-bold text-danger">Operarios inactivos: </div>
                        <div className="d-flex flex-row mx-2 headerTile">
                            <span id="operariosInactivosAlert" className="px-2 text-danger border-alert pointer">
                                {operariosInactivos?.length}
                            </span>
                        </div>
                        <Popover
                            target={"#operariosInactivosAlert"}
                            showEvent={"dxhoverstart"}
                            hideEvent={"dxhoverend"}
                        >
                            <div className="d-flex flex-column">
                                {operariosInactivos.map((x) => {
                                    let jornadaInicio = new Date(x.jornadaIni);
                                    let horasDiff = (fechaLavanderia - jornadaInicio) / 1000 / 60 / 60;

                                    return (
                                        <span
                                            className={horasDiff > 14 ? "text-danger" : ""}
                                            key={"opInac-" + x.nombre}
                                        >
                                            {x.nombre}
                                        </span>
                                    );
                                })}
                            </div>
                        </Popover>
                    </div>
                )}
                <div className="d-flex flex-row flex-wrap align-items-center">
                    <div className="font-weight-bold">Kg. Operario: </div>
                    <div className="d-flex flex-row mx-2 headerTile" id="PAOH">
                        <span className="px-2">{hora} h.</span>
                        <span className="px-2">{produccionKgHoraActual}</span>
                    </div>
                    <div>Kg/h</div>
                </div>
                <Tooltip target={"#PAOH"} showEvent={"dxhoverstart"} hideEvent={"dxhoverend"}>
                    <div className="d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between">
                            <div className="color-secondary pr-5">Operarios Producción</div>
                            <div className="font-weight-bold">{operariosActivosProduccion}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="color-secondary pr-5">Producción</div>
                            <div className="font-weight-bold">{kgProduccion} Kg</div>
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    }
}
ResumenOperarios.contextType = SVContext;

export default ResumenOperarios;
