import { getArticulos, getDefaultIvaId, getDefaultMonedaId } from "pages/Administracion/shared/functions";
import { empresaPolarierSel } from "pages/Administracion/shared/model";

export const getClearPresupuesto = () => {
    return {
        idAdmPresupuestoVenta: null,
        codigo: null,
        idTipoPresupuesto: null,
        idAdmCliente: null,
        fechaCreacion: null,
        idMoneda: getDefaultMonedaId(),
        idAdmTipoCambio: 1,
        tasaCambio: 1,
        idIvaNPais: getDefaultIvaId(false),
        // idAdmPedido_Estado: null,
        idAdmFormaPago: null,
        descuento: 0,
        idAdmTipoDescuento: 1,
        observaciones: null,
        idAdmPresupuestoVenta_Estado: 1,
        articulosDraft: [],
        tblArticuloNAdmPresupuestoVenta: [
            // {
            //   idArticulo: 1,
            //   codigoArticulo: 'ART00001',
            //   descripcion: 'Artículo 1',
            //   descuento: 0.3,
            //   cantidad: 1,
            //   precio: 1,
            // }
        ],
    };
};

export const payloadBuilder = (data, queryReady = true) => {
    if (queryReady) {
        let payload = {
            codigo: data.codigo,
            idTipoPresupuesto: data.idTipoPresupuesto,
            idAdmCliente: data.idAdmCliente,
            fechaCreacion: data.fechaCreacion,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            idAdmFormaPago: data.idAdmFormaPago,
            descuento: data.descuento,
            idIvaNPais: data.idIvaNPais,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            observaciones: data.observaciones,
            idAdmPresupuestoVenta_Estado: data.idAdmPresupuestoVenta_Estado,
            idEmpresaPolarier: empresaPolarierSel.idEmpresaPolarier,
            tblArticuloNAdmPresupuestoVenta: getArticulos(data.tblArticuloNAdmPresupuestoVenta, queryReady),
        };
        if (data.idAdmPresupuestoVenta != null) {
            payload.idAdmPresupuestoVenta = data.idAdmPresupuestoVenta;
        }
        return payload;
    } else {
        return {
            idAdmPresupuestoVenta: data.idAdmPresupuestoVenta,
            codigo: data.codigo,
            idTipoPresupuesto: data.idTipoPresupuesto,
            idAdmCliente: data.idAdmCliente,
            fechaCreacion: data.fechaCreacion,
            idMoneda: data.idMoneda,
            idAdmTipoCambio: data.idAdmTipoCambio,
            tasaCambio: data.tasaCambio,
            idAdmFormaPago: data.idAdmFormaPago,
            descuento: data.descuento,
            idIvaNPais: data.idIvaNPais,
            idAdmTipoDescuento: data.idAdmTipoDescuento,
            observaciones: data.observaciones,
            idAdmPresupuestoVenta_Estado: data.idAdmPresupuestoVenta_Estado,
            tblArticuloNAdmPresupuestoVenta: getArticulos(data.tblArticuloNAdmPresupuestoVenta),
        };
    }
};
