const UbicacionColumn = (isEditable, getTrad) => ({
  dataField: "ubicacion",
  caption: getTrad("ubicacion"),
  alignment: "left",
  width: 110,
  cssClass: isEditable ? "dx-Cell_Editable" : "",
  allowEditing: isEditable,
});

export default UbicacionColumn;
