import { connectionConstants } from "../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../helpers";
import { svg_edit, svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_vehiculo;

  //#region Datasource

  //Datasource vehiculos
  datasource_vehiculo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblVehiculo",
      key: "idVehiculo",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: [
      "capacidadBacs",
      "denominacion",
      "eliminado",
      "idTipoVehiculo",
      "idVehiculo",
      "matricula",
    ],
    expand: ["tblVehiculoNLavanderia($select=idLavanderia)"],
  });

  var datasource_tblLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblLavanderia",
      key: "idLavanderia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idLavanderia", "denominacion", "idPais"],
    filter: ["idPais", "=", LAVANDERIA.idPais],
    sort: ["denominacion"],
  });

  var enum_tipoVehiculo = [
    {
      idTipoVehiculo: 1,
      img: "icon_Vehiculos4",
    },
    {
      idTipoVehiculo: 2,
      img: "icon_Vehiculos3",
    },
    {
      idTipoVehiculo: 3,
      img: "icon_Vehiculos2",
    },
    {
      idTipoVehiculo: 4,
      img: "icon_Vehiculos1",
    },
  ];

  //#endregion

  var lavanderias = [];

  //#region Controles

  $("#Vehiculos #dxContainer").dxBox({
    height: "100%",
    direction: "col",
  });

  //Grid Vehiculos
  $("#Vehiculos #dgvVehiculos").dxDataGrid({
    //Datos
    dataSource: datasource_vehiculo,
    remoteOperations: true,
    //Configuración
    columnAutoWidth: true,
    height: "100%",
    filterRow: {
      visible: true,
      applyFilter: "auto",
    },
    searchPanel: {
      visible: true,
      width: 240,
      placeholder: getTrad("busqueda"),
    },
    paging: { pageSize: 50 },
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [20, 50, 100],
      showInfo: true,
    },
    sorting: { mode: "multiple" },
    editing: {
      mode: "popup",
      allowUpdating: false,
      allowAdding: true,
      popup: {
        title: getTrad("infoVehiculo"),
        showTitle: true,
        position: { at: "center", of: window },
        height: "80%",
        width: "60%",
      },
      form: {
        labelLocation: "top",
        height: "100%",
        colCount: 1,
        itemType: "group",
        colCountByScreen: {
          xl: 3,
          lg: 3,
          md: 3,
          sm: 3,
          xs: 1,
        },
        screenByWidth: function (width) {
          return width >= 1200
            ? "xl"
            : width >= 992
              ? "lg"
              : width >= 768
                ? "md"
                : width >= 576
                  ? "sm"
                  : "xs";
        },
        items: [
          {
            dataField: "denominacion",
          },
          {
            dataField: "matricula",
          },
          {
            dataField: "capacidadBacs",
          },
          {
            dataField: "idTipoVehiculo",
            colSpan: 3,
          },
          {
            dataField: "tblVehiculoNLavanderia",
            colSpan: 3,
          },
        ],
      },
    },
    columns: [
      {
        caption: " ",
        width: "40px",
        alignment: "center",
        cssClass: "p-0",
        cellTemplate: function (cellElement, cellInfo) {
          var contenedor = $("<div/>").addClass("container_spanCentrado");
          svg_edit
            .clone()
            .css("height", "25px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(contenedor);

          cellElement.on("click", function () {
            cellInfo.component.editRow(cellInfo.rowIndex);
          });

          contenedor.appendTo(cellElement);
        },
        allowSearch: false,
      },
      {
        dataField: "denominacion",
        caption: getTrad("denominacion"),
        minWidth: 100,
        allowHeaderFiltering: false,
        validationRules: [
          {
            type: "required",
          },
          {
            type: "pattern",
            pattern: "^[_A-zÀ-ú0-9 ]*((-|s)*[_A-zÀ-ú0-9 ])*$",
            message: "validation_NoCaracteresEspeciales",
          },
        ],
      },
      {
        dataField: "matricula",
        caption: getTrad("matricula"),
        minWidth: 50,
        sortOrder: "asc",
        sortIndex: 1,
        allowFiltering: true,
        validationRules: [
          {
            type: "required",
          },
          {
            type: "pattern",
            pattern: "^[_A-zÀ-ú0-9 ]*((-|s)*[_A-zÀ-ú0-9 ])*$",
            message: "validation_NoCaracteresEspeciales",
          },
        ],
      },
      {
        dataField: "capacidadBacs",
        caption: getTrad("capacidadBacs"),
        minWidth: 50,
        alignment: "center",
        allowHeaderFiltering: false,
        allowFiltering: false,
        allowSearch: true,
        format: { style: "decimal", maximumFractionDigits: 0 },
        editorOptions: {
          step: 0,
          format: { style: "decimal", maximumFractionDigits: 0 },
          min: 0,
          max: 9999,
        },
        validationRules: [
          {
            type: "required",
          },
        ],
      },
      {
        dataField: "tblVehiculoNLavanderia",
        caption: getTrad("lavanderia"),
        width: 900,
        alignment: "center",
        allowHeaderFiltering: false,
        allowFiltering: false,
        allowSearch: false,
        cellTemplate: function (container, options) {
          if (lavanderias.length > 0) {
            const ids_lavanderia = options.row.data.tblVehiculoNLavanderia.map(
              (lavanderia) => lavanderia.idLavanderia,
            );
            const lavanderias_ = lavanderias.filter((x) =>
              ids_lavanderia.includes(x.idLavanderia),
            );
            const nombres = lavanderias_
              .slice(0, 6)
              .map((lav) => ` ${lav.denominacion}`);
            if (lavanderias_.length > 6) {
              nombres.push(" ...");
            }
            $("<div>").text(nombres.join()).appendTo(container);
          } else {
            datasource_tblLavanderia.load().done(function (data) {
              lavanderias = [...data];
              const ids_lavanderia =
                options.row.data.tblVehiculoNLavanderia.map(
                  (lavanderia) => lavanderia.idLavanderia,
                );
              const lavanderias_ = lavanderias.filter((x) =>
                ids_lavanderia.includes(x.idLavanderia),
              );
              const nombres = lavanderias_
                .slice(0, 6)
                .map((lav) => ` ${lav.denominacion}`);
              if (lavanderias_.length > 6) {
                nombres.push(" ...");
              }
              $("<div>").text(nombres.join()).appendTo(container);
            });
          }
        },
        editCellTemplate: function (container, options) {
          $("<div id='Lavanderia'/>")
            .dxDataGrid({
              dataSource: datasource_tblLavanderia,
              accessKey: "idLavanderia",
              columnsAutoWidth: true,
              remoteOperations: false,
              height: "350px",
              paging: { enabled: false },
              headerFilter: { visible: false },
              searchPanel: { visible: false },
              filterRow: { visible: false },
              editing: { mode: "cell", allowUpdating: true },
              selection: { mode: "multiple", showCheckBoxesMode: "always" },
              columns: [
                {
                  dataField: "denominacion",
                  caption: getTrad("lavanderia"),
                  minWidth: 250,
                  allowEditing: false,
                },
              ],
              selectedRowKeys:
                options.row.data.tblVehiculoNLavanderia === undefined
                  ? [LAVANDERIA.idLavanderia]
                  : options.row.data.tblVehiculoNLavanderia
                        .map((lavanderia) => lavanderia.idLavanderia)
                        .includes(LAVANDERIA.idLavanderia)
                    ? options.row.data.tblVehiculoNLavanderia.map(
                        (lavanderia) => lavanderia.idLavanderia,
                      )
                    : [
                        LAVANDERIA.idLavanderia,
                        ...options.row.data.tblVehiculoNLavanderia.map(
                          (lavanderia) => lavanderia.idLavanderia,
                        ),
                      ],
              onSelectionChanged: function (e) {
                const selectedRowKeys_formatted = e.selectedRowKeys.map(
                  (idLavanderia) => ({ idLavanderia }),
                );
                let lavanderiaSel = [{ idLavanderia: LAVANDERIA.idLavanderia }];
                if (!e.selectedRowKeys.includes(LAVANDERIA.idLavanderia)) {
                  $("#Lavanderia")
                    .dxDataGrid("instance")
                    .selectRows(
                      [...e.selectedRowKeys, LAVANDERIA.idLavanderia],
                      false,
                    );
                } else {
                  lavanderiaSel = selectedRowKeys_formatted;
                }
                if (options.component.getController("editing").isEditing()) {
                  options.setValue(lavanderiaSel);
                }
              },
              showColumnLines: false,
              showRowLines: true,
              rowAlternationEnabled: true,
            })
            .appendTo(container);
          return container;
        },
      },
      {
        dataField: "idTipoVehiculo",
        caption: "Tipo vehículo",
        minWidth: 50,
        alignment: "center",
        allowHeaderFiltering: false,
        allowFiltering: false,
        cssClass: "p-0",
        validationRules: [
          {
            type: "required",
            message: "Seleccione un tipo de vehiculo.",
          },
        ],
        cellTemplate: function (container, options) {
          var tipoVehiculo = $.grep(
            enum_tipoVehiculo,
            function (element, index) {
              return element.idTipoVehiculo === options.data.idTipoVehiculo;
            },
          );

          $("<div/>")
            .attr("class", tipoVehiculo[0].img)
            .css("font-size", "50px")
            .appendTo(container);
        },

        editCellTemplate: function (container, options) {
          if (typeof options.data.idTipoVehiculo === "undefined") {
            options.data.idTipoVehiculo = null;
          }

          $("<div/>")
            .dxRadioGroup({
              valueExpr: "idTipoVehiculo",
              layout: "horizontal",
              value: options.data.idTipoVehiculo,
              items: enum_tipoVehiculo,
              itemTemplate: function (itemData, itemIndex, itemElement) {
                var img = itemData.img;
                itemElement.addClass("container_spanCentrado");
                $("<div/>")
                  .attr("class", img)
                  .css("font-size", "75px")
                  .appendTo(itemElement);
              },
              onValueChanged: function (e) {
                if (options.component.getController("editing").isEditing()) {
                  options.setValue(e.value);
                }
              },
            })
            .appendTo(container);
          return container;
        },
      },
      {
        caption: " ",
        width: "35px",
        alignment: "left",
        cssClass: "p-0",
        cellTemplate: function (cellElement, cellInfo) {
          var contenedor = $("<div/>").addClass("container_spanCentrado");
          svg_remove
            .clone()

            .css("height", "25px")
            .css("width", "25px")
            .css("cursor", "pointer")
            .appendTo(contenedor);

          cellElement.on("click", function () {
            cellInfo.component.deleteRow(cellInfo.rowIndex);
          });

          contenedor.appendTo(cellElement);
        },
      },
    ],
    //Eventos
    onRowRemoved: function (e) {
      notify({
        message: getTrad("aviso_C_RegistroEliminado"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    },
    onToolbarPreparing: function (e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          locateInMenu: "auto",
          options: {
            text: getTrad("limpiarFiltro"),
            icon: " icon_EliminarFiltros",
            onClick: function () {
              e.component.clearFilter();
              notify({
                message: getTrad("aviso_C_FiltroRestaurado"),
                type: "success",
                displayTime: "1500",
                closeOnClick: true,
              });
            },
          },
        },
        {
          location: "after",
          widget: "dxButton",
          showText: "inMenu",
          options: {
            icon: "refresh",
            text: getTrad("actualizar"),
            onClick: function () {
              e.component.refresh();
            },
          },
        },
      );
      $.each(e.toolbarOptions.items, function (index, item) {
        switch (item.name) {
          case "addRowButton":
            item.location = "before";
            item.showText = "always";
            item.options.text = getTrad("añadir");
            item.options.icon = "plus";
            break;
          default:
            break;
        }
      });
    },

    //Estilos
    showColumnLines: false,
    showRowLines: true,
    rowAlternationEnabled: true,
  });
  //#endregion

  deferred.resolve();
  return deferred.promise();
}
