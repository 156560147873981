import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import { getNombreFormulario, errorHandler, authHeader, getTrad, formatNumber } from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { Tooltip } from "devextreme-react/tooltip";
import { MultiView, Item as MultiViewItem } from "devextreme-react/multi-view";
import DataGrid, { Column, Paging, FilterRow, Summary, TotalItem, ColumnFixing } from "devextreme-react/data-grid";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

import Chart, {
    Legend,
    Series,
    SeriesTemplate,
    CommonSeriesSettings,
    Margin,
    Tooltip as ChartTooltip,
    Point,
} from "devextreme-react/chart";
import PieChart, {
    Series as PieSeries,
    Legend as PieLegend,
    Label as PieLabel,
    Connector,
    SmallValuesGrouping as PieSmallValuesGrouping,
} from "devextreme-react/pie-chart";
import RadioGroup from "devextreme-react/radio-group";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

class ControlPresupuestario extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            datos: [],
            fechaSel: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1, 0, 0, 0),
            centroSel: {},
            empresaPolarierSel: {},
            dxMultiView_index: 0,
            mostrarCosteKg: true,
            divisa: {},
            divisaSel: "EUR",
        };

        //REFERENCIAS
        this.dxDataGrid_centros_REF = React.createRef();
        this.dxChart_BAI_REF = React.createRef();
        this.dxPieChart_Costes_mensuales_REF = React.createRef();
        this.dxChart_Costes_mensuales_REF = React.createRef();

        //DATASOURCE
        this.getDatos = this.getDatos.bind(this);
        //this.dataSource_spSelectControlPresupuestario_KgLavanderia_beforeSend = this.dataSource_spSelectControlPresupuestario_KgLavanderia_beforeSend.bind(this);
        this.dataSource_tblEmpresasPolarier_onLoaded = this.dataSource_tblEmpresasPolarier_onLoaded.bind(this);
        this.generarDatos_centroSel = this.generarDatos_centroSel.bind(this);

        //DATE PICKER
        this.datePicker = this.datePicker.bind(this);
        this.prevDate_Click = this.prevDate_Click.bind(this);
        this.nextDate_Click = this.nextDate_Click.bind(this);
        this.datePicker_onValueChanged = this.datePicker_onValueChanged.bind(this);
        this.datePicker_displayFormat = this.datePicker_displayFormat.bind(this);

        //SELECTOR EMPRESAS POLARIER
        this.selectorEmpresasPolarier_onSelectionChanged = this.selectorEmpresasPolarier_onSelectionChanged.bind(this);

        //SELECTOR DIVISAS
        this.selectorDivisas_onSelectionChanged = this.selectorDivisas_onSelectionChanged.bind(this);

        //GRID
        this.gridCentros_onRowClick = this.gridCentros_onRowClick.bind(this);
        this.gridCentros_onCellPrepared = this.gridCentros_onCellPrepared.bind(this);
        this.desviacion_calculateCellValue = this.desviacion_calculateCellValue.bind(this);
        this.desviacion_cellTemplate = this.desviacion_cellTemplate.bind(this);
        this.costeKg_calculateCellValue = this.costeKg_calculateCellValue.bind(this);
        this.costeKg_cellTemplate = this.costeKg_cellTemplate.bind(this);
        this.costeKgPresupuestado_calculateCellValue = this.costeKgPresupuestado_calculateCellValue.bind(this);

        //GRID ANUAL
        this.gridAnual_onCellPrepared = this.gridAnual_onCellPrepared.bind(this);
        this.gridAnual_calculateCellValue_acumulado = this.gridAnual_calculateCellValue_acumulado.bind(this);
        this.gridAnual_cellTemplate_acumulado = this.gridAnual_cellTemplate_acumulado.bind(this);
        this.gridAnual_calculateCellValue_acumuladoPresupuestado =
            this.gridAnual_calculateCellValue_acumuladoPresupuestado.bind(this);

        //CHARTS
        this.customizeTooltip = this.customizeTooltip.bind(this);
        this.pieChart_centerTemplate = this.pieChart_centerTemplate.bind(this);
        this.pieChart_customizeLabel = this.pieChart_customizeLabel.bind(this);

        //GRID DATOS CENTRO
        this.gridBAI_customizeColumns = this.gridBAI_customizeColumns.bind(this);
        this.gridAnual_customizeColumns = this.gridAnual_customizeColumns.bind(this);

        //RADIO GROUP
        this.onValueChanged_radioGroup = this.onValueChanged_radioGroup.bind(this);
        this.onValueChanged_radioGroup_costeKg = this.onValueChanged_radioGroup_costeKg.bind(this);

        //BACK BUTTON
        this.backButton_onClick = this.backButton_onClick.bind(this);

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);
        this.loadPanel_show();
    }

    //REFERENCIAS
    get dxDataGrid_centros() {
        return this.dxDataGrid_centros_REF.current.instance;
    }
    get dxChart_BAI() {
        return this.dxChart_BAI_REF.current.instance;
    }
    get dxPieChart_Costes_mensuales() {
        return this.dxPieChart_Costes_mensuales_REF.current.instance;
    }
    get dxChart_Costes_mensuales() {
        return this.dxChart_Costes_mensuales_REF.current.instance;
    }

    //FORMATS
    currency_format_2 = {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    };
    currency_format_3 = {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 3,
        minimumFractionDigits: 3,
    };
    kilogram_format = {
        style: "unit",
        unit: "kilogram",
        maximumFractionDigits: 2,
    };

    //DATSOURCES
    handleResponse(response) {
        return response.text().then((text) => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                return Promise.reject(response.status);
            }

            if (data.length > 0) {
                return JSON.parse(data);
            } else {
                return { Centros: [] };
            }
        });
    }

    getDatos() {
        let { fechaSel, centroSel, empresaPolarierSel } = this.state;
        let _this = this;

        let ejercicio = empresaPolarierSel.ejercicioGsBase ? empresaPolarierSel.ejercicioGsBase.toLowerCase() : "";

        let requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                aplicacion: "gscontrol_pres",
                ejercicio: "eja_" + ejercicio,
                pantallaAcceso: "netroutines_mypolarier",
                stringEnviado: fechaSel.getFullYear().toString() + "|" + ejercicio,
                funcion: "a_get_datos",
            }),
        };

        fetch(connectionConstants.WEB_API_URL + `GsBase/GetDatos`, requestOptions)
            .then(this.handleResponse)
            .then((datos) => {
                $.each(datos.Centros, function (index, centro) {
                    //COSTES
                    centro.CostesMensuales = [null, null, null, null, null, null, null, null, null, null, null, null];
                    centro.CostesMensuales_ajustado = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];
                    centro.CostesMensuales_presupuestado = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];

                    $.each(centro.Costes, function (index, centroCoste) {
                        $.each(centroCoste.Meses, function (index, mes) {
                            if (mes[0] != "") {
                                //Presupuestado
                                centro.CostesMensuales_presupuestado[index] += mes[0];
                            }
                            if (mes[1] != "") {
                                centro.CostesMensuales[index] += mes[1];
                            }
                            if (mes[2] != "") {
                                centro.CostesMensuales_ajustado[index] += mes[2];
                            }
                        });
                    });

                    //KG
                    centro.KgMensuales = [null, null, null, null, null, null, null, null, null, null, null, null];
                    centro.KgMensuales_presupuestado = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];

                    $.each(centro.Kilos, function (index, mes) {
                        if (mes[0] != "") {
                            //Presupuestado
                            centro.KgMensuales_presupuestado[index] += mes[0];
                        }
                        if (mes[1] != "") {
                            centro.KgMensuales[index] += mes[1];
                        }
                    });

                    //ACUMULADO
                    centro.CostesMensuales_acumulados = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];
                    centro.CostesMensuales_presupuestado_acumulados = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];
                    centro.KgMensuales_Acumulados = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];
                    centro.KgMensuales_presupuestado_Acumulados = [
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                    ];

                    for (let mes = 0; mes < 12; mes++) {
                        for (let i = 0; i <= mes; i++) {
                            //COSTES
                            if (centro.CostesMensuales[i] != null) {
                                centro.CostesMensuales_acumulados[mes] += centro.CostesMensuales[i];
                            }

                            if (centro.CostesMensuales_presupuestado[i] != null) {
                                centro.CostesMensuales_presupuestado_acumulados[mes] +=
                                    centro.CostesMensuales_presupuestado[i];
                            }

                            //KG
                            if (centro.KgMensuales[i] != null) {
                                centro.KgMensuales_Acumulados[mes] += centro.KgMensuales[i];
                            }

                            if (centro.KgMensuales_presupuestado[i] != null) {
                                centro.KgMensuales_presupuestado_Acumulados[mes] += centro.KgMensuales_presupuestado[i];
                            }
                        }
                    }

                    // let datosKgLav = $.grep(_this.dataSource_spSelectControlPresupuestario_KgLavanderia.items(), function (x) {
                    //     return x.idLavanderia == centro.IDLav
                    // });

                    // $.each(datosKgLav, function (index, datosKgLav_mes) {
                    //     centro.KgMensuales[datosKgLav_mes.mes - 1] = datosKgLav_mes.kgProcesados;
                    // });
                });

                let divisaSel = datos.Divisa ? datos.Divisa.Moneda : "EUR";
                let divisa = datos.Divisa ? datos.Divisa : {};

                _this.currency_format_2.currency = divisaSel;
                _this.currency_format_3.currency = divisaSel;

                _this.setState({
                    datos: datos.Centros,
                    divisa: divisa,
                    divisaSel: divisaSel,
                });

                if (Object.keys(centroSel).length > 0) {
                    //Actualizamos los valores del centroSel
                    _this.generarDatos_centroSel(centroSel.CC, datos.Centros);
                } else {
                    _this.loadPanel_hide();
                }
            });
    }
    generarDatos_centroSel(CC, datos, actualizar = false) {
        let _this = this;
        let { idioma } = this.props;
        let { centroSel } = this.state;

        if (actualizar) {
            CC = centroSel.CC;
        }

        let data = datos.find((x) => x.CC === CC);
        if (data) {
            let centroSel = {
                CC: data.CC,
                denominacion: data.Deno,
                datosTabla_BAI: [],
                datosGrafica_BAI: [],
                datosTabla_Costes_mensual: [],
                datosGrafica_Costes_mensual: [],
            };

            //BAI

            //TABLA
            let rowPres = { denominacion: "BAI PRESUPUESTADO" };
            let rowAjus = { denominacion: "BAI AJUSTADO" };
            let rowDif = { denominacion: "DIFERENCIAL BAI" };

            $.each(data.BAI, function (index, mes) {
                rowPres[index] = mes.Pres;
                rowAjus[index] = mes.Ajus;
                rowDif[index] = mes.Ajus.length != "" ? mes.Ajus - mes.Pres : null;
            });

            centroSel.datosTabla_BAI.push(rowPres);
            centroSel.datosTabla_BAI.push(rowAjus);
            centroSel.datosTabla_BAI.push(rowDif);

            //GRÁFICA
            $.each(data.BAI, function (index, mes) {
                centroSel.datosGrafica_BAI.push({
                    mes: index,
                    arg: new Date(1900, index, 1, 0, 0, 0)
                        .toLocaleString(idioma.codigo, { month: "long" })
                        .toUpperCase(),
                    Pres: mes.Pres !== "" ? mes.Pres : null,
                    Ajus: mes.Ajus !== "" ? mes.Ajus : null,
                });
            });

            //COSTES
            // let kgLavanderia_items_año = $.grep(this.dataSource_spSelectControlPresupuestario_KgLavanderia.items(), function (x) {
            //     return x.idLavanderia == data.IDLav
            // })

            $.each(data.Costes, function (index, centroCoste) {
                let rowCoste_mensual = { denominacion: centroCoste.Deno };

                $.each(centroCoste.Meses, function (index, mes) {
                    // let datosKgLav = $.grep(kgLavanderia_items_año, function (x) {
                    //     return x.mes - 1 == index
                    // })[0];
                    let kgProcesados = data.Kilos ? data.Kilos[index][1] : null;
                    let kgProcesadosPresupuestado = data.Kilos ? data.Kilos[index][0] : null;
                    //TABLA
                    rowCoste_mensual[index + "_coste"] = mes[1];
                    rowCoste_mensual[index + "_costePresupuestado"] = mes[0];
                    rowCoste_mensual[index + "_costeAjustado"] = mes[2];

                    if (kgProcesados) {
                        rowCoste_mensual[index + "_kgProcesados"] = kgProcesados;
                        rowCoste_mensual[index + "_costeKg"] = mes[1] / kgProcesados;
                    }

                    if (kgProcesadosPresupuestado) {
                        rowCoste_mensual[index + "_kgProcesadosPresupuestado"] = kgProcesadosPresupuestado;
                        rowCoste_mensual[index + "_costeKgPresupuestado"] = mes[0] / kgProcesadosPresupuestado;
                    }

                    //GRÁFICA
                    let obj_grafica = {
                        serie: centroCoste.Deno,
                        arg: new Date(1900, index, 1, 0, 0, 0)
                            .toLocaleString(idioma.codigo, { month: "long" })
                            .toUpperCase(),
                        mes: index,
                    };
                    obj_grafica["val_coste"] = typeof mes[1] === "number" ? mes[1] : null;
                    obj_grafica["val_costeAjustado"] = typeof mes[2] === "number" ? mes[2] : null;

                    if (kgProcesados) {
                        obj_grafica["val_costeKg"] = typeof mes[1] === "number" ? mes[1] / kgProcesados : null;
                        obj_grafica["val_costeKgAjustado"] =
                            typeof mes[1] === "number" && typeof mes[2] === "number"
                                ? (mes[1] + mes[2]) / kgProcesados
                                : null;
                    }

                    centroSel.datosGrafica_Costes_mensual.push(obj_grafica);
                });

                centroSel.datosTabla_Costes_mensual.push(rowCoste_mensual);
            });

            if (actualizar) {
                return centroSel;
            }

            this.setState(
                {
                    centroSel: centroSel,
                    dxMultiView_index: 1,
                },
                () => {
                    _this.loadPanel_hide();
                }
            );
        } else {
            if (actualizar) {
                return {};
            }

            this.setState(
                {
                    centroSel: {},
                    dxMultiView_index: 0,
                },
                () => {
                    _this.loadPanel_hide();
                }
            );
        }
    }
    aplicarCambioDivisa(multiplicadorCambio) {
        let { datos } = this.state;
        let datos_ = $.extend(true, [], datos);
        $.each(datos_, function (index, centro) {
            //BAI
            $.each(centro.BAI, function (index, mes) {
                mes.Real = mes.Real / multiplicadorCambio;
                mes.Ajus = mes.Ajus / multiplicadorCambio;
                mes.Pres = mes.Pres / multiplicadorCambio;
            });

            //COSTES
            $.each(centro.Costes, function (index, centroCoste) {
                $.each(centroCoste.Meses, function (index, mes) {
                    mes[0] = mes[0] / multiplicadorCambio;
                    mes[1] = mes[1] / multiplicadorCambio;
                    mes[2] = mes[2] / multiplicadorCambio;
                });
            });

            $.each(centro.CostesMensuales, function (index, val) {
                if (val) {
                    centro.CostesMensuales[index] = val / multiplicadorCambio;
                }
            });

            $.each(centro.CostesMensuales_acumulados, function (index, val) {
                if (val) {
                    centro.CostesMensuales_acumulados[index] = val / multiplicadorCambio;
                }
            });

            $.each(centro.CostesMensuales_ajustado, function (index, val) {
                if (val) {
                    centro.CostesMensuales_ajustado[index] = val / multiplicadorCambio;
                }
            });

            $.each(centro.CostesMensuales_presupuestado, function (index, val) {
                if (val) {
                    centro.CostesMensuales_presupuestado[index] = val / multiplicadorCambio;
                }
            });

            $.each(centro.CostesMensuales_presupuestado_acumulados, function (index, val) {
                if (val) {
                    centro.CostesMensuales_presupuestado_acumulados[index] = val / multiplicadorCambio;
                }
            });
        });

        return {
            datos: datos_,
            centroSel: this.generarDatos_centroSel(null, datos_, true),
        };
    }

    // dataSource_spSelectControlPresupuestario_KgLavanderia = new DataSource({
    //     paginate: false,
    //     store: new ODataStore({
    //         url: connectionConstants.ODATA_URL + "spSelectControlPresupuestario_KgLavanderia",
    //         errorHandler: function (error) { errorHandler(error, null) },
    //         beforeSend: (request) => { this.dataSource_spSelectControlPresupuestario_KgLavanderia_beforeSend(request) },
    //         version: 4
    //     })
    // });
    // dataSource_spSelectControlPresupuestario_KgLavanderia_beforeSend(request) {
    //     let { fechaSel } = this.state
    //     request.headers = { ...authHeader() }
    //     request.params.año = fechaSel.getFullYear()
    // }

    dataSource_tblEmpresasPolarier = new DataSource({
        paginate: false,
        store: new ODataStore({
            url: connectionConstants.ODATA_URL + "tblEmpresasPolarier",
            key: "idEmpresaPolarier",
            keyType: "Int32",
            errorHandler: function (error) {
                errorHandler(error, null);
            },
            beforeSend: function (request) {
                request.headers = { ...authHeader() };
            },
            onLoaded: (data) => {
                this.dataSource_tblEmpresasPolarier_onLoaded(data);
            },
            version: 4,
        }),
        expand: ["tblMoneda"],
    });
    dataSource_tblEmpresasPolarier_onLoaded(data) {
        let empresaPolarierSel = data[2];
        this.setState({ empresaPolarierSel: empresaPolarierSel }); //POL
    }

    //DATE PICKER
    datePicker() {
        let { showLoading } = this.props;
        return (
            <DateBox
                key="postHeading_1"
                className="centerText font-size-sm mr-3"
                inputAttr={{ class: "font-size-sm" }}
                value={this.state.fechaSel}
                width="100%"
                stylingMode="underlined"
                openOnFieldClick={true}
                calendarOptions={{
                    maxZoomLevel: "year",
                    minZoomLevel: "year",
                }}
                disabled={showLoading}
                displayFormat={this.datePicker_displayFormat}
                onValueChanged={this.datePicker_onValueChanged}
            >
                <DateBoxButton
                    name="prevDate"
                    location="before"
                    options={{
                        icon: "spinprev",
                        stylingMode: "text",
                        onClick: this.prevDate_Click,
                    }}
                />
                <DateBoxButton
                    name="nextDate"
                    location="after"
                    options={{
                        icon: "spinnext",
                        stylingMode: "text",
                        onClick: this.nextDate_Click,
                    }}
                />
            </DateBox>
        );
    }
    prevDate_Click(e) {
        let { fechaSel } = this.state;
        let _fechaSel = new Date(fechaSel.getTime());
        this.setState({
            fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() - 1)),
        });
    }
    nextDate_Click(e) {
        let { fechaSel } = this.state;
        let _fechaSel = new Date(fechaSel.getTime());
        this.setState({
            fechaSel: new Date(_fechaSel.setMonth(_fechaSel.getMonth() + 1)),
        });
    }
    datePicker_onValueChanged(e) {
        this.setState({ fechaSel: e.value });
    }
    datePicker_displayFormat(fecha) {
        let { idioma } = this.props;
        if (new Date().getFullYear() === fecha.getFullYear()) {
            return fecha.toLocaleString(idioma.codigo, { month: "long" }).toUpperCase();
        } else {
            return fecha.toLocaleString(idioma.codigo, { month: "long", year: "numeric" }).toUpperCase();
        }
    }

    selectorEmpresasPolarier() {
        let { showLoading } = this.props;
        let { empresaPolarierSel } = this.state;
        return (
            <SelectBox
                key="postHeading_0"
                deferRendering={false}
                className="centerText font-size-sm mr-3"
                inputAttr={{ class: "font-size-sm" }}
                dataSource={this.dataSource_tblEmpresasPolarier.items()}
                displayExpr="denominacion"
                valueExpr="ejercicioGsBase"
                value={empresaPolarierSel.ejercicioGsBase}
                defaultValue={empresaPolarierSel.ejercicioGsBase}
                disabled={showLoading}
                width="100%"
                stylingMode="underlined"
                onSelectionChanged={this.selectorEmpresasPolarier_onSelectionChanged}
            />
        );
    }
    selectorEmpresasPolarier_onSelectionChanged(e) {
        let _this = this;
        let empresaPolarierSel = e.selectedItem;
        this.setState(
            {
                empresaPolarierSel: empresaPolarierSel,
                centroSel: {},
                dxMultiView_index: 0,
            },
            () => {
                _this.loadPanel_show();
                _this.getDatos();
            }
        );
    }

    selectorDivisas() {
        let { showLoading } = this.props;
        let { divisa, divisaSel } = this.state;

        let items = [
            { denominacion: "EURO", codigoMoneda: "EUR" },
            { denominacion: "DÓLAR", codigoMoneda: "USD" },
        ];
        if (divisa && divisa.Moneda != "EUR" && divisa.Moneda != "USD") {
            items.push({ denominacion: divisa.Moneda, codigoMoneda: divisa.Moneda });
        }
        return (
            <SelectBox
                key="postHeading_3"
                deferRendering={false}
                className="centerText font-size-sm"
                inputAttr={{ class: "font-size-sm" }}
                items={items}
                displayExpr="denominacion"
                valueExpr="codigoMoneda"
                disabled={showLoading || divisa.Moneda === "EUR"}
                value={divisaSel}
                defaultValue={divisaSel}
                width="100%"
                stylingMode="underlined"
                onSelectionChanged={this.selectorDivisas_onSelectionChanged}
            />
        );
    }
    selectorDivisas_onSelectionChanged(e) {
        let { divisaSel, divisa } = this.state;
        let nuevaDivisa = e.selectedItem.codigoMoneda;

        let multiplicadorCambio = 0;
        if (divisaSel === divisa.Moneda) {
            //Cambio de predefinida a otra
            multiplicadorCambio = divisa["Conv" + nuevaDivisa];
        } else if (nuevaDivisa === divisa.Moneda) {
            //Cambio de otra a predefinida
            multiplicadorCambio = 1 / divisa["Conv" + divisaSel];
        } else {
            //Cambio de otra a otra (EUR => USD)
            multiplicadorCambio = divisa["Conv" + nuevaDivisa] / divisa["Conv" + divisaSel];
        }

        this.currency_format_2.currency = nuevaDivisa;
        this.currency_format_3.currency = nuevaDivisa;

        let cambiosState = this.aplicarCambioDivisa(multiplicadorCambio);
        cambiosState.divisaSel = nuevaDivisa;
        this.setState(cambiosState);
    }

    render() {
        let { dxMultiView_index, mostrarCosteKg, centroSel } = this.state;
        let disableCostes = !centroSel.datosTabla_Costes_mensual || centroSel.datosTabla_Costes_mensual.length == 0;

        return (
            <Fragment>
                <PageTitle
                    preHeading={this.getBackButton()}
                    heading={getNombreFormulario(this)}
                    postHeading={[this.selectorEmpresasPolarier(), this.datePicker(), this.selectorDivisas()]}
                />
                <div className={"media-body"}>
                    {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
                    <div id="ControlPresupuestario" className={"formContainer"}>
                        <Box direction="col" width="100%" height="100%">
                            <ItemBox visible={Object.keys(centroSel).length > 0} baseSize={"50px"}>
                                <Toolbar>
                                    <ToolbarItem location="before">
                                        <div className="pl-2 font-size-xl">{centroSel.denominacion}</div>
                                    </ToolbarItem>
                                    <ToolbarItem location="center" locateInMenu="auto" visible={dxMultiView_index > 1}>
                                        <RadioGroup
                                            items={[
                                                { text: "COSTE KG.", val: true },
                                                { text: "COSTE TOTAL", val: false },
                                            ]}
                                            value={mostrarCosteKg}
                                            valueExpr="val"
                                            className="text-center"
                                            width="100%"
                                            layout="horizontal"
                                            height="35px"
                                            onValueChanged={this.onValueChanged_radioGroup_costeKg}
                                        />
                                    </ToolbarItem>
                                    <ToolbarItem location="after" locateInMenu="auto">
                                        <RadioGroup
                                            items={[
                                                { text: "BAI", val: 1 },
                                                { text: "C. MENSUAL", val: 2, disabled: disableCostes },
                                                { text: "C. ANUAL", val: 3, disabled: disableCostes },
                                            ]}
                                            value={dxMultiView_index}
                                            valueExpr="val"
                                            className="text-center"
                                            width="100%"
                                            layout="horizontal"
                                            height="35px"
                                            onValueChanged={this.onValueChanged_radioGroup}
                                        />
                                    </ToolbarItem>
                                </Toolbar>
                            </ItemBox>
                            <ItemBox ratio={1}>
                                <MultiView
                                    height="100%"
                                    width="100%"
                                    selectedIndex={dxMultiView_index}
                                    swipeEnabled={false}
                                    deferRendering={false}
                                >
                                    <MultiViewItem render={this.render_TabCentros} />
                                    <MultiViewItem render={this.render_TabDatosCentro_BAI} />
                                    <MultiViewItem render={this.render_TabDatosCentro_Costes_mensual} />
                                    <MultiViewItem render={this.render_TabDatosCentro_Costes_anual} />
                                </MultiView>
                            </ItemBox>
                        </Box>
                    </div>
                    {/* </ReactCSSTransitionGroup> */}
                </div>
            </Fragment>
        );
    }

    render_TabCentros = () => {
        let { datos, fechaSel, divisaSel } = this.state;
        let mesSel = fechaSel.getMonth();

        let _currency_format_2 = {
            style: "currency",
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            currency: divisaSel,
        };
        let _currency_format_3 = {
            style: "currency",
            maximumFractionDigits: 3,
            minimumFractionDigits: 3,
            currency: divisaSel,
        };

        let datos_tabla = $.extend(true, [], datos);
        $.each(datos_tabla, function (index, centro) {
            if (centro.CostesMensuales_acumulados[mesSel]) {
                centro.CostesMensuales_acumulados[mesSel] =
                    centro.CostesMensuales_acumulados[mesSel] + centro.CostesMensuales_ajustado[mesSel];
            }
        });

        return (
            <DataGrid
                ref={this.dxDataGrid_centros_REF}
                height="100%"
                dataSource={datos_tabla}
                remoteOperations={false}
                //Estilos
                hoverStateEnabled={true}
                showColumnLines={false}
                showRowLines={true}
                rowAlternationEnabled={true}
                onRowClick={this.gridCentros_onRowClick}
                onRowPrepared={this.gridCentros_onRowPrepared}
                onCellPrepared={this.gridCentros_onCellPrepared}
            >
                <FilterRow visible={true} />
                <Paging enabled={false} />
                <Column dataField="CC" visible={false} />
                <Column
                    dataField="Deno"
                    caption={"CENTRO"}
                    minWidth={200}
                    sortOrder="asc"
                    editorOptions={{ showClearButton: true }}
                />
                <Column
                    dataField={"BAI[" + mesSel + "].Pres"}
                    caption={"BAI PRESUPUESTADO"}
                    name="pres"
                    dataType="number"
                    alignment="right"
                    width={150}
                    format={_currency_format_2}
                    allowFiltering={false}
                />
                <Column
                    dataField={"BAI[" + mesSel + "].Real"}
                    caption={"BAI REAL"}
                    name="real"
                    dataType="number"
                    alignment="right"
                    width={150}
                    format={_currency_format_2}
                    allowFiltering={false}
                />
                <Column
                    dataField={"BAI[" + mesSel + "].Ajus"}
                    caption={"BAI AJUSTADO"}
                    name="ajus"
                    dataType="number"
                    alignment="right"
                    width={150}
                    format={_currency_format_2}
                    allowFiltering={false}
                />
                <Column
                    caption={"DESVIACIÓN"}
                    name="desv"
                    dataType="number"
                    alignment="center"
                    width={200}
                    format={_currency_format_2}
                    calculateCellValue={this.desviacion_calculateCellValue}
                    cellTemplate={this.desviacion_cellTemplate}
                    allowFiltering={false}
                    allowSorting={true}
                />
                <Column
                    dataField={"CostesMensuales_acumulados[" + mesSel + "]"}
                    caption={"COSTES"}
                    name="costes"
                    dataType="number"
                    alignment="right"
                    width={150}
                    format={_currency_format_2}
                    allowFiltering={false}
                />
                <Column
                    dataField={"KgMensuales_Acumulados[" + mesSel + "]"}
                    caption={"KG. PROCESADOS"}
                    name="kg"
                    dataType="number"
                    alignment="right"
                    width={150}
                    format={this.kilogram_format}
                    allowFiltering={false}
                />
                <Column
                    caption={"COSTE / KG."}
                    name="costeKg"
                    dataType="number"
                    alignment="center"
                    width={120}
                    format={_currency_format_3}
                    calculateCellValue={this.costeKg_calculateCellValue}
                    cellTemplate={this.costeKg_cellTemplate}
                    allowFiltering={false}
                    allowSorting={true}
                />
                <Column
                    dataField={"CostesMensuales_presupuestado_acumulados[" + mesSel + "]"}
                    name="costesPresupuestado"
                    visible={false}
                />
                <Column
                    dataField={"KgMensuales_presupuestado_Acumulados[" + mesSel + "]"}
                    name="kgPresupuestado"
                    visible={false}
                />
                <Column
                    caption={"COSTE / KG. OBJ."}
                    name="costeKgPresupuestado"
                    dataType="number"
                    alignment="center"
                    width={120}
                    format={_currency_format_3}
                    calculateCellValue={this.costeKgPresupuestado_calculateCellValue}
                    allowFiltering={false}
                    allowSorting={true}
                />
                <Summary>
                    <TotalItem column="Deno" displayFormat={getTrad("total").toUpperCase()} />
                    <TotalItem column="pres" summaryType="sum" valueFormat={_currency_format_2} displayFormat="{0}" />
                    <TotalItem column="real" summaryType="sum" valueFormat={_currency_format_2} displayFormat="{0}" />
                    <TotalItem column="ajus" summaryType="sum" valueFormat={_currency_format_2} displayFormat="{0}" />
                    <TotalItem column="desv" summaryType="sum" valueFormat={_currency_format_2} displayFormat="{0}" />
                    <TotalItem column="costes" summaryType="sum" valueFormat={_currency_format_2} displayFormat="{0}" />
                    <TotalItem column="kg" summaryType="sum" valueFormat={this.kilogram_format} displayFormat="{0}" />
                    <TotalItem
                        column="costeKg"
                        summaryType="sum"
                        valueFormat={_currency_format_3}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="kgPresupuestado"
                        summaryType="sum"
                        valueFormat={this.kilogram_format}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="costesPresupuestado"
                        summaryType="sum"
                        valueFormat={_currency_format_3}
                        displayFormat="{0}"
                    />
                    <TotalItem
                        column="costeKgPresupuestado"
                        summaryType="sum"
                        valueFormat={_currency_format_3}
                        displayFormat="{0}"
                    />
                </Summary>
            </DataGrid>
        );
    };

    render_TabDatosCentro_BAI = () => {
        let { centroSel, fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();

        let format = { ...this.currency_format_2 };

        let datos_tabla = $.extend(true, [], centroSel.datosTabla_BAI);
        $.each(datos_tabla, function (index, item) {
            if (index > 0) {
                //No se filtra el presupuestado
                $.each(Object.keys(item), function (index, property) {
                    if (property != "denominacion" && parseInt(property) > mesSel) {
                        item[property] = null;
                    }
                });
            }
        });

        let datos_grafica = $.extend(true, [], centroSel.datosGrafica_BAI);
        $.each(datos_grafica, function (index, item) {
            if (item.mes > mesSel) {
                item["Ajus"] = null;
            }
        });

        return (
            <Box direction="col" width="100%" height="100%">
                <ItemBox baseSize="auto">
                    <DataGrid
                        dataSource={datos_tabla}
                        remoteOperations={false}
                        //Estilos
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        columnAutoWidth={true}
                        customizeColumns={this.gridBAI_customizeColumns}
                        onCellPrepared={this.gridBAI_onCellPrepared}
                    >
                        <Paging enabled={false} />
                        <Column dataField="denominacion" caption={" "} minWidth={120} />
                        <Column dataField={"0"} format={format} />
                        <Column dataField={"1"} format={format} />
                        <Column dataField={"2"} format={format} />
                        <Column dataField={"3"} format={format} />
                        <Column dataField={"4"} format={format} />
                        <Column dataField={"5"} format={format} />
                        <Column dataField={"6"} format={format} />
                        <Column dataField={"7"} format={format} />
                        <Column dataField={"8"} format={format} />
                        <Column dataField={"9"} format={format} />
                        <Column dataField={"10"} format={format} />
                        <Column dataField={"11"} format={format} />
                    </DataGrid>
                </ItemBox>
                <ItemBox ratio={1}>
                    <Chart
                        ref={this.dxChart_BAI_REF}
                        className="dxChart_responsive"
                        dataSource={datos_grafica}
                        redrawOnResize={true}
                    >
                        <Margin top={25} bottom={25} />
                        <Series name="BAI PRESUPUESTADO" valueField="Pres" type="spline" color="var(--primary)" />
                        <Series
                            name="BAI AJUSTADO"
                            valueField="Ajus"
                            type="spline"
                            color="var(--blue)"
                            ignoreEmptyPoints={true}
                        />
                        <Legend
                            position="outside"
                            itemsAlignment="center"
                            itemTextPosition="bottom"
                            verticalAlignment="top"
                            horizontalAlignment="center"
                        />
                        <ChartTooltip enabled={true} shared={true} customizeTooltip={this.customizeTooltip} />
                    </Chart>
                </ItemBox>
            </Box>
        );
    };

    render_TabDatosCentro_Costes_mensual = () => {
        let { centroSel, mostrarCosteKg, fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();

        let format = mostrarCosteKg ? { ...this.currency_format_3 } : { ...this.currency_format_2 };
        let dataField = mesSel + (mostrarCosteKg ? "_costeKg" : "_coste");

        let datos_tabla = $.extend(true, [], centroSel.datosTabla_Costes_mensual);
        $.each(datos_tabla, function (index, item) {
            item[mesSel + "_coste"] = item[mesSel + "_coste"] + item[mesSel + "_costeAjustado"];
            item[mesSel + "_costeKg"] = item[mesSel + "_coste"] / item[mesSel + "_kgProcesados"];
        });

        return (
            <Box direction="col" width="100%" height="100%">
                <ItemBox ratio={1}>
                    <Box direction="row" width="100%" height="100%">
                        <ItemBox ratio={1}>
                            <DataGrid
                                dataSource={datos_tabla}
                                remoteOperations={false}
                                //Estilos
                                width="100%"
                                showColumnLines={false}
                                showRowLines={true}
                                rowAlternationEnabled={true}
                            >
                                <Paging enabled={false} />
                                <Column dataField="denominacion" caption={getTrad("denominacion")} minWidth={120} />
                                <Column
                                    dataField={dataField}
                                    caption={"Coste" + (mostrarCosteKg ? " Kg." : "")}
                                    name={dataField}
                                    dataType="number"
                                    alignment="right"
                                    width={150}
                                    format={format}
                                    allowFiltering={false}
                                />
                                <Summary>
                                    <TotalItem column="arg" displayFormat={getTrad("total").toUpperCase()} />
                                    <TotalItem
                                        column={dataField}
                                        summaryType="sum"
                                        valueFormat={format}
                                        displayFormat="{0}"
                                    />
                                </Summary>
                            </DataGrid>
                        </ItemBox>
                        <ItemBox ratio={2}>
                            <PieChart
                                ref={this.dxPieChart_Costes_mensuales_REF}
                                className="dxChart_responsive"
                                dataSource={datos_tabla}
                                resolveLabelOverlapping="shift"
                                innerRadius={0.65}
                                type="doughnut"
                                startAngle={90}
                                centerTemplate={this.pieChart_centerTemplate}
                            >
                                <Margin top={25} bottom={25} left={25} right={25} />
                                <PieSeries valueField={dataField} argumentField={"denominacion"}>
                                    <PieLabel
                                        visible={true}
                                        position="columns"
                                        format="fixedPoint"
                                        customizeText={this.pieChart_customizeLabel}
                                        backgroundColor="none"
                                    >
                                        <Connector visible={true}></Connector>
                                    </PieLabel>
                                </PieSeries>
                                <PieLegend
                                    position="outside"
                                    itemsAlignment="center"
                                    itemTextPosition="bottom"
                                    verticalAlignment="top"
                                    horizontalAlignment="center"
                                />
                            </PieChart>
                        </ItemBox>
                    </Box>
                </ItemBox>
            </Box>
        );
    };
    pieChart_centerTemplate(pieChart, container) {
        let { divisaSel, mostrarCosteKg } = this.state;

        const points = pieChart.getAllSeries()[0].getVisiblePoints();
        if (points.length > 0) {
            let totalKg = points.reduce((total, point) => total + point.originalValue, 0);

            let content = $(
                '<svg><circle cx="200" cy="200" fill="#eee" r="' +
                    (pieChart.getInnerRadius() - 6) +
                    '"></circle>' +
                    '<text text-anchor="middle" class="font-size-xxl" x="200" y="200" fill="#494949">' +
                    '<tspan x="200" dy="10px" style="font-weight: 600">' +
                    formatNumber(totalKg, mostrarCosteKg ? 3 : 2, "currency", divisaSel) +
                    "</tspan></text></svg>"
            );

            container.appendChild(content.get(0));
        }
    }
    pieChart_customizeLabel(e) {
        let { divisaSel, mostrarCosteKg } = this.state;

        return `<b>${e.argumentText}</b>\n${formatNumber(e.value, mostrarCosteKg ? 3 : 2, "currency", divisaSel)} (${
            e.percentText
        })`;
    }

    render_TabDatosCentro_Costes_anual = () => {
        let { centroSel, mostrarCosteKg, fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();
        let dataField_type = mostrarCosteKg ? "_costeKg" : "_coste";
        let format = mostrarCosteKg ? { ...this.currency_format_3 } : { ...this.currency_format_2 };
        let datos_tabla = $.extend(true, [], centroSel.datosTabla_Costes_mensual);
        $.each(datos_tabla, function (index, item) {
            $.each(Object.keys(item), function (index, property) {
                if (property.split("_")[0] > mesSel) {
                    item[property] = null;
                }
            });

            item[mesSel + "_coste"] = item[mesSel + "_coste"] + item[mesSel + "_costeAjustado"];
            item[mesSel + "_costeKg"] = item[mesSel + "_coste"] / item[mesSel + "_kgProcesados"];
        });

        let datos_grafica = $.extend(true, [], centroSel.datosGrafica_Costes_mensual);
        $.each(datos_grafica, function (index, item) {
            if (item.mes > mesSel) {
                item["val" + dataField_type] = null;
            }

            if (item.mes == mesSel) {
                item["val_coste"] = item["val_coste"] + item["val_costeAjustado"];
                item["val_costeKg"] = item["val_costeKgAjustado"];
            }
        });

        return (
            <Box direction="col" width="100%" height="100%">
                <ItemBox baseSize="auto">
                    <DataGrid
                        dataSource={datos_tabla}
                        remoteOperations={false}
                        //Estilos
                        width="100%"
                        height="100%"
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        columnAutoWidth={true}
                        customizeColumns={this.gridAnual_customizeColumns}
                        onCellPrepared={this.gridAnual_onCellPrepared}
                    >
                        <ColumnFixing enabled={true} />
                        <Paging enabled={false} />
                        <Column dataField="denominacion" caption={" "} width={300} fixed={true} />
                        <Column dataField={"0" + dataField_type} />
                        <Column dataField={"1" + dataField_type} />
                        <Column dataField={"2" + dataField_type} />
                        <Column dataField={"3" + dataField_type} />
                        <Column dataField={"4" + dataField_type} />
                        <Column dataField={"5" + dataField_type} />
                        <Column dataField={"6" + dataField_type} />
                        <Column dataField={"7" + dataField_type} />
                        <Column dataField={"8" + dataField_type} />
                        <Column dataField={"9" + dataField_type} />
                        <Column dataField={"10" + dataField_type} />
                        <Column dataField={"11" + dataField_type} />
                        <Column
                            caption="Acumulado"
                            name="acumulado"
                            calculateCellValue={this.gridAnual_calculateCellValue_acumulado}
                            cellTemplate={this.gridAnual_cellTemplate_acumulado}
                            fixed={true}
                            fixedPosition="right"
                            minWidth={100}
                        />
                        <Column
                            caption="Objetivo"
                            name="acumuladoPresupuestado"
                            calculateCellValue={this.gridAnual_calculateCellValue_acumuladoPresupuestado}
                            fixed={true}
                            fixedPosition="right"
                            minWidth={100}
                        />
                        <Summary>
                            <TotalItem column="denominacion" displayFormat={getTrad("total").toUpperCase()} />
                            <TotalItem
                                column={"0" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"1" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"2" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"3" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"4" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"5" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"6" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"7" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"8" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"9" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"10" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"11" + dataField_type}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                                customizeText={this.grid_Summary_CustomizeText}
                            />
                            <TotalItem
                                column={"acumulado"}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                            />
                            <TotalItem
                                column={"acumuladoPresupuestado"}
                                summaryType="sum"
                                valueFormat={format}
                                displayFormat="{0}"
                            />
                        </Summary>
                    </DataGrid>
                </ItemBox>
                <ItemBox ratio={1}>
                    <Chart
                        ref={this.dxChart_Costes_mensuales_REF}
                        className="dxChart_responsive"
                        dataSource={datos_grafica}
                        redrawOnResize={true}
                    >
                        <Margin top={25} bottom={25} />
                        <CommonSeriesSettings valueField={"val" + dataField_type} type="spline">
                            <Point size={10} />
                        </CommonSeriesSettings>
                        <SeriesTemplate nameField="serie" />
                        <Legend
                            position="outside"
                            itemsAlignment="center"
                            itemTextPosition="bottom"
                            verticalAlignment="top"
                            horizontalAlignment="center"
                        />
                        <ChartTooltip enabled={true} shared={true} customizeTooltip={this.customizeTooltip} />
                    </Chart>
                </ItemBox>
            </Box>
        );
    };

    //GRID
    gridCentros_onRowClick({ data }) {
        let { datos } = this.state;
        this.generarDatos_centroSel(data.CC, datos);
    }
    gridCentros_onRowPrepared(e) {
        if (e.rowType === "data") {
            e.rowElement.css("cursor", "pointer");
        }
    }
    gridCentros_onCellPrepared(e) {
        let { divisaSel } = this.state;

        if (e.rowType === "totalFooter") {
            var isDesviacion = $.grep(e.summaryItems, function (v) {
                return v.column === "desv";
            });

            var isCosteKg = $.grep(e.summaryItems, function (v) {
                return v.column === "costeKg";
            });

            var isCosteKgPresupuestado = $.grep(e.summaryItems, function (v) {
                return v.column === "costeKgPresupuestado";
            });

            if (isDesviacion.length > 0) {
                var container = e.cellElement.find(".dx-datagrid-summary-item");
                container.empty();

                var presupuestado = Math.abs(e.component.getTotalSummaryValue("pres"));
                var desviacion = e.component.getTotalSummaryValue("desv");
                let valorPorcentaje = desviacion / presupuestado;
                let porcentaje;
                if (presupuestado === 0) {
                    porcentaje = "N/A";
                } else if (valorPorcentaje * 100 > 10000) {
                    porcentaje = "+10.000 %";
                } else if (valorPorcentaje * 100 < -10000) {
                    porcentaje = "-10.000 %";
                } else {
                    porcentaje = formatNumber(valorPorcentaje, 2, "percent");
                }

                container.css(
                    "color",
                    desviacion > 0 ? "var(--success)" : desviacion < 0 ? "var(--danger)" : "inherit"
                );

                container.append(
                    $("<div />")
                        .addClass("d-flex mr-3")
                        .append(
                            $("<div />")
                                .addClass("flex-fill w-100 text-right")
                                .text(formatNumber(desviacion, 2, "currency", divisaSel))
                        )
                        .append($("<div />").addClass("text-right").css("flex-basis", "150px").text(porcentaje))
                );
            } else if (isCosteKg.length > 0) {
                var container = e.cellElement.find(".dx-datagrid-summary-item");
                container.empty();

                var costes = Math.abs(e.component.getTotalSummaryValue("costes"));
                var kg = e.component.getTotalSummaryValue("kg");
                var costeKgPresupuestado = Math.abs(e.component.getTotalSummaryValue("costeKgPresupuestado"));

                var value = costes / kg;

                container.append($("<div />").text(formatNumber(value, 3, "currency", divisaSel)));
                container.css(
                    "color",
                    value < costeKgPresupuestado
                        ? "var(--success)"
                        : value > costeKgPresupuestado
                        ? "var(--danger)"
                        : "inherit"
                );
            } else if (isCosteKgPresupuestado.length > 0) {
                var container = e.cellElement.find(".dx-datagrid-summary-item");
                container.empty();

                var costes = Math.abs(e.component.getTotalSummaryValue("costesPresupuestado"));
                var kg = e.component.getTotalSummaryValue("kgPresupuestado");

                var value = costes / kg;

                container.append($("<div />").text(formatNumber(value, 3, "currency", divisaSel)));
            }
        }
    }
    desviacion_calculateCellValue(rowData) {
        let { fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();
        return rowData.BAI[mesSel].Ajus - rowData.BAI[mesSel].Pres;
    }
    desviacion_cellTemplate(cellElement, cellInfo) {
        let { fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();

        let presupuestado = Math.abs(cellInfo.data.BAI[mesSel].Pres);
        let valorPorcentaje = cellInfo.value / presupuestado;
        let porcentaje;
        if (presupuestado === 0) {
            porcentaje = "N/A";
        } else if (valorPorcentaje * 100 > 10000) {
            porcentaje = "+10.000 %";
        } else if (valorPorcentaje * 100 < -10000) {
            porcentaje = "-10.000 %";
        } else {
            porcentaje = formatNumber(valorPorcentaje, 2, "percent");
        }

        cellElement.css(
            "color",
            cellInfo.value > 0 ? "var(--success)" : cellInfo.value < 0 ? "var(--danger)" : "inherit"
        );

        cellElement.append(
            $("<div />")
                .addClass("d-flex mr-3")
                .append($("<div />").addClass("flex-fill w-100 text-right").text(cellInfo.text))
                .append($("<div />").addClass("text-right").css("flex-basis", "150px").text(porcentaje))
        );
    }
    costeKg_calculateCellValue(rowData) {
        let { fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();
        if (rowData.KgMensuales_Acumulados[mesSel] > 0) {
            return rowData.CostesMensuales_acumulados[mesSel] / rowData.KgMensuales_Acumulados[mesSel];
        } else {
            return null;
        }
    }
    costeKg_cellTemplate(cellElement, cellInfo) {
        let { fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();

        let costeKg = cellInfo.value;
        let costePresupuestado = 0;
        if (cellInfo.data.KgMensuales_presupuestado_Acumulados[mesSel] > 0) {
            costePresupuestado =
                cellInfo.data.CostesMensuales_presupuestado_acumulados[mesSel] /
                cellInfo.data.KgMensuales_presupuestado_Acumulados[mesSel];
        }
        cellElement.text(cellInfo.text);
        cellElement.css(
            "color",
            costePresupuestado > costeKg ? "var(--success)" : costePresupuestado < costeKg ? "var(--danger)" : "inherit"
        );
    }

    costeKgPresupuestado_calculateCellValue(rowData) {
        let { fechaSel } = this.state;
        let mesSel = fechaSel.getMonth();
        if (rowData.KgMensuales_presupuestado_Acumulados[mesSel] > 0) {
            return (
                rowData.CostesMensuales_presupuestado_acumulados[mesSel] /
                rowData.KgMensuales_presupuestado_Acumulados[mesSel]
            );
        } else {
            return null;
        }
    }

    //GRID DATOS CENTRO
    gridBAI_customizeColumns(columns) {
        let { idioma } = this.props;
        let { mostrarCosteKg, dxMultiView_index } = this.state;
        let format =
            dxMultiView_index > 1 && mostrarCosteKg ? { ...this.currency_format_3 } : { ...this.currency_format_2 };
        $.each(columns, function (index, column) {
            if (index > 0 && column.dataField) {
                let mes = column.dataField;
                if (mes.includes("_")) {
                    mes = mes.split("_")[0];
                }

                column.caption = new Date(1900, mes, 1, 0, 0, 0)
                    .toLocaleString(idioma.codigo, { month: "long" })
                    .toUpperCase();
                column.dataType = "number";
                column.alignment = "right";
            }
            column.format = format;
        });
    }
    gridBAI_onCellPrepared(e) {
        if (e.rowType === "data" && e.rowIndex === 2) {
            //Row BAI DIFERENCIAL
            e.cellElement.css("color", e.value > 0 ? "var(--success)" : e.value < 0 ? "var(--danger)" : "inherit");
        }
    }

    gridAnual_customizeColumns(columns) {
        let { idioma } = this.props;
        let { mostrarCosteKg } = this.state;
        let format = mostrarCosteKg ? { ...this.currency_format_3 } : { ...this.currency_format_2 };
        $.each(columns, function (index, column) {
            if (index > 0 && column.dataField) {
                let mes = column.dataField;
                if (mes.includes("_")) {
                    mes = mes.split("_")[0];
                }

                column.caption = new Date(1900, mes, 1, 0, 0, 0)
                    .toLocaleString(idioma.codigo, { month: "long" })
                    .toUpperCase();
                column.dataType = "number";
                column.alignment = "right";
            }
            column.format = format;
        });
    }
    gridAnual_onCellPrepared(e) {
        let { divisaSel, mostrarCosteKg } = this.state;
        let decimales = mostrarCosteKg ? 3 : 2;
        if (e.rowType === "totalFooter") {
            var isAcumulado = $.grep(e.summaryItems, function (v) {
                return v.column === "acumulado";
            });

            if (isAcumulado.length > 0) {
                var container = e.cellElement.find(".dx-datagrid-summary-item");
                container.empty();

                var acumulado = e.component.getTotalSummaryValue("acumulado");
                var acumuladoPresupuestado = Math.abs(e.component.getTotalSummaryValue("acumuladoPresupuestado"));

                container.append($("<div />").text(formatNumber(acumulado, decimales, "currency", divisaSel)));
                container.css(
                    "color",
                    acumulado < acumuladoPresupuestado
                        ? "var(--success)"
                        : acumulado > acumuladoPresupuestado
                        ? "var(--danger)"
                        : "inherit"
                );
            }
        }
    }
    gridAnual_calculateCellValue_acumulado(rowData) {
        let { mostrarCosteKg } = this.state;
        let coste = 0;
        let kgProcesados = 0;

        for (let i = 0; i < 12; i++) {
            if (rowData[i + "_coste"] != null && rowData[i + "_coste"] != "") {
                coste += rowData[i + "_coste"];
            }
            if (rowData[i + "_kgProcesados"] != null && rowData[i + "_kgProcesados"] != "") {
                kgProcesados += rowData[i + "_kgProcesados"];
            }
        }
        return mostrarCosteKg ? (kgProcesados > 0 ? coste / kgProcesados : null) : coste;
    }
    gridAnual_cellTemplate_acumulado(cellElement, cellInfo) {
        let { mostrarCosteKg } = this.state;
        let acumulado = cellInfo.value;
        let presupuestado = 0;

        let coste = 0;
        let kgProcesados = 0;

        for (let i = 0; i < 12; i++) {
            if (cellInfo.data[i + "_costePresupuestado"] != null && cellInfo.data[i + "_costePresupuestado"] != "") {
                coste += cellInfo.data[i + "_costePresupuestado"];
            }
            if (
                cellInfo.data[i + "_kgProcesadosPresupuestado"] != null &&
                cellInfo.data[i + "_kgProcesadosPresupuestado"] != ""
            ) {
                kgProcesados += cellInfo.data[i + "_kgProcesadosPresupuestado"];
            }
        }
        presupuestado = mostrarCosteKg ? (kgProcesados > 0 ? coste / kgProcesados : null) : coste;

        cellElement.text(cellInfo.text);
        cellElement.css(
            "color",
            presupuestado > acumulado ? "var(--success)" : presupuestado < acumulado ? "var(--danger)" : "inherit"
        );
    }
    gridAnual_calculateCellValue_acumuladoPresupuestado(rowData) {
        let { mostrarCosteKg } = this.state;
        let coste = 0;
        let kgProcesados = 0;
        for (let i = 0; i < 12; i++) {
            if (rowData[i + "_costePresupuestado"] != null && rowData[i + "_costePresupuestado"] != "") {
                coste += rowData[i + "_costePresupuestado"];
            }
            if (rowData[i + "_kgProcesadosPresupuestado"] != null && rowData[i + "_kgProcesadosPresupuestado"] != "") {
                kgProcesados += rowData[i + "_kgProcesadosPresupuestado"];
            }
        }
        return mostrarCosteKg ? (kgProcesados > 0 ? coste / kgProcesados : null) : coste;
    }
    grid_Summary_CustomizeText(itemInfo) {
        return itemInfo.value > 0 ? itemInfo.valueText : "";
    }

    //CHART
    customizeTooltip(info) {
        let { divisaSel, mostrarCosteKg, dxMultiView_index } = this.state;
        let decimales = dxMultiView_index > 1 && mostrarCosteKg ? 3 : 2;

        let html = $("<div />");
        html.append($("<div />").addClass("tooltip-header").text(info.argumentText));

        let body = $("<div />").addClass("tooltip-body").css("width", "300px");
        html.append(body);

        for (let i = 0; i < info.points.length; i++) {
            let row = $("<div />").addClass("tooltip-row");
            body.append(row);

            row.append($("<div />").addClass("series-name text-cut").text(info.points[i].seriesName));
            row.append(
                $("<div />")
                    .addClass("value-text")
                    .text(formatNumber(info.points[i].valueText, decimales, "currency", divisaSel))
            );
        }
        return {
            html: html.html(),
        };
    }

    //RADIO GROUP
    onValueChanged_radioGroup(e) {
        this.setState({ dxMultiView_index: e.value });
    }
    onValueChanged_radioGroup_costeKg(e) {
        this.setState({ mostrarCosteKg: e.value });
    }

    //BACK BUTTON
    getBackButton() {
        let { dxMultiView_index } = this.state;
        return (
            <Fragment>
                <div
                    id="backButton"
                    className="backButton dx-icon-chevronleft container_spanCentrado"
                    onClick={this.backButton_onClick}
                    hidden={dxMultiView_index === 0}
                />
                <Tooltip target="#backButton" position="bottom" showEvent="dxhoverstart" hideEvent="dxhoverend">
                    <div>{getTrad("volverLavanderia")}</div>
                </Tooltip>
            </Fragment>
        );
    }
    backButton_onClick() {
        this.setState({
            dxMultiView_index: 0,
            centroSel: {},
        });
    }

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidMount() {
        let _this = this;
        $.when(
            this.dataSource_tblEmpresasPolarier.load()
            //this.dataSource_spSelectControlPresupuestario_KgLavanderia.load()
        ).then(function () {
            _this.getDatos();
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let { fechaSel, centroSel } = this.state;
        let _this = this;

        if (fechaSel.getFullYear() !== prevState.fechaSel.getFullYear()) {
            this.loadPanel_show();
            //this.dataSource_spSelectControlPresupuestario_KgLavanderia.load().done(function () {
            _this.getDatos();
            //});
        } else if (fechaSel.getMonth() !== prevState.fechaSel.getMonth()) {
            if (Object.keys(centroSel).length == 0) {
                this.dxDataGrid_centros.refresh(true);
            }
        }

        setTimeout(() => {
            this.dxChart_BAI.render();
            this.dxPieChart_Costes_mensuales.render();
            this.dxChart_Costes_mensuales.render();
        }, 100);
    }
}

const mapStateToProps = (state) => ({
    lavanderia: state.Global.lavanderia,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
    showLoading: state.LoadPanel.show,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlPresupuestario);
