import React, { useEffect, useRef, useState } from "react";

import $ from "jquery";

import ArrayStore from "devextreme/data/array_store";
import Query from "devextreme/data/query";
import notify from "devextreme/ui/notify";
import { Column, Lookup } from "devextreme-react/data-grid";

import SharedDataGridHistorico from "../shared/SharedDataGridHistorico";
import SharedPopupHistorico from "../shared/SharedPopupHistorico";
import {
  convertClienteUtcToSinUtc,
  formatDate_parameter,
  getTrad,
} from "helpers";

const PopupHistoricoTipoFacturacionPrenda = ({
  visible,
  isEditing,
  prendaSel,
  tblTipoFacturacion,
  datasource_tblPrenda,
  getIsCerrado,
  onHiding,
  onSave,
}) => {
  const dataGridRef = useRef(null);

  const [prendaSelState, setPrendaSelStateState] = useState(prendaSel);

  useEffect(() => {
    if (visible) {
      dataGridRef?.current?.instance?.repaint();
      setPrendaSelStateState($.extend(true, {}, prendaSel));
    }
  }, [visible]);

  const handleOnSave = () => {
    const dataGridInstance = dataGridRef?.current?.instance;

    dataGridInstance.saveEditData().done(() => {
      setTimeout(() => {
        let isValid = true;

        const data = dataGridInstance.getVisibleRows();

        data.forEach((row) => (isValid = isValid && !row.isEditing));

        const tblPrenda_historico_idTipoFacturacion = data.map(({ data }) => {
          return {
            idTipoFacturacion: data.idTipoFacturacion,
            fecha: data.fecha,
            idPrenda: prendaSelState.idPrenda ?? 0,
          };
        });

        var tipoFact = Query(tblPrenda_historico_idTipoFacturacion)
          .filter(
            (tf) =>
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(tf.fecha))
              ) <=
              convertClienteUtcToSinUtc(
                new Date(formatDate_parameter(new Date()))
              )
          )
          .sortBy("fecha", true)
          .toArray()?.[0]?.idTipoFacturacion;

        if (!isValid) {
          notify({
            message: getTrad("errorIntroduccionDatos"),
            type: "error",
            displayTime: 1500,
            closeOnClick: true,
          });
        } else if (isEditing) {
          datasource_tblPrenda
            .store()
            .update(prendaSelState.idPrenda, {
              tipoFact,
              tblPrenda_historico_idTipoFacturacion,
            })
            .done(() =>
              datasource_tblPrenda.reload().done((data) => {
                onSave(data);
                notify({
                  message: getTrad("aviso_C_RegistroActualizado"),
                  type: "success",
                  displayTime: 1500,
                  closeOnClick: true,
                });
              })
            )
            .catch(() => {
              notify({
                message: getTrad("aviso_RegistroNoActualizado"),
                type: "error",
                displayTime: 1500,
                closeOnClick: true,
              });
            });
        } else {
          onSave(tblPrenda_historico_idTipoFacturacion, tipoFact);
        }
      }, 0);
    });
  };

  const validationRules = [
    {
      type: "required",
      message: getTrad("campoNecesario"),
    },
  ];

  const dataSource = new ArrayStore({
    data: prendaSelState?.tblPrenda_historico_idTipoFacturacion,
  });

  return (
    <SharedPopupHistorico
      visible={visible}
      title={getTrad("tipoFacturacion")}
      centerTitle={prendaSelState?.denominacion}
      onHiding={onHiding}
      onSave={handleOnSave}
    >
      <SharedDataGridHistorico
        ref={dataGridRef}
        dataSource={dataSource}
        keyExpr={"idTipoFacturacion"}
        visible={visible}
        errorMessage={"error_historicoTipoFacturacionAlmenosUnTipoFacturacion"}
        getIsCerrado={getIsCerrado}
      >
        <Column
          dataField={"idTipoFacturacion"}
          caption={getTrad("tipoFact")}
          alignment={"center"}
          dataType={"number"}
          validationRules={validationRules}
        >
          <Lookup
            dataSource={tblTipoFacturacion}
            valueExpr={"idTipoFacturacion"}
            displayExpr={"denominacion"}
          />
        </Column>
      </SharedDataGridHistorico>
    </SharedPopupHistorico>
  );
};

export default PopupHistoricoTipoFacturacionPrenda;
