import React from "react";
import { connect } from "react-redux";

import { Popup, ToolbarItem as ToolbarItemPopup } from "devextreme-react/popup";
import { getTrad, history } from "../../helpers";
import $ from "jquery";

class NoDataPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };

    this.dxPopup_noDatos_contentRender =
      this.dxPopup_noDatos_contentRender.bind(this);
    this.dxPopup_noDatos_buttonOnClick =
      this.dxPopup_noDatos_buttonOnClick.bind(this);

    this.aceptar_dxButton = {
      text: getTrad("aceptar"),
      type: "danger",
      onClick: this.dxPopup_noDatos_buttonOnClick,
    };
  }
  render() {
    let { visible } = this.state;
    return (
      <Popup
        height="auto"
        width="auto"
        minWidth={300}
        maxWidth={500}
        visible={visible}
        showTitle={false}
        deferRendering={false}
        contentRender={this.dxPopup_noDatos_contentRender}
        onContentReady={this.dxPopup_noDatos_onContentReady}
      >
        <ToolbarItemPopup
          toolbar="bottom"
          location="center"
          widget="dxButton"
          options={this.aceptar_dxButton}
        ></ToolbarItemPopup>
      </Popup>
    );
  }

  dxPopup_noDatos_contentRender(e) {
    let { texto } = this.props;
    return (
      <div style={{ fontSize: 14, whiteSpace: "pre-wrap" }}>
        <p>{texto}</p>
      </div>
    );
  }

  dxPopup_noDatos_onContentReady(e) {
    var html = e.component.content();
    $(html).css("padding-top", "20px");
    $(html).css("padding-bottom", "10px");
    $(html).css("text-align", "center");
  }

  dxPopup_noDatos_buttonOnClick() {
    let { isRedirect } = this.props;
    this.setState({ visible: false });

    if (isRedirect) history.push("/");
  }

  componentDidUpdate(prevProps, prevState) {
    let { visible } = this.props;
    if (prevProps.visible != visible) {
      this.setState({ visible: visible });
    }
  }
}

export default connect(null)(NoDataPopup);
