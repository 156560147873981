import React from "react";
import { connect } from "react-redux";
import { connectionConstants } from "../constants";

import { errorHandler, authHeader, getTrad } from "../helpers";

import List from "devextreme-react/list";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Toolbar, { Item as ItemToolbar } from "devextreme-react/toolbar";
import $ from "jquery";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import query from "devextreme/data/query";

class ListsCompaEnti extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items_entidades_compaSel: null,
      dxList_compaEnti_idCompaSel: [],
      dxList_compaEnti_idEntiSel: [],
    };

    this.dxListEntidades_REF = React.createRef();
    this.dxListCompañias_REF = React.createRef();

    //DATASOURCE
    this.datasource_CompaEnti_load = this.datasource_CompaEnti_load.bind(this);
    this.datasource_tblEntidad_postProcess =
      this.datasource_tblEntidad_postProcess.bind(this);

    //#region EVENTOS
    this.dxList_compañias_onContentReady =
      this.dxList_compañias_onContentReady.bind(this);
    this.dxList_compañias_onOptionChanged =
      this.dxList_compañias_onOptionChanged.bind(this);
    this.dxList_entidades_onOptionChanged =
      this.dxList_entidades_onOptionChanged.bind(this);
    this.dxList_entidades_onSelectionChanged =
      this.dxList_entidades_onSelectionChanged.bind(this);
    this.dxList_entidades_onOptionChanged =
      this.dxList_entidades_onOptionChanged.bind(this);

    this.dxButton_seleccionarTodasCompaEnti_onClick =
      this.dxButton_seleccionarTodasCompaEnti_onClick.bind(this);
    //#endregion
  }

  get dxListEntidades() {
    return this.dxListEntidades_REF.current.instance;
  }
  get dxListCompañias() {
    return this.dxListCompañias_REF.current.instance;
  }

  //filtroLavanderia
  //#region DATASOURCES
  datasource_tblCompañia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCompañia_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idCompañia", "denominacion"],
    filter: ["eliminado eq false"],
    sort: ["denominacion asc"],
  });

  datasource_tblCompañia_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { filtroLavanderia } = this.props;
    if (filtroLavanderia != null)
      request.params.idLavanderia = filtroLavanderia;
  }

  datasource_tblEntidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblEntidad_beforeSend(request);
      },
      version: 4,
    }),
    select: ["idCompañia", "idEntidad", "denominacion"],
    sort: ["denominacion asc"],
    postProcess: (data) => {
      return this.datasource_tblEntidad_postProcess(data);
    },
  });

  datasource_tblEntidad_postProcess(data) {
    let { todasEntisSeleccionables, compañiaSeleccionable, omitir_idsEntidad } =
      this.props;
    if (todasEntisSeleccionables)
      data.splice(0, 0, {
        idEntidad: 0,
        denominacion: getTrad("todas").toUpperCase(),
        idCompañia: 0,
      }); // Todas

    if (compañiaSeleccionable) {
      data.splice(1, 0, {
        idEntidad: -1,
        denominacion: getTrad("seleccionarCompañia").toUpperCase(),
        idCompañia: 0,
      }); // Selección de compañía
    }

    if (omitir_idsEntidad)
      data = data.filter((x) => omitir_idsEntidad.indexOf(x.idEntidad) === -1);
    return data;
  }

  datasource_tblEntidad_beforeSend(request) {
    request.headers = { ...authHeader() };

    let { filtroLavanderia } = this.props;
    if (filtroLavanderia != null)
      request.params.idLavanderia = filtroLavanderia;
  }

  datasource_CompaEnti = new DataSource({
    paginate: false,
    store: new CustomStore({
      load: () => {
        this.datasource_CompaEnti_load();
      },
    }),
  });

  datasource_CompaEnti_load() {
    var _deferred = $.Deferred();
    let _this = this;
    $.when(
      this.datasource_tblCompañia.load(),
      this.datasource_tblEntidad.load(),
    ).done(function (dataSource_compañias, dataSource_entidades) {
      let compañias = dataSource_compañias[0];
      let entidades = dataSource_entidades[0];

      //Se filtran las entidades con las que NO están ya subscritas a la lavanderia y se obtiene una lista de todas las entidades
      var compaEnti = [];
      var todasEntidades = [];

      $.each(compañias, function (index, compañia) {
        var compa = $.extend(true, {}, compañia);
        compa.tblEntidad = $.grep(entidades, function (entidad) {
          if (entidad.idEntidad == -1 && compa.idCompañia != 0) return entidad;
          else return entidad.idCompañia === compa.idCompañia;
        });

        compaEnti.push(compa);
        $.merge(
          todasEntidades,
          $.grep(compa.tblEntidad, function (item) {
            return item.idEntidad != -1;
          }),
        );
      });

      compaEnti = $.grep(compaEnti, function (compañia) {
        return compañia.tblEntidad.length > 0;
      });

      todasEntidades = query(todasEntidades).sortBy("denominacion").toArray();

      compaEnti.splice(0, 0, {
        idCompañia: -1,
        denominacion: getTrad("todas").toUpperCase(),
        tblEntidad: todasEntidades,
      });
      _this.setState({
        items_compaEnti: compaEnti,
        dxList_compaEnti_idCompaSel: [],
      });

      _deferred.resolve(compaEnti);
    });
    return _deferred.promise();
  }
  //#endregion

  //Props
  // dropDownBoxComponent -> Si va dentro de un dropDown
  // dxList_entidades_onSelectionChanged -> Evento al cambiar seleccion entidades
  // dxList_entidades_onOptionChanged

  render() {
    let {
      dropDownBoxComponent,
      todasCompaEntiSeleccionable,
      isMultiSelection,
    } = this.props;
    let {
      items_compaEnti,
      items_entidades_compaSel,
      dxList_compaEnti_idCompaSel,
      dxList_compaEnti_idEntiSel,
    } = this.state;

    return (
      <Box direction="col" width="100%" height="100%">
        <ItemBox ratio={1}>
          <Box direction="row" width="100%" height="100%">
            <ItemBox ratio={1}>
              <Box direction="col" width="100%" height="100%">
                <ItemBox baseSize={45}>
                  <div className="font-size">{getTrad("compañias")}</div>
                </ItemBox>
                <ItemBox ratio={1}>
                  <List
                    ref={this.dxListCompañias_REF}
                    dataSource={items_compaEnti}
                    height="100%"
                    pageLoadMode="scrollBottom"
                    keyExpr="idCompañia"
                    displayExpr="denominacion"
                    selectedItemKeys={dxList_compaEnti_idCompaSel}
                    searchEnabled={true}
                    searchExpr={"denominacion"}
                    selectionMode="single"
                    onContentReady={this.dxList_compañias_onContentReady}
                    onOptionChanged={this.dxList_compañias_onOptionChanged}
                  />
                </ItemBox>
              </Box>
            </ItemBox>
            <ItemBox baseSize={20}></ItemBox>
            <ItemBox ratio={1}>
              <Box direction="col" width="100%" height="100%">
                <ItemBox baseSize={45}>
                  <div className="font-size">{getTrad("entidades")}</div>
                </ItemBox>
                <ItemBox ratio={1}>
                  <List
                    ref={this.dxListEntidades_REF}
                    dataSource={items_entidades_compaSel}
                    height="100%"
                    pageLoadMode="scrollBottom"
                    keyExpr="idEntidad"
                    displayExpr="denominacion"
                    searchEnabled={true}
                    searchExpr={"denominacion"}
                    selectionMode={isMultiSelection ? "multiple" : "single"}
                    selectedItemKeys={dxList_compaEnti_idEntiSel}
                    onOptionChanged={this.dxList_entidades_onOptionChanged}
                    onSelectionChanged={(e) => {
                      this.dxList_entidades_onSelectionChanged(
                        e,
                        dropDownBoxComponent,
                      );
                    }}
                  />
                </ItemBox>
              </Box>
            </ItemBox>
          </Box>
        </ItemBox>
        <ItemBox baseSize={20} />
        {todasCompaEntiSeleccionable && (
          <ItemBox baseSize={36}>
            <Toolbar>
              <ItemToolbar
                location="after"
                widget="dxButton"
                options={this.dxButton_seleccionarTodasCompaEnti}
              />
            </Toolbar>
          </ItemBox>
        )}
      </Box>
    );
  }

  dxButton_seleccionarTodasCompaEnti = {
    text: "Seleccionar todas las compañías y entidades",
    type: "normal",
    onClick: (e) => {
      this.dxButton_seleccionarTodasCompaEnti_onClick(e, this);
    },
  };

  dxButton_seleccionarTodasCompaEnti_onClick(e, _this) {
    let { dropDownBoxComponent } = this.props;
    let { items_compaEnti } = this.state;
    if (this.props.dxList_entidades_onSelectionChanged) {
      let todasCompa = $.grep(items_compaEnti, function (item) {
        return item.idCompañia == -1;
      });
      this.setState({
        dxList_compaEnti_idCompaSel: [-1],
        items_entidades_compaSel: todasCompa[0].tblEntidad,
        dxList_compaEnti_idEntiSel: [],
      });
      this.props.dxList_entidades_onSelectionChanged(
        { idEntidad: null },
        dropDownBoxComponent,
      );
    }
  }

  dxList_compañias_onContentReady(e) {
    // SELECCIONA EL ITEM 0 POR DEFECTO
    e.component.selectItem(0);
  }

  dxList_compañias_onOptionChanged(e) {
    if (e.name === "selectedItemKeys") {
      let selectedItems = e.component.option("selectedItems");
      let tblEntidad =
        selectedItems.length > 0 ? selectedItems[0].tblEntidad : null;

      this.setState({
        items_entidades_compaSel: tblEntidad,
        dxList_compaEnti_idCompaSel: e.value,
      });
    }
  }

  dxList_entidades_onOptionChanged(e) {
    if (e.name === "selectedItemKeys") {
      this.setState({ dxList_compaEnti_idEntiSel: e.value });
      if (this.props.dxList_entidades_onOptionChanged)
        this.props.dxList_entidades_onOptionChanged(e);
    }
  }

  dxList_entidades_onSelectionChanged(e, dropDownBoxComponent) {
    let { addedItems, removedItems } = e;
    let { dxList_compaEnti_idCompaSel } = this.state;
    let { isMultiSelection } = this.props;

    if (addedItems.length > 0) {
      if (this.props.dxList_entidades_onSelectionChanged) {
        addedItems[0].idCompañia = dxList_compaEnti_idCompaSel[0];
        this.props.dxList_entidades_onSelectionChanged(
          addedItems[0],
          dropDownBoxComponent,
          "add",
        );
      }
    } else if (removedItems.length > 0) {
      if (this.props.dxList_entidades_onSelectionChanged && isMultiSelection) {
        removedItems[0].idCompañia = dxList_compaEnti_idCompaSel[0];
        this.props.dxList_entidades_onSelectionChanged(
          removedItems[0],
          dropDownBoxComponent,
          "remove",
        );
      }
    }
  }

  componentDidMount() {
    this.datasource_CompaEnti.load();
  }

  componentDidUpdate(prevProps, prevState) {
    let { dxList_compaEnti_idEntiSel } = this.state;
    let {
      omitir_idsEntidad,
      resetListas,
      idsEntidad_multiSeleccion,
      recargarListas,
    } = this.props;

    //Entidades que no saldrán en la lista(porque ya se han seleccionado por ejemplo)
    if (
      omitir_idsEntidad != null &&
      prevProps.omitir_idsEntidad != null &&
      prevProps.omitir_idsEntidad.toString() != omitir_idsEntidad.toString()
    ) {
      this.datasource_CompaEnti.reload();
    }

    // Para poder guardar la seleccion de entidades entre compañías
    if (
      idsEntidad_multiSeleccion &&
      (dxList_compaEnti_idEntiSel.toString() !=
        idsEntidad_multiSeleccion.toString() ||
        dxList_compaEnti_idEntiSel != prevState.dxList_compaEnti_idEntiSel)
    ) {
      this.setState({ dxList_compaEnti_idEntiSel: idsEntidad_multiSeleccion });
    }

    //Para resetear las listas (por ejemplo si están dentro de un popup que se cierra)
    if (resetListas != prevProps.resetListas && resetListas == true) {
      this.setState({ dxList_compaEnti_idEntiSel: [] });
      this.dxListEntidades.option("searchValue", "");
      this.dxListCompañias.option("searchValue", "");
      this.dxListCompañias.selectItem(0);
    }

    //Para recargar fuente de datos listas
    if (recargarListas != prevProps.recargarListas && recargarListas == true) {
      this.datasource_CompaEnti.load();
    }
  }
}

export default connect(null)(ListsCompaEnti);
