import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import DxForm, { Item } from "devextreme-react/form";
import { getTrad } from "helpers";

const RetencionComponent = forwardRef(({ formData, readOnly }, ref) => {
    const [componentDisabled, setComponentDisabled] = useState(formData?.idTipoFactura === 4);
    const [aplicaRetencion, setAplicaRetencion] = useState(formData.aplicaRetencion);

    useEffect(() => {
        if (formData.codigoRetencion != null && formData.codigoRetencion < 1) {
            formData.codigoRetencion = formData.codigoRetencion * 100;
            formRef.current.instance.repaint();
        }
    }, [formData]);

    useImperativeHandle(ref, () => ({
        checkIsValid,
        refresh: () => {
            formRef.current.instance.repaint();
        },
        shouldRetencionApply: () => {
            return aplicaRetencion;
        },
        setComponentDisabled,
    }));

    const checkIsValid = () => {
        return formRef?.current?.instance?.validate();
    };

    const formRef = useRef();

    const getText = (trad) => ({ text: getTrad(trad) });

    const colCount = 4;
    const multiplier = 3;
    const labels = {
        // idAdmCentroCoste: getText("centroCoste"),
        // idAdmElementoPEP: getText("elementoPep"),
    };

    const editorOptions = {
        tipoRetencion: {
            readOnly: true,
        },
        codigoRetencion: {
            readOnly: true,
        },
        aplicaRetencion: {
            value: aplicaRetencion,
            switchedOffText: "No aplica",
            switchedOnText: "Aplica",
        },
    };

    const onFieldDataChanged = (e) => {
        if (e.dataField === "aplicaRetencion") {
            setAplicaRetencion(e.value);
        }
    };
    return (
        <DxForm
            ref={formRef}
            formData={formData}
            onFieldDataChanged={onFieldDataChanged}
            colCount={2 * multiplier + 2}
            height={"100%"}
            width={"100%"}
            labelLocation={"top"}
            readOnly={readOnly}
            disabled={componentDisabled}
        >
            <Item
                dataField={"tipoRetencion"}
                label={{ text: "Tipo retención" }}
                editorOptions={editorOptions.tipoRetencion}
                disabled={!aplicaRetencion}
                colSpan={1 * multiplier}
            />
            <Item
                dataField={"codigoRetencion"}
                label={{ text: "Indicador retención" }}
                editorType={"dxNumberBox"}
                editorOptions={editorOptions.codigoRetencion}
                disabled={!aplicaRetencion}
                colSpan={1 * multiplier}
            />
            <Item
                dataField={"aplicaRetencion"}
                label={{ text: "Aplica retención" }}
                editorType={"dxSwitch"}
                editorOptions={editorOptions.aplicaRetencion}
                colSpan={2}
            />
        </DxForm>
    );
});

export default RetencionComponent;
