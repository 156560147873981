import { connectionConstants } from "constants";
import { Tooltip } from "devextreme-react";
import { Box, Item as ItemBox } from "devextreme-react/box";
import ODataContext from "devextreme/data/odata/context";
import { authHeader, errorHandler, patchRequestHandler } from "helpers";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import Posicion from "../components/Posicion";

const { Fragment } = require("react");
const { Component } = require("react");

class Maquina extends Component {
    constructor(props) {
        super(props);

        this.layout_REF = this.props.layout_REF;
    }

    render() {
        const { posiciones, maquina, svg, previsiones } = this.props;
        // Posiciones del area de procesado
        const pPosiciones = posiciones.filter((x) => x.idAreaLavanderia === 3);
        // Posiciones del area de recogida
        const rPosiciones = posiciones.filter((x) => x.idAreaLavanderia === 4);
        // Posiciones en otras areas
        const oPosiciones = posiciones.filter((x) => x.idAreaLavanderia !== 3 && x.idAreaLavanderia !== 4);

        // CONFIGURACION DE LAS TARJETAS
        // Se configuran según el tipo de máquina al que estan asignados
        // 1  = Calandra
        // 34 = Plegadora
        const display =
            maquina.idTipoMaquina === 1
                ? { svg: svg.calandra, col_md: 6, col_xl: 4 }
                : maquina.idTipoMaquina === 34
                ? { svg: svg.plegador, col_md: 6, col_xl: 2 }
                : { svg: svg.missing, col_md: 4, col_xl: 4 };

        const color =
            previsiones.length === posiciones.length ? "success" : previsiones.length > 0 ? "warning" : "secondary";

        return (
            <Col md={display.col_md} xl={display.col_xl} className="p-2">
                <Card className={`card-btm-border he-100 px-1 border-${color}`} style={{ borderRadius: "0.5rem" }}>
                    <CardHeader className="px-3">
                        <CardTitle
                            style={{
                                fontSize: "0.9rem",
                                margin: 0,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            <span style={{ fontWeight: 400 }}>{maquina.etiqueta} - </span>
                            {maquina.denominacion}
                        </CardTitle>
                    </CardHeader>
                    <CardBody className={`py-2 px-0 ${maquina.idTipoMaquina !== 34 ? "container_spanCentrado" : ""}`}>
                        {maquina.idTipoMaquina === 1 ? (
                            <this.Calandra
                                pPosiciones={pPosiciones}
                                rPosiciones={rPosiciones}
                                oPosiciones={oPosiciones}
                                display={display}
                            />
                        ) : maquina.idTipoMaquina === 34 ? (
                            <this.Plegador
                                pPosiciones={pPosiciones}
                                rPosiciones={rPosiciones}
                                oPosiciones={oPosiciones}
                                display={display}
                            />
                        ) : (
                            <this.MaquinaDefault
                                pPosiciones={pPosiciones}
                                rPosiciones={rPosiciones}
                                oPosiciones={oPosiciones}
                                display={display}
                            />
                        )}
                    </CardBody>
                </Card>
            </Col>
        );
    }

    // Aplica efectos de estilo cuando el raton pasa por encima
    onMouseEnter_posicion = (e, posicion, prevision) => {
        if (this.props.enablePosition) {
            const img = e.currentTarget.firstChild.childNodes[0];
            const remove = e.currentTarget.firstChild.childNodes[1];

            if (!prevision || prevision === null) {
                img.src = this.props.svg.personaHover;
            } else {
                img.style.opacity = 0.5;
                remove.style.opacity = 1;
            }
            this.layout_REF.setTooltipData(posicion, prevision, img.id);
        }
    };

    // Elimina los efectos de estilo cuando el raton pasa por encima
    onMouseLeave_posicion = (e, prevision) => {
        if (this.props.enablePosition) {
            const img = e.currentTarget.firstChild.childNodes[0];
            const remove = e.currentTarget.firstChild.childNodes[1];

            if (!prevision || prevision === null) {
                img.src = prevision && prevision !== null ? prevision.image : this.props.svg.persona;
            }
            img.style.opacity = 1;
            remove.style.opacity = 0;

            this.layout_REF.resetTooltipData();
        }
    };

    onClick_posicion = (e, posicion, prevision, data) => {
        if (this.props.enablePosition) {
            // Si "data" contiene información es por que un elemento ha sido
            // arrastrado hasta allí por lo tanto reemplaza la posicion
            // Si "data" no tiene información es por que se ha clickado
            // directamente en la posicion para restablecerla (dejarla vacía)
            if (data) {
                const idCuadrantePersonal = data.idCuadrantePersonal;
                const idPosicionNAreaLavanderiaNLavanderia = posicion.idPosicionNAreaLavanderiaNLavanderia;

                // Llama a una llamada custom para asegurarse de que no van
                // a haber varias personas asigandas al mismo puesto el mismo dia y tuno

                const context_tblPosicionNAreaLavanderiaNLavanderia = new ODataContext({
                    url: connectionConstants.WEB_API_CORE_ODATA_URL + "MyPolarier/RRHH/CuadrantePersonal",
                    entities: {
                        CambiarPosicion: {},
                    },
                    errorHandler: function (error) {
                        errorHandler(error, null);
                    },
                    beforeSend: function (request) {
                        request.headers = { ...authHeader() };
                        request.params = {
                            idCuadrantePersonal: idCuadrantePersonal,
                            idPosicionNAreaLavanderiaNLavanderia: idPosicionNAreaLavanderiaNLavanderia,
                            fecha: data.fecha,
                        };
                    },
                });

                context_tblPosicionNAreaLavanderiaNLavanderia.invoke("CambiarPosicion", {}, "PATCH").done((data) => {
                    this.layout_REF.notificarCambio(data);
                });
            } else if (prevision) {
                const id = prevision.idCuadrantePersonal;
                const payload = patchRequestHandler({
                    idPosicionNAreaLavanderiaNLavanderia: null,
                });

                const img = e.currentTarget.firstChild.childNodes[0];
                const remove = e.currentTarget.firstChild.childNodes[1];

                this.layout_REF.datasource_tblCuadrantePersonal
                    .store()
                    .update(id, payload)
                    .then((data) => {
                        this.layout_REF.notificarCambio([data]);

                        img.style.opacity = 1;
                        img.style.src = this.props.svg.personaHover;
                        remove.style.opacity = 0;
                    });
            }
        }
    };

    // Render personalizado para calandras
    Calandra = (props) => {
        const { maquina, svg, previsiones } = this.props;
        const { pPosiciones, rPosiciones, oPosiciones, display } = props;

        return (
            <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Row style={{ minHeight: 200, width: "100%" }}>
                        <Col
                            xs={2}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            {pPosiciones.map((x) => {
                                return (
                                    <this.PosicionMaquina
                                        key={x.idPosicionNAreaLavanderiaNLavanderia}
                                        svg={svg}
                                        posicion={x}
                                        prevision={previsiones.find(
                                            (y) =>
                                                x.idPosicionNAreaLavanderiaNLavanderia ===
                                                y.idPosicionNAreaLavanderiaNLavanderia
                                        )}
                                        style={{ margin: "5px auto" }}
                                    />
                                );
                            })}
                        </Col>
                        <Col
                            md={8}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            <div className="container_spanCentrado">
                                <img
                                    id={"maquina_" + maquina.idMaquina}
                                    src={display.svg}
                                    height="100%"
                                    style={{
                                        maxHeight: "85%",
                                        maxWidth: "85%",
                                        // paddingLeft: data.xPos + "%",
                                        // paddingRight: data.xPos + "%",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={2}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            {rPosiciones.map((x) => {
                                return (
                                    <this.PosicionMaquina
                                        key={x.idPosicionNAreaLavanderiaNLavanderia}
                                        svg={svg}
                                        posicion={x}
                                        prevision={previsiones.find(
                                            (y) =>
                                                x.idPosicionNAreaLavanderiaNLavanderia ===
                                                y.idPosicionNAreaLavanderiaNLavanderia
                                        )}
                                        style={{ margin: "5px auto" }}
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                    {oPosiciones.length > 0 && (
                        <div
                            style={{
                                margin: 5,
                                marginTop: 10,
                                border: "1px solid #5e5e5e",
                                borderRadius: 15,
                                padding: 10,
                            }}
                        >
                            <CardTitle>Otras posiciones</CardTitle>
                            <Row style={{ padding: "0 10px", justifyContent: "center" }}>
                                {oPosiciones.map((x) => {
                                    return (
                                        <Col
                                            key={x.idPosicionNAreaLavanderiaNLavanderia}
                                            xs={"auto"}
                                            style={{ padding: 0 }}
                                        >
                                            <this.PosicionMaquina
                                                svg={svg}
                                                posicion={x}
                                                prevision={previsiones.find(
                                                    (y) =>
                                                        x.idPosicionNAreaLavanderiaNLavanderia ===
                                                        y.idPosicionNAreaLavanderiaNLavanderia
                                                )}
                                                style={{ margin: "5px auto" }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    )}
                </div>
            </>
        );
    };

    // Render personalizado para plegadoras
    Plegador = (props) => {
        const { maquina, svg, previsiones } = this.props;
        const { pPosiciones, rPosiciones, oPosiciones, display } = props;

        return (
            <>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                        <Row style={{ justifyContent: "space-evenly" }}>
                            {pPosiciones.map((x) => {
                                return (
                                    <Col
                                        xs={"auto"}
                                        style={{ padding: 0 }}
                                        key={x.idPosicionNAreaLavanderiaNLavanderia}
                                    >
                                        <this.PosicionMaquina
                                            svg={svg}
                                            posicion={x}
                                            prevision={previsiones.find(
                                                (y) =>
                                                    x.idPosicionNAreaLavanderiaNLavanderia ===
                                                    y.idPosicionNAreaLavanderiaNLavanderia
                                            )}
                                            style={{ margin: "5px" }}
                                        />
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                    <div className="container_spanCentrado">
                        <img
                            id={"maquina_" + maquina.idMaquina}
                            src={display.svg}
                            height="100%"
                            style={{
                                maxHeight: 190,
                                maxWidth: "90%",
                                // paddingLeft: data.xPos + "%",
                                // paddingRight: data.xPos + "%",
                            }}
                        />
                    </div>
                    <Row style={{ justifyContent: "space-evenly" }}>
                        {rPosiciones.map((x) => {
                            return (
                                <Col xs={"auto"} style={{ padding: 0 }} key={x.idPosicionNAreaLavanderiaNLavanderia}>
                                    <this.PosicionMaquina
                                        svg={svg}
                                        posicion={x}
                                        prevision={previsiones.find(
                                            (y) =>
                                                x.idPosicionNAreaLavanderiaNLavanderia ===
                                                y.idPosicionNAreaLavanderiaNLavanderia
                                        )}
                                        style={{ margin: "5px" }}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    {oPosiciones.length > 0 && (
                        <div
                            style={{
                                margin: 5,
                                marginTop: 10,
                                border: "1px solid #5e5e5e",
                                borderRadius: 15,
                                padding: 10,
                            }}
                        >
                            <CardTitle>Otras posiciones</CardTitle>
                            <Row style={{ padding: "0 10px", justifyContent: "center" }}>
                                {oPosiciones.map((x) => {
                                    return (
                                        <Col
                                            key={x.idPosicionNAreaLavanderiaNLavanderia}
                                            xs={"auto"}
                                            style={{ padding: 0 }}
                                        >
                                            <this.PosicionMaquina
                                                svg={svg}
                                                posicion={x}
                                                prevision={previsiones.find(
                                                    (y) =>
                                                        x.idPosicionNAreaLavanderiaNLavanderia ===
                                                        y.idPosicionNAreaLavanderiaNLavanderia
                                                )}
                                                style={{ margin: "5px" }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    )}
                </div>
            </>
        );
    };

    // Render por defecto de otras máquinas
    MaquinaDefault = (props) => {
        const { maquina, svg, previsiones } = this.props;
        const { pPosiciones, rPosiciones, oPosiciones, display } = props;

        return (
            <>
                <div style={{ display: "flex", flexDirection: "column", width: "95%" }}>
                    <Row style={{ minHeight: 200, width: "100%" }}>
                        <Col
                            xs={2}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            {pPosiciones.map((x) => {
                                return (
                                    <this.PosicionMaquina
                                        key={x.idPosicionNAreaLavanderiaNLavanderia}
                                        svg={svg}
                                        posicion={x}
                                        prevision={previsiones.find(
                                            (y) =>
                                                x.idPosicionNAreaLavanderiaNLavanderia ===
                                                y.idPosicionNAreaLavanderiaNLavanderia
                                        )}
                                        style={{ margin: "5px" }}
                                    />
                                );
                            })}
                        </Col>
                        <Col
                            md={8}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            <div className="container_spanCentrado">
                                <img
                                    id={"maquina_" + maquina.idMaquina}
                                    src={display.svg}
                                    height={"100%"}
                                    style={{
                                        maxHeight: "85%",
                                        maxWidth: "85%",
                                        aspectRatio: "1/1",
                                        // paddingLeft: data.xPos + "%",
                                        // paddingRight: data.xPos + "%",
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={2}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: 0,
                            }}
                        >
                            {rPosiciones.map((x) => {
                                return (
                                    <this.PosicionMaquina
                                        key={x.idPosicionNAreaLavanderiaNLavanderia}
                                        svg={svg}
                                        posicion={x}
                                        prevision={previsiones.find(
                                            (y) =>
                                                x.idPosicionNAreaLavanderiaNLavanderia ===
                                                y.idPosicionNAreaLavanderiaNLavanderia
                                        )}
                                        style={{ margin: "5px" }}
                                    />
                                );
                            })}
                        </Col>
                    </Row>
                    {oPosiciones.length > 0 && (
                        <div
                            style={{
                                margin: 5,
                                marginTop: 10,
                                border: "1px solid #5e5e5e",
                                borderRadius: 15,
                                padding: 10,
                            }}
                        >
                            <CardTitle>Otras posiciones</CardTitle>
                            <Row style={{ padding: "0 10px", justifyContent: "center" }}>
                                {oPosiciones.map((x) => {
                                    return (
                                        <Col
                                            key={x.idPosicionNAreaLavanderiaNLavanderia}
                                            xs={"auto"}
                                            style={{ padding: 0 }}
                                        >
                                            <this.PosicionMaquina
                                                svg={svg}
                                                posicion={x}
                                                prevision={previsiones.find(
                                                    (y) =>
                                                        x.idPosicionNAreaLavanderiaNLavanderia ===
                                                        y.idPosicionNAreaLavanderiaNLavanderia
                                                )}
                                                style={{ margin: "5px" }}
                                            />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    )}
                </div>
            </>
        );
    };

    PosicionMaquina = (props) => {
        const { svg, posicion, prevision, style } = props;
        const { enablePosition } = this.props;
        return (
            <Posicion
                svg={svg}
                style={style}
                posicion={posicion}
                prevision={prevision}
                layout={this.layout_REF}
                enablePosition={enablePosition}
                onClick={this.onClick_posicion}
                onMouseEnter={this.onMouseEnter_posicion}
                onMouseLeave={this.onMouseLeave_posicion}
            />
        );
    };
}

export default Maquina;
