import { globalConstants, userConstants } from "../constants";
import { get_resolucion } from "../constants";
import { locale } from "devextreme/localization";

const lavanderias = JSON.parse(localStorage.getItem("lavanderias"));
const empresas = JSON.parse(localStorage.getItem("empresas"));
const idiomas = JSON.parse(localStorage.getItem("idiomas"));
const user = JSON.parse(localStorage.getItem("user"));
const aplicaciones = JSON.parse(localStorage.getItem("aplicaciones"));
const idUsuarioOriginal = JSON.parse(localStorage.getItem("idUsuarioOriginal"));

export default function reducer(
  state = {
    empresa: empresas ? empresas[0] : null,
    lavanderia:
      (aplicaciones &&
        (user.idAplicacionInicial === 1 || !user.idAplicacionInicial)) ||
      !aplicaciones
        ? user && lavanderias && user.idLavanderiaInicio
          ? lavanderias.find((x) => x.idLavanderia === user.idLavanderiaInicio)
          : user && lavanderias
            ? lavanderias[0]
            : null
        : null,
    idioma:
      user && idiomas ? idiomas.find((x) => x.idIdioma === user.idioma) : null,
    resolucion: get_resolucion(window.screen),
    formularioActivo: "",
    aplicacionActiva:
      user &&
      aplicaciones &&
      user.tblFormularioInicio &&
      user.tblFormularioInicio.tblAplicacion
        ? user.tblFormularioInicio.tblAplicacion
        : user && aplicaciones && user.idAplicacionInicial
          ? aplicaciones.find(
              (x) => x.idAplicacion === user.idAplicacionInicial,
            )
          : user && aplicaciones
            ? aplicaciones[0]
            : null,
    idUsuarioOriginal: idUsuarioOriginal,
  },
  action,
) {
  switch (action.type) {
    case globalConstants.SET_LAVANDERIA_SUCCESS:
      return {
        ...state,
        lavanderia: action.lavanderia,
      };
    case globalConstants.SET_EMPRESA_SUCCESS:
      return {
        ...state,
        empresa: action.empresa,
      };
    case globalConstants.SET_IDIOMA_SUCCESS:
      locale(action.idioma.codigo);
      return {
        ...state,
        idioma: action.idioma,
      };
    case globalConstants.SET_RESOLUCION_SUCCESS:
      return {
        ...state,
        resolucion: action.resolucion,
      };
    case globalConstants.SET_FORMULARIO_SUCCESS:
      return {
        ...state,
        formularioActivo: action.formularioActivo,
      };
    case globalConstants.SET_APLICACION_SUCCESS:
      return {
        ...state,
        aplicacionActiva: action.aplicacionActiva,
      };
    case globalConstants.SET_IDUSUARIO_ORIGINAL_SUCCESS:
      return {
        ...state,
        idUsuarioOriginal: action.idUsuarioOriginal,
      };
    case userConstants.LOGOUT:
      return {};
    default:
      if (state.idioma) {
        locale(state.idioma.codigo);
      }
      return state;
  }
}
