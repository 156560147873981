import React from "react";
import "./GestionPluses.scss";

import { connectionConstants } from "constants";
import { getTrad, authHeader, errorHandler } from "helpers";
import SelectorPersonas from "components/SelectorPersonas";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { currency_editorOptions } from "../DataGridPersonas";

import validationEngine from "devextreme/ui/validation_engine";
import { Button, NumberBox, TextArea } from "devextreme-react";
import Validator, { RequiredRule } from "devextreme-react/validator";
import Box, { Item as ItemBox } from "devextreme-react/box";
import Popup, { ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import { Column } from "devextreme-react/data-grid";

class PopupModificarPluses extends React.Component {
    constructor(props) {
        super(props);

        this.selectorColumns = [
            <Column
                dataField={"centroLav"}
                caption={getTrad("centro")}
                sortOrder={"asc"}
                sortIndex={0}
                minWidth={150}
            />,
            <Column
                dataField={"tipoTrabajo"}
                caption={getTrad("tipoTrabajo")}
                allowSorting={false}
                sortOrder={"asc"}
                sortIndex={1}
                minWidth={150}
            />,
        ];

        this.state = {
            dxPopup_visible: true,
            gestionPlusesData: {},
            showSelectorPlusError: false,
            showSelectorPersonasError: false,
        };
    }

    dataSource_tblConceptoNominaNNomina = new DataSource({
        store: new ODataStore({
            url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblConceptoNominaNNomina",
            key: ["idNomina", "idConceptoNomina"],
            errorHandler: function (error) {
                errorHandler(error);
            },
            beforeSend: (request) => this.dataSource_tblConceptoNominaNNomina_beforeSend(request),
            version: 4,
        }),
    });

    dataSource_tblConceptoNominaNNomina_beforeSend = (request) => {
        request.headers = { ...authHeader() };

        if (request.method === "POST") {
            request.url += "/IU_masivo_tblConceptoNominaNNomina";
        }
    };

    render() {
        const { dataSource, visible, onHiding } = this.props;
        const { showSelectorPersonasError } = this.state;

        return (
            <Popup
                title={"Modificar pluses"}
                showTitle={true}
                width={900}
                height={750}
                shading={true}
                visible={visible}
                closeOnOutsideClick={false}
                dragEnabled={false}
                onHiding={onHiding}
                onHidden={this.dxPopup_onHidden}
            >
                <div className="he-100">
                    <Box direction="col" width="100%" height="100%">
                        <ItemBox baseSize={165}>
                            <Box direction="row" height="100%">
                                <ItemBox ratio={1}>
                                    <this.renderSelectorPlus plus={"plusActividad"} />
                                </ItemBox>
                                <ItemBox baseSize={20} />
                                <ItemBox ratio={1}>
                                    <this.renderSelectorPlus plus={"plusProductividad"} />
                                </ItemBox>
                            </Box>
                        </ItemBox>
                        <ItemBox baseSize={20} />
                        <ItemBox baseSize={450}>
                            <div className="he-100">
                                {visible && (
                                    <SelectorPersonas
                                        containerClassName={`shadow-sm border border-radius${
                                            showSelectorPersonasError ? " border-danger" : ""
                                        }`}
                                        dataSource={dataSource ?? []}
                                        columns={this.selectorColumns}
                                        selectionMode={"multiple"}
                                        allowFiltering={true}
                                        stylingMode="underlined"
                                        onSelectionChanged={this.SelectorPersonas_onSelectionChanged}
                                    />
                                )}
                            </div>
                        </ItemBox>
                    </Box>
                </div>
                <ToolbarItem_popup location={"after"} toolbar={"bottom"}>
                    <Button text={getTrad("aplicar")} type="success" onClick={this.modificarPluses_aplicar} />
                </ToolbarItem_popup>
                <ToolbarItem_popup location={"after"} toolbar={"bottom"}>
                    <Button text={getTrad("cancelar")} onClick={onHiding} />
                </ToolbarItem_popup>
            </Popup>
        );
    }

    dxDataGrid_modificarPluses_onClick = () => {
        this.setState({ dxPopup_visible: true });
    };

    dxPopup_onHidden = () => {
        this.setState({ gestionPlusesData: {}, showSelectorPersonasError: false, showSelectorPlusError: false });
    };

    renderSelectorPlus = ({ plus }) => {
        const { gestionPlusesData, showSelectorPlusError } = this.state;
        return (
            <div
                className={`he-100 p-3 shadow-sm position-relative border border-radius ${
                    gestionPlusesData[plus] == null
                        ? `activarPlus${showSelectorPlusError ? " border-danger" : ""}`
                        : "border-success"
                }`}
                onClick={gestionPlusesData[plus] == null ? () => this.activarPlus_onClick(plus) : undefined}
            >
                {gestionPlusesData[plus] == null ? (
                    <div className="he-100 d-flex flex-column justify-content-center align-items-center position-absolute-0 font-size-xl font-weight-semiBold">
                        {getTrad(plus)}
                    </div>
                ) : (
                    <div
                        className={`he-100 d-flex flex-column justify-content-center align-items-stretch${
                            gestionPlusesData[plus] == null ? " plusBackground" : ""
                        }`}
                    >
                        <Button
                            name={plus}
                            icon="close"
                            stylingMode={"text"}
                            className="position-absolute-top-right"
                            disabled={gestionPlusesData[plus] == null}
                            onClick={this.dxButton_onClick}
                        />
                        <div className="dx-form mb-2">
                            <span className="dx-field-item-label-text">{getTrad(plus)}:</span>
                            <NumberBox
                                value={gestionPlusesData[plus]}
                                disabled={gestionPlusesData[plus] == null}
                                name={plus}
                                stylingMode={"underlined"}
                                {...currency_editorOptions}
                                onValueChanged={this.gestionPlusesData_onValueChanged}
                            >
                                <Validator validationGroup={"validationGroup_" + plus}>
                                    <RequiredRule />
                                </Validator>
                            </NumberBox>
                        </div>
                        <div className="dx-form">
                            <span className="dx-field-item-label-text">{getTrad("observaciones")}:</span>
                            <TextArea
                                value={gestionPlusesData[plus + "_observaciones"]}
                                disabled={gestionPlusesData[plus] == null}
                                name={plus + "_observaciones"}
                                stylingMode={"underlined"}
                                onValueChanged={this.gestionPlusesData_onValueChanged}
                            >
                                <Validator validationGroup={"validationGroup_" + plus}>
                                    <RequiredRule />
                                </Validator>
                            </TextArea>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    activarPlus_onClick = (plus) => {
        let { gestionPlusesData } = this.state;
        gestionPlusesData[plus] = 0;

        if (plus === "plusProductividad") this.dxNumberBox_plusProductividad?.focus();

        if (plus === "plusActividad") this.dxNumberBox_plusActividad?.focus();

        this.setState({ gestionPlusesData, showSelectorPlusError: false });
    };

    dxButton_onClick = (e) => {
        let { gestionPlusesData } = this.state;
        gestionPlusesData[e.component.option("name")] = undefined;
        gestionPlusesData[e.component.option("name") + "_observaciones"] = undefined;
        this.setState({ gestionPlusesData });
    };

    gestionPlusesData_onValueChanged = (e) => {
        let { gestionPlusesData } = this.state;
        gestionPlusesData[e.component.option("name")] = e.value;
        this.setState({ gestionPlusesData });
    };

    SelectorPersonas_onSelectionChanged = (data) => {
        let { gestionPlusesData } = this.state;
        gestionPlusesData.nominas = data.selectedRowsData.map((x) => {
            return {
                fechaFinNomina: x.fechaFinNomina,
                fechaInicioNomina: x.fechaInicioNomina,
                idPersona: x.idPersona,
            };
        });
        this.setState({ gestionPlusesData, showSelectorPersonasError: false });
    };

    modificarPluses_aplicar = (e) => {
        let { gestionPlusesData } = this.state;

        const conceptos = [];

        let plusProductividadValidation =
            gestionPlusesData.plusProductividad != null &&
            validationEngine.validateGroup("validationGroup_plusProductividad");
        let plusActividadValidation =
            gestionPlusesData.plusActividad != null && validationEngine.validateGroup("validationGroup_plusActividad");

        if (
            (gestionPlusesData.plusProductividad == null && gestionPlusesData.plusActividad == null) ||
            !gestionPlusesData.nominas ||
            gestionPlusesData.nominas.length === 0
        ) {
            this.setState({
                showSelectorPlusError:
                    gestionPlusesData.plusProductividad == null && gestionPlusesData.plusActividad == null,
                showSelectorPersonasError: !gestionPlusesData.nominas || gestionPlusesData.nominas.length === 0,
            });
            return;
        }

        // Plus de productividad
        if (gestionPlusesData.plusProductividad != null && plusProductividadValidation.isValid)
            conceptos.push(
                ...gestionPlusesData.nominas.map((nomina) => {
                    return {
                        ...nomina,
                        cantidad: null,
                        idConceptoNomina: 9,
                        precioUnitario: null,
                        importe: gestionPlusesData.plusProductividad,
                        observaciones: gestionPlusesData.plusProductividad_observaciones,
                    };
                })
            );

        // Plus de actividad
        if (gestionPlusesData.plusActividad != null && plusActividadValidation.isValid)
            conceptos.push(
                ...gestionPlusesData.nominas.map((nomina) => {
                    return {
                        ...nomina,
                        cantidad: null,
                        idConceptoNomina: 2,
                        precioUnitario: null,
                        importe: gestionPlusesData.plusActividad,
                        observaciones: gestionPlusesData.plusActividad_observaciones,
                    };
                })
            );

        if (
            (gestionPlusesData.plusProductividad == null || plusProductividadValidation.isValid) &&
            (gestionPlusesData.plusActividad == null || plusActividadValidation.isValid) &&
            conceptos.length > 0
        ) {
            const { datasource_gestionNominas_personas, onHiding } = this.props;
            e.component.option("disabled", true);
            this.dataSource_tblConceptoNominaNNomina
                .store()
                .insert(conceptos)
                .always(() => {
                    e.component.option("disabled", false);
                    onHiding();
                })
                .then(() => datasource_gestionNominas_personas.reload());
        }
    };
}

export default PopupModificarPluses;
