import React, { Fragment } from "react";
import { connectionConstants } from "../../../../../constants";

import {
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  patchRequestHandler,
} from "../../../../../helpers";

import notify from "devextreme/ui/notify";
import $ from "jquery";

//Datasource
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import List from "devextreme-react/list";
import { Button } from "devextreme-react";
import Box, { Item } from "devextreme-react/box";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

import PopupInsUpdCategoriaConvenio from "../PopupInsUpdCategoriaConvenio/index";

export default class ListCategoriaConvenio extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedItemKeys: [],
      categoriaConvenio_items: [],
      selectedCategoriaConvenio_item: null,
    };

    this.onSelectedItemKeysChange = this.onSelectedItemKeysChange.bind(this);

    this.onClick_addItem = this.onClick_addItem.bind(this);
    this.onClick_editItem = this.onClick_editItem.bind(this);
    this.onClick_removeItem = this.onClick_removeItem.bind(this);
    this.dxPopup_tblCategoriaConvenio_onHiding =
      this.dxPopup_tblCategoriaConvenio_onHiding.bind(this);
    this.dxPopup_tblCategoriaConvenio_onSave =
      this.dxPopup_tblCategoriaConvenio_onSave.bind(this);

    //DATASOURCE
    this.datasource_tblCategoriaConvenio_onLoading =
      this.datasource_tblCategoriaConvenio_onLoading.bind(this);

    this.itemRender = this.itemRender.bind(this);
    this.getTrad = this.getTrad.bind(this);
    this.reload_data = this.reload_data.bind(this);
  }

  array_traducciones = [];
  getTrad(traduccion) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.array_traducciones[codigoIdioma] == null)
      this.array_traducciones[codigoIdioma] = [];

    if (this.array_traducciones[codigoIdioma][traduccion] == null)
      this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

    return this.array_traducciones[codigoIdioma][traduccion];
  }

  get dxDataGrid_tblCategoriaInterna() {
    return this.dxDataGrid_tblCategoriaInterna_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblCategoriaConvenio = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblCategoriaConvenio",
      key: "idCategoriaConvenio",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaConvenio_beforeSend(request);
      },
      onLoading: (request) => {
        this.datasource_tblCategoriaConvenio_onLoading(request);
      },
      version: 4,
    }),
    sort: ["denominacion"],
    expand: ["tblCategoriaInterna($top=1;$select=idCategoriaInterna)"],
    postProcess: (data) => {
      return this.atasource_tblCategoriaConvenio_postProcess(data);
    },
  });

  datasource_tblCategoriaConvenio_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.todas = true;
  }

  datasource_tblCategoriaConvenio_onLoading(loadOptions) {
    let { idPaisSel, isOficina_checked } = this.props;
    let idPaisSel_filter =
      idPaisSel != null ? ["idPais", "=", idPaisSel] : ["1 eq 1"];

    if (loadOptions.filter)
      loadOptions.filter = [
        loadOptions.filter,
        "and",
        idPaisSel_filter,
        "and",
        ["isOficina", "=", isOficina_checked],
      ];
    else
      loadOptions.filter = [
        idPaisSel_filter,
        "and",
        ["isOficina", "=", isOficina_checked],
      ];
  }

  atasource_tblCategoriaConvenio_postProcess(data) {
    $.each(data, function (index, item) {
      item.denominacion = item.denominacion.toUpperCase();
    });
    return data;
  }
  //#endregion

  render() {
    let { idioma } = this.props;
    let {
      categoriaConvenio_items,
      selectedItemKeys,
      isVisible_popup_showEditarCategoriaConvenio,
      selectedCategoriaConvenio_item,
    } = this.state;
    return (
      <Fragment>
        <Box direction="col" height="100%" width="100%">
          <Item baseSize={30}>
            <Toolbar>
              <ToolbarItem location="before">
                <div className="font-size pb-2">{"Categorías convenio"}</div>
              </ToolbarItem>
              <ToolbarItem location="after">
                <Button
                  text={this.getTrad("añadir")}
                  icon="add"
                  onClick={this.onClick_addItem}
                />
              </ToolbarItem>
            </Toolbar>
          </Item>
          <Item baseSize={15} />
          <Item ratio={1}>
            <List
              height="100%"
              items={categoriaConvenio_items}
              keyExpr="idCategoriaConvenio"
              displayExpr="denominacion"
              selectionMode={"single"}
              selectedItemKeys={selectedItemKeys}
              itemRender={this.itemRender}
              onContentReady={this.onContentReady}
              onOptionChanged={this.onSelectedItemKeysChange}
            />
          </Item>
        </Box>
        <PopupInsUpdCategoriaConvenio
          isVisible_popup_showEditarCategoriaConvenio={
            isVisible_popup_showEditarCategoriaConvenio
          }
          onHiding={this.dxPopup_tblCategoriaConvenio_onHiding}
          idioma={idioma}
          selectedCategoriaConvenio_item={selectedCategoriaConvenio_item}
          onSaveCategoriaConvenio={this.dxPopup_tblCategoriaConvenio_onSave}
        />
      </Fragment>
    );
  }

  itemRender(item) {
    let has_categoriaInterna = item.tblCategoriaInterna.length > 0;
    return (
      <Box
        direction="row"
        align="space-around"
        crossAlign="stretch"
        height="100%"
        width="100%"
      >
        <Item ratio={1}>
          <div
            style={{ minHeight: 36 }}
            className={"font-size-md p-1 d-flex align-items-center"}
          >
            {item.denominacion}
          </div>
        </Item>
        <Item baseSize={70}>
          <Toolbar elementAttr={this.elementAttr_toolbarClass}>
            <ToolbarItem location="after">
              <div className="container_spanCentrado dxToolbar_opcion p-1">
                <div
                  className="font-size-lg icon_Edit center-svg"
                  onClick={this.onClick_editItem}
                />
              </div>
            </ToolbarItem>
            {!has_categoriaInterna && (
              <ToolbarItem location="after">
                <div className="container_spanCentrado dxToolbar_opcion p-1">
                  <div
                    className="font-size-lg icon_Cerrar center-svg center-svg"
                    onClick={this.onClick_removeItem}
                  />
                </div>
              </ToolbarItem>
            )}
          </Toolbar>
        </Item>
      </Box>
    );
  }

  elementAttr_toolbarClass = { class: "dxToolbar_opciones" };

  onContentReady(e) {
    $(e.element).find(".dx-scrollable-wrapper").css("padding-right", "7px");
  }

  onSelectedItemKeysChange(args) {
    if (args.name === "selectedItemKeys") {
      this.setState({
        selectedItemKeys: args.value,
      });
      this.props.onItemClick(args.value);
    }
  }

  onClick_addItem(e) {
    this.setState({
      isVisible_popup_showEditarCategoriaConvenio: true,
      selectedCategoriaConvenio_item: null,
    });
  }

  onClick_editItem(e) {
    let { categoriaConvenio_items, selectedItemKeys } = this.state;
    let idSel = selectedItemKeys.length > 0 ? selectedItemKeys[0] : null;

    let selectedCategoriaConvenio_item = $.grep(
      categoriaConvenio_items,
      function (item) {
        return item.idCategoriaConvenio == idSel;
      },
    );
    selectedCategoriaConvenio_item =
      selectedCategoriaConvenio_item.length > 0
        ? selectedCategoriaConvenio_item[0]
        : null;

    this.setState({
      isVisible_popup_showEditarCategoriaConvenio: true,
      selectedCategoriaConvenio_item: selectedCategoriaConvenio_item,
    });
  }

  onClick_removeItem(e) {
    let _this = this;
    dxMensajePregunta(getTrad("preg_RegistroEliminarCorto"), [
      [
        _this.getTrad("aceptar"),
        function (e) {
          let { selectedItemKeys } = _this.state;
          if (selectedItemKeys.length > 0) {
            _this.datasource_tblCategoriaConvenio
              .store()
              .remove(selectedItemKeys[0])
              .fail(function (error) {
                notify({
                  message: _this.getTrad("aviso_RegistroNoEliminado"),
                  type: "error",
                  displayTime: "1500",
                  closeOnClick: true,
                });
              })
              .done(function () {
                notify({
                  message: _this.getTrad("aviso_C_RegistroEliminado"),
                  type: "success",
                  displayTime: "1500",
                  closeOnClick: true,
                });
                _this.reload_data(_this);
              });
          }
        },
        "danger",
      ],
      [_this.getTrad("cancelar"), function () {}],
    ]);
  }

  dxPopup_tblCategoriaConvenio_onHiding(e) {
    this.setState({
      isVisible_popup_showEditarCategoriaConvenio: false,
      selectedCategoriaConvenio_item: null,
    });
  }

  dxPopup_tblCategoriaConvenio_onSave(fields, id) {
    let _this = this;
    let { idPaisSel, isOficina_checked } = this.props;
    if (id) {
      //UPD
      this.datasource_tblCategoriaConvenio
        .store()
        .update(id, patchRequestHandler(fields))
        .fail(function (error) {
          notify({
            message: _this.getTrad("aviso_RegistroNoActualizado"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        })
        .done(function () {
          notify({
            message: _this.getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
          _this.reload_data(_this, id);
          _this.setState({
            isVisible_popup_showEditarCategoriaConvenio: false,
            selectedCategoriaConvenio_item: null,
          });
        });
    } else {
      fields.idPais = idPaisSel;
      fields.isOficina = isOficina_checked;

      this.datasource_tblCategoriaConvenio
        .store()
        .insert(fields)
        .fail(function (error) {
          notify({
            message: _this.getTrad("aviso_RegistroNoInsertado"),
            type: "error",
            displayTime: "1500",
            closeOnClick: true,
          });
        })
        .done(function (idInserted) {
          notify({
            message: _this.getTrad("aviso_C_RegistroInsertado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
          _this.reload_data(_this, idInserted);
          _this.setState({
            isVisible_popup_showEditarCategoriaConvenio: false,
            selectedCategoriaConvenio_item: null,
          });
        });
    }
  }

  reload_data(_this, idSelected) {
    if (_this == null) _this = this;
    _this.datasource_tblCategoriaConvenio.load().done(function (items) {
      let idCategoriaConvenio =
        idSelected == null
          ? items.length > 0
            ? [items[0].idCategoriaConvenio]
            : []
          : [idSelected];
      _this.setState({
        categoriaConvenio_items: items,
        selectedItemKeys: idCategoriaConvenio,
      });
      _this.props.onItemClick(idCategoriaConvenio);
    });
  }

  componentDidMount() {
    this.reload_data(this);
  }

  componentDidUpdate(prevProps) {
    let { idPaisSel, isOficina_checked } = this.props;

    if (
      prevProps.idPaisSel !== idPaisSel ||
      prevProps.isOficina_checked !== isOficina_checked
    ) {
      this.reload_data(this);
    }
  }
}
