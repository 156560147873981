import { connectionConstants } from "../../../constants";
import { authHeader, errorHandler, getTrad } from "../../../helpers";
import { svg_edit, svg_remove } from "../../../styles/svg_iconos";

import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_prendaHuesped = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaHuesped",
      key: "idPrendaHuesped",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_prendaExtra = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPrendaExtra",
      key: "idPrendaExtra",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  var datasource_tblPrendaEjecutivo = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendaEjecutivo",
      key: "idPrendaEjecutivo",
      errorHandler: (error) => errorHandler(error, COMPONENT),
      beforeSend: (request) => {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
  });

  $("#PrendasHuesped #dxContainer").dxBox({
    direction: "col",
    align: "space-around",
    crossAlign: "stretch",
    height: "100%",
    items: [
      {
        ratio: 1,
        template: function (e, index, item) {
          item.append(
            $("<div />")
              .addClass("h3")
              .dxTabPanel({
                height: "100%",
                width: "100%",
                deferRendering: false,
                animationEnabled: true,
                swipeEnabled: false,
                items: [
                  {
                    title: getTrad("prendasHuesped"),
                    name: "prendasHuesped",
                    template: function (itemData, itemIndex, itemElement) {
                      return $("<div />")
                        .addClass("dxDataGrid_prendas")
                        .dxDataGrid({
                          //Datos
                          dataSource: datasource_prendaHuesped,
                          remoteOperations: true,
                          //Propiedades
                          hoverStateEnabled: true,
                          columnsAutoWidth: true,
                          width: "auto",
                          height: "100%",
                          headerFilter: {
                            visible: true,
                          },
                          filterRow: {
                            visible: true,
                            showAllText: getTrad("todos").toUpperCase(),
                            applyFilter: "auto",
                          },
                          searchPanel: {
                            visible: true,
                            width: 240,
                            placeholder: getTrad("busqueda"),
                          },
                          paging: {
                            pageSize: 50,
                          },
                          sorting: {
                            mode: "multiple",
                          },
                          pager: {
                            showPageSizeSelector: true,
                            allowedPageSizes: [20, 50, 100],
                            showInfo: true,
                          },
                          editing: {
                            mode: "popup",
                            allowUpdating: false,
                            allowAdding: true,
                            popup: {
                              title: "Prendas",
                              showTitle: true,
                              position: { at: "center", of: window },
                              height: "auto",
                              width: "50%",
                            },
                            form: {
                              labelLocation: "top",
                              height: "100%",
                              width: "99%",
                              colCountByScreen: {
                                xl: 4,
                                lg: 4,
                                md: 4,
                                sm: 2,
                                xs: 1,
                              },
                              screenByWidth: function (width) {
                                return width >= 1200
                                  ? "xl"
                                  : width >= 992
                                    ? "lg"
                                    : width >= 768
                                      ? "md"
                                      : width >= 576
                                        ? "sm"
                                        : "xs";
                              },
                              customizeItem: function (item) {
                                if (item.dataField === "peso") {
                                  item.label.text = getTrad("pesoUnitarioG");
                                }
                              },
                              items: [
                                {
                                  dataField: "denominacion",
                                  colSpan: 4,
                                },
                                {
                                  dataField: "precioLavado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioLavadoPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioTintoreria",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "incLavadoExpress",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "coste",
                                  colSpan: 2,
                                },
                                {
                                  dataField: "peso",
                                  colSpan: 1,
                                },
                              ],
                            },
                          },
                          columns: [
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (cellElement, cellInfo) {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_edit
                                  .clone()
                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", function () {
                                  cellInfo.component.editRow(cellInfo.rowIndex);
                                });

                                contenedor.appendTo(cellElement);
                              },
                              allowSearch: false,
                            },
                            {
                              dataField: "codigoPrendaHuesped",
                              caption: getTrad("codigo"),
                              width: 80,
                            },
                            {
                              dataField: "denominacion",
                              caption: getTrad("denominacion"),
                              minWidth: 310,
                              sortIndex: 1,
                              sortOrder: "asc",
                              allowHeaderFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                              ],
                            },
                            {
                              dataField: "precioLavado",
                              caption: getTrad("precioLavadoEjecutivo"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioPlanchado",
                              caption: getTrad("precioPlanchado"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioLavadoPlanchado",
                              caption: getTrad("precioLavPlanchado"),
                              width: "16%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioTintoreria",
                              caption: getTrad("precioTintoreria"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "incLavadoExpress",
                              caption: getTrad("incLavadoExpress"),
                              width: "11.5%",
                              alignment: "center",
                              dataType: "number",
                              format: "0# %",
                              editorOptions: {
                                format: "0# %",
                                min: 0,
                                max: 9999,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                              calculateDisplayValue: function (e) {
                                return e.incLavadoExpress * 0.01;
                              },
                            },
                            {
                              dataField: "coste",
                              caption: getTrad("costeManoObra"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                                // {
                                //     type: 'custom',
                                //     validationCallback: function (option) { if (option.value === 0) { return false; } else { return true; } }
                                // }
                              ],
                            },
                            {
                              dataField: "peso",
                              caption: getTrad("pesoUnitarioKG"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: "0.000 kg",
                              editorOptions: {
                                format: "#,##0",
                                min: 0,
                                max: 9999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              calculateDisplayValue: function (e) {
                                if (typeof e.peso === "undefined") return 0;
                                return parseFloat((e.peso * 0.001).toFixed(4));
                              },
                              validationRules: [
                                {
                                  type: "required",
                                },
                                // , {
                                //     type: 'custom',
                                //     validationCallback: function (option) { if (option.value === 0) { return false; } else { return true; } }
                                // }
                              ],
                            },
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (cellElement, cellInfo) {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_remove
                                  .clone()

                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", function () {
                                  cellInfo.component.deleteRow(
                                    cellInfo.rowIndex,
                                  );
                                });

                                contenedor.appendTo(cellElement);
                              },
                            },
                          ],
                          // EVENTOS
                          onEditorPrepared: function (options) {
                            if (
                              options.parentType === "dataRow" &&
                              options.dataField === "incLavadoExpress"
                            ) {
                              var valor = options.editorElement
                                .dxNumberBox("instance")
                                .option("value");
                              options.editorElement
                                .dxNumberBox("instance")
                                .option("value", valor * 0.01);
                            }
                          },
                          onToolbarPreparing: function (e) {
                            e.toolbarOptions.items.unshift(
                              {
                                location: "after",
                                widget: "dxButton",
                                locateInMenu: "auto",
                                showText: "inMenu",
                                template: function (item) {
                                  var container = $("<div></div>");

                                  $("<div id='btnLimpiarFiltro'>")
                                    .dxButton({
                                      icon: " icon_EliminarFiltros",
                                      onClick: function (options) {
                                        e.component.clearFilter();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnLimpiarFiltro",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("limpiarFiltro"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                              {
                                location: "after",
                                widget: "dxButton",
                                showText: "inMenu",
                                locateInMenu: "auto",
                                template: function (item) {
                                  var container = $("<div></div>");

                                  $("<div id='btnActualizar'>")
                                    .dxButton({
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onClick: function (options) {
                                        e.component.refresh();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnActualizar",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("actualizar"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                            );
                            $.each(
                              e.toolbarOptions.items,
                              function (index, item) {
                                switch (item.name) {
                                  case "addRowButton":
                                    item.location = "before";
                                    item.showText = "always";
                                    item.options.text = getTrad("añadirPrenda");
                                    item.options.icon = "plus";
                                    item.options.onClick = function () {
                                      e.component.addRow();
                                    };
                                    break;
                                  default:
                                    break;
                                }
                              },
                            );
                          },
                          onRowInserting: function (e) {
                            if (e.data.incLavadoExpress)
                              e.data.incLavadoExpress =
                                e.data.incLavadoExpress * 100;
                          },
                          onRowInserted: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroInsertado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowUpdating: function (e) {
                            if (e.newData.incLavadoExpress)
                              e.newData.incLavadoExpress =
                                e.newData.incLavadoExpress * 100;
                          },
                          onRowUpdated: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroActualizado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowRemoved: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroEliminado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          //Estilos
                          showColumnLines: false,
                          showRowLines: true,
                          rowAlternationEnabled: true,
                        });
                    },
                  },
                  {
                    title: getTrad("prendasExtra"),
                    name: "prendasExtra",
                    template: function (itemData, itemIndex, itemElement) {
                      return $("<div />")
                        .addClass("dxDataGrid_prendas")
                        .dxDataGrid({
                          //Datos
                          dataSource: datasource_prendaExtra,
                          remoteOperations: true,
                          //Propiedades
                          hoverStateEnabled: true,
                          columnsAutoWidth: true,
                          width: "auto",
                          height: "100%",
                          headerFilter: {
                            visible: true,
                          },
                          filterRow: {
                            visible: true,
                            showAllText: getTrad("todos").toUpperCase(),
                            applyFilter: "auto",
                          },
                          searchPanel: {
                            visible: true,
                            width: 240,
                            placeholder: getTrad("busqueda"),
                          },
                          paging: {
                            pageSize: 50,
                          },
                          sorting: {
                            mode: "multiple",
                          },
                          pager: {
                            showPageSizeSelector: true,
                            allowedPageSizes: [20, 50, 100],
                            showInfo: true,
                          },
                          editing: {
                            mode: "popup",
                            allowUpdating: false,
                            allowAdding: true,
                            popup: {
                              title: "Prendas",
                              showTitle: true,
                              position: { at: "center", of: window },
                              height: "auto",
                              width: "50%",
                            },
                            form: {
                              labelLocation: "top",
                              height: "100%",
                              width: "99%",
                              colCountByScreen: {
                                xl: 4,
                                lg: 4,
                                md: 4,
                                sm: 2,
                                xs: 1,
                              },
                              screenByWidth: function (width) {
                                return width >= 1200
                                  ? "xl"
                                  : width >= 992
                                    ? "lg"
                                    : width >= 768
                                      ? "md"
                                      : width >= 576
                                        ? "sm"
                                        : "xs";
                              },
                              customizeItem: function (item) {
                                if (item.dataField === "peso") {
                                  item.label.text = getTrad("pesoUnitarioG");
                                }
                              },
                              items: [
                                {
                                  dataField: "denominacion",
                                  colSpan: 4,
                                },
                                {
                                  dataField: "precioLavado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioLavadoPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioTintoreria",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "incLavadoExpress",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "coste",
                                  colSpan: 2,
                                },
                                {
                                  dataField: "peso",
                                  colSpan: 1,
                                },
                              ],
                            },
                          },
                          columns: [
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (cellElement, cellInfo) {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_edit
                                  .clone()
                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", function () {
                                  cellInfo.component.editRow(cellInfo.rowIndex);
                                });

                                contenedor.appendTo(cellElement);
                              },
                              allowSearch: false,
                            },
                            {
                              dataField: "codigoPrendaExtra",
                              caption: getTrad("codigo"),
                              width: 80,
                            },
                            {
                              dataField: "denominacion",
                              caption: getTrad("denominacion"),
                              minWidth: 310,
                              sortIndex: 1,
                              sortOrder: "asc",
                              allowHeaderFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                              ],
                            },
                            {
                              dataField: "precioLavado",
                              caption: getTrad("precioLavadoEjecutivo"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioPlanchado",
                              caption: getTrad("precioPlanchado"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioLavadoPlanchado",
                              caption: getTrad("precioLavPlanchado"),
                              width: "16%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioTintoreria",
                              caption: getTrad("precioTintoreria"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "incLavadoExpress",
                              caption: getTrad("incLavadoExpress"),
                              width: "11.5%",
                              alignment: "center",
                              dataType: "number",
                              format: "0# %",
                              editorOptions: {
                                format: "0# %",
                                min: 0,
                                max: 9999,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                              calculateDisplayValue: function (e) {
                                return e.incLavadoExpress * 0.01;
                              },
                            },
                            {
                              dataField: "coste",
                              caption: getTrad("costeManoObra"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                                // {
                                //     type: 'custom',
                                //     validationCallback: function (option) { if (option.value === 0) { return false; } else { return true; } }
                                // }
                              ],
                            },
                            {
                              dataField: "peso",
                              caption: getTrad("pesoUnitarioKG"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: "0.000 kg",
                              editorOptions: {
                                format: "#,##0",
                                min: 0,
                                max: 9999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              calculateDisplayValue: function (e) {
                                if (typeof e.peso === "undefined") return 0;
                                return parseFloat((e.peso * 0.001).toFixed(4));
                              },
                              validationRules: [
                                {
                                  type: "required",
                                },
                                // , {
                                //     type: 'custom',
                                //     validationCallback: function (option) { if (option.value === 0) { return false; } else { return true; } }
                                // }
                              ],
                            },
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (cellElement, cellInfo) {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_remove
                                  .clone()

                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", function () {
                                  cellInfo.component.deleteRow(
                                    cellInfo.rowIndex,
                                  );
                                });

                                contenedor.appendTo(cellElement);
                              },
                            },
                          ],
                          // EVENTOS
                          onEditorPrepared: function (options) {
                            if (
                              options.parentType === "dataRow" &&
                              options.dataField === "incLavadoExpress"
                            ) {
                              var valor = options.editorElement
                                .dxNumberBox("instance")
                                .option("value");
                              options.editorElement
                                .dxNumberBox("instance")
                                .option("value", valor * 0.01);
                            }
                          },
                          onToolbarPreparing: function (e) {
                            e.toolbarOptions.items.unshift(
                              {
                                location: "after",
                                widget: "dxButton",
                                locateInMenu: "auto",
                                showText: "inMenu",
                                template: function (item) {
                                  var container = $("<div></div>");

                                  $("<div id='btnLimpiarFiltro'>")
                                    .dxButton({
                                      icon: " icon_EliminarFiltros",
                                      onClick: function (options) {
                                        e.component.clearFilter();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnLimpiarFiltro",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("limpiarFiltro"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                              {
                                location: "after",
                                widget: "dxButton",
                                showText: "inMenu",
                                locateInMenu: "auto",
                                template: function (item) {
                                  var container = $("<div></div>");

                                  $("<div id='btnActualizar'>")
                                    .dxButton({
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onClick: function (options) {
                                        e.component.refresh();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnActualizar",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: function (
                                        contentElement,
                                      ) {
                                        contentElement.html(
                                          getTrad("actualizar"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                            );
                            $.each(
                              e.toolbarOptions.items,
                              function (index, item) {
                                switch (item.name) {
                                  case "addRowButton":
                                    item.location = "before";
                                    item.showText = "always";
                                    item.options.text = getTrad("añadirPrenda");
                                    item.options.icon = "plus";
                                    item.options.onClick = function () {
                                      e.component.addRow();
                                    };
                                    break;
                                  default:
                                    break;
                                }
                              },
                            );
                          },
                          onRowInserting: function (e) {
                            if (e.data.incLavadoExpress)
                              e.data.incLavadoExpress =
                                e.data.incLavadoExpress * 100;
                          },
                          onRowInserted: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroInsertado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowUpdating: function (e) {
                            if (e.newData.incLavadoExpress)
                              e.newData.incLavadoExpress =
                                e.newData.incLavadoExpress * 100;
                          },
                          onRowUpdated: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroActualizado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowRemoved: function (e) {
                            notify({
                              message: getTrad("aviso_C_RegistroEliminado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          //Estilos
                          showColumnLines: false,
                          showRowLines: true,
                          rowAlternationEnabled: true,
                        });
                    },
                  },
                  {
                    title: getTrad("prendasEjecutivo"),
                    name: "prendasEjecutivo",
                    template: (itemData, itemIndex, itemElement) => {
                      return $("<div />")
                        .addClass("dxDataGrid_prendas")
                        .dxDataGrid({
                          dataSource: datasource_tblPrendaEjecutivo,
                          remoteOperations: true,
                          hoverStateEnabled: true,
                          columnsAutoWidth: true,
                          width: "auto",
                          height: "100%",
                          headerFilter: { visible: true },
                          filterRow: {
                            visible: true,
                            showAllText: getTrad("todos").toUpperCase(),
                            applyFilter: "auto",
                          },
                          searchPanel: {
                            visible: true,
                            width: 240,
                            placeholder: getTrad("busqueda"),
                          },
                          paging: { pageSize: 50 },
                          sorting: { mode: "multiple" },
                          pager: {
                            showPageSizeSelector: true,
                            allowedPageSizes: [20, 50, 100],
                            showInfo: true,
                          },
                          editing: {
                            mode: "popup",
                            allowUpdating: false,
                            allowAdding: true,
                            popup: {
                              title: "Prendas",
                              showTitle: true,
                              position: { at: "center", of: window },
                              height: "auto",
                              width: "50%",
                            },
                            form: {
                              labelLocation: "top",
                              height: "100%",
                              width: "99%",
                              colCountByScreen: {
                                xl: 4,
                                lg: 4,
                                md: 4,
                                sm: 2,
                                xs: 1,
                              },
                              screenByWidth: function (width) {
                                return width >= 1200
                                  ? "xl"
                                  : width >= 992
                                    ? "lg"
                                    : width >= 768
                                      ? "md"
                                      : width >= 576
                                        ? "sm"
                                        : "xs";
                              },
                              customizeItem: function (item) {
                                if (item.dataField === "peso") {
                                  item.label.text = getTrad("pesoUnitarioG");
                                }
                              },
                              items: [
                                {
                                  dataField: "denominacion",
                                  colSpan: 4,
                                },
                                {
                                  dataField: "precioLavadoDoblado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioLavadoVaporizado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "precioLavadoSecadoPlanchado",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "incLavadoExpress",
                                  colSpan: 1,
                                },
                                {
                                  dataField: "coste",
                                  colSpan: 2,
                                },
                                {
                                  dataField: "peso",
                                  colSpan: 1,
                                },
                              ],
                            },
                          },
                          columns: [
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: (cellElement, cellInfo) => {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_edit
                                  .clone()
                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", () => {
                                  cellInfo.component.editRow(cellInfo.rowIndex);
                                });

                                contenedor.appendTo(cellElement);
                              },
                              allowSearch: false,
                            },
                            {
                              dataField: "codigoPrendaEjecutivo",
                              caption: getTrad("codigo"),
                              width: 80,
                            },
                            {
                              dataField: "denominacion",
                              caption: getTrad("denominacion"),
                              minWidth: 150,
                              sortIndex: 1,
                              sortOrder: "asc",
                              allowHeaderFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                              ],
                            },
                            {
                              dataField: "precioLavadoDoblado",
                              caption: getTrad("precioLavadoDoblado"),
                              width: "11%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioLavadoVaporizado",
                              caption: getTrad("precioLavadoVaporizado"),
                              width: "12%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioPlanchado",
                              caption: getTrad("precioPlanchado"),
                              width: "8%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "precioLavadoSecadoPlanchado",
                              caption: getTrad("precioLavadoSecadoPlanchado"),
                              width: "15%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999.9999,
                                step: 0,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                            },
                            {
                              dataField: "incLavadoExpress",
                              caption: getTrad("incLavadoExpress"),
                              width: "9%",
                              alignment: "center",
                              dataType: "number",
                              format: "0# %",
                              editorOptions: {
                                format: "0# %",
                                min: 0,
                                max: 9999,
                              },
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              value: 0,
                              calculateDisplayValue: function (e) {
                                return e.incLavadoExpress * 0.01;
                              },
                            },
                            {
                              dataField: "coste",
                              caption: getTrad("costeManoObra"),
                              width: "11%",
                              alignment: "center",
                              dataType: "number",
                              format: {
                                style: "currency",
                                currency: LAVANDERIA.moneda,
                                minimumFractionDigits: 2,
                              },
                              editorOptions: {
                                format: {
                                  style: "currency",
                                  currency: LAVANDERIA.moneda,
                                  minimumFractionDigits: 2,
                                },
                                min: 0,
                                max: 99999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              validationRules: [
                                {
                                  type: "required",
                                },
                              ],
                            },
                            {
                              dataField: "peso",
                              caption: getTrad("pesoUnitarioKG"),
                              width: "8%",
                              alignment: "center",
                              dataType: "number",
                              format: "0.000 kg",
                              editorOptions: {
                                format: "#,##0",
                                min: 0,
                                max: 9999,
                                step: 0,
                              },
                              value: 0,
                              allowHeaderFiltering: false,
                              allowFiltering: false,
                              calculateDisplayValue: function (e) {
                                if (typeof e.peso === "undefined") return 0;
                                return parseFloat((e.peso * 0.001).toFixed(4));
                              },
                              validationRules: [
                                {
                                  type: "required",
                                },
                              ],
                            },
                            {
                              caption: " ",
                              width: "30px",
                              alignment: "center",
                              cssClass: "p-0",
                              cellTemplate: function (cellElement, cellInfo) {
                                var contenedor = $("<div/>").addClass(
                                  "container_spanCentrado",
                                );
                                svg_remove
                                  .clone()

                                  .css("height", "25px")
                                  .css("width", "25px")
                                  .css("cursor", "pointer")
                                  .appendTo(contenedor);

                                cellElement.on("click", function () {
                                  cellInfo.component.deleteRow(
                                    cellInfo.rowIndex,
                                  );
                                });

                                contenedor.appendTo(cellElement);
                              },
                            },
                          ],
                          onEditorPrepared: (options) => {
                            if (
                              options.parentType === "dataRow" &&
                              options.dataField === "incLavadoExpress"
                            ) {
                              var valor = options.editorElement
                                .dxNumberBox("instance")
                                .option("value");
                              options.editorElement
                                .dxNumberBox("instance")
                                .option("value", valor * 0.01);
                            }
                          },
                          onToolbarPreparing: (e) => {
                            e.toolbarOptions.items.unshift(
                              {
                                location: "after",
                                widget: "dxButton",
                                locateInMenu: "auto",
                                showText: "inMenu",
                                template: (item) => {
                                  var container = $("<div></div>");

                                  $("<div id='btnLimpiarFiltro'>")
                                    .dxButton({
                                      icon: " icon_EliminarFiltros",
                                      onClick: (options) => {
                                        e.component.clearFilter();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnLimpiarFiltro",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: (contentElement) => {
                                        contentElement.html(
                                          getTrad("limpiarFiltro"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                              {
                                location: "after",
                                widget: "dxButton",
                                showText: "inMenu",
                                locateInMenu: "auto",
                                template: (item) => {
                                  var container = $("<div></div>");

                                  $("<div id='btnActualizar'>")
                                    .dxButton({
                                      icon: "refresh",
                                      text: getTrad("actualizar"),
                                      onClick: (options) => {
                                        e.component.refresh();
                                      },
                                    })
                                    .appendTo(container);
                                  $("<div>")
                                    .dxTooltip({
                                      target: "#btnActualizar",
                                      height: "42px",
                                      showEvent: "dxhoverstart",
                                      hideEvent: "dxhoverend",
                                      position: "bottom",
                                      contentTemplate: (contentElement) => {
                                        contentElement.html(
                                          getTrad("actualizar"),
                                        );
                                      },
                                      animation: {
                                        show: {
                                          type: "pop",
                                          from: {
                                            scale: 0.1,
                                            opacity: 0,
                                          },
                                          to: {
                                            opacity: 1,
                                            scale: 1,
                                          },
                                        },
                                        hide: {
                                          type: "pop",
                                          from: {
                                            scale: 1,
                                            opacity: 1,
                                          },
                                          to: {
                                            opacity: 0,
                                            scale: 0.1,
                                          },
                                        },
                                      },
                                    })
                                    .appendTo(container);

                                  return container;
                                },
                              },
                            );
                            $.each(e.toolbarOptions.items, (index, item) => {
                              switch (item.name) {
                                case "addRowButton":
                                  item.location = "before";
                                  item.showText = "always";
                                  item.options.text = getTrad("añadirPrenda");
                                  item.options.icon = "plus";
                                  item.options.onClick = () => {
                                    e.component.addRow();
                                  };
                                  break;
                                default:
                                  break;
                              }
                            });
                          },
                          onRowInserting: (e) => {
                            if (e.data.incLavadoExpress)
                              e.data.incLavadoExpress =
                                e.data.incLavadoExpress * 100;
                          },
                          onRowInserted: (e) => {
                            notify({
                              message: getTrad("aviso_C_RegistroInsertado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowUpdating: (e) => {
                            if (e.newData.incLavadoExpress)
                              e.newData.incLavadoExpress =
                                e.newData.incLavadoExpress * 100;
                          },
                          onRowUpdated: (e) => {
                            notify({
                              message: getTrad("aviso_C_RegistroActualizado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          onRowRemoved: (e) => {
                            notify({
                              message: getTrad("aviso_C_RegistroEliminado"),
                              type: "success",
                              displayTime: "600",
                              closeOnClick: true,
                            });
                          },
                          showColumnLines: false,
                          showRowLines: true,
                          rowAlternationEnabled: true,
                        });
                    },
                  },
                ],
              }),
          );
        },
      },
    ],
  });

  deferred.resolve();
  return deferred.promise();
}
