import { FileUploader, Gallery, Popup, TextBox } from "devextreme-react";
import Box, { Item } from "devextreme-react/box";

import { authHeader, getTrad, patchRequestHandler } from "helpers";
import React, { createRef } from "react";
import { dataSource_tblSolicitudGestoria, datasource_tblDocumento, datasource_tblPersona } from "../datasources";
import FotoPerfil from "../PopupDatosPersona/FotoPerfil";
import LottieIcon from "components/LottieIcon";
import { Validator, AsyncRule, NumericRule, PatternRule } from "devextreme-react/validator";
import ODataContext from "devextreme/data/odata/context";
import { connectionConstants } from "constants";
import notify from "devextreme/ui/notify";

class PopUpContrato extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datosPersonaSel: null,
      dataField_archivoSel: null,
      codigoGestoriaSet: null,
      archivoSel: null,
      showPopupPDF: false,
    };
    this.textBoxCodigoGestoria = createRef();
  }

  componentDidUpdate(prevProps) {
    const { idPersonaSel, visible } = this.props;

    if (visible && idPersonaSel && idPersonaSel !== prevProps.idPersonaSel) {
      datasource_tblPersona(idPersonaSel)
        .load()
        .done((data) => {
          this.setState({ datosPersonaSel: data[0] });
        });
    }

    if (!visible && prevProps.visible) {
      setTimeout(() => {
        this.setState({ datosPersonaSel: null });
      }, 500);
    }
  }

  setCodigoGestoria = (e) => {
    let codigo = e.value.length > 0 ? e.value.padStart(6, "0") : null;
    this.setState({ codigoGestoriaSet: codigo });
  };

  hidePopup = () => {
    const { onValueChange } = this.props;
    onValueChange({ idPersonaSel: null, showPopupContrato: false });
    this.setState({
      datosPersonaSel: null,
      codigoGestoriaSet: null,
      archivoSel: null,
    });
  };

  pdf_onLoadStarted = (e) => {
    let personaSel = { ...this.state.personaSel };

    let reader = new FileReader(),
      _this = this;

    if (e.file.size > 5242880) {
      notify({
        message: _this.getTrad("subirArchivo"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    } else {
      reader.onloadend = function () {
        if (reader.result !== "") {
          let fileSplit = reader.result.split(",");
          let extension = e.file.type;
          let base64 = fileSplit[1];

          const denominacion = e.file.name;

          function insertarDocumento() {
            let addedItem = {
              denominacion,
              documento: base64,
              extension: extension,
              fecha: new Date(),
              fechaModificacion: new Date(),
              idPersona: personaSel.idPersona,
              idCarpetaDocumentos: null,
            };
            e.component.option({
              value: [],
            });

            _this.setState({ archivoSel: addedItem });
          }

          insertarDocumento();
        }
      };
      reader.readAsDataURL(e.file);
    }
    e.request.abort();
  };

  visualizarDocumento = () => {
    this.setState({ showPopupPDF: true });
  };

  hidePopupPDF = () => {
    this.setState({ showPopupPDF: false });
  };

  dxGallery_itemRender = (itemData) => {
    if (itemData.extension.split("/")[0] === "image") {
      return (
        <img
          className="image"
          style={{
            backgroundImage: "url(data:" + itemData.extension + ";base64," + itemData.documento,
          }}
        />
      );
    } else if (itemData.extension.split("/")[1] === "pdf") {
      return (
        <object
          toolbar="0"
          width="100%"
          height="100%"
          data={"data:" + itemData.extension + ";base64," + itemData.documento}
        />
      );
    }
  };

  confirmar = async () => {
    const { codigoGestoriaSet, archivoSel, datosPersonaSel } = this.state;
    const { solicitudGestoriaSel, estadoSel_aceptado, onValueChange } = this.props;
    const { idSolicitudGestoria } = solicitudGestoriaSel;

    if (codigoGestoriaSet != null) {
      const isValid = await this.validateAsync_codigoGestoria({ value: codigoGestoriaSet });
      if (!isValid) {
        notify({
          message: "Código de gestoria inválido.",
          type: "error",
          displayTime: "1500",
          closeOnClick: true,
        });
        return;
      }
    } else if (codigoGestoriaSet == null && datosPersonaSel?.codigoGestoria == null) {
      notify({
        message: "Por favor, inserta un código de gestoría.",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      return;
    }

    const idPersonaSel = datosPersonaSel?.idPersona;

    const updateSolicitudGestoria = () => {
      const jsonData = patchRequestHandler({ idEstadoSolicitudGestoria: 3, fecha_validacion: new Date() });
      dataSource_tblSolicitudGestoria()
        .store()
        .update(idSolicitudGestoria, jsonData)
        .done(() => {
          dataSource_tblSolicitudGestoria(estadoSel_aceptado)
            .load()
            .done((data) => {
              onValueChange({ dataSource_tblSolicitudGestoria: data });
            });
          notify({
            message: "Solicitud de gestoría actualizada correctamente.",
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
          this.hidePopup();
        });
    };

    if (archivoSel != null) {
      const documento = {
        fecha: new Date(),
        fechaModificacion: new Date(),
        denominacion: archivoSel.denominacion,
        documento: archivoSel.documento,
        extension: archivoSel.extension,
        idPersona: idPersonaSel,
        idCarpetaDocumentos: 2,
        nuevo: true,
        notificacion: true,
        firmado: false,
      };

      datasource_tblDocumento
        .store()
        .insert(documento)
        .done(() => {
          if (codigoGestoriaSet != null) {
            const jsonDataPersona = patchRequestHandler({ codigoGestoria: codigoGestoriaSet });
            datasource_tblPersona().store().update(idPersonaSel, jsonDataPersona).done(updateSolicitudGestoria);
          } else {
            updateSolicitudGestoria();
          }
        });
    } else {
      notify({
        message: "Por favor, adjunta un contrato.",
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
    }
  };

  render() {
    const { visible } = this.props;
    const { datosPersonaSel, codigoGestoriaSet, archivoSel, showPopupPDF } = this.state;
    const codigoGestoria = datosPersonaSel?.codigoGestoria;

    return (
      <>
        <Popup
          showTitle={true}
          title={getTrad("datosPersonales")}
          visible={visible}
          showCloseButton={true}
          closeOnOutsideClick={false}
          width={"35%"}
          minWidth={400}
          height={"70%"}
          onHiding={this.hidePopup}
          className="px-1 d-flex flex-column "
        >
          <div
            style={datosPersonaSel != null ? { height: "0px" } : {}}
            className="he-100 d-flex flex-column justify-content-center align-items-center "
          >
            <LottieIcon icon="loading_circular" height={"50%"} width={"50%"} />
            <h4 style={datosPersonaSel != null ? { display: "none" } : {}}>{getTrad("cargaDatosPersona")}</h4>
          </div>
          <Box width={"100%"} height={"100%"}>
            <Item ratio={1}>
              <div style={datosPersonaSel == null ? { display: "none" } : {}}>
                <div className="flex-row d-flex mb-3 justify-content-between">
                  <div className="px-3">
                    <FotoPerfil datosPersonaSel={datosPersonaSel} size={160} />
                  </div>
                  <div className="d-flex flex-column shadow-sm border-radius ">
                    <div className="d-flex p-3 ">
                      <div className=" d-flex flex-column  ">
                        <div className="font-weight-bold ">{getTrad("nombre")}</div>
                        <div className="pb-2">{datosPersonaSel?.nombre}</div>
                      </div>
                      <div className="d-flex flex-column px-3 ">
                        <div className="font-weight-bold">{getTrad("apellidos")}</div>
                        <div className="pb-2">{datosPersonaSel?.apellidos}</div>
                      </div>
                    </div>
                    <div className="px-3 d-flex flex-column ">
                      <div className="font-weight-bold ">{getTrad("numDocumentoIdentidad")}</div>
                      <div>{datosPersonaSel?.numDocumentoIdentidad?.toUpperCase()}</div>
                    </div>
                  </div>
                </div>
                <div className="d-flex p-3 mb-3  flex-column align-items-end shadow-sm border-radius ">
                  {codigoGestoria != null ? (
                    <div className="d-flex justify-content-end py-2">
                      Revisa que el <strong className="mx-1"> código de gestoría </strong> sea el correcto.
                    </div>
                  ) : (
                    <div className="d-flex justify-content-end py-2">
                      Introduce un <strong className="mx-1"> código de gestoría </strong>.
                    </div>
                  )}
                  <Box width={"100%"} className="d-flex flex-column justify-content-center align-items-end">
                    <Item ratio={1}>
                      <Box>
                        <Item ratio={1}>
                          <TextBox
                            ref={(ref) => (this.textBoxCodigoGestoria = ref)}
                            value={codigoGestoriaSet}
                            maxLength={6}
                            onValueChanged={this.setCodigoGestoria}
                            className="p-1 text-right  w-100"
                            style={{ borderRadius: 10, alignSelf: "center", width: "100%" }}
                            placeholder={datosPersonaSel?.codigoGestoria ?? "Código de gestoría"}
                          >
                            <Validator>
                              <AsyncRule
                                ignoreEmptyValue={true}
                                reevaluate={false}
                                message="El código gestoría introducido ya está asignado a otra persona"
                                validationCallback={this.validateAsync_codigoGestoria}
                              />
                              <NumericRule />
                              <PatternRule pattern={/^.{6,}$/} message={getTrad("validation_Formato")} />
                            </Validator>
                          </TextBox>
                        </Item>
                      </Box>
                    </Item>
                  </Box>
                </div>
                <div className=" shadow-sm p-3 border-radius ">
                  <Box direction="row" width="100%" height="100%">
                    <Item baseSize={"auto"}>
                      {archivoSel && (
                        <div
                          onClick={this.visualizarDocumento}
                          className="pointer align-items-center justify-content-center shadow-sm border-radius btn btn-light mr-4"
                          style={{ height: 110, width: 110 }}
                        >
                          <LottieIcon height="100%" width="100%" icon={"pdffile"} />
                        </div>
                      )}
                    </Item>
                    <Item ratio={1}>
                      <div className="d-flex flex-column justify-content-center pb-2">
                        <div>
                          Sube el <strong>contrato </strong> de la persona.
                        </div>
                      </div>
                      <FileUploader
                        labelText={getTrad("msg_arrastraArchivo5mb")}
                        accept={"application/pdf"}
                        minFileSize={1}
                        uploadMode="instantly"
                        uploadFailedMessage={getTrad("subidaFallida")}
                        selectButtonText={getTrad("subirArchivo")}
                        onUploadStarted={this.pdf_onLoadStarted}
                      />
                    </Item>
                  </Box>
                </div>
              </div>
              <div className="d-flex flex-1 justify-content-end align-items-end">
                <div className="btn btn-success mx-3 p-2 pointer" style={{ width: 100 }} onClick={this.confirmar}>
                  {getTrad("validar")}
                </div>
                <div className="btn btn-danger p-2 pointer" style={{ width: 100 }} onClick={this.hidePopup}>
                  {getTrad("cancelar")}
                </div>
              </div>
            </Item>
          </Box>
        </Popup>
        <Popup
          title={getTrad("visualizarDocumento")}
          visible={showPopupPDF}
          showCloseButton={true}
          closeOnOutsideClick={true}
          width={"80%"}
          height={"80%"}
          onHiding={this.hidePopupPDF}
        >
          <Gallery
            items={archivoSel ? [archivoSel] : []}
            showIndicator={false}
            showNavButtons={false}
            loop={false}
            height="100%"
            width="100%"
            noDataText={getTrad("sinFoto")}
            itemRender={this.dxGallery_itemRender}
          />
        </Popup>
      </>
    );
  }

  objCheckCodigoGestoria = {};
  context_CodigoGestoria = new ODataContext({
    url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPersona",
    entities: {
      fn_CheckCodigoGestoria: {},
    },
    beforeSend: (request) => {
      this.context_CodigoGestoria_beforeSend(request);
    },
  });

  context_CodigoGestoria_beforeSend(request) {
    let { datosPersonaSel } = this.state;

    request.headers = { ...authHeader() };
    request.params.idPersona = datosPersonaSel ? datosPersonaSel.idPersona : null;
    request.params.codigoGestoria = this.objCheckCodigoGestoria.codigo;
    request.params.idEmpresaPolarier = datosPersonaSel ? datosPersonaSel.idEmpresaPolarier : null;
  }

  validateAsync_codigoGestoria = (params) => {
    let value = params.value.replace(/\s+/g, " ").trim();

    return new Promise((resolve) => {
      this.objCheckCodigoGestoria = { codigo: value };
      this.context_CodigoGestoria.invoke("fn_CheckCodigoGestoria", {}, "POST").done(function (result) {
        const isValid = result === -1;
        return resolve(isValid);
      });
    });
  };
}

export default PopUpContrato;
