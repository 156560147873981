import React, { Fragment } from "react";
import { connect } from "react-redux";

import $ from "jquery";
import {
    getTrad,
    formatDate,
    getBrightnessColor,
    durationToDatetime,
    dateTime_hourMinute,
    dxMensajePregunta,
    formatTime_parameter,
} from "../../helpers";

import Calendar from "../../libraries/rc-year-calendar";
import "rc-year-calendar/locales/rc-year-calendar.es";
import "rc-year-calendar/locales/rc-year-calendar.pt";
import { Popover } from "devextreme-react/popover";
import query from "devextreme/data/query";
import { Popup, ToolbarItem as ToolbarItem_popup } from "devextreme-react/popup";
import Box, { Item as ItemBox } from "devextreme-react/box";
import { Button } from "devextreme-react";
import List from "devextreme-react/list";
import { svg_warning } from "../../styles/svg_iconos";
//Actions
import { loadPanelActions } from "../../actions";

//Css
import "./Css.scss";

class YearCalendar extends React.PureComponent {
    constructor(props) {
        super(props);

        let { data, estados, currentYear } = this.props;
        this.formatData = this.formatData.bind(this);

        this.state = {
            data: this.formatData(data),
            estados: estados,
            currentYear: currentYear,
            dxPopover_fechaHover_isVisible: false,
            fechaHover: [],
            fechasSel: null,
            isVisible_dxPopup_selectDia: false,
            yearCalendar_newData: [],
        };

        //LOAD PANEL
        this.loadPanel_show = this.loadPanel_show.bind(this);
        this.loadPanel_hide = this.loadPanel_hide.bind(this);

        this.yearCalendar_onDayEnter = this.yearCalendar_onDayEnter.bind(this);
        this.yearCalendar_onDayLeave = this.yearCalendar_onDayLeave.bind(this);
        this.yearCalendar_onDayClick = this.yearCalendar_onDayClick.bind(this);
        this.yearCalendar_onRangeSelected = this.yearCalendar_onRangeSelected.bind(this);
        this.yearCalendar_onYearChanged = this.yearCalendar_onYearChanged.bind(this);
        this.yearCalendar_customDayRenderer = this.yearCalendar_customDayRenderer.bind(this);
        this.yearCalendar_customDataSourceRenderer = this.yearCalendar_customDataSourceRenderer.bind(this);
        this.onHiding_dxPopup_selectDia = this.onHiding_dxPopup_selectDia.bind(this);
        this.onClick_guardar_dxPopup_selectDia = this.onClick_guardar_dxPopup_selectDia.bind(this);
        this.dxListEstados_itemRender = this.dxListEstados_itemRender.bind(this);
        this.dxListEstados_onSelectionChanged = this.dxListEstados_onSelectionChanged.bind(this);
        this.dxListEstados_onOptionChanged = this.dxListEstados_onOptionChanged.bind(this);
        this.dxListEstados_onItemDeleting = this.dxListEstados_onItemDeleting.bind(this);
        this.dxListEstados_onContentReady = this.dxListEstados_onContentReady.bind(this);
        this.dxListEstados_onItemRendered = this.dxListEstados_onItemRendered.bind(this);
        this.dxPopover_estados_onShowing = this.dxPopover_estados_onShowing.bind(this);

        this.getTrad = this.getTrad.bind(this);
    }

    array_traducciones = [];
    getTrad(traduccion) {
        let codigoIdioma = this.props.idioma.codigo;

        if (this.array_traducciones[codigoIdioma] == null) this.array_traducciones[codigoIdioma] = [];

        if (this.array_traducciones[codigoIdioma][traduccion] == null)
            this.array_traducciones[codigoIdioma][traduccion] = getTrad(traduccion);

        return this.array_traducciones[codigoIdioma][traduccion];
    }

    formatData(data) {
        let { idioma, estados, tipoCalendario, isDisabled } = this.props;
        data = query(data ? data : [])
            .sortBy("fecha")
            .toArray();

        let dataFiltrada = $.grep(data, function (item) {
            return item.idEstado !== 0;
        });
        let _this = this;
        return $.map(dataFiltrada, function (item, index) {
            let lugarRango = null;
            let estado = $.grep(estados, function (estado) {
                return estado.idEstado === item.idEstado;
            });
            estado = estado.length > 0 ? estado[0] : null;
            item.isError = false;

            let date = new Date(item.fecha);

            if (tipoCalendario == "calendarioEntidad") {
                if (estado.idEstado === 1) {
                    //Inicio
                    let siguienteFecha_range = $.grep(data, function (nextItem) {
                        if (nextItem.fecha.getTime() > item.fecha.getTime())
                            return nextItem.idEstado === 1 || nextItem.idEstado === 2;
                    });
                    siguienteFecha_range = siguienteFecha_range.length > 0 ? siguienteFecha_range[0] : null;

                    //La fecha siguiente no es cierre
                    if (siguienteFecha_range && siguienteFecha_range.idEstado !== 2) {
                        item.isError = true;
                    }
                } else if (estado.idEstado === 2) {
                    //Fin
                    let anteriorFecha_range = $.grep(data, function (nextItem) {
                        if (nextItem.fecha.getTime() < item.fecha.getTime())
                            return nextItem.idEstado === 1 || nextItem.idEstado === 2;
                    });
                    anteriorFecha_range =
                        anteriorFecha_range.length > 0 ? anteriorFecha_range[anteriorFecha_range.length - 1] : null;

                    // La fecha anterior es fin || Es la primera fecha
                    if ((anteriorFecha_range && anteriorFecha_range.idEstado !== 1) || anteriorFecha_range == null)
                        item.isError = true;
                }
            } else if (tipoCalendario != "calendarioEntidad") {
                let diaAnterior = new Date(new Date(date).setDate(new Date(date).getDate() - 1));
                let diaSiguiente = new Date(new Date(date).setDate(new Date(date).getDate() + 1));

                let estadosItem_actual = [];
                let estadosItem_anterior = [];
                let estadosItem_siguiente = [];

                for (let i = 0; i < dataFiltrada.length; i++) {
                    if (dataFiltrada[i].fecha.toDateString() === item.fecha.toDateString()) {
                        estadosItem_actual.push(dataFiltrada[i].idEstado);
                    }
                    if (dataFiltrada[i].fecha.toDateString() === diaAnterior.toDateString()) {
                        estadosItem_anterior.push(dataFiltrada[i].idEstado);
                    }
                    if (dataFiltrada[i].fecha.toDateString() === diaSiguiente.toDateString()) {
                        estadosItem_siguiente.push(dataFiltrada[i].idEstado);
                    }
                }

                estadosItem_actual.sort();
                estadosItem_anterior.sort();
                estadosItem_siguiente.sort();

                if (
                    estadosItem_actual.toString() === estadosItem_anterior.toString() &&
                    estadosItem_siguiente.toString() === estadosItem_actual.toString()
                ) {
                    lugarRango = "intermedio";
                } else if (
                    estadosItem_actual.toString() !== estadosItem_anterior.toString() &&
                    estadosItem_siguiente.toString() !== estadosItem_actual.toString()
                ) {
                    lugarRango = "unico";
                } else if (
                    estadosItem_actual.toString() === estadosItem_anterior.toString() &&
                    estadosItem_siguiente.toString() !== estadosItem_actual.toString()
                ) {
                    //sin fecha siguiente
                    lugarRango = "fin";
                } else if (
                    estadosItem_actual.toString() !== estadosItem_anterior.toString() &&
                    estadosItem_siguiente.toString() === estadosItem_actual.toString()
                ) {
                    //sin fecha anterior
                    lugarRango = "inicio";
                }
            }
            if (item.idEstado === 0) lugarRango = null;

            let obj = {
                idEstado: item.idEstado,
                fecha: item.fecha, // La fecha que viene de bd
                date: item.fecha, // Para que pueda coger los días en los eventos de dayEnter/dayLeave
                startDate: item.fecha, //Para pintar los días en calendario
                endDate: item.fecha, //Para pintar los días en calendario
                color: estado.colorHexa ? estado.colorHexa : "transparent",
                denoEstado: estado.tblTraduccion
                    ? estado.tblTraduccion[idioma.codigo]
                    : estado.idTraduccionNavigation
                    ? estado.idTraduccionNavigation[idioma.codigo]
                    : _this.getTrad(estado.traduccion),
                idTipoEstado: estado.idTipoEstado,
                isError: item.isError,
                lavanderia: item.lavanderia,
                turno: item.turno,
                horaEntrada: !item.horaEntrada
                    ? null
                    : typeof item.horaEntrada === "object"
                    ? item.horaEntrada
                    : durationToDatetime(item.horaEntrada),
                horaSalida: !item.horaSalida
                    ? null
                    : typeof item.horaSalida === "object"
                    ? item.horaSalida
                    : durationToDatetime(item.horaSalida),
                lugarRango: lugarRango, // inicio, fin, intermedio, único
                isDisabled: isDisabled,
            };

            if (!item.lavanderia) {
                delete obj.lavanderia;
                delete obj.turno;
                delete obj.horaEntrada;
                delete obj.horaSalida;
            }

            if (tipoCalendario == "calendarioEntidad") delete obj.lugarRango;
            return obj;
        });
    }

    render() {
        let {
            data,
            estados,
            fechaHover,
            dxPopover_fechaHover_isVisible,
            currentYear,
            isVisible_dxPopup_selectDia,
            fechasSel,
        } = this.state;
        let {
            idioma,
            listEstados_selectMode,
            enableRangeSelection,
            soloMostrar_idEstados_seleccion,
            readOnly_idEstados_seleccion,
            tipoCalendario,
            isDisabled,
            disabledDays,
            allowDelete,
            user,
        } = this.props;

        let fechaSel_idEstados = !fechasSel
            ? []
            : $.map(fechasSel.events, function (item) {
                  return item.idEstado;
              });
        let is_personaVariosEventos_Warning =
            (tipoCalendario == "calendarioLaboral" || tipoCalendario == "personal") &&
            fechaHover.filter((x) => x.idEstado !== 10).length > 1 &&
            fechasSel &&
            fechasSel.startDate == fechasSel.endDate;
        let isSelected_readOnlyEstado =
            fechaSel_idEstados.length > 0 &&
            readOnly_idEstados_seleccion &&
            $.grep(fechaSel_idEstados, function (item) {
                return $.inArray(item, readOnly_idEstados_seleccion) >= 0;
            }).length > 0;

        let datasource_estadosAgrupados = [
            { key: "Eventos de rangos", items: [] },
            { key: "Eventos diarios", items: [] },
        ];

        $.each(estados, function (index, item) {
            if (tipoCalendario != "calendarioEntidad") {
                item.visible = true;
                if (isSelected_readOnlyEstado) {
                    if ($.inArray(item.idEstado, fechaSel_idEstados) < 0) item.visible = false;
                } else {
                    if ($.inArray(item.idEstado, soloMostrar_idEstados_seleccion) < 0) item.visible = false;

                    if ($.inArray(item.idEstado, readOnly_idEstados_seleccion) >= 0) item.visible = false;
                }
            }

            if (item.idTipoEstado === 1 || item.idTipoEstado === 2) datasource_estadosAgrupados[0].items.push(item);
            else datasource_estadosAgrupados[1].items.push(item);
        });

        if (isVisible_dxPopup_selectDia) {
            let subText = null;
            if (is_personaVariosEventos_Warning) {
                let estadosTrabajo = $.grep(fechasSel.events, function (item) {
                    return item.idEstado == 3 || item.idEstado == 4;
                });
                if (estadosTrabajo.length > 0) {
                    let horaEntrada =
                        estadosTrabajo[0].horaEntrada != null
                            ? formatTime_parameter(estadosTrabajo[0].horaEntrada).split(":")[0] +
                              ":" +
                              formatTime_parameter(estadosTrabajo[0].horaEntrada).split(":")[1]
                            : "(Sin hora entrada)";
                    let horaSalida =
                        estadosTrabajo[0].horaSalida != null
                            ? formatTime_parameter(estadosTrabajo[0].horaSalida).split(":")[0] +
                              ":" +
                              formatTime_parameter(estadosTrabajo[0].horaSalida).split(":")[1]
                            : "(Sin hora salida)";
                    subText =
                        "Horario: " +
                        horaEntrada +
                        " - " +
                        horaSalida +
                        " (" +
                        estadosTrabajo[0].turno.toUpperCase() +
                        ")";
                }
            }
            $.each(datasource_estadosAgrupados[1].items, function (index, item) {
                if (item.idEstado == 3 || item.idEstado == 4) {
                    item.subText = subText;
                }
            });

            if (is_personaVariosEventos_Warning) {
                datasource_estadosAgrupados[1].items.sort(function (a, b) {
                    var textA = a.denominacion.toUpperCase();
                    var textB = b.denominacion.toUpperCase();
                    return a.idEstado == 3 || a.idEstado == 4 ? -1 : textA < textB ? -1 : textA > textB ? 1 : 0;
                });
            }
        }

        return (
            <Fragment>
                <Calendar
                    dataSource={data}
                    style={tipoCalendario != "calendarioEntidad" ? "custom" : "background"}
                    language={idioma.codigo}
                    enableRangeSelection={enableRangeSelection}
                    roundRangeLimits={true}
                    weekStart={1}
                    year={currentYear}
                    allowOverlap={true}
                    customDataSourceRenderer={this.yearCalendar_customDataSourceRenderer}
                    customDayRenderer={this.yearCalendar_customDayRenderer}
                    onDayEnter={this.yearCalendar_onDayEnter}
                    onDayLeave={this.yearCalendar_onDayLeave}
                    onDayClick={this.yearCalendar_onDayClick}
                    onRangeSelected={this.yearCalendar_onRangeSelected}
                    onYearChanged={this.yearCalendar_onYearChanged}
                    displayDisabledDataSource={true}
                    isDisabled={isDisabled}
                    disabledDays={disabledDays}
                />
                <Popover
                    target={"#" + (fechaHover.length > 0 ? fechaHover[0].id : 1)}
                    position="top"
                    width={"auto"}
                    onShowing={this.dxPopover_estados_onShowing}
                    visible={dxPopover_fechaHover_isVisible}
                >
                    <div className="flex flex-column">
                        {tipoCalendario != "calendarioEntidad" ? null : (
                            <div>
                                <h5> {this.getTrad("eventos")} </h5>
                            </div>
                        )}
                        <div className={tipoCalendario != "calendarioEntidad" ? "" : "ml-2"}>
                            {tipoCalendario != "calendarioEntidad" || fechaHover.length > 1 ? (
                                <Fragment>
                                    {fechaHover.length > 0 && fechaHover[0].lavanderia ? (
                                        <div
                                            style={{
                                                color:
                                                    getBrightnessColor(fechaHover[0].color) >= 200 ||
                                                    fechaHover[0].color === "transparent"
                                                        ? "#5C6476"
                                                        : "#FFF",
                                                fontWeight: "600",
                                            }}
                                            className="pb-2"
                                        >
                                            {fechaHover[0].lavanderia}
                                        </div>
                                    ) : null}
                                    {fechaHover.length > 1 &&
                                        (tipoCalendario == "calendarioLaboral" || tipoCalendario == "personal") && (
                                            <Fragment>
                                                <div
                                                    style={{
                                                        fontWeight: 600,
                                                        color:
                                                            fechaHover.filter((x) => x.idEstado !== 10).length === 1
                                                                ? "white"
                                                                : "#5C6476",
                                                    }}
                                                >
                                                    {this.getTrad("variosEstadosDia")}
                                                </div>
                                                <ul className="pt-1 mb-1">
                                                    {fechaHover.map((child, i) => (
                                                        <li
                                                            key={i}
                                                            style={{
                                                                color:
                                                                    fechaHover.filter((x) => x.idEstado !== 10)
                                                                        .length === 1
                                                                        ? "white"
                                                                        : "#5C6476",
                                                                fontWeight: "400",
                                                            }}
                                                        >
                                                            {child.denoEstado}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Fragment>
                                        )}
                                    {((fechaHover.length > 1 &&
                                        tipoCalendario != "calendarioLaboral" &&
                                        tipoCalendario != "personal") ||
                                        fechaHover.length <= 1) &&
                                        fechaHover.map((child, i) => (
                                            <div key={i} className="d-flex flex-row align-items-center">
                                                <div
                                                    style={{
                                                        color:
                                                            getBrightnessColor(child.color) >= 200 ||
                                                            child.color === "transparent"
                                                                ? "#5C6476"
                                                                : "#FFF",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {child.denoEstado}
                                                </div>
                                            </div>
                                        ))}
                                    {fechaHover.length > 0 && fechaHover[0].turno ? (
                                        <div
                                            style={{
                                                color:
                                                    getBrightnessColor(fechaHover[0].color) >= 200 ||
                                                    fechaHover[0].color === "transparent"
                                                        ? "#5C6476"
                                                        : "#FFF",
                                            }}
                                            className="text-capitalize pt-2"
                                        >
                                            <span className="pr-1" style={{ fontWeight: "500" }}>
                                                Horario:
                                            </span>{" "}
                                            {" " +
                                                (fechaHover[0].horaEntrada
                                                    ? dateTime_hourMinute(fechaHover[0].horaEntrada)
                                                    : "N/A") +
                                                " - " +
                                                (fechaHover[0].horaSalida
                                                    ? dateTime_hourMinute(fechaHover[0].horaSalida)
                                                    : "N/A") +
                                                " (" +
                                                fechaHover[0].turno.toLowerCase() +
                                                ")"}
                                        </div>
                                    ) : null}
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {fechaHover.map((child, i) => (
                                        <div key={i} className="d-flex flex-row align-items-center">
                                            <div
                                                style={{
                                                    "--circleColor": child.color,
                                                    "--circleSize": "8px",
                                                }}
                                                className="dxcalendar-date-circle"
                                            />
                                            <div className="pr-3" />
                                            <div>{child.denoEstado}</div>
                                        </div>
                                    ))}
                                    {fechaHover.length > 0 && fechaHover[0].isError ? (
                                        <Fragment>
                                            <br />
                                            <div className="font-size-xxxs text-danger" style={{ fontWeight: "500" }}>
                                                {fechaHover[0].idTipoEstado === 1
                                                    ? "*" + this.getTrad("error_fechaCierre")
                                                    : fechaHover[0].idTipoEstado === 2
                                                    ? "*" + this.getTrad("error_fechaApertura")
                                                    : "Error"}
                                            </div>
                                        </Fragment>
                                    ) : null}
                                </Fragment>
                            )}
                        </div>
                    </div>
                </Popover>
                <Popup
                    width={350}
                    height={500}
                    title={this.getTrad("selTipoDiaLibre")}
                    visible={isVisible_dxPopup_selectDia}
                    closeOnOutsideClick={false}
                    onContentReady={this.onContentReady_dxPopup_selectDia}
                    onHiding={this.onHiding_dxPopup_selectDia}
                >
                    <Box direction="col" align="space-around" crossAlign="stretch" height="100%" width="100%">
                        <ItemBox baseSize={40}>
                            <Box
                                direction="row"
                                align="space-around"
                                crossAlign="stretch"
                                height={"100%"}
                                width="100%"
                                elementAttr={this.dxPopup_titleBar_elementAttr}
                            >
                                <ItemBox baseSize={30}>
                                    <div className="dx-icon-event he-100 d-flex align-items-center justify-content-start font-size-xl pt-1"></div>
                                </ItemBox>
                                <ItemBox ratio={1}>
                                    <div className="he-100 d-flex align-items-center font-size-lg p-2 title-dxpopup-fecha">
                                        {fechasSel
                                            ? fechasSel.type === "selectRange" && fechasSel.startDate
                                                ? formatDate(fechasSel.startDate) +
                                                  " - " +
                                                  formatDate(fechasSel.endDate)
                                                : fechasSel.date
                                                ? formatDate(fechasSel.date)
                                                : ""
                                            : ""}
                                    </div>
                                </ItemBox>
                            </Box>
                        </ItemBox>
                        <ItemBox baseSize={15} />
                        <ItemBox ratio={1}>
                            <List
                                dataSource={datasource_estadosAgrupados}
                                selectedItemKeys={
                                    fechasSel
                                        ? fechaSel_idEstados.filter(
                                              (x) => tipoCalendario !== "calendarioLaboral" || x !== 10
                                          ).length > 1 && listEstados_selectMode === "single"
                                            ? [] //Si tiene más de 1 estado, vaciar
                                            : fechaSel_idEstados.filter(
                                                  (x) => tipoCalendario !== "calendarioLaboral" || x !== 10
                                              ).length === 0 && listEstados_selectMode === "single"
                                            ? [0] //Si No tiene estado seleccionado y es single, que se seleccione idEstado 0.
                                            : fechaSel_idEstados.filter(
                                                  (x) => tipoCalendario !== "calendarioLaboral" || x !== 10
                                              )
                                        : []
                                }
                                focusStateEnabled={
                                    isVisible_dxPopup_selectDia && is_personaVariosEventos_Warning ? false : true
                                }
                                hoverStateEnabled={
                                    isVisible_dxPopup_selectDia && is_personaVariosEventos_Warning ? false : true
                                }
                                activeStateEnabled={
                                    isVisible_dxPopup_selectDia && is_personaVariosEventos_Warning ? false : true
                                }
                                selectionMode={
                                    isVisible_dxPopup_selectDia && is_personaVariosEventos_Warning
                                        ? "none"
                                        : listEstados_selectMode
                                        ? listEstados_selectMode
                                        : "multiple"
                                }
                                disabled={
                                    isSelected_readOnlyEstado &&
                                    !user.enableDatosRRHH &&
                                    !is_personaVariosEventos_Warning
                                }
                                height="100%"
                                grouped={true}
                                keyExpr="idEstado"
                                collapsibleGroups={false}
                                repaintChangesOnly={true}
                                allowItemDeleting={
                                    !isVisible_dxPopup_selectDia
                                        ? false
                                        : allowDelete && is_personaVariosEventos_Warning
                                }
                                itemDeleteMode="static"
                                noDataText="Sin eventos"
                                itemRender={this.dxListEstados_itemRender}
                                onContentReady={this.dxListEstados_onContentReady}
                                onItemRendered={this.dxListEstados_onItemRendered}
                                onSelectionChanged={this.dxListEstados_onSelectionChanged}
                                onOptionChanged={this.dxListEstados_onOptionChanged}
                                onItemDeleting={this.dxListEstados_onItemDeleting}
                            />
                        </ItemBox>
                    </Box>
                    {(listEstados_selectMode === "multiple" || !listEstados_selectMode) && (
                        <ToolbarItem_popup widget="dxButton" location="after" toolbar="bottom">
                            <Button
                                text={this.getTrad("guardar")}
                                type="success"
                                onClick={this.onClick_guardar_dxPopup_selectDia}
                            />
                        </ToolbarItem_popup>
                    )}
                </Popup>
            </Fragment>
        );
    }

    dxPopover_estados_onShowing(e) {
        let { fechaHover } = this.state;
        let { tipoCalendario } = this.props;

        if (tipoCalendario != "calendarioEntidad" && fechaHover.length > 0) {
            const color =
                fechaHover.length === 1 ? fechaHover[0].color : fechaHover.filter((x) => x.idEstado !== 10)[0].color;
            e.component
                .content()
                .parent()
                .css({
                    "background-color":
                        color !== "transparent" && fechaHover.filter((x) => x.idEstado !== 10).length === 1
                            ? color
                            : "#FFF",
                    border: "1px solid #DDD",
                    "border-radius": "12px",
                });

            e.component.content().css({
                padding: "15px 20px",
            });
        }
    }

    dxPopup_titleBar_elementAttr = {
        id: "dxPopup_titleBar",
    };

    dxListEstados_itemRender(e) {
        let { idioma } = this.props;

        if (e.subText != null) {
            return (
                <Fragment>
                    <div>
                        {e.tblTraduccion
                            ? e.tblTraduccion[idioma.codigo]
                            : e.traduccion
                            ? this.getTrad(e.traduccion)
                            : ""}
                    </div>
                    <div style={{ fontSize: "12px" }}>{e.subText}</div>
                </Fragment>
            );
        } else {
            if (e.tblTraduccion) return e.tblTraduccion[idioma.codigo];
            else if (e.traduccion) return this.getTrad(e.traduccion);
        }
    }

    dxListEstados_onItemRendered(e) {
        if (e.itemData && e.itemData.idEstado === 0) {
            e.itemElement.css("borderBottom", "1px solid #bbb");
            return;
        }
    }

    dxListEstados_onContentReady(e) {
        let { tipoCalendario } = this.props;
        let groupHeader = $($(e.element)[0]).find(".dx-list-group-header");
        $.each(groupHeader, function (index, item) {
            if (tipoCalendario != "calendarioEntidad") {
                $(item).css("display", "none");
            } else {
                if (index === 0) $(item).css("padding-top", "0px");
            }
        });
        e.element.css("opacity", "1");
    }

    dxListEstados_onSelectionChanged(e) {
        let { data, fechasSel } = this.state;
        let { listEstados_selectMode, tipoCalendario } = this.props;
        if (e.addedItems.length > 0) {
            if (tipoCalendario != "calendarioEntidad" && listEstados_selectMode === "single") {
                let newDates = [];
                if (fechasSel.type === "selectRange") {
                    let fechaSel_dates = [];
                    let loop = new Date(fechasSel.startDate);
                    while (loop <= fechasSel.endDate) {
                        fechaSel_dates.push(new Date(loop));

                        let newDate = loop.setDate(loop.getDate() + 1);
                        loop = new Date(newDate);
                    }

                    let fechaSel_dates_format = $.map(fechaSel_dates, function (item) {
                        return item.toDateString();
                    });

                    //Quitar la fechaSel del dataSource
                    data = $.grep(data, function (item) {
                        return $.inArray(item.fecha.toDateString(), fechaSel_dates_format) < 0;
                    });

                    $.each(fechaSel_dates, function (index, item) {
                        newDates.push({
                            idEstado: fechasSel.events[0].idEstado,
                            date: new Date(item), //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                            fecha: new Date(item),
                            color: e.addedItems[0].colorHexa ? e.addedItems[0].colorHexa : "transparent",
                        });
                    });
                } else if (fechasSel.type === "select" || fechasSel.type === "clickDay") {
                    //Quitar la fechaSel del dataSource
                    data = $.grep(data, function (item) {
                        return item.fecha.toDateString() != fechasSel.date.toDateString();
                    });

                    $.each(fechasSel.events, function (index, item) {
                        newDates.push({
                            idEstado: item.idEstado,
                            fecha: fechasSel.date,
                            date: fechasSel.date, //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                            color: e.addedItems[0].colorHexa ? e.addedItems[0].colorHexa : "transparent",
                        });
                    });
                }

                let fechasCalendario = data.concat(newDates);
                var fechasCalendario_filterDuplicados = [];

                $.each(fechasCalendario, function (key, value) {
                    var exists = false;

                    $.each(fechasCalendario_filterDuplicados, function (k, val2) {
                        if (value.fecha.getTime() == val2.fecha.getTime() && value.idEstado === val2.idEstado) {
                            exists = true;
                        }
                    });

                    if (exists == false) fechasCalendario_filterDuplicados.push(value);
                });

                data = fechasCalendario_filterDuplicados;
                this.setState({ isVisible_dxPopup_selectDia: false }, () => {
                    setTimeout(() => {
                        this.props.yearCalendar_onDateChanged(data, newDates);
                    }, 75);
                });
            } else if (tipoCalendario === "calendarioEntidad") {
                let estado = e.addedItems[0].idEstado;
                let dxListEstados_items = e.component.option("selectedItems")[0].items;

                if (estado === 1) {
                    let itemCierre = $.grep(dxListEstados_items, function (item) {
                        return item.idEstado === 2;
                    });
                    if (itemCierre.length > 0) {
                        e.component.unselectItem(itemCierre[0]);
                    }
                } else if (estado === 2) {
                    let itemApertura = $.grep(dxListEstados_items, function (item) {
                        return item.idEstado === 1;
                    });
                    if (itemApertura.length > 0) {
                        e.component.unselectItem(itemApertura[0]);
                    }
                }
            }
        }
    }

    dxListEstados_onOptionChanged(e) {
        if (e.name == "selectedItems") {
            let fechasSel = { ...this.state.fechasSel };
            let events = [];
            $.each(e.value, function (index, item) {
                $.each(item.items, function (index2, item2) {
                    events.push(item2);
                });
            });

            fechasSel.events = events;
            fechasSel.type = fechasSel.startDate && fechasSel.endDate ? "selectRange" : "select";
            this.setState({ fechasSel: fechasSel });
        }
    }

    dxListEstados_onItemDeleting(e) {
        let isDiaTrabajado = e.itemData.idEstado == 3 || e.itemData.idEstado == 4;
        let _this = this;
        dxMensajePregunta(
            isDiaTrabajado
                ? "Se eliminará el día trabajado, ¿desea continuar?"
                : this.getTrad("preg_RegistroEliminarCorto"),
            [
                // ACEPTAR
                [
                    this.getTrad("aceptar"),
                    function () {
                        _this.props.yearCalendar_onEstadoDeleting(e.itemData, _this.state.fechasSel.date);
                        _this.setState({ isVisible_dxPopup_selectDia: false });
                    },
                    "normal",
                    "btnAceptar",
                ],
                // CANCELAR
                [this.getTrad("cancelar"), function () {}, "normal", "btnCancelar"],
            ]
        );
        e.cancel = true;
    }

    onClick_guardar_dxPopup_selectDia(e) {
        let { data, fechasSel } = this.state;
        let { tipoCalendario } = this.props;

        if (tipoCalendario === "calendarioEntidad") {
            // Fechas menores a la seleccionada y con estado Apertura
            let fechasAnteriores_apertura = data.filter(
                (d) => d.fecha.getTime() < fechasSel.date.getTime() && d.idTipoEstado == 1
            );

            let ultimaFechaAnterior_fechasSel_apertura =
                fechasAnteriores_apertura.length > 0
                    ? query(fechasAnteriores_apertura).sortBy("fecha", true).toArray()[0]
                    : null;

            // Fecha siguiente con estado después de la última fecha de apertura
            let fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura = ultimaFechaAnterior_fechasSel_apertura
                ? data.filter((d) => d.fecha.getTime() > ultimaFechaAnterior_fechasSel_apertura.fecha.getTime())
                : null;
            fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura =
                fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura != null
                    ? query(fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura).sortBy("fecha").toArray()[0]
                    : null;

            let isClick_entreFechas =
                ultimaFechaAnterior_fechasSel_apertura &&
                fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura &&
                (fechasSel.idTipoEstado === 1 || fechasSel.idTipoEstado === 2) &&
                fechasSel.date.getTime() > ultimaFechaAnterior_fechasSel_apertura.fecha.getTime() &&
                fechasSel.date.getTime() < fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura.fecha.getTime() &&
                fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura.idTipoEstado == 2;

            let diaPosteriorSel = new Date(fechasSel.date);
            diaPosteriorSel.setDate(diaPosteriorSel.getDate() + 1);

            let newDates = [];

            //Quitar la fechaSel del dataSource
            data = $.grep(data, function (item) {
                return item.fecha.toDateString() != fechasSel.date.toDateString();
            });

            $.each(fechasSel.events, function (index, item) {
                newDates.push({
                    idEstado: item.idEstado,
                    fecha: fechasSel.date,
                });
            });

            let fechasCalendario = data.concat(newDates);
            var fechasCalendario_filterDuplicados = [];

            $.each(fechasCalendario, function (key, value) {
                var exists = false;
                $.each(fechasCalendario_filterDuplicados, function (k, val2) {
                    if (value.fecha.getTime() == val2.fecha.getTime() && value.idEstado === val2.idEstado) {
                        exists = true;
                    }
                });

                if (exists == false) {
                    if (
                        (!isClick_entreFechas ||
                            (isClick_entreFechas &&
                                value.fecha.getTime() != ultimaFechaAnterior_fechasSel_apertura.fecha.getTime() &&
                                value.fecha.getTime() !=
                                    fechaSiguiente_ultimaFechaAnterior_fechasSel_apertura.fecha.getTime())) &&
                        (fechasSel.events.length > 0 ||
                            (fechasSel.events.length === 0 &&
                                value.fecha.toDateString() !== fechasSel.date.toDateString()))
                    )
                        fechasCalendario_filterDuplicados.push(value);
                }
            });

            data = fechasCalendario_filterDuplicados;

            let data_ = $.extend(true, [], this.formatData(data));
            let isCalendar_valid = true;
            $.each(data_, function (index, item) {
                if (item.isError) {
                    isCalendar_valid = false;
                }
            });
            this.props.yearCalendar_onDateChanged(data, isCalendar_valid);

            this.setState({ isVisible_dxPopup_selectDia: false });
        } else {
            let newDates = [];
            if (fechasSel.type === "selectRange") {
                let fechaSel_dates = [];
                let loop = new Date(fechasSel.startDate);
                while (loop <= fechasSel.endDate) {
                    fechaSel_dates.push(new Date(loop));

                    let newDate = loop.setDate(loop.getDate() + 1);
                    loop = new Date(newDate);
                }

                let fechaSel_dates_format = $.map(fechaSel_dates, function (item) {
                    return item.toDateString();
                });

                //Quitar la fechaSel del dataSource
                data = $.grep(data, function (item) {
                    return $.inArray(item.fecha.toDateString(), fechaSel_dates_format) < 0;
                });

                let distinctEvents = [...new Map(fechasSel.events.map((item) => [item["idEstado"], item])).values()];
                $.each(fechaSel_dates, function (iFecha, fecha) {
                    $.each(distinctEvents, function (iEvents, events) {
                        newDates.push({
                            idEstado: events.idEstado,
                            fecha: fecha,
                            date: fecha, //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                            colorHexa: events.colorHexa ? events.colorHexa : "transparent",
                        });
                    });
                    if (fechasSel.events.length === 0) {
                        newDates.push({
                            idEstado: 0,
                            fecha: fecha,
                            date: fecha, //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                            colorHexa: "transparent",
                        });
                    }
                });
            } else if (fechasSel.type === "select") {
                //Quitar la fechaSel del dataSource
                data = $.grep(data, function (item) {
                    return item.fecha.toDateString() != fechasSel.date.toDateString();
                });

                $.each(fechasSel.events, function (index, item) {
                    newDates.push({
                        idEstado: item.idEstado,
                        fecha: fechasSel.date,
                        date: fechasSel.date, //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                        colorHexa: item.colorHexa ? item.colorHexa : "transparent",
                    });
                });

                if (fechasSel.events.length === 0) {
                    newDates.push({
                        idEstado: 0,
                        fecha: fechasSel.date,
                        date: fechasSel.date, //para que actualice YearCalendar al instante, antes de ir a personal y volver a YearCalendar
                        colorHexa: "transparent",
                    });
                }
            }

            let fechasCalendario = data.concat(newDates);
            var fechasCalendario_filterDuplicados = [];

            $.each(fechasCalendario, function (key, value) {
                var exists = false;

                $.each(fechasCalendario_filterDuplicados, function (k, val2) {
                    if (value.fecha.getTime() == val2.fecha.getTime() && value.idEstado === val2.idEstado) {
                        exists = true;
                    }
                });

                if (exists == false) fechasCalendario_filterDuplicados.push(value);
            });

            data = fechasCalendario_filterDuplicados;
            this.setState({ isVisible_dxPopup_selectDia: false }, () => {
                setTimeout(() => {
                    this.setState({
                        data: this.formatData(fechasCalendario_filterDuplicados),
                    });
                    this.props.yearCalendar_onDateChanged(data, newDates);
                }, 75);
            });
        }
    }

    onContentReady_dxPopup_selectDia(e) {
        var html = e.component.content();
        $(html).css("padding-top", "12px");
    }

    onHiding_dxPopup_selectDia(e) {
        this.setState({ isVisible_dxPopup_selectDia: false });
    }

    yearCalendar_onDayEnter(date) {
        let cellValue = date.date;
        let eventos = $.grep(date.events, function (item) {
            if (item.idEstado !== 0) return formatDate(item.date) === formatDate(cellValue);
        });

        if (eventos.length > 0) {
            $.each(eventos, function (index, item) {
                item.id = formatDate(cellValue).replaceAll("/", "");
            });
            this.setState({
                fechaHover: eventos,
                dxPopover_fechaHover_isVisible: true,
            });
        } else {
            if (this.state.dxPopover_fechaHover_isVisible) this.setState({ dxPopover_fechaHover_isVisible: false });
        }
    }

    yearCalendar_onDayLeave() {
        if (this.state.dxPopover_fechaHover_isVisible) this.setState({ dxPopover_fechaHover_isVisible: false });
    }

    yearCalendar_onDayClick(day) {
        this.setState({ isVisible_dxPopup_selectDia: true, fechasSel: day });
    }

    yearCalendar_onRangeSelected(dates) {
        this.setState({ isVisible_dxPopup_selectDia: true, fechasSel: dates });
    }

    yearCalendar_onYearChanged(data) {
        if (this.props.yearCalendar_onYearChanged) this.props.yearCalendar_onYearChanged(data);
    }

    yearCalendar_customDataSourceRenderer(element, date, eventos) {
        let { tipoCalendario } = this.props;

        $(element).parent().attr("id", formatDate(date).replaceAll("/", ""));

        if (tipoCalendario != "calendarioEntidad") {
            let colorNoNull = $.grep(eventos, function (item) {
                return item.color !== "transparent";
            });
            let colorFinal = colorNoNull.length > 0 ? colorNoNull[0].color : eventos[0].color;
            if (eventos[0].idEstado !== 0) {
                $(element)
                    .parent()
                    .css({
                        "border-radius": 0,
                        "background-color": colorFinal,
                        color:
                            getBrightnessColor(colorFinal) >= 200 || colorFinal === "transparent" ? "#484848" : "white",
                    });

                $(element).css({
                    "border-radius": 0,
                });

                if (
                    eventos.length > 1 &&
                    (tipoCalendario == "calendarioLaboral" || tipoCalendario == "personal") &&
                    $.grep(eventos, function (item) {
                        return item.idEstado === 10;
                    }).length === 0
                ) {
                    $(element).parent().css({
                        "border-radius": 0,
                        "background-color": "#FFFFFF",
                        color: "#484848",
                    });
                    $(element)
                        .css({
                            "font-size": "1px",
                            margin: "0 auto",
                            height: "24px",
                            width: "24px",
                        })
                        .addClass("position-relative")
                        .append(
                            svg_warning
                                .clone()
                                .css("height", "24px")
                                .css("width", "24px")
                                .css("cursor", "pointer")
                                .css("position", "absolute")
                                .css("left", "0px")
                                .css("top", "0px")
                        );
                }

                if (
                    $.grep(eventos, function (item) {
                        return item.idEstado === 10;
                    }).length > 0
                )
                    //día de cierre
                    $(element).addClass("diaCierre");
            } else {
                //Estado 0
                $(element).parent().css({
                    "border-radius": 0,
                    "background-color": "transparent",
                });
                $(element).css({
                    "border-radius": 0,
                });

                if (eventos.length > 0) {
                    $(element).parent().css({
                        color: "#484848",
                    });
                }
            }

            if (eventos[0].isDisabled) $(element).parent().addClass("disabled");
            else if (eventos[0].lugarRango === "inicio") {
                $(element).css("border-radius", "15px 0px 0px 15px");
                $(element).parent().css({
                    "border-radius": "15px 0px 0px 15px",
                    opacity: 1,
                });
            } else if (eventos[0].lugarRango === "fin") {
                $(element).css("border-radius", "0px 15px 15px 0px");
                $(element).parent().css({
                    "border-radius": "0px 15px 15px 0px",
                    opacity: 1,
                });
            } else if (eventos[0].lugarRango === "unico") {
                $(element).addClass("roundedDay");
                $(element).parent().addClass("roundedDay");
            }
        }
    }

    yearCalendar_customDayRenderer(element, date) {
        let { data } = this.state;
        let { tipoCalendario } = this.props;

        $(element).parent().attr("id", formatDate(date).replaceAll("/", ""));

        if (tipoCalendario == "calendarioEntidad") {
            let eventos = $.grep(data, function (item) {
                return item.date.getFullYear() === date.getFullYear() && item.date.getTime() === date.getTime();
            });
            let _data = $.grep(data, function (item) {
                return item.idTipoEstado === 1 || item.idTipoEstado === 2;
            });

            let fechasMenores_apertura = $.extend([], true, _data).filter(
                (v) => v.date.getTime() < date.getTime() && v.idTipoEstado == 1
            );
            fechasMenores_apertura = query(fechasMenores_apertura).sortBy("date").toArray();

            let ultimaFecha_apertura =
                fechasMenores_apertura.length > 0 ? fechasMenores_apertura[fechasMenores_apertura.length - 1] : null;

            let siguienteFecha_despuesApertura =
                fechasMenores_apertura.length > 0
                    ? $.extend([], true, _data).filter(
                          (v) =>
                              v.date.getTime() >
                              fechasMenores_apertura[fechasMenores_apertura.length - 1].date.getTime()
                      )
                    : null;
            siguienteFecha_despuesApertura =
                siguienteFecha_despuesApertura != null
                    ? query(siguienteFecha_despuesApertura).sortBy("date").toArray()
                    : [];

            let is_fechaEntre_aperturaCierre =
                ultimaFecha_apertura != null &&
                siguienteFecha_despuesApertura.length > 0 &&
                date.getTime() > ultimaFecha_apertura.date.getTime() &&
                date.getTime() < siguienteFecha_despuesApertura[0].date.getTime() &&
                siguienteFecha_despuesApertura[0].idTipoEstado == 2;

            let objDia_aperturas = $.grep(data, function (dia) {
                return dia.idTipoEstado === 1;
            });

            let objDia_cierres = $.grep(data, function (dia) {
                return dia.idTipoEstado === 2;
            });

            let ultimaApertura_año = query(objDia_aperturas).sortBy("date", true).toArray();

            let ultimoCierre_año = query(objDia_cierres).sortBy("date", true).toArray();

            if (
                (ultimaApertura_año.length > 0 &&
                    date.getTime() > ultimaApertura_año[0].fecha.getTime() && //Fecha es mayor a ultima apertura año y ultima apertura es mayor a ultimo cierre
                    ((ultimoCierre_año.length > 0 &&
                        ultimaApertura_año[0].fecha.getTime() > ultimoCierre_año[0].fecha.getTime()) ||
                        ultimoCierre_año.length == 0)) ||
                //Fecha está entre una apertura y un cierre
                is_fechaEntre_aperturaCierre
            ) {
                $(element).parent().addClass("yearCalendar_opacity");
                $(element).parent().css({ color: "white" });
            }

            if (eventos.length > 0 && eventos[0].idTipoEstado === 1) {
                $(element).parent().css({
                    "border-radius": "15px 0px 0px 15px",
                });
                $(element).css({
                    "border-radius": "15px 0px 0px 15px",
                });
            }

            if (eventos.length > 0 && eventos[0].idTipoEstado === 2) {
                $(element).parent().css({
                    "border-radius": "0px 15px 15px 0px",
                });
                $(element).css({
                    "border-radius": "0px 15px 15px 0px",
                });
            }
            if (eventos.length === 1 && eventos[0].idTipoEstado === 3) {
                $(element).addClass("roundedDay");

                $(element).parent().css({
                    background: eventos[0].color,
                });

                //Evento entre fechas o está después de última apertura año
                if (
                    is_fechaEntre_aperturaCierre ||
                    (ultimaApertura_año.length > 0 && date.getTime() > ultimaApertura_año[0].fecha.getTime())
                ) {
                    $(element).parent().addClass("singleDay_betweenDates");
                    $(element).css({
                        background: eventos[0].color,
                    });
                } else {
                    $(element).parent().addClass("roundedDay");
                    $(element).css({
                        background: eventos[0].color,
                    });
                }
            }

            if (eventos.length > 0) {
                $(element).parent().css({ color: "white" });

                if (eventos[0].isError) $(element).parent().addClass("errorDate");
                else $(element).parent().removeClass("errorDate");
            }
        }
    }

    //LOAD PANEL
    loadPanel_show(shading) {
        this.props.loadPanel_show(shading);
    }
    loadPanel_hide() {
        this.props.loadPanel_hide();
    }

    componentDidUpdate(prevProps) {
        let { data, estados, currentYear, yearCalendar_newData, idioma } = this.props;

        if (data !== prevProps.data || currentYear !== prevProps.currentYear || estados !== prevProps.estados) {
            this.setState({
                data: this.formatData(data),
                estados: estados,
                currentYear: currentYear,
                yearCalendar_newData: yearCalendar_newData,
            });
        }
    }

    componentDidMount() {
        let { data, estados, currentYear, yearCalendar_newData } = this.props;

        this.setState({
            data: this.formatData(data),
            estados: estados,
            currentYear: currentYear,
            yearCalendar_newData: yearCalendar_newData,
        });
    }
}

const mapStateToProps = (state) => ({
    resolucion: state.Global.resolucion,
    idioma: state.Global.idioma,
    user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
    loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
    loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(YearCalendar);
