const NumRegistroColumn = (getTrad) => ({
  dataField: "numRegistro",
  caption: getTrad("numRegistro"),
  minWidth: 130,
  alignment: "left",
  allowResizing: true,
  allowHeaderFiltering: false,
  showInColumnChooser: true,
  visible: false,
});

export default NumRegistroColumn;
