import React, { Fragment } from "react";

import { connect } from "react-redux";
import { globalActions, userActions } from "../../../actions";
import {
    history,
    getAplicaciones,
    getUrlFormularioInicio,
    dxMensajePregunta,
    getTrad,
    errorHandler,
    authHeader,
    patchRequestHandler,
} from "../../../helpers";

import $ from "jquery";

import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
    Container,
    Row,
    Col,
} from "reactstrap";

import PerfectScrollbar from "react-perfect-scrollbar";

import { setEnableMobileMenu, setEnableMobileMenuSmall } from "../../../reducers/ThemeOptions";
import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";

class HeaderDots extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
        };

        this.changeIdioma = this.changeIdioma.bind(this);
        this.toggle = this.toggle.bind(this);
        this.redirectPage = this.redirectPage.bind(this);
        this.seleccionarAplicacion = this.seleccionarAplicacion.bind(this);
    }

    changeIdioma = (idioma) => (e) => {
        this.props.setIdioma(idioma);
    };

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    redirectPage = (path) => (e) => {
        this.setState({ dropdownOpen: false });

        setTimeout(() => {
            history.push(path);
        }, 0);
    };

    seleccionarAplicacion = (e) => {
        const id = e.currentTarget.id;

        let { lavanderia, user } = this.props;
        let aplicaciones = getAplicaciones(null);

        let aplicacionSel = $.grep(aplicaciones, function (item) {
            return item.idAplicacion.toString() == id;
        })[0];

        let lavanderias = JSON.parse(localStorage.getItem("lavanderias"));
        let lavInicio;
        if (lavanderias.length > 0) {
            if (user.idLavanderiaInicio) {
                lavInicio = lavanderias.find((x) => x.idLavanderia === user.idLavanderiaInicio);
            } else {
                lavInicio = lavanderias[0];
            }
        }

        //Comprobación de permisos de empresa al cambiar de aplicación a Administración
        if (aplicacionSel.idAplicacion == 5 && user.tblEmpresaPolarierNUsuario.length == 0) {
            dxMensajePregunta(getTrad("alert_sinPermisos_empresa"), [
                [getTrad("aceptar"), function (e) {}, "danger", "btnErrorSinDatos"],
            ]);
            return;
        }

        this.props.setAplicacionActiva(aplicacionSel, lavInicio);

        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));

        history.push(getUrlFormularioInicio(lavanderia, aplicacionSel, null));
    };

    // #region Desarrolladores

    store_tblUsuario = new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblUsuario",
        key: "idUsuario",
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => (request.headers = { ...authHeader() }),
        version: 4,
    });

    setFormularioInicio = () => {
        const { user, formularioActivo } = this.props;
        if (user.tblFormularioInicio?.to === formularioActivo) {
            notify("Este ya es tu formulario de inicio", "info", 1000);
        } else {
            let formularios = Object.values(JSON.parse(localStorage.getItem("estructuraMenu")));
            let idFormulario = formularios
                .map((x) => x.content)
                .flat()
                .find((x) => x.to === formularioActivo)?.idFormulario;
            this.store_tblUsuario
                .update(user.idUsuario, patchRequestHandler({ idFormularioInicio: idFormulario }))
                .then(() => {
                    this.props.loginChangeUser(user.idUsuario, user.idUsuario, true);
                });
        }
    };

    refrescarLocalStorage = () => {
        let { user } = this.props;
        this.props.loginChangeUser(user.idUsuario, user.idUsuario, true);
    };

    // #endregion

    render() {
        const idiomas = JSON.parse(localStorage.getItem("idiomas"));
        let { idioma, lavanderia, aplicacionActiva, resolucion, user, formularioActivo } = this.props;
        let aplicaciones = getAplicaciones(null);
        let isVisible_btnAplicacion = aplicaciones ? aplicaciones.length > 1 : false;
        let isMobile = resolucion === "xs" || resolucion == "sm";
        let reg = /^#([0-9a-f]{3}){1,2}$/i;

        return (
            <Fragment>
                <div className="header-dots ">
                    {process.env.NODE_ENV === "development" && (
                        <div
                            className={`icon-wrapper icon-wrapper-alt rounded-circle p-0 mr-2`}
                            onClick={this.refrescarLocalStorage}
                        >
                            <div className="icon-wrapper-bg bg-focus" />
                            <div
                                style={{ lineHeight: "10px" }}
                                className="language-icon h4 text-uppercase font-size-xl"
                            >
                                <i className={`icon-gradient btn-icon-wrapper bg-dark-gray mb-0 dx-icon-refresh`} />
                            </div>
                        </div>
                    )}
                    {process.env.NODE_ENV === "development" && (
                        <div
                            className={`icon-wrapper icon-wrapper-alt rounded-circle p-0 ${
                                user.tblFormularioInicio?.to === formularioActivo ||
                                (user.tblFormularioInicio === null && formularioActivo === "/Informes")
                                    ? " bg-primary"
                                    : ""
                            }`}
                            onClick={this.setFormularioInicio}
                        >
                            <div className="icon-wrapper-bg bg-focus" />
                            <div
                                style={{ lineHeight: "10px" }}
                                className="language-icon h4 text-uppercase font-size-xl"
                            >
                                <i
                                    className={`icon-gradient btn-icon-wrapper ${
                                        user.tblFormularioInicio?.to === formularioActivo ||
                                        (user.tblFormularioInicio === null && formularioActivo === "/Informes")
                                            ? "bg-white"
                                            : "bg-dark-gray"
                                    } mb-0 dx-icon-home`}
                                />
                            </div>
                        </div>
                    )}

                    {process.env.NODE_ENV === "development" && <div className="header-btn-lg"></div>}

                    {aplicaciones && isVisible_btnAplicacion ? (
                        <UncontrolledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle className={"p-0 mr-2 "} color="none">
                                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <div className="icon-wrapper-bg bg-focus" />
                                    <div className="m-auto h4 text-uppercase font-size-xl d-flex">
                                        <i
                                            className={
                                                "icon-gradient btn-icon-wrapper bg-dark-gray mb-0 " +
                                                (aplicacionActiva ? aplicacionActiva.icon : "")
                                            }
                                        />
                                    </div>
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="rm-pointers border-0" style={{ borderRadius: 8 }}>
                                <div className="dropdown-menu-header mb-2">
                                    <div className="dropdown-menu-header-inner">
                                        <div className="menu-header-image img-CabeceraPolarier" />
                                        <div className="menu-header-content">
                                            <h5 className="menu-header-title">Workplace</h5>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <PerfectScrollbar className="ps-show-always">
                                        <div style={{ maxHeight: 350, minWidth: 380, width: "100%" }}>
                                            <div className="gridAplicaciones">
                                                {aplicaciones.map((child, i) => (
                                                    <div key={i} className="btnAplicaciones_container pt-2 pb-2">
                                                        <Button
                                                            id={child.idAplicacion.toString()}
                                                            style={{
                                                                "--appColor":
                                                                    child.color && reg.test(child.color)
                                                                        ? child.color
                                                                        : "#000000",
                                                            }}
                                                            className={
                                                                (isMobile ? "he-100" : "h-auto") +
                                                                " w-100 btnAplicacion rounded btn-icon-vertical btn-transition p-2 pb-2 btnApp " +
                                                                (aplicacionActiva &&
                                                                aplicacionActiva.idAplicacion == child.idAplicacion
                                                                    ? "activo"
                                                                    : null)
                                                            }
                                                            outline
                                                            color="link"
                                                            onClick={this.seleccionarAplicacion}
                                                        >
                                                            <i
                                                                className={
                                                                    "icon-gradient btn-icon-wrapper mb-2 " + child.icon
                                                                }
                                                            >
                                                                {" "}
                                                            </i>
                                                            <span className="text-break">
                                                                {child.tblTraduccion &&
                                                                child.tblTraduccion[idioma.codigo]
                                                                    ? child.tblTraduccion[idioma.codigo]
                                                                    : child.denominacion}
                                                            </span>
                                                        </Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </PerfectScrollbar>
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    ) : null}

                    <UncontrolledDropdown>
                        <DropdownToggle className="p-0 mr-2" color="none">
                            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                <div className="icon-wrapper-bg bg-focus" />
                                <div
                                    style={{ lineHeight: "10px" }}
                                    className="language-icon h4 text-uppercase font-size-xl"
                                >
                                    {idioma.codigo}
                                </div>
                            </div>
                        </DropdownToggle>
                        <DropdownMenu right className="rm-pointers">
                            <div className="dropdown-menu-header mb-2">
                                <div className="dropdown-menu-header-inner">
                                    <div className="menu-header-image img-CabeceraPolarier" />
                                    <div className="menu-header-content">
                                        <h5 className="menu-header-title">Idioma</h5>
                                    </div>
                                </div>
                            </div>

                            {idiomas.map((lang, i) => (
                                <div key={i}>
                                    <DropdownItem
                                        active={lang.idIdioma === idioma.idIdioma}
                                        onClick={this.changeIdioma(lang)}
                                    >
                                        <div className="icon-wrapper icon-wrapper-alt rounded-circle mr-3">
                                            <div className="icon-wrapper-bg bg-focus" />
                                            <div className="language-icon h6 text-uppercase">{lang.codigo}</div>
                                        </div>{" "}
                                        {lang.denominacion}
                                    </DropdownItem>
                                </div>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    idioma: state.Global.idioma,
    lavanderia: state.Global.lavanderia,
    formularioActivo: state.Global.formularioActivo,
    user: state.Authentication.user,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
    aplicacionActiva: state.Global.aplicacionActiva,
    resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
    setIdioma: (idioma) => dispatch(globalActions.setIdioma(idioma)),
    setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
    setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable)),
    setAplicacionActiva: (aplicacion, lavInicio) => dispatch(globalActions.setAplicacionActiva(aplicacion, lavInicio)),
    loginChangeUser: (idUsuarioSel, idUsuarioOriginal, isLogout) =>
        dispatch(userActions.loginChangeUser(idUsuarioSel, idUsuarioOriginal, isLogout)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderDots);
