export const password = {
  v: "5.7.4",
  fr: 30,
  ip: 0,
  op: 150,
  w: 500,
  h: 500,
  nm: "animation lock",
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: "Shape Layer 2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 26, ix: 10 },
        p: {
          a: 0,
          k: [245.00000000000003, 250.99999999999997, 0],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [1.163, -1444.186, 0], ix: 1, l: 2 },
        s: { a: 0, k: [-94, 94, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [257.836, 257.836], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.9882352941176471, 0.7254901960784313, 0.00392156862745098,
                  1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-0.082, -1449.082], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "tm",
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.403], y: [1] },
                o: { x: [0.559], y: [0] },
                t: 45,
                s: [100],
              },
              { t: 61, s: [0] },
            ],
            ix: 1,
          },
          e: {
            a: 1,
            k: [
              {
                i: { x: [0.003], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 54,
                s: [100],
              },
              { t: 63, s: [0] },
            ],
            ix: 2,
          },
          o: { a: 0, k: 0, ix: 3 },
          m: 1,
          ix: 2,
          nm: "Trim Paths 1",
          mn: "ADBE Vector Filter - Trim",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: "Artwork 26 Outlines",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 64,
              s: [0],
            },
            { t: 71.5, s: [100] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 69,
              s: [-12],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 75.857,
              s: [6],
            },
            { t: 81, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [246, 293, 0], ix: 2, l: 2 },
        a: { a: 0, k: [45.5, 118.235, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 64,
              s: [28.000000000000004, 28.000000000000004, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 71.5,
              s: [104, 104, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 77.833,
              s: [83, 83, 100],
            },
            { t: 81, s: [94, 94, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.464, 0],
                    [0.65, 2.47],
                    [0, 0],
                    [-15.463, 4.073],
                    [-4.072, -15.463],
                    [0, 0],
                    [2.94, -0.774],
                    [0.774, 2.94],
                    [0, 0],
                    [9.593, -2.526],
                    [-2.526, -9.593],
                    [0, 0],
                    [2.94, -0.774],
                  ],
                  o: [
                    [-2.439, 0],
                    [0, 0],
                    [-4.072, -15.463],
                    [15.463, -4.072],
                    [0, 0],
                    [0.775, 2.94],
                    [-2.937, 0.774],
                    [0, 0],
                    [-2.527, -9.593],
                    [-9.593, 2.527],
                    [0, 0],
                    [0.775, 2.939],
                    [-0.469, 0.124],
                  ],
                  v: [
                    [-19.195, 26.948],
                    [-24.514, 22.844],
                    [-28.046, 9.441],
                    [-7.387, -25.988],
                    [28.042, -5.331],
                    [31.342, 7.204],
                    [27.421, 13.929],
                    [20.698, 10.008],
                    [17.396, -2.527],
                    [-4.583, -15.344],
                    [-17.4, 6.637],
                    [-13.869, 20.041],
                    [-17.79, 26.765],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.3803921568627451, 0.3803921568627451, 0.3803921568627451, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [21.036, 52.852], ix: 2 },
              a: { a: 0, k: [-19.082, 25.904], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 70,
                    s: [-34],
                  },
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 76,
                    s: [23],
                  },
                  { t: 83, s: [13] },
                ],
                ix: 6,
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.956, 0],
                    [0, 5.954],
                    [-5.953, 0],
                    [0, -5.955],
                  ],
                  o: [
                    [-5.953, 0],
                    [0, -5.955],
                    [5.956, 0],
                    [0, 5.954],
                  ],
                  v: [
                    [0, 12.369],
                    [-10.781, 1.587],
                    [0, -9.194],
                    [10.781, 1.587],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ind: 1,
              ty: "sh",
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.217, 0],
                    [0, 0],
                    [0, -8.216],
                    [0, 0],
                    [-8.217, 0],
                    [0, 0],
                    [0, 8.217],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-8.217, 0],
                    [0, 0],
                    [0, 8.217],
                    [0, 0],
                    [8.217, 0],
                    [0, 0],
                    [0, -8.216],
                  ],
                  v: [
                    [30.402, -34.757],
                    [-30.402, -34.757],
                    [-45.343, -19.817],
                    [-45.343, 19.817],
                    [-30.402, 34.758],
                    [30.402, 34.758],
                    [45.343, 19.817],
                    [45.343, -19.817],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 2",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "mm",
              mm: 1,
              nm: "Merge Paths 1",
              mn: "ADBE Vector Filter - Merge",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9882352941176471, 0.7254901960784313, 0.00392156862745098,
                  1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [45.343, 84.252], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 6,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 6",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 81,
              s: [100],
            },
            { t: 84, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 31, ix: 10 },
        p: { a: 0, k: [306, 229.99999999999997, 0], ix: 2, l: 2 },
        a: { a: 0, k: [164, -1393, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [163.5, -1393],
                    [181.5, -1411.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 79,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.126], y: [1] },
                    o: { x: [0.512], y: [0] },
                    t: 74,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.788235294117647, 0.6196078431372549, 0.45098039215686275, 1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 5",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 81,
              s: [100],
            },
            { t: 84, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: 68, ix: 10 },
        p: { a: 0, k: [302, 248.25, 0], ix: 2, l: 2 },
        a: { a: 0, k: [164, -1393, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [163.5, -1393],
                    [181.5, -1411.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 79,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.126], y: [1] },
                    o: { x: [0.512], y: [0] },
                    t: 74,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.788235294117647, 0.6196078431372549, 0.45098039215686275, 1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: "Shape Layer 4",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 81,
              s: [100],
            },
            { t: 84, s: [0] },
          ],
          ix: 11,
        },
        r: { a: 0, k: -1, ix: 10 },
        p: { a: 0, k: [292.5, 215.75000000000003, 0], ix: 2, l: 2 },
        a: { a: 0, k: [164, -1393, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [163.5, -1393],
                    [181.5, -1411.5],
                  ],
                  c: false,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "tm",
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 79,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.126], y: [1] },
                    o: { x: [0.512], y: [0] },
                    t: 74,
                    s: [0],
                  },
                  { t: 84, s: [100] },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              m: 1,
              ix: 2,
              nm: "Trim Paths 1",
              mn: "ADBE Vector Filter - Trim",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [
                  0.788235294117647, 0.6196078431372549, 0.45098039215686275, 1,
                ],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 6, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Shape 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Artwork 25 Outlines",
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 60,
              s: [0],
            },
            { t: 62, s: [100] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.274], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 64.137,
              s: [-48],
            },
            {
              i: { x: [0.282], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 71,
              s: [12],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 78,
              s: [0],
            },
            { t: 83, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [307, 143, 0], ix: 2, l: 2 },
        a: { a: 0, k: [144.64, 7.435, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 60,
              s: [3, 3, 100],
            },
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 69.454,
              s: [107, 107, 100],
            },
            { t: 84, s: [107, 107, 100] },
          ],
          ix: 6,
          l: 2,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [2.629, 0.788],
                    [0, 0],
                    [1.165, -0.35],
                    [0, 0],
                    [0, -2.743],
                    [0, 0],
                    [-9.502, -12.127],
                    [-12.544, -9.08],
                    [-0.873, -0.39],
                    [-0.865, 0],
                    [-0.807, 0.363],
                    [-21.074, 15.254],
                    [-7.257, 9.261],
                    [0, 11.902],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.165, -0.35],
                    [0, 0],
                    [-2.626, 0.788],
                    [0, 0],
                    [0, 11.902],
                    [7.257, 9.261],
                    [21.072, 15.254],
                    [0.807, 0.362],
                    [0.866, 0],
                    [0.872, -0.39],
                    [12.543, -9.08],
                    [9.502, -12.126],
                    [0, 0],
                    [0, -2.743],
                  ],
                  v: [
                    [84.967, -80.815],
                    [1.786, -105.77],
                    [-1.785, -105.77],
                    [-84.969, -80.815],
                    [-89.395, -74.865],
                    [-89.395, 16.636],
                    [-75.075, 52.847],
                    [-45.234, 80.488],
                    [-2.538, 105.489],
                    [0, 106.032],
                    [2.539, 105.489],
                    [45.235, 80.488],
                    [75.075, 52.847],
                    [89.395, 16.636],
                    [89.395, -74.865],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [
                  0.9882352941176471, 0.9215686274509803, 0.7333333333333333, 1,
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [89.395, 106.032], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: -9,
      op: 291,
      st: -9,
      bm: 0,
    },
  ],
  markers: [],
};
