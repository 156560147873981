import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  formatDate_parameter,
  startOfMonth,
  formatNumber,
  endOfMonth,
  capitalize,
  dxRangeSelector_PosicionValor_nuevoMarker,
  customMonthViewer_rangeSelector,
  selectorMeses_dxRangeSelector,
  filtroTiempo_Resize,
} from "../../../../helpers";

import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { locale } from "devextreme/localization";

//Layout
import PageTitle from "../../../../layout/AppMain/PageTitle";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import ScrollView from "devextreme-react/scroll-view";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Summary,
  TotalItem,
  Scrolling,
  Grouping,
  GroupItem,
  Export,
} from "devextreme-react/data-grid";

import Box, { Item as ItemBox } from "devextreme-react/box";
import { RangeSelector, SliderMarker } from "devextreme-react/range-selector";

// Export excel
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from "devextreme/excel_exporter";

class InfFactUnif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fechaDesde: null,
      fechaHasta: null,
      datos_FiltroTiempo: [],
      datos: [],
      idDepartamento: null,
      idPersona: null,
      group: props.lavanderia.isMyUniformCantidad
        ? "departamento"
        : "tipoPrenda",
      timeout_RangeSelector_valueChanged: null,
      rangeSelector: {
        tipo: null, // mensual, anual, diario
      },
    };

    //DATASOURCE
    //Filtro Tiempo
    this.datasource_datos_filtroTiempo_beforeSend =
      this.datasource_datos_filtroTiempo_beforeSend.bind(this);
    this.datasource_datos_filtroTiempo_onLoaded =
      this.datasource_datos_filtroTiempo_onLoaded.bind(this);

    //Datos
    this.datasource_datos_beforeSend =
      this.datasource_datos_beforeSend.bind(this);
    this.datasource_datos_onLoaded = this.datasource_datos_onLoaded.bind(this);

    //Datos isCantidad
    this.datasource_datos_isCantidad_beforeSend =
      this.datasource_datos_isCantidad_beforeSend.bind(this);
    this.datasource_datos_isCantidad_onLoaded =
      this.datasource_datos_isCantidad_onLoaded.bind(this);

    //EVENTOS
    //Grid
    this.onValueChanged_SelectBox_Group =
      this.onValueChanged_SelectBox_Group.bind(this);
    this.gridCantidad_onToolbarPreparing =
      this.gridCantidad_onToolbarPreparing.bind(this);
    this.grid_onToolbarPreparing = this.grid_onToolbarPreparing.bind(this);
    this.gridCantidad_onExporting = this.gridCantidad_onExporting.bind(this);
    this.grid_onExporting = this.grid_onExporting.bind(this);

    //Range selector
    this.dxRangeSelector_onValueChanged =
      this.dxRangeSelector_onValueChanged.bind(this);
    this.dxRangeSelector_onDrawn = this.dxRangeSelector_onDrawn.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  //#region DATSOURCES

  //GENERAL
  datasource_datos_filtroTiempo = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spSelectFacturacionUniformidad_filtroTiempo",
      key: "arg",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_filtroTiempo_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_datos_filtroTiempo_onLoaded(data);
      },
      version: 4,
    }),
  });
  datasource_datos_filtroTiempo_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    request.params.idLavanderia = lavanderia.idLavanderia;
  }
  datasource_datos_filtroTiempo_onLoaded(data) {
    let ultimaFecha = data[data.length - 1].arg;

    let fechaDesde = startOfMonth(ultimaFecha);
    let fechaHasta = ultimaFecha;

    this.setState({
      datos_FiltroTiempo: data,
      fechaHasta: fechaHasta,
      fechaDesde: fechaDesde,
    });

    if (this.props.lavanderia.isMyUniformCantidad)
      this.datasource_datos_isCantidad.load();
    else this.datasource_datos.load();
  }

  datasource_datos = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "spSelectFacturacionUniformidad",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_datos_onLoaded(data);
      },
      version: 4,
    }),
  });
  datasource_datos_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    let { fechaDesde, fechaHasta } = this.state;

    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }
  datasource_datos_onLoaded(data) {
    $.each(data, function (index, item) {
      if (!item.idPersona) {
        item.persona = getTrad("prendasNoAsignadas").toUpperCase();
        item.departamento = getTrad("prendasNoAsignadas").toUpperCase();
      }
    });

    this.setState({ datos: data });
    this.loadPanel_hide();
  }

  datasource_datos_isCantidad = new DataSource({
    paginate: false,
    store: new ODataStore({
      url:
        connectionConstants.ODATA_URL +
        "spSelectFacturacionUniformidad_isCantidad",
      key: "idPersona",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_datos_isCantidad_beforeSend(request);
      },
      onLoaded: (data) => {
        this.datasource_datos_isCantidad_onLoaded(data);
      },
      version: 4,
    }),
  });
  datasource_datos_isCantidad_beforeSend(request) {
    request.headers = { ...authHeader() };
    let { lavanderia } = this.props;
    let { fechaDesde, fechaHasta } = this.state;

    request.params.idLavanderia = lavanderia.idLavanderia;
    request.params.fechaDesde = formatDate_parameter(fechaDesde);
    request.params.fechaHasta = formatDate_parameter(fechaHasta);
  }
  datasource_datos_isCantidad_onLoaded(data) {
    $.each(data, function (index, item) {
      if (!item.idPersona) {
        item.persona = getTrad("prendasNoAsignadas").toUpperCase();
        item.departamento = getTrad("prendasNoAsignadas").toUpperCase();
      }
    });

    this.setState({ datos: data });
    this.loadPanel_hide();
  }

  cargaDatos_lavanderia() {
    this.setState({
      group: this.props.lavanderia.isMyUniformCantidad
        ? "departamento"
        : "tipoPrenda",
    });
    this.datasource_datos_filtroTiempo.load();
  }

  enum_group = ["tipoPrenda", "departamento", "persona"];

  render() {
    let _this = this;
    if (this.state.datos_FiltroTiempo <= 0) return false;
    let { fechaDesde, fechaHasta, datos_FiltroTiempo, datos, group } =
      this.state;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="InfFactUnif" className="formContainer scrollbar-container">
            <Box
              direction="col"
              align="space-around"
              crossAlign="stretch"
              height={"100%"}
            >
              <ItemBox ratio={1}>
                {this.props.lavanderia.isMyUniformCantidad ? (
                  <DataGrid
                    //Datos
                    dataSource={datos}
                    //Propiedades
                    columnsAutoWidth={true}
                    height={"100%"}
                    width={"100%"}
                    hoverStateEnabled={true}
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onExporting={this.gridCantidad_onExporting}
                    onToolbarPreparing={this.gridCantidad_onToolbarPreparing}
                  >
                    <Export enabled={true} />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <Scrolling mode="virtual" />
                    <Pager visible={false} />
                    <Grouping autoExpandAll={false} />
                    <Column
                      visibleIndex={group === "departamento" ? 0 : 1}
                      visible={group === "departamento"}
                      dataField=""
                      caption={getTrad(group)}
                      width={250}
                    />
                    <Column
                      dataField="departamento"
                      caption={getTrad("departamento")}
                      alignment="left"
                      groupIndex={
                        group === "departamento"
                          ? 0
                          : group === "tipoPrenda"
                            ? 1
                            : "undefined"
                      }
                      groupCellRender={this.groupCellRender}
                    />
                    <Column
                      visibleIndex={group === "departamento" ? 1 : 0}
                      dataField="codPersona"
                      width={90}
                      caption={getTrad("codigo")}
                      allowHeaderFiltering={false}
                      dataType="number"
                      alignment="left"
                      sortOrder="asc"
                    />
                    <Column
                      visibleIndex={group === "departamento" ? 2 : 1}
                      dataField="persona"
                      caption={getTrad("persona")}
                      alignment="left"
                      sortOrder="asc"
                    />
                    <Column
                      dataField="precio"
                      caption={getTrad("precio")}
                      width={1}
                      alignment="center"
                      allowHeaderFiltering={false}
                      dataType="number"
                      format={this.currency_format}
                      editorOptions={this.currency_editorOptions}
                    />
                    <Column
                      dataField="entregas"
                      caption={getTrad("cantidad")}
                      width={100}
                      alignment="center"
                      allowHeaderFiltering={false}
                      dataType="number"
                    />
                    <Column
                      caption={getTrad("total")}
                      name="total"
                      dataType="number"
                      alignment="center"
                      width={120}
                      format={this.currency_format}
                      calculateCellValue={this.total_calculateCellValue}
                      allowFiltering={false}
                    />
                    <Summary>
                      <GroupItem
                        column="precio"
                        summaryType="avg"
                        valueFormat={this.currency_format}
                        displayFormat={"{0}"}
                        alignByColumn={true}
                      />
                      <GroupItem
                        column="entregas"
                        summaryType="sum"
                        displayFormat="{0}"
                        alignByColumn={true}
                      />
                      <GroupItem
                        column="total"
                        summaryType="sum"
                        valueFormat={this.currency_format}
                        displayFormat="{0}"
                        alignByColumn={true}
                      />
                      <TotalItem
                        summaryType="avg"
                        valueFormat={this.currency_format}
                        displayFormat={getTrad("precio") + ": " + "{0}"}
                        showInColumn="departamento"
                        alignment="right"
                        column="precio"
                      />
                      {group === "departamento" ? (
                        <TotalItem
                          summaryType="avg"
                          valueFormat={this.currency_format}
                          displayFormat={getTrad("precio") + ": " + "{0}"}
                          showInColumn="persona"
                          alignment="right"
                          column="precio"
                        />
                      ) : (
                        ""
                      )}
                      <TotalItem
                        summaryType="sum"
                        displayFormat={"{0}"}
                        column="entregas"
                      />
                      <TotalItem
                        summaryType="sum"
                        valueFormat={this.currency_format}
                        displayFormat={"{0}"}
                        column="total"
                      />
                    </Summary>
                  </DataGrid>
                ) : (
                  <DataGrid
                    //Datos
                    dataSource={datos}
                    //Propiedades
                    columnsAutoWidth={true}
                    height={"100%"}
                    width={"100%"}
                    hoverStateEnabled={true}
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onExporting={this.grid_onExporting}
                    onToolbarPreparing={this.grid_onToolbarPreparing}
                  >
                    <Export enabled={true} />
                    <HeaderFilter visible={true} />
                    <FilterRow visible={true} />
                    <Scrolling mode="virtual" />
                    <Pager visible={false} />
                    <Grouping autoExpandAll={false} />
                    <Column dataField="" caption={getTrad(group)} width={250} />
                    <Column
                      dataField="departamento"
                      caption={getTrad("departamento")}
                      alignment="left"
                      groupIndex={
                        group === "departamento"
                          ? 0
                          : group === "tipoPrenda"
                            ? 1
                            : "undefined"
                      }
                      groupCellRender={this.groupCellRender}
                    />
                    <Column
                      dataField="persona"
                      caption={getTrad("persona")}
                      alignment="left"
                      sortOrder="asc"
                      groupIndex={group === "persona" ? 0 : "undefined"}
                      groupCellRender={this.groupCellRender}
                    />
                    <Column
                      dataField="tipoPrenda"
                      caption={getTrad("tipoPrenda")}
                      alignment="left"
                      groupIndex={
                        group === "tipoPrenda"
                          ? 0
                          : group === "departamento"
                            ? 1
                            : "undefined"
                      }
                      groupCellRender={this.groupCellRender}
                    />
                    <Column
                      dataField="precio"
                      caption={getTrad("precio")}
                      width={100}
                      alignment="center"
                      allowHeaderFiltering={false}
                      dataType="number"
                      format={this.currency_format}
                      editorOptions={this.currency_editorOptions}
                    />
                    <Column
                      dataField="entregas"
                      caption={getTrad("cantidad")}
                      width={100}
                      alignment="center"
                      allowHeaderFiltering={false}
                      dataType="number"
                    />
                    <Column
                      dataField="peso"
                      caption={getTrad("pesoKg")}
                      width={100}
                      alignment="center"
                      allowHeaderFiltering={false}
                      dataType="number"
                    />
                    <Column
                      caption={getTrad("total")}
                      name="total"
                      dataType="number"
                      alignment="center"
                      width={100}
                      format={this.currency_format}
                      calculateCellValue={this.total_calculateCellValue}
                      allowFiltering={false}
                    />
                    <Summary>
                      {group === "tipoPrenda" ? (
                        <GroupItem
                          column="precio"
                          summaryType="max"
                          valueFormat={this.currency_format}
                          displayFormat="{0}"
                          alignByColumn={true}
                        />
                      ) : null}
                      <GroupItem
                        column="entregas"
                        summaryType="sum"
                        displayFormat="{0}"
                        alignByColumn={true}
                      />
                      <GroupItem
                        column="peso"
                        summaryType="sum"
                        valueFormat={this.peso_format}
                        displayFormat="{0}"
                        alignByColumn={true}
                      />
                      <GroupItem
                        column="total"
                        summaryType="sum"
                        valueFormat={this.currency_format}
                        displayFormat="{0}"
                        alignByColumn={true}
                      />
                      <TotalItem
                        summaryType="sum"
                        displayFormat={"{0}"}
                        column="entregas"
                      />
                      <TotalItem
                        summaryType="sum"
                        valueFormat={this.peso_format}
                        displayFormat={"{0}"}
                        column="peso"
                      />
                      <TotalItem
                        summaryType="sum"
                        valueFormat={this.currency_format}
                        displayFormat={"{0}"}
                        column="total"
                      />
                    </Summary>
                  </DataGrid>
                )}
              </ItemBox>
              <ItemBox baseSize={120}>
                <ScrollView
                  elementAttr={{ id: "scrollView_dxRangeSelector" }}
                  showScrollbar="always"
                  direction="horizontal"
                  height="100%"
                  width="100%"
                >
                  <RangeSelector
                    elementAttr={{ id: "dxRangeSelector_filtroTiempo" }}
                    behavior={{
                      animationEnabled: false,
                      callValueChanged: "onMovingComplete",
                    }}
                    dataSource={datos_FiltroTiempo}
                    value={[fechaDesde, fechaHasta]}
                    mesesVisibles={{ diario: 4, mensual: 12, anual: 72 }}
                    defaultValue={[
                      this.state.fechaDesde,
                      this.state.fechaHasta,
                    ]}
                    valueHasta={
                      new Date(
                        this.datasource_datos_filtroTiempo[
                          this.datasource_datos_filtroTiempo.length
                        ],
                      )
                    } // value[1] , sirve para por ejemplo mensual restar al value 1 dia y asignar la fecha a este, asi no desvirtua la fecha visible.
                    is_anual={this.state.rangeSelector["tipo"] === "anual"}
                    is_mensual={this.state.rangeSelector["tipo"] === "mensual"}
                    size={{
                      height: 150,
                      width: "100%",
                    }}
                    sliderMarker={{
                      format: "monthAndDay",
                      paddingLeftRight: 30,
                      customizeText: function (e) {
                        if (
                          typeof $("#dxRangeSelector_filtroTiempo").data(
                            "dxRangeSelector",
                          ) !== "undefined" &&
                          $("#dxRangeSelector_filtroTiempo")
                            .data("dxRangeSelector")
                            .option("value") &&
                          ($("#dxRangeSelector_filtroTiempo")
                            .dxRangeSelector("instance")
                            .option("scale.tickInterval") === "month" ||
                            $("#dxRangeSelector_filtroTiempo")
                              .dxRangeSelector("instance")
                              .option("is_daytoDaySelection") ||
                            $("#dxRangeSelector_filtroTiempo")
                              .dxRangeSelector("instance")
                              .option("scale.tickInterval") === "year") // si es mensual, o es day to day o es anual
                        ) {
                          dxRangeSelector_PosicionValor_nuevoMarker(
                            $("#dxRangeSelector_filtroTiempo"),
                          );
                        } else return e.valueText;
                      },
                    }}
                    scale={{
                      maxRange: null,
                      startValue: null,
                      endValue: null,
                      tickInterval: "day",
                      minorTickInterval: "day",
                      aggregationInterval: null,
                      valueType: "datetime",
                      minorTick: {
                        visible: false,
                      },
                      marker: {
                        label: {
                          customizeText: function (e) {
                            if (_this.state.rangeSelector["tipo"] === "anual") {
                              return e.valueText;
                            } else {
                              return (
                                capitalize(
                                  new Intl.DateTimeFormat(locale(), {
                                    month: "long",
                                  }).format(e.value),
                                ) +
                                " (" +
                                e.value.getFullYear() +
                                ")"
                              );
                            }
                          },
                        },
                      },
                      label: {
                        customizeText: function (e) {
                          if (_this.state.rangeSelector["tipo"] === "anual") {
                            var fecha = new Date(e.value);
                            var mes = fecha.toLocaleString("es-ES", {
                              month: "short",
                            });
                            var año = fecha.getFullYear();
                            var text = mes;

                            if (año !== new Date().getFullYear()) {
                              var displayYear =
                                " '" +
                                e.value.getFullYear().toString().substr(-2);
                              text += " " + displayYear;
                            }

                            return text.toUpperCase();
                          }
                          return e.valueText;
                        },
                      },
                      //  ,endValue: _this.state.tabPanel_data.endValue
                    }}
                    onInitialized={this.dxRangeSelector_onInitialized}
                    onValueChanged={this.dxRangeSelector_onValueChanged}
                    onDrawn={this.dxRangeSelector_onDrawn}
                  >
                    <SliderMarker format="monthAndDay" />
                  </RangeSelector>
                </ScrollView>
              </ItemBox>
            </Box>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //CONFIG GRID
  tblTipoPrenda_selection = { mode: "single" };

  //FORMATS
  currency_format = {
    style: "currency",
    maximumFractionDigits: 2,
    currency: this.props.lavanderia.moneda,
  };

  peso_format = { maximumFractionDigits: 2 };

  //#region Eventos
  displayExpr_SelectBox_Group(item) {
    return getTrad(item);
  }
  onValueChanged_SelectBox_Group(e) {
    this.setState({ group: e.value });
  }

  groupCellRender(e) {
    return <div>{e.text}</div>;
  }

  total_calculateCellValue(rowData) {
    return rowData.precio * rowData.entregas;
  }

  gridCantidad_onToolbarPreparing(e) {
    let _this = this;
    let { group } = this.state;
    let filter_datasource_agrupacion = $.grep(
      _this.enum_group,
      function (item) {
        return item !== "tipoPrenda";
      },
    );

    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div class ='font-size pr-2 mt-2''>").text(
            getTrad("agrupacion") + ":",
          );
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          dataSource: filter_datasource_agrupacion,
          value: group,
          width: 200,
          displayExpr: _this.displayExpr_SelectBox_Group,
          onValueChanged: _this.onValueChanged_SelectBox_Group,
        },
      },
    );
  }

  grid_onToolbarPreparing(e) {
    let _this = this;
    let { group } = this.state;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        template: function () {
          return $("<div class ='font-size pr-2 mt-2''>").text(
            getTrad("agrupacion") + ":",
          );
        },
      },
      {
        location: "before",
        widget: "dxSelectBox",
        options: {
          dataSource: _this.enum_group,
          value: group,
          width: 200,
          displayExpr: _this.displayExpr_SelectBox_Group,
          onValueChanged: _this.onValueChanged_SelectBox_Group,
        },
      },
    );
  }

  gridCantidad_onExporting(e) {
    let _this = this;
    let { group } = this.state;
    e.component.beginUpdate();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    if (group === "departamento") {
      worksheet.columns = [
        { width: 50 },
        { width: 15 },
        { width: 50 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];
    } else {
      worksheet.columns = [
        { width: 15 },
        { width: 50 },
        { width: 50 },
        { width: 15 },
        { width: 15 },
        { width: 15 },
      ];
    }
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      customizeCell: function ({ gridCell, excelCell }) {
        gridCell.font = { name: "Arial", size: 12 };
        gridCell.alignment = { horizontal: "left" };

        if (gridCell.rowType === "data") {
          if (
            gridCell.column.dataField === "precio" ||
            gridCell.column.dataField === "total"
          ) {
            excelCell.value = formatNumber(
              gridCell.value,
              2,
              "currency",
              _this.currency_format.currency,
            );
          }
        }
        if (gridCell.rowType === "group") {
          excelCell.font = { bold: true };
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: gridCell.groupIndex == 0 ? "EDB637" : "eeeeee" },
          };
        }
        if (gridCell.rowType === "totalFooter" && excelCell.value) {
          excelCell.font.italic = true;
        }
      },
    }).then(function () {
      workbook.xlsx
        .writeBuffer()
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx",
          );
        })
        .then(function () {
          e.component.columnOption("isEnDestino", "visible", true);
          e.component.endUpdate();
        });
    });
    e.cancel = true;
  }

  grid_onExporting(e) {
    let _this = this;
    e.component.beginUpdate();
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Sheet");
    worksheet.columns = [
      { width: 50 },
      { width: 50 },
      { width: 50 },
      { width: 15 },
      { width: 15 },
      { width: 15 },
    ];
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      customizeCell: function ({ gridCell, excelCell }) {
        gridCell.font = { name: "Arial", size: 12 };
        gridCell.alignment = { horizontal: "left" };

        if (gridCell.rowType === "data") {
          if (
            gridCell.column.dataField === "precio" ||
            gridCell.column.dataField === "total"
          ) {
            excelCell.value = formatNumber(
              gridCell.value,
              2,
              "currency",
              _this.currency_format.currency,
            );
          }
        }
        if (gridCell.rowType === "group") {
          excelCell.font = { bold: true };
          excelCell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: gridCell.groupIndex == 0 ? "EDB637" : "eeeeee" },
          };
        }
        if (gridCell.rowType === "totalFooter" && excelCell.value) {
          excelCell.font.italic = true;
        }
      },
    }).then(function () {
      workbook.xlsx
        .writeBuffer()
        .then(function (buffer) {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "DataGrid.xlsx",
          );
        })
        .then(function () {
          e.component.columnOption("isEnDestino", "visible", true);
          e.component.endUpdate();
        });
    });
    e.cancel = true;
  }

  dxRangeSelector_onValueChanged(e) {
    let _this = this;
    this.setState({
      fechaDesde: new Date(e.value[0]),
      fechaHasta: new Date(e.value[1]),
    });

    if (this.state.timeout_RangeSelector_valueChanged)
      clearTimeout(this.state.timeout_RangeSelector_valueChanged);

    e.component.option("valueHasta", new Date(e.value[1]));

    if (
      e.component.option("scale.tickInterval") === "month" ||
      e.component.option("scale.tickInterval") === "year"
    ) {
      //MENSUAL
      if (e.value[1].getDate() === 1) {
        var d = new Date(e.value[1]);
        e.component.option("valueHasta", new Date(d.setDate(d.getDate() - 1)));
      }
      dxRangeSelector_PosicionValor_nuevoMarker(e.element);
    }

    _this.loadPanel_show(true);
    if (this.props.lavanderia.isMyUniformCantidad)
      _this.datasource_datos_isCantidad.load();
    else _this.datasource_datos.load();
  }

  dxRangeSelector_onInitialized(e) {
    setTimeout(() => {
      filtroTiempo_Resize();
    }, 0);
  }

  dxRangeSelector_onDrawn(e) {
    if (
      e.component.option("scale.tickInterval") === "month" ||
      e.component.option("scale.tickInterval") === "year"
    ) {
      customMonthViewer_rangeSelector();
    } else {
      selectorMeses_dxRangeSelector(e.component);
    }
  }

  //#endregion

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    this.cargaDatos_lavanderia();

    let { lavanderia } = this.props;
    this.currency_editorOptions = {
      format: lavanderia.moneda,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let { lavanderia } = this.props;
    if (
      lavanderia &&
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia
    ) {
      this.loadPanel_show();
      this.currency_format.currency = lavanderia.moneda;
      this.currency_editorOptions.format = this.currency_format;

      this.currency_format = {
        style: "currency",
        maximumFractionDigits: 2,
        currency: this.props.lavanderia.moneda,
      };

      this.cargaDatos_lavanderia();
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfFactUnif);
