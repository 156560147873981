import React from "react";

export class editCellComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick = () => {
    const { data } = this.props;
    data.component.editRow(data.rowIndex);
  };

  render() {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={this.onClick}
      >
        <div className="icon_Edit font-size-lg" />
      </div>
    );
  }
}
