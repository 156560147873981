import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  getNombreFormulario,
  errorHandler,
  authHeader,
  getTrad,
  dxMensajePregunta,
  get_isMovil,
} from "../../../helpers";

import $ from "jquery";
//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";
import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import Box, { Item as ItemBox } from "devextreme-react/box";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  KeyboardNavigation,
  Editing,
  LoadPanel,
  Summary,
  TotalItem,
  Scrolling,
  Selection,
  RemoteOperations,
} from "devextreme-react/data-grid";
import { Tooltip } from "devextreme-react/tooltip";

import ScrollView from "devextreme-react/scroll-view";
import { Switch } from "devextreme-react/switch";
import "./Css.scss";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sistemaMaquinaSel: null,
      categoriaMaquinaSel: null,
      sistemaMaquinaSel_bk: null, //Row seleccionada antes de popup se perderán los cambios,
      categoriaMaquinaSel_bk: null, //Row seleccionada antes de popup se perderán los cambios,
    };

    // REFERENCIAS
    this.dxDataGrid_tblSistemaMaquina_REF = React.createRef();
    this.dxDataGrid_tblCategoriaMaquina_REF = React.createRef();
    this.dxDataGrid_tblMaquina_REF = React.createRef();

    this.update_datasources = this.update_datasources.bind(this);
    this.cargaDatos_lavanderia = this.cargaDatos_lavanderia.bind(this);

    //DATASOURCES
    this.datasource_tblSistemaMaquina_beforeSend =
      this.datasource_tblSistemaMaquina_beforeSend.bind(this);
    this.datasource_tblSistemaMaquina_onLoaded =
      this.datasource_tblSistemaMaquina_onLoaded.bind(this);
    this.datasource_tblSistemaMaquina_onLoading =
      this.datasource_tblSistemaMaquina_onLoading.bind(this);

    //EVENTOS
    this.onToolbarPreparing_tblSistemaMaquina =
      this.onToolbarPreparing_tblSistemaMaquina.bind(this);
    this.onRowUpdating_tblSistemaMaquina =
      this.onRowUpdating_tblSistemaMaquina.bind(this);
    this.onSelectionChanged_tblSistemaMaquina =
      this.onSelectionChanged_tblSistemaMaquina.bind(this);
    this.onRowUpdated_tblSistemaMaquina =
      this.onRowUpdated_tblSistemaMaquina.bind(this);
    this.setCellValue_tblPesoNSistemaNLavanderia =
      this.setCellValue_tblPesoNSistemaNLavanderia.bind(this);
    this.onRowUpdating_tblCategoriaMaquina =
      this.onRowUpdating_tblCategoriaMaquina.bind(this);
    this.onToolbarPreparing_tblCategoriaMaquina =
      this.onToolbarPreparing_tblCategoriaMaquina.bind(this);
    this.onSelectionChanged_tblCategoriaMaquina =
      this.onSelectionChanged_tblCategoriaMaquina.bind(this);
    this.editCellRender_restrictiva_tblMaquinas =
      this.editCellRender_restrictiva_tblMaquinas.bind(this);
    this.onValueChanged_SwitchRestrictiva_tblMaquinas =
      this.onValueChanged_SwitchRestrictiva_tblMaquinas.bind(this);
    this.onRowUpdating_tblMaquina = this.onRowUpdating_tblMaquina.bind(this);
    this.onToolbarPreparing_tblMaquina =
      this.onToolbarPreparing_tblMaquina.bind(this);
    this.onAdaptiveDetailRowPreparing_tblMaquina =
      this.onAdaptiveDetailRowPreparing_tblMaquina.bind(this);
    this.onEditingStart_tblMaquina = this.onEditingStart_tblMaquina.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();
  }

  get dxDataGrid_tblSistemaMaquina() {
    return this.dxDataGrid_tblSistemaMaquina_REF.current.instance;
  }

  get dxDataGrid_tblCategoriaMaquina() {
    return this.dxDataGrid_tblCategoriaMaquina_REF.current.instance;
  }

  get dxDataGrid_tblMaquina() {
    return this.dxDataGrid_tblMaquina_REF.current.instance;
  }

  //#region DATASOURCES
  datasource_tblSistemaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblSistemaMaquina",
      key: "idSistemaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblSistemaMaquina_beforeSend(request);
      },
      onLoading: (loadOptions) => {
        this.datasource_tblSistemaMaquina_onLoading(loadOptions);
      },
      onLoaded: (data) => {
        this.datasource_tblSistemaMaquina_onLoaded(data);
      },
      version: 4,
    }),
  });
  datasource_tblSistemaMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.idLavanderia = this.props.lavanderia.idLavanderia;
  }

  datasource_tblSistemaMaquina_onLoading(loadOptions) {
    var idLavanderia = this.props.lavanderia.idLavanderia;

    loadOptions.expand = [
      "tblPesoNSistemaNLavanderia($filter=idLavanderia eq " +
        idLavanderia +
        "),tblCategoriaMaquina($select=idCategoriaMaquina,denominacion,orden;$expand=tblPesoNCategoriaNLavanderia($filter=idLavanderia eq " +
        idLavanderia +
        "),tblTipoMaquinaNCategoriaMaquina($select=tblMaquina;$expand=tblMaquina($filter= ((eliminado eq false and activo eq true)) and idLavanderia eq " +
        idLavanderia +
        ")))",
    ];
  }

  datasource_tblSistemaMaquina_onLoaded(data) {
    let { lavanderia } = this.props;
    $.each(data, function (index, item) {
      if (item.tblPesoNSistemaNLavanderia.length === 0) {
        item.tblPesoNSistemaNLavanderia.push({
          idLavanderia: lavanderia.idLavanderia,
          idSistemaMaquina: item.idSistemaMaquina,
          peso: 0,
        });
      }

      var pesosCategoria = 0;
      var numMaquinasSist = 0;
      var hayMaquinaSinPesoSist = false;
      $.each(item.tblCategoriaMaquina, function (index, cat) {
        if (cat.tblPesoNCategoriaNLavanderia.length === 0) {
          cat.tblPesoNCategoriaNLavanderia.push({
            idLavanderia: lavanderia.idLavanderia,
            idCategoriaMaquina: cat.idCategoriaMaquina,
            peso: 0,
          });
        } else {
          pesosCategoria += cat.tblPesoNCategoriaNLavanderia[0].peso;
        }

        var pesosMaquinas = 0;
        var numMaquinasCat = 0;
        var hayMaquinaSinPesoCat = false;

        $.each(cat.tblTipoMaquinaNCategoriaMaquina, function (index, tmNCat) {
          $.each(tmNCat.tblMaquina, function (index, maq) {
            pesosMaquinas += maq.peso;

            numMaquinasCat++;
            if (!(maq.peso > 0)) {
              hayMaquinaSinPesoSist = true;
              hayMaquinaSinPesoCat = true;
            }
          });
        });

        if (
          cat.tblPesoNCategoriaNLavanderia[0].peso === 0 &&
          numMaquinasCat > 0
        ) {
          hayMaquinaSinPesoSist = true;
        }

        numMaquinasSist += numMaquinasCat;
        cat.error = null;
        if (numMaquinasCat === 0) {
          //GRIS
          cat.error = "sinMaquinas";
        } else if (pesosMaquinas != 100) {
          //ROJO
          cat.error = "peso";
          pesosCategoria = -1;
        } else if (hayMaquinaSinPesoCat) {
          //AMARILLO
          cat.error = "maqSinPeso";
        }
      });

      item.error = null;
      if (numMaquinasSist === 0) {
        //GRIS
        item.error = "sinMaquinas";
      } else if (pesosCategoria != 100) {
        //ROJO
        item.error = "peso";
      } else if (hayMaquinaSinPesoSist) {
        //AMARILLO
        item.error = "maqSinPeso";
      }
    });

    this.loadPanel_hide();
    return data;
  }

  datasource_tblPesoNSistemaNLavanderia = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblPesoNSistemaNLavanderia",
      key: "idSistemaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      version: 4,
    }),
  });

  datasource_tblCategoriaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoriaMaquina",
      key: "idCategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblCategoriaMaquina_beforeSend(request);
      },
      version: 4,
    }),
  });
  datasource_tblCategoriaMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
  }

  datasource_tblMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_tblMaquina_beforeSend(request);
      },
      version: 4,
    }),
  });
  datasource_tblMaquina_beforeSend(request) {
    request.headers = { ...authHeader() };
    request.params.idLavanderia = this.props.lavanderia.idLavanderia;
  }
  //#endregion

  //#region TRADUCCIONES
  localTrad = [];
  getTrad(key) {
    let codigoIdioma = this.props.idioma.codigo;

    if (this.localTrad[codigoIdioma] == null) this.localTrad[codigoIdioma] = [];

    if (this.localTrad[codigoIdioma][key] == null)
      this.localTrad[codigoIdioma][key] = getTrad(key);

    return this.localTrad[codigoIdioma][key];
  }
  //#endregion

  render() {
    let { sistemaMaquinaSel, categoriaMaquinaSel } = this.state;
    let { resolucion } = this.props;

    return (
      <Fragment>
        <PageTitle heading={getNombreFormulario(this)} />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                component="div"
                className={"media-body"}
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="CategoriasMaquinas" className="formContainer">
            <ScrollView
              id="dxScrollView"
              className={get_isMovil(resolucion) ? " vMovil" : ""}
            >
              <Box
                direction="col"
                width="100%"
                height="100%"
                elementAttr={this.elementAttr_BoxPrincipal}
                align="space-around"
                crossAlign="stretch"
              >
                <ItemBox ratio={1}>
                  <Box
                    direction={get_isMovil(resolucion) ? "col" : "row"}
                    width="100%"
                    height="100%"
                    align="space-around"
                    crossAlign="stretch"
                  >
                    <ItemBox ratio={1}>
                      <DataGrid
                        ref={this.dxDataGrid_tblSistemaMaquina_REF}
                        //Datos
                        dataSource={this.datasource_tblSistemaMaquina}
                        //Propiedades
                        noDataText={this.getTrad("sinDatos")}
                        columnsAutoWidth={true}
                        keyExpr="idSistemaMaquina"
                        height={"100%"}
                        width={"100%"}
                        repaintChangesOnly={true}
                        //Estilos
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        hoverStateEnabled={true}
                        selectedRowKeys={
                          sistemaMaquinaSel &&
                          sistemaMaquinaSel.idSistemaMaquina
                            ? [sistemaMaquinaSel.idSistemaMaquina]
                            : []
                        }
                        //Eventos
                        onToolbarPreparing={
                          this.onToolbarPreparing_tblSistemaMaquina
                        }
                        onRowUpdating={this.onRowUpdating_tblSistemaMaquina}
                        onSelectionChanged={
                          this.onSelectionChanged_tblSistemaMaquina
                        }
                        onRowPrepared={this.onRowPrepared_pointer}
                        onRowUpdated={this.onRowUpdated_tblSistemaMaquina}
                      >
                        <LoadPanel enabled={false} />
                        <RemoteOperations summary={false} />
                        <SearchPanel visible={false} />
                        <HeaderFilter visible={false} />
                        <FilterRow visible={false} />
                        <Scrolling mode="infinite" showScrollbar="always" />
                        <Editing
                          mode="batch"
                          refreshMode="repaint"
                          allowUpdating={true}
                          selectTextOnEditStart={true}
                        />
                        <Selection mode="single" />
                        <KeyboardNavigation
                          enterKeyAction="moveFocus"
                          enterKeyDirection="column"
                          editOnKeyPress={true}
                        />
                        <Column
                          caption=" "
                          dataField="error"
                          width={35}
                          alignment="center"
                          cssClass="p-0"
                          allowEditing={false}
                          allowFiltering={false}
                          allowSorting={false}
                          cellRender={this.render_error}
                        />
                        <Column
                          dataField="denominacion"
                          caption={this.getTrad("denominacion")}
                          alignment="left"
                          //width={200}
                          allowFiltering={false}
                          allowSorting={false}
                          allowEditing={false}
                        />
                        <Column
                          dataField="tblPesoNSistemaNLavanderia[0].peso"
                          caption={this.getTrad("peso")}
                          dataType="number"
                          editorOptions={this.number_editorOptions}
                          cssClass={"dx-Cell_Editable"}
                          width={80}
                          alignment="center"
                          allowFiltering={false}
                          allowSorting={false}
                          allowEditing={true}
                          setCellValue={(newData, value, currentRowData) => {
                            this.setCellValue_tblPesoNSistemaNLavanderia(
                              newData,
                              value,
                              currentRowData,
                              0,
                            );
                          }}
                        />
                        <Summary recalculateWhileEditing={true}>
                          <TotalItem
                            column="denominacion"
                            displayFormat={this.getTrad("total").toUpperCase()}
                          />
                          <TotalItem
                            name="sumaPeso"
                            column="tblPesoNSistemaNLavanderia[0].peso"
                            summaryType="sum"
                            displayFormat="{0}"
                          />
                        </Summary>
                      </DataGrid>
                    </ItemBox>
                    <ItemBox baseSize={25} />
                    <ItemBox ratio={1}>
                      <DataGrid
                        ref={this.dxDataGrid_tblCategoriaMaquina_REF}
                        //Datos
                        dataSource={
                          sistemaMaquinaSel != null
                            ? sistemaMaquinaSel.tblCategoriaMaquina
                            : []
                        }
                        //Propiedades
                        noDataText={this.getTrad("sinDatos")}
                        keyExpr="idCategoriaMaquina"
                        columnsAutoWidth={true}
                        height={"100%"}
                        width={"100%"}
                        repaintChangesOnly={true}
                        //Estilos
                        showColumnLines={false}
                        showRowLines={true}
                        rowAlternationEnabled={true}
                        allowColumnReordering={true}
                        hoverStateEnabled={true}
                        selectedRowKeys={
                          categoriaMaquinaSel &&
                          categoriaMaquinaSel.idCategoriaMaquina
                            ? [categoriaMaquinaSel.idCategoriaMaquina]
                            : []
                        }
                        //Eventos
                        onToolbarPreparing={
                          this.onToolbarPreparing_tblCategoriaMaquina
                        }
                        onRowUpdating={this.onRowUpdating_tblCategoriaMaquina}
                        onSelectionChanged={
                          this.onSelectionChanged_tblCategoriaMaquina
                        }
                        onRowPrepared={this.onRowPrepared_pointer}
                      >
                        <LoadPanel enabled={false} />
                        <SearchPanel visible={false} />
                        <HeaderFilter visible={false} />
                        <FilterRow visible={false} />
                        <Scrolling mode="infinite" showScrollbar="always" />
                        <Editing
                          mode="batch"
                          refreshMode="repaint"
                          allowUpdating={true}
                          selectTextOnEditStart={true}
                        />
                        <Selection mode="single" />
                        <KeyboardNavigation
                          enterKeyAction="moveFocus"
                          enterKeyDirection="column"
                          editOnKeyPress={true}
                        />
                        <Column
                          caption=" "
                          dataField="error"
                          width={35}
                          alignment="center"
                          cssClass="p-0"
                          allowEditing={false}
                          allowFiltering={false}
                          allowSorting={false}
                          cellRender={this.render_error}
                        />
                        <Column
                          dataField="denominacion"
                          caption={this.getTrad("denominacion")}
                          alignment="left"
                          allowFiltering={false}
                          allowSorting={false}
                          allowEditing={false}
                        />
                        <Column
                          dataField="tblPesoNCategoriaNLavanderia[0].peso"
                          caption={this.getTrad("peso")}
                          dataType="number"
                          editorOptions={this.number_editorOptions}
                          cssClass={"dx-Cell_Editable"}
                          width={80}
                          alignment="center"
                          allowFiltering={false}
                          allowSorting={false}
                          allowEditing={true}
                          setCellValue={(newData, value, currentRowData) => {
                            this.setCellValue_tblPesoNCategoriaNLavanderia(
                              newData,
                              value,
                              currentRowData,
                              0,
                            );
                          }}
                        />
                        <Summary recalculateWhileEditing={true}>
                          <TotalItem
                            column="denominacion"
                            displayFormat={this.getTrad("total").toUpperCase()}
                          />
                          <TotalItem
                            name="sumaPeso"
                            column="tblPesoNCategoriaNLavanderia[0].peso"
                            summaryType="sum"
                            displayFormat="{0}"
                          />
                        </Summary>
                      </DataGrid>
                    </ItemBox>
                  </Box>
                </ItemBox>
                <ItemBox ratio={get_isMovil(resolucion) ? 0.4 : 1}>
                  <DataGrid
                    ref={this.dxDataGrid_tblMaquina_REF}
                    //Datos
                    dataSource={
                      categoriaMaquinaSel
                        ? this.get_Maquinas(
                            categoriaMaquinaSel.tblTipoMaquinaNCategoriaMaquina,
                          )
                        : []
                    }
                    //Propiedades
                    noDataText={this.getTrad("sinDatos")}
                    columnsAutoWidth={true}
                    height={"100%"}
                    keyExpr="idMaquina"
                    repaintChangesOnly={true}
                    //Estilos
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    allowColumnReordering={true}
                    //Eventos
                    onToolbarPreparing={this.onToolbarPreparing_tblMaquina}
                    onRowUpdating={this.onRowUpdating_tblMaquina}
                    onEditingStart={this.onEditingStart_tblMaquina}
                    onAdaptiveDetailRowPreparing={
                      this.onAdaptiveDetailRowPreparing_tblMaquina
                    }
                  >
                    <LoadPanel enabled={false} />
                    <SearchPanel visible={false} />
                    <HeaderFilter visible={false} />
                    <FilterRow visible={false} />
                    <Scrolling mode="infinite" showScrollbar="always" />
                    <Editing
                      mode="batch"
                      refreshMode="repaint"
                      allowUpdating={true}
                      selectTextOnEditStart={true}
                    />
                    <KeyboardNavigation
                      enterKeyAction="moveFocus"
                      enterKeyDirection="column"
                      editOnKeyPress={true}
                    />
                    <Column
                      dataField="etiqueta"
                      caption={this.getTrad("etiqueta")}
                      minWidth={50}
                      width="20%"
                      alignment="left"
                      allowFiltering={false}
                      allowSorting={false}
                      allowEditing={false}
                      hidingPriority={get_isMovil(resolucion) ? 0 : null}
                    />
                    <Column
                      dataField="denominacion"
                      caption={this.getTrad("denominacion")}
                      //minWidth={260}
                      minWidth={175}
                      width="60%"
                      alignment="left"
                      allowFiltering={false}
                      allowSorting={false}
                      allowEditing={false}
                    />
                    <Column
                      dataField="numSerie"
                      caption={this.getTrad("numSerie")}
                      minWidth={120}
                      width="20%"
                      alignment="left"
                      allowFiltering={false}
                      allowSorting={false}
                      allowEditing={false}
                      hidingPriority={get_isMovil(resolucion) ? 1 : null}
                    />
                    <Column
                      dataField="peso"
                      caption={this.getTrad("peso")}
                      dataType="number"
                      editorOptions={this.number_editorOptions}
                      cssClass={"dx-Cell_Editable"}
                      width={get_isMovil(resolucion) ? 55 : 80}
                      //width={70}
                      alignment="center"
                      allowFiltering={false}
                      allowSorting={false}
                      allowEditing={true}
                    />
                    <Column
                      dataField="restrictiva"
                      caption={this.getTrad("restrictiva")}
                      alignment={"center"}
                      dataType={"boolean"}
                      showEditorAlways={true}
                      cssClass="p-0"
                      width={get_isMovil(resolucion) ? 85 : 145}
                      sortOrder={"desc"}
                      allowFiltering={false}
                      //allowHeaderFiltering={true}
                      //headerFilter={this.headerFilter_filtroActivo}
                      editCellRender={
                        this.editCellRender_restrictiva_tblMaquinas
                      }
                      //hidingPriority= {get_isMovil(resolucion) ? 2 : null}
                    />
                    <Summary recalculateWhileEditing={true}>
                      <TotalItem
                        column="etiqueta"
                        displayFormat={this.getTrad("total").toUpperCase()}
                      />
                      <TotalItem
                        name="sumaPeso"
                        column="peso"
                        summaryType="sum"
                        displayFormat="{0}"
                      />
                    </Summary>
                  </DataGrid>
                </ItemBox>
              </Box>
            </ScrollView>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  onRowUpdating_tblSistemaMaquina(e) {
    var pesoSum = e.component.getTotalSummaryValue("sumaPeso");
    if (!this.comprobarPeso(pesoSum)) e.cancel = true;
    else {
      //Se updatea siempre los siguientes valores para darles un valor por defecto en caso de no exisir el registro
      //También se enviará todo el array tblPesoNSistemaNLavanderia, asignado al newData en (tblPesoNSistemaNLavanderia_setCellValue)
      e.newData.tblPesoNSistemaNLavanderia =
        typeof e.newData.tblPesoNSistemaNLavanderia !== "undefined"
          ? e.newData.tblPesoNSistemaNLavanderia
          : e.oldData.tblPesoNSistemaNLavanderia;
    }
  }

  //#region tblSistemaMaquina

  onToolbarPreparing_tblSistemaMaquina(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        text: this.getTrad("sistemaMaquina"),
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: this.getTrad("actualizar"),
          onClick: function () {
            _this.loadPanel_show(true);
            _this.update_datasources();
          },
        },
      },
    );
  }

  onSelectionChanged_tblSistemaMaquina(e) {
    let sistemaMaquinaSel = $.extend(true, {}, e.selectedRowsData[0]);

    let has_dxDataGrid_editData =
      this.dxDataGrid_tblCategoriaMaquina.hasEditData() ||
      this.dxDataGrid_tblMaquina.hasEditData();

    let _this = this;
    if (has_dxDataGrid_editData) {
      this.setState({ sistemaMaquinaSel_bk: sistemaMaquinaSel });
      dxMensajePregunta(this.getTrad("preg_PerderCambios"), [
        [
          this.getTrad("aceptar"),
          function (e) {
            _this.dxDataGrid_tblCategoriaMaquina.cancelEditData();
            _this.dxDataGrid_tblMaquina.cancelEditData();

            _this.setState({
              sistemaMaquinaSel: _this.state.sistemaMaquinaSel_bk,
              categoriaMaquinaSel: null,
            });
          },
          "danger",
        ],
        [this.getTrad("cancelar"), function () {}],
      ]);
    } else
      this.setState({
        sistemaMaquinaSel: sistemaMaquinaSel,
        categoriaMaquinaSel: null,
      });
  }

  onRowUpdated_tblSistemaMaquina(e) {
    notify({
      message: this.getTrad("aviso_C_RegistroActualizado"),
      type: "success",
      displayTime: "1500",
      closeOnClick: true,
    });
  }
  //#endregion

  //#region tblCategoriaMaquina
  onToolbarPreparing_tblCategoriaMaquina(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        text: this.getTrad("categoriaMaquina"),
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: this.getTrad("actualizar"),
          onClick: function () {
            _this.loadPanel_show(true);
            _this.update_datasources();
          },
        },
      },
    );
  }

  onRowUpdating_tblCategoriaMaquina(e) {
    var pesoSum = e.component.getTotalSummaryValue("sumaPeso");
    if (!this.comprobarPeso(pesoSum)) e.cancel = true;
    else {
      //Se updatea siempre los siguientes valores para darles un valor por defecto en caso de no exisir el registro
      //También se enviará todo el array tblPesoNSistemaNLavanderia, asignado al newData en (tblPesoNSistemaNLavanderia_setCellValue)
      e.newData.tblPesoNCategoriaNLavanderia =
        typeof e.newData.tblPesoNCategoriaNLavanderia !== "undefined"
          ? e.newData.tblPesoNCategoriaNLavanderia
          : e.oldData.tblPesoNCategoriaNLavanderia;

      //#region Guardado custom
      let categoria = e.oldData;
      categoria.tblPesoNCategoriaNLavanderia =
        e.newData.tblPesoNCategoriaNLavanderia;
      delete categoria.error;
      let _this = this;

      _this.loadPanel_show(true);
      this.datasource_tblCategoriaMaquina
        .store()
        .update(categoria.idCategoriaMaquina, categoria)
        .done(function (result) {
          _this.update_datasources().then(function () {
            notify({
              message: _this.getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
        });
      //#endregion
    }
  }

  onSelectionChanged_tblCategoriaMaquina(e) {
    let categoriaMaquinaSel = $.extend(true, {}, e.selectedRowsData[0]);

    let has_dxDataGrid_editData = this.dxDataGrid_tblMaquina.hasEditData();

    let _this = this;
    if (has_dxDataGrid_editData) {
      this.setState({ categoriaMaquinaSel_bk: categoriaMaquinaSel });
      dxMensajePregunta(this.getTrad("preg_PerderCambios"), [
        [
          this.getTrad("aceptar"),
          function (e) {
            _this.dxDataGrid_tblMaquina.cancelEditData();

            _this.setState({
              categoriaMaquinaSel: _this.state.categoriaMaquinaSel_bk,
            });
          },
          "danger",
        ],
        [this.getTrad("cancelar"), function () {}],
      ]);
    } else this.setState({ categoriaMaquinaSel: categoriaMaquinaSel });
  }
  //#endregion

  //#region tblMaquina
  onToolbarPreparing_tblMaquina(e) {
    let _this = this;
    e.toolbarOptions.items.unshift(
      {
        location: "before",
        text: this.getTrad("maquinas"),
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: this.getTrad("actualizar"),
          onClick: function () {
            _this.loadPanel_show(true);
            _this.update_datasources();
          },
        },
      },
    );
  }

  editCellRender_restrictiva_tblMaquinas(e) {
    return (
      <Switch
        switchedOnText={this.getTrad("restrictiva")}
        switchedOffText={this.getTrad("noRestrictiva")}
        defaultValue={e.value}
        onValueChanged={(value) =>
          this.onValueChanged_SwitchRestrictiva_tblMaquinas(value, e)
        }
      />
    );
  }

  onValueChanged_SwitchRestrictiva_tblMaquinas(value, e) {
    let _this = this;
    this.datasource_tblMaquina
      .store()
      .update(e.data.idMaquina, { restrictiva: value.value })
      .done(function () {
        _this.update_datasources().then(function () {
          notify({
            message: _this.getTrad("aviso_C_RegistroActualizado"),
            type: "success",
            displayTime: "1500",
            closeOnClick: true,
          });
        });
      });
  }

  onEditingStart_tblMaquina(e) {
    let is_adaptiveVisible = false; // checkea si columna adaptive (3 puntitos) es visible o no
    $.each(e.component.getVisibleColumns(), function (index, item) {
      if (item.type === "adaptive")
        is_adaptiveVisible =
          item.visibleWidth !== null && item.visibleWidth > 0;
    });

    if (is_adaptiveVisible && e.column.dataField === "peso") {
      setTimeout(() => {
        e.component.collapseAdaptiveDetailRow();
        e.component.expandAdaptiveDetailRow(e.data.idMaquina);
      }, 0);
    }
  }

  onAdaptiveDetailRowPreparing_tblMaquina(e) {
    e.formOptions.labelLocation = "top";
    e.formOptions.colCountByScreen = {
      xs: 2,
    };

    $.each(e.formOptions.items, function (index, item) {
      item.label = { alignment: "center" };
      item.cssClass = "text-center";
    });
  }

  onRowUpdating_tblMaquina(e) {
    var pesoSum = e.component.getTotalSummaryValue("sumaPeso");
    if (!this.comprobarPeso(pesoSum)) e.cancel = true;
    else {
      //Se updatea siempre los siguientes valores para darles un valor por defecto en caso de no exisir el registro
      //También se enviará todo el array newData, asignado al newData en (newData_setCellValue)
      e.newData.tblMaquina =
        typeof e.newData.newData !== "undefined"
          ? e.newData.newData
          : e.oldData.newData;

      //#region Guardado custom
      let maquina = e.oldData;
      maquina.peso = e.newData.peso;

      let _this = this;

      _this.loadPanel_show(true);
      this.datasource_tblMaquina
        .store()
        .update(maquina.idMaquina, maquina)
        .done(function () {
          _this.update_datasources().then(function () {
            notify({
              message: _this.getTrad("aviso_C_RegistroActualizado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          });
        });
      //#endregion
    }
  }
  //#endregion

  onRowPrepared_pointer(e) {
    if (e.rowType === "data") {
      e.rowElement.css("cursor", "pointer");
    }
    if (e.rowType === "data") {
      let error = e.data.error;
      if (error == "sinMaquinas") {
        e.rowElement.addClass("disabledRow");
      }
    }
  }

  onToolbarPreparing_refreshButton(e) {
    let _this = this;
    e.toolbarOptions.items.unshift({
      location: "after",
      widget: "dxButton",
      showText: "inMenu",
      options: {
        icon: "refresh",
        text: this.getTrad("actualizar"),
        onClick: function () {
          _this.datasource_tblSistemaMaquina.reload();
          e.component.repaint();
        },
      },
    });
  }

  render_error(e) {
    let rowData = e.data;
    let error = rowData.error;

    let icono =
      error === null
        ? "icon_Success"
        : error == "peso"
          ? "icon_Warning"
          : error == "maqSinPeso"
            ? "icon_Alert"
            : "";

    let texto =
      error == "peso"
        ? "Peso total incorrecto"
        : error == "maqSinPeso"
          ? "Máquina/s sin peso"
          : "";

    return (
      <>
        <div
          className={
            "container_spanCentrado center-svg iconos_svg " +
            icono +
            " error_" +
            rowData.idSistemaMaquina +
            rowData.orden +
            error
          }
          style={{ fontSize: "23px" }}
        />
        {error != null && (
          <Tooltip
            target={
              ".error_" + rowData.idSistemaMaquina + rowData.orden + error
            } //maqSinPeso
            position="bottom"
            showEvent="dxhoverstart"
            hideEvent="dxhoverend"
          >
            <div>{texto}</div>
          </Tooltip>
        )}
      </>
    );
  }

  setCellValue_tblPesoNSistemaNLavanderia(
    newData,
    value,
    currentRowData,
    index,
  ) {
    if (currentRowData.tblPesoNSistemaNLavanderia) {
      newData.tblPesoNSistemaNLavanderia =
        currentRowData.tblPesoNSistemaNLavanderia;
      newData.tblPesoNSistemaNLavanderia[index].peso = value;
    }
  }

  setCellValue_tblPesoNCategoriaNLavanderia(
    newData,
    value,
    currentRowData,
    index,
  ) {
    if (currentRowData.tblPesoNCategoriaNLavanderia) {
      newData.tblPesoNCategoriaNLavanderia =
        currentRowData.tblPesoNCategoriaNLavanderia;
      newData.tblPesoNCategoriaNLavanderia[index].peso = value;
    }
  }

  setCellValue_tblPesoNTipoNCategoriaNLavanderia(
    newData,
    value,
    currentRowData,
    index,
  ) {
    if (currentRowData.tblPesoNTipoNCategoriaNLavanderia) {
      newData.tblPesoNTipoNCategoriaNLavanderia =
        currentRowData.tblPesoNTipoNCategoriaNLavanderia;
      newData.tblPesoNTipoNCategoriaNLavanderia[index].peso = value;
    }
  }

  update_datasources() {
    var deferred = new $.Deferred();
    let _this = this;
    let { sistemaMaquinaSel, categoriaMaquinaSel } = this.state;
    this.datasource_tblSistemaMaquina.reload().done(function (result_sistema) {
      if (sistemaMaquinaSel) {
        //# Cojo datos actualizados Sistema maquina y actualiza CategoriaMaquinaSel
        let idSistemaMaquina = sistemaMaquinaSel.idSistemaMaquina;
        let idCategoriaMaquina = categoriaMaquinaSel
          ? categoriaMaquinaSel.idCategoriaMaquina
          : null;

        $.each(result_sistema, function (iSistema, sistema) {
          if (sistema.idSistemaMaquina === idSistemaMaquina) {
            $.each(
              sistema.tblCategoriaMaquina,
              function (iCategoria, categoria) {
                if (categoria.idCategoriaMaquina === idCategoriaMaquina) {
                  $.each(
                    categoria.tblTipoMaquinaNCategoriaMaquina,
                    function (iTipo, tipo) {
                      _this.setState({
                        sistemaMaquinaSel: sistema,
                        categoriaMaquinaSel: categoria,
                      });
                      _this.loadPanel_hide();
                      return false; // Para no seguir iterando
                    },
                  );
                } else if (idCategoriaMaquina == null) {
                  _this.setState({
                    sistemaMaquinaSel: sistema,
                  });
                  _this.loadPanel_hide();
                  return false; // Para no seguir iterando
                }
              },
            );
          }
        });
        //#endregion
        deferred.resolve();
      }
    });
    return deferred.promise();
  }

  get_Maquinas(tblTipoMaquinaNCategoriaMaquina) {
    let maquinas = [];
    $.each(tblTipoMaquinaNCategoriaMaquina, function (index, item) {
      $.each(item.tblMaquina, function (index2, item2) {
        maquinas.push(item2);
      });
    });
    return maquinas;
  }

  comprobarPeso(pesoSum) {
    if (pesoSum < 100) {
      notify({
        message: this.getTrad("alerta_PesoMenor"),
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
      return false;
    }
    if (pesoSum > 100) {
      notify({
        message: this.getTrad("alerta_PesoMayor"),
        type: "error",
        displayTime: "2500",
        closeOnClick: true,
      });
      return false;
    }
    return true;
  }

  //FORMAT
  number_editorOptions = {
    step: 0,
    format: { style: "decimal", maximumFractionDigits: 0 },
    min: 0,
    max: 100,
  };

  elementAttr_BoxPrincipal = {
    id: "BoxPrincipal",
  };

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  cargaDatos_lavanderia() {
    this.loadPanel_show();
    let _this = this;
    this.datasource_tblSistemaMaquina.reload().done(function () {
      _this.loadPanel_hide();
      _this.setState({
        sistemaMaquinaSel: null,
        categoriaMaquinaSel: null,
        sistemaMaquinaSel_bk: null,
        categoriaMaquinaSel_bk: null,
      });
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    let { lavanderia, idioma } = this.props;
    if (
      lavanderia.idLavanderia !== prevProps.lavanderia.idLavanderia ||
      idioma.idIdioma !== prevProps.idioma.idIdioma
    ) {
      if (idioma.idIdioma !== prevProps.idioma.idIdioma) {
        if (this.dxDataGrid_tblSistemaMaquina_REF.current != null) {
          //Para rerenderizar el toolbar y que cambie el idioma se tienen que repintar los grids.
          this.dxDataGrid_tblSistemaMaquina.repaint();
          this.dxDataGrid_tblCategoriaMaquina.repaint();
          this.dxDataGrid_tblMaquina.repaint();
        }
      } else {
        this.cargaDatos_lavanderia();
      }
    }
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
  resolucion: state.Global.resolucion,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
