import React from "react";

import { SelectBox } from "devextreme-react";

import { ControlPresupuestarioCxt } from "../../../contexts/context";

class SelectorMoneda extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <ControlPresupuestarioCxt.Consumer>
                {({ moneda, setMoneda, datasource_tblMoneda }) => {
                    return (
                        <SelectBox
                            dataSource={datasource_tblMoneda}
                            value={moneda}
                            className="centerText"
                            displayExpr={"denominacion"}
                            onValueChanged={(e) => setMoneda(e.value)}
                            width={250}
                            {...this.props}
                        />
                    );
                }}
            </ControlPresupuestarioCxt.Consumer>
        );
    }
}

export default SelectorMoneda;
