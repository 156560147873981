import { Button, List, Popup } from "devextreme-react";
import DataGrid, {
    Column,
    ColumnChooser,
    SearchPanel,
    Sorting,
    Lookup as DataGrid_Lookup,
} from "devextreme-react/data-grid";
import { ToolbarItem } from "devextreme-react/autocomplete";
import notify from "devextreme/ui/notify";
import { dxMensajePregunta, getTrad } from "helpers";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    datasource_tblMoneda,
    datasource_tblAdmPedidoCliente,
    datasource_tblCliente,
    sharedEditorOptions,
    datasource_tblAdmPedido_Estado,
    datasource_tblAdmTipoElemento,
    formats,
    datasource_tblViasPagoCobro,
    datasource_tblAdmCentroCoste,
    datasource_tblAdmElementoPEP,
    datasource_tblIncoterm,
} from "pages/Administracion/shared/model";
import ChipEstado from "pages/Administracion/shared/components/ChipEstado";
import TipoElemento from "pages/Administracion/shared/components/TipoElemento";
import { custom_displayExpr, getArticulos, getDefaultIvaId } from "pages/Administracion/shared/functions";
import { getClearPedido } from "pages/Administracion/PedidoCliente/model";

// const formats = {
//     percent: {
//       type: 'percent',
//       maximumFractionDigits: 0
//     },
//     currency: {
//       style: 'currency',
//       maximumFractionDigits: 2,
//       currency: datasource_tblMoneda.find(m => m.idMoneda === idMoneda)?.codigo || 'EUR'
//     },
//     number: {
//       style: 'decimal',
//       maximumFractionDigits: 0
//     }
//   };

const CargarPedidos = forwardRef(
    ({ cargarPedidoPopup_visibility, setCargarPedidoPopup_visibility, setPedido, hasEditData }, ref) => {
        const [selectedItem, setSelectedItem] = useState();
        useImperativeHandle(ref, () => ({
            quitarPedidoSeleccionado,
        }));

        const closePopup = () => {
            setSelectedItem(null);
            setCargarPedidoPopup_visibility(false);
        };

        const confirmarPedido = () => {
            setPedido({
                idAdmPedidoCliente: selectedItem.idAdmPedidoCliente,
                codigo: selectedItem.codigo,
                idTipoPedido: selectedItem.idTipoPedido,
                // numPedidoCliente: selectedItem.numPedidoCliente,
                idAdmCliente: selectedItem.idAdmCliente,
                idAdmCentroCoste: selectedItem.idAdmCentroCoste,
                idAdmElementoPEP: selectedItem.idAdmElementoPEP,
                idAdmFormaPago: selectedItem.idAdmFormaPago,
                idMoneda: selectedItem.idMoneda,
                tasaCambio: selectedItem.tasaCambio,
                idAdmTipoCambio: selectedItem.idAdmTipoCambio,
                descuento: selectedItem.descuento,
                idAdmTipoDescuento: selectedItem.idAdmTipoDescuento,
                idIvaNPais: selectedItem.idIvaNPais,
                idIncoterm: selectedItem.idIncoterm,
                tblArticuloNAdmPedidoCliente: getArticulos(selectedItem.tblArticuloNAdmPedidoCliente),
            });
        }

        const pedidoSeleccionado = () => {
            if (selectedItem) {
                if(hasEditData(getClearPedido())){
                    dxMensajePregunta("Se van a sobreescribir datos al asociar el pedido. ¿Quieres continuar? ", [
                        [
                            getTrad("aceptar"),
                            () => {
                                confirmarPedido();
                                closePopup();
                            },
                            "danger",
                        ],
                        [getTrad("cancelar"), () => {}],
                    ]);
                } else {
                    confirmarPedido();
                    closePopup();
                }
            } else {
                notify({
                    message: getTrad("seleccionePedido"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                });
            }
        };

        const onSelectionChanged = (e) => {
            setSelectedItem(e.currentSelectedRowKeys[0]);
        };

        const quitarPedidoSeleccionado = () => {
            const defaultFormData = getClearPedido()
            dxMensajePregunta("Se van a vaciar algunos campos. ¿Está seguro de quitar el pedido asociado?", [
                [
                    getTrad("aceptar"),
                    () => {
                        setPedido({...defaultFormData})
                        closePopup();
                    },
                    "danger",
                ],
                [getTrad("cancelar"), () => {}],
            ]);
        };

        const onRowPrepared_tblPedidos = (e) => {
            if (e.rowType === "data") {
                e.rowElement.css("cursor", "pointer");
            }
        };

        return (
            <Popup
                showTitle={true}
                title={getTrad("pedidos")}
                visible={cargarPedidoPopup_visibility}
                showCloseButton={true}
                closeOnOutsideClick={true}
                // height={"fit-content"}
                height={"600px"}
                width={"60%"}
                onHiding={closePopup}
            >
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("seleccionar")} type="success" onClick={pedidoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="before" widget="dxButton">
                    <Button text={getTrad("quitarSeleccion")} type="default" onClick={quitarPedidoSeleccionado} />
                </ToolbarItem>
                <ToolbarItem toolbar="bottom" location="after" widget="dxButton">
                    <Button text={getTrad("cancelar")} onClick={closePopup} />
                </ToolbarItem>
                <div className={"d-flex he-100 mt-4"}>
                    <DataGrid
                        dataSource={datasource_tblAdmPedidoCliente}
                        height={"100%"}
                        width={"100%"}
                        showRowLines
                        columnsAutoWidth
                        repaintChangesOnly
                        rowAlternationEnabled
                        showColumnLines={false}
                        remoteOperations={false}
                        //   onToolbarPreparing={onToolbarPreparing}
                        hoverStateEnabled={true}
                        onRowPrepared={onRowPrepared_tblPedidos}
                        selection={{ mode: "single" }}
                        selectedRowKeys={[selectedItem]}
                        onSelectionChanged={onSelectionChanged}
                    >
                        <ColumnChooser enabled />
                        <SearchPanel visible width={240} />
                        <Column
                            dataField={"idTipoPedido"}
                            caption={getTrad("tipoPedido")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) => TipoElemento(e.data.data.idTipoPedido)}
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmTipoElemento.store()}
                                valueExpr="idAdmTipoElemento"
                                // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"codigo"}
                            caption={getTrad("codigo")}
                            dataType={"string"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"fechaCreacion"}
                            caption={getTrad("fecha")}
                            dataType={"date"}
                            alignment={"left"}
                            minWidth={150}
                            sortOrder={"desc"}
                            format={"dd/MM/yyyy"}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmPedido_Estado"}
                            caption={getTrad("estado")}
                            dataType={"number"}
                            alignment={"left"}
                            defaultFilterValues={[1, 2]}
                            minWidth={150}
                            allowReordering
                            allowResizing
                            cellComponent={(e) => ChipEstado(e.data.data.idAdmPedido_Estado, "", "pedido")}
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmPedido_Estado.store()}
                                valueExpr="idAdmPedido_Estado"
                                // itemComponent={(e) => ChipEstado(e.idAdmPedido_Estado)}
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"idAdmCliente"}
                            caption={getTrad("cliente")}
                            editorOptions={sharedEditorOptions.idAdmCliente}
                            dataType={"number"}
                            alignment={"left"}
                            minWidth={150}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblCliente.store()}
                                valueExpr="idAdmCliente"
                                displayExpr="nombreFiscal"
                            />
                        </Column>
                        <Column
                            dataField={"fechaEstimadaRecepcion"}
                            caption={getTrad("fechaEstimadaRecepcion")}
                            dataType={"date"}
                            visible={false}
                            format={"dd/MM/yyyy"}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idMoneda"}
                            caption={getTrad("moneda")}
                            dataType={"number"}
                            editorOptions={sharedEditorOptions.idMoneda}
                            visible={false}
                            // format={formats.currency}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblMoneda.store()}
                                valueExpr="idMoneda"
                                displayExpr="codigo"
                            />
                        </Column>
                        <Column
                            dataField={"tasaCambio"}
                            caption={getTrad("tasaCambio")}
                            dataType={"number"}
                            format={sharedEditorOptions.tasaCambio.format}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        {/* <Column
                            dataField={"idIvaNPais"}
                            caption={getTrad("iva")}
                            visible={false}
                            allowReordering
                            allowResizing
                        /> */}
                        {/* <Column
            dataField={"numPedidoCliente"}
            caption={getTrad("numPedidoCliente")}
            visible={false}
            allowReordering
            allowResizing
          /> */}
                        {/* <Column
                            dataField={"idAdmPedidoCliente"}
                            caption={getTrad("pedidoCliente")}
                            visible={false}
                            allowReordering
                            allowResizing
                        /> */}
                        <Column
                            dataField={"descuento"}
                            caption={getTrad("descuento")}
                            dataType={"number"}
                            visible={false}
                            format={formats.percent}
                            allowReordering
                            allowResizing
                        />
                        <Column
                            dataField={"idAdmFormaPago"}
                            caption={getTrad("formaPago")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblViasPagoCobro.store()}
                                valueExpr="idAdmFormaPago"
                                displayExpr="denominacion"
                            />
                        </Column>
                        <Column
                            dataField={"idAdmCentroCoste"}
                            caption={getTrad("centroCoste")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmCentroCoste.store()}
                                valueExpr="idAdmCentroCoste"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idAdmElementoPEP"}
                            caption={getTrad("elementoPep")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblAdmElementoPEP.store()}
                                valueExpr="idAdmElementoPEP"
                                displayExpr={custom_displayExpr}
                            />
                        </Column>
                        <Column
                            dataField={"idIncoterm"}
                            caption={getTrad("incoterms")}
                            dataType={"number"}
                            visible={false}
                            allowReordering
                            allowResizing
                        >
                            <DataGrid_Lookup
                                dataSource={datasource_tblIncoterm.store()}
                                valueExpr="idIncoterm"
                                displayExpr="denominacion"
                            />
                        </Column>
                        {/* <Column
                            dataField={"numPresupuestoProveedor"}
                            caption={getTrad("numPresupuestoProveedor")}
                            visible={false}
                            allowReordering
                            allowResizing
                        /> */}
                        <Column
                            dataField={"observaciones"}
                            caption={getTrad("observaciones")}
                            dataType={"string"}
                            visible={false}
                            allowReordering
                            allowResizing
                        />
                        {/* <Column
            dataField={"total"}
            caption={getTrad("total")}
            visible={true}
            width={100}
            // calculateCellValue={calculateTotal}
            calculateDisplayValue={calculateTotalValueForDisplay}
          /> */}
                    </DataGrid>
                    {/* <List
          dataSource={pedidos}
          displayExpr={"codigo"}
          selectionMode="single"
          selectedItem={selectedItem}
          onSelectionChanged={onSelectionChanged}
        /> */}
                </div>
            </Popup>
        );
    }
);

export default CargarPedidos;
