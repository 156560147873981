import React from "react";

import { connectionConstants } from "../../../../constants";
import {
  errorHandler,
  authHeader,
  getTrad,
  formatNumber,
  formatDate_parameter,
} from "../../../../helpers";

import $ from "jquery";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  FilterRow,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";

import { progressBarCellComponent } from "../../../../components/DataGrid/Cells";

const GET_COLOR_CUMPLIMIENTO = (cumplimiento) => {
  if (cumplimiento >= 0.95) return "success";
  else if (cumplimiento >= 0.9) return "warning";
  else return "danger";
};

const GET_COLOR_REPOSICION = (reposicion) => {
  if (reposicion >= 0.7) return "danger";
  else if (reposicion > 0.65) return "warning";
  else if (reposicion >= 0.45) return "success";
  else if (reposicion >= 0.4) return "warning";
  else return "danger";
};

export default class GridCalidadCliente extends React.PureComponent {
  constructor(props) {
    super(props);

    this.grid_REF = React.createRef();

    this.cellRender_detallado_comañia =
      this.cellRender_detallado_comañia.bind(this);
    this.onToolbarPreparing = this.onToolbarPreparing.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.onMouseDown_cellRender_detallado_compañia =
      this.onMouseDown_cellRender_detallado_compañia.bind(this);
  }

  get grid() {
    return this.grid_REF.current.instance;
  }

  groupMapping = {
    fecha: "detallado",
    prenda: "detallado",
  };

  datasource_cumplimientoPedidos = new DataSource({
    paginate: false,
    store: new ODataStore({
      key: "id",
      url:
        connectionConstants.WEB_API_CORE_ODATA_URL +
        "MyPolarier/ControlCalidad/infCumplimientoPedidos",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: (request) => {
        this.datasource_beforeSend(request);
      },
      version: 4,
    }),
    postProcess: (data) => {
      data.forEach((item) => {
        item.progressBarCellOptions_cumplimiento = {
          color: GET_COLOR_CUMPLIMIENTO(item.cumplimiento),
        };
        item.progressBarCellOptions_reposicion = {
          color: GET_COLOR_REPOSICION(item.reposicion),
        };
      });
      return data;
    },
  });

  datasource_beforeSend(request) {
    let { filtros, group } = this.props;
    let { idLavanderia, fechaIni, fechaFin, idCompañia, idEntidad } = filtros;

    request.headers = { ...authHeader() };
    request.params.idLavanderia = idLavanderia;
    request.params.fechaIni = formatDate_parameter(fechaIni);
    request.params.fechaFin = formatDate_parameter(fechaFin);
    request.params.idCompañia = idCompañia;
    request.params.idEntidad = idEntidad;
    request.params.group = group;
  }

  render() {
    let { selectedRowKey, group } = this.props;

    return (
      <DataGrid
        ref={this.grid_REF}
        dataSource={this.datasource_cumplimientoPedidos}
        remoteOperations={false}
        height="100%"
        showColumnLines={false}
        showRowLines={true}
        rowAlternationEnabled={true}
        hoverStateEnabled={group !== "detallado"}
        selectedRowKeys={selectedRowKey != null ? [selectedRowKey] : undefined}
        onContentReady={this.onContentReady}
        onRowPrepared={this.onRowPrepared}
        onToolbarPreparing={
          this.props.title ? this.onToolbarPreparing : undefined
        }
        onEditorPreparing={this.onEditorPreparing}
        onSelectionChanged={
          this.props.onSelectionChanged ? this.onSelectionChanged : undefined
        }
      >
        <Paging enabled={false} />
        <HeaderFilter visible={true} />
        <FilterRow visible={true} />
        <Selection
          mode={this.props.onSelectionChanged ? "single" : undefined}
        />
        <Column
          visible={group === "compañia"}
          caption=""
          width={40}
          alignment="center"
          cssClass="p-0"
          fixed={true}
          allowFixing={false}
          allowHiding={false}
          allowReordering={false}
          cellRender={this.cellRender_detallado_comañia}
        />
        <Column
          dataField="denominacion"
          dataType="date"
          format={"dd/MM/yyyy"}
          caption={getTrad("fecha")}
          minWidth={150}
          visible={group === "fecha"}
          sortOrder={group === "fecha" ? "asc" : undefined}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="denominacion"
          dataType="string"
          caption={getTrad("denominacion")}
          minWidth={
            this.groupMapping[group] && this.groupMapping[group] === "detallado"
              ? 400
              : 150
          }
          sortOrder={group === "fecha" ? undefined : "asc"}
          visible={group !== "fecha"}
        />
        <Column
          dataField="udsPedidas"
          dataType="number"
          caption={getTrad("udsPedidas")}
          alignment={"center"}
          defaultSelectedFilterOperation=">="
          allowSearch={false}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="udsEntregadas"
          dataType="number"
          caption={getTrad("udsEntregadas")}
          alignment={"center"}
          defaultSelectedFilterOperation=">="
          allowSearch={false}
          allowHeaderFiltering={false}
        />
        <Column
          dataField="udsPendientes"
          dataType="number"
          caption={getTrad("udsPendientes")}
          alignment={"center"}
          defaultSelectedFilterOperation=">="
          allowSearch={false}
          allowHeaderFiltering={false}
        />
        <Column dataField="stockDefinido" visible={false} />
        <Column dataField="udsDiarias" visible={false} />
        <Column
          dataField="cumplimiento"
          caption={getTrad("cumplimiento")}
          dataType="number"
          alignment="center"
          filterOperations={["=", "<>", "<", ">", "<=", ">="]}
          defaultSelectedFilterOperation=">="
          defaultFilterValue={0}
          cssClass="p-0"
          allowSearch={false}
          allowHeaderFiltering={false}
          allowFiltering={true}
          allowSorting={true}
          minWidth={125}
          cellComponent={progressBarCellComponent}
        />
        <Column
          dataField="reposicion"
          caption={getTrad("reposicion")}
          dataType="number"
          alignment="center"
          filterOperations={["=", "<>", "<", ">", "<=", ">="]}
          defaultSelectedFilterOperation=">="
          defaultFilterValue={0}
          cssClass="p-0"
          allowSearch={false}
          allowHeaderFiltering={false}
          allowFiltering={true}
          allowSorting={true}
          minWidth={125}
          cellComponent={progressBarCellComponent}
        />
        <Summary calculateCustomSummary={this.calculateCustomSummary}>
          <TotalItem
            column={group === "fecha" ? "fecha" : "denominacion"}
            displayFormat={getTrad("total").toUpperCase()}
          />
          <TotalItem
            column="udsPedidas"
            name="udsPedidas"
            summaryType="sum"
            displayFormat="{0}"
          />
          <TotalItem
            column="udsEntregadas"
            name="udsEntregadas"
            summaryType="sum"
            displayFormat="{0}"
          />
          <TotalItem
            column="udsPendientes"
            name="udsPendientes"
            summaryType="sum"
            displayFormat="{0}"
          />
          <TotalItem
            name="cumplimiento"
            showInColumn={"cumplimiento"}
            summaryType="custom"
            displayFormat="{0}"
            customizeText={this.customizeText_porcentaje}
          />
          <TotalItem
            name="reposicion"
            showInColumn={"reposicion"}
            summaryType="custom"
            displayFormat="{0}"
            customizeText={this.customizeText_porcentaje}
          />
        </Summary>
      </DataGrid>
    );
  }

  cellRender_detallado_comañia(e) {
    return (
      <div
        id={"compañiaButton_" + e.data.id}
        className="container_spanCentrado pointer"
        onMouseDown={(arg) =>
          this.onMouseDown_cellRender_detallado_compañia(arg, e)
        }
      >
        <div className="icon_Camisa font-size-xl" />
      </div>
    );
  }

  onMouseDown_cellRender_detallado_compañia = (arg, e) => {
    let key = e.data.id.split("_");
    this.props.onSelectionChanged({
      [key[0]]: parseInt(key[1]),
      denominacion: e.data.denominacion,
    });
    this.props.onClick_compañia(e.data);
  };

  onContentReady(e) {
    e.element.find(".dx-freespace-row").css("height", 0);
  }

  onRowPrepared(e) {
    let { group } = this.props;
    if (e.rowType === "data" && group !== "detallado") {
      e.rowElement.css("cursor", "pointer");
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      location: "before",
      text: this.props.title,
    });
  }

  sliderText = null;
  onEditorPreparing(e) {
    let _this = this;
    if (e.parentType === "filterRow") {
      if (e.dataField === "cumplimiento" || e.dataField === "reposicion") {
        e.editorName = "dxSlider";
        e.editorOptions = {
          min: 0,
          max: 1,
          value: e.value,
          step: 0.05,
          width: "100%",
          onValueChanged: function (data) {
            e.setValue(data.value);
            let textValue = formatNumber(data.value, 0, "percent");
            _this.sliderText.text(textValue);
          },
          onInitialized: function (e) {
            let value = e.component.option("value");
            let textValue = formatNumber(value, 0, "percent");
            _this.sliderText = $("<div />")
              .addClass("slider-textValue")
              .text(textValue);
            e.element.append(_this.sliderText);
          },
        };
      }
    }
  }

  calculateCustomSummary(options) {
    if (options.name == "cumplimiento") {
      switch (options.summaryProcess) {
        case "start":
          options.udsPedidas = 0;
          options.udsEntregadas = 0;
          break;
        case "calculate":
          options.udsPedidas += options.value.udsPedidas;
          options.udsEntregadas += options.value.udsEntregadas;
          break;
        case "finalize":
          options.totalValue = options.udsEntregadas / options.udsPedidas;
          options.totalValue = options.totalValue ? options.totalValue : 0;
          break;
      }
    } else if (options.name == "reposicion") {
      switch (options.summaryProcess) {
        case "start":
          options.udsDiarias = 0;
          options.stockDefinido = 0;
          break;
        case "calculate":
          options.udsDiarias += options.value.udsDiarias;
          options.stockDefinido += options.value.stockDefinido;
          break;
        case "finalize":
          options.totalValue = options.udsDiarias / options.stockDefinido;
          options.totalValue = !isNaN(options.totalValue)
            ? options.totalValue
            : 0;
          break;
      }
    }
  }

  customizeText_porcentaje(itemInfo) {
    return formatNumber(itemInfo.value, 2, "percent");
  }

  onSelectionChanged(e) {
    if (e.selectedRowKeys.length > 0) {
      let key = e.selectedRowKeys[0].split("_");
      let { denominacion } = e.selectedRowsData[0];
      this.props.onSelectionChanged({
        [key[0]]: parseInt(key[1]),
        denominacion: denominacion,
      });
    } else {
      this.props.onSelectionChanged({});
    }
  }

  componentDidUpdate(prevProps) {
    let { title, filtros, group } = this.props;

    if (JSON.stringify(prevProps.filtros) !== JSON.stringify(filtros)) {
      this.datasource_cumplimientoPedidos.reload();
      this.grid.clearFilter();
    }

    if (prevProps.title !== title) {
      this.grid.repaint();
    }
    if (prevProps.group !== group) {
      this.grid.clearSorting();
      this.grid.columnOption(
        "fecha",
        "sortOrder",
        group === "fecha" ? "asc" : undefined,
      );
      this.grid.columnOption(
        "denominacion",
        "sortOrder",
        group === "fecha" ? undefined : "asc",
      );
    }
  }
}
