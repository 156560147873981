import React, { Fragment } from "react";
import { connect } from "react-redux";

import { connectionConstants } from "../../../constants";
import {
  history,
  errorHandler,
  authHeader,
  getTrad,
  getNombreFormulario,
  formatNumber,
  tooltipControl_creacion,
} from "../../../helpers";
import $ from "jquery";

//Actions
import { loadPanelActions } from "../../../actions";

//Components
//import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

//Layout
import PageTitle from "../../../layout/AppMain/PageTitle";

import notify from "devextreme/ui/notify";
import query from "devextreme/data/query";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import DataGrid, {
  Column,
  Editing,
  Paging,
  KeyboardNavigation,
  CustomRule,
} from "devextreme-react/data-grid";
import { SelectBox } from "devextreme-react/select-box";
import { Popup } from "devextreme-react/popup";
import { Form, Item, ButtonItem } from "devextreme-react/form";
import { DateBox, Button as DateBoxButton } from "devextreme-react/date-box";

class DatosFinancieros extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      añoSel: new Date().getFullYear(),
      idGrupoEmpresarialSel: null,
      currentRow: {},
      popup_DatosGraficos_visible: false,
      popup_Observaciones_visible: false,
      isTerminadoAñoAnterior: false,
    };

    //Referencias
    this.grid_datos_REF = React.createRef();

    //DATASOURCE
    this.dataSource_tblDatosFinancieros_onLoading =
      this.dataSource_tblDatosFinancieros_onLoading.bind(this);
    this.dataSource_tblDatosFinancieros_onUpdated =
      this.dataSource_tblDatosFinancieros_onUpdated.bind(this);
    this.dataSource_tblDatosFinancieros_postProcess =
      this.dataSource_tblDatosFinancieros_postProcess.bind(this);
    this.dataSource_tblDatosFinancieros_anterior_onLoaded =
      this.dataSource_tblDatosFinancieros_anterior_onLoaded.bind(this);
    this.dataSource_tblGrupoEmpresarial_onLoaded =
      this.dataSource_tblGrupoEmpresarial_onLoaded.bind(this);

    //LOAD PANEL
    this.loadPanel_show = this.loadPanel_show.bind(this);
    this.loadPanel_hide = this.loadPanel_hide.bind(this);
    this.loadPanel_show();

    //YEAR PICKER
    this.yearPicker = this.yearPicker.bind(this);
    this.prevYear_Click = this.prevYear_Click.bind(this);
    this.nextYear_Click = this.nextYear_Click.bind(this);
    this.yearPicker_onValueChanged = this.yearPicker_onValueChanged.bind(this);

    //SELECTOR GRUPO EMPRESARIAL
    this.selectorGrupoEmpresarial = this.selectorGrupoEmpresarial.bind(this);
    this.selectorGrupoEmpresarial_onSelectionChanged =
      this.selectorGrupoEmpresarial_onSelectionChanged.bind(this);

    //GRID
    this.rowUpdating = this.rowUpdating.bind(this);
    this.editRow = this.editRow.bind(this);

    //POPUP
    this.hiding_popup = this.hiding_popup.bind(this);
    this.popup_submit = this.popup_submit.bind(this);
    this.validationCallback_CustomRule_MaxVal =
      this.validationCallback_CustomRule_MaxVal.bind(this);
    this.validationCallback_CustomRule_Rangos =
      this.validationCallback_CustomRule_Rangos.bind(this);
  }

  //REFERENCIAS
  get grid_datos() {
    return this.grid_datos_REF.current.instance;
  }

  //FORMATS
  decimal_format = { style: "decimal", maximumFractionDigits: 2 };
  decimal_editorOptions = {
    format: this.decimal_format,
    step: 0,
    min: -999999999,
    max: 999999999,
  };

  //DATSOURCES
  dataSource_tblConceptosFinancieros = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblConceptosFinancieros",
      key: "idConceptoFinanciero",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });
  dataSource_tblDatosFinancieros = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblDatosFinancieros",
      key: ["idConceptoFinanciero", "año", "idGrupoEmpresarial"],
      keyType: {
        idConceptoFinanciero: "Int32",
        año: "Int32",
        idGrupoEmpresarial: "Int32",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoading: (loadOptions) => {
        this.dataSource_tblDatosFinancieros_onLoading(loadOptions);
      },
      onUpdating: (key, values) => {
        this.dataSource_tblDatosFinancieros_onUpdating(key, values);
      },
      onUpdated: (key, values) => {
        this.dataSource_tblDatosFinancieros_onUpdated(key, values);
      },
      version: 4,
    }),
    postProcess: (data) => {
      return this.dataSource_tblDatosFinancieros_postProcess(data);
    },
  });
  dataSource_tblDatosFinancieros_onLoading(loadOptions) {
    loadOptions.filter = [
      ["año", "=", this.state.añoSel],
      "and",
      ["idGrupoEmpresarial", "=", this.state.idGrupoEmpresarialSel],
    ];
  }
  dataSource_tblDatosFinancieros_postProcess(data) {
    let { añoSel, idGrupoEmpresarialSel } = this.state;
    let datosAnteriores = this.dataSource_tblDatosFinancieros_anterior.items();
    var datosConcepto_añoAnterior = $.grep(datosAnteriores, function (item) {
      return (
        item.año === añoSel - 1 &&
        item.idGrupoEmpresarial === idGrupoEmpresarialSel
      );
    });

    $.each(
      this.dataSource_tblConceptosFinancieros.items(),
      function (index, concepto) {
        var datosConcepto = $.grep(data, function (item) {
          return item.idConceptoFinanciero === concepto.idConceptoFinanciero;
        })[0];

        var datosConcepto_anterior = $.grep(
          datosConcepto_añoAnterior,
          function (item) {
            return item.idConceptoFinanciero === concepto.idConceptoFinanciero;
          },
        )[0];

        if (datosConcepto) {
          datosConcepto.orden = concepto.orden;
          datosConcepto.denominacion = concepto.denominacion;

          datosConcepto.T1 =
            datosConcepto.tblDatosFinancierosNTrimestre[0].valor;
          datosConcepto.T2 =
            datosConcepto.tblDatosFinancierosNTrimestre[1].valor;
          datosConcepto.T3 =
            datosConcepto.tblDatosFinancierosNTrimestre[2].valor;
          datosConcepto.T4 =
            datosConcepto.tblDatosFinancierosNTrimestre[3].valor;
        } else {
          data.push({
            idConceptoFinanciero: concepto.idConceptoFinanciero,
            orden: concepto.orden,
            año: añoSel,
            idGrupoEmpresarial: idGrupoEmpresarialSel,
            denominacion: concepto.denominacion,
            observaciones: datosConcepto_anterior
              ? datosConcepto_anterior.observaciones
              : "",
            minVal: datosConcepto_anterior ? datosConcepto_anterior.minVal : 0,
            maxVal: datosConcepto_anterior ? datosConcepto_anterior.maxVal : 0,
            inicioRango1: datosConcepto_anterior
              ? datosConcepto_anterior.inicioRango1
              : 0,
            finRango1: datosConcepto_anterior
              ? datosConcepto_anterior.finRango1
              : 0,
            inicioRango2: datosConcepto_anterior
              ? datosConcepto_anterior.inicioRango2
              : 0,
            finRango2: datosConcepto_anterior
              ? datosConcepto_anterior.finRango2
              : 0,
            T1: 0,
            T2: 0,
            T3: 0,
            T4: 0,
            tblDatosFinancierosNTrimestre: [
              { trimestre: 1, valor: 0, observaciones: "" },
              { trimestre: 2, valor: 0, observaciones: "" },
              { trimestre: 3, valor: 0, observaciones: "" },
              { trimestre: 4, valor: 0, observaciones: "" },
            ],
          });
        }
      },
    );
    var sortedData = query(data).sortBy("orden").toArray();
    return sortedData;
  }
  dataSource_tblDatosFinancieros_onUpdating(key, values) {
    for (let i = 1; i <= 4; i++) {
      let valorTrimestral = values["T" + i];
      if (typeof valorTrimestral !== "undefined") {
        values.tblDatosFinancierosNTrimestre[i - 1].valor = valorTrimestral;
      }
      delete values["T" + i];
    }
  }
  dataSource_tblDatosFinancieros_onUpdated(key, values) {
    this.dataSource_tblDatosFinancieros_anterior.load();
  }
  dataSource_tblDatosFinancieros_anterior = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblDatosFinancieros",
      key: ["idConceptoFinanciero", "año", "idGrupoEmpresarial"],
      keyType: {
        idConceptoFinanciero: "Int32",
        año: "Int32",
        idGrupoEmpresarial: "Int32",
      },
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoaded: (data) => {
        this.dataSource_tblDatosFinancieros_anterior_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_tblDatosFinancieros_anterior_onLoaded(data) {
    this.calcular_isTerminadoAñoAnterior(data);
  }

  dataSource_tblGrupoEmpresarial = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblGrupoEmpresarial",
      key: "idGrupoEmpresarial",
      keyType: "Int32",
      errorHandler: function (error) {
        errorHandler(error, null);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      onLoaded: (data) => {
        this.dataSource_tblGrupoEmpresarial_onLoaded(data);
      },
      version: 4,
    }),
  });
  dataSource_tblGrupoEmpresarial_onLoaded(data) {
    this.setState({ idGrupoEmpresarialSel: data[0].idGrupoEmpresarial });
  }

  //YEAR PICKER
  yearPicker() {
    let { isTerminadoAñoAnterior, añoSel } = this.state;

    return (
      <DateBox
        className="centerText font-size-sm"
        inputAttr={{ class: "font-size-sm" }}
        value={new Date(añoSel, 0, 1, 0, 0, 0)}
        readOnly={true}
        width="100%"
        stylingMode="underlined"
        displayFormat={"yyyy"}
        onValueChanged={this.yearPicker_onValueChanged}
      >
        <DateBoxButton
          name="prevYear"
          location="before"
          options={{
            icon: "spinprev",
            stylingMode: "text",
            disabled: añoSel <= 2018,
            onClick: this.prevYear_Click,
          }}
        />
        <DateBoxButton
          name="nextYear"
          location="after"
          options={{
            icon: "spinnext",
            stylingMode: "text",
            disabled: !isTerminadoAñoAnterior,
            onClick: this.nextYear_Click,
          }}
        />
      </DateBox>
    );
  }

  prevYear_Click(e) {
    this.setState({ añoSel: this.state.añoSel - 1 }, () => {
      this.calcular_isTerminadoAñoAnterior();
    });
  }
  nextYear_Click(e) {
    this.setState({ añoSel: this.state.añoSel + 1 }, () => {
      this.calcular_isTerminadoAñoAnterior();
    });
  }
  yearPicker_onValueChanged(e) {
    this.setState({ añoSel: e.value.getFullYear() });
  }
  calcular_isTerminadoAñoAnterior(datos) {
    let datosAnteriores = datos
      ? datos
      : this.dataSource_tblDatosFinancieros_anterior.items();
    let { añoSel, idGrupoEmpresarialSel } = this.state;
    var datosConcepto_añoAnterior = $.grep(datosAnteriores, function (item) {
      return (
        item.año === añoSel && item.idGrupoEmpresarial === idGrupoEmpresarialSel
      );
    });
    let isTerminadoAñoAnterior = false;
    $.each(datosConcepto_añoAnterior, function (index, dato) {
      //Miramos si hay datos en el 4rto trimestre
      if (dato.tblDatosFinancierosNTrimestre[3].valor > 0) {
        isTerminadoAñoAnterior = true;
      }
    });

    this.setState({ isTerminadoAñoAnterior: isTerminadoAñoAnterior });
  }

  //SELECTOR GRUPO EMPRESARIAL
  selectorGrupoEmpresarial() {
    let { idGrupoEmpresarialSel } = this.state;
    return (
      <SelectBox
        deferRendering={false}
        className="centerText font-size-sm mr-3"
        inputAttr={{ class: "font-size-sm" }}
        dataSource={this.dataSource_tblGrupoEmpresarial.items()}
        displayExpr="denominacion"
        valueExpr="idGrupoEmpresarial"
        value={idGrupoEmpresarialSel}
        defaultValue={idGrupoEmpresarialSel}
        width="100%"
        stylingMode="underlined"
        onSelectionChanged={this.selectorGrupoEmpresarial_onSelectionChanged}
      />
    );
  }
  selectorGrupoEmpresarial_onSelectionChanged(e) {
    this.setState({ idGrupoEmpresarialSel: e.selectedItem.idGrupoEmpresarial });
  }

  //CELLS
  renderEditCell_grafica = (itemData) => {
    let idConceptoFinanciero = itemData.data.idConceptoFinanciero;
    if (
      idConceptoFinanciero > 4 &&
      idConceptoFinanciero !== 13 &&
      idConceptoFinanciero !== 15 &&
      idConceptoFinanciero !== 16 &&
      idConceptoFinanciero !== 17 &&
      idConceptoFinanciero !== 18
    ) {
      return (
        <div
          className="container_spanCentrado pointer"
          onMouseDown={() => this.editRow(itemData, false)}
        >
          <div className="icon_Gauge font-size-lg" />
        </div>
      );
    } else {
      return <div />;
    }
  };
  renderEditCell_observaciones = (itemData) => {
    return (
      <div
        className="container_spanCentrado pointer"
        onMouseDown={() => this.editRow(itemData, true)}
      >
        <div className="dx-icon dx-icon-menu font-size-lg" />
      </div>
    );
  };
  cellTemplate_trimestres = (cellElement, cellInfo) => {
    let container = $("<div/>");

    var valueText =
      cellInfo.data.idConceptoFinanciero <= 4
        ? formatNumber(cellInfo.value, 2, "currency", "EUR")
        : formatNumber(cellInfo.value, 2, "decimal");

    let dataField = cellInfo.column.dataField;
    let trimestre_index = parseInt(dataField.split("T")[1]) - 1;

    let datosTrimestre =
      cellInfo.data.tblDatosFinancierosNTrimestre[trimestre_index];

    container.append($("<div/>").text(valueText));
    if (datosTrimestre && datosTrimestre.observaciones.length > 0) {
      container.addClass("container_spanCentrado");
      let icon = $("<div />").addClass("icon_Comment pointer pl-2");
      tooltipControl_creacion(icon, datosTrimestre.observaciones);
      container.append(icon);
    }
    cellElement.append(container);
  };
  cellTemplate_denominacion = (cellElement, cellInfo) => {
    let container = $("<div/>");
    container.append($("<div/>").text(cellInfo.text));
    if (cellInfo.data.observaciones && cellInfo.data.observaciones.length > 0) {
      container.addClass("d-flex");
      let icon = $("<div />").addClass("icon_Comment pointer pl-2");
      tooltipControl_creacion(icon, cellInfo.data.observaciones);
      container.append(icon);
    }
    cellElement.append(container);
  };

  render() {
    return (
      <Fragment>
        <PageTitle
          heading={getNombreFormulario(this)}
          postHeading={[this.selectorGrupoEmpresarial(), this.yearPicker()]}
        />
        <div className={"media-body"}>
          {/* <ReactCSSTransitionGroup
                className={"media-body"}
                component="div"
                transitionName="PageAnimation"
                transitionAppear={true}
                transitionAppearTimeout={1000}
                transitionEnter={false}
                transitionLeave={false}> */}
          <div id="DatosFinancieros" className={"formContainer"}>
            <DataGrid
              ref={this.grid_datos_REF}
              dataSource={this.dataSource_tblDatosFinancieros}
              remoteOperations={{
                sorting: false,
              }}
              height="100%"
              //Eventos
              onRowUpdating={this.rowUpdating}
              //Estilos
              showColumnLines={false}
              showRowLines={true}
              rowAlternationEnabled={true}
            >
              <Paging enabled={false} />
              <Editing
                mode="cell"
                refreshMode="full"
                allowUpdating={true}
                selectTextOnEditStart={true}
              />
              <KeyboardNavigation
                enterKeyAction="moveFocus"
                enterKeyDirection="row"
                editOnKeyPress={true}
              />
              <Column type="buttons" visible={false} />
              <Column dataField="orden" sortOrder="asc" visible={false} />
              <Column dataField="idConceptoFinanciero" visible={false} />
              <Column
                dataField="denominacion"
                caption={getTrad("denominacion")}
                allowEditing={false}
                minWidth={200}
                cellTemplate={this.cellTemplate_denominacion}
              />
              <Column
                dataField="T1"
                caption={getTrad("primerTrimestre")}
                dataType="number"
                alignment="center"
                width={150}
                editorOptions={this.decimal_editorOptions}
                cellTemplate={this.cellTemplate_trimestres}
              >
                <CustomRule
                  validationCallback={
                    this.validationCallback_CustomRule_Trimestres
                  }
                  message="Rango inválido"
                />
              </Column>
              <Column
                dataField="T2"
                caption={getTrad("segundoTrimestre")}
                dataType="number"
                alignment="center"
                width={150}
                editorOptions={this.decimal_editorOptions}
                cellTemplate={this.cellTemplate_trimestres}
              >
                <CustomRule
                  validationCallback={
                    this.validationCallback_CustomRule_Trimestres
                  }
                  message="Rango inválido"
                />
              </Column>
              <Column
                dataField="T3"
                caption={getTrad("tercerTrimestre")}
                dataType="number"
                alignment="center"
                width={150}
                editorOptions={this.decimal_editorOptions}
                cellTemplate={this.cellTemplate_trimestres}
              >
                <CustomRule
                  validationCallback={
                    this.validationCallback_CustomRule_Trimestres
                  }
                  message="Rango inválido"
                />
              </Column>
              <Column
                dataField="T4"
                caption={getTrad("cuartoTrimestre")}
                dataType="number"
                alignment="center"
                width={150}
                editorOptions={this.decimal_editorOptions}
                cellTemplate={this.cellTemplate_trimestres}
              >
                <CustomRule
                  validationCallback={
                    this.validationCallback_CustomRule_Trimestres
                  }
                  message="Rango inválido"
                />
              </Column>

              <Column dataField="observaciones" visible={false} />
              <Column dataField="minVal" visible={false} />
              <Column dataField="maxVal" visible={false} />
              <Column dataField="inicioRango1" visible={false} />
              <Column dataField="finRango1" visible={false} />
              <Column dataField="inicioRango2" visible={false} />
              <Column dataField="finRango2" visible={false} />
              <Column
                dataField="tblDatosFinancierosNTrimestre"
                visible={false}
              />

              <Column
                caption={getTrad("observaciones")}
                width={120}
                alignment="center"
                cssClass="p-0"
                allowEditing={false}
                cellRender={this.renderEditCell_observaciones}
              />
              <Column
                caption=" "
                width={50}
                alignment="center"
                cssClass="p-0"
                allowEditing={false}
                cellRender={this.renderEditCell_grafica}
              />
            </DataGrid>

            <Popup
              title={this.state.currentRow.denominacion}
              showTitle={true}
              width={550}
              height={500}
              visible={this.state.popup_DatosGraficos_visible}
              closeOnOutsideClick={true}
              onHiding={this.hiding_popup}
            >
              <form onSubmit={this.popup_submit}>
                <Form
                  formData={this.state.currentRow}
                  labelLocation="top"
                  scrollingEnabled={true}
                >
                  <Item itemType="group" colCount={2}>
                    <Item
                      dataField="minVal"
                      label={{ text: getTrad("minVal") }}
                      editorType="dxNumberBox"
                      editorOptions={this.decimal_editorOptions}
                    />
                    <Item
                      dataField="maxVal"
                      label={{ text: getTrad("maxVal") }}
                      editorType="dxNumberBox"
                      editorOptions={this.decimal_editorOptions}
                    >
                      <CustomRule
                        reevaluate={true}
                        validationCallback={
                          this.validationCallback_CustomRule_MaxVal
                        }
                        message="Rango inválido"
                      />
                    </Item>
                    <Item
                      itemType="group"
                      caption={getTrad("rangoVerde")}
                      colCount={2}
                      colSpan={2}
                    >
                      <Item
                        dataField="inicioRango1"
                        label={{ text: getTrad("inicio") }}
                        editorType="dxNumberBox"
                        editorOptions={this.decimal_editorOptions}
                      >
                        <CustomRule
                          reevaluate={true}
                          validationCallback={
                            this.validationCallback_CustomRule_Rangos
                          }
                          message="Rango inválido"
                        />
                      </Item>
                      <Item
                        dataField="finRango1"
                        label={{ text: getTrad("fin") }}
                        editorType="dxNumberBox"
                        editorOptions={this.decimal_editorOptions}
                      >
                        <CustomRule
                          reevaluate={true}
                          validationCallback={
                            this.validationCallback_CustomRule_Rangos
                          }
                          message="Rango inválido"
                        />
                      </Item>
                    </Item>
                    <Item
                      itemType="group"
                      caption={getTrad("rangoAmarillo")}
                      colCount={2}
                      colSpan={2}
                    >
                      <Item
                        dataField="inicioRango2"
                        label={{ text: getTrad("inicio") }}
                        editorType="dxNumberBox"
                        editorOptions={this.decimal_editorOptions}
                      >
                        <CustomRule
                          reevaluate={true}
                          validationCallback={
                            this.validationCallback_CustomRule_Rangos
                          }
                          message="Rango inválido"
                        />
                      </Item>
                      <Item
                        dataField="finRango2"
                        label={{ text: getTrad("fin") }}
                        editorType="dxNumberBox"
                        editorOptions={this.decimal_editorOptions}
                      >
                        <CustomRule
                          reevaluate={true}
                          validationCallback={
                            this.validationCallback_CustomRule_Rangos
                          }
                          message="Rango inválido"
                        />
                      </Item>
                    </Item>
                  </Item>
                  <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={{
                      text: getTrad("aceptar"),
                      type: "success",
                      useSubmitBehavior: true,
                    }}
                  />
                </Form>
              </form>
            </Popup>

            <Popup
              title={
                this.state.currentRow.denominacion +
                " - " +
                getTrad("observaciones")
              }
              showTitle={true}
              width={550}
              height={"auto"}
              visible={this.state.popup_Observaciones_visible}
              closeOnOutsideClick={true}
              onHiding={this.hiding_popup}
            >
              <form onSubmit={this.popup_submit}>
                <Form
                  formData={this.state.currentRow}
                  labelLocation="top"
                  scrollingEnabled={true}
                >
                  <Item itemType="group" colCount={1}>
                    <Item
                      dataField="observaciones"
                      label={{ text: getTrad("observaciones") }}
                      editorType="dxTextArea"
                    />
                    <Item
                      dataField="tblDatosFinancierosNTrimestre[0].observaciones"
                      label={{ text: getTrad("primerTrimestre") }}
                      editorType="dxTextArea"
                    />
                    <Item
                      dataField="tblDatosFinancierosNTrimestre[1].observaciones"
                      label={{ text: getTrad("segundoTrimestre") }}
                      editorType="dxTextArea"
                    />
                    <Item
                      dataField="tblDatosFinancierosNTrimestre[2].observaciones"
                      label={{ text: getTrad("tercerTrimestre") }}
                      editorType="dxTextArea"
                    />
                    <Item
                      dataField="tblDatosFinancierosNTrimestre[3].observaciones"
                      label={{ text: getTrad("cuartoTrimestre") }}
                      editorType="dxTextArea"
                    />
                  </Item>
                  <ButtonItem
                    horizontalAlignment="right"
                    buttonOptions={{
                      text: getTrad("aceptar"),
                      type: "success",
                      useSubmitBehavior: true,
                    }}
                  />
                </Form>
              </form>
            </Popup>
          </div>
          {/* </ReactCSSTransitionGroup> */}
        </div>
      </Fragment>
    );
  }

  //GRID
  rowUpdating(e) {
    //Se updatea siempre los siguientes valores para darles un valor por defecto en caso de no exisir el registro
    //También se enviará todo el array tblDatosFinancierosNTrimestre, asignado al newData en (tblDatosFinancierosNTrimestre_setCellValue)
    e.newData.minVal =
      typeof e.newData.minVal !== "undefined"
        ? e.newData.minVal
        : e.oldData.minVal;
    e.newData.maxVal =
      typeof e.newData.maxVal !== "undefined"
        ? e.newData.maxVal
        : e.oldData.maxVal;
    e.newData.inicioRango1 =
      typeof e.newData.inicioRango1 !== "undefined"
        ? e.newData.inicioRango1
        : e.oldData.inicioRango1;
    e.newData.finRango1 =
      typeof e.newData.finRango1 !== "undefined"
        ? e.newData.finRango1
        : e.oldData.finRango1;
    e.newData.inicioRango2 =
      typeof e.newData.inicioRango2 !== "undefined"
        ? e.newData.inicioRango2
        : e.oldData.inicioRango2;
    e.newData.finRango2 =
      typeof e.newData.finRango2 !== "undefined"
        ? e.newData.finRango2
        : e.oldData.finRango2;
    e.newData.tblDatosFinancierosNTrimestre =
      typeof e.newData.tblDatosFinancierosNTrimestre !== "undefined"
        ? e.newData.tblDatosFinancierosNTrimestre
        : e.oldData.tblDatosFinancierosNTrimestre;
    e.newData.observaciones =
      typeof e.newData.observaciones !== "undefined"
        ? e.newData.observaciones
        : e.oldData.observaciones;
    e.newData.T1 =
      typeof e.newData.T1 !== "undefined" ? e.newData.T1 : e.oldData.T1;
    e.newData.T2 =
      typeof e.newData.T2 !== "undefined" ? e.newData.T2 : e.oldData.T2;
    e.newData.T3 =
      typeof e.newData.T3 !== "undefined" ? e.newData.T3 : e.oldData.T3;
    e.newData.T4 =
      typeof e.newData.T4 !== "undefined" ? e.newData.T4 : e.oldData.T4;
  }
  validationCallback_CustomRule_Trimestres(e) {
    let data = e.data;
    if (
      data.idConceptoFinanciero > 4 &&
      data.idConceptoFinanciero !== 13 &&
      data.idConceptoFinanciero !== 15 &&
      data.idConceptoFinanciero !== 16 &&
      data.idConceptoFinanciero !== 17 &&
      data.idConceptoFinanciero !== 18
    ) {
      //TODOS LOS CONCEPTOS QUE TIENEN MAX VALUE Y MIN VALUE
      if (e.value <= data.maxVal) {
        return true;
      }
      return false;
    }

    return true;
  }

  editRow(itemData, isObservaciones) {
    this.setState({
      currentRow: itemData.data,
      popup_Observaciones_visible: isObservaciones,
      popup_DatosGraficos_visible: !isObservaciones,
    });
  }
  //POPUP
  hiding_popup() {
    this.setState({
      currentRow: {},
      popup_DatosGraficos_visible: false,
      popup_Observaciones_visible: false,
    });
  }
  popup_submit(e) {
    e.preventDefault();
    let { currentRow } = this.state;
    let _this = this;

    let index = this.grid_datos.getRowIndexByKey({
      año: currentRow.año,
      idConceptoFinanciero: currentRow.idConceptoFinanciero,
      idGrupoEmpresarial: currentRow.idGrupoEmpresarial,
    });

    var fields = [
      "minVal",
      "maxVal",
      "inicioRango1",
      "finRango1",
      "inicioRango2",
      "finRango2",
      "tblDatosFinancierosNTrimestre",
      "observaciones",
    ];
    $.each(fields, function (i, field) {
      _this.grid_datos.cellValue(index, field, currentRow[field]);
    });
    this.grid_datos.saveEditData().done(function () {
      notify({
        message: getTrad("aviso_C_RegistroActualizado"),
        type: "success",
        displayTime: "1500",
        closeOnClick: true,
      });

      _this.hiding_popup();
    });
  }

  validationCallback_CustomRule_MaxVal(e) {
    let data = this.state.currentRow;
    let minValue = data.minVal;

    if (typeof data.tblDatosFinancierosNTrimestre !== "undefined") {
      minValue = data.inicioRango1 > minValue ? data.inicioRango1 : minValue;
      minValue = data.finRango1 > minValue ? data.finRango1 : minValue;
      minValue = data.inicioRango2 > minValue ? data.inicioRango2 : minValue;
      minValue = data.finRango2 > minValue ? data.finRango2 : minValue;

      minValue =
        data.tblDatosFinancierosNTrimestre[0].valor > minValue
          ? data.tblDatosFinancierosNTrimestre[0].valor
          : minValue;
      minValue =
        data.tblDatosFinancierosNTrimestre[1].valor > minValue
          ? data.tblDatosFinancierosNTrimestre[1].valor
          : minValue;
      minValue =
        data.tblDatosFinancierosNTrimestre[2].valor > minValue
          ? data.tblDatosFinancierosNTrimestre[2].valor
          : minValue;
      minValue =
        data.tblDatosFinancierosNTrimestre[3].valor > minValue
          ? data.tblDatosFinancierosNTrimestre[3].valor
          : minValue;

      return minValue <= e.value;
    }
    return true;
  }
  validationCallback_CustomRule_Rangos(e) {
    let data = this.state.currentRow;
    let value = data[e.formItem.dataField];
    if (typeof data.tblDatosFinancierosNTrimestre !== "undefined") {
      return value <= data.maxVal;
    }
    return true;
  }

  //LOAD PANEL
  loadPanel_show(shading) {
    this.props.loadPanel_show(shading);
  }
  loadPanel_hide() {
    this.props.loadPanel_hide();
  }

  componentDidMount() {
    let _this = this;
    this.dataSource_tblConceptosFinancieros.load();
    this.dataSource_tblGrupoEmpresarial.load().done(function () {
      _this.dataSource_tblDatosFinancieros_anterior.load();
    });
    this.loadPanel_hide();
  }
}

const mapStateToProps = (state) => ({
  lavanderia: state.Global.lavanderia,
  idioma: state.Global.idioma,
  user: state.Authentication.user,
});

const mapDispatchToProps = (dispatch) => ({
  loadPanel_show: (shading) => dispatch(loadPanelActions.show(shading)),
  loadPanel_hide: () => dispatch(loadPanelActions.hide()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DatosFinancieros);
