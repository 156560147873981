import React from "react";
import { ControlPresupuestarioCxt } from "../../contexts/context";
import { formatNumber } from "helpers";
import Loader from "react-loaders";
import { SelectorCentro } from "../Filtros";

class CentroCard extends React.Component {
    constructor(props) {
        super(props);
    }

    style = {
        cardTitle: { flexBasis: 40 },
        cardBody: { flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "pre" },
    };

    render() {
        const { title, icon, value, placeholder, color } = this.props;
        return (
            <div className="w-100 he-100 card border-radius resumenCard" style={{ "--cardColor": color }}>
                <div
                    className="align-content-center align-items-center justify-content-between d-flex font-weight-bold px-3"
                    style={this.style.cardTitle}
                >
                    <span>{title}</span>
                    <SelectorCentro />

                    <i className={icon + " font-size-xl"} />
                </div>
                <div
                    className={`align-content-center align-items-center px-4 font-size-xxll`}
                    style={this.style.cardBody}
                >
                    {value ?? placeholder}
                </div>
            </div>
        );
    }
}

export default CentroCard;
