import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import DataGrid, {
  Column,
  ColumnChooser,
  Export,
  FilterRow,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  SearchPanel,
  Selection,
  Sorting,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";

import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import CustomStore from "devextreme/data/custom_store";

import $ from "jquery";

import {
  errorHandler,
  authHeader,
  getTrad,
  tooltipControl_creacion,
} from "helpers";
import { connectionConstants } from "../../../../../../../constants";

const allowedPageSizes = [20, 50, 100];

const DataGridMovimiento = ({
  setSelectedRowKeys,
  visible = true,
  lavanderia: { idLavanderia },
  idioma,
}) => {
  const dataGrid = useRef(null);

  useEffect(() => {
    if (dataGrid.current) {
      const dataGridInstance = dataGrid.current.instance;
      dataGridInstance.clearFilter();
      dataGridInstance.clearSelection();
      dataGridInstance.state({});
      dataGridInstance.repaint();
    }
  }, [visible]);

  useEffect(() => {
    loadData();
  }, [idLavanderia]);

  const enum_isEntrada = [
    {
      value: true,
      denominacion: getTrad("entrada"),
    },
    {
      value: false,
      denominacion: getTrad("salida"),
    },
  ];

  const datasource_tblMovimiento_beforeSend = (request) => {
    request.headers = { ...authHeader() };

    request.params.idLavanderia = idLavanderia;
  };

  const datasource_tblMovimiento = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblMovimiento",
      key: "idMovimiento",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: datasource_tblMovimiento_beforeSend,
      version: 4,
    }),
    expand: [
      "idCompañiaNavigation($select=denominacion)",
      "idEntidadNavigation($select=denominacion)",
      "idTipoMovimientoNavigation($select=isEntrada)",
    ],
  });

  const loadData = () => datasource_tblMovimiento.load();

  const datasource_tblMovimiento_postProcess = (data) =>
    data.map((item) => ({
      ...item,
      denominacion: item.idTraduccionNavigation[idioma.codigo].toUpperCase(),
    }));

  const datasource_tblTipoMovimiento = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblTipoMovimiento",
      key: "idTipoMovimiento",
      errorHandler: (error) => errorHandler(error, null),
      beforeSend: (request) => (request.headers = { ...authHeader() }),
      version: 4,
    }),
    select: ["idTipoMovimiento", "denominacion"],
    expand: [`idTraduccionNavigation($select=${idioma.codigo})`],
    postProcess: datasource_tblMovimiento_postProcess,
  });

  const customstore_tblTipoMovimiento_load = () =>
    new Promise((resolve, reject) =>
      datasource_tblTipoMovimiento.load().done(resolve).catch(reject),
    );

  const customstore_tblTipoMovimiento = () => ({
    key: "idTipoMovimiento",
    store: new CustomStore({
      load: customstore_tblTipoMovimiento_load,
      byKey: (key) => {
        const tipoMovimiento = datasource_tblTipoMovimiento
          .items()
          .find((tm) => tm.idTipoMovimiento === key);

        return new Promise((resolve, reject) => {
          if (tipoMovimiento) resolve(tipoMovimiento);
          else reject();
        });
      },
    }),
  });

  const onRowClick = ({ isSelected, component, key }) =>
    isSelected ? component.deselectRows(key) : component.selectRows(key, true);

  const onRowPrepared = ({ rowType, rowElement }) => {
    if (rowType === "data") rowElement.css("cursor", "pointer");
  };

  const onToolbarPreparing = ({ toolbarOptions, component }) => {
    toolbarOptions.items.unshift(
      {
        location: "before",
        template: () =>
          $(
            '<div id="movimientos_title" class="font-size" style="margin-top:10px" >',
          ).text(getTrad("movimientos")),
      },
      {
        location: "affter",
        widget: "dxButton",
        showText: "always",
        locateInMenu: "auto",
        options: {
          width: "100%",
          icon: "exportxlsx",
          text: getTrad("exportar"),
          type: "normal",
          onClick: () => component.exportToExcel(),
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        locateInMenu: "auto",
        options: {
          text: getTrad("limpiarFiltro"),
          icon: " icon_EliminarFiltros",
          onInitialized: ({ element, component }) =>
            tooltipControl_creacion(element, component.option("text")),
          onClick: () => {
            component.clearFilter();
            notify({
              message: getTrad("aviso_C_FiltroRestaurado"),
              type: "success",
              displayTime: "1500",
              closeOnClick: true,
            });
          },
        },
      },
      {
        location: "after",
        widget: "dxButton",
        showText: "inMenu",
        options: {
          icon: "refresh",
          text: getTrad("actualizar"),
          onInitialized: ({ element, component }) =>
            tooltipControl_creacion(element, component.option("text")),
          onClick: loadData,
        },
      },
    );
  };

  const unSelectRowKeys = (component) => {
    component.option("selectedRowKeys", []);
    setSelectedRowKeys([]);
  };

  const onSelectionChanged = ({ selectedRowKeys, component }) => {
    if (selectedRowKeys.length > 1000) {
      notify({
        message: getTrad("aviso_I_LimiteMovimientos"),
        type: "error",
        displayTime: "1500",
        closeOnClick: true,
      });
      unSelectRowKeys(component);
    } else {
      setSelectedRowKeys(selectedRowKeys);
    }
  };

  const onContentReady = ({ component }) => {
    var filter_old = component.option("filter_old");
    var filter_new = component.getCombinedFilter();

    if (JSON.stringify(filter_old) !== JSON.stringify(filter_new)) {
      component.option("filter_old", filter_new);
      unSelectRowKeys(component);
    }
  };

  const calculateCellValue = ({
    idCompañia,
    idCompañiaNavigation,
    idEntidadNavigation,
  }) =>
    idCompañia
      ? idCompañiaNavigation.denominacion
      : idEntidadNavigation.denominacion;

  const calculateFilterExpression_denoCliente = (filterValue) => [
    ["idCompañiaNavigation/denominacion", "contains", filterValue],
    "or",
    ["idEntidadNavigation/denominacion", "contains", filterValue],
  ];

  const calculateFilterExpression_tblPrendaNMovimiento = (filterValue) => [
    [
      `tblPrendaNMovimiento/any(pnm: contains(tolower(pnm/idPrendaNavigation/denominacion), '${filterValue}'))`,
    ],
    "or",
    [
      `tblPrendaNMovimiento/any(pnm: contains(tolower(pnm/idPrendaNavigation/codigoPrenda), '${filterValue}'))`,
    ],
  ];

  return (
    <DataGrid
      ref={dataGrid}
      dataSource={datasource_tblMovimiento}
      width={"100%"}
      height={"100%"}
      showRowLines
      columnsAutoWidth
      hoverStateEnabled
      rowAlternationEnabled
      allowColumnReordering
      showColumnLines={false}
      onRowClick={onRowClick}
      onRowPrepared={onRowPrepared}
      onToolbarPreparing={onToolbarPreparing}
      onSelectionChanged={onSelectionChanged}
      onContentReady={onContentReady}
    >
      <HeaderFilter visible />
      <SearchPanel visible width={240} />
      <FilterRow visible />
      <Sorting mode={"multiple"} />
      <Paging defaultPageSize={50} />
      <Pager
        showInfo
        showPageSizeSelector
        allowedPageSizes={allowedPageSizes}
      />
      <Selection mode={"multiple"} showCheckBoxesMode={"always"} />
      <Export fileName={getTrad("movimientos")} />
      <ColumnChooser enabled />
      <Column
        dataField={"idMovimiento"}
        caption={getTrad("codigo")}
        alignment={"center"}
        width={70}
        allowHeaderFiltering={false}
      />
      <Column
        dataField={"fecha"}
        caption={getTrad("fecha")}
        dataType={"date"}
        alignment={"center"}
        width={110}
        sortOrder={"desc"}
        allowHeaderFiltering={false}
        format={"dd/MM/yyyy"}
      />
      <Column
        dataField={"denoCliente"}
        caption={`${getTrad("compañia")} / ${getTrad("entidad")}`}
        minWidth={200}
        allowHeaderFiltering={false}
        allowHiding={false}
        calculateCellValue={calculateCellValue}
        calculateFilterExpression={calculateFilterExpression_denoCliente}
      />
      <Column
        dataField={"idTipoMovimientoNavigation.isEntrada"}
        caption={getTrad("tipo")}
        width={100}
        alignment={"left"}
      >
        <Lookup
          dataSource={enum_isEntrada}
          valueExpr={"value"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"idTipoMovimiento"}
        caption={getTrad("subTipo")}
        width={140}
        alignment={"left"}
      >
        <Lookup
          dataSource={customstore_tblTipoMovimiento}
          valueExpr={"idTipoMovimiento"}
          displayExpr={"denominacion"}
        />
      </Column>
      <Column
        dataField={"observaciones"}
        caption={getTrad("observaciones")}
        minWidth={200}
        allowHeaderFiltering={false}
        visible={false}
      />
      <Column
        dataField={"codigoAlbaran"}
        caption={getTrad("codigoAlbaran")}
        minWidth={120}
        allowHeaderFiltering={false}
        visible={false}
      />
      <Column
        dataField={"codigoFactura"}
        caption={getTrad("codigoFactura")}
        minWidth={120}
        allowHeaderFiltering={false}
        visible={false}
      />
      <Column
        visible={false}
        showInColumnChooser={false}
        calculateFilterExpression={
          calculateFilterExpression_tblPrendaNMovimiento
        }
      />
    </DataGrid>
  );
};

const mapStateToProps = ({ Global: { lavanderia, idioma } }) => ({
  lavanderia,
  idioma,
});

export default connect(mapStateToProps)(DataGridMovimiento);
