import { Draggable } from "devextreme-react";
import "./Draggable.css";
import FotoPersona from "components/FotoPersona";

const { Component } = require("react");
const targetColor = "rgb(48, 177, 255)";
const backgroundColor = "rgba(237, 182, 55, 0.68)";

// CREA LOS ELEMENTOS DE LAS PERSONAS EN EL MAPA DE LAS MAQUINAS Y AREAS
class Posicion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { prevision, posicion, svg, style, layout, enablePosition } = this.props;
        const { onMouseEnter, onMouseLeave, onClick } = this.props;

        const id =
            posicion && posicion !== null
                ? "posicion_" + posicion.idPosicionNAreaLavanderiaNLavanderia
                : prevision !== null
                ? `posicion_area_${prevision.idCuadrantePersonal}`
                : "add_area";

        const size = 50;

        const marked =
            prevision &&
            prevision == layout?.getTooltipData()?.prevision &&
            layout?.getTooltipData()?.target.includes("lista");

        return (
            <div
                style={{
                    backgroundColor: marked
                        ? targetColor
                        : prevision && prevision !== null
                        ? backgroundColor
                        : undefined,
                    borderRadius: size,
                    width: size,
                    height: size,
                    ...style,
                }}
                onMouseEnter={(e) => {
                    onMouseEnter(e, posicion, prevision);
                }}
                onMouseLeave={(e) => onMouseLeave(e, prevision)}
                onClick={(e) => onClick(e, posicion, prevision)}
            >
                {/* IMPORTANTE */}
                {/* 
                Necesita ser un elemento Draggable para tener el evento onDrop 
                que detecta cuando se ha soltado una persona en la posicion

                El campo "group" tiene que coincidir en todos los draggables 
                para que puedan interracionar entre si
            */}
                <Draggable
                    onDrop={(e) => {
                        onClick(e, posicion, prevision, e.fromData);
                    }}
                    group={"draggingGroup"}
                    clone={true}
                    className={enablePosition ? undefined : "draggable-disabled"}
                    style={{
                        display: "inline-flex",
                        borderRadius: size,
                        opacity: marked ? 0.5 : 1,
                        outline: marked ? "dashed 3px " + targetColor : null,
                        outlineOffset: "5px",
                    }}
                    // Las posiciones no deberian poder moverse por lo tanto
                    // cancela la accion según empieza
                    onDragStart={(e) => {
                        e.cancel = true;
                    }}
                >
                    {/* Imagen perfil o SVG de posicion vacia */}
                    {prevision?.idPersona ? (
                        <FotoPersona
                            idPersona={prevision?.idPersona}
                            style={{
                                width: size,
                                height: size,
                                opacity: 1,
                                borderRadius: size,
                                objectFit: "cover",
                            }}
                        />
                    ) : (
                        <img
                            id={id}
                            src={
                                prevision && prevision !== null
                                    ? prevision.image
                                    : posicion && posicion !== null
                                    ? svg.persona
                                    : svg.add
                            }
                            className="align-self-center"
                            height={size}
                            width={size}
                            style={{
                                opacity: 1,
                                borderRadius: size,
                                objectFit: "cover",
                            }}
                        />
                    )}
                    {/* SVG de "eliminar" persona de la posicion */}
                    <img
                        src={svg.remove}
                        className="align-self-center"
                        height={size}
                        width={size}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            opacity: 0,
                            borderRadius: size,
                            objectFit: "cover",
                        }}
                    />
                </Draggable>
            </div>
        );
    }
}

export default Posicion;
