import { createBrowserHistory } from "history";
import { logUsoService } from "../services";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

history.listen((location) => {
  logUsoService.setLog(3);
});
