import React, { useEffect, useRef, useState } from "react";

import DataGrid, {
    Column,
    CustomRule,
    Editing,
    KeyboardNavigation,
    Lookup,
    RequiredRule,
    Sorting,
    Summary,
    TotalItem,
} from "devextreme-react/data-grid";

import $ from "jquery";

import { errorHandler, authHeader, formatNumber, getTrad } from "helpers";
import { removeCellComponent } from "components/DataGrid/Cells";
import { datasource_tblIvaNPais_Compra, datasource_tblMoneda } from "pages/Administracion/shared/model";
import { Button, Popup } from "devextreme-react";
import GestorArticulos from "../GestorArticulos";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";

import { connectionConstants } from "constants";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";

import { custom_displayExpr, getFormatCurrency, getFormatCurrencyPrecio, retrieveIvaValue } from "pages/Administracion/shared/functions";

const datasource_tblAdmCuentaContable = new DataSource({
    paginate: false,
    store: new ODataStore({
        url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblAdmCuentaContable",
        errorHandler: (error) => errorHandler(error, null),
        beforeSend: (request) => store_tblAdmCuentaContable_beforeSend(request),
        version: 4,
    }),
    select: ["idAdmCuentaContable", "codigo", "denominacion"],
});

const store_tblAdmCuentaContable_beforeSend = (request) => {
    request.headers = { ...authHeader() };
};

const DataGridArticulos = ({
    idMoneda,
    dataSource = [],
    validations,
    tipoArticulos,
    updateSummary,
    isCompra,
    showIva = true,
    readOnly = false,
}) => {
    //Variable auxiliar que evita la autogestion de los cambios realizados en el datagrid, dandonos mayor control sobre lo que se guarda y lo que no.
    //La variable en si no hace nada, y no debe hacer nada salvo que queramos hacer algo con los cambios que implique su persistencia entre renders
    let changes = [];
    const dataGridRef = useRef(null);
    const [gestorArticulosVisible, setGestorArticulosVisible] = useState(false);

    useEffect(() => {
        datasource_tblAdmCuentaContable.filter(
            isCompra ? ["codigo", "startsWith", "6"] : ["codigo", "startsWith", "7"]
        );
        datasource_tblAdmCuentaContable.load();
    }, []);

    const [newRowData, setNewRowData] = useState();

    const formats = {
        percent: {
            type: "percent",
            maximumFractionDigits: 0,
        },
        currencyPrecio: getFormatCurrencyPrecio(idMoneda),
        currency: getFormatCurrency(idMoneda),
        number: {
            style: "decimal",
            maximumFractionDigits: 0,
        },
    };

    const dataGrid_onRowUpdating = (e) => {
        if (e.newData.codigoArticulo != null && e.newData.codigoArticulo != undefined) {
            e.newData.codigoArticulo = e.newData.codigoArticulo.trim();
        }
    };

    const editorOptions = {
        cantidad: {
            step: 0,
            format: { type: "fixedPoint", precision: 2, /* style: "decimal", maximumFractionDigits: 0 */ },
            min: 1,
            max: 99999999,
        },
        precio: {
            step: 0,
            format: formats.currency,
            min: 0,
            max: 9999999999,
        },
        porcentajes: {
            step: 0.01,
            format: formats.percent,
            min: 0,
            max: 1,
        },
        cuentaContable: { dropDownOptions: { minWidth: 450 } },
    };

    useEffect(() => {
        validations.push(checkIsValid);
    }, []);

    const checkIsValid = () => {
        let isValid = true;
        // const codigosRepetidos = new Set();
        let mensajeError = "";
        return new Promise((resolve, reject) => {
            dataGridRef.current.instance.saveEditData().done(function () {
                setTimeout(() => {
                    if (dataGridRef.current.instance.getVisibleRows().length === 0) {
                        isValid = false;
                        mensajeError = getTrad("errorSinArticulos");
                    }
                    dataGridRef.current.instance.getVisibleRows().forEach((row) => {
                        // if (codigosRepetidos.has(row.data.codigoArticulo)) {
                        //     isValid = false;
                        //     mensajeError = getTrad("errorCodigosRepetidos");
                        // } else {
                        // codigosRepetidos.add(row.data.codigoArticulo);
                        // }
                        if (row.isEditing === true) {
                            if (dataGridRef.current.instance.hasEditData()) {
                                //Esto evita un falso negativo
                                isValid = false;
                                mensajeError = getTrad("errorCamposRequeridosVacios");
                            }
                        }
                    });

                    if (isValid) {
                        resolve();
                    } else {
                        reject(mensajeError);
                    }
                }, 0);
            });
        });
    };

    const calculateCellValue_bruto = ({ cantidad = 0, precio = 0, iva = 0 }) => {
        let resultado = cantidad * precio; //* (1 + iva);
        return isNaN(resultado) ? 0 : resultado;
    };

    const calculateCellValue_total = ({ cantidad = 0, precio = 0, idIvaNPais = 0, descuento = 0 }) => {
        let apartado = "venta";
        if(showIva = false){
            apartado = "compra"
        } 
        let iva = retrieveIvaValue(idIvaNPais, apartado);
        let resultado = cantidad * precio * (1 + iva) * (1 - descuento);
        return isNaN(resultado) ? 0 : resultado;
    };

    const netoSummary = (e) => {
        if (updateSummary) {
            let apartado = "venta";
            if(showIva = false){
                apartado = "compra"
            } 
            updateSummary(e, apartado);
        }
        return customSummary(e);
    };

    const customSummary = (e) => {
        const codigoMoneda = datasource_tblMoneda.items().find((m) => m.idMoneda === idMoneda)?.codigo || "EUR";
        return formatNumber(e, 2, "currency", codigoMoneda);
    };

    const dxPopup_onHiding = () => {
        setGestorArticulosVisible(false);
    };

    const GestorArticulos_onRowClick = (e) => {
        setGestorArticulosVisible(false);
        setNewRowData(e.data);
        dataGridRef.current.instance.addRow();
    };

    const crearArticuloLibre = () => {
        setNewRowData({});
        dataGridRef.current.instance.addRow();
    };

    const onInitNewRow = ({ data, component }) => {
        data.idArticulo = newRowData.idArticulo;
        data.tipoArticulo = newRowData.tipoArticulo;
        data.codigo = newRowData.codigo;
        data.denominacion = newRowData.denominacion;
        data.idAdmCuentaContable = newRowData[isCompra ? "idAdmCuentaContableCompra" : "idAdmCuentaContableVenta"];

        setTimeout(() => {
            component.saveEditData();
        }, 150);
    };

    const validationCallback_descripcion = ({ value, data }) => {
        return !((value == null || value.length === 0) && data.idArticulo == null);
    };
    
    return (
        <div style={{ height: 267 }}>
            <Toolbar className="mb-2">
                <ToolbarItem
                    location={"before"}
                    template={() =>
                        $('<div id="articulos_title" class="font-size" style="margin-top:10px" >').text(
                            getTrad("articulos")
                        )
                    }
                />
                {!readOnly && [
                    <ToolbarItem location={"after"}>
                        <Button
                            icon={"plus"}
                            text={getTrad("añadirArticuloLibre")}
                            disabled={tipoArticulos == null}
                            onClick={crearArticuloLibre}
                        />
                    </ToolbarItem>,
                    <ToolbarItem location={"after"}>
                        <Button
                            icon={"plus"}
                            text={getTrad("añadirArticulo")}
                            disabled={tipoArticulos == null}
                            onClick={() => setGestorArticulosVisible(true)}
                        />
                    </ToolbarItem>,
                ]}
            </Toolbar>
            <DataGrid
                ref={dataGridRef}
                dataSource={dataSource}
                height={"224px"}
                showRowLines
                columnsAutoWidth
                rowAlternationEnabled
                showColumnLines={false}
                remoteOperations={false}
                onInitNewRow={onInitNewRow}
            >
                <Sorting mode={"multiple"} />
                <KeyboardNavigation enterKeyAction={"moveFocus"} enterKeyDirection={"row"} editOnKeyPress />
                {!readOnly && <Editing mode={"cell"} allowUpdating changes={changes} />}

                <Column dataField={"idArticulo"} allowEditing={false} visible={false} />
                <Column dataField={"tipoArticulo"} allowEditing={false} visible={false} />
                <Column
                    dataField={"codigo"}
                    caption={getTrad("codigo")}
                    dataType={"string"}
                    alignment={"center"}
                    width={125}
                    allowEditing={false}
                />
                <Column
                    dataField={"denominacion"}
                    caption={getTrad("denominacion")}
                    dataType={"string"}
                    allowEditing={false}
                />
                <Column dataField={"descripcion"} caption={getTrad("descripcion")} dataType={"string"}>
                    <CustomRule validationCallback={validationCallback_descripcion} />
                </Column>
                <Column
                    dataField={"idAdmCuentaContable"}
                    caption={getTrad("cuentaContable")}
                    dataType={"string"}
                    alignment={"left"}
                    editorOptions={editorOptions.cuentaContable}
                >
                    <Lookup
                        dataSource={datasource_tblAdmCuentaContable.items()}
                        displayExpr={custom_displayExpr}
                        valueExpr={"idAdmCuentaContable"}
                    />
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"cantidad"}
                    caption={getTrad("cantidad")}
                    dataType={"number"}
                    // sortOrder={'desc'}
                    width={100}
                    editorOptions={editorOptions.cantidad}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"precio"}
                    caption={getTrad("precio")}
                    dataType={"number"}
                    width={100}
                    format={formats.currencyPrecio}
                    editorOptions={editorOptions.precio}
                >
                    <RequiredRule />
                </Column>
                <Column
                    dataField={"descuento"}
                    caption={getTrad("descuento")}
                    dataType={"number"}
                    width={100}
                    format={formats.percent}
                    editorOptions={editorOptions.porcentajes} //quizás poner algunas opciones
                />
                {showIva && (
                    <Column
                        dataField={"idIvaNPais"}
                        caption={getTrad("iva")}
                        dataType={"number"}
                        width={100}
                        format={formats.percent}
                        editorOptions={editorOptions.porcentajes}
                    >
                        <RequiredRule />
                        <Lookup
                            dataSource={datasource_tblIvaNPais_Compra.items()}
                            valueExpr={"idIvaNPais"}
                            displayExpr={"valor"}
                        />
                    </Column>
                )}
                <Column
                    caption={getTrad("bruto")}
                    dataType={"number"}
                    width={100}
                    format={formats.currency}
                    calculateCellValue={calculateCellValue_bruto} //cambiar
                    allowEditing={false}
                />
                <Column
                    caption={getTrad("total")}
                    dataType={"number"}
                    width={100}
                    format={formats.currency}
                    calculateCellValue={calculateCellValue_total} //cambiar
                    allowEditing={false}
                />
                {!readOnly && (
                    <Column
                        caption={" "}
                        width={30}
                        alignment={"center"}
                        cssClass={"p-0"}
                        cellComponent={removeCellComponent}
                    />
                )}
                <Summary>
                    <TotalItem column={getTrad("bruto")} summaryType="sum" displayFormat={customSummary} />
                    <TotalItem column={getTrad("total")} summaryType="sum" displayFormat={netoSummary} />
                </Summary>
            </DataGrid>
            <Popup
                height={600}
                width={1100}
                visible={gestorArticulosVisible}
                dragEnabled={false}
                title={getTrad("articulos")}
                onHiding={dxPopup_onHiding}
            >
                <div className="he-100 w-100 position-relative">
                    <GestorArticulos
                        isMainScreen={false}
                        onRowClick={GestorArticulos_onRowClick}
                        isCompra={isCompra}
                        tipoArticulos={tipoArticulos}
                    />
                </div>
            </Popup>
        </div>
    );
};

export default DataGridArticulos;
