import { connectionConstants } from "../../../constants";
import {
  authHeader,
  errorHandler,
  getTrad,
  filtroActivo,
  leadingZero,
  enum_NumOrdinales,
  tooltipControl_creacion,
  patchRequestHandler,
} from "../../../helpers";

import { svg_edit, svg_remove } from "../../../styles/svg_iconos";
import $ from "jquery";
import "devextreme/integration/jquery";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import notify from "devextreme/ui/notify";
import throttle from "lodash/throttle";
//Css
import "./Css.scss";

export default function cargaJs(COMPONENT, LAVANDERIA, IDIOMA) {
  var deferred = $.Deferred();

  var datasource_maquinaria;
  var datasource_tipoMaquinaria;
  var datasource_sistemaMaquina;
  var datasource_categoriaMaquina;
  var datasource_tipoMaquinaNCategoriaMaquina;
  var datasource_tareaMaquina;
  var datasource_frecuenciaMantenimiento;
  var datasource_dia;
  var datasource_mes;
  var datasource_responsableTarea;

  var maquinaSel;
  var maquinaSel_bak; //copia de maquinaSel para tener los datos originales de la maquina, se actualzia al cambiar de Sistema, categoria o tipo
  var familiaSel = null;

  //#region Datasource

  datasource_maquinaria = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMaquina",
      key: "idMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
        request.params.todas = true;
      },
      onLoading: function (loadOptions) {
        loadOptions.expand =
          "tblIncidencia($filter=estado eq false;$count=true;$top=0),tblTipoMaquinaNCategoriaMaquina($expand=tblCategoriaMaquina($select=idSistemaMaquina))";
      },
      version: 4,
    }),
    postProcess: function (data) {
      $.each(data, function (index, item) {
        item.numIncidenciasAbiertas = item["tblIncidencia@odata.count"];
        delete item.tblIncidencia;
        delete item["tblIncidencia@odata.count"];

        //Inicializar vacío para que coja los cambios el form
        $.each(item.items, function (index2, item2) {
          if (item2.tblTipoMaquinaNCategoriaMaquina == null) {
            item2.tblTipoMaquinaNCategoriaMaquina = {
              tblCategoriaMaquina: {
                idSistemaMaquina: null,
              },
              idCategoriaMaquina: null,
              idTipoMaquina: null,
              idTipoMaquinaNCategoriaMaquina: null,
            };
          }
        });
      });
      return data;
    },
  });

  datasource_tareaMaquina = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "EF_spSelectTareasMaquina",
      key: "idTareaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idMaquina = maquinaSel.idMaquina;
      },
      version: 4,
    }),
  });

  datasource_frecuenciaMantenimiento = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblFrecuenciaMantenimiento",
      key: "idFrecuenciaMantenimiento",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idFrecuenciaMantenimiento", "denominacion"],
  });

  datasource_tipoMaquinaria = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoMaquina",
      key: "idTipoMaquinaNCategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    postProcess: function (data) {
      let data_ = [];
      $.each(data, function (index, item) {
        $.each(item.tblTipoMaquinaNCategoriaMaquina, function (index2, item2) {
          item2.denominacion = item.denominacion;
          data_.push(item2);
        });
      });
      return data_;
    },
    select: ["idTipoMaquina", "denominacion", "codigo"],
    expand: ["tblTipoMaquinaNCategoriaMaquina"],
    sort: "denominacion",
  });
  datasource_sistemaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblSistemaMaquina",
      key: "idSistemaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    select: ["idSistemaMaquina", "denominacion", "orden"],
    sort: "orden",
  });
  datasource_categoriaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCategoriaMaquina",
      key: "idCategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idCategoriaMaquina", "denominacion", "idSistemaMaquina"],
    sort: "idCategoriaMaquina",
    expand: ["tblTipoMaquinaNCategoriaMaquina($expand=tblTipoMaquina)"],
  });

  datasource_tipoMaquinaNCategoriaMaquina = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblTipoMaquinaNCategoriaMaquina",
      key: "idTipoMaquinaNCategoriaMaquina",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
  });

  datasource_dia = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblDiaSemana",
      key: "idDiaSemana",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idDiaSemana", "denominacion"],
  });

  datasource_mes = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblMes",
      key: "idMes",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idMes", "denominacion"],
  });

  datasource_responsableTarea = new DataSource({
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblResponsableTarea",
      key: "idResponsableTarea",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    select: ["idResponsableTarea", "denominacion"],
  });

  var datasource_compañias = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblCompañia",
      key: "idCompañia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idCompañia", "denominacion"],
  });

  var datasource_entidades = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.ODATA_URL + "tblEntidad",
      key: "idEntidad",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      version: 4,
    }),
    sort: "denominacion",
    select: ["idEntidad", "denominacion", "idCompañia"],
  });

  var datasource_tblPrendasHora = new DataSource({
    paginate: false,
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblPrendasHora",
      key: "idPrendasHora",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
        request.params.idLavanderia = LAVANDERIA.idLavanderia;
      },
      onLoading: function (loadOptions) {
        let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
        let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

        const filtroFamilia = ["idFamilia", "=", familiaSel != null ? familiaSel.idFamilia : null];
        const filtroTipoPrendaPlegadora = ["idTipoPrenda", "=", null];

        const filter = [
          [is_calandra ? filtroFamilia : filtroTipoPrendaPlegadora],
          ["idMaquina", "=", maquinaSel.idMaquina],
        ];

        if (loadOptions.filter) loadOptions.filter.push(filter);
        else loadOptions.filter = filter;
      },
      version: 4,
    }),
    expand: ["idTipoPrendaNavigation, idFamiliaNavigation($select=codigo)"],
    postProcess: function (data) {
      let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
      let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");
      let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

      if (is_calandra) {
        let tiposPrenda = familiaSel.tblTipoPrenda;
        tiposPrenda.forEach((item, index) => {
          const isItemInArray = data.filter((dbItem) => dbItem.idTipoPrenda === item.idTipoPrenda).length;
          if (!isItemInArray) {
            data.push({
              idPrendasHora: (index + 1) * -1,
              idMaquina: maquinaSel.idMaquina,
              idFamilia: familiaSel != null ? familiaSel.idFamilia : null,
              idTipoPrenda: item.idTipoPrenda,
              idTipoPrendaNavigation: {
                denominacion: item.denominacion,
              },
              idFamiliaNavigation: {
                codigo: item.codigo,
              },
              prendasHora: null,
              numVias: null,
            });
          }
        });
      } else if (is_plegadora) {
        let familias = datasource_tblFamilia.items();
        familias.forEach((item, index) => {
          let itemData = data.filter((dbItem) => dbItem.idFamilia === item.idFamilia);
          const isItemInArray = itemData.length > 0;
          if (!isItemInArray) {
            data.push({
              idPrendasHora: (index + 1) * -1,
              idMaquina: maquinaSel.idMaquina,
              idFamilia: item.idFamilia,
              idTipoPrenda: null,
              idTipoPrendaNavigation: {
                denominacion: item.denominacion,
              },
              idFamiliaNavigation: {
                codigo: item.codigo,
              },
              prendasHora: null,
              numVias: null,
            });
          } else {
            itemData[0].idTipoPrendaNavigation = {
              denominacion: item.denominacion,
            };
          }
        });
      }

      return data.sort(function (a, b) {
        if (is_calandra)
          return a.idTipoPrendaNavigation.denominacion.localeCompare(b.idTipoPrendaNavigation.denominacion);
        else return a.idFamiliaNavigation.codigo.localeCompare(b.idFamiliaNavigation.codigo);
      });
    },
  });

  var datasource_tblFamilia = new DataSource({
    store: new ODataStore({
      url: connectionConstants.WEB_API_CORE_ODATA_URL + "tblFamilia",
      errorHandler: function (error) {
        errorHandler(error, COMPONENT);
      },
      beforeSend: function (request) {
        request.headers = { ...authHeader() };
      },
      version: 4,
    }),
    expand: ["tblTipoPrenda($select=idTipoPrenda, denominacion)"],
    select: ["idFamilia", "denominacion", "abreviatura", "codigo"],
    sort: "codigo",
    postProcess: function (data) {
      let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
      let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");
      let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

      data.forEach((item) => {
        if (
          (is_calandra && item.idFamilia !== 1 && item.idFamilia !== 3) ||
          (is_plegadora && item.idFamilia !== 4 && item.idFamilia !== 5)
        )
          item.disabled = true;
      });

      return data;
    },
  });

  var enum_viasCalandras = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
  ];

  var enum_viasPlegadoras = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
  ];

  //#endregion
  $.when(
    datasource_tipoMaquinaria.load(),
    datasource_sistemaMaquina.load(),
    datasource_categoriaMaquina.load(),
    datasource_compañias.load(),
    datasource_entidades.load(),
    datasource_tipoMaquinaNCategoriaMaquina.load()
  )
    .then(function () {
      //#region Controles
      $("#Maquinaria #dxContainer").dxBox({
        height: "100%",
        direction: "col",
      });

      //Grid Maquinária
      $("#Maquinaria #dgvMaquinaria").dxDataGrid({
        //Datos
        dataSource: datasource_maquinaria,
        remoteOperations: true,
        //Propiedades
        columnsAutoWidth: true,
        width: "auto",
        height: "100%",
        headerFilter: {
          visible: true,
        },
        filterRow: {
          visible: true,
          showAllText: getTrad("todos").toUpperCase(),
          applyFilter: "auto",
        },
        searchPanel: {
          visible: true,
          width: 240,
          placeholder: getTrad("busqueda"),
        },
        paging: {
          pageSize: 50,
        },
        sorting: {
          mode: "multiple",
        },
        grouping: {
          autoExpandAll: true,
          allowCollapsing: true,
        },
        groupPanel: {
          visible: false,
        },
        pager: {
          showPageSizeSelector: true,
          allowedPageSizes: [20, 50, 100],
          showInfo: true,
        },
        columns: [
          {
            dataField: "tblTipoMaquinaNCategoriaMaquina.tblCategoriaMaquina.idSistemaMaquina",
            width: 0,
            groupCellTemplate: function (cellElement, cellInfo) {
              switch (cellInfo.data.key) {
                case null:
                  cellElement.text("Sin sistema");
                  break;
                case 1:
                  cellElement.text("Valet");
                  break;
                case 2:
                  cellElement.text("Lavado");
                  break;
                case 3:
                  cellElement.text("Procesado");
                  break;
                case 4:
                  cellElement.text("Auxiliar");
                  break;
                case 5:
                  cellElement.text("Instalaciones");
                  break;
                default:
                  break;
              }
            },
            visible: false,
            groupIndex: 0,
            allowSearch: false,
          },
          {
            dataField: "numIncidenciasAbiertas",
            visible: false,
            allowSearch: false,
          },
          {
            caption: " ",
            width: "30px",
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (cellElement, cellInfo) {
              var contenedor = $("<div/>").addClass("container_spanCentrado");
              svg_edit.clone().css("height", "25px").css("width", "25px").css("cursor", "pointer").appendTo(contenedor);

              cellElement.on("click", function () {
                maquinaSel = cellInfo.data;
                maquinaSel_bak = $.extend(true, {}, maquinaSel);
                //#region ASIGNAR VALOR CORRECTO CAMPO añoFabricación (DE SQL VIENE UN INT DE AÑO NO UN DATETIME)
                if (maquinaSel.añoFabricacion) {
                  var newDate = new Date();
                  var date = new Date(
                    maquinaSel.añoFabricacion +
                      "-" +
                      leadingZero(newDate.getMonth() + 1) +
                      "-" +
                      leadingZero(newDate.getDate())
                  );
                  maquinaSel.añoFabricacion = date;
                }
                //#endregion
                var dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
                if (typeof dxForm_InfoMaquina !== "undefined") {
                  dxForm_InfoMaquina.updateData($.extend(true, {}, maquinaSel));
                }

                $("#popup_infoMaquina").dxPopup("instance").show();
              });

              contenedor.appendTo(cellElement);
            },
            allowSearch: false,
          },
          {
            caption: getTrad("tareas"),
            width: 40,
            alignment: "left",
            cssClass: "p-0",
            cellTemplate: function (container, options) {
              $("<div />")
                .addClass("icon_Herramientas")
                .css("cursor", "pointer")
                .on("click", function () {
                  maquinaSel = options.data;
                  $("#popup_generarTareas").dxPopup("instance").show();
                  $("#dgvGenerarTareas").dxDataGrid("instance").refresh();
                })
                .appendTo(container);
            },
            allowSearch: false,
          },
          {
            dataField: "denominacion",
            caption: getTrad("denominacion"),
            minWidth: 350,
            sortIndex: 1,
            sortOrder: "asc",
            allowHeaderFiltering: false,
            validationRules: [
              {
                type: "required",
              },
            ],
          },
          {
            dataField: "etiqueta",
            caption: getTrad("etiqueta"),
            minWidth: 150,
            validationRules: [
              {
                type: "required",
              },
            ],
          },
          {
            dataField: "numSerie",
            caption: getTrad("numSerie"),
            minWidth: 150,
            allowHeaderFiltering: false,
          },
          {
            dataField: "tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina",
            caption: getTrad("categoriaMaquina"),
            minWidth: 150,
            lookup: {
              dataSource: datasource_categoriaMaquina.items(),
              valueExpr: "idCategoriaMaquina",
              displayExpr: "denominacion",
            },
            validationRules: [
              {
                type: "required",
                message: getTrad("seleccioneCategoria"),
              },
            ],
            allowHeaderFiltering: false,
          },
          {
            dataField: "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina",
            caption: getTrad("tipoMaquina"),
            minWidth: 150,
            lookup: {
              dataSource: datasource_tipoMaquinaria.items(),
              valueExpr: "idTipoMaquinaNCategoriaMaquina",
              displayExpr: "denominacion",
            },
            validationRules: [
              {
                type: "required",
                message: getTrad("seleccioneTipoMaquina"),
              },
            ],
            allowHeaderFiltering: false,
          },
          {
            dataField: "activo",
            caption: getTrad("activo"),
            width: 125,
            sortOrder: "desc",
            sortIndex: 0,
            cssClass: "p-0",
            editCellTemplate: function (container, options) {
              $("<div/>")
                .dxSwitch({
                  switchedOnText: getTrad("activo"),
                  switchedOffText: getTrad("noActivo"),
                  value: typeof options.data.activo !== "undefined" ? options.data.activo : true,
                  disabled: options.data.numIncidenciasAbiertas > 0,
                  onValueChanged: function (e) {
                    //Si la máquina tiene peso, no permitir eliminar ni desactivar
                    if (typeof options.data.peso !== "undefined" && options.data.peso > 0) {
                      e.component.option(
                        "value",
                        typeof options.data.activo !== "undefined" ? options.data.activo : true
                      );
                      notify({
                        message: getTrad("alerta_MaquinaTienePeso"),
                        type: "error",
                        displayTime: "1500",
                        closeOnClick: true,
                      });
                    } else {
                      if (options.component.getController("editing").isEditing()) {
                        options.setValue(e.value);
                      } else {
                        //Update
                        var maquina = {};
                        maquina.activo = e.value;
                        datasource_maquinaria
                          .store()
                          .update(options.data.idMaquina, maquina)
                          .done(function () {
                            notify({
                              message: getTrad("aviso_C_RegistroActualizado"),
                              type: "success",
                              displayTime: "1500",
                              closeOnClick: true,
                            });
                            options.component.refresh();
                          });
                      }
                    }
                  },
                })
                .appendTo(container);

              if (options.data.numIncidenciasAbiertas > 0) {
                tooltipControl_creacion(container, getTrad("alerta_DesactivarMaquinaInciAbierta"));
              }
            },
            headerFilter: {
              dataSource: filtroActivo,
            },
          },
          {
            dataField: "capacidad",
            caption: getTrad("capacidad"),
            allowSearch: false,
            visible: false,
          },
          {
            dataField: "peso",
            caption: getTrad("pesoGrupo"),
            allowSearch: false,
            visible: false,
            editorOptions: {
              format: "#0.## '%'",
            },
          },
          {
            dataField: "añoFabricacion",
            caption: getTrad("añoFabricacion"),
            dataType: "date",
            format: "yyyy",
            allowSearch: false,
            visible: false,
            editorOptions: {
              maxZoomLevel: "decade",
              minZoomLevel: "decade",
              max: new Date(),
            },
          },
          {
            caption: " ",
            width: "60px",
            alignment: "center",
            cssClass: "p-0",
            cellTemplate: function (cellElement, cellInfo) {
              var contenedor = $("<div/>").addClass("container_spanCentrado");

              svg_remove
                .clone()

                .css("height", "25px")
                .css("width", "25px")
                .css("cursor", "pointer")
                .appendTo(contenedor);

              cellElement.on("click", function () {
                //Si la máquina tiene incidencias abiertas  no permitir eliminar ni desactivar
                if (cellInfo.data.numIncidenciasAbiertas > 0) {
                  notify({
                    message: getTrad("alerta_DesactivarMaquinaInciAbierta"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                }
                //Si la máquina tiene peso, no permitir eliminar ni desactivar
                else if (typeof cellInfo.data.peso !== "undefined" && cellInfo.data.peso > 0) {
                  notify({
                    message: getTrad("alerta_MaquinaTienePeso"),
                    type: "error",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                } else {
                  cellInfo.component.deleteRow(cellInfo.rowIndex);
                }
              });

              contenedor.appendTo(cellElement);
            },
          },
        ],
        //Eventos
        onToolbarPreparing: function (e) {
          e.toolbarOptions.items.unshift(
            {
              location: "before",
              widget: "dxButton",
              locateInMenu: "auto",
              options: {
                text: getTrad("añadir"),
                icon: "plus",
                onClick: function () {
                  $("#popup_addMaquina").dxPopup("instance").show();
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              locateInMenu: "auto",
              options: {
                text: getTrad("limpiarFiltro"),
                icon: " icon_EliminarFiltros",
                onClick: function () {
                  e.component.clearFilter();
                  notify({
                    message: getTrad("aviso_C_FiltroRestaurado"),
                    type: "success",
                    displayTime: "1500",
                    closeOnClick: true,
                  });
                },
              },
            },
            {
              location: "after",
              widget: "dxButton",
              showText: "inMenu",
              options: {
                icon: "refresh",
                text: getTrad("actualizar"),
                onClick: function () {
                  e.component.refresh();
                },
              },
            }
          );
          $.each(e.toolbarOptions.items, function (index, item) {
            switch (item.name) {
              case "addRowButton":
                item.location = "before";
                item.showText = "always";
                item.options.text = getTrad("añadir");
                item.options.icon = "plus";
                item.options.onClick = function () {
                  e.component.addRow();
                };
                break;
              default:
                break;
            }
          });
        },
        onRowInserting: function (e) {
          //Como el Switch se instancia como TRUE(Activo) el valor por defecto sin modificaciones ha de ser TRUE.
          if (typeof e.data.activo === "undefined") {
            e.data.activo = true;
          }
        },
        onEditorPreparing: function (e) {
          var field = e.dataField;
          var options = e.editorOptions;
          if (field === "peso") {
            options.disabled = true;
            options.readOnly = true;
          }
        },
        //Estilos
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: true,
      });

      //Popup generar tareas
      $("#Maquinaria #popup_generarTareas").dxPopup({
        title: getTrad("tareas"),
        showTitle: true,
        contentTemplate: function () {
          return $("<div id='dgvGenerarTareas'/>").dxDataGrid({
            //Datos
            dataSource: datasource_tareaMaquina,
            remoteOperations: true,
            //Propiedades
            height: "100%",
            columnsAutoWidth: true,
            headerFilter: {
              visible: true,
            },
            filterRow: {
              visible: true,
              showAllText: getTrad("todos").toUpperCase(),
              applyFilter: "auto",
            },
            searchPanel: {
              visible: true,
              width: 240,
              placeholder: getTrad("busqueda"),
            },
            paging: {
              pageSize: 50,
            },
            sorting: {
              mode: "multiple",
            },
            pager: {
              showPageSizeSelector: true,
              allowedPageSizes: [20, 50, 100],
              showInfo: true,
            },
            editing: {
              mode: "popup",
              allowUpdating: false,
              allowAdding: true,
              popup: {
                title: getTrad("informacionTarea"),
                showTitle: true,
                position: { at: "center", of: window },
                height: "auto",
                width: "50%",
              },
              form: {
                labelLocation: "top",
                height: "100%",
                scrollingEnabled: true,
                colCount: 1,
                colCountByScreen: {
                  xl: 3,
                  lg: 3,
                  md: 3,
                  sm: 2,
                  xs: 1,
                },
                screenByWidth: function (width) {
                  return width >= 1200 ? "xl" : width >= 992 ? "lg" : width >= 768 ? "md" : width >= 576 ? "sm" : "xs";
                },
                items: [
                  {
                    dataField: "denominacion",
                    colSpan: 3,
                  },
                  {
                    dataField: "idFrecuenciaMantenimiento",
                  },
                  {
                    dataField: "idDiaSemana",
                  },
                  {
                    dataField: "semana",
                  },
                  {
                    dataField: "idMes",
                  },
                  {
                    dataField: "tiempo",
                  },
                  {
                    dataField: "idResponsableTarea",
                  },
                  {
                    dataField: "parada",
                  },
                ],
              },
            },
            //Columnas
            columns: [
              {
                caption: " ",
                width: 60,
                alignment: "center",
                cssClass: "p-0",
                cellTemplate: function (cellElement, cellInfo) {
                  var contenedor = $("<div/>").addClass("container_spanCentrado");
                  svg_edit
                    .clone()
                    .css("height", "25px")
                    .css("width", "25px")
                    .css("cursor", "pointer")
                    .appendTo(contenedor);

                  cellElement.on("click", function () {
                    cellInfo.component.editRow(cellInfo.rowIndex);
                  });

                  contenedor.appendTo(cellElement);
                },
                allowSearch: false,
              },
              {
                dataField: "denominacion",
                caption: getTrad("denominacion"),
                minWidth: 450,
                visible: true,
                validationRules: [
                  {
                    type: "required",
                  },
                ],
              },
              {
                dataField: "idFrecuenciaMantenimiento",
                caption: getTrad("frecuenciaMantenimiento"),
                visible: true,
                setCellValue: function (rowData, value) {
                  rowData.idFrecuenciaMantenimiento = value;
                },
                lookup: {
                  dataSource: datasource_frecuenciaMantenimiento.store(),
                  valueExpr: "idFrecuenciaMantenimiento",
                  displayExpr: "denominacion",
                },
                validationRules: [
                  {
                    type: "required",
                  },
                ],
              },
              {
                dataField: "idDiaSemana",
                caption: getTrad("dia"),
                visible: true,
                lookup: {
                  dataSource: datasource_dia.store(),
                  valueExpr: "idDiaSemana",
                  displayExpr: "denominacion",
                },
                validationRules: [
                  {
                    type: "custom",
                    validationCallback: function (e) {
                      return validation_frequenciaMantenimiento(e, "idDiaSemana");
                    },
                    message: getTrad("campoRequerido"),
                  },
                ],
              },
              {
                dataField: "semana",
                caption: getTrad("semana"),
                visible: true,
                lookup: {
                  dataSource: enum_NumOrdinales,
                  valueExpr: "semana",
                  displayExpr: "denominacion",
                },
                validationRules: [
                  {
                    type: "custom",
                    validationCallback: function (e) {
                      return validation_frequenciaMantenimiento(e, "semana");
                    },
                    message: getTrad("campoRequerido"),
                  },
                ],
              },
              {
                dataField: "idMes",
                caption: getTrad("mes"),
                visible: true,
                lookup: {
                  dataSource: datasource_mes.store(),
                  valueExpr: "idMes",
                  displayExpr: "denominacion",
                },
                validationRules: [
                  {
                    type: "custom",
                    validationCallback: function (e) {
                      return validation_frequenciaMantenimiento(e, "idMes");
                    },
                  },
                ],
              },
              {
                dataField: "tiempo",
                caption: getTrad("tiempo"),
                visible: true,
                allowFiltering: false,
                allowHeaderFiltering: false,
                editorOptions: {
                  mask: "00:00",
                },
                validationRules: [
                  {
                    type: "required",
                  },
                  {
                    type: "pattern",
                    message: getTrad("tiempoInvalido"),
                    pattern: /^([01]?[0-9]|2[0-3]):?[0-5][0-9]$/,
                  },
                ],
              },
              {
                dataField: "idResponsableTarea",
                caption: getTrad("responsable"),
                visible: true,
                lookup: {
                  dataSource: datasource_responsableTarea.store(),
                  valueExpr: "idResponsableTarea",
                  displayExpr: "denominacion",
                },
                validationRules: [
                  {
                    type: "required",
                  },
                ],
              },
              {
                dataField: "parada",
                caption: getTrad("parada"),
                allowHeaderFiltering: false,
                alignment: "center",
                cssClass: "p-0",
                editCellTemplate: function (container, options) {
                  $("<div/>")
                    .dxSwitch({
                      switchedOnText: getTrad("noParada"),
                      switchedOffText: getTrad("parada"),
                      value: typeof options.data.parada !== "undefined" ? options.data.parada : true,
                      elementAttr: {
                        class: "dxSwitch_toolbar",
                      },
                      onValueChanged: function (e) {
                        options.setValue(e.value);
                      },
                    })
                    .appendTo(container);
                },
                cellTemplate: function (container, options) {
                  $("<div/>")
                    .attr("class", options.data.parada ? "icon_ManoStop" : "")
                    .attr("height", "20px")
                    .attr("width", "20px")
                    .appendTo(container);
                },
                headerFilter: {
                  dataSource: [
                    {
                      text: getTrad("parada"),
                      value: true,
                    },
                    {
                      text: getTrad("noParada"),
                      value: false,
                    },
                  ],
                },
              },
              {
                caption: " ",
                width: "30px",
                alignment: "center",
                cssClass: "p-0",
                cellTemplate: function (cellElement, cellInfo) {
                  var contenedor = $("<div/>").addClass("container_spanCentrado");
                  svg_remove
                    .clone()

                    .css("height", "25px")
                    .css("width", "25px")
                    .css("cursor", "pointer")
                    .appendTo(contenedor);

                  cellElement.on("click", function () {
                    cellInfo.component.deleteRow(cellInfo.rowIndex);
                  });

                  contenedor.appendTo(cellElement);
                },
              },
            ],
            //Eventos
            onEditorPreparing: function (e) {
              if (typeof e.row !== "undefined") {
                var idFrequencia = e.row.data.idFrecuenciaMantenimiento;
                var field = e.dataField;
                var options = e.editorOptions;

                if ($.inArray(field, ["idDiaSemana", "semana", "idMes"]) > -1) {
                  options.readOnly = false;
                  if (
                    !(idFrequencia >= 2 && field === "idDiaSemana") &&
                    !(idFrequencia >= 3 && field === "semana") &&
                    !(idFrequencia >= 5 && field === "idMes")
                  ) {
                    options.readOnly = true;
                    options.value = null;
                  }
                }
              }
            },
            onToolbarPreparing: function (e) {
              e.toolbarOptions.items.unshift(
                {
                  location: "after",
                  widget: "dxButton",
                  showText: "inMenu",
                  locateInMenu: "auto",
                  options: {
                    text: getTrad("limpiarFiltro"),
                    icon: " icon_EliminarFiltros",
                    onClick: function () {
                      e.component.clearFilter();
                      notify({
                        message: getTrad("aviso_C_FiltroRestaurado"),
                        type: "success",
                        displayTime: "1500",
                        closeOnClick: true,
                      });
                    },
                  },
                },
                {
                  location: "after",
                  widget: "dxButton",
                  showText: "inMenu",
                  options: {
                    icon: "refresh",
                    text: getTrad("actualizar"),
                    onClick: function () {
                      e.component.refresh();
                    },
                  },
                }
              );
              $.each(e.toolbarOptions.items, function (index, item) {
                switch (item.name) {
                  case "addRowButton":
                    item.location = "before";
                    item.showText = "always";
                    item.options.text = getTrad("añadirTarea");
                    item.options.icon = "plus";
                    item.options.onClick = function () {
                      e.component.addRow();
                    };
                    break;
                  default:
                    break;
                }
              });
            },
            onRowInserting: function (e) {
              if (typeof e.data.parada === "undefined") {
                e.data.parada = true;
              }

              var strTiempo = e.data.tiempo.replace(":", "");
              e.data.tiempo = strTiempo.substring(0, 2) + ":" + strTiempo.substring(2, 4);
            },
            onRowUpdating: function (e) {
              for (var propertyName in e.oldData) {
                if (typeof e.newData[propertyName] === "undefined") {
                  e.newData[propertyName] = e.oldData[propertyName];
                }
              }
              var strTiempo = e.newData.tiempo.replace(":", "");
              e.newData.tiempo = strTiempo.substring(0, 2) + ":" + strTiempo.substring(2, 4);

              if (e.newData.idFrecuenciaMantenimiento <= 1) {
                e.newData.idDiaSemana = null;
                e.newData.semana = null;
                e.newData.idMes = null;
              }
              if (e.newData.idFrecuenciaMantenimiento <= 2) {
                e.newData.semana = null;
                e.newData.idMes = null;
              }
              if (e.newData.idFrecuenciaMantenimiento <= 4) {
                e.newData.idMes = null;
              }
            },
            //Estilos
            showColumnLines: false,
            showRowLines: true,
            rowAlternationEnabled: true,
          });
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "before",
            widget: "dxButton",
            visible: false,
            options: {
              text: getTrad("importarTareas"),
              icon: "upload",
              onClick: function (e) {
                $("#popup_importarTareas").dxPopup("instance").show();
              },
            },
          },
        ],
      });

      // popup importar tareas
      $("#Maquinaria #popup_importarTareas").dxPopup({
        title: getTrad("importarTareas"),
        showTitle: true,
        width: "800px",
        height: "500px",

        contentTemplate: function (e) {
          return $("<div>").dxBox({
            direction: "row",
            align: "space-around",
            crossAlign: "stretch",
            items: [
              {
                ratio: 1,
                template: function () {
                  var container = $("<div>");
                  var title = $("<div class='font-size-lg' style='margin-left:9px;' />");
                  var lista_compañias = $("<div id='popup_selCompaEnti_lista_compañias'>").dxList({
                    dataSource: enum_NumOrdinales,
                    selectionMode: "single",
                    height: "375px",
                    width: "95%",
                    keyExpr: "semana",
                    scrolling: { mode: "infinite" },
                    pageLoadMode: "scrollBottom",
                    // Eventos
                    onContentReady: function (e) {
                      e.component.selectItem(0);
                    },

                    itemTemplate: function (data, index) {
                      var result = $("<div>");
                      $("<div>").text(data.denominacion).appendTo(result);

                      return result;
                    },
                  });

                  title.appendTo(container);
                  lista_compañias.appendTo(container);

                  return container;
                },
              },
              {
                ratio: 1,
                template: function () {
                  var container = $("<div>");
                  var title = $("<div class='font-size-md' style='margin-left:9px;'>" + getTrad("entidad") + "</div>");
                  var lista_entidades = $("<div id='popup_selCompaEnti_lista_entidades'>").dxList({
                    //Datos
                    dataSource: enum_NumOrdinales,
                    selectionMode: "single",
                    keyExpr: "semana",
                    height: "375px",
                    scrolling: { mode: "infinite" },
                    pageLoadMode: "scrollBottom",
                    //Eventos
                    itemTemplate: function (data, index) {
                      var result = $("<div>");
                      $("<div>").text(data.denominacion).appendTo(result);

                      return result;
                    },
                  });
                  title.appendTo(container);
                  lista_entidades.appendTo(container);

                  return container;
                },
              },
            ],
          });
        },
      });

      //#endregion

      $("#Maquinaria #popup_addMaquina").dxPopup({
        height: 650,
        width: "75%",
        title: getTrad("selección_tipoPrenda"),
        contentTemplate: function (contentElement) {
          return $("<div />").dxBox({
            direction: "row",
            align: "space-around",
            crossAlign: "stretch",
            height: "100%",
            width: "100%",
            items: [
              {
                ratio: 1,
                template: function () {
                  return $("<div/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    width: "100%",
                    items: [
                      {
                        baseSize: 40,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          return $("<div class='font-size'>" + getTrad("sistema") + "</div>");
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id='dxList_sistema' />").dxList({
                            dataSource: datasource_sistemaMaquina.items(),
                            focusStateEnabled: false,
                            pageLoadMode: "scrollBottom",
                            selectionMode: "single",
                            height: "100%",
                            keyExpr: "idSistemaMaquina",
                            displayExpr: "denominacion",
                            onSelectionChanged: function (e) {
                              let idSistemaMaquina = e.addedItems.length > 0 ? e.addedItems[0].idSistemaMaquina : -1;
                              let dataSource_filtrado = $.grep(datasource_categoriaMaquina.items(), function (item) {
                                return item.idSistemaMaquina === idSistemaMaquina;
                              });
                              $("#dxList_categoriaMaquina")
                                .dxList("instance")
                                .option("dataSource", dataSource_filtrado);
                              $("#dxList_tipoMaquina").dxList("instance").option("dataSource", []);
                            },
                          });
                        },
                      },
                    ],
                  });
                },
              },
              {
                ratio: 1,
                template: function () {
                  return $("<div style='padding-left:20px;'/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    width: "100%",
                    items: [
                      {
                        baseSize: 40,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          return $("<div class='font-size'>" + getTrad("categoriaMaquina") + "</div>");
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id='dxList_categoriaMaquina' />").dxList({
                            focusStateEnabled: false,
                            pageLoadMode: "scrollBottom",
                            selectionMode: "single",
                            height: "100%",
                            keyExpr: "idCategoriaMaquina",
                            searchEnabled: true,
                            displayExpr: "denominacion",
                            searchExpr: "denominacion",
                            onSelectionChanged: function (e) {
                              let tblTipoMaquinaNCategoriaMaquina =
                                e.addedItems.length > 0 ? e.addedItems[0].tblTipoMaquinaNCategoriaMaquina : [];
                              let dataSource = [];
                              $.each(tblTipoMaquinaNCategoriaMaquina, function (index, item) {
                                item.tblTipoMaquina.idTipoMaquinaNCategoriaMaquina =
                                  item.idTipoMaquinaNCategoriaMaquina;
                                dataSource.push(item.tblTipoMaquina);
                              });

                              $("#dxList_tipoMaquina").dxList("instance").option("dataSource", dataSource);

                              if (dataSource.length === 1) {
                                setTimeout(() => {
                                  $("#dxList_tipoMaquina").dxList("instance").selectItem(0);
                                }, 50);
                              }
                            },
                          });
                        },
                      },
                    ],
                  });
                },
              },
              {
                ratio: 1,
                template: function () {
                  return $("<div style='padding-left:20px;'/>").dxBox({
                    direction: "col",
                    align: "space-around",
                    crossAlign: "stretch",
                    height: "100%",
                    width: "100%",
                    items: [
                      {
                        baseSize: 40,
                        shrink: 0,
                        ratio: 0,
                        template: function () {
                          return $("<div class='font-size'>" + getTrad("tipoMaquina") + "</div>");
                        },
                      },
                      {
                        ratio: 1,
                        template: function () {
                          return $("<div id='dxList_tipoMaquina' />").dxList({
                            focusStateEnabled: false,
                            pageLoadMode: "scrollBottom",
                            selectionMode: "single",
                            height: "100%",
                            keyExpr: "idTipoMaquinaNCategoriaMaquina",
                            displayExpr: "denominacion",
                            searchEnabled: true,
                            searchExpr: "denominacion",
                            onSelectionChanged: function (e) {
                              if (e.addedItems.length > 0) {
                                var sistemaSel = $("#dxList_sistema").dxList("instance").option("selectedItems")[0];
                                var categoriaMaqSel = $("#dxList_categoriaMaquina")
                                  .dxList("instance")
                                  .option("selectedItems")[0];
                                var tipoMaqSel = e.addedItems[0];

                                var dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
                                maquinaSel = {
                                  idTipoMaquinaNCategoriaMaquina: tipoMaqSel.idTipoMaquinaNCategoriaMaquina,
                                  tblTipoMaquinaNCategoriaMaquina: {
                                    tblCategoriaMaquina: {
                                      idSistemaMaquina: sistemaSel.idSistemaMaquina,
                                    },
                                    idCategoriaMaquina: categoriaMaqSel.idCategoriaMaquina,
                                    idTipoMaquina: tipoMaqSel.idTipoMaquina,
                                    idTipoMaquinaNCategoriaMaquina: tipoMaqSel.idTipoMaquinaNCategoriaMaquina,
                                  },
                                  activo: true,
                                  capacidad: 0,
                                };

                                if (dxForm_InfoMaquina != null) {
                                  dxForm_InfoMaquina.resetValues();
                                  dxForm_InfoMaquina.option("formData", maquinaSel);
                                  dxForm_InfoMaquina.repaint();
                                }

                                $("#popup_infoMaquina").dxPopup("instance").show();
                                $("#popup_addMaquina").dxPopup("instance").hide();
                              }
                            },
                          });
                        },
                      },
                    ],
                  });
                },
              },
            ],
          });
        },
        onShowing: function () {
          $("#dxList_sistema").dxList("instance").option("selectedItemKeys", []);
          $("#dxList_categoriaMaquina").dxList("instance").option("dataSource", []);
          $("#dxList_tipoMaquina").dxList("instance").option("dataSource", []);
        },
      });

      $("#Maquinaria #popup_infoMaquina").dxPopup({
        showTitle: false,
        position: { at: "center", of: window },
        height: 650,
        width: "75%",
        contentTemplate: function () {
          return $("<div id='dxTabPanel_infoMaquina' />")
            .addClass("h4")
            .css("padding", "20px")
            .dxTabPanel({
              height: "100%",
              width: "100%",
              scrollByContent: true,
              scrollingEnabled: true,
              animationEnabled: true,
              items: [
                {
                  title: getTrad("informacionMaquinaria"),
                  template: function () {
                    return $("<div>").dxBox({
                      height: "100%",
                      direction: "col",
                      align: "space-around",
                      crossAlign: "stretch",
                      items: [
                        {
                          ratio: 1,
                          template: function () {
                            return $("<div id='dxForm_InfoMaquina' style='padding:20px;padding-top:15px;'>").dxForm({
                              formData: $.extend(true, {}, maquinaSel), // PARA QUE AL MODIFICAR UN CAMPO NO PISE LOS DATOS ORIGINALES
                              labelLocation: "top",
                              height: "100%",
                              colCount: 4,
                              items: [
                                {
                                  itemType: "group",
                                  caption: "Grupo 1",
                                  name: "grupo1",
                                  colSpan: 4,
                                  colCountByScreen: {
                                    xl: 4,
                                    lg: 4,
                                    md: 4,
                                    sm: 4,
                                    xs: 1,
                                  },
                                  screenByWidth: function (width) {
                                    return width >= 1200
                                      ? "xl"
                                      : width >= 992
                                      ? "lg"
                                      : width >= 768
                                      ? "md"
                                      : width >= 576
                                      ? "sm"
                                      : "xs";
                                  },
                                  items: [
                                    {
                                      dataField: "tblTipoMaquinaNCategoriaMaquina.tblCategoriaMaquina.idSistemaMaquina",
                                      editorType: "dxSelectBox",
                                      colSpan: 1,
                                      editorOptions: {
                                        elementAttr: {
                                          id: "dxSelectBox_sistemaMaquina",
                                        },
                                        dataSource: datasource_sistemaMaquina.items(),
                                        valueExpr: "idSistemaMaquina",
                                        displayExpr: "denominacion",
                                        is_valueChanged: false,
                                        onValueChanged: function (e) {
                                          e.component.option("is_valueChanged", true);
                                        },
                                        onSelectionChanged: function (e) {
                                          setTimeout(() => {
                                            let dxSelectBox_categoriaMaquina = $(
                                              "#dxSelectBox_categoriaMaquina"
                                            ).dxSelectBox("instance");
                                            let idSistemaMaquina =
                                              e.selectedItem != null ? e.selectedItem.idSistemaMaquina : null;
                                            if (idSistemaMaquina != null) {
                                              let dataSource_filtrado = $.grep(
                                                datasource_categoriaMaquina.items(),
                                                function (item) {
                                                  return item.idSistemaMaquina === idSistemaMaquina;
                                                }
                                              );
                                              dxSelectBox_categoriaMaquina.option("dataSource", dataSource_filtrado);
                                            } else {
                                              dxSelectBox_categoriaMaquina.option("dataSource", []);
                                            }
                                            $("#dxSelectBox_tipoMaquina")
                                              .dxSelectBox("instance")
                                              .option("dataSource", []);
                                          }, 0);
                                        },
                                        onItemClick: function (e) {
                                          setTimeout(() => {
                                            if (e.component.option("is_valueChanged")) {
                                              //todo
                                              let dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
                                              dxForm_InfoMaquina
                                                .getEditor(
                                                  "tblTipoMaquinaNCategoriaMaquina.tblCategoriaMaquina.idSistemaMaquina"
                                                )
                                                .option("isValid", true);

                                              dxForm_InfoMaquina.updateData(
                                                "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina",
                                                null
                                              );
                                              dxForm_InfoMaquina
                                                .getEditor(
                                                  "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina"
                                                )
                                                .option("isValid", true);
                                            }
                                          }, 0);
                                        },
                                      },
                                      validationRules: [
                                        {
                                          type: "required",
                                        },
                                      ],
                                      label: {
                                        text: getTrad("sistema"),
                                      },
                                    },
                                    {
                                      dataField: "tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina",
                                      editorType: "dxSelectBox",
                                      editorOptions: {
                                        elementAttr: {
                                          id: "dxSelectBox_categoriaMaquina",
                                        },
                                        valueExpr: "idCategoriaMaquina",
                                        displayExpr: "denominacion",
                                        is_valueChanged: false,
                                        onValueChanged: function (e) {
                                          e.component.option("is_valueChanged", true);
                                        },
                                        onSelectionChanged: function (e) {
                                          setTimeout(() => {
                                            let dxSelectBox_tipoMaquina =
                                              $("#dxSelectBox_tipoMaquina").dxSelectBox("instance");
                                            let idCategoriaMaquina =
                                              e.selectedItem != null ? e.selectedItem.idCategoriaMaquina : null;
                                            if (idCategoriaMaquina != null) {
                                              let tblTipoMaquina = [];

                                              $.each(
                                                e.selectedItem.tblTipoMaquinaNCategoriaMaquina,
                                                function (index, item) {
                                                  item.tblTipoMaquina.idTipoMaquinaNCategoriaMaquina =
                                                    item.idTipoMaquinaNCategoriaMaquina;
                                                  tblTipoMaquina.push(item.tblTipoMaquina);
                                                }
                                              );

                                              dxSelectBox_tipoMaquina.option({
                                                dataSource: tblTipoMaquina,
                                              });
                                              if (tblTipoMaquina.length === 1) {
                                                setTimeout(() => {
                                                  dxSelectBox_tipoMaquina.option(
                                                    "value",
                                                    tblTipoMaquina[0].idTipoMaquinaNCategoriaMaquina
                                                  );
                                                }, 0);
                                              }
                                            } else {
                                              dxSelectBox_tipoMaquina.option("dataSource", []);
                                            }
                                          }, 0);
                                        },
                                        onItemClick: function (e) {
                                          let dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
                                          setTimeout(() => {
                                            if (e.component.option("is_valueChanged")) {
                                              dxForm_InfoMaquina.updateData(
                                                "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina",
                                                null
                                              );
                                              dxForm_InfoMaquina
                                                .getEditor(
                                                  "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina"
                                                )
                                                .option("isValid", true);
                                            }
                                            e.component.option("is_valueChanged", false);
                                          }, 0);
                                        },
                                      },
                                      validationRules: [
                                        {
                                          type: "required",
                                        },
                                      ],
                                      label: {
                                        text: getTrad("categoriaMaquina"),
                                      },
                                    },
                                    {
                                      dataField: "tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina",
                                      editorType: "dxSelectBox",
                                      colSpan: 1,
                                      editorOptions: {
                                        elementAttr: {
                                          id: "dxSelectBox_tipoMaquina",
                                        },
                                        valueExpr: "idTipoMaquinaNCategoriaMaquina",
                                        displayExpr: "denominacion",
                                        onValueChanged: function (e) {
                                          let dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
                                          let itemSel = $.grep(
                                            datasource_tipoMaquinaNCategoriaMaquina.items(),
                                            function (item) {
                                              return item.idTipoMaquinaNCategoriaMaquina === e.value;
                                            }
                                          );
                                          let idTipoMaquina = itemSel.length > 0 ? itemSel[0].idTipoMaquina : null;

                                          maquinaSel_bak = $.extend(true, {}, dxForm_InfoMaquina.option("formData"));
                                          maquinaSel_bak.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina = idTipoMaquina;
                                          maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina = idTipoMaquina;
                                        },
                                      },
                                      validationRules: [
                                        {
                                          type: "required",
                                        },
                                      ],
                                      label: {
                                        text: getTrad("tipoMaquina"),
                                      },
                                    },
                                    {
                                      dataField: "activo",
                                      editorType: "dxSwitch",
                                      colSpan: 1,
                                      editorOptions: {
                                        height: "100%",
                                        switchedOnText: getTrad("activo"),
                                        switchedOffText: getTrad("noActivo"),
                                        elementAttr: {
                                          class: "dxSwitch_toolbar",
                                        },
                                      },
                                      label: {
                                        text: getTrad("activo"),
                                      },
                                    },
                                  ],
                                },
                                {
                                  itemType: "empty",
                                  colSpan: 4,
                                },
                                {
                                  itemType: "group",
                                  caption: "Grupo 2",
                                  name: "grupo2",
                                  colSpan: 4,
                                  colCountByScreen: {
                                    xl: 4,
                                    lg: 4,
                                    md: 4,
                                    sm: 4,
                                    xs: 1,
                                  },
                                  screenByWidth: function (width) {
                                    return width >= 1200
                                      ? "xl"
                                      : width >= 992
                                      ? "lg"
                                      : width >= 768
                                      ? "md"
                                      : width >= 576
                                      ? "sm"
                                      : "xs";
                                  },
                                  items: [
                                    {
                                      dataField: "denominacion",
                                      colSpan: 1,
                                      validationRules: [
                                        {
                                          type: "required",
                                        },
                                      ],
                                      label: {
                                        text: getTrad("denominacion"),
                                      },
                                    },
                                    {
                                      dataField: "etiqueta",
                                      colSpan: 1,
                                      validationRules: [
                                        {
                                          type: "required",
                                        },
                                      ],
                                      label: {
                                        text: getTrad("etiqueta"),
                                      },
                                    },
                                    {
                                      dataField: "numSerie",
                                      colSpan: 1,
                                      label: {
                                        text: getTrad("numSerie"),
                                      },
                                    },
                                    {
                                      dataField: "capacidad",
                                      colSpan: 1,
                                      editorType: "dxNumberBox",
                                      label: {
                                        text: getTrad("capacidad"),
                                      },
                                    },
                                    {
                                      dataField: "peso",
                                      colSpan: 1,
                                      editorType: "dxNumberBox",
                                      editorOptions: {
                                        format: "#0.## '%'",
                                      },
                                      disabled: true,
                                      label: {
                                        text: getTrad("pesoGrupo"),
                                      },
                                    },
                                    {
                                      dataField: "añoFabricacion",
                                      editorType: "dxDateBox",
                                      colSpan: 1,
                                      allowSearch: false,
                                      editorOptions: {
                                        width: "100%",
                                        maxZoomLevel: "decade",
                                        minZoomLevel: "decade",
                                        max: new Date(),
                                        displayFormat: "yyyy",
                                        min: "01/01/1900",
                                      },
                                      label: {
                                        text: getTrad("añoFabricacion"),
                                      },
                                    },
                                    {
                                      dataField: "fechaInicioGarantia",
                                      editorType: "dxDateBox",
                                      colSpan: 1,
                                      allowSearch: false,
                                      editorOptions: {
                                        showClearButton: true,
                                        width: "100%",
                                        displayFormat: "dd/MM/yyyy",
                                        min: "01/01/1900",
                                      },
                                      label: {
                                        text: getTrad("fechaInicioGarantia"),
                                      },
                                      validationRules: [
                                        {
                                          type: "custom",
                                          reevaluate: true,
                                          validationCallback: function (e) {
                                            let fechaFinGarantia = $("#dxForm_InfoMaquina")
                                              .dxForm("instance")
                                              .option("formData.fechaFinGarantia");
                                            return (
                                              (fechaFinGarantia != null && e.value != null) || fechaFinGarantia == null
                                            );
                                          },
                                          message: getTrad("validator_fechaGarantia"),
                                        },
                                      ],
                                    },
                                    {
                                      dataField: "fechaFinGarantia",
                                      editorType: "dxDateBox",
                                      colSpan: 1,
                                      allowSearch: false,
                                      editorOptions: {
                                        showClearButton: true,
                                        width: "100%",
                                        displayFormat: "dd/MM/yyyy",
                                        min: "01/01/1900",
                                      },
                                      label: {
                                        text: getTrad("fechaFinGarantia"),
                                      },
                                      validationRules: [
                                        {
                                          type: "custom",
                                          reevaluate: true,
                                          validationCallback: function (e) {
                                            let fechaInicioGarantia = $("#dxForm_InfoMaquina")
                                              .dxForm("instance")
                                              .option("formData.fechaInicioGarantia");
                                            return (
                                              (fechaInicioGarantia != null && e.value > fechaInicioGarantia) ||
                                              fechaInicioGarantia == null
                                            );
                                          },
                                          message: getTrad("validator_fechaGarantia"),
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                              onContentReady: function (e) {
                                var form = e.component;
                                var formData = form.option("formData");
                                form.getEditor("activo").option("disabled", formData.numIncidenciasAbiertas > 0);
                              },
                            });
                          },
                        },
                      ],
                    });
                  },
                },
                {
                  title: "Prendas / hora",
                  template: function () {
                    return $("<div style='padding-top:20px;'>").dxBox({
                      height: "100%",
                      direction: "col",
                      align: "space-around",
                      crossAlign: "stretch",
                      items: [
                        {
                          baseSize: 30,
                          template: function () {
                            return $(
                              "<div id='denoMaquinaLabel' class='font-size-lg pl-4 ml-2'>" +
                                maquinaSel.denominacion +
                                "</div>"
                            );
                          },
                        },
                        {
                          ratio: 1,
                          template: function () {
                            return $("<div id='dxBox_prendasHora' style='padding:35px 30px;'>").dxBox({
                              height: "100%",
                              width: "70%",
                              direction: "row",
                              align: "space-around",
                              crossAlign: "stretch",
                              items: dxBox_prendasHora(""),
                            });
                          },
                        },
                      ],
                    });
                  },
                },
              ],
              onSelectionChanged: function (e) {
                if (e.component.option("selectedIndex") === 1) {
                  if (typeof $("#dxToolbar_piezasHoraMaquina").dxToolbar("instance") !== "undefined") {
                    $("#dxToolbar_piezasHoraMaquina").dxToolbar("instance").repaint();
                  }

                  //#region RESET LISTAS FILTROS
                  var filtro_dxList_compañia = $("#filtro_dxList_compañia").dxList("instance");
                  var filtro_dxList_entidad = $("#filtro_dxList_entidad").dxList("instance");
                  if (typeof filtro_dxList_compañia !== "undefined") {
                    filtro_dxList_compañia.option("searchValue", "");
                    filtro_dxList_compañia.selectItem(0);
                  }
                  if (typeof filtro_dxList_entidad !== "undefined") {
                    filtro_dxList_entidad.option("searchValue", "");
                    filtro_dxList_entidad.unselectAll();
                  }
                  //#endregion
                  var dxDataGrid_piezasHora = $("#dxDataGrid_piezasHora").dxDataGrid("instance");
                  if (typeof dxDataGrid_piezasHora !== "undefined") {
                    dxDataGrid_piezasHora.clearFilter();
                    dxDataGrid_piezasHora.refresh();
                  }
                }
                if (e.component.option("selectedIndex") === 1) {
                  let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
                  let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");

                  let layout = is_plegadora ? "plegador" : "full";
                  $("#dxBox_prendasHora")
                    .dxBox("instance")
                    .option({
                      items: dxBox_prendasHora(layout),
                      width: is_plegadora ? "575px" : "775px",
                    });

                  $("#denoMaquinaLabel").text(maquinaSel.denominacion);

                  const dxDataGrid_prendasHora = $("#dxDataGrid_prendasHora").dxDataGrid("instance");
                  dxDataGrid_prendasHora.columnOption(
                    "numVias",
                    "lookup.dataSource",
                    is_plegadora ? enum_viasPlegadoras : enum_viasCalandras
                  );
                }
              },
            });
        },
        onContentReady: function (args) {
          var html = args.component.content();
          $(html).css("padding", "0");
        },
        onHidden: function (e) {
          $("#dxTabPanel_infoMaquina").dxTabPanel("instance").option("selectedIndex", 0);
          var dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
          if (typeof dxForm_InfoMaquina !== "undefined") {
            dxForm_InfoMaquina.repaint();
          }
        },
        onShowing: function (e) {
          var dxForm_InfoMaquina = $("#dxForm_InfoMaquina").dxForm("instance");
          if (typeof dxForm_InfoMaquina.option("formData").activo === "undefined") {
            dxForm_InfoMaquina.option("formData").activo = true;
          }

          let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
          let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");
          let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

          $("#dxTabPanel_infoMaquina")
            .dxTabPanel("instance")
            .option("items[1].visible", maquinaSel.idMaquina != null && (is_plegadora || is_calandra));

          dxForm_InfoMaquina.repaint();
          dxForm_InfoMaquina
            .getEditor("tblTipoMaquinaNCategoriaMaquina.tblCategoriaMaquina.idSistemaMaquina")
            .option(
              "disabled",
              maquinaSel != null && maquinaSel.idMaquina != null && maquinaSel.idTipoMaquinaNCategoriaMaquina != null
            );
          dxForm_InfoMaquina
            .getEditor("tblTipoMaquinaNCategoriaMaquina.idCategoriaMaquina")
            .option(
              "disabled",
              maquinaSel != null && maquinaSel.idMaquina != null && maquinaSel.idTipoMaquinaNCategoriaMaquina != null
            );
          dxForm_InfoMaquina
            .getEditor("tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina")
            .option(
              "disabled",
              maquinaSel != null && maquinaSel.idMaquina != null && maquinaSel.idTipoMaquinaNCategoriaMaquina != null
            );
          datasource_tblFamilia.reload().done(function () {
            if (is_plegadora) datasource_tblPrendasHora.reload();
          });
        },
        onShown: function (e) {
          if ($("#filtro_dxList_tblTipoPrenda").length > 0) {
            const filtro_dxList_tblTipoPrenda = $("#filtro_dxList_tblTipoPrenda").dxList("instance");
            var handleFilterChanged = throttle(function (args) {
              // Clear the filter if the search value is empty
              if (args.value === "") {
                filtro_dxList_tblTipoPrenda.getDataSource().filter(null);
              }
              // Apply the filter if the search value is not empty
              else {
                filtro_dxList_tblTipoPrenda.getDataSource().filter(["denominacion", "contains", args.value]);
              }
            }, 500);

            filtro_dxList_tblTipoPrenda.option("onFilterChanged", handleFilterChanged);
            filtro_dxList_tblTipoPrenda.option("searchValue", "");
          }

          if ($("#filtro_dxList_tblFamilia").length > 0) {
            const filtro_dxList_tblFamilia = $("#filtro_dxList_tblFamilia").dxList("instance");
            var handleFilterChanged = throttle(function (args) {
              // Clear the filter if the search value is empty
              if (args.value === "") {
                filtro_dxList_tblFamilia.getDataSource().filter(null);
              }
              // Apply the filter if the search value is not empty
              else {
                filtro_dxList_tblFamilia.getDataSource().filter(["denominacion", "contains", args.value]);
              }
            }, 500);

            filtro_dxList_tblFamilia.option("onFilterChanged", handleFilterChanged);
            filtro_dxList_tblFamilia.option("searchValue", "");
          }
        },
        toolbarItems: [
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("guardar"),
              width: 100,
              type: "normal",
              onClick: function (e) {
                var form = $("#dxForm_InfoMaquina").dxForm("instance");
                var data = form.option("formData");
                if (data && form.validate().isValid) {
                  var maquina = {
                    denominacion: data.denominacion,
                    etiqueta: data.etiqueta,
                    numSerie: data.numSerie,
                    idTipoMaquinaNCategoriaMaquina: data.tblTipoMaquinaNCategoriaMaquina.idTipoMaquinaNCategoriaMaquina,
                    capacidad: typeof data.capacidad !== "undefined" ? data.capacidad : 0,
                    peso: data.peso,
                    añoFabricacion:
                      data.añoFabricacion && data.añoFabricacion !== "" ? data.añoFabricacion.getFullYear() : null,
                    fechaInicioGarantia: data.fechaInicioGarantia,
                    fechaFinGarantia: data.fechaFinGarantia,
                    activo: data.activo,
                    restrictiva: false,
                    eliminado: false,
                  };
                  if (!maquinaSel.idMaquina) {
                    datasource_maquinaria
                      .store()
                      .insert(maquina)
                      .done(function () {
                        $("#dgvMaquinaria").dxDataGrid("instance").refresh();
                        $("#popup_infoMaquina").dxPopup("instance").hide();
                      });
                  } else {
                    datasource_maquinaria
                      .store()
                      .update(maquinaSel.idMaquina, maquina)
                      .done(function () {
                        $("#dgvMaquinaria").dxDataGrid("instance").refresh();
                        $("#popup_infoMaquina").dxPopup("instance").hide();
                      });
                  }
                }
              },
            },
          },
          {
            toolbar: "bottom",
            location: "after",
            widget: "dxButton",
            options: {
              text: getTrad("cancelar"),
              width: 100,
              type: "normal",
              onClick: function (e) {
                $("#popup_infoMaquina").dxPopup("instance").hide();
              },
            },
          },
        ],
      });
    })
    .always(function () {
      deferred.resolve();
    });

  function dxBox_prendasHora(layout) {
    datasource_tblFamilia.reload();

    let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
    let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");
    let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

    const listaFamilia = {
      baseSize: 220, //345,
      template: function () {
        return $("<div />").dxBox({
          direction: "col",
          align: "space-around",
          crossAlign: "stretch",
          height: "100%",
          items: [
            {
              baseSize: 25,
              shrink: 0,
              ratio: 0,
              template: function () {
                return $("<div class='font-size-lg' style='margin-left:9px;'>").text(getTrad("familia"));
              },
            },
            {
              ratio: 1,
              template: function () {
                return $("<div id='filtro_dxList_tblFamilia' style='margin-top:17px;'>").dxList({
                  dataSource: datasource_tblFamilia,
                  keyExpr: "idFamilia",
                  selectionMode: "single",
                  scrolling: { mode: "infinite" },
                  pageLoadMode: "scrollBottom",
                  height: "100%",
                  onContentReady: function (e) {
                    let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
                    let is_calandra = isTipoMaquina(idTipoMaquina, "calandra");

                    e.component.selectItem(is_calandra ? 0 : 1); // SELECCIONA EL ITEM 0 POR DEFECTO
                  },
                  onSelectionChanged: function (e) {
                    familiaSel = e.addedItems.length > 0 ? e.addedItems[0] : null;
                    datasource_tblPrendasHora.reload();
                  },
                  itemTemplate: function (data, index) {
                    return $("<div>").text(data.denominacion);
                  },
                });
              },
            },
          ],
        });
      },
    };

    const serparador = {
      baseSize: 20,
    };

    const numViasColumn = {
      dataField: "numVias",
      caption: getTrad("numVias_abr"),
      alignment: "left",
      allowSorting: false,
      width: 80,
      allowFiltering: false,
      cssClass: "dx-Cell_Editable",
      showEditorAlways: true,
      lookup: {
        dataSource: {
          store: {
            type: "array",
            data: enum_viasPlegadoras,
            key: "id",
          },
          paginate: false,
        },
        valueExpr: "id",
        displayExpr: "name",
      },
    };

    const dxDataGrid_prendasHora = {
      ratio: 0,
      baseSize: is_plegadora ? 550 : 475,
      template: function () {
        return $(
          "<div id='dxDataGrid_prendasHora' style='padding-top: " +
            (is_plegadora ? "0px" : "14px") +
            ";padding-left:20px;' />"
        ).dxDataGrid({
          //Datos
          dataSource: datasource_tblPrendasHora,
          //Propiedades
          columnsAutoWidth: true,
          height: "100%",
          width: "100%",
          headerFilter: {
            visible: true,
          },
          filterRow: {
            visible: true,
            applyFilter: "auto",
          },
          editing: {
            mode: "cell",
            allowUpdating: true,
            allowDeleting: false,
            useIcons: true,
          },
          searchPanel: {
            visible: false,
            placeholder: getTrad("busqueda"),
          },
          scrolling: {
            mode: "infinite",
          },
          keyboardNavigation: {
            enterKeyAction: "moveFocus",
            enterKeyDirection: "column",
            editOnKeyPress: true,
          },
          columns: [
            {
              dataField: "idTipoPrendaNavigation.denominacion",
              caption: getTrad("denominacion"),
              alignment: "left",
              allowSorting: false,
              allowEditing: false,
              allowFiltering: false,
              sortOrder: "asc",
              cssClass: "pl-4",
            },
            {
              dataField: "idFamilia",
              visible: false,
              allowSearch: false,
            },
            is_calandra ? numViasColumn : null,
            {
              dataField: "prendasHora",
              caption: getTrad("prendasHora"),
              width: 105,
              alignment: "center",
              allowFiltering: false,
              allowSorting: false,
              showEditorAlways: true,
              cssClass: "dx-Cell_Editable",
              editorOptions: {
                step: 0,
                placeholder: "0",
                min: 0,
                max: 9999,
                format: { style: "decimal", maximumFractionDigits: 0 },
              },
            },
            {
              caption: " ",
              name: "removeCell",
              width: "50px",
              alignment: "center",
              cssClass: "p-0",
              cellTemplate: function (container, options) {
                const isDisabled = is_plegadora && (options.data.idFamilia === 1 || options.data.idFamilia === 3);
                var contenedor = $("<div/>").addClass("container_spanCentrado");
                svg_remove
                  .clone()
                  .css("opacity", isDisabled ? 0.5 : 1)
                  .css("pointer-events", isDisabled ? "none" : "auto")
                  .css("height", "25px")
                  .css("width", "25px")
                  .css("cursor", "pointer")
                  .appendTo(contenedor);

                container.on("click", function () {
                  if (is_calandra || (is_plegadora && (options.data.idFamilia === 4 || options.data.idFamilia === 5)))
                    options.component.deleteRow(options.rowIndex);
                });

                contenedor.appendTo(container);
              },
            },
          ],
          onCellPrepared: function (e) {
            if (e.column.dataField === "prendasHora" && e.rowType === "data") {
              let isDisabledColumn = e.data.numVias == null && is_calandra;
              e.cellElement.find("input").prop("disabled", isDisabledColumn);

              if (is_plegadora) {
                let isDisabledColumnPlegadora = e.data.idFamilia == 1 || e.data.idFamilia == 3;
                e.component
                  .getCellElement(e.rowIndex, "prendasHora")
                  .find("input")
                  .prop("disabled", isDisabledColumnPlegadora);
              }
            }
            if (e.column.dataField === "numVias" && e.rowType === "data") {
              let isDisabledColumn = e.data.numVias == null && is_calandra;
              e.component.getCellElement(e.rowIndex, "prendasHora").find("input").prop("disabled", isDisabledColumn);
            }
          },
          onRowPrepared: function (e) {
            if (e.rowType === "data") {
              if (is_plegadora && (e.data.idFamilia === 1 || e.data.idFamilia === 3)) {
                e.rowElement.addClass("disabledRow");
              }
            }
          },
          onRowUpdating: function (option) {
            if (option.key <= 0) {
              let idTipoMaquina = maquinaSel.tblTipoMaquinaNCategoriaMaquina.idTipoMaquina;
              let is_plegadora = isTipoMaquina(idTipoMaquina, "plegadora");

              let item = $.extend(true, {}, option.oldData);
              if (option.newData.prendasHora) item.prendasHora = option.newData.prendasHora;
              if (option.newData.numVias) item.numVias = option.newData.numVias;

              if (option.newData.prendasHora == null) item.prendasHora = 0;

              if (is_plegadora) {
                item.idFamilia = option.oldData.idFamilia;
                item.numVias = 1;
              }

              delete item.idPrendasHora;
              delete item.idTipoPrendaNavigation;
              delete item.idFamiliaNavigation;

              datasource_tblPrendasHora
                .store()
                .insert(item)
                .done(function () {
                  option.component.refresh(true);
                });

              option.component.cancelEditData();
              option.cancel = true;
            } else {
              option.newData = patchRequestHandler(option.newData);
            }
          },
          onEditorPreparing: function (e) {
            if (e.parentType == "dataRow" && e.dataField == "numVias") {
              e.editorOptions.searchEnabled = false;
              e.editorOptions.placeholder = "Sel.";
              var onValueChanged = e.editorOptions.onValueChanged;
              e.editorOptions.onValueChanged = function (args) {
                onValueChanged.apply(this, arguments);
                let isDisabledColumn = args == null && is_calandra;
                e.component
                  .getCellElement(e.row.rowIndex, "prendasHora")
                  .find("input")
                  .prop("disabled", isDisabledColumn);
              };
            }
          },
          onToolbarPreparing: function (e) {
            $.each(e.toolbarOptions.items, function (_, item) {
              if (item.name === "saveButton") {
                item.visible = false;
              }
            });
          },
          //Estilos
          showColumnLines: false,
          showRowLines: true,
          rowAlternationEnabled: true,
        });
      },
    };

    if (layout === "plegador") return [dxDataGrid_prendasHora];

    return [listaFamilia, serparador, dxDataGrid_prendasHora];
  }

  function isTipoMaquina(id, tipoMaquina) {
    let ids_tipoMaquina = {
      calandra: [1],
      plegadora: [2, 20, 33, 34],
    };

    let ids_todas = $.map(ids_tipoMaquina, function (item, index) {
      return item;
    });
    return $.inArray(id, tipoMaquina == null ? ids_todas : ids_tipoMaquina[tipoMaquina]) >= 0;
  }

  function validation_frequenciaMantenimiento(e, field) {
    var idFrequencia = e.data.idFrecuenciaMantenimiento;
    var idDia = e.data.idDiaSemana;
    var idSemana = e.data.semana;
    var idMes = e.data.idMes;

    if (
      !(idFrequencia >= 2 && field === "idDiaSemana" && !idDia) &&
      !(idFrequencia >= 3 && field === "semana" && !idSemana) &&
      !(idFrequencia >= 5 && field === "idMes" && !idMes)
    ) {
      return true;
    }
    return false;
  }
  return deferred.promise();
}
